import React from 'react'
import InlineSVG from 'react-inlinesvg';

import img from '../assets/svg/alert-triangle-icon.svg';

const UserIsNotVerifiedTooltip = ({ lang, warning = false }) => {
    return (
        <div className={`user-not-verified-tooltip ${warning ? `user-not-verified-tooltip-warning` : ''}`}>
            <InlineSVG src={img} />
            <span>{warning ? 'This account was created using two factor authentication.'.translate(lang) : 'This account is not yet verified!'.translate(lang)}</span>
        </div>
    )
}

export default UserIsNotVerifiedTooltip