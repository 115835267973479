import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";

import Isvg from "react-inlinesvg";
import { Calendar } from "react-date-range";

import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import * as locales from "react-date-range/dist/locale";
import { DOMAINS } from "../../../constants";
import moment from "moment";
function iosDevice() {
  //return true;
  var userAgent = window.navigator.userAgent;
  if (userAgent.match(/iPad/i) || userAgent.match(/iPhone/i)) {
    return true;
  } else {
    return false;
  }
}

/**
 * Custom datepicker
 * @author   Stefan Pantic
 */
class DatePicker extends Component {
  constructor(props) {
    super(props);
    this.wrapperRef = React.createRef();

    this.state = {};
  }

  componentDidMount() {
    let date = new Date();

    this.setState({
      year: date.getFullYear(),
    });

    if (this.props.value) {
      this.setState({
        value: this.props.value,
      });
    }

    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value != this.state.value && !this.state.value) {
      if (this.props.value) {
        this.setState({
          value: this.props.value,
        });
      }
    }
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      this.setState({
        showCalendar: null,
      });
    }
  };

  render() {
    let locale = null;

    if (typeof window !== "undefined" && iosDevice() && false) {
      locale = undefined;
    } else if (typeof window !== "undefined" && window._lang) {
      if (window._lang == "en") {
        locale = locales.enGB;
      } else {
        let domen = window.location.origin;
        let country = DOMAINS.find((item) => item.domen == domen)?.country;
        if (country == "sweden") {
          locale = locales.sv;
        } else if (country == "denmark") {
          locale = locales.da;
        } else if (country == "gr") {
          locale = locales.el;
        } else if (country == "es") {
          locale = locales.es;
        } else if (country == "ch") {
          locale = locales.de;
        } else {
          locale = locales.enGB;
        }
      }
    }
    return (
      <div
        className={
          this.props.error
            ? "date-picker-field-prev-next required"
            : "date-picker-field-prev-next"
        }
        ref={this.wrapperRef}
      >
        <label>{this.props.label}</label>
        <div style={{ display: "flex", position: "relative" }}>
          <div
            className="prev-button"
            onClick={() => {
              let day = new Date(this.props.value.replace(/-/g, "/"));
              day.setDate(day.getDate() - 1);
              this.props.onChange(
                moment(day).format(
                  this.props.dateFormat ? this.props.dateFormat : "YYYY-MM-DD"
                )
              );
            }}
          >
            {"Previous".translate(this.props.lang)}
          </div>
          <input
            value={this.props.value}
            type="text"
            onFocus={() => {
              if (!this.state.showCalendar) {
                this.setState({
                  showCalendar: true,
                });
              }
            }}
          />
          <div
            className="next-button"
            onClick={() => {
              let day = new Date(this.props.value.replace(/-/g, "/"));
              day.setDate(day.getDate() + 1);
              this.props.onChange(
                moment(day).format(
                  this.props.dateFormat ? this.props.dateFormat : "YYYY-MM-DD"
                )
              );
            }}
          >
            {"Next".translate(this.props.lang)}
          </div>
        </div>

        {this.state.showCalendar ? (
          <Calendar
            minDate={
              this.props.endDate
                ? this.props.endDate
                : this.props.minDate
                  ? this.props.minDate
                  : new Date(1990, 1 - 1)
            }
            maxDate={this.props.maxDate}
            // maxDate={new Date(this.state.year, 11, 31)}
            date={
              this.props.value
                ? new Date(this.props.value.replace(/-/g, "/"))
                : this.state.date
            }
            locale={locale}
            // navigatorRenderer={(date, fun, obj) => {
            //     console.log(date, obj);
            // }}
            // showMonthAndYearPickers={true}
            // editableDateInputs={true}
            onShownDateChange={(date) => {
              // console.log(date);
              let d = new Date(date);
              if (this.props.value) {
                // console.log(new Date(this.props.value.replace(/-/g, "/")).getDate());
                d.setDate(
                  new Date(this.props.value.replace(/-/g, "/")).getDate()
                );
              }

              this.props.onChange(
                moment(d).format(
                  this.props.dateFormat ? this.props.dateFormat : "YYYY-MM-DD"
                )
              );
            }}
            onChange={(date) => {
              this.setState(
                {
                  showCalendar: null,
                  // value:moment(new Date(date)).format('YYYY-MM-DD')
                },
                () => {
                  // if(typeof window != 'undefined'){
                  //     window.stopVerifyFun = false
                  // }
                }
              );
              this.props.onChange(
                moment(new Date(date)).format(
                  this.props.dateFormat ? this.props.dateFormat : "YYYY-MM-DD"
                )
              );
              if (this.props.openCalendar) this.props.closeCalendar();
            }}
          />
        ) : null}
      </div>
    );
  }
}

export default DatePicker;
