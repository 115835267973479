import React, { Component } from 'react';
import Page from '../../containers/page';

import {
    Container,
    Row,
    Col,
    Modal, ModalHeader, ModalBody, ModalFooter, Button, FormGroup, Input, Label, Table
} from 'reactstrap';

import { Link } from 'react-router-dom';
import Isvg from 'react-inlinesvg'

import FormBuilder from '../../components/forms/modalFormBuilder';
import { required } from '../../components/forms/validation';

import ListBuilder from '../../components/listBuilder';
import DeleteModal from '../../components/deleteModal';
import ErrorModal from '../../components/errorModal';
import { getSearchParams, generateSearchLink, generateSearchLinkMultiple } from '../../helpers/linkHelper';
import { API_ENDPOINT } from '../../constants';
import { formValueSelector, change } from 'redux-form';  // ES6
import { connect } from 'react-redux';
import ReferralClinics from '../../components/referralClinics';
import moment from 'moment';
import PrintHelper from '../../components/printHelper';
import edit from '../../assets/svg/edit.svg'
import garabage from '../../assets/svg/garbage.svg'
import addIcon from '../../assets/svg/add-icon.svg';
import Search from '../../components/search';
import profileUser from '../../assets/images/user.png';
import notification from '../../assets/svg/notification.svg';
import billInfoIcon from '../../assets/svg/bill_info.svg';
import moreIcon from '../../assets/svg/more.svg';
import viewIcon from '../../assets/svg/eye.svg';
import pickTask from '../../assets/svg/pick_task.svg';
import transferIcon from '../../assets/svg/transfer-icon.svg';
import infoIcon from "../../assets/svg/link-new-tab.svg";
import QuestionMark from '../../assets/svg/question.svg'
import startChatIcon from '../../assets/svg/startChat.svg';
import garbageIcon from '../../assets/svg/garbage.svg';

import xIcon from '../../assets/svg/x.svg';

import Checkbox from '../../components/forms/fields/checkbox';
import InfoModal from '../../components/infoModal';

import FormPreview from '../../components/formPreview';

import ReferralContent from '../../components/referralContent';
import ConsultationModal from '../../components/e-connect/ConsultationModal';


function pad(n, width, z) {
    z = z || '0';
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
}

/**
* List of recieved E-self-referrals
* @author   Milan Stanojevic
*/
class ReceivedReferrals extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props.initialData,
            form: false,
            useState: false,
            params: {
                entries: 10,
                page: 0
            },
            items: [],
            disableFilterButtons: true,
            responseTimestamp: Math.floor(new Date().getTime()),
            groups: [],
            subgroups: [],
            total: 0,
            loading: true,
            changeButton: false,
            loadingAssign: false,
            selectedDoctor: -1,
            tabIndex: 1,
            doctorsList: [],


        };
    }

    checkDoctorsAvailable = (doctorsList, modal, callback) => {
        let event;


        if (modal == 1) {
            event = this.state.updateEvent;
        } else if (modal == 2) {
            event = this.state.scheduleReferral;
        } else if (modal == 3) {
            event = this.state.scheduleControl;
        } else if (modal == 4) {
            event = doctorsList;
            doctorsList = doctorsList.additionalDoctors;
        } else if (modal == 5) {
            event = this.state.scheduleListReferral;
        }
        // console.log(event)

        if (doctorsList) {
            let data = {
                doctorsList: doctorsList,
                startEventReservedDateOriginal: event.date + ' ' + event.startTime,
                endEventReservedDateOriginal: event.date + ' ' + event.endTime,
                startEventReservedDate: this.props.getStringDateInverseTs(Math.floor(new Date(event.date + ' ' + event.startTime).getTime() / 1000), `${this.props.dateFormat} HH:mm`),
                endEventReservedDate: this.props.getStringDateInverseTs(Math.floor(new Date(event.date + ' ' + event.endTime).getTime() / 1000), `${this.props.dateFormat} HH:mm`),
                eventId: event.id
            }
            fetch(API_ENDPOINT + '/working-hours/check/doctors', {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify(data)
            }).then(res => res.json()).then((result) => {
                if (result.error) {
                    this.setState({
                        errorAdditionalDoctors: result.doctorsList
                    })
                } else {
                    this.setState({ doctorsModal: false, successfullyAdditionalDoctors: modal != 4 ? true : false }, () => {
                        if (modal == 1) {
                            let updateEvent = this.state.updateEvent;
                            updateEvent.additionalDoctors = this.state.doctorsList;
                            this.setState({ updateEvent })
                        } else if (modal == 2) {
                            let scheduleReferral = this.state.scheduleReferral;
                            scheduleReferral.additionalDoctors = this.state.doctorsList;
                            this.setState({ scheduleReferral })
                        } else if (modal == 3) {
                            let scheduleControl = this.state.scheduleControl;
                            scheduleControl.additionalDoctors = this.state.doctorsList;
                            this.setState({ scheduleControl })
                        } else if (modal == 5) {
                            let scheduleListReferral = this.state.scheduleListReferral;
                            scheduleListReferral.additionalDoctors = this.state.doctorsList;
                            this.setState({ scheduleListReferral })
                        }


                    })
                }
                if (callback) {
                    callback();
                }


            })
        }


    }
    get = () => {
        this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));

        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0, sortField: 'timestamp', sortType: -1, clinic: this.props.selectedClinic, group: this.props.selectedGroup })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

        for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
            this.props.loadDataWithQuery[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, {
                entries: 10, page: 0, sortField: 'timestamp', sortType: -1, clinic: this.props.selectedClinic, recipientUser: this.props.uData._id, group: this.props.selectedGroup,
                tabIndex: this.state.tabIndex,
                closed: this.state.closed ? this.state.closed : false,
                waiting: this.state.waiting ? this.state.waiting : false,
                inProgress: this.state.inProgress ? this.state.inProgress : false,
                columnVisibility: 'drop-in'
            })).then((data) => {
                let dataToSet = { ...data }
                if (dataToSet.page || dataToSet.items || dataToSet.total) {
                    dataToSet.loading = null;
                    // console.log(dataToSet.page || dataToSet.items || dataToSet.total)
                    let checkFilter = '';
                    if (dataToSet.resultFilter) {
                        checkFilter = String(dataToSet.resultFilter)
                        delete dataToSet.resultFilter;
                    }
                    let search = this.props[0].location.search;
                    let serachParams = getSearchParams(search);
                    let searchParamsFilter = '';
                    if (serachParams.filter) {
                        searchParamsFilter = serachParams.filter;
                    }
                    if (searchParamsFilter != checkFilter) {
                        delete dataToSet.page;
                        delete dataToSet.items;
                        delete dataToSet.total;
                        delete dataToSet.loading;
                    }
                }
                this.setState({
                    ...dataToSet,
                    // loading: null
                }, () => {
                    if (!this.state.doctorPermission) {
                        this.setState({ tabIndex: 2 })
                    }
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

    }

    componentDidMount() {
        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }

        let params = {};
        if (this.state.useState) {
            params = this.state.params;
        } else {
            params = getSearchParams(this.props[0].location.search, { entries: 10, page: 0, sortField: 'timestamp', sortType: -1, group: this.props.selectedGroup })
        }
        if (params.filter) {
            this.setState({
                updateFilterField: new Date().getTime()
            })
        }


        this.get();


        this.interval = setInterval(() => {
            this.get();
        }, 60 * 1000);



    }
    componentWillUnmount() {
        if (this.interval) {
            clearInterval(this.interval)
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps[0].location.search != this.props[0].location.search) {
            this.setState({
                loading: true
            }, () => {
                this.get();

            })
        }

        if (prevProps.selectedClinic != this.props.selectedClinic || prevProps.selectedGroup != this.props.selectedGroup) {
            this.updateMultipleParams([{ name: 'page', value: '0' }, { name: 'entries', value: '10' }])
            this.get();
        }
        if (prevState.doctorPermission != this.state.doctorPermission) {
            if (!this.state.doctorPermission) {
                this.setState({ tabIndex: 2 })
            } else {
                this.setState({ tabIndex: 1 })
            }
        }

        if (this.state.responseTimestamp && prevState.responseTimestamp && JSON.stringify(this.state.responseTimestamp) != JSON.stringify(prevState.responseTimestamp)) {
            this.setState({
                disableFilterButtons: false
            })
        }
    }


    updateSort = (field, type) => {
        if (this.state.useState) {
            let state = this.state.params;
            state.sortField = field;
            state.sortType = type;
            this.setState({
                params: state
            })
        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0, sortField: 'timestamp', sortType: -1 }, [{ name: 'sortField', value: field }, { name: 'sortType', value: type }], false));

        }
    }

    updateParams = (name, value, restart = false) => {

        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };
                obj[name] = value;
                this.setState({ params: obj });
            } else {
                let obj = this.state.params;
                obj[name] = value;
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(generateSearchLink(this.props[0].location.search, { entries: 10, page: 0, sortField: 'timestamp', sortType: -1, group: this.props.selectedGroup }, name, value, restart));
        }
    }
    updateMultipleParams = (fields, restart = false, callback) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj });

            } else {
                let obj = this.state.params;
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0, sortField: 'timestamp', sortType: -1, group: this.props.selectedGroup }, fields, restart));
        }
        if (callback) {
            callback()
        }
    }

    /**
    * Allow referral if status is waiting-for-approval
    * @author   Milan Stanojevic
    */
    allowReferral = () => {


        this.setState({
            loading: true
        }, () => {
            fetch(API_ENDPOINT + '/referrals/allow/' + this.state.referral._id, {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify({})
            }).then(res => res.json()).then((result) => {
                this.get();
                if (result.error) {
                    this.setState({
                        error: result.error?.translate(this.props.lang),
                        signinModal: false,
                        allowForm: false,
                        referral: null,
                        disallowForm: false,
                        returnForm: false,
                        scheduleForm: false,
                    })
                } else {
                    this.setState({
                        signinModal: false,
                        allowForm: false,
                        referral: null,
                        disallowForm: false,
                        returnForm: false,
                        scheduleForm: false,
                        scheduleReferral: true
                    })
                }

            })
        })

    }
    /**
    * Disallow referral if status is waiting-for-approval
    * @author   Milan Stanojevic
    */
    disallowReferral = (data) => {
        this.setState({
            loading: true
        }, () => {
            fetch(API_ENDPOINT + '/referrals/disallow/' + this.state.referral._id, {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify(data)
            }).then(res => res.json()).then((result) => {
                this.get();
                if (result.error) {
                    this.setState({
                        error: result.error?.translate(this.props.lang),
                        referral: null,
                        disallowForm: false,
                        returnForm: false,
                        scheduleForm: false,
                    })
                } else {
                    this.setState({
                        referral: null,
                        disallowForm: false,
                        returnForm: false,
                        scheduleForm: false
                    })
                }

            })
        })
    }

    returnForUpdate = (data) => {
        this.setState({
            loading: true
        }, () => {
            fetch(API_ENDPOINT + '/referrals/return/' + this.state.referral._id, {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify(data)
            }).then(res => res.json()).then((result) => {
                this.get();
                this.setState({
                    referral: null,
                    disallowForm: false,
                    returnForm: false,
                    scheduleForm: false
                })
            })
        })
    }
    deleteReferral = () => {
        if (!this.state.referral?._id) {
            return;
        }
        this.setState({
            loading: true
        }, () => {
            fetch(API_ENDPOINT + '/referrals/delete/' + this.state.referral._id, {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify({})
            }).then(res => res.json()).then((result) => {
                this.get();
                if (result?.error) {
                    this.setState({
                        error: result.error?.translate(this.props.lang),
                        loading: false
                    })
                } else {
                    this.setState({
                        referral: null,

                    })
                }

            })
        })
    }

    closeReferral = (data) => {
        if (!data) data = {};
        data.selectedGroup = this.props.selectedGroup;
        data.closeConversation = true;

        this.setState({
            loading: true
        }, () => {
            fetch(API_ENDPOINT + '/referrals/close/' + this.state.referral._id, {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify(data)
            }).then(res => res.json()).then((result) => {
                this.get();
                if (result?.error) {
                    this.setState({
                        error: result.error?.translate(this.props.lang),
                        loading: false
                    })
                } else {
                    this.setState({
                        referral: null,
                        disallowForm: false,
                        returnForm: false,
                        scheduleForm: false
                    })
                }

            })
        })
    }

    forward = (clinic) => {

        this.setState({
            loading: true
        }, () => {
            fetch(API_ENDPOINT + '/referrals/forward/' + this.state.referral._id, {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify({
                    forwardRecipientClinic: clinic._id
                })
            }).then(res => res.json()).then((result) => {
                this.get();
                this.setState({
                    referral: null,
                    disallowForm: false,
                    returnForm: false,
                    selectClinicModal: false,
                    scheduleForm: false,
                    checkForward: false
                })
            })
        })

    }


    acceptReferral = () => {
        this.setState({
            loading: true
        }, () => {
            fetch(API_ENDPOINT + '/referrals/accept/' + this.state.referral._id, {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify({})
            }).then(res => res.json()).then((result) => {
                this.get();
                this.setState({
                    referral: null,
                    disallowForm: false,
                    returnForm: false,
                    scheduleForm: false
                })
            })
        })
    }
    rejectReferral = (data) => {
        this.setState({
            loading: true
        }, () => {
            fetch(API_ENDPOINT + '/referrals/reject/' + this.state.referral._id, {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify(data)
            }).then(res => res.json()).then((result) => {
                this.get();
                this.setState({
                    referral: null,
                    disallowForm: false,
                    returnForm: false,
                    scheduleForm: false
                })
            })
        })
    }

    /**
    * Accept requested time from patient if status is patient-requested-another-time
    * @author   Milan Stanojevic
    */
    acceptRequestedTime = () => {
        this.setState({
            loading: true
        }, () => {
            fetch(API_ENDPOINT + '/referrals/patient/accept-requested-time/' + this.state.referral._id, {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify({})
            }).then(res => res.json()).then((result) => {
                this.get();
                this.setState({
                    referral: null,
                    disallowForm: false,
                    returnForm: false,
                    scheduleForm: false
                })
            })
        })
    }
    /**
    * Reject requested time from patient if status is patient-requested-another-time
    * @author   Milan Stanojevic
    */
    rejectRequestedTime = (data) => {
        this.setState({
            loading: true
        }, () => {
            fetch(API_ENDPOINT + '/referrals/patient/reject-requested-time/' + this.state.referral._id, {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify({ date: data.date + ' ' + data.time })
            }).then(res => res.json()).then((result) => {
                this.get();
                this.setState({
                    referral: null,
                    disallowForm: false,
                    returnForm: false,
                    scheduleForm: false
                })
            })
        })
    }

    /**
    * Reject requested time from patient if status is patient-requested-another-time
    * @author   Milan Stanojevic
    * @Objectparam    {String} id                   referral id
    * @Objectparam    {String} name                 referral number
    * @Objectparam    {Boolean} patient             true
    */
    conversation = (id, data) => {
        data.dropIn = true;
        this.setState({
            loading: true
        }, () => {
            fetch(API_ENDPOINT + '/referrals/conversation/recpient/' + id, {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify(data)
            }).then(res => res.json()).then((result) => {
                this.props[0].history.push(`/chat?conversation=${result.conversation}`)
            })

        })
    }

    assignReferral = (item) => {

        this.setState({
            loadingAssign: true
        }, () => {
            fetch(API_ENDPOINT + '/referrals/assign/drop-in/' + item._id, {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify({})
            }).then(res => res.json()).then((result) => {
                this.get();

                if (result?.error) {
                    this.setState({
                        error: result.error?.translate(this.props.lang),
                        conversationModal: null,
                        loadingAssign: false
                    });
                    return;
                }

                this.setState({
                    conversationModal: null,
                    loadingAssign: false

                }, () => this.conversation(item._id, { ...this.state.conversationForm, patient: true }))
            })
        })


    }

    priorityForDropIn = (item) => {

        fetch(API_ENDPOINT + '/referrals/priority/drop-in/' + item._id, {
            method: 'POST',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
            body: JSON.stringify({ priority: item.priority })
        }).then(res => res.json()).then((result) => {
            this.get();
            let referral = this.state.referral
            referral.priority = item.priority
            this.setState({
                referral
            })
        })

    }

    transferDropIn = (item) => {
        // console.log(item);


        this.setState({
            loading: true
        }, () => {
            fetch(API_ENDPOINT + '/referrals/transfer/drop-in/' + item._id, {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify({ doctor: this.state.selectedDoctor, prevDoctor: item.recipientUser, group: this.props.selectedGroup, clinic: this.props.selectedClinic })
            }).then(res => res.json()).then((result) => {
                if (result.error) {
                    this.setState({
                        error: result.error,
                        loading: false
                    })
                } else {
                    this.get();

                    this.setState({
                        transferModal: false,
                        selectedDoctor: -1,
                        loading: false

                    })
                }
            })
        })

    }

    generateSections = (itemsArr) => {
        let sections = {};
        let items = [{ type: 0 }, ...itemsArr];
        let lastSectionId = -1;
        for (let i = 0; i < items.length; i++) {
            if (items[i].type == 0) {
                lastSectionId++;
                sections[lastSectionId] = {
                    name: items[i].name,
                    displayOnReferral: false,
                    questions: [],
                    conditions: items[i].conditions ? items[i].conditions : [],
                    displayOnReferral: items[i].displayOnReferral,
                    fontStyle: items[i].fontStyle,
                    fontSize: items[i].fontSize,
                    fontWeight: items[i].fontWeight,

                }
            }
            if (lastSectionId != -1 && items[i].type != 0) {


                let wikiLinks = [];

                items[i].wikiLinks = wikiLinks;


                if (items[i].type == 1) {
                    let displayOnReferral = false;
                    if (items[i].answers) {
                        for (let j = 0; j < items[i].answers.length; j++) {
                            if (items[i].answers[j].text) {
                                displayOnReferral = true;
                            }
                        }
                    }

                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        answers: items[i].answers,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        displayOnReferral: displayOnReferral,
                        wikiLinks: items[i].wikiLinks,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                    });

                } else if (items[i].type == 2) {
                    let displayOnReferral = false;
                    if (items[i].answers) {
                        for (let j = 0; j < items[i].answers.length; j++) {

                            if (items[i].answers[j].text) {
                                displayOnReferral = true;
                            }
                        }
                    }
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        answers: items[i].answers,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        answerPrefix: items[i].answerPrefix,
                        userInput: items[i].userInput,
                        displayOnReferral: displayOnReferral || items[i].answerPrefix ? true : false,
                        wikiLinks: items[i].wikiLinks,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,

                    })
                } else if (items[i].type == 3) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        answerPrefix: items[i].answerPrefix,
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                    })

                } else if (items[i].type == 4) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        text: items[i].text,
                        userInput: items[i].userInput,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                    })
                } else if (items[i].type == 15) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        text: items[i].text,
                        userInput: items[i].userInput,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                    })

                } else if (items[i].type == 5) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                        score: items[i].score
                    })

                } else if (items[i].type == 6) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                        score: items[i].score
                    })

                } else if (items[i].type == 7) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                    })

                } else if (items[i].type == 8) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                    })

                } else if (items[i].type == 9) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                    })

                } else if (items[i].type == 10) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        userInput: items[i].userInput,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                    })
                } else if (items[i].type == 12) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                        score: items[i].score
                    })
                } else if (items[i].type == 14) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                        score: items[i].score
                    })

                } else if (items[i].type == 13) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                        score: items[i].score
                    })

                } else if (items[i].type == 16) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                        blocks: items[i].blocks,
                    })

                } else if (items[i].type == 17) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                        blocks: items[i].blocks,

                    })

                }
            }
        }

        return Object.values(sections);
    }


    getPatientProfile = (item) => {


        fetch(API_ENDPOINT + '/users/referral/patient-profile', {
            method: 'POST',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
            body: JSON.stringify({ referral: item._id })
        }).then(res => res.json()).then((result) => {
            if (result && result.patient) {
                let obj = { ...item, ...result }
                this.setState({ patientProfile: obj })
            } else {
                this.setState({ patientProfile: item })
            }
        })
    }
    getReferralInfo = (item, referralContentPreview = false) => {
        let referral = { ...item }

        fetch(API_ENDPOINT + '/referrals-cases/referral/projection/data', {
            method: 'POST',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
            body: JSON.stringify({ _id: item._id })
        }).then(res => res.json()).then((result) => {
            if (result) {
                referral = { ...referral, ...result }
            }
            this.setState({ referral }, () => {
                this.setState({ refresh: new Date().getTime() })
            })
            // if (referralContentPreview) {
            //     this.setState({ referralContentPreview: referral }, () => {
            //         this.setState({ refresh: new Date().getTime() })

            //     })
            // } else {
            //     this.setState({ referral })
            // }


        })
    }

    searchPatientWithRedirect = (SSN, enterprise) => {
        let obj = {
            socialSecurityNumber: SSN,
            group: this.props.selectedGroup,
            clinic: this.props.selectedClinic,
        }
        enterprise && (obj.openOnWebDoc = true);

        fetch(API_ENDPOINT + '/users/find/patient', {
            method: 'POST',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
            body: JSON.stringify(obj)
        }).then(res => res.json()).then((result) => {

            if (result && result._id) {
                const path = enterprise ? '/patients/search-enterprise' : '/patients/search';
                this.props.registerClinicGroupLogs({
                    type: 'patientProfile',
                    patient: SSN,
                });

                let state = {
                    TYPE: 'redirect',
                    redirectPatient: result,
                    redirectTabIndex: 1
                }
                enterprise && (state.redirectTabActive = 1);

                this.props[0].history.push(path, state);

            } else {
                this.setState({ error: 'User not found in this clinic group' })
            }
        })
    }

    prepareNote = (item) => {
        let counter = 0
        let noteArr = item.note.split('')
        let noteFinished = ''
        for (let i = 0; i < noteArr.length; i++) {
            counter++
            noteFinished = noteFinished + noteArr[i]
            if (noteArr[i] === '\n') {
                counter = 0
            }

            if (counter === 80) {
                counter = 0
                noteFinished = noteFinished + '\n'
            }

        }

        item.note = noteFinished

        return item.note.split('\n').join('<br/>')
    }

    updateReferralNote = (data) => {
        let obj = {
            note: data.note,
            username: this.props.uData.username
        }
        this.setState({
            loading: true
        }, () => {
            fetch(API_ENDPOINT + '/referrals/note/' + data._id, {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify(obj)
            }).then(res => res.json()).then((result) => {
                this.setState({
                    referralNote: null,

                })
                this.get()
            })
        })
    }
    removeReferralFromList = (referralId) => {
        let data = {
            referralId: referralId,
            clinic: this.props.selectedClinic
        }
        if (data.referralId && data.clinic) {
            fetch(API_ENDPOINT + '/referral/remove/transfered/info', {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify(data)
            }).then(res => res.json()).then((result) => {
                if (result?.error) {
                    this.setState({ error: result.error })
                } else {
                    this.setState({ removeReferralModal: null, referral: null })
                    this.get()

                }

            })
        }


    }
    render() {

        let params = {};
        if (this.state.useState) {
            params = this.state.params;
        } else {
            params = getSearchParams(this.props[0].location.search, { entries: 10, page: 0, sortField: 'timestamp', sortType: -1, group: this.props.selectedGroup })
        }


        let subgroups = [{ name: 'None', value: null }];

        if (this.props.group) {
            for (let i = 0; i < this.state.subgroups.length; i++) {
                if (this.state.subgroups[i].groups && this.state.subgroups[i].groups.indexOf(this.props.group) !== -1) {
                    subgroups.push(this.state.subgroups[i]);
                }
            }
        }

        const statusColors = {
            'waiting-for-approval': 'orange',
            'waiting': 'orange',
            'waiting-for-payment': 'orange',
            'disallowed': 'red',
            'allowed': 'green',
            'return-for-update': 'blue',
            'patient-requested-another-time': 'purple',
            'patient-rejected': 'red',
            'patient-accepted': 'green',
            'clinic-accepted-requested-time': 'green',
            'clinic-rejected-requested-time': 'orange',
            'approved-by-region-manager': 'green',
            'rejected-by-region-manager': 'red',
            'scheduled': 'green',
            'in-progress': 'green',
            'not-booked': 'red',
            'closed': 'gray',
            'requested-new-appointment': 'orange',
            'external': 'gray',

        }

        const humanReadable = {
            'waiting-for-approval': 'Waiting for approval'.translate(this.props.lang),
            'waiting': 'Waiting'.translate(this.props.lang),
            'waiting-for-payment': 'Waiting for payment'.translate(this.props.lang),
            'disallowed': 'Disallowed'.translate(this.props.lang),
            'allowed': 'Allowed'.translate(this.props.lang),
            'return-for-update': 'Return for update'.translate(this.props.lang),
            'patient-requested-another-time': 'Patient requested anoother time'.translate(this.props.lang),
            'patient-rejected': 'Rejected by patient'.translate(this.props.lang),
            'patient-accepted': 'Patient accepted'.translate(this.props.lang),
            'clinic-accepted-requested-time': 'Clinic accepted requested time'.translate(this.props.lang),
            'clinic-rejected-requested-time': 'Clinic rejected requested time'.translate(this.props.lang),
            'approved-by-region-manager': 'Approved by region manager'.translate(this.props.lang),
            'rejected-by-region-manager': 'Rejected by region manager'.translate(this.props.lang),
            'scheduled': 'Scheduled'.translate(this.props.lang),
            'not-booked': 'Not booked'.translate(this.props.lang),
            'closed': 'Closed'.translate(this.props.lang),
            'in-progress': 'In progress'.translate(this.props.lang),
            'requested-new-appointment': 'Requested new appointment'.translate(this.props.lang),
            'external': 'External transfer'.translate(this.props.lang),
        }

        // console.log(this.state.clinicAdminPermission,   this.state.doctorPermission)

        let selectedGroupIdx = -1;
        if (this.props.uData && this.props.selectedGroup && this.props.uData?.clinicGroups?.length) {
            for (let i = 0; i < this.props.uData.clinicGroups.length; i++) {
                if (this.props.uData.clinicGroups[i]._id == this.props.selectedGroup) {
                    selectedGroupIdx = i;
                    break;
                }
            }
        }
        let enterprise = this.props.uData && this.props.uData.clinicGroups && this.props.uData.clinicGroups[selectedGroupIdx] && this.props.uData.clinicGroups[selectedGroupIdx].enterprise;
        let canSearchPatients = this.props.uData && this.props.uData.clinicGroups && this.props.uData.clinicGroups[selectedGroupIdx] && this.props.uData.clinicGroups[selectedGroupIdx].sarchPatients;

        const groupPermissionsUser = this.props.uData?.groupPermissionsUser;
        const adminPermissionId = this.props.uData?.permissions?.admin;
        const doctorPermissionId = this.props.uData?.permissions?.doctor;
        const isAdmin = groupPermissionsUser?.[this.props.selectedGroup]?.[this.props.selectedClinic]?.indexOf(adminPermissionId) !== -1 ? true : false;
        const isDoctor = groupPermissionsUser?.[this.props.selectedGroup]?.[this.props.selectedClinic]?.indexOf(doctorPermissionId) !== -1 ? true : false;

        const eConnectEnabled = this.props.uData && this.props.uData.clinicGroups && this.props.uData.clinicGroups[selectedGroupIdx] && this.props.uData.clinicGroups[selectedGroupIdx].eConnect && !this.props.uData.clinicGroups[selectedGroupIdx].eConnectTempDisabled;
        return (
            <div className={this.props.autoHideSidebar ? "dashboard dashboard-auto-hide" : "dashboard"}>

                <Container fluid>
                    <Row>

                        <Col lg="12">
                            <div className="panel">
                                <h5 className="component-header">{this.props.linksMeta && this.props.linksMeta[this.props[0].location.pathname] ? this.props.linksMeta[this.props[0].location.pathname][this.props.lang] : ''}</h5>
                                {/* {
                                    (this.state.clinicAdminPermission && this.state.doctorPermission) || (this.state.clinicAdminPermission && !this.state.doctorPermission) ?
                                        <div className="flex-space-between tablet-none notifications-tabs-wrap" style={{ position: 'relative', marginBottom: '50px', width: "100%", borderBottom: "1px solid #9cafbf" }}>
                                            <div className="notifications-tabs">
                                                {
                                                    this.state.doctorPermission ?
                                                        <Button className={this.state.tabIndex == 1 ? "tab-button acitve-tab-button" : "tab-button"} onClick={() => this.updateMultipleParams([{ name: 'page', value: 0 }, { name: 'entries', value: 10 }, { name: 'filter', value: null },
                                                        ], false, this.setState({ tabIndex: 1 }, () => this.get()))}>{"Waiting".translate(this.props.lang)}</Button>
                                                        :
                                                        null
                                                }

                                                <Button className={this.state.tabIndex == 2 ? "tab-button acitve-tab-button" : "tab-button"} onClick={() => this.updateMultipleParams([{ name: 'page', value: 0 }, { name: 'entries', value: 10 }, { name: 'filter', value: null },
                                                ], false, this.setState({ tabIndex: 2 }, () => this.get()))}>{"In progress".translate(this.props.lang)}</Button>

                                                <Button className={this.state.tabIndex == 3 ? "tab-button acitve-tab-button" : "tab-button"} onClick={() => this.updateMultipleParams([{ name: 'page', value: 0 }, { name: 'entries', value: 10 }, { name: 'filter', value: null },
                                                ], false, this.setState({ tabIndex: 3 }, () => this.get()))}>{"Closed".translate(this.props.lang)}</Button>
                                            </div>
                                        </div>
                                        :
                                        null
                                } */}
                                <div >
                                    <div style={{ marginTop: 10 }}>
                                        <Label>{'Case status:'.translate(this.props.lang)}</Label>
                                    </div>


                                    <div className="profession-buttons mr-0">
                                        <div>
                                            <div >
                                                <button disabled={this.state.disableFilterButtons} className={this.state.waiting ? ' clicked' : ''} onClick={() => {
                                                    this.setState({
                                                        waiting: !this.state.waiting,
                                                        // inProgress: false,
                                                        // closed: false
                                                        disableFilterButtons: true
                                                    }, () => this.get(), this.updateMultipleParams([{ name: 'page', value: '0' }, { name: 'entries', value: '10' }]))
                                                }} >{'Waiting'.translate(this.props.lang)}</button>
                                            </div>
                                        </div>

                                        <div>
                                            <div >
                                                <button disabled={this.state.disableFilterButtons} className={this.state.inProgress ? ' clicked' : ''} onClick={() => {
                                                    this.setState({
                                                        inProgress: !this.state.inProgress,
                                                        // waiting: false,
                                                        // closed: false
                                                        disableFilterButtons: true
                                                    }, () => this.get(), this.updateMultipleParams([{ name: 'page', value: '0' }, { name: 'entries', value: '10' }]))
                                                }} >{'In progress'.translate(this.props.lang)}</button>
                                            </div>
                                        </div>

                                        <div>
                                            <div >
                                                <button disabled={this.state.disableFilterButtons} className={this.state.closed ? ' clicked' : ''} onClick={() => {
                                                    this.setState({
                                                        closed: !this.state.closed,
                                                        // waiting: false,
                                                        // inProgress: false
                                                        disableFilterButtons: true
                                                    }, () => this.get(), this.updateMultipleParams([{ name: 'page', value: '0' }, { name: 'entries', value: '10' }]))
                                                }} >{'Closed'.translate(this.props.lang)}</button>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div style={{ marginBottom: 20 }}>

                                </div>

                                <ListBuilder
                                    lang={this.props.lang}
                                    loading={this.state.loading}
                                    total={this.state.total}
                                    showNumeration={false}
                                    updateFilterField={this.state.updateFilterField}
                                    columnVisibility={'drop-in'} //columnVisibility
                                    uData={this.props.uData} //columnVisibility
                                    updateListBuilderFields={this.props.updateListBuilderFields} //columnVisibility
                                    get={this.get}//columnVisibility
                                    fields={[
                                        { type: 'text', name: 'recpientClinicName', label: 'Recipient'.translate(this.props.lang), allowSort: true },
                                        { type: 'text', name: 'remissType', label: 'Referral Type'.translate(this.props.lang), allowSort: true },
                                        { type: 'text', name: 'patient', label: 'Patient'.translate(this.props.lang), allowSort: false },
                                        { type: 'text', name: 'priority', label: 'Priority'.translate(this.props.lang), allowSort: true },
                                        { type: 'text', name: 'version', label: 'Referral ID'.translate(this.props.lang), allowSort: true },
                                        { type: 'text', name: 'questionaryName', label: 'Visit reason'.translate(this.props.lang), allowSort: true },
                                        { type: 'text', name: 'inProgressTimestamp', label: 'Visit date'.translate(this.props.lang), allowSort: true },
                                        { type: 'text', name: 'status', label: 'Status'.translate(this.props.lang), allowSort: true },
                                        { type: 'text', name: 'timestamp', label: 'Created'.translate(this.props.lang), allowSort: true },
                                        { type: 'text', name: 'lastActivity', label: 'Last activity'.translate(this.props.lang), allowSort: true },
                                        // { type: 'checkbox', name: 'seenScheduled', label: 'Seen'.translate(this.props.lang), allowSort: true },
                                    ]}
                                    rawItems={this.state.items}
                                    items={this.state.items.map((item => {

                                        return {
                                            ...item,
                                            remissType: item.remissTypeEn ? item.remissTypeEn.translate(this.props.lang) : '',
                                            questionaryName: this.props.lang == 'en' ? item.questionaryName?.replace?.(' (onlinebesök)', ' (online visit)') : item.questionaryName?.replace?.(' (online visit)', ' (onlinebesök)'),
                                            version: item.patient?.referralNumber + (item.version != 0 ? '.' + item.version : ''),
                                            recpientClinicName: <>{item.recpientClinicName}<br /><span className="clinic-subgroup-name">{item.recpientClinicSubgroupName} </span><br /> <span className="clinic-subgroup-name">{item.doctorName}</span>  </>,
                                            patient: <Button color='#fff' className="patient-modal-button" onClick={() => {
                                                // this.setState({ patientProfile: item }, () => {
                                                //     this.props.registerClinicGroupLogs({
                                                //         type: 'patientProfile',
                                                //         patient: item.patient?.socialSecurityNumber
                                                //     })
                                                // })
                                                if (enterprise || canSearchPatients) {
                                                    this.searchPatientWithRedirect(item.patient?.socialSecurityNumber, enterprise);
                                                } else {
                                                    this.getPatientProfile(item)
                                                    this.props.registerClinicGroupLogs({
                                                        type: 'patientProfile',
                                                        patient: item.patient?.socialSecurityNumber
                                                    })
                                                }

                                            }}>{item.patient?.name}<br /><span className="clinic-subgroup-name">{item.patient?.socialSecurityNumber} </span> {item?._2fa ? <p style={{ padding: 0, margin: 0, color: 'red', fontSize: 12 }}>{'2FA'.translate(this.props.lang)}</p> : null} </Button>,
                                            timestamp: this.props.getStringDateTs(item.timestamp, `${this.props.dateFormat} HH:mm`)/*moment.unix(item.timestamp).format(`${this.props.dateFormat} HH:mm`)*/,
                                            lastActivity: item.tsModified ? this.props.getStringDateTs(item.tsModified, `${this.props.dateFormat} HH:mm`)/*moment.unix(item.tsModified).format(`${this.props.dateFormat} HH:mm`)*/ : this.props.getStringDateTs(item.tsCreated, `${this.props.dateFormat} HH:mm`)/*moment.unix(item.tsCreated).format(`${this.props.dateFormat} HH:mm`)*/,
                                            converateTimestamp: this.props.getStringDateTs(item.converateTimestamp, `${this.props.dateFormat} HH:mm`)/*moment.unix(item.converateTimestamp).format(`${this.props.dateFormat} HH:mm`)*/,
                                            statusValue: item.status,
                                            priority: <div className={

                                                item.priority == 1 ? 'priority-circle priority-circle-list p-red nohover' :
                                                    item.priority == 2 ? 'priority-circle priority-circle-list p-orange nohover' :
                                                        item.priority == 3 ? 'priority-circle priority-circle-list p-yellow nohover' :
                                                            item.priority == 4 ? 'priority-circle priority-circle-list p-black nohover' :
                                                                item.priority == 5 ? 'priority-circle priority-circle-list p-green nohover' : null

                                            }>
                                                {item.priority}
                                            </div>,
                                            inProgressTimestamp: item.inProgressTimestamp ? this.props.getStringDateTs(item.inProgressTimestamp, `${this.props.dateFormat} HH:mm`)/*moment.unix(item.inProgressTimestamp).format(`${this.props.dateFormat} HH:mm`)*/ : 'N/A'.translate(this.props.lang),
                                            status: item.recipientClinic !== this.props.selectedClinic ? <>
                                                <span style={{ color: statusColors['external'] }}>{humanReadable['external']} </span>
                                                <br />


                                            </> : <>
                                                <span style={{ color: statusColors[item.status] }}>{humanReadable[item.status]} </span>
                                                <br />


                                            </>,

                                        }
                                    }))}
                                    actions={
                                        [

                                            {
                                                component: <div className="tabel-action-tooltip"> <Isvg src={viewIcon} /> <div className="chat-icons-tooltip">{'Preview'.translate(this.props.lang)}</div> </div>,
                                                onClick: (item) => this.getReferralInfo(item) /*this.setState({ referral: item })*/

                                            },
                                            // {
                                            //     component: <div className="tabel-action-tooltip">  test <div className="chat-icons-tooltip">{'Preview'.translate(this.props.lang)}</div> </div>,
                                            //     onClick: (item) => this.getReferralInfo(item, true)
                                            // },
                                            {
                                                component: <div className="tabel-action-tooltip"> <Isvg src={pickTask} className="transfer-icon" /> <div className="chat-icons-tooltip">{'Pick up'.translate(this.props.lang)}</div> </div>,
                                                onClick: (item) => {
                                                    if (item && item.patient && item.patient?.socialSecurityNumber == this.props.uData.socialSecurityNumber) {
                                                        this.setState({ messageInfo: 'Unable to pick up this case. Both participants are using the same account.'.translate(this.props.lang) })
                                                        return;
                                                    }

                                                    this.setState({ conversationModal: item, conversationForm: { name: item.patient?.referralNumber + '-' + pad(item.conversations.length + 1, 2) } })
                                                },
                                                condition: (item) => {
                                                    if (item.recipientClinic !== this.props.selectedClinic) {
                                                        return false;
                                                    } else if (item.statusValue == 'waiting')
                                                        return true;
                                                    else
                                                        return false;
                                                }

                                            },

                                            {
                                                renderComponent: (item) => <div className="tabel-action-tooltip"> <Isvg src={transferIcon} className={eConnectEnabled && item?._consultation ? "transfer-icon consultation-active-icon" : "transfer-icon"} /> <div className="chat-icons-tooltip">{eConnectEnabled ? 'Consultation'.translate(this.props.lang) : 'Transfer'.translate(this.props.lang)}</div> </div>,
                                                onClick: async (item) => {
                                                    if (eConnectEnabled && this.props.checkIfConsultationExists && item?._id) {
                                                        let consultationExists = await this.props.checkIfConsultationExists(item._id, () => {
                                                            this.setState({ transferModal: item })
                                                        });
                                                        if (!consultationExists)
                                                            this.setState({ transferModal: item })
                                                    } else {
                                                        this.setState({ transferModal: item })
                                                    }

                                                },
                                                condition: (item) => {
                                                    if (item.recipientClinic !== this.props.selectedClinic) {
                                                        return false;
                                                    } else if (item.statusValue !== 'closed' && (this.state.adminPermission))
                                                        return true;
                                                    else
                                                        return false;
                                                }
                                            },
                                            enterprise ?
                                                {
                                                    renderComponent: (item) => { return (<div className="tabel-action-tooltip"> <Isvg src={infoIcon} className={item && item.note ? "note-icon" : ''} style={{ width: 36, height: 23 }} /> <div className="chat-icons-tooltip info-tooltip-custom"><span>{item && item.note ? <div dangerouslySetInnerHTML={{ __html: this.prepareNote(item) }}></div> : 'Note'.translate(this.props.lang)}{item && item.note && item.noteUsername ? ' ' + item.noteUsername : ''}<br />{item && item.note && item.noteUsername && item.noteTimestamp ? ' ' + this.props.getStringDateTs(item.noteTimestamp, `${this.props.dateFormat} HH:mm`)/*moment.unix(item.noteTimestamp).format(`${this.props.dateFormat} HH:mm`)*/ : ''}</span></div> </div>) },
                                                    onClick: (item) => this.setState({ referralNote: { ...item } }),
                                                    condition: (item) => {
                                                        if (item.recipientClinic === this.props.selectedClinic)
                                                            return true;
                                                        else
                                                            return false;
                                                    }
                                                } : {
                                                    renderComponent: () => { return null },
                                                    onClick: () => { },
                                                    condition: (item) => {
                                                        if (item.recipientClinic === this.props.selectedClinic)
                                                            return true;
                                                        else
                                                            return false;
                                                    }
                                                },
                                            {
                                                component: <div className="tabel-action-tooltip"> <Isvg src={garbageIcon} className="transfer-icon" /> <div className="chat-icons-tooltip">{'Remove'.translate(this.props.lang)}</div> </div>,
                                                onClick: (item) => this.setState({ removeReferralModal: { ...item } }),
                                                condition: (item) => {
                                                    if (item.recipientClinic !== this.props.selectedClinic && isAdmin)
                                                        return true;
                                                    else
                                                        return false;
                                                }
                                            }

                                        ]
                                    }
                                    params={params}
                                    sortField={params.sortField}
                                    sortType={params.sortType}
                                    updateSort={this.updateSort}
                                    updateParams={this.updateParams}
                                    updateMultipleParams={this.updateMultipleParams}

                                ></ListBuilder>
                                <DeleteModal
                                    lang={this.props.lang}
                                    isOpen={this.state.deleteModal}
                                    toggle={() => this.setState({ deleteModal: null })}
                                    handler={() => { this.delete(this.state.deleteModal._id); this.setState({ deleteModal: null }) }}
                                >
                                    {'Delete referral'.translate(this.props.lang)} <strong>{this.state.deleteModal && this.state.deleteModal.title}</strong>?
                                </DeleteModal>

                            </div>
                        </Col>
                    </Row>

                </Container>
                {
                    this.state.removeReferralModal ?
                        <DeleteModal
                            zIndex={99999}
                            lang={this.props.lang}
                            isOpen={this.state.removeReferralModal}
                            toggle={() => this.setState({ removeReferralModal: null })}
                            handler={() => { this.removeReferralFromList(this.state.removeReferralModal._id); }}
                        >
                            {'Do you want to delete the referral from the list of this clinic?'.translate(this.props.lang)}
                        </DeleteModal>
                        :
                        null
                }
                {
                    this.state.messageInfo ?
                        <InfoModal
                            isOpen={this.state.messageInfo}

                            toggle={() => this.setState({ messageInfo: null })}
                            header={'Info'.translate(this.props.lang)}
                            info={this.state.messageInfo}
                            buttons={[
                                <Button color='primary' onClick={() => {
                                    this.setState({
                                        messageInfo: null,
                                    })

                                }}>{'Close'.translate(this.props.lang)}</Button>
                            ]}
                        />
                        :
                        null

                }
                {
                    this.state.transferModal ?
                        eConnectEnabled ?
                            <ConsultationModal
                                {...this.props}
                                isOpen={this.state.transferModal}
                                referral={this.state.transferModal}
                                closeModal={() => this.setState({ transferModal: null }, () => {
                                    this.get()
                                })}
                            />
                            :
                            <Modal isOpen={this.state.transferModal} centered>
                                <ModalHeader toggle={() => this.setState({ transferModal: !this.state.transferModal, selectedDoctor: -1 })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ transferModal: !this.state.transferModal, selectedDoctor: -1 })}><Isvg src={xIcon} /></button>}>
                                    <h4>{'Transfer modal'.translate(this.props.lang)}</h4>
                                </ModalHeader>
                                <ModalBody>
                                    <FormGroup>
                                        <Label>{'Transfer from'.translate(this.props.lang)}</Label>
                                        <Input type='text' disabled
                                            value={this.state.transferModal.doctorName ? this.state.transferModal.doctorName : 'Unassigned'.translate(this.props.lang)} />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>{'Transfer to'.translate(this.props.lang)}</Label>
                                        <Input type='select'
                                            value={this.state.selectedDoctor}
                                            onChange={(e) => {
                                                this.setState({ selectedDoctor: e.target.value })
                                            }}
                                        >
                                            <option value={-1}>{'None'.translate(this.props.lang)}</option>
                                            {

                                                this.state.subAccounts && this.state.subAccounts.filter(el => el._id != this.state.transferModal.patientId).sort((a, b) => {
                                                    const nameA = a.name.toLowerCase();
                                                    const nameB = b.name.toLowerCase();
                                                    if (nameA < nameB) {
                                                        return -1
                                                    }
                                                    if (nameA > nameB) {
                                                        return 1
                                                    }
                                                }).map((item, idx) => {
                                                    if (item._id != this.state.transferModal.recipientUser)
                                                        return (
                                                            <option value={item._id}>{item.name}</option>
                                                        )
                                                })
                                            }
                                        </Input>
                                    </FormGroup>

                                </ModalBody>
                                <ModalFooter className='buttons-right-blue'>
                                    <Button color="primary" disabled={this.state.selectedDoctor == -1 ? true : false} onClick={() => this.transferDropIn(this.state.transferModal)}>{'Transfer'.translate(this.props.lang)}</Button>

                                    <Button color="primary" onClick={() => this.setState({ transferModal: !this.state.transferModal, selectedDoctor: -1 })}>{'Close'.translate(this.props.lang)}</Button>

                                </ModalFooter>
                            </Modal>
                        :
                        null
                }
                {
                    this.state.patientProfile ?
                        <Modal isOpen={this.state.patientProfile} centered>
                            <ModalHeader toggle={() => this.setState({ patientProfile: !this.state.patientProfile })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ patientProfile: !this.state.patientProfile })}><Isvg src={xIcon} /></button>}>
                                <h4>{'Patient profile'.translate(this.props.lang)}</h4>
                            </ModalHeader>
                            <ModalBody>
                                <FormGroup>
                                    <Label>{'Social security number'.translate(this.props.lang)}</Label>
                                    <Input type='text' disabled
                                        value={this.state.patientProfile.patient?.socialSecurityNumber} />
                                </FormGroup>
                                <FormGroup>
                                    <Label>{'Name'.translate(this.props.lang)}</Label>
                                    <Input type='text' disabled
                                        value={this.state.patientProfile.patient?.name} />
                                </FormGroup>
                                <FormGroup>
                                    <Label>{'Email'.translate(this.props.lang)}</Label>
                                    <Input type='text' disabled
                                        value={this.state.patientProfile.patient?.parents ? this.state.patientProfile.patient?.parents[0].email : this.state.patientProfile.patient?.email} />
                                </FormGroup>
                                <FormGroup>
                                    <Label>{'Phone'.translate(this.props.lang)}</Label>
                                    <Input type='text' disabled
                                        value={this.state.patientProfile.patient?.parents ? this.state.patientProfile.patient?.parents[0].phone : this.state.patientProfile.patient?.phone} />
                                </FormGroup>
                                <FormGroup>
                                    <Label>{'Address'.translate(this.props.lang)}</Label>
                                    <Input type='text' disabled
                                        value={this.state.patientProfile.patient?.parents ? this.state.patientProfile.patient?.parents[0].address : this.state.patientProfile.patient?.street + ',' + ' ' + this.state.patientProfile.patient?.zip + ' ' + this.state.patientProfile.patient?.city} />
                                </FormGroup>


                            </ModalBody>
                            <ModalFooter className='buttons-right-blue'>
                                <Button color="primary" onClick={() => this.setState({ patientProfile: !this.state.patientProfile })}>{'Close'.translate(this.props.lang)}</Button>

                            </ModalFooter>
                        </Modal>

                        : null
                }

                {
                    this.state.referral ?
                        <ReferralContent
                            {...this.props}
                            data={this.state.referral}
                            screen={'drop-in'}
                            doctorPage={true}
                            enterprise={enterprise}
                            deleteCase={() => { this.setState({ deleteCase: true }) }}
                            closeCase={() => { this.setState({ closeCase: true }) }}
                            removeReferralModal={(item) => this.setState({ removeReferralModal: { ...item } })}
                            priorityModal={(referral) => {
                                this.setState({
                                    priorityModal: { ...referral }
                                })
                            }}
                            pickUp={() => {
                                if (this.state.referral && this.state.referral.patient && this.state.referral.patient?.socialSecurityNumber == this.props.uData.socialSecurityNumber) {
                                    this.setState({ messageInfo: 'Unable to pick up this case. Both participants are using the same account.'.translate(this.props.lang) })
                                    return;
                                }
                                this.setState({ conversationModal: this.state.referral, conversationForm: { name: this.state.referral.patient?.referralNumber + '-' + pad(this.state.referral.conversations.length + 1, 2) } })

                            }}
                            closeReferralContent={(data) => {
                                if (this.state.referral) {
                                    this.setState({
                                        referral: null
                                    })
                                }
                            }}
                        />
                        :
                        null
                }

                {
                    this.state.referral && false ?
                        <Modal isOpen={this.state.referral} centered toggle={() => this.setState({ referral: !this.state.referral })} size="lg" className="modal-footer-modifed modal-header-unpaid">
                            <ModalHeader toggle={() => this.setState({ referral: !this.state.referral })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ referral: !this.state.referral })}><Isvg src={xIcon} /></button>}>
                                <div className='unpaid-header'>
                                    <p>{'Referral preview'.translate(this.props.lang)}</p>

                                </div>
                            </ModalHeader>

                            <ModalBody>
                                <div className='unpaid-button-container'>
                                    {
                                        this.state.referral.referralIsPaid || (this.state.referral.event && this.state.referral.event.appointmentPaid) || this.state.referral.questionaryPrice == 0 ?
                                            null
                                            :
                                            <button>
                                                <Isvg src={billInfoIcon} />
                                                {'UNPAID'.translate(this.props.lang)}
                                            </button>
                                    }
                                </div>
                                {
                                    this.state.referral.priority ?
                                        <div className={
                                            this.state.referral.priority == 1 ? 'priority-circle p-red nohover' :
                                                this.state.referral.priority == 2 ? 'priority-circle p-orange nohover' :
                                                    this.state.referral.priority == 3 ? 'priority-circle p-yellow nohover' :
                                                        this.state.referral.priority == 4 ? 'priority-circle p-black nohover' :
                                                            'priority-circle p-green nohover'
                                        }>
                                            {this.state.referral.priority}
                                        </div>
                                        :
                                        null
                                }
                                <div dangerouslySetInnerHTML={{ __html: this.props.lang == 'se' ? this.state.referral.referralContent.protectReferralFiles(this.props.uData ? this.props.uData.mediaToken : '', this.state.referral._id) : this.state.referral.referralContentEn ? this.state.referral.referralContentEn.protectReferralFiles(this.props.uData ? this.props.uData.mediaToken : '', this.state.referral._id) : this.state.referral.referralContent.protectReferralFiles(this.props.uData ? this.props.uData.mediaToken : '', this.state.referral._id) }} onChange={() => { }}></div>
                                {
                                    this.state.referral && this.state.referral.answers && this.state.referral.questionaryData && this.state.referral.questionaryData.items ?
                                        <div style={{ marginBottom: 10 }}>
                                            <Button color='primary' onClick={() => { this.setState({ previewAnswersReferralModal: this.state.referral }) }}>{'Preview answers'.translate(this.props.lang)}</Button>
                                        </div>
                                        :
                                        null
                                }
                                <div>
                                    <p> <strong>{'Status:'.translate(this.props.lang)}</strong> {humanReadable[this.state.referral.status]}</p>
                                    {this.state.referral.reason ?
                                        <p> <strong>{'Reason:'.translate(this.props.lang)}</strong> {this.state.referral.reason}</p>
                                        :
                                        null}
                                    {this.state.referral.patientReservedTimestamp ?
                                        <p> <strong>{'Scheduled time:'.translate(this.props.lang)}</strong> {this.props.getStringDateTs(this.state.referral.patientReservedTimestamp, `${this.props.dateFormat} HH:mm`)/*moment.unix(this.state.referral.patientReservedTimestamp).format(`${this.props.dateFormat} HH:mm`)*/}</p>
                                        :
                                        null}

                                    {
                                        this.state.referral.status == 'patient-requested-another-time' ?
                                            <p> <strong>{'Requested time:'.translate(this.props.lang)}</strong> {this.props.getStringDateTs(this.state.referral.patientRequestedTimestamp, `${this.props.dateFormat} HH:mm`)/*moment.unix(this.state.referral.patientRequestedTimestamp).format(`${this.props.dateFormat} HH:mm`)*/}</p>

                                            :
                                            null
                                    }

                                </div>

                            </ModalBody>
                            {this.state.referral.status == 'waiting-for-approval' || this.state.referral.status == 'approved-by-region-manager' || this.state.referral.status == 'rejected-by-region-manager' ?
                                <ModalFooter style={{ margin: 'unset', justifyContent: 'flex-end', display: 'flex', width: '100%' }}>

                                    {!this.state.referral.forwardRecipientClinic && !this.state.referral.forward ?
                                        <>
                                            <Button color="success" onClick={() => this.allowReferral()}><><i className="fa fa-check" />{'Allow'.translate(this.props.lang)}</></Button>
                                            <Button color="danger" onClick={() => this.setState({ disallowForm: this.state.referral })}><i className="fa fa-close" /> {'Disallow'.translate(this.props.lang)} </Button>


                                        </>
                                        :
                                        null


                                    }

                                    {
                                        this.state.referral.forwardRecipientClinic && this.state.referral.forward ?
                                            <>
                                                <Button color="success" onClick={() => this.acceptReferral()}><i className="fa fa-check" /> {'Accept'.translate(this.props.lang)}</Button>
                                                <Button color="danger" onClick={() => this.rejectReferral({})}><i className="fa fa-close" /> {'Reject'.translate(this.props.lang)} </Button>
                                            </>

                                            :
                                            null
                                    }


                                    <PrintHelper lang={this.props.lang} html={this.props.lang == 'se' ? this.state.referral.referralContent : this.state.referral.referralContentEn ? this.state.referral.referralContentEn : this.state.referral.referralContent} />
                                    <Button color="primary" onClick={() => this.setState({ referral: null })}> {'Close'.translate(this.props.lang)} </Button>

                                </ModalFooter>
                                :
                                this.state.referral.status == 'patient-requested-another-time' ?
                                    <ModalFooter>

                                        <Button color="success" onClick={() => this.acceptRequestedTime()}><i className="fa fa-check" /> {'Accept requested time'.translate(this.props.lang)}</Button>
                                        <Button color="danger" onClick={() => this.setState({ scheduleForm: this.state.referral })}><i className="fa fa-close" /> {'Reject requested time'.translate(this.props.lang)} </Button>
                                        <PrintHelper lang={this.props.lang} html={this.props.lang == 'se' ? this.state.referral.referralContent : this.state.referral.referralContentEn ? this.state.referral.referralContentEn : this.state.referral.referralContent} />

                                        <Button color="primary" onClick={() => this.setState({ referral: null })}> {'Close'.translate(this.props.lang)} </Button>

                                    </ModalFooter>
                                    :
                                    <ModalFooter style={{ margin: 'unset', justifyContent: 'flex-end', display: 'flex', width: '100%' }} className="buttons-right-blue">
                                        <Button onClick={() => {
                                            this.setState({
                                                priorityModal: { ...this.state.referral }
                                            })
                                        }}>{'Set priority'.translate(this.props.lang)}</Button>
                                        {
                                            this.state.referral.status == 'waiting' && this.props.uData && this.props.uData.profession && this.props.uData.profession[this.props.selectedGroup] && this.state.referral.professionList && this.state.referral.professionList.filter(profession => {
                                                if (typeof this.props.uData.profession[this.props.selectedGroup] !== 'string') {
                                                    return this.props.uData.profession[this.props.selectedGroup].indexOf(profession) !== -1
                                                } else {
                                                    return profession == this.props.uData.profession[this.props.selectedGroup]
                                                }

                                            }).length ?
                                                <Button onClick={() => {
                                                    if (this.state.referral && this.state.referral.patient && this.state.referral.patient?.socialSecurityNumber == this.props.uData.socialSecurityNumber) {
                                                        this.setState({ messageInfo: 'Unable to pick up this case. Both participants are using the same account.'.translate(this.props.lang) })
                                                        return;
                                                    }
                                                    this.setState({ conversationModal: this.state.referral, conversationForm: { name: this.state.referral.patient?.referralNumber + '-' + pad(this.state.referral.conversations.length + 1, 2) } })
                                                }} color="success">{'Pick up'.translate(this.props.lang)}</Button>
                                                :
                                                null
                                        }
                                        {
                                            this.state.referral.status == 'scheduled' || this.state.referral.status == 'patient-rejected' || this.state.referral.status == 'not-booked' ?
                                                <Button onClick={() => this.closeReferral()} color="danger">{'Close case'.translate(this.props.lang)}</Button>
                                                :
                                                null
                                        }


                                        <PrintHelper lang={this.props.lang} html={this.props.lang == 'se' ? this.state.referral.referralContent : this.state.referral.referralContentEn ? this.state.referral.referralContentEn : this.state.referral.referralContent} />

                                        <Button color="primary" onClick={() => this.setState({ referral: null })}> {'Close'.translate(this.props.lang)} </Button>

                                    </ModalFooter>

                            }

                        </Modal>

                        :
                        null
                }
                {
                    this.state.closeCase ?
                        <Modal isOpen={this.state.closeCase} centered>
                            <ModalBody className="delete-modal">
                                <p className="message">{`Are you sure you want to close the case?`.translate(this.props.lang)}</p>
                            </ModalBody>
                            <ModalFooter className='buttons-right-blue'>
                                <Button color="primary" style={{ margin: 10, minWidth: 100 }} onClick={() => this.setState({ closeCase: false }, () => this.closeReferral())}>{'Yes'.translate(this.props.lang)}</Button>
                                <Button color="primary" style={{ margin: 10, minWidth: 100 }} onClick={() => this.setState({ closeCase: false })}>{'No'.translate(this.props.lang)}</Button>

                            </ModalFooter>
                        </Modal>
                        : null
                }
                {
                    this.state.deleteCase ?
                        <Modal isOpen={this.state.deleteCase} centered>
                            <ModalBody className="delete-modal">
                                <p className="message">{`Are you sure you want to delete the case?`.translate(this.props.lang)}</p>
                            </ModalBody>
                            <ModalFooter className='buttons-right-blue'>
                                <Button color="primary" style={{ margin: 10, minWidth: 100 }} onClick={() => this.setState({ deleteCase: false }, () => this.deleteReferral())}>{'Yes'.translate(this.props.lang)}</Button>
                                <Button color="primary" style={{ margin: 10, minWidth: 100 }} onClick={() => this.setState({ deleteCase: false })}>{'No'.translate(this.props.lang)}</Button>

                            </ModalFooter>
                        </Modal>
                        : null
                }
                {
                    this.state.previewAnswersReferralModal ?
                        <Modal isOpen={this.state.previewAnswersReferralModal} centered toggle={() => this.setState({ previewAnswersReferralModal: !this.state.previewAnswersReferralModal })} size="lg">
                            <ModalHeader toggle={() => this.setState({ previewAnswersReferralModal: !this.state.previewAnswersReferralModal })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ previewAnswersReferralModal: !this.state.previewAnswersReferralModal })}><Isvg src={xIcon} /></button>}>{'Form preview'.translate(this.props.lang)}</ModalHeader>

                            <ModalBody>

                                <FormPreview {...this.props} referral={{ name: this.state.previewAnswersReferralModal.name, description: this.state.previewAnswersReferralModal.description, intelligentReferral: this.state.previewAnswersReferralModal.intelligentReferral }} sections={this.generateSections(this.state.previewAnswersReferralModal.questionaryData.items)} answers={this.state.previewAnswersReferralModal.answers} disabledForm={true} hidePreview={true}></FormPreview>

                            </ModalBody>
                        </Modal>
                        :
                        null
                }
                {
                    this.state.disallowForm ?
                        <FormBuilder selectedGroup={this.props.selectedGroup} selectedClinic={this.props.selectedClinic} countryTwoLetter={this.props.countryTwoLetter} country={this.props.country} dateFormat={this.props.dateFormat}
                            getStringDateTs={this.props.getStringDateTs}
                            lang={this.props.lang}
                            onSubmit={(data) => this.disallowReferral(data)}
                            isOpen={this.state.disallowForm}
                            toggle={() => this.setState({ disallowForm: null })}
                            title={'Disallow referral'.translate(this.props.lang)}
                            buttonText={'Submit'.translate(this.props.lang)}
                            fields={[
                                {
                                    type: 'row',
                                    children: [
                                        {
                                            type: 'col',
                                            width: {
                                                lg: 12,
                                                sm: 12,
                                                xs: 12
                                            },
                                            children: [
                                                {
                                                    type: 'textarea',
                                                    name: 'reason',
                                                    label: 'Give reason'.translate(this.props.lang),
                                                    validate: [required('Reason is required!'.translate(this.props.lang))]
                                                },
                                            ]
                                        },

                                    ]
                                }
                            ]}
                        ></FormBuilder>
                        :
                        null
                }

                {
                    this.state.returnForm ?
                        <FormBuilder selectedGroup={this.props.selectedGroup} selectedClinic={this.props.selectedClinic} countryTwoLetter={this.props.countryTwoLetter} country={this.props.country} dateFormat={this.props.dateFormat}
                            getStringDateTs={this.props.getStringDateTs}
                            lang={this.props.lang}
                            onSubmit={(data) => this.returnForUpdate(data)}
                            isOpen={this.state.returnForm}
                            toggle={() => this.setState({ returnForm: null })}
                            title={'Return for referral update'.translate(this.props.lang)}
                            buttonText={'Submit'.translate(this.props.lang)}
                            fields={[
                                {
                                    type: 'row',
                                    children: [
                                        {
                                            type: 'col',
                                            width: {
                                                lg: 12,
                                                sm: 12,
                                                xs: 12
                                            },
                                            children: [
                                                {
                                                    type: 'textarea',
                                                    name: 'reason',
                                                    label: 'Reason'.translate(this.props.lang),
                                                    validate: [required('Reason is required!'.translate(this.props.lang))]
                                                },
                                            ]
                                        },

                                    ]
                                }
                            ]}
                        ></FormBuilder>
                        :
                        null
                }

                {
                    this.state.allowForm ?
                        <FormBuilder selectedGroup={this.props.selectedGroup} selectedClinic={this.props.selectedClinic} countryTwoLetter={this.props.countryTwoLetter} country={this.props.country} dateFormat={this.props.dateFormat}
                            getStringDateTs={this.props.getStringDateTs}
                            lang={this.props.lang}
                            onSubmit={(data) => this.allowReferral(data)}
                            isOpen={this.state.allowForm}
                            toggle={() => this.setState({ allowForm: null })}
                            title={'Allow referral'.translate(this.props.lang)}
                            buttonText={'Submit'.translate(this.props.lang)}
                            fields={[
                                {
                                    type: 'row',
                                    children: [
                                        {
                                            type: 'col',
                                            width: {
                                                lg: 6,
                                                sm: 12,
                                                xs: 12
                                            },
                                            children: [
                                                {
                                                    type: 'date',
                                                    name: 'date',
                                                    label: 'Date'.translate(this.props.lang),
                                                    validate: [required('Date is required!'.translate(this.props.lang))]
                                                },
                                            ]
                                        },
                                        {
                                            type: 'col',
                                            width: {
                                                lg: 6,
                                                sm: 12,
                                                xs: 12
                                            },
                                            children: [
                                                {
                                                    type: 'time',
                                                    name: 'time',
                                                    label: 'Time'.translate(this.props.lang),
                                                    validate: [required('Time is required!'.translate(this.props.lang))]
                                                },
                                            ]
                                        },
                                    ]
                                }
                            ]}
                        ></FormBuilder>
                        :
                        null
                }


                {
                    this.state.scheduleForm ?
                        <FormBuilder selectedGroup={this.props.selectedGroup} selectedClinic={this.props.selectedClinic} countryTwoLetter={this.props.countryTwoLetter} country={this.props.country} dateFormat={this.props.dateFormat}
                            getStringDateTs={this.props.getStringDateTs}
                            lang={this.props.lang}
                            onSubmit={(data) => this.rejectRequestedTime(data)}
                            isOpen={this.state.scheduleForm}
                            toggle={() => this.setState({ scheduleForm: null })}
                            title={'Schedule new time'.translate(this.props.lang)}
                            buttonText={'Submit'.translate(this.props.lang)}
                            fields={[
                                {
                                    type: 'row',
                                    children: [
                                        {
                                            type: 'col',
                                            width: {
                                                lg: 6,
                                                sm: 12,
                                                xs: 12
                                            },
                                            children: [
                                                {
                                                    type: 'date',
                                                    name: 'date',
                                                    label: 'Date'.translate(this.props.lang),
                                                    validate: [required('Date is required!'.translate(this.props.lang))]
                                                },
                                            ]
                                        },
                                        {
                                            type: 'col',
                                            width: {
                                                lg: 6,
                                                sm: 12,
                                                xs: 12
                                            },
                                            children: [
                                                {
                                                    type: 'time',
                                                    name: 'time',
                                                    label: 'Time'.translate(this.props.lang),
                                                    validate: [required('Time is required!'.translate(this.props.lang))]
                                                },
                                            ]
                                        },
                                    ]
                                }
                            ]}
                        ></FormBuilder>
                        :
                        null
                }


                {
                    this.state.selectClinicModal ?
                        <Modal isOpen={this.state.selectClinicModal} centered toggle={() => this.setState({ selectClinicModal: !this.state.selectClinicModal })} size="xl">
                            <ModalHeader>
                                <h4>{'Search Curoflow database'.translate(this.props.lang)}</h4>
                            </ModalHeader>
                            <ModalBody>
                                <ReferralClinics {...this.props} patientData={this.state.patientData} searchFor={this.state.referral.articleName} includeTags={this.state.tags} importClinic={(clinic) => {
                                    this.setState({
                                        checkForward: clinic
                                    })
                                    //this.forward(clinic);
                                    /*let addressLines = [];
                                    if (clinic.postAddress) {
                                        addressLines = this.splitAddress(clinic.postAddress);
                                    } else {
                                        addressLines = [clinic.street, clinic.zip + ' ' + clinic.city, ''];
                                    }
                 
                                    this.setState({
                                        recipientData: {
                                            ...clinic,
                                            name: clinic.vantetiderName ? clinic.vantetiderName : clinic.name,
                                            address1: addressLines[0],
                                            address2: addressLines[1],
                                            address3: addressLines[2]
                                        },
                                        selectClinicModal: null
                                    })*/
                                }}></ReferralClinics>
                            </ModalBody>
                        </Modal>
                        :
                        null
                }

                {
                    this.state.checkForward ?
                        <Modal isOpen={this.state.checkForward} centered toggle={() => this.setState({ checkForward: !this.state.checkForward })} size="xl">
                            {/* <ModalHeader>
                                <h4></h4>
                            </ModalHeader> */}
                            <ModalBody>
                                <p>{'Are you shure that you want to continue?'.translate(this.props.lang)} {'This referral will be sent to'.translate(this.props.lang)} {this.state.checkForward.name}.</p>
                            </ModalBody>
                            <ModalFooter className='buttons-right-blue'>
                                <Button style={{ minWidth: 100 }} onClick={() => this.forward(this.state.checkForward)}>{'Continue'.translate(this.props.lang)}</Button>
                            </ModalFooter>
                        </Modal>
                        :
                        null
                }
                {
                    this.state.managerModal ?
                        <Modal isOpen={this.state.managerModal} centered toggle={() => this.setState({ managerModal: !this.state.managerModal })} >
                            <ModalHeader>
                                <h4>{'Request for payment'.translate(this.props.lang)}</h4>
                            </ModalHeader>
                            <ModalBody>
                                <div>
                                    <div class="form-check">
                                        <input type="checkbox" class="form-check-input" onChange={() => this.setState({ changeButton: !this.state.changeButton })} />
                                        <label class="form-check-label" for="paymentChecked" >Accept payment</label>
                                    </div>
                                    <Button onClick={this.state.changeButton ? () => { this.managerMail(this.state.managerModal), this.setState({ changeButton: !this.state.changeButton }) } : () => this.setState({ signinModal: this.state.managerModal, managerModal: false })}>{this.state.changeButton ? 'Sent to region manager' : 'Sign and allow'}</Button>


                                </div>
                            </ModalBody>
                        </Modal>
                        :
                        null
                }

                {
                    this.state.conversationModal ?
                        <InfoModal
                            isOpen={this.state.conversationModal}
                            onClose={(item) => {
                                this.setState({
                                    conversationModal: item
                                })
                            }}
                            close={true}
                            toggle={() => this.setState({ conversationModal: !this.state.conversationModal })}
                            header={'Info'.translate(this.props.lang)}
                            info={'Do you want to pick up this case?'.translate(this.props.lang)}
                            buttons={[
                                <Button color="primary" disabled={this.state.loadingAssign} style={{ margin: 10 }} onClick={() => this.assignReferral(this.state.conversationModal)}>{'Yes'.translate(this.props.lang)}</Button>,
                                <Button color="primary" style={{ margin: 10 }} onClick={() => this.setState({ conversationModal: false })}>{'No'.translate(this.props.lang)}</Button>


                            ]}
                        />
                        :
                        null
                }


                {
                    this.state.error ?

                        <ErrorModal lang={this.props.lang}
                            isOpen={this.state.error}
                            toggle={() => this.setState({ error: null })}

                        >
                            {this.state.error.translate(this.props.lang)}
                        </ErrorModal>

                        :
                        null
                }
                {
                    this.state.signinModal ?
                        <Modal isOpen={this.state.signinModal} centered toggle={() => this.setState({ signinModal: !this.state.signinModal })} >
                            <ModalHeader>
                                <h4>{'Sign in with your NET ID'.translate(this.props.lang)}</h4>
                            </ModalHeader>
                            <ModalBody>
                                <Button onClick={() => this.allowReferral({})}>{'Continue'.translate(this.props.lang)}</Button>
                            </ModalBody>
                        </Modal>
                        : null
                }

                {
                    this.state.priorityModal ?
                        <Modal isOpen={this.state.priorityModal} centered >

                            <ModalBody>
                                <div className='priority-circle-row'>
                                    <div className={this.state.priorityModal.priority == 1 ? 'priority-circle p-red red-active' : 'priority-circle p-red'} onClick={() => {
                                        let priorityModal = this.state.priorityModal
                                        priorityModal.priority = 1
                                        this.setState({
                                            priorityModal
                                        })
                                    }}>1</div>
                                    <div className={this.state.priorityModal.priority == 2 ? 'priority-circle p-orange orange-active' : 'priority-circle p-orange'} onClick={() => {
                                        let priorityModal = this.state.priorityModal
                                        priorityModal.priority = 2
                                        this.setState({
                                            priorityModal
                                        })
                                    }}>2</div>
                                    <div className={this.state.priorityModal.priority == 3 ? 'priority-circle p-yellow yellow-active' : 'priority-circle p-yellow'} onClick={() => {
                                        let priorityModal = this.state.priorityModal
                                        priorityModal.priority = 3
                                        this.setState({
                                            priorityModal
                                        })
                                    }}>3</div>
                                    <div className={this.state.priorityModal.priority == 4 ? 'priority-circle p-black black-active' : 'priority-circle p-black'} onClick={() => {
                                        let priorityModal = this.state.priorityModal
                                        priorityModal.priority = 4
                                        this.setState({
                                            priorityModal
                                        })
                                    }}>4</div>
                                    <div className={this.state.priorityModal.priority == 5 ? 'priority-circle p-green green-active' : 'priority-circle p-green'} onClick={() => {
                                        let priorityModal = this.state.priorityModal
                                        priorityModal.priority = 5
                                        this.setState({
                                            priorityModal
                                        })
                                    }}>5</div>
                                </div>
                            </ModalBody>
                            <ModalFooter className='buttons-right-blue'>
                                <Button onClick={() => {
                                    this.priorityForDropIn(this.state.priorityModal)
                                    this.setState({
                                        priorityModal: null
                                    })
                                }}>{'Confirm'.translate(this.props.lang)}</Button>
                                <Button color='primary'
                                    disabled={!this.state.priorityModal.priority}
                                    onClick={() => {
                                        let priorityModal = this.state.priorityModal
                                        priorityModal.priority = null;
                                        this.setState({
                                            priorityModal
                                        })
                                    }}>{'Remove'.translate(this.props.lang)}</Button>
                                <Button onClick={() => {
                                    this.setState({
                                        priorityModal: null
                                    })
                                }}>{'Cancel'.translate(this.props.lang)}</Button>
                            </ModalFooter>
                        </Modal>
                        : null
                }

                {
                    this.state.scheduleReferral ?
                        <Modal isOpen={this.state.scheduleReferral} centered>
                            {/* <ModalHeader style={{ margin: 'auto' }}>{'Schedule case'.translate(this.props.lang)}</ModalHeader> */}
                            <ModalBody className='delete-modal'>
                                {/* <Isvg src={QuestionMark} /> */}
                                <p className='message'>{'Do you want to schedule the selected referral?'.translate(this.props.lang)}</p>
                            </ModalBody>
                            <ModalFooter className='buttons-right-blue'>
                                <Button color='primary' style={{ margin: 10, minWidth: 100 }} onClick={() => {
                                    this.props[0].history.push(`/my-data/referrals/working-hours`)
                                }}>{'Yes'.translate(this.props.lang)}</Button>
                                <Button color='primary' style={{ margin: 10, minWidth: 100 }} onClick={() => this.setState({
                                    scheduleReferral: false
                                })}>{'No'.translate(this.props.lang)}</Button>
                            </ModalFooter>
                        </Modal>
                        : null
                }

                {
                    this.state.referralNote ?
                        <Modal isOpen={this.state.referralNote} centered>
                            <ModalHeader style={{ margin: 'auto' }}>{'Note'.translate(this.props.lang)}</ModalHeader>
                            <ModalBody className="delete-modal">
                                <Input type="textarea" style={{ height: 100 }} value={this.state.referralNote.note} onChange={(e) => {
                                    let referralNote = this.state.referralNote;
                                    referralNote.note = e.target.value;
                                    this.setState({ referralNote })
                                }} />
                            </ModalBody>
                            <ModalFooter className='buttons-right-blue'>
                                <Button color="primary" style={{ margin: 10 }} onClick={() => {
                                    this.updateReferralNote(this.state.referralNote)
                                }}>{'Save'.translate(this.props.lang)}</Button>

                                <Button color="primary" style={{ margin: 10 }} onClick={() => this.setState({ referralNote: null })

                                }>{'Close'.translate(this.props.lang)}</Button>

                            </ModalFooter>
                        </Modal>


                        :
                        null
                }

                {
                    this.state.referralNote ?
                        <Modal isOpen={this.state.referralNote} centered>
                            <ModalHeader style={{ margin: 'auto' }}>{'Note'.translate(this.props.lang)}</ModalHeader>
                            <ModalBody className="delete-modal">
                                <Input type="textarea" style={{ height: 100 }} value={this.state.referralNote.note} onChange={(e) => {
                                    let referralNote = this.state.referralNote;
                                    referralNote.note = e.target.value;
                                    this.setState({ referralNote })
                                }} />
                            </ModalBody>
                            <ModalFooter className='buttons-right-blue'>
                                <Button color="primary" style={{ margin: 10 }} onClick={() => {
                                    this.updateReferralNote(this.state.referralNote)
                                }}>{'Save'.translate(this.props.lang)}</Button>

                                <Button color="primary" style={{ margin: 10 }} onClick={() => this.setState({ referralNote: null })

                                }>{'Close'.translate(this.props.lang)}</Button>

                            </ModalFooter>
                        </Modal>


                        :
                        null
                }

            </div >
        );
    }
}

const selector = formValueSelector('modalForm');

export default connect(state => {
    return { group: selector(state, 'group') }

}, {
    changeSubgroup: value => change("modalForm", "subgroup", value),

})(Page(ReceivedReferrals));