import React, { Component } from 'react';
// import { Link, Redirect } from 'react-router-dom';

import Isvg from 'react-inlinesvg';

import { FormGroup, Label, Input, FormFeedback, FormText, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import infoIcon from "../../../assets/svg/link-new-tab.svg";
import { isStringAValidNumber } from '../../TwoFactorAuthModal';

// import { Calendar } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
// import * as locales from 'react-date-range/dist/locale';

// import moment from 'moment';
import { API_ENDPOINT, CHILD_AGE_LIMIT } from '../../../constants';

// import refreshIcon from "../../../assets/svg/refresh_icon.svg";

import { v4 as uuid } from 'uuid';

function pad(n, width, z) {
    z = z || '0';
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
}

export function calculateAgeFromSSN(ssn, country) {
    if (!country) {
        if (typeof window !== 'undefined' && localStorage && localStorage?.getItem?.('country')) country = localStorage?.getItem?.('country');
        else country = 'sweden';
    };

    if (!ssn || !isStringAValidNumber(ssn)) return null;

    let day;
    let month;
    let year;

    if (country === 'denmark') {
        if (ssn.length < 6) return null;
        day = Number(`${ssn[0]}${ssn[1]}`);
        month = Number(`${ssn[2]}${ssn[3]}`);
        const yearLastDigits = Number(`${ssn[4]}${ssn[5]}`);
        const nowYear = new Date().getFullYear();
        const nowYearLastDigits = `${String(nowYear)[2]}${String(nowYear)[3]}`;
        if (yearLastDigits === 0) year = 2000;
        else {
            if (yearLastDigits >= +nowYearLastDigits) year = Number(`19${yearLastDigits < 10 ? '0' + yearLastDigits : yearLastDigits}`);
            else year = Number(`20${yearLastDigits < 10 ? '0' + yearLastDigits : yearLastDigits}`);
        }
    } else if (country === 'sweden') {
        if (ssn.length < 8) return null;
        year = parseInt(`${ssn[0]}${ssn[1]}${ssn[2]}${ssn[3]}`);
        month = parseInt(`${ssn[4]}${ssn[5]}`);
        day = parseInt(`${ssn[6]}${ssn[7]}`);
    }
    // Other countries

    if (day && month && year) {
        let date = new Date(year, month - 1, day);
        let years = (new Date().getTime() / 1000 - date.getTime() / 1000) / 31556926;
        return years;
    };

    return null;
}


export function isSocialSecurityNumberValid(answer, country) {
    if (!country) {
        if (typeof window !== 'undefined' && localStorage && localStorage?.getItem?.('country')) country = localStorage?.getItem?.('country');
        else country = 'sweden';
    }

    if (!answer) return false;

    if (country === 'denmark') {
        if (answer.length !== 10) return false;

        const day = Number(`${answer[0]}${answer[1]}`);
        const month = Number(`${answer[2]}${answer[3]}`);
        const yearLastDigits = Number(`${answer[4]}${answer[5]}`);

        if (!day || !month) return false;
        if (day < 1 || day > 31) return false;
        if (month < 1 || month > 12) return false;

        const nowYear = new Date().getFullYear();
        const nowYearLastDigits = `${String(nowYear)[2]}${String(nowYear)[3]}`;

        let year;
        if (yearLastDigits === 0) year = 2000;
        else {
            if (yearLastDigits >= +nowYearLastDigits) year = Number(`19${yearLastDigits < 10 ? '0' + yearLastDigits : yearLastDigits}`);
            else year = Number(`20${yearLastDigits < 10 ? '0' + yearLastDigits : yearLastDigits}`);
        }

        if (year > nowYear || year < (nowYear - 150)) return false;

        let daysInMoth = new Date(year, month, 0).getDate();

        if (day > daysInMoth) return false;

        return true;

    } else if (country === 'sweden') {
        if (answer.length !== 12) return false;

        let year = parseInt(`${answer[0]}${answer[1]}${answer[2]}${answer[3]}`);
        let month = parseInt(`${answer[4]}${answer[5]}`);
        let day = parseInt(`${answer[6]}${answer[7]}`);
        //let date = new Date(year, month - 1, day);
        if (month < 1 || month > 12) {
            return false;
        }

        if (day < 1 || day > 31) {
            return false;
        }
        let yearNow = new Date().getFullYear()
        let limitYearUp = 2200;
        let limitYearDown = 1850;
        if (yearNow) {
            limitYearUp = yearNow;
            limitYearDown = yearNow - 150;
        }

        if (year < limitYearDown || year > limitYearUp) {
            return false;
        }

        let daysInMoth = new Date(year, month, 0).getDate();

        if (day > daysInMoth) {
            return false;
        }


        return true;
    } else if (country === 'uk') {
        if (answer.length !== 12) return false;
        // if (answer.indexOf('0000') !== -1) return false;

        let year = parseInt(`${answer[0]}${answer[1]}${answer[2]}${answer[3]}`);
        let month = parseInt(`${answer[4]}${answer[5]}`);
        let day = parseInt(`${answer[6]}${answer[7]}`);
        //let date = new Date(year, month - 1, day);
        if (month < 1 || month > 12) {
            return false;
        }

        if (day < 1 || day > 31) {
            return false;
        }
        let yearNow = new Date().getFullYear()
        let limitYearUp = 2200;
        let limitYearDown = 1850;
        if (yearNow) {
            limitYearUp = yearNow;
            limitYearDown = yearNow - 150;
        }

        if (year < limitYearDown || year > limitYearUp) {
            return false;
        }

        let daysInMoth = new Date(year, month, 0).getDate();

        if (day > daysInMoth) {
            return false;
        }


        return true;

    }
    // more countries here...
}

/**
* Component for socialSecurityNumber
* @author   Milan Stanojevic
*/
class SocialSecurityNumber extends Component {
    constructor(props) {
        super(props);

        const id = uuid();

        this.state = {
            isTouched: false,
            options: {
                days: [],
                months: [],
                years: []
            },
            months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
            openSearchFieldForNameOrEmail: true,
            id,
        };
    }
    setValue = (value, callback) => {
        this.setState({ value }, () => {
            // this.getUsers()
            this.checkIsChild()
            if (value !== this.props.value)
                this.props.onChange(value, callback);
        })
    }
    getValue = () => {
        if (this.props.country === 'uk') {
            let key, day, month, year;
            const value = this.props.value;
            if (value?.length === 12) {
                key = value.substr(8, 4);
                day = value.substr(6, 2);
                month = value.substr(4, 2);
                year = value.substr(0, 4);
            } else {
                key = '0000';
                day = '00';// `${pad(new Date().getDate(), 2)}`;
                month = '00';//`${pad(new Date().getMonth() + 1, 2)}`;
                year = '0000';//`${new Date().getFullYear()}`;
            }
            if (year && month && day && key) {
                let ssn = `${year}${month}${day}${key}`
                this.setValue(ssn)
            }

        }
    }
    checkIsChild = () => {
        if (this.props.country === 'uk') {
            let value = this.state.value;

            if (value?.length === 12) {
                let key, day, month, year;
                key = value.substr(8, 4);
                day = value.substr(6, 2);
                month = value.substr(4, 2);
                year = value.substr(0, 4);
                let check = false;
                if (Number(day) && Number(month) && Number(year) && key === '0000') {
                    check = true;
                }
                const childAgeLimit = CHILD_AGE_LIMIT?.[this.props.country] ?? 18;

                if (check) {
                    fetch(API_ENDPOINT + '/users/users/get-age/' + value, {
                        method: 'POST',
                        headers: {
                            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                            'content-type': 'application/json'
                        },
                        body: JSON.stringify({ socialSecurityNumber: value })
                    }).then(res => res.json()).then((result) => {
                        if (result?.age < childAgeLimit) {
                            this.setState({ parentEmailModal: {}, isChild: true })
                        } else if (this.state.parentEmailModal) {
                            this.setState({ parentEmailModal: false, isChild: false })
                        } else if (this.state.isChild) {
                            this.setState({ isChild: false })
                        }
                    })
                }
            }
        }



    }

    componentDidMount() {
        if (this.props.country === 'uk') {
            if (this.props.value) {
                this.setState({ initialValueChecked: true }, () => {
                    this.getValue()
                })
            } else {
                this.getValue()
            }


            let options = this.state.options;
            for (let i = 1; i <= 31; i++) {
                options.days.push({
                    value: i,
                    name: String(i)
                })
            }
            for (let i = 1; i <= 12; i++) {
                options.months.push({
                    value: i,
                    name: String(i)// this.state.months[i - 1]?.translate(this.props.lang)
                })
            }
            for (let i = new Date().getFullYear(); i >= 1850; i--) {
                options.years.push({
                    value: i,
                    name: String(i)
                })
            }
            this.setState({ options })
        }
        if (this.props.registerRefresh) {
            this.props.registerRefresh(this.getUsers);
        }
    }


    componentDidUpdate(prevProps) {
        if (prevProps.value !== this.props.value) {
            this.getValue()
        }


        if (!this.state.isTouched) {
            const value = this.state.value;
            let day, month, year;
            if (value?.length === 12) {
                day = Number(value.substr(6, 2));
                month = Number(value.substr(4, 2));
                year = Number(value.substr(0, 4));
            }
            if (day && month && year) {
                this.onBlur();
            }
        }
    }



    onBlur = () => { this.setState({ isTouched: true }) }

    generateSSN = (val, type, digits = '0000') => {
        if (val && type) {
            let value = this.state.value;
            let key, day, month, year;
            if (value?.length === 12) {
                // key = value.substr(8, 4);
                key = digits;
                day = value.substr(6, 2);
                month = value.substr(4, 2);
                year = value.substr(0, 4);
            } else {
                key = digits;
                day = `${pad(new Date().getDate(), 2)}`;
                month = `${pad(new Date().getMonth() + 1, 2)}`;
                year = `${new Date().getFullYear()}`;
            }

            if (type === 'day') {
                day = `${pad(Number(val), 2)}`
            } else if (type === 'month') {
                month = `${pad(Number(val), 2)}`
            } else if (type === 'year') {
                year = `${val}`;
            }
            if (year && month && day && key) {
                return `${year}${month}${day}${key}`
            }
        }
    }

    getUsers = () => {

        if (this.props.country === 'uk' && !this.props.ukDisableCheck) {
            // if (this.state.value && this.state.value.indexOf('0000') !== -1 && isSocialSecurityNumberValid(this.state.value)) {
            //     let obj = {
            //         socialSecurityNumber: this.state.value,
            //         group: this.props.selectedGroup,
            //         clinic: this.props.selectedClinic,
            //         searchByName: this.props.searchByName,
            //         filterFields: [],
            //         inThisGroup: this.props.inThisGroup
            //     }
            //     this.setState({ searchResult: null, showDropDown: false }, () => {
            //         fetch(API_ENDPOINT + '/users/find/patient/ssn', {
            //             method: 'POST',
            //             headers: {
            //                 'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            //                 'content-type': 'application/json'
            //             },
            //             body: JSON.stringify(obj)
            //         }).then(res => res.json()).then((result) => {
            //             if (result?.length) {
            //                 this.setState({ searchResult: result, showDropDown: true })
            //             }
            //         })
            //     })
            // } else 
            if (this.state.patientNameOrEmail || this.state.patientNameOrEmail === '') {
                let filterFields = this.props.filterFields ?? ['email', 'name', 'socialSecurityNumber']
                let obj = {
                    group: this.props.selectedGroup,
                    clinic: this.props.selectedClinic,
                    patientNameOrEmail: this.state.patientNameOrEmail,
                    filterFields: filterFields,
                    inThisGroup: this.props.inThisGroup,
                    includeChildren: this.props.includeChildren
                }
                let ssn = this.generateSSN(this.state.value, 'no-change', '0000');
                this.setValue(ssn)
                this.setState({ searchResult: null, showDropDown: false }, () => {
                    fetch(API_ENDPOINT + '/users/find/patient/ssn', {
                        method: 'POST',
                        headers: {
                            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                            'content-type': 'application/json'
                        },
                        body: JSON.stringify(obj)
                    }).then(res => res.json()).then((result) => {
                        if (result?.length) {
                            this.setState({ searchResult: result, showDropDown: true })
                        }
                    })
                })
            }
        }

    }

    checkFilterDelay = () => {
        if (this.lastChangeFilter) {
            let delay = new Date().getTime() - this.lastChangeFilter;

            if (delay > 600) {
                this.getUsers()
                this.lastChangeFilter = null
            }
        }
    }
    checkParentEmail = () => {
        let email = this.state.parentEmailModal?.email;
        if (email) {
            let obj = {
                group: this.props.selectedGroup,
                clinic: this.props.selectedClinic,
                patientNameOrEmail: email,
                filterFields: [],
                inThisGroup: false,
                children: true
            }
            this.setState({ loadingSearch: true }, () => {
                fetch(API_ENDPOINT + '/users/find/patient/ssn', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify(obj)
                }).then(res => res.json()).then((result) => {
                    this.setState({ loadingSearch: false })
                    if (result?.length) {
                        let parentEmailModal = this.state.parentEmailModal;
                        parentEmailModal.result = result;
                        this.setState({ parentEmailModal })
                    }
                })
            })
        }
    }
    resetSearchField = () => {
        if (this.state.patientNameOrEmail) {
            this.setState({ patientNameOrEmail: '' }, () => {
                this.getUsers()
                if (this.props.callbackAfterResetSerachField) {
                    this.props.callbackAfterResetSerachField()
                }
            })
        }
    }

    render() {
        if (this.props.country === 'uk') {
            const value = this.state.value;
            let day, month, year;
            if (value?.length === 12) {
                day = Number(value.substr(6, 2));
                month = Number(value.substr(4, 2));
                year = Number(value.substr(0, 4));
            }
            const hideFindChildren = this.props.hideFindChildren;
            const error = (this.state.isTouched && this.props.error) || (this.props.error && this.props.errorText);
            const errorMessage = this.props.error ?? this.props.errorMessage ?? this.props.errorText;
            return (
                <FormGroup className={this.props.error ? 'input-date-of-birth-wrap ssn-date-of-birth-wrap date-picker-field required' : 'input-date-of-birth-wrap ssn-date-of-birth-wrap date-picker-field'} >

                    {
                        this.state.parentEmailModal && !hideFindChildren ?
                            <Modal isOpen={this.state.parentEmailModal} toggle={() => this.setState({ parentEmailModal: !this.state.parentEmailModal })} centered>
                                <ModalHeader style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    {'Find children'.translate(this.props.lang)}
                                </ModalHeader>
                                <ModalBody className='parent-email-modal-body-wrap'>
                                    <div className='search-wrap'>
                                        <FormGroup>
                                            <Label>{'Parent email'.translate(this.props.lang)}</Label>
                                            <Input type='text' value={this.state.parentEmailModal?.email} onChange={(e) => {
                                                let val = e.target.value;
                                                let parentEmailModal = this.state.parentEmailModal;
                                                parentEmailModal.email = val;
                                                this.setState({ parentEmailModal })

                                            }} />
                                        </FormGroup>
                                        <Button color='primary' disabled={this.state.loadingSearch} onClick={() => {
                                            this.checkParentEmail()
                                        }}>{'Search'.translate(this.props.lang)}</Button>
                                    </div>
                                    {
                                        this.state.parentEmailModal?.result?.length ?
                                            <div className='search-results-wrap'>
                                                {
                                                    this.state.parentEmailModal.result?.filter(item => item.email === this.state.parentEmailModal?.email)?.map((item, idx) => {
                                                        return (
                                                            <div className='search-results-item-wrap'>
                                                                <div className='parent-info'>
                                                                    {'Parent'.translate(this.props.lang)}: {item.name}
                                                                </div>
                                                                {
                                                                    item?.childrenList?.length ?
                                                                        <div className='child-info-wrap'>
                                                                            <table className='child-info'>
                                                                                <tr >
                                                                                    <th>{'Name'.translate(this.props.lang)}</th>
                                                                                    <th style={{ marginLeft: 27 }}>{'Social security number'.translate(this.props.lang)}</th>
                                                                                    <th></th>
                                                                                </tr>
                                                                                {
                                                                                    item.childrenList.map((child, cIdx) => {
                                                                                        return (
                                                                                            <tr >
                                                                                                <td>{child.name}</td>
                                                                                                <td style={{ marginLeft: 27 }}>{child.socialSecurityNumber}</td>
                                                                                                <td>
                                                                                                    <Button color='primary' onClick={() => {
                                                                                                        this.setState({ parentEmailModal: null }, () => {
                                                                                                            this.setValue(child.socialSecurityNumber, () => {
                                                                                                                if (this.props.selectUserCallback) {
                                                                                                                    this.props.selectUserCallback()
                                                                                                                }
                                                                                                            })
                                                                                                        })
                                                                                                    }}>{'Select'.translate(this.props.lang)}</Button>
                                                                                                </td>
                                                                                            </tr>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </table>

                                                                        </div>
                                                                        :
                                                                        'This user has no registered children.'.translate(this.props.lang)
                                                                }
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                            :
                                            null
                                    }


                                </ModalBody>
                                <ModalFooter style={{ justifyContent: 'center' }}>
                                    <Button style={{ minWidth: 100 }} className={'danger-button'} onClick={() => this.setState({ parentEmailModal: !this.state.parentEmailModal })}>{'Cancel'.translate(this.props.lang)}</Button>
                                </ModalFooter>
                            </Modal>
                            :
                            null
                    }

                    {this.props.label ?
                        <Label check={this.props.type == 'checkbox'} size="sm" className={this.props.error ? "required form-group-label-wrap" : "form-group-label-wrap"}>
                            {this.props.label}
                            {this.props.haveValidation ? <span className="required-label-span">*</span> : null}
                            {
                                this.props.labelInfo ?
                                    <div className='info'>
                                        <Isvg src={infoIcon} />
                                        <div className="info-text">{this.props.labelInfo}</div>
                                    </div>
                                    :
                                    null
                            }
                        </Label>
                        :
                        null}

                    <div >
                        {
                            !this.props.hideDateOfBirth ?
                                <>
                                    <div className='ssn-date-of-birth-fields'>
                                        <Input
                                            className='input-day-field'
                                            value={day}
                                            disabled={this.props.disabled || this.props.disableDoB}
                                            type="select"
                                            onChange={(e) => {

                                                let ssn = this.generateSSN(e.target.value, 'day')
                                                this.setValue(ssn)
                                                this.resetSearchField()
                                            }}
                                            onFocus={() => {
                                                // this.setState({
                                                //     patientNameOrEmail: ''
                                                // })
                                                // this.getUsers()
                                            }}

                                        >
                                            {
                                                !day ?
                                                    <option value={0}>{'Day'.translate(this.props.lang)}</option>
                                                    :
                                                    null
                                            }
                                            {
                                                this.state.options?.days?.map((item, idx) => {
                                                    return (
                                                        <option value={item.value}>{item.name}</option>
                                                    )
                                                })
                                            }
                                        </Input>
                                        -
                                        <Input
                                            className='input-month-field'
                                            value={month}
                                            disabled={this.props.disabled || this.props.disableDoB}
                                            type="select"
                                            onChange={(e) => {
                                                this.resetSearchField()
                                                let ssn = this.generateSSN(e.target.value, 'month')
                                                this.setValue(ssn)
                                            }}
                                            onFocus={() => {
                                                // this.setState({
                                                //     patientNameOrEmail: ''
                                                // })
                                                // this.getUsers()
                                            }}
                                        >
                                            {
                                                !month ?
                                                    <option value={0}>{'Month'.translate(this.props.lang)}</option>
                                                    :
                                                    null
                                            }
                                            {
                                                this.state.options?.months?.map((item, idx) => {
                                                    return (
                                                        <option value={item.value}>{item.name}</option>
                                                    )
                                                })
                                            }
                                        </Input>
                                        -
                                        <Input
                                            className='input-year-field'
                                            value={year}
                                            disabled={this.props.disabled || this.props.disableDoB}
                                            type="select"
                                            onChange={(e) => {
                                                this.resetSearchField()
                                                let ssn = this.generateSSN(e.target.value, 'year')
                                                this.setValue(ssn)
                                            }}
                                            onFocus={() => {
                                                // this.setState({
                                                //     patientNameOrEmail: ''
                                                // })
                                                // this.getUsers()
                                            }}
                                        >
                                            {
                                                !year ?
                                                    <option value={0}>{'Year'.translate(this.props.lang)}</option>
                                                    :
                                                    null
                                            }
                                            {
                                                this.state.options?.years?.map((item, idx) => {
                                                    return (
                                                        <option value={item.value}>{item.name}</option>
                                                    )
                                                })
                                            }
                                        </Input>
                                        {/* {
                                            isSocialSecurityNumberValid(value) && !this.props.disabled && !this.props.hideDropDown && !this.state.showDropDown && !this.props.ukDisableCheck ?
                                                <div className='reload-results' onClick={() => {
                                                    let ssn = this.generateSSN(value, 'no-change', '0000')
                                                    this.setValue(ssn);
                                                }}>
                                                    <Isvg src={refreshIcon} />
                                                </div>
                                                :
                                                null
                                        } */}
                                        {/* {
                                            this.props.searchByNameAndEmailFormBuilder ?
                                                <div className={!this.state.openSearchFieldForNameOrEmail ? 'little-dropdown-icon-down arrow-icon-info' : 'little-dropdown-icon-down little-dropdown-icon-down-hide'} onClick={() => {
                                                    this.setState({
                                                        patientNameOrEmail: '',
                                                        value: '000000000000',
                                                    }, () => {
                                                        this.getUsers()
                                                        this.setState({
                                                            openSearchFieldForNameOrEmail: !this.state.openSearchFieldForNameOrEmail
                                                        })
                                                    })

                                                }}>
                                                    <div>{'Show additional search options'.translate(this.props.lang)}</div>
                                                </div>
                                                :
                                                null
                                        } */}

                                    </div>
                                    {error ? <FormFeedback >{errorMessage}</FormFeedback> : null}

                                </>
                                :
                                null
                        }


                        {this.props.searchByNameAndEmail ?
                            <div style={{ width: 300 }}>
                                {
                                    !this.props.hideDateOfBirth ?
                                        <Label>{'Search email'.translate(this.props.lang)}</Label>
                                        :
                                        null
                                }
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Input
                                        className='input-day-field'
                                        value={this.state.patientNameOrEmail}
                                        disabled={this.props.disabled}
                                        type="text"
                                        onChange={(e) => {
                                            const nameOrEmail = e.target.value
                                            this.setState({
                                                patientNameOrEmail: nameOrEmail,
                                                // value: '000000000000',
                                            }, () => {
                                                this.lastChangeFilter = new Date().getTime();
                                                setTimeout(() => {
                                                    this.checkFilterDelay()
                                                }, 600);
                                            })
                                        }}

                                    />
                                    {/* <div className={this.props.searchByNameAndEmail ? 'little-dropdown-icon-up arrow-icon-info' : 'little-dropdown-icon-up little-dropdown-icon-up-hide'} onClick={() => {
                                        this.setState({
                                            patientNameOrEmail: '',
                                            value: '000000000000',
                                        }, () => {
                                            this.getUsers()
                                            this.props.searchByNameAndEmailShow(!this.props.searchByNameAndEmail)
                                        })

                                    }}>
                                        <div>{'Hide additional search options'.translate(this.props.lang)}</div>
                                    </div> */}
                                </div>

                            </div>
                            :
                            null
                        }

                        {this.props.searchByNameAndEmailFormBuilder && this.state.openSearchFieldForNameOrEmail && !this.state.isChild ?
                            <div >
                                <Label>{'Search email'.translate(this.props.lang)}</Label>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Input
                                        className='input-day-field'
                                        value={this.state.patientNameOrEmail}
                                        disabled={this.props.disabled}
                                        type="text"
                                        onChange={(e) => {
                                            const nameOrEmail = e.target.value
                                            this.setState({
                                                patientNameOrEmail: nameOrEmail,
                                                value: '000000000000',
                                            }, () => {
                                                this.lastChangeFilter = new Date().getTime();
                                                setTimeout(() => {
                                                    this.checkFilterDelay()
                                                }, 600);
                                            })
                                        }}

                                    />
                                    {/* <div className={this.state.openSearchFieldForNameOrEmail ? 'little-dropdown-icon-up arrow-icon-info' : 'little-dropdown-icon-up little-dropdown-icon-up-hide'} onClick={() => {
                                        this.setState({
                                            patientNameOrEmail: '',
                                            value: '000000000000',
                                        }, () => {
                                            this.getUsers()
                                            this.setState({
                                                openSearchFieldForNameOrEmail: !this.state.openSearchFieldForNameOrEmail
                                            })
                                        })

                                    }}>
                                        <div>{'Hide additional search options'.translate(this.props.lang)}</div>
                                    </div> */}
                                </div>

                            </div>
                            :
                            null
                        }

                        {
                            !this.props.disabled && !this.props.hideDropDown && this.state.showDropDown && (this.props.hideDateOfBirth || (!this.props.hideDateOfBirth && this.state.value?.indexOf('0000') !== -1)) ?
                                <div style={this.props.searchByNameAndEmail && !this.props.hideDateOfBirth ? { marginTop: 5 } : { marginTop: 10 }} className='search-ssn-users-wrap' >
                                    {/* <div className='action-buttons'>
                                        <Button color='primary' onClick={() => {
                                            this.setState({ showDropDown: false }, () => {
                                                let ssn = this.generateSSN(value, 'no-change', '9999')
                                                this.setValue(ssn, () => {
                                                    if (this.props.newUserCallback) {
                                                        this.props.newUserCallback()
                                                    }
                                                });
                                            })
                                        }}>{'New'.translate(this.props.lang)}</Button>
                                        <div className='close-search-ssn-users' onClick={() => {
                                            this.setState({ showDropDown: false })
                                        }}>
                                            &times;
                                        </div>
                                    </div> */}

                                    {
                                        this.state.searchResult?.length ?
                                            // <div style={{ overflowY: 'scroll', height: 400 }}>
                                            <table>
                                                <div className='table-scroll'>
                                                    <tr className='action-row first-row'>
                                                        <td></td>
                                                        <td></td>
                                                        <td>
                                                            {
                                                                !this.props.disableNewPatientBtn ?
                                                                    <Button color='primary' onClick={() => {
                                                                        this.setState({ showDropDown: false }, () => {
                                                                            let ssn = this.generateSSN(value, 'no-change', '9999')
                                                                            this.setValue(ssn, () => {
                                                                                if (this.props.newUserCallback) {
                                                                                    this.props.newUserCallback()
                                                                                }
                                                                            });
                                                                        })
                                                                    }}>{'New'.translate(this.props.lang)}</Button>
                                                                    :
                                                                    null
                                                            }

                                                        </td>
                                                        <td>
                                                            <div className='close-search-ssn-users' onClick={() => {
                                                                this.setState({ showDropDown: false })
                                                            }}>
                                                                &times;
                                                            </div>
                                                        </td>
                                                    </tr>

                                                    <tr className='second-row'>
                                                        {/* <th>{'Social security number'.translate(this.props.lang)}</th> */}
                                                        <th>{'Name'.translate(this.props.lang)}</th>
                                                        <th style={{ marginLeft: 27 }}>{'Email'.translate(this.props.lang)}</th>
                                                        <th></th>
                                                        <th></th>
                                                    </tr>
                                                </div>
                                                <div className='ssn-field-patient-popup' style={{ overflowY: 'scroll', maxHeight: 420 }}>
                                                    {
                                                        this.state.searchResult.map((item, idx) => {
                                                            return (
                                                                <tr>
                                                                    {/* <td>{item.socialSecurityNumber}</td> */}
                                                                    <td>{item?.userData?.name ?? item.name}</td>
                                                                    <td>{item.email}</td>
                                                                    <td><Button color='primary' onClick={() => {
                                                                        this.setState({ showDropDown: false }, () => {
                                                                            this.setValue(item.socialSecurityNumber, () => {
                                                                                if (this.props.selectUserCallback) {
                                                                                    this.props.selectUserCallback()
                                                                                }
                                                                            })
                                                                        })
                                                                    }}>{'Select'.translate(this.props.lang)}</Button></td>
                                                                    <td></td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </div>

                                            </table>
                                            // </div>
                                            :
                                            null
                                    }

                                </div>
                                :
                                null
                        }
                    </div>



                    {error && this.props.hideDateOfBirth ? <FormFeedback >{errorMessage}</FormFeedback> : null}
                </FormGroup>
            );
        } else {
            return (
                <FormGroup>
                    {this.props.label ?
                        <Label htmlFor={this.state.id} check={this.props.type == 'checkbox'} size="sm" className={this.props.error ? "required form-group-label-wrap" : "form-group-label-wrap"}>
                            {this.props.label} (
                            {this.props.country === 'denmark' ? `DDMMYYNNNN`.substr(0, this.props.value ? this.props.value.length : 0) : `${this.props.lang === 'en' ? 'YYYY' : 'ÅÅÅÅ'}MMDDNNNN`.substr(0, this.props.value ? this.props.value.length : 0)}
                            <span style={{ color: 'red' }}>
                                {this.props.country === 'denmark' ? 'DDMMYYNNNN'.substr(this.props.value ? this.props.value.length : 0, 10) : `${this.props.lang === 'en' ? 'YYYY' : 'ÅÅÅÅ'}MMDDNNNN`.substr(this.props.value ? this.props.value.length : 0, 12)}
                            </span>
                            )
                            {this.props.haveValidation ? <span className="required-label-span">*</span> : null}
                            {
                                this.props.labelInfo ?
                                    <div className='info'>
                                        <Isvg src={infoIcon} />
                                        <div className="info-text">{this.props.labelInfo}</div>
                                    </div>
                                    :
                                    null
                            }
                        </Label>
                        :
                        null}

                    <Input id={this.state.id} size="sm" disabled={this.props.disabled} onKeyUp={this.props.onKeyUp} name={this.props.name} invalid={(this.state.isTouched && (this.props.error || (this.props.value && !isSocialSecurityNumberValid(this.props.value, this.props.country)))) ? true : false} onChange={(e) => {
                        let value = e.target.value.replace(/[^\d]/g, '');
                        if (this.props.country === 'denmark' && value.length > 10) return;
                        if (value.length > 12) return;
                        this.props.onChange(value);
                    }} value={this.props.value}
                        placeholder={this.props.country === 'denmark' ? 'DDMMYYNNNN' : this.props.lang === 'en' ? 'YYYYMMDDNNNN' : 'ÅÅÅÅMMDDNNNN'} onBlur={this.onBlur}></Input>
                    {(this.state.isTouched && (this.props.error || (this.props.value && !isSocialSecurityNumberValid(this.props.value, this.props.country)))) ? <FormFeedback >{this.props.error ? this.props.error : this.props.errorMessage}</FormFeedback> : null}
                </FormGroup>
            );
        }


    }
}

export default SocialSecurityNumber;