import React, { Component } from 'react';
import Link from '../components/link';

import Isvg from 'react-inlinesvg';
import Page from '../containers/page';

import { API_ENDPOINT } from '../constants';

/**
* BankId login component
* @author   Milan Stanojevic
*/
class BankId extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }


    render() {
        return (
            <div>            </div>
        );
    }
}

export default BankId;