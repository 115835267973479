
import React, { Component } from 'react';
import { formatCSS } from '../cssHelper';

import loginFormPNG from '../../assets/images/loginform_v2.png';

/**
* Component for html field
* @author   Milan Stanojevic
*/
class Login extends Component {
    constructor(props) {
        super(props);

        this.state = {

        };
    }

    render() {
        return (
            <div style={{...formatCSS(this.props.style), position: 'relative'}}>
                {
                    this.props.style.borderTopLeftRadius || this.props.style.borderTopRightRadius ||
                        this.props.style.borderBottomLeftRadius || this.props.style.borderBottomRightRadius ?
                        <InverseRadius style={this.props.style} />
                        :
                        null


                }

                <img src={loginFormPNG} style={{width: '100%', height: '100%'}} />

            </div>




        );
    }
}

export default Login;