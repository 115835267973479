import React, { Component } from 'react';
import Page from '../containers/page';

import {
    Container,
    Row,
    Col, Input, Label, Modal, ModalBody, ModalHeader, FormGroup, Button
} from 'reactstrap';


import { getSearchParams } from '../helpers/linkHelper';
import { API_ENDPOINT } from '../constants';


/**
* Stripe
* @author   Stefan Pantic
*/
class Stripe extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props.initialData,
            form: false,
            useState: false,
            params: {
                entries: 10,
                page: 0
            },
            items: [],
            total: 0,
            loading: true,
            order: {},
            currency: 'sek',
            item: {},
            merchant: {},
            snippet: false
        };
    }


    get = () => {
        this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));

        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0, group: this.props.selectedGroup, clinic: this.props.selectedClinic, doctor: this.props.uData._id })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

        for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
            this.props.loadDataWithQuery[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0, group: this.props.selectedGroup, clinic: this.props.selectedClinic, doctor: this.props.uData._id })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }



    }

    componentDidMount() {
        // if (typeof window !== 'undefined') { window.scrollTo(0, 0); }

        // this.get();

        this.createCheckout();

    }

    componentDidUpdate(prevProps) {
        // if (prevProps[0].location.search != this.props[0].location.search) {
        //     this.setState({
        //         loading: true
        //     }, () => {
        //         this.get();

        //     })
        // }

        // if (prevProps.selectedGroup != this.props.selectedGroup || prevProps.selectedClinic != this.props.selectedClinic) {
        //     this.get();

        // }
    }
    componentWillUnmount() {
        clearInterval(this.paymentInterval);
    }

    /**
    * Schedule doctor events on calendar
    * @author   Stefan Pantic
    * @Objectparam    {String} name           product name
    * @Objectparam    {Number} unitPrice      unitPrice
    * @Objectparam    {Number} quantity        quantity
    * @Objectparam    {String} currency         currency
    * @Objectparam    {Number} taxRate          taxRate
    */
    createCheckout = () => {

        this.setState({
            loading: true
        }, () => {

            fetch(API_ENDPOINT + '/stripe', {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify({ lang: this.props.lang, name: 'referral', currency: /*'sek'*/this.props.currency ? this.props.currency : typeof window !== 'undefined' && localStorage?.getItem("currency") ? localStorage.getItem("currency") : '', unitPrice: this.props.price, quantity: 1, referral: this.props.referral, doctor: this.props.doctor, patient: this.props.patient, data: this.props.data, reddirectToPaid: this.props.reddirectToPaid, successMessageNeeded: this.props.successMessageNeeded, fromMyPayments: this.props.fromMyPayments, fromMyCases: this.props.fromMyCases, referralPreviewObj: this.props.referralPreviewObj })
            }).then(res => res.json()).then((result) => {
                if (result.url) {

                    const paymentWindow = window.open(result.url, 'popupPayment', 'height=500,width=400,left=100,top=100,resizable=yes,scrollbars=yes')

                    const transactionID = result.id

                    this.paymentInterval = setInterval(() => {
                        let key = ''
                        fetch(API_ENDPOINT + '/stripe/check/payment/' + transactionID, {
                            method: 'GET',
                            headers: {
                                // 'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                                'content-type': 'application/json'
                            },
                        }).then(res => res.json()).then((result) => {
                            if (result && result.key) {

                                if (result.key === 'stripe-cancel') {
                                    paymentWindow.close()
                                    clearInterval(this.paymentInterval);

                                    this.props[0].history.push({
                                        pathname: '/my-data/personal-referrals/my-payments',
                                        state: { successModal: "Payment failed, please make sure to pay before your scheduled visit.".translate(this.props.lang) }
                                    })

                                } else if (result.key === 'stripe-success') {
                                    paymentWindow.close()
                                    clearInterval(this.paymentInterval);

                                    this.props[0].history.push({
                                        pathname: '/my-data/personal-referrals/referrals',
                                        state: { successModal: "Payment successful.".translate(this.props.lang) }
                                    })

                                }
                            }

                        })

                    }, 1000)

                }

            })
        })

    }

    // checkConfirmation = (id) => {
    //     let key = ''
    //     fetch(API_ENDPOINT + '/stripe/check/payment/' + id, {
    //         method: 'GET',
    //         headers: {
    //             'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
    //             'content-type': 'application/json'
    //         },
    //     }).then(res => res.json()).then((result) => {
    //         if (result && result.key) {

    //             key = result.key
    //         }

    //     })

    //     return key
    // }

    render() {
        return (
            <div >
            </div>
        );
    }
}

export default Stripe;