import React, { Component, Fragment } from 'react';
import PageWithLayout from '../../containers/page';
import { Alert, Button, Col, Container, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import EconnectCasesList from '../../components/e-connect/EconnectCasesList';
import Switch from '../../components/forms/fields/switch'
import FormBuilder from '../../components/forms/modalFormBuilder';
import { required, checkSocialSecurityNumber, dateValidate } from '../../components/forms/validation';
import { connect } from 'react-redux';
import { formValueSelector, change } from 'redux-form';

import ErrorModal from '../../components/errorModal';
import { API_ENDPOINT, CHILD_AGE_LIMIT } from '../../constants';
import SocialSecurityNumber, { isSocialSecurityNumberValid } from '../../components/forms/fields/socialSecurityNumber';
import PatientFilter from '../../components/patientFilter';
import InfoModal from '../../components/infoModal';
import UserIsNotVerifiedTooltip from '../../components/UserIsNotVerifiedTooltip';
import { defaultError, isStringAValidNumber } from '../../components/TwoFactorAuthModal';
import Checkbox from '../../components/forms/fields/checkbox';
import { Player } from '@lottiefiles/react-lottie-player';
import InfoWithTooltip from '../../components/InfoWithTooltip';
import InlineSVG from 'react-inlinesvg';

import infoIcon from '../../assets/svg/info2.svg';
import errorIcon from '../../assets/svg/error-icon.svg';
import successIcon from '../../assets/svg/success-icon.svg';

import trashIcon from '../../assets/svg/garbage.svg';
import axios from 'axios';
import { validateSocialSecurityNumber } from '../patients/searchEnterprise';
import ListBuilder from '../../components/listBuilder';

function parseJSON(response) {
    return response.json().then(json => {
        return {
            result: json,
            status: response.status
        }
    })
}



const allowedFileTypes = ['image/jpeg', 'image/png', 'image/webp', 'text/plain', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/xml', 'application/rtf', 'application/vnd.ms-powerpoint', 'application/vnd.openxmlformats-officedocument.presentationml.presentation', 'video/mp4', 'application/json', 'text/csv', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/pdf'];

export class EconnectCreatedCasesPage extends Component {
    constructor(props) {
        super(props);

        const adminPermissionId = props?.uData?.permissions?.admin;

        this.state = {
            num: 0,
            view: 'active',
            allCases: adminPermissionId && props?.uData?.groupPermissionsUser?.[props?.selectedGroup]?.[props?.selectedClinic]?.includes?.(adminPermissionId) ? true : false,
            form: null,
            clinicGroupSettings: null,
            error: null,
            highlightedCases: [],
            searchByNameAndEmail: false,
        }
    }

    componentDidMount() {
        this.getClinicGroupSettings();
        this.getLanguages();
        this.getPermissions();

        this.localStorageInterval = setInterval(() => {
            this.saveFormProgressToLocalStorage();
        }, 10 * 1000);

        if (this.props?.[0]?.location?.state?.messageModal) {
            const highlightedCases = this.state.highlightedCases;
            highlightedCases.push(this.props?.[0]?.location?.state?.messageModal?.replace?.('_forward', ''));
            this.setState({ highlightedCases });
        }
    }

    getPermissions = () => {
        this.setState({ permissions: null }, async () => {
            const response = await fetch(API_ENDPOINT + '/e-connect/permission-types', {
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            if (response && response.ok) {
                const result = await response.json();
                if (result && result.permissions) this.setState({ permissions: result.permissions });
            }
        });
    }

    checkValidateParent2 = () => {
        let parent2Required = false;
        let socialSecurityNumberParent2 = this.props.socialSecurityNumberParent2;
        if (socialSecurityNumberParent2 === '000000000000') {
            socialSecurityNumberParent2 = null;
        }
        if (socialSecurityNumberParent2 || this.props.email2 || this.props.phone2 || this.props.nameParent2) {
            parent2Required = true;
        }
        if (this.state.parent2Required !== parent2Required) {
            this.setState({ parent2Required })
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if ((!prevProps?.selectedGroup && this.props.selectedGroup) || (prevProps?.selectedGroup !== this.props.selectedGroup)) {
            this.getClinicGroupSettings();
            this.setState({ _availableClinics: null })
        }
        if (prevProps.socialSecurityNumberParent != this.props.socialSecurityNumberParent && this.props.socialSecurityNumberParent && (this.props.socialSecurityNumberParent.length == 12 || (this.props.country === 'denmark' && this.props.socialSecurityNumberParent.length == 10))) {
            this.checkUser();
        }

        if (prevProps.socialSecurityNumber !== this.props.socialSecurityNumber) {
            if ((this.props.country === 'denmark' && this.props.socialSecurityNumber?.length === 10) || this.props.socialSecurityNumber?.length === 12) {
                let dateOfBirth = this.props.getDateOfBirthFromSSN(this.props.socialSecurityNumber);
                if (dateOfBirth && this.props.changeDateOfBirth) {
                    this.props.changeDateOfBirth(dateOfBirth)
                    if (!this.state.disabledDateOfBirth) { this.setState({ disabledDateOfBirth: true }) }
                } else {
                    if (this.state.disabledDateOfBirth) { this.setState({ disabledDateOfBirth: false }) }
                }
            } else {
                if (this.state.disabledDateOfBirth) { this.setState({ disabledDateOfBirth: false }) }
            }
        }
        if (prevProps.socialSecurityNumberParent !== this.props.socialSecurityNumberParent) {

            if ((this.props.country === 'denmark' && this.props.socialSecurityNumberParent?.length === 10) || this.props.socialSecurityNumberParent?.length === 12) {
                let dateOfBirth = this.props.getDateOfBirthFromSSN(this.props.socialSecurityNumberParent);
                if (dateOfBirth && this.props.changeDateOfBirthParent) {
                    this.props.changeDateOfBirthParent(dateOfBirth)
                    if (!this.state.disabledDateOfBirthParent) { this.setState({ disabledDateOfBirthParent: true }) }
                } else {
                    if (this.state.disabledDateOfBirthParent) { this.setState({ disabledDateOfBirthParent: false }) }
                }
            } else {
                if (this.state.disabledDateOfBirthParent) { this.setState({ disabledDateOfBirthParent: false }) }
            }
        }

        if (prevProps.socialSecurityNumberParent2 != this.props.socialSecurityNumberParent2 && this.props.socialSecurityNumberParent2 && ((this.props.country === 'denmark' && this.props.socialSecurityNumberParent2.length == 10) || this.props.socialSecurityNumberParent2.length == 12)) {
            this.checkUser2();
        }
        if (prevProps.socialSecurityNumberParent2 !== this.props.socialSecurityNumberParent2) {

            if ((this.props.country === 'denmark' && this.props.socialSecurityNumberParent2?.length === 10) || this.props.socialSecurityNumberParent2?.length === 12) {
                let dateOfBirth = this.props.getDateOfBirthFromSSN(this.props.socialSecurityNumberParent2);
                if (dateOfBirth && this.props.changeDateOfBirthParent) {
                    this.props.changeDateOfBirthParent2(dateOfBirth)
                    if (!this.state.disabledDateOfBirthParent2) { this.setState({ disabledDateOfBirthParent2: true }) }
                } else {
                    if (this.state.disabledDateOfBirthParent2) { this.setState({ disabledDateOfBirthParent2: false }) }
                }
            } else {
                if (this.state.disabledDateOfBirthParent2) { this.setState({ disabledDateOfBirthParent2: false }) }
            }
        }

        if ((prevProps.socialSecurityNumberParent2 !== this.props.socialSecurityNumberParent2) || (prevProps.email2 !== this.props.email2) || (prevProps.phone2 !== this.props.phone2) || (prevProps.nameParent2 !== this.props.nameParent2)) {
            this.checkValidateParent2()
        }
    }
    checkUser2 = () => {
        fetch(API_ENDPOINT + '/users/subaccounts/get', {
            method: 'POST',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
            body: JSON.stringify({ socialSecurityNumber: this.props.socialSecurityNumberParent2 })
        }).then(parseJSON).then(({ result, status }) => {
            if (status == 200 && result && result.userData) {

                // console.log(result)
                // if (result.groupPermissions && result.groupPermissions[this.props.selectedGroup] && result.groupPermissions[this.props.selectedGroup][this.props.selectedClinic] && result.groupPermissions[this.props.selectedGroup][this.props.selectedClinic].indexOf(this.state.doctorIdPermissions) != -1) {
                //     this.setState({ doctorPermissions: true })
                // }

                // this.setState({
                //     form: result
                // }, () => {
                //     if (!result.email) {
                //         this.setState({ changeEmail: true })
                //     }
                this.props.changeNameParent2(result.name);
                this.props.changeEmail2(result.email);
                if (result.userData.sex)
                    this.props.changeSexParent2(result.userData.sex);
                this.props.changeLanguage2(result.language);
                this.props.changePhone2(result.phone);
                // this.setState({ lockFields: true })
                //     })

                // }
                // if (status == 405) {
                //     this.setState({
                //         error: 'The user is forbidden!'.translate(this.props.lang)
                //     })
                //     this.props.changeSocialSecurityNumber('')
            } else {
                this.props.changeNameParent2('');
                this.props.changeEmail2('');
                this.props.changeSexParent2('');
                this.props.changeLanguage2('');
                this.props.changePhone2('');
            }
        })

    }
    checkUser = () => {
        fetch(API_ENDPOINT + '/users/subaccounts/get', {
            method: 'POST',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
            body: JSON.stringify({ socialSecurityNumber: this.props.socialSecurityNumberParent })
        }).then(parseJSON).then(({ result, status }) => {
            if (status == 200 && result && result.userData) {

                this.props.changeNameParent(result.name);
                this.props.changeEmail(result.email);
                if (result.userData.sex)
                    this.props.changeSexParent(result.userData.sex);
                this.props.changeLanguage(result.language);
                this.props.changePhone(result.phone);
            } else {
                this.props.changeNameParent('');
                this.props.changeEmail('');
                this.props.changeSexParent('');
                this.props.changeLanguage('');
                this.props.changePhone('');
            }
        })

    }

    componentWillUnmount() {
        clearInterval(this.localStorageInterval);
        clearInterval(this.timer1);
        clearTimeout(this.timer2);
    }

    saveFormProgressToLocalStorage = (clearForm = false) => {
        if (this.state._savingProgress) return;
        if (this.state.form && !this.state.form._id && this.state.form.patient && this.state.form.patient.id && typeof window !== 'undefined' && typeof localStorage !== 'undefined') {
            const form = { ...(this.state.form || {}) };
            if (form && Object.keys(form)?.length > 0) {
                const doctorId = this.props.uData?._id;
                const groupId = this.props.selectedGroup;
                const patientId = form?.patient?.id;

                if (doctorId && groupId && patientId) {
                    const eConnectSavedProgressString = localStorage?.getItem?.('eConnectSavedProgress');

                    let eConnectSavedProgress;

                    if (eConnectSavedProgressString) {
                        try {
                            const parsed = JSON.parse(eConnectSavedProgressString);
                            if (parsed && Array.isArray(parsed) && parsed.length > 0) eConnectSavedProgress = parsed;
                            else eConnectSavedProgress = [];
                        } catch (err) {
                            console.error('JSON PARSE ERR: ', err);
                            eConnectSavedProgress = []
                        }
                    }
                    else eConnectSavedProgress = [];

                    const existingObj = eConnectSavedProgress?.find(obj => obj?.doctorId === doctorId && obj.groupId === groupId && obj.patientId === patientId);

                    let newArr = [];
                    let objToAdd = {};

                    if (existingObj) {
                        objToAdd = {
                            ...existingObj,
                            form,
                            ts: + new Date(),
                        };
                        newArr = eConnectSavedProgress?.filter(obj => (obj?.doctorId === doctorId && obj.groupId === groupId && obj.patientId === patientId) ? false : true);
                    } else {
                        objToAdd = {
                            doctorId,
                            groupId,
                            patientId,
                            form,
                            ts: + new Date(),
                        };
                        newArr.push(...(eConnectSavedProgress || []));
                    }

                    newArr.push(objToAdd);

                    localStorage?.setItem?.('eConnectSavedProgress', JSON.stringify(newArr));
                    if (clearForm) this.setState({ form: null });
                }
            }
        }
    }

    refresh = () => {
        this.setState(prev => ({ ...prev, num: +prev?.num + 1 }));
    }

    getLanguages = async () => {
        const response = await fetch(API_ENDPOINT + '/languages', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                sortType: 1,
                sortField: 'key',
                query: {}
            })
        });
        if (response) {
            const result = await response.json();
            if (result) this.setState({ languages: result })
        }
    }
    patientAge = (data) => {

        fetch(API_ENDPOINT + '/users/users/get-age/' + data, {
            method: 'POST',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
            body: JSON.stringify({ socialSecurityNumber: this.state.patientSocialSecurityNumber })
        }).then(res => res.json()).then((result) => {
            this.setState({
                age: result.age
            })
        })
    }

    findPatient = () => {

        if (this.state.patientSocialSecurityNumber) {
            this.checkSSN(this.state.patientSocialSecurityNumber)
        }

        this.setState({ patient: null, age: null }, () => {
            fetch(API_ENDPOINT + '/users/find/patient', {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify({ group: this.props.selectedGroup, clinic: this.props.selectedClinic, socialSecurityNumber: this.state.patientSocialSecurityNumber })
            }).then(res => res.json()).then((result) => {
                if (result.error) {
                    this.setState({
                        error: result.error.translate(this.props.lang),
                        loading: false
                    })
                } else {
                    if (result && result.name && result.socialSecurityNumber) {
                        this.setState({
                            patient: result
                        }, () => {
                            this.patientAge(this.state.patient._id)
                        })
                    } else {
                        let selectedGroupIdx = -1;
                        if (this.props.uData && this.props.selectedGroup && this.props.uData?.clinicGroups?.length) {
                            for (let i = 0; i < this.props.uData.clinicGroups.length; i++) {
                                if (this.props.uData.clinicGroups[i]._id == this.props.selectedGroup) {
                                    selectedGroupIdx = i;
                                    break;
                                }
                            }
                        }
                        let enterprise = this.props.uData && this.props.uData.clinicGroups && this.props.uData.clinicGroups[selectedGroupIdx] && this.props.uData.clinicGroups[selectedGroupIdx].enterprise;
                        let canSearchPatients = this.props.uData && this.props.uData.clinicGroups && this.props.uData.clinicGroups[selectedGroupIdx] && this.props.uData.clinicGroups[selectedGroupIdx].sarchPatients;

                        if (enterprise || canSearchPatients) {
                            this.setState({
                                errorPatientNotFound: 'Check digits or register a new patient'.translate(this.props.lang)
                            }, () => {
                                this.patientAge(this.state.patientSocialSecurityNumber)
                            })
                        } else {
                            this.setState({
                                error: 'The patient you are searching can not be found in your clinic'.translate(this.props.lang)
                            })
                        }

                    }

                }

            })
        })

    }

    checkSSN = (ssn) => {
        fetch(API_ENDPOINT + '/users/patient/check/ssn', {
            method: 'POST',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
            body: JSON.stringify({
                group: this.props.selectedGroup,
                clinic: this.props.selectedClinic,
                socialSecurityNumber: ssn
            })
        }).then(res => res.json()).then((result) => {
            if (result) {
                this.setState({ socialSecurityNumberExist: result.exist, ssnExistInThisGroup: result.ssnExistInThisGroup }, () => {
                    if (this.state.socialSecurityNumberExist && !this.state.ssnExistInThisGroup) {
                        fetch(API_ENDPOINT + '/users/subaccounts/get', {
                            method: 'POST',
                            headers: {
                                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                                'content-type': 'application/json'
                            },
                            body: JSON.stringify({ socialSecurityNumber: ssn })
                        }).then(parseJSON).then(({ result, status }) => {
                            if (status == 200 && result && result.userData) {

                                this.props.changeName(result.userData.name);
                                this.props.changeSex(result.userData.sex);
                                this.props.changeEmail(result.email)
                                this.props.changePhone(result.phone)

                                if (result.parentObj) {
                                    this.props.changeSocialSecurityNumberParent(result.parentObj.socialSecurityNumber)
                                    this.props.changeNameParent(result.parentObj.name);
                                    this.props.changeEmail(result.parentObj.email);
                                    if (result.parentObj.userData.sex)
                                        this.props.changeSexParent(result.parentObj.userData.sex);
                                    this.props.changeLanguage(result.parentObj.language);
                                    this.props.changePhone(result.parentObj.phone);
                                }

                            }
                        })
                    }
                })
            }

        })
    }
    checkPatientValidations = (data) => {

        if (!data || (!this.state.clinicGroupSettings?.twoFactorAuthentication && data.twoFactorAuthenticationEnabled)) return this.setState({ error: 'Something went wrong! Please refresh your browser and try again!'.translate(this.props.lang) });
        if (this.state.age && (this.props.country === 'denmark' ? this.state.age >= CHILD_AGE_LIMIT.denmark : this.props.country === 'sweden' ? this.state.age >= CHILD_AGE_LIMIT.sweden : this.state.age >= 18) && data) {
            if (data.socialSecurityNumberParent) delete data.socialSecurityNumberParent;
            if (data.socialSecurityNumberParent2) delete data.socialSecurityNumberParent2;
            if (data.dateOfBirthParent) delete data.dateOfBirthParent;
            if (data.dateOfBirthParent2) delete data.dateOfBirthParent2;
            if (data.nameParent) delete data.nameParent;
            if (data.nameParent2) delete data.nameParent2;
            if (data.sexParent) delete data.sexParent;
            if (data.sexParent2) delete data.sexParent2;
            if (data.email2) delete data.email2;
            if (data.phone2) delete data.phone2;
        }

        if (this.state.clinicGroupSettings?.twoFactorAuthentication && data.twoFactorAuthenticationEnabled && (!data.email?.trim?.() || !data.phone?.trim?.())) return this.setState({ error: 'When two factor authentication is enabled, both email and phone number are required!'.translate(this.props.lang) });

        if (!(data.email || data.phone) && !this.state.socialSecurityNumberExist) {
            this.setState({ error: 'Mandatory to enter either email or phone.'.translate(this.props.lang) })
        } else {
            if (data.socialSecurityNumberParent) {
                let check = validateSocialSecurityNumber(data.socialSecurityNumberParent, true, this.props.country);

                if (check.error) {
                    this.setState({ error: check.error.translate(this.props.lang) })
                } else {
                    this.setState({
                        saveModal: data
                    })
                }

            } else if (data.socialSecurityNumber && !data.socialSecurityNumberParent) {
                let check = validateSocialSecurityNumber(data.socialSecurityNumber, false, this.props.country);

                if (check.error) {
                    this.setState({ error: check.error.translate(this.props.lang) })
                } else {
                    if (data.socialSecurityNumberParent2 && this.state.parent2Required) {
                        let check = validateSocialSecurityNumber(data.socialSecurityNumberParent2, true, this.props.country)

                        if (check.error) {
                            this.setState({ error: check.error.translate(this.props.lang) })
                        } else {

                            this.setState({
                                saveModal: data
                            })
                        }
                    } else {
                        this.setState({
                            saveModal: data
                        })
                    }
                }
            }
        }

    }

    clearState = () => {
        this.setState({
            patientModal: null,
            errorPatientNotFound: null,
            registerNewPatientModal: null,
            ssnExistInThisGroup: null,
            socialSecurityNumberExist: null,
            age: null,
            patient: null,
            patientSocialSecurityNumber: '',
            saveModal: null,
        })
    }



    addPatient = (data) => {
        if (this.props.country === 'uk' && !this.state.parent2Required && data) {
            if (data.socialSecurityNumberParent2) delete data.socialSecurityNumberParent2;
            if (data.dateOfBirthParent2) delete data.dateOfBirthParent2;
            if (data.nameParent2) delete data.nameParent2;
            if (data.sexParent2) delete data.sexParent2;
            if (data.email2) delete data.email2;
            if (data.phone2) delete data.phone2;
        }
        this.setState({ loading: true }, () => {
            fetch(API_ENDPOINT + '/users/patient/add/new', {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify({
                    group: this.props.selectedGroup,
                    clinic: this.props.selectedClinic,
                    ...data
                })
            }).then(res => res.json()).then(async (result) => {
                if (result.error) {
                    this.setState({ error: result.error, saveModal: null, loading: null })
                } else {
                    if (this.props.country === 'uk' && result?.responseSSN) {
                        this.setState({ patientSocialSecurityNumber: result.responseSSN }, async () => {
                            const response = await fetch(API_ENDPOINT + '/users/find/patient', {
                                method: 'POST',
                                headers: {
                                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                                    'content-type': 'application/json'
                                },
                                body: JSON.stringify({ group: this.props.selectedGroup, clinic: this.props.selectedClinic, socialSecurityNumber: this.state.patientSocialSecurityNumber })
                            });
                            if (!response) return this.setState({ error: defaultError, loading: false }, () => {
                                this.clearState();
                            });
                            const result = await response.json();
                            if (!result || result.error || !result._id || !result.name || !result.socialSecurityNumber) return this.setState({ error: result.error || defaultError, loading: null }, () => {
                                this.clearState();
                            });
                            this.setState({ loading: null }, () => {
                                this.startFormSubmission({
                                    id: result._id,
                                    name: result.userData?.name || result.name || '',
                                    ssn: result.socialSecurityNumber
                                });
                            })
                        })
                    } else {
                        const response = await fetch(API_ENDPOINT + '/users/find/patient', {
                            method: 'POST',
                            headers: {
                                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                                'content-type': 'application/json'
                            },
                            body: JSON.stringify({ group: this.props.selectedGroup, clinic: this.props.selectedClinic, socialSecurityNumber: this.state.patientSocialSecurityNumber })
                        });
                        if (!response) return this.setState({ error: defaultError, loading: false }, () => {
                            this.clearState();
                        });
                        const result = await response.json();
                        if (!result || result.error || !result._id || !result.name || !result.socialSecurityNumber) return this.setState({ error: result.error || defaultError, loading: null }, () => {
                            this.clearState();
                        });
                        this.setState({ loading: null }, () => {
                            this.startFormSubmission({
                                id: result._id,
                                name: result.userData?.name || result.name || '',
                                ssn: result.socialSecurityNumber
                            });
                        })
                    }


                }

            })
        })
    }

    getClinicGroupSettings = () => {
        this.setState({ clinicGroupSettings: null }, async () => {
            if (!this.props?.selectedGroup) return;
            const response = await fetch(API_ENDPOINT + '/data/clinic-group-settings/' + this.props.selectedGroup);
            if (!response || !response.ok) return;
            const result = await response.json();
            if (!result || result.error || !result.clinicGroupSettings) return;
            this.setState({ clinicGroupSettings: result.clinicGroupSettings });
        })
    }

    startFormSubmission = (patient) => {
        let form = {
            patient,
            patientConsent: false,
            subject: '',
            anamnesis: '',
            status: '',
            attachments: [],
            receiver: {
                type: '', // ! internal or external
            }
        }

        if (typeof window !== 'undefined' && typeof localStorage !== 'undefined') {
            const eConnectSavedProgressString = localStorage?.getItem?.('eConnectSavedProgress');
            if (eConnectSavedProgressString) {
                try {
                    const parsed = JSON.parse(eConnectSavedProgressString);
                    if (parsed && Array.isArray(parsed) && parsed.length > 0) {
                        const doctorId = this.props.uData?._id;
                        const groupId = this.props.selectedGroup;
                        const patientId = patient?.id;

                        if (doctorId && groupId && patientId) {
                            const existingForm = parsed.find(obj => obj.doctorId === doctorId && obj.groupId === groupId && obj.patientId === patientId);
                            if (existingForm && existingForm.form) {
                                form = {
                                    ...existingForm.form,
                                    patient
                                }
                            }
                        }
                    }
                } catch (err) {
                    console.error('JSON PARSE ERR: (submission) ', err);
                }
            }
        }

        this.setState({
            form
        }, () => {
            this.clearState();
        })
    }

    addClinicModalHandler = () => {
        if (this.state._availableClinics) return this.setState({
            loading: false,
            error: null,
            addClinicModal: {
                availableClinics: this.state._availableClinics,
                manual: {
                    name: '',
                    street: '',
                    zip: '',
                    city: '',
                    phone: '',
                    email: ''
                }
            }
        });


        this.setState({ loading: true }, async () => {
            const response = await fetch(API_ENDPOINT + '/e-connect/clinics?selectedGroupId=' + this.props.selectedGroup, {
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
            });

            if (!response || !response.ok) return this.setState({ loading: false, error: defaultError });

            const result = await response.json();
            if (!result || !result.clinics) return this.setState({ loading: false, error: defaultError });

            const availableClinics = result.clinics?.filter?.(c => String(c._id) !== this.props.selectedGroup)?.map?.(clinic => ({
                id: clinic._id,
                logo: API_ENDPOINT + clinic?.logo,
                name: clinic?.name,
                address: clinic?.landingConfig?.address?.length ? clinic.landingConfig.address.join?.('; ') : '',
                phone: clinic?.landingConfig?.phone ?? ''
            }))

            this.setState({
                loading: false,
                error: null,
                addClinicModal: {
                    availableClinics,
                    manual: {
                        name: '',
                        street: '',
                        zip: '',
                        city: '',
                        phone: '',
                        email: ''
                    }
                },
                _availableClinics: availableClinics
            });
        });
    }

    isSaveAvailable = () => {
        if (this.props.selectedGroup && this.state.form && this.state.form.patient && this.state.form.patientConsent && this.state.form.patient.id && this.state.form.patient.name && this.state.form.patient.ssn && this.state.form.subject?.trim?.() && (this.state.form.anamnesis?.trim?.() || this.state.form.status?.trim?.() || (this.state.form.receiver?.type === 'internal' ? this.state.form.attachments?.length : false)) && this.state.form.receiver && this.state.form.receiver.type && ['internal', 'external'].includes(this.state.form.receiver.type) && ((this.state.form.receiver.type === 'internal' && this.state.form.receiver.id) || (this.state.form.receiver.type === 'external' && this.state.form.receiver.name?.trim?.() && this.state.form.receiver.address?.trim?.() && this.state.form.receiver.phone?.trim?.()))) return true;
        return false;
    }

    isSendAvailable = () => {
        if (this.isSaveAvailable() && this.state.form?.receiver?.type === 'internal' && this.state.form?.receiver?.id) return true;
        return false;
    }

    removeFormFromLocalStorageOnSave = (obj) => {
        if (!obj || !obj.doctorId || !obj.groupId || !obj.patientId) return;
        try {
            const eConnectSavedProgressString = localStorage?.getItem?.('eConnectSavedProgress');
            if (eConnectSavedProgressString) {
                const parsed = JSON.parse(eConnectSavedProgressString);
                if (parsed && Array.isArray(parsed) && parsed.length > 0) {
                    const filtered = parsed.filter(o => (o.doctorId === obj.doctorId && o.groupId === obj.groupId && o.patientId === obj.patientId) ? false : true);
                    localStorage?.setItem?.('eConnectSavedProgress', JSON.stringify(filtered));
                }
            }
        } catch (err) {
            console.log('--- removeFormFromLocalStorageOnSave err: ', err);
        }
    }

    saveProgress = (_action = 'save') => {
        if (!_action) return;
        if ((_action === 'save' || _action === 'print') && !this.isSaveAvailable()) return;
        if (_action === 'send' && !this.isSendAvailable()) return;

        if (_action !== 'print' && this.state.form?._id && this.state.form?.sender?.status && this.state.form?.sender?.status !== 'created') return;

        if (this.state.form?._id && this.state.form?.sender?.status === 'created' && _action === 'save' && !this.state.form?._edited) return this.setState({ form: null });

        try {
            this.setState({ _savingProgress: true, loading: true }, async () => {
                let newState = {
                    _savingProgress: false,
                    loading: false,
                    closeModal: null
                }

                let obj = {
                    doctorId: this.props.uData?._id,
                    groupId: this.props.selectedGroup,
                    patientId: this.state.form?.patient?.id,
                }

                let url = `${API_ENDPOINT}/e-connect/cases`
                let method = 'POST';
                let body = JSON.stringify({
                    action: _action,
                    form: { ...(this.state.form || {}) },
                    groupId: this.props.selectedGroup,
                    departmentId: this.props.selectedClinic
                });

                const response = await fetch(url, {
                    method,
                    headers: {
                        'Content-type': 'application/json',
                        'Authorization': `Bearer ${localStorage?.getItem?.('authToken')}`
                    },
                    body,
                });

                if (!response) {
                    newState.error = defaultError;
                    return this.setState(newState);
                }

                const result = await response.json();

                if (!result || result.error) {
                    newState.error = result.error || defaultError;
                    return this.setState(newState, () => {
                        this.refresh();
                    });
                }

                newState.form = null;

                this.removeFormFromLocalStorageOnSave(obj);

                if (result.uuid) {
                    this.numOfTries = 0;
                    this.checkProgress(result.uuid, (id) => {
                        this.setState(newState, () => {
                            this.refresh();
                            if (_action === 'print') this.print(id || result.uuid);
                        });
                    })
                } else {
                    this.setState(newState, () => {
                        this.refresh();
                        this.numOfTries = 0;
                    });
                }
            });
        } catch (err) {
            console.error('save progress error: ', err);
        }
    }

    checkProgress = (uuid, action) => {
        if (!uuid || !action || typeof action !== 'function') return;
        const fetcher = async (uuid) => {
            const response = await fetch(API_ENDPOINT + `/e-connect/cases/check/${uuid}`, {
                headers: {
                    'Content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage?.getItem?.('authToken')}`
                }
            });
            if (!response || !response.ok) return null;
            const result = await response.json();
            if (!result || !result.id) return null;
            return result.id;
        }
        this.timer1 = setInterval(async () => {
            if (this.numOfTries >= 5) {
                clearInterval(this.timer1);
                this.timer1 = null;
                this.setState({ loading: false, _savingProgress: false, error: defaultError }, () => {
                    this.refresh();
                });
                return;
            }
            let newNum = (this.numOfTries || 0) + 1;
            this.numOfTries = newNum;
            const id = await fetcher(uuid);
            if (id) {
                clearInterval(this.timer1);
                this.timer1 = null;
                action?.(id);
            }
        }, 1500);
    }

    uploadFiles = (files) => {
        if (!files) return;
        try {
            files = Array.from(files);
            if (files && Array.isArray(files) && files.length > 0) {
                this.setState({ _uploading: true, _dragging: false }, async () => {
                    let attachments = [];
                    for (let i = 0; i < files.length; i++) {
                        const file = files[i];
                        if (!file || !file.type || !file.name) continue;
                        if (!allowedFileTypes.includes(file.type)) continue;
                        const obj = {
                            type: (file.type?.startsWith?.('video') ? (
                                'video'
                            ) : file.type?.startsWith?.('image') ? (
                                'image'
                            ) : 'file'),
                            name: file.name
                        };

                        const formData = new FormData();
                        formData.append('file', file);
                        formData.append('group', this.props.selectedGroup);
                        formData.append('fileType', 'eConnect');

                        try {
                            const response = await axios.post(API_ENDPOINT + '/data/upload-document', formData, {
                                headers: {
                                    Accept: 'application/json',
                                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                                }
                            });
                            if (response && response.status && response.status == 200 && response.data) {
                                obj.url = response.data;
                                attachments.push(obj);
                            }
                        } catch (err) {
                            console.log('upload err: ', err);
                            continue;
                        }
                    };

                    this.setState(prev => ({
                        ...prev,
                        _uploading: false,
                        form: {
                            ...prev?.form,
                            attachments: [...(prev.form.attachments || []), ...attachments],
                            _edited: true,
                        }
                    }));
                });
            }
        } catch (err) {
            console.log('upload files err: ', err);
        }
    }

    deleteCase = () => {
        if (this.state.deleteModal) {
            this.setState({ loading: true, _savingProgress: true, }, async () => {
                const response = await fetch(API_ENDPOINT + '/e-connect/cases/' + this.state.deleteModal, {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage?.getItem?.('authToken')}`
                    }
                });
                if (!response) return this.setState({ loading: false, _savingProgress: false, error: defaultError, deleteModal: null });
                const result = await response.json();
                if (!result || result.error) return this.setState({ loading: false, _savingProgress: false, error: result.error || defaultError, deleteModal: null });
                return this.setState({ loading: false, _savingProgress: false, form: null, deleteModal: null }, () => {
                    this.refresh();
                })
            });
        }
    }

    searchPatientWithRedirect = (SSN, enterprise) => {
        let obj = {
            socialSecurityNumber: SSN,
            group: this.props.selectedGroup,
            clinic: this.props.selectedClinic,
        }
        enterprise && (obj.openOnWebDoc = true);

        fetch(API_ENDPOINT + '/users/find/patient', {
            method: 'POST',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
            body: JSON.stringify(obj)
        }).then(res => res.json()).then((result) => {

            if (result && result._id) {
                const path = enterprise ? '/patients/search-enterprise' : '/patients/search';
                this.props.registerClinicGroupLogs?.({
                    type: 'patientProfile',
                    patient: SSN,
                });

                let state = {
                    TYPE: 'redirect',
                    redirectPatient: result,
                    redirectTabIndex: 1
                }
                enterprise && (state.redirectTabActive = 1);

                this.props?.[0]?.history?.push?.(path, state);

            } else {
                this.setState({ error: 'User not found in this clinic group' })
            }
        })
    }

    archiveCase = () => {
        if (!this.state.archiveModal) return;
        this.setState({ loading: true, _savingProgress: true }, async () => {
            const response = await fetch(API_ENDPOINT + `/e-connect/cases/${this.state.archiveModal}`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage?.getItem?.('authToken')}`
                },
                body: JSON.stringify({
                    action: 'archive',
                    from: 'sender',
                    groupId: this.props.selectedGroup,
                    departmentId: this.props.selectedClinic
                })
            });
            if (!response) return this.setState({
                loading: false,
                _savingProgress: false,
                error: defaultError,
                archiveModal: null
            });
            const result = await response.json();
            if (!result || result.error) return this.setState({
                loading: false,
                _savingProgress: false,
                error: result.error || defaultError,
                archiveModal: null
            });

            return this.setState({
                loading: false,
                _savingProgress: false,
                archiveModal: null,
                form: null
            }, () => {
                this.refresh();
            })
        })
    }

    recreateCase = () => {
        if (!this.state.newCaseModal) return;
        this.setState({ loading: true, _savingProgress: true }, () => {
            const oldForm = { ...(this.state.newCaseModal || {}) };
            const form = {
                _oldCaseId: String(oldForm?._id),
                patient: {
                    id: oldForm?.patient?.id,
                    name: oldForm?.patient?.name,
                    ssn: oldForm?.patient?.ssn,
                },
                subject: oldForm?.subject,
                anamnesis: oldForm?.anamnesis || '',
                status: oldForm?.status || '',
                attachments: oldForm?.attachments || [],
                patientConsent: oldForm?.patientConsent,
            };
            const receiver = oldForm?.receiver?.type === 'internal' ? {
                type: 'internal',
                id: oldForm?.receiver?.id,
                name: oldForm?.receiver?.name,
                address: oldForm?.receiver?.address,
                phone: oldForm?.receiver?.phone
            } : {
                type: 'external',
                name: oldForm?.receiver?.name,
                address: oldForm?.receiver?.address,
                phone: oldForm?.receiver?.phone,
                email: oldForm?.receiver?.email
            };
            if (receiver && receiver.type && ((receiver.type === 'internal' && receiver.id && receiver.name) || (receiver.type === 'external' && receiver.name && receiver.address && receiver.phone && !receiver.id))) form.receiver = receiver;

            if (form && form._oldCaseId && form.patient && form.patient.id && form.patient.ssn && form.subject && form.patientConsent && form.receiver && form.receiver.type) return this.setState({ loading: false, _savingProgress: false, form, newCaseModal: null });
            else return this.setState({ loading: false, _savingProgress: false, form: null, error: 'Something went wrong and a new case based on the old case could not be created! Please refresh your browser and try again!', newCaseModal: null }, () => {
                this.refresh();
            });
        })
    }

    print = (id) => {
        if (!id) return;
        this.setState({ loading: true, _savingProgress: true }, async () => {
            const newState = {
                loading: false,
                _savingProgress: false
            }
            const response = await fetch(API_ENDPOINT + `/e-connect/cases/pdf/${id}?lang=${this.props.lang || 'se'}&groupId=${this.props.selectedGroup}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage?.getItem?.('authToken')}`
                }
            });
            if (!response) {
                newState.error = defaultError;
                return this.setState(newState);
            }
            const result = await response.json();
            if (!result || !result.url || result.error) {
                newState.error = result.error || defaultError;
                return this.setState(newState);
            }

            this.setState(newState, () => {
                window?.open?.(API_ENDPOINT + result.url, '_blank');
            });
        })
    }

    scheduleMonitoring = () => {
        if (!this.state.scheduleMonitoring?.id || !this.state.scheduleMonitoring?.num?.trim() || !this.state.scheduleMonitoring?.measure || !isStringAValidNumber(this.state.scheduleMonitoring?.num) || +this.state.scheduleMonitoring?.num === 0) return;

        this.setState({ loading: true }, async () => {
            const { id, num, measure, note } = this.state.scheduleMonitoring
            const newState = {
                loading: false,
            }

            const response = await fetch(API_ENDPOINT + `/e-connect/monitoring/${id}`, {
                method: 'POST',
                headers: {
                    'Content-Type': "application/json",
                    'Authorization': `Bearer ${localStorage?.getItem?.('authToken')}`
                },
                body: JSON.stringify({
                    num,
                    measure,
                    note,
                })
            });

            if (!response) {
                newState.error = defaultError;
                return this.setState(newState);
            }

            const result = await response.json();

            if (!result || result.error || !result.updatedCase) {
                newState.error = result.error || defaultError;
                return this.setState(newState);
            }

            newState.monitoring = result.updatedCase;
            newState.scheduleMonitoring = null;
            this.setState(newState, () => {
                this.refresh();
            });
        });
    }

    updateMonitoring = (id, uuid, status, note) => {
        if (!id || !uuid || !status || !['cancelled', 'finished', 'add-note'].includes(status)) return;
        this.setState({ loading: true }, async () => {
            const newState = {
                loading: false,
            }

            const response = await fetch(API_ENDPOINT + `/e-connect/monitoring/${id}/${uuid}`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': "application/json",
                    'Authorization': `Bearer ${localStorage?.getItem?.('authToken')}`
                },
                body: JSON.stringify({
                    status,
                    note
                })
            });

            if (!response) {
                newState.error = defaultError;
                return this.setState(newState);
            }

            const result = await response.json();

            if (!result || result.error || !result.updatedCase) {
                newState.error = result.error || defaultError;
                return this.setState(newState);
            }

            newState.monitoring = result.updatedCase;
            newState.updateMonitoring = null; // todo
            if (this.state.monitoringNotes) {
                if (result.updatedCase?._id && result.updatedCase?.monitoring?.find?.(obj => obj?.uuid === uuid)) newState.monitoringNotes = {
                    id: result.updatedCase?._id,
                    item: result.updatedCase?.monitoring?.find?.(obj => obj?.uuid === uuid)
                };
                else newState.monitoringNotes = null;
            } else newState.monitoringNotes = null;
            this.setState(newState, () => {
                if (status === 'finished') this.props.checkEConnectCount?.();
                this.refresh();
            });
        })
    }

    render() {
        let selectedGroupIdx = -1;
        if (this.props.uData && this.props.selectedGroup && this.props.uData?.clinicGroups?.length) {
            for (let i = 0; i < this.props.uData.clinicGroups.length; i++) {
                if (this.props.uData.clinicGroups[i]._id == this.props.selectedGroup) {
                    selectedGroupIdx = i;
                    break;
                }
            }
        }
        let enterprise = this.props.uData && this.props.uData.clinicGroups && this.props.uData.clinicGroups[selectedGroupIdx] && this.props.uData.clinicGroups[selectedGroupIdx].enterprise;
        let canSearchPatients = this.props.uData && this.props.uData.clinicGroups && this.props.uData.clinicGroups[selectedGroupIdx] && this.props.uData.clinicGroups[selectedGroupIdx].sarchPatients;

        let canOpenProfile = false;
        if (this.state.clinicGroupSettings?.enterprise || this.state.clinicGroupSettings?.canSearchPatients) canOpenProfile = true;

        const isEditingDisabled = (!this.state.form || !this.state.form.patient || (this.state.form?.sender?.status && this.state.form.sender.status !== 'created') || (this.props.uData?._id && this.state.form?.sender?.user && (String(this.props.uData?._id) !== String(this.state.form?.sender?.user))));
        const isChild = this.state.age && (this.props.country === 'denmark' ? this.state.age < CHILD_AGE_LIMIT.denmark : this.props.country === 'sweden' ? this.state.age < CHILD_AGE_LIMIT.sweden : this.state.age < 18) ? true : false;
        return (
            <div className={this.props.autoHideSidebar ? "dashboard dashboard-auto-hide" : "dashboard"}>
                <Container fluid>
                    <Row style={{ marginBottom: 10 }}>
                        <Col lg='12' style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: 10 }}>
                            {/* <h2 style={{ fontWeight: '800' }}>{'All Created E-Connect Cases'.translate(this.props.lang)}</h2> */}
                            {this.state.permissions?.nurse && this.props.uData?.groupPermissionsUser?.[this.props.selectedGroup]?.[this.props.selectedClinic]?.includes?.(this.state.permissions?.nurse) ? <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: 10, position: 'relative', right: '6%' }}>
                                <span>{'Show all e-connect cases'.translate(this.props.lang)}</span>
                                <Switch value={this.state.allCases} onChange={(e) => {
                                    this.setState({ allCases: e?.target?.checked })
                                }} />
                            </div> : null}
                        </Col>
                    </Row>

                    <div className='e-connect-tabs'>
                        <Button className={this.state.view === 'active' ? 'active-tab-button' : ''} color='secondary' onClick={() => {
                            if (this.state.view === 'active') return;
                            this.setState({ view: 'active' })
                        }}>{'Active'.translate(this.props.lang)}</Button>
                        <Button className={this.state.view === 'archive' ? 'active-tab-button' : ''} color='secondary' onClick={() => {
                            if (this.state.view === 'archive') return;
                            this.setState({ view: 'archive' })
                        }}>{'Archive'.translate(this.props.lang)}</Button>
                    </div>

                    {this.state.view === 'active' ? <Fragment key='active'>
                        <Row>
                            <Col lg='12'>
                                <div className='panel aeb-panel'>
                                    <div className='panel-header'>
                                        <h5 className='component-header'>
                                            {'Created e-Connect Cases'.translate(this.props?.lang || 'en')}
                                        </h5>
                                        {this.state.permissions?.doctor && this.props.uData?.groupPermissionsUser?.[this.props.selectedGroup]?.[this.props.selectedClinic]?.includes?.(this.state.permissions?.doctor) ? <Button
                                            color='primary'
                                            size='lg'
                                            style={{ borderRadius: 6 }}
                                            onClick={() => {
                                                this.setState({
                                                    patientModal: true,
                                                    patient: null,
                                                    patientSocialSecurityNumber: '',
                                                    age: null,
                                                });
                                            }}
                                        >
                                            {'Create New Case'.translate(this.props.lang)}
                                        </Button> : null}
                                    </div>
                                    <EconnectCasesList
                                        {...this.props}
                                        highlightedCases={this.state.highlightedCases}
                                        num={this.state.num}
                                        refresh={this.refresh}
                                        allCases={this.state.allCases}
                                        type='created'
                                        _page='created'
                                        canOpenProfile={canOpenProfile}
                                        enterprise={this.state.clinicGroupSettings?.enterprise}
                                        onPreview={(item) => {
                                            this.setState({ form: item });
                                            if (item?._id && this.state.highlightedCases?.includes(item._id)) {
                                                const highlightedCases = this.state.highlightedCases.filter(id => id !== item._id);
                                                this.setState({ highlightedCases })
                                            }
                                        }}
                                        onHistory={(item) => {
                                            this.setState({ history: item });
                                            if (item?._id && this.state.highlightedCases?.includes(item._id)) {
                                                const highlightedCases = this.state.highlightedCases.filter(id => id !== item._id);
                                                this.setState({ highlightedCases })
                                            }
                                        }}
                                        onDelete={item => { if (item && item._id) this.setState({ deleteModal: item._id }) }}
                                        uData={this.props.uData}
                                        onPrint={(item) => {
                                            if (item && item._id) {
                                                this.print(item._id);
                                                if (item?._id && this.state.highlightedCases?.includes(item._id)) {
                                                    const highlightedCases = this.state.highlightedCases.filter(id => id !== item._id);
                                                    this.setState({ highlightedCases })
                                                }
                                            }
                                        }}
                                        onReferralResponse={(item) => {
                                            this.setState({
                                                answerAndResponseModal: item
                                            });
                                            if (item?._id && this.state.highlightedCases?.includes(item._id)) {
                                                const highlightedCases = this.state.highlightedCases.filter(id => id !== item._id);
                                                this.setState({ highlightedCases })
                                            }
                                        }}
                                        onMonitoring={item => {
                                            if (['sent', 'accepted'].includes(item?.sender?.status) && item?.sender?.user && String(item?.sender?.user) === String(this.props.uData?._id)) {
                                                this.setState({ monitoring: item });
                                                if (item?._id && this.state.highlightedCases?.includes(item._id)) {
                                                    const highlightedCases = this.state.highlightedCases.filter(id => id !== item._id);
                                                    this.setState({ highlightedCases })
                                                }
                                            }
                                        }}
                                        onChat={item => {
                                            if (item && item.conversationId) {
                                                this.setState({ chatModal: item });
                                                if (item?._id && this.state.highlightedCases?.includes(item._id)) {
                                                    const highlightedCases = this.state.highlightedCases.filter(id => id !== item._id);
                                                    this.setState({ highlightedCases })
                                                }
                                            }
                                        }}
                                    />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg='12'>
                                <div className='panel aeb-panel'>
                                    <div className='panel-header'>
                                        <h5 className='component-header'>
                                            {'Sent e-Connect Cases'.translate(this.props?.lang || 'en')}
                                        </h5>
                                    </div>
                                    <EconnectCasesList
                                        {...this.props}
                                        highlightedCases={this.state.highlightedCases}
                                        num={this.state.num}
                                        refresh={this.refresh}
                                        allCases={this.state.allCases}
                                        type='sent'
                                        _page='created'
                                        canOpenProfile={canOpenProfile}
                                        enterprise={this.state.clinicGroupSettings?.enterprise}
                                        onPreview={(item) => {
                                            this.setState({ form: item });
                                            if (item?._id && this.state.highlightedCases?.includes(item._id)) {
                                                const highlightedCases = this.state.highlightedCases.filter(id => id !== item._id);
                                                this.setState({ highlightedCases })
                                            }
                                        }}
                                        onHistory={(item) => {
                                            this.setState({ history: item });
                                            if (item?._id && this.state.highlightedCases?.includes(item._id)) {
                                                const highlightedCases = this.state.highlightedCases.filter(id => id !== item._id);
                                                this.setState({ highlightedCases })
                                            }
                                        }}
                                        onArchive={item => {
                                            if (item && item._id) {
                                                this.setState({ archiveModal: item._id });
                                                if (item?._id && this.state.highlightedCases?.includes(item._id)) {
                                                    const highlightedCases = this.state.highlightedCases.filter(id => id !== item._id);
                                                    this.setState({ highlightedCases })
                                                }
                                            }
                                        }}
                                        uData={this.props.uData}
                                        onPrint={(item) => {
                                            if (item && item._id) {
                                                this.print(item._id);
                                                if (item?._id && this.state.highlightedCases?.includes(item._id)) {
                                                    const highlightedCases = this.state.highlightedCases.filter(id => id !== item._id);
                                                    this.setState({ highlightedCases })
                                                }
                                            }
                                        }}
                                        onReferralResponse={(item) => {
                                            this.setState({
                                                answerAndResponseModal: item
                                            });
                                            if (item?._id && this.state.highlightedCases?.includes(item._id)) {
                                                const highlightedCases = this.state.highlightedCases.filter(id => id !== item._id);
                                                this.setState({ highlightedCases })
                                            }
                                        }}
                                        onMonitoring={item => {
                                            if (['sent', 'accepted'].includes(item?.sender?.status) && item?.sender?.user && String(item?.sender?.user) === String(this.props.uData?._id)) {
                                                this.setState({ monitoring: item });
                                                if (item?._id && this.state.highlightedCases?.includes(item._id)) {
                                                    const highlightedCases = this.state.highlightedCases.filter(id => id !== item._id);
                                                    this.setState({ highlightedCases })
                                                }
                                            }
                                        }}
                                        onChat={item => {
                                            if (item && item.conversationId) {
                                                this.setState({ chatModal: item });
                                                if (item?._id && this.state.highlightedCases?.includes(item._id)) {
                                                    const highlightedCases = this.state.highlightedCases.filter(id => id !== item._id);
                                                    this.setState({ highlightedCases })
                                                }
                                            }
                                        }}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Fragment> : this.state.view === 'archive' ? <Fragment key='archive'>
                        <Row>
                            <Col lg='12'>
                                <div className='panel aeb-panel'>
                                    <div className='panel-header'>
                                        <h5 className='component-header'>
                                            {'Archived e-Connect Cases'.translate(this.props?.lang || 'en')}
                                        </h5>
                                    </div>
                                    <EconnectCasesList
                                        {...this.props}
                                        highlightedCases={this.state.highlightedCases}
                                        num={this.state.num}
                                        refresh={this.refresh}
                                        allCases={this.state.allCases}
                                        type='archive'
                                        _page='created'
                                        canOpenProfile={canOpenProfile}
                                        enterprise={this.state.clinicGroupSettings?.enterprise}
                                        onPreview={(item) => {
                                            this.setState({ form: item });
                                            if (item?._id && this.state.highlightedCases?.includes(item._id)) {
                                                const highlightedCases = this.state.highlightedCases.filter(id => id !== item._id);
                                                this.setState({ highlightedCases })
                                            }
                                        }}
                                        onHistory={(item) => {
                                            this.setState({ history: item });
                                            if (item?._id && this.state.highlightedCases?.includes(item._id)) {
                                                const highlightedCases = this.state.highlightedCases.filter(id => id !== item._id);
                                                this.setState({ highlightedCases })
                                            }
                                        }}
                                        uData={this.props.uData}
                                        onPrint={(item) => {
                                            if (item && item._id) {
                                                this.print(item._id);
                                                if (item?._id && this.state.highlightedCases?.includes(item._id)) {
                                                    const highlightedCases = this.state.highlightedCases.filter(id => id !== item._id);
                                                    this.setState({ highlightedCases })
                                                }
                                            }
                                        }}
                                        onReferralResponse={(item) => {
                                            this.setState({
                                                answerAndResponseModal: item
                                            });
                                            if (item?._id && this.state.highlightedCases?.includes(item._id)) {
                                                const highlightedCases = this.state.highlightedCases.filter(id => id !== item._id);
                                                this.setState({ highlightedCases })
                                            }
                                        }}
                                        onMonitoring={item => {
                                            if (['sent', 'accepted'].includes(item?.sender?.status) && item?.sender?.user && String(item?.sender?.user) === String(this.props.uData?._id)) {
                                                this.setState({ monitoring: item });
                                                if (item?._id && this.state.highlightedCases?.includes(item._id)) {
                                                    const highlightedCases = this.state.highlightedCases.filter(id => id !== item._id);
                                                    this.setState({ highlightedCases })
                                                }
                                            }
                                        }}
                                        onChat={item => {
                                            if (item && item.conversationId) {
                                                this.setState({ chatModal: item });
                                                if (item?._id && this.state.highlightedCases?.includes(item._id)) {
                                                    const highlightedCases = this.state.highlightedCases.filter(id => id !== item._id);
                                                    this.setState({ highlightedCases })
                                                }
                                            }
                                        }}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Fragment> : null}
                </Container>

                {
                    this.state.form ? <Modal key='form-modal' isOpen={this.state.form} size='xl' centered>
                        <ModalHeader toggle={() => this.setState({ form: null })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => {
                            if (!this.state.form?._id) return this.saveFormProgressToLocalStorage(true);
                            if (this.state.form?.sender?.status && this.state.form?.sender?.status === 'created') {
                                if (!this.state.form?._edited) return this.setState({ form: null });
                                this.setState({ closeModal: true });
                            } else {
                                this.setState({ form: null });
                            }
                        }}>&times;</button>}>
                            {!this.state.form?._id ? 'Create New Case'.translate(this.props.lang) : this.state.form?.sender?.status === 'created' ? 'Edit Case'.translate(this.props.lang) : 'Case Details'.translate(this.props.lang)}
                            {!this.state.form?._id || this.state.form?.sender?.status === 'created' ? null : <InfoWithTooltip
                                style={{ bottom: 4 }}
                                text={!this.state.form?._id || this.state.form?.sender?.status === 'created' ? (
                                    'If receiving clinic is outside of Curoflow e-Connect, referrals can only be printed or saved as PDF'
                                ) : this.state.form?.sender?.status === 'sent' ? (
                                    'This case has been sent to the receiving clinic. Further actions can be taken after receiving clinic answers this case'
                                ) : this.state.form?.sender?.status === 'rejected' ? (
                                    !this.state.form?.newCaseCreated ? (
                                        'This case has been rejected by the receiving clinic. You can now create a new case based on it, or archive this case'
                                    ) : (
                                        'This case has been rejected by the receiving clinic and you have already created a new case based on it'
                                    )
                                ) : this.state.form?.sender?.status === 'accepted' ? (
                                    'This case has been accepted by the receiving clinic. They will process the case from now on. You can archive this case now'
                                ) : this.state.form?.sender?.status === 'archived' ? (
                                    'This case has been archived. No further actions can be taken at this time'
                                ) : ''}
                                lang={this.props.lang}
                            />}
                        </ModalHeader>
                        <ModalBody style={{ position: 'relative' }}>
                            {this.state.form?.forward ? <Row>
                                <Col>
                                    <Alert color='primary' className='ec-details-container'>
                                        <details>
                                            <summary>
                                                <strong>{'Referral Forwarded'.translate(this.props.lang)}</strong>: {this.state.form?.forward?.ts ? new Intl.DateTimeFormat(this.props.lang === 'se' ? 'sv-SE' : 'en-EN', {
                                                    year: 'numeric',
                                                    month: 'short',
                                                    day: 'numeric',
                                                    hour: 'numeric',
                                                    minute: 'numeric'
                                                }).format(this.state.form?.forward?.ts) : null}
                                            </summary>
                                            <div>
                                                <Row>
                                                    {/* <Col lg={this.state.form?.answer?.user?.assignedDoctorId ? '3' : '4'}>
                                                        <h6>{'Answer'.translate(this.props.lang)}:</h6>
                                                        <div className={this.state.form?.answer?.type === 'rejected' ? 'e-connect-list-status-rejected' : this.state.form?.answer?.type === 'accepted' ? 'e-connect-list-status-accepted' : undefined} style={{ fontWeight: '500' }}>
                                                            {`${this.state.form?.answer?.type?.charAt?.(0)?.toUpperCase?.() + this.state.form?.answer?.type?.slice?.(1)}`.translate(this.props.lang)}
                                                        </div>

                                                    </Col> */}
                                                    <Col lg='6'>
                                                        <h6>{'Forwarding department'.translate(this.props.lang)}:</h6>
                                                        <div>{this.state.form?.forward?.user?.departmentName || 'N/A'}</div>
                                                    </Col>
                                                    <Col lg='6'>
                                                        <h6>{'Forwarding personel'.translate(this.props.lang)}:</h6>
                                                        <div>{this.state.form?.forward?.user?.name || 'N/A'}</div>
                                                    </Col>
                                                    {/* {this.state.form?.answer?.user?.assignedDoctorId ? <Col lg='3'>
                                                        <h6>{'Assigned caregiver'.translate(this.props.lang)}:</h6>
                                                        <div>{this.state.form?.answer?.user?.assignedDoctorName || 'N/A'}</div>
                                                    </Col> : null} */}
                                                </Row>
                                                <hr />
                                                <Row style={{ marginTop: 20 }}>
                                                    <Col>
                                                        <FormGroup>
                                                            <h6>{'Referral confirmation'.translate(this.props.lang)}:</h6>
                                                            <p>
                                                                {this.state.form?.forward?.message}
                                                            </p>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </details>
                                    </Alert>
                                </Col>
                            </Row> : null}
                            <Row>
                                <Col lg='7' >
                                    <div key='div-patient-asdf' className='econnect-create-patient'>
                                        <div>
                                            <div>
                                                <h6>{'Social security number'.translate(this.props.lang)}:</h6>
                                                <p>{this.state.form?.patient?.ssn} {this.state.form?._2fa ? <UserIsNotVerifiedTooltip warning lang={this.props.lang} /> : null}</p>
                                            </div>
                                            <div>
                                                <h6>{'Name'.translate(this.props.lang)}:</h6>
                                                <p>{this.state.form?.patient?.name}</p>
                                            </div>
                                        </div>
                                        {this.state.form?._id ? <button onClick={() => {
                                            const patient = this.state.form?.patient;
                                            this.setState({
                                                casePatientModal: patient
                                            })
                                        }}>{'See patient info'.translate(this.props.lang)}</button> : null}
                                    </div>
                                    <FormGroup key='fg-consent'>
                                        <Checkbox disabled={isEditingDisabled} checked={this.state.form.patientConsent} onChange={e => {
                                            if (isEditingDisabled) return;
                                            const patientConsent = e?.target?.checked;
                                            this.setState(prev => ({
                                                ...prev, form: {
                                                    ...prev?.form,
                                                    patientConsent,
                                                    _edited: true
                                                }
                                            }))
                                        }
                                        } label={'Patient has given consent to be referred and their information to be shared'.translate(this.props.lang)} />
                                    </FormGroup>
                                    <FormGroup key='fg-subject'>
                                        <Label className='e-connect-title' htmlFor='e-cr-subject'>{!isEditingDisabled ? '*' : ''}{'Subject'.translate(this.props.lang)}:</Label>
                                        <Input disabled={isEditingDisabled} type='textarea' rows='2' value={this.state.form?.subject || ''} onChange={e => {
                                            if (isEditingDisabled) return;
                                            const subject = e?.target?.value;
                                            this.setState(prev => ({ ...prev, form: { ...prev?.form, _edited: true, subject } }))
                                        }} placeholder={'What is this case in regard to?'.translate(this.props.lang)} />
                                    </FormGroup>
                                </Col>
                                <Col lg='5'>
                                    <h6 className='e-connect-title'>{!isEditingDisabled ? '*' : ''}{'Receiving clinic'.translate(this.props.lang)}<InfoWithTooltip style={{ bottom: 3 }} lang={this.props.lang} text='If receiving clinic is outside of Curoflow e-Connect, referrals can only be printed or saved as PDF' />:</h6>
                                    {this.state.form?.receiver?.type ? <div className={`econnect-receiver-card ${isEditingDisabled ? 'disabled' : ''}`}>
                                        <p style={this.state.form?.receiver?.userName ? { padding: 0, margin: 0 } : undefined} className={this.state.form?.receiver?.userName ? undefined : 'ec-clinic-name'}>{this.state.form?.receiver?.name}</p>
                                        {this.state.form?.receiver?.userName ? <p className='ec-clinic-name'>{this.state.form?.receiver?.userTitle ? `${this.state.form?.receiver?.userTitle} ` : ''}{this.state.form?.receiver?.userName}</p> : null}

                                        {this.state.form?.receiver?.phone || this.state.form?.receiver?.email ? <div className='ec-clinic-contact-div'>
                                            {this.state.form?.receiver?.phone ? <p className='ec-clinic-phone'>{this.state.form?.receiver?.phone}</p> : null}
                                            {this.state.form?.receiver?.email ? <p className='ec-clinic-email'>{this.state.form?.receiver?.email}</p> : null}
                                        </div> : null}

                                        {this.state.form?.receiver?.address ? <div className='ec-clinic-address'>
                                            {this.state.form?.receiver?.address?.split?.(';')?.map?.(addr => <p>
                                                {addr}
                                            </p>)}
                                        </div> : null}

                                        <div className={`ec-clinic-type ec-clinic-type-${this.state.form?.receiver?.type}`}>{this.state.form?.receiver?.type === 'internal' ? 'e-Connect' : 'External'.translate(this.props.lang)}</div>
                                        {!isEditingDisabled ? <button onClick={() => {
                                            if (isEditingDisabled) return;
                                            const receiver = { type: '' };
                                            this.setState(prev => ({
                                                ...prev,
                                                form: {
                                                    ...prev?.form,
                                                    _edited: true,
                                                    attachments: [],
                                                    receiver
                                                }
                                            }))
                                        }}>{'Delete receiver'.translate(this.props.lang)}</button> : null}
                                    </div> : <div>
                                        <Button disabled={isEditingDisabled} color='primary' size='lg' style={{
                                            minWidth: 100
                                        }} onClick={this.addClinicModalHandler}>{'Add'.translate(this.props.lang)}</Button>
                                    </div>}

                                    {this.state.form?.forwarder ? <>
                                        <h6 style={{ marginTop: 10 }} className='e-connect-title'>{'Forwarding clinic'.translate(this.props.lang)}<InfoWithTooltip style={{ bottom: 3 }} lang={this.props.lang} text='This clinic was original receiver, but they forwarded the case to current receiver' />:</h6>
                                        <div className={`econnect-receiver-card disabled`}>
                                            <p style={this.state.form?.forwarder?.userName ? { padding: 0, margin: 0 } : undefined} className={this.state.form?.forwarder?.userName ? undefined : 'ec-clinic-name'}>{this.state.form?.forwarder?.name}</p>
                                            {this.state.form?.forwarder?.userName ? <p className='ec-clinic-name'>{this.state.form?.forwarder?.userTitle ? `${this.state.form?.forwarder?.userTitle} ` : ''}{this.state.form?.forwarder?.userName}</p> : null}

                                            {this.state.form?.forwarder?.phone || this.state.form?.forwarder?.email ? <div className='ec-clinic-contact-div'>
                                                {this.state.form?.forwarder?.phone ? <p className='ec-clinic-phone'>{this.state.form?.forwarder?.phone}</p> : null}
                                                {this.state.form?.forwarder?.email ? <p className='ec-clinic-email'>{this.state.form?.forwarder?.email}</p> : null}
                                            </div> : null}

                                            {this.state.form?.forwarder?.address ? <div className='ec-clinic-address'>
                                                {this.state.form?.forwarder?.address?.split?.(';')?.map?.(addr => <p>
                                                    {addr}
                                                </p>)}
                                            </div> : null}

                                            <div className={`ec-clinic-type ec-clinic-type-${this.state.form?.forwarder?.type}`}>{this.state.form?.forwarder?.type === 'internal' ? 'e-Connect' : 'External'.translate(this.props.lang)}</div>
                                        </div>
                                    </> : null}
                                </Col>
                            </Row>
                            <Row>
                                <Col lg='6'>
                                    <FormGroup>
                                        <Label className='e-connect-title' htmlFor='e-conn-anamnesis'>
                                            {'Anamnesis'.translate(this.props.lang)}:
                                        </Label>
                                        <Input disabled={isEditingDisabled} rows='5' id='e-conn-anamnesis' type='textarea' value={this.state.form?.anamnesis || ''} onChange={e => {
                                            if (isEditingDisabled) return;
                                            const anamnesis = e?.target?.value;
                                            this.setState(prev => ({ ...prev, form: { ...prev?.form, _edited: true, anamnesis } }));
                                        }} />
                                    </FormGroup>
                                </Col>
                                <Col lg='6'>
                                    <FormGroup>
                                        <Label className='e-connect-title' htmlFor='e-conn-status'>
                                            {'Status'.translate(this.props.lang)}:
                                        </Label>
                                        <Input disabled={isEditingDisabled} rows='5' id='e-conn-status' type='textarea' value={this.state.form?.status || ''} onChange={e => {
                                            if (isEditingDisabled) return;
                                            const status = e?.target?.value;
                                            this.setState(prev => ({ ...prev, form: { ...prev?.form, _edited: true, status } }));
                                        }} />
                                    </FormGroup>
                                </Col>
                            </Row>
                            {this.state.form?.receiver?.type && this.state.form?.receiver?.type === 'internal' && (!this.state.form?._id || this.state.form?.sender?.status === 'created' || (this.state.form?.sender?.status !== 'created' && this.state.form?.attachments?.length > 0)) ?
                                <Row>
                                    <Col>
                                        <h6 className='e-connect-title'>
                                            {'Attachments'.translate(this.props.lang)}:
                                            {' '}
                                            {!isEditingDisabled ? <InfoWithTooltip style={{}} text='You can upload as many files and/or images as you need. Just click on the upload button, or drag and drop your files' lang={this.props.lang} /> : null}
                                        </h6>
                                        <div className='e-connect-modal-attachments'>
                                            <div
                                                onDragEnter={e => { e.preventDefault(); e.stopPropagation(); this.setState({ _dragging: true }) }}
                                                onDragOver={e => { e.preventDefault(); e.stopPropagation(); }}
                                                onDragLeave={e => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    this.setState({ _dragging: false })
                                                }}
                                                onDrop={e => {
                                                    e.preventDefault();
                                                    if (isEditingDisabled) return;
                                                    const files = e?.dataTransfer?.files;
                                                    if (files) this.uploadFiles(files);
                                                }}
                                                className={`${isEditingDisabled ? 'ec-disabled' : ''} ${this.state._uploading || this.state._dragging ? 'ec-uploading-pl' : ''}`}
                                            >
                                                {this.state._uploading || this.state._dragging ? <div key='_uploading'>
                                                    {this.state._uploading ? 'Uploading...'.translate(this.props.lang) : this.state._dragging ? 'Drop files here'.translate(this.props.lang) : ''}
                                                </div>
                                                    :
                                                    this.state.form?.attachments?.map?.((item, i) => {
                                                        if (!item?.url) return null;
                                                        return <div
                                                            key={item?.url ? item.url : item?.name ? `${item.name}-${i}` : i}
                                                            className='econnect-attachment-item'
                                                        >
                                                            <div>
                                                                <button
                                                                    title={'Opens in new browser tab'.translate(this.props.lang)}
                                                                    onClick={() => {
                                                                        if (typeof window !== 'undefined') window.open(API_ENDPOINT + item.url, '_blank')
                                                                    }}
                                                                >
                                                                    &#91;
                                                                    {'Open'.translate(this.props.lang)}
                                                                    &#93;
                                                                </button>
                                                                <div>{item?.name}</div>
                                                            </div>
                                                            {!isEditingDisabled ? <button onClick={() => {
                                                                if (isEditingDisabled) return;
                                                                const url = item.url;
                                                                const attachments = this.state.form?.attachments?.filter?.(item => item.url !== url);
                                                                this.setState(prev => ({
                                                                    ...prev,
                                                                    form: {
                                                                        ...prev?.form,
                                                                        attachments,
                                                                        _edited: true
                                                                    }
                                                                }))
                                                            }}>
                                                                <InlineSVG src={trashIcon} title={'Delete'.translate(this.props.lang)} />
                                                            </button> : null}
                                                        </div>
                                                    })}
                                            </div>
                                            <div className={`${isEditingDisabled ? 'ec2-disabled' : ''} ${this.state._uploading ? 'ec2-disabled-indom' : ''}`}>
                                                <Label>
                                                    {'Upload'.translate(this.props.lang)}
                                                    <Input disabled={this.state._uploading} multiple accept={`.png,.jpg,.jpeg,.webp,.pdf,.doc,.docx,.txt,.xls,.xlsx,.mp4,.ppt,.pptx,.json,.csv,${allowedFileTypes.join(',')}`} type='file' onChange={(e) => {
                                                        if (isEditingDisabled) return;
                                                        const files = e.target.files;
                                                        this.uploadFiles(files);
                                                    }} />
                                                </Label>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                :
                                null}

                            {this.state.loading ? <div className='econnect-loader'><Player
                                autoplay={true}
                                loop={true}
                                src="/lf30_editor_l5cxzdyf.json"
                                style={{ height: typeof window != 'undefined' && window.innerWidth < 768 ? "128px" : "256px", width: typeof window != 'undefined' && window.innerWidth < 768 ? "128px" : "256px" }}
                            /></div> : null}
                        </ModalBody>
                        <ModalFooter style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                            <button style={{ visibility: 'hidden', display: 'none' }} aria-hidden='true'></button>

                            <Button
                                style={{ minWidth: 100 }}
                                color='primary'
                                disabled={!this.isSaveAvailable()}
                                onClick={() => {
                                    if (!this.isSaveAvailable()) return;
                                    if (this.state.form?._id) {
                                        this.print(this.state.form._id);
                                    }
                                    else {
                                        this.saveProgress('print');
                                    }
                                }}
                            >{'Save as PDF'.translate(this.props.lang)}</Button>

                            {!isEditingDisabled ? <Button style={{ minWidth: 100 }} color='info' disabled={!this.isSaveAvailable() || this.state._savingProgress} onClick={() => this.saveProgress('save')}>
                                {'Save'.translate(this.props.lang)}
                            </Button> : null}

                            {!isEditingDisabled ? <Button style={{ minWidth: 100 }} color='info' disabled={!this.isSendAvailable() || this.state._savingProgress} onClick={() => this.saveProgress('send')}>
                                {'Send'.translate(this.props.lang)}
                            </Button> : null}

                            {this.state.form?._id && this.state.form?.sender?.status === 'created' && this.props.uData?._id && (String(this.props.uData?._id) === String(this.state.form?.sender?.user)) ? <Button
                                color='danger'
                                disabled={this.state._savingProgress}
                                onClick={() => { this.setState({ deleteModal: this.state.form?._id }) }}
                            >
                                {'Delete'.translate(this.props.lang)}
                            </Button> : null}

                            {this.state.form?.sender?.status === 'rejected' && !this.state.form?.newCaseCreated && String(this.state.form?.sender?.user) === String(this.props.uData?._id) ? <Button disabled={this.state._savingProgress} color='info' onClick={() => {
                                if (this.state.form?.sender?.status === 'rejected' && !this.state.form?.newCaseCreated && this.state.form?._id) {
                                    this.setState({
                                        newCaseModal: this.state.form
                                    });
                                }
                            }}>
                                {'Create New Case'.translate(this.props.lang)}
                            </Button> : null}

                            {this.state.form?.sender?.status && ['accepted', 'rejected'].includes(this.state.form?.sender?.status) && String(this.state.form?.sender?.user) === String(this.props.uData?._id) ? <Button disabled={this.state._savingProgress} color={this.state.form?.sender?.status === 'rejected' ? 'primary' : 'info'} onClick={() => {
                                if (this.state.form?.sender?.status && ['accepted', 'rejected'].includes(this.state.form?.sender?.status) && this.state.form?._id) {
                                    this.setState({
                                        archiveModal: this.state.form?._id
                                    });
                                }
                            }}>
                                {'Archive'.translate(this.props.lang)}
                            </Button> : null}

                            <Button style={{ minWidth: 100 }} onClick={() => {
                                if (!this.state.form?._id) return this.saveFormProgressToLocalStorage(true);
                                if (this.state.form?.sender?.status && this.state.form?.sender?.status === 'created') {
                                    if (!this.state.form?._edited || !this.isSaveAvailable()) return this.setState({ form: null });
                                    this.setState({ closeModal: true });
                                } else {
                                    this.setState({ form: null });
                                }
                            }} color='primary' disabled={this.state._savingProgress}>
                                {'Close'.translate(this.props.lang)}
                            </Button>

                            <button style={{ visibility: 'hidden', display: 'none' }} aria-hidden='true'></button>
                        </ModalFooter>
                    </Modal> : null
                }

                {
                    this.state.addClinicModal && this.state.form ? <Modal key='clinic-modal' isOpen={this.state.addClinicModal} size='lg' centered>
                        <ModalHeader style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gap: 10 }} toggle={() => this.setState({ addClinicModal: null })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ addClinicModal: null })}>&times;</button>}>
                            {'Add Receiving Clinic'.translate(this.props.lang)}
                        </ModalHeader>
                        <ModalBody>
                            <div key='select-clinic-div' className='econnect-available-clinics'>
                                <h6>{'Available Clinics'.translate(this.props.lang)} <InfoWithTooltip style={{ bottom: 4 }} text='If receiving clinic is connected to Curoflow e-Connect, you can find them here. Otherwise, enter their information manually.' lang={this.props.lang} /></h6>
                                {this.state._availableClinics?.length > 0 ? <div className='econnect-available-clinics-container'>
                                    <Input type='search' placeholder={'Search...'.translate(this.props.lang)} onChange={(e) => {
                                        const value = e?.target?.value?.toLocaleLowerCase?.();
                                        const ac = this.state._availableClinics;
                                        if (!ac || !Array.isArray(ac) || !ac.length) return;
                                        if (!value || !value?.trim?.()) {
                                            const addClinicModal = this.state.addClinicModal;
                                            addClinicModal.availableClinics = ac;
                                            return this.setState({ addClinicModal });
                                        }

                                        const availableClinics = [];

                                        for (const clinic of ac) {
                                            const name = clinic?.name?.toLocaleLowerCase?.();
                                            const address = clinic?.address?.toLocaleLowerCase?.();
                                            const phone = clinic?.phone?.toLocaleLowerCase?.();

                                            if (
                                                (name && name.includes?.(value))
                                                || (address && address.includes?.(value))
                                                || (phone && phone.includes?.(value))
                                            ) availableClinics.push(clinic);
                                        }

                                        const addClinicModal = this.state.addClinicModal;
                                        addClinicModal.availableClinics = availableClinics;
                                        this.setState({ addClinicModal });
                                    }} />
                                    {this.state.addClinicModal?.availableClinics?.length ? <div className='econnect-available-clinics-list'>
                                        {this.state.addClinicModal?.availableClinics?.map?.(clinic => <div
                                            key={clinic.id}
                                            onClick={() => {
                                                if (isEditingDisabled) return;
                                                const receiver = {
                                                    type: 'internal',
                                                    id: clinic.id,
                                                    name: clinic.name,
                                                    address: clinic.address,
                                                    phone: clinic.phone,
                                                };
                                                this.setState(prev => ({
                                                    ...prev,
                                                    form: {
                                                        ...prev?.form,
                                                        _edited: true,
                                                        receiver
                                                    },
                                                    addClinicModal: null
                                                }));
                                            }}
                                            title={'Select this clinic'.translate(this.props.lang)}
                                        >
                                            <div>
                                                <img src={clinic.logo} alt='logo' />
                                            </div>
                                            <div>
                                                <div>{clinic.name}</div>
                                                {clinic.address || clinic.phone ? <div>
                                                    {clinic.address ? <div>{clinic.address}</div> : null}
                                                    {clinic.phone ? <div>{clinic.phone?.startsWith?.('+') ? '' : '+'}{clinic.phone}</div> : null}
                                                </div> : null}
                                            </div>
                                        </div>)}
                                    </div> : <p className='e-connect-no-avail-clin'>{'There are no available clinics for the searched term'.translate(this.props.lang)}.</p>}
                                </div> : <p className='e-connect-no-avail-clin'>{'There are no available clinics at the moment'.translate(this.props.lang)}.</p>}
                            </div>
                            {this.state.addClinicModal?.availableClinics?.length > 0 ? <div key='or-div' className='econnect-or-div'>
                                <div />
                                <span>{'or'.translate(this.props.lang)}</span>
                            </div> : null}
                            <div key='manual-clinics-div' className='econnect-manual-clinic'>
                                <Row>
                                    <Col lg='12'>
                                        <FormGroup key='fg-m-clname'>
                                            <Label>
                                                *{'Clinic name'.translate(this.props.lang)}:
                                            </Label>
                                            <Input
                                                disabled={isEditingDisabled}
                                                type='text'
                                                value={this.state.addClinicModal?.manual?.name || ''}
                                                onChange={(e) => {
                                                    if (isEditingDisabled) return;
                                                    const name = e?.target?.value;
                                                    this.setState(prev => ({
                                                        ...prev,
                                                        addClinicModal: {
                                                            ...prev?.addClinicModal,
                                                            manual: {
                                                                ...prev?.addClinicModal?.manual,
                                                                name
                                                            }
                                                        }
                                                    }));
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg='6'>
                                        <FormGroup key='fg-m-clstr'>
                                            <Label>
                                                *{'Street'.translate(this.props.lang)}:
                                            </Label>
                                            <Input
                                                type='text'
                                                disabled={isEditingDisabled}
                                                value={this.state.addClinicModal?.manual?.street || ''}
                                                onChange={(e) => {
                                                    if (isEditingDisabled) return;
                                                    const street = e?.target?.value;
                                                    this.setState(prev => ({
                                                        ...prev,
                                                        addClinicModal: {
                                                            ...prev?.addClinicModal,
                                                            manual: {
                                                                ...prev?.addClinicModal?.manual,
                                                                street
                                                            }
                                                        }
                                                    }));
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col lg='2'>
                                        <FormGroup key='fg-m-clzip'>
                                            <Label>
                                                *{'Zip'.translate(this.props.lang)}:
                                            </Label>
                                            <Input
                                                type='text'
                                                disabled={isEditingDisabled}
                                                value={this.state.addClinicModal?.manual?.zip || ''}
                                                onChange={(e) => {
                                                    if (isEditingDisabled) return;
                                                    const zip = e?.target?.value;
                                                    this.setState(prev => ({
                                                        ...prev,
                                                        addClinicModal: {
                                                            ...prev?.addClinicModal,
                                                            manual: {
                                                                ...prev?.addClinicModal?.manual,
                                                                zip
                                                            }
                                                        }
                                                    }));
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col lg='4'>
                                        <FormGroup key='fg-m-clcty'>
                                            <Label>
                                                *{'City'.translate(this.props.lang)}:
                                            </Label>
                                            <Input
                                                type='text'
                                                disabled={isEditingDisabled}
                                                value={this.state.addClinicModal?.manual?.city || ''}
                                                onChange={(e) => {
                                                    if (isEditingDisabled) return;
                                                    const city = e?.target?.value;
                                                    this.setState(prev => ({
                                                        ...prev,
                                                        addClinicModal: {
                                                            ...prev?.addClinicModal,
                                                            manual: {
                                                                ...prev?.addClinicModal?.manual,
                                                                city
                                                            }
                                                        }
                                                    }));
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg='6'>
                                        <FormGroup key='fg-m-clph'>
                                            <Label>
                                                *{'Phone'.translate(this.props.lang)}:
                                            </Label>
                                            <Input
                                                type='text'
                                                disabled={isEditingDisabled}
                                                value={this.state.addClinicModal?.manual?.phone || ''}
                                                onChange={(e) => {
                                                    if (isEditingDisabled) return;
                                                    const phone = e?.target?.value;
                                                    this.setState(prev => ({
                                                        ...prev,
                                                        addClinicModal: {
                                                            ...prev?.addClinicModal,
                                                            manual: {
                                                                ...prev?.addClinicModal?.manual,
                                                                phone
                                                            }
                                                        }
                                                    }));
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col lg='6'>
                                        <FormGroup key='fg-m-clemail'>
                                            <Label>
                                                {'Email address'.translate(this.props.lang)}:
                                            </Label>
                                            <Input
                                                type='text'
                                                disabled={isEditingDisabled}
                                                value={this.state.addClinicModal?.manual?.email || ''}
                                                onChange={(e) => {
                                                    if (isEditingDisabled) return;
                                                    const email = e?.target?.value;
                                                    this.setState(prev => ({
                                                        ...prev,
                                                        addClinicModal: {
                                                            ...prev?.addClinicModal,
                                                            manual: {
                                                                ...prev?.addClinicModal?.manual,
                                                                email
                                                            }
                                                        }
                                                    }));
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </div>
                        </ModalBody>
                        <ModalFooter style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                            <button style={{ visibility: 'hidden', display: 'none' }} aria-hidden='true'></button>
                            <Button disabled={!this.state.addClinicModal?.manual || !this.state.addClinicModal?.manual?.name || !this.state.addClinicModal?.manual?.street || !this.state.addClinicModal?.manual?.zip || !this.state.addClinicModal?.manual?.city || !this.state.addClinicModal?.manual?.phone || isEditingDisabled} color='primary' style={{ minWidth: 100 }} onClick={() => {
                                if (!this.state.form) return;
                                if (isEditingDisabled) return;
                                const data = this.state.addClinicModal?.manual;
                                if (!data || !data.name || !data.street || !data.zip || !data.city || !data.phone) return;
                                const address = `${data.street}; ${data.zip} ${data.city}`
                                this.setState(prev => ({
                                    ...prev,
                                    form: {
                                        ...prev?.form,
                                        _edited: true,
                                        receiver: {
                                            ...(prev?.form?.receiver || {}),
                                            type: 'external',
                                            name: data.name,
                                            address,
                                            phone: data.phone,
                                            email: data.email || '',
                                        }
                                    },
                                    addClinicModal: null
                                }))
                            }}>
                                {'Add Manually'.translate(this.props.lang)}
                            </Button>
                            <Button style={{ minWidth: 100 }} onClick={() => this.setState({ addClinicModal: null })} color='danger'>
                                {'Close'.translate(this.props.lang)}
                            </Button>
                            <button style={{ visibility: 'hidden', display: 'none' }} aria-hidden='true'></button>
                        </ModalFooter>
                    </Modal> : null
                }

                {this.state.casePatientModal ? <Modal key='clinic-patient-info' isOpen={this.state.casePatientModal} size='md' centered>
                    <ModalHeader style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gap: 10 }} toggle={() => this.setState({ casePatientModal: null })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ casePatientModal: null })}>&times;</button>}>
                        {'Patient'.translate(this.props.lang)}
                        <InfoWithTooltip style={{ bottom: 4 }} lang={this.props.lang} text='Information about patient correct as of the moment of case creation' />
                    </ModalHeader>
                    <ModalBody>
                        <div className='eb-modal-div'>
                            <h6>{'Name'.translate(this.props.lang || 'en')}</h6>
                            <div>{this.state.casePatientModal?.name || 'N/A'}</div>
                        </div>
                        {this.state.casePatientModal?.ssn ? <div className='eb-modal-div'>
                            <h6>{'Social security number'.translate(this.props.lang || 'en')}</h6>
                            <div>{this.state.casePatientModal?.ssn || 'N/A'}</div>
                        </div> : this.state.casePatientModal?.dateOfBirth ? <div className='eb-modal-div'>
                            <h6>{'Date of Birth'.translate(this.props.lang || 'en')}</h6>
                            <div>{this.state.casePatientModal?.dateOfBirth || 'N/A'}</div>
                        </div> : null}
                        <div className='eb-modal-div'>
                            <h6>{'Sex'.translate(this.props.lang || 'en')}</h6>
                            <div>{this.state.casePatientModal?.gender?.translate?.(this.props.lang) || 'N/A'}</div>
                        </div>
                        {/* <div className='eb-modal-div'>
                            <h6>{'Language'.translate(this.props.lang || 'en')}</h6>
                            <div>{this.state.casePatientModal?.language?.translate?.(this.props.lang) || 'N/A'}</div>
                        </div> */}
                        <div className='eb-modal-div'>
                            <h6>{'Phone Number'.translate(this.props.lang || 'en')}</h6>
                            <div>{this.state.casePatientModal?.phone || 'N/A'}</div>
                        </div>
                        <div className='eb-modal-div'>
                            <h6>{'Email Address'.translate(this.props.lang || 'en')}</h6>
                            <div>{this.state.casePatientModal?.email || 'N/A'}</div>
                        </div>
                    </ModalBody>
                    <ModalFooter style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                        <button style={{ visibility: 'hidden', display: 'none' }} aria-hidden='true'></button>
                        {canOpenProfile && this.state.casePatientModal?.type && this.state.casePatientModal?.type === 'internal' ? <Button color='primary' onClick={() => {
                            if (!canOpenProfile) return;
                            this.searchPatientWithRedirect(this.state.casePatientModal?.ssn, enterprise);
                        }}>
                            {'Open Patient Profile'.translate(this.props.lang)}
                        </Button> : null}
                        <Button color='primary' onClick={() => this.setState({ casePatientModal: null })}>
                            {'Close'.translate(this.props.lang)}
                        </Button>
                        <button style={{ visibility: 'hidden', display: 'none' }} aria-hidden='true'></button>
                    </ModalFooter>
                </Modal> : null}

                {this.state.clinicDetails ? <Modal key='clinic-patient-info' isOpen={this.state.clinicDetails} size='md' centered>
                    <ModalHeader style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gap: 10 }} toggle={() => this.setState({ clinicDetails: null })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ clinicDetails: null })}>&times;</button>}>
                        {'Receiving clinic'.translate(this.props.lang)}
                        {/* <InfoWithTooltip style={{ bottom: 4 }} lang={this.props.lang} text='Information about patient correct as of the moment of case creation' /> */}
                    </ModalHeader>
                    <ModalBody>
                        <div className='eb-modal-div'>
                            <h6>{'Name'.translate(this.props.lang || 'en')}</h6>
                            <div>{this.state.clinicDetails?.name || 'N/A'}</div>
                        </div>
                        <div className='eb-modal-div'>
                            <h6>{'Address'.translate(this.props.lang || 'en')}</h6>
                            <div>{this.state.clinicDetails?.address || 'N/A'}</div>
                        </div>
                        <div className='eb-modal-div'>
                            <h6>{'Personnel in charge of the case'.translate(this.props.lang || 'en')}</h6>
                            <div>{this.state.clinicDetails?.userName ? `${this.state.clinicDetails?.userTitle ? `${this.state.clinicDetails?.userTitle} ` : ''}${this.state.clinicDetails?.userName}` : 'N/A'}</div>
                        </div>
                        <div className='eb-modal-div'>
                            <h6>{'Phone Number'.translate(this.props.lang || 'en')}</h6>
                            <div>{this.state.clinicDetails?.phone ? '+' : null} {this.state.clinicDetails?.phone || 'N/A'}</div>
                        </div>
                        <div className='eb-modal-div'>
                            <h6>{'Email Address'.translate(this.props.lang || 'en')}</h6>
                            <div>{this.state.clinicDetails?.email || 'N/A'}</div>
                        </div>
                    </ModalBody>
                    <ModalFooter style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                        <button style={{ visibility: 'hidden', display: 'none' }} aria-hidden='true'></button>
                        <Button color='primary' onClick={() => this.setState({ clinicDetails: null })}>
                            {'Close'.translate(this.props.lang)}
                        </Button>
                        <button style={{ visibility: 'hidden', display: 'none' }} aria-hidden='true'></button>
                    </ModalFooter>
                </Modal> : null}

                {
                    this.state.patientModal ? <Modal key='patient-modal' isOpen={this.state.patientModal} size={!enterprise && !canSearchPatients ? 'lg' : ''} centered>
                        <ModalHeader toggle={() => this.setState({ patientModal: !this.state.patientModal, patientSocialSecurityNumber: '', patient: null, age: null })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ patientModal: !this.state.patientModal, patientSocialSecurityNumber: '', patient: null, age: null })}>&times;</button>}>{'Find patient'.translate(this.props.lang)}</ModalHeader>

                        <ModalBody>
                            {
                                !enterprise && !canSearchPatients ?
                                    <PatientFilter
                                        {...this.props}
                                        hideUserLevel={true}
                                        hideForUser={this.props.uData._id}
                                        value={this.state.patientModal.title}
                                        onChange={(val) => {
                                            let patientModal = this.state.patientModal;
                                            patientModal.title = val.userData.name.trim();
                                            patientModal.socialSecurityNumber = val.socialSecurityNumber;
                                            patientModal.patient = val._id;

                                            this.setState({ scheduleControl: this.state.patientModal, patientModal: !this.state.patientModal, patientSocialSecurityNumber: '' }, () => {
                                                this.patientAge(val._id)
                                            })
                                        }}>
                                    </PatientFilter>
                                    :
                                    <div>
                                        <div style={this.state.searchByNameAndEmail ? { marginBottom: 90 } : this.props.country === 'uk' ? { alignItems: 'flex-end' } : {}} className='patient-search-modal-calendar'>
                                            <SocialSecurityNumber
                                                includeChildren={true}
                                                hideFindChildren={true}
                                                // hideDateOfBirth={true}
                                                // inThisGroup={true}
                                                disableNewPatientBtn={true}
                                                searchByNameAndEmail={this.state.searchByNameAndEmail || true}
                                                searchByNameAndEmailShow={(show) => {
                                                    this.setState({
                                                        searchByNameAndEmail: show
                                                    })
                                                }}
                                                selectedGroup={this.props.selectedGroup}
                                                selectedClinic={this.props.selectedClinic}
                                                // label={this.props.country === 'uk' ? "Date of birth".translate(this.props.lang) : "Social security number".translate(this.props.lang)}
                                                // label={this.props.country === 'uk' ? "Search patient by email".translate(this.props.lang) : ''}
                                                error={isSocialSecurityNumberValid(this.state.patientSocialSecurityNumber, this.props.country) ? null : 'Social security number is incorrect'.translate(this.props.lang)}
                                                type={'text'}
                                                // labelInfo={'Searches are logged'.translate(this.props.lang)}
                                                country={this.props.country}
                                                placeholder={this.props.country === 'denmark' ? 'DDMMYYNNNNN' : this.props.lang === 'en' ? 'YYYYMMDDNNNN' : 'ÅÅÅÅMMDDNNNN'}
                                                lang={this.props.lang}
                                                value={this.state.patientSocialSecurityNumber}
                                                onKeyUp={(e) => {
                                                    if (e.keyCode == 13 && this.state.patientSocialSecurityNumber && ((this.props.country === 'denmark' && !this.state.patientSocialSecurityNumber.length === 10) || this.state.patientSocialSecurityNumber.length === 12) && isSocialSecurityNumberValid(this.state.patientSocialSecurityNumber, this.props.country)) {
                                                        this.findPatient()
                                                    }
                                                }}
                                                onChange={(value, callback) => {
                                                    this.setState({
                                                        patientSocialSecurityNumber: value
                                                    }, () => {
                                                        if (callback) {
                                                            callback()
                                                        }
                                                    })
                                                }}
                                                selectUserCallback={() => {
                                                    if (!(!this.state.patientSocialSecurityNumber || !isSocialSecurityNumberValid(this.state.patientSocialSecurityNumber, this.props.country))) {
                                                        this.findPatient()
                                                    }
                                                }}

                                            ></SocialSecurityNumber>
                                            <Button style={this.props.country === 'uk' ? { marginBottom: 8 } : {}} disabled={!this.state.patientSocialSecurityNumber || !isSocialSecurityNumberValid(this.state.patientSocialSecurityNumber, this.props.country)} className='patient-search-modal-calendar-button' color='primary' onClick={() => {
                                                console.log(this.state.patientSocialSecurityNumber)
                                                this.findPatient()
                                            }}>{'Search'.translate(this.props.lang)}</Button>
                                        </div>
                                        {
                                            this.state.patient ?
                                                <FormGroup>
                                                    <Label>{'Search results'.translate(this.props.lang)}</Label>
                                                    <div className='patient-search-modal-result' onClick={() => {
                                                        if (this.state.patient?.twoFactorAuthentication?.[this.props.selectedGroup]?.createAccount && !this.state.patient?.twoFactorAuthentication?.[this.props.selectedGroup]?.infoIsVerified) return;

                                                        const patient = this.state.patient;
                                                        if (!patient || !patient._id) return this.setState({ error: defaultError });

                                                        this.startFormSubmission({
                                                            id: patient._id,
                                                            name: patient.userData?.name || patient.name || '',
                                                            ssn: patient.socialSecurityNumber
                                                        });
                                                    }}>
                                                        <div>
                                                            <p>{'Name'.translate(this.props.lang)}</p>
                                                            <p className='patient-search-modal-result-text'>{this.state.patient.name}</p>
                                                        </div>
                                                        <div>
                                                            <p>{'Social security number'.translate(this.props.lang)}</p>
                                                            <p className='patient-search-modal-result-text'>{this.state.patient.socialSecurityNumber}</p>
                                                        </div>
                                                        {this.state.patient?.twoFactorAuthentication?.[this.props.selectedGroup]?.createAccount && !this.state.patient?.twoFactorAuthentication?.[this.props.selectedGroup]?.infoIsVerified ? <div>
                                                            <p>{'Info'.translate(this.props.lang)}</p>
                                                            <p className='patient-search-modal-result-text' style={{ textAlign: 'center' }}>
                                                                <UserIsNotVerifiedTooltip lang={this.props.lang} />
                                                            </p>
                                                        </div> : (this.state.patient?.twoFactorAuthentication && Object.keys(this.state.patient?.twoFactorAuthentication)?.find(key => this.state.patient?.twoFactorAuthentication?.[key]?.createAccount)) ? <div>
                                                            <p>{'Info'.translate(this.props.lang)}</p>
                                                            <p className='patient-search-modal-result-text' style={{ textAlign: 'center' }}>
                                                                <UserIsNotVerifiedTooltip lang={this.props.lang} warning />
                                                            </p>
                                                        </div> : null}
                                                    </div>
                                                    {
                                                        isChild ?
                                                            <p style={{ color: 'red' }}>{`Child is under 13 years of age - Appointments are handled by parent or legal guardian.`.translate(this.props.lang)}</p>
                                                            :
                                                            null
                                                    }

                                                </FormGroup>
                                                :
                                                null
                                        }
                                    </div>
                            }





                        </ModalBody>
                    </Modal> : null
                }

                {
                    this.state.errorPatientNotFound ? <Modal key='error-patient-modal' isOpen={this.state.errorPatientNotFound} toggle={() => this.setState({ errorPatientNotFound: !this.state.errorPatientNotFound })} centered>
                        <ModalHeader style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            {'Patient not found'.translate(this.props.lang)}
                        </ModalHeader>
                        <ModalBody className='delete-modal'>
                            <p>{this.state.errorPatientNotFound}</p>
                        </ModalBody>
                        <ModalFooter style={{ justifyContent: 'center' }}>
                            <Button style={{ minWidth: 100 }} className={'danger-button'} onClick={() => this.setState({ errorPatientNotFound: null })}>{'Cancel'.translate(this.props.lang)}</Button>
                            <Button style={{ minWidth: 100 }} color='primary' onClick={() => this.setState({ errorPatientNotFound: null, patientModal: null, registerNewPatientModal: { language: this.state.languages && this.state.languages[0] && this.state.languages[0]._id, socialSecurityNumber: this.state.patientSocialSecurityNumber, twoFactorAuthenticationEnabled: this.props.country === 'uk' ? true : false } }, () => {
                                this.checkSSN(this.state.patientSocialSecurityNumber)
                            })}>{'Register a new patient'.translate(this.props.lang)}</Button>

                        </ModalFooter>
                    </Modal> : null
                }
                {this.state.registerNewPatientModal ?
                    <FormBuilder
                        selectedGroup={this.props.selectedGroup}
                        selectedClinic={this.props.selectedClinic}
                        countryTwoLetter={this.props.countryTwoLetter}
                        country={this.props.country} dateFormat={this.props.dateFormat}
                        getStringDateTs={this.props.getStringDateTs}
                        onSubmit={(data) => {
                            if (!this.state.ssnExistInThisGroup) {
                                this.checkPatientValidations(data)
                            } else {
                                this.setState({ registerNewPatientModal: null, ssnExistInThisGroup: null, socialSecurityNumberExist: null })
                            }

                        }}
                        initialValues={this.state.registerNewPatientModal}
                        size="lg"
                        isOpen={this.state.registerNewPatientModal}
                        toggle={() => this.setState({ registerNewPatientModal: null, age: null, ssnExistInThisGroup: null, socialSecurityNumberExist: null })}
                        title={this.state.registerNewPatientModal._id ? 'Edit patient'.translate(this.props.lang) : 'Register a new patient'.translate(this.props.lang)}
                        _saving={this.state._saving}
                        buttonConfirmText={'Saved'.translate(this.props.lang)}
                        buttonText={!this.state.ssnExistInThisGroup ? 'Save and send confirmation'.translate(this.props.lang) : 'Close'.translate(this.props.lang)}

                        lang={this.props.lang}
                        fields={[
                            {
                                type: 'row',
                                children: [
                                    {
                                        type: 'col',
                                        width: {
                                            lg: 8,
                                            sm: 12,
                                            xs: 12
                                        },
                                        children: [
                                            {
                                                type: 'socialSecurityNumber',
                                                name: 'socialSecurityNumber',
                                                label: this.props.country === 'uk' ? "Date of birth".translate(this.props.lang) : 'Social security number'.translate(this.props.lang),
                                                hideFindChildren: true,
                                                disableNewPatientBtn: true,
                                                disabled: true,
                                                validate: [required('Social security number is required!'.translate(this.props.lang)), checkSocialSecurityNumber('Social security number is not valid!'.translate(this.props.lang), this.props.country)]

                                            },
                                        ]
                                    },
                                    this.props.showDateOfBirth ?
                                        {
                                            type: 'col',
                                            width: {
                                                lg: 4,
                                                sm: 12,
                                                xs: 12
                                            },
                                            children: [
                                                {
                                                    type: 'date-of-birth',
                                                    dateFormat: this.props.dateFormat,
                                                    name: 'dateOfBirth.date',
                                                    disabled: this.state.disabledDateOfBirth,
                                                    label: 'Date of birth'.translate(this.props.lang),
                                                    validate: [required('Date of birth is required!'.translate(this.props.lang)), dateValidate('Invalid date!'.translate(this.props.lang))]

                                                },
                                            ]
                                        } : null,
                                    this.state.socialSecurityNumberExist && !this.state.ssnExistInThisGroup ?
                                        {
                                            type: 'col',
                                            width: {
                                                lg: 12,
                                                sm: 12,
                                                xs: 12
                                            },
                                            children: [
                                                {
                                                    type: 'h6',
                                                    text: 'The user already exists in the system'.translate(this.props.lang),
                                                    style: { marginBottom: 20 }
                                                },
                                            ]
                                        } : { type: 'empty' },
                                    this.state.socialSecurityNumberExist && this.state.ssnExistInThisGroup ?
                                        {
                                            type: 'col',
                                            width: {
                                                lg: 12,
                                                sm: 12,
                                                xs: 12
                                            },
                                            children: [
                                                {
                                                    type: 'h6',
                                                    text: "The user is already registered within your clinic group".translate(this.props.lang),
                                                    style: { marginBottom: 20 }
                                                },
                                            ]
                                        } : { type: 'empty' },

                                    !this.state.socialSecurityNumberExist /*|| (isChild && this.state.socialSecurityNumberExist)*/ ?
                                        {
                                            type: 'col',
                                            width: {
                                                lg: 4,
                                                sm: 12,
                                                xs: 12
                                            },
                                            children: [
                                                {
                                                    type: 'select',
                                                    name: 'userData.sex',
                                                    labelInfo: "The patient's biological sex is needed for proper treatment".translate(this.props.lang),
                                                    label: 'Sex'.translate(this.props.lang),
                                                    disabled: this.state.socialSecurityNumberExist && !this.state.ssnExistInThisGroup ? true : false,
                                                    values: [{ value: 0, name: 'Select'.translate(this.props.lang) }, { value: 'male', name: 'Male'.translate(this.props.lang) }, { value: 'female', name: 'Female'.translate(this.props.lang) }],
                                                },
                                            ]
                                        } : { type: 'empty' },
                                    !this.state.socialSecurityNumberExist /*|| (isChild && this.state.socialSecurityNumberExist)*/ ?
                                        {
                                            type: 'col',
                                            width: {
                                                lg: this.props.showDateOfBirth ? this.props.secondaryLanguageDisabled ? 6 : 4 : this.props.secondaryLanguageDisabled ? 12 : 8,
                                                sm: 12,
                                                xs: 12
                                            },
                                            children: [
                                                {
                                                    type: 'text',
                                                    name: 'userData.name',
                                                    label: 'First and last name'.translate(this.props.lang),
                                                    disabled: this.state.socialSecurityNumberExist && !this.state.ssnExistInThisGroup ? true : false,
                                                    validate: [required('Name is required!'.translate(this.props.lang))]
                                                },
                                            ]
                                        } : { type: 'empty' },
                                    isChild ?
                                        {
                                            type: 'col',
                                            width: {
                                                lg: 12,
                                                sm: 12,
                                                xs: 12
                                            },
                                            children: [
                                                {
                                                    type: 'h6',
                                                    style: { color: 'red', marginBottom: 20 },
                                                    text: `Child is under 13 years of age - Account must be connected to a parent- or legal guardian account.`.translate(this.props.lang),
                                                },
                                            ]
                                        } : { type: 'empty' },
                                    !this.state.socialSecurityNumberExist && isChild /*|| (isChild && this.state.socialSecurityNumberExist)*/ ?
                                        {
                                            type: 'col',
                                            width: {
                                                lg: 12,
                                                sm: 12,
                                                xs: 12
                                            },
                                            children: [
                                                {
                                                    type: 'h6',
                                                    style: { marginBottom: 20 },
                                                    text: 'Register parent or legal guardian'.translate(this.props.lang),
                                                },
                                            ]
                                        } : { type: 'empty' },
                                    !this.state.socialSecurityNumberExist && isChild /*|| (isChild && this.state.socialSecurityNumberExist)*/ ?
                                        {
                                            type: 'col',
                                            width: {
                                                lg: 8,
                                                sm: 12,
                                                xs: 12
                                            },
                                            children: [
                                                {
                                                    type: 'socialSecurityNumber',
                                                    name: 'socialSecurityNumberParent',
                                                    hideFindChildren: true,
                                                    disableNewPatientBtn: true,
                                                    label: this.props.country === 'uk' ? "Date of birth".translate(this.props.lang) : 'Social security number'.translate(this.props.lang),
                                                    searchByNameAndEmailFormBuilder: this.state.socialSecurityNumberExist && !this.state.ssnExistInThisGroup ? false : true,
                                                    disabled: this.state.socialSecurityNumberExist && !this.state.ssnExistInThisGroup ? true : false,
                                                    validate: [required('Social security number is required!'.translate(this.props.lang)), checkSocialSecurityNumber('Social security number is not valid!'.translate(this.props.lang), this.props.country)]
                                                },
                                            ]
                                        } : { type: 'empty' },
                                    isChild && this.props.showDateOfBirth ?
                                        {
                                            type: 'col',
                                            width: {
                                                lg: 4,
                                                sm: 12,
                                                xs: 12
                                            },
                                            children: [
                                                {
                                                    type: 'date-of-birth',
                                                    name: 'dateOfBirthParent.date',
                                                    disabled: this.state.disabledDateOfBirthParent,
                                                    label: 'Date of birth'.translate(this.props.lang),
                                                    validate: [required('Date of birth is required!'.translate(this.props.lang)), dateValidate('Invalid date!'.translate(this.props.lang))]

                                                },
                                            ]
                                        } : { type: 'empty' },
                                    !this.state.socialSecurityNumberExist && isChild /*|| (isChild && this.state.socialSecurityNumberExist)*/ ?
                                        {
                                            type: 'col',
                                            width: {
                                                lg: 4,
                                                sm: 12,
                                                xs: 12
                                            },
                                            children: [
                                                {
                                                    type: 'select',
                                                    name: 'sexParent',
                                                    label: 'Sex'.translate(this.props.lang),
                                                    disabled: this.state.socialSecurityNumberExist && !this.state.ssnExistInThisGroup ? true : false,
                                                    values: [{ value: 0, name: 'Select'.translate(this.props.lang) }, { value: 'male', name: 'Male'.translate(this.props.lang) }, { value: 'female', name: 'Female'.translate(this.props.lang) }],
                                                },
                                            ]
                                        } : { type: 'empty' },
                                    !this.state.socialSecurityNumberExist && isChild /*|| (isChild && this.state.socialSecurityNumberExist)*/ ?
                                        {
                                            type: 'col',
                                            width: {
                                                lg: this.props.showDateOfBirth ? this.props.secondaryLanguageDisabled ? 6 : 4 : this.props.secondaryLanguageDisabled ? 12 : 8,
                                                sm: 12,
                                                xs: 12
                                            },
                                            children: [
                                                {
                                                    type: 'text',
                                                    name: 'nameParent',
                                                    label: 'First and last name'.translate(this.props.lang),
                                                    disabled: this.state.socialSecurityNumberExist && !this.state.ssnExistInThisGroup ? true : false,
                                                    validate: [required('Name is required!'.translate(this.props.lang))]


                                                },
                                            ]
                                        } : { type: 'empty' },
                                    (!this.state.socialSecurityNumberExist /*|| (isChild && this.state.socialSecurityNumberExist)*/) && !this.props.secondaryLanguageDisabled ?
                                        {
                                            type: 'col',
                                            width: {
                                                lg: 4,
                                                sm: 12,
                                                xs: 12
                                            },
                                            children: [
                                                {
                                                    type: 'select',
                                                    name: 'language',
                                                    label: 'Language'.translate(this.props.lang),
                                                    disabled: this.state.socialSecurityNumberExist && !this.state.ssnExistInThisGroup ? true : false,
                                                    values: this.state.languages?.map(item => { return { name: item.name?.translate(this.props.lang), value: item._id } })
                                                },
                                            ]
                                        } : { type: 'empty' },

                                    !this.state.socialSecurityNumberExist /*|| (isChild && this.state.socialSecurityNumberExist)*/ ?
                                        {
                                            type: 'col',
                                            width: {
                                                lg: 6,
                                                sm: 12,
                                                xs: 12
                                            },
                                            children: [
                                                {
                                                    type: 'text',
                                                    name: 'email',
                                                    label: 'Email address'.translate(this.props.lang),
                                                    disabled: this.state.socialSecurityNumberExist && !this.state.ssnExistInThisGroup ? true : false,
                                                    validate: this.props.twoFactorAuthenticationEnabled ? [required('When two factor authentication is enabled, both email and phone number are required!'.translate(this.props.lang))] : []
                                                },
                                            ]
                                        } : { type: 'empty' },
                                    !this.state.socialSecurityNumberExist /*|| (isChild && this.state.socialSecurityNumberExist)*/ ?
                                        {
                                            type: 'col',
                                            width: {
                                                lg: 6,
                                                sm: 12,
                                                xs: 12
                                            },
                                            children: [
                                                {
                                                    type: 'phone',

                                                    name: 'phone',
                                                    label: 'Phone'.translate(this.props.lang),
                                                    disabled: this.state.socialSecurityNumberExist && !this.state.ssnExistInThisGroup ? true : false,
                                                    validate: this.props.twoFactorAuthenticationEnabled ? [required('When two factor authentication is enabled, both email and phone number are required!'.translate(this.props.lang))] : []
                                                },
                                            ]
                                        } : { type: 'empty' },

                                    //

                                    !this.state.socialSecurityNumberExist && isChild /*|| (isChild && this.state.socialSecurityNumberExist)*/ ?
                                        {
                                            type: 'col',
                                            width: {
                                                lg: 12,
                                                sm: 12,
                                                xs: 12
                                            },
                                            children: [
                                                {
                                                    type: 'h6',
                                                    style: { marginBottom: 20 },
                                                    text: 'Register second parent or legal guardian'.translate(this.props.lang),
                                                },
                                            ]
                                        } : { type: 'empty' },
                                    !this.state.socialSecurityNumberExist && isChild/*|| (isChild && this.state.socialSecurityNumberExist)*/ ?
                                        {
                                            type: 'col',
                                            width: {
                                                lg: 8,
                                                sm: 12,
                                                xs: 12
                                            },
                                            children: [
                                                {
                                                    type: 'socialSecurityNumber',
                                                    name: 'socialSecurityNumberParent2',
                                                    hideFindChildren: true,
                                                    searchByNameAndEmailFormBuilder: this.state.socialSecurityNumberExist && !this.state.ssnExistInThisGroup ? false : true,
                                                    disableNewPatientBtn: true,
                                                    label: this.props.country === 'uk' ? "Date of birth".translate(this.props.lang) : 'Social security number'.translate(this.props.lang),
                                                    disabled: this.state.socialSecurityNumberExist && !this.state.ssnExistInThisGroup ? true : false,
                                                    validate: !this.state.parent2Required ? [] : [required('Social security number is required!'.translate(this.props.lang)), checkSocialSecurityNumber('Social security number is not valid!'.translate(this.props.lang), this.props.country)]
                                                },
                                            ]
                                        } : { type: 'empty' },
                                    isChild && this.props.showDateOfBirth ?
                                        {
                                            type: 'col',
                                            width: {
                                                lg: 4,
                                                sm: 12,
                                                xs: 12
                                            },
                                            children: [
                                                {
                                                    type: 'date-of-birth',
                                                    dateFormat: this.props.dateFormat,
                                                    name: 'dateOfBirthParent2.date',
                                                    disabled: this.state.disabledDateOfBirthParent2,
                                                    label: 'Date of birth'.translate(this.props.lang),
                                                    validate: !this.state.parent2Required ? [] : [required('Date of birth is required!'.translate(this.props.lang)), dateValidate('Invalid date!'.translate(this.props.lang))]

                                                },
                                            ]
                                        } : { type: 'empty' },
                                    !this.state.socialSecurityNumberExist && isChild /*|| (isChild && this.state.socialSecurityNumberExist)*/ ?
                                        {
                                            type: 'col',
                                            width: {
                                                lg: 4,
                                                sm: 12,
                                                xs: 12
                                            },
                                            children: [
                                                {
                                                    type: 'select',
                                                    name: 'sexParent2',
                                                    label: 'Sex'.translate(this.props.lang),
                                                    disabled: this.state.socialSecurityNumberExist && !this.state.ssnExistInThisGroup ? true : false,
                                                    values: [{ value: 0, name: 'Select'.translate(this.props.lang) }, { value: 'male', name: 'Male'.translate(this.props.lang) }, { value: 'female', name: 'Female'.translate(this.props.lang) }],
                                                },
                                            ]
                                        } : { type: 'empty' },
                                    !this.state.socialSecurityNumberExist && isChild /*|| (isChild && this.state.socialSecurityNumberExist)*/ ?
                                        {
                                            type: 'col',
                                            width: {
                                                lg: this.props.showDateOfBirth ? this.props.secondaryLanguageDisabled ? 6 : 4 : this.props.secondaryLanguageDisabled ? 12 : 8,
                                                sm: 12,
                                                xs: 12
                                            },
                                            children: [
                                                {
                                                    type: 'text',
                                                    name: 'nameParent2',
                                                    label: 'First and last name'.translate(this.props.lang),
                                                    validate: !this.state.parent2Required ? [] : [required('Name is required!'.translate(this.props.lang))],
                                                    disabled: this.state.socialSecurityNumberExist && !this.state.ssnExistInThisGroup ? true : false,

                                                },
                                            ]
                                        } : { type: 'empty' },
                                    !this.state.socialSecurityNumberExist && isChild /*|| (isChild && this.state.socialSecurityNumberExist)*/ && !this.props.secondaryLanguageDisabled ?
                                        {
                                            type: 'col',
                                            width: {
                                                lg: 4,
                                                sm: 12,
                                                xs: 12
                                            },
                                            children: [
                                                {
                                                    type: 'select',
                                                    name: 'language2',
                                                    label: 'Language'.translate(this.props.lang),
                                                    disabled: this.state.socialSecurityNumberExist && !this.state.ssnExistInThisGroup ? true : false,
                                                    values: this.state.languages.map(item => { return { name: item.name, value: item._id } })
                                                },
                                            ]
                                        } : { type: 'empty' },

                                    !this.state.socialSecurityNumberExist && isChild /*|| (isChild && this.state.socialSecurityNumberExist)*/ ?
                                        {
                                            type: 'col',
                                            width: {
                                                lg: 6,
                                                sm: 12,
                                                xs: 12
                                            },
                                            children: [
                                                {
                                                    type: 'text',
                                                    name: 'email2',
                                                    label: 'Email address'.translate(this.props.lang),
                                                    disabled: this.state.socialSecurityNumberExist && !this.state.ssnExistInThisGroup ? true : false,
                                                    validate: !this.state.parent2Required ? [] : this.props.twoFactorAuthenticationEnabled ? [] : []
                                                },
                                            ]
                                        } : { type: 'empty' },
                                    !this.state.socialSecurityNumberExist && isChild /*|| (isChild && this.state.socialSecurityNumberExist)*/ ?
                                        {
                                            type: 'col',
                                            width: {
                                                lg: 6,
                                                sm: 12,
                                                xs: 12
                                            },
                                            children: [
                                                {
                                                    type: 'phone',

                                                    name: 'phone2',
                                                    label: 'Phone'.translate(this.props.lang),
                                                    disabled: this.state.socialSecurityNumberExist && !this.state.ssnExistInThisGroup ? true : false,
                                                    validate: !this.state.parent2Required ? [] : this.props.twoFactorAuthenticationEnabled ? [] : []
                                                },
                                            ]
                                        } : { type: 'empty' },
                                    //
                                    !this.state.socialSecurityNumberExist && this.state.clinicGroupSettings?.twoFactorAuthentication ?
                                        {
                                            type: 'col',
                                            width: {
                                                lg: 12,
                                                sm: 12,
                                                xs: 12
                                            },
                                            children: [
                                                {
                                                    type: 'checkbox',
                                                    name: 'twoFactorAuthenticationEnabled',
                                                    label: 'Enable login with two factor authentication'.translate(this.props.lang),
                                                    labelInfo: 'Login link is sent to the users phone number and email. The link is valid for 7 days.'.translate(this.props.lang)
                                                }
                                            ]

                                        }
                                        : { type: 'empty' },
                                    !this.state.ssnExistInThisGroup ?
                                        {
                                            type: 'col',
                                            width: {
                                                lg: 12,
                                                sm: 12,
                                                xs: 12
                                            },
                                            children: [
                                                {
                                                    type: 'checkbox',
                                                    validate: [required('Consent is required!'.translate(this.props.lang))],
                                                    name: 'consentAccepted',
                                                    label: 'Consent has been obtained'.translate(this.props.lang),

                                                },
                                            ]
                                        } : { type: 'empty' },




                                ]
                            }
                        ]}
                    ></FormBuilder> : null
                }

                {this.state.saveModal ? <InfoModal
                    isOpen={this.state.saveModal}
                    onClose={(item) => {
                        this.setState({
                            saveModal: item
                        })
                    }}
                    // toggle={() => this.setState({ saveModal: false })}
                    header={'Warning'.translate(this.props.lang)}
                    info={this.state.saveModal?.twoFactorAuthenticationEnabled
                        ? 'Is the information correct? Incorrect information may lead to the wrong person accessing the account'.translate(this.props.lang)
                        : 'Is it true that consent has been obtained to open the account'.translate(this.props.lang)}
                    buttons={[
                        <button style={{ visibility: 'hidden', display: 'none' }} aria-hidden='true'></button>,
                        <Button
                            color='success'
                            style={{ margin: 10 }}
                            onClick={() => this.addPatient(this.state.saveModal)}
                        >
                            {this.state.saveModal?.twoFactorAuthenticationEnabled
                                ? 'Confirm'.translate(this.props.lang)
                                : 'Yes'.translate(this.props.lang)
                            }
                        </Button>,
                        <Button
                            color='danger'
                            style={{ margin: 10 }}
                            onClick={() => this.setState({
                                saveModal: null
                            })}
                        >
                            {this.state.saveModal?.twoFactorAuthenticationEnabled
                                ? 'Go back'.translate(this.props.lang)
                                : 'No'.translate(this.props.lang)
                            }
                        </Button>,
                        <button style={{ visibility: 'hidden', display: 'none' }} aria-hidden='true'></button>
                    ]}
                /> : null
                }

                {this.state.closeModal ? <InfoModal
                    isOpen={this.state.closeModal}
                    onClose={() => {
                        this.setState({
                            closeModal: null
                        })
                    }}
                    header={'Warning'.translate(this.props.lang)}
                    info={'Do you want to save changes before exiting?'.translate(this.props.lang)}
                    buttons={[
                        <button style={{ visibility: 'hidden', display: 'none' }} aria-hidden='true'></button>,
                        <Button
                            color='primary'
                            style={{ margin: 10, minWidth: 100 }}
                            onClick={() => this.setState({ closeModal: null }, () => {
                                this.saveProgress('save')
                            })}
                        >
                            {'Save'.translate(this.props.lang)}
                        </Button>,
                        <Button
                            color='danger'
                            style={{ margin: 10, minWidth: 100 }}
                            onClick={() => this.setState({
                                closeModal: null,
                                form: null,
                            }, () => {
                                this.clearState();
                            })}
                        >
                            {'Exit without saving'.translate(this.props.lang)}
                        </Button>,
                        <button style={{ visibility: 'hidden', display: 'none' }} aria-hidden='true'></button>
                    ]}
                /> : null
                }

                {this.state.deleteModal ? <InfoModal
                    isOpen={this.state.deleteModal}
                    onClose={() => {
                        this.setState({
                            deleteModal: null
                        })
                    }}
                    header={'Warning'.translate(this.props.lang)}
                    info={'Are you sure you want to delete this case? This action cannot be reversed!'.translate(this.props.lang)}
                    buttons={[
                        <button style={{ visibility: 'hidden', display: 'none' }} aria-hidden='true'></button>,
                        <Button
                            color='primary'
                            style={{ margin: 10, minWidth: 100 }}
                            onClick={() => {
                                this.setState({
                                    deleteModal: null
                                });
                            }}
                        >
                            {'Cancel'.translate(this.props.lang)}
                        </Button>,
                        <Button
                            color='danger'
                            style={{ margin: 10, minWidth: 100 }}
                            onClick={this.deleteCase}
                        >
                            {'Delete'.translate(this.props.lang)}
                        </Button>,
                        <button style={{ visibility: 'hidden', display: 'none' }} aria-hidden='true'></button>
                    ]}
                /> : null
                }

                {this.state.archiveModal ? <InfoModal
                    isOpen={this.state.archiveModal}
                    onClose={() => {
                        this.setState({
                            archiveModal: null
                        })
                    }}
                    header={'Warning'.translate(this.props.lang)}
                    info={'Move this case to the archive?'.translate(this.props.lang)}
                    buttons={[
                        <button style={{ visibility: 'hidden', display: 'none' }} aria-hidden='true'></button>,
                        <Button
                            color='primary'
                            style={{ margin: 10, minWidth: 100 }}
                            onClick={this.archiveCase}
                        >
                            {'Archive case'.translate(this.props.lang)}
                        </Button>,
                        <Button
                            color='primary'
                            style={{ margin: 10, minWidth: 100 }}
                            onClick={() => {
                                this.setState({
                                    archiveModal: null
                                });
                            }}
                        >
                            {'Cancel'.translate(this.props.lang)}
                        </Button>,
                        <button style={{ visibility: 'hidden', display: 'none' }} aria-hidden='true'></button>
                    ]}
                /> : null
                }

                {this.state.newCaseModal ? <InfoModal
                    isOpen={this.state.newCaseModal}
                    onClose={() => {
                        this.setState({
                            newCaseModal: null
                        })
                    }}
                    header={'Warning'.translate(this.props.lang)}
                    info={'Create a new case based on the information in this case?'.translate(this.props.lang)}
                    buttons={[
                        <button style={{ visibility: 'hidden', display: 'none' }} aria-hidden='true'></button>,
                        <Button
                            color='info'
                            style={{ margin: 10, minWidth: 100 }}
                            onClick={this.recreateCase}
                        >
                            {'Create'.translate(this.props.lang)}
                        </Button>,
                        <Button
                            color='primary'
                            style={{ margin: 10, minWidth: 100 }}
                            onClick={() => {
                                this.setState({
                                    newCaseModal: null
                                });
                            }}
                        >
                            {'Cancel'.translate(this.props.lang)}
                        </Button>,
                        <button style={{ visibility: 'hidden', display: 'none' }} aria-hidden='true'></button>
                    ]}
                /> : null
                }

                {this.state.history && this.state.history.history ? <Modal key='history-modal' isOpen={this.state.history} size='lg' centered>
                    <ModalHeader toggle={() => { this.setState({ history: null }) }} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => {
                        this.setState({ history: null })
                    }}>&times;</button>}>
                        {'Case history'.translate(this.props.lang)}
                        <InfoWithTooltip style={{ bottom: 4 }} lang={this.props.lang} text='Here you can see all the actions that were taken on this case, as well as other cases that are connected to it' />
                    </ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col>
                                <div className='e-connect-history-container'>
                                    {this.state.history?.history?.map?.((obj, i) => {
                                        if (!obj || !obj.action || !obj.ts || !obj.user || obj.action === 'receiver-archived' || obj.action === 'forwarder-archived') return null;
                                        const caseNumber = obj.no;
                                        const text = obj.action === 'sent' && i === 0 ? (
                                            'Created and sent'.translate(this.props.lang)
                                        ) : obj.action === 'new-created' ? (
                                            'Created new case'.translate(this.props.lang)
                                        ) : obj.action === 'new-sent' ? (
                                            'Created and sent new case'.translate(this.props.lang)
                                        ) : obj.action === 'referral-response' ? (
                                            'Sent a referral response'.translate(this.props.lang)
                                        ) : obj.action?.includes?.('archived') ? (
                                            'Archived'.translate(this.props.lang)
                                        ) : obj.action?.includes?.('assigned') ? (
                                            'Assigned to'.translate(this.props.lang) + ` ${obj.assignToUser?.username || 'N/A'}`
                                        ) : `${obj.action?.charAt?.(0)?.toUpperCase?.() + obj.action?.slice?.(1)}`.translate(this.props.lang);
                                        const date = new Intl.DateTimeFormat(this.props.lang === 'se' ? 'sv-SE' : 'en-EN', {
                                            year: 'numeric',
                                            month: 'short',
                                            day: 'numeric',
                                            hour: 'numeric',
                                            minute: 'numeric'
                                        }).format(obj.ts);
                                        const user = obj.user?.username || 'N/A';
                                        return <div key={obj.ts} className='e-connect-history-item'>
                                            <div>{caseNumber}</div>
                                            <div>{text}</div>
                                            <div>{date}</div>
                                            <div>{user}</div>
                                        </div>
                                    })}
                                </div>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                        <Button color='primary' onClick={() => { this.setState({ history: null }) }}>
                            {'Close'.translate(this.props.lang)}
                        </Button>
                    </ModalFooter>
                </Modal> : null}

                {this.state.answerAndResponseModal ? <Modal key='referral-response-modal' isOpen={this.state.answerAndResponseModal} size='xl' centered className='ec-res-modal-apoisugf'>
                    <ModalHeader toggle={() => { this.setState({ answerAndResponseModal: null }) }} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => {
                        this.setState({ answerAndResponseModal: null })
                    }}>&times;</button>}>
                        {'Referral Responses'.translate(this.props.lang)}
                        <InfoWithTooltip style={{ bottom: 4 }} lang={this.props.lang} text='Here you can see all the referral responses attached to this case' />
                    </ModalHeader>
                    <ModalBody style={{ overflow: 'auto' }}>
                        <Row>
                            {this.state.answerAndResponseModal?.responses?.map?.(responseObj => <Col sm='12' key={responseObj?.ts}>
                                <Alert className={`ec-details-container ${!responseObj?.seen && this.state.answerAndResponseModal?.sender?.user && this.props.uData?._id && String(this.state.answerAndResponseModal?.sender?.user) === String(this.props.uData?._id) ? 'ec-details-container-not-seen' : ''}`} onClick={() => {
                                    if (!responseObj?.seen && this.state.answerAndResponseModal?.sender?.user && this.props.uData?._id && String(this.state.answerAndResponseModal?.sender?.user) === String(this.props.uData?._id)) {
                                        const newResponse = {
                                            ...(responseObj || {}),
                                            seen: true
                                        }
                                        const answerAndResponseModal = this.state.answerAndResponseModal;
                                        const responses = answerAndResponseModal.responses.map(obj => obj?.uuid === responseObj?.uuid || obj?.ts === responseObj?.ts ? newResponse : obj);
                                        answerAndResponseModal.responses = responses;
                                        this.setState({ answerAndResponseModal })
                                    }
                                }}>
                                    <details>
                                        <summary>
                                            <strong>{'Referral Response Received'.translate(this.props.lang)}</strong>: {responseObj?.ts ? new Intl.DateTimeFormat(this.props.lang === 'se' ? 'sv-SE' : 'en-EN', {
                                                year: 'numeric',
                                                month: 'short',
                                                day: 'numeric',
                                                hour: 'numeric',
                                                minute: 'numeric'
                                            }).format(responseObj?.ts) : null}
                                        </summary>
                                        <div>
                                            <Row>
                                                <Col lg='4'>
                                                    <h6>{'Answering department'.translate(this.props.lang)}:</h6>
                                                    <div>{responseObj?.user?.departmentName || 'N/A'}</div>
                                                </Col>
                                                <Col lg='4'>
                                                    <h6>{'Answering personel'.translate(this.props.lang)}:</h6>
                                                    <div>{this.state.answerAndResponseModal?.answer?.user?.name ? `${this.state.answerAndResponseModal?.answer?.user?.title ? `${this.state.answerAndResponseModal?.answer?.user?.title} ` : ''}${this.state.answerAndResponseModal?.answer?.user?.name}` : 'N/A'}</div>
                                                </Col>
                                                <Col lg='4'>
                                                    <h6>{'Assigned caregiver'.translate(this.props.lang)}:</h6>
                                                    <div>{responseObj?.user?.name ? `${responseObj?.user?.title ? `${responseObj?.user?.title} ` : ''}${responseObj?.user?.name}` : 'N/A'}</div>
                                                </Col>
                                            </Row>
                                            <hr />
                                            <Row style={{ marginTop: 20 }}>
                                                <Col>
                                                    <FormGroup>
                                                        <h6>{'Comment'.translate(this.props.lang)}:</h6>
                                                        <p>
                                                            {responseObj?.message}
                                                        </p>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </div>
                                    </details>
                                </Alert>
                            </Col>)}
                            {this.state.answerAndResponseModal?.answer ? <Col sm='12' key='answer-col'>
                                <Alert className={`ec-details-container ${!this.state.answerAndResponseModal?.answer?.seen && this.state.answerAndResponseModal?.sender?.user && this.props.uData?._id && String(this.state.answerAndResponseModal?.sender?.user) === String(this.props.uData?._id) ? 'ec-details-container-not-seen' : ''}`} onClick={() => {
                                    if (!this.state.answerAndResponseModal?.answer?.seen && this.state.answerAndResponseModal?.sender?.user && this.props.uData?._id && String(this.state.answerAndResponseModal?.sender?.user) === String(this.props.uData?._id)) {
                                        const answerAndResponseModal = this.state.answerAndResponseModal;
                                        answerAndResponseModal.answer.seen = true;
                                        this.setState({ answerAndResponseModal });
                                    }
                                }}>
                                    <details>
                                        <summary>
                                            <strong>{'Answer received'.translate(this.props.lang)}</strong>: {this.state.answerAndResponseModal?.answer?.ts ? new Intl.DateTimeFormat(this.props.lang === 'se' ? 'sv-SE' : 'en-EN', {
                                                year: 'numeric',
                                                month: 'short',
                                                day: 'numeric',
                                                hour: 'numeric',
                                                minute: 'numeric'
                                            }).format(this.state.answerAndResponseModal?.answer?.ts) : null}
                                        </summary>
                                        <div>
                                            <Row>
                                                <Col lg='3'>
                                                    <h6>{'Answer'.translate(this.props.lang)}:</h6>
                                                    <div className={this.state.answerAndResponseModal?.answer?.type === 'rejected' ? 'e-connect-list-status-rejected' : this.state.answerAndResponseModal?.answer?.type === 'accepted' ? 'e-connect-list-status-accepted' : undefined} style={{ fontWeight: '500' }}>
                                                        {`${this.state.answerAndResponseModal?.answer?.type?.charAt?.(0)?.toUpperCase?.() + this.state.answerAndResponseModal?.answer?.type?.slice?.(1)}`.translate(this.props.lang)}
                                                    </div>

                                                </Col>
                                                <Col lg='3'>
                                                    <h6>{'Answering department'.translate(this.props.lang)}:</h6>
                                                    <div>{this.state.answerAndResponseModal?.answer?.user?.departmentName || 'N/A'}</div>
                                                </Col>
                                                <Col lg='3'>
                                                    <h6>{'Answering personel'.translate(this.props.lang)}:</h6>
                                                    <div>{this.state.answerAndResponseModal?.answer?.user?.name ? `${this.state.answerAndResponseModal?.answer?.user?.title ? `${this.state.answerAndResponseModal?.answer?.user?.title} ` : ''}${this.state.answerAndResponseModal?.answer?.user?.name}` : 'N/A'}</div>
                                                </Col>
                                                <Col lg='3'>
                                                    <h6>{'Assigned caregiver'.translate(this.props.lang)}:</h6>
                                                    <div>{this.state.answerAndResponseModal?.answer?.user?.assignedDoctorName ? `${this.state.answerAndResponseModal?.answer?.user?.assignedDoctorTitle ? `${this.state.answerAndResponseModal?.answer?.user?.assignedDoctorTitle} ` : ''}${this.state.answerAndResponseModal?.answer?.user?.assignedDoctorName}` : this.state.answerAndResponseModal?.answer?.user?.name ? `${this.state.answerAndResponseModal?.answer?.user?.title ? `${this.state.answerAndResponseModal?.answer?.user?.title} ` : ''}${this.state.answerAndResponseModal?.answer?.user?.name}` : 'N/A'}</div>
                                                </Col>
                                            </Row>
                                            <hr />
                                            <Row style={{ marginTop: 20 }}>
                                                <Col>
                                                    <FormGroup>
                                                        <h6>{'Referral confirmation'.translate(this.props.lang)}:</h6>
                                                        <p>
                                                            {this.state.answerAndResponseModal?.answer?.message}
                                                        </p>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </div>
                                    </details>
                                </Alert>
                            </Col> : null}
                            {this.state.answerAndResponseModal?.forward ? <Col sm='12' key='forward-col'>
                                <Alert className={`ec-details-container ${!this.state.answerAndResponseModal?.forward?.senderSeen && this.state.answerAndResponseModal?.sender?.user && this.props.uData?._id && String(this.state.answerAndResponseModal?.sender?.user) === String(this.props.uData?._id) ? 'ec-details-container-not-seen' : ''}`} onClick={() => {
                                    if (!this.state.answerAndResponseModal?.forward?.senderSeen && this.state.answerAndResponseModal?.sender?.user && this.props.uData?._id && String(this.state.answerAndResponseModal?.sender?.user) === String(this.props.uData?._id)) {
                                        const answerAndResponseModal = this.state.answerAndResponseModal;
                                        answerAndResponseModal.forward.senderSeen = true;
                                        this.setState({ answerAndResponseModal });
                                    }
                                }}>
                                    <details>
                                        <summary>
                                            <strong>{'Referral Forwarded'.translate(this.props.lang)}</strong>: {this.state.answerAndResponseModal?.forward?.ts ? new Intl.DateTimeFormat(this.props.lang === 'se' ? 'sv-SE' : 'en-EN', {
                                                year: 'numeric',
                                                month: 'short',
                                                day: 'numeric',
                                                hour: 'numeric',
                                                minute: 'numeric'
                                            }).format(this.state.answerAndResponseModal?.forward?.ts) : null}
                                        </summary>
                                        <div>
                                            <Row>
                                                {/* <Col lg='3'>
                                                    <h6>{'Answer'.translate(this.props.lang)}:</h6>
                                                    <div className={this.state.answerAndResponseModal?.answer?.type === 'rejected' ? 'e-connect-list-status-rejected' : this.state.answerAndResponseModal?.answer?.type === 'accepted' ? 'e-connect-list-status-accepted' : undefined} style={{ fontWeight: '500' }}>
                                                        {`${this.state.answerAndResponseModal?.answer?.type?.charAt?.(0)?.toUpperCase?.() + this.state.answerAndResponseModal?.answer?.type?.slice?.(1)}`.translate(this.props.lang)}
                                                    </div>

                                                </Col> */}
                                                <Col lg='6'>
                                                    <h6>{'Forwarding department'.translate(this.props.lang)}:</h6>
                                                    <div>{this.state.answerAndResponseModal?.forward?.user?.departmentName || 'N/A'}</div>
                                                </Col>
                                                <Col lg='6'>
                                                    <h6>{'Forwarding personel'.translate(this.props.lang)}:</h6>
                                                    <div>{this.state.answerAndResponseModal?.forward?.user?.name ? `${this.state.answerAndResponseModal?.forward?.user?.title ? `${this.state.answerAndResponseModal?.forward?.user?.title} ` : ''}${this.state.answerAndResponseModal?.forward?.user?.name}` : 'N/A'}</div>
                                                </Col>
                                                {/* <Col lg='4'>
                                                    <h6>{'Forwarding caregiver'.translate(this.props.lang)}:</h6>
                                                    <div>{this.state.answerAndResponseModal?.answer?.user?.assignedDoctorName || this.state.answerAndResponseModal?.answer?.user?.name || 'N/A'}</div>
                                                </Col> */}
                                            </Row>
                                            <hr />
                                            <Row style={{ marginTop: 20 }}>
                                                <Col>
                                                    <FormGroup>
                                                        <h6>{'Referral confirmation'.translate(this.props.lang)}:</h6>
                                                        <p>
                                                            {this.state.answerAndResponseModal?.forward?.message}
                                                        </p>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </div>
                                    </details>
                                </Alert>
                            </Col> : null}
                        </Row>
                        {this.state.loading ? <div className='econnect-loader'><Player
                            autoplay={true}
                            loop={true}
                            src="/lf30_editor_l5cxzdyf.json"
                            style={{ height: typeof window != 'undefined' && window.innerWidth < 768 ? "128px" : "256px", width: typeof window != 'undefined' && window.innerWidth < 768 ? "128px" : "256px" }}
                        /></div> : null}
                    </ModalBody>
                    <ModalFooter style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                        <button hidden aria-hidden='true'></button>

                        {/* <Button
                            style={{ minWidth: 100 }}
                            color='primary'
                            onClick={() => {
                                if (!this.state.answerAndResponseModal?._id) return;
                                this.print(this.state.answerAndResponseModal?._id);
                            }}
                        >{'Save as PDF'.translate(this.props.lang)}</Button> */}

                        <Button color='primary' onClick={() => { this.setState({ answerAndResponseModal: null }) }}>
                            {'Close'.translate(this.props.lang)}
                        </Button>
                        <button hidden aria-hidden='true'></button>
                    </ModalFooter>
                </Modal> : null}

                {this.state.monitoring ? <Modal key='monitoring-modal' isOpen={this.state.monitoring} size='lg' centered className='ec-res-modal-apoisugf'>
                    <ModalHeader toggle={() => { this.setState({ monitoring: null }) }} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => {
                        this.setState({ monitoring: null })
                    }}>&times;</button>}>
                        {'Referral Monitoring'.translate(this.props.lang)}
                        <InfoWithTooltip style={{ bottom: 4 }} lang={this.props.lang} text="Here you can set a monitoring date for this case and we'll send you a notification to check case progress" />
                    </ModalHeader>
                    <ModalBody style={{ overflow: 'auto' }}>
                        <Row>
                            {this.state.monitoring?.monitoring?.length ? <Col>
                                <ListBuilder
                                    lang={this.props.lang}
                                    loading={this.state.loading}
                                    total={this.state.monitoring?.monitoring?.length}
                                    showNumeration={false}
                                    hideEntries
                                    hideFilters
                                    hidePagination
                                    fields={[
                                        { type: 'text', name: '_date1', label: 'Date created'.translate(this.props.lang), allowSort: false },
                                        { type: 'text', name: '_date', label: 'Monitoring date'.translate(this.props.lang), allowSort: false },
                                        { type: 'text', name: '_status', label: 'Status'.translate(this.props.lang), allowSort: false },
                                    ]}
                                    items={this.state.monitoring?.monitoring?.map(item => ({
                                        ...item,
                                        _date1: item?.ts ? <div>
                                            <div style={{ fontSize: 13, margin: 0, padding: 0 }}>
                                                {new Intl.DateTimeFormat(
                                                    this.props.lang === 'se' ? 'sv-SE' : 'en-EN',
                                                    {
                                                        year: 'numeric',
                                                        month: 'short',
                                                        day: 'numeric',
                                                    }
                                                ).format(item?.ts)}
                                            </div>

                                        </div> : '',
                                        _date: item?.date?.ts ? <div>
                                            <div style={{ fontSize: 13, margin: 0, padding: 0 }}>
                                                {new Intl.DateTimeFormat(
                                                    this.props.lang === 'se' ? 'sv-SE' : 'en-EN',
                                                    {
                                                        year: 'numeric',
                                                        month: 'short',
                                                        day: 'numeric',
                                                    }
                                                ).format(item?.date?.ts)}
                                            </div>

                                        </div> : '',
                                        _status: item?.status ? (
                                            item.status === 'scheduled' ? (
                                                <span>{'Scheduled'.translate(this.props.lang)}</span>
                                            ) : item.status === 'cancelled' ? (
                                                <span style={{ color: 'red' }}>{'Cancelled'.translate(this.props.lang)}</span>
                                            ) : item.status === 'in-progress' ? (
                                                <span style={{ color: 'blue' }}>{'In progress'.translate(this.props.lang)}</span>
                                            ) : (
                                                <span style={{ color: '#50c878' }}>{'Finished'.translate(this.props.lang)}</span>
                                            )
                                        ) : (
                                            ''
                                        )
                                    }))}
                                    actions={[
                                        {
                                            component: <div className="tabel-action-tooltip">
                                                <InlineSVG style={{ width: 32, height: 32, left: 'unset' }} src={infoIcon} className="view-icon" />
                                                <div className="chat-icons-tooltip">{'Notes'.translate(this.props.lang)}</div>
                                            </div>,
                                            onClick: item => {
                                                this.setState({
                                                    monitoringNotes: {
                                                        id: this.state.monitoring?._id,
                                                        item,
                                                    }
                                                })
                                            }
                                        },
                                        {
                                            component: <div className="tabel-action-tooltip">
                                                <InlineSVG style={{ width: 30, height: 30, left: 'unset' }} src={errorIcon} className="view-icon" />
                                                <div className="chat-icons-tooltip">{'Cancel Monitoring'.translate(this.props.lang)}</div>
                                            </div>,
                                            condition: item => item?.status === 'scheduled' ? true : false,
                                            onClick: item => {
                                                this.setState({
                                                    updateMonitoring: {
                                                        id: this.state.monitoring?._id,
                                                        item,
                                                        status: 'cancelled',
                                                        note: '',
                                                    }
                                                })
                                            }
                                        },
                                        {
                                            component: <div className="tabel-action-tooltip">
                                                <InlineSVG style={{ width: 27, height: 27, left: 'unset' }} src={successIcon} className="view-icon" />
                                                <div className="chat-icons-tooltip">{'Mark as completed'.translate(this.props.lang)}</div>
                                            </div>,
                                            condition: item => item?.status === 'in-progress' ? true : false,
                                            onClick: item => {
                                                this.setState({
                                                    updateMonitoring: {
                                                        id: this.state.monitoring?._id,
                                                        item,
                                                        status: 'finished',
                                                        note: '',
                                                    }
                                                })
                                            }
                                        },
                                    ]}
                                    rawItems={this.state.monitoring?.monitoring}
                                    updateSort={() => { }}
                                    updateParams={() => { }}
                                    updateMultipleParams={() => { }}
                                />
                            </Col> : <p style={{ width: '70%', margin: 'auto', textAlign: 'center' }}>{'Referral monitoring is not yet set. You can set it up by clicking on the "Schedule Monitoring" button bellow'.translate(this.props.lang)}</p>}
                        </Row>
                        {this.state.loading ? <div className='econnect-loader'><Player
                            autoplay={true}
                            loop={true}
                            src="/lf30_editor_l5cxzdyf.json"
                            style={{ height: typeof window != 'undefined' && window.innerWidth < 768 ? "128px" : "256px", width: typeof window != 'undefined' && window.innerWidth < 768 ? "128px" : "256px" }}
                        /></div> : null}
                    </ModalBody>
                    <ModalFooter style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                        <button hidden aria-hidden='true'></button>

                        {(!this.state.monitoring?.monitoring || !this.state.monitoring?.monitoring?.length || !this.state.monitoring?.monitoring?.find?.(obj => ['scheduled', 'in-progress'].includes(obj?.status)) && this.state.monitoring?._id) ? <Button disabled={this.state.loading} color='info' onClick={() => {
                            this.setState({
                                scheduleMonitoring: {
                                    id: this.state.monitoring._id,
                                    num: '1',
                                    measure: 'month',
                                    note: '',
                                }
                            });
                        }}>
                            {'Schedule Monitoring'.translate(this.props.lang)}
                        </Button> : null}

                        <Button color='primary' onClick={() => { this.setState({ monitoring: null }) }}>
                            {'Close'.translate(this.props.lang)}
                        </Button>

                        <button hidden aria-hidden='true'></button>
                    </ModalFooter>
                </Modal> : null}

                {this.state.scheduleMonitoring && this.state.scheduleMonitoring.id ? <Modal key='schedule-monitoring-modal' isOpen={this.state.scheduleMonitoring} size='md' centered className='ec-res-modal-apoisugf'>
                    <ModalHeader toggle={() => { this.setState({ scheduleMonitoring: null }) }} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => {
                        this.setState({ scheduleMonitoring: null })
                    }}>&times;</button>}>
                        {'Schedule Monitoring'.translate(this.props.lang)}
                        <InfoWithTooltip style={{ bottom: 4 }} lang={this.props.lang} text="Please choose monitoring time by entering the number of days/weeks/months in which you wish to be reminded" />
                    </ModalHeader>
                    <ModalBody>
                        <FormGroup>
                            <Label>{'Remind me in'.translate(this.props.lang)}:</Label>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: 20, width: '100%' }}>
                                <Input type='number' value={this.state.scheduleMonitoring?.num ?? ''} onChange={(e) => {
                                    const num = e?.target?.value;
                                    const scheduleMonitoring = this.state.scheduleMonitoring;
                                    scheduleMonitoring.num = num;
                                    this.setState({ scheduleMonitoring });
                                }} />
                                <Input type='select' value={this.state.scheduleMonitoring?.measure ?? ''} onChange={(e) => {
                                    const measure = e?.target?.value;
                                    const scheduleMonitoring = this.state.scheduleMonitoring;
                                    scheduleMonitoring.measure = measure;
                                    this.setState({ scheduleMonitoring });
                                }} >
                                    <option key='day' value='day'>{'day(s)'.translate(this.props.lang)}</option>
                                    <option key='week' value='week'>{'week(s)'.translate(this.props.lang)}</option>
                                    <option key='month' value='month'>{'month(s)'.translate(this.props.lang)}</option>
                                </Input>
                            </div>
                        </FormGroup>
                        <FormGroup>
                            <Label>{'Note (optional)'.translate(this.props.lang)}</Label>
                            <Input
                                type='textarea'
                                rows={5}
                                value={this.state.scheduleMonitoring?.note || ''}
                                onChange={(e) => {
                                    const note = e?.target?.value;
                                    const scheduleMonitoring = this.state.scheduleMonitoring;
                                    scheduleMonitoring.note = note;
                                    this.setState({ scheduleMonitoring });
                                }}
                            />
                        </FormGroup>
                        {this.state.loading ? <div className='econnect-loader'><Player
                            autoplay={true}
                            loop={true}
                            src="/lf30_editor_l5cxzdyf.json"
                            style={{ height: typeof window != 'undefined' && window.innerWidth < 768 ? "128px" : "256px", width: typeof window != 'undefined' && window.innerWidth < 768 ? "128px" : "256px" }}
                        /></div> : null}
                    </ModalBody>
                    <ModalFooter style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                        <button hidden aria-hidden='true'></button>

                        <Button disabled={this.state.loading || !this.state.scheduleMonitoring?.id || !this.state.scheduleMonitoring?.num?.trim() || !this.state.scheduleMonitoring?.measure || !isStringAValidNumber(this.state.scheduleMonitoring?.num) || +this.state.scheduleMonitoring?.num === 0} color='info' onClick={() => {
                            if (!this.state.scheduleMonitoring?.id || !this.state.scheduleMonitoring?.num?.trim() || !this.state.scheduleMonitoring?.measure || !isStringAValidNumber(this.state.scheduleMonitoring?.num) || +this.state.scheduleMonitoring?.num === 0) return;
                            this.scheduleMonitoring();
                        }}>
                            {'Schedule Monitoring'.translate(this.props.lang)}
                        </Button>

                        <Button color='primary' onClick={() => { this.setState({ scheduleMonitoring: null }) }}>
                            {'Close'.translate(this.props.lang)}
                        </Button>

                        <button hidden aria-hidden='true'></button>
                    </ModalFooter>
                </Modal> : null}

                {this.state.monitoringNotes ? <Modal key='monitoring-notes-modal' isOpen={this.state.monitoringNotes} size='md' centered className='ec-res-modal-apoisugf'>
                    <ModalHeader toggle={() => { this.setState({ monitoringNotes: null }) }} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => {
                        this.setState({ monitoringNotes: null })
                    }}>&times;</button>}>
                        {'Monitoring Notes'.translate(this.props.lang)}
                        <InfoWithTooltip style={{ bottom: 4 }} lang={this.props.lang} text="Here you can see all notes created with this monitoring" />
                    </ModalHeader>
                    <ModalBody style={{ overflow: 'auto' }}>
                        {this.state.monitoringNotes?.item?.notes?.length ? <div className='ec-monitoring-notes-container'>
                            {this.state.monitoringNotes.item?.notes?.map?.((note, i) => <div key={note + String(i)}>
                                <p>{note}</p>
                            </div>)}
                        </div> : <p style={{ textAlign: 'center' }}>{'No notes yet.'.translate(this.props.lang)}</p>}

                        {this.state.loading ? <div className='econnect-loader'><Player
                            autoplay={true}
                            loop={true}
                            src="/lf30_editor_l5cxzdyf.json"
                            style={{ height: typeof window != 'undefined' && window.innerWidth < 768 ? "128px" : "256px", width: typeof window != 'undefined' && window.innerWidth < 768 ? "128px" : "256px" }}
                        /></div> : null}
                    </ModalBody>
                    <ModalFooter style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                        <button hidden aria-hidden='true'></button>

                        {this.state.monitoringNotes?.id && this.state.monitoringNotes?.item?.uuid ? <Button color='info' onClick={() => {
                            this.setState({
                                updateMonitoring: {
                                    id: this.state.monitoringNotes.id,
                                    item: this.state.monitoringNotes.item,
                                    status: 'add-note',
                                    note: ''
                                }
                            })
                        }}>
                            {'Add New Note'.translate(this.props.lang)}
                        </Button> : null}

                        <Button color='primary' onClick={() => { this.setState({ monitoringNotes: null }) }}>
                            {'Close'.translate(this.props.lang)}
                        </Button>

                        <button hidden aria-hidden='true'></button>
                    </ModalFooter>
                </Modal> : null}

                {this.state.updateMonitoring && this.state.updateMonitoring?.id && this.state.updateMonitoring?.item?.uuid && this.state.updateMonitoring?.status ? <Modal key='monitoring-update-modal' isOpen={this.state.updateMonitoring} size='md' centered className='ec-res-modal-apoisugf'>
                    <ModalHeader toggle={() => { this.setState({ updateMonitoring: null }) }} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => {
                        this.setState({ updateMonitoring: null })
                    }}>&times;</button>}>
                        {this.state.updateMonitoring?.status === 'add-note' ? (
                            'Add New Note'.translate(this.props.lang)
                        ) : this.state.updateMonitoring?.status === 'cancelled' ? (
                            'Cancel Monitoring'.translate(this.props.lang)
                        ) : 'Mark as completed'.translate(this.props.lang)}
                        <InfoWithTooltip style={{ bottom: 4 }} lang={this.props.lang} text={
                            this.state.updateMonitoring?.status === 'add-note' ? (
                                'Add a new monitoring note here. This note will be available with all the other notes'
                            ) : this.state.updateMonitoring?.status === 'cancelled' ? (
                                'Cancel this monitoring? If you cancel monitoring you will not be notified at the specified date, but you will be able to add another monitoring date'
                            ) : (
                                'Mark this monitoring as completed? After monitoring is marked as completed, another one can be created'
                            )
                        } />
                    </ModalHeader>
                    <ModalBody style={{ overflow: 'auto' }}>
                        <FormGroup>
                            <Label>{this.state.updateMonitoring.status === 'add-note' ? 'Note'.translate(this.props.lang) : 'Note (optional)'.translate(this.props.lang)}</Label>
                            <Input
                                type='textarea'
                                rows={5}
                                value={this.state.updateMonitoring.note || ''}
                                onChange={(e) => {
                                    const note = e?.target?.value;
                                    const updateMonitoring = this.state.updateMonitoring;
                                    updateMonitoring.note = note;
                                    this.setState({ updateMonitoring });
                                }}
                            />
                        </FormGroup>
                        {this.state.loading ? <div className='econnect-loader'><Player
                            autoplay={true}
                            loop={true}
                            src="/lf30_editor_l5cxzdyf.json"
                            style={{ height: typeof window != 'undefined' && window.innerWidth < 768 ? "128px" : "256px", width: typeof window != 'undefined' && window.innerWidth < 768 ? "128px" : "256px" }}
                        /></div> : null}
                    </ModalBody>
                    <ModalFooter style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                        <button hidden aria-hidden='true'></button>

                        <Button disabled={this.state.updateMonitoring.status === 'add-note' && !this.state.updateMonitoring?.note?.trim?.()} color={this.state.updateMonitoring?.status === 'cancelled' ? 'danger' : 'info'} onClick={() => {
                            if (this.state.updateMonitoring.status === 'add-note' && !this.state.updateMonitoring?.note?.trim?.()) return;
                            this.updateMonitoring(this.state.updateMonitoring.id, this.state.updateMonitoring.item.uuid, this.state.updateMonitoring.status, this.state.updateMonitoring.note);
                        }}>
                            {this.state.updateMonitoring?.status === 'add-note' ? (
                                'Add New Note'.translate(this.props.lang)
                            ) : this.state.updateMonitoring?.status === 'cancelled' ? (
                                'Cancel Monitoring'.translate(this.props.lang)
                            ) : 'Mark as completed'.translate(this.props.lang)}
                        </Button>

                        <Button color='primary' onClick={() => { this.setState({ updateMonitoring: null }) }}>
                            {'Close'.translate(this.props.lang)}
                        </Button>

                        <button hidden aria-hidden='true'></button>
                    </ModalFooter>
                </Modal> : null}

                {this.state.chatModal ? <Modal key='chat-modal' isOpen={this.state.chatModal} size='xl' centered >
                    <ModalHeader toggle={() => { this.setState({ chatModal: null }, () => { this.props.checkEConnectCount?.() }) }} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => {
                        this.setState({ chatModal: null }, () => { this.props.checkEConnectCount?.() })
                    }}>&times;</button>}>
                        {'Chat'.translate(this.props.lang)}
                        {/* <InfoWithTooltip style={{ bottom: 4 }} lang={this.props.lang} text='Here you can chat with other party about the case progress' /> */}
                    </ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col lg='6' style={{ overflow: 'auto' }}>
                                <div key='div-patient-asdf' className='econnect-create-patient'>
                                    <div>
                                        <div>
                                            <h6>{'Social security number'.translate(this.props.lang)}:</h6>
                                            <p>{this.state.chatModal?.patient?.ssn} {this.state.chatModal?._2fa ? <UserIsNotVerifiedTooltip warning lang={this.props.lang} /> : null}</p>
                                        </div>
                                        <div>
                                            <h6>{'Name'.translate(this.props.lang)}:</h6>
                                            <p>{this.state.chatModal?.patient?.name}</p>
                                        </div>
                                    </div>
                                    {this.state.chatModal?._id ? <button onClick={() => {
                                        const patient = this.state.chatModal?.patient;
                                        this.setState({
                                            casePatientModal: patient
                                        })
                                    }}>{'See patient info'.translate(this.props.lang)}</button> : null}
                                </div>
                                <div key='div-clinic-asdfasdfg' className='econnect-create-patient'>
                                    <div>
                                        <div>
                                            <h6>{'Receiving clinic'.translate(this.props.lang)}:</h6>
                                            <p>{this.state.chatModal?.receiver?.name}</p>
                                        </div>
                                        <div>
                                            <h6>{'Type'.translate(this.props.lang)}:</h6>
                                            <p style={{ color: this.state.chatModal?.receiver?.type === 'internal' ? 'green' : 'red' }}>{this.state.chatModal?.receiver?.type === 'internal' ? 'e-Connect' : 'External'.translate(this.props.lang)}</p>
                                        </div>
                                    </div>
                                    <button onClick={() => {
                                        const clinic = this.state.chatModal?.receiver;
                                        this.setState({
                                            clinicDetails: clinic
                                        })
                                    }}>{'See clinic info'.translate(this.props.lang)}</button>
                                </div>

                                {/* <h6 className='e-connect-title'>{'Receiving clinic'.translate(this.props.lang)}:</h6>
                                {this.state.chatModal?.receiver?.type ? <div className={`econnect-receiver-card disabled`}>
                                    <p style={this.state.chatModal?.receiver?.userName ? { padding: 0, margin: 0 } : undefined} className={this.state.chatModal?.receiver?.userName ? undefined : 'ec-clinic-name'}>{this.state.chatModal?.receiver?.name}</p>
                                    {this.state.chatModal?.receiver?.userName ? <p className='ec-clinic-name'>{this.state.chatModal?.receiver?.userName}</p> : null}

                                    {this.state.chatModal?.receiver?.phone || this.state.chatModal?.receiver?.email ? <div className='ec-clinic-contact-div'>
                                        {this.state.chatModal?.receiver?.phone ? <p className='ec-clinic-phone'>{this.state.chatModal?.receiver?.phone}</p> : null}
                                        {this.state.chatModal?.receiver?.email ? <p className='ec-clinic-email'>{this.state.chatModal?.receiver?.email}</p> : null}
                                    </div> : null}

                                    {this.state.chatModal?.receiver?.address ? <div className='ec-clinic-address'>
                                        {this.state.chatModal?.receiver?.address?.split?.(';')?.map?.(addr => <p>
                                            {addr}
                                        </p>)}
                                    </div> : null}

                                    <div className={`ec-clinic-type ec-clinic-type-${this.state.chatModal?.receiver?.type}`}>{this.state.chatModal?.receiver?.type === 'internal' ? 'e-Connect' : 'External'.translate(this.props.lang)}</div>
                                </div> : null} */}

                                <FormGroup key='fg-subject'>
                                    <Label className='e-connect-title' htmlFor='e-cr-subject'>{'Subject'.translate(this.props.lang)}:</Label>
                                    <Input disabled type='textarea' value={this.state.chatModal?.subject || ''} rows='2' />
                                </FormGroup>

                                <FormGroup>
                                    <Label className='e-connect-title' htmlFor='e-conn-anamnesis'>
                                        {'Anamnesis'.translate(this.props.lang)}:
                                    </Label>
                                    <Input disabled rows='4' id='e-conn-anamnesis' type='textarea' value={this.state.chatModal?.anamnesis || ''} />
                                </FormGroup>

                                <FormGroup>
                                    <Label className='e-connect-title' htmlFor='e-conn-status'>
                                        {'Status'.translate(this.props.lang)}:
                                    </Label>
                                    <Input disabled rows='4' id='e-conn-status' type='textarea' value={this.state.chatModal?.status || ''} />
                                </FormGroup>

                                {this.state.chatModal?.attachments?.length > 0 ? <Row>
                                    <Col>
                                        <h6 className='e-connect-title'>
                                            {'Attachments'.translate(this.props.lang)}:
                                        </h6>
                                        <div className='e-connect-modal-attachments'>
                                            <div className={`ec-disabled`}>
                                                {this.state.chatModal?.attachments?.map?.((item, i) => {
                                                    if (!item?.url) return null;
                                                    return <div
                                                        key={item?.url ? item.url : item?.name ? `${item.name}-${i}` : i}
                                                        className='econnect-attachment-item'
                                                    >
                                                        <div>
                                                            <button
                                                                title={'Opens in new browser tab'.translate(this.props.lang)}
                                                                onClick={() => {
                                                                    if (typeof window !== 'undefined') window.open(API_ENDPOINT + item.url, '_blank')
                                                                }}
                                                            >
                                                                &#91;
                                                                {'Open'.translate(this.props.lang)}
                                                                &#93;
                                                            </button>
                                                            <div>{item?.name}</div>
                                                        </div>
                                                    </div>
                                                })}
                                            </div>
                                            <div className={`ec2-disabled`} />
                                        </div>
                                    </Col>
                                </Row> : null}
                            </Col>

                            <Col lg='6'>
                                <div className="e-connect-chat-container">
                                    <iframe
                                        src={`/chat/${this.state.chatModal?.conversationId}?patientSearchModule=true&doNotOpenPatientProfile=true`}
                                        onLoad={() => {
                                            this.timer2 = setTimeout(() => {
                                                this.props.checkEConnectCount?.();
                                            }, 3000);
                                        }}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                        <button hidden aria-hidden='true'></button>
                        <Button color='primary' onClick={() => { this.setState({ chatModal: null }, () => { this.props.checkEConnectCount?.() }) }}>
                            {'Close'.translate(this.props.lang)}
                        </Button>
                        <button hidden aria-hidden='true'></button>
                    </ModalFooter>
                </Modal> : null}

                {
                    this.state.error ? <ErrorModal lang={this.props.lang}
                        isOpen={this.state.error}
                        toggle={() => this.setState({ error: null })}

                    >
                        {this.state.error.translate(this.props.lang)}
                    </ErrorModal> : null
                }
            </div>
        )
    }
}

const selector = formValueSelector('modalForm');

export default connect(state => {
    return {
        socialSecurityNumber: selector(state, 'socialSecurityNumber'),
        socialSecurityNumberParent: selector(state, 'socialSecurityNumberParent'),
        dateOfBirth: selector(state, 'dateOfBirth.date'),
        dateOfBirthParent: selector(state, 'dateOfBirthParent.date'),
        dateOfBirthParent2: selector(state, 'dateOfBirthParent2.date'),

        socialSecurityNumberParent2: selector(state, 'socialSecurityNumberParent2'),
        email2: selector(state, 'email2'),
        phone2: selector(state, 'phone2'),
        language2: selector(state, 'language2'),
        nameParent2: selector(state, 'nameParent2'),

        twoFactorAuthenticationEnabled: selector(state, 'twoFactorAuthenticationEnabled')

    }


}, {
    changeName: value => change('modalForm', 'userData.name', value),
    changeSex: value => change('modalForm', 'userData.sex', value),
    changeSocialSecurityNumberParent: value => change('modalForm', 'socialSecurityNumberParent', value),

    changeEmail: value => change("modalForm", "email", value),
    changeNameParent: value => change("modalForm", "nameParent", value),
    changeLanguage: value => change("modalForm", "language", value),
    changePhone: value => change("modalForm", "phone", value),
    changeSexParent: value => change("modalForm", "sexParent", value),

    changeEmail2: value => change("modalForm", "email2", value),
    changeNameParent2: value => change("modalForm", "nameParent2", value),
    changeLanguage2: value => change("modalForm", "language2", value),
    changePhone2: value => change("modalForm", "phone2", value),
    changeSexParent2: value => change("modalForm", "sexParent2", value),

    changeDateOfBirth: value => change("modalForm", "dateOfBirth.date", value),
    changeDateOfBirthParent: value => change("modalForm", "dateOfBirthParent.date", value),
    changeDateOfBirthParent2: value => change("modalForm", "dateOfBirthParent2.date", value)

})(PageWithLayout(EconnectCreatedCasesPage));