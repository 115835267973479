import React, { Component } from 'react';
import Link from '../components/link';

import Isvg from 'react-inlinesvg';
import Page from '../containers/page';

import {
    Container,
    Row,
    Col,
    Button,
} from 'reactstrap';


import FormBuilder from '../components/forms/formBuilder';
import { required } from '../components/forms/validation';
import { API_ENDPOINT } from '../constants';
import { getSearchParams } from '../helpers/linkHelper';

import success_svg from '../assets/svg/success_svg.svg';



/**
* Recovery password page
* @author   Stefan Pantic
*/
class LoginPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            promotedProjects: [],
            ...props.initialData,
        };
    }

    componentDidMount() {
        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }

        if (this.props[0].location?.state?.success) {
            this.setState({ successMessage: this.props[0].location?.state?.success })
        }



        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match).then((data) => {
                this.setState({
                    ...data
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

    }

    /**
    * Find user and update his password
    * @author   Stefan Pantic
    * @Objectparam    {Object} data     form data
    * @Objectparam    {String} key      verification key
    */
    find = (data) => {
        let key = this.props[0].match.params.key;
        // console.log(key)

        fetch(API_ENDPOINT + '/users/account/updatePassword', {
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify({ ...data, key })
        }).then(res => res.json()).then((result) => {
            if (result.error) {
                this.setState({
                    error: result.error
                })
            } else {
                this.setState({ successMessage: result.message }, () => {

                    this.props[0].history.push({ state: { success: this.state.successMessage } })
                    // setTimeout(() => {

                    //     // this.setState({
                    //     //     message: result.message
                    //     // })
                    //     if (alias) {
                    //         this.props[0].history.push(`/${alias}`)
                    //     } else {
                    //         this.props[0].history.push('/login')

                    //     }
                    // }, 3000);
                })

            }


        });

    }


    render() {

        if (this.state.successMessage) {
            return (
                <div>
                    <Container fluid>
                        <Row>
                            <Col lg={{ size: 4, offset: 4 }}>
                                <div className="panel">
                                    <div className='success-recover-pw-wrap'>
                                        <div className='success-image'>
                                            <Isvg src={success_svg} />
                                        </div>
                                        <h5>{this.state.successMessage.translate(this.props.lang)}</h5>

                                        <Button color='primary' onClick={() => {
                                            // console.log(this.props[0].location?.state)
                                            if (this.props[0].match.params.group) {
                                                this.props[0].history.push(`/${this.props[0].match.params.group}`)
                                            } else {
                                                this.props[0].history.push('/login')

                                            }
                                        }}>{'Login'.translate(this.props.lang)}</Button>
                                    </div>

                                </div>

                            </Col>
                        </Row>
                    </Container>
                </div >
            )
        } else {
            return (
                <div>

                    <Container fluid>
                        <Row>
                            <Col lg={{ size: 4, offset: 4 }}>
                                {this.props.userVerificationInProgress ?
                                    <div className="loader-wrap">
                                        <div className="loader" />
                                    </div>

                                    :
                                    <div className="panel">
                                        <h4>{'Password recovery'.translate(this.props.lang)} </h4>
                                        <FormBuilder
                                            selectedGroup={this.props.selectedGroup}
                                            selectedClinic={this.props.selectedClinic}
                                            countryTwoLetter={this.props.countryTwoLetter}
                                            country={this.props.country}
                                            dateFormat={this.props.dateFormat}
                                            getStringDateTs={this.props.getStringDateTs}
                                            lang={this.props.lang}
                                            onSubmit={(data) => this.find(data)}
                                            buttonText={'Save'.translate(this.props.lang).translate(this.props.lang)}
                                            fields={[
                                                {
                                                    type: 'row',
                                                    children: [

                                                        {
                                                            type: 'col',
                                                            width: {
                                                                lg: 12,
                                                                sm: 12,
                                                                xs: 12
                                                            },
                                                            children: [
                                                                {
                                                                    type: 'password',
                                                                    name: 'password',
                                                                    label: 'Password'.translate(this.props.lang),
                                                                    validate: [required('Password is required!'.translate(this.props.lang))]
                                                                },
                                                            ]
                                                        },
                                                        {
                                                            type: 'col',
                                                            width: {
                                                                lg: 12,
                                                                sm: 12,
                                                                xs: 12
                                                            },
                                                            children: [
                                                                {
                                                                    type: 'password',
                                                                    name: 'repeatPassword',
                                                                    label: 'Repeat Password'.translate(this.props.lang),
                                                                    validate: [required('Repeat password is required!'.translate(this.props.lang))]
                                                                },
                                                            ]
                                                        },

                                                    ]
                                                }
                                            ]}
                                        ></FormBuilder>



                                        {this.state.error ?
                                            <p style={{ color: 'red' }}>{this.state.error.translate(this.props.lang)}</p>
                                            :
                                            <p>{this.state.message ? this.state.message.translate(this.props.lang) : null}</p>
                                        }
                                    </div>

                                }
                            </Col>
                        </Row>
                    </Container>

                </div>
            );
        }

    }
}

export default Page(LoginPage);