import React, { Component, isValidElement } from 'react';
import DefaultErrorPage from './DefaultErrorPage';
import { API_ENDPOINT } from '../../constants';

var browserInfo = require('browser-info');

export default class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
            reportSent: null
        }
    }

    sendErrorToBackend = (error, stack) => {
        const submit = async (data) => {
            const response = await fetch(API_ENDPOINT + '/error-boundary', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });
            this.setState({reportSent: (response && response.ok)})
        }

        let userData = null;
        try {
            if (typeof window !== 'undefined' && localStorage) {
                let d = JSON.parse(localStorage?.getItem?.('userData'));
                if (d) userData = d;
                if (userData && typeof userData === 'object') {
                    userData.localStorageProfile = localStorage?.getItem?.('profile') || null;
                    userData.localStorageUserLevel = localStorage?.getItem?.('userLevel') || null;
                    userData.localStorageGroup = localStorage?.getItem?.('group') || null;
                    userData.localStorageGroupAlias = localStorage?.getItem?.('groupAlias') || null;
                }
            }
        } catch(err) {
            console.error('user data err bound error: ', err);
        }

        let obj = {
            page: typeof window !== 'undefined' ? window.location?.pathname : 'No Window Object detected - Likely error on server hydration!',
            name: error?.name,
            message: error?.message,
            errorStack: error?.stack,
            nativeStack: stack,
            browser: browserInfo?.(),
            userData,
            ts: new Date().getTime()
        }

        submit(obj);
    }

    static getDerivedStateFromError(error) {
        return { hasError: true }
    }

    componentDidCatch(error, errorInfo) {
        this.sendErrorToBackend?.(error, errorInfo?.componentStack);
    }

    render() {
        if (this.state.hasError) return (this.props.fallback && isValidElement(this.props.fallback)) ? this.props.fallback : <DefaultErrorPage reportSent={this.state.reportSent} />
        return this.props.children;
    }
}