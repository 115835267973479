import React, { Component } from 'react';
import Link from '../components/link';

import Isvg from 'react-inlinesvg';
import Page from '../containers/page';

import { API_ENDPOINT } from '../constants';

/**
* BankId login component
* @author   Milan Stanojevic
*/
class BankId extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    componentDidMount() {
      
        let loginStorageKey = this.props[0].match.params.loginStorageKey;
        let groupAlias;
        if (typeof window !== 'undefined' && localStorage.getItem('groupAlias')) {
            groupAlias = localStorage.getItem('groupAlias');
        }
        let sessionId;
        if (typeof window != 'undefined' && localStorage.sessionId) {
            sessionId = localStorage.sessionId;
        }
        fetch(API_ENDPOINT + '/users/bankid/v2/login', {
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify({ loginStorageKey, groupAlias, sessionId })
        }).then(res => res.json()).then((result) => {

            if (result.error) {
                this.setState({
                    error: result.error
                })
            } else {

                try {
                    if (result?.userData && typeof window !== 'undefined') {
                        localStorage?.setItem?.('userData', JSON.stringify(result?.userData));
                    }
                } catch (err) {
                    console.error('login user data err: ', err);
                }

                if (window.top) {
                    if (result.newUser) {
                        window.top.postMessage('loggedin_newuser', '*')

                    } else {
                        window.top.postMessage('loggedin', '*')
                    }
                }
                if (result.sessionId) {
                    localStorage.setItem('sessionId', result.sessionId)
                }

                localStorage.setItem('authToken', result.token);
                localStorage.setItem('time', Math.floor(Date.now() / 1000))
                localStorage.setItem('profile', 'doctor');
                localStorage.setItem('userLevel', 20);
                // localStorage.setItem('logoutLandingPage', true);
                if (localStorage.getItem('groupAlias')) {
                    localStorage.setItem('logoutLandingPage', true);
                    localStorage.setItem('loginGroupAlias', localStorage.getItem('groupAlias'));
                }


                //this.props.verifyUser();
                // this.props[0].history.push("/")
                //window.location.reload(false)
                if (result.message) {
                    this.props[0].history.push({
                        pathname: '/my-data/account',
                        state: { bankIdError: result.message }
                    })
                } else {
                    window.location.href = "/"
                }


            }
        });
    }

    render() {
        return (
            <div>            </div>
        );
    }
}

export default BankId;