import React from 'react'
import reactCSS from 'reactcss'
import {
    Container,
    Row,
    Col,
    FormGroup, Label
} from 'reactstrap';
import Checkbox from './checkbox';
import infoIcon from "../../../assets/svg/link-new-tab.svg";
import Isvg from 'react-inlinesvg';

/**
* Custom checkbox
* @author   Milan Stanojevic
*/
class CustomCheckbox extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
        };
    }



    render() {
        // console.log(this.props.disabled)

        return (
            <FormGroup className='form-group-label-wrap'>
                {/*{this.props.label ? <Label>{this.props.label}</Label> : null}*/}

                <Checkbox onChange={(e) => {
                    if (!this.props.disabled)
                        this.props.onChange(e.target.checked);
                }} checked={this.props.value} label={this.props.label ? this.props.label : this.props.afterText ? this.props.afterText : ''} disabled={this.props.disabled} error={this.props.error} />
               
                {/*
                <div className="checkbox-container move-left ">

                    <input onChange={this.props.onChange} checked={this.props.value} type="checkbox" />
                    {this.props.label ? <Label>{this.props.label}</Label> : null}
                    {this.props.afterText ? <Label>{this.props.afterText}</Label> : null}
                    <Label className="custom-checkbox-small"></Label>
            </div>*/}
            {
                            this.props.labelInfo ?
                                <div className='info'>
                                    <Isvg src={infoIcon} />
                                    <div className="info-text">{this.props.labelInfo}</div>
                                </div>
                                :
                                null
                        }
            </FormGroup>

        )
    }
}

export default CustomCheckbox