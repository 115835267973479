import React, { Component } from 'react';
import Page from '../../containers/page';

import {
    Container,
    Row,
    Col,
    Modal, ModalHeader, ModalBody, ModalFooter, Button

} from 'reactstrap';

import Isvg from 'react-inlinesvg'
import { formValueSelector, change } from 'redux-form';  // ES6
import { connect } from 'react-redux';
import InfoModal from '../../components/infoModal';
import FormBuilder from '../../components/forms/modalFormBuilder';
import { emailAddress, required, checkSocialSecurityNumber, dateValidate } from '../../components/forms/validation';

import ListBuilder from '../../components/listBuilder';
import DeleteModal from '../../components/deleteModal';
import ErrorModal from '../../components/errorModal';

import edit from '../../assets/svg/edit.svg'
import garabage from '../../assets/svg/garbage.svg'
import forgotPassword from '../../assets/svg/forgot_password.svg';
import addIcon from '../../assets/svg/add-icon.svg';
import Usage from "../../assets/svg/usage.svg";
import loginVersionSvg from "../../assets/svg/login_version.svg";


import moment from 'moment';

import { getSearchParams, generateSearchLink, generateSearchLinkMultiple } from '../../helpers/linkHelper';
import { API_ENDPOINT } from '../../constants';
function parseJSON(response) {
    return response.json().then(json => {
        return {
            result: json,
            status: response.status
        }
    })
}


/**
* Users page
* @author   Milan Stanojevic
*/
class Users extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...props.initialData,
            form: false,
            useState: false,
            params: {
                entries: 10,
                page: 0
            },
            items: [],
            endpoints: [],
            formModules: [],
            userLevels: [],
            languages: [],
            formUsers: [],
            total: 0,
            loading: true,
            supportCategory: [],
            clinicGroups: []
        };
    }


    get = () => {
        this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));

        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0 })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

        for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
            this.props.loadDataWithQuery[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0 })).then((data) => {
                let dataToSet = { ...data }
                if (dataToSet.page || dataToSet.items || dataToSet.total) {
                    dataToSet.loading = null;
                    // console.log(dataToSet.page || dataToSet.items || dataToSet.total)
                    let checkFilter = '';
                    if (dataToSet.resultFilter) {
                        checkFilter = String(dataToSet.resultFilter)
                        delete dataToSet.resultFilter;
                    }
                    let search = this.props[0].location.search;
                    let serachParams = getSearchParams(search);
                    let searchParamsFilter = '';
                    if (serachParams.filter) {
                        searchParamsFilter = serachParams.filter;
                    }
                    if (searchParamsFilter != checkFilter) {
                        delete dataToSet.page;
                        delete dataToSet.items;
                        delete dataToSet.total;
                        delete dataToSet.loading;
                    }
                }

                this.setState({
                    ...dataToSet,

                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

    }

    componentDidMount() {
        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }

        this.get();
    }

    componentDidUpdate(prevProps) {
        if (prevProps[0].location.search != this.props[0].location.search) {
            this.setState({
                loading: true
            }, () => {
                this.get();

            })
        }
        if (prevProps.userLevel != this.props.userLevel && this.props.userLevel != '5f6b126329ef8110f415087b') {
            //alert(true)
            this.props.changeParentAccount(null);
            this.forceUpdate();
        }
        // if (prevProps.name != this.props.name && this.state.form && this.props.name && this.props.name.indexOf(' ') !== -1) {
        //     this.getUsername();
        // }
        if (prevProps.name != this.props.name && this.state.form) {
            if (this.props.name) {
                this.getUsername();
            } else {
                if (this.props.username)
                    this.props.changeUsername('')
            }

        }

        if (prevProps.userLevel != this.props.userLevel) {
            for (let i = 0; i < this.state.userLevels.length; i++) {
                if (this.state.userLevels[i]._id == this.props.userLevel) {
                    this.setState({
                        _disableSupportCategory: this.state.userLevels[i].level <= 20 ? true : false
                    })

                    break;
                }
            }


        }

        if (prevProps.socialSecurityNumber !== this.props.socialSecurityNumber) {
            if ((this.props.country === 'denmark' && this.props.socialSecurityNumber?.length === 10) || this.props.socialSecurityNumber?.length === 12) {
                let dateOfBirth = this.props.getDateOfBirthFromSSN(this.props.socialSecurityNumber);
                if (dateOfBirth && this.props.changeDateOfBirth) {
                    this.props.changeDateOfBirth(dateOfBirth)
                    if (!this.state.disabledDateOfBirth) { this.setState({ disabledDateOfBirth: true }) }
                } else {
                    if (this.state.disabledDateOfBirth) { this.setState({ disabledDateOfBirth: false }) }
                }
            } else {
                if (this.state.disabledDateOfBirth) { this.setState({ disabledDateOfBirth: false }) }
            }
        }
    }


    componentWillReceiveProps(nextProps) {
    }

    updateSort = (field, type) => {
        if (this.state.useState) {
            let state = this.state.params;
            state.sortField = field;
            state.sortType = type;
            this.setState({
                params: state
            })
        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0 }, [{ name: 'sortField', value: field }, { name: 'sortType', value: type }], false));

        }
    }

    updateParams = (name, value, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };
                obj[name] = value;
                this.setState({ params: obj });
            } else {
                let obj = this.state.params;
                obj[name] = value;
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(generateSearchLink(this.props[0].location.search, { entries: 10, page: 0 }, name, value, restart));
        }
    }
    updateMultipleParams = (fields, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj });

            } else {
                let obj = this.state.params;
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0 }, fields, restart));
        }
    }



    /**
    * Create new or update user
    * @author   Milan Stanojevic
    * @Objectparam    {String} socialSecurityNumber     social securiy number of user
    * @Objectparam    {String} title                    user title
    * @Objectparam    {String} name                     user name
    * @Objectparam    {String} email                    user email
    * @Objectparam    {String} phone                    user phone
    * @Objectparam    {String} password                 user password
    * @Objectparam    {String} supportCategory          user support category
    * @Objectparam    {String} language                 user language
    * @Objectparam    {String} username                 user username created from user name
    * @Objectparam    {Boolean} articles                    
    */
    insertOrUpdate = (data) => {
        if (!data.language) {
            data.language = this.state.languages.filter(item => { if (item.key == 'en') return (item) })._id
        }
        this.setState({
            loading: true,
            _saving: true
        }, () => {
            if (!this.state.form._id) {

                fetch(API_ENDPOINT + '/users/users/new', {
                    method: 'PUT',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify(data)
                }).then(parseJSON).then(({ result, status }) => {
                    if (result.error) {
                        this.setState({ error: result.error })
                    } else {
                        this.setState({
                            form: null
                        })
                    }
                    this.get();
                    this.setState({
                        _saving: false
                    }, () => {
                        setTimeout(() => {
                            this.setState({ _saving: false })
                        }, 1000)
                    })
                })
            } else {
                fetch(API_ENDPOINT + '/users/users/' + this.state.form._id, {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify(data)
                }).then(parseJSON).then(({ result, status }) => {
                    if (result.error) {
                        this.setState({ error: result.error })
                    } else {
                        this.setState({
                            form: null
                        })
                    }
                    this.get();
                    this.setState({
                        _saving: false
                    }, () => {
                        setTimeout(() => {
                            this.setState({ _saving: false })
                        }, 1000)
                    })
                })
            }
        })
    }


    stopAnimation = () => {
        this.player.current.play();
    }


    /**
    * Delete user
    * @author   Milan Stanojevic
    * @Objectparam    {String} id     user id
    */
    delete = (id) => {
        this.setState({
            loading: true
        }, () => {

            fetch(API_ENDPOINT + '/users/users/' + id, {
                method: 'DELETE',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
            }).then(parseJSON).then(({ result, status }) => {
                if (result.error) {
                    this.setState({ error: result.error })
                }
                this.get();
            })
        })

    }

    /**
    * Generate username of user
    * @author   Milan Stanojevic
    */
    getUsername = () => {
        if (this.state.form._disableEdit) {
            return;
        }

        fetch(API_ENDPOINT + '/users/users/get-username', {
            method: 'POST',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
            body: JSON.stringify({
                userId: this.state.form._id ? this.state.form._id : null,
                name: this.props.name,
            })
        }).then(parseJSON).then(({ result, status }) => {
            if (status == 200 && result && result.username) {
                this.props.changeUsername(result.username)
            }
        })

    }

    forgotPassword = (data) => {
        console.log(data.email)
        fetch(API_ENDPOINT + '/users/user/forgotpassword', {
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify({ uid: data._id })
        }).then(res => res.json()).then((result) => {
            if (result.error) {
                this.setState({
                    error: result.error,
                    forgotPassword: null
                })
            } else {
                this.setState({
                    message: result.message,
                    disable: true,
                    forgotPassword: null
                })
            }
        })
    }


    getUserProfile = (id) => {
        if (id) {
            fetch(API_ENDPOINT + `/users/users/user-accounts/v2/${id}`, {
                method: 'GET',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                }
            }).then(res => res.json()).then((result) => {
                if (result && result._id) {
                    this.setState({ form: result })
                }

            })
        }

    }

    getLoginVersion = (id) => {
        if (id) {
            fetch(API_ENDPOINT + `/users/login/version/${id}`, {
                method: 'GET',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                }
            }).then(res => res.json()).then((result) => {
                if (result) {
                    this.setState({ loginVersion: result.loginVersion ? result.loginVersion : [] })
                }

            })
        }

    }


    render() {
        let params = {};
        if (this.state.useState) {
            params = this.state.params;
        } else {
            params = getSearchParams(this.props[0].location.search, { entries: 10, page: 0 })
        }
        const defaultOptions = {
            loop: true,
            autoplay: true,
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice'
            }
        };
        //console.log(this.props)
        let parentAccounts = [];
        for (let i = 0; i < this.state.formUsers.length; i++) {
            if (this.state.formUsers[i].userLevel == '5f6b125929ef8110f415087a') {
                parentAccounts.push(this.state.formUsers[i])
            }
        }
        return (

            <div className={this.props.autoHideSidebar ? "dashboard dashboard-auto-hide" : "dashboard"}>

                <Container fluid>
                    <Row>
                        <Col lg="12">
                            <div className="panel">
                                <div className="panel-header">
                                    <h5 className="component-header">{this.props.linksMeta && this.props.linksMeta[this.props[0].location.pathname] ? this.props.linksMeta[this.props[0].location.pathname][this.props.lang] : ''}</h5>
                                    <button className="btn add-button" onClick={() => this.setState({
                                        form: {}
                                    })}><Isvg src={addIcon} /> {'Add'.translate(this.props.lang)}</button>
                                </div>

                                <ListBuilder
                                    lang={this.props.lang}
                                    loading={this.state.loading}
                                    total={this.state.total}
                                    showNumeration={false}
                                    fields={[
                                        { type: 'text', name: 'username', label: 'Username'.translate(this.props.lang), allowSort: true },
                                        { type: 'text', name: 'name', label: 'Name'.translate(this.props.lang), allowSort: true },
                                        { type: 'text', name: 'userLevelName', label: 'User level'.translate(this.props.lang), allowSort: true },
                                        { type: 'text', name: 'timestamp', label: 'Date created'.translate(this.props.lang), allowSort: true }

                                    ]}
                                    items={this.state.items.map(item => {
                                        return {
                                            ...item,
                                            name: this.props.formatName(item?.userData?.title, item?.userData?.name),
                                            userLevelName: item.isPatient ? `${Object.translate(item, 'userLevelName', this.props.lang)} / ${'Patient'.translate(this.props.lang)}` : Object.translate(item, 'userLevelName', this.props.lang),
                                            timestamp: this.props.getStringDateTs(item.tsCreated, `${this.props.dateFormat} HH:mm:ss`)/*moment.unix(item.tsCreated).format(`${this.props.dateFormat} HH:mm:ss`)*/
                                        }
                                    })}
                                    actions={
                                        [
                                            {
                                                component: <div className="tabel-action-tooltip login-version-button"><Isvg src={loginVersionSvg} className="pie-icon" style={{ margin: 10 }} /> <div className="chat-icons-tooltip">{'Login versions'.translate(this.props.lang)}</div> </div>,
                                                onClick: (item) => {
                                                    if (item?._id) {
                                                        this.getLoginVersion(item._id)
                                                    }
                                                }
                                            },
                                            {
                                                component: <div className="tabel-action-tooltip"><Isvg src={Usage} className="pie-icon" style={{ margin: 10 }} /> <div className="chat-icons-tooltip">{'Number of days before user is deleted from clinic'.translate(this.props.lang)}</div> </div>,
                                                onClick: (item) => this.setState({ counterOfDaysModal: item.counter }),
                                                condition: (item) => {
                                                    if (item.counter && item.counter.length) {
                                                        return true
                                                    } else {
                                                        return false
                                                    }
                                                }
                                            },
                                            {
                                                component: <div className="tabel-action-tooltip"><Isvg src={forgotPassword} className="edit-icon" /> <div className="chat-icons-tooltip">{'Reset password'.translate(this.props.lang)}</div> </div>,
                                                onClick: (item) => this.setState({ forgotPassword: item })
                                            },
                                            {
                                                component: <Isvg src={edit} className="edit-icon" />,
                                                onClick: (item) => {
                                                    if (item && item._id)
                                                        this.getUserProfile(item._id)
                                                }

                                            },
                                            {
                                                component: <Isvg src={garabage} className="delete-icon" />,
                                                onClick: (item) => this.setState({ deleteModal: item }),
                                                condition: (item) => {
                                                    if (item.patientLevel == 1 || item.patientLevel == 2 || item.isPatient) {
                                                        return false
                                                    } else {
                                                        return true
                                                    }
                                                }
                                            },
                                        ]
                                    }
                                    params={params}
                                    sortField={params.sortField}
                                    sortType={params.sortType}
                                    updateSort={this.updateSort}
                                    updateParams={this.updateParams}
                                    updateMultipleParams={this.updateMultipleParams}
                                ></ListBuilder>
                                <DeleteModal
                                    lang={this.props.lang}
                                    isOpen={this.state.deleteModal}
                                    toggle={() => this.setState({ deleteModal: null })}
                                    handler={() => { this.delete(this.state.deleteModal._id); this.setState({ deleteModal: null }) }}
                                >
                                    {'Delete user'.translate(this.props.lang)} <strong>{this.state.deleteModal && this.state.deleteModal.name}</strong>?
                                </DeleteModal>
                                {this.state.removeClinicAdminModal ?
                                    <DeleteModal
                                        lang={this.props.lang}
                                        isOpen={this.state.removeClinicAdminModal}
                                        toggle={() => this.setState({ removeClinicAdminModal: null })}
                                        handler={() => {

                                            fetch(API_ENDPOINT + '/users/users/clinic/' + this.state.removeClinicAdminModal.item._id + '/' + this.state.removeClinicAdminModal.item.clinics[this.state.removeClinicAdminModal.idx]._id, {
                                                method: 'DELETE',
                                                headers: {
                                                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                                                    'content-type': 'application/json'
                                                },
                                            }).then(parseJSON).then(({ result, status }) => {
                                                if (result.error) {
                                                    this.setState({ error: result.error })
                                                }
                                                this.setState({
                                                    removeClinicAdminModal: null
                                                })
                                                this.get();
                                            })

                                        }}
                                    >
                                        {'Remove admin'.translate(this.props.lang)} {this.state.removeClinicAdminModal.item.name}  from {this.state.removeClinicAdminModal.item.clinics[this.state.removeClinicAdminModal.idx].name}
                                    </DeleteModal>
                                    :
                                    null
                                }
                                <DeleteModal
                                    lang={this.props.lang}
                                    isOpen={this.state.aliasModal}
                                    toggle={() => this.setState({ aliasModal: null })}
                                    handler={() => {
                                        this.setState({
                                            loading: true,
                                        }, () => {

                                            fetch(API_ENDPOINT + '/users/users/create-alias/' + this.state.aliasModal._id, {
                                                method: 'GET',
                                                headers: {
                                                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                                                    'content-type': 'application/json'
                                                },
                                            }).then(parseJSON).then(({ result, status }) => {
                                                if (result.error) {
                                                    this.setState({ error: result.error })
                                                }
                                                this.setState({
                                                    aliasModal: null
                                                })
                                                this.get();
                                            })
                                        })

                                    }}
                                >
                                    {'Create alias for user'.translate(this.props.lang)} <strong>{this.state.aliasModal && this.state.aliasModal.name}</strong>?
                                </DeleteModal>
                                <DeleteModal
                                    lang={this.props.lang}
                                    isOpen={this.state.unlinkModal}
                                    toggle={() => this.setState({ unlinkModal: null })}
                                    handler={() => {
                                        this.setState({
                                            loading: true,
                                        }, () => {

                                            fetch(API_ENDPOINT + '/users/users/' + this.state.unlinkModal._id, {
                                                method: 'POST',
                                                headers: {
                                                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                                                    'content-type': 'application/json'
                                                },
                                                body: JSON.stringify({ parentAccount: null, parentAccountName: null })

                                            }).then(parseJSON).then(({ result, status }) => {
                                                if (result.error) {
                                                    this.setState({ error: result.error })
                                                }
                                                this.get();
                                                this.setState({
                                                    unlinkModal: null,
                                                    form: null
                                                })
                                            })
                                        })

                                    }}
                                >
                                    {'Unlink sub account'.translate(this.props.lang)} <strong>{this.state.unlinkModal && this.state.unlinkModal.name}</strong>?
                                </DeleteModal>
                                {this.state.error ?

                                    <ErrorModal lang={this.props.lang}
                                        isOpen={this.state.error}
                                        toggle={() => this.setState({ error: null })}

                                    >
                                        {this.state.error.translate(this.props.lang)}
                                    </ErrorModal>

                                    :
                                    null
                                }
                                {this.state.forgotPassword ?
                                    <InfoModal
                                        isOpen={this.state.forgotPassword}

                                        toggle={() => this.setState({ forgotPassword: null })}
                                        header={'Password recovery'.translate(this.props.lang)}
                                        info={'Are sure you want to send reset password link to selected user?'.translate(this.props.lang)}
                                        buttons={[
                                            <Button color='primary' onClick={() => {
                                                this.forgotPassword(this.state.forgotPassword);

                                            }}>{'Yes'.translate(this.props.lang)}</Button>,

                                            <Button color='primary' onClick={() => {
                                                this.setState({ forgotPassword: null });

                                            }}>{'No'.translate(this.props.lang)}</Button>

                                        ]}
                                    />

                                    :

                                    null}
                                {
                                    this.state.counterOfDaysModal ?
                                        <Modal isOpen={this.state.counterOfDaysModal} centered size='lg'>
                                            <ModalHeader>{'Number of days before user is deleted from clinic'.translate(this.props.lang)}</ModalHeader>
                                            <ModalBody>
                                                {
                                                    this.state.counterOfDaysModal.map(item => {
                                                        return (
                                                            <div>
                                                                {item.groupName ? item.groupName : item.groupId} - {item.counter} {'days'.translate(this.props.lang)}
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </ModalBody>
                                            <ModalFooter className='buttons-right-blue'>
                                                <Button onClick={() => {
                                                    this.setState({
                                                        counterOfDaysModal: false
                                                    })
                                                }}>{'Close'.translate(this.props.lang)}</Button>
                                            </ModalFooter>
                                        </Modal>
                                        :
                                        null
                                }


                            </div>
                        </Col>
                    </Row>

                </Container>
                {
                    this.state.loginVersion ?
                        <Modal isOpen={this.state.loginVersion} centered size='lg'>
                            <ModalHeader>{'Last logins by version'.translate(this.props.lang)}</ModalHeader>
                            <ModalBody>
                                <table className='login-version-table'>
                                    <tr>
                                        <th>{'Platform'.translate(this.props.lang)}</th>
                                        <th>{'Version'.translate(this.props.lang)}</th>
                                        <th>{'Time'.translate(this.props.lang)}</th>
                                    </tr>
                                    {
                                        this.state.loginVersion?.map((item, idx) => {
                                            return (
                                                <tr>
                                                    <td>{item.platform?.toUpperCase()}</td>
                                                    <td>{item.version}</td>
                                                    <td>{this.props.getStringDateTs(item.timestamp, `${this.props.dateFormat} HH:mm`)}</td>
                                                </tr>
                                            )
                                        })
                                    }
                                </table>

                            </ModalBody>
                            <ModalFooter className='buttons-right-blue'>
                                <Button onClick={() => {
                                    this.setState({
                                        loginVersion: null
                                    })
                                }}>{'Close'.translate(this.props.lang)}</Button>
                            </ModalFooter>
                        </Modal>
                        :
                        null
                }
                {this.state.form ?
                    <FormBuilder
                        selectedGroup={this.props.selectedGroup}
                        selectedClinic={this.props.selectedClinic}
                        countryTwoLetter={this.props.countryTwoLetter}
                        country={this.props.country} dateFormat={this.props.dateFormat}
                        getStringDateTs={this.props.getStringDateTs}
                        onSubmit={(data) => {
                            //supportCategories are array everywhere in code so when it comes as string is turned back to array
                            if (data.supportCategory && typeof data.supportCategory === 'string') {
                                data.supportCategory = data.supportCategory.split(',')
                                data.supportCategory.pop()
                            }

                            if (!data.supportPriorityLevel) {
                                data.supportPriorityLevel = 1
                            }

                            if (!this.state.form._disableEdit)
                                this.insertOrUpdate(data)
                        }}
                        initialValues={this.state.form}
                        size="xl"
                        isOpen={this.state.form}
                        toggle={() => this.setState({ form: null })}
                        title={this.state.form._id ? 'Edit user'.translate(this.props.lang) : 'Add new user'.translate(this.props.lang)}
                        _saving={this.state._saving}
                        buttonConfirmText={'Saved'.translate(this.props.lang)}
                        buttonText={'Save'.translate(this.props.lang)}
                        disableSave={this.state.form._disableEdit}

                        lang={this.props.lang}
                        fields={[
                            {
                                type: 'row',
                                children: [
                                    {
                                        type: 'col',
                                        width: {
                                            lg: 6,
                                            sm: 12,
                                            xs: 12
                                        },
                                        children: [
                                            {
                                                type: 'socialSecurityNumber',
                                                name: 'socialSecurityNumber',
                                                searchByNameAndEmailFormBuilder: false,
                                                label: this.props.country === 'uk' ? "Date of birth".translate(this.props.lang) : 'Social security number'.translate(this.props.lang),
                                                validate: [required('Social security number is required!'.translate(this.props.lang)), checkSocialSecurityNumber('Social security number is not valid!'.translate(this.props.lang), this.props.country)],
                                                hideDropDown: true,
                                                hideFindChildren: true
                                            },
                                        ]
                                    },
                                    {
                                        type: 'col',
                                        width: {
                                            lg: 6,
                                            sm: 12,
                                            xs: 12
                                        },
                                        children: [
                                            {
                                                type: 'netId',
                                                name: 'netId',
                                                tooltip: "Do not fill in this field when registering a new user. It is registered afterwards.".translate(this.props.lang),
                                                label: 'HSA-id'.translate(this.props.lang),

                                            },
                                        ]
                                    },

                                    {
                                        type: 'col',
                                        width: {
                                            lg: 2,
                                            sm: 12,
                                            xs: 12
                                        },
                                        children: [
                                            {
                                                type: 'text',
                                                name: 'userData.title',
                                                label: 'Title'.translate(this.props.lang),
                                                disabled: this.props.alias
                                            },
                                        ]
                                    },
                                    {
                                        type: 'col',
                                        width: {
                                            lg: 4,
                                            sm: 12,
                                            xs: 12
                                        },
                                        children: [
                                            {
                                                type: 'text',
                                                name: 'userData.name',
                                                label: 'First and last name'.translate(this.props.lang),
                                                disabled: this.props.alias,
                                                validate: [required('Name is required!'.translate(this.props.lang))]


                                            },
                                        ]
                                    },
                                    this.props.showDateOfBirth ?
                                        {
                                            type: 'col',
                                            width: {
                                                lg: 6,
                                                sm: 12,
                                                xs: 12
                                            },
                                            children: [
                                                {
                                                    type: 'date-of-birth',
                                                    dateFormat: this.props.dateFormat,
                                                    name: 'dateOfBirth.date',
                                                    disabled: this.state.disabledDateOfBirth,
                                                    label: 'Date of birth'.translate(this.props.lang),
                                                    validate: [required('Date of birth is required!'.translate(this.props.lang)), dateValidate('Invalid date!'.translate(this.props.lang))]

                                                },
                                            ]
                                        } : null,
                                    {
                                        type: 'col',
                                        width: {
                                            lg: 6,
                                            sm: 12,
                                            xs: 12
                                        },
                                        children: [
                                            {
                                                type: this.state.form._disableEdit ? 'text' : 'email',
                                                name: 'email',
                                                label: 'Email address'.translate(this.props.lang),
                                                validate: [required('Email address is required!'.translate(this.props.lang)), emailAddress('Invalid email address!'.translate(this.props.lang))]

                                            },
                                        ]
                                    },
                                    {
                                        type: 'col',
                                        width: {
                                            lg: 6,
                                            sm: 12,
                                            xs: 12
                                        },
                                        children: [
                                            {
                                                type: 'text',
                                                name: 'username',
                                                label: 'Username'.translate(this.props.lang),
                                                disabled: true,
                                                validate: [required('Username is required!'.translate(this.props.lang))]

                                            },
                                        ]
                                    },
                                    !this.props.secondaryLanguageDisabled ?
                                        {
                                            type: 'col',
                                            width: {
                                                lg: 6,
                                                sm: 12,
                                                xs: 12
                                            },
                                            children: [
                                                {
                                                    type: 'select',
                                                    name: 'language',
                                                    label: 'Language'.translate(this.props.lang),
                                                    values: this.state.languages.map(item => { return { name: item.name?.translate(this.props.lang), value: item._id } })
                                                },
                                            ]
                                        } : { type: 'empty' },
                                    {
                                        type: 'col',
                                        width: {
                                            lg: 6,
                                            sm: 12,
                                            xs: 12
                                        },
                                        children: [
                                            {
                                                type: 'select',
                                                name: 'articlesEnabled',
                                                disabled: this.props.userLevel == '5f6b126329ef8110f415087b',
                                                label: 'Articles'.translate(this.props.lang),
                                                values: [{ name: 'Disabled'.translate(this.props.lang), value: false }, { name: 'Enabled'.translate(this.props.lang), value: true }]
                                            },
                                        ]
                                    },
                                    {
                                        type: 'col',
                                        width: {
                                            lg: 6,
                                            sm: 12,
                                            xs: 12
                                        },
                                        children: [
                                            {
                                                type: 'select',
                                                name: 'userLevel',
                                                label: 'User level'.translate(this.props.lang),
                                                values: [{ name: 'Select user level'.translate(this.props.lang), value: null }, ...this.state.userLevels.map(item => { return { name: Object.translate(item, 'name', this.props.lang), value: item._id } })],
                                                validate: [required('User level is required!'.translate(this.props.lang))]

                                            },
                                        ]
                                    },
                                    {
                                        type: 'col',
                                        width: {
                                            lg: 6,
                                            sm: 12,
                                            xs: 12
                                        },
                                        children: [
                                            {
                                                type: 'password',
                                                name: 'password',
                                                labelInfo: "Minimum 10 characters, at least one uppercase letter, one lowercase letter, one number and one special character:".translate(this.props.lang),
                                                autoComplete: 'new-password',
                                                label: 'Password'.translate(this.props.lang),


                                            },
                                        ]
                                    },
                                    {
                                        type: 'col',
                                        width: {
                                            lg: 6,
                                            sm: 12,
                                            xs: 12
                                        },
                                        children: [
                                            {
                                                type: 'password',
                                                name: 'repeatPassword',
                                                autoComplete: 'new-password',

                                                label: 'Repeat password'.translate(this.props.lang),


                                            },
                                        ]
                                    },
                                    {
                                        type: 'col',
                                        width: {
                                            lg: 6,
                                            sm: 12,
                                            xs: 12
                                        },
                                        children: [
                                            {
                                                type: this.state.form._disableEdit ? 'text' : 'phone',

                                                name: 'phone',
                                                label: 'Phone'.translate(this.props.lang),


                                            },
                                        ]
                                    },
                                    this.state.form.patientLevel == 1 ?
                                        {
                                            type: 'col',
                                            width: {
                                                lg: 12,
                                                sm: 12,
                                                xs: 12
                                            },
                                            children: [
                                                {
                                                    type: 'list-checkbox',
                                                    name: 'patientRegisterClinicGroup',
                                                    checkboxField: true,
                                                    label: 'Account registered at'.translate(this.props.lang),
                                                    values: [...this.state.form.patientRegisterClinicGroup.map(item => { return { name: item /*Object.translate(item, 'name', this.props.lang)*/, value: '1' } })],

                                                },
                                            ]
                                        }
                                        :
                                        this.state.form.patientLevel == 2 ?
                                            {
                                                type: 'col',
                                                width: {
                                                    lg: 12,
                                                    sm: 12,
                                                    xs: 12
                                                },
                                                children: [
                                                    {
                                                        type: 'list-checkbox',
                                                        name: 'patientClinicConnections',
                                                        checkboxField: true,
                                                        label: 'Patient at clinics'.translate(this.props.lang),
                                                        values: [...this.state.form.patientClinicConnections.map(item => { return { name: item /*Object.translate(item, 'name', this.props.lang)*/, value: '1' } })],

                                                    },
                                                ]
                                            }
                                            :
                                            this.state.form.patientLevel == 20 ?
                                                {
                                                    type: 'col',
                                                    width: {
                                                        lg: 12,
                                                        sm: 12,
                                                        xs: 12
                                                    },
                                                    children: [
                                                        {
                                                            type: 'list-checkbox',
                                                            name: 'doctorClinicConnections',
                                                            checkboxField: true,
                                                            label: 'Member at clinics'.translate(this.props.lang),
                                                            values: [...this.state.form.doctorClinicConnections.map(item => { return { name: item /*Object.translate(item, 'name', this.props.lang)*/, value: '1' } })],

                                                        },
                                                    ]
                                                }
                                                :
                                                null,
                                    this.state.form.patientLevel == 20 && this.state.form.isPatient ?
                                        {
                                            type: 'col',
                                            width: {
                                                lg: 12,
                                                sm: 12,
                                                xs: 12
                                            },
                                            children: [
                                                {
                                                    type: 'list-checkbox',
                                                    name: 'patientClinicConnections',
                                                    checkboxField: true,
                                                    label: 'Patient at clinics'.translate(this.props.lang),
                                                    values: [...this.state.form.patientClinicConnections.map(item => { return { name: item /*Object.translate(item, 'name', this.props.lang)*/, value: '1' } })],

                                                },
                                            ]
                                        }
                                        :
                                        null,
                                    this.state.form.patientLevel == 1 && this.state.form.patientClinicConnections.length ?
                                        {
                                            type: 'col',
                                            width: {
                                                lg: 12,
                                                sm: 12,
                                                xs: 12
                                            },
                                            children: [
                                                {
                                                    type: 'list-checkbox',
                                                    name: 'patientClinicConnections',
                                                    checkboxField: true,
                                                    label: 'Patient at clinics'.translate(this.props.lang),
                                                    values: [...this.state.form.patientClinicConnections.map(item => { return { name: item /*Object.translate(item, 'name', this.props.lang)*/, value: '1' } })],

                                                },
                                            ]
                                        }
                                        :
                                        null,
                                    !this.state._disableSupportCategory ?
                                        {
                                            type: 'col',
                                            width: {
                                                lg: 6,
                                                sm: 12,
                                                xs: 12
                                            },
                                            children: [
                                                {
                                                    //values is turned from array to string so that redux can see changes when we check checkbox
                                                    type: 'list-checkbox-string-values',
                                                    name: 'supportCategory',
                                                    multiple: true,
                                                    label: 'Support category'.translate(this.props.lang),
                                                    values: [...this.state.supportCategory.map(item => { return { name: item.name, value: item._id } })],


                                                },
                                            ]
                                        }
                                        : null,
                                    !this.state._disableSupportCategory && this.props.supportCategory ?
                                        {
                                            type: 'col',
                                            width: {
                                                lg: 6,
                                                sm: 12,
                                                xs: 12
                                            },
                                            children: [
                                                {
                                                    type: 'select',
                                                    name: 'supportPriorityLevel',
                                                    label: 'Support priority level'.translate(this.props.lang),
                                                    values: [{ name: '1st line'.translate(this.props.lang), value: 1 }, { name: '2nd line'.translate(this.props.lang), value: 2 }, { name: '3rd line'.translate(this.props.lang), value: 3 }],


                                                }
                                            ]
                                        }
                                        : null,
                                    !this.state._disableSupportCategory && this.props.supportCategory ?
                                        {
                                            type: 'col',
                                            width: {
                                                lg: 12,
                                                sm: 12,
                                                xs: 12
                                            },
                                            children: [
                                                {
                                                    type: 'checkbox',
                                                    name: 'selectAllAndDisableValues',
                                                    label: 'Select all clinic groups for support'.translate(this.props.lang),
                                                },
                                            ]
                                        }
                                        : null,
                                    !this.state._disableSupportCategory && this.props.supportCategory ?
                                        {
                                            type: 'col',
                                            width: {
                                                lg: 12,
                                                sm: 12,
                                                xs: 12
                                            },
                                            children: [
                                                {
                                                    type: 'easy-select',
                                                    name: 'supportClinicGroups',
                                                    disableFilter: true,
                                                    selectAllAndDisableValues: this.props.selectAllAndDisableValues ? true : false,
                                                    label: 'Support clinic groups'.translate(this.props.lang),
                                                    multiple: true,
                                                    values: this.state.clinicGroups.map((item, idx) => { return { value: item._id, name: item.name } }),
                                                    lang: this.props.lang,
                                                    validate: [required('One clinic must be selected!'.translate(this.props.lang))]
                                                },
                                            ]
                                        }
                                        : null,
                                    {
                                        type: 'col',
                                        width: {
                                            lg: 12,
                                            sm: 12,
                                            xs: 12
                                        },
                                        children: [
                                            {
                                                type: 'easy-select',
                                                name: 'regions',
                                                disableFilter: true,
                                                label: 'Regions'.translate(this.props.lang),
                                                disabled: this.props.userLevel == '5f6b126329ef8110f415087b',
                                                multiple: true,
                                                values: this.state.regions.map((item, idx) => { return { value: item._id, name: item.shortName } }),
                                                lang: this.props.lang
                                            },
                                        ]
                                    },

                                    // {
                                    //     type: 'col',
                                    //     width: {
                                    //         lg: 12,
                                    //         sm: 12,
                                    //         xs: 12
                                    //     },
                                    //     children: [
                                    //         {
                                    //             type: 'table',
                                    //             name: 'subAccounts',
                                    //             fields: [{ name: 'name', type: 'text' }],
                                    //             actions: [
                                    //                 {
                                    //                     component: <i className="fa fa-edit base-color" />,
                                    //                     onClick: (item) => this.setState({ form: null }, () => this.setState({ form: item }))
                                    //                 },
                                    //                 {
                                    //                     component: <i className="fa fa-unlink danger-color" />,
                                    //                     onClick: (item) => this.setState({ unlinkModal: item })
                                    //                 },

                                    //                 {
                                    //                     component: <i className="fa fa-trash danger-color" />,
                                    //                     onClick: (item) => this.setState({ deleteModal: item })
                                    //                 },

                                    //             ],
                                    //             label: 'Sub Accounts'.translate(this.props.lang),
                                    //         },
                                    //     ]
                                    // },

                                    // {
                                    //     type: 'col',
                                    //     width: {
                                    //         lg: 12,
                                    //         sm: 12,
                                    //         xs: 12
                                    //     },
                                    //     children: [
                                    //         {
                                    //             type: 'table',
                                    //             name: 'clinics',
                                    //             fields: [{ name: 'name', type: 'text' }],
                                    //             actions: [

                                    //                 {
                                    //                     component: <i className="fa fa-trash danger-color" />,
                                    //                     onClick: (clinic) => {
                                    //                         let idx;
                                    //                         for (let i = 0; i < this.state.form.clinics.length; i++) {
                                    //                             if (this.state.form.clinics[i]._id == clinic._id) {
                                    //                                 idx = i;
                                    //                                 break;
                                    //                             }
                                    //                         }
                                    //                         let item = JSON.parse(JSON.stringify(this.state.form));

                                    //                         this.setState({ removeClinicAdminModal: { item, idx }, form: null })
                                    //                     }
                                    //                 },

                                    //             ],
                                    //             label: 'Current clinics'.translate(this.props.lang),
                                    //         },
                                    //     ]
                                    // },

                                    /*{
                                        type: 'col',
                                        width: {
                                            lg: 8,
                                            sm: 12,
                                            xs: 12
                                        },
                                        children: [
                                            {
                                                type: 'row',
                                                children: [

                                                    {
                                                        type: 'col',
                                                        width: {
                                                            lg: 12,
                                                            sm: 12,
                                                            xs: 12
                                                        },
                                                        children: [
                                                            {
                                                                type: 'text',
                                                                name: 'clinicData.name',
                                                                label: 'Clinic name'.translate(this.props.lang),
                                                            },
                                                        ]
                                                    },

                                                    {
                                                        type: 'col',
                                                        width: {
                                                            lg: 12,
                                                            sm: 12,
                                                            xs: 12
                                                        },
                                                        children: [
                                                            {
                                                                type: 'text',
                                                                name: 'clinicData.address',
                                                                label: 'Street name and number'.translate(this.props.lang),
                                                            },
                                                        ]
                                                    },
                                                    {
                                                        type: 'col',
                                                        width: {
                                                            lg: 4,
                                                            sm: 12,
                                                            xs: 12
                                                        },
                                                        children: [
                                                            {
                                                                type: 'text',
                                                                name: 'clinicData.post',
                                                                label: 'Zip code'.translate(this.props.lang),
                                                            },
                                                        ]
                                                    },


                                                    {
                                                        type: 'col',
                                                        width: {
                                                            lg: 8,
                                                            sm: 12,
                                                            xs: 12
                                                        },
                                                        children: [
                                                            {
                                                                type: 'text',
                                                                name: 'clinicData.city',
                                                                label: 'City'.translate(this.props.lang),
                                                            },
                                                        ]
                                                    },
                                                    {
                                                        type: 'col',
                                                        width: {
                                                            lg: 12,
                                                            sm: 12,
                                                            xs: 12
                                                        },
                                                        children: [
                                                            {
                                                                type: 'text',
                                                                name: 'clinicData.phone',
                                                                label: 'Phone'.translate(this.props.lang),
                                                            },
                                                        ]
                                                    },
                                                    {
                                                        type: 'col',
                                                        width: {
                                                            lg: 12,
                                                            sm: 12,
                                                            xs: 12
                                                        },
                                                        children: [
                                                            {
                                                                type: 'text',
                                                                name: 'clinicData.email',
                                                                label: 'Email'.translate(this.props.lang),
                                                            },
                                                        ]
                                                    },





                                                ]
                                            }
                                        ]
                                    },
                                    {
                                        type: 'col',
                                        width: {
                                            lg: 4,
                                            sm: 12,
                                            xs: 12
                                        },
                                        children: [
                                            {
                                                type: 'image',
                                                name: 'clinicData.logo',
                                                label: 'Clinic logo'.translate(this.props.lang),
                                            },
                                        ]
                                    },
                                    {
                                        type: 'col',
                                        width: {
                                            lg: 12,
                                            sm: 12,
                                            xs: 12
                                        },
                                        children: [
                                            {
                                                type: 'checkbox',
                                                name: 'referralCreating',
                                                afterText: 'User can create referrals'.translate(this.props.lang),
                                            },
                                        ]
                                    },
                                    {
                                        type: 'col',
                                        width: {
                                            lg: 12,
                                            sm: 12,
                                            xs: 12
                                        },
                                        children: [
                                            {
                                                type: 'checkbox',
                                                name: 'referralReceiving',
                                                afterText: 'User can receive referrals'.translate(this.props.lang),
                                            },
                                        ]
                                    },*/



                                ]
                            }
                        ]}
                    ></FormBuilder>
                    :
                    null
                }

            </div>
        );
    }
}


const selector = formValueSelector('modalForm');

export default connect(state => {
    return {
        userLevel: selector(state, 'userLevel'),
        alias: selector(state, 'alias'),
        username: selector(state, 'username'),
        name: selector(state, 'userData.name'),
        selectAllAndDisableValues: selector(state, 'selectAllAndDisableValues'),
        supportCategory: selector(state, 'supportCategory'),
        socialSecurityNumber: selector(state, 'socialSecurityNumber'),
        dateOfBirth: selector(state, 'dateOfBirth.date'),


    }


}, {
    changeParentAccount: value => change("modalForm", "parentAccount", value),
    changeUsername: value => change("modalForm", "username", value),
    changeDateOfBirth: value => change("modalForm", "dateOfBirth.date", value),
})(Page(Users));