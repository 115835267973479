import React from 'react';

import {
    Container,
    Row,
    Col,
    FormGroup, Label, Input, Button, UncontrolledAlert,
    Modal, ModalBody
} from 'reactstrap';

import { Document, Page as Pageee, pdfjs } from 'react-pdf';
import { API_ENDPOINT } from '../constants';

/**
* Open print page of browser, generate Save PDF button
* @author   Milan Stanojevic
*/
class PdfPreviewComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            pageNumber: 1,
            numPages: 0
        }
    }

    componentDidMount() {
        if (this.props.data) {
            this.setState({ data: this.props.data })
        }
    }
    componentDidUpdate(prevProps) {
        if (this.props.data && !this.state.data) {
            this.setState({ data: this.props.data })
        }
    }


    nextPage = () => {
        if (this.state.pageNumber < this.state.numPages)
            this.setState({
                pageNumber: this.state.pageNumber + 1
            })
    }
    backPage = () => {
        if (this.state.pageNumber != 1)
            this.setState({
                pageNumber: this.state.pageNumber - 1
            })
    }
    onDocumentLoadSuccess = ({ numPages }) => {
        if (this.state.numPages != numPages) {
            this.setState({
                numPages: numPages
            })
        }


    }

    render() {
        if (!this.state.data) {
            return null;
        }

        let divStyle = { width: '100%' }

        return (
            <div style={divStyle} ref={(node) => this.pdfDocument = node} >

                <Document
                    file={{ url: API_ENDPOINT + (this.state.data.privacyPolicy && typeof this.state.data.privacyPolicy == "object" ? this.state.data.privacyPolicy.file : this.state.data.privacyPolicy) }}
                    onLoadSuccess={this.onDocumentLoadSuccess}
                    error={"Failed to load PDF file.".translate(this.props.lang)}
                    loading={"Loading PDF...".translate(this.props.lang)}
                    noData={"No page specified.".translate(this.props.lang)}
                >
                    <Pageee                       
                        width={this.pdfDocument ? this.pdfDocument.clientWidth : null}
                        pageNumber={this.state.pageNumber}
                    />
                </Document>
                <div className="page-pagination">
                    <Button onClick={this.backPage}>{'Previous'.translate(this.props.lang)}</Button>
                    <p>{'Page'.translate(this.props.lang)} {this.state.pageNumber} {'of'.translate(this.props.lang)} {this.state.numPages}</p>
                    <Button onClick={this.nextPage}>{'Next'.translate(this.props.lang)}</Button>
                </div>

            </div>
        )
    }
}

export default PdfPreviewComponent;