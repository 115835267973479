import React from 'react';
import { jsPDF } from "jspdf";

import {
    Button
} from 'reactstrap';

import Isvg from 'react-inlinesvg';
import printModal from '../assets/svg/print-modal.svg'

/**
* Open print page of browser
* @author   Milan Stanojevic
*/
class PrintHelper extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    componentDidMount() {

        if (this.props.element) {
            const doc = new jsPDF('p', 'mm', [210, 297]);
            doc.html(this.props.element, {
                margin: [20, 20, 20, 20],

                html2canvas: {
                    scale: 0.1909
                },

                callback: () => {
                    this.setState({
                        blob: doc.output('bloburl')
                    })
                },

            })
        }
    }

    render() {
        return (
            <div>
                { this.state.blob && this.state._loaded ?
                    <Button onClick={() => {
                        this.iframe.focus();
                        this.iframe.contentWindow.print()
                    }}><Isvg src={printModal} style={{ marginRight: 5 }} />
                        {'Print'.translate(this.props.lang)}
                    </Button>
                    :
                    null
                }
                <iframe width="0" height="0" style={{ border: 0 }} src={this.state.blob} ref={(node) => this.iframe = node} onLoad={() => this.setState({ _loaded: true })}></iframe>

            </div>
        )
    }
}

export default PrintHelper;