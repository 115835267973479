import React, { Component } from "react";
import { Container, Row, Col, Button, Modal, ModalBody, ModalHeader, } from "reactstrap";
import { API_ENDPOINT } from "../constants";
import { Player, Controls } from "@lottiefiles/react-lottie-player";

/**
* Swish component
* @author   Stefan Pantic
*/
class Swish extends Component {
  constructor(props) {
    super(props);
  }
  state = {
    originalPaymentReference: "", //Nakon izvrsenog placanja, ovde se belezi referenca uplate koju treba sacuvati u bazi za potrebe povracaja novca
    identifier: "", //ID broj Swish transakcije se kreira nakon uspesnog pokretanja postupka placanja. Treba ga sacuvati u bazi kao identifikator transakcije
    currentStatus: "Wait...", //Trenutni status postupka placanja
    formData: {
      payee: "1231181189", //Ubaciti broj racuna klinike iz Clinic Group 
      payer: "46707590899", //Ubaciti broj telefona pacijenta, bez + na pocetku
      amount: "200", //Ubaciti iznos koji treba naplatiti u SEK
      message: "100046", //Ubaciti broj remissa. Ovo polje se kod primaoca uplate vidi kao poruka.
    },
  };

  handleStartPayment() { //Pokretanje zahteva za placanje
    const url = API_ENDPOINT + "/swish/paymentrequests";
    // console.log(this.state.formData)
    // console.log(this.props.referral)
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        // payeeAlias: this.state.formData.payee,
        payerAlias: this.state.formData.payer,
        amount: this.state.formData.amount,
        message: this.state.formData.message,
        // alias: localStorage.getItem('groupAlias')
        referral: this.props.referral,
        sendNotification: this.props.sendNotification
      }),
    })
      .then((response) => {
        if (response.status != 201 && response.status !== 200) {
          this.setState({
            currentStatus: "Request failure: ".translate(this.props.lang) + response.statusText,
          });
          return;
        }
        return response.json();
      })
      .then((json) => {
        if (json) {
          if (json["status"] === "CREATED") {
            this.setState({ identifier: json["id"] });
            this.setState({ currentStatus: "Open your Swish app to confirm payment." });
            this.registerNewPayment(); //Registrovanje nove naplate
            this.checkPaymentStatus(); //Provera statusa naplate (autorefresh na 2 sekunde)
          }
        }
      })
      .catch((error) => {
        // console.log("Request failure: ", error);
      });
  }

  checkPaymentStatus = () => {
    if (!this.state.identifier || this.state.identifier.length <= 0) {
      this.setState({ currentStatus: "No payment Id" });
      return;
    }
    const url = API_ENDPOINT + "/swish/paymentrequests/" + this.state.identifier;

    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        if (json.status === "PAID") {
          this.setState({ originalPaymentReference: json["paymentReference"] });
          this.setState({ currentStatus: "Payment successfull." });
          this.paymentSuccessfull(); //Registrovanje uspesno izvrsenog placanja
        }
        else {
          // console.log("Checking for payment");
          setTimeout(this.checkPaymentStatus, 2000);
        }
      })
      .catch((error) => {
        console.log("Request failure: ", error);
      });
  }

  handlePayerChanged(phone) {
    var formData = this.state.formData;
    var payer = phone;
    formData.payer = payer;
    this.setState({
      formData: formData,
    });
  }

  /**
  * Insert new transaction 
  * @author   Stefan Pantic
  * @Objectparam    {String} identifier     transaction id
  * @Objectparam    {String} referral       referral id
  */
  registerNewPayment() {
    //Pokrenut je postupak placanja.
    //Registrovati u bazi this.state.identifier uz remiss
    console.log('Payment process started');

    fetch(API_ENDPOINT + '/swish/transaction', {
      method: 'POST',
      headers: {
        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
        'content-type': 'application/json'
      },
      body: JSON.stringify({ referral: this.props.referral, identifier: this.state.identifier, doctor: this.props.doctor, patient: this.props.patient, data: this.props.data })
    }).then(res => res.json()).then((result) => {

    })
  }

  /**
  * Update transaction data with status successfull
  * @author   Stefan Pantic
  * @Objectparam    {String} identifier                   transaction id
  * @Objectparam    {String} originalPaymentReference     transaction reference
  */
  paymentSuccessfull() {
    //Placanje je izvrseno uspesno.
    //Registrovati u bazi, prema this.state.identifier, da je remiss placen i ubeleziti uz njega originalPaymentReference this.state.originalPaymentReference
    console.log('Payment successfull');

    fetch(API_ENDPOINT + '/swish/transaction-successfull', {
      method: 'POST',
      headers: {
        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
        'content-type': 'application/json'
      },
      body: JSON.stringify({ identifier: this.state.identifier, originalPaymentReference: this.state.originalPaymentReference })
    }).then(res => res.json()).then((result) => {

      this.checkUpdate()

      if (result.refData && result.refData.doctor != 'none' && result.refData.patient != 'none') {
        this.scheduleReferral(result.refData, this.state.identifier)
      } else {
        this.generateInvoice(result.refData, this.state.identifier, 'swish')
        if (window.location.href == `${window.location.origin}/my-data/personal-referrals/referrals`) {
          location.reload()
        } else {
          if (this.props.callbackFunction) {
            this.props.callbackFunction()
          } else {
            this.props.history.history.push('/my-data/personal-referrals/referrals')
          }

        }
      }

    })
  }
  checkUpdate = () => {

    fetch(API_ENDPOINT + '/payment/check/update', {
      method: 'GET',
      headers: {
        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
        'content-type': 'application/json'
      },
    })
  }

  /**
    * Schedule referral event on calendar
    * @author   Stefan Pantic
    * @Objectparam    {String} date                 date when event start
    * @Objectparam    {Number} startTime            start time for event
    * @Objectparam    {Number} endTime              start time for event
    * @Objectparam    {String} doctor               doctor id
    * @Objectparam    {String} patientNote          note for events with referral
    * @Objectparam    {String} patient              patient id
    * @Objectparam    {String} referral             referral id
    */
  scheduleReferral = (refData, identifier) => {

    this.setState({
      loading: true
    }, () => {
      fetch(API_ENDPOINT + '/referrals/patient-schedule/' + refData.referral.toString(), {
        method: 'POST',
        headers: {
          'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
          'content-type': 'application/json'
        },
        body: JSON.stringify({ startEventReservedDateOriginal: refData.data.date + ' ' + refData.data.startTime, endEventReservedDateOriginal: refData.data.date + ' ' + refData.data.endTime, startPatientReservedDate: this.props.getStringDateInverseTs(Math.floor(new Date(refData.data.date + ' ' + refData.data.startTime).getTime() / 1000), `${this.props.dateFormat} HH:mm`), endPatientReservedDate: this.props.getStringDateInverseTs(Math.floor(new Date(refData.data.date + ' ' + refData.data.endTime).getTime() / 1000), `${this.props.dateFormat} HH:mm`), doctor: refData.doctor, patientNote: refData.data.patientNote, patient: refData.patient, referral: refData.referral })
      }).then(res => res.json()).then((result) => {
        this.generateInvoice(refData, identifier, 'swish');
        if (this.props.callbackFunction) {
          this.setState({ swishModal: null }, () => this.props.callbackFunction())

        } else {
          this.props.history.history.push('/my-data/personal-referrals/referrals')
        }
        // this.props.history.history.push('/my-data/personal-referrals/referrals')
      })
    })

  }

  /**
  * Generate invoice for referral
  * @author   Stefan Pantic
  * @Objectparam    {String} refData                referral data
  * @Objectparam    {String} transaction            transaction identifier
  */
  generateInvoice = (refData, transaction, online) => {


    this.setState({
      loading: true
    }, () => {
      fetch(API_ENDPOINT + '/referrals/generate-invoice/' + refData.referral.toString(), {
        method: 'POST',
        headers: {
          'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
          'content-type': 'application/json'
        },
        body: JSON.stringify({ transaction: transaction, online: online })
      }).then(res => res.json()).then((result) => {
        if (this.props.callbackFunction) {
          this.setState({ swishModal: null }, () => this.props.callbackFunction())
        }
        this.props.clearStatusBefore();
        this.props.verifyUser();
      })
    })

  }

  componentDidMount() {
    // console.log(this.props.open, this.props.price, this.props.phone)
    if (this.props.referral) {
      this.setState({
        loading: true
      }, () => {
        fetch(API_ENDPOINT + '/referrals/referrals-number/' + this.props.referral, {
          method: 'GET',
          headers: {
            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            'content-type': 'application/json'
          },
        }).then(res => res.json()).then((result) => {
          if (this.props.open && this.props.price && this.props.phone && result) {

            let phone
            if (this.props.phone.indexOf('+') == 0) {
              phone = this.props.phone.replace('+', '')
            } else if (this.props.phone.indexOf('00') == 0) {
              phone = this.props.phone.replace(/^00/, '')

            } else {
              phone = this.props.phone
            }

            if (phone.indexOf('+') !== 0) {
              this.setState({
                testPhone: '+' + phone
              })
            } else {
              this.setState({
                testPhone: phone
              })
            }

            if (phone.indexOf('46') !== 0) {
              phone = '46' + phone
            }

            let formData = this.state.formData;
            formData.payer = phone;
            formData.amount = this.props.price;
            formData.message = result.number;

            this.setState({
              swishModal: true,
              // payee: '123456789',
              formData
            })


          }
          this.setState({ currentStatus: "" });
        })
      })
    }

  }
  render() {
    // console.log(this.state.swishModal)
    return (
      <div className={this.props.autoHideSidebar ? "dashboard dashboard-auto-hide" : "dashboard"}>
        {this.state.swishModal ?
          <Modal isOpen={this.state.swishModal} centered>
            <ModalHeader toggle={() => {
              if (!this.state.identifier)
                this.setState({ swishModal: null }, () => {
                  this.props.openModal(false);
                })
            }
            }
              close={
                !this.state.identifier ?
                  <button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ swishModal: null }, () => {
                    this.props.openModal(false);
                  })}
                  >&times;</button> : <></>
              }>{'Payment'.translate(this.props.lang)}</ModalHeader>

            <ModalBody style={{ position: 'relative' }}>
              <div className="login-main-wrap" style={{ height: '50vh', minHeight: '50vh', display: "flex", alignItems: "center" }}>
                {/* <div  style={{ width: '100%' }}> */}
                {/* <div className="log-in"> */}
                <Container fluid>
                  <Row>
                    <Col lg={{ size: 12 }}>
                      <div className="panel" style={{ paddingTop: 0 }}>
                        <div className="login-container">
                          <h4>{"Swish payment".translate(this.props.lang)}</h4>
                          <div className="login-form">
                            <form>
                              <Row>
                                <Col lg={{ size: 12 }}>
                                  <div className="form-group">
                                    <label className="col-form-label-sm">
                                      {"Your Swish mobile number".translate(this.props.lang)}
                                    </label>
                                    <input
                                      type="text"
                                      className="form-contro-sm form-control"
                                      // value={this.state.formData.payer}
                                      value={this.state.testPhone}
                                      onChange={(e) => {

                                        let phone = e.target.value.replace(/[A-Za-z]/g, '')
                                        this.setState({
                                          testPhone: phone
                                        })


                                        if (phone.indexOf('00') == 0) {
                                          phone = phone.replace(/^00/, '')
                                        } else if (phone.indexOf('0') == 0) {
                                          phone = phone.replace(/^0/, '')
                                        }
                                        if (phone.indexOf('+00') == 0) {
                                          phone = phone.replace('+00', '+')
                                        } else if (phone.indexOf('+0') == 0) {
                                          phone = phone.replace('+0', '+')
                                        }

                                        phone = phone.replace(/[^0-9]/g, '')

                                        if (phone.indexOf('46') !== 0) {
                                          phone = '46' + phone
                                        }

                                        this.handlePayerChanged(phone);
                                      }}
                                    />
                                  </div>
                                </Col>
                                <Col lg={{ size: 6 }}>
                                  <div>{"Amount:".translate(this.props.lang)}<br /><strong>{this.state.formData.amount} kr.</strong></div>
                                </Col>
                                <Col lg={{ size: 6 }}>
                                  <div>{"Reference:".translate(this.props.lang)}<br /><strong>{this.state.formData.message}</strong></div>
                                </Col>
                              </Row>
                            </form>
                          </div>
                          <Row>
                            <Col lg={{ size: 12 }}>
                              <div className="bank-id-button">
                                <Button disabled={this.state.identifier}
                                  onClick={this.handleStartPayment.bind(this)}
                                >
                                  {"Start Payment".translate(this.props.lang)}
                                </Button>
                              </div>
                            </Col>
                          </Row>
                          <div style={{ position: "relative" }}>
                            <p>{this.state.currentStatus.translate(this.props.lang)}</p>
                          </div>
                          {/* <div style={{ position: "relative" }}>
                            <p>{this.state.formData.payer}</p>
                          </div> */}

                        </div>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>
              {/* </div> */}
              {/* </div> */}
              {this.state.currentStatus == 'Open your Swish app to confirm payment.' ?
                <div className="swish-modal-loading" >
                  <div>
                    <Player
                      autoplay={true}
                      loop={true}
                      src="/lf30_editor_l5cxzdyf.json"
                      style={{ height: "256px", width: "256px", position: 'absolute', top: "50%", left: '50%', transform: 'translate(-50%, -50%)' }}
                    ></Player>
                  </div>
                </div>
                :
                null
              }
            </ModalBody>
          </Modal>
          : null}
      </div>

    );
  }
}

export default Swish;


// typeof window != 'undefined' && window.innerWidth < 768 ? "128px" : 