
import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import {
    FormGroup, Label, Form, Modal, ModalBody, ModalHeader, ModalFooter, Button
} from 'reactstrap';

import Isvg from 'react-inlinesvg';
import image from '../../assets/svg/picture.svg';
import { API_ENDPOINT } from '../../constants';
import { Player, Controls } from "@lottiefiles/react-lottie-player";
import { formatCSS } from '../cssHelper';

import Image from './image'

function parseText(response) {
    return response.text().then(text => {
        return {
            result: text,
            status: response.status
        }
    })
}
/**
* Component for import and style image
* @author   Milan Stanojevic
*/
class Icon extends Component {
    constructor(props) {
        super(props);
        this.selectFile = this.selectFile.bind(this);
        this.state = {
            lang: 'se',
            tab: 1,
            value: null
        };
    }


    componentDidMount() {
        if (typeof window != 'undefined' && window.lang) {
            this.setState({ lang: window.lang })
        }
        if (this.props.value) {
            this.setState({ value: this.props.value })
        }
    }
    saveChanges = () => {
        this.props.onChange(this.state.value);
        this.setState({
            iconLibraryModal: false
        })

    }
    selectFile(e) {


        this.setState({
            error: null
        })
        let input = e.target;
        if (input.files && input.files[0]) {
            let fsize = input.files[0].size;
            let file = Math.round((fsize / 1024))

            if (file > 5120) {
                this.setState({ error: "File size limit has been reached! (max 5 MB)".translate(this.props.lang) })
            } else {
                this.setState({
                    _loading: true
                })

                let formData = new FormData();
                formData.append('file', input.files[0]);


                fetch(API_ENDPOINT + '/data/upload-image/1920', {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,

                    },
                    body: formData
                }).then(parseText).then(({ result, status }) => {
                    console.log(result)
                    if (status != 200) {
                        this.setState({ error: result.translate(this.props.lang) })
                    } else {
                        this.setState({
                            _loading: null,
                            value: result
                        })
                    }
                })
            }


        }
    }

    render() {
        return (

            <div className="app-builder-icon-picker" style={{ position: 'relative' }} onClick={() => { this.setState({ iconLibraryModal: true }) }}>

                {this.props.value && this.props.value != null && this.props.value != 'null' ?
                    <img src={API_ENDPOINT + this.props.value} />
                    :
                    null
                }
                {
                    this.props.value ?
                        <button className="delete" onClick={(e) => {
                            e.stopPropagation()
                            this.props.onChange(null)
                        }}>&times;</button>
                        :
                        null
                }
                {
                    this.state._loading ?
                        <div className="loader-wrap" >
                            <Player
                                autoplay={true}
                                loop={true}
                                src="/lf30_editor_l5cxzdyf.json"
                                style={{ height: "128px", width: "128px", position: 'absolute', top: "50%", left: '50%', transform: 'translate(-50%, -50%)' }}
                            ></Player>

                        </div>
                        :
                        null
                }

                {
                    this.state.iconLibraryModal ?
                        <Modal isOpen={this.state.iconLibraryModal} size='lg' centered className="icon-library-wrap-builder">
                            <ModalHeader>
                                {'Choose icon'.translate(this.props.lang)}
                                <div style={{ cursor: 'pointer' }} onClick={() => {
                                    this.setState({ iconLibraryModal: false })
                                }}> &times;</div>
                            </ModalHeader>

                            <ModalBody>
                                <div className='choose-icon-tab-wrap'>
                                    <div className={this.state.tab == 1 ? "choose-icon-tab-item active-tab" : "choose-icon-tab-item"} onClick={() => this.setState({ tab: 1 })}>
                                        {'Icon library'.translate(this.props.lang)}
                                    </div>
                                    <div className={this.state.tab == 2 ? "choose-icon-tab-item active-tab" : "choose-icon-tab-item"} onClick={() => this.setState({ tab: 2 })}>
                                        {'Upload file'.translate(this.props.lang)}
                                    </div>
                                </div>
                                {
                                    this.state.tab == 1 ?
                                        this.props.libraryIcons && this.props.libraryIcons.length ?
                                            <div className="icon-items">
                                                {
                                                    this.props.libraryIcons && this.props.libraryIcons.map((icon, idx) => {
                                                        return (
                                                            <div className="icon-item" onClick={() => {
                                                                this.setState({ value: icon })
                                                                // this.setState({ iconLibraryModal: false })
                                                            }}>
                                                                <img src={API_ENDPOINT + icon} />
                                                            </div>
                                                        )
                                                    })
                                                }

                                            </div>
                                            :
                                            <div className="empty-array">
                                                <h6>{'No icons in library'.translate(this.props.lang)}</h6>
                                            </div>
                                        :
                                        null

                                }
                                {
                                    this.state.tab == 2 ?
                                        <div className="app-builder-image-picker" style={{ position: 'relative' }}>

                                            <input type="file" accept=".jpg, .jpeg, .JPG, .JPEG, .png, .gif" title={"No file chosen".translate(this.props.lang)} onChange={this.selectFile} key={this.props.value} />
                                            <Button color='primary'>{'Upload icon'.translate(this.props.lang)}</Button>
                                            {
                                                this.state._loading ?
                                                    <div className="loader-wrap" >
                                                        <Player
                                                            autoplay={true}
                                                            loop={true}
                                                            src="/lf30_editor_l5cxzdyf.json"
                                                            style={{ height: "128px", width: "128px", position: 'absolute', top: "50%", left: '50%', transform: 'translate(-50%, -50%)' }}
                                                        ></Player>

                                                    </div>
                                                    :
                                                    null
                                            }



                                        </div>
                                        :
                                        null

                                }
                                <hr/>
                                {this.state.value && this.state.value != 'null' ?
                                    <div>
                                        <div className="preview-icon">
                                            <img src={API_ENDPOINT + this.state.value} />
                                            {
                                                this.state._loading ?
                                                    <div className="loader-wrap" >
                                                        <Player
                                                            autoplay={true}
                                                            loop={true}
                                                            src="/lf30_editor_l5cxzdyf.json"
                                                            style={{ height: "128px", width: "128px", position: 'absolute', top: "50%", left: '50%', transform: 'translate(-50%, -50%)' }}
                                                        ></Player>

                                                    </div>
                                                    :
                                                    null
                                            }
                                        </div>
                                        <Button color="primary" onClick={() => { this.saveChanges() }}>{'Save'.translate(this.props.lang)}</Button>
                                    </div>
                                    :
                                    null
                                }

                            </ModalBody>

                            <ModalFooter >


                            </ModalFooter>

                        </Modal>
                        :
                        null
                }

            </div>
        );
    }
}

export default Icon;