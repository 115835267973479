import React from 'react';

import {
    Modal, ModalHeader, ModalBody, ModalFooter, Button
} from 'reactstrap';
import info from '../assets/svg/info.svg'
import xIcon from '../assets/svg/x.svg';
import Isvg from 'react-inlinesvg';

/**
* Info modal 
* @author   Milan Stanojevic
*/
class InfoModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    componentDidMount() {
        if (this.props.isOpen) {
            this.setState({
                isOpen: this.props.isOpen
            })
        }
    }

    render() {
        return (

            <Modal isOpen={this.state.isOpen} toggle={this.props.toggle} centered size={this.props.size ? this.props.size : null}>
                {/* {this.props.close ?

                    <ModalHeader style={{ margin: 'auto' }}>
                        {this.props.header}
                    </ModalHeader>

                    :

                    <ModalHeader style={{ margin: 'auto' }}>
                        {this.props.header}
                    </ModalHeader>
                } */}
                <ModalBody className="delete-modal">
                    {/* <Isvg src={info} /> */}
                    <div className='message-info' style={{ wordBreak: 'break-word', textAlign: 'center', paddingTop: 30, paddingLeft: 20, paddingRight: 20 }}>
                        {
                            this.props.info
                        }
                    </div>

                </ModalBody>
                <ModalFooter className="buttons-right-blue">
                    {this.props.buttons}
                </ModalFooter>
            </Modal>
        )
    }
}

export default InfoModal;