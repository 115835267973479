import React, { Component } from 'react';
import Page from '../../containers/page';

import {
    Container,
    Row,
    Col,
    FormGroup, Label, Input

} from 'reactstrap';

import ListBuilder from '../../components/listBuilder';
import DeleteModal from '../../components/deleteModal';
import { getSearchParams, generateSearchLink, generateSearchLinkMultiple } from '../../helpers/linkHelper';
import { API_ENDPOINT } from '../../constants';
import ErrorModal from '../../components/errorModal';
import { formValueSelector, change } from 'redux-form';  // ES6
import { connect } from 'react-redux';
import DatePicker from '../../components/forms/fields/datePicker';
import moment from 'moment';
import exportIcon from '../../assets/svg/export.svg';
import Isvg from 'react-inlinesvg'


function parseJSON(response) {
    return response.json().then(json => {
        return {
            result: json,
            status: response.status
        }
    })
}

/**
* Group statistics page
* @author   Milan Stanojevic
*/
class Statistics extends Component {
    constructor(props) {
        super(props);

        let currDate = new Date();
        let date1 = new Date(currDate.getFullYear(), 0, 1);
        let date2 = new Date();


        this.state = {
            ...props.initialData,
            form: false,
            useState: false,
            params: {
                entries: 10,
                page: 0,
                'query-group': null
            },
            items: [],
            total: 0,
            loading: true,
            subgroups: [],
            categories: [],
            formModules: [],
            groups: [],
            clinicGroupID: [],
            searchGroups: '',
            listOfGroups: [],
            dateFrom: moment.unix(Math.floor(date1.getTime() / 1000)).format('YYYY-MM-DD'),
            dateTo: moment.unix(Math.floor(date2.getTime() / 1000)).format('YYYY-MM-DD')
        };
    }


    get = () => {
        this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));

        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0, 'query-group': null, group: this.state.group != '' ? this.state.group : null, dateFrom: this.state.dateFrom, dateTo: this.state.dateTo })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

        for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
            this.props.loadDataWithQuery[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0, 'query-group': null, group: this.state.group != '' ? this.state.group : null, dateFrom: this.state.dateFrom, dateTo: this.state.dateTo })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

    }

    componentDidMount() {
        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }

        this.get();

    }

    componentDidUpdate(prevProps) {
        if (prevProps[0].location.search != this.props[0].location.search) {
            this.setState({
                loading: true
            }, () => {
                this.get();

            })
        }
    }


    updateSort = (field, type) => {
        if (this.state.useState) {
            let state = this.state.params;
            state.sortField = field;
            state.sortType = type;
            this.setState({
                params: state
            })
        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0 }, [{ name: 'sortField', value: field }, { name: 'sortType', value: type }], false));

        }
    }

    updateParams = (name, value, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };
                obj[name] = value;
                this.setState({ params: obj });
            } else {
                let obj = this.state.params;
                obj[name] = value;
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(generateSearchLink(this.props[0].location.search, { entries: 10, page: 0 }, name, value, restart));
        }
    }

    updateMultipleParams = (fields, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj });

            } else {
                let obj = this.state.params;
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0 }, fields, restart));
        }
    }

    findClinicGroup = () => {

        this.setState({ loadGroups: true }, () => {
            fetch(API_ENDPOINT + '/data/group-statistics/search', {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify({ searchGroups: this.state.searchGroups })
            }).then(res => res.json()).then((result) => {
                console.log(result)
                if (result && result.length == 1) {
                    if (result[0]._id) {
                        this.setState({ listOfGroups: result, loadGroups: false, group: result[0]._id }, () => this.get())

                    } else {
                        this.setState({ loadGroups: false, group: "" }, () => this.get())

                    }
                } else if (result && result.length > 1) {
                    this.setState({ listOfGroups: result, loadGroups: false })
                }
            })
        })
    }


    render() {
        let params = {};
        if (this.state.useState) {
            params = this.state.params;
        } else {
            params = getSearchParams(this.props[0].location.search, { entries: 10, page: 0 })
        }



        // console.log(this.state.searchGroups, "pretraga ")
        // console.log( this.state.listOfGroups, "lista grupa")
        return (
            <div className={this.props.autoHideSidebar ? "dashboard dashboard-auto-hide" : "dashboard"}>

                <Container fluid>
                    <Row>
                        <Col lg="12">
                            <div className="panel">
                                <div className="panel-header">
                                    <h5 className="component-header">{this.props.linksMeta && this.props.linksMeta[this.props[0].location.pathname] ? this.props.linksMeta[this.props[0].location.pathname][this.props.lang] : ''} </h5>
                                    <button className="btn add-button" onClick={() => this.export(this.state.clinicGroupID, this.state.dateFrom, this.state.dateTo)}><Isvg src={exportIcon} /> {'Export'.translate(this.props.lang)}</button>
                                </div>
                                <Row>
                                    <Col lg="12">

                                        <FormGroup>
                                            <Label>{'Clinic group'.translate(this.props.lang)}</Label>
                                            <div onClick={() => this.setState({ openDrop: true })} style={{ position: 'relative' }}>
                                                <Input type='text' value={this.state.searchGroups} onChange={(e) => {
                                                    if (e.target.value) {
                                                        this.setState({ searchGroups: e.target.value }, () => this.findClinicGroup())
                                                    } else {
                                                        this.setState({ searchGroups: '', listOfGroups: null }, () => { this.findClinicGroup() })
                                                    }
                                                }} />
                                                {
                                                    this.state.searchGroups ?
                                                        <div onClick={() => this.setState({ searchGroups: '', listOfGroups: null }, () => this.findClinicGroup(true))} style={{ position: 'absolute', fontSize: 20, top: 10, right: 10, cursor: 'pointer' }}>&times;</div>
                                                        :
                                                        null
                                                }
                                            </div>

                                        </FormGroup>
                                        {
                                            this.state.listOfGroups && this.state.listOfGroups.length && this.state.searchGroups != '' && this.state.openDrop ?
                                                <div className="groups-serach-results" >
                                                    {
                                                        this.state.listOfGroups.map((item, idx) => {
                                                            return (
                                                                <div className='groups-item' onClick={
                                                                    () => {
                                                                        this.setState({
                                                                            searchGroups: item.name,
                                                                            group: item._id,
                                                                            clinicGroupID: item._id,
                                                                            openDrop: false
                                                                        }, () => { this.get(), this.findClinicGroup() })
                                                                    }
                                                                }>
                                                                    <p>{item.name}</p>

                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                                :
                                                null

                                        }

                                    </Col>

                                    <Col lg="6">
                                        <FormGroup>
                                            <DatePicker
                                                label={'Date from'.translate(this.props.lang)}
                                                value={this.state.dateFrom}
                                                onChange={(e) => {
                                                    if (this.state.dateTo < e) {
                                                        this.setState({
                                                            error: 'Date start can not be greater than the end date'
                                                        })
                                                    } else {
                                                        this.setState({ dateFrom: e }, () => this.get())
                                                    }
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col lg="6">
                                        <FormGroup>
                                            <DatePicker
                                                label={'Date to'.translate(this.props.lang)}
                                                value={this.state.dateTo}
                                                onChange={(e) => {
                                                    if (this.state.dateFrom > e) {
                                                        this.setState({
                                                            error: 'Date end can not be greater than the start date'
                                                        })
                                                    } else {
                                                        this.setState({ dateTo: e }, () => this.get())
                                                    }

                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <ListBuilder
                                    lang={this.props.lang}
                                    loading={this.state.loading}
                                    total={this.state.total}
                                    showNumeration={false}
                                    fields={[
                                        { type: 'text', name: 'groupName', label: 'Clinic'.translate(this.props.lang), allowSort: true },
                                        { type: 'text', name: 'clinicName', label: 'Department'.translate(this.props.lang), allowSort: true },
                                        { type: 'text', name: 'name', label: 'Reason'.translate(this.props.lang), allowSort: true },
                                        { type: 'text', name: 'timestamp', label: 'Date'.translate(this.props.lang), allowSort: true },


                                    ]}
                                    items={this.state.items.map((item) => {
                                        return {
                                            ...item,
                                            timestamp: this.props.getStringDateTs(item.timestamp, this.props.dateFormat)/*moment.unix(item.timestamp).format(this.props.dateFormat)*/
                                        }
                                    })}

                                    params={params}
                                    sortField={params.sortField}
                                    sortType={params.sortType}
                                    updateSort={this.updateSort}
                                    updateParams={this.updateParams}
                                    updateMultipleParams={this.updateMultipleParams}
                                ></ListBuilder>
                                <DeleteModal
                                    lang={this.props.lang}
                                    isOpen={this.state.deleteModal}
                                    toggle={() => this.setState({ deleteModal: null })}
                                    handler={() => { this.delete(this.state.deleteModal._id); this.setState({ deleteModal: null }) }}
                                >
                                    {'Delete group permission'.translate(this.props.lang)} <strong>{this.state.deleteModal && this.state.deleteModal.name}</strong>?
                                </DeleteModal>

                                {this.state.error ?

                                    <ErrorModal lang={this.props.lang}
                                        isOpen={this.state.error}
                                        toggle={() => this.setState({ error: null })}

                                    >
                                        {this.state.error.translate(this.props.lang)}
                                    </ErrorModal>

                                    :
                                    null
                                }

                            </div>
                        </Col>
                    </Row>

                </Container>

            </div>
        );
    }
}

const selector = formValueSelector('modalForm');

export default connect(state => {
    return { category: selector(state, 'category') }

}, {

})(Page(Statistics));