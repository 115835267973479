import React, { Component } from 'react';

import {
    Container,
    Row,
    Col,
    FormGroup, Label, Input,
    Button,
    Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';



import ListBuilder from './listBuilder';
import { getSearchParams, generateSearchLink, generateSearchLinkMultiple } from '../helpers/linkHelper';
import { API_ENDPOINT, GOOGLE_API_KEY } from '../constants';
import moment from 'moment';
import eRemissIcon from '../assets/svg/e.svg';
import import2 from '../assets/svg/import2.svg';

import Isvg from 'react-inlinesvg';


function parseJSON(response) {
    return response.json().then(json => {
        return {
            result: json,
            status: response.status
        }
    })
}


function getDistance(a, b, c, d,) {
    return getDst([a, b], [c, d]);
}

function rad(x) {
    return x * Math.PI / 180;
}

function getDst(p1, p2) {
    var R = 6378137; // Earth’s mean radius in meter
    var dLat = rad(p2[0] - p1[0]);
    var dLong = rad(p2[1] - p1[1]);
    var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(rad(p1[0])) * Math.cos(rad(p2[0])) *
        Math.sin(dLong / 2) * Math.sin(dLong / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;
    return (d / 1000).toFixed(1); // returns the distance in km
}

const loadDataWithQuery = [

    (fetchFunction, lang, match, query) => {

        let queryFields = {};
        for (var key in query) {
            if (key && key.indexOf('query-') === 0) {
                queryFields[key.replace('query-', '')] = query[key];
            }
        }
    
        return fetchFunction(API_ENDPOINT + '/data/clinics-support', {
            method: 'POST',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
            body: JSON.stringify({
                page: query.page,
                entries: query.entries,
                filter: query.filter,
                sortType: query.sortType,
                sortField: query.sortField,
                query: queryFields,
                group: query.group,
                groupId: query.groupId
            })
        }).then(parseJSON).then(({ result, status }) => {
            if (status >= 200 && status < 300)
                return {
                    page: query.page ? query.page : 0,
                    items: result.items,
                    total: result.total,
                }
        })

    },

    (fetchFunction, lang, match, query) => {

        let queryFields = {};
        for (var key in query) {
            if (key && key.indexOf('query-') === 0) {
                queryFields[key.replace('query-', '')] = query[key];
            }
        }

        return fetchFunction(API_ENDPOINT + '/data/clinic-groups/all', {
            method: 'POST',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
        }).then(parseJSON).then(({ result, status }) => {
            if (status >= 200 && status < 300)
                return {
                    groups: result
                }
        })

    }

]

/**
* Import referral
* @author   Milan Stanojevic
*/
class updateAssignedClinicGroup extends Component {

    constructor(props) {
        super(props);

        this.state = {
            regions: [],
            cities: [],
            groups: [],
            subgroups: [],
            ...props.initialData,
            useState: true,
            params: {
                entries: 10,
                page: 0,
                // 'query-group': null
            },
            items: [],
            total: 0,
            loading: true,
            suggestions: [],

        };
    }


    get = () => {
        let params = this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0, /*'query-group': null*/ });
        this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
        params._ts = new Date().getTime();

        this.setState({
            _ts: params._ts
        }, () => {
            for (let i = 0; i < loadDataWithQuery.length; i++) {
                loadDataWithQuery[i](window.fetch, this.props.lang, this.props[0].match, params).then((data) => {
                    if (data && data._clinicSearchTs && this.state._ts == data._clinicSearchTs || (data && !data._clinicSearchTs)) {

                        this.setState({
                            ...data,
                            loading: null
                        })
                    }
                })
            }

        })


    }

    componentDidMount() {
        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }

        this.get();


    }

    componentWillUnmount() {

    }



    componentDidUpdate(prevProps, prevState) {
        if (prevProps[0].location.search != this.props[0].location.search) {
            this.setState({
                loading: true
            }, () => {
                this.get();

            })

        }

        if (this.state.useState && prevState._useStateTs != this.state._useStateTs) {
            this.setState({
                loading: true
            }, () => {
                this.get();

            })

        }


    }


    updateSort = (field, type) => {
        if (this.state.useState) {
            let state = this.state.params;
            state.sortField = field == 'waitingTime' ? 'waitingMin' : field;
            state.sortType = type;
            this.setState({
                params: state
            })

            this.setState({
                _useStateTs: new Date().getTime()
            })

        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0 }, [{ name: 'sortField', value: field == 'waitingTime' ? 'waitingMin' : field }, { name: 'sortType', value: type }], false));

        }
    }

    updateParams = (name, value, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };

                obj[name] = value;

                this.setState({ params: obj });
            } else {
                let obj = this.state.params;
                obj[name] = value;


                this.setState({ params: obj })
            }

            this.setState({
                _useStateTs: new Date().getTime()
            })
        } else {
            this.props[0].history.push(generateSearchLink(this.props[0].location.search, { entries: 10, page: 0 }, name, value, restart));
        }
    }

    updateMultipleParams = (fields, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj });

            } else {
                let obj = this.state.params;
                for (let i = 0; i < fields.length; i++) {

                    obj[fields[i].name] = fields[i].value;

                }
                this.setState({ params: obj })
            }

            this.setState({
                _useStateTs: new Date().getTime()
            })

        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0 }, fields, restart));
        }
    }


    updateTicketFromSite = (data) => {

        fetch(API_ENDPOINT + '/ticket-from-site-update/' + this.props.assignedClinicModal, {
            method: 'POST',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
            body: JSON.stringify({clinic: data})
        }).then(res => res.json()).then((result) => {
            if(result){
                this.props.close(result)

            }
        })
    }

    render() {
        let params = {};
        if (this.state.useState) {
            params = this.state.params;
        } else {
            params = getSearchParams(this.props[0].location.search, { entries: 10, page: 0 })
        }

        return (
            <div>

                <Row>
                    <Col lg="6">
                        <FormGroup>
                            <Label>{'Clinic group'.translate(this.props.lang)}</Label>
                            <Input type="select" value={params['group']} onChange={(e) => this.updateParams('group', e.target.value, true)}>
                                {
                                    [{ _id: null, name: 'Select group'.translate(this.props.lang) }, ...this.state.groups].map((item, idx) => {
                                        return (
                                            <option value={item._id}>{item.name}</option>
                                        )
                                    })
                                }
                            </Input>
                        </FormGroup>
                    </Col>
                    <Col lg="12">
                        <ListBuilder
                            lang={this.props.lang}
                            loading={this.state.loading}
                            total={this.state.total}
                            showNumeration={false}
                            fields={[
                                { type: 'text', name: 'name', label: 'Clinic name'.translate(this.props.lang), allowSort: true },
                            ]}
                            items={this.state.items}
                            actions={
                                [
                                    {
                                        component: <div className="tabel-action-tooltip"><Isvg src={import2} /><div key={this.props.lang} className="chat-icons-tooltip">{'Assign'.translate(this.props.lang)}</div></div>,
                                        onClick: (item) => this.updateTicketFromSite(item._id)
                                    },
                                ]
                            }
                            params={params}
                            sortField={params.sortField}
                            sortType={params.sortType}
                            updateSort={this.updateSort}
                            updateParams={this.updateParams}
                            updateMultipleParams={this.updateMultipleParams}

                        ></ListBuilder>

                    </Col>
                </Row>



            </div >
        );


    }
}
export default updateAssignedClinicGroup;