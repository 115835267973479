import React, { Component } from 'react';
import Page from '../../containers/page';

import {
    Container,
    Row,
    Col,
    FormGroup, Label, Input, Button, UncontrolledAlert, Alert, FormFeedback, Modal, ModalBody, ModalFooter, ModalHeader

} from 'reactstrap';

import { Rnd } from 'react-rnd';

import Isvg from "react-inlinesvg";
import { formValueSelector, change } from 'redux-form';
import { connect } from 'react-redux';

import FormBuilder from '../../components/forms/formBuilder';
import { email, required } from '../../components/forms/validation';

import ListBuilder from '../../components/listBuilder';
import DeleteModal from '../../components/deleteModal';
import { getSearchParams, generateSearchLink, generateSearchLinkMultiple } from '../../helpers/linkHelper';
import { API_ENDPOINT } from '../../constants';
import ErrorModal from '../../components/errorModal';
import { Link } from 'react-router-dom';

import { Player, Controls } from "@lottiefiles/react-lottie-player";

import Search from "../../components/search";
import profileUser from "../../assets/images/user.png";
import notification from "../../assets/svg/notification.svg";
import link from "../../assets/svg/link-new-tab.svg";

import Iframe from 'react-iframe';
import Checkbox from '../../components/forms/fields/checkbox';

import infoIcon from "../../assets/svg/link-new-tab.svg";

import addIcon from '../../assets/svg/add-icon.svg';
import garbageIcon from '../../assets/svg/garbage.svg';

import warrning from '../../assets/svg/warrning.svg';
import { v4 as uuidv4 } from 'uuid';

function parseJSON(response) {
    return response.json().then(json => {
        return {
            result: json,
            status: response.status
        }
    })
}


/**
* Group settings page
* @author   Milan Stanojevic
*/
class ClinicData extends Component {
    constructor(props) {
        super(props);
        this.player = React.createRef();
        this.player2 = React.createRef();
        this.state = {
            showPlayer: false,
            showPlayer2: false,
            ...props.initialData,
            form: false,
            useState: false,
            backgroundColors: ['#F63030', '#568AC8', '#60BEBB', '#F9EF68', '#F2BE57', '#F257E8', '#AF57F2', '#5CF257', '#73C931', '#6BF0D5'],
            params: {
                entries: 10,
                page: 0,
                'query-group': null
            },
            items: [],
            itemsScale: [],
            diaryGraphTypes: [],
            initialItems: [],
            initialScale: [],
            initialGraphTypes: [],
            loading: false,



        };
    }

    showPlayer = () => {
        this.setState({
            showPlayer: true,
        });
    };

    hidePlayer = () => {
        this.setState({
            showPlayer: false,
        });
    };

    get = () => {
        this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));

        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0, 'query-group': null })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

        for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
            this.props.loadDataWithQuery[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0, 'query-group': null })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

        let selectedGroupIdx = 0;
        if (this.props.selectedGroup && this.props.uData?.clinicGroups?.length) {
            for (let i = 0; i < this.props.uData.clinicGroups.length; i++) {
                if (this.props.uData.clinicGroups[i]._id == this.props.selectedGroup) {
                    selectedGroupIdx = i;
                    break;
                }
            }
        }
        if (this.props.uData && this.props.uData.clinicGroups && this.props.uData.clinicGroups[selectedGroupIdx] && this.props.uData.clinicGroups[selectedGroupIdx].daybookEvents && this.props.uData.clinicGroups[selectedGroupIdx].daybookEvents.length) {
            this.setState({ items: this.props.uData.clinicGroups[selectedGroupIdx].daybookEvents }, () => {
                this.setState({ initialItems: JSON.parse(JSON.stringify(this.state.items)) })
            })
        } else {
            this.setState({ items: [] }, () => {
                this.setState({ initialItems: JSON.parse(JSON.stringify(this.state.items)) })
            })
        }
        if (this.props.uData && this.props.uData.clinicGroups && this.props.uData.clinicGroups[selectedGroupIdx] && this.props.uData.clinicGroups[selectedGroupIdx].daybookScale && this.props.uData.clinicGroups[selectedGroupIdx].daybookScale.length) {
            this.setState({ itemsScale: this.props.uData.clinicGroups[selectedGroupIdx].daybookScale }, () => {
                this.setState({ initialScale: JSON.parse(JSON.stringify(this.state.itemsScale)) })
            })
        } else {
            this.setState({ itemsScale: [] }, () => {
                this.setState({ initialScale: JSON.parse(JSON.stringify(this.state.itemsScale)) })
            })
        }
        if (this.props.uData && this.props.uData.clinicGroups && this.props.uData.clinicGroups[selectedGroupIdx] && this.props.uData.clinicGroups[selectedGroupIdx].diaryGraphTypes && this.props.uData.clinicGroups[selectedGroupIdx].diaryGraphTypes.length) {
            this.setState({ diaryGraphTypes: this.props.uData.clinicGroups[selectedGroupIdx].diaryGraphTypes }, () => {
                this.setState({ initialGraphTypes: JSON.parse(JSON.stringify(this.state.diaryGraphTypes)) })
            })
        } else {
            this.setState({ diaryGraphTypes: [] }, () => {
                this.setState({ initialGraphTypes: JSON.parse(JSON.stringify(this.state.diaryGraphTypes)) })
            })
        }





    }

    componentDidMount() {
        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }

        this.props.abortAction()

        this.props.verifyUser(() => this.get())

        this.setState({ showForm: true })



    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps[0].location.search != this.props[0].location.search) {
            this.setState({
                loading: true
            }, () => {
                this.get();

            })
        }

        if (prevProps.selectedGroup != this.props.selectedGroup) {

            this.setState({
                loading: true,

            }, () => {
                this.get();

            })

        }

        if (prevProps.uData != this.props.uData) {
            let selectedGroupIdx = -1;
            if (this.props.selectedGroup && this.props.uData?.clinicGroups?.length) {
                for (let i = 0; i < this.props.uData.clinicGroups.length; i++) {
                    if (this.props.uData.clinicGroups[i]._id == this.props.selectedGroup) {
                        selectedGroupIdx = i;
                        break;
                    }
                }
            }
            let redirect = false;

            if (!this.props.uData || (this.props.uData && !this.props.uData.clinicGroups) || (this.props.uData && this.props.uData.clinicGroups && !this.props.uData.clinicGroups[selectedGroupIdx])) {
                redirect = true;
            } else if (this.props.uData && this.props.uData.clinicGroups && this.props.uData.clinicGroups[selectedGroupIdx] && !this.props.uData.clinicGroups[selectedGroupIdx].enterprise) {
                redirect = true;
            }
            if (redirect == true) {
                this.props[0].history.push('/')
            }
        }


        ///check if changed
        let checkItems = this.state.items && this.state.items.map(item => {
            let obj = {
                color: item.color,
                name: item.name
            }
            return obj
        });
        let checkItemsScale = this.state.itemsScale && this.state.itemsScale.map(item => {
            let obj = {
                itemNumber: item.itemNumber,
                name: item.name
            }
            return obj
        });
        let checkDiaryGraphTypes = this.state.diaryGraphTypes && this.state.diaryGraphTypes.map(item => {
            let obj = {
                id: item.id,
                name: item.name
            }
            return obj
        });
        if (JSON.stringify(checkItems) != JSON.stringify(this.state.initialItems)) {
            if (!this.state.detectedChangedItems)
                this.setState({ detectedChangedItems: true })
        } else {
            if (this.state.detectedChangedItems)
                this.setState({ detectedChangedItems: false })
        }
        if (JSON.stringify(checkItemsScale) != JSON.stringify(this.state.initialScale)) {
            if (!this.state.detectedChangedScale)
                this.setState({ detectedChangedScale: true })
        } else {
            if (this.state.detectedChangedScale)
                this.setState({ detectedChangedScale: false })
        }

        if (JSON.stringify(checkDiaryGraphTypes) != JSON.stringify(this.state.initialGraphTypes)) {
            if (!this.state.detectedChangedGraphTypes)
                this.setState({ detectedChangedGraphTypes: true })
        } else {
            if (this.state.detectedChangedGraphTypes)
                this.setState({ detectedChangedGraphTypes: false })
        }
        if ((this.state.detectedChangedItems || this.state.detectedChangedScale || this.state.detectedChangedGraphTypes) && !this.props.groupSettingsModal) {
            this.props.groupSettingsChanged()
        } else if (!(this.state.detectedChangedItems || this.state.detectedChangedScale || this.state.detectedChangedGraphTypes) && this.props.groupSettingsModal) {
            this.props.abortAction()
        }

    }


    updateSort = (field, type) => {
        if (this.state.useState) {
            let state = this.state.params;
            state.sortField = field;
            state.sortType = type;
            this.setState({
                params: state
            })
        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0 }, [{ name: 'sortField', value: field }, { name: 'sortType', value: type }], false));

        }
    }

    updateParams = (name, value, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };
                obj[name] = value;
                this.setState({ params: obj });
            } else {
                let obj = this.state.params;
                obj[name] = value;
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(generateSearchLink(this.props[0].location.search, { entries: 10, page: 0 }, name, value, restart));
        }
    }
    updateMultipleParams = (fields, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj });

            } else {
                let obj = this.state.params;
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0 }, fields, restart));
        }
    }

    stopAnimation = () => {
        this.player.current.play();
    };





    update = (redirect) => {
        let data = {};

        if (this.state.items?.length || this.state.itemsScale?.length || this.state.diaryGraphTypes?.length) {
            let items = this.state.items;
            let itemsScale = this.state.itemsScale;
            let diaryGraphTypes = this.state.diaryGraphTypes;
            let error = false;
            for (let i = 0; i < items.length; i++) {
                if (!items[i].name) {
                    error = true;
                    items[i].error = 'Required';
                } else {
                    items[i].error = null;
                }
            }
            if (this.state.itemsScale && this.state.itemsScale.length) {

                for (let i = 0; i < itemsScale.length; i++) {
                    if (!itemsScale[i].name) {
                        error = true;
                        itemsScale[i].error = 'Required';
                    } else {
                        itemsScale[i].error = null;
                    }
                }
            }
            if (this.state.diaryGraphTypes && this.state.diaryGraphTypes.length) {

                for (let i = 0; i < diaryGraphTypes.length; i++) {
                    if (!diaryGraphTypes[i].name) {
                        error = true;
                        diaryGraphTypes[i].error = 'Required';
                    } else {
                        diaryGraphTypes[i].error = null;
                    }
                }
            }


            this.setState({ items, itemsScale, diaryGraphTypes }, () => {
                if (error) {
                    return;
                }

                data.items = this.state.items.map(item => {
                    let obj = {
                        color: item.color,
                        name: item.name
                    }
                    return obj;
                });

                data.itemsScale = this.state.itemsScale && this.state.itemsScale.length ? this.state.itemsScale.map(item => {
                    let obj = {
                        itemNumber: item.itemNumber,
                        name: item.name
                    }
                    return obj;
                }) : [];

                data.diaryGraphTypes = this.state.diaryGraphTypes && this.state.diaryGraphTypes.length ? this.state.diaryGraphTypes.map(item => {
                    let obj = {
                        id: item.id,
                        name: item.name
                    }
                    return obj;
                }) : [];

                this.setState(
                    {
                        showPlayer: true,
                    },
                    () => {
                        data.groupId = this.props.selectedGroup;
                        data.clinic = this.props.selectedClinic;
                        fetch(API_ENDPOINT + '/users/groupSettings/daybookEvents', {
                            method: 'POST',
                            headers: {
                                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                                'content-type': 'application/json'
                            },
                            body: JSON.stringify(data)
                        }).then(parseJSON).then(({ result, status }) => {
                            if (result.error) {
                                this.setState({ error: result.error })
                            } else {
                                this.setState({
                                    message: result.message,
                                    showPlayer: false,
                                    showPlayer2: true,
                                },
                                    () => {
                                        setTimeout(() => {
                                            this.setState({ showPlayer2: false });
                                        }, 1000);
                                    })

                                this.props.verifyUser(() => this.get())
                                if (redirect) {
                                    this.props[0].history.push(this.props.changeLinkModal)
                                }
                                // this.props.verifyUser(() => { }, () => {
                                //     this.props.changeGroup(data.groupId)
                                // });
                            }

                        })
                    }
                )
            })

        } else {
            data.items = [];
            data.itemsScale = []
            data.diaryGraphTypes = []
            this.setState(
                {
                    showPlayer: true,
                },
                () => {
                    data.groupId = this.props.selectedGroup;
                    data.clinic = this.props.selectedClinic;
                    fetch(API_ENDPOINT + '/users/groupSettings/daybookEvents', {
                        method: 'POST',
                        headers: {
                            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                            'content-type': 'application/json'
                        },
                        body: JSON.stringify(data)
                    }).then(parseJSON).then(({ result, status }) => {
                        if (result.error) {
                            this.setState({ error: result.error })
                        } else {
                            this.setState({
                                message: result.message,
                                showPlayer: false,
                                showPlayer2: true,
                            },
                                () => {
                                    setTimeout(() => {
                                        this.setState({ showPlayer2: false });
                                    }, 1000);
                                })
                            this.props.verifyUser()
                            // this.props.verifyUser(() => { }, () => {
                            //     this.props.changeGroup(data.groupId)
                            // });
                        }

                    })
                }
            )
        }



    }


    render() {
        let params = {};
        if (this.state.useState) {
            params = this.state.params;
        } else {
            params = getSearchParams(this.props[0].location.search, { entries: 10, page: 0 })
        }

        let selectedGroupIdx = 0;
        if (this.props.selectedGroup && this.props.uData?.clinicGroups?.length) {
            for (let i = 0; i < this.props.uData.clinicGroups.length; i++) {
                if (this.props.uData.clinicGroups[i]._id == this.props.selectedGroup) {
                    selectedGroupIdx = i;
                    break;
                }
            }
        }
        if (!this.props.uData || (this.props.uData && !this.props.uData.clinicGroups) || (this.props.uData && this.props.uData.clinicGroups && !this.props.uData.clinicGroups[selectedGroupIdx])) {
            return null
        } else if (this.props.uData && this.props.uData.clinicGroups && this.props.uData.clinicGroups[selectedGroupIdx] && !this.props.uData.clinicGroups[selectedGroupIdx].enterprise) {
            return null;
        }
        return (
            <div className={this.props.autoHideSidebar ? "dashboard dashboard-auto-hide" : "dashboard"}>

                <Container fluid>

                    <Row>
                        <Col lg="12">

                            <div className="panel">

                                <Row>
                                    <Col lg="12">
                                        <h5 className="component-header mb-35">{this.props.linksMeta && this.props.linksMeta[this.props[0].location.pathname] ? this.props.linksMeta[this.props[0].location.pathname][this.props.lang] : ''}</h5>


                                    </Col>

                                    <Col lg="4">
                                        <div className='daybook-events-wrap'>
                                            <div className='daybook-events-title'>{'Daybook events'.translate(this.props.lang)}</div>
                                            {/* <div className='daybook-events-title'>{'Enter patient event'.translate(this.props.lang)}</div> */}
                                            <div className="daybook-events">
                                                {
                                                    this.state.items && this.state.items.map((item, idx) => {
                                                        return (
                                                            <div>
                                                                <div className="daybook-events-item">
                                                                    <div className="daybook-events-item-color" style={{ backgroundColor: item.color ? item.color : 'transparent' }}></div>
                                                                    <div className="daybook-events-item-name">
                                                                        <Input type="text" value={item.name} onChange={(e) => {
                                                                            let items = this.state.items;
                                                                            if (items && items[idx]) {
                                                                                items[idx].name = e.target.value;
                                                                                items[idx].error = null;
                                                                                this.setState({ items })
                                                                            }
                                                                        }} />
                                                                    </div>
                                                                    <div className="daybook-events-item-delete-button" onClick={() => {
                                                                        let items = this.state.items;
                                                                        if (items && items[idx]) {
                                                                            items.splice(idx, 1)
                                                                            this.setState({ items })
                                                                        }
                                                                    }}>
                                                                        <Isvg src={garbageIcon} />
                                                                        <div className='daybook-events-item-delete-button-tooltip'>{'Delete'.translate(this.props.lang)}</div>
                                                                    </div>

                                                                </div>
                                                                {item.error ? <FormFeedback >{item.error.translate(this.props.lang)}</FormFeedback> : null}
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                            {
                                                this.state.items && this.state.items.length < 10 ?
                                                    <div className="add-daybook-events-item" onClick={() => {
                                                        let items = this.state.items;
                                                        if (items && items.length < 10) {
                                                            let color;
                                                            if (this.state.backgroundColors && this.state.backgroundColors.length) {
                                                                if (this.state.backgroundColors[items.length] && items.filter(item => item.color == this.state.backgroundColors[items.length]).length == 0) {
                                                                    color = this.state.backgroundColors[items.length];
                                                                } else {
                                                                    for (let i = 0; i < this.state.backgroundColors.length; i++) {
                                                                        if (items.filter(item => item.color == this.state.backgroundColors[i]).length == 0) {
                                                                            color = this.state.backgroundColors[i];
                                                                            break;
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                            items.push({
                                                                color: color ? color : 'transparent',
                                                                name: ''
                                                            })
                                                            this.setState({ items })
                                                        }
                                                    }}>
                                                        <Isvg src={addIcon} />
                                                        <h6>{'Add more'.translate(this.props.lang)}</h6>
                                                    </div>
                                                    :
                                                    null
                                            }
                                        </div>
                                    </Col>
                                    <Col lg="4">
                                        <div className='daybook-events-wrap'>
                                            <div className='daybook-events-title'>{'Scale 0-10 types'.translate(this.props.lang)}</div>
                                            <div className="daybook-events">
                                                {
                                                    this.state.itemsScale && this.state.itemsScale.map((item, idx) => {
                                                        return (
                                                            <div>
                                                                {/* {item.itemNumber} */}
                                                                <div className="daybook-events-item">
                                                                    {/* <div className="daybook-events-item-color" style={{ backgroundColor: 'transparent', border: 'none' }}>{item.itemNumber}</div> */}
                                                                    <div className="daybook-events-item-name-scale">
                                                                        <Input type="text" value={item.name} onChange={(e) => {
                                                                            let itemsScale = this.state.itemsScale;
                                                                            if (itemsScale && itemsScale[idx]) {
                                                                                itemsScale[idx].name = e.target.value;
                                                                                itemsScale[idx].error = null;
                                                                                this.setState({ itemsScale })
                                                                            }
                                                                        }} />
                                                                    </div>
                                                                    <div className="daybook-events-item-delete-button" onClick={() => {
                                                                        let itemsScale = this.state.itemsScale;
                                                                        if (itemsScale && itemsScale[idx]) {
                                                                            itemsScale.splice(idx, 1)
                                                                            this.setState({ itemsScale })
                                                                        }
                                                                    }}>
                                                                        <Isvg src={garbageIcon} />
                                                                        <div className='daybook-events-item-delete-button-tooltip'>{'Delete'.translate(this.props.lang)}</div>

                                                                    </div>

                                                                </div>
                                                                {item.error ? <FormFeedback >{item.error.translate(this.props.lang)}</FormFeedback> : null}
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                            {
                                                // this.state.items && this.state.items.length < 10 ?
                                                <div className="add-daybook-events-item" onClick={() => {
                                                    let itemsScale = this.state.itemsScale;
                                                    if (itemsScale && itemsScale.length) {
                                                        let idx = itemsScale.length + 1
                                                        // let color;
                                                        // if (this.state.backgroundColors && this.state.backgroundColors.length) {
                                                        //     if (this.state.backgroundColors[itemsScale.length] && itemsScale.filter(item => item.color == this.state.backgroundColors[itemsScale.length]).length == 0) {
                                                        //         color = this.state.backgroundColors[itemsScale.length];
                                                        //     } else {
                                                        //         for (let i = 0; i < this.state.backgroundColors.length; i++) {
                                                        //             if (itemsScale.filter(item => item.color == this.state.backgroundColors[i]).length == 0) {
                                                        //                 color = this.state.backgroundColors[i];
                                                        //                 break;
                                                        //             }
                                                        //         }
                                                        //     }
                                                        // }
                                                        itemsScale.push({
                                                            // color: color ? color : 'transparent',
                                                            itemNumber: idx,
                                                            name: ''
                                                        })
                                                        this.setState({ itemsScale })
                                                    } else {
                                                        itemsScale.push({
                                                            itemNumber: 1,
                                                            name: ''
                                                        })
                                                        this.setState({ itemsScale })
                                                    }
                                                }}>
                                                    <Isvg src={addIcon} />
                                                    <h6>{'Add more'.translate(this.props.lang)}</h6>
                                                </div>
                                                // :
                                                // null
                                            }
                                        </div>
                                    </Col>

                                    <Col lg="4">
                                        <div className='daybook-events-wrap'>
                                            <div className='daybook-events-title'>{'Diary graph types'.translate(this.props.lang)}</div>
                                            <div className="daybook-events">
                                                {
                                                    this.state.diaryGraphTypes && this.state.diaryGraphTypes.map((item, idx) => {
                                                        return (
                                                            <div>
                                                                <div className="daybook-events-item">
                                                                    <div className="daybook-events-item-name-scale">
                                                                        <Input type="text" value={item.name} onChange={(e) => {
                                                                            let diaryGraphTypes = this.state.diaryGraphTypes;
                                                                            let index = diaryGraphTypes.findIndex(el => el.id === item.id);
                                                                            if (diaryGraphTypes && diaryGraphTypes[index]) {
                                                                                diaryGraphTypes[index].name = e.target.value;
                                                                                diaryGraphTypes[index].error = null;
                                                                                this.setState({ diaryGraphTypes })
                                                                            }
                                                                        }} />
                                                                    </div>
                                                                    <div className="daybook-events-item-delete-button" onClick={() => {
                                                                        let diaryGraphTypes = this.state.diaryGraphTypes;
                                                                        let index = diaryGraphTypes.findIndex(el => el.id === item.id);
                                                                        if (index > -1) {
                                                                            diaryGraphTypes.splice(index, 1)
                                                                        }
                                                                        this.setState({ diaryGraphTypes })
                                                                    }}>
                                                                        <Isvg src={garbageIcon} />
                                                                        <div className='daybook-events-item-delete-button-tooltip'>{'Delete'.translate(this.props.lang)}</div>

                                                                    </div>

                                                                </div>
                                                                {item.error ? <FormFeedback >{item.error.translate(this.props.lang)}</FormFeedback> : null}
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                            {
                                                <div className="add-daybook-events-item" onClick={() => {
                                                    let diaryGraphTypes = this.state.diaryGraphTypes;
                                                    diaryGraphTypes.push({
                                                        id: uuidv4(),
                                                        name: ''
                                                    })
                                                    this.setState({ diaryGraphTypes })
                                                }}>
                                                    <Isvg src={addIcon} />
                                                    <h6>{'Add more'.translate(this.props.lang)}</h6>
                                                </div>

                                            }
                                        </div>
                                    </Col>
                                    <Col lg='12' style={{ marginTop: 50 }}>
                                        <Button color="primary" onClick={() => this.update()}>
                                            {
                                                this.state.showPlayer ? (
                                                    <Player
                                                        onEvent={(event) => {
                                                            if (event === "load") this.stopAnimation();
                                                        }}
                                                        onStateChange={this.toggleVisibility}
                                                        ref={this.player} // set the ref to your class instance
                                                        autoplay={true}
                                                        loop={true}
                                                        controls={true}
                                                        src="https://assets8.lottiefiles.com/packages/lf20_ZSRKCF.json"
                                                        style={{ height: "30px", width: "30px" }}
                                                    ></Player>

                                                ) : this.state.showPlayer2 ? (
                                                    <div style={{ display: 'flex', alignItems: 'center' }}><Player
                                                        onStateChange={this.toggleVisibility}
                                                        ref={this.player2} // set the ref to your class instance
                                                        autoplay={true}
                                                        loop={true}
                                                        controls={true}
                                                        src="https://assets10.lottiefiles.com/packages/lf20_CxyxLk.json"
                                                        style={{ height: "30px", width: "30px" }}
                                                    ></Player>
                                                        {'Saved'.translate(this.props.lang)}
                                                    </div>
                                                ) : (
                                                    "Save".translate(this.props.lang)
                                                )
                                            }

                                        </Button>
                                    </Col>

                                </Row>


                            </div>

                        </Col>
                    </Row>

                </Container>
                {this.props.changeLinkModal ?
                    <Modal isOpen={this.props.changeLinkModal} centered>
                        {/* <ModalHeader style={{ margin: 'auto' }}>{'Warning'.translate(this.props.lang)}</ModalHeader> */}
                        <ModalBody className="delete-modal">
                            {/* <img src={warrning} /> */}
                            <p className="message">{'Data is not saved, if you click Yes it will be deleted'.translate(this.props.lang)}</p>
                        </ModalBody>
                        <ModalFooter className='buttons-right-blue'>
                            <Button color="primary" style={{ margin: 10, minWidth: 100 }} onClick={() => {
                                this.update(true)


                            }}>{'Save and exit'.translate(this.props.lang)}</Button>


                            <Button color="primary" style={{ margin: 10, minWidth: 100 }} onClick={() => this.setState({ showForm: null, tabIndex: this.state.tabIndexCheck, tabIndexCheck: false, colorSchemeChanged: false, selectedColorScheme: this.state.currentScheme, pageBuilderChanged: false }, () => {
                                this.setState({
                                    showForm: true,
                                    detectedChage: false
                                }, () => {
                                    if (this.props.changeLinkModal.indexOf('group|') != -1) {
                                        this.props.changeGroup(this.props.changeLinkModal.split('|')[1])
                                        this.props.abortAction()
                                    } else if (this.props.changeLinkModal.indexOf('clinic|') != -1) {
                                        this.props.changeClinic(this.props.changeLinkModal.split('|')[1]);
                                        this.props.abortAction()
                                    } else if (!this.state.pageBuilderChanged) {
                                        this.props[0].history.push(this.props.changeLinkModal)
                                        this.props.abortAction()
                                    }
                                })

                                // this.props[0].history.push(this.props.changeLinkModal)
                                // this.props.abortAction()
                            })}>{'Exit without saving'.translate(this.props.lang)}</Button>

                        </ModalFooter>
                    </Modal>
                    : null}
            </div>
        );
    }
}
const selector = formValueSelector('form');

export default connect(state => {
    return {
        // check: selector(state, 'landingConfig.informationLink')

    }

})(Page(ClinicData));


