import React, { Component } from 'react'
import { API_ENDPOINT } from '../../../constants';

import Isvg from 'react-inlinesvg'

import openLinkIcon from '../../../assets/svg/open-link.svg'
import { Player } from '@lottiefiles/react-lottie-player';

export default class PatientDocuments extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            documents: []
        }
    }

    getDocuments = async () => {
        try {
            const response = await fetch(`${API_ENDPOINT}/users/users/patient/documents/get`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify({
                    groupAlias: localStorage.getItem('groupAlias')
                })
            });

            const data = await response.json();

            if (data.documents) {
                this.setState({
                    loading: false,
                    documents: data.documents
                })
            } else {
                this.setState({
                    loading: false
                })
            }
        } catch (err) { }
    }

    componentDidMount() {
        if (this.props.documents) {
            this.setState({
                loading: false,
                documents: this.props.documents
            })
        } else {
            this.getDocuments();
        }
    }

    render() {
        if (this.state.loading) return <div className="loader-wrap" >
            <Player
                autoplay={true}
                loop={true}
                src="/lf30_editor_l5cxzdyf.json"
                style={{ height: typeof window != 'undefined' && window.innerWidth < 768 ? "128px" : "256px", width: typeof window != 'undefined' && window.innerWidth < 768 ? "128px" : "256px" }}
            ></Player>
        </div>;

        if (!this.state.documents?.length) return <p style={{ textAlign: 'center' }}>{'No documents for this patient'.translate(this.props.lang)}.</p>;

        return (
            <table className='patient-documents-table'>
                <thead>
                    <tr>
                        <th>{'Name'.translate(this.props.lang)}</th>
                        <th>{'Type'.translate(this.props.lang)}</th>
                        <th>{'Actions'.translate(this.props.lang)}</th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.documents.map((item, idx) => {
                        if (!item || !item.link) return null;

                        return <tr key={item.id ?? item.link ?? idx}>
                            <td>{item.name}</td>
                            <td>{item.type}</td>
                            <td>
                                <div className='patient-document-link-container'>
                                    <a
                                        href={item.link}
                                        target='_blank'
                                        rel='noopener noreferrer'
                                        aria-label={'Open'.translate(this.props.lang)}
                                    >
                                        <Isvg src={openLinkIcon} />
                                    </a>
                                    <p>{'Open'.translate(this.props.lang)}</p>
                                </div>
                            </td>
                        </tr>
                    })}
                </tbody>
            </table>
        )
    }
}
