import React, { Component } from 'react';
import Page from '../../containers/page';

import {
    Container,
    Row,
    Col,
    FormGroup, Label, Input

} from 'reactstrap';

import { Link } from 'react-router-dom';

import Isvg from 'react-inlinesvg'
import Search from '../../components/search';
import addIcon from '../../assets/svg/add-icon.svg';
import edit from '../../assets/svg/edit.svg'
import garabage from '../../assets/svg/garbage.svg'

import FormBuilder from '../../components/forms/modalFormBuilder';
import { required } from '../../components/forms/validation';

import ListBuilder from '../../components/listBuilder';
import DeleteModal from '../../components/deleteModal';
import { getSearchParams, generateSearchLink, generateSearchLinkMultiple } from '../../helpers/linkHelper';
import { API_ENDPOINT } from '../../constants';
import ErrorModal from '../../components/errorModal';
import { formValueSelector, change } from 'redux-form';  // ES6
import { connect } from 'react-redux';

import { Player, Controls } from '@lottiefiles/react-lottie-player';

import profileUser from '../../assets/images/user.png';
import notification from '../../assets/svg/notification.svg'
import moreIcon from '../../assets/svg/more.svg'

function parseJSON(response) {
    return response.json().then(json => {
        return {
            result: json,
            status: response.status
        }
    })
}

/**
* Sub cateogoies page
* @author   Milan Stanojevic
*/
class SubCategories extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props.initialData,
            form: false,
            useState: false,
            params: {
                entries: 10,
                page: 0,
                'query-group': null
            },
            items: [],
            total: 0,
            loading: true,
            subgroups: [],
            categories: []
        };
    }


    get = () => {
        this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));

        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0, 'query-group': null })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

        for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
            this.props.loadDataWithQuery[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0, 'query-group': null })).then((data) => {
                let dataToSet = { ...data }
                if (dataToSet.page || dataToSet.items || dataToSet.total) {
                    dataToSet.loading = null;
                    // console.log(dataToSet.page || dataToSet.items || dataToSet.total)
                    let checkFilter = '';
                    if (dataToSet.resultFilter) {
                        checkFilter = String(dataToSet.resultFilter)
                        delete dataToSet.resultFilter;
                    }
                    let search = this.props[0].location.search;
                    let serachParams = getSearchParams(search);
                    let searchParamsFilter = '';
                    if (serachParams.filter) {
                        searchParamsFilter = serachParams.filter;
                    }
                    if (searchParamsFilter != checkFilter) {
                        delete dataToSet.page;
                        delete dataToSet.items;
                        delete dataToSet.total;
                        delete dataToSet.loading;
                    }
                }
                this.setState({
                    ...dataToSet,
                    // loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

    }

    componentDidMount() {
        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }

        this.get();

    }

    componentDidUpdate(prevProps) {
        if (prevProps[0].location.search != this.props[0].location.search) {
            this.setState({
                loading: true
            }, () => {
                this.get();

            })
        }

        if (prevProps.category != this.props.category) {
            let cats = {};
            for (let i = 0; i < this.state.categories.length; i++) {
                if (this.state.categories[i]._id == this.props.category) {
                    this.props.changeCategorySubgroups(this.state.categories[i]._subgroups);

                }
            }
        }
    }


    updateSort = (field, type) => {
        if (this.state.useState) {
            let state = this.state.params;
            state.sortField = field;
            state.sortType = type;
            this.setState({
                params: state
            })
        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0 }, [{ name: 'sortField', value: field }, { name: 'sortType', value: type }], false));

        }
    }

    updateParams = (name, value, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };
                obj[name] = value;
                this.setState({ params: obj });
            } else {
                let obj = this.state.params;
                obj[name] = value;
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(generateSearchLink(this.props[0].location.search, { entries: 10, page: 0 }, name, value, restart));
        }
    }
    updateMultipleParams = (fields, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj });

            } else {
                let obj = this.state.params;
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0 }, fields, restart));
        }
    }


    /**
    * Insert new or update subcategory
    * @author   Milan Stanojevic
    * @Objectparam    {Object} data     form data
    * @Objectparam    {String} id       subcategory id
    */
    insertOrUpdate = (data) => {
        this.setState({
            loading: true,
            _saving: true
        }, () => {
            if (!this.state.form._id) {

                fetch(API_ENDPOINT + '/data/subcategories/new', {
                    method: 'PUT',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify(data)
                }).then(res => res.json()).then((result) => {
                    this.get();
                    this.setState({
                        _saving: false
                    }, () => {
                        setTimeout(() => {
                            this.setState({ _saving: false, form: null })
                        }, 1000)
                    })
                })
            } else {
                fetch(API_ENDPOINT + '/data/subcategories/' + this.state.form._id, {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify(data)
                }).then(res => res.json()).then((result) => {
                    this.get();
                    this.setState({
                        _saving: false
                    }, () => {
                        setTimeout(() => {
                            this.setState({ _saving: false, form: null })
                        }, 1000)
                    })
                })
            }
        })
    }


    stopAnimation = () => {
        this.player.current.play();
    }

    /**
    * Delete subcategory
    * @author   Milan Stanojevic
    * @Objectparam    {String} id       subcategory id
    */
    delete = (id) => {
        this.setState({
            loading: true
        }, () => {

            fetch(API_ENDPOINT + '/data/subcategories/' + id, {
                method: 'DELETE',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
            }).then(parseJSON).then(({ result, status }) => {
                if (result.error) {
                    this.setState({ error: result.error })
                }
                this.get();
            })
        })

    }



    render() {
        let params = {};
        if (this.state.useState) {
            params = this.state.params;
        } else {
            params = getSearchParams(this.props[0].location.search, { entries: 10, page: 0 })
        }


        let _categories = {};
        for (let i = 0; i < this.state.categories.length; i++) {
            _categories[this.state.categories[i]._id] = this.state.categories[i].subgroups;
        }
        let __categories = {};
        for (let i = 0; i < this.state.categories.length; i++) {
            __categories[this.state.categories[i]._id] = this.state.categories[i];
        }



        let subgroups = [];
        for (let i = 0; i < this.state.subgroups.length; i++) {

            if (this.props.category) {
                if (_categories[this.props.category] && _categories[this.props.category].indexOf(this.state.subgroups[i]._id) !== -1) {
                    continue;
                } else {
                    subgroups.push(this.state.subgroups[i]);

                }

            } else {
                subgroups.push(this.state.subgroups[i]);
            }
        }

        const defaultOptions = {
            loop: true,
            autoplay: true,
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice'
            }
        };

        return (
            <div className={this.props.autoHideSidebar ? "dashboard dashboard-auto-hide" : "dashboard"}>

                <Container fluid>
                    <Row>
                        <Col lg="12">
                            <div className="panel">
                                <h4>{'Edit subcategory'.translate(this.props.lang)}</h4>
                                <Row>
                                    <Col lg="12">
                                        <Label>{'Select category'.translate(this.props.lang)}</Label>
                                        <select value={params['query-category']} onChange={(e) => this.updateParams('query-category', e.target.value, true)} className="form-control">
                                            {
                                                [{ name: 'None'.translate(this.props.lang), _id: null }, ...this.state.categories].map((item, idx) => {
                                                    return (
                                                        <option key={idx} value={item._id}>{item.name}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </Col>
                                </Row>
                            </div>

                            <div className="panel">
                                <ListBuilder
                                    lang={this.props.lang}
                                    loading={this.state.loading}
                                    total={this.state.total}
                                    showNumeration={false}
                                    fields={[{ type: 'text', name: 'name', label: 'Subcategory name'.translate(this.props.lang), allowSort: true },
                                    {
                                        type: 'list', itemName: 'name', name: '_subgroups', label: 'Subgroups'.translate(this.props.lang), allowSort: false, actions: []
                                    },
                                    ]}
                                    items={this.state.items}
                                    actions={
                                        [
                                            {
                                                component: <Isvg src={edit} className="edit-icon" />,
                                                onClick: (item) => this.setState({ form: item })
                                            },
                                            {
                                                component: <Isvg src={garabage} className="delete-icon" />,
                                                onClick: (item) => this.setState({ deleteModal: item })
                                            },
                                        ]
                                    }
                                    params={params}
                                    sortField={params.sortField}
                                    sortType={params.sortType}
                                    updateSort={this.updateSort}
                                    updateParams={this.updateParams}
                                    updateMultipleParams={this.updateMultipleParams}
                                ></ListBuilder>
                                <DeleteModal
                                    lang={this.props.lang}
                                    isOpen={this.state.deleteModal}
                                    toggle={() => this.setState({ deleteModal: null })}
                                    handler={() => { this.delete(this.state.deleteModal._id); this.setState({ deleteModal: null }) }}
                                >
                                    {'Delete subcategory'.translate(this.props.lang)} <strong>{this.state.deleteModal && this.state.deleteModal.name}</strong>?
                                </DeleteModal>

                                {this.state.error ?

                                    <ErrorModal lang={this.props.lang}
                                        isOpen={this.state.error}
                                        toggle={() => this.setState({ error: null })}

                                    >
                                        {this.state.error.translate(this.props.lang)}
                                    </ErrorModal>

                                    :
                                    null
                                }

                            </div>
                        </Col>
                    </Row>

                </Container>
                {this.state.form ?
                    <FormBuilder selectedGroup={this.props.selectedGroup} selectedClinic={this.props.selectedClinic} countryTwoLetter={this.props.countryTwoLetter} country={this.props.country} dateFormat={this.props.dateFormat}
                        getStringDateTs={this.props.getStringDateTs}
                        lang={this.props.lang}
                        onSubmit={(data) => this.insertOrUpdate(data)}
                        initialValues={this.state.form}
                        isOpen={this.state.form}
                        toggle={() => this.setState({ form: null })}
                        size={'xl'}
                        title={this.state.form._id ? 'Edit subcategory'.translate(this.props.lang) : 'Add new subcategory'.translate(this.props.lang)}
                        _saving={this.state._saving}
                        buttonConfirmText={'Saved'.translate(this.props.lang)}
                        buttonText={'Save'.translate(this.props.lang)}

                        beforeComponent={
                            __categories[this.props.category] ?
                                <FormGroup className="parent-element-edit-group">
                                    {'Category:'.translate(this.props.lang)} <strong>{__categories[this.props.category] ? __categories[this.props.category].name : null}</strong> <a target="_blank" href={`/data-managment/categories?editId=${__categories[this.props.category]._id}`}><button type="button" className="button-transparent"><Isvg src={edit} /></button></a>
                                </FormGroup>
                                :
                                null
                        }
                        fields={[
                            {
                                type: 'row',
                                children: [
                                    {
                                        type: 'col',
                                        width: {
                                            lg: 12,
                                            sm: 12,
                                            xs: 12
                                        },
                                        children: [
                                            {
                                                type: 'text',
                                                name: 'name',
                                                label: 'Subcategory name'.translate(this.props.lang),
                                                validate: [required('Subcategory name is required!'.translate(this.props.lang))]
                                            },
                                        ]
                                    },
                                    {
                                        type: 'col',
                                        width: {
                                            lg: 12,
                                            sm: 12,
                                            xs: 12
                                        },
                                        children: [
                                            {
                                                type: 'select',
                                                name: 'category',
                                                label: 'Category'.translate(this.props.lang),
                                                validate: [required('Category is required to select!'.translate(this.props.lang))],
                                                values: [{ name: 'None'.translate(this.props.lang), value: null }, ...this.state.categories.map((item, idx) => { return { value: item._id, name: item.name } })]
                                            },
                                        ]
                                    },

                                    {
                                        type: 'col',
                                        width: {
                                            lg: 12,
                                            sm: 12,
                                            xs: 12
                                        },
                                        children: [
                                            {
                                                type: 'table',
                                                name: '_categorySubgroups',
                                                fields: [{ name: 'name', type: 'text' }],
                                                actions: [


                                                ],
                                                label: 'Category subgroups'.translate(this.props.lang),
                                            },
                                        ]
                                    },



                                    {
                                        type: 'col',
                                        width: {
                                            lg: 12,
                                            sm: 12,
                                            xs: 12
                                        },
                                        children: [
                                            {
                                                type: 'easy-select',
                                                lang: this.props.lang,
                                                name: 'subgroups',
                                                label: 'Subgroups'.translate(this.props.lang),
                                                validate: [required('Subgroup is required to select!'.translate(this.props.lang))],
                                                values: subgroups.map((item, idx) => { return { value: item._id, name: item.name } })
                                            },
                                        ]
                                    },

                                ]
                            }
                        ]}
                    ></FormBuilder>
                    :
                    null
                }

            </div>
        );
    }
}

const selector = formValueSelector('modalForm');

export default connect(state => {
    return { category: selector(state, 'category') }

}, {
    changeCategorySubgroups: value => change('modalForm', '_categorySubgroups', value)
    /*changeMinValue: value => change("modalForm", "waitingMin", value),
    changeMaxValue: value => change("modalForm", "waitingMax", value),
    changeSelectedWaitingTimeType: value => change("modalForm", "selectedWaitingTimeType", value),*/

})(Page(SubCategories));