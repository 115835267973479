import React, { Component } from 'react';
import Link from '../components/link';

import Isvg from 'react-inlinesvg';
import Page from '../containers/page';

import { API_ENDPOINT } from '../constants';

import ErrorModal from '../components/errorModal';

/**
* NetId login component
* @author   Milan Stanojevic
*/
class NetId extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    componentDidMount() {
        let loginStorageKey = this.props[0].match.params.loginStorageKey;

        let groupAlias;
        if (typeof window !== 'undefined' && localStorage.getItem('groupAlias')) {
            groupAlias = localStorage.getItem('groupAlias');
        }


        fetch(API_ENDPOINT + '/users/netid/sign', {
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify({ loginStorageKey, groupAlias })
        }).then(res => res.json()).then((result) => {
            if (result.error) {
                this.setState({
                    error: result.error
                })
            } else {

                if (window.top) {

                    window.top.postMessage('sign_netid', '*')
                }

                this.props[0].history.push('/')
            }
        });
    }

    render() {
        return (
            <div>
                {
                    this.state.error ?
                        <ErrorModal
                            lang={this.props.lang}
                            isOpen={this.state.error}
                            toggle={() => {
                                this.setState({ error: null }, () => {
                                    this.props[0].history.push('/login')
                                })
                            }}

                        >
                            {this.state.error.translate(this.props.lang)}
                        </ErrorModal>
                        :
                        null
                }


            </div>
        );
    }
}

export default NetId;
