import React, { Component } from 'react';
import Page from '../../../containers/page';

import {
    Container,
    Row,
    Col, Input, Label, Modal, ModalBody, ModalHeader, FormGroup, Button, Table, Collapse, CardBody, Card, FormFeedback
} from 'reactstrap';
import { Link } from 'react-router-dom';

import EventComponent from '../../referral/calendarViews/EventComponentt'
import ErrorModal from '../../../components/errorModal';
import { getSearchParams, generateSearchLink, generateSearchLinkMultiple } from '../../../helpers/linkHelper';
import { API_ENDPOINT } from '../../../constants';

import TimePicker from '../../../components/forms/fields/timepicker';

import Isvg from 'react-inlinesvg'
import PatientFilter from '../../../components/patientFilter';
import warrning from '../../../assets/images/warrning.png';
import xIcon from '../../../assets/svg/x.svg';
import InfoModal from '../../../components/infoModal';
import DeleteModal from '../../../components/deleteModal';

import { Calendar, Day, momentLocalizer } from 'react-big-calendar';
import CustomToolbar from '../../referral/calendarViews/CustomToolbar';
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Tooltip } from '@material-ui/core';
import { withStyles } from "@material-ui/core/styles";

import Checkbox from '../../../components/forms/fields/checkbox';
import DatePicker from '../../../components/forms/fields/datePicker';
import openChat from '../../../assets/svg/open_chat.svg';

import { Player, Controls } from "@lottiefiles/react-lottie-player";
import arrowDown from '../../../assets/svg/arrow-down2.svg'
import arrowLeft from '../../../assets/svg/arrow-left.svg'

import edit from '../../../assets/svg/edit.svg'
import garabage from '../../../assets/svg/garbage.svg'

import { v4 as uuidv4 } from 'uuid';


const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: "rgba(58, 63, 85, 0.9)",
        color: "#fff",
        boxShadow: theme.shadows[1],
        fontSize: 14
    }
}))(Tooltip);



import moment from 'moment';
import { faTintSlash } from '@fortawesome/free-solid-svg-icons';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import striptags from 'striptags';
function parseJSON(response) {
    return response.json().then(json => {
        return {
            result: json,
            status: response.status
        }
    })
}


const localizer = momentLocalizer(moment)

function pad(n, width, z) {
    z = z || '0';
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
}

/**
* Test results patient
* @author  Aleksandar Dabic
*/
class TestResults extends Component {
    constructor(props) {
        super(props);
        this.player = React.createRef();
        this.player2 = React.createRef();
        this.state = {
            ...props.initialData,
            form: false,
            useState: false,
            params: {
                entries: 10,
                page: 0
            },
            items: [],
            total: 0,
            loading: true,
            searchItemNumber: 0,
            startDate: new Date(),
            openedSections: [],
            modified: 'Not modified'.translate(this.props.lang),
            sections: [],


        };



    }
    showPlayer = () => {
        this.setState({
            showPlayer: true,
        });
    };

    hidePlayer = () => {
        this.setState({
            showPlayer: false,
        });
    };
    stopAnimation = () => {
        this.player.current.play();
    };





    get = () => {

        if (this.props.uData && this.props.uData.socialSecurityNumber && typeof window != 'undefined') {
            fetch(API_ENDPOINT + '/users/users/patient/test-results/get', {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify({
                    patient: this.state.patient ? this.state.patient.socialSecurityNumber : this.props.uData.socialSecurityNumber,
                    groupAlias: localStorage.getItem('groupAlias')
                })
            }).then(res => res.json()).then((result) => {
                if (result) {
                    if (result.sections) {
                        this.setState({ sections: result.sections })
                    } else {
                        this.setState({
                            sections: [],
                        })
                    }
                    if (result.tsModified) {
                        // let modified = `${moment.unix(result.tsModified).format(this.props.dateFormat)} ${moment.unix(result.tsModified).format("HH:mm:ss")}`
                        let modified = `${this.props.getStringDateTs(result.tsModified, this.props.dateFormat)/*moment.unix(result.tsModified).format(this.props.dateFormat)*/}`
                        this.setState({ modified })
                    } else if (result.tsCreated) {
                        // let modified = `${moment.unix(result.tsCreated).format(this.props.dateFormat)} ${moment.unix(result.tsCreated).format("HH:mm:ss")}`
                        let modified = `${this.props.getStringDateTs(result.tsCreated, this.props.dateFormat)/*moment.unix(result.tsCreated).format(this.props.dateFormat)*/}`
                        this.setState({ modified })
                    } else {
                        this.setState({
                            modified: 'Not modified'.translate(this.props.lang),
                        })
                    }

                }

            })
        }
    }

    componentDidMount() {
        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }

        this.get()

    }

    componentDidUpdate(prevProps, prevState) {


    }
    componentWillUnmount = () => {

    }


    updateSort = (field, type) => {
        if (this.state.useState) {
            let state = this.state.params;
            state.sortField = field;
            state.sortType = type;
            this.setState({
                params: state
            })
        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0 }, [{ name: 'sortField', value: field }, { name: 'sortType', value: type }], false));

        }
    }

    updateParams = (name, value, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };
                obj[name] = value;
                this.setState({ params: obj });
            } else {
                let obj = this.state.params;
                obj[name] = value;
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(generateSearchLink(this.props[0].location.search, { entries: 10, page: 0 }, name, value, restart));
        }
    }
    updateMultipleParams = (fields, restart = false) => {

        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj });

            } else {
                let obj = this.state.params;
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0 }, fields, restart));
        }
    }







    toggle = (idx) => {
        if (idx != this.state.openedSection) {
            this.setState({ openedSection: idx })
        } else {
            this.setState({ openedSection: -1 })
        }


    }


    render() {


        return (
            <div >
                <Container fluid className="test-results-wrap" style={{ padding: 0 }}>
                    <Row>
                        <Col lg='12' style={{ padding: 0 }}>
                            <div >
                                <h6 className="component-header daybook-header">
                                    {
                                        this.props.items.length ?
                                            <div>
                                                <Input style={{ paddingRight: 50 }} type="select" onChange={(e) => {
                                                    let patient = this.props.items.filter(item => item.socialSecurityNumber == e.target.value)[0]

                                                    this.setState({
                                                        patient: patient
                                                    }, () => this.get())
                                                }}>
                                                    <option value={this.props.uData.socialSecurityNumber}>
                                                        {this.props.uData && this.props.uData.userData && this.props.uData.userData.name}
                                                        &nbsp;
                                                        {this.props.uData && this.props.uData.socialSecurityNumber}
                                                    </option>
                                                    {this.props.items.map(item => {
                                                        return (
                                                            <option value={item.socialSecurityNumber}>
                                                                {item.name}
                                                                &nbsp;
                                                                {item.socialSecurityNumber}
                                                            </option>
                                                        )
                                                    })}
                                                </Input>
                                            </div>
                                            :
                                            <div>

                                                {this.props.uData && this.props.uData.userData && this.props.uData.userData.name}<br />
                                                {this.props.uData && this.props.uData.socialSecurityNumber}
                                            </div>
                                    }
                                    <label>{'Test results updated'.translate(this.props.lang)}: {this.state.modified}</label>

                                </h6>


                                {/* <div className="component-header">
                                    <div className="component-header-info">
                                        <h6>{'Test results updated'.translate(this.props.lang)}:</h6>
                                        <p>{this.state.modified}</p>
                                    </div>

                                </div> */}





                            </div>

                        </Col>
                        <Col lg="12">
                            {
                                this.state.sections && this.state.sections.length ?
                                    null
                                    :
                                    <p className='no-conversations-p'>
                                        {'No test results available'.translate(this.props.lang)}
                                    </p>
                            }
                            <div className="results-wrap">
                                {
                                    this.state.sections && this.state.sections.map((item, idx) => {
                                        return (
                                            <div className='accordion-section-item-wrap'>
                                                <div className="accordion-section-item" onClick={() => {
                                                    this.toggle(idx)
                                                }} style={{ marginBottom: '1rem' }}>
                                                    <div style={{ width: '30%' }}>
                                                        <h6>{item.name}</h6>
                                                    </div>
                                                    <div style={{ width: '30%' }}>
                                                        <h6>{this.props.getStringDateTs(item.timestamp, this.props.dateFormat)/*moment.unix(item.timestamp).format(this.props.dateFormat)*/}</h6>
                                                    </div>

                                                    <div className="action-buttons">

                                                        <div>
                                                            {
                                                                this.state.openedSection == idx ?
                                                                    <Isvg src={arrowDown} />
                                                                    :
                                                                    <Isvg src={arrowLeft} />
                                                            }
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="accordion-card">
                                                    <Collapse isOpen={this.state.openedSection == idx ? true : false} className="no-border">
                                                        <Card>
                                                            <CardBody className='accordion-body-wrap test-result-table-container'>
                                                                <table id={item.id} className='accordion-table-wrap'>
                                                                    <tr>
                                                                        <th>{'Analysis'.translate(this.props.lang)}</th>
                                                                        <th>{'Result'.translate(this.props.lang)}</th>
                                                                        <th>{'Referent value'.translate(this.props.lang)}</th>
                                                                        <th>{'Comment'.translate(this.props.lang)}</th>
                                                                    </tr>
                                                                    {
                                                                        item.items && item.items.map((element, eidx) => {
                                                                            if (element.analysis || element.result || element.referentValue || element.comment)
                                                                                return (
                                                                                    <tr>
                                                                                        <td className='cursor-default'><span>{element.analysis}</span> </td>
                                                                                        <td className='cursor-default'><span >{element.result}</span></td>
                                                                                        <td className='cursor-default'><span>{element.referentValue}</span></td>
                                                                                        <td className='cursor-default'><span>{element.comment}</span></td>
                                                                                    </tr>
                                                                                )

                                                                        })

                                                                    }
                                                                </table>

                                                            </CardBody>
                                                        </Card>
                                                    </Collapse>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </Col>
                    </Row>

                </Container>



                {
                    this.state.error ?

                        <ErrorModal lang={this.props.lang}
                            isOpen={this.state.error}
                            toggle={() => this.setState({ error: null })}

                        >
                            {this.state.error.translate(this.props.lang)}
                        </ErrorModal>

                        :
                        null
                }

            </div >
        );
    }
}

export default TestResults;