import React, { Component } from 'react';
import Page from '../../containers/page';

import {
    Container,
    Row,
    Col,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    Input,
    FormGroup,
    Label
} from 'reactstrap';

import Isvg from 'react-inlinesvg'

import FormBuilder from '../../components/forms/modalFormBuilder';
import { required } from '../../components/forms/validation';
import addIcon from '../../assets/svg/add-icon.svg';
import Search from '../../components/search';
import profileUser from '../../assets/images/user.png';
import notification from '../../assets/svg/notification.svg';
import moreIcon from '../../assets/svg/more.svg';
import edit from '../../assets/svg/edit.svg'
import garabage from '../../assets/svg/garbage.svg'
import file from '../../assets/svg/fajl.svg'
import importIcon from '../../assets/svg/import.svg'

import ListBuilder from '../../components/listBuilder';
import DeleteModal from '../../components/deleteModal';
import { getSearchParams, generateSearchLink, generateSearchLinkMultiple } from '../../helpers/linkHelper';
import { API_ENDPOINT, DOMAINS } from '../../constants';
import { formValueSelector, change } from 'redux-form';  // ES6
import { connect } from 'react-redux';
import SelectReferral from '../../components/selectReferral';
import { Player, Controls } from "@lottiefiles/react-lottie-player";

import SelectReferralForTransfer from '../../components/selectReferralForTransfer'
import SelectEReferral from '../../components/selectEReferral';

import ErrorModal from '../../components/errorModal';

/**
* Personal referrals page
* @author   Milan Stanojevic
*/
class FormImporter extends Component {
    constructor(props) {
        super(props);
        this.player = React.createRef();
        this.player2 = React.createRef();
        this.state = {
            ...props.initialData,
            form: false,
            useState: false,
            showPlayer: false,
            showPlayer2: false,
            params: {
                entries: 10,
                page: 0
            },
            items: [],
            total: 0,
            activeTab: 1,
            clinicGroups: [],
            importModalItems: [],
            questionaries: {},
            loading: false
        };
    }


    get = () => {
        let formType;
        let activeTab = this.state.activeTab;
        if (activeTab == 1) {
            formType = 'visit-forms'
        } else if (activeTab == 2) {
            formType = 'revisit-forms'
        } else if (activeTab == 3) {
            formType = 'chat-forms'
        } else if (activeTab == 4) {
            formType = 'daybook-forms'
        }


        this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));

        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0 })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

        for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
            this.props.loadDataWithQuery[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0, formType: formType })).then((data) => {
                let dataToSet = { ...data }
                if (dataToSet.page || dataToSet.items || dataToSet.total) {
                    dataToSet.loading = null;
                    // console.log(dataToSet.page || dataToSet.items || dataToSet.total)
                    let checkFilter = '';
                    if (dataToSet.resultFilter) {
                        checkFilter = String(dataToSet.resultFilter)
                        delete dataToSet.resultFilter;
                    }
                    let search = this.props[0].location.search;
                    let serachParams = getSearchParams(search);
                    let searchParamsFilter = '';
                    if (serachParams.filter) {
                        searchParamsFilter = serachParams.filter;
                    }
                    if (searchParamsFilter != checkFilter) {
                        delete dataToSet.page;
                        delete dataToSet.items;
                        delete dataToSet.total;
                        delete dataToSet.loading;
                    }
                }
                this.setState({
                    ...dataToSet,
                    // loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

    }
    showPlayer = () => {
        this.setState({
            showPlayer: true,
        });
    };

    hidePlayer = () => {
        this.setState({
            showPlayer: false,
        });
    };
    stopAnimation = () => {
        this.player.current.play();
    };


    componentDidMount() {
        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }
        if (this.props[0].location.state && this.props[0].location.state.activeTab) {
            this.setState({ activeTab: this.props[0].location.state.activeTab }, () => {
                this.get();
                this.props[0].history.push({
                    state: {
                        activeTab: null
                    }
                })
            })
        } else {
            this.get();
        }

    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps[0].location.search != this.props[0].location.search) {
            this.setState({
                loading: true
            }, () => {
                this.get();

            })
        }
        if (prevState.activeTab != this.state.activeTab) {

            this.get()
        }

    }


    updateSort = (field, type) => {
        if (this.state.useState) {
            let state = this.state.params;
            state.sortField = field;
            state.sortType = type;
            this.setState({
                params: state
            })
        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0, group: this.props.selectedGroup, clinic: this.props.selectedClinic }, [{ name: 'sortField', value: field }, { name: 'sortType', value: type }], false));

        }
    }

    updateParams = (name, value, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };
                obj[name] = value;
                this.setState({ params: obj });
            } else {
                let obj = this.state.params;
                obj[name] = value;
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(generateSearchLink(this.props[0].location.search, { entries: 10, page: 0, group: this.props.selectedGroup, clinic: this.props.selectedClinic }, name, value, restart));
        }
    }
    updateMultipleParams = (fields, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj });

            } else {
                let obj = this.state.params;
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0, group: this.props.selectedGroup, clinic: this.props.selectedClinic }, fields, restart));
        }
    }



    /**
    * Update personal questionary
    * @author   Milan Stanojevic
    * @Objectparam    {Object} data     form data
    * @Objectparam    {String} id       personal questionary id
    */
    update = (data) => {


        this.setState({
            loading: true
        }, () => {
            // fetch(API_ENDPOINT + '/data/personal-questionary/' + data._id, {
            //     method: 'POST',
            //     headers: {
            //         'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            //         'content-type': 'application/json'
            //     },
            //     body: JSON.stringify(data)
            // }).then(res => res.json()).then((result) => {
            //     this.get();
            //     this.setState({
            //         form: null
            //     })
            // })

        })
    }

    /**
    * Delete personal questionary
    * @author   Milan Stanojevic
    * @Objectparam    {String} id       personal questionary id
    */
    delete = (id) => {
        this.setState({
            loading: true
        }, () => {

            fetch(API_ENDPOINT + '/data/form-importer/' + id, {
                method: 'DELETE',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
            }).then(res => res.json()).then((result) => {
                this.get();
            })
        })

    }

    getForms = () => {
        this.setState({ loadingModal: true }, () => {
            fetch(API_ENDPOINT + '/data/form-importer/clinic-group/forms', {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify({
                    group: this.state.selectedGroup,
                    formType: this.state.selectedFormType
                })
            }).then(res => res.json()).then((result) => {
                if (result) {
                    this.setState({ importModalItems: result })
                }
                this.setState({ loadingModal: false })
            })
        })

    }
    importForm = (form) => {
        if (form) {
            let obj = {
                formType: this.state.selectedFormType,
                questionaryId: form._id
            }
            if (form.group) {
                obj.group = form.group;
            }
            if (form.clinic) {
                obj.clinic = form.clinic;
            }
            if (form.name) {
                obj.name = form.name;
            }
            if (form.items) {
                obj.items = form.items
            }
            if (form.intelligentReferral) {
                obj.intelligentReferral = form.intelligentReferral
            }
            this.setState({ showPlayer: form._id, }, () => {
                this.player.current.play();
                fetch(API_ENDPOINT + '/data/form-importer/import/form', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify(obj)
                }).then(res => res.json()).then((result) => {
                    this.setState({
                        message: result.message,
                        showPlayer: false,
                        showPlayer2: form._id,
                    },
                        () => {
                            setTimeout(() => {
                                this.setState({ showPlayer2: false });
                            }, 1000);
                        })
                    // if (result) {
                    //     this.setState({ importModalItems: result })
                    // }
                    this.getForms()
                    this.setState({ loadingModal: false }, this.get)
                })
            })
        }

    }

    transferQuestionaries = (data) => {
        if (this.state.domen && this.state.domen != -1) {
            data.domen = this.state.domen
            this.setState({
                loading: true
            }, () => {

                fetch(API_ENDPOINT + '/domen-transfer/forms', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify(data)
                }).then(res => res.json()).then((result) => {
                    if (result) {
                        this.setState({
                            transferModal: false,
                            questionaries: null,
                            domen: null
                        })
                    }
                    this.get();
                })
            })
        } else {
            this.setState({
                error: 'You have to select domen'.translate(this.props.lang)
            })
        }

    }


    render() {
        let params = {};
        if (this.state.useState) {
            params = this.state.params;
        } else {
            params = getSearchParams(this.props[0].location.search, { entries: 10, page: 0, group: this.props.selectedGroup, clinic: this.props.selectedClinic })
        }




        return (
            <div className={this.props.autoHideSidebar ? "dashboard dashboard-auto-hide" : "dashboard"}>
                {this.state.error ?

                    <ErrorModal lang={this.props.lang}
                        isOpen={this.state.error}
                        toggle={() => this.setState({ error: null })}

                    >
                        {this.state.error.translate(this.props.lang)}
                    </ErrorModal>

                    : null}

                <Container fluid>
                    <Row>
                        <Col lg="12">
                            <div className="panel">
                                <div className="panel-header header-two-buttons">
                                    <h5 className="component-header">
                                        {this.props.linksMeta && this.props.linksMeta[this.props[0].location.pathname] ? this.props.linksMeta[this.props[0].location.pathname][this.props.lang] : ''}
                                    </h5>
                                    <div className="">
                                        <button className="btn add-button" style={{ marginRight: 15 }} onClick={() => {
                                            this.props[0].history.push('/data-managment/form-importer/new')
                                        }}><Isvg src={addIcon} /> {'Add'.translate(this.props.lang)}</button>
                                        <button className="btn add-button" style={{ marginRight: 15 }} onClick={() => {
                                            this.setState({
                                                transferModal: true,
                                            })
                                        }}><Isvg src={importIcon} /> {'Transfer questionary'.translate(this.props.lang)}</button>



                                        <button className="btn add-button" style={{ marginRight: 15 }} onClick={() => {
                                            this.setState({
                                                selectReferralModal: true
                                            })
                                        }}><Isvg src={importIcon} /> {'Import E-referral'.translate(this.props.lang)}</button>

                                        <button className="btn add-button" onClick={() => {
                                            this.setState({ importFromClinicGroupModal: true })
                                        }}><Isvg src={addIcon} /> {'Import from clinic group'.translate(this.props.lang)}</button>


                                    </div>
                                </div>
                                <div className="flex" style={{ position: 'relative', marginBottom: '50px', zIndex: "950", width: "100%", borderBottom: "1px solid #9cafbf" }}>
                                    <Button className={this.state.activeTab == 1 ? "tab-button acitve-tab-button" : "tab-button"} onClick={() => {
                                        if (this.state.activeTab !== 1)
                                            this.setState({ activeTab: 1 })
                                    }}>{"Visit forms".translate(this.props.lang)}</Button>
                                    <Button className={this.state.activeTab == 2 ? "tab-button acitve-tab-button" : "tab-button"} onClick={() => {
                                        if (this.state.activeTab !== 2)
                                            this.setState({ activeTab: 2 })
                                    }}>{"Revisit forms".translate(this.props.lang)}</Button>
                                    <Button className={this.state.activeTab == 3 ? "tab-button acitve-tab-button" : "tab-button"} onClick={() => {
                                        if (this.state.activeTab !== 3)
                                            this.setState({ activeTab: 3 })
                                    }}>{"Chat forms".translate(this.props.lang)}</Button>
                                    <Button className={this.state.activeTab == 4 ? "tab-button acitve-tab-button" : "tab-button"} onClick={() => {
                                        if (this.state.activeTab !== 4)
                                            this.setState({ activeTab: 4 })
                                    }}>{"Daybook forms".translate(this.props.lang)}</Button>

                                </div>

                                <ListBuilder
                                    lang={this.props.lang}
                                    loading={this.state.loading}
                                    total={this.state.total}
                                    showNumeration={false}
                                    fields={[
                                        { type: 'text', name: 'name', label: 'Name'.translate(this.props.lang), allowSort: true },
                                        { type: 'text', name: 'clinicGroupName', label: 'Parent group'.translate(this.props.lang), allowSort: true },

                                    ]}
                                    items={this.state.items}
                                    actions={
                                        [
                                            {
                                                component: <div className="tabel-action-tooltip"><Isvg src={edit} style={{ width: 24, minWidth: 24 }} /><div style={{ width: 180 }} className="chat-icons-tooltip">{'Edit'.translate(this.props.lang)}</div> </div>,
                                                onClick: (item) => this.props[0].history.push('/data-managment/form-importer/' + item._id)
                                            },
                                            {
                                                component: <div className="tabel-action-tooltip"><Isvg src={garabage} style={{ width: 24, minWidth: 24 }} /><div style={{ width: 180 }} className="chat-icons-tooltip">{'Delete'.translate(this.props.lang)}</div> </div>,
                                                onClick: (item) => this.setState({ deleteModal: item })
                                            },
                                        ]
                                    }
                                    params={params}
                                    sortField={params.sortField}
                                    sortType={params.sortType}
                                    updateSort={this.updateSort}
                                    updateParams={this.updateParams}
                                    updateMultipleParams={this.updateMultipleParams}

                                ></ListBuilder>
                                <DeleteModal
                                    lang={this.props.lang}
                                    isOpen={this.state.deleteModal}
                                    toggle={() => this.setState({ deleteModal: null })}
                                    handler={() => { this.delete(this.state.deleteModal._id); this.setState({ deleteModal: null }) }}
                                >
                                    {'Delete form'.translate(this.props.lang)} <strong>{this.state.deleteModal && this.state.deleteModal.name}</strong>?
                                </DeleteModal>

                            </div>
                        </Col>
                    </Row>
                </Container>

                {
                    this.state.importFromClinicGroupModal ?
                        <Modal isOpen={this.state.importFromClinicGroupModal} centered size="lg" className='form-importer-modal-wrap'>
                            <ModalHeader >{'Import from clinic group'.translate(this.props.lang)}</ModalHeader>

                            <ModalBody>
                                <FormGroup>
                                    <Label>{'Select clinic group'.translate(this.props.lang)}</Label>
                                    <Input type='select' value={this.state.selectedGroup} onChange={(e) => {
                                        let value = e.target.value;
                                        if (this.state.selectedGroup != value)
                                            this.setState({ selectedGroup: value, selectedFormType: -1, importModalItems: [] })
                                    }}>
                                        <option value={-1}>{'Select'.translate(this.props.lang)}</option>
                                        {
                                            this.state.clinicGroups && this.state.clinicGroups.map((item, idx) => {
                                                return (
                                                    <option value={item._id}>{item.name}</option>
                                                )
                                            })
                                        }
                                    </Input>
                                </FormGroup>
                                {
                                    this.state.selectedGroup && this.state.selectedGroup != -1 ?
                                        <FormGroup>
                                            <Label>{'Select form type'.translate(this.props.lang)}</Label>
                                            <Input type='select' value={this.state.selectedFormType} onChange={(e) => {
                                                let value = e.target.value;
                                                this.setState({ selectedFormType: value }, () => {
                                                    if (this.state.selectedFormType != -1) {
                                                        this.getForms()
                                                    }
                                                })
                                            }}>
                                                <option value={-1}>{'Select'.translate(this.props.lang)}</option>
                                                {
                                                    this.state.clinicGroups && this.state.clinicGroups.length && this.state.clinicGroups.filter(el => el._id == this.state.selectedGroup)[0] && this.state.clinicGroups.filter(el => el._id == this.state.selectedGroup)[0].formTypes.map((item, idx) => {
                                                        return (
                                                            <option value={item.value}>{item.name.translate(this.props.lang)}</option>
                                                        )
                                                    })
                                                }
                                            </Input>
                                        </FormGroup>
                                        :
                                        null
                                }
                                <div className='list-of-forms-wrap'>
                                    {
                                        this.state.selectedGroup && this.state.selectedFormType && this.state.importModalItems && this.state.importModalItems.map((item, idx) => {
                                            return (
                                                <div className="list-of-forms-item">
                                                    <h6>{item.name}</h6>
                                                    {/* {
                                                        item.imported ? <h6>{item.imported}</h6> : null
                                                    } */}

                                                    <Button color='primary' onClick={() => {
                                                        this.importForm(item)
                                                    }}>
                                                        {this.state.showPlayer == item._id ? (
                                                            <Player
                                                                onEvent={(event) => {
                                                                    if (event === "load") this.stopAnimation();
                                                                }}
                                                                onStateChange={this.toggleVisibility}
                                                                ref={this.player} // set the ref to your class instance
                                                                autoplay={true}
                                                                loop={true}
                                                                controls={true}
                                                                src="https://assets8.lottiefiles.com/packages/lf20_ZSRKCF.json"
                                                                style={{ height: "30px", width: "30px" }}
                                                            ></Player>

                                                        ) : this.state.showPlayer2 == item._id ? (
                                                            <div style={{ display: 'flex', alignItems: 'center' }}><Player
                                                                onStateChange={this.toggleVisibility}
                                                                ref={this.player2} // set the ref to your class instance
                                                                autoplay={true}
                                                                loop={true}
                                                                controls={true}
                                                                src="https://assets10.lottiefiles.com/packages/lf20_CxyxLk.json"
                                                                style={{ height: "30px", width: "30px" }}
                                                            ></Player>
                                                                {'Imported'.translate(this.props.lang)}
                                                            </div>
                                                        ) : (
                                                            "Import".translate(this.props.lang)
                                                        )
                                                        }
                                                    </Button>
                                                </div>
                                            )
                                        })
                                    }
                                </div>


                            </ModalBody>
                            <ModalFooter className='buttons-right-blue' style={{ justifyContent: 'flex-end' }}>
                                <Button color="primary" onClick={() => this.setState({ importFromClinicGroupModal: !this.state.importFromClinicGroupModal, selectedFormType: null, selectedGroup: null, importModalItems: null })}>{'Close'.translate(this.props.lang)}</Button>

                            </ModalFooter>
                            {
                                this.state.loadingModal ?
                                    <div className="loader-wrap">
                                        <Player
                                            autoplay={true}
                                            loop={true}
                                            renderer={'svg'}
                                            src="/lf30_editor_l5cxzdyf.json"
                                            style={{ height: typeof window != 'undefined' && window.innerWidth < 768 ? "128px" : "256px", width: typeof window != 'undefined' && window.innerWidth < 768 ? "128px" : "256px" }}
                                        ></Player>

                                    </div>
                                    :
                                    null
                            }

                        </Modal>
                        :
                        null
                }
                {
                    this.state.transferModal ?
                        <Modal isOpen={this.state.transferModal} centered size="xl">
                            <ModalHeader toggle={() => this.setState({ transferModal: !this.state.transferModal, questionaries: null, domen: null })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ transferModal: !this.state.transferModal, questionaries: null, domen: null })}>&times;</button>}>{'Select referrals for transfer'.translate(this.props.lang)}</ModalHeader>

                            <ModalBody>
                                <FormGroup>
                                    <Label>{'Select domen'.translate(this.props.lang)}</Label>
                                    <Input type='select'
                                        onChange={e => {

                                            this.setState({ domen: e.target.value == -1 ? null : e.target.value, }, () => { })
                                        }}
                                        value={this.state.domen}
                                    >
                                        <option value={-1}>
                                            {'None'.translate(this.props.lang)}
                                        </option>
                                        {
                                            DOMAINS?.map((item, idx) => {
                                                if (window.location.origin !== item.domen)
                                                    return (
                                                        <option value={item.api}>
                                                            {item.name}
                                                        </option>
                                                    )
                                            })
                                        }
                                        {/* {
                                            this.props.country === 'denmark' ?
                                                <>
                                                    {window.location.origin != 'https://test.curoflow.dk' ?
                                                        <option value={'https://testapi.curoflow.dk'}>
                                                            {'TEST'.translate(this.props.lang)}
                                                        </option>
                                                        : null}
                                                    {window.location.origin != 'https://tryme.curoflow.dk' ?
                                                        <option value={'https://tryme-api.curoflow.dk'}>
                                                            {'TRY ME'.translate(this.props.lang)}
                                                        </option>
                                                        : null}
                                                    {window.location.origin != 'https://dashboard.curoflow.dk' ?
                                                        <option value={'https://dashboardproxy.curoflow.dk'}>
                                                            {'DASHBOARD'.translate(this.props.lang)}
                                                        </option>
                                                        : null}
                                                </>
                                                :
                                                <>
                                                    {window.location.origin != 'https://dev.curoflow.se' ?
                                                        <option value={'https://devapi.curoflow.se'}>
                                                            {'DEV'.translate(this.props.lang)}
                                                        </option>
                                                        : null}
                                                    {window.location.origin != 'https://tryme.curoflow.se' ?
                                                        <option value={'https://tryme-api.curoflow.se'}>
                                                            {'TRY ME'.translate(this.props.lang)}
                                                        </option>
                                                        : null}
                                                    {window.location.origin != 'https://test.curoflow.se' ?
                                                        <option value={'https://testapi.curoflow.se'}>
                                                            {'TEST'.translate(this.props.lang)}
                                                        </option>
                                                        : null}
                                                    {window.location.origin != 'https://dashboard.curoflow.se' ?
                                                        <option value={'https://dashboardapi.curoflow.se'}>
                                                            {'DASHBOARD'.translate(this.props.lang)}
                                                        </option>
                                                        : null}
                                                    {window.location.origin != 'https://sandbox.curoflow.se' ?
                                                        <option value={'https://sandboxapi.curoflow.se'}>
                                                            {'SANDBOX'.translate(this.props.lang)}
                                                        </option>
                                                        : null}
                                                </>
                                        } */}
                                    </Input>
                                </FormGroup>

                                <SelectReferralForTransfer {...this.props}
                                    formType={
                                        this.state.activeTab == 1 ? 'visit-forms'
                                            :
                                            this.state.activeTab == 2 ? 'revisit-forms'
                                                :
                                                this.state.activeTab == 3 ? 'chat-forms'
                                                    :
                                                    this.state.activeTab == 4 ? 'daybook-forms'
                                                        :
                                                        null
                                    }
                                    value={this.state.questionaries}
                                    onChange={(val) => {
                                        if (val) {
                                            let questionaries = this.state.questionaries;
                                            questionaries = val;
                                            this.setState({ questionaries });
                                        }
                                    }}
                                ></SelectReferralForTransfer>

                            </ModalBody>
                            <ModalFooter>
                                <Button onClick={() => this.transferQuestionaries(this.state.questionaries)}>{'Transfer form'.translate(this.props.lang)}</Button>
                            </ModalFooter>

                        </Modal>
                        :
                        null
                }
                {
                    this.state.selectReferralModal ?
                        <Modal isOpen={this.state.selectReferralModal} centered size="xl">
                            <ModalHeader toggle={() => this.setState({ selectReferralModal: !this.state.selectReferralModal })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ selectReferralModal: !this.state.selectReferralModal })}>&times;</button>}>{'Select referral'.translate(this.props.lang)}</ModalHeader>

                            <ModalBody>
                                <SelectEReferral {...this.props}
                                    formType={
                                        this.state.activeTab == 1 ? 'visit-forms'
                                            :
                                            this.state.activeTab == 2 ? 'revisit-forms'
                                                :
                                                this.state.activeTab == 3 ? 'chat-forms'
                                                    :
                                                    this.state.activeTab == 4 ? 'daybook-forms'
                                                        :
                                                        null
                                    }
                                ></SelectEReferral>
                            </ModalBody>

                        </Modal>
                        :
                        null
                }


            </div>
        );
    }
}

const selector = formValueSelector('modalForm');

export default connect(state => {
    // return { group: selector(state, 'group') }

}, {
    // changeSubgroup: value => change("modalForm", "subgroup", value),

})(Page(FormImporter));