import React, { Component } from 'react';

import {
    Container,
    Row,
    Col,
    FormGroup, Label, Input,
    Button,
    Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';



import ListBuilder from './listBuilder';
import { getSearchParams, generateSearchLink, generateSearchLinkMultiple } from '../helpers/linkHelper';
import { API_ENDPOINT, GOOGLE_API_KEY } from '../constants';
import moment from 'moment';
import eRemissIcon from '../assets/svg/e.svg';

import Isvg from 'react-inlinesvg';
import Checkbox from './forms/fields/checkbox';
import xIcon from '../assets/svg/x.svg';

import fullscreenIcon from '../assets/svg/fullscreen-icon.svg'


import {
    Chart as ChartJS,
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    LineController,
    BarController,
} from 'chart.js';

import { Pie, Bar, Line, Chart } from 'react-chartjs-2';

function colorToRgba(color, alpha = 1) {
    // Create a hidden div element to assist with color conversion
    const div = document.createElement("div");
    div.style.color = color;
    document.body.appendChild(div);

    // Get the computed RGBA value
    const rgbaColor = window.getComputedStyle(div).color;

    // Remove the "rgb(" and ")" from the computed color
    const rgbaString = rgbaColor.substring(rgbaColor.indexOf("(") + 1, rgbaColor.lastIndexOf(")"));

    // Ensure alpha is a valid value between 0 and 1
    alpha = parseFloat(alpha);
    if (isNaN(alpha) || alpha < 0 || alpha > 1) {
        throw new Error('Invalid alpha value. Should be between 0 and 1.');
    }

    // Construct the final RGBA string
    const rgba = `rgba(${rgbaString}, ${alpha})`;

    // Clean up the temporary div element
    document.body.removeChild(div);

    return rgba;
}




ChartJS.register(
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    LineController,
    BarController
);


class DiaryGraph extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props.initialData,
            backgroundColors: [
                '#5E35B1',
                '#2E7D32',
                '#00ACC1',
                '#FF4081',
                '#FF5733',
                '#4286f4',
                '#A8D87D',
                '#FFC107',
                '#FF7043',
                '#FF57BC',
            ],



        };
    }




    componentDidMount() {
        if (this.props.getGraphImage) {
            this.props.getGraphImage(this.getGraphImage)
        }

    }
    componentDidUpdate(prevProps, prevState) {

    }

    componentWillUnmount() {
        this.ref = null;
    }

    getGraphImage = () => {
        if (this.ref && this.ref?.toBase64Image?.()) {
            return this.ref.toBase64Image();
        }
    }



    render() {
        const data = this.props.data;
        const diaryChartType = this.props.daybookChartType ?? 'monthly';

        let leftYAxis = {
            type: 'logarithmic',
            position: 'left',
            offset: true
        }
        let rightYAxis = {
            type: 'linear',
            position: 'right',
            grace: '1000%',
            display: diaryChartType === 'monthly' ? true : false
        }
        if (diaryChartType === 'daily') {
            rightYAxis.max = 1;
        }
        const options = {
            animation: {
                duration: 0
            },
            responsive: true,
            plugins: {
                legend: {
                    position: 'bottom',
                    labels: {
                        usePointStyle: true,
                    },
                }
            },
            scales: {
                'left-y-axis': leftYAxis,
                'right-y-axis': rightYAxis,
                // x: {
                //     stacked: true,
                // },
                // y: {
                //     stacked: true,
                //     display: false
                // }



            },
        }

        // const labels = diaryChartType === 'monthly' ? data?.graph?.map((item) => item?.date && this.props.getStringDateTs(item.date, 'MMMM') && this.props.getStringDateTs(item.date, 'MMMM').translate(this.props.lang)) : data?.graph?.map((item) => item?.date && this.props.getStringDateTs(item.date, 'DD-MM'));
        const labels = diaryChartType === 'monthly' ? data?.graph?.map((item) => item?.name && item.name.translate(this.props.lang)) : data?.graph?.map((item) => item?.date && this.props.getStringDateTs(item.date, 'DD-MM'));

        const datasets = [
            ...data?.diaryGraphDataset?.map((item, idx) => {
                let obj = {
                    type: 'line',
                    yAxisID: 'left-y-axis',
                    label: item.name,
                    data: item.counts,
                    backgroundColor: this.state.backgroundColors[idx],
                    borderColor: this.state.backgroundColors[idx],
                    borderWidth: 3,
                    lineTension: 0.4,

                }
                return obj
            }),
            ...data?.dataset?.sort((a, b) => a.counts[0] - b.counts[0])?.map((item, idx) => {
                let obj = {
                    type: diaryChartType === 'monthly' ? 'line' : 'bar',
                    yAxisID: 'right-y-axis',
                    maxBarThickness: 16,
                    label: item.name,
                    data: item.counts.map(item => {
                        if (diaryChartType === 'monthly' && !item) {
                            return null
                        } else {
                            return item
                        }
                    }),
                    backgroundColor: item.color,//colorToRgba(item.color, 1),
                    borderColor: item.color, // colorToRgba(item.color, 1),
                    borderWidth: diaryChartType === 'monthly' ? 3 : 0,
                    lineTension: 0.4,
                    // stack: 'true'
                }
                return obj
            })
        ]

        return (
            <div className="diary-statistic-graph-wrap">
                <Bar
                    ref={(node) => {
                        this.ref = node;

                    }}
                    options={options}
                    data={{
                        labels: labels,
                        datasets: datasets
                    }}
                />
                {
                    !this.props.disableFullScreen ?
                        <div className='diary-statistic-graph-footer tabel-action-tooltip'>
                            <div className='fullscreen-btn' onClick={() => this.setState({ fullScreen: true })}>
                                <Isvg src={fullscreenIcon} />
                            </div>
                            <div className="chat-icons-tooltip">{'Display graph in full screen'.translate(this.props.lang)}</div>
                        </div>
                        :
                        null
                }


                {
                    this.state.fullScreen ?
                        <Modal isOpen={this.state.fullScreen} centered size='xxxl'>
                            <ModalHeader toggle={() => this.setState({ fullScreen: !this.state.fullScreen })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ fullScreen: !this.state.fullScreen })}><Isvg src={xIcon} /></button>}>

                            </ModalHeader>
                            <ModalBody>
                                <Bar
                                    options={options}
                                    data={{
                                        labels: labels,
                                        datasets: datasets
                                    }}
                                />
                            </ModalBody>
                            <ModalFooter className='buttons-right-blue'>
                                <Button color="primary" onClick={() => this.setState({ fullScreen: !this.state.fullScreen })}>{'Close'.translate(this.props.lang)}</Button>
                            </ModalFooter>
                        </Modal>
                        :
                        null
                }

            </div>
        );


    }
}
export default DiaryGraph;