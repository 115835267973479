import React, { Component, useRef } from 'react'
// import { Link, Redirect } from 'react-router-dom'
import Link from './sidebarLink';
import { connect } from 'react-redux'

import Isvg from 'react-inlinesvg'

import {
  Container,
  Row,
  Col,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  FormGroup,
  Label,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from 'reactstrap'

import moment from 'moment'
import logoBig from '../assets/svg/logo.svg'
import logoSmall from '../assets/svg/logo-small.svg'
import menuIcon from '../assets/svg/menu-icon.svg';
import logoMobile from '../assets/images/logo_mobile.png';
import homeIcon from '../assets/svg/sidebar-icons/home-icon.svg'
import dataIcon from '../assets/svg/sidebar-icons/data-icon.svg'
import managmentIcon from '../assets/svg/sidebar-icons/managment-icon.svg'
import userIcon from '../assets/svg/sidebar-icons/user-icon.svg'
import modulesIcon from '../assets/svg/sidebar-icons/modules-icon.svg'
import languagesIcon from '../assets/svg/sidebar-icons/languages-icon.svg'
import logoutIcon from '../assets/svg/sidebar-icons/logout-icon.svg'
import shapeBlue from '../assets/svg/shape-blue.svg'
import accountIcon from '../assets/svg/sidebar-icons/account.svg'
import arrowSidebar from '../assets/images/down_white_arrow.png'
import clinicUsersIcon from '../assets/svg/sidebar-icons/clinics-users.svg'
import regionIcon from '../assets/svg/sidebar-icons/region-icon.svg'
import clinicIcon from '../assets/svg/sidebar-icons/clinic-icon.svg'
import ceIcon from '../assets/svg/ce_logo.svg'
import mdIcon from '../assets/svg/mdIcon.svg'
import qaIcon from '../assets/svg/qa.svg'
import { v4 as uuidv4 } from 'uuid';

import profileUser from '../assets/images/user.png';
import selectArrow from '../assets/images/down_white_arrow.png';

import shapeGreen from "../assets/svg/shape-green.svg";
import shapeYellow from "../assets/images/shape-yellow.png";

import Toggle from '../components/forms/fields/switch';

// import NotifIcon from '../assets/svg/sidebar-icons/notification.svg';
// import ChatIcon from '../assets/svg/sidebar-icons/chat-icon.svg';

import { API_ENDPOINT } from '../constants'
import Button from 'reactstrap/lib/Button';
import Chat from '../icons/Chat';
import Notification from '../icons/Notification';
import WaitingRoom from '../icons/WaitingRoom';
moment.locale('sr')


function iosDevice() {
  var userAgent = window.navigator.userAgent;
  if (userAgent.match(/iPad/i) || userAgent.match(/iPhone/i)) {
    return true;
  }
  else {
    return false;
  }
}


/**
* Sidebar component
* @author   Milan Stanojevic
*/
export class Sidebar extends Component {
  constructor(props) {
    super(props)

    this.linkRefs = {};
    this.sidebarRef = React.createRef();
    this.state = {
      showData: false,
      showManagment: false,
      selectedMobileItems: {},
      showSelectGroup: false,
      showSelectClinic: false,
      unpaidCount: 0,
      invoicesRequested: 0,
      closeDropdown: false,
      aboutModal: false,
      newMessageCount: false,
      notifindex: -1,
      W: typeof window !== 'undefined' ? window?.innerWidth : null,
      // sidebarWide: typeof window != 'undefined' && window.innerWidth < 1199 ? false : true
      blink: false,
    }
    this.sidebarWide = this.props.autoHideSidebar ? typeof window != 'undefined' && window.innerWidth < 1199 ? false : true : false;
    // this.sidebarWide = typeof window != 'undefined' && window.innerWidth < 1199 ? false : true;
  }



  uDataCondition = (condition) => {
    let arr = condition.split('||');
    for (let i = 0; i < arr.length; i++) {
      if (this.props.uData[arr[i]]) {
        return true;
      }
    }

    return false;
  }

  clinicEntryModuleCondition = (group, condition) => {

    let arr = condition.split('||');
    for (let i = 0; i < arr.length; i++) {
      if (group[arr[i]]) {
        return true;
      }
    }

    return false;
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.linkRefs[this.props[0].location.pathname] && (this.props.sidebarScrollTop === null || this.props.sidebarScrollTop === undefined)) {
      if (window.location.href == `${window.location.origin}/` || window.location.href == `${window.location.origin}/my-data/account`) {
        this.sidebarNode.scrollTop = 0;
        this.props.updateSidebarScrollTop(this.sidebarNode.scrollTop);



      } else {
        this.sidebarNode.scrollTop = this.linkRefs[this.props[0].location.pathname].offsetTop;
        this.props.updateSidebarScrollTop(this.sidebarNode.scrollTop);

      }

    }


    if (this.props.uData && prevProps.uData && this.props.uData.unpaidCount != prevProps.uData.unpaidCount) {
      if (this.props.uData && this.props.uData.unpaidCount) {
        this.setState({ unpaidCount: this.props.uData.unpaidCount })
      }
    }
    if (this.props.uData && prevProps.uData && this.props.uData.invoiceRequested != prevProps.uData.invoiceRequested) {
      if (this.props.uData && this.props.uData.invoiceRequested) {
        this.setState({ invoicesRequested: this.props.uData.invoiceRequested })
      } else {
        this.setState({ invoicesRequested: 0 })
      }
    }
    if (this.props.selectedClinic != prevProps.selectedClinic || this.props.selectedGroup != prevProps.selectedGroup) {

      this.props.verifyUser()
      this.checkNewMessageNotif()
    }


    if (this.props.uData && prevProps.uData && this.props.uData.lastNotifications && prevProps.uData.lastNotifications && this.props.uData.lastNotifications.length != prevProps.uData.lastNotifications.length) {

      this.setState({
        notifCount: this.props.uData.lastNotifications.length ? this.props.uData.lastNotifications.length : 0
      })
    }

    if (this.props.selectedGroup && this.props.uData?.clinicGroups?.length && this.props.selectedClinic && (prevProps.selectedGroup != this.props.selectedGroup || prevProps.selectedClinic != this.props.selectedClinic)) {
      for (let i = 0; i < this.props.uData.clinicGroups.length; i++) {
        if (this.props.uData.clinicGroups[i]._id == this.props.selectedGroup) {
          for (let j = 0; j < this.props.uData.clinicGroups[i]?.clinics?.length; j++) {
            if (this.props.uData.clinicGroups[i]?.clinics?.[j]?._id == this.props.selectedClinic) {
              this.setState({
                clinicName: this.props.uData.clinicGroups[i].clinics[j].name + ' ' + this.props.uData.clinicGroups[i].clinics[j].subgroupName
              })
            }
          }
        }
      }
    }
    if (this.props.uData && this.props.uData.patientGroups && this.props.uData.patientGroups.length === 1 && typeof window !== 'undefined' && (!localStorage?.getItem?.('groupAlias') || localStorage?.getItem?.('groupAlias') !== this.props.uData.patientGroups[0].alias)) {
      let isPatient = false;
      if (this.props?.uData?.userLevel && (this.props?.uData?.userLevel === 1 || this.props?.uData?.userLevel === 2 || (this.props?.uData?.userLevel === 20 && localStorage?.getItem?.('profile') && localStorage?.getItem?.('profile') === 'patient'))) isPatient = true;
      let group = this.props.uData.patientGroups[0];
      if (group && group.alias && isPatient) {
        this.setToLocalstorage(group.alias)
      }
    }


    // if ((this.props.uData && prevProps.uData && this.props.uData.lastNotifications && prevProps.uData.lastNotifications && this.props.uData.lastNotifications.findIndex(item => item.link.indexOf('chat') != -1 && !item.seen) != -1 && !this.state.newMessageCount && !this.state._checkingNewMessage) || (this.props.uData && prevProps.uData && this.props.uData.lastNotifications && prevProps.lastNotifications && this.props.uData.lastNotifications.length != prevProps.uData.lastNotifications.length)) {
    //   let notifindex = this.props.uData.lastNotifications.findIndex(item => item.link.indexOf('chat') != -1 && !item.seen)
    //   if (this.props.uData.userLevel < 20 && localStorage.getItem('groupAlias')) {
    //     let groupIndex = this.props.uData.patientGroups.findIndex(item => item.alias == localStorage.getItem('groupAlias'))
    //     if (this.props.uData.patientGroups && this.props.uData.patientGroups[groupIndex])
    //       this.checkNewMessageNotif(notifindex, this.props.uData.patientGroups[groupIndex]._id)
    //   } else if (this.props.selectedGroup) {
    //     this.checkNewMessageNotif(notifindex, this.props.selectedGroup)
    //   } else {
    //     this.checkNewMessageNotif(notifindex)
    //   }

    // }

    if (this.props.activeVideoCallInWaitingRoom && prevProps.activeVideoCallInWaitingRoom !== this.props.activeVideoCallInWaitingRoom) {
      this.blinkInterval = setInterval(() => {
        this.setState({ blink: !this.state.blink })
      }, 800)
    }

    if (!this.props.activeVideoCallInWaitingRoom && prevProps.activeVideoCallInWaitingRoom !== this.props.activeVideoCallInWaitingRoom) {
      if (this.blinkInterval) {
        clearInterval(this.blinkInterval)
      }
      this.setState({ blink: false })
    }

  }
  check = () => {
    fetch(API_ENDPOINT + '/notification/last', {
      method: 'POST',
      headers: {
        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
        'content-type': 'application/json'
      },

    }).then(res => res.json()).then((result) => {

      if (result) {
        if (localStorage.getItem('notif') != result?._id) {
          this.props.verifyUser()
          this.setState({
            newNotif: true
          })

        } else {
          this.setState({
            newNotif: false
          })
        }

      }

    })


  }
  checkNewMessageNotif = () => {
    let profile = (typeof window !== 'undefined' && localStorage && localStorage.getItem('profile')) ? localStorage.getItem('profile') : null;
    if (!profile) return;

    let groupId;
    let groupAlias;
    if (this.props.uData && this.props.uData?.userLevel && this.props.uData?.userLevel === 20 && (typeof window !== 'undefined' && localStorage?.getItem?.('profile') && localStorage?.getItem?.('profile') !== 'patient') && this.props.selectedGroup) groupId = this.props.selectedGroup;
    else if (this.props.uData && this.props.uData?.userLevel && [1, 2].includes(this.props.uData?.userLevel) || (this.props.uData?.userLevel === 20 && typeof window !== 'undefined' && localStorage?.getItem?.('profile') && localStorage?.getItem?.('profile') === 'patient')) groupAlias = localStorage?.getItem('groupAlias')
    this.setState({
      _checkingNewMessage: true
    }, () => {
      fetch(API_ENDPOINT + '/message/chat/last', {
        method: 'POST',
        headers: {
          'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
          'content-type': 'application/json'
        },
        body: JSON.stringify({
          groupId,
          groupAlias,
          profile,
        })
      }).then(res => res.json()).then((result) => {
        if (result.error) this.setState({ _checkingNewMessage: false, newMessageCount: false });
        else this.setState({ _checkingNewMessage: false, newMessageCount: true })
      }).catch((err) => {
        console.error(err);
        this.setState({
          _checkingNewMessage: false,
          newMessageCount: false,
        })

      })
    })
  }
  registerEvents = async () => {
    // this.props.socket.on('newMessage', (data) => {
    //   if (this.props.selectedGroup)
    //     this.checkNewMessageNotif('refresh', this.props.selectedGroup)

    // })
    this.props.socket.on('triggerFetch', (data) => {
      this.checkNewMessageNotif()
    })

    // this.props.socket.on('activeVideoCalls', (data) => this.onActiveVideoCalls(data)
    //   {
    //   let foundVideoCall = false;
    //   if (data.myVideoCallsDisconnected && Object.keys(data.myVideoCallsDisconnected).length > 0) {

    //     this.props.setActiveVideoCallInWaitingRoom(true)
    //   }
    //   foundVideoCall = true;

    //   if (!foundVideoCall) {
    //     this.props.setActiveVideoCallInWaitingRoom(false)
    //   }


    // }
    // );
    // this.props.socket.emit('activeVideoCalls', {})

  }
  componentDidMount() {

    this.checkNewMessageNotif();

    if (this.props.uData && this.props.socket && !this.state._registerEvents) {
      this.setState({
        _registerEvents: true
      }, () => {
        this.registerEvents();


      })
    } else {
      this.socketInterval = setInterval(() => {
        if (this.props.uData && this.props.socket && !this.state._registerEvents) {
          if (this.socketInterval) {
            clearInterval(this.socketInterval)
          }

          this.setState({
            _registerEvents: true
          }, () => {
            this.registerEvents();


          })
        }

      }, 150);
    }
    // console.log(this.props.sidebarScrollTop)

    // if (this.props.uData?.userLevel === 20 && (typeof window !== 'undefined' && localStorage?.getItem?.('profile') !== 'patient') && this.props.selectedGroup) {
    //   this.checkNewMessageNotif(this.props.selectedGroup, this.props.selectedGroup);
    // } else if ([1,2].includes(this.props.uData?.userLevel) || this.props.uData.userLevel === 20 && (typeof window !== 'undefined' && localStorage?.getItem?.('profile') === 'patient') && localStorage?.groupAlias) {
    //   let groupIndex = this.props.uData?.patientGroups?.findIndex(item => item.alias === localStorage.groupAlias);
    //   if (groupIndex && this.props.uData?.patientGroups && this.props.uData?.patientGroups[groupIndex]) {
    //     this.checkNewMessageNotif(this.props.uData.patientGroups[groupIndex]._id, this.props.uData.patientGroups[groupIndex]._id);
    //   }
    // }
    this.sidebarNodeTs = new Date().getTime();

    if (this.props.sidebarScrollTop && this.sidebarNode) {
      if (window.location.href == `${window.location.origin}/` || window.location.href == `${window.location.origin}/my-data/account`) {
        this.sidebarNode.scrollTop = 0
      } else {

        this.sidebarNode.scrollTop = this.props.sidebarScrollTop;
      }


    } else {
      // console.log(this.linkRefs)
    }
    // console.log('mount')

    // if(localStorage?.getItem?.('profile')){
    //   this.props.changeProfile(localStorage.getItem('profile'))
    // }
    /*for(let i=0; i<this.ref.length;i++){
      if(ref[i] == this.props.pathname){
      ref[i].scrollIntoView({
      behavior: 'auto',
      block: 'start',
     });}
    }*/



    if (this.props.selectedGroup && this.props.uData?.clinicGroups?.length && this.props.selectedClinic) {
      for (let i = 0; i < this.props.uData.clinicGroups.length; i++) {
        if (this.props.uData.clinicGroups[i]._id == this.props.selectedGroup) {
          for (let j = 0; j < this.props.uData.clinicGroups[i]?.clinics?.length; j++) {
            if (this.props.uData.clinicGroups[i].clinics?.[j]?._id == this.props.selectedClinic) {
              this.setState({
                clinicName: this.props.uData.clinicGroups[i].clinics[j].name + ' ' + this.props.uData.clinicGroups[i].clinics[j].subgroupName
              })
            }
          }
        }
      }
    }

    if (this.props.uData && this.props.uData.unpaidCount) {
      this.setState({ unpaidCount: this.props.uData.unpaidCount })
    }
    if (this.props.uData && this.props.uData.invoiceRequested) {
      this.setState({ invoicesRequested: this.props.uData.invoiceRequested })
    } else {
      this.setState({ invoicesRequested: 0 })
    }



    if (this.props.uData && this.props.uData.lastNotifications) {

      this.setState({
        notifCount: this.props.uData.lastNotifications.length ? this.props.uData.lastNotifications.length : 0
      })
    }


    window.addEventListener('resize', this.handleResize)
    document.addEventListener('mousemove', this.checkMoseMove)

    if (this.props.activeVideoCallInWaitingRoom) {
      this.blinkInterval = setInterval(() => {
        this.setState({ blink: !this.state.blink })
      }, 800)
    }


  }
  handleResize = () => {
    this.setState({ windowSizeChanged: uuidv4(), W: typeof window !== 'undefined' ? window.innerWidth : null });
  }

  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval)
    }
    if (this.sidebarWideTimeout) {
      clearTimeout(this.sidebarWideTimeout)
    }
    if (this.blinkInterval) {
      clearInterval(this.blinkInterval)
    }

    document.removeEventListener('mousemove', this.checkMoseMove);
    document.removeEventListener('resize', this.handleResize);
    if (this.props.socket) {
      // this.props.socket.removeListener('newMessage');
      this.props.socket.removeListener('triggerFetch')
      // this.props.socket.removeListener('activeVideoCalls')
      // this.props.socket.removeListener('activeVideoCalls', this.onActiveVideoCalls);
    }


  }

  // onActiveVideoCalls = (data) => {

  //   let foundVideoCall = false;
  //   if (data.myVideoCallsDisconnected && Object.keys(data.myVideoCallsDisconnected).length > 0) {

  //     this.props.setActiveVideoCallInWaitingRoom(true)
  //   }
  //   foundVideoCall = true;

  //   if (!foundVideoCall) {
  //     this.props.setActiveVideoCallInWaitingRoom(false)
  //   }



  // }

  setToLocalstorage = (alias) => {
    localStorage.setItem('groupAlias', alias)

    if (alias == -1) {
      localStorage.removeItem('groupAlias')
      this.props[0].history.push('/home-patient-level')
    } else {
      for (let i = 0; i < this.props.uData.patientGroups.length; i++) {
        if (alias == this.props.uData.patientGroups[i].alias) {
          this.checkNewMessageNotif()
          this.props.verifyUser()
          this.props[0].history.push(`/${alias}`)
        }
      }
    }

  }

  toggleSidebar = (check = null) => {
    if (this.sidebarWideTimeout) {
      clearTimeout(this.sidebarWideTimeout)
    }
    if ((typeof window != 'undefined' && window.innerWidth < 1199) || !this.props.autoHideSidebar) {
      if (this.props.toggleSidebar) {
        this.props.toggleSidebar(!this.props.sidebarWide)
      }

      this.sidebarWide = !this.props.sidebarWide;
      return;
    }
    let value = check == 'leave' ? true : check == 'enter' ? false : !this.sidebarWide;


    this.sidebarWide = value;


    if (this.sidebarWide) {
      this.sidebarWideTimeout = setTimeout(() => {
        if (this.props.sidebarWide != this.sidebarWide) {
          if (this.props.toggleSidebar) {
            this.props.toggleSidebar(this.sidebarWide)
          }

        }

      }, 200);
    } else {
      if (this.props.sidebarWide != this.sidebarWide) {
        if (this.props.toggleSidebar) {
          this.props.toggleSidebar(this.sidebarWide)
        }

      }

    }



  }

  checkMoseMove = (event) => {
    if (this.props.autoHideSidebar) {
      if (typeof window != 'undefined' && window.innerWidth >= 1199) {
        if (this.sidebarRef && this.sidebarRef.current && this.sidebarRef.current.contains(event.target)) {
          if (this.props.sidebarWide) {
            this.toggleSidebar('enter')
          }
        }
      }
    }

  }

  render() {
    // console.log(this.sidebarNode?.scrollTop, this.props.sidebarScrollTop)
    // let doctorPermission;
    // if (this.props.uData && this.props.uData.clinicModules && this.props.uData.clinicModules.length) {
    //   for (let i = 0; i < this.props.uData.clinicModules.length; i++) {
    //     if (this.props.uData.clinicModules[i].name == "Vårdutövare") {
    //       doctorPermission = this.props.uData.clinicModules[i]._id;
    //     }
    //   }
    // }

    let isDoctor = false;
    if (this.props.uData.groupPermissionsUser && this.props.uData.groupPermissionsUser[this.props.selectedGroup] && this.props.uData.groupPermissionsUser[this.props.selectedGroup][this.props.selectedClinic] && Object.keys(this.props.uData.groupPermissionsUser[this.props.selectedGroup][this.props.selectedClinic]).length) {
      isDoctor = true;
    }

    let isPatient = false;
    if (this.props?.uData?.userLevel && (this.props?.uData?.userLevel === 1 || this.props?.uData?.userLevel === 2 || (this.props?.uData?.userLevel === 20 && localStorage?.getItem('profile') && localStorage?.getItem('profile') === 'patient'))) isPatient = true;

    let selectedGroupIdx = 0;
    if (this.props.selectedGroup && this.props.uData?.clinicGroups?.length) {
      for (let i = 0; i < this.props.uData.clinicGroups.length; i++) {
        if (this.props.uData.clinicGroups[i]._id == this.props.selectedGroup) {
          selectedGroupIdx = i;
          break;
        }
      }
    }

    if (this.props.uData && this.props.uData.userLevel == 1) {
      return null;
    }

    if (this.props[0].match.path == '/chat/:windowConversation') {
      return null;
    }

    if (this.props[0].match.path == '/my-data/clinic-data/landing-configurator/:id/:tab/:group/:type')
      return null;
    if (this.props[0].match.path == '/data-managment/landing-configurator/:tab/:id/:type')
      return null;
    if (this.props[0].match.path == '/video-call/:conversation')
      return null;


    let patientGroupName = 'Choose clinic'.translate(this.props.lang);
    if (this.props.uData.patientGroups && this.props.uData.patientGroups.length) {
      let check = false
      for (let i = 0; i < this.props.uData.patientGroups.length; i++) {
        if (localStorage.groupAlias && localStorage.getItem('groupAlias') == this.props.uData.patientGroups[i].alias) {
          patientGroupName = this.props.uData.patientGroups[i].name
          break;
        }
      }

    }

    let isMobile = typeof window != 'undefined' && window.innerWidth < 768;
    let isTablet = typeof window != 'undefined' && window.innerWidth < 1200;

    if (this.props.hideSidebar) {
      return null
    }

    return (
      <div ref={this.sidebarRef} className={this.props.sidebarWide ? 'sidebar active' : 'sidebar'} style={typeof window != 'undefined' && iosDevice() ? { maxWidth: `${window.innerWidth}px` } : {}}
        onMouseEnter={(e) => {
          if (this.props.autoHideSidebar) {
            if (this.toggleSidebar && typeof window != 'undefined' && window.innerWidth >= 1199) {
              this.toggleSidebar('enter')
            }
          }

        }}
        onMouseLeave={(e) => {
          if (this.props.autoHideSidebar) {

            if (this.toggleSidebar && typeof window != 'undefined' && window.innerWidth >= 1199) {
              this.toggleSidebar('leave')
            }
          }
        }}
      >
        {this.props.uData.userLevel <= 20 ?
          <div onClick={() => {
            if (!this.props.autoHideSidebar) {
              this.toggleSidebar()
            }
          }} className='sidebar-cta' style={!this.props.autoHideSidebar ? { cursor: 'pointer' } : {}}>
            <img src={arrowSidebar} />
          </div>
          : null}
        <div className="sidebar-container">
          <div className='sidebar-content'>
            {this.props.uData.userLevel > 20 || (typeof window != 'undefined' && window.innerWidth < 1200) ?
              <div className='sidebar-header'>

                <div className="logo">
                  {
                    this.props.uData.userLevel > 20 || (typeof window != 'undefined' && window.innerWidth > 1024) ?
                      <Link check={this.props.groupSettingsModal} openModal={(item) => this.props.openModal(item)} to={this.props.modulesTree && this.props.modulesTree[0] ? this.props.modulesTree[0].link : '/'} currentPathname={this.props[0].location.pathname}  >
                        <Isvg src={logoBig} className='logo' />

                      </Link>
                      : null
                  }
                </div>


                <Link check={this.props.groupSettingsModal} openModal={(item) => this.props.openModal(item)} to={this.props.modulesTree && this.props.modulesTree[0] ? this.props.modulesTree[0].link : '/'} currentPathname={this.props[0].location.pathname}  >
                  <img src={logoMobile} className='logo-small' />
                </Link>

                <Isvg
                  src={menuIcon}
                  className='menu-icon'
                  onClick={this.toggleSidebar}
                />
                <div className="sidebar-profile">
                  {this.state.W && this.state.W < 1200 && isPatient && this.props[0].location.pathname !== '/waiting-room' ?
                    <Link check={this.props.groupSettingsModal} openModal={(item) => this.props.openModal(item)} to='/waiting-room' currentPathname={this.props[0].location.pathname} >
                      <div
                        className={this.props.activeVideoCallInWaitingRoom && this.state.blink ? 'sidebar-profile-div video-call-active-blinking padding-5' : 'sidebar-profile-div padding-5'}
                        onClick={() => { this.props.forceSidebarWideToFalse(); }}
                      >
                        {/* <WaitingRoom className={this.props.activeVideoCallInWaitingRoom && this.state.blink ? 'red-sidebar-icon sidebar-profile-waiting-room-icon' : 'header-icon sidebar-profile-waiting-room-icon'} width={30} height={30} title={'Waiting room'.translate(this.props.lang)} /> */}
                        <WaitingRoom className={'header-icon sidebar-profile-waiting-room-icon'} width={30} height={30} title={'Waiting room'.translate(this.props.lang)} />
                      </div>
                    </Link>
                    : null}
                  {/* {this.state.W && this.state.W < 1200 && isPatient && this.props[0].location.pathname !== '/waiting-room' ? <div
                    className={this.props.activeVideoCallInWaitingRoom && this.state.blink ? 'sidebar-profile-div video-call-active-blinking padding-5' : 'sidebar-profile-div padding-5'}
                    onClick={() => { this.props.forceSidebarWideToFalse(); this.props[0]?.history?.push('/waiting-room') }}
                  >
                    <WaitingRoom className={'header-icon sidebar-profile-waiting-room-icon'} width={30} height={30} title={'Waiting room'.translate(this.props.lang)} />
                  </div> : null} */}
                  {this.state.W && this.state.W < 1200 && isPatient ?
                    <Link check={this.props.groupSettingsModal} openModal={(item) => this.props.openModal(item)} to='/chat' currentPathname={this.props[0].location.pathname} >

                      <div
                        className={this.state.newMessageCount ? 'sidebar-profile-dot-chat sidebar-profile-div' : 'sidebar-profile-div'}
                        onClick={() => { this.props.forceSidebarWideToFalse(); }}
                      >
                        <Chat className='sidebar-profile-chat-icon' width={30} height={30} title={'Chat'.translate(this.props.lang)} />
                      </div>
                    </Link>

                    : null}
                  {/* {this.state.W && this.state.W < 1200 && isPatient ? <div
                    className={this.state.newMessageCount ? 'sidebar-profile-dot-chat sidebar-profile-div' : 'sidebar-profile-div'}
                    onClick={() => { this.props.forceSidebarWideToFalse(); this.props[0]?.history?.push('/chat') }}
                  >
                    <Chat className='sidebar-profile-chat-icon' width={30} height={30} title={'Chat'.translate(this.props.lang)} />
                  </div> : null} */}

                  {this.state.W && this.state.W < 1200 && isPatient ?
                    <Link check={this.props.groupSettingsModal} openModal={(item) => this.props.openModal(item)} to='/notifications' currentPathname={this.props[0].location.pathname} >

                      <div
                        className={this.state.notifCount && this.state.notifCount > 0 ? ' sidebar-profile-dot-notif sidebar-profile-div' : 'sidebar-profile-div'}
                        onClick={() => { this.props.forceSidebarWideToFalse(); }}
                      >
                        <Notification className='sidebar-profile-notif-icon' width={36} height={36} title={'Notification'.translate(this.props.lang)} />
                      </div>
                    </Link>
                    :
                    null}
                  {/* {this.state.W && this.state.W < 1200 && isPatient ?
                    <div
                      className={this.state.notifCount && this.state.notifCount > 0 ? ' sidebar-profile-dot-notif sidebar-profile-div' : 'sidebar-profile-div'}
                      onClick={() => { this.props.forceSidebarWideToFalse(); this.props[0]?.history?.push('/notifications') }}
                    >
                      <Notification className='sidebar-profile-notif-icon' width={36} height={36} title={'Notification'.translate(this.props.lang)} />
                    </div>
                    :
                    null} */}
                  <Link check={this.props.groupSettingsModal} openModal={(item) => this.props.openModal(item)} to='/my-data/account' currentPathname={this.props[0].location.pathname}  >
                    <img onClick={this.props.forceSidebarWideToFalse} src={this.props.uData && this.props.uData.profilePicture ? API_ENDPOINT + this.props.uData.profilePicture : profileUser} className="profile-image" />
                  </Link>
                </div>
              </div>
              :
              null
            }

            {/* {this.props.uData && this.props.uData.userLevel == 20 && isDoctor && this.props.uData.havePersonalReferrals ?
              <>
                <FormGroup className="switch-account" >
                  <Label size="sm">{'Select profile'.translate(this.props.lang)}</Label>
                  <Input value={this.props.selectedProfile} onChange={(e) => {
                    this.props.changeProfile(e.target.value);
                    this.props[0].history.push("/my-data/account")
                  }} size="sm" type="select">
                    <option value={'doctor'}>{"Doctor".translate(this.props.lang)}</option>
                    <option value={'patient'}>{"Patient".translate(this.props.lang)}</option>
                  </Input>
                </FormGroup>

              </>

              :
              null
            } */}

            <div className='sidebar-body' ref={(node) => this.sidebarNode = node} onScroll={() => {
              if (this.sidebarNodeTs && new Date().getTime() - this.sidebarNodeTs > 500)
                this.props.updateSidebarScrollTop(this.sidebarNode.scrollTop)
            }}>
              {/* {this.props.uData && this.props.uData.userLevel == 20 && isDoctor && this.props.uData.havePersonalReferrals ?
              <>
                <FormGroup className="switch-account-mobile" >
                  <Label size="sm">{'Select profile'.translate(this.props.lang)}</Label>
                  <Input value={this.props.selectedProfile} onChange={(e) => {
                    this.props.changeProfile(e.target.value);
                    this.props[0].history.push("/my-data/account")
                  }} size="sm" type="select">
                    <option value={'doctor'}>{"Doctor".translate(this.props.lang)}</option>
                    <option value={'patient'}>{"Patient".translate(this.props.lang)}</option>
                  </Input>
                </FormGroup>


              </>

              :
              null
            } */}
              {this.props.uData && this.props.uData.clinicGroups && this.props.uData.clinicGroups.length ?
                this.props.uData && this.props.uData.userLevel == 20 && isDoctor ?
                  typeof localStorage !== 'undefined' && localStorage.getItem('profile') == 'doctor' ?
                    <>
                      <FormGroup className="switch-account" style={{ marginBottom: 0 }} >

                        <div style={{ cursor: 'pointer' }} onClick={() => {
                          this.setState({
                            showSelectGroup: !this.state.showSelectGroup,
                          }, () => {
                            if (this.state.showSelectGroup) {
                              this.setState({
                                showSelectClinic: false
                              })
                            }
                          })
                        }} className={this.state.showSelectGroup ? 'selecet-group-activ' : 'selecet-group-field'}>
                          <p
                            className={"search-field-show"}
                            disabled
                          >
                            {this.props.uData && this.props.uData.clinicGroups && this.props.uData.clinicGroups && this.props.uData.clinicGroups[selectedGroupIdx] && this.props.uData.clinicGroups[selectedGroupIdx].name}
                            <img src={selectArrow} />
                          </p>
                          <div className={this.state.showSelectGroup ? "search-users-results-wrap" : 'show-none'}>
                            {
                              this.props.uData?.clinicGroups && this.props.uData.clinicGroups.length ?
                                <>
                                  {
                                    this.props.uData.clinicGroups.map((item, idx) => {
                                      return (
                                        <div style={{ cursor: 'pointer' }} onClick={() => {
                                          if (this.props.groupSettingsModal) {
                                            this.props.openModal('group|' + item?._id)
                                          } else {
                                            this.props.changeGroup(item?._id)
                                          }


                                        }} value={item?._id}>{item?.name}</div>
                                      )
                                    })
                                  }
                                </>
                                :
                                null
                            }

                          </div>
                        </div>


                      </FormGroup>
                      <FormGroup className="switch-account-1"  >

                        <div style={{ cursor: 'pointer' }} onClick={() => {
                          this.setState({
                            showSelectClinic: !this.state.showSelectClinic
                          }, () => {
                            if (this.state.showSelectClinic) {
                              this.setState({
                                showSelectGroup: false
                              })
                            }
                          })
                        }} className={this.state.showSelectClinic ? 'selecet-clinic-activ' : 'selecet-clinic-field'}>
                          <p
                            className={"select-clinic-field-show"}
                            disabled
                          >{this.state.clinicName}
                            <img src={selectArrow} />
                          </p>
                          <div className={this.state.showSelectClinic ? "dropdown-clinic-results" : 'show-none'}>
                            {
                              this.props.uData?.clinicGroups && this.props.uData.clinicGroups.length ?
                                <>
                                  {
                                    this.props.uData.clinicGroups[selectedGroupIdx].clinics.map((item, idx) => {
                                      return (
                                        <div style={{ cursor: 'pointer' }} onClick={(e) => {
                                          if (this.props.groupSettingsModal) {
                                            this.props.openModal('clinic|' + item?._id)
                                          } else {
                                            this.props.changeClinic(item?._id);
                                          }

                                        }} value={item?._id}>{item?.name} - {item?.subgroupName}</div>
                                      )
                                    })
                                  }
                                </>
                                :
                                null
                            }

                          </div>
                        </div>

                      </FormGroup>
                    </>
                    :
                    <>
                      {
                        this.props.uData.patientGroups && this.props.uData.patientGroups.length > 1 ?
                          <FormGroup className="switch-account">

                            <div style={{ cursor: 'pointer' }} onClick={() => {
                              this.setState({
                                showSelectGroup: !this.state.showSelectGroup,
                              })
                            }} className={this.state.showSelectGroup ? 'selecet-group-activ' : 'selecet-group-field'}>
                              <p
                                className={"search-field-show"}
                                disabled
                              >
                                {patientGroupName}
                                <img src={selectArrow} />
                              </p>
                              <div className={this.state.showSelectGroup ? "search-users-results-wrap" : 'show-none'}>
                                {
                                  this.props.uData.patientGroups && this.props.uData.patientGroups.length ?
                                    <>
                                      <div style={{ cursor: 'pointer' }} onClick={(e) => {
                                        this.setToLocalstorage(-1)

                                      }} value={-1}>{'Choose clinic'.translate(this.props.lang)}</div>
                                      {
                                        this.props.uData.patientGroups.map((item, idx) => {

                                          return (
                                            <div style={{ cursor: 'pointer' }} onClick={(e) => {

                                              this.setToLocalstorage(item.alias)

                                            }} value={item.alias}>{item.name}</div>
                                          )
                                        })
                                      }
                                    </>
                                    :
                                    null
                                }

                              </div>
                            </div>



                          </FormGroup>
                          :
                          <FormGroup className="switch-account" >

                            <div className='sidebar-group-field'>
                              <p >{patientGroupName} </p>

                            </div>

                          </FormGroup>
                      }
                    </>

                  :
                  <>
                    <FormGroup className="switch-account" >
                      <div style={{ cursor: 'pointer' }} onClick={() => {
                        this.setState({
                          showSelectGroup: !this.state.showSelectGroup,
                        }, () => {
                          if (this.state.showSelectGroup) {
                            this.setState({
                              showSelectClinic: false
                            })
                          }
                        })
                      }} className={this.state.showSelectGroup ? 'selecet-group-activ' : 'selecet-group-field'}>
                        <p
                          className={"search-field-show"}
                          disabled
                        >
                          {this.props.uData && this.props.uData.clinicGroups && this.props.uData.clinicGroups && this.props.uData.clinicGroups[selectedGroupIdx] && this.props.uData.clinicGroups[selectedGroupIdx].name}
                          <img src={selectArrow} />
                        </p>
                        <div className={this.state.showSelectGroup ? "search-users-results-wrap" : 'show-none'}>
                          {
                            this.props.uData?.clinicGroups && this.props.uData.clinicGroups.length ?
                              <>
                                {
                                  this.props.uData.clinicGroups.map((item, idx) => {
                                    return (
                                      <div style={{ cursor: 'pointer' }} onClick={() => {
                                        if (this.props.groupSettingsModal) {
                                          this.props.openModal('group|' + item?._id)
                                        } else {
                                          this.props.changeGroup(item?._id)
                                        }

                                      }} value={item?._id}>{item.name}</div>
                                    )
                                  })
                                }
                              </>
                              :
                              null
                          }

                        </div>
                      </div>

                    </FormGroup>
                    <FormGroup className="switch-account-1" >
                      <div style={{ cursor: 'pointer' }} onClick={() => {
                        this.setState({
                          showSelectClinic: !this.state.showSelectClinic
                        }, () => {
                          if (this.state.showSelectClinic) {
                            this.setState({
                              showSelectGroup: false
                            })
                          }
                        })
                      }} className={this.state.showSelectClinic ? 'selecet-clinic-activ' : 'selecet-clinic-field'}>
                        <p
                          className={"select-clinic-field-show"}
                          disabled
                        >{this.state.clinicName}
                          <img src={selectArrow} />
                        </p>
                        <div className={this.state.showSelectClinic ? "dropdown-clinic-results" : 'show-none'}>
                          {
                            this.props.uData?.clinicGroups && this.props.uData.clinicGroups.length ?
                              <>
                                {
                                  this.props.uData.clinicGroups[selectedGroupIdx].clinics.map((item, idx) => {
                                    return (
                                      <div style={{ cursor: 'pointer' }} onClick={(e) => {
                                        if (this.props.groupSettingsModal) {
                                          this.props.openModal('clinic|' + item?._id)
                                        } else {
                                          this.props.changeClinic(item?._id);
                                        }
                                      }} value={item?._id}>{item.name} - {item.subgroupName}</div>
                                    )
                                  })
                                }
                              </>
                              :
                              null
                          }

                        </div>
                      </div>
                    </FormGroup>
                  </>
                :
                // select za pacijenta level 1 i 2
                this.props.uData && this.props.uData.userLevel < 20 ?
                  <>
                    {
                      this.props.uData.patientGroups && this.props.uData.patientGroups.length > 1 || (typeof window !== 'undefined' && !localStorage?.getItem?.('groupAlias')) ?
                        <FormGroup className="switch-account" >

                          <div style={{ cursor: 'pointer' }} onClick={() => {
                            this.setState({
                              showSelectGroup: !this.state.showSelectGroup,
                            })
                          }} className={this.state.showSelectGroup ? 'selecet-group-activ' : 'selecet-group-field'}>
                            <p
                              className={"search-field-show"}
                              disabled
                            >
                              {patientGroupName}
                              <img src={selectArrow} />
                            </p>
                            <div className={this.state.showSelectGroup ? "search-users-results-wrap" : 'show-none'}>
                              {
                                this.props.uData.patientGroups && this.props.uData.patientGroups.length ?
                                  <>
                                    <div style={{ cursor: 'pointer' }} onClick={(e) => {
                                      this.setToLocalstorage(-1)

                                    }} value={-1}>{'Choose clinic'.translate(this.props.lang)}</div>
                                    {
                                      this.props.uData.patientGroups.map((item, idx) => {

                                        return (
                                          <div style={{ cursor: 'pointer' }} onClick={(e) => {

                                            this.setToLocalstorage(item.alias)



                                          }} value={item.alias}>{item.name}</div>
                                        )
                                      })
                                    }
                                  </>
                                  :
                                  null
                              }

                            </div>
                          </div>

                        </FormGroup>
                        :
                        <FormGroup className="switch-account" >

                          <div className='sidebar-group-field'>
                            <p >{patientGroupName} </p>

                          </div>

                        </FormGroup>
                    }
                  </>
                  : null
              }
              {this.props.uData && this.props.uData.clinicGroups && this.props.uData.clinicGroups.length ?
                this.props.uData && this.props.uData.userLevel == 20 && isDoctor ?
                  typeof localStorage !== 'undefined' && localStorage.getItem('profile') == 'doctor' ? <>
                    <FormGroup className="switch-account-mobile" >
                      <div style={{ cursor: 'pointer' }} onClick={() => {
                        this.setState({
                          showSelectGroup: !this.state.showSelectGroup,
                        }, () => {
                          if (this.state.showSelectGroup) {
                            this.setState({
                              showSelectClinic: false
                            })
                          }
                        })
                      }} className={this.state.showSelectGroup ? 'selecet-group-activ' : 'selecet-group-field'}>
                        <p
                          className={"search-field-show"}
                          disabled
                        >
                          {this.props.uData && this.props.uData.clinicGroups && this.props.uData.clinicGroups && this.props.uData.clinicGroups[selectedGroupIdx] && this.props.uData.clinicGroups[selectedGroupIdx].name}
                          <img src={selectArrow} />
                        </p>
                        <div className={this.state.showSelectGroup ? "search-users-results-wrap" : 'show-none'}>
                          {
                            this.props.uData?.clinicGroups && this.props.uData.clinicGroups.length ?
                              <>
                                {
                                  this.props.uData.clinicGroups.map((item, idx) => {
                                    return (
                                      <div style={{ cursor: 'pointer' }} onClick={() => {
                                        if (this.props.groupSettingsModal) {
                                          this.props.openModal('group|' + item?._id)
                                        } else {
                                          this.props.changeGroup(item?._id)
                                        }

                                      }} value={item?._id}>{item.name}</div>
                                    )
                                  })
                                }
                              </>
                              :
                              null
                          }

                        </div>
                      </div>
                    </FormGroup>
                    <FormGroup className="switch-account-1-mobile" >
                      <div style={{ cursor: 'pointer' }} onClick={() => {
                        this.setState({
                          showSelectClinic: !this.state.showSelectClinic
                        }, () => {
                          if (this.state.showSelectClinic) {
                            this.setState({
                              showSelectGroup: false
                            })
                          }
                        })
                      }} className={this.state.showSelectClinic ? 'selecet-clinic-activ' : 'selecet-clinic-field'}>
                        <p
                          className={"select-clinic-field-show"}
                          disabled
                        >{this.state.clinicName}
                          <img src={selectArrow} />
                        </p>
                        <div className={this.state.showSelectClinic ? "dropdown-clinic-results" : 'show-none'}>
                          {
                            this.props.uData?.clinicGroups && this.props.uData.clinicGroups.length ?
                              <>
                                {
                                  this.props.uData.clinicGroups[selectedGroupIdx].clinics.map((item, idx) => {
                                    return (
                                      <div style={{ cursor: 'pointer' }} onClick={(e) => {
                                        if (this.props.groupSettingsModal) {
                                          this.props.openModal('clinic|' + item?._id)
                                        } else {
                                          this.props.changeClinic(item?._id);
                                        }
                                      }} value={item?._id}>{item.name} - {item.subgroupName}</div>
                                    )
                                  })
                                }
                              </>
                              :
                              null
                          }

                        </div>
                      </div>

                    </FormGroup>
                  </>
                    :
                    <>
                      {
                        this.props.uData.patientGroups && this.props.uData.patientGroups.length > 1 || (typeof window !== 'undefined' && !localStorage?.getItem?.('groupAlias')) ?
                          <FormGroup className="switch-account-mobile" >


                            <div style={{ cursor: 'pointer' }} onClick={() => {
                              this.setState({
                                showSelectGroup: !this.state.showSelectGroup,
                              })
                            }} className={this.state.showSelectGroup ? 'selecet-group-activ' : 'selecet-group-field'}>
                              <p
                                className={"search-field-show"}
                                disabled
                              >
                                {patientGroupName}
                                <img src={selectArrow} />
                              </p>
                              <div className={this.state.showSelectGroup ? "search-users-results-wrap" : 'show-none'}>
                                {
                                  this.props.uData.patientGroups && this.props.uData.patientGroups.length ?
                                    <>
                                      <div style={{ cursor: 'pointer' }} onClick={(e) => {
                                        this.setToLocalstorage(-1)

                                      }} value={-1}>{'Choose clinic'.translate(this.props.lang)}</div>
                                      {
                                        this.props.uData.patientGroups.map((item, idx) => {

                                          return (
                                            <div style={{ cursor: 'pointer' }} onClick={(e) => {

                                              this.setToLocalstorage(item.alias)



                                            }} value={item.alias}>{item.name}</div>
                                          )
                                        })
                                      }
                                    </>
                                    :
                                    null
                                }

                              </div>
                            </div>


                          </FormGroup>
                          :
                          <FormGroup className="switch-account-mobile" >

                            <div className='sidebar-group-field'>
                              <p >{patientGroupName} </p>
                            </div>
                          </FormGroup>

                      }

                    </>
                  :
                  <>
                    <FormGroup className="switch-account-mobile" >
                      <div style={{ cursor: 'pointer' }} onClick={() => {
                        this.setState({
                          showSelectGroup: !this.state.showSelectGroup,
                        }, () => {
                          if (this.state.showSelectGroup) {
                            this.setState({
                              showSelectClinic: false
                            })
                          }
                        })
                      }} className={this.state.showSelectGroup ? 'selecet-group-activ' : 'selecet-group-field'}>
                        <p
                          className={"search-field-show"}
                          disabled
                        >
                          {this.props.uData && this.props.uData.clinicGroups && this.props.uData.clinicGroups && this.props.uData.clinicGroups[selectedGroupIdx] && this.props.uData.clinicGroups[selectedGroupIdx].name}
                          <img src={selectArrow} />
                        </p>
                        <div className={this.state.showSelectGroup ? "search-users-results-wrap" : 'show-none'}>
                          {
                            this.props.uData?.clinicGroups && this.props.uData.clinicGroups.length ?
                              <>
                                {
                                  this.props.uData.clinicGroups.map((item, idx) => {
                                    return (
                                      <div style={{ cursor: 'pointer' }} onClick={() => {
                                        if (this.props.groupSettingsModal) {
                                          this.props.openModal('group|' + item?._id)
                                        } else {
                                          this.props.changeGroup(item?._id)
                                        }

                                      }} value={item?._id}>{item.name}</div>
                                    )
                                  })
                                }
                              </>
                              :
                              null
                          }

                        </div>
                      </div>
                    </FormGroup>
                    <FormGroup className="switch-account-1-mobile" >
                      <div style={{ cursor: 'pointer' }} onClick={() => {
                        this.setState({
                          showSelectClinic: !this.state.showSelectClinic
                        }, () => {
                          if (this.state.showSelectClinic) {
                            this.setState({
                              showSelectGroup: false
                            })
                          }
                        })
                      }} className={this.state.showSelectClinic ? 'selecet-clinic-activ' : 'selecet-clinic-field'}>
                        <p
                          className={"select-clinic-field-show"}
                          disabled
                        >{this.state.clinicName}
                          <img src={selectArrow} />
                        </p>
                        <div className={this.state.showSelectClinic ? "dropdown-clinic-results" : 'show-none'}>
                          {
                            this.props.uData?.clinicGroups && this.props.uData.clinicGroups.length && this.props.uData.clinicGroups[selectedGroupIdx]?.clinics?.length ?
                              <>
                                {
                                  this.props.uData.clinicGroups[selectedGroupIdx]?.clinics?.map((item, idx) => {
                                    return (
                                      <div style={{ cursor: 'pointer' }} onClick={(e) => {
                                        if (this.props.groupSettingsModal) {
                                          this.props.openModal('clinic|' + item?._id)
                                        } else {
                                          this.props.changeClinic(item?._id);
                                        }
                                      }} value={item?._id}>{item.name} - {item.subgroupName}</div>
                                    )
                                  })
                                }
                              </>
                              :
                              null
                          }

                        </div>
                      </div>

                    </FormGroup>
                  </>
                :
                <>
                  {
                    this.props.uData.patientGroups && this.props.uData.patientGroups.length > 1 || (typeof window !== 'undefined' && !localStorage?.getItem?.('groupAlias')) ?
                      <FormGroup className="switch-account-mobile" >


                        <div style={{ cursor: 'pointer' }} onClick={() => {
                          this.setState({
                            showSelectGroup: !this.state.showSelectGroup,
                          })
                        }} className={this.state.showSelectGroup ? 'selecet-group-activ' : 'selecet-group-field'}>
                          <p
                            className={"search-field-show"}
                            disabled
                          >
                            {patientGroupName}
                            <img src={selectArrow} />
                          </p>
                          <div className={this.state.showSelectGroup ? "search-users-results-wrap" : 'show-none'}>
                            {
                              this.props.uData.patientGroups && this.props.uData.patientGroups.length ?
                                <>
                                  <div style={{ cursor: 'pointer' }} onClick={(e) => {
                                    this.setToLocalstorage(-1)

                                  }} value={-1}>{'Choose clinic'.translate(this.props.lang)}</div>
                                  {
                                    this.props.uData.patientGroups.map((item, idx) => {

                                      return (
                                        <div style={{ cursor: 'pointer' }} onClick={(e) => {

                                          this.setToLocalstorage(item.alias)



                                        }} value={item.alias}>{item.name}</div>
                                      )
                                    })
                                  }
                                </>
                                :
                                null
                            }

                          </div>
                        </div>


                      </FormGroup>
                      :
                      <FormGroup className="switch-account-mobile" >

                        <div className='sidebar-group-field'>
                          <p >{patientGroupName} </p>

                        </div>

                      </FormGroup>
                  }

                </>
              }
              <ul className='menu'>

                {
                  this.props.modulesTree?.map((item, idx) => {


                    let subpaths = [];
                    for (let i = 0; i < item.modules.length; i++) {
                      let sub = item.modules[i];


                      if ((!sub.clinicEntryModule || (sub.clinicEntryModule && this.props.uData && this.props.uData.groupPermissions && this.props.uData.groupPermissions[this.props.selectedGroup] && this.props.uData.groupPermissions[this.props.selectedGroup][this.props.selectedClinic] && this.props.uData.groupPermissions[this.props.selectedGroup][this.props.selectedClinic].indexOf(sub?._id) !== -1) &&
                        (!sub.clinicEntryModuleCondition ||
                          (
                            sub.clinicEntryModuleCondition &&
                            this.props.uData &&
                            this.props.uData.clinicGroups &&
                            this.props.uData.clinicGroups[selectedGroupIdx] &&
                            /*this.props.uData.clinicGroups[selectedGroupIdx][sub.clinicEntryModuleCondition]*/
                            this.clinicEntryModuleCondition(this.props.uData.clinicGroups[selectedGroupIdx], sub.clinicEntryModuleCondition)
                          )
                        )) && (!sub.uDataCondition || (sub.uDataCondition && this.props.uData && this.uDataCondition(sub.uDataCondition)))) {

                        if ((sub.link && sub.link.indexOf(':alias') !== -1 && localStorage.getItem('groupAlias')) || (sub.link && sub.link.indexOf(':alias') == -1)) {
                          subpaths.push(sub.link && sub.link.indexOf(':alias') !== -1 ? sub.link.replace(':alias', localStorage.getItem('groupAlias')) : sub.link);
                        }
                      }
                    }
                    if ((!item.clinicEntryModule ||
                      (
                        item.clinicEntryModule &&
                        this.props.uData &&
                        this.props.uData.groupPermissions &&
                        this.props.uData.groupPermissions[this.props.selectedGroup] &&
                        this.props.uData.groupPermissions[this.props.selectedGroup][this.props.selectedClinic] &&
                        this.props.uData.groupPermissions[this.props.selectedGroup][this.props.selectedClinic].indexOf(item?._id) !== -1 &&
                        (!item.clinicEntryModuleCondition ||
                          (
                            item.clinicEntryModuleCondition &&
                            this.props.uData &&
                            this.props.uData.clinicGroups &&
                            this.props.uData.clinicGroups[selectedGroupIdx] &&
                            //this.props.uData.clinicGroups[selectedGroupIdx][item.clinicEntryModuleCondition]
                            this.clinicEntryModuleCondition(this.props.uData.clinicGroups[selectedGroupIdx], item.clinicEntryModuleCondition)
                          )
                        )
                      )) && (!item.uDataCondition || (item.uDataCondition && this.props.uData && this.uDataCondition(item.uDataCondition)))
                    )
                      if (item.link != '/user/manual')
                        return (
                          <div className={item.link === '/waiting-room' && item.link !== this.props[0].location.pathname && this.props.activeVideoCallInWaitingRoom && this.state.blink ? 'video-call-active-blinking' : ''} >
                            <li

                              onMouseEnter={(e) => {
                                this.setState({
                                  tooltip: {
                                    name: Object.translate(item, 'name', this.props.lang),
                                    top: e.target.getBoundingClientRect().top + e.target.getBoundingClientRect().height / 2 - 10,
                                    left: e.clientX
                                  }
                                })
                              }} onMouseLeave={() => {
                                this.setState({
                                  tooltip: null
                                })

                              }}

                              style={item.newSection ? { marginBottom: 40 } : {}} key={item.id} ref={(node) => this.linkRefs[subpaths.length ? subpaths[0] : item.link] = node}
                              className={item.link === '/waiting-room' && item.link !== this.props[0].location.pathname && this.props.activeVideoCallInWaitingRoom && this.state.blink ? 'menu__item video-call-active-blinking' : (((subpaths.length != 0 && subpaths.indexOf(this.props[0].location.pathname) !== -1) || (subpaths.length == 0 && item.link == this.props[0].location.pathname)) && !isTablet) || (this.state.selectedMobileItems[item?._id] && isTablet) || (item.link == this.props[0].location.pathname && isTablet) || (this.state.openDropdown && this.state.openDropdown == idx) ? 'menu__item active' : 'menu__item'}>

                              {subpaths && subpaths.length ?
                                <a check={this.props.groupSettingsModal} openModal={(item) => this.props.openModal(item)}
                                  className='menu__link'
                                  onClick={(e) => {
                                    this.props.registerLog(`Open module: ${item.name.en} / ${item.modules && item.modules[0] && item.modules[0].name ? item.modules[0].name.en : '-'}`);
                                    if (isTablet && subpaths.length != 0) {
                                      let selectedMobileItems = this.state.selectedMobileItems;
                                      selectedMobileItems[item?._id] = !selectedMobileItems[item?._id];
                                      this.setState({ selectedMobileItems });
                                      e.preventDefault();

                                      return false;
                                    }

                                    // if (isMobile /*&& item.link == this.props[0].location.pathname*/) {
                                    //   this.toggleSidebar()
                                    // }
                                    if (typeof window != 'undefined' && window.innerWidth < 1199) {
                                      this.toggleSidebar()
                                    }


                                    if (this.state.openDropdown && this.state.openDropdown == idx) {
                                      this.setState({
                                        openDropdown: !this.state.openDropdown
                                      })
                                    } else {
                                      if (!this.state.openDropdown && subpaths.indexOf(this.props[0].location.pathname) !== -1 && !isMobile) {
                                        this.setState({
                                          closeDropdown: !this.state.closeDropdown
                                        })
                                      } else {
                                        this.setState({
                                          openDropdown: idx,
                                          closeDropdown: true
                                        })
                                      }

                                    }

                                  }} >
                                  <span>

                                    {' '}
                                    {item.link == '/qa' ?
                                      <Isvg src={qaIcon} className='header-icon' />
                                      :
                                      <Isvg src={API_ENDPOINT + item.svgIcon} className='header-icon' />
                                    }
                                    {' '}
                                    {item.link == `/notifications` && this.state.notifCount > 0 || (this.props.uData.deletionRequests && ((item.name && item.name.en == 'Group settings') || item.link == '/my-data/deletion-requests') || (item.link === '/e-connect/created-cases' && (this.props.eConnect?.createdCount || this.props.eConnect?.receivedCount || this.props.consultationIndicators?.sidebar || this.props.eConnect?.responses?.length || this.props.eConnect?.monitoring?.length || this.props.eConnect?.chat?.received?.length || this.props.eConnect?.chat?.sent?.length || this.props.eConnect?.forwarding?.length))) ?
                                      <div className='notif-sidebar'></div>
                                      : null}
                                  </span>

                                  {
                                    item.link == `/notifications` && this.state.notifCount > 0 ?
                                      <span className="menu__text">{Object.translate(item, 'name', this.props.lang)}{' ' + '(' + this.state.notifCount + ')'}</span>
                                      :
                                      item.link == `/my-data/personal-referrals/my-payments` && this.state.unpaidCount && this.state.unpaidCount > 0 ?
                                        <span className="menu__text">{Object.translate(item, 'name', this.props.lang)}{' ' + '(' + this.state.unpaidCount + ')'}</span>
                                        :
                                        item.link == `/my-data/personal-referrals/payments` && this.state.invoicesRequested && this.state.invoicesRequested > 0 ?
                                          <span className="menu__text">{Object.translate(item, 'name', this.props.lang)}{' ' + '(' + this.state.invoicesRequested + ')'}</span>
                                          :
                                          <span className="menu__text">{Object.translate(item, 'name', this.props.lang)}</span>
                                  }


                                </a>
                                :
                                <Link
                                  onMouseEnter={(e) => {
                                    this.setState({
                                      tooltip: {
                                        name: Object.translate(item, 'name', this.props.lang),
                                        top: e.target.getBoundingClientRect().top + e.target.getBoundingClientRect().height / 2 - 10,
                                        left: e.clientX
                                      }
                                    })
                                  }} onMouseLeave={() => {
                                    this.setState({
                                      tooltip: null
                                    })

                                  }}
                                  className='menu__link'
                                  check={this.props.groupSettingsModal} openModal={(item) => this.props.openModal(item)} onClick={(e) => {
                                    this.props.registerLog(`Open module: ${item.name.en} / ${item.modules && item.modules[0] && item.modules[0].name ? item.modules[0].name.en : '-'}`);

                                    if (isTablet && subpaths.length != 0) {
                                      let selectedMobileItems = this.state.selectedMobileItems;
                                      selectedMobileItems[item?._id] = !selectedMobileItems[item?._id];
                                      this.setState({ selectedMobileItems });
                                      e.preventDefault();

                                      return false;
                                    }
                                    // if (isMobile/* && item.link == this.props[0].location.pathname*/) {
                                    //   this.toggleSidebar()
                                    // }
                                    if (typeof window != 'undefined' && window.innerWidth < 1199) {
                                      this.toggleSidebar()
                                    }

                                  }} to={subpaths.length ? subpaths[0] : item.link} currentPathname={this.props[0].location.pathname}  >
                                  <span >

                                    {' '}
                                    {item.link == '/qa' ?
                                      <Isvg src={qaIcon} className='header-icon' />
                                      :
                                      // item.link == '/waiting-room' ?
                                      //   <Isvg src={API_ENDPOINT + item.svgIcon} className={this.props.activeVideoCallInWaitingRoom && this.state.blink ? 'red-sidebar-icon' : 'header-icon'} />
                                      //   :
                                      <Isvg src={API_ENDPOINT + item.svgIcon} className='header-icon' />
                                    }

                                    {' '}
                                    {(item.link == `/notifications` && this.state.notifCount > 0) || (item.link == '/chat' && this.state.newMessageCount) || (this.props.uData.deletionRequests && ((item.name && item.name.en == 'Group settings') || item.link == '/my-data/deletion-requests') || (item.link === '/e-connect/created-cases' && (this.props.eConnect?.createdCount || this.props.eConnect?.receivedCount || this.props.consultationIndicators?.sidebar || this.props.eConnect?.responses?.length || this.props.eConnect?.monitoring?.length || this.props.eConnect?.chat?.received?.length || this.props.eConnect?.chat?.sent?.length || this.props.eConnect?.forwarding?.length))) ?
                                      <div className='notif-sidebar'></div>
                                      : null}
                                  </span>

                                  {
                                    item.link == `/notifications` && this.state.notifCount > 0 ?
                                      <span className="menu__text">{Object.translate(item, 'name', this.props.lang)}{' ' + '(' + this.state.notifCount + ')'}</span>
                                      :
                                      item.link == `/my-data/personal-referrals/my-payments` && this.state.unpaidCount && this.state.unpaidCount > 0 ?
                                        <span className="menu__text">{Object.translate(item, 'name', this.props.lang)}{' ' + '(' + this.state.unpaidCount + ')'}</span>
                                        :
                                        item.link == `/my-data/personal-referrals/payments` && this.state.invoicesRequested && this.state.invoicesRequested > 0 ?
                                          <span className="menu__text">{Object.translate(item, 'name', this.props.lang)}{' ' + '(' + this.state.invoicesRequested + ')'}</span>
                                          :
                                          <span className="menu__text">{Object.translate(item, 'name', this.props.lang)}</span>
                                  }

                                </Link>
                              }
                              {item.modules && item.modules.length ?
                                <ul className={(this.state.openDropdown && this.state.openDropdown == idx) || (!this.state.openDropdown && !this.state.closeDropdown && subpaths.indexOf(this.props[0].location.pathname) !== -1 && !isTablet) || (!this.state.openDropdown && !this.state.closeDropdown && this.state.selectedMobileItems[item?._id] && isTablet) ? 'dropdown show' : 'dropdown'}>
                                  {
                                    item.modules.map((sub, sidx) => {
                                      if ((!sub.clinicEntryModule || (sub.clinicEntryModule && this.props.uData && this.props.uData.groupPermissions && this.props.uData.groupPermissions[this.props.selectedGroup] && this.props.uData.groupPermissions[this.props.selectedGroup][this.props.selectedClinic] && this.props.uData.groupPermissions[this.props.selectedGroup][this.props.selectedClinic].indexOf(sub?._id) !== -1) &&
                                        (!sub.clinicEntryModuleCondition ||
                                          (
                                            sub.clinicEntryModuleCondition &&
                                            this.props.uData &&
                                            this.props.uData.clinicGroups &&
                                            this.props.uData.clinicGroups[selectedGroupIdx] &&
                                            //this.props.uData.clinicGroups[selectedGroupIdx][sub.clinicEntryModuleCondition]
                                            this.clinicEntryModuleCondition(this.props.uData.clinicGroups[selectedGroupIdx], sub.clinicEntryModuleCondition)
                                          )
                                        )) && (!sub.uDataCondition || (sub.uDataCondition && this.props.uData && this.uDataCondition(sub.uDataCondition)))) {

                                        if ((sub.link && sub.link.indexOf(':alias') !== -1 && localStorage.getItem('groupAlias')) || (sub.link && sub.link.indexOf(':alias') == -1))

                                          return (
                                            <li className={sub.link == this.props[0].location.pathname ? 'active' : ''} onClick={() => {
                                              // if (isMobile/* && sub.link == this.props[0].location.pathname*/) {
                                              //   this.toggleSidebar()
                                              // }
                                              if (typeof window != 'undefined' && window.innerWidth < 1199) {
                                                this.toggleSidebar()
                                              }



                                              this.props.registerLog(`Open module: ${item.name.en} / ${sub.name.en}`);

                                            }}> <Link
                                              onMouseEnter={(e) => {
                                                this.setState({
                                                  tooltip: {
                                                    name: Object.translate(sub, 'name', this.props.lang),
                                                    top: e.target.getBoundingClientRect().top + e.target.getBoundingClientRect().height / 2 - 10,
                                                    left: e.clientX
                                                  }
                                                })
                                              }} onMouseLeave={() => {
                                                this.setState({
                                                  tooltip: null
                                                })

                                              }}

                                              check={this.props.groupSettingsModal} openModal={(item) => this.props.openModal(item)} to={sub.link && sub.link.indexOf(':alias') !== -1 ? sub.link.replace(':alias', localStorage.getItem('groupAlias')) : sub.link}
                                              currentPathname={this.props[0].location.pathname}
                                            >
                                                <span>
                                                  {' '}
                                                  <Isvg src={API_ENDPOINT + sub.svgIcon} className='header-icon' />{' '}
                                                  {(this.props.uData.deletionRequests && sub.link == '/my-data/deletion-requests') || (this.props.uData.listRequests && sub.link == '/my-data/patient-list') || (sub.link === '/my-data/registration-requests' && this.props.hasRegistrationRequests) || ((this.props.eConnect?.createdCount || this.props.eConnect?.responses?.length || this.props.eConnect?.monitoring?.length || this.props.eConnect?.chat?.sent?.length) && sub.link === '/e-connect/created-cases') || ((this.props.eConnect?.receivedCount || this.props.eConnect?.chat?.received?.length || this.props.eConnect?.forwarding?.length) && sub.link === '/e-connect/received-cases') || (sub.link === '/e-connect/consultation' && this.props.consultationIndicators?.sidebar) ?
                                                    <div className='notif-sidebar'></div>
                                                    : null}
                                                </span>
                                                <span className="menu__text">{Object.translate(sub, 'name', this.props.lang)}</span>
                                              </Link> </li>

                                          )
                                      }
                                    })
                                  }

                                </ul>
                                :
                                null
                              }

                            </li>
                            {item.newSection ? <hr className='sidebar-line' /> : null}
                          </div>
                        )
                  })
                }


                <li className='menu__item'>
                  <div
                    onMouseEnter={(e) => {
                      this.setState({
                        tooltip: {
                          name: 'Logout'.translate(this.props.lang),
                          top: e.target.getBoundingClientRect().top + e.target.getBoundingClientRect().height / 2 - 10,
                          left: e.clientX
                        }
                      })
                    }} onMouseLeave={() => {
                      this.setState({
                        tooltip: null
                      })

                    }}

                    onClick={() => {
                      this.props.abortAction()
                      this.props.registerLog('Logged out');

                      this.props.signOut()


                    }} className='menu__link'>
                    <span>
                      {' '}
                      <Isvg src={logoutIcon} className='header-icon' />{' '}
                    </span>
                    <span className='menu__text' > {'Logout'.translate(this.props.lang)} </span>
                  </div>
                </li>
                {
                  this.props.uData && this.props.uData.userLevel == 20 && typeof localStorage !== 'undefined' && localStorage.getItem('profile') == 'doctor' ?
                    <div >
                      {<hr className='sidebar-line-about' />}

                      <div className="sidebar-doctor-footer-links">
                        <div onClick={() => {
                          this.setState({ aboutModal: true })
                        }}><h6>{'About'.translate(this.props.lang)}</h6></div>
                        <div onClick={() => {
                          this.props[0].history.push('/user/manual')
                          if (typeof window != 'undefined' && window.innerWidth < 1199) {
                            this.toggleSidebar()
                          }
                        }}>
                          <h6>
                            {
                              this.props.modulesTree && this.props.modulesTree.filter(item => item.link == '/user/manual').length ?
                                this.props.modulesTree && this.props.modulesTree.map((item, idx) => {
                                  if (item.link == '/user/manual') {
                                    return Object.translate(item, 'name', this.props.lang)
                                  }
                                })
                                :
                                'User manual'.translate(this.props.lang)
                            }</h6>
                        </div>

                      </div>
                    </div>
                    :
                    null
                }
              </ul>
              {
                this.props.uData && this.props.uData.userLevel <= 20 ?
                  <div className="sidebar-doctor-footer">

                    <div className="ce-icon">

                      <Isvg src={ceIcon} />
                    </div>
                  </div>
                  :
                  null
              }
              {
                typeof window != 'undefined' && window.innerWidth >= 1199 && !this.props.sidebarWide ?
                  <div className='auto-hide-sidebar-toggle'>
                    <Toggle value={this.props.autoHideSidebar} label={"Auto hide sidebar".translate(this.props.lang)} onChange={(e) => {
                      this.props.toggleAutoHide()
                    }} />
                  </div>
                  :
                  null
              }

            </div>

          </div>



          <img src={shapeBlue} className='shape-sidebar' />
        </div>


        {
          this.state.aboutModal ?

            <Modal isOpen={this.state.aboutModal} centered size="lg" className="sidebar-doctor-about-modal">
              {/* <ModalHeader style={{ margin: 'auto' }}>{'Error'.translate(this.props.lang)}</ModalHeader> */}
              <div style={{ position: 'relative' }}>
                <Isvg src={shapeBlue} className="shape-blue" />
                <Isvg src={shapeGreen} className="shape-green" />
                <div className="shape-yellow" >
                  <img src={shapeYellow} />
                </div>

              </div>
              <ModalBody className="sidebar-doctor-about-modal-body">
                <div className="sidebar-doctor-about-section">
                  {this.props.lang == 'se' ?
                    <p>
                      <span>
                        Tjänsten tillhandahålls av<br />
                        Curoflow Technology AB<br />
                        Org.nr: 559123-3654<br />
                      </span>

                    </p>
                    :
                    <p>
                      <span>
                        The service is provided by:<br />
                        Curoflow Technology AB<br />
                        Reg.nr: 559123-3654<br />
                      </span>

                    </p>
                  }

                  <p>
                    <span> Version:</span> v2.6.0.0<br />
                    <span> UDI:</span> (1)7350133890010(11)240816(8012)2.6.0.0
                  </p>
                </div>
                {this.props.lang == 'se' ?
                  <p>
                    Adress:<br />
                    Scheelegatan 28<br />
                    112 28 Stockholm<br />

                  </p>
                  :
                  <p>
                    Address:<br />
                    Scheelegatan 28<br />
                    112 28 Stockohlm<br />
                    Sweden<br />

                  </p>
                }
                {this.props.lang == 'se' ?
                  <p>
                    Curoflows plattform är en registrerad medicinteknisk produkt i enlighet med EU Förordningen om medicintekniska produkter (MDR) (EU) 2017/745. All data krypteras, lagras och behandlas enligt patientdatalagen (PDL) och EUs dataskyddsförordning (GDPR).
                  </p>
                  :
                  <p>
                    The Curoflow platform is registered as a medical device in accordance with the EU medical device regulation (MDR) (EU) 2017/745. All data is encrypted, stored and processed in accordance with the Swedish Patient Data Act and EU General Data Protection Regulation (GDPR).
                  </p>
                }
                <div className="sidebar-doctor-about-section">
                  {this.props.lang == 'se' ?
                    <p>
                      Kontakt telefon: <span>010-750 06 55</span><br />
                      Kontakt mejl: <a href='mailto:kontakt@curoflow.se'>kontakt@curoflow.se</a><br />
                      Hemsida: <a href="https://www.curoflow.se/" target="_blank">www.curoflow.se</a><br />

                    </p>
                    :
                    <p>
                      Phone: <span>+46 (0)10-750 06 55</span><br />
                      E-mail: <a href='mailto:kontakt@curoflow.se'>kontakt@curoflow.se</a><br />
                      Web page: <a href="https://www.curoflow.se/" target="_blank">www.curoflow.se</a><br />
                    </p>
                  }
                  <div className="sidebar-doctor-about-section2">
                    <div className="about-modal-ce">
                      <Isvg src={ceIcon} />
                    </div>
                    <div className="about-modal-md">
                      <Isvg src={mdIcon} />
                    </div>

                  </div>
                </div>


              </ModalBody>
              <ModalFooter style={{ display: 'flex', justifyContent: 'center' }}>
                <Button type="submit" color="primary" onClick={() => this.setState({ aboutModal: false })} >{'Close'.translate(this.props.lang)}</Button>{' '}
              </ModalFooter>
            </Modal>
            :
            null
        }
        {
          this.state.tooltip && this.props.sidebarWide ?
            <div className='sidebar-tooltip' style={{ position: 'absolute', top: this.state.tooltip.top, left: 110 }}>{this.state.tooltip.name}</div>
            :
            null
        }
      </div >
    )
  }
}

export default Sidebar
