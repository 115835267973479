import React, { Component } from 'react';

/**
* Custom checkbox
* @author   Milan Stanojevic
*/
class Toggle extends Component {
    constructor(props) {
        super(props);

        this.state = {
        };
    }

    render() {
        return (
            <div className={"toggle-wrap-v2"}  onClick={(e) => {
                if (!this.props.onChange){
                    return;
                }
                let obj = {
                    target: {
                        checked: !this.props.checked
                    }
                }

                this.props.onChange(obj);
            }}>
                <div className={this.props.checked ? "toggle-v2 toggle-v2-checked" : "toggle-v2"}>
                </div>
                <span>{this.props.label}</span>
            </div>
        );
    }
}

export default Toggle;