import React from 'react';

import {
    Container,
    Row,
    Col, Input, Label, Modal, ModalBody, ModalHeader, FormGroup, Button, ButtonToggle, ModalFooter
} from 'reactstrap';

import { Rnd } from 'react-rnd';

import Isvg from 'react-inlinesvg'

import addIcon from '../../assets/svg/add-icon.svg';

import PreviewComponent from '../landingBuilder/previewComponent';


import defaultJson from '../landingBuilder/default.json'
import defaultInformationJson from '../landingBuilder/defaultInformation.json'
import defaultLoginJson from '../landingBuilder/defaultLogin.json'


import FormBuilder from '../../components/forms/formBuilderFooter';



import { Player, Controls } from "@lottiefiles/react-lottie-player";

import warrning from '../../assets/images/warrning.png';


import Toggle from '../../components/forms/fields/toggle';
import { API_ENDPOINT } from '../../constants';
import { v4 as uuidv4 } from 'uuid';
import { copyMobileToApp } from './landingHelper';

import AppBuilder from '../../appBuilder/appBuilder'

function parseJSON(response) {
    return response.json().then(json => {
        return {
            result: json,
            status: response.status,

        }
    })
}


/**
* Landing page configurator - template
* @author   Aleksandar Dabic
*/
class DragAndDropComponent extends React.Component {

    constructor(props) {
        super(props);
        this.player = React.createRef();
        this.player2 = React.createRef();
        this.state = {
            activeTab: 1,
            showPlayer: false,
            showPlayer2: false,
            infoPage: true,
            reset1: false,
            reset2: false,
            reset3: false,
            name: '',
            typeConf: 1,
            tabIndex: 1,
            layout: 'lg',
            ifChange: false,
            libraryIcons: []

        }
    }
    updateFooterSettings = (data) => {
        let footerSettings = {
            backgroundColor: '#e6edf5',
            upperSegment: {
                color: '#7e8fae',
                fontSize: 16
            },
            lowerSegment: {
                color: '#7e8fae',
                fontSize: 16
            }
        }

        footerSettings.backgroundColor = data.backgroundColor;
        footerSettings.upperSegment = data.upperSegment;
        footerSettings.lowerSegment = data.lowerSegment;

        let obj = {}
        obj.footerSettings = footerSettings
        obj.groupId = this.props.selectedGroup;
        obj.clinic = this.props.selectedClinic;


        this.player?.current?.play();
        fetch(API_ENDPOINT + '/users/account/updateFooterSettings', {
            method: 'POST',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
            body: JSON.stringify(obj)
        }).then(parseJSON).then(({ result, status }) => {
            if (result.error) {
                this.setState({ error: result.error })
            } else {
                this.setState({
                    showPlayer: false,
                    showPlayer2: true,
                },
                    () => {
                        setTimeout(() => {
                            this.setState({ showPlayer2: false });
                        }, 1000);
                    })

                if (this.props.changeLinkModal) {
                    this.props[0].history.push(this.props.changeLinkModal)
                    this.props.abortAction()
                } else {
                    this.props.onChange('done')
                    this.props.abortAction()
                }



                this.props.verifyUser(() => { }, () => {
                    this.props.changeGroup(data.groupId)
                });
            }

        })



    }
    /**
    * Update landing page styles
    * @author   Aleksandar Dabic
    */
    update = () => {
        this.setState(
            {
                showPlayer: true,
            },
            () => {

                let data = {};
                // if (this.state.activeTab == 1) {
                //     data = { landingJSON: this.state.landingJSON };
                //     data.landingMobJSON = this.state.landingMobJSON ? this.state.landingMobJSON : defaultMobJSON;
                // }
                // else if (this.state.activeTab == 2) {
                //     data = { informationJSON: this.state.informationJSON };
                //     data.informationMobJSON = this.state.informationMobJSON ? this.state.informationMobJSON : defaultMobInfoPageJSON;
                // }
                // else {
                //     data = { loginJSON: this.state.loginJSON };
                //     data.loginMobJSON = this.state.loginMobJSON ? this.state.loginMobJSON : defaultMobLoginJSON;
                // }

                if (this.state.tabIndex !== 4) {


                    data.landingJSON_v2 = this.state.landingJSON;
                    data.informationJSON_v2 = this.state.informationJSON;
                    data.loginJSON_v2 = this.state.loginJSON;

                    data.groupId = this.props.selectedGroup;
                    data.clinic = this.props.selectedClinic;
                    this.player.current.play();
                    fetch(API_ENDPOINT + '/users/account/updateClinicDataLanding', {
                        method: 'POST',
                        headers: {
                            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                            'content-type': 'application/json'
                        },
                        body: JSON.stringify(data)
                    }).then(parseJSON).then(({ result, status }) => {
                        if (result.error) {
                            this.setState({ error: result.error })
                        } else {
                            this.setState({
                                showPlayer: false,
                                showPlayer2: true,
                            },
                                () => {
                                    setTimeout(() => {
                                        this.setState({ showPlayer2: false });
                                    }, 1000);
                                })

                            if (this.props.changeLinkModal) {
                                this.props[0].history.push(this.props.changeLinkModal)
                                this.props.abortAction()
                            } else {
                                this.props.onChange('done')
                                this.props.abortAction()
                            }



                            this.props.verifyUser(() => { }, () => {
                                this.props.changeGroup(data.groupId)
                            });
                        }

                    })
                }


            }
        )

        //     }
        // )
    }

    stopAnimation = () => {
        this.player?.current?.play();
    };

    showPlayer = () => {
        this.setState({
            showPlayer: true,
        });
    };

    hidePlayer = () => {
        this.setState({
            showPlayer: false,
        });
    };

    get = () => {
        if (this.props.landingStyle) {
            let footerSettings = {
                backgroundColor: '#e6edf5',
                upperSegment: {
                    color: '#7e8fae',
                    fontSize: 16
                },
                lowerSegment: {
                    color: '#7e8fae',
                    fontSize: 16
                }
            }
            if (this.props.landingStyle.footerSettings) {
                footerSettings = this.props.landingStyle.footerSettings;
            }

            this.setState({
                landingJSON: this.props.landingStyle.landingJSON ? this.props.landingStyle.landingJSON : {},
                informationJSON: this.props.landingStyle.informationJSON ? this.props.landingStyle.informationJSON : {},
                loginJSON: this.props.landingStyle.loginJSON ? this.props.landingStyle.loginJSON : {},
                name: this.props.landingStyle.name ? this.props.landingStyle.name : '',
                footerSettings: footerSettings

            }, () => this.setState({ updateNeeded: uuidv4() }))
        }

        setTimeout(() => {
            this.setState({
                ifChange: true
            })

        }, 2000)

        fetch(API_ENDPOINT + '/data-managment/landing-builder-styles/library/icons', {
            method: 'GET',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
        }).then(parseJSON).then(({ result, status }) => {
            if (status >= 200 && status < 300) {
                if (result && result.gallery) {
                    this.setState({
                        libraryIcons: result.gallery,
                        loadLibrary: false
                    })
                } else {
                    this.setState({
                        libraryIcons: [],
                        loadLibrary: false
                    })
                }


            }

        })

    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.landingStyle != this.props.landingStyle) {
            this.get()

        }
        if (this.props.updateLanding) {
            this.update()
            if (this.state.footerSettingsUpdated) {
                this.updateFooterSettings(this.state.footerSettingsUpdated)
            }
            this.props.changeUpdateLanding(false)
        }

    }

    componentDidMount() {
        this.get()



    }

    setReset = () => {
        if (this.state.tabIndex == 1) {
            this.setState({
                landingJSON: defaultJson,
                reset: null
            }, () => this.setState({ updateNeeded: uuidv4() }))
        } else if (this.state.tabIndex == 2) {
            this.setState({
                informationJSON: defaultInformationJson,
                reset: null
            }, () => this.setState({ updateNeeded: uuidv4() }))
        } else if (this.state.tabIndex == 3) {
            this.setState({
                loginJSON: defaultLoginJson,
                reset: null
            }, () => this.setState({ updateNeeded: uuidv4() }))
        }
    }

    /**
    * Import landing page template and update landing page style
    * @author   Aleksandar Dabic
    */
    importTemp = () => {



        let item = this.props.templates.filter(item => item._id == this.state.selectedTemp)[0];

        if (this.state.tabIndex == 1) {
            this.setState({ landingJSON: item.landingJSON, importModal: false, selectedTemp: null }, () => this.setState({ updateNeeded: uuidv4() }))

        } else if (this.state.tabIndex == 2) {
            this.setState({ informationJSON: item.informationJSON, importModal: false, selectedTemp: null }, () => this.setState({ updateNeeded: uuidv4() }))

        } else if (this.state.tabIndex == 3) {
            this.setState({ loginJSON: item.loginJSON, importModal: false, selectedTemp: null }, () => this.setState({ updateNeeded: uuidv4() }))
        }


        // this.update();







    }




    render() {
        let selectedGroupIdx = 0;
        if (this.props.selectedGroup && this.props.uData?.clinicGroups?.length) {
            for (let i = 0; i < this.props.uData.clinicGroups.length; i++) {
                if (this.props.uData.clinicGroups[i]._id == this.props.selectedGroup) {
                    selectedGroupIdx = i;
                    break;
                }
            }
        }
        let enterprise = false;
        if (this.props.uData && this.props.uData.clinicGroups && this.props.uData.clinicGroups[selectedGroupIdx] && this.props.uData.clinicGroups[selectedGroupIdx].enterprise) {
            enterprise = true;
        }

        let listing = false;
        if (this.props.uData && this.props.uData.clinicGroups && this.props.uData.clinicGroups[selectedGroupIdx] && this.props.uData.clinicGroups[selectedGroupIdx].listing) {
            listing = true;
        }
        // console.log('footerSettings', this.state.footerSettings)
        return (
            <div style={{ marginTop: 50 }}>


                <div className="tabs-buttons-wrap2">
                    <div className='left-buttons'>

                        <Button className={this.state.tabIndex == 1 ? "tab-button acitve-tab-button" : "tab-button"} onClick={() => this.setState({ tabIndex: 1 })}>{"Landing page".translate(this.props.lang)}</Button>
                        <Button className={this.state.tabIndex == 2 ? "tab-button acitve-tab-button" : "tab-button"} onClick={() => this.setState({ tabIndex: 2 })}>{"Information page".translate(this.props.lang)}</Button>
                        <Button className={this.state.tabIndex == 3 ? "tab-button acitve-tab-button" : "tab-button"} onClick={() => this.setState({ tabIndex: 3 })}>{"Login page".translate(this.props.lang)}</Button>
                        {
                            this.props.footerSettings ?
                                <Button className={this.state.tabIndex == 4 ? "tab-button acitve-tab-button" : "tab-button"} onClick={() => {
                                    let setObj = { tabIndex: 4 }
                                    if (this.state.preview) {
                                        setObj.preview = false;
                                    }
                                    if (this.state.importModal) {
                                        setObj.importModal = false;
                                    }
                                    this.setState(setObj)
                                }}>{"Footer settings".translate(this.props.lang)}</Button>
                                :
                                null
                        }



                    </div>

                    {
                        this.state.tabIndex !== 4 ?
                            <div className='right-buttons'>
                                <div style={{ height: 33, width: 200, marginTop: -18 }}>
                                    <Toggle value={this.state.preview} label={"Preview".translate(this.props.lang)} onChange={(e) => {
                                        this.setState({ preview: !this.state.preview, importModal: false }, () => {
                                            // if(this.state.preview){
                                            //     this.setState({ updateNeeded: uuidv4() })
                                            // }
                                        })
                                    }} />
                                </div>

                                <Button className='tab-button acitve-tab-button' onClick={() => this.update()}>
                                    {
                                        this.state.showPlayer ? (
                                            <Player
                                                onEvent={(event) => {
                                                    if (event === "load") this.stopAnimation();
                                                }}
                                                onStateChange={this.toggleVisibility}
                                                ref={this.player} // set the ref to your class instance
                                                autoplay={true}
                                                loop={true}
                                                controls={true}
                                                src="https://assets8.lottiefiles.com/packages/lf20_ZSRKCF.json"
                                                style={{ height: "20px", width: "20px" }}
                                            ></Player>

                                        ) : this.state.showPlayer2 ? (
                                            <div style={{ display: 'flex', alignItems: 'center' }}><Player
                                                onStateChange={this.toggleVisibility}
                                                ref={this.player2} // set the ref to your class instance
                                                autoplay={true}
                                                loop={true}
                                                controls={true}
                                                src="https://assets10.lottiefiles.com/packages/lf20_CxyxLk.json"
                                                style={{ height: "20px", width: "20px" }}
                                            ></Player>
                                                {'Saved'.translate(this.props.lang)}
                                            </div>
                                        ) : (
                                            "Save".translate(this.props.lang)
                                        )
                                    }
                                </Button>

                                {
                                    this.props[0].match.params.id != 'new' ?
                                        <>
                                            {/* <Button className='tab-button acitve-tab-button' onClick={() => this.setState({ preview: JSON.parse(JSON.stringify(this.state.landingJSON)) }, () => console.log(JSON.parse(JSON.stringify(this.state.landingJSON))))}>{"Preview".translate(this.props.lang)}</Button> */}
                                            <Button className='tab-button acitve-tab-button' onClick={() => this.setState({ reset: this.state.tabIndex })}>{"Reset".translate(this.props.lang)}</Button>
                                        </>
                                        :
                                        null
                                }
                                <Button className='tab-button acitve-tab-button' onClick={() => this.setState({ importModal: true, preview: false })}>{"Import".translate(this.props.lang)}</Button>



                            </div>
                            :
                            null
                    }
                </div>
                {
                    this.state.tabIndex == 1 ?
                        <div style={{ visibility: this.state.preview || this.state.importModal ? 'hidden' : 'visible', height: this.state.preview || this.state.importModal ? '0' : 'auto' }}>
                            <AppBuilder
                                selectedClinic={this.props.selectedClinic}
                                selectedGroup={this.props.selectedGroup}
                                json={this.state.landingJSON ? this.state.landingJSON : {}}
                                location={this.props[0].location.pathname}
                                libraryIcons={this.state.libraryIcons}
                                handleChange={(json) => {
                                    if (this.state.ifChange) {
                                        this.props.onChange('open')
                                        this.setState({
                                            ifChange: false
                                        })
                                    } else {
                                        // this.props.onChange('done')
                                    }
                                    this.setState({
                                        landingJSON: json,
                                    })

                                }}
                                changeLayout={(layout) => {
                                    this.setState({
                                        layout: layout
                                    })
                                }}
                                importMobileToApp={(nodes) => {
                                    let newNodes = copyMobileToApp(nodes)
                                    if (this.state.ifChange) {
                                        this.props.onChange('open')
                                        this.setState({
                                            ifChange: false
                                        })
                                    }
                                    this.setState({
                                        landingJSON: JSON.parse(JSON.stringify(newNodes)),
                                        updateNeeded: uuidv4()
                                    })
                                }}
                                layout={this.state.layout}
                                key={1}
                                updateNeeded={this.state.updateNeeded}
                                lang={this.props.lang}
                                preview={this.state.preview}
                                enterprise={enterprise}
                                listing={listing}

                            />
                        </div>
                        :
                        this.state.tabIndex == 2 ?
                            <div style={{ visibility: this.state.preview || this.state.importModal ? 'hidden' : 'visible', height: this.state.preview || this.state.importModal ? '0' : 'auto' }}>
                                <AppBuilder
                                    selectedClinic={this.props.selectedClinic}
                                    selectedGroup={this.props.selectedGroup}
                                    json={this.state.informationJSON ? this.state.informationJSON : {}}
                                    location={this.props[0].location.pathname}
                                    libraryIcons={this.state.libraryIcons}
                                    handleChange={(json) => {
                                        if (this.state.ifChange) {
                                            this.props.onChange('open')
                                            this.setState({
                                                ifChange: false
                                            })
                                        } else {
                                            // this.props.onChange('done')
                                        }
                                        this.setState({
                                            informationJSON: json,
                                        })
                                    }}
                                    changeLayout={(layout) => {
                                        this.setState({
                                            layout: layout
                                        })
                                    }}
                                    importMobileToApp={(nodes) => {
                                        let newNodes = copyMobileToApp(nodes)
                                        if (this.state.ifChange) {
                                            this.props.onChange('open')
                                            this.setState({
                                                ifChange: false
                                            })
                                        }
                                        this.setState({
                                            informationJSON: JSON.parse(JSON.stringify(newNodes)),
                                            updateNeeded: uuidv4()
                                        })
                                    }}
                                    layout={this.state.layout}
                                    key={2}
                                    updateNeeded={this.state.updateNeeded}
                                    lang={this.props.lang}
                                    preview={this.state.preview}
                                    enterprise={enterprise}
                                    listing={listing}

                                />
                            </div>
                            :
                            this.state.tabIndex == 3 ?
                                <div style={{ visibility: this.state.preview || this.state.importModal ? 'hidden' : 'visible', height: this.state.preview || this.state.importModal ? '0' : 'auto' }}>
                                    <AppBuilder
                                        selectedClinic={this.props.selectedClinic}
                                        selectedGroup={this.props.selectedGroup}
                                        json={this.state.loginJSON ? this.state.loginJSON : {}}
                                        location={this.props[0].location.pathname}
                                        libraryIcons={this.state.libraryIcons}
                                        handleChange={(json) => {
                                            if (this.state.ifChange) {
                                                this.props.onChange('open')
                                                this.setState({
                                                    ifChange: false
                                                })
                                            } else {
                                                // this.props.onChange('done')
                                            }
                                            this.setState({
                                                loginJSON: json,
                                            })
                                        }}
                                        changeLayout={(layout) => {
                                            this.setState({
                                                layout: layout
                                            })
                                        }}
                                        importMobileToApp={(nodes) => {
                                            let newNodes = copyMobileToApp(nodes)
                                            if (this.state.ifChange) {
                                                this.props.onChange('open')
                                                this.setState({
                                                    ifChange: false
                                                })
                                            }
                                            this.setState({
                                                loginJSON: JSON.parse(JSON.stringify(newNodes)),
                                                updateNeeded: uuidv4()
                                            })
                                        }}
                                        layout={this.state.layout}
                                        key={3}
                                        updateNeeded={this.state.updateNeeded}
                                        lang={this.props.lang}
                                        preview={this.state.preview}
                                        enterprise={enterprise}
                                        listing={listing}

                                    />
                                </div>
                                :
                                this.state.tabIndex === 4 ?
                                    <div style={{ visibility: 'visible', height: 'auto' }}>
                                        <div className='footer-settings-wrap'>
                                            <FormBuilder
                                                countryTwoLetter={this.props.countryTwoLetter}
                                                country={this.props.country}
                                                dateFormat={this.props.dateFormat}
                                                getStringDateTs={this.props.getStringDateTs}
                                                lang={this.props.lang}
                                                initialValues={this.state.footerSettings}
                                                onChange={(data) => {
                                                    if (data && this.state.footerSettings && JSON.stringify(data) !== JSON.stringify(this.state.footerSettings)) {
                                                        this.setState({ footerSettingsUpdated: data })
                                                        this.props.onChange('open')
                                                    }
                                                }}
                                                // hideSubmitButton={true}
                                                onSubmit={(data) => { this.updateFooterSettings(data) }}
                                                buttonText={
                                                    this.state.showPlayer ? (
                                                        <Player
                                                            onEvent={(event) => {
                                                                if (event === "load") this.stopAnimation();
                                                            }}
                                                            onStateChange={this.toggleVisibility}
                                                            ref={this.player} // set the ref to your class instance
                                                            autoplay={true}
                                                            loop={true}
                                                            controls={true}
                                                            src="https://assets8.lottiefiles.com/packages/lf20_ZSRKCF.json"
                                                            style={{ height: "30px", width: "30px" }}
                                                        ></Player>

                                                    ) : this.state.showPlayer2 ? (
                                                        <div style={{ display: 'flex', alignItems: 'center' }}><Player
                                                            onStateChange={this.toggleVisibility}
                                                            ref={this.player2} // set the ref to your class instance
                                                            autoplay={true}
                                                            loop={true}
                                                            controls={true}
                                                            src="https://assets10.lottiefiles.com/packages/lf20_CxyxLk.json"
                                                            style={{ height: "30px", width: "30px" }}
                                                        ></Player>
                                                            {'Saved'.translate(this.props.lang)}
                                                        </div>
                                                    ) : (
                                                        "Save".translate(this.props.lang)
                                                    )
                                                }
                                                fields={[
                                                    {
                                                        type: 'row',
                                                        children: [

                                                            {
                                                                type: 'col',
                                                                width: {
                                                                    lg: 12,
                                                                    sm: 12,
                                                                    xs: 12
                                                                },
                                                                children: [
                                                                    {
                                                                        type: 'color-picker',
                                                                        name: 'backgroundColor',
                                                                        label: 'Background color'.translate(this.props.lang)
                                                                    }

                                                                ]
                                                            },
                                                            {
                                                                type: 'col',
                                                                width: {
                                                                    lg: 12,
                                                                    sm: 12,
                                                                    xs: 12
                                                                },
                                                                children: [
                                                                    {
                                                                        type: 'row',
                                                                        style: { alignItems: 'center' },
                                                                        children: [
                                                                            {
                                                                                type: 'col',
                                                                                width: {
                                                                                    lg: 4,
                                                                                    sm: 12,
                                                                                    xs: 12
                                                                                },
                                                                                children: [
                                                                                    {
                                                                                        type: 'color-picker',
                                                                                        name: 'upperSegment.color',
                                                                                        label: 'Upper segment text color'.translate(this.props.lang)
                                                                                    }


                                                                                ]
                                                                            },
                                                                            {
                                                                                type: 'col',
                                                                                width: {
                                                                                    lg: 4,
                                                                                    sm: 12,
                                                                                    xs: 12
                                                                                },
                                                                                children: [
                                                                                    {
                                                                                        type: 'text',
                                                                                        name: 'upperSegment.fontSize',
                                                                                        label: 'Upper segment font size'.translate(this.props.lang),
                                                                                        width: 100,
                                                                                        type: 'number',
                                                                                        minValue: '0'
                                                                                    }


                                                                                ]
                                                                            }
                                                                        ]
                                                                    },


                                                                ]
                                                            },
                                                            {
                                                                type: 'col',
                                                                width: {
                                                                    lg: 12,
                                                                    sm: 12,
                                                                    xs: 12
                                                                },
                                                                children: [
                                                                    {
                                                                        type: 'row',
                                                                        style: { alignItems: 'center' },
                                                                        children: [
                                                                            {
                                                                                type: 'col',
                                                                                width: {
                                                                                    lg: 4,
                                                                                    sm: 12,
                                                                                    xs: 12
                                                                                },
                                                                                children: [
                                                                                    {
                                                                                        type: 'color-picker',
                                                                                        name: 'lowerSegment.color',
                                                                                        label: 'Lower segment text color'.translate(this.props.lang)
                                                                                    }


                                                                                ]
                                                                            },
                                                                            {
                                                                                type: 'col',
                                                                                width: {
                                                                                    lg: 4,
                                                                                    sm: 12,
                                                                                    xs: 12
                                                                                },
                                                                                children: [
                                                                                    {
                                                                                        type: 'text',
                                                                                        name: 'lowerSegment.fontSize',
                                                                                        label: 'Lower segment font size'.translate(this.props.lang),
                                                                                        width: 100,
                                                                                        type: 'number',
                                                                                        minValue: '0'
                                                                                    }



                                                                                ]
                                                                            }
                                                                        ]
                                                                    },


                                                                ]
                                                            }


                                                        ]
                                                    }
                                                ]}
                                            ></FormBuilder>
                                        </div>
                                    </div>

                                    :
                                    null
                }
                {
                    this.state.preview ?
                        <div className="preview-landing-wrap">
                            <div className={this.state.layout == 'xs' || this.state.layout == 'app' ? 'preview-xs-size' : this.state.layout == 'sm' ? 'preview-sm-size' : 'preview-lg-size'}>
                                <PreviewComponent
                                    layout={this.state.layout}
                                    nodes={this.state.tabIndex == 1 ? JSON.parse(JSON.stringify(this.state.landingJSON)) : this.state.tabIndex == 2 ? JSON.parse(JSON.stringify(this.state.informationJSON)) : this.state.tabIndex == 3 ? JSON.parse(JSON.stringify(this.state.loginJSON)) : {}} />
                            </div>
                        </div>
                        :
                        null
                }

                {this.state.reset == 1 ?
                    <Modal isOpen={this.state.reset} style={{ marginTop: '50px' }} centered>
                        {/* <ModalHeader style={{ margin: 'auto' }}>{'Reset landing page'.translate(this.props.lang)}</ModalHeader> */}

                        <ModalBody className="delete-modal">
                            {/* <img src={warrning} /> */}
                            <div>{'Are you sure you want reset landing page?'.translate(this.props.lang)}</div>
                        </ModalBody>
                        <ModalFooter className='buttons-right-blue'>
                            <Button color='primary' style={{ margin: 5, backgroundColor: "#3A3F56", minWidth: 100 }} onClick={() => this.setReset()}>{'Yes'.translate(this.props.lang)}</Button>
                            <Button color='primary' style={{ margin: 5, minWidth: 100 }} onClick={() => this.setState({ reset: !this.state.reset })}>{'No'.translate(this.props.lang)}</Button>

                        </ModalFooter>
                    </Modal>
                    : null}
                {this.state.reset == 2 ?
                    <Modal isOpen={this.state.reset} style={{ marginTop: '50px' }} centered>
                        {/* <ModalHeader style={{ margin: 'auto' }}>{'Reset information page'.translate(this.props.lang)}</ModalHeader> */}

                        <ModalBody className="delete-modal">
                            {/* <img src={warrning} /> */}
                            <div>{'Are you sure you want reset information page?'.translate(this.props.lang)}</div>
                        </ModalBody>
                        <ModalFooter className='buttons-right-blue'>
                            <Button color='primary' style={{ margin: 5, backgroundColor: "#3A3F56", minWidth: 100 }} onClick={() => this.setReset()}>{'Yes'.translate(this.props.lang)}</Button>
                            <Button color='primary' style={{ margin: 5, minWidth: 100 }} onClick={() => this.setState({ reset: !this.state.reset })}>{'No'.translate(this.props.lang)}</Button>

                        </ModalFooter>
                    </Modal>
                    : null}

                {this.state.reset == 3 ?
                    <Modal isOpen={this.state.reset} style={{ marginTop: '50px' }} centered>
                        {/* <ModalHeader style={{ margin: 'auto' }}>{'Reset login page'.translate(this.props.lang)}</ModalHeader> */}
                        <ModalBody className="delete-modal">
                            {/* <img src={warrning} /> */}
                            <div>{'Are you sure you want reset login page?'.translate(this.props.lang)}</div>
                        </ModalBody>
                        <ModalFooter className='buttons-right-blue'>
                            <Button color='primary' style={{ margin: 5, backgroundColor: "#3A3F56", minWidth: 100 }} onClick={() => this.setReset()}>{'Yes'.translate(this.props.lang)}</Button>
                            <Button color='primary' style={{ margin: 5, minWidth: 100 }} onClick={() => this.setState({ reset: !this.state.reset })}>{'No'.translate(this.props.lang)}</Button>
                        </ModalFooter>
                    </Modal>
                    : null}



                {
                    this.state.importModal ?
                        // <Modal isOpen={this.state.importModal} style={{ marginTop: '50px' }} size="xl" centered>
                        //     <ModalHeader toggle={() => this.setState({ importModal: !this.state.importModal })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '30px' }} onClick={() => this.setState({ importModal: !this.state.importModal, selectedTemp: null })}>&times;</button>}>{'Import'.translate(this.props.lang)}</ModalHeader>

                        //     <ModalBody>
                        //         {

                        //             this.props.templates && this.props.templates.map((item, idx) => {
                        //                 return (
                        //                     <FormGroup style={{ position: 'relative' }}>
                        //                         <hr />
                        //                         <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        //                             <Label>{item.name}</Label>

                        //                             <Button color='primary' style={{ margin: 5, }} onClick={() => { this.setState({ selectedTemp: item._id }, () => this.importTemp()) }}>{'Import'.translate(this.props.lang)}</Button>

                        //                         </div>
                        //                         <hr />
                        //                         <div className="import-modal-items">
                        //                             {
                        //                                 item.landingJSON && this.state.tabIndex == 1 ?
                        //                                     <div className="preview-landing-wrap">
                        //                                         <div className={this.state.layout == 'xs' ? 'preview-xs-size' : this.state.layout == 'sm' ? 'preview-sm-size' : 'preview-lg-size'}>
                        //                                             <PreviewComponent
                        //                                                 layout={this.state.layout}
                        //                                                 nodes={JSON.parse(JSON.stringify(item.landingJSON))}
                        //                                             />
                        //                                         </div>
                        //                                     </div>

                        //                                     :
                        //                                     null
                        //                             }
                        //                             {
                        //                                 item.informationJSON && this.state.tabIndex == 2 ?
                        //                                     <div className="preview-landing-wrap">
                        //                                         <div className={this.state.layout == 'xs' ? 'preview-xs-size' : this.state.layout == 'sm' ? 'preview-sm-size' : 'preview-lg-size'}>
                        //                                             <PreviewComponent
                        //                                                 layout={this.state.layout}
                        //                                                 nodes={JSON.parse(JSON.stringify(item.informationJSON))}
                        //                                             />
                        //                                         </div>
                        //                                     </div>

                        //                                     :
                        //                                     null
                        //                             }
                        //                             {
                        //                                 item.loginJSON && this.state.tabIndex == 3 ?
                        //                                     <div className="preview-landing-wrap">
                        //                                         <div className={this.state.layout == 'xs' ? 'preview-xs-size' : this.state.layout == 'sm' ? 'preview-sm-size' : 'preview-lg-size'}>
                        //                                             <PreviewComponent
                        //                                                 layout={this.state.layout}
                        //                                                 nodes={JSON.parse(JSON.stringify(item.loginJSON))}
                        //                                             />
                        //                                         </div>
                        //                                     </div>

                        //                                     :
                        //                                     null
                        //                             }

                        //                         </div>


                        //                     </FormGroup>
                        //                 )
                        //             })


                        //         }

                        //     </ModalBody>
                        //     <ModalFooter className='buttons-right-blue'>
                        //         <Button color='primary' style={{ margin: 5 }} onClick={() => this.setState({ importModal: !this.state.importModal, selectedTemp: null })}>{'Cancel'.translate(this.props.lang)}</Button>
                        //     </ModalFooter>
                        // </Modal>
                        <>
                            <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                                <div style={{ fontSize: 24, cursor: 'pointer' }} onClick={() => this.setState({ importModal: !this.state.importModal, selectedTemp: null })}>&times;</div>
                            </div>
                            {

                                this.props.templates && this.props.templates.map((item, idx) => {
                                    return (
                                        <FormGroup style={{ position: 'relative' }}>
                                            <hr />
                                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <Label>{item.name}</Label>

                                                <Button color='primary' style={{ margin: 5, }} onClick={() => { this.setState({ selectedTemp: item._id }, () => this.importTemp()) }}>{'Import'.translate(this.props.lang)}</Button>

                                            </div>
                                            <hr />
                                            <div className="import-modal-items">
                                                {
                                                    item.landingJSON && this.state.tabIndex == 1 ?
                                                        <div className="preview-landing-wrap">
                                                            <div className={this.state.layout == 'xs' || this.state.layout == 'app' ? 'preview-xs-size' : this.state.layout == 'sm' ? 'preview-sm-size' : 'preview-lg-size'}>
                                                                <PreviewComponent
                                                                    layout={this.state.layout}
                                                                    nodes={JSON.parse(JSON.stringify(item.landingJSON))}
                                                                />
                                                            </div>
                                                        </div>

                                                        :
                                                        null
                                                }
                                                {
                                                    item.informationJSON && this.state.tabIndex == 2 ?
                                                        <div className="preview-landing-wrap">
                                                            <div className={this.state.layout == 'xs' || this.state.layout == 'app' ? 'preview-xs-size' : this.state.layout == 'sm' ? 'preview-sm-size' : 'preview-lg-size'}>
                                                                <PreviewComponent
                                                                    layout={this.state.layout}
                                                                    nodes={JSON.parse(JSON.stringify(item.informationJSON))}
                                                                />
                                                            </div>
                                                        </div>

                                                        :
                                                        null
                                                }
                                                {
                                                    item.loginJSON && this.state.tabIndex == 3 ?
                                                        <div className="preview-landing-wrap">
                                                            <div className={this.state.layout == 'xs' || this.state.layout == 'app' ? 'preview-xs-size' : this.state.layout == 'sm' ? 'preview-sm-size' : 'preview-lg-size'}>
                                                                <PreviewComponent
                                                                    layout={this.state.layout}
                                                                    nodes={JSON.parse(JSON.stringify(item.loginJSON))}
                                                                />
                                                            </div>
                                                        </div>

                                                        :
                                                        null
                                                }

                                            </div>


                                        </FormGroup>
                                    )
                                })


                            }

                        </>
                        : null
                }





            </div>

        )
    }
}

export default DragAndDropComponent;