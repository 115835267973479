import React, { Component } from 'react';
import Page from '../../containers/page';

import {
    Container,
    Row,
    Col,
    Modal, ModalHeader, ModalBody, Input, FormGroup, Label, Button
} from 'reactstrap';

import Isvg from 'react-inlinesvg'

import FormBuilder from '../../components/forms/modalFormBuilder';

import { required } from '../../components/forms/validation';

import ListBuilder from '../../components/listBuilder';
import DeleteModal from '../../components/deleteModal';
import { getSearchParams, generateSearchLink, generateSearchLinkMultiple } from '../../helpers/linkHelper';
import { API_ENDPOINT } from '../../constants';
import ErrorModal from '../../components/errorModal';
import moment from 'moment';

import Search from '../../components/search';
import addIcon from '../../assets/svg/add-icon.svg';
import view from '../../assets/svg/eye.svg'

import profileUser from '../../assets/images/user.png';
import notification from '../../assets/svg/notification.svg'
import moreIcon from '../../assets/svg/more.svg'

import DatePicker from '../../components/forms/fields/datePicker';

import exportIcon from "../../assets/svg/export.svg";

import { Player, Controls } from '@lottiefiles/react-lottie-player';



function parseJSON(response) {
    return response.json().then(json => {
        return {
            result: json,
            status: response.status
        }
    })
}

/**
* Group logs list
* @author   Aleksandar Dabic
*/
class Logs extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props.initialData,
            form: false,
            useState: false,
            params: {
                entries: 10,
                page: 0,
                sortField: 'timestamp',
                sortType: -1
            },
            items: [],
            clinicGroups: [],
            startDateSearch: null,
            endDataSearch: null,
            total: 0,
            loading: true
        };
    }


    get = () => {
        this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));

        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, {
                entries: 10, page: 0, sortField: 'timestamp',
                sortType: -1
            })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

        for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
            this.props.loadDataWithQuery[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, {
                entries: 10, page: 0, sortField: 'timestamp', group: this.state.selectedClinicGroup, startDate: this.state.startDateSearch, endDate: this.state.endDataSearch,
                sortType: -1
            })).then((data) => {
                let dataToSet = { ...data }
                if (dataToSet.page || dataToSet.items || dataToSet.total) {
                    dataToSet.loading = null;
                    // console.log(dataToSet.page || dataToSet.items || dataToSet.total)
                    let checkFilter = '';
                    if (dataToSet.resultFilter) {
                        checkFilter = String(dataToSet.resultFilter)
                        delete dataToSet.resultFilter;
                    }
                    let search = this.props[0].location.search;
                    let serachParams = getSearchParams(search);
                    let searchParamsFilter = '';
                    if (serachParams.filter) {
                        searchParamsFilter = serachParams.filter;
                    }
                    if (searchParamsFilter != checkFilter) {
                        delete dataToSet.page;
                        delete dataToSet.items;
                        delete dataToSet.total;
                        delete dataToSet.loading;
                    }
                }
                this.setState({
                    ...dataToSet,
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

    }

    componentDidMount() {
        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }

        this.get();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps[0].location.search != this.props[0].location.search) {
            this.setState({
                loading: true
            }, () => {
                this.get();

            })
        }
        if (prevState.externalStorage != this.state.externalStorage && !this.state.externalStorage) {
            this.setState({
                externalStorageLogs: [],
                startDateSearchExternalStorage: null,
                endDataSearchExternalStorage: null,
                clinicSearchExternalStorage: null
            })
        }
    }


    updateSort = (field, type) => {
        if (this.state.useState) {
            let state = this.state.params;
            state.sortField = field;
            state.sortType = type;
            this.setState({
                params: state
            })
        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0 }, [{ name: 'sortField', value: field }, { name: 'sortType', value: type }], false));

        }
    }

    updateParams = (name, value, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0,
                    sortField: 'timestamp',
                    sortType: -1
                };
                obj[name] = value;
                this.setState({ params: obj });
            } else {
                let obj = this.state.params;
                obj[name] = value;
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(generateSearchLink(this.props[0].location.search, { entries: 10, page: 0 }, name, value, restart));
        }
    }
    updateMultipleParams = (fields, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0,
                    sortField: 'timestamp',
                    sortType: -1
                };
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj });

            } else {
                let obj = this.state.params;
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0 }, fields, restart));
        }
    }

    export = () => {
        this.setState({ loadingExport: true }, () => {
            fetch(API_ENDPOINT + "/data/clinic-groups-logs/export", {
                method: "POST",
                headers: {
                    Authorization:
                        typeof localStorage !== "undefined"
                            ? `Bearer ${localStorage.getItem("authToken")}`
                            : null,
                    "content-type": "application/json",
                },
                body: JSON.stringify({
                    group: this.state.selectedClinicGroup,
                    startDate: this.state.startDateSearch,
                    endDate: this.state.endDataSearch
                }),
            })
                .then((res) => res.json())
                .then((result) => {

                    if (result.success) {
                        window.location.href = API_ENDPOINT + `${result.file}`;
                    }
                    this.setState({
                        success: result.success,
                        file: result.file,
                        loadingExport: false
                    })
                });
        })
    }
    getExternalStorage = () => {
        if (!this.state.startDateSearchExternalStorage || !this.state.endDataSearchExternalStorage) {
            this.setState({ error: 'Please select start and date' })
        } else {
            this.setState({ loadingExport: true }, () => {
                fetch(API_ENDPOINT + "/data/clinic-groups-logs/exteranl/storage", {
                    method: "POST",
                    headers: {
                        Authorization:
                            typeof localStorage !== "undefined"
                                ? `Bearer ${localStorage.getItem("authToken")}`
                                : null,
                        "content-type": "application/json",
                    },
                    body: JSON.stringify({
                        // group: this.state.selectedClinicGroup,
                        startDate: this.state.startDateSearchExternalStorage,
                        endDate: this.state.endDataSearchExternalStorage,
                        clinicName: this.state.clinicSearchExternalStorage
                    }),
                })
                    .then((res) => res.json())
                    .then((result) => {
                        this.setState({ externalStorageLogs: result, loadingExport: false })
                    });
            })

        }

    }
    importExternalStorage = () => {
        this.setState({ loadingExport: true }, () => {
            fetch(API_ENDPOINT + "/data/clinic-groups-logs/exteranl/storage/import", {
                method: "POST",
                headers: {
                    Authorization:
                        typeof localStorage !== "undefined"
                            ? `Bearer ${localStorage.getItem("authToken")}`
                            : null,
                    "content-type": "application/json",
                },
                body: JSON.stringify({
                    importLogs: this.state.externalStorageLogs
                }),
            })
                .then((res) => res.json())
                .then((result) => {
                    this.setState({ externalStorage: null, loadingExport: false }, this.get)
                });
        })

    }
    exportExternalStorage = () => {
        this.setState({ loadingExport: true }, () => {
            fetch(API_ENDPOINT + "/data/clinic-groups-logs/exteranl/storage/export", {
                method: "POST",
                headers: {
                    Authorization:
                        typeof localStorage !== "undefined"
                            ? `Bearer ${localStorage.getItem("authToken")}`
                            : null,
                    "content-type": "application/json",
                },
                body: JSON.stringify({
                    startDate: this.state.startDateSearchExternalStorage,
                    endDate: this.state.endDataSearchExternalStorage,
                    clinicName: this.state.clinicSearchExternalStorage
                }),
            })
                .then((res) => res.json())
                .then((result) => {
                    if (result.success) {
                        window.location.href = API_ENDPOINT + `${result.file}`;
                    }
                    this.setState({
                        success: result.success,
                        file: result.file
                    })
                    this.setState({ externalStorage: null, loadingExport: false }, this.get)
                });
        })

    }


    render() {
        let params = {};
        if (this.state.useState) {
            params = this.state.params;
        } else {
            params = getSearchParams(this.props[0].location.search, { entries: 10, page: 0 })
        }
        return (
            <div className={this.props.autoHideSidebar ? "dashboard dashboard-auto-hide" : "dashboard"}>

                <Container fluid>
                    <Row>
                        <Col lg="12">
                            <div className="panel">
                                <div className="panel-header mob-view-flex-col">
                                    <h5 className="component-header">{this.props.linksMeta && this.props.linksMeta[this.props[0].location.pathname] ? this.props.linksMeta[this.props[0].location.pathname][this.props.lang] : ''}</h5>
                                    <div className='mob-view-padding-top-15'>
                                        <button className="btn add-button" onClick={() => this.setState({ externalStorage: true })} style={{ marginRight: 15 }}><Isvg src={exportIcon} /> {'External storage'.translate(this.props.lang)}</button>

                                        <button className="btn add-button" onClick={() => this.export()}><Isvg src={exportIcon} /> {'Export'.translate(this.props.lang)}</button>

                                    </div>
                                </div>
                                <div className="logs-clinic-group-filter-wrap">
                                    <FormGroup style={{ marginBottom: 0}}>
                                        <Label>{'Clinic group'.translate(this.props.lang)}</Label>
                                        <Input type="select" className="clinic-group-select" value={this.state.selectedClinicGroup} onChange={(e) => {
                                            if (e.target.value != -1) {
                                                this.setState({ selectedClinicGroup: e.target.value }, this.get)
                                            } else {
                                                this.setState({ selectedClinicGroup: null }, this.get)
                                            }
                                        }}>
                                            <option value={-1}>{'Select clinic group'.translate(this.props.lang)}</option>
                                            {
                                                this.state.clinicGroups && this.state.clinicGroups.map((item, idx) => {
                                                    return (
                                                        <option value={item._id}>{item.name}</option>
                                                    )
                                                })
                                            }
                                        </Input>
                                    </FormGroup>
                                    <DatePicker
                                        label={'Start date'.translate(this.props.lang)}
                                        value={this.state.startDateSearch}
                                        onChange={(e) => {
                                            this.setState({
                                                startDateSearch: e
                                            }, this.get)
                                        }}
                                    />
                                    <DatePicker
                                        label={'End date'.translate(this.props.lang)}
                                        value={this.state.endDataSearch}
                                        onChange={(e) => {
                                            this.setState({
                                                endDataSearch: e
                                            }, this.get)
                                        }}
                                    />

                                </div>
                                <ListBuilder
                                    lang={this.props.lang}
                                    loading={this.state.loading}
                                    total={this.state.total}
                                    showNumeration={false}
                                    fields={[
                                        { type: 'text', name: 'groupName', label: 'Clinic group'.translate(this.props.lang), allowSort: true },
                                        { type: 'text', name: 'username', label: 'User'.translate(this.props.lang), allowSort: false },
                                        { type: 'text', name: 'ip', label: 'IP'.translate(this.props.lang), allowSort: false },
                                        { type: 'text', name: 'typeName', label: 'Method'.translate(this.props.lang), allowSort: false },
                                        { type: 'text', name: 'timestamp', label: 'Time'.translate(this.props.lang), allowSort: true },

                                    ]}
                                    rawItems={this.state.items}
                                    items={this.state.items.map(item => {
                                        return {
                                            ...item,
                                            timestamp: this.props.getStringDateTs(Math.floor(item.tsCreated), `${this.props.dateFormat} HH:mm:ss`)/*moment.unix(Math.floor(item.tsCreated)).format(`${this.props.dateFormat} HH:mm:ss`)*/
                                        }
                                    })}
                                    // actions={
                                    //     [
                                    //         {
                                    //             component: <Isvg src={view} className="view-icon" />,
                                    //             onClick: (item) => this.setState({ logView: item })
                                    //         },
                                    //     ]
                                    // }
                                    params={params}
                                    sortField={params.sortField}
                                    sortType={params.sortType}
                                    updateSort={this.updateSort}
                                    updateParams={this.updateParams}
                                    updateMultipleParams={this.updateMultipleParams}
                                ></ListBuilder>
                            </div>
                        </Col>
                    </Row>

                </Container>

                <Modal isOpen={this.state.externalStorage} centered size={'xl'}>
                    <ModalHeader close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ externalStorage: !this.state.externalStorage })}>&times;</button>}>{'External storage'.translate(this.props.lang)}</ModalHeader>

                    <ModalBody>
                        <div className="archive-search">
                            <FormGroup style={{ marginBottom: 0, marginRight: 15 }}>
                                <Label>{'Clinic group'.translate(this.props.lang)}</Label>
                                <Input type='text' value={this.state.clinicSearchExternalStorage} style={{ height: 50 }} onChange={(e) => { this.setState({ clinicSearchExternalStorage: e.target.value }) }} />
                            </FormGroup>
                            <div style={{ marginRight: 15 }}>
                                <DatePicker
                                    label={'Start date'.translate(this.props.lang)}
                                    value={this.state.startDateSearchExternalStorage}
                                    onChange={(e) => {
                                        this.setState({
                                            startDateSearchExternalStorage: e
                                        })
                                    }}
                                />
                            </div>
                            <div style={{ marginRight: 15 }}>
                                <DatePicker
                                    label={'End date'.translate(this.props.lang)}
                                    value={this.state.endDataSearchExternalStorage}
                                    onChange={(e) => {
                                        this.setState({
                                            endDataSearchExternalStorage: e
                                        })
                                    }}
                                />
                            </div>
                            <Button color='primary' className='search-btn' style={{ height: 50 }} onClick={() => this.getExternalStorage()}>{'Search'.translate(this.props.lang)}</Button>
                            {/* {
                                this.state.externalStorageLogs && this.state.externalStorageLogs.length ?
                                    <Button color='primary' style={{ height: 50, marginLeft: 15 }} onClick={() => this.setState({ importExternalStorage: true })}>{'Import'.translate(this.props.lang)}</Button>
                                    :
                                    null
                            } */}
                            {
                                this.state.externalStorageLogs && this.state.externalStorageLogs.length ?
                                    <Button color='primary' className='export-btn' style={{ height: 50, marginLeft: 15 }} onClick={() => this.setState({ exportExternalStorage: true })}>{'Export'.translate(this.props.lang)}</Button>
                                    :
                                    null
                            }
                        </div>
                        <div style={{ maxHeight: '50vh', overflowY: 'scroll', overflowX: 'hidden' }}>

                            {
                                this.state.externalStorageLogs && this.state.externalStorageLogs.length ?
                                    <ListBuilder
                                        lang={this.props.lang}
                                        loading={this.state.loading}
                                        total={this.state.total}
                                        showNumeration={false}
                                        hideFilters={true}
                                        hideEntries={true}
                                        hidePagination={true}
                                        fields={[
                                            { type: 'text', name: 'groupName', label: 'Clinic group'.translate(this.props.lang), allowSort: false },
                                            { type: 'text', name: 'username', label: 'User'.translate(this.props.lang), allowSort: false },
                                            { type: 'text', name: 'ip', label: 'IP'.translate(this.props.lang), allowSort: false },
                                            { type: 'text', name: 'typeName', label: 'Method'.translate(this.props.lang), allowSort: false },
                                            { type: 'text', name: 'timestamp', label: 'Time'.translate(this.props.lang), allowSort: false },

                                        ]}
                                        rawItems={this.state.externalStorageLogs}
                                        items={this.state.externalStorageLogs.map(item => {
                                            return {
                                                ...item,
                                                timestamp: this.props.getStringDateTs(Math.floor(item.tsCreated), `${this.props.dateFormat} HH:mm:ss`)/*moment.unix(Math.floor(item.tsCreated)).format(`${this.props.dateFormat} HH:mm:ss`)*/
                                            }
                                        })}
                                        // actions={
                                        //     [
                                        //         {
                                        //             component: <Isvg src={view} className="view-icon" />,
                                        //             onClick: (item) => this.setState({ logView: item })
                                        //         },
                                        //     ]
                                        // }
                                        params={params}
                                        sortField={params.sortField}
                                        sortType={params.sortType}
                                        updateSort={this.updateSort}
                                        updateParams={this.updateParams}
                                        updateMultipleParams={this.updateMultipleParams}
                                    ></ListBuilder>
                                    :
                                    null
                            }
                        </div>

                    </ModalBody>
                </Modal>

                <DeleteModal
                    lang={this.props.lang}
                    zIndex={99999999999}
                    isOpen={this.state.exportExternalStorage}
                    toggle={() => this.setState({ exportExternalStorage: null })}
                    handler={() => { this.exportExternalStorage(); this.setState({ exportExternalStorage: null }) }}
                >
                    {'Do you want to export logs from files?'.translate(this.props.lang)}
                </DeleteModal>
                <DeleteModal
                    lang={this.props.lang}
                    zIndex={99999999999}
                    isOpen={this.state.importExternalStorage}
                    toggle={() => this.setState({ importExternalStorage: null })}
                    handler={() => { this.importExternalStorage(); this.setState({ importExternalStorage: null }) }}
                >
                    {'Do you want to import logs from files?'.translate(this.props.lang)}
                </DeleteModal>
                {this.state.error ?

                    <ErrorModal lang={this.props.lang}
                        isOpen={this.state.error}
                        toggle={() => this.setState({ error: null })}

                    >
                        {this.state.error.translate(this.props.lang)}
                    </ErrorModal>

                    :
                    null
                }
                {
                    this.state.logView ?
                        <Modal isOpen={this.state.logView} centered toggle={() => this.setState({ logView: !this.state.logView })} size={'lg'} className="clinic-modal">
                            <ModalHeader toggle={() => this.setState({ logView: !this.state.logView })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ logView: !this.state.logView })}>&times;</button>}>{'Preview log'.translate(this.props.lang)}</ModalHeader>

                            <ModalBody>
                                <Row>
                                    <Col lg="6">

                                        <p><strong>{'Endpoint:'.translate(this.props.lang)}</strong> {this.state.logView.endpoint}</p>
                                        <p><strong>{'Method:'.translate(this.props.lang)}</strong> {this.state.logView.method}</p>
                                        <p><strong>{'Status code:'.translate(this.props.lang)}</strong> {this.state.logView.status}</p>
                                        <br />
                                        <p><strong>{'Time:'.translate(this.props.lang)}</strong> {this.props.getStringDateTs(Math.floor(this.state.logView.timestamp / 1000), `${this.props.dateFormat} HH:mm:ss`)/*moment.unix(Math.floor(this.state.logView.timestamp / 1000)).format(`${this.props.dateFormat} HH:mm:ss`)*/}</p>
                                        <p><strong>{'Execution time:'.translate(this.props.lang)}</strong> {this.state.logView.executionTime} ms</p>

                                        <br />


                                    </Col>
                                    <Col lg="6">
                                        {
                                            this.state.logView.body ?
                                                <pre>
                                                    {
                                                        this.state.logView.body
                                                    }
                                                </pre>
                                                :

                                                null
                                        }

                                    </Col>
                                    <Col lg="12">
                                        <p><strong>{'Ip:'.translate(this.props.lang)}</strong> {this.state.logView.ip}</p>
                                        <p><strong>{'Referer:'.translate(this.props.lang)}</strong> {this.state.logView.referer}</p>
                                        <p><strong>{'User agent:'.translate(this.props.lang)}</strong> {this.state.logView.userAgent}</p>

                                    </Col>

                                </Row>
                            </ModalBody>
                        </Modal>
                        :
                        null
                }
                {
                    this.state.loadingExport ?
                        <div className="file-progress-loader">
                            <div>
                                <Player
                                    onEvent={(event) => {
                                        if (event === "load" && this.player && this.player.current) this.player.current.play();
                                    }}
                                    ref={this.player} // set the ref to your class instance
                                    autoplay={true}
                                    loop={true}
                                    controls={true}
                                    src="/lf30_editor_l5cxzdyf.json"
                                    renderer={'svg'}
                                    style={{ height: "128px", width: "128px", }}
                                ></Player>
                                <h6>{'Processing file...'.translate(this.props.lang)}</h6>
                            </div>

                        </div>
                        :
                        null
                }

            </div>
        );
    }
}

export default Page(Logs);