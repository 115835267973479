import React, { Component } from 'react';
import Link from '../components/link';

import Isvg from 'react-inlinesvg';
import Page from '../containers/page';

import {
    Container,
    Row,
    Col,
} from 'reactstrap';


import FormBuilder from '../components/forms/modalFormBuilder';
import { required } from '../components/forms/validation';

import ListBuilder from '../components/listBuilder';
import DeleteModal from '../components/deleteModal';
import { getSearchParams, generateSearchLink, generateSearchLinkMultiple } from '../helpers/linkHelper';
import { API_ENDPOINT } from '../constants';


var striptags = require('striptags');

/**
* Home page for patient users if thay have referrals in more then one group
* @author   Stefan Pantic
*/
class HomePage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            promotedProjects: [],
            ...props.initialData,
            form: false,

            useState: false,
            params: {
                entries: 10,
                page: 0
            },
            items: [],
        };
    }

    componentDidMount() {
        if (this.props.uData.userLevel != 20) {
            //if (this.props.uData && this.props.uData.patientGroups && this.props.uData.patientGroups.length > 1)
            //localStorage.removeItem('groupAlias')
        }


        this.forceUpdate()

        if (!this.props.uData.havePersonalReferrals && !this.props.uData.haveReferrals && !this.props.uData.patientInClinic?.length && this.props.uData.userLevel != 20) {
            this.props[0].history.push('/patient-error')
        }
        else if (!this.props.uData.havePersonalReferrals && !this.props.uData.haveReferrals && !this.props.uData.patientInClinic?.length && this.props.uData.userLevel == 20) {

            this.props[0].history.push(`/${localStorage.getItem('groupAlias')}`)
        }
        

        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }

        if (this.props.uData.patientGroups.length == 1) {
            this.props[0].history.push(`/${this.props.uData.patientGroups[0].alias}`)
        }
        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match).then((data) => {
                this.setState({
                    ...data
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

        this.props.verifyUser()
    }



    toggle = () => {
        this.setState({
            form: !this.state.form
        })
    }



    render() {
        let params = getSearchParams(this.props[0].location.search, { entries: 10, page: 0 })
        //console.log(params);
        return (
            <div className={this.props.autoHideSidebar ? "dashboard dashboard-auto-hide" : "dashboard"}>
                <Container fluid>
                    {!this.props.uData.havePersonalReferrals && !this.props.uData.haveReferrals && !this.props.uData.patientInClinic?.length ? <h1 className="pacient-message">{'Du har inte aktiv konto hos oss! För inloggning som patient använd aktuell klinik landningssida…'.translate(this.props.lang)}</h1> :

                        <div className="select-clinics-wrapper">

                            <h3>{'Choose clinic'.translate(this.props.lang)}</h3>
                            <Row>
                                <Col lg="4">
                                    <div className="choose-clinic-list">
                                        {this.props.uData.patientGroups.map(item => {
                                            return (
                                                <div>
                                                    <Link onClick={() => {
                                                        localStorage.setItem('groupAlias', item.alias)
                                                        // window.location.reload(false)
                                                        this.props.verifyUser()
                                                    }} to={`/${item.alias}`}>
                                                        <div >
                                                            <img src={API_ENDPOINT + item.logo} />
                                                            {item.name}
                                                        </div>
                                                    </Link>
                                                </div>


                                            )

                                        })
                                        }

                                    </div>
                                </Col>
                            </Row>


                        </div>
                    }


                </Container>

            </div>

        );


    }
}

export default Page(HomePage);