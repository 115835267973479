import React, { Component } from 'react';
import Isvg from 'react-inlinesvg';
// import { Link } from 'react-router-dom'
import Link from './sidebarLink';
import notification from '../assets/svg/notification.svg';
import moreIcon from '../assets/svg/more.svg';
import info from '../assets/svg/info.svg'
import profileUser from '../assets/images/user.png';
import SelectWihtCheckbox from './forms/fields/selectWithCheckbox'
import { API_ENDPOINT } from '../constants';


/**
* Checks if there is a new notification
* @author Stefan Pantic
*/

import {
    Container,
    Row,
    Col, Input, Label, Modal, ModalBody, ModalHeader, FormGroup, Button
} from 'reactstrap';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import moment from 'moment';
function parseJSON(response) {
    return response.json().then(json => {
        return {
            result: json,
            status: response.status
        }
    })
}
export class Notification extends Component {
    constructor(props) {
        super(props);
        this.wrapperRef = React.createRef();
        this.dropdownMenuRef = React.createRef();
        this.handleClickOutside = this.handleClickOutside.bind(this);

        this.state = {
            professionModal: false,
            dropdownMenu: false
        };
    }


    componentDidMount() {
        // this.interval = setInterval(() => {
        //     this.check();
        // }, 5000);

        if (this.props.uData && this.props.uData.lastNotifications) {

            this.setState({
                notifCount: this.props.uData.lastNotifications.length ? this.props.uData.lastNotifications.length : 0
            })
        }

        if (this.props.selectedGroup && this.props.uData && this.props.uData.userLevel == 20 && typeof window != 'undefined' && localStorage?.getItem?.('profile') != 'patient') {
            if (!this.props.uData.profession || (this.props.uData.profession && !this.props.uData.profession[this.props.selectedGroup])) {
                fetch(API_ENDPOINT + '/data/groups-professions/all', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({group: this.props.selectedGroup})
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        this.setState({
                            professionModal: result
                        })
                })
            }
        }

        document.addEventListener('mousedown', this.handleClickOutside);

        this.tzInt = setInterval(() => {
            this.setState({ ts: new Date().getTime() })
        }, 1000);

    }

    componentDidUpdate(prevProps) {
        if (this.props.uData && prevProps.uData && this.props.uData.lastNotifications != prevProps.uData.lastNotifications) {

            this.setState({
                notifCount: this.props.uData.lastNotifications.length ? this.props.uData.lastNotifications.length : 0
            })

        }

        if (this.props.selectedGroup && prevProps.selectedGroup != this.props.selectedGroup && this.props.uData && this.props.uData.userLevel == 20 && typeof window != 'undefined' && localStorage?.getItem?.('profile') != 'patient') {

            if (!this.props.uData.profession || (this.props.uData.profession && !this.props.uData.profession[this.props.selectedGroup]) || (this.props.uData.profession && this.props.uData.profession[this.props.selectedGroup]) == '-1') {

                fetch(API_ENDPOINT + '/data/groups-professions/all', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({group: this.props.selectedGroup})
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        this.setState({
                            professionModal: result
                        })
                })

            }
        }
    }

    componentWillUnmount() {
        if (this.interval) {
            clearInterval(this.interval)
        }
        if (this.tzInt) {
            clearInterval(this.tzInt)
        }

        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside(event) {
        if (this.wrapperRef && this.wrapperRef.contains && !this.wrapperRef.contains(event.target) && this.dropdownMenuRef && this.dropdownMenuRef.contains && !this.dropdownMenuRef.contains(event.target)) {
            this.setState({ dropdownMenu: false })
        }
    }

    /**
    * Checks if there is a new notification for logged user
    * @author   Stefan pantic
    */
    check = () => {
        fetch(API_ENDPOINT + '/notification/last', {
            method: 'POST',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },

        }).then(res => res.json()).then((result) => {

            if (result) {
                if (localStorage.getItem('notif') != result?._id) {
                    this.props.verifyUser()
                    this.setState({
                        newNotif: true
                    })

                } else {
                    this.setState({
                        newNotif: false
                    })
                }
            }

        })


    }

    updateUser = () => {
        let obj = {};
        obj[this.props.selectedGroup] = this.state.profession
        fetch(API_ENDPOINT + '/users/subaccounts/log-in-category/' + this.props.uData?._id, {
            method: 'POST',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
            body: JSON.stringify({ profession: obj, group: this.props.selectedGroup, clinic: this.props.selectedClinic })
        }).then(parseJSON).then(({ result, status }) => {
            this.props.verifyUser()
            this.setState({
                profession: !this.state.profession
            })
        })
    }


    render() {
        if (this.props[0].match.path == '/:alias' || this.props[0].match.path == '/:mainAlias/:alias' || this.props[0].match.path == '/:mainAlias/:alias/information' || this.props[0].match.path == '/:mainAlias/:alias/login' || this.props[0].match.path == '/video-call/:conversation' || this.props[0].match.path == '/:alias/login' || this.props[0].match.path == '/my-data/clinic-data/landing-configurator/:id/:tab/:group/:type' || this.props[0].match.path == '/data-managment/landing-configurator/:tab/:id/:type' || this.props[0].match.path == '/:alias/information' || !this.props.uData || this.props[0].match.path == '/:alias/privacy-policy' || this.props[0].match.path == '/:alias/terms-of-use' || this.props[0].match.path == '/:alias/accessibility-report' || this.props[0].match.path == '/chat/:windowConversation' || this.props?.[0]?.match?.path === '/:alias/e-connect') {
            return (
                <>
                    {
                        this.props.logoutMessage ?
                            <Modal isOpen={this.props.logoutMessage} centered >
                                {/* <ModalHeader style={{ margin: 'auto' }}>{'Notification'.translate(this.props.lang)}</ModalHeader> */}

                                <ModalBody className='delete-modal'>
                                    {/* <img src={info} /> */}
                                    <p className='message'>{'You have been logged out due to inactivity.'.translate(this.props.lang)}</p>
                                </ModalBody>
                                <ModalFooter className='buttons-right-blue'>
                                    <Button color="primary" style={{ float: 'right', minWidth: 100 }} onClick={() => this.props.disableLogoutMessage()}>{"Ok".translate(this.props.lang)}</Button>

                                </ModalFooter>
                            </Modal>

                            :
                            null
                    }
                </>
            );
        }

        if (this.props.hideNavbar) {
            return null
        }

        let tsNow = Math.floor(new Date().getTime() / 1000);
        let tsOffset = new Date().getTimezoneOffset()
        let time = this.props.getStringDateTs(tsNow, 'DD.MM.YYYY HH:mm:ss')/*moment.unix(tsNow).format('DD.MM.YYYY HH:mm:ss')*/


        let timeC = this.props.getStringDate(new Date(), 'DD.MM.YYYY HH:mm:ss')



        return (
            <div className={this.props.autoHideSidebar ? 'component notifications notifications-auto-hide' : 'component notifications'}>
                {/* <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div style={{ fontSize: 12, display: 'flex' }}>
                        Client: &nbsp;&nbsp; <div style={{ width: 100 }}>ts: <span style={{ fontWeight: 700 }}>{tsNow}</span></div> &nbsp; <div style={{ width: 180 }}>time: <span style={{ fontWeight: 700 }}>{time}</span></div> &nbsp; tsOffset: <span style={{ fontWeight: 700 }}>{tsOffset}</span>
                    </div>
                    <div style={{ fontSize: 12, display: 'flex' }}>
                        Converted time:&nbsp;&nbsp; <span style={{ fontWeight: 700 }}> {timeC}</span>
                    </div>

                </div> */}
                <Link check={this.props.groupSettingsModal} openModal={(item) => this.props.openModal(item)} to='/notifications'>
                    <div className="nofitication-container">
                        <Isvg src={notification} />
                        {this.state.notifCount > 0 ?
                            <div className="notification-cricle"></div>
                            :
                            null
                        }
                        <div className='nofitication-container-tooltip'>{'Notifications'.translate(this.props.lang)}</div>
                    </div>
                </Link>
                <div className='profile'>
                    <Link check={this.props.groupSettingsModal} refershPageDisabled={true} openModal={(item) => this.props.openModal(item)} onClick={() => this.setState({ dropdownMenu: !this.state.dropdownMenu })} /*to="/my-data/account"*/>
                        <span className='name' >{this.props.uData ? this.props.uData.userData.name + " " + (this.props.uData.userData.title && typeof localStorage && localStorage.getItem('profile') != 'patient' && this.props.uData.userLevel >= 20 ? this.props.uData.userData.title : '') : null}</span>
                        <img src={this.props.uData && this.props.uData.profilePicture ? API_ENDPOINT + this.props.uData.profilePicture : profileUser} className="profile-image" />
                        <span ref={node => this.dropdownMenuRef = node}><Isvg src={moreIcon} /></span>
                        <ul className={this.state.dropdownMenu ? 'notification-dropdown dropdown-opened' : 'notification-dropdown'} ref={node => this.wrapperRef = node}>
                            <li>
                                <Link to="/my-data/account">{'My profile'.translate(this.props.lang)}</Link>
                            </li>
                            <li>
                                <a onClick={() => {
                                    this.props.signOut()
                                    this.props.abortAction()
                                    this.props.registerLog('Logged out')
                                }
                                }>
                                    {'Logout'.translate(this.props.lang)}
                                </a>
                            </li>
                        </ul>
                    </Link>
                </div>
                {
                    this.props.logoutMessage ?
                        <Modal isOpen={this.props.logoutMessage} centered >
                            {/* <ModalHeader style={{ margin: 'auto' }}>{'Notification'.translate(this.props.lang)}</ModalHeader> */}

                            <ModalBody className='delete-modal'>
                                {/* <img src={info} /> */}
                                <p className='message'>{'You have been logged out due to inactivity.'.translate(this.props.lang)}</p>
                            </ModalBody>
                            <ModalFooter className='buttons-right-blue'>
                                <Button color="primary" style={{ float: 'right', minWidth: 100 }} onClick={() => this.props.disableLogoutMessage()}>{"Ok".translate(this.props.lang)}</Button>

                            </ModalFooter>
                        </Modal>

                        :
                        null
                }

                {
                    this.state.professionModal && typeof window != 'undefined' && localStorage?.getItem?.('profile') != 'patient' ?
                        <Modal isOpen={this.state.professionModal} centered >
                            {/* <ModalHeader style={{ margin: 'auto' }}>{'Select groups'.translate(this.props.lang)}</ModalHeader> */}

                            <ModalBody className='delete-modal'>
                                {/* <img src={info} /> */}
                                <p className='message'>{'You have to select groups with first login in new clinic group.'.translate(this.props.lang)}</p>
                                {/* <Input type='select' style={{ width: '100%' }}
                                    onChange={e => {

                                        this.setState({ profession: e.target.value == -1 ? null : e.target.value })
                                    }}
                                    value={this.state.profession}
                                >
                                    <option value={-1}>{'Select groups'.translate(this.props.lang)}</option>

                                    {this.state.professionModal.map(item => {
                                        return <option value={item._id}>
                                            {this.props.lang == 'en' ? item.professionEn : item.professionSv}
                                        </option>
                                    })}

                                </Input> */}
                                <SelectWihtCheckbox placeHolder={'Select group(s)'.translate(this.props.lang)} values={this.state.professionModal.map(item => {return { name: this.props.lang === 'en' ? item.professionEn : item.professionSv, value: item?._id }})} value={this.state.profession} onChange={(value) => {this.setState({profession: value})}} />
                            </ModalBody>
                            <ModalFooter className='buttons-right-blue'>
                                <Button color="primary" style={{ float: 'right', minWidth: 100 }} disabled={this.state.profession && this.state.profession.length ? false : true} onClick={() => this.setState({ professionModal: !this.state.professionModal }, () => this.updateUser())}>{"Set groups".translate(this.props.lang)}</Button>

                            </ModalFooter>
                        </Modal>

                        :
                        null
                }
            </div>
        )
    }
}

export default Notification;