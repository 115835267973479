import React from 'react';
import { SelectableGroup, createSelectable } from 'react-selectable';

import garabage from '../assets/svg/garbage.svg'
import Isvg from 'react-inlinesvg'


// import './selectable.css'

class SelectableItem extends React.Component {
    constructor(props) {
        super(props);

        this.state = {

        };
    }

    componentDidMount() {

    }

    render() {
        return (
            <div style={this.props.style}>
                {
                }
            </div>
        )
    }

}


const SelectableComponent = createSelectable(SelectableItem);


class SelectableInterval extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedKeys: [],
            selectionXStart: null
        };
    }

    componentDidMount() {

    }

    handleSelection = (selectedKeys) => {
        if (this.props.onBlockSelection) {
            this.setState({ selectedKeys });
        }
    }

    onBeginSelection = (event) => {
        if (this.props.onBlockSelection) {
            this.setState({
                selectionXStart: event.clientX,
                selectionXEnd: event.clientX,
                selectionInProgress: true
            })
        }


    }

    onEndSelection = (selected, event) => {
        this.setState({ selectionInProgress: false })
        if (this.props.onBlockSelection) {
            this.setState({
                selectionXStart: null,
                selectionXEnd: null

            })
            selected.sort(function (a, b) {
                return a - b;
            });

            let items = [];
            for (let i = this.props.min; i <= this.props.max; i++) {
                items.push(i);
            }

            let used = [];
            for (let i = 0; i < this.props.blocks.length; i++) {
                for (let j = this.props.blocks[i].start; j <= this.props.blocks[i].end; j++) {
                    used.push(j);
                }
            }

            let start = selected[0];
            let end = selected[selected.length - 1];


            let overlap = true;
            let started = false;

            for (let i = start; i <= end; i++) {
                if (used.indexOf(i) !== -1) {
                    if (started) {
                        end = i - 1;
                        break;
                    }

                    continue;
                } else {
                    overlap = false;
                    if (!started)
                        start = i;

                    started = true;

                }
            }

            if (!overlap) {
                this.props.onBlockSelection({
                    start: start,
                    end: end
                })
            }
        }



    }
    conditionIndexOf = (conditions, conditionQuestion, conditionAnswer) => {
        if (!conditions) {
            return -1;
        }

        for (let i = 0; i < conditions.length; i++) {
            if (!conditionAnswer) {
                if (conditions[i].conditionQuestion == conditionQuestion) {
                    return i;
                }
            } else {
                if (conditions[i].conditionQuestion == conditionQuestion && conditions[i].conditionAnswer == conditionAnswer) {
                    return i;
                }
            }
        }

        return -1;
    }

    render() {
        let items = [];
        for (let i = this.props.min; i <= this.props.max; i++) {
            items.push(i);
        }

        let left = 0;
        let width = 100;
        if (this.state.containerRef && this.state.containerRef.getBoundingClientRect()) {
            left = this.state.containerRef.getBoundingClientRect().left;
            width = this.state.containerRef.getBoundingClientRect().width;
        }


        return (
            <div className='selectable-interval' style={{ height: (this.props.blocks.length + 1) * this.props.blockHeight + 2 * this.props.blocks.length }} onMouseMove={(event) => {
                if (this.props.onBlockSelection) {
                    if (this.state.selectionXStart) {
                        this.setState({
                            selectionXEnd: event.clientX
                        })
                    }
                }


            }} ref={(node) => {
                if (!this.state.containerRef) {
                    this.setState({
                        containerRef: node
                    })
                }
            }}>
                <SelectableGroup onBeginSelection={this.onBeginSelection} onEndSelection={this.onEndSelection} onSelection={this.handleSelection} selectingClassName={'selected'}>
                    {items.map((item, idx) => {
                        let selected = this.state.selectedKeys.indexOf(item) > -1;
                        return (
                            <SelectableComponent key={item} selected={selected} selectableKey={item} style={{ width: width / items.length, backgroundColor: this.state.selectionXStart !== null && Math.min(this.state.selectionXStart, this.state.selectionXEnd) <= left + (idx + 1) * (width / items.length) && Math.max(this.state.selectionXStart, this.state.selectionXEnd) >= left + idx * (width / items.length) + (width / items.length) / 3 ? 'rgba(200,20,30,0.1)' : 'transparent' }}>
                                {item}

                            </SelectableComponent>
                        );
                    })}
                </SelectableGroup>

                <div className='ruller'>
                    <div className='selection-in-progress-wrap' >
                        {
                            this.state.selectedKeys && this.state.selectedKeys.length && this.state.selectionInProgress ?
                                <p>
                                    {
                                        Number(this.state.selectedKeys.sort(function (a, b) {
                                            return a - b;
                                        })[0]) >= 0 ?
                                            this.state.selectedKeys.sort(function (a, b) {
                                                return a - b;
                                            })[0]
                                            :
                                            <>(
                                                {this.state.selectedKeys.sort(function (a, b) {
                                                    return a - b;
                                                })[0]}
                                                )</>
                                    }
                                    {
                                        this.state.selectedKeys.length > 1 ?
                                            ' - '
                                            :
                                            null
                                    }
                                    {
                                        this.state.selectedKeys.length > 1 ?
                                            Number(this.state.selectedKeys.sort(function (a, b) {
                                                return a - b;
                                            })[this.state.selectedKeys.length - 1]) >= 0 ?
                                                this.state.selectedKeys.sort(function (a, b) {
                                                    return a - b;
                                                })[this.state.selectedKeys.length - 1]
                                                :
                                                <>(
                                                    {this.state.selectedKeys.sort(function (a, b) {
                                                        return a - b;
                                                    })[this.state.selectedKeys.length - 1]}
                                                    )</>

                                            :
                                            null
                                    }
                                </p>
                                :
                                null
                        }
                    </div>
                    {items.map((item, idx) => {
                        return (
                            <div>{(item % 5 == 0) || (idx + 1 == items.length || idx == 0) ? item : ''}</div>
                        );
                    })}
                </div>

                <div className='blocks'>
                    {
                        this.props.blocks.map((item, idx) => {
                            return (
                                <div onClick={() => { this.props.onClick(item, idx) }}
                                    className={this.props.linkingElements ? (this.props.showLink && this.props.item && this.props.items && this.conditionIndexOf(this.props.showLink, this.props.item.id, item.id) != -1) || (this.props.linkingElement !== null && this.props.items[this.props.linkingElement].conditions && this.props.items[this.props.linkingElement].conditions.length && this.conditionIndexOf(this.props.items[this.props.linkingElement].conditions, this.props.item.id, item.id) != -1) ? 'selectable-block-item-wrap selectable-block-item-active-linking-element' : 'selectable-block-item-wrap selectable-block-item-linking-element' : (this.props.showLink && this.props.item && this.conditionIndexOf(this.props.showLink, this.props.item.id, item.id) != -1) ? 'selectable-block-item-wrap selectable-block-item-active-linking-element' : 'selectable-block-item-wrap'}
                                    style={{ cursor: 'pointer', position: 'absolute', top: idx * this.props.blockHeight + (idx + 1) * 2, height: this.props.blockHeight, width: (item.end - item.start + 1) * (width / items.length), left: items.indexOf(item.start) * (width / items.length), border: this.props.linkingElements ? '1px solid #60BEBB' : 'none', backgroundColor: this.props.linkingElement ? 'transparent' : this.props.tooltip ? item[this.props.tooltip] ? 'rgba(20,220,30,0.3)' : 'rgba(200,20,30,0.3)' : 'rgba(20,220,30,0.3)', display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: 10 }}>
                                    {item.start == item.end ? item.start : `${item.start}-${item.end}`}
                                    {
                                        this.props.deleteBlockHandler ?
                                            <div className='delete-selectable-block-item' onClick={(e) => {
                                                e.stopPropagation()
                                                this.props.deleteBlockHandler(item, idx)
                                            }}>
                                                <Isvg src={garabage} />
                                            </div>
                                            :
                                            null
                                    }
                                    {
                                        item && this.props.tooltip && item[this.props.tooltip] ?
                                            <div className='selectable-block-item-tooltip'>{item[this.props.tooltip]}</div>
                                            :
                                            null
                                    }
                                </div>

                            )
                        })
                    }
                </div>

            </div>
        )
    }

}

export default SelectableInterval;