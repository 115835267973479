import PropTypes from "prop-types";
import React from "react";

import Week from "react-big-calendar/lib/Week";
import TimeGrid from "react-big-calendar/lib/TimeGrid";
import moment from 'moment';

function workWeekRange(date, options) {
    let calendarDay = date.getDay()
    var curr = new Date;
    var lastday = new Date(curr.setDate(curr.getDate() - curr.getDay() + 6));
    let disallowedDays = []

    if (date < lastday) {
        for (let i = 1; i <= 6; i++) {
            if (calendarDay > i) {
                disallowedDays.push(i)
            }
        }
    }

    return Week.range(date, options).filter(
        (d) => disallowedDays.indexOf(d.getDay()) === -1
    );
}

/**
* Component for custom week in calendar
* @author   Stefan Pantic
*/
class MyWorkWeek extends React.Component {
    render() {
        let { date, ...props } = this.props;
        let range = workWeekRange(date, this.props);

        return <TimeGrid {...props} range={range} eventOffset={15} />;
    }
}

MyWorkWeek.propTypes = {
    date: PropTypes.instanceOf(Date).isRequired
};

MyWorkWeek.defaultProps = TimeGrid.defaultProps;

MyWorkWeek.range = workWeekRange;

MyWorkWeek.navigate = Week.navigate;

MyWorkWeek.title = (date, { localizer }) => {
    let [start, ...rest] = workWeekRange(date, { localizer });

    return localizer.format({ start, end: rest.pop() }, "dayRangeHeaderFormat");
};

export default MyWorkWeek;
