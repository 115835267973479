import React, { Component } from 'react';

import {
    Container,
    Row,
    Col,
    FormGroup, Label, Input,
    Button,
    Modal, ModalHeader, ModalBody, ModalFooter, Table
} from 'reactstrap';



import ListBuilder from './listBuilder';
import { getSearchParams, generateSearchLink, generateSearchLinkMultiple } from '../helpers/linkHelper';
import { API_ENDPOINT, GOOGLE_API_KEY } from '../constants';
import moment from 'moment';
import eRemissIcon from '../assets/svg/e.svg';
import import2 from '../assets/svg/import2.svg';
import Checkbox from '../components/forms/fields/checkbox';
import Isvg from 'react-inlinesvg';
import xIcon from '../assets/svg/x.svg';


function parseJSON(response) {
    return response.json().then(json => {
        return {
            result: json,
            status: response.status
        }
    })
}


function getDistance(a, b, c, d,) {
    return getDst([a, b], [c, d]);
}

function rad(x) {
    return x * Math.PI / 180;
}

function getDst(p1, p2) {
    var R = 6378137; // Earth’s mean radius in meter
    var dLat = rad(p2[0] - p1[0]);
    var dLong = rad(p2[1] - p1[1]);
    var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(rad(p1[0])) * Math.cos(rad(p2[0])) *
        Math.sin(dLong / 2) * Math.sin(dLong / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;
    return (d / 1000).toFixed(1); // returns the distance in km
}

const loadDataWithQuery = [
    (fetchFunction, lang, match, query) => {

        // let queryFields = {};
        // for (var key in query) {
        //     if (key && key.indexOf('query-') === 0) {
        //         queryFields[key.replace('query-', '')] = query[key];
        //     }
        // }

        return fetchFunction(API_ENDPOINT + '/data/daybook-events', {
            method: 'POST',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
            body: JSON.stringify({
                // page: query.page,
                // entries: query.entries,
                page: null,
                entries: null,
                filter: query.filter,
                sortType: query.sortType,
                sortField: query.sortField,
                group: query.group,
                clinic: query.clinic
                // query: queryFields,
                // group: query.group,
            })
        }).then(parseJSON).then(({ result, status }) => {
            if (status >= 200 && status < 300)
                return {
                    // page: query.page ? query.page : 0,
                    items: result.items,
                    total: result.total,
                }
        })

    },

]

/**
* List of referral for selected clinic
* @author   Milan Stanojevic
*/
class SelectReferral extends Component {
    constructor(props) {
        super(props);

        this.state = {
            regions: [],
            cities: [],
            groups: [],
            subgroups: [],
            ...props.initialData,
            useState: true,
            params: {
                entries: 10,
                page: 0,
                'query-group': null
            },
            items: [],
            total: 0,
            loading: true,
            suggestions: [],
            checkedAll: []
        };
    }


    get = () => {
        // console.log(this.props[0])
        let params = this.state.useState ? { ...this.state.params, items: this.state.items, filter: this.state.filter, group: this.props.selectedGroup, clinic: this.props.selectedClinic } : getSearchParams(this.props[0].location.search, { entries: 10, page: 0, 'query': null, items: this.props.items,group: this.props.selectedGroup, clinic: this.props.selectedClinic });

        this.setState({
            _ts: params._ts
        }, () => {
            for (let i = 0; i < loadDataWithQuery.length; i++) {
                loadDataWithQuery[i](window.fetch, this.props.lang, this.props[0].match, params).then((data) => {
                    // if (data && data._clinicSearchTs && this.state._ts == data._clinicSearchTs || (data && !data._clinicSearchTs)) {
                    
                    if(data.items && data.items.length){
                        // console.log(data)
                        let items = [];
                        let total = 0;
                        for (let i = 0; i < data.items.length; i++) {
                            if(data.items[i].active){
                                items.push(data.items[i]);
                                total++
                            }
                            
                        }

                        data.items = items;
                        data.total = total;

                        // console.log(data)
                    }

                    this.setState({
                        ...data,
                        loading: null
                    })
                    // }
                })
            }

        })
    }


    componentDidMount() {
        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }

        this.get();


    }

    componentWillUnmount() {

    }



    componentDidUpdate(prevProps, prevState) {
        // if (prevProps[0].location.search != this.props[0].location.search) {
        //     this.setState({
        //         loading: true
        //     }, () => {
        //         this.get();

        //     })

        // }

        if (JSON.stringify(prevState.params) != JSON.stringify(this.state.params)) {
            this.get();
        }

        if (this.state.useState && prevState._useStateTs != this.state._useStateTs) {
            this.setState({
                loading: true
            }, () => {
                this.get();

            })

        }


    }


    updateSort = (field, type) => {
        if (this.state.useState) {
            let state = this.state.params;
            state.sortField = field;
            state.sortType = type;
            this.setState({
                params: state
            })

            this.setState({
                _useStateTs: new Date().getTime()
            })

        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0 }, [{ name: 'sortField', value: field }, { name: 'sortType', value: type }], false));

        }
    }

    updateParams = (name, value, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };

                obj[name] = value;

                this.setState({ params: obj });
            } else {
                let obj = this.state.params;
                obj[name] = value;


                this.setState({ params: obj })
            }

            this.setState({
                _useStateTs: new Date().getTime()
            })
        } else {
            this.props[0].history.push(generateSearchLink(this.props[0].location.search, { entries: 10, page: 0 }, name, value, restart));
        }
    }

    updateMultipleParams = (fields, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj });

            } else {
                let obj = this.state.params;
                for (let i = 0; i < fields.length; i++) {

                    obj[fields[i].name] = fields[i].value;

                }
                this.setState({ params: obj })
            }

            this.setState({
                _useStateTs: new Date().getTime()
            })

        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0 }, fields, restart));
        }
    }


    render() {
        let params = {};
        if (this.state.useState) {
            params = this.state.params;
        } else {
            params = getSearchParams(this.props[0].location.search, { entries: 10, page: 0 })
        }

        return (
            <div>
                {this.props.isOpen ?
                    <Modal isOpen={this.props.isOpen} toggle={this.props.toggle} centered size={this.props.size ? this.props.size : null} >

                        <ModalHeader style={{ margin: 'auto' }} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.props.onClose(false)}><Isvg src={xIcon} /></button>}>
                            {"Daybook forms".translate(this.props.lang)}
                        </ModalHeader>
                        <ModalBody className="table-daybook-wrap">
                            <Row className="table-daybook-wrap-container">
                                <div style={{ display: 'flex', alignItems: 'center', marginLeft: 10, marginRight: 10 }}>
                                    <Label>{'Search'.translate(this.props.lang)}</Label>
                                    <Input style={{marginLeft:10}} value={this.state.filter}
                                            onChange={(e) => {
                                                this.setState({ filter: e.target.value }, () => this.get())
                                            }}
                                        />
                                </div>
                                <div>
                                    <Button className="table-daybook-button" color='primary' onClick={() => {
                                        if (this.state.items && this.state.items.length) {
                                            let checkedAll = [];
                                            for (let i = 0; i < this.state.items.length; i++) {
                                                checkedAll.push(this.state.items[i]._id)
                                                
                                            }
                                            this.setState({ checkedAll })
                                        }
                                    }}>{'Mark all'.translate(this.props.lang)}</Button>
                                </div>
                            </Row>
                            <Row>

                                <Col lg="12">

                                    <Table response >
                                        <thead>
                                            {<tr>
                                                <th>{'Name'.translate(this.props.lang)}</th>
                                                <th>{'Description'.translate(this.props.lang)}</th>
                                                <th></th>

                                            </tr>}

                                        </thead>
                                        <tbody>
                                            {
                                                this.state.items.map((item, idx) => {
                                                    return (
                                                        <tr key={idx}>
                                                            <td>{item.name}</td>
                                                            <td>{item.description}</td>
                                                            <td>
                                                                <Checkbox checked={this.state.checkedAll.indexOf(item._id) != -1} onChange={(e) => {
                                                                    let checkedAll = this.state.checkedAll;
                                                                    let index = checkedAll.indexOf(item._id);
                                                                    if(index != -1){
                                                                        checkedAll.splice(index, 1);
                                                                    }else{
                                                                        checkedAll.push(item._id)
                                                                    }

                                                                    this.setState({ checkedAll })


                                                                }} />
                                                    
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }

                                        </tbody>

                                    </Table>
                                </Col>
                            </Row>
                        </ModalBody>
                        <ModalFooter>
                            <Button color='primary' disabled={this.state.checkedAll && this.state.checkedAll.length ? false : true} onClick={() => this.props.onClose(this.state.checkedAll)}>{'Insert'.translate(this.props.lang)}</Button>

                        </ModalFooter>
                    </Modal>

                    :
                    null
                }

            </div >
        );


    }
}
export default SelectReferral;