import React, { Component } from 'react';
function generateInverseRadius(r) {
    var points = []
    points.push([r, 0]);

    for (var alpha = Math.PI; alpha < Math.PI + Math.PI / 2; alpha += 0.01) {
        points.push([Math.sin(alpha) * r * -1, r + Math.cos(alpha) * r]);
    }

    var path = `M${points[0][1]} ${points[0][0]} L${points[1][1]} ${points[1][0]} `

    for (let i = 2; i < points.length; i++) {
        path += `${points[i][1]} ${points[i][0]} `
    }
    //points.push([r,0]);
    return `${path} Z`;
}

/**
* Component for import and style image
* @author   Milan Stanojevic
*/
class InverseRadius extends Component {
    constructor(props) {
        super(props);

        this.state = {

        };
    }


    render() {
        return (
            <>
                {
                    this.props.style && this.props.style.borderTopLeftRadius < 0 ?
                        <div style={{ position: 'absolute', top: this.props.style.borderTopLeftRadius + 'px', left: 0 }}>
                            <svg height={Math.abs(this.props.style.borderTopLeftRadius)} width={Math.abs(this.props.style.borderTopLeftRadius)} viewbox={`0 0 ${Math.abs(this.props.style.borderTopLeftRadius)} ${Math.abs(this.props.style.borderTopLeftRadius)}`}>
                                <path fill={this.props.style.backgroundColor} d={generateInverseRadius(Math.abs(this.props.style.borderTopLeftRadius))}></path>
                            </svg>
                        </div>

                        :

                        null
                }
                {
                    this.props.style && this.props.style.borderTopRightRadius < 0 ?
                        <div style={{ position: 'absolute', top: this.props.style.borderTopRightRadius + 'px', right: 0, transform: 'scaleX(-1)' }}>
                            <svg height={Math.abs(this.props.style.borderTopRightRadius)} width={Math.abs(this.props.style.borderTopRightRadius)} viewbox={`0 0 ${Math.abs(this.props.style.borderTopRightRadius)} ${Math.abs(this.props.style.borderTopRightRadius)}`}>
                                <path fill={this.props.style.backgroundColor} d={generateInverseRadius(Math.abs(this.props.style.borderTopRightRadius))}></path>
                            </svg>
                        </div>

                        :

                        null
                }

{
                    this.props.style && this.props.style.borderBottomLeftRadius < 0 ?
                        <div style={{ position: 'absolute', bottom: this.props.style.borderBottomLeftRadius + 'px', left: 0, transform: 'scaleY(-1)' }}>
                            <svg height={Math.abs(this.props.style.borderBottomLeftRadius)} width={Math.abs(this.props.style.borderBottomLeftRadius)} viewbox={`0 0 ${Math.abs(this.props.style.borderBottomLeftRadius)} ${Math.abs(this.props.style.borderBottomLeftRadius)}`}>
                                <path fill={this.props.style.backgroundColor} d={generateInverseRadius(Math.abs(this.props.style.borderBottomLeftRadius))}></path>
                            </svg>
                        </div>

                        :

                        null
                }

{
                    this.props.style && this.props.style.borderBottomRightRadius < 0 ?
                        <div style={{ position: 'absolute', bottom: this.props.style.borderBottomRightRadius + 'px', right: 0, transform: 'scale(-1, -1)' }}>
                            <svg height={Math.abs(this.props.style.borderBottomRightRadius)} width={Math.abs(this.props.style.borderBottomRightRadius)} viewbox={`0 0 ${Math.abs(this.props.style.borderBottomRightRadius)} ${Math.abs(this.props.style.borderBottomRightRadius)}`}>
                                <path fill={this.props.style.backgroundColor} d={generateInverseRadius(Math.abs(this.props.style.borderBottomRightRadius))}></path>
                            </svg>
                        </div>

                        :

                        null
                }


            </>
        );
    }
}

export default InverseRadius;