import React, { Component } from 'react';
import Page from '../../containers/page';

import {
    Container,
    Row,
    Col,
    Modal, ModalBody, ModalFooter, ModalHeader, Button
} from 'reactstrap';

import Isvg from 'react-inlinesvg'

import { formValueSelector, change } from 'redux-form';  // ES6
import { connect } from 'react-redux';

import FormBuilder from '../../components/forms/modalFormBuilder';
import { required } from '../../components/forms/validation';

import ListBuilder from '../../components/listBuilder';
import DeleteModal from '../../components/deleteModal';
import { getSearchParams, generateSearchLink, generateSearchLinkMultiple } from '../../helpers/linkHelper';
import { API_ENDPOINT } from '../../constants';
import { routes } from '../../routesList';

import edit from '../../assets/svg/edit.svg'
import garabage from '../../assets/svg/garbage.svg'
import Search from '../../components/search';
import addIcon from '../../assets/svg/add-icon.svg';
import importIcon from '../../assets/svg/import.svg'
import exportIcon from "../../assets/svg/export.svg";
import xIcon from '../../assets/svg/x.svg';


import profileUser from '../../assets/images/user.png';
import notification from '../../assets/svg/notification.svg'
import moreIcon from '../../assets/svg/more.svg'

import { Player, Controls } from '@lottiefiles/react-lottie-player';
import UploadJSON from '../../components/forms/fields/uploadJSON';
import InfoModal from '../../components/infoModal';


/**
* List of modules
* @author   Milan Stanojevic
*/
class ManageModules extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...props.initialData,
            form: false,
            useState: false,
            params: {
                entries: 10,
                page: 0
            },
            items: [],
            endpoints: [],
            formModules: [],
            total: 0,
            loading: true
        };
    }


    get = () => {
        this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));

        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0 })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

        for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
            this.props.loadDataWithQuery[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0 })).then((data) => {
                let dataToSet = { ...data }
                if (dataToSet.page || dataToSet.items || dataToSet.total) {
                    dataToSet.loading = null;
                    // console.log(dataToSet.page || dataToSet.items || dataToSet.total)
                    let checkFilter = '';
                    if (dataToSet.resultFilter) {
                        checkFilter = String(dataToSet.resultFilter)
                        delete dataToSet.resultFilter;
                    }
                    let search = this.props[0].location.search;
                    let serachParams = getSearchParams(search);
                    let searchParamsFilter = '';
                    if (serachParams.filter) {
                        searchParamsFilter = serachParams.filter;
                    }
                    if (searchParamsFilter != checkFilter) {
                        delete dataToSet.page;
                        delete dataToSet.items;
                        delete dataToSet.total;
                        delete dataToSet.loading;
                    }
                }
                this.setState({
                    ...dataToSet,
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

    }

    componentDidMount() {
        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }

        this.get();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps[0].location.search != this.props[0].location.search) {
            this.setState({
                loading: true
            }, () => {
                this.get();

            })
        }

        if (prevState.uploadedJSON?.fileName !== this.state.uploadedJSON?.fileName) {
            this.checkJSON()
        }
    }



    updateSort = (field, type) => {
        if (this.state.useState) {
            let state = this.state.params;
            state.sortField = field;
            state.sortType = type;
            this.setState({
                params: state
            })
        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0 }, [{ name: 'sortField', value: field }, { name: 'sortType', value: type }], false));

        }
    }

    updateParams = (name, value, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };
                obj[name] = value;
                this.setState({ params: obj });
            } else {
                let obj = this.state.params;
                obj[name] = value;
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(generateSearchLink(this.props[0].location.search, { entries: 10, page: 0 }, name, value, restart));
        }
    }
    updateMultipleParams = (fields, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj });

            } else {
                let obj = this.state.params;
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0 }, fields, restart));
        }
    }


    /**
    * Insert or update module
    * @author   Milan Stanojevic
    * @Objectparam    {String} name.en                      module's name on english
    * @Objectparam    {String} name.se                      module's name on swedish
    * @Objectparam    {String} link                         module's link
    * @Objectparam    {String} type                         module's type  (menu, submenu, page)
    * @Objectparam    {String} parent                       module's parent module
    * @Objectparam    {String} svgIcon                      module's icon
    * @Objectparam    {String} uDataCondition               user data condition
    * @Objectparam    {String} clinicEntryModule            clinic entry module (true, false)
    * @Objectparam    {Array} endpoints                     List of approved API endpoints
    */
    insertOrUpdate = (data) => {
        this.setState({
            loading: true,
            _saving: true
        }, () => {
            if (!this.state.form._id) {

                fetch(API_ENDPOINT + '/modules/new', {
                    method: 'PUT',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify(data)
                }).then(res => res.json()).then((result) => {
                    this.get();
                    this.setState({
                        _saving: false
                    }, () => {
                        setTimeout(() => {
                            this.setState({ _saving: false, form: null })
                        }, 1000)
                    })
                })
            } else {
                fetch(API_ENDPOINT + '/modules/' + this.state.form._id, {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify(data)
                }).then(res => res.json()).then((result) => {
                    this.get();
                    this.setState({
                        _saving: false
                    }, () => {
                        setTimeout(() => {
                            this.setState({ _saving: false, form: null })
                        }, 1000)
                    })
                })
            }
        })
    }

    stopAnimation = () => {
        this.player.current.play();
    }

    /**
    * Delete module
    * @author   Milan Stanojevic
    * @Objectparam    {String} id                    module's id
    */
    delete = (id) => {
        this.setState({
            loading: true
        }, () => {

            fetch(API_ENDPOINT + '/modules/' + id, {
                method: 'DELETE',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
            }).then(res => res.json()).then((result) => {
                this.get();
            })
        })

    }

    exportModules = () => {
        if (!this.state.loadingExport) {
            this.setState({ loadingExport: true }, () => {
                fetch(API_ENDPOINT + '/modules/export/data', {
                    method: 'GET',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                }).then(res => res.json()).then((result) => {

                    if (result?.path) {
                        setTimeout(() => {
                            let path = result.path;
                            let url = API_ENDPOINT + path;

                            var link = document.createElement("a");
                            link.download = result?.fileName ?? 'endpoints';
                            link.target = "_blank";

                            // Construct the URI
                            link.href = url;
                            document.body.appendChild(link);
                            link.click();
                            this.setState({ loadingExport: null, exportModulesModal: null })
                        }, 2000);
                    } else {
                        this.setState({ loadingExport: null })

                    }

                })
            })
        }

    }

    checkJSON = () => {
        let file = this.state.uploadedJSON;
        if (!file) {
            this.setState({ endpointsData: null })
        } else {
            fetch(API_ENDPOINT + '/modules/imported/data', {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify(file)
            }).then(res => res.json()).then((result) => {
                if (result?.differentModules) {
                    this.setState({ endpointsData: result.differentModules })
                } else {
                    this.setState({ endpointsData: null })
                }
            })
        }
    }
    importEndpoints = () => {
        if (this.state.endpointsData?.length && !this.state.loadingImport) {
            this.setState({ loadingImport: true }, () => {
                let data = this.state.endpointsData;
                fetch(API_ENDPOINT + '/modules/import/data', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({ data: data })
                }).then(res => res.json()).then((result) => {
                    this.setState({ loadingImport: false, endpointsData: null, uploadedJSON: null, importModulesModal: null, successMessage: 'The data has been imported' })
                })
            })
        }
    }
    render() {
        let params = {};
        if (this.state.useState) {
            params = this.state.params;
        } else {
            params = getSearchParams(this.props[0].location.search, { entries: 10, page: 0 })
        }
        const defaultOptions = {
            loop: true,
            autoplay: true,
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice'
            }
        };

        //console.log(this.props)
        const importModulesModal = this.state.importModulesModal;
        const endpointsData = this.state.endpointsData;

        return (
            <div className={this.props.autoHideSidebar ? "dashboard dashboard-auto-hide" : "dashboard"}>
                {
                    this.state.successMessage ?
                        <InfoModal
                            isOpen={this.state.successMessage}

                            toggle={() => this.setState({ successMessage: null })}
                            header={'Info'.translate(this.props.lang)}
                            info={this.state.successMessage.translate(this.props.lang)}
                            buttons={[
                                <Button color='primary' onClick={() => {
                                    this.setState({
                                        successMessage: null
                                    })

                                }}>{'Close'.translate(this.props.lang)}</Button>
                            ]}
                        />
                        :
                        null
                }


                {
                    this.state.exportModulesModal ?
                        <DeleteModal
                            lang={this.props.lang}
                            isOpen={this.state.exportModulesModal}
                            toggle={() => this.setState({ exportModulesModal: null })}
                            handler={() => {
                                this.exportModules();
                            }}
                        >
                            {"Do you want to export modules?".translate(this.props.lang)}

                        </DeleteModal>
                        :
                        null
                }
                {
                    importModulesModal ?
                        <Modal
                            isOpen={importModulesModal}
                            centered
                            size="lg"
                        >
                            <ModalHeader
                                close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ importModulesModal: !importModulesModal, endpointsData: null, uploadedJSON: null })} ><Isvg src={xIcon} /></button>}
                            >
                                {'Import modules'.translate(this.props.lang)}
                            </ModalHeader>
                            <ModalBody>
                                <UploadJSON
                                    lang={this.props.lang}
                                    label={"Upload JSON file".translate(this.props.lang)}
                                    onChange={(e) => {
                                        this.setState({ uploadedJSON: e })
                                    }}
                                    value={this.state.uploadedJSON}
                                />
                                {
                                    endpointsData?.length ?
                                        <div className='endpoints-data-wrap'>
                                            <h6>{'Modules and endpoints that are not synced'.translate(this.props.lang)}</h6>
                                            {
                                                endpointsData.map((item, idx) => {
                                                    return (
                                                        <div className='endpoints-data'>
                                                            {
                                                                item?.moduleExists ?
                                                                    <div className='endpoints-data-exists'>
                                                                        <h6>{item?.name?.[this.props.lang]}</h6>
                                                                        <div className='endpoints-items'>
                                                                            <h5>{'DIFFERENT ENDPOINTS'.translate(this.props.lang)}:</h5>
                                                                            {
                                                                                item?.endpointsDifference?.map((eItem, eIdx) => {
                                                                                    return (
                                                                                        <div className='endpoints-item' key={eIdx}>{eItem}</div>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    <div className='endpoints-data-not-exists'>
                                                                        <h6>{item?.name?.[this.props.lang]} ({'New module'.translate(this.props.lang)})</h6>
                                                                        <p>{'Link'.translate(this.props.lang)}: {item.link}</p>
                                                                        <div className='endpoints-items'>
                                                                            <h5>{'ENDPOINTS'.translate(this.props.lang)}:</h5>
                                                                            {
                                                                                item?.endpoints?.map((eItem, eIdx) => {
                                                                                    return (
                                                                                        <div className='endpoints-item' key={eIdx}>{eItem}</div>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </div>
                                                                    </div>
                                                            }
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                        :
                                        endpointsData?.length === 0 ?
                                            <p style={{ fontWeight: 600, color: 'red' }}>{'All endpoints are synced'}</p>
                                            :
                                            null
                                }
                            </ModalBody>
                            <ModalFooter className='buttons-right-blue'>

                                <Button color="primary" disabled={!endpointsData?.length || this.state.loadingImport} onClick={() => this.importEndpoints()}>{'Import endpoints'.translate(this.props.lang)}</Button>

                                <Button color="primary" onClick={() => this.setState({ importModulesModal: !importModulesModal, endpointsData: null, uploadedJSON: null })}>{'Close'.translate(this.props.lang)}</Button>

                            </ModalFooter>

                        </Modal>
                        :
                        null
                }

                <Container fluid>
                    <Row>
                        <Col lg="12">
                            <div className="panel">
                                <div className="panel-header">
                                    <h5 className="component-header">{this.props.linksMeta && this.props.linksMeta[this.props[0].location.pathname] ? this.props.linksMeta[this.props[0].location.pathname][this.props.lang] : ''}</h5>
                                    <div className="clinic-group-header-buttons">
                                        <button className="btn add-button" style={{ marginRight: 10 }} onClick={() => this.setState({
                                            importModulesModal: true
                                        })}><Isvg src={importIcon} /> {'import'.translate(this.props.lang)}</button>
                                        <button className="btn add-button" style={{ marginRight: 10 }} onClick={() => this.setState({
                                            exportModulesModal: true
                                        })}><Isvg src={exportIcon} /> {'Export'.translate(this.props.lang)}</button>

                                        <button className="btn add-button" onClick={() => this.setState({
                                            form: {}
                                        })}><Isvg src={addIcon} /> {'Add'.translate(this.props.lang)}</button>
                                    </div>

                                </div>
                                <ListBuilder
                                    lang={this.props.lang}
                                    loading={this.state.loading}
                                    total={this.state.total}
                                    showNumeration={false}
                                    fields={[{ type: 'text', name: 'name', label: 'Module name'.translate(this.props.lang), multilang: true, allowSort: true }]}
                                    items={this.state.items}
                                    actions={
                                        [
                                            {
                                                component: <Isvg src={edit} className="edit-icon" />,
                                                onClick: (item) => this.setState({ form: item })
                                            },
                                            {
                                                component: <Isvg src={garabage} className="delete-icon" />,
                                                onClick: (item) => this.setState({ deleteModal: item })
                                            },
                                        ]
                                    }
                                    params={params}
                                    sortField={params.sortField}
                                    sortType={params.sortType}
                                    updateSort={this.updateSort}
                                    updateParams={this.updateParams}
                                    updateMultipleParams={this.updateMultipleParams}
                                ></ListBuilder>
                                <DeleteModal
                                    lang={this.props.lang}
                                    isOpen={this.state.deleteModal}
                                    toggle={() => this.setState({ deleteModal: null })}
                                    handler={() => { this.delete(this.state.deleteModal._id); this.setState({ deleteModal: null }) }}
                                >
                                    {'Delete module'.translate(this.props.lang)} <strong>{Object.translate(this.state.deleteModal, 'name', this.props.lang)}</strong>?
                                </DeleteModal>

                            </div>
                        </Col>
                    </Row>

                </Container>
                {this.state.form ?
                    <FormBuilder selectedGroup={this.props.selectedGroup} selectedClinic={this.props.selectedClinic} countryTwoLetter={this.props.countryTwoLetter} country={this.props.country} dateFormat={this.props.dateFormat}
                        getStringDateTs={this.props.getStringDateTs}
                        lang={this.props.lang}
                        onSubmit={(data) => this.insertOrUpdate(data)}
                        initialValues={this.state.form}
                        size="xl"
                        isOpen={this.state.form}
                        toggle={() => this.setState({ form: null })}
                        title={this.state.form._id ? 'Edit module'.translate(this.props.lang) : 'Add new module'.translate(this.props.lang)}
                        _saving={this.state._saving}
                        buttonConfirmText={'Saved'.translate(this.props.lang)}
                        buttonText={'Save'.translate(this.props.lang)}
                        fields={[
                            {
                                type: 'row',
                                children: [
                                    {
                                        type: 'col',
                                        width: {
                                            lg: 3,
                                            sm: 12,
                                            xs: 12
                                        },
                                        children: [
                                            {
                                                type: 'text',
                                                name: 'name.en',
                                                label: 'Module name [en]'.translate(this.props.lang),
                                                //multilang: true,
                                                lang: this.props.lang,
                                                validate: [required('Module name is required!'.translate(this.props.lang))]
                                            },
                                        ]
                                    },
                                    {
                                        type: 'col',
                                        width: {
                                            lg: 3,
                                            sm: 12,
                                            xs: 12
                                        },
                                        children: [
                                            {
                                                type: 'text',
                                                name: 'name.se',
                                                label: 'Module name [sv]'.translate(this.props.lang),
                                                //multilang: true,
                                                lang: this.props.lang,
                                                validate: [required('Module name is required!'.translate(this.props.lang))]
                                            },
                                        ]
                                    },
                                    {
                                        type: 'col',
                                        width: {
                                            lg: 6,
                                            sm: 12,
                                            xs: 12
                                        },
                                        children: [
                                            {
                                                type: 'select',
                                                name: 'link',
                                                label: 'Link'.translate(this.props.lang),
                                                validate: [required('Link is required!'.translate(this.props.lang))],
                                                values: [{ name: 'Select link'.translate(this.props.lang), value: null }, ...routes.map((route) => { return { name: route.path, value: route.path } })]
                                            },
                                        ]
                                    },
                                    {
                                        type: 'col',
                                        width: {
                                            lg: 6,
                                            sm: 12,
                                            xs: 12
                                        },
                                        children: [
                                            {
                                                type: 'select',
                                                name: 'type',
                                                label: 'Type'.translate(this.props.lang),
                                                validate: [required('Type is required!'.translate(this.props.lang))],
                                                values: [{ name: 'Select module type'.translate(this.props.lang), value: null }, { name: 'Menu', value: 'menu' }, { name: 'Submenu', value: 'submenu' }, { name: 'Page', value: 'page' },]
                                            },
                                        ]
                                    },
                                    {
                                        type: 'col',
                                        width: {
                                            lg: 6,
                                            sm: 12,
                                            xs: 12
                                        },
                                        children: [
                                            {
                                                type: 'select',
                                                name: 'parent',
                                                label: 'Parent'.translate(this.props.lang),
                                                disabled: this.props.type == 'submenu' ? false : true,
                                                values: [{ name: 'Select parent module'.translate(this.props.lang), value: null }, ...this.state.formModules.filter((module) => { return module.type == 'menu' }).map((module) => { return { name: Object.translate(module, 'name', this.props.lang), value: module._id } })]
                                            },
                                        ]
                                    },
                                    /*{
                                        type: 'col',
                                        width: {
                                            lg: 6,
                                            sm: 12,
                                            xs: 12
                                        },
                                        children: [
                                            {
                                                type: 'tect',
                                                name: 'position',
                                                label: 'Position'.translate(this.props.lang),
                                                disabled: this.props.type == 'menu' || this.props.type == 'submenu' ? false : true,
                                            },
                                        ]
                                    },*/
                                    {
                                        type: 'col',
                                        width: {
                                            lg: 6,
                                            sm: 12,
                                            xs: 12
                                        },
                                        children: [
                                            {
                                                type: 'image',
                                                name: 'svgIcon',
                                                label: 'Icon'.translate(this.props.lang),
                                            },
                                        ]
                                    },

                                    {
                                        type: 'col',
                                        width: {
                                            lg: 6,
                                            sm: 12,
                                            xs: 12
                                        },
                                        children: [
                                            {
                                                type: 'text',
                                                name: 'uDataCondition',
                                                label: 'User data condition name'.translate(this.props.lang),
                                            },
                                        ]

                                    },
                                    this.props.clinicEntryModule ? {
                                        type: 'col',
                                        width: {
                                            lg: 6,
                                            sm: 12,
                                            xs: 12
                                        },
                                        children: [
                                            {
                                                type: 'text',
                                                name: 'clinicEntryModuleCondition',
                                                label: 'Clinic group condition name'.translate(this.props.lang),
                                            },
                                        ]

                                    } : null,
                                    {
                                        type: 'col',
                                        width: {
                                            lg: 4,
                                            sm: 12,
                                            xs: 12
                                        },
                                        children: [
                                            {
                                                type: 'checkbox',
                                                name: 'clinicEntryModule',
                                                afterText: 'Clinic entry module'.translate(this.props.lang),
                                            },
                                        ]
                                    },
                                    {
                                        type: 'col',
                                        width: {
                                            lg: 4,
                                            sm: 12,
                                            xs: 12
                                        },
                                        children: [
                                            {
                                                type: 'checkbox',
                                                name: 'newSection',
                                                afterText: 'New section'.translate(this.props.lang),
                                            },
                                        ]
                                    },


                                    {
                                        type: 'col',
                                        width: {
                                            lg: 12,
                                            sm: 12,
                                            xs: 12
                                        },
                                        children: [
                                            {
                                                type: 'easy-select',
                                                name: 'endpoints',
                                                lang: this.props.lang,
                                                disableFilter: true,
                                                label: 'API Endpoints'.translate(this.props.lang),
                                                multiple: true,
                                                values: this.state.endpoints.map((item, idx) => { return { value: item.method + '|||' + item.route, name: <div className="endpoint-wrap"><span>{item.route}</span> <span className={item.method}>[{item.method}]</span> </div> } })
                                            },
                                        ]
                                    },


                                ]
                            }
                        ]}
                    ></FormBuilder>
                    :
                    null
                }

            </div>
        );
    }
}

const selector = formValueSelector('modalForm');

export default connect(state => {
    return { type: selector(state, 'type'), clinicEntryModule: selector(state, 'clinicEntryModule') }

}, {
    /*    changeMinValue: value => change("modalForm", "waitingMin", value),
        changeMaxValue: value => change("modalForm", "waitingMax", value),
        changeSelectedWaitingTimeType: value => change("modalForm", "selectedWaitingTimeType", value),
    */
})(Page(ManageModules));