import React, { Component } from 'react';
import Page from '../../containers/page';

import {
    Container,
    Row,
    Col,
    FormGroup, Label, Input, Button, UncontrolledAlert,
    Modal, ModalBody, ModalFooter
} from 'reactstrap';

import { Link } from 'react-router-dom';

import ModalFormBuilder from '../../components/forms/modalFormBuilder';

import FormBuilder from '../../components/forms/formBuilder';
import { required } from '../../components/forms/validation';

import ListBuilder from '../../components/listBuilder';
import { getSearchParams, generateSearchLink, generateSearchLinkMultiple } from '../../helpers/linkHelper';
import { API_ENDPOINT } from '../../constants';
import { formValueSelector, change } from 'redux-form';  // ES6
import { connect } from 'react-redux';
import lIcon from '../../assets/svg/upitnik.svg'
import Comment from '../../assets/svg/koment.svg'
import Hart from '../../assets/svg/srce.svg';

import Isvg from 'react-inlinesvg'
import error from '../../assets/images/error.png';
import BankIdModal from '../../components/bankIdModal';
import ErrorModal from '../../components/errorModal';
import LogoHeader from '../../assets/svg/header-logo.png';
import LogoFooter from '../../assets/svg/footer-logo.png';
import Woman from '../../assets/svg/woman.png';
import i1 from '../../assets/svg/i1.png';
import i2 from '../../assets/svg/i2.png';
import i3 from '../../assets/svg/i3.png';
import locationIcon from '../../assets/svg/location.png'


import JsonToHtmlV2 from '../landingBuilder/jsonToHtml';
import defaultInformationJSON_v2 from '../landingBuilder/defaultInformation.json';
import FooterComponent from './footerComponent';

function prepareJSON(json) {
    let arr = [];
    for (var key in json) {
        arr.push({
            ...json[key],
            __id: key
        })
    }
    return arr;
}



function parseJSON(response) {
    return response.json().then(json => {
        return {
            result: json,
            status: response.status
        }
    })
}
/**
* Information page
* @author   Stefan Pantic
*/
class Account extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props.initialData,
            form: false,
            useState: false,
            params: {
                entries: 10,
                page: 0,
                'query-group': null
            },
            items: [],
            total: 0,
            loading: true,
            groups: [],
            languages: [],
            clinicIdx: 0,
            windowWidth: typeof window != 'undefined' && window.innerWidth ? window.innerWidth : 0,
            data: null
        };
    }


    get = () => {
        this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));

        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0, 'query-group': null })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

        for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
            this.props.loadDataWithQuery[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0, 'query-group': null })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

    }

    componentDidMount() {
        //if (typeof window !== 'undefined') { window.scrollTo(0, 0); }

        //this.get();

        let alias = this.props[0].match.params.alias;


        localStorage.setItem('groupAlias', alias);
        this.forceUpdate();
        window.addEventListener('resize', this.resizeWindow);

        let mainAlias = this.props[0].match.params.mainAlias;

        if (mainAlias && alias) {
            fetch(API_ENDPOINT + '/landing/page', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json'
                },
                body: JSON.stringify({ alias, mainAlias })
            }).then(res => res.json()).then((result) => {
                if (result.error) {
                    this.props[0].history.push(`/`);
                    /*this.setState({
                        error: result.error
                        
                    })*/
                } else {
                    // console.log(result)
                    this.setState({
                        data: result
                    }, () => {
                        this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                        if (result.favicon) {
                            let favicon = document.getElementById("favicon");
                            favicon.href = result.favicon;
                        } else if (result.logoChat) {
                            let favicon = document.getElementById("favicon");
                            favicon.href = result.logoChat;

                        } else {
                            favicon.href = '/favicon.ico';
                        }

                    })

                }
            });
        } else {
            fetch(API_ENDPOINT + '/landing/page', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json'
                },
                body: JSON.stringify({ alias })
            }).then(res => res.json()).then((result) => {
                if (result.error) {
                    this.props[0].history.push(`/`);
                    /*this.setState({
                        error: result.error
                        
                    })*/
                } else {
                    // console.log(result)
                    this.setState({
                        data: result
                    }, () => {
                        this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                        if (result.favicon) {
                            let favicon = document.getElementById("favicon");
                            favicon.href = result.favicon;
                        } else if (result.logoChat) {
                            let favicon = document.getElementById("favicon");
                            favicon.href = result.logoChat;

                        } else {
                            favicon.href = '/favicon.ico';
                        }

                    })

                }
            });
        }


        if (this.props.uData && this.state.data) {
            this.checkUserAuth();
        }

    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps[0].location.search != this.props[0].location.search) {
            this.setState({
                loading: true
            }, () => {
                this.get();

            })
        }

        if (typeof prevProps.selectedWaitingTimeType != 'undefined' && prevProps.selectedWaitingTimeType != this.props.selectedWaitingTimeType) {
            if (this.props.selectedWaitingTimeType == 0) {
                this.props.changeMinValue('');
                this.props.changeMaxValue('');
            } else if (this.props.selectedWaitingTimeType == 1) {
                this.props.changeMinValue(0);
                this.props.changeMaxValue(0);
            } else if (this.props.selectedWaitingTimeType == 2) {
                this.props.changeMinValue('');
                this.props.changeMaxValue(0);
            } else if (this.props.selectedWaitingTimeType == 3) {
                this.props.changeMinValue(0);
                this.props.changeMaxValue('');
            } else if (this.props.selectedWaitingTimeType == 4) {
                this.props.changeMinValue('');
                this.props.changeMaxValue('');
            } else if (this.props.selectedWaitingTimeType == 5) {
                this.props.changeMinValue('');
                this.props.changeMaxValue('');
            }
        }

        if (prevProps?.uData?._id !== this.props.uData?._id || prevState?.data?._id !== this.state.data?._id || prevProps?.[0]?.match?.params?.alias !== this.props?.[0]?.match?.params?.alias) {
            this.checkUserAuth();
        }
    }
    componentWillUnmount() {

        window.removeEventListener('resize', this.resizeWindow);
    }
    resizeWindow = () => {
        let windowWidth = this.state.windowWidth;
        let windowHeight = this.state.windowHeight;
        if (typeof window != 'undefined' && window.innerWidth && Math.abs(windowWidth - window.innerWidth) > 50) {
            this.setState({ windowWidth: window.innerWidth })
        } else if (typeof window != 'undefined' && window.innerHeight && Math.abs(windowHeight - window.innerHeight) > 50) {
            this.setState({ windowHeight: window.innerHeight })
        }

    }


    updateSort = (field, type) => {
        if (this.state.useState) {
            let state = this.state.params;
            state.sortField = field;
            state.sortType = type;
            this.setState({
                params: state
            })
        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0 }, [{ name: 'sortField', value: field }, { name: 'sortType', value: type }], false));

        }
    }

    updateParams = (name, value, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };
                obj[name] = value;
                this.setState({ params: obj });
            } else {
                let obj = this.state.params;
                obj[name] = value;
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(generateSearchLink(this.props[0].location.search, { entries: 10, page: 0 }, name, value, restart));
        }
    }
    updateMultipleParams = (fields, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj });

            } else {
                let obj = this.state.params;
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0 }, fields, restart));
        }
    }
    fetchBankId = () => {
        fetch(API_ENDPOINT + '/users/bankid/v2/start', {
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify({ group: this.state.data._id, doctorsLogin: true })
        }).then(res => res.json()).then((result) => {
            if (result.redirect_url) {
                this.setState({

                    loginBankIdModal: result.redirect_url
                }, () => {
                    setTimeout(() => {
                        if (!this.props.uData) {
                            this.fetchBankId()
                        }
                    }, 1000 * 60 * 4);
                })
            }
        })

    }

    checkUserAuth = () => {
        const user = this.props.uData;
        const groupId = this.state.data?._id;
        const loggedInWith2fa = localStorage?.getItem?.('2faLogin');

        if (loggedInWith2fa && loggedInWith2fa === 'true' && user && groupId) {
            this.setState({ loading: true }, async () => {
                const newState = {
                    loading: false,
                };

                const response = await fetch(API_ENDPOINT + '/users/two-factor-auth/check-clinic-group', {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                    },
                    body: JSON.stringify({ groupId })
                });

                const result = await response?.json?.();

                if (!result) return this.setState(newState);

                if (result.error) {
                    newState.twoFactorAuthForbidden = result.error;
                    this.setState(newState);
                } else {
                    newState.twoFactorAuthForbidden = false;
                    this.setState(newState);
                }

                if (result.verify && result.verify === true) this.props.verifyUser?.();
            });
        }
    }

    navigateBackToProfile = () => {
        this.setState({ twoFactorAuthForbidden: false }, () => {
            this.props?.[0]?.history?.replace?.('/my-data/account');
        });
    }

    render() {
        if (!this.state.data) {
            return null;
        }
        if (typeof window == 'undefined')
            return null;


        let maxHeight = typeof window !== 'undefined' && document.getElementById('main-container')?.offsetHeight;
        let shameStyle = {}
        if (maxHeight) {
            shameStyle.maxHeight = maxHeight;
            shameStyle.overflow = 'hidden';
        }
        return (

            <div className="shame" style={shameStyle} /*style={this.state.selectdLandingConfig && this.state.selectdLandingConfig.footerBackground ? { backgroundColor: this.state.selectdLandingConfig.footerBackground } : this.state.data && this.state.data.footerBackground ? { backgroundColor: this.state.data.footerBackground } : {}}*/>


                {this.state.error ? null :
                    <Container fluid id='main-container'>
                        <Row>
                            <Col lg="12" className="p-0">
                                <div className="landing-page-container">
                                    {
                                        typeof window != 'undefined' && window.innerWidth > 480 ?
                                            <div style={{ marginTop: this.props.uData && typeof window != 'undefined' && window.innerWidth < 1200 ? 70 : 0 }}>

                                                <div className='landing-header'>
                                                    <Container>
                                                        <Row>
                                                            <Col lg="12">
                                                                <div className="login-header">


                                                                    <div className="logo">
                                                                        <img src={API_ENDPOINT + this.state.data.logo} className="landing-page-logo" />
                                                                    </div>


                                                                    <div className="navigation">
                                                                        {this.props.uData ?
                                                                            <div>
                                                                                <span className="username">{this.props.uData.userData.name}</span>
                                                                                <button className={'login-button-landing-page'} style={this.state.data.loginOutline ? { backgroundColor: 'transparent', border: `1px solid ${this.state.data.loginColor}`, color: this.state.data.loginColor } : { background: this.state.data.loginColor, border: `1px solid transparent`, color: 'white' }}
                                                                                    onMouseEnter={() => {
                                                                                        let data = this.state.data;
                                                                                        data.loginOutline = !this.state.data.loginOutline
                                                                                        this.setState({
                                                                                            data
                                                                                        })
                                                                                    }}
                                                                                    onMouseLeave={() => {
                                                                                        let data = this.state.data;
                                                                                        data.loginOutline = !this.state.data.loginOutline
                                                                                        this.setState({
                                                                                            data
                                                                                        })
                                                                                    }}
                                                                                    onClick={() => this.props.signOut()}>{'Logout'.translate(this.props.lang)}</button>
                                                                            </div>
                                                                            :
                                                                            <button className={'login-button-landing-page'} style={this.state.data.loginOutline ? { backgroundColor: 'transparent', border: `1px solid ${this.state.data.loginColor}`, color: this.state.data.loginColor } : { background: this.state.data.loginColor, border: `1px solid transparent`, color: 'white' }}
                                                                                onMouseEnter={() => {
                                                                                    let data = this.state.data;
                                                                                    data.loginOutline = !this.state.data.loginOutline
                                                                                    this.setState({
                                                                                        data
                                                                                    })
                                                                                }}
                                                                                onMouseLeave={() => {
                                                                                    let data = this.state.data;
                                                                                    data.loginOutline = !this.state.data.loginOutline
                                                                                    this.setState({
                                                                                        data
                                                                                    })
                                                                                }}
                                                                                onClick={() => {

                                                                                    this.setState({
                                                                                        loginBankIdModal: true
                                                                                    }, () => {
                                                                                        // fetch(API_ENDPOINT + '/users/bankid/v2/start', {
                                                                                        //     method: 'POST',
                                                                                        //     headers: {
                                                                                        //         'content-type': 'application/json'
                                                                                        //     },
                                                                                        //     body: JSON.stringify({ group: this.state.data._id, doctorsLogin: true })
                                                                                        // }).then(res => res.json()).then((result) => {
                                                                                        //     if (result.redirect_url) {
                                                                                        //         this.setState({

                                                                                        //             loginBankIdModal: result.redirect_url
                                                                                        //         })
                                                                                        //     }
                                                                                        // })
                                                                                        this.fetchBankId()
                                                                                    })

                                                                                }}>{'Login'.translate(this.props.lang)}</button>
                                                                        }
                                                                    </div>
                                                                </div>

                                                            </Col>
                                                        </Row>


                                                    </Container>
                                                </div>

                                            </div>
                                            :
                                            null
                                    }



                                </div>

                                {/* Landing style v2 */}
                                <div>
                                    {
                                        typeof window != 'undefined' && window.innerWidth < 480 ?
                                            this.state.data && this.state.data.informationJSON_v2 ?
                                                <div style={{ marginTop: this.props.uData ? 70 : 0 }}>
                                                    <JsonToHtmlV2
                                                        layout={'xs'}
                                                        nodes={JSON.parse(JSON.stringify(this.state.data.informationJSON_v2))}
                                                        uData={this.props.uData}
                                                        loginHandler={() => {
                                                            if (!this.props.uData) {
                                                                this.setState({
                                                                    loginBankIdModal: true
                                                                }, () => {

                                                                    this.fetchBankId()
                                                                })
                                                            }
                                                        }}
                                                    />
                                                </div>
                                                :
                                                <div style={{ marginTop: this.props.uData ? 70 : 0 }}>
                                                    <JsonToHtmlV2
                                                        layout={'xs'}
                                                        nodes={JSON.parse(JSON.stringify(defaultInformationJSON_v2))}
                                                        uData={this.props.uData}
                                                        loginHandler={() => {
                                                            if (!this.props.uData) {
                                                                this.setState({
                                                                    loginBankIdModal: true
                                                                }, () => {

                                                                    this.fetchBankId()
                                                                })
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            :
                                            typeof window != 'undefined' && window.innerWidth > 480 && window.innerWidth < 1200 ?
                                                this.state.data && this.state.data.informationJSON_v2 ?
                                                    <div style={{ marginTop: this.props.uData ? 70 : 0 }}>
                                                        <JsonToHtmlV2
                                                            layout={'sm'}
                                                            nodes={JSON.parse(JSON.stringify(this.state.data.informationJSON_v2))}
                                                            uData={this.props.uData}
                                                            loginHandler={() => {
                                                                if (!this.props.uData) {
                                                                    this.setState({
                                                                        loginBankIdModal: true
                                                                    }, () => {

                                                                        this.fetchBankId()
                                                                    })
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                    :
                                                    <div style={{ marginTop: this.props.uData ? 70 : 0 }}>
                                                        <JsonToHtmlV2
                                                            layout={'sm'}
                                                            nodes={JSON.parse(JSON.stringify(defaultInformationJSON_v2))}
                                                            uData={this.props.uData}
                                                            loginHandler={() => {
                                                                if (!this.props.uData) {
                                                                    this.setState({
                                                                        loginBankIdModal: true
                                                                    }, () => {

                                                                        this.fetchBankId()
                                                                    })
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                :
                                                typeof window != 'undefined' && window.innerWidth >= 1200 && this.state.data && this.state.data.informationJSON_v2 ?
                                                    <JsonToHtmlV2
                                                        layout={'lg'}
                                                        nodes={JSON.parse(JSON.stringify(this.state.data.informationJSON_v2))}
                                                        uData={this.props.uData}
                                                        loginHandler={() => {
                                                            if (!this.props.uData) {
                                                                this.setState({
                                                                    loginBankIdModal: true
                                                                }, () => {

                                                                    this.fetchBankId()
                                                                })
                                                            }
                                                        }}
                                                    />
                                                    :
                                                    <JsonToHtmlV2
                                                        layout={'lg'}
                                                        nodes={JSON.parse(JSON.stringify(defaultInformationJSON_v2))}
                                                        uData={this.props.uData}
                                                        loginHandler={() => {
                                                            if (!this.props.uData) {
                                                                this.setState({
                                                                    loginBankIdModal: true
                                                                }, () => {

                                                                    this.fetchBankId()
                                                                })
                                                            }
                                                        }}
                                                    />
                                    }


                                </div>
                                {/* ---------- */}

                                {
                                    this.state.data.footerSettings ?
                                        <FooterComponent {...this.props} data={this.state.data} />

                                        :
                                        <div className="landing-footer footer-space">
                                            <Container>
                                                <Row>
                                                    <Col lg="12">
                                                        <ul className="landing-footer">
                                                            {this.state.data.privacyPolicy ? <li><Link to={`/${this.props[0].match.params.alias}/privacy-policy`} target="_blank"> {'Privacy policy'.translate(this.props.lang)} </Link></li> : null}
                                                            {this.state.data.termsOfUse ? <li><a href={`/${this.props[0].match.params.alias}/terms-of-use`} target="_blank"> {'Terms of use'.translate(this.props.lang)} </a></li> : null}
                                                            {this.state.data.accessibilityReport ? <li><a href={`/${this.props[0].match.params.alias}/accessibility-report`} target="_blank"> {'Accessibility Report'.translate(this.props.lang)} </a></li> : null}
                                                        </ul>
                                                    </Col>
                                                    <Col lg="12">
                                                        <ul className="landing-footer landing-footer-list">
                                                            <li>{this.state.data.address ? this.state.data.address[0] ? this.state.data.address[1] || this.state.data.address[2] ? this.state.data.address[0] + ',' : this.state.data.address[0] : '' : ''}</li>
                                                            <li>{this.state.data.address ? this.state.data.address[1] ? this.state.data.address[2] ? this.state.data.address[1] + ',' : this.state.data.address[1] : '' : ''}</li>
                                                            <li>{this.state.data.address ? this.state.data.address[2] : ''}</li>
                                                        </ul>

                                                    </Col>
                                                </Row>
                                            </Container>
                                        </div>
                                }

                                {/* <div className="landing-footer footer-space" style={this.state.data.footerBackground ? { backgroundColor: this.state.data.footerBackground } : {}}>
                                    <Container>
                                        <Row>
                                            <Col lg="12">
                                                <ul className="landing-footer">
                                                    {this.state.data.privacyPolicy ? <li><Link to={`/${this.props[0].match.params.alias}/privacy-policy`} target="_blank" style={this.state.data.footerTextColor ? { color: this.state.data.footerTextColor } : {}}> {'Privacy policy'.translate(this.props.lang)} </Link></li> : null}
                                                    {this.state.data.termsOfUse ?
                                                        <a style={{ color: this.state.data.footerTextColor ? this.state.data.footerTextColor : '#7e8fa7' }}>|</a>
                                                        :
                                                        null}
                                                    {this.state.data.termsOfUse ? <li><a href={`/${this.props[0].match.params.alias}/terms-of-use`} target="_blank" style={this.state.data.footerTextColor ? { color: this.state.data.footerTextColor } : {}}> {'Terms of use'.translate(this.props.lang)} </a></li> : null}
                                                    {this.state.data.accessibilityReport ?
                                                        <a style={{ color: this.state.data.footerTextColor ? this.state.data.footerTextColor : '#7e8fa7' }}>|</a>
                                                        :
                                                        null}
                                                    {this.state.data.accessibilityReport ? <li><a href={`/${this.props[0].match.params.alias}/accessibility-report`} target="_blank" style={this.state.data.footerTextColor ? { color: this.state.data.footerTextColor } : {}}> {'Accessibility Report'.translate(this.props.lang)} </a></li> : null}
                                                </ul>
                                            </Col>
                                            <Col lg="12">
                                                <ul className="landing-footer landing-footer-list">
                                                    <li style={this.state.data.footerTextColor ? { color: this.state.data.footerTextColor } : {}}>{this.state.data.address ? this.state.data.address[0] ? this.state.data.address[1] || this.state.data.address[2] ? this.state.data.address[0] + ',' : this.state.data.address[0] : '' : ''}</li>
                                                    <li style={this.state.data.footerTextColor ? { color: this.state.data.footerTextColor } : {}}>{this.state.data.address ? this.state.data.address[1] ? this.state.data.address[2] ? this.state.data.address[1] + ',' : this.state.data.address[1] : '' : ''}</li>
                                                    <li style={this.state.data.footerTextColor ? { color: this.state.data.footerTextColor } : {}}>{this.state.data.address ? this.state.data.address[2] : ''}</li>
                                                </ul>

                                            </Col>
                                        </Row>
                                    </Container>
                                </div> */}


                            </Col>

                        </Row>
                    </Container>
                }

                {this.state.error ?

                    <Modal isOpen={this.state.error} centered>

                        <ModalBody className="delete-modal">
                            {/* <img src={error} /> */}
                            <div className="message">
                                {
                                    this.state.error
                                }
                            </div>
                        </ModalBody>
                        <ModalFooter className='buttons-right-blue'>
                            <Button style={{ minWidth: 100 }} type='button' color='primary' onClick={() => this.setState({ error: null })}>
                                {'Close'.translate(this.props.lang)}
                            </Button>
                        </ModalFooter>
                    </Modal>

                    :
                    null
                }

                {
                    this.state.bankIdModal ?
                        <BankIdModal
                            {...this.props}
                            isOpen={this.state.bankIdModal}
                            toggle={() => {

                                if (this.props.uData) {

                                    localStorage.setItem('groupAlias', this.props[0].match.params.alias);

                                    this.props[0].history.push(`/${this.props[0].match.params.alias}/personal-referral`)
                                }
                                this.setState({ bankIdModal: null })

                            }}
                            redirect_url={this.state.bankIdModal}></BankIdModal>
                        :

                        null
                }
                {
                    this.state.loginBankIdModal ?
                        <BankIdModal
                            {...this.props}
                            isOpen={this.state.loginBankIdModal}
                            toggle={() => {

                                if (this.props.uData) {
                                    this.props.registerClinicGroupLogs({
                                        type: 'login',
                                        loginType: 'bankid'
                                    })
                                    localStorage.setItem('groupAlias', this.props[0].match.params.alias);
                                }
                                this.setState({ loginBankIdModal: null })

                            }}
                            redirect_url={this.state.loginBankIdModal}></BankIdModal>
                        :

                        null
                }
                {
                    this.state.bankIdModal1 ?
                        <BankIdModal
                            {...this.props}
                            isOpen={this.state.bankIdModal1}
                            toggle={() => {

                                if (this.props.uData) {

                                    localStorage.setItem('groupAlias', this.props[0].match.params.alias);
                                    if (!this.props.uData.haveReferrals && !this.props.uData.havePersonalReferrals) {
                                        this.setState({
                                            error: 'You have no active issues with us...'.translate(this.props.lang)
                                        })
                                        return;
                                    }

                                    this.props[0].history.push(`/my-data/personal-referrals/referrals`)
                                }
                                this.setState({ bankIdModal1: null })

                            }}
                            redirect_url={this.state.bankIdModal1}></BankIdModal>
                        :

                        null
                }


                {this.state.error ?
                    <ErrorModal lang={this.props.lang}
                        isOpen={this.state.error}
                        toggle={() => this.setState({ error: null })}

                    >
                        {this.state.error.translate(this.props.lang)}
                    </ErrorModal>
                    :
                    null

                }

                {
                    this.state.twoFactorAuthForbidden ? <Modal
                        zIndex={99999}
                        size='md'
                        centered
                        isOpen={this.state.twoFactorAuthForbidden}
                    >
                        <ModalBody style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 20 }}>
                            <p style={{ textAlign: 'center', margin: 0, padding: 0, }}>{typeof this.state.twoFactorAuthForbidden === 'string' ? this.state.twoFactorAuthForbidden.translate(this.props.lang) : 'This clinic does not accept cases from patients who use two factor authentication, if their account is not verified with them.'.translate(this.props.lang)}</p>
                        </ModalBody>
                        <ModalFooter style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Button onClick={this.navigateBackToProfile} style={{ minWidth: 100 }}>
                                {'Ok'.translate(this.props.lang)}
                            </Button>
                        </ModalFooter>
                    </Modal> : null
                }


            </div>
        );
    }
}

const selector = formValueSelector('modalForm');

export default connect(state => {
    return { selectedWaitingTimeType: selector(state, 'selectedWaitingTimeType') }

}, {
    changeMinValue: value => change("modalForm", "waitingMin", value),
    changeMaxValue: value => change("modalForm", "waitingMax", value),
    changeSelectedWaitingTimeType: value => change("modalForm", "selectedWaitingTimeType", value),

})(Page(Account));
