import React, { Component } from 'react';
import infoIcon from "../../../assets/svg/link-new-tab.svg";
import Isvg from 'react-inlinesvg';


/**
* Custom checkbox
* @author   Milan Stanojevic
*/
class Checkbox extends Component {
    constructor(props) {
        super(props);

        this.state = {
        };
    }

    render() {
        return (
            <div className={this.props.disabled ? "checkbox-wrap-v2 checkbox-wrap-v2-disabled" : "checkbox-wrap-v2"} onClick={(e) => {
                if (!this.props.onChange) {
                    return;
                }
                let obj = {
                    target: {
                        checked: !this.props.checked
                    }
                }

                this.props.onChange(obj);
            }}>
                <div className={this.props.checked ? "checkbox-v2 checkbox-v2-checked" : "checkbox-v2"} style={{ border: this.props.error ? '1px solid #dc3545' : 'none' }}>
                </div>
                <span style={this.props.small ? { color: this.props.error && !this.props.errorLabelDisabled ? '#dc3545' : 'black', fontSize: 14 } : { color: this.props.error && !this.props.errorLabelDisabled ? '#dc3545' : 'black' }} className={this.props.labelInfo || this.props.tooltip ? "flex-center" : ""}>
                    {this.props.label}
                    {
                        this.props.labelInfo ?
                            <div className='label-info'>
                                <Isvg src={infoIcon} />
                                <div className="info-text">{this.props.labelInfo}</div>
                            </div>
                            :
                            null
                    }
                    {
                        this.props.tooltip ?
                            <div className='tooltip-label-info'>
                                <Isvg src={infoIcon} />
                                <div className="info-text">{this.props.tooltip}</div>
                            </div>
                            :
                            null
                    }
                </span>
            </div>
        );
    }
}

export default Checkbox;