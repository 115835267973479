import React, { Component } from 'react';
import Page from '../../containers/page';
import {
    Container,
    Row,
    Col, Modal, ModalBody, ModalFooter, ModalHeader, Button, FormGroup, Label, Input
} from 'reactstrap';

import Isvg from 'react-inlinesvg'

import incommingCallIcon from '../../assets/svg/incomming-call-icon.svg';

function parseJSON(response) {
    return response.json().then(json => {
        return {
            result: json,
            status: response.status
        }
    })
}


class VideoCallPopUp extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props.initialData,
            _registerEvents: false,
            circle: 1

        };
    }


    get = () => {

    }
    checkActiveCall = () => {
        if (this.props.socket) {
            this.props.socket.emit('checkActiveCall', {})
        }
    }

    componentDidMount() {
        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }

        this.circleInterval = setInterval(() => {
            this.setState(prevState => {
                let newValue = prevState.circle + 1;
                if (newValue > 3) {
                    newValue = 1;
                }
                return {
                    circle: newValue
                }
            })
        }, 500);

        this.registerSocket();
        this.checkActiveCallInterval = setInterval(() => {
            this.checkActiveCall()
        }, 1000 * 5);
    }
    registerSocket = () => {
        if (this.props.uData && this.props.socket && !this.state._registerEvents) {
            this.setState({
                _registerEvents: true
            }, () => {
                this.registerEvents();
            })
        } else {
            this.socketInterval = setInterval(() => {
                if (this.props.uData && this.props.socket && !this.state._registerEvents) {
                    if (this.socketInterval) {
                        clearInterval(this.socketInterval)
                    }

                    this.setState({
                        _registerEvents: true
                    }, () => {
                        this.registerEvents();
                    })
                }

            }, 150);
        }
    }

    onCheckActiveCall = async (data) => {
        let activeCall = data?.activeCall ? true : false;
        if (activeCall !== this.state.callInProgress) {
            this.setState({ callInProgress: activeCall }, () => {
                if (this.state.callInProgress && this.props.pauseAudioIncommingCall) {
                    this.props.pauseAudioIncommingCall()
                }
            })
        }
    }

    registerEvents = () => {

        this.props.socket.on('checkActiveCall', async (data) => this.onCheckActiveCall(data)
            //     {
            //     let activeCall = data?.activeCall ? true : false;
            //     if (activeCall !== this.state.callInProgress) {
            //         this.setState({ callInProgress: activeCall }, () => {
            //             if (this.state.callInProgress && this.props.pauseAudioIncommingCall) {
            //                 this.props.pauseAudioIncommingCall()
            //             }
            //         })
            //     }
            // }
        );



    }

    componentDidUpdate(prevProps) {

    }

    componentWillUnmount() {
        if (this.circleInterval) {
            clearInterval(this.circleInterval)
        }

        if (this.props.socket) {
            // this.props.socket.removeListener('checkActiveCall');
            this.props.socket.removeListener('checkActiveCall', this.onCheckActiveCall);

        }

        if (this.checkActiveCallInterval) {
            clearInterval(this.checkActiveCallInterval)
        }
    }

    render() {
        if (this.state.callInProgress) {
            return null;
        }
        const { profilePicture, name } = this.props;
        // const profilePicture = "http://localhost:4005/uploads/810a3a20-7731-4223-8d3f-105e5aa61f23.png";
        // const name = "Milan Stanojevic Doktor";

        return (

            <div style={this.props.style ?? {}} className="video-call-pop-up-wrap">
                <div className="caller-image-wrap">
                    <div className={this.state.circle > 2 ? "circle-3 active-circle-3" : "circle-3"}>
                        <div className={this.state.circle > 1 ? "circle-2 active-circle-2" : "circle-2"}>
                            <div className={this.state.circle > 0 ? "circle-1 active-circle-1" : "circle-1"}>
                                <img src={profilePicture} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='incomming-call-wrap'>
                    <Isvg src={incommingCallIcon} />
                    {'Incomming call'.translate(this.props.lang)}...
                </div>
                <div className='caller-name-wrap'>
                    {name}
                </div>

                <div className='answer-call-btn-wrap'>
                    <button onClick={() => {
                        if (this.props.answer) this.props.answer();
                    }} className="answer-button">
                        {/* <Isvg src={incommingCallIcon} /> */}
                        {'Answer the call'.translate(this.props.lang)}
                    </button>
                </div>
                {/* <div className="buttons">
                    
                    <button onClick={this.endCall} className="decline-button"><Isvg src={call} /></button>
                </div> */}
            </div>

        );
    }
}

export default VideoCallPopUp;