import React, { Component } from 'react';
import Page from '../../containers/page';

import {
    Container,
    Row,
    Col,
    FormGroup, Label, Input, Button, UncontrolledAlert,
    Modal, ModalBody, ModalHeader, ModalFooter
} from 'reactstrap';

import { Link } from 'react-router-dom';

import ModalFormBuilder from '../../components/forms/modalFormBuilder';

import FormBuilder from '../../components/forms/formBuilder';
import { required, emailAddress, dateValidate } from '../../components/forms/validation';

import ListBuilder from '../../components/listBuilder';
import { getSearchParams, generateSearchLink, generateSearchLinkMultiple } from '../../helpers/linkHelper';
import { API_ENDPOINT } from '../../constants';
import { formValueSelector, change } from 'redux-form';  // ES6
import { connect } from 'react-redux';
import lIcon from '../../assets/svg/upitnik.svg'
import Comment from '../../assets/svg/koment.svg'
import Hart from '../../assets/svg/srce.svg';

import CustomCheckbox from '../../components/forms/fields/customCheckbox';

import Isvg from 'react-inlinesvg'
import error from '../../assets/images/error.png';
// import BankIdModal from '../../components/bankIdModal';
import BankId from '../../components/bankId';

import SignBankIdModal from '../../components/signBankIdModal';
import InfoModal from '../../components/infoModal';


import ErrorModal from '../../components/errorModal';
import LogoHeader from '../../assets/svg/header-logo.png';
import LogoFooter from '../../assets/svg/footer-logo.png';
import Woman from '../../assets/svg/woman.png';
import i1 from '../../assets/svg/i1.png';
import i2 from '../../assets/svg/i2.png';
import i3 from '../../assets/svg/i3.png';
import locationIcon from '../../assets/svg/location.png'
import arrowLeftIcon from '../../assets/svg/arrow-left-icon.svg'

import addIcon from '../../assets/svg/add-icon.svg';




import JsonToHtmlV2 from '../landingBuilder/jsonToHtml';
import defaultJSON_v2 from '../landingBuilder/default.json';

import defaultMainClinicGroup from '../landingBuilder/defaultMainClinicGroup.json';

import MainClinicLandingPage from '../../components/mainClinicLandingPage';

import TextField from '../../components/forms/fields/field';
import SocialSecurityNumber, { calculateAgeFromSSN } from '../../components/forms/fields/socialSecurityNumber';

import { Player, Controls } from "@lottiefiles/react-lottie-player";

import logoLogin from "../../assets/svg/logo_login.svg";
import shapeBlue from "../../assets/svg/shape-blue.svg";
import shapeGreen from "../../assets/svg/shape-green.svg";
import shapeYellow from "../../assets/images/shape-yellow.png";

import view from '../../assets/svg/eye.svg'

import PrintHelper from '../../components/printHelper';

import backIcon from '../../assets/svg/back-icon.svg';

import DateOfBirth from '../../components/forms/fields/dateOfBirth';


import moment from 'moment';

function prepareJSON(json) {
    let arr = [];
    for (var key in json) {
        arr.push({
            ...json[key],
            __id: key
        })
    }
    return arr;
}



function parseJSON(response) {
    return response.json().then(json => {
        return {
            result: json,
            status: response.status
        }
    })
}
function iosDevice() {
    //return true;
    var userAgent = window.navigator.userAgent;
    if (userAgent.match(/iPad/i) || userAgent.match(/iPhone/i)) {
        return true;
    }
    else {
        return false;
    }
}


/**
* LIsting page
* @author Aleksandar Dabic
*/
class ListingPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props.initialData,
            form: false,
            useState: false,
            params: {
                entries: 10,
                page: 0,
                'query-group': null
            },
            windowWidth: typeof window != 'undefined' && window.innerWidth ? window.innerWidth : 0,
            doctorsList: [],
            clinics: [],
            checkedListing: false,
            listing: [],
            childrenError: null,
            hasGivenConsent: false,

        };
    }



    get = () => {


        this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));

        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0, 'query-group': null })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

        for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
            this.props.loadDataWithQuery[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0, 'query-group': null })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    if (!this.state.seoData?.bankIdAuthentication) this.goBackHome();
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }


        this.getData()
        if (this.props.uData) {
            this.checkListing()
        }
        // this.getDoctors()
    }

    getData = () => {
        let alias = this.props[0].match.params.alias;

        localStorage.setItem('groupAlias', alias);


        fetch(API_ENDPOINT + '/landing/page', {
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify({ alias })
        }).then(res => res.json()).then((result) => {
            if (result.error) {
                // this.props[0].history.push(`/`);

            } else {
                if (!result.listing) {
                    this.props[0].history.push(`/${this.props[0].match.params.alias}`)
                } else {
                    this.setState({
                        data: result,
                    }, () => {
                        if (result.favicon) {
                            let favicon = document.getElementById("favicon");
                            favicon.href = result.favicon;
                        } else if (result.logoChat) {
                            let favicon = document.getElementById("favicon");
                            favicon.href = result.logoChat;

                        } else {
                            favicon.href = '/favicon.ico';
                        }
                    })
                }


            }
        });
    }

    getDoctors = () => {
        fetch(API_ENDPOINT + '/clinic-groups/doctors/list', {
            method: 'POST',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
            body: JSON.stringify({
                alias: this.props[0].match.params.alias
            })
        }).then(res => res.json()).then((result) => {
            if (result) {
                if (result.clinics) {
                    this.setState({ clinics: result.clinics })
                }
                if (result.doctorsList) {
                    this.setState({ doctorsObj: result.doctorsList }, () => {
                        let clinic;
                        if (result.clinics && result.clinics.length && result.clinics.length == 1) {
                            clinic = result.clinics[0];
                        }

                        if (this.state.doctorsObj && Object.keys(this.state.doctorsObj).length && Object.keys(this.state.doctorsObj).length == 1 && clinic && this.state.doctorsObj[clinic._id]) {
                            this.props.changeSelectedClinic(clinic._id)
                            this.setState({ doctorsList: this.state.doctorsObj[clinic._id] })
                        }

                    })
                }

            }

        })
    }

    checkListing = () => {

        fetch(API_ENDPOINT + '/data/listing/check', {
            method: 'POST',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
            body: JSON.stringify({
                groupAlias: this.props[0].match.params.alias
            })
        }).then(res => res.json()).then((result) => {
            if (result) {
                let stateObj = {
                    canSentNewRequest: false
                }
                if (result.listing) {
                    stateObj.listing = [...result.listing];
                }
                if (!this.state.checkedListing)
                    stateObj.checkedListing = true;

                if (result.canSentNewRequest) {
                    stateObj.canSentNewRequest = true;
                } else if (this.state.canSentNewRequest) {
                    stateObj.canSentNewRequest = false;
                }
                if (result.canSentNewRequestForChildren) {
                    stateObj.canSentNewRequestForChildren = true;
                } else if (this.state.canSentNewRequestForChildren) {
                    stateObj.canSentNewRequestForChildren = false;
                }

                if (result.listOfApprovedChildren) {
                    stateObj.listOfApprovedChildren = result.listOfApprovedChildren;
                }

                this.setState(stateObj)
            }
        })
    }




    componentDidMount() {
        window.addEventListener('resize', this.resizeWindow);

        this.get();

        if (!this.props.uData) {
            this.fetchBankId()
        }

        if (this.props.uData && this.state.data) {
            this.checkUserAuth();
        }
    }

    componentWillUnmount() {

        window.removeEventListener('resize', this.resizeWindow);
    }
    resizeWindow = () => {
        let windowWidth = this.state.windowWidth;
        if (typeof window != 'undefined' && window.innerWidth && Math.abs(windowWidth - window.innerWidth) > 50) {
            this.setState({ windowWidth: window.innerWidth })
        }

    }



    componentDidUpdate(prevProps, prevState) {
        if (prevProps[0].location.search != this.props[0].location.search) {
            this.setState({
                loading: true
            }, () => {
                this.get();

            })
        }

        if (prevProps[0].location.pathname != this.props[0].location.pathname) {
            this.get();
        }

        if (prevProps.uData != this.props.uData && this.props.uData && this.state.bankIdModal) {
            this.setState({ bankIdModal: null })
        }


        if (prevProps.uData != this.props.uData) {
            this.get();
        }

        if (prevProps.sex != this.props.sex) {
            if (this.props.sex == 0) {
                this.props.changeSex(null)
            }
        }
        if (prevProps.selectedDoctor != this.props.selectedDoctor) {
            if (this.props.selectedDoctor == 0) {
                this.props.changeSelectedDoctor(null)
            }
        }
        if (prevProps.clinic != this.props.clinic) {
            if (this.props.clinic == 0) {
                this.props.changeSelectedClinic(null)
                this.props.changeSelectedDoctor(null)
            } else {
                this.props.changeSelectedDoctor(null)
                if (this.state.doctorsObj && this.state.doctorsObj[this.props.clinic] && this.state.doctorsObj[this.props.clinic].length) {
                    this.setState({ doctorsList: this.state.doctorsObj[this.props.clinic] })
                }
            }
        }

        if (prevProps.addOnlyChildren !== this.props.addOnlyChildren) {
            if (this.props.addOnlyChildren && (!this.props.childrenList || this.props.childrenList?.length === 0)) {
                this.addNewSet();
            }
        }

        if (this.state.canSentNewRequestForChildren && !this.state.canSentNewRequest && !this.props.addOnlyChildren) {
            this.props.changeAddOnlyChildren(true);
        }
        if (prevProps.socialSecurityNumber !== this.props.socialSecurityNumber) {
            if ((this.props.country === 'denmark' && this.props.socialSecurityNumber?.length === 10) || this.props.socialSecurityNumber?.length === 12) {
                let dateOfBirth = this.props.getDateOfBirthFromSSN(this.props.socialSecurityNumber);
                if (dateOfBirth && this.props.changeDateOfBirth) {
                    this.props.changeDateOfBirth(dateOfBirth)
                    if (!this.state.disabledDateOfBirth) { this.setState({ disabledDateOfBirth: true }) }
                } else {
                    if (this.state.disabledDateOfBirth) { this.setState({ disabledDateOfBirth: false }) }
                }
            } else {
                if (this.state.disabledDateOfBirth) { this.setState({ disabledDateOfBirth: false }) }
            }
        }
        if (prevProps?.uData?._id !== this.props.uData?._id || prevState?.data?._id !== this.state.data?._id || prevProps?.[0]?.match?.params?.alias !== this.props?.[0]?.match?.params?.alias) {
            this.checkUserAuth();
        }
    }



    updateSort = (field, type) => {
        if (this.state.useState) {
            let state = this.state.params;
            state.sortField = field;
            state.sortType = type;
            this.setState({
                params: state
            })
        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0 }, [{ name: 'sortField', value: field }, { name: 'sortType', value: type }], false));

        }
    }

    updateParams = (name, value, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };
                obj[name] = value;
                this.setState({ params: obj });
            } else {
                let obj = this.state.params;
                obj[name] = value;
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(generateSearchLink(this.props[0].location.search, { entries: 10, page: 0 }, name, value, restart));
        }
    }
    updateMultipleParams = (fields, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj });

            } else {
                let obj = this.state.params;
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0 }, fields, restart));
        }
    }

    fetchBankId = () => {
        // let groupId;
        // if (this.state.data && this.state.data._id) {
        //     groupId = this.state.data._id;
        // }
        // fetch(API_ENDPOINT + '/users/bankid/v2/start', {
        //     method: 'POST',
        //     headers: {
        //         'content-type': 'application/json'
        //     },
        //     body: JSON.stringify({ group: groupId })
        // }).then(res => res.json()).then((result) => {
        //     if (result.redirect_url) {
        //         this.setState({

        //             bankIdModal: result.redirect_url
        //         }, () => {
        //             setTimeout(() => {
        //                 if (!this.props.uData) {
        //                     this.fetchBankId()
        //                 }
        //             }, 1000 * 60 * 4);
        //         })
        //     }
        // })
        this.setState({
            bankIdModal: true
        })
    }





    checkValidation = async () => {
        let validationError = false;
        let checkChildrenList = {}
        if (this.props.childrenList && this.props.childrenList.length) {
            checkChildrenList = JSON.parse(JSON.stringify(this.props.childrenList));

            for (let i = 0; i < this.props.childrenList.length; i++) {
                if (!this.props.childrenList[i].name) {
                    checkChildrenList[i].errorName = true;
                    if (!validationError) {
                        validationError = true;
                    }
                }
                if (!this.props.childrenList[i].socialSecurityNumber) {
                    checkChildrenList[i].errorSSN = "Child's social security number is required!".translate(this.props.lang);
                    if (!validationError) {
                        validationError = true;
                    }
                }
                if (!this.props.childrenList[i].dateOfBirth && this.props.showDateOfBirth) {
                    checkChildrenList[i].errorDateOfBirth = "Date of birth is required!".translate(this.props.lang);
                    if (!validationError) {
                        validationError = true;
                    }
                }
                if (this.props.childrenList[i].dateOfBirth) {
                    let checkAge = await this.props.calculateAgeFromDate(this.props.childrenList[i].dateOfBirth)
                    if (checkAge >= 13) {
                        checkChildrenList[i].errorDateOfBirth = "The child must be under 13 years of age!".translate(this.props.lang);
                        if (!validationError) {
                            validationError = true;
                        }
                    }
                }

                // if (this.props.childrenList[i].socialSecurityNumber) {
                //     if (this.props.childrenList[i].socialSecurityNumber.length == 12) {
                //         let checkAge = this.getAge(this.props.childrenList[i].socialSecurityNumber)
                //         if (checkAge >= 13) {
                //             checkChildrenList[i].errorSSN = "The child must be under 13 years of age!".translate(this.props.lang);
                //             if (!validationError) {
                //                 validationError = true;
                //             }
                //         }
                //     }
                // }
                if (!this.state.canSentNewRequest && this.state.canSentNewRequestForChildren && this.state.listOfApprovedChildren && this.state.listOfApprovedChildren.length) {
                    if (this.state.listOfApprovedChildren.filter(el => el == this.props.childrenList[i].socialSecurityNumber).length) {
                        checkChildrenList[i].errorSSN = "The child already added in approved request!".translate(this.props.lang);
                        if (!validationError) {
                            validationError = true;
                        }
                    }
                }
            }
        }
        return {
            checkChildrenList: checkChildrenList,
            validationError: validationError
        };

    }

    fetchBankIdSign = (obj) => {
        let groupId;
        if (this.state.data && this.state.data._id) {
            groupId = this.state.data._id;
        }
        fetch(API_ENDPOINT + '/users/bankid/v5/sign', {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            },
            body: JSON.stringify({ group: groupId })
        }).then(res => res.json()).then((result) => {
            if (result.redirect_url) {
                this.setState({
                    signBankIdModal: {
                        key: result.key,
                        url: result.redirect_url,
                        obj: obj
                    },
                    qrCodeLink: result.qrCodeLink,
                    key: result.key
                })
            }
        })
    }
    getAge = (socialSecurityNumber) => {

        const years = calculateAgeFromSSN(socialSecurityNumber, this.props.country)

        return years;
    }
    signWithBankId = async (data) => {
        if (data) {
            let obj = {
                socialSecurityNumber: data.socialSecurityNumber,
                userData: data.userData,
                email: data.email,
                phone: data.phone,
                street: data.street,
                zip: data.zip,
                city: data.city,
                childrenList: data.childrenList ? JSON.parse(JSON.stringify(data.childrenList)) : [],
                selectedDoctor: data.doctor,
                addOnlyChildren: data.addOnlyChildren,
                hasGivenConsent: this.state.hasGivenConsent,
                // clinic: data.clinic

            }

            if (data.addOnlyChildren && (!data.childrenList || data.childrenList.length === 0)) {
                this.setState({
                    childrenError: 'At least one child is required when ”List only children” is selected.'.translate(this.props.lang)
                });
                return;
            }
            if (!this.state.canSentNewRequest && this.state.canSentNewRequestForChildren) {
                obj.childrenListingRequest = true;
            }

            let checkChildrenList = [...obj.childrenList];
            let validationError = false;
            for (let i = 0; i < obj.childrenList.length; i++) {
                if (!obj.childrenList[i].name) {
                    checkChildrenList[i].errorName = true;
                    if (!validationError) {
                        validationError = true;
                    }
                }
                if (!obj.childrenList[i].socialSecurityNumber) {
                    checkChildrenList[i].errorSSN = "Child's social security number is required!".translate(this.props.lang);
                    if (!validationError) {
                        validationError = true;
                    }
                }
                if (!obj.childrenList[i].dateOfBirth && this.props.showDateOfBirth) {
                    checkChildrenList[i].errorDateOfBirth = "Date of birth is required!".translate(this.props.lang);
                    if (!validationError) {
                        validationError = true;
                    }
                }
                if (obj.childrenList[i].dateOfBirth) {
                    let checkAge = await this.props.calculateAgeFromDate(obj.childrenList[i].dateOfBirth)
                    if (checkAge >= 13) {
                        checkChildrenList[i].errorDateOfBirth = "The child must be under 13 years of age!".translate(this.props.lang);
                        if (!validationError) {
                            validationError = true;
                        }
                    }
                }
                // if (obj.childrenList[i].socialSecurityNumber) {
                //     if (obj.childrenList[i].socialSecurityNumber.length == 12) {
                //         let checkAge = this.getAge(obj.childrenList[i].socialSecurityNumber)
                //         if (checkAge >= 13) {
                //             checkChildrenList[i].errorSSN = "The child must be under 13 years of age!".translate(this.props.lang);
                //             if (!validationError) {
                //                 validationError = true;
                //             }
                //         }
                //     }
                // }
                if (!this.state.canSentNewRequest && this.state.canSentNewRequestForChildren && this.state.listOfApprovedChildren && this.state.listOfApprovedChildren.length) {
                    if (this.state.listOfApprovedChildren.filter(el => el == obj.childrenList[i].socialSecurityNumber).length) {
                        checkChildrenList[i].errorSSN = "The child already added in approved request!".translate(this.props.lang);
                        if (!validationError) {
                            validationError = true;
                        }
                    }
                }
            }
            if (!this.state.canSentNewRequest && this.state.canSentNewRequestForChildren) {
                if (!obj.childrenList || (obj.childrenList && obj.childrenList.length == 0)) {
                    this.setState({ errorEmail: 'You must add at least one child.'.translate(this.props.lang) })

                    if (!validationError) {
                        validationError = true;
                    }
                }

            }
            obj.groupAlias = this.props[0].match.params.alias;

            if (!this.state.hasGivenConsent) {
                validationError = true;
                this.setState({
                    childrenError: 'Consent is required!'.translate(this.props.lang), hasGivenConsentChecked: !this.state.hasGivenConsent
                });
            }
            if (validationError) {
                this.setState({ checkChildrenList: checkChildrenList })
            } else {


                fetch(API_ENDPOINT + '/users/account/update/check/email', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({ email: obj.email })
                }).then(res => res.json()).then((result) => {
                    if (result && !result.emailExist) {
                        this.setState({ signingModal: true }, () => {
                            this.fetchBankIdSign(obj)
                        })
                    } else {
                        this.setState({ errorEmail: 'Email address already in use.'.translate(this.props.lang) })
                    }
                })



            }


        }

    }
    insertRequest = (obj, relaystate) => {
        this.setState({ signBankIdModal: null })

        obj.relaystate = relaystate != 'null' ? relaystate : null;
        fetch(API_ENDPOINT + '/data/listing/new', {
            method: 'POST',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
            body: JSON.stringify(obj)
        }).then(res => res.json()).then((result) => {
            // if (result && !result.error) {
            //     this.setState({ signingModal: null, signBankIdModal: null, successModal: 'Your request has been submitted', addNew: false })
            // }
            if (result && result.error) {
                this.setState({ errorEmail: result.error.translate(this.props.lang), signingModal: null, SignBankIdModal: null })
            }
            if (result && !result.error) {
                this.setState({ signingModal: null, signBankIdModal: null, successModal: 'Your request has been submitted' })
            }
            if (obj.relaystate) {

                // fetch(API_ENDPOINT + '/users/bankid/v2/relaystate', {
                fetch(API_ENDPOINT + '/users/bankid/v5/relaystate', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({ relaystate: relaystate, group: this.state.data && this.state.data._id, key: this.state.key })
                }).then(res => res.json()).then((result) => {

                })
            }

        })



    }
    addNewSet = async () => {
        let childrenList = this.props.childrenList;
        if (!childrenList) {
            childrenList = [];
        }

        let obj = {
            name: '',
            socialSecurityNumber: '',
            dateOfBirth: ''
        }
        childrenList.push(obj);

        this.props.changeChildrenList(childrenList)

        let setObj = {
            refresh: new Date().getTime()
        }
        if (this.state.checkChildrenList) {
            let checkChildrenList = await this.checkValidation()
            if (checkChildrenList && checkChildrenList.checkChildrenList) {
                setObj.checkChildrenList = checkChildrenList.checkChildrenList;
            }
        }

        this.setState(setObj)
    }
    removeSet = async (idx) => {
        let childrenList = this.props.childrenList;
        if (childrenList?.length === 1 && this.props.addOnlyChildren) {
            this.setState({
                childrenError: 'At least one child is required when ”List only children” is selected.'.translate(this.props.lang)
            });
            return;
        }
        if (childrenList && childrenList[idx]) {
            childrenList.splice(idx, 1)
            this.props.changeChildrenList(childrenList)

            let setObj = {
                refresh: new Date().getTime()
            }
            if (this.state.checkChildrenList) {
                let checkChildrenList = await this.checkValidation()
                if (checkChildrenList && checkChildrenList.checkChildrenList) {
                    setObj.checkChildrenList = checkChildrenList.checkChildrenList;
                }
            }

            this.setState(setObj)
        }
    }
    changeFieldValue = async (name, value, idx) => {
        let childrenList = this.props.childrenList;
        if (childrenList && childrenList[idx]) {
            childrenList[idx][name] = value;

            if (name === 'socialSecurityNumber') {
                if ((this.props.country === 'denmark' && value?.length === 10) || value?.length === 12) {
                    let dateOfBirth = this.props.getDateOfBirthFromSSN(value);
                    if (dateOfBirth) {
                        childrenList[idx]['dateOfBirth'] = dateOfBirth;
                        childrenList[idx]['dateOfBirthDisabled'] = true;
                    } else {
                        childrenList[idx]['dateOfBirthDisabled'] = false;
                    }
                } else {
                    childrenList[idx]['dateOfBirthDisabled'] = false;
                }

            }

            this.props.changeChildrenList(childrenList)

            let setObj = {
                refresh: new Date().getTime()
            }
            if (this.state.checkChildrenList) {
                let checkChildrenList = await this.checkValidation()
                if (checkChildrenList && checkChildrenList.checkChildrenList) {
                    setObj.checkChildrenList = checkChildrenList.checkChildrenList;
                }
            }

            this.setState(setObj)
        }

    }

    goBackHome = () => {
        const alias = this.props[0]?.match?.params?.alias;
        if (alias) {
            this.props[0]?.history?.push('/' + alias);
        }
        else {
            this.props[0]?.history?.push('/');
        }
    }

    checkUserAuth = () => {
        const user = this.props.uData;
        const groupId = this.state.data?._id;
        const loggedInWith2fa = localStorage?.getItem?.('2faLogin');

        if (loggedInWith2fa && loggedInWith2fa === 'true' && user && groupId) {
            this.setState({ loading: true }, async () => {
                const newState = {
                    loading: false,
                };

                const response = await fetch(API_ENDPOINT + '/users/two-factor-auth/check-clinic-group', {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                    },
                    body: JSON.stringify({ groupId })
                });

                const result = await response?.json?.();

                if (!result) return this.setState(newState);

                if (result.error) {
                    newState.twoFactorAuthForbidden = result.error;
                    this.setState(newState);
                } else {
                    newState.twoFactorAuthForbidden = false;
                    this.setState(newState);
                }

                if (result.verify && result.verify === true) this.props.verifyUser?.();
            });
        }
    }

    navigateBackToProfile = () => {
        this.setState({ twoFactorAuthForbidden: false }, () => {
            this.props?.[0]?.history?.replace?.('/my-data/account');
        });
    }


    render() {

        if (!this.state.seoData?.bankIdAuthentication) return null;

        if (!this.props.uData) {
            return (
                // <div>
                //     <BankIdModal
                //         {...this.props}
                //         checkLastLogin={true}
                //         isOpen={this.state.bankIdModal}
                //         toggle={() => {

                //             if (this.props.uData) {
                //                 localStorage.setItem('groupAlias', this.props[0].match.params.alias);
                //             } else {
                //                 this.props[0].history.push(`/${this.props[0].match.params.alias}`)
                //             }
                //             this.setState({ bankIdModal: null })

                //         }}
                //         redirect_url={this.state.bankIdModal}></BankIdModal>
                // </div>
                <div className="login-main-wrap">
                    <div className="login-wrapper">
                        {/* {!this.props.userVerificationInProgress ? (
                            <>
                                <Isvg src={shapeBlue} className="shape-blue" />
                                <Isvg src={shapeGreen} className="shape-green" />
                                <img src={shapeYellow} className="shape-yellow" />
                            </>
                        ) : null} */}
                        <div className="logo-header">
                            {
                                this.state.data && this.state.data.logo ?
                                    <img style={{ width: '60%', objectFit: 'contain', maxHeight: 110 }} src={API_ENDPOINT + this.state.data.logo} />
                                    :
                                    null
                            }
                        </div>

                        <div className="log-in">
                            <Container fluid>
                                <Row>
                                    <Col lg={{ size: 12 }}>
                                        {
                                            this.props.userVerificationInProgress ? (
                                                <div className="loader-wrap">
                                                    <Player
                                                        autoplay={true}
                                                        loop={true}
                                                        src="/lf30_editor_l5cxzdyf.json"
                                                        style={{
                                                            height:
                                                                typeof window != "undefined" &&
                                                                    window.innerWidth < 768
                                                                    ? "128px"
                                                                    : "256px",
                                                            width:
                                                                typeof window != "undefined" &&
                                                                    window.innerWidth < 768
                                                                    ? "128px"
                                                                    : "256px",
                                                        }}
                                                    ></Player>
                                                </div>
                                            ) :
                                                (
                                                    <div className="panel" style={{
                                                        minWidth: typeof window != "undefined" &&
                                                            window.innerWidth < 768
                                                            ? null
                                                            : "508px",
                                                    }}>
                                                        <div className="login-container">
                                                            <h4 style={{ textAlign: 'center' }}>{"Lista dig hos".translate(this.props.lang)} {this.state.data && this.state.data.name ? this.state.data.name : null}</h4>
                                                            <div>
                                                                <BankId
                                                                    {...this.props}
                                                                    checkLastLogin={true}
                                                                    isOpen={this.state.bankIdModal}
                                                                    alias={this.props[0].match.params.alias}
                                                                    toggle={() => {

                                                                        if (this.props.uData) {
                                                                            localStorage.setItem('groupAlias', this.props[0].match.params.alias);
                                                                        } else {
                                                                            this.props[0].history.push(`/${this.props[0].match.params.alias}`)
                                                                        }
                                                                        this.setState({ bankIdModal: null })

                                                                    }}
                                                                    redirect_url={this.state.bankIdModal}></BankId>
                                                            </div>

                                                            <div className='listing-login-info-text'>
                                                                <p>{"By logging in, you agree to our".translate(this.props.lang)} <a href={`/${this.props[0].match.params.alias}/privacy-policy`} target="_blank">{"Privacy policy".translate(this.props.lang)}</a> {"and our".translate(this.props.lang)} <a href={`/${this.props[0].match.params.alias}/terms-of-use`} target="_blank">{"Terms of use".translate(this.props.lang)}</a>.</p>
                                                            </div>



                                                            {this.state.error ? (
                                                                <p style={{ color: 'red' }} className="error-message">
                                                                    {this.state.error.translate(this.props.lang)}
                                                                </p>
                                                            ) : null}

                                                        </div>
                                                    </div>
                                                )}
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </div>

                    {
                        this.state.twoFactorAuthForbidden ? <Modal
                            zIndex={99999}
                            size='md'
                            centered
                            isOpen={this.state.twoFactorAuthForbidden}
                        >
                            <ModalBody style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 20 }}>
                                <p style={{ textAlign: 'center', margin: 0, padding: 0, }}>{typeof this.state.twoFactorAuthForbidden === 'string' ? this.state.twoFactorAuthForbidden.translate(this.props.lang) : 'This clinic does not accept cases from patients who use two factor authentication, if their account is not verified with them.'.translate(this.props.lang)}</p>
                            </ModalBody>
                            <ModalFooter style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Button onClick={this.navigateBackToProfile} style={{ minWidth: 100 }}>
                                    {'Ok'.translate(this.props.lang)}
                                </Button>
                            </ModalFooter>
                        </Modal> : null
                    }
                </div>
            );

        } else /*if ((this.state.checkedListing && !this.state.listing.length) || this.state.addNew)*/ {

            return (
                <div className={this.props.autoHideSidebar ? "dashboard dashboard-auto-hide" : "dashboard"}>
                    {
                        this.state.errorEmail ?
                            <ErrorModal
                                lang={this.props.lang}
                                isOpen={this.state.errorEmail}
                                toggle={() => {
                                    this.setState({ errorEmail: null })
                                }}
                            >
                                {this.state.errorEmail}
                            </ErrorModal>
                            :
                            null
                    }
                    {
                        this.state.signBankIdModal && this.state.signBankIdModal.url ?
                            <SignBankIdModal
                                {...this.props}
                                signBankID={true}
                                alias={this.props[0].match.params.alias}
                                isOpen={this.state.signBankIdModal}
                                toggle={(id) => {
                                    if (this.state.signBankIdModal && this.state.signBankIdModal.obj) {
                                        console.log('ajmo na potpis');
                                        this.insertRequest(this.state.signBankIdModal.obj, id)

                                    }


                                }}
                                closeModal={() => this.setState({ signBankIdModal: null, signingModal: null })}
                                qrCodeLink={this.state.qrCodeLink}
                                redirect_url={this.state.signBankIdModal.url}></SignBankIdModal>
                            :
                            null
                    }
                    {
                        this.state.successModal ?
                            <InfoModal
                                isOpen={this.state.successModal}
                                toggle={() => this.setState({ successModal: null })}
                                header={'Info'.translate(this.props.lang)}
                                info={this.state.successModal.translate(this.props.lang)}
                                buttons={[
                                    <Button color='primary' onClick={() => {
                                        this.setState({
                                            successModal: null
                                        }, this.goBackHome)

                                    }}>{'Ok'.translate(this.props.lang)}</Button>
                                    // <Button color='primary' onClick={() => {
                                    //     this.setState({
                                    //         successModal: null
                                    //     }, this.get)

                                    // }}>{'Ok'.translate(this.props.lang)}</Button>
                                ]}
                            />

                            :

                            null
                    }
                    {
                        this.state.signingModal ?
                            <Modal isOpen={this.state.signingModal} centered>
                                <ModalHeader style={{ margin: 'auto' }}>
                                    {"Sign with BankID".translate(this.props.lang)}
                                </ModalHeader>
                                <ModalBody className="delete-modal signing-modal-body-wrap">
                                    {/* <Isvg src={info} /> */}
                                    <div className="loader-wrap">
                                        <Player
                                            autoplay={true}
                                            loop={true}
                                            src="/lf30_editor_l5cxzdyf.json"
                                            style={{ height: typeof window != 'undefined' && window.innerWidth < 768 ? "128px" : "256px", width: typeof window != 'undefined' && window.innerWidth < 768 ? "128px" : "256px", position: 'absolute', top: "50%", left: '50%', transform: 'translate(-50%, -50%)' }}
                                        ></Player>
                                    </div>

                                    {/* <div className='message-info' style={{ wordBreak: 'break-word' }}>
                                        {"Signing...".translate(this.props.lang)}
                                    </div> */}

                                </ModalBody>
                                <ModalFooter className="buttons-right-blue">

                                </ModalFooter>
                            </Modal>
                            :
                            null
                    }
                    <Container fluid>
                        <Row>
                            <Col lg="12">
                                <div className='panel'>
                                    <h5 className="component-header">{'Listing'.translate(this.props.lang)}</h5>

                                    <div className='listing-page-wrap'>

                                        {this.state.childrenError ? <ErrorModal
                                            lang={this.props.lang}
                                            isOpen={this.state.childrenError}
                                            toggle={() => {
                                                this.setState({ childrenError: null })
                                            }}
                                        >{this.state.childrenError}</ErrorModal> : null
                                        }

                                        {
                                            this.props.uData ?
                                                <FormBuilder selectedGroup={this.props.selectedGroup} selectedClinic={this.props.selectedClinic} countryTwoLetter={this.props.countryTwoLetter} country={this.props.country} dateFormat={this.props.dateFormat}
                                                    getStringDateTs={this.props.getStringDateTs}
                                                    lang={this.props.lang}
                                                    onSubmit={async (data) => await this.signWithBankId(data)}
                                                    initialValues={this.props.uData}
                                                    toggle={() => this.setState({ form: null })}
                                                    buttonText={'Sign with BankID'.translate(this.props.lang)}
                                                    buttonsWrapClassName={'listing-form-buttons-wrap'}
                                                    additionalBeforeButton={
                                                        <Button
                                                            className="add-child-btn"
                                                            color="primary"
                                                            onClick={() => {
                                                                this.addNewSet()
                                                            }}>
                                                            <Isvg src={addIcon} className='add-icon' />
                                                            {!this.props.childrenList || this.props.childrenList.length === 0 ? 'List child under 18 for whom I am the custodian'.translate(this.props.lang) : 'Add additional child'.translate(this.props.lang)}
                                                        </Button>

                                                    }
                                                    additionalAdditionalBeforeButton={
                                                        <CustomCheckbox
                                                            label={'I hereby declare that the information provided is correct and I give my consent for the named persons to be listed with the chosen clinic.'.translate(this.props.lang)}
                                                            value={this.state.hasGivenConsent}
                                                            onChange={(value) => { this.setState(prev => ({ hasGivenConsent: !prev.hasGivenConsent, hasGivenConsentChecked: false })) }}
                                                            error={this.state.hasGivenConsentChecked}
                                                        />
                                                    }
                                                    afterComponent={
                                                        <div className='listing-page-children-wrap'>
                                                            {
                                                                this.props.childrenList && this.props.childrenList.length ?
                                                                    <div className='listing-page-children'>
                                                                        {
                                                                            this.props.childrenList.map((item, idx) => {
                                                                                return (
                                                                                    <div className='listing-page-children-item'>
                                                                                        <div className={this.props.showDateOfBirth ? 'listing-page-children-item-left' : 'listing-page-children-item-left listing-page-children-item-left-without-date-of-birth'}>
                                                                                            <TextField
                                                                                                label={"Child's first and last name".translate(this.props.lang)}
                                                                                                error={this.state.checkChildrenList && this.state.checkChildrenList[idx] && this.state.checkChildrenList[idx].errorName ? "Child's first and last name is required!".translate(this.props.lang) : null}
                                                                                                type={'text'}
                                                                                                lang={this.props.lang}
                                                                                                value={item.name}
                                                                                                onChange={(e) => this.changeFieldValue('name', e.target.value, idx)}
                                                                                            />
                                                                                            <SocialSecurityNumber
                                                                                                selectedGroup={this.props.selectedGroup}
                                                                                                selectedClinic={this.props.selectedClinic}
                                                                                                country={this.props.country} placeholder={this.props.country === 'denmark' ? 'DDMMYYNNNNN' : this.props.lang === 'en' ? 'YYYYMMDDNNNN' : 'ÅÅÅÅMMDDNNNN'}
                                                                                                label={this.props.country === 'uk' ? "Child's date of birth".translate(this.props.lang) : "Child's social security number".translate(this.props.lang)}
                                                                                                error={this.state.checkChildrenList && this.state.checkChildrenList[idx] && this.state.checkChildrenList[idx].errorSSN ? this.state.checkChildrenList[idx].errorSSN : null}
                                                                                                type={'text'}
                                                                                                lang={this.props.lang}
                                                                                                value={item.socialSecurityNumber}
                                                                                                onChange={(value) => {
                                                                                                    this.changeFieldValue('socialSecurityNumber', value, idx)
                                                                                                }}

                                                                                            ></SocialSecurityNumber>
                                                                                            {
                                                                                                this.props.showDateOfBirth ?
                                                                                                    <DateOfBirth
                                                                                                        label={'Date of birth'.translate(this.props.lang)}
                                                                                                        // required={true}
                                                                                                        error={this.state.checkChildrenList && this.state.checkChildrenList[idx] && this.state.checkChildrenList[idx].errorDateOfBirth ? this.state.checkChildrenList[idx].errorDateOfBirth : null}
                                                                                                        disabled={item.dateOfBirthDisabled}
                                                                                                        value={item.dateOfBirth}
                                                                                                        dateFormat={this.props.dateFormat}
                                                                                                        onChange={(value) => {
                                                                                                            this.changeFieldValue('dateOfBirth', value, idx)
                                                                                                        }}
                                                                                                    />
                                                                                                    :
                                                                                                    null
                                                                                            }



                                                                                        </div>
                                                                                        <div className='listing-page-children-item-right'>
                                                                                            <div onClick={() => this.removeSet(idx)} className='remove-set-div'>&times;</div>
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            })
                                                                        }

                                                                    </div>
                                                                    :
                                                                    null
                                                            }


                                                        </div>
                                                    }
                                                    fields={[
                                                        {
                                                            type: 'row',
                                                            children: [
                                                                {
                                                                    type: 'col',
                                                                    width: {
                                                                        lg: 12,
                                                                        sm: 12,
                                                                        xs: 12
                                                                    },
                                                                    children: [
                                                                        {
                                                                            type: 'checkbox',
                                                                            name: 'addOnlyChildren',
                                                                            label: 'List children only'.translate(this.props.lang),
                                                                            disabled: this.state.canSentNewRequestForChildren && !this.state.canSentNewRequest ? true : false,
                                                                            labelInfo: 'If you click here, you as a guardian will not be listed with us. Only the children listed below will be listed.'.translate(this.props.lang)
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 12, sm: 12, xs: 12 },
                                                                    children: [{
                                                                        type: 'hr'
                                                                    }]
                                                                },
                                                                {
                                                                    type: 'col',
                                                                    width: {
                                                                        lg: 4,
                                                                        sm: 12,
                                                                        xs: 12
                                                                    },
                                                                    children: [
                                                                        {
                                                                            type: 'socialSecurityNumber',
                                                                            disabled: true,
                                                                            name: 'socialSecurityNumber',
                                                                            label: this.props.country === 'uk' ? "Date of birth".translate(this.props.lang) : 'Social security number'.translate(this.props.lang),
                                                                            validate: [required('Social security number is required!'.translate(this.props.lang))]

                                                                        },
                                                                    ]
                                                                },


                                                                {
                                                                    type: 'col',
                                                                    width: {
                                                                        lg: 4,
                                                                        sm: 12,
                                                                        xs: 12
                                                                    },
                                                                    children: [
                                                                        {
                                                                            type: 'text',
                                                                            name: 'userData.name',
                                                                            label: 'First and last name'.translate(this.props.lang),
                                                                            // disabled: this.props.alias,
                                                                            disabled: this.props.uData && this.props.uData.userData && this.props.uData.userData.name ? true : false,
                                                                            validate: [required('First and last name is required!'.translate(this.props.lang))]

                                                                        },
                                                                    ]
                                                                },
                                                                this.props.showDateOfBirth ?
                                                                    {
                                                                        type: 'col',
                                                                        width: {
                                                                            lg: 4,
                                                                            sm: 12,
                                                                            xs: 12
                                                                        },
                                                                        children: [
                                                                            {
                                                                                type: 'date-of-birth',
                                                                                dateFormat: this.props.dateFormat,
                                                                                name: 'dateOfBirth.date',
                                                                                disabled: this.state.disabledDateOfBirth,
                                                                                label: 'Date of birth'.translate(this.props.lang),
                                                                                validate: [required('Date of birth is required!'.translate(this.props.lang)), dateValidate('Invalid date!'.translate(this.props.lang))]

                                                                            },
                                                                        ]
                                                                    } : null,
                                                                {
                                                                    type: 'col',
                                                                    width: {
                                                                        lg: 4,
                                                                        sm: 12,
                                                                        xs: 12
                                                                    },
                                                                    children: [
                                                                        {
                                                                            type: 'select',
                                                                            name: 'userData.sex',
                                                                            disabled: !this.state.canSentNewRequest && this.state.canSentNewRequestForChildren ? true : false,
                                                                            labelInfo: "Your biological sex is needed for proper treatment".translate(this.props.lang),
                                                                            label: 'Sex'.translate(this.props.lang),
                                                                            values: [{ value: 0, name: 'Select'.translate(this.props.lang) }, { value: 'male', name: 'Male'.translate(this.props.lang) }, { value: 'female', name: 'Female'.translate(this.props.lang) }],
                                                                            // validate: [required('Sex is required!'.translate(this.props.lang))]
                                                                        },
                                                                    ]
                                                                },
                                                                {
                                                                    type: 'col',
                                                                    width: {
                                                                        lg: 4,
                                                                        sm: 12,
                                                                        xs: 12
                                                                    },
                                                                    children: [
                                                                        {
                                                                            type: 'email',
                                                                            name: 'email',
                                                                            label: 'Email address'.translate(this.props.lang),
                                                                            disabled: !this.state.canSentNewRequest && this.state.canSentNewRequestForChildren ? true : false,
                                                                            validate: [required('Email address is required!'.translate(this.props.lang)), emailAddress('Invalid email address!'.translate(this.props.lang))]
                                                                        },
                                                                    ]
                                                                },
                                                                {
                                                                    type: 'col',
                                                                    width: {
                                                                        lg: 4,
                                                                        sm: 12,
                                                                        xs: 12
                                                                    },
                                                                    children: [
                                                                        {
                                                                            type: 'phone',
                                                                            name: 'phone',
                                                                            label: 'Phone number'.translate(this.props.lang),
                                                                            disabled: !this.state.canSentNewRequest && this.state.canSentNewRequestForChildren ? true : false,
                                                                            validate: [required('Phone number is required!'.translate(this.props.lang))]
                                                                        },
                                                                    ]
                                                                },
                                                                {
                                                                    type: 'col',
                                                                    width: {
                                                                        lg: 4,
                                                                        sm: 12,
                                                                        xs: 12
                                                                    },
                                                                    children: [
                                                                        {
                                                                            type: 'text',
                                                                            name: 'street',
                                                                            label: 'Street'.translate(this.props.lang),
                                                                            disabled: !this.state.canSentNewRequest && this.state.canSentNewRequestForChildren ? true : false,
                                                                            validate: [required('Street is required!'.translate(this.props.lang))]
                                                                        },
                                                                    ]
                                                                },
                                                                {
                                                                    type: 'col',
                                                                    width: {
                                                                        lg: 4,
                                                                        sm: 12,
                                                                        xs: 12
                                                                    },
                                                                    children: [
                                                                        {
                                                                            type: 'text',
                                                                            name: 'zip',
                                                                            // pattern: new RegExp(/[0-9 ]+/i),
                                                                            label: 'Postal code'.translate(this.props.lang),
                                                                            disabled: !this.state.canSentNewRequest && this.state.canSentNewRequestForChildren ? true : false,
                                                                            validate: [required('Postal code is required!'.translate(this.props.lang))]
                                                                        },
                                                                    ]
                                                                },
                                                                {
                                                                    type: 'col',
                                                                    width: {
                                                                        lg: 4,
                                                                        sm: 12,
                                                                        xs: 12
                                                                    },
                                                                    children: [
                                                                        {
                                                                            type: 'text',
                                                                            name: 'city',
                                                                            pattern: new RegExp(/^[a-zA-Z \u00c0-\u017e]$/i),
                                                                            label: 'City'.translate(this.props.lang),
                                                                            disabled: !this.state.canSentNewRequest && this.state.canSentNewRequestForChildren ? true : false,
                                                                            validate: [required('City is required!'.translate(this.props.lang))]
                                                                        },
                                                                    ]
                                                                },

                                                                {
                                                                    type: 'col',
                                                                    width: {
                                                                        lg: 6,
                                                                        sm: 12,
                                                                        xs: 12
                                                                    },
                                                                    children: [
                                                                        {
                                                                            type: 'text',
                                                                            name: 'doctor',
                                                                            label: 'Desired doctor (can be left blank)'.translate(this.props.lang),
                                                                        },
                                                                    ]
                                                                },







                                                            ]
                                                        }
                                                    ]}
                                                ></FormBuilder>

                                                :
                                                null
                                        }

                                    </div>
                                </div>

                            </Col>
                            {this.props.uData?.userLevel === 1 ? <Col lg='12'>
                                <div style={{ textAlign: 'right' }}>
                                    <button onClick={() => this.goBackHome()} className="btn add-button listing-back-to-home-page" style={{ cursor: 'pointer' }}><Isvg src={backIcon} />{'Back to Home Page'.translate(this.props.lang)}</button>
                                </div>
                            </Col> : null}
                        </Row>
                    </Container>

                    {
                        this.state.twoFactorAuthForbidden ? <Modal
                            zIndex={99999}
                            size='md'
                            centered
                            isOpen={this.state.twoFactorAuthForbidden}
                        >
                            <ModalBody style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 20 }}>
                                <p style={{ textAlign: 'center', margin: 0, padding: 0, }}>{typeof this.state.twoFactorAuthForbidden === 'string' ? this.state.twoFactorAuthForbidden.translate(this.props.lang) : 'This clinic does not accept cases from patients who use two factor authentication, if their account is not verified with them.'.translate(this.props.lang)}</p>
                            </ModalBody>
                            <ModalFooter style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Button onClick={this.navigateBackToProfile} style={{ minWidth: 100 }}>
                                    {'Ok'.translate(this.props.lang)}
                                </Button>
                            </ModalFooter>
                        </Modal> : null
                    }

                </div >
            );

        }





    }
}

const selector = formValueSelector('form');

export default connect(state => {
    return {
        // selectedWaitingTimeType: selector(state, 'selectedWaitingTimeType')
        sex: selector(state, 'userData.sex'),
        selectedDoctor: selector(state, 'selectedDoctor'),
        childrenList: selector(state, 'childrenList'),
        clinic: selector(state, 'clinic'),
        addOnlyChildren: selector(state, 'addOnlyChildren'),
        socialSecurityNumber: selector(state, 'socialSecurityNumber'),
        dateOfBirth: selector(state, 'dateOfBirth.date')
    }

}, {
    changeSex: (value) => change("form", "userData.sex", value),
    changeSelectedDoctor: (value) => change("form", "selectedDoctor", value),
    changeChildrenList: (value) => change("form", "childrenList", value),
    changeSelectedClinic: (value) => change("form", "clinic", value),
    changeAddOnlyChildren: (value) => change('form', 'addOnlyChildren', value),
    changeDateOfBirth: (value) => change("form", "dateOfBirth.date", value)


})(Page(ListingPage));
