import React, { Component } from 'react';

import {
    Row,
    Col,
    Table,
    FormGroup, Label, Input, Button
} from 'reactstrap';

import ReactPaginate from 'react-paginate';
import moment from 'moment';
import Checkbox from './forms/fields/checkbox';
import { Player, Controls } from "@lottiefiles/react-lottie-player";

function isNumber(char) {
    if (typeof char !== 'string') {
        return false;
    }
    if (char.trim() === '') {
        return false;
    }

    return !isNaN(char);
}

/**
* Generate data list with pagination
* @author   Milan Stanojevic
*/
class ListBuilder extends Component {
    constructor(props) {
        super(props);
        this.columnVisibilityRef = React.createRef();
        this.state = {
            filter: '',
            // lastChangeFilter: null
            selectedItems: [],
        };
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }
    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }
    handleClickOutside = (event) => {
        if (this.columnVisibilityRef && this.columnVisibilityRef.contains && !this.columnVisibilityRef.contains(event.target)) {
            this.setState({ columnVisibilityMenu: null })
        }
    }

    updateColumnVisibility = (name) => {
        if (this.props.updateListBuilderFields && this.props.columnVisibility) {
            this.props.updateListBuilderFields(this.props.columnVisibility, name, () => {
                if (this.props.get) {
                    this.props.get()
                }
            })
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.updateFilterField != this.props.updateFilterField) {
            let params = this.props.params;
            if (params.filter != this.state.filter) {
                this.setState({ filter: params.filter ? params.filter : '' })
            }
        }
    }
    checkFilterDelay = () => {
        if (this.lastChangeFilter && this.props.filterDelay) {
            let delay = new Date().getTime() - this.lastChangeFilter;
            let delayTime = 100;
            if (this.props.filterDelay > 100) {
                delayTime = this.props.filterDelay - 100;
            }
            if (delay > delayTime) {
                this.props.updateMultipleParams?.([{ name: 'filter', value: this.state.filter }, { name: 'page', value: 0 }], true)
                this.lastChangeFilter = null
                // this.setState({ lastChangeFilter: null }, () => {

                // })
            }
        }
    }
    render() {
        let params = this.props.params;
        const multiselect = this.props.multiselect;

        return (
            <Row className="list-builder-wrap">
                {
                    !this.props.hideEntries ?
                        <Col lg="6" className='flex-center-space-between'>
                            <FormGroup>
                                <Label>{'Show'.translate(this.props.lang)}
                                    <Input type="select" className="entries" value={params.entries} onChange={(e) => this.props.updateMultipleParams([{ name: 'entries', value: e.target.value }, { name: 'page', value: 0 }], true)}>
                                        <option value={10}>10</option>
                                        <option value={25}>25</option>
                                        <option value={50}>50</option>
                                        <option value={100}>100</option>
                                    </Input>
                                    {'entries'.translate(this.props.lang)}
                                </Label>
                            </FormGroup>

                            {
                                this.props.columnVisibility ?
                                    <FormGroup className='column-visibility-wrap' >
                                        <div ref={node => this.columnVisibilityRef = node}>
                                            <Button color='primary' disabled={this.props.fields && this.props.fields.length ? false : true} onClick={() => { this.setState({ columnVisibilityMenu: !this.state.columnVisibilityMenu }) }}>{'Column visibility'.translate(this.props.lang)}</Button>
                                            {
                                                this.state.columnVisibilityMenu ?
                                                    <div className='column-visibility-menu-wrap'>
                                                        <div className='column-visibility-menu'>
                                                            {
                                                                this.props.fields && this.props.fields.map((item, idx) => {

                                                                    return (
                                                                        <div
                                                                            className={
                                                                                item.name && this.props.uData && this.props.uData.hiddenListBuilderFields && this.props.uData.hiddenListBuilderFields[this.props.columnVisibility] && this.props.uData.hiddenListBuilderFields[this.props.columnVisibility][item.name] ?
                                                                                    'column-visibility-menu-item hidden-column'
                                                                                    :
                                                                                    'column-visibility-menu-item'
                                                                            }
                                                                            onClick={() => {
                                                                                if (item.name) {
                                                                                    this.updateColumnVisibility(item.name)
                                                                                }
                                                                            }}>
                                                                            {item.label ? item.label.translate(this.props.lang) : ''}
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                    :
                                                    null
                                            }
                                        </div>

                                    </FormGroup>
                                    :
                                    null
                            }
                        </Col>
                        :
                        null
                }

                {
                    this.props.selectData ?
                        <Col lg="2" className={this.props.className ? this.props.className : 'flex-center-space-between'}>
                            <FormGroup>
                                {
                                    this.props.selectLabela ?
                                        <Label>
                                            {this.props.selectLabela.translate(this.props.lang)}
                                        </Label>
                                        :
                                        null
                                }

                                <Input type="select" style={{ paddingRight: 35 }} value={this.props.selectedValue} onChange={(e) => {
                                    this.props.onSelectChange(e.target.value)
                                }}>
                                    {this.props.selectData && this.props.selectData.map(item => {
                                        return (
                                            <option value={item.value}>{item.name}</option>
                                        )
                                    })}
                                </Input>
                            </FormGroup>
                        </Col>
                        :
                        null
                }


                {
                    !this.props.hideFilters ?
                        <Col lg="6" className="filter-results" style={this.props.export && this.props.exportFunction ? { alignItems: 'center' } : {}}>
                            {
                                this.props.export && this.props.exportFunction ?
                                    <FormGroup>
                                        <Button className='export-button-listbuilder' color='primary' onClick={this.props.exportFunction}>{'Export'.translate(this.props.lang)}</Button>
                                    </FormGroup>
                                    :
                                    null
                            }
                            <FormGroup>
                                <Label style={this.props.filterMoveRight ? { textAlign: 'end', width: '100%' } : {}}>
                                    {'Filter results'.translate(this.props.lang)}
                                    <Input
                                        type="text"
                                        style={{ marginRight: 0 }}
                                        // value={params.filter ? params.filter : ''}
                                        value={this.state.filter}
                                        onChange={(e) => {
                                            let value = e.target.value;

                                            if (this.props.hideDash) {
                                                let dash = value.indexOf('-');
                                                if (dash !== -1) {
                                                    if (value[dash - 1] && isNumber(value[dash - 1]) && value[dash + 1] && isNumber(value[dash + 1])) {
                                                        value = value.replace('-', '');
                                                    }
                                                }
                                            }

                                            if (this.props.filterDelay && value) {
                                                this.setState({ filter: value/*, lastChangeFilter: new Date().getTime()*/ }, () => {
                                                    this.lastChangeFilter = new Date().getTime();
                                                    setTimeout(() => {
                                                        this.checkFilterDelay()
                                                    }, this.props.filterDelay);
                                                })
                                            } else {
                                                this.setState({ filter: value }, () => {
                                                    this.props.updateMultipleParams?.([{ name: 'filter', value: this.state.filter }, { name: 'page', value: 0 }], true)
                                                })
                                            }


                                        }}
                                    // this.props.updateMultipleParams([{ name: 'filter', value: e.target.value }, { name: 'page', value: 0 }], true)
                                    ></Input>
                                </Label>
                            </FormGroup>

                        </Col>
                        :
                        null
                }
                <Col lg="12" className="table-container">
                    <div className="table-container-scroll">
                        {multiselect && this.state.selectedItems?.length && this.state.selectedItems.length > 0 ? <div className='list-builder-multiselect-div-container'>
                            {multiselect.map(obj => <Button onClick={() => {
                                obj?.onClick?.(this.state.selectedItems);
                                this.setState({ selectedItems: [] });
                            }} key={obj?.text} color={obj?.color ?? 'primary'}>
                                {obj?.text}
                            </Button>)}
                        </div> : null}
                        <Table responsive hover className={!this.props.actions ? 'cursor-row' : ''}>
                            <thead>
                                <tr style={{ whiteSpace: 'nowrap' }}>
                                    {multiselect && this.props?.items?.length && this.props?.items?.length > 0 ? <th>
                                        <input
                                            type='checkbox'
                                            className='list-builder-multiselect-input'
                                            checked={this.state.selectedItems && this.props.items && this.props.items.length > 0 && this.props.items.every(obj => this.state.selectedItems.includes(obj?._id))}
                                            onChange={() => {
                                                if (!this.props.items || !this.props.items.length) return;

                                                if (this.state.selectedItems && this.props.items && this.props.items.length > 0 && this.props.items.every(obj => this.state.selectedItems.includes(obj?._id))) {
                                                    this.setState({ selectedItems: [] })
                                                } else {
                                                    const ids = this.props.items.map(obj => obj?._id);
                                                    const filtered = ids.filter(id => id ? true : false);
                                                    this.setState({ selectedItems: filtered });
                                                }
                                            }}
                                        />
                                    </th> : null}
                                    {this.props.showNumeration ? <th>#</th> : null}

                                    {
                                        this.props.fields.filter(item => !(item.name && this.props.uData && this.props.uData.hiddenListBuilderFields && this.props.uData.hiddenListBuilderFields[this.props.columnVisibility] && this.props.uData.hiddenListBuilderFields[this.props.columnVisibility][item.name])).map((item, idx) => {
                                            return (
                                                <th className={item.allowSort ? 'sort-enabled' : null} onClick={() => {
                                                    if (item.allowSort) {
                                                        this.props.updateSort?.(item.name, !this.props.sortField ? 1 : this.props.sortField == item.name && parseInt(this.props.sortType) == 1 ? -1 : 1)
                                                    }
                                                }} key={idx}>{item.label} {item.allowSort ? <span className={this.props.sortField == item.name && parseInt(this.props.sortType) == 1 ? `sort sort-asc` : this.props.sortField == item.name && parseInt(this.props.sortType) == -1 ? 'sort sort-desc' : item.allowSort ? 'sort' : ''}></span> : null}</th>
                                            )
                                        })
                                    }
                                    {this.props.actions ?
                                        <th className="action-td">{this.props.actionLabel ? this.props.actionLabel : 'Action'.translate(this.props.lang)}</th>
                                        :
                                        null}
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.props.items && this.props.items.length ? this.props.items.map((item, idx) => {
                                        return (
                                            <tr className={item.___className} key={idx} onClick={() => {
                                                if (!this.props.actions && this.props.onClick) {
                                                    if (this.props.rawItems)
                                                        this.props.onClick(this.props.rawItems[idx])
                                                    else
                                                        this.props.onClick(item)
                                                }
                                            }}>
                                                {multiselect ? <td>
                                                    <input
                                                        type='checkbox'
                                                        className='list-builder-multiselect-input'
                                                        checked={item?._id && this.state.selectedItems?.includes(item?._id) ? true : false}
                                                        onChange={() => {
                                                            const id = item?._id;
                                                            if (!id) return;
                                                            if (this.state.selectedItems?.includes(id)) {
                                                                this.setState(prev => ({
                                                                    ...prev,
                                                                    selectedItems: prev?.selectedItems?.filter(_id => _id !== id)
                                                                }));
                                                            } else {
                                                                this.setState(prev => ({
                                                                    ...prev,
                                                                    selectedItems: [
                                                                        ...(prev?.selectedItems || []),
                                                                        id
                                                                    ]
                                                                }))
                                                            }
                                                        }}
                                                    />
                                                </td> : null}
                                                {this.props.showNumeration ? <td scope="row">{params.page * params.entries + idx + 1}</td> : null}
                                                {
                                                    this.props.fields.filter(item => !(item.name && this.props.uData && this.props.uData.hiddenListBuilderFields && this.props.uData.hiddenListBuilderFields[this.props.columnVisibility] && this.props.uData.hiddenListBuilderFields[this.props.columnVisibility][item.name])).map((field, fidx) => {
                                                        if (field.type == 'two-rows-text') {
                                                            return (
                                                                <td key={fidx}>
                                                                    {
                                                                        field.strong && field.strong(item) ?
                                                                            <>
                                                                                <strong> {field.beforeText && field.beforeText.condition(item) ? field.beforeText.text : ''}  {field.multilang ? Object.translate(item, field.name, this.props.lang) : Object.get(item, field.name)}</strong> <br /><span className="clinic-subgroup-name">{Object.get(item, field.secondRowName)}</span>
                                                                            </>
                                                                            :
                                                                            <>
                                                                                {field.beforeText && field.beforeText.condition(item) ? field.beforeText.text : ''} {field.multilang ? Object.translate(item, field.name, this.props.lang) : Object.get(item, field.name)} <br /><span className="clinic-subgroup-name">{Object.get(item, field.secondRowName)}</span>

                                                                            </>
                                                                    }

                                                                </td>
                                                            )
                                                        } else if (field.type == 'text') {
                                                            return (
                                                                <td key={fidx} style={{ maxWidth: field.maxWidth, overflow: field.maxWidth ? 'hidden' : 'unset', wordBreak: field.maxWidth ? 'break-all' : 'unset' }}>{field.multilang ? item[field.name] && item[field.name][this.props.lang] : item[field.name]}</td>
                                                            )
                                                        } else if (field.type == 'checkbox') {
                                                            return (
                                                                <td key={fidx}>
                                                                    <div>
                                                                        <Checkbox checked={item[field.name]} label={field.multilang ? item[field.name] && item[field.name][this.props.lang] : item[field.name]} />
                                                                    </div>
                                                                </td>
                                                            )
                                                        } else if (field.type == 'list') {
                                                            return (
                                                                <td key={fidx}>
                                                                    {
                                                                        item[field.name] ? item[field.name].map((fitem, fitemidx) => {
                                                                            return (
                                                                                <div className="list-item">
                                                                                    {fitem[field.itemName]}

                                                                                    {
                                                                                        field.actions.map((action, aidx) => {
                                                                                            return (
                                                                                                <button key={aidx} onClick={() => action.onClick(item, fitemidx)}>{action.component}</button>
                                                                                            )
                                                                                        })

                                                                                    }
                                                                                </div>
                                                                            )
                                                                        })
                                                                            :
                                                                            null
                                                                    }
                                                                </td>
                                                            )

                                                        }
                                                    })
                                                }
                                                {this.props.actions ?
                                                    <td className={`action-td ${this.props.actionColClassName ? this.props.actionColClassName : ''}`}>
                                                        {
                                                            this.props.actions && this.props.actions.length ?
                                                                this.props.actions.map((action, aidx) => {
                                                                    if (!action.condition || (action.condition && action.condition(item)))
                                                                        return (
                                                                            <button key={aidx} onClick={() => {
                                                                                if (this.props.rawItems) {
                                                                                    action.onClick(this.props.rawItems[idx]);
                                                                                }
                                                                                else {
                                                                                    action.onClick(item)
                                                                                }
                                                                            }}>{action.renderComponent ? action.renderComponent(item) : action.component}</button>
                                                                        )
                                                                })
                                                                :
                                                                null
                                                        }
                                                    </td>
                                                    :
                                                    null
                                                }



                                            </tr>
                                        )
                                    })
                                        :
                                        null

                                }
                            </tbody>
                        </Table>
                    </div>
                    {this.props.loading ?
                        <div className="loader-wrap" >
                            <Player
                                autoplay={true}
                                loop={true}
                                src="/lf30_editor_l5cxzdyf.json"
                                style={{ height: typeof window != 'undefined' && window.innerWidth < 768 ? "128px" : "256px", width: typeof window != 'undefined' && window.innerWidth < 768 ? "128px" : "256px" }}
                            ></Player>

                        </div>
                        :
                        null
                    }

                </Col>
                {
                    !this.props.hidePagination ?
                        <>
                            <Col lg="6">
                                <ReactPaginate
                                    previousLabel={'Previous'.translate(this.props.lang)}
                                    nextLabel={'Next'.translate(this.props.lang)}
                                    breakLabel={'...'}
                                    breakClassName={'page-item disabled'}
                                    breakLinkClassName={'page-link disabled'}
                                    pageCount={this.props.total / params.entries}
                                    marginPagesDisplayed={1}
                                    pageRangeDisplayed={2}
                                    onPageChange={(page) => { this.props.updateParams?.('page', page.selected) }}
                                    containerClassName={'pagination'}
                                    subContainerClassName={'pages pagination'}

                                    pageClassName={'page-item'}
                                    pageLinkClassName={'page-link'}
                                    previousClassName={'page-item'}
                                    previousLinkClassName={'page-link'}
                                    nextClassName={'page-item'}
                                    nextLinkClassName={'page-link'}
                                    activeClassName={'active'}
                                    forcePage={parseInt(params.page)}
                                />
                            </Col>
                            <Col lg="6">
                                <span className="news-counter" >{'Showing'.translate(this.props.lang)}  <span className="colored-green"> {this.props.total ? (params.page * params.entries + 1) : 0} </span> {'to'.translate(this.props.lang)} <span className="colored-green"> {this.props.total < (params.page * params.entries + params.entries * 1) ? this.props.total : (params.page * params.entries + params.entries * 1)} </span>  {'of'.translate(this.props.lang)} <span className="colored-blue"> {this.props.total} </span>  {'entries'.translate(this.props.lang)}</span>
                            </Col>
                        </>
                        :
                        null
                }
            </Row>

        );
    }
}

export default ListBuilder;