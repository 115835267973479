
import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import {
    FormGroup, Label, Form, FormFeedback
} from 'reactstrap';

import Isvg from 'react-inlinesvg';
import attach from '../../../assets/svg/attach.svg';
import image from '../../../assets/svg/imageIcon.svg';
import video from '../../../assets/svg/videoIcon.svg';

import fileSvg from '../../../assets/svg/fajl.svg';
import { API_ENDPOINT } from '../../../constants';
import { Player, Controls } from "@lottiefiles/react-lottie-player";
import axios from 'axios';

import ErrorModal from '../../../components/errorModal';



function parseText(response) {
    return response.text().then(text => {
        return {
            result: text,
            status: response.status
        }
    })
}
function parseJSON(response) {
    return response.json().then(json => {
        return {
            result: json,
            status: response.status
        }
    })
}

import Dropzone from 'react-dropzone';
import GridLayout from 'react-grid-layout';


class Gallery extends Component {
    constructor(props) {
        super(props);
        this.dropzone = React.createRef()
        this.getBase64 = this.getBase64.bind(this);
        this.removeImage = this.removeImage.bind(this);
        this.onDrop = this.onDrop.bind(this);
        this.onLayoutChange = this.onLayoutChange.bind(this);
        this.state = {
            files: [],
            imagesLayout: [],
            _uploading: [],
        };
    }


    componentDidMount() {
        let files = [];
        let imagesLayout = [];
        let value = this.props.value;
        if (!value) {
            value = [];
        }
        for (let i = 0; i < value.length; i++) {
            files.push(value[i]);
            imagesLayout.push(i);
        }

        this.setState({
            files: files,
            imagesLayout: imagesLayout,
        });

    }



    componentDidUpdate(prevProps, prevState) {
        let value = this.props.value;
        if (!value) {
            value = [];
        }

        if ((!this.state.files.length && value.length) && !(prevState.files.length && !this.state.files.length)) {
            let files = [];
            let imagesLayout = [];
            for (let i = 0; i < value.length; i++) {
                files.push(value[i]);
                imagesLayout.push(i);
            }

            this.setState({
                files: files,
                imagesLayout: imagesLayout,
            });

        }

        if (prevState.files.length && !this.state.files.length) {
            let files = [];
            for (let i = 0; i < this.state.imagesLayout.length; i++) {
                files.push(this.state.files[this.state.imagesLayout[i]]);
            }

            this.props.onChange(files);

        }
    }


    removeImage(idx) {

        let files = this.state.files.slice(0, idx).concat(this.state.files.slice(idx + 1, this.state.files.length))
        let imagesLayout = this.state.imagesLayout.slice(0, idx).concat(this.state.imagesLayout.slice(idx + 1, this.state.imagesLayout.length))
        let _uploading = this.state._uploading.slice(0, idx).concat(this.state._uploading.slice(idx + 1, this.state._uploading.length))
        this.setState({
            files: files,
            imagesLayout: imagesLayout,
            _uploading: _uploading
        });
    }
    handleUploadFileProgress = (data, progress) => {
        // if (data){
        //   IdleTimerManager.setIdleTimerDisabled(true);
        // }else{
        //   IdleTimerManager.setIdleTimerDisabled(false);
        // }
        this.setState({
            uploadingFile: data,
            uploadingFileProgress: progress
        })
    }

    getBase64(file) {
        // return new Promise((resolve, reject) => {
        //     const reader = new FileReader();
        //     reader.readAsDataURL(file);
        //     reader.onload = () => {
        //         let encoded = reader.result;
        //         resolve(encoded);
        //     };
        //     reader.onerror = error => reject(error);
        // });
    }


    async onDrop(imageFiles) {

        let imagesLayout = [];
        let images = [];
        let _uploading = [];

        for (let i = 0; i < imageFiles.length; i++) {
            let input = imageFiles[i];
            if (input) {

                let fsize = input.size;
                let file = Math.round((fsize / 1024))



                let isVideo = input.name.toLowerCase().indexOf('.mp4');

                if (isVideo == -1) {
                    isVideo = input.name.toLowerCase().indexOf('.mov');
                }
                if (file > 5120 && isVideo == -1) {
                    this.setState({ error: "File size limit has been reached! (max 5 MB)".translate(this.props.lang) })
                } else if (file > 524288 && isVideo > -1) {
                    this.setState({ error: "File size limit has been reached! (max 512 MB)".translate(this.props.lang) })
                } else {
                    this.setState({
                        _loading: true
                    })
                    let name = this.state.files.length + i;

                    let formData = new FormData();
                    formData.append('file', input);
                    if (this.props.alias) {
                        formData.append('alias', this.props.alias)
                    } else {
                        formData.append('group', this.props.group)
                        formData.append('fileType', 'clinicSupport')
                    }
                    if (this.props.acceptTypes) {
                        formData.append('acceptTypes', this.props.acceptTypes)
                    }

                    this.setState({ origValue: this.props.value })
                    // this.props.onChange('loading')

                    this.handleUploadFileProgress('Processing file...'.translate(this.props.lang));

                    axios.post(API_ENDPOINT + '/data/upload-document', formData, {
                        headers: {
                            Accept: 'application/json',
                            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        },
                        onUploadProgress: (progressEvent) => {
                            this.handleUploadFileProgress('Uploading file...'.translate(this.props.lang), Math.round((progressEvent.loaded * 100) / progressEvent.total));

                        }
                    }).then((response) => {
                        if (response.status != 200) {
                            this.setState({ error: response.data.translate(this.props.lang), _loading: null })
                        } else {
                            let obj = { fileName: input.name, file: response.data };
                            if (response.data && response.data.split('.').length && response.data.split('.')[1]) {
                                let extension = response.data.split('.')[1];
                                extension = extension.toLowerCase();
                                if (extension == 'png' || extension == 'jpg' || extension == 'jpeg' || extension == 'gif' || extension == 'svg') {
                                    obj.preview = input;
                                }
                            }
                            let files = this.state.files;
                            let _uploading = this.state._uploading;
                            files[name] = obj;
                            _uploading[name] = null;

                            this.setState({
                                _uploading: _uploading,
                                files: files
                            }, () => {
                                this.props.onChange(this.state.files);
                            })

                            // let value = this.props.value;
                            // if (!value) {
                            //     value = []
                            // }
                            // value = value.filter(el => el);
                            // value.push(obj)
                            // this.props.onChange(value);
                            this.setState({
                                _loading: null
                            })
                        }
                    }).catch((error) => {
                        if (error.response) {
                            if (error.response.status != 200)
                                this.setState({ error: error.response.data.translate(this.props.lang), _loading: null })

                        }
                    })


                }
            }


            // --------------------------------------------
            // let formData = new FormData();
            // formData.append('file', imageFiles[i]);
            // let name = this.state.files.length + i;


            // fetch(API_ENDPOINT + '/upload-image', {
            //     method: 'POST',
            //     headers: {
            //         Accept: 'application/json',
            //         //'Content-Type': 'multipart/form-data',
            //         'Authorization': `Bearer ${localStorage.getItem('authToken')}`

            //     },
            //     body: formData
            // }).then((res) => res.text()).then((img) => {
            //     //this.props.onChange(img);
            //     this.setState({
            //         _loading: null
            //     })

            //     let files = this.state.files;
            //     let _uploading = this.state._uploading;
            //     files[name] = img;
            //     _uploading[name] = null;

            //     this.setState({
            //         _uploading: _uploading,
            //         files: files
            //     }, () => {
            //         this.props.onChange(this.state.files);
            //     })


            // });

            //---------------
            // images.push(null);
            // imagesLayout.push(i);
            // _uploading.push(true);
        }

        // this.setState({
        //     files: this.state.files.concat(images),
        //     imagesLayout: this.state.imagesLayout.concat(imagesLayout),
        //     _uploading: this.state._uploading.concat(_uploading)
        // }, () => {
        //     let files = [];
        //     for (let i = 0; i < this.state.imagesLayout.length; i++) {
        //         files.push(this.state.files[this.state.imagesLayout[i]]);
        //     }
        //     console.log(files)

        //     // this.props.onChange(files);
        // });




    }



    onLayoutChange(layout) {
        let arr = [];
        for (let i = 0; i < layout.length; i++) {
            arr.push({ idx: layout[i].i, position: layout[i].y * 3 + layout[i].x });
        }

        arr.sort(function (a, b) { return (a.position > b.position) ? 1 : ((b.position > a.position) ? -1 : 0); });
        let imagesLayout = [];
        for (let i = 0; i < arr.length; i++) {
            imagesLayout.push(parseInt(arr[i].idx));
        }


        this.setState({
            imagesLayout: imagesLayout
        }, () => {
            let files = [];
            for (let i = 0; i < this.state.imagesLayout.length; i++) {
                files.push(this.state.files[this.state.imagesLayout[i]]);
            }

            this.props.onChange(files);


        });

    }


    render() {

        let images = [];
        if (this.state.files) {

            let x = 0;
            let y = 0;
            for (let i = 0; i < this.state.files.length; i++) {
                // console.log(this.state.files[i])
                let type;
                let extension;
                if (this.state.files[i] && this.state.files[i].file && this.state.files[i].file.split('/') && this.state.files[i].file.split('/').length && this.state.files[i].file.split('/')[this.state.files[i].file.split('/').length - 1] && this.state.files[i].file.split('/')[this.state.files[i].file.split('/').length - 1].split('.') && this.state.files[i].file.split('/')[this.state.files[i].file.split('/').length - 1].split('.')[1]) {
                    extension = this.state.files[i].file.split('/')[this.state.files[i].file.split('/').length - 1].split('.')[1];
                    extension = extension.toLowerCase()
                }
                if (extension == 'png' || extension == 'jpg' || extension == 'jpeg' || extension == 'gif' || extension == 'svg' || extension == 'webm') {
                    type = 'image';
                } else if (extension == 'mp4' || extension == 'avi' || extension == 'mov') {
                    type = 'video';
                }
         

                let layout = { i: i.toString(), x: x, y: y, w: 1, h: 1 };
                let item = {
                    content: (
                        <div className="image" key={i.toString()} data-grid={layout}>

                            <div className="image-wrap">

                                {/* <Isvg src={fileSvg} /> */}
                                {
                                    this.state.files[i] && this.state.files[i].preview && this.state.files[i].preview.size && URL.createObjectURL(this.state.files[i].preview) ?
                                        <img src={URL.createObjectURL(this.state.files[i].preview)} />
                                        :
                                        type == 'image' && this.state.files[i].file ?
                                            <Isvg src={image} />
                                            // <img src={API_ENDPOINT + this.state.files[i].file}  />
                                            :
                                            type == 'video' ?
                                                <Isvg src={video} />
                                                :
                                                <Isvg src={fileSvg} />
                                }

                                <div className={'delete'} onClick={() => this.removeImage(i)}>
                                    &times;
                                </div>
                            </div>
                            {
                                this.state.files[i] && this.state.files[i].fileName ?
                                    this.state.files[i].fileName.length < 20 ?
                                        <p>{this.state.files[i].fileName}</p>
                                        :
                                        <p>{this.state.files[i].fileName.substr(0, 17) + '...'}</p>
                                    :
                                    null
                            }

                        </div>
                    )
                };
                images.push(item);
                x++;
                if (x >= 3) {
                    y++;
                    x = 0;
                }
            }



        }


        return (
            <div className="gallery-picker-wrap">
                <label>{this.props.label}</label>
                <div className="file-picker" ref={(ref) => this.dropzone = ref}>

                    <Dropzone
                        onDrop={this.onDrop}
                        className='dropzone'
                        activeClassName='active-dropzone'
                        multiple={true}
                    >
                        <button type="button"><i className="mdi mdi-file-outline"></i></button>
                    </Dropzone>

                    <div className="bottom-content">
                        <Isvg src={attach} />
                        <p> <span>{'Upload a file'.translate(this.props.lang)}</span> {'or drag and drop'.translate(this.props.lang)}</p>
                    </div>


                    <GridLayout
                        className="grid-layout"
                        onLayoutChange={this.onLayoutChange}
                        width={this.dropzone.offsetWidth}
                        margin={[30, 30]}
                        rowHeight={120}

                        compactType={'horizontal'}
                        isResizable={false}
                        verticalCompact={true}
                        horizontalCompact={true}
                        useCSSTransforms={true}
                        cols={3}
                    >

                        {
                            images.map((image, idx) => {
                                return (
                                    image.content
                                );
                            })
                        }
                    </GridLayout>


                </div>

                {
                    this.state.error ?

                        <ErrorModal lang={this.props.lang}
                            isOpen={this.state.error}
                            toggle={() => this.setState({ error: null })}
                        >
                            {this.state.error.translate(this.props.lang)}
                        </ErrorModal>

                        :
                        null
                }

                <div style={{ color: 'red' }}>{this.props.error}</div>
                {/* <div> {this.props.value && typeof this.props.value == 'object' ? this.props.value.fileName : ''} </div> */}





            </div>
        );
    }
}

export default Gallery;