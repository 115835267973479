import React, { Component } from 'react';
import Page from '../../containers/page';

import {
    Container,
    Row,
    Col,
    Button,
    UncontrolledAlert,
    Alert,
    UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Table, Form, Modal, ModalHeader, ModalBody, ModalFooter, Input, FormGroup, Label

} from 'reactstrap';

import Isvg from 'react-inlinesvg'

import { formValueSelector, change } from 'redux-form';  // ES6
import { connect } from 'react-redux';

import FormBuilder from '../../components/forms/modalFormBuilder';
import { required } from '../../components/forms/validation';

import ListBuilder from '../../components/listBuilder';
import DeleteModal from '../../components/deleteModal';
import { getSearchParams, generateSearchLink, generateSearchLinkMultiple } from '../../helpers/linkHelper';
import { API_ENDPOINT } from '../../constants';
import { routes } from '../../routesList';
import { SortableContainer, SortableElement, sortableHandle } from 'react-sortable-hoc';
import arrayMove from 'array-move';

import { Player, Controls } from "@lottiefiles/react-lottie-player";

import edit from '../../assets/svg/edit.svg'
import garabage from '../../assets/svg/garbage.svg'
import Search from '../../components/search';
import addIcon from '../../assets/svg/add-icon.svg';
import dragIcon from '../../assets/svg/dadrp.svg';
import docusignIcon from '../../assets/svg/docusign.svg';

import HTMLField from '../../components/forms/fields/html';


import profileUser from '../../assets/images/user.png';
import notification from '../../assets/svg/notification.svg'
import moreIcon from '../../assets/svg/more.svg'
import { faThumbsDown } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';

import DatePicker from '../../components/forms/fields/datePicker';

function parseJSON(response) {
    return response.json().then(json => {
        return {
            result: json,
            status: response.status
        }
    })
}
function getInitials(string) {
    let returnValue = ''
    if (string) {
        string = string.trim();
        let splitString = string.split(' ');
        if (splitString && splitString.length) {
            for (let i = 0; i < splitString.length; i++) {
                if (splitString[i] && splitString[i].substr(0, 1)) {
                    returnValue += splitString[i].substr(0, 1).toUpperCase();
                }
            }
        }
    }


    return returnValue;
}


const SortableItem = SortableElement(({ value, }) => <li className={value.className}> {value.component}</li>);

const SortableList = SortableContainer(({ items }) => {
    return (
        <ul className="sort-list">
            {items.map((value, index) => (
                <SortableItem key={`item-${index}`} index={index} value={value} />
            ))}
        </ul>
    );
});
const DragHandle = sortableHandle(({ children }) => children);

/**
* List of sorted modules
* @author   Milan Stanojevic
*/
class QAModule extends Component {
    constructor(props) {
        super(props);
        this.player = React.createRef();
        this.player2 = React.createRef();
        this.state = {
            showPlayer: false,
            showPlayer2: false,
            ...props.initialData,
            form: false,
            useState: false,
            params: {
                entries: 10,
                page: 0
            },
            items: [],
            endpoints: [],
            formModules: [],
            total: 0,
            loading: true
        };
    }

    showPlayer = () => {
        this.setState({
            showPlayer: true,
        });
    };

    hidePlayer = () => {
        this.setState({
            showPlayer: false,
        });
    };


    get = () => {
        this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));

        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0 })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

        for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
            this.props.loadDataWithQuery[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0 })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

    }


    refresh = () => {
        if (this.state.saveEnabled) {
            return;
        }

        this.get();
    }

    componentDidMount() {
        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }

        this.get();

        this.refreshInterval = setInterval(this.refresh, 1500);


        fetch(API_ENDPOINT + '/qa/docusign/check', {
            method: 'GET',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
        }).then(parseJSON).then(({ result, status }) => {
            if (result && result.consentUrl) {
                this.setState({
                    consentUrl: result.consentUrl
                })
            }

        })


    }

    componentWillUnmount() {
        if (this.refreshInterval) {
            clearInterval(this.refreshInterval);
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps[0].location.search != this.props[0].location.search) {
            this.setState({
                loading: true
            }, () => {
                this.get();

            })
        }
    }


    updateSort = (field, type) => {
        if (this.state.useState) {
            let state = this.state.params;
            state.sortField = field;
            state.sortType = type;
            this.setState({
                params: state
            })
        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0 }, [{ name: 'sortField', value: field }, { name: 'sortType', value: type }], false));

        }
    }

    updateParams = (name, value, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };
                obj[name] = value;
                this.setState({ params: obj });
            } else {
                let obj = this.state.params;
                obj[name] = value;
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(generateSearchLink(this.props[0].location.search, { entries: 10, page: 0 }, name, value, restart));
        }
    }
    updateMultipleParams = (fields, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj });

            } else {
                let obj = this.state.params;
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0 }, fields, restart));
        }
    }




    onSortEnd = ({ oldIndex, newIndex }) => {
        this.setState({
            saveEnabled: true
        })
        this.setState(({ items }) => ({
            items: arrayMove(items, oldIndex, newIndex),
            dragIndex: null
        }));
    };


    updateSubMenu = (index, oldIndex, newIndex) => {
        console.log(index, oldIndex, newIndex)
        let items = this.state.items;
        let newItems = this.state.items[index].submenu;
        newItems = arrayMove(newItems, oldIndex, newIndex);
        items[index].submenu = newItems;
        this.setState({
            items,
            dragIndex1: null
        })

    };

    stopAnimation = () => {
        this.player.current.play();
    };

    /**
    * Delete module
    * @author   Milan Stanojevic
    * @Objectparam    {String} id                    module's id
    */
    delete = (id) => {
        this.setState({
            loading: true
        }, () => {

            fetch(API_ENDPOINT + '/qa/test/' + id, {
                method: 'DELETE',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
            }).then(res => res.json()).then((result) => {
                this.get();
            })
        })

    }



    /**
    * Update module's position
    * @author   Milan Stanojevic
    */
    updatePosition = () => {
        this.setState(
            {
                showPlayer: true,
            },
            () => {
                let positions = [];
                let items = this.state.items;
                for (let i = 0; i < items.length; i++) {
                    positions.push({
                        _id: items[i]._id,
                        position: i
                    });
                }

                this.player.current.play();
                fetch(API_ENDPOINT + '/qa/test/sort', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({ items: positions })
                }).then(res => res.json()).then((result) => {
                    this.setState(
                        {
                            message: 'Modules updated successfully',
                            //message: result.message,
                            showPlayer: false,
                            showPlayer2: true,
                        },
                        () => {
                            setTimeout(() => {
                                this.setState({ showPlayer2: false, saveEnabled: false });
                            }, 1000);
                        }
                    );
                    this.get();
                    this.props.verifyUser();
                })
            }
        )
    }

    runAll = () => {
        fetch(API_ENDPOINT + '/qa/test/run-all', {
            method: 'GET',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
        }).then(res => res.json()).then((result) => {
            if (result && result.key) {
                if (typeof window != 'undefined') {
                    localStorage.setItem('qaKey', result.key)
                }
            }

        })

    }
    stopTest = () => {
        let key;
        if (typeof window != 'undefined' && localStorage.qaKey) {
            key = localStorage.qaKey;
        }
        if (key) {
            fetch(API_ENDPOINT + '/qa/test/stop/all', {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify({
                    key: key
                })
            }).then(res => res.json()).then((result) => {
                if (typeof window != 'undefined' && localStorage.qaKey) {
                    localStorage.removeItem('qaKey')
                }

            })
        }
    }

    runSingle = (id) => {
        fetch(API_ENDPOINT + '/qa/test/run-single/' + id, {
            method: 'GET',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
        }).then(res => res.json()).then((result) => {

        })

    }
    runFrom = (id) => {
        fetch(API_ENDPOINT + '/qa/test/run-from/' + id, {
            method: 'GET',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
        }).then(res => res.json()).then((result) => {
            if (result && result.key) {
                if (typeof window != 'undefined') {
                    localStorage.setItem('qaKey', result.key)
                }
            }

        })

    }
    resetChrome = () => {
        fetch(API_ENDPOINT + '/qa/chrome/kill', {
            method: 'GET',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
        }).then(res => res.json()).then((result) => {
            this.setState({
                _chromeReset: true
            })
        })

    }

    fetchDocuments = (id) => {

        this.setState({
            envelopesModal: true
        })

        fetch(API_ENDPOINT + '/qa/test/documents/' + id, {
            method: 'GET',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
        }).then(res => res.json()).then((result) => {
            this.setState({
                envelopes: result
            })
        })


    }



    resetToOriginalPositions = () => {
        let positions = [];
        let items = this.state.items;
        for (let i = 0; i < items.length; i++) {
            positions.push({
                _id: items[i]._id,
                position: items.length - items[i].number
            });
        }

        fetch(API_ENDPOINT + '/qa/test/sort', {
            method: 'POST',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
            body: JSON.stringify({ items: positions })
        }).then(res => res.json()).then((result) => {
            this.setState(
                {
                    message: 'Modules updated successfully',
                    //message: result.message,
                    showPlayer: false,
                    showPlayer2: true,
                },
                () => {
                    setTimeout(() => {
                        this.setState({ showPlayer2: false, saveEnabled: false });
                    }, 1000);
                }
            );
            this.get();
            this.props.verifyUser();
        })
    }


    onDismiss = () => { this.setState({ message: null }) };

    saveSettings = (settingsModal, settingsItem, deviationItem, settingsTab) => {
        if (settingsModal && settingsModal._id) {
            let data = {}
            if (settingsTab == 1 && settingsItem) {
                data.settings = settingsItem;
            } else if (settingsTab == 2 && deviationItem) {
                data.deviation = deviationItem;
            }
            fetch(API_ENDPOINT + '/qa/test/' + settingsModal._id, {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify(data)
            }).then(res => res.json()).then((result) => {
                this.setState({ settingsModal: null, settingsItem: null })
                // this.get();
            })
        }

        // this.setState({ settingsModal: null, settingsItem: null })
    }
    saveTest = (newTest) => {
        fetch(API_ENDPOINT + '/qa/test/new', {
            method: 'PUT',
            headers: {
                // 'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,

            },
            body: JSON.stringify(newTest)
        }).then(res => res.json()).then((result) => {
            this.setState({ newTest: null })
            this.get();
        })


    }
    render() {
        let params = {};
        if (this.state.useState) {
            params = this.state.params;
        } else {
            params = getSearchParams(this.props[0].location.search, { entries: 10, page: 0 })
        }

        return (
            <div className={this.props.autoHideSidebar ? "dashboard dashboard-auto-hide" : "dashboard"}>
                {/* {this.state.message ?
                    <Alert toggle={this.onDismiss} isOpen={this.state.message} color="success">
                        {this.state.message.translate(this.props.lang)}
                    </Alert>
                    :
                    null
                } */}
                <Container fluid>
                    <Row>
                        <Col lg="12">



                            <div className="panel">
                                <div className="panel-header">
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <h5 style={{ marginBottom: 0 }} className="component-header">{this.props.linksMeta && this.props.linksMeta[this.props[0].location.pathname] ? this.props.linksMeta[this.props[0].location.pathname][this.props.lang] : ''}

                                        </h5>

                                        <button style={{ marginLeft: 20 }} className="btn add-button" onClick={() => this.setState({
                                            newTest: {
                                                name: '',
                                                items: [],
                                                url: '',
                                            }
                                        })}><Isvg src={addIcon} /> {'Add'.translate(this.props.lang)}</button>
                                    </div>
                                    <div>
                                        {this.state.consentUrl ?
                                            <a href={this.state.consentUrl}><Button className="docusign-button" style={{ marginRight: 10 }} color="primary" onClick={() => this.resetToOriginalPositions()}><Isvg src={docusignIcon} /> {'Grant consent'.translate(this.props.lang)}</Button></a>
                                            :
                                            null

                                        }
                                        {!this.state._chromeReset ?

                                            <Button style={{ marginRight: 10 }} color="danger" onClick={() => this.resetChrome()}>{'Reset chrome'.translate(this.props.lang)}</Button>
                                            :
                                            null
                                        }

                                        <Button style={{ marginRight: 10 }} color="primary" onClick={() => this.resetToOriginalPositions()}>{'Reset order'.translate(this.props.lang)}</Button>

                                        {
                                            typeof window != 'undefined' && localStorage.qaKey ?
                                                <Button style={{ marginRight: 10 }} color="danger" onClick={() => this.stopTest()}>{'Stop all tests'.translate(this.props.lang)}</Button>
                                                :
                                                null
                                        }
                                        <Button color="primary" onClick={() => this.runAll()}>{'Run all tests'.translate(this.props.lang)}</Button>
                                    </div>
                                </div>
                                <SortableList useDragHandle onSortStart={({ node, index }) => {
                                    this.setState({ dragIndex: index })
                                }}
                                    dragIndex={this.state.dragIndex}
                                    hideSortableGhost={false} lang={this.props.lang} items={this.state.items.map((item, idx) => {
                                        return {
                                            className: idx == this.state.dragIndex ? 'drag' : null,
                                            component: <div className="qa-test-item">
                                                <h6><DragHandle><div><Isvg src={dragIcon} /></div></DragHandle> #{item.number} - {item.name} <div className="info">
                                                    {item.status ? <p>{'Status:'.translate(this.props.lang)} <span>{item.status}</span></p> : null}
                                                    {item.resultTimestamp ? <p>{'Result:'.translate(this.props.lang)} <span style={{ color: item.result ? 'green' : 'red' }}>{item.result ? 'Passed' : 'Failed'} ({moment.unix(item.resultTimestamp).format(this.props.dateFormat)} {moment.unix(item.resultTimestamp).format('HH:mm:ss')})</span></p> : null}

                                                </div> <Button onClick={() => {
                                                    this.setState({
                                                        showLog: this.state.showLog != item._id ? item._id : null
                                                    })
                                                }} color="primary" className="qa-show-log-button">{'Toggle log'.translate(this.props.lang)}</Button>
                                                    <UncontrolledDropdown size="sm">
                                                        <DropdownToggle caret>

                                                        </DropdownToggle>
                                                        <DropdownMenu>
                                                            <DropdownItem onClick={() => { this.runSingle(item._id) }}>{'Run this test'.translate(this.props.lang)}</DropdownItem>
                                                            <DropdownItem onClick={() => { this.runFrom(item._id) }}>{'Run from this test'.translate(this.props.lang)}</DropdownItem>
                                                            <DropdownItem onClick={() => {
                                                                if (item.settings) {
                                                                    this.setState({
                                                                        settingsModal: item,
                                                                        settingsItem: item.settings,

                                                                        settingsTab: 1
                                                                    })
                                                                } else {
                                                                    this.setState({
                                                                        settingsModal: item,
                                                                        settingsItem: {
                                                                            testers: [
                                                                                {
                                                                                    role: '',
                                                                                    username: ''
                                                                                },
                                                                            ],
                                                                            testScript: item.number,
                                                                            testScriptName: item.name,
                                                                            numberOfSteps: item.items && item.items.length ? item.items.length : 0,
                                                                            testStart: item.startTimestamp ? `${moment.unix(item.startTimestamp).format(this.props.dateFormat)} ${moment.unix(item.startTimestamp).format('HH:mm')}` : '',
                                                                            testEnd: item.resultTimestamp ? `${moment.unix(item.resultTimestamp).format(this.props.dateFormat)} ${moment.unix(item.resultTimestamp).format('HH:mm')}` : '',
                                                                            testEndTs: item.resultTimestamp,
                                                                            passFail: item.result ? 1 : item.status == 'Done' ? 2 : -1,
                                                                            lastAttachment: item.attachments && item.attachments.length,
                                                                            numberOfAttachments: item.attachments && item.attachments.length,
                                                                            attachments: item.attachments
                                                                            // testerName: '',
                                                                            // date: '',
                                                                            // role: ''
                                                                        },

                                                                        settingsTab: 1
                                                                    })
                                                                }

                                                            }}>{'Settings'.translate(this.props.lang)}</DropdownItem>
                                                            <DropdownItem onClick={() => { this.fetchDocuments(item._id) }}>{'Documents'.translate(this.props.lang)}</DropdownItem>

                                                        </DropdownMenu>
                                                    </UncontrolledDropdown>


                                                    <button onClick={() => this.setState({ deleteModal: item })} className="delete"><Isvg src={garabage} /></button>
                                                </h6>

                                                {this.state.showLog == item._id ? <pre>
                                                    {/* DEFAULT */}
                                                    {/* {item.output} */}

                                                    {/* LINKOVI */}
                                                    <div dangerouslySetInnerHTML={{ __html: item.output2 }}></div>

                                                    {/* SLIKE */}
                                                    {/* <div dangerouslySetInnerHTML={{ __html: item.output3 }}></div> */}
                                                </pre>
                                                    :
                                                    null
                                                }
                                            </div>
                                        }
                                    })} onSortEnd={this.onSortEnd} />

                                {this.state.saveEnabled ? <div className="sort-modules-save-button">
                                    <Button onClick={this.updatePosition} color="primary">
                                        {this.state.showPlayer ? (
                                            <Player
                                                onEvent={(event) => {
                                                    if (event === "load") this.stopAnimation();
                                                }}
                                                onStateChange={this.toggleVisibility}
                                                ref={this.player} // set the ref to your class instance
                                                autoplay={true}
                                                loop={true}
                                                controls={true}
                                                src="https://assets8.lottiefiles.com/packages/lf20_ZSRKCF.json"
                                                style={{ height: "30px", width: "30px" }}
                                            ></Player>
                                        ) : this.state.showPlayer2 ? (
                                            <div style={{ display: 'flex', alignItems: 'center' }}><Player
                                                onStateChange={this.toggleVisibility}
                                                ref={this.player2} // set the ref to your class instance
                                                autoplay={true}
                                                loop={true}
                                                controls={true}
                                                src="https://assets10.lottiefiles.com/packages/lf20_CxyxLk.json"
                                                style={{ height: "30px", width: "30px" }}
                                            ></Player>
                                                {'Saved'.translate(this.props.lang)}
                                            </div>
                                        ) : (
                                            'Save'.translate(this.props.lang)
                                        )
                                        }
                                    </Button>
                                </div> : null}
                            </div>
                        </Col>
                    </Row>

                </Container>
                {
                    this.state.newTest ?
                        <Modal isOpen={this.state.newTest} centered>
                            <ModalHeader>{'Add new test'.translate(this.props.lang)}</ModalHeader>
                            <ModalBody>
                                <FormGroup>
                                    <Label>{'Name'.translate(this.props.lang)}</Label>
                                    <Input type='text' value={this.state.newTest.name} onChange={(e) => {
                                        let newTest = this.state.newTest;
                                        newTest.name = e.target.value;
                                        this.setState({ newTest })
                                    }} />
                                </FormGroup>
                            </ModalBody>
                            <ModalFooter className="buttons-right-blue">
                                <Button color='primary' onClick={() => this.saveTest(this.state.newTest)} disabled={this.state.newTest && this.state.newTest.name ? false : true}>{'Save'.translate(this.props.lang)}</Button>
                                <Button color='primary' onClick={() => this.setState({ newTest: null })}>{'Close'.translate(this.props.lang)}</Button>
                            </ModalFooter>
                        </Modal>
                        :
                        null
                }
                {
                    this.state.settingsModal ?
                        <Modal isOpen={this.state.settingsModal} centered size='xl'>
                            <ModalHeader>{'Settings'.translate(this.props.lang)}</ModalHeader>
                            <ModalBody>
                                <Row>
                                    <Col lg='12'>
                                        <div className="flex" style={{ position: 'relative', marginBottom: '15px', zIndex: "950", width: "100%", borderBottom: "1px solid #9cafbf" }}>
                                            <Button className={this.state.settingsTab == 1 ? "tab-button acitve-tab-button" : "tab-button"}
                                                onClick={() => {
                                                    if (this.state.settingsTab != 1) {
                                                        this.setState({
                                                            settingsTab: null,
                                                            settingsItem:
                                                                this.state.settingsModal && this.state.settingsModal.settings ?
                                                                    this.state.settingsModal.settings
                                                                    :
                                                                    {
                                                                        testers: [
                                                                            {
                                                                                role: '',
                                                                                username: ''
                                                                            },
                                                                        ],
                                                                        testScript: this.state.settingsModal.number,
                                                                        testScriptName: this.state.settingsModal.name,
                                                                        numberOfSteps: this.state.settingsModal.items && this.state.settingsModal.items.length ? this.state.settingsModal.items.length : 0,
                                                                        testStart: this.state.settingsModal.startTimestamp ? `${moment.unix(this.state.settingsModal.startTimestamp).format(this.props.dateFormat)} ${moment.unix(this.state.settingsModal.startTimestamp).format('HH:mm')}` : '',
                                                                        testEnd: this.state.settingsModal.resultTimestamp ? `${moment.unix(this.state.settingsModal.resultTimestamp).format(this.props.dateFormat)} ${moment.unix(this.state.settingsModal.resultTimestamp).format('HH:mm')}` : '',
                                                                        testEndTs: this.state.settingsModal.resultTimestamp,
                                                                        passFail: this.state.settingsModal.result ? 1 : this.state.settingsModal.status == 'Done' ? 2 : -1,
                                                                        lastAttachment: this.state.settingsModal.attachments && this.state.settingsModal.attachments.length,
                                                                        numberOfAttachments: this.state.settingsModal.attachments && this.state.settingsModal.attachments.length,
                                                                        attachments: this.state.settingsModal.attachments
                                                                        // testerName: '',
                                                                        // date: '',
                                                                        // role: ''
                                                                    }
                                                        }, () => this.setState({ settingsTab: 1 }))
                                                    }
                                                }}>{"Settings".translate(this.props.lang)}</Button>
                                            <Button className={this.state.settingsTab == 2 ? "tab-button acitve-tab-button" : "tab-button"}
                                                onClick={() => {
                                                    if (this.state.settingsTab != 2) {
                                                        this.setState({
                                                            settingsTab: null, deviationItem: this.state.settingsModal && this.state.settingsModal.deviation ? this.state.settingsModal.deviation : {
                                                                observationForm: {},
                                                                solutionForm: {}
                                                            }
                                                        }, () => this.setState({ settingsTab: 2 }))
                                                    }
                                                }

                                                }>{"Deviation".translate(this.props.lang)}</Button>
                                        </div>
                                    </Col>
                                </Row>
                                {
                                    this.state.settingsTab == 1 ?
                                        <Row>
                                            <Col lg='6'>
                                                <FormGroup>
                                                    <Label>{'Protocol ID'.translate(this.props.lang)}</Label>
                                                    <Input type='text' value={this.state.settingsItem.protocolId} onChange={(e) => {
                                                        let settingsItem = this.state.settingsItem;
                                                        settingsItem.protocolId = e.target.value;
                                                        this.setState({ settingsItem })
                                                    }} />
                                                </FormGroup>
                                                <FormGroup>
                                                    <Label>{'Test script'.translate(this.props.lang)}</Label>
                                                    <Input type='text' disabled value={this.state.settingsItem.testScript} onChange={(e) => {
                                                        let settingsItem = this.state.settingsItem;
                                                        settingsItem.testScript = e.target.value;
                                                        this.setState({ settingsItem })
                                                    }} />
                                                </FormGroup>
                                                <FormGroup>
                                                    <Label>{'Test script title'.translate(this.props.lang)}</Label>
                                                    <Input type='text' disabled value={this.state.settingsItem.testScriptName} onChange={(e) => {
                                                        let settingsItem = this.state.settingsItem;
                                                        settingsItem.testScriptName = e.target.value;
                                                        this.setState({ settingsItem })
                                                    }} />
                                                </FormGroup>
                                                <h5>SCRIPT INFORMATION</h5>
                                                <FormGroup>
                                                    <Label>{'Number of steps'.translate(this.props.lang)}</Label>
                                                    <Input type='text' disabled value={this.state.settingsItem.numberOfSteps} onChange={(e) => {
                                                        let settingsItem = this.state.settingsItem;
                                                        settingsItem.numberOfSteps = e.target.value;
                                                        this.setState({ settingsItem })
                                                    }} />
                                                </FormGroup>
                                                <FormGroup>
                                                    <Label>{'Scenario tested'.translate(this.props.lang)}</Label>
                                                    <Input type='text' value={this.state.settingsItem.scenarioTested} onChange={(e) => {
                                                        let settingsItem = this.state.settingsItem;
                                                        settingsItem.scenarioTested = e.target.value;
                                                        this.setState({ settingsItem })
                                                    }} />
                                                </FormGroup>
                                                <FormGroup>
                                                    <Label>{'Test objective'.translate(this.props.lang)}</Label>
                                                    <Input type='text' value={this.state.settingsItem.testObjective} onChange={(e) => {
                                                        let settingsItem = this.state.settingsItem;
                                                        settingsItem.testObjective = e.target.value;
                                                        this.setState({ settingsItem })
                                                    }} />
                                                </FormGroup>
                                                <FormGroup>
                                                    <Label>{'Requirments tested'.translate(this.props.lang)}</Label>
                                                    <Input type='text' value={this.state.settingsItem.requirmentsTested} onChange={(e) => {
                                                        let settingsItem = this.state.settingsItem;
                                                        settingsItem.requirmentsTested = e.target.value;
                                                        this.setState({ settingsItem })
                                                    }} />
                                                </FormGroup>
                                                <FormGroup>
                                                    <Label>{'Acceptance criteria'.translate(this.props.lang)}</Label>
                                                    <Input type='text' value={this.state.settingsItem.acceptanceCriteria} onChange={(e) => {
                                                        let settingsItem = this.state.settingsItem;
                                                        settingsItem.acceptanceCriteria = e.target.value;
                                                        this.setState({ settingsItem })
                                                    }} />
                                                </FormGroup>


                                                <h5>REQUIREMENTS</h5>
                                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                    <h6>TESTER</h6>
                                                    <Button color='primary' onClick={() => {
                                                        let settingsItem = this.state.settingsItem;
                                                        if (!settingsItem.testers) {
                                                            settingsItem.testers = [];
                                                        }
                                                        settingsItem.testers.push({
                                                            role: '',
                                                            username: ''
                                                        })
                                                        this.setState({ settingsItem })
                                                    }}>{'Add'.translate(this.props.lang)}</Button>
                                                </div>
                                                {
                                                    this.state.settingsItem.testers && this.state.settingsItem.testers.map((item, idx) => {
                                                        return (
                                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                                <FormGroup>
                                                                    <Label>{'Role'.translate(this.props.lang)}</Label>
                                                                    <Input type='text' value={item.role} onChange={(e) => {
                                                                        let testers = this.state.settingsItem.testers;
                                                                        testers[idx].role = e.target.value;
                                                                        this.setState({ settingsItem: { ...this.state.settingsItem, testers } })
                                                                    }} />
                                                                </FormGroup>
                                                                <FormGroup>
                                                                    <Label>{'Username'.translate(this.props.lang)}</Label>
                                                                    <Input type='text' value={item.username} onChange={(e) => {
                                                                        let testers = this.state.settingsItem.testers;
                                                                        testers[idx].username = e.target.value;
                                                                        this.setState({ settingsItem: { ...this.state.settingsItem, testers } })
                                                                    }} />
                                                                </FormGroup>
                                                                <Button color='danger' onClick={() => {
                                                                    let settingsItem = this.state.settingsItem;
                                                                    if (settingsItem.testers && settingsItem.testers.length && settingsItem.testers[idx]) {
                                                                        settingsItem.testers.splice(idx, 1)
                                                                    }
                                                                    this.setState({ settingsItem })
                                                                }}>&#10005;</Button>

                                                            </div>
                                                        )
                                                    })
                                                }
                                                <hr />
                                                <FormGroup>
                                                    <Label>{'Preparation'.translate(this.props.lang)}</Label>
                                                    <Input type='text' value={this.state.settingsItem.preparation} onChange={(e) => {
                                                        let settingsItem = this.state.settingsItem;
                                                        settingsItem.preparation = e.target.value;
                                                        this.setState({ settingsItem })
                                                    }} />
                                                </FormGroup>
                                                <FormGroup>
                                                    <Label>{'Input requirement'.translate(this.props.lang)}</Label>
                                                    <Input type='text' value={this.state.settingsItem.inputRequirement} onChange={(e) => {
                                                        let settingsItem = this.state.settingsItem;
                                                        settingsItem.inputRequirement = e.target.value;
                                                        this.setState({ settingsItem })
                                                    }} />
                                                </FormGroup>

                                            </Col>
                                            <Col lg='6'>


                                                <h6>STEP # 1.1</h6>
                                                <FormGroup>
                                                    <Label>{'Test start'.translate(this.props.lang)}</Label>
                                                    <Input type='text' disabled value={this.state.settingsItem.testStart} onChange={(e) => {
                                                        let settingsItem = this.state.settingsItem;
                                                        settingsItem.testStart = e.target.value;
                                                        this.setState({ settingsItem })
                                                    }} />
                                                </FormGroup>
                                                <FormGroup>
                                                    <Label>{'Test end'.translate(this.props.lang)}</Label>
                                                    <Input type='text' disabled value={this.state.settingsItem.testEnd} onChange={(e) => {
                                                        let settingsItem = this.state.settingsItem;
                                                        settingsItem.testEnd = e.target.value;
                                                        this.setState({ settingsItem })
                                                    }} />
                                                </FormGroup>
                                                <FormGroup>
                                                    <Label>{'Action'.translate(this.props.lang)}</Label>
                                                    <Input type='textarea' value={this.state.settingsItem.action} onChange={(e) => {
                                                        let settingsItem = this.state.settingsItem;
                                                        settingsItem.action = e.target.value;
                                                        this.setState({ settingsItem })
                                                    }} style={{ height: 100 }} />
                                                </FormGroup>
                                                <FormGroup>
                                                    <Label>{'Exptected result'.translate(this.props.lang)}</Label>
                                                    <Input type='text' value={this.state.settingsItem.exptectedResult} onChange={(e) => {
                                                        let settingsItem = this.state.settingsItem;
                                                        settingsItem.exptectedResult = e.target.value;
                                                        this.setState({ settingsItem })
                                                    }} />
                                                </FormGroup>
                                                <FormGroup>
                                                    <Label>{'Actual result'.translate(this.props.lang)}</Label>
                                                    <Input type='select' value={this.state.settingsItem.actualResult} onChange={(e) => {
                                                        let settingsItem = this.state.settingsItem;
                                                        settingsItem.actualResult = e.target.value != -1 ? e.target.value : null;
                                                        this.setState({ settingsItem })
                                                    }}>
                                                        <option value={-1}>{'Select'.translate(this.props.lang)}</option>
                                                        <option value={1}>{'AS EXPECTED'.translate(this.props.lang)}</option>
                                                        <option value={2}>{'OTHER'.translate(this.props.lang)}</option>
                                                    </Input>
                                                </FormGroup>
                                                <FormGroup>
                                                    <Label>{'PASS/FAIL'.translate(this.props.lang)}</Label>
                                                    <Input type='select' disabled value={this.state.settingsItem.passFail} onChange={(e) => {
                                                        let settingsItem = this.state.settingsItem;
                                                        settingsItem.passFail = e.target.value != -1 ? e.target.value : null;
                                                        this.setState({ settingsItem })
                                                    }}>
                                                        <option value={-1}>{'Select'.translate(this.props.lang)}</option>
                                                        <option value={1}>{'Pass'.translate(this.props.lang)}</option>
                                                        <option value={2}>{'Fail'.translate(this.props.lang)}</option>
                                                    </Input>
                                                </FormGroup>
                                                <FormGroup>
                                                    <Label>{'ATTACHMENT #'.translate(this.props.lang)}</Label>
                                                    <Input type='text' disabled value={this.state.settingsItem.lastAttachment} onChange={(e) => {
                                                        let settingsItem = this.state.settingsItem;
                                                        settingsItem.lastAttachment = e.target.value;
                                                        this.setState({ settingsItem })
                                                    }} />
                                                </FormGroup>

                                                <FormGroup>
                                                    <Label>{'Comments'.translate(this.props.lang)}</Label>
                                                    <Input type='textarea' value={this.state.settingsItem.comments} onChange={(e) => {
                                                        let settingsItem = this.state.settingsItem;
                                                        settingsItem.comments = e.target.value;
                                                        this.setState({ settingsItem })
                                                    }} style={{ height: 100 }} />
                                                </FormGroup>

                                                <h6>{'Summary'.translate(this.props.lang)}</h6>
                                                <FormGroup>
                                                    <Label>{'Test script acceptance criteria'.translate(this.props.lang)}</Label>
                                                    <Input type='select' value={this.state.settingsItem.testScriptAcceptanceCriteria} onChange={(e) => {
                                                        let settingsItem = this.state.settingsItem;
                                                        settingsItem.testScriptAcceptanceCriteria = e.target.value != -1 ? e.target.value : null;
                                                        this.setState({ settingsItem })
                                                    }}>
                                                        <option value={-1}>{'Select'.translate(this.props.lang)}</option>
                                                        <option value={1}>{'Pass'.translate(this.props.lang)}</option>
                                                        <option value={2}>{'Fail'.translate(this.props.lang)}</option>
                                                    </Input>
                                                </FormGroup>
                                                <FormGroup>
                                                    <Label>{'Number of attachments'.translate(this.props.lang)}</Label>
                                                    <Input type='text' disabled value={this.state.settingsItem.numberOfAttachments} onChange={(e) => {
                                                        let settingsItem = this.state.settingsItem;
                                                        settingsItem.numberOfAttachments = e.target.value;
                                                        this.setState({ settingsItem })
                                                    }} />
                                                </FormGroup>
                                                <FormGroup>
                                                    <Label>{'Deviation(s) number'.translate(this.props.lang)}</Label>
                                                    <Input type='text' value={this.state.settingsItem.deviationNumber} onChange={(e) => {
                                                        let settingsItem = this.state.settingsItem;
                                                        settingsItem.deviationNumber = e.target.value;
                                                        this.setState({ settingsItem })
                                                    }} />
                                                </FormGroup>

                                                <FormGroup>
                                                    <Label>{'Re-run required'.translate(this.props.lang)}</Label>
                                                    <Input type='select' value={this.state.settingsItem.reRunRequired} onChange={(e) => {
                                                        let settingsItem = this.state.settingsItem;
                                                        settingsItem.reRunRequired = e.target.value != -1 ? e.target.value : null;
                                                        this.setState({ settingsItem })
                                                    }}>
                                                        <option value={-1}>{'Select'.translate(this.props.lang)}</option>
                                                        <option value={1}>{'Yes'.translate(this.props.lang)}</option>
                                                        <option value={2}>{'NO'.translate(this.props.lang)}</option>
                                                    </Input>
                                                </FormGroup>
                                                <h5>{'Tests executed by'.translate(this.props.lang)}</h5>
                                                <FormGroup>
                                                    <DatePicker
                                                        label={'Date'.translate(this.props.lang)}
                                                        value={this.state.settingsItem.date}
                                                        onChange={(e) => {
                                                            let settingsItem = this.state.settingsItem;
                                                            settingsItem.date = e;
                                                            this.setState({ settingsItem })
                                                        }}
                                                    />
                                                </FormGroup>
                                                <FormGroup>
                                                    <Label>{'Tester name'.translate(this.props.lang)}</Label>
                                                    <Input type='text' value={this.state.settingsItem.testerName} onChange={(e) => {
                                                        let settingsItem = this.state.settingsItem;
                                                        settingsItem.testerName = e.target.value;
                                                        this.setState({ settingsItem })
                                                    }} />
                                                </FormGroup>
                                                <FormGroup>
                                                    <Label>{'Role'.translate(this.props.lang)}</Label>
                                                    <Input type='text' value={this.state.settingsItem.role} onChange={(e) => {
                                                        let settingsItem = this.state.settingsItem;
                                                        settingsItem.role = e.target.value;
                                                        this.setState({ settingsItem })
                                                    }} />
                                                </FormGroup>



                                            </Col>
                                            <Col lg='12'>
                                                <h4>{'Detail'.translate(this.props.lang)}</h4>
                                                <FormGroup>
                                                    <HTMLField
                                                        value={this.state.settingsItem.detail}
                                                        onChange={(e) => {
                                                            console.log(e)
                                                            let settingsItem = this.state.settingsItem;
                                                            settingsItem.detail = e;
                                                            this.setState({ settingsItem })
                                                        }}
                                                    ></HTMLField>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        :
                                        this.state.settingsTab == 2 ?
                                            <Row>
                                                <Col lg='6'>
                                                    <FormGroup>
                                                        <Label>{'Protocol ID'.translate(this.props.lang)}</Label>
                                                        <Input type='text' value={this.state.deviationItem.protocolId} onChange={(e) => {
                                                            let deviationItem = this.state.deviationItem;
                                                            deviationItem.protocolId = e.target.value;
                                                            this.setState({ deviationItem })
                                                        }} />
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label>{'Deviation script'.translate(this.props.lang)}</Label>
                                                        <Input type='text' value={this.state.deviationItem.deviationScript} onChange={(e) => {
                                                            let deviationItem = this.state.deviationItem;
                                                            deviationItem.deviationScript = e.target.value;
                                                            this.setState({ deviationItem })
                                                        }} />
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label>{'Deviation script title'.translate(this.props.lang)}</Label>
                                                        <Input type='text' value={this.state.deviationItem.deviationScriptTitle} onChange={(e) => {
                                                            let deviationItem = this.state.deviationItem;
                                                            deviationItem.deviationScriptTitle = e.target.value;
                                                            this.setState({ deviationItem })
                                                        }} />
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label>{'Version'.translate(this.props.lang)}</Label>
                                                        <Input type='text' value={this.state.deviationItem.deviationVersion} onChange={(e) => {
                                                            let deviationItem = this.state.deviationItem;
                                                            deviationItem.deviationVersion = e.target.value;
                                                            this.setState({ deviationItem })
                                                        }} />
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label>{'Test script'.translate(this.props.lang)}</Label>
                                                        <Input type='text' value={this.state.deviationItem.testScript} onChange={(e) => {
                                                            let deviationItem = this.state.deviationItem;
                                                            deviationItem.testScript = e.target.value;
                                                            this.setState({ deviationItem })
                                                        }} />
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label>{'Test script title'.translate(this.props.lang)}</Label>
                                                        <Input type='text' value={this.state.deviationItem.testScriptTitle} onChange={(e) => {
                                                            let deviationItem = this.state.deviationItem;
                                                            deviationItem.testScriptTitle = e.target.value;
                                                            this.setState({ deviationItem })
                                                        }} />
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label>{'Version'.translate(this.props.lang)}</Label>
                                                        <Input type='text' value={this.state.deviationItem.testVersion} onChange={(e) => {
                                                            let deviationItem = this.state.deviationItem;
                                                            deviationItem.testVersion = e.target.value;
                                                            this.setState({ deviationItem })
                                                        }} />
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label>{'Run'.translate(this.props.lang)}</Label>
                                                        <Input type='text' value={this.state.deviationItem.testRun} onChange={(e) => {
                                                            let deviationItem = this.state.deviationItem;
                                                            deviationItem.testRun = e.target.value;
                                                            this.setState({ deviationItem })
                                                        }} />
                                                    </FormGroup>
                                                    <hr />
                                                    <FormGroup>
                                                        <Label>{'Requirement'.translate(this.props.lang)}</Label>
                                                        <Input type='text' value={this.state.deviationItem.requirement} onChange={(e) => {
                                                            let deviationItem = this.state.deviationItem;
                                                            deviationItem.requirement = e.target.value;
                                                            this.setState({ deviationItem })
                                                        }} />
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label>{'Step'.translate(this.props.lang)}</Label>
                                                        <Input type='text' value={this.state.deviationItem.step} onChange={(e) => {
                                                            let deviationItem = this.state.deviationItem;
                                                            deviationItem.step = e.target.value;
                                                            this.setState({ deviationItem })
                                                        }} />
                                                    </FormGroup>
                                                    <hr />

                                                    <h6>{'DEVIATION OBSERVATION FORM'.translate(this.props.lang)}</h6>
                                                    <FormGroup>
                                                        <Label>{'Deviation (Description)'.translate(this.props.lang)}</Label>
                                                        <Input type='textarea' value={this.state.deviationItem.observationForm.deviationDesciption} onChange={(e) => {
                                                            let deviationItem = this.state.deviationItem;
                                                            deviationItem.deviationDesciption = e.target.value;
                                                            this.setState({ deviationItem })
                                                        }} style={{ height: 100 }} />
                                                    </FormGroup>

                                                    <FormGroup>
                                                        <Label>{'Prepared by'.translate(this.props.lang)}</Label>
                                                        <Input type='text' value={this.state.deviationItem.observationForm.preparedBy} onChange={(e) => {
                                                            let deviationItem = this.state.deviationItem;
                                                            deviationItem.observationForm.preparedBy = e.target.value;
                                                            deviationItem.observationForm.preparedInitials = getInitials(deviationItem.observationForm.preparedBy);
                                                            this.setState({ deviationItem })
                                                        }} />
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label>{'Initials'.translate(this.props.lang)}</Label>
                                                        <Input type='text' disabled value={this.state.deviationItem.observationForm.preparedInitials} onChange={(e) => {
                                                            let deviationItem = this.state.deviationItem;
                                                            deviationItem.observationForm.preparedInitials = e.target.value;
                                                            this.setState({ deviationItem })
                                                        }} />
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <DatePicker
                                                            label={'Date'.translate(this.props.lang)}
                                                            value={this.state.deviationItem.observationForm.preparedDate}
                                                            onChange={(e) => {
                                                                let deviationItem = this.state.deviationItem;
                                                                deviationItem.observationForm.preparedDate = e;
                                                                this.setState({ deviationItem })
                                                            }}
                                                        />
                                                    </FormGroup>

                                                    <FormGroup>
                                                        <Label>{'Deviation caused by'.translate(this.props.lang)}</Label>
                                                        <Input type='textarea' value={this.state.deviationItem.observationForm.deviationCausedBy} onChange={(e) => {
                                                            let deviationItem = this.state.deviationItem;
                                                            deviationItem.observationForm.deviationCausedBy = e.target.value;
                                                            this.setState({ deviationItem })
                                                        }} style={{ height: 100 }} />
                                                    </FormGroup>

                                                    <FormGroup>
                                                        <Label>{'Suggestion to corrective actions'.translate(this.props.lang)}</Label>
                                                        <Input type='textarea' value={this.state.deviationItem.observationForm.suggestion} onChange={(e) => {
                                                            let deviationItem = this.state.deviationItem;
                                                            deviationItem.observationForm.suggestion = e.target.value;
                                                            this.setState({ deviationItem })
                                                        }} style={{ height: 100 }} />
                                                    </FormGroup>



                                                </Col>
                                                <Col lg='6'>

                                                    <FormGroup>
                                                        <Label>{'Made by'.translate(this.props.lang)}</Label>
                                                        <Input type='text' value={this.state.deviationItem.observationForm.madeBy} onChange={(e) => {
                                                            let deviationItem = this.state.deviationItem;
                                                            deviationItem.observationForm.madeBy = e.target.value;
                                                            deviationItem.observationForm.madeInitials = getInitials(deviationItem.observationForm.madeBy);

                                                            this.setState({ deviationItem })
                                                        }} />
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label>{'Initials'.translate(this.props.lang)}</Label>
                                                        <Input type='text' disabled value={this.state.deviationItem.observationForm.madeInitials} onChange={(e) => {
                                                            let deviationItem = this.state.deviationItem;
                                                            deviationItem.observationForm.madeInitials = e.target.value;
                                                            this.setState({ deviationItem })
                                                        }} />
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <DatePicker
                                                            label={'Date'.translate(this.props.lang)}
                                                            value={this.state.deviationItem.observationForm.madeDate}
                                                            onChange={(e) => {
                                                                let deviationItem = this.state.deviationItem;
                                                                deviationItem.observationForm.madeDate = e;
                                                                this.setState({ deviationItem })
                                                            }}
                                                        />
                                                    </FormGroup>

                                                    <FormGroup>
                                                        <Label>{'Approved by'.translate(this.props.lang)}</Label>
                                                        <Input type='text' value={this.state.deviationItem.observationForm.approvedBy} onChange={(e) => {
                                                            let deviationItem = this.state.deviationItem;
                                                            deviationItem.observationForm.approvedBy = e.target.value;
                                                            deviationItem.observationForm.approvedInitials = getInitials(deviationItem.observationForm.approvedBy);

                                                            this.setState({ deviationItem })
                                                        }} />
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label>{'Initials'.translate(this.props.lang)}</Label>
                                                        <Input type='text' disabled value={this.state.deviationItem.observationForm.approvedInitials} onChange={(e) => {
                                                            let deviationItem = this.state.deviationItem;
                                                            deviationItem.observationForm.approvedInitials = e.target.value;
                                                            this.setState({ deviationItem })
                                                        }} />
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <DatePicker
                                                            label={'Date'.translate(this.props.lang)}
                                                            value={this.state.deviationItem.observationForm.approvedDate}
                                                            onChange={(e) => {
                                                                let deviationItem = this.state.deviationItem;
                                                                deviationItem.observationForm.approvedDate = e;
                                                                this.setState({ deviationItem })
                                                            }}
                                                        />
                                                    </FormGroup>
                                                    <hr />
                                                    <h6>{'DEVIATION SOLUTION FORM'.translate(this.props.lang)}</h6>
                                                    <FormGroup>
                                                        <Label>{'Implemented corrective actions'.translate(this.props.lang)}</Label>
                                                        <Input type='textarea' value={this.state.deviationItem.solutionForm.implementedActions} onChange={(e) => {
                                                            let deviationItem = this.state.deviationItem;
                                                            deviationItem.solutionForm.implementedActions = e.target.value;
                                                            this.setState({ deviationItem })
                                                        }} style={{ height: 100 }} />
                                                    </FormGroup>

                                                    <FormGroup>
                                                        <Label>{'Prepared by'.translate(this.props.lang)}</Label>
                                                        <Input type='text' value={this.state.deviationItem.solutionForm.preparedBy} onChange={(e) => {
                                                            let deviationItem = this.state.deviationItem;
                                                            deviationItem.solutionForm.preparedBy = e.target.value;
                                                            deviationItem.solutionForm.preparedInitials = getInitials(deviationItem.solutionForm.preparedBy);
                                                            this.setState({ deviationItem })
                                                        }} />
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label>{'Initials'.translate(this.props.lang)}</Label>
                                                        <Input type='text' disabled value={this.state.deviationItem.solutionForm.preparedInitials} onChange={(e) => {
                                                            let deviationItem = this.state.deviationItem;
                                                            deviationItem.solutionForm.preparedInitials = e.target.value;
                                                            this.setState({ deviationItem })
                                                        }} />
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <DatePicker
                                                            label={'Date'.translate(this.props.lang)}
                                                            value={this.state.deviationItem.solutionForm.preparedDate}
                                                            onChange={(e) => {
                                                                let deviationItem = this.state.deviationItem;
                                                                deviationItem.solutionForm.preparedDate = e;
                                                                this.setState({ deviationItem })
                                                            }}
                                                        />
                                                    </FormGroup>


                                                    <FormGroup>
                                                        <Label>{'Results of re-run'.translate(this.props.lang)}</Label>
                                                        <Input type='text' value={this.state.deviationItem.solutionForm.resultsOfReRun} onChange={(e) => {
                                                            let deviationItem = this.state.deviationItem;
                                                            deviationItem.solutionForm.resultsOfReRun = e.target.value;
                                                            this.setState({ deviationItem })
                                                        }} />
                                                    </FormGroup>

                                                    <FormGroup>
                                                        <Label>{'Made by'.translate(this.props.lang)}</Label>
                                                        <Input type='text' value={this.state.deviationItem.solutionForm.madeBy} onChange={(e) => {
                                                            let deviationItem = this.state.deviationItem;
                                                            deviationItem.solutionForm.madeBy = e.target.value;
                                                            deviationItem.solutionForm.madeInitials = getInitials(deviationItem.solutionForm.madeBy);
                                                            this.setState({ deviationItem })
                                                        }} />
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label>{'Initials'.translate(this.props.lang)}</Label>
                                                        <Input type='text' disabled value={this.state.deviationItem.solutionForm.madeInitials} onChange={(e) => {
                                                            let deviationItem = this.state.deviationItem;
                                                            deviationItem.solutionForm.madeInitials = e.target.value;
                                                            this.setState({ deviationItem })
                                                        }} />
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <DatePicker
                                                            label={'Date'.translate(this.props.lang)}
                                                            value={this.state.deviationItem.solutionForm.madeDate}
                                                            onChange={(e) => {
                                                                let deviationItem = this.state.deviationItem;
                                                                deviationItem.solutionForm.madeDate = e;
                                                                this.setState({ deviationItem })
                                                            }}
                                                        />
                                                    </FormGroup>


                                                    <FormGroup>
                                                        <Label>{'Approved by'.translate(this.props.lang)}</Label>
                                                        <Input type='text' value={this.state.deviationItem.solutionForm.approvedBy} onChange={(e) => {
                                                            let deviationItem = this.state.deviationItem;
                                                            deviationItem.solutionForm.approvedBy = e.target.value;
                                                            deviationItem.solutionForm.approvedInitials = getInitials(deviationItem.solutionForm.approvedBy);
                                                            this.setState({ deviationItem })
                                                        }} />
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label>{'Initials'.translate(this.props.lang)}</Label>
                                                        <Input type='text' disabled value={this.state.deviationItem.solutionForm.approvedInitials} onChange={(e) => {
                                                            let deviationItem = this.state.deviationItem;
                                                            deviationItem.solutionForm.approvedInitials = e.target.value;
                                                            this.setState({ deviationItem })
                                                        }} />
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <DatePicker
                                                            label={'Date'.translate(this.props.lang)}
                                                            value={this.state.deviationItem.solutionForm.approvedDate}
                                                            onChange={(e) => {
                                                                let deviationItem = this.state.deviationItem;
                                                                deviationItem.solutionForm.approvedDate = e;
                                                                this.setState({ deviationItem })
                                                            }}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col lg='12'>
                                                    <h4>{'Detail'.translate(this.props.lang)}</h4>
                                                    <FormGroup>
                                                        <HTMLField
                                                            value={this.state.deviationItem.detail}
                                                            onChange={(e) => {
                                                                console.log(e)
                                                                let deviationItem = this.state.deviationItem;
                                                                deviationItem.detail = e;
                                                                this.setState({ deviationItem })
                                                            }}
                                                        ></HTMLField>
                                                    </FormGroup>
                                                </Col>

                                            </Row>
                                            :
                                            null
                                }


                            </ModalBody>
                            <ModalFooter className="buttons-right-blue">
                                <Button color='primary' onClick={() => {
                                    this.saveSettings(this.state.settingsModal, this.state.settingsItem, this.state.deviationItem, this.state.settingsTab)

                                }}>{'Save'.translate(this.props.lang)}</Button>
                                <Button color='primary' onClick={() => this.setState({ settingsModal: null, settingsItem: null, deviationItem: null })}>{'Close'.translate(this.props.lang)}</Button>
                            </ModalFooter>
                        </Modal>
                        :
                        null
                }



                {this.state.envelopesModal ?
                    <Modal isOpen={this.state.envelopesModal} centered size='xl'>
                        <ModalHeader>{'Documents'.translate(this.props.lang)}</ModalHeader>
                        <ModalBody>

                            <Table responsive>
                                <thead>
                                    <th>{'Envelope'.translate(this.props.lang)}</th>
                                    <th>{'ID'.translate(this.props.lang)}</th>
                                    <th>{'Date'.translate(this.props.lang)}</th>
                                    <th>{'Action'.translate(this.props.lang)}</th>
                                </thead>
                                <tbody>
                                    {
                                        this.state.envelopes && this.state.envelopes.length ?
                                            this.state.envelopes.map((item, idx) => {
                                                return item.documents.map((document, didx) => {
                                                    return (
                                                        <tr key={didx}>
                                                            <td>{item.envelopeId}</td>
                                                            <td>{document.documentId}</td>
                                                            <td>{moment.unix(item.ts).format(this.props.dateFormat + ' HH:mm:ss')}</td>
                                                            <td><a href={API_ENDPOINT + `/uploads/envelopes/${item.envelopeId}-${document.docName}`} target="_blank"><Button color="primary">{'Preview'.translate(this.props.lang)}</Button> </a> </td>

                                                        </tr>

                                                    )
                                                })
                                            })
                                            :

                                            null
                                    }
                                </tbody>
                            </Table>


                        </ModalBody>
                        <ModalFooter className="buttons-right-blue">
                            <Button color='primary' onClick={() => this.setState({ envelopesModal: null, envelopes: [] })}>{'Close'.translate(this.props.lang)}</Button>
                        </ModalFooter>
                    </Modal>
                    :
                    null
                }



                <DeleteModal
                    lang={this.props.lang}
                    isOpen={this.state.deleteModal}
                    toggle={() => this.setState({ deleteModal: null })}
                    handler={() => { this.delete(this.state.deleteModal._id); this.setState({ deleteModal: null }) }}
                >
                    {'Delete test'.translate(this.props.lang)} <strong>{this.state.deleteModal ? this.state.deleteModal.name : null}</strong>?
                </DeleteModal>

            </div>
        );
    }
}

export default Page(QAModule)