export const generateSections = (itemsArr) => {
    let sections = {};
    let items = [{ type: 0 }, ...itemsArr];
    let lastSectionId = -1;
    for (let i = 0; i < items.length; i++) {
        if (items[i].type == 0) {
            lastSectionId++;
            sections[lastSectionId] = {
                name: items[i].name,
                displayOnReferral: false,
                questions: [],
                conditions: items[i].conditions ? items[i].conditions : [],
                displayOnReferral: items[i].displayOnReferral,
                fontStyle: items[i].fontStyle,
                fontSize: items[i].fontSize,
                fontWeight: items[i].fontWeight,
                required: items[i].required,

            }
        }
        if (lastSectionId != -1 && items[i].type != 0) {


            let wikiLinks = [];

            items[i].wikiLinks = wikiLinks;


            if (items[i].type == 1) {
                let displayOnReferral = false;
                if (items[i].answers) {
                    for (let j = 0; j < items[i].answers.length; j++) {
                        if (items[i].answers[j].text) {
                            displayOnReferral = true;
                        }
                    }
                }

                sections[lastSectionId].questions.push({
                    id: items[i].id,
                    type: items[i].type,
                    name: items[i].name,
                    answers: items[i].answers,
                    conditions: items[i].conditions ? items[i].conditions : [],
                    displayOnReferral: displayOnReferral,
                    wikiLinks: items[i].wikiLinks,
                    fontStyle: items[i].fontStyle,
                    fontSize: items[i].fontSize,
                    fontWeight: items[i].fontWeight,
                    required: items[i].required,
                });

            } else if (items[i].type == 2) {
                let displayOnReferral = false;
                if (items[i].answers) {
                    for (let j = 0; j < items[i].answers.length; j++) {

                        if (items[i].answers[j].text) {
                            displayOnReferral = true;
                        }
                    }
                }
                sections[lastSectionId].questions.push({
                    id: items[i].id,
                    type: items[i].type,
                    name: items[i].name,
                    answers: items[i].answers,
                    conditions: items[i].conditions ? items[i].conditions : [],
                    answerPrefix: items[i].answerPrefix,
                    userInput: items[i].userInput,
                    displayOnReferral: displayOnReferral || items[i].answerPrefix ? true : false,
                    wikiLinks: items[i].wikiLinks,
                    fontStyle: items[i].fontStyle,
                    fontSize: items[i].fontSize,
                    fontWeight: items[i].fontWeight,
                    required: items[i].required,

                })
            } else if (items[i].type == 3) {
                sections[lastSectionId].questions.push({
                    id: items[i].id,
                    type: items[i].type,
                    name: items[i].name,
                    conditions: items[i].conditions ? items[i].conditions : [],
                    answerPrefix: items[i].answerPrefix,
                    wikiLinks: items[i].wikiLinks,
                    displayOnReferral: true,
                    fontStyle: items[i].fontStyle,
                    fontSize: items[i].fontSize,
                    fontWeight: items[i].fontWeight,
                    required: items[i].required,
                })

            } else if (items[i].type == 4) {
                sections[lastSectionId].questions.push({
                    id: items[i].id,
                    type: items[i].type,
                    name: items[i].name,
                    text: items[i].text,
                    userInput: items[i].userInput,
                    conditions: items[i].conditions ? items[i].conditions : [],
                    wikiLinks: items[i].wikiLinks,
                    fontStyle: items[i].fontStyle,
                    fontSize: items[i].fontSize,
                    fontWeight: items[i].fontWeight,
                    required: items[i].required,
                })
            } else if (items[i].type == 15) {
                sections[lastSectionId].questions.push({
                    id: items[i].id,
                    type: items[i].type,
                    name: items[i].name,
                    text: items[i].text,
                    userInput: items[i].userInput,
                    conditions: items[i].conditions ? items[i].conditions : [],
                    wikiLinks: items[i].wikiLinks,
                    fontStyle: items[i].fontStyle,
                    fontSize: items[i].fontSize,
                    fontWeight: items[i].fontWeight,
                    required: items[i].required,
                })

            } else if (items[i].type == 5) {
                sections[lastSectionId].questions.push({
                    id: items[i].id,
                    type: items[i].type,
                    name: items[i].name,
                    conditions: items[i].conditions ? items[i].conditions : [],
                    wikiLinks: items[i].wikiLinks,
                    displayOnReferral: true,
                    fontStyle: items[i].fontStyle,
                    fontSize: items[i].fontSize,
                    fontWeight: items[i].fontWeight,
                    required: items[i].required,
                    score: items[i].score
                })

            } else if (items[i].type == 6) {
                sections[lastSectionId].questions.push({
                    id: items[i].id,
                    type: items[i].type,
                    name: items[i].name,
                    conditions: items[i].conditions ? items[i].conditions : [],
                    wikiLinks: items[i].wikiLinks,
                    displayOnReferral: true,
                    fontStyle: items[i].fontStyle,
                    fontSize: items[i].fontSize,
                    fontWeight: items[i].fontWeight,
                    required: items[i].required,
                    score: items[i].score
                })

            } else if (items[i].type == 7) {
                sections[lastSectionId].questions.push({
                    id: items[i].id,
                    type: items[i].type,
                    name: items[i].name,
                    conditions: items[i].conditions ? items[i].conditions : [],
                    wikiLinks: items[i].wikiLinks,
                    displayOnReferral: true,
                    fontStyle: items[i].fontStyle,
                    fontSize: items[i].fontSize,
                    fontWeight: items[i].fontWeight,
                    required: items[i].required,
                })

            } else if (items[i].type == 8) {
                sections[lastSectionId].questions.push({
                    id: items[i].id,
                    type: items[i].type,
                    name: items[i].name,
                    conditions: items[i].conditions ? items[i].conditions : [],
                    wikiLinks: items[i].wikiLinks,
                    displayOnReferral: true,
                    fontStyle: items[i].fontStyle,
                    fontSize: items[i].fontSize,
                    fontWeight: items[i].fontWeight,
                    required: items[i].required,
                })

            } else if (items[i].type == 9) {
                sections[lastSectionId].questions.push({
                    id: items[i].id,
                    type: items[i].type,
                    name: items[i].name,
                    conditions: items[i].conditions ? items[i].conditions : [],
                    wikiLinks: items[i].wikiLinks,
                    displayOnReferral: true,
                    fontStyle: items[i].fontStyle,
                    fontSize: items[i].fontSize,
                    fontWeight: items[i].fontWeight,
                    required: items[i].required,
                })

            } else if (items[i].type == 10) {
                sections[lastSectionId].questions.push({
                    id: items[i].id,
                    type: items[i].type,
                    name: items[i].name,
                    userInput: items[i].userInput,
                    conditions: items[i].conditions ? items[i].conditions : [],
                    wikiLinks: items[i].wikiLinks,
                    displayOnReferral: true,
                    fontStyle: items[i].fontStyle,
                    fontSize: items[i].fontSize,
                    fontWeight: items[i].fontWeight,
                    required: items[i].required,
                })
            } else if (items[i].type == 12) {
                sections[lastSectionId].questions.push({
                    id: items[i].id,
                    type: items[i].type,
                    name: items[i].name,
                    conditions: items[i].conditions ? items[i].conditions : [],
                    wikiLinks: items[i].wikiLinks,
                    displayOnReferral: true,
                    fontStyle: items[i].fontStyle,
                    fontSize: items[i].fontSize,
                    fontWeight: items[i].fontWeight,
                    required: items[i].required,
                    score: items[i].score
                })
            } else if (items[i].type == 14) {
                sections[lastSectionId].questions.push({
                    id: items[i].id,
                    type: items[i].type,
                    name: items[i].name,
                    conditions: items[i].conditions ? items[i].conditions : [],
                    wikiLinks: items[i].wikiLinks,
                    displayOnReferral: true,
                    fontStyle: items[i].fontStyle,
                    fontSize: items[i].fontSize,
                    fontWeight: items[i].fontWeight,
                    required: items[i].required,
                    score: items[i].score
                })

            } else if (items[i].type == 13) {
                sections[lastSectionId].questions.push({
                    id: items[i].id,
                    type: items[i].type,
                    name: items[i].name,
                    conditions: items[i].conditions ? items[i].conditions : [],
                    wikiLinks: items[i].wikiLinks,
                    displayOnReferral: true,
                    fontStyle: items[i].fontStyle,
                    fontSize: items[i].fontSize,
                    fontWeight: items[i].fontWeight,
                    required: items[i].required,
                    score: items[i].score
                })

            } else if (items[i].type == 16) {
                sections[lastSectionId].questions.push({
                    id: items[i].id,
                    type: items[i].type,
                    name: items[i].name,
                    conditions: items[i].conditions ? items[i].conditions : [],
                    wikiLinks: items[i].wikiLinks,
                    displayOnReferral: true,
                    fontStyle: items[i].fontStyle,
                    fontSize: items[i].fontSize,
                    fontWeight: items[i].fontWeight,
                    required: items[i].required,
                    blocks: items[i].blocks,
                })

            } else if (items[i].type == 17) {
                sections[lastSectionId].questions.push({
                    id: items[i].id,
                    type: items[i].type,
                    name: items[i].name,
                    conditions: items[i].conditions ? items[i].conditions : [],
                    wikiLinks: items[i].wikiLinks,
                    displayOnReferral: true,
                    fontStyle: items[i].fontStyle,
                    fontSize: items[i].fontSize,
                    fontWeight: items[i].fontWeight,
                    required: items[i].required,
                    blocks: items[i].blocks,

                })

            } else if (items[i].type == 18) {
                sections[lastSectionId].questions.push({
                    id: items[i].id,
                    type: items[i].type,
                    name: items[i].name,
                    conditions: items[i].conditions ? items[i].conditions : [],
                    wikiLinks: items[i].wikiLinks,
                    displayOnReferral: true,
                    fontStyle: items[i].fontStyle,
                    fontSize: items[i].fontSize,
                    fontWeight: items[i].fontWeight,
                    required: items[i].required,
                    answers: items[i].answers,

                })

            } else if (items[i].type == 19) {
                sections[lastSectionId].questions.push({
                    id: items[i].id,
                    type: items[i].type,
                    name: items[i].name,
                    conditions: items[i].conditions ? items[i].conditions : [],
                    answerPrefix: items[i].answerPrefix,
                    wikiLinks: items[i].wikiLinks,
                    displayOnReferral: true,
                    fontStyle: items[i].fontStyle,
                    fontSize: items[i].fontSize,
                    fontWeight: items[i].fontWeight,
                    required: items[i].required,
                    graphType: items[i].graphType
                })

            }
        }
    }

    return Object.values(sections);

}