import React, { Component } from 'react';
import Page from '../../containers/page';

import {
    Container,
    Row,
    Col,
    Button,
    UncontrolledAlert,
    FormGroup,
    Label
} from 'reactstrap';

import Isvg from 'react-inlinesvg'

import { formValueSelector, change } from 'redux-form';  // ES6
import { connect } from 'react-redux';

import FormBuilder from '../../components/forms/modalFormBuilder';
import { required } from '../../components/forms/validation';

import ListBuilder from '../../components/listBuilder';
import DeleteModal from '../../components/deleteModal';
import { getSearchParams, generateSearchLink, generateSearchLinkMultiple } from '../../helpers/linkHelper';
import { API_ENDPOINT } from '../../constants';
import { routes } from '../../routesList';


import profileUser from '../../assets/images/user.png';
import notification from '../../assets/svg/notification.svg'
import moreIcon from '../../assets/svg/more.svg'

import Search from '../../components/search';

/**
* Categories tree page
* @author   Milan Stanojevic
*/
class CategoriesTree extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props.initialData,
            form: false,
            useState: false,
            params: {
                entries: 10,
                page: 0
            },
            items: [],
            endpoints: [],
            formModules: [],
            total: 0,
            loading: true,
            selectedCategories: {},
            selectedSubcategories: {},
            subgroups: [],
            categories: []
        };
    }


    get = () => {
        this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));

        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0 })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

        for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
            this.props.loadDataWithQuery[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0 })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

    }

    componentDidMount() {
        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }

        this.get();
    }

    componentDidUpdate(prevProps) {
        if (prevProps[0].location.search != this.props[0].location.search) {
            this.setState({
                loading: true
            }, () => {
                this.get();

            })
        }
    }



    /**
    * Function to update category
    * @author   Milan Stanojevic
    * @Objectparam    {Object} data     form data
    * @Objectparam    {String} id       category id
    */
    updateCategory = (data) => {
        this.setState({
            loading: true
        }, () => {

            fetch(API_ENDPOINT + '/data/categories/' + this.state.categoryForm._id, {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify(data)
            }).then(res => res.json()).then((result) => {
                this.get();
                this.setState({
                    categoryForm: null
                })
            })

        })
    }

    /**
    * Function to update subcateogry
    * @author   Milan Stanojevic
    * @Objectparam    {Object} data     form data
    * @Objectparam    {String} id       subcategory id
    */
    updateSubcategory = (data) => {
        this.setState({
            loading: true
        }, () => {

            fetch(API_ENDPOINT + '/data/subcategories/' + this.state.subCategoryForm._id, {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify(data)
            }).then(res => res.json()).then((result) => {
                this.get();
                this.setState({
                    subCategoryForm: null
                })
            })

        })
    }



    render() {
        let params = {};
        if (this.state.useState) {
            params = this.state.params;
        } else {
            params = getSearchParams(this.props[0].location.search, { entries: 10, page: 0 })
        }


        let _categories = {};
        for (let i = 0; i < this.state.categories.length; i++) {
            _categories[this.state.categories[i]._id] = this.state.categories[i].subgroups;
        }
        let __categories = {};
        for (let i = 0; i < this.state.categories.length; i++) {
            __categories[this.state.categories[i]._id] = this.state.categories[i];
        }



        let subgroups = [];
        for (let i = 0; i < this.state.subgroups.length; i++) {

            if (this.state.subCategoryForm) {
                if (_categories[this.state.subCategoryForm.category] && _categories[this.state.subCategoryForm.category].indexOf(this.state.subgroups[i]._id) !== -1) {
                    continue;
                } else {
                    subgroups.push(this.state.subgroups[i]);

                }

            } else {
                subgroups.push(this.state.subgroups[i]);
            }
        }


        return (
            <div className={this.props.autoHideSidebar ? "dashboard dashboard-auto-hide" : "dashboard"}>

                <Container fluid>
                    <Row>
                        <Col lg="12">
                            {/* {this.state.message ?
                                <UncontrolledAlert color="success">
                                    {this.state.message.translate(this.props.lang)}
                                </UncontrolledAlert>
                                :
                                null
                            } */}


                            <div className="panel">
                                <h4>{this.props.linksMeta && this.props.linksMeta[this.props[0].location.pathname] ? this.props.linksMeta[this.props[0].location.pathname][this.props.lang] : ''}</h4>
                                <ul className="categories-tree">
                                    {
                                        this.state.items.map((category, cidx) => {
                                            return (
                                                <li className={this.state.selectedCategories && this.state.selectedCategories[category._id] ? 'active' : ''}>
                                                    <div className="category-name" onClick={(e) => {
                                                        if (e && e.target && e.target.tagName == 'BUTTON') {
                                                            return;
                                                        }

                                                        let selectedCategories = this.state.selectedCategories;
                                                        selectedCategories[category._id] = !selectedCategories[category._id];
                                                        this.setState({
                                                            selectedCategories
                                                        })
                                                    }} >
                                                        {category.name}
                                                        <div className="subgroups">
                                                            <div className="subgroups-content">
                                                                <strong>{'Subgroups'.translate(this.props.lang)}:</strong>
                                                                {
                                                                    category._subgroups.map(sgroup => sgroup.name).join(', ')

                                                                }
                                                            </div>
                                                            <Button size="sm" color="primary" onClick={(e) => {
                                                                this.setState({
                                                                    categoryForm: category
                                                                })

                                                            }}>{'Edit'.translate(this.props.lang)}</Button>
                                                        </div>

                                                    </div>
                                                    <ul>
                                                        {
                                                            category.subcategories.map((subCategory, sidx) => {
                                                                return (
                                                                    <li className={this.state.selectedSubcategories && this.state.selectedSubcategories[category._id + '-' + subCategory._id] ? 'active' : ''}>
                                                                        <div className="subcategory-name" onClick={(e) => {
                                                                            // console.log(e.target.tagName)
                                                                            if (e && e.target && e.target.tagName == 'BUTTON') {
                                                                                return;
                                                                            }

                                                                            let selectedSubcategories = this.state.selectedSubcategories;
                                                                            selectedSubcategories[category._id + '-' + subCategory._id] = !selectedSubcategories[category._id + '-' + subCategory._id];
                                                                            this.setState({
                                                                                selectedSubcategories
                                                                            })
                                                                        }} >
                                                                            {subCategory.name}
                                                                            <div className="subgroups">
                                                                                <div className="subgroup-content">
                                                                                    <strong>{'Subgroups'.translate(this.props.lang)}:</strong>
                                                                                    {
                                                                                        subCategory._subgroups.map(sgroup => sgroup.name).join(', ')

                                                                                    }
                                                                                </div>
                                                                                <Button size="sm" color="primary"
                                                                                    onClick={(e) => {
                                                                                        this.setState({
                                                                                            subCategoryForm: subCategory
                                                                                        })

                                                                                    }}
                                                                                >{'Edit'.translate(this.props.lang)}</Button>

                                                                            </div>


                                                                        </div>

                                                                        <ul>
                                                                            {
                                                                                subCategory.tags.map((tag, tidx) => {
                                                                                    return (
                                                                                        <li>
                                                                                            {tag.name}
                                                                                        </li>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </ul>
                                                                    </li>
                                                                )
                                                            })
                                                        }
                                                    </ul>

                                                </li>
                                            )
                                        })
                                    }
                                </ul>


                            </div>
                        </Col>
                    </Row>

                </Container>

                {this.state.categoryForm ?
                    <FormBuilder selectedGroup={this.props.selectedGroup} selectedClinic={this.props.selectedClinic} countryTwoLetter={this.props.countryTwoLetter} country={this.props.country} dateFormat={this.props.dateFormat}
                        getStringDateTs={this.props.getStringDateTs}
                        lang={this.props.lang}
                        onSubmit={(data) => this.updateCategory(data)}
                        initialValues={this.state.categoryForm}
                        isOpen={this.state.categoryForm}
                        size={'xl'}
                        toggle={() => this.setState({ categoryForm: null })}
                        title={'Edit category subgrpups'.translate(this.props.lang)}
                        buttonText={'Save'.translate(this.props.lang).translate(this.props.lang)}
                        beforeComponent={
                            <FormGroup className="parent-element-edit-group">
                                {'Category:'.translate(this.props.lang)} <strong>{this.state.categoryForm.name}</strong>
                            </FormGroup>

                        }

                        fields={[
                            {
                                type: 'row',
                                children: [

                                    {
                                        type: 'col',
                                        width: {
                                            lg: 12,
                                            sm: 12,
                                            xs: 12
                                        },
                                        children: [
                                            {
                                                type: 'easy-select',
                                                name: 'subgroups',
                                                lang: this.props.lang,
                                                label: 'Subgroups (Select the first letter to filter results)'.translate(this.props.lang),
                                                multiple: true,
                                                values: this.state.subgroups.map((item, idx) => { return { value: item._id, name: item.name } })
                                            },
                                        ]
                                    },

                                ]
                            }
                        ]}
                    ></FormBuilder>
                    :
                    null
                }

                {this.state.subCategoryForm ?
                    <FormBuilder selectedGroup={this.props.selectedGroup} selectedClinic={this.props.selectedClinic} countryTwoLetter={this.props.countryTwoLetter} country={this.props.country} dateFormat={this.props.dateFormat}
                        getStringDateTs={this.props.getStringDateTs}
                        lang={this.props.lang}
                        onSubmit={(data) => this.updateSubcategory(data)}
                        initialValues={this.state.subCategoryForm}
                        isOpen={this.state.subCategoryForm}
                        toggle={() => this.setState({ subCategoryForm: null })}
                        size={'xl'}
                        title={'Edit subcategory subgroups'.translate(this.props.lang)}
                        buttonText={'Save'.translate(this.props.lang).translate(this.props.lang)}
                        beforeComponent={
                            __categories[this.state.subCategoryForm.category] ?
                                <>
                                    <FormGroup className="parent-element-edit-group">
                                        {'Category:'.translate(this.props.lang)} <strong>{__categories[this.state.subCategoryForm.category] ? __categories[this.state.subCategoryForm.category].name : null}</strong>
                                    </FormGroup>
                                    <FormGroup className="parent-element-edit-group">
                                        {'Subcategory:'.translate(this.props.lang)} <strong>{this.state.subCategoryForm.name}</strong>
                                    </FormGroup>

                                </>
                                :
                                null
                        }
                        fields={[
                            {
                                type: 'row',
                                children: [
                                    {
                                        type: 'col',
                                        width: {
                                            lg: 12,
                                            sm: 12,
                                            xs: 12
                                        },
                                        children: [
                                            {
                                                type: 'easy-select',
                                                lang: this.props.lang,
                                                name: 'subgroups',
                                                label: 'Subgroups'.translate(this.props.lang),
                                                validate: [required('Subgroup is required to select!'.translate(this.props.lang))],
                                                values: this.state.subgroups.map((item, idx) => { return { value: item._id, name: item.name, disabled: _categories[this.state.subCategoryForm.category].indexOf(item._id) !== -1 } })
                                            },
                                        ]
                                    },

                                ]
                            }
                        ]}
                    ></FormBuilder>
                    :
                    null
                }



            </div>
        );
    }
}

export default Page(CategoriesTree)