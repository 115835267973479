import React, { Component } from 'react';
import Page from '../containers/page';
// import BankIdModal from '../components/bankIdModal';
import { API_ENDPOINT } from '../constants';
import ErrorModal from './errorModal';

class VideoCallStarted extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...props.initialData,
            check: true

        };

    }


    componentDidMount() {

    }
    startVideoChat = (data) => {
        if (data) {
            if (data.link.indexOf('initiator') !== -1) {
                data.link = '/' + data.link
            }
            this.props[0].history.push(data.link)
        } else {
            this.props[0].history.push('/')
        }
    }
    componentDidUpdate() {
        if (this.state.check) {
            if (this.props[0].location) {
                fetch(API_ENDPOINT + '/data/video-call-started/' + this.props[0].match.params.id, {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json'
                    },
                }).then(res => res.json()).then((result) => {
                    this.startVideoChat(result)
                    // if (result?.bundleId) {
                    //     let bundleId = result.bundleId;
                    //     console.log('bundleId', bundleId)
                    //     try {
                    //         if (bundleId && typeof window !== 'undefined') {
                    //             // let url = `clinic-app://${bundleId}`
                    //             // this.appIframe.src = bundleId + '://';
                    //             let url = `${bundleId}://`;

                    //             var linker = new DeepLinker({
                    //               onIgnored: function() {
                    //                 console.log('browser failed to respond to the deep link');
                    //                   this.startVideoChat(result)
                    //               },
                    //               onFallback: function() {
                    //                 console.log('dialog hidden or user returned to tab');
                    //                   this.startVideoChat(result)
                    //               },
                    //               onReturn: function() {
                    //                 console.log('user returned to the page from the native app');
                    //                   this.startVideoChat(result)
                    //               },
                    //             });
                                
                    //             linker.openURL(url);

                                
                    //         }
                    //     } catch (error) {
                    //         this.startVideoChat(result)
                    //     }
                    // } else {
                    //     this.startVideoChat(result)
                    // }




                })

                this.setState({
                    check: false
                })
            }
        }

    }

    render() {


        return (
            <div>
                <iframe ref={(node) => this.appIframe = node} onLoad={() => {
                    console.log('loaded')
                }} width="1" height="1" style={{ visibility: "hidden" }}></iframe>
            </div >
        );
    }
}



export default Page(VideoCallStarted);
