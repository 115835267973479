import React, { Component } from 'react';
import Page from '../../containers/page';

import {
    Button,
    Col,
    Container,
    FormGroup,
    Input,
    Label,
    Modal, ModalBody, ModalFooter, ModalHeader,
    Row
} from 'reactstrap';

import Isvg from 'react-inlinesvg';
import { Player } from '@lottiefiles/react-lottie-player';
import moment from 'moment';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form'; // ES6
import garabage from '../../assets/svg/garbage.svg';
import xIcon from "../../assets/svg/x.svg";
import DeleteModal from '../../components/deleteModal';
import ErrorModal from '../../components/errorModal';
import DatePicker from '../../components/forms/fields/datePicker';
import ListCheckBox from '../../components/forms/fields/easySelect';
import { API_ENDPOINT } from '../../constants';
import { generateSearchLink, generateSearchLinkMultiple, getSearchParams } from '../../helpers/linkHelper';
import InfoModal from '../../components/infoModal';


function parseJSON(response) {
    return response.json().then(json => {
        return {
            result: json,
            status: response.status
        }
    })
}

/**
* Group statistics page
* @author   Milan Stanojevic
*/
class GroupStatistics extends Component {
    constructor(props) {
        super(props);
        let currDate = new Date();
        let date1 = new Date(currDate.getFullYear(), 0, 1);
        let date2 = new Date();
        this.state = {
            ...props.initialData,
            form: false,
            useState: false,
            params: {
                entries: 10,
                page: 0,
                'query-group': null
            },
            items: [],
            total: 0,
            loading: true,
            subgroups: [],
            categories: [],
            formModules: [],
            years: [],
            files: [],
            professions: [],
            openCalendar: true,
            reportData: {},
            tabIndex: 1,
            chatRatings: [],
            dateFrom: moment.unix(Math.floor(date1.getTime() / 1000)).format('YYYY-MM-DD'),
            dateTo: moment.unix(Math.floor(date2.getTime() / 1000)).format('YYYY-MM-DD'),
            loading: false
        };

    }


    get = () => {
        this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));

        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0, 'query-group': null, group: this.props.selectedGroup })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

        for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
            this.props.loadDataWithQuery[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, {
                entries: 10, page: 0, 'query-group': null,
                group: this.props.selectedGroup,
                year: this.state.year ? this.state.year : new Date().getFullYear(),
                january: this.state.january,
                february: this.state.february,
                march: this.state.march,
                april: this.state.april,
                may: this.state.may,
                june: this.state.june,
                july: this.state.july,
                august: this.state.august,
                september: this.state.september,
                october: this.state.october,
                november: this.state.november,
                december: this.state.december
            })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

    }

    componentDidMount() {
        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }

        this.get();

        let nowYear = new Date().getFullYear()
        while (Number(nowYear) >= 2020) {
            let years = this.state.years;
            years.push(nowYear)
            this.setState({
                years
            })
            nowYear -= 1
        }


    }

    componentDidUpdate(prevProps) {
        if (prevProps[0].location.search != this.props[0].location.search) {
            this.setState({
                loading: true
            }, () => {
                this.get();

            })
        }
    }


    updateSort = (field, type) => {
        if (this.state.useState) {
            let state = this.state.params;
            state.sortField = field;
            state.sortType = type;
            this.setState({
                params: state
            })
        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0 }, [{ name: 'sortField', value: field }, { name: 'sortType', value: type }], false));

        }
    }

    updateParams = (name, value, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };
                obj[name] = value;
                this.setState({ params: obj });
            } else {
                let obj = this.state.params;
                obj[name] = value;
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(generateSearchLink(this.props[0].location.search, { entries: 10, page: 0 }, name, value, restart));
        }
    }
    updateMultipleParams = (fields, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj });

            } else {
                let obj = this.state.params;
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0 }, fields, restart));
        }
    }

    export() {


        fetch(API_ENDPOINT + "/data/group-statistics/export", {
            method: "POST",
            headers: {
                Authorization:
                    typeof localStorage !== "undefined"
                        ? `Bearer ${localStorage.getItem("authToken")}`
                        : null,
                "content-type": "application/json",
            },
            body: JSON.stringify({ group: this.props.selectedGroup }),
        })
            .then((res) => res.json())
            .then((result) => {

                if (result.success) {
                    window.location.href = API_ENDPOINT + `${result.file}`;
                }
                this.setState({
                    success: result.success,
                    file: result.file
                })
            });
    }
    createReport = (data) => {
        this.setState({
            exportStatistic: true,
            // _loading: true
        })
        if (!data.dateFrom) {
            data.dateFrom = moment(new Date()).format(this.props.dateFormat)
        }
        if (!data.dateTo) {
            data.dateTo = moment(new Date()).format(this.props.dateFormat)
        }
        data.group = this.props.selectedGroup

        fetch(API_ENDPOINT + "/data/all/statistics", {
            method: "POST",
            headers: {
                Authorization:
                    typeof localStorage !== "undefined"
                        ? `Bearer ${localStorage.getItem("authToken")}`
                        : null,
                "content-type": "application/json",
            },
            body: JSON.stringify(data),
        })
            .then((res) => res.json())
            .then((result) => {
                if (result) {
                    this.setState({
                        reportData: {},
                        createReportModal: null,
                        errorReporrtName: null,
                        // _loading: false
                    }, () => this.get())
                }
            });
    }

    deleteFile = (data) => {
        fetch(API_ENDPOINT + "/data/group-statistics/files/delete/" + data, {
            method: "DELETE",
            headers: {
                Authorization:
                    typeof localStorage !== "undefined"
                        ? `Bearer ${localStorage.getItem("authToken")}`
                        : null,
                "content-type": "application/json",
            },
            body: JSON.stringify({}),
        })
            .then((res) => res.json())
            .then((result) => {

                if (result) {
                    this.setState({
                        reportData: {},
                        createReportModal: null
                    }, () => this.get())
                }
            });
    }

    chatRatings = (dateFrom, dateTo) => {
        console.log(dateFrom, dateTo, this.props.selectedGroup)
        let data = {
            dateFrom: dateFrom,
            dateTo: dateTo,
            selectedGroup: this.props.selectedGroup
        }

        fetch(API_ENDPOINT + '/data/conversationRatings', {
            method: 'POST',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(parseJSON).then(({ result, status }) => {
            if (status >= 200 && status < 300)
                this.setState({
                    chatRatings: result
                }, () => {
                    this.answer1()
                })
        })

    }

    answer1 = () => {
        let average1 = 0;
        let average2 = 0;
        let average3 = 0;
        let average = 0;
        let count = 0;
        for (let i = 0; i < this.state.chatRatings.length; i++) {
            average1 += this.state.chatRatings[i].rating1
            average2 += this.state.chatRatings[i].rating2
            average3 += this.state.chatRatings[i].rating3
            average += this.state.chatRatings[i].ratingAverage
            count++
        }
        this.setState({
            answer1: average1 > 0 ? (average1 / count).toFixed(2) : null,
            answer2: average2 > 0 ? (average2 / count).toFixed(2) : null,
            answer3: average3 > 0 ? (average3 / count).toFixed(2) : null,
            answer: average > 0 ? (average / count).toFixed(2) : null

        })

        // return Math.round((average / count) / 100) * 100
    }

    render() {
        let params = {};
        if (this.state.useState) {
            params = this.state.params;
        } else {
            params = getSearchParams(this.props[0].location.search, { entries: 10, page: 0 })
        }

        return (
            <div className={this.props.autoHideSidebar ? "dashboard dashboard-auto-hide" : "dashboard"}>

                <Container fluid>
                    <Row>
                        <Col lg="12">
                            <div className="panel group-statistics-panel">
                                <Col lg="12" className='col-no-padding'>
                                    <div className="tabs-buttons-wrap" style={{ marginBottom: 35 }}>
                                        <Button className={this.state.tabIndex == 1 ? "tab-button acitve-tab-button" : "tab-button"} onClick={() => this.setState({ tabIndex: 1 })}>{"Group statistics".translate(this.props.lang)}</Button>
                                        <Button className={this.state.tabIndex == 2 ? "tab-button acitve-tab-button" : "tab-button"} onClick={() => this.setState({ tabIndex: 2 })}>{"Chat ratings".translate(this.props.lang)}</Button>
                                    </div>
                                </Col>
                                {
                                    this.state.tabIndex == 1 ?
                                        <div>
                                            <div className="panel-header" style={{ marginBottom: 0 }}>
                                                <h5 className="component-header">{'Group statistics'.translate(this.props.lang)} </h5>
                                                {/* <button className="btn add-button" onClick={() => this.export()}><Isvg src={exportIcon} /> {'Export'.translate(this.props.lang)}</button> */}
                                                <button className="btn add-button" onClick={() => this.setState({ createReportModal: true })}> {"Create new report".translate(this.props.lang)}</button>
                                            </div>

                                            <div className="group-statistics-container">
                                                <div>
                                                    <Label>{'Year'.translate(this.props.lang)}</Label>
                                                    <Input type='select' value={this.state.year} onChange={(e) => {
                                                        let val = e.target.value

                                                        this.setState({
                                                            year: val,
                                                            january: false,
                                                            february: false,
                                                            march: false,
                                                            april: false,
                                                            may: false,
                                                            june: false,
                                                            july: false,
                                                            august: false,
                                                            september: false,
                                                            october: false,
                                                            november: false,
                                                            december: false
                                                        }, () => this.get())
                                                    }}>
                                                        {this.state.years.map(item => {
                                                            return (
                                                                <option value={item}>
                                                                    {item}
                                                                </option>
                                                            )
                                                        })}
                                                    </Input>
                                                </div>

                                                <div >


                                                    <div className="profession-buttons group-statistic-buttons mr-0">
                                                        <div>
                                                            <div >
                                                                <button className={this.state.january ? 'btn clicked' : 'btn'} onClick={() => {
                                                                    this.setState({
                                                                        january: !this.state.january,
                                                                        // notBooked: false,
                                                                        // rejectedByPatient: false,
                                                                        // closed: false
                                                                    }, () => this.get())
                                                                }} >{'JAN'.translate(this.props.lang)}</button>
                                                            </div>
                                                        </div>

                                                        <div>
                                                            <div >

                                                                <button className={this.state.february ? 'btn clicked' : 'btn'} onClick={() => {
                                                                    this.setState({
                                                                        february: !this.state.february,
                                                                        // scheduled: false,
                                                                        // rejectedByPatient: false,
                                                                        // closed: false
                                                                    }, () => this.get())
                                                                }} >{'FEB'.translate(this.props.lang)}</button>
                                                            </div>
                                                        </div>

                                                        <div>
                                                            <div >

                                                                <button className={this.state.march ? 'btn clicked' : 'btn'} onClick={() => {
                                                                    this.setState({
                                                                        march: !this.state.march,
                                                                        // scheduled: false,
                                                                        // notBooked: false,
                                                                        // closed: false
                                                                    }, () => this.get())
                                                                }}>{'MAR'.translate(this.props.lang)}</button>
                                                            </div>
                                                        </div>

                                                        <div>
                                                            <div style={{ marginLeft: 10 }}>
                                                                <button className={this.state.april ? 'btn clicked' : 'btn'} onClick={() => {
                                                                    this.setState({
                                                                        april: !this.state.april,
                                                                        // scheduled: false,
                                                                        // rejectedByPatient: false,
                                                                        // notBooked: false
                                                                    }, () => this.get())
                                                                }} >{'APR'.translate(this.props.lang)}</button>
                                                            </div>
                                                        </div>

                                                        <div>
                                                            <div style={{ marginLeft: 10 }}>
                                                                <button className={this.state.may ? 'btn clicked' : 'btn'} onClick={() => {
                                                                    this.setState({
                                                                        may: !this.state.may,
                                                                        // scheduled: false,
                                                                        // rejectedByPatient: false,
                                                                        // notBooked: false
                                                                    }, () => this.get())
                                                                }} >{'MAY'.translate(this.props.lang)}</button>
                                                            </div>
                                                        </div>

                                                        <div>
                                                            <div style={{ marginLeft: 10 }}>
                                                                <button className={this.state.june ? 'btn clicked' : 'btn'} onClick={() => {
                                                                    this.setState({
                                                                        june: !this.state.june,
                                                                        // scheduled: false,
                                                                        // rejectedByPatient: false,
                                                                        // notBooked: false
                                                                    }, () => this.get())
                                                                }} >{'JUN'.translate(this.props.lang)}</button>
                                                            </div>
                                                        </div>

                                                        <div>
                                                            <div style={{ marginLeft: 10 }}>
                                                                <button className={this.state.july ? 'btn clicked' : 'btn'} onClick={() => {
                                                                    this.setState({
                                                                        july: !this.state.july,
                                                                        // scheduled: false,
                                                                        // rejectedByPatient: false,
                                                                        // notBooked: false
                                                                    }, () => this.get())
                                                                }} >{'JUL'.translate(this.props.lang)}</button>
                                                            </div>
                                                        </div>

                                                        <div>
                                                            <div style={{ marginLeft: 10 }}>
                                                                <button className={this.state.august ? 'btn clicked' : 'btn'} onClick={() => {
                                                                    this.setState({
                                                                        august: !this.state.august,
                                                                        // scheduled: false,
                                                                        // rejectedByPatient: false,
                                                                        // notBooked: false
                                                                    }, () => this.get())
                                                                }} >{'AUG'.translate(this.props.lang)}</button>
                                                            </div>
                                                        </div>

                                                        <div>
                                                            <div style={{ marginLeft: 10 }}>
                                                                <button className={this.state.september ? 'btn clicked' : 'btn'} onClick={() => {
                                                                    this.setState({
                                                                        september: !this.state.september,
                                                                        // scheduled: false,
                                                                        // rejectedByPatient: false,
                                                                        // notBooked: false
                                                                    }, () => this.get())
                                                                }} >{'SEP'.translate(this.props.lang)}</button>
                                                            </div>
                                                        </div>

                                                        <div>
                                                            <div style={{ marginLeft: 10 }}>
                                                                <button className={this.state.october ? 'btn clicked' : 'btn'} onClick={() => {
                                                                    this.setState({
                                                                        october: !this.state.october,
                                                                        // scheduled: false,
                                                                        // rejectedByPatient: false,
                                                                        // notBooked: false
                                                                    }, () => this.get())
                                                                }} >{'OCT'.translate(this.props.lang)}</button>
                                                            </div>
                                                        </div>

                                                        <div>
                                                            <div style={{ marginLeft: 10 }}>
                                                                <button className={this.state.november ? 'btn clicked' : 'btn'} onClick={() => {
                                                                    this.setState({
                                                                        november: !this.state.november,
                                                                        // scheduled: false,
                                                                        // rejectedByPatient: false,
                                                                        // notBooked: false
                                                                    }, () => this.get())
                                                                }} >{'NOV'.translate(this.props.lang)}</button>
                                                            </div>
                                                        </div>

                                                        <div>
                                                            <div style={{ marginLeft: 10 }}>
                                                                <button className={this.state.december ? 'btn clicked' : 'btn'} onClick={() => {
                                                                    this.setState({
                                                                        december: !this.state.december,
                                                                        // scheduled: false,
                                                                        // rejectedByPatient: false,
                                                                        // notBooked: false
                                                                    }, () => this.get())
                                                                }} >{'DEC'.translate(this.props.lang)}</button>
                                                            </div>
                                                        </div>

                                                    </div>


                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <div>
                                            <Container fluid>
                                                <Row>
                                                    <Col lg="12">
                                                        <div className="panel" style={{ paddingTop: 0, paddingLeft: 0 }}>
                                                            <div className="panel-header" style={{ marginBottom: 20 }}>
                                                                <h5 style={{ position: 'relative', left: '-15px' }} className="component-header">{'Chat ratings'.translate(this.props.lang)}</h5>

                                                            </div>
                                                            <Row className="chat-rating-date-buttons">
                                                                <div>
                                                                    <FormGroup>
                                                                        <DatePicker
                                                                            label={'Date from'.translate(this.props.lang)}
                                                                            value={this.state.dateFrom}
                                                                            onChange={(e) => {
                                                                                if (this.state.dateTo < e) {
                                                                                    this.setState({
                                                                                        error: 'Date start can not be greater than the end date'
                                                                                    })
                                                                                } else {
                                                                                    this.setState({ dateFrom: e }, () => this.get())
                                                                                }
                                                                            }}
                                                                        />
                                                                    </FormGroup>
                                                                </div>
                                                                <div>
                                                                    <FormGroup>
                                                                        <DatePicker
                                                                            label={'Date to'.translate(this.props.lang)}
                                                                            value={this.state.dateTo}
                                                                            onChange={(e) => {
                                                                                if (this.state.dateFrom > e) {
                                                                                    this.setState({
                                                                                        error: 'Date end can not be greater than the start date'
                                                                                    })
                                                                                } else {
                                                                                    this.setState({ dateTo: e }, () => this.get())
                                                                                }

                                                                            }}
                                                                        />
                                                                    </FormGroup>
                                                                </div>
                                                                <div>
                                                                    <FormGroup>
                                                                        <button className='chat-ratings-button' onClick={() => this.chatRatings(this.state.dateFrom, this.state.dateTo)}>{'Show'.translate(this.props.lang)}</button>
                                                                    </FormGroup>
                                                                </div>
                                                            </Row>
                                                            <div className="chat-rating-table">
                                                                <table className='listofmedicines-table listofmedicines-table-noeditable chat-ratings'>
                                                                    <thead>
                                                                        <tr>
                                                                            <th>{'Date'.translate(this.props.lang)}</th>
                                                                            <th>
                                                                                {'How did you experience'.translate(this.props.lang)}
                                                                                <br />
                                                                                {'your visit?'.translate(this.props.lang)}
                                                                            </th>
                                                                            <th>
                                                                                {`How did you experience the`.translate(this.props.lang)}
                                                                                <br />
                                                                                {`staff's competence?`.translate(this.props.lang)}
                                                                            </th>
                                                                            <th>
                                                                                {'How did you experience'.translate(this.props.lang)}
                                                                                <br />
                                                                                {'the treatment?'.translate(this.props.lang)}
                                                                            </th>
                                                                            <th>{'Average'.translate(this.props.lang)}</th>
                                                                            <th>{'Comment'.translate(this.props.lang)}</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {this.state.chatRatings ? this.state.chatRatings.map((item, idx) => {
                                                                            console.log(item)
                                                                            return (
                                                                                <tr>
                                                                                    <td>
                                                                                        <p>{this.props.getStringDateTs(item.tsCreated, this.props.dateFormat)/*moment.unix(item.tsCreated).format(this.props.dateFormat)*/}</p>
                                                                                    </td>
                                                                                    <td>
                                                                                        <p style={{ textAlign: 'right' }}>{item.rating1}</p>
                                                                                    </td>
                                                                                    <td>
                                                                                        <p style={{ textAlign: 'right' }}>{item.rating2}</p>

                                                                                    </td>
                                                                                    <td>
                                                                                        <p style={{ textAlign: 'right' }}>{item.rating3}</p>

                                                                                    </td>
                                                                                    <td>
                                                                                        <p style={{ textAlign: 'right' }}>{item.ratingAverage}</p>

                                                                                    </td>
                                                                                    <td>
                                                                                        <p>{item.comment}</p>

                                                                                    </td>

                                                                                </tr>

                                                                            )
                                                                        }) : null}

                                                                    </tbody>
                                                                    <tfoot>
                                                                        <tr>
                                                                            <td>
                                                                                {'Average:'.translate(this.props.lang)}
                                                                            </td>
                                                                            <td style={{ textAlign: 'right' }}>
                                                                                {this.state.answer1 ? this.state.answer1 : null}
                                                                            </td>
                                                                            <td style={{ textAlign: 'right' }}>
                                                                                {this.state.answer2 ? this.state.answer2 : null}
                                                                            </td>
                                                                            <td style={{ textAlign: 'right' }}>
                                                                                {this.state.answer3 ? this.state.answer3 : null}
                                                                            </td>
                                                                            <td style={{ textAlign: 'right' }}>
                                                                                {this.state.answer ? this.state.answer : null}
                                                                            </td>
                                                                        </tr>
                                                                    </tfoot>
                                                                </table>
                                                            </div>

                                                            {/* <ListBuilder
                                                                lang={this.props.lang}
                                                                loading={this.state.loading}
                                                                total={this.state.total}
                                                                showNumeration={false}
                                                                fields={[
                                                                    { type: 'text', name: 'date', label: 'Date'.translate(this.props.lang), allowSort: true },
                                                                    { type: 'text', name: 'rating1', label: 'How did you experience your visit?'.translate(this.props.lang), allowSort: true },
                                                                    { type: 'text', name: 'rating2', label: `How did you experience the staff's competence?`.translate(this.props.lang), allowSort: true },
                                                                    { type: 'text', name: 'rating3', label: 'How did you experience the treatment?'.translate(this.props.lang), allowSort: true },
                                                                    { type: 'text', name: 'ratingAverage', label: 'Average'.translate(this.props.lang), allowSort: true },
                                                                    { type: 'text', name: 'comment', label: 'Comment'.translate(this.props.lang), allowSort: true },
                                                                    // { type: 'text', name: 'groupName', label: 'Clinic'.translate(this.props.lang), allowSort: true },


                                                                ]}
                                                                rawItems={this.state.chatRatings}
                                                                items={this.state.chatRatings.map(item => {
                                                                    return {
                                                                        ...item,
                                                                        date: moment.unix(item.tsCreated).format(this.props.dateFormat)
                                                                    }
                                                                })}
                                                                // actions={
                                                                //     [

                                                                //         // {
                                                                //         //     component: <Isvg src={view} className="view-icon" />,
                                                                //         //     onClick: (item) => this.setState({ ratingView: item })
                                                                //         // },
                                                                //     ]
                                                                // }
                                                                params={params}
                                                                sortField={params.sortField}
                                                                sortType={params.sortType}
                                                                updateSort={this.updateSort}
                                                                hidePagination={true}
                                                                hideEntries={true}
                                                                hideFilters={true}
                                                                updateParams={this.updateParams}
                                                                updateMultipleParams={this.updateMultipleParams}
                                                            ></ListBuilder> */}
                                                            {/* <hr style={{ height: '1px', background: '#000', borderRadius: 6 }} />

                                                            <div style={{ display: 'flex', justifyContent: 'space-between', marginLeft: '10%', fontWeight: 600 }}>
                                                                {this.state.answer1 ? <div> {this.state.answer1} </div> : null}
                                                                {this.state.answer2 ? <div> {this.state.answer2} </div> : null}
                                                                {this.state.answer3 ? <div> {this.state.answer3} </div> : null}
                                                                {this.state.answer ? <div> {this.state.answer} </div> : null}
                                                            </div> */}

                                                        </div>
                                                    </Col>
                                                </Row>

                                            </Container>
                                        </div>
                                }





                                <DeleteModal
                                    lang={this.props.lang}
                                    isOpen={this.state.deleteModal}
                                    toggle={() => this.setState({ deleteModal: null })}
                                    handler={() => { this.delete(this.state.deleteModal._id); this.setState({ deleteModal: null }) }}
                                >
                                    {'Delete group permission'.translate(this.props.lang)} <strong>{this.state.deleteModal && this.state.deleteModal.name}</strong>?
                                </DeleteModal>

                                {this.state.error ?

                                    <ErrorModal lang={this.props.lang}
                                        isOpen={this.state.error}
                                        toggle={() => this.setState({ error: null })}

                                    >
                                        {this.state.error.translate(this.props.lang)}
                                    </ErrorModal>

                                    :
                                    null
                                }

                                {
                                    this.state.createReportModal ?
                                        <Modal isOpen={this.state.createReportModal} size='xl' centered>

                                            <ModalHeader close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ createReportModal: false, reportData: {} })}><Isvg src={xIcon} /></button>}>
                                                {'Create new report'.translate(this.props.lang)}
                                            </ModalHeader>
                                            <ModalBody className="delete-modal">
                                                <div className="report-modal-container">
                                                    <div className="1">
                                                        <div className="report-modal-row-1">
                                                            <Label>{'Report name'.translate(this.props.lang) + '*'}</Label>
                                                            <Input type='text' value={this.state.reportData && this.state.reportData.reportName ? this.state.reportData.reportName : ''} onChange={(e) => {
                                                                let reportData = this.state.reportData;
                                                                reportData.reportName = e.target.value;
                                                                this.setState({
                                                                    reportData
                                                                })
                                                            }} />
                                                            {this.state.errorReporrtName ? <p style={{ color: 'red' }}>{'Report name is required'.translate(this.props.lang)}</p> : null}
                                                        </div>
                                                        <div className="report-modal-row-2">
                                                            <div>
                                                                <Label>{"From".translate(this.props.lang)}</Label>
                                                                <DatePicker openCalendar={this.state.openCalendar} closeCalendar={() => { }} dateFormat={this.props.dateFormat} type='text' value={this.state.reportData && this.state.reportData.dateFrom ? this.state.reportData.dateFrom : moment(new Date()).format('YYYY-MM-DD')} onChange={(e) => {
                                                                    let reportData = this.state.reportData;
                                                                    reportData.dateFrom = e;
                                                                    this.setState({
                                                                        reportData
                                                                    })
                                                                }} />
                                                            </div>
                                                            <div>
                                                                <Label>{"To".translate(this.props.lang)}</Label>
                                                                <DatePicker openCalendar={this.state.openCalendar} closeCalendar={() => { }} dateFormat={this.props.dateFormat} type='text' value={this.state.reportData && this.state.reportData.dateTo ? this.state.reportData.dateTo : moment(new Date()).format('YYYY-MM-DD')} onChange={(e) => {
                                                                    let reportData = this.state.reportData;
                                                                    reportData.dateTo = e;
                                                                    this.setState({
                                                                        reportData
                                                                    })
                                                                }} />
                                                            </div>
                                                        </div>


                                                    </div>
                                                    <div className="2">
                                                        <div>
                                                            <Label>{'Interval'.translate(this.props.lang)}</Label>
                                                            <Input type='select' onChange={(e) => {
                                                                let reportData = this.state.reportData;
                                                                reportData.interval = e.target.value;
                                                                this.setState({
                                                                    reportData
                                                                })
                                                            }}>
                                                                <option value={-1}>
                                                                    {"Select interval".translate(this.props.lang)}
                                                                </option>
                                                                <option value={'day'}>
                                                                    {'Day'.translate(this.props.lang)}
                                                                </option>
                                                                <option value={'week'}>
                                                                    {'Week'.translate(this.props.lang)}
                                                                </option>
                                                                <option value={'month'}>
                                                                    {'Month'.translate(this.props.lang)}
                                                                </option>
                                                                <option value={'year'}>
                                                                    {'Year'.translate(this.props.lang)}
                                                                </option>
                                                            </Input>
                                                        </div>
                                                        <Label>{'Groups:'.translate(this.props.lang)}</Label>
                                                        <div className="list-checkbox-scroll-container">
                                                            <ListCheckBox
                                                                /*label={'Professions:'.translate(this.props.lang)}*/
                                                                lang={this.props.lang}
                                                                disableFilter={true}
                                                                disableFlex={true}
                                                                value={this.state.values}
                                                                buttonText1={'Mark all'.translate(this.props.lang)}
                                                                buttonText2={'Unmark all'.translate(this.props.lang)}
                                                                values={this.state.professions && this.state.professions.length ? this.state.professions.map(item => {
                                                                    return { name: this.props.lang == 'en' ? item.professionEn : item.professionSv, value: item._id }
                                                                }) : null}

                                                                onChange={(e) => {
                                                                    if (e && e.length) {
                                                                        let arr = [];
                                                                        arr = [...e]
                                                                        let reportData = this.state.reportData;
                                                                        reportData.professions = []
                                                                        reportData.professions = arr
                                                                        this.setState({
                                                                            values: arr,
                                                                            reportData
                                                                        }, () => this.get())

                                                                    } else {
                                                                        this.setState({
                                                                            values: [],
                                                                            // controlBoking: false,
                                                                            // webBoking: false,
                                                                            // dropInBoking: false,
                                                                            // clinicBoking: false,
                                                                            // allowed: false,
                                                                            // scheduled: false,
                                                                            // waitingForApproval: false,
                                                                            // waitingForPayment: false,
                                                                            // notBooked: false,
                                                                            // disallowed: false,
                                                                            // inProgress: false,
                                                                            // waiting: false,
                                                                            // controlScheduled: false,
                                                                            // cancledByClinic: false,
                                                                            // rejectedByPatient: false,
                                                                            // closed: false
                                                                        }, () => this.get())
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                            </ModalBody>
                                            <ModalFooter className="buttons-right-blue" style={{ justifyContent: 'flex-end' }}>
                                                <Button color="primary" style={{ margin: 10 }} onClick={() => this.state.reportData && this.state.reportData.reportName ? this.createReport(this.state.reportData) : this.setState({ errorReporrtName: true })}>{'Create report'.translate(this.props.lang)}</Button>

                                            </ModalFooter>
                                        </Modal>
                                        :
                                        null
                                }

                            </div>
                        </Col>
                    </Row>

                </Container>

                <Container fluid>
                    <Row>
                        <Col lg="12">

                            {
                                this.state.tabIndex == 1 ?
                                    <div className="panel" style={{ paddingBottom: 40 }}>
                                        <div className="statistics-box">
                                            {
                                                this.state.files && this.state.files.length ? this.state.files.map(item => {
                                                    return (
                                                        <div className="statistics-box-container" style={{ borderBottom: '1px solid #ddd', marginBottom: 15, paddingBottom: 5 }}>
                                                            <div>
                                                                <h6>{this.props.lang == 'en' ? item.name : item.nameSv}</h6>
                                                                <p>{'From:'.translate(this.props.lang) + " " + moment(new Date(item.startDate)).format(this.props.dateFormat) + ' ' + 'to'.translate(this.props.lang) + ' ' + moment(new Date(item.endDate)).format(this.props.dateFormat)}</p>
                                                            </div>
                                                            <div>

                                                                <button className='group-statistics-delete' style={{ backgroundColor: 'transparent', border: 'none' }} onClick={() => this.deleteFile(item._id)}> <Isvg src={garabage} />  <div className="group-statistics-delete-tooltip">{'Delete'.translate(this.props.lang)}</div> </button>
                                                                <a href={API_ENDPOINT + `${this.props.lang == 'en' ? item.link : item.linkSv}`} target={'_blank'}>{'Download'.translate(this.props.lang)}</a>

                                                            </div>

                                                        </div>
                                                    )
                                                })
                                                    :
                                                    <div className="statistics-box-report">
                                                        <p>{"No reports created for this period".translate(this.props.lang)}</p>
                                                        <button className="btn add-button" onClick={() => this.setState({ createReportModal: true })}> {"Create new report".translate(this.props.lang)}</button>
                                                    </div>
                                            }
                                        </div>
                                    </div>
                                    :
                                    null
                            }

                        </Col>
                    </Row>
                </Container>

                {
                    this.state._loading ?
                        <div className="file-progress-loader">
                            <div>
                                <Player
                                    onEvent={(event) => {
                                        if (event === "load" && this.player && this.player.current) this.player.current.play();
                                    }}
                                    ref={this.player} // set the ref to your class instance
                                    autoplay={true}
                                    loop={true}
                                    controls={true}
                                    src="/lf30_editor_l5cxzdyf.json"
                                    renderer={'svg'}
                                    style={{ height: "128px", width: "128px", }}
                                ></Player>
                                <h6>{'Processing...'.translate(this.props.lang)}</h6>
                            </div>

                        </div>

                        :
                        null
                }
                {
                    this.state.exportStatistic ?
                        <InfoModal
                            isOpen={this.state.exportStatistic}
                            toggle={() => this.setState({ exportStatistic: !this.state.exportStatistic })}
                            header={'Info'.translate(this.props.lang)}
                            info={'Report generation has started. This process could take a while. Please check the Group statistics page in 10 minutes.'.translate(this.props.lang)}
                            buttons={[
                                <Button color="primary" style={{ margin: 10 }} onClick={() => this.setState({ exportStatistic: false, createReportModal: null, errorReporrtName: null })}>{'Close'.translate(this.props.lang)}</Button>
                            ]}

                        />
                        :
                        null
                }
            </div>
        );
    }
}

const selector = formValueSelector('modalForm');

export default connect(state => {
    return { category: selector(state, 'category') }

}, {

})(Page(GroupStatistics));