import React, { Component } from 'react';
import Page from '../../containers/page';

import {
    Container,
    Row,
    Col,
    Modal, ModalHeader, ModalBody, ModalFooter, Button

} from 'reactstrap';

import Isvg from 'react-inlinesvg'
import { formValueSelector, change } from 'redux-form';  // ES6
import { connect } from 'react-redux';
import ErrorModal from '../../components/errorModal';
import infoIcon from "../../assets/svg/link-new-tab.svg";
import PatientFilter from '../../components/patientsList';

import { getSearchParams, generateSearchLink, generateSearchLinkMultiple } from '../../helpers/linkHelper';
import { API_ENDPOINT } from '../../constants';
function parseJSON(response) {
    return response.json().then(json => {
        return {
            result: json,
            status: response.status
        }
    })
}

/**
* List of patients for selected clinic and selected department
* @author   Aleksandar Dabic
*/
class Patients extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...props.initialData,
            form: false,
            useState: false,
            params: {
                entries: 10,
                page: 0
            },
            items: [],
            endpoints: [],
            formModules: [],
            userLevels: [],
            languages: [],
            formUsers: [],
            total: 0,
            tabIndex: 1,
            loading: true
        };
    }


    get = () => {
        this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));

        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0, group: this.props.selectedGroup })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

        for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
            this.props.loadDataWithQuery[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0, group: this.props.selectedGroup })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

    }

    componentDidMount() {
        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }


        this.checkPermissions()


        this.get();
    }

    checkPermissions = () => {
        if (this.props.uData) {
            let adminPermission = false;
            let clinicAdminPermission = false;

            if (this.props.uData.permissions && this.props.uData.groupPermissionsUser) {
                let adminPermissionId = this.props.uData.permissions.admin ? this.props.uData.permissions.admin : null;
                let clinicAdminPermissionId = this.props.uData.permissions.clinicAdmin ? this.props.uData.permissions.clinicAdmin : null;
                if (this.props.selectedGroup && this.props.selectedClinic) {
                    if (this.props.uData.groupPermissionsUser[this.props.selectedGroup] && this.props.uData.groupPermissionsUser[this.props.selectedGroup][this.props.selectedClinic] && this.props.uData.groupPermissionsUser[this.props.selectedGroup][this.props.selectedClinic].length) {
                        for (let i = 0; i < this.props.uData.groupPermissionsUser[this.props.selectedGroup][this.props.selectedClinic].length; i++) {
                            if (this.props.uData.groupPermissionsUser[this.props.selectedGroup][this.props.selectedClinic][i] == adminPermissionId) {
                                adminPermission = true;
                            }
                            if (this.props.uData.groupPermissionsUser[this.props.selectedGroup][this.props.selectedClinic][i] == clinicAdminPermissionId) {
                                clinicAdminPermission = true;
                            }
                        }
                    }
                }
            }



            this.setState({ adminPermission, clinicAdminPermission }, () => {
                // if (!this.state.clinicAdminPermission) {
                //     this.setState({ tabIndex: 2, getData: true })
                // }
            })
        }

    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps[0].location.search != this.props[0].location.search) {
            this.setState({
                loading: true
            }, () => {
                this.get();

            })
        }
        if (prevProps.userLevel != this.props.userLevel && this.props.userLevel != '5f6b126329ef8110f415087b') {
            //alert(true)
            this.props.changeParentAccount(null);
            this.forceUpdate();
        }
        if (!prevProps.uData && this.props.uData) {
            this.checkPermissions()
        }

    }


    componentWillReceiveProps(nextProps) {
    }

    updateSort = (field, type) => {
        if (this.state.useState) {
            let state = this.state.params;
            state.sortField = field;
            state.sortType = type;
            this.setState({
                params: state
            })
        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0 }, [{ name: 'sortField', value: field }, { name: 'sortType', value: type }], false));

        }
    }

    updateParams = (name, value, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };
                obj[name] = value;
                this.setState({ params: obj });
            } else {
                let obj = this.state.params;
                obj[name] = value;
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(generateSearchLink(this.props[0].location.search, { entries: 10, page: 0 }, name, value, restart));
        }
    }
    updateMultipleParams = (fields, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj });

            } else {
                let obj = this.state.params;
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0 }, fields, restart));
        }
    }





    stopAnimation = () => {
        this.player.current.play();
    }





    render() {
        let params = {};
        if (this.state.useState) {
            params = this.state.params;
        } else {
            params = getSearchParams(this.props[0].location.search, { entries: 10, page: 0 })
        }
        const defaultOptions = {
            loop: true,
            autoplay: true,
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice'
            }
        };
        //console.log(this.props)
        let parentAccounts = [];
        for (let i = 0; i < this.state.formUsers.length; i++) {
            if (this.state.formUsers[i].userLevel == '5f6b125929ef8110f415087a') {
                parentAccounts.push(this.state.formUsers[i])
            }
        }

        return (
            <div className={this.props.autoHideSidebar ? "dashboard dashboard-auto-hide" : "dashboard"}>

                <Container fluid>

                    <Row>
                        <Col lg="12">
                            <div className="panel">
                                <div className="panel-header">
                                    <h5 className="component-header">{this.props.linksMeta && this.props.linksMeta[this.props[0].location.pathname] ? this.props.linksMeta[this.props[0].location.pathname][this.props.lang] : ''}</h5>

                                </div>
                                {
                                    (this.state.clinicAdminPermission || this.state.adminPermission) && this.props.uData && this.props.uData.patientLevelAllowed ?
                                        <div className="tabs-buttons-wrap" style={{ zIndex: 950, overflowY: 'unset' }}>

                                            <Button className={this.state.tabIndex === 1 ? "tab-button acitve-tab-button" : "tab-button"} onClick={() => this.setState({ tabIndex: 1, getData: true })}>{"Patients - level 2".translate(this.props.lang)}</Button>
                                            <Button className={this.state.tabIndex === 2 ? "tab-button acitve-tab-button tab-button-with-tlt" : "tab-button tab-button-with-tlt"} onClick={() => this.setState({ tabIndex: 2, getData: true })}>
                                                <div className="tab-info-action-tooltip"> <Isvg src={infoIcon} className="info-text" /> <div className="chat-icons-tooltip">{'Patient level 1 consists of patients who have registered but have not submitted a case. The account is automatically deleted after 7 days unless the patient submits a case.'.translate(this.props.lang)}</div> </div>
                                                {"Patients - level 1".translate(this.props.lang)}
                                            </Button>
                                        </div>
                                        :
                                        null
                                }

                                {
                                    this.state.tabIndex === 1 && (this.state.clinicAdminPermission || this.state.adminPermission) ?
                                        <PatientFilter
                                            {...this.props}
                                            getData={this.state.getData}
                                            setData={() => {
                                                this.setState({ getData: false })
                                            }}
                                            patientDelete={true}
                                            onChange={(val) => {

                                            }}
                                        />
                                        :
                                        this.state.tabIndex === 2 && this.props.uData && this.props.uData.patientLevelAllowed ?
                                            <PatientFilter
                                                {...this.props}
                                                getData={this.state.getData}
                                                setData={() => {
                                                    this.setState({ getData: false })
                                                }}
                                                userLevel1={true}
                                                onChange={(val) => {

                                                }}
                                            />
                                            :
                                            null

                                }


                                {this.state.error ?

                                    <ErrorModal
                                        isOpen={this.state.error}
                                        toggle={() => this.setState({ error: null })}

                                    >
                                        {this.state.error.translate(this.props.lang)}
                                    </ErrorModal>

                                    :
                                    null
                                }



                            </div>
                        </Col>
                    </Row>

                </Container>


            </div>
        );
    }
}


const selector = formValueSelector('modalForm');

export default connect(state => {
    return {
        userLevel: selector(state, 'userLevel'),
        alias: selector(state, 'alias'),
        username: selector(state, 'username'),
        name: selector(state, 'userData.name'),


    }


}, {
    changeParentAccount: value => change("modalForm", "parentAccount", value),
    changeUsername: value => change("modalForm", "username", value),
})(Page(Patients));