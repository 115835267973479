import React, { Component } from 'react';
import { GOOGLE_MAPS_API_KEY } from '../../../constants';
/**
* Showing map with specific coords
* @author   Milan Stanojevic
*/
export class Map extends Component {
    constructor(props) {
        super(props);
        this.initMap = this.initMap.bind(this);
        this.gmapCallback = this.gmapCallback.bind(this);
        if (typeof window !== 'undefined')
            window.googleMapsCallback = this.gmapCallback;

        this.state = {

        };
    }

    gmapCallback() {
        this.setState({
            _googleMapsLoaded: true
        })
    }



    initMap() {
        this.setState({
            _mapInit: true
        });


        var latLng = new window.google.maps.LatLng(this.props.value[0], this.props.value[1]);
        this.map = new window.google.maps.Map(this.GoogleMap, {
            zoom: 18,
            center: latLng,
            mapTypeId: window.google.maps.MapTypeId.ROADMAP,
            disableDefaultUI: true,
            gestureHandling: "greedy",
        });

        google.maps.event.addListener(this.map, 'click', (event) => {
            this.changePosition(event.latLng);
         });
         
         

        this.marker = new window.google.maps.Marker({
            position: new window.google.maps.LatLng(this.props.value[0], this.props.value[1]),
            map: this.map,
        });

    }
    changePosition = (latLng) => {
        // console.log(latLng)
        this.props.onChange([latLng.lat(), latLng.lng()])
        this.marker.setPosition(latLng);

    }

    componentDidMount() {

        var ref = window.document.getElementsByTagName("script")[0];
        var script = window.document.createElement("script");
        script.src = `https://maps.googleapis.com/maps/api/js?sensor=false&key=${GOOGLE_MAPS_API_KEY}&callback=googleMapsCallback&language=se`;
        script.async = true;
        script.defer = true;

        ref.parentNode.insertBefore(script, ref);


        if (this.state._googleMapsLoaded && !this.state._mapInit) {
            this.initMap();
        }

    }

    componentDidUpdate(prevProps) {

        if (this.state._googleMapsLoaded && !this.state._mapInit) {
            this.initMap();
        }

        if (this.state._googleMapsLoaded && JSON.stringify(prevProps.value) != JSON.stringify(this.props.value)) {
            var latLng = new window.google.maps.LatLng(this.props.value[0], this.props.value[1]);
            //this.map.setCenter(latLng);
            this.marker.setPosition(latLng);

        }

    }


    render() {
        return (
            <div className="map-wrap map-field" style={{height: this.props.height}}>{
                this.state._googleMapsLoaded ?
                    <div className="map" ref={(input) => { this.GoogleMap = input; }}>

                    </div>
                    : null}
            </div>
        )
    }
}

export default Map;