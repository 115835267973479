
import React, { Component } from 'react';
import {
    FormGroup, Label, Form, Modal, ModalBody, ModalFooter, ModalHeader, Button
} from 'reactstrap';

import Isvg from 'react-inlinesvg';
import image from '../../../assets/svg/picture.svg';
import { API_ENDPOINT } from '../../../constants';
import { Player, Controls } from "@lottiefiles/react-lottie-player";
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import logoSmall from '../../../assets/svg/logo-small.svg'

function parseText(response) {
    return response.text().then(text => {
        return {
            result: text,
            status: response.status
        }
    })
}


/**
* Component for import and style image
* @author   Milan Stanojevic
*/
class Image extends Component {
    constructor(props) {
        super(props);
        this.selectFile = this.selectFile.bind(this);

        this.state = {
            crop: { width: 50, height: 50, aspect: 1 },
        };
    }

    selectFile(e) {
        this.setState({
            error: null
        })
        let input = e.target;
        if (input.files && input.files[0]) {
            let fsize = input.files[0].size;
            let file = Math.round((fsize / 1024))

            if (file > 5120) {
                this.setState({ error: "File size limit has been reached! (max 5 MB)".translate(this.props.lang) })
            } else {
                this.setState({
                    _loading: true,
                    cropModal: this.props.cropModal ? true : this.props.description ? true : false
                }, () => {

                    if ((window.location.href != `${window.location.origin}/my-data/main-clinic-data`) && (window.location.href != `${window.location.origin}/my-data/clinic-data`) && (window.location.pathname != `/modules/manage-modules`) && (window.location.pathname != `/data-managment/wiki`))
                        this.props.onLoadImage(false)
                })

                let formData = new FormData();
                formData.append('file', input.files[0]);
                formData.append('group', this.props.group)
                formData.append('fileType', 'clinicData')

                this.setState({ origValue: this.props.value })
                this.props.onChange('loading')

                this.props.cropModal ?
                    fetch(API_ENDPOINT + '/data/upload-image/logochat', {
                        method: 'POST',
                        headers: {
                            Accept: 'application/json',
                            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,

                        },
                        body: formData
                    }).then(parseText).then(async ({ result, status }) => {
                        if (status != 200) {
                            this.setState({ error: result.translate(this.props.lang) })
                        } else {

                            this.props.onChange(result);
                            this.setState({
                                showCropNextCycle: true

                            })
                        }
                    })


                    :

                    this.props.description ?
                        fetch(API_ENDPOINT + '/data/upload-image/logochat', {
                            method: 'POST',
                            headers: {
                                Accept: 'application/json',
                                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,

                            },
                            body: formData
                        }).then(parseText).then(async ({ result, status }) => {
                            if (status != 200) {
                                this.setState({ error: result.translate(this.props.lang) })
                            } else {

                                this.props.onChange(result);
                                this.setState({
                                    showCropNextCycle: true

                                })
                            }
                        })


                        :

                        fetch(API_ENDPOINT + '/data/upload-image', {
                            method: 'POST',
                            headers: {
                                Accept: 'application/json',
                                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,

                            },
                            body: formData
                        }).then(parseText).then(({ result, status }) => {
                            if (status != 200) {
                                this.setState({ error: result.translate(this.props.lang) })
                            } else {
                                this.props.onChange(result);
                                this.setState({
                                    _loading: null
                                }, () => {

                                    if ((window.location.href != `${window.location.origin}/my-data/main-clinic-data`) && (window.location.href != `${window.location.origin}/my-data/clinic-data`) && (window.location.pathname != `/modules/manage-modules`) && (window.location.pathname != `/data-managment/wiki`))
                                        this.props.onLoadImage(false)
                                })
                            }
                        })
            }
        }
    }

    onImageLoaded = img => {
        this.imageRef = img
    };

    onCropComplete = crop => {
        this.makeClientCrop(crop);
    };

    onCropChange = (crop, percentCrop) => {
        // You could also use percentCrop:
        // this.setState({ crop: percentCrop });
        this.setState({ crop });
    };

    async makeClientCrop(crop) {
        if (this.imageRef && crop.width && crop.height) {
            const croppedImageUrl = await this.getCroppedImg(
                this.imageRef,
                crop,
                'newFile.jpeg'
            );
            this.setState({ croppedImageUrl });
            // console.log(croppedImageUrl)
        }

    }

    getCroppedImg(image, crop, fileName) {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height,
        );


        return new Promise((resolve, reject) => {
            resolve(canvas.toDataURL());

        });
    }

    componentDidUpdate(prevProps) {

    }


    render() {
        return (
            <FormGroup className="image-picker-wrap">
                <div style={{ display: 'flex', paddingLeft: 5 }}>
                    <div>
                        <Label size="sm">{this.props.label}</Label>
                        {this.props.descriptio && !this.props.cropModal ?

                            <p style={{ fontSize: 12 }}> {this.props.description} </p>

                            : null
                        }
                    </div>
                    {this.props.description && !this.props.cropModal ?
                        <div className='group-profile-logo'>
                            <Isvg src={logoSmall} style={{ minHeight: 53, minWidth: 53 }} />
                        </div>
                        : null}
                </div>

                <div className="image-picker" style={{ position: 'relative' }}>
                    <input type="file" accept=".jpg, .jpeg, .JPG, .JPEG, .png, .gif" title={"No file chosen".translate(this.props.lang)} onChange={this.selectFile} key={this.props.value} />
                    {this.props.value ?
                        <img crossOrigin="anonymous" onLoad={() => {
                            if (this.state.showCropNextCycle) {

                                let img = this.imgRef;
                                var canvas = document.createElement("canvas");
                                canvas.width = img.naturalWidth;
                                canvas.height = img.naturalHeight;
                                var ctx = canvas.getContext("2d");
                                ctx.drawImage(img, 0, 0);
                                var dataURL = canvas.toDataURL("image/png");
                                // console.log(dataURL)
                                this.setState({ imageToCrop: dataURL, _loading: null })

                            }
                        }} ref={(node) => this.imgRef = node} src={this.props.value.indexOf('data:image') !== -1 ? this.props.value : API_ENDPOINT + this.props.value} />
                        :
                        null
                    }


                    {
                        this.props.value ?
                            <button className="delete" onClick={() => this.props.onChange(null)}>&times;</button>
                            :
                            null
                    }

                    <div className="bottom-content">
                        <Isvg src={image} />
                        <p> <span>{'Upload a file'.translate(this.props.lang && this.props.lang)}</span> {'or drag and drop'.translate(this.props.lang)}</p>
                    </div>


                </div>
                <div>{this.state.error}</div>

                {this.state.cropModal && !this.state._loading ?
                    <Modal size="lg" isOpen={this.state.cropModal} toggle={() => { this.setState({ cropModal: !this.state.cropModal }) }} >
                        <ModalHeader toggle={() => { this.setState({ cropModal: !this.state.cropModal }) }}>{'Crop image'.translate(this.props.lang)}</ModalHeader>
                        <ModalBody>
                            {
                                <ReactCrop
                                    src={this.state.imageToCrop}
                                    ruleOfThirds
                                    onImageLoaded={this.onImageLoaded}
                                    onComplete={this.onCropComplete}
                                    onChange={this.onCropChange}
                                    crop={{ ...this.state.crop }}
                                />
                            }


                        </ModalBody>

                        <ModalFooter>
                            <Button color="secondary" onClick={() => {
                                let image = this.state.image;

                                image = this.state.croppedImageUrl;
                                this.setState({ cropModal: !this.state.cropModal, showCropNextCycle: false, }, () => {
                                    this.props.onChange(image);

                                })

                            }}>{'Save'.translate(this.props.lang)}</Button>
                        </ModalFooter>
                    </Modal>
                    :
                    null
                }

                {
                    this.state._loading ?
                        <div className="file-progress-loader">
                            <div>
                                <Player
                                    onEvent={(event) => {
                                        if (event === "load" && this.player && this.player.current) this.player.current.play();
                                    }}
                                    ref={this.player} // set the ref to your class instance
                                    autoplay={true}
                                    loop={true}
                                    controls={true}
                                    src="/lf30_editor_l5cxzdyf.json"
                                    renderer={'svg'}
                                    style={{ height: "128px", width: "128px", }}
                                ></Player>
                                <h6>{'Processing file...'.translate(this.props.lang)}</h6>
                            </div>

                        </div>

                        :
                        null
                }
            </FormGroup>


        );
    }
}

export default Image;