import React from 'react';

import {
    Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';

import RGL, { WidthProvider } from "react-grid-layout";
import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'

import Isvg from 'react-inlinesvg';
import moveIcon from '../../assets/svg/move.svg';
import Header from './header';
import Text from './text';
import Button from './button';

import Image from './img';
import { Responsive as ResponsiveGridLayout } from 'react-grid-layout';
import { formatCSS } from '../cssHelper';
import Login from './login';
import Account from './account';
import Map from './map';
import MainInfo from './mainInfo';

//const ReactGridLayout = WidthProvider(RGL);

/**
* AppBuilder
* @author   Milan Stanojevic
*/
class Grid extends React.Component {

    constructor(props) {
        super(props);
        this.state = {

        }
    }

    onLayoutChange = () => {

    }

    onDrop = (layout, layoutItem, _event) => {
        this.props.addElement(layoutItem);
    };


    render() {

        let width = this.grid && this.grid.offsetWidth;
        if (this.props.style && this.props.style.paddingLeft) {
            width = width - this.props.style.paddingLeft;
        }
        if (this.props.style && this.props.style.paddingRight) {
            width = width - this.props.style.paddingRight;
        }


        let nodes = [];
        let responsiveLayouts = { lg: [], sm: [], xs: [], app: [] };
        for (var node in this.props.nodes) {
            let show = true;
            if (this.props.loggedIn && this.props.nodes[node].hideLoggedIn) {
                show = false
            }
            if (!this.props.loggedIn && this.props.nodes[node].hideLoggedOut) {
                show = false
            }
            if (this.props.nodes[node] && this.props.nodes[node].parent == this.props.id && this.props.nodes[node].visibility[this.props.layout] && show) {


                let detectedLayout;

                if (width < 480 && width != 355) {
                    detectedLayout = 'xs';
                }
                if (width == 355) {
                    detectedLayout = 'app';
                }
                if (width < 768 && width >= 480) {
                    detectedLayout = 'sm';
                }
                if (width >= 768) {
                    detectedLayout = 'lg';
                }

                if (detectedLayout != this.props.layout) {
                    if (detectedLayout == 'lg') {
                        responsiveLayouts.lg.push({
                            i: node,
                            ...this.props.nodes[node][this.props.layout],
                        })
                    }
                    if (detectedLayout == 'sm') {
                        responsiveLayouts.sm.push({
                            i: node,
                            ...this.props.nodes[node][this.props.layout],
                        })
                    }
                    if (detectedLayout == 'xs') {
                        responsiveLayouts.xs.push({
                            i: node,
                            ...this.props.nodes[node][this.props.layout],
                        })
                    }
                    if (detectedLayout == 'app') {
                        responsiveLayouts.app.push({
                            i: node,
                            ...this.props.nodes[node][this.props.layout],
                        })
                    }

                } else {
                    responsiveLayouts.lg.push({
                        i: node,
                        ...this.props.nodes[node].lg,
                    })
                    responsiveLayouts.sm.push({
                        i: node,
                        ...this.props.nodes[node].sm,
                    })
                    responsiveLayouts.xs.push({
                        i: node,
                        ...this.props.nodes[node].xs,
                    })
                    responsiveLayouts.app.push({
                        i: node,
                        ...this.props.nodes[node].app,
                    })
                }


                let style = {};
                if (this.props.nodes[node].style) {
                    style = { ...this.props.nodes[node].style };
                }

                if (this.props.layout == 'sm' && this.props.nodes[node].responsiveStyle && this.props.nodes[node].responsiveStyle.sm) {
                    style = { ...style, ...this.props.nodes[node].responsiveStyle.sm };

                }
                if (this.props.layout == 'xs' && this.props.nodes[node].responsiveStyle && this.props.nodes[node].responsiveStyle.xs) {
                    style = { ...style, ...this.props.nodes[node].responsiveStyle.xs };
                }
                if (this.props.layout == 'app' && this.props.nodes[node].responsiveStyle && this.props.nodes[node].responsiveStyle.app) {
                    style = { ...style, ...this.props.nodes[node].responsiveStyle.app };
                }
                if (this.props.isActive && this.props.nodes[node].activeStyle) {
                    style = { ...style, ...this.props.nodes[node].activeStyle };
                }

                if (style && style.boxShadow) {
                    let boxShadow = style.boxShadow;
                    let boxShadowSplit = boxShadow.split('rgba')
                    let boxShadowSplitLeft = boxShadowSplit[0].split(' ')
                    let newValue = '';
                    for (let i = 0; i < 4; i++) {
                        newValue += boxShadowSplitLeft[i].indexOf('px') == -1 ? boxShadowSplitLeft[i] + 'px' : boxShadowSplitLeft[i];
                        newValue += ' ';
                    }
                    newValue += `rgba${boxShadowSplit[1]}`
                    style.boxShadow = newValue;
                }



                let props = {};
                if (this.props.nodes[node].props) {
                    props = { ...this.props.nodes[node].props };
                }

                if (this.props.layout == 'sm' && this.props.nodes[node].responsiveProps && this.props.nodes[node].responsiveProps.sm) {
                    props = { ...props, ...this.props.nodes[node].responsiveProps.sm };

                }
                if (this.props.layout == 'xs' && this.props.nodes[node].responsiveProps && this.props.nodes[node].responsiveProps.xs) {
                    props = { ...props, ...this.props.nodes[node].responsiveProps.xs };
                }
                if (this.props.layout == 'app' && this.props.nodes[node].responsiveProps && this.props.nodes[node].responsiveProps.app) {
                    props = { ...props, ...this.props.nodes[node].responsiveProps.app };
                }
                if (this.props.isActive && this.props.nodes[node].activeProps) {
                    props = { ...style, ...this.props.nodes[node].activeProps };
                }


                nodes.push({
                    i: node,
                    ...this.props.nodes[node],
                    style: style,
                    props: props

                })
            }
        }

        nodes.sort((a, b) => {
            return (a[this.props.layout].y * 100 + a[this.props.layout].x) - (b[this.props.layout].y * 100 + b[this.props.layout].x);
        })


        return (
            <div className="app-builder-grid" ref={(node) => this.grid = node} style={formatCSS(this.props.style)}>
                {this.grid && this.grid.offsetWidth ?
                    <ResponsiveGridLayout
                        breakpoints={{ lg: 768, sm: 480, xs: 0 }}
                        //onResize={() => this.setState({width: this.grid && this.grid.offsetWidth}, this.forceUpdate)}
                        width={width}
                        layouts={responsiveLayouts}
                        onLayoutChange={(layout, layouts) => this.props.changeLayout(this.props.id, layouts, width)}
                        cols={{ lg: 100, sm: 100, xs: 100 }}
                        draggableHandle={this.props.id ? `.app-builder-drag-handle-${this.props.id}` : ".app-builder-drag-handle"}
                        margin={[1, 1]}
                        isDroppable={(this.props.activeElement == this.props.id) || (this.props.nodes[this.props.activeElement] && this.props.nodes[this.props.activeElement].parent == this.props.id && this.props.nodes[this.props.activeElement].type != 'grid') ? true : false}
                        onDrop={this.onDrop}
                        //compactType={'horizontal'}
                        //measureBeforeMount={false}
                        //preventCollision={true}
                        rowHeight={10}
                    >
                        {
                            nodes.map((item, idx) => {

                                if (item.type == 'grid') {
                                    return (
                                        <div onContextMenu={(e) => this.props.handleRightClick(item.i, e)} onClick={(e) => { this.props.handleElementHover(e, item.i, true) }} key={item.i} dummyWidth={this.state.width} >
                                            {this.props.activeElement == item.i ? <Header removeElement={() => this.props.removeElement(item.i)} handleClassName={this.props.id ? `app-builder-drag-handle-${this.props.id}` : "app-builder-drag-handle"} /> : null}
                                            <Grid selectedClinic={this.props.selectedClinic} selectedGroup={this.props.selectedGroup} mainData={this.props.mainData} isActive={this.props.isActive} loggedIn={this.props.loggedIn} style={item.style} handleRightClick={this.props.handleRightClick} removeElement={this.props.removeElement} layout={this.props.layout} addElement={this.props.addElement} changeLayout={this.props.changeLayout} changeElement={this.props.changeElement} cols={100} nodes={this.props.nodes} id={item.i} handleElementHover={this.props.handleElementHover} activeElement={this.props.activeElement} lang={this.props.lang}></Grid>
                                        </div>
                                    )
                                }
                                else if (item.type == 'text') {
                                    return (
                                        <div onContextMenu={(e) => this.props.handleRightClick(item.i, e)} key={item.i} onClick={(e) => this.props.handleElementHover(e, item.i, true)} >
                                            {this.props.activeElement == item.i ? <Header removeElement={() => this.props.removeElement(item.i)} handleClassName={this.props.id ? `app-builder-drag-handle-${this.props.id}` : "app-builder-drag-handle"} /> : null}
                                            <Text selectedClinic={this.props.selectedClinic} selectedGroup={this.props.selectedGroup} style={item.style} onChange={(text) => this.props.changeElement(item.i, 'value', text)} value={item.value} id={item.i} />
                                        </div>
                                    )
                                }
                                else if (item.type == 'image') {
                                    return (
                                        <div onContextMenu={(e) => this.props.handleRightClick(item.i, e)} key={item.i} onClick={(e) => this.props.handleElementHover(e, item.i, true)} >
                                            {this.props.activeElement == item.i ? <Header removeElement={() => this.props.removeElement(item.i)} handleClassName={this.props.id ? `app-builder-drag-handle-${this.props.id}` : "app-builder-drag-handle"} /> : null}
                                            <Image style={item.style} onChange={(text) => this.props.changeElement(item.i, 'value', text)} value={item.value} />
                                        </div>
                                    )
                                } else if (item.type == 'button') {
                                    return (
                                        <div onContextMenu={(e) => this.props.handleRightClick(item.i, e)} key={item.i} onClick={(e) => this.props.handleElementHover(e, item.i, true)} >
                                            {this.props.activeElement == item.i ? <Header removeElement={() => this.props.removeElement(item.i)} handleClassName={this.props.id ? `app-builder-drag-handle-${this.props.id}` : "app-builder-drag-handle"} /> : null}
                                            <Button selectedClinic={this.props.selectedClinic} selectedGroup={this.props.selectedGroup} props={item.props} style={item.style} onChange={(text) => this.props.changeElement(item.i, 'value', text)} value={item.value} />
                                        </div>
                                    )
                                } else if (item.type == 'button2') {
                                    return (
                                        <div onContextMenu={(e) => this.props.handleRightClick(item.i, e)} key={item.i} onClick={(e) => this.props.handleElementHover(e, item.i, true)} >
                                            {this.props.activeElement == item.i ? <Header removeElement={() => this.props.removeElement(item.i)} handleClassName={this.props.id ? `app-builder-drag-handle-${this.props.id}` : "app-builder-drag-handle"} /> : null}
                                            <Button selectedClinic={this.props.selectedClinic} selectedGroup={this.props.selectedGroup} props={item.props} style={item.style} onChange={(text) => this.props.changeElement(item.i, 'value', text)} value={item.value} />
                                        </div>
                                    )
                                } else if (item.type == 'account') {
                                    return (
                                        <div onContextMenu={(e) => this.props.handleRightClick(item.i, e)} key={item.i} onClick={(e) => this.props.handleElementHover(e, item.i, true)} >
                                            {this.props.activeElement == item.i ? <Header removeElement={() => this.props.removeElement(item.i)} handleClassName={this.props.id ? `app-builder-drag-handle-${this.props.id}` : "app-builder-drag-handle"} /> : null}
                                            <Account props={item.props} style={item.style} onChange={(text) => this.props.changeElement(item.i, 'value', text)} value={item.value} />
                                        </div>
                                    )
                                } else if (item.type == 'login') {
                                    return (
                                        <div onContextMenu={(e) => this.props.handleRightClick(item.i, e)} key={item.i} onClick={(e) => this.props.handleElementHover(e, item.i, true)} >
                                            {this.props.activeElement == item.i ? <Header removeElement={() => this.props.removeElement(item.i)} handleClassName={this.props.id ? `app-builder-drag-handle-${this.props.id}` : "app-builder-drag-handle"} /> : null}
                                            <Login props={item.props} style={item.style} onChange={(text) => this.props.changeElement(item.i, 'value', text)} value={item.value} />
                                        </div>
                                    )
                                } else if (item.type == 'map') {
                                    return (
                                        <div onContextMenu={(e) => this.props.handleRightClick(item.i, e)} key={item.i} onClick={(e) => this.props.handleElementHover(e, item.i, true)} >
                                            {this.props.activeElement == item.i ? <Header removeElement={() => this.props.removeElement(item.i)} handleClassName={this.props.id ? `app-builder-drag-handle-${this.props.id}` : "app-builder-drag-handle"} /> : null}
                                            <Map props={item.props} style={item.style} onChange={(text) => this.props.changeElement(item.i, 'value', text)} value={item.value} />
                                        </div>
                                    )
                                } else if (item.type == 'mainInfo') {
                                    return (
                                        <div onContextMenu={(e) => this.props.handleRightClick(item.i, e)} key={item.i} onClick={(e) => this.props.handleElementHover(e, item.i, true)} >
                                            {this.props.activeElement == item.i ? <Header removeElement={() => this.props.removeElement(item.i)} handleClassName={this.props.id ? `app-builder-drag-handle-${this.props.id}` : "app-builder-drag-handle"} /> : null}
                                            <MainInfo mainData={this.props.mainData} props={item.props} style={item.style} onChange={(text) => this.props.changeElement(item.i, 'value', text)} value={item.value} lang={this.props.lang} />
                                        </div>
                                    )
                                }



                            })
                        }
                    </ResponsiveGridLayout>
                    :
                    null
                }
            </div>
        )
    }
}

export default Grid;