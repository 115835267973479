import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';


class Check extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    render() {
        return (
            <div className="toogle-switch">
                <label class='switch switch-1'>
                    <span className="switch-text">
                        {this.props.label}
                    </span>
                    <input type='checkbox' checked={this.props.value} onChange={this.props.onChange} className='switch-input' />
                    <span class='slider round'></span>
                </label>
            </div>

        );
    }
}

export default Check;