import React from 'react';
import { reduxForm } from 'redux-form'
import { renderFields } from './fields/fields';
import { Button } from 'reactstrap';


/**
* Generate form
* @author   Milan Stanojevic
*/
class form extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        const { handleSubmit } = this.props;
        return (
            <form onSubmit={handleSubmit}>

                {
                    renderFields(this.props.fields, this.props.lang, this.props.country, this.props.countryTwoLetter, this.props.selectedGroup, this.props.selectedClinic)
                }
                {
                    this.props.afterComponent ? this.props.afterComponent : null
                }
                {
                    this.props.invalidMessage && this.props.invalid && this.props.submitFailed ?
                        <div className='form-builder-invalid-message'>
                            <p>{this.props.invalidMessage}</p>
                        </div>
                        :
                        null
                }
                {
                    this.props.buttonsWrapClassName ?
                        <div className={this.props.buttonsWrapClassName ? this.props.buttonsWrapClassName : ''}>
                            {
                                this.props.additionalBeforeButton ?
                                    this.props.additionalBeforeButton : null
                            }
                            {
                                this.props.additionalAdditionalBeforeButton && this.props.additionalAdditionalBeforeButton
                            }
                            {
                                !this.props.hideSubmitButton ?
                                    <Button type="submit" color="primary" disabled={this.props.disabled}>{this.props.buttonText}</Button>
                                    :
                                    null
                            }

                            {
                                this.props.additionalButton ?
                                    this.props.additionalButton : null
                            }
                        </div>
                        :
                        !this.props.hideSubmitButton ?
                            <Button type="submit" color="primary" disabled={this.props.disabled}>{this.props.buttonText}</Button>
                            :
                            null
                }

                {
                    this.props.afterTextComponent ? this.props.afterTextComponent : null
                }


            </form>

        )
    }
}

export default reduxForm({
    form: 'form'  // a unique identifier for this form
})(form)
