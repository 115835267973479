import React, { Component } from 'react';
import { FormGroup, Label } from 'reactstrap';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

/**
* Phone field
* @author   Milan Stanojevic
*/
class Text extends Component {
    constructor(props) {
        super(props);

        this.state = {
            focus: false,
            phoneValue: ''
        };
    }


    render() {
        // console.log('phone field: ', this.props.country)
        return (
            <FormGroup check={this.props.type == 'checkbox'}>
                {this.props.label ? <Label check={this.props.type == 'checkbox'} size="sm" className={this.props.error ? "required form-group-label-wrap" : "form-group-label-wrap"}>{this.props.label}{this.props.haveValidation ? <span className="required-label-span">*</span> : null}</Label> : null}
                <div className={this.state.focus && this.props.error ? 'required-error-phone-hover required-error-phone' : this.props.error && !this.state.focus ? 'required-error-phone' : ''}>
                    <PhoneInput
                        onFocus={() => { this.setState({ focus: true }) }}
                        onBlur={() => { this.setState({ focus: false }) }}
                        disabled={this.props.disabled}
                        country={this.props.country === 'uk' && this.props.countryTwoLetter ? this.props.countryTwoLetter : this.props.country === 'denmark' ? 'dk' : this.props.country === 'uk' ? 'gb' : 'se'}
                        value={this.props.value}
                        enableLongNumbers={true}
                        autoFormat={true}
                        onChange={(e, data) => {

                            if (e[0] === '0' && e[1] === '0') {
                                e = '+' + e.substring(2);
                            }

                            if (e[0] === '0' && e[1] !== '0') {
                                e = '+' + data.dialCode + e.substring(1);
                            }

                            if (e.startsWith(data.dialCode + '0')) {
                                e = e.replace(data.dialCode + '0', data.dialCode);
                            }

                            // if (e.indexOf('+') !== 0) {
                            //     e = '+' + e;
                            // };

                            // if (e.indexOf(data.dialCode + '0') == 1) {
                            //     e = e.replace(data.dialCode + '0', data.dialCode)
                            // }

                            this.setState({
                                phoneValue: e
                            })

                            this.props.onChange(e)
                        }}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                if (this.state.phoneValue) {
                                    this.props.onKey(this.state.phoneValue)
                                }
                            }
                        }
                        }

                    />
                </div>


                {this.props.error ? <div className="required-error">{this.props.error}</div> : null}
            </FormGroup>
        );
    }
}

export default Text;
