
import React, { Component } from 'react';
import { API_ENDPOINT } from '../../constants';
import { v4 as uuidv4 } from 'uuid';

import { formatCSS } from './cssHelper';

import accountMobile from '../../assets/images/mobile-account.png';
import loginFormPNG from '../../assets/images/loginform.png';

import Map from '../../components/map_v2'

import MainClinicInfo from '../../components/mainClinicInfo'



/**
* Component for html field
* @author   Milan Stanojevic
*/

const convertToArray = (nodes, parent, layout, uData) => {
    let arr = [];
    for (var node in nodes) {
        if (nodes[node].parent == parent) {
            let show = true;
            if (!uData && nodes[node].hideLoggedOut) {
                show = false
            }
            if (uData && nodes[node].hideLoggedIn) {
                show = false
            }
            if (!show) {
                continue;
            }
            if (nodes[node].visibility && !nodes[node].visibility[layout]) {
                continue;
            }

            if (nodes[node].type == 'grid') {
                nodes[node].children = convertToArray(nodes, node, layout, uData);
            }

            arr.push({
                key: node,
                ...nodes[node]
            });
        }
    }

    return arr;
}

const sortNodes = (nodes, layout) => {
    nodes.sort((a, b) => {
        return (a[layout].y * 100 + a[layout].x) - (b[layout].y * 100 + b[layout].x);
    })

    for (let i = 0; i < nodes.length; i++) {
        if (nodes[i].type == 'grid' && nodes[i].children) {
            nodes[i].children = sortNodes(nodes[i].children, layout);
        }
    }

    return nodes;
}

const findMaxHInRow = (nodes, y, layout) => {
    let maxH = -1;
    for (let i = 0; i < nodes.length; i++) {
        if (nodes[i][layout].y == y && nodes[i][layout].h > maxH) {
            maxH = nodes[i][layout].h;
        }
    }

    return maxH;
}

const findElementsInRow = (nodes, y, maxH, layout) => {
    let elements = [];
    for (let i = 0; i < nodes.length; i++) {
        if (nodes[i][layout].y == y && (/*nodes[i][layout].y <= y+maxH && */nodes[i][layout].h <= maxH)) {
            elements.push(nodes[i]);
        }
    }

    return elements;


}
const findElementsInRow1 = (nodes, y, maxH, layout) => {
    let elements = [];
    for (let i = 0; i < nodes.length; i++) {
        if ((nodes[i][layout].y >= y && nodes[i][layout].y + nodes[i][layout].h <= y + maxH)) {
            elements.push(nodes[i]);
        }
    }

    return elements;


}


const findElementsWithMaxH = (nodes, maxH, layout) => {
    let elements = [];
    for (let i = 0; i < nodes.length; i++) {
        if (nodes[i][layout].h == maxH) {
            elements.push(nodes[i]);
        }
    }

    return elements;


}

const findSmallerElementsInRow = (nodes, maxH, layout) => {
    let res = {
        x: null,
        y: null,
        width: null,
        height: null,
        elements: []
    };

    let minX = Infinity;
    let maxX = 0;
    let minY = Infinity;
    let maxY = 0;

    for (let i = 0; i < nodes.length; i++) {
        if (nodes[i][layout].h < maxH) {
            res.elements.push(nodes[i]);

            if (nodes[i][layout].x < minX) {
                minX = nodes[i][layout].x;
            }
            if (nodes[i][layout].y < minY) {
                minY = nodes[i][layout].y;
            }
            if (nodes[i][layout].x + nodes[i][layout].w > maxX) {
                maxX = nodes[i][layout].x + nodes[i][layout].w;
            }
            if (nodes[i][layout].y + nodes[i][layout].h > maxY) {
                maxY = nodes[i][layout].y + nodes[i][layout].h;
            }

        }
    }

    res.width = maxX - minX;
    res.height = maxY - minY;
    res.x = minX;
    res.y = minY;

    return res;
}


const calculateNewDimensionsForElements = (res, layout) => {
    let ratio = 100 / res.width;

    for (let i = 0; i < res.elements.length; i++) {
        res.elements[i][layout].w = res.elements[i][layout].w * ratio;
        res.elements[i][layout].x = (res.elements[i][layout].x - res.x) * ratio;
        res.elements[i][layout].y = res.elements[i][layout].y - res.y;
    }

    return res.elements;

}

const group = (nodes, layout) => {
    let y = 0;
    while (true) {
        let maxH = findMaxHInRow(nodes, y, layout);

        if (maxH == -1) {
            break;
        }

        let elements = findElementsInRow1(nodes, y, maxH, layout);
        if (elements.length == 0) {
            break;
        }


        let elementsToGroup = findSmallerElementsInRow(elements, maxH, layout);
        // console.log(elements.length, elementsToGroup.elements.length)

        if (elementsToGroup.elements.length) {
            // console.log(elementsToGroup.elements)
            elementsToGroup.elements = calculateNewDimensionsForElements(elementsToGroup, layout);
            //let maxHElements = findElementsWithMaxH(elements, maxH, layout);



            let gridElement = {
                key: uuidv4(),
                _isNewGrid: true,
                type: 'grid',
                parent: 'root',

                lg: { x: elementsToGroup.x, y: y, w: elementsToGroup.width, h: elementsToGroup.height + ((elementsToGroup.elements.length * 4) / 10) },
                sm: { x: elementsToGroup.x, y: y, w: elementsToGroup.width, h: elementsToGroup.height + ((elementsToGroup.elements.length * 4) / 10) },
                xs: { x: elementsToGroup.x, y: y, w: elementsToGroup.width, h: elementsToGroup.height + ((elementsToGroup.elements.length * 4) / 10) },
                app: { x: elementsToGroup.x, y: y, w: elementsToGroup.width, h: elementsToGroup.height + ((elementsToGroup.elements.length * 4) / 10) },

                style: { marginTop: '0', marginBottom: '0' },
                responsiveStyle: {
                    sm: {},
                    xs: {},
                    app: {}
                },
                props: {},
                responsiveprops: {
                    sm: {},
                    xs: {},
                    app: {}
                },
                children: elementsToGroup.elements
            };


            let doNotIncludeElementKeys = elementsToGroup.elements.map((el) => el.key);
            let elementKeys = elements.map((el) => el.key);

            // console.log(doNotIncludeElementKeys);
            // recreate array
            let newArr = [];
            let found = false;
            for (let i = 0; i < nodes.length; i++) {

                if (elementKeys.indexOf(nodes[i].key) !== -1 && doNotIncludeElementKeys.indexOf(nodes[i].key) == -1) {
                    nodes[i].lg.h += (4 / 10);
                    nodes[i].sm.h += (4 / 10);
                    nodes[i].xs.h += (4 / 10);
                    if (nodes[i].app && nodes[i].app.h)
                        nodes[i].app.h += (4 / 10);
                }
                if (doNotIncludeElementKeys.indexOf(nodes[i].key) != -1) {
                    nodes[i].parent = gridElement.key;
                }

                if (doNotIncludeElementKeys.indexOf(nodes[i].key) == -1) {
                    newArr.push(nodes[i]);
                } else if (!found) {
                    newArr.push(gridElement);
                    found = true;
                }
            }
            // console.log(newArr.length)

            nodes = newArr;
            break;
        }



        y += maxH;

    }

    for (let i = 0; i < nodes.length; i++) {
        if (nodes[i] && nodes[i].children) {
            nodes[i].children = group(nodes[i].children, layout);
        }
    }

    return nodes;
}

const fillGaps = (nodes, layout) => {
    let y = 0;
    while (true) {
        let maxH = findMaxHInRow(nodes, y, layout);

        if (maxH == -1) {
            break;
        }

        let elements = findElementsInRow1(nodes, y, maxH, layout);
        if (elements.length == 0) {
            break;
        }

        // console.log(elements.length, elements);

        for (let i = 0; i < elements.length; i++) {
            if (i == 0 && elements[i][layout].x != 0) {
                elements[i].style.marginLeft = elements[i][layout].x + '%';
            } else if (i != 0 && elements[i][layout].x != (elements[i - 1][layout].x + elements[i - 1][layout].w)) {
                elements[i].style.marginLeft = elements[i][layout].x - (elements[i - 1][layout].x + elements[i - 1][layout].w) + '%';
                // console.log(elements, elements[i].style.marginLeft)

            }

            for (let j = 0; j < nodes.length; j++) {
                if (nodes[j].key == elements[i].key) {
                    nodes[j] = elements[i];
                }
            }
        }




        y += maxH;

    }

    for (let i = 0; i < nodes.length; i++) {
        if (nodes[i] && nodes[i].children) {
            nodes[i].children = fillGaps(nodes[i].children, layout);
        }
    }


    return nodes;
}


const groupNodes = (nodesObj, parent, layout, uData) => {
    let nodes = convertToArray(nodesObj, parent, layout, uData);
    // first sort nodes by position

    nodes = sortNodes(nodes, layout);

    nodes = group(nodes, layout);

    nodes = fillGaps(JSON.parse(JSON.stringify(nodes)), layout)


    //nodes = sortNodes(JSON.parse(JSON.stringify(nodes)), layout);
    return nodes;

}



class ToReact extends Component {
    constructor(props) {
        super(props);

        this.state = {
            updateNeeded: uuidv4()
        };
    }

    componentDidMount() {
        if (this.props.data && this.props.data.mainClinicGroup) {
            let element = document.getElementById('react-converter-v2-id');

            if (element && element.children && element.children[0]) {
                let firstChild = element.children[0];
                firstChild.style.maxWidth = '100%'
                firstChild.style.flex = '0 0 100%'
                firstChild.style.height = '100vh';
                firstChild.style.padding = '0';

                if (firstChild && firstChild.firstChild) {
                    firstChild.firstChild.style.overflowY = 'auto';
                }
                // console.log(element.children[0].getAttributes())
                // element.children[0].setAttribute("style", "max-width: 100%;");
            }


            // element.firstChild.setAt
        }
    }
    goToLink = (props) => {
        let protocol = props.protocol ? props.protocol : 'https://';
        if (typeof window != 'undefined') {
            let link = protocol + props.link;
            window.open(link, '_blank')
        }
    }

    filterStyle = (style) => {
        let newStyle = {};
        for (var key in style) {
            if (style[key] != "") {
                newStyle[key] = style[key];
            }
        }

        return newStyle;
    }


    renderNodes = (items) => {

        let nodes = [];

        for (let i = 0; i < items.length; i++) {

            if (!items[i]) {
                continue;
            }




            let style = {};
            let activeStyle = {};
            if (items[i].style) {
                style = { ...this.filterStyle(items[i].style) };
            }
            if (items[i].activeStyle) {
                activeStyle = { ...this.filterStyle(items[i].activeStyle) };
            }





            if (this.props.layout == 'sm' && items[i].responsiveStyle && items[i].responsiveStyle.sm) {
                style = { ...style, ...this.filterStyle(items[i].responsiveStyle.sm) };

            }
            if (this.props.layout == 'xs' && items[i].responsiveStyle && items[i].responsiveStyle.xs) {
                style = { ...style, ...this.filterStyle(items[i].responsiveStyle.xs) };
            }
            if (this.props.layout == 'app' && items[i].responsiveStyle && items[i].responsiveStyle.app) {
                style = { ...style, ...this.filterStyle(items[i].responsiveStyle.app) };
            }



            if (!style.marginBottom) {
                style.marginBottom = 1;
            }
            if (!style.marginTop) {
            }

            if (!style.marginLeft) {
                style.marginLeft = 2;
            }
            if (!style.marginRight) {
                style.marginRight = 2;
            }

            if (style && style.boxShadow) {
                let boxShadow = style.boxShadow;
                let boxShadowSplit = boxShadow.split('rgba')
                let boxShadowSplitLeft = boxShadowSplit[0].split(' ')
                let newValue = '';
                for (let i = 0; i < 4; i++) {
                    newValue += boxShadowSplitLeft[i].indexOf('px') == -1 ? boxShadowSplitLeft[i] + 'px' : boxShadowSplitLeft[i];
                    newValue += ' ';
                }
                newValue += `rgba${boxShadowSplit[1]}`
                style.boxShadow = newValue;
            }

            if (activeStyle && activeStyle.boxShadow) {
                let boxShadow = activeStyle.boxShadow;
                let boxShadowSplit = boxShadow.split('rgba')
                let boxShadowSplitLeft = boxShadowSplit[0].split(' ')
                let newValue = '';
                for (let i = 0; i < 4; i++) {
                    newValue += boxShadowSplitLeft[i].indexOf('px') == -1 ? boxShadowSplitLeft[i] + 'px' : boxShadowSplitLeft[i];
                    newValue += ' ';
                }
                newValue += `rgba${boxShadowSplit[1]}`
                activeStyle.boxShadow = newValue;
            }



            let props = {};

            if (items[i].props) {
                props = { ...items[i].props };
            }
            let activeProps = {};

            if (items[i].activeProps) {
                activeProps = { ...items[i].activeProps };
            }

            if (this.props.layout == 'sm' && items[i].responsiveProps && items[i].responsiveProps.sm) {
                props = { ...props, ...items[i].responsiveProps.sm };

            }
            if (this.props.layout == 'xs' && items[i].responsiveProps && items[i].responsiveProps.xs) {
                props = { ...props, ...items[i].responsiveProps.xs };
            }
            if (this.props.layout == 'app' && items[i].responsiveProps && items[i].responsiveProps.app) {
                props = { ...props, ...items[i].responsiveProps.app };
            }


            nodes.push({
                ...items[i],
                style: style,
                activeStyle: activeStyle,
                activeProps: activeProps,
                props: props
            })

        }

        return nodes.map((node, idx) => {

            let formattedStyle = formatCSS(node.style);
            let style = { ...formattedStyle };
            let formattedStyleActive = formatCSS(node.activeStyle);
            let activeStyle = { ...formattedStyleActive };
            style.height = '100%';
            style.width = '100%';

            let wrapStyle = { height: node[this.props.layout].h * 10, flex: `0 0 ${node[this.props.layout].w}%`, maxWidth: !formattedStyle.maxWidth ? `${node[this.props.layout].w}%` : formattedStyle.maxWidth }

            if (style.marginLeft) {
                if (style.marginLeft != 'auto') {
                    if (style.marginLeft == '2px') {
                        wrapStyle.paddingLeft = style.marginLeft;

                    } else {
                        wrapStyle.marginLeft = style.marginLeft;

                    }
                    // if (style.marginLeft.indexOf && style.marginLeft.indexOf('%') !== -1) {
                    //     wrapStyle.maxWidth = !formattedStyle.maxWidth ? `${node[this.props.layout].w + parseInt(style.marginLeft.replace('%', ''))}%` : formattedStyle.maxWidth
                    //     wrapStyle.flex = `0 0 ${wrapStyle.maxWidth}%`
                    // }

                } else {
                    wrapStyle.marginLeft = 'auto';
                    delete style.height;
                    delete style.width;

                }

                delete style.marginLeft;
            }
            if (style.marginRight) {
                if (style.marginRight != 'auto') {
                    wrapStyle.paddingRight = style.marginRight;
                } else {
                    wrapStyle.marginRight = 'auto';
                    delete style.height;
                    delete style.width;

                } delete style.marginRight;
            }

            if (style.marginTop) {
                wrapStyle.paddingTop = style.marginTop;
                delete style.marginTop;
            }

            if (style.marginBottom) {
                wrapStyle.paddingBottom = style.marginBottom;
                delete style.marginBottom;
            }
            if (style.lineHeight) {
                style.lineHeight = style.lineHeight + 'px';
            }
            // let wrapStyleActive = { ...wrapStyle, ...activeStyle }
            if (node.type == 'login') {
                wrapStyle.height = 'unset';
                if (style) {
                    style.justifyContent = 'center';
                }
            }

            if (this.props.createNewCasePage) {
                delete wrapStyle.marginLeft;
                delete wrapStyle.marginRight;
                delete wrapStyle.marginTop;
                delete wrapStyle.marginBottom;

                if (this.props.layout == 'xs') {
                    wrapStyle.marginTop = '5px';
                    wrapStyle.marginBottom = '5px';
                }
            }
            if (node.type === 'grid' && this.props.createNewCasePage) {
                style.justifyContent = 'center'
            }
            if (node.type == 'text') {
                return (
                    <div style={wrapStyle}

                        onMouseEnter={() => {
                            let elementStyle = JSON.parse(JSON.stringify(activeStyle))
                            Object.keys(elementStyle).map(function (key, index) {
                                if (document.getElementById(node.key) && document.getElementById(node.key).style)
                                    document.getElementById(node.key).style[`${key}`] = elementStyle[key];
                            });

                        }}
                        onMouseLeave={() => {
                            let elementStyle = JSON.parse(JSON.stringify(activeStyle))
                            Object.keys(elementStyle).map(function (key, index) {
                                if (document.getElementById(node.key) && document.getElementById(node.key).style)
                                    document.getElementById(node.key).style[`${key}`] = 'unset';
                            });
                            elementStyle = JSON.parse(JSON.stringify(style))
                            Object.keys(elementStyle).map(function (key, index) {
                                if (document.getElementById(node.key) && document.getElementById(node.key).style)
                                    document.getElementById(node.key).style[`${key}`] = elementStyle[key];
                            });
                        }}
                    >

                        <div className={`node-${node.type}`} id={node.key} style={style} dangerouslySetInnerHTML={{ __html: node.value }}></div>
                    </div>
                )
            } else if (node.type == 'image') {

                return (<div style={wrapStyle}
                    onMouseEnter={() => {

                        let elementStyle = JSON.parse(JSON.stringify(activeStyle))
                        Object.keys(elementStyle).map(function (key, index) {
                            if (document.getElementById(node.key) && document.getElementById(node.key).style)
                                document.getElementById(node.key).style[`${key}`] = elementStyle[key];
                        });
                    }}
                    onMouseLeave={() => {
                        let elementStyle = JSON.parse(JSON.stringify(activeStyle))
                        Object.keys(elementStyle).map(function (key, index) {
                            if (document.getElementById(node.key) && document.getElementById(node.key).style)
                                document.getElementById(node.key).style[`${key}`] = 'unset';
                        });
                        elementStyle = JSON.parse(JSON.stringify(style))
                        Object.keys(elementStyle).map(function (key, index) {
                            if (document.getElementById(node.key) && document.getElementById(node.key).style)
                                document.getElementById(node.key).style[`${key}`] = elementStyle[key];
                        });
                    }}
                >

                    <img className={`node-${node.type}`} id={node.key} style={style} src={API_ENDPOINT + node.value}></img>
                </div>
                )
            } else if (node.type == 'button') {
                if (node.props.type == "login" && this.props.uData) {
                    return;
                }

                let iconStyle = {
                    width: 36,
                    height: 36,
                    objectFit: 'contain'
                };


                if (node.props) {
                    if (node.props.iconWidth) {
                        iconStyle.width = node.props.iconWidth + 'px';
                    }
                    if (node.props.iconHeight) {
                        iconStyle.height = node.props.iconHeight + 'px';
                    }
                    if (node.props.iconMarginLeft) {
                        iconStyle.marginLeft = node.props.iconMarginLeft + 'px';
                    }
                    if (node.props.iconMarginTop) {
                        iconStyle.marginTop = node.props.iconMarginTop + 'px';
                    }
                    if (node.props.iconMarginRight) {
                        iconStyle.marginRight = node.props.iconMarginRight + 'px';
                    }
                    if (node.props.iconMarginBottom) {
                        iconStyle.marginBottom = node.props.iconMarginBottom + 'px';
                    }

                }
                let iconStyleWrap = iconStyle;
                let icon = null
                if (node.props && node.props.icon && node.props.icon != 'null')
                    icon = API_ENDPOINT + node.props.icon;
                if (this.props.createNewCasePage && style) {
                    style.display = 'flex';
                    style.alignItems = 'center';
                    style.justifyContent = 'center';
                    style.wordBreak = 'break-word';
                }

                return (
                    (this.props.dropInButtonShow && node.props.type == "dropIn") || node.props.type != "dropIn" ?
                        <div style={wrapStyle}
                            onMouseEnter={() => {
                                let elementStyle = JSON.parse(JSON.stringify(activeStyle))
                                Object.keys(elementStyle).map(function (key, index) {
                                    if (document.getElementById(node.key) && document.getElementById(node.key).style)
                                        document.getElementById(node.key).style[`${key}`] = elementStyle[key];
                                });

                                let iconStyleActive = JSON.parse(JSON.stringify(iconStyleWrap))
                                if (node.activeProps) {
                                    if (node.activeProps.iconWidth) {
                                        iconStyleActive.width = node.activeProps.iconWidth + 'px';
                                    }
                                    if (node.activeProps.iconHeight) {
                                        iconStyleActive.height = node.activeProps.iconHeight + 'px';
                                    }
                                    if (node.activeProps.iconMarginLeft) {
                                        iconStyleActive.marginLeft = node.activeProps.iconMarginLeft + 'px';
                                    }
                                    if (node.activeProps.iconMarginTop) {
                                        iconStyleActive.marginTop = node.activeProps.iconMarginTop + 'px';
                                    }
                                    if (node.activeProps.iconMarginRight) {
                                        iconStyleActive.marginRight = node.activeProps.iconMarginRight + 'px';
                                    }
                                    if (node.activeProps.iconMarginBottom) {
                                        iconStyleActive.marginBottom = node.activeProps.iconMarginBottom + 'px';
                                    }
                                    if (node.activeProps.icon && node.activeProps.icon != 'null') {
                                        icon = API_ENDPOINT + node.activeProps.icon;
                                        if (document.getElementById(node.key + 'image'))
                                            document.getElementById(node.key + 'image').src = icon
                                    }
                                }
                                elementStyle = JSON.parse(JSON.stringify(iconStyleActive))
                                Object.keys(elementStyle).map(function (key, index) {
                                    if (document.getElementById(node.key + 'image') && document.getElementById(node.key + 'image').style)
                                        document.getElementById(node.key + 'image').style[`${key}`] = elementStyle[key];
                                });
                                iconStyleWrap = iconStyleActive

                            }}
                            onMouseLeave={() => {
                                let elementStyle = JSON.parse(JSON.stringify(activeStyle))
                                Object.keys(elementStyle).map(function (key, index) {
                                    if (document.getElementById(node.key) && document.getElementById(node.key).style)
                                        document.getElementById(node.key).style[`${key}`] = 'unset';
                                });
                                elementStyle = JSON.parse(JSON.stringify(style))
                                Object.keys(elementStyle).map(function (key, index) {
                                    if (document.getElementById(node.key) && document.getElementById(node.key).style)
                                        document.getElementById(node.key).style[`${key}`] = elementStyle[key];
                                });


                                let iconStyle = JSON.parse(JSON.stringify(iconStyleWrap))
                                if (node.activeProps) {
                                    if (node.activeProps.iconWidth) {
                                        iconStyle.width = node.activeProps.iconWidth + 'px';
                                    }
                                    if (node.activeProps.iconHeight) {
                                        iconStyle.height = node.activeProps.iconHeight + 'px';
                                    }
                                    if (node.activeProps.iconMarginLeft) {
                                        iconStyle.marginLeft = node.activeProps.iconMarginLeft + 'px';
                                    }
                                    if (node.activeProps.iconMarginTop) {
                                        iconStyle.marginTop = node.activeProps.iconMarginTop + 'px';
                                    }
                                    if (node.activeProps.iconMarginRight) {
                                        iconStyle.marginRight = node.activeProps.iconMarginRight + 'px';
                                    }
                                    if (node.activeProps.iconMarginBottom) {
                                        iconStyle.marginBottom = node.activeProps.iconMarginBottom + 'px';
                                    }
                                }
                                Object.keys(iconStyle).map(function (key, index) {
                                    iconStyle[`${key}`] = 'unset';
                                });
                                iconStyle = {
                                    width: '36px',
                                    height: '36px',
                                    objectFit: 'contain'
                                };


                                if (node.props) {
                                    if (node.props.iconWidth) {
                                        iconStyle.width = node.props.iconWidth + 'px';
                                    }
                                    if (node.props.iconHeight) {
                                        iconStyle.height = node.props.iconHeight + 'px';
                                    }
                                    if (node.props.iconMarginLeft) {
                                        iconStyle.marginLeft = node.props.iconMarginLeft + 'px';
                                    }
                                    if (node.props.iconMarginTop) {
                                        iconStyle.marginTop = node.props.iconMarginTop + 'px';
                                    }
                                    if (node.props.iconMarginRight) {
                                        iconStyle.marginRight = node.props.iconMarginRight + 'px';
                                    }
                                    if (node.props.iconMarginBottom) {
                                        iconStyle.marginBottom = node.props.iconMarginBottom + 'px';
                                    }

                                    if (node.props.icon && node.props.icon != 'null') {
                                        icon = API_ENDPOINT + node.props.icon;
                                        if (document.getElementById(node.key + 'image'))
                                            document.getElementById(node.key + 'image').src = icon
                                    }

                                }
                                elementStyle = JSON.parse(JSON.stringify(iconStyle))
                                Object.keys(elementStyle).map(function (key, index) {
                                    // console.log(elementStyle[key])
                                    if (document.getElementById(node.key + 'image') && document.getElementById(node.key + 'image').style)
                                        document.getElementById(node.key + 'image').style[`${key}`] = elementStyle[key];
                                });
                                iconStyleWrap = iconStyle;

                            }}
                        >


                            <button key={node.key} id={node.key} onClick={() => {
                                if (node.props.type) {
                                    if (node.props.type == "seekCare") {
                                        this.props.seekCareHandler();
                                    } else if (node.props.type == "information") {
                                        this.props.informationHandler();

                                    } else if (node.props.type == "myReferral") {
                                        this.props.myReferralHandler();

                                    } else if (node.props.type == "dropIn") {
                                        this.props.dropInHandler();

                                    } else if (node.props.type == "chooseTime") {
                                        this.props.chooseTimeHandle();
                                    } else if (node.props.type == "externalLink" && node.props.link) {
                                        this.goToLink(node.props);
                                    } else if (node.props.type == "daybook") {
                                        this.props.daybookHandler();

                                    } else if (node.props.type == "login") {
                                        if (this.props.loginHandler)
                                            this.props.loginHandler();
                                    } else if (node.props.type == "listing") {
                                        if (this.props[0].match.params.alias) {
                                            this.props[0].history.push(`/${this.props[0].match.params.alias}/listing`);
                                        }
                                    } else if (node.props.type === 'waitingRoom') {
                                        this.props.waitingRoomHandler();
                                    }

                                }
                            }}
                                className={`node-${node.type} ${this.props.createNewCasePage && this.props.layout == 'xs' ? 'land-build-center-text' : ''}`} style={{ border: 0, ...style, outline: 'none' }}
                            >
                                {
                                    node.props && node.props.icon && node.props.icon != 'null' && !this.props.createNewCasePage ?
                                        <img style={iconStyleWrap} id={node.key + 'image'} src={icon} />
                                        :
                                        null
                                }
                                <div dangerouslySetInnerHTML={{ __html: node.value }}></div>
                            </button>
                        </div>
                        :
                        null

                )
            } else if (node.type == 'button2') {

                let iconStyle = {
                    width: 36,
                    height: 36,
                    objectFit: 'contain'
                };


                if (node.props) {
                    if (node.props.iconWidth) {
                        iconStyle.width = node.props.iconWidth + 'px';
                    }
                    if (node.props.iconHeight) {
                        iconStyle.height = node.props.iconHeight + 'px';
                    }
                    if (node.props.iconMarginLeft) {
                        iconStyle.marginLeft = node.props.iconMarginLeft + 'px';
                    }
                    if (node.props.iconMarginTop) {
                        iconStyle.marginTop = node.props.iconMarginTop + 'px';
                    }
                    if (node.props.iconMarginRight) {
                        iconStyle.marginRight = node.props.iconMarginRight + 'px';
                    }
                    if (node.props.iconMarginBottom) {
                        iconStyle.marginBottom = node.props.iconMarginBottom + 'px';
                    }

                }
                let iconStyleWrap = iconStyle;
                let icon = null
                if (node.props && node.props.icon && node.props.icon != 'null')
                    icon = API_ENDPOINT + node.props.icon;


                if (this.props.createNewCasePage && style) {
                    style.display = 'flex';
                    style.alignItems = 'center';
                    style.justifyContent = 'center';
                    style.wordBreak = 'break-word';
                }

                return (
                    (this.props.dropInButtonShow && node.props.type == "dropIn") || node.props.type != "dropIn" ?
                        <div style={wrapStyle}
                            onMouseEnter={() => {
                                let elementStyle = JSON.parse(JSON.stringify(activeStyle))
                                Object.keys(elementStyle).map(function (key, index) {
                                    if (document.getElementById(node.key) && document.getElementById(node.key).style)
                                        document.getElementById(node.key).style[`${key}`] = elementStyle[key];
                                });

                                let iconStyleActive = JSON.parse(JSON.stringify(iconStyleWrap))
                                if (node.activeProps) {
                                    if (node.activeProps.iconWidth) {
                                        iconStyleActive.width = node.activeProps.iconWidth + 'px';
                                    }
                                    if (node.activeProps.iconHeight) {
                                        iconStyleActive.height = node.activeProps.iconHeight + 'px';
                                    }
                                    if (node.activeProps.iconMarginLeft) {
                                        iconStyleActive.marginLeft = node.activeProps.iconMarginLeft + 'px';
                                    }
                                    if (node.activeProps.iconMarginTop) {
                                        iconStyleActive.marginTop = node.activeProps.iconMarginTop + 'px';
                                    }
                                    if (node.activeProps.iconMarginRight) {
                                        iconStyleActive.marginRight = node.activeProps.iconMarginRight + 'px';
                                    }
                                    if (node.activeProps.iconMarginBottom) {
                                        iconStyleActive.marginBottom = node.activeProps.iconMarginBottom + 'px';
                                    }
                                    if (node.activeProps.icon && node.activeProps.icon != 'null') {
                                        icon = API_ENDPOINT + node.activeProps.icon;
                                        if (document.getElementById(node.key + 'image'))
                                            document.getElementById(node.key + 'image').src = icon
                                    }
                                }
                                elementStyle = JSON.parse(JSON.stringify(iconStyleActive))
                                Object.keys(elementStyle).map(function (key, index) {
                                    if (document.getElementById(node.key + 'image') && document.getElementById(node.key + 'image').style)
                                        document.getElementById(node.key + 'image').style[`${key}`] = elementStyle[key];
                                });
                                iconStyleWrap = iconStyleActive

                            }}
                            onMouseLeave={() => {
                                let elementStyle = JSON.parse(JSON.stringify(activeStyle))
                                Object.keys(elementStyle).map(function (key, index) {
                                    if (document.getElementById(node.key) && document.getElementById(node.key).style)
                                        document.getElementById(node.key).style[`${key}`] = 'unset';
                                });
                                elementStyle = JSON.parse(JSON.stringify(style))
                                Object.keys(elementStyle).map(function (key, index) {
                                    if (document.getElementById(node.key) && document.getElementById(node.key).style)
                                        document.getElementById(node.key).style[`${key}`] = elementStyle[key];
                                });


                                let iconStyle = JSON.parse(JSON.stringify(iconStyleWrap))
                                if (node.activeProps) {
                                    if (node.activeProps.iconWidth) {
                                        iconStyle.width = node.activeProps.iconWidth + 'px';
                                    }
                                    if (node.activeProps.iconHeight) {
                                        iconStyle.height = node.activeProps.iconHeight + 'px';
                                    }
                                    if (node.activeProps.iconMarginLeft) {
                                        iconStyle.marginLeft = node.activeProps.iconMarginLeft + 'px';
                                    }
                                    if (node.activeProps.iconMarginTop) {
                                        iconStyle.marginTop = node.activeProps.iconMarginTop + 'px';
                                    }
                                    if (node.activeProps.iconMarginRight) {
                                        iconStyle.marginRight = node.activeProps.iconMarginRight + 'px';
                                    }
                                    if (node.activeProps.iconMarginBottom) {
                                        iconStyle.marginBottom = node.activeProps.iconMarginBottom + 'px';
                                    }
                                }
                                Object.keys(iconStyle).map(function (key, index) {
                                    iconStyle[`${key}`] = 'unset';
                                });
                                iconStyle = {
                                    width: '36px',
                                    height: '36px',
                                    objectFit: 'contain'
                                };


                                if (node.props) {
                                    if (node.props.iconWidth) {
                                        iconStyle.width = node.props.iconWidth + 'px';
                                    }
                                    if (node.props.iconHeight) {
                                        iconStyle.height = node.props.iconHeight + 'px';
                                    }
                                    if (node.props.iconMarginLeft) {
                                        iconStyle.marginLeft = node.props.iconMarginLeft + 'px';
                                    }
                                    if (node.props.iconMarginTop) {
                                        iconStyle.marginTop = node.props.iconMarginTop + 'px';
                                    }
                                    if (node.props.iconMarginRight) {
                                        iconStyle.marginRight = node.props.iconMarginRight + 'px';
                                    }
                                    if (node.props.iconMarginBottom) {
                                        iconStyle.marginBottom = node.props.iconMarginBottom + 'px';
                                    }

                                    if (node.props.icon && node.props.icon != 'null') {
                                        icon = API_ENDPOINT + node.props.icon;
                                        if (document.getElementById(node.key + 'image'))
                                            document.getElementById(node.key + 'image').src = icon
                                    }

                                }
                                elementStyle = JSON.parse(JSON.stringify(iconStyle))
                                Object.keys(elementStyle).map(function (key, index) {
                                    // console.log(elementStyle[key])
                                    if (document.getElementById(node.key + 'image') && document.getElementById(node.key + 'image').style)
                                        document.getElementById(node.key + 'image').style[`${key}`] = elementStyle[key];
                                });
                                iconStyleWrap = iconStyle;

                            }}
                        >


                            {/* <button className={`node-${node.type}`} style={{ border: 0, outline: 'none', ...style }} id={node.key}>
                                {
                                    node.props && icon ?
                                        <img style={iconStyleWrap} id={node.key + 'image'} src={icon} />
                                        :
                                        null
                                }
                                <div dangerouslySetInnerHTML={{ __html: node.value }}></div>
                            </button> */}
                            <button key={node.key} id={node.key} onClick={() => {
                                if (node.props.type) {
                                    if (node.props.type == "seekCare") {
                                        this.props.seekCareHandler();
                                    } else if (node.props.type == "information") {
                                        this.props.informationHandler();

                                    } else if (node.props.type == "myReferral") {
                                        this.props.myReferralHandler();

                                    } else if (node.props.type == "dropIn") {
                                        this.props.dropInHandler();

                                    } else if (node.props.type == "chooseTime") {
                                        this.props.chooseTimeHandle();
                                    } else if (node.props.type == "externalLink" && node.props.link) {
                                        this.goToLink(node.props.link);
                                    } else if (node.props.type == "login") {
                                        if (this.props.loginHandler)
                                            this.props.loginHandler();
                                    } else if (node.props.type == "listing") {
                                        if (this.props[0].match.params.alias) {
                                            this.props[0].history.push(`/${this.props[0].match.params.alias}/listing`);
                                        }
                                    } else if (node.props.type === 'waitingRoom') {
                                        this.props.waitingRoomHandler();
                                    }
                                }
                            }}
                                className={`node-${node.type} ${this.props.createNewCasePage && this.props.layout == 'xs' ? 'land-build-center-text' : ''}`} style={{ border: 0, ...style, outline: 'none' }}
                            >
                                {
                                    node.props && node.props.icon && node.props.icon != 'null' && !this.props.createNewCasePage ?
                                        <img style={iconStyleWrap} id={node.key + 'image'} src={icon} />
                                        :
                                        null
                                }
                                <div dangerouslySetInnerHTML={{ __html: node.value }}></div>
                            </button>
                        </div>
                        :
                        null
                )
            } else if (node.type == 'grid' && node.children) {
                if (this.props.layout == 'lg' && wrapStyle.maxWidth == '1140px' && wrapStyle.marginLeft == 'auto' && wrapStyle.marginRight == 'auto') {
                    return (
                        <div style={{ width: '100%' }}>
                            <div style={wrapStyle}
                                onMouseEnter={() => {
                                    let elementStyle = JSON.parse(JSON.stringify(activeStyle))
                                    Object.keys(elementStyle).map(function (key, index) {
                                        if (document.getElementById(node.key) && document.getElementById(node.key).style)
                                            document.getElementById(node.key).style[`${key}`] = elementStyle[key];
                                    });
                                }}
                                onMouseLeave={() => {
                                    let elementStyle = JSON.parse(JSON.stringify(activeStyle))
                                    Object.keys(elementStyle).map(function (key, index) {
                                        if (document.getElementById(node.key) && document.getElementById(node.key).style)
                                            document.getElementById(node.key).style[`${key}`] = 'unset';
                                    });
                                    elementStyle = JSON.parse(JSON.stringify(style))
                                    Object.keys(elementStyle).map(function (key, index) {
                                        if (document.getElementById(node.key) && document.getElementById(node.key).style)
                                            document.getElementById(node.key).style[`${key}`] = elementStyle[key];
                                    });
                                }}

                            >

                                <div className={`node-${node.type}`} id={node.key} style={{ ...style, display: 'flex', flexWrap: 'wrap' }}>
                                    {this.renderNodes(node.children)}
                                </div>
                            </div>
                        </div>
                    )
                } else {
                    return (
                        <div style={wrapStyle}
                            onMouseEnter={() => {
                                let elementStyle = JSON.parse(JSON.stringify(activeStyle))
                                Object.keys(elementStyle).map(function (key, index) {
                                    if (document.getElementById(node.key) && document.getElementById(node.key).style)
                                        document.getElementById(node.key).style[`${key}`] = elementStyle[key];
                                });
                            }}
                            onMouseLeave={() => {
                                let elementStyle = JSON.parse(JSON.stringify(activeStyle))
                                Object.keys(elementStyle).map(function (key, index) {
                                    if (document.getElementById(node.key) && document.getElementById(node.key).style)
                                        document.getElementById(node.key).style[`${key}`] = 'unset';
                                });
                                elementStyle = JSON.parse(JSON.stringify(style))
                                Object.keys(elementStyle).map(function (key, index) {
                                    if (document.getElementById(node.key) && document.getElementById(node.key).style)
                                        document.getElementById(node.key).style[`${key}`] = elementStyle[key];
                                });
                            }}

                        >

                            <div className={`node-${node.type}`} id={node.key} style={{ ...style, display: 'flex', flexWrap: 'wrap' }}>
                                {this.renderNodes(node.children)}
                            </div>
                        </div>
                    )
                }

            } else if (node.type == 'login') {
                return (
                    <div style={wrapStyle}
                        onMouseEnter={() => {
                            let elementStyle = JSON.parse(JSON.stringify(activeStyle))
                            Object.keys(elementStyle).map(function (key, index) {
                                if (document.getElementById(node.key) && document.getElementById(node.key).style)
                                    document.getElementById(node.key).style[`${key}`] = elementStyle[key];
                            });
                        }}
                        onMouseLeave={() => {
                            let elementStyle = JSON.parse(JSON.stringify(activeStyle))
                            Object.keys(elementStyle).map(function (key, index) {
                                if (document.getElementById(node.key) && document.getElementById(node.key).style)
                                    document.getElementById(node.key).style[`${key}`] = 'unset';
                            });
                            elementStyle = JSON.parse(JSON.stringify(style))
                            Object.keys(elementStyle).map(function (key, index) {
                                if (document.getElementById(node.key) && document.getElementById(node.key).style)
                                    document.getElementById(node.key).style[`${key}`] = elementStyle[key];
                            });
                        }}
                    >
                        <div style={style} id={node.key}>
                            {this.props.loginComponent}
                        </div>
                    </div>
                )
            } else if (node.type == 'account') {
                return (
                    <div style={wrapStyle}
                        onMouseEnter={() => {
                            let elementStyle = JSON.parse(JSON.stringify(activeStyle))
                            Object.keys(elementStyle).map(function (key, index) {
                                if (document.getElementById(node.key) && document.getElementById(node.key).style)
                                    document.getElementById(node.key).style[`${key}`] = elementStyle[key];
                            });
                        }}
                        onMouseLeave={() => {
                            let elementStyle = JSON.parse(JSON.stringify(activeStyle))
                            Object.keys(elementStyle).map(function (key, index) {
                                if (document.getElementById(node.key) && document.getElementById(node.key).style)
                                    document.getElementById(node.key).style[`${key}`] = 'unset';
                            });
                            elementStyle = JSON.parse(JSON.stringify(style))
                            Object.keys(elementStyle).map(function (key, index) {
                                if (document.getElementById(node.key) && document.getElementById(node.key).style)
                                    document.getElementById(node.key).style[`${key}`] = elementStyle[key];
                            });
                        }}
                    >

                        <div onClick={() => this.props.loginBtn()} style={style} id={node.key}>
                            {
                                this.props.uData ?

                                    <button className="logout-landing-page">{'Logout'.translate(this.props.lang)}</button>
                                    :
                                    <img src={accountMobile} />
                            }

                        </div>
                    </div>
                    // <div style={style}>
                    //     <img className={`node-${node.type}`} src={accountMobile}></img>
                    // </div>
                )
            } else if (node.type == 'map') {
                let list = [];
                if (this.props.data && this.props.data.clinicGroupsItems) {
                    list = this.props.data.clinicGroupsItems
                }
                return (
                    <div style={wrapStyle}>
                        <div className="landing-map">
                            <Map value={list} {...this.props} mapHeight={wrapStyle && wrapStyle.height ? wrapStyle.height : null} getValue={(value) => {
                                let landingConfig = null;
                                if (this.props.getSelectedItem) {
                                    if (list && list.filter(el => el.value == value.value).length) {
                                        let index = list.findIndex(el => el.value == value.value)
                                        if (index > -1 && list[index] && list[index].landingConfig) {

                                            landingConfig = {
                                                ...list[index].landingConfig,
                                                alias: list[index].alias
                                            }
                                        }
                                    }

                                }
                                this.props.getSelectedItem(landingConfig)
                            }} />
                        </div>
                    </div >

                )
            } else if (node.type == 'mainInfo') {
                let list = [];
                if (this.props.data && this.props.data.clinicGroupsItems) {
                    list = this.props.data.clinicGroupsItems.sort((a, b) => a.name > b.name ? 1 : -1)
                }
                return (
                    <div style={wrapStyle}>
                        <div className="landing-map">
                            <MainClinicInfo
                                mainData={this.props.data}
                                style={style}
                                activeStyle={activeStyle}
                                value={list}
                                uData={this.props.uData}
                                loginBankId={this.props.loginBankId}
                                {...this.props}
                                mapHeight={wrapStyle && wrapStyle.height ? wrapStyle.height : null}
                                getValue={(value) => {
                                    // let landingConfig = null;
                                    // if (this.props.getSelectedItem) {
                                    //     if (list && list.filter(el => el.value == value.value).length) {
                                    //         let index = list.findIndex(el => el.value == value.value)
                                    //         if (index > -1 && list[index] && list[index].landingConfig) {

                                    //             landingConfig = {
                                    //                 ...list[index].landingConfig,
                                    //                 alias: list[index].alias
                                    //             }
                                    //         }
                                    //     }

                                    // }
                                    // this.props.getSelectedItem(landingConfig)
                                }}
                            />
                        </div>
                    </div >

                )
            }

        })

    }



    render() {
        // console.log(this.props.loginBankId)
        return (

            <div id='react-converter-v2-id' className="react-converter-v2" ref={(node) => this.ref = node} style={{ display: 'flex', flexWrap: 'wrap', backgroundColor: '#fff', maxWidth: '100%' }}>
                {this.renderNodes(groupNodes(this.props.nodes, 'root', this.props.layout, this.props.uData))}
            </div>


        );
    }
}

export default ToReact;