import React, { Component } from 'react';
import Page from '../../../containers/page';

import {
    Container,
    Row,
    Col, Input, Label, Modal, ModalBody, ModalHeader, FormGroup, Button, Table, FormFeedback
} from 'reactstrap';
import { Link } from 'react-router-dom';

import EventComponent from '../../referral/calendarViews/EventComponentt'
import ErrorModal from '../../../components/errorModal';
import DeleteModal from "../../../components/deleteModal";
import { getSearchParams, generateSearchLink, generateSearchLinkMultiple } from '../../../helpers/linkHelper';
import { API_ENDPOINT } from '../../../constants';

import TimePicker from '../../../components/forms/fields/timepicker';

import Isvg from 'react-inlinesvg'
import PatientFilter from '../../../components/patientFilter';
import warrning from '../../../assets/images/warrning.png';
import xIcon from '../../../assets/svg/x.svg';
import InfoModal from '../../../components/infoModal';

import { Calendar, Day, momentLocalizer } from 'react-big-calendar';
import CustomToolbar from '../../referral/calendarViews/CustomToolbar';
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Tooltip } from '@material-ui/core';
import { withStyles } from "@material-ui/core/styles";

import Checkbox from '../../../components/forms/fields/checkbox';
import DatePicker from '../../../components/forms/fields/datePickerRange';
import DatePicker2 from '../../../components/forms/fields/datePicker';
import PrintHelper from '../../../components/printHelper';
import openChat from '../../../assets/svg/open_chat.svg';
import garbageIcon from '../../../assets/svg/garbage.svg';
import viewIcon from '../../../assets/svg/eye.svg';
import { Player, Controls } from "@lottiefiles/react-lottie-player";
import InsertForm from '../../../components/insertForm';
import { Pie, Bar, Line } from 'react-chartjs-2';

import CreateDaybook from '../../../components/createDaybookModal';

import PdfSaveV2 from '../../../components/pdfSaveV2';

import { v4 as uuidv4 } from 'uuid';

const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: "rgba(58, 63, 85, 0.9)",
        color: "#fff",
        boxShadow: theme.shadows[1],
        fontSize: 14
    }
}))(Tooltip);



import moment from 'moment';
import { faTintSlash } from '@fortawesome/free-solid-svg-icons';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import striptags from 'striptags';

import ReferralContentDiary from '../../../components/referralContentDiary';
import DiaryGraph from '../../../components/diaryGraph';


function parseJSON(response) {
    return response.json().then(json => {
        return {
            result: json,
            status: response.status
        }
    })
}
function calculateAverage(arr, daybookChartType) {

    let sum = 0;
    let count = 0;

    for (let i = 0; i < arr.length; i++) {
        if (daybookChartType === 'monthly') {
            if (arr[i]) {
                sum += arr[i];
                count++;
            }
        } else {
            if (arr[i] !== null) {
                sum += arr[i];
                count++;
            }
        }

    }

    if (count === 0) {
        return 0; // to avoid division by zero
    }

    const average = sum / count;
    return Math.round(average * 100) / 100; // rounding to two decimal places
}



const localizer = momentLocalizer(moment)

function pad(n, width, z) {
    z = z || '0';
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
}
function generateAlias(str) {
    str.trim()
    str = str.toLowerCase();
    str = str.replace(/ä/g, 'a');
    str = str.replace(/ö/g, 'o');
    str = str.replace(/ü/g, 'u');
    str = str.replace(/ß/g, 'b');

    str = str.replace(/[^a-zA-Z0-9]/gi, '-').toLowerCase()
    str = str.replace(/-+/g, '-');

    return str;
}
/**
* Calendar for doctors
* @author   Stefan Pantic, Aleksandar Dabic, Milan Stanojevic
*/
class Daybook extends Component {
    constructor(props) {
        super(props);
        this.player = React.createRef();
        this.player2 = React.createRef();
        this.state = {
            ...props.initialData,
            form: false,
            useState: false,
            params: {
                entries: 10,
                page: 0
            },
            items: [],
            calendarEvents: [],
            total: 0,
            loading: true,
            // view: typeof window != 'undefined' && window.innerWidth < 768 ? 'day' : 'month',
            view: 'month',
            day: new Date(),
            addEventDaybook: false,
            daybookChartType: 'monthly',

        };
        this.state.min = new Date();
        this.state.min.setHours(8, 30, 0)
        this.state.max = new Date();
        this.state.max.setHours(20, 30, 0)

        this.state.weekDate = new Date()
        this.state.weekDate.setDate(this.state.weekDate.getDate() + 7)

        this.state.monthDate = new Date()
        this.state.monthDate.setDate(this.state.monthDate.getDate() + 31)


    }
    showPlayer = () => {
        this.setState({
            showPlayer: true,
        });
    };

    hidePlayer = () => {
        this.setState({
            showPlayer: false,
        });
    };
    stopAnimation = () => {
        this.player.current.play();
    };

    getLastModified = () => {

        fetch(API_ENDPOINT + '/users/search/daybook/event/patient/lastModified', {
            method: 'POST',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
            body: JSON.stringify({
                patient: this.props.patient._id,
                group: this.props.selectedGroup
            })
        }).then(parseJSON).then(({ result, status }) => {
            if (result) {
                this.setState({ daybookLastUpdate: result.lastModified })
            }
        })
    }

    get = () => {

        let obj = {
            doctor: this.props.uData._id,
            patient: this.props.patient._id,
            day: this.state.day ? this.state.day : new Date(),
            view: this.state.view,
            clinic: this.props.selectedClinic,
            group: this.props.selectedGroup

        }
        this.getLastModified()

        fetch(API_ENDPOINT + '/users/search/addevent/daybook/event/patient', {
            method: 'POST',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
            body: JSON.stringify(obj)
        }).then(parseJSON).then(({ result, status }) => {
            if (status >= 200 && status < 300) {

                this.setState({
                    items: result,

                }, () => {
                    let array = []
                    array = [...result]
                    this.setState({
                        eventItems: array
                    }, () => {
                        let arr = []
                        for (let i = 0; i < this.state.eventItems.length; i++) {
                            if (this.state.eventItems[i] && !arr.filter(item => item.data && this.state.eventItems[i].data && item.data.startTime && this.state.eventItems[i].data.startTime && new Date(this.props.getStringDateTs(item.data.startTime, 'MM/DD/YYYY')).getDate() == new Date(this.props.getStringDateTs(this.state.eventItems[i].data.startTime, 'MM/DD/YYYY')).getDate() && new Date(this.props.getStringDateTs(item.data.startTime, 'MM/DD/YYYY')).getMonth() == new Date(this.props.getStringDateTs(this.state.eventItems[i].data.startTime, 'MM/DD/YYYY')).getMonth()).length) {
                                arr.push(this.state.eventItems[i])
                            } else {
                                let index = arr.findIndex(item => item.data && this.state.eventItems[i].data && item.data.startTime && this.state.eventItems[i].data.startTime && new Date(this.props.getStringDateTs(item.data.startTime, 'MM/DD/YYYY')).getDate() == new Date(this.props.getStringDateTs(this.state.eventItems[i].data.startTime, 'MM/DD/YYYY')).getDate() && new Date(this.props.getStringDateTs(item.data.startTime, 'MM/DD/YYYY')).getMonth() == new Date(this.props.getStringDateTs(this.state.eventItems[i].data.startTime, 'MM/DD/YYYY')).getMonth())
                                if (this.state.eventItems[i].colors && arr[index]) {
                                    let colorItems = this.state.eventItems[i].colors.split('-')
                                    for (let i = 0; i < colorItems.length; i++) {
                                        if (!arr[index].colors) {
                                            arr[index].colors = `${colorItems[i]}`
                                        } else if (arr[index].colors.indexOf(colorItems[i]) == -1) {
                                            arr[index].colors += `-${colorItems[i]}`
                                        }

                                    }
                                }

                                if (this.state.eventItems[i].formsAnswers) {
                                    let formsAnswers = []
                                    formsAnswers = [...this.state.eventItems[i].formsAnswers]
                                    if (arr[index] && arr[index].formsAnswers) {
                                        arr[index].formsAnswers = [...formsAnswers, ...arr[index].formsAnswers]
                                    } else if (arr[index]) {
                                        arr[index].formsAnswers = [...formsAnswers]
                                    }


                                }

                                if (this.state.eventItems[i].formularID) {
                                    let formularID = []
                                    formularID = [...this.state.eventItems[i].formularID]
                                    if (arr[index] && arr[index].formularID) {
                                        arr[index].formularID = [...formularID, ...arr[index].formularID]
                                    } else if (arr[index]) {
                                        arr[index].formularID = [...formularID]
                                    }


                                }

                            }

                        }
                        this.setState({
                            calendarEvents: arr
                        }, () => {
                            if (this.state.addEventBookDeteils) {

                                this.handleSelect(new Date(this.state.addEventBookDeteils.date))

                            }
                        })
                    })
                })
            }
        })


    }
    handleResize = () => {
        if (this.checkTodayTimeout) {
            clearTimeout(this.checkTodayTimeout)
        }
        this.checkTodayTimeout = setTimeout(() => {
            this.checkToday()
        }, 400);
    }
    componentDidMount() {
        this.get()
        document.addEventListener('mousedown', this.handleClick);
        window.addEventListener('resize', this.handleResize)



        let dateNow = new Date(this.props.getStringDateTs(Math.floor(new Date().getTime() / 1000), 'MM/DD/YYYY'));
        let firstDay = new Date(dateNow.getFullYear(), dateNow.getMonth(), 1);
        let lastDay = new Date(dateNow.getFullYear(), dateNow.getMonth() + 1, 0);
        let setObj = {
            seeStatistics: {
                dateFrom: moment.unix(new Date(firstDay).getTime() / 1000).format(this.props.dateFormat),
                dateTo: moment.unix(new Date(lastDay).getTime() / 1000).format(this.props.dateFormat)
            }
        }

        this.setState(setObj, () => {
            this.showStats()
        })

    }
    handleClick = (event) => {
        if (this.state.view == 'month' && event && event.target && event.target.className && event.target.className.indexOf) {
            // console.log(event.target.className)
            if (event.target.className.indexOf('rbc-date-cell') != -1) {
                if (event.target.children && event.target.children[0] && event.target.children[0].innerHTML) {
                    // let day = new Date(this.state.day);
                    // day.setDate(Number(event.target.children[0].innerHTML))
                    // this.handleSelect(day)
                    let day = new Date(this.state.day);
                    day.setDate(1)
                    if (event.srcElement && event.srcElement.className, event.srcElement && event.srcElement.className.indexOf('rbc-off-range') != -1) {
                        if (Number(event.target.children[0].innerHTML) && Number(event.target.children[0].innerHTML) > 20) {
                            day.setMonth(day.getMonth() - 1)
                        } else {
                            day.setMonth(day.getMonth() + 1)
                        }
                    }
                    day.setDate(Number(event.target.children[0].innerHTML))
                    this.handleSelect(day)
                }

            } else if (event.target.className.indexOf('rbc-day-bg') != -1) {

                let split = event.target.className.split('date_')
                if (split[1]) {
                    let split2 = split[1].split('_date')
                    if (split2[0]) {
                        let day = new Date(split2[0])
                        if (split2[0] && split2[0].split('-') && split2[0].split('-').length > 2) {
                            let dayString = split2[0].split('-')[split2[0].split('-').length - 1]
                            let monthString = split2[0].split('-')[split2[0].split('-').length - 2]
                            if (dayString && Number(dayString))
                                day.setDate(Number(dayString))
                            if (monthString && Number(monthString))
                                day.setMonth(Number(monthString) - 1)
                        }
                        this.handleSelect(day)

                    }

                }
            }
        }

    }
    checkToday = () => {
        if (typeof window !== 'undefined') {
            let check = document.getElementsByClassName('rbc-now');
            if (check && check.length) {
                for (let i = 0; i < check.length; i++) {
                    if (check && check[i] && check[i].children && check[i].children[0] && check[i].children[0].innerHTML) {
                        let calendarToday = Number(check[i].children[0].innerHTML);
                        let today = this.props.getStringDateTs(Math.floor(new Date().getTime() / 1000), 'DD')
                        if (today) {
                            today = Number(today);
                        }
                        if (calendarToday !== today) {
                            document.getElementsByClassName('rbc-now')[i].className = document.getElementsByClassName('rbc-now')[i].className.replace(/rbc-now/, '');
                        }
                    }
                }
            }


            let checkToday = document.getElementsByClassName('rbc-today');
            if (checkToday && checkToday.length) {
                for (let i = 0; i < checkToday.length; i++) {
                    if (checkToday && checkToday[i] && checkToday[i].className && checkToday[i].className.indexOf('rbc-today') !== -1) {
                        let checkString = `date_${this.props.getStringDateTs(Math.floor(new Date().getTime() / 1000), 'YYYY-MM-DD')}_date`;
                        if (checkToday[i].className.indexOf(checkString) === -1) {
                            document.getElementsByClassName('rbc-today')[i].className = document.getElementsByClassName('rbc-today')[i].className.replace(/rbc-today/, '');
                        }

                    }
                }
            }


        }

    }

    componentDidUpdate(prevProps, prevState) {
        this.checkToday()

        // if (this.state.daybookStats && !this.state.seeStatistics) {
        //     this.setState({ daybookStats: null })
        // }


        // if (prevState.seeStatistics != this.state.seeStatistics && this.state.seeStatistics) {
        //     if (this.refreshInterval) {
        //         clearInterval(this.refreshInterval)
        //     }
        //     this.refreshInterval = setInterval(() => {
        //         if (!this.graphRef && this.state.daybookStats){
        //             this.setState({ refresh: uuidv4() })
        //         }
        //     }, 1000);
        // }
        // if (prevState.seeStatistics != this.state.seeStatistics && !this.state.seeStatistics) {
        //     if (this.refreshInterval) {
        //         clearInterval(this.refreshInterval)
        //     }

        // }


        if (prevState.graphRef1 != this.state.graphRef1 && this.state.graphRef1) {
            this.setState({ graphImage: this.state.graphRef1.toBase64Image() })

        }
        if (prevState.graphRef2 != this.state.graphRef2 && this.state.graphRef2) {
            this.setState({ graphImage: this.state.graphRef2.toBase64Image() })

        }

        if (this.props.selectedClinic && prevProps.selectedClinic && this.props.selectedClinic != prevProps.selectedClinic) {
            this.get()
        }
    }
    componentWillUnmount = () => {
        if (this.fetchWebDocInterval) {
            clearInterval(this.fetchWebDocInterval)
        }
        // if (this.refreshInterval) {
        //     clearInterval(this.refreshInterval)
        // }
        document.removeEventListener('mousedown', this.handleClick);

        document.removeEventListener('resize', this.handleResize);
        if (this.checkTodayTimeout) {
            clearTimeout(this.checkTodayTimeout)
        }
    }


    updateSort = (field, type) => {
        if (this.state.useState) {
            let state = this.state.params;
            state.sortField = field;
            state.sortType = type;
            this.setState({
                params: state
            })
        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0 }, [{ name: 'sortField', value: field }, { name: 'sortType', value: type }], false));

        }
    }

    updateParams = (name, value, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };
                obj[name] = value;
                this.setState({ params: obj });
            } else {
                let obj = this.state.params;
                obj[name] = value;
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(generateSearchLink(this.props[0].location.search, { entries: 10, page: 0 }, name, value, restart));
        }
    }
    updateMultipleParams = (fields, restart = false) => {

        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj });

            } else {
                let obj = this.state.params;
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0 }, fields, restart));
        }
    }

    addNewEvent = (data, formular) => {
        if (data.eventType == 'recuring' && data.frequencyType && data.frequencyType == 'day' && !data.frequencyDayScheduledDateFrom && !data.frequencyDayScheduledDateTo) {
            this.setState({
                errorEvent: 'Required'
            })
            return
        } else if (data.eventType == 'recuring' && data.frequencyType && data.frequencyType == 'week' && !data.frequencyWeekScheduledDateFrom && !data.frequencyWeekScheduledDateTo && !(data.checkedMonday || data.checkedTuesday || data.checkedWednesday || data.checkedThursday || data.checkedFriday || data.checkedSaturday || data.checkedSunday)) {
            this.setState({
                errorEvent: 'Required'
            })
            return
        } else if (data.eventType == 'recuring' && data.frequencyType && data.frequencyType == 'month' && !data.frequencyMonthScheduledDateFrom && !data.frequencyMonthScheduledDateTo && !data.frequencyMonthScheduled) {
            this.setState({
                errorEvent: 'Required'
            })
            return
        } else if (data.eventType == 'recuring' && !data.frequencyType) {
            this.setState({
                errorEvent: 'Required'
            })
            return
        } else if (!data.eventType) {
            this.setState({
                errorEvent: 'Required'
            })
            return
        }
        else {

            if (data.eventType == 'recuring' && data.frequencyType && data.frequencyType == 'day' && !data.frequencyDayScheduledDateFrom) {
                data.frequencyDayScheduledDateFrom = this.props.getStringDateTs(Math.floor(new Date().getTime() / 1000), this.props.dateFormat);
            } else if (data.eventType == 'recuring' && data.frequencyType && data.frequencyType == 'day' && !data.frequencyDayScheduledDateTo) {
                data.frequencyDayScheduledDateTo = this.props.getStringDateTs(Math.floor(new Date().getTime() / 1000), this.props.dateFormat)
            } else if (data.eventType == 'recuring' && data.frequencyType && data.frequencyType == 'week' && !data.frequencyWeekScheduledDateFrom) {
                data.frequencyWeekScheduledDateFrom = this.props.getStringDateTs(Math.floor(new Date().getTime() / 1000), this.props.dateFormat);
            } else if (data.eventType == 'recuring' && data.frequencyType && data.frequencyType == 'week' && !data.frequencyWeekScheduledDateTo) {
                data.frequencyWeekScheduledDateTo = this.props.getStringDateTs(Math.floor(new Date(this.state.weekDate).getTime() / 1000), this.props.dateFormat)/*moment(this.state.weekDate).format(this.props.dateFormat)*/
            } else if (data.eventType == 'recuring' && data.frequencyType && data.frequencyType == 'month' && !data.frequencyMonthScheduledDateFrom) {
                data.frequencyMonthScheduledDateFrom = this.props.getStringDateTs(Math.floor(new Date().getTime() / 1000), this.props.dateFormat);
            } else if (data.eventType == 'recuring' && data.frequencyType && data.frequencyType == 'month' && !data.frequencyMonthScheduledDateTo) {
                data.frequencyMonthScheduledDateTo = this.props.getStringDateTs(Math.floor(new Date(this.state.monthDate).getTime() / 1000), this.props.dateFormat)/*moment(this.state.monthDate).format(this.props.dateFormat)*/;
            } else if (data.eventType == 'oneTime' && !data.scheduledDateTo) {
                data.scheduledDateTo = this.props.getStringDateTs(Math.floor(new Date().getTime() / 1000), this.props.dateFormat);
            }
            // if (data.eventType == 'recuring' && data.frequencyType && data.frequencyType == 'day' && !data.frequencyDayScheduledDateFrom) {
            //     data.frequencyDayScheduledDateFrom = moment(new Date()).format(this.props.dateFormat);
            // } else if (data.eventType == 'recuring' && data.frequencyType && data.frequencyType == 'day' && !data.frequencyDayScheduledDateTo) {
            //     data.frequencyDayScheduledDateTo = moment(new Date()).format(this.props.dateFormat)
            // } else if (data.eventType == 'recuring' && data.frequencyType && data.frequencyType == 'week' && !data.frequencyWeekScheduledDateFrom) {
            //     data.frequencyWeekScheduledDateFrom = moment(new Date()).format(this.props.dateFormat);
            // } else if (data.eventType == 'recuring' && data.frequencyType && data.frequencyType == 'week' && !data.frequencyWeekScheduledDateTo) {
            //     data.frequencyWeekScheduledDateTo = moment(this.state.weekDate).format(this.props.dateFormat)
            // } else if (data.eventType == 'recuring' && data.frequencyType && data.frequencyType == 'month' && !data.frequencyMonthScheduledDateFrom) {
            //     data.frequencyMonthScheduledDateFrom = moment(new Date()).format(this.props.dateFormat);
            // } else if (data.eventType == 'recuring' && data.frequencyType && data.frequencyType == 'month' && !data.frequencyMonthScheduledDateTo) {
            //     data.frequencyMonthScheduledDateTo = moment(this.state.monthDate).format(this.props.dateFormat);
            // } else if (data.eventType == 'oneTime' && !data.scheduledDateTo) {
            //     data.scheduledDateTo = moment(new Date()).format(this.props.dateFormat);
            // }

            if (data.scheduledDateTo) {
                data.startTime = new Date(data.scheduledDateTo).getTime() / 1000
                data.endTime = new Date(data.scheduledDateTo).getTime() / 1000
            }

            let obj = {
                data: data,
                formularID: formular,
                patientID: this.props.patient._id,
                clinic: this.props.selectedClinic,
                group: this.props.selectedGroup
            }

            fetch(API_ENDPOINT + '/users/search/addevent/daybook/patient', {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify(obj)
            }).then(res => res.json()).then((result) => {
                if (result && result.error) {
                    this.setState({
                        error: result.error
                    })
                } else {
                    this.setState({
                        addEventDaybook: null,
                        insertFormFormular: null
                    }, () => this.get())
                }

            })

        }

    }

    getObjForDeletingMultipleForms = (data) => {
        if (!data || data.eventType !== 'recuring' || !data.frequencyType || (data.frequencyType === 'day' && (!data.frequencyDayScheduledDateFrom || !data.frequencyDayScheduledDateTo)) || (data.frequencyType === 'week' && (!data.frequencyWeekScheduledDateFrom || !data.frequencyWeekScheduledDateTo)) || (data.frequencyType === 'month' && (!data.frequencyMonthScheduledDateFrom || !data.frequencyMonthScheduledDateTo))) {
            this.setState({ errorEvent: 'Required' });
            return;
        }
        let obj = {
            group: this.props.selectedGroup,
            patient: this.props.patient._id
        };
        if (data.frequencyType === 'day') {
            let startDay = new Date(data.frequencyDayScheduledDateFrom);
            obj.startDay = moment.unix(startDay.getTime() / 1000).format(this.props.dateFormat);
            let endDay = new Date(data.frequencyDayScheduledDateTo);
            obj.endDay = moment.unix(endDay.getTime() / 1000).format(this.props.dateFormat);
        } else if (data.frequencyType === 'week') {
            let startDay = new Date(data.frequencyWeekScheduledDateFrom);
            obj.startDay = moment.unix(startDay.getTime() / 1000).format(this.props.dateFormat);
            let endDay = new Date(data.frequencyWeekScheduledDateTo);
            obj.endDay = moment.unix(endDay.getTime() / 1000).format(this.props.dateFormat);
        } else if (data.frequencyType === 'month') {
            let startDay = new Date(data.frequencyMonthScheduledDateFrom);
            obj.startDay = moment.unix(startDay.getTime() / 1000).format(this.props.dateFormat);
            let endDay = new Date(data.frequencyMonthScheduledDateTo);
            obj.endDay = moment.unix(endDay.getTime() / 1000).format(this.props.dateFormat);
        }
        return obj;
    }

    deleteMultipleForms = (data) => {
        fetch(API_ENDPOINT + '/users/daybook/delete/form/multiple', {
            method: 'POST',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(res => res.json()).then((result) => {
            this.setState({ deleteMultipleForm: null, addEventDaybook: null, insertFormFormular: null }, () => { this.get() })
        })
    }

    generateSections = (data) => {
        let sections = {};
        let items = [{ type: 0 }, ...data];
        let lastSectionId = -1;
        for (let i = 0; i < items.length; i++) {
            if (items[i].type == 0) {
                lastSectionId++;
                sections[lastSectionId] = {
                    name: items[i].name,
                    displayOnReferral: false,
                    questions: [],
                    conditions: items[i].conditions ? items[i].conditions : [],
                    displayOnReferral: items[i].displayOnReferral,
                    fontStyle: items[i].fontStyle,
                    fontSize: items[i].fontSize,
                    fontWeight: items[i].fontWeight,

                }
            }
            if (lastSectionId != -1 && items[i].type != 0) {


                let wikiLinks = [];

                items[i].wikiLinks = wikiLinks;


                if (items[i].type == 1) {
                    let displayOnReferral = false;
                    if (items[i].answers) {
                        for (let j = 0; j < items[i].answers.length; j++) {
                            if (items[i].answers[j].text) {
                                displayOnReferral = true;
                            }
                        }
                    }

                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        answers: items[i].answers,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        displayOnReferral: displayOnReferral,
                        wikiLinks: items[i].wikiLinks,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                    });

                } else if (items[i].type == 2) {
                    let displayOnReferral = false;
                    if (items[i].answers) {
                        for (let j = 0; j < items[i].answers.length; j++) {

                            if (items[i].answers[j].text) {
                                displayOnReferral = true;
                            }
                        }
                    }
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        answers: items[i].answers,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        answerPrefix: items[i].answerPrefix,
                        userInput: items[i].userInput,
                        displayOnReferral: displayOnReferral || items[i].answerPrefix ? true : false,
                        wikiLinks: items[i].wikiLinks,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,

                    })
                } else if (items[i].type == 3) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        answerPrefix: items[i].answerPrefix,
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                    })

                } else if (items[i].type == 4) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        text: items[i].text,
                        userInput: items[i].userInput,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                    })
                } else if (items[i].type == 15) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        text: items[i].text,
                        userInput: items[i].userInput,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                    })

                } else if (items[i].type == 5) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                        score: items[i].score
                    })

                } else if (items[i].type == 6) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                        score: items[i].score
                    })

                } else if (items[i].type == 7) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                    })

                } else if (items[i].type == 8) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                    })

                } else if (items[i].type == 9) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                    })

                } else if (items[i].type == 10) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        userInput: items[i].userInput,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                    })
                } else if (items[i].type == 12) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                        score: items[i].score
                    })
                } else if (items[i].type == 14) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                        score: items[i].score
                    })

                } else if (items[i].type == 13) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                        score: items[i].score
                    })

                } else if (items[i].type == 16) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                        blocks: items[i].blocks,
                    })

                } else if (items[i].type == 17) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                        blocks: items[i].blocks,

                    })

                } else if (items[i].type == 18) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                        answers: items[i].answers,

                    })

                } else if (items[i].type == 19) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        answerPrefix: items[i].answerPrefix,
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                        required: items[i].required,
                        graphType: items[i].graphType
                    })

                }
            }
        }

        return Object.values(sections);
    }

    handleSelect = (event) => {
        var startDate = moment.unix(event.getTime() / 1000).format(this.props.dateFormat);

        let obj = {
            date: startDate,
        }

        let eventDate = event.getDate();
        let eventMonth = event.getMonth();

        let arr = [];
        // if (this.state.calendarEvents && this.state.calendarEvents.filter(item => new Date(item.data && item.data.startTime * 1000).getDate() == event.getDate() && new Date(item.data && item.data.startTime * 1000).getMonth() == event.getMonth()).length) {
        //     arr = [...this.state.calendarEvents.filter(item => new Date(item.data && item.data.startTime * 1000).getDate() == event.getDate() && new Date(item.data && item.data.startTime * 1000).getMonth() == event.getMonth())[0].formularID]
        //     let selectedEvent = this.state.calendarEvents.filter(item => new Date(item.data && item.data.startTime * 1000).getDate() == event.getDate() && new Date(item.data && item.data.startTime * 1000).getMonth() == event.getMonth())[0]
        if (this.state.calendarEvents && this.state.calendarEvents.filter(item => new Date(this.props.getStringDateTs(item.data?.startTime, 'MM/DD/YYYY')).getDate() == eventDate && new Date(this.props.getStringDateTs(item.data?.startTime, 'MM/DD/YYYY')).getMonth() == eventMonth).length) {
            arr = [...this.state.calendarEvents.filter(item => new Date(this.props.getStringDateTs(item.data?.startTime, 'MM/DD/YYYY')).getDate() == eventDate && new Date(this.props.getStringDateTs(item.data?.startTime, 'MM/DD/YYYY')).getMonth() == eventMonth)[0].formularID]
            let selectedEvent = this.state.calendarEvents.filter(item => new Date(this.props.getStringDateTs(item.data?.startTime, 'MM/DD/YYYY')).getDate() == eventDate && new Date(this.props.getStringDateTs(item.data?.startTime, 'MM/DD/YYYY')).getMonth() == eventMonth)[0]
            obj.event = selectedEvent
            fetch(API_ENDPOINT + '/users/patient/daybook-forms', {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify({
                    arr: arr,
                    day: startDate,
                    doctor: this.props.uData._id,
                    patient: this.props.patient._id,
                    clinic: this.props.selectedClinic,
                    group: this.props.selectedGroup
                })
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300) {
                    this.setState({
                        addEventBookDeteils: obj,
                        formItems: result.forms,
                        doctorForms: result.doctorForms
                    })
                }

            })
        } else {

            this.setState({
                addEventBookDeteils: obj,
                formItems: [],
            })
        }

    }
    showSummary = () => {
        if (this.props.patient && this.props.patient._id) {


            let data = {
                patientID: this.props.patient._id,
                clinic: this.props.selectedClinic,
                group: this.props.selectedGroup,

            }

            this.setState({ loadingSummary: true }, () => {
                fetch(API_ENDPOINT + '/users/daybook/summary/stats', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify(data)
                }).then(res => res.json()).then((result) => {
                    if (result) {

                        this.setState({ loadingSummary: false })

                        if (result && result.result && result.result.length) {
                            this.setState({ showSummary: result.result })
                        } else {
                            this.setState({ error: 'The patient did not answer any form'.translate(this.props.lang) })
                        }

                        // this.setState({ daybookStats: null }, () => {
                        //     this.setState({ daybookStats: result, graphRef1: null, graphRef2: null }, () => {
                        //         // if (this.state.graphRef && this.state.graphRef.toBase64Image()) {
                        //         //     this.setState({ graphImage: this.graphRef.toBase64Image() })
                        //         // }

                        //     })
                        // })
                    }
                })
            })
        }
    }
    copySummary = () => {


        this.setState(
            {
                showPlayer: true,
            },
            () => {

                this.player.current.play();
                let showSummary = this.state.showSummary;

                if (showSummary && showSummary.length) {
                    let copyObj = `${'Summary'.translate(this.props.lang)}\n`;
                    for (let i = 0; i < showSummary.length; i++) {
                        let month = showSummary[i];
                        copyObj += '\n';

                        copyObj += `${/*moment.unix(month.ts).format('YYYY')*/this.props.getStringDateTs(month.ts, 'YYYY')} - ${/*moment.unix(month.ts).format('MM')*/this.props.getStringDateTs(month.ts, 'MM')} (${/*moment.unix(month.ts).format('MMM')*/this.props.getStringDateTs(month.ts, 'MMM') ? /*moment.unix(month.ts).format('MMM')*/this.props.getStringDateTs(month.ts, 'MMM').translate(this.props.lang) : null})`;
                        copyObj += '\n';
                        copyObj += `${'Number of days answered'.translate(this.props.lang)}: ${month && month.numberOfDays ? month.numberOfDays.length : 0}`;
                        copyObj += '\n';
                        if (month && month.items && month.items.length) {
                            for (let j = 0; j < month.items.length; j++) {
                                let item = month.items[j];
                                copyObj += `${item.name.translate(this.props.lang)}: ${item.count ? item.count : 0}`
                                copyObj += '\n';
                            }
                        }
                        if (month && month.itemsScale && month.itemsScale.length) {
                            for (let j = 0; j < month.itemsScale.length; j++) {
                                let item = month.itemsScale[j];
                                copyObj += `${item.name.translate(this.props.lang)}: ${item.counter && item.points ? Math.round(item.points / item.counter * 100) / 100 : 0}`
                                copyObj += '\n';
                            }
                        }
                        if (month && month.diaryGraphItems && month.diaryGraphItems.length) {
                            for (let j = 0; j < month.diaryGraphItems.length; j++) {
                                let item = month.diaryGraphItems[j];
                                copyObj += `${item.name}: ${item.average}`
                                copyObj += '\n';
                            }
                        }
                    }

                    navigator.clipboard.writeText(copyObj)

                }
                this.setState({
                    showPlayer: false,
                    showPlayer2: true,
                }, () => {
                    setTimeout(() => {
                        this.setState({ showPlayer2: false });
                    }, 1000);
                })

            }
        )

    }
    showStats = () => {
        if (this.state.seeStatistics && this.state.seeStatistics.dateFrom && this.state.seeStatistics.dateTo && this.props.patient && this.props.patient._id) {
            let data = {
                dateFrom: this.state.seeStatistics.dateFrom,
                dateTo: this.state.seeStatistics.dateTo,
                patientID: this.props.patient._id,
                clinic: this.props.selectedClinic,
                group: this.props.selectedGroup,

            }
            if (this.state.daybookChartType) {
                data.daybookChartType = this.state.daybookChartType;
            }

            this.setState({ graphImage: null }, () => {
                fetch(API_ENDPOINT + '/users/daybook/stats', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify(data)
                }).then(res => res.json()).then((result) => {
                    if (result) {

                        this.setState({ daybookStats: null }, () => {
                            this.setState({ daybookStats: result, graphRef1: null, graphRef2: null, resetGraphImage: new Date().getTime(), diaryGraphImage: null }, () => {
                                // if (this.state.graphRef && this.state.graphRef.toBase64Image()) {
                                //     this.setState({ graphImage: this.graphRef.toBase64Image() })
                                // }

                            })
                        })
                    }
                })
            })

        }

    }
    exportStats = () => {
        if (this.state.seeStatistics && this.state.seeStatistics.dateFrom && this.state.seeStatistics.dateTo && this.props.patient && this.props.patient._id) {
            let data = {
                dateFrom: this.state.seeStatistics.dateFrom,
                dateTo: this.state.seeStatistics.dateTo,
                patientID: this.props.patient._id,
                clinic: this.props.selectedClinic,
                group: this.props.selectedGroup,
                lang: this.props.lang
            }
            fetch(API_ENDPOINT + '/users/daybook/stats/export', {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify(data)
            }).then(res => res.json()).then((result) => {
                if (result.success) {
                    window.location.href = API_ENDPOINT + `${result.file}`;
                }
                this.setState({
                    success: result.success,
                    file: result.file
                })
                this.setState({
                    exportStatsModal: null,
                })
            })
        }

    }

    deleteFormInEvent = (data) => {

        fetch(API_ENDPOINT + '/users/daybook/delete/form', {
            method: 'POST',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(res => res.json()).then((result) => {
            this.setState({
                deleteForm: null,

            }, () => {
                this.get()

            })
        })
    }

    showWeekAndMonthReccuringOptions = () => {
        return true;
        // Function to check on whether today is greater than or equeal to 31st of March 2024
        // If it is, then show the recurring options for week and month
        // If it is not, then hide the recurring options for week and month
        let today = new Date();
        let march2024 = new Date('2024-03-31');
        if (today >= march2024) return true;
        return false;
    }

    calculateMaxDateHotfix = (minDateString) => {
        return undefined;
        try {
            if (minDateString && minDateString !== 'Invalid date' && minDateString !== 'NaN-NaN-NaN' && minDateString !== 'Invalid Date') {
                let minDate = new Date(minDateString);
                minDate.setHours(12, 0, 0, 0);
                if (minDate.getFullYear() === 2024 && minDate.getMonth() === 2) {
                    // March 2024
                    if (minDate.getDate() < 31) {
                        const maxDate = new Date('2024-03-30T12:00:00.000Z');
                        // const maxDateString = this.props.getStringDateTs(Math.floor(maxDate.getTime() / 1000));
                        return maxDate;
                    }
                }
            }
        } catch (err) {
            console.log('calculateMaxDateHotfix error: ', err);
        }
        return undefined;
    }

    render() {
        const showRecurringOptions = this.showWeekAndMonthReccuringOptions();
        return (
            <div >
                <Container fluid>
                    <Row>
                        <Col lg='12'>
                            <div>
                                <h6 className="component-header daybook-header">
                                    <div>
                                        <span>{this.props.patient && this.props.patient.userData && this.props.patient.userData.name}</span> <br />
                                        <p>{this.props.patient && this.props.patient.socialSecurityNumber}</p>
                                    </div>
                                    <label>{'Daybook updated'.translate(this.props.lang)}: {this.state.daybookLastUpdate ? this.props.getStringDateTs(this.state.daybookLastUpdate, this.props.dateFormat)/*moment.unix(this.state.daybookLastUpdate).format(this.props.dateFormat)*/ : 'Not modified'.translate(this.props.lang)}</label>
                                    {/* {this.props.patient.daybookModifiedTs ? moment.unix(this.props.patient.daybookModifiedTs).format(this.props.dateFormat) : moment(new Date()).format(this.props.dateFormat)}</label> */}
                                    <div className="same-height-button-container">
                                        <Button color='primary' style={{ marginRight: 15 }} onClick={() => {
                                            if (this.props.patient?.twoFactorAuthentication?.[this.props.selectedGroup]?.createAccount && !this.props.patient?.twoFactorAuthentication?.[this.props.selectedGroup]?.infoIsVerified) return;

                                            this.setState({
                                                addEventDaybook: {}
                                            })
                                        }}
                                            disabled={(this.props.patient?.twoFactorAuthentication?.[this.props.selectedGroup]?.createAccount && !this.props.patient?.twoFactorAuthentication?.[this.props.selectedGroup]?.infoIsVerified)}
                                        >{'Add event'.translate(this.props.lang)}</Button>
                                        {/* {typeof window != 'undefined' && window.innerWidth < 768 ?

                                            <Button style={{ height: 45 }} color='primary' onClick={() => {
                                                if (!this.state.seeStatistics) {
                                                    this.setState({
                                                        seeStatistics: {
                                                            dateFrom: moment.unix(new Date().getTime() / 1000).format(this.props.dateFormat),
                                                            dateTo: moment.unix(new Date().getTime() / 1000).format(this.props.dateFormat)
                                                        }
                                                    })
                                                } else {
                                                    this.setState({ seeStatistics: null })
                                                }
                                            }}>
                                                {
                                                    !this.state.seeStatistics ?
                                                        'See statistics'.translate(this.props.lang)
                                                        :
                                                        'Close statistics'.translate(this.props.lang)
                                                }
                                            </Button>
                                            :
                                            null
                                        } */}
                                    </div>
                                </h6>
                                {/* <div style={{ display: 'flex', justifyContent: 'flex-end', position: 'absolute', top: 100, right: 20, zIndex: 9 }}>
                                    {typeof window != 'undefined' && window.innerWidth < 768 ?
                                        null
                                        :
                                        <Button style={{ height: 45 }} color='primary' onClick={() => {
                                            if (!this.state.seeStatistics) {
                                                this.setState({
                                                    seeStatistics: {
                                                        dateFrom: moment.unix(new Date().getTime() / 1000).format(this.props.dateFormat),
                                                        dateTo: moment.unix(new Date().getTime() / 1000).format(this.props.dateFormat)
                                                    }
                                                })
                                            } else {
                                                this.setState({ seeStatistics: null })
                                            }
                                        }}>
                                            {
                                                !this.state.seeStatistics ?
                                                    'See statistics'.translate(this.props.lang)
                                                    :
                                                    'Close statistics'.translate(this.props.lang)
                                            }
                                        </Button>
                                    }
                                </div> */}

                                <Row>
                                    {this.state.addEventBookDeteils ?
                                        <Col lg='3'>
                                            <div className="component-header daybook-header-deteils">
                                                <h6 className="component-header daybook-header-box-shadow">
                                                    <div style={{ margin: 'auto', marginTop: 10, boxShadow: 'none' }} >
                                                        <span style={{ padding: 5 }} >{moment(new Date(this.state.addEventBookDeteils.date)).locale(this.props.lang == 'en' ? 'en' : 'sv').format("D MMMM YYYY")}</span> < br />
                                                    </div>
                                                </h6>
                                                <div style={{ display: 'flex', marginTop: 25, marginBottom: 25, marginLeft: 10, marginRight: 10 }} >
                                                    {this.state.addEventBookDeteils.event && this.state.addEventBookDeteils.event.colors && this.state.addEventBookDeteils.event.colors != '' ? this.state.addEventBookDeteils.event.colors.split('-').map((item, idx) => {
                                                        return (
                                                            <span style={{ display: 'flex', flexWrap: 'wrap', paddingRight: 1 }}>
                                                                <div className='calendar-daybook-info-tooltip-on' style={{ backgroundColor: item, minWidth: 18, height: 18, marginLeft: 5 }}>
                                                                    <div >
                                                                        <div className="chat-icons-tooltip">{`${this.props.uData?.clinicGroups?.find(group => group._id == this.props.selectedGroup)?.daybookEvents?.filter(color => color?.color == item)?.[0]?.name}`.translate(this.props.lang)}</div>
                                                                    </div>
                                                                </div>
                                                            </span>
                                                        )
                                                    }) : null}
                                                </div>
                                                {this.state.formItems && this.state.formItems.map(item => {
                                                    return (
                                                        <div className='daybook-header-box-shadow-item'>
                                                            <span style={this.state.addEventBookDeteils && this.state.addEventBookDeteils.event && this.state.addEventBookDeteils.event.formsAnswers && this.state.addEventBookDeteils.event.formsAnswers.length && this.state.addEventBookDeteils.event.formsAnswers.filter(answer => answer.formID == item._id)[0] ? { color: '', paddingLeft: 5 } : { color: 'red', paddingLeft: 5 }}>{item.name}

                                                                {
                                                                    !(this.state.addEventBookDeteils && this.state.addEventBookDeteils.event && this.state.addEventBookDeteils.event.data && this.state.addEventBookDeteils.event.formsAnswers && this.state.addEventBookDeteils.event.formsAnswers.length && this.state.addEventBookDeteils.event.formsAnswers.filter(answer => answer.formID == item._id)[0]) /*&& this.state.doctorForms.findIndex(form => form == item._id) != -1*/ ?
                                                                        <button style={{ border: 'none', backgroundColor: 'transparent', position: 'absolute', right: '5%' }} onClick={() => {
                                                                            // let items = this.state.items.filter(event => new Date(event.data && event.data.startTime * 1000).getDate() == new Date().getDate() && new Date(event.data && event.data.startTime * 1000).getMonth() == new Date().getMonth())
                                                                            // let eventID
                                                                            // for (let i = 0; i < items.length; i++) {
                                                                            //     if (items[i] && items[i].formularID.indexOf(item._id) != -1) {
                                                                            //         eventID = items[i]._id
                                                                            //         break;
                                                                            //     }
                                                                            // }

                                                                            this.setState({
                                                                                deleteForm: {
                                                                                    formID: item._id,
                                                                                    // eventID: eventID,
                                                                                    day: this.state.addEventBookDeteils.date,
                                                                                    patient: this.props.patient._id,
                                                                                    group: this.props.selectedGroup,
                                                                                    name: item.name
                                                                                }
                                                                            })
                                                                        }}><div className="tabel-action-tooltip"> <Isvg src={garbageIcon} /> <div className="chat-icons-tooltip">{'Delete'.translate(this.props.lang)}</div></div></button>
                                                                        :
                                                                        null
                                                                }
                                                                {/* {
                                                                    this.state.addEventBookDeteils && this.state.addEventBookDeteils.event && this.state.addEventBookDeteils.event.formsAnswers && this.state.addEventBookDeteils.event.formsAnswers.length && this.state.addEventBookDeteils.event.formsAnswers.filter(answer => answer.formID == item._id)[0] ?
                                                                        <button style={{ border: 'none', backgroundColor: 'transparent', position: 'absolute', right: '5%' }} onClick={() => {
                                                                            let answers = this.state.addEventBookDeteils && this.state.addEventBookDeteils.event && this.state.addEventBookDeteils.event.formsAnswers && this.state.addEventBookDeteils.event.formsAnswers.length && this.state.addEventBookDeteils.event.formsAnswers.filter(answer => answer.formID == item._id)[0]
                                                                            this.setState({
                                                                                openDaybookFormModal: answers,
                                                                                html: this.props.lang == 'en' ? answers.htmlEn : answers.htmlSe,
                                                                                questions: item.items,
                                                                            })
                                                                        }}><div className="tabel-action-tooltip"> <Isvg src={viewIcon} /> <div className="chat-icons-tooltip">{'Preview'.translate(this.props.lang)}</div></div></button>
                                                                        :
                                                                        null
                                                                } */}
                                                                {
                                                                    this.state.addEventBookDeteils && this.state.addEventBookDeteils.event && this.state.addEventBookDeteils.event.formsAnswers && this.state.addEventBookDeteils.event.formsAnswers.length && this.state.addEventBookDeteils.event.formsAnswers.filter(answer => answer.formID == item._id)[0] ?
                                                                        <button style={{ border: 'none', backgroundColor: 'transparent', position: 'absolute', right: '5%' }} onClick={() => {
                                                                            let answers = this.state.addEventBookDeteils && this.state.addEventBookDeteils.event && this.state.addEventBookDeteils.event.formsAnswers && this.state.addEventBookDeteils.event.formsAnswers.length && this.state.addEventBookDeteils.event.formsAnswers.filter(answer => answer.formID == item._id)[0]
                                                                            let obj = {}
                                                                            if (this.state.addEventBookDeteils && this.state.addEventBookDeteils.event) {
                                                                                if (this.state.addEventBookDeteils.event.patientData) {
                                                                                    obj.patientData = this.state.addEventBookDeteils.event.patientData;
                                                                                }
                                                                                if (this.state.addEventBookDeteils.event.doctorName) {
                                                                                    obj.doctorName = this.state.addEventBookDeteils.event.doctorName;
                                                                                }
                                                                                if (this.state.addEventBookDeteils.event.clinic) {
                                                                                    obj.clinic = this.state.addEventBookDeteils.event.clinic;
                                                                                }
                                                                                if (this.state.addEventBookDeteils.event.timestamp) {
                                                                                    obj.timestamp = this.state.addEventBookDeteils.event.timestamp;
                                                                                } else {
                                                                                    obj.timestamp = Math.floor(new Date(this.state.addEventBookDeteils.date).getTime() / 1000);
                                                                                }
                                                                            }
                                                                            if (answers) {
                                                                                obj.answers = answers;
                                                                            }
                                                                            if (item) {
                                                                                obj.questionaryData = item;
                                                                            }
                                                                            obj.daybookId = this.state.addEventBookDeteils?.event?._id;
                                                                            obj.formId = item._id;
                                                                            this.setState({

                                                                                previewDiaryContent: obj
                                                                            })
                                                                        }}><div className="tabel-action-tooltip"> <Isvg src={viewIcon} /> <div className="chat-icons-tooltip">{'Preview'.translate(this.props.lang)}</div></div></button>
                                                                        :
                                                                        null
                                                                }
                                                                <br />
                                                            </span>
                                                        </div>
                                                    )
                                                })}

                                                <Button style={{ position: 'absolute', bottom: 20, left: '50%', transform: 'translate(-50%, 0)' }} color="primary" onClick={() => { this.setState({ addEventBookDeteils: null }) }} >{'Close day'.translate(this.props.lang)}</Button>
                                            </div>


                                        </Col>
                                        : null}
                                    <Col lg={this.state.addEventBookDeteils ? '9' : '12'} className={this.state.addEventBookDeteils || this.state.seeStatistics ? 'mobile-daybook-calendar-hide' : ''}>

                                        <div className="schedule-working-hours daybook-calendar daybook-calendar-fixed-height mobile-daybook-calendar unset-height">


                                            <Calendar
                                                culture={this.props.culture}
                                                localizer={localizer}
                                                tooltipAccessor={null}
                                                views={typeof window != 'undefined' && window.innerWidth < 768 ? ['month'] : ['month', 'week', 'day']}
                                                messages={{
                                                    noEventsInRange: 'There are no events in this range'.translate(this.props.lang),
                                                    date: 'Date'.translate(this.props.lang),
                                                    time: 'Time'.translate(this.props.lang),
                                                    event: 'Event'.translate(this.props.lang),
                                                    agenda: 'Agenda'.translate(this.props.lang),
                                                    allDay: 'All day'.translate(this.props.lang),
                                                    month: 'Month'.translate(this.props.lang),
                                                    day: 'Day'.translate(this.props.lang),
                                                    today: 'Today'.translate(this.props.lang),
                                                    previous: 'Previous'.translate(this.props.lang),
                                                    next: 'Next'.translate(this.props.lang),
                                                    tomorrow: 'Tomorrow'.translate(this.props.lang),
                                                    week: 'Week'.translate(this.props.lang),
                                                    work_week: 'Work week'.translate(this.props.lang),
                                                    yesterday: 'Yesterday'.translate(this.props.lang),
                                                    showMore: (total) => `+${total} ${'more'.translate(this.props.lang)}`

                                                }}
                                                events={this.state.calendarEvents.map(item => {
                                                    return {
                                                        id: item._id,
                                                        title: '',
                                                        start: new Date(this.props.getStringDateTs(item.data && item.data.startTime, 'MM/DD/YYYY')),
                                                        end: new Date(this.props.getStringDateTs(item.data && item.data.endTime, 'MM/DD/YYYY')),
                                                        allDay: true,
                                                        color: item.colors ? item.colors : '',

                                                    }
                                                })}
                                                onNavigate={day => {
                                                    let setObj = {
                                                        day: new Date(this.props.getStringDateTs(Math.floor(new Date(day).getTime() / 1000), 'MM/DD/YYYY')),
                                                        scroll: false
                                                    }

                                                    if (day && new Date(day)) {
                                                        let date = new Date(day);
                                                        let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
                                                        let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

                                                        setObj.seeStatistics = {
                                                            dateFrom: moment.unix(new Date(firstDay).getTime() / 1000).format(this.props.dateFormat),
                                                            dateTo: moment.unix(new Date(lastDay).getTime() / 1000).format(this.props.dateFormat)
                                                        }
                                                        // setObj.seeStatistics = {
                                                        //     dateFrom: this.props.getStringDateTs(new Date(firstDay).getTime() / 1000, this.props.dateFormat)/*moment.unix(new Date(firstDay).getTime() / 1000).format(this.props.dateFormat)*/,
                                                        //     dateTo: this.props.getStringDateTs(new Date(lastDay).getTime() / 1000, this.props.dateFormat)/*moment.unix(new Date(lastDay).getTime() / 1000).format(this.props.dateFormat)*/
                                                        // }
                                                    }

                                                    this.setState(setObj, () => {
                                                        this.get()
                                                        if (setObj.seeStatistics) {
                                                            this.showStats()
                                                        }
                                                    })


                                                }}
                                                date={new Date(this.props.getStringDateTs(Math.floor(new Date(this.state.day).getTime() / 1000), 'MM/DD/YYYY'))}
                                                defaultDate={new Date(this.props.getStringDateTs(Math.floor(new Date(this.state.day).getTime() / 1000), 'MM/DD/YYYY'))}
                                                onView={(view) => {
                                                    this.setState({
                                                        view,
                                                        // addEventBookDeteils: null,
                                                        day: new Date()
                                                    }, () => {

                                                        this.get()
                                                    })
                                                }}
                                                onDrillDown={(event) => { this.handleSelect(event) }}
                                                view={this.state.view}
                                                components={{
                                                    toolbar: props => (<CustomToolbar {...props} lang={this.props.lang} hideWeek={true} hideDay={true} hideWorkWeek={true} hideAgenda={true} />),
                                                    event: ({ event }) => {
                                                        return (
                                                            <span style={{ display: 'flex', flexWrap: 'wrap' }}>
                                                                {event.color != '' ? event.color.split('-').map((item, idx) => {
                                                                    return (
                                                                        <div className='calendar-daybook-tooltip-on' style={typeof window !== 'undefined' && window.innerWidth < 768 ? { backgroundColor: item, minWidth: 5, height: 5, marginLeft: 2, marginBottom: 2 } : { backgroundColor: item, minWidth: 15, height: 15, marginLeft: 5, marginBottom: 2 }}>
                                                                            <div >
                                                                                <div className="chat-icons-tooltip">{`${this.props.uData?.clinicGroups?.find(group => group?._id == this.props.selectedGroup)?.daybookEvents?.filter(color => color?.color == item)?.[0]?.name}`.translate(this.props.lang)}</div>
                                                                            </div>
                                                                        </div>

                                                                    )
                                                                }) : null}
                                                            </span>
                                                        )
                                                    }
                                                }}
                                                // month look
                                                dayPropGetter={(date) => {
                                                    let dateString = moment.unix(new Date(date).getTime() / 1000).format('YYYY-MM-DD')

                                                    let isToday = false;
                                                    if (this.props.getStringDateTs(Math.floor(new Date().getTime() / 1000), 'YYYY-MM-DD') === dateString) {
                                                        isToday = true;
                                                    }

                                                    if (this.state.items.filter(item => new Date(this.props.getStringDateTs(item.data?.startTime, 'MM/DD/YYYY')).getDate() == date.getDate() && new Date(this.props.getStringDateTs(item.data?.startTime, 'MM/DD/YYYY')).getMonth() == date.getMonth() && item.formNotAnswerd && item.formsAnswers).length || (this.state.items.filter(item => new Date(this.props.getStringDateTs(item.data?.startTime, 'MM/DD/YYYY')).getDate() == date.getDate() && new Date(this.props.getStringDateTs(item.data?.startTime, 'MM/DD/YYYY')).getMonth() == date.getMonth() && item.formNotAnswerd).length && this.state.items.filter(item => new Date(this.props.getStringDateTs(item.data?.startTime, 'MM/DD/YYYY')).getDate() == date.getDate() && new Date(this.props.getStringDateTs(item.data?.startTime, 'MM/DD/YYYY')).getMonth() == date.getMonth() && item.formsAnswers).length)) {
                                                        return { className: `daybook-day-block-yellow date_${dateString}_date ${isToday ? 'rbc-today' : ''}` }
                                                    } else if (this.state.items.filter(item => new Date(this.props.getStringDateTs(item.data?.startTime, 'MM/DD/YYYY')).getDate() == date.getDate() && new Date(this.props.getStringDateTs(item.data?.startTime, 'MM/DD/YYYY')).getMonth() == date.getMonth() && item.formNotAnswerd).length) {
                                                        return { className: `daybook-day-block-red date_${dateString}_date ${isToday ? 'rbc-today' : ''}` }
                                                    } else if (this.state.items.filter(item => new Date(this.props.getStringDateTs(item.data?.startTime, 'MM/DD/YYYY')).getDate() == date.getDate() && new Date(this.props.getStringDateTs(item.data?.startTime, 'MM/DD/YYYY')).getMonth() == date.getMonth() && !item.formNotAnswerd).length) {
                                                        return { className: `daybook-day-block-green date_${dateString}_date ${isToday ? 'rbc-today' : ''}` }
                                                    } else {
                                                        return { className: `date_${dateString}_date ${isToday ? 'rbc-today' : ''}` }
                                                    }



                                                    // if (this.state.items.filter(item => new Date(item.data && item.data.startTime * 1000).getDate() == date.getDate() && new Date(item.data && item.data.startTime * 1000).getMonth() == date.getMonth() && item.formNotAnswerd && item.formsAnswers).length || (this.state.items.filter(item => new Date(item.data && item.data.startTime * 1000).getDate() == date.getDate() && new Date(item.data && item.data.startTime * 1000).getMonth() == date.getMonth() && item.formNotAnswerd).length && this.state.items.filter(item => new Date(item.data && item.data.startTime * 1000).getDate() == date.getDate() && new Date(item.data && item.data.startTime * 1000).getMonth() == date.getMonth() && item.formsAnswers).length)) {
                                                    //     return { className: `daybook-day-block-yellow date_${dateString}_date` }
                                                    // } else if (this.state.items.filter(item => new Date(item.data && item.data.startTime * 1000).getDate() == date.getDate() && new Date(item.data && item.data.startTime * 1000).getMonth() == date.getMonth() && item.formNotAnswerd).length) {
                                                    //     return { className: `daybook-day-block-red date_${dateString}_date` }
                                                    // } else if (this.state.items.filter(item => new Date(item.data && item.data.startTime * 1000).getDate() == date.getDate() && new Date(item.data && item.data.startTime * 1000).getMonth() == date.getMonth() && !item.formNotAnswerd).length) {
                                                    //     return { className: `daybook-day-block-green date_${dateString}_date` }
                                                    // } else {
                                                    //     return { className: `date_${dateString}_date` }
                                                    // }
                                                }}

                                                eventPropGetter={event => {
                                                    return { style: { backgroundColor: 'transparent' } }
                                                }}

                                            />
                                        </div>
                                        <div className="calendar-legend-wrap calendar-legend-wrap-legend calendar-legend-fixed-height calendar-legend-wrap-daybook-mobile">
                                            {this.props.uData && this.props.uData.clinicGroups && this.props.uData.clinicGroups.length && this.props.uData.clinicGroups.find(item => item._id == this.props.selectedGroup)?.daybookEvents ?
                                                this.props.uData.clinicGroups.find(item => item._id == this.props.selectedGroup).daybookEvents.map(item => {
                                                    return (
                                                        <div className="legend-item">
                                                            <div className="legend-color" style={{ backgroundColor: item.color }}></div>
                                                            <h6>{item.name.translate(this.props.lang)}</h6>
                                                        </div>
                                                    )
                                                })
                                                :
                                                null
                                            }

                                        </div>
                                    </Col>
                                </Row>
                            </div>

                        </Col>

                        {
                            this.state.seeStatistics ?
                                <Row className='daybook-statistic-wrap'>
                                    <Col lg="6">

                                        <div className="daybook-statistic-search-wrap daybook-statistic-search-doctors-screen-wrap">
                                            <div className="daybook-statistic-search">
                                                <h6>{'Period'.translate(this.props.lang)}</h6>
                                                <DatePicker2
                                                    label={'From'.translate(this.props.lang)}
                                                    dateFormat={this.props.dateFormat}
                                                    value={this.state.seeStatistics.dateFrom ? this.state.seeStatistics.dateFrom : this.props.getStringDateTs(Math.floor(new Date().getTime() / 1000), this.props.dateFormat)}
                                                    onChange={(e) => {
                                                        let seeStatistics = this.state.seeStatistics;
                                                        seeStatistics.dateFrom = e;
                                                        this.setState({
                                                            seeStatistics,

                                                        }, () => {
                                                            this.showStats()
                                                        })
                                                    }}
                                                />
                                                <DatePicker2
                                                    label={'To'.translate(this.props.lang)}
                                                    dateFormat={this.props.dateFormat}
                                                    value={this.state.seeStatistics.dateTo ? this.state.seeStatistics.dateTo : this.props.getStringDateTs(Math.floor(new Date().getTime() / 1000), this.props.dateFormat)}
                                                    onChange={(e) => {
                                                        let seeStatistics = this.state.seeStatistics;
                                                        seeStatistics.dateTo = e;
                                                        this.setState({
                                                            seeStatistics,

                                                        }, () => {
                                                            this.showStats()
                                                        })
                                                    }}
                                                />
                                                {/* <Button
                                                    color='primary'
                                                    className='test123'
                                                    disabled={this.state.seeStatistics && this.state.seeStatistics.dateFrom && this.state.seeStatistics.dateTo ? false : true}
                                                    onClick={() => {

                                                        this.showStats()
                                                    }}>
                                                    {'Show'.translate(this.props.lang)}
                                                </Button> */}
                                            </div>

                                            <div className='actions-buttons'>
                                                {/* <Button color='primary' onClick={() => { this.setState({ seeStatistics: null }) }}>{'Close statistics'.translate(this.props.lang)}</Button> */}
                                                <Button color='primary' className='export-dairy-stats-buttn' onClick={() => this.setState({ exportStatsModal: true, diaryGraphImage: this.getGraphImage?.() })}>{'Export'.translate(this.props.lang)}</Button>
                                            </div>

                                        </div>

                                        <div className="daybook-statistic-body-wrap">
                                            <div style={{ display: 'flex' }}>
                                                <div style={{ width: 'auto', minWidth: '50%', maxWidth: '50%' }}>
                                                    <h4>{'Period overview'.translate(this.props.lang)}</h4>
                                                    <div className='overview-head'>
                                                        <h6>{'Number of days answered'.translate(this.props.lang)}:</h6>
                                                        <span>{this.state.daybookStats && this.state.daybookStats.numberOfDays ? this.state.daybookStats.numberOfDays.length : 0}</span>
                                                    </div>
                                                    <div className='overview-body'>
                                                        {
                                                            this.state.daybookStats && this.state.daybookStats.items && this.state.daybookStats.items.map((item, idx) => {
                                                                return (
                                                                    <div className="overview-body-item-wrap">
                                                                        <div className="overview-body-item">
                                                                            <div className="overview-body-color" style={{ backgroundColor: item.color }}></div>
                                                                            <h6>{item.name.translate(this.props.lang)}</h6>
                                                                        </div>
                                                                        <span>{item.count ? item.count : 0}</span>
                                                                    </div>
                                                                )
                                                            })
                                                        }

                                                    </div>
                                                </div>
                                                <div style={{ width: '50%' }}>

                                                    <div className='overview-body'>
                                                        {
                                                            this.state.daybookStats && this.state.daybookStats.itemsScale && this.state.daybookStats.itemsScale.map((item, idx) => {
                                                                return (
                                                                    <div className="overview-body-item-wrap-scale" style={{ display: 'block' }}>
                                                                        <div className="overview-body-item-scale">
                                                                            {/* <div className="overview-body-color" style={{ backgroundColor: item.color }}></div> */}
                                                                            <h6><strong>{item.name && item.name.translate(this.props.lang)}</strong></h6>
                                                                            <br />
                                                                        </div>

                                                                        <div>{'Average:'.translate(this.props.lang)} <span>{item.counter && item.points ? Math.round(item.points / item.counter * 100) / 100 : 0}</span></div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                        {
                                                            this.state.daybookStats?.diaryGraphDataset?.filter(item => item?.counts?.filter(el => el).length)?.map((item, idx) => {
                                                                return (
                                                                    <div className="overview-body-item-wrap-scale" style={{ display: 'block' }}>
                                                                        <div className="overview-body-item-scale">
                                                                            <h6><strong>{item.name}</strong></h6>
                                                                            <br />
                                                                        </div>

                                                                        <div>{'Average:'.translate(this.props.lang)} <span>{calculateAverage(item.counts, this.state.daybookChartType)}</span></div>
                                                                    </div>
                                                                )
                                                            })
                                                        }

                                                    </div>
                                                </div>
                                            </div>
                                            <div className='summary-wrap'>
                                                <Button color='primary' onClick={() => {
                                                    this.showSummary()
                                                }}>{'Summary'.translate(this.props.lang)}</Button>
                                            </div>
                                        </div>

                                    </Col>
                                    <Col lg="6">
                                        {
                                            this.state.daybookStats && (this.state.daybookStats.dataset?.length || this.state.daybookStats.diaryGraphDataset?.length) ?
                                                <div className="daybook-statistic-graph-select-wrap">
                                                    <Label>{'Chart type'.translate(this.props.lang)}</Label>
                                                    <Input type='select' value={this.state.daybookChartType} onChange={(e) => {

                                                        let val = e.target.value


                                                        this.setState({
                                                            daybookChartType: val
                                                        }, () => this.showStats())


                                                    }}>

                                                        <option value={'daily'}>
                                                            {'Daily chart'.translate(this.props.lang)}
                                                        </option>
                                                        <option value={'monthly'}>
                                                            {'Monthly chart'.translate(this.props.lang)}
                                                        </option>

                                                    </Input>
                                                </div>
                                                :
                                                null
                                        }
                                        {
                                            this.state.daybookStats && (this.state.daybookStats.dataset?.length || this.state.daybookStats.diaryGraphDataset?.length) ?
                                                <DiaryGraph
                                                    getStringDateTs={this.props.getStringDateTs}
                                                    lang={this.props.lang}
                                                    daybookChartType={this.state.daybookChartType}
                                                    data={this.state.daybookStats}
                                                    resetGraphImage={this.state.resetGraphImage}
                                                    getGraphImage={(img) => {
                                                        this.getGraphImage = img;
                                                    }}
                                                />
                                                :
                                                null
                                        }
                                        {/* {
                                            this.state.daybookChartType == 'monthly' ?
                                                this.state.daybookStats && this.state.daybookStats.dataset && this.state.daybookStats.dataset.length ?
                                                    <div className="daybook-statistic-graph-wrap">

                                                        <Line
                                                            ref={(node) => { if (!this.state.graphRef1) { this.setState({ graphRef1: node }) } }}
                                                            options={{
                                                                animation: {
                                                                    duration: 0
                                                                },
                                                                resposive: true,
                                                                maintainAspectRatio: false,
                                                                plugins: {
                                                                    tooltip: {
                                                                        callbacks: {
                                                                            label: function (tooltipItem, data) {
                                                                                return tooltipItem.parsed.y;
                                                                            }
                                                                        }
                                                                    },

                                                                    legend: {
                                                                        position: 'bottom',
                                                                        labels: {
                                                                            usePointStyle: true,
                                                                        },
                                                                    }
                                                                }
                                                            }}
                                                            data={{
                                                                labels: this.state.daybookStats.graph.map((item) => this.props.getStringDateTs(item.date, 'MMMM')?.translate(this.props.lang)),
                                                                datasets: this.state.daybookStats.dataset && this.state.daybookStats.dataset.map((item, idx) => {
                                                                    let obj = {
                                                                        label: item.name,
                                                                        data: item.counts,
                                                                        backgroundColor: item.color,
                                                                        borderColor: item.color,
                                                                        borderWidth: 1,
                                                                        lineTension: 0.4
                                                                    }
                                                                    return obj
                                                                })

                                                            }}
                                                        ></Line>


                                                    </div>
                                                    :
                                                    null
                                                :
                                                this.state.daybookStats && this.state.daybookStats.dataset && this.state.daybookStats.dataset.length ?
                                                    <div className="daybook-statistic-graph-wrap">

                                                        <Line
                                                            ref={(node) => { if (!this.state.graphRef2) { this.setState({ graphRef2: node }) } }}
                                                            options={{
                                                                animation: {
                                                                    duration: 0
                                                                },
                                                                resposive: true,
                                                                maintainAspectRatio: false,
                                                                plugins: {
                                                                    tooltip: {
                                                                        callbacks: {
                                                                            label: function (tooltipItem, data) {
                                                                                return tooltipItem.parsed.y;
                                                                            }
                                                                        }
                                                                    },

                                                                    legend: {
                                                                        position: 'bottom',
                                                                        labels: {
                                                                            usePointStyle: true,
                                                                        },
                                                                    }
                                                                }
                                                            }}
                                                            data={{
                                                                labels: this.state.daybookStats.graph.map((item) => this.props.getStringDateTs(item.date, this.props.dateFormat)),
                                                                datasets: this.state.daybookStats.dataset && this.state.daybookStats.dataset.map((item, idx) => {
                                                                    let obj = {
                                                                        label: item.name,
                                                                        data: item.counts,
                                                                        backgroundColor: item.color,
                                                                        borderColor: item.color,
                                                                        borderWidth: 1,
                                                                        lineTension: 0.4
                                                                    }
                                                                    return obj
                                                                })

                                                            }}
                                                        ></Line>


                                                    </div>
                                                    :
                                                    null
                                        } */}
                                    </Col>
                                    {
                                        this.state.daybookStats && this.state.daybookStats.items && this.state.daybookStats.items.length ?
                                            <div style={{ position: 'fixed', top: 0, right: -1000, width: 800, height: 'auto' }}>
                                                <div style={{ backgroundColor: '#fff', padding: '50px 100px' }} ref={node => this.statsRef = node}>

                                                    <div className="daybook-statistic-body-wrap" style={{ boxShadow: 'none' }}>
                                                        <div className='daybook-statistic-body-title-info' style={{ marginBottom: 20 }}>
                                                            <h3 style={{ fontSize: 24, fontWeight: 600, margin: 0, marginBottom: 5 }}>
                                                                {this.props.patient && this.props.patient.userData && this.props.patient.userData.name}
                                                            </h3>
                                                            <h3 style={{ margin: 0 }}>
                                                                <span style={{ fontSize: 16, fontWeight: 400 }}>{this.state.seeStatistics.dateFrom} - {this.state.seeStatistics.dateTo}</span>
                                                            </h3>

                                                        </div>
                                                        <div style={{ display: 'flex' }}>
                                                            <div style={{ width: 400, marginRight: 20 }}>
                                                                <h4>{'Period overview'.translate(this.props.lang)}</h4>
                                                                <div style={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
                                                                    <h6>{'Number of days answered'.translate(this.props.lang)}:</h6>
                                                                    <span>{this.state.daybookStats && this.state.daybookStats.numberOfDays ? this.state.daybookStats.numberOfDays.length : 0}</span>
                                                                </div>
                                                                <div className='overview-body'>
                                                                    {
                                                                        this.state.daybookStats && this.state.daybookStats.items && this.state.daybookStats.items.map((item, idx) => {
                                                                            return (
                                                                                <div style={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
                                                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                                        <div style={{ width: 15, height: 15, marginRight: 5, backgroundColor: item.color }}></div>
                                                                                        <h6>{item.name.translate(this.props.lang)}</h6>
                                                                                    </div>
                                                                                    <span>{item.count ? item.count : 0}</span>
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }

                                                                </div>
                                                            </div>
                                                            <div style={{ width: 380 }}>

                                                                <div className='overview-body'>
                                                                    {
                                                                        this.state.daybookStats && this.state.daybookStats.itemsScale && this.state.daybookStats.itemsScale.map((item, idx) => {
                                                                            return (
                                                                                <div className="overview-body-item-wrap-scale" style={{ display: 'block' }}>
                                                                                    <div className="overview-body-item-scale">
                                                                                        <h6><strong>{item.name && item.name.translate(this.props.lang)}</strong></h6>
                                                                                        <br />
                                                                                    </div>

                                                                                    <div>{'Average:'.translate(this.props.lang)} <span>{item.counter && item.points ? Math.round(item.points / item.counter * 100) / 100 : 0}</span></div>
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }
                                                                    {
                                                                        this.state.daybookStats?.diaryGraphDataset?.filter(item => item?.counts?.filter(el => el).length)?.map((item, idx) => {
                                                                            return (
                                                                                <div className="overview-body-item-wrap-scale" style={{ display: 'block' }}>
                                                                                    <div className="overview-body-item-scale">
                                                                                        <h6><strong>{item.name}</strong></h6>
                                                                                        <br />
                                                                                    </div>

                                                                                    <div>{'Average:'.translate(this.props.lang)} <span>{calculateAverage(item.counts)}</span></div>
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {
                                                        this.state.daybookStats && this.state.daybookStats.dataset && this.state.daybookStats.dataset.length && this.state.diaryGraphImage ?
                                                            <div style={{ boxShadow: 'none', backgroundColor: '#fff', marginTop: 50, width: '100%', minHeight: 350 }}>
                                                                <img src={this.state.diaryGraphImage} />



                                                            </div>
                                                            :
                                                            null
                                                    }


                                                </div>
                                            </div>

                                            :
                                            null
                                    }

                                </Row>

                                :
                                null
                        }

                        {this.state.addEventBookDeteils ?
                            <Col lg='2'>
                                <h5>{ }</h5>
                            </Col>
                            : null}

                    </Row>

                </Container>
                {
                    this.state.exportStatsModal ?
                        <InfoModal
                            isOpen={this.state.exportStatsModal}
                            toggle={() => this.setState({ exportStatsModal: null })}
                            header={'Export'.translate(this.props.lang)}
                            info={this.state.exportStatsModal}
                            buttons={[
                                // <Button color='primary' onClick={() => {
                                //     this.setState({
                                //         exportStatsModal: null,
                                //     })

                                // }}>{'PDF'.translate(this.props.lang)}</Button>,



                                <Button color='primary'
                                    disabled={this.state.daybookStats && this.state.daybookStats.items && this.state.daybookStats.items.length ? false : true}
                                    onClick={() => {
                                        this.exportStats()

                                    }}>{'Excel'.translate(this.props.lang)}</Button>,
                                <PdfSaveV2 disabled={this.statsRef ? false : true}
                                    buttonText={'PDF'.translate(this.props.lang)}
                                    onClick={() => {
                                        this.setState({
                                            exportStatsModal: null,
                                        })

                                    }}
                                    lang={this.props.lang} element={this.statsRef} pdfName={`${this.props.lang == 'en' ? 'diary' : 'dagbok'}_stats${this.props.patient && this.props.patient.name ? ('_' + generateAlias(this.props.patient.name)) : ''}`} />,
                                <Button color='primary'
                                    onClick={() => {
                                        this.setState({
                                            exportStatsModal: null,
                                        })

                                    }}>{'Close'.translate(this.props.lang)}</Button>,


                            ]}
                        />
                        :
                        null

                }
                {
                    this.state.referralNote && this.state.view == 'agenda' ?
                        <Modal isOpen={this.state.referralNote} centered>
                            <ModalHeader toggle={() => this.setState({ referralNote: null })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ referralNote: null })}>&times;</button>}>{'Add note'.translate(this.props.lang)}</ModalHeader>

                            <ModalBody>
                                <FormGroup>
                                    <Label>{this.state.noteTitle}</Label>
                                </FormGroup>
                                <FormGroup>
                                    <Label>{'Note'.translate(this.props.lang)}</Label>
                                    <Input type='textarea'
                                        name="note"
                                        style={{ height: '150px' }}
                                        onChange={(e) => {

                                            this.setState({
                                                patientNote: striptags(e.target.value)
                                            })
                                        }}
                                        value={this.state.patientNote}
                                    />
                                    <div style={{ display: 'flex', 'margin-top': '10px', 'margin-left': '-5px' }}>
                                        <Button color='primary' style={{ margin: 5 }} onClick={this.submitNote}>{'Save'.translate(this.props.lang)}</Button>
                                    </div>
                                </FormGroup>

                            </ModalBody>
                        </Modal>
                        :
                        null
                }
                {
                    this.state.controlNote && this.state.view == 'agenda' ?
                        <Modal isOpen={this.state.controlNote} centered>
                            <ModalHeader toggle={() => this.setState({ controlNote: null })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ controlNote: null })}>&times;</button>}>{'Add note'.translate(this.props.lang)}</ModalHeader>

                            <ModalBody>
                                <FormGroup>
                                    <Label>{this.state.noteTitle}</Label>
                                </FormGroup>
                                <FormGroup>
                                    <Label>{'Note'.translate(this.props.lang)}</Label>
                                    <Input type='textarea'
                                        name="note"
                                        style={{ height: '150px' }}
                                        onChange={(e) => {

                                            this.setState({
                                                patientNote: striptags(e.target.value)
                                            })
                                        }}
                                        value={this.state.patientNote}
                                    />
                                    <div style={{ display: 'flex', 'margin-top': '10px', 'margin-left': '-5px' }}>
                                        <Button color='primary' style={{ margin: 5 }} onClick={this.submitControlNote}>{'Save'.translate(this.props.lang)}</Button>
                                    </div>
                                </FormGroup>

                            </ModalBody>
                        </Modal>
                        :
                        null
                }





                {
                    this.state.addEventDaybook ?
                        <Modal isOpen={this.state.addEventDaybook} centered>
                            <ModalHeader style={{ marginLeft: 0 }} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ addEventDaybook: null, insertFormFormular: null })}><Isvg src={xIcon} /></button>}>
                                {'Add event'.translate(this.props.lang)}
                            </ModalHeader>
                            <ModalBody className="form-feedback-wrap-display-block">

                                <Row>
                                    <Col lg="8">
                                        <Label>{'Event type'.translate(this.props.lang) + '*'}</Label>
                                        <Input type='select' onChange={(e) => {
                                            let addEventDaybook = this.state.addEventDaybook;
                                            addEventDaybook.eventType = e.target.value;
                                            this.setState({
                                                addEventDaybook,
                                                errorEvent: null
                                            })
                                        }}>
                                            <option value={'-1'}>{'Choose event type'.translate(this.props.lang)}</option>
                                            <option value={'oneTime'}>
                                                {'One time'.translate(this.props.lang)}
                                            </option>
                                            <option value={'recuring'}>
                                                {'Recurring'.translate(this.props.lang)}
                                            </option>
                                        </Input>
                                        {this.state.errorEvent ? <FormFeedback >{this.state.errorEvent}</FormFeedback> : null}
                                    </Col>
                                    <Col lg="4">
                                        <Button color='primary' style={{ marginTop: 30, marginLeft: 30 }} onClick={() => {
                                            this.setState({ insertForm: {} })
                                        }}>{'Insert form'.translate(this.props.lang)}</Button>
                                    </Col>

                                </Row>

                                {this.state.addEventDaybook.eventType == 'oneTime' ?
                                    <div style={{ marginTop: 10 }}>
                                        <Row>
                                            <Col lg="8">
                                                <DatePicker
                                                    label={'Select date'.translate(this.props.lang) + '*'}
                                                    minDate={new Date(this.props.getStringDateTs(Math.floor(new Date().getTime() / 1000), 'MM/DD/YYYY'))}
                                                    dateFormat={this.props.dateFormat}
                                                    value={this.state.addEventDaybook.scheduledDateTo ? this.state.addEventDaybook.scheduledDateTo : this.props.getStringDateTs(Math.floor(new Date().getTime() / 1000), this.props.dateFormat)}
                                                    onChange={(e) => {
                                                        let addEventDaybook = this.state.addEventDaybook;
                                                        addEventDaybook.scheduledDateTo = e;
                                                        this.setState({
                                                            addEventDaybook,

                                                        })
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                    </div>
                                    : null}
                                {this.state.addEventDaybook.eventType == 'recuring' ?
                                    <div style={{ marginTop: 10 }}>
                                        <Row>
                                            <Col lg="8">
                                                <Label >{'Frequency'.translate(this.props.lang) + '*'}</Label>
                                                <Input type='select' onChange={(e) => {
                                                    if (!showRecurringOptions && ['week', 'month'].includes(e.target.value)) return;
                                                    let addEventDaybook = this.state.addEventDaybook;
                                                    addEventDaybook.frequencyType = e.target.value;
                                                    if (e.target.value === 'day') {
                                                        addEventDaybook.frequencyDayScheduledDateFrom = this.props.getStringDateTs(Math.floor(new Date().getTime() / 1000), this.props.dateFormat);
                                                        addEventDaybook.frequencyDayScheduledDateTo = this.props.getStringDateTs(Math.floor(new Date().getTime() / 1000), this.props.dateFormat);
                                                    } else if (e.target.value === 'week') {
                                                        addEventDaybook.frequencyWeekScheduledDateFrom = this.props.getStringDateTs(Math.floor(new Date().getTime() / 1000), this.props.dateFormat);
                                                        addEventDaybook.frequencyWeekScheduledDateTo = this.props.getStringDateTs(Math.floor(new Date(this.state.weekDate).getTime() / 1000), this.props.dateFormat)/*moment(this.state.weekDate).format(this.props.dateFormat)*/;
                                                    } else if (e.target.value === 'month') {
                                                        addEventDaybook.frequencyMonthScheduledDateFrom = this.props.getStringDateTs(Math.floor(new Date().getTime() / 1000), this.props.dateFormat);
                                                        addEventDaybook.frequencyMonthScheduledDateTo = this.props.getStringDateTs(Math.floor(new Date(this.state.monthDate).getTime() / 1000), this.props.dateFormat)/*moment(this.state.monthDate).format(this.props.dateFormat)*/;
                                                    }
                                                    this.setState({
                                                        addEventDaybook,
                                                        errorEvent: null
                                                    })
                                                }}>
                                                    <option value={-1}>
                                                        {'Choose frequency'.translate(this.props.lang)}
                                                    </option>
                                                    <option value={'day'}>
                                                        {'Day'.translate(this.props.lang)}
                                                    </option>
                                                    {showRecurringOptions ? <>
                                                        <option value={'week'}>
                                                            {'Week'.translate(this.props.lang)}
                                                        </option>
                                                        <option value={'month'}>
                                                            {'Month'.translate(this.props.lang)}
                                                        </option>
                                                    </> : null}
                                                </Input>
                                            </Col>
                                        </Row>
                                        {this.state.errorEvent ? <FormFeedback >{this.state.errorEvent}</FormFeedback> : null}
                                    </div>
                                    : null
                                }
                                {this.state.addEventDaybook.eventType == 'recuring' && this.state.addEventDaybook.frequencyType == 'day' ?
                                    <div>
                                        <Row>
                                            <Col lg="8">
                                                <div style={{ display: 'flex', textAlign: 'center', alignItems: 'center', marginBottom: 5, marginTop: 10 }}>
                                                    <Label style={{ marginRight: 5 }}>{'Every'.translate(this.props.lang)}</Label>
                                                    <Input style={{ width: '25%' }} type='text' value={this.state.addEventDaybook.frequencyDayText} onChange={(e) => {
                                                        let value = e.target.value
                                                        value = value.replace(/[^0-9]/g, '')

                                                        let addEventDaybook = this.state.addEventDaybook;
                                                        addEventDaybook.frequencyDayText = value;
                                                        this.setState({
                                                            addEventDaybook
                                                        })
                                                    }} />
                                                    <Label style={{ marginLeft: 5 }}>{'day(s)'.translate(this.props.lang) + '*'}</Label>
                                                </div>
                                                {this.state.errorEvent ? <FormFeedback >{this.state.errorEvent}</FormFeedback> : null}
                                                <DatePicker
                                                    label={'Start date'.translate(this.props.lang) + '*'}
                                                    minDate={new Date(this.props.getStringDateTs(Math.floor(new Date().getTime() / 1000), 'MM/DD/YYYY'))}
                                                    dateFormat={this.props.dateFormat}
                                                    value={this.state.addEventDaybook.frequencyDayScheduledDateFrom ? this.state.addEventDaybook.frequencyDayScheduledDateFrom : this.props.getStringDateTs(Math.floor(new Date().getTime() / 1000), this.props.dateFormat)}
                                                    onChange={(e) => {
                                                        let addEventDaybook = this.state.addEventDaybook;
                                                        addEventDaybook.frequencyDayScheduledDateFrom = e;
                                                        this.setState({
                                                            addEventDaybook
                                                        })

                                                        if (this.state.addEventDaybook.frequencyDayScheduledDateTo && this.state.addEventDaybook.frequencyDayScheduledDateFrom) {
                                                            let dateTo = new Date(this.state.addEventDaybook.frequencyDayScheduledDateTo)
                                                            let dateFrom = new Date(this.state.addEventDaybook.frequencyDayScheduledDateFrom)
                                                            if (dateFrom.getTime() > dateTo.getTime() || this.calculateMaxDateHotfix(this.state.addEventDaybook.frequencyDayScheduledDateFrom)) {
                                                                let addEventDaybook = this.state.addEventDaybook;
                                                                addEventDaybook.frequencyDayScheduledDateTo = this.state.addEventDaybook.frequencyDayScheduledDateFrom;
                                                                this.setState({
                                                                    addEventDaybook
                                                                })

                                                            }
                                                        } else if (!this.state.addEventDaybook.frequencyDayScheduledDateTo && this.state.addEventDaybook.frequencyDayScheduledDateFrom) {
                                                            let addEventDaybook = this.state.addEventDaybook;
                                                            addEventDaybook.frequencyDayScheduledDateTo = this.state.addEventDaybook.frequencyDayScheduledDateFrom;
                                                            this.setState({
                                                                addEventDaybook
                                                            })
                                                        }

                                                    }}
                                                />
                                                {this.state.errorEvent ? <FormFeedback >{this.state.errorEvent}</FormFeedback> : null}
                                            </Col>
                                            <Col lg="8">
                                                <div style={{ marginTop: 5 }}>
                                                    <DatePicker
                                                        label={'End date'.translate(this.props.lang) + '*'}
                                                        dateFormat={this.props.dateFormat}
                                                        minDate={new Date(this.state.addEventDaybook.frequencyDayScheduledDateFrom) != 'Invalid Date' ? new Date(this.state.addEventDaybook.frequencyDayScheduledDateFrom) : new Date()}
                                                        maxDate={this.calculateMaxDateHotfix(this.state.addEventDaybook.frequencyDayScheduledDateFrom)}
                                                        value={this.state.addEventDaybook.frequencyDayScheduledDateTo ? this.state.addEventDaybook.frequencyDayScheduledDateTo : this.props.getStringDateTs(Math.floor(new Date().getTime() / 1000), this.props.dateFormat)}
                                                        onChange={(e) => {
                                                            let addEventDaybook = this.state.addEventDaybook;
                                                            addEventDaybook.frequencyDayScheduledDateTo = e;
                                                            this.setState({
                                                                addEventDaybook
                                                            })

                                                        }}
                                                    />
                                                </div>
                                                {this.state.errorEvent ? <FormFeedback >{this.state.errorEvent}</FormFeedback> : null}
                                            </Col>
                                        </Row>

                                    </div>
                                    : null}


                                {this.state.addEventDaybook.eventType == 'recuring' && this.state.addEventDaybook.frequencyType == 'week' ?
                                    <div style={{ marginTop: 10, marginBottom: 15 }}>
                                        <Row>
                                            <Col lg="8">
                                                <Checkbox
                                                    onChange={(e) => {
                                                        let addEventDaybook = this.state.addEventDaybook;
                                                        addEventDaybook.checkedMonday = e.target.checked
                                                        this.setState({
                                                            addEventDaybook
                                                        })
                                                    }}
                                                    checked={this.state.addEventDaybook.checkedMonday}
                                                    label={'Monday'.translate(this.props.lang)}
                                                />
                                                <Checkbox
                                                    onChange={(e) => {
                                                        let addEventDaybook = this.state.addEventDaybook;
                                                        addEventDaybook.checkedTuesday = e.target.checked
                                                        this.setState({
                                                            addEventDaybook
                                                        })
                                                    }}
                                                    checked={this.state.addEventDaybook.checkedTuesday}
                                                    label={'Tuesday'.translate(this.props.lang)}
                                                />
                                                <Checkbox
                                                    onChange={(e) => {
                                                        let addEventDaybook = this.state.addEventDaybook;
                                                        addEventDaybook.checkedWednesday = e.target.checked
                                                        this.setState({
                                                            addEventDaybook
                                                        })
                                                    }}
                                                    checked={this.state.addEventDaybook.checkedWednesday}
                                                    label={'Wednesday'.translate(this.props.lang)}
                                                />
                                                <Checkbox
                                                    onChange={(e) => {
                                                        let addEventDaybook = this.state.addEventDaybook;
                                                        addEventDaybook.checkedThursday = e.target.checked
                                                        this.setState({
                                                            addEventDaybook
                                                        })
                                                    }}
                                                    checked={this.state.addEventDaybook.checkedThursday}
                                                    label={'Thursday'.translate(this.props.lang)}
                                                />
                                                <Checkbox
                                                    onChange={(e) => {
                                                        let addEventDaybook = this.state.addEventDaybook;
                                                        addEventDaybook.checkedFriday = e.target.checked
                                                        this.setState({
                                                            addEventDaybook
                                                        })
                                                    }}
                                                    checked={this.state.addEventDaybook.checkedFriday}
                                                    label={'Friday'.translate(this.props.lang)}
                                                />
                                                <Checkbox
                                                    onChange={(e) => {
                                                        let addEventDaybook = this.state.addEventDaybook;
                                                        addEventDaybook.checkedSaturday = e.target.checked
                                                        this.setState({
                                                            addEventDaybook
                                                        })
                                                    }}
                                                    checked={this.state.addEventDaybook.checkedSaturday}
                                                    label={'Saturday'.translate(this.props.lang)}
                                                />
                                                <Checkbox
                                                    onChange={(e) => {
                                                        let addEventDaybook = this.state.addEventDaybook;
                                                        addEventDaybook.checkedSunday = e.target.checked
                                                        this.setState({
                                                            addEventDaybook
                                                        })
                                                    }}
                                                    checked={this.state.addEventDaybook.checkedSunday}
                                                    label={'Sunday'.translate(this.props.lang)}
                                                />
                                                {this.state.errorEvent ? <FormFeedback >{this.state.errorEvent}</FormFeedback> : null}
                                                <DatePicker
                                                    label={'Start date'.translate(this.props.lang) + '*'}
                                                    minDate={new Date(this.props.getStringDateTs(Math.floor(new Date().getTime() / 1000), 'MM/DD/YYYY'))}
                                                    dateFormat={this.props.dateFormat}
                                                    // completeStaeInput={this.state.addEventDaybook}
                                                    value={this.state.addEventDaybook.frequencyWeekScheduledDateFrom ? this.state.addEventDaybook.frequencyWeekScheduledDateFrom : this.props.getStringDateTs(Math.floor(new Date().getTime() / 1000), this.props.dateFormat)}
                                                    onChange={(e) => {
                                                        let addEventDaybook = this.state.addEventDaybook;
                                                        addEventDaybook.frequencyWeekScheduledDateFrom = e;
                                                        this.setState({
                                                            addEventDaybook
                                                        })

                                                        if (this.state.addEventDaybook.frequencyWeekScheduledDateTo && this.state.addEventDaybook.frequencyWeekScheduledDateFrom) {
                                                            let dateTo = new Date(this.state.addEventDaybook.frequencyWeekScheduledDateTo)
                                                            let dateFrom = new Date(this.state.addEventDaybook.frequencyWeekScheduledDateFrom)
                                                            if (dateFrom.getTime() + 1000 * 60 * 60 * 24 * 7 > dateTo.getTime()) {
                                                                let addEventDaybook = this.state.addEventDaybook;
                                                                let date = new Date(this.state.addEventDaybook.frequencyWeekScheduledDateFrom)
                                                                date.setDate(date.getDate() + 7)
                                                                addEventDaybook.frequencyWeekScheduledDateTo = this.props.getStringDateTs(Math.floor(new Date(date).getTime() / 1000), this.props.dateFormat ? this.props.dateFormat : 'YYYY-MM-DD')/*moment(new Date(date)).format(this.props.dateFormat ? this.props.dateFormat : 'YYYY-MM-DD')*/;
                                                                this.setState({
                                                                    addEventDaybook
                                                                })

                                                            }
                                                        } else if (!this.state.addEventDaybook.frequencyWeekScheduledDateTo && this.state.addEventDaybook.frequencyWeekScheduledDateFrom) {
                                                            let addEventDaybook = this.state.addEventDaybook;
                                                            let date = new Date(this.state.addEventDaybook.frequencyWeekScheduledDateFrom)
                                                            date.setDate(date.getDate() + 7)
                                                            addEventDaybook.frequencyWeekScheduledDateTo = this.props.getStringDateTs(Math.floor(new Date(date).getTime() / 1000), this.props.dateFormat ? this.props.dateFormat : 'YYYY-MM-DD')/*moment(new Date(date)).format(this.props.dateFormat ? this.props.dateFormat : 'YYYY-MM-DD')*/;
                                                            this.setState({
                                                                addEventDaybook
                                                            })
                                                        }

                                                    }}
                                                />
                                                {this.state.errorEvent ? <FormFeedback >{this.state.errorEvent}</FormFeedback> : null}
                                            </Col>
                                            <Col lg="8">
                                                <DatePicker
                                                    label={'End date'.translate(this.props.lang) + '*'}
                                                    dateFormat={this.props.dateFormat}
                                                    minDate={new Date(this.state.addEventDaybook.frequencyWeekScheduledDateFrom) != 'Invalid Date' ? new Date(this.state.addEventDaybook.frequencyWeekScheduledDateFrom) : new Date(this.state.weekDate)}
                                                    // completeStaeInput={this.state.addEventDaybook}
                                                    value={this.state.addEventDaybook.frequencyWeekScheduledDateTo ? this.state.addEventDaybook.frequencyWeekScheduledDateTo : this.props.getStringDateTs(Math.floor(new Date(this.state.weekDate).getTime() / 1000), this.props.dateFormat)/*moment(this.state.weekDate).format(this.props.dateFormat)*/}
                                                    onChange={(e) => {
                                                        let addEventDaybook = this.state.addEventDaybook;
                                                        addEventDaybook.frequencyWeekScheduledDateTo = e;
                                                        this.setState({
                                                            addEventDaybook
                                                        })

                                                    }}
                                                />
                                                {this.state.errorEvent ? <FormFeedback >{this.state.errorEvent}</FormFeedback> : null}
                                            </Col>
                                        </Row>
                                    </div>
                                    : null}
                                {this.state.addEventDaybook.eventType == 'recuring' && this.state.addEventDaybook.frequencyType == 'month' ?
                                    <div style={{ marginBottom: 5 }}>
                                        <Row>
                                            <Col lg="8">
                                                <div style={{ display: 'flex', textAlign: 'center', alignItems: 'center', marginBottom: 5, marginTop: 10 }}>
                                                    <Label style={{ marginRight: 5 }}>{'Every'.translate(this.props.lang)}</Label>
                                                    <Input style={{ width: '30%' }} type='select' onChange={(e) => {
                                                        let addEventDaybook = this.state.addEventDaybook;
                                                        addEventDaybook.frequencyMonthScheduled = e.target.value;
                                                        this.setState({
                                                            addEventDaybook
                                                        })
                                                    }}>
                                                        <option value={'-1'}>

                                                        </option>
                                                        <option value={'1'}>
                                                            {'1st'.translate(this.props.lang)}
                                                        </option>
                                                        <option value={'2'}>
                                                            {'2nd'.translate(this.props.lang)}
                                                        </option>
                                                        <option value={'3'}>
                                                            {'3rd'.translate(this.props.lang)}
                                                        </option>
                                                        <option value={'4'}>
                                                            {'4th'.translate(this.props.lang)}
                                                        </option>
                                                        <option value={'5'}>
                                                            {'5th'.translate(this.props.lang)}
                                                        </option>
                                                        <option value={'6'}>
                                                            {'6th'.translate(this.props.lang)}
                                                        </option>
                                                        <option value={'7'}>
                                                            {'7th'.translate(this.props.lang)}
                                                        </option>
                                                        <option value={'8'}>
                                                            {'8th'.translate(this.props.lang)}
                                                        </option>
                                                        <option value={'9'}>
                                                            {'9th'.translate(this.props.lang)}
                                                        </option>
                                                        <option value={'10'}>
                                                            {'10th'.translate(this.props.lang)}
                                                        </option>
                                                        <option value={'11'}>
                                                            {'11th'.translate(this.props.lang)}
                                                        </option>
                                                        <option value={'12'}>
                                                            {'12th'.translate(this.props.lang)}
                                                        </option>
                                                        <option value={'13'}>
                                                            {'13th'.translate(this.props.lang)}
                                                        </option>
                                                        <option value={'14'}>
                                                            {'14th'.translate(this.props.lang)}
                                                        </option>
                                                        <option value={'15'}>
                                                            {'15th'.translate(this.props.lang)}
                                                        </option>
                                                        <option value={'16'}>
                                                            {'16th'.translate(this.props.lang)}
                                                        </option>
                                                        <option value={'17'}>
                                                            {'17th'.translate(this.props.lang)}
                                                        </option>
                                                        <option value={'18'}>
                                                            {'18th'.translate(this.props.lang)}
                                                        </option>
                                                        <option value={'19'}>
                                                            {'19th'.translate(this.props.lang)}
                                                        </option>
                                                        <option value={'20'}>
                                                            {'20th'.translate(this.props.lang)}
                                                        </option>
                                                        <option value={'21'}>
                                                            {'21st'.translate(this.props.lang)}
                                                        </option>
                                                        <option value={'22'}>
                                                            {'22nd'.translate(this.props.lang)}
                                                        </option>
                                                        <option value={'23'}>
                                                            {'23rd'.translate(this.props.lang)}
                                                        </option>
                                                        <option value={'24'}>
                                                            {'24th'.translate(this.props.lang)}
                                                        </option>
                                                        <option value={'25'}>
                                                            {'25th'.translate(this.props.lang)}
                                                        </option>
                                                        <option value={'26'}>
                                                            {'26th'.translate(this.props.lang)}
                                                        </option>
                                                        <option value={'27'}>
                                                            {'27th'.translate(this.props.lang)}
                                                        </option>
                                                        <option value={'28'}>
                                                            {'28th'.translate(this.props.lang)}
                                                        </option>
                                                        <option value={'29'}>
                                                            {'29th'.translate(this.props.lang)}
                                                        </option>
                                                        <option value={'30'}>
                                                            {'30th'.translate(this.props.lang)}
                                                        </option>
                                                        <option value={'31'}>
                                                            {'31st'.translate(this.props.lang)}
                                                        </option>

                                                    </Input>
                                                    <Label style={{ marginLeft: 5 }}>{'day of the month'.translate(this.props.lang) + '*'}</Label>
                                                </div>
                                                {this.state.errorEvent ? <FormFeedback >{this.state.errorEvent}</FormFeedback> : null}
                                                <DatePicker
                                                    label={'Start date'.translate(this.props.lang) + '*'}
                                                    minDate={new Date(this.props.getStringDateTs(Math.floor(new Date().getTime() / 1000), 'MM/DD/YYYY'))}
                                                    value={this.state.addEventDaybook.frequencyMonthScheduledDateFrom ? this.state.addEventDaybook.frequencyMonthScheduledDateFrom : this.props.getStringDateTs(Math.floor(new Date().getTime() / 1000), this.props.dateFormat)}
                                                    onChange={(e) => {
                                                        let addEventDaybook = this.state.addEventDaybook;
                                                        addEventDaybook.frequencyMonthScheduledDateFrom = e;
                                                        this.setState({
                                                            addEventDaybook
                                                        })

                                                        if (this.state.addEventDaybook.frequencyMonthScheduledDateTo && this.state.addEventDaybook.frequencyMonthScheduledDateFrom) {
                                                            let dateTo = new Date(this.state.addEventDaybook.frequencyMonthScheduledDateTo)
                                                            let dateFrom = new Date(this.state.addEventDaybook.frequencyMonthScheduledDateFrom)
                                                            if (dateFrom.getTime() + 1000 * 60 * 60 * 24 * 31 > dateTo.getTime()) {
                                                                let addEventDaybook = this.state.addEventDaybook;
                                                                let date = new Date(this.state.addEventDaybook.frequencyMonthScheduledDateFrom)
                                                                date.setDate(date.getDate() + 31)
                                                                addEventDaybook.frequencyMonthScheduledDateTo = this.props.getStringDateTs(Math.floor(new Date(date).getTime() / 1000), this.props.dateFormat ? this.props.dateFormat : 'YYYY-MM-DD')/*moment(new Date(date)).format(this.props.dateFormat ? this.props.dateFormat : 'YYYY-MM-DD')*/;
                                                                this.setState({
                                                                    addEventDaybook
                                                                })

                                                            }
                                                        } else if (!this.state.addEventDaybook.frequencyMonthScheduledDateTo && this.state.addEventDaybook.frequencyMonthScheduledDateFrom) {
                                                            let addEventDaybook = this.state.addEventDaybook;
                                                            let date = new Date(this.state.addEventDaybook.frequencyMonthScheduledDateFrom)
                                                            date.setDate(date.getDate() + 31)
                                                            addEventDaybook.frequencyMonthScheduledDateTo = this.props.getStringDateTs(Math.floor(new Date(date).getTime() / 1000), this.props.dateFormat ? this.props.dateFormat : 'YYYY-MM-DD')/*moment(new Date(date)).format(this.props.dateFormat ? this.props.dateFormat : 'YYYY-MM-DD')*/;
                                                            this.setState({
                                                                addEventDaybook
                                                            })
                                                        }
                                                    }}
                                                />
                                                {this.state.errorEvent ? <FormFeedback >{this.state.errorEvent}</FormFeedback> : null}
                                            </Col>
                                            <Col lg="8">
                                                <DatePicker
                                                    label={'End date'.translate(this.props.lang) + '*'}
                                                    minDate={new Date(this.state.addEventDaybook.frequencyMonthScheduledDateFrom) != 'Invalid Date' ? new Date(this.state.addEventDaybook.frequencyMonthScheduledDateFrom) : new Date(this.state.monthDate)}
                                                    value={this.state.addEventDaybook.frequencyMonthScheduledDateTo ? this.state.addEventDaybook.frequencyMonthScheduledDateTo : this.props.getStringDateTs(Math.floor(new Date(this.state.monthDate).getTime() / 1000), this.props.dateFormat)/*moment(this.state.monthDate).format(this.props.dateFormat)*/}
                                                    onChange={(e) => {
                                                        let addEventDaybook = this.state.addEventDaybook;
                                                        addEventDaybook.frequencyMonthScheduledDateTo = e;
                                                        this.setState({
                                                            addEventDaybook
                                                        })

                                                    }}
                                                />
                                                {this.state.errorEvent ? <FormFeedback >{this.state.errorEvent}</FormFeedback> : null}
                                            </Col>
                                        </Row>
                                    </div>
                                    : null}

                            </ModalBody>
                            <ModalFooter style={{ justifyContent: 'left', marginBottom: 20 }}>
                                <Button color='primary' disabled={this.state.addEventDaybook && this.state.addEventDaybook.eventType && this.state.insertFormFormular ? false : true} style={{ marginLeft: 5 }} onClick={() => this.addNewEvent(this.state.addEventDaybook, this.state.insertFormFormular)}>{'Confirm'.translate(this.props.lang)}</Button>
                                <Button disabled={this.state.addEventDaybook?.eventType === 'recuring' && this.state.addEventDaybook?.frequencyType && (
                                    this.state.addEventDaybook.frequencyType === 'day' && this.state.addEventDaybook.frequencyDayScheduledDateFrom && this.state.addEventDaybook.frequencyDayScheduledDateTo
                                ) || (
                                        this.state.addEventDaybook.frequencyType === 'week' && this.state.addEventDaybook.frequencyWeekScheduledDateFrom && this.state.addEventDaybook.frequencyWeekScheduledDateTo
                                    ) || (
                                        this.state.addEventDaybook.frequencyType === 'month' && this.state.addEventDaybook.frequencyMonthScheduledDateFrom && this.state.addEventDaybook.frequencyMonthScheduledDateTo
                                    ) ? false : true} color='primary' style={{ marginLeft: 5 }} onClick={() => this.setState({ deleteMultipleForm: this.getObjForDeletingMultipleForms(this.state.addEventDaybook) })}>{'Delete'.translate(this.props.lang)}</Button>
                            </ModalFooter>
                        </Modal>
                        :
                        null
                }
                {
                    this.state.showSummary ?
                        <Modal isOpen={this.state.showSummary} centered>
                            <ModalHeader style={{ marginLeft: 0 }}>
                                {'Summary'.translate(this.props.lang)}
                            </ModalHeader>
                            <ModalBody>
                                <div className='diary-summary-wrap'>
                                    {
                                        this.state.showSummary.map((month, midx) => {
                                            return (
                                                <div className='diary-summary-item'>
                                                    <div className='diary-summary-header'>
                                                        <h5>{this.props.getStringDateTs(month.ts, 'YYYY')/*moment.unix(month.ts).format('YYYY')*/} - {this.props.getStringDateTs(month.ts, 'MM')/*moment.unix(month.ts).format('MM')*/} ({this.props.getStringDateTs(month.ts, 'MMM')/*moment.unix(month.ts).format('MMM')*/ ? /*moment.unix(month.ts).format('MMM')*/this.props.getStringDateTs(month.ts, 'MMM').translate(this.props.lang) : null}) </h5>
                                                    </div>
                                                    <div className='diary-summary-subtitle'>
                                                        <h6>{'Number of days answered'.translate(this.props.lang)}:</h6>
                                                        <span>{month && month.numberOfDays ? month.numberOfDays.length : 0}</span>
                                                    </div>
                                                    <div className='diary-summary-body'>
                                                        {
                                                            month && month.items && month.items.map((item, idx) => {
                                                                return (
                                                                    <div className="overview-body-item-wrap">
                                                                        <div className="overview-body-item">
                                                                            <div className="overview-body-color" style={{ backgroundColor: item.color }}></div>
                                                                            <h6>{item.name.translate(this.props.lang)}</h6>
                                                                        </div>
                                                                        <span>{item.count ? item.count : 0}</span>
                                                                    </div>
                                                                )
                                                            })
                                                        }

                                                    </div>
                                                    <div className='diary-summary-body item-scale-wrap'>
                                                        {
                                                            month && month.itemsScale && month.itemsScale.map((item, idx) => {
                                                                return (
                                                                    <div className="overview-body-item-scale">
                                                                        <h6>{item.name && item.name.translate(this.props.lang)} </h6>
                                                                        <span>{item.counter && item.points ? Math.round(item.points / item.counter * 100) / 100 : 0}</span>
                                                                    </div>

                                                                )
                                                            })
                                                        }

                                                    </div>
                                                    <div className='diary-summary-body item-scale-wrap'>
                                                        {
                                                            month && month.diaryGraphItems && month.diaryGraphItems.map((item, idx) => {
                                                                return (
                                                                    <div className="overview-body-item-scale">
                                                                        <h6>{item.name} </h6>
                                                                        <span>{item.average}</span>
                                                                    </div>

                                                                )
                                                            })
                                                        }

                                                    </div>


                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </ModalBody>
                            <ModalFooter style={{ margin: 'unset', justifyContent: 'flex-end', display: 'flex', width: '100%' }} className="buttons-right-blue">

                                <Button color="primary" style={this.state.showPlayer2 ? { paddingTop: 5 } : {}} onClick={() => this.copySummary()}>
                                    {
                                        this.state.showPlayer ? (
                                            <Player
                                                onEvent={(event) => {
                                                    if (event === "load") this.stopAnimation();
                                                }}
                                                onStateChange={this.toggleVisibility}
                                                ref={this.player} // set the ref to your class instance
                                                autoplay={true}
                                                loop={true}
                                                controls={true}
                                                src="https://assets8.lottiefiles.com/packages/lf20_ZSRKCF.json"
                                                style={{ height: "30px", width: "30px" }}
                                            ></Player>

                                        ) : this.state.showPlayer2 ? (
                                            <div style={{ display: 'flex', alignItems: 'center' }}><Player
                                                onStateChange={this.toggleVisibility}
                                                ref={this.player2} // set the ref to your class instance
                                                autoplay={true}
                                                loop={true}
                                                controls={true}
                                                src="https://assets10.lottiefiles.com/packages/lf20_CxyxLk.json"
                                                style={{ height: "30px", width: "30px" }}
                                            ></Player>
                                                {'Copied'.translate(this.props.lang)}
                                            </div>
                                        ) : (
                                            "Copy".translate(this.props.lang)
                                        )
                                    }
                                </Button>
                                <Button color="primary" onClick={() => this.setState({ showSummary: null })}> {'Close'.translate(this.props.lang)} </Button>
                            </ModalFooter>
                        </Modal>
                        :
                        null
                }
                {
                    this.state.previewDiaryContent ?
                        <ReferralContentDiary
                            {...this.props}
                            data={this.state.previewDiaryContent}
                            closeReferralContent={() => {
                                this.setState({ previewDiaryContent: null })
                            }}
                        />
                        :
                        null
                }

                {
                    this.state.openDaybookFormModal ?

                        <Modal isOpen={this.state.openDaybookFormModal} size='xl' centered>
                            <ModalHeader style={{ marginLeft: 0 }} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ openDaybookFormModal: null })}><Isvg src={xIcon} /></button>}>{'Preview'.translate(this.props.lang)}</ModalHeader>
                            <ModalBody>
                                <div dangerouslySetInnerHTML={{ __html: this.state.html.protectReferralFiles(this.props.uData ? this.props.uData.mediaToken : '', this.state.openDaybookFormModal._id) }}></div>
                                <div style={{ display: 'flex' }}>
                                    {
                                        this.state.openDaybookFormModal.totals.map((item, idx) => {

                                            return (
                                                <div style={{ display: 'flex', alignItems: "center" }}>
                                                    <div style={{ marginRight: 5 }} dangerouslySetInnerHTML={{ __html: item.name }}></div>

                                                </div>
                                            )
                                        })
                                    }

                                </div>
                                <Button color='primary' onClick={() => {
                                    this.setState({
                                        openSubtotalQuestions: this.state.openDaybookFormModal.answers
                                    })
                                }}>{'Preview answers'.translate(this.props.lang)}</Button>



                            </ModalBody>
                            <ModalFooter style={{ margin: 'unset', justifyContent: 'flex-end', display: 'flex', width: '100%' }} className="buttons-right-blue">

                                <PrintHelper lang={this.props.lang} html={`<div>${this.state.html} ${this.state.openDaybookFormModal.totals.map(item => { return item.name })}</div>`} />
                                <Button color="primary" onClick={() => this.setState({ openDaybookFormModal: null })}> {'Close'.translate(this.props.lang)} </Button>

                            </ModalFooter>
                        </Modal>
                        :
                        null
                }

                {
                    this.state.openSubtotalQuestions ?

                        <Modal isOpen={this.state.openSubtotalQuestions} size='lg'>
                            <ModalHeader style={{ marginLeft: 0 }} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ openSubtotalQuestions: null })}><Isvg src={xIcon} /></button>}>{'Questions'.translate(this.props.lang)}</ModalHeader>
                            <ModalBody>
                                <CreateDaybook
                                    lang={this.props.lang}
                                    sections={this.generateSections(this.state.questions)}
                                    // _preview={true}
                                    answers={this.state.openDaybookFormModal.answers}
                                    // doctorsView={true}
                                    disableAnswers={true}
                                    daybookFormPreview={this.state.openSubtotalQuestions}
                                />

                            </ModalBody>
                        </Modal>
                        :
                        null
                }

                {
                    this.state.insertForm ?
                        <InsertForm
                            {...this.props}
                            isOpen={this.state.insertForm}
                            onClose={(arr) => {
                                if (arr) {
                                    this.setState({
                                        insertFormFormular: arr
                                    })
                                }
                                this.setState({
                                    insertForm: !this.state.insertForm,

                                })
                            }}
                            size='lg'

                        />
                        :
                        null

                }

                {
                    this.state.error ?

                        <ErrorModal lang={this.props.lang}
                            isOpen={this.state.error}
                            toggle={() => this.setState({ error: null })}

                        >
                            {this.state.error.translate(this.props.lang)}
                        </ErrorModal>

                        :
                        null
                }

                {
                    this.state.deleteForm ?
                        <DeleteModal
                            lang={this.props.lang}
                            isOpen={this.state.deleteForm}
                            toggle={() => this.setState({ deleteForm: null })}
                            handler={() => { this.deleteFormInEvent(this.state.deleteForm); this.setState({ deleteForm: null }) }}
                        >
                            {'Delete event'.translate(this.props.lang)} <strong>{this.state.deleteForm && this.state.deleteForm.name}</strong>?
                        </DeleteModal>
                        :
                        null
                }

                {
                    this.state.deleteMultipleForm ? <DeleteModal
                        lang={this.props.lang}
                        isOpen={this.state.deleteMultipleForm}
                        toggle={() => this.setState({ deleteMultipleForm: null })}
                        handler={() => { this.deleteMultipleForms(this.state.deleteMultipleForm); this.setState({ deleteMultipleForm: null }) }}
                    >
                        {'Are you sure you want to delete all events for selected time period?'.translate(this.props.lang)}
                    </DeleteModal> : null
                }

            </div >
        );
    }
}

export default Daybook;