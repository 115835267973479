import React, { Component } from 'react';
import moment from 'moment';
import Page from '../../containers/page';
import ListBuilder from '../../components/listBuilder';
import { Link } from 'react-router-dom';
import {
    Container,
    Row,
    Col,
    Modal, ModalHeader, ModalBody, ModalFooter, Button, FormGroup, Input, Label, Table
} from 'reactstrap';
import { getSearchParams, generateSearchLink, generateSearchLinkMultiple } from '../../helpers/linkHelper';
import { API_ENDPOINT } from '../../constants';
import ErrorModal from '../../components/errorModal';
import profileUser from "../../assets/images/user.png";
import warrning from '../../assets/svg/warrning.svg';
import Isvg from 'react-inlinesvg';
import attach from '../../assets/svg/attach.svg';
import xIcon from '../../assets/svg/x.svg';
import UpdateAssignedClinicGroup from '../../components/updateAssignedClinicGroup';
import { Player } from '@lottiefiles/react-lottie-player';
import File from '../../components/forms/fields/multipleFilesSupport'

var striptags = require('striptags');

function parseJSONUpload(response) {
    if (response?.status === 500) {
        return { error: 'Wrong file type!' }
    } else {
        return response.json()
    }
}
function parseTextUpload(response) {
    if (response?.status === 500) {
        return { error: 'Wrong file type!' }
    } else {
        return response.text()
    }
}


/**
* Support page
* @author   Stefan Pantic
*/
class HomePage extends Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef();

        this.state = {
            promotedProjects: [],
            ...props.initialData,
            form: false,

            useState: false,
            params: {
                entries: 10,
                page: 0
            },
            items: [],
            tikets: [],
            category: {},
            messages: [],
            messagesDraftArray: [],
            assignedList: true,
            doctorTikets: [],
            assignedTickets: [],
            tiket: -1,
            groupError: false,
            displayNone: false,
            pageNumber: 0,
            stopSend: false

        };
    }

    get = () => {

        // if (this.props.uData && this.props.uData.lastNotifications) {
        //     let notif = localStorage.getItem('notif') ? JSON.parse(localStorage.getItem('notif')) : [];

        //     for (let i = 0; i < this.props.uData.lastNotifications.length; i++) {
        //         if (notif.indexOf(this.props.uData.lastNotifications[i]._id) == -1) {
        //             notif.push(this.props.uData.lastNotifications[i]._id);
        //         }
        //     }
        //     localStorage.setItem('notif', JSON.stringify(notif));
        //     this.props.verifyUser();
        // }


        this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));

        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0, sortField: 'timestamp', sortType: -1, user: this.props.uData._id, alias: localStorage.getItem('groupAlias'), group: this.props.selectedGroup })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

        for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
            this.props.loadDataWithQuery[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0, sortField: 'timestamp', sortType: -1, user: this.props.uData._id, alias: localStorage.getItem('groupAlias'), group: this.props.selectedGroup, pageNumber: this.state.pageNumber })).then((data) => {

                if (data && data.assignedTickets && this.state.assignedTickets && this.state.assignedTickets.length) {
                    for (let i = 0; i < data.assignedTickets.length; i++) {
                        for (let j = 0; j < this.state.assignedTickets.length; j++) {
                            if (this.state.assignedTickets[j]._id == data.assignedTickets[i]._id) {
                                this.state.assignedTickets.splice(j, 1)
                            }
                        }
                    }
                    data.assignedTickets = [...this.state.assignedTickets, ...data.assignedTickets]
                }

                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));

                })
            })
        }



    }

    componentDidMount() {
        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }

        this.get();

        if (!localStorage?.getItem?.('groupAlias') && (this.props.uData.userLevel == 1 || this.props.uData.userLevel == 2)) {
            // this.props[0].history.push(`/home-patient-level`);
            this.setState({
                groupError: true
            })
        }

        this.interval = setInterval(() => {
            this.get();

        }, 60 * 1000);

    }

    componentWillUnmount() {
        if (this.interval) {
            clearInterval(this.interval)
        }
    }
    componentDidUpdate(prevProps, prevState) {


        if (prevProps.selectedGroup != this.props.selectedGroup) {
            this.get();
        }
        if (this.props[0].location && this.props[0].location.state && this.props[0].location.state.messageModal && !this.state.messageModal && this.state.assignedTickets && this.state.assignedTickets.length && !prevState.assignedTickets.length) {
            for (let i = 0; i < this.state.assignedTickets.length; i++) {
                if (this.props[0].location.state.messageModal.toString() == this.state.assignedTickets[i]._id.toString()) {
                    this.setState({
                        messageModal: this.state.assignedTickets[i]
                    }, () => this.seen(this.state.assignedTickets[i]._id), this.findMessages(this.state.assignedTickets[i]._id))
                }
            }
        }


    }

    /**
    * Function change seen option on support tickets
    * @author   Stefan Pantic
    * @Objectparam    {String} id      ticket id
    */
    seen = (id) => {

        this.setState({
            loading: true
        }, () => {
            fetch(API_ENDPOINT + '/support-category/tiket/update/' + id, {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify()
            }).then(res => res.json()).then((result) => {
                this.get();

            })

        })

        fetch(API_ENDPOINT + '/notification/seen/update-type', {
            method: 'POST',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
            body: JSON.stringify({ type: id, user: this.props.uData._id })
        }).then(res => res.json()).then((result) => {
            this.props.verifyUser()
        })
    }

    updateMessgeModalState = () => {
        if (this.props[0].location && this.props[0].location.state && this.props[0].location.state.messageModal && this.state.assignedTickets && this.state.assignedTickets.length && !prevState.assignedTickets.length) {
            for (let i = 0; i < this.state.assignedTickets.length; i++) {
                if (this.props[0].location.state.messageModal.toString() == this.state.assignedTickets[i]._id.toString()) {
                    this.setState({
                        messageModal: this.state.assignedTickets[i]
                    }, () => this.seen(this.state.assignedTickets[i]._id))
                }
            }
        }
    }

    /**
    * Function for sending reply on received ticket
    * @author   Stefan Pantic
    * @Objectparam    {String} answer       message that user send
    * @Objectparam    {String} tiket        ticket id
    * @Objectparam    {String} user         user id who send reply
    */
    sendAnswer = (data) => {
        this.setState({ stopSend: true })
        if (!data.clinic) {
            // console.log(data)
            fetch(API_ENDPOINT + '/support-category/tiket/message-site', {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify({ answer: data.answer, email: data.email, tiketNumber: data.tiketNumber, userID: this.props.uData._id, phone: data.phone, tiket: data._id, image: this.state.image, document: this.state.document })
            }).then(res => res.json()).then((result) => {
                if (result.error) {
                    this.setState({
                        error: result.error.translate(this.props.lang)
                    })
                } else {
                    this.get();

                    let messageModal = this.state.messageModal;
                    messageModal.answer = '';
                    this.setState({
                        messageModal,
                        image: null,
                        document: null,
                        stopSend: false
                    }, () => this.findMessages(data._id))
                }

            })
        } else {
            this.setState({
                loading: true,
                stopSend: true
            }, () => {
                fetch(API_ENDPOINT + '/support-category/answer', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({ answer: data.answer, tiket: data._id, user: this.props.uData._id, image: this.state.image, document: this.state.document })
                }).then(res => res.json()).then((result) => {
                    if (result.error) {
                        this.setState({
                            error: result.error.translate(this.props.lang)
                        })
                    } else {
                        this.get();

                        let messageModal = this.state.messageModal;
                        messageModal.answer = '';
                        this.setState({
                            messageModal,
                            image: null,
                            document: null,
                            stopSend: false
                        }, () => this.findMessages(data._id))
                    }

                })

            })
        }
    }

    /**
    * Function that close ticket for further use
    * @author   Stefan Pantic
    * @Objectparam    {String} data       ticket id
    */
    closeTiket = (data) => {

        this.setState({
            loading: true
        }, () => {
            fetch(API_ENDPOINT + '/support-category/close/' + data, {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify({ closeConclusion: this.state.closeConclusion, selectedCriticality: this.state.selectedCriticality })
            }).then(res => res.json()).then((result) => {
                this.setState({
                    closeTicketModal: null,
                    messageModal: null,
                    conclusion: null,
                    assignedTickets: [],
                    messageModal: null,
                })
                this.get();
                // this.props[0].history.push('/support-archive')

            })

        })
    }

    /**
    * Function for file upload
    * @author   Milan Stanojevic
    */
    uploadDocument = (e) => {
        let input = e.target;
        if (input.files && input.files[0]) {

            let fsize = input.files[0].size;
            let file = Math.round((fsize / 1024))
            this.setState({
                uploadTimestampDoc: Math.floor(new Date().getTime() / 1000)
            })
            if (file > 5120) {
                this.setState({ error: "File size limit has been reached! (max 5 MB)".translate(this.props.lang) })
            } else {

                this.setState({
                    _documentUploading: true
                })

                let formData = new FormData();
                formData.append('file', input.files[0]);
                formData.append('group', this.props.selectedGroup)
                formData.append('fileType', 'clinicSupport')

                fetch(API_ENDPOINT + '/chat/upload/document', {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,

                    },
                    body: formData
                }).then(parseJSONUpload).then((img) => {
                    if (img?.error) {
                        this.setState({ error: img.error, _documentUploading: null })
                    } else {
                        this.setState({
                            document: img
                        })
                        this.setState({
                            _documentUploading: null
                        })
                    }

                });
            }
        }
    }

    /**
    * Function for image upload
    * @author   Milan Stanojevic
    */
    uploadImage = (e) => {
        let input = e.target;
        if (input.files && input.files[0]) {

            let fsize = input.files[0].size;
            let file = Math.round((fsize / 1024))
            this.setState({
                uploadTimestamp: Math.floor(new Date().getTime() / 1000)
            })
            if (file > 10240) {
                this.setState({ error: "File size limit has been reached! (max 10 MB)".translate(this.props.lang) })
            } else {

                this.setState({
                    _imageUploading: true,
                })
                let group;
                if (this.props.selectedGroup) {
                    group = this.props.selectedGroup;
                } else if (this.state.messageModal && this.state.messageModal.group) {
                    group = this.state.messageModal.group;
                }
                let formData = new FormData();
                formData.append('file', input.files[0]);
                formData.append('group', group)
                formData.append('fileType', 'clinicSupport')

                fetch(API_ENDPOINT + '/chat/upload/image', {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,

                    },
                    body: formData
                }).then(parseTextUpload).then((img) => {
                    if (img?.error) {
                        this.setState({ error: img.error, _imageUploading: null })
                    } else {
                        this.setState({
                            image: img
                        })
                        this.setState({
                            _imageUploading: null
                        })
                    }

                });
            }
        }
    }

    findMessages = (id) => {
        fetch(API_ENDPOINT + '/support-category/all-messages/' + id, {
            method: 'POST',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
            body: JSON.stringify({})
        }).then(res => res.json()).then((result) => {
            if (result.error) {
                this.setState({
                    error: result.error.translate(this.props.lang)
                })
            } else {
                let selectedTicket = {}
                if (this.state.assignedTickets) {
                    selectedTicket = this.state.assignedTickets.filter(item => item._id == id)[0]
                    console.log(selectedTicket)
                }
                this.setState({
                    messages: result,
                    selectedTicket: selectedTicket
                })
            }
        })
    }
    notifSeenUpdate = (id) => {

        fetch(API_ENDPOINT + '/notification/seen/update-type', {
            method: 'POST',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
            body: JSON.stringify({ type: id, user: this.props.uData ? this.props.uData._id : null })
        }).then(res => res.json()).then((result) => {
            this.props.verifyUser()
        })
    }

    checkDraftMessages = (data, update = false) => {
        if (update && this.state.messagesDraftArray && this.state.messagesDraftArray.length) {
            let messageModal = data;
            for (let i = 0; i < this.state.messagesDraftArray.length; i++) {
                if (messageModal._id == this.state.messagesDraftArray[i]._id) {
                    messageModal.answer = this.state.messagesDraftArray[i].answer;
                    messageModal.image = this.state.messagesDraftArray[i].image;
                    messageModal.document = this.state.messagesDraftArray[i].document
                }

            }
        } else {
            let messagesDraftArray = this.state.messagesDraftArray
            if (messagesDraftArray && messagesDraftArray.length) {
                let check = true
                for (let i = 0; i < messagesDraftArray.length; i++) {
                    if (messagesDraftArray[i]._id == data._id) {
                        messagesDraftArray.splice(i, 1, {
                            _id: data._id,
                            answer: data.answer,
                            image: data.image,
                            document: data.document
                        })
                        check = false
                    }

                }
                if (check) {
                    messagesDraftArray.push({
                        _id: data._id,
                        answer: data.answer,
                        image: data.image,
                        document: data.document
                    })
                }
            } else {
                messagesDraftArray.push({
                    _id: data._id,
                    answer: data.answer,
                    image: data.image,
                    document: data.document
                })
            }

            this.setState({
                messagesDraftArray
            })
        }

    }

    findConclusions = (ticketID) => {
        fetch(API_ENDPOINT + '/support-conclusions/all', {
            method: 'GET',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
        }).then(res => res.json()).then((result) => {
            if (result) {

                fetch(API_ENDPOINT + '/support-criticality/all', {
                    method: 'GET',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                }).then(res => res.json()).then((res) => {
                    this.setState({
                        closeTicketModal: ticketID,
                        conclusions: [...result],
                        criticality: [...res],
                        closeConclusion: null
                    }, () => {
                        if (this.state.selectedTicket && this.state.selectedTicket.criticality) {
                            this.setState({
                                selectedCriticality: this.state.selectedTicket.criticality
                            })
                        } else {
                            this.setState({
                                selectedCriticality: null
                            })
                        }
                    })
                })

            }

        })
    }

    forwardSupportTicket = () => {
        fetch(API_ENDPOINT + '/support/transfer/' + this.state.forwardSupportTicketModal._id, {
            method: 'POST',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
            body: JSON.stringify({ upgradeSupportTicketLine: this.state.upgradeSupportTicketLine, changeSupportTicketAgent: this.state.changeSupportTicketAgent })
        }).then(res => res.json()).then((result) => {
            this.setState({
                forwardSupportTicketModal: null,
                forwardSupportTicketUpdateObject: null,
                supportAgents: null,
                assignedTickets: [],
                messageModal: null,
                upgradeSupportTicketLine: null,
                changeSupportTicketAgent: null
            }, () => {
                this.get()
            })

        })
    }

    render() {
        // let params = getSearchParams(this.props[0].location.search, { entries: 10, page: 0 })
        // let level = this.props.uData.userLevel
        // console.log(this.state.stopSend);

        return (
            <div className={this.props.autoHideSidebar ? "dashboard dashboard-auto-hide" : "dashboard"}>
                {this.state._imageUploading || this.state._documentUploading ?
                    <div className="file-progress-loader">
                        <div>
                            <Player
                                onEvent={(event) => {
                                    if (event === "load" && this.player && this.player.current) this.player.current.play();
                                }}
                                ref={this.player} // set the ref to your class instance
                                autoplay={true}
                                loop={true}
                                controls={true}
                                src="/lf30_editor_l5cxzdyf.json"
                                renderer={'svg'}
                                style={{ height: "128px", width: "128px", }}
                            ></Player>
                            <h6>{'Processing file...'.translate(this.props.lang)}</h6>
                        </div>
                    </div>
                    : null
                }
                <Container fluid>
                    <div className="panel select-clinics-wrapper">
                        <h5 className="component-header mb-35">{this.props.linksMeta && this.props.linksMeta[this.props[0].location.pathname] ? this.props.linksMeta[this.props[0].location.pathname][this.props.lang] : ''}</h5>

                        <Row>

                            {this.state.assignedList ?
                                <Col lg="4">
                                    <div className={typeof window != 'undefined' && window.innerWidth < 768 && this.state.displayNone ? "support-ticket-none" : "support-ticket"}>

                                        <>
                                            {this.state.assignedTickets && this.state.assignedTickets.length ? this.state.assignedTickets.sort(function (a, b) {
                                                if (!a.seenSupport && b.seenSupport) return -1;
                                                if (a.seenSupport && !b.seenSupport) return 1;
                                                return b.timestemp - a.timestemp
                                            }).sort(function (a, b) { return b.close - a.close }).map(item => {
                                                return (

                                                    <button
                                                        className={this.state.messageModal && this.state.messageModal._id == item._id ? item.close ? 'ticket-closed active-ticket-red' : item.supportUserWhoTakeTicket ? 'ticked-open active-ticket-green' : 'ticket-inprogress active-ticket-inprogress' : item.close ? 'ticket-closed' : item.supportUserWhoTakeTicket ? 'ticked-open' : 'ticket-inprogress'}

                                                        onClick={() => {
                                                            if (this.state.messageModal)
                                                                this.checkDraftMessages(this.state.messageModal)
                                                            this.setState({ messageModal: null },
                                                                () => {
                                                                    this.setState({
                                                                        messageModal: item, displayNone: true
                                                                    }, () => {
                                                                        setTimeout(() => {
                                                                            if (this.myRef && this.myRef.scrollHeight) {
                                                                                this.myRef.scrollTop = this.myRef.scrollHeight;
                                                                            }
                                                                        }, 200)


                                                                        this.checkDraftMessages(this.state.messageModal, true), this.seen(item._id), this.findMessages(item._id), this.notifSeenUpdate(item._id)
                                                                    })
                                                                })
                                                        }}>
                                                        <h6 className='s-heading'>{item.name ? item.name : 'Site - support'}</h6>
                                                        <h6><span className='bold'>{'Support-ID:'.translate(this.props.lang)}</span> {item.tiketNumber} - {item.subject ? item.subject : 'Site'}</h6>
                                                        <h6><span className='bold'>{'Clinic:'.translate(this.props.lang)}</span> {item.clinicName ? item.clinicName : item.organization ? item.organization : ''}</h6>
                                                        {item.userWhoCreatedTicket && item.userWhoCreatedTicket.toString() != this.props.uData._id.toString() ? !item.seenSupport ? <p className="ticket-notification"></p> : '' : !item.seenUser && item.userWhoCreatedTicket ? <p className="ticket-notification"></p> : item.userWhoCreatedTicket ? '' : !item.seenSupport ? <p className="ticket-notification"></p> : ''}
                                                        <h6>{item.email ? <><span className='bold'>{`${'E-mail:'.translate(this.props.lang)}`}</span>  {`${item.email}`}</> : null}</h6>
                                                        <h6> {item.phone ? <p><span className='bold'>{'Phone'.translate(this.props.lang)}: </span> {item.phone}</p> : null}</h6>
                                                        <div className="ticket-body">
                                                            {item.profilePicture ?
                                                                <img src={API_ENDPOINT + item.profilePicture} />

                                                                :
                                                                <img src={profileUser} />

                                                            }

                                                            <h6>{item.userName ? item.userName : item.name ? item.name : ''}</h6>
                                                            <p>{this.props.getStringDateTs(item.timestemp, `${this.props.dateFormat} HH:mm`)/*moment.unix(item.timestemp).format(`${this.props.dateFormat} HH:mm`)*/}</p>
                                                        </div>
                                                    </button>
                                                )
                                            })
                                                : null}
                                        </>
                                    </div>
                                    {this.state.total > 5 && this.state.total > this.state.tikets.length && !(typeof window != 'undefined' && window.innerWidth < 768 && this.state.messageModal) ?
                                        <Button color='primary' onClick={() => this.setState({ pageNumber: this.state.pageNumber + 1 }, () => this.get())} >{'Load more'.translate(this.props.lang)}</Button>
                                        : null}
                                </Col>
                                : null}

                            <Col lg="8">
                                <div className="ticket-answer" style={{ height: (!this.state.messageModal && typeof window != 'undefined' && window.innerWidth < 768) ? 0 : 'auto' }}>
                                    {this.state.messageModal && typeof window != 'undefined' && window.innerWidth > 768 ?
                                        <div className="ticket-message-tab-container" ref={node => this.myRef = node}>

                                            {this.state.messages && this.state.messages.length && this.state.messages.sort(function (a, b) { return a.timestemp - b.timestemp }).map(item => {
                                                return (
                                                    <div className={item.user == this.props.uData.name ? 'mesage-box user-message' : 'mesage-box'}>
                                                        <div className="message-header">
                                                            <div className="message-header-container">
                                                                {item.profilePicture ?
                                                                    <img src={API_ENDPOINT + item.profilePicture} />

                                                                    :
                                                                    <img src={profileUser} />

                                                                }
                                                                <h6>{item.user}</h6>
                                                            </div>
                                                            <p>{this.props.getStringDateTs(item.timestemp, `${this.props.dateFormat} HH:mm`)/*moment.unix(item.timestemp).format(`${this.props.dateFormat} HH:mm`)*/}</p>
                                                        </div>
                                                        <p className='message-content'>
                                                            {item.message}
                                                            <div>
                                                                {item.image && item.image.length && typeof item.image == 'object' ?
                                                                    item.image.map(img => {
                                                                        return (
                                                                            <a className='attach-icon' target='_blank' href={API_ENDPOINT + img}><Isvg src={attach} style={{ width: 20, height: 20 }} /><span>{'Open attachment'.translate(this.props.lang)}</span></a>
                                                                        )

                                                                    })
                                                                    :
                                                                    item.image && item.image.length > 0 ?
                                                                        <a className='attach-icon' target='_blank' href={API_ENDPOINT + item.image}><Isvg src={attach} style={{ width: 20, height: 20 }} /><span>{'Open attachment'.translate(this.props.lang)}</span></a>
                                                                        :
                                                                        null
                                                                }
                                                                {
                                                                    item.document && item.document.length && typeof item.document == 'object' ?
                                                                        item.document.map(doc => {
                                                                            return (
                                                                                <div className="document" style={{ paddingLeft: 0, marginTop: 0 }}><a style={{ marginTop: 0 }} className='attach-icon' target="_blank" download href={API_ENDPOINT + doc.file}><Isvg src={attach} style={{ width: 20, height: 20 }} /><span>{'Open attachment'.translate(this.props.lang)}</span></a></div>
                                                                            )
                                                                        })
                                                                        :
                                                                        item.document && item.document.file ?
                                                                            <div className="document" style={{ paddingLeft: 0, marginTop: 0 }}><a style={{ marginTop: 0 }} className='attach-icon' target="_blank" download href={API_ENDPOINT + item.document.file}><Isvg src={attach} style={{ width: 20, height: 20 }} /><span>{'Open attachment'.translate(this.props.lang)}</span></a></div>
                                                                            :
                                                                            null
                                                                }
                                                            </div>

                                                        </p>
                                                    </div>

                                                )
                                            })}

                                            {this.props.uData.userLevel != 1 && this.props.uData.userLevel != 2 ?
                                                <Button className={'close-support-button-text message-option-button'} onClick={() => {
                                                    fetch(API_ENDPOINT + '/support/support-users', {
                                                        method: 'POST',
                                                        headers: {
                                                            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                                                            'content-type': 'application/json'
                                                        },
                                                        body: JSON.stringify({ group: this.state.selectedTicket.group, tiket: this.state.selectedTicket.tiket, supportPriorityLevel: this.state.selectedTicket.supportPriorityLevel })
                                                    }).then(res => res.json()).then((result) => {
                                                        if (result)
                                                            this.setState({
                                                                forwardSupportTicketModal: this.state.selectedTicket,
                                                                supportAgents: [...result]
                                                            })
                                                    })
                                                }} disabled={this.state.selectedTicket && this.state.selectedTicket.close}>
                                                    {'Forward support ticket'.translate(this.props.lang)}
                                                </Button> : null}

                                            {this.props.uData.userLevel != 1 && this.props.uData.userLevel != 2 ?
                                                <Button className={'close-support-button-text message-option-button'} style={{ marginLeft: 20 }} onClick={() => this.findConclusions(this.state.selectedTicket._id)} disabled={this.state.selectedTicket && this.state.selectedTicket.close}>
                                                    {this.state.selectedTicket && this.state.selectedTicket.close ? 'Closed'.translate(this.props.lang) : 'Close tiket'.translate(this.props.lang)}
                                                </Button> : null}

                                            {((this.state.selectedTicket && !this.state.selectedTicket.userWhoCreatedTicket) && (this.state.selectedTicket && !this.state.selectedTicket.clinic)) ?
                                                <Button className={'close-support-button-text message-option-button'} style={{ marginLeft: 20 }} onClick={() => this.setState({ assignedClinicModal: this.state.selectedTicket._id })}>
                                                    {'Assigned clinic'.translate(this.props.lang)}
                                                </Button> : null}


                                            {this.state.messageModal.close ?
                                                null
                                                : <FormGroup className="ticket-message-group ticket-message-tab">
                                                    <Label>{'Message'.translate(this.props.lang)}</Label>
                                                    <Input type='textarea' rows="6" className="textarea-six-row" onChange={(e) => {
                                                        let messageModal = this.state.messageModal;
                                                        messageModal.answer = e.target.value;
                                                        this.setState({
                                                            messageModal
                                                        })
                                                    }} value={this.state.messageModal.answer} />
                                                </FormGroup>}
                                            <div style={{ background: 'white' }}>
                                                {
                                                    this.state.document && this.state.document.length ?
                                                        this.state.document.map(doc => {
                                                            if (doc && doc.file)
                                                                return (
                                                                    <div className="document" style={{ paddingLeft: 0, marginTop: 0 }}><a target="_blank" download href={API_ENDPOINT + doc.file}><Isvg src={attach} style={{ width: 20, height: 20 }} />{'Open attachment'.translate(this.props.lang)}</a></div>
                                                                )
                                                        })
                                                        :
                                                        null
                                                }
                                            </div>

                                            {this.state.messageModal.close || !this.state.messageModal.clinic ? null :
                                                <Button color="primary" style={{ marginBottom: 20, marginRight: 10 }} className={this.state._documentUploading ? 'attach uploading' : this.state.document ? 'attach uploaded' : "attach"} onClick={() => { this.setState({ attachFile: true }) }}>
                                                    {'Attach files'.translate(this.props.lang)}
                                                </Button>}
                                            {this.state.messageModal.close ? null :
                                                <Button className="news-ticket-button" disabled={this.state.messageModal.answer || this.state.image || this.state.document ? this.state.messageModal.answer != '' || this.state.image != '' || this.state.document != '' ? false : true : true} onClick={() => {
                                                    if (!this.state.stopSend)
                                                        this.setState({ stopSend: true }, () => { this.sendAnswer(this.state.messageModal) })
                                                }}>{'Send'.translate(this.props.lang)}</Button>
                                            }
                                        </div>
                                        :
                                        this.state.messageModal && typeof window != 'undefined' && window.innerWidth < 768 ?
                                            <div className="ticket-message-tab-container ticket-message-tab-container-fixed">
                                                <button className="support-back-button" onClick={() => { this.setState({ displayNone: false, messageModal: !this.state.messageModal }) }}>{'Back'.translate(this.props.lang)}</button>
                                                {this.state.messages.sort(function (a, b) { return a.timestemp - b.timestemp }).map(item => {
                                                    return (
                                                        <div className="mesage-box">
                                                            <div className="message-header">
                                                                <div className="message-header-container">
                                                                    {item.profilePicture ?
                                                                        <img src={API_ENDPOINT + item.profilePicture} />

                                                                        :
                                                                        <img src={profileUser} />

                                                                    }
                                                                    <h6>{item.user ? item.user : ''}</h6>
                                                                </div>
                                                                <p>{this.props.getStringDateTs(item.timestemp, `${this.props.dateFormat} HH:mm`)/*moment.unix(item.timestemp).format(`${this.props.dateFormat} HH:mm`)*/}</p>
                                                            </div>
                                                            <p>
                                                                {item.message}
                                                                <div >
                                                                    {item.image && item.image.length && typeof item.image == 'object' ?
                                                                        item.image.map(img => {
                                                                            return (
                                                                                <a className='attach-icon' target='_blank' href={API_ENDPOINT + img}><Isvg src={attach} style={{ width: 20, height: 20 }} /><span>{'Open attachment'.translate(this.props.lang)}</span></a>
                                                                            )

                                                                        })
                                                                        :
                                                                        item.image && item.image.length > 0 ?
                                                                            <a className='attach-icon' target='_blank' href={API_ENDPOINT + item.image}><Isvg src={attach} style={{ width: 20, height: 20 }} /><span>{'Open attachment'.translate(this.props.lang)}</span></a>
                                                                            :
                                                                            null
                                                                    }
                                                                    {
                                                                        item.document && item.document.length && typeof item.document == 'object' ?
                                                                            item.document.map(doc => {
                                                                                return (
                                                                                    <div className="document" style={{ paddingLeft: 0, marginTop: 0 }}><a style={{ marginTop: 0 }} className='attach-icon' target="_blank" download href={API_ENDPOINT + doc.file}><Isvg src={attach} style={{ width: 20, height: 20 }} /><span>{'Open attachment'.translate(this.props.lang)}</span></a></div>
                                                                                )
                                                                            })
                                                                            :
                                                                            item.document && item.document.file ?
                                                                                <div className="document" style={{ paddingLeft: 0, marginTop: 0 }}><a style={{ marginTop: 0 }} className='attach-icon' target="_blank" download href={API_ENDPOINT + item.document.file}><Isvg src={attach} style={{ width: 20, height: 20 }} /><span>{'Open attachment'.translate(this.props.lang)}</span></a></div>
                                                                                :
                                                                                null
                                                                    }
                                                                </div>
                                                            </p>
                                                        </div>

                                                    )
                                                })}

                                                {this.props.uData.userLevel != 1 && this.props.uData.userLevel != 2 ?
                                                    <Button className={'close-support-button-text message-option-button'} onClick={() => {
                                                        fetch(API_ENDPOINT + '/support/support-users', {
                                                            method: 'POST',
                                                            headers: {
                                                                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                                                                'content-type': 'application/json'
                                                            },
                                                            body: JSON.stringify({ group: this.state.selectedTicket.group, tiket: this.state.selectedTicket.tiket, supportPriorityLevel: this.state.selectedTicket.supportPriorityLevel })
                                                        }).then(res => res.json()).then((result) => {
                                                            if (result)
                                                                this.setState({
                                                                    forwardSupportTicketModal: this.state.selectedTicket,
                                                                    supportAgents: [...result]
                                                                })
                                                        })
                                                    }} disabled={this.state.selectedTicket && this.state.selectedTicket.close}>
                                                        {'Forward support ticket'.translate(this.props.lang)}
                                                    </Button> : null}

                                                {this.props.uData.userLevel != 1 && this.props.uData.userLevel != 2 ?
                                                    <Button className={'close-support-button-text message-option-button'} style={{ marginLeft: 20 }} onClick={() => this.findConclusions(this.state.selectedTicket._id)} disabled={this.state.selectedTicket && this.state.selectedTicket.close}>
                                                        {this.state.selectedTicket && this.state.selectedTicket.close ? 'Closed'.translate(this.props.lang) : 'Close tiket'.translate(this.props.lang)}
                                                    </Button> : null}

                                                {((this.state.selectedTicket && !this.state.selectedTicket.userWhoCreatedTicket) && (this.state.selectedTicket && !this.state.selectedTicket.clinic)) ?
                                                    <Button className={'close-support-button-text message-option-button'} style={{ marginLeft: 20 }} onClick={() => this.setState({ assignedClinicModal: this.state.selectedTicket._id })}>
                                                        {'Assigned clinic'.translate(this.props.lang)}
                                                    </Button> : null}

                                                {this.state.messageModal.close ?
                                                    null
                                                    : <FormGroup className="ticket-message-group ticket-message-tab">
                                                        <Label>{'Message'.translate(this.props.lang)}</Label>
                                                        <Input type='textarea' rows="6" className="textarea-six-row" onChange={(e) => {
                                                            let messageModal = this.state.messageModal;
                                                            messageModal.answer = e.target.value;
                                                            this.setState({
                                                                messageModal
                                                            })
                                                        }} value={this.state.messageModal.answer} />
                                                    </FormGroup>}
                                                <div style={{ background: 'white' }}>
                                                    {
                                                        this.state.document && this.state.document.length ?
                                                            this.state.document.map(doc => {
                                                                if (doc && doc.file)
                                                                    return (
                                                                        <div className="document" style={{ paddingLeft: 0, marginTop: 0 }}><a target="_blank" download href={API_ENDPOINT + doc.file}><Isvg src={attach} style={{ width: 20, height: 20 }} />{'Open attachment'.translate(this.props.lang)}</a></div>
                                                                    )
                                                            })
                                                            :
                                                            null
                                                    }
                                                </div>
                                                <div className="chat-buttons-footer-support">
                                                    {this.state.messageModal.close || !this.state.messageModal.clinic ? null :
                                                        <Button color="primary" style={{ marginBottom: 20, marginRight: 10 }} className={this.state._documentUploading ? 'attach uploading' : this.state.document ? 'attach uploaded' : "attach"} onClick={() => { this.setState({ attachFile: true }) }}>
                                                            {'Attach files'.translate(this.props.lang)}
                                                        </Button>}
                                                    {this.state.messageModal.close ? null :

                                                        <Button className="news-ticket-button" disabled={this.state.messageModal.answer || this.state.image || this.state.document ? this.state.messageModal.answer != '' || this.state.image != '' || this.state.document != '' ? false : true : true} onClick={() => {
                                                            if (!this.state.stopSend)
                                                                this.setState({ stopSend: true }, () => { this.sendAnswer(this.state.messageModal) })
                                                        }}>{'Send'.translate(this.props.lang)}</Button>
                                                    }
                                                </div>
                                            </div>
                                            : null}
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
                <input ref={(node) => this.documentInput = node} type="file" accept=".doc, .docx, .pdf" title={"No file chosen".translate(this.props.lang)} key={this.state.uploadTimestampDoc} onChange={this.uploadDocument} style={{ pointerEvents: 'none', opacity: 0 }} />
                <input ref={(node) => this.imageInput = node} type="file" title={"No file chosen".translate(this.props.lang)} accept="image/png, image/jpeg, image/jpg, image/gif, image/svg" key={this.state.uploadTimestamp} onChange={this.uploadImage} style={{ pointerEvents: 'none', opacity: 0 }} />

                {this.state.error ? (
                    <ErrorModal lang={this.props.lang}
                        isOpen={this.state.error}
                        toggle={() => this.setState({ error: null })}

                    >
                        {this.state.error.translate(this.props.lang)}
                    </ErrorModal>
                ) : null}
                {this.state.groupError ? (
                    <Modal isOpen={this.state.groupError} centered>
                        <ModalBody className="delete-modal">
                            <p className="message">{'You have to select the group!'.translate(this.props.lang)}</p>
                        </ModalBody>

                        <ModalFooter style={{ display: 'flex', justifyContent: 'center' }}>
                            <Button style={{ minWidth: 100 }} onClick={() => {
                                this.props[0].history.push(`/home-patient-level`);
                            }}>{'Close'.translate(this.props.lang)}</Button>
                        </ModalFooter>
                    </Modal>
                ) : null}
                {this.state.closeTicketModal ? (
                    <Modal isOpen={this.state.closeTicketModal} centered size='lg'>
                        <ModalBody className="delete-modal">
                            <p className='message-info'>{'Do you really want to close this ticket?'.translate(this.props.lang)}</p>
                            <FormGroup>
                                <Input type='select' value={this.state.closeConclusion ? this.state.closeConclusion : 'none'} onChange={(e) => {
                                    this.setState({
                                        closeConclusion: e.target.value
                                    })
                                }}>
                                    <option value={-1}>{'Select one conclusion'.translate(this.props.lang)}</option>
                                    {
                                        this.state.conclusions && this.state.conclusions.map((item, idx) => {
                                            return (
                                                <option value={item._id}>
                                                    {
                                                        this.props.lang === 'en' ? item.conclusionsEn : item.conclusionsSe
                                                    }
                                                </option>
                                            )
                                        })

                                    }
                                </Input>
                            </FormGroup>
                            <FormGroup>
                                <Input type='select' value={this.state.selectedCriticality ? this.state.selectedCriticality : 'none'} onChange={(e) => {
                                    this.setState({
                                        selectedCriticality: e.target.value
                                    })
                                }}>
                                    <option value={-1}>{'Select criticality'.translate(this.props.lang)}</option>
                                    {
                                        this.state.criticality && this.state.criticality.map((item, idx) => {
                                            return (
                                                <option value={item._id}>
                                                    {
                                                        this.props.lang === 'en' ? item.criticalityEn : item.criticalitySe
                                                    }
                                                </option>
                                            )
                                        })

                                    }
                                </Input>

                                <p>
                                    {this.props.lang === 'en' ?
                                        this.state.criticality && this.state.selectedCriticality && this.state.selectedCriticality !== '-1' && this.state.criticality.filter(item => item._id === this.state.selectedCriticality)[0]?.descriptionEn ? this.state.criticality.filter(item => item._id === this.state.selectedCriticality)[0]?.descriptionEn : ''
                                        :
                                        this.state.criticality && this.state.selectedCriticality && this.state.selectedCriticality !== '-1' && this.state.criticality.filter(item => item._id === this.state.selectedCriticality)[0]?.descriptionSe ? this.state.criticality.filter(item => item._id === this.state.selectedCriticality)[0]?.descriptionSe : ''

                                    }
                                </p>

                            </FormGroup>




                        </ModalBody>
                        <ModalFooter className='buttons-right-blue'>
                            <Button disabled={!this.state.closeConclusion || !this.state.selectedCriticality || this.state.selectedCriticality === '-1' || this.state.closeConclusion === '-1'} color="primary" style={{ margin: 10, minWidth: 100 }} onClick={() => this.closeTiket(this.state.closeTicketModal)}>{'Yes'.translate(this.props.lang)}</Button>
                            <Button color="primary" style={{ margin: 10, minWidth: 100 }} onClick={() => this.setState({ closeTicketModal: null })}>{'No'.translate(this.props.lang)}</Button>

                        </ModalFooter>

                    </Modal>
                ) : null}

                <Modal isOpen={this.state.assignedClinicModal} centered size="xl">
                    <ModalHeader toggle={() => this.setState({ assignedClinicModal: !this.state.assignedClinicModal })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ assignedClinicModal: !this.state.assignedClinicModal })}>&times;</button>}>{'Select clinic'.translate(this.props.lang)}</ModalHeader>

                    <ModalBody>
                        <UpdateAssignedClinicGroup {...this.props} assignedClinicModal={this.state.assignedClinicModal} close={(data) => {
                            this.setState({
                                assignedClinicModal: !this.state.assignedClinicModal,
                                messageModal: data
                            }, () => {
                                this.get()
                            })

                        }}></UpdateAssignedClinicGroup>
                    </ModalBody>

                </Modal>


                <Modal isOpen={this.state.attachFile} centered>
                    <ModalHeader toggle={() => this.setState({ attachFile: null })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ attachFile: null })}>&times;</button>}>{'Upload file'.translate(this.props.lang)}</ModalHeader>
                    <ModalBody>

                        <FormGroup tag="fieldset">
                            <File group={this.props.selectedGroup} lang={this.props.lang} onChange={(files) => {
                                if (this.state.supportModal) {
                                    this.setState({
                                        documentTicket: files,

                                    })
                                } else {
                                    if (this.state.document) {
                                        this.setState({
                                            document: files
                                        })
                                    } else {
                                        this.setState({
                                            document: files
                                        })
                                    }
                                }

                            }} value={this.state.supportModal ? this.state.documentTicket : this.state.document} />

                        </FormGroup>

                    </ModalBody>
                    <ModalFooter className='buttons-right-blue'>
                        <Button color="primary" style={{ margin: 10 }} className={this.state._documentUploading ? 'attach uploading' : this.state.documentTicket ? 'attach uploaded' : "attach"} onClick={() => {
                            this.setState({ attachFile: null })
                        }}>
                            <div className='chat-icons-tooltip'>
                                {'Attach'.translate(this.props.lang)}
                            </div>
                        </Button>
                    </ModalFooter>
                </Modal>
                {
                    this.state.forwardSupportTicketModal ?
                        <Modal isOpen={this.state.forwardSupportTicketModal} centered>
                            <ModalHeader toggle={() => this.setState({ forwardSupportTicketModal: null, upgradeSupportTicketLine: null, changeSupportTicketAgent: null })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ forwardSupportTicketModal: null, upgradeSupportTicketLine: null, changeSupportTicketAgent: null })}>&times;</button>}>{'Forward support ticket'.translate(this.props.lang)}</ModalHeader>
                            <ModalBody>

                                <FormGroup>
                                    <Input disabled={this.state.changeSupportTicketAgent && this.state.changeSupportTicketAgent !== this.state.forwardSupportTicketModal.supportUserWhoTakeTicket} type='select' value={this.state.upgradeSupportTicketLine ? this.state.upgradeSupportTicketLine : this.state.forwardSupportTicketModal.supportUserWhoTakeTicket} onChange={(e) => {
                                        this.setState({
                                            upgradeSupportTicketLine: e.target.value
                                        })
                                    }} >
                                        {[{ name: 'Forward ticket to higher line'.translate(this.props.lang), value: this.state.forwardSupportTicketModal.supportUserWhoTakeTicket }, { name: '2nd line'.translate(this.props.lang), value: 2 }, { name: '3rd line'.translate(this.props.lang), value: 3 }].map(item => {
                                            if ((item.value > this.state.forwardSupportTicketModal.supportPriorityLevel && this.state.supportAgents.filter(lvl => lvl.supportPriorityLevel === item.value).length) || (item.value === this.state.forwardSupportTicketModal.supportUserWhoTakeTicket))
                                                return (
                                                    <option value={item.value}>{item.name.translate(this.props.lang)}</option>
                                                )
                                        })}
                                    </Input>
                                </FormGroup>
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    {'or'.translate(this.props.lang)}
                                </div>
                                <FormGroup>
                                    <Input disabled={this.state.upgradeSupportTicketLine && this.state.upgradeSupportTicketLine !== this.state.forwardSupportTicketModal.supportUserWhoTakeTicket} type='select' value={this.state.changeSupportTicketAgent ? this.state.changeSupportTicketAgent : this.state.forwardSupportTicketModal.supportUserWhoTakeTicket} onChange={(e) => {
                                        this.setState({
                                            changeSupportTicketAgent: e.target.value
                                        })
                                    }} >
                                        {this.state.supportAgents && [{ name: 'Forward ticket to other support agent'.translate(this.props.lang), _id: this.state.forwardSupportTicketModal.supportUserWhoTakeTicket }, ...this.state.supportAgents.filter(item => item._id !== this.state.forwardSupportTicketModal.supportUserWhoTakeTicket)].map(item => {
                                            let adminLine = '1st line'
                                            if (item.supportPriorityLevel) {
                                                if (item.supportPriorityLevel === 2) {
                                                    adminLine = '2nd line'
                                                } else if (item.supportPriorityLevel === 3) {
                                                    adminLine = '3rd line'
                                                }
                                            }
                                            return (
                                                <option value={item._id}>{item.name && item.supportPriorityLevel ? item.name + ' - ' + adminLine.translate(this.props.lang) : item.name ? item.name : ''}</option>
                                            )
                                        })}
                                    </Input>
                                </FormGroup>

                            </ModalBody>
                            <ModalFooter className='buttons-right-blue'>
                                <Button disabled={(!this.state.upgradeSupportTicketLine && !this.state.changeSupportTicketAgent) || this.state.changeSupportTicketAgent === this.state.forwardSupportTicketModal.supportUserWhoTakeTicket || this.state.upgradeSupportTicketLine === this.state.forwardSupportTicketModal.supportUserWhoTakeTicket} onClick={() => {
                                    this.forwardSupportTicket()
                                }}> {'OK'.translate(this.props.lang)} </Button>
                            </ModalFooter>
                        </Modal>
                        :
                        null
                }


            </div>



        );


    }
}

export default Page(HomePage);