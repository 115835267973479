import {API_ENDPOINT} from '../constants';

const addPx = [
    'maxWidth',
    'marginTop',
    'marginBottom',
    'marginLeft',
    'lineHeight',
    'marginRight',
    'paddingTop',
    'paddingLeft',
    'paddingRight',
    'paddingBottom',
    'borderTopLeftRadius',
    'borderTopRightRadius',
    'borderBottomLeftRadius',
    'borderBottomRightRadius',
]


export const formatCSS = (cssStyle) => {
    
    if (!cssStyle){
        return {};
    }

    let style = JSON.parse(JSON.stringify(cssStyle));

    if (style && style.backgroundImage){
        style.backgroundImage = `url(${API_ENDPOINT + style.backgroundImage})`;
    }

    for(let i=0;i<addPx.length;i++){
        if (style && style[addPx[i]] && !/[^-.0-9]+/g.test(style[addPx[i]])){
            style[addPx[i]] = style[addPx[i]]+'px';
        }
    }

    return style;
}