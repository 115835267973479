import React, { Component } from 'react';
import Page from '../../containers/page';

import {
    Container,
    Row,
    Col,
    Modal, ModalHeader, ModalBody, ModalFooter, Button, Label, FormGroup, Input, Alert,

} from 'reactstrap';

import Isvg from 'react-inlinesvg'
import { formValueSelector, change } from 'redux-form';  // ES6
import { connect } from 'react-redux';

import FormBuilder from '../../components/forms/modalFormBuilder';
import { required, emailAddress, checkSocialSecurityNumber, dateValidate } from '../../components/forms/validation';

import ListBuilder from '../../components/listBuilder';
import DeleteModal from '../../components/deleteModal';
import ErrorModal from '../../components/errorModal';
import InfoModal from '../../components/infoModal';
import edit from '../../assets/svg/edit.svg'
import billInfoIcon from '../../assets/svg/bill_info.svg';
import garabage from '../../assets/svg/garbage.svg'
import view from '../../assets/svg/eye.svg'
import Search from '../../components/search';
import addIcon from '../../assets/svg/add-icon.svg';
import cloneIcon from '../../assets/svg/clone.svg';
import garbageIcon from '../../assets/svg/garbage.svg';


import profileUser from '../../assets/images/user.png';
import notification from '../../assets/svg/notification.svg'
import moreIcon from '../../assets/svg/more.svg'

import { Player, Controls } from '@lottiefiles/react-lottie-player';

import PatientFilter from '../../components/patientsList';
import viewIcon from '../../assets/svg/eye.svg';
import xIcon from '../../assets/svg/x.svg';
import PrintHelper from '../../components/printHelper';

import ChatIcon from '../../assets/svg/chat-icon.svg'
import ChatIcon2 from '../../assets/svg/chatIcon2.svg'
import TriangleIcon from '../../assets/svg/alert-triangle-icon.svg';

import formIcon from '../../assets/svg/attach-form.svg';

import { getSearchParams, generateSearchLink, generateSearchLinkMultiple } from '../../helpers/linkHelper';
import { API_ENDPOINT, CHILD_AGE_LIMIT } from '../../constants';
import SocialSecurityNumber, { isSocialSecurityNumberValid } from '../../components/forms/fields/socialSecurityNumber';

import infoIcon from "../../assets/svg/link-new-tab.svg";
import transferIcon from '../../assets/svg/transfer-icon.svg';

import moment from 'moment';

import linkSvg from '../../assets/svg/users.svg';
import lockSvg from '../../assets/svg/lock.svg';
import userSvg from '../../assets/svg/user.svg';
import Phone from '../../components/forms/fields/phone';
import warrning from '../../assets/svg/warrning.svg';
import pickTask from '../../assets/svg/pick_task.svg';
import QuestionMark from '../../assets/svg/question.svg'
import ArrowDown from '../../assets/svg/arrow-down.svg'

import webdocLogo from '../../assets/images/webdoc.png'



import Checkbox from '../../components/forms/fields/checkbox';

import DatePicker from '../../components/forms/fields/datePicker';
import TimePicker from '../../components/forms/fields/timepicker';
import TimePickerV2 from '../../components/forms/fields/timepicker_v2';

import ListCheckBox from '../../components/forms/fields/listCheckboxFlex';
import LinkNewAccoutn from './components/linkNewAccount';
import LinkedAccoutns from './components/linkedAccounts';
import Daybook from './components/daybook';
import TestResults from './components/testResults';
import ListOfMedicines from './components/listOfMedicines';
import PhoneField from '../../components/forms/fields/phone';

// import FormPreview from '../../components/formPreview';
import FormPreview from '../forms/previewAnsweredForm';


import ReferralContent from '../../components/referralContent';

import striptags from 'striptags';

import cameraIcon from '../../assets/svg/camera.svg';
import paymentIcon from '../../assets/svg/payment-icon.svg';
import Statements from './components/statements';
import ConsultationModal from '../../components/e-connect/ConsultationModal';

import copyLinkIcon from '../../assets/svg/copy-icon.svg';
import openLinkIcon from '../../assets/svg/open-link.svg';
import PatientDocuments from './components/patientDocuments';

const isStringAValidNumber = (string) => {
    if (typeof string !== 'string') return false;
    return !isNaN(string) && !isNaN(parseFloat(string));
};


function parseJSON(response) {
    return response.json().then(json => {
        return {
            result: json,
            status: response.status
        }
    })
}

function pad(n, width, z) {
    z = z || '0';
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
};
export function calculateAgeFromSSN(socialSecurityNumber, country) {
    if (!country) {
        if (typeof window !== 'undefined' && localStorage && localStorage?.getItem?.('country')) country = localStorage?.getItem?.('country');
        else country = 'sweden';
    }
    let year;
    let month;
    let day;

    try {
        if (country === 'denmark') {
            day = Number(`${socialSecurityNumber[0]}${socialSecurityNumber[1]}`);
            month = Number(`${socialSecurityNumber[2]}${socialSecurityNumber[3]}`);
            const yearLastDigits = Number(`${socialSecurityNumber[4]}${socialSecurityNumber[5]}`);
            const nowYear = new Date().getFullYear();
            const nowYearLastDigits = `${String(nowYear)[2]}${String(nowYear)[3]}`;
            if (yearLastDigits === 0) year = 2000;
            else {
                if (yearLastDigits >= +nowYearLastDigits) year = Number(`19${yearLastDigits < 10 ? '0' + yearLastDigits : yearLastDigits}`);
                else year = Number(`20${yearLastDigits < 10 ? '0' + yearLastDigits : yearLastDigits}`);
            }
        } else {
            year = parseInt(`${socialSecurityNumber[0]}${socialSecurityNumber[1]}${socialSecurityNumber[2]}${socialSecurityNumber[3]}`);
            month = parseInt(`${socialSecurityNumber[4]}${socialSecurityNumber[5]}`);
            day = parseInt(`${socialSecurityNumber[6]}${socialSecurityNumber[7]}`);
        }
    } catch (err) {
        return 0;
    }

    let birthday = new Date(year, month - 1, day);

    var ageDifMs = Date.now() - birthday;
    var ageDate = new Date(ageDifMs); // miliseconds from epoch
    return Math.abs(ageDate.getUTCFullYear() - 1970);
};
function calculateAge(birthday) { // birthday is a date
    var ageDifMs = Date.now() - birthday;
    var ageDate = new Date(ageDifMs); // miliseconds from epoch
    return Math.abs(ageDate.getUTCFullYear() - 1970);
};
export function validateSocialSecurityNumber(socialSecurityNumber, parent = false, country) {
    if (!country) {
        if (typeof window !== 'undefined' && localStorage && localStorage?.getItem?.('country')) country = localStorage?.getItem?.('country');
        else country = 'sweden';
    }

    if (!socialSecurityNumber) {
        return { error: 'Social security number is not valid' };
    }

    if (country === 'denmark') {
        let answer = socialSecurityNumber;
        if (answer.length !== 10) return { error: 'Social security number is not valid' };

        const day = Number(`${answer[0]}${answer[1]}`);
        const month = Number(`${answer[2]}${answer[3]}`);
        const yearLastDigits = Number(`${answer[4]}${answer[5]}`);

        if (!day || !month) return { error: 'Social security number is not valid' };
        if (day < 1 || day > 31) return { error: 'Social security number is not valid' };
        if (month < 1 || month > 12) return { error: 'Social security number is not valid' };

        const nowYear = new Date().getFullYear();
        const nowYearLastDigits = `${String(nowYear)[2]}${String(nowYear)[3]}`;

        let year;
        if (yearLastDigits === 0) year = 2000;
        else {
            if (yearLastDigits >= +nowYearLastDigits) year = Number(`19${yearLastDigits < 10 ? '0' + yearLastDigits : yearLastDigits}`);
            else year = Number(`20${yearLastDigits < 10 ? '0' + yearLastDigits : yearLastDigits}`);
        }

        if (year > nowYear || year < (nowYear - 150)) return { error: 'Social security number is not valid' };

        let daysInMoth = new Date(year, month, 0).getDate();

        if (day > daysInMoth) return { error: 'Social security number is not valid' };

        let date = new Date(year, month - 1, day);

        if (parent) {
            if (calculateAge(date) < 18) {
                return { error: 'The parent is under 18 years old' }
            }
        }

        return { error: null };
    }

    let year = parseInt(`${socialSecurityNumber[0]}${socialSecurityNumber[1]}${socialSecurityNumber[2]}${socialSecurityNumber[3]}`);
    let month = parseInt(`${socialSecurityNumber[4]}${socialSecurityNumber[5]}`);
    let day = parseInt(`${socialSecurityNumber[6]}${socialSecurityNumber[7]}`);
    let date = new Date(year, month - 1, day);

    if (month > 12) {
        return { error: 'Social security number is not valid' };
    }

    if (day > 31) {
        return { error: 'Social security number is not valid' };
    }


    let daysInMoth = new Date(year, month, 0).getDate();


    if (day > daysInMoth) {
        return { error: 'Social security number is not valid' };
    }

    if (parent) {
        if (calculateAge(date) < 18) {
            return { error: 'The parent is under 18 years old' }
        }
    }



    return { error: null };

};


/**
* List of patients for selected clinic and selected department
* @author   Aleksandar Dabic
*/
class Patients extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...props.initialData,
            form: false,
            useState: false,
            params: {
                entries: 10,
                page: 0
            },
            items: [],
            endpoints: [],
            formModules: [],
            userLevels: [],
            languages: [],
            formUsers: [],
            total: 0,
            loading: true,
            patientSearched: null,
            cases: [],
            totalCases: 0,
            lastSerach: [],
            chatConversation: null,
            doctorsList: [],
            values: [],
            activeRevisitForm: 1,
            userConnected: false,
            tabDaybook: false,
            saveTestResults: false,
            saveMedicine: false,
            showFirstSegment: false,
            tabActive: 1,
            selectedDoctor: -1,
            updateEditTimeTs: null,
            webDocInfo: null,
            changing2FAStatus: false,
            _videoButtonEnabled: true,
            doctorCanStartVideoCall: false,
            searchByNameAndEmail: false
        };
        this.state.min = new Date();
        this.state.min.setHours(8, 30, 0)
        this.state.max = new Date();
        this.state.max.setHours(20, 30, 0)
    }


    get = () => {
        this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));

        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0, group: this.props.selectedGroup })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

        for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
            this.props.loadDataWithQuery[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0, group: this.props.selectedGroup, clinic: this.props.selectedClinic })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }
        if (this.state.patient) {
            this.getCases()
        }
        if (this.props.selectedGroup) {
            this.getLastOpenedChats()
        }


    }

    generateSections = (itemsArr) => {
        let sections = {};
        let items = [{ type: 0 }, ...itemsArr];
        let lastSectionId = -1;
        for (let i = 0; i < items.length; i++) {
            if (items[i].type == 0) {
                lastSectionId++;
                sections[lastSectionId] = {
                    name: items[i].name,
                    displayOnReferral: false,
                    questions: [],
                    conditions: items[i].conditions ? items[i].conditions : [],
                    displayOnReferral: items[i].displayOnReferral,
                    fontStyle: items[i].fontStyle,
                    fontSize: items[i].fontSize,
                    fontWeight: items[i].fontWeight,

                }
            }
            if (lastSectionId != -1 && items[i].type != 0) {


                let wikiLinks = [];

                items[i].wikiLinks = wikiLinks;


                if (items[i].type == 1) {
                    let displayOnReferral = false;
                    if (items[i].answers) {
                        for (let j = 0; j < items[i].answers.length; j++) {
                            if (items[i].answers[j].text) {
                                displayOnReferral = true;
                            }
                        }
                    }

                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        answers: items[i].answers,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        displayOnReferral: displayOnReferral,
                        wikiLinks: items[i].wikiLinks,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                    });

                } else if (items[i].type == 2) {
                    let displayOnReferral = false;
                    if (items[i].answers) {
                        for (let j = 0; j < items[i].answers.length; j++) {

                            if (items[i].answers[j].text) {
                                displayOnReferral = true;
                            }
                        }
                    }
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        answers: items[i].answers,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        answerPrefix: items[i].answerPrefix,
                        userInput: items[i].userInput,
                        displayOnReferral: displayOnReferral || items[i].answerPrefix ? true : false,
                        wikiLinks: items[i].wikiLinks,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,

                    })
                } else if (items[i].type == 3) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        answerPrefix: items[i].answerPrefix,
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                    })

                } else if (items[i].type == 4) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        text: items[i].text,
                        userInput: items[i].userInput,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                    })
                } else if (items[i].type == 15) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        text: items[i].text,
                        userInput: items[i].userInput,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                    })

                } else if (items[i].type == 5) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                        score: items[i].score
                    })

                } else if (items[i].type == 6) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                        score: items[i].score
                    })

                } else if (items[i].type == 7) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                    })

                } else if (items[i].type == 8) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                    })

                } else if (items[i].type == 9) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                    })

                } else if (items[i].type == 10) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        userInput: items[i].userInput,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                    })
                } else if (items[i].type == 12) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                        score: items[i].score
                    })
                } else if (items[i].type == 14) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                        score: items[i].score
                    })

                } else if (items[i].type == 13) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                        score: items[i].score
                    })

                } else if (items[i].type == 16) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                        blocks: items[i].blocks,
                    })

                } else if (items[i].type == 17) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                        blocks: items[i].blocks,

                    })

                }
            }
        }

        return Object.values(sections);
    }
    checkDoctorsAvailable = (doctorsList, modal, callback) => {
        let event;


        if (modal == 1) {
            event = this.state.updateEvent;
        } else if (modal == 2) {
            event = this.state.scheduleReferral;
        } else if (modal == 3) {
            event = this.state.scheduleControl;
        } else if (modal == 4) {
            event = doctorsList;
            doctorsList = doctorsList.additionalDoctors;
        } else if (modal == 5) {
            event = this.state.scheduleListReferral;
        }

        if (doctorsList) {
            let data = {
                doctorsList: doctorsList,
                startEventReservedDateOriginal: event.date + ' ' + event.startTime,
                endEventReservedDateOriginal: event.date + ' ' + event.endTime,
                startEventReservedDate: this.props.getStringDateInverseTs(Math.floor(new Date(event.date + ' ' + event.startTime).getTime() / 1000), `${this.props.dateFormat} HH:mm`),
                endEventReservedDate: this.props.getStringDateInverseTs(Math.floor(new Date(event.date + ' ' + event.endTime).getTime() / 1000), `${this.props.dateFormat} HH:mm`),
                eventId: event.id
            }
            fetch(API_ENDPOINT + '/working-hours/check/doctors', {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify(data)
            }).then(res => res.json()).then((result) => {
                if (result.error) {
                    this.setState({
                        errorAdditionalDoctors: result.doctorsList
                    })
                } else {
                    this.setState({ doctorsModal: false, successfullyAdditionalDoctors: modal != 4 ? true : false }, () => {
                        if (modal == 1) {
                            let updateEvent = this.state.updateEvent;
                            updateEvent.additionalDoctors = this.state.doctorsList;
                            this.setState({ updateEvent })
                        } else if (modal == 2) {
                            let scheduleReferral = this.state.scheduleReferral;
                            scheduleReferral.additionalDoctors = this.state.doctorsList;
                            this.setState({ scheduleReferral })
                        } else if (modal == 3) {
                            let scheduleControl = this.state.scheduleControl;
                            scheduleControl.additionalDoctors = this.state.doctorsList;
                            this.setState({ scheduleControl })
                        } else if (modal == 5) {
                            let scheduleListReferral = this.state.scheduleListReferral;
                            scheduleListReferral.additionalDoctors = this.state.doctorsList;
                            this.setState({ scheduleListReferral })
                        }


                    })
                }
                if (callback) {
                    callback();
                }


            })
        }


    }
    scheduleControl = (data) => {
        let checkDate = new Date(data.date.replace(/-/g, "/") + ' ' + data.startTime);
        let today = new Date();

        // console.log(data)
        if (data.controlSchedule) {
            data.patientControlNote = data.patientNote;
            data.title = data.controlSchedule.event && data.controlSchedule.event.title;
            data.socialSecurityNumber = data.controlSchedule.patient ? data.controlSchedule.patient.socialSecurityNumber : '';
            data.visitReasonOnline = data.controlSchedule.event && data.controlSchedule.event.visitReasonOnline;
            data.videoCall = data.controlSchedule.event && data.controlSchedule.event.videoCall;
            data.titleDoctor = '';
            data.revisitForms = data.controlSchedule.event && data.controlSchedule.event.revisitForms;
            data.visitReasonName = data.controlSchedule.event && data.controlSchedule.event.visitReasonName;
            data.visitReasonPrice = data.controlSchedule.event && data.controlSchedule.event.questionaryPrice ? data.controlSchedule.event.questionaryPrice : 0;
            data.visiReason = data.controlSchedule.event && data.controlSchedule.event.visiReason;
            data.hideTimeFromThePatient = data.controlSchedule.event && data.controlSchedule.event.hideTimeFromThePatient;
            data.closeCaseManually = data.controlSchedule.event && data.controlSchedule.event.closeCaseManually;
            data.referralExists = data.controlSchedule._id
            data.appointmentPaid = data.controlSchedule.event.appointmentPaid ? data.controlSchedule.event.appointmentPaid : false
            data.revisitFormsArray = data.controlSchedule.event.revisitFormsArray ? data.controlSchedule.event.revisitFormsArray : [];
            data.visitReasonOnline = data.controlSchedule.event.onlineVisit ? data.controlSchedule.event.onlineVisit : false;
            data.journalNoteFormSelected = data.controlSchedule.event.journalNoteFormSelected ? data.controlSchedule.event.journalNoteFormSelected : null;
            data.rescheduleRevisit = data.rescheduleRevisit ? true : false;
        }

        if (checkDate >= today) {
            this.setState({
                loading: true
            }, () => {
                this.scheduleControlApiCalled = true;
                fetch(API_ENDPOINT + '/working-hours/control', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        startEventReservedDateOriginal: data.date + ' ' + data.startTime,
                        endEventReservedDateOriginal: data.date + ' ' + data.endTime,
                        startEventReservedDate: this.props.getStringDateInverseTs(Math.floor(new Date(data.date + ' ' + data.startTime).getTime() / 1000), `${this.props.dateFormat} HH:mm`),
                        endEventReservedDate: this.props.getStringDateInverseTs(Math.floor(new Date(data.date + ' ' + data.endTime).getTime() / 1000), `${this.props.dateFormat} HH:mm`),
                        title: data.title,
                        socialSecurityNumber: data.socialSecurityNumber,
                        doctor: this.state.listDoctor ? this.state.listDoctor : null,
                        group: this.props.selectedGroup,
                        clinic: this.props.selectedClinic,
                        patientControlNote: data.patientNote,
                        onlineVisit: data.visitReasonOnline,
                        videoCall: data.videoCall,
                        additionalDoctors: data.additionalDoctors,
                        titleDoctor: data.titleDoctor,
                        revisitForms: this.state.revisitFormsSelected,
                        visitReasonName: data.visitReasonName,
                        visitReasonPrice: data.visitReasonPrice,
                        visiReason: data.visiReason,
                        freePayment: data.freePayment,
                        hideTimeFromThePatient: data.hideTimeFromThePatient,
                        closeCaseManually: data.closeCaseManually,
                        referralExists: data.referralExists ? data.referralExists : null,
                        appointmentPaid: data.appointmentPaid ? data.appointmentPaid : false,
                        revisitFormsArray: data.revisitFormsArray ? data.revisitFormsArray : [],
                        rescheduleRevisit: data.rescheduleRevisit
                    })
                }).then(res => res.json()).then((result) => {
                    if (result.error) {
                        this.setState({
                            error: result.error.translate(this.props.lang),
                            loading: false
                        })
                    } else {
                        this.getCases();
                        if (this.state.listDoctor) {

                            this.setState({
                                scheduleListReferral: null,
                                doctor: this.state.listDoctor,
                                doctorsList: [],
                                referral: null,
                                listDoctor: null
                            }, () => {
                                // this.setState({
                                //     day: new Date(data.date.replace(/-/g, "/") + ' ' + data.startTime),
                                //     view: 'day',
                                //     scroll: true,
                                // }, () => {
                                //     this.get();
                                //     this.getWorkingTime()
                                // })
                            })
                        }
                        // else if (data.controlSchedule) {
                        //     this.get();
                        //     this.setState({
                        //         scheduleReferral: null,
                        //         referralId: null,
                        //         day: new Date(data.date.replace(/-/g, "/") + ' ' + data.startTime),
                        //         scroll: true,
                        //         doctorsList: [],
                        //         referralId: null
                        //     }, () => this.get())
                        // } else {
                        //     this.get()
                        //     this.setState({
                        //         scheduleModal: null,
                        //         referralId: null,
                        //         scheduleControl: null,
                        //         revisitFormsSelected: [],
                        //         revisitFormsSelect: [],
                        //         // view: 'week',
                        //         day: new Date(data.date.replace(/-/g, "/") + ' ' + data.startTime),
                        //         scroll: true,
                        //         doctorsList: []

                        //     })
                        // }

                    }

                })
            })
        } else {
            this.setState({
                wrongDate: true
            })
        }

    }
    getLastOpenedChats = () => {
        fetch(API_ENDPOINT + '/users/patients/last/search', {
            method: 'POST',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
            body: JSON.stringify({
                group: this.props.selectedGroup
            })
        }).then(res => res.json()).then((result) => {
            if (result && result.searchItems) {
                this.setState({ lastSerach: result.searchItems })
            }
        })
    }

    componentDidMount() {
        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }

        this.getClinicGroupSettings();

        if (this.props[0].location?.state?.reditectToDiary) {
            this.searchPatient(this.props[0].location?.state?.reditectToDiary, true, true, () => {
                this.setState({ tabActive: 2 })
            })

        }

        this.get();

        if (this.props.uData)
            this.checkPermissions()

        window.addEventListener('message',
            (e) => {
                if (e.data == 'chat-closed') {
                    this.setState({ chatConversation: null })
                }
            }

            , false)

        setTimeout(() => {
            let selectedGroupIdx = -1;
            if (this.props.selectedGroup && this.props.uData?.clinicGroups?.length) {
                for (let i = 0; i < this.props.uData.clinicGroups.length; i++) {
                    if (this.props.uData.clinicGroups[i]._id == this.props.selectedGroup) {
                        selectedGroupIdx = i;
                        break;
                    }
                }
            }
            if (this.props.uData && this.props.uData.clinicGroups && this.props.uData.clinicGroups[selectedGroupIdx] && !this.props.uData.clinicGroups[selectedGroupIdx].enterprise) {
                this.props[0].history.push('/my-data/account')
            }
        }, 1000);

        this.getToken()

        if (this.props[0].location?.state?.TYPE === 'redirect') {
            this.setState({ patient: this.props[0].location?.state?.redirectPatient || null, tabIndex: this.props[0].location?.state?.redirectTabIndex || null, tabActive: this.props[0].location?.state?.redirectTabActive || null }, () => this.openChat(this.state.patient))
        }

        if (this.props.uData && this.props.socket && !this.state._registerEvents) {
            this.setState({
                _registerEvents: true
            }, () => {
                this.registerEvents();


            })
        } else {
            this.socketInterval = setInterval(() => {
                if (this.props.uData && this.props.socket && !this.state._registerEvents) {
                    if (this.socketInterval) {
                        clearInterval(this.socketInterval)
                    }

                    this.setState({
                        _registerEvents: true
                    }, () => {
                        this.registerEvents();


                    })
                }

            }, 150);
        }



    }
    registerEvents = async () => {

        this.props.socket.on('newEventCreated', (data) => {
            if (data?.error && this.scheduleControlApiCalled) {
                this.setState({ error: data.error.translate(this.props.lang) })
            }
            this.getCases();
        })


    }
    getToken = () => {

        if (this.intervalCheckWebDocToken) {
            clearInterval(this.intervalCheckWebDocToken)
        }
        this.checkWebDocToken()
        this.intervalCheckWebDocToken = setInterval(() => {
            this.checkWebDocToken()
        }, 2500);
    }
    checkWebDocToken = () => {
        if (this.props.selectedGroup && this.props.selectedClinic) {
            fetch(API_ENDPOINT + '/users/patient/search/webdoc/check-token', {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify({
                    group: this.props.selectedGroup,
                    clinic: this.props.selectedClinic
                })
            }).then(res => res.json()).then((result) => {
                if (result) {
                    if (JSON.stringify(this.state.webDocInfo) !== JSON.stringify(result))
                        this.setState({ webDocInfo: result })
                    if (!result.webdocConnected && this.intervalCheckWebDocToken) {
                        clearInterval(this.intervalCheckWebDocToken)
                    }
                }
            })
        }

    }
    makeTokenWebDoc = () => {
        if (this.props.selectedGroup && this.props.selectedClinic) {
            fetch(API_ENDPOINT + '/users/patient/search/webdoc/token', {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify({
                    group: this.props.selectedGroup,
                    clinic: this.props.selectedClinic
                })
            }).then(res => res.json()).then((result) => {
                if (result && result.link) {
                    window.open(result.link, '_blank')
                }
            })
        }
    }

    componentWillUnmount() {
        if (this.props.socket) {
            this.props.socket.removeListener('newEventCreated');
        }
        window.removeEventListener('message', this.resizeWindow);
        if (this.intervalCheckWebDocToken) {
            clearInterval(this.intervalCheckWebDocToken)
        }
    }
    checkValidateParent2 = () => {
        let parent2Required = false;
        let socialSecurityNumberParent2 = this.props.socialSecurityNumberParent2;
        if (socialSecurityNumberParent2 === '000000000000') {
            socialSecurityNumberParent2 = null;
        }
        if (socialSecurityNumberParent2 || this.props.email2 || this.props.phone2 || this.props.nameParent2) {
            parent2Required = true;
        }
        if (this.state.parent2Required !== parent2Required) {
            this.setState({ parent2Required })
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if ((!prevProps?.selectedGroup && this.props.selectedGroup) || (prevProps?.selectedGroup !== this.props.selectedGroup)) {
            this.getClinicGroupSettings();
        }
        if (prevProps[0].location.search != this.props[0].location.search) {
            this.setState({
                loading: true
            }, () => {
                this.get();

            })
        }
        if (prevProps.userLevel != this.props.userLevel && this.props.userLevel != '5f6b126329ef8110f415087b') {
            //alert(true)
            this.props.changeParentAccount(null);
            this.forceUpdate();
        }
        if (prevState.patient != this.state.patient && this.state.patient && this.state.patient.socialSecurityNumber) {
            this.get()
        }
        if (this.props.selectedGroup != prevProps.selectedGroup) {

            this.setState({ lastSerach: [], patientSearched: null, patient: null, chatId: null, chatConversation: null, search: '', otherInformations: null, patientInfo: null, relatedParties: null, startChat: false }, () => {
                this.getLastOpenedChats()
                this.props.verifyUser(() => {
                    if (this.props.uData && !this.props.uData.searchPatientsAllowed) {
                        if (this.props.modulesTree && this.props.modulesTree.length && this.props.modulesTree.filter(item => item.link != "/patients/search").length && this.props.modulesTree.filter(item => item.link != "/patients/search")[0].link) {
                            this.props[0].history.push(this.props.modulesTree.filter(item => item.link != "/patients/search")[0].link);
                        }
                    }
                })
            })
        }


        if ((!prevProps?.socialSecurityNumber && this.props.socialSecurityNumber) || (prevProps?.socialSecurityNumber !== this.props.socialSecurityNumber)) {
            if ((this.props.country === 'denmark' && this.props.socialSecurityNumber?.length == 10) || (this.props.socialSecurityNumber?.length == 12 && this.props.socialSecurityNumber?.indexOf('000000000000') === -1)) {

                let check = validateSocialSecurityNumber(this.props.socialSecurityNumber, false, this.props.country);
                if (!check.error) {
                    this.checkSSN(this.props.socialSecurityNumber)

                    this.patientAge(this.props.socialSecurityNumber)
                } else {
                    if (this.state.socialSecurityNumberExist) {
                        this.setState({ socialSecurityNumberExist: false })
                    }
                }
            } else {
                if (this.state.socialSecurityNumberExist) {
                    this.setState({ socialSecurityNumberExist: false })
                }
            }

        }
        if (prevState.socialSecurityNumberExist !== this.state.socialSecurityNumberExist && !this.state.socialSecurityNumberExist && ((this.props.country === 'denmark' && this.props.socialSecurityNumber?.length !== 10) || this.props.country === 'uk' || this.props.socialSecurityNumber?.length !== 12)) {
            this.props.changeName('')
            // this.props.changeSex('')
            this.props.changeSocialSecurityNumberParent('')
            this.props.changeSocialSecurityNumberParent2('')
            this.props.changeEmail('')
            this.props.changeNameParent('')
            // this.props.changeLanguage('')
            this.props.changePhone('')
            // this.props.changeSexParent('')
            this.props.changeEmail2('')
            this.props.changeNameParent2('')
            // this.props.changeLanguage2('')
            this.props.changePhone2('')
            this.props.changeSexParent2('')
            // this.props.changeDateOfBirth('')
            // this.props.changeDateOfBirthParent('')
            // this.props.changeDateOfBirthParent2('')

        }
        if (prevState.referral != this.state.referral && !this.state.referral && this.state.activeRevisitForm !== 1) {
            this.setState({ activeRevisitForm: 1 })
        }
        if (!this.props.groupSettingsModal && this.state.notSavedTestResults) {
            this.props.groupSettingsChanged()
        }

        if (prevProps.uData != this.props.uData) {
            let selectedGroupIdx = -1;
            if (this.props.selectedGroup && this.props.uData?.clinicGroups?.length) {
                for (let i = 0; i < this.props.uData.clinicGroups.length; i++) {
                    if (this.props.uData.clinicGroups[i]._id == this.props.selectedGroup) {
                        selectedGroupIdx = i;
                        break;
                    }
                }
            }
            let redirect = false;

            if (!this.props.uData || (this.props.uData && !this.props.uData.clinicGroups) || (this.props.uData && this.props.uData.clinicGroups && !this.props.uData.clinicGroups[selectedGroupIdx])) {
                redirect = true;
            } else if (this.props.uData && this.props.uData.clinicGroups && this.props.uData.clinicGroups[selectedGroupIdx] && !this.props.uData.clinicGroups[selectedGroupIdx].enterprise) {
                redirect = true;
            }
            if (redirect == true) {
                this.props[0].history.push('/')
            }
        }
        if (JSON.stringify(this.props.uData) != JSON.stringify(prevProps.uData) && this.props.uData) {

            this.checkPermissions()
        }
        if (this.props.selectedClinic != prevProps.selectedClinic) {
            this.checkPermissions()
            // this.checkWebDocToken()
            this.getToken()

            this.setState({
                chatConversation: null
            })
            this.getCases();
            this.openChat(this.state.patient);
        }
        if (this.props.selectedGroup != prevProps.selectedGroup) {
            this.checkPermissions()
            // this.checkWebDocToken()
            this.getToken()
            if (this.state.patient) {
                this.setState({ patient: null })
            }
        }
        if (prevProps.socialSecurityNumberParent != this.props.socialSecurityNumberParent && this.props.socialSecurityNumberParent && ((this.props.country === 'denmark' && this.props.socialSecurityNumberParent.length == 10) || (this.props.socialSecurityNumberParent.length == 12 && this.props.socialSecurityNumberParent.indexOf('000000000000') === -1))) {
            this.checkUser();
        }
        if (prevProps.socialSecurityNumberParent2 != this.props.socialSecurityNumberParent2 && this.props.socialSecurityNumberParent2 && ((this.props.country === 'denmark' && this.props.socialSecurityNumberParent2.length == 10) || (this.props.socialSecurityNumberParent2.length == 12 && this.props.socialSecurityNumberParent2.indexOf('000000000000') === -1))) {
            this.checkUser2();
        }

        if (prevProps.socialSecurityNumber !== this.props.socialSecurityNumber) {
            if ((this.props.country === 'denmark' && this.props.socialSecurityNumber?.length === 10) || this.props.socialSecurityNumber?.length === 12) {
                let dateOfBirth = this.props.getDateOfBirthFromSSN(this.props.socialSecurityNumber);
                if (dateOfBirth && this.props.changeDateOfBirth) {
                    this.props.changeDateOfBirth(dateOfBirth)
                    if (!this.state.disabledDateOfBirth) { this.setState({ disabledDateOfBirth: true }) }
                } else {
                    if (this.state.disabledDateOfBirth) { this.setState({ disabledDateOfBirth: false }) }
                }
            } else {
                if (this.state.disabledDateOfBirth) { this.setState({ disabledDateOfBirth: false }) }
            }
        }
        if (prevProps.socialSecurityNumberParent !== this.props.socialSecurityNumberParent) {

            if ((this.props.country === 'denmark' && this.props.socialSecurityNumberParent?.length === 10) || this.props.socialSecurityNumberParent?.length === 12) {
                let dateOfBirth = this.props.getDateOfBirthFromSSN(this.props.socialSecurityNumberParent);
                if (dateOfBirth && this.props.changeDateOfBirthParent) {
                    this.props.changeDateOfBirthParent(dateOfBirth)
                    if (!this.state.disabledDateOfBirthParent) { this.setState({ disabledDateOfBirthParent: true }) }
                } else {
                    if (this.state.disabledDateOfBirthParent) { this.setState({ disabledDateOfBirthParent: false }) }
                }
            } else {
                if (this.state.disabledDateOfBirthParent) { this.setState({ disabledDateOfBirthParent: false }) }
            }
        }
        if (prevProps.socialSecurityNumberParent2 !== this.props.socialSecurityNumberParent2) {

            if ((this.props.country === 'denmark' && this.props.socialSecurityNumberParent2?.length === 10) || this.props.socialSecurityNumberParent2?.length === 12) {
                let dateOfBirth = this.props.getDateOfBirthFromSSN(this.props.socialSecurityNumberParent2);
                if (dateOfBirth && this.props.changeDateOfBirthParent) {
                    this.props.changeDateOfBirthParent2(dateOfBirth)
                    if (!this.state.disabledDateOfBirthParent2) { this.setState({ disabledDateOfBirthParent2: true }) }
                } else {
                    if (this.state.disabledDateOfBirthParent2) { this.setState({ disabledDateOfBirthParent2: false }) }
                }
            } else {
                if (this.state.disabledDateOfBirthParent2) { this.setState({ disabledDateOfBirthParent2: false }) }
            }
        }

        if ((prevProps.socialSecurityNumberParent2 !== this.props.socialSecurityNumberParent2) || (prevProps.email2 !== this.props.email2) || (prevProps.phone2 !== this.props.phone2) || (prevProps.nameParent2 !== this.props.nameParent2)) {
            this.checkValidateParent2()
        }
    }
    checkUser = () => {
        fetch(API_ENDPOINT + '/users/subaccounts/get', {
            method: 'POST',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
            body: JSON.stringify({ socialSecurityNumber: this.props.socialSecurityNumberParent })
        }).then(parseJSON).then(({ result, status }) => {
            if (status == 200 && result && result.userData) {

                // console.log(result)
                // if (result.groupPermissions && result.groupPermissions[this.props.selectedGroup] && result.groupPermissions[this.props.selectedGroup][this.props.selectedClinic] && result.groupPermissions[this.props.selectedGroup][this.props.selectedClinic].indexOf(this.state.doctorIdPermissions) != -1) {
                //     this.setState({ doctorPermissions: true })
                // }

                // this.setState({
                //     form: result
                // }, () => {
                //     if (!result.email) {
                //         this.setState({ changeEmail: true })
                //     }
                this.props.changeNameParent(result.name);
                this.props.changeEmail(result.email);
                if (result.userData.sex)
                    this.props.changeSexParent(result.userData.sex);
                this.props.changeLanguage(result.language);
                this.props.changePhone(result.phone);
                // this.setState({ lockFields: true })
                //     })

                // }
                // if (status == 405) {
                //     this.setState({
                //         error: 'The user is forbidden!'.translate(this.props.lang)
                //     })
                //     this.props.changeSocialSecurityNumber('')
            } else {
                this.props.changeNameParent('');
                this.props.changeEmail('');
                this.props.changeSexParent('');
                this.props.changeLanguage('');
                this.props.changePhone('');
            }
        })

    }
    checkUser2 = () => {
        fetch(API_ENDPOINT + '/users/subaccounts/get', {
            method: 'POST',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
            body: JSON.stringify({ socialSecurityNumber: this.props.socialSecurityNumberParent2 })
        }).then(parseJSON).then(({ result, status }) => {
            if (status == 200 && result && result.userData) {

                // console.log(result)
                // if (result.groupPermissions && result.groupPermissions[this.props.selectedGroup] && result.groupPermissions[this.props.selectedGroup][this.props.selectedClinic] && result.groupPermissions[this.props.selectedGroup][this.props.selectedClinic].indexOf(this.state.doctorIdPermissions) != -1) {
                //     this.setState({ doctorPermissions: true })
                // }

                // this.setState({
                //     form: result
                // }, () => {
                //     if (!result.email) {
                //         this.setState({ changeEmail: true })
                //     }
                this.props.changeNameParent2(result.name);
                this.props.changeEmail2(result.email);
                if (result.userData.sex)
                    this.props.changeSexParent2(result.userData.sex);
                this.props.changeLanguage2(result.language);
                this.props.changePhone2(result.phone);
                // this.setState({ lockFields: true })
                //     })

                // }
                // if (status == 405) {
                //     this.setState({
                //         error: 'The user is forbidden!'.translate(this.props.lang)
                //     })
                //     this.props.changeSocialSecurityNumber('')
            } else {
                this.props.changeNameParent2('');
                this.props.changeEmail2('');
                this.props.changeSexParent2('');
                this.props.changeLanguage2('');
                this.props.changePhone2('');
            }
        })

    }
    checkPermissions = () => {
        if (this.props.uData && this.props.uData.permissions && this.props.selectedClinic && this.props.selectedGroup && this.props.uData.groupPermissionsUser) {

            if (this.props.uData.groupPermissionsUser[this.props.selectedGroup] && this.props.uData.groupPermissionsUser[this.props.selectedGroup][this.props.selectedClinic]) {
                let permissions = this.props.uData.groupPermissionsUser[this.props.selectedGroup][this.props.selectedClinic];
                let doctorPermission = false;
                let adminPermission = false;
                let clinicAdminPermission = false;
                for (let i = 0; i < permissions.length; i++) {
                    if (this.props.uData.permissions.clinicAdmin == permissions[i]) {
                        clinicAdminPermission = true;
                    } else if (this.props.uData.permissions.admin == permissions[i]) {
                        adminPermission = true;
                    } else if (this.props.uData.permissions.doctor == permissions[i]) {
                        doctorPermission = true;
                    }
                }
                if ((doctorPermission || adminPermission)) {
                    if (!this.state.showFirstSegment)
                        this.setState({ showFirstSegment: true })
                } else {
                    this.props[0].history.push('/my-data/account')
                    if (this.state.showFirstSegment)
                        this.setState({ showFirstSegment: false })
                }
            }
        }
    }
    checkSavedTestResults = (obj) => {
        if (this.state.notSavedTestResults) {
            this.setState({ changedTab: obj })
        } else {
            this.setState(obj)
        }
    }

    // checkSavedMedicine = (obj) => {
    //     if (this.state.notSavedTestResults) {
    //         this.setState({ changeMedicine: obj })
    //     } else {
    //         this.setState(obj)
    //     }
    // }


    componentWillReceiveProps(nextProps) {
    }

    updateSort = (field, type) => {
        if (this.state.useState) {
            let state = this.state.params;
            state.sortField = field;
            state.sortType = type;
            this.setState({
                params: state
            })
        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0 }, [{ name: 'sortField', value: field }, { name: 'sortType', value: type }], false));

        }
    }

    updateParams = (name, value, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };
                obj[name] = value;
                this.setState({ params: obj });
            } else {
                let obj = this.state.params;
                obj[name] = value;
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(generateSearchLink(this.props[0].location.search, { entries: 10, page: 0 }, name, value, restart));
        }
    }
    updateMultipleParams = (fields, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj });

            } else {
                let obj = this.state.params;
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0 }, fields, restart));
        }
    }








    stopAnimation = () => {
        this.player.current.play();
    }


    searchPatient = (search, direct = false, openOnWebDoc = false, callback) => {
        let obj = {
            socialSecurityNumber: search,
            group: this.props.selectedGroup,
            clinic: this.props.selectedClinic,
            openOnWebDoc: openOnWebDoc
        }


        fetch(API_ENDPOINT + '/users/find/patient', {
            method: 'POST',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
            body: JSON.stringify(obj)
        }).then(res => res.json()).then((result) => {
            if (result && result._id) {
                if (direct) {
                    this.setState({ patient: result, tabIndex: 1, tabActive: direct == 'linked' ? 1 : direct }, () => {
                        if (direct == 'linked') {
                            this.updateParams('page', 0, true)
                        }
                        this.props.registerClinicGroupLogs({
                            type: 'patientProfile',
                            patient: this.state.patient.socialSecurityNumber,

                        })
                        this.openChat(this.state.patient)


                        if (callback) {
                            callback()
                        }

                    })
                } else {
                    this.props.registerClinicGroupLogs({
                        type: 'patientProfile',
                        patient: result.socialSecurityNumber
                    })
                    this.setState({ patientSearched: result, tabIndex: 1 }, () => {

                        if (callback) {
                            callback()
                        }
                    })
                }


            } else {
                if (!direct && ((this.props.country === 'denmark' && search?.length == 10) || search?.length == 12)) {
                    let selectedGroupIdx = -1;
                    if (this.props.uData && this.props.selectedGroup && this.props.uData?.clinicGroups?.length) {
                        for (let i = 0; i < this.props.uData.clinicGroups.length; i++) {
                            if (this.props.uData.clinicGroups[i]._id == this.props.selectedGroup) {
                                selectedGroupIdx = i;
                                break;
                            }
                        }
                    }
                    let enterprise = this.props.uData && this.props.uData.clinicGroups && this.props.uData.clinicGroups[selectedGroupIdx] && this.props.uData.clinicGroups[selectedGroupIdx].enterprise;
                    let canSearchPatients = this.props.uData && this.props.uData.clinicGroups && this.props.uData.clinicGroups[selectedGroupIdx] && this.props.uData.clinicGroups[selectedGroupIdx].sarchPatients;

                    if (enterprise || canSearchPatients) {
                        this.setState({
                            errorPatientNotFound: 'Check digits or register a new patient'.translate(this.props.lang)
                        }, () => {
                            this.patientAge(search)
                        })
                    } else {
                        this.setState({
                            error: 'The patient you are searching can not be found in your clinic'.translate(this.props.lang)
                        })
                    }

                } else {
                    this.setState({ error: 'User not found in this clinic group' })

                }
            }
            this.getLastOpenedChats()
        })
    }
    patientAge = (data) => {
        let socialSecurityNumber
        // if (this.state.search) {
        //     socialSecurityNumber = this.state.search
        // }
        // if (!this.state.search || (this.props.country === 'uk' && data)) {
        socialSecurityNumber = data
        // }

        fetch(API_ENDPOINT + '/users/users/get-age/' + data, {
            method: 'POST',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
            body: JSON.stringify({ socialSecurityNumber: socialSecurityNumber })
        }).then(res => res.json()).then((result) => {

            this.setState({
                age: result.age
            })
        })
    }

    searchPatient1 = (search) => {
        let obj = {
            socialSecurityNumber: search,
            group: this.props.selectedGroup,
            clinic: this.props.selectedClinic
        }

        fetch(API_ENDPOINT + '/users/find/patient', {
            method: 'POST',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
            body: JSON.stringify(obj)
        }).then(res => res.json()).then((result) => {
            if (result && result._id) {

                this.setState({ patient: result, tabIndex: 1 }, () => {
                    this.props.registerClinicGroupLogs({
                        type: 'patientProfile',
                        patient: this.state.patient.socialSecurityNumber,

                    })

                })

            } else {
                this.setState({ error: 'User not found in this clinic group' })
            }
        })
    }


    getCases = () => {
        if (this.state.patient && this.state.patient.socialSecurityNumber) {
            let query = this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0, group: this.props.selectedGroup });
            let queryFields = {};
            for (var key in query) {
                if (key && key.indexOf('query-') === 0) {
                    queryFields[key.replace('query-', '')] = query[key];
                }
            }

            this.setState({ loadingCases: true }, () => {
                fetch(API_ENDPOINT + '/referrals-cases/all-cases/patient', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        entries: query.entries,
                        filter: this.state.patient?.socialSecurityNumber,
                        sortType: query.sortType ? query.sortType : -1,
                        sortField: query.sortField ? query.sortField : 'timestamp',
                        query: queryFields,
                        clinic: this.props.selectedClinic,

                        professions: this.state.values,
                        controlBoking: this.state.controlBoking ? this.state.controlBoking : false,
                        webBoking: this.state.webBoking ? this.state.webBoking : false,
                        dropInBoking: this.state.dropInBoking ? this.state.dropInBoking : false,
                        clinicBoking: this.state.clinicBoking ? this.state.clinicBoking : false,

                        controlScheduled: this.state.controlScheduled ? this.state.controlScheduled : false,
                        cancledByClinic: this.state.cancledByClinic ? this.state.cancledByClinic : false,
                        rejectedByPatient: this.state.rejectedByPatient ? this.state.rejectedByPatient : false,
                        closed: this.state.closed ? this.state.closed : false,
                        scheduled: this.state.scheduled ? this.state.scheduled : false,
                        notBooked: this.state.notBooked ? this.state.notBooked : false,
                        disallowed: this.state.disallowed ? this.state.disallowed : false,
                        waiting: this.state.waiting ? this.state.waiting : false,
                        inProgress: this.state.inProgress ? this.state.inProgress : false,
                        waitingForApproval: this.state.waitingForApproval ? this.state.waitingForApproval : false,
                        waitingForPayment: this.state.waitingForPayment ? this.state.waitingForPayment : false,
                        allowed: this.state.allowed ? this.state.allowed : false,
                        lang: this.props.lang

                    })
                }).then(res => res.json()).then((result) => {
                    if (result) {
                        this.setState({
                            cases: result.items,
                            totalCases: result.total,
                            clinicAdminPermission: result.clinicAdminPermission,
                            adminPermission: result.adminPermission,
                            doctorPermission: result.doctorPermission,
                            loadingCases: null
                        })
                    }
                })
            })

        }



    }
    openChat = (item) => {
        if (item) {
            fetch(API_ENDPOINT + '/users/find/patient/conversation', {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify({
                    group: this.props.selectedGroup,
                    patient: item?._id
                })
            }).then(res => res.json()).then((result) => {
                if (result && result.conversationId && result.patientID == this.state.patient?._id) {
                    this.setState({ chatId: result.conversationId })
                }

                if (result && result.members && result.members[0] === this.props.uData._id) {
                    this.setState({
                        doctorCanStartVideoCall: true
                    })
                } else {
                    this.setState({
                        doctorCanStartVideoCall: false
                    })
                }
            })
        }



    }
    savePatientInfo = (data) => {
        if (this.state.patient && this.state.patient._id) {
            fetch(API_ENDPOINT + '/users/save/patient/info', {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify({
                    group: this.props.selectedGroup,
                    patient: this.state.patient._id,
                    ...data
                })
            }).then(res => res.json()).then((result) => {
                if (result) {
                    let user = this.state.patient;
                    user.patientInfo = result;
                    this.setState({ formInfo: null, patientSearched: user, patient: user, patientInfo: null })
                }


            })
        }
    }
    saveNote = (note) => {
        if (this.state.patient && this.state.patient._id) {
            let data = {
                note: note,
                group: this.props.selectedGroup,
                patient: this.state.patient._id,
            }
            this.setState({ loading: true }, () => {
                fetch(API_ENDPOINT + '/users/save/patient/note', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify(data)
                }).then(res => res.json()).then((result) => {
                    if (result) {
                        let user = this.state.patient;
                        user.patientInfo = result;
                        this.setState({ patientSearched: user, patient: user, note: '' })
                    }
                    if (this.notesRef) {
                        this.notesRef.scrollTop = this.notesRef.scrollHeight;
                    }
                    this.setState({ loading: false })

                })
            })
        }
    }
    addPatient = (data) => {
        let selectedGroupIdx = -1;
        if (this.props.uData && this.props.selectedGroup && this.props.uData?.clinicGroups?.length) {
            for (let i = 0; i < this.props.uData.clinicGroups.length; i++) {
                if (this.props.uData.clinicGroups[i]._id == this.props.selectedGroup) {
                    selectedGroupIdx = i;
                    break;
                }
            }
        }
        let hideWhoIsTheCaseRegarding = this.props.uData && this.props.uData.clinicGroups && this.props.uData.clinicGroups[selectedGroupIdx] && this.props.uData.clinicGroups[selectedGroupIdx].hideWhoIsTheCaseRegarding;
        if (hideWhoIsTheCaseRegarding && this.state.age && (this.props.country === 'denmark' ? this.state.age < CHILD_AGE_LIMIT.denmark : this.props.country === 'sweden' ? this.state.age < CHILD_AGE_LIMIT.sweden : this.state.age < 18)) {
            this.setState({
                error: "You can't apply for a child in this clinic"
            })
        } else {
            if (this.props.country === 'uk' && !this.state.parent2Required && data) {
                if (data.socialSecurityNumberParent2) delete data.socialSecurityNumberParent2;
                if (data.dateOfBirthParent2) delete data.dateOfBirthParent2;
                if (data.nameParent2) delete data.nameParent2;
                if (data.sexParent2) delete data.sexParent2;
                if (data.email2) delete data.email2;
                if (data.phone2) delete data.phone2;
            }

            fetch(API_ENDPOINT + '/users/patient/add/new', {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify({
                    group: this.props.selectedGroup,
                    clinic: this.props.selectedClinic,
                    ...data
                })
            }).then(res => res.json()).then((result) => {
                if (result.error) {
                    this.setState({ error: result.error })
                } else {
                    this.setState({ succesMessage: 'You have successfully added a patient'.translate(this.props.lang), form: null, registerNewPatientModal: null })
                }
                this.setState({ saveModal: null })

            })
        }

    }
    checkPatientValidations = (data) => {

        if (!data || (!this.state.clinicGroupSettings?.twoFactorAuthentication && data.twoFactorAuthenticationEnabled)) return this.setState({ error: 'Something went wrong! Please refresh your browser and try again!'.translate(this.props.lang) });

        if (this.state.age && (this.props.country === 'denmark' ? this.state.age >= CHILD_AGE_LIMIT.denmark : this.props.country === 'sweden' ? this.state.age >= CHILD_AGE_LIMIT.sweden : this.state.age >= 18) && data) {
            if (data.socialSecurityNumberParent) delete data.socialSecurityNumberParent;
            if (data.socialSecurityNumberParent2) delete data.socialSecurityNumberParent2;
            if (data.dateOfBirthParent) delete data.dateOfBirthParent;
            if (data.dateOfBirthParent2) delete data.dateOfBirthParent2;
            if (data.nameParent) delete data.nameParent;
            if (data.nameParent2) delete data.nameParent2;
            if (data.sexParent) delete data.sexParent;
            if (data.sexParent2) delete data.sexParent2;
            if (data.email2) delete data.email2;
            if (data.phone2) delete data.phone2;
        }

        // console.log('data', JSON.parse(JSON.stringify(data)))

        if (this.state.clinicGroupSettings?.twoFactorAuthentication && data.twoFactorAuthenticationEnabled && (!data.email?.trim?.() || !data.phone?.trim?.())) return this.setState({ error: 'When two factor authentication is enabled, both email and phone number are required!'.translate(this.props.lang) });

        if (!(data.email || data.phone) && !this.state.socialSecurityNumberExist) {
            this.setState({ error: 'Mandatory to enter either email or phone.'.translate(this.props.lang) })
        } else {
            if (data.socialSecurityNumber) {
                let check = validateSocialSecurityNumber(data.socialSecurityNumber, false, this.props.country);

                if (check.error) {
                    this.setState({ error: check.error.translate(this.props.lang) })
                } else {
                    if (data.socialSecurityNumberParent) {
                        let check = validateSocialSecurityNumber(data.socialSecurityNumberParent, true, this.props.country)
                        // console.log('check', check)
                        if (check.error) {
                            this.setState({ error: check.error.translate(this.props.lang) })
                        } else {

                            if (data.socialSecurityNumberParent2 && this.state.parent2Required) {
                                let check = validateSocialSecurityNumber(data.socialSecurityNumberParent2, true, this.props.country)
                                // console.log('check2', check)

                                if (check.error) {
                                    this.setState({ error: check.error.translate(this.props.lang) })
                                } else {

                                    this.setState({
                                        saveModal: data
                                    })
                                }
                            } else {
                                this.setState({
                                    saveModal: data
                                })
                            }
                        }
                    } else {
                        this.setState({
                            saveModal: data
                        })
                    }

                }

            }
        }

    }
    checkSSN = (ssn) => {
        fetch(API_ENDPOINT + '/users/patient/check/ssn', {
            method: 'POST',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
            body: JSON.stringify({
                group: this.props.selectedGroup,
                clinic: this.props.selectedClinic,
                socialSecurityNumber: ssn,
            })
        }).then(res => res.json()).then((result) => {
            if (result) {
                this.setState({ socialSecurityNumberExist: result.exist, ssnExistInThisGroup: result.ssnExistInThisGroup }, () => {
                    if (this.state.socialSecurityNumberExist && !this.state.ssnExistInThisGroup) {
                        fetch(API_ENDPOINT + '/users/subaccounts/get', {
                            method: 'POST',
                            headers: {
                                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                                'content-type': 'application/json'
                            },
                            body: JSON.stringify({ socialSecurityNumber: ssn })
                        }).then(parseJSON).then(({ result, status }) => {
                            if (status == 200 && result && result.userData) {

                                this.props.changeName(result.userData.name);
                                this.props.changeSex(result.userData.sex);
                                this.props.changeEmail(result.email)
                                this.props.changePhone(result.phone)

                                if (result.parentObj) {
                                    this.props.changeSocialSecurityNumberParent(result.parentObj.socialSecurityNumber)
                                    this.props.changeNameParent(result.parentObj.name);
                                    this.props.changeEmail(result.parentObj.email);
                                    if (result.parentObj.userData.sex)
                                        this.props.changeSexParent(result.parentObj.userData.sex);
                                    this.props.changeLanguage(result.parentObj.language);
                                    this.props.changePhone(result.parentObj.phone);
                                }
                                if (result.parentObj2) {
                                    this.props.changeSocialSecurityNumberParent2(result.parentObj2.socialSecurityNumber)
                                    this.props.changeNameParent2(result.parentObj2.name);
                                    this.props.changeEmail2(result.parentObj2.email);
                                    if (result.parentObj2.userData.sex)
                                        this.props.changeSexParent2(result.parentObj2.userData.sex);
                                    this.props.changeLanguage2(result.parentObj2.language);
                                    this.props.changePhone2(result.parentObj2.phone);
                                }

                            }
                        })
                    }
                })
            }

        })
    }


    conversation = (id, data) => {
        this.setState({
            loading: true
        }, () => {
            fetch(API_ENDPOINT + '/referrals/conversation/recpient/' + id, {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify(data)
            }).then(res => res.json()).then((result) => {
                // this.props[0].history.push(`/chat?conversation=${result.conversation}`)
                if (result.conversation) {
                    this.getCases()
                    this.setState({ chatConversation: result.conversation })
                }

                if (result && result.members && result.members[0] === this.props.uData._id) {
                    this.setState({
                        doctorCanStartVideoCall: true
                    })
                } else {
                    this.setState({
                        doctorCanStartVideoCall: false
                    })
                }

            })

        })
    }
    transferDropIn = (item) => {


        this.setState({
            loading: true
        }, () => {
            fetch(API_ENDPOINT + '/referrals/transfer/drop-in/' + item._id, {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify({ doctor: this.state.selectedDoctor, prevDoctor: item.recipientUser, group: this.props.selectedGroup, clinic: this.props.selectedClinic })
            }).then(res => res.json()).then((result) => {
                if (result.error) {
                    this.setState({
                        error: result.error,
                        loading: false
                    })
                } else {
                    this.getCases();

                    this.setState({
                        transferModal: false,
                        selectedDoctor: -1,
                        loading: false

                    })
                }
            })
        })

    }
    updatePatientProfile = (obj) => {
        let data = {
            email: obj.email,
            phone: obj.phone,
            city: obj.city,
            street: obj.street,
            zip: obj.zip,
            userData: obj.userData

        }

        this.setState({
            loading: true
        }, () => {
            fetch(API_ENDPOINT + '/users/users/' + obj._id, {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify(data)
            }).then(res => res.json()).then((result) => {
                if (!result.error) {
                    this.searchPatient(obj.socialSecurityNumber, 1)
                    this.setState({
                        patientInfo: null,
                        succesMessage: 'You have successfully updated patient information.'.translate(this.props.lang)
                    })
                } else {
                    this.setState({
                        error: result.error
                    })
                }

            })
        })
    }
    updatePatientAddress = (obj) => {
        let data = {
            city: obj.city,
            street: obj.street,
            zip: obj.zip,
        }

        this.setState({
            loading: true
        }, () => {
            fetch(API_ENDPOINT + '/users/users/' + obj._id, {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify(data)
            }).then(res => res.json()).then((result) => {

            })
        })
    }
    closeReferral = (data) => {
        if (!data) data = {};
        data.selectedGroup = this.props.selectedGroup;
        data.closeConversation = true;

        this.setState({
            loading: true
        }, () => {
            fetch(API_ENDPOINT + '/referrals/close/' + this.state.referral._id, {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify(data)
            }).then(res => res.json()).then((result) => {
                this.get();
                if (result?.error) {
                    this.setState({
                        error: result.error?.translate(this.props.lang),
                        loading: false
                    })
                } else {
                    this.setState({
                        referral: null,
                        disallowForm: false,
                        returnForm: false,
                        scheduleForm: false
                    })
                }

            })
        })
    }
    deleteReferral = () => {
        if (!this.state.referral?._id) {
            return;
        }
        this.setState({
            loading: true
        }, () => {
            fetch(API_ENDPOINT + '/referrals/delete/' + this.state.referral._id, {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify({})
            }).then(res => res.json()).then((result) => {
                this.get();
                if (result?.error) {
                    this.setState({
                        error: result.error?.translate(this.props.lang),
                        loading: false
                    })
                } else {
                    this.setState({
                        referral: null,

                    })
                }

            })
        })
    }

    assignReferral = (item) => {

        this.setState({
            loadingAssign: true
        }, () => {
            fetch(API_ENDPOINT + '/referrals/assign/drop-in/' + item._id, {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify({})
            }).then(res => res.json()).then((result) => {
                this.get();

                this.setState({
                    conversationModal: null,
                    referral: null,
                    loadingAssign: false

                }, () => this.conversation(item._id, { ...this.state.conversationForm, patient: true }))
            })
        })


    }
    transferBookingByClinic = (referral) => {
        let data = {};
        if (referral && referral.event && (referral.status == 'scheduled' || referral.status === 'visit-rescheduled')) {
            if (referral.event.date) {
                data.date = referral.event.date;
            }
            if (referral.event.startTimeString) {
                data.startTime = referral.event.startTimeString;
            }
            if (referral.event.endTimeString) {
                data.endTime = referral.event.endTimeString;
            }
            if (this.state.selectedDoctor) {
                data.doctor = this.state.selectedDoctor;
            }
            if (referral.event._id) {
                data.id = referral.event._id;
            }
            this.setState({
                loading: true
            }, () => {
                fetch(API_ENDPOINT + '/referrals/transfer/' + data.id, {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({ startEventReservedDateOriginal: data.date + ' ' + data.startTime, endEventReservedDateOriginal: data.date + ' ' + data.endTime, startEventReservedDate: this.props.getStringDateInverseTs(Math.floor(new Date(data.date + ' ' + data.startTime).getTime() / 1000), `${this.props.dateFormat} HH:mm`), endEventReservedDate: this.props.getStringDateInverseTs(Math.floor(new Date(data.date + ' ' + data.endTime).getTime() / 1000), `${this.props.dateFormat} HH:mm`), doctor: data.doctor, group: this.props.selectedGroup, clinic: this.props.selectedClinic })
                }).then(res => res.json()).then((result) => {
                    if (result.error) {
                        if (result.error == 'Event can not start or end while others are in progress!') {
                            this.setState({
                                errorOverlap: {
                                    message: 'Unable to transfer this case to a new caregiver. The term is not available.'.translate(this.props.lang),
                                    doctor: referral.recipientUser,
                                    date: referral.event.startTime

                                },
                                loading: false
                            })
                        } else {
                            this.setState({
                                error: result.error.translate(this.props.lang),
                                loading: false
                            })
                        }
                    } else {
                        this.get()
                        this.setState({
                            transferModal: null,
                            selectedDoctor: -1,
                            loading: false
                        })
                    }

                })
            })


        } else if (referral) {
            this.setState({
                loading: true
            }, () => {
                fetch(API_ENDPOINT + '/referrals/transfer/drop-in/' + referral._id, {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({ doctor: this.state.selectedDoctor, prevDoctor: referral.recipientUser, group: this.props.selectedGroup, clinic: this.props.selectedClinic, transferType: 'bookingByClinic' })
                }).then(res => res.json()).then((result) => {
                    if (result.error) {
                        this.setState({
                            error: result.error,
                            loading: false
                        })
                    } else {
                        this.get();

                        this.setState({
                            transferModal: false,
                            selectedDoctor: -1,
                            loading: false

                        })
                    }
                })
            })

        }
    }

    transferWebBookingReferral = (referral) => {
        let data = {};
        if (referral && referral.event && (referral.status == 'scheduled' || referral.status === 'visit-rescheduled' || referral.status == 'revisit-scheduled')) {
            if (referral.event.date) {
                data.date = referral.event.date;
            }
            if (referral.event.startTimeString) {
                data.startTime = referral.event.startTimeString;
            }
            if (referral.event.endTimeString) {
                data.endTime = referral.event.endTimeString;
            }
            if (this.state.selectedDoctor) {
                data.doctor = this.state.selectedDoctor;
            }
            if (referral.event._id) {
                data.id = referral.event._id;
            }
            this.setState({
                loading: true
            }, () => {
                fetch(API_ENDPOINT + '/referrals/transfer/' + data.id, {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({ startEventReservedDateOriginal: data.date + ' ' + data.startTime, endEventReservedDateOriginal: data.date + ' ' + data.endTime, startEventReservedDate: this.props.getStringDateInverseTs(Math.floor(new Date(data.date + ' ' + data.startTime).getTime() / 1000), `${this.props.dateFormat} HH:mm`), endEventReservedDate: this.props.getStringDateInverseTs(Math.floor(new Date(data.date + ' ' + data.endTime).getTime() / 1000), `${this.props.dateFormat} HH:mm`), doctor: data.doctor, group: this.props.selectedGroup, clinic: this.props.selectedClinic })
                }).then(res => res.json()).then((result) => {
                    if (result.error) {
                        if (result.error == 'Event can not start or end while others are in progress!') {
                            this.setState({
                                errorOverlap: {
                                    message: 'Unable to transfer this case to a new caregiver. The term is not available.'.translate(this.props.lang),
                                    doctor: referral.recipientUser,
                                    date: referral.event.startTime

                                },
                                loading: false
                            })
                        } else {
                            this.setState({
                                error: result.error.translate(this.props.lang),
                                loading: false
                            })
                        }
                    } else {
                        this.get()
                        this.setState({
                            transferModal: null,
                            selectedDoctor: -1,
                            loading: false
                        })
                    }

                })
            })


        } else if (referral) {
            this.setState({
                loading: true
            }, () => {
                fetch(API_ENDPOINT + '/referrals/transfer/drop-in/' + referral._id, {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({ doctor: this.state.selectedDoctor, prevDoctor: referral.recipientUser, group: this.props.selectedGroup, clinic: this.props.selectedClinic, transferType: 'webbooking' })
                }).then(res => res.json()).then((result) => {
                    if (result.error) {
                        this.setState({
                            error: result.error,
                            loading: false
                        })
                    } else {
                        this.get();

                        this.setState({
                            transferModal: false,
                            selectedDoctor: -1,
                            loading: false

                        })
                    }
                })
            })

        }

    }
    generateTimeReferral = (data) => {
        if (data.event)
            data.controlSchedule = data
        let date = new Date();
        var startDate = this.props.getStringDateTs(Math.floor(date.getTime() / 1000), 'YYYY-MM-DD')/*moment.unix(Math.floor(date.getTime() / 1000)).format('YYYY-MM-DD')*/;
        let start = this.props.getStringDateTs(Math.floor(date.getTime() / 1000 + 600), 'HH:mm')/*moment.unix(Math.floor(date.getTime() / 1000 + 600)).format('HH:mm')*/
        var end = this.props.getStringDateTs(Math.floor(date.getTime() / 1000 + 900), 'HH:mm')/*moment.unix(Math.floor(date.getTime() / 1000 + 900)).format("HH:mm")*/;


        data.date = startDate;
        data.startTime = start;
        data.endTime = end;

        if (!this.state.adminPermission) {

            this.setState({ listDoctor: this.props.uData._id, loadingDoctor: true, scroll: false }, () => {

                this.getWorkingTime(this.state.listDoctor)
            })
        }


        this.setState({
            scheduleListReferral: data
        })

    }
    getWorkingTime = () => {
        // {
        //     this.state.listDoctor ?
        //         this.state.subAccounts.filter(item => item._id.toString() == this.state.listDoctor).map(item => {
        //             if (item.workingTimeConfig) {
        //                 if (item.workingTimeConfig[this.props.selectedGroup] && item.workingTimeConfig[this.props.selectedGroup][this.props.selectedClinic]) {
        //                     this.setState({
        //                         min: new Date(item.workingTimeConfig[this.props.selectedGroup][this.props.selectedClinic].min),
        //                         max: new Date(item.workingTimeConfig[this.props.selectedGroup][this.props.selectedClinic].max),

        //                     })
        //                 }
        //             }
        //         })
        //         : this.setState({
        //             min: this.state.min,
        //             max: this.state.max,
        //         })
        // }
        let obj = {

        };
        obj.min = new Date();
        obj.min.setHours(8, 30, 0)
        obj.max = new Date();
        obj.max.setHours(20, 30, 0)

        obj.min = new Date(this.props.getStringDateTs(Math.floor(new Date(obj.min).getTime() / 1000), 'MM/DD/YYYY HH:mm'))
        obj.max = new Date(this.props.getStringDateTs(Math.floor(new Date(obj.max).getTime() / 1000), 'MM/DD/YYYY HH:mm'))

        if (this.state.listDoctor) {
            if (this.state.subAccounts && this.state.subAccounts.length && this.state.subAccounts.filter(item => item._id.toString() == this.state.listDoctor).length) {
                let doctors = this.state.subAccounts.filter(item => item._id.toString() == this.state.listDoctor);
                for (let i = 0; i < doctors.length; i++) {
                    if (doctors[i].workingTimeConfig) {
                        if (doctors[i].workingTimeConfig[this.props.selectedGroup] && doctors[i].workingTimeConfig[this.props.selectedGroup][this.props.selectedClinic]) {
                            obj.min = new Date(doctors[i].workingTimeConfig[this.props.selectedGroup][this.props.selectedClinic].min)
                            obj.max = new Date(doctors[i].workingTimeConfig[this.props.selectedGroup][this.props.selectedClinic].max)
                        }
                    }
                }
            }
        }
        let scheduleListReferral = this.state.scheduleListReferral;
        let min = this.props.getStringDateTs(Math.floor(obj.min.getTime() / 1000), 'HH:mm');
        let max = this.props.getStringDateTs(Math.floor(obj.max.getTime() / 1000), 'HH:mm');
        if (scheduleListReferral?.startTime && scheduleListReferral?.endTime && min) {
            let minSeconds = Number(min?.split(':')?.[0]) * 60 + Number(min?.split(':')?.[1])
            let maxSeconds = Number(max?.split(':')?.[0]) * 60 + Number(max?.split(':')?.[1])
            let startTimeSeconds = Number(scheduleListReferral.startTime?.split(':')?.[0]) * 60 + Number(scheduleListReferral.startTime?.split(':')?.[1])
            let endTimeSeconds = Number(scheduleListReferral.endTime?.split(':')?.[0]) * 60 + Number(scheduleListReferral.endTime?.split(':')?.[1])
            if (minSeconds > startTimeSeconds) {
                scheduleListReferral.startTime = min;
                obj.scheduleListReferral = scheduleListReferral;
            }
            if (minSeconds > endTimeSeconds) {
                scheduleListReferral.endTime = this.props.getStringDateTs(Math.floor((obj.min.getTime() / 1000) + 60 * 5), 'HH:mm');
                obj.scheduleListReferral = scheduleListReferral;
            }
            if (maxSeconds < startTimeSeconds) {
                scheduleListReferral.startTime = this.props.getStringDateTs(Math.floor((obj.max.getTime() / 1000) - 60 * 5), 'HH:mm');;
                obj.scheduleListReferral = scheduleListReferral;
            }
            if (maxSeconds < endTimeSeconds) {
                scheduleListReferral.endTime = max;
                obj.scheduleListReferral = scheduleListReferral;
            }
        }
        this.setState(obj)

    }
    listScheduleReferral = (data) => {
        if (data && data.additionalDoctors && data.additionalDoctors.length) {

            this.checkDoctorsAvailable(data.additionalDoctors, 5, () => {
                if (!this.state.errorAdditionalDoctors) {
                    let checkDate = new Date(data.date.replace(/-/g, "/") + ' ' + data.startTime);
                    let today = new Date();

                    if (checkDate >= today) {
                        // alert('test')
                        this.setState({
                            loading: true
                        }, () => {
                            fetch(API_ENDPOINT + '/referrals/schedule/' + data._id, {
                                method: 'POST',
                                headers: {
                                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                                    'content-type': 'application/json'
                                },
                                body: JSON.stringify({
                                    startEventReservedDateOriginal: data.date + ' ' + data.startTime,
                                    endEventReservedDateOriginal: data.date + ' ' + data.endTime,
                                    startPatientReservedDate: this.props.getStringDateInverseTs(Math.floor(new Date(data.date + ' ' + data.startTime).getTime() / 1000), `${this.props.dateFormat} HH:mm`),
                                    endPatientReservedDate: this.props.getStringDateInverseTs(Math.floor(new Date(data.date + ' ' + data.endTime).getTime() / 1000), `${this.props.dateFormat} HH:mm`),
                                    doctor: this.state.listDoctor,
                                    patientNote: data.patientNote,
                                    additionalDoctors: data.additionalDoctors,
                                    rescheduleReferral: data.rescheduleReferral ? true : false
                                })
                            }).then(res => res.json()).then((result) => {
                                // alert('test')
                                if (result.error) {
                                    if (result.error == 'Event can not start or end while others are in progress!') {
                                        this.setState({
                                            errorOverlap: {
                                                message: 'Unable to schedule this case. Selected time period is not available.'.translate(this.props.lang),
                                                doctor: this.state.listDoctor,
                                                date: Math.floor(new Date(data.date + ' ' + data.startTime).getTime() / 1000)

                                            },
                                            loading: false
                                        })
                                    } else {
                                        this.setState({
                                            error: result.error.translate(this.props.lang),
                                            loading: false
                                        })
                                        this.get();
                                    }
                                } else {
                                    this.get();
                                    this.setState({
                                        scheduleListReferral: null,
                                        doctor: this.state.listDoctor,
                                        doctorsList: [],
                                        referral: null,
                                        listDoctor: null
                                    }, () => {
                                        // this.setState({
                                        //     day: new Date(data.date.replace(/-/g, "/") + ' ' + data.startTime),
                                        //     view: 'day',
                                        //     scroll: true,
                                        // }, () => {
                                        //     this.get();
                                        //     this.getWorkingTime()
                                        // })
                                    })
                                }

                            })
                        })
                    } else {
                        this.setState({
                            wrongDate: true
                        })


                    }
                }

            })
        } else {
            let checkDate = new Date(data.date.replace(/-/g, "/") + ' ' + data.startTime);
            let today = new Date();

            if (checkDate >= today) {
                // alert('test')
                this.setState({
                    loading: true
                }, () => {
                    fetch(API_ENDPOINT + '/referrals/schedule/' + data._id, {
                        method: 'POST',
                        headers: {
                            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                            'content-type': 'application/json'
                        },
                        body: JSON.stringify({
                            startEventReservedDateOriginal: data.date + ' ' + data.startTime,
                            endEventReservedDateOriginal: data.date + ' ' + data.endTime,
                            startPatientReservedDate: this.props.getStringDateInverseTs(Math.floor(new Date(data.date + ' ' + data.startTime).getTime() / 1000), `${this.props.dateFormat} HH:mm`),
                            endPatientReservedDate: this.props.getStringDateInverseTs(Math.floor(new Date(data.date + ' ' + data.endTime).getTime() / 1000), `${this.props.dateFormat} HH:mm`),
                            doctor: this.state.listDoctor,
                            patientNote: data.patientNote,
                            additionalDoctors: data.additionalDoctors,
                            rescheduleReferral: data.rescheduleReferral ? true : false
                        })
                    }).then(res => res.json()).then((result) => {
                        // alert('test')
                        if (result.error) {
                            if (result.error == 'Event can not start or end while others are in progress!') {
                                this.setState({
                                    errorOverlap: {
                                        message: 'Unable to schedule this case. Selected time period is not available.'.translate(this.props.lang),
                                        doctor: this.state.listDoctor,
                                        date: Math.floor(new Date(data.date + ' ' + data.startTime).getTime() / 1000)

                                    },
                                    loading: false
                                })
                            } else {
                                this.setState({
                                    error: result.error.translate(this.props.lang),
                                    loading: false
                                })
                                this.get();
                            }
                        } else {
                            this.get();
                            this.setState({
                                scheduleListReferral: null,
                                doctor: this.state.listDoctor,
                                doctorsList: [],
                                referral: null,
                                listDoctor: null
                            }, () => {
                                // this.setState({
                                //     day: new Date(data.date.replace(/-/g, "/") + ' ' + data.startTime),
                                //     view: 'day',
                                //     scroll: true,
                                // }, () => {
                                //     this.get();
                                //     this.getWorkingTime()
                                // })
                            })
                        }

                    })
                })
            } else {
                this.setState({
                    wrongDate: true
                })


            }
        }


    }
    /**
    * Allow referral if status is waiting-for-approval
    * @author   Milan Stanojevic
    */
    allowReferral = () => {
        this.setState({
            loading: true
        }, () => {
            fetch(API_ENDPOINT + '/referrals/allow/' + this.state.referral._id, {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify({})
            }).then(res => res.json()).then((result) => {
                this.get();
                if (result.error) {
                    this.setState({
                        error: result.error?.translate(this.props.lang),
                        signinModal: false,
                        allowForm: false,
                        referral: null,
                        disallowForm: false,
                        returnForm: false,
                        scheduleForm: false,
                    })
                } else {
                    this.setState({
                        signinModal: false,
                        allowForm: false,
                        referral: null,
                        disallowForm: false,
                        returnForm: false,
                        scheduleForm: false,
                        scheduleReferral: true
                    })
                }

            })
        })
    }


    /**
    * Disallow referral if status is waiting-for-approval
    * @author   Milan Stanojevic
    */
    disallowReferral = (data) => {
        this.setState({
            loading: true
        }, () => {
            fetch(API_ENDPOINT + '/referrals/disallow/' + this.state.referral._id, {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify(data)
            }).then(res => res.json()).then((result) => {
                this.get();
                if (result.error) {
                    this.setState({
                        error: result.error?.translate(this.props.lang),
                        referral: null,
                        disallowForm: false,
                        returnForm: false,
                        scheduleForm: false,
                    })
                } else {
                    this.setState({
                        referral: null,
                        disallowForm: false,
                        returnForm: false,
                        scheduleForm: false
                    })
                }

            })
        })
    }
    generateHtmlForPrint = () => {
        let referral = this.state.referral;
        let html = '<div>';
        if (referral) {
            html += `${this.props.lang == 'se' ? this.state.referral.referralContent : this.state.referral.referralContentEn ? this.state.referral.referralContentEn : this.state.referral.referralContent}`
            if (referral.revisitFormsArray && referral.revisitFormsArray.length) {
                let revisitFormsArray = referral.revisitFormsArray;
                for (let i = 0; i < revisitFormsArray.length; i++) {
                    html += `<h6 style="font-size: 18px;">${'Patient answers'.translate(this.props.lang)} <span style="margin-left:20px; font-weight:600">${revisitFormsArray[i].name}</span> </h6>`
                    if (revisitFormsArray[i].referralData) {
                        html += `${revisitFormsArray[i].referralData ? revisitFormsArray[i].referralData : ''}`
                        html += `${revisitFormsArray[i].referralTotalData ? revisitFormsArray[i].referralTotalData : ''}`
                    } else {
                        html += `<p style="font-style: italic; font-size: 14px;">${'The patient did not fill out the form'.translate(this.props.lang)}</p>`
                    }
                    if (i != (revisitFormsArray.length - 1)) {
                        html += '<hr/>'
                    }
                }
            }

        }
        html += '</div>'

        return html;
    }


    updateReferralNote = (data) => {
        let obj = {
            note: data.note,
            username: this.props.uData.username
        }
        this.setState({
            loading: true
        }, () => {
            fetch(API_ENDPOINT + '/referrals/note/' + data._id, {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify(obj)
            }).then(res => res.json()).then((result) => {
                this.setState({
                    referralNote: null,

                })
                this.get()
            })
        })
    }
    addUserToConversation = (conversationId) => {

        fetch(API_ENDPOINT + '/users/conversations/add-user', {
            method: 'POST',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
            body: JSON.stringify({ conversation: conversationId })
        }).then(res => res.json()).then((result) => {
            if (result && result._id) {
                this.setState({ chatConversation: result._id })
            }

        })
    }

    priorityForDropIn = (item) => {

        fetch(API_ENDPOINT + '/referrals/priority/drop-in/' + item._id, {
            method: 'POST',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
            body: JSON.stringify({ priority: item.priority })
        }).then(res => res.json()).then((result) => {
            this.get();
            let referral = this.state.referral
            referral.priority = item.priority
            this.setState({
                referral
            })
        })

    }

    getClinicGroupSettings = () => {
        this.setState({ clinicGroupSettings: null }, async () => {
            if (!this.props?.selectedGroup) return;
            const response = await fetch(API_ENDPOINT + '/data/clinic-group-settings/' + this.props.selectedGroup);
            if (!response || !response.ok) return;
            const result = await response.json();
            if (!result || result.error || !result.clinicGroupSettings) return;
            this.setState({ clinicGroupSettings: result.clinicGroupSettings });
        })
    }

    change2FAStatus = (patient, action, closeModal = false) => {
        if (!patient || !action) return this.setState({ error: 'Something went wrong! Please refresh your browser and try again!'.translate(this.props.lang) });

        if (action === 'send-link' && !this.state.twoFactorAuthenticationPhoneNumber?.trim?.()) return this.setState({ error: 'Please enter a valid phone number!'.translate(this.props.lang) });

        this.setState({ changing2FAStatus: true }, async () => {

            const response = await fetch(API_ENDPOINT + '/users/two-factor-auth', {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify({
                    groupId: this.props.selectedGroup,
                    patientId: patient?._id,
                    action,
                    newPhoneNumber: this.state.twoFactorAuthenticationPhoneNumber && this.state.twoFactorAuthenticationPhoneNumber !== this.state.usersPhoneNumber
                        ? (this.state.twoFactorAuthenticationPhoneNumber?.trim?.()?.replace?.('+', '')?.replace?.(/ /g, '') || '')
                        : null
                })
            });

            if (!response) return this.setState({
                changing2FAStatus: false,
                error: 'Something went wrong! Please refresh your browser and try again!'.translate(this.props.lang)
            });

            const result = await response.json();
            if (!result || result.error) return this.setState({
                changing2FAStatus: false,
                error: result.error?.translate(this.props.lang) || 'Something went wrong! Please refresh your browser and try again!'.translate(this.props.lang)
            });

            this.setState(prev => ({
                ...prev,
                changing2FAStatus: false,
                twoFactorAuthenticationMessage: result.message ? result.message?.translate(this.props.lang) : null,
                patient: {
                    ...(prev?.patient || {}),
                    phone: result.phone ? result.phone : (prev?.patient?.phone || ''),
                    twoFactorAuthentication: result.twoFactorAuthentication
                }
            }));

            if (closeModal) this.setState({
                twoFactorAuthenticationModal: false,
                twoFactorAuthenticationPhoneNumber: '',
                usersPhoneNumber: ''
            })
        });
    }

    startVideoCall = () => {
        if (!this.state.chatConversation && !this.state.chatId) return;
        if (!this.state._videoButtonEnabled) return;
        this.props?.startVideoCall?.(this.state.chatConversation ? this.state.chatConversation : this.state.chatId);
    }

    prepareNote = (item) => {
        let counter = 0
        let noteArr = item.note.split('')
        let noteFinished = ''
        for (let i = 0; i < noteArr.length; i++) {
            counter++
            noteFinished = noteFinished + noteArr[i]
            if (noteArr[i] === '\n') {
                counter = 0
            }

            if (counter === 80) {
                counter = 0
                noteFinished = noteFinished + '\n'
            }

        }

        item.note = noteFinished

        return item.note.split('\n').join('<br/>')
    }
    removeReferralFromList = (referralId) => {
        let data = {
            referralId: referralId,
            clinic: this.props.selectedClinic
        }
        if (data.referralId && data.clinic) {
            fetch(API_ENDPOINT + '/referral/remove/transfered/info', {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify(data)
            }).then(res => res.json()).then((result) => {
                if (result?.error) {
                    this.setState({ error: result.error })
                } else {
                    this.setState({ removeReferralModal: null, referral: null })
                    this.get()

                }

            })
        }


    }
    render() {
        let selectedGroupIdx = -1;
        if (this.props.selectedGroup && this.props.uData?.clinicGroups?.length) {
            for (let i = 0; i < this.props.uData.clinicGroups.length; i++) {
                if (this.props.uData.clinicGroups[i]._id == this.props.selectedGroup) {
                    selectedGroupIdx = i;
                    break;
                }
            }
        }
        if (!this.props.uData || (this.props.uData && !this.props.uData.clinicGroups) || (this.props.uData && this.props.uData.clinicGroups && !this.props.uData.clinicGroups[selectedGroupIdx])) {
            return null
        } else if (this.props.uData && this.props.uData.clinicGroups && this.props.uData.clinicGroups[selectedGroupIdx] && !this.props.uData.clinicGroups[selectedGroupIdx].enterprise) {
            return null;
        }
        const statementEnabled = (this.props.uData?.clinicGroups?.[selectedGroupIdx]?.enterprise || this.props.uData?.clinicGroups?.[selectedGroupIdx]?.sarchPatients) && this.props.uData?.clinicGroups?.[selectedGroupIdx]?.medicalStatments;

        let params = {};
        if (this.state.useState) {
            params = this.state.params;
        } else {
            params = getSearchParams(this.props[0].location.search, { entries: 10, page: 0 })
        }
        const defaultOptions = {
            loop: true,
            autoplay: true,
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice'
            }
        };

        const statusColors = {
            'waiting-for-approval': 'orange',
            'waiting-for-payment': 'orange',
            'disallowed': 'red',
            'allowed': 'green',
            'return-for-update': 'blue',
            'patient-requested-another-time': 'purple',
            'patient-rejected': 'red',
            'patient-accepted': 'green',
            'clinic-accepted-requested-time': 'green',
            'clinic-rejected-requested-time': 'orange',
            'approved-by-region-manager': 'green',
            'rejected-by-region-manager': 'red',
            'scheduled': 'green',
            'visit-rescheduled': 'green',
            'not-booked': 'red',
            'revisit-scheduled': 'green',
            'closed': 'gray',
            'in-progress': 'green',
            'waiting': 'orange',
            'canceled-by-clinic': 'red',
            'requested-new-appointment': 'orange',
            'external': 'gray',

        }

        const humanReadable = {
            'waiting-for-approval': 'Waiting for approval'.translate(this.props.lang),
            'waiting-for-payment': 'Waiting for payment'.translate(this.props.lang),
            'disallowed': 'Disallowed'.translate(this.props.lang),
            'allowed': 'Allowed'.translate(this.props.lang),
            'return-for-update': 'Return for update'.translate(this.props.lang),
            'patient-requested-another-time': 'Patient requested anoother time'.translate(this.props.lang),
            'patient-rejected': 'Rejected by patient'.translate(this.props.lang),
            'patient-accepted': 'Patient accepted'.translate(this.props.lang),
            'clinic-accepted-requested-time': 'Clinic accepted requested time'.translate(this.props.lang),
            'clinic-rejected-requested-time': 'Clinic rejected requested time'.translate(this.props.lang),
            'approved-by-region-manager': 'Approved by region manager'.translate(this.props.lang),
            'rejected-by-region-manager': 'Rejected by region manager'.translate(this.props.lang),
            'scheduled': 'Scheduled'.translate(this.props.lang),
            'visit-rescheduled': 'Visit rescheduled'.translate(this.props.lang),
            'revisit-scheduled': 'Revisit scheduled'.translate(this.props.lang),
            'not-booked': 'Not booked'.translate(this.props.lang),
            'closed': 'Closed'.translate(this.props.lang),
            'waiting': 'Waiting'.translate(this.props.lang),
            'in-progress': 'In progress'.translate(this.props.lang),
            'canceled-by-clinic': 'Canceled by clinic'.translate(this.props.lang),
            'requested-new-appointment': 'Requested new appointment'.translate(this.props.lang),
            'external': 'External transfer'.translate(this.props.lang),

        }
        const isChild = this.state.age && (this.props.country === 'denmark' ? this.state.age < CHILD_AGE_LIMIT.denmark : this.props.country === 'sweden' ? this.state.age < CHILD_AGE_LIMIT.sweden : this.state.age < 18) ? true : false;

        const groupPermissionsUser = this.props.uData?.groupPermissionsUser;
        const adminPermissionId = this.props.uData?.permissions?.admin;
        const doctorPermissionId = this.props.uData?.permissions?.doctor;
        const isAdmin = groupPermissionsUser?.[this.props.selectedGroup]?.[this.props.selectedClinic]?.indexOf(adminPermissionId) !== -1 ? true : false;
        const isDoctor = groupPermissionsUser?.[this.props.selectedGroup]?.[this.props.selectedClinic]?.indexOf(doctorPermissionId) !== -1 ? true : false;

        const eConnectEnabled = this.props.uData && this.props.uData.clinicGroups && this.props.uData.clinicGroups[selectedGroupIdx] && this.props.uData.clinicGroups[selectedGroupIdx].eConnect && !this.props.uData.clinicGroups[selectedGroupIdx].eConnectTempDisabled;

        return (
            <div className={this.props.autoHideSidebar ? "dashboard dashboard-auto-hide" : "dashboard"}>
                {
                    this.state.errorPatientNotFound ?
                        <Modal isOpen={this.state.errorPatientNotFound} toggle={() => this.setState({ errorPatientNotFound: !this.state.errorPatientNotFound })} centered>
                            <ModalHeader style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                {'Patient not found'.translate(this.props.lang)}
                            </ModalHeader>
                            <ModalBody className='delete-modal'>
                                <p>{this.state.errorPatientNotFound}</p>
                            </ModalBody>
                            <ModalFooter style={{ justifyContent: 'center' }}>
                                <Button style={{ minWidth: 100 }} className={'danger-button'} onClick={() => this.setState({ errorPatientNotFound: !this.state.errorPatientNotFound })}>{'Cancel'.translate(this.props.lang)}</Button>
                                <Button style={{ minWidth: 100 }} color='primary' onClick={() => this.setState({ errorPatientNotFound: !this.state.errorPatientNotFound, registerNewPatientModal: { language: this.state.languages && this.state.languages[0] && this.state.languages[0]._id, socialSecurityNumber: this.state.search, twoFactorAuthenticationEnabled: this.props.country === 'uk' ? true : false } }, () => {
                                    // if(this.state.age >= 13){
                                    this.checkSSN(this.state.search)
                                    // }
                                    if (this.props.country === 'uk') {
                                        this.setState({ search: null })
                                    }


                                })}>{'Register a new patient'.translate(this.props.lang)}</Button>

                            </ModalFooter>
                        </Modal>

                        : null
                }
                {this.state.registerNewPatientModal ?
                    <FormBuilder
                        selectedGroup={this.props.selectedGroup}
                        selectedClinic={this.props.selectedClinic}
                        countryTwoLetter={this.props.countryTwoLetter}
                        country={this.props.country} dateFormat={this.props.dateFormat}
                        getStringDateTs={this.props.getStringDateTs}
                        onSubmit={(data) => {
                            if (!this.state.ssnExistInThisGroup) {
                                this.checkPatientValidations(data)
                            } else {
                                this.setState({ registerNewPatientModal: null, ssnExistInThisGroup: null, socialSecurityNumberExist: null })
                            }
                        }}
                        initialValues={this.state.registerNewPatientModal}
                        size="lg"
                        isOpen={this.state.registerNewPatientModal}
                        toggle={() => this.setState({ registerNewPatientModal: null, age: null, ssnExistInThisGroup: null, socialSecurityNumberExist: null })}
                        title={this.state.registerNewPatientModal._id ? 'Edit patient'.translate(this.props.lang) : 'Register a new patient'.translate(this.props.lang)}
                        _saving={this.state._saving}
                        buttonConfirmText={'Saved'.translate(this.props.lang)}
                        buttonText={!this.state.ssnExistInThisGroup ? 'Save and send confirmation'.translate(this.props.lang) : 'Close'.translate(this.props.lang)}
                        lang={this.props.lang}
                        fields={[
                            {
                                type: 'row',
                                children: [
                                    {
                                        type: 'col',
                                        width: {
                                            lg: 8,
                                            sm: 12,
                                            xs: 12
                                        },
                                        children: [
                                            {
                                                type: 'socialSecurityNumber',
                                                name: 'socialSecurityNumber',
                                                searchByNameAndEmailFormBuilder: this.state.registerNewPatientModal.socialSecurityNumber ? false : true,
                                                disableNewPatientBtn: true,
                                                label: this.props.country === 'uk' ? "Date of birth".translate(this.props.lang) : 'Social security number'.translate(this.props.lang),
                                                disabled: this.state.registerNewPatientModal.socialSecurityNumber ? true : false,
                                                validate: [required('Social security number is required!'.translate(this.props.lang)), checkSocialSecurityNumber('Social security number is not valid!'.translate(this.props.lang), this.props.country)]
                                            },
                                        ]
                                    },
                                    this.props.showDateOfBirth ?
                                        {
                                            type: 'col',
                                            width: {
                                                lg: 4,
                                                sm: 12,
                                                xs: 12
                                            },
                                            children: [
                                                {
                                                    type: 'date-of-birth',
                                                    dateFormat: this.props.dateFormat,
                                                    name: 'dateOfBirth.date',
                                                    disabled: this.state.disabledDateOfBirth,
                                                    label: 'Date of birth'.translate(this.props.lang),
                                                    validate: [required('Date of birth is required!'.translate(this.props.lang)), dateValidate('Invalid date!'.translate(this.props.lang))]

                                                },
                                            ]
                                        } : null,
                                    this.state.socialSecurityNumberExist && !this.state.ssnExistInThisGroup ?
                                        {
                                            type: 'col',
                                            width: {
                                                lg: 12,
                                                sm: 12,
                                                xs: 12
                                            },
                                            children: [
                                                {
                                                    type: 'h6',
                                                    text: 'The user already exists in the system'.translate(this.props.lang),
                                                    style: { marginBottom: 20 }
                                                },
                                            ]
                                        } : { type: 'empty' },
                                    this.state.socialSecurityNumberExist && this.state.ssnExistInThisGroup ?
                                        {
                                            type: 'col',
                                            width: {
                                                lg: 12,
                                                sm: 12,
                                                xs: 12
                                            },
                                            children: [
                                                {
                                                    type: 'h6',
                                                    text: "The user is already registered within your clinic group".translate(this.props.lang),
                                                    style: { marginBottom: 20 }
                                                },
                                            ]
                                        } : { type: 'empty' },

                                    !this.state.socialSecurityNumberExist /*|| (isChild && this.state.socialSecurityNumberExist)*/ ?
                                        {
                                            type: 'col',
                                            width: {
                                                lg: 4,
                                                sm: 12,
                                                xs: 12
                                            },
                                            children: [
                                                {
                                                    type: 'select',
                                                    name: 'userData.sex',
                                                    labelInfo: "The patient's biological sex is needed for proper treatment".translate(this.props.lang),
                                                    label: 'Sex'.translate(this.props.lang),
                                                    disabled: this.state.socialSecurityNumberExist && !this.state.ssnExistInThisGroup ? true : false,
                                                    values: [{ value: 0, name: 'Select'.translate(this.props.lang) }, { value: 'male', name: 'Male'.translate(this.props.lang) }, { value: 'female', name: 'Female'.translate(this.props.lang) }],
                                                },
                                            ]
                                        } : { type: 'empty' },
                                    !this.state.socialSecurityNumberExist /*|| (isChild && this.state.socialSecurityNumberExist)*/ ?
                                        {
                                            type: 'col',
                                            width: {
                                                lg: this.props.showDateOfBirth ? this.props.secondaryLanguageDisabled ? 6 : 4 : this.props.secondaryLanguageDisabled ? 12 : 8,
                                                sm: 12,
                                                xs: 12
                                            },
                                            children: [
                                                {
                                                    type: 'text',
                                                    name: 'userData.name',
                                                    label: 'First and last name'.translate(this.props.lang),
                                                    disabled: this.state.socialSecurityNumberExist && !this.state.ssnExistInThisGroup ? true : false,
                                                    validate: [required('Name is required!'.translate(this.props.lang))]
                                                },
                                            ]
                                        } : { type: 'empty' },
                                    isChild ?
                                        {
                                            type: 'col',
                                            width: {
                                                lg: 12,
                                                sm: 12,
                                                xs: 12
                                            },
                                            children: [
                                                {
                                                    type: 'h6',
                                                    style: { color: 'red', marginBottom: 20 },
                                                    text: 'Child is under 13 years of age - Account must be connected to a parent- or legal guardian account.'.translate(this.props.lang),
                                                },
                                            ]
                                        } : { type: 'empty' },
                                    !this.state.socialSecurityNumberExist && isChild /*|| (isChild && this.state.socialSecurityNumberExist)*/ ?
                                        {
                                            type: 'col',
                                            width: {
                                                lg: 12,
                                                sm: 12,
                                                xs: 12
                                            },
                                            children: [
                                                {
                                                    type: 'h6',
                                                    style: { marginBottom: 20 },
                                                    text: 'Register parent or legal guardian'.translate(this.props.lang),
                                                },
                                            ]
                                        } : { type: 'empty' },
                                    !this.state.socialSecurityNumberExist && isChild /*|| (isChild && this.state.socialSecurityNumberExist)*/ ?
                                        {
                                            type: 'col',
                                            width: {
                                                lg: 8,
                                                sm: 12,
                                                xs: 12
                                            },
                                            children: [
                                                {
                                                    type: 'socialSecurityNumber',
                                                    name: 'socialSecurityNumberParent',
                                                    searchByNameAndEmailFormBuilder: this.state.socialSecurityNumberExist && !this.state.ssnExistInThisGroup ? false : true,
                                                    disableNewPatientBtn: true,
                                                    hideFindChildren: true,
                                                    label: this.props.country === 'uk' ? "Date of birth".translate(this.props.lang) : 'Social security number'.translate(this.props.lang),
                                                    disabled: this.state.socialSecurityNumberExist && !this.state.ssnExistInThisGroup ? true : false,
                                                    validate: [required('Social security number is required!'.translate(this.props.lang)), checkSocialSecurityNumber('Social security number is not valid!'.translate(this.props.lang), this.props.country)]
                                                },
                                            ]
                                        } : { type: 'empty' },
                                    isChild && this.props.showDateOfBirth ?
                                        {
                                            type: 'col',
                                            width: {
                                                lg: 4,
                                                sm: 12,
                                                xs: 12
                                            },
                                            children: [
                                                {
                                                    type: 'date-of-birth',
                                                    dateFormat: this.props.dateFormat,
                                                    name: 'dateOfBirthParent.date',
                                                    disabled: this.state.disabledDateOfBirthParent,
                                                    label: 'Date of birth'.translate(this.props.lang),
                                                    validate: [required('Date of birth is required!'.translate(this.props.lang)), dateValidate('Invalid date!'.translate(this.props.lang))]

                                                },
                                            ]
                                        } : { type: 'empty' },
                                    !this.state.socialSecurityNumberExist && isChild /*|| (isChild && this.state.socialSecurityNumberExist)*/ ?
                                        {
                                            type: 'col',
                                            width: {
                                                lg: 4,
                                                sm: 12,
                                                xs: 12
                                            },
                                            children: [
                                                {
                                                    type: 'select',
                                                    name: 'sexParent',
                                                    label: 'Sex'.translate(this.props.lang),
                                                    disabled: this.state.socialSecurityNumberExist && !this.state.ssnExistInThisGroup ? true : false,
                                                    values: [{ value: 0, name: 'Select'.translate(this.props.lang) }, { value: 'male', name: 'Male'.translate(this.props.lang) }, { value: 'female', name: 'Female'.translate(this.props.lang) }],
                                                },
                                            ]
                                        } : { type: 'empty' },
                                    !this.state.socialSecurityNumberExist && isChild /*|| (isChild && this.state.socialSecurityNumberExist)*/ ?
                                        {
                                            type: 'col',
                                            width: {
                                                lg: this.props.showDateOfBirth ? this.props.secondaryLanguageDisabled ? 6 : 4 : this.props.secondaryLanguageDisabled ? 12 : 8,
                                                sm: 12,
                                                xs: 12
                                            },
                                            children: [
                                                {
                                                    type: 'text',
                                                    name: 'nameParent',
                                                    label: 'First and last name'.translate(this.props.lang),
                                                    validate: [required('Name is required!'.translate(this.props.lang))],
                                                    disabled: this.state.socialSecurityNumberExist && !this.state.ssnExistInThisGroup ? true : false,

                                                },
                                            ]
                                        } : { type: 'empty' },
                                    (!this.state.socialSecurityNumberExist /*|| (isChild && this.state.socialSecurityNumberExist)*/) && !this.props.secondaryLanguageDisabled ?
                                        {
                                            type: 'col',
                                            width: {
                                                lg: 4,
                                                sm: 12,
                                                xs: 12
                                            },
                                            children: [
                                                {
                                                    type: 'select',
                                                    name: 'language',
                                                    label: 'Language'.translate(this.props.lang),
                                                    disabled: this.state.socialSecurityNumberExist && !this.state.ssnExistInThisGroup ? true : false,
                                                    values: this.state.languages.map(item => { return { name: item.name?.translate(this.props.lang), value: item._id } })
                                                },
                                            ]
                                        } : { type: 'empty' },

                                    !this.state.socialSecurityNumberExist /*|| (isChild && this.state.socialSecurityNumberExist)*/ ?
                                        {
                                            type: 'col',
                                            width: {
                                                lg: 6,
                                                sm: 12,
                                                xs: 12
                                            },
                                            children: [
                                                {
                                                    type: 'text',
                                                    name: 'email',
                                                    label: 'Email address'.translate(this.props.lang),
                                                    disabled: this.state.socialSecurityNumberExist && !this.state.ssnExistInThisGroup ? true : false,
                                                    validate: this.props.twoFactorAuthenticationEnabled ? [required('When two factor authentication is enabled, both email and phone number are required!'.translate(this.props.lang))] : []
                                                },
                                            ]
                                        } : { type: 'empty' },
                                    !this.state.socialSecurityNumberExist /*|| (isChild && this.state.socialSecurityNumberExist)*/ ?
                                        {
                                            type: 'col',
                                            width: {
                                                lg: 6,
                                                sm: 12,
                                                xs: 12
                                            },
                                            children: [
                                                {
                                                    type: 'phone',

                                                    name: 'phone',
                                                    label: 'Phone'.translate(this.props.lang),
                                                    disabled: this.state.socialSecurityNumberExist && !this.state.ssnExistInThisGroup ? true : false,
                                                    validate: this.props.twoFactorAuthenticationEnabled ? [required('When two factor authentication is enabled, both email and phone number are required!'.translate(this.props.lang))] : []
                                                },
                                            ]
                                        } : { type: 'empty' },

                                    // ---

                                    !this.state.socialSecurityNumberExist && isChild /*|| (isChild && this.state.socialSecurityNumberExist)*/ ?
                                        {
                                            type: 'col',
                                            width: {
                                                lg: 12,
                                                sm: 12,
                                                xs: 12
                                            },
                                            children: [
                                                {
                                                    type: 'h6',
                                                    style: { marginBottom: 20 },
                                                    text: 'Register second parent or legal guardian'.translate(this.props.lang),
                                                },
                                            ]
                                        } : { type: 'empty' },
                                    !this.state.socialSecurityNumberExist && isChild /*|| (isChild && this.state.socialSecurityNumberExist)*/ ?
                                        {
                                            type: 'col',
                                            width: {
                                                lg: 8,
                                                sm: 12,
                                                xs: 12
                                            },
                                            children: [
                                                {
                                                    type: 'socialSecurityNumber',
                                                    name: 'socialSecurityNumberParent2',
                                                    hideFindChildren: true,
                                                    searchByNameAndEmailFormBuilder: this.state.socialSecurityNumberExist && !this.state.ssnExistInThisGroup ? false : true,
                                                    disableNewPatientBtn: true,
                                                    label: this.props.country === 'uk' ? "Date of birth".translate(this.props.lang) : 'Social security number'.translate(this.props.lang),
                                                    disabled: this.state.socialSecurityNumberExist && !this.state.ssnExistInThisGroup ? true : false,
                                                    validate: !this.state.parent2Required ? [] : [required('Social security number is required!'.translate(this.props.lang)), checkSocialSecurityNumber('Social security number is not valid!'.translate(this.props.lang), this.props.country)]
                                                },
                                            ]
                                        } : { type: 'empty' },
                                    !this.state.socialSecurityNumberExist && isChild/*|| (isChild && this.state.socialSecurityNumberExist)*/ && this.props.showDateOfBirth ?
                                        {
                                            type: 'col',
                                            width: {
                                                lg: 4,
                                                sm: 12,
                                                xs: 12
                                            },
                                            children: [
                                                {
                                                    type: 'date-of-birth',
                                                    dateFormat: this.props.dateFormat,
                                                    name: 'dateOfBirthParent2.date',
                                                    disabled: this.state.disabledDateOfBirthParent2,
                                                    label: 'Date of birth'.translate(this.props.lang),
                                                    validate: !this.state.parent2Required ? [] : [required('Date of birth is required!'.translate(this.props.lang)), dateValidate('Invalid date!'.translate(this.props.lang))]

                                                },
                                            ]
                                        } : { type: 'empty' },
                                    !this.state.socialSecurityNumberExist && isChild /*|| (isChild && this.state.socialSecurityNumberExist)*/ ?
                                        {
                                            type: 'col',
                                            width: {
                                                lg: 4,
                                                sm: 12,
                                                xs: 12
                                            },
                                            children: [
                                                {
                                                    type: 'select',
                                                    name: 'sexParent2',
                                                    label: 'Sex'.translate(this.props.lang),
                                                    disabled: this.state.socialSecurityNumberExist && !this.state.ssnExistInThisGroup ? true : false,
                                                    values: [{ value: 0, name: 'Select'.translate(this.props.lang) }, { value: 'male', name: 'Male'.translate(this.props.lang) }, { value: 'female', name: 'Female'.translate(this.props.lang) }],
                                                },
                                            ]
                                        } : { type: 'empty' },
                                    !this.state.socialSecurityNumberExist && isChild /*|| (isChild && this.state.socialSecurityNumberExist)*/ ?
                                        {
                                            type: 'col',
                                            width: {
                                                lg: this.props.showDateOfBirth ? this.props.secondaryLanguageDisabled ? 6 : 4 : this.props.secondaryLanguageDisabled ? 12 : 8,
                                                sm: 12,
                                                xs: 12
                                            },
                                            children: [
                                                {
                                                    type: 'text',
                                                    name: 'nameParent2',
                                                    label: 'First and last name'.translate(this.props.lang),
                                                    validate: !this.state.parent2Required ? [] : [required('Name is required!'.translate(this.props.lang))],
                                                    disabled: this.state.socialSecurityNumberExist && !this.state.ssnExistInThisGroup ? true : false,

                                                },
                                            ]
                                        } : { type: 'empty' },
                                    !this.state.socialSecurityNumberExist && isChild /*|| (isChild && this.state.socialSecurityNumberExist)*/ && !this.props.secondaryLanguageDisabled ?
                                        {
                                            type: 'col',
                                            width: {
                                                lg: 4,
                                                sm: 12,
                                                xs: 12
                                            },
                                            children: [
                                                {
                                                    type: 'select',
                                                    name: 'language2',
                                                    label: 'Language'.translate(this.props.lang),
                                                    disabled: this.state.socialSecurityNumberExist && !this.state.ssnExistInThisGroup ? true : false,
                                                    values: this.state.languages.map(item => { return { name: item.name?.translate(this.props.lang), value: item._id } })
                                                },
                                            ]
                                        } : { type: 'empty' },

                                    !this.state.socialSecurityNumberExist && isChild /*|| (isChild && this.state.socialSecurityNumberExist)*/ ?
                                        {
                                            type: 'col',
                                            width: {
                                                lg: 6,
                                                sm: 12,
                                                xs: 12
                                            },
                                            children: [
                                                {
                                                    type: 'text',
                                                    name: 'email2',
                                                    label: 'Email address'.translate(this.props.lang),
                                                    disabled: this.state.socialSecurityNumberExist && !this.state.ssnExistInThisGroup ? true : false,
                                                    validate: !this.state.parent2Required ? [] : this.props.twoFactorAuthenticationEnabled ? [] : []
                                                },
                                            ]
                                        } : { type: 'empty' },
                                    !this.state.socialSecurityNumberExist && isChild /*|| (isChild && this.state.socialSecurityNumberExist)*/ ?
                                        {
                                            type: 'col',
                                            width: {
                                                lg: 6,
                                                sm: 12,
                                                xs: 12
                                            },
                                            children: [
                                                {
                                                    type: 'phone',

                                                    name: 'phone2',
                                                    label: 'Phone'.translate(this.props.lang),
                                                    disabled: this.state.socialSecurityNumberExist && !this.state.ssnExistInThisGroup ? true : false,
                                                    validate: !this.state.parent2Required ? [] : this.props.twoFactorAuthenticationEnabled ? [] : []
                                                },
                                            ]
                                        } : { type: 'empty' },
                                    // ---
                                    !this.state.socialSecurityNumberExist && this.state.clinicGroupSettings?.twoFactorAuthentication ?
                                        {
                                            type: 'col',
                                            width: {
                                                lg: 12,
                                                sm: 12,
                                                xs: 12
                                            },
                                            children: [
                                                {
                                                    type: 'checkbox',
                                                    name: 'twoFactorAuthenticationEnabled',
                                                    disabled: this.props.country === 'uk' ? true : false,
                                                    label: 'Enable login with two factor authentication'.translate(this.props.lang),
                                                    labelInfo: 'Login link is sent to the users phone number and email. The link is valid for 7 days.'.translate(this.props.lang)
                                                }
                                            ]

                                        }
                                        : { type: 'empty' },
                                    !this.state.ssnExistInThisGroup ?
                                        {
                                            type: 'col',
                                            width: {
                                                lg: 12,
                                                sm: 12,
                                                xs: 12
                                            },
                                            children: [
                                                {
                                                    type: 'checkbox',
                                                    validate: [required('Consent is required!'.translate(this.props.lang))],
                                                    name: 'consentAccepted',
                                                    label: 'Consent has been obtained'.translate(this.props.lang),

                                                },
                                            ]
                                        } : { type: 'empty' },

                                ]
                            }
                        ]}
                    ></FormBuilder>
                    :
                    null
                }
                <Container fluid>
                    {!this.state.patient ?
                        <Row className="search-patient-area">
                            <Col lg="12">
                                {
                                    this.state.showFirstSegment ?
                                        <div className="panel">
                                            <div className="panel-header">
                                                <h5 className="component-header">

                                                    <div style={this.state.searchByNameAndEmail ? { marginBottom: 50 } : {}} className="search-wrap">
                                                        <Label>{'Search patient'.translate(this.props.lang)}</Label>
                                                        <SocialSecurityNumber
                                                            hideFindChildren={true}
                                                            hideDateOfBirth={true}
                                                            inThisGroup={true}
                                                            disableNewPatientBtn={true}
                                                            selectedGroup={this.props.selectedGroup}
                                                            selectedClinic={this.props.selectedClinic}
                                                            lang={this.props.lang}
                                                            country={this.props.country}
                                                            searchByNameAndEmail={this.state.searchByNameAndEmail || true}
                                                            searchByNameAndEmailShow={(show) => {
                                                                this.setState({
                                                                    searchByNameAndEmail: show
                                                                })
                                                            }}
                                                            // label={this.props.country === 'uk' ? "Date of birth".translate(this.props.lang) : "Social security number".translate(this.props.lang)}
                                                            placeholder={this.props.country === 'denmark' ? 'DDMMYYNNNNN' : this.props.lang === 'en' ? 'YYYYMMDDNNNN' : 'ÅÅÅÅMMDDNNNN'}
                                                            value={this.state.search}
                                                            onKeyUp={(e) => {
                                                                if (e.keyCode === 13) {
                                                                    e.preventDefault(); // Ensure it is only this code that runs
                                                                    this.searchPatient(this.state.search, false, true)
                                                                }
                                                            }}
                                                            onChange={(val, callback) => {
                                                                this.setState({ search: val }, () => {
                                                                    if (callback) {
                                                                        callback()
                                                                    }
                                                                })
                                                            }}
                                                            newUserCallback={() => {
                                                                if (!(!this.state.search || !isSocialSecurityNumberValid(this.state.search, this.props.country))) {
                                                                    this.searchPatient(this.state.search, false, true)
                                                                }
                                                            }}
                                                            selectUserCallback={() => {
                                                                if (!(!this.state.search || !isSocialSecurityNumberValid(this.state.search, this.props.country))) {
                                                                    this.searchPatient(this.state.search, false, true)
                                                                }
                                                            }}
                                                        />
                                                        {/* {
                                                            this.props.country === 'uk' ?
                                                                <div className={this.state.searchByNameAndEmail ? 'little-dropdown-icon-down little-dropdown-icon-down-hide' : 'little-dropdown-icon-down arrow-icon-info'} onClick={() => {
                                                                    this.setState({
                                                                        searchByNameAndEmail: !this.state.searchByNameAndEmail
                                                                    })
                                                                }}>
                                                                    <div>{'Show additional search options'.translate(this.props.lang)}</div>
                                                                </div>
                                                                :
                                                                null
                                                        } */}
                                                        <div className="info">
                                                            <Isvg src={infoIcon} />
                                                            <div>{'Searches are logged'.translate(this.props.lang)}</div>
                                                        </div>
                                                        {
                                                            this.props.country !== 'uk' ?
                                                                <Button disabled={!this.state.search || !isSocialSecurityNumberValid(this.state.search, this.props.country)} onClick={() => {
                                                                    this.searchPatient(this.state.search, false, true)
                                                                }} color="primary">{'Search'.translate(this.props.lang)}</Button>
                                                                :
                                                                null
                                                        }

                                                        {
                                                            this.state.webDocInfo && this.state.webDocInfo.webdocConnected ?
                                                                <div className='webdoc-user-token-wrap'>
                                                                    <div
                                                                        className={this.state.webDocInfo.tokenExist ? 'web-doc-connected' : 'web-doc-not-connected'}
                                                                        onClick={(e) => {
                                                                            if (!this.state.webDocInfo.tokenExist) {
                                                                                this.setState({ makeTokenModal: true })
                                                                            }
                                                                        }}
                                                                    >
                                                                        <img src={webdocLogo} />
                                                                        <p>
                                                                            {
                                                                                this.state.webDocInfo.tokenExist ?
                                                                                    'Connected'.translate(this.props.lang)
                                                                                    :
                                                                                    'Connect'.translate(this.props.lang)
                                                                            }
                                                                        </p>

                                                                        <div className='webdoc-user-tooltip'>
                                                                            {
                                                                                this.state.webDocInfo.tokenExist ?
                                                                                    'Connected to WebDoc'.translate(this.props.lang)
                                                                                    :
                                                                                    'Not connected to WebDoc'.translate(this.props.lang)
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                :
                                                                null
                                                        }
                                                    </div>




                                                    <button className="btn add-button" onClick={() => {
                                                        this.setState({
                                                            // form: {
                                                            //     language: this.state.languages && this.state.languages[0] && this.state.languages[0]._id
                                                            // }
                                                            registerNewPatientModal: {
                                                                language: this.state.languages && this.state.languages[0] && this.state.languages[0]._id,
                                                                twoFactorAuthenticationEnabled: this.props.country === 'uk' ? true : false
                                                            }
                                                        })

                                                    }}><Isvg src={addIcon} /> {'Add new patient'.translate(this.props.lang)}</button>

                                                </h5>
                                            </div>


                                            <div className="search-result">
                                                <h6>{'Search result'.translate(this.props.lang)}</h6>
                                                <div className="table-h-scroll">
                                                    <table>
                                                        <thead>
                                                            <tr>
                                                                <th>{'Name'.translate(this.props.lang)}</th>
                                                                <th>{'Social security number'.translate(this.props.lang)}</th>
                                                                <th></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                this.state.patientSearched ?
                                                                    <tr>
                                                                        <td>{this.state.patientSearched.userData && this.state.patientSearched.userData.name}</td>
                                                                        <td>{this.state.patientSearched.socialSecurityNumber}</td>
                                                                        <td><Button color="primary" onClick={() => this.setState({ patient: this.state.patientSearched, tabActive: 1 }, () => {
                                                                            this.props.registerClinicGroupLogs({
                                                                                type: 'patientProfile',
                                                                                patient: this.state.patient.socialSecurityNumber
                                                                            })
                                                                            this.openChat(this.state.patient)

                                                                        })}>{'Open patient profile'.translate(this.props.lang)}</Button> </td>
                                                                    </tr>
                                                                    :
                                                                    null
                                                            }


                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>

                                        </div>
                                        :
                                        null
                                }

                            </Col>
                            <Col lg="12">
                                <div className="panel">
                                    <div className="search-result previous-search-results">
                                        <h6>{'Last opened patient profiles / Search history'.translate(this.props.lang)}</h6>
                                        <div>
                                            <div className="table-h-scroll">
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th>{'Name'.translate(this.props.lang)}</th>
                                                            <th>{'Social security number'.translate(this.props.lang)}</th>
                                                            <th>{'Last opened'.translate(this.props.lang)}</th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            this.state.lastSerach && this.state.lastSerach.map((item, idx) => {
                                                                return (
                                                                    <tr>
                                                                        <td>{item.name}</td>
                                                                        <td>{item.socialSecurityNumber}</td>
                                                                        <td>{this.props.getStringDateTs(item.timestamp, this.props.dateFormat)/*moment.unix(item.timestamp).format(this.props.dateFormat)*/}</td>
                                                                        <td><Button color="primary" onClick={() => {
                                                                            this.searchPatient(item.socialSecurityNumber, 1, true)

                                                                        }}>{'Open'.translate(this.props.lang)}</Button> </td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }


                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>

                        </Row>
                        :
                        <Row className="patient-profile-area">

                            <Col lg="12" >
                                <div className="patient-profile-header">
                                    <div className='patient-profile-enterprise no-border no-dropdown'>
                                        <div>
                                            <div className={this.state.tabActive == 1 ? 'item-with-dropdown active-item-with-dropdown' : 'item-with-dropdown'}>
                                                <button onClick={() => {
                                                    this.checkSavedTestResults({
                                                        tabActive: 1
                                                    })
                                                    // this.checkSavedMedicine({
                                                    //     tabActive: 1
                                                    // })
                                                }}>{'Patient info'.translate(this.props.lang)}</button>
                                                <div onClick={() => {
                                                    let patientInfo = null;
                                                    if (!this.state.patientInfo) {
                                                        patientInfo = {
                                                            socialSecurityNumber: this.state.patient && this.state.patient.socialSecurityNumber,
                                                            _id: this.state.patient && this.state.patient._id,
                                                            address: `${this.state.patient && this.state.patient.street ? this.state.patient.street : ''} ${this.state.patient && this.state.patient.zip ? this.state.patient.zip : ''} ${this.state.patient && this.state.patient.city ? this.state.patient.city : ''}`,
                                                            email: this.state.patient && this.state.patient.email ? this.state.patient.email : '',
                                                            phone: this.state.patient && this.state.patient.phone ? this.state.patient.phone : '',
                                                            street: this.state.patient && this.state.patient.street ? this.state.patient.street : '',
                                                            zip: this.state.patient && this.state.patient.zip ? this.state.patient.zip : '',
                                                            city: this.state.patient && this.state.patient.city ? this.state.patient.city : '',
                                                            userData: {
                                                                name: this.state.patient && this.state.patient.userData && this.state.patient.userData.name ? this.state.patient.userData.name : '',
                                                                title: this.state.patient && this.state.patient.userData && this.state.patient.userData.title ? this.state.patient.userData.title : '',
                                                                sex: this.state.patient && this.state.patient.userData && this.state.patient.userData.sex ? this.state.patient.userData.sex : null,
                                                                otherInformations: this.state.patient && this.state.patient.userData && this.state.patient.userData.otherInformations ? this.state.patient.userData.otherInformations : '',
                                                                relatedParties: this.state.patient && this.state.patient.userData && this.state.patient.userData && this.state.patient.userData.relatedParties ?
                                                                    this.state.patient.userData.relatedParties
                                                                    :
                                                                    { name: '', relations: '', phone: '', phone2: '' }
                                                            }
                                                        };
                                                    }


                                                    this.setState({
                                                        patientInfo: patientInfo,

                                                    })
                                                }}>
                                                    <div className='button-elements-tooltip-patient'>
                                                        <Isvg src={ArrowDown} />
                                                        <div className='tooltip-text'>{'Patient profile'.translate(this.props.lang)}</div>
                                                    </div>
                                                    {/* <Isvg src={ArrowDown} /> */}
                                                </div>


                                            </div>
                                        </div>
                                        <div>
                                            <button className={this.state.tabActive == 2 ? 'active-button' : ''} onClick={() => {
                                                this.checkSavedTestResults({ tabActive: 2, patientInfo: null })
                                                // this.checkSavedMedicine({
                                                //     tabActive: 2
                                                // })
                                            }}>{'Daybook'.translate(this.props.lang)}</button>


                                        </div>
                                        <div>
                                            <button className={this.state.tabActive == 3 ? 'active-button' : ''} onClick={() => {
                                                // this.checkSavedMedicine({
                                                //     tabActive: 3
                                                // })
                                                if (this.state.tabActive == 4) {
                                                    this.checkSavedTestResults({ tabActive: 3 })
                                                    this.searchPatient1(this.state.patient.socialSecurityNumber)

                                                }
                                                else if (this.state.patient && this.state.patient.socialSecurityNumber) {
                                                    this.searchPatient(this.state.patient.socialSecurityNumber, 3)
                                                }
                                                // this.setState({ tabActive: 3, patientInfo: null })
                                            }}>{'Test results'.translate(this.props.lang)}</button>
                                        </div>
                                        <div>
                                            <button className={this.state.tabActive == 4 ? 'active-button' : ''} onClick={() => {
                                                if (this.state.tabActive != 4)
                                                    this.checkSavedTestResults({ tabActive: 4, patientInfo: null })

                                            }}>{'List of medicines'.translate(this.props.lang)}</button>
                                        </div>
                                        {
                                            this.props.hasSemble ? <div>
                                                <button
                                                    className={this.state.tabActive == 5 ? 'active-button' : ''}
                                                    onClick={() => {
                                                        if (this.state.tabActive !== 5) this.setState({
                                                            tabActive: 5,
                                                            patientInfo: null
                                                        })
                                                    }}
                                                >
                                                    {'Patient Documents'.translate(this.props.lang)}
                                                </button>
                                            </div> : null
                                        }
                                    </div>


                                    <Button color="danger" onClick={() => this.setState({ patient: null, tabDaybook: null, chatId: null, chatConversation: null, startChat: null, patientInfo: null, tabActive: 1 }, () => this.updateParams('page', 0, true))}>{'Close patient'.translate(this.props.lang)}</Button>
                                    {this.state.patientInfo ?
                                        <div className="patient-info-form">
                                            <div>
                                                <p><strong>{'Name'.translate(this.props.lang)}:</strong> <span>{this.state.patient && this.state.patient.userData && this.state.patient.userData.name}</span></p>
                                                <p><strong>{'Social security number'.translate(this.props.lang)}:</strong> <span>{this.state.patient && this.state.patient.socialSecurityNumber}</span></p>
                                                {/* <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                                                        <FormGroup className="form-group-patient-info">
                                                            <Label>{'Sex'.translate(this.props.lang)}:</Label>
                                                            <Input type="select" value={this.state.patientInfo.userData.sex} onChange={(e) => {
                                                                if (e.target.value == 'male' || e.target.value == 'female') {
                                                                    let patientInfo = this.state.patientInfo;
                                                                    patientInfo.userData.sex = e.target.value;
                                                                    this.setState({ patientInfo })
                                                                } else {
                                                                    let patientInfo = this.state.patientInfo;
                                                                    patientInfo.userData.sex = null;
                                                                    this.setState({ patientInfo })
                                                                }

                                                            }}>
                                                                <option value={0}>{'Select'.translate(this.props.lang)}</option>
                                                                <option value={'male'}>{'Male'.translate(this.props.lang)}</option>
                                                                <option value={'female'}>{'Female'.translate(this.props.lang)}</option>
                                                            </Input>
                                                        </FormGroup>
                                                    </div> */}
                                                <p><strong>{'Sex'.translate(this.props.lang)}:</strong>
                                                    <span style={{ width: '100%' }}>
                                                        <FormGroup className="form-group-patient-info">
                                                            <Input type="select" value={this.state.patientInfo.userData.sex} onChange={(e) => {
                                                                if (e.target.value == 'male' || e.target.value == 'female') {
                                                                    let patientInfo = this.state.patientInfo;
                                                                    patientInfo.userData.sex = e.target.value;
                                                                    this.setState({ patientInfo })
                                                                } else {
                                                                    let patientInfo = this.state.patientInfo;
                                                                    patientInfo.userData.sex = null;
                                                                    this.setState({ patientInfo })
                                                                }

                                                            }}>
                                                                <option value={0}>{'Select'.translate(this.props.lang)}</option>
                                                                <option value={'male'}>{'Male'.translate(this.props.lang)}</option>
                                                                <option value={'female'}>{'Female'.translate(this.props.lang)}</option>
                                                            </Input>
                                                        </FormGroup>
                                                    </span>
                                                </p>
                                            </div>

                                            <h6>{'Contact information'.translate(this.props.lang)}:</h6>
                                            <div style={{ wordBreak: 'break-all' }}>
                                                <p><strong>{'Address'.translate(this.props.lang)}:</strong>
                                                    <span style={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
                                                        <span>{this.state.patientInfo.address}</span>
                                                        <div className="edit-addres-wrap" onClick={() => this.setState({ editAddress: this.state.patientInfo })}><Isvg src={edit} /></div>
                                                    </span>
                                                </p>
                                                <p><strong>{'Phone'.translate(this.props.lang)}:</strong>
                                                    <span style={{ width: '100%' }}>
                                                        <Phone countryTwoLetter={this.props.countryTwoLetter} country={this.props.country} value={this.state.patientInfo.phone} onChange={(e) => {
                                                            let patientInfo = this.state.patientInfo;
                                                            patientInfo.phone = e;
                                                            this.setState({ patientInfo })
                                                        }} />
                                                    </span>
                                                </p>
                                                <p><strong>{'E-mail'.translate(this.props.lang)}:</strong>
                                                    <span style={{ width: '100%' }}>
                                                        <FormGroup className="form-group-patient-info2">
                                                            <Input type='text' value={this.state.patientInfo.email} onChange={(e) => {
                                                                let patientInfo = this.state.patientInfo;
                                                                patientInfo.email = e.target.value;
                                                                this.setState({ patientInfo })
                                                            }} />
                                                        </FormGroup>
                                                    </span>
                                                </p>

                                            </div>
                                            <h6>{'Related parties'.translate(this.props.lang)}:</h6>
                                            <div>
                                                <p><strong>{'Name'.translate(this.props.lang)}:</strong> <span style={{ width: '100%' }}><Input type="text" value={this.state.patientInfo.userData.relatedParties.name} onChange={(e) => {
                                                    let patientInfo = this.state.patientInfo;
                                                    patientInfo.userData.relatedParties.name = e.target.value;
                                                    this.setState({ patientInfo })
                                                }} /></span></p>
                                                <p><strong>{'Relation'.translate(this.props.lang)}:</strong> <span style={{ width: '100%' }}><Input type="text" value={this.state.patientInfo.userData.relatedParties.relations} onChange={(e) => {
                                                    let patientInfo = this.state.patientInfo;
                                                    patientInfo.userData.relatedParties.relations = e.target.value;
                                                    this.setState({ patientInfo })
                                                }} /></span></p>
                                                <p><strong>{'Phone 1'.translate(this.props.lang)}:</strong>
                                                    <span style={{ width: '100%' }}>
                                                        <Phone countryTwoLetter={this.props.countryTwoLetter} country={this.props.country} value={this.state.patientInfo.userData.relatedParties.phone} onChange={(e) => {
                                                            let patientInfo = this.state.patientInfo;
                                                            patientInfo.userData.relatedParties.phone = e;
                                                            this.setState({ patientInfo })
                                                        }} />
                                                        {/* <Input type="text" value={this.state.patientInfo.userData.relatedParties.phone} onChange={(e) => {
                                                                let patientInfo = this.state.patientInfo;
                                                                patientInfo.userData.relatedParties.phone = e.target.value;
                                                                this.setState({ patientInfo })
                                                            }} /> */}
                                                    </span>
                                                </p>
                                                <p><strong>{'Phone 2'.translate(this.props.lang)}:</strong>
                                                    <span style={{ width: '100%' }}>
                                                        <Phone countryTwoLetter={this.props.countryTwoLetter} country={this.props.country} value={this.state.patientInfo.userData.relatedParties.phone2} onChange={(e) => {
                                                            let patientInfo = this.state.patientInfo;
                                                            patientInfo.userData.relatedParties.phone2 = e;
                                                            this.setState({ patientInfo })
                                                        }} />
                                                        {/* <Input type="text" value={this.state.patientInfo.userData.relatedParties.phone2} onChange={(e) => {
                                                                let patientInfo = this.state.patientInfo;
                                                                patientInfo.userData.relatedParties.phone2 = e.target.value;
                                                                this.setState({ patientInfo })
                                                            }} /> */}
                                                    </span>
                                                </p>

                                            </div>

                                            <Label>{'Other information'.translate(this.props.lang)}:</Label>
                                            <Input type="textarea" style={{ height: 100 }} value={this.state.patientInfo.userData.otherInformations} onChange={(e) => {
                                                let patientInfo = this.state.patientInfo;
                                                patientInfo.userData.otherInformations = e.target.value;
                                                this.setState({ patientInfo })
                                            }} />

                                            <div className="button-wrap">
                                                <Button color="primary" onClick={() => {
                                                    this.updatePatientProfile(this.state.patientInfo)
                                                    // this.savePatientInfo({
                                                    //     relatedParties: this.state.relatedParties,
                                                    //     otherInformations: this.state.otherInformations
                                                    // })
                                                }}>{'Save'.translate(this.props.lang)}</Button>
                                            </div>

                                        </div>
                                        :
                                        null

                                    }
                                </div>
                            </Col>
                            {(this.state.patient?.twoFactorAuthentication?.[this.props.selectedGroup]?.createAccount && !this.state.patient?.twoFactorAuthentication?.[this.props.selectedGroup]?.infoIsVerified) ? <Col lg='12'>
                                <Alert color='danger' className='patient-not-verified-alert'>
                                    <Isvg src={TriangleIcon} />
                                    <p>{(this.state.patient?.twoFactorAuthentication?.[this.props.selectedGroup]?.phoneIsVerified && this.state.patient?.twoFactorAuthentication?.[this.props.selectedGroup]?.emailIsVerified && this.state.patient?.twoFactorAuthentication?.[this.props.selectedGroup]?.passwordIsSet) ? 'This account needs to be verified, by going to Registration Requests page from Patient cases tab'.translate(this.props.lang) : 'This account is not yet verified!'.translate(this.props.lang)}</p>
                                </Alert>
                            </Col> : this.state.patient?.twoFactorAuthentication && Object.keys(this.state.patient?.twoFactorAuthentication)?.find?.(key => this.state.patient?.twoFactorAuthentication?.[key]?.createAccount) ? <Col lg='12'>
                                <Alert color='info' className='patient-not-verified-alert patient-not-verified-alert-warning'>
                                    <Isvg src={TriangleIcon} />
                                    <p>{'This account was created using two factor authentication.'.translate(this.props.lang)}</p>
                                </Alert>
                            </Col> : null}
                            {
                                this.state.tabActive == 1 ?
                                    <>
                                        <Col lg="8">

                                            <div className="panel">
                                                <Row>
                                                    <Col lg="8">
                                                        <div className="patient-main-info">
                                                            <div className="patient-main-info-container">
                                                                <h6>{'Patient'.translate(this.props.lang)}</h6>
                                                                <div className="patient-main-right">
                                                                    {this.state.clinicGroupSettings?.twoFactorAuthentication ? <button className="patient-button-2" onClick={() => {
                                                                        this.setState({
                                                                            twoFactorAuthenticationModal: true,
                                                                            twoFactorAuthenticationPhoneNumber: this.state.patient?.phone?.trim?.()?.replace('+', '')?.replace(/ /g, '') || '',
                                                                            usersPhoneNumber: this.state.patient?.phone?.trim?.()?.replace('+', '')?.replace(/ /g, '') || ''
                                                                        })
                                                                    }}>
                                                                        <div className='button-elements-tooltip'>
                                                                            <Isvg src={lockSvg} width={24} height={24} />
                                                                            <div className='tooltip-text'>{'Two factor authentication'.translate(this.props.lang)}</div>
                                                                        </div>

                                                                    </button> : null}
                                                                    <button className="patient-button-2" onClick={() => {
                                                                        this.setState({
                                                                            linkedAccoutns: true
                                                                        })
                                                                    }}>
                                                                        <div className='button-elements-tooltip'>
                                                                            <Isvg src={linkSvg} />
                                                                            <div className='tooltip-text'>{'Linked accounts'.translate(this.props.lang)}</div>
                                                                        </div>

                                                                    </button>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <div>
                                                                    <p className="patient-name">{this.state.patient && this.state.patient.userLevel && this.state.patient.userData.name}	</p>
                                                                    <p className="patient-number">{this.state.patient && this.state.patient.socialSecurityNumber}	</p>
                                                                    <p className="patient-address">{this.state.patient && this.state.patient.userData && this.state.patient.userData.sex ? this.state.patient.userData.sex == 'male' ? 'Male'.translate(this.props.lang) : this.state.patient.userData.sex == 'Boy' ? 'Boy'.translate(this.props.lang) : this.state.patient.userData.sex == 'Girl' ? 'Girl'.translate(this.props.lang) : 'Female'.translate(this.props.lang) : ''}{this.state.patient && this.state.patient.userData && this.state.patient.userData.sex && this.state.patient.socialSecurityNumber ? ', ' : ''}{this.state.patient && this.state.patient.socialSecurityNumber ? (calculateAgeFromSSN(this.state.patient.socialSecurityNumber, this.props.country) + ' ' + (calculateAgeFromSSN(this.state.patient.socialSecurityNumber, this.props.country) == 1 ? 'year'.translate(this.props.lang) : 'years'.translate(this.props.lang))) : ''}</p>

                                                                </div>
                                                                <div className={this.state.patient && this.state.patient.patientInfo && this.state.patient.patientInfo[this.props.selectedGroup] && this.state.patient.patientInfo[this.props.selectedGroup].infection || this.state.patient && this.state.patient.patientInfo && this.state.patient.patientInfo[this.props.selectedGroup] && this.state.patient.patientInfo[this.props.selectedGroup].allergies ? "patient-notes-exists" : "patient-notes"}>
                                                                    <button onClick={() => {
                                                                        this.setState({
                                                                            formInfo: {
                                                                                infection: this.state.patient && this.state.patient.patientInfo && this.state.patient.patientInfo[this.props.selectedGroup] && this.state.patient.patientInfo[this.props.selectedGroup].infection ? this.state.patient.patientInfo[this.props.selectedGroup].infection : '',
                                                                                allergies: this.state.patient && this.state.patient.patientInfo && this.state.patient.patientInfo[this.props.selectedGroup] && this.state.patient.patientInfo[this.props.selectedGroup].allergies ? this.state.patient.patientInfo[this.props.selectedGroup].allergies : '',
                                                                                tsModified: this.state.patient && this.state.patient.patientInfo && this.state.patient.patientInfo[this.props.selectedGroup] && this.state.patient.patientInfo[this.props.selectedGroup].tsModified ? this.state.patient.patientInfo[this.props.selectedGroup].tsModified : null,
                                                                                modifiedByUsername: this.state.patient && this.state.patient.patientInfo && this.state.patient.patientInfo[this.props.selectedGroup] && this.state.patient.patientInfo[this.props.selectedGroup].modifiedByUsername ? this.state.patient.patientInfo[this.props.selectedGroup].modifiedByUsername : null,
                                                                            }
                                                                        })
                                                                    }}>
                                                                        <div className='button-elements-tooltip'>
                                                                            <Isvg src={infoIcon} />
                                                                            <div className='tooltip-text' style={{ minWidth: 180, bottom: 35 }}>{'Important information'.translate(this.props.lang)}</div>
                                                                        </div>
                                                                    </button>
                                                                </div>
                                                            </div>

                                                        </div>


                                                    </Col>
                                                    <Col lg="4" className="patient-payments-button-wrap">
                                                        <div className='start-video-button-wrap'>
                                                            <Button color="primary" onClick={() => this.props[0].history.push(`/my-data/personal-referrals/payments?entries=10&sortField=timestamp&sortType=-1&filter=${this.state.patient.socialSecurityNumber}`)}>
                                                                <Isvg src={paymentIcon} />
                                                                {'See payments'.translate(this.props.lang)}
                                                            </Button>
                                                            <div className='fake-svg-26' />
                                                        </div>

                                                        {this.state._videoButtonEnabled && (this.state.chatConversation || this.state.chatId) && this.state.doctorCanStartVideoCall ?
                                                            <div className='start-video-button-wrap'>
                                                                <Button onClick={this.startVideoCall}>
                                                                    <Isvg src={cameraIcon} />
                                                                    {'Start video chat'.translate(this.props.lang)}
                                                                </Button>
                                                                <div className='button-elements-tooltip'>
                                                                    <Isvg src={infoIcon} width={26} height={26} />
                                                                    <div className='tooltip-text'>{`Note that if patient hasn't yet signed in and completed their account, the call might not get through. You can make sure the patient is signed in by sending a chat message. You can also activate the notification "Video call started" to send a video-link to the patient.`.translate(this.props.lang)}</div>
                                                                </div>
                                                            </div> : null}



                                                    </Col>
                                                </Row>

                                                <div className="search-result previous-search-results">
                                                    {/* <h6>{'Case overview'.translate(this.props.lang)}</h6> */}
                                                    <div className="tabs-buttons-wrap" style={{ marginBottom: 20 }}>
                                                        <Button className={this.state.tabIndex == 1 ? "tab-button acitve-tab-button" : "tab-button"} onClick={() => this.setState({ tabIndex: 1 }, () => this.updateParams('page', 0))}>{"Case overview".translate(this.props.lang)}</Button>
                                                        <Button className={this.state.tabIndex == 2 ? "tab-button acitve-tab-button" : "tab-button"} onClick={() => this.setState({ tabIndex: 2, note: '' }, () => {
                                                            if (this.notesRef) {
                                                                this.notesRef.scrollTop = this.notesRef.scrollHeight;
                                                            }
                                                        })}>{"Notes".translate(this.props.lang)}</Button>
                                                        {
                                                            statementEnabled ?
                                                                <Button className={this.state.tabIndex == 3 ? "tab-button acitve-tab-button" : "tab-button"} onClick={() => this.setState({ tabIndex: 3 })}>{"Statements".translate(this.props.lang)}</Button>
                                                                :
                                                                null
                                                        }

                                                    </div>

                                                    {
                                                        this.state.tabIndex == 1 ?
                                                            <div>
                                                                <ListCheckBox
                                                                    label={'Groups:'.translate(this.props.lang)}
                                                                    value={this.state.values}
                                                                    buttonText1={'Mark all'.translate(this.props.lang)}
                                                                    buttonText2={'Unmark all'.translate(this.props.lang)}
                                                                    values={this.state.professions && this.state.professions.length ? this.state.professions.map(item => {
                                                                        return { name: this.props.lang == 'en' ? item.professionEn : item.professionSv, value: item._id }
                                                                    }) : null}

                                                                    onChange={(e) => {
                                                                        if (e && e.length) {
                                                                            let arr = [];
                                                                            arr = [...e]
                                                                            this.setState({
                                                                                values: arr
                                                                            }, () => this.get(), this.updateMultipleParams([{ name: 'page', value: '0' }, { name: 'entries', value: '10' }]))

                                                                        } else {
                                                                            this.setState({
                                                                                values: [],
                                                                                controlBoking: false,
                                                                                webBoking: false,
                                                                                dropInBoking: false,
                                                                                clinicBoking: false,
                                                                                allowed: false,
                                                                                scheduled: false,
                                                                                waitingForApproval: false,
                                                                                waitingForPayment: false,
                                                                                notBooked: false,
                                                                                disallowed: false,
                                                                                inProgress: false,
                                                                                waiting: false,
                                                                                controlScheduled: false,
                                                                                cancledByClinic: false,
                                                                                rejectedByPatient: false,
                                                                                closed: false
                                                                            }, () => this.get(), this.updateMultipleParams([{ name: 'page', value: '0' }, { name: 'entries', value: '10' }]))
                                                                        }
                                                                    }}
                                                                />
                                                                <div >
                                                                    {this.state.values && this.state.values.length ?
                                                                        <div>
                                                                            <Label>{'Way of schedulig:'.translate(this.props.lang)}</Label>
                                                                        </div>
                                                                        :
                                                                        null}
                                                                    <div className="profession-buttons">
                                                                        {this.state.values && this.state.values.length ?
                                                                            <div>
                                                                                <div >
                                                                                    <button className={this.state.controlBoking ? 'btn clicked' : 'btn'} onClick={() => {
                                                                                        this.setState({
                                                                                            controlBoking: !this.state.controlBoking,
                                                                                            webBoking: false,
                                                                                            dropInBoking: false,
                                                                                            clinicBoking: false,
                                                                                            allowed: false,
                                                                                            scheduled: false,
                                                                                            waitingForApproval: false,
                                                                                            waitingForPayment: false,
                                                                                            notBooked: false,
                                                                                            disallowed: false,
                                                                                            inProgress: false,
                                                                                            waiting: false,
                                                                                            controlScheduled: false,
                                                                                            cancledByClinic: false,
                                                                                            rejectedByPatient: false,
                                                                                            closed: false
                                                                                        }, () => this.get(), this.updateMultipleParams([{ name: 'page', value: '0' }, { name: 'entries', value: '10' }]))
                                                                                    }} >{'revisit'.translate(this.props.lang)}</button>
                                                                                </div>
                                                                            </div>
                                                                            :
                                                                            null}
                                                                        {this.state.values && this.state.values.length ?
                                                                            <div>
                                                                                <div style={{ marginLeft: 10 }}>
                                                                                    <button className={this.state.webBoking ? 'btn clicked' : 'btn'} onClick={() => {
                                                                                        this.setState({
                                                                                            webBoking: !this.state.webBoking,
                                                                                            controlBoking: false,
                                                                                            dropInBoking: false,
                                                                                            clinicBoking: false,
                                                                                            allowed: false,
                                                                                            scheduled: false,
                                                                                            waitingForApproval: false,
                                                                                            waitingForPayment: false,
                                                                                            notBooked: false,
                                                                                            disallowed: false,
                                                                                            inProgress: false,
                                                                                            waiting: false,
                                                                                            controlScheduled: false,
                                                                                            cancledByClinic: false,
                                                                                            rejectedByPatient: false,
                                                                                            closed: false
                                                                                        }, () => this.get(), this.updateMultipleParams([{ name: 'page', value: '0' }, { name: 'entries', value: '10' }]))
                                                                                    }} >{'Web boking'.translate(this.props.lang)}</button>
                                                                                </div>
                                                                            </div>
                                                                            :
                                                                            null}
                                                                        {this.state.values && this.state.values.length ?
                                                                            <div>
                                                                                <div style={{ marginLeft: 10 }}>
                                                                                    <button className={this.state.dropInBoking ? 'btn clicked' : 'btn'} onClick={() => {
                                                                                        this.setState({
                                                                                            dropInBoking: !this.state.dropInBoking,
                                                                                            controlBoking: false,
                                                                                            webBoking: false,
                                                                                            clinicBoking: false,
                                                                                            allowed: false,
                                                                                            scheduled: false,
                                                                                            waitingForApproval: false,
                                                                                            waitingForPayment: false,
                                                                                            notBooked: false,
                                                                                            disallowed: false,
                                                                                            inProgress: false,
                                                                                            waiting: false,
                                                                                            controlScheduled: false,
                                                                                            cancledByClinic: false,
                                                                                            rejectedByPatient: false,
                                                                                            closed: false
                                                                                        }, () => this.get(), this.updateMultipleParams([{ name: 'page', value: '0' }, { name: 'entries', value: '10' }]))
                                                                                    }} >{'Drop in'.translate(this.props.lang)}</button>
                                                                                </div>
                                                                            </div>
                                                                            :
                                                                            null}
                                                                        {this.state.values && this.state.values.length ?
                                                                            <div>
                                                                                <div style={{ marginLeft: 10 }}>
                                                                                    <button className={this.state.clinicBoking ? 'btn clicked' : 'btn'} onClick={() => {
                                                                                        this.setState({
                                                                                            clinicBoking: !this.state.clinicBoking,
                                                                                            controlBoking: false,
                                                                                            dropInBoking: false,
                                                                                            webBoking: false,
                                                                                            allowed: false,
                                                                                            scheduled: false,
                                                                                            waitingForApproval: false,
                                                                                            waitingForPayment: false,
                                                                                            notBooked: false,
                                                                                            disallowed: false,
                                                                                            inProgress: false,
                                                                                            waiting: false,
                                                                                            controlScheduled: false,
                                                                                            cancledByClinic: false,
                                                                                            rejectedByPatient: false,
                                                                                            closed: false
                                                                                        }, () => this.get(), this.updateMultipleParams([{ name: 'page', value: '0' }, { name: 'entries', value: '10' }]))
                                                                                    }} >{'Booking by clinic'.translate(this.props.lang)}</button>
                                                                                </div>
                                                                            </div>
                                                                            :
                                                                            null}

                                                                    </div>

                                                                </div>
                                                                <div >
                                                                    {this.state.controlBoking ?
                                                                        <div style={{ marginTop: 10 }}>
                                                                            <Label>{'Case status:'.translate(this.props.lang)}</Label>
                                                                        </div>
                                                                        :
                                                                        null}

                                                                    <div className="profession-buttons mr-0">
                                                                        {this.state.controlBoking ?
                                                                            <div>
                                                                                <div>
                                                                                    <button className={this.state.controlScheduled ? 'btn clicked' : 'btn'} onClick={() => {
                                                                                        this.setState({
                                                                                            controlScheduled: !this.state.controlScheduled,
                                                                                            cancledByClinic: false,
                                                                                            rejectedByPatient: false,
                                                                                            closed: false
                                                                                        }, () => this.get(), this.updateMultipleParams([{ name: 'page', value: '0' }, { name: 'entries', value: '10' }]))
                                                                                    }} >{'Revisit scheduled'.translate(this.props.lang)}</button>
                                                                                </div>
                                                                            </div>
                                                                            :
                                                                            null}
                                                                        {this.state.controlBoking ?
                                                                            <div>
                                                                                <div >
                                                                                    <button className={this.state.cancledByClinic ? 'btn clicked' : 'btn'} onClick={() => {
                                                                                        this.setState({
                                                                                            cancledByClinic: !this.state.cancledByClinic,
                                                                                            controlScheduled: false,
                                                                                            rejectedByPatient: false,
                                                                                            closed: false
                                                                                        }, () => this.get(), this.updateMultipleParams([{ name: 'page', value: '0' }, { name: 'entries', value: '10' }]))
                                                                                    }} >{'Cancled by clinic'.translate(this.props.lang)}</button>
                                                                                </div>
                                                                            </div>
                                                                            :
                                                                            null}
                                                                        {this.state.controlBoking ?
                                                                            <div>
                                                                                <div >
                                                                                    <button className={this.state.rejectedByPatient ? 'btn clicked' : 'btn'} onClick={() => {
                                                                                        this.setState({
                                                                                            rejectedByPatient: !this.state.rejectedByPatient,
                                                                                            controlScheduled: false,
                                                                                            cancledByClinic: false,
                                                                                            closed: false
                                                                                        }, () => this.get(), this.updateMultipleParams([{ name: 'page', value: '0' }, { name: 'entries', value: '10' }]))
                                                                                    }} >{'Rejected by patient'.translate(this.props.lang)}</button>
                                                                                </div>
                                                                            </div>
                                                                            :
                                                                            null}
                                                                        {this.state.controlBoking ?
                                                                            <div>
                                                                                <div >
                                                                                    <button className={this.state.closed ? 'btn clicked' : 'btn'} onClick={() => {
                                                                                        this.setState({
                                                                                            closed: !this.state.closed,
                                                                                            controlScheduled: false,
                                                                                            rejectedByPatient: false,
                                                                                            cancledByClinic: false
                                                                                        }, () => this.get(), this.updateMultipleParams([{ name: 'page', value: '0' }, { name: 'entries', value: '10' }]))
                                                                                    }} >{'Closed'.translate(this.props.lang)}</button>
                                                                                </div>
                                                                            </div>
                                                                            :
                                                                            null}
                                                                    </div>
                                                                </div>
                                                                <div >
                                                                    {this.state.webBoking ?
                                                                        <div style={{ marginTop: 10 }}>
                                                                            <Label>{'Case status:'.translate(this.props.lang)}</Label>
                                                                        </div>
                                                                        :
                                                                        null}

                                                                    <div className="profession-buttons mr-0">
                                                                        {this.state.webBoking ?
                                                                            <div>
                                                                                <div >
                                                                                    <button className={this.state.scheduled ? 'btn clicked' : 'btn'} onClick={() => {
                                                                                        this.setState({
                                                                                            scheduled: !this.state.scheduled,
                                                                                            notBooked: false,
                                                                                            rejectedByPatient: false,
                                                                                            closed: false
                                                                                        }, () => this.get(), this.updateMultipleParams([{ name: 'page', value: '0' }, { name: 'entries', value: '10' }]))
                                                                                    }} >{'Scheduled'.translate(this.props.lang)}</button>
                                                                                </div>
                                                                            </div>
                                                                            :
                                                                            null}
                                                                        {this.state.webBoking ?
                                                                            <div>
                                                                                <div >
                                                                                    <button className={this.state.notBooked ? 'btn clicked' : 'btn'} onClick={() => {
                                                                                        this.setState({
                                                                                            notBooked: !this.state.notBooked,
                                                                                            scheduled: false,
                                                                                            rejectedByPatient: false,
                                                                                            closed: false
                                                                                        }, () => this.get(), this.updateMultipleParams([{ name: 'page', value: '0' }, { name: 'entries', value: '10' }]))
                                                                                    }} >{'Not booked'.translate(this.props.lang)}</button>
                                                                                </div>
                                                                            </div>
                                                                            :
                                                                            null}
                                                                        {this.state.webBoking ?
                                                                            <div>
                                                                                <div >
                                                                                    <button className={this.state.rejectedByPatient ? 'btn clicked' : 'btn'} onClick={() => {
                                                                                        this.setState({
                                                                                            rejectedByPatient: !this.state.rejectedByPatient,
                                                                                            scheduled: false,
                                                                                            notBooked: false,
                                                                                            closed: false
                                                                                        }, () => this.get(), this.updateMultipleParams([{ name: 'page', value: '0' }, { name: 'entries', value: '10' }]))
                                                                                    }}>{'Rejected by patient'.translate(this.props.lang)}</button>
                                                                                </div>
                                                                            </div>
                                                                            :
                                                                            null}
                                                                        {this.state.webBoking ?
                                                                            <div>
                                                                                <div >
                                                                                    <button className={this.state.closed ? 'btn clicked' : 'btn'} onClick={() => {
                                                                                        this.setState({
                                                                                            closed: !this.state.closed,
                                                                                            scheduled: false,
                                                                                            rejectedByPatient: false,
                                                                                            notBooked: false
                                                                                        }, () => this.get(), this.updateMultipleParams([{ name: 'page', value: '0' }, { name: 'entries', value: '10' }]))
                                                                                    }} >{'Closed'.translate(this.props.lang)}</button>
                                                                                </div>
                                                                            </div>
                                                                            :
                                                                            null}
                                                                    </div>
                                                                </div>
                                                                <div >
                                                                    {this.state.dropInBoking ?
                                                                        <div style={{ marginTop: 10 }}>
                                                                            <Label>{'Case status:'.translate(this.props.lang)}</Label>
                                                                        </div>
                                                                        :
                                                                        null}

                                                                    <div className="profession-buttons mr-0">
                                                                        {this.state.dropInBoking ?
                                                                            <div>
                                                                                <div >
                                                                                    <button className={this.state.waiting ? 'btn clicked' : 'btn'} onClick={() => {
                                                                                        this.setState({
                                                                                            waiting: !this.state.waiting,
                                                                                            inProgress: false,
                                                                                            closed: false
                                                                                        }, () => this.get(), this.updateMultipleParams([{ name: 'page', value: '0' }, { name: 'entries', value: '10' }]))
                                                                                    }} >{'Waiting'.translate(this.props.lang)}</button>
                                                                                </div>
                                                                            </div>
                                                                            :
                                                                            null}
                                                                        {this.state.dropInBoking ?
                                                                            <div>
                                                                                <div >
                                                                                    <button className={this.state.inProgress ? 'btn clicked' : 'btn'} onClick={() => {
                                                                                        this.setState({
                                                                                            inProgress: !this.state.inProgress,
                                                                                            waiting: false,
                                                                                            closed: false
                                                                                        }, () => this.get(), this.updateMultipleParams([{ name: 'page', value: '0' }, { name: 'entries', value: '10' }]))
                                                                                    }} >{'In progress'.translate(this.props.lang)}</button>
                                                                                </div>
                                                                            </div>
                                                                            :
                                                                            null}

                                                                        {this.state.dropInBoking ?
                                                                            <div>
                                                                                <div >
                                                                                    <button className={this.state.closed ? 'btn clicked' : 'btn'} onClick={() => {
                                                                                        this.setState({
                                                                                            closed: !this.state.closed,
                                                                                            waiting: false,
                                                                                            inProgress: false
                                                                                        }, () => this.get(), this.updateMultipleParams([{ name: 'page', value: '0' }, { name: 'entries', value: '10' }]))
                                                                                    }} >{'Closed'.translate(this.props.lang)}</button>
                                                                                </div>
                                                                            </div>
                                                                            :
                                                                            null}
                                                                    </div>
                                                                </div>
                                                                <div >
                                                                    {this.state.clinicBoking ?
                                                                        <div style={{ marginTop: 10 }}>
                                                                            <Label>{'Case status:'.translate(this.props.lang)}</Label>
                                                                        </div>
                                                                        :
                                                                        null}

                                                                    <div className="profession-buttons mr-0">
                                                                        {this.state.clinicBoking ?
                                                                            <div>
                                                                                <div >
                                                                                    <button className={this.state.waitingForApproval ? 'btn clicked' : 'btn'} onClick={() => {
                                                                                        this.setState({
                                                                                            waitingForApproval: !this.state.waitingForApproval,
                                                                                            waitingForPayment: false,
                                                                                            allowed: false,
                                                                                            scheduled: false,
                                                                                            notBooked: false,
                                                                                            rejectedByPatient: false,
                                                                                            closed: false,
                                                                                            disallowed: false
                                                                                        }, () => this.get(), this.updateMultipleParams([{ name: 'page', value: '0' }, { name: 'entries', value: '10' }]))
                                                                                    }} >{'Waitign for approval'.translate(this.props.lang)}</button>
                                                                                </div>
                                                                            </div>
                                                                            :
                                                                            null}
                                                                        {this.state.clinicBoking ?
                                                                            <div>
                                                                                <div >
                                                                                    <button className={this.state.waitingForPayment ? 'btn clicked' : 'btn'} onClick={() => {
                                                                                        this.setState({
                                                                                            waitingForPayment: !this.state.waitingForPayment,
                                                                                            waitingForApproval: false,
                                                                                            allowed: false,
                                                                                            scheduled: false,
                                                                                            notBooked: false,
                                                                                            rejectedByPatient: false,
                                                                                            closed: false,
                                                                                            disallowed: false
                                                                                        }, () => this.get(), this.updateMultipleParams([{ name: 'page', value: '0' }, { name: 'entries', value: '10' }]))
                                                                                    }} >{'Waiting for payment'.translate(this.props.lang)}</button>
                                                                                </div>
                                                                            </div>
                                                                            :
                                                                            null}

                                                                        {this.state.clinicBoking ?
                                                                            <div>
                                                                                <div >
                                                                                    <button className={this.state.allowed ? 'btn clicked' : 'btn'} onClick={() => {
                                                                                        this.setState({
                                                                                            allowed: !this.state.allowed,
                                                                                            waitingForApproval: false,
                                                                                            waitingForPayment: false,
                                                                                            scheduled: false,
                                                                                            notBooked: false,
                                                                                            rejectedByPatient: false,
                                                                                            closed: false,
                                                                                            disallowed: false
                                                                                        }, () => this.get(), this.updateMultipleParams([{ name: 'page', value: '0' }, { name: 'entries', value: '10' }]))
                                                                                    }} >{'Allowed'.translate(this.props.lang)}</button>
                                                                                </div>
                                                                            </div>
                                                                            :
                                                                            null}
                                                                        {this.state.clinicBoking ?
                                                                            <div>
                                                                                <div >
                                                                                    <button className={this.state.scheduled ? 'btn clicked' : 'btn'} onClick={() => {
                                                                                        this.setState({
                                                                                            scheduled: !this.state.scheduled,
                                                                                            waitingForApproval: false,
                                                                                            waitingForPayment: false,
                                                                                            allowed: false,
                                                                                            notBooked: false,
                                                                                            rejectedByPatient: false,
                                                                                            closed: false,
                                                                                            disallowed: false
                                                                                        }, () => this.get(), this.updateMultipleParams([{ name: 'page', value: '0' }, { name: 'entries', value: '10' }]))
                                                                                    }} >{'Scheduled'.translate(this.props.lang)}</button>
                                                                                </div>
                                                                            </div>
                                                                            :
                                                                            null}
                                                                        {this.state.clinicBoking ?
                                                                            <div>
                                                                                <div >
                                                                                    <button className={this.state.notBooked ? 'btn clicked' : 'btn'} onClick={() => {
                                                                                        this.setState({
                                                                                            notBooked: !this.state.notBooked,
                                                                                            waitingForApproval: false,
                                                                                            waitingForPayment: false,
                                                                                            allowed: false,
                                                                                            scheduled: false,
                                                                                            rejectedByPatient: false,
                                                                                            closed: false,
                                                                                            disallowed: false
                                                                                        }, () => this.get(), this.updateMultipleParams([{ name: 'page', value: '0' }, { name: 'entries', value: '10' }]))
                                                                                    }} >{'Not booked'.translate(this.props.lang)}</button>
                                                                                </div>
                                                                            </div>
                                                                            :
                                                                            null}
                                                                        {this.state.clinicBoking ?
                                                                            <div>
                                                                                <div >
                                                                                    <button className={this.state.disallowed ? 'btn clicked' : 'btn'} onClick={() => {
                                                                                        this.setState({
                                                                                            disallowed: !this.state.disallowed,
                                                                                            notBooked: false,
                                                                                            waitingForApproval: false,
                                                                                            waitingForPayment: false,
                                                                                            allowed: false,
                                                                                            scheduled: false,
                                                                                            rejectedByPatient: false,
                                                                                            closed: false
                                                                                        }, () => this.get(), this.updateMultipleParams([{ name: 'page', value: '0' }, { name: 'entries', value: '10' }]))
                                                                                    }} >{'Disallowed'.translate(this.props.lang)}</button>
                                                                                </div>
                                                                            </div>
                                                                            :
                                                                            null}

                                                                        {this.state.clinicBoking ?
                                                                            <div>
                                                                                <div >
                                                                                    <button className={this.state.rejectedByPatient ? 'btn clicked' : 'btn'} onClick={() => {
                                                                                        this.setState({
                                                                                            rejectedByPatient: !this.state.rejectedByPatient,
                                                                                            waitingForApproval: false,
                                                                                            waitingForPayment: false,
                                                                                            allowed: false,
                                                                                            scheduled: false,
                                                                                            notBooked: false,
                                                                                            closed: false,
                                                                                            disallowed: false
                                                                                        }, () => this.get(), this.updateMultipleParams([{ name: 'page', value: '0' }, { name: 'entries', value: '10' }]))
                                                                                    }} >{'Rejected by patient'.translate(this.props.lang)}</button>
                                                                                </div>
                                                                            </div>
                                                                            :
                                                                            null}
                                                                        {this.state.clinicBoking ?
                                                                            <div>
                                                                                <div >
                                                                                    <button className={this.state.closed ? 'btn clicked' : 'btn'} onClick={() => {
                                                                                        this.setState({
                                                                                            closed: !this.state.closed,
                                                                                            waitingForApproval: false,
                                                                                            waitingForPayment: false,
                                                                                            allowed: false,
                                                                                            scheduled: false,
                                                                                            notBooked: false,
                                                                                            rejectedByPatient: false,
                                                                                            disallowed: false
                                                                                        }, () => this.get(), this.updateMultipleParams([{ name: 'page', value: '0' }, { name: 'entries', value: '10' }]))
                                                                                    }} >{'Closed'.translate(this.props.lang)}</button>
                                                                                </div>
                                                                            </div>
                                                                            :
                                                                            null}
                                                                    </div>
                                                                </div>
                                                                <div style={{ marginBottom: 20 }}>
                                                                </div>
                                                                <ListBuilder
                                                                    lang={this.props.lang}
                                                                    loading={this.state.loadingCases}
                                                                    total={this.state.totalCases}
                                                                    hideFilters={true}
                                                                    hideEntries={true}
                                                                    showNumeration={false}
                                                                    fields={[
                                                                        { type: 'text', name: 'questionaryName', label: 'Reason'.translate(this.props.lang), allowSort: true },
                                                                        { type: 'text', name: 'version', label: 'Referral ID'.translate(this.props.lang), allowSort: true },
                                                                        { type: 'text', name: 'remissType', label: 'Referral Type'.translate(this.props.lang), allowSort: true },
                                                                        { type: 'text', name: 'timestamp', label: 'Referral date'.translate(this.props.lang), allowSort: true },
                                                                        { type: 'text', name: 'status', label: 'Status'.translate(this.props.lang), allowSort: true },

                                                                        // { type: 'text', name: 'recpientClinicName', label: 'Receiver'.translate(this.props.lang), allowSort: true },
                                                                        // { type: 'text', name: 'profession', label: 'Profession'.translate(this.props.lang), allowSort: true },
                                                                        // { type: 'text', name: 'patient', label: 'Patient'.translate(this.props.lang), allowSort: false },
                                                                        // { type: 'text', name: 'lastActivity', label: 'Last activity'.translate(this.props.lang), allowSort: true },
                                                                        // { type: 'checkbox', name: 'seenScheduled', label: 'Seen'.translate(this.props.lang), allowSort: true },
                                                                    ]}
                                                                    rawItems={this.state.cases}
                                                                    items={this.state.cases.map((item => {
                                                                        return {
                                                                            ...item,
                                                                            remissType: item.remissType ? item.remissType.translate(this.props.lang) : '',
                                                                            questionaryName: this.props.lang == 'en' ? item.questionaryName?.replace?.(' (onlinebesök)', ' (online visit)') : item.questionaryName?.replace?.(' (online visit)', ' (onlinebesök)'),
                                                                            profession: this.props.lang == 'en' ? item.professionsStringEn : item.professionsStringSv,
                                                                            version: item.patient.referralNumber + (item.version != 0 ? '.' + item.version : ''),
                                                                            recpientClinicName: <>{item.recpientClinicName}<br /><span className="clinic-subgroup-name">{item.recpientClinicSubgroupName} </span><br /> <span className="clinic-subgroup-name">{item.doctorName}</span>  </>,
                                                                            patient: <Button color='#fff' className="patient-modal-button" onClick={() => this.setState({ patientProfile: item }, () => {
                                                                                this.props.registerClinicGroupLogs({
                                                                                    type: 'patientProfile',
                                                                                    patient: item.patient.socialSecurityNumber
                                                                                })
                                                                            })}>{item.patient.name}<br /><span className="clinic-subgroup-name">{item.patient.socialSecurityNumber} </span> </Button>,
                                                                            timestamp: this.props.getStringDateTs(item.timestamp, `${this.props.dateFormat} HH:mm`)/*moment.unix(item.timestamp).format(`${this.props.dateFormat} HH:mm`)*/,
                                                                            lastActivity: item.tsModified ? this.props.getStringDateTs(item.tsModified, `${this.props.dateFormat} HH:mm`)/*moment.unix(item.tsModified).format(`${this.props.dateFormat} HH:mm`)*/ : this.props.getStringDateTs(item.tsCreated, `${this.props.dateFormat} HH:mm`)/*moment.unix(item.tsCreated).format(`${this.props.dateFormat} HH:mm`)*/,
                                                                            converateTimestamp: this.props.getStringDateTs(item.converateTimestamp, `${this.props.dateFormat} HH:mm`)/*moment.unix(item.converateTimestamp).format(`${this.props.dateFormat} HH:mm`)*/,
                                                                            statusValue: item.status,
                                                                            status: item.recipientClinic !== this.props.selectedClinic ? <>

                                                                                <span style={{ color: statusColors['external'] }}>{humanReadable['external']} </span><br />  </>
                                                                                : <>
                                                                                    <span style={{ color: statusColors[item.status] }}>{humanReadable[item.status]} </span><br />

                                                                                    {item.status !== 'waiting-for-approval' && item.status !== 'waiting-for-payment' && item.status !== 'waiting' ?
                                                                                        <span>(
                                                                                            {item.status == 'disallowed' ?
                                                                                                this.props.getStringDateTs(item.disallowedTimestamp, `${this.props.dateFormat} HH:mm`) :
                                                                                                // moment.unix(item.disallowedTimestamp).format(`${this.props.dateFormat} HH:mm`) :
                                                                                                item.status == 'return-for-update' ?
                                                                                                    this.props.getStringDateTs(item.returnTimestamp, `${this.props.dateFormat} HH:mm`) :
                                                                                                    // moment.unix(item.returnTimestamp).format(`${this.props.dateFormat} HH:mm`) :
                                                                                                    item.status == 'allowed' ?
                                                                                                        this.props.getStringDateTs(item.allowedTimestamp, `${this.props.dateFormat} HH:mm`) :
                                                                                                        // moment.unix(item.allowedTimestamp).format(`${this.props.dateFormat} HH:mm`) :
                                                                                                        item.status == 'patient-requested-another-time' ?
                                                                                                            this.props.getStringDateTs(item.patientRequestTimeTimestamp, `${this.props.dateFormat} HH:mm`) :
                                                                                                            // moment.unix(item.patientRequestTimeTimestamp).format(`${this.props.dateFormat} HH:mm`) :
                                                                                                            item.status == 'patient-rejected' ?
                                                                                                                this.props.getStringDateTs(item.patientRejectedTimestamp, `${this.props.dateFormat} HH:mm`) :
                                                                                                                // moment.unix(item.patientRejectedTimestamp).format(`${this.props.dateFormat} HH:mm`) :
                                                                                                                item.status == 'patient-accepted' ?
                                                                                                                    this.props.getStringDateTs(item.patientAcceptedTimestamp, `${this.props.dateFormat} HH:mm`) :
                                                                                                                    // moment.unix(item.patientAcceptedTimestamp).format(`${this.props.dateFormat} HH:mm`) :
                                                                                                                    item.status == 'patient-accepted' ?
                                                                                                                        this.props.getStringDateTs(item.patientAcceptedTimestamp, `${this.props.dateFormat} HH:mm`) :
                                                                                                                        // moment.unix(item.patientAcceptedTimestamp).format(`${this.props.dateFormat} HH:mm`) :
                                                                                                                        item.status == 'clinic-accepted-requested-time' ?
                                                                                                                            this.props.getStringDateTs(item.clinicAcceptedTimestamp, `${this.props.dateFormat} HH:mm`) :
                                                                                                                            // moment.unix(item.clinicAcceptedTimestamp).format(`${this.props.dateFormat} HH:mm`) :
                                                                                                                            item.status == 'clinic-rejected-requested-time' ?
                                                                                                                                this.props.getStringDateTs(item.clinicRejectedTimestamp, `${this.props.dateFormat} HH:mm`) :
                                                                                                                                // moment.unix(item.clinicRejectedTimestamp).format(`${this.props.dateFormat} HH:mm`) :
                                                                                                                                item.status == 'approved-by-region-manager' ?
                                                                                                                                    this.props.getStringDateTs(item.approvedByRegionManagerTimestemp, `${this.props.dateFormat} HH:mm`) :
                                                                                                                                    // moment.unix(item.approvedByRegionManagerTimestemp).format(`${this.props.dateFormat} HH:mm`) :
                                                                                                                                    item.status == 'canceled-by-region-manager' ?
                                                                                                                                        this.props.getStringDateTs(item.canceledByRegionManagerTimestemp, `${this.props.dateFormat} HH:mm`) :
                                                                                                                                        // moment.unix(item.canceledByRegionManagerTimestemp).format(`${this.props.dateFormat} HH:mm`) :
                                                                                                                                        item.status == 'scheduled' ?
                                                                                                                                            this.props.getStringDateTs(item.patientReservedTimestamp, `${this.props.dateFormat} HH:mm`) :
                                                                                                                                            item.status == 'visit-rescheduled' ?
                                                                                                                                                // moment.unix(item.patientReservedTimestamp).format(`${this.props.dateFormat} HH:mm`)
                                                                                                                                                this.props.getStringDateTs(item.patientReservedTimestamp, `${this.props.dateFormat} HH:mm`)
                                                                                                                                                :
                                                                                                                                                // moment.unix(item.patientReservedTimestamp).format(`${this.props.dateFormat} HH:mm`) :
                                                                                                                                                item.status == 'revisit-scheduled' ?
                                                                                                                                                    this.props.getStringDateTs(item.patientReservedTimestamp, `${this.props.dateFormat} HH:mm`) :
                                                                                                                                                    // moment.unix(item.patientReservedTimestamp).format(`${this.props.dateFormat} HH:mm`) :
                                                                                                                                                    item.status == 'not-booked' ?
                                                                                                                                                        this.props.getStringDateTs(item.notBookedTimestamp, `${this.props.dateFormat} HH:mm`) :
                                                                                                                                                        // moment.unix(item.notBookedTimestamp).format(`${this.props.dateFormat} HH:mm`) :
                                                                                                                                                        item.status == 'in-progress' ?
                                                                                                                                                            this.props.getStringDateTs(item.inProgressTimestamp, `${this.props.dateFormat} HH:mm`) :
                                                                                                                                                            // moment.unix(item.inProgressTimestamp).format(`${this.props.dateFormat} HH:mm`) :
                                                                                                                                                            item.status == 'closed' ?
                                                                                                                                                                this.props.getStringDateTs(item.closedTimestamp, `${this.props.dateFormat} HH:mm`) :
                                                                                                                                                                // moment.unix(item.closedTimestamp).format(`${this.props.dateFormat} HH:mm`) :
                                                                                                                                                                item.status == 'canceled-by-clinic' ?
                                                                                                                                                                    this.props.getStringDateTs(item.canceledByClinicTimestamp, `${this.props.dateFormat} HH:mm`) :
                                                                                                                                                                    // moment.unix(item.canceledByClinicTimestamp).format(`${this.props.dateFormat} HH:mm`) :
                                                                                                                                                                    item.status == 'requested-new-appointment' ?
                                                                                                                                                                        this.props.getStringDateTs(item.patientRequestedNewAppointment, `${this.props.dateFormat} HH:mm`)
                                                                                                                                                                        // moment.unix(item.patientRequestedNewAppointment).format(`${this.props.dateFormat} HH:mm`) 
                                                                                                                                                                        :
                                                                                                                                                                        null
                                                                                            }) </span> : null}</>,

                                                                        }
                                                                    }))}
                                                                    actions={
                                                                        [
                                                                            {
                                                                                component: <div className="tabel-action-tooltip"> <Isvg src={viewIcon} /> <div className="chat-icons-tooltip">{'Preview'.translate(this.props.lang)}</div> </div>,
                                                                                onClick: (item) => this.setState({ referral: item, showChat: false }, () => {
                                                                                    this.setState({ refresh: new Date().getTime() })
                                                                                })
                                                                            },
                                                                            // {
                                                                            //     component: <div className="tabel-action-tooltip">  test <div className="chat-icons-tooltip">{'Preview'.translate(this.props.lang)}</div> </div>,
                                                                            //     onClick: (item) => this.setState({ referralContentPreview: item }, () => {
                                                                            //         this.setState({ refresh: new Date().getTime() })
                                                                            //     })
                                                                            // },
                                                                            {
                                                                                renderComponent: (item) => { return (<div className="tabel-action-tooltip"> <Isvg src={infoIcon} className={item && item.note ? "note-icon" : ''} style={{ width: 36, height: 23 }} /> <div className="chat-icons-tooltip info-tooltip-custom"><span>{item && item.note ? <div dangerouslySetInnerHTML={{ __html: this.prepareNote(item) }}></div> : 'Note'.translate(this.props.lang)}{item && item.note && item.noteUsername ? ' ' + item.noteUsername : ''}<br />{item && item.note && item.noteUsername && item.noteTimestamp ? ' ' + this.props.getStringDateTs(item.noteTimestamp, `${this.props.dateFormat} HH:mm`)/*moment.unix(item.noteTimestamp).format(`${this.props.dateFormat} HH:mm`)*/ : ''}</span></div> </div>) },
                                                                                onClick: (item) => this.setState({ referralNote: { ...item } }),
                                                                                condition: (item) => {
                                                                                    if (item.recipientClinic === this.props.selectedClinic)
                                                                                        return true;
                                                                                    else
                                                                                        return false;
                                                                                }
                                                                            },
                                                                            {
                                                                                component: <div className="tabel-action-tooltip"> <Isvg src={pickTask} className="transfer-icon" /> <div className="chat-icons-tooltip">{'Pick up'.translate(this.props.lang)}</div> </div>,
                                                                                onClick: (item) => {
                                                                                    if (item && item.patient && item.patient.socialSecurityNumber == this.props.uData.socialSecurityNumber) {
                                                                                        this.setState({ messageInfo: 'Unable to pick up this case. Both participants are using the same account.'.translate(this.props.lang) })
                                                                                        return;
                                                                                    }
                                                                                    this.setState({ conversationModal: item, conversationForm: { name: item.patient.referralNumber + '-' + pad(item.conversations.length + 1, 2) } })
                                                                                },
                                                                                condition: (item) => {
                                                                                    if (item.recipientClinic !== this.props.selectedClinic) {
                                                                                        return false;
                                                                                    } else if (item.statusValue == 'waiting' && this.props.uData && this.props.uData.profession && this.props.uData.profession[this.props.selectedGroup] && item.professionList && item.professionList.filter(profession => {
                                                                                        if (typeof this.props.uData.profession[this.props.selectedGroup] !== 'string') {
                                                                                            return this.props.uData.profession[this.props.selectedGroup].indexOf(profession) !== -1
                                                                                        } else {
                                                                                            return profession == this.props.uData.profession[this.props.selectedGroup]
                                                                                        }
                                                                                    }).length)
                                                                                        return true;
                                                                                    else
                                                                                        return false;
                                                                                }
                                                                            },
                                                                            {
                                                                                component: <div className="tabel-action-tooltip"> <Isvg src={ChatIcon} /> <div className="chat-icons-tooltip">{'Start conversation'.translate(this.props.lang)}</div> </div>,
                                                                                onClick: (item) => {
                                                                                    if (item && item.patient && item.patient.socialSecurityNumber == this.props.uData.socialSecurityNumber) {
                                                                                        this.setState({ messageInfo: 'Unable to open a new conversation. Both participants are using the same account.'.translate(this.props.lang) })
                                                                                        return;
                                                                                    }
                                                                                    if (item.conversations && item.conversations.length) {
                                                                                        if (item.conversations[0].members && item.conversations[0].members.filter(el => el == this.props.uData._id).length) {
                                                                                            this.setState({ chatConversation: item.conversations[0]._id })
                                                                                            if (item.conversations[0].members[0] === this.props.uData._id) {
                                                                                                this.setState({
                                                                                                    doctorCanStartVideoCall: true
                                                                                                })
                                                                                            } else {
                                                                                                this.setState({
                                                                                                    doctorCanStartVideoCall: false
                                                                                                })
                                                                                            }
                                                                                        } else {
                                                                                            this.addUserToConversation(item.conversations[0]._id)
                                                                                        }

                                                                                    } else {
                                                                                        this.setState({
                                                                                            conversationForm: { name: item.patient.referralNumber + '-' + pad(item.conversations.length + 1, 2) }
                                                                                        }, () => {
                                                                                            this.conversation(item._id, { ...this.state.conversationForm, patient: true });

                                                                                        })
                                                                                    }


                                                                                },
                                                                                condition: (item) => {
                                                                                    if (item.recipientClinic !== this.props.selectedClinic) {
                                                                                        return false;
                                                                                    } else if ((item.statusValue == 'waiting-for-approval' || item.statusValue == 'waiting-for-payment')) {
                                                                                        return true
                                                                                    } else {
                                                                                        //     if (this.props.uData && ((this.props.uData._id == item.recipientUser) || (item.conversations.filter(item => item.members.filter(mem => mem == this.props.uData._id).length).length) || (item && item.additionalDoctors && item.additionalDoctors.filter(doc => doc == this.props.uData._id).length)) && ((item.wayOfScheduling == 'dropIn' && item.statusValue != 'closed' && item.statusValue != 'not-booked') || item.wayOfScheduling == 'bookingByClinic' || item.wayOfScheduling == 'webBooking' || item.control))
                                                                                        //         return true;
                                                                                        //     else
                                                                                        //         return false;
                                                                                        // }
                                                                                        if (this.props.uData && ((this.props.uData._id == item.recipientUser) || (item.conversations.filter(item => item.members.filter(mem => mem == this.props.uData._id).length).length) || (item && item.additionalDoctors && item.additionalDoctors.filter(doc => doc == this.props.uData._id).length)) && ((item.wayOfScheduling == 'dropIn' && item.statusValue != 'closed' && item.statusValue != 'not-booked') || item.wayOfScheduling == 'bookingByClinic' || item.wayOfScheduling == 'webBooking' || item.control))
                                                                                            return true;
                                                                                        else
                                                                                            return false;

                                                                                    }

                                                                                }

                                                                                // onClick: (item) => this.setState({ conversationModal: item, conversationForm: { name: item.patient.referralNumber + '-' + pad(item.conversations.length + 1, 2) } })
                                                                            },
                                                                            // {
                                                                            //     renderComponent: (item) => {
                                                                            //         return (
                                                                            //             <div className="tabel-action-tooltip inverse-chat-icon">
                                                                            //                 <Isvg src={ChatIcon2} />
                                                                            //                 {
                                                                            //                     item.conversations && item.conversations[0] && item.conversations[0].startedByUser ?
                                                                            //                         <div className="chat-icons-tooltip">
                                                                            //                             {item.conversations[0].startedByUser} {"has started the conversation. Click to open.".translate(this.props.lang)}
                                                                            //                         </div>
                                                                            //                         :
                                                                            //                         null
                                                                            //                 }

                                                                            //             </div>)
                                                                            //     },
                                                                            //     onClick: (item) => {
                                                                            //         if (item && item.patient && item.patient.socialSecurityNumber == this.props.uData.socialSecurityNumber) {
                                                                            //             this.setState({ messageInfo: 'Unable to open a new conversation. Both participants are using the same account.'.translate(this.props.lang) })
                                                                            //             return;
                                                                            //         }
                                                                            //         if (item.conversations && item.conversations.length) {
                                                                            //             if (item.conversations[0].members && item.conversations[0].members.filter(el => el == this.props.uData._id).length) {
                                                                            //                 this.setState({ chatConversation: item.conversations[0]._id })
                                                                            //             } else {
                                                                            //                 this.addUserToConversation(item.conversations[0]._id)
                                                                            //             }

                                                                            //         } else {

                                                                            //             this.setState({
                                                                            //                 conversationForm: { name: item.patient.referralNumber + '-' + pad(item.conversations.length + 1, 2) }
                                                                            //             }, () => {
                                                                            //                 this.conversation(item._id, { ...this.state.conversationForm, patient: true });

                                                                            //             })
                                                                            //         }
                                                                            //     },
                                                                            //     condition: (item) => {
                                                                            //         if ((item.statusValue == 'waiting-for-approval' || item.statusValue == 'waiting-for-payment') && item.conversations && item.conversations.length) {
                                                                            //             if (this.props.uData && ((this.props.uData._id == item.recipientUser) || (item.conversations.filter(item => item.members.filter(mem => mem == this.props.uData._id).length).length) || (item && item.additionalDoctors && item.additionalDoctors.filter(doc => doc == this.props.uData._id).length)) && ((item.wayOfScheduling == 'dropIn' && item.statusValue != 'closed' && item.statusValue != 'not-booked') || item.wayOfScheduling == 'bookingByClinic' || item.wayOfScheduling == 'webBooking' || item.control))
                                                                            //                 return true;
                                                                            //             else
                                                                            //                 return false;
                                                                            //         } else {
                                                                            //             return false;
                                                                            //         }

                                                                            //     }

                                                                            //     // onClick: (item) => this.setState({ conversationModal: item, conversationForm: { name: item.patient.referralNumber + '-' + pad(item.conversations.length + 1, 2) } })
                                                                            // },
                                                                            {
                                                                                renderComponent: (item) => <div className="tabel-action-tooltip"> <Isvg src={transferIcon} className={eConnectEnabled && item?._consultation ? "transfer-icon consultation-active-icon" : "transfer-icon"} /> <div className="chat-icons-tooltip">{eConnectEnabled ? 'Consultation'.translate(this.props.lang) : 'Transfer'.translate(this.props.lang)}</div> </div>,
                                                                                onClick: async (item) => {
                                                                                    if (eConnectEnabled && this.props.checkIfConsultationExists && item?._id) {
                                                                                        let consultationExists = await this.props.checkIfConsultationExists(item._id, () => {
                                                                                            this.setState({ transferModal: item })
                                                                                        });
                                                                                        if (!consultationExists)
                                                                                            this.setState({ transferModal: item })
                                                                                    } else {
                                                                                        this.setState({ transferModal: item })
                                                                                    }

                                                                                },
                                                                                condition: (item) => {

                                                                                    if (item.recipientClinic !== this.props.selectedClinic) {
                                                                                        return false;
                                                                                    } else if (((item.wayOfScheduling == 'dropIn' && item.statusValue !== 'closed' && item.dropIn) || (item.wayOfScheduling == 'webBooking' && (item.statusValue == 'scheduled' || item.statusValue === 'visit-rescheduled' || item.statusValue == 'not-booked')) || (item.control && (item.statusValue == 'revisit-scheduled' || item.statusValue === 'visit-rescheduled')) || (item.wayOfScheduling == 'bookingByClinic' && (item.statusValue == 'allowed' || item.statusValue == 'scheduled' || item.statusValue === 'visit-rescheduled' || item.statusValue == 'not-booked'))) && this.state.adminPermission && item.statusValue != 'closed') {
                                                                                        return true;
                                                                                    } else
                                                                                        return false;
                                                                                }
                                                                            },
                                                                            {
                                                                                component: <div className="tabel-action-tooltip"> <Isvg src={garbageIcon} className="transfer-icon" /> <div className="chat-icons-tooltip">{'Remove'.translate(this.props.lang)}</div> </div>,
                                                                                onClick: (item) => this.setState({ removeReferralModal: { ...item } }),
                                                                                condition: (item) => {
                                                                                    if (item.recipientClinic !== this.props.selectedClinic && isAdmin)
                                                                                        return true;
                                                                                    else
                                                                                        return false;
                                                                                }
                                                                            }
                                                                        ]
                                                                    }
                                                                    params={params}
                                                                    sortField={params.sortField}
                                                                    sortType={params.sortType}
                                                                    updateSort={this.updateSort}
                                                                    updateParams={this.updateParams}
                                                                    updateMultipleParams={this.updateMultipleParams}

                                                                ></ListBuilder>

                                                            </div>
                                                            :
                                                            null
                                                    }
                                                    {
                                                        this.state.tabIndex == 2 ?
                                                            <div className="patient-search-notes-wrap">
                                                                <div className="patient-search-notes" ref={(node) => this.notesRef = node}>
                                                                    {
                                                                        this.state.patient && this.state.patient.patientInfo && this.state.patient.patientInfo[this.props.selectedGroup] && this.state.patient.patientInfo[this.props.selectedGroup].notes && this.state.patient.patientInfo[this.props.selectedGroup].notes && this.state.patient.patientInfo[this.props.selectedGroup].notes.map((item, idx) => {
                                                                            return (
                                                                                <div className="patient-search-notes-item">
                                                                                    <p>
                                                                                        {
                                                                                            item.note.split('\n').length ?
                                                                                                item.note.split('\n').map((text, t) => {
                                                                                                    if (t != item.note.split('\n').length - 1)
                                                                                                        return (
                                                                                                            <>{text}<br /></>
                                                                                                        )
                                                                                                    else
                                                                                                        return (
                                                                                                            text
                                                                                                        )
                                                                                                })
                                                                                                :
                                                                                                item.note
                                                                                        }
                                                                                    </p>
                                                                                    <div className="patient-search-notes-item-footer">
                                                                                        <p>
                                                                                            {
                                                                                                item.senderUsername ?
                                                                                                    <>
                                                                                                        {'Created by'.translate(this.props.lang)} {item.senderUsername}
                                                                                                    </>
                                                                                                    :
                                                                                                    null
                                                                                            }
                                                                                        </p>
                                                                                        <p>
                                                                                            {
                                                                                                item.timestamp ?
                                                                                                    <>
                                                                                                        {this.props.getStringDateTs(item.timestamp, this.props.dateFormat)/*moment.unix(item.timestamp).format(this.props.dateFormat)*/} {this.props.getStringDateTs(item.timestamp, 'HH:mm:ss')/*moment.unix(item.timestamp).format('HH:mm:ss')*/}
                                                                                                    </>
                                                                                                    :
                                                                                                    null
                                                                                            }
                                                                                        </p>

                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                                <div style={{ paddingRight: 10 }}>
                                                                    <Input type='textarea' style={{ height: 150, paddingTop: 15 }} placeholder={'Write a note...'.translate(this.props.lang)} value={this.state.note} onChange={(e) => this.setState({ note: e.target.value })} />
                                                                </div>
                                                                <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%', marginTop: 10, paddingRight: 10 }}>
                                                                    <Button color="primary" onClick={() => this.saveNote(this.state.note)}>{'Save'.translate(this.props.lang)}</Button>
                                                                </div>

                                                            </div>
                                                            :
                                                            null
                                                    }
                                                    {
                                                        this.state.tabIndex === 3 ?
                                                            <Statements
                                                                {...this.props}
                                                                socket={this.props.socket}
                                                                patientId={this.state.patient?._id}
                                                            />
                                                            :
                                                            null
                                                    }
                                                </div>
                                            </div>

                                        </Col>
                                        <Col lg="4">

                                            <div className="panel iframe-chat-modified">

                                                {
                                                    this.state.chatConversation ?
                                                        <iframe src={`/chat/${this.state.chatConversation}?doNotOpenPatientProfile=true`} />
                                                        :
                                                        this.state.chatId ?
                                                            <iframe src={`/chat/${this.state.chatId}?patientSearchModule=true&doNotOpenPatientProfile=true`} />
                                                            :
                                                            null
                                                }

                                            </div>

                                        </Col>
                                    </>
                                    :
                                    this.state.tabActive == 2 ?
                                        <Col lg='12'>
                                            <div className="panel">
                                                <Daybook
                                                    {...this.props}
                                                    clinicGroup={this.props.selectedGroup}
                                                    patient={this.state.patient}
                                                />
                                            </div>
                                        </Col>
                                        :
                                        this.state.tabActive == 3 ?
                                            <Col lg='12'>
                                                <div className="panel">
                                                    <TestResults
                                                        {...this.props}
                                                        patient={this.state.patient}
                                                        notSaved={(item) => this.setState({ notSavedTestResults: item })}
                                                        notSavedTestResults={this.state.notSavedTestResults}
                                                        initialSave={this.state.saveTestResults}
                                                        setStates={(obj) => this.setState(obj)}
                                                        saveTestResults={(item, callback) => this.setState({ saveTestResults: item }, () => {
                                                            this.props.abortAction()
                                                            if (callback) {
                                                                callback()
                                                            }
                                                        })}
                                                    />
                                                </div>
                                            </Col>
                                            :
                                            this.state.tabActive == 4 ?
                                                <Col lg='12'>
                                                    <div className="panel">

                                                        <ListOfMedicines
                                                            {...this.props}

                                                            patient={this.state.patient}
                                                            clinicGroup={this.props.selectedGroup}
                                                            notSaved={(item) => this.setState({ notSavedTestResults: item })}
                                                            notSavedTestResults={this.state.notSavedTestResults}
                                                            updateEditTime={() => this.searchPatient1(this.state.patient.socialSecurityNumber)}
                                                            // updateEditTimeTs = {this.state.updateEditTimeTs}
                                                            initialSave={this.state.saveMedicine}
                                                            setStates={(obj) => this.setState(obj, () => console.log(obj))}
                                                            saveMedicine={(item, callback) => this.setState({ saveMedicine: item }, () => {
                                                                this.props.abortAction()
                                                                if (callback) {
                                                                    callback()
                                                                }
                                                            })}
                                                        />
                                                    </div>
                                                </Col>
                                                :
                                                this.state.tabActive == 5 ?
                                                    <Col lg='12'>
                                                        <div className="panel">
                                                            <PatientDocuments
                                                                {...this.props}
                                                                documents={this.state.patient?.patientDocuments}
                                                            />
                                                        </div>
                                                    </Col>
                                                    : null

                            }

                        </Row>
                    }

                </Container>
                {
                    this.state.removeReferralModal ?
                        <DeleteModal
                            zIndex={99999}
                            lang={this.props.lang}
                            isOpen={this.state.removeReferralModal}
                            toggle={() => this.setState({ removeReferralModal: null })}
                            handler={() => { this.removeReferralFromList(this.state.removeReferralModal._id); }}
                        >
                            {'Do you want to delete the referral from the list of this clinic?'.translate(this.props.lang)}
                        </DeleteModal>
                        :
                        null
                }
                {
                    this.state.makeTokenModal ?
                        <InfoModal
                            isOpen={this.state.makeTokenModal}

                            toggle={() => this.setState({ makeTokenModal: null })}
                            header={'Info'.translate(this.props.lang)}
                            info={'Do you want to connect with WebDoc?'.translate(this.props.lang)}
                            buttons={[
                                <Button color='primary' onClick={() => {
                                    this.setState({
                                        makeTokenModal: null,
                                    })
                                    this.makeTokenWebDoc()

                                }}>{'Yes'.translate(this.props.lang)}</Button>,
                                <Button color='primary' onClick={() => {
                                    this.setState({
                                        makeTokenModal: null,
                                    })

                                }}>{'No'.translate(this.props.lang)}</Button>
                            ]}
                        />
                        :
                        null
                }
                {
                    this.state.messageInfo ?
                        <InfoModal
                            isOpen={this.state.messageInfo}

                            toggle={() => this.setState({ messageInfo: null })}
                            header={'Info'.translate(this.props.lang)}
                            info={this.state.messageInfo}
                            buttons={[
                                <Button color='primary' onClick={() => {
                                    this.setState({
                                        messageInfo: null,
                                    })

                                }}>{'Close'.translate(this.props.lang)}</Button>
                            ]}
                        />
                        :
                        null

                }
                {
                    this.state.conversationModal ?
                        <InfoModal
                            isOpen={this.state.conversationModal}
                            onClose={(item) => {
                                this.setState({
                                    conversationModal: item
                                })
                            }}
                            close={true}
                            toggle={() => this.setState({ conversationModal: !this.state.conversationModal })}
                            header={'Info'.translate(this.props.lang)}
                            info={'Do you want to pick up this case?'.translate(this.props.lang)}
                            buttons={[
                                <Button color="primary" disabled={this.state.loadingAssign} style={{ margin: 10 }} onClick={() => this.assignReferral(this.state.conversationModal)}>{'Yes'.translate(this.props.lang)}</Button>,
                                <Button color="primary" style={{ margin: 10 }} onClick={() => this.setState({ conversationModal: false })}>{'No'.translate(this.props.lang)}</Button>


                            ]}
                        />
                        :
                        null
                }
                {
                    this.state.editAddress ?
                        <Modal isOpen={this.state.editAddress} centered>
                            <ModalHeader toggle={() => this.setState({ editAddress: !this.state.editAddress })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ editAddress: !this.state.editAddress })}><Isvg src={xIcon} /></button>}>
                                <h4>{'Edit address'.translate(this.props.lang)}</h4>
                            </ModalHeader>
                            <ModalBody>
                                <FormGroup>
                                    <Label>{'Street'.translate(this.props.lang)}</Label>
                                    <Input type='text' value={this.state.editAddress.street} onChange={(e) => {
                                        let editAddress = this.state.editAddress;
                                        editAddress.street = e.target.value;
                                        this.setState({ editAddress })
                                    }} />
                                </FormGroup>
                                <FormGroup>
                                    <Label>{'City'.translate(this.props.lang)}</Label>
                                    <Input type='text' value={this.state.editAddress.city} onChange={(e) => {
                                        let editAddress = this.state.editAddress;
                                        editAddress.city = e.target.value;
                                        this.setState({ editAddress })
                                    }} />
                                </FormGroup>
                                <FormGroup>
                                    <Label>{'Zip'.translate(this.props.lang)}</Label>
                                    <Input type='text' value={this.state.editAddress.zip} onChange={(e) => {
                                        let editAddress = this.state.editAddress;
                                        editAddress.zip = e.target.value;
                                        this.setState({ editAddress })
                                    }} />
                                </FormGroup>

                            </ModalBody>
                            <ModalFooter className='buttons-right-blue'>
                                <Button color="primary" onClick={() => {
                                    let patientInfo = this.state.patientInfo;
                                    patientInfo.city = this.state.editAddress.city;
                                    patientInfo.zip = this.state.editAddress.zip;
                                    patientInfo.street = this.state.editAddress.street;
                                    patientInfo.address = `${this.state.editAddress && this.state.editAddress.street ? this.state.editAddress.street : ''} ${this.state.editAddress && this.state.editAddress.zip ? this.state.editAddress.zip : ''} ${this.state.editAddress && this.state.editAddress.city ? this.state.editAddress.city : ''}`;

                                    this.setState({ patientInfo, editAddress: null }, () => {
                                        this.updatePatientAddress(this.state.patientInfo)
                                    })
                                }}>{'Save'.translate(this.props.lang)}</Button>

                                <Button color="primary" onClick={() => this.setState({ editAddress: !this.state.editAddress })}>{'Close'.translate(this.props.lang)}</Button>

                            </ModalFooter>
                        </Modal>
                        :
                        null
                }
                {
                    this.state.transferModal ?
                        eConnectEnabled ?
                            <ConsultationModal
                                {...this.props}
                                isOpen={this.state.transferModal}
                                referral={this.state.transferModal}
                                closeModal={() => this.setState({ transferModal: null }, () => {
                                    this.get()
                                })}
                            />
                            :
                            <Modal isOpen={this.state.transferModal} centered>
                                <ModalHeader toggle={() => this.setState({ transferModal: !this.state.transferModal, selectedDoctor: -1 })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ transferModal: !this.state.transferModal, selectedDoctor: -1 })}><Isvg src={xIcon} /></button>}>
                                    <h4>{'Transfer modal'.translate(this.props.lang)}</h4>
                                </ModalHeader>
                                <ModalBody>
                                    <FormGroup>
                                        <Label>{'Transfer from'.translate(this.props.lang)}</Label>
                                        <Input type='text' disabled
                                            value={this.state.transferModal.doctorName ? this.state.transferModal.doctorName : 'Unassigned'.translate(this.props.lang)} />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>{'Transfer to'.translate(this.props.lang)}</Label>
                                        <Input type='select'
                                            value={this.state.selectedDoctor}
                                            onChange={(e) => {
                                                this.setState({ selectedDoctor: e.target.value })
                                            }}
                                        >
                                            <option value={-1}>{'None'.translate(this.props.lang)}</option>
                                            {

                                                this.state.subAccounts && this.state.subAccounts.filter(el => el._id != this.state.transferModal.patientId).sort((a, b) => {
                                                    const nameA = a.name.toLowerCase();
                                                    const nameB = b.name.toLowerCase();
                                                    if (nameA < nameB) {
                                                        return -1
                                                    }
                                                    if (nameA > nameB) {
                                                        return 1
                                                    }
                                                }).map((item, idx) => {
                                                    if (item._id != this.state.transferModal.recipientUser)
                                                        return (
                                                            <option value={item._id}>{item.name}</option>
                                                        )
                                                })
                                            }
                                        </Input>
                                    </FormGroup>

                                </ModalBody>
                                <ModalFooter className='buttons-right-blue'>
                                    <Button color="primary" disabled={this.state.selectedDoctor == -1 ? true : false} onClick={() => {
                                        if (this.state.transferModal.wayOfScheduling == 'webBooking') {
                                            this.transferWebBookingReferral(this.state.transferModal)
                                        } else if (this.state.transferModal.wayOfScheduling == 'dropIn') {
                                            this.transferDropIn(this.state.transferModal)
                                        } else if (this.state.transferModal.wayOfScheduling == 'bookingByClinic') {
                                            this.transferBookingByClinic(this.state.transferModal)
                                        } else if (this.state.transferModal.control) {
                                            this.transferWebBookingReferral(this.state.transferModal)
                                        }
                                    }}>{'Transfer'.translate(this.props.lang)}</Button>

                                    <Button color="primary" onClick={() => this.setState({ transferModal: !this.state.transferModal, selectedDoctor: -1 })}>{'Close'.translate(this.props.lang)}</Button>

                                </ModalFooter>
                            </Modal>
                        :
                        null
                }
                {this.state.error ?

                    <ErrorModal
                        isOpen={this.state.error}
                        toggle={() => this.setState({ error: null })}
                        lang={this.props.lang}

                    >
                        {this.state.error.translate(this.props.lang)}
                    </ErrorModal>

                    :
                    null
                }

                {this.state.errorOverlap ?
                    <Modal isOpen={this.state.errorOverlap} centered>
                        {/* <ModalHeader style={{ margin: 'auto' }}>{'Error'.translate(this.props.lang)}</ModalHeader> */}
                        <ModalBody className="delete-modal">
                            {/* <img src={warrning} /> */}
                            <div className="message">
                                {this.state.errorOverlap && this.state.errorOverlap.message}
                            </div>
                        </ModalBody>
                        <ModalFooter style={{ display: 'flex', justifyContent: 'center' }} className="buttons-right-blue">
                            <Button style={{ minWidth: 100 }} color="primary" onClick={() => {
                                if (this.state.adminPermission) {
                                    this.props[0].history.push({
                                        pathname: '/my-data/referrals/schedule',
                                        search: '?fromAllCases=true',
                                        state: {
                                            doctor: this.state.errorOverlap && this.state.errorOverlap.doctor,
                                            day: new Date((this.state.errorOverlap && this.state.errorOverlap.date) * 1000),
                                            step: 5,

                                        }
                                    })
                                } else {
                                    this.props[0].history.push({
                                        pathname: '/my-data/referrals/working-hours',
                                        search: '?fromAllCases=true',
                                        state: {
                                            doctor: this.state.errorOverlap && this.state.errorOverlap.doctor,
                                            day: new Date((this.state.errorOverlap && this.state.errorOverlap.date) * 1000),
                                            step: 5,

                                        }
                                    })

                                }

                            }} >{'Manage in calender'.translate(this.props.lang)}</Button>
                            <Button style={{ minWidth: 100 }} color="primary" onClick={() => this.setState({ errorOverlap: null })} >{'Close'.translate(this.props.lang)}</Button>{' '}
                        </ModalFooter>
                    </Modal>

                    :
                    null
                }


                {this.state.form ?
                    <FormBuilder
                        selectedGroup={this.props.selectedGroup}
                        selectedClinic={this.props.selectedClinic}
                        countryTwoLetter={this.props.countryTwoLetter}
                        country={this.props.country} dateFormat={this.props.dateFormat}
                        getStringDateTs={this.props.getStringDateTs}
                        onSubmit={(data) => {
                            if (!this.state.ssnExistInThisGroup) {
                                this.checkPatientValidations(data)
                            } else {
                                this.setState({ form: null })
                            }

                        }}
                        initialValues={this.state.form}
                        size="lg"
                        isOpen={this.state.form}
                        toggle={() => this.setState({ form: null })}
                        title={this.state.form._id ? 'Edit patient'.translate(this.props.lang) : 'Add new patient'.translate(this.props.lang)}
                        _saving={this.state._saving}
                        buttonConfirmText={'Saved'.translate(this.props.lang)}
                        buttonText={!this.state.ssnExistInThisGroup ? 'Save and send confirmation'.translate(this.props.lang) : 'Close'.translate(this.props.lang)}
                        disableSave={this.state.form._disableEdit}

                        lang={this.props.lang}
                        fields={[
                            {
                                type: 'row',
                                children: [
                                    {
                                        type: 'col',
                                        width: {
                                            lg: 8,
                                            sm: 12,
                                            xs: 12
                                        },
                                        children: [
                                            {
                                                type: 'socialSecurityNumber',
                                                name: 'socialSecurityNumber',
                                                searchByNameAndEmailFormBuilder: true,
                                                label: this.props.country === 'uk' ? "Date of birth".translate(this.props.lang) : 'Social security number'.translate(this.props.lang),
                                                labelInfo: "Clinic can only add adults 18+ Children 13-17 apply for themselves or guardians apply for them. Children 0-12 guardians are looking for.".translate(this.props.lang),
                                                validate: [required('Social security number is required!'.translate(this.props.lang)), checkSocialSecurityNumber('Social security number is not valid!'.translate(this.props.lang), this.props.country)]

                                            },
                                        ]
                                    },
                                    this.state.socialSecurityNumberExist && !this.state.ssnExistInThisGroup ?
                                        {
                                            type: 'col',
                                            width: {
                                                lg: 12,
                                                sm: 12,
                                                xs: 12
                                            },
                                            children: [
                                                {
                                                    type: 'h6',
                                                    text: 'The user already exists in the system'.translate(this.props.lang),
                                                    style: { marginBottom: 20 }
                                                },
                                            ]
                                        } : { type: 'empty' },
                                    this.state.socialSecurityNumberExist && this.state.ssnExistInThisGroup ?
                                        {
                                            type: 'col',
                                            width: {
                                                lg: 12,
                                                sm: 12,
                                                xs: 12
                                            },
                                            children: [
                                                {
                                                    type: 'h6',
                                                    text: "The user is already registered within your clinic group".translate(this.props.lang),
                                                    style: { marginBottom: 20 }
                                                },
                                            ]
                                        } : { type: 'empty' },

                                    !this.state.socialSecurityNumberExist ?
                                        {
                                            type: 'col',
                                            width: {
                                                lg: 4,
                                                sm: 12,
                                                xs: 12
                                            },
                                            children: [
                                                {
                                                    type: 'select',
                                                    name: 'userData.sex',
                                                    labelInfo: "The patient's biological sex is needed for proper treatment".translate(this.props.lang),
                                                    label: 'Sex'.translate(this.props.lang),
                                                    values: [{ value: 0, name: 'Select'.translate(this.props.lang) }, { value: 'male', name: 'Male'.translate(this.props.lang) }, { value: 'female', name: 'Female'.translate(this.props.lang) }],
                                                    // validate: [required('Sex is required!'.translate(this.props.lang))]
                                                },
                                            ]
                                        } : { type: 'empty' },

                                    !this.state.socialSecurityNumberExist ?
                                        {
                                            type: 'col',
                                            width: {
                                                lg: this.props.secondaryLanguageDisabled ? 12 : 6,
                                                sm: 12,
                                                xs: 12
                                            },
                                            children: [
                                                {
                                                    type: 'text',
                                                    name: 'userData.name',
                                                    label: 'First and last name'.translate(this.props.lang),
                                                    disabled: this.props.alias,
                                                    validate: [required('Name is required!'.translate(this.props.lang))]


                                                },
                                            ]
                                        } : { type: 'empty' },
                                    !this.state.socialSecurityNumberExist && !this.props.secondaryLanguageDisabled ?
                                        {
                                            type: 'col',
                                            width: {
                                                lg: 6,
                                                sm: 12,
                                                xs: 12
                                            },
                                            children: [
                                                {
                                                    type: 'select',
                                                    name: 'language',
                                                    label: 'Language'.translate(this.props.lang),
                                                    values: this.state.languages.map(item => { return { name: item.name?.translate(this.props.lang), value: item._id } })
                                                },
                                            ]
                                        } : { type: 'empty' },

                                    !this.state.socialSecurityNumberExist ?
                                        {
                                            type: 'col',
                                            width: {
                                                lg: 6,
                                                sm: 12,
                                                xs: 12
                                            },
                                            children: [
                                                {
                                                    type: this.state.form._disableEdit ? 'text' : 'email',
                                                    name: 'email',
                                                    label: 'Email address'.translate(this.props.lang),
                                                    // validate: [required('Email address is required!'.translate(this.props.lang))]

                                                },
                                            ]
                                        } : { type: 'empty' },
                                    !this.state.socialSecurityNumberExist ?
                                        {
                                            type: 'col',
                                            width: {
                                                lg: 6,
                                                sm: 12,
                                                xs: 12
                                            },
                                            children: [
                                                {
                                                    type: 'phone',

                                                    name: 'phone',
                                                    label: 'Phone'.translate(this.props.lang),


                                                },
                                            ]
                                        } : { type: 'empty' },
                                    !this.state.ssnExistInThisGroup ?
                                        {
                                            type: 'col',
                                            width: {
                                                lg: 6,
                                                sm: 12,
                                                xs: 12
                                            },
                                            children: [
                                                {
                                                    type: 'checkbox',
                                                    validate: [required('Consent is required!'.translate(this.props.lang))],
                                                    name: 'consentAccepted',
                                                    label: 'Consent has been obtained'.translate(this.props.lang),

                                                },
                                            ]
                                        } : { type: 'empty' },




                                ]
                            }
                        ]}
                    ></FormBuilder>
                    :
                    null
                }
                {this.state.succesMessage ?
                    <InfoModal
                        isOpen={this.state.succesMessage}
                        onClose={(item) => {
                            this.setState({
                                succesMessage: item
                            })
                        }}
                        // toggle={() => this.setState({ succesMessage: false })}
                        header={'Info'.translate(this.props.lang)}
                        info={this.state.succesMessage.translate(this.props.lang)}
                        buttons={[
                            <Button color='primary' style={{ margin: 10 }} onClick={() => this.setState({ succesMessage: null })}>{'Close'.translate(this.props.lang)}</Button>,

                        ]}
                    />
                    :
                    null
                }
                {this.state.saveModal ?
                    <InfoModal
                        isOpen={this.state.saveModal}
                        onClose={(item) => {
                            this.setState({
                                saveModal: item
                            })
                        }}
                        // toggle={() => this.setState({ saveModal: false })}
                        header={'Warning'.translate(this.props.lang)}
                        info={this.state.saveModal?.twoFactorAuthenticationEnabled
                            ? 'Is the information correct? Incorrect information may lead to the wrong person accessing the account'.translate(this.props.lang)
                            : 'Is it true that consent has been obtained to open the account'.translate(this.props.lang)}
                        buttons={[
                            <button style={{ visibility: 'hidden', display: 'none' }} aria-hidden='true'></button>,
                            <Button
                                color='success'
                                style={{ margin: 10 }}
                                onClick={() => this.addPatient(this.state.saveModal)}
                            >
                                {this.state.saveModal?.twoFactorAuthenticationEnabled
                                    ? 'Confirm'.translate(this.props.lang)
                                    : 'Yes'.translate(this.props.lang)
                                }
                            </Button>,
                            <Button
                                color='danger'
                                style={{ margin: 10 }}
                                onClick={() => this.setState({
                                    saveModal: null
                                })}
                            >
                                {this.state.saveModal?.twoFactorAuthenticationEnabled
                                    ? 'Go back'.translate(this.props.lang)
                                    : 'No'.translate(this.props.lang)
                                }
                            </Button>,
                            <button style={{ visibility: 'hidden', display: 'none' }} aria-hidden='true'></button>
                        ]}
                    />
                    :
                    null
                }
                {
                    this.state.previewAnswersReferralModal ?
                        <Modal isOpen={this.state.previewAnswersReferralModal} centered toggle={() => this.setState({ previewAnswersReferralModal: !this.state.previewAnswersReferralModal })} size="lg">
                            <ModalHeader toggle={() => this.setState({ previewAnswersReferralModal: !this.state.previewAnswersReferralModal })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ previewAnswersReferralModal: !this.state.previewAnswersReferralModal })}><Isvg src={xIcon} /></button>}>{'Form preview'.translate(this.props.lang)}</ModalHeader>

                            <ModalBody>

                                <FormPreview {...this.props} referral={{ name: this.state.previewAnswersReferralModal.name, description: this.state.previewAnswersReferralModal.description, intelligentReferral: this.state.previewAnswersReferralModal.intelligentReferral, items: this.state.previewAnswersReferralModal.questionaryData?.items, group: this.props.selectedGroup }} sections={this.generateSections(this.state.previewAnswersReferralModal.questionaryData.items)} answers={this.state.previewAnswersReferralModal.answers} disabledForm={true} hidePreview={true}></FormPreview>

                            </ModalBody>
                        </Modal>
                        :
                        null
                }
                {
                    this.state.previewAnswersModal ?
                        <Modal isOpen={this.state.previewAnswersModal} centered toggle={() => this.setState({ previewAnswersModal: !this.state.previewAnswersModal })} size="lg">
                            <ModalHeader toggle={() => this.setState({ previewAnswersModal: !this.state.previewAnswersModal })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ previewAnswersModal: !this.state.previewAnswersModal })}><Isvg src={xIcon} /></button>}>{'Form preview'.translate(this.props.lang)}</ModalHeader>

                            <ModalBody>

                                <FormPreview {...this.props} referral={{ name: this.state.previewAnswersModal.name, description: this.state.previewAnswersModal.description, intelligentReferral: this.state.previewAnswersModal.intelligentReferral, items: this.state.previewAnswersModal.items, group: this.props.selectedGroup }} sections={this.generateSections(this.state.previewAnswersModal.items)} answers={this.state.previewAnswersModal.answers} disabledForm={true} hidePreview={true}></FormPreview>

                            </ModalBody>
                        </Modal>
                        :
                        null
                }
                {
                    this.state.referral ?
                        <ReferralContent
                            {...this.props}
                            data={this.state.referral}
                            screen={'search-enterprise'}
                            doctorPage={true}
                            enterprise={true}
                            adminPermission={this.state.adminPermission}
                            generateTimeReferral={(referral) => { this.generateTimeReferral(referral) }}
                            allowReferral={() => { this.allowReferral() }}
                            disallowReferral={(referral) => { this.setState({ disallowForm: referral }) }}
                            removeReferralModal={(item) => this.setState({ removeReferralModal: { ...item } })}
                            priorityModal={(referral) => {
                                this.setState({
                                    priorityModal: { ...referral }
                                })
                            }}
                            pickUp={() => {
                                if (this.state.referral && this.state.referral.patient && this.state.referral.patient.socialSecurityNumber == this.props.uData.socialSecurityNumber) {
                                    this.setState({ messageInfo: 'Unable to pick up this case. Both participants are using the same account.'.translate(this.props.lang) })
                                    return;
                                }
                                this.setState({ conversationModal: this.state.referral, conversationForm: { name: this.state.referral.patient.referralNumber + '-' + pad(this.state.referral.conversations.length + 1, 2) } })
                            }}
                            closeCase={() => { this.setState({ closeCase: true }) }}
                            deleteCase={() => { this.setState({ deleteCase: true }) }}
                            previewAnswersRevisit={(form) => {
                                this.setState({ previewAnswersModal: form })
                            }}
                            closeReferralContent={(data) => {
                                if (this.state.referral) {
                                    this.setState({
                                        referral: null
                                    })
                                }
                            }}
                        />
                        :
                        null
                }
                {this.state.referralContentPreview && false ?
                    <Modal className='modal-header-unpaid' isOpen={this.state.referralContentPreview} centered toggle={() => this.setState({ referralContentPreview: !this.state.referralContentPreview })} size="lg" >
                        <ModalHeader toggle={() => this.setState({ referralContentPreview: !this.state.referralContentPreview })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ referralContentPreview: !this.state.referralContentPreview })}><Isvg src={xIcon} /></button>}>
                            <div className='unpaid-header'>
                                <p>{'Referral preview'.translate(this.props.lang)}</p>


                            </div>
                        </ModalHeader>

                        <ModalBody>
                            <div className='unpaid-button-container'>
                                {
                                    this.state.referralContentPreview.referralIsPaid || (this.state.referralContentPreview.event && this.state.referralContentPreview.event.appointmentPaid) || (this.state.referralContentPreview.event && this.state.referralContentPreview.event.visitReasonPrice == 0) || this.state.referralContentPreview.questionaryPrice == 0 ?
                                        null
                                        :
                                        <button>
                                            <Isvg src={billInfoIcon} />
                                            {'UNPAID'.translate(this.props.lang)}
                                        </button>
                                }
                            </div>
                            {
                                this.state.referralContentPreview.priority ?
                                    <div className={
                                        this.state.referralContentPreview.priority == 1 ? 'priority-circle p-red nohover' :
                                            this.state.referralContentPreview.priority == 2 ? 'priority-circle p-orange nohover' :
                                                this.state.referralContentPreview.priority == 3 ? 'priority-circle p-yellow nohover' :
                                                    this.state.referralContentPreview.priority == 4 ? 'priority-circle p-black nohover' :
                                                        'priority-circle p-green nohover'
                                    }>
                                        {this.state.referralContentPreview.priority}
                                    </div>
                                    :
                                    null
                            }
                            <div dangerouslySetInnerHTML={{ __html: this.props.lang == 'se' ? this.state.referralContentPreview.referralContent.protectReferralFiles(this.props.uData ? this.props.uData.mediaToken : '', this.state.referralContentPreview._id) : this.state.referralContentPreview.referralContentEn ? this.state.referralContentPreview.referralContentEn.protectReferralFiles(this.props.uData ? this.props.uData.mediaToken : '', this.state.referralContentPreview._id) : this.state.referralContentPreview.referralContent.protectReferralFiles(this.props.uData ? this.props.uData.mediaToken : '', this.state.referralContentPreview._id) }} onChange={() => { }}></div>
                            {
                                this.state.referralContentPreview.revisitFormsArray && this.state.referralContentPreview.revisitFormsArray.length && this.state.referralContentPreview.revisitFormsArray[this.state.activeRevisitForm - 1] ?
                                    this.state.referralContentPreview.revisitFormsArray[this.state.activeRevisitForm - 1].referralData ?
                                        <div>
                                            <h6>{'Patient answers'.translate(this.props.lang)} <span style={{ marginLeft: 20, fontWeight: 600 }}>{this.state.referralContentPreview.revisitFormsArray[this.state.activeRevisitForm - 1].name}</span> </h6>
                                            <div dangerouslySetInnerHTML={{ __html: this.props.lang == 'se' ? this.state.referralContentPreview.revisitFormsArray[this.state.activeRevisitForm - 1].referralData.protectReferralFiles(this.props.uData ? this.props.uData.mediaToken : '', this.state.referralContentPreview._id) : this.state.referralContentPreview.revisitFormsArray[this.state.activeRevisitForm - 1].referralDataEn ? this.state.referralContentPreview.revisitFormsArray[this.state.activeRevisitForm - 1].referralDataEn.protectReferralFiles(this.props.uData ? this.props.uData.mediaToken : '', this.state.referralContentPreview._id) : this.state.referralContentPreview.revisitFormsArray[this.state.activeRevisitForm - 1].referralData.protectReferralFiles(this.props.uData ? this.props.uData.mediaToken : '', this.state.referralContentPreview._id) }}></div>

                                            {/* <div dangerouslySetInnerHTML={{ __html: this.state.referralContentPreview.revisitFormsArray[this.state.activeRevisitForm - 1].referralData.protectReferralFiles(this.props.uData ? this.props.uData.mediaToken : '', this.state.referralContentPreview._id) }}></div> */}
                                            {
                                                this.state.referralContentPreview.revisitFormsArray[this.state.activeRevisitForm - 1].referralTotalData ?
                                                    <div dangerouslySetInnerHTML={{ __html: this.props.lang == 'se' ? this.state.referralContentPreview.revisitFormsArray[this.state.activeRevisitForm - 1].referralTotalData.protectReferralFiles(this.props.uData ? this.props.uData.mediaToken : '', this.state.referralContentPreview._id) : this.state.referralContentPreview.revisitFormsArray[this.state.activeRevisitForm - 1].referralTotalDataEn ? this.state.referralContentPreview.revisitFormsArray[this.state.activeRevisitForm - 1].referralTotalDataEn.protectReferralFiles(this.props.uData ? this.props.uData.mediaToken : '', this.state.referralContentPreview._id) : this.state.referralContentPreview.revisitFormsArray[this.state.activeRevisitForm - 1].referralTotalData.protectReferralFiles(this.props.uData ? this.props.uData.mediaToken : '', this.state.referralContentPreview._id) }}></div>
                                                    // <div dangerouslySetInnerHTML={{ __html: this.state.referralContentPreview.revisitFormsArray[this.state.activeRevisitForm - 1].referralTotalData.protectReferralFiles(this.props.uData ? this.props.uData.mediaToken : '', this.state.referralContentPreview._id) }}></div>
                                                    :
                                                    null
                                            }

                                            {/* <div dangerouslySetInnerHTML={{ __html: this.state.referralContentPreview.revisitFormsArray[this.state.activeRevisitForm - 1].referralData }}></div>
                                            <div dangerouslySetInnerHTML={{ __html: this.state.referralContentPreview.revisitFormsArray[this.state.activeRevisitForm - 1].referralTotalData }}></div> */}
                                            <Button color='primary' onClick={() => { this.setState({ previewAnswersModal: this.state.referralContentPreview.revisitFormsArray[this.state.activeRevisitForm - 1] }) }}>{'Preview answers'.translate(this.props.lang)}</Button>
                                            <hr />
                                        </div>
                                        :
                                        <div>
                                            <h6>{'Patient answers'.translate(this.props.lang)} <span style={{ marginLeft: 20, fontWeight: 600 }}>{this.state.referralContentPreview.revisitFormsArray[this.state.activeRevisitForm - 1].name}</span> </h6>
                                            <p style={{ fontStyle: 'italic', fontSize: 14 }}>{'The patient did not fill out the form'.translate(this.props.lang)}</p>
                                            <hr />
                                        </div>

                                    :
                                    null
                            }
                            {
                                this.state.referralContentPreview && this.state.referralContentPreview.answers && this.state.referralContentPreview.questionaryData && this.state.referralContentPreview.questionaryData.items ?
                                    <div style={{ marginBottom: 10 }}>
                                        <Button color='primary' onClick={() => { this.setState({ previewAnswersReferralModal: this.state.referralContentPreview }) }}>{'Preview answers'.translate(this.props.lang)}</Button>
                                    </div>
                                    :
                                    null
                            }
                            <div className='patient-referral-preview-status-wrap'>
                                <div>
                                    <p> <strong>{'Status:'.translate(this.props.lang)}</strong> {humanReadable[this.state.referralContentPreview.status]}</p>
                                    {this.state.referralContentPreview.reason ?
                                        <p> <strong>{'Reason:'.translate(this.props.lang)}</strong> {this.state.referralContentPreview.reason}</p>
                                        :
                                        null}
                                    {this.state.referralContentPreview.patientReservedTimestamp ?
                                        <p> <strong>{'Scheduled time:'.translate(this.props.lang)}</strong> {this.props.getStringDateTs(this.state.referralContentPreview.patientReservedTimestamp, `${this.props.dateFormat} HH:mm`)/*moment.unix(this.state.referralContentPreview.patientReservedTimestamp).format(`${this.props.dateFormat} HH:mm`)*/}</p>
                                        :
                                        null}

                                    {
                                        this.state.referralContentPreview.status == 'patient-requested-another-time' ?
                                            <p> <strong>{'Requested time:'.translate(this.props.lang)}</strong> {this.props.getStringDateTs(this.state.referralContentPreview.patientRequestedTimestamp, `${this.props.dateFormat} HH:mm`)/*moment.unix(this.state.referralContentPreview.patientRequestedTimestamp).format(`${this.props.dateFormat} HH:mm`)*/}</p>

                                            :
                                            null
                                    }

                                </div>
                                {
                                    this.state.referralContentPreview.revisitFormsArray && this.state.referralContentPreview.revisitFormsArray.length ?
                                        <div className='doctor-preview-revisit-forms'>
                                            <div className='doctor-preview-revisit-forms-header'>
                                                {
                                                    this.state.activeRevisitForm ?
                                                        <span>{this.state.referralContentPreview.revisitFormsArray[this.state.activeRevisitForm - 1].name} <span>({this.state.activeRevisitForm}/{this.state.referralContentPreview.revisitFormsArray.length})</span></span>
                                                        :
                                                        <span>{this.state.referralContentPreview.revisitFormsArray[0].name} <span>(1/{this.state.referralContentPreview.revisitFormsArray.length})</span></span>
                                                }

                                            </div>
                                            <div className='buttons-next-prev-wrap'>
                                                <Button color='primary' disabled={this.state.activeRevisitForm && this.state.activeRevisitForm > 1 ? false : true} onClick={() => {
                                                    let activeRevisitForm = this.state.activeRevisitForm;
                                                    if (!activeRevisitForm) {
                                                        activeRevisitForm = 1;
                                                    }
                                                    if (activeRevisitForm > 1) {
                                                        activeRevisitForm--;
                                                    }
                                                    this.setState({ activeRevisitForm })
                                                }}>{'Previous'.translate(this.props.lang)}</Button>
                                                <Button color='primary' disabled={this.state.activeRevisitForm && this.state.activeRevisitForm < this.state.referralContentPreview.revisitFormsArray.length ? false : true} onClick={() => {
                                                    let activeRevisitForm = this.state.activeRevisitForm;
                                                    if (!activeRevisitForm) {
                                                        activeRevisitForm = 1;
                                                    }
                                                    if (activeRevisitForm < this.state.referralContentPreview.revisitFormsArray.length) {
                                                        activeRevisitForm++;
                                                    }
                                                    this.setState({ activeRevisitForm })
                                                }}>{'Next'.translate(this.props.lang)}</Button>
                                            </div>
                                        </div>
                                        :
                                        null

                                }

                            </div>


                            {
                                (this.props.uData && ((this.props.uData._id == this.state.referralContentPreview.recipientUser) || (this.state.referralContentPreview && this.state.referralContentPreview.additionalDoctors && this.state.referralContentPreview.additionalDoctors.filter(doc => doc == this.props.uData._id).length))) && this.state.referralContentPreview.conversations && this.state.referralContentPreview.conversations.length ?
                                    <div className='read-only-conversation-wrap'>
                                        <div style={{ marginBottom: 10 }}>
                                            {
                                                !this.state.showChat ?
                                                    <Button color='primary' onClick={() => this.setState({ showChat: true })}>{'See conversation'.translate(this.props.lang)}</Button>
                                                    :
                                                    <Button color='danger' onClick={() => this.setState({ showChat: false })}>{'Hide conversation'.translate(this.props.lang)}</Button>

                                            }
                                        </div>
                                        {
                                            this.state.showChat && this.state.referralContentPreview.conversations && this.state.referralContentPreview.conversations.map((conversation, i) => {
                                                if (conversation && conversation.messages && conversation.messages.length) {
                                                    return (
                                                        <div className="read-only-messages-wrap">

                                                            {
                                                                conversation.messages.map((message, idx) => {
                                                                    // console.log(message);
                                                                    return (
                                                                        <div className="read-only-message-wrap">
                                                                            <div className="read-only-message">
                                                                                <span>{message.senderName}:</span>
                                                                                {
                                                                                    message.healthCareReferral && !message.hideIcon ?
                                                                                        <div className='form-icon'>
                                                                                            <Isvg src={formIcon} />
                                                                                        </div>
                                                                                        :
                                                                                        null
                                                                                }
                                                                                {
                                                                                    message?.message?.indexOf('The patient filled out the form successfully') > -1 ?
                                                                                        <>
                                                                                            {'The patient filled out the form successfully'.translate(this.props.lang) + ' - '} &nbsp;
                                                                                            <div className='form-icon'>
                                                                                                <Isvg src={formIcon} />
                                                                                            </div>
                                                                                            {message.message.split('The patient filled out the form successfully - ')[1]}

                                                                                        </>

                                                                                        :
                                                                                        <>
                                                                                            {message.message}

                                                                                        </>


                                                                                }

                                                                                {
                                                                                    message.image ?
                                                                                        <a style={{ marginLeft: message.message ? 4 : 0 }} href={API_ENDPOINT + message.image} target='_blank'>{'Image'.translate(this.props.lang)}</a>
                                                                                        :
                                                                                        null
                                                                                }
                                                                                {
                                                                                    message.video ?
                                                                                        <a style={{ marginLeft: message.message ? 4 : 0 }} href={API_ENDPOINT + message.video} target='_blank'>{'Video'.translate(this.props.lang)}</a>
                                                                                        :
                                                                                        null
                                                                                }
                                                                                {
                                                                                    message.document && message.document.file ?
                                                                                        <a style={{ marginLeft: message.message ? 4 : 0 }} href={API_ENDPOINT + message.document.file} target='_blank'>{message.document && message.document.name ? message.document.name : 'Document'.translate(this.props.lang)}</a>
                                                                                        :
                                                                                        null
                                                                                }

                                                                            </div>
                                                                            {
                                                                                message.timestamp ?
                                                                                    <div className='message-time'>{this.props.getStringDateTs(message.timestamp, this.props.dateFormat + " HH:mm")/*moment.unix(message.timestamp).format(this.props.dateFormat + " HH:mm")*/}</div>
                                                                                    :
                                                                                    null
                                                                            }
                                                                        </div>
                                                                    )
                                                                })
                                                            }

                                                        </div>
                                                    )
                                                } else
                                                    return (
                                                        <div className="read-only-messages-wrap">
                                                            <h5>{'Conversation is empty'.translate(this.props.lang)}</h5>
                                                        </div>

                                                    )
                                            })
                                        }
                                    </div>
                                    :
                                    null
                            }


                        </ModalBody>
                        {this.state.referralContentPreview.status == 'waiting-for-approval' ?
                            <ModalFooter style={{ margin: 'unset', justifyContent: 'flex-end', display: 'flex', width: '100%' }} className="buttons-right-blue">

                                {!this.state.referralContentPreview.forwardRecipientClinic && !this.state.referralContentPreview.forward && this.props.uData && this.props.uData.profession && this.props.uData.profession[this.props.selectedGroup] && this.state.referralContentPreview.professionList && this.state.referralContentPreview.professionList.filter(profession => {
                                    if (typeof this.props.uData.profession[this.props.selectedGroup] !== 'string') {
                                        return this.props.uData.profession[this.props.selectedGroup].indexOf(profession) !== -1
                                    } else {
                                        return profession == this.props.uData.profession[this.props.selectedGroup]
                                    }
                                }).length ?
                                    <>
                                        <Button color="success" onClick={() => this.allowReferral()}><><i className="fa fa-check" />{'Allow'.translate(this.props.lang)}</></Button>
                                        <Button color="danger" onClick={() => this.setState({ disallowForm: this.state.referralContentPreview })}><i className="fa fa-close" /> {'Disallow'.translate(this.props.lang)} </Button>


                                    </>
                                    :
                                    null


                                }



                                <PrintHelper lang={this.props.lang} html={this.props.lang == 'se' ? this.state.referralContentPreview.referralContent : this.state.referralContentPreview.referralContentEn ? this.state.referralContentPreview.referralContentEn : this.state.referralContentPreview.referralContent} />
                                <Button color="primary" onClick={() => this.setState({ referralContentPreview: null })}> {'Close'.translate(this.props.lang)} </Button>

                            </ModalFooter>

                            :
                            this.state.referralContentPreview.status == 'patient-requested-another-time' ?
                                <ModalFooter style={{ margin: 'unset', justifyContent: 'flex-end', display: 'flex', width: '100%' }}>

                                    <Button color="success" onClick={() => this.acceptRequestedTime()}><i className="fa fa-check" /> {'Accept requested time'.translate(this.props.lang)}</Button>
                                    <Button color="danger" onClick={() => this.setState({ scheduleForm: this.state.referralContentPreview })}><i className="fa fa-close" /> {'Reject requested time'.translate(this.props.lang)} </Button>
                                    {/* <PrintHelper lang={this.props.lang} html={this.props.lang == 'se' ? this.state.referralContentPreview.referralContent : this.state.referralContentPreview.referralContentEn ? this.state.referralContentPreview.referralContentEn : this.state.referralContentPreview.referralContent} /> */}

                                    <Button color="primary" onClick={() => this.setState({ referralContentPreview: null })}> {'Close'.translate(this.props.lang)} </Button>

                                </ModalFooter>
                                :
                                this.state.referralContentPreview.status == 'allowed' || this.state.referralContentPreview.status == 'not-booked' || this.state.referralContentPreview.status == 'canceled-by-clinic' ?
                                    <ModalFooter style={{ margin: 'unset', justifyContent: 'flex-end', display: 'flex', width: '100%' }} className="buttons-right-blue">

                                        <Button color="primary" style={{ margin: 10 }} onClick={() => this.generateTimeReferral(this.state.referralContentPreview)}> {'ScheduleList'.translate(this.props.lang)} </Button>
                                        <PrintHelper lang={this.props.lang} html={this.props.lang == 'se' ? this.state.referralContentPreview.referralContent : this.state.referralContentPreview.referralContentEn ? this.state.referralContentPreview.referralContentEn : this.state.referralContentPreview.referralContent} />
                                        <Button color="primary" onClick={() => this.setState({ referralContentPreview: null })}> {'Close'.translate(this.props.lang)} </Button>



                                    </ModalFooter>
                                    :
                                    <ModalFooter style={{ margin: 'unset', justifyContent: 'flex-end', display: 'flex', width: '100%' }} className="buttons-right-blue">

                                        {
                                            this.state.referralContentPreview.dropIn ?
                                                <Button onClick={() => {
                                                    this.setState({
                                                        priorityModal: { ...this.state.referralContentPreview }
                                                    })
                                                }}>{'Set priority'.translate(this.props.lang)}</Button>
                                                :
                                                null
                                        }
                                        {
                                            (this.state.referralContentPreview.status == 'scheduled' || this.state.referralContentPreview.status === 'visit-rescheduled') && this.state.referralContentPreview.recipientUser == this.props.uData._id ? // || this.state.referralContentPreview.status == 'patient-rejected' || this.state.referralContentPreview.status == 'not-booked'
                                                <div className='red-button-wrap'>
                                                    <Button onClick={() => this.setState({ closeCase: true })} >{'Close case'.translate(this.props.lang)}</Button>

                                                </div>
                                                :
                                                null
                                        }
                                        {
                                            (this.state.referralContentPreview.status == 'revisit-scheduled' || this.state.referralContentPreview.status === 'visit-rescheduled') && this.state.referralContentPreview.closeCaseManually && this.state.referralContentPreview.recipientUser == this.props.uData._id ?
                                                <div className='red-button-wrap'>
                                                    <Button color='danger' onClick={() => this.setState({ closeCase: true })} >{'Close case'.translate(this.props.lang)}</Button>
                                                </div>
                                                :
                                                null
                                        }

                                        {
                                            (this.state.referralContentPreview.status == 'scheduled' || this.state.referralContentPreview.status === 'visit-rescheduled') && this.state.adminPermission ? // || this.state.referralContentPreview.status == 'patient-rejected' || this.state.referralContentPreview.status == 'not-booked'
                                                <Button color="primary" style={{ margin: 10 }} onClick={() => {
                                                    this.props[0].history.push({
                                                        pathname: '/my-data/referrals/schedule',
                                                        search: '?fromAllCases=true',
                                                        state: {
                                                            doctor: this.state.referralContentPreview.recipientUser,
                                                            day: new Date(this.state.referralContentPreview.patientReservedTimestamp * 1000),
                                                            step: 5,

                                                        }
                                                    })
                                                }}> {'Manage in calendar'.translate(this.props.lang)} </Button>
                                                :
                                                null
                                        }

                                        {
                                            this.state.referralContentPreview.status == 'waiting' && this.props.uData && this.props.uData.profession && this.props.uData.profession[this.props.selectedGroup] && this.state.referralContentPreview.professionList && this.state.referralContentPreview.professionList.filter(profession => {
                                                if (typeof this.props.uData.profession[this.props.selectedGroup] !== 'string') {
                                                    return this.props.uData.profession[this.props.selectedGroup].indexOf(profession) !== -1
                                                } else {
                                                    return profession == this.props.uData.profession[this.props.selectedGroup]
                                                }
                                            }).length ?
                                                <Button onClick={() => {
                                                    if (this.state.referralContentPreview && this.state.referralContentPreview.patient && this.state.referralContentPreview.patient.socialSecurityNumber == this.props.uData.socialSecurityNumber) {
                                                        this.setState({ messageInfo: 'Unable to pick up this case. Both participants are using the same account.'.translate(this.props.lang) })
                                                        return;
                                                    }
                                                    this.setState({ conversationModal: this.state.referralContentPreview, conversationForm: { name: this.state.referralContentPreview.patient.referralNumber + '-' + pad(this.state.referralContentPreview.conversations.length + 1, 2) } })
                                                }} color="success">{'Pick up'.translate(this.props.lang)}</Button>
                                                :
                                                null
                                        }
                                        {
                                            this.state.referralContentPreview.revisitFormsArray && this.state.referralContentPreview.revisitFormsArray.length && this.state.referralContentPreview.revisitFormsArray[this.state.activeRevisitForm - 1] ?
                                                <PrintHelper lang={this.props.lang} html={this.generateHtmlForPrint()} />

                                                :
                                                <PrintHelper lang={this.props.lang} html={this.props.lang == 'se' ? this.state.referralContentPreview.referralContent : this.state.referralContentPreview.referralContentEn ? this.state.referralContentPreview.referralContentEn : this.state.referralContentPreview.referralContent} />

                                        }
                                        {/* <PrintHelper lang={this.props.lang} html={this.props.lang == 'se' ? this.state.referralContentPreview.referralContent : this.state.referralContentPreview.referralContentEn ? this.state.referralContentPreview.referralContentEn : this.state.referral.referralContent} /> */}

                                        <Button color="primary" onClick={() => this.setState({ referralContentPreview: null })}> {'Close'.translate(this.props.lang)} </Button>

                                    </ModalFooter>

                        }

                    </Modal>

                    :
                    null
                }
                {
                    this.state.scheduleListReferral ?
                        <Modal isOpen={this.state.scheduleListReferral} centered>
                            <ModalHeader toggle={() => this.setState({ scheduleListReferral: !this.state.scheduleListReferral, doctorsList: [], listDoctor: null })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ scheduleListReferral: !this.state.scheduleListReferral, doctorsList: [], listDoctor: null })}>&times;</button>}>{'Schedule referral'.translate(this.props.lang)}</ModalHeader>

                            <ModalBody>

                                <FormGroup>

                                    <Label>{'Doctor'.translate(this.props.lang)}</Label>
                                    <Input type='select'
                                        disabled={!this.state.adminPermission}
                                        onChange={e => this.setState({ listDoctor: e.target.value == -1 ? null : e.target.value, loadingDoctor: true, scroll: false }, () => {
                                            this.getWorkingTime(this.state.listDoctor)
                                        })}
                                        value={this.state.listDoctor}
                                    >
                                        <option value={-1}>{'None'.translate(this.props.lang)}</option>

                                        {this.state.subAccounts?.filter(userObj => userObj.socialSecurityNumber !== this.state.scheduleListReferral?.patient?.socialSecurityNumber)?.sort((a, b) => {
                                            const nameA = a.name.toLowerCase();
                                            const nameB = b.name.toLowerCase();
                                            if (nameA < nameB) {
                                                return -1
                                            }
                                            if (nameA > nameB) {
                                                return 1
                                            }
                                        }).map(item => {
                                            return <option value={item._id}>
                                                {item.name} - {item.username}
                                            </option>
                                        })}

                                    </Input>

                                </FormGroup>
                                <FormGroup>

                                    <Label>{'Patient'.translate(this.props.lang)}</Label>
                                    <Input type='text' value={`${this.state.scheduleListReferral.patient.name}${" " + humanReadable[this.state.scheduleListReferral.status].translate(this.props.lang)}${this.state.scheduleListReferral.onlineVisit ? (' - ' + 'Online'.translate(this.props.lang)) : ''}`}>


                                    </Input>
                                </FormGroup>
                                <FormGroup>
                                    <DatePicker
                                        label={'Start date'.translate(this.props.lang)}
                                        value={this.state.scheduleListReferral.date}
                                        onChange={(e) => {
                                            let scheduleListReferral = this.state.scheduleListReferral;
                                            scheduleListReferral.date = e;
                                            this.setState({
                                                scheduleListReferral
                                            })
                                            if (Math.floor(new Date(this.state.scheduleListReferral.date + ' ' + this.state.scheduleListReferral.startTime) / 1000) > Math.floor(new Date(this.state.scheduleListReferral.endDate + ' ' + this.state.scheduleListReferral.endTime) / 1000)) {
                                                scheduleListReferral.endDate = this.state.scheduleListReferral.date
                                                this.setState({
                                                    scheduleListReferral
                                                })
                                            }
                                        }}
                                    />
                                </FormGroup>
                                <FormGroup className="timepicker-container timepicker-wide">
                                    <Label>{'Start time'.translate(this.props.lang)}</Label>
                                    &nbsp;

                                    <TimePickerV2
                                        getStringDateTs={this.props.getStringDateTs}
                                        format={'HH:mm'}
                                        value={this.state.scheduleListReferral.startTime}
                                        minValue={this.props.getStringDateTs(Math.floor(this.state.min.getTime() / 1000), 'HH:mm')} //moment.unix(this.state.min.getTime() / 1000).format("HH:mm")
                                        maxValue={this.props.getStringDateTs(Math.floor(this.state.max.getTime() / 1000 - 300), 'HH:mm')} // moment.unix(this.state.max.getTime() / 1000).format("HH:mm")
                                        flag={0}
                                        onChange={(time1, timeString) => {

                                            let scheduleListReferral = this.state.scheduleListReferral;
                                            scheduleListReferral.startTime = timeString

                                            let startTimeSplit = scheduleListReferral.startTime.split(":");
                                            let endTimeSplit = scheduleListReferral.endTime.split(":");

                                            let startTimeHourMinute = Number(startTimeSplit[0]) * 60 + Number(startTimeSplit[1]);
                                            let endTimeHourMinute = Number(endTimeSplit[0]) * 60 + Number(endTimeSplit[1]);
                                            // this.setState({ scheduleListReferral })
                                            if (startTimeHourMinute < endTimeHourMinute) {
                                                this.setState({ scheduleListReferral })
                                            } else {
                                                scheduleListReferral.endTime = Math.trunc((Number(this.state.scheduleListReferral.startTime.split(':')[0]) * 60 + Number(this.state.scheduleListReferral.startTime.split(':')[1]) + 5) / 60) + ":" + (Number(this.state.scheduleListReferral.startTime.split(':')[0]) * 60 + Number(this.state.scheduleListReferral.startTime.split(':')[1]) + 5) % 60
                                                const padded = scheduleListReferral?.endTime?.split?.(':');
                                                if (padded && Array.isArray(padded) && padded.length && isStringAValidNumber(padded[0]) && isStringAValidNumber(padded[1])) {
                                                    const h = padded[0]?.padStart?.(2, '0');
                                                    const m = padded[1]?.padStart?.(2, '0');
                                                    if (h && m) scheduleListReferral.endTime = `${h}:${m}`;
                                                }
                                                this.setState({
                                                    scheduleListReferral
                                                })
                                            }

                                        }}
                                    />
                                    {/* <TimePicker
                                        getStringDateTs={this.props.getStringDateTs}
                                        format={'HH:mm'}
                                        value={moment(new Date(this.state.scheduleListReferral.date.replace(/-/g, "/") + " " + this.state.scheduleListReferral.startTime))}
                                        minValue={this.props.getStringDateTs(Math.floor(this.state.min.getTime() / 1000), 'HH:mm')} //moment.unix(this.state.min.getTime() / 1000).format("HH:mm")
                                        maxValue={this.props.getStringDateTs(Math.floor(this.state.max.getTime() / 1000), 'HH:mm')} // moment.unix(this.state.max.getTime() / 1000).format("HH:mm")
                                        flag={0}
                                        onChange={(time1, timeString) => {

                                            let scheduleListReferral = this.state.scheduleListReferral;
                                            scheduleListReferral.startTime = timeString

                                            let startTimeSplit = scheduleListReferral.startTime.split(":");
                                            let endTimeSplit = scheduleListReferral.endTime.split(":");

                                            let startTimeHourMinute = Number(startTimeSplit[0]) * 60 + Number(startTimeSplit[1]);
                                            let endTimeHourMinute = Number(endTimeSplit[0]) * 60 + Number(endTimeSplit[1]);
                                            this.setState({ scheduleListReferral })

                                        }}
                                        allowClear={false}
                                    /> */}
                                </FormGroup>
                                <FormGroup className="timepicker-container timepicker-wide">
                                    <Label>{'End time'.translate(this.props.lang)}</Label>
                                    &nbsp;
                                    <TimePickerV2
                                        getStringDateTs={this.props.getStringDateTs}
                                        format={'HH:mm'}
                                        value={this.state.scheduleListReferral.endTime}
                                        minValue={Math.trunc((Number(this.state.scheduleListReferral.startTime.split(':')[0]) * 60 + Number(this.state.scheduleListReferral.startTime.split(':')[1]) + 5) / 60) + ":" + (Number(this.state.scheduleListReferral.startTime.split(':')[0]) * 60 + Number(this.state.scheduleListReferral.startTime.split(':')[1]) + 5) % 60}
                                        maxValue={this.props.getStringDateTs(Math.floor(this.state.max.getTime() / 1000), 'HH:mm')} // moment.unix(this.state.max.getTime() / 1000).format("HH:mm")
                                        flag={0}
                                        onChange={(time1, timeString) => {

                                            let scheduleListReferral = this.state.scheduleListReferral;
                                            scheduleListReferral.endTime = timeString

                                            let startTimeSplit = scheduleListReferral.startTime.split(":");
                                            let endTimeSplit = scheduleListReferral.endTime.split(":");

                                            let startTimeHourMinute = Number(startTimeSplit[0]) * 60 + Number(startTimeSplit[1]);
                                            let endTimeHourMinute = Number(endTimeSplit[0]) * 60 + Number(endTimeSplit[1]);

                                            if (startTimeHourMinute < endTimeHourMinute) {
                                                this.setState({ scheduleListReferral })
                                            }
                                        }}
                                    />
                                    {/* <TimePicker
                                        getStringDateTs={this.props.getStringDateTs}
                                        format={'HH:mm'}
                                        value={moment(new Date(this.state.scheduleListReferral.date.replace(/-/g, "/") + " " + this.state.scheduleListReferral.endTime))}
                                        minValue={Math.trunc((Number(this.state.scheduleListReferral.startTime.split(':')[0]) * 60 + Number(this.state.scheduleListReferral.startTime.split(':')[1]) + 5) / 60) + ":" + (Number(this.state.scheduleListReferral.startTime.split(':')[0]) * 60 + Number(this.state.scheduleListReferral.startTime.split(':')[1]) + 5) % 60}
                                        maxValue={this.props.getStringDateTs(Math.floor(this.state.max.getTime() / 1000), 'HH:mm')} // moment.unix(this.state.max.getTime() / 1000).format("HH:mm")
                                        flag={0}
                                        onChange={(time1, timeString) => {

                                            let scheduleListReferral = this.state.scheduleListReferral;
                                            scheduleListReferral.endTime = timeString

                                            let startTimeSplit = scheduleListReferral.startTime.split(":");
                                            let endTimeSplit = scheduleListReferral.endTime.split(":");

                                            let startTimeHourMinute = Number(startTimeSplit[0]) * 60 + Number(startTimeSplit[1]);
                                            let endTimeHourMinute = Number(endTimeSplit[0]) * 60 + Number(endTimeSplit[1]);

                                            if (startTimeHourMinute < endTimeHourMinute) {
                                                this.setState({ scheduleListReferral })
                                            }
                                        }}
                                        allowClear={false}
                                    /> */}
                                </FormGroup>
                                {
                                    this.state.listDoctor ?
                                        <FormGroup>
                                            <Label>{'Note'.translate(this.props.lang)}</Label>
                                            <Input type='textarea'
                                                name="note"
                                                style={{ height: '150px' }}
                                                onChange={(e) => {
                                                    let scheduleListReferral = this.state.scheduleListReferral;
                                                    scheduleListReferral.patientNote = striptags(e.target.value);
                                                    this.setState({
                                                        scheduleListReferral
                                                    })
                                                }}
                                                value={this.state.scheduleListReferral.patientNote}
                                            />
                                        </FormGroup>
                                        :
                                        null
                                }
                                {
                                    this.state.listDoctor ?
                                        <FormGroup>
                                            <Button onClick={() => this.setState({
                                                doctorsModal: 5
                                            })} color="primary">
                                                {'Add caregiver'.translate(this.props.lang)}
                                            </Button>
                                        </FormGroup>

                                        :

                                        null
                                }



                                {
                                    this.state.scheduleListReferral.controlSchedule ?
                                        <Button color='primary' disabled={this.state.listDoctor ? false : true} onClick={() => this.scheduleControl({ ...this.state.scheduleListReferral, rescheduleRevisit: true })}>{'Schedule referral'.translate(this.props.lang)}</Button>
                                        :
                                        <Button color='primary' disabled={this.state.listDoctor ? false : true} onClick={() => {
                                            if (this.state.scheduleListReferral.status === 'requested-new-appointment') {
                                                this.listScheduleReferral({ ...this.state.scheduleListReferral, rescheduleReferral: true })
                                            } else {
                                                this.listScheduleReferral({ ...this.state.scheduleListReferral, rescheduleReferral: false })
                                            }
                                        }}>{'Schedule referral'.translate(this.props.lang)}</Button>

                                }
                            </ModalBody>
                        </Modal>
                        :
                        null
                }
                {
                    this.state.doctorsModal ?
                        <Modal size="lg" isOpen={this.state.doctorsModal} centered toggle={() => this.setState({ doctorsModal: !this.state.doctorsModal })} >
                            <ModalHeader toggle={() => this.setState({ doctorsModal: !this.state.doctorsModal })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ doctorsModal: !this.state.doctorsModal })}><Isvg src={xIcon} /></button>}>{'Choose doctors'.translate(this.props.lang)}</ModalHeader>

                            <ModalBody className="run-modal">
                                <FormGroup className="form-group-checkbox">
                                    {
                                        this.state.doctors && this.state.doctors.length && this.state.doctors.filter((item) => {
                                            if (this.state.listDoctor && item._id != this.state.listDoctor) {
                                                return true;
                                            } else {
                                                return false;
                                            }
                                        }).length ?
                                            this.state.doctors.map((item, idx) => {
                                                if (item._id != this.state.listDoctor)
                                                    return (
                                                        <Checkbox
                                                            checked={this.state.doctorsList && this.state.doctorsList.filter(doctor => doctor == item._id).length}
                                                            onChange={(e) => {

                                                                let doctorsList = this.state.doctorsList;
                                                                let exists = false;
                                                                for (let i = 0; i < doctorsList.length; i++) {
                                                                    if (doctorsList[i] == item._id) {
                                                                        exists = true;
                                                                    }
                                                                }
                                                                if (!exists) {
                                                                    doctorsList.push(item._id)
                                                                } else {
                                                                    let index = doctorsList?.indexOf(item._id);
                                                                    if (index > -1) {
                                                                        doctorsList.splice(index, 1);
                                                                    }
                                                                }
                                                                this.setState({ doctorsList })


                                                            }}
                                                            label={item.name} />
                                                    )
                                            })
                                            :
                                            "The doctor's list is empty".translate(this.props.lang)
                                    }
                                </FormGroup>


                            </ModalBody>
                            <ModalFooter className='buttons-right-blue'>
                                {
                                    this.state.doctors && this.state.doctors.length && this.state.doctors.filter((item) => {
                                        if (this.props.uData._id && item._id != this.props.uData._id) {
                                            return true;
                                        } else {
                                            return false;
                                        }
                                    }).length ?
                                        <Button color='primary'
                                            // disabled={this.state.doctorsList && this.state.doctorsList.length > 0 ? false : true}
                                            onClick={() =>
                                                this.checkDoctorsAvailable(this.state.doctorsList, this.state.doctorsModal)
                                            }>{'Save'.translate(this.props.lang)}</Button>
                                        :
                                        null
                                }
                                <Button color='primary' onClick={() => {

                                    this.setState({ doctorsModal: false })
                                }
                                }>{"Close".translate(this.props.lang)}</Button>
                            </ModalFooter>
                        </Modal>
                        :
                        null
                }
                {
                    this.state.disallowForm ?
                        <FormBuilder selectedGroup={this.props.selectedGroup} selectedClinic={this.props.selectedClinic} countryTwoLetter={this.props.countryTwoLetter} country={this.props.country} dateFormat={this.props.dateFormat}
                            getStringDateTs={this.props.getStringDateTs}
                            lang={this.props.lang}
                            onSubmit={(data) => this.disallowReferral(data)}
                            isOpen={this.state.disallowForm}
                            toggle={() => this.setState({ disallowForm: null })}
                            title={'Disallow referral'.translate(this.props.lang)}
                            buttonText={'Submit'.translate(this.props.lang)}
                            fields={[
                                {
                                    type: 'row',
                                    children: [
                                        {
                                            type: 'col',
                                            width: {
                                                lg: 12,
                                                sm: 12,
                                                xs: 12
                                            },
                                            children: [
                                                {
                                                    type: 'textarea',
                                                    name: 'reason',
                                                    label: 'Give reason'.translate(this.props.lang),
                                                    validate: [required('Reason is required!'.translate(this.props.lang))]
                                                },
                                            ]
                                        },

                                    ]
                                }
                            ]}
                        ></FormBuilder>
                        :
                        null
                }
                {this.state.scheduleReferral ?
                    <Modal isOpen={this.state.scheduleReferral} centered>
                        {/* <ModalHeader style={{ margin: 'auto' }}>{'Schedule case'.translate(this.props.lang)}</ModalHeader> */}
                        <ModalBody className='delete-modal'>
                            {/* <Isvg src={QuestionMark} /> */}
                            <p className='message'>{'Do you want to schedule the selected referral?'.translate(this.props.lang)}</p>
                        </ModalBody>
                        <ModalFooter className='buttons-right-blue'>
                            <Button color='primary' style={{ margin: 10, minWidth: 100 }} onClick={() => {
                                this.props[0].history.push(`/my-data/referrals/working-hours`)
                            }}>{'Yes'.translate(this.props.lang)}</Button>
                            <Button color='primary' style={{ margin: 10, minWidth: 100 }} onClick={() => this.setState({
                                scheduleReferral: false
                            })}>{'No'.translate(this.props.lang)}</Button>
                        </ModalFooter>
                    </Modal>
                    : null}

                {
                    this.state.allowForm ?
                        <FormBuilder selectedGroup={this.props.selectedGroup} selectedClinic={this.props.selectedClinic} countryTwoLetter={this.props.countryTwoLetter} country={this.props.country} dateFormat={this.props.dateFormat}
                            getStringDateTs={this.props.getStringDateTs}
                            lang={this.props.lang}
                            onSubmit={(data) => this.allowReferral(data)}
                            isOpen={this.state.allowForm}
                            toggle={() => this.setState({ allowForm: null })}
                            title={'Allow referral'.translate(this.props.lang)}
                            buttonText={'Submit'.translate(this.props.lang)}
                            fields={[
                                {
                                    type: 'row',
                                    children: [
                                        {
                                            type: 'col',
                                            width: {
                                                lg: 6,
                                                sm: 12,
                                                xs: 12
                                            },
                                            children: [
                                                {
                                                    type: 'date',
                                                    name: 'date',
                                                    label: 'Date'.translate(this.props.lang),
                                                    validate: [required('Date is required!'.translate(this.props.lang))]
                                                },
                                            ]
                                        },
                                        {
                                            type: 'col',
                                            width: {
                                                lg: 6,
                                                sm: 12,
                                                xs: 12
                                            },
                                            children: [
                                                {
                                                    type: 'time',
                                                    name: 'time',
                                                    label: 'Time'.translate(this.props.lang),
                                                    validate: [required('Time is required!'.translate(this.props.lang))]
                                                },
                                            ]
                                        },
                                    ]
                                }
                            ]}
                        ></FormBuilder>
                        :
                        null
                }
                {
                    this.state.wrongDate ?
                        <Modal isOpen={this.state.wrongDate} centered>
                            {/* <ModalHeader style={{ margin: 'auto' }}>
                                {'Warning'.translate(this.props.lang)}
                            </ModalHeader> */}
                            <ModalBody className="delete-modal">
                                {/* <img src={warrning} /> */}
                                <p style={{ marginTop: 10 }}>{'You can not pick date form the past'.translate(this.props.lang)}</p>
                            </ModalBody>
                            <ModalFooter className="buttons-right-blue">
                                <Button style={{ minWidth: 100 }} color='primary' onClick={() => this.setState({ wrongDate: !this.state.wrongDate })}>{'Pick again'.translate(this.props.lang)}</Button>
                            </ModalFooter>
                        </Modal>
                        : null
                }

                {this.state.closeCase ?
                    <Modal isOpen={this.state.closeCase} centered>
                        {/* <ModalHeader style={{ margin: 'auto' }}>{'Warning'.translate(this.props.lang)}</ModalHeader> */}
                        <ModalBody className="delete-modal">
                            {/* <img src={warrning} /> */}
                            <p className="message">{`Are you sure you want to close the case?`.translate(this.props.lang)}</p>
                        </ModalBody>
                        <ModalFooter className='buttons-right-blue'>
                            <Button color="primary" style={{ margin: 10, minWidth: 100 }} onClick={() => this.setState({ closeCase: false }, () => this.closeReferral())}>{'Yes'.translate(this.props.lang)}</Button>
                            <Button color="primary" style={{ margin: 10, minWidth: 100 }} onClick={() => this.setState({ closeCase: false })}>{'No'.translate(this.props.lang)}</Button>

                        </ModalFooter>
                    </Modal>
                    : null}
                {
                    this.state.deleteCase ?
                        <Modal isOpen={this.state.deleteCase} centered>
                            <ModalBody className="delete-modal">
                                <p className="message">{`Are you sure you want to delete the case?`.translate(this.props.lang)}</p>
                            </ModalBody>
                            <ModalFooter className='buttons-right-blue'>
                                <Button color="primary" style={{ margin: 10, minWidth: 100 }} onClick={() => this.setState({ deleteCase: false }, () => this.deleteReferral())}>{'Yes'.translate(this.props.lang)}</Button>
                                <Button color="primary" style={{ margin: 10, minWidth: 100 }} onClick={() => this.setState({ deleteCase: false })}>{'No'.translate(this.props.lang)}</Button>

                            </ModalFooter>
                        </Modal>
                        : null
                }

                {this.state.formInfo ?
                    <FormBuilder
                        countryTwoLetter={this.props.countryTwoLetter}
                        country={this.props.country}
                        dateFormat={this.props.dateFormat}
                        getStringDateTs={this.props.getStringDateTs}
                        onSubmit={(data) => {
                            this.savePatientInfo(data)
                        }}
                        initialValues={this.state.formInfo}
                        size="lg"
                        isOpen={this.state.formInfo}
                        toggle={() => this.setState({ formInfo: null })}
                        title={'Important information'.translate(this.props.lang)}
                        _saving={this.state._saving}
                        buttonConfirmText={'Saved'.translate(this.props.lang)}
                        buttonText={'Save'.translate(this.props.lang)}

                        lang={this.props.lang}
                        fields={[
                            {
                                type: 'row',
                                children: [
                                    {
                                        type: 'col',
                                        width: {
                                            lg: 6,
                                            sm: 12,
                                            xs: 12
                                        },
                                        children: [
                                            {
                                                type: 'textarea',
                                                name: 'infection',
                                                label: 'Infection'.translate(this.props.lang),
                                                height: 100

                                            },
                                        ]
                                    },
                                    {
                                        type: 'col',
                                        width: {
                                            lg: 6,
                                            sm: 12,
                                            xs: 12
                                        },
                                        children: [
                                            {
                                                type: 'textarea',
                                                name: 'allergies',
                                                label: 'Allergies'.translate(this.props.lang),
                                                height: 100

                                            },
                                        ]
                                    },




                                ]
                            }
                        ]}
                    ></FormBuilder>
                    :
                    null
                }

                {
                    this.state.newAccount ?
                        <LinkNewAccoutn
                            {...this.props}
                            isOpen={this.state.newAccount}
                            haveValidation={true}
                            parentID={this.state.patient._id}
                            onClose={() => {
                                this.setState({
                                    newAccount: false
                                }, () => {
                                    this.setState({
                                        userConnected: true
                                    })
                                })
                            }}
                        />
                        :
                        null
                }
                {
                    this.state.linkedAccoutns ?
                        <LinkedAccoutns
                            {...this.props}
                            userConnected={this.state.userConnected}
                            size='lg'
                            isOpen={this.state.linkedAccoutns}
                            patientID={this.state.patient._id}
                            patient={this.state.patient}
                            newAccount={(item) => {
                                this.setState({
                                    newAccount: item
                                })
                            }}
                            openPatient={(social) => {
                                this.setState({
                                    linkedAccoutns: false
                                }, () => {
                                    this.searchPatient(social, 'linked')
                                    // this.updateParams('page',0, true)
                                })
                            }}
                            onClose={() => {
                                this.setState({
                                    linkedAccoutns: false
                                })
                            }}
                            listUpdate={() => {
                                this.setState({
                                    userConnected: false
                                })
                            }}
                        />
                        :
                        null
                }
                {
                    this.state.changedTab ?
                        <Modal isOpen={this.state.changedTab} centered>
                            {/* <ModalHeader style={{ margin: 'auto' }}>{'Warning'.translate(this.props.lang)}</ModalHeader> */}
                            <ModalBody className="delete-modal">
                                {/* <img src={warrning} /> */}
                                <p className="message">{'Your page contains changes that have not been saved. If you leave the page without saving, these changes will be lost.'.translate(this.props.lang)}</p>
                            </ModalBody>
                            <ModalFooter className='buttons-right-blue'>
                                <Button color="primary" style={{ margin: 10, minWidth: 100 }} onClick={() => {
                                    if (this.state.tabActive == 4) {
                                        this.setState({ saveMedicine: this.state.changedTab })

                                    } else {
                                        this.setState({ saveTestResults: this.state.changedTab })

                                    }

                                }}>{'Save and exit'.translate(this.props.lang)}</Button>


                                <Button color="primary" style={{ margin: 10, minWidth: 100 }} onClick={() => this.setState({ showForm: null }, () => {
                                    this.setState({
                                        showForm: true
                                    }, () => {
                                        let obj = { ...this.state.changedTab, changedTab: null }
                                        this.setState(obj)
                                    })

                                })}>{'Exit without saving'.translate(this.props.lang)}</Button>
                                <Button color="primary" style={{ margin: 10, minWidth: 100 }} onClick={() => this.setState({ showForm: null }, () => {
                                    this.setState({
                                        showForm: true
                                    }, () => {
                                        this.setState({ changedTab: null })
                                    })

                                })}>{'Cancel'.translate(this.props.lang)}</Button>

                            </ModalFooter>
                        </Modal>
                        : null
                }

                {/* {
                    this.state.changeMedicine ?
                        <Modal isOpen={this.state.changeMedicine} centered>
                            <ModalHeader style={{ margin: 'auto' }}>{'Warning'.translate(this.props.lang)}</ModalHeader>
                            <ModalBody className="delete-modal">
                                <img src={warrning} />
                                <p className="message">{'Your page contains changes that have not been saved. If you leave the page without saving, these changes will be lost.'.translate(this.props.lang)}</p>
                            </ModalBody>
                            <ModalFooter className='buttons-right-blue'>
                                <Button color="primary" style={{ margin: 10 }} onClick={() => {

                                    this.setState({ saveMedicine: this.state.changeMedicine })

                                }}>{'Save and exit'.translate(this.props.lang)}</Button>


                                <Button color="primary" style={{ margin: 10 }} onClick={() => this.setState({ showForm: null }, () => {
                                    this.setState({
                                        showForm: true
                                    }, () => {
                                        let obj = { ...this.state.changeMedicine, changeMedicine: null }
                                        this.setState(obj)
                                    })

                                })}>{'Exit without saving'.translate(this.props.lang)}</Button>
                                <Button color="primary" style={{ margin: 10 }} onClick={() => this.setState({ showForm: null }, () => {
                                    this.setState({
                                        showForm: true
                                    }, () => {
                                        this.setState({ changeMedicine: null })
                                    })

                                })}>{'Cancel'.translate(this.props.lang)}</Button>

                            </ModalFooter>
                        </Modal>
                        : null
                } */}

                {
                    this.state.priorityModal ?
                        <Modal isOpen={this.state.priorityModal} centered >

                            <ModalBody>
                                <div className='priority-circle-row'>
                                    <div className={this.state.priorityModal.priority == 1 ? 'priority-circle p-red red-active' : 'priority-circle p-red'} onClick={() => {
                                        let priorityModal = this.state.priorityModal
                                        priorityModal.priority = 1
                                        this.setState({
                                            priorityModal
                                        })
                                    }}>1</div>
                                    <div className={this.state.priorityModal.priority == 2 ? 'priority-circle p-orange orange-active' : 'priority-circle p-orange'} onClick={() => {
                                        let priorityModal = this.state.priorityModal
                                        priorityModal.priority = 2
                                        this.setState({
                                            priorityModal
                                        })
                                    }}>2</div>
                                    <div className={this.state.priorityModal.priority == 3 ? 'priority-circle p-yellow yellow-active' : 'priority-circle p-yellow'} onClick={() => {
                                        let priorityModal = this.state.priorityModal
                                        priorityModal.priority = 3
                                        this.setState({
                                            priorityModal
                                        })
                                    }}>3</div>
                                    <div className={this.state.priorityModal.priority == 4 ? 'priority-circle p-black black-active' : 'priority-circle p-black'} onClick={() => {
                                        let priorityModal = this.state.priorityModal
                                        priorityModal.priority = 4
                                        this.setState({
                                            priorityModal
                                        })
                                    }}>4</div>
                                    <div className={this.state.priorityModal.priority == 5 ? 'priority-circle p-green green-active' : 'priority-circle p-green'} onClick={() => {
                                        let priorityModal = this.state.priorityModal
                                        priorityModal.priority = 5
                                        this.setState({
                                            priorityModal
                                        })
                                    }}>5</div>
                                </div>
                            </ModalBody>
                            <ModalFooter className='buttons-right-blue'>
                                <Button onClick={() => {
                                    this.priorityForDropIn(this.state.priorityModal)
                                    this.setState({
                                        priorityModal: null
                                    })
                                }}>{'Confirm'.translate(this.props.lang)}</Button>
                                <Button color='primary'
                                    disabled={!this.state.priorityModal.priority}
                                    onClick={() => {
                                        let priorityModal = this.state.priorityModal
                                        priorityModal.priority = null;
                                        this.setState({
                                            priorityModal
                                        })
                                    }}>{'Remove'.translate(this.props.lang)}</Button>
                                <Button onClick={() => {
                                    this.setState({
                                        priorityModal: null
                                    })
                                }}>{'Cancel'.translate(this.props.lang)}</Button>
                            </ModalFooter>
                        </Modal>
                        : null
                }

                {this.props.changeLinkModal ?
                    <Modal isOpen={this.props.changeLinkModal} centered>
                        {/* <ModalHeader style={{ margin: 'auto' }}>{'Warning'.translate(this.props.lang)}</ModalHeader> */}
                        <ModalBody className="delete-modal">
                            {/* <img src={warrning} /> */}
                            <p className="message">{'Your page contains changes that have not been saved. If you leave the page without saving, these changes will be lost.'.translate(this.props.lang)}</p>
                        </ModalBody>
                        <ModalFooter className='buttons-right-blue'>
                            <Button color="primary" style={{ margin: 10, minWidth: 100 }} onClick={() => {
                                if (this.state.tabActive == 4) {
                                    this.setState({ saveMedicine: true })
                                } else {
                                    this.setState({ saveTestResults: true })
                                }


                            }}>{'Save and exit'.translate(this.props.lang)}</Button>


                            <Button color="primary" style={{ margin: 10, minWidth: 100 }} onClick={() => this.setState({ showForm: null }, () => {
                                this.setState({
                                    showForm: true
                                }, () => {
                                    if (this.props.changeLinkModal?.indexOf('group|') != -1) {
                                        this.props.changeGroup(this.props.changeLinkModal.split('|')[1])
                                    } else if (this.props.changeLinkModal?.indexOf('clinic|') != -1) {
                                        this.props.changeClinic(this.props.changeLinkModal.split('|')[1]);
                                    } else
                                        this.props[0].history.push(this.props.changeLinkModal)
                                    this.props.abortAction()
                                })

                            })}>{'Exit without saving'.translate(this.props.lang)}</Button>
                            <Button color="primary" style={{ margin: 10, minWidth: 100 }} onClick={() => this.setState({ showForm: null }, () => {
                                this.setState({
                                    showForm: true
                                }, () => {
                                    this.props.abortAction()
                                })

                            })}>{'Cancel'.translate(this.props.lang)}</Button>

                        </ModalFooter>
                    </Modal>
                    : null}


                {this.state.referralNote ?
                    <Modal isOpen={this.state.referralNote} centered>
                        <ModalHeader style={{ margin: 'auto' }}>{'Note'.translate(this.props.lang)}</ModalHeader>
                        <ModalBody className="delete-modal">
                            <Input type="textarea" style={{ height: 100 }} value={this.state.referralNote.note} onChange={(e) => {
                                let referralNote = this.state.referralNote;
                                referralNote.note = e.target.value;
                                this.setState({ referralNote })
                            }} />
                        </ModalBody>
                        <ModalFooter className='buttons-right-blue'>
                            <Button color="primary" style={{ margin: 10 }} onClick={() => {
                                this.updateReferralNote(this.state.referralNote)
                            }}>{'Save'.translate(this.props.lang)}</Button>

                            <Button color="primary" style={{ margin: 10 }} onClick={() => this.setState({ referralNote: null })

                            }>{'Close'.translate(this.props.lang)}</Button>

                        </ModalFooter>
                    </Modal>


                    : null}

                {
                    this.state.twoFactorAuthenticationModal && this.state.patient
                        ? <Modal
                            className='twofa-modal'
                            isOpen={this.state.twoFactorAuthenticationModal && this.state.patient}
                            toggle={() => this.setState(prev => ({
                                ...prev,
                                twoFactorAuthenticationModal: !prev?.twoFactorAuthenticationModal,
                                twoFactorAuthenticationPhoneNumber: '',
                                usersPhoneNumber: '',
                            }))}
                            backdrop={true}
                            centered
                        >
                            <ModalHeader>
                                {'Two Factor Authentication'.translate(this.props.lang)}
                                <div className='button-elements-tooltip'>
                                    <Isvg src={infoIcon} width={26} height={26} />
                                    <div className='tooltip-text'>{'Login link is sent to the users phone number and email. The link is valid for 7 days.'.translate(this.props.lang)}</div>
                                </div>
                            </ModalHeader>
                            <ModalBody>
                                <div className='twofa-modal-status' style={{ marginBottom: 5 }}>
                                    <div style={{ fontWeight: '600' }}>{'2FA Status'.translate(this.props.lang)}:</div>
                                    {
                                        this.state.patient?.twoFactorAuthentication?.[this.props.selectedGroup]?.enabled
                                            ? <>
                                                <div style={{ color: 'green' }}>{'Enabled'.translate(this.props.lang)}</div>
                                                <Button
                                                    color='danger'
                                                    onClick={() => this.change2FAStatus(this.state.patient, 'disable', false)}
                                                >
                                                    {'Disable'.translate(this.props.lang)}
                                                </Button>
                                            </>
                                            : <>
                                                <div style={{ color: 'red' }}>{'Disabled'.translate(this.props.lang)}</div>
                                                <Button
                                                    color='success'
                                                    onClick={() => this.change2FAStatus(this.state.patient, 'enable', false)}
                                                >
                                                    {'Enable'.translate(this.props.lang)}
                                                </Button>
                                            </>
                                    }
                                </div>

                                <div className='twofa-modal-status' style={{ marginTop: 5, marginBottom: 5 }}>
                                    <div style={{ fontWeight: '600' }}>{'Account Status'.translate(this.props.lang)}:</div>
                                    <div>{
                                        this.state.patient?.twoFactorAuthentication?.[this.props.selectedGroup]?.enabled
                                            ? (this.state.patient?.twoFactorAuthentication?.[this.props.selectedGroup]?.emailIsVerified && this.state.patient?.twoFactorAuthentication?.[this.props.selectedGroup]?.phoneIsVerified && this.state.patient?.twoFactorAuthentication?.[this.props.selectedGroup]?.passwordIsSet)
                                                ? 'Activated'.translate(this.props.lang)
                                                : 'Not Activated'.translate(this.props.lang)
                                            : (!this.state.patient?.userData?.name || !this.state.patient?.email || !this.state.patient?.phone || !this.state.patient?.street || !this.state.patient?.city || !this.state.patient?.zip)
                                                ? 'Not Activated'.translate(this.props.lang)
                                                : 'Activated'.translate(this.props.lang)
                                    }</div>
                                </div>

                                {this.state.patient?.twoFactorAuthentication?.[this.props.selectedGroup]?.enabled
                                    ? <div
                                        style={{ marginTop: 5, marginBottom: 5 }}
                                    >
                                        <PhoneField
                                            countryTwoLetter={this.props.countryTwoLetter}
                                            country={this.props.country}
                                            label={'Patients phone number'.translate(this.props.lang)}
                                            value={this.state.twoFactorAuthenticationPhoneNumber}
                                            onChange={e => {
                                                this.setState({ twoFactorAuthenticationPhoneNumber: e?.trim?.()?.replace(/ /g, '')?.replace('+', '') })
                                            }}
                                        />
                                        <Button
                                            color='primary'
                                            disabled={!this.state.twoFactorAuthenticationPhoneNumber?.trim?.()}
                                            onClick={() => this.change2FAStatus(this.state.patient, 'send-link', true)}
                                        >
                                            {this.state.twoFactorAuthenticationPhoneNumber !== this.state.usersPhoneNumber
                                                ? 'Save new number and send login link'.translate(this.props.lang)
                                                : 'Send login link'.translate(this.props.lang)}
                                        </Button>
                                    </div>
                                    : null
                                }
                            </ModalBody>
                            <ModalFooter style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Button
                                    color='primary'
                                    onClick={() => this.setState({ twoFactorAuthenticationModal: false, twoFactorAuthenticationPhoneNumber: '', usersPhoneNumber: '' })}
                                    style={{ minWidth: 100 }}
                                >
                                    {'Close'.translate(this.props.lang)}
                                </Button>
                            </ModalFooter>
                        </Modal> : null
                }
                {
                    this.state.twoFactorAuthenticationMessage ?
                        <InfoModal
                            isOpen={this.state.twoFactorAuthenticationMessage}

                            toggle={() => this.setState({ twoFactorAuthenticationMessage: null })}
                            header={'Info'.translate(this.props.lang)}
                            info={this.state.twoFactorAuthenticationMessage?.translate?.(this.props.lang)}
                            buttons={[
                                <Button color='primary' onClick={() => {
                                    this.setState({
                                        twoFactorAuthenticationMessage: null,
                                    })

                                }}>{'Close'.translate(this.props.lang)}</Button>
                            ]}
                        />
                        :
                        null

                }
                {this.state.changing2FAStatus ?
                    <div className='changing-2fa-status'>
                        <div>
                            <Player
                                autoplay={true}
                                loop={true}
                                src="/lf30_editor_l5cxzdyf.json"
                                style={{ height: typeof window != 'undefined' && window.innerWidth < 768 ? "128px" : "256px", width: typeof window != 'undefined' && window.innerWidth < 768 ? "128px" : "256px" }}
                            ></Player>
                        </div>
                    </div>
                    :
                    null
                }

            </div >
        );
    }
};


const selector = formValueSelector('modalForm');

export default connect(state => {
    return {
        userLevel: selector(state, 'userLevel'),
        socialSecurityNumber: selector(state, 'socialSecurityNumber'),

        socialSecurityNumberParent: selector(state, 'socialSecurityNumberParent'),
        email: selector(state, 'email'),
        phone: selector(state, 'phone'),
        socialSecurityNumberParent2: selector(state, 'socialSecurityNumberParent2'),
        email2: selector(state, 'email2'),
        phone2: selector(state, 'phone2'),
        language2: selector(state, 'language2'),
        nameParent2: selector(state, 'nameParent2'),
        alias: selector(state, 'alias'),
        username: selector(state, 'username'),
        name: selector(state, 'userData.name'),
        socialSecurityNumber: selector(state, 'socialSecurityNumber'),
        dateOfBirth: selector(state, 'dateOfBirth.date'),
        dateOfBirthParent: selector(state, 'dateOfBirthParent.date'),
        dateOfBirthParent2: selector(state, 'dateOfBirthParent2.date'),

        twoFactorAuthenticationEnabled: selector(state, 'twoFactorAuthenticationEnabled')

    }


}, {
    changeParentAccount: value => change("modalForm", "parentAccount", value),
    changeUsername: value => change("modalForm", "username", value),

    changeName: value => change('modalForm', 'userData.name', value),
    changeSex: value => change('modalForm', 'userData.sex', value),
    changeSocialSecurityNumberParent: value => change('modalForm', 'socialSecurityNumberParent', value),
    changeSocialSecurityNumberParent2: value => change('modalForm', 'socialSecurityNumberParent2', value),

    changeEmail: value => change("modalForm", "email", value),
    changeNameParent: value => change("modalForm", "nameParent", value),
    changeLanguage: value => change("modalForm", "language", value),
    changePhone: value => change("modalForm", "phone", value),
    changeSexParent: value => change("modalForm", "sexParent", value),

    changeEmail2: value => change("modalForm", "email2", value),
    changeNameParent2: value => change("modalForm", "nameParent2", value),
    changeLanguage2: value => change("modalForm", "language2", value),
    changePhone2: value => change("modalForm", "phone2", value),
    changeSexParent2: value => change("modalForm", "sexParent2", value),

    changeDateOfBirth: value => change("modalForm", "dateOfBirth.date", value),
    changeDateOfBirthParent: value => change("modalForm", "dateOfBirthParent.date", value),
    changeDateOfBirthParent2: value => change("modalForm", "dateOfBirthParent2.date", value)

})(Page(Patients));


