import { faAlignRight } from '@fortawesome/free-solid-svg-icons';
import React, { Component } from 'react';
import Toolbar from 'react-big-calendar/lib/Toolbar';
import { API_ENDPOINT } from '../../../constants';

import { Tooltip } from '@material-ui/core';
import { withStyles, makeStyles } from "@material-ui/core/styles";

import xIcon from '../../../assets/svg/x.svg';

import multiIcon from '../../../assets/svg/multi.svg';

import Isvg from 'react-inlinesvg'

import moment from 'moment';

import {
    Container,
    Row,
    Col,
    Modal, ModalHeader, ModalBody, ModalFooter, Button, FormGroup, Input, Label, Table
} from 'reactstrap';

const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: "rgba(58, 63, 85, 0.9)",
        color: "#fff",
        boxShadow: theme.shadows[1],
        fontSize: 14
    }
}))(Tooltip);

function getTitle(text, view) {
    let returnValue = '';

    if ((view == 'month' || view == 'work_week') && text && text.length > 20) {
        returnValue = text.substr(0, 20) + '...'
    } else if (view == 'week' && text && text.length > 15) {
        returnValue = text.substr(0, 15) + '...'
    } else if (view == 'day') {
        returnValue = text;
    } else if (text && text.length > 40) {
        returnValue = text.substr(0, 37) + '...'
    } else {
        returnValue = text;
    }




    return returnValue;
}
/**
* Component for custom events in calendar
* @author   Aleksandar Dabic
*/
class EventComponent extends Component {
    constructor(props) {
        super(props);


        this.state = {

            referral: null,
            note: this.props.event.patientNote,
            controlNote: this.props.event.patientControlNote,
            tooltip: this.props.event.tooltip
        };
    }


    componentDidMount() {
        const view = this.props.view;

    }


    render() {

        let daysWithDuplicate = [];
        if (this.props.daysWithDuplicate && this.props.view == 'month') {
            daysWithDuplicate = this.props.daysWithDuplicate;
        }
        return (
            <div className="rbc-container" key={this.props.event._id}>
                {/* {this.props.view != 'agenda' ? <span>{this.props.event.tooltip}</span> : null} */}

                {
                    this.props.view == 'month' && this.props.event.start && this.props.getStringDateTs(new Date(this.props.event.start).getTime() / 1000, 'MM-DD-YYYY')/*moment.unix(new Date(this.props.event.start).getTime() / 1000).format('MM-DD-YYYY')*/ && daysWithDuplicate.filter(el => el == this.props.getStringDateTs(new Date(this.props.event.start).getTime() / 1000, 'MM-DD-YYYY')/*moment.unix(new Date(this.props.event.start).getTime() / 1000).format('MM-DD-YYYY')*/).length ?
                        <div className='duplicate-events-month-view-wrap'>
                            <div className='duplicate-events-month-view-icon-wrap' onClick={(e) => {
                                e.stopPropagation()
                                this.props.setView('agenda', this.props.event.start)
                            }}>
                                <div className='svg-wrap'>
                                    <Isvg src={multiIcon} />
                                </div>
                                <div className='duplicate-events-tooltip'>
                                    {'Overlapping bookings, see agenda!'.translate(this.props.lang)}
                                </div>

                            </div>
                        </div>
                        :
                        null
                }


                <Container>
                    <Row style={{ 'padding-bottom': '10px', 'align-items': 'center' }}>
                        <Col lg={this.props.view == 'agenda' ? '6' : '12'} style={{ overflow: 'hidden', paddingLeft: 0 }}>
                            {
                                this.props.event.patientProfile && this.props.view == 'agenda' ?
                                    <Button color='#fff' className="event-name-button" style={{ padding: 0 }} onClick={() => this.props.setPatientProfile(this.props.event.patientProfile)}>{this.props.event.title} </Button>
                                    :
                                    (!this.props.event.tooltipDisabled || true) && this.props.event.type != 'temporaryEvent' ?
                                        <LightTooltip title={this.state.tooltip} placement="top-start">
                                            <p>{getTitle(this.props.event.title, this.props.view)}</p>
                                        </LightTooltip>

                                        :
                                        <p>{this.props.event.title}</p>

                            }
                        </Col>
                        <Col lg="4" style={{ overflowX: 'hidden', paddingLeft: 0 }}>

                            {
                                this.props.event.patientNote && this.props.view == 'agenda' ?
                                    <div>
                                        {this.props.event.patientNote}
                                    </div>
                                    :
                                    (this.props.event.type == "control" || this.props.event.type === 'revisit') && this.props.view == 'agenda' ?
                                        <div>
                                            {this.props.event.patientControlNote}
                                        </div>
                                        :
                                        null
                            }
                        </Col>
                        <Col lg="2" style={{ overflowX: 'hidden', display: 'flex', justifyContent: 'flex-end' }}>
                            {
                                this.props.event.referral && this.props.view == 'agenda' ?
                                    <Button style={{ background: '#E6EDF5', color: "black" }} onClick={() => this.props.setReferral(this.props.event.referral, this.state.note, this.props.event.title)}>{'Add note'.translate(this.props.lang)}</Button>
                                    :
                                    (this.props.event.type == "control" || this.props.event.type === 'revisit') && this.props.view == 'agenda' ?
                                        <Button style={{ background: '#E6EDF5', color: "black" }} onClick={() => this.props.setControl(this.props.event.id, this.state.controlNote, this.props.event.title)}>{'Add note'.translate(this.props.lang)}</Button>
                                        :
                                        null
                            }
                        </Col>
                    </Row>
                </Container>




            </div>
        );
    }
}

export default EventComponent;