import { v4 as uuidv4 } from 'uuid';


export function copyMobileToApp(nodes) {
    let newNodes = JSON.parse(JSON.stringify(nodes));
    if (newNodes && Object.keys(newNodes).length) {
        for (var key in newNodes) {
            if (newNodes[key] && Object.keys(newNodes[key]).length) {
                if (newNodes[key]['app'] && newNodes[key]['visibility'] && newNodes[key]['visibility']['app']) {
                    if (!newNodes[key]['visibility']['xs'] && !newNodes[key]['visibility']['sm'] && !newNodes[key]['visibility']['lg']) {
                        delete newNodes[key];
                    } else {
                        newNodes[key]['visibility']['app'] = false;
                    }

                }
            }
        }
    }

    let dict = {};
    if (nodes && Object.keys(nodes).length) {
        for (var key in nodes) {
            if (nodes[key] && Object.keys(nodes[key]).length) {


                if (nodes[key]['xs'] && nodes[key]['visibility'] && nodes[key]['visibility']['xs']) {
                    let newKey = uuidv4();
                    if (!newNodes[newKey]) {
                        newNodes[newKey] = {}
                    }
                    dict[key] = newKey;
                    newNodes[newKey] = { ...nodes[key], parent: dict[nodes[key].parent] ? dict[nodes[key].parent] : nodes[key].parent };
                    newNodes[newKey]['visibility'] = {
                        lg: false,
                        sm: false,
                        xs: false,
                        app: true
                    }
                    for (var keyXs in nodes[key]) {
                        if (keyXs == 'xs') {
                            newNodes[newKey]['app'] = { ...nodes[key][keyXs], i: newKey };
                        } else if (keyXs != 'visibility' && nodes[key][keyXs] && typeof nodes[key][keyXs] === 'object' && nodes[key][keyXs]['xs']) {
                            newNodes[newKey][keyXs]['app'] = nodes[key][keyXs]['xs'];
                        }

                    }

                }
            }

        }
    }

    return newNodes;

}
