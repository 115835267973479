import React, { Component } from 'react';
import Page from '../../containers/page';

import {
    Container,
    Row,
    Col,
    Input,
    Label,
    Button,
    FormGroup,
    UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem

} from 'reactstrap';


import FormBuilder from '../../components/forms/modalFormBuilder';
import { required } from '../../components/forms/validation';
import HTML from '../../components/forms/fields/html';

import Isvg from 'react-inlinesvg'
import Search from '../../components/search';

import profile from '../../assets/images/profile-pic.png'
import map from '../../assets/svg/map.svg'
import chart from '../../assets/svg/chart.svg'
import edit from '../../assets/svg/edit.svg'
import garabage from '../../assets/svg/garbage.svg'
import arrowDown from '../../assets/svg/arrow-down.svg'
import arrowUp from '../../assets/svg/arrow-up.svg'
import addIcon from '../../assets/svg/add-icon.svg';
import { Player, Controls } from "@lottiefiles/react-lottie-player";
import arrayMove from 'array-move';

import ListBuilder from '../../components/listBuilder';
import DeleteModal from '../../components/deleteModal';
import { getSearchParams, generateSearchLink, generateSearchLinkMultiple } from '../../helpers/linkHelper';
import { SortableContainer, SortableElement, sortableHandle, } from 'react-sortable-hoc';

import { API_ENDPOINT } from '../../constants';
import ErrorModal from '../../components/errorModal';

import profileUser from '../../assets/images/user.png';
import notification from '../../assets/svg/notification.svg';
import moreIcon from '../../assets/svg/more.svg';
import Iframe from 'react-iframe';



function parseJSON(response) {
    return response.json().then(json => {
        return {
            result: json,
            status: response.status
        }
    })
}
const SortableItem = SortableElement(({ value, update }) => <li className={value.className}>{value.component}</li>);

const SortableList = SortableContainer(({ items, updateSubMenu }) => {
    return (
        <ul className="prepare-questionary-sort-list">
            {items.map((value, index) => (
                <SortableItem key={`item-${index}`} index={index} value={value} />
            ))}
        </ul>
    );
});
const DragHandle = sortableHandle(({ children }) => children);

/**
* Page for edit user manual
* @author   Aleksandar Dabic
*/
class UserManualEditor extends Component {
    constructor(props) {
        super(props);
        this.player = React.createRef();
        this.player2 = React.createRef();
        this.state = {
            ...props.initialData,
            form: false,
            useState: false,
            showPlayer: false,
            showPlayer2: false,
            deleteModal: null,
            params: {
                entries: 10,
                page: 0
            },
            sections: [],
            numberOfElementsToRender: 1,

            loading: true
        };
    }
    showPlayer = () => {
        this.setState({
            showPlayer: true,
        });
    };

    hidePlayer = () => {
        this.setState({
            showPlayer: false,
        });
    };


    get = () => {
        this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));

        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0 })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

        for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
            this.props.loadDataWithQuery[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0 })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }


        fetch(API_ENDPOINT + '/data-managment/user/user-manual/en', {
            method: 'GET',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            }
        }).then(parseJSON).then(({ result, status }) => {
            if (result && result.sections) {
                this.setState({ sections: result.sections })
            }

        })

    }
    componentWillUnmount() {
        try {
            window.removeEventListener('scroll', this.handleScroll);

            delete this.state;
            this.state = {};
        } catch (e) {

        }




    }
    handleScroll = () => {
        var scrollTop = (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop;

        // Grodriguez's fix for scrollHeight:
        // accounting for cases where html/body are set to height:100%
        var scrollHeight = (document.documentElement && document.documentElement.scrollHeight) || document.body.scrollHeight;

        // >= is needed because if the horizontal scrollbar is visible then window.innerHeight includes
        // it and in that case the left side of the equation is somewhat greater.
        var scrolledToBottom = (scrollTop + window.innerHeight) >= ((scrollHeight) - scrollHeight / 8);
        // console.log('scrolledToBottom', scrolledToBottom)
        if (scrolledToBottom) {
            this.setState({
                numberOfElementsToRender: this.state.numberOfElementsToRender + 1
            })
        }
    }


    componentDidMount() {
        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }

        this.get();

        window.addEventListener('scroll', this.handleScroll);
        // window.onmessage = (e) => {
        //     if (e && e.data && e.data.indexOf && e.data.indexOf('changePath') !== -1) {
        //         this.props[0].history.push(e.data.split('||')[1])
        //     }
        // }

        // if (this.props.uData) {
        //     this.setState({
        //         loggedIn: true
        //     })
        // }
    }

    componentDidUpdate(prevProps) {
        if (prevProps[0].location.search != this.props[0].location.search) {
            this.setState({
                loading: true
            }, () => {
                this.get();

            })
        }
    }


    updateSort = (field, type) => {
        if (this.state.useState) {
            let state = this.state.params;
            state.sortField = field;
            state.sortType = type;
            this.setState({
                params: state
            })
        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0 }, [{ name: 'sortField', value: field }, { name: 'sortType', value: type }], false));

        }
    }

    updateParams = (name, value, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };
                obj[name] = value;
                this.setState({ params: obj });
            } else {
                let obj = this.state.params;
                obj[name] = value;
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(generateSearchLink(this.props[0].location.search, { entries: 10, page: 0 }, name, value, restart));
        }
    }
    updateMultipleParams = (fields, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj });

            } else {
                let obj = this.state.params;
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0 }, fields, restart));
        }
    }



    insertOrUpdate = () => {
        this.setState(
            {
                showPlayer: true,
            },
            () => {

                this.player.current.play();
                fetch(API_ENDPOINT + '/data-managment/user/user-manual/update/en', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        sections: this.state.sections
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (result.error) {
                        this.setState({ error: result.error })
                    } else {
                        this.setState({
                            message: result.message,
                            showPlayer: false,
                            showPlayer2: true,
                        },
                            () => {
                                setTimeout(() => {
                                    this.setState({ showPlayer2: false });
                                }, 1000);
                            })

                    }

                })
            }
        )
    }


    stopAnimation = () => {
        this.player.current.play();
    }






    render() {
        let params = {};
        if (this.state.useState) {
            params = this.state.params;
        } else {
            params = getSearchParams(this.props[0].location.search, { entries: 10, page: 0 })
        }


        return (
            <div className={this.props.autoHideSidebar ? "dashboard dashboard-auto-hide" : "dashboard"}>

                <Container fluid>

                    <Row>
                        <Col xl="12">
                            <div className="panel panel-short">
                                <div className="panel-header user-manual-header">
                                    <h5 className="component-header">
                                        {this.props.linksMeta &&
                                            this.props.linksMeta[this.props[0].location.pathname]
                                            ? this.props.linksMeta[this.props[0].location.pathname][
                                            this.props.lang
                                            ]
                                            : ""}{" "}
                                    </h5>
                                    <Button color='primary' onClick={() => {
                                        let sections = this.state.sections;
                                        sections.push({
                                            title: '',
                                            content: '',
                                            fontSize: 16
                                        })
                                        this.setState({ sections })
                                    }}>{'Add new section'.translate(this.props.lang)}</Button>
                                </div>




                            </div>
                        </Col>
                        <Col lg='12'>
                            <div className="panel build-questionary-section">
                                <SortableList helperClass="test123"

                                    onSortEnd={({ oldIndex, newIndex }) => {
                                        let items = this.state.sections;



                                        if (oldIndex > newIndex) {
                                            items = arrayMove(items, oldIndex, newIndex);
                                            this.setState({
                                                sections: items
                                            })

                                        }
                                        else {
                                            items = arrayMove(items, oldIndex, newIndex);
                                            this.setState({
                                                sections: items
                                            })
                                        }
                                        document.body.style.cursor = 'default';


                                    }}
                                    onSortOver={({ index, oldIndex, newIndex }) => {


                                        if (index > newIndex) {
                                            this.setState({ cursorNoDrop: false }, () => { document.body.style.cursor = 'default'; })


                                        }
                                        else {
                                            this.setState({ cursorNoDrop: false }, () => { document.body.style.cursor = 'default'; })

                                        }


                                    }}
                                    useDragHandle items={this.state.sections.slice(0, this.state.numberOfElementsToRender * 10).map((item, idx) => {
                                        let cssStyle = {};
                                        if (item.fontSize) {
                                            cssStyle.fontSize = item.fontSize;
                                        }
                                        if (item.fontWeight) {
                                            cssStyle.fontWeight = item.fontWeight;
                                        }
                                        if (item.fontStyle) {
                                            cssStyle.fontStyle = item.fontStyle;
                                        }


                                        return {
                                            component: (
                                                <div className='user-manual-section-wrap'>
                                                    <div style={{ display: 'flex', marginTop: 15 }}>
                                                        <div className="drag-handle">
                                                            <DragHandle><i className="fa fa-arrows" /> </DragHandle>
                                                        </div>
                                                        <span >{idx + 1}.</span>
                                                    </div>
                                                    <div className='user-manual-section'>
                                                        <FormGroup>
                                                            <Label>{'Section title'.translate(this.props.lang)}
                                                                <>

                                                                    <div className="heading-dropdown">
                                                                        {/* <button className={item.fontStyle == 'italic' ? 'active' : ''} onClick={() => { let items = this.state.items; items[idx].fontStyle = items[idx].fontStyle == 'italic' ? null : 'italic'; this.setState({ items }) }}><i className="fa fa-italic" /></button> */}
                                                                        {/* <button className={item.fontWeight == 'bold' ? 'active' : ''} onClick={() => { let items = this.state.items; items[idx].fontWeight = items[idx].fontWeight == 'bold' ? null : 'bold'; this.setState({ items }) }}><i className="fa fa-bold" /></button> */}

                                                                        <UncontrolledDropdown size="sm">
                                                                            <DropdownToggle caret>
                                                                                {/* <i className="fa fa-font" /> */}
                                                                                {'Heading'.translate(this.props.lang)}
                                                                                {
                                                                                    this.state.sections && this.state.sections[idx] ?

                                                                                        this.state.sections[idx].fontSize == 12 ? ' - ' + 'H6 title'.translate(this.props.lang)
                                                                                            :
                                                                                            this.state.sections[idx].fontSize == 14 ? ' - ' + 'H5 title'.translate(this.props.lang)
                                                                                                :
                                                                                                this.state.sections[idx].fontSize == 16 ? ' - ' + 'H4 title'.translate(this.props.lang)
                                                                                                    :
                                                                                                    this.state.sections[idx].fontSize == 19 ? ' - ' + 'H3 title'.translate(this.props.lang)
                                                                                                        :
                                                                                                        this.state.sections[idx].fontSize == 24 ? ' - ' + 'H2 title'.translate(this.props.lang)
                                                                                                            :
                                                                                                            this.state.sections[idx].fontSize == 32 ? ' - ' + 'H1 title'.translate(this.props.lang)
                                                                                                                :
                                                                                                                null
                                                                                        :
                                                                                        null
                                                                                }
                                                                            </DropdownToggle>
                                                                            <DropdownMenu>
                                                                                <DropdownItem onClick={() => { let sections = this.state.sections; sections[idx].fontSize = 12; this.setState({ sections }) }}>{'H6 title'.translate(this.props.lang)}</DropdownItem>
                                                                                <DropdownItem onClick={() => { let sections = this.state.sections; sections[idx].fontSize = 14; this.setState({ sections }) }}>{'H5 title'.translate(this.props.lang)}</DropdownItem>
                                                                                <DropdownItem onClick={() => { let sections = this.state.sections; sections[idx].fontSize = 16; this.setState({ sections }) }}>{'H4 title'.translate(this.props.lang)}</DropdownItem>
                                                                                <DropdownItem onClick={() => { let sections = this.state.sections; sections[idx].fontSize = 19; this.setState({ sections }) }}>{'H3 title'.translate(this.props.lang)}</DropdownItem>
                                                                                <DropdownItem onClick={() => { let sections = this.state.sections; sections[idx].fontSize = 24; this.setState({ sections }) }}>{'H2 title'.translate(this.props.lang)}</DropdownItem>
                                                                                <DropdownItem onClick={() => { let sections = this.state.sections; sections[idx].fontSize = 32; this.setState({ sections }) }}>{'H1 title'.translate(this.props.lang)}</DropdownItem>
                                                                            </DropdownMenu>
                                                                        </UncontrolledDropdown>
                                                                    </div>

                                                                </>
                                                            </Label>

                                                            <Input type='text' style={this.state.sections[idx].fontSize ? { fontSize: this.state.sections[idx].fontSize } : {}} value={item.title} onChange={(e) => {
                                                                let sections = this.state.sections;
                                                                sections[idx].title = e.target.value;
                                                                this.setState({ sections })
                                                            }} />

                                                        </FormGroup>
                                                        {this.state.sections[idx].titleField ? null :
                                                            <FormGroup>
                                                                <Label>{'Section content'.translate(this.props.lang)}</Label>
                                                                <HTML height={350} value={item.content} onChange={(text) => {
                                                                    let sections = this.state.sections;
                                                                    sections[idx].content = text;
                                                                    this.setState({
                                                                        sections
                                                                    })
                                                                }} />
                                                            </FormGroup>
                                                        }
                                                    </div>
                                                    <div>
                                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                                            <div className="heading-dropdown heading-dropdown-type">
                                                                <UncontrolledDropdown size="sm">
                                                                    <DropdownToggle caret>
                                                                        {/* <i className="fa fa-font" /> */}
                                                                        {'Type'.translate(this.props.lang)}
                                                                    </DropdownToggle>
                                                                    <DropdownMenu>
                                                                        <DropdownItem onClick={() => { let sections = this.state.sections; sections[idx].titleField = true; this.setState({ sections }) }}>{'Title'.translate(this.props.lang)}</DropdownItem>
                                                                        <DropdownItem onClick={() => { let sections = this.state.sections; sections[idx].titleField = false; this.setState({ sections }) }}>{'Content'.translate(this.props.lang)}</DropdownItem>
                                                                    </DropdownMenu>
                                                                </UncontrolledDropdown>
                                                            </div>
                                                            <div className='user-manual-delete-section' onClick={() => this.setState({ deleteModal: idx })}>
                                                                <Isvg src={garabage} />
                                                            </div>
                                                            <UncontrolledDropdown size="sm" className="insert-above-below">
                                                                <DropdownToggle>
                                                                    <Isvg src={moreIcon} />
                                                                </DropdownToggle>
                                                                <DropdownMenu>
                                                                    {
                                                                        idx > 0 ?
                                                                            <DropdownItem
                                                                                onClick={() => {
                                                                                    let sections = this.state.sections;
                                                                                    sections.splice(idx, 0, {
                                                                                        title: '',
                                                                                        content: '',
                                                                                        fontSize: 16
                                                                                    });

                                                                                    this.setState({
                                                                                        sections
                                                                                    })
                                                                                }}>{"Insert above".translate(this.props.lang)}</DropdownItem>
                                                                            :
                                                                            null
                                                                    }

                                                                    <DropdownItem
                                                                        onClick={() => {
                                                                            let sections = this.state.sections;
                                                                            sections.splice(idx + 1, 0, {
                                                                                title: '',
                                                                                content: '',
                                                                                fontSize: 16
                                                                            });

                                                                            this.setState({
                                                                                sections
                                                                            })
                                                                        }}
                                                                    >{"Insert below".translate(this.props.lang)}</DropdownItem>
                                                                </DropdownMenu>
                                                            </UncontrolledDropdown>
                                                        </div>
                                                    </div>



                                                </div>
                                            )
                                        }
                                    })}
                                ></SortableList>
                            </div>
                        </Col>
                        <Col lg='12'>
                            <div className="panel" style={{ display: 'flex', alignItems: 'center' }}>
                                <Button color="primary" onClick={() => this.insertOrUpdate()}>

                                    {
                                        this.state.showPlayer ? (
                                            <Player
                                                onEvent={(event) => {
                                                    if (event === "load") this.stopAnimation();
                                                }}
                                                onStateChange={this.toggleVisibility}
                                                ref={this.player} // set the ref to your class instance
                                                autoplay={true}
                                                loop={true}
                                                controls={true}
                                                src="https://assets8.lottiefiles.com/packages/lf20_ZSRKCF.json"
                                                style={{ height: "30px", width: "30px" }}
                                            ></Player>

                                        ) : this.state.showPlayer2 ? (
                                            <div style={{ display: 'flex', alignItems: 'center' }}><Player
                                                onStateChange={this.toggleVisibility}
                                                ref={this.player2} // set the ref to your class instance
                                                autoplay={true}
                                                loop={true}
                                                controls={true}
                                                src="https://assets10.lottiefiles.com/packages/lf20_CxyxLk.json"
                                                style={{ height: "30px", width: "30px" }}
                                            ></Player>
                                                {'Saved'.translate(this.props.lang)}
                                            </div>
                                        ) : (
                                            "Save".translate(this.props.lang)
                                        )
                                    }

                                </Button>
                                <div style={{ marginLeft: 15 }}>
                                    <Button color='primary' onClick={() => {
                                        let sections = this.state.sections;
                                        sections.push({
                                            title: '',
                                            content: '',
                                            fontSize: 16
                                        })
                                        this.setState({ sections })
                                    }}>{'Add new section'.translate(this.props.lang)}</Button>
                                </div>
                            </div>

                        </Col>
                    </Row>
                    <DeleteModal
                        lang={this.props.lang}
                        isOpen={this.state.deleteModal !== null}
                        toggle={() => this.setState({ deleteModal: null })}
                        handler={() => {
                            let sections = this.state.sections;
                            sections.splice(this.state.deleteModal, 1);
                            this.setState({
                                sections,
                                deleteModal: null
                            })
                        }}
                    >
                        {'Delete element'.translate(this.props.lang)} <strong>{this.state.sections[this.state.deleteModal] && this.state.sections[this.state.deleteModal].title}</strong>?
                    </DeleteModal>
                </Container>



            </div>
        );
    }
}

export default Page(UserManualEditor);