import React, { Component } from 'react';
import Page from '../containers/page';

import {
    Container,
    Row,
    Col,
    Modal, ModalHeader, ModalBody, ModalFooter, Button, Label, FormGroup, Input,

} from 'reactstrap';

import Isvg from 'react-inlinesvg'
import { Player, Controls } from '@lottiefiles/react-lottie-player';


class TempPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...props.initialData,
            loading: true
        };

    }



    componentDidMount() {
      
        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }
        // if (this.props[0].location && this.props[0].location.state && this.props[0].location.state.TYPE && this.props[0].location.state.TYPE === 'diary') {
        //     this.props[0].history.push(this.props[0].location.state.path, this.props[0].location.state);
        // }
        if (typeof window != 'undefined' && window.location?.search && window.location.search.indexOf('diary=') !== -1) {
            let search = window.location.search;
            let diary = search.split('=')[1];

            this.diarySSN = diary;

            if(this.props.uData && this.props.availablePaths?.length && this.props.modulesTree?.length){
                this.openDiary()
            }

         
            return;
        }

        if (this.props[0].location && this.props[0].location.state && this.props[0].location.state.reditectTo) {
            this.props[0].history.push(this.props[0].location.state.reditectTo)
        } else {
            this.props[0].history.push('/my-data/account')
        }
    }
    openDiary = () => {
        if(this.diarySSN) {
            this.props[0].history.push({
                pathname: '/patients/search-enterprise',
                state: { reditectToDiary: this.diarySSN }
            });
        } 
    }


    componentWillUnmount() {

    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.uData && this.props.availablePaths?.length && this.props.modulesTree?.length && this.diarySSN) this.openDiary();
    }



    render() {


        return (
            <div className={this.props.autoHideSidebar ? "dashboard dashboard-auto-hide" : "dashboard"}>

            </div >
        );
    }
}



export default Page(TempPage);