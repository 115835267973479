import { faAlignRight } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import Toolbar from 'react-big-calendar/lib/Toolbar';


import {
    Container,
    Row,
    Col,
    Modal, ModalHeader, ModalBody, ModalFooter, Button, FormGroup, Input, Label, Table
} from 'reactstrap';
/**
* React Big Calendar custom toolbar for patient calendar
* @author   Stefan Pantic
*/
export default class PatientCustomToolbar extends Toolbar {

    componentDidMount() {
        const view = this.props.view;

    }

    render() {
        return (
            <div className="rbc-container">
                <div className="rbc-btn-group rbc-gray" style={{ display: 'flex' }}>
                    <Button type="button" onClick={() => this.navigate('PREV')}>{'Previous'.translate(this.props.lang)}</Button>
                    <Label className="rbc-toolbar-label">{this.props.label}</Label>
                    <Button type="button" onClick={() => this.navigate('NEXT')}>{'Next'.translate(this.props.lang)}</Button>
                </div>
            </div>
        );
    }
}