import React, { Component } from 'react';

import { Link } from 'react-router-dom';

import {
    Container,
    Row,
    Col,
    Button,
    FormGroup,
    Label,
    Input,
    Modal, ModalHeader, ModalBody, ModalFooter, Form
} from 'reactstrap';

import { API_ENDPOINT } from '../../constants';

import AnswerFormComponent from './answerFormComponent';

function parseJSON(response) {
    return response.json().then(json => {
        return {
            result: json,
            status: response.status,
            disabledForm: false
        }
    })
}



class ChatForms extends Component {
    constructor(props) {
        super(props);
        this.searchResultsWraps = {};

        this.state = {
            ...props.initialData,
            answers: {},
            disabledForm: false,
            sections: [],
        };
    }

    componentDidMount() {
        let setObj = {}
        let referral = {

        };

        if (this.props.referral) {
            referral = this.props.referral;
        }

        if (this.props.sections) {
            referral.sections = this.props.sections;
        }
        if (this.props.answers) {
            setObj.answers = this.props.answers;
        }
        if (this.props.disabledForm) {
            setObj.disabledForm = true;
        }
        setObj.referral = referral;

        this.setState(setObj)

        if (this.props.alias) {
            this.getGroup(this.props.alias);
        }

    }
    getGroup = (alias) => {
        fetch(API_ENDPOINT + '/landing/page', {
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify({ alias })
        }).then(res => res.json()).then((result) => {
            if (result && result._id) {
                this.setState({ clinicGroup: result })
            }
        })
    }

    componentDidUpdate(prevProps, prevState) {


    }

    emitEnd = (answers, value) => {
        this.props.answer(answers, value)
    }


    render() {
        const bmiIndex = ['#9ABBDA', '#93CCA9', '#FFE02B', '#EAA353', '#D8414A'];
        return (
            <div>

                <Container fluid>
                    <Row>
                        {this.state.referral?.sections?.length ?
                            <Col lg="12">
                                <AnswerFormComponent
                                    {...this.props}
                                    referral={this.state.referral}
                                    clinicGroup={this.state.clinicGroup}
                                    inModal={true}
                                    hideSubmitButton={true}
                                    preview={this.props.disabledForm || this.props.forPrint}
                                    forPrint={this.props.forPrint}
                                    forPrintHtml={this.props.forPrintHtml}
                                    emitEnd={(answers, value) => {
                                        this.emitEnd(answers, value);
                                    }}
                                />
                            </Col>
                            :
                            null
                        }

                    </Row>

                </Container>



                {
                    this.state.wikiModal ?
                        <Modal isOpen={this.state.wikiModal} centered toggle={() => this.setState({ wikiModal: !this.state.wikiModal })} wrapClassName="wiki-hint-modal">
                            <ModalHeader close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ wikiModal: !this.state.wikiModal })}>&times;</button>}>{this.state.wikiModal.name}</ModalHeader>
                            <ModalBody>
                                <div dangerouslySetInnerHTML={{ __html: this.state.wikiModal.content }}></div>
                                <img src={API_ENDPOINT + this.state.wikiModal.image} />
                            </ModalBody>
                        </Modal>
                        :
                        null
                }


            </div >
        );
    }
}

export default ChatForms;