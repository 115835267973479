import React, { Component } from 'react';
import Page from '../../containers/page';

import {
    Container,
    Row,
    Col, Modal, ModalBody, ModalHeader, Button, FormGroup, Label, ModalFooter, Input
} from 'reactstrap';

import Isvg from 'react-inlinesvg';
import attach from '../../assets/svg/attach.svg';
import edit from '../../assets/svg/edit.svg'
import viewIcon from '../../assets/svg/eye.svg';
import garabage from '../../assets/svg/garbage.svg';
import profileUser from "../../assets/images/user.png";
import ListBuilder from '../../components/listBuilder';
import DeleteModal from '../../components/deleteModal';
import { getSearchParams, generateSearchLink, generateSearchLinkMultiple } from '../../helpers/linkHelper';
import { API_ENDPOINT } from '../../constants';
import { formValueSelector, change } from 'redux-form';  // ES6
import { connect } from 'react-redux';
import moment from 'moment';
import transferIcon from '../../assets/svg/transfer-icon.svg';
import assign from '../../assets/svg/assign.svg';
import criticality from '../../assets/svg/alert-triangle-icon.svg'
import takeover from '../../assets/svg/takeover.svg';
import InfoModal from '../../components/infoModal';
import xIcon from '../../assets/svg/x.svg';


/**
* Support archive page show tickets that are closed
* @author   Stefan Pantic
*/
class AllSupportTickets extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props.initialData,
            form: false,
            useState: false,
            params: {
                entries: 10,
                page: 0
            },
            items: [],
            groups: [],
            subgroups: [],
            total: 0,
            loading: true,
            category: {},
            supports: [],
            selectedDoctor: -1,
            criticalityItems: []

        };
    }


    get = () => {
        this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));

        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0, group: this.props.selectedGroup, clinic: this.props.selectedClinic })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

        for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
            this.props.loadDataWithQuery[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0, group: this.props.selectedGroup, clinic: this.props.selectedClinic })).then((data) => {
                let dataToSet = { ...data }
                if (dataToSet.page || dataToSet.items || dataToSet.total) {
                    dataToSet.loading = null;
                    // console.log(dataToSet.page || dataToSet.items || dataToSet.total)
                    let checkFilter = '';
                    if (dataToSet.resultFilter) {
                        checkFilter = String(dataToSet.resultFilter)
                        delete dataToSet.resultFilter;
                    }
                    let search = this.props[0].location.search;
                    let serachParams = getSearchParams(search);
                    let searchParamsFilter = '';
                    if (serachParams.filter) {
                        searchParamsFilter = serachParams.filter;
                    }
                    if (searchParamsFilter != checkFilter) {
                        delete dataToSet.page;
                        delete dataToSet.items;
                        delete dataToSet.total;
                        delete dataToSet.loading;
                    }
                }
                this.setState({
                    ...dataToSet,
                    // loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

    }

    componentDidMount() {
        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }

        this.get();
    }

    componentDidUpdate(prevProps) {
        if (prevProps[0].location.search != this.props[0].location.search) {
            this.setState({
                loading: true
            }, () => {
                this.get();

            })
        }

        if (prevProps.selectedClinic != this.props.selectedClinic) {
            this.updateMultipleParams([{ name: 'page', value: '0' }, { name: 'entries', value: '10' }])
            this.get();
        }

        if (prevProps.selectedGroup != this.props.selectedGroup) {
            this.get();
        }

        if (prevProps.selectedClinic != this.props.selectedClinic) {
            this.get();
        }

    }
    updateSort = (field, type) => {
        if (this.state.useState) {
            let state = this.state.params;
            state.sortField = field;
            state.sortType = type;
            this.setState({
                params: state
            })
        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0 }, [{ name: 'sortField', value: field }, { name: 'sortType', value: type }], false));

        }
    }

    updateParams = (name, value, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };
                obj[name] = value;
                this.setState({ params: obj });
            } else {
                let obj = this.state.params;
                obj[name] = value;
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(generateSearchLink(this.props[0].location.search, { entries: 10, page: 0, sortField: 'timestamp', sortType: -1 }, name, value, restart));
        }
    }
    updateMultipleParams = (fields, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj });

            } else {
                let obj = this.state.params;
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0, sortField: 'timestamp', sortType: -1 }, fields, restart));
        }
    }

    /**
    * Delete support ticket
    * @author   Stefan Pantic
    * @Objectparam    {String} id                  support ticket id
    */
    delete = (id) => {

        fetch(API_ENDPOINT + '/support-ticket/delete/' + id, {
            method: 'DELETE',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
        }).then(res => res.json()).then((result) => {
            this.get();
        })
    }

    findMessages = (id) => {
        fetch(API_ENDPOINT + '/support-category/all-messages/' + id, {
            method: 'POST',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
            body: JSON.stringify({})
        }).then(res => res.json()).then((result) => {
            if (result.error) {
                this.setState({
                    error: result.error.translate(this.props.lang)
                })
            } else {

                this.setState({
                    messages: result
                })
            }

        })
    }

    transferTicket = () => {
        let id = ''
        let user
        if (this.state.transferModal) {
            id = this.state.transferModal._id
            user = this.state.selectedDoctor
        } else {
            id = this.state.confirmModal._id
            user = this.props.uData._id
        }
        fetch(API_ENDPOINT + '/support/transfer/' + id, {
            method: 'POST',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
            body: JSON.stringify({ changeSupportTicketAgent: user })
        }).then(res => res.json()).then((result) => {
            this.setState({
                transferModal: null,
                confirmModal: null
            })
            this.get()

        })
    }

    setCriticality = () => {

        fetch(API_ENDPOINT + '/support-ticket-criticality/update', {
            method: 'POST',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
            body: JSON.stringify({ ticketId: this.state.criticalityModal?._id, selectedCriticality: this.state.selectedCriticality })
        }).then(res => res.json()).then((result) => {
            this.setState({
                criticalityModal: null,
                selectedCriticality: -1
            })
            this.get()

        })
    }

    render() {
        let params = {};
        if (this.state.useState) {
            params = this.state.params;
        } else {
            params = getSearchParams(this.props[0].location.search, { entries: 10, page: 0 })
        }

        let subgroups = [{ name: 'None', value: null }];

        if (this.props.group) {
            for (let i = 0; i < this.state.subgroups.length; i++) {
                if (this.state.subgroups[i].groups && this.state.subgroups[i].groups.indexOf(this.props.group) !== -1) {
                    subgroups.push(this.state.subgroups[i]);
                }
            }
        }

        return (
            <div className={this.props.autoHideSidebar ? "dashboard dashboard-auto-hide" : "dashboard"}>

                <Container fluid>
                    <Row>
                        <Col lg="12">
                            <div className="panel">


                                <ListBuilder
                                    lang={this.props.lang}
                                    loading={this.state.loading}
                                    total={this.state.total}
                                    showNumeration={false}
                                    fields={[
                                        { type: 'text', name: 'name', label: 'Category'.translate(this.props.lang), allowSort: true },
                                        { type: 'text', name: 'supportUserWhoTakeTicket', label: 'Owner'.translate(this.props.lang), allowSort: true },
                                        { type: 'text', name: 'tiketNumber', label: 'Subject'.translate(this.props.lang), allowSort: true },
                                        { type: 'text', name: 'tsCreated', label: 'Date'.translate(this.props.lang), allowSort: true },
                                        { type: 'text', name: 'clinicName', label: 'Clinic'.translate(this.props.lang), allowSort: true },
                                        { type: 'text', name: 'status', label: 'Status'.translate(this.props.lang), allowSort: true },
                                        { type: 'text', name: 'criticality', label: 'Criticality'.translate(this.props.lang), allowSort: true },
                                    ]}
                                    items={this.state.items.map((item => {
                                        let supportNivo = ''
                                        if (item.ownerLine) {
                                            if (Number(item.ownerLine) === 1) {
                                                supportNivo = '1st line'
                                            } else if (Number(item.ownerLine) === 2) {
                                                supportNivo = '2nd line'
                                            } else if (Number(item.ownerLine) === 3) {
                                                supportNivo = '3rd line'
                                            }
                                        }
                                        return {
                                            ...item,
                                            ticketOwner: item.supportUserWhoTakeTicket,
                                            ticketNumber: item.tiketNumber,
                                            criticality: this.props.lang === 'en' ? item?.criticality?.criticalityEn : item?.criticality?.criticalitySe,
                                            name: item && item.name && item.site ? 'Support-site' : item.name,
                                            supportUserWhoTakeTicket: item && item.owner && item.ownerLine ? item.owner + ' - ' + supportNivo.translate(this.props.lang) : 'N/A',
                                            tiketNumber: <>{item.tiketNumber && item.subject ? 'Ticket ID:'.translate(this.props.lang) + ' ' + item.tiketNumber + ' ' + item.subject : 'Ticket ID:'.translate(this.props.lang) + ' ' + item.tiketNumber}</>,
                                            tsCreated: <>{this.props.getStringDateTs(item.tsCreated, `${this.props.dateFormat} HH:mm`)/*moment.unix(item.tsCreated).format(`${this.props.dateFormat} HH:mm`)*/} - {this.props.getStringDateTs(item.timestemp, `${this.props.dateFormat} HH:mm`)/*moment.unix(item.timestemp).format(`${this.props.dateFormat} HH:mm`)*/}</>,
                                            // status: item.supportUserWhoTakeTicket && !item.close ? 'In progress'.translate(this.props.lang) :  !item.supportUserWhoTakeTicket && !item.close ? 'Waiting'.translate(this.props.lang) : item.close ? 'Closed'.translate(this.props.lang) : ''
                                            status: item.status ? item.status.translate(this.props.lang) : ''
                                        }
                                    }))}
                                    actions={
                                        [
                                            {
                                                component: <Isvg src={viewIcon} />,
                                                onClick: (item) => {
                                                    this.setState({ supportModal: { ...item } }, () => this.findMessages(item._id))
                                                }
                                            },
                                            {
                                                component: <div className="tabel-action-tooltip"> <Isvg src={takeover} className='take-over-icon' /> <div className="chat-icons-tooltip">{'Take over'.translate(this.props.lang)}</div> </div>,
                                                onClick: (item) => {

                                                    this.setState({ confirmModal: { ...item } })
                                                },
                                                condition: (item) => {
                                                    if (!item.close && item.ticketOwner != this.props.uData._id && this.props.uData.supportPriorityLevel && item.supportPriorityLevel <= Number(this.props.uData.supportPriorityLevel)) {
                                                        return true
                                                    } else {
                                                        return false
                                                    }
                                                }
                                            },
                                            {
                                                component: <div className="tabel-action-tooltip"> <Isvg src={assign} className='take-over-icon' /> <div className="chat-icons-tooltip">{'Assign'.translate(this.props.lang)}</div> </div>,
                                                onClick: (item) => {

                                                    fetch(API_ENDPOINT + '/support/support-users', {
                                                        method: 'POST',
                                                        headers: {
                                                            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                                                            'content-type': 'application/json'
                                                        },
                                                        body: JSON.stringify({ tiket: item.tiket, group: item.group, supportPriorityLevel: item.supportPriorityLevel })
                                                    }).then(res => res.json()).then((result) => {
                                                        this.setState({ transferModal: { ...item }, supports: [...result] })

                                                    })

                                                },
                                                condition: (item) => {
                                                    if (!item.close && item.group && ((item.ticketOwner == this.props.uData._id) || (this.props.uData.supportPriorityLevel && item.supportPriorityLevel < Number(this.props.uData.supportPriorityLevel)))) {
                                                        return true
                                                    } else {
                                                        return false
                                                    }
                                                }
                                            },
                                            {
                                                component: <div className="tabel-action-tooltip"> <Isvg src={criticality} className='take-over-icon' /> <div className="chat-icons-tooltip">{'Criticality'.translate(this.props.lang)}</div> </div>,
                                                onClick: (item) => {

                                                    fetch(API_ENDPOINT + '/support-criticality/all', {
                                                        method: 'GET',
                                                        headers: {
                                                            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                                                            'content-type': 'application/json'
                                                        },
                                                    }).then(res => res.json()).then((result) => {
                                                        this.setState({ criticalityModal: { ...item }, selectedCriticality: -1, criticalityItems: [...result] })

                                                    })

                                                },
                                                condition: (item) => {
                                                    if (item.group && ((item.ticketOwner == this.props.uData._id) || (this.props.uData.supportPriorityLevel && item.supportPriorityLevel < Number(this.props.uData.supportPriorityLevel)))) {
                                                        return true
                                                    } else {
                                                        return false
                                                    }
                                                }
                                            },
                                            {
                                                component: <Isvg src={garabage} />,
                                                onClick: (item) => {
                                                    this.setState({ deleteModal: { ...item } })
                                                },
                                                condition: (item) => {
                                                    if (item.close) {
                                                        return true
                                                    } else {
                                                        return false
                                                    }
                                                }
                                            },

                                        ]
                                    }
                                    params={params}
                                    sortField={params.sortField}
                                    sortType={params.sortType}
                                    updateSort={this.updateSort}
                                    updateParams={this.updateParams}
                                    updateMultipleParams={this.updateMultipleParams}

                                ></ListBuilder>

                                {
                                    this.state.supportModal ?
                                        <Modal isOpen={this.state.supportModal} size='xl' centered>
                                            <ModalHeader close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ supportModal: !this.state.supportModal })}>&times;</button>}>{
                                                this.state.supportModal.tiketNumber
                                            }</ModalHeader>
                                            <ModalBody className={'support-archive-scroll'}>
                                                {
                                                    this.state.supportModal.close && this.state.supportModal.conclusion ?
                                                        <p>
                                                            {this.props.lang == 'en' ? 'Conclusion:'.translate(this.props.lang) + ' ' + this.state.supportModal.conclusion.conclusionsEn : 'Conclusion:'.translate(this.props.lang) + ' ' + this.state.supportModal.conclusion.conclusionsSe}

                                                        </p>
                                                        :
                                                        null
                                                }
                                                {this.state.messages && this.state.messages.sort(function (a, b) { return b.timestemp - a.timestemp }).map(item => {
                                                    return (
                                                        <div className="mesage-box" style={{ marginBottom: 5, background: '#E6EDF5', borderRadius: '6px', padding: 10 }}>
                                                            <div className="message-header" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                <div className="message-header-container" style={{ display: 'flex', alignItems: 'center' }}>
                                                                    {item.profilePicture ?
                                                                        <img src={API_ENDPOINT + item.profilePicture} style={{ width: 32, height: 32, borderRadius: '50%', objectFit: 'cover', marginRight: 10 }} />

                                                                        :
                                                                        <img src={profileUser} style={{ width: 32, height: 32, borderRadius: '50%', objectFit: 'cover', marginRight: 10 }} />

                                                                    }
                                                                    <h6>{item.user}</h6>
                                                                </div>
                                                                <p>{this.props.getStringDateTs(item.timestemp, `${this.props.dateFormat} HH:mm`)/*moment.unix(item.timestemp).format(`${this.props.dateFormat} HH:mm`)*/}</p>
                                                            </div>
                                                            <p>
                                                                {item.message}
                                                                <div >
                                                                    {item.image && item.image.length && typeof item.image == 'object' ?
                                                                        item.image.map(img => {
                                                                            return (
                                                                                <a className='attach-icon' target='_blank' href={API_ENDPOINT + img}><Isvg src={attach} style={{ width: 20, height: 20 }} /><span>{'Open attachment'.translate(this.props.lang)}</span></a>
                                                                            )

                                                                        })
                                                                        :
                                                                        item.image && item.image.length > 0 ?
                                                                            <a className='attach-icon' target='_blank' href={API_ENDPOINT + item.image}><Isvg src={attach} style={{ width: 20, height: 20 }} /><span>{'Open attachment'.translate(this.props.lang)}</span></a>
                                                                            :
                                                                            null
                                                                    }
                                                                    {
                                                                        item.document && item.document.length && typeof item.document == 'object' ?
                                                                            item.document.map(doc => {
                                                                                return (
                                                                                    <div className="document" style={{ paddingLeft: 0, marginTop: 0 }}><a style={{ marginTop: 0 }} className='attach-icon' target="_blank" download href={API_ENDPOINT + doc.file}><Isvg src={attach} style={{ width: 20, height: 20 }} /><span>{'Open attachment'.translate(this.props.lang)}</span></a></div>
                                                                                )
                                                                            })
                                                                            :
                                                                            item.document && item.document.file ?
                                                                                <div className="document" style={{ paddingLeft: 0, marginTop: 0 }}><a style={{ marginTop: 0 }} className='attach-icon' target="_blank" download href={API_ENDPOINT + item.document.file}><Isvg src={attach} style={{ width: 20, height: 20 }} /><span>{'Open attachment'.translate(this.props.lang)}</span></a></div>
                                                                                :
                                                                                null
                                                                    }
                                                                </div>
                                                            </p>
                                                        </div>

                                                    )
                                                })}
                                            </ModalBody>
                                        </Modal>
                                        :
                                        null
                                }

                                {
                                    this.state.deleteModal ?
                                        <DeleteModal
                                            lang={this.props.lang}
                                            isOpen={this.state.deleteModal}
                                            toggle={() => this.setState({ deleteModal: null })}
                                            handler={() => { this.delete(this.state.deleteModal._id); this.setState({ deleteModal: null }) }}
                                        >
                                            {'Delete ticket'.translate(this.props.lang)} <strong>{this.state.deleteModal && this.state.deleteModal.subject}</strong>?
                                        </DeleteModal>
                                        :
                                        null
                                }

                                {
                                    this.state.transferModal ?
                                        <Modal isOpen={this.state.transferModal} centered>
                                            <ModalHeader toggle={() => this.setState({ transferModal: !this.state.transferModal, selectedDoctor: -1 })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ transferModal: !this.state.transferModal, selectedDoctor: -1 })}><Isvg src={xIcon} /></button>}>
                                                <h4>{'Assign ticket'.translate(this.props.lang)}</h4>
                                            </ModalHeader>
                                            <ModalBody>
                                                {/* <FormGroup>
                                                    <Label>{'Transfer from'.translate(this.props.lang)}</Label>
                                                    <Input type='text' disabled
                                                        value={this.state.transferModal.doctorName ? this.state.transferModal.doctorName : 'Unassigned'.translate(this.props.lang)} />
                                                </FormGroup> */}
                                                <FormGroup>
                                                    {/* <Label>{'Transfer to'.translate(this.props.lang)}</Label> */}
                                                    <Input type='select'
                                                        value={this.state.selectedDoctor}
                                                        onChange={(e) => {
                                                            this.setState({ selectedDoctor: e.target.value })
                                                        }}
                                                    >
                                                        <option value={-1}>{'None'.translate(this.props.lang)}</option>
                                                        {

                                                            this.state.supports && this.state.supports.filter(el => el._id != this.props.uData._id).sort((a, b) => {
                                                                const nameA = a.name.toLowerCase();
                                                                const nameB = b.name.toLowerCase();
                                                                if (nameA < nameB) {
                                                                    return -1
                                                                }
                                                                if (nameA > nameB) {
                                                                    return 1
                                                                }
                                                            }).map((item, idx) => {
                                                                if (item._id != this.state.transferModal.ticketOwner)
                                                                    return (
                                                                        <option value={item._id}>{item.name}</option>
                                                                    )
                                                            })
                                                        }
                                                    </Input>
                                                </FormGroup>

                                            </ModalBody>
                                            <ModalFooter className='buttons-right-blue'>
                                                <Button color="primary" disabled={this.state.selectedDoctor == -1 ? true : false} onClick={() => this.transferTicket()}>{'Confirm'.translate(this.props.lang)}</Button>

                                                <Button color="primary" onClick={() => this.setState({ transferModal: !this.state.transferModal, selectedDoctor: -1 })}>{'Cancel'.translate(this.props.lang)}</Button>

                                            </ModalFooter>
                                        </Modal>
                                        :
                                        null
                                }

                                {
                                    this.state.criticalityModal ?
                                        <Modal isOpen={this.state.criticalityModal} centered>
                                            <ModalHeader toggle={() => this.setState({ criticalityModal: !this.state.criticalityModal, selectedCriticality: -1 })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ criticalityModal: !this.state.criticalityModal, selectedCriticality: -1 })}><Isvg src={xIcon} /></button>}>
                                                <h4>{'Set criticality'.translate(this.props.lang)}</h4>
                                            </ModalHeader>
                                            <ModalBody>
                                                <FormGroup>
                                                    <Input type='select'
                                                        value={this.state.selectedCriticality}
                                                        onChange={(e) => {
                                                            this.setState({ selectedCriticality: e.target.value })
                                                        }}
                                                    >
                                                        <option value={-1}>{'None'.translate(this.props.lang)}</option>
                                                        {

                                                            this.state.criticalityItems.map((item, idx) => {
                                                                if (item._id != this.state.criticalityModal.ticketOwner)
                                                                    return (
                                                                        <option value={item._id}>{this.props.lang === 'en' ? item.criticalityEn : item.criticalitySe}</option>
                                                                    )
                                                            })
                                                        }
                                                    </Input>
                                                </FormGroup>

                                                <FormGroup>
                                                    <p>
                                                        {this.props.lang === 'en' ?
                                                            this.state.criticalityItems && this.state.selectedCriticality && this.state.selectedCriticality !== '-1' && this.state.criticalityItems.filter(item => item._id === this.state.selectedCriticality)[0]?.descriptionEn ? this.state.criticalityItems.filter(item => item._id === this.state.selectedCriticality)[0]?.descriptionEn : ''
                                                            :
                                                            this.state.criticalityItems && this.state.selectedCriticality && this.state.selectedCriticality !== '-1' && this.state.criticalityItems.filter(item => item._id === this.state.selectedCriticality)[0]?.descriptionSe ? this.state.criticalityItems.filter(item => item._id === this.state.selectedCriticality)[0]?.descriptionSe : ''

                                                        }
                                                    </p>

                                                </FormGroup>

                                            </ModalBody>
                                            <ModalFooter className='buttons-right-blue'>
                                                <Button color="primary" disabled={this.state.selectedCriticality == -1 ? true : false} onClick={() => this.setCriticality()}>{'Confirm'.translate(this.props.lang)}</Button>

                                                <Button color="primary" onClick={() => this.setState({ criticalityModal: !this.state.criticalityModal, selectedCriticality: -1 })}>{'Cancel'.translate(this.props.lang)}</Button>

                                            </ModalFooter>
                                        </Modal>
                                        :
                                        null
                                }

                                {
                                    this.state.confirmModal ?
                                        <InfoModal
                                            isOpen={this.state.confirmModal}
                                            toggle={() => this.setState({ confirmModal: !this.state.confirmModal })}
                                            header={'Info'.translate(this.props.lang)}
                                            info={`Are you sure that you want to take over the ticket`.translate(this.props.lang) + ` ${this.state.confirmModal.ticketNumber}?`}
                                            buttons={[
                                                <Button color="primary" style={{ margin: 10 }} onClick={() => this.transferTicket()}>{'Yes'.translate(this.props.lang)}</Button>,

                                                <Button color="primary" style={{ margin: 10 }} onClick={() => this.setState({ confirmModal: false })}>{'Cancel'.translate(this.props.lang)}</Button>,

                                            ]}

                                        />
                                        :
                                        null
                                }
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

const selector = formValueSelector('modalForm');

export default connect(state => {
    return { group: selector(state, 'group') }

}, {
    changeSubgroup: value => change("modalForm", "subgroup", value),

})(Page(AllSupportTickets));