import React, { Component } from 'react';
import SelectWithSearch from './forms/fields/selectWithSearch'
import { v4 as uuidv4 } from 'uuid';
import { GOOGLE_MAPS_API_KEY } from '../constants';

/**
* Showing map with specific coords
* @author   Milan Stanojevic
*/
export class Map extends Component {
    constructor(props) {
        super(props);
        this.initMap = this.initMap.bind(this);
        this.gmapCallback = this.gmapCallback.bind(this);
        if (typeof window !== 'undefined')
            window.googleMapsCallback = this.gmapCallback;

        this.state = {
            selectedValue: null
        };
    }

    gmapCallback() {
        this.setState({
            _googleMapsLoaded: true
        })
    }



    initMap() {
        this.setState({
            _mapInit: true
        });

        if (this.state.value) {
            var latLng = new window.google.maps.LatLng(this.state.value[0].coords.split(',')[0], this.state.value[0].coords.split(',')[1]);
            var map = new window.google.maps.Map(this.GoogleMap, {
                zoom: this.state.selectedValue ? 16 : 8,
                center: latLng,
                mapTypeId: window.google.maps.MapTypeId.ROADMAP,
                disableDefaultUI: true,
                gestureHandling: "greedy",
            });


            for (let i = 0; i < this.state.value.length; i++) {
                var marker = new window.google.maps.Marker({
                    position: new window.google.maps.LatLng(this.state.value[i].coords.split(',')[0], this.state.value[i].coords.split(',')[1]),
                    map: map,
                });
                let props = this.props;
                let state = this.state;
                window.google.maps.event.addListener(marker, 'click', (function (marker, i, props, state) {
                    return function () {
                        props[0].history.push(state.value[i].link)
                    }
                })(marker, i, props, state));


            }



            this.setState({ googleMap: map });
        }




    }

    componentDidMount() {

        var ref = window.document.getElementsByTagName("script")[0];
        var script = window.document.createElement("script");
        script.src = `https://maps.googleapis.com/maps/api/js?sensor=false&key=${GOOGLE_MAPS_API_KEY}&callback=googleMapsCallback&language=se`;
        script.async = true;
        script.defer = true;

        ref.parentNode.insertBefore(script, ref);


        if (this.state._googleMapsLoaded && !this.state._mapInit) {
            this.initMap();
        }
        this.setState({ value: this.props.value }, () => {
            if (this.state.value && this.state.value.length) {
                this.setState({ selectedValue: { name: this.state.value[0].name, value: this.state.value[0].value } })
            }
        })

    }

    componentDidUpdate(prevProps, prevState) {

        if (this.state._googleMapsLoaded && !this.state._mapInit) {
            this.initMap();
        }

        if (this.state._googleMapsLoaded && prevProps.value[0].coords != this.props.value[0].coords) {
            var latLng = new window.google.maps.LatLng(this.props.value[0].coords.split(',')[0], this.props.value[0].coords.split(',')[1]);
            this.state.googleMap.setCenter(latLng);
        }
        if (prevState.updateNeeded != this.state.updateNeeded) {
            this.initMap();
        }
        if(prevState.selectedValue != this.state.selectedValue && this.props.getValue){
            this.props.getValue(this.state.selectedValue)
        }

    }


    render() {
        return (
            <div className='landing-map-wrap'>

                <div className='landing-map-sidebar'>
                    <div className="serach-elements">
                        <div className="landing-map-select-wrap">
                            <SelectWithSearch
                                // width={'300px'}
                                mapHeight={this.props.mapHeight}
                                list={this.props.value}
                                value={this.state.selectedValue}
                                clearable={true}
                                lang={this.props.lang}
                                history={this.props[0].history}
                                onChange={(e) => {
                                    if (e != null) {
                                        let oldList = this.props.value;
                                        let newList = [];

                                        for (let i = 0; i < oldList.length; i++) {

                                            if (oldList[i].value == e.value) {
                                                newList.push(oldList[i])
                                            }
                                        }

                                        this.setState({ selectedValue: { name: e.label, value: e.value }, value: newList }, () => this.setState({ updateNeeded: uuidv4() }))
                                    } else if (e == null && this.state.selectedValue) {
                                        this.setState({ selectedValue: null, value: this.props.value }, () => this.setState({ updateNeeded: uuidv4() }))

                                    }

                                }}
                            />
                        </div>
                    </div>
                    {/* <div className="elements-wrap">
                        {
                            this.props.value && this.props.value.map((item, idx) => {
                                return (
                                    <div className='element' onClick={() => this.setState({ selectedValue: { name: item.name, value: item.value } }, () => this.setState({ updateNeeded: uuidv4() }))}>
                                        <h5>{item.name}</h5>
                                    </div>
                                )
                            })
                        }
                    </div> */}
                </div>
                <div className="map-wrap">{
                    this.state._googleMapsLoaded ?
                        <div className="map" ref={(input) => { this.GoogleMap = input; }}>

                        </div>
                        : null}
                </div>
            </div>

        )
    }
}

export default Map;