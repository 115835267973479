import React, { Component } from 'react';

import {
    Container,
    Row,
    Col,
    FormGroup, Label, Input,
    Button,
    Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';



import ListBuilder from './listBuilder';
import { getSearchParams, generateSearchLink, generateSearchLinkMultiple } from '../helpers/linkHelper';
import { API_ENDPOINT, GOOGLE_API_KEY } from '../constants';
import moment from 'moment';
import eRemissIcon from '../assets/svg/e.svg';

import Isvg from 'react-inlinesvg';
import Checkbox from './forms/fields/checkbox';

function parseJSON(response) {
    return response.json().then(json => {
        return {
            result: json,
            status: response.status
        }
    })
}


function getDistance(a, b, c, d,) {
    return getDst([a, b], [c, d]);
}

function rad(x) {
    return x * Math.PI / 180;
}

function getDst(p1, p2) {
    var R = 6378137; // Earth’s mean radius in meter
    var dLat = rad(p2[0] - p1[0]);
    var dLong = rad(p2[1] - p1[1]);
    var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(rad(p1[0])) * Math.cos(rad(p2[0])) *
        Math.sin(dLong / 2) * Math.sin(dLong / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;
    return (d / 1000).toFixed(1); // returns the distance in km
}

const loadDataWithQuery = [
    (fetchFunction, lang, match, query) => {
        // console.log(query)
        let queryFields = {};
        for (var key in query) {
            if (key && key.indexOf('query-') === 0) {
                queryFields[key.replace('query-', '')] = query[key];
            }
        }
        if (query['_query-region']) {
            queryFields['region'] = query['_query-region'];
        }

        if (query['_query-group']) {
            queryFields['group'] = query['_query-group'];
        }

        return fetchFunction(API_ENDPOINT + '/data/main-clinic-groups', {
            method: 'POST',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
            body: JSON.stringify({
                page: query.page,
                entries: query.entries,
                filter: query.filter,
                sortType: query.sortType,
                sortField: query.sortField,
                query: queryFields
            })
        }).then(parseJSON).then(({ result, status }) => {
            if (status >= 200 && status < 300) {
                for (let i = 0; i < result.items.length; i++) {
                    if (result.items[i].vantetiderName) {
                        result.items[i].vantetiderName = result.items[i].vantetiderName.replace(/\&amp;/g, '&');
                    }
                }
                return {
                    page: query.page ? query.page : 0,
                    items: result.items,
                    total: result.total,
                    debugQuery: result.debugQuery,
                    _clinicSearchTs: query._ts
                }
            }

        })

    },
    (fetchFunction, lang, match, query) => {


        return fetchFunction(API_ENDPOINT + '/data/regions', {
            method: 'GET',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
        }).then(parseJSON).then(({ result, status }) => {
            if (status >= 200 && status < 300)
                return {
                    regions: result,
                }
        })

    },
    (fetchFunction, lang, match, query) => {


        return fetchFunction(API_ENDPOINT + '/data/groups', {
            method: 'GET',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
        }).then(parseJSON).then(({ result, status }) => {
            if (status >= 200 && status < 300)
                return {
                    groups: result,
                }
        })

    },
    (fetchFunction, lang, match, query) => {
        let queryFields = {};

        if (query['_query-region']) {
            queryFields = { region: query['_query-region'] };
        }


        return fetchFunction(API_ENDPOINT + '/data/cities', {
            method: 'POST',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
            body: JSON.stringify({
                query: queryFields,
                sortField: 'name',
                sortType: 1
            })
        }).then(parseJSON).then(({ result, status }) => {
            if (status >= 200 && status < 300)
                return {
                    cities: result,
                }
        })

    },
    (fetchFunction, lang, match, query) => {
        let queryFields = {};

        if (query['_query-group']) {
            queryFields = { group: query['_query-group'] };
        }


        return fetchFunction(API_ENDPOINT + '/data/subgroups/nonempty', {
            method: 'POST',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
            body: JSON.stringify({
                query: queryFields,
                sortField: 'name',
                sortType: 1

            })
        }).then(parseJSON).then(({ result, status }) => {
            if (status >= 200 && status < 300)
                return {
                    subgroups: result,
                }
        })

    },
]

/**
* List of clinics
* @author   Milan Stanojevic
*/
class ClinicsFilter extends Component {
    constructor(props) {
        super(props);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.searchResultsWrap = React.createRef();

        this.state = {
            regions: [],
            cities: [],
            groups: [],
            subgroups: [],
            ...props.initialData,
            useState: true,
            params: {
                entries: 10,
                page: 0,
                'query-group': null
            },
            items: [],
            total: 0,
            loading: true,
            suggestions: [],

        };
    }


    get = () => {
        let params = this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0, 'query-group': null, });
        params.groupCheck = this.props.group;
        params._ts = new Date().getTime();

        this.setState({
            _ts: params._ts
        }, () => {
            for (let i = 0; i < loadDataWithQuery.length; i++) {
                loadDataWithQuery[i](window.fetch, this.props.lang, this.props[0].match, params).then((data) => {
                    if (data && data._clinicSearchTs && this.state._ts == data._clinicSearchTs || (data && !data._clinicSearchTs)) {

                        this.setState({
                            ...data,
                            loading: null
                        })
                    }
                })
            }

        })


    }

    componentDidMount() {
        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }
        // console.log(this.props)
        this.get();
        document.addEventListener('mousedown', this.handleClickOutside);

        let params = getSearchParams(this.props[0].location.search, { entries: 10, page: 0, 'query-group': null });
        if (params['query-search']) {
            this.setState({
                search: params['query-search']
            })
        }

        if (this.props.searchFor) {
            this.updateParams('query-search', this.props.searchFor);
        }
        if (this.props.includeTags) {
            this.updateParams('query-includeTags', this.props.includeTags);
        }

        if ("geolocation" in window.navigator) {
            window.navigator.geolocation.getCurrentPosition((position) => {

                this.setState({
                    currentLocation: position.coords
                })

                this.updateParams('client-location', `${position.coords.latitude},${position.coords.longitude}`);
            });

        }


    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }



    componentDidUpdate(prevProps, prevState) {
        if (prevProps[0].location.search != this.props[0].location.search) {
            this.setState({
                loading: true
            }, () => {
                this.get();

            })

        }

        if (this.state.useState && prevState._useStateTs != this.state._useStateTs) {
            this.setState({
                loading: true
            }, () => {
                this.get();

            })

        }


    }


    updateSort = (field, type) => {
        if (this.state.useState) {
            let state = this.state.params;
            state.sortField = field == 'waitingTime' ? 'waitingMin' : field;
            state.sortType = type;
            this.setState({
                params: state
            })

            this.setState({
                _useStateTs: new Date().getTime()
            })

        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0 }, [{ name: 'sortField', value: field == 'waitingTime' ? 'waitingMin' : field }, { name: 'sortType', value: type }], false));

        }
    }

    updateParams = (name, value, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };

                obj[name] = value;

                this.setState({ params: obj });
            } else {
                let obj = this.state.params;
                obj[name] = value;


                this.setState({ params: obj })
            }

            this.setState({
                _useStateTs: new Date().getTime()
            })
        } else {
            this.props[0].history.push(generateSearchLink(this.props[0].location.search, { entries: 10, page: 0 }, name, value, restart));
        }
    }

    updateMultipleParams = (fields, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj });

            } else {
                let obj = this.state.params;
                for (let i = 0; i < fields.length; i++) {

                    obj[fields[i].name] = fields[i].value;

                }
                this.setState({ params: obj })
            }

            this.setState({
                _useStateTs: new Date().getTime()
            })

        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0 }, fields, restart));
        }
    }

    /**
    * Search database for input value
    * @author   Milan Stanojevic
    */
    updateMainGroup = (data) => {

        fetch(API_ENDPOINT + '/data/main-clinic-groups/' + data.mainGroupId, {
            method: 'POST',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
            body: JSON.stringify({
                clinicGroups: data.clinicGroups
            })
        }).then(res => res.json()).then((result) => {
            
        })


    }

    handleClickOutside = (event) => {
        if (this.searchResultsWrap && this.searchResultsWrap.current && !this.searchResultsWrap.current.contains(event.target)) {
            this.setState({
                showSuggestions: false
            })
        }
    }


    waitingTimePrint(min, max) {
        if (min === '' && max === '') {
            return 'No data'.translate(this.props.lang);
        } else if (min == '0' && max == '0') {
            return '0' + ' ' + 'weeks'.translate(this.props.lang);
        } else if (min > 0 && max == '0') {
            return min + ' ' + 'weeks minimum'.translate(this.props.lang);
        } else if (min == '0' && max > 0) {
            return max + ' ' + 'weeks maximum'.translate(this.props.lang);
        } else if (min == max) {
            return max + ' ' + 'weeks'.translate(this.props.lang);
        } else return min + ' ' + 'to'.translate(this.props.lang) + ' ' + max + ' ' + 'weeks'.translate(this.props.lang);
    }




    render() {
        let params = {};
        if (this.state.useState) {
            params = this.state.params;
        } else {
            params = getSearchParams(this.props[0].location.search, { entries: 10, page: 0 })
        }
        let check = false
        return (
            <div>
                <ListBuilder
                    lang={this.props.lang}
                    loading={this.state.loading}
                    total={this.state.total}
                    showNumeration={false}
                    disableShowEntries
                    disableFilterResults
                    actionLabel={'Import'.translate(this.props.lang)}
                    fields={
                        [
                            { type: 'text', name: '_checked', label: '' },
                            { type: 'text', name: 'name', label: 'Clinic name'.translate(this.props.lang), allowSort: true },

                        ]
                    }
                    
                    items={this.state.items.map((item, idx) => {
                        if(this.props.group && item.clinicGroups[this.props.group]){
                            check = true;
                        }
                        // console.log(this.props.group)
                        return {
                            ...item,
                            _checked:<Checkbox checked={this.props.group && item.clinicGroups && item.clinicGroups[this.props.group]} onChange={() => {
                            }} />,
                            name: <p>{item.vantetiderName ? item.vantetiderName : item.name}<br /><span className="clinic-subgroup-name">{item.subgroupName} {this.props[0].location.pathname !== '/' && item._sortScoreSubgroup ? `(${parseFloat(item._sortScore).toFixed(2)})` : null}</span> </p>,

                        }
                    })}
                    rawItems={this.state.items}
                    onClick={(item) => {
                        if (item.clinicGroups[this.props.group]) {
                            let value = {};
                            value.mainGroupId = item._id
                            let arr = []
                            for (var group in item.clinicGroups) {
                                if (item.clinicGroups[group] != item.clinicGroups[this.props.group])
                                    arr.push(group)
                            }
                            delete item.clinicGroups[this.props.group]
                            value.clinicGroups = arr
                            this.updateMainGroup(value)
                            this.get()
                        } else {
                            if(check){
                                // this.props.onChange(false);
                                // check = false
                            }else{
                                item.clinicGroups[this.props.group] = this.props.group
                                let value = {};
                                value.mainGroupId = item._id
                                let arr = []
                                for (var group in item.clinicGroups) {
                                    arr.push(group)
                                }
                                value.clinicGroups = arr
                                this.updateMainGroup(value)
                                this.get()
                            }
                            
                        }
                    }}
                    params={params}
                    sortField={params.sortField}
                    sortType={params.sortType}
                    updateSort={this.updateSort}
                    updateParams={this.updateParams}
                    updateMultipleParams={this.updateMultipleParams}
                ></ListBuilder>
            </div >
        );


    }
}
export default ClinicsFilter;