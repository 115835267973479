import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import Isvg from 'react-inlinesvg';

import {
    Container,
    Row,
    Col,
    UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, FormGroup, Label, Input, Button, NavbarText, Modal, ModalHeader, ModalBody, ModalFooter

} from 'reactstrap';

import moment from 'moment';
import logo from '../assets/images/logo.png';
import doc from '../assets/svg/doc.svg';
import warrning from '../assets/svg/warrning.svg';
moment.locale('sr');

import FromBuilder from './forms/formBuilder';
import { required, phoneNumber, checkSocialSecurityNumber } from './forms/validation';
import { API_ENDPOINT, CHILD_AGE_LIMIT } from '../constants';
import SocialSecurityNumber, { isSocialSecurityNumberValid } from './forms/fields/socialSecurityNumber';
import { formValueSelector, change } from 'redux-form';  // ES6
import userChat from '../assets/svg/chat.svg';
import logoSmall from '../assets/images/chat-helper-logo.png'
import DateOfBirth from './forms/fields/dateOfBirth';
import Link from './sidebarLink';
import AddNewChildModal from './addNewChildModal';

function parseJSON(response) {
    return response.json().then(json => {
        return {
            result: json,
            status: response.status
        }
    })
}
// const AGE_LIMIT = 13;

/**
* Create referral chat helper
* @author   Milan Stanojevic
*/
export class ReferralChatHelper extends Component {
    constructor(props) {
        super(props);
        this.AGE_LIMIT = props?.country === 'denmark' ? CHILD_AGE_LIMIT.denmark : props?.country === 'uk' ? CHILD_AGE_LIMIT.uk : props?.country === 'sweden' ? CHILD_AGE_LIMIT.sweden : 18;

        this.questions = [
            {
                id: 'message-1',
                messages: [
                    {
                        type: 'text',
                        value: 'If your condition is life-threatening, immediately call 112. If you are unsure, call 1177.'.translate(this.props.lang),
                    }
                ],
                answers: [
                    {
                        type: 'button',
                        id: 'message-1-1',
                        name: "It's not life threatening".translate(this.props.lang),
                        backgroundColor: 'primary'
                    },
                    {
                        type: 'button',
                        id: 'message-1-2',
                        name: 'Ring 112'.translate(this.props.lang),
                        onClick: () => { this.setState({ ringModal: true }, () => this.props.ring112Audio(true)) },
                        backgroundColor: 'danger'
                    }
                ],
                conditions: props?.clinicGroup?.hideEmergencyCall ? [{ question: 'message-123', answer: 'message-1-123' }] : []
            },
            {
                id: 'message-2',
                messages: [
                    {
                        type: 'text',
                        value: 'In order for us to be able to help you, we need your consent to our personal data policy.'.translate(this.props.lang),
                    },
                    {
                        type: 'document',
                        title: 'Personal data policy'.translate(this.props.lang),
                        subtitle: 'Read our personal data policy and information on how we handle your health data.'.translate(this.props.lang),
                        link: props.privacyPolicy
                    }
                ],
                answers: [
                    {
                        type: 'button',
                        id: 'message-2-1',
                        name: 'I agree'.translate(this.props.lang),
                        backgroundColor: 'primary'
                    },
                    {
                        type: 'button',
                        id: 'message-2-2',
                        name: 'I do not agree'.translate(this.props.lang),
                        backgroundColor: 'primary'
                    }
                ],
                conditions: [{ question: 'message-123', answer: 'message-1-123' }]
            },
            {
                id: 'message-3',
                messages: [
                    {
                        type: 'text',
                        value: 'Unfortunately, we cannot help you online without your consent.'.translate(this.props.lang),
                    }
                ],
                answers: [
                    {
                        type: 'button',
                        id: 'message-3-1',
                        name: 'I repent'.translate(this.props.lang),
                        backgroundColor: 'primary'
                    },
                    {
                        type: 'button',
                        id: 'message-3-2',
                        name: 'Close the case'.translate(this.props.lang),
                        backgroundColor: 'primary'
                    }
                ],
                conditions: [{ question: 'message-2', answer: 'message-2-2' }]
            },

            {
                id: 'message-21',
                messages: [
                    {
                        type: 'text',
                        value: 'In order for us to be able to help you, we need your consent to our personal data policy.'.translate(this.props.lang),
                    },
                    {
                        type: 'document',
                        title: 'Personal data policy'.translate(this.props.lang),
                        subtitle: 'Read our personal data policy and information on how we handle your health data.'.translate(this.props.lang),
                        link: props.privacyPolicy
                    }
                ],
                answers: [
                    {
                        type: 'button',
                        id: 'message-21-1',
                        name: 'I agree'.translate(this.props.lang),
                        backgroundColor: 'primary'
                    },
                    {
                        type: 'button',
                        id: 'message-21-2',
                        name: 'I do not agree'.translate(this.props.lang),
                        backgroundColor: 'primary'
                    }
                ],
                conditions: [{ question: 'message-3', answer: 'message-3-1' }]
            },
            {
                id: 'message-4',
                messages: [
                    {
                        type: 'text',
                        value: 'Who is the case?'.translate(this.props.lang),
                    }
                ],
                answers: [
                    {
                        type: 'button',
                        id: 'message-4-1',
                        name: 'Myself'.translate(this.props.lang),
                        backgroundColor: 'primary',
                        onClick: () => { !this.props.clinicGroup?.askForMedicalRecordPermission && (this.props.chooseTime || props.dropIn || this.props.linkToForm) ? this.sendData() : null }

                    },
                    {
                        type: 'button',
                        id: 'message-4-2',
                        name: 'A child'.translate(this.props.lang),
                        backgroundColor: 'primary'
                    }
                ],
                conditions: props?.clinicGroup?.hideEmergencyCall
                    ? props?.clinicGroup?.hideWhoIsTheCaseRegarding
                        ? [{ question: 'message-123', answer: 'message-1-123' }]
                        : []
                    : [{ question: !this.props.clinicGroup?.hideWhoIsTheCaseRegarding && this.props.age >= this.AGE_LIMIT ? 'message-1' : '', answer: !this.props.clinicGroup?.hideWhoIsTheCaseRegarding && this.props.age >= this.AGE_LIMIT ? 'message-1-1' : '' }, { question: 'message-21', answer: 'message-21-1' }]
            },
            {
                id: 'message-5-x',
                messages: [
                    {
                        type: 'text',
                        value: 'By proceeding, you agree that we as caregivers can access your medical record.'.translate(this.props.lang),
                    }
                ],
                answers: [
                    {
                        type: 'button',
                        id: 'message-5-x1',
                        name: 'I agree'.translate(this.props.lang),
                        backgroundColor: 'primary',
                        onClick: () => { this.props.chooseTime || this.props.selectedDropInForm || this.props.linkToForm ? this.sendData() : null }
                    },
                    {
                        type: 'button',
                        id: 'message-5-x2',
                        name: 'I do not agree'.translate(this.props.lang),
                        backgroundColor: 'primary'
                    }

                ],
                conditions: props?.clinicGroup?.hideEmergencyCall
                    ?
                    props?.clinicGroup?.hideWhoIsTheCaseRegarding
                        ?
                        props?.clinicGroup?.askForMedicalRecordPermission
                            ?
                            []
                            :
                            [
                                { question: 'message-123', answer: 'message-1-123' }
                            ]
                        :
                        [
                            { question: this.props.clinicGroup?.askForMedicalRecordPermission ? 'message-4' : '', answer: this.props.clinicGroup?.askForMedicalRecordPermission ? 'message-4-1' : '' }
                        ]
                    :
                    [
                        { question: this.props.clinicGroup?.askForMedicalRecordPermission ? this.props.clinicGroup?.hideWhoIsTheCaseRegarding ? 'message-1' : '' : '', answer: this.props.clinicGroup?.askForMedicalRecordPermission ? this.props.clinicGroup?.hideWhoIsTheCaseRegarding ? 'message-1-1' : '' : '' },
                        { question: this.props.clinicGroup?.askForMedicalRecordPermission ? 'message-4' : '', answer: this.props.clinicGroup?.askForMedicalRecordPermission ? 'message-4-1' : '' }
                    ]
            },
            {
                id: 'socialSecurityNumber',
                messages: [
                    {
                        type: 'text',
                        value: this.props.country === 'uk' ?
                            "Please select the child, if the child is not on the list, click on Add new child. In the event of a life-threatening condition, call 112".translate(this.props.lang) :
                            "Please enter the child's social security number. In the event of a life-threatening condition, call 112".translate(this.props.lang),
                    }
                ],
                answers: [
                    {
                        type: this.props.country === 'uk' ? 'selectChild' : 'socialSecurityNumber',
                        id: 'message-6-1',
                        name: this.props.country === 'uk' ? "Date of birth".translate(this.props.lang) : 'Social security number'.translate(this.props.lang),

                    },
                ],
                conditions: [{ question: 'message-4', answer: 'message-4-2' }]
            },

            {
                id: 'message-7',
                messages: [
                    {
                        type: 'text',
                        value: `The person is over 13 years old and therefore needs to use their own BankID. If the person does not have their own BankID, it is possible to obtain it via their bank.`.translate(this.props.lang),
                    }
                ],
                answers: [],
                conditions: [{
                    question: 'socialSecurityNumber', answerCondition: (answer) => {
                        let years = this.state.years;

                        if (years >= this.AGE_LIMIT) {
                            return true;
                        } else {
                            return false;
                        }

                    }
                }]
            },
            {
                id: 'dateOfBirth',
                messages: [
                    {
                        type: 'text',
                        value: "Enter child's date birth of date".translate(this.props.lang)
                    },
                ],
                answers: [
                    {
                        type: 'dateOfBirth',
                        id: 'dateOfBirthAnswerId',
                    }
                ],
                conditions: [{
                    question: 'socialSecurityNumber', answerCondition: (answer) => {
                        let years = this.state.years;



                        if (years < this.AGE_LIMIT && this.props.showDateOfBirth) {
                            return true;
                        } else {
                            return false;
                        }

                    }
                }]

            },
            {
                id: 'childName',
                messages: [
                    {
                        type: 'text',
                        value: 'Give the child name and surname'.translate(this.props.lang)
                    },
                ],
                answers: [
                    {
                        type: 'letters',
                        id: 'message-9-1'
                    }
                ],
                conditions: [
                    {
                        question: this.props.showDateOfBirth ? 'dateOfBirth' : 'socialSecurityNumber', answerCondition: (answer) => {
                            let years = this.state.years;

                            if (years < this.AGE_LIMIT) {
                                return true;
                            } else {
                                return false;
                            }

                        }
                    },
                ]

            },

            {
                id: 'message-11',
                messages: [
                    {
                        type: 'text',
                        value: "Does the child have one or two legal guardians?".translate(this.props.lang)
                        // value: 'If the child has two guardians, do both agree that you seek care on behalf of the child? If you have sole custody, answer yes.'.translate(this.props.lang),
                    }
                ],
                answers: [
                    {
                        type: 'button',
                        id: 'message-11-1',
                        name: 'One'.translate(this.props.lang),
                        backgroundColor: 'primary'
                    },
                    {
                        type: 'button',
                        id: 'message-11-2',
                        name: 'Two'.translate(this.props.lang),
                        backgroundColor: 'primary'
                    }

                ],
                conditions: [{
                    question: 'childName'
                }]
            },


            {
                id: 'parentData',
                formatMessage: (data) => {
                    let firstParent = `Vårdnadshavare 1:<br/>${data[0].name} (${data[0].socialSecurityNumber})<br/>${data[0].address}<br/>${data[0].phone}<br/><br/>`;
                    let secondParent = data[1] ? `Vårdnadshavare 2:<br/>${data[1].name} (${data[1].socialSecurityNumber})<br/>${data[1].address}<br/>${data[1].phone}` : '';
                    let ret = firstParent;
                    if (data[1] && data[1].name && data[1].socialSecurityNumber && data[1].address && data[1].phone) {
                        ret += secondParent;
                    }
                    return ret;
                },
                messages: [
                    {
                        type: 'text',
                        value: 'Please provide contact information to guardians'.translate(this.props.lang)
                    },
                ],
                answers: [
                    {
                        type: 'parentData',
                        id: 'message-10-1'
                    }
                ],
                conditions: [{ question: 'message-11', answer: 'message-11-1' }]

            },
            {
                id: 'message-12',
                messages: [
                    {
                        type: 'text',
                        value: "Do both legal guardians agree that you seek care on behalf of the child?".translate(this.props.lang)
                        // value: 'Do you agree that we as caregivers can take part in the child current and previous medical records in the medical record system and via the national patient overview?'.translate(this.props.lang),
                    }
                ],
                answers: [
                    {
                        type: 'button',
                        id: 'message-12-1',
                        name: 'Yes'.translate(this.props.lang),
                        backgroundColor: 'primary'
                    },
                    {
                        type: 'button',
                        id: 'message-12-2',
                        name: 'No'.translate(this.props.lang),
                        backgroundColor: 'primary'
                    }
                ],
                conditions: [{ question: 'message-11', answer: 'message-11-2' },
                    // {
                    //     question: 'socialSecurityNumber', answerCondition: (answer) => {
                    //         let year = parseInt(`${answer[0]}${answer[1]}${answer[2]}${answer[3]}`);
                    //         let month = parseInt(`${answer[4]}${answer[5]}`);
                    //         let day = parseInt(`${answer[6]}${answer[7]}`);
                    //         let date = new Date(year, month - 1, day);

                    //         let years = (new Date().getTime() / 1000 - date.getTime() / 1000) / 31556926;
                    //         if (years < 13 && (this.state.answers['parentsData'] || this.state.answers['parentData'])) {
                    //             return true;
                    //         } else {
                    //             return false;
                    //         }

                    //     }
                    // }
                ]
            },
            {
                id: 'parentsData',
                formatMessage: (data) => {
                    let firstParent = `Vårdnadshavare 1:<br/>${data[0].name} (${data[0].socialSecurityNumber})<br/>${data[0].address}<br/>${data[0].phone}<br/><br/>`;
                    let secondParent = data[1] ? `Vårdnadshavare 2:<br/>${data[1].name} (${data[1].socialSecurityNumber})<br/>${data[1].address}<br/>${data[1].phone}` : '';
                    let ret = firstParent;
                    if (data[1] && data[1].name && data[1].socialSecurityNumber && data[1].address && data[1].phone) {
                        ret += secondParent;
                    }
                    return ret;
                },
                messages: [
                    {
                        type: 'text',
                        value: 'Please provide contact information to guardians'.translate(this.props.lang)
                    },
                ],
                answers: [
                    {
                        type: 'parentsData',
                        id: 'message-10-1'
                    }
                ],
                conditions: [{ question: 'message-12', answer: 'message-12-1' }]

            },
            // {
            //     id: 'message-8',
            //     messages: [
            //         {
            //             type: 'text',
            //             value: "If the child is 13 years old or older, does the child agree that we, as caregivers, may access the child's medical record and share content from the medical record with you as the legal guardian?".translate(this.props.lang)
            //             // value: 'When your child is 13 years or older, the child consent is required that you seek care on behalf of the child, and that you / you as a guardian may receive medical records. Has such consent been given by the child?'.translate(this.props.lang),
            //         }
            //     ],
            //     answers: [
            //         {
            //             type: 'button',
            //             id: 'message-8-1',
            //             name: 'Yes'.translate(this.props.lang),
            //             backgroundColor: 'primary'
            //         },
            //         {
            //             type: 'button',
            //             id: 'message-8-2',
            //             name: 'No'.translate(this.props.lang),
            //             backgroundColor: 'primary'
            //         }

            //     ],
            //     conditions: [{
            //         // question: 'socialSecurityNumber', answerCondition: (answer) => {
            //         //     let year = parseInt(`${answer[0]}${answer[1]}${answer[2]}${answer[3]}`);
            //         //     let month = parseInt(`${answer[4]}${answer[5]}`);
            //         //     let day = parseInt(`${answer[6]}${answer[7]}`);
            //         //     let date = new Date(year, month - 1, day);

            //         //     let years = (new Date().getTime() / 1000 - date.getTime() / 1000) / 31556926;
            //         //     if (years >= 13 && years < 18 && (this.state.answers['parentsData'] || this.state.answers['parentData'])) {
            //         //         return true;
            //         //     } else {
            //         //         return false;
            //         //     }

            //         // }
            //         question: 'parentData'
            //     }, { question: 'parentsData' }]
            // },

            {
                id: 'message-13',
                messages: [
                    {
                        type: 'text',
                        value: 'By proceeding you take full legal responsibility that the information you have provided is accurate and that the child you are seeking care for is your child and that you have the legal right to seek care for that child.'.translate(this.props.lang),
                    }
                ],
                answers: [
                    {
                        type: 'button',
                        id: 'message-13-1',
                        name: 'I confirm'.translate(this.props.lang),
                        backgroundColor: 'primary',
                        onClick: () => { this.props.chooseTime ? this.sendData() : {} }

                    },
                    {
                        type: 'button',
                        id: 'message-13-2',
                        name: 'I do not confirm'.translate(this.props.lang),
                        backgroundColor: 'primary'
                    }
                ],
                conditions: [/*{ question: 'message-8', answer: 'message-8-1' }*/{ question: 'parentData' }, { question: 'parentsData' }]
                // conditions: [{ question: 'message-12', answer: 'message-12-1' }]
            },

            {
                id: 'message-14',
                messages: [
                    {
                        type: 'text',
                        value: 'Unfortunately, we can not help you online without your consent!'.translate(this.props.lang),
                    }
                ],
                answers: [
                ],
                conditions: [/*{ question: 'message-8', answer: 'message-8-2' },*/ /*{ question: 'message-11', answer: 'message-11-2' },*/ { question: 'message-5-x', answer: 'message-5-x2' }]
            },
            // {
            //     id: 'message-15',
            //     messages: [
            //         {
            //             type: 'text',
            //             value: `Unfortunately, we can not help you online without your child's consent!`.translate(this.props.lang),
            //         }
            //     ],
            //     answers: [
            //     ],
            //     conditions: [{ question: 'message-8', answer: 'message-8-2' }]
            // },
            {
                id: 'message-16',
                messages: [
                    {
                        type: 'text',
                        value: `Unfortunately we can not help you online without your confirmation!`.translate(this.props.lang),
                    }
                ],
                answers: [
                ],
                conditions: [{ question: 'message-13', answer: 'message-13-2' }]
            },
            {
                id: 'message-17',
                messages: [
                    {
                        type: 'text',
                        value: "Both legal guardians need to consent to you seeking care for the child!".translate(this.props.lang),
                    }
                ],
                answers: [
                ],
                conditions: [{ question: 'message-12', answer: 'message-12-2' }]
            },
            {
                id: 'message-5',
                messages: [
                    {
                        type: 'text',
                        value: this.props.selectedDropInForm || this.props.chooseTime || this.props.linkToForm ? props?.clinicGroup?.hideEmergencyCall && props?.clinicGroup?.hideWhoIsTheCaseRegarding && !props?.clinicGroup?.askForMedicalRecordPermission ? 'Click on the button bellow to start filling out the questionairre.'.translate(this.props.lang) : '' : 'Tell us what you want help with by answering one of our questionnaires and possibly upload a photo.'.translate(this.props.lang),
                    }
                ],
                answers: [
                    {
                        type: 'button',
                        id: 'message-5-1',
                        name: this.props.selectedDropInForm || this.props.chooseTime || this.props.linkToForm ? 'Continue'.translate(this.props.lang) : 'Select contact reason'.translate(this.props.lang),
                        backgroundColor: 'primary',
                        className: this.props.selectedDropInForm || this.props.linkToForm ? "continue-button" : "",
                        onClick: () => { this.sendData() }
                    },
                ],
                conditions: (props?.clinicGroup?.hideEmergencyCall && props?.clinicGroup?.hideWhoIsTheCaseRegarding && !props?.clinicGroup?.askForMedicalRecordPermission)
                    ? []
                    : [
                        { question: 'message-5-x', answer: 'message-5-x1' },
                        { question: !this.props.clinicGroup?.askForMedicalRecordPermission && this.props.clinicGroup?.hideWhoIsTheCaseRegarding ? 'message-1' : '', answer: !this.props.clinicGroup?.askForMedicalRecordPermission && this.props.clinicGroup?.hideWhoIsTheCaseRegarding ? 'message-1-1' : '' },
                        { question: !this.props.clinicGroup?.askForMedicalRecordPermission ? 'message-4' : '', answer: !this.props.clinicGroup?.askForMedicalRecordPermission ? 'message-4-1' : '' },
                        { question: 'message-13', answer: 'message-13-1' },
                        { question: this.props.age < this.AGE_LIMIT ? 'message-2' : '', answer: this.props.age < this.AGE_LIMIT ? 'message-2-1' : '' }
                    ]
            },


        ]

        this.state = {
            answerStack: [],
            answers: {},
            tmpAnswers: {}
        };
    }

    componentDidMount() {
        if (this.props.country) {
            if (this.props.country === 'denmark') this.AGE_LIMIT = CHILD_AGE_LIMIT.denmark;
            else if (this.props.country === 'uk') this.AGE_LIMIT = CHILD_AGE_LIMIT.uk;
            else if (this.props.country === 'sweden') this.AGE_LIMIT = CHILD_AGE_LIMIT.sweden;
            else this.AGE_LIMIT = 18;
        }
        this.getChildren()
    }

    getChildren = () => {
        if (this.props.country === 'uk' && this.props.uData?._id) {
            fetch(API_ENDPOINT + '/users/users/patient/search/' + this.props.uData._id, {
                method: 'POST',
                headers: {
                    'Authorization': typeof window !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify({
                    page: 0,
                    entries: 100,
                    patientProfile: true
                })
            }).then(parseJSON).then(({ result, status }) => {
                if (status === 200 && result?.items?.length) {
                    this.setState({ listOfChildren: result.items })
                }
            })
        }

    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps?.country !== this.props.country && this.props.country) {
            if (this.props.country === 'denmark') this.AGE_LIMIT = CHILD_AGE_LIMIT.denmark;
            else if (this.props.country === 'uk') this.AGE_LIMIT = CHILD_AGE_LIMIT.uk;
            else if (this.props.country === 'sweden') this.AGE_LIMIT = CHILD_AGE_LIMIT.sweden;
            else this.AGE_LIMIT = 18;
        }
    }


    getAnswer = (answerId) => {
        for (let i = 0; i < this.questions.length; i++) {
            if (this.questions[i].answers) {
                for (let j = 0; j < this.questions[i].answers.length; j++) {
                    if (this.questions[i].answers[j].id == answerId) {
                        return this.questions[i].answers[j];
                    }
                }
            }
        }
    }

    getQuestion = (id) => {
        for (let i = 0; i < this.questions.length; i++) {
            if (this.questions[i].id == id) {
                return this.questions[i];
            }

        }
    }

    back = () => {
        let answerStack = this.state.answerStack;

        let lastAnswer = answerStack.pop();
        let answers = this.state.answers;
        delete answers[lastAnswer.question];
        this.setState({
            answers,
            answerStack
        }, () => {
            if (lastAnswer?.question === 'dateOfBirth' || (this.props.country === 'uk' && lastAnswer?.question === 'childName')) {
                this.back()
            }
        })
    }

    answer = (questionId, answer) => {
        let answerStack = this.state.answerStack;
        answerStack.push({ question: questionId });

        this.setState({
            answerStack: answerStack
        })

        let answers = this.state.answers;
        answers[questionId] = answer;
        this.setState({
            answers
        }, () => {
            if (this.wrapper) {
                setTimeout(() => {
                    this.wrapper.scrollTop = this.wrapper.scrollHeight;
                }, 100)

            }
        })
    }

    checkCondition = (questionId) => {
        let answers = this.state.answers;
        let question = this.getQuestion(questionId);

        if (!question.conditions) {
            return true;
        }

        if (!question.conditions.length) {
            return true;
        }

        for (let i = 0; i < question.conditions.length; i++) {
            if (answers[question.conditions[i].question] && ((!question.conditions[i].answer && !question.conditions[i].answerCondition) || (question.conditions[i].answer && answers[question.conditions[i].question] == question.conditions[i].answer) || (question.conditions[i].answerCondition && question.conditions[i].answerCondition(answers[question.conditions[i].question])))) {
                return true;
            }
        }

        return false;
    }


    sendData = () => {
        this.props.handleData({
            childName: this.state.answers.childName,
            parentsData: this.state.answers.parentsData ? this.state.answers.parentsData : this.state.answers.parentData,
            socialSecurityNumber: this.state.answers.socialSecurityNumber,
            dateOfBirth: this.state.answers.dateOfBirth
        });
    }

    render() {
        return (
            <div className="personal-remiss-wrap">

                <div className="area" ref={(node) => this.wrapper = node}>
                    {
                        this.questions.map((item, idx) => {
                            if (this.checkCondition(item.id))
                                return (
                                    <>
                                        {
                                            item.messages && item.messages.filter(message => message.value).length ?
                                                <div className="clinic-message">
                                                    <div>
                                                        {this.props.clinicGroup && this.props.clinicGroup.logoChat ?
                                                            // <div className="clinic-profile-image">
                                                            <img src={this.props.clinicGroup.logoChat} />
                                                            // </div>
                                                            :
                                                            <img src={logoSmall} />

                                                        }

                                                        <div>
                                                            <p className="user">{this.props.clinicGroup ? this.props.clinicGroup.name : ''}</p>
                                                            {
                                                                item.messages.map((message) => {
                                                                    return (
                                                                        <div>
                                                                            {message.type == 'text' ?
                                                                                <p>{message.value}</p>
                                                                                :
                                                                                message.type == 'document' ?
                                                                                    <a href={message.link} target="_blank">
                                                                                        <div className="document">
                                                                                            <Isvg src={doc} />
                                                                                            <div>
                                                                                                <h6>{message.title}</h6>
                                                                                                <p>{message.subtitle}</p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </a>
                                                                                    :
                                                                                    null
                                                                            }
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                null
                                        }


                                        {this.state.answers[item.id] ?
                                            <div className="my-message">
                                                <div>
                                                    <div>
                                                        <p className="user">{this.props.uData.userData.name}</p>
                                                        <div>
                                                            {this.getAnswer(this.state.answers[item.id]) ? this.getAnswer(this.state.answers[item.id]).name : item.formatMessage ? <div dangerouslySetInnerHTML={{ __html: item.formatMessage(this.state.answers[item.id]) }}></div> : this.state.answers[item.id]}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            <div className="answers">
                                                {
                                                    item.answers.map((answer) => {
                                                        if (answer.type == 'button')
                                                            return (
                                                                <Button onClick={() => {
                                                                    if (answer.onClick) {
                                                                        answer.onClick();
                                                                    }
                                                                    this.answer(item.id, answer.id)
                                                                }} color={answer.backgroundColor} className={answer.className}>{answer.name}</Button>
                                                            )
                                                        if (answer.type == 'text')
                                                            return (
                                                                <FormGroup>
                                                                    <Label>{answer.name}</Label>
                                                                    <div style={{ display: 'flex' }} ><Input value={this.state.tmpAnswers[answer.id]} type="text" onChange={(e) => {
                                                                        let tmpAnswers = this.state.tmpAnswers;
                                                                        tmpAnswers[answer.id] = e.target.value;
                                                                        this.setState({
                                                                            tmpAnswers
                                                                        })
                                                                    }} /> <Button size="sm" style={{ marginLeft: 10 }} onClick={() => this.answer(item.id, this.state.tmpAnswers[answer.id])} color="primary">{'Submit'.translate(this.props.lang)}</Button> </div>
                                                                </FormGroup>
                                                            )
                                                        if (answer.type == 'letters')
                                                            return (
                                                                <FormGroup>
                                                                    <Label>{answer.name}</Label>
                                                                    <div className="child-name" ><Input onKeyPress={(e) => {
                                                                        var value = String.fromCharCode(e.which);
                                                                        var pattern = new RegExp(/^[a-zA-Z \u00c0-\u017e]$/i);
                                                                        let check = pattern.test(value);
                                                                        if (!check) {
                                                                            e.preventDefault();
                                                                            return false;
                                                                        }
                                                                        return true;

                                                                    }} value={this.state.tmpAnswers[answer.id]} type="text" onChange={(e) => {
                                                                        let tmpAnswers = this.state.tmpAnswers;
                                                                        tmpAnswers[answer.id] = e.target.value;
                                                                        this.setState({
                                                                            tmpAnswers
                                                                        })
                                                                    }} /> <Button size="sm" onClick={() => this.answer(item.id, this.state.tmpAnswers[answer.id])} color="primary">{'Submit'.translate(this.props.lang)}</Button> </div>
                                                                </FormGroup>
                                                            )

                                                        if (answer.type == 'socialSecurityNumber')
                                                            return (
                                                                <FormGroup>
                                                                    <div>
                                                                        <div className="social-security-number-container">
                                                                            <SocialSecurityNumber
                                                                                selectedGroup={this.props.selectedGroup}
                                                                                selectedClinic={this.props.selectedClinic}
                                                                                lang={this.props.lang}
                                                                                country={this.props.country} label={answer.name} value={this.state.tmpAnswers[answer.id]} type="text" onChange={(e) => {
                                                                                    let tmpAnswers = this.state.tmpAnswers;
                                                                                    tmpAnswers[answer.id] = e;
                                                                                    this.setState({
                                                                                        tmpAnswers
                                                                                    })
                                                                                }} />
                                                                        </div>

                                                                        <Button
                                                                            disabled={!isSocialSecurityNumberValid(this.state.tmpAnswers[answer.id], this.props.country)}
                                                                            style={{ margin: 0 }}
                                                                            size="sm"
                                                                            onClick={async () => {
                                                                                if (this.state.tmpAnswers[answer.id] && (this.props.country === 'denmark' && this.state.tmpAnswers[answer.id].length == 10) || this.state.tmpAnswers[answer.id].length == 12) {
                                                                                    this.answer(item.id, this.state.tmpAnswers[answer.id])
                                                                                    let tmpAnswers = this.state.tmpAnswers;

                                                                                    if (this.props.getDateOfBirthFromSSN) {
                                                                                        let dateOfBirth = this.props.getDateOfBirthFromSSN(this.state.tmpAnswers[answer.id]);
                                                                                        if (dateOfBirth) {

                                                                                            tmpAnswers['dateOfBirthAnswerId'] = dateOfBirth;
                                                                                            this.setState({
                                                                                                tmpAnswers
                                                                                            }, () => {
                                                                                                if (!this.props.showDateOfBirth) {
                                                                                                    this.answer('dateOfBirth', this.state.tmpAnswers['dateOfBirthAnswerId'])
                                                                                                }
                                                                                            })
                                                                                            if (!this.state.disabledDateOfBirth) { this.setState({ disabledDateOfBirth: true }) }
                                                                                        } else {
                                                                                            if (this.state.disabledDateOfBirth) { this.setState({ disabledDateOfBirth: false }) }
                                                                                        }
                                                                                    }
                                                                                    let years, checkYears;
                                                                                    if (tmpAnswers && tmpAnswers['dateOfBirthAnswerId'])
                                                                                        checkYears = await this.props.calculateAgeFromDate(tmpAnswers['dateOfBirthAnswerId']);

                                                                                    if (tmpAnswers && tmpAnswers['dateOfBirthAnswerId'] && checkYears) {
                                                                                        years = checkYears;
                                                                                    } else {
                                                                                        let day;
                                                                                        let month;
                                                                                        let year;
                                                                                        if (this.props.country === 'denmark') {
                                                                                            day = Number(`${tmpAnswers[answer.id][0]}${tmpAnswers[answer.id][1]}`);
                                                                                            month = Number(`${tmpAnswers[answer.id][2]}${tmpAnswers[answer.id][3]}`);
                                                                                            const yearLastDigits = Number(`${tmpAnswers[answer.id][4]}${tmpAnswers[answer.id][5]}`);
                                                                                            const nowYear = new Date().getFullYear();
                                                                                            const nowYearLastDigits = `${String(nowYear)[2]}${String(nowYear)[3]}`;
                                                                                            if (yearLastDigits === 0) year = 2000;
                                                                                            else {
                                                                                                if (yearLastDigits >= +nowYearLastDigits) year = Number(`19${yearLastDigits < 10 ? '0' + yearLastDigits : yearLastDigits}`);
                                                                                                else year = Number(`20${yearLastDigits < 10 ? '0' + yearLastDigits : yearLastDigits}`);
                                                                                            }
                                                                                        } else {
                                                                                            year = parseInt(`${tmpAnswers[answer.id][0]}${tmpAnswers[answer.id][1]}${tmpAnswers[answer.id][2]}${tmpAnswers[answer.id][3]}`);
                                                                                            month = parseInt(`${tmpAnswers[answer.id][4]}${tmpAnswers[answer.id][5]}`);
                                                                                            day = parseInt(`${tmpAnswers[answer.id][6]}${tmpAnswers[answer.id][7]}`);

                                                                                        }
                                                                                        let date = new Date(year, month - 1, day);

                                                                                        years = (new Date().getTime() / 1000 - date.getTime() / 1000) / 31556926;

                                                                                    }
                                                                                    this.setState({ years })

                                                                                } else {
                                                                                    if (this.state.disabledDateOfBirth) { this.setState({ disabledDateOfBirth: false }) }
                                                                                    if (this.state.years) { this.setState({ years: null }) }
                                                                                }
                                                                            }}
                                                                            color="primary"
                                                                        >
                                                                            {'Submit'.translate(this.props.lang)}
                                                                        </Button>
                                                                    </div>
                                                                </FormGroup>
                                                            )
                                                        if (answer.type == 'selectChild')
                                                            return (
                                                                <FormGroup>
                                                                    <div>
                                                                        <div className='add-new-child-btn'>
                                                                            <Button color='primary' onClick={() => this.setState({ addNewChildModal: true })}>{'Add new child'.translate(this.props.lang)}</Button>
                                                                        </div>
                                                                        {
                                                                            this.state.listOfChildren?.length ?
                                                                                <div className='chat-helper-list-wrap'>
                                                                                    <table>
                                                                                        {
                                                                                            this.state.listOfChildren.map((child, cIdx) => {
                                                                                                return (
                                                                                                    <tr>
                                                                                                        <td>
                                                                                                            {child?.userData?.name ?? child?.name}
                                                                                                        </td>
                                                                                                        <td>
                                                                                                            <Button color='primary' onClick={() => {
                                                                                                                if (child?.socialSecurityNumber) {
                                                                                                                    let tmpAnswers = this.state.tmpAnswers;
                                                                                                                    tmpAnswers[answer.id] = child.socialSecurityNumber;
                                                                                                                    this.setState({
                                                                                                                        tmpAnswers
                                                                                                                    }, async () => {
                                                                                                                        if (this.state.tmpAnswers[answer.id] && (this.props.country === 'denmark' && this.state.tmpAnswers[answer.id].length == 10) || this.state.tmpAnswers[answer.id].length == 12) {
                                                                                                                            this.answer(item.id, this.state.tmpAnswers[answer.id])
                                                                                                                            let childName = child?.userData?.name ?? child?.name;
                                                                                                                            this.answer('childName', childName)
                                                                                                                            let tmpAnswers = this.state.tmpAnswers;
                                                                                                                            if (this.props.getDateOfBirthFromSSN) {
                                                                                                                                let dateOfBirth = this.props.getDateOfBirthFromSSN(this.state.tmpAnswers[answer.id]);
                                                                                                                                if (dateOfBirth) {

                                                                                                                                    tmpAnswers['dateOfBirthAnswerId'] = dateOfBirth;
                                                                                                                                    this.setState({
                                                                                                                                        tmpAnswers
                                                                                                                                    }, () => {
                                                                                                                                        if (!this.props.showDateOfBirth) {
                                                                                                                                            this.answer('dateOfBirth', this.state.tmpAnswers['dateOfBirthAnswerId'])
                                                                                                                                        }
                                                                                                                                    })
                                                                                                                                    if (!this.state.disabledDateOfBirth) { this.setState({ disabledDateOfBirth: true }) }
                                                                                                                                } else {
                                                                                                                                    if (this.state.disabledDateOfBirth) { this.setState({ disabledDateOfBirth: false }) }
                                                                                                                                }
                                                                                                                            }
                                                                                                                            let years, checkYears;
                                                                                                                            if (tmpAnswers && tmpAnswers['dateOfBirthAnswerId'])
                                                                                                                                checkYears = await this.props.calculateAgeFromDate(tmpAnswers['dateOfBirthAnswerId']);

                                                                                                                            if (tmpAnswers && tmpAnswers['dateOfBirthAnswerId'] && checkYears) {
                                                                                                                                years = checkYears;
                                                                                                                            } else {
                                                                                                                                let day;
                                                                                                                                let month;
                                                                                                                                let year;
                                                                                                                                if (this.props.country === 'denmark') {
                                                                                                                                    day = Number(`${tmpAnswers[answer.id][0]}${tmpAnswers[answer.id][1]}`);
                                                                                                                                    month = Number(`${tmpAnswers[answer.id][2]}${tmpAnswers[answer.id][3]}`);
                                                                                                                                    const yearLastDigits = Number(`${tmpAnswers[answer.id][4]}${tmpAnswers[answer.id][5]}`);
                                                                                                                                    const nowYear = new Date().getFullYear();
                                                                                                                                    const nowYearLastDigits = `${String(nowYear)[2]}${String(nowYear)[3]}`;
                                                                                                                                    if (yearLastDigits === 0) year = 2000;
                                                                                                                                    else {
                                                                                                                                        if (yearLastDigits >= +nowYearLastDigits) year = Number(`19${yearLastDigits < 10 ? '0' + yearLastDigits : yearLastDigits}`);
                                                                                                                                        else year = Number(`20${yearLastDigits < 10 ? '0' + yearLastDigits : yearLastDigits}`);
                                                                                                                                    }
                                                                                                                                } else {
                                                                                                                                    year = parseInt(`${tmpAnswers[answer.id][0]}${tmpAnswers[answer.id][1]}${tmpAnswers[answer.id][2]}${tmpAnswers[answer.id][3]}`);
                                                                                                                                    month = parseInt(`${tmpAnswers[answer.id][4]}${tmpAnswers[answer.id][5]}`);
                                                                                                                                    day = parseInt(`${tmpAnswers[answer.id][6]}${tmpAnswers[answer.id][7]}`);

                                                                                                                                }
                                                                                                                                let date = new Date(year, month - 1, day);

                                                                                                                                years = (new Date().getTime() / 1000 - date.getTime() / 1000) / 31556926;

                                                                                                                            }
                                                                                                                            this.setState({ years })

                                                                                                                        } else {
                                                                                                                            if (this.state.disabledDateOfBirth) { this.setState({ disabledDateOfBirth: false }) }
                                                                                                                            if (this.state.years) { this.setState({ years: null }) }
                                                                                                                        }
                                                                                                                    })
                                                                                                                }

                                                                                                            }}>
                                                                                                                {'Select'.translate(this.props.lang)}
                                                                                                            </Button>
                                                                                                        </td>
                                                                                                    </tr>

                                                                                                )
                                                                                            })
                                                                                        }
                                                                                    </table>
                                                                                </div>
                                                                                :
                                                                                <div className='chat-helper-empty-list-message'>
                                                                                    {'The list is empty!'.translate(this.props.lang)}
                                                                                    {/* {'The list is empty! Go to the'.translate(this.props.lang)} <Link to='/my-data/account'>{'My profile'.translate(this.props.lang)}</Link> {'page and add the child to the list.'.translate(this.props.lang)} */}
                                                                                </div>

                                                                        }



                                                                    </div>
                                                                </FormGroup>
                                                            )
                                                        if (answer.type == 'dateOfBirth') {
                                                            return (
                                                                <div>
                                                                    <div className="social-security-number-container" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                                        {/* <Input type='date' disabled={this.state.disabledDateOfBirth} style={{ width: 200 }} value={this.state.tmpAnswers[answer.id]} onChange={(e) => {
                                                                                if (e?.target?.value && !this.state.disabledDateOfBirth) {
                                                                                    let tmpAnswers = this.state.tmpAnswers;
                                                                                    tmpAnswers[answer.id] = e.target.value;
                                                                                    this.setState({
                                                                                        tmpAnswers
                                                                                    })
                                                                                }

                                                                            }} /> */}
                                                                        <DateOfBirth
                                                                            label={'Date of birth'.translate(this.props.lang)}
                                                                            disabled={this.state.disabledDateOfBirth}
                                                                            value={this.state.tmpAnswers[answer.id]}
                                                                            dateFormat={this.props.dateFormat}
                                                                            onChange={(e) => {
                                                                                if (e?.target?.value && !this.state.disabledDateOfBirth) {
                                                                                    let tmpAnswers = this.state.tmpAnswers;
                                                                                    tmpAnswers[answer.id] = e.target.value;
                                                                                    this.setState({
                                                                                        tmpAnswers
                                                                                    })
                                                                                }

                                                                            }}
                                                                        />
                                                                    </div>

                                                                    <Button style={{ margin: 0, marginTop: 15 }} size="sm" onClick={() => {
                                                                        if (this.state.tmpAnswers[answer.id])
                                                                            this.answer(item.id, this.state.tmpAnswers[answer.id])
                                                                    }} color="primary">{'Submit'.translate(this.props.lang)}</Button>
                                                                </div>
                                                            )
                                                        }
                                                        if (answer.type == 'parentData') {
                                                            let initialParents = [{ email: this.props.uData.email, name: this.props.uData.userData.name, phone: this.props.uData.phone, socialSecurityNumber: this.props.uData.socialSecurityNumber, address: `${this.props.uData.street}, ${this.props.uData.zip} ${this.props.uData.city}` }, {}]

                                                            return (
                                                                <div className="parents-data">
                                                                    <FromBuilder countryTwoLetter={this.props.countryTwoLetter} country={this.props.country}
                                                                        buttonText={'Submit'.translate(this.props.lang)}
                                                                        initialValues={{ parents: initialParents }}
                                                                        lang={this.props.lang}
                                                                        onSubmit={(data) => {
                                                                            // console.log(data)
                                                                            this.answer(item.id, data.parents)
                                                                        }}

                                                                        fields={[
                                                                            {
                                                                                type: 'row',
                                                                                children: [
                                                                                    {
                                                                                        type: 'col',
                                                                                        width: {
                                                                                            lg: 6,
                                                                                            sm: 12,
                                                                                            xs: 12
                                                                                        },
                                                                                        children: [
                                                                                            {
                                                                                                type: 'h6',
                                                                                                text: 'Guardian 1'.translate(this.props.lang),
                                                                                            },
                                                                                            {
                                                                                                type: 'text',
                                                                                                name: 'parents[0].name',
                                                                                                label: 'First and last name'.translate(this.props.lang),
                                                                                                pattern: new RegExp(/^[a-zA-Z \u00c0-\u017e]$/i),
                                                                                                validate: [required('First and last name is required!'.translate(this.props.lang))]
                                                                                            },
                                                                                            {
                                                                                                type: 'text',
                                                                                                name: 'parents[0].address',
                                                                                                label: 'Address'.translate(this.props.lang),
                                                                                                validate: [required('Address is required!'.translate(this.props.lang))]
                                                                                            },
                                                                                            {
                                                                                                type: 'phone',
                                                                                                name: 'parents[0].phone',
                                                                                                label: 'Phone number'.translate(this.props.lang),
                                                                                                validate: [required('Phone is required!'.translate(this.props.lang)), phoneNumber(10, `Minimum 10 numbers required`.translate(this.props.lang))]
                                                                                            },
                                                                                            {
                                                                                                type: 'socialSecurityNumber',
                                                                                                name: 'parents[0].socialSecurityNumber',
                                                                                                label: this.props.country === 'uk' ? "Date of birth".translate(this.props.lang) : 'Social security number'.translate(this.props.lang),
                                                                                                validate: [required('Social security number is required!'.translate(this.props.lang)), checkSocialSecurityNumber('Social security number is not valid!'.translate(this.props.lang), this.props.country)]

                                                                                            },
                                                                                        ]
                                                                                    },
                                                                                    // {
                                                                                    //     type: 'col',
                                                                                    //     width: {
                                                                                    //         lg: 6,
                                                                                    //         sm: 12,
                                                                                    //         xs: 12
                                                                                    //     },
                                                                                    //     children: [
                                                                                    //         {
                                                                                    //             type: 'h6',
                                                                                    //             text: 'Guardian 2'.translate(this.props.lang),
                                                                                    //         },
                                                                                    //         {
                                                                                    //             type: 'text',
                                                                                    //             name: 'parents[1].name',
                                                                                    //             pattern: new RegExp(/^[a-zA-Z \u00c0-\u017e]$/i),
                                                                                    //             label: 'First and last name'.translate(this.props.lang),
                                                                                    //             validate: [required('First and last name is required!'.translate(this.props.lang))]
                                                                                    //             // validate: this.props.parents && this.props.parents[1] && (this.props.parents[1].name || this.props.parents[1].address || this.props.parents[1].phone || this.props.parents[1].socialSecurityNumber) ? [required('First and last name is required!'.translate(this.props.lang))] : null
                                                                                    //         },

                                                                                    //         {
                                                                                    //             type: 'text',
                                                                                    //             name: 'parents[1].address',
                                                                                    //             label: 'Address'.translate(this.props.lang),
                                                                                    //             validate: [required('Address is required!'.translate(this.props.lang))]
                                                                                    //             // validate: this.props.parents && this.props.parents[1] && (this.props.parents[1].name || this.props.parents[1].address || this.props.parents[1].phone || this.props.parents[1].socialSecurityNumber) ? [required('Address is required!'.translate(this.props.lang))] : null

                                                                                    //         },
                                                                                    //         {
                                                                                    //             type: 'phone',
                                                                                    //             name: 'parents[1].phone',
                                                                                    //             label: 'Phone number'.translate(this.props.lang),
                                                                                    //             validate: [required('Phone is required!'.translate(this.props.lang))]
                                                                                    //             // validate: this.props.parents && this.props.parents[1] && (this.props.parents[1].name || this.props.parents[1].address || this.props.parents[1].phone || this.props.parents[1].socialSecurityNumber) ? [required('Phone is required!'.translate(this.props.lang))] : null

                                                                                    //         },
                                                                                    //         {
                                                                                    //             type: 'socialSecurityNumber',
                                                                                    //             name: 'parents[1].socialSecurityNumber',
                                                                                    //             label: this.props.country === 'uk' ? "Date of birth".translate(this.props.lang) : 'Social security number'.translate(this.props.lang),
                                                                                    //             validate: [required('Social security number is required!'.translate(this.props.lang))]
                                                                                    //             // validate: this.props.parents && this.props.parents[1] && (this.props.parents[1].name || this.props.parents[1].address || this.props.parents[1].phone || this.props.parents[1].socialSecurityNumber) ? [required('Social security number is required!'.translate(this.props.lang))] : null

                                                                                    //         },

                                                                                    //     ]
                                                                                    // }
                                                                                ]
                                                                            }
                                                                        ]}>

                                                                    </FromBuilder>
                                                                </div>
                                                            )
                                                        }
                                                        if (answer.type == 'parentsData') {
                                                            let initialParents = [{ email: this.props.uData.email, name: this.props.uData.userData.name, phone: this.props.uData.phone, socialSecurityNumber: this.props.uData.socialSecurityNumber, address: `${this.props.uData.street}, ${this.props.uData.zip} ${this.props.uData.city}` }, {}]
                                                            if (this.props.country === 'uk') {
                                                                let selectedChild = this.state.tmpAnswers['message-6-1'] // ssn
                                                                let child = this.state.listOfChildren?.filter(item => item.socialSecurityNumber === selectedChild)?.[0];
                                                                if (child && child?.secondParentData?._id) {
                                                                    initialParents[1] = { email: child.secondParentData.email, name: child.secondParentData.userData?.name, phone: child.secondParentData.phone, socialSecurityNumber: child.secondParentData.socialSecurityNumber, address: `${child.secondParentData.street}, ${child.secondParentData.zip} ${child.secondParentData.city}` }
                                                                }
                                                            }

                                                            return (
                                                                <div className="parents-data">
                                                                    <FromBuilder
                                                                        countryTwoLetter={this.props.countryTwoLetter}
                                                                        country={this.props.country}
                                                                        buttonText={'Submit'.translate(this.props.lang)}
                                                                        initialValues={{ parents: initialParents }}
                                                                        lang={this.props.lang}
                                                                        onSubmit={(data) => {
                                                                            // console.log(data)
                                                                            this.answer(item.id, data.parents)
                                                                        }}

                                                                        fields={[
                                                                            {
                                                                                type: 'row',
                                                                                children: [
                                                                                    {
                                                                                        type: 'col',
                                                                                        width: {
                                                                                            lg: 6,
                                                                                            sm: 12,
                                                                                            xs: 12
                                                                                        },
                                                                                        children: [
                                                                                            {
                                                                                                type: 'h6',
                                                                                                text: 'Guardian 1'.translate(this.props.lang),
                                                                                            },
                                                                                            {
                                                                                                type: 'text',
                                                                                                name: 'parents[0].name',
                                                                                                label: 'First and last name'.translate(this.props.lang),
                                                                                                pattern: new RegExp(/^[a-zA-Z \u00c0-\u017e]$/i),
                                                                                                validate: [required('First and last name is required!'.translate(this.props.lang))]
                                                                                            },
                                                                                            {
                                                                                                type: 'text',
                                                                                                name: 'parents[0].address',
                                                                                                label: 'Address'.translate(this.props.lang),
                                                                                                validate: [required('Address is required!'.translate(this.props.lang))]
                                                                                            },
                                                                                            {
                                                                                                type: 'phone',
                                                                                                name: 'parents[0].phone',
                                                                                                label: 'Phone number'.translate(this.props.lang),
                                                                                                validate: [required('Phone is required!'.translate(this.props.lang)), phoneNumber(10, `Minimum 10 numbers required`.translate(this.props.lang))]
                                                                                            },
                                                                                            {
                                                                                                type: 'socialSecurityNumber',
                                                                                                name: 'parents[0].socialSecurityNumber',
                                                                                                label: this.props.country === 'uk' ? "Date of birth".translate(this.props.lang) : 'Social security number'.translate(this.props.lang),
                                                                                                validate: [required('Social security number is required!'.translate(this.props.lang)), checkSocialSecurityNumber('Social security number is not valid!'.translate(this.props.lang), this.props.country)]
                                                                                            },
                                                                                        ]
                                                                                    },
                                                                                    {
                                                                                        type: 'col',
                                                                                        width: {
                                                                                            lg: 6,
                                                                                            sm: 12,
                                                                                            xs: 12
                                                                                        },
                                                                                        children: [
                                                                                            {
                                                                                                type: 'h6',
                                                                                                text: 'Guardian 2'.translate(this.props.lang),
                                                                                            },
                                                                                            {
                                                                                                type: 'text',
                                                                                                name: 'parents[1].name',
                                                                                                pattern: new RegExp(/^[a-zA-Z \u00c0-\u017e]$/i),
                                                                                                label: 'First and last name'.translate(this.props.lang),
                                                                                                validate: [required('First and last name is required!'.translate(this.props.lang))]
                                                                                                // validate: this.props.parents && this.props.parents[1] && (this.props.parents[1].name || this.props.parents[1].address || this.props.parents[1].phone || this.props.parents[1].socialSecurityNumber) ? [required('First and last name is required!'.translate(this.props.lang))] : null
                                                                                            },

                                                                                            {
                                                                                                type: 'text',
                                                                                                name: 'parents[1].address',
                                                                                                label: 'Address'.translate(this.props.lang),
                                                                                                validate: [required('Address is required!'.translate(this.props.lang))]
                                                                                                // validate: this.props.parents && this.props.parents[1] && (this.props.parents[1].name || this.props.parents[1].address || this.props.parents[1].phone || this.props.parents[1].socialSecurityNumber) ? [required('Address is required!'.translate(this.props.lang))] : null

                                                                                            },
                                                                                            {
                                                                                                type: 'phone',
                                                                                                name: 'parents[1].phone',
                                                                                                label: 'Phone number'.translate(this.props.lang),
                                                                                                validate: [required('Phone is required!'.translate(this.props.lang)), phoneNumber(10, `Minimum 10 numbers required`.translate(this.props.lang))]
                                                                                                // validate: this.props.parents && this.props.parents[1] && (this.props.parents[1].name || this.props.parents[1].address || this.props.parents[1].phone || this.props.parents[1].socialSecurityNumber) ? [required('Phone is required!'.translate(this.props.lang))] : null

                                                                                            },
                                                                                            {
                                                                                                type: 'socialSecurityNumber',
                                                                                                name: 'parents[1].socialSecurityNumber',
                                                                                                label: this.props.country === 'uk' ? "Date of birth".translate(this.props.lang) : 'Social security number'.translate(this.props.lang),
                                                                                                validate: [required('Social security number is required!'.translate(this.props.lang)), checkSocialSecurityNumber('Social security number is not valid!'.translate(this.props.lang), this.props.country)]
                                                                                                // validate: this.props.parents && this.props.parents[1] && (this.props.parents[1].name || this.props.parents[1].address || this.props.parents[1].phone || this.props.parents[1].socialSecurityNumber) ? [required('Social security number is required!'.translate(this.props.lang))] : null

                                                                                            },

                                                                                        ]
                                                                                    }
                                                                                ]
                                                                            }
                                                                        ]}>

                                                                    </FromBuilder>
                                                                </div>
                                                            )
                                                        }

                                                    })
                                                }
                                            </div>
                                        }
                                    </>
                                )
                        })
                    }
                </div>
                {this.state.answers && Object.keys(this.state.answers).length >= 1 ? <Button onClick={this.back} className="back-button" outline color="primary">{'Back'.translate(this.props.lang)}</Button> : null}
                {
                    this.state.addNewChildModal ?
                        <AddNewChildModal
                            {...this.props}
                            isOpen={this.state.addNewChildModal}
                            closeModal={() => this.setState({ addNewChildModal: null })}
                            getChildren={() => this.getChildren()}
                        />
                        :
                        null
                }


                <Modal isOpen={this.state.ringModal} centered>
                    {/* <ModalHeader style={{ margin: 'auto' }}>{'Ring 112'.translate(this.props.lang)}</ModalHeader> */}
                    <ModalBody className='delete-modal'>
                        {/* <Isvg src={warrning} /> */}
                        <p className='message'>
                            {'Welcome back when your condition is not life threatening.'.translate(this.props.lang)}
                        </p>
                    </ModalBody>
                    <ModalFooter className='buttons-right-blue'>
                        <Button style={{ minWidth: 100 }} color="primary" onClick={() => this.setState({ ringModal: null },/* () => this.props.ring112Audio(false)*/)}>{'Close'.translate(this.props.lang)}</Button>
                    </ModalFooter>
                </Modal>


            </div>
        )
    }
}


const selector = formValueSelector('form');

export default connect(state => {
    return { parents: selector(state, 'parents') }

}, {

})(ReferralChatHelper);
