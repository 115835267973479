import React, { Component } from "react";
import Page from "../../containers/page";

import {
  Container,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  Button,
  UncontrolledAlert,
  Modal, ModalBody, ModalFooter, ModalHeader, FormFeedback
} from "reactstrap";

import Isvg from "react-inlinesvg";
import ModalFormBuilder from "../../components/forms/modalFormBuilder";

import FormBuilder from "../../components/forms/formBuilder";
import { required, emailAddress } from "../../components/forms/validation";

import ListBuilder from "../../components/listBuilder";
import DeleteModal from "../../components/deleteModal";
import {
  getSearchParams,
  generateSearchLink,
  generateSearchLinkMultiple,
} from "../../helpers/linkHelper";
import { API_ENDPOINT } from "../../constants";
import ErrorModal from "../../components/errorModal";
import { formValueSelector, change } from "redux-form"; // ES6
import { connect } from "react-redux";

import { Player, Controls } from "@lottiefiles/react-lottie-player";
import Search from "../../components/search";
import profileUser from "../../assets/images/user.png";
import notification from "../../assets/svg/notification.svg";
import moreIcon from "../../assets/svg/more.svg";
import garbageIcon from '../../assets/svg/garbage.svg';
import viewIcon from '../../assets/svg/eye.svg';
import savedIcon from '../../assets/svg/saved-icon.svg';
import faceidIcon from '../../assets/svg/faceid-icon.svg';

import warrning from '../../assets/svg/warrning.svg'
import infoIcon from "../../assets/svg/link-new-tab.svg";
import xIcon from '../../assets/svg/x.svg';
import SocialSecurityNumber from '../../components/forms/fields/socialSecurityNumber';
import Checkbox from "../../components/forms/fields/checkbox";
import DatePicker2 from '../../components/forms/fields/datePicker';
import { Calendar, Day, momentLocalizer } from 'react-big-calendar';
import CustomToolbar from '../referral/calendarViews/CustomToolbar';
import EventComponent from '../referral/calendarViews/EventComponentt';
// import AnswerDaybookModal from '../../components/answerDaybookModal';
import AnswerDaybookModal from '../forms/diaryForm';

import TestResultsPatient from "../patients/components/testResultsPatient";
import ListOfMedicinesPatient from "../patients/components/listOfMedicinesPatient";
import FaceApiComponent from "../../faceApi/faceApiComponent";

import ListCheckBox from '../../components/forms/fields/listCheckbox'
import Toggle from '../../components/forms/fields/toggle';
import InfoModal from '../../components/infoModal';
import PdfSaveV2 from '../../components/pdfSaveV2';
import moment from 'moment';
import { defaultError } from '../../components/TwoFactorAuthModal';

import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
} from 'chart.js';

import { Pie, Bar, Line, Chart } from 'react-chartjs-2';
import DiaryGraph from "../../components/diaryGraph";
import PatientDocuments from "../patients/components/patientDocuments";


ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController
);

const localizer = momentLocalizer(moment)

function parseJSON(response) {
  return response.json().then((json) => {
    return {
      result: json,
      status: response.status,
    };
  });
}

function parseText(response) {
  return response.text().then(text => {
    return {
      result: text,
      status: response.status
    }
  })
}
function calculateAverage(arr, daybookChartType) {

  let sum = 0;
  let count = 0;

  for (let i = 0; i < arr.length; i++) {
    if (daybookChartType === 'monthly') {
      if (arr[i]) {
        sum += arr[i];
        count++;
      }
    } else {
      if (arr[i] !== null) {
        sum += arr[i];
        count++;
      }
    }

  }

  if (count === 0) {
    return 0; // to avoid division by zero
  }

  const average = sum / count;
  return Math.round(average * 100) / 100; // rounding to two decimal places
}


/**
* My profile page
* @author   Milan Stanojevic
*/
class Account extends Component {
  constructor(props) {
    super(props);
    this.player = React.createRef();
    this.player2 = React.createRef();
    this.state = {
      num: 0,
      showPlayer: false,
      showPlayer2: false,
      ...props.initialData,
      form: false,
      useState: true,
      params: {
        entries: 10,
        page: 0,
        "query-group": null,
      },
      items: [],
      formItems: [],
      eventItems: [],
      questionaryAnswers: {},
      calendarEvents: [],
      total: 0,
      loading: true,
      groups: [],
      languages: [],
      clinicIdx: 0,
      view: typeof window != 'undefined' && window.innerWidth < 768 ? 'month' : 'month',
      showForm: false,
      activeTab: 1,
      enterprise: false,
      hideWhoIsTheCaseRegarding: true,
      numberOfScanedPhotos: 0,
      scanedTypes: [],
      days: 7,
      day: new Date(),
      guideMessage: 'Turn your head straight towards the camera',
      showInfoText: false,
      daybookChartType: 'daily',
      backgroundColors: ['#F63030', '#568AC8', '#60BEBB', '#F9EF68', '#F2BE57', '#F257E8', '#AF57F2', '#5CF257', '#73C931', '#6BF0D5'],
    };
  }

  showPlayer = () => {
    this.setState({
      showPlayer: true,
    });
  };

  hidePlayer = () => {
    this.setState({
      showPlayer: false,
    });
  };

  get = () => {
    this.props.updateMeta(
      this.props.generateSeoTags(this.state, this.props.lang)
    );

    for (let i = 0; i < this.props.loadData.length; i++) {
      this.props.loadData[i](
        window.fetch,
        this.props.lang,
        this.props[0].match,
        this.state.useState
          ? this.state.params
          : getSearchParams(this.props[0].location.search, {
            entries: 10,
            page: 0,
            "query-group": null,
          })
      ).then((data) => {
        this.setState(
          {
            ...data,
            loading: null,
          },
          () => {
            this.props.updateMeta(
              this.props.generateSeoTags(this.state, this.props.lang)
            );
          }
        );
      });
    }

    for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
      this.props.loadDataWithQuery[i](
        window.fetch,
        this.props.lang,
        this.props[0].match,
        this.state.useState
          ? { ...this.state.params, patientId: this.props.uData._id }
          : getSearchParams(this.props[0].location.search, {
            entries: 10,
            page: 0,
            "query-group": null,
          })
      ).then((data) => {
        this.setState(
          {
            ...data,
            loading: null,
          },
          () => {
            this.props.updateMeta(
              this.props.generateSeoTags(this.state, this.props.lang)
            );
          }
        );
      });
    }
  };
  handleResize = () => {
    if (this.checkTodayTimeout) {
      clearTimeout(this.checkTodayTimeout)
    }
    this.checkTodayTimeout = setTimeout(() => {
      this.checkToday()
    }, 400);
  }

  componentDidMount() {
    if (this.props.uData?.userLevel && this.props.uData?.userLevel >= 20) this.getUserInfo();

    const checkSemble = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}/semble/check`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            alias: localStorage.getItem('groupAlias')
          })
        });

        if (response?.ok) return this.setState({ hasSemble: true });

        return this.setState({ hasSemble: false });
      } catch (err) { }
    }
    checkSemble();

    this.getClinicGroupSettings();

    if (this.props[0].location?.state?.navigateToMyChildren) {
      this.setState({ activeTab: 5, addNewChildModal: {} })
    }

    if (typeof window !== "undefined") {
      window.scrollTo(0, 0);
    }
    if (this.props[0].location.state && this.props[0].location.state.bankIdError) {
      this.setState({ bankIdError: this.props[0].location.state.bankIdError })
    }
    if (this.props[0].location.state && this.props[0].location.state.tabIndex) {
      this.setState({ activeTab: this.props[0].location.state.tabIndex }, () => this.getEvents())
    }


    this.get();
    this.setState({ showForm: true })

    this.checkGroup()
    document.addEventListener('mousedown', this.handleClick);
    window.addEventListener('resize', this.handleResize)


  }
  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick);
    document.removeEventListener('resize', this.handleResize);
    if (this.checkTodayTimeout) {
      clearTimeout(this.checkTodayTimeout)
    }
  }
  handleClick = (event) => {
    if (this.state.view == 'month' && event && event.target && event.target.className && event.target.className.indexOf) {
      // console.log(event.target.className)
      if (event.target.className.indexOf('rbc-date-cell') != -1) {
        if (event.target.children && event.target.children[0] && event.target.children[0].innerHTML) {
          let day = new Date(this.props.getStringDateTs(Math.floor(new Date(this.state.day).getTime() / 1000), 'MM/DD/YYYY'));
          day.setDate(1)
          if (event.srcElement && event.srcElement.className, event.srcElement && event.srcElement.className.indexOf('rbc-off-range') != -1) {
            if (Number(event.target.children[0].innerHTML) && Number(event.target.children[0].innerHTML) > 20) {
              day.setMonth(day.getMonth() - 1)
            } else {
              day.setMonth(day.getMonth() + 1)
            }
          }
          day.setDate(Number(event.target.children[0].innerHTML))
          this.handleSelect(day)
        }

      } else if (event.target.className.indexOf('rbc-day-bg') != -1) {
        let split = event.target.className.split('date_')
        if (split[1]) {
          let split2 = split[1].split('_date')
          if (split2[0]) {
            let day = new Date(split2[0])
            if (split2[0] && split2[0].split('-') && split2[0].split('-').length > 2) {
              let dayString = split2[0].split('-')[split2[0].split('-').length - 1]
              let monthString = split2[0].split('-')[split2[0].split('-').length - 2]
              if (dayString && Number(dayString))
                day.setDate(Number(dayString))
              if (monthString && Number(monthString))
                day.setMonth(Number(monthString) - 1)
            }
            this.handleSelect(day)

          }

        }
      }
    }

  }
  checkGroup = () => {
    if (this.props.uData && (typeof window != 'undefined' && localStorage.getItem('groupAlias'))) {
      if (this.props.uData && this.props.uData.userLevel == 1 || this.props.uData.userLevel == 2 || (typeof window != 'undefined' && localStorage.getItem('profile') == 'patient')) {
        fetch(API_ENDPOINT + '/landing/page', {
          method: 'POST',
          headers: {
            'content-type': 'application/json'
          },
          body: JSON.stringify({ alias: localStorage.getItem('groupAlias') })
        }).then(res => res.json()).then((result) => {
          if (!result.error) {
            if (result && result.enterprise) {
              this.setState({ enterprise: result.enterprise })
            }
            let hideWhoIsTheCaseRegarding = result.hideWhoIsTheCaseRegarding ? true : false;
            if (this.state.hideWhoIsTheCaseRegarding !== hideWhoIsTheCaseRegarding) {
              this.setState({ hideWhoIsTheCaseRegarding })
            }
          }
        });
      }
    }

  }

  componentDidUpdate(prevProps, prevState) {
    this.checkToday();

    if ((!prevProps?.selectedGroup && this.props.selectedGroup) || (prevProps?.selectedGroup !== this.props.selectedGroup)) {
      this.getClinicGroupSettings();
    }

    if ((prevProps?.selectedGroup !== this.props.selectedGroup || prevProps?.selectedClinic !== this.props.selectedClinic || prevState?.num !== this.state.num) && this.props.uData?.userLevel && this.props.uData?.userLevel >= 20) this.getUserInfo();

    if (prevProps[0].location.search != this.props[0].location.search) {
      this.setState(
        {
          loading: true,
        },
        () => {
          this.get();
        }
      );
    }

    if (
      typeof prevProps.selectedWaitingTimeType != "undefined" &&
      prevProps.selectedWaitingTimeType != this.props.selectedWaitingTimeType
    ) {
      if (this.props.selectedWaitingTimeType == 0) {
        this.props.changeMinValue("");
        this.props.changeMaxValue("");
      } else if (this.props.selectedWaitingTimeType == 1) {
        this.props.changeMinValue(0);
        this.props.changeMaxValue(0);
      } else if (this.props.selectedWaitingTimeType == 2) {
        this.props.changeMinValue("");
        this.props.changeMaxValue(0);
      } else if (this.props.selectedWaitingTimeType == 3) {
        this.props.changeMinValue(0);
        this.props.changeMaxValue("");
      } else if (this.props.selectedWaitingTimeType == 4) {
        this.props.changeMinValue("");
        this.props.changeMaxValue("");
      } else if (this.props.selectedWaitingTimeType == 5) {
        this.props.changeMinValue("");
        this.props.changeMaxValue("");
      }
    }
    if (prevProps.sex != this.props.sex) {
      if (this.props.sex == 0) {
        this.props.changeSex(null)
      }
    }
    if (this.props.uData && prevProps.uData && JSON.stringify(this.props.uData.faceIdPhotos) != JSON.stringify(prevProps.uData.faceIdPhotos) && this.state.faceIdModal) {
      if (this.props.uData?.userLevel && this.props.uData?.userLevel >= 20) this.getUserInfo();
      this.setState({ photosFaceId: this.props.uData.faceIdPhotos ? JSON.parse(JSON.stringify(this.props.uData.faceIdPhotos)) : [] })
    }

    if (this.state.scheduleModal != prevState.scheduleModal && this.state.scheduleModal) {
      this.checkInfoText()
    } else if (!this.state.scheduleModal && this.state.showInfoText) {
      this.setState({ showInfoText: false })
    }



    // if (prevState.graphRef1 != this.state.graphRef1 && this.state.graphRef1) {
    //   this.setState({ graphImage: this.state.graphRef1.toBase64Image() })

    // }
    // if (prevState.graphRef2 != this.state.graphRef2 && this.state.graphRef2) {
    //   this.setState({ graphImage: this.state.graphRef2.toBase64Image() })

    // }



  }
  checkToday = () => {
    if (typeof window !== 'undefined') {
      let check = document.getElementsByClassName('rbc-now');
      if (check && check.length) {
        for (let i = 0; i < check.length; i++) {
          if (check && check[i] && check[i].children && check[i].children[0] && check[i].children[0].innerHTML) {
            let calendarToday = Number(check[i].children[0].innerHTML);
            let today = this.props.getStringDateTs(Math.floor(new Date().getTime() / 1000), 'DD')
            if (today) {
              today = Number(today);
            }
            if (calendarToday !== today) {
              document.getElementsByClassName('rbc-now')[i].className = document.getElementsByClassName('rbc-now')[i].className.replace(/rbc-now/, '');
            }
          }
        }
      }


      let checkToday = document.getElementsByClassName('rbc-today');
      if (checkToday && checkToday.length) {
        for (let i = 0; i < checkToday.length; i++) {
          if (checkToday && checkToday[i] && checkToday[i].className && checkToday[i].className.indexOf('rbc-today') !== -1) {
            let checkString = `date_${this.props.getStringDateTs(Math.floor(new Date().getTime() / 1000), 'YYYY-MM-DD')}_date`;
            if (checkToday[i].className.indexOf(checkString) === -1) {
              document.getElementsByClassName('rbc-today')[i].className = document.getElementsByClassName('rbc-today')[i].className.replace(/rbc-today/, '');
            }

          }
        }
      }


    }

  }

  updateSort = (field, type) => {
    if (this.state.useState) {
      let state = this.state.params;
      state.sortField = field;
      state.sortType = type;
      this.setState({
        params: state,
      });
    } else {
      this.props[0].history.push(
        generateSearchLinkMultiple(
          this.props[0].location.search,
          { entries: 10, page: 0 },
          [
            { name: "sortField", value: field },
            { name: "sortType", value: type },
          ],
          false
        )
      );
    }
  };

  updateParams = (name, value, restart = false) => {
    if (this.state.useState) {
      if (restart) {
        let obj = {
          entries: 10,
          page: 0,
        };
        obj[name] = value;
        this.setState({ params: obj });
      } else {
        let obj = this.state.params;
        obj[name] = value;
        this.setState({ params: obj });
      }
    } else {
      this.props[0].history.push(
        generateSearchLink(
          this.props[0].location.search,
          { entries: 10, page: 0 },
          name,
          value,
          restart
        )
      );
    }
  };
  updateMultipleParams = (fields, restart = false) => {
    if (this.state.useState) {
      if (restart) {
        let obj = {
          entries: 10,
          page: 0,
        };
        for (let i = 0; i < fields.length; i++) {
          obj[fields[i].name] = fields[i].value;
        }
        this.setState({ params: obj });
      } else {
        let obj = this.state.params;
        for (let i = 0; i < fields.length; i++) {
          obj[fields[i].name] = fields[i].value;
        }
        this.setState({ params: obj });
      }
    } else {
      this.props[0].history.push(
        generateSearchLinkMultiple(
          this.props[0].location.search,
          { entries: 10, page: 0 },
          fields,
          restart
        )
      );
    }
  };

  stopAnimation = () => {
    this.player.current.play();
  };

  /**
  * Update profile data
  * @author   Milan Stanojevic
  * @Objectparam    {Object} data    form data
  */
  update = (data) => {
    data.group = this.props.selectedGroup
    this.setState(
      {
        showPlayer: true,
      },
      () => {
        this.player.current.play();
        fetch(API_ENDPOINT + "/users/account/update", {
          method: "POST",
          headers: {
            Authorization:
              typeof localStorage !== "undefined"
                ? `Bearer ${localStorage.getItem("authToken")}`
                : null,
            "content-type": "application/json",
          },
          body: JSON.stringify(data),
        })
          .then(parseJSON)
          .then(({ result, status }) => {
            if (result.error) {
              this.setState({ error: result.error, showPlayer: false });
            } else {
              this.setState(
                {
                  message: result.message,
                  showPlayer: false,
                  showPlayer2: true,
                },
                () => {
                  setTimeout(() => {
                    this.setState({ showPlayer2: false });
                  }, 1000);
                }
              );
              //this.player.current.stop();
              this.props.verifyUser(() => {
                this.forceUpdate()
              });
            }
          });
      }
    );
  };

  /**
  * Update language for user
  * @author   Milan Stanojevic
  * @Objectparam    {Object} data    form data
  */
  updateLanguage = (data) => {
    this.setState(
      {
        showPlayer: true,
        loadLang: true
      },
      () => {
        this.player.current.play();
        fetch(API_ENDPOINT + "/users/account/update-userdata", {
          method: "POST",
          headers: {
            Authorization:
              typeof localStorage !== "undefined"
                ? `Bearer ${localStorage.getItem("authToken")}`
                : null,
            "content-type": "application/json",
          },
          body: JSON.stringify(data),
        })
          .then(parseJSON)
          .then(({ result, status }) => {
            if (result.error) {
              this.setState({ error: result.error, showPlayer: false });
            } else {
              this.setState(
                {
                  message: result.message,
                  showPlayer: false,
                  showPlayer2: true,

                },
                () => {
                  setTimeout(() => {
                    this.setState({ showPlayer2: false, loadLang: false });
                  }, 1000);
                }
              );
              //this.player.current.stop();
              this.props.verifyUser(() => {
                this.setState({
                  showForm: null,
                }, () => {
                  this.setState({
                    showForm: true
                  })
                })
              });
            }
          });
      }
    );
  };

  /**
  * Update clinis list for user
  * @author   Milan Stanojevic
  * @Objectparam    {Object} data    form data
  */
  updateClinic = (data) => {
    fetch(API_ENDPOINT + "/users/account/updateClinicEntry/" + data._id, {
      method: "POST",
      headers: {
        Authorization:
          typeof localStorage !== "undefined"
            ? `Bearer ${localStorage.getItem("authToken")}`
            : null,
        "content-type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then(parseJSON)
      .then(({ result, status }) => {
        if (result.error) {
          this.setState({ error: result.error });
        } else {
          this.setState({
            message: result.message,

          });
        }

        this.props.verifyUser();

        this.setState({
          form: null,
        });
      });
  };

  /**
  * Upload profile image
  * @author   Milan Stanojevic
  */
  uploadImage = (e) => {

    let input = e.target;
    if (input.files && input.files[0]) {
      let fsize = input.files[0].size;
      let file = Math.round((fsize / 1024))

      if (file > 5120) {
        this.setState({ error: "File size limit has been reached! (max 5 MB)".translate(this.props.lang) })
      } else {
        this.setState({
          _loading: true,
        });

        let formData = new FormData();
        formData.append("file", input.files[0]);

        fetch(API_ENDPOINT + "/data/upload-image", {
          method: "POST",
          headers: {
            Accept: "application/json",
            Authorization:
              typeof localStorage !== "undefined"
                ? `Bearer ${localStorage.getItem("authToken")}`
                : null,
          },
          body: formData,
        })
          .then(parseText).then(({ result, status }) => {
            if (status != 200) {
              this.setState({ error: result })
            } else {
              this.updateLanguage({ profilePicture: result });
              this.setState({
                _loading: null
              })
            }
            /*.then((res) => res.text())
            .then((img) => {
              this.update({ profilePicture: img, notifications: this.props.uData.notifications });
              this.setState({
                _loading: null,
              });
            });*/
          })
      }
    }
  };
  disconnect = (field) => {
    this.setState({ loading: true }, () => {


      fetch(API_ENDPOINT + "/users/account/disconnect", {
        method: "POST",
        headers: {
          "content-type": "application/json",
          Authorization:
            typeof localStorage !== "undefined"
              ? `Bearer ${localStorage.getItem(
                "authToken"
              )}`
              : null,
        },
        body: JSON.stringify({
          field: field
        })
      }
      )
        .then((res) => res.json())
        .then((result) => {
          this.props.verifyUser(() => {
            this.forceUpdate()
          });
        });
    })
  }
  updateOtherEmails = (data) => {

    if (data) {
      let error = false;
      for (var key in data) {
        if (data[key].email && !/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/i.test(data[key].email)) {
          data[key].error = 'Invalid email address'.translate(this.props.lang);
          error = true;
        } else {
          data[key].error = null;
        }
      }
      if (error) {
        this.setState({ editOtherEmailsModal: data })
      } else {
        let obj = {}
        for (var key in data) {
          obj[key] = {
            email: data[key].email,
            phone: data[key].phone,
          }
        }
        this.setState(
          {
            showPlayer: true,
            loadLang: true,
            editOtherEmailsModal: null
          },
          () => {
            this.player.current.play();
            fetch(API_ENDPOINT + "/users/account/update-contacts", {
              method: "POST",
              headers: {
                Authorization:
                  typeof localStorage !== "undefined"
                    ? `Bearer ${localStorage.getItem("authToken")}`
                    : null,
                "content-type": "application/json",
              },
              body: JSON.stringify(obj),
            })
              .then(parseJSON)
              .then(({ result, status }) => {
                if (result.error) {
                  this.setState({ error: result.error, showPlayer: false });
                } else {
                  this.setState(
                    {
                      message: result.message,
                      showPlayer: false,
                      showPlayer2: true,

                    },
                    () => {
                      setTimeout(() => {
                        this.setState({ showPlayer2: false, loadLang: false });
                      }, 1000);
                    }
                  );
                  //this.player.current.stop();
                  this.props.verifyUser(() => {
                    this.setState({
                      showForm: null,
                    }, () => {
                      this.setState({
                        showForm: true
                      })
                    })
                  });
                }
              });
          }
        );
      }


    }
  }
  updateFaceIdPhotos = (closeModal = true) => {
    let data = {
      faceIdPhotos: this.state.photosFaceId,
    };
    let haveScannededFaceId = false;
    if (data && data.faceIdPhotos && data.faceIdPhotos.length) {
      haveScannededFaceId = true;
    }

    this.setState(
      {
        showPlayer: true,
        loadLang: true,
        photosFaceId: null,
        haveScannededFaceId
      },
      () => {
        this.player.current.play();
        fetch(API_ENDPOINT + "/users/account/update-faceid", {
          method: "POST",
          headers: {
            Authorization:
              typeof localStorage !== "undefined"
                ? `Bearer ${localStorage.getItem("authToken")}`
                : null,
            "content-type": "application/json",
          },
          body: JSON.stringify(data),
        })
          .then(parseJSON)
          .then(({ result, status }) => {
            if (result.error) {
              this.setState({ error: result.error, showPlayer: false });
            } else {
              if (closeModal) {
                this.setState({ faceIdModal: null })
              }
              this.setState(
                {
                  message: result.message,
                  showPlayer: false,
                  showPlayer2: true,
                  numberOfScanedPhotos: 0

                },
                () => {
                  setTimeout(() => {
                    this.setState({ showPlayer2: false, loadLang: false });
                  }, 1000);
                }
              );
              //this.player.current.stop();
              this.props.verifyUser(() => {
                this.setState({
                  showForm: null,
                }, () => {
                  this.setState({
                    showForm: true
                  })
                })
              });
            }
          });
      }
    );
  }
  updateFaceIdEnabled = (value) => {
    let data = {
      faceIdEnabled: value
    }
    this.setState(
      {
        loadLang: true,
      },
      () => {
        fetch(API_ENDPOINT + "/users/account/update-faceid-enabled", {
          method: "POST",
          headers: {
            Authorization:
              typeof localStorage !== "undefined"
                ? `Bearer ${localStorage.getItem("authToken")}`
                : null,
            "content-type": "application/json",
          },
          body: JSON.stringify(data),
        })
          .then(parseJSON)
          .then(({ result, status }) => {
            if (result.error) {
              this.setState({ error: result.error, loadLang: false });
            } else {
              //this.player.current.stop();
              this.props.verifyUser(() => {
                this.setState({ loadLang: false })
              });
            }
          });
      }
    );
  }

  deleteLinkUser(data) {
    fetch(API_ENDPOINT + '/users/users/patient/search/delete', {
      method: 'POST',
      headers: {
        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
        'content-type': 'application/json'
      },
      body: JSON.stringify({
        parentPatient: this.props.uData._id,
        id: data._id,
        patientProfile: true
      })
    }).then(parseJSON).then(({ result, status }) => {
      if (result) {
        this.get()
      }
    })

  }

  addNewChild = (data) => {
    fetch(API_ENDPOINT + '/users/users/patient/add-new-child', {
      method: 'POST',
      headers: {
        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
        'content-type': 'application/json'
      },
      body: JSON.stringify({
        name: data.name,
        socialSecurityNumber: data.socialSecurityNumber,
        sex: data.sex,
        parentUser: this.props.uData._id
      })
    }).then(parseJSON).then(({ result, status }) => {
      if (result && result.error) {
        this.setState({
          error: result.error
        })
      } else {
        this.setState({
          addNewChildModal: null,
          check: false
        })
        this.get()
      }

    })
  }

  handleSelect = (event) => {
    var startDate = moment.unix(event.getTime() / 1000).format(this.props.dateFormat);
    let startTs = Math.floor(event.getTime() / 1000)


    let obj = {
      date: startDate,
    }

    let arr = [];
    let selectedEvent = []
    // console.log(this.state.eventItems)
    let eventItems = [...this.state.eventItems]
    // let formsAndEventID = []

    let eventDate = event.getDate();
    let eventMonth = event.getMonth();



    // if (eventItems.filter(item => new Date(item.data && item.data.startTime * 1000).getDate() == eventDate && new Date(item.data && item.data.startTime * 1000).getMonth() == eventMonth).length) {
    if (eventItems.filter(item => new Date(this.props.getStringDateTs(item.data?.startTime, 'MM/DD/YYYY')).getDate() == eventDate && new Date(this.props.getStringDateTs(item.data?.startTime, 'MM/DD/YYYY')).getMonth() == eventMonth).length) {
      // arr = [...eventItems.filter(item => (item.data && item.data.startTime) == event.getTime() / 1000)[0].formularID]
      // let items = eventItems.filter(item => new Date(item.data && item.data.startTime * 1000).getDate() == eventDate && new Date(item.data && item.data.startTime * 1000).getMonth() == eventMonth)
      let items = eventItems.filter(item => new Date(this.props.getStringDateTs(item.data?.startTime, 'MM/DD/YYYY')).getDate() == eventDate && new Date(this.props.getStringDateTs(item.data?.startTime, 'MM/DD/YYYY')).getMonth() == eventMonth)


      for (let i = 0; i < items.length; i++) {
        // formsAndEventID = [{forms: items[i].formularID, index: i, eventID: items[i]._id}, ...formsAndEventID]
        arr = [...items[i].formularID, ...arr]
        if (items[i].formsAnswers)
          selectedEvent = [...items[i].formsAnswers, ...selectedEvent]
      }
      // let eventID = eventItems.filter(item => (item.data && item.data.startTime) == event.getTime() / 1000)[0]._id
      // let selectedEvent = eventItems.filter(item => (item.data && item.data.startTime) == event.getTime() / 1000)[0]

      fetch(API_ENDPOINT + '/users/patient/daybook-forms', {
        method: 'POST',
        headers: {
          'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
          'content-type': 'application/json'
        },
        body: JSON.stringify({ arr: arr, checkStatusDate: startDate })
      }).then(parseJSON).then(({ result, status }) => {
        if (status >= 200 && status < 300) {
          this.setState({
            scheduleModal: obj,
            // eventID: eventID,
            formItems: result.forms,
            selectedEventAnswers: selectedEvent,
            dayStatus: result.dayStatus ? result.dayStatus : null
          }, () => {

          })
        }

      })
    } else {
      this.setState({
        scheduleModal: obj,
        formItems: [],
      })
    }


  }

  getEvents = (openDay = false, patient = false) => {
    let obj = {
      // doctor: this.props.uData._id,
      patient: this.state.patient ? this.state.patient._id : this.props.uData._id,
      day: this.state.day ? this.state.day : new Date(),
      view: this.state.view,
      alias: localStorage.getItem('groupAlias')
    }
    fetch(API_ENDPOINT + '/users/search/addevent/daybook/event/patient', {
      method: 'POST',
      headers: {
        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
        'content-type': 'application/json'
      },
      body: JSON.stringify(obj)
    }).then(parseJSON).then(({ result, status }) => {
      if (status >= 200 && status < 300) {
        let arr = []
        arr = [...result]
        this.setState({
          eventItems: arr
        }, () => {
          let arr = []
          // for (let i = 0; i < this.state.eventItems.length; i++) {
          //   if (this.state.eventItems[i] && !arr.filter(item => item.data && this.state.eventItems[i].data && item.data.startTime && this.state.eventItems[i].data.startTime && new Date(item.data.startTime * 1000).getDate() == new Date(this.state.eventItems[i].data.startTime * 1000).getDate() && new Date(item.data.startTime * 1000).getMonth() == new Date(this.state.eventItems[i].data.startTime * 1000).getMonth()).length) {
          //     arr.push(this.state.eventItems[i])
          //   } else {
          //     let index = arr.findIndex(item => item.data && this.state.eventItems[i].data && item.data.startTime && this.state.eventItems[i].data.startTime && new Date(item.data.startTime * 1000).getDate() == new Date(this.state.eventItems[i].data.startTime * 1000).getDate() && new Date(item.data.startTime * 1000).getMonth() == new Date(this.state.eventItems[i].data.startTime * 1000).getMonth())
          //     if (this.state.eventItems[i].colors && arr[index]) {
          //       let colorItems = this.state.eventItems[i].colors.split('-')
          //       for (let i = 0; i < colorItems.length; i++) {
          //         if (!arr[index].colors) {
          //           arr[index].colors = `${colorItems[i]}`
          //         } else if (arr[index].colors.indexOf(colorItems[i]) == -1) {
          //           arr[index].colors += `-${colorItems[i]}`
          //         }

          //       }
          //     }

          //   }

          // }

          for (let i = 0; i < this.state.eventItems.length; i++) {
            if (this.state.eventItems[i] && !arr.filter(item => item.data && this.state.eventItems[i].data && item.data.startTime && this.state.eventItems[i].data.startTime && new Date(this.props.getStringDateTs(item.data.startTime, 'MM/DD/YYYY')).getDate() == new Date(this.props.getStringDateTs(this.state.eventItems[i].data.startTime, 'MM/DD/YYYY')).getDate() && new Date(this.props.getStringDateTs(item.data.startTime, 'MM/DD/YYYY')).getMonth() == new Date(this.props.getStringDateTs(this.state.eventItems[i].data.startTime, 'MM/DD/YYYY')).getMonth()).length) {
              arr.push(this.state.eventItems[i])
            } else {
              let index = arr.findIndex(item => item.data && this.state.eventItems[i].data && item.data.startTime && this.state.eventItems[i].data.startTime && new Date(this.props.getStringDateTs(item.data.startTime, 'MM/DD/YYYY')).getDate() == new Date(this.props.getStringDateTs(this.state.eventItems[i].data.startTime, 'MM/DD/YYYY')).getDate() && new Date(this.props.getStringDateTs(item.data.startTime, 'MM/DD/YYYY')).getMonth() == new Date(this.props.getStringDateTs(this.state.eventItems[i].data.startTime, 'MM/DD/YYYY')).getMonth())
              if (this.state.eventItems[i].colors && arr[index]) {
                let colorItems = this.state.eventItems[i].colors.split('-')
                for (let i = 0; i < colorItems.length; i++) {
                  if (!arr[index].colors) {
                    arr[index].colors = `${colorItems[i]}`
                  } else if (arr[index].colors.indexOf(colorItems[i]) == -1) {
                    arr[index].colors += `-${colorItems[i]}`
                  }

                }
              }

            }

          }

          this.setState({
            calendarEvents: arr
          }, () => {
            if (openDay && this.state.scheduleModal && this.state.scheduleModal.date) {
              this.handleSelect(new Date(this.state.scheduleModal.date))
            }
          })
        })
      }

    })


    this.getLastModified()

  }
  getLastModified = () => {

    fetch(API_ENDPOINT + '/users/search/daybook/event/patient/lastModified', {
      method: 'POST',
      headers: {
        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
        'content-type': 'application/json'
      },
      body: JSON.stringify({
        patient: this.state.patient ? this.state.patient._id : this.props.uData._id,
        alias: localStorage.getItem('groupAlias')
      })
    }).then(parseJSON).then(({ result, status }) => {
      if (result) {
        this.setState({ daybookLastUpdate: result.lastModified })
      }
    })
  }

  generateSections = (data) => {
    let sections = {};
    let items = [{ type: 0 }, ...data];
    let lastSectionId = -1;
    for (let i = 0; i < items.length; i++) {
      if (items[i].type == 0) {
        lastSectionId++;
        sections[lastSectionId] = {
          name: items[i].name,
          displayOnReferral: false,
          questions: [],
          conditions: items[i].conditions ? items[i].conditions : [],
          displayOnReferral: items[i].displayOnReferral,
          fontStyle: items[i].fontStyle,
          fontSize: items[i].fontSize,
          fontWeight: items[i].fontWeight,

        }
      }
      if (lastSectionId != -1 && items[i].type != 0) {


        let wikiLinks = [];

        items[i].wikiLinks = wikiLinks;


        if (items[i].type == 1) {
          let displayOnReferral = false;
          if (items[i].answers) {
            for (let j = 0; j < items[i].answers.length; j++) {
              if (items[i].answers[j].text) {
                displayOnReferral = true;
              }
            }
          }

          sections[lastSectionId].questions.push({
            id: items[i].id,
            type: items[i].type,
            name: items[i].name,
            answers: items[i].answers,
            conditions: items[i].conditions ? items[i].conditions : [],
            displayOnReferral: displayOnReferral,
            wikiLinks: items[i].wikiLinks,
            fontStyle: items[i].fontStyle,
            fontSize: items[i].fontSize,
            fontWeight: items[i].fontWeight,
          });

        } else if (items[i].type == 2) {
          let displayOnReferral = false;
          if (items[i].answers) {
            for (let j = 0; j < items[i].answers.length; j++) {

              if (items[i].answers[j].text) {
                displayOnReferral = true;
              }
            }
          }
          sections[lastSectionId].questions.push({
            id: items[i].id,
            type: items[i].type,
            name: items[i].name,
            answers: items[i].answers,
            conditions: items[i].conditions ? items[i].conditions : [],
            answerPrefix: items[i].answerPrefix,
            userInput: items[i].userInput,
            displayOnReferral: displayOnReferral || items[i].answerPrefix ? true : false,
            wikiLinks: items[i].wikiLinks,
            fontStyle: items[i].fontStyle,
            fontSize: items[i].fontSize,
            fontWeight: items[i].fontWeight,

          })
        } else if (items[i].type == 3) {
          sections[lastSectionId].questions.push({
            id: items[i].id,
            type: items[i].type,
            name: items[i].name,
            conditions: items[i].conditions ? items[i].conditions : [],
            answerPrefix: items[i].answerPrefix,
            wikiLinks: items[i].wikiLinks,
            displayOnReferral: true,
            fontStyle: items[i].fontStyle,
            fontSize: items[i].fontSize,
            fontWeight: items[i].fontWeight,
          })

        } else if (items[i].type == 4) {
          sections[lastSectionId].questions.push({
            id: items[i].id,
            type: items[i].type,
            name: items[i].name,
            text: items[i].text,
            userInput: items[i].userInput,
            conditions: items[i].conditions ? items[i].conditions : [],
            wikiLinks: items[i].wikiLinks,
            fontStyle: items[i].fontStyle,
            fontSize: items[i].fontSize,
            fontWeight: items[i].fontWeight,
          })
        } else if (items[i].type == 15) {
          sections[lastSectionId].questions.push({
            id: items[i].id,
            type: items[i].type,
            name: items[i].name,
            text: items[i].text,
            userInput: items[i].userInput,
            conditions: items[i].conditions ? items[i].conditions : [],
            wikiLinks: items[i].wikiLinks,
            fontStyle: items[i].fontStyle,
            fontSize: items[i].fontSize,
            fontWeight: items[i].fontWeight,
          })

        } else if (items[i].type == 5) {
          sections[lastSectionId].questions.push({
            id: items[i].id,
            type: items[i].type,
            name: items[i].name,
            conditions: items[i].conditions ? items[i].conditions : [],
            wikiLinks: items[i].wikiLinks,
            displayOnReferral: true,
            fontStyle: items[i].fontStyle,
            fontSize: items[i].fontSize,
            fontWeight: items[i].fontWeight,
            score: items[i].score
          })

        } else if (items[i].type == 6) {
          sections[lastSectionId].questions.push({
            id: items[i].id,
            type: items[i].type,
            name: items[i].name,
            conditions: items[i].conditions ? items[i].conditions : [],
            wikiLinks: items[i].wikiLinks,
            displayOnReferral: true,
            fontStyle: items[i].fontStyle,
            fontSize: items[i].fontSize,
            fontWeight: items[i].fontWeight,
            score: items[i].score
          })

        } else if (items[i].type == 7) {
          sections[lastSectionId].questions.push({
            id: items[i].id,
            type: items[i].type,
            name: items[i].name,
            conditions: items[i].conditions ? items[i].conditions : [],
            wikiLinks: items[i].wikiLinks,
            displayOnReferral: true,
            fontStyle: items[i].fontStyle,
            fontSize: items[i].fontSize,
            fontWeight: items[i].fontWeight,
          })

        } else if (items[i].type == 8) {
          sections[lastSectionId].questions.push({
            id: items[i].id,
            type: items[i].type,
            name: items[i].name,
            conditions: items[i].conditions ? items[i].conditions : [],
            wikiLinks: items[i].wikiLinks,
            displayOnReferral: true,
            fontStyle: items[i].fontStyle,
            fontSize: items[i].fontSize,
            fontWeight: items[i].fontWeight,
          })

        } else if (items[i].type == 9) {
          sections[lastSectionId].questions.push({
            id: items[i].id,
            type: items[i].type,
            name: items[i].name,
            conditions: items[i].conditions ? items[i].conditions : [],
            wikiLinks: items[i].wikiLinks,
            displayOnReferral: true,
            fontStyle: items[i].fontStyle,
            fontSize: items[i].fontSize,
            fontWeight: items[i].fontWeight,
          })

        } else if (items[i].type == 10) {
          sections[lastSectionId].questions.push({
            id: items[i].id,
            type: items[i].type,
            name: items[i].name,
            userInput: items[i].userInput,
            conditions: items[i].conditions ? items[i].conditions : [],
            wikiLinks: items[i].wikiLinks,
            displayOnReferral: true,
            fontStyle: items[i].fontStyle,
            fontSize: items[i].fontSize,
            fontWeight: items[i].fontWeight,
          })
        } else if (items[i].type == 12) {
          sections[lastSectionId].questions.push({
            id: items[i].id,
            type: items[i].type,
            name: items[i].name,
            conditions: items[i].conditions ? items[i].conditions : [],
            wikiLinks: items[i].wikiLinks,
            displayOnReferral: true,
            fontStyle: items[i].fontStyle,
            fontSize: items[i].fontSize,
            fontWeight: items[i].fontWeight,
            score: items[i].score
          })
        } else if (items[i].type == 14) {
          sections[lastSectionId].questions.push({
            id: items[i].id,
            type: items[i].type,
            name: items[i].name,
            conditions: items[i].conditions ? items[i].conditions : [],
            wikiLinks: items[i].wikiLinks,
            displayOnReferral: true,
            fontStyle: items[i].fontStyle,
            fontSize: items[i].fontSize,
            fontWeight: items[i].fontWeight,
            score: items[i].score
          })

        } else if (items[i].type == 13) {
          sections[lastSectionId].questions.push({
            id: items[i].id,
            type: items[i].type,
            name: items[i].name,
            conditions: items[i].conditions ? items[i].conditions : [],
            wikiLinks: items[i].wikiLinks,
            displayOnReferral: true,
            fontStyle: items[i].fontStyle,
            fontSize: items[i].fontSize,
            fontWeight: items[i].fontWeight,
            score: items[i].score
          })

        } else if (items[i].type == 16) {
          sections[lastSectionId].questions.push({
            id: items[i].id,
            type: items[i].type,
            name: items[i].name,
            conditions: items[i].conditions ? items[i].conditions : [],
            wikiLinks: items[i].wikiLinks,
            displayOnReferral: true,
            fontStyle: items[i].fontStyle,
            fontSize: items[i].fontSize,
            fontWeight: items[i].fontWeight,
            blocks: items[i].blocks,
          })

        } else if (items[i].type == 17) {
          sections[lastSectionId].questions.push({
            id: items[i].id,
            type: items[i].type,
            name: items[i].name,
            conditions: items[i].conditions ? items[i].conditions : [],
            wikiLinks: items[i].wikiLinks,
            displayOnReferral: true,
            fontStyle: items[i].fontStyle,
            fontSize: items[i].fontSize,
            fontWeight: items[i].fontWeight,
            blocks: items[i].blocks,

          })

        } else if (items[i].type == 18) {
          sections[lastSectionId].questions.push({
            id: items[i].id,
            type: items[i].type,
            name: items[i].name,
            conditions: items[i].conditions ? items[i].conditions : [],
            wikiLinks: items[i].wikiLinks,
            displayOnReferral: true,
            fontStyle: items[i].fontStyle,
            fontSize: items[i].fontSize,
            fontWeight: items[i].fontWeight,
            answers: items[i].answers,

          })

        } else if (items[i].type == 19) {
          sections[lastSectionId].questions.push({
            id: items[i].id,
            type: items[i].type,
            name: items[i].name,
            conditions: items[i].conditions ? items[i].conditions : [],
            answerPrefix: items[i].answerPrefix,
            wikiLinks: items[i].wikiLinks,
            displayOnReferral: true,
            fontStyle: items[i].fontStyle,
            fontSize: items[i].fontSize,
            fontWeight: items[i].fontWeight,
            required: items[i].required,
            graphType: items[i].graphType
          })

        }
      }
    }

    return Object.values(sections);
  }

  showStats = () => {

    if (this.state.seeStatistics && this.state.seeStatistics.dateFrom && this.state.seeStatistics.dateTo) {
      let data = {
        dateFrom: this.state.seeStatistics.dateFrom,
        dateTo: this.state.seeStatistics.dateTo,
        patientID: this.state.patient ? this.state.patient._id : this.props.uData._id,
        // clinic: this.props.selectedClinic,
        // group: this.props.selectedGroup,
        alias: localStorage.getItem('groupAlias')
      }
      if (this.state.daybookChartType) {
        data.daybookChartType = this.state.daybookChartType
      }
      // if (this.graphRef) {
      //   this.graphRef = null;
      // }

      this.setState({ graphImage: null }, () => {
        fetch(API_ENDPOINT + '/users/daybook/stats', {
          method: 'POST',
          headers: {
            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            'content-type': 'application/json'
          },
          body: JSON.stringify(data)
        }).then(res => res.json()).then((result) => {
          if (result) {
            // this.setState({ daybookStats: result })
            this.setState({ daybookStats: null }, () => {
              this.setState({ daybookStats: result, graphRef1: null, graphRef2: null, resetGraphImage: new Date().getTime(), diaryGraphImage: null })
            })
          }
        })
      })

    }

  }
  exportStats = () => {
    if (this.state.seeStatistics && this.state.seeStatistics.dateFrom && this.state.seeStatistics.dateTo) {
      let data = {
        dateFrom: this.state.seeStatistics.dateFrom,
        dateTo: this.state.seeStatistics.dateTo,
        patientID: this.state.patient ? this.state.patient._id : this.props.uData._id,
        // clinic: this.props.selectedClinic,
        // group: this.props.selectedGroup,
        alias: localStorage.getItem('groupAlias'),
        lang: this.props.lang
      }
      fetch(API_ENDPOINT + '/users/daybook/stats/export', {
        method: 'POST',
        headers: {
          'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
          'content-type': 'application/json'
        },
        body: JSON.stringify(data)
      }).then(res => res.json()).then((result) => {
        if (result.success) {
          window.location.href = API_ENDPOINT + `${result.file}`;
        }
        this.setState({
          success: result.success,
          file: result.file
        })
        this.setState({
          exportStatsModal: null,
        })
      })
    }

  }
  checkInfoText = () => {
    if (this.state.scheduleModal && this.state.formItems && this.state.formItems.length) {
      let check = false;
      let date = new Date(this.state.scheduleModal.date + ' 23:59:59')
      date.setDate(date.getDate() + this.state.days)
      for (let i = 0; i < this.state.formItems.length; i++) {
        if ((this.state.dayStatus && this.state.dayStatus === 'expired') || (!this.state.dayStatus && Math.floor(new Date(date).getTime() / 1000) < Math.floor(new Date().getTime() / 1000))) {
          if (this.state.selectedEventAnswers && this.state.selectedEventAnswers.length) {
            let arrFormIdx = this.state.selectedEventAnswers.findIndex(item => item.formID == this.state.formItems[i]._id)
            if (!this.state.selectedEventAnswers[arrFormIdx]) {
              check = true;
            }
          } else {
            check = true;
          }
        }
      }
      if (check != this.state.showInfoText) {
        this.setState({ showInfoText: check })
      }

    }
  }
  deleteMyAccount = () => {
    if (this.state.deleteMyAccountModal && this.state.deleteMyAccountModal.reason && this.state.deleteMyAccountModal.clinicGroups && this.state.deleteMyAccountModal.clinicGroups.length) {
      let data = { ...this.state.deleteMyAccountModal };



      fetch(API_ENDPOINT + '/users/account/delete-account/request', {
        method: 'POST',
        headers: {
          'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
          'content-type': 'application/json'
        },
        body: JSON.stringify(data)
      }).then(res => res.json()).then((result) => {
        if (result.error) {
          this.setState({ error: result.error })
        } else {
          this.setState({ deleteMyAccountModal: null, deleteMyAccountInfoModal2: 'Your request has been sent'.translate(this.props.lang) })
        }
      })
    }
  }

  getUserInfo = () => {
    this.setState({ userInfo: null }, async () => {
      const response = await fetch(API_ENDPOINT + `/e-connect/doctors/profile?groupId=${this.props.selectedGroup}&departmentId=${this.props.selectedClinic}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage?.getItem?.('authToken')}`
        }
      });
      if (!response || !response.ok) return;
      const result = await response.json();
      if (!result || !result.user || !result.user._id || String(result.user._id) !== String(this.props.uData?._id)) return;
      this.setState({ userInfo: result.user });
    });
  }

  refresh = () => { this.setState(prev => ({ ...prev, num: (prev.num || 0) + 1 })) }

  changeEConnectStatus = () => {
    if (!this.state.userInfo || (!this.state.userInfo.isAdmin && !this.state.userInfo.isDoctor)) return;
    this.setState({ loading: true }, async () => {
      const newState = {
        loading: false,
        error: null,
        eConnectModal: null,
      };
      const response = await fetch(API_ENDPOINT + '/e-connect/doctors', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage?.getItem?.('authToken')}`
        },
        body: JSON.stringify({
          action: this.state.eConnectModal
        })
      });
      if (!response) {
        newState.error = defaultError;
        return this.setState(newState, () => {
          this.refresh();
        })
      };
      const result = await response.json();
      if (!result || result.error) {
        newState.error = result.error || defaultError;
        return this.setState(newState, () => {
          this.refresh();
        })
      }

      this.setState(newState, () => {
        this.refresh();
      })
    });
  }

  getClinicGroupSettings = () => {
    this.setState({ clinicGroupSettings: null }, async () => {
      if (!this.props?.selectedGroup) return;
      const response = await fetch(API_ENDPOINT + '/data/clinic-group-settings/' + this.props.selectedGroup);
      if (!response || !response.ok) return;
      const result = await response.json();
      if (!result || result.error || !result.clinicGroupSettings) return;
      this.setState({ clinicGroupSettings: result.clinicGroupSettings });
    })
  }

  render() {
    let params = {};
    if (this.state.useState) {
      params = this.state.params;
    } else {
      params = getSearchParams(this.props[0].location.search, {
        entries: 10,
        page: 0,
      });
    }


    const waitingTimeDisabled = [
      [true, true],
      [true, true],
      [false, true],
      [true, false],
      [false, false],
      [false, false],
    ];

    const defaultOptions = {
      loop: true,
      autoplay: true,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };

    let selectedGroupIdx = -1;
    if (this.props.selectedGroup && this.props.uData?.clinicGroups?.length) {
      for (let i = 0; i < this.props.uData.clinicGroups.length; i++) {
        if (this.props.uData.clinicGroups[i]._id == this.props.selectedGroup) {
          selectedGroupIdx = i;
          break;
        }
      }
    }
    let patientGroups = []
    if (this.props.uData && this.props.uData.patientGroups && this.props.uData.patientGroups.length) {
      if (typeof window != 'undefined' && localStorage.groupAlias) {
        if (this.props.uData.patientGroups.filter(el => el.alias && el.alias.toLowerCase() == localStorage.groupAlias.toLowerCase()).length) {
          let mainClGroup = this.props.uData.patientGroups.filter(el => el.alias && el.alias.toLowerCase() == localStorage.groupAlias.toLowerCase())[0].mainClGroup;
          if (mainClGroup) {
            for (let i = 0; i < this.props.uData.patientGroups.length; i++) {
              if (this.props.uData.patientGroups[i].mainClGroup == mainClGroup) {
                patientGroups.push(this.props.uData.patientGroups[i])
              }
            }
          } else {
            patientGroups.push(this.props.uData.patientGroups.filter(el => el.alias && el.alias.toLowerCase() == localStorage.groupAlias.toLowerCase())[0])
          }

        } else {
          patientGroups = this.props.uData.patientGroups;
        }
      }
    }

    return (
      <div className={this.props.autoHideSidebar ? "dashboard dashboard-auto-hide" : "dashboard"}>
        <div className={this.props.to}>
          <Container fluid>
            <Row>

              {
                this.props.uData.userLevel == 1 || this.props.uData.userLevel == 2 || (typeof window != 'undefined' && localStorage.getItem('profile') == 'patient') ?
                  <Col lg="12" className="page-navbar-wrap">
                    <div className="page-navbar">
                      <div className='page-navbar-list no-border no-dropdown'>
                        <div>
                          <button className={this.state.activeTab == 1 ? 'active-button' : ''} onClick={() => {
                            if (this.state.activeTab != 1) {
                              this.setState({ activeTab: 1 })
                            }
                          }}>{'My profile'.translate(this.props.lang)}</button>


                        </div>
                        {
                          this.state.enterprise ?
                            <div>
                              <button className={this.state.activeTab == 2 ? 'active-button' : ''} onClick={() => {
                                if (this.state.activeTab != 2) {
                                  this.setState({ activeTab: 2 }, () => this.getEvents())
                                }
                              }}>{'Daybook'.translate(this.props.lang)}</button>


                            </div>
                            :
                            null
                        }
                        {
                          this.state.enterprise ?
                            <div>
                              <button className={this.state.activeTab == 3 ? 'active-button' : ''} onClick={() => {
                                if (this.state.activeTab != 3) {
                                  this.setState({ activeTab: 3 })
                                }
                              }}>{'Test results'.translate(this.props.lang)}</button>


                            </div>
                            :
                            null
                        }
                        {
                          this.state.enterprise ?
                            <div>
                              <button className={this.state.activeTab == 4 ? 'active-button' : ''} onClick={() => {
                                if (this.state.activeTab != 4) {
                                  this.setState({ activeTab: 4 })
                                }
                              }}>{'List of medicines'.translate(this.props.lang)}</button>
                            </div>
                            :
                            null
                        }
                        {
                          this.state.enterprise && this.state.hasSemble ? <div>
                            <button className={this.state.activeTab == 6 ? 'active-button' : ''} onClick={() => {
                              if (this.state.activeTab != 6) {
                                this.setState({ activeTab: 6 })
                              }
                            }}>{'Patient Documents'.translate(this.props.lang)}</button>
                          </div>
                            :
                            null
                        }
                        {
                          !this.state.hideWhoIsTheCaseRegarding ?
                            <div>
                              <button className={this.state.activeTab == 5 ? 'active-button' : ''} onClick={() => {
                                if (this.state.activeTab != 5) {
                                  this.setState({ activeTab: 5 })
                                }
                              }}>{'My children'.translate(this.props.lang)}</button>
                            </div>
                            :
                            null
                        }

                      </div>
                    </div>

                  </Col>
                  :
                  null
              }


              <Col lg="12">

                {this.state.error ? (
                  <ErrorModal lang={this.props.lang}
                    isOpen={this.state.error}
                    toggle={() => this.setState({ error: null })}

                  >
                    {this.state.error.translate(this.props.lang)}
                  </ErrorModal>
                ) : null}
                {this.state.bankIdError ? (
                  <ErrorModal lang={this.props.lang}
                    isOpen={this.state.bankIdError}
                    toggle={() => this.setState({ bankIdError: null })}

                  >
                    {this.state.bankIdError.translate(this.props.lang)}
                  </ErrorModal>
                ) : null}

                <div className="panel">
                  {
                    this.state.activeTab == 1 || !(this.props.uData.userLevel == 1 || this.props.uData.userLevel == 2 || (typeof window != 'undefined' && localStorage.getItem('profile') == 'patient')) ?
                      <Row>
                        <Col lg="12">
                          <div className="account-title">
                            <h5 className="component-header">
                              {this.props.linksMeta &&
                                this.props.linksMeta[this.props[0].location.pathname]
                                ? this.props.linksMeta[
                                this.props[0].location.pathname
                                ][this.props.lang]
                                : ""}
                            </h5>
                          </div>
                          <div className="account-header">
                            <div className="profile">
                              <div style={{ position: 'relative', marginRight: 15 }}>
                                <img
                                  src={
                                    this.props.uData && this.props.uData.profilePicture
                                      ? API_ENDPOINT + this.props.uData.profilePicture
                                      : profileUser
                                  }
                                />
                                {
                                  this.state._loading ?
                                    <div className="file-progress-loader">
                                      <div>
                                        <Player
                                          onEvent={(event) => {
                                            if (event === "load" && this.player && this.player.current) this.player.current.play();
                                          }}
                                          ref={this.player} // set the ref to your class instance
                                          autoplay={true}
                                          loop={true}
                                          controls={true}
                                          src="/lf30_editor_l5cxzdyf.json"
                                          renderer={'svg'}
                                          style={{ height: "128px", width: "128px", }}
                                        ></Player>
                                        <h6>{'Processing file...'.translate(this.props.lang)}</h6>
                                      </div>

                                    </div>
                                    :
                                    null
                                }
                              </div>
                              <div className="account-profile-button-wrap">
                                <Button
                                  className="upload-file"
                                  onClick={() => {
                                    this.uploadButton.click();
                                  }}
                                >
                                  {'Upload'.translate(this.props.lang)}
                                  <input
                                    type="file"
                                    title={"No file chosen".translate(this.props.lang)}
                                    accept=".jpg, .jpeg, .JPG, .JPEG, .png, .gif"
                                    ref={(node) => {
                                      this.uploadButton = node;
                                    }}
                                    onChange={this.uploadImage}
                                  />
                                </Button>

                                <Button
                                  className="upload-file"
                                  onClick={() => {
                                    this.update({ profilePicture: null, notifications: this.props.uData.notifications });
                                  }}
                                >
                                  {'Remove'.translate(this.props.lang)}
                                </Button>
                                {(this.props.uData.userLevel == 1 || this.props.uData.userLevel == 2 || (typeof window != 'undefined' && localStorage.getItem('profile') == 'patient')) && this.props.uData.faceIdEnv ?
                                  <>
                                    <Button
                                      className="face-id"
                                      onClick={() => {
                                        this.setState({ faceIdModal: true, photosFaceId: this.props.uData.faceIdPhotos ? JSON.parse(JSON.stringify(this.props.uData.faceIdPhotos)) : [] })
                                      }}
                                    >
                                      {'Face ID configuration'.translate(this.props.lang)}
                                    </Button>
                                    <div className="toggle-face-id-wrap">
                                      <Toggle value={this.props.uData && this.props.uData.faceIdEnabled}
                                        label={"Enable Face ID".translate(this.props.lang)}
                                        onChange={(e) => {
                                          let value = e.target.checked ? true : false
                                          this.updateFaceIdEnabled(value)

                                        }} />
                                    </div>


                                  </>
                                  :
                                  null
                                }
                              </div>

                            </div>
                            {!this.props.secondaryLanguageDisabled ? <div className="language">
                              <label for="languages">{'Language'.translate(this.props.lang)}</label>
                              <select
                                value={this.props.uData && this.props.uData.language}
                                onChange={(e) => {
                                  this.updateLanguage({ language: e.target.value });
                                }}
                              >
                                {this.state.languages &&
                                  this.state.languages.map((item, idx) => {
                                    return (
                                      <option key={idx} value={item._id}>
                                        {item.name?.translate(this.props.lang)}
                                      </option>
                                    );
                                  })}
                              </select>
                            </div> : null}
                          </div>
                        </Col>
                        <Col lg="12">
                          <div className="form-group-row">
                            <div className="form-group-col">
                              <FormGroup className="account-details">
                                {this.props.uData.userLevel == 1 || this.props.uData.userLevel == 2 || (typeof window != 'undefined' && localStorage.getItem('profile') == 'patient') ?
                                  "First and last name".translate(
                                    this.props.lang
                                  ) : "Title, first and last name".translate(
                                    this.props.lang
                                  )}
                                : <br /> <strong>{this.props.formatName(this.props.uData.userLevel == 1 || this.props.uData.userLevel == 2 || (typeof window != 'undefined' && localStorage.getItem('profile') == 'patient') ? '' : this.props.uData.userData.title, this.props.uData.userData.name)}</strong>
                              </FormGroup>

                              <FormGroup className="account-details">

                                {
                                  this.props.uData && this.props.uData.userLevel == 20 ?
                                    <div className="email-tooltip" >
                                      <div className="email-tooltip-on"><Isvg src={infoIcon} /> <div className="chat-icons-tooltip">{"This email address is used when changing or resetting a forgotten password.".translate(this.props.lang)}</div></div>

                                      <div>{"Email address".translate(this.props.lang)}: <br />{" "}</div>
                                    </div>
                                    :
                                    <div>{"Email address".translate(this.props.lang)}: <br />{" "}</div>
                                }
                                <strong>{this.props.uData.userLevel == 1 || this.props.uData.userLevel == 2 || this.props.uData.userLevel > 20 || (typeof window != 'undefined' && localStorage.getItem('profile') == 'patient') || true ? this.props.uData.email : this.props.uData && this.props.uData.contacts && this.props.uData.contacts[this.props.selectedGroup] && this.props.uData.contacts[this.props.selectedGroup].email ? this.props.uData.contacts[this.props.selectedGroup].email : ''}</strong>
                                {
                                  !(this.props.uData.userLevel == 1 || this.props.uData.userLevel == 2 || this.props.uData.userLevel > 20 || (typeof window != 'undefined' && localStorage.getItem('profile') == 'patient')) && this.props.uData && this.props.uData.contacts ?
                                    <div className='edit-other-emails-wrap' onClick={() => {
                                      this.setState({ editOtherEmailsModal: JSON.parse(JSON.stringify(this.props.uData.contacts)) })
                                    }}>
                                      {'Edit other mail addresses'.translate(this.props.lang)}
                                    </div>
                                    :
                                    null
                                }
                              </FormGroup>


                            </div>

                            <div className="form-group-col">
                              {this.props.uData.userLevel != 1 && this.props.uData.userLevel != 2 && (typeof window != 'undefined' && localStorage.getItem('profile') != 'patient') ? (
                                <div className="account-details">
                                  <FormGroup className="account-details-container">
                                    {"User level".translate(this.props.lang)}: <br />{" "}
                                    <strong>
                                      {" "}
                                      {Object.translate(
                                        this.props.uData,
                                        "userLevelName",
                                        this.props.lang
                                      )}
                                    </strong>
                                  </FormGroup>
                                </div>
                              ) : null}
                              {this.props.uData.userLevel != 1 && this.props.uData.userLevel != 2 && (typeof window != 'undefined' && localStorage.getItem('profile') != 'patient') ? (
                                <FormGroup className="account-details-container">
                                  {"Username".translate(this.props.lang)}: <br />{" "}
                                  <strong>{this.props.uData.username}</strong>
                                </FormGroup>
                              ) : null}
                            </div>

                            <div className="form-group-col">
                              {this.props.uData.userLevel != 1 && this.props.uData.userLevel != 2 && (typeof window != 'undefined' && localStorage.getItem('profile') != 'patient') && this.state.clinicGroupSettings?.bankIdAuthentication ? (
                                <FormGroup className="account-details">
                                  {"BankID".translate(this.props.lang)}: <br />
                                  {this.props.uData.bankId ? (
                                    <>
                                      <strong>{"Connected".translate(this.props.lang)} </strong>
                                      <Button
                                        onClick={() => this.setState({ disconnectModal: 'bankId' })}
                                        color="primary"
                                        size="sm"
                                      >
                                        {"Disconnect".translate(this.props.lang)}
                                      </Button>
                                    </>
                                  ) : (
                                    <>
                                      {" "}
                                      <strong>
                                        {"Not connected".translate(this.props.lang)}
                                      </strong>{" "}
                                      <Button
                                        onClick={() => {
                                          fetch(
                                            API_ENDPOINT +
                                            "/users/bankid/v2/account/start",
                                            {
                                              method: "GET",
                                              headers: {
                                                "content-type": "application/json",
                                                Authorization:
                                                  typeof localStorage !== "undefined"
                                                    ? `Bearer ${localStorage.getItem(
                                                      "authToken"
                                                    )}`
                                                    : null,
                                              },
                                            }
                                          )
                                            .then((res) => res.json())
                                            .then((result) => {
                                              if (result.redirect_url) {
                                                window.location.replace(
                                                  result.redirect_url
                                                );
                                              }
                                            });
                                        }}
                                        color="primary"
                                        size="sm"
                                      >
                                        {"Connect".translate(this.props.lang)}
                                      </Button>
                                    </>
                                  )}
                                </FormGroup>
                              ) : null}

                              {this.props.uData.userLevel != 1 && this.props.uData.userLevel != 2 && (typeof window != 'undefined' && localStorage.getItem('profile') != 'patient') && this.state.clinicGroupSettings?.netIdAuthentication ? (
                                <FormGroup className="account-details">
                                  {"Net ID (SITHS)".translate(this.props.lang)}:
                                  &nbsp;
                                  {this.props.uData.netId ? (

                                    <>
                                      <strong>{"Connected".translate(this.props.lang)} </strong>
                                      <Button
                                        onClick={() => this.setState({ disconnectModal: 'netId' })}
                                        color="primary"
                                        size="sm"
                                      >
                                        {"Disconnect".translate(this.props.lang)}
                                      </Button>
                                    </>
                                  ) : (
                                    <>
                                      {" "}
                                      <br />{" "}
                                      <strong>
                                        {"Not connected".translate(this.props.lang)}
                                      </strong>{" "}
                                      <Button
                                        onClick={() => {
                                          fetch(
                                            API_ENDPOINT +
                                            "/users/netid/account/start",
                                            {
                                              method: "GET",
                                              headers: {
                                                "content-type": "application/json",
                                                Authorization:
                                                  typeof localStorage !== "undefined"
                                                    ? `Bearer ${localStorage.getItem(
                                                      "authToken"
                                                    )}`
                                                    : null,
                                              },
                                            }
                                          )
                                            .then((res) => res.json())
                                            .then((result) => {
                                              if (result.redirectUrl) {
                                                window.location.replace(
                                                  result.redirectUrl
                                                );
                                              }
                                            });
                                        }}
                                        color="primary"
                                        size="sm"
                                      >
                                        {"Connect".translate(this.props.lang)}
                                      </Button>
                                    </>
                                  )}
                                </FormGroup>
                              ) : null}
                            </div>
                          </div>
                          {this.state.clinicGroupSettings?.eConnect && (this.state.userInfo?.isAdmin || this.state.userInfo?.isDoctor) && (typeof localStorage === 'undefined' || ((typeof localStorage !== 'undefined' && !localStorage?.getItem?.('profile')) || (typeof localStorage !== 'undefined' && localStorage?.getItem?.('profile') && localStorage?.getItem?.('profile') !== 'patient'))) ? <div className="e-connect-profile-page-container">
                            <FormGroup className="account-details">
                              {"e-Connect".translate(this.props.lang)}:
                              &nbsp;
                              <strong>
                                {this.state.userInfo?.eConnectTempDisabled ? 'Disabled'.translate(this.props.lang) : 'Enabled'.translate(this.props.lang)}
                              </strong>
                              <Button style={{ paddingRight: 32, paddingLeft: 32 }} color={this.state.userInfo?.eConnectTempDisabled ? 'info' : 'danger'} onClick={() => {
                                if (!this.state.userInfo || (!this.state.userInfo.isAdmin && !this.state.userInfo.isDoctor)) return;
                                let action = this.state.userInfo?.eConnectTempDisabled ? 'enable' : 'disable';
                                this.setState({ eConnectModal: action });
                              }}>
                                {this.state.userInfo?.eConnectTempDisabled ? 'Enable'.translate(this.props.lang) : 'Disable'.translate(this.props.lang)}
                              </Button>
                            </FormGroup>
                          </div> : null}

                          {this.props.uData &&
                            this.props.uData.userLevel >= 20 && (typeof window != 'undefined' && localStorage.getItem('profile') != 'patient') &&
                            this.state.userInfo?.isClinicAdmin
                            ? (
                              <FormGroup className="account-clinics-group">
                                <div>
                                  {"My database entries".translate(this.props.lang)}{" "}
                                </div>
                                <div class="account-clinics">
                                  <Input
                                    onChange={(e) =>
                                      this.setState({ clinicIdx: e.target.value })
                                    }
                                    size="sm"
                                    type="select"
                                  >
                                    {this.props.uData?.clinicGroups[
                                      selectedGroupIdx
                                    ]?.clinics
                                      .filter(
                                        (clinic) =>
                                          this.props.uData.isGroupAdmin[
                                          this.props.selectedGroup
                                          ][clinic._id]
                                      )
                                      .map((item, idx) => {
                                        return <option value={idx}>{item.name}</option>;
                                      })}
                                  </Input>
                                  <Button
                                    onClick={() => {
                                      if (
                                        this.props.uData &&
                                        this.props.uData.userLevel >= 20 &&
                                        selectedGroupIdx != -1 &&
                                        this.props.uData?.clinicGroups?.[selectedGroupIdx]?.clinics &&
                                        this.props.uData.clinicGroups[selectedGroupIdx]
                                          .clinics[this.state.clinicIdx]
                                      ) {
                                        let form = this.props.uData.clinicGroups[
                                          selectedGroupIdx
                                        ].clinics[this.state.clinicIdx];

                                        if (
                                          form.waitingMin == "" &&
                                          form.waitingMax == ""
                                        ) {
                                          form.selectedWaitingTimeType = 0;
                                        }
                                        if (
                                          form.waitingMin == 0 &&
                                          form.waitingMax == 0
                                        ) {
                                          form.selectedWaitingTimeType = 1;
                                        }
                                        if (
                                          form.waitingMin != "" &&
                                          form.waitingMax == ""
                                        ) {
                                          form.selectedWaitingTimeType = 2;
                                        }
                                        if (
                                          form.waitingMin == "" &&
                                          form.waitingMax != ""
                                        ) {
                                          form.selectedWaitingTimeType = 3;
                                        }

                                        if (
                                          form.waitingMin != "" &&
                                          form.waitingMax != ""
                                        ) {
                                          form.selectedWaitingTimeType = 5;
                                        }

                                        this.setState({ form: form });

                                        this.setState({
                                          form: form,
                                        });
                                      }
                                    }}
                                    color="primary"
                                    size="sm"
                                  >
                                    {"Edit".translate(this.props.lang)}
                                  </Button>
                                </div>
                              </FormGroup>
                            ) : null}
                          {this.state.showForm ?
                            <FormBuilder selectedGroup={this.props.selectedGroup} selectedClinic={this.props.selectedClinic} countryTwoLetter={this.props.countryTwoLetter} country={this.props.country} dateFormat={this.props.dateFormat}
                              getStringDateTs={this.props.getStringDateTs}
                              lang={this.props.lang}
                              onSubmit={(data) => this.update(data)}
                              initialValues={this.props.uData}
                              buttonText={
                                this.state.showPlayer ? (
                                  <Player
                                    onEvent={(event) => {
                                      if (event === "load" && this.player && this.player.current) this.stopAnimation();
                                    }}
                                    onStateChange={this.toggleVisibility}
                                    ref={this.player} // set the ref to your class instance
                                    autoplay={true}
                                    loop={true}
                                    controls={true}
                                    src="https://assets8.lottiefiles.com/packages/lf20_ZSRKCF.json"
                                    style={{ height: "30px", width: "30px" }}
                                  ></Player>

                                ) : this.state.showPlayer2 ? (
                                  <div style={{ display: 'flex', alignItems: 'center' }}><Player
                                    onStateChange={this.toggleVisibility}
                                    ref={this.player2} // set the ref to your class instance
                                    autoplay={true}
                                    loop={true}
                                    controls={true}
                                    src="https://assets10.lottiefiles.com/packages/lf20_CxyxLk.json"
                                    style={{ height: "30px", width: "30px" }}
                                  ></Player>
                                    {'Saved'.translate(this.props.lang)}
                                  </div>
                                ) : (
                                  "Save".translate(this.props.lang)
                                )
                              }
                              fields={[
                                {
                                  type: "row",
                                  children: [

                                    this.props.uData.userLevel == 1 || this.props.uData.userLevel == 2 || (typeof window != 'undefined' && localStorage.getItem('profile') == 'patient')
                                      ? {
                                        type: "col",
                                        width: {
                                          lg: 9,
                                          sm: 12,
                                          xs: 12,
                                        },
                                        children: [
                                          {
                                            type: "text",
                                            name: "userData.name",
                                            label: "Name".translate(this.props.lang),
                                            autoComplete: 'new-password',
                                            disabled: this.props.alias,
                                            validate: [
                                              required(
                                                "Name is required!".translate(
                                                  this.props.lang
                                                )
                                              ),
                                            ],
                                          },
                                        ],
                                      }
                                      : null,
                                    this.props.uData.userLevel == 1 || this.props.uData.userLevel == 2 || (typeof window != 'undefined' && localStorage.getItem('profile') == 'patient')
                                      ?
                                      {
                                        type: 'col',
                                        width: {
                                          lg: 3,
                                          sm: 12,
                                          xs: 12
                                        },
                                        children: [
                                          {
                                            type: 'select',
                                            name: 'userData.sex',
                                            labelInfo: "Your biological sex is needed for proper treatment".translate(this.props.lang),
                                            label: 'Sex'.translate(this.props.lang),
                                            values: [{ value: 0, name: 'Select'.translate(this.props.lang) }, { value: 'male', name: 'Male'.translate(this.props.lang) }, { value: 'female', name: 'Female'.translate(this.props.lang) }],
                                          },
                                        ]
                                      } : null,
                                    this.props.uData.userLevel == 20 && (typeof window != 'undefined' && localStorage.getItem('profile') != 'patient')
                                      ? {
                                        type: "col",
                                        width: {
                                          lg: 6,
                                          sm: 12,
                                          xs: 12,
                                        },
                                        children: [
                                          {
                                            type: "text",
                                            name: "userData.title",
                                            autoComplete: 'new-password',
                                            label: "Title".translate(
                                              this.props.lang
                                            ),
                                          },
                                        ],
                                      }
                                      : null,
                                    this.props.uData.userLevel == 1 || this.props.uData.userLevel == 2 || this.props.uData.userLevel > 20 ||
                                      (typeof window != 'undefined' && localStorage.getItem('profile') == 'patient') || true
                                      ? {
                                        type: "col",
                                        width: {
                                          lg: 6,
                                          sm: 12,
                                          xs: 12,
                                        },
                                        children: [
                                          {
                                            type: "email",
                                            name: "email",
                                            autoComplete: 'new-password',
                                            label: "Email address".translate(
                                              this.props.lang
                                            ),
                                            validate: [
                                              required(
                                                "Email address is required!".translate(
                                                  this.props.lang
                                                )
                                              ),
                                              emailAddress('Invalid email address!'.translate(this.props.lang))
                                            ],
                                          },
                                        ],
                                      }
                                      :
                                      {
                                        type: "col",
                                        width: {
                                          lg: 6,
                                          sm: 12,
                                          xs: 12,
                                        },
                                        children: [
                                          {
                                            type: "email",
                                            name: `contacts.${this.props.selectedGroup}.email`,
                                            autoComplete: 'new-password',
                                            label: "Email address".translate(
                                              this.props.lang
                                            ),
                                            validate: [
                                              required(
                                                "Email address is required!".translate(
                                                  this.props.lang
                                                )
                                              ),
                                              emailAddress('Invalid email address!'.translate(this.props.lang))
                                            ],
                                          },
                                        ],
                                      },
                                    this.props.uData.userLevel == 1 || this.props.uData.userLevel == 2 || this.props.uData.userLevel > 20 ||
                                      (typeof window != 'undefined' && localStorage.getItem('profile') == 'patient')
                                      ? {
                                        type: "col",
                                        width: {
                                          lg: 6,
                                          sm: 12,
                                          xs: 12,
                                        },
                                        children: [
                                          {
                                            type: "phone",
                                            name: "phone",
                                            autoComplete: 'new-password',
                                            label: "Phone number".translate(
                                              this.props.lang
                                            ),
                                            validate: [
                                              required(
                                                "Phone is required!".translate(
                                                  this.props.lang
                                                )
                                              ),
                                            ],
                                          },
                                        ],
                                      }
                                      :
                                      {
                                        type: "col",
                                        width: {
                                          lg: 6,
                                          sm: 12,
                                          xs: 12,
                                        },
                                        children: [
                                          {
                                            type: "phone",
                                            name: `contacts.${this.props.selectedGroup}.phone`,
                                            autoComplete: 'new-password',
                                            label: "Phone number".translate(
                                              this.props.lang
                                            ),
                                            validate: [
                                              required(
                                                "Phone is required!".translate(
                                                  this.props.lang
                                                )
                                              ),
                                            ],
                                          },
                                        ],
                                      },


                                    this.props.uData.userLevel == 1 || this.props.uData.userLevel == 2 || (typeof window != 'undefined' && localStorage.getItem('profile') == 'patient')
                                      ? {
                                        type: "col",
                                        width: {
                                          lg: 12,
                                          sm: 12,
                                          xs: 12,
                                        },
                                        children: [
                                          {
                                            type: "text",
                                            name: "street",
                                            autoComplete: 'new-password',
                                            label: "Street".translate(
                                              this.props.lang
                                            ),
                                            validate: [
                                              required(
                                                "Street is required!".translate(
                                                  this.props.lang
                                                )
                                              ),
                                            ],
                                          },
                                        ],
                                      }
                                      : null,
                                    this.props.uData.userLevel == 1 || this.props.uData.userLevel == 2 || (typeof window != 'undefined' && localStorage.getItem('profile') == 'patient')
                                      ? {
                                        type: "col",
                                        width: {
                                          lg: 9,
                                          sm: 12,
                                          xs: 12,
                                        },
                                        children: [
                                          {
                                            type: "text",
                                            name: "city",
                                            autoComplete: 'new-password',
                                            label: "City".translate(this.props.lang),
                                            validate: [
                                              required(
                                                "City is required!".translate(
                                                  this.props.lang
                                                )
                                              ),
                                            ],
                                          },
                                        ],
                                      }
                                      : null,
                                    this.props.uData.userLevel == 1 || this.props.uData.userLevel == 2 || (typeof window != 'undefined' && localStorage.getItem('profile') == 'patient')
                                      ? {
                                        type: "col",
                                        width: {
                                          lg: 3,
                                          sm: 12,
                                          xs: 12,
                                        },
                                        children: [
                                          {
                                            type: "text",
                                            name: "zip",
                                            autoComplete: 'new-password',
                                            label: "Postal code".translate(
                                              this.props.lang
                                            ),
                                            validate: [
                                              required(
                                                "Postal code is required!".translate(
                                                  this.props.lang
                                                )
                                              ),
                                            ],
                                          },
                                        ],
                                      }
                                      : null,
                                    this.props.uData.userLevel == 1 || this.props.uData.userLevel == 2 || (typeof window != 'undefined' && localStorage.getItem('profile') == 'patient') ?
                                      {
                                        type: "col",
                                        width: {
                                          lg: 12,
                                          sm: 12,
                                          xs: 12,
                                        },
                                        style: { padding: 0, marginTop: 15 },
                                        children: [
                                          {
                                            type: "col",
                                            width: {
                                              lg: 12,
                                              sm: 12,
                                              xs: 12,
                                            },
                                            children: [
                                              {
                                                type: "h6",
                                                text: 'Related parties'.translate(this.props.lang)
                                              },
                                            ],
                                          },
                                          {
                                            type: "col",
                                            width: {
                                              lg: 12,
                                              sm: 12,
                                              xs: 12,
                                            },

                                            children: [
                                              {
                                                type: "hr",
                                              },
                                            ],
                                          },
                                        ],
                                      } : null,

                                    this.props.uData.userLevel == 1 || this.props.uData.userLevel == 2 || (typeof window != 'undefined' && localStorage.getItem('profile') == 'patient') ?
                                      {
                                        type: "col",
                                        width: {
                                          lg: 6,
                                          sm: 12,
                                          xs: 12,
                                        },
                                        children: [
                                          {
                                            type: "text",
                                            name: "userData.relatedParties.name",
                                            label: "Name".translate(
                                              this.props.lang
                                            ),
                                          },
                                        ],
                                      }
                                      : null,
                                    this.props.uData.userLevel == 1 || this.props.uData.userLevel == 2 || (typeof window != 'undefined' && localStorage.getItem('profile') == 'patient') ?
                                      {
                                        type: "col",
                                        width: {
                                          lg: 6,
                                          sm: 12,
                                          xs: 12,
                                        },
                                        children: [
                                          {
                                            type: "text",
                                            name: "userData.relatedParties.relations",
                                            label: "Relations".translate(
                                              this.props.lang
                                            ),
                                          },
                                        ],
                                      }
                                      : null,
                                    this.props.uData.userLevel == 1 || this.props.uData.userLevel == 2 || (typeof window != 'undefined' && localStorage.getItem('profile') == 'patient') ?
                                      {
                                        type: "col",
                                        width: {
                                          lg: 6,
                                          sm: 12,
                                          xs: 12,
                                        },
                                        style: { padding: 0 },
                                        children: [
                                          {
                                            type: "col",
                                            width: {
                                              lg: 12,
                                              sm: 12,
                                              xs: 12,
                                            },
                                            children: [
                                              {
                                                type: "phone",
                                                name: "userData.relatedParties.phone",
                                                label: "Phone 1".translate(
                                                  this.props.lang
                                                ),
                                              },
                                            ],
                                          },
                                          {
                                            type: "col",
                                            width: {
                                              lg: 12,
                                              sm: 12,
                                              xs: 12,
                                            },

                                            children: [
                                              {
                                                type: "phone",
                                                name: "userData.relatedParties.phone2",
                                                label: "Phone 2".translate(
                                                  this.props.lang
                                                ),
                                              },
                                            ],
                                          },
                                        ],
                                      } : null,


                                    this.props.uData.userLevel == 1 || this.props.uData.userLevel == 2 || (typeof window != 'undefined' && localStorage.getItem('profile') == 'patient') ?
                                      {
                                        type: "col",
                                        width: {
                                          lg: 6,
                                          sm: 12,
                                          xs: 12,
                                        },
                                        children: [
                                          {
                                            type: "textarea",
                                            name: "userData.otherInformations",
                                            height: 152,
                                            label: "Other information".translate(
                                              this.props.lang
                                            ),
                                          },
                                        ],
                                      }
                                      : null,



                                    this.props.uData.userLevel != 1 && this.props.uData.userLevel != 2 && this.props.uData.pk && (typeof window != 'undefined' && localStorage.getItem('profile') != 'patient') ? {
                                      type: "col",
                                      width: {
                                        lg: 6,
                                        sm: 6,
                                        xs: 12,
                                      },
                                      children: [
                                        {
                                          type: "password",
                                          name: "oldPassword",
                                          autoComplete: 'new-password',
                                          label: "Old password".translate(
                                            this.props.lang
                                          ),
                                        },
                                      ],
                                    }
                                      : null,

                                    this.props.uData.userLevel != 1 && this.props.uData.userLevel != 2 && (typeof window != 'undefined' && localStorage.getItem('profile') != 'patient')
                                      ? {
                                        type: "col",
                                        width: {
                                          lg: 6,
                                          sm: 12,
                                          xs: 12,
                                        },
                                        children: [
                                          {
                                            type: "password",
                                            name: "newPassword",
                                            autoComplete: 'new-password',
                                            label: "New password".translate(
                                              this.props.lang
                                            ),
                                          },
                                        ],
                                      }
                                      : null,
                                    this.props.uData.userLevel != 1 && this.props.uData.userLevel != 2 && (typeof window != 'undefined' && localStorage.getItem('profile') != 'patient')
                                      ? {
                                        type: "col",
                                        width: {
                                          lg: 6,
                                          sm: 12,
                                          xs: 12,
                                        },
                                        children: [
                                          {
                                            type: "password",
                                            name: "repeatPassword",
                                            autoComplete: 'new-password',
                                            label: "Repeat new password".translate(
                                              this.props.lang
                                            ),
                                          },
                                        ],
                                      }
                                      : null,

                                    {
                                      type: "col",
                                      width: {
                                        lg: 12,
                                        sm: 12,
                                        xs: 12,
                                      },
                                    },
                                  ],
                                },
                              ]}
                            ></FormBuilder>
                            :
                            null
                          }

                          {this.state.form ? (
                            <ModalFormBuilder
                              country={this.props.country}
                              lang={this.props.lang}
                              dateFormat={this.props.dateFormat}
                              getStringDateTs={this.props.getStringDateTs}
                              onSubmit={(data) => {
                                this.updateClinic(data);
                              }}
                              initialValues={{ ...this.state.form }}
                              isOpen={this.state.form}
                              toggle={() => this.setState({ form: null })}
                              title={
                                this.state.form._id
                                  ? "Edit entry".translate(this.props.lang)
                                  : "Add new clinic".translate(this.props.lang)
                              }
                              buttonText={"Save".translate(this.props.lang)}
                              fields={[
                                {
                                  type: "row",
                                  children: [
                                    {
                                      type: "col",
                                      width: {
                                        lg: 12,
                                        sm: 12,
                                        xs: 12,
                                      },
                                      children: [
                                        {
                                          type: "text",
                                          name: this.state.form.vantetiderName
                                            ? "vantetiderName"
                                            : "name",
                                          label: "Clinic name".translate(
                                            this.props.lang
                                          ),
                                          disabled: true,
                                        },
                                      ],
                                    },

                                    {
                                      type: "col",
                                      width: {
                                        lg: 12,
                                        sm: 12,
                                        xs: 12,
                                      },
                                      children: [
                                        {
                                          type: "text",
                                          name: "regionName",
                                          disabled: true,
                                          label: "Region".translate(this.props.lang),
                                        },
                                      ],
                                    },
                                    {
                                      type: "col",
                                      width: {
                                        lg: 12,
                                        sm: 12,
                                        xs: 12,
                                      },
                                      children: [
                                        {
                                          type: "text",
                                          name: "subgroupName",
                                          disabled: true,
                                          label: "Subgroup".translate(
                                            this.props.lang
                                          ),
                                        },
                                      ],
                                    },
                                    {
                                      type: "col",
                                      width: {
                                        lg: 12,
                                        sm: 12,
                                        xs: 12,
                                      },
                                      children: [
                                        {
                                          type: "textarea",
                                          name: "description",
                                          label: "Description".translate(
                                            this.props.lang
                                          ),
                                        },
                                      ],
                                    },
                                    {
                                      type: "col",
                                      width: {
                                        lg: 12,
                                        sm: 12,
                                        xs: 12,
                                      },
                                      children: [
                                        {
                                          type: "text",
                                          name: "street",
                                          label: "Street name and number".translate(
                                            this.props.lang
                                          ),
                                        },
                                      ],
                                    },
                                    {
                                      type: "col",
                                      width: {
                                        lg: 3,
                                        sm: 12,
                                        xs: 12,
                                      },
                                      children: [
                                        {
                                          type: "text",
                                          name: "zip",
                                          label: "Zip code".translate(
                                            this.props.lang
                                          ),
                                        },
                                      ],
                                    },
                                    {
                                      type: "col",
                                      width: {
                                        lg: 9,
                                        sm: 12,
                                        xs: 12,
                                      },
                                      children: [
                                        {
                                          type: "text",
                                          name: "city",
                                          label: "City".translate(this.props.lang),
                                        },
                                      ],
                                    },
                                    {
                                      type: "col",
                                      width: {
                                        lg: 12,
                                        sm: 12,
                                        xs: 12,
                                      },
                                      children: [
                                        {
                                          type: "text",
                                          name: "postAddress",
                                          label: "Mailing address".translate(
                                            this.props.lang
                                          ),
                                        },
                                      ],
                                    },
                                    {
                                      type: "col",
                                      width: {
                                        lg: 6,
                                        sm: 12,
                                        xs: 12,
                                      },
                                      children: [
                                        {
                                          type: "text",
                                          name: "phone",
                                          label: "Phone".translate(this.props.lang),
                                        },
                                      ],
                                    },
                                    {
                                      type: "col",
                                      width: {
                                        lg: 6,
                                        sm: 12,
                                        xs: 12,
                                      },
                                      children: [
                                        {
                                          type: "text",
                                          name: "email",
                                          label: "Email".translate(this.props.lang),
                                        },
                                      ],
                                    },
                                    {
                                      type: "col",
                                      width: {
                                        lg: 6,
                                        sm: 12,
                                        xs: 12,
                                      },
                                      children: [
                                        {
                                          type: "text",
                                          name: "webSite",
                                          label: "Web address".translate(
                                            this.props.lang
                                          ),
                                        },
                                      ],
                                    },
                                    {
                                      type: "col",
                                      width: {
                                        lg: 6,
                                        sm: 12,
                                        xs: 12,
                                      },
                                      children: [
                                        {
                                          type: "text",
                                          name: "coordinates",
                                          disabled: true,
                                          label: "Google map coordinates".translate(
                                            this.props.lang
                                          ),
                                        },
                                      ],
                                    },
                                    {
                                      type: "col",
                                      width: {
                                        lg: 12,
                                        sm: 12,
                                        xs: 12,
                                      },
                                      children: [
                                        {
                                          type: "select",
                                          name: "selectedWaitingTimeType",
                                          label: "Waiting time type".translate(
                                            this.props.lang
                                          ),
                                          values: [
                                            {
                                              name: "No data".translate(
                                                this.props.lang
                                              ),
                                              value: 0,
                                            },
                                            {
                                              name: "0 weeks (No waiting)".translate(
                                                this.props.lang
                                              ),
                                              value: 1,
                                            },
                                            {
                                              name: "(x) weeks minimum".translate(
                                                this.props.lang
                                              ),
                                              value: 2,
                                            },
                                            {
                                              name: "(x) weeks maximum".translate(
                                                this.props.lang
                                              ),
                                              value: 3,
                                            },
                                            {
                                              name: "(x) weeks".translate(
                                                this.props.lang
                                              ),
                                              value: 4,
                                            },
                                            {
                                              name: "(x) to (y) weeks".translate(
                                                this.props.lang
                                              ),
                                              value: 5,
                                            },
                                          ],
                                        },
                                      ],
                                    },
                                    {
                                      type: "col",
                                      width: {
                                        lg: 6,
                                        sm: 12,
                                        xs: 12,
                                      },
                                      children: [
                                        {
                                          type: "text",
                                          name: "waitingMin",
                                          label: "Minimum".translate(this.props.lang),
                                          disabled:
                                            waitingTimeDisabled[
                                            this.props.selectedWaitingTimeType
                                              ? this.props.selectedWaitingTimeType
                                              : 0
                                            ][0],
                                        },
                                      ],
                                    },
                                    {
                                      type: "col",
                                      width: {
                                        lg: 6,
                                        sm: 12,
                                        xs: 12,
                                      },
                                      children: [
                                        {
                                          type: "text",
                                          name: "waitingMax",
                                          label: "Maximum".translate(this.props.lang),
                                          disabled:
                                            waitingTimeDisabled[
                                            this.props.selectedWaitingTimeType
                                              ? this.props.selectedWaitingTimeType
                                              : 0
                                            ][1],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ]}
                            ></ModalFormBuilder>
                          ) : null}


                          {
                            this.props.uData.userLevel == 1 || this.props.uData.userLevel == 2 || (typeof window != 'undefined' && localStorage.getItem('profile') == 'patient') ?

                              <div className="profile-footer">
                                <div></div>
                                <button onClick={() => {
                                  if (this.props.uData.userLevel == 1) {
                                    this.setState({ error: 'Your account and data will be deleted automatically after 7 days if you do not start a case with us.'.translate(this.props.lang) })
                                    return
                                  }
                                  if (typeof window != 'undefined' && localStorage.groupAlias && patientGroups && patientGroups.filter(el => el.alias == localStorage.groupAlias).length) {
                                    this.setState({ deleteMyAccountInfoModal: true })
                                  } else {
                                    this.setState({ groupError: true })
                                  }


                                }}>{'Delete my account'.translate(this.props.lang)}</button>
                              </div>
                              :
                              null
                          }
                        </Col>
                      </Row>
                      :
                      null
                  }
                  {
                    this.state.activeTab == 5 && (this.props.uData.userLevel == 1 || this.props.uData.userLevel == 2 || (typeof window != 'undefined' && localStorage.getItem('profile') == 'patient')) ?
                      <div>
                        <div className="display-flex-space-between">
                          <h5 className="component-header">{'My children'.translate(this.props.lang)}</h5>
                          <div>
                            <Button style={{ marginTop: 10, marginRight: 10 }} onClick={() => {
                              this.setState({
                                addNewChildModal: {}
                              })
                            }} color='primary'>{'Add a child'.translate(this.props.lang)}</Button>
                          </div>
                        </div>
                        <div>
                          {
                            this.state.items.length <= 0 ?
                              <p className="no-conversations-p">
                                {'No children registered'.translate(this.props.lang)}
                              </p>
                              :
                              null
                          }

                          <ListBuilder
                            lang={this.props.lang}
                            loading={this.state.loading}
                            total={this.state.total}
                            showNumeration={false}
                            hideFilters={true}
                            // disableFilterResults
                            actionLabel={'Action'.translate(this.props.lang)}
                            fields={
                              [
                                { type: 'text', name: 'name', label: 'Name'.translate(this.props.lang), allowSort: false },
                                { type: 'text', name: 'socialSecurityNumber', label: 'Social security number'.translate(this.props.lang), allowSort: false },
                                { type: 'text', name: 'gender', label: 'Gender'.translate(this.props.lang), allowSort: false },
                                { type: 'text', name: 'age', label: 'Age'.translate(this.props.lang), allowSort: false },

                              ]
                            }
                            rawItems={this.state.items}
                            items={this.state.items.map((item, idx) => {
                              return {
                                ...item,
                                gender: item.userData && item.userData.sex ? item.userData.sex.translate(this.props.lang) : ''
                              }
                            })}


                            actions={
                              [
                                {
                                  component: <div className="tabel-action-tooltip"><Isvg src={garbageIcon} style={{ width: 24, minWidth: 24 }} /><div style={{ width: 180 }} className="chat-icons-tooltip">{'Delete'.translate(this.props.lang)}</div> </div>,
                                  onClick: (item) => this.setState({
                                    deleteLink: item
                                  }),

                                },

                                {
                                  component: <div className="tabel-action-tooltip"><Isvg src={viewIcon} style={{ width: 24, minWidth: 24 }} /><div style={{ width: 180 }} className="chat-icons-tooltip">{'Show cases'.translate(this.props.lang)}</div> </div>,
                                  onClick: (item) => {
                                    this.props[0].history.push({
                                      pathname: '/my-data/personal-referrals/referrals',
                                      search: `?filter=${item.socialSecurityNumber}`
                                    })
                                  },
                                  condition: (item) => {
                                    if ((this.props.country === 'denmark' && Number(item.age) < 18) || Number(item.age) < 13) {
                                      return true
                                    } else {
                                      return false
                                    }
                                  }
                                },
                              ]
                            }
                            params={params}
                            sortField={params.sortField}
                            sortType={params.sortType}
                            updateSort={this.updateSort}
                            updateParams={this.updateParams}
                            updateMultipleParams={this.updateMultipleParams}
                          ></ListBuilder>
                        </div>

                      </div>
                      :
                      null
                  }
                  {
                    this.state.activeTab == 2 && (this.props.uData.userLevel == 1 || this.props.uData.userLevel == 2 || (typeof window != 'undefined' && localStorage.getItem('profile') == 'patient')) ?
                      <div>
                        <h6 className="component-header daybook-header">
                          {
                            this.state.items.length ?
                              <div>
                                <Input style={{ paddingRight: 50 }} type="select" onChange={(e) => {
                                  let patient = this.state.items.filter(item => item._id == e.target.value)[0]
                                  this.setState({
                                    patient: patient
                                  }, () => this.getEvents())

                                }}>
                                  <option value={this.props.uData._id}>
                                    {this.props.uData && this.props.uData.userData && this.props.uData.userData.name}
                                    &nbsp;
                                    {this.props.uData && this.props.uData.socialSecurityNumber}
                                  </option>
                                  {this.state.items.map(item => {
                                    return (
                                      <option value={item._id}>
                                        {item.name}
                                        &nbsp;
                                        {item.socialSecurityNumber}
                                      </option>
                                    )
                                  })}
                                </Input>
                              </div>
                              :
                              <div>

                                {this.props.uData && this.props.uData.userData && this.props.uData.userData.name}<br />
                                {this.props.uData && this.props.uData.socialSecurityNumber}
                              </div>
                          }

                          <label>{'Daybook updated'.translate(this.props.lang)}: {this.state.daybookLastUpdate ? this.props.getStringDateTs(this.state.daybookLastUpdate, this.props.dateFormat)/*moment.unix(this.state.daybookLastUpdate).format(this.props.dateFormat)*/ : 'Not modified'.translate(this.props.lang)}</label>

                          {/* <label>{'Daybook updated'.translate(this.props.lang)}: {this.state.daybookLastUpdate ? moment.unix(this.state.daybookLastUpdate).format(this.props.dateFormat) : 'Not modified'.translate(this.props.lang)}</label> */}
                          {/* {this.props.uData && this.props.uData.daybookModifiedTs && !this.state.patient ? moment.unix(this.props.uData.daybookModifiedTs).format(this.props.dateFormat) : this.state.patient && this.state.patient.daybookModifiedTs ? moment.unix(this.props.uData.daybookModifiedTs).format(this.props.dateFormat) : 'Not modified'.translate(this.props.lang)}</label> */}
                          {/* {typeof window != 'undefined' && window.innerWidth < 768 ?
                            null
                            : */}
                          <Button style={{ height: 45 }} color='primary' onClick={() => {
                            if (!this.state.seeStatistics) {
                              let tsNow = this.props.getStringDateTs(Math.floor(new Date().getTime() / 1000), this.props.dateFormat)
                              this.setState({
                                seeStatistics: {
                                  dateFrom: tsNow/*moment.unix(new Date().getTime() / 1000).format(this.props.dateFormat)*/,
                                  dateTo: tsNow/*moment.unix(new Date().getTime() / 1000).format(this.props.dateFormat)*/
                                }
                              })
                            } else {
                              this.setState({ seeStatistics: null })
                            }
                          }}>
                            {
                              !this.state.seeStatistics ?
                                'See statistics'.translate(this.props.lang)
                                :
                                'Close statistics'.translate(this.props.lang)
                            }
                          </Button>
                          {/* } */}
                        </h6>
                        <div className={`daybook-calendar-patient-wrap daybook-calendar mobile-daybook-calendar ${this.state.seeStatistics ? 'mobile-daybook-calendar-hide' : ''}`}>
                          <Calendar
                            culture={this.props.culture}
                            localizer={localizer}
                            views={typeof window != 'undefined' && window.innerWidth < 768 ? ['month'] : ['month']}
                            messages={{
                              noEventsInRange: 'There are no events in this range'.translate(this.props.lang),
                              date: 'Date'.translate(this.props.lang),
                              time: 'Time'.translate(this.props.lang),
                              event: 'Event'.translate(this.props.lang),
                              agenda: 'Agenda'.translate(this.props.lang),
                              allDay: 'All day'.translate(this.props.lang),
                              month: 'Month'.translate(this.props.lang),
                              day: 'Day'.translate(this.props.lang),
                              today: 'Today'.translate(this.props.lang),
                              previous: 'Previous'.translate(this.props.lang),
                              next: 'Next'.translate(this.props.lang),
                              tomorrow: 'Tomorrow'.translate(this.props.lang),
                              week: 'Week'.translate(this.props.lang),
                              work_week: 'Work week'.translate(this.props.lang),
                              yesterday: 'Yesterday'.translate(this.props.lang),
                              showMore: (total) => `+${total} ${'more'.translate(this.props.lang)}`


                            }}
                            events={this.state.calendarEvents.map(item => {
                              return {
                                id: item._id,
                                title: '',
                                start: new Date(this.props.getStringDateTs(item.data && item.data.startTime, 'MM/DD/YYYY')),
                                end: new Date(this.props.getStringDateTs(item.data && item.data.endTime, 'MM/DD/YYYY')),
                                allDay: true,
                                color: item.colors ? item.colors : '',
                                // formNotAnswerd: item.formNotAnswerd ? item.formNotAnswerd : true
                              }
                            })}
                            onNavigate={day => {

                              this.setState({
                                day: new Date(this.props.getStringDateTs(Math.floor(new Date(day).getTime() / 1000), 'MM/DD/YYYY')),
                                scroll: false
                              }, () => this.getEvents())

                            }}
                            date={new Date(this.props.getStringDateTs(Math.floor(new Date(this.state.day).getTime() / 1000), 'MM/DD/YYYY'))}
                            defaultDate={new Date(this.props.getStringDateTs(Math.floor(new Date(this.state.day).getTime() / 1000), 'MM/DD/YYYY'))}
                            onView={(view) => {
                              this.setState({
                                view, day: new Date()
                              }, () => this.getEvents())
                            }}
                            view={this.state.view}
                            onDrillDown={(event) => {
                              this.handleSelect(event)
                            }}

                            components={{
                              toolbar: props => (<CustomToolbar {...props} hideWeek={true} hideDay={true} hideWorkWeek={true} hideAgenda={true} lang={this.props.lang} />),
                              event: ({ event }) => {
                                return (
                                  <span style={{ display: 'flex', flexWrap: 'wrap' }}>
                                    {event.color != '' ? event.color.split('-').map((item, idx) => {
                                      return (
                                        <div style={typeof window !== 'undefined' && window.innerWidth < 768 ? { backgroundColor: item, minWidth: 5, height: 5, marginLeft: 2, marginBottom: 2 } : { backgroundColor: item, minWidth: 15, height: 15, marginLeft: 5, marginBottom: 2 }}></div>
                                      )
                                    }) : null}
                                  </span>
                                )
                              }

                            }}

                            // month look
                            dayPropGetter={(date) => {
                              // let dateString = this.props.getStringDateTs(Math.floor(new Date(date).getTime() / 1000), 'YYYY-MM-DD')/*moment.unix(new Date(date).getTime() / 1000).format('YYYY-MM-DD')*/
                              let dateString = moment.unix(new Date(date).getTime() / 1000).format('YYYY-MM-DD')

                              let isToday = false;
                              if (this.props.getStringDateTs(Math.floor(new Date().getTime() / 1000), 'YYYY-MM-DD') === dateString) {
                                isToday = true;
                              }


                              if (this.state.eventItems.filter(item => new Date(this.props.getStringDateTs(item.data?.startTime, 'MM/DD/YYYY')).getDate() == date.getDate() && new Date(this.props.getStringDateTs(item.data?.startTime, 'MM/DD/YYYY')).getMonth() == date.getMonth() && item.formNotAnswerd && item.formsAnswers).length || (this.state.eventItems.filter(item => new Date(this.props.getStringDateTs(item.data?.startTime, 'MM/DD/YYYY')).getDate() == date.getDate() && new Date(this.props.getStringDateTs(item.data?.startTime, 'MM/DD/YYYY')).getMonth() == date.getMonth() && item.formNotAnswerd).length && this.state.eventItems.filter(item => new Date(this.props.getStringDateTs(item.data?.startTime, 'MM/DD/YYYY')).getDate() == date.getDate() && new Date(this.props.getStringDateTs(item.data?.startTime, 'MM/DD/YYYY')).getMonth() == date.getMonth() && item.formsAnswers).length)) {
                                return { className: `daybook-day-block-yellow date_${dateString}_date ${isToday ? 'rbc-today' : ''}` }
                              } else if (this.state.eventItems.filter(item => new Date(this.props.getStringDateTs(item.data?.startTime, 'MM/DD/YYYY')).getDate() == date.getDate() && new Date(this.props.getStringDateTs(item.data?.startTime, 'MM/DD/YYYY')).getMonth() == date.getMonth() && item.formNotAnswerd).length) {
                                return { className: `daybook-day-block-red date_${dateString}_date ${isToday ? 'rbc-today' : ''}` }
                              } else if (this.state.eventItems.filter(item => new Date(this.props.getStringDateTs(item.data?.startTime, 'MM/DD/YYYY')).getDate() == date.getDate() && new Date(this.props.getStringDateTs(item.data?.startTime, 'MM/DD/YYYY')).getMonth() == date.getMonth() && !item.formNotAnswerd).length) {
                                return { className: `daybook-day-block-green date_${dateString}_date ${isToday ? 'rbc-today' : ''}` }
                              } else {
                                return { className: `date_${dateString}_date ${isToday ? 'rbc-today' : ''}` }
                              }
                              // if (this.state.eventItems.filter(item => new Date(item.data && item.data.startTime * 1000).getDate() == date.getDate() && new Date(item.data && item.data.startTime * 1000).getMonth() == date.getMonth() && item.formNotAnswerd && item.formsAnswers).length || (this.state.eventItems.filter(item => new Date(item.data && item.data.startTime * 1000).getDate() == date.getDate() && new Date(item.data && item.data.startTime * 1000).getMonth() == date.getMonth() && item.formNotAnswerd).length && this.state.eventItems.filter(item => new Date(item.data && item.data.startTime * 1000).getDate() == date.getDate() && new Date(item.data && item.data.startTime * 1000).getMonth() == date.getMonth() && item.formsAnswers).length)) {
                              //   return { className: `daybook-day-block-yellow date_${dateString}_date ${isToday ? 'rbc-today' : ''}` }
                              // } else if (this.state.eventItems.filter(item => new Date(item.data && item.data.startTime * 1000).getDate() == date.getDate() && new Date(item.data && item.data.startTime * 1000).getMonth() == date.getMonth() && item.formNotAnswerd).length) {
                              //   return { className: `daybook-day-block-red date_${dateString}_date ${isToday ? 'rbc-today' : ''}` }
                              // } else if (this.state.eventItems.filter(item => new Date(item.data && item.data.startTime * 1000).getDate() == date.getDate() && new Date(item.data && item.data.startTime * 1000).getMonth() == date.getMonth() && !item.formNotAnswerd).length) {
                              //   return { className: `daybook-day-block-green date_${dateString}_date ${isToday ? 'rbc-today' : ''}` }
                              // } else {
                              //   return { className: `date_${dateString}_date ${isToday ? 'rbc-today' : ''}` }
                              // }
                            }}


                            eventPropGetter={event => {
                              return { style: { backgroundColor: 'transparent' } }
                            }}

                          />
                        </div>
                        <div className={`calendar-legend-wrap calendar-legend-wrap-mobile-height ${this.state.seeStatistics ? 'mobile-daybook-calendar-hide' : ''}`}></div>
                        {/* <div className={`calendar-legend-wrap calendar-legend-wrap-mobile-height ${this.state.seeStatistics ? 'mobile-daybook-calendar-hide' : ''}`}>
                          {this.props.uData && this.props.uData.patientGroups && this.props.uData.patientGroups.length && localStorage.getItem('groupAlias') && this.props.uData.patientGroups.find(item => item.alias == localStorage.getItem('groupAlias')).daybookEvents && this.props.uData.patientGroups.find(item => item.alias == localStorage.getItem('groupAlias')).daybookEvents.length ?
                            this.props.uData.patientGroups.find(item => item.alias == localStorage.getItem('groupAlias')).daybookEvents.map(item => {
                              return (
                                <div className="legend-item">
                                  <div className="legend-color" style={{ backgroundColor: item.color }}></div>
                                  <h6>{item.name.translate(this.props.lang)}</h6>
                                </div>
                              )
                            })
                            :
                            null
                          }


                        </div> */}

                        {
                          !this.state.seeStatistics && this.state.calendarEvents.length <= 0 ?
                            <p className="no-conversations-p">
                              {'No statistics available'.translate(this.props.lang)}
                            </p>
                            :
                            null
                        }
                        {
                          this.state.seeStatistics ?
                            <Row className='daybook-statistic-wrap'>
                              <Col lg="6">

                                <div className="daybook-statistic-search-wrap">
                                  <div className="daybook-statistic-search">
                                    <h6>{'Period'.translate(this.props.lang)}</h6>
                                    <DatePicker2
                                      label={'From'.translate(this.props.lang)}
                                      dateFormat={this.props.dateFormat}
                                      value={this.state.seeStatistics.dateFrom ? this.state.seeStatistics.dateFrom : moment(new Date()).format(this.props.dateFormat)}
                                      onChange={(e) => {
                                        let seeStatistics = this.state.seeStatistics;
                                        seeStatistics.dateFrom = e;
                                        this.setState({
                                          seeStatistics,

                                        })
                                      }}
                                    />
                                    <DatePicker2
                                      label={'To'.translate(this.props.lang)}
                                      dateFormat={this.props.dateFormat}
                                      value={this.state.seeStatistics.dateTo ? this.state.seeStatistics.dateTo : moment(new Date()).format(this.props.dateFormat)}
                                      onChange={(e) => {
                                        let seeStatistics = this.state.seeStatistics;
                                        seeStatistics.dateTo = e;
                                        this.setState({
                                          seeStatistics,

                                        })
                                      }}
                                    />
                                    <Button
                                      color='primary'
                                      className='test123'
                                      disabled={this.state.seeStatistics && this.state.seeStatistics.dateFrom && this.state.seeStatistics.dateTo ? false : true}
                                      onClick={() => {
                                        this.showStats()
                                      }}>
                                      {'Show'.translate(this.props.lang)}
                                    </Button>
                                  </div>

                                  <div className='actions-buttons'>
                                    <Button color='primary' onClick={() => { this.setState({ seeStatistics: null }) }}>{'Close statistics'.translate(this.props.lang)}</Button>
                                    <Button color='primary' onClick={() => this.setState({ exportStatsModal: true, diaryGraphImage: this.getGraphImage?.() })}>{'Export'.translate(this.props.lang)}</Button>
                                  </div>

                                </div>

                                <div className="daybook-statistic-body-wrap">
                                  <div style={{ display: 'flex' }}>
                                    <div style={{ width: 'auto', minWidth: '50%', maxWidth: '50%' }}>
                                      <h4>{'Period overview'.translate(this.props.lang)}</h4>
                                      <div className='overview-head'>
                                        <h6>{'Number of days answered'.translate(this.props.lang)}:</h6>
                                        <span>{this.state.daybookStats && this.state.daybookStats.numberOfDays ? this.state.daybookStats.numberOfDays.length : 0}</span>
                                      </div>
                                      <div className='overview-body'>
                                        {
                                          this.state.daybookStats && this.state.daybookStats.items && this.state.daybookStats.items.map((item, idx) => {
                                            return (
                                              <div className="overview-body-item-wrap">
                                                <div className="overview-body-item">
                                                  <div className="overview-body-color" style={{ backgroundColor: item.color }}></div>
                                                  <h6>{item.name.translate(this.props.lang)}</h6>
                                                </div>
                                                <span>{item.count ? item.count : 0}</span>
                                              </div>
                                            )
                                          })
                                        }

                                      </div>
                                    </div>
                                    <div style={{ width: '50%' }}>
                                      {/* <h4>{'Scale 0-10'.translate(this.props.lang)}</h4> */}
                                      {/* <div className='overview-head-scale'>
                                                        <h6>{'Number of days answered'.translate(this.props.lang)}:</h6>
                                                        <span>{this.state.daybookStats && this.state.daybookStats.days ? this.state.daybookStats.days.length : 0}</span>
                                                    </div> */}
                                      <div className='overview-body'>
                                        {
                                          this.state.daybookStats && this.state.daybookStats.itemsScale && this.state.daybookStats.itemsScale.map((item, idx) => {
                                            return (
                                              <div className="overview-body-item-wrap-scale" style={{ display: 'block' }}>
                                                <div className="overview-body-item-scale">
                                                  {/* <div className="overview-body-color" style={{ backgroundColor: item.color }}></div> */}
                                                  <h6><strong>{item.name && item.name.translate(this.props.lang)}</strong></h6>
                                                  <br />
                                                </div>

                                                <div>{'Average:'.translate(this.props.lang)} <span>{item.counter && item.points ? Math.round(item.points / item.counter * 100) / 100 : 0}</span></div>
                                              </div>
                                            )
                                          })
                                        }

                                        {
                                          this.state.daybookStats?.diaryGraphDataset?.filter(item => item?.counts?.filter(el => el).length)?.map((item, idx) => {
                                            return (
                                              <div className="overview-body-item-wrap-scale" style={{ display: 'block' }}>
                                                <div className="overview-body-item-scale">
                                                  <h6><strong>{item.name}</strong></h6>
                                                  <br />
                                                </div>

                                                <div>{'Average:'.translate(this.props.lang)} <span>{calculateAverage(item.counts, this.state.daybookChartType)}</span></div>
                                              </div>
                                            )
                                          })
                                        }

                                      </div>
                                    </div>
                                  </div>
                                </div>

                              </Col>
                              <Col lg="6">
                                {
                                  this.state.daybookStats && (this.state.daybookStats.dataset?.length || this.state.daybookStats.diaryGraphDataset?.length) ?
                                    <div className="daybook-statistic-graph-select-wrap">
                                      <Label>{'Chart type'.translate(this.props.lang)}</Label>
                                      <Input
                                        type='select'
                                        onChange={(e) => {
                                          let val = e.target.value
                                          this.setState({
                                            daybookChartType: val
                                          }, () => this.showStats())
                                        }}
                                        value={this.state.daybookChartType}
                                      >

                                        <option value={'daily'}>
                                          {'Daily chart'.translate(this.props.lang)}
                                        </option>
                                        <option value={'monthly'}>
                                          {'Monthly chart'.translate(this.props.lang)}
                                        </option>

                                      </Input>
                                    </div>
                                    :
                                    null
                                }
                                {/* {
                                  this.state.daybookChartType == 'monthly' ?
                                    this.state.daybookStats && this.state.daybookStats.dataset && this.state.daybookStats.dataset.length ?
                                      <div className="daybook-statistic-graph-wrap">

                                        <Line
                                          ref={(node) => { if (!this.state.graphRef1) { this.setState({ graphRef1: node }) } }}
                                          options={{
                                            animation: {
                                              duration: 0
                                            },
                                            resposive: true,
                                            maintainAspectRatio: false,
                                            plugins: {
                                              tooltip: {
                                                callbacks: {
                                                  label: function (tooltipItem, data) {
                                                    return tooltipItem.parsed.y;
                                                  }
                                                }
                                              },

                                              legend: {
                                                position: 'bottom',
                                                labels: {
                                                  usePointStyle: true,
                                                },
                                              }
                                            }
                                          }}
                                          data={{
                                            labels: this.state.daybookStats.graph.map((item) => this.props.getStringDateTs(item.date, 'MMMM')?.translate(this.props.lang)),
                                            datasets: this.state.daybookStats.dataset && this.state.daybookStats.dataset.map((item, idx) => {
                                              let obj = {
                                                label: item.name,
                                                data: item.counts,
                                                backgroundColor: item.color,
                                                borderColor: item.color,
                                                borderWidth: 1,
                                                lineTension: 0.4
                                              }
                                              return obj
                                            })

                                          }}
                                        ></Line>


                                      </div>
                                      :
                                      null
                                    :
                                    this.state.daybookStats && this.state.daybookStats.dataset && this.state.daybookStats.dataset.length ?
                                      <div className="daybook-statistic-graph-wrap">

                                        <Line
                                          ref={(node) => { if (!this.state.graphRef2) { this.setState({ graphRef2: node }) } }}
                                          options={{
                                            animation: {
                                              duration: 0
                                            },
                                            resposive: true,
                                            maintainAspectRatio: false,
                                            plugins: {
                                              tooltip: {
                                                callbacks: {
                                                  label: function (tooltipItem, data) {
                                                    return tooltipItem.parsed.y;
                                                  }
                                                }
                                              },

                                              legend: {
                                                position: 'bottom',
                                                labels: {
                                                  usePointStyle: true,
                                                },
                                              }
                                            }
                                          }}
                                          data={{
                                            labels: this.state.daybookStats.graph.map((item) => this.props.getStringDateTs(item.date, this.props.dateFormat)),
                                            datasets: this.state.daybookStats.dataset && this.state.daybookStats.dataset.map((item, idx) => {
                                              let obj = {
                                                label: item.name,
                                                data: item.counts,
                                                backgroundColor: item.color,
                                                borderColor: item.color,
                                                borderWidth: 1,
                                                lineTension: 0.4
                                              }
                                              return obj
                                            })

                                          }}
                                        ></Line>


                                      </div>
                                      :
                                      null
                                } */}


                                {
                                  this.state.daybookStats && (this.state.daybookStats.dataset?.length || this.state.daybookStats.diaryGraphDataset?.length) ?
                                    <DiaryGraph
                                      getStringDateTs={this.props.getStringDateTs}
                                      lang={this.props.lang}
                                      daybookChartType={this.state.daybookChartType}
                                      data={this.state.daybookStats}
                                      resetGraphImage={this.state.resetGraphImage}
                                      getGraphImage={(img) => {
                                        this.getGraphImage = img;
                                      }}
                                    />
                                    :
                                    null
                                }

                                {/* {
                                  this.state.daybookStats && this.state.daybookStats.dataset?.length && this.state.daybookStats.diaryGraphDataset?.length ?
                                    <div className="daybook-statistic-graph-wrap">
                                      <div style={{ padding: '5px 10px' }} onClick={() => this.setState({ fullScreenGraph: true })}>Full screen</div>
                                      <Bar
                                        options={{
                                          plugins: {

                                          },
                                          responsive: true,
                                          plugins: {


                                            legend: {
                                              position: 'bottom',
                                              labels: {
                                                usePointStyle: true,
                                              },
                                            }
                                          },
                                          scales: {
                                            x: {
                                              stacked: true,

                                            },
                                            y: {
                                              alignToPixels: true
                                            },
                                          },
                                        }}
                                        data={{
                                          labels: this.state.daybookStats.graph.map((item) => this.props.getStringDateTs(item.date, this.props.dateFormat)),
                                          datasets: [
                                            ...this.state.daybookStats?.diaryGraphDataset.map((item, idx) => {
                                              let obj = {
                                                type: 'line',
                                                label: item.name,
                                                data: item.counts,
                                                backgroundColor: this.state.backgroundColors[idx],
                                                borderColor: this.state.backgroundColors[idx],
                                                borderWidth: 2,
                                                lineTension: 0.4
                                              }
                                              return obj
                                            }),
                                            ...this.state.daybookStats?.dataset.map((item, idx) => {
                                              let obj = {
                                                type: 'bar',
                                                label: item.name,
                                                data: item.counts,
                                                backgroundColor: item.color,
                                                borderColor: item.color,
                                                borderWidth: 2,
                                                lineTension: 0.4
                                              }
                                              return obj
                                            })

                                          ],
                                        }}
                                      />
                                    </div>

                                    :
                                    null
                                } */}

                              </Col>
                              {
                                this.state.daybookStats && this.state.daybookStats.items && this.state.daybookStats.items.length ?
                                  <div style={{ position: 'fixed', top: 0, right: -1000, width: 800, height: 'auto' }}>
                                    <div style={{ backgroundColor: '#fff', padding: '50px 100px' }} ref={node => this.statsRef = node}>

                                      <div className="daybook-statistic-body-wrap" style={{ boxShadow: 'none' }}>
                                        <div className='daybook-statistic-body-title-info' style={{ marginBottom: 20 }}>
                                          <h3 style={{ fontSize: 24, fontWeight: 600, margin: 0, marginBottom: 5 }}>
                                            {this.props.uData && this.props.uData.userData && this.props.uData.userData.name}
                                          </h3>
                                          <h3 style={{ margin: 0 }}>
                                            <span style={{ fontSize: 16, fontWeight: 400 }}>{this.state.seeStatistics.dateFrom} - {this.state.seeStatistics.dateTo}</span>
                                          </h3>

                                        </div>
                                        <div style={{ display: 'flex' }}>
                                          <div style={{ width: 400, marginRight: 20 }}>
                                            <h4>{'Period overview'.translate(this.props.lang)}</h4>
                                            <div style={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
                                              <h6>{'Number of days answered'.translate(this.props.lang)}:</h6>
                                              <span>{this.state.daybookStats && this.state.daybookStats.numberOfDays ? this.state.daybookStats.numberOfDays.length : 0}</span>
                                            </div>
                                            <div className='overview-body'>
                                              {
                                                this.state.daybookStats && this.state.daybookStats.items && this.state.daybookStats.items.map((item, idx) => {
                                                  return (
                                                    <div style={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
                                                      <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <div style={{ width: 15, height: 15, marginRight: 5, backgroundColor: item.color }}></div>
                                                        <h6>{item.name.translate(this.props.lang)}</h6>
                                                      </div>
                                                      <span>{item.count ? item.count : 0}</span>
                                                    </div>
                                                  )
                                                })
                                              }

                                            </div>
                                          </div>
                                          <div style={{ width: 380 }}>

                                            <div className='overview-body'>
                                              {
                                                this.state.daybookStats && this.state.daybookStats.itemsScale && this.state.daybookStats.itemsScale.map((item, idx) => {
                                                  return (
                                                    <div className="overview-body-item-wrap-scale" style={{ display: 'block' }}>
                                                      <div className="overview-body-item-scale">
                                                        <h6><strong>{item.name && item.name.translate(this.props.lang)}</strong></h6>
                                                        <br />
                                                      </div>

                                                      <div>{'Average:'.translate(this.props.lang)} <span>{item.counter && item.points ? Math.round(item.points / item.counter * 100) / 100 : 0}</span></div>
                                                    </div>
                                                  )
                                                })
                                              }
                                              {
                                                this.state.daybookStats?.diaryGraphDataset?.filter(item => item?.counts?.filter(el => el).length)?.map((item, idx) => {
                                                  return (
                                                    <div className="overview-body-item-wrap-scale" style={{ display: 'block' }}>
                                                      <div className="overview-body-item-scale">
                                                        <h6><strong>{item.name}</strong></h6>
                                                        <br />
                                                      </div>

                                                      <div>{'Average:'.translate(this.props.lang)} <span>{calculateAverage(item.counts)}</span></div>
                                                    </div>
                                                  )
                                                })
                                              }

                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      {
                                        this.state.daybookStats && this.state.daybookStats.dataset && this.state.daybookStats.dataset.length && this.state.diaryGraphImage ?
                                          <div style={{ boxShadow: 'none', backgroundColor: '#fff', marginTop: 50, width: '100%', minHeight: 350 }}>
                                            <img src={this.state.diaryGraphImage} />



                                          </div>
                                          :
                                          null
                                      }


                                    </div>
                                  </div>

                                  :
                                  null
                              }

                            </Row>

                            :
                            null
                        }

                        {
                          this.state.exportStatsModal ?
                            <InfoModal
                              isOpen={this.state.exportStatsModal}
                              toggle={() => this.setState({ exportStatsModal: null })}
                              header={'Export'.translate(this.props.lang)}
                              info={this.state.exportStatsModal}
                              buttons={[
                                // <Button color='primary' onClick={() => {
                                //     this.setState({
                                //         exportStatsModal: null,
                                //     })

                                // }}>{'PDF'.translate(this.props.lang)}</Button>,



                                <Button color='primary'
                                  disabled={this.state.daybookStats && this.state.daybookStats.items && this.state.daybookStats.items.length ? false : true}
                                  onClick={() => {
                                    this.exportStats()

                                  }}>{'Excel'.translate(this.props.lang)}</Button>,
                                <PdfSaveV2 disabled={this.statsRef ? false : true}
                                  buttonText={'PDF'.translate(this.props.lang)}
                                  onClick={() => {
                                    this.setState({
                                      exportStatsModal: null,
                                    })

                                  }}
                                  lang={this.props.lang} element={this.statsRef} pdfName={`${this.props.lang == 'en' ? 'diary' : 'dagbok'}_stats${this.props.patient && this.props.patient.name ? ('_' + generateAlias(this.props.patient.name)) : ''}`} />,
                                <Button color='primary'
                                  onClick={() => {
                                    this.setState({
                                      exportStatsModal: null,
                                    })

                                  }}>{'Close'.translate(this.props.lang)}</Button>,


                              ]}
                            />
                            :
                            null

                        }
                      </div>
                      :
                      null
                  }
                  {
                    this.state.activeTab == 3 && (this.props.uData.userLevel == 1 || this.props.uData.userLevel == 2 || (typeof window != 'undefined' && localStorage.getItem('profile') == 'patient')) ?
                      <Col lg='12'>
                        <TestResultsPatient
                          {...this.props}
                          items={this.state.items}
                        />
                      </Col>
                      :
                      null
                  }
                  {
                    this.state.activeTab == 4 && (this.props.uData.userLevel == 1 || this.props.uData.userLevel == 2 || (typeof window != 'undefined' && localStorage.getItem('profile') == 'patient')) ?
                      <Col lg='12'>
                        <ListOfMedicinesPatient
                          {...this.props}
                          children={this.state.items}
                        />
                      </Col>
                      :
                      null
                  }

                  {
                    this.state.activeTab == 6 && (this.props.uData.userLevel == 1 || this.props.uData.userLevel == 2 || (typeof window != 'undefined' && localStorage.getItem('profile') == 'patient')) ? (
                      <Col>
                        <PatientDocuments
                          {...this.props}
                          documents={undefined}
                        />
                      </Col>
                    ) : (null)
                  }


                  {this.state.addNewChildModal ?
                    <Modal isOpen={this.state.addNewChildModal} centered>
                      <ModalHeader toggle={() => this.setState({ addNewChildModal: !this.state.addNewChildModal })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ addNewChildModal: !this.state.addNewChildModal })}><Isvg src={xIcon} /></button>}>
                        <div className='new-child'>
                          <div className='new-child-tooltip'>
                            <Isvg src={infoIcon} /> <div className="chat-icons-tooltip">{"You may only apply for care for children 0-17 years for whom you are the guardian.".translate(this.props.lang)}</div>
                          </div>
                          <h4 >{'Patient profile'.translate(this.props.lang)}</h4>
                        </div>

                      </ModalHeader>
                      <ModalBody>
                        <FormGroup>
                          <Label>{'First and last name'.translate(this.props.lang)}</Label>
                          <Input type='text'
                            value={this.state.addNewChildModal && this.state.addNewChildModal.name ? this.state.addNewChildModal.name : ''}
                            onChange={(e) => {
                              let addNewChildModal = this.state.addNewChildModal;
                              addNewChildModal.name = e.target.value;
                              this.setState({
                                addNewChildModal
                              }, () => {
                                this.registerRefresh()
                              })
                            }}
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label>{this.props.country === 'uk' ? 'Date of birth'.translate(this.props.lang) : 'Social security number'.translate(this.props.lang)}</Label>
                          <SocialSecurityNumber
                            hideFindChildren={true}
                            selectedGroup={this.props.selectedGroup}
                            selectedClinic={this.props.selectedClinic}
                            registerRefresh={(registerRefresh) => this.registerRefresh = registerRefresh}
                            searchByName={this.state.addNewChildModal && this.state.addNewChildModal.name ? this.state.addNewChildModal.name : '$$_'}
                            lang={this.props.lang} country={this.props.country} placeholder={this.props.country === 'denmark' ? 'DDMMYYNNNNN' : this.props.lang === 'en' ? 'YYYYMMDDNNNN' : 'ÅÅÅÅMMDDNNNN'} haveValidation={true} value={this.state.addNewChildModal && this.state.addNewChildModal.socialSecurityNumber ? this.state.addNewChildModal.socialSecurityNumber : ''} type="text" onChange={(e) => {
                              let addNewChildModal = this.state.addNewChildModal;
                              addNewChildModal.socialSecurityNumber = e;
                              this.setState({
                                addNewChildModal
                              })
                            }} />
                        </FormGroup>
                        <FormGroup>
                          <div className='gender-new'>
                            <div className='gender-new-tooltip'>
                              <Isvg src={infoIcon} /> <div className="chat-icons-tooltip">{"Biological gender is needed for proper treatment.".translate(this.props.lang)}</div>
                            </div>
                            <Label>{'Gender'.translate(this.props.lang)}</Label>
                          </div>
                          <Input type='select' onChange={(e) => {
                            let addNewChildModal = this.state.addNewChildModal;
                            addNewChildModal.sex = e.target.value;
                            this.setState({
                              addNewChildModal
                            })
                          }} >
                            <option value={-1}>{'Select gender'.translate(this.props.lang)}</option>
                            <option value={'Boy'}>{'Boy'.translate(this.props.lang)}</option>
                            <option value={'Girl'}>{'Girl'.translate(this.props.lang)}</option>
                          </Input>
                        </FormGroup>
                        <FormGroup>
                          <Checkbox checked={this.state.check} onChange={() => {
                            this.setState({
                              check: !this.state.check
                            })
                          }} label={"Confirm that you are the child's guardian and have the right to seek care for the child.".translate(this.props.lang)}></Checkbox>
                        </FormGroup>



                      </ModalBody>
                      <ModalFooter className='buttons-right-blue'>
                        <Button color="primary" disabled={this.state.check && this.state.addNewChildModal.name && this.state.addNewChildModal.socialSecurityNumber ? false : true} onClick={() => this.addNewChild(this.state.addNewChildModal)}>{'Add'.translate(this.props.lang)}</Button>

                      </ModalFooter>
                    </Modal>

                    : null}
                  {
                    this.state.editOtherEmailsModal ?
                      <Modal isOpen={this.state.editOtherEmailsModal} centered size='md'>
                        <ModalHeader style={{ margin: 'auto' }}>
                          {'My mail addresses'.translate(this.props.lang)}
                        </ModalHeader>
                        <ModalBody className='edit-other-emails-modal-wrap'>
                          {
                            this.state.editOtherEmailsModal && Object.keys(this.state.editOtherEmailsModal).map((key) => {
                              let item = this.state.editOtherEmailsModal[key];
                              if (this.props.uData && this.props.uData.groupPermissions && this.props.uData.groupPermissions[key])
                                return (
                                  <div className="edit-other-email-wrap">
                                    <span>{item.clinicGroupName}</span>
                                    <FormGroup>
                                      <Input type='email' value={item.email} onChange={(e) => {
                                        let editOtherEmailsModal = this.state.editOtherEmailsModal;
                                        editOtherEmailsModal[key].email = e.target.value;
                                        this.setState({ editOtherEmailsModal })
                                      }} />
                                      {item.error ? <FormFeedback>{item.error}</FormFeedback> : null}
                                    </FormGroup>
                                  </div>
                                )
                            })
                          }
                        </ModalBody>
                        <ModalFooter className="buttons-right-blue">
                          <Button color='primary' onClick={() => this.updateOtherEmails(this.state.editOtherEmailsModal)}>{'Save'.translate(this.props.lang)}</Button>
                          <Button color='primary' onClick={() => this.setState({ editOtherEmailsModal: null })}>{'Close'.translate(this.props.lang)}</Button>
                        </ModalFooter>
                      </Modal>
                      :
                      null
                  }
                  {
                    this.state.faceIdModal ?
                      <Modal isOpen={this.state.faceIdModal} centered>
                        <ModalHeader style={{ margin: 'auto' }}>
                          {'Face ID configuration'.translate(this.props.lang)}
                        </ModalHeader>
                        <ModalBody className="face-id-modal-body-wrap">
                          <FaceApiComponent
                            {...this.props}
                            takePhoto={this.state.takePhoto}
                            getNumberOfPhotos={(number) => this.setState({ numberOfScanedPhotos: number })}
                            scanedTypes={this.state.scanedTypes}
                            getScanedTypes={(type) => {
                              let scanedTypes = this.state.scanedTypes;
                              if (!scanedTypes) {
                                scanedTypes = []
                              };
                              type++;
                              scanedTypes.push(type)
                              scanedTypes.sort(function (a, b) {
                                return a - b;
                              });
                              let next = 1;
                              for (let i = 1; i <= 5; i++) {
                                if (scanedTypes.filter(el => el == i).length == 0) {
                                  next = i;
                                  break;
                                }
                              }

                              let guideMessage = '';
                              if (next == 1) {
                                guideMessage = 'Turn your head straight towards the camera';
                              } else if (next == 2) {
                                guideMessage = 'Turn your head to the left';
                              } else if (next == 3) {
                                guideMessage = 'Turn your head to the right';
                              } else if (next == 4) {
                                guideMessage = 'Turn your head up';
                              } else if (next == 5) {
                                guideMessage = 'Turn your head down';
                              }


                              this.setState({ scanedTypes, guideMessage })




                            }}
                            savePhoto={(photo) => {
                              let photosFaceId = this.state.photosFaceId;
                              if (!photosFaceId) {
                                photosFaceId = []
                              };
                              photosFaceId.push(photo)

                              setTimeout(() => {
                                this.setState({ photosFaceId: photosFaceId, takePhoto: null, scanedTypes: [] }, () => this.updateFaceIdPhotos())
                              }, 3000);

                            }} />
                          {
                            this.state.takePhoto ?
                              <div className="scanning-wrap">
                                {
                                  this.state.guideMessage && this.state.numberOfScanedPhotos != 5 && (!this.state.scanedTypes || (this.state.scanedTypes && this.state.scanedTypes.length < 5)) ?
                                    <div className="scan-guide">
                                      <h6>{this.state.guideMessage.translate(this.props.lang)}</h6>
                                    </div>
                                    :
                                    null
                                }

                                <div class="ratio" style={{ '--ratio': this.state.numberOfScanedPhotos ? (this.state.numberOfScanedPhotos * 0.2) : 0 }}>
                                  {
                                    this.state.numberOfScanedPhotos == 5 ?
                                      <h6>{'Scanning completed successfully'.translate(this.props.lang)}</h6>
                                      :
                                      <h6 style={{ fontSize: 24 }}>{this.state.numberOfScanedPhotos * 20}%</h6>
                                  }
                                </div>


                              </div>
                              :
                              null
                          }

                          {
                            (this.state.haveScannededFaceId || (this.state.photosFaceId && this.state.photosFaceId.length)) && !this.state.takePhoto ?
                              <div className="face-id-saved-wrap">
                                <Isvg src={savedIcon} />
                                <p>{'Face ID scanned'.translate(this.props.lang)}</p>
                              </div>

                              // <div className="face-id-photos-wrap">
                              //   <div className="face-id-photos">
                              //     {
                              //       this.state.photosFaceId && this.state.photosFaceId.map((item, idx) => {
                              //         return (
                              //           <div className="photo-face-id">
                              //             <img src={item} />
                              //             <div className="delete-face-id-photo"
                              //               onClick={() => {
                              //                 let photosFaceId = this.state.photosFaceId;
                              //                 photosFaceId.splice(idx, 1)
                              //                 this.setState({ photosFaceId })
                              //               }}
                              //             ><Isvg src={garbageIcon} /></div>
                              //           </div>
                              //         )
                              //       })
                              //     }
                              //   </div>
                              // </div>
                              :
                              null
                          }

                        </ModalBody>
                        <ModalFooter className="buttons-right-blue">
                          {
                            !this.state.takePhoto ?
                              <Button color='primary'
                                disabled={this.state.takePhoto}
                                onClick={() => {
                                  this.setState({ takePhoto: Math.floor(new Date().getTime() / 1000), photosFaceId: [], numberOfScanedPhotos: null, guideMessage: 'Turn your head straight towards the camera' })

                                }}>{'Scan face'.translate(this.props.lang)}</Button>
                              :
                              <Button className="danger-button"
                                onClick={() => {
                                  this.setState({ takePhoto: null, photosFaceId: [], numberOfScanedPhotos: null, scanedTypes: [] })

                                }}>{'Stop scanning'.translate(this.props.lang)}</Button>
                          }
                          {/* {
                            !this.state.takePhoto ?
                              <Button color='primary'
                                disabled={JSON.stringify(this.state.photosFaceId) != JSON.stringify(this.props.uData.faceIdPhotos) ? false : true}
                                onClick={() => {
                                  this.updateFaceIdPhotos()
                                }}>{'Save'.translate(this.props.lang)}</Button>
                              :
                              null
                          } */}
                          {
                            !this.state.takePhoto ?
                              <Button color='danger'
                                // disabled={!this.state.photosFaceId || (this.state.photosFaceId && this.state.photosFaceId.length == 0) ? true : false}
                                disabled={(this.state.haveScannededFaceId || (this.state.photosFaceId && this.state.photosFaceId.length)) ? false : true}
                                onClick={() => {
                                  this.setState({ photosFaceId: [] }, () => this.updateFaceIdPhotos())

                                }}>{'Remove configuration'.translate(this.props.lang)}</Button>
                              :
                              null
                          }
                          {
                            !this.state.takePhoto ?
                              <Button color='primary' onClick={() => this.setState({ faceIdModal: null })}>{'Close'.translate(this.props.lang)}</Button>
                              :
                              null
                          }



                        </ModalFooter>
                      </Modal>
                      :
                      null
                  }
                  {
                    this.state.disconnectModal ?
                      <DeleteModal
                        lang={this.props.lang}
                        isOpen={this.state.disconnectModal}
                        toggle={() => this.setState({ disconnectModal: null })}
                        handler={() => { this.disconnect(this.state.disconnectModal); this.setState({ disconnectModal: null }) }}
                      >
                        {
                          this.state.disconnectModal == 'bankId' ?
                            <>
                              {'Are you sure you want to disconnect from BankID'.translate(this.props.lang)}?
                            </>
                            :
                            <>
                              {'Are you sure you want to disconnect from Net ID (SITHS)'.translate(this.props.lang)}?
                            </>

                        }
                      </DeleteModal>
                      :
                      null
                  }
                  {
                    this.state.deleteLink ?
                      <DeleteModal
                        lang={this.props.lang}
                        isOpen={this.state.deleteLink}
                        toggle={() => this.setState({ deleteLink: null })}
                        handler={() => { this.deleteLinkUser(this.state.deleteLink); this.setState({ deleteLink: null }) }}
                      >
                        {'Delete link to user'.translate(this.props.lang)} <strong>{this.state.deleteLink && this.state.deleteLink.name}</strong>?
                      </DeleteModal>
                      :
                      null
                  }
                  {
                    this.state.loadLang ?
                      <div className="loader-wrap loader-wrap-account" >
                        <Player
                          autoplay={true}
                          loop={true}
                          src="/lf30_editor_l5cxzdyf.json"
                          style={{ height: typeof window != 'undefined' && window.innerWidth < 768 ? "128px" : "256px", width: typeof window != 'undefined' && window.innerWidth < 768 ? "128px" : "256px", position: 'absolute', top: "50%", left: '50%', transform: 'translate(-50%, -50%)' }}
                        ></Player>
                      </div>
                      :
                      null
                  }

                  {this.state.scheduleModal ?
                    <Modal isOpen={this.state.scheduleModal} centered size='lg'>
                      <ModalHeader toggle={() => this.setState({ scheduleModal: !this.state.scheduleModal })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }}
                        onClick={() => this.setState({
                          scheduleModal: !this.state.scheduleModal,
                          // eventID: null,
                          selectedEventAnswers: null
                        }, () => this.getEvents())}>&times;</button>}>{'Forms to answer'.translate(this.props.lang)}</ModalHeader>

                      <ModalBody>
                        <FormGroup>
                          <Input className="schedule-input-custom-width" type='text' disabled value={this.state.scheduleModal.date} />

                        </FormGroup>
                        <FormGroup>
                          {this.state.formItems && this.state.formItems.length ?
                            <ListBuilder
                              lang={this.props.lang}
                              loading={this.state.loading}
                              total={this.state.total}
                              showNumeration={false}
                              hideFilters={true}
                              hidePagination
                              hideEntries
                              actionLabel={'Action'.translate(this.props.lang)}
                              fields={
                                [
                                  { type: 'text', name: 'name', label: 'Name'.translate(this.props.lang), allowSort: false },


                                ]
                              }
                              rawItems={this.state.formItems}
                              items={this.state.formItems.map((item, idx) => {
                                return {
                                  ...item,
                                }
                              })}

                              actions={
                                [

                                  {
                                    component: <Button color="primary" className="tabel-action-tooltip"><div style={{ width: 180 }} className="chat-icons-tooltip">{'Answer the form'.translate(this.props.lang)}</div>{'Answer the form'.translate(this.props.lang)}</Button>,
                                    onClick: (item) => {
                                      this.setState({
                                        answerDaybookOpenModal: item
                                      })
                                    },
                                    condition: (form) => {
                                      let date = new Date(this.state.scheduleModal.date + ' 23:59:59')
                                      // console.log(date)
                                      date.setDate(date.getDate() + this.state.days)

                                      if (this.state.selectedEventAnswers && this.state.selectedEventAnswers.length) {
                                        let arrFormIdx = this.state.selectedEventAnswers.findIndex(item => item.formID == form._id)
                                        if (this.state.selectedEventAnswers[arrFormIdx]) {
                                          return false
                                        } else if ((this.state.dayStatus && this.state.dayStatus !== 'active') || (!this.state.dayStatus && (Math.floor(new Date(date).getTime() / 1000) < Math.floor(new Date().getTime() / 1000) || Math.floor(new Date(this.state.scheduleModal.date).getTime() / 1000) > Math.floor(new Date().getTime() / 1000)))) {
                                          return false
                                        } else {
                                          return true
                                        }
                                      } else if ((this.state.dayStatus && this.state.dayStatus !== 'active') || (!this.state.dayStatus && (Math.floor(new Date(date).getTime() / 1000) < Math.floor(new Date().getTime() / 1000) || Math.floor(new Date(this.state.scheduleModal.date).getTime() / 1000) > Math.floor(new Date().getTime() / 1000)))) {
                                        return false
                                      } else {
                                        return true
                                      }


                                    }
                                  },
                                  {
                                    component: <div className="expired-button">{'EXPIRED'.translate(this.props.lang)}</div>,
                                    onClick: (item) => { },
                                    condition: (form) => {
                                      let date = new Date(this.state.scheduleModal.date + ' 23:59:59')
                                      date.setDate(date.getDate() + this.state.days)

                                      if ((this.state.dayStatus && this.state.dayStatus === 'expired') || (!this.state.dayStatus && Math.floor(new Date(date).getTime() / 1000) < Math.floor(new Date().getTime() / 1000))) {
                                        if (this.state.selectedEventAnswers && this.state.selectedEventAnswers.length) {
                                          let arrFormIdx = this.state.selectedEventAnswers.findIndex(item => item.formID == form._id)
                                          if (!this.state.selectedEventAnswers[arrFormIdx]) {
                                            return true
                                          } else {
                                            return false
                                          }
                                        } else {
                                          return true
                                        }

                                      } else {
                                        return false
                                      }

                                    }
                                  },
                                  {
                                    component: <Button style={{ backgroundColor: 'green' }} className="tabel-action-tooltip"><div style={{ width: 180 }} className="chat-icons-tooltip">{"See my answers".translate(this.props.lang)}</div>{"See my answers".translate(this.props.lang)}</Button>,
                                    onClick: (item) => {
                                      this.setState({
                                        answerDaybookOpenModal: item
                                      }, () => {
                                        if (this.state.selectedEventAnswers && this.state.selectedEventAnswers.length) {
                                          let arrFormIdx = this.state.selectedEventAnswers.findIndex(item => item.formID == this.state.answerDaybookOpenModal._id)
                                          if (this.state.selectedEventAnswers[arrFormIdx]) {
                                            this.setState({
                                              questionaryAnswers: this.state.selectedEventAnswers[arrFormIdx] && this.state.selectedEventAnswers[arrFormIdx].answers ? { ...this.state.selectedEventAnswers[arrFormIdx].answers } : {}
                                            })
                                          }
                                        }
                                      })
                                    },
                                    condition: (form) => {
                                      if (this.state.selectedEventAnswers && this.state.selectedEventAnswers.length) {
                                        let arrFormIdx = this.state.selectedEventAnswers.findIndex(item => item.formID == form._id)
                                        if (this.state.selectedEventAnswers[arrFormIdx]) {
                                          return true
                                        } else {
                                          return false
                                        }
                                      } else {
                                        return false
                                      }
                                    }
                                  },
                                ]
                              }
                              params={params}
                              sortField={params.sortField}
                              sortType={params.sortType}
                              updateSort={this.updateSort}
                              updateParams={this.updateParams}
                              updateMultipleParams={this.updateMultipleParams}
                            ></ListBuilder>
                            :
                            <strong>{"No forms to answer on selected day".translate(this.props.lang)}</strong>
                          }
                        </FormGroup>
                        {
                          this.state.formItems && this.state.formItems.length && this.state.showInfoText ?
                            <div className="text-info-expired-forms-wrap">
                              <Isvg src={infoIcon} />
                              <p>{'One or more of your forms has expired. Forms must be completed no later than 7 days after the scheduled date.'.translate(this.props.lang)}</p>
                            </div>
                            :
                            null
                        }

                      </ModalBody>
                      <ModalFooter>
                        <Button color='primary' onClick={() => this.setState({
                          scheduleModal: !this.state.scheduleModal,
                          // eventID: null,
                          selectedEventAnswers: null
                        }, () => this.getEvents())}>{'Close'.translate(this.props.lang)}</Button>
                      </ModalFooter>
                    </Modal>
                    : null}

                  {
                    this.state.answerDaybookOpenModal ?
                      <Modal isOpen={this.state.answerDaybookOpenModal} centered size={'lg'} zIndex={999999999}>
                        <ModalHeader close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }}
                          onClick={() => {
                            if (this.state.selectedEventAnswers && this.state.selectedEventAnswers.length) {
                              let arrFormIdx = this.state.selectedEventAnswers.findIndex(item => item.formID == this.state.answerDaybookOpenModal._id)
                              if (!this.state.selectedEventAnswers[arrFormIdx]) {
                                this.setState({
                                  errorFormModal: 'Your data will not be saved.'
                                })
                              } else {
                                this.setState({
                                  questionaryAnswers: {},
                                  answerDaybookOpenModal: null,
                                  // eventID: null,
                                  // scheduleModal: null,
                                  // selectedEventAnswers: null,

                                }, () => {
                                  this.getEvents(true)
                                })
                              }
                            } else {
                              this.setState({
                                errorFormModal: 'Your data will not be saved.'
                              })
                            }

                          }}><Isvg src={xIcon} /></button>}>
                          <div>
                            <p>{this.state.answerDaybookOpenModal.name}</p>
                            {/* <p style={{ fontSize: 16, fontWeight: 500 }}>{this.state.answerDaybookOpenModal.description}  </p> */}
                          </div>

                        </ModalHeader>

                        <ModalBody className="run-modal">
                          <AnswerDaybookModal
                            {...this.props}
                            sections={this.generateSections(this.state.answerDaybookOpenModal.items)}
                            answer={(item, callback) => {
                              this.setState({ questionaryAnswers: item }, () => {
                                if (callback) {
                                  callback()
                                }
                              })
                            }}
                            alias={typeof window != 'undefined' && localStorage.groupAlias ? localStorage.groupAlias : null}
                            date={this.state.scheduleModal.date}
                            selectedEventAnswers={this.state.selectedEventAnswers}
                            answerDaybookOpenModal={this.state.answerDaybookOpenModal}
                            answers={this.state.questionaryAnswers}
                            days={this.state.days}
                            saveAnswers={(html, totals, scale, callback) => {

                              let items = this.state.eventItems.filter(item => this.state.scheduleModal && new Date(item.data && item.data.startTime * 1000).getDate() == new Date(this.state.scheduleModal.date.replace(/-/g, "/")).getDate() && new Date(item.data && item.data.startTime * 1000).getMonth() == new Date(this.state.scheduleModal.date.replace(/-/g, "/")).getMonth())

                              let eventID
                              for (let i = 0; i < items.length; i++) {
                                if (items[i] && items[i].formularID.indexOf(this.state.answerDaybookOpenModal._id) != -1) {
                                  eventID = items[i]._id
                                  break;
                                }

                              }
                              let questionaryAnswers = JSON.parse(JSON.stringify(this.state.questionaryAnswers));
                              let questionaryItems = JSON.parse(JSON.stringify(this.state.answerDaybookOpenModal.items));
                              if (questionaryAnswers && questionaryItems && questionaryItems.length) {
                                for (var key in questionaryAnswers) {
                                  if (questionaryItems.filter(el => el.id == key && el.type == 9).length && questionaryAnswers[key] && questionaryAnswers[key].length) {
                                    for (let i = 0; i < questionaryAnswers[key].length; i++) {
                                      if (questionaryAnswers[key][i].preview) {
                                        delete questionaryAnswers[key][i].preview;
                                      }
                                    }
                                  }
                                }
                              }
                              // console.log(questionaryItems, questionaryAnswers)

                              fetch(API_ENDPOINT + '/users/daybook-event/form-answer/' + eventID, {
                                method: 'POST',
                                headers: {
                                  'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                                  'content-type': 'application/json'
                                },
                                body: JSON.stringify({ formID: this.state.answerDaybookOpenModal._id, html: html, totals: totals, scale: scale, alias: localStorage.getItem('groupAlias'), answers: questionaryAnswers })
                              }).then(parseJSON).then(({ result, status }) => {
                                if (status >= 200 && status < 300) {
                                  if (callback) {
                                    callback()
                                  }
                                  this.setState({
                                    questionaryAnswers: {},
                                    answerDaybookOpenModal: null,
                                    // eventID: null,
                                    scheduleModal: null,
                                    selectedEventAnswers: null
                                  }, () => this.getEvents())
                                }

                              })
                            }}
                          />
                        </ModalBody>
                      </Modal>
                      :
                      null
                  }
                </div>

                {this.state.eConnectModal ? <InfoModal
                  isOpen={this.state.eConnectModal}
                  onClose={() => {
                    this.setState({
                      eConnectModal: null
                    })
                  }}
                  info={this.state.eConnectModal === 'enable' ? 'Enable eConnect again? Your clinic admin will be able to see you in the list of caregivers when assigning new eConnect cases, if eConnect is enabled for your clinic.'.translate(this.props.lang) : 'Temporarily disable eConnect? Your clinic admin will NOT be able to see you in the list of caregivers when assigning new eConnect cases, if eConnect is enabled for your clinic.'.translate(this.props.lang)}
                  buttons={[
                    <button style={{ visibility: 'hidden', display: 'none' }} aria-hidden='true'></button>,
                    <Button
                      color={this.state.eConnectModal === 'enable' ? 'info' : 'danger'}
                      style={{ margin: 10, minWidth: 100 }}
                      onClick={this.changeEConnectStatus}
                      className="e-connect-profile-button"
                    >
                      {this.state.eConnectModal === 'enable' ? 'Enable'.translate(this.props.lang) : 'Disable'.translate(this.props.lang)}
                    </Button>,
                    <Button
                      color='primary'
                      style={{ margin: 10, minWidth: 100 }}
                      onClick={() => {
                        this.setState({
                          eConnectModal: null
                        });
                      }}
                    >
                      {'Cancel'.translate(this.props.lang)}
                    </Button>,
                    <button style={{ visibility: 'hidden', display: 'none' }} aria-hidden='true'></button>
                  ]}
                /> : null
                }

                {
                  this.state.errorFormModal ?
                    <Modal isOpen={this.state.errorFormModal} centered zIndex={9999999999999}>
                      {/* <ModalHeader style={{ margin: 'auto' }}>{'Error'.translate(this.props.lang)}</ModalHeader> */}
                      <ModalBody className="delete-modal">


                        {/* <img src={warrning} /> */}
                        <div className="message">
                          {
                            this.state.errorFormModal.translate(this.props.lang)
                          }
                        </div>
                      </ModalBody>
                      <ModalFooter style={{ display: 'flex', justifyContent: 'center' }}>

                        <Button style={{ minWidth: 100 }} color="primary" onClick={() => this.setState({
                          questionaryAnswers: {},
                          answerDaybookOpenModal: null,
                          errorFormModal: null,
                          // selectedEventAnswers: null,
                        })} >{this.props.closeButtonText ? this.props.closeButtonText : 'Cancel'.translate(this.props.lang)}</Button>

                        <Button style={{ marginRight: 5, minWidth: 100 }} color="primary" onClick={() => this.setState({
                          errorFormModal: null,
                        })} >{this.props.closeButtonText ? this.props.closeButtonText : 'Continue answering'.translate(this.props.lang)}</Button>
                      </ModalFooter>
                    </Modal>
                    :
                    null
                }
                {
                  this.state.deleteMyAccountInfoModal ?
                    <InfoModal
                      isOpen={this.state.deleteMyAccountInfoModal}
                      toggle={() => this.setState({ deleteMyAccountInfoModal: null })}
                      header={'Delete my account'.translate(this.props.lang)}
                      info={'Are you sure you want to delete your account? If you confirm, your account will be deleted within 7 days.'.translate(this.props.lang)}
                      buttons={[
                        <Button color='primary'
                          onClick={() => {
                            let clinicGroups = [];
                            if (patientGroups && patientGroups.length > 1) {
                              if (typeof window != 'undefined' && localStorage.groupAlias) {
                                if (patientGroups.filter(el => el.alias && el.alias.toLowerCase() == localStorage.groupAlias.toLowerCase()).length) {
                                  clinicGroups.push(patientGroups.filter(el => el.alias && el.alias.toLowerCase() == localStorage.groupAlias.toLowerCase())[0]._id)
                                }
                              }
                            } else if (patientGroups && patientGroups.length == 1) {
                              clinicGroups.push(patientGroups[0]._id)
                            }

                            this.setState({
                              deleteMyAccountInfoModal: null,
                              deleteMyAccountModal: {
                                clinicGroups: clinicGroups,
                                reason: ''
                              }
                            })

                          }}>{'Confirm'.translate(this.props.lang)}</Button>,
                        <Button color='primary'
                          onClick={() => {
                            this.setState({ deleteMyAccountInfoModal: null })

                          }}>{'Cancel'.translate(this.props.lang)}</Button>,



                      ]}
                    />
                    :
                    null

                }
                {
                  this.state.deleteMyAccountModal ?
                    <Modal isOpen={this.state.deleteMyAccountModal} centered>
                      <ModalHeader >{'Delete my account'.translate(this.props.lang)}</ModalHeader>
                      <ModalBody>
                        {
                          patientGroups && patientGroups.length > 1 ?
                            <ListCheckBox
                              label={'Delete my account from'.translate(this.props.lang)}
                              value={this.state.deleteMyAccountModal?.clinicGroups ? this.state.deleteMyAccountModal.clinicGroups : []}
                              values={patientGroups.map(item => {
                                return ({
                                  name: item.name,
                                  value: item._id
                                })
                              })}
                              onChange={(item) => {
                                let deleteMyAccountModal = this.state.deleteMyAccountModal;
                                deleteMyAccountModal.clinicGroups = item
                                this.setState({
                                  deleteMyAccountModal
                                })
                              }}
                            />
                            :
                            null
                        }

                        <FormGroup>
                          <Label>{'Reason for account deletion'.translate(this.props.lang)}*</Label>
                          <Input type="textarea" style={{ height: 100 }} value={this.state.deleteMyAccountModal.reason ? this.state.deleteMyAccountModal.reason : ''} onChange={(e) => {
                            let deleteMyAccountModal = this.state.deleteMyAccountModal;
                            deleteMyAccountModal.reason = e.target.value
                            this.setState({
                              deleteMyAccountModal
                            })
                          }} />
                        </FormGroup>
                      </ModalBody>
                      <ModalFooter>
                        <Button
                          disabled={this.state.deleteMyAccountModal && this.state.deleteMyAccountModal.clinicGroups && this.state.deleteMyAccountModal.clinicGroups.length && this.state.deleteMyAccountModal.reason ? false : true}
                          onClick={() => {
                            this.deleteMyAccount()
                          }}
                        >{'Request account deletion'.translate(this.props.lang)}</Button>
                        <Button onClick={() => {
                          this.setState({
                            deleteMyAccountModal: null
                          })
                        }}>{'Cancel'.translate(this.props.lang)}</Button>
                      </ModalFooter>
                    </Modal>
                    :
                    null
                }
                {
                  this.state.deleteMyAccountInfoModal2 ?
                    <InfoModal
                      isOpen={this.state.deleteMyAccountInfoModal2}
                      toggle={() => this.setState({ deleteMyAccountInfoModal2: null })}
                      header={'Delete my account'.translate(this.props.lang)}
                      info={this.state.deleteMyAccountInfoModal2}
                      buttons={[
                        <Button color='primary'
                          onClick={() => {
                            this.setState({ deleteMyAccountInfoModal2: null })

                          }}>{'Ok'.translate(this.props.lang)}</Button>,



                      ]}
                    />
                    :
                    null

                }
                {this.state.groupError ? (
                  <Modal isOpen={this.state.groupError} centered>
                    {/* <ModalHeader style={{ margin: 'auto' }}>{'Error'.translate(this.props.lang)}</ModalHeader> */}
                    <ModalBody className="delete-modal">
                      {/* <img src={warrning} /> */}
                      <p className="message">{'You have to select the group!'.translate(this.props.lang)}</p>
                    </ModalBody>

                    <ModalFooter className='buttons-right-blue'>
                      <Button style={{ minWidth: 100 }} onClick={() => {
                        this.props[0].history.push(`/home-patient-level`);
                      }}>{'Close'.translate(this.props.lang)}</Button>
                    </ModalFooter>
                  </Modal>
                ) : null}
              </Col>
            </Row>


          </Container>



        </div>
      </div >

    );
  }
}

const selector = formValueSelector("modalForm");
const selector2 = formValueSelector("form");


export default connect(
  (state) => {
    return {
      selectedWaitingTimeType: selector(state, "selectedWaitingTimeType"),
      sex: selector2(state, 'userData.sex')
    };
  },
  {
    changeMinValue: (value) => change("modalForm", "waitingMin", value),
    changeSex: (value) => change("form", "userData.sex", value),
    changeMaxValue: (value) => change("modalForm", "waitingMax", value),
    changeSelectedWaitingTimeType: (value) =>
      change("modalForm", "selectedWaitingTimeType", value),
  }
)(Page(Account));
