import React, { Component } from 'react';
import Isvg from 'react-inlinesvg';
import Page from '../../containers/page';

import {
    Container,
    Row,
    Col,
    Button,
    FormGroup,
    Label,
    Input,
    UncontrolledAlert,
    UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Table, Form, Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';

import HTML from '../../components/forms/fields/html';

import FormBuilder from '../../components/forms/modalFormBuilder';
import { required } from '../../components/forms/validation';

import ErrorModal from '../../components/errorModal';
import DeleteModal from '../../components/deleteModal';
import InfoModal from '../../components/infoModal';

import { getSearchParams, generateSearchLink, generateSearchLinkMultiple } from '../../helpers/linkHelper';
import { API_ENDPOINT } from '../../constants';
import { formValueSelector, change } from 'redux-form';  // ES6
import { connect } from 'react-redux';

import { FlowChartWithState, IPortDefaultProps, LinkDefault, PortDefault } from '@mrblenny/react-flow-chart'
import { Player, Controls } from "@lottiefiles/react-lottie-player";

import { SortableContainer, SortableElement, sortableHandle, } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import { v4 as uuidv4 } from 'uuid';

import Search from '../../components/search';
import Notification from '../../assets/svg/notification.svg'
import profileUser from '../../assets/images/user.png'
import moreIcon from '../../assets/svg/more.svg'
import Print from '../../assets/svg/print.svg'
import Link from '../../assets/svg/link.svg'
import EasySelect from '../../components/forms/fields/easySelect';
import CreateReferralModal from '../../components/createReferralModal'
import Arrows from '../../assets/svg/arrows.svg'
import garabage from '../../assets/svg/garbage.svg'
import import2 from '../../assets/svg/import2.svg'
import Checkbox from '../../components/forms/fields/checkbox';
import addIcon from '../../assets/svg/add-icon.svg';

import viewIcon from '../../assets/svg/eye.svg';
import hideIcon from '../../assets/svg/hide.svg';

function getRandomColor() {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
}


const colors = [
    "aqua",
    "black",
    "lime",
    "blue",
    "fuchsia",
    "gray",
    "green",
    "navy",
    "olive",
    "purple",
    "red",
    "silver",
    "teal",
    "yellow",
    "maroon",
];


function parseJSON(response) {
    return response.json().then(json => {
        return {
            result: json,
            status: response.status
        }
    })
}


const SortableItem = SortableElement(({ value, update }) => <li className={value.className}>{value.component}</li>);

const SortableList = SortableContainer(({ items, updateSubMenu }) => {
    return (
        <ul className="prepare-questionary-sort-list">
            {items.map((value, index) => (
                <SortableItem key={`item-${index}`} index={index} value={value} />
            ))}
        </ul>
    );
});

const SortableAnswer = SortableElement(({ value }) => <div className={value.className}>{value.component}</div>);

const SortableAnswers = SortableContainer(({ items }) => {
    return (
        <div className="sortable-answers">
            {items.map((value, index) => (
                <SortableAnswer key={`item-${index}`} index={index} value={value} />
            ))}
        </div>
    );
});




const DragHandle = sortableHandle(({ children }) => children);


/**
* Prepare questionary page
* @author   Milan Stanojevic
*/
class PrepareQuestionary extends Component {
    constructor(props) {
        super(props);
        this.player = React.createRef();
        this.player2 = React.createRef();
        this.state = {
            ...props.initialData,
            showPlayer: false,
            showPlayer2: false,
            sectionFormIdx: null,
            answerForm: null,
            deleteElementModal: null,
            deleteOptionModal: null,
            linkingElement: null,
            articles: [],
            sections: [],
            tags: [],
            formTags: [],
            items: [],
            version: 1,
            haveQuestionary: true,
            cursorNoDrop: false,
            numberOfElementsToRender: 1

        };
    }

    showPlayer = () => {
        this.setState({
            showPlayer: true,
        });
    };

    hidePlayer = () => {
        this.setState({
            showPlayer: false,
        });
    };

    stopAnimation = () => {
        this.player.current.play();
    };

    get = () => {
        this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));

        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0 })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

        for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
            this.props.loadDataWithQuery[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0 })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

    }

    componentDidMount() {
        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }

        this.get();

        let params = getSearchParams(this.props[0].location.search, {});

        if (params.article && params.article != 'None') {

            this.setState({
                article: params.article
            },
                this.getQuestionary
            )
        }

        window.addEventListener('scroll', this.handleScroll);

    }

    handleScroll = () => {
        var scrollTop = (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop;

        // Grodriguez's fix for scrollHeight:
        // accounting for cases where html/body are set to height:100%
        var scrollHeight = (document.documentElement && document.documentElement.scrollHeight) || document.body.scrollHeight;

        // >= is needed because if the horizontal scrollbar is visible then window.innerHeight includes
        // it and in that case the left side of the equation is somewhat greater.
        var scrolledToBottom = (scrollTop + window.innerHeight) >= ((scrollHeight) - scrollHeight / 8);
        // console.log('scrolledToBottom', scrolledToBottom)
        if (scrolledToBottom) {
            this.setState({
                numberOfElementsToRender: this.state.numberOfElementsToRender + 1
            })
        }
    }
    componentWillUnmount() {
        try {
            window.removeEventListener('scroll', this.handleScroll);

            delete this.state;
            this.state = {};
        } catch (e) {

        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps[0].location.search != this.props[0].location.search) {
            this.setState({
                loading: true
            }, () => {
                this.get();

            })
        }
    }

    updateParams = (name, value,) => {
        this.props[0].history.push(generateSearchLink(this.props[0].location.search, {}, name, value, false));
    }

    /**
    * Function return questionary
    * @author   Milan Stanojevic
    * @Objectparam    {String} article    article id
    */
    getQuestionary = () => {
        fetch(API_ENDPOINT + '/data/questionary/prepare/' + this.state.article, {
            method: 'GET',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
        }).then(parseJSON).then(({ result, status }) => {
            if (status >= 200 && status < 300) {
                //fix broken relations

                for (let i = 0; i < result.items.length; i++) {
                    if (!result.items[i].id) {
                        result.items[i].id = uuidv4();
                    }


                    /*if (result.items[i].conditionQuestion){
                        result.items[i].conditions = [{conditionQuestion: result.items[i].conditionQuestion, conditionAnswer: result.items[i].conditionAnswer }]
                        delete result.items[i].conditionQuestion;
                        delete result.items[i].conditionAnswer;
                    }*/
                }

                //console.log(JSON.stringify(result.items, null, 4) )



                this.setState({ ...result, loading: null }, () => {
                    if (this.state.items) {
                        for (let i = 0; i < this.state.items.length; i++) {
                            let newConditions = [];
                            if (this.state.items[i].conditions) {
                                for (let j = 0; j < this.state.items[i].conditions.length; j++) {
                                    if (this.state.items[i].conditions[j]) {
                                        let question = this.getItem(this.state.items[i].conditions[j].conditionQuestion);
                                        if (question) {
                                            if (question.type == 1 || question.type == 2) {
                                                let answer = this.getAnswer(this.state.items[i].conditions[j].conditionAnswer);
                                                if (answer || this.state.items[i].conditions[j].conditionAnswer == '__user-input:') {
                                                    newConditions.push(this.state.items[i].conditions[j]);
                                                }

                                            } else {
                                                newConditions.push(this.state.items[i].conditions[j]);
                                            }

                                        }
                                    }
                                }
                            }

                            this.state.items[i].conditions = newConditions;

                        }
                    }
                });
            }
        })
    }
    /**
    * Function update questionary
    * @author   Milan Stanojevic
    * @Objectparam    {String} article      article id
    * @Objectparam    {String} items        list of questions
    * @Objectparam    {String} version      version
    * @Objectparam    {String} tags         tags
    */
    updateQuestionary = () => {
        /*if (!this.checkAllPrintAnswers()) {
            this.setState({
                error: 'Please check all print answers'
            })
            return;
        }*/
        this.setState({
            showPlayer: true
        }, () => {
            this.player.current.play();
            fetch(API_ENDPOINT + '/data/questionary/prepare/' + this.state.article, {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify({
                    article: this.state.article,
                    items: this.state.items,
                    version: this.state.version,
                    tags: this.state.tags
                })
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300) {
                    this.getQuestionary();
                    this.setState({
                        message: 'Questionary saved successfully',
                        showPlayer: false,
                        showPlayer2: true,
                    }, () => {
                        setTimeout(() => {
                            this.setState({ showPlayer2: false });
                        }, 1000);
                    })
                } else {
                    this.setState({
                        showPlayer: false,
                        showPlayer2: false,
                    })
                }
            })
        })


    }

    /**
    * Function delete questionary
    * @author   Milan Stanojevic
    * @Objectparam    {String} id      questionary id
    */
    delete = (id) => {
        this.setState({
            loading: true
        }, () => {

            fetch(API_ENDPOINT + '/data/questionary/prepare/' + id, {
                method: 'DELETE',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
            }).then(parseJSON).then(({ result, status }) => {
                this.get();
                this.getQuestionary();
            })
        })

    }


    checkAllPrintAnswers = () => {
        for (let i = 0; i < this.state.items.length; i++) {
            if (this.state.items[i].type == 1 || this.state.items[i].type == 2) {
                let answers = 0;
                for (let j = 0; j < this.state.items[i].answers.length; j++) {
                    if (!this.state.items[i].answers[j].text) {
                        answers++;
                    }
                }
                if (answers == this.state.items[i].answers.length || answers == 0) {
                    continue;
                } else {
                    return false;
                }
            }
        }

        return true;
    }


    conditionIndexOf = (conditions, conditionQuestion, conditionAnswer) => {
        if (!conditions) {
            return -1;
        }

        for (let i = 0; i < conditions.length; i++) {
            if (!conditionAnswer) {
                if (conditions[i].conditionQuestion == conditionQuestion) {
                    return i;
                }
            } else {
                if (conditions[i].conditionQuestion == conditionQuestion && conditions[i].conditionAnswer == conditionAnswer) {
                    return i;
                }
            }
        }

        return -1;
    }

    getAnswer = (id) => {
        for (let i = 0; i < this.state.items.length; i++) {
            if (this.state.items[i].answers) {
                for (let j = 0; j < this.state.items[i].answers.length; j++) {
                    if (this.state.items[i].answers[j].id == id) {
                        return this.state.items[i].answers[j];
                    }
                }
            }
        }
    }


    getItem = (id) => {
        for (let i = 0; i < this.state.items.length; i++) {
            if (this.state.items[i].id == id) {
                return this.state.items[i];
            }
        }
    }



    getSectionQuestionTree = (questions, conditionQuestion, level, yAdd, xAdd, previousIds = []) => {
        // console.log(conditionQuestion);
        let maxLevel = 0;
        let items = [];
        for (let i = 0; i < questions.length; i++) {
            if (!conditionQuestion && (!questions[i].conditions || (questions[i].conditions && !questions[i].conditions.length))) {
                items.push(questions[i]);
            } else {
                // console.log('else')
                if (questions[i].conditions) {
                    let found = false;
                    for (let j = 0; j < questions[i].conditions.length; j++) {
                        if (questions[i].conditions[j].conditionQuestion == conditionQuestion && previousIds.indexOf(questions[i].id) == -1) {
                            items.push(questions[i]);
                            found = true;
                            break;
                        }
                    }

                    if (!found) {



                        /*let check1 = false;
                        for (let k = 0; k < items.length; k++) {
                            if (items[k].id == questions[i].id) {
                                check1 = true;
                                break;
                            }
                        }*/
                        if (!conditionQuestion) {

                            let checkLink = false;
                            for (let k = 0; k < questions.length; k++) {
                                if (k == i) {
                                    continue;
                                }

                                for (let j = 0; j < questions[i].conditions.length; j++) {
                                    if (questions[i].conditions[j].conditionQuestion == questions[k].id) {
                                        checkLink = true;
                                        break;
                                    }
                                }
                            }


                            if (!checkLink && previousIds.indexOf(questions[i].id) == -1) {
                                // console.log('Adding 2', questions[i].name, checkLink, conditionQuestion)
                                items.push(questions[i]);
                            }
                        }
                    }

                }
            }
        }

        // console.log(items.length);


        if (!items.length && !conditionQuestion) {
            for (let i = 0; i < questions.length; i++) {
                if (questions[i].conditions && questions[i].conditions.length) {
                    let check = true;
                    for (let j = 0; j < questions[i].conditions.length; j++) {
                        for (let k = 0; k < questions.length; k++) {
                            if (k == i) {
                                continue;
                            }

                            if (questions[i].conditions[j].conditionQuestion == questions[k].id) {
                                check = false;
                            }
                        }

                    }

                    if (check && previousIds.indexOf(questions[i].id) == -1) {
                        items.push(questions[i]);
                    }
                }
            }

        }


        previousIds = [...previousIds, ...items.map(item => item.id)];
        // console.log(previousIds)

        //console.log(items);
        for (let i = 0; i < items.length; i++) {
            if (!items[i]) {
                continue;
            }

            // console.log(items[i].name);

            items[i].position = {
                x: xAdd + i * 450,
                y: yAdd + level * 250
            }
            let res = this.getSectionQuestionTree(questions, items[i].id, level + 1, yAdd, xAdd + i * 450 - 250, previousIds);
            items[i].subQuestions = res.items;
            if (res.retLevel > level && res.retLevel > maxLevel) {
                maxLevel = res.retLevel;
            }
        }


        return {
            items,
            retLevel: !items.length ? level : maxLevel
        };
    }


    checkRelations = (itemId) => {
        let items = this.state.items;

        let itemsDict = {};
        for (let i = 0; i < items.length; i++) {
            itemsDict[items[i].id] = items[i];
        }

        let item = this.getItem(itemId)
        if (!item) {
            return null;
        }


        let upLinks = [];
        let downLinks = [];

        for (let i = 0; i < items.length; i++) {
            if (items[i].conditions) {

                let idx = this.conditionIndexOf(items[i].conditions, itemId, null);
                if (idx != -1) {
                    for (let j = 0; j < items[i].conditions.length; j++) {
                        if (items[i].conditions[j].conditionQuestion == itemId) {

                            downLinks.push({
                                conditionElement: {
                                    itemIndex: this.state.items.map((item, idx) => {
                                        if (item.id == items[i].conditions[j].conditionQuestion)
                                            return idx
                                    }),
                                    id: items[i].conditions[j].conditionQuestion,
                                    answerId: items[i].conditions[j].conditionAnswer,
                                    name: itemsDict[items[i].conditions[j].conditionQuestion].name,
                                    answer: this.getAnswer(items[i].conditions[j].conditionAnswer) && this.getAnswer(items[i].conditions[j].conditionAnswer).name,
                                },
                                element: {
                                    id: items[i].id,
                                    name: items[i].name,
                                    itemIndex: i,
                                    conditionIndex: j
                                }
                            });

                        }
                    }
                }


                if (items[i].id == itemId) {
                    for (let j = 0; j < items[i].conditions.length; j++) {
                        // if (this.getAnswer(items[i].conditions[j].conditionAnswer)) {
                        upLinks.push({
                            conditionElement: {
                                itemIndex: this.state.items.map((item, idx) => {
                                    if (item.id == items[i].conditions[j].conditionQuestion)
                                        return idx
                                }),
                                id: items[i].conditions[j].conditionQuestion,
                                answerId: items[i].conditions[j].conditionAnswer,
                                name: this.getItem(items[i].conditions[j].conditionQuestion) && this.getItem(items[i].conditions[j].conditionQuestion).name,
                                answer: this.getAnswer(items[i].conditions[j].conditionAnswer) && this.getAnswer(items[i].conditions[j].conditionAnswer).name,
                            },
                            element: {
                                id: item.id,
                                name: item.name,
                                itemIndex: i,
                                conditionIndex: j
                            }
                        })
                        // }
                    }
                }
            }


        }


        if (!upLinks.length && !downLinks.length) {
            return null;
        } else {
            return [upLinks, downLinks];
        }


    }

    checkAnswerRelations = (itemId, answerId) => {
        let items = this.state.items;

        let itemsDict = {};
        for (let i = 0; i < items.length; i++) {
            itemsDict[items[i].id] = items[i];
        }

        let item = this.getItem(itemId)
        if (!item) {
            return null;
        }


        let upLinks = [];
        let downLinks = [];

        for (let i = 0; i < items.length; i++) {
            if (items[i].conditions) {

                let idx = this.conditionIndexOf(items[i].conditions, itemId, answerId);
                if (idx != -1) {
                    for (let j = 0; j < items[i].conditions.length; j++) {
                        if (items[i].conditions[j].conditionQuestion == itemId && items[i].conditions[j].conditionAnswer == answerId) {

                            downLinks.push({
                                conditionElement: {
                                    id: items[i].conditions[j].conditionQuestion,
                                    answerId: items[i].conditions[j].conditionAnswer,
                                    name: itemsDict[items[i].conditions[j].conditionQuestion].name,
                                    answer: this.getAnswer(items[i].conditions[j].conditionAnswer).name,
                                },
                                element: {
                                    id: items[i].id,
                                    name: items[i].name,
                                    itemIndex: i,
                                    conditionIndex: j
                                }
                            });

                        }
                    }
                }

            }


        }


        if (!upLinks.length && !downLinks.length) {
            return null;
        } else {
            return [upLinks, downLinks];
        }


    }


    generateTree = () => {
        let sections = {};
        let items = this.state.items;
        let lastSectionId = -1;

        for (let i = 0; i < items.length; i++) {
            if (items[i].type == 0) {
                lastSectionId++;
                sections[lastSectionId] = {
                    id: items[i].id,
                    name: items[i].name,
                    displayOnReferral: false,
                    questions: [],
                    conditions: items[i].conditions ? items[i].conditions : []
                }
            }


            if (lastSectionId != -1 && items[i].type != 0) {

                if (items[i].type == 1) {
                    let displayOnReferral = false;
                    if (items[i].answers) {
                        for (let j = 0; j < items[i].answers.length; j++) {
                            if (items[i].answers[j].text) {
                                displayOnReferral = true;
                                break;
                            }
                        }
                    }
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        answers: items[i].answers,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        displayOnReferral: displayOnReferral
                    })
                } else if (items[i].type == 2) {
                    let displayOnReferral = false;
                    if (items[i].answers) {
                        for (let j = 0; j < items[i].answers.length; j++) {
                            if (items[i].answers[j].text) {
                                displayOnReferral = true;
                                break;
                            }
                        }
                    }
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        answers: items[i].answers,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        displayOnReferral: displayOnReferral

                    })
                } else if (items[i].type == 3) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                    })

                } else if (items[i].type == 4) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        text: items[i].text,
                        userInput: items[i].userInput,
                        conditions: items[i].conditions ? items[i].conditions : []
                    })
                } else if (items[i].type == 15) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        text: items[i].text,
                        userInput: items[i].userInput,
                        conditions: items[i].conditions ? items[i].conditions : []
                    })
                } else if (items[i].type == 5) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                    })

                } else if (items[i].type == 6) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                    })
                } else if (items[i].type == 9) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                    })
                } else if (items[i].type == 10) {
                    lastSectionId++;
                    sections[lastSectionId] = {
                        id: items[i].id,
                        name: items[i].name,
                        displayOnReferral: false,
                        questions: [],
                        conditions: items[i].conditions ? items[i].conditions : [],
                        userInput: items[i].userInput,
                    }
                } else if (items[i].type == 12) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                    })
                } else if (items[i].type == 14) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                    })
                } else if (items[i].type == 13) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                    })

                }
            }
        }

        sections = Object.values(sections);

        let arr = [];
        let yAdd = 0;
        let resItems = [];
        for (let i = 0; i < sections.length; i++) {
            resItems.push({
                name: sections[i].name,
                conditions: sections[i].conditions,
                id: sections[i].id,
                type: 0,
                position: {
                    x: 0,
                    y: yAdd
                }
            })
            yAdd += 250;

            let res = this.getSectionQuestionTree(sections[i].questions, null, 0, yAdd, 0);

            sections[i].questions = this.getQuestions(res.items);
            for (let j = 0; j < sections[i].questions.length; j++) {
                sections[i].questions[j].sectionId = sections[i].id;
            }
            resItems = [...resItems, ...sections[i].questions];
            yAdd += res.retLevel * 250
        }

        //console.log(resItems);

        return resItems;

    }


    getQuestions = (questions) => {
        let items = [...questions];

        for (let i = 0; i < questions.length; i++) {
            items = [...items, ...this.getQuestions(questions[i].subQuestions)];
        }

        return items;

    }


    generateSections = () => {
        let sections = {};
        let items = this.state.items;
        let lastSectionId = -1;
        for (let i = 0; i < items.length; i++) {
            if (items[i].type == 0) {
                lastSectionId++;
                sections[lastSectionId] = {
                    name: items[i].name,
                    displayOnReferral: false,
                    questions: [],
                    conditions: items[i].conditions ? items[i].conditions : [],
                    displayOnReferral: items[i].displayOnReferral,
                    fontStyle: items[i].fontStyle,
                    fontSize: items[i].fontSize,
                    fontWeight: items[i].fontWeight,

                }
            }
            if (lastSectionId != -1 && items[i].type != 0) {


                let wikiLinks = [];

                items[i].wikiLinks = wikiLinks;


                if (items[i].type == 1) {
                    let displayOnReferral = false;
                    if (items[i].answers) {
                        for (let j = 0; j < items[i].answers.length; j++) {
                            if (items[i].answers[j].text) {
                                displayOnReferral = true;
                            }
                        }
                    }

                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        answers: items[i].answers,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        displayOnReferral: displayOnReferral,
                        wikiLinks: items[i].wikiLinks,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                    });

                } else if (items[i].type == 2) {
                    let displayOnReferral = false;
                    if (items[i].answers) {
                        for (let j = 0; j < items[i].answers.length; j++) {

                            if (items[i].answers[j].text) {
                                displayOnReferral = true;
                            }
                        }
                    }
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        answers: items[i].answers,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        answerPrefix: items[i].answerPrefix,
                        userInput: items[i].userInput,
                        displayOnReferral: displayOnReferral || items[i].answerPrefix ? true : false,
                        wikiLinks: items[i].wikiLinks,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,

                    })
                } else if (items[i].type == 3) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        answerPrefix: items[i].answerPrefix,
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                    })

                } else if (items[i].type == 4) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        text: items[i].text,
                        userInput: items[i].userInput,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                    })
                } else if (items[i].type == 15) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        text: items[i].text,
                        userInput: items[i].userInput,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                    })

                } else if (items[i].type == 5) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                    })

                } else if (items[i].type == 6) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                    })
                } else if (items[i].type == 9) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                    })
                } else if (items[i].type == 10) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        userInput: items[i].userInput,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                    })
                } else if (items[i].type == 12) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                    })
                } else if (items[i].type == 14) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                    })

                } else if (items[i].type == 13) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                    })

                }

            }
        }

        return Object.values(sections);
    }


    render() {
        let params = {};
        if (this.state.useState) {
            params = this.state.params;
        } else {
            params = getSearchParams(this.props[0].location.search, { entries: 10, page: 0 })
        }

        let nodes = {};

        let links = {};
        let conditionsIdx = 0;

        let portCount = 0;

        if (this.state.items && this.state.items.length && this.state.showPreview) {
            let items = this.generateTree();

            for (let i = 0; i < items.length; i++) {

                //let maxParentConditionX = 0;

                let ports = {};
                if (items[i].answers) {
                    for (let j = 0; j < items[i].answers.length; j++) {
                        ports[items[i].answers[j].id] = {
                            id: items[i].answers[j].id,
                            type: 'bottom',
                            properties: { ...items[i].answers[j], linkColor: colors[portCount] }
                        }
                        portCount++;
                        if (portCount >= colors.length) {
                            portCount = 0;
                        }
                    }
                }

                if (items[i].conditions) {
                    for (let j = 0; j < items[i].conditions.length; j++) {

                        /*if (nodes[items[i].conditions[j].conditionQuestion] && nodes[items[i].conditions[j].conditionQuestion].position.x >= maxParentConditionX) {
                            maxParentConditionX = nodes[items[i].conditions[j].conditionQuestion].position.x;
                        }*/

                        ports[items[i].id + '_' + items[i].conditions[j].conditionQuestion + '_' + items[i].conditions[j].conditionAnswer] = {
                            id: items[i].id + '_' + items[i].conditions[j].conditionQuestion + '_' + items[i].conditions[j].conditionAnswer,
                            type: 'top',
                            properties: {
                                //linkColor: getRandomColor()
                            },
                            position: {
                                x: 0,
                                y: 0
                            }
                        }
                    }
                }

                if (items[i].conditions && items[i].conditions.length) {
                    for (let j = 0; j < items[i].conditions.length; j++) {
                        if (!this.getAnswer(items[i].conditions[j].conditionAnswer)) {
                            continue;
                        }

                        links['link_' + items[i].id + '_' + j] = {
                            id: 'link_' + items[i].id + '_' + j,
                            from: {
                                nodeId: items[i].conditions[j].conditionQuestion,
                                portId: items[i].conditions[j].conditionAnswer
                            },
                            to: {
                                nodeId: items[i].id,
                                portId: items[i].id + '_' + items[i].conditions[j].conditionQuestion + '_' + items[i].conditions[j].conditionAnswer
                            },
                            properties: {
                                label: this.getAnswer(items[i].conditions[j].conditionAnswer) ? this.getAnswer(items[i].conditions[j].conditionAnswer).name : 'not found link',
                                showLinkState: [items[i].conditions[j]]
                            },
                        }
                    }
                }

                if (items[i].type == 0) {
                    ports[items[i].id + '_sectionPort'] = {
                        id: items[i].id + '_sectionPort',
                        type: 'bottom',
                        properties: {}
                    }

                }


                if (items[i].type != 0 && (!items[i].conditions || (items[i].conditions && !items[i].conditions.length))) {
                    //sectionId
                    ports[items[i].id + '_sectionPort'] = {
                        id: items[i].id + '_sectionPort',
                        type: 'top',
                        properties: {}
                    }

                    links['section_link' + items[i].id + '_' + items[i].sectionId] = {
                        id: 'section_link' + items[i].id + '_' + items[i].sectionId,
                        from: {
                            nodeId: items[i].sectionId,
                            portId: items[i].sectionId + '_sectionPort'
                        },
                        to: {
                            nodeId: items[i].id,
                            portId: items[i].id + '_sectionPort'
                        },
                        properties: {

                        },
                    }
                }

                nodes[items[i].id] = {
                    id: items[i].id,
                    //readonly: true,
                    item: items[i],
                    position: items[i].position,
                    ports: ports,


                }

                if (items[i].conditions && items[i].conditions.length) {
                    conditionsIdx++;
                }
            }

        }
        //console.log(nodes);

        let articlesDict = {};
        for (let i = 0; i < this.state.articles.length; i++) {
            articlesDict[this.state.articles[i]._id] = this.state.articles[i];
        }

        return (
            <div className={this.props.autoHideSidebar ? "dashboard dashboard-auto-hide" : "dashboard"}>

                <Container fluid>

                    <Row>
                        <Col lg="12">
                            {/* {this.state.message ?
                                <UncontrolledAlert color="success">
                                    {this.state.message.translate(this.props.lang)}
                                </UncontrolledAlert>
                                :
                                null
                            } */}

                            {/*this.state.items.length && this.state.showPreview ?
                                <div className="flow-diagram">
                                    <button onClick={() => this.setState({ showPreview: null })} className="close">&times;</button>
                                    <FlowChartWithState
                                        initialValue={{
                                            offset: {
                                                x: 0,
                                                y: 0
                                            },
                                            nodes: nodes,
                                            links: links,
                                            selected: {},
                                            hovered: {}

                                        }}
                                        Components={{
                                            NodeInner: ({ node, config }) => {
                                                return (
                                                    <div style={{ width: 350, padding: 20, maxHeight: 500 }}>
                                                        <p>{node.item.type == 4 ?
                                                            <div className="treatment-instructions">
                                                                {node.item.name ? node.item.name : '/'}
                                                                <div dangerouslySetInnerHTML={{ __html: node.item.text }} />
                                                                <Checkbox checked={node.item.userInput ? true : false} label={'Alert the patient'.translate(this.props.lang)} />
                                                            </div>
                                                            : node.item.name ? node.item.name : '/'}</p>
                                                    </div>
                                                )
                                            },
                                            Link: (props) => {
                                                const { startPos, endPos, onLinkClick, link } = props
                                                const centerX = startPos.x + (endPos.x - startPos.x) / 2
                                                const centerY = startPos.y + (endPos.y - startPos.y) / 2
                                                return (
                                                    <>
                                                        <LinkDefault  {...props} onLinkMouseEnter={() => {
                                                            if (props.link.properties.showLinkState) {
                                                                this.setState({
                                                                    showLink: props.link.properties.showLinkState
                                                                })
                                                            }
                                                        }} onLinkMouseLeave={() => {
                                                            this.setState({
                                                                showLink: null
                                                            })
                                                        }} />
                                                        <div style={{ position: 'absolute', left: centerX, top: centerY }}>
                                                            {props.link.properties && props.link.properties.label && (
                                                                <div className="link-label-content">{props.link.properties && props.link.properties.label}</div>
                                                            )}
                                                        </div>
                                                    </>
                                                )
                                            },

                                            Port: (props) => {
                                                return (
                                                    <div className="answer-wrap">
                                                        <PortDefault {...props} />
                                                        {props.port && props.port.properties && props.port.properties.name ?
                                                            <span className="answer">{props.port && props.port.properties && props.port.properties.name}</span>
                                                            :
                                                            null
                                                        }
                                                    </div>
                                                )
                                            },
                                        }}
                                    />
                                </div>
                                :
                                null*/
                            }

                        </Col>
                        {this.state.articles && this.state.articles.length ?
                            <Col lg="12">
                                <div className="panel">
                                    <h5 className="component-header">{'Add or edit questionary'.translate(this.props.lang)} </h5>
                                    <Row>
                                        <Col lg="12">
                                            <Label size="sm">{'Select article'.translate(this.props.lang)}</Label>
                                            <FormGroup className="build-questionary-select-article">
                                                <Input type="select" size="sm" value={this.state.article} onChange={(e) => {
                                                    this.updateParams('article', e.target.value)
                                                    this.setState({
                                                        article: e.target.value
                                                    }, this.getQuestionary)
                                                }}>
                                                    {
                                                        [{ title: 'None'.translate(this.props.lang), _id: null }, ...this.state.articles].map((item, idx) => {
                                                            return (
                                                                <option style={item.havePreparedQuestionary ? { backgroundColor: '#cbffde' } : {}} key={idx} value={item._id}>{item.title}</option>
                                                            )
                                                        })
                                                    }
                                                </Input>
                                                <Button onClick={() => this.setState({ openPageWarningModal: true })} size="sm" color="primary">{this.state.showPlayer ? (
                                                    <Player
                                                        onEvent={(event) => {
                                                            if (event === "load") this.stopAnimation();
                                                        }}
                                                        onStateChange={this.toggleVisibility}
                                                        ref={this.player} // set the ref to your class instance
                                                        autoplay={true}
                                                        loop={true}
                                                        controls={true}
                                                        src="https://assets8.lottiefiles.com/packages/lf20_ZSRKCF.json"
                                                        style={{ height: "30px", width: "30px" }}
                                                    ></Player>

                                                ) : this.state.showPlayer2 ? (
                                                    <div style={{ display: 'flex', alignItems: 'center' }}><Player
                                                        onStateChange={this.toggleVisibility}
                                                        ref={this.player2} // set the ref to your class instance
                                                        autoplay={true}
                                                        loop={true}
                                                        controls={true}
                                                        src="https://assets10.lottiefiles.com/packages/lf20_CxyxLk.json"
                                                        style={{ height: "30px", width: "30px" }}
                                                    ></Player>
                                                        {'Saved'.translate(this.props.lang)}
                                                    </div>
                                                ) : (
                                                    "Save".translate(this.props.lang)
                                                )}</Button>
                                                {/*this.state.items.length ?
                                                    <Button onClick={() => this.setState({ showPreview: true })} size="sm" color="primary">{'Preview'.translate(this.props.lang)}</Button>
                                                    :
                                                    null*/
                                                }
                                                {this.state.article && articlesDict[this.state.article] && articlesDict[this.state.article].havePreparedQuestionary ?
                                                    <Button onClick={() => this.setState({ deleteModal: true })} size="sm" color="danger">{'Delete'.translate(this.props.lang)}</Button>
                                                    :
                                                    null
                                                }

                                                {this.state.items.length ?
                                                    <Button onClick={() => this.setState({ showTags: !this.state.showTags })} size="sm" color="primary">{this.state.showTags ? 'Hide tags'.translate(this.props.lang) : 'Show tags'.translate(this.props.lang)}</Button>
                                                    :
                                                    null
                                                }
                                                {this.state.items.length ?
                                                    <Button onClick={() => this.setState({ runModal: !this.state.runModal })} size="sm" color="primary">{'Run questionary'.translate(this.props.lang)}</Button>
                                                    :
                                                    null
                                                }

                                            </FormGroup>
                                        </Col>
                                    </Row>

                                </div>
                            </Col>
                            :
                            null
                        }
                        {this.state.showTags ?
                            <Col lg="12">
                                <div className="panel">
                                    <h4>{'Tags'.translate(this.props.lang)} </h4>
                                    <Row>
                                        <Col lg="12">
                                            {/*<FormGroup >
                                                <Label size="sm">{'Select tags (hold CTRL to select multiple tags)'.translate(this.props.lang)}</Label>
                                                <Input style={{ height: 250 }} type="select" value={this.state.tags} multiple size="sm" onChange={(e) => {
                                                    let tags = [];
                                                    for (let i = 0; i < e.target.options.length; i++) {
                                                        if (e.target.options[i].selected) {
                                                            tags.push(e.target.options[i].value);
                                                        }
                                                    }
                                                    this.setState({
                                                        tags: tags
                                                    })
                                                }}>
                                                    {
                                                        this.state.formTags.map((item, idx) => {
                                                            return (
                                                                <option key={idx} value={item._id}>{item.name}</option>
                                                            )
                                                        })
                                                    }
                                                </Input>
                                                </FormGroup>*/}
                                            <EasySelect width={3} value={this.state.tags} onChange={(tags) => this.setState({ tags })} values={this.state.formTags.map((item) => { return { name: item.name, value: item._id } })} />
                                        </Col>
                                    </Row>

                                </div>
                            </Col>

                            :
                            null
                        }

                        {this.state.article ?

                            <Col lg="12">
                                <div className="build-questionary-section">


                                    <ul className={this.state.showLink ? "linked-elements active" : "linked-elements"}>
                                        {
                                            this.state.items.map((item, idx) => {
                                                if (this.conditionIndexOf(this.state.showLink, item.id) !== -1)
                                                    return (
                                                        <li>
                                                            <div>
                                                                <span>{idx}.</span>
                                                                {
                                                                    item.type == 1 || item.type == 2 ?
                                                                        <FormGroup tag="fieldset">
                                                                            <Label size="sm">{item.name}</Label>
                                                                            {
                                                                                item.answers.map((answer, aidx) => {
                                                                                    return (
                                                                                        <FormGroup check>
                                                                                            <Label size="sm" check>
                                                                                                <Input disabled size="sm" name={item.id} type={item.type == 1 ? 'radio' : 'checkbox'} />
                                                                                                {' '}
                                                                                                {(this.state.showLink && this.conditionIndexOf(this.state.showLink, item.id, answer.id) != -1) ?
                                                                                                    <button className={(this.state.showLink && this.conditionIndexOf(this.state.showLink, item.id, answer.id) != -1) || (this.state.linkingElement !== null && this.state.items[this.state.linkingElement]?.conditions && this.state.items[this.state.linkingElement].conditions.length && this.conditionIndexOf(this.state.items[this.state.linkingElement].conditions, item.id, answer.id) != -1) ? 'linking-element active' : 'linking-element'} onClick={() => {
                                                                                                        let items = this.state.items;
                                                                                                        if (!items[this.state.linkingElement].conditions) {
                                                                                                            items[this.state.linkingElement].conditions = [];
                                                                                                        }

                                                                                                        let check = -1;
                                                                                                        for (let i = 0; i < items[this.state.linkingElement].conditions.length; i++) {
                                                                                                            if (items[this.state.linkingElement].conditions[i].conditionQuestion == item.id && items[this.state.linkingElement].conditions[i].conditionAnswer == answer.id) {
                                                                                                                check = i;
                                                                                                            }
                                                                                                        }

                                                                                                        if (check != -1) {
                                                                                                            items[this.state.linkingElement].conditions.splice(check, 1);
                                                                                                        } else {
                                                                                                            items[this.state.linkingElement].conditions.push({
                                                                                                                conditionAnswer: answer.id,
                                                                                                                conditionQuestion: item.id
                                                                                                            })
                                                                                                        }

                                                                                                        /*items[this.state.linkingElement].conditionQuestion = item.id;
                                                                                                        items[this.state.linkingElement].conditionAnswer = answer.id;
                                                                                                        */
                                                                                                        this.setState({
                                                                                                            items,
                                                                                                            // linkingElement: null
                                                                                                        });

                                                                                                    }}>{answer.name}</button>
                                                                                                    :
                                                                                                    <>
                                                                                                        {answer.name}
                                                                                                    </>
                                                                                                }
                                                                                            </Label>
                                                                                        </FormGroup>
                                                                                    )
                                                                                })
                                                                            }

                                                                        </FormGroup>
                                                                        :
                                                                        null
                                                                }
                                                                {
                                                                    item.type == 3 || item.type == 5 ?
                                                                        <FormGroup tag="fieldset">
                                                                            <Label size="sm">
                                                                                {(this.state.linkingElement !== null && this.state.linkingElement !== idx && this.state.linkingElement > idx) || (this.state.showLink && this.conditionIndexOf(this.state.showLink, item.id, null) != -1) ?
                                                                                    <button className={(this.state.showLink && this.conditionIndexOf(this.state.showLink, item.id, null) != -1) || (this.state.linkingElement !== null && this.state.items[this.state.linkingElement]?.conditions && this.state.items[this.state.linkingElement].conditions.length && this.conditionIndexOf(this.state.items[this.state.linkingElement].conditions, item.id) != -1) ? 'linking-element active' : 'linking-element'} onClick={() => {
                                                                                        let items = this.state.items;
                                                                                        //items[this.state.linkingElement].conditionQuestion = item.id;

                                                                                        if (!items[this.state.linkingElement].conditions) {
                                                                                            items[this.state.linkingElement].conditions = [];
                                                                                        }

                                                                                        let check = -1;
                                                                                        for (let i = 0; i < items[this.state.linkingElement].conditions.length; i++) {
                                                                                            if (items[this.state.linkingElement].conditions[i].conditionQuestion == item.id) {
                                                                                                check = i;
                                                                                            }
                                                                                        }

                                                                                        if (check != -1) {
                                                                                            items[this.state.linkingElement].conditions.splice(check, 1);
                                                                                        } else {
                                                                                            items[this.state.linkingElement].conditions.push({
                                                                                                conditionQuestion: item.id
                                                                                            })
                                                                                        }


                                                                                        this.setState({
                                                                                            items,
                                                                                            //linkingElement: null
                                                                                        });

                                                                                    }}>{item.name}</button>

                                                                                    :
                                                                                    <input type="text" value={item.name} onChange={(e) => {
                                                                                        let items = this.state.items;
                                                                                        items[idx].name = e.target.value;
                                                                                        this.setState({
                                                                                            items
                                                                                        })
                                                                                    }} className="edit-field-name" />
                                                                                }
                                                                            </Label>

                                                                            <Input disabled size="sm" type="text"></Input>


                                                                        </FormGroup>

                                                                        :
                                                                        null
                                                                }
                                                                {
                                                                    item.type == 6 ?
                                                                        <FormGroup tag="fieldset">
                                                                            <Label size="sm">
                                                                                {(this.state.linkingElement !== null && this.state.linkingElement !== idx && this.state.linkingElement > idx) || (this.state.showLink && this.conditionIndexOf(this.state.showLink, item.id, null) != -1) ?
                                                                                    <button className={(this.state.showLink && this.conditionIndexOf(this.state.showLink, item.id, null) != -1) || (this.state.linkingElement !== null && this.state.items[this.state.linkingElement]?.conditions && this.state.items[this.state.linkingElement].conditions.length && this.conditionIndexOf(this.state.items[this.state.linkingElement].conditions, item.id) != -1) ? 'linking-element active' : 'linking-element'} onClick={() => {
                                                                                        let items = this.state.items;
                                                                                        //items[this.state.linkingElement].conditionQuestion = item.id;

                                                                                        if (!items[this.state.linkingElement].conditions) {
                                                                                            items[this.state.linkingElement].conditions = [];
                                                                                        }

                                                                                        let check = -1;
                                                                                        for (let i = 0; i < items[this.state.linkingElement].conditions.length; i++) {
                                                                                            if (items[this.state.linkingElement].conditions[i].conditionQuestion == item.id) {
                                                                                                check = i;
                                                                                            }
                                                                                        }

                                                                                        if (check != -1) {
                                                                                            items[this.state.linkingElement].conditions.splice(check, 1);
                                                                                        } else {
                                                                                            items[this.state.linkingElement].conditions.push({
                                                                                                conditionQuestion: item.id
                                                                                            })
                                                                                        }


                                                                                        this.setState({
                                                                                            items,
                                                                                            //linkingElement: null
                                                                                        });

                                                                                    }}>{item.name}</button>

                                                                                    :
                                                                                    <input type="text" value={item.name} onChange={(e) => {
                                                                                        let items = this.state.items;
                                                                                        items[idx].name = e.target.value;
                                                                                        this.setState({
                                                                                            items
                                                                                        })
                                                                                    }} className="edit-field-name" />
                                                                                }
                                                                            </Label>

                                                                            <Row>
                                                                                <Col lg="2">
                                                                                    <FormGroup>
                                                                                        <Label>{'Weight:'.translate(this.props.lang)}</Label>
                                                                                        <Input disabled size="sm" type="text"></Input>

                                                                                    </FormGroup>
                                                                                </Col>
                                                                                <Col lg="2">
                                                                                    <FormGroup>
                                                                                        <Label>{'Height:'.translate(this.props.lang)}</Label>
                                                                                        <Input disabled size="sm" type="text"></Input>

                                                                                    </FormGroup>

                                                                                </Col>
                                                                            </Row>


                                                                        </FormGroup>

                                                                        :
                                                                        null
                                                                }
                                                                {
                                                                    item.type == 9 ?
                                                                        <FormGroup tag="fieldset">
                                                                            <Label size="sm">
                                                                                {/*
                                                                    <input style={cssStyle} type="text" value={item.name} onChange={(e) => {
                                                                        let items = this.state.items;
                                                                        items[idx].name = e.target.value;
                                                                        this.setState({
                                                                            items
                                                                        })
                                                                    }} />*/}
                                                                                {(this.state.linkingElement !== null && this.state.linkingElement !== idx && this.state.linkingElement > idx) || (this.state.showLink && this.conditionIndexOf(this.state.showLink, item.id, null) != -1) ?
                                                                                    <button className={(this.state.showLink && this.conditionIndexOf(this.state.showLink, item.id, null) != -1) || (this.state.linkingElement !== null && this.state.items[this.state.linkingElement]?.conditions && this.state.items[this.state.linkingElement].conditions.length && this.conditionIndexOf(this.state.items[this.state.linkingElement].conditions, item.id) != -1) ? 'linking-element active' : 'linking-element'} onClick={() => {
                                                                                        let items = this.state.items;
                                                                                        //items[this.state.linkingElement].conditionQuestion = item.id;

                                                                                        if (!items[this.state.linkingElement].conditions) {
                                                                                            items[this.state.linkingElement].conditions = [];
                                                                                        }

                                                                                        let check = -1;
                                                                                        for (let i = 0; i < items[this.state.linkingElement].conditions.length; i++) {
                                                                                            if (items[this.state.linkingElement].conditions[i].conditionQuestion == item.id) {
                                                                                                check = i;
                                                                                            }
                                                                                        }

                                                                                        if (check != -1) {
                                                                                            items[this.state.linkingElement].conditions.splice(check, 1);
                                                                                        } else {
                                                                                            items[this.state.linkingElement].conditions.push({
                                                                                                conditionQuestion: item.id
                                                                                            })
                                                                                        }


                                                                                        this.setState({
                                                                                            items,
                                                                                            //linkingElement: null
                                                                                        });

                                                                                    }}>{item.name}</button>

                                                                                    :
                                                                                    <>
                                                                                        <input style={cssStyle} type="text" value={item.name} onChange={(e) => {
                                                                                            let items = this.state.items;
                                                                                            items[idx].name = e.target.value;
                                                                                            this.setState({
                                                                                                items
                                                                                            })
                                                                                        }} className="edit-field-name" />
                                                                                        <div className="text-options">
                                                                                            <button className={item.fontStyle == 'italic' ? 'active' : ''} onClick={() => { let items = this.state.items; items[idx].fontStyle = items[idx].fontStyle == 'italic' ? null : 'italic'; this.setState({ items }) }}><i className="fa fa-italic" /></button>
                                                                                            <button className={item.fontWeight == 'bold' ? 'active' : ''} onClick={() => { let items = this.state.items; items[idx].fontWeight = items[idx].fontWeight == 'bold' ? null : 'bold'; this.setState({ items }) }}><i className="fa fa-bold" /></button>

                                                                                            <UncontrolledDropdown size="sm">
                                                                                                <DropdownToggle caret>
                                                                                                    <i className="fa fa-font" />
                                                                                                </DropdownToggle>
                                                                                                <DropdownMenu>
                                                                                                    <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 10; this.setState({ items }) }}>10px</DropdownItem>
                                                                                                    <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 12; this.setState({ items }) }}>12px</DropdownItem>
                                                                                                    <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 14; this.setState({ items }) }}>14px</DropdownItem>
                                                                                                    <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 16; this.setState({ items }) }}>16px</DropdownItem>
                                                                                                    <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 18; this.setState({ items }) }}>18px</DropdownItem>
                                                                                                    <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 20; this.setState({ items }) }}>20px</DropdownItem>
                                                                                                </DropdownMenu>
                                                                                            </UncontrolledDropdown>
                                                                                        </div>

                                                                                    </>

                                                                                }

                                                                            </Label>

                                                                            <Input size="sm" disabled type="file" title={"No file chosen".translate(this.props.lang)} accept="image/png, image/jpeg, image/jpg, image/gif, image/svg, .doc, .docx, .pdf" >{'Select Image'.translate(this.props.lang)}</Input>


                                                                        </FormGroup>

                                                                        :
                                                                        null
                                                                }
                                                                {
                                                                    item.type == 12 ?
                                                                        <FormGroup tag="fieldset">
                                                                            <Label size="sm">
                                                                                {(this.state.linkingElement !== null && this.state.linkingElement !== idx && this.state.linkingElement > idx) || (this.state.showLink && this.conditionIndexOf(this.state.showLink, item.id, null) != -1) ?
                                                                                    <button className={(this.state.showLink && this.conditionIndexOf(this.state.showLink, item.id, null) != -1) || (this.state.linkingElement !== null && this.state.items[this.state.linkingElement]?.conditions && this.state.items[this.state.linkingElement].conditions.length && this.conditionIndexOf(this.state.items[this.state.linkingElement].conditions, item.id) != -1) ? 'linking-element active' : 'linking-element'} onClick={() => {
                                                                                        let items = this.state.items;
                                                                                        //items[this.state.linkingElement].conditionQuestion = item.id;

                                                                                        if (!items[this.state.linkingElement].conditions) {
                                                                                            items[this.state.linkingElement].conditions = [];
                                                                                        }

                                                                                        let check = -1;
                                                                                        for (let i = 0; i < items[this.state.linkingElement].conditions.length; i++) {
                                                                                            if (items[this.state.linkingElement].conditions[i].conditionQuestion == item.id) {
                                                                                                check = i;
                                                                                            }
                                                                                        }

                                                                                        if (check != -1) {
                                                                                            items[this.state.linkingElement].conditions.splice(check, 1);
                                                                                        } else {
                                                                                            items[this.state.linkingElement].conditions.push({
                                                                                                conditionQuestion: item.id
                                                                                            })
                                                                                        }


                                                                                        this.setState({
                                                                                            items,
                                                                                            //linkingElement: null
                                                                                        });

                                                                                    }}>{item.name}</button>

                                                                                    :
                                                                                    <input type="text" value={item.name} onChange={(e) => {
                                                                                        let items = this.state.items;
                                                                                        items[idx].name = e.target.value;
                                                                                        this.setState({
                                                                                            items
                                                                                        })
                                                                                    }} className="edit-field-name" />
                                                                                }
                                                                            </Label>

                                                                            <Row>
                                                                                <Col lg="2">
                                                                                    <FormGroup>
                                                                                        <Label>{'Systolic:'.translate(this.props.lang)}</Label>
                                                                                        <Input disabled size="sm" type="text"></Input>

                                                                                    </FormGroup>
                                                                                </Col>
                                                                                <Col lg="2">
                                                                                    <FormGroup>
                                                                                        <Label>{'Diastolic:'.translate(this.props.lang)}</Label>
                                                                                        <Input disabled size="sm" type="text"></Input>

                                                                                    </FormGroup>

                                                                                </Col>
                                                                                <Col lg="2">
                                                                                    <FormGroup>
                                                                                        <Label>{'Pulse:'.translate(this.props.lang)}</Label>
                                                                                        <Input disabled size="sm" type="text"></Input>

                                                                                    </FormGroup>

                                                                                </Col>
                                                                            </Row>


                                                                        </FormGroup>

                                                                        :
                                                                        null
                                                                }
                                                                {
                                                                    item.type == 13 ?
                                                                        <FormGroup tag="fieldset">
                                                                            <Label size="sm">
                                                                                {(this.state.linkingElement !== null && this.state.linkingElement !== idx && this.state.linkingElement > idx) || (this.state.showLink && this.conditionIndexOf(this.state.showLink, item.id, null) != -1) ?
                                                                                    <button className={(this.state.showLink && this.conditionIndexOf(this.state.showLink, item.id, null) != -1) || (this.state.linkingElement !== null && this.state.items[this.state.linkingElement]?.conditions && this.state.items[this.state.linkingElement].conditions.length && this.conditionIndexOf(this.state.items[this.state.linkingElement].conditions, item.id) != -1) ? 'linking-element active' : 'linking-element'} onClick={() => {
                                                                                        let items = this.state.items;
                                                                                        //items[this.state.linkingElement].conditionQuestion = item.id;

                                                                                        if (!items[this.state.linkingElement].conditions) {
                                                                                            items[this.state.linkingElement].conditions = [];
                                                                                        }

                                                                                        let check = -1;
                                                                                        for (let i = 0; i < items[this.state.linkingElement].conditions.length; i++) {
                                                                                            if (items[this.state.linkingElement].conditions[i].conditionQuestion == item.id) {
                                                                                                check = i;
                                                                                            }
                                                                                        }

                                                                                        if (check != -1) {
                                                                                            items[this.state.linkingElement].conditions.splice(check, 1);
                                                                                        } else {
                                                                                            items[this.state.linkingElement].conditions.push({
                                                                                                conditionQuestion: item.id
                                                                                            })
                                                                                        }


                                                                                        this.setState({
                                                                                            items,
                                                                                            //linkingElement: null
                                                                                        });

                                                                                    }}>{item.name}</button>

                                                                                    :
                                                                                    <input type="text" value={item.name} onChange={(e) => {
                                                                                        let items = this.state.items;
                                                                                        items[idx].name = e.target.value;
                                                                                        this.setState({
                                                                                            items
                                                                                        })
                                                                                    }} className="edit-field-name" />
                                                                                }
                                                                            </Label>

                                                                            <Row>
                                                                                <Col lg="2">
                                                                                    <FormGroup>
                                                                                        <Input disabled size="sm" type="text"></Input>

                                                                                    </FormGroup>
                                                                                </Col>

                                                                            </Row>


                                                                        </FormGroup>

                                                                        :
                                                                        null
                                                                }

                                                                {
                                                                    item.type == 4 ?

                                                                        <FormGroup tag="fieldset">
                                                                            <Label size="sm">{item.name}</Label>


                                                                            <div dangerouslySetInnerHTML={{ __html: item.text }}>

                                                                            </div>
                                                                            <FormGroup>
                                                                                <Checkbox checked={item.userInput ? true : false} label={'Alert the patient'.translate(this.props.lang)} />

                                                                            </FormGroup>


                                                                        </FormGroup>
                                                                        :
                                                                        null
                                                                }
                                                                {
                                                                    item.type == 15 ?

                                                                        <FormGroup tag="fieldset">
                                                                            <Label size="sm">{item.name}</Label>


                                                                            <div dangerouslySetInnerHTML={{ __html: item.text }}>

                                                                            </div>



                                                                        </FormGroup>
                                                                        :
                                                                        null
                                                                }



                                                            </div>
                                                        </li>
                                                    )
                                            })
                                        }
                                    </ul>

                                    <SortableList onSortEnd={({ oldIndex, newIndex }) => {
                                        let items = this.state.items;

                                        let checkOldIndex = this.checkRelations(items[oldIndex].id);
                                        if (oldIndex > newIndex) {
                                            let arr = [];
                                            if (checkOldIndex && checkOldIndex[0].length) {
                                                for (let i = 0; i < checkOldIndex[0].length; i++) {
                                                    arr.push(checkOldIndex[0][i].conditionElement.id)
                                                }
                                                let max = 0;
                                                for (let i = 0; i < arr.length; i++) {
                                                    for (let j = 0; j < items.length; j++) {
                                                        if (arr[i] == items[j].id && j > max) {
                                                            max = j;
                                                        }
                                                    }
                                                }
                                                if (newIndex > max) {
                                                    items = arrayMove(items, oldIndex, newIndex);
                                                    this.setState({
                                                        items: items
                                                    })
                                                } else {
                                                    this.setState({ messageModalError: true })
                                                }
                                            } else {
                                                items = arrayMove(items, oldIndex, newIndex);
                                                this.setState({
                                                    items: items
                                                })
                                            }

                                        }
                                        else {
                                            let arr = [];
                                            if (checkOldIndex && checkOldIndex[1].length) {
                                                for (let i = 0; i < checkOldIndex[1].length; i++) {
                                                    arr.push(checkOldIndex[1][i].element.id)
                                                }
                                                let min = items.length - 1;
                                                for (let i = 0; i < arr.length; i++) {
                                                    for (let j = 0; j < items.length; j++) {
                                                        if (arr[i] == items[j].id && j < min) {
                                                            min = j;
                                                        }
                                                    }
                                                }
                                                if (newIndex < min) {
                                                    items = arrayMove(items, oldIndex, newIndex);
                                                    this.setState({
                                                        items: items
                                                    })
                                                } else {
                                                    this.setState({ messageModalError: true })
                                                }
                                            } else {
                                                items = arrayMove(items, oldIndex, newIndex);
                                                this.setState({
                                                    items: items
                                                })
                                            }
                                        }
                                        document.body.style.cursor = 'default';


                                    }}
                                        onSortOver={({ index, oldIndex, newIndex }) => {


                                            let items = this.state.items;



                                            let checkOldIndex = this.checkRelations(items[index].id);

                                            if (index > newIndex) {
                                                let arr = [];
                                                if (checkOldIndex && checkOldIndex[0].length) {
                                                    for (let i = 0; i < checkOldIndex[0].length; i++) {
                                                        arr.push(checkOldIndex[0][i].conditionElement.id)
                                                    }
                                                    let max = 0;
                                                    for (let i = 0; i < arr.length; i++) {
                                                        for (let j = 0; j < items.length; j++) {
                                                            if (arr[i] == items[j].id && j > max) {
                                                                max = j;
                                                            }
                                                        }
                                                    }
                                                    if (newIndex > max) {
                                                        this.setState({ cursorNoDrop: false }, () => { document.body.style.cursor = 'default'; })
                                                    } else {
                                                        this.setState({ cursorNoDrop: true }, () => { document.body.style.cursor = 'no-drop'; })
                                                    }
                                                } else {
                                                    this.setState({ cursorNoDrop: false }, () => { document.body.style.cursor = 'default'; })
                                                }

                                            }
                                            else {
                                                let arr = [];
                                                if (checkOldIndex && checkOldIndex[1].length) {
                                                    for (let i = 0; i < checkOldIndex[1].length; i++) {
                                                        arr.push(checkOldIndex[1][i].element.id)
                                                    }
                                                    let min = items.length - 1;
                                                    for (let i = 0; i < arr.length; i++) {
                                                        for (let j = 0; j < items.length; j++) {
                                                            if (arr[i] == items[j].id && j < min) {
                                                                min = j;
                                                            }
                                                        }
                                                    }
                                                    if (newIndex < min) {
                                                        this.setState({ cursorNoDrop: false }, () => { document.body.style.cursor = 'default'; })
                                                    } else {
                                                        this.setState({ cursorNoDrop: true }, () => { document.body.style.cursor = 'no-drop'; })
                                                    }
                                                } else {
                                                    this.setState({ cursorNoDrop: false }, () => { document.body.style.cursor = 'default'; })
                                                }
                                            }


                                        }}
                                        useDragHandle items={this.state.items.slice(0, this.state.numberOfElementsToRender * 10).map((item, idx) => {
                                            let cssStyle = {};
                                            if (item.fontSize) {
                                                cssStyle.fontSize = item.fontSize;
                                            }
                                            if (item.fontWeight) {
                                                cssStyle.fontWeight = item.fontWeight;
                                            }
                                            if (item.fontStyle) {
                                                cssStyle.fontStyle = item.fontStyle;
                                            }



                                            return {
                                                className: this.state.showLink && this.conditionIndexOf(this.state.showLink, item.id, null) != -1 ? 'element-link-hove' : '',
                                                component: (
                                                    <div className="referral-item">
                                                        {idx != 0 ?
                                                            <div style={{ marginTop: 15 }}>

                                                                <div className="drag-handle" >
                                                                    <DragHandle>
                                                                        <div className="drag-handle-container">
                                                                            <Isvg src={Arrows} />
                                                                        </div>
                                                                    </DragHandle>
                                                                </div>
                                                                <span>{idx}.</span>
                                                            </div>
                                                            :
                                                            null
                                                        }

                                                        {
                                                            item.type == 0 ?
                                                                <h4><input style={cssStyle} type="text" value={item.name} onChange={(e) => {
                                                                    let items = this.state.items;
                                                                    items[idx].name = e.target.value;
                                                                    this.setState({
                                                                        items
                                                                    })
                                                                }} className="edit-field-name m-l-45" />
                                                                    <div className="text-options">
                                                                        <button className={item.fontStyle == 'italic' ? 'active' : ''} onClick={() => { let items = this.state.items; items[idx].fontStyle = items[idx].fontStyle == 'italic' ? null : 'italic'; this.setState({ items }) }}><i className="fa fa-italic" /></button>
                                                                        <button className={item.fontWeight == 'bold' ? 'active' : ''} onClick={() => { let items = this.state.items; items[idx].fontWeight = items[idx].fontWeight == 'bold' ? null : 'bold'; this.setState({ items }) }}><i className="fa fa-bold" /></button>

                                                                        <UncontrolledDropdown size="sm">
                                                                            <DropdownToggle caret>
                                                                                <i className="fa fa-font" />
                                                                            </DropdownToggle>
                                                                            <DropdownMenu>
                                                                                <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 10; this.setState({ items }) }}>10px</DropdownItem>
                                                                                <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 12; this.setState({ items }) }}>12px</DropdownItem>
                                                                                <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 14; this.setState({ items }) }}>14px</DropdownItem>
                                                                                <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 16; this.setState({ items }) }}>16px</DropdownItem>
                                                                                <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 18; this.setState({ items }) }}>18px</DropdownItem>
                                                                                <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 20; this.setState({ items }) }}>20px</DropdownItem>
                                                                            </DropdownMenu>
                                                                        </UncontrolledDropdown>
                                                                    </div>



                                                                </h4>
                                                                :
                                                                null
                                                        }
                                                        {
                                                            item.type == 10 ?
                                                                <div className='new-paragraph-checkbox'>
                                                                    <h4>
                                                                        <input style={cssStyle} type="text" value={item.name} className="new-line-field" disabled />
                                                                    </h4>

                                                                    <Checkbox onChange={() => {
                                                                        let items = this.state.items;
                                                                        items[idx].userInput = !items[idx].userInput;
                                                                        this.setState({ items }, () => this.detectChange())
                                                                    }} checked={item.userInput ? true : false} label={"Add an empty line".translate(this.props.lang)} />
                                                                </div>
                                                                // <h4><input style={cssStyle} type="text" value={item.name} className="new-line-field m-l-45" disabled />


                                                                // </h4>
                                                                :
                                                                null
                                                        }
                                                        {
                                                            item.type == 14 ?
                                                                <FormGroup tag="fieldset">
                                                                    <Label size="sm">
                                                                        {(this.state.linkingElement !== null && this.state.linkingElement !== idx && this.state.linkingElement > idx) || (this.state.showLink && this.conditionIndexOf(this.state.showLink, item.id, null) != -1) ?
                                                                            <button className={(this.state.showLink && this.conditionIndexOf(this.state.showLink, item.id, null) != -1) || (this.state.linkingElement !== null && this.state.items[this.state.linkingElement]?.conditions && this.state.items[this.state.linkingElement].conditions.length && this.conditionIndexOf(this.state.items[this.state.linkingElement].conditions, item.id) != -1) ? 'linking-element active' : 'linking-element'} onClick={() => {
                                                                                let items = this.state.items;

                                                                                if (!items[this.state.linkingElement].conditions) {
                                                                                    items[this.state.linkingElement].conditions = [];
                                                                                }

                                                                                let check = -1;
                                                                                for (let i = 0; i < items[this.state.linkingElement].conditions.length; i++) {
                                                                                    if (items[this.state.linkingElement].conditions[i].conditionQuestion == item.id) {
                                                                                        check = i;
                                                                                    }
                                                                                }

                                                                                if (check != -1) {
                                                                                    items[this.state.linkingElement].conditions.splice(check, 1);
                                                                                } else {
                                                                                    items[this.state.linkingElement].conditions.push({
                                                                                        conditionQuestion: item.id
                                                                                    })
                                                                                }

                                                                                this.setState({
                                                                                    items,
                                                                                });

                                                                            }}>{item.name}</button>

                                                                            :
                                                                            <input type="text" value={item.name} onChange={(e) => {
                                                                                let items = this.state.items;
                                                                                items[idx].name = e.target.value;
                                                                                this.setState({
                                                                                    items
                                                                                })
                                                                            }} className="edit-field-name" />
                                                                        }
                                                                    </Label>
                                                                    <Col>
                                                                        <Input type='select' disabled min={0} max={100} />
                                                                    </Col>
                                                                </FormGroup>

                                                                :
                                                                null
                                                        }
                                                        {/* {
                                                            item.type == 14 ?
                                                                <FormGroup>
                                                                    <Row>
                                                                        <Col lg='12'>
                                                                            <Label>
                                                                                {(this.state.linkingElement !== null && this.state.linkingElement !== idx && this.state.linkingElement > idx) || (this.state.showLink && this.conditionIndexOf(this.state.showLink, item.id, null) != -1) ?
                                                                                    <button className={(this.state.showLink && this.conditionIndexOf(this.state.showLink, item.id, null) != -1) || (this.state.linkingElement !== null && this.state.items[this.state.linkingElement]?.conditions && this.state.items[this.state.linkingElement].conditions.length && this.conditionIndexOf(this.state.items[this.state.linkingElement].conditions, item.id) != -1) ? 'linking-element active' : 'linking-element'} onClick={() => {
                                                                                        let items = this.state.items;
                                                                                        //items[this.state.linkingElement].conditionQuestion = item.id;

                                                                                        if (!items[this.state.linkingElement].conditions) {
                                                                                            items[this.state.linkingElement].conditions = [];
                                                                                        }

                                                                                        let check = -1;
                                                                                        for (let i = 0; i < items[this.state.linkingElement].conditions.length; i++) {
                                                                                            if (items[this.state.linkingElement].conditions[i].conditionQuestion == item.id) {
                                                                                                check = i;
                                                                                            }
                                                                                        }

                                                                                        if (check != -1) {
                                                                                            items[this.state.linkingElement].conditions.splice(check, 1);
                                                                                        } else {
                                                                                            items[this.state.linkingElement].conditions.push({
                                                                                                conditionQuestion: item.id
                                                                                            })
                                                                                        }


                                                                                        this.setState({
                                                                                            items,
                                                                                            //linkingElement: null
                                                                                        });

                                                                                    }}>{item.name}</button>

                                                                                    :
                                                                                    <input type="text" value={item.name} onChange={(e) => {
                                                                                        let items = this.state.items;
                                                                                        items[idx].name = e.target.value;
                                                                                        this.setState({
                                                                                            items
                                                                                        })
                                                                                    }} className="edit-field-name" />
                                                                                }

                                                                            </Label>
                                                                        </Col>
                                                                        <Col>
                                                                            <Input type='select' disabled min={0} max={100} />
                                                                        </Col>
                                                                    </Row>
                                                                </FormGroup>
                                                                :
                                                                null
                                                        } */}

                                                        {
                                                            item.type == 1 || item.type == 2 ?
                                                                <FormGroup tag="fieldset">
                                                                    <Label size="sm"><input style={cssStyle} type="text" value={item.name} onChange={(e) => {
                                                                        let items = this.state.items;
                                                                        items[idx].name = e.target.value;
                                                                        this.setState({
                                                                            items
                                                                        })
                                                                    }} className="edit-field-name" />
                                                                        {
                                                                            item.type == 2 ?
                                                                                <>

                                                                                    <span>/</span>

                                                                                    <input style={cssStyle} type="text" value={item.answerPrefix} placeholder={'Prefix for answers'.translate(this.props.lang)} onChange={(e) => {
                                                                                        let items = this.state.items;
                                                                                        items[idx].answerPrefix = e.target.value;
                                                                                        this.setState({
                                                                                            items
                                                                                        })
                                                                                    }} className="edit-field-name" />

                                                                                </>
                                                                                :
                                                                                null
                                                                        }
                                                                        <div className="text-options">
                                                                            <button className={item.fontStyle == 'italic' ? 'active' : ''} onClick={() => { let items = this.state.items; items[idx].fontStyle = items[idx].fontStyle == 'italic' ? null : 'italic'; this.setState({ items }) }}><i className="fa fa-italic" /></button>
                                                                            <button className={item.fontWeight == 'bold' ? 'active' : ''} onClick={() => { let items = this.state.items; items[idx].fontWeight = items[idx].fontWeight == 'bold' ? null : 'bold'; this.setState({ items }) }}><i className="fa fa-bold" /></button>

                                                                            <UncontrolledDropdown size="sm">
                                                                                <DropdownToggle caret>
                                                                                    <i className="fa fa-font" />
                                                                                </DropdownToggle>
                                                                                <DropdownMenu>
                                                                                    <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 10; this.setState({ items }) }}>10px</DropdownItem>
                                                                                    <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 12; this.setState({ items }) }}>12px</DropdownItem>
                                                                                    <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 14; this.setState({ items }) }}>14px</DropdownItem>
                                                                                    <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 16; this.setState({ items }) }}>16px</DropdownItem>
                                                                                    <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 18; this.setState({ items }) }}>18px</DropdownItem>
                                                                                    <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 20; this.setState({ items }) }}>20px</DropdownItem>
                                                                                </DropdownMenu>
                                                                            </UncontrolledDropdown>
                                                                        </div>


                                                                    </Label>
                                                                    <SortableAnswers onSortEnd={({ oldIndex, newIndex }) => {
                                                                        let items = this.state.items;
                                                                        let answers = JSON.parse(JSON.stringify(items[idx].answers));
                                                                        //answers = arrayMove(answers, oldIndex, newIndex);
                                                                        let tmp = answers[newIndex];
                                                                        answers[newIndex] = answers[oldIndex];
                                                                        answers[oldIndex] = tmp;
                                                                        items[idx].answers = answers
                                                                        this.setState({
                                                                            items: items
                                                                        }, () => {
                                                                            setTimeout(() => {
                                                                                this.forceUpdate()
                                                                            }, 50)
                                                                        })
                                                                    }} useDragHandle items={
                                                                        item.answers.map((answer, aidx) => {
                                                                            return {
                                                                                component: (
                                                                                    <FormGroup className="referal-checkbox-2" check key={answer.id}>
                                                                                        <Label size="sm" check>
                                                                                            <Input disabled size="sm" name={item.id} type={item.type == 1 ? 'radio' : 'checkbox'} />
                                                                                            {' '}
                                                                                            {(this.state.linkingElement !== null && this.state.linkingElement !== idx && this.state.linkingElement > idx) ?
                                                                                                <button className={(this.state.showLink && this.conditionIndexOf(this.state.showLink, item.id, answer.id) != -1) || (this.state.linkingElement !== null && this.state.items[this.state.linkingElement]?.conditions && this.state.items[this.state.linkingElement].conditions.length && this.conditionIndexOf(this.state.items[this.state.linkingElement].conditions, item.id, answer.id) != -1) ? 'linking-element active' : 'linking-element'} onClick={() => {
                                                                                                    let items = this.state.items;
                                                                                                    if (!items[this.state.linkingElement].conditions) {
                                                                                                        items[this.state.linkingElement].conditions = [];
                                                                                                    }

                                                                                                    let check = -1;
                                                                                                    for (let i = 0; i < items[this.state.linkingElement].conditions.length; i++) {
                                                                                                        if (items[this.state.linkingElement].conditions[i].conditionQuestion == item.id && items[this.state.linkingElement].conditions[i].conditionAnswer == answer.id) {
                                                                                                            check = i;
                                                                                                        }
                                                                                                    }

                                                                                                    if (check != -1) {
                                                                                                        items[this.state.linkingElement].conditions.splice(check, 1);
                                                                                                    } else {
                                                                                                        items[this.state.linkingElement].conditions.push({
                                                                                                            conditionAnswer: answer.id,
                                                                                                            conditionQuestion: item.id
                                                                                                        })
                                                                                                    }

                                                                                                    /*items[this.state.linkingElement].conditionQuestion = item.id;
                                                                                                    items[this.state.linkingElement].conditionAnswer = answer.id;
                                                                                                    */
                                                                                                    this.setState({
                                                                                                        items,
                                                                                                        // linkingElement: null
                                                                                                    });

                                                                                                }}>{answer.name}</button>
                                                                                                :
                                                                                                <>
                                                                                                    <input type="text" value={answer.name} placeholder={'Enter option name...'.translate(this.props.lang)} className={(this.state.showLink && this.conditionIndexOf(this.state.showLink, item.id, answer.id) != -1) ? 'edit-field-input edit-field-input-active' : "edit-field-input"} onChange={(e) => {
                                                                                                        let items = this.state.items;
                                                                                                        items[idx].answers[aidx].name = e.target.value;
                                                                                                        this.setState({
                                                                                                            items
                                                                                                        })
                                                                                                    }} /> <span className="slash">/</span>  <input type="text" value={answer.text} placeholder={'Enter text for printing on referral...'.translate(this.props.lang)} onChange={(e) => {
                                                                                                        let items = this.state.items;
                                                                                                        items[idx].answers[aidx].text = e.target.value;
                                                                                                        this.setState({
                                                                                                            items
                                                                                                        })
                                                                                                    }} className={(this.state.showLink && this.conditionIndexOf(this.state.showLink, item.id, answer.id) != -1) ? 'edit-field-input edit-field-input-active' : "edit-field-input"} />
                                                                                                </>
                                                                                            }

                                                                                            <button onClick={() => {
                                                                                                this.setState({
                                                                                                    deleteOptionModal: { idx, aidx },
                                                                                                })
                                                                                            }}><Isvg src={garabage} /></button>
                                                                                            <DragHandle>
                                                                                                <div className="bam">
                                                                                                    <Isvg src={Arrows} className="arrows" />
                                                                                                </div>
                                                                                            </DragHandle>
                                                                                        </Label>
                                                                                    </FormGroup>
                                                                                )
                                                                            }

                                                                        })
                                                                    } />

                                                                    <div className="add-new-option-wrap">
                                                                        <button className="add-new-option" onClick={() => {
                                                                            let items = this.state.items;
                                                                            items[idx].answers.push({
                                                                                id: uuidv4(),
                                                                                name: '',
                                                                            });
                                                                            this.setState({
                                                                                items
                                                                            })
                                                                        }}> {'Add new option'.translate(this.props.lang)} </button>
                                                                        {item.type == 2 && item.answerPrefix ?
                                                                            <FormGroup>
                                                                                {(this.state.linkingElement !== null && this.state.linkingElement !== idx && this.state.linkingElement > idx) || (this.state.showLink && this.conditionIndexOf(this.state.showLink, item.id, null) != -1) ?
                                                                                    <button className={(this.state.showLink && this.conditionIndexOf(this.state.showLink, item.id, '__user-input:') != -1) || (this.state.linkingElement !== null && this.state.items[this.state.linkingElement]?.conditions && this.state.items[this.state.linkingElement].conditions.length && this.conditionIndexOf(this.state.items[this.state.linkingElement].conditions, item.id, '__user-input:') != -1) ? 'linking-element active' : 'linking-element'} onClick={() => {
                                                                                        let items = this.state.items;
                                                                                        if (!items[this.state.linkingElement].conditions) {
                                                                                            items[this.state.linkingElement].conditions = [];
                                                                                        }

                                                                                        let check = -1;
                                                                                        for (let i = 0; i < items[this.state.linkingElement].conditions.length; i++) {
                                                                                            if (items[this.state.linkingElement].conditions[i].conditionQuestion == item.id && items[this.state.linkingElement].conditions[i].conditionAnswer == '__user-input:') {
                                                                                                check = i;
                                                                                            }
                                                                                        }

                                                                                        if (check != -1) {
                                                                                            items[this.state.linkingElement].conditions.splice(check, 1);
                                                                                        } else {
                                                                                            items[this.state.linkingElement].conditions.push({
                                                                                                conditionAnswer: '__user-input:',
                                                                                                conditionQuestion: item.id
                                                                                            })
                                                                                        }

                                                                                        /*items[this.state.linkingElement].conditionQuestion = item.id;
                                                                                        items[this.state.linkingElement].conditionAnswer = answer.id;
                                                                                        */
                                                                                        this.setState({
                                                                                            items,
                                                                                            // linkingElement: null
                                                                                        });

                                                                                    }}>{'User can enter text answer'.translate(this.props.lang)}</button>


                                                                                    :
                                                                                    <>
                                                                                        <Checkbox onChange={() => {
                                                                                            let items = this.state.items;
                                                                                            items[idx].userInput = !items[idx].userInput;
                                                                                            this.setState({ items })
                                                                                        }} checked={item.userInput ? true : false} label={'User can enter text answer'.translate(this.props.lang)} />
                                                                                    </>
                                                                                }
                                                                            </FormGroup>
                                                                            :
                                                                            null
                                                                        }
                                                                    </div>

                                                                </FormGroup>
                                                                :
                                                                null
                                                        }
                                                        {
                                                            item.type == 3 || item.type == 5 ?
                                                                <FormGroup tag="fieldset">
                                                                    <Label size="sm">
                                                                        {(this.state.linkingElement !== null && this.state.linkingElement !== idx && this.state.linkingElement > idx) || (this.state.showLink && this.conditionIndexOf(this.state.showLink, item.id, null) != -1) ?
                                                                            <button className={(this.state.showLink && this.conditionIndexOf(this.state.showLink, item.id, null) != -1) || (this.state.linkingElement !== null && this.state.items[this.state.linkingElement]?.conditions && this.state.items[this.state.linkingElement].conditions.length && this.conditionIndexOf(this.state.items[this.state.linkingElement].conditions, item.id) != -1) ? 'linking-element active' : 'linking-element'} onClick={() => {
                                                                                let items = this.state.items;
                                                                                //items[this.state.linkingElement].conditionQuestion = item.id;

                                                                                if (!items[this.state.linkingElement].conditions) {
                                                                                    items[this.state.linkingElement].conditions = [];
                                                                                }

                                                                                let check = -1;
                                                                                for (let i = 0; i < items[this.state.linkingElement].conditions.length; i++) {
                                                                                    if (items[this.state.linkingElement].conditions[i].conditionQuestion == item.id) {
                                                                                        check = i;
                                                                                    }
                                                                                }

                                                                                if (check != -1) {
                                                                                    items[this.state.linkingElement].conditions.splice(check, 1);
                                                                                } else {
                                                                                    items[this.state.linkingElement].conditions.push({
                                                                                        conditionQuestion: item.id
                                                                                    })
                                                                                }


                                                                                this.setState({
                                                                                    items,
                                                                                    //linkingElement: null
                                                                                });

                                                                            }}>{item.name}</button>

                                                                            :
                                                                            <>
                                                                                <input style={cssStyle} type="text" value={item.name} onChange={(e) => {
                                                                                    let items = this.state.items;
                                                                                    items[idx].name = e.target.value;
                                                                                    this.setState({
                                                                                        items
                                                                                    })
                                                                                }} className="edit-field-name" />
                                                                                {
                                                                                    item.type == 3 && this.state.intelligentReferral ?
                                                                                        <>

                                                                                            /

                                                                                            <input style={cssStyle} type="text" value={item.answerPrefix} placeholder={'Prefix for answers'.translate(this.props.lang)} onChange={(e) => {
                                                                                                let items = this.state.items;
                                                                                                items[idx].answerPrefix = e.target.value;
                                                                                                this.setState({
                                                                                                    items
                                                                                                }, () => this.detectChange())
                                                                                            }} className="edit-field-name" />

                                                                                        </>
                                                                                        :
                                                                                        null
                                                                                }
                                                                                <div className="text-options">
                                                                                    <button className={item.fontStyle == 'italic' ? 'active' : ''} onClick={() => { let items = this.state.items; items[idx].fontStyle = items[idx].fontStyle == 'italic' ? null : 'italic'; this.setState({ items }) }}><i className="fa fa-italic" /></button>
                                                                                    <button className={item.fontWeight == 'bold' ? 'active' : ''} onClick={() => { let items = this.state.items; items[idx].fontWeight = items[idx].fontWeight == 'bold' ? null : 'bold'; this.setState({ items }) }}><i className="fa fa-bold" /></button>

                                                                                    <UncontrolledDropdown size="sm">
                                                                                        <DropdownToggle caret>
                                                                                            <i className="fa fa-font" />
                                                                                        </DropdownToggle>
                                                                                        <DropdownMenu>
                                                                                            <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 10; this.setState({ items }) }}>10px</DropdownItem>
                                                                                            <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 12; this.setState({ items }) }}>12px</DropdownItem>
                                                                                            <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 14; this.setState({ items }) }}>14px</DropdownItem>
                                                                                            <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 16; this.setState({ items }) }}>16px</DropdownItem>
                                                                                            <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 18; this.setState({ items }) }}>18px</DropdownItem>
                                                                                            <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 20; this.setState({ items }) }}>20px</DropdownItem>
                                                                                        </DropdownMenu>
                                                                                    </UncontrolledDropdown>
                                                                                </div>

                                                                            </>

                                                                        }
                                                                    </Label>

                                                                    <Input disabled size="sm" type="text"></Input>


                                                                </FormGroup>

                                                                :
                                                                null
                                                        }
                                                        {
                                                            item.type == 15 ?

                                                                <FormGroup tag="fieldset">
                                                                    <Label size="sm">{item.name}</Label>


                                                                    <HTML height={350} value={item.text} onChange={(text) => {
                                                                        let items = this.state.items;
                                                                        items[idx].text = text;
                                                                        this.setState({
                                                                            items
                                                                        })
                                                                    }} />



                                                                </FormGroup>
                                                                :
                                                                null
                                                        }

                                                        {
                                                            item.type == 6 ?
                                                                <FormGroup tag="fieldset">
                                                                    <Label size="sm">
                                                                        {(this.state.linkingElement !== null && this.state.linkingElement !== idx && this.state.linkingElement > idx) || (this.state.showLink && this.conditionIndexOf(this.state.showLink, item.id, null) != -1) ?
                                                                            <button className={(this.state.showLink && this.conditionIndexOf(this.state.showLink, item.id, null) != -1) || (this.state.linkingElement !== null && this.state.items[this.state.linkingElement]?.conditions && this.state.items[this.state.linkingElement].conditions.length && this.conditionIndexOf(this.state.items[this.state.linkingElement].conditions, item.id) != -1) ? 'linking-element active' : 'linking-element'} onClick={() => {
                                                                                let items = this.state.items;
                                                                                //items[this.state.linkingElement].conditionQuestion = item.id;

                                                                                if (!items[this.state.linkingElement].conditions) {
                                                                                    items[this.state.linkingElement].conditions = [];
                                                                                }

                                                                                let check = -1;
                                                                                for (let i = 0; i < items[this.state.linkingElement].conditions.length; i++) {
                                                                                    if (items[this.state.linkingElement].conditions[i].conditionQuestion == item.id) {
                                                                                        check = i;
                                                                                    }
                                                                                }

                                                                                if (check != -1) {
                                                                                    items[this.state.linkingElement].conditions.splice(check, 1);
                                                                                } else {
                                                                                    items[this.state.linkingElement].conditions.push({
                                                                                        conditionQuestion: item.id
                                                                                    })
                                                                                }


                                                                                this.setState({
                                                                                    items,
                                                                                    //linkingElement: null
                                                                                });

                                                                            }}>{item.name}</button>

                                                                            :
                                                                            <>
                                                                                <input style={cssStyle} type="text" value={item.name} onChange={(e) => {
                                                                                    let items = this.state.items;
                                                                                    items[idx].name = e.target.value;
                                                                                    this.setState({
                                                                                        items
                                                                                    })
                                                                                }} className="edit-field-name" />

                                                                                <div className="text-options">
                                                                                    <button className={item.fontStyle == 'italic' ? 'active' : ''} onClick={() => { let items = this.state.items; items[idx].fontStyle = items[idx].fontStyle == 'italic' ? null : 'italic'; this.setState({ items }) }}><i className="fa fa-italic" /></button>
                                                                                    <button className={item.fontWeight == 'bold' ? 'active' : ''} onClick={() => { let items = this.state.items; items[idx].fontWeight = items[idx].fontWeight == 'bold' ? null : 'bold'; this.setState({ items }) }}><i className="fa fa-bold" /></button>

                                                                                    <UncontrolledDropdown size="sm">
                                                                                        <DropdownToggle caret>
                                                                                            <i className="fa fa-font" />
                                                                                        </DropdownToggle>
                                                                                        <DropdownMenu>
                                                                                            <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 10; this.setState({ items }) }}>10px</DropdownItem>
                                                                                            <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 12; this.setState({ items }) }}>12px</DropdownItem>
                                                                                            <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 14; this.setState({ items }) }}>14px</DropdownItem>
                                                                                            <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 16; this.setState({ items }) }}>16px</DropdownItem>
                                                                                            <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 18; this.setState({ items }) }}>18px</DropdownItem>
                                                                                            <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 20; this.setState({ items }) }}>20px</DropdownItem>
                                                                                        </DropdownMenu>
                                                                                    </UncontrolledDropdown>
                                                                                </div>

                                                                            </>

                                                                        }
                                                                    </Label>
                                                                    <Row>
                                                                        <Col lg="2">
                                                                            <FormGroup>
                                                                                <Label>{'Weight:'.translate(this.props.lang)}</Label>
                                                                                <Input disabled size="sm" type="text"></Input>

                                                                            </FormGroup>
                                                                        </Col>
                                                                        <Col lg="2">
                                                                            <FormGroup>
                                                                                <Label>{'Height:'.translate(this.props.lang)}</Label>
                                                                                <Input disabled size="sm" type="text"></Input>

                                                                            </FormGroup>

                                                                        </Col>
                                                                    </Row>

                                                                </FormGroup>

                                                                :
                                                                null
                                                        }
                                                        {
                                                            item.type == 9 ?
                                                                <FormGroup tag="fieldset">
                                                                    <Label size="sm">
                                                                        {/*
                                                                    <input style={cssStyle} type="text" value={item.name} onChange={(e) => {
                                                                        let items = this.state.items;
                                                                        items[idx].name = e.target.value;
                                                                        this.setState({
                                                                            items
                                                                        })
                                                                    }} />*/}
                                                                        {(this.state.linkingElement !== null && this.state.linkingElement !== idx && this.state.linkingElement > idx) || (this.state.showLink && this.conditionIndexOf(this.state.showLink, item.id, null) != -1) ?
                                                                            <button className={(this.state.showLink && this.conditionIndexOf(this.state.showLink, item.id, null) != -1) || (this.state.linkingElement !== null && this.state.items[this.state.linkingElement]?.conditions && this.state.items[this.state.linkingElement].conditions.length && this.conditionIndexOf(this.state.items[this.state.linkingElement].conditions, item.id) != -1) ? 'linking-element active' : 'linking-element'} onClick={() => {
                                                                                let items = this.state.items;
                                                                                //items[this.state.linkingElement].conditionQuestion = item.id;

                                                                                if (!items[this.state.linkingElement].conditions) {
                                                                                    items[this.state.linkingElement].conditions = [];
                                                                                }

                                                                                let check = -1;
                                                                                for (let i = 0; i < items[this.state.linkingElement].conditions.length; i++) {
                                                                                    if (items[this.state.linkingElement].conditions[i].conditionQuestion == item.id) {
                                                                                        check = i;
                                                                                    }
                                                                                }

                                                                                if (check != -1) {
                                                                                    items[this.state.linkingElement].conditions.splice(check, 1);
                                                                                } else {
                                                                                    items[this.state.linkingElement].conditions.push({
                                                                                        conditionQuestion: item.id
                                                                                    })
                                                                                }


                                                                                this.setState({
                                                                                    items,
                                                                                    //linkingElement: null
                                                                                });

                                                                            }}>{item.name}</button>

                                                                            :
                                                                            <>
                                                                                <input style={cssStyle} type="text" value={item.name} onChange={(e) => {
                                                                                    let items = this.state.items;
                                                                                    items[idx].name = e.target.value;
                                                                                    this.setState({
                                                                                        items
                                                                                    })
                                                                                }} className="edit-field-name" />
                                                                                <div className="text-options">
                                                                                    <button className={item.fontStyle == 'italic' ? 'active' : ''} onClick={() => { let items = this.state.items; items[idx].fontStyle = items[idx].fontStyle == 'italic' ? null : 'italic'; this.setState({ items }) }}><i className="fa fa-italic" /></button>
                                                                                    <button className={item.fontWeight == 'bold' ? 'active' : ''} onClick={() => { let items = this.state.items; items[idx].fontWeight = items[idx].fontWeight == 'bold' ? null : 'bold'; this.setState({ items }) }}><i className="fa fa-bold" /></button>

                                                                                    <UncontrolledDropdown size="sm">
                                                                                        <DropdownToggle caret>
                                                                                            <i className="fa fa-font" />
                                                                                        </DropdownToggle>
                                                                                        <DropdownMenu>
                                                                                            <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 10; this.setState({ items }) }}>10px</DropdownItem>
                                                                                            <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 12; this.setState({ items }) }}>12px</DropdownItem>
                                                                                            <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 14; this.setState({ items }) }}>14px</DropdownItem>
                                                                                            <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 16; this.setState({ items }) }}>16px</DropdownItem>
                                                                                            <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 18; this.setState({ items }) }}>18px</DropdownItem>
                                                                                            <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 20; this.setState({ items }) }}>20px</DropdownItem>
                                                                                        </DropdownMenu>
                                                                                    </UncontrolledDropdown>
                                                                                </div>

                                                                            </>

                                                                        }

                                                                    </Label>

                                                                    <Input size="sm" disabled type="file" title={"No file chosen".translate(this.props.lang)} accept="image/png, image/jpeg, image/jpg, image/gif, image/svg, .doc, .docx, .pdf" >{'Select Image'.translate(this.props.lang)}</Input>


                                                                </FormGroup>

                                                                :
                                                                null
                                                        }
                                                        {
                                                            item.type == 12 ?
                                                                <FormGroup tag="fieldset">
                                                                    <Label size="sm">
                                                                        {(this.state.linkingElement !== null && this.state.linkingElement !== idx && this.state.linkingElement > idx) || (this.state.showLink && this.conditionIndexOf(this.state.showLink, item.id, null) != -1) ?
                                                                            <button className={(this.state.showLink && this.conditionIndexOf(this.state.showLink, item.id, null) != -1) || (this.state.linkingElement !== null && this.state.items[this.state.linkingElement]?.conditions && this.state.items[this.state.linkingElement].conditions.length && this.conditionIndexOf(this.state.items[this.state.linkingElement].conditions, item.id) != -1) ? 'linking-element active' : 'linking-element'} onClick={() => {
                                                                                let items = this.state.items;
                                                                                //items[this.state.linkingElement].conditionQuestion = item.id;

                                                                                if (!items[this.state.linkingElement].conditions) {
                                                                                    items[this.state.linkingElement].conditions = [];
                                                                                }

                                                                                let check = -1;
                                                                                for (let i = 0; i < items[this.state.linkingElement].conditions.length; i++) {
                                                                                    if (items[this.state.linkingElement].conditions[i].conditionQuestion == item.id) {
                                                                                        check = i;
                                                                                    }
                                                                                }

                                                                                if (check != -1) {
                                                                                    items[this.state.linkingElement].conditions.splice(check, 1);
                                                                                } else {
                                                                                    items[this.state.linkingElement].conditions.push({
                                                                                        conditionQuestion: item.id
                                                                                    })
                                                                                }


                                                                                this.setState({
                                                                                    items,
                                                                                    //linkingElement: null
                                                                                });

                                                                            }}>{item.name}</button>

                                                                            :
                                                                            <input type="text" value={item.name} onChange={(e) => {
                                                                                let items = this.state.items;
                                                                                items[idx].name = e.target.value;
                                                                                this.setState({
                                                                                    items
                                                                                })
                                                                            }} className="edit-field-name" />
                                                                        }
                                                                    </Label>

                                                                    <Row>
                                                                        <Col lg="2">
                                                                            <FormGroup>
                                                                                <Label>{'Systolic:'.translate(this.props.lang)}</Label>
                                                                                <Input disabled size="sm" type="text"></Input>

                                                                            </FormGroup>
                                                                        </Col>
                                                                        <Col lg="2">
                                                                            <FormGroup>
                                                                                <Label>{'Diastolic:'.translate(this.props.lang)}</Label>
                                                                                <Input disabled size="sm" type="text"></Input>

                                                                            </FormGroup>

                                                                        </Col>
                                                                        <Col lg="2">
                                                                            <FormGroup>
                                                                                <Label>{'Pulse:'.translate(this.props.lang)}</Label>
                                                                                <Input disabled size="sm" type="text"></Input>

                                                                            </FormGroup>

                                                                        </Col>
                                                                    </Row>


                                                                </FormGroup>

                                                                :
                                                                null
                                                        }
                                                        {
                                                            item.type == 13 ?
                                                                <FormGroup tag="fieldset">
                                                                    <Label size="sm">
                                                                        {(this.state.linkingElement !== null && this.state.linkingElement !== idx && this.state.linkingElement > idx) || (this.state.showLink && this.conditionIndexOf(this.state.showLink, item.id, null) != -1) ?
                                                                            <button className={(this.state.showLink && this.conditionIndexOf(this.state.showLink, item.id, null) != -1) || (this.state.linkingElement !== null && this.state.items[this.state.linkingElement]?.conditions && this.state.items[this.state.linkingElement].conditions.length && this.conditionIndexOf(this.state.items[this.state.linkingElement].conditions, item.id) != -1) ? 'linking-element active' : 'linking-element'} onClick={() => {
                                                                                let items = this.state.items;
                                                                                //items[this.state.linkingElement].conditionQuestion = item.id;

                                                                                if (!items[this.state.linkingElement].conditions) {
                                                                                    items[this.state.linkingElement].conditions = [];
                                                                                }

                                                                                let check = -1;
                                                                                for (let i = 0; i < items[this.state.linkingElement].conditions.length; i++) {
                                                                                    if (items[this.state.linkingElement].conditions[i].conditionQuestion == item.id) {
                                                                                        check = i;
                                                                                    }
                                                                                }

                                                                                if (check != -1) {
                                                                                    items[this.state.linkingElement].conditions.splice(check, 1);
                                                                                } else {
                                                                                    items[this.state.linkingElement].conditions.push({
                                                                                        conditionQuestion: item.id
                                                                                    })
                                                                                }


                                                                                this.setState({
                                                                                    items,
                                                                                    //linkingElement: null
                                                                                });

                                                                            }}>{item.name}</button>

                                                                            :
                                                                            <input type="text" value={item.name} onChange={(e) => {
                                                                                let items = this.state.items;
                                                                                items[idx].name = e.target.value;
                                                                                this.setState({
                                                                                    items
                                                                                })
                                                                            }} className="edit-field-name" />
                                                                        }
                                                                    </Label>

                                                                    <Row>
                                                                        <Col lg="2">
                                                                            <FormGroup>
                                                                                <Input disabled size="sm" type="text"></Input>

                                                                            </FormGroup>
                                                                        </Col>

                                                                    </Row>


                                                                </FormGroup>

                                                                :
                                                                null
                                                        }

                                                        {
                                                            item.type == 4 ?

                                                                <FormGroup tag="fieldset">
                                                                    <Label size="sm"><input style={cssStyle} type="text" value={item.name} onChange={(e) => {
                                                                        let items = this.state.items;
                                                                        items[idx].name = e.target.value;
                                                                        this.setState({
                                                                            items
                                                                        })
                                                                    }} className="edit-field-name" />

                                                                        <div className="text-options">
                                                                            <button className={item.fontStyle == 'italic' ? 'active' : ''} onClick={() => { let items = this.state.items; items[idx].fontStyle = items[idx].fontStyle == 'italic' ? null : 'italic'; this.setState({ items }) }}><i className="fa fa-italic" /></button>
                                                                            <button className={item.fontWeight == 'bold' ? 'active' : ''} onClick={() => { let items = this.state.items; items[idx].fontWeight = items[idx].fontWeight == 'bold' ? null : 'bold'; this.setState({ items }) }}><i className="fa fa-bold" /></button>

                                                                            <UncontrolledDropdown size="sm">
                                                                                <DropdownToggle caret>
                                                                                    <i className="fa fa-font" />
                                                                                </DropdownToggle>
                                                                                <DropdownMenu>
                                                                                    <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 10; this.setState({ items }) }}>10px</DropdownItem>
                                                                                    <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 12; this.setState({ items }) }}>12px</DropdownItem>
                                                                                    <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 14; this.setState({ items }) }}>14px</DropdownItem>
                                                                                    <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 16; this.setState({ items }) }}>16px</DropdownItem>
                                                                                    <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 18; this.setState({ items }) }}>18px</DropdownItem>
                                                                                    <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 20; this.setState({ items }) }}>20px</DropdownItem>
                                                                                </DropdownMenu>
                                                                            </UncontrolledDropdown>
                                                                        </div>



                                                                    </Label>


                                                                    <HTML height={350} value={item.text} onChange={(text) => {
                                                                        let items = this.state.items;
                                                                        items[idx].text = text;
                                                                        this.setState({
                                                                            items
                                                                        })
                                                                    }} />

                                                                    <Checkbox onChange={() => {
                                                                        let items = this.state.items;
                                                                        items[idx].userInput = !items[idx].userInput;
                                                                        this.setState({ items })
                                                                    }} checked={item.userInput ? true : false} label={'Alert the patient'.translate(this.props.lang)} />

                                                                </FormGroup>
                                                                :
                                                                null
                                                        }

                                                        <div>
                                                            {idx != 0 ? <UncontrolledDropdown className="dropdown-toggle-button" size="sm" direction="left">
                                                                <DropdownToggle caret>
                                                                    {'Type'.translate(this.props.lang)}
                                                                </DropdownToggle>
                                                                <DropdownMenu>
                                                                    <DropdownItem onClick={() => {
                                                                        let items = this.state.items;
                                                                        items[idx].type = 0;
                                                                        items[idx].name = 'Title'.translate(this.props.lang);
                                                                        this.setState({
                                                                            items
                                                                        })
                                                                    }}>{'Title'.translate(this.props.lang)}</DropdownItem>
                                                                    <DropdownItem onClick={() => {
                                                                        let items = this.state.items;
                                                                        if (item.type != 1 && item.type != 2) {
                                                                            items[idx].name = 'Single option answer name'.translate(this.props.lang);
                                                                            items[idx].answers = [
                                                                                {
                                                                                    id: uuidv4(),
                                                                                    name: ''
                                                                                },
                                                                                {
                                                                                    id: uuidv4(),
                                                                                    name: ''
                                                                                }
                                                                            ]
                                                                        }
                                                                        items[idx].type = 1;
                                                                        this.setState({
                                                                            items
                                                                        })
                                                                    }}>{'Single option answer'.translate(this.props.lang)}</DropdownItem>
                                                                    <DropdownItem onClick={() => {
                                                                        let items = this.state.items;
                                                                        if (item.type != 1 && item.type != 2) {
                                                                            items[idx].name = 'Multiple options answer name'.translate(this.props.lang);
                                                                            items[idx].answers = [
                                                                                {
                                                                                    id: uuidv4(),
                                                                                    name: ''
                                                                                },
                                                                                {
                                                                                    id: uuidv4(),
                                                                                    name: ''
                                                                                }
                                                                            ]
                                                                        }

                                                                        items[idx].type = 2;

                                                                        this.setState({
                                                                            items
                                                                        })
                                                                    }}>{'Multiple options answer'.translate(this.props.lang)}</DropdownItem>
                                                                    <DropdownItem onClick={() => {
                                                                        let items = this.state.items;
                                                                        items[idx].type = 3;
                                                                        items[idx].name = 'Text answer name'.translate(this.props.lang);
                                                                        this.setState({
                                                                            items
                                                                        })
                                                                    }}>{'Text answer'.translate(this.props.lang)}</DropdownItem>
                                                                    <DropdownItem onClick={() => {
                                                                        let items = this.state.items;
                                                                        items[idx].type = 5;
                                                                        items[idx].name = 'Diagnos'.translate(this.props.lang);
                                                                        this.setState({
                                                                            items
                                                                        })
                                                                    }}>{'Diagnos'.translate(this.props.lang)}</DropdownItem>

                                                                    <DropdownItem onClick={() => {
                                                                        let items = this.state.items;
                                                                        items[idx].type = 6;
                                                                        items[idx].name = "Enter the patient's weight and height".translate(this.props.lang);
                                                                        this.setState({
                                                                            items
                                                                        })
                                                                    }}>{"Enter the patient's weight and height".translate(this.props.lang)}</DropdownItem>
                                                                    <DropdownItem onClick={() => {
                                                                        let items = this.state.items;
                                                                        items[idx].type = 9;
                                                                        items[idx].name = 'Image or file'.translate(this.props.lang);
                                                                        this.setState({
                                                                            items
                                                                        })
                                                                    }}>{'Image or file'.translate(this.props.lang)}</DropdownItem>
                                                                    <DropdownItem onClick={() => {
                                                                        let items = this.state.items;
                                                                        items[idx].type = 4;
                                                                        items[idx].name = 'Treatment instructions'.translate(this.props.lang);
                                                                        this.setState({
                                                                            items
                                                                        })
                                                                    }}>{'Treatment instructions'.translate(this.props.lang)}</DropdownItem>
                                                                    <DropdownItem onClick={() => {
                                                                        let items = this.state.items;
                                                                        items[idx].type = 15;
                                                                        items[idx].name = 'Info text'.translate(this.props.lang);
                                                                        this.setState({
                                                                            items
                                                                        })
                                                                    }}>{'Info text'.translate(this.props.lang)}</DropdownItem>
                                                                    <DropdownItem onClick={() => {
                                                                        let items = this.state.items;
                                                                        items[idx].type = 10;
                                                                        items[idx].name = 'New empty row'.translate(this.props.lang);
                                                                        this.setState({
                                                                            items
                                                                        })
                                                                    }}>{'New empty row'.translate(this.props.lang)}</DropdownItem>

                                                                    <DropdownItem onClick={() => {
                                                                        let items = this.state.items;
                                                                        items[idx].type = 12;
                                                                        items[idx].name = 'Blood pressure measured to'.translate(this.props.lang);
                                                                        this.setState({
                                                                            items
                                                                        })
                                                                    }}>{'Blood pressure'.translate(this.props.lang)}</DropdownItem>

                                                                    <DropdownItem onClick={() => {
                                                                        let items = this.state.items;
                                                                        items[idx].type = 13;
                                                                        items[idx].name = 'The patient states VAS:'.translate(this.props.lang);
                                                                        this.setState({
                                                                            items
                                                                        })
                                                                    }}>{'VAS-scale'.translate(this.props.lang)}</DropdownItem>
                                                                    <DropdownItem onClick={() => {
                                                                        let items = this.state.items;
                                                                        items[idx].type = 14;
                                                                        items[idx].name = 'Current respiratory rate/minute:'.translate(this.props.lang);
                                                                        this.setState({
                                                                            items
                                                                        })
                                                                    }}>{'Respiratory rate'.translate(this.props.lang)}</DropdownItem>
                                                                </DropdownMenu>

                                                            </UncontrolledDropdown>
                                                                :
                                                                null
                                                            }

                                                            {item.type == 0 ?

                                                                <button className={item.displayOnReferral ? /*'print-enabled'*/ 'referral-builder-tooltip-wrap' : 'referral-builder-tooltip-wrap'} onClick={() => {
                                                                    let items = this.state.items;
                                                                    items[idx].displayOnReferral = !items[idx].displayOnReferral;

                                                                    this.setState({
                                                                        items
                                                                    }, () => this.detectChange())
                                                                }}>
                                                                    {
                                                                        item.displayOnReferral ?
                                                                            <div className='print-show-hide-icon-wrap'>
                                                                                <Isvg src={viewIcon} />
                                                                            </div>
                                                                            :
                                                                            <div className='print-show-hide-icon-wrap hide-icon-wrap'>
                                                                                <Isvg src={hideIcon} />
                                                                            </div>
                                                                    }
                                                                    <div className='referral-builder-tooltip2'>{'Display title in preview'.translate(this.props.lang)}</div>

                                                                </button>


                                                                :
                                                                null
                                                            }


                                                            {idx != 0 ?
                                                                <button className={this.state.linkingElement == idx ? 'linking-enabled' : (item.conditions && item.conditions.length) ? 'item-linked' : ''} onMouseEnter={() => {
                                                                    if (item.conditions && item.conditions.length) {
                                                                        this.setState({
                                                                            showLink: item.conditions
                                                                        })
                                                                    }
                                                                }} onMouseLeave={() => {
                                                                    if (item.conditions && item.conditions.length) {
                                                                        this.setState({
                                                                            showLink: null
                                                                        })
                                                                    }
                                                                }} onClick={() => {
                                                                    this.setState({
                                                                        linkingElement: this.state.linkingElement !== idx ? idx : null,
                                                                        //showLink: this.state.linkingElement !== idx ? item.conditions  : null
                                                                    })
                                                                }}><Isvg src={Link} /></button>
                                                                :
                                                                null
                                                            }


                                                            {idx != 0 ?
                                                                <button className="trash-button" onClick={() => {
                                                                    this.setState({
                                                                        deleteElementModal: idx,
                                                                    })
                                                                }}><Isvg src={garabage} /></button>
                                                                :
                                                                null
                                                            }
                                                            <UncontrolledDropdown size="sm" className="insert-above-below">
                                                                <DropdownToggle>
                                                                    <Isvg src={moreIcon} />
                                                                </DropdownToggle>
                                                                <DropdownMenu>
                                                                    {
                                                                        idx > 0 ?
                                                                            <DropdownItem
                                                                                onClick={() => {
                                                                                    let items = this.state.items;
                                                                                    items.splice(idx, 0, {
                                                                                        name: 'Single option answer name'.translate(this.props.lang),
                                                                                        answers: [
                                                                                            {
                                                                                                id: uuidv4(),
                                                                                                name: ''
                                                                                            },
                                                                                            {
                                                                                                id: uuidv4(),
                                                                                                name: ''
                                                                                            }
                                                                                        ],
                                                                                        id: uuidv4(),
                                                                                        type: 1,
                                                                                    });

                                                                                    this.setState({
                                                                                        items
                                                                                    })
                                                                                }}>{"Insert above".translate(this.props.lang)}</DropdownItem>
                                                                            :
                                                                            null
                                                                    }

                                                                    <DropdownItem
                                                                        onClick={() => {
                                                                            let items = this.state.items;
                                                                            items.splice(idx + 1, 0, {
                                                                                name: 'Single option answer name'.translate(this.props.lang),
                                                                                answers: [
                                                                                    {
                                                                                        id: uuidv4(),
                                                                                        name: ''
                                                                                    },
                                                                                    {
                                                                                        id: uuidv4(),
                                                                                        name: ''
                                                                                    }
                                                                                ],
                                                                                id: uuidv4(),
                                                                                type: 1,
                                                                            });

                                                                            this.setState({
                                                                                items
                                                                            })
                                                                        }}
                                                                    >{"Insert below".translate(this.props.lang)}</DropdownItem>
                                                                </DropdownMenu>
                                                            </UncontrolledDropdown>
                                                            {/* <Button color="primary" style={{ padding: 0 }}
                                                            className="add-new-el-btn"
                                                            onClick={() => {
                                                                let items = this.state.items;
                                                                items.splice(idx + 1, 0,
                                                                    {
                                                                        name: `Element ${this.state.items.length + 1}`,
                                                                        id: uuidv4(),
                                                                        type: 0,
                                                                    })

                                                                // items.push({
                                                                //     name: `Element ${this.state.items.length + 1}`,
                                                                //     id: uuidv4(),
                                                                //     type: 0,
                                                                // });
                                                                this.setState({
                                                                        items
                                                                    })
                                                            }}><Isvg src={addIcon} />
                                                            <div className="chat-icons-tooltip">{'Add new element'.translate(this.props.lang)}</div>
                                                            </Button> */}

                                                        </div>



                                                    </div>
                                                )
                                            }
                                        })}></SortableList>
                                </div>



                                <div className="panel">
                                    <Button color="primary" onClick={() => {
                                        let items = this.state.items;
                                        items.push({
                                            name: `Element ${this.state.items.length + 1}`,
                                            id: uuidv4(),
                                            type: 0,
                                        });
                                        this.setState({
                                            items
                                        })
                                    }}>{'Add new element'.translate(this.props.lang)}</Button>
                                    <Button onClick={() => this.setState({ openPageWarningModal: true })} style={{ marginLeft: 20 }} color="primary">{this.state.showPlayer ? (
                                        <Player
                                            onEvent={(event) => {
                                                if (event === "load") this.stopAnimation();
                                            }}
                                            onStateChange={this.toggleVisibility}
                                            ref={this.player} // set the ref to your class instance
                                            autoplay={true}
                                            loop={true}
                                            controls={true}
                                            src="https://assets8.lottiefiles.com/packages/lf20_ZSRKCF.json"
                                            style={{ height: "30px", width: "30px" }}
                                        ></Player>

                                    ) : this.state.showPlayer2 ? (
                                        <div style={{ display: 'flex', alignItems: 'center' }}><Player
                                            onStateChange={this.toggleVisibility}
                                            ref={this.player2} // set the ref to your class instance
                                            autoplay={true}
                                            loop={true}
                                            controls={true}
                                            src="https://assets10.lottiefiles.com/packages/lf20_CxyxLk.json"
                                            style={{ height: "30px", width: "30px" }}
                                        ></Player>
                                            {'Saved'.translate(this.props.lang)}
                                        </div>
                                    ) : (
                                        "Save".translate(this.props.lang)
                                    )}</Button>

                                </div>

                            </Col>

                            :
                            null
                        }
                    </Row>
                    {
                        this.state.messageModalError ?
                            <Modal isOpen={this.state.messageModalError} centered toggle={() => this.setState({ messageModalError: !this.state.runModal })}>
                                {/* <ModalHeader style={{ margin: 'auto' }} >
                                    // {'Error message'.translate(this.props.lang)}
                                    {'Warning'.translate(this.props.lang)}
                                </ModalHeader> */}

                                <ModalBody className='delete-modal'>
                                    <p>{'You cannot drop here this element!'.translate(this.props.lang)}</p>

                                </ModalBody>

                                <ModalFooter className='buttons-right-blue'>
                                    <Button style={{ minWidth: 100 }} color='primary' onClick={() => this.setState({ messageModalError: !this.state.messageModalError })}>{'Ok'.translate(this.props.lang)}</Button>

                                </ModalFooter>

                            </Modal>
                            :
                            null
                    }

                </Container>



                <DeleteModal
                    lang={this.props.lang}
                    isOpen={this.state.deleteElementModal !== null}
                    toggle={() => this.setState({ deleteElementModal: null })}
                    handler={() => {
                        let items = this.state.items;
                        let relations = this.checkRelations(this.state.items[this.state.deleteElementModal].id);
                        // console.log(relations)
                        if (relations && relations.length && (relations[0].length || relations[1].length)) {
                            this.setState({
                                relationError: relations
                            })

                            return;
                        }


                        items.splice(this.state.deleteElementModal, 1);
                        this.setState({
                            items,
                            deleteElementModal: null
                        })
                    }}
                >
                    {'Delete element'.translate(this.props.lang)} <strong>{this.state.items[this.state.deleteElementModal] && this.state.items[this.state.deleteElementModal].name}</strong>?
                </DeleteModal>
                <DeleteModal
                    lang={this.props.lang}
                    isOpen={this.state.deleteOptionModal !== null}
                    toggle={() => this.setState({ deleteOptionModal: null })}
                    handler={() => {
                        let items = this.state.items;

                        let relations = this.checkAnswerRelations(this.state.items[this.state.deleteOptionModal.idx].id, this.state.items[this.state.deleteOptionModal.idx].answers[this.state.deleteOptionModal.aidx].id);
                        if (relations && relations.length && (relations[0].length || relations[1].length)) {
                            this.setState({
                                answerRelationError: relations
                            })

                            return;
                        }


                        items[this.state.deleteOptionModal.idx].answers.splice(this.state.deleteOptionModal.aidx, 1);
                        this.setState({
                            items,
                            deleteOptionModal: null,
                        })
                    }}
                >
                    {'Delete option'.translate(this.props.lang)}?
                </DeleteModal>

                {
                    this.state.error ?

                        <ErrorModal lang={this.props.lang}
                            isOpen={this.state.error}
                            toggle={() => this.setState({ error: null })}

                        >
                            {this.state.error.translate(this.props.lang)}
                        </ErrorModal>

                        :
                        null
                }

                {
                    this.state.relationError ?

                        <ErrorModal lang={this.props.lang}
                            isOpen={this.state.relationError}
                            toggle={() => this.setState({ relationError: null })}
                            size="xl"

                        >
                            <h4>{'You need to unlink current relationships to continue'.translate(this.props.lang)}</h4>
                            <div className="relations-content">

                                <h6>{'Upper conditions'.translate(this.props.lang)}</h6>
                                <Table striped className="relations-table">
                                    <tr>
                                        <th>{'Condition element'.translate(this.props.lang)}</th>
                                        <th>{'Answer'.translate(this.props.lang)}</th>
                                        <th>{'Element'.translate(this.props.lang)}</th>
                                        <th>{'Action'.translate(this.props.lang)}</th>
                                    </tr>

                                    {
                                        this.state.relationError[0].map((item) => {
                                            return (
                                                <tr>
                                                    <td>{item.conditionElement.itemIndex}. {item.conditionElement.name}</td>
                                                    <td>{item.conditionElement.answer}</td>
                                                    <td>{item.element.itemIndex}. {item.element.name}</td>
                                                    <td><button onClick={() => {
                                                        let items = this.state.items;
                                                        items[item.element.itemIndex].conditions.splice(item.element.conditionIndex, 1);
                                                        this.setState({
                                                            items
                                                        }, () => {
                                                            let relations = this.checkRelations(this.state.items[this.state.deleteElementModal].id);
                                                            if (relations && relations.length && (relations[0].length || relations[1].length)) {
                                                                this.setState({
                                                                    relationError: relations
                                                                })

                                                            } else {
                                                                this.setState({
                                                                    relationError: null
                                                                })
                                                            }

                                                        })
                                                    }}><i className="fa fa-unlink danger-color" /></button></td>
                                                </tr>
                                            )
                                        })
                                    }


                                </Table>

                                <h6>{'Down conditions'.translate(this.props.lang)}</h6>
                                <Table striped className="relations-table">
                                    <tr>
                                        <th>{'Condition element'.translate(this.props.lang)}</th>
                                        <th>{'Answer'.translate(this.props.lang)}</th>
                                        <th>{'Element'.translate(this.props.lang)}</th>
                                        <th>{'Action'.translate(this.props.lang)}</th>
                                    </tr>
                                    {
                                        this.state.relationError[1].map((item) => {
                                            return (
                                                <tr>
                                                    <td>{item.conditionElement.itemIndex}. {item.conditionElement.name}</td>
                                                    <td>{item.conditionElement.answer}</td>
                                                    <td>{item.element.itemIndex}. {item.element.name}</td>

                                                    <td><button onClick={() => {
                                                        let items = this.state.items;
                                                        items[item.element.itemIndex].conditions.splice(item.element.conditionIndex, 1);
                                                        this.setState({
                                                            items
                                                        }, () => {
                                                            let relations = this.checkRelations(this.state.items[this.state.deleteElementModal].id);
                                                            if (relations && relations.length && (relations[0].length || relations[1].length)) {
                                                                this.setState({
                                                                    relationError: relations
                                                                })

                                                            } else {
                                                                this.setState({
                                                                    relationError: null
                                                                })
                                                            }

                                                        })
                                                    }}><i className="fa fa-unlink danger-color" /></button></td>
                                                </tr>
                                            )
                                        })
                                    }
                                </Table>

                            </div>
                        </ErrorModal>

                        :
                        null
                }

                {
                    this.state.answerRelationError ?

                        <ErrorModal lang={this.props.lang}
                            isOpen={this.state.answerRelationError}
                            toggle={() => this.setState({ answerRelationError: null })}
                            size="xl"

                        >
                            <h4>{'Relations'.translate(this.props.lang)}</h4>
                            <Table striped className="relations-table">
                                <tr>
                                    <th>{'Condition element'.translate(this.props.lang)}</th>
                                    <th>{'Answer'.translate(this.props.lang)}</th>
                                    <th>{'Element'.translate(this.props.lang)}</th>
                                    <th>{'Action'.translate(this.props.lang)}</th>
                                </tr>
                                {
                                    this.state.answerRelationError[1].map((item) => {
                                        return (
                                            <tr>
                                                <td>{item.conditionElement.name}</td>
                                                <td>{item.conditionElement.answer}</td>
                                                <td>{item.element.name}</td>

                                                <td><button onClick={() => {
                                                    let items = this.state.items;
                                                    items[item.element.itemIndex].conditions.splice(item.element.conditionIndex, 1);
                                                    this.setState({
                                                        items
                                                    }, () => {
                                                        let relations = this.checkAnswerRelations(this.state.items[this.state.deleteOptionModal.idx].id, this.state.items[this.state.deleteOptionModal.idx].answers[this.state.deleteOptionModal.aidx].id);
                                                        if (relations && relations.length && (relations[0].length || relations[1].length)) {
                                                            this.setState({
                                                                answerRelationError: relations
                                                            })

                                                        } else {
                                                            this.setState({
                                                                answerRelationError: null
                                                            })
                                                        }

                                                    })
                                                }}><i className="fa fa-unlink danger-color" /></button></td>
                                            </tr>
                                        )
                                    })
                                }
                                {
                                    this.state.answerRelationError[0].map((item) => {
                                        return (
                                            <tr>
                                                <td>{item.conditionElement.name}</td>
                                                <td>{item.conditionElement.answer}</td>
                                                <td>{item.element.name}</td>
                                                <td><button onClick={() => {
                                                    let items = this.state.items;
                                                    items[item.element.itemIndex].conditions.splice(item.element.conditionIndex, 1);
                                                    this.setState({
                                                        items
                                                    }, () => {
                                                        let relations = this.checkAnswerRelations(this.state.items[this.state.deleteOptionModal.idx].id, this.state.items[this.state.deleteOptionModal.idx].answers[this.state.deleteOptionModal.aidx].id);
                                                        if (relations && relations.length && (relations[0].length || relations[1].length)) {
                                                            this.setState({
                                                                answerRelationError: relations
                                                            })

                                                        } else {
                                                            this.setState({
                                                                answerRelationError: null
                                                            })
                                                        }

                                                    })
                                                }}><i className="fa fa-unlink danger-color" /></button></td>
                                            </tr>
                                        )
                                    })
                                }


                            </Table>
                        </ErrorModal>

                        :
                        null
                }



                <DeleteModal
                    lang={this.props.lang}
                    isOpen={this.state.deleteModal}
                    toggle={() => this.setState({ deleteModal: null })}
                    handler={() => {
                        this.delete(this.state.article);
                        this.setState({ deleteModal: null })
                    }}
                >
                    {'Delete prepared questionary'.translate(this.props.lang)}?
                </DeleteModal>


                {
                    this.state.runModal ?
                        <Modal isOpen={this.state.runModal} centered toggle={() => this.setState({ runModal: !this.state.runModal })} size={'xl'}>
                            <ModalHeader toggle={() => this.setState({ runModal: !this.state.runModal })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ runModal: !this.state.runModal })}>&times;</button>}>{'Form preview'.translate(this.props.lang)}</ModalHeader>

                            <ModalBody className="run-modal">
                                <CreateReferralModal lang={this.props.lang} ring112Audio={(item) => this.props.ring112Audio(item)} sections={this.generateSections()}></CreateReferralModal>
                            </ModalBody>
                        </Modal>
                        :
                        null
                }
                {this.state.openPageWarningModal ?
                    <InfoModal
                        isOpen={this.state.openPageWarningModal}
                        onClose={(item) => {
                            this.setState({
                                openPageWarningModal: item
                            })
                        }}
                        toggle={() => this.setState({ openPageWarningModal: !this.state.openPageWarningModal })}
                        header={'Warning'.translate(this.props.lang)}
                        info={'Make sure the form works as desired!'.translate(this.props.lang)}
                        buttons={[

                            <Button color='primary' onClick={() => {
                                this.setState({
                                    openPageWarningModal: false
                                })
                                this.updateQuestionary()

                            }}>{'Ok'.translate(this.props.lang)}</Button>


                        ]}
                    />

                    :

                    null}

            </div >
        );
    }
}

const selector = formValueSelector('modalForm');

export default connect(state => {
    return {
        group: selector(state, 'group'),
        type: selector(state, 'type'),
        displayOnReferral: selector(state, 'displayOnReferral'),
        conditionQuestion: selector(state, 'conditionQuestion')

    }

}, {
    changeSubgroup: value => change("modalForm", "subgroup", value),

})(Page(PrepareQuestionary));