
import React, { Component } from 'react';
import { formatCSS } from '../cssHelper';

import accountMobile from '../../assets/images/mobile-account.png';

/**
* Component for html field
* @author   Milan Stanojevic
*/
class Account extends Component {
    constructor(props) {
        super(props);

        this.state = {

        };
    }

    render() {
        return (
            <div style={{...formatCSS(this.props.style), position: 'relative'}}>
                {
                    this.props.style.borderTopLeftRadius || this.props.style.borderTopRightRadius ||
                        this.props.style.borderBottomLeftRadius || this.props.style.borderBottomRightRadius ?
                        <InverseRadius style={this.props.style} />
                        :
                        null


                }

                <img src={accountMobile} />

            </div>




        );
    }
}

export default Account;