import React, { Component } from 'react';

import Select, { components, DropdownIndicatorProps, GroupProps, PlaceholderProps } from 'react-select';

import { Link, Redirect } from 'react-router-dom';

import Isvg from 'react-inlinesvg';

import { API_ENDPOINT } from '../../../constants';

import GoToLocation from '../../../assets/svg/go-to-location.svg'

import direction from '../../../assets/svg/direction.svg'
import search from '../../../assets/svg/search.svg'


import chroma from 'chroma-js';
const defaultValue = [
    {
        value: -1,
        label: 'Search...'
    },
    {
        value: -1,
        label: 'Sök...'
    },
]

const NoOptionsMessage = props => {
    return (
        <div style={{ backgroundColor: 'white', padding: '0 15px', fontSize: 14, lineHeight: '30px', textAlign: 'center', color: '#afafaf' }}>{'NO OPTIONS'.translate(typeof window != 'undefined' ? window.lang : 'se')}</div>
    );
};

const DropdownIndicator = (
    props
) => {
    return (
        <components.DropdownIndicator {...props}>
            <Isvg src={search} style={{ width: 18 }} />
        </components.DropdownIndicator>
    );
};




const colourStyles = {
    control: styles => ({ ...styles, backgroundColor: 'white', border: 'none', borderRadius: 10, height: 48, paddingLeft: 5, paddingRight: 0, fontSize: 15, fontWeight: 500, color: '#3A3F56' }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        const color = chroma("#E6EDF5");
        return {
            ...styles,
            border: 'none',
            outline: 'none',
            fontSize: 14,
            backgroundColor: isDisabled
                ? null
                : isSelected
                    ? "#568AC8"
                    : isFocused
                        ? color.alpha(0.1).css()
                        : null,
            color: isDisabled
                ? '#ccc'
                : isSelected
                    ? 'white'
                        ? "#E6EDF5"
                        : 'black'
                    : data.color,
            cursor: isDisabled ? 'not-allowed' : 'pointer',

            ':active': {
                ...styles[':active'],
                backgroundColor:
                    !isDisabled && (isSelected ? "#568AC8" : "#E6EDF5"),
            },
            ':hover': {
                ...styles[':hover'],
                backgroundColor:
                    !isDisabled && (isSelected ? "#568AC8" : "#E6EDF5"),
            },

        };
    },
    menu: (provided, state) => {

        let obj = {}
        let height;
        if (state && state.selectProps && state.selectProps.mapHeight) {
            height = state.selectProps.mapHeight;
        }
        if (height && height == '100vh' && typeof window != 'undefined' && window.screen && window.screen.height) {
            height = window.screen.height
        }
        if (height) {
            obj.maxHeight = height - 80;
            obj.height = height - 80;
            obj.height = 'auto'
        }
        obj.position = 'relative';

        return {
            ...provided,
            ...obj
        }
    },




    input: styles => ({ ...styles }),
    placeholder: styles => ({ ...styles, fontSize: '16px', color: '#ccc', fontWeight: 400 }),
    singleValue: (styles, { data }) => ({ ...styles }),
};
const CustomOption = ({ children, ...props }) => {
    return (
        <components.Option {...props} className={props.isSelected ? "option-row option-row-selected" : "option-row"}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div className="landing-map-search-wrap">

                    <div>
                        <div className="landing-map-search-label">

                            {
                                props && props.data && props.data.label ?
                                    <p>{props.data.label}</p>
                                    :
                                    null
                            }
                        </div>

                    </div>


                </div>

            </div>

        </components.Option>
    );
};

const Placeholder = (props) => {
    return <components.Placeholder {...props} />;
};


class SelectWithSearch extends Component {
    constructor(props) {
        super(props);

        this.state = {
            list: [],
            inputValue: ''

        };
    }

    componentDidMount() {

    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.showAll != this.props.showAll && this.props.showAll) {
            if (this.selectRef && this.selectRef.select) {
                this.selectRef.select.focus()
            }
        }
    }




    render() {
        const resultLimit = 600;
        const maxLength = 10;
        let i = 0

        let lastVisitedClinics = this.props.lastVisitedClinics;
        let list1 = [];
        if (this.props.showAll || this.state.inputValue || true) {
            list1 = this.props.list && this.props.list.filter(el => !this.state.inputValue || (this.state.inputValue && el.name && el.name.toLowerCase().indexOf(this.state.inputValue.toLowerCase()) != -1)).map((item, idx) => {
                let obj = {
                    label: item.name,
                    value: item.value,
                    image: item.logo,
                    address: item.landingConfig && item.landingConfig.address,
                    link: item.link,
                    history: this.props.history
                }

                return (
                    obj
                )
            })
        }

        let list2 = []
        if (!this.state.inputValue) {
            list2 = lastVisitedClinics && lastVisitedClinics.map((item, idx) => {
                let obj = {
                    label: item.name,
                    value: item._id,
                    image: item.logo,
                    // address: item.landingConfig && item.landingConfig.address,
                    link: item.link,
                    history: this.props.history
                }

                return (
                    obj
                )
            });
        }

        const groupedOptions = [
            {
                label: 'Last visited'.translate(this.props.lang),
                options: [...list2],
            },
            {
                label: !this.state.inputValue && list2.length ? 'Others'.translate(this.props.lang) : '',
                options: [...list1],
            },
        ];

        // const groupStyles = {
        //     background: 
        // };
        const Group = (props) => {
            return (
                <div style={{ background: props && props.data && props.data.label == 'Last visited'.translate(this.props.lang) ? '#fcfce6' : '#fff' }}>
                    <components.Group {...props} />
                </div>
            );
        };


        return (
            <div className="select-with-search-wrap" style={{ width: this.props.width ? this.props.width : '100%' }}>
                {
                    this.props.label ?
                        <label>{this.props.label}</label>
                        :
                        null
                }
                {
                    typeof window != 'undefined' && window.innerWidth >= 768 ?
                        <Select
                            ref={node => this.selectRef = node}
                            defaultValue={typeof window != 'undefined' && window.lang == 'en' ? defaultValue[0] : defaultValue[1]}
                            options={groupedOptions}

                            // menuIsOpen={this.props.menuIsOpen || this.props.showAll ? true : this.state.inputValue ? undefined : false}
                            menuIsOpen={this.props.showAll ? true : undefined}

                            // mapHeight={this.props.mapHeight}
                            components={{ NoOptionsMessage, Group, DropdownIndicator, IndicatorSeparator: () => null }}
                            // isClearable={true}
                            styles={colourStyles}
                            className="select-with-search"
                            onChange={(e) => this.props.onChange(e)}
                            maxOptions={2}
                            value={
                                this.props.value ? {
                                    label: this.props.value.name,
                                    value: this.props.value.value
                                }
                                    :
                                    typeof window != 'undefined' && window.lang == 'en' ? defaultValue[0] : defaultValue[1]
                            }
                            inputValue={this.state.inputValue}
                            onInputChange={(e) => {
                                this.setState({ inputValue: e }, () => {
                                    if (this.props.showAll && this.props.changeShowAll) {
                                        this.props.changeShowAll(false)
                                    }
                                })

                            }}
                        />
                        :
                        <div className={this.props.showAll ? 'select-with-search-fixed-mobile' : ''}>
                            {
                                this.props.showAll ?
                                    <div className='close-show-all' onClick={() => {
                                        if (this.props.showAll && this.props.changeShowAll) {
                                            this.props.changeShowAll(false)
                                        }
                                    }}>&times;</div>
                                    :
                                    null
                            }
                            <Select
                                defaultValue={typeof window != 'undefined' && window.lang == 'en' ? defaultValue[0] : defaultValue[1]}

                                // options={this.props.list && this.props.list.filter(el => !this.state.inputValue || (this.state.inputValue && el.name && el.name.toLowerCase().indexOf(this.state.inputValue.toLowerCase()) != -1)).map((item, idx) => {
                                //     let obj = {
                                //         label: item.name,
                                //         value: item.value,
                                //         image: item.logo,
                                //         address: item.landingConfig && item.landingConfig.address,
                                //         link: item.link,
                                //         history: this.props.history
                                //     }

                                //     return (
                                //         obj
                                //     )
                                // })}
                                // menuIsOpen={this.props.menuIsOpen || this.props.showAll ? true : this.state.inputValue ? undefined : false}
                                // components={{ NoOptionsMessage, Option: CustomOption, DropdownIndicator, IndicatorSeparator: () => null }}
                                options={groupedOptions}

                                // menuIsOpen={this.props.menuIsOpen || this.props.showAll ? true : this.state.inputValue ? undefined : false}
                                menuIsOpen={this.props.showAll ? true : undefined}

                                // mapHeight={this.props.mapHeight}
                                components={{ NoOptionsMessage, Group, DropdownIndicator, IndicatorSeparator: () => null }}
                                // isClearable={true}
                                styles={colourStyles}
                                className="select-with-search"
                                onChange={(e) => this.props.onChange(e)}
                                mapHeight={'100vh'}
                                maxOptions={2}
                                // placeholder={this.props.showAll ? 'Search'.translate(this.props.lang) : ''}
                                value={
                                    this.props.value ? {
                                        label: this.props.value.name,
                                        value: this.props.value.value
                                    }
                                        :
                                        typeof window != 'undefined' && window.lang == 'en' ? defaultValue[0] : defaultValue[1]
                                }
                                inputValue={this.state.inputValue}
                                onInputChange={(e) => {
                                    this.setState({ inputValue: e })
                                    // if (this.props.showAll && this.props.changeShowAll) {
                                    //     this.props.changeShowAll(false)
                                    // }
                                }}
                            />
                        </div>

                }

            </div>
        )

    }
}

export default SelectWithSearch;