import React, { Component } from 'react';
import Isvg from "react-inlinesvg";
import SoundMeter from '../helpers/soundMeter';
import micIcon from '../assets/svg/mic.svg';
import cameraIcon from '../assets/svg/camera.svg';
import speakerIcon from '../assets/svg/speaker.svg';
import micMutedIcon from '../assets/svg/mic_muted.svg';
import cameraOffIcon from '../assets/svg/camera_off.svg';
import notificationSound from '../assets/sound/notification.wav';
import soundTest from '../assets/svg/test_sound.svg';
import screenShareIcon from '../assets/svg/screen_share.svg';
import screenShareOffIcon from '../assets/svg/screen_share_off.svg';

import {
  Row,
  Col,
  Button, Input, FormGroup, Label
} from 'reactstrap';

function detectMob() {
  const toMatch = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPad/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i
  ];

  return toMatch.some((toMatchItem) => {
    return navigator.userAgent.match(toMatchItem);
  });
}


class WebRTCTest extends Component {
  constructor(props) {
    super(props);

    this.tmpStreams = [];

    this.state = {
      audioInput: [],
      audioOutput: [],
      videoInput: [],
      shareScreenSource: false,
      videoResolution: '480',
      fullHDSupport: false,
      notAllowedError: true,
    };

  }

  checkPermissions = () => {
    if (typeof window !== 'undefined') {
      navigator?.mediaDevices?.getUserMedia({ video: true, audio: true }).then(stream => {
        this.setState({ notAllowedError: false });
        if (stream) {
          stream.getTracks().forEach(track => track.stop());
          if (this.getDevicesInterval) {
            clearInterval(this.getDevicesInterval);
          }
        }

        navigator?.mediaDevices?.getUserMedia({ video: { width: { exact: 1920 }, height: { exact: 1080 } } }).then(stream => {
          if (stream) {
            stream.getTracks().forEach(track => track.stop());
          }
          this.setState({
            fullHDSupport: true
          })
          this.getDevices();
          this.getStream(true);
        }).catch(err => {
          this.setState({
            fullHDSupport: false
          })
          this.getDevices();
          this.getStream(true);
        });




      }).catch(err => {
        // if (err?.name === 'NotAllowedError') {
        this.setState({ notAllowedError: true });
        // }
      });

    }
  }


  attachSinkId = (deviceId) => {

    if (typeof this.previewVideo.sinkId !== 'undefined') {

      this.previewVideo.setSinkId(deviceId).then(() => {
        console.log(`Success, audio output device attached: ${deviceId}`);
        this.setState({ audioOutputDevice: deviceId })

      })
        .catch(error => {
          let errorMessage = error;
          if (error.name === 'SecurityError') {
            errorMessage = `You need to use HTTPS for selecting audio output device: ${error}`;
          }
          console.error(errorMessage);
          // Jump back to first output device in the list as it's the default.
          //this.setState({ selectedAudioOutputIndex: 0 })
        });

    } else {
      console.warn('Browser does not support output device selection.');
    }


    if (typeof this.audioRef.sinkId !== 'undefined') {

      this.audioRef.setSinkId(deviceId).then(() => {
        console.log(`Success, audio test device attached: ${deviceId}`);
        // this.setState({ audioOutputDevice: deviceId })

      })
        .catch(error => {
          let errorMessage = error;
          if (error.name === 'SecurityError') {
            errorMessage = `You need to use HTTPS for selecting audio output device: ${error}`;
          }
          console.error(errorMessage);
          // Jump back to first output device in the list as it's the default.
          //this.setState({ selectedAudioOutputIndex: 0 })
        });

    } else {
      console.warn('Browser does not support output device selection.');
    }



  }


  getStream = (reload = false) => {
    try {

      window.AudioContext = window.AudioContext || window.webkitAudioContext;
      this.audioContext = new AudioContext();
    }
    catch (e) {
      alert('Web Audio API not supported.');
    }

    return new Promise((resolve, reject) => {
      if (this.audioVideoStream && !reload) {
        resolve(this.audioVideoStream);
      } else {
        if (this.audioVideoStream) {


          if (this.previewVideo) {
            this.previewVideo.srcObject = null;
          }

          //this.audioVideoStream.getTracks().forEach(track => track.stop());
          if (this.soundMeter)
            this.soundMeter.stop();
          if (this.meterRefresh) {
            clearInterval(this.meterRefresh);
          }

          this.audioVideoStream.getAudioTracks()[0].stop();
          this.audioVideoStream.getVideoTracks()[0].stop();


          for (let i = 0; i < this.tmpStreams.length; i++) {
            try {
              if (this.tmpStreams[i]) {
                this.tmpStreams[i].getTracks().forEach(track => track.stop());
                this.tmpStreams[i] = null;
              }
            } catch (e) { }
          }

        }

        navigator?.mediaDevices?.getUserMedia?.(
          { video: this.state.videoInputDevice ? { deviceId: this.state.videoInputDevice } : true, audio: this.state.audioInputDevice ? { deviceId: this.state.audioInputDevice } : true }).then(
            stream => {

              this.tmpStreams.push(stream);

              if (this.previewVideo) {
                this.previewVideo.srcObject = stream;
                this.previewVideo.autoplay = true;
                this.previewVideo.playsInline = true;
                this.previewVideo.muted = true;

              }

              this.audioVideoStream = stream;


              this.soundMeter = new SoundMeter(this.audioContext);
              this.soundMeter.connectToSource(stream, (e) => {
                if (e) {
                  alert(e);
                  return;
                }
                this.meterRefresh = setInterval(() => {
                  this.setState({
                    micMeterValue: this.soundMeter.instant.toFixed(2)
                  })
                }, 200);
              });
              resolve(stream);
            }

          ).catch((err) => console.log(err));
      }




    })
  }

  getDevices = () => {
    if (navigator) {
      navigator.mediaDevices.enumerateDevices().then((devices) => {
        console.log('get devices', devices);

        let videoInput = [];
        let audioInput = [];
        let audioOutput = [];
        let audioOutputFilter = []
        let audioInputFilter = []

        let check = false;
        for (let i = 0; i < devices.length; i++) {
          console.log(devices[i]);
          if (devices[i].kind == "audioinput") {
            if (devices[i].deviceId !== 'default' && devices[i].deviceId !== 'communications') {
              audioInputFilter.push(devices[i])
            } else {
              audioInput.push(devices[i]);
            }
            // audioInput.push(devices[i]);
          } else if (devices[i].kind == "audiooutput") {
            if (devices[i].deviceId !== 'default' && devices[i].deviceId !== 'communications') {
              audioOutputFilter.push(devices[i])
            } else {
              audioOutput.push(devices[i]);
            }
            // audioOutput.push(devices[i]);
          } else if (devices[i].kind == "videoinput") {
            videoInput.push(devices[i]);
          }

          if (devices[i].label && devices[i].deviceId) {
            check = true;
          }
        }



        this.setState({
          videoInput,
          audioOutput,
          audioInput,
          audioOutputFilter,
          audioInputFilter
        })

        if (devices.length && this.getDevicesInterval && check) {
          clearInterval(this.getDevicesInterval);
        }

      })
    }
  }

  componentDidMount() {
    // this.getStream();
    this.getDevices();

    this.getDevicesInterval = setInterval(() => {
      this.getDevices();
      //this.checkPermissions()
    }, 1000);
    this.checkPermissions()

  }


  toggleCamera = () => {
    this.setState({
      cameraOff: !this.state.cameraOff
    }, async () => {


      if (this.audioVideoStream) {
        this.audioVideoStream.getVideoTracks().forEach(track => track.enabled = !this.state.cameraOff);
      }
    })
  }

  toggleMic = () => {
    this.setState({
      micOff: !this.state.micOff
    }, async () => {

      if (this.audioVideoStream) {
        this.audioVideoStream.getAudioTracks().forEach(track => track.enabled = !this.state.micOff);
      }
    })
  }


  componentWillUnmount() {
    if (this.audioVideoStream) {
      //this.audioVideoStream.getTracks().forEach(track => track.stop());


      if (this.soundMeter)
        this.soundMeter.stop();
      if (this.meterRefresh) {
        clearInterval(this.meterRefresh);
      }

      if (this.previewVideo) {
        this.previewVideo.srcObject = null;
      }

      this.audioVideoStream.getAudioTracks()[0].stop();
      this.audioVideoStream.getVideoTracks()[0].stop();

      for (let i = 0; i < this.tmpStreams.length; i++) {
        try {
          if (this.tmpStreams[i]) {
            this.tmpStreams[i].getTracks().forEach(track => track.stop());
            this.tmpStreams[i] = null;
          }
        } catch (e) { }
      }

    }
  }


  render() {
    const isPatient = this.props.isPatient;

    const showSettings = !this.props.isPatient || this.state.showSettings;

    return (
      <div className="web-rtc-test">
        <div>
          <Row>
            {
              showSettings ?
                <Col lg="8">
                  {this.state.videoInput && this.state.videoInput.length ?
                    <FormGroup>
                      <Label>{'Video source'.translate(this.props.lang)}</Label>
                      <div>
                        <Input type="select" value={this.state.videoInputDevice} onChange={(e) => this.setState({ videoInputDevice: e.target.value }, () => this.getStream(true))}>
                          {
                            this.state.videoInput ?
                              this.state.videoInput.map((item) => {
                                return <option value={item.deviceId}>{item.label}</option>
                              })
                              :

                              null
                          }
                        </Input>
                        <Isvg src={cameraIcon} />
                      </div>
                    </FormGroup>
                    :
                    null
                  }
                  {this.state.videoInput && this.state.videoInput.length ?
                    <FormGroup>
                      <Label>{'Video resolution (maximum)'.translate(this.props.lang)}</Label>
                      <div>
                        <Input type="select" value={this.state.videoResolution} onChange={(e) => this.setState({ videoResolution: e.target.value }, () => this.getStream(true))}>
                          <option value={'480'}>{'Standard definition'.translate(this.props.lang)}</option>
                          <option value={'720'}>{'High definition'.translate(this.props.lang)}</option>
                          {/* <option disabled={this.props.participantsInVideoCall >= 10 || !this.state.fullHDSupport} value={'1080'}>1080p</option> */}
                        </Input>
                        <Isvg src={cameraIcon} />
                      </div>
                    </FormGroup>
                    :
                    null
                  }

                  {this.state.audioInput && this.state.audioInput.length ?

                    <FormGroup>
                      <Label>{'Audio source'.translate(this.props.lang)}</Label>
                      <div>
                        <Input type="select" value={this.state.audioInputDevice} onChange={(e) => this.setState({ audioInputDevice: e.target.value }, () => this.getStream(true))}>
                          {
                            this.state.audioInputFilter && this.state.audioInputFilter.length > 0 ?
                              this.state.audioInputFilter.map((item, idx) => {
                                return <option value={item.deviceId}>{item.label}</option>
                              })
                              :
                              this.state.audioInput ?
                                this.state.audioInput.map((item) => {
                                  return <option value={item.deviceId}>{item.label}</option>
                                })
                                :

                                null
                          }
                        </Input>
                        <Isvg src={micIcon} />

                      </div>
                    </FormGroup>
                    :
                    null
                  }
                  {this.state.audioOutput && this.state.audioOutput.length ?

                    <FormGroup>
                      <Label>{'Audio output'.translate(this.props.lang)}</Label>
                      <div>
                        <Input type="select" onChange={(e) => this.attachSinkId(e.target.value)} value={this.state.audioOutputDevice}>
                          {
                            this.state.audioOutputFilter && this.state.audioOutputFilter.length > 0 ?
                              this.state.audioOutputFilter.map((item, idx) => {
                                return <option value={item.deviceId}>{item.label}</option>
                              })
                              :
                              this.state.audioOutput ?
                                this.state.audioOutput.map((item, idx) => {
                                  return <option value={item.deviceId}>{item.label}</option>
                                })
                                :

                                null
                          }
                        </Input>
                        <Isvg src={speakerIcon} />

                      </div>
                    </FormGroup>
                    :
                    null
                  }

                </Col>
                :
                null
            }
            {
              <div>
                <audio ref={(node) => this.audioRef = node} >
                  <source src={notificationSound} type="audio/ogg"></source>
                  <source src={notificationSound} type="audio/mpeg"></source>
                </audio>
              </div>
            }


            <Col lg={!showSettings ? "12" : "4"} className="preview-content">
              <video ref={(node) => this.previewVideo = node} autoPlay playsInline />
              <div className="audio-meter-wrap">
                <Isvg src={micIcon} />

                <div className="audio-meter"><div style={{ width: (this.state.micMeterValue * 100) + '%' }}></div></div>

              </div>
              <div className={isPatient ? 'control-buttons test-sound-button-patient' : 'control-buttons test-sound-button'}>
                {
                  <button onClick={() => {
                    this.audioRef.play()
                  }} className={"camera-button off"}><Isvg src={soundTest} />   {'Test sound'.translate(this.props.lang)}</button>

                }
              </div>
              {
                this.state.showSettings ?
                  <div className="control-buttons">
                    {typeof window !== 'undefined' && !detectMob() ?
                      <button onClick={() => {
                        this.setState({ shareScreenSource: !this.state.shareScreenSource })
                      }} className={!this.state.shareScreenSource ? "camera-button off" : "camera-button"}>{this.state.shareScreenSource ? <Isvg src={screenShareIcon} /> : <Isvg src={screenShareOffIcon} />}  <div className="chat-icons-tooltip-off">{'Turn off share screen'.translate(this.props.lang)}</div> <div className="chat-icons-tooltip-on">{'Turn on share screen'.translate(this.props.lang)}</div> </button> : null}

                    <button onClick={this.toggleCamera} className={this.state.cameraOff ? "camera-button off" : "camera-button"}>{this.state.cameraOff ? <Isvg src={cameraOffIcon} /> : <Isvg src={cameraIcon} />}  <div className="chat-icons-tooltip-off">{'Turn off camera'.translate(this.props.lang)}</div> <div className="chat-icons-tooltip-on">{'Turn on camera'.translate(this.props.lang)}</div> </button>
                    <button onClick={this.toggleMic} className={this.state.micOff ? "mic-button off" : "mic-button on"}>{this.state.micOff ? <Isvg src={micMutedIcon} /> : <Isvg src={micIcon} />} <div className="chat-icons-tooltip-off">{'Turn off microphone'.translate(this.props.lang)}</div> <div className="chat-icons-tooltip-on">{'Turn on microphone'.translate(this.props.lang)}</div></button>
                  </div>
                  :
                  null
              }

            </Col>
            <Col lg="12" className='join-video-button-container'>
              {this.state.notAllowedError ? <div>
                {'You have not authorized this website to access you microphone and/or camera! Please reset you permissions to allow access for microphone and camera to this website, by going to settings or clicking on lock icon above, and then clicking on "Reset Permissions" button, and then try again.'.translate(this.props.lang)}
              </div> : null}

              {
                isPatient ?
                  <div className='web-rtc-btns-wrap'>
                    <Button outline={true} color="primary" className='video-settings-btn' onClick={() => { this.setState({ showSettings: !this.state.showSettings }) }} >
                      {
                        this.state.showSettings ?
                          'Hide video call settings'.translate(this.props.lang)
                          :
                          'Show video call settings'.translate(this.props.lang)
                      }
                    </Button>
                    <Button color="primary" onClick={() => {
                      if (this.audioVideoStream) {
                        //this.audioVideoStream.getTracks().forEach(track => track.stop());


                        if (this.soundMeter)
                          this.soundMeter.stop();
                        if (this.meterRefresh) {
                          clearInterval(this.meterRefresh);
                        }

                        if (this.previewVideo) {
                          this.previewVideo.srcObject = null;
                        }


                        this.audioVideoStream.getAudioTracks()[0].stop();
                        this.audioVideoStream.getVideoTracks()[0].stop();

                        for (let i = 0; i < this.tmpStreams.length; i++) {
                          try {
                            if (this.tmpStreams[i]) {
                              this.tmpStreams[i].getTracks().forEach(track => track.stop());
                              this.tmpStreams[i] = null;
                            }
                          } catch (e) { }
                        }

                      }

                      this.props.callback(this.state.videoInputDevice, this.state.audioInputDevice, this.state.audioOutputDevice, this.state.cameraOff, this.state.micOff, this.state.shareScreenSource, this.state.videoResolution, this.state.fullHDSupport)

                      if (this.props.registerLog) {
                        this.props.registerLog(`Join call button clicked`)
                      }
                    }
                    } disabled={this.state.notAllowedError}>{'Join'.translate(this.props.lang)}</Button>
                  </div>

                  :
                  <Button color="primary" onClick={() => {
                    if (this.audioVideoStream) {
                      //this.audioVideoStream.getTracks().forEach(track => track.stop());


                      if (this.soundMeter)
                        this.soundMeter.stop();
                      if (this.meterRefresh) {
                        clearInterval(this.meterRefresh);
                      }

                      if (this.previewVideo) {
                        this.previewVideo.srcObject = null;
                      }


                      this.audioVideoStream.getAudioTracks()[0].stop();
                      this.audioVideoStream.getVideoTracks()[0].stop();

                      for (let i = 0; i < this.tmpStreams.length; i++) {
                        try {
                          if (this.tmpStreams[i]) {
                            this.tmpStreams[i].getTracks().forEach(track => track.stop());
                            this.tmpStreams[i] = null;
                          }
                        } catch (e) { }
                      }

                    }

                    this.props.callback(this.state.videoInputDevice, this.state.audioInputDevice, this.state.audioOutputDevice, this.state.cameraOff, this.state.micOff, this.state.shareScreenSource, this.state.videoResolution, this.state.fullHDSupport)
                  }
                  } disabled={this.state.notAllowedError}>{'Join'.translate(this.props.lang)}</Button>
              }

            </Col>
          </Row>

        </div>

      </div>

    )
  }
}

export default WebRTCTest
