
import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import Isvg from 'react-inlinesvg';

import {
    Container,
    Row,
    Col,
    UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem,
    Modal, ModalHeader, ModalBody, ModalFooter, Button

} from 'reactstrap';

import moment from 'moment';
import logo from '../assets/svg/logo.svg';
import { API_ENDPOINT } from '../constants';

function parseJSON(response) {
    return response.json().then(json => {
        return {
            result: json,
            status: response.status
        }
    })
}

export class ImportantInformation extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props.initialData,
        };
    }
    previewedImporatantInfo = () => {

        fetch(API_ENDPOINT + '/data-managment/important-information/previewed/information', {
            method: 'POST',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
            body: JSON.stringify({
                importantInformation: this.state.showInfo?._id,
            })
        }).then(parseJSON).then(({ result, status }) => {
            if (result.error) {
                this.setState({ error: result.error })
            }
            this.setState({ showInfo: null }, () => this.props.verifyUser())

        })

    }

    render() {
        return (
            <div className="important-information-wrap">
                {
                    this.props.uData && this.props.uData.importantInformation && this.props.uData.importantInformation.map((item, idx) => {
                        if (!item.switchFor || (item.switchFor && this.props.uData.userLevel == 20 && typeof window != 'undefined' && localStorage?.getItem?.('profile') != 'patient'))
                            return (
                                <div className="important-information-item">
                                    <h6>{'Important information'.translate(this.props.lang)}: <span>{item.title && item.title[this.props.lang]}</span></h6>
                                    <div className="show-more" onClick={() => this.setState({ showInfo: item })}>
                                        {'Show more'.translate(this.props.lang)}
                                    </div>
                                </div>
                            )
                    })
                }
                {
                    this.state.showInfo ?
                        <Modal isOpen={this.state.showInfo} centered zIndex={99999999999}>
                            <ModalHeader>
                                {this.state.showInfo.title && this.state.showInfo.title[this.props.lang]}
                            </ModalHeader>
                            <ModalBody>
                                {

                                   this.state.showInfo.content && (this.state.showInfo.content.en || this.state.showInfo.content.se) && this.props.lang ?
                                        <div dangerouslySetInnerHTML={{ __html: this.props.lang == "en" ? this.state.showInfo.content.en : this.state.showInfo.content.se }} onChange={() => { }}></div>
                                        :
                                        null
                                }
                            </ModalBody>
                            <ModalFooter style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                                <Button color='primary' onClick={() => this.previewedImporatantInfo()}>{'Ok'.translate(this.props.lang)}</Button>
                            </ModalFooter>
                        </Modal>
                        :
                        null
                }
            </div>
        )
    }
}

export default ImportantInformation;