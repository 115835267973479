import React, { Component } from 'react';
import Page from '../containers/page';

import { Link } from 'react-router-dom';

import {
    Container,
    Row,
    Col,
    Button,
    FormGroup,
    Label,
    Input,
    Modal, ModalHeader, ModalBody, ModalFooter, Form
} from 'reactstrap';
import Checkbox from './forms/fields/checkbox';
import File from './forms/fields/multipleFiles';



import { API_ENDPOINT } from '../../constants';

function parseJSON(response) {
    return response.json().then(json => {
        return {
            result: json,
            status: response.status
        }
    })
}

import AnswerFormComponent from './answerFormComponent';



class PreviewAnsweredForm extends Component {
    constructor(props) {
        super(props);
        this.searchResultsWraps = {};

        this.state = {
            ...props.initialData,
            answers: {},
            items: [],
            nextItems: [],
            sections: [],
            totalSubtotal: [],
            audioStarted: false,
        };
    }

    componentDidMount() {
        let setObj = {}
        let referral = {

        };

        if (this.props.referral) {
            referral = this.props.referral;
        }

        if (this.props.sections) {
            referral.sections = this.props.sections;
        }
        if (this.props.answers) {
            setObj.answers = this.props.answers;
        }
        setObj.disabledForm = true;

        setObj.referral = referral;

        this.setState(setObj)


        if (this.props.alias) {
            this.getGroup(this.props.alias);
        } else if (this.props.referral?.group) {
            this.getGroupById(this.props.referral.group);
        }

    }
    getGroup = (alias) => {
        fetch(API_ENDPOINT + '/landing/page', {
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify({ alias })
        }).then(res => res.json()).then((result) => {
            if (result && result._id) {
                this.setState({ clinicGroup: result })
            }
        })
    }
    getGroupById = (id) => {
        fetch(API_ENDPOINT + '/landing/page/' + id, {
            method: 'GET',
            headers: {
                'content-type': 'application/json'
            }
        }).then(res => res.json()).then((result) => {
            if (result && result._id) {
                this.setState({ clinicGroup: result })
            }
        })
    }

 






    render() {


        return (
            <div>

                <Container fluid>
                    <Row>
                        {this.state.referral?.sections?.length ?
                            <Col lg="12">
                                <AnswerFormComponent
                                    {...this.props}
                                    referral={this.state.referral}
                                    inModal={true}
                                    hideSubmitButton={true}
                                    clinicGroup={this.state.clinicGroup}
                                    preview={true}
                                    forPrint={this.props.forPrint}
                                    previewAnsweredForm={true}

                                />
                            </Col>
                            :
                            null
                        }

                    </Row>

                </Container>





            </div >
        );
    }
}

export default PreviewAnsweredForm;