import React, { Component } from 'react';
import Link from '../components/link';

import Isvg from 'react-inlinesvg';
import Page from '../containers/page';

import {
    Container,
    Row,
    Col,
    Button,
} from 'reactstrap';


import FormBuilder from '../components/forms/formBuilder';
import { required, emailAddress } from '../components/forms/validation';
import { API_ENDPOINT } from '../constants';

import { getSearchParams } from '../helpers/linkHelper';

/**
* Forgot password page
* @author   Stefan Pantic
*/
class LoginPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            promotedProjects: [],
            ...props.initialData,
            disable: false,
        };
    }

    componentDidMount() {
        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }


        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match).then((data) => {
                this.setState({
                    ...data
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }
    }

    /**
    * Send mail to user with link of recovery password page
    * @author   Stefan Pantic
    * @Objectparam    {Object} data    form data
    */
    find = (data) => {
        let params = getSearchParams(this.props[0].location.search);
        if (params?.alias) {
            data.alias = params.alias;
        }
        fetch(API_ENDPOINT + '/users/forgotpassword', {
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(res => res.json()).then((result) => {
            if (result.error) {
                this.setState({
                    error: result.error
                })
            } else {
                this.setState({
                    message: result.message,
                    disable: true,
                    error: null
                })
            }
        })

    }


    render() {
        return (
            <div className={this.props.autoHideSidebar ? "dashboard dashboard-auto-hide" : "dashboard"}>

                <Container fluid>
                    <Row>
                        <Col lg={{ size: 6, offset: 1 }}>
                            {this.props.userVerificationInProgress ?
                                <div className="loader-wrap">
                                    <div className="loader" />
                                </div>

                                :
                                <div className="panel">
                                    <h4>{'Enter your email'.translate(this.props.lang)} </h4>
                                    <FormBuilder selectedGroup={this.props.selectedGroup} selectedClinic={this.props.selectedClinic} countryTwoLetter={this.props.countryTwoLetter} country={this.props.country} dateFormat={this.props.dateFormat}
                                        getStringDateTs={this.props.getStringDateTs}
                                        lang={this.props.lang}
                                        onSubmit={(data) => this.find(data)}
                                        onChange={() => this.setState({ error: null })}
                                        disabled={this.state.disable}
                                        buttonText={'Send'.translate(this.props.lang)}
                                        fields={[
                                            {
                                                type: 'row',
                                                children: [

                                                    {
                                                        type: 'col',
                                                        width: {
                                                            lg: 12,
                                                            sm: 12,
                                                            xs: 12
                                                        },
                                                        children: [
                                                            {
                                                                type: 'email',
                                                                name: 'email',
                                                                label: 'Email'.translate(this.props.lang),
                                                                validate: [required('Email is required!'.translate(this.props.lang)), emailAddress('Invalid email address!'.translate(this.props.lang))]
                                                            },
                                                        ]
                                                    },

                                                ]
                                            }
                                        ]}
                                    ></FormBuilder>



                                    {this.state.error ?
                                        <p style={{ color: 'red' }}>{this.state.error.translate(this.props.lang)}</p>
                                        :
                                        <p>{this.state.message ? this.state.message.translate(this.props.lang) : null}</p>
                                    }
                                </div>

                            }
                        </Col>
                    </Row>
                </Container>

            </div>
        );
    }
}

export default Page(LoginPage);