
import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';

import Isvg from 'react-inlinesvg';

import { FormGroup, Label, Input, FormFeedback, FormText, Button, Row, Col } from 'reactstrap';
import Checkbox from './checkbox';
/**
* Custom select list checkbox
* @author   Stefan Pantic
*/
class Text extends Component {
    constructor(props) {
        super(props);

        this.state = {
            filter: null
        };
    }

    render() {

        return (
            <div>
                {this.props.label ? <Label size="sm" className={this.props.error ? "required" : ""}>{this.props.label}{this.props.haveValidation ? <span className="required-label-span">*</span> : null}</Label> : null}

                <div className={'list-checkbox-scroll'}>
                    <FormGroup>

                        <Row className="multiselect-checkboxes">
                            {this.props.values && this.props.values.length ? this.props.values.map((option, oidx) => {
                                if (!this.state.filter || (this.state.filter && option.name.indexOf(this.state.filter) == 0))
                                    return (
                                        <Col lg={"12"}>
                                            <FormGroup>
                                                {
                                                    this.props.checkboxField ?
                                                        <p>{option.name}</p>
                                                        :
                                                        <Checkbox key={oidx} disabled={this.props.disabled || option.disabled} checked={this.props.value && this.props.value.indexOf(option.value) !== -1} onChange={() => {
                                                            let value = this.props.value ? this.props.value : '';
                                                            if (value && typeof value !== 'string') {
                                                                let values = ''
                                                                for (let i = 0; i < this.props.value.length; i++) {
                                                                    if (this.props.values.indexOf(this.props.value) !== -1)
                                                                        values += `${this.props.value[i]},`

                                                                }

                                                                value = values
                                                            }

                                                            let idx = value.indexOf(`${option.value},`);
                                                            if (idx === -1) {
                                                                value += `${option.value},`
                                                            } else {
                                                                value = value.replace(`${option.value},`, '')
                                                            }
                                                            this.props.onChange(value);

                                                        }} type="checkbox" label={option.name} />
                                                }
                                            </FormGroup>
                                        </Col>
                                    )
                            })
                                :
                                null
                            }
                        </Row>
                    </FormGroup>
                </div>
            </div>


        );
    }
}

export default Text;