import React from 'react';
import { jsPDF } from "jspdf";

import {
    Button
} from 'reactstrap';

import Isvg from 'react-inlinesvg';
import printModal from '../assets/svg/print-modal.svg'
import Save from '../assets/svg/save.svg';

/**
* Open print page of browser, generate Save PDF button
* @author   Milan Stanojevic
*/
class PrintHelper extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    componentDidMount() {

        const doc = new jsPDF('p', 'mm', [210, 297]);
        doc.html(`<div style="width:1100px;padding: 100px 50px;">${this.props.html}</div>`, {
            margin: [20, 20, 20, 20],

            html2canvas: {
                scale: 0.1909
            },

            callback: () => {
                this.setState({
                    blob: doc.output('bloburl'),
                    docObj: doc
                })
            },

        })
    }

    render() {
        return (
            <div>


                { this.state.blob && this.state._loaded ?
                    <Button style={{ marginRight: 10 }} onClick={() => {
                        this.iframe.focus();
                        this.iframe.contentWindow.print()
                    }}><Isvg src={printModal} style={{ marginRight: 5 }} />
                        {'Print'.translate(this.props.lang)}
                    </Button>

                    :
                    null
                }
                { this.state.docObj ?
                    <Button style={{ marginRight: 0, backgroundColor: '#3A3F56' }} onClick={() => {
                        this.state.docObj.save('invoice.pdf');
                    }}><Isvg src={Save} style={{ marginRight: 5 }} />
                        {'Save as pdf'.translate(this.props.lang)}
                    </Button>
                    :
                    null
                }

                <iframe width="0" height="0" style={{ border: 0 }} src={this.state.blob} ref={(node) => this.iframe = node} onLoad={() => this.setState({ _loaded: true })}></iframe>

            </div>
        )
    }
}

export default PrintHelper;