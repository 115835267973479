import React, { Component } from 'react';

import {
    Container,
    Row,
    Col,
    FormGroup, Label, Input,
    Button,
    Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';



import { getSearchParams, generateSearchLink, generateSearchLinkMultiple } from '../../../helpers/linkHelper';
import { API_ENDPOINT } from '../../../constants';
import moment from 'moment';

import Isvg from 'react-inlinesvg';
// import Checkbox from './forms/fields/checkbox';

import garabage from '../../../assets/svg/garbage.svg'
import ListBuilder from '../../../components/listBuilder';
import DeleteModal from '../../../components/deleteModal';
import ErrorModal from '../../../components/errorModal';

import xIcon from '../../../assets/svg/x.svg';


function parseJSON(response) {
    return response.json().then(json => {
        return {
            result: json,
            status: response.status
        }
    })
}




const loadDataWithQuery = [
    (fetchFunction, lang, match, query) => {

        let queryFields = {};
        for (var key in query) {
            if (key && key.indexOf('query-') === 0) {
                queryFields[key.replace('query-', '')] = query[key];
            }
        }

        return fetchFunction(API_ENDPOINT + '/data/statements', {
            method: 'POST',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
            body: JSON.stringify({
                page: query.page,
                entries: query.entries,
                filter: query.filter,
                sortType: query.sortType,
                sortField: query.sortField,
                group: query.group,
                clinic: query.clinic,
                patient: query.patient,
                // query: queryFields
            })
        }).then(parseJSON).then(({ result, status }) => {
            if (status >= 200 && status < 300)
                return {
                    page: query.page ? query.page : 0,
                    items: result.items,
                    total: result.total,
                    resultFilter: result.filter
                }
        })


    },

]

/**
* List of patients for selected clinic and selected department
* @author   Milan Stanojevic
*/
class Statements extends Component {
    constructor(props) {
        super(props);

        this.state = {
            regions: [],
            cities: [],
            groups: [],
            subgroups: [],
            ...props.initialData,
            useState: true,
            params: {
                entries: 100,
                page: 0,
            },
            items: [],
            total: 0,
            loading: true,
            suggestions: [],

        };
    }


    get = () => {
        let params = this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0, group: this.props.selectedGroup, clinic: this.props.selectedClinic, patient: this.props.patientId });
        params.group = this.props.selectedGroup;
        params.clinic = this.props.selectedClinic;
        params.patient = this.props.patientId;
        params._ts = new Date().getTime();

        this.setState({
            _ts: params._ts
        }, () => {
            for (let i = 0; i < loadDataWithQuery.length; i++) {
                loadDataWithQuery[i](window.fetch, this.props.lang, this.props[0].match, params).then((data) => {

                    this.setState({
                        ...data,
                        loading: null
                    })
                })
            }

        })


    }

    componentDidMount() {
        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }

        this.get();

        let params = getSearchParams(this.props[0].location.search, { entries: 10, page: 0 });


    }

    componentWillUnmount() {
        this.props.socket.removeListener('statementEvent');
    }



    componentDidUpdate(prevProps, prevState) {
        if (prevProps[0].location.search != this.props[0].location.search) {
            this.setState({
                loading: true
            }, () => {
                this.get();

            })

        }

        if (this.state.useState && prevState._useStateTs != this.state._useStateTs) {
            this.setState({
                loading: true
            }, () => {
                this.get();

            })

        }
        if (prevProps.selectedGroup != this.props.selectedGroup) {
            this.get();
        }

        if (prevProps.selectedClinic != this.props.selectedClinic) {
            this.get();
        }

        if (prevProps.getLogs != this.props.getLogs) {
            this.get();
        }


    }


    updateSort = (field, type) => {
        if (this.state.useState) {
            let state = this.state.params;
            state.sortField = field == 'waitingTime' ? 'waitingMin' : field;
            state.sortType = type;
            this.setState({
                params: state
            })

            this.setState({
                _useStateTs: new Date().getTime()
            })

        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0 }, [{ name: 'sortField', value: field == 'waitingTime' ? 'waitingMin' : field }, { name: 'sortType', value: type }], false));

        }
    }

    updateParams = (name, value, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };

                obj[name] = value;

                this.setState({ params: obj });
            } else {
                let obj = this.state.params;
                obj[name] = value;


                this.setState({ params: obj })
            }

            this.setState({
                _useStateTs: new Date().getTime()
            })
        } else {
            this.props[0].history.push(generateSearchLink(this.props[0].location.search, { entries: 10, page: 0 }, name, value, restart));
        }
    }

    updateMultipleParams = (fields, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj });

            } else {
                let obj = this.state.params;
                for (let i = 0; i < fields.length; i++) {

                    obj[fields[i].name] = fields[i].value;

                }
                this.setState({ params: obj })
            }

            this.setState({
                _useStateTs: new Date().getTime()
            })

        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0 }, fields, restart));
        }
    }




    removeStatement = (statementId) => {
        if (statementId) {
            this.setState({ loadingDelete: true }, () => {
                fetch(API_ENDPOINT + '/data/statements/delete/' + statementId, {
                    method: 'DELETE',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage?.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    }
                }).then(res => res.json()).then((result) => {
                    if (!result?.error) {
                        this.setState({ deleteStatementModal: null, loadingDelete: null }, () => {
                            this.get()
                        })
                        this.props.socket.emit('statementEvent', { patient: this.props.patientId, type: 'delete' })

                    } else {
                        this.setState({ error: result.error })
                    }


                })
            })

        } else {
            this.setState({ deleteStatementModal: null })
        }


    }

    exportAsPdf = (conversationId, statementId) => {

        if (conversationId && statementId) {
            this.setState({ loadingPdf: true }, () => {
                let data = {
                    conversationId: conversationId,
                    statementId: statementId,
                    lang: this.props.lang
                }
                fetch(API_ENDPOINT + '/statement/export-as-pdf', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage?.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify(data)
                }).then(res => res.json()).then((result) => {
                    if (result?.pdf) {
                        setTimeout(() => {
                            let path = result.pdf;
                            let name = result?.pdfName ?? 'statement.pdf';
                            let pdfUrl = API_ENDPOINT + path;
                            var link = document.createElement("a");
                            link.download = name ? name : 'test';
                            link.target = "_blank";

                            // Construct the URI
                            link.href = pdfUrl;
                            document.body.appendChild(link);
                            link.click();
                            this.setState({ loadingPdf: false })
                        }, 1000);
                    } else {
                        this.setState({ loadingPdf: false })
                    }




                })
            })
        }


    }




    render() {
        let params = {};
        if (this.state.useState) {
            params = this.state.params;
        } else {
            params = getSearchParams(this.props[0].location.search, { entries: 10, page: 0 })
        }
        const previewStatement = this.state.previewStatement;
        return (
            <div className="patient-search-notes-wrap statments-preview-wrap">
                <div className="patient-search-notes" ref={(node) => this.notesRef = node}>
                    {
                        this.state.items?.map((item, idx) => {
                            const canDeleteStatement = item.savedBy === this.props.uData?._id ? true : false;

                            return (
                                <div className="patient-search-notes-item">
                                    <div className='patient-search-notes-item-header'>
                                        <div className='patient-search-notes-item-header-title' onClick={() => {
                                            this.setState({ previewStatement: item })
                                        }}>
                                            <h6>{item?.statement?.title} <span>({item.caseNumber ?? item?.conversationName})</span></h6>

                                            <div className='tooltip-wrap'>
                                                {'Preview statement'.translate(this.props.lang)}
                                            </div>
                                        </div>
                                        {
                                            canDeleteStatement ?
                                                <div className='delete-item' onClick={() => {
                                                    this.setState({ deleteStatementModal: item._id })
                                                }}><Isvg src={garabage} /></div>
                                                :
                                                null
                                        }

                                    </div>
                                    {/* <div dangerouslySetInnerHTML={{ __html: item?.statement?.notes }} /> */}
                                    <div className="patient-search-notes-item-footer">
                                        <p>
                                            {
                                                item.savedByUser ?
                                                    <>
                                                        {'Created by'.translate(this.props.lang)} {item.savedByUser}
                                                    </>
                                                    :
                                                    null
                                            }
                                        </p>
                                        <p>
                                            {
                                                item.timestamp ?
                                                    <>
                                                        {this.props.getStringDateTs(item.timestamp, this.props.dateFormat)} {this.props.getStringDateTs(item.timestamp, 'HH:mm:ss')}
                                                    </>
                                                    :
                                                    null
                                            }
                                        </p>

                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                {
                    previewStatement ?
                        <Modal
                            isOpen={previewStatement}
                            centered
                            size="lg"
                        >
                            <ModalHeader
                                close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ previewStatement: !previewStatement })} ><Isvg src={xIcon} /></button>}
                            >
                                {'Preview statement'.translate(this.props.lang)}
                            </ModalHeader>
                            <ModalBody>
                                <div className='preview-statement-body-wrap'>
                                    <h6>{previewStatement?.statement?.title} <span>({previewStatement.caseNumber ?? previewStatement?.conversationName})</span></h6>
                                    <div dangerouslySetInnerHTML={{ __html: previewStatement?.statement?.notes }} />
                                    <hr />
                                    <div className='preview-info'>
                                        <p>
                                            {
                                                previewStatement.savedByUser ?
                                                    <>
                                                        {'Created by'.translate(this.props.lang)} {previewStatement.savedByUser}
                                                    </>
                                                    :
                                                    null
                                            }
                                        </p>
                                        <p>
                                            {
                                                previewStatement.timestamp ?
                                                    <>
                                                        {this.props.getStringDateTs(previewStatement.timestamp, this.props.dateFormat)} {this.props.getStringDateTs(previewStatement.timestamp, 'HH:mm:ss')}
                                                    </>
                                                    :
                                                    null
                                            }
                                        </p>
                                    </div>

                                </div>
                            </ModalBody>
                            <ModalFooter className='buttons-right-blue'>
                                <Button disabled={this.state.loadingPdf} color="primary" onClick={() => {
                                    this.exportAsPdf(previewStatement?.conversationId, previewStatement?._id)
                                }}>{'Export as PDF'.translate(this.props.lang)}</Button>

                                <Button color="primary" onClick={() => this.setState({ previewStatement: !previewStatement })}>{'Close'.translate(this.props.lang)}</Button>

                            </ModalFooter>

                        </Modal>
                        :
                        null
                }
                {
                    this.state.deleteStatementModal ?
                        <DeleteModal
                            lang={this.props.lang}
                            isOpen={this.state.deleteStatementModal}
                            toggle={() => this.setState({ deleteStatementModal: null })}
                            zIndex={9999}
                            handler={() => {
                                this.removeStatement(this.state.deleteStatementModal)
                                this.setState({ deleteStatementModal: null });
                            }}
                        >
                            {"Do you want to remove statement?".translate(this.props.lang)}

                        </DeleteModal>
                        :
                        null
                }
                {this.state.error ?

                    <ErrorModal lang={this.props.lang}
                        isOpen={this.state.error}
                        toggle={() => this.setState({ error: null })}
                        zIndex={999999}
                    >
                        {this.state.error.translate(this.props.lang)}
                    </ErrorModal>

                    : null}
            </div>
            // <div className="users-filter">

            //     <ListBuilder
            //         lang={this.props.lang}
            //         loading={this.state.loading}
            //         total={this.state.total}
            //         showNumeration={false}
            //         hideFilters={true}
            //         hideEntries={true}
            //         fields={[
            //             {type: 'text', name: 'patientName', label: 'Patient'.translate(this.props.lang), allowSort: false },
            //             {type: 'text', name: 'savedByUser', label: 'Doctor'.translate(this.props.lang), allowSort: false },
            //             {type: 'text', name: 'title', label: 'Title'.translate(this.props.lang), allowSort: false },
            //             {type: 'text', name: 'notes', label: 'Notes'.translate(this.props.lang), allowSort: false },
            //             {type: 'text', name: 'timestamp', label: 'Time'.translate(this.props.lang), allowSort: false },

            //         ]}
            //         items={this.state.items.map(item => {
            //             return {
            //                 ...item,
            //                 patientName: item?.patientsNames?.[0] ?? '',
            //                 title: item?.statement?.title ?? '',
            //                 notes: item?.statement?.notes ? <div dangerouslySetInnerHTML={{ __html: item.statement.notes }}></div> : '',
            //                 timestamp: item.timestamp ? this.props.getStringDateTs(item.timestamp, this.props.dateFormat + ' HH:mm') : null

            //             }
            //         })}

            //         rawItems={this.state.items}
            //         params={params}
            //         sortField={params.sortField}
            //         sortType={params.sortType}
            //         updateSort={this.updateSort}
            //         updateParams={this.updateParams}
            //         updateMultipleParams={this.updateMultipleParams}
            //     ></ListBuilder>







            // </div >
        );


    }
}
export default Statements;