import React, { Component } from 'react';

import {
    Container,
    Row,
    Col,
    FormGroup, Label, Input,
    Button,
    Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';



import ListBuilder from './listBuilder';
import { getSearchParams, generateSearchLink, generateSearchLinkMultiple } from '../helpers/linkHelper';
import { API_ENDPOINT, GOOGLE_API_KEY } from '../constants';
import moment from 'moment';
import eRemissIcon from '../assets/svg/e.svg';

import Isvg from 'react-inlinesvg';
import Checkbox from './forms/fields/checkbox';

import garabage from '../assets/svg/garbage.svg'

import InfoModal from './infoModal';



function parseJSON(response) {
    return response.json().then(json => {
        return {
            result: json,
            status: response.status
        }
    })
}




const loadDataWithQuery = [
    (fetchFunction, lang, match, query) => {

        let queryFields = {};
        for (var key in query) {
            if (key && key.indexOf('query-') === 0) {
                queryFields[key.replace('query-', '')] = query[key];
            }
        }

        if (query.type == 'visit-forms') {
            return fetchFunction(API_ENDPOINT + '/data/personal-questionary', {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify({
                    page: query.page,
                    entries: query.entries,
                    filter: query.filter,
                    sortType: query.sortType,
                    sortField: query.sortField,
                    query: queryFields,
                    group: query.group,
                    clinic: query.clinic,
                    tabIndex: query.tabIndex
                })
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        page: query.page ? query.page : 0,
                        items: result.items,
                        total: result.total,
                        resultFilter: result.filter
                    }
            })
        } else if (query.type == 'revisit-forms') {
            return fetchFunction(API_ENDPOINT + '/data/revisit-questionary', {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify({
                    page: query.page,
                    entries: query.entries,
                    filter: query.filter,
                    sortType: query.sortType,
                    sortField: query.sortField,
                    query: queryFields,
                    group: query.group,
                    clinic: query.clinic,
                })
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        page: query.page ? query.page : 0,
                        items: result.items,
                        total: result.total,
                        resultFilter: result.filter
                    }
            })
        } else if (query.type == 'chat-forms') {
            return fetchFunction(API_ENDPOINT + '/data/chat-form-questionary', {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify({
                    page: query.page,
                    entries: query.entries,
                    filter: query.filter,
                    sortType: query.sortType,
                    sortField: query.sortField,
                    query: queryFields,
                    group: query.group,
                    clinic: query.clinic,
                })
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        page: query.page ? query.page : 0,
                        items: result.items,
                        total: result.total,
                        resultFilter: result.filter
                    }
            })
        } else if (query.type == 'daybook-forms') {
            return fetchFunction(API_ENDPOINT + '/data/daybook-events', {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify({
                    page: query.page,
                    entries: query.entries,
                    filter: query.filter,
                    sortType: query.sortType,
                    sortField: query.sortField,
                    query: queryFields,
                    group: query.group,
                    clinic: query.clinic,
                })
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        page: query.page ? query.page : 0,
                        items: result.items,
                        total: result.total,
                        resultFilter: result.filter
                    }
            })
        }




    },

]

/**
* List of patients for selected clinic and selected department
* @author   Milan Stanojevic
*/
class FormsListComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            regions: [],
            cities: [],
            groups: [],
            subgroups: [],
            ...props.initialData,
            useState: true,
            params: {
                entries: 10,
                page: 0,
            },
            items: [],
            total: 0,
            loading: true,
            suggestions: [],
            activeTab: 1

        };
    }


    get = () => {

        let params = this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0, group: this.props.selectedGroup, clinic: this.props.selectedDepartment, tabIndex: this.state.activeTab, type: this.props.type });
        params.group = this.props.selectedGroup;
        params.clinic = this.props.selectedDepartment;
        params.tabIndex = this.state.activeTab;
        params.type = this.props.type;
        params._ts = new Date().getTime();

        this.setState({
            _ts: params._ts
        }, () => {
            for (let i = 0; i < loadDataWithQuery.length; i++) {
                loadDataWithQuery[i](window.fetch, this.props.lang, this.props[0].match, params).then((data) => {

                    this.setState({
                        ...data,
                        loading: null
                    })
                })
            }

        })


    }

    componentDidMount() {
        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }

        this.get();

        let params = getSearchParams(this.props[0].location.search, { entries: 10, page: 0 });


    }

    componentWillUnmount() {
    }



    componentDidUpdate(prevProps, prevState) {
        if (prevProps[0].location.search != this.props[0].location.search) {
            this.setState({
                loading: true
            }, () => {
                this.get();

            })

        }

        if (this.state.useState && prevState._useStateTs != this.state._useStateTs) {
            this.setState({
                loading: true
            }, () => {
                this.get();

            })

        }
        if (prevProps.selectedGroup != this.props.selectedGroup) {
            this.get();
        }
        if (prevProps.selectedDepartment != this.props.selectedDepartment) {
            this.get();
        }




    }


    updateSort = (field, type) => {
        if (this.state.useState) {
            let state = this.state.params;
            state.sortField = field == 'waitingTime' ? 'waitingMin' : field;
            state.sortType = type;
            this.setState({
                params: state
            })

            this.setState({
                _useStateTs: new Date().getTime()
            })

        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0 }, [{ name: 'sortField', value: field == 'waitingTime' ? 'waitingMin' : field }, { name: 'sortType', value: type }], false));

        }
    }

    updateParams = (name, value, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };

                obj[name] = value;

                this.setState({ params: obj });
            } else {
                let obj = this.state.params;
                obj[name] = value;


                this.setState({ params: obj })
            }

            this.setState({
                _useStateTs: new Date().getTime()
            })
        } else {
            this.props[0].history.push(generateSearchLink(this.props[0].location.search, { entries: 10, page: 0 }, name, value, restart));
        }
    }

    updateMultipleParams = (fields, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj });

            } else {
                let obj = this.state.params;
                for (let i = 0; i < fields.length; i++) {

                    obj[fields[i].name] = fields[i].value;

                }
                this.setState({ params: obj })
            }

            this.setState({
                _useStateTs: new Date().getTime()
            })

        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0 }, fields, restart));
        }
    }



    importForm = (item) => {
        let obj = {
            ...item,
            clinic: this.props.selectedClinic,
            checkNameIfExist: true
        }
        if (obj._id) {
            delete obj._id;
        }
        if (this.props.type == 'visit-forms') {
            fetch(API_ENDPOINT + '/data/personal-questionary/new', {
                method: 'PUT',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify(obj)
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300) {
                    // this.props.closeComponent()
                    this.setState({ successModal: true })
                    if (this.props.get)
                        this.props.get()

                }
            })
        } else if (this.props.type == 'revisit-forms') {
            fetch(API_ENDPOINT + '/data/revisit-questionary/new', {
                method: 'PUT',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify(obj)
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300) {
                    // this.props.closeComponent()
                    this.setState({ successModal: true })
                    if (this.props.get)
                        this.props.get()

                }
            })
        } else if (this.props.type == 'chat-forms') {
            fetch(API_ENDPOINT + '/data/chat-form-questionary/new', {
                method: 'PUT',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify(obj)
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300) {
                    // this.props.closeComponent()
                    this.setState({ successModal: true })
                    if (this.props.get)
                        this.props.get()

                }
            })
        } else if (this.props.type == 'daybook-forms') {
            fetch(API_ENDPOINT + '/data/daybook-events/new', {
                method: 'PUT',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify(obj)
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300) {
                    // this.props.closeComponent()
                    this.setState({ successModal: true })
                    if (this.props.get)
                        this.props.get()

                }
            })
        }



    }





    render() {
        let params = {};
        if (this.state.useState) {
            params = this.state.params;
        } else {
            params = getSearchParams(this.props[0].location.search, { entries: 10, page: 0 })
        }
        return (
            <div className="users-filter" style={{ marginTop: 30 }}>
                {
                    this.props.type == 'visit-forms' ?
                        <div className="tabs-buttons-wrap" style={{ marginBottom: 35 }}>
                            <Button className={this.state.activeTab == 1 ? "tab-button acitve-tab-button" : "tab-button"} onClick={() => this.setState({ activeTab: 1 }, () => this.get(), this.updateParams('page', 0))}>{'Booking by clinic'.translate(this.props.lang)}</Button>
                            <Button className={this.state.activeTab == 2 ? "tab-button acitve-tab-button" : "tab-button"} onClick={() => this.setState({ activeTab: 2 }, () => this.get(), this.updateParams('page', 0))}>{'Web booking'.translate(this.props.lang)}</Button>
                            <Button className={this.state.activeTab == 3 ? "tab-button acitve-tab-button" : "tab-button"} onClick={() => this.setState({ activeTab: 3 }, () => this.get(), this.updateParams('page', 0))}>{'Drop-in'.translate(this.props.lang)}</Button>
                        </div>
                        :
                        null
                }


                <ListBuilder
                    lang={this.props.lang}
                    loading={this.state.loading}
                    total={this.state.total}
                    showNumeration={false}
                    fields={[
                        { type: 'text', name: 'name', label: 'Name'.translate(this.props.lang), allowSort: true },


                    ]}
                    items={this.state.items.map(item => {
                        return {
                            ...item,
                            name: this.props.lang == 'en' ? item.name.replace(' (onlinebesök)', ' (online visit)') : item.name.replace(' (online visit)', ' (onlinebesök)'),
                        }
                    })}
                    actions={
                        [
                            {
                                component: <Button color='primary'>{'Import'.translate(this.props.lang)}</Button>,
                                onClick: (item) => {
                                    this.importForm(item)
                                }
                            },

                        ]
                    }
                    rawItems={this.state.items}
                    params={params}
                    sortField={params.sortField}
                    sortType={params.sortType}
                    updateSort={this.updateSort}
                    updateParams={this.updateParams}
                    updateMultipleParams={this.updateMultipleParams}
                ></ListBuilder>



                {
                    this.state.successModal ?
                        <InfoModal
                            isOpen={this.state.successModal}
                            toggle={() => this.setState({ successModal: !this.state.successModal })}
                            header={'Info'.translate(this.props.lang)}
                            info={'The form was successfully imported'.translate(this.props.lang)}
                            buttons={[
                                <Button color="primary" style={{ margin: 10 }} onClick={() => this.setState({ successModal: false })}>{'Ok'.translate(this.props.lang)}</Button>

                            ]}

                        />
                        :
                        null
                }



            </div >
        );


    }
}
export default FormsListComponent;