import React, { Component } from 'react';

class VideoCallLogger extends Component {
  constructor(props) {
    super(props);


    this.state = {
      processed: {},
      intervals: {}
    };

  }


  componentDidMount() {
    this.interval = setInterval(this.counter, 1000);
  }

  counter = () => {
    let intervals = this.state.intervals;
    let changed = false;
    let processed = this.state.processed;

    let items = this.props.items.filter((item) => !this.state.processed[item.id]);

    for (let i = 0; i < items.length; i++) {

      let key = items[i].id;

      if (intervals[key]) {
        intervals[key]--;
        changed = true;

        if (intervals[key] === 0) {
          if (items[i].tryAgain) {
            items[i].tryAgain();
            processed[key] = true;
          }
        }
      }

      if (intervals[key] === undefined && items[i].tryAgain) {
        intervals[key] = 5;
        changed = true;
      }
    }

    if (changed) {
      this.setState({
        intervals,
        processed
      })
    }
  }


  componentWillUnmount() {
    clearInterval(this.interval);
  }


  render() {


    return (
      <div className="video-call-logger">
        {
          this.props.items.filter((item) => !this.state.processed[item.id]).map((item) => {
            return (
              <div>

                {/* {
                            item.type === 'initWorker' || item.type === 'loadDevice' ? 'Error occured while trying to initialise video call.'.translate(this.props.lang)
                            :
                            item.type === 'createProducerTransport' ? 'Error occured while trying to create producer transport.'.translate(this.props.lang)
                            :
                            item.type === 'connectProducerTransport' ? 'Error occured while trying to connect producer transport.'.translate(this.props.lang)
                            :
                            item.type === 'createConsumerTransport' ? 'Error occured while trying to create consumer transport.'.translate(this.props.lang)
                            :
                            item.type === 'connectConsumerTransport' ? 'Error occured while trying to connect consumer transport.'.translate(this.props.lang)
                            :
                            item.type === 'produce' ? 'Error occured while trying to send stream'.translate(this.props.lang) + ' ' + item.data.kind
                            :
                            item.type === 'consume' ? 'Error occured while trying to receive stream'.translate(this.props.lang) + ' ' + item.data.kind
                            :
                            item.type === 'networkChange' ? 'Network type has been changed'.translate(this.props.lang)
                            :
                            null
                        } */}

                {
                  "Error occured in video call.".translate(this.props.lang)
                }

                {
                  this.state.intervals[item.id] ?
                    <p>
                      {'Reattempting in '.translate(this.props.lang)} {this.state.intervals[item.id] ? this.state.intervals[item.id] : 'n/a'}s
                    </p>
                    :
                    null
                }

              </div>
            )
          })
        }
      </div >

    )
  }
}

export default VideoCallLogger
