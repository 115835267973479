import React, { Component } from 'react';
import Page from '../containers/page';

import { Link } from 'react-router-dom';

import {
    Container,
    Row,
    Col,
    Button,
    FormGroup,
    Label,
    Input,
    Modal, ModalHeader, ModalBody, ModalFooter, Form
} from 'reactstrap';

import HTML from '../../components/forms/fields/html';

import moment from 'moment';
import { API_ENDPOINT } from '../../constants';

import AnswerFormComponent from './answerFormComponent';

import { getReferralData, getReferralDataRevisit } from '../../helpers/getReferralData';
import { generateHtml } from './helper/helperFunctions';


function parseJSON(response) {
    return response.json().then(json => {
        return {
            result: json,
            status: response.status
        }
    })
}


class PreviewAnswerForm extends Component {
    constructor(props) {
        super(props);
        this.searchResultsWraps = {};

        this.state = {
            ...props.initialData,
            answers: {},
            nextItems: [],
            sections: [],
            html: ''

        };
    }

    componentDidMount() {
        console.log(this.props);
        let setObj = {}
        let referral = {};
        if (this.props.referral) {
            referral = this.props.referral;
        }

        if (this.props.sections) {
            referral.sections = this.props.sections;
        }

        if (this.props.answers) {
            setObj.answers = this.props.answers;
        }

        setObj.referral = referral;
        this.setState(setObj)

        this.generateHtmlPreview()

    }
    componentDidUpdate(prevProps, prevState) {

    }


    generateHtmlPreview = () => {
        let answers = this.state.answers;

        if (!this.state.htmlAnswers || (this.state.htmlAnswers && JSON.stringify(answers) !== JSON.stringify(this.state.htmlAnswers))) {
            let html = generateHtml({
                sections: this.state.sections,
                answers: answers,
                question18Finished: this.state.question18Finished ? this.state.question18Finished : [],
                intelligentReferral: this.state.referral?.intelligentReferral
            }, this.props.lang, null, true, true);

            this.setState({ html, htmlAnswers: JSON.parse(JSON.stringify(answers)) })
        }

    }










    render() {
        return (
            <div>
                <Container fluid>
                    <Row>
                        <Col lg="12">
                            <div className="panel referral-section">
                                <Row>

                                    <Col lg={this.props.referralContent ? "6" : "12"} className="questionary-section-scroll scroll-questions col-no-padding">
                                        {
                                            this.state.referral ?
                                                <AnswerFormComponent
                                                    {...this.props}
                                                    referral={this.state.referral}
                                                    inModal={true}
                                                    previewAnswerForm={true}
                                                    getAnswersAndSections={(sections, answers, question18Finished) => {
                                                        this.setState({ sections, answers, question18Finished }, () => {
                                                            this.generateHtmlPreview()
                                                        })
                                                    }}
                                                    submitButtonText={'Finish'.translate(this.props.lang)}
                                                    submitAction={(data, callback) => {
                                                        if (callback) {
                                                            callback()
                                                        }
                                                    }}
                                                />
                                                :
                                                null
                                        }






                                    </Col>
                                    {
                                        this.props.referralContent ?
                                            <Col lg="6">
                                                {/* <HTML value={getReferralData({
                                                    questionarySections: this.state.sections,
                                                    answers: this.state.answers,
                                                    question18Finished: this.state.question18Finished ? this.state.question18Finished : [],
                                                    intelligentReferral: this.state.referral?.intelligentReferral
                                                }, this.props.lang, true)} disabled={true} onChange={() => { }}></HTML> */}

                                                <HTML value={this.state.html} disabled={true} onChange={() => { }}></HTML>

                                            </Col>
                                            :
                                            null
                                    }
                                </Row>
                            </div>


                        </Col>

                    </Row>

                </Container>



                {this.state.wikiModal ?
                    <Modal isOpen={this.state.wikiModal} centered toggle={() => this.setState({ wikiModal: !this.state.wikiModal })} wrapClassName="wiki-hint-modal">
                        <ModalHeader close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ wikiModal: !this.state.wikiModal })}>&times;</button>}>{this.state.wikiModal.name}</ModalHeader>
                        <ModalBody>
                            <div dangerouslySetInnerHTML={{ __html: this.state.wikiModal.content }}></div>
                            <img src={API_ENDPOINT + this.state.wikiModal.image} />
                        </ModalBody>
                    </Modal>
                    :
                    null
                }


            </div>
        );
    }
}

export default PreviewAnswerForm;