
import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import {
    Container,
    Row,
    Col,
    FormGroup, Label
} from 'reactstrap';

import { Editor } from '@tinymce/tinymce-react';
import { API_ENDPOINT } from '../../../constants'
/**
* Component for html field
* @author   Milan Stanojevic
*/
class HtmlImage extends Component {
    constructor(props) {
        super(props);
        this.selectFile = this.selectFile.bind(this);

        this.state = {

        };
    }

    selectFile(e) {
        let input = e.target;
        if (input.files && input.files[0]) {
            var reader = new FileReader();

            reader.onload = (e) => {
                this.props.onChange({
                    type: 'image',
                    value: e.target.result
                })
            }

            reader.readAsDataURL(input.files[0]);
        }
    }

    render() {
        let fontFormats = `Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Montserrat=montserrat, sans-serif; Symbol=symbol; Museo Sans Rounded 500=MuseoSansRounded500, sans-serif;Museo Sans Rounded 700=MuseoSansRounded700, sans-serif; Open Sans=OpenSansRegular, sans-serif; Open Sans SemiBold=OpenSansSemiBold, sans-serif; Poppins=poppins, sans-serif; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats;`;
        if (this.props.selectedGroup && (String(this.props.selectedGroup) === '6332d8bd39a2266ce2e07f15' || String(this.props.selectedGroup) === '62b2fb15339558d6374d19dc')) {
            // console.log(this.props);
            fontFormats = fontFormats + ` Flecha M Regular=FlechaMRegular, serif; Flecha M Regular Italic=FlechaMRegularItalic, serif; Sohne Leicht=SohneLeicht, sans-serif; Sohne Leicht Kursiv=SohneLeichtKursiv, sans-serif; Sohne Halbfett=SohneHalbfett, sans-serif; Sohne Halbfett Kursiv=SohneHalbfettKursiv, sans-serif; Sohne Kraftig=SohneKraftig, sans-serif; Sohne Kraftig Kursiv=SohneKraftigKursiv, sans-serif;`;
        }
        const toolbar = this.props.toolbar;

        let defaultPlagins = this.props.disableImage ? 'link table code print autolink' : 'link table code print image autolink';
        let plugins = this.props.plugins ?? defaultPlagins;
        return (
            <FormGroup>
                {this.props.label ? <Label>{this.props.label}</Label> : null}

                <Editor
                    disabled={this.props.disabled}
                    apiKey="ubxmhz75z3m6zd19co7o8zl3y4g4vsjnygpm76aq9cfj88d8"
                    toolbar={toolbar}
                    // menubar={this.props.disabledMenuBar ? false : true}
                    init={{
                        plugins: plugins,
                        relative_urls: false,
                        remove_script_host: false,
                        default_link_target: '_blank',
                        menubar: this.props.disabledMenuBar ? false : true,
                        height: this.props.height ? this.props.height : 500,
                        content_css: '/content.css',
                        images_upload_url: `${API_ENDPOINT}/upload/image/tinymceUpload`,
                        image_advtab: true,
                        file_picker_types: 'file image media',
                        file_picker_callback: function (callback, value, meta) {
                            // Provide file and text for the link dialog
                            if (meta.filetype == 'file') {
                                callback('mypage.html', { text: 'My text' });
                            }

                            // Provide image and alt text for the image dialog
                            if (meta.filetype == 'image') {
                                callback('myimage.jpg', { alt: 'My alt text' });
                            }

                            // Provide alternative source and posted for the media dialog
                            if (meta.filetype == 'media') {
                                callback('movie.mp4', { source2: 'alt.ogg', poster: 'image.jpg' });
                            }
                        },
                        font_formats: fontFormats
                    }}
                    value={this.props.multilang ? (this.props.value && this.props.value[this.props.lang]) ? this.props.value[this.props.lang] : '' : this.props.value}
                    onEditorChange={(val) => {

                        if (this.props.multilang) {
                            let value = this.props.value;
                            if (!value) {
                                value = {};
                            }
                            value[this.props.lang] = val;

                            this.props.onChange(value);
                        } else {

                            this.props.onChange(val);
                        }
                        this.forceUpdate();


                    }} />

            </FormGroup>




        );
    }
}

export default HtmlImage;