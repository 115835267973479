
import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';

import Isvg from 'react-inlinesvg';

import { FormGroup, Label, Input, FormFeedback, FormText, Button, Row, Col } from 'reactstrap';
import Checkbox from './checkbox';

/**
* Custom select list checkbox
* @author   Stefan Pantic
*/
class Text extends Component {
    constructor(props) {
        super(props);

        this.state = {
        };
    }


    render() {

        return (
            <div>
                {this.props.label ? <Label size="sm" className={this.props.error ? "required" : ""}>{this.props.label}{this.props.haveValidation ? <span className="required-label-span">*</span> : null}</Label> : null}

                <div className={'list-checkbox-scroll'}>
                    <FormGroup>
                        <Row className={this.props.mainButtons ? 'transfer-users-main-buttons' : null}>
                            <Col lg='12'>
                                <Button color='primary' style={{ margin: 5 }} onClick={() => {
                                    if (this.props.values && this.props.values) {
                                        this.props.onChange(this.props.values.map(item => item.value));
                                    }
                                }}>{this.props.buttonText1}</Button>
                                <Button color='primary' style={{ margin: 5 }} onClick={() => {
                                    if (this.props.values && this.props.values) {
                                        this.props.onChange([]);
                                    }
                                }}>{this.props.buttonText2}</Button>
                            </Col>

                        </Row>

                        {
                            this.props.tableShow ?
                                <div className="profession-buttons transfer-table">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th>{this.props.thName}</th>
                                                <th>{this.props.thUsername}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.props.values && this.props.values.length ? this.props.values.map((option, oidx) => {
                                                return (
                                                    <tr>
                                                        <td>
                                                            <Checkbox
                                                            checked={this.props.value.indexOf(option.value) !== -1}
                                                            onChange={
                                                                () => {

                                                                    let value = this.props.value;
                                                                    if (!value) {
                                                                        value = [];
                                                                    }
    
                                                                    let idx = value.indexOf(option.value);
                                                                    if (idx == -1) {
                                                                        value.push(option.value);
                                                                    } else {
                                                                        value.splice(idx, 1);
                                                                    }
    
                                                                    this.props.onChange(value);
                                                                    this.forceUpdate();
                                                                }
                                                            }
                                                            />
                                                            
                                                        </td>
                                                        <td>
                                                            {option.name}
                                                        </td>
                                                        <td>
                                                            
                                                            {option.username}
                                                        </td>

                                                    </tr>
                                                )
                                            })
                                                :
                                                null
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                :
                                <div className="profession-buttons">

                                    {this.props.values && this.props.values.length ? this.props.values.map((option, oidx) => {
                                        return (
                                            <div>
                                                <div>
                                                    <button className={this.props.value && this.props.value.indexOf(option.value) !== -1 ? ' clicked' : ''} onClick={() => {

                                                        let value = this.props.value;
                                                        if (!value) {
                                                            value = [];
                                                        }

                                                        let idx = value.indexOf(option.value);
                                                        if (idx == -1) {
                                                            value.push(option.value);
                                                        } else {
                                                            value.splice(idx, 1);
                                                        }

                                                        this.props.onChange(value);
                                                        this.forceUpdate();
                                                    }
                                                    }  > {option.name}</button>
                                                </div>

                                            </div>
                                        )
                                    })
                                        :
                                        null
                                    }
                                </div>
                        }

                    </FormGroup>
                </div>
            </div>


        );
    }
}

export default Text;