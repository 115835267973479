import React, { Component } from 'react';
import Page from '../containers/page';

import { Link } from 'react-router-dom';

import {
    Container,
    Row,
    Col,
    Button,
    FormGroup,
    Label,
    Input,
    Modal, ModalHeader, ModalBody, ModalFooter, Form
} from 'reactstrap';
import Checkbox from './forms/fields/checkbox';
import Isvg from "react-inlinesvg";
import xIcon from '../assets/svg/x.svg';
import infoIcon from "../assets/svg/link-new-tab.svg";


import { API_ENDPOINT } from '../constants';
import HTML from './forms/fields/html';

import FormBuilder from './forms/modalFormBuilder';
import { formValueSelector, change } from 'redux-form';  // ES6
import { connect } from 'react-redux';

import { required, checkSocialSecurityNumber, dateValidate } from './forms/validation';
import SocialSecurityNumber, { isSocialSecurityNumberValid } from './forms/fields/socialSecurityNumber';

import ErrorModal from './errorModal';



function parseJSON(response) {
    return response.json().then(json => {
        return {
            result: json,
            status: response.status
        }
    })
}



class AddNewChildModal extends Component {
    constructor(props) {
        super(props);
        this.searchResultsWraps = {};

        this.state = {
            ...props.initialData,

        };
    }

    componentDidMount() {
        if (this.props.isOpen) {
            this.setState({ addNewChildModal: {} })
        }
    }
    componentDidUpdate(prevProps, prevState) {

    }

    addNewChild = (data) => {
        fetch(API_ENDPOINT + '/users/users/patient/add-new-child', {
            method: 'POST',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
            body: JSON.stringify({
                name: data.name,
                socialSecurityNumber: data.socialSecurityNumber,
                sex: data.sex,
                parentUser: this.props.uData._id
            })
        }).then(parseJSON).then(({ result, status }) => {
            if (result && result.error) {
                this.setState({
                    error: result.error
                })
            } else {
                this.props.closeModal?.();
                this.setState({
                    check: false
                })
                this.props.getChildren?.()
            }

        })
    }


    render() {
        return (
            <>
                <Modal isOpen={this.state.addNewChildModal} centered>
                    <ModalHeader toggle={() => this.props.closeModal?.()} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.props.closeModal?.()}><Isvg src={xIcon} /></button>}>
                        <div className='new-child'>
                            <div className='new-child-tooltip'>
                                <Isvg src={infoIcon} /> <div className="chat-icons-tooltip">{"You may only apply for care for children 0-17 years for whom you are the guardian.".translate(this.props.lang)}</div>
                            </div>
                            <h4 >{'Patient profile'.translate(this.props.lang)}</h4>
                        </div>

                    </ModalHeader>
                    <ModalBody>
                        <FormGroup>
                            <Label>{'First and last name'.translate(this.props.lang)}</Label>
                            <Input type='text'
                                value={this.state.addNewChildModal && this.state.addNewChildModal.name ? this.state.addNewChildModal.name : ''}
                                onChange={(e) => {
                                    let addNewChildModal = this.state.addNewChildModal;
                                    addNewChildModal.name = e.target.value;
                                    this.setState({
                                        addNewChildModal
                                    }, () => {
                                        this.registerRefresh()
                                    })
                                }}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label>{'Date of birth'.translate(this.props.lang)}</Label>
                            <SocialSecurityNumber
                                hideFindChildren={true}
                                selectedGroup={this.props.selectedGroup}
                                selectedClinic={this.props.selectedClinic}
                                ukDisableCheck={true}
                                registerRefresh={(registerRefresh) => this.registerRefresh = registerRefresh}
                                searchByName={this.state.addNewChildModal && this.state.addNewChildModal.name ? this.state.addNewChildModal.name : '$$_'}
                                lang={this.props.lang} country={this.props.country} placeholder={this.props.country === 'denmark' ? 'DDMMYYNNNNN' : this.props.lang === 'en' ? 'YYYYMMDDNNNN' : 'ÅÅÅÅMMDDNNNN'} haveValidation={true} value={this.state.addNewChildModal && this.state.addNewChildModal.socialSecurityNumber ? this.state.addNewChildModal.socialSecurityNumber : ''} type="text" onChange={(e) => {
                                    let addNewChildModal = this.state.addNewChildModal;
                                    addNewChildModal.socialSecurityNumber = e;
                                    this.setState({
                                        addNewChildModal
                                    })
                                }} />
                        </FormGroup>
                        <FormGroup>
                            <div className='gender-new'>
                                <div className='gender-new-tooltip'>
                                    <Isvg src={infoIcon} /> <div className="chat-icons-tooltip">{"Biological gender is needed for proper treatment.".translate(this.props.lang)}</div>
                                </div>
                                <Label>{'Gender'.translate(this.props.lang)}</Label>
                            </div>
                            <Input type='select' onChange={(e) => {
                                let addNewChildModal = this.state.addNewChildModal;
                                addNewChildModal.sex = e.target.value;
                                this.setState({
                                    addNewChildModal
                                })
                            }} >
                                <option value={-1}>{'Select gender'.translate(this.props.lang)}</option>
                                <option value={'Boy'}>{'Boy'.translate(this.props.lang)}</option>
                                <option value={'Girl'}>{'Girl'.translate(this.props.lang)}</option>
                            </Input>
                        </FormGroup>
                        <FormGroup>
                            <Checkbox checked={this.state.check} onChange={() => {
                                this.setState({
                                    check: !this.state.check
                                })
                            }} label={"Confirm that you are the child's guardian and have the right to seek care for the child.".translate(this.props.lang)}></Checkbox>
                        </FormGroup>



                    </ModalBody>
                    <ModalFooter className='buttons-right-blue'>
                        <Button color="primary" disabled={this.state.check && this.state.addNewChildModal.name && this.state.addNewChildModal.socialSecurityNumber ? false : true} onClick={() => this.addNewChild(this.state.addNewChildModal)}>{'Add'.translate(this.props.lang)}</Button>

                    </ModalFooter>
                </Modal>
                {this.state.error ? (
                    <ErrorModal lang={this.props.lang}
                        isOpen={this.state.error}
                        toggle={() => this.setState({ error: null })}

                    >
                        {this.state.error.translate(this.props.lang)}
                    </ErrorModal>
                ) : null}
            </>

        );
    }
}


export default AddNewChildModal;