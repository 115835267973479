import React, { Component } from 'react';
import Page from '../../../containers/page';

import {
    Container,
    Row,
    Col, Input, Label, Modal, ModalBody, ModalHeader, FormGroup, Button, Table, Collapse, CardBody, Card, FormFeedback
} from 'reactstrap';
import { Link } from 'react-router-dom';

import EventComponent from '../../referral/calendarViews/EventComponentt'
import ErrorModal from '../../../components/errorModal';
import { getSearchParams, generateSearchLink, generateSearchLinkMultiple } from '../../../helpers/linkHelper';
import { API_ENDPOINT } from '../../../constants';

import TimePicker from '../../../components/forms/fields/timepicker';

import Isvg from 'react-inlinesvg'
import PatientFilter from '../../../components/patientFilter';
import warrning from '../../../assets/images/warrning.png';
import xIcon from '../../../assets/svg/x.svg';
import InfoModal from '../../../components/infoModal';
import DeleteModal from '../../../components/deleteModal';

import { Calendar, Day, momentLocalizer } from 'react-big-calendar';
import CustomToolbar from '../../referral/calendarViews/CustomToolbar';
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Tooltip } from '@material-ui/core';
import { withStyles } from "@material-ui/core/styles";

import Checkbox from '../../../components/forms/fields/checkbox';
import DatePicker from '../../../components/forms/fields/datePicker';
import openChat from '../../../assets/svg/open_chat.svg';

import { Player, Controls } from "@lottiefiles/react-lottie-player";
import arrowDown from '../../../assets/svg/arrow-down2.svg'
import arrowLeft from '../../../assets/svg/arrow-left.svg'

import edit from '../../../assets/svg/edit.svg'
import garabage from '../../../assets/svg/garbage.svg'

import { v4 as uuidv4 } from 'uuid';


const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: "rgba(58, 63, 85, 0.9)",
        color: "#fff",
        boxShadow: theme.shadows[1],
        fontSize: 14
    }
}))(Tooltip);



import moment from 'moment';
import { faTintSlash } from '@fortawesome/free-solid-svg-icons';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import striptags from 'striptags';
function parseJSON(response) {
    return response.json().then(json => {
        return {
            result: json,
            status: response.status
        }
    })
}


const localizer = momentLocalizer(moment)

function pad(n, width, z) {
    z = z || '0';
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
}

/**
* Calendar for doctors
* @author   Stefan Pantic, Aleksandar Dabic, Milan Stanojevic
*/
class TestResults extends Component {
    constructor(props) {
        super(props);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.tableRef = {};
        this.player = React.createRef();
        this.player2 = React.createRef();
        this.state = {
            ...props.initialData,
            form: false,
            useState: false,
            params: {
                entries: 10,
                page: 0
            },
            items: [],
            total: 0,
            loading: true,
            step: 5,
            referrals: [],
            view: typeof window != 'undefined' && window.innerWidth < 768 ? 'day' : 'month',
            searchItemNumber: 0,
            day: new Date(),
            doctorsList: [],
            errorAdditionalDoctors: null,
            visitReasons: [],
            addEventDaybook: false,
            startDate: new Date(),
            openedSections: [],
            modified: 'Not modified'.translate(this.props.lang),
            sections: [],
            // currentDateForDay: new Date()


        };



    }
    showPlayer = () => {
        this.setState({
            showPlayer: true,
        });
    };

    hidePlayer = () => {
        this.setState({
            showPlayer: false,
        });
    };
    stopAnimation = () => {
        this.player.current.play();
    };

    get = () => {
        let params = this.state.useState ? { ...this.state.params, patientId: this.props.patientID } : getSearchParams(this.props[0].location.search, { entries: 10, page: 0, 'query': null, patientId: this.props.patientID });

        this.setState({
            _ts: params._ts
        }, () => {
            for (let i = 0; i < loadDataWithQuery.length; i++) {
                loadDataWithQuery[i](window.fetch, this.props.lang, this.props[0].match, params).then((data) => {
                    // if (data && data._clinicSearchTs && this.state._ts == data._clinicSearchTs || (data && !data._clinicSearchTs)) {

                    this.setState({
                        ...data,
                        loading: null
                    })
                    // }
                })
            }

        })
    }

    componentDidMount() {
        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }

        if (this.props.patient && this.props.patient.testResult) {
            if (this.props.patient.testResult.sections) {
                let sections = this.props.patient.testResult.sections;
                this.setState({ sections: JSON.parse(JSON.stringify(sections)), sectionsLastState: JSON.parse(JSON.stringify(sections)) })
            }
            if (this.props.patient.testResult.tsModified) {
                // let modified = `${moment.unix(this.props.patient.testResult.tsModified).format(this.props.dateFormat)} ${moment.unix(this.props.patient.testResult.tsModified).format("HH:mm:ss")}`
                let modified = `${this.props.getStringDateTs(this.props.patient.testResult.tsModified, this.props.dateFormat)/*moment.unix(this.props.patient.testResult.tsModified).format(this.props.dateFormat)*/}`
                this.setState({ modified })
            } else if (this.props.patient.testResult.tsCreated) {
                // let modified = `${moment.unix(this.props.patient.testResult.tsCreated).format(this.props.dateFormat)} ${moment.unix(this.props.patient.testResult.tsCreated).format("HH:mm:ss")}`
                let modified = `${this.props.getStringDateTs(this.props.patient.testResult.tsCreated, this.props.dateFormat)/*moment.unix(this.props.patient.testResult.tsCreated).format(this.props.dateFormat)*/}`
                this.setState({ modified })
            }
        }


        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentDidUpdate(prevProps, prevState) {
        // if (prevState.focusedField && prevState.focusedField != this.state.focusedField) {
        //     this.updateTestResults()
        // }
        let checkSections = [];
        if (this.state.sections && this.state.sections.length) {
            for (let i = 0; i < this.state.sections.length; i++) {
                if (this.state.sections[i].name || (this.state.sections[i].edit && this.state.sections[i].edit.name)) {
                    checkSections.push(this.state.sections[i])
                }
            }
        }
        let sectionsLastState = this.state.sectionsLastState;
        if (!sectionsLastState) {
            sectionsLastState = []
        }

        if (JSON.stringify(sectionsLastState) != JSON.stringify(checkSections)) {
            if (!this.props.notSavedTestResults) {
                this.props.notSaved(true)
            }
        } else {
            if (this.props.notSavedTestResults) {
                this.props.notSaved(false)
            }
        }
        if (prevProps.initialSave != this.props.initialSave && this.props.initialSave) {
            this.updateTestResults();
        }

    }
    componentWillUnmount = () => {
        if (this.fetchWebDocInterval) {
            clearInterval(this.fetchWebDocInterval)
        }
        document.removeEventListener('mousedown', this.handleClickOutside);
    }


    updateSort = (field, type) => {
        if (this.state.useState) {
            let state = this.state.params;
            state.sortField = field;
            state.sortType = type;
            this.setState({
                params: state
            })
        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0 }, [{ name: 'sortField', value: field }, { name: 'sortType', value: type }], false));

        }
    }

    updateParams = (name, value, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };
                obj[name] = value;
                this.setState({ params: obj });
            } else {
                let obj = this.state.params;
                obj[name] = value;
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(generateSearchLink(this.props[0].location.search, { entries: 10, page: 0 }, name, value, restart));
        }
    }
    updateMultipleParams = (fields, restart = false) => {

        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj });

            } else {
                let obj = this.state.params;
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0 }, fields, restart));
        }
    }


    toggle = (idx) => {
        if (idx != this.state.openedSection) {
            this.setState({ openedSection: idx })
        } else {
            this.setState({ openedSection: -1 })
        }


    }
    handleClickOutside = (event) => {
        if (this.tableRef && this.state.focusedField) {
            let exists = false;
            for (var key in this.tableRef) {
                if (this.tableRef[key] && this.tableRef[key].contains && this.tableRef[key].contains(event.target)) {
                    exists = true
                }
            }
            if (!exists) {
                this.setState({
                    focusedField: null
                })
            }
        }

    }
    updateTestResults = () => {
        let checkValidation = true;
        let initialSave;
        if (this.props.initialSave && this.props.initialSave !== true) {
            initialSave = { ...this.props.initialSave, changedTab: null }
        }
        let sections = this.state.sections;
        if (sections && sections.length) {
            for (let i = 0; i < sections.length; i++) {
                let section = sections[i];
                if (section && section.items && section.items.length) {
                    for (let j = 0; j < section.items.length; j++) {
                        if (!section.items[j].analysis || !section.items[j].result) {
                            checkValidation = false;
                        }
                    }
                }
            }
        }
        if (!checkValidation) {
            this.setState({ error: 'All required fields must be filled'.translate(this.props.lang) })
            this.props.saveTestResults(false, () => {
                this.props.abortAction()
                this.props.setStates({ changedTab: null })
            })
        } else {

            let sections = this.state.sections;
            let newSections = [];
            if (sections && sections.length) {
                for (let i = 0; i < sections.length; i++) {
                    if (sections[i].name && sections[i].timestamp) {
                        newSections.push(sections[i])
                    }
                }
            }
            let data = {
                sections: newSections,
                patient: this.props.patient && this.props.patient.socialSecurityNumber,
                group: this.props.selectedGroup,
                clinic: this.props.selectedClinic
            }


            if (data.patient) {
                fetch(API_ENDPOINT + '/users/users/patient/test-results/update', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify(data)
                }).then(res => res.json()).then((result) => {
                    if (result) {
                        this.props.saveTestResults(false, () => {
                            if (initialSave) {
                                this.props.setStates(initialSave)
                            } else {
                                this.props[0].history.push(this.props.changeLinkModal)
                                this.props.abortAction()
                            }


                        })

                    }

                })
            }
        }




    }
    deleteSection = (sectionId) => {
        let data = {
            sectionId: sectionId,
            patient: this.props.patient && this.props.patient.socialSecurityNumber,
            group: this.props.selectedGroup,
            clinic: this.props.selectedClinic
        }
        if (data.patient) {
            fetch(API_ENDPOINT + '/users/users/patient/test-results/delete/section', {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify(data)
            }).then(res => res.json()).then((result) => {
                if (result) {

                    if (result.sections) {
                        this.setState({ sectionsLastState: JSON.parse(JSON.stringify(result.sections)) })
                    }
                    if (result.tsModified) {
                        let modified = `${this.props.getStringDateTs(result.tsModified, this.props.dateFormat)/*moment.unix(result.tsModified).format(this.props.dateFormat)*/}`
                        this.setState({ modified })
                    } else if (result.tsCreated) {
                        let modified = `${this.props.getStringDateTs(result.tsCreated, this.props.dateFormat)/*moment.unix(result.tsCreated).format(this.props.dateFormat)*/}`
                        this.setState({ modified })
                    }
                }

            })

        }
    }
    updateSectionHeader = (sectionId, name, timestamp) => {

        let data = {
            sectionId: sectionId,
            name: name,
            timestamp: timestamp,
            patient: this.props.patient && this.props.patient.socialSecurityNumber,
            group: this.props.selectedGroup,
            clinic: this.props.selectedClinic
        }
        if (data.patient) {
            fetch(API_ENDPOINT + '/users/users/patient/test-results/update/section/header', {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify(data)
            }).then(res => res.json()).then((result) => {
                if (result) {

                    if (result.sections) {
                        this.setState({ sectionsLastState: JSON.parse(JSON.stringify(result.sections)) })
                    }
                    if (result.tsModified) {
                        let modified = `${this.props.getStringDateTs(result.tsModified, this.props.dateFormat)/*moment.unix(result.tsModified).format(this.props.dateFormat)*/}`
                        this.setState({ modified })
                    } else if (result.tsCreated) {
                        let modified = `${this.props.getStringDateTs(result.tsCreated, this.props.dateFormat)/*moment.unix(result.tsCreated).format(this.props.dateFormat)*/}`
                        this.setState({ modified })
                    }
                }

            })

        }
    }

    updateTableTestResults = (section) => {
        let checkValidation = true;
        if (section && section.items && section.items.length) {
            for (let i = 0; i < section.items.length; i++) {
                if (!section.items[i].analysis || !section.items[i].result) {
                    checkValidation = false;
                }
            }
        }
        if (!checkValidation) {
            this.setState({ error: 'All required fields must be filled'.translate(this.props.lang) })
            return;
        }
        let data = {
            section: section,
            patient: this.props.patient && this.props.patient.socialSecurityNumber,
            group: this.props.selectedGroup,
            clinic: this.props.selectedClinic
        }

        if (data.patient) {
            this.setState({ showPlayer: true }, () => {
                this.player.current.play();
                fetch(API_ENDPOINT + '/users/users/patient/test-results/update/section', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify(data)
                }).then(res => res.json()).then((result) => {
                    if (result) {
                        this.setState({
                            showPlayer: false,
                            showPlayer2: true,
                        }, () => {
                            setTimeout(() => {
                                this.setState({ showPlayer2: false });
                            }, 1000);
                        })
                        if (result.sections) {
                            this.setState({ sectionsLastState: JSON.parse(JSON.stringify(result.sections)) })
                        }
                        if (result.tsModified) {
                            let modified = `${this.props.getStringDateTs(result.tsModified, this.props.dateFormat)/*moment.unix(result.tsModified).format(this.props.dateFormat)*/}`
                            this.setState({ modified })
                        } else if (result.tsCreated) {
                            let modified = `${this.props.getStringDateTs(result.tsCreated, this.props.dateFormat)/*moment.unix(result.tsCreated).format(this.props.dateFormat)*/}`
                            this.setState({ modified })
                        }
                    }

                })
            })

        }

    }

    render() {

        function iosDevice() {
            //return true;
            var userAgent = window.navigator.userAgent;
            if (userAgent.match(/iPad/i) || userAgent.match(/iPhone/i)) {
                return true;
            }
            else {
                return false;
            }
        }

        return (

            <div >
                <Container fluid className="test-results-wrap">
                    <Row>
                        <Col lg='12'>
                            <div >
                                <h6 className="component-header daybook-header">
                                    <div>
                                        <span>{this.props.patient && this.props.patient.userData && this.props.patient.userData.name}</span> <br />
                                        <p>{this.props.patient && this.props.patient.socialSecurityNumber}</p>
                                    </div>
                                    <label>{'Test results updated'.translate(this.props.lang)}: {this.state.modified}</label>
                                    <Button color="primary"
                                        style={{ marginRight: 15 }}
                                        disabled={(this.props.patient?.twoFactorAuthentication?.[this.props.selectedGroup]?.createAccount && !this.props.patient?.twoFactorAuthentication?.[this.props.selectedGroup]?.infoIsVerified)}
                                        onClick={() => {
                                            if (this.props.patient?.twoFactorAuthentication?.[this.props.selectedGroup]?.createAccount && !this.props.patient?.twoFactorAuthentication?.[this.props.selectedGroup]?.infoIsVerified) return;

                                            let sections = this.state.sections;
                                            let obj = {
                                                id: uuidv4(),
                                                name: '',
                                                timestamp: Math.floor(new Date().getTime() / 1000),
                                                edit: {
                                                    name: '',
                                                    timestamp: Math.floor(new Date().getTime() / 1000)
                                                },
                                                items: [
                                                    {
                                                        id: uuidv4(),
                                                        analysis: '',
                                                        result: '',
                                                        referentValue: '',
                                                        comment: '',
                                                    }
                                                ]
                                            }

                                            sections.splice(0, 0, obj);
                                            this.setState({ sections })
                                        }}>{"Add test result".translate(this.props.lang)}</Button>
                                </h6>

                                {/* <div className="component-header">
                                    <div className="component-header-info">
                                        <h6>{this.props.patient && this.props.patient.name}</h6>
                                        <p>{this.props.patient && this.props.patient.socialSecurityNumber}</p>
                                    </div>
                                    <div className="update-info">
                                        <h6>{'Test results updated'.translate(this.props.lang)}:</h6>
                                        <p>{this.state.modified}</p>
                                    </div>
                                    <div>
                                        <Button color="primary" onClick={() => {
                                            let sections = this.state.sections;
                                            let obj = {
                                                id: uuidv4(),
                                                name: '',
                                                timestamp: Math.floor(new Date().getTime() / 1000),
                                                edit: {
                                                    name: '',
                                                    timestamp: Math.floor(new Date().getTime() / 1000)
                                                },
                                                items: [
                                                    {
                                                        id: uuidv4(),
                                                        analysis: '',
                                                        result: '',
                                                        referentValue: '',
                                                        comment: '',
                                                    }
                                                ]
                                            }

                                            sections.splice(0, 0, obj);
                                            this.setState({ sections })
                                        }}>{"Add test result".translate(this.props.lang)}</Button>
                                    </div>
                                </div> */}



                            </div>

                        </Col>
                        <Col lg="12">
                            <div className="results-wrap">
                                {
                                    this.state.sections && this.state.sections.map((item, idx) => {
                                        return (
                                            <div className='accordion-section-item-wrap'>
                                                <div className="accordion-section-item" onClick={() => {
                                                    this.toggle(idx)
                                                }} style={{ marginBottom: '1rem' }}>
                                                    <div className="accordion-section-item-block block-1" style={{ width: '30%' }}>
                                                        {
                                                            !item.edit ?
                                                                <h6>{item.name}</h6>
                                                                :
                                                                <div>
                                                                    <Input placeholder={'Type of sampling'.translate(this.props.lang)} type="text"
                                                                        value={item.edit.name}
                                                                        onClick={(e) => {
                                                                            e.stopPropagation()
                                                                        }}
                                                                        onChange={(e) => {
                                                                            let sections = this.state.sections;
                                                                            sections[idx].edit.name = e.target.value;
                                                                            if (sections[idx].requiredName) {
                                                                                delete sections[idx].requiredName;
                                                                            }
                                                                            this.setState({ sections })
                                                                        }} />
                                                                    {
                                                                        item.requiredName ?
                                                                            <FormFeedback>{item.requiredName}</FormFeedback>
                                                                            :
                                                                            null
                                                                    }
                                                                </div>
                                                        }
                                                    </div>
                                                    <div className="accordion-section-item-block block-2" style={{ width: '30%' }}>
                                                        {
                                                            !item.edit ?
                                                                <h6>{this.props.getStringDateTs(item.timestamp, this.props.dateFormat)/*moment.unix(item.timestamp).format(this.props.dateFormat)*/}</h6>
                                                                :
                                                                <DatePicker
                                                                    value={this.props.getStringDateTs(item.edit.timestamp, this.props.dateFormat)/*moment.unix(item.edit.timestamp).format(this.props.dateFormat)*/}
                                                                    onClick={(e) => {
                                                                        e.stopPropagation()
                                                                    }}
                                                                    onChange={(e) => {
                                                                        let sections = this.state.sections;
                                                                        if (e) {
                                                                            sections[idx].edit.timestamp = Math.floor(new Date(e).getTime() / 1000);
                                                                        }
                                                                        this.setState({ sections })
                                                                    }}
                                                                />
                                                        }
                                                    </div>

                                                    <div className="action-buttons">
                                                        {
                                                            !item.edit && !item.sembleId ?
                                                                <div onClick={(e) => {
                                                                    e.stopPropagation()
                                                                    let sections = this.state.sections;
                                                                    sections[idx].edit = {
                                                                        name: sections[idx].name,
                                                                        timestamp: sections[idx].timestamp
                                                                    };
                                                                    this.setState({ sections })
                                                                }} className='test-results-tooltip-wrap' style={{ position: 'relative' }}>
                                                                    <Isvg src={edit} />
                                                                    <div className='test-results-tooltip'>{'Edit'.translate(this.props.lang)}</div>

                                                                </div>
                                                                :
                                                                null
                                                        }

                                                        {
                                                            !item.edit && !item.sembleId ?
                                                                <div className='test-results-tooltip-wrap' style={{ position: 'relative' }} onClick={(e) => {
                                                                    e.stopPropagation()
                                                                    this.setState({ deleteSectionItem: idx + 1 })
                                                                }}>
                                                                    <Isvg src={garabage} />
                                                                    <div className='test-results-tooltip'>{'Delete'.translate(this.props.lang)}</div>
                                                                </div>
                                                                :
                                                                null
                                                        }
                                                        {
                                                            item.edit ?
                                                                <Button color='primary' onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    let sections = this.state.sections;
                                                                    if (!sections[idx].edit.name) {
                                                                        sections[idx].requiredName = 'Required'.translate(this.props.lang)
                                                                        this.setState({ sections })
                                                                    } else {
                                                                        sections[idx].name = sections[idx].edit.name;
                                                                        sections[idx].timestamp = sections[idx].edit.timestamp;
                                                                        if (sections[idx].edit) {
                                                                            delete sections[idx].edit;
                                                                        }
                                                                        this.setState({ sections, openedSection: idx }, () => this.updateSectionHeader(sections[idx].id, sections[idx].name, sections[idx].timestamp))

                                                                    }


                                                                }}>{'Save'.translate(this.props.lang)}</Button>
                                                                :
                                                                null
                                                        }
                                                        <div>
                                                            {
                                                                this.state.openedSection == idx && item.name && item.timestamp && !item.edit ?
                                                                    <Isvg src={arrowDown} />
                                                                    :
                                                                    <Isvg src={arrowLeft} />
                                                            }
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="accordion-card">
                                                    <Collapse isOpen={this.state.openedSection == idx && item.name && item.timestamp && !item.edit ? true : false} className="no-border">
                                                        <Card >
                                                            <CardBody className='accordion-body-wrap test-result-table-container'>
                                                                {/* {item.content} */}
                                                                <table id={item.id} className='accordion-table-wrap'>
                                                                    <tr>
                                                                        <th>{'Analysis'.translate(this.props.lang)}</th>
                                                                        <th>{'Result'.translate(this.props.lang)}</th>
                                                                        <th>{'Referent value'.translate(this.props.lang)}</th>
                                                                        <th>{'Comment'.translate(this.props.lang)}</th>

                                                                    </tr>
                                                                    {
                                                                        item.items && item.items.map((element, eidx) => {
                                                                            return (
                                                                                <tr ref={node => {
                                                                                    if (!this.tableRef) {
                                                                                        this.tableRef = {};
                                                                                    }
                                                                                    if (element.id) {
                                                                                        this.tableRef[element.id] = node;
                                                                                    }

                                                                                }}>
                                                                                    <td onClick={() => {
                                                                                        if (item.sembleId) return;
                                                                                        this.setState({ focusedField: `${element.id}-analysis` }, () => {
                                                                                            if (typeof window != 'undefined') {
                                                                                                document.getElementById(`${element.id}-analysis`).focus()
                                                                                            }
                                                                                        })
                                                                                    }}
                                                                                        className={!element.analysis ? 'required-td' : ''}
                                                                                    >
                                                                                        {
                                                                                            this.state.focusedField == `${element.id}-analysis` ?
                                                                                                <Input id={`${element.id}-analysis`} type='text' className={!element.analysis ? 'required-input' : ''} value={element.analysis} onChange={(e) => {
                                                                                                    let sections = this.state.sections;
                                                                                                    if (sections[idx] && sections[idx].items && sections[idx].items[eidx]) {
                                                                                                        sections[idx].items[eidx].analysis = e.target.value;
                                                                                                        this.setState({ sections })
                                                                                                    }
                                                                                                }} />
                                                                                                :
                                                                                                <span>{element.analysis}</span>
                                                                                        }
                                                                                    </td>
                                                                                    <td onClick={() => {
                                                                                        if (item.sembleId) return;
                                                                                        this.setState({ focusedField: `${element.id}-result` }, () => {
                                                                                            if (typeof window != 'undefined') {
                                                                                                document.getElementById(`${element.id}-result`).focus()
                                                                                            }
                                                                                        })
                                                                                    }}
                                                                                        className={!element.result ? 'required-td' : ''}
                                                                                    >
                                                                                        {
                                                                                            this.state.focusedField == `${element.id}-result` ?
                                                                                                <Input id={`${element.id}-result`} className={!element.result ? 'required-input' : ''} type='text' value={element.result} onChange={(e) => {
                                                                                                    let sections = this.state.sections;
                                                                                                    if (sections[idx] && sections[idx].items && sections[idx].items[eidx]) {
                                                                                                        sections[idx].items[eidx].result = e.target.value;
                                                                                                        this.setState({ sections })
                                                                                                    }
                                                                                                }} />
                                                                                                :
                                                                                                <span >{element.result}</span>
                                                                                        }
                                                                                    </td>
                                                                                    <td onClick={() => {
                                                                                        if (item.sembleId) return;
                                                                                        this.setState({ focusedField: `${element.id}-referentValue` }, () => {
                                                                                            if (typeof window != 'undefined') {
                                                                                                document.getElementById(`${element.id}-referentValue`).focus()
                                                                                            }
                                                                                        })
                                                                                    }}>
                                                                                        {
                                                                                            this.state.focusedField == `${element.id}-referentValue` ?
                                                                                                <Input id={`${element.id}-referentValue`} type='text' value={element.referentValue} onChange={(e) => {
                                                                                                    let sections = this.state.sections;
                                                                                                    if (sections[idx] && sections[idx].items && sections[idx].items[eidx]) {
                                                                                                        sections[idx].items[eidx].referentValue = e.target.value;
                                                                                                        this.setState({ sections })
                                                                                                    }
                                                                                                }} />
                                                                                                :
                                                                                                <span>{element.referentValue}</span>
                                                                                        }
                                                                                    </td>
                                                                                    <td onClick={() => {
                                                                                        if (item.sembleId) return;
                                                                                        this.setState({ focusedField: `${element.id}-comment` }, () => {
                                                                                            if (typeof window != 'undefined') {
                                                                                                document.getElementById(`${element.id}-comment`).focus()
                                                                                            }
                                                                                        })
                                                                                    }}>
                                                                                        {
                                                                                            this.state.focusedField == `${element.id}-comment` ?
                                                                                                <Input id={`${element.id}-comment`} type='text' value={element.comment} onChange={(e) => {
                                                                                                    let sections = this.state.sections;
                                                                                                    if (sections[idx] && sections[idx].items && sections[idx].items[eidx]) {
                                                                                                        sections[idx].items[eidx].comment = e.target.value;
                                                                                                        this.setState({ sections })
                                                                                                    }
                                                                                                }} />
                                                                                                :
                                                                                                <span>{element.comment}</span>
                                                                                        }
                                                                                    </td>

                                                                                    {item.sembleId ? null :
                                                                                        iosDevice() ?
                                                                                            <td className='test-results-delete-row iphone-row' onClick={() => {
                                                                                                let sections = this.state.sections;
                                                                                                if (sections && sections[idx] && sections[idx].items && sections[idx].items[eidx]) {
                                                                                                    sections[idx].items.splice(eidx, 1)
                                                                                                }
                                                                                                this.setState({ sections })
                                                                                            }}>
                                                                                                <Isvg src={garabage} />

                                                                                            </td>
                                                                                            :
                                                                                            <div className='test-results-delete-row test-results-tooltip-wrap' onClick={() => {
                                                                                                let sections = this.state.sections;
                                                                                                if (sections && sections[idx] && sections[idx].items && sections[idx].items[eidx]) {
                                                                                                    sections[idx].items.splice(eidx, 1)
                                                                                                }
                                                                                                this.setState({ sections })
                                                                                            }}>
                                                                                                <Isvg src={garabage} />
                                                                                                <div className='test-results-tooltip'>{'Delete'.translate(this.props.lang)}</div>

                                                                                            </div>
                                                                                    }


                                                                                </tr>


                                                                            )

                                                                        })

                                                                    }
                                                                </table>
                                                                {item.sembleId ? null : <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                                    <Button color='primary' onClick={() => {
                                                                        let sections = this.state.sections;
                                                                        if (sections && sections[idx] && sections[idx].items) {
                                                                            sections[idx].items.push({
                                                                                id: uuidv4(),
                                                                                analysis: '',
                                                                                result: '',
                                                                                referentValue: '',
                                                                                comment: ''
                                                                            })
                                                                            this.setState({ sections })
                                                                        }
                                                                    }}>{'Add new line'.translate(this.props.lang)}</Button>
                                                                    <Button color='primary' onClick={() => {
                                                                        let sections = this.state.sections;
                                                                        if (sections && sections[idx]) {
                                                                            this.updateTableTestResults(sections[idx])
                                                                        }

                                                                    }}>
                                                                        {
                                                                            this.state.showPlayer ? (
                                                                                <Player
                                                                                    onEvent={(event) => {
                                                                                        if (event === "load") this.stopAnimation();
                                                                                    }}
                                                                                    onStateChange={this.toggleVisibility}
                                                                                    ref={this.player} // set the ref to your class instance
                                                                                    autoplay={true}
                                                                                    loop={true}
                                                                                    controls={true}
                                                                                    src="https://assets8.lottiefiles.com/packages/lf20_ZSRKCF.json"
                                                                                    style={{ height: "30px", width: "30px" }}
                                                                                ></Player>

                                                                            ) : this.state.showPlayer2 ? (
                                                                                <div style={{ display: 'flex', alignItems: 'center' }}><Player
                                                                                    onStateChange={this.toggleVisibility}
                                                                                    ref={this.player2} // set the ref to your class instance
                                                                                    autoplay={true}
                                                                                    loop={true}
                                                                                    controls={true}
                                                                                    src="https://assets10.lottiefiles.com/packages/lf20_CxyxLk.json"
                                                                                    style={{ height: "30px", width: "30px" }}
                                                                                ></Player>
                                                                                    {'Saved'.translate(this.props.lang)}
                                                                                </div>
                                                                            ) : (
                                                                                "Save".translate(this.props.lang)
                                                                            )
                                                                        }
                                                                    </Button>

                                                                </div>}
                                                            </CardBody>
                                                        </Card>
                                                    </Collapse>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </Col>
                    </Row>

                </Container>
                <DeleteModal
                    lang={this.props.lang}
                    zIndex={99999999999}
                    isOpen={this.state.deleteSectionItem}
                    toggle={() => this.setState({ deleteSectionItem: null })}
                    handler={() => {
                        let index = this.state.deleteSectionItem - 1;
                        let sections = this.state.sections;
                        let sectionId;
                        if (sections[index]) {
                            sectionId = sections[index].id;
                            sections.splice(index, 1);
                            this.setState({ sections, deleteSectionItem: null }, () => this.deleteSection(sectionId))
                        }

                    }}
                >
                    {'Are you sure you want to delete the test result?'.translate(this.props.lang)}
                </DeleteModal>



                {
                    this.state.error ?

                        <ErrorModal lang={this.props.lang}
                            isOpen={this.state.error}
                            toggle={() => this.setState({ error: null })}

                        >
                            {this.state.error.translate(this.props.lang)}
                        </ErrorModal>

                        :
                        null
                }

            </div >
        );
    }
}

export default TestResults;