import React, { Component } from 'react';
import { Modal, ModalBody, ModalHeader, ModalFooter, Button } from 'reactstrap'
import PrintHelper from './printHelper';
import Isvg from 'react-inlinesvg';
import xIcon from '../assets/svg/x.svg';
import billInfoIcon from '../assets/svg/bill_info.svg';

import { API_ENDPOINT } from '../constants';
import moment from 'moment';
import FormPreview from './formPreview';
import CreateDaybook from './createDaybookModal';

import { generateReferralDaybook, generateScaleDaybook } from '../helpers/getReferralData';
import { generateSections } from '../helpers/generateSections';


class referralContent extends Component {
    constructor(props) {
        super(props);
        this.modalBody = React.createRef();
        this.state = {
            clinic: false,
            activeRevisitForm: 1,
            printReferralContent: false
        };
    }

    componentDidMount() {

        let printInterval = setInterval(() => {
            if (!this.state.printReferralContent) {
                this.initPrint()
            } else {
                clearInterval(printInterval)
            }
        }, 200);
        if (this.props.data && !this.state.clinic && this.props.data.clinic) {
            fetch(API_ENDPOINT + '/data/clinics/' + this.props.data.clinic, {
                method: 'GET',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
            }).then(res => res.json()).then((result) => {
                this.setState({
                    clinic: result
                })
            })
        }
    }

    initPrint = () => {
        if (this.modalBody && this.modalBody.current && !this.state.printReferralContent) {
            this.setState({
                printReferralContent: this.modalBody.current.outerHTML
            })
        }
    }



    render() {
        if (!this.props.data) {
            return null;
        }


        return (
            <div >

                <Modal isOpen={this.props.data && this.state.clinic} centered size={this.props.screen == 'all-cases' ? 'xl' : "lg"}>
                    <ModalHeader toggle={() => this.props.closeReferralContent(true)} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.props.closeReferralContent(true)}><Isvg src={xIcon} /></button>}>{'Preview'.translate(this.props.lang)}</ModalHeader>

                    <ModalBody>


                        <div ref={this.modalBody}>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                {this.state.clinic.logo ? <p><img style={{ maxWidth: 220 }} src={API_ENDPOINT + this.state.clinic.logo} /></p> : ''}
                                <div style={{ marginTop: 20 }}>
                                    <p ><strong>{'Datum för bokning'.translate(this.props.lang) + ': '}</strong> <span >{this.props.getStringDateTs(this.props.data.timestamp, this.props.dateFormat)/*moment.unix(this.props.data.timestamp).format(this.props.dateFormat)*/}</span></p>
                                </div>
                            </div>
                            <div >
                                <div>{this.state.clinic.name ? this.state.clinic.name : ''}</div>
                                <div>{this.state.clinic.street ? this.state.clinic.street : ''}, {this.state.clinic.zip ? this.state.clinic.zip : ''} {this.state.clinic.city ? this.state.clinic.city : ''}</div>
                                <div>{this.state.clinic.phone ? this.state.clinic.phone : ''} {this.state.clinic.email ? this.state.clinic.email : ''}</div>
                                <div>{this.props.data.doctorName ? this.props.data.doctorName : ''}</div>
                            </div>
                            <hr />
                            <div>
                                <p><strong>{'Personuppgifter'.translate(this.props.lang) + ': '}</strong></p>
                            </div>
                            <div>
                                <p>
                                    {this.props.data.patientData?.socialSecurityNumber ? this.props.data.patientData.socialSecurityNumber : ''}<br />
                                    {
                                        this.props.data.patientData?.name ?
                                            this.props.data.patientData.name : null
                                    }


                                    <br />
                                    {this.props.data.patientData?.street ? this.props.data.patientData.street : ''}<br />
                                    {this.props.data.patientData?.zip ? this.props.data.patientData.zip : ''}&nbsp;
                                    {this.props.data.patientData?.city ? this.props.data.patientData.city : ''}<br />
                                    {this.props.data.patientData?.phone ? this.props.data.patientData.phone : ''}<br />
                                    {this.props.data.patientData?.email ? this.props.data.patientData.email : ''}
                                </p>
                            </div>
                            <hr />


                            {
                                this.props.data?.questionaryData?.items && this.props.data?.answers?.answers ?
                                    <>
                                        <div dangerouslySetInnerHTML={{
                                            __html: generateReferralDaybook({
                                                questionarySections: generateSections(this.props.data.questionaryData.items),
                                                answers: this.props.data.answers.answers
                                            }, this.props.lang).protectReferralFiles(this.props.uData ? this.props.uData.mediaToken : '', this.props.data._id)
                                        }}></div>

                                        <div dangerouslySetInnerHTML={{
                                            __html: generateScaleDaybook({
                                                questionarySections: generateSections(this.props.data.questionaryData.items),
                                                answers: this.props.data.answers.answers
                                            }, this.props.lang).protectReferralFiles(this.props.uData ? this.props.uData.mediaToken : '', this.props.data._id)
                                        }}></div>

                                        <hr />
                                    </>
                                    :
                                    null
                            }



                        </div>


                        {
                            this.props.data && this.props.data.answers && this.props.data.questionaryData && this.props.data.questionaryData.items ?
                                <div style={{ marginBottom: 10 }}>
                                    <Button color='primary' onClick={() => { this.setState({ previewAnswersReferralModal: this.props.data }) }}>{'Preview answers'.translate(this.props.lang)}</Button>
                                </div>
                                :
                                null
                        }





                    </ModalBody>
                    <ModalFooter style={{ margin: 'unset', justifyContent: 'flex-end', display: 'flex', width: '100%' }}>
                        {this.props.data?.daybookId && this.props.data?.formId ?
                            <Button disabled={!(this.props.data.daybookId && this.props.data.formId) || this.props.loadingPdf} color="primary" onClick={() => this.props.getCasePdf(this.props.data.daybookId, this.props.data.formId)}> {'Save as pdf'.translate(this.props.lang)} </Button>
                            :
                            null
                        }
                        {/* {
                            this.state.printReferralContent ?
                                <PrintHelper lang={this.props.lang} html={this.state.printReferralContent} />
                                :
                                null
                        } */}
                        <Button color="primary" onClick={() => this.props.closeReferralContent(true)}> {'Close'.translate(this.props.lang)} </Button>

                    </ModalFooter>


                </Modal>

                {
                    this.state.previewAnswersReferralModal ?
                        <Modal isOpen={this.state.previewAnswersReferralModal} centered toggle={() => this.setState({ previewAnswersReferralModal: !this.state.previewAnswersReferralModal })} size="lg">
                            <ModalHeader toggle={() => this.setState({ previewAnswersReferralModal: !this.state.previewAnswersReferralModal })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ previewAnswersReferralModal: !this.state.previewAnswersReferralModal })}><Isvg src={xIcon} /></button>}>{'Form preview'.translate(this.props.lang)}</ModalHeader>
                            <ModalBody>
                                {/* <FormPreview {...this.props} referral={{ name: this.state.previewAnswersReferralModal.questionaryData?.name, description: this.state.previewAnswersReferralModal?.questionaryData.description, intelligentReferral: this.state.previewAnswersReferralModal.questionaryData?.intelligentReferral }} sections={generateSections(this.state.previewAnswersReferralModal.questionaryData.items)} answers={this.state.previewAnswersReferralModal.answers} disabledForm={true} hidePreview={true}></FormPreview> */}
                                <CreateDaybook
                                    lang={this.props.lang}
                                    sections={generateSections(this.props.data.questionaryData.items)}
                                    answers={this.props.data.answers?.answers}
                                    disableAnswers={true}
                                />
                            </ModalBody>
                        </Modal>
                        :
                        null
                }

            </div >
        );


    }
}
export default referralContent;