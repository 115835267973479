import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';

import Isvg from 'react-inlinesvg';

import { FormGroup, Label, Input, FormFeedback, FormText, Button } from 'reactstrap';

import ColorPicker from './colorpicker';

import fontSizeIcon from '../../../assets/svg/font-size.svg'
import italicIcon from '../../../assets/svg/italic.svg'
import boldIcon from '../../../assets/svg/bold.svg'

/**
* Generate style for text inputs
* @author   Milan Stanojevic
*/
class SmsName extends Component {
    constructor(props) {
        super(props);

        this.state = {
        };
    }

    render() {

        let cssStyle = {};
        let value = this.props.value && typeof this.props.value == 'object' ? this.props.value : {};
        // console.log(value);

        if (value.fontSize) {
            cssStyle.fontSize = value.fontSize + 'px';
        }
        if (value.fontWeight) {
            cssStyle.fontWeight = value.fontWeight;
        }
        if (value.fontStyle) {
            cssStyle.fontStyle = value.fontStyle;
        }
        if (value.color) {
            cssStyle.color = value.color;
        }


        return (
            <FormGroup>
                {this.props.label ? <Label check={this.props.type == 'checkbox'} size="sm" className={this.props.error ? "required" : ""}>{this.props.label}{this.props.haveValidation ? <span className="required-label-span">*</span> : null}</Label> : null}
                <div className="text-with-style">
                    <Input style={cssStyle} size="sm" disabled={this.props.disabled} name={this.props.name} invalid={this.props.error ? true : false} onChange={(e) => {
                        let value = this.props.value && typeof this.props.value == 'object' ? this.props.value : {};
                        value.content = e.target.value;
                        this.props.onChange(value)
                        this.forceUpdate();

                    }} value={this.props.value ? this.props.value.content : ''} placeholder={this.props.placeholder}></Input>

                    <Button size="sm" className="italic-button" type="button" color={this.props.value ? this.props.value.fontStyle == 'italic' ? 'info' : '' : ''} onClick={() => {
                        let value = this.props.value && typeof this.props.value == 'object' ? this.props.value : {};
                        value.fontStyle = value.fontStyle == 'italic' ? null : 'italic';
                        this.props.onChange(value);
                        this.forceUpdate();
                    }}><Isvg src={italicIcon} /></Button>
                    <Button size="sm" className="bold-button" color={this.props.value ? this.props.value.fontWeight == 'bold' ? 'info' : '' : ''} onClick={() => {
                        let value = this.props.value && typeof this.props.value == 'object' ? this.props.value : {};
                        value.fontWeight = value.fontWeight == 'bold' ? null : 'bold';
                        this.props.onChange(value);
                        this.forceUpdate();

                    }}><Isvg src={boldIcon} /></Button>

                    <div className="fs-dropdown-container">
                        <Isvg src={fontSizeIcon} />
                        <Input className="fs-dropdown" size="sm" type="select" value={this.props.value ? this.props.value.fontSize : 16} onChange={(e) => {
                            // console.log(e.target.value)
                            let value = this.props.value && typeof this.props.value == 'object' ? this.props.value : {};
                            value.fontSize = e.target.value;
                            this.props.onChange(value);
                            this.forceUpdate();

                        }}>
                                <option value={12}>12px</option>
                                <option value={13}>13px</option>
                                <option value={14}>14px</option>
                                <option value={15}>15px</option>
                                <option value={16}>16px</option>
                                <option value={17}>17px</option>
                                <option value={18}>18px</option>
                                <option value={19}>19px</option>
                                <option value={20}>20px</option>
                                <option value={21}>21px</option>
                                <option value={22}>22px</option>
                                <option value={23}>23px</option>
                                <option value={24}>24px</option>
                                <option value={25}>25px</option>
                                <option value={26}>26px</option>
                                <option value={27}>27px</option>
                                <option value={28}>28px</option>
                                <option value={29}>29px</option>
                                <option value={30}>30px</option>
                                <option value={31}>31px</option>
                                <option value={32}>32px</option>
                                <option value={33}>33px</option>
                                <option value={34}>34px</option>
                                <option value={35}>35px</option>
                                <option value={36}>36px</option>
                        </Input>
                    </div>
                    <ColorPicker value={this.props.value ? this.props.value.color : '#000000'} onChange={(color) => {
                        let value = this.props.value && typeof this.props.value == 'object' ? this.props.value : {};
                        value.color = color;
                        this.props.onChange(value)
                        this.forceUpdate();

                    }}></ColorPicker>
                </div>
                {this.props.error ? <FormFeedback >{this.props.error}</FormFeedback> : null}
            </FormGroup>
        );
    }
}

export default SmsName;