import React from 'react';

import {
    Container,
    Row,
    Col, Input, Label, Modal, ModalBody, ModalHeader, FormGroup, Button, ButtonToggle, ModalFooter
} from 'reactstrap';

import { Rnd } from 'react-rnd';

import Isvg from 'react-inlinesvg'

import addIcon from '../../assets/svg/add-icon.svg';

import PreviewComponent from './previewComponent';


import defaultJson from './default.json'
import defaultInformationJson from './defaultInformation.json'
import defaultLoginJson from './defaultLogin.json'

import { Player, Controls } from "@lottiefiles/react-lottie-player";

import warrning from '../../assets/images/warrning.png';


import Toggle from '../../components/forms/fields/toggle';
import { API_ENDPOINT } from '../../constants';
import { v4 as uuidv4 } from 'uuid';


import AppBuilder from '../../appBuilder/appBuilder'
import striptags from 'striptags';

import { copyMobileToApp } from '../user/landingHelper';
import Checkbox from '../../components/forms/fields/checkbox';


function parseJSON(response) {
    return response.json().then(json => {
        return {
            result: json,
            status: response.status,

        }
    })
}


/**
* Landing page configurator - template
* @author   Aleksandar Dabic
*/
class DragAndDropComponent extends React.Component {

    constructor(props) {
        super(props);
        this.player = React.createRef();
        this.player2 = React.createRef();
        this.state = {
            activeTab: 1,
            showPlayer: false,
            showPlayer2: false,
            infoPage: true,
            reset1: false,
            reset2: false,
            reset3: false,
            libraryIcons: [],
            clinicGroups: [],
            name: '',
            typeConf: 1,
            tabIndex: 1,
            layout: 'lg',

        }
    }
    /**
    * Update landing page style template
    * @author   Aleksandar Dabic
    */
    update = () => {
        this.setState(
            {
                showPlayer: true,
            },
            () => {

                let data = {};
                // console.log(this.props)
                if (this.props[0].match.params.id == 'new') {
                    data.landingJSON = this.state.landingJSON ? this.state.landingJSON : defaultJson; //defaultJSON
                    data.informationJSON = this.state.informationJSON ? this.state.informationJSON : defaultInformationJson; //defaultInfoPageJSON
                    data.loginJSON = this.state.loginJSON ? this.state.loginJSON : defaultLoginJson; //defaultLoginJSON

                } else {
                    data.landingJSON = this.state.landingJSON;
                    data.informationJSON = this.state.informationJSON;
                    data.loginJSON = this.state.loginJSON;
                }

                data.name = this.state.name;
                data.clinicGroups = this.state.clinicGroups;
                data.allClinicGroups = this.state.allClinicGroups;
                this.player.current.play();
                fetch(API_ENDPOINT + '/data-managment/landing-builder-styles/' + this.props[0].match.params.id, {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify(data)
                }).then(parseJSON).then(({ result, status }) => {
                    if (result.error) {
                        this.setState({ error: result.error })
                    } else {
                        this.setState({
                            showPlayer: false,
                            showPlayer2: true,
                        },
                            () => {
                                setTimeout(() => {
                                    this.setState({ showPlayer2: false });
                                }, 1000);
                            })

                    }
                    if (this.props[0].match.params.id == 'new' && result && result.id) {
                        let pathname = `/data-managment/landing-builder-styles/` + result.id;
                        this.props[0].history.push(pathname)

                    }

                })
            }
        )

    }
    stopAnimation = () => {
        this.player.current.play();
    };

    showPlayer = () => {
        this.setState({
            showPlayer: true,
        });
    };

    hidePlayer = () => {
        this.setState({
            showPlayer: false,
        });
    };

    get = () => {
        if (this.props.landingStyle) {
            // console.log(JSON.stringify(this.props.landingStyle.loginJSON))
            this.setState({
                landingJSON: this.props.landingStyle.landingJSON ? this.props.landingStyle.landingJSON : {},
                informationJSON: this.props.landingStyle.informationJSON ? this.props.landingStyle.informationJSON : {},
                loginJSON: this.props.landingStyle.loginJSON ? this.props.landingStyle.loginJSON : {},
                name: this.props.landingStyle.name ? this.props.landingStyle.name : '',
                clinicGroups: this.props.landingStyle?.clinicGroups ? this.props.landingStyle.clinicGroups : [],
                allClinicGroups: this.props.landingStyle?.allClinicGroups ? this.props.landingStyle.allClinicGroups : false,

            }, () => this.setState({ updateNeeded: uuidv4() }))
        }
        fetch(API_ENDPOINT + '/data-managment/landing-builder-styles/library/icons', {
            method: 'GET',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
        }).then(parseJSON).then(({ result, status }) => {
            if (status >= 200 && status < 300) {
                if (result && result.gallery) {
                    this.setState({
                        libraryIcons: result.gallery,
                        loadLibrary: false
                    })
                } else {
                    this.setState({
                        libraryIcons: [],
                        loadLibrary: false
                    })
                }


            }

        })
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.landingStyle != this.props.landingStyle) {
            this.get()
        }

    }

    componentDidMount() {
        this.get()
    }

    setReset = () => {
        if (this.state.tabIndex == 1) {
            this.setState({
                landingJSON: defaultJson,
                reset: null
            }, () => this.setState({ updateNeeded: uuidv4() }))
        } else if (this.state.tabIndex == 2) {
            this.setState({
                informationJSON: defaultInformationJson,
                reset: null
            }, () => this.setState({ updateNeeded: uuidv4() }))
        } else if (this.state.tabIndex == 3) {
            this.setState({
                loginJSON: defaultLoginJson,
                reset: null
            }, () => this.setState({ updateNeeded: uuidv4() }))
        }
    }




    render() {

        return (
            <div>
                <div className='landing-builder-styles-name-wrap'>
                    <Label>{'Name'.translate(this.props.lang)}</Label>
                    <Input type='text' style={{ backgroundColor: '#F1F1F1', marginLeft: "10px", height: '30px', width: "315px" }}
                        value={this.state.name}
                        onChange={(e) => this.setState({ name: striptags(e.target.value) })}
                    />
                </div>
                <div className='form-builder-clinic-groups-header-wrap' style={{ marginBottom: 20 }}>
                    <Checkbox checked={this.state.allClinicGroups}
                        onChange={(e) => {
                            let value = e.target.checked;

                            this.setState({
                                allClinicGroups: value
                            })
                        }}
                        label={'Set for all clinic groups'.translate(this.props.lang)}
                    />
                </div>

                <div className='form-builder-clinic-groups-header-wrap'>
                    <h6>{'List of clinic groups'.translate(this.props.lang)}</h6>
                    <div className='form-builder-clinic-groups-mark-buttons'>
                        <Button disabled={this.state.allClinicGroups} color='primary' onClick={() => {
                            if (this.props.clinicGroupsList && this.props.clinicGroupsList.length) {
                                let clinicGroups = [];
                                for (let i = 0; i < this.props.clinicGroupsList.length; i++) {
                                    clinicGroups.push(this.props.clinicGroupsList[i]._id)
                                }
                                this.setState({ clinicGroups })
                            }
                        }}>{'Mark all'.translate(this.props.lang)}</Button>
                        <Button disabled={this.state.allClinicGroups} color='primary' onClick={() => {
                            this.setState({ clinicGroups: [] })
                        }}>{'Unmark all'.translate(this.props.lang)}</Button>
                    </div>
                </div>

                <hr />
                <div className='form-builder-clinic-groups-wrap'>
                    {
                        this.props.clinicGroupsList && this.props.clinicGroupsList.map((item, idx) => {
                            return (
                                <Checkbox disabled={this.state.allClinicGroups} checked={this.state.clinicGroups.filter(el => el == item._id).length || this.state.allClinicGroups ? true : false}
                                    onChange={(e) => {
                                        if (this.state.allClinicGroups) {
                                            return
                                        }
                                        let value = e.target.checked;
                                        let clinicGroups = this.state.clinicGroups;
                                        if (value) {
                                            if (clinicGroups && clinicGroups.filter(el => el == item._id).length == 0) {
                                                clinicGroups.push(item._id);
                                            }
                                        } else {
                                            if (clinicGroups && clinicGroups.filter(el => el == item._id).length) {
                                                let index = clinicGroups.findIndex(el => el == item._id)
                                                if (index > -1) {
                                                    clinicGroups.splice(index, 1)
                                                }
                                            }
                                        }
                                        this.setState({
                                            clinicGroups
                                        })
                                    }}
                                    label={item.name} />
                            )
                        })
                    }
                </div>
                <hr />

                <div className="tabs-buttons-wrap2">
                    <div className='left-buttons'>

                        <Button className={this.state.tabIndex == 1 ? "tab-button acitve-tab-button" : "tab-button"} onClick={() => this.setState({ tabIndex: 1 })}>{"Landing page".translate(this.props.lang)}</Button>
                        <Button className={this.state.tabIndex == 2 ? "tab-button acitve-tab-button" : "tab-button"} onClick={() => this.setState({ tabIndex: 2 })}>{"Information page".translate(this.props.lang)}</Button>
                        <Button className={this.state.tabIndex == 3 ? "tab-button acitve-tab-button" : "tab-button"} onClick={() => this.setState({ tabIndex: 3 })}>{"Login page".translate(this.props.lang)}</Button>
                    </div>

                    <div className='right-buttons'>
                        <div style={{ height: 33, width: 200, marginTop: -18 }}>
                            <Toggle value={this.state.preview} label={"Preview".translate(this.props.lang)} onChange={(e) => {
                                this.setState({ preview: !this.state.preview }, () => {

                                })
                            }} />
                        </div>
                        <Button className='tab-button acitve-tab-button' onClick={() => this.update()}>
                            {
                                this.state.showPlayer ? (
                                    <Player
                                        onEvent={(event) => {
                                            if (event === "load") this.stopAnimation();
                                        }}
                                        onStateChange={this.toggleVisibility}
                                        ref={this.player} // set the ref to your class instance
                                        autoplay={true}
                                        loop={true}
                                        controls={true}
                                        src="https://assets8.lottiefiles.com/packages/lf20_ZSRKCF.json"
                                        style={{ height: "20px", width: "20px" }}
                                    ></Player>

                                ) : this.state.showPlayer2 ? (
                                    <div style={{ display: 'flex', alignItems: 'center' }}><Player
                                        onStateChange={this.toggleVisibility}
                                        ref={this.player2} // set the ref to your class instance
                                        autoplay={true}
                                        loop={true}
                                        controls={true}
                                        src="https://assets10.lottiefiles.com/packages/lf20_CxyxLk.json"
                                        style={{ height: "20px", width: "20px" }}
                                    ></Player>
                                        {'Saved'.translate(this.props.lang)}
                                    </div>
                                ) : (
                                    "Save".translate(this.props.lang)
                                )
                            }
                        </Button>

                        {
                            this.props[0].match.params.id != 'new' ?
                                <>
                                    {/* <Button className='tab-button acitve-tab-button' onClick={() => this.setState({ preview: JSON.parse(JSON.stringify(this.state.landingJSON)) }, () => console.log(JSON.parse(JSON.stringify(this.state.landingJSON))))}>{"Preview".translate(this.props.lang)}</Button> */}
                                    <Button className='tab-button acitve-tab-button' onClick={() => this.setState({ reset: this.state.tabIndex })}>{"Reset".translate(this.props.lang)}</Button>
                                </>
                                :
                                null
                        }

                    </div>
                </div>
                {
                    this.state.tabIndex == 1 ?
                        <div style={{ visibility: this.state.preview ? 'hidden' : 'visible', height: this.state.preview ? '0' : 'auto' }}>
                            <AppBuilder
                                json={this.state.landingJSON ? this.state.landingJSON : {}}
                                location={this.props[0].location.pathname}
                                handleChange={(json) => {
                                    this.setState({
                                        landingJSON: json,
                                    })
                                }}
                                changeLayout={(layout) => {
                                    this.setState({
                                        layout: layout
                                    })
                                }}
                                importMobileToApp={(nodes) => {
                                    let newNodes = copyMobileToApp(nodes)
                                    if (this.state.ifChange) {
                                        this.props.onChange('open')
                                        this.setState({
                                            ifChange: false
                                        })
                                    }
                                    this.setState({
                                        landingJSON: JSON.parse(JSON.stringify(newNodes)),
                                        updateNeeded: uuidv4()
                                    })
                                }}
                                layout={this.state.layout}
                                key={1}
                                updateNeeded={this.state.updateNeeded}
                                libraryIcons={this.state.libraryIcons}
                                lang={this.props.lang}
                                preview={this.state.preview}

                            />
                        </div>
                        :
                        this.state.tabIndex == 2 ?
                            <div style={{ visibility: this.state.preview ? 'hidden' : 'visible', height: this.state.preview ? '0' : 'auto' }}>
                                <AppBuilder
                                    json={this.state.informationJSON ? this.state.informationJSON : {}}
                                    location={this.props[0].location.pathname}
                                    libraryIcons={this.state.libraryIcons}
                                    handleChange={(json) => {
                                        this.setState({
                                            informationJSON: json,
                                        })
                                    }}
                                    changeLayout={(layout) => {
                                        this.setState({
                                            layout: layout
                                        })
                                    }}
                                    importMobileToApp={(nodes) => {
                                        let newNodes = copyMobileToApp(nodes)
                                        if (this.state.ifChange) {
                                            this.props.onChange('open')
                                            this.setState({
                                                ifChange: false
                                            })
                                        }
                                        this.setState({
                                            informationJSON: JSON.parse(JSON.stringify(newNodes)),
                                            updateNeeded: uuidv4()
                                        })
                                    }}
                                    layout={this.state.layout}
                                    key={2}
                                    updateNeeded={this.state.updateNeeded}
                                    lang={this.props.lang}
                                    preview={this.state.preview}

                                />
                            </div>
                            :
                            this.state.tabIndex == 3 ?
                                <div style={{ visibility: this.state.preview ? 'hidden' : 'visible', height: this.state.preview ? '0' : 'auto' }}>
                                    <AppBuilder
                                        json={this.state.loginJSON ? this.state.loginJSON : {}}
                                        location={this.props[0].location.pathname}
                                        libraryIcons={this.state.libraryIcons}
                                        handleChange={(json) => {
                                            this.setState({
                                                loginJSON: json,
                                            })
                                        }}
                                        changeLayout={(layout) => {
                                            this.setState({
                                                layout: layout
                                            })
                                        }}
                                        importMobileToApp={(nodes) => {
                                            let newNodes = copyMobileToApp(nodes)
                                            if (this.state.ifChange) {
                                                this.props.onChange('open')
                                                this.setState({
                                                    ifChange: false
                                                })
                                            }
                                            this.setState({
                                                loginJSON: JSON.parse(JSON.stringify(newNodes)),
                                                updateNeeded: uuidv4()
                                            })
                                        }}
                                        layout={this.state.layout}
                                        key={3}
                                        updateNeeded={this.state.updateNeeded}
                                        lang={this.props.lang}
                                        preview={this.state.preview}

                                    />
                                </div>
                                :
                                null
                }
                {
                    this.state.preview ?
                        <div className="preview-landing-wrap">
                            <div className={this.state.layout == 'xs' || this.state.layout == 'app' ? 'preview-xs-size' : this.state.layout == 'sm' ? 'preview-sm-size' : 'preview-lg-size'}>
                                <PreviewComponent
                                    layout={this.state.layout}
                                    nodes={this.state.tabIndex == 1 ? JSON.parse(JSON.stringify(this.state.landingJSON)) : this.state.tabIndex == 2 ? JSON.parse(JSON.stringify(this.state.informationJSON)) : this.state.tabIndex == 3 ? JSON.parse(JSON.stringify(this.state.loginJSON)) : {}} />
                            </div>
                        </div>
                        :
                        null
                }

                {this.state.reset == 1 ?
                    <Modal isOpen={this.state.reset} style={{ marginTop: '50px' }} centered>
                        {/* <ModalHeader style={{ margin: 'auto' }}>{'Reset landing page'.translate(this.props.lang)}</ModalHeader> */}

                        <ModalBody className="delete-modal">
                            {/* <img src={warrning} /> */}
                            <div>{'Are you sure you want reset landing page?'.translate(this.props.lang)}</div>
                        </ModalBody>
                        <ModalFooter className='buttons-right-blue'>
                            <Button color='primary' style={{ margin: 5, backgroundColor: "#3A3F56", minWidth: 100 }} onClick={() => this.setReset()}>{'Yes'.translate(this.props.lang)}</Button>
                            <Button color='primary' style={{ margin: 5, minWidth: 100 }} onClick={() => this.setState({ reset: !this.state.reset })}>{'No'.translate(this.props.lang)}</Button>

                        </ModalFooter>
                    </Modal>
                    : null}
                {this.state.reset == 2 ?
                    <Modal isOpen={this.state.reset} style={{ marginTop: '50px' }} centered>
                        {/* <ModalHeader style={{ margin: 'auto' }}>{'Reset information page'.translate(this.props.lang)}</ModalHeader> */}

                        <ModalBody className="delete-modal">
                            {/* <img src={warrning} /> */}
                            <div>{'Are you sure you want reset information page?'.translate(this.props.lang)}</div>
                        </ModalBody>
                        <ModalFooter className='buttons-right-blue'>
                            <Button color='primary' style={{ margin: 5, backgroundColor: "#3A3F56", minWidth: 100 }} onClick={() => this.setReset()}>{'Yes'.translate(this.props.lang)}</Button>
                            <Button color='primary' style={{ margin: 5, minWidth: 100 }} onClick={() => this.setState({ reset: !this.state.reset })}>{'No'.translate(this.props.lang)}</Button>

                        </ModalFooter>
                    </Modal>
                    : null}

                {this.state.reset == 3 ?
                    <Modal isOpen={this.state.reset} style={{ marginTop: '50px' }} centered>
                        {/* <ModalHeader style={{ margin: 'auto' }}>{'Reset login page'.translate(this.props.lang)}</ModalHeader> */}
                        <ModalBody className="delete-modal">
                            {/* <img src={warrning} /> */}
                            <div>{'Are you sure you want reset login page?'.translate(this.props.lang)}</div>
                        </ModalBody>
                        <ModalFooter className='buttons-right-blue'>
                            <Button color='primary' style={{ margin: 5, backgroundColor: "#3A3F56", minWidth: 100 }} onClick={() => this.setReset()}>{'Yes'.translate(this.props.lang)}</Button>
                            <Button color='primary' style={{ margin: 5, minWidth: 100 }} onClick={() => this.setState({ reset: !this.state.reset })}>{'No'.translate(this.props.lang)}</Button>
                        </ModalFooter>
                    </Modal>
                    : null}





            </div>

        )
    }
}

export default DragAndDropComponent;