import React, { Component } from 'react';
import Page from '../../containers/page';

import {
    Container,
    Row,
    Col,
    Button,
    FormGroup,
    Label,
    Input,
    UncontrolledAlert,
    UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Table, Form, Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';

import Isvg from 'react-inlinesvg'

import HTML from '../../components/forms/fields/html';

import ErrorModal from '../../components/errorModal';
import DeleteModal from '../../components/deleteModal';
import InfoModal from '../../components/infoModal';
import ListCheckBox from '../../components/forms/fields/listCheckbox';
import { getSearchParams, generateSearchLink, generateSearchLinkMultiple } from '../../helpers/linkHelper';
import { API_ENDPOINT } from '../../constants';
import { formValueSelector, change } from 'redux-form';  // ES6
import { connect } from 'react-redux';
import warrning from '../../assets/svg/warrning.svg';
import { SortableContainer, SortableElement, sortableHandle, } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import { v4 as uuidv4 } from 'uuid';
import garabage from '../../assets/svg/garbage.svg'
import arrows from '../../assets/svg/arrows.svg'

import viewIcon from '../../assets/svg/eye.svg';
import hideIcon from '../../assets/svg/hide.svg';


import backIcon from '../../assets/svg/back-icon.svg'


import CreateReferralModal from '../../components/createReferralModal';
import ImportReferral from '../../components/importReferral';
import CreatePersonalReferralModal from '../../components/createPersonalReferralModal';

import FormPreview from '../../components/formPreview';
import ListBuilder from '../../components/listBuilder';
import { Player, Controls } from "@lottiefiles/react-lottie-player";

import Search from '../../components/search';
import profileUser from '../../assets/images/user.png';
import notification from '../../assets/svg/notification.svg';
import moreIcon from '../../assets/svg/more.svg';
import xIcon from '../../assets/svg/x.svg';
import Print from '../../assets/svg/print.svg'
import Link from '../../assets/svg/link.svg'
import Checkbox from '../../components/forms/fields/checkbox';
import addIcon from '../../assets/svg/add-icon.svg';
import Html from '../../components/forms/fields/html';

import CreateDaybookModal from '../../components/createDaybookModal';

import PreviewAnswerForm from '../forms/previewAnswerForm';


import SelectableInterval from '../../components/selectableInterval';
import WebBookingOffset from '../../components/forms/fields/webBookingOffset';



function getRandomColor() {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
}


const colors = [
    "aqua",
    "black",
    "lime",
    "blue",
    "fuchsia",
    "gray",
    "green",
    "navy",
    "olive",
    "purple",
    "red",
    "silver",
    "teal",
    "yellow",
    "maroon",
];


function parseJSON(response) {
    return response.json().then(json => {
        return {
            result: json,
            status: response.status
        }
    })
}


const SortableItem = SortableElement(({ value, update }) => <li className={value.className}>{value.component}</li>);

const SortableList = SortableContainer(({ items, updateSubMenu }) => {
    return (
        <ul className="prepare-questionary-sort-list">
            {items.map((value, index) => (
                <SortableItem key={`item-${index}`} index={index} value={value} />
            ))}
        </ul>
    );
});

const SortableAnswer = SortableElement(({ value }) => <div className={value.className}>{value.component}</div>);

const SortableAnswers = SortableContainer(({ items }) => {
    return (
        <div>
            {items.map((value, index) => (
                <SortableAnswer className="bam" key={`item-${index}`} index={index} value={value} />
            ))}
        </div>
    );
});




const DragHandle = sortableHandle(({ children }) => children);


/**
* Form builder for referral
* @author   Milan Stanojevic
*/
class ReferralBuilder extends Component {
    constructor(props) {
        super(props);
        this.player = React.createRef();
        this.player2 = React.createRef();
        this.state = {
            ...props.initialData,
            showPlayer: false,
            showPlayer2: false,
            sectionFormIdx: null,
            answerForm: null,
            deleteElementModal: null,
            deleteOptionModal: null,
            linkingElement: null,
            articles: [],
            sections: [],
            tags: [],
            formTags: [],
            items: [],
            professions: [],
            professionList: [],
            version: 1,
            haveQuestionary: true,
            answers: {},
            duration: -1,
            freeEventsStartOffset: 15,
            doctorsModal: false,
            professionsModal: false,
            doctorsList: [],
            blocks: [],
            wayOfScheduling: 'bookingByClinic',
            messageShow: false,
            messageModalError: false,
            cursorNoDrop: false,
            checkActiveSave: true,
            price: 0,
            freeOfCharge: true,
            openPageWarningModal: false,
            deleteBlockModal: null,
            // under18: true,
            // older85: true,
            // under18child: true,
            nameBonus: '',
            numberOfElementsToRender: 1,
            detectedChage: false,
            vatRate: -1,
            selectVatRate: false

        };
    }
    detectChange = () => {
        // throw new Error()
        if (!this.state.detectedChage)
            this.setState({ detectedChage: true })
    }

    get = () => {
        this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));

        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { formType: 'visit-forms', entries: 10, page: 0, clinic: this.props.selectedClinic, groupId: this.props.selectedGroup })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

        for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
            this.props.loadDataWithQuery[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { formType: 'visit-forms', entries: 10, page: 0, clinic: this.props.selectedClinic, groupId: this.props.selectedGroup })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

    }

    componentDidMount() {
        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }

        this.get();

        // this.setState({ openPageWarningModal: true })



        let params = getSearchParams(this.props[0].location.search, {});

        this.getQuestionary();
        window.addEventListener('scroll', this.handleScroll);

        if (this.props.uData && this.props.uData.userLevel == 100) {


            fetch(API_ENDPOINT + '/data/clinic-groups/all', {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
            }).then(parseJSON).then(({ result, status }) => {
                this.setState({
                    clinicGroupsData: result
                })
            })
        }

        if (this.props[0].match.params.id == 'new') {
            this.checkActiveSave()
        }


        if (this.props[0].location.state && this.props[0].location.state.wayOfScheduling) {
            this.setState({ wayOfScheduling: this.props[0].location.state.wayOfScheduling }, () => {
                if (this.state.wayOfScheduling == 'webBooking' || this.state.wayOfScheduling == 'dropIn') {
                    this.setState({
                        freeOfCharge: true,
                        paymentOnSite: false,
                        invoice: false,
                        onlinePayment: false,
                        paymentBeforeBookedTime: false,
                        paymentAfterBookedTime: false,
                        paymentBeforeSubmission: false,
                        paymentAfterSubmission: false,
                        onlineVisit: this.state.wayOfScheduling === 'dropIn' ? true : this.state.onlineVisit
                    })

                }
            })
        }

        this.setGroupBookingSettings()
    }
    setGroupBookingSettings = () => {
        let selectedGroupIdx = 0;
        if (this.props.selectedGroup && this.props.uData?.clinicGroups?.length) {
            for (let i = 0; i < this.props.uData.clinicGroups.length; i++) {
                if (this.props.uData.clinicGroups[i]._id == this.props.selectedGroup) {
                    selectedGroupIdx = i;
                    break;
                }
            }
        }
        const selectedGroupData = this.props.uData && this.props.uData.clinicGroups && this.props.uData.clinicGroups[selectedGroupIdx];
        const groupBookingSettings = selectedGroupData?.bookingSettings ?? {
            offset: 15,
            default: false,
            global: false
        }
        this.setState({ groupBookingSettings }, () => {
            if (this.state.groupBookingSettings?.global && this.state.groupBookingSettings?.offset) {
                this.setState({ freeEventsStartOffset: this.state.groupBookingSettings.offset })
            } else if (this.state.groupBookingSettings?.default && this.state.groupBookingSettings?.offset) {
                this.setState({ freeEventsStartOffset: this.state.groupBookingSettings.offset })
            }
        })
    }
    handleScroll = () => {
        var scrollTop = (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop;

        // Grodriguez's fix for scrollHeight:
        // accounting for cases where html/body are set to height:100%
        var scrollHeight = (document.documentElement && document.documentElement.scrollHeight) || document.body.scrollHeight;

        // >= is needed because if the horizontal scrollbar is visible then window.innerHeight includes
        // it and in that case the left side of the equation is somewhat greater.
        var scrolledToBottom = (scrollTop + window.innerHeight) >= ((scrollHeight) - scrollHeight / 8);
        // console.log('scrolledToBottom', scrolledToBottom)
        if (scrolledToBottom) {
            this.setState({
                numberOfElementsToRender: this.state.numberOfElementsToRender + 1
            })
        }
    }

    componentWillUnmount() {
        try {
            window.removeEventListener('scroll', this.handleScroll);

            delete this.state;
            this.state = {};
        } catch (e) {

        }




    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps[0].location.search != this.props[0].location.search && this.state.doctors) {
            this.setState({
                loading: true
            }, () => {
                this.get();

            })
        }
        if (prevProps[0].location.pathname != this.props[0].location.pathname) {
            this.props.abortAction()
            this.get()
        }
        if (prevProps.selectedClinic != this.props.selectedClinic) {
            this.get();
        }
        if (prevProps.selectedGroup !== this.props.selectedGroup) {
            this.setGroupBookingSettings()
        }

        // if (prevState.doctors != this.state.doctors && this.state.doctors && this.state.doctors.length && !this.state.loading) {
        //     let arr = [];
        //     for (let i = 0; i < this.state.doctors.length; i++) {
        //         let obj = {
        //             _id: this.state.doctors[i]._id,
        //             checked: false,
        //             name: this.state.doctors[i].name
        //         }
        //         arr.push(obj);
        //     }
        //     if (this.state.doctorsListQuestionary && this.state.doctorsListQuestionary.length) {
        //         for (let i = 0; i < arr.length; i++) {
        //             for (let j = 0; j < this.state.doctorsListQuestionary.length; j++) {
        //                 if (this.state.doctorsListQuestionary[j]._id == arr[i]._id) {
        //                     arr[i].checked = this.state.doctorsListQuestionary[j].checked;
        //                 }
        //             }
        //         }
        //     }

        //     this.setState({ doctorsList: arr, messageShow: true })
        // }
        // if (prevState.doctorsListQuestionary != this.state.doctorsListQuestionary && this.state.doctorsListQuestionary && this.state.doctorsListQuestionary.length && this.state.doctors && this.state.doctors.length && !this.state.loading) {
        //     let arr = [];
        //     for (let i = 0; i < this.state.doctors.length; i++) {
        //         let obj = {
        //             _id: this.state.doctors[i]._id,
        //             checked: false,
        //             name: this.state.doctors[i].name
        //         }
        //         arr.push(obj);
        //     }
        //     for (let i = 0; i < arr.length; i++) {
        //         for (let j = 0; j < this.state.doctorsListQuestionary.length; j++) {
        //             if (this.state.doctorsListQuestionary[j]._id == arr[i]._id) {
        //                 arr[i].checked = this.state.doctorsListQuestionary[j].checked;
        //             }
        //         }
        //     }


        //     this.setState({ doctorsList: arr, messageShow: true })
        // }

        if (this.state.name && prevState.name != this.state.name) {
            this.checkActiveSave()
        }

        if (this.state.detectedChage && !this.props.groupSettingsModal) {
            this.props.groupSettingsChanged()
        }



    }

    showPlayer = () => {
        this.setState({
            showPlayer: true,
        });
    };

    hidePlayer = () => {
        this.setState({
            showPlayer: false,
        });
    };

    stopAnimation = () => {
        this.player.current.play();
    };

    updateSort = (field, type) => {
        if (this.state.useState) {
            let state = this.state.params;
            state.sortField = field;
            state.sortType = type;
            this.setState({
                params: state
            })
        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0, group: this.props.selectedGroup, clinic: this.props.selectedClinic }, [{ name: 'sortField', value: field }, { name: 'sortType', value: type }], false));

        }
    }

    updateParams = (name, value, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };
                obj[name] = value;
                this.setState({ params: obj });
            } else {
                let obj = this.state.params;
                obj[name] = value;
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(generateSearchLink(this.props[0].location.search, { entries: 10, page: 0, group: this.props.selectedGroup, clinic: this.props.selectedClinic }, name, value, restart));
        }
    }
    updateMultipleParams = (fields, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj });

            } else {
                let obj = this.state.params;
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0, group: this.props.selectedGroup, clinic: this.props.selectedClinic }, fields, restart));
        }
    }
    checkDuplicateConditions = (idx, bidx) => {
        if (this.state.items && this.state.items[idx]) {
            let item = this.state.items[idx];
            if (item && item.blocks && item.blocks.length) {
                let blocks = item.blocks;
                let thisBlock = blocks[bidx];
                if (thisBlock) {
                    if (blocks.filter(block => block.id != thisBlock.id && block.comparison == thisBlock.comparison && block.value == thisBlock.value).length) {
                        this.setState({ duplicateBlock: 'There is already a block with the same parameters!'.translate(this.props.lang) })
                    }
                }

            }

        }
    }


    /**
    * Function return questionary selected by id
    * @author   Milan Stanojevic
    * @Objectparam    {String} id    questionary id
    */
    getQuestionary = () => {
        fetch(API_ENDPOINT + '/data/personal-questionary/' + this.props[0].match.params.id, {
            method: 'GET',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
        }).then(parseJSON).then(({ result, status }) => {

            if (status >= 200 && status < 300) {
                for (let i = 0; i < result.items.length; i++) {
                    if (!result.items[i].id) {
                        result.items[i].id = uuidv4();
                    }
                    /*if (result.items[i].conditionQuestion){
                        result.items[i].conditions = [{conditionQuestion: result.items[i].conditionQuestion, conditionAnswer: result.items[i].conditionAnswer }]
                        delete result.items[i].conditionQuestion;
                        delete result.items[i].conditionAnswer;
                    }*/
                }
                if (result?._id && !result.freeEventsStartOffset) {
                    result.freeEventsStartOffset = 15;
                }

                this.setState(result, () => {
                    if (this.state.name) {
                        this.setState({
                            name: this.props.lang == 'en' ? this.state.name.replace(' (onlinebesök)', ' (online visit)') : this.state.name.replace(' (online visit)', ' (onlinebesök)')
                        })
                    }
                    this.checkBlocksRange()

                    if (this.state.wayOfScheduling === 'webBooking' && this.state.price > 0 && !this.state.paymentAfterSubmission && !this.state.paymentBeforeSubmission) {
                        this.setState({ paymentAfterSubmission: true })
                    }
                    if (this.state.wayOfScheduling === 'dropIn' && this.state.price > 0 && !this.state.paymentAfterSubmission && !this.state.paymentBeforeSubmission) {
                        this.setState({ paymentBeforeSubmission: true })
                    }

                });

            } else {
                fetch(API_ENDPOINT + '/data/questionary/prepare/' + this.props[0].match.params.id, {
                    method: 'GET',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300) {
                        for (let i = 0; i < result.items.length; i++) {
                            if (!result.items[i].id) {
                                result.items[i].id = uuidv4();
                            }
                            /*if (result.items[i].conditionQuestion){
                                result.items[i].conditions = [{conditionQuestion: result.items[i].conditionQuestion, conditionAnswer: result.items[i].conditionAnswer }]
                                delete result.items[i].conditionQuestion;
                                delete result.items[i].conditionAnswer;
                            }*/
                        }

                        fetch(API_ENDPOINT + '/data/articles/' + this.props[0].match.params.id, {
                            method: 'GET',
                            headers: {
                                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                                'content-type': 'application/json'
                            },
                        }).then(parseJSON).then(({ result, status }) => {
                            if (status >= 200 && status < 300) {


                                this.setState({
                                    name: result.title,

                                })


                            }
                        })

                        this.setState({
                            intelligentReferral: true,

                        })

                        this.setState(result, () => {
                            if (this.state.name) {
                                this.setState({
                                    name: this.props.lang == 'en' ? this.state.name.replace(' (onlinebesök)', ' (online visit)') : this.state.name.replace(' (online visit)', ' (onlinebesök)')
                                })
                            }
                            this.checkBlocksRange()
                        });
                    }
                })
            }

        })
    }

    /**
    * Insert or update questionary
    * @author   Milan Stanojevic
    * @Objectparam    {String} id    questionary id
    */
    updateQuestionary = async (redirect, changeLink) => {
        /*if (!this.checkAllPrintAnswers()) {
            this.setState({
                error: 'Please check all print answers'
            })
            return;
        }*/
        let vatObj = {}
        let selectedGroupIdx = 0;
        if (this.props.selectedGroup && this.props.uData?.clinicGroups?.length) {
            for (let i = 0; i < this.props.uData.clinicGroups.length; i++) {
                if (this.props.uData.clinicGroups[i]._id == this.props.selectedGroup) {
                    selectedGroupIdx = i;
                    break;
                }
            }
        }

        let hasOnlinePaymentInClinic = (this.props.uData.clinicGroups[selectedGroupIdx] && ((this.props.uData.clinicGroups[selectedGroupIdx].apiKey && this.props.uData.clinicGroups[selectedGroupIdx].agentId) || this.props.uData.clinicGroups[selectedGroupIdx].bankAccount || this.props.uData.clinicGroups[selectedGroupIdx].stripeAccountID)) ? true : false;

        let canTransferPayments = this.props.uData && this.props.uData.clinicGroups && this.props.uData.clinicGroups[selectedGroupIdx] && this.props.uData.clinicGroups[selectedGroupIdx].canTransferPayments;
        if (canTransferPayments) {
            vatObj = {
                vatRate: this.state.vatRate,
                selectVatRate: this.state.selectVatRate
            }
        } else {
            vatObj = {
                vatRate: -1,
                selectVatRate: false
            }
        }


        this.setState({ openPageWarningModal: null })
        let name = this.state.name;

        let nameUsed = false;
        if (name) {
            let checkName = await fetch(API_ENDPOINT + '/data/form-questionary/check', {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify({
                    group: this.props.selectedGroup,
                    clinic: this.props.selectedClinic,
                    type: 'visit-form',
                    name: name.trim(),
                    id: this.props[0].match.params.id,
                    wayOfScheduling: this.state.wayOfScheduling
                })
            })
            checkName = await checkName.json();
            if (checkName && checkName.nameUsed) {
                nameUsed = checkName.nameUsed;
            }
        }

        if (nameUsed) {
            this.setState({ error: 'A form with the same name already exists. Please select another form name.'.translate(this.props.lang) })
            return;
        }


        if (!name) {
            if (this.props.lang == 'en') {
                name = 'New form';
            } else {
                name = 'Ny formulär';
            }
            if (this.state.personalQuestionaryNumberNew && this.state.personalQuestionaryNumberNew > 0) {
                name += ` (${this.state.personalQuestionaryNumberNew})`
            }
        }

        let anotherName = '';
        if (name) {
            if (this.props.lang == 'en') {
                anotherName = name.replace('(online visit)', '(onlinebesök)')
            } else {
                anotherName = name.replace('(onlinebesök)', '(online visit)')
            }
        }



        if (this.state.duration == -1 && this.state.wayOfScheduling == 'webBooking') {
            this.setState({ error: "Value of meeting length cannot be none.".translate(this.props.lang) });

            this.props.abortAction()

        } else {
            if (this.props[0].location.pathname.indexOf('/create-personal-referral') !== -1) {
                let obj;
                if (this.state.wayOfScheduling == 'bookingByClinic') {
                    obj = {
                        items: this.state.items,
                        name: name,
                        intelligentReferral: this.state.intelligentReferral,
                        description: this.state.description,
                        groupsValue: this.state.groupsValue,
                        anotherName: anotherName,
                        ...vatObj

                    }
                } else {
                    obj = {
                        items: this.state.items,
                        name: name,
                        intelligentReferral: this.state.intelligentReferral,
                        description: this.state.description,
                        groupsValue: this.state.groupsValue,
                        anotherName: anotherName,
                        active: false, // this.state.wayOfScheduling == 'webBooking' && this.state.doctorsList && (this.state.doctorsList.length == 0 || this.state.doctorsList.filter((listItem) => listItem.checked == true).length == 0) ? false : true,
                        ...vatObj
                    }
                }
                if (obj.items && obj.items.length === 0) {
                    obj.active = false;
                }

                fetch(API_ENDPOINT + '/data/personal-questionary/new', {
                    method: 'PUT',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify(obj)
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300) {
                        this.getQuestionary();
                        this.setState({
                            message: 'Questionary saved successfully',
                            detectedChage: false
                        })
                        if (changeLink) {
                            this.props[0].history.push(this.props.changeLinkModal)

                        } else {
                            this.props[0].history.push('/data-managment/personal-referrals')

                        }
                        this.props.abortAction()

                    }
                })
            } else {
                let obj;
                if (this.state.wayOfScheduling == 'bookingByClinic') {
                    obj = {
                        items: this.state.items,
                        name: name,
                        intelligentReferral: this.state.intelligentReferral,
                        // saveFormular: this.state.saveFormular,
                        description: this.state.description,
                        price: this.state.price,
                        groupsValue: this.state.groupsValue,
                        group: this.props.selectedGroup ? this.props.selectedGroup : null,
                        clinic: this.props.selectedClinic ? this.props.selectedClinic : null,
                        wayOfScheduling: this.state.wayOfScheduling,
                        // active: false,  //this.state.professionList && this.state.professionList.length == 0 ? false : true
                        professionList: this.state.professionList,
                        invoice: this.state.invoice,
                        onlineVisit: this.state.onlineVisit,
                        videoCall: this.state.videoCall,
                        paymentOnSite: this.state.paymentOnSite,
                        freeOfCharge: this.state.freeOfCharge,
                        onlinePayment: this.state.onlinePayment && hasOnlinePaymentInClinic ? true : false,
                        paymentAfterBookedTime: this.state.paymentAfterBookedTime,
                        paymentBeforeBookedTime: this.state.paymentBeforeBookedTime,
                        paymentBeforeSubmission: this.state.paymentBeforeSubmission,
                        paymentAfterSubmission: false,
                        haveFreeCard: this.state.onlinePayment || this.state.paymentOnSite || this.state.invoice ? this.state.haveFreeCard : false,
                        under23: this.state.onlinePayment || this.state.paymentOnSite || this.state.invoice ? this.state.under23 : false,
                        under20: this.state.onlinePayment || this.state.paymentOnSite || this.state.invoice ? this.state.under20 : false,
                        under18: this.state.onlinePayment || this.state.paymentOnSite || this.state.invoice ? this.state.under18 : false,
                        older85: this.state.onlinePayment || this.state.paymentOnSite || this.state.invoice ? this.state.older85 : false,
                        under18child: this.state.onlinePayment || this.state.paymentOnSite || this.state.invoice ? this.state.under18child : false,
                        anotherName: anotherName,
                        ...vatObj
                    }
                } else {
                    obj = {
                        items: this.state.items,
                        name: name,
                        intelligentReferral: this.state.intelligentReferral,
                        // saveFormular: this.state.saveFormular,
                        description: this.state.description,
                        price: this.state.price,
                        groupsValue: this.state.groupsValue,
                        duration: this.state.duration,
                        // active: false, //this.state.professionList && this.state.professionList.length == 0 ? false : true
                        group: this.props.selectedGroup ? this.props.selectedGroup : null,
                        clinic: this.props.selectedClinic ? this.props.selectedClinic : null,
                        professionList: this.state.professionList,
                        doctorsListQuestionary: this.state.doctorsList,
                        wayOfScheduling: this.state.wayOfScheduling,
                        invoice: this.state.invoice,
                        paymentOnSite: this.state.paymentOnSite,
                        onlineVisit: this.state.onlineVisit || this.state.wayOfScheduling === 'dropIn' ? true : false,
                        videoCall: this.state.videoCall,
                        freeOfCharge: this.state.freeOfCharge,
                        onlinePayment: this.state.onlinePayment && hasOnlinePaymentInClinic ? true : false,
                        paymentAfterBookedTime: false,
                        paymentBeforeBookedTime: false,
                        paymentAfterSubmission: this.state.paymentAfterSubmission,
                        paymentBeforeSubmission: this.state.paymentBeforeSubmission,
                        haveFreeCard: this.state.onlinePayment || this.state.paymentOnSite || this.state.invoice ? this.state.haveFreeCard : false,
                        under23: this.state.onlinePayment || this.state.paymentOnSite || this.state.invoice ? this.state.under23 : false,
                        under20: this.state.onlinePayment || this.state.paymentOnSite || this.state.invoice ? this.state.under20 : false,
                        under18: this.state.onlinePayment || this.state.paymentOnSite || this.state.invoice ? this.state.under18 : false,
                        older85: this.state.onlinePayment || this.state.paymentOnSite || this.state.invoice ? this.state.older85 : false,
                        under18child: this.state.onlinePayment || this.state.paymentOnSite || this.state.invoice ? this.state.under18child : false,
                        anotherName: anotherName,
                        ...vatObj
                        // invoiceMessage: this.state.invoiceMessage,

                    }
                    if (this.state.wayOfScheduling === 'webBooking') {
                        obj.freeEventsStartOffset = this.state.freeEventsStartOffset;
                    }
                }
                if (this.props[0].match.params.id == 'new') {
                    obj.active = false;
                }
                if (obj.items && obj.items.length === 0) {
                    obj.active = false;
                }
                this.setState({ showPlayer: true }, () => {
                    this.player.current.play();

                    fetch(API_ENDPOINT + '/data/personal-questionary/' + this.props[0].match.params.id, {
                        method: this.props[0].match.params.id == 'new' ? 'PUT' : 'POST',
                        headers: {
                            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                            'content-type': 'application/json'
                        },
                        body: JSON.stringify(obj)
                    }).then(parseJSON).then(({ result, status }) => {
                        if (status >= 200 && status < 300) {
                            this.getQuestionary();
                            this.setState({
                                message: 'Questionary saved successfully',
                                detectedChage: false
                            })
                            if (changeLink) {
                                this.props[0].history.push(this.props.changeLinkModal)

                            } else if (!this.props.selectedGroup) {
                                if (redirect)
                                    this.props[0].history.push('/data-managment/personal-referrals')
                            } else {
                                if (redirect)
                                    this.props[0].history.push('/personal-referrals/list')
                            }
                            this.props.abortAction()

                            if (this.props[0].match.params.id == 'new' && result && result.id && !changeLink && !redirect) {
                                let redirectFrom = {
                                    tabIndex: 1,
                                    page: 0
                                }
                                if (this.state.wayOfScheduling == 'webBooking') {
                                    redirectFrom.tabIndex = 2;
                                } else if (this.state.wayOfScheduling == 'dropIn') {
                                    redirectFrom.tabIndex = 3;
                                }


                                this.props[0].history.push({
                                    pathname: `/personal-referrals/${result.id}`,
                                    state: { redirectFrom: redirectFrom }
                                })
                                this.getQuestionary();
                            }
                            this.setState(
                                {
                                    showPlayer: false,
                                    showPlayer2: true,
                                },
                                () => {
                                    setTimeout(() => {
                                        this.setState({ showPlayer2: false });
                                    }, 1000);
                                }
                            );



                        } else {
                            this.setState({ showPlayer: false });
                        }
                    })

                })


            }
        }



    }

    checkActiveSave = () => {
        let selectedGroupIdx = 0;
        if (this.props.selectedGroup && this.props.uData?.clinicGroups?.length) {
            for (let i = 0; i < this.props.uData.clinicGroups.length; i++) {
                if (this.props.uData.clinicGroups[i]._id == this.props.selectedGroup) {
                    selectedGroupIdx = i;
                    break;
                }
            }
        }
        let hasOnlinePaymentInClinic = (this.props.uData.clinicGroups[selectedGroupIdx] && ((this.props.uData.clinicGroups[selectedGroupIdx].apiKey && this.props.uData.clinicGroups[selectedGroupIdx].agentId) || this.props.uData.clinicGroups[selectedGroupIdx].bankAccount || this.props.uData.clinicGroups[selectedGroupIdx].stripeAccountID)) ? true : false;

        let returnValue = true;

        // if (this.state.onlinePayment && this.state.invoice && !this.state.paymentOnSite) {
        //     returnValue = false;
        // } else if (this.state.onlinePayment && !this.state.invoice && this.state.paymentOnSite) {
        //     returnValue = false;
        // } else if (!(this.state.onlinePayment && hasOnlinePaymentInClinic) && this.state.invoice && this.state.paymentOnSite) {
        //     returnValue = false;
        // } else if (this.state.freeOfCharge) {
        //     returnValue = true;
        // }
        // if (!returnValue) {
        //     this.setState({ errorSave: 'You cannot select 2 payment types.'.translate(this.props.lang) })
        // } else {
        //     this.setState({ errorSave: null })
        // }
        if (!this.state.name || this.state.name == '') {
            returnValue = false
        }

        this.setState({ checkActiveSave: returnValue })
    }


    checkAllPrintAnswers = () => {
        for (let i = 0; i < this.state.items.length; i++) {
            if (this.state.items[i].type == 1 || this.state.items[i].type == 2) {
                let answers = 0;
                for (let j = 0; j < this.state.items[i].answers.length; j++) {
                    if (!this.state.items[i].answers[j].text) {
                        answers++;
                    }
                }
                if (answers == this.state.items[i].answers.length || answers == 0) {
                    continue;
                } else {
                    return false;
                }
            }
        }

        return true;
    }


    conditionIndexOf = (conditions, conditionQuestion, conditionAnswer) => {
        if (!conditions) {
            return -1;
        }

        for (let i = 0; i < conditions.length; i++) {
            if (!conditionAnswer) {
                if (conditions[i].conditionQuestion == conditionQuestion) {
                    return i;
                }
            } else {
                if (conditions[i].conditionQuestion == conditionQuestion && conditions[i].conditionAnswer == conditionAnswer) {
                    return i;
                }
            }
        }

        return -1;
    }

    getAnswer = (id) => {
        for (let i = 0; i < this.state.items.length; i++) {
            if (this.state.items[i].answers) {
                for (let j = 0; j < this.state.items[i].answers.length; j++) {
                    if (this.state.items[i].answers[j].id == id) {
                        return this.state.items[i].answers[j];
                    }
                }
            }
            if (this.state.items[i].blocks) {
                for (let j = 0; j < this.state.items[i].blocks.length; j++) {
                    if (this.state.items[i].blocks[j].id == id) {
                        return this.state.items[i].blocks[j];
                    }
                }
            }
        }
    }


    getItem = (id) => {
        for (let i = 0; i < this.state.items.length; i++) {
            if (this.state.items[i].id == id) {
                return this.state.items[i];
            }
        }
    }




    checkRelations = (itemId) => {
        let items = this.state.items;

        let itemsDict = {};
        for (let i = 0; i < items.length; i++) {
            itemsDict[items[i].id] = items[i];
        }


        let item = this.getItem(itemId)
        if (!item) {
            return null;
        }


        let upLinks = [];
        let downLinks = [];

        for (let i = 0; i < items.length; i++) {
            if (items[i].conditions) {

                let idx = this.conditionIndexOf(items[i].conditions, itemId, null);
                if (idx != -1) {
                    for (let j = 0; j < items[i].conditions.length; j++) {
                        if (items[i].conditions[j].conditionQuestion == itemId) {

                            downLinks.push({
                                conditionElement: {
                                    id: items[i].conditions[j].conditionQuestion,
                                    answerId: items[i].conditions[j].conditionAnswer,
                                    name: itemsDict[items[i].conditions[j].conditionQuestion].name,
                                    answer: this.getAnswer(items[i].conditions[j].conditionAnswer) && this.getAnswer(items[i].conditions[j].conditionAnswer).name,
                                },
                                element: {
                                    id: items[i].id,
                                    name: items[i].name,
                                    itemIndex: i,
                                    conditionIndex: j
                                }
                            });

                        }
                    }
                }


                if (items[i].id == itemId) {
                    for (let j = 0; j < items[i].conditions.length; j++) {
                        upLinks.push({
                            conditionElement: {
                                id: items[i].conditions[j].conditionQuestion,
                                answerId: items[i].conditions[j].conditionAnswer,
                                name: this.getItem(items[i].conditions[j].conditionQuestion) && this.getItem(items[i].conditions[j].conditionQuestion).name,
                                answer: this.getAnswer(items[i].conditions[j].conditionAnswer) && this.getAnswer(items[i].conditions[j].conditionAnswer).name,
                            },
                            element: {
                                id: item.id,
                                name: item.name,
                                itemIndex: i,
                                conditionIndex: j
                            }
                        })
                    }
                }
            }


        }


        if (!upLinks.length && !downLinks.length) {
            return null;
        } else {
            return [upLinks, downLinks];
        }


    }

    checkAnswerRelations = (itemId, answerId) => {
        let items = this.state.items;

        let itemsDict = {};
        for (let i = 0; i < items.length; i++) {
            itemsDict[items[i].id] = items[i];
        }

        let item = this.getItem(itemId)
        if (!item) {
            return null;
        }


        let upLinks = [];
        let downLinks = [];

        for (let i = 0; i < items.length; i++) {
            if (items[i].conditions) {

                let idx = this.conditionIndexOf(items[i].conditions, itemId, answerId);
                if (idx != -1) {
                    for (let j = 0; j < items[i].conditions.length; j++) {
                        if (items[i].conditions[j].conditionQuestion == itemId && items[i].conditions[j].conditionAnswer == answerId) {

                            downLinks.push({
                                conditionElement: {
                                    id: items[i].conditions[j].conditionQuestion,
                                    answerId: items[i].conditions[j].conditionAnswer,
                                    name: itemsDict[items[i].conditions[j].conditionQuestion].name,
                                    answer: this.getAnswer(items[i].conditions[j].conditionAnswer).name,
                                },
                                element: {
                                    id: items[i].id,
                                    name: items[i].name,
                                    itemIndex: i,
                                    conditionIndex: j
                                }
                            });

                        }
                    }
                }

            }


        }


        if (!upLinks.length && !downLinks.length) {
            return null;
        } else {
            return [upLinks, downLinks];
        }


    }



    generateSections = () => {
        let sections = {};
        let items = [{ type: 0 }, ...this.state.items];
        let lastSectionId = -1;
        for (let i = 0; i < items.length; i++) {
            if (items[i].type == 0) {
                lastSectionId++;
                sections[lastSectionId] = {
                    name: items[i].name,
                    displayOnReferral: false,
                    questions: [],
                    conditions: items[i].conditions ? items[i].conditions : [],
                    displayOnReferral: items[i].displayOnReferral,
                    fontStyle: items[i].fontStyle,
                    fontSize: items[i].fontSize,
                    fontWeight: items[i].fontWeight,

                }
            }
            if (lastSectionId != -1 && items[i].type != 0) {


                let wikiLinks = [];

                items[i].wikiLinks = wikiLinks;


                if (items[i].type == 1) {
                    let displayOnReferral = false;
                    if (items[i].answers) {
                        for (let j = 0; j < items[i].answers.length; j++) {
                            if (items[i].answers[j].text) {
                                displayOnReferral = true;
                            }
                        }
                    }

                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        answers: items[i].answers,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        displayOnReferral: displayOnReferral,
                        wikiLinks: items[i].wikiLinks,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                    });

                } else if (items[i].type == 2) {
                    let displayOnReferral = false;
                    if (items[i].answers) {
                        for (let j = 0; j < items[i].answers.length; j++) {

                            if (items[i].answers[j].text) {
                                displayOnReferral = true;
                            }
                        }
                    }
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        answers: items[i].answers,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        answerPrefix: items[i].answerPrefix,
                        userInput: items[i].userInput,
                        displayOnReferral: displayOnReferral || items[i].answerPrefix ? true : false,
                        wikiLinks: items[i].wikiLinks,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,

                    })
                } else if (items[i].type == 3) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        answerPrefix: items[i].answerPrefix,
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                    })

                } else if (items[i].type == 4) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        text: items[i].text,
                        userInput: items[i].userInput,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                    })
                } else if (items[i].type == 15) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        text: items[i].text,
                        userInput: items[i].userInput,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                    })

                } else if (items[i].type == 5) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                        score: items[i].score
                    })

                } else if (items[i].type == 6) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                        score: items[i].score
                    })

                } else if (items[i].type == 7) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                    })

                } else if (items[i].type == 8) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                    })

                } else if (items[i].type == 9) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                    })

                } else if (items[i].type == 10) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        userInput: items[i].userInput,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                    })
                } else if (items[i].type == 12) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                        score: items[i].score
                    })
                } else if (items[i].type == 14) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                        score: items[i].score
                    })

                } else if (items[i].type == 13) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                        score: items[i].score
                    })

                } else if (items[i].type == 16) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                        blocks: items[i].blocks,
                    })

                } else if (items[i].type == 17) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                        blocks: items[i].blocks,

                    })

                }
            }
        }

        return Object.values(sections);
    }



    checkBlocksRange = () => {
        let items = this.state.items;
        if (items && items.length) {
            let blocks = [];
            let min = 0;
            let max = 0;
            let minTotal = 0;
            let maxTotal = 0;
            for (let i = 0; i < items.length; i++) {
                let item = items[i];
                if (item.type == 16 || item.type == 17) {
                    if (blocks.filter(el => el.id == item.id).length == 0) {
                        let obj = {
                            id: item.id,
                            min: item.type == 16 ? min : minTotal,
                            max: item.type == 16 ? max : maxTotal
                        }
                        blocks.push(obj)
                        min = 0;
                        max = 0;
                    }
                } else {
                    if ((item.type == 1 || item.type == 2) && item.answers && item.answers.length) {
                        let checkMin = 100;
                        let checkMax = 0;
                        for (let j = 0; j < item.answers.length; j++) {
                            let score = item.answers[j].score ? item.answers[j].score : 0;

                            if (item.type == 1) {
                                if (score > checkMax) {
                                    checkMax = score;
                                }
                            } else if (item.type == 2) {
                                checkMax += score;
                            }
                            if (score < checkMin) {
                                checkMin = score;
                            }
                        }

                        if (checkMin < min && checkMin >= 0) {
                            min = checkMin;
                        } else if (checkMin < 0) {
                            min += checkMin;
                        }

                        max += checkMax;
                        if (checkMin < minTotal && checkMin >= 0) {
                            minTotal = checkMin;
                        } else if (checkMin < 0) {
                            minTotal += checkMin;
                        }
                        maxTotal += checkMax;
                    }
                    if ((item.type == 5 || item.type == 6 || item.type == 12 || item.type == 13 || item.type == 14) && item.score) {
                        if (item.score < min && item.score >= 0) {
                            min = item.score;
                        } else if (item.score < 0) {
                            min += item.score;
                        }

                        if (item.score < minTotal && item.score >= 0) {
                            minTotal = item.score;
                        } else if (item.score < 0) {
                            minTotal += item.score;
                        }
                        if (item.score > 0) {
                            max += item.score;
                            maxTotal += item.score;
                        }


                    }

                }
            }
            for (let i = 0; i < blocks.length; i++) {
                let min = blocks[i].min;
                let max = blocks[i].max;
                for (let j = 0; j < items.length; j++) {
                    if (items[j].id == blocks[i].id) {
                        let checkBlocks = items[j].blocks;
                        if (checkBlocks && checkBlocks.length) {
                            let newBlocks = [];
                            for (let b = 0; b < checkBlocks.length; b++) {
                                let push = true;
                                if (checkBlocks[b].start < min && checkBlocks[b].end >= min) {
                                    checkBlocks[b].start = min;
                                } else if (checkBlocks[b].end < min) {
                                    push = false;
                                }

                                if (checkBlocks[b].end > max && checkBlocks[b].start <= max) {
                                    checkBlocks[b].end = max;
                                } else if (checkBlocks[b].start > max) {
                                    push = false;
                                }

                                if (push) {
                                    newBlocks.push(checkBlocks[b])
                                }
                            }
                            items[j].blocks = newBlocks;
                        }

                    }
                }
                this.setState({ items })
            }


            this.setState({ blocksRange: blocks })
        }

    }




    render() {
        let params = {};
        if (this.state.useState) {
            params = this.state.params;
        } else {
            params = getSearchParams(this.props[0].location.search, { entries: 10, page: 0 })
        }

        let selectedGroupIdx = 0;
        if (this.props.selectedGroup && this.props.uData?.clinicGroups?.length) {
            for (let i = 0; i < this.props.uData.clinicGroups.length; i++) {
                if (this.props.uData.clinicGroups[i]._id == this.props.selectedGroup) {
                    selectedGroupIdx = i;
                    break;
                }
            }
        }
        let hasOnlinePaymentInClinic = (this.props.uData.clinicGroups[selectedGroupIdx] && ((this.props.uData.clinicGroups[selectedGroupIdx].apiKey && this.props.uData.clinicGroups[selectedGroupIdx].agentId) || this.props.uData.clinicGroups[selectedGroupIdx].bankAccount || this.props.uData.clinicGroups[selectedGroupIdx].stripeAccountID)) ? true : false;

        let canTransferPayments = this.props.uData && this.props.uData.clinicGroups && this.props.uData.clinicGroups[selectedGroupIdx] && this.props.uData.clinicGroups[selectedGroupIdx].canTransferPayments;
        const selectedGroupData = this.props.uData && this.props.uData.clinicGroups && this.props.uData.clinicGroups[selectedGroupIdx] && this.props.uData.clinicGroups[selectedGroupIdx];
        const linksToForms = selectedGroupData?.linksToForms;

        let articlesDict = {};
        for (let i = 0; i < this.state.articles.length; i++) {
            articlesDict[this.state.articles[i]._id] = this.state.articles[i];
        }

        const bmiIndex = ['#9ABBDA', '#93CCA9', '#FFE02B', '#EAA353', '#D8414A'];

        const currency = this.props.currency ? this.props.currency : typeof window !== 'undefined' && localStorage?.getItem("currency") ? localStorage.getItem("currency") : '';
        return (
            <div className={this.props.autoHideSidebar ? "dashboard dashboard-auto-hide" : "dashboard"}>

                <Container fluid>
                    <Row>

                        <Col lg="12">
                            <div className="panel">
                                <div className='forms-builder-title space-between'>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <h4>{this.props[0].match.params.id == 'new' ? 'Add questionary'.translate(this.props.lang) : 'Edit questionary'.translate(this.props.lang)}</h4>
                                        <Button color='primary' onClick={() => {
                                            if (this.state.detectedChage) {
                                                this.setState({ notSavedModal: true })
                                            } else {
                                                let obj = {}
                                                if (this.props[0].location.state && this.props[0].location.state.redirectFrom) {
                                                    obj = this.props[0].location.state.redirectFrom;
                                                }
                                                // obj.tabIndex = 1

                                                this.props[0].history.push({
                                                    pathname: '/personal-referrals/list',
                                                    state: { initialState: obj }
                                                })
                                            }

                                        }}><Isvg src={backIcon} /></Button>
                                    </div>
                                    <div className='copy-link-to-form-wrap'>
                                        {
                                            linksToForms && this.props[0].match.params.id !== 'new' ?
                                                <Button color='primary' onClick={() => {
                                                    this.setState({ showCopyPlayer: true }, () => {
                                                        // copy link
                                                        let link;
                                                        if (typeof window !== 'undefined') {
                                                            let endpoint = window.origin;
                                                            let alias = selectedGroupData?.alias;
                                                            let formId = this.props[0].match.params.id?.length === 24 ? this.props[0].match.params.id : null;

                                                            if (endpoint && alias && formId) {
                                                                link = `${endpoint}/${alias}/forms/${formId}`;
                                                                navigator.clipboard.writeText(link)
                                                            }
                                                        }
                                                        //
                                                        if (link) {
                                                            this.setState({ showCopyPlayer: false, showCopyPlayer2: true }, () => {
                                                                setTimeout(() => {
                                                                    this.setState({ showCopyPlayer2: false })
                                                                }, 2000);
                                                            })
                                                        } else {
                                                            this.setState({ showCopyPlayer: false, showCopyPlayer3: true }, () => {
                                                                setTimeout(() => {
                                                                    this.setState({ showCopyPlayer3: false })
                                                                }, 2000);
                                                            })
                                                        }

                                                    })

                                                }}>
                                                    {
                                                        this.state.showCopyPlayer ? (
                                                            <Player
                                                                onEvent={(event) => {
                                                                    if (event === "load" && this.player && this.player.current) this.stopAnimation();
                                                                }}
                                                                onStateChange={this.toggleVisibility}
                                                                ref={this.player} // set the ref to your class instance
                                                                autoplay={true}
                                                                loop={true}
                                                                controls={true}
                                                                src="https://assets8.lottiefiles.com/packages/lf20_ZSRKCF.json"
                                                                style={{ height: "30px", width: "30px" }}
                                                            ></Player>

                                                        ) : this.state.showCopyPlayer2 ? (
                                                            <div style={{ display: 'flex', alignItems: 'center' }}><Player
                                                                onStateChange={this.toggleVisibility}
                                                                ref={this.player2} // set the ref to your class instance
                                                                autoplay={true}
                                                                loop={true}
                                                                controls={true}
                                                                src="https://assets10.lottiefiles.com/packages/lf20_CxyxLk.json"
                                                                style={{ height: "30px", width: "30px" }}
                                                            ></Player>
                                                                {'Link copied to the clipboard'.translate(this.props.lang)}
                                                            </div>
                                                        ) :
                                                            this.state.showCopyPlayer3 ? (
                                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                    {/* <Player
                                                                        onStateChange={this.toggleVisibility}
                                                                        ref={this.player2} // set the ref to your class instance
                                                                        autoplay={true}
                                                                        loop={true}
                                                                        controls={true}
                                                                        src="https://assets10.lottiefiles.com/packages/lf20_CxyxLk.json"
                                                                        style={{ height: "30px", width: "30px" }}
                                                                    ></Player> */}
                                                                    {'Could not copy to clipboard!'.translate(this.props.lang)}
                                                                </div>
                                                            ) :
                                                                ('Generate link'.translate(this.props.lang))
                                                    }
                                                </Button>
                                                :
                                                null
                                        }
                                    </div>
                                </div>

                                <Row>
                                    {
                                        this.state.duplicateBlock ?
                                            <InfoModal
                                                isOpen={this.state.duplicateBlock}
                                                onClose={(item) => {
                                                    this.setState({
                                                        duplicateBlock: item
                                                    })
                                                }}
                                                toggle={() => this.setState({ duplicateBlock: !this.state.duplicateBlock })}
                                                header={'Warning'.translate(this.props.lang)}
                                                info={this.state.duplicateBlock}
                                                buttons={[

                                                    <Button color='primary' onClick={() => {
                                                        this.setState({ duplicateBlock: null })
                                                    }}>{'Ok'.translate(this.props.lang)}</Button>


                                                ]}
                                            />
                                            :
                                            null
                                    }

                                    <Col lg="6">
                                        <FormGroup>
                                            <Label>{'Name'.translate(this.props.lang)}*</Label>
                                            <Input size="sm" value={this.state.name} onChange={(e) => this.setState({ name: e.target.value }, () => {
                                                this.checkActiveSave()
                                                this.detectChange()
                                            })} />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label>{'Description'.translate(this.props.lang)}</Label>
                                            <Input size="sm" type="textarea" className="questionary-description" value={this.state.description} onChange={(e) => this.setState({ description: e.target.value }, () => this.detectChange())} />
                                        </FormGroup>


                                        {
                                            this.props.uData.userLevel != 100 && this.props.uData.userLevel != 100 ?

                                                <FormGroup>
                                                    <Label>{'Ways to receive the patient'.translate(this.props.lang)}</Label>
                                                    <Input type='select'

                                                        onChange={(e) => {
                                                            this.setState({ wayOfScheduling: e.target.value }, () => {
                                                                this.detectChange()

                                                                if (this.state.wayOfScheduling == 'webBooking' || this.state.wayOfScheduling == 'dropIn') {
                                                                    if (this.state.price == 0) {
                                                                        this.setState({
                                                                            freeOfCharge: true,
                                                                            paymentOnSite: false,
                                                                            invoice: false,
                                                                            onlinePayment: false,
                                                                            paymentBeforeBookedTime: false,
                                                                            paymentAfterBookedTime: false,
                                                                            paymentBeforeSubmission: false,
                                                                            paymentAfterSubmission: false,
                                                                            onlineVisit: this.state.wayOfScheduling === 'dropIn' ? true : this.state.onlineVisit
                                                                        })

                                                                    } else {
                                                                        if (!hasOnlinePaymentInClinic) {
                                                                            this.setState({
                                                                                onlineVisit: false,
                                                                                videoCall: false,
                                                                            }
                                                                                // , () => {
                                                                                //     if (this.state.name && this.state.name.indexOf(' (online visit)') != -1) {
                                                                                //         this.setState({
                                                                                //             name: this.state.name.replace(' (online visit)', '')
                                                                                //         })
                                                                                //     }
                                                                                //     if (this.state.name && this.state.name.indexOf(' (onlinebesök)') != -1) {
                                                                                //         this.setState({
                                                                                //             name: this.state.name.replace(' (onlinebesök)', '')
                                                                                //         })
                                                                                //     }
                                                                                // }
                                                                            )
                                                                        }
                                                                        this.setState({
                                                                            paymentBeforeBookedTime: false,
                                                                            paymentAfterSubmission: this.state.wayOfScheduling === 'dropIn' && !hasOnlinePaymentInClinic ? true : false,
                                                                            paymentAfterBookedTime: false,
                                                                            paymentBeforeSubmission: this.state.wayOfScheduling === 'dropIn' && !hasOnlinePaymentInClinic ? false : true,
                                                                            invoice: this.state.wayOfScheduling === 'dropIn' && hasOnlinePaymentInClinic ? false : this.state.invoice,
                                                                            onlinePayment: this.state.wayOfScheduling === 'dropIn' ? true : false,
                                                                            onlineVisit: this.state.wayOfScheduling === 'dropIn' ? true : this.state.onlineVisit,

                                                                        })
                                                                    }
                                                                    if (this.state.wayOfScheduling === 'dropIn') {
                                                                        if (this.state.price != 0) {
                                                                            this.setState({
                                                                                nameBonus: this.props.lang == 'en' ? ' (online visit)' : ' (onlinebesök)',


                                                                            }, () => {
                                                                                this.setState({
                                                                                    name: this.state.name /* + this.state.nameBonus*/
                                                                                })
                                                                            })
                                                                        } else if (this.state.price == 0) {
                                                                            this.setState({
                                                                                nameBonus: this.props.lang == 'en' ? ' (online visit)' : ' (onlinebesök)',

                                                                            }, () => {
                                                                                this.setState({
                                                                                    name: this.state.name /* + this.state.nameBonus*/
                                                                                })
                                                                            })
                                                                        }
                                                                    }
                                                                } else if (this.state.wayOfScheduling == 'bookingByClinic') {
                                                                    if (this.state.price == 0) {
                                                                        this.setState({
                                                                            freeOfCharge: true,
                                                                            paymentOnSite: false,
                                                                            invoice: false,
                                                                            onlinePayment: false
                                                                        })
                                                                    } else if (this.state.price > 0) {
                                                                        if (!hasOnlinePaymentInClinic) {
                                                                            this.setState({
                                                                                onlineVisit: false,
                                                                                videoCall: false,
                                                                            }
                                                                                // , () => {
                                                                                //     if (this.state.name && this.state.name.indexOf(' (online visit)') != -1) {
                                                                                //         this.setState({
                                                                                //             name: this.state.name.replace(' (online visit)', '')
                                                                                //         })
                                                                                //     }
                                                                                //     if (this.state.name && this.state.name.indexOf(' (onlinebesök)') != -1) {
                                                                                //         this.setState({
                                                                                //             name: this.state.name.replace(' (onlinebesök)', '')
                                                                                //         })
                                                                                //     }
                                                                                // }
                                                                            )
                                                                        }
                                                                        if (!this.state.paymentAfterBookedTime && !this.state.paymentBeforeBookedTime && !this.state.paymentBeforeSubmission) {
                                                                            this.setState({ paymentBeforeBookedTime: true }, () => {
                                                                                this.setState({
                                                                                    paymentOnSite: false,
                                                                                    invoice: (!this.state.invoice && !(this.state.onlinePayment && hasOnlinePaymentInClinic)) || this.state.invoice ? true : false
                                                                                })
                                                                            })
                                                                        }
                                                                    }
                                                                } else {
                                                                    if (!this.state.onlineVisit) {
                                                                        if (this.state.price != 0) {
                                                                            this.setState({
                                                                                nameBonus: this.props.lang == 'en' ? ' (online visit)' : ' (onlinebesök)',
                                                                                paymentOnSite: false,
                                                                                invoice: false,
                                                                                onlinePayment: true,

                                                                            }, () => {
                                                                                this.setState({
                                                                                    name: this.state.name /* + this.state.nameBonus*/
                                                                                })
                                                                            })
                                                                        } else if (this.state.price == 0) {
                                                                            this.setState({
                                                                                nameBonus: this.props.lang == 'en' ? ' (online visit)' : ' (onlinebesök)',
                                                                                paymentOnSite: false,
                                                                                invoice: false,
                                                                                freeOfCharge: true
                                                                            }, () => {
                                                                                this.setState({
                                                                                    name: this.state.name /* + this.state.nameBonus*/
                                                                                })
                                                                            })
                                                                        }
                                                                    }

                                                                    this.setState({ onlineVisit: true })

                                                                    if (this.state.price == 0) {
                                                                        this.setState({
                                                                            freeOfCharge: true,
                                                                            paymentOnSite: false,
                                                                            invoice: false,
                                                                            onlinePayment: false,
                                                                            paymentBeforeBookedTime: false,
                                                                            paymentAfterBookedTime: false
                                                                        })
                                                                    } else {
                                                                        this.setState({
                                                                            // nameBonus: this.props.lang == 'en' ? ' (online visit)' : ' (onlinebesök)',
                                                                            paymentOnSite: false,
                                                                            invoice: false,
                                                                            onlinePayment: true,

                                                                        }, () => {
                                                                            // this.setState({
                                                                            //     name: this.state.name + this.state.nameBonus
                                                                            // })
                                                                        })


                                                                    }

                                                                }
                                                            })

                                                        }}
                                                        value={this.state.wayOfScheduling}
                                                    >
                                                        <option value={'bookingByClinic'}>{'Booking by clinic'.translate(this.props.lang)}</option>
                                                        <option value={'dropIn'}>{'Drop in'.translate(this.props.lang)}</option>
                                                        <option value={'webBooking'}>{'Web booking'.translate(this.props.lang)}</option>

                                                    </Input>

                                                </FormGroup>
                                                :
                                                null
                                        }

                                        {
                                            this.props.uData.userLevel != 100 && this.state.wayOfScheduling == 'webBooking' && this.props.uData.userLevel != 100 ?
                                                <FormGroup>
                                                    <Label>{'Meeting length'.translate(this.props.lang)}</Label>
                                                    <Input type='select' style={{ width: 200 }}
                                                        onChange={(e) => this.setState({ duration: e.target.value }, () => this.detectChange())}
                                                        value={this.state.duration}
                                                    >


                                                        <option value={-1}>{'None'.translate(this.props.lang)}</option>

                                                        <option value={5}>{'5min'.translate(this.props.lang)}</option>
                                                        <option value={10}>{'10min'.translate(this.props.lang)}</option>
                                                        <option value={15}>{'15min'.translate(this.props.lang)}</option>
                                                        <option value={20}>{'20min'.translate(this.props.lang)}</option>
                                                        <option value={25}>{'25min'.translate(this.props.lang)}</option>
                                                        <option value={30}>{'30min'.translate(this.props.lang)}</option>
                                                        <option value={35}>{'35min'.translate(this.props.lang)}</option>
                                                        <option value={40}>{'40min'.translate(this.props.lang)}</option>
                                                        <option value={45}>{'45min'.translate(this.props.lang)}</option>
                                                        <option value={50}>{'50min'.translate(this.props.lang)}</option>
                                                        <option value={55}>{'55min'.translate(this.props.lang)}</option>
                                                        <option value={60}>{'60min'.translate(this.props.lang)}</option>
                                                        <option value={65}>{'65min'.translate(this.props.lang)}</option>
                                                        <option value={70}>{'70min'.translate(this.props.lang)}</option>
                                                        <option value={75}>{'75min'.translate(this.props.lang)}</option>
                                                        <option value={80}>{'80min'.translate(this.props.lang)}</option>
                                                        <option value={85}>{'85min'.translate(this.props.lang)}</option>
                                                        <option value={90}>{'90min'.translate(this.props.lang)}</option>

                                                    </Input>
                                                </FormGroup>
                                                :
                                                null
                                        }
                                        {
                                            this.props.uData.userLevel != 100 && this.state.wayOfScheduling == 'webBooking' && this.props.uData.userLevel != 100 && !this.state.groupBookingSettings?.global ?
                                                <FormGroup>
                                                    <WebBookingOffset
                                                        lang={this.props.lang}
                                                        value={this.state.freeEventsStartOffset}
                                                        labelInfo={'Time-delay description'.translate(this.props.lang)}
                                                        onChange={(value) => {
                                                            this.setState({ freeEventsStartOffset: value }, () => this.detectChange())
                                                        }}
                                                    />
                                                </FormGroup>
                                                :
                                                null
                                        }



                                        <FormGroup>
                                            <div >
                                                <Checkbox checked={this.state.intelligentReferral} onChange={(e) => {
                                                    this.setState({
                                                        intelligentReferral: e.target.checked
                                                    }, () => {
                                                        this.detectChange()
                                                    })
                                                }} label={'Intelligent form builder'.translate(this.props.lang)} />
                                            </div>
                                        </FormGroup>
                                        {this.props.uData.userLevel != 100 ?
                                            <FormGroup>
                                                <div >
                                                    <Checkbox
                                                        disabled={/*!(this.state.freeOfCharge || hasOnlinePaymentInClinic) || */ this.state.wayOfScheduling == 'dropIn' || (!this.state.freeOfCharge && !hasOnlinePaymentInClinic && (this.state.wayOfScheduling === 'bookingByClinic' || this.state.wayOfScheduling === 'webBooking') && this.state.paymentBeforeSubmission)}
                                                        checked={this.state.onlineVisit || this.state.wayOfScheduling === 'dropIn'}
                                                        onChange={(e) => {
                                                            this.detectChange()
                                                            // if ((this.state.freeOfCharge || this.props.uData.clinicGroups[selectedGroupIdx] && ((this.props.uData.clinicGroups[selectedGroupIdx].apiKey && this.props.uData.clinicGroups[selectedGroupIdx].agentId) || this.props.uData.clinicGroups[selectedGroupIdx].bankAccount || this.props.uData.clinicGroups[selectedGroupIdx].stripeAccountID)) && this.state.wayOfScheduling != 'dropIn') {
                                                            if (!(this.state.wayOfScheduling == 'dropIn' || (!this.state.freeOfCharge && !hasOnlinePaymentInClinic && (this.state.wayOfScheduling === 'bookingByClinic' || this.state.wayOfScheduling === 'webBooking') && this.state.paymentBeforeSubmission))) {
                                                                this.setState({
                                                                    onlineVisit: e.target.checked
                                                                }, () => {
                                                                    if (!this.state.onlineVisit) {
                                                                        this.setState({
                                                                            nameBonus: '',
                                                                            videoCall: false
                                                                        }, () => {
                                                                            // if (this.state.name && this.state.name.indexOf(' (online visit)') != -1) {
                                                                            //     this.setState({
                                                                            //         name: this.state.name.replace(' (online visit)', '')
                                                                            //     })
                                                                            // }
                                                                            // if (this.state.name && this.state.name.indexOf(' (onlinebesök)') != -1) {
                                                                            //     this.setState({
                                                                            //         name: this.state.name.replace(' (onlinebesök)', '')
                                                                            //     })
                                                                            // }

                                                                        })
                                                                    }
                                                                    if (this.state.onlineVisit && hasOnlinePaymentInClinic && this.state.price != 0) {
                                                                        this.setState({
                                                                            nameBonus: this.props.lang == 'en' ? ' (online visit)' : ' (onlinebesök)',
                                                                            paymentOnSite: false,
                                                                            onlinePayment: true,

                                                                        }, () => {
                                                                            this.setState({
                                                                                name: this.state.name /*+ this.state.nameBonus*/
                                                                            })
                                                                        })
                                                                    } else if (this.state.onlineVisit && hasOnlinePaymentInClinic && this.state.price == 0) {
                                                                        this.setState({
                                                                            nameBonus: this.props.lang == 'en' ? ' (online visit)' : ' (onlinebesök)',
                                                                            paymentOnSite: false,
                                                                            invoice: false,
                                                                            freeOfCharge: true
                                                                        }, () => {
                                                                            this.setState({
                                                                                name: this.state.name /*+ this.state.nameBonus*/
                                                                            })
                                                                        })
                                                                    }
                                                                })
                                                            }

                                                        }} label={'Online visit'.translate(this.props.lang)} />
                                                </div>
                                            </FormGroup>
                                            : null}
                                        {this.state.onlineVisit ?
                                            <FormGroup>
                                                <div >
                                                    <Checkbox
                                                        checked={this.state.videoCall}
                                                        onChange={(e) => {
                                                            this.detectChange()
                                                            this.setState({
                                                                videoCall: e.target.checked
                                                            })

                                                        }} label={'Video call'.translate(this.props.lang)} />
                                                </div>
                                            </FormGroup>
                                            :
                                            null
                                        }

                                        {/* <FormGroup>
                                            <div >
                                                <Checkbox checked={this.state.saveFormular} onChange={(e) => {
                                                    this.setState({
                                                        saveFormular: e.target.checked
                                                    }, () => {
                                                        this.detectChange()
                                                    })
                                                }} label={'Save formular'.translate(this.props.lang)} />
                                            </div>
                                        </FormGroup> */}

                                        {
                                            this.props.uData.userLevel != 100 ?
                                                <FormGroup style={{ display: 'flex', alignItems: 'center' }}>
                                                    <Button style={{ marginLeft: 0, padding: '10px 22px' }} onClick={() => this.setState({
                                                        professionsModal: true
                                                    })} color="primary">
                                                        {'Select group(s)'.translate(this.props.lang)}
                                                    </Button>
                                                    {
                                                        this.state.professionList && this.state.professionList.length == 0 ?
                                                            <p style={{ marginBottom: 0, marginLeft: '15px', fontSize: 15, maxWidth: "65%", color: 'red' }}>{"You must select at least one profession to activate this form.".translate(this.props.lang)}</p>
                                                            :
                                                            null
                                                    }
                                                </FormGroup>
                                                :
                                                null
                                        }




                                    </Col>
                                    {this.props.uData.userLevel != 100 ?
                                        <Col lg='6'>
                                            <FormGroup>
                                                <Label>{'Price'.translate(this.props.lang)}</Label>
                                                <Input size="sm" type="text" value={this.state.price} onChange={(e) => {
                                                    this.detectChange()
                                                    let val = e.target.value.replace(/[^\d]/g, '')

                                                    this.setState({ price: Number(val) }, () => {
                                                        if (this.state.price == 0) {
                                                            if (this.state.wayOfScheduling == 'webBooking') {
                                                                this.setState({
                                                                    freeOfCharge: true,
                                                                    paymentOnSite: false,
                                                                    invoice: false,
                                                                    onlinePayment: false,
                                                                    vatRate: -1,
                                                                    selectVatRate: false
                                                                })
                                                            } else if (this.state.wayOfScheduling == 'bookingByClinic') {
                                                                this.setState({
                                                                    paymentBeforeBookedTime: false,
                                                                    paymentAfterBookedTime: false,
                                                                    freeOfCharge: true,
                                                                    paymentOnSite: false,
                                                                    invoice: false,
                                                                    onlinePayment: false,
                                                                    vatRate: -1,
                                                                    selectVatRate: false
                                                                })
                                                            } else if (this.state.wayOfScheduling == 'dropIn') {
                                                                this.setState({
                                                                    paymentBeforeBookedTime: false,
                                                                    paymentAfterBookedTime: false,
                                                                    freeOfCharge: true,
                                                                    paymentOnSite: false,
                                                                    invoice: false,
                                                                    vatRate: -1,
                                                                    selectVatRate: false
                                                                })
                                                            }

                                                        } else if (this.state.price > 0) {
                                                            if (this.state.wayOfScheduling == 'webBooking') {
                                                                if (this.state.onlineVisit && hasOnlinePaymentInClinic) {
                                                                    this.setState({
                                                                        paymentOnSite: false,
                                                                        invoice: false,
                                                                        onlinePayment: true,

                                                                    })
                                                                }
                                                                // else if (!this.state.paymentOnSite && !this.state.invoice && !(this.state.onlinePayment && hasOnlinePaymentInClinic)) {
                                                                //     this.setState({
                                                                //         paymentOnSite: true,
                                                                //     })
                                                                // }
                                                                if (!hasOnlinePaymentInClinic) {
                                                                    this.setState({
                                                                        onlineVisit: false,
                                                                        videoCall: false,
                                                                    }, () => {
                                                                        if (this.state.name && this.state.name.indexOf(' (online visit)') != -1) {
                                                                            this.setState({
                                                                                name: this.state.name.replace(' (online visit)', '')
                                                                            })
                                                                        }
                                                                        if (this.state.name && this.state.name.indexOf(' (onlinebesök)') != -1) {
                                                                            this.setState({
                                                                                name: this.state.name.replace(' (onlinebesök)', '')
                                                                            })
                                                                        }
                                                                    })
                                                                }

                                                                if (!this.state.paymentAfterSubmission && !this.state.paymentBeforeSubmission) {
                                                                    this.setState({ paymentBeforeSubmission: true }, () => {
                                                                        if (!this.state.freeOfCharge && !this.state.paymentOnSite && !this.state.invoice && !(this.state.onlinePayment && hasOnlinePaymentInClinic)) {
                                                                            this.setState({ invoice: true })
                                                                        }
                                                                    })
                                                                }

                                                            } else if (this.state.wayOfScheduling == 'bookingByClinic' && !this.state.paymentBeforeBookedTime && !this.state.paymentAfterBookedTime && !this.state.paymentBeforeSubmission) {
                                                                if (this.state.onlineVisit && hasOnlinePaymentInClinic) {
                                                                    this.setState({
                                                                        paymentOnSite: false,
                                                                        invoice: false,
                                                                        onlinePayment: true,

                                                                    })
                                                                } else if (!this.state.paymentOnSite && !this.state.invoice && !(this.state.onlinePayment && hasOnlinePaymentInClinic)) {
                                                                    this.setState({
                                                                        invoice: true,

                                                                    })
                                                                }
                                                                if (!hasOnlinePaymentInClinic) {
                                                                    this.setState({
                                                                        onlineVisit: false,
                                                                        videoCall: false,
                                                                    }, () => {
                                                                        if (this.state.name && this.state.name.indexOf(' (online visit)') != -1) {
                                                                            this.setState({
                                                                                name: this.state.name.replace(' (online visit)', '')
                                                                            })
                                                                        }
                                                                        if (this.state.name && this.state.name.indexOf(' (onlinebesök)') != -1) {
                                                                            this.setState({
                                                                                name: this.state.name.replace(' (onlinebesök)', '')
                                                                            })
                                                                        }
                                                                    })
                                                                }
                                                                this.setState({ paymentBeforeBookedTime: true, paymentTypeBookedTime: true })

                                                            } else if (this.state.wayOfScheduling == 'dropIn') {
                                                                if (this.state.onlineVisit && hasOnlinePaymentInClinic) {
                                                                    this.setState({
                                                                        paymentOnSite: false,
                                                                        invoice: false,
                                                                        onlinePayment: true,
                                                                        paymentBeforeSubmission: true

                                                                    })
                                                                } else if (!this.state.paymentOnSite && !this.state.invoice && !(this.state.onlinePayment && hasOnlinePaymentInClinic)) {
                                                                    this.setState({
                                                                        invoice: true,
                                                                        paymentAfterSubmission: true

                                                                    })
                                                                }
                                                                // if (!hasOnlinePaymentInClinic) {
                                                                //     this.setState({
                                                                //         onlineVisit: false,
                                                                //     }, () => {
                                                                //         if (this.state.name && this.state.name.indexOf(' (online visit)') != -1) {
                                                                //             this.setState({
                                                                //                 name: this.state.name.replace(' (online visit)', '')
                                                                //             })
                                                                //         }
                                                                //         if (this.state.name && this.state.name.indexOf(' (onlinebesök)') != -1) {
                                                                //             this.setState({
                                                                //                 name: this.state.name.replace(' (onlinebesök)', '')
                                                                //             })
                                                                //         }
                                                                //     })
                                                                // }
                                                                // this.setState({ paymentBeforeBookedTime: true, paymentTypeBookedTime: true })

                                                            }
                                                        }
                                                    })

                                                    if (e.target.value != 0) {
                                                        if (!hasOnlinePaymentInClinic) {
                                                            this.setState({
                                                                onlineVisit: false,
                                                                videoCall: false,
                                                            }, () => {
                                                                if (this.state.name && this.state.name.indexOf(' (online visit)') != -1) {
                                                                    this.setState({
                                                                        name: this.state.name.replace(' (online visit)', '')
                                                                    })
                                                                }
                                                                if (this.state.name && this.state.name.indexOf(' (onlinebesök)') != -1) {
                                                                    this.setState({
                                                                        name: this.state.name.replace(' (onlinebesök)', '')
                                                                    })
                                                                }
                                                            })
                                                        }
                                                        this.setState({
                                                            freeOfCharge: false
                                                        })
                                                    }
                                                }} />
                                            </FormGroup>

                                            {
                                                canTransferPayments ?
                                                    <FormGroup className='select-vat-rate-wrap'>
                                                        <Checkbox
                                                            checked={this.state.selectVatRate}
                                                            disabled={this.state.price && this.state.price > 0 ? false : true}
                                                            onChange={(e) => {
                                                                if (this.state.price && this.state.price > 0) {
                                                                    this.detectChange()
                                                                    let setObj = {
                                                                        selectVatRate: e.target.checked
                                                                    }
                                                                    if (!e.target.checked) {
                                                                        setObj.vatRate = -1;
                                                                    }
                                                                    this.setState(setObj, () => this.checkActiveSave())
                                                                }

                                                            }}
                                                            label={'Select VAT rate'.translate(this.props.lang)}
                                                            labelInfo={'If you do not select a VAT rate, the fee is VAT-free'.translate(this.props.lang)}
                                                        />
                                                    </FormGroup>
                                                    :
                                                    null
                                            }
                                            {
                                                canTransferPayments && this.state.selectVatRate ?
                                                    <FormGroup>
                                                        {/* <Label>{'Meeting length'.translate(this.props.lang)}</Label> */}
                                                        <Input type='select' style={{ width: 200 }}
                                                            onChange={(e) => this.setState({ vatRate: e.target.value }, () => this.detectChange())}
                                                            value={this.state.vatRate}
                                                        >
                                                            <option value={-1}>{'VAT-free'.translate(this.props.lang)}</option>
                                                            <option value={6}>{'6%'.translate(this.props.lang)}</option>
                                                            <option value={12}>{'12%'.translate(this.props.lang)}</option>
                                                            <option value={25}>{'25%'.translate(this.props.lang)}</option>


                                                        </Input>
                                                    </FormGroup>
                                                    :
                                                    null
                                            }



                                            <FormGroup>

                                                {this.state.wayOfScheduling == 'bookingByClinic' && this.state.price > 0 ?
                                                    <div>
                                                        <Label>{'Payment type:'.translate(this.props.lang)}</Label>

                                                        <Checkbox
                                                            checked={this.state.paymentBeforeSubmission}
                                                            onChange={(e) => {
                                                                this.detectChange()

                                                                if (!hasOnlinePaymentInClinic && !this.state.paymentBeforeSubmission) {
                                                                    this.setState({
                                                                        onlineVisit: false,
                                                                    })

                                                                }

                                                                if (this.state.price > 0 && !this.state.paymentBeforeSubmission) {
                                                                    if (e.target.checked) {
                                                                        this.setState({
                                                                            paymentTypeBookedTime: true,
                                                                            paymentBeforeBookedTime: false,
                                                                            paymentAfterBookedTime: false,
                                                                            paymentOnSite: false
                                                                        }, () => {
                                                                            if (!this.state.paymentOnSite && !this.state.invoice && !(this.state.onlinePayment && hasOnlinePaymentInClinic)) {
                                                                                this.setState({ invoice: true })
                                                                            }
                                                                        })
                                                                    }
                                                                    this.setState({
                                                                        paymentBeforeSubmission: e.target.checked
                                                                    }, () => this.checkActiveSave())

                                                                }


                                                            }}
                                                            label={'Payment before submission'.translate(this.props.lang)}
                                                            tooltip={'Payment must be completed before submission or request is deleted'.translate(this.props.lang)}
                                                        />
                                                        <Checkbox
                                                            checked={this.state.paymentBeforeBookedTime}
                                                            onChange={(e) => {
                                                                this.detectChange()
                                                                if (this.state.price > 0 && !this.state.paymentBeforeBookedTime) {
                                                                    if (e.target.checked) {
                                                                        this.setState({
                                                                            paymentTypeBookedTime: true,
                                                                            paymentAfterBookedTime: false,
                                                                            paymentBeforeSubmission: false,
                                                                            paymentOnSite: false
                                                                        }, () => {
                                                                            if (!this.state.paymentOnSite && !this.state.invoice && !(this.state.onlinePayment && hasOnlinePaymentInClinic)) {
                                                                                this.setState({ invoice: true })
                                                                            }
                                                                        })
                                                                    }
                                                                    this.setState({
                                                                        paymentBeforeBookedTime: e.target.checked
                                                                    }, () => this.checkActiveSave())

                                                                }


                                                            }}
                                                            label={'Payment before approval'.translate(this.props.lang)}
                                                            tooltip={'Payment must be completed before case can be approved and scheduled'.translate(this.props.lang)}
                                                        />
                                                        <Checkbox
                                                            checked={this.state.paymentAfterBookedTime}
                                                            onChange={(e) => {
                                                                this.detectChange()
                                                                if (this.state.price > 0 && !this.state.paymentAfterBookedTime) {
                                                                    if (e.target.checked) {
                                                                        this.setState({
                                                                            paymentTypeBookedTime: true,
                                                                            paymentBeforeBookedTime: false,
                                                                            paymentBeforeSubmission: false

                                                                        })
                                                                    }
                                                                    this.setState({
                                                                        paymentAfterBookedTime: e.target.checked
                                                                    }, () => this.checkActiveSave())
                                                                }


                                                            }}
                                                            label={'Payment after booked time'.translate(this.props.lang)}
                                                            tooltip={'Payment is made after case has been approved'.translate(this.props.lang)}
                                                        />


                                                    </div>

                                                    : null}
                                                {(this.state.wayOfScheduling == 'webBooking' || this.state.wayOfScheduling == 'dropIn') && this.state.price > 0 ?
                                                    <div>
                                                        <Label>{'Payment type:'.translate(this.props.lang)}</Label>

                                                        <Checkbox
                                                            checked={this.state.paymentBeforeSubmission}
                                                            onChange={(e) => {

                                                                if (this.state.wayOfScheduling === 'webBooking' && !hasOnlinePaymentInClinic && !this.state.paymentBeforeSubmission) {
                                                                    this.setState({
                                                                        onlineVisit: false,
                                                                    })

                                                                }

                                                                if (!(this.state.wayOfScheduling === 'dropIn' && !hasOnlinePaymentInClinic)) {
                                                                    this.detectChange()
                                                                    if (this.state.price > 0 && !this.state.paymentBeforeSubmission) {
                                                                        if (e.target.checked) {
                                                                            this.setState({
                                                                                paymentTypeBookedTime: true,
                                                                                paymentBeforeBookedTime: false,
                                                                                paymentAfterBookedTime: false,
                                                                                paymentOnSite: false,
                                                                                paymentAfterSubmission: false,
                                                                            }, () => {
                                                                                if (!this.state.paymentOnSite && !this.state.invoice && !(this.state.onlinePayment && hasOnlinePaymentInClinic)) {
                                                                                    this.setState({ invoice: true })
                                                                                }
                                                                            })
                                                                        }
                                                                        this.setState({
                                                                            paymentBeforeSubmission: e.target.checked
                                                                        }, () => this.checkActiveSave())

                                                                    }
                                                                }



                                                            }}
                                                            label={'Payment before submission'.translate(this.props.lang)}
                                                            tooltip={this.state.wayOfScheduling === 'dropIn' ? 'Payment must be completed before submission or request is deleted'.translate(this.props.lang) : 'Payment must be completed before submission or request and selected time is deleted'.translate(this.props.lang)}
                                                            disabled={this.state.wayOfScheduling === 'dropIn' && !hasOnlinePaymentInClinic ? true : false}
                                                        />

                                                        <Checkbox
                                                            checked={this.state.paymentAfterSubmission}
                                                            onChange={(e) => {
                                                                this.detectChange()
                                                                if (this.state.price > 0 && !this.state.paymentAfterSubmission) {
                                                                    if (e.target.checked) {
                                                                        this.setState({
                                                                            paymentTypeBookedTime: true,
                                                                            paymentBeforeBookedTime: false,
                                                                            paymentAfterBookedTime: false,
                                                                            paymentBeforeSubmission: false,
                                                                            paymentOnSite: false
                                                                        }, () => {
                                                                            if (!this.state.paymentOnSite && !this.state.invoice && !(this.state.onlinePayment && hasOnlinePaymentInClinic)) {
                                                                                this.setState({ invoice: true })
                                                                            }
                                                                        })
                                                                    }
                                                                    this.setState({
                                                                        paymentAfterSubmission: e.target.checked
                                                                    }, () => this.checkActiveSave())

                                                                }


                                                            }}
                                                            label={'Payment after submission'.translate(this.props.lang)}
                                                            tooltip={this.state.wayOfScheduling == 'dropIn' ? 'Payment can be made after case has been submitted'.translate(this.props.lang) : 'Payment can be made after submission and booking of time'.translate(this.props.lang)}
                                                        />
                                                    </div>

                                                    : null}

                                                {this.state.wayOfScheduling == 'bookingByClinic' || this.state.wayOfScheduling == 'webBooking' || this.state.wayOfScheduling == 'dropIn' ?
                                                    <div className="payment-types">
                                                        {
                                                            !this.state.paymentBeforeBookedTime && !this.state.paymentBeforeSubmission && this.state.wayOfScheduling != 'dropIn' ?
                                                                <Checkbox checked={this.state.paymentOnSite} onChange={(e) => {
                                                                    this.detectChange()
                                                                    if (this.state.wayOfScheduling == 'bookingByClinic' && !this.state.onlineVisit) {
                                                                        if (!(this.state.paymentOnSite && !this.state.invoice && !(this.state.onlinePayment && hasOnlinePaymentInClinic)) && this.state.price > 0) {
                                                                            if (e.target.checked) {
                                                                                this.setState({
                                                                                    freeOfCharge: false,

                                                                                })
                                                                            }
                                                                            this.setState({
                                                                                paymentOnSite: e.target.checked
                                                                            }, () => this.checkActiveSave())
                                                                        }
                                                                    } else if (this.state.wayOfScheduling == 'webBooking' && !this.state.onlineVisit) {
                                                                        if (this.state.price > 0) {
                                                                            if (!(this.state.paymentOnSite && !this.state.invoice && !(this.state.onlinePayment && hasOnlinePaymentInClinic))) {
                                                                                if (e.target.checked) {
                                                                                    this.setState({
                                                                                        freeOfCharge: false,

                                                                                    })
                                                                                }
                                                                                this.setState({
                                                                                    paymentOnSite: e.target.checked
                                                                                }, () => this.checkActiveSave())
                                                                            }

                                                                        }
                                                                    }




                                                                }} label={'Payment on site'.translate(this.props.lang)}
                                                                    disabled={this.state.price == 0 || this.state.onlineVisit ? true : false}
                                                                />
                                                                :
                                                                null
                                                        }

                                                        {
                                                            !(this.state.wayOfScheduling === 'dropIn' && this.state.paymentBeforeSubmission) ?
                                                                <Checkbox checked={this.state.invoice} onChange={(e) => {
                                                                    this.detectChange()

                                                                    if (this.state.wayOfScheduling == 'bookingByClinic') {
                                                                        if (!(!this.state.paymentOnSite && this.state.invoice && !(this.state.onlinePayment && hasOnlinePaymentInClinic)) && this.state.price > 0) {
                                                                            if (e.target.checked) {
                                                                                this.setState({
                                                                                    freeOfCharge: false,
                                                                                    // under18child: true,
                                                                                    // older85: true,
                                                                                    // under18: true
                                                                                })
                                                                            }
                                                                            this.setState({
                                                                                invoice: e.target.checked
                                                                            }, () => this.checkActiveSave())
                                                                        }
                                                                    } else if (this.state.wayOfScheduling == 'webBooking' || this.state.wayOfScheduling == 'dropIn') {
                                                                        if (this.state.price > 0) {
                                                                            if (!(!this.state.paymentOnSite && this.state.invoice && !(this.state.onlinePayment && hasOnlinePaymentInClinic))) {
                                                                                if (e.target.checked) {
                                                                                    this.setState({
                                                                                        freeOfCharge: false,
                                                                                        // under18child: true,
                                                                                        // older85: true,
                                                                                        // under18: true
                                                                                    })
                                                                                }
                                                                                this.setState({
                                                                                    invoice: e.target.checked
                                                                                }, () => this.checkActiveSave())
                                                                            }

                                                                        }
                                                                    }
                                                                    // else if (this.state.wayOfScheduling == 'dropIn') {
                                                                    //     if (!(!this.state.paymentOnSite && this.state.invoice && !(this.state.onlinePayment && hasOnlinePaymentInClinic)) && this.state.price > 0) {
                                                                    //         if (e.target.checked) {
                                                                    //             this.setState({
                                                                    //                 freeOfCharge: false,

                                                                    //             })
                                                                    //         }
                                                                    //         this.setState({
                                                                    //             invoice: e.target.checked
                                                                    //         }, () => this.checkActiveSave())
                                                                    //     }
                                                                    // }

                                                                }} label={'Invoice'.translate(this.props.lang)}
                                                                    disabled={this.state.price == 0}
                                                                    tooltip={'Invoice must be attached and registered as "Paid" manually under "Payments"'.translate(this.props.lang)}
                                                                />
                                                                :
                                                                null
                                                        }



                                                        <Checkbox checked={this.state.freeOfCharge} onChange={(e) => {
                                                            this.detectChange()
                                                            if (this.state.wayOfScheduling == 'webBooking') {
                                                                if (this.state.price > 0) {
                                                                    this.setState({
                                                                        priceZeroModal: true
                                                                    }, () => this.checkActiveSave())

                                                                }

                                                            } else {
                                                                if (this.state.price > 0) {
                                                                    this.setState({
                                                                        priceZeroModal: true
                                                                    }, () => this.checkActiveSave())

                                                                }
                                                            }


                                                        }} label={'Free of charge'.translate(this.props.lang)} />

                                                        {/* {this.state.priceZeroModal ?
                                                        <Modal isOpen={this.state.priceZeroModal} centered>
                                                            <ModalHeader close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ priceZeroModal: !this.state.priceZeroModal })}>&times;</button>}>
                                                                <h4>{'Warning'.translate(this.props.lang)}</h4>
                                                            </ModalHeader>
                                                            <ModalBody>
                                                                <p>
                                                                    {'This action will make price 0 SEK.'.translate(this.props.lang)}
                                                                </p>
                                                                <Button color='primary' style={{margin: 10}} onClick={() => this.setState({
                                                                    freeOfCharge: true,
                                                                    price: 0,
                                                                    priceZeroModal: false
                                                                })}>{'OK'.translate(this.props.lang)}</Button>
                                                                <Button color='primary' style={{margin: 10}} onClick={() => this.setState({
                                                                    priceZeroModal: false
                                                                })}>{'Cancel'.translate(this.props.lang)}</Button>
                                                            </ModalBody>
                                                            <ModalFooter>
                                                                
                                                            </ModalFooter>
                                                        </Modal>
                                                        : null} */}

                                                        {this.state.priceZeroModal ?
                                                            <InfoModal
                                                                isOpen={this.state.priceZeroModal}
                                                                onClose={(item) => {
                                                                    this.setState({
                                                                        priceZeroModal: item
                                                                    })
                                                                }}
                                                                toggle={() => this.setState({ priceZeroModal: !this.state.priceZeroModal })}
                                                                header={'Warning'.translate(this.props.lang)}
                                                                info={'This action will make price 0'.translate(this.props.lang) + " " + currency + "."}
                                                                buttons={[
                                                                    <Button color='primary' style={{ margin: 10 }} onClick={() => this.setState({
                                                                        freeOfCharge: true,
                                                                        price: 0,
                                                                        priceZeroModal: false,
                                                                        freeOfCharge: true,
                                                                        price: 0,
                                                                        priceZeroModal: false,
                                                                        paymentOnSite: false,
                                                                        invoice: false,
                                                                        onlinePayment: false,
                                                                        under18: false,
                                                                        under18child: false,
                                                                        under20: false,
                                                                        under23: false,
                                                                        haveFreeCard: false,
                                                                        older85: false,
                                                                        paymentBeforeBookedTime: false,
                                                                        paymentAfterBookedTime: false,
                                                                        paymentAfterSubmission: false,
                                                                        paymentBeforeSubmission: false,
                                                                        selectVatRate: false,
                                                                        vatRate: -1
                                                                    })}>{'OK'.translate(this.props.lang)}</Button>,
                                                                    <Button color='primary' style={{ margin: 10 }} onClick={() => this.setState({
                                                                        priceZeroModal: false
                                                                    })}>{'Cancel'.translate(this.props.lang)}</Button>

                                                                ]}
                                                            />
                                                            :

                                                            null}



                                                        {hasOnlinePaymentInClinic || this.state.wayOfScheduling === 'dropIn' ?

                                                            <Checkbox checked={this.state.onlinePayment && hasOnlinePaymentInClinic} onChange={(e) => {
                                                                if (hasOnlinePaymentInClinic) {
                                                                    this.detectChange()
                                                                    if (this.state.wayOfScheduling == 'bookingByClinic') {
                                                                        if (!(!this.state.paymentOnSite && !this.state.invoice && this.state.onlinePayment) && this.state.price > 0) {
                                                                            if (e.target.checked) {
                                                                                this.setState({
                                                                                    freeOfCharge: false,

                                                                                })
                                                                            }
                                                                            this.setState({
                                                                                onlinePayment: e.target.checked
                                                                            }, () => this.checkActiveSave())
                                                                        }
                                                                    } else if (this.state.wayOfScheduling == 'webBooking') {
                                                                        if (this.state.price > 0) {
                                                                            if (!(!this.state.paymentOnSite && !this.state.invoice && this.state.onlinePayment)) {
                                                                                if (e.target.checked) {
                                                                                    this.setState({
                                                                                        freeOfCharge: false,

                                                                                    })
                                                                                }
                                                                                this.setState({
                                                                                    onlinePayment: e.target.checked
                                                                                }, () => this.checkActiveSave())
                                                                            }
                                                                        }
                                                                    } else if (this.state.wayOfScheduling == 'dropIn') {
                                                                        if (this.state.price > 0) {
                                                                            if (!(!this.state.paymentOnSite && !this.state.invoice && this.state.onlinePayment)) {
                                                                                if (e.target.checked) {
                                                                                    this.setState({
                                                                                        freeOfCharge: false,

                                                                                    })
                                                                                }
                                                                                this.setState({
                                                                                    onlinePayment: e.target.checked
                                                                                }, () => this.checkActiveSave())
                                                                            }
                                                                        }
                                                                    }
                                                                }


                                                            }} label={'Online payment'.translate(this.props.lang)}
                                                                disabled={this.state.price == 0 || !hasOnlinePaymentInClinic ? true : false}
                                                            />
                                                            :
                                                            null
                                                        }


                                                    </div>
                                                    : null}
                                                {
                                                    this.state.errorSave ?
                                                        <div className="error-save-message">
                                                            <p>{this.state.errorSave}</p>
                                                        </div>
                                                        :
                                                        null
                                                }

                                            </FormGroup>
                                            {/* {this.state.invoice ?
                                            <Html onChange={(e) =>
                                                this.setState({
                                                    invoiceMessage: e
                                                })
                                            } value={this.state.invoiceMessage} label={'Invoice message'.translate(this.props.lang)} />
                                            : null} */}

                                            {
                                                this.state.onlinePayment || this.state.invoice || this.state.paymentOnSite ?
                                                    <FormGroup>
                                                        <Label>{'Payment settings'.translate(this.props.lang)}</Label>
                                                        <div className="online-payment-types">
                                                            <Checkbox checked={this.state.haveFreeCard} onChange={(e) => {
                                                                this.detectChange()

                                                                this.setState({
                                                                    haveFreeCard: e.target.checked
                                                                })

                                                            }} label={'I have a free card'.translate(this.props.lang)} />

                                                            <Checkbox checked={this.state.under23} onChange={(e) => {
                                                                this.detectChange()

                                                                this.setState({
                                                                    under23: e.target.checked
                                                                })

                                                            }} label={'I am under 23 years old'.translate(this.props.lang)} />

                                                            <Checkbox checked={this.state.under20} onChange={(e) => {
                                                                this.detectChange()

                                                                this.setState({
                                                                    under20: e.target.checked
                                                                })

                                                            }} label={'I am under 20 years old'.translate(this.props.lang)} />

                                                            <Checkbox checked={this.state.under18}
                                                                onChange={(e) => {
                                                                    this.detectChange()

                                                                    this.setState({
                                                                        under18: e.target.checked,
                                                                        under18child: e.target.checked
                                                                    })

                                                                }}
                                                                label={'I am under 18 years old'.translate(this.props.lang)} />

                                                            <Checkbox checked={this.state.older85}
                                                                onChange={(e) => {
                                                                    this.detectChange()
                                                                    this.setState({
                                                                        older85: e.target.checked
                                                                    })

                                                                }}
                                                                label={'I am 85 years or older'.translate(this.props.lang)} />

                                                            {!this.state.under18 ?
                                                                <Checkbox checked={this.state.under18child}
                                                                    onChange={(e) => {
                                                                        this.detectChange()
                                                                        this.setState({
                                                                            under18child: e.target.checked,
                                                                            under18: e.target.checked
                                                                        })

                                                                    }}
                                                                    label={'I seek healthcare for my child'.translate(this.props.lang)} />
                                                                : null}




                                                        </div>

                                                    </FormGroup>
                                                    :
                                                    null
                                            }
                                        </Col>
                                        :
                                        <Col lg='6'>

                                            <FormGroup>
                                                <ListCheckBox
                                                    label={'Assigned to:'.translate(this.props.lang)}
                                                    value={this.state.groupsValue}
                                                    values={this.state.clinicGroupsData && this.state.clinicGroupsData.map(item => {
                                                        return ({
                                                            name: item.name,
                                                            value: item._id
                                                        })
                                                    })}
                                                    onChange={(item) => {
                                                        this.detectChange()
                                                        this.setState({
                                                            groupsValue: item
                                                        })
                                                    }}
                                                />
                                            </FormGroup>

                                        </Col>
                                    }
                                    {this.state.openPageWarningModal ?
                                        <InfoModal
                                            isOpen={this.state.openPageWarningModal}
                                            onClose={(item) => {
                                                this.setState({
                                                    openPageWarningModal: item
                                                })
                                            }}
                                            toggle={() => this.setState({ openPageWarningModal: !this.state.openPageWarningModal })}
                                            header={'Warning'.translate(this.props.lang)}
                                            info={'Make sure the form works as desired!'.translate(this.props.lang)}
                                            buttons={[

                                                <Button color='primary' onClick={() => {
                                                    if (this.state.openPageWarningModal == 1) {
                                                        this.updateQuestionary(true)
                                                    } else if (this.state.openPageWarningModal == 2) {
                                                        this.updateQuestionary(false)
                                                    }
                                                }}>{'Ok'.translate(this.props.lang)}</Button>


                                            ]}
                                        />

                                        :

                                        null}
                                    <Col lg='12'>
                                        <FormGroup className="questionary-buttons">

                                            <Button onClick={() => this.setState({ openPageWarningModal: 2 })} size="sm" color="primary" disabled={this.state.checkActiveSave ? false : true}>
                                                {
                                                    this.state.showPlayer ? (
                                                        <Player
                                                            onEvent={(event) => {
                                                                if (event === "load" && this.player && this.player.current) this.stopAnimation();
                                                            }}
                                                            onStateChange={this.toggleVisibility}
                                                            ref={this.player} // set the ref to your class instance
                                                            autoplay={true}
                                                            loop={true}
                                                            controls={true}
                                                            src="https://assets8.lottiefiles.com/packages/lf20_ZSRKCF.json"
                                                            style={{ height: "30px", width: "30px" }}
                                                        ></Player>

                                                    ) : this.state.showPlayer2 ? (
                                                        <div style={{ display: 'flex', alignItems: 'center' }}><Player
                                                            onStateChange={this.toggleVisibility}
                                                            ref={this.player2} // set the ref to your class instance
                                                            autoplay={true}
                                                            loop={true}
                                                            controls={true}
                                                            src="https://assets10.lottiefiles.com/packages/lf20_CxyxLk.json"
                                                            style={{ height: "30px", width: "30px" }}
                                                        ></Player>
                                                            {'Saved'.translate(this.props.lang)}
                                                        </div>
                                                    ) : (
                                                        "Save".translate(this.props.lang)
                                                    )
                                                }
                                            </Button>
                                            {
                                                this.props.selectedGroup ?
                                                    // <Button size="sm" style={{ marginLeft: 10 }} onClick={() => this.setState({
                                                    //     selectReferralModal: true
                                                    // })} color="primary">
                                                    //     {'Import'.translate(this.props.lang)}
                                                    // </Button>
                                                    <Button size="sm" style={{ marginLeft: 10 }} onClick={() => this.setState({
                                                        importModal: true,
                                                        importModalList: [],

                                                    }, () => this.get())} color="primary">
                                                        {'Import'.translate(this.props.lang)}
                                                    </Button>
                                                    :

                                                    null
                                            }
                                            <Button size="sm" style={{ marginLeft: 10 }} onClick={() => this.setState({
                                                previewModal: true
                                            })} color="primary">
                                                {'Preview'.translate(this.props.lang)}
                                            </Button>



                                        </FormGroup>
                                    </Col>
                                </Row>

                            </div>
                        </Col>


                        <Col lg="12">
                            <div className="panel build-questionary-section">
                                {
                                    !this.state.items || (this.state.items && !this.state.items.length) ?
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <p style={{ marginBottom: 0, fontSize: 15, color: 'red' }}>
                                                {'You must add at least one question to activate this form.'.translate(this.props.lang)}
                                            </p>
                                        </div>
                                        :
                                        null
                                }

                                {
                                    this.state.items && this.state.items.length ?
                                        <>
                                            <ul className={this.state.showLink ? "linked-elements active" : "linked-elements"}>

                                                {
                                                    this.state.items.map((item, idx) => {
                                                        if (this.conditionIndexOf(this.state.showLink, item.id) !== -1)
                                                            return (
                                                                <li>
                                                                    <div>
                                                                        <span>{idx + 1}.</span>
                                                                        {
                                                                            item.type == 1 || item.type == 2 ?
                                                                                <FormGroup tag="fieldset">
                                                                                    <Label size="sm">{item.name}</Label>
                                                                                    {
                                                                                        item.answers.map((answer, aidx) => {
                                                                                            return (
                                                                                                <FormGroup check>
                                                                                                    <Label size="sm" check>
                                                                                                        <Input disabled size="sm" name={item.id} type={item.type == 1 ? 'radio' : 'checkbox'} />
                                                                                                        {' '}
                                                                                                        {(this.state.showLink && this.conditionIndexOf(this.state.showLink, item.id, answer.id) != -1) ?
                                                                                                            <button className={(this.state.showLink && this.conditionIndexOf(this.state.showLink, item.id, answer.id) != -1) || (this.state.linkingElement !== null && this.state.items[this.state.linkingElement].conditions
                                                                                                                && this.state.items[this.state.linkingElement].conditions.length && this.conditionIndexOf(this.state.items[this.state.linkingElement].conditions, item.id, answer.id) != -1) ? 'linking-element active' : 'linking-element'}
                                                                                                                onClick={() => {
                                                                                                                    let items = this.state.items;
                                                                                                                    if (!items[this.state.linkingElement].conditions) {
                                                                                                                        items[this.state.linkingElement].conditions = [];
                                                                                                                    }

                                                                                                                    let check = -1;
                                                                                                                    for (let i = 0; i < items[this.state.linkingElement].conditions.length; i++) {
                                                                                                                        if (items[this.state.linkingElement].conditions[i].conditionQuestion == item.id && items[this.state.linkingElement].conditions[i].conditionAnswer == answer.id) {
                                                                                                                            check = i;
                                                                                                                        }
                                                                                                                    }


                                                                                                                    if (check != -1) {
                                                                                                                        items[this.state.linkingElement].conditions.splice(check, 1);
                                                                                                                    } else {
                                                                                                                        items[this.state.linkingElement].conditions.push({
                                                                                                                            conditionAnswer: answer.id,
                                                                                                                            conditionQuestion: item.id
                                                                                                                        })
                                                                                                                    }

                                                                                                                    /*items[this.state.linkingElement].conditionQuestion = item.id;
                                                                                                                    items[this.state.linkingElement].conditionAnswer = answer.id;
                                                                                                                    */
                                                                                                                    this.setState({
                                                                                                                        items,
                                                                                                                        // linkingElement: null
                                                                                                                    }, () => this.detectChange());

                                                                                                                }}>{answer.name}</button>
                                                                                                            :
                                                                                                            <>
                                                                                                                {answer.name}
                                                                                                            </>
                                                                                                        }
                                                                                                    </Label>
                                                                                                </FormGroup>
                                                                                            )
                                                                                        })
                                                                                    }

                                                                                    {(this.state.showLink && this.conditionIndexOf(this.state.showLink, item.id, '__user-input:') != -1) ?
                                                                                        <button className={(this.state.showLink && this.conditionIndexOf(this.state.showLink, item.id, '__user-input:') != -1) || (this.state.linkingElement !== null && this.state.items[this.state.linkingElement]?.conditions && this.state.items[this.state.linkingElement].conditions.length && this.conditionIndexOf(this.state.items[this.state.linkingElement].conditions, item.id, '__user-input:') != -1) ? 'linking-element active' : 'linking-element'} onClick={() => {
                                                                                            let items = this.state.items;
                                                                                            if (!items[this.state.linkingElement].conditions) {
                                                                                                items[this.state.linkingElement].conditions = [];
                                                                                            }

                                                                                            let check = -1;
                                                                                            for (let i = 0; i < items[this.state.linkingElement].conditions.length; i++) {
                                                                                                if (items[this.state.linkingElement].conditions[i].conditionQuestion == item.id && items[this.state.linkingElement].conditions[i].conditionAnswer == '__user-input:') {
                                                                                                    check = i;
                                                                                                }
                                                                                            }

                                                                                            if (check != -1) {
                                                                                                items[this.state.linkingElement].conditions.splice(check, 1);
                                                                                            } else {
                                                                                                items[this.state.linkingElement].conditions.push({
                                                                                                    conditionAnswer: '__user-input:',
                                                                                                    conditionQuestion: item.id
                                                                                                })
                                                                                            }

                                                                                            this.setState({
                                                                                                items,
                                                                                                // linkingElement: null
                                                                                            }, () => this.detectChange());

                                                                                        }}>{'user-input'}</button>
                                                                                        :
                                                                                        null
                                                                                    }


                                                                                </FormGroup>
                                                                                :
                                                                                null
                                                                        }
                                                                        {
                                                                            item.type == 16 || item.type == 17 ?
                                                                                <FormGroup tag="fieldset">
                                                                                    <Label size="sm">{item.name}</Label>
                                                                                    {
                                                                                        item.blocks.map((block, bidx) => {
                                                                                            return (
                                                                                                <FormGroup check>
                                                                                                    <Label size="sm">
                                                                                                        {(this.state.showLink && this.conditionIndexOf(this.state.showLink, item.id, block.id) != -1) ?
                                                                                                            <button className={(this.state.showLink && this.conditionIndexOf(this.state.showLink, item.id, block.id) != -1) || (this.state.linkingElement !== null && this.state.items[this.state.linkingElement]?.conditions && this.state.items[this.state.linkingElement].conditions.length && this.conditionIndexOf(this.state.items[this.state.linkingElement].conditions, item.id, block.id) != -1) ? 'linking-element active' : 'linking-element'} onClick={() => {
                                                                                                                let items = this.state.items;
                                                                                                                if (!items[this.state.linkingElement].conditions) {
                                                                                                                    items[this.state.linkingElement].conditions = [];
                                                                                                                }

                                                                                                                let check = -1;
                                                                                                                for (let i = 0; i < items[this.state.linkingElement].conditions.length; i++) {
                                                                                                                    if (items[this.state.linkingElement].conditions[i].conditionQuestion == item.id && items[this.state.linkingElement].conditions[i].conditionAnswer == block.id) {
                                                                                                                        check = i;
                                                                                                                    }
                                                                                                                }

                                                                                                                if (check != -1) {
                                                                                                                    items[this.state.linkingElement].conditions.splice(check, 1);
                                                                                                                } else {
                                                                                                                    items[this.state.linkingElement].conditions.push({
                                                                                                                        conditionAnswer: block.id,
                                                                                                                        conditionQuestion: item.id
                                                                                                                    })
                                                                                                                }

                                                                                                                this.setState({
                                                                                                                    items,
                                                                                                                }, () => this.detectChange());

                                                                                                            }}>{'Block'.translate(this.props.lang)} {block.start == block.end ? block.start : `${block.start}-${block.end}`}</button>
                                                                                                            :
                                                                                                            <>
                                                                                                                {'Block'.translate(this.props.lang)} {block.start == block.end ? block.start : `${block.start}-${block.end}`}
                                                                                                            </>
                                                                                                        }
                                                                                                    </Label>
                                                                                                </FormGroup>
                                                                                            )
                                                                                        })
                                                                                    }

                                                                                </FormGroup>
                                                                                :
                                                                                null
                                                                        }
                                                                        {
                                                                            item.type == 3 || item.type == 5 ?
                                                                                <FormGroup tag="fieldset">
                                                                                    <Label size="sm">
                                                                                        {(this.state.linkingElement !== null && this.state.linkingElement !== idx && this.state.linkingElement > idx) || (this.state.showLink && this.conditionIndexOf(this.state.showLink, item.id, null) != -1) ?
                                                                                            <button className={(this.state.showLink && this.conditionIndexOf(this.state.showLink, item.id, null) != -1) || (this.state.linkingElement !== null && this.state.items[this.state.linkingElement]?.conditions && this.state.items[this.state.linkingElement].conditions.length && this.conditionIndexOf(this.state.items[this.state.linkingElement].conditions, item.id) != -1) ? 'linking-element active' : 'linking-element'} onClick={() => {
                                                                                                let items = this.state.items;
                                                                                                //items[this.state.linkingElement].conditionQuestion = item.id;

                                                                                                if (!items[this.state.linkingElement].conditions) {
                                                                                                    items[this.state.linkingElement].conditions = [];
                                                                                                }

                                                                                                let check = -1;
                                                                                                for (let i = 0; i < items[this.state.linkingElement].conditions.length; i++) {
                                                                                                    if (items[this.state.linkingElement].conditions[i].conditionQuestion == item.id) {
                                                                                                        check = i;
                                                                                                    }
                                                                                                }

                                                                                                if (check != -1) {
                                                                                                    items[this.state.linkingElement].conditions.splice(check, 1);
                                                                                                } else {
                                                                                                    items[this.state.linkingElement].conditions.push({
                                                                                                        conditionQuestion: item.id
                                                                                                    })
                                                                                                }


                                                                                                this.setState({
                                                                                                    items,
                                                                                                    //linkingElement: null
                                                                                                }, () => this.detectChange());

                                                                                            }}>{item.name}</button>

                                                                                            :
                                                                                            <input type="text" value={item.name} onChange={(e) => {
                                                                                                let items = this.state.items;
                                                                                                items[idx].name = e.target.value;
                                                                                                this.setState({
                                                                                                    items
                                                                                                }, () => this.detectChange())
                                                                                            }} className="edit-field-name" />
                                                                                        }
                                                                                    </Label>
                                                                                    {
                                                                                        item.type == 3 ?
                                                                                            <Input disabled size="sm" type="textarea"></Input>
                                                                                            :
                                                                                            <Input disabled size="sm" type="text"></Input>

                                                                                    }


                                                                                </FormGroup>

                                                                                :
                                                                                null
                                                                        }
                                                                        {
                                                                            item.type == 6 ?
                                                                                <FormGroup tag="fieldset">
                                                                                    <Label size="sm">
                                                                                        {(this.state.linkingElement !== null && this.state.linkingElement !== idx && this.state.linkingElement > idx) || (this.state.showLink && this.conditionIndexOf(this.state.showLink, item.id, null) != -1) ?
                                                                                            <button className={(this.state.showLink && this.conditionIndexOf(this.state.showLink, item.id, null) != -1) || (this.state.linkingElement !== null && this.state.items[this.state.linkingElement]?.conditions && this.state.items[this.state.linkingElement].conditions.length && this.conditionIndexOf(this.state.items[this.state.linkingElement].conditions, item.id) != -1) ? 'linking-element active' : 'linking-element'} onClick={() => {
                                                                                                let items = this.state.items;
                                                                                                //items[this.state.linkingElement].conditionQuestion = item.id;

                                                                                                if (!items[this.state.linkingElement].conditions) {
                                                                                                    items[this.state.linkingElement].conditions = [];
                                                                                                }

                                                                                                let check = -1;
                                                                                                for (let i = 0; i < items[this.state.linkingElement].conditions.length; i++) {
                                                                                                    if (items[this.state.linkingElement].conditions[i].conditionQuestion == item.id) {
                                                                                                        check = i;
                                                                                                    }
                                                                                                }

                                                                                                if (check != -1) {
                                                                                                    items[this.state.linkingElement].conditions.splice(check, 1);
                                                                                                } else {
                                                                                                    items[this.state.linkingElement].conditions.push({
                                                                                                        conditionQuestion: item.id
                                                                                                    })
                                                                                                }


                                                                                                this.setState({
                                                                                                    items,
                                                                                                    //linkingElement: null
                                                                                                }, () => this.detectChange());

                                                                                            }}>{item.name}</button>

                                                                                            :
                                                                                            <input type="text" value={item.name} onChange={(e) => {
                                                                                                let items = this.state.items;
                                                                                                items[idx].name = e.target.value;
                                                                                                this.setState({
                                                                                                    items
                                                                                                }, () => this.detectChange())
                                                                                            }} className="edit-field-name" />
                                                                                        }
                                                                                    </Label>

                                                                                    <Row>
                                                                                        <Col lg="2">
                                                                                            <FormGroup>
                                                                                                <Label>{'Weight:'.translate(this.props.lang)}</Label>
                                                                                                <Input disabled size="sm" type="text"></Input>

                                                                                            </FormGroup>
                                                                                        </Col>
                                                                                        <Col lg="2">
                                                                                            <FormGroup>
                                                                                                <Label>{'Height:'.translate(this.props.lang)}</Label>
                                                                                                <Input disabled size="sm" type="text"></Input>

                                                                                            </FormGroup>

                                                                                        </Col>
                                                                                    </Row>


                                                                                </FormGroup>

                                                                                :
                                                                                null
                                                                        }
                                                                        {
                                                                            item.type == 9 ?
                                                                                <FormGroup tag="fieldset">
                                                                                    <Label size="sm">
                                                                                        {/*
                                                                    <input style={cssStyle} type="text" value={item.name} onChange={(e) => {
                                                                        let items = this.state.items;
                                                                        items[idx].name = e.target.value;
                                                                        this.setState({
                                                                            items
                                                                        })
                                                                    }} />*/}
                                                                                        {(this.state.linkingElement !== null && this.state.linkingElement !== idx && this.state.linkingElement > idx) || (this.state.showLink && this.conditionIndexOf(this.state.showLink, item.id, null) != -1) ?
                                                                                            <button className={(this.state.showLink && this.conditionIndexOf(this.state.showLink, item.id, null) != -1) || (this.state.linkingElement !== null && this.state.items[this.state.linkingElement]?.conditions && this.state.items[this.state.linkingElement].conditions.length && this.conditionIndexOf(this.state.items[this.state.linkingElement].conditions, item.id) != -1) ? 'linking-element active' : 'linking-element'} onClick={() => {
                                                                                                let items = this.state.items;
                                                                                                //items[this.state.linkingElement].conditionQuestion = item.id;

                                                                                                if (!items[this.state.linkingElement].conditions) {
                                                                                                    items[this.state.linkingElement].conditions = [];
                                                                                                }

                                                                                                let check = -1;
                                                                                                for (let i = 0; i < items[this.state.linkingElement].conditions.length; i++) {
                                                                                                    if (items[this.state.linkingElement].conditions[i].conditionQuestion == item.id) {
                                                                                                        check = i;
                                                                                                    }
                                                                                                }

                                                                                                if (check != -1) {
                                                                                                    items[this.state.linkingElement].conditions.splice(check, 1);
                                                                                                } else {
                                                                                                    items[this.state.linkingElement].conditions.push({
                                                                                                        conditionQuestion: item.id
                                                                                                    })
                                                                                                }


                                                                                                this.setState({
                                                                                                    items,
                                                                                                    //linkingElement: null
                                                                                                }, () => this.detectChange());

                                                                                            }}>{item.name}</button>

                                                                                            :
                                                                                            <>
                                                                                                <input style={cssStyle} type="text" value={item.name} onChange={(e) => {
                                                                                                    let items = this.state.items;
                                                                                                    items[idx].name = e.target.value;
                                                                                                    this.setState({
                                                                                                        items
                                                                                                    }, () => this.detectChange())
                                                                                                }} className="edit-field-name" />
                                                                                                <div className="text-options">
                                                                                                    <button className={item.fontStyle == 'italic' ? 'active' : ''} onClick={() => { let items = this.state.items; items[idx].fontStyle = items[idx].fontStyle == 'italic' ? null : 'italic'; this.setState({ items }, () => this.detectChange()) }}><i className="fa fa-italic" /></button>
                                                                                                    <button className={item.fontWeight == 'bold' ? 'active' : ''} onClick={() => { let items = this.state.items; items[idx].fontWeight = items[idx].fontWeight == 'bold' ? null : 'bold'; this.setState({ items }, () => this.detectChange()) }}><i className="fa fa-bold" /></button>

                                                                                                    <UncontrolledDropdown size="sm">
                                                                                                        <DropdownToggle caret>
                                                                                                            <i className="fa fa-font" />
                                                                                                        </DropdownToggle>
                                                                                                        <DropdownMenu>
                                                                                                            <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 10; this.setState({ items }, () => this.detectChange()) }}>10px</DropdownItem>
                                                                                                            <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 12; this.setState({ items }, () => this.detectChange()) }}>12px</DropdownItem>
                                                                                                            <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 14; this.setState({ items }, () => this.detectChange()) }}>14px</DropdownItem>
                                                                                                            <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 16; this.setState({ items }, () => this.detectChange()) }}>16px</DropdownItem>
                                                                                                            <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 18; this.setState({ items }, () => this.detectChange()) }}>18px</DropdownItem>
                                                                                                            <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 20; this.setState({ items }, () => this.detectChange()) }}>20px</DropdownItem>
                                                                                                        </DropdownMenu>
                                                                                                    </UncontrolledDropdown>
                                                                                                </div>

                                                                                            </>

                                                                                        }

                                                                                    </Label>

                                                                                    <Input size="sm" disabled type="file" title={"No file chosen".translate(this.props.lang)} accept="image/png, image/jpeg, image/jpg, image/gif, image/svg, .doc, .docx, .pdf" >{'Select Image'.translate(this.props.lang)}</Input>


                                                                                </FormGroup>

                                                                                :
                                                                                null
                                                                        }
                                                                        {
                                                                            item.type == 12 ?
                                                                                <FormGroup tag="fieldset">
                                                                                    <Label size="sm">
                                                                                        {(this.state.linkingElement !== null && this.state.linkingElement !== idx && this.state.linkingElement > idx) || (this.state.showLink && this.conditionIndexOf(this.state.showLink, item.id, null) != -1) ?
                                                                                            <button className={(this.state.showLink && this.conditionIndexOf(this.state.showLink, item.id, null) != -1) || (this.state.linkingElement !== null && this.state.items[this.state.linkingElement]?.conditions && this.state.items[this.state.linkingElement].conditions.length && this.conditionIndexOf(this.state.items[this.state.linkingElement].conditions, item.id) != -1) ? 'linking-element active' : 'linking-element'} onClick={() => {
                                                                                                let items = this.state.items;
                                                                                                //items[this.state.linkingElement].conditionQuestion = item.id;

                                                                                                if (!items[this.state.linkingElement].conditions) {
                                                                                                    items[this.state.linkingElement].conditions = [];
                                                                                                }

                                                                                                let check = -1;
                                                                                                for (let i = 0; i < items[this.state.linkingElement].conditions.length; i++) {
                                                                                                    if (items[this.state.linkingElement].conditions[i].conditionQuestion == item.id) {
                                                                                                        check = i;
                                                                                                    }
                                                                                                }

                                                                                                if (check != -1) {
                                                                                                    items[this.state.linkingElement].conditions.splice(check, 1);
                                                                                                } else {
                                                                                                    items[this.state.linkingElement].conditions.push({
                                                                                                        conditionQuestion: item.id
                                                                                                    })
                                                                                                }


                                                                                                this.setState({
                                                                                                    items,
                                                                                                    //linkingElement: null
                                                                                                }, () => this.detectChange());

                                                                                            }}>{item.name}</button>

                                                                                            :
                                                                                            <input type="text" value={item.name} onChange={(e) => {
                                                                                                let items = this.state.items;
                                                                                                items[idx].name = e.target.value;
                                                                                                this.setState({
                                                                                                    items
                                                                                                }, () => this.detectChange())
                                                                                            }} className="edit-field-name" />
                                                                                        }
                                                                                    </Label>

                                                                                    <Row>
                                                                                        <Col lg="2">
                                                                                            <FormGroup>
                                                                                                <Label>{'Systolic:'.translate(this.props.lang)}</Label>
                                                                                                <Input disabled size="sm" type="text"></Input>

                                                                                            </FormGroup>
                                                                                        </Col>
                                                                                        <Col lg="2">
                                                                                            <FormGroup>
                                                                                                <Label>{'Diastolic:'.translate(this.props.lang)}</Label>
                                                                                                <Input disabled size="sm" type="text"></Input>

                                                                                            </FormGroup>

                                                                                        </Col>
                                                                                        <Col lg="2">
                                                                                            <FormGroup>
                                                                                                <Label>{'Pulse:'.translate(this.props.lang)}</Label>
                                                                                                <Input disabled size="sm" type="text"></Input>

                                                                                            </FormGroup>

                                                                                        </Col>
                                                                                    </Row>


                                                                                </FormGroup>

                                                                                :
                                                                                null
                                                                        }
                                                                        {
                                                                            item.type == 13 ?
                                                                                <FormGroup tag="fieldset">
                                                                                    <Label size="sm">
                                                                                        {(this.state.linkingElement !== null && this.state.linkingElement !== idx && this.state.linkingElement > idx) || (this.state.showLink && this.conditionIndexOf(this.state.showLink, item.id, null) != -1) ?
                                                                                            <button className={(this.state.showLink && this.conditionIndexOf(this.state.showLink, item.id, null) != -1) || (this.state.linkingElement !== null && this.state.items[this.state.linkingElement]?.conditions && this.state.items[this.state.linkingElement].conditions.length && this.conditionIndexOf(this.state.items[this.state.linkingElement].conditions, item.id) != -1) ? 'linking-element active' : 'linking-element'} onClick={() => {
                                                                                                let items = this.state.items;
                                                                                                //items[this.state.linkingElement].conditionQuestion = item.id;

                                                                                                if (!items[this.state.linkingElement].conditions) {
                                                                                                    items[this.state.linkingElement].conditions = [];
                                                                                                }

                                                                                                let check = -1;
                                                                                                for (let i = 0; i < items[this.state.linkingElement].conditions.length; i++) {
                                                                                                    if (items[this.state.linkingElement].conditions[i].conditionQuestion == item.id) {
                                                                                                        check = i;
                                                                                                    }
                                                                                                }

                                                                                                if (check != -1) {
                                                                                                    items[this.state.linkingElement].conditions.splice(check, 1);
                                                                                                } else {
                                                                                                    items[this.state.linkingElement].conditions.push({
                                                                                                        conditionQuestion: item.id
                                                                                                    })
                                                                                                }


                                                                                                this.setState({
                                                                                                    items,
                                                                                                    //linkingElement: null
                                                                                                }, () => this.detectChange());

                                                                                            }}>{item.name}</button>

                                                                                            :
                                                                                            <input type="text" value={item.name} onChange={(e) => {
                                                                                                let items = this.state.items;
                                                                                                items[idx].name = e.target.value;
                                                                                                this.setState({
                                                                                                    items
                                                                                                }, () => this.detectChange())
                                                                                            }} className="edit-field-name" />
                                                                                        }
                                                                                    </Label>

                                                                                    <Row>
                                                                                        <Col lg="2">
                                                                                            <FormGroup>
                                                                                                <Input disabled size="sm" type="text"></Input>

                                                                                            </FormGroup>
                                                                                        </Col>

                                                                                    </Row>


                                                                                </FormGroup>

                                                                                :
                                                                                null
                                                                        }

                                                                        {
                                                                            item.type == 4 ?

                                                                                <FormGroup tag="fieldset">
                                                                                    <Label size="sm">{item.name}</Label>


                                                                                    <div dangerouslySetInnerHTML={{ __html: item.text }}>

                                                                                    </div>
                                                                                    <FormGroup>
                                                                                        <Checkbox checked={item.userInput ? true : false} label={'Alert the patient'.translate(this.props.lang)} />

                                                                                    </FormGroup>


                                                                                </FormGroup>
                                                                                :
                                                                                null
                                                                        }
                                                                        {
                                                                            item.type == 15 ?

                                                                                <FormGroup tag="fieldset">
                                                                                    <Label size="sm">{item.name}</Label>


                                                                                    <div dangerouslySetInnerHTML={{ __html: item.text }}>

                                                                                    </div>


                                                                                </FormGroup>
                                                                                :
                                                                                null
                                                                        }



                                                                    </div>
                                                                </li>
                                                            )
                                                    })
                                                }
                                            </ul>
                                            <SortableList helperClass="test123"
                                                // onSortEnd={({ oldIndex, newIndex }) => {
                                                //     let items = this.state.items;

                                                //     items = arrayMove(items, oldIndex, newIndex);
                                                //     this.setState({
                                                //         items: items
                                                //     })
                                                // }}
                                                onSortEnd={({ oldIndex, newIndex }) => {
                                                    this.setState({ cursorNoDrop: false }, () => { document.body.style.cursor = 'default'; })
                                                    let items = this.state.items;

                                                    let checkOldIndex = this.checkRelations(items[oldIndex].id);

                                                    let haveTotal = null;
                                                    if (items && items.length && items[items.length - 1].type == 17) {
                                                        haveTotal = items.length - 1;
                                                    }
                                                    if (haveTotal && newIndex >= haveTotal) {
                                                        return;
                                                    }

                                                    if (oldIndex > newIndex) {
                                                        let arr = [];
                                                        if (checkOldIndex && checkOldIndex[0].length) {
                                                            for (let i = 0; i < checkOldIndex[0].length; i++) {
                                                                arr.push(checkOldIndex[0][i].conditionElement.id)
                                                            }


                                                            let max = 0;
                                                            for (let i = 0; i < arr.length; i++) {
                                                                for (let j = 0; j < items.length; j++) {
                                                                    if (arr[i] == items[j].id && j > max) {
                                                                        max = j;
                                                                    }
                                                                }
                                                            }

                                                            if (newIndex > max) {
                                                                items = arrayMove(items, oldIndex, newIndex);
                                                                this.setState({
                                                                    items: items
                                                                }, () => this.detectChange())
                                                            } else {
                                                                this.setState({ messageModalError: true })
                                                            }
                                                        } else {
                                                            items = arrayMove(items, oldIndex, newIndex);
                                                            this.setState({
                                                                items: items
                                                            }, () => this.detectChange())
                                                        }

                                                    } else {
                                                        let arr = [];
                                                        if (checkOldIndex && checkOldIndex[1].length) {
                                                            for (let i = 0; i < checkOldIndex[1].length; i++) {
                                                                arr.push(checkOldIndex[1][i].element.id)
                                                            }
                                                            let min = items.length - 1;
                                                            for (let i = 0; i < arr.length; i++) {
                                                                for (let j = 0; j < items.length; j++) {
                                                                    if (arr[i] == items[j].id && j < min) {
                                                                        min = j;
                                                                    }
                                                                }
                                                            }
                                                            if (newIndex < min) {
                                                                items = arrayMove(items, oldIndex, newIndex);
                                                                this.setState({
                                                                    items: items
                                                                }, () => this.detectChange())
                                                            } else {
                                                                this.setState({ messageModalError: true })
                                                            }
                                                        } else {
                                                            items = arrayMove(items, oldIndex, newIndex);
                                                            this.setState({
                                                                items: items
                                                            }, () => this.detectChange())
                                                        }
                                                    }
                                                    document.body.style.cursor = 'default';

                                                    this.detectChange()

                                                }}
                                                onSortOver={({ index, oldIndex, newIndex }) => {


                                                    let items = this.state.items;

                                                    let haveTotal = null;
                                                    if (items && items.length && items[items.length - 1].type == 17) {
                                                        haveTotal = items.length - 1;
                                                    }

                                                    if (haveTotal && newIndex >= haveTotal) {
                                                        this.setState({ cursorNoDrop: true }, () => { document.body.style.cursor = 'no-drop'; })
                                                        return;
                                                    }

                                                    let checkOldIndex = this.checkRelations(items[index].id);
                                                    if (index > newIndex) {
                                                        let arr = [];
                                                        if (checkOldIndex && checkOldIndex[0].length) {
                                                            for (let i = 0; i < checkOldIndex[0].length; i++) {
                                                                arr.push(checkOldIndex[0][i].conditionElement.id)
                                                            }
                                                            let max = 0;
                                                            for (let i = 0; i < arr.length; i++) {
                                                                for (let j = 0; j < items.length; j++) {
                                                                    if (arr[i] == items[j].id && j > max) {
                                                                        max = j;
                                                                    }
                                                                }
                                                            }
                                                            if (newIndex > max) {
                                                                this.setState({ cursorNoDrop: false }, () => { document.body.style.cursor = 'default'; })
                                                            } else {
                                                                this.setState({ cursorNoDrop: true }, () => { document.body.style.cursor = 'no-drop'; })
                                                            }
                                                        } else {
                                                            this.setState({ cursorNoDrop: false }, () => { document.body.style.cursor = 'default'; })
                                                        }

                                                    } else {
                                                        let arr = [];
                                                        if (checkOldIndex && checkOldIndex[1].length) {
                                                            for (let i = 0; i < checkOldIndex[1].length; i++) {
                                                                arr.push(checkOldIndex[1][i].element.id)
                                                            }
                                                            let min = items.length - 1;
                                                            for (let i = 0; i < arr.length; i++) {
                                                                for (let j = 0; j < items.length; j++) {
                                                                    if (arr[i] == items[j].id && j < min) {
                                                                        min = j;
                                                                    }
                                                                }
                                                            }
                                                            if (newIndex < min) {
                                                                this.setState({ cursorNoDrop: false }, () => { document.body.style.cursor = 'default'; })
                                                            } else {
                                                                this.setState({ cursorNoDrop: true }, () => { document.body.style.cursor = 'no-drop'; })
                                                            }
                                                        } else {
                                                            this.setState({ cursorNoDrop: false }, () => { document.body.style.cursor = 'default'; })
                                                        }
                                                    }

                                                    this.detectChange()

                                                }}
                                                useDragHandle items={this.state.items.slice(0, this.state.numberOfElementsToRender * 10).map((item, idx) => {
                                                    let cssStyle = {};
                                                    if (item.fontSize) {
                                                        cssStyle.fontSize = item.fontSize;
                                                    }
                                                    if (item.fontWeight) {
                                                        cssStyle.fontWeight = item.fontWeight;
                                                    }
                                                    if (item.fontStyle) {
                                                        cssStyle.fontStyle = item.fontStyle;
                                                    }


                                                    return {
                                                        component: (
                                                            <div>
                                                                <div style={{ display: 'flex', marginTop: 15 }}>
                                                                    {
                                                                        item.type != 17 ?
                                                                            <div className="drag-handle">
                                                                                <DragHandle><i className="fa fa-arrows" /> </DragHandle>
                                                                            </div>
                                                                            :
                                                                            null
                                                                    }
                                                                    <span >{idx + 1}.</span>
                                                                </div>

                                                                {
                                                                    item.type == 0 ?
                                                                        <h4><input style={cssStyle} type="text" value={item.name} onChange={(e) => {
                                                                            let items = this.state.items;
                                                                            items[idx].name = e.target.value;
                                                                            this.setState({
                                                                                items
                                                                            }, () => this.detectChange())
                                                                        }} className="edit-field-name m-l-45" />
                                                                            <div className="text-options">
                                                                                <button className={item.fontStyle == 'italic' ? 'active' : ''} onClick={() => { let items = this.state.items; items[idx].fontStyle = items[idx].fontStyle == 'italic' ? null : 'italic'; this.setState({ items }, () => this.detectChange()) }}><i className="fa fa-italic" /></button>
                                                                                <button className={item.fontWeight == 'bold' ? 'active' : ''} onClick={() => { let items = this.state.items; items[idx].fontWeight = items[idx].fontWeight == 'bold' ? null : 'bold'; this.setState({ items }, () => this.detectChange()) }}><i className="fa fa-bold" /></button>

                                                                                <UncontrolledDropdown size="sm">
                                                                                    <DropdownToggle caret>
                                                                                        <i className="fa fa-font" />
                                                                                    </DropdownToggle>
                                                                                    <DropdownMenu>
                                                                                        <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 10; this.setState({ items }, () => this.detectChange()) }}>10px</DropdownItem>
                                                                                        <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 12; this.setState({ items }, () => this.detectChange()) }}>12px</DropdownItem>
                                                                                        <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 14; this.setState({ items }, () => this.detectChange()) }}>14px</DropdownItem>
                                                                                        <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 16; this.setState({ items }, () => this.detectChange()) }}>16px</DropdownItem>
                                                                                        <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 18; this.setState({ items }, () => this.detectChange()) }}>18px</DropdownItem>
                                                                                        <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 20; this.setState({ items }, () => this.detectChange()) }}>20px</DropdownItem>
                                                                                    </DropdownMenu>
                                                                                </UncontrolledDropdown>
                                                                            </div>



                                                                        </h4>
                                                                        :
                                                                        null
                                                                }
                                                                {
                                                                    item.type == 1 || item.type == 2 ?
                                                                        <FormGroup tag="fieldset">
                                                                            <Label size="sm"><input style={cssStyle} type="text" value={item.name} onChange={(e) => {
                                                                                let items = this.state.items;
                                                                                items[idx].name = e.target.value;
                                                                                this.setState({
                                                                                    items
                                                                                }, () => this.detectChange())
                                                                            }} className="edit-field-name" />
                                                                                {
                                                                                    item.type == 2 && this.state.intelligentReferral ?
                                                                                        <>

                                                                                            /

                                                                                            <input style={cssStyle} type="text" value={item.answerPrefix} placeholder={'Prefix for answers'.translate(this.props.lang)} onChange={(e) => {
                                                                                                let items = this.state.items;
                                                                                                items[idx].answerPrefix = e.target.value;
                                                                                                this.setState({
                                                                                                    items
                                                                                                }, () => this.detectChange())
                                                                                            }} className="edit-field-name" />

                                                                                        </>
                                                                                        :
                                                                                        null
                                                                                }
                                                                                <div className="text-options">
                                                                                    <button className={item.fontStyle == 'italic' ? 'active' : ''} onClick={() => { let items = this.state.items; items[idx].fontStyle = items[idx].fontStyle == 'italic' ? null : 'italic'; this.setState({ items }, () => this.detectChange()) }}><i className="fa fa-italic" /></button>
                                                                                    <button className={item.fontWeight == 'bold' ? 'active' : ''} onClick={() => { let items = this.state.items; items[idx].fontWeight = items[idx].fontWeight == 'bold' ? null : 'bold'; this.setState({ items }, () => this.detectChange()) }}><i className="fa fa-bold" /></button>

                                                                                    <UncontrolledDropdown size="sm">
                                                                                        <DropdownToggle caret>
                                                                                            <i className="fa fa-font" />
                                                                                        </DropdownToggle>
                                                                                        <DropdownMenu>
                                                                                            <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 10; this.setState({ items }, () => this.detectChange()) }}>10px</DropdownItem>
                                                                                            <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 12; this.setState({ items }, () => this.detectChange()) }}>12px</DropdownItem>
                                                                                            <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 14; this.setState({ items }, () => this.detectChange()) }}>14px</DropdownItem>
                                                                                            <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 16; this.setState({ items }, () => this.detectChange()) }}>16px</DropdownItem>
                                                                                            <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 18; this.setState({ items }, () => this.detectChange()) }}>18px</DropdownItem>
                                                                                            <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 20; this.setState({ items }, () => this.detectChange()) }}>20px</DropdownItem>
                                                                                        </DropdownMenu>
                                                                                    </UncontrolledDropdown>
                                                                                </div>


                                                                            </Label>
                                                                            <SortableAnswers onSortEnd={({ oldIndex, newIndex }) => {
                                                                                let items = this.state.items;
                                                                                let answers = JSON.parse(JSON.stringify(items[idx].answers));
                                                                                answers = arrayMove(answers, oldIndex, newIndex);
                                                                                // let tmp = answers[newIndex];
                                                                                // answers[newIndex] = answers[oldIndex];
                                                                                // answers[oldIndex] = tmp;
                                                                                items[idx].answers = answers
                                                                                this.setState({
                                                                                    items: items
                                                                                }, () => {
                                                                                    this.detectChange();
                                                                                    setTimeout(() => {
                                                                                        this.forceUpdate()
                                                                                    }, 50)
                                                                                })
                                                                            }} useDragHandle items={
                                                                                item.answers.map((answer, aidx) => {
                                                                                    return {
                                                                                        component: (
                                                                                            <FormGroup check key={answer.id}>

                                                                                                <Label size="sm" check>

                                                                                                    <Input disabled size="sm" name={item.id} type={item.type == 1 ? 'radio' : 'checkbox'} />
                                                                                                    {' '}

                                                                                                    {(this.state.linkingElement !== null && this.state.linkingElement !== idx && this.state.linkingElement > idx) ?
                                                                                                        <button className={(this.state.showLink && this.conditionIndexOf(this.state.showLink, item.id, answer.id) != -1) || (this.state.linkingElement !== null && this.state.items[this.state.linkingElement]?.conditions && this.state.items[this.state.linkingElement].conditions.length && this.conditionIndexOf(this.state.items[this.state.linkingElement].conditions, item.id, answer.id) != -1) ? 'linking-element active' : 'linking-element'} onClick={() => {
                                                                                                            let items = this.state.items;
                                                                                                            if (!items[this.state.linkingElement].conditions) {
                                                                                                                items[this.state.linkingElement].conditions = [];
                                                                                                            }

                                                                                                            let check = -1;
                                                                                                            for (let i = 0; i < items[this.state.linkingElement].conditions.length; i++) {
                                                                                                                if (items[this.state.linkingElement].conditions[i].conditionQuestion == item.id && items[this.state.linkingElement].conditions[i].conditionAnswer == answer.id) {
                                                                                                                    check = i;
                                                                                                                }
                                                                                                            }

                                                                                                            if (check != -1) {
                                                                                                                items[this.state.linkingElement].conditions.splice(check, 1);
                                                                                                            } else {
                                                                                                                items[this.state.linkingElement].conditions.push({
                                                                                                                    conditionAnswer: answer.id,
                                                                                                                    conditionQuestion: item.id
                                                                                                                })
                                                                                                            }

                                                                                                            /*items[this.state.linkingElement].conditionQuestion = item.id;
                                                                                                            items[this.state.linkingElement].conditionAnswer = answer.id;
                                                                                                            */
                                                                                                            this.setState({
                                                                                                                items,
                                                                                                                // linkingElement: null
                                                                                                            }, () => this.detectChange());

                                                                                                        }}>{answer.name}</button>
                                                                                                        :
                                                                                                        <>
                                                                                                            <input type="text" value={answer.name} placeholder={'Enter option name...'.translate(this.props.lang)} className={(this.state.showLink && this.conditionIndexOf(this.state.showLink, item.id, answer.id) != -1) ? 'edit-field-input edit-field-input-active' : "edit-field-input"} onChange={(e) => {
                                                                                                                let items = this.state.items;
                                                                                                                items[idx].answers[aidx].name = e.target.value;
                                                                                                                this.setState({
                                                                                                                    items
                                                                                                                }, () => this.detectChange())
                                                                                                            }} />

                                                                                                            {this.state.intelligentReferral ? <>
                                                                                                                <span className="slash">/</span>  <input type="text" value={answer.text} placeholder={'Enter text for printing on referral...'.translate(this.props.lang)} onChange={(e) => {
                                                                                                                    let items = this.state.items;
                                                                                                                    items[idx].answers[aidx].text = e.target.value;
                                                                                                                    this.setState({
                                                                                                                        items
                                                                                                                    }, () => this.detectChange())
                                                                                                                }} className={(this.state.showLink && this.conditionIndexOf(this.state.showLink, item.id, answer.id) != -1) ? 'edit-field-input edit-field-input-active' : "edit-field-input"} />
                                                                                                            </>
                                                                                                                :
                                                                                                                null
                                                                                                            }
                                                                                                        </>
                                                                                                    }

                                                                                                    <div className="answer-score-wrap">
                                                                                                        <Button color="primary" onClick={() => {
                                                                                                            let items = this.state.items;
                                                                                                            items[idx].answers[aidx].score = Number(items[idx].answers[aidx].score) - 1 <= -100 ? -100 : Number(items[idx].answers[aidx].score) - 1;
                                                                                                            this.setState({
                                                                                                                items
                                                                                                            }, () => {
                                                                                                                this.checkBlocksRange()
                                                                                                                this.detectChange()
                                                                                                            })
                                                                                                        }}>-</Button>
                                                                                                        <input type="text" value={answer.score} onChange={(e) => {
                                                                                                            let items = this.state.items;
                                                                                                            let val = String(e.target.value).match(/[-]{0,1}[\d]*[.]{0,1}[\d]+/g);
                                                                                                            val = val.length ? val[0] : 0;
                                                                                                            val = Number(val);
                                                                                                            items[idx].answers[aidx].score = val < -100 ? -100 : val > 100 ? 100 : val;
                                                                                                            this.setState({
                                                                                                                items
                                                                                                            }, () => {
                                                                                                                this.checkBlocksRange()
                                                                                                                this.detectChange()
                                                                                                            })

                                                                                                        }} />
                                                                                                        <Button color="primary" onClick={() => {
                                                                                                            let items = this.state.items;
                                                                                                            items[idx].answers[aidx].score = Number(items[idx].answers[aidx].score) + 1 >= 100 ? 100 : Number(items[idx].answers[aidx].score) + 1;
                                                                                                            this.setState({
                                                                                                                items
                                                                                                            }, () => {
                                                                                                                this.checkBlocksRange()
                                                                                                                this.detectChange()
                                                                                                            })
                                                                                                        }}>+</Button>
                                                                                                    </div>

                                                                                                    <button className='referral-builder-tooltip-wrap' onClick={() => {
                                                                                                        this.setState({
                                                                                                            deleteOptionModal: { idx, aidx },
                                                                                                        })
                                                                                                    }}><Isvg style={{ minWidth: 22 }} src={garabage} />
                                                                                                        <div className='referral-builder-tooltip'>{'Delete'.translate(this.props.lang)}</div>
                                                                                                    </button>
                                                                                                    <DragHandle>
                                                                                                        <div><Isvg src={arrows} className="arrows" /></div> </DragHandle>

                                                                                                </Label>
                                                                                            </FormGroup>
                                                                                        )
                                                                                    }

                                                                                })
                                                                            } />

                                                                            <div className="add-new-option-wrap">
                                                                                <button className="add-new-option" onClick={() => {
                                                                                    let items = this.state.items;
                                                                                    items[idx].answers.push({
                                                                                        id: uuidv4(),
                                                                                        name: '',
                                                                                        score: 0
                                                                                    });
                                                                                    this.setState({
                                                                                        items
                                                                                    }, () => this.detectChange())
                                                                                }}> {'Add new option'.translate(this.props.lang)} </button>
                                                                                {
                                                                                    item.type == 2 && item.answerPrefix && this.state.showLink && this.conditionIndexOf(this.state.showLink, item.id, '__user-input:') != -1 && !(this.state.linkingElement !== null && this.state.linkingElement !== idx && this.state.linkingElement > idx) ?
                                                                                        <button className={'linking-element active'} >{'user-input'.translate(this.props.lang)}</button>
                                                                                        :
                                                                                        null
                                                                                }
                                                                                {item.type == 2 && item.answerPrefix && (this.state.linkingElement !== null && this.state.linkingElement !== idx && this.state.linkingElement > idx) ?
                                                                                    <button className={(this.state.showLink && this.conditionIndexOf(this.state.showLink, item.id, '__user-input:') != -1) || (this.state.linkingElement !== null && this.state.items[this.state.linkingElement]?.conditions && this.state.items[this.state.linkingElement].conditions.length && this.conditionIndexOf(this.state.items[this.state.linkingElement].conditions, item.id, '__user-input:') != -1) ? 'linking-element active' : 'linking-element'} onClick={() => {
                                                                                        let items = this.state.items;
                                                                                        if (!items[this.state.linkingElement].conditions) {
                                                                                            items[this.state.linkingElement].conditions = [];
                                                                                        }

                                                                                        let check = -1;
                                                                                        for (let i = 0; i < items[this.state.linkingElement].conditions.length; i++) {
                                                                                            if (items[this.state.linkingElement].conditions[i].conditionQuestion == item.id && items[this.state.linkingElement].conditions[i].conditionAnswer == '__user-input:') {
                                                                                                check = i;
                                                                                            }
                                                                                        }

                                                                                        if (check != -1) {
                                                                                            items[this.state.linkingElement].conditions.splice(check, 1);
                                                                                        } else {
                                                                                            items[this.state.linkingElement].conditions.push({
                                                                                                conditionAnswer: '__user-input:',
                                                                                                conditionQuestion: item.id
                                                                                            })
                                                                                        }

                                                                                        this.setState({
                                                                                            items,
                                                                                            // linkingElement: null
                                                                                        }, () => this.detectChange());

                                                                                    }}>{'user-input'.translate(this.props.lang)}</button>
                                                                                    :
                                                                                    null
                                                                                }
                                                                                {item.type == 2 && item.answerPrefix ?
                                                                                    <FormGroup>

                                                                                        <Checkbox onChange={() => {
                                                                                            let items = this.state.items;
                                                                                            items[idx].userInput = !items[idx].userInput;
                                                                                            // console.log(items);
                                                                                            this.setState({ items }, () => this.detectChange())
                                                                                        }} checked={item.userInput ? true : false} label={'User can enter text answer'.translate(this.props.lang)} />
                                                                                    </FormGroup>
                                                                                    :
                                                                                    null
                                                                                }
                                                                            </div>

                                                                        </FormGroup>
                                                                        :
                                                                        null
                                                                }
                                                                {
                                                                    item.type == 3 || item.type == 5 ?
                                                                        <FormGroup tag="fieldset">
                                                                            <Label size="sm">

                                                                                {(this.state.linkingElement !== null && this.state.linkingElement !== idx && this.state.linkingElement > idx) || (this.state.showLink && this.conditionIndexOf(this.state.showLink, item.id, null) != -1) ?
                                                                                    <button className={(this.state.showLink && this.conditionIndexOf(this.state.showLink, item.id, null) != -1) || (this.state.linkingElement !== null && this.state.items[this.state.linkingElement]?.conditions && this.state.items[this.state.linkingElement].conditions.length && this.conditionIndexOf(this.state.items[this.state.linkingElement].conditions, item.id) != -1) ? 'linking-element active' : 'linking-element'} onClick={() => {
                                                                                        let items = this.state.items;
                                                                                        //items[this.state.linkingElement].conditionQuestion = item.id;

                                                                                        if (!items[this.state.linkingElement].conditions) {
                                                                                            items[this.state.linkingElement].conditions = [];
                                                                                        }

                                                                                        let check = -1;
                                                                                        for (let i = 0; i < items[this.state.linkingElement].conditions.length; i++) {
                                                                                            if (items[this.state.linkingElement].conditions[i].conditionQuestion == item.id) {
                                                                                                check = i;
                                                                                            }
                                                                                        }

                                                                                        if (check != -1) {
                                                                                            items[this.state.linkingElement].conditions.splice(check, 1);
                                                                                        } else {
                                                                                            items[this.state.linkingElement].conditions.push({
                                                                                                conditionQuestion: item.id
                                                                                            })
                                                                                        }


                                                                                        this.setState({
                                                                                            items,
                                                                                            //linkingElement: null
                                                                                        }, () => this.detectChange());

                                                                                    }}>{item.name}</button>

                                                                                    :
                                                                                    <>
                                                                                        <input style={cssStyle} type="text" value={item.name} onChange={(e) => {
                                                                                            let items = this.state.items;
                                                                                            items[idx].name = e.target.value;
                                                                                            this.setState({
                                                                                                items
                                                                                            }, () => this.detectChange())
                                                                                        }} className="edit-field-name" />
                                                                                        {
                                                                                            item.type == 3 && this.state.intelligentReferral ?
                                                                                                <>

                                                                                                    /

                                                                                                    <input style={cssStyle} type="text" value={item.answerPrefix} placeholder={'Prefix for answers'.translate(this.props.lang)} onChange={(e) => {
                                                                                                        let items = this.state.items;
                                                                                                        items[idx].answerPrefix = e.target.value;
                                                                                                        this.setState({
                                                                                                            items
                                                                                                        }, () => this.detectChange())
                                                                                                    }} className="edit-field-name" />

                                                                                                </>
                                                                                                :
                                                                                                null
                                                                                        }

                                                                                        <div className="text-options">
                                                                                            <button className={item.fontStyle == 'italic' ? 'active' : ''} onClick={() => { let items = this.state.items; items[idx].fontStyle = items[idx].fontStyle == 'italic' ? null : 'italic'; this.setState({ items }, () => this.detectChange()) }}><i className="fa fa-italic" /></button>
                                                                                            <button className={item.fontWeight == 'bold' ? 'active' : ''} onClick={() => { let items = this.state.items; items[idx].fontWeight = items[idx].fontWeight == 'bold' ? null : 'bold'; this.setState({ items }, () => this.detectChange()) }}><i className="fa fa-bold" /></button>

                                                                                            <UncontrolledDropdown size="sm">
                                                                                                <DropdownToggle caret>
                                                                                                    <i className="fa fa-font" />
                                                                                                </DropdownToggle>
                                                                                                <DropdownMenu>
                                                                                                    <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 10; this.setState({ items }, () => this.detectChange()) }}>10px</DropdownItem>
                                                                                                    <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 12; this.setState({ items }, () => this.detectChange()) }}>12px</DropdownItem>
                                                                                                    <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 14; this.setState({ items }, () => this.detectChange()) }}>14px</DropdownItem>
                                                                                                    <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 16; this.setState({ items }, () => this.detectChange()) }}>16px</DropdownItem>
                                                                                                    <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 18; this.setState({ items }, () => this.detectChange()) }}>18px</DropdownItem>
                                                                                                    <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 20; this.setState({ items }, () => this.detectChange()) }}>20px</DropdownItem>
                                                                                                </DropdownMenu>
                                                                                            </UncontrolledDropdown>
                                                                                        </div>
                                                                                        {
                                                                                            item.type == 5 ?
                                                                                                <div className="answer-score-wrap">
                                                                                                    <Button color="primary" onClick={() => {
                                                                                                        let items = this.state.items;
                                                                                                        items[idx].score = Number(items[idx].score) - 1 <= -100 ? -100 : Number(items[idx].score) - 1;
                                                                                                        this.setState({
                                                                                                            items
                                                                                                        }, () => {
                                                                                                            this.checkBlocksRange()
                                                                                                            this.detectChange()
                                                                                                        })
                                                                                                    }}>-</Button>
                                                                                                    <input type="text" value={item.score} onChange={(e) => {
                                                                                                        let items = this.state.items;
                                                                                                        let val = String(e.target.value).match(/[-]{0,1}[\d]*[.]{0,1}[\d]+/g);
                                                                                                        val = val.length ? val[0] : 0;
                                                                                                        val = Number(val);
                                                                                                        items[idx].score = val < -100 ? -100 : val > 100 ? 100 : val;
                                                                                                        this.setState({
                                                                                                            items
                                                                                                        }, () => {
                                                                                                            this.checkBlocksRange()
                                                                                                            this.detectChange()
                                                                                                        })

                                                                                                    }} />
                                                                                                    <Button color="primary" onClick={() => {
                                                                                                        let items = this.state.items;
                                                                                                        items[idx].score = Number(items[idx].score) + 1 >= 100 ? 100 : Number(items[idx].score) + 1;
                                                                                                        this.setState({
                                                                                                            items
                                                                                                        }, () => {
                                                                                                            this.checkBlocksRange()
                                                                                                            this.detectChange()
                                                                                                        })
                                                                                                    }}>+</Button>
                                                                                                </div>
                                                                                                :
                                                                                                null
                                                                                        }
                                                                                    </>

                                                                                }

                                                                            </Label>
                                                                            {
                                                                                item.type == 3 ?
                                                                                    <Input disabled size="sm" type="textarea"></Input>
                                                                                    :
                                                                                    <Input disabled size="sm" type="text"></Input>

                                                                            }


                                                                        </FormGroup>

                                                                        :
                                                                        null
                                                                }
                                                                {
                                                                    item.type == 8 ?
                                                                        <FormGroup tag="fieldset">
                                                                            <Label size="sm">

                                                                                {/* <input style={cssStyle} type="text" value={item.name} onChange={(e) => {
                                                                        let items = this.state.items;
                                                                        items[idx].name = e.target.value;
                                                                        this.setState({
                                                                            items
                                                                        })
                                                                    }} />*/}
                                                                                {(this.state.linkingElement !== null && this.state.linkingElement !== idx && this.state.linkingElement > idx) || (this.state.showLink && this.conditionIndexOf(this.state.showLink, item.id, null) != -1) ?
                                                                                    <button className={(this.state.showLink && this.conditionIndexOf(this.state.showLink, item.id, null) != -1) || (this.state.linkingElement !== null && this.state.items[this.state.linkingElement]?.conditions && this.state.items[this.state.linkingElement].conditions.length && this.conditionIndexOf(this.state.items[this.state.linkingElement].conditions, item.id) != -1) ? 'linking-element active' : 'linking-element'} onClick={() => {
                                                                                        let items = this.state.items;
                                                                                        //items[this.state.linkingElement].conditionQuestion = item.id;

                                                                                        if (!items[this.state.linkingElement].conditions) {
                                                                                            items[this.state.linkingElement].conditions = [];
                                                                                        }

                                                                                        let check = -1;
                                                                                        for (let i = 0; i < items[this.state.linkingElement].conditions.length; i++) {
                                                                                            if (items[this.state.linkingElement].conditions[i].conditionQuestion == item.id) {
                                                                                                check = i;
                                                                                            }
                                                                                        }

                                                                                        if (check != -1) {
                                                                                            items[this.state.linkingElement].conditions.splice(check, 1);
                                                                                        } else {
                                                                                            items[this.state.linkingElement].conditions.push({
                                                                                                conditionQuestion: item.id
                                                                                            })
                                                                                        }


                                                                                        this.setState({
                                                                                            items,
                                                                                            //linkingElement: null
                                                                                        }, () => this.detectChange());

                                                                                    }}>{item.name}</button>

                                                                                    :
                                                                                    <>
                                                                                        <input style={cssStyle} type="text" value={item.name} onChange={(e) => {
                                                                                            let items = this.state.items;
                                                                                            items[idx].name = e.target.value;
                                                                                            this.setState({
                                                                                                items
                                                                                            }, () => this.detectChange())
                                                                                        }} className="edit-field-name" />
                                                                                        <div className="text-options">
                                                                                            <button className={item.fontStyle == 'italic' ? 'active' : ''} onClick={() => { let items = this.state.items; items[idx].fontStyle = items[idx].fontStyle == 'italic' ? null : 'italic'; this.setState({ items }, () => this.detectChange()) }}><i className="fa fa-italic" /></button>
                                                                                            <button className={item.fontWeight == 'bold' ? 'active' : ''} onClick={() => { let items = this.state.items; items[idx].fontWeight = items[idx].fontWeight == 'bold' ? null : 'bold'; this.setState({ items }, () => this.detectChange()) }}><i className="fa fa-bold" /></button>

                                                                                            <UncontrolledDropdown size="sm">
                                                                                                <DropdownToggle caret>
                                                                                                    <i className="fa fa-font" />
                                                                                                </DropdownToggle>
                                                                                                <DropdownMenu>
                                                                                                    <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 10; this.setState({ items }, () => this.detectChange()) }}>10px</DropdownItem>
                                                                                                    <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 12; this.setState({ items }, () => this.detectChange()) }}>12px</DropdownItem>
                                                                                                    <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 14; this.setState({ items }, () => this.detectChange()) }}>14px</DropdownItem>
                                                                                                    <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 16; this.setState({ items }, () => this.detectChange()) }}>16px</DropdownItem>
                                                                                                    <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 18; this.setState({ items }, () => this.detectChange()) }}>18px</DropdownItem>
                                                                                                    <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 20; this.setState({ items }, () => this.detectChange()) }}>20px</DropdownItem>
                                                                                                </DropdownMenu>
                                                                                            </UncontrolledDropdown>
                                                                                        </div>

                                                                                    </>

                                                                                }

                                                                            </Label>

                                                                            <Input size="sm" type="file" title={"No file chosen".translate(this.props.lang)} accept=".doc, .docx, .pdf" >{'Select file'.translate(this.props.lang)}</Input>


                                                                        </FormGroup>

                                                                        :
                                                                        null
                                                                }
                                                                {
                                                                    item.type == 9 ?
                                                                        <FormGroup tag="fieldset">
                                                                            <Label size="sm">
                                                                                {/*
                                                                    <input style={cssStyle} type="text" value={item.name} onChange={(e) => {
                                                                        let items = this.state.items;
                                                                        items[idx].name = e.target.value;
                                                                        this.setState({
                                                                            items
                                                                        })
                                                                    }} />*/}
                                                                                {(this.state.linkingElement !== null && this.state.linkingElement !== idx && this.state.linkingElement > idx) || (this.state.showLink && this.conditionIndexOf(this.state.showLink, item.id, null) != -1) ?
                                                                                    <button className={(this.state.showLink && this.conditionIndexOf(this.state.showLink, item.id, null) != -1) || (this.state.linkingElement !== null && this.state.items[this.state.linkingElement]?.conditions && this.state.items[this.state.linkingElement].conditions.length && this.conditionIndexOf(this.state.items[this.state.linkingElement].conditions, item.id) != -1) ? 'linking-element active' : 'linking-element'} onClick={() => {
                                                                                        let items = this.state.items;
                                                                                        //items[this.state.linkingElement].conditionQuestion = item.id;

                                                                                        if (!items[this.state.linkingElement].conditions) {
                                                                                            items[this.state.linkingElement].conditions = [];
                                                                                        }

                                                                                        let check = -1;
                                                                                        for (let i = 0; i < items[this.state.linkingElement].conditions.length; i++) {
                                                                                            if (items[this.state.linkingElement].conditions[i].conditionQuestion == item.id) {
                                                                                                check = i;
                                                                                            }
                                                                                        }

                                                                                        if (check != -1) {
                                                                                            items[this.state.linkingElement].conditions.splice(check, 1);
                                                                                        } else {
                                                                                            items[this.state.linkingElement].conditions.push({
                                                                                                conditionQuestion: item.id
                                                                                            })
                                                                                        }


                                                                                        this.setState({
                                                                                            items,
                                                                                            //linkingElement: null
                                                                                        }, () => this.detectChange());

                                                                                    }}>{item.name}</button>

                                                                                    :
                                                                                    <>
                                                                                        <input style={cssStyle} type="text" value={item.name} onChange={(e) => {
                                                                                            let items = this.state.items;
                                                                                            items[idx].name = e.target.value;
                                                                                            this.setState({
                                                                                                items
                                                                                            }, () => this.detectChange())
                                                                                        }} className="edit-field-name" />
                                                                                        <div className="text-options">
                                                                                            <button className={item.fontStyle == 'italic' ? 'active' : ''} onClick={() => { let items = this.state.items; items[idx].fontStyle = items[idx].fontStyle == 'italic' ? null : 'italic'; this.setState({ items }, () => this.detectChange()) }}><i className="fa fa-italic" /></button>
                                                                                            <button className={item.fontWeight == 'bold' ? 'active' : ''} onClick={() => { let items = this.state.items; items[idx].fontWeight = items[idx].fontWeight == 'bold' ? null : 'bold'; this.setState({ items }, () => this.detectChange()) }}><i className="fa fa-bold" /></button>

                                                                                            <UncontrolledDropdown size="sm">
                                                                                                <DropdownToggle caret>
                                                                                                    <i className="fa fa-font" />
                                                                                                </DropdownToggle>
                                                                                                <DropdownMenu>
                                                                                                    <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 10; this.setState({ items }, () => this.detectChange()) }}>10px</DropdownItem>
                                                                                                    <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 12; this.setState({ items }, () => this.detectChange()) }}>12px</DropdownItem>
                                                                                                    <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 14; this.setState({ items }, () => this.detectChange()) }}>14px</DropdownItem>
                                                                                                    <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 16; this.setState({ items }, () => this.detectChange()) }}>16px</DropdownItem>
                                                                                                    <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 18; this.setState({ items }, () => this.detectChange()) }}>18px</DropdownItem>
                                                                                                    <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 20; this.setState({ items }, () => this.detectChange()) }}>20px</DropdownItem>
                                                                                                </DropdownMenu>
                                                                                            </UncontrolledDropdown>
                                                                                        </div>

                                                                                    </>

                                                                                }

                                                                            </Label>

                                                                            <Input size="sm" type="file" title={"No file chosen".translate(this.props.lang)} accept="image/png, image/jpeg, image/jpg, image/gif, image/svg, .doc, .docx, .pdf" >{'Select Image'.translate(this.props.lang)}</Input>


                                                                        </FormGroup>

                                                                        :
                                                                        null
                                                                }
                                                                {
                                                                    item.type == 6 ?
                                                                        <FormGroup tag="fieldset">
                                                                            <Label size="sm">

                                                                                {(this.state.linkingElement !== null && this.state.linkingElement !== idx && this.state.linkingElement > idx) || (this.state.showLink && this.conditionIndexOf(this.state.showLink, item.id, null) != -1) ?
                                                                                    <button className={(this.state.showLink && this.conditionIndexOf(this.state.showLink, item.id, null) != -1) || (this.state.linkingElement !== null && this.state.items[this.state.linkingElement]?.conditions && this.state.items[this.state.linkingElement].conditions.length && this.conditionIndexOf(this.state.items[this.state.linkingElement].conditions, item.id) != -1) ? 'linking-element active' : 'linking-element'} onClick={() => {
                                                                                        let items = this.state.items;
                                                                                        //items[this.state.linkingElement].conditionQuestion = item.id;

                                                                                        if (!items[this.state.linkingElement].conditions) {
                                                                                            items[this.state.linkingElement].conditions = [];
                                                                                        }

                                                                                        let check = -1;
                                                                                        for (let i = 0; i < items[this.state.linkingElement].conditions.length; i++) {
                                                                                            if (items[this.state.linkingElement].conditions[i].conditionQuestion == item.id) {
                                                                                                check = i;
                                                                                            }
                                                                                        }

                                                                                        if (check != -1) {
                                                                                            items[this.state.linkingElement].conditions.splice(check, 1);
                                                                                        } else {
                                                                                            items[this.state.linkingElement].conditions.push({
                                                                                                conditionQuestion: item.id
                                                                                            })
                                                                                        }


                                                                                        this.setState({
                                                                                            items,
                                                                                            //linkingElement: null
                                                                                        }, () => this.detectChange());

                                                                                    }}>{item.name}</button>

                                                                                    :
                                                                                    <>
                                                                                        <input style={cssStyle} type="text" value={item.name} onChange={(e) => {
                                                                                            let items = this.state.items;
                                                                                            items[idx].name = e.target.value;
                                                                                            this.setState({
                                                                                                items
                                                                                            }, () => this.detectChange())
                                                                                        }} className="edit-field-name" />
                                                                                        <div className="text-options">
                                                                                            <button className={item.fontStyle == 'italic' ? 'active' : ''} onClick={() => { let items = this.state.items; items[idx].fontStyle = items[idx].fontStyle == 'italic' ? null : 'italic'; this.setState({ items }, () => this.detectChange()) }}><i className="fa fa-italic" /></button>
                                                                                            <button className={item.fontWeight == 'bold' ? 'active' : ''} onClick={() => { let items = this.state.items; items[idx].fontWeight = items[idx].fontWeight == 'bold' ? null : 'bold'; this.setState({ items }, () => this.detectChange()) }}><i className="fa fa-bold" /></button>

                                                                                            <UncontrolledDropdown size="sm">
                                                                                                <DropdownToggle caret>
                                                                                                    <i className="fa fa-font" />
                                                                                                </DropdownToggle>
                                                                                                <DropdownMenu>
                                                                                                    <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 10; this.setState({ items }, () => this.detectChange()) }}>10px</DropdownItem>
                                                                                                    <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 12; this.setState({ items }, () => this.detectChange()) }}>12px</DropdownItem>
                                                                                                    <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 14; this.setState({ items }, () => this.detectChange()) }}>14px</DropdownItem>
                                                                                                    <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 16; this.setState({ items }, () => this.detectChange()) }}>16px</DropdownItem>
                                                                                                    <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 18; this.setState({ items }, () => this.detectChange()) }}>18px</DropdownItem>
                                                                                                    <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 20; this.setState({ items }, () => this.detectChange()) }}>20px</DropdownItem>
                                                                                                </DropdownMenu>
                                                                                            </UncontrolledDropdown>
                                                                                        </div>
                                                                                        <div className="answer-score-wrap">
                                                                                            <Button color="primary" onClick={() => {
                                                                                                let items = this.state.items;
                                                                                                items[idx].score = Number(items[idx].score) - 1 <= -100 ? -100 : Number(items[idx].score) - 1;
                                                                                                this.setState({
                                                                                                    items
                                                                                                }, () => {
                                                                                                    this.checkBlocksRange()
                                                                                                    this.detectChange()
                                                                                                })
                                                                                            }}>-</Button>
                                                                                            <input type="text" value={item.score} onChange={(e) => {
                                                                                                let items = this.state.items;
                                                                                                let val = String(e.target.value).match(/[-]{0,1}[\d]*[.]{0,1}[\d]+/g);
                                                                                                val = val.length ? val[0] : 0;
                                                                                                val = Number(val);
                                                                                                items[idx].score = val < -100 ? -100 : val > 100 ? 100 : val;
                                                                                                this.setState({
                                                                                                    items
                                                                                                }, () => {
                                                                                                    this.checkBlocksRange()
                                                                                                    this.detectChange()
                                                                                                })

                                                                                            }} />
                                                                                            <Button color="primary" onClick={() => {
                                                                                                let items = this.state.items;
                                                                                                items[idx].score = Number(items[idx].score) + 1 >= 100 ? 100 : Number(items[idx].score) + 1;
                                                                                                this.setState({
                                                                                                    items
                                                                                                }, () => {
                                                                                                    this.checkBlocksRange()
                                                                                                    this.detectChange()
                                                                                                })
                                                                                            }}>+</Button>
                                                                                        </div>
                                                                                    </>

                                                                                }


                                                                            </Label>
                                                                            <Row>
                                                                                <Col lg="2">
                                                                                    <FormGroup>
                                                                                        <Label>{'Weight:'.translate(this.props.lang)}</Label>
                                                                                        <Input disabled size="sm" type="text"></Input>

                                                                                    </FormGroup>
                                                                                </Col>
                                                                                <Col lg="2">
                                                                                    <FormGroup>
                                                                                        <Label>{'Height:'.translate(this.props.lang)}</Label>
                                                                                        <Input disabled size="sm" type="text"></Input>

                                                                                    </FormGroup>

                                                                                </Col>
                                                                            </Row>

                                                                        </FormGroup>

                                                                        :
                                                                        null
                                                                }

                                                                {
                                                                    item.type == 12 ?
                                                                        <FormGroup tag="fieldset">
                                                                            <Label size="sm">


                                                                                {(this.state.linkingElement !== null && this.state.linkingElement !== idx && this.state.linkingElement > idx) || (this.state.showLink && this.conditionIndexOf(this.state.showLink, item.id, null) != -1) ?
                                                                                    <button className={(this.state.showLink && this.conditionIndexOf(this.state.showLink, item.id, null) != -1) || (this.state.linkingElement !== null && this.state.items[this.state.linkingElement]?.conditions && this.state.items[this.state.linkingElement].conditions.length && this.conditionIndexOf(this.state.items[this.state.linkingElement].conditions, item.id) != -1) ? 'linking-element active' : 'linking-element'} onClick={() => {
                                                                                        let items = this.state.items;
                                                                                        //items[this.state.linkingElement].conditionQuestion = item.id;

                                                                                        if (!items[this.state.linkingElement].conditions) {
                                                                                            items[this.state.linkingElement].conditions = [];
                                                                                        }

                                                                                        let check = -1;
                                                                                        for (let i = 0; i < items[this.state.linkingElement].conditions.length; i++) {
                                                                                            if (items[this.state.linkingElement].conditions[i].conditionQuestion == item.id) {
                                                                                                check = i;
                                                                                            }
                                                                                        }

                                                                                        if (check != -1) {
                                                                                            items[this.state.linkingElement].conditions.splice(check, 1);
                                                                                        } else {
                                                                                            items[this.state.linkingElement].conditions.push({
                                                                                                conditionQuestion: item.id
                                                                                            })
                                                                                        }


                                                                                        this.setState({
                                                                                            items,
                                                                                            //linkingElement: null
                                                                                        }, () => this.detectChange());

                                                                                    }}>{item.name}</button>

                                                                                    :
                                                                                    <>
                                                                                        <input style={cssStyle} type="text" value={item.name} onChange={(e) => {
                                                                                            let items = this.state.items;
                                                                                            items[idx].name = e.target.value;
                                                                                            this.setState({
                                                                                                items
                                                                                            }, () => this.detectChange())
                                                                                        }} className="edit-field-name" />
                                                                                        <div className="text-options">
                                                                                            <button className={item.fontStyle == 'italic' ? 'active' : ''} onClick={() => { let items = this.state.items; items[idx].fontStyle = items[idx].fontStyle == 'italic' ? null : 'italic'; this.setState({ items }, () => this.detectChange()) }}><i className="fa fa-italic" /></button>
                                                                                            <button className={item.fontWeight == 'bold' ? 'active' : ''} onClick={() => { let items = this.state.items; items[idx].fontWeight = items[idx].fontWeight == 'bold' ? null : 'bold'; this.setState({ items }, () => this.detectChange()) }}><i className="fa fa-bold" /></button>

                                                                                            <UncontrolledDropdown size="sm">
                                                                                                <DropdownToggle caret>
                                                                                                    <i className="fa fa-font" />
                                                                                                </DropdownToggle>
                                                                                                <DropdownMenu>
                                                                                                    <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 10; this.setState({ items }, () => this.detectChange()) }}>10px</DropdownItem>
                                                                                                    <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 12; this.setState({ items }, () => this.detectChange()) }}>12px</DropdownItem>
                                                                                                    <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 14; this.setState({ items }, () => this.detectChange()) }}>14px</DropdownItem>
                                                                                                    <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 16; this.setState({ items }, () => this.detectChange()) }}>16px</DropdownItem>
                                                                                                    <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 18; this.setState({ items }, () => this.detectChange()) }}>18px</DropdownItem>
                                                                                                    <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 20; this.setState({ items }, () => this.detectChange()) }}>20px</DropdownItem>
                                                                                                </DropdownMenu>
                                                                                            </UncontrolledDropdown>
                                                                                        </div>
                                                                                        <div className="answer-score-wrap">
                                                                                            <Button color="primary" onClick={() => {
                                                                                                let items = this.state.items;
                                                                                                items[idx].score = Number(items[idx].score) - 1 <= -100 ? -100 : Number(items[idx].score) - 1;
                                                                                                this.setState({
                                                                                                    items
                                                                                                }, () => {
                                                                                                    this.checkBlocksRange()
                                                                                                    this.detectChange()
                                                                                                })
                                                                                            }}>-</Button>
                                                                                            <input type="text" value={item.score} onChange={(e) => {
                                                                                                let items = this.state.items;
                                                                                                let val = String(e.target.value).match(/[-]{0,1}[\d]*[.]{0,1}[\d]+/g);
                                                                                                val = val.length ? val[0] : 0;
                                                                                                val = Number(val);
                                                                                                items[idx].score = val < -100 ? -100 : val > 100 ? 100 : val;
                                                                                                this.setState({
                                                                                                    items
                                                                                                }, () => {
                                                                                                    this.checkBlocksRange()
                                                                                                    this.detectChange()
                                                                                                })

                                                                                            }} />
                                                                                            <Button color="primary" onClick={() => {
                                                                                                let items = this.state.items;
                                                                                                items[idx].score = Number(items[idx].score) + 1 >= 100 ? 100 : Number(items[idx].score) + 1;
                                                                                                this.setState({
                                                                                                    items
                                                                                                }, () => {
                                                                                                    this.checkBlocksRange()
                                                                                                    this.detectChange()
                                                                                                })
                                                                                            }}>+</Button>
                                                                                        </div>
                                                                                    </>

                                                                                }



                                                                            </Label>
                                                                            <Row>
                                                                                <Col lg="2">
                                                                                    <FormGroup>
                                                                                        <Label>{'Systolic:'.translate(this.props.lang)}</Label>
                                                                                        <Input disabled size="sm" type="text"></Input>

                                                                                    </FormGroup>
                                                                                </Col>
                                                                                <Col lg="2">
                                                                                    <FormGroup>
                                                                                        <Label>{'Diastolic:'.translate(this.props.lang)}</Label>
                                                                                        <Input disabled size="sm" type="text"></Input>

                                                                                    </FormGroup>

                                                                                </Col>
                                                                                <Col lg="2">
                                                                                    <FormGroup>
                                                                                        <Label>{'Pulse:'.translate(this.props.lang)}</Label>
                                                                                        <Input disabled size="sm" type="text"></Input>

                                                                                    </FormGroup>

                                                                                </Col>
                                                                            </Row>

                                                                        </FormGroup>

                                                                        :
                                                                        null
                                                                }
                                                                {
                                                                    item.type == 14 ?
                                                                        <FormGroup tag="fieldset">
                                                                            <Label size="sm">
                                                                                {(this.state.linkingElement !== null && this.state.linkingElement !== idx && this.state.linkingElement > idx) || (this.state.showLink && this.conditionIndexOf(this.state.showLink, item.id, null) != -1) ?
                                                                                    <button className={(this.state.showLink && this.conditionIndexOf(this.state.showLink, item.id, null) != -1) || (this.state.linkingElement !== null && this.state.items[this.state.linkingElement]?.conditions && this.state.items[this.state.linkingElement].conditions.length && this.conditionIndexOf(this.state.items[this.state.linkingElement].conditions, item.id) != -1) ? 'linking-element active' : 'linking-element'} onClick={() => {
                                                                                        let items = this.state.items;
                                                                                        //items[this.state.linkingElement].conditionQuestion = item.id;

                                                                                        if (!items[this.state.linkingElement].conditions) {
                                                                                            items[this.state.linkingElement].conditions = [];
                                                                                        }

                                                                                        let check = -1;
                                                                                        for (let i = 0; i < items[this.state.linkingElement].conditions.length; i++) {
                                                                                            if (items[this.state.linkingElement].conditions[i].conditionQuestion == item.id) {
                                                                                                check = i;
                                                                                            }
                                                                                        }

                                                                                        if (check != -1) {
                                                                                            items[this.state.linkingElement].conditions.splice(check, 1);
                                                                                        } else {
                                                                                            items[this.state.linkingElement].conditions.push({
                                                                                                conditionQuestion: item.id
                                                                                            })
                                                                                        }


                                                                                        this.setState({
                                                                                            items,
                                                                                            //linkingElement: null
                                                                                        }, () => this.detectChange());

                                                                                    }}>{item.name}</button>

                                                                                    :
                                                                                    <>
                                                                                        <input style={cssStyle} type="text" value={item.name} onChange={(e) => {
                                                                                            let items = this.state.items;
                                                                                            items[idx].name = e.target.value;
                                                                                            this.setState({
                                                                                                items
                                                                                            }, () => this.detectChange())
                                                                                        }} className="edit-field-name" />
                                                                                        <div className="text-options">
                                                                                            <button className={item.fontStyle == 'italic' ? 'active' : ''} onClick={() => { let items = this.state.items; items[idx].fontStyle = items[idx].fontStyle == 'italic' ? null : 'italic'; this.setState({ items }, () => this.detectChange()) }}><i className="fa fa-italic" /></button>
                                                                                            <button className={item.fontWeight == 'bold' ? 'active' : ''} onClick={() => { let items = this.state.items; items[idx].fontWeight = items[idx].fontWeight == 'bold' ? null : 'bold'; this.setState({ items }, () => this.detectChange()) }}><i className="fa fa-bold" /></button>

                                                                                            <UncontrolledDropdown size="sm">
                                                                                                <DropdownToggle caret>
                                                                                                    <i className="fa fa-font" />
                                                                                                </DropdownToggle>
                                                                                                <DropdownMenu>
                                                                                                    <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 10; this.setState({ items }, () => this.detectChange()) }}>10px</DropdownItem>
                                                                                                    <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 12; this.setState({ items }, () => this.detectChange()) }}>12px</DropdownItem>
                                                                                                    <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 14; this.setState({ items }, () => this.detectChange()) }}>14px</DropdownItem>
                                                                                                    <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 16; this.setState({ items }, () => this.detectChange()) }}>16px</DropdownItem>
                                                                                                    <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 18; this.setState({ items }, () => this.detectChange()) }}>18px</DropdownItem>
                                                                                                    <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 20; this.setState({ items }, () => this.detectChange()) }}>20px</DropdownItem>
                                                                                                </DropdownMenu>
                                                                                            </UncontrolledDropdown>
                                                                                        </div>
                                                                                        <div className="answer-score-wrap">
                                                                                            <Button color="primary" onClick={() => {
                                                                                                let items = this.state.items;
                                                                                                items[idx].score = Number(items[idx].score) - 1 <= -100 ? -100 : Number(items[idx].score) - 1;
                                                                                                this.setState({
                                                                                                    items
                                                                                                }, () => {
                                                                                                    this.checkBlocksRange()
                                                                                                    this.detectChange()
                                                                                                })
                                                                                            }}>-</Button>
                                                                                            <input type="text" value={item.score} onChange={(e) => {
                                                                                                let items = this.state.items;
                                                                                                let val = String(e.target.value).match(/[-]{0,1}[\d]*[.]{0,1}[\d]+/g);
                                                                                                val = val.length ? val[0] : 0;
                                                                                                val = Number(val);
                                                                                                items[idx].score = val < -100 ? -100 : val > 100 ? 100 : val;
                                                                                                this.setState({
                                                                                                    items
                                                                                                }, () => {
                                                                                                    this.checkBlocksRange()
                                                                                                    this.detectChange()
                                                                                                })

                                                                                            }} />
                                                                                            <Button color="primary" onClick={() => {
                                                                                                let items = this.state.items;
                                                                                                items[idx].score = Number(items[idx].score) + 1 >= 100 ? 100 : Number(items[idx].score) + 1;
                                                                                                this.setState({
                                                                                                    items
                                                                                                }, () => {
                                                                                                    this.checkBlocksRange()
                                                                                                    this.detectChange()
                                                                                                })
                                                                                            }}>+</Button>
                                                                                        </div>

                                                                                    </>

                                                                                }

                                                                            </Label>

                                                                            <Col>
                                                                                <Input type='select' disabled min={0} max={100} />
                                                                            </Col>

                                                                        </FormGroup>

                                                                        :
                                                                        null
                                                                }
                                                                {
                                                                    item.type == 13 ?
                                                                        <FormGroup tag="fieldset">
                                                                            <Label size="sm">


                                                                                {(this.state.linkingElement !== null && this.state.linkingElement !== idx && this.state.linkingElement > idx) || (this.state.showLink && this.conditionIndexOf(this.state.showLink, item.id, null) != -1) ?
                                                                                    <button className={(this.state.showLink && this.conditionIndexOf(this.state.showLink, item.id, null) != -1) || (this.state.linkingElement !== null && this.state.items[this.state.linkingElement]?.conditions && this.state.items[this.state.linkingElement].conditions.length && this.conditionIndexOf(this.state.items[this.state.linkingElement].conditions, item.id) != -1) ? 'linking-element active' : 'linking-element'} onClick={() => {
                                                                                        let items = this.state.items;
                                                                                        //items[this.state.linkingElement].conditionQuestion = item.id;

                                                                                        if (!items[this.state.linkingElement].conditions) {
                                                                                            items[this.state.linkingElement].conditions = [];
                                                                                        }

                                                                                        let check = -1;
                                                                                        for (let i = 0; i < items[this.state.linkingElement].conditions.length; i++) {
                                                                                            if (items[this.state.linkingElement].conditions[i].conditionQuestion == item.id) {
                                                                                                check = i;
                                                                                            }
                                                                                        }

                                                                                        if (check != -1) {
                                                                                            items[this.state.linkingElement].conditions.splice(check, 1);
                                                                                        } else {
                                                                                            items[this.state.linkingElement].conditions.push({
                                                                                                conditionQuestion: item.id
                                                                                            })
                                                                                        }


                                                                                        this.setState({
                                                                                            items,
                                                                                            //linkingElement: null
                                                                                        }, () => this.detectChange());

                                                                                    }}>{item.name}</button>

                                                                                    :
                                                                                    <>
                                                                                        <input style={cssStyle} type="text" value={item.name} onChange={(e) => {
                                                                                            let items = this.state.items;
                                                                                            items[idx].name = e.target.value;
                                                                                            this.setState({
                                                                                                items
                                                                                            }, () => this.detectChange())
                                                                                        }} className="edit-field-name" />
                                                                                        <div className="text-options">
                                                                                            <button className={item.fontStyle == 'italic' ? 'active' : ''} onClick={() => { let items = this.state.items; items[idx].fontStyle = items[idx].fontStyle == 'italic' ? null : 'italic'; this.setState({ items }, () => this.detectChange()) }}><i className="fa fa-italic" /></button>
                                                                                            <button className={item.fontWeight == 'bold' ? 'active' : ''} onClick={() => { let items = this.state.items; items[idx].fontWeight = items[idx].fontWeight == 'bold' ? null : 'bold'; this.setState({ items }, () => this.detectChange()) }}><i className="fa fa-bold" /></button>

                                                                                            <UncontrolledDropdown size="sm">
                                                                                                <DropdownToggle caret>
                                                                                                    <i className="fa fa-font" />
                                                                                                </DropdownToggle>
                                                                                                <DropdownMenu>
                                                                                                    <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 10; this.setState({ items }, () => this.detectChange()) }}>10px</DropdownItem>
                                                                                                    <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 12; this.setState({ items }, () => this.detectChange()) }}>12px</DropdownItem>
                                                                                                    <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 14; this.setState({ items }, () => this.detectChange()) }}>14px</DropdownItem>
                                                                                                    <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 16; this.setState({ items }, () => this.detectChange()) }}>16px</DropdownItem>
                                                                                                    <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 18; this.setState({ items }, () => this.detectChange()) }}>18px</DropdownItem>
                                                                                                    <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 20; this.setState({ items }, () => this.detectChange()) }}>20px</DropdownItem>
                                                                                                </DropdownMenu>
                                                                                            </UncontrolledDropdown>
                                                                                        </div>
                                                                                        <div className="answer-score-wrap">
                                                                                            <Button color="primary" onClick={() => {
                                                                                                let items = this.state.items;
                                                                                                items[idx].score = Number(items[idx].score) - 1 <= -100 ? -100 : Number(items[idx].score) - 1;
                                                                                                this.setState({
                                                                                                    items
                                                                                                }, () => {
                                                                                                    this.checkBlocksRange()
                                                                                                    this.detectChange()
                                                                                                })
                                                                                            }}>-</Button>
                                                                                            <input type="text" value={item.score} onChange={(e) => {
                                                                                                let items = this.state.items;
                                                                                                let val = String(e.target.value).match(/[-]{0,1}[\d]*[.]{0,1}[\d]+/g);
                                                                                                val = val.length ? val[0] : 0;
                                                                                                val = Number(val);
                                                                                                items[idx].score = val < -100 ? -100 : val > 100 ? 100 : val;
                                                                                                this.setState({
                                                                                                    items
                                                                                                }, () => {
                                                                                                    this.checkBlocksRange()
                                                                                                    this.detectChange()
                                                                                                })

                                                                                            }} />
                                                                                            <Button color="primary" onClick={() => {
                                                                                                let items = this.state.items;
                                                                                                items[idx].score = Number(items[idx].score) + 1 >= 100 ? 100 : Number(items[idx].score) + 1;
                                                                                                this.setState({
                                                                                                    items
                                                                                                }, () => {
                                                                                                    this.checkBlocksRange()
                                                                                                    this.detectChange()
                                                                                                })
                                                                                            }}>+</Button>
                                                                                        </div>
                                                                                    </>

                                                                                }



                                                                            </Label>
                                                                            <Row>
                                                                                <Col lg="2">
                                                                                    <FormGroup>
                                                                                        {/* <Label>{'Systolic:'.translate(this.props.lang)}</Label> */}
                                                                                        <Input disabled size="sm" type="text"></Input>

                                                                                    </FormGroup>
                                                                                </Col>

                                                                            </Row>

                                                                        </FormGroup>

                                                                        :
                                                                        null
                                                                }

                                                                {
                                                                    item.type == 10 ?
                                                                        <div className='new-paragraph-checkbox'>
                                                                            <h4>
                                                                                <input style={cssStyle} type="text" value={item.name} className="new-line-field" disabled />
                                                                            </h4>

                                                                            <Checkbox onChange={() => {
                                                                                let items = this.state.items;
                                                                                items[idx].userInput = !items[idx].userInput;
                                                                                this.setState({ items }, () => this.detectChange())
                                                                            }} checked={item.userInput ? true : false} label={"Add an empty line".translate(this.props.lang)} />
                                                                        </div>
                                                                        // <h4><input style={cssStyle} type="text" value={item.name} className="new-line-field m-l-45" disabled />


                                                                        // </h4>
                                                                        :
                                                                        null
                                                                }


                                                                {
                                                                    item.type == 4 ?

                                                                        <FormGroup tag="fieldset">
                                                                            <Label size="sm">

                                                                                {(this.state.linkingElement !== null && this.state.linkingElement !== idx && this.state.linkingElement > idx) || (this.state.showLink && this.conditionIndexOf(this.state.showLink, item.id, null) != -1) ?
                                                                                    <button className={(this.state.showLink && this.conditionIndexOf(this.state.showLink, item.id, null) != -1) || (this.state.linkingElement !== null && this.state.items[this.state.linkingElement]?.conditions && this.state.items[this.state.linkingElement].conditions.length && this.conditionIndexOf(this.state.items[this.state.linkingElement].conditions, item.id) != -1) ? 'linking-element active' : 'linking-element'} onClick={() => {
                                                                                        let items = this.state.items;
                                                                                        //items[this.state.linkingElement].conditionQuestion = item.id;

                                                                                        if (!items[this.state.linkingElement].conditions) {
                                                                                            items[this.state.linkingElement].conditions = [];
                                                                                        }

                                                                                        let check = -1;
                                                                                        for (let i = 0; i < items[this.state.linkingElement].conditions.length; i++) {
                                                                                            if (items[this.state.linkingElement].conditions[i].conditionQuestion == item.id) {
                                                                                                check = i;
                                                                                            }
                                                                                        }

                                                                                        if (check != -1) {
                                                                                            items[this.state.linkingElement].conditions.splice(check, 1);
                                                                                        } else {
                                                                                            items[this.state.linkingElement].conditions.push({
                                                                                                conditionQuestion: item.id
                                                                                            })
                                                                                        }


                                                                                        this.setState({
                                                                                            items,
                                                                                            //linkingElement: null
                                                                                        }, () => this.detectChange());

                                                                                    }}>{item.name}</button>

                                                                                    :
                                                                                    <>
                                                                                        <input style={cssStyle} type="text" value={item.name} onChange={(e) => {
                                                                                            let items = this.state.items;
                                                                                            items[idx].name = e.target.value;
                                                                                            this.setState({
                                                                                                items
                                                                                            }, () => this.detectChange())
                                                                                        }} className="edit-field-name" />
                                                                                        <div className="text-options">
                                                                                            <button className={item.fontStyle == 'italic' ? 'active' : ''} onClick={() => { let items = this.state.items; items[idx].fontStyle = items[idx].fontStyle == 'italic' ? null : 'italic'; this.setState({ items }, () => this.detectChange()) }}><i className="fa fa-italic" /></button>
                                                                                            <button className={item.fontWeight == 'bold' ? 'active' : ''} onClick={() => { let items = this.state.items; items[idx].fontWeight = items[idx].fontWeight == 'bold' ? null : 'bold'; this.setState({ items }, () => this.detectChange()) }}><i className="fa fa-bold" /></button>

                                                                                            <UncontrolledDropdown size="sm">
                                                                                                <DropdownToggle caret>
                                                                                                    <i className="fa fa-font" />
                                                                                                </DropdownToggle>
                                                                                                <DropdownMenu>
                                                                                                    <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 10; this.setState({ items }, () => this.detectChange()) }}>10px</DropdownItem>
                                                                                                    <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 12; this.setState({ items }, () => this.detectChange()) }}>12px</DropdownItem>
                                                                                                    <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 14; this.setState({ items }, () => this.detectChange()) }}>14px</DropdownItem>
                                                                                                    <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 16; this.setState({ items }, () => this.detectChange()) }}>16px</DropdownItem>
                                                                                                    <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 18; this.setState({ items }, () => this.detectChange()) }}>18px</DropdownItem>
                                                                                                    <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 20; this.setState({ items }, () => this.detectChange()) }}>20px</DropdownItem>
                                                                                                </DropdownMenu>
                                                                                            </UncontrolledDropdown>
                                                                                        </div>

                                                                                    </>

                                                                                }



                                                                            </Label>


                                                                            <HTML disableImage={true} height={350} value={item.text} onChange={(text) => {
                                                                                if (item.text !== text.replace(/ rel="noopener"/g, '')) {
                                                                                    this.detectChange()
                                                                                }

                                                                                let items = this.state.items;
                                                                                items[idx].text = text;
                                                                                this.setState({
                                                                                    items
                                                                                })
                                                                            }} />

                                                                            <Checkbox onChange={() => {
                                                                                let items = this.state.items;
                                                                                items[idx].userInput = !items[idx].userInput;
                                                                                this.setState({ items }, () => this.detectChange())
                                                                            }} checked={item.userInput ? true : false} label={'Alert the patient'.translate(this.props.lang)} />


                                                                        </FormGroup>
                                                                        :
                                                                        null
                                                                }
                                                                {
                                                                    item.type == 15 ?

                                                                        <FormGroup tag="fieldset">
                                                                            <Label size="sm">{item.name}</Label>


                                                                            <HTML disableImage={true} height={350} value={item.text} onChange={(text) => {
                                                                                if (item.text !== text.replace(/ rel="noopener"/g, '')) {
                                                                                    // console.log(item.text);
                                                                                    // console.log(text);
                                                                                    this.detectChange()
                                                                                }

                                                                                let items = this.state.items;
                                                                                items[idx].text = text;
                                                                                this.setState({
                                                                                    items
                                                                                })


                                                                            }} />



                                                                        </FormGroup>
                                                                        :
                                                                        null
                                                                }
                                                                {
                                                                    item.type == 16 || item.type == 17 ?
                                                                        <FormGroup tag="fieldset">
                                                                            <Label size="sm"><input style={cssStyle} type="text" value={item.name} onChange={(e) => {
                                                                                let items = this.state.items;
                                                                                items[idx].name = e.target.value;
                                                                                this.setState({
                                                                                    items
                                                                                }, () => this.detectChange())
                                                                            }} className="edit-field-name" />
                                                                                {
                                                                                    item.type == 2 && this.state.intelligentReferral ?
                                                                                        <>

                                                                                            /

                                                                                            <input style={cssStyle} type="text" value={item.answerPrefix} placeholder={'Prefix for answers'.translate(this.props.lang)} onChange={(e) => {
                                                                                                let items = this.state.items;
                                                                                                items[idx].answerPrefix = e.target.value;
                                                                                                this.setState({
                                                                                                    items
                                                                                                }, () => this.detectChange())
                                                                                            }} className="edit-field-name" />

                                                                                        </>
                                                                                        :
                                                                                        null
                                                                                }
                                                                                <div className="text-options">
                                                                                    <button className={item.fontStyle == 'italic' ? 'active' : ''} onClick={() => { let items = this.state.items; items[idx].fontStyle = items[idx].fontStyle == 'italic' ? null : 'italic'; this.setState({ items }, () => this.detectChange()) }}><i className="fa fa-italic" /></button>
                                                                                    <button className={item.fontWeight == 'bold' ? 'active' : ''} onClick={() => { let items = this.state.items; items[idx].fontWeight = items[idx].fontWeight == 'bold' ? null : 'bold'; this.setState({ items }, () => this.detectChange()) }}><i className="fa fa-bold" /></button>

                                                                                    <UncontrolledDropdown className='font-size-dorpdown' size="sm">
                                                                                        <DropdownToggle caret>
                                                                                            <i className="fa fa-font" />
                                                                                        </DropdownToggle>
                                                                                        <DropdownMenu>
                                                                                            <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 10; this.setState({ items }, () => this.detectChange()) }}>10px</DropdownItem>
                                                                                            <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 12; this.setState({ items }, () => this.detectChange()) }}>12px</DropdownItem>
                                                                                            <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 14; this.setState({ items }, () => this.detectChange()) }}>14px</DropdownItem>
                                                                                            <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 16; this.setState({ items }, () => this.detectChange()) }}>16px</DropdownItem>
                                                                                            <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 18; this.setState({ items }, () => this.detectChange()) }}>18px</DropdownItem>
                                                                                            <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 20; this.setState({ items }, () => this.detectChange()) }}>20px</DropdownItem>
                                                                                        </DropdownMenu>
                                                                                    </UncontrolledDropdown>
                                                                                </div>


                                                                            </Label>


                                                                            {(this.state.linkingElement !== null && this.state.linkingElement !== idx && this.state.linkingElement > idx) ?
                                                                                <div className="selectable-interval-component-wrap">
                                                                                    <SelectableInterval
                                                                                        min={this.state.blocksRange && this.state.blocksRange.filter(el => el.id == item.id).length && this.state.blocksRange.filter(el => el.id == item.id)[0] && this.state.blocksRange.filter(el => el.id == item.id)[0].min ? this.state.blocksRange.filter(el => el.id == item.id)[0].min : 0}
                                                                                        max={this.state.blocksRange && this.state.blocksRange.filter(el => el.id == item.id).length && this.state.blocksRange.filter(el => el.id == item.id)[0] && this.state.blocksRange.filter(el => el.id == item.id)[0].max ? this.state.blocksRange.filter(el => el.id == item.id)[0].max : 0}
                                                                                        blockHeight={30}
                                                                                        blocks={item.blocks}
                                                                                        onClick={(block, bidx) => {
                                                                                            let items = this.state.items;
                                                                                            if (!items[this.state.linkingElement].conditions) {
                                                                                                items[this.state.linkingElement].conditions = [];
                                                                                            }

                                                                                            let check = -1;
                                                                                            for (let i = 0; i < items[this.state.linkingElement].conditions.length; i++) {
                                                                                                if (items[this.state.linkingElement].conditions[i].conditionQuestion == item.id && items[this.state.linkingElement].conditions[i].conditionAnswer == block.id) {
                                                                                                    check = i;
                                                                                                }
                                                                                            }

                                                                                            if (check != -1) {
                                                                                                items[this.state.linkingElement].conditions.splice(check, 1);
                                                                                            } else {
                                                                                                items[this.state.linkingElement].conditions.push({
                                                                                                    conditionAnswer: block.id,
                                                                                                    conditionQuestion: item.id
                                                                                                })
                                                                                            }


                                                                                            this.setState({
                                                                                                items,
                                                                                            }, () => this.detectChange());
                                                                                        }}
                                                                                        tooltip={'response'}
                                                                                        linkingElements={true}
                                                                                        showLink={this.state.showLink}
                                                                                        linkingElement={this.state.linkingElement}
                                                                                        items={this.state.items}
                                                                                        item={item}
                                                                                    ></SelectableInterval>
                                                                                </div>
                                                                                :
                                                                                <div className="selectable-interval-component-wrap">
                                                                                    <SelectableInterval
                                                                                        min={this.state.blocksRange && this.state.blocksRange.filter(el => el.id == item.id).length && this.state.blocksRange.filter(el => el.id == item.id)[0] && this.state.blocksRange.filter(el => el.id == item.id)[0].min ? this.state.blocksRange.filter(el => el.id == item.id)[0].min : 0}
                                                                                        max={this.state.blocksRange && this.state.blocksRange.filter(el => el.id == item.id).length && this.state.blocksRange.filter(el => el.id == item.id)[0] && this.state.blocksRange.filter(el => el.id == item.id)[0].max ? this.state.blocksRange.filter(el => el.id == item.id)[0].max : 0}
                                                                                        blockHeight={30}
                                                                                        blocks={item.blocks}
                                                                                        onBlockSelection={(block) => {
                                                                                            let obj = {
                                                                                                id: uuidv4(),
                                                                                                // comparison: 'more',
                                                                                                // value: block.start,
                                                                                                start: block.start,
                                                                                                end: block.end,
                                                                                                response: ''
                                                                                            }

                                                                                            let items = this.state.items;
                                                                                            items[idx].blocks.push(obj);
                                                                                            this.setState({
                                                                                                items
                                                                                            }, () => this.detectChange())
                                                                                        }}
                                                                                        onClick={(block, bidx) => {
                                                                                            let items = this.state.items;
                                                                                            let blocks = [...items[idx].blocks];

                                                                                            let min = this.state.blocksRange && this.state.blocksRange.filter(el => el.id == item.id).length && this.state.blocksRange.filter(el => el.id == item.id)[0] && this.state.blocksRange.filter(el => el.id == item.id)[0].min ? this.state.blocksRange.filter(el => el.id == item.id)[0].min : 0;
                                                                                            let max = this.state.blocksRange && this.state.blocksRange.filter(el => el.id == item.id).length && this.state.blocksRange.filter(el => el.id == item.id)[0] && this.state.blocksRange.filter(el => el.id == item.id)[0].max ? this.state.blocksRange.filter(el => el.id == item.id)[0].max : 0;

                                                                                            for (let i = 0; i < blocks.length; i++) {
                                                                                                if (i != bidx) {
                                                                                                    if (blocks[i].end < block.start) {
                                                                                                        if (min < blocks[i].end + 1) {
                                                                                                            min = blocks[i].end + 1;
                                                                                                        }
                                                                                                    } else if (blocks[i].start > block.end) {
                                                                                                        if (max > blocks[i].start - 1) {
                                                                                                            max = blocks[i].start - 1;
                                                                                                        }

                                                                                                    }
                                                                                                }
                                                                                            }
                                                                                            let obj = {
                                                                                                idx: idx,
                                                                                                bidx: bidx,
                                                                                                item: { ...block },
                                                                                                start: block.start,
                                                                                                end: block.end,
                                                                                                min: min,
                                                                                                max: max
                                                                                            }
                                                                                            this.setState({ clickedBlockModal: obj })

                                                                                        }}
                                                                                        item={item}
                                                                                        showLink={this.state.showLink}
                                                                                        tooltip={'response'}

                                                                                        deleteBlockHandler={(item, bidx) => {

                                                                                            this.setState({
                                                                                                deleteBlockModal: { idx, bidx },
                                                                                            })
                                                                                        }}
                                                                                    ></SelectableInterval>
                                                                                </div>

                                                                            }



                                                                        </FormGroup>
                                                                        :
                                                                        null
                                                                }


                                                                <div>

                                                                    {
                                                                        item.type != 10 ?
                                                                            <div className="referal-checkbox-container">
                                                                                <Checkbox checked={this.state.intelligentReferral ? true : item.required} onChange={(e) => {
                                                                                    let items = this.state.items;
                                                                                    items[idx].required = e.target.checked;
                                                                                    this.setState({
                                                                                        items
                                                                                    }, () => this.detectChange())
                                                                                }} label={'Required'.translate(this.props.lang)} />

                                                                            </div>
                                                                            :
                                                                            null
                                                                    }

                                                                    <UncontrolledDropdown size="sm" direction="left">
                                                                        <DropdownToggle caret>
                                                                            {'Type'.translate(this.props.lang)}
                                                                        </DropdownToggle>
                                                                        <DropdownMenu>
                                                                            {
                                                                                this.state.intelligentReferral ?
                                                                                    <DropdownItem
                                                                                        onClick={() => {
                                                                                            let items = this.state.items;
                                                                                            items[idx].type = 0;
                                                                                            items[idx].name = 'Title'.translate(this.props.lang);
                                                                                            this.setState({
                                                                                                items
                                                                                            }, () => {
                                                                                                this.detectChange()
                                                                                                this.checkBlocksRange()
                                                                                            })
                                                                                        }}>{'Title'.translate(this.props.lang)}</DropdownItem>

                                                                                    :
                                                                                    null
                                                                            }
                                                                            <DropdownItem onClick={() => {
                                                                                let items = this.state.items;
                                                                                if (item.type != 1 && item.type != 2) {
                                                                                    items[idx].name = 'Single option answer name'.translate(this.props.lang);
                                                                                    items[idx].answers = [
                                                                                        {
                                                                                            id: uuidv4(),
                                                                                            name: '',
                                                                                            score: 0
                                                                                        },
                                                                                        {
                                                                                            id: uuidv4(),
                                                                                            name: '',
                                                                                            score: 0
                                                                                        }
                                                                                    ]
                                                                                }
                                                                                items[idx].type = 1;
                                                                                this.setState({
                                                                                    items
                                                                                }, () => {
                                                                                    this.detectChange()
                                                                                    this.checkBlocksRange()
                                                                                })
                                                                            }}>{'Single option answer'.translate(this.props.lang)}</DropdownItem>
                                                                            <DropdownItem onClick={() => {
                                                                                let items = this.state.items;

                                                                                if (item.type != 1 && item.type != 2) {
                                                                                    items[idx].name = 'Multiple options answer name'.translate(this.props.lang);
                                                                                    items[idx].answers = [
                                                                                        {
                                                                                            id: uuidv4(),
                                                                                            name: '',
                                                                                            score: 0
                                                                                        },
                                                                                        {
                                                                                            id: uuidv4(),
                                                                                            name: '',
                                                                                            score: 0
                                                                                        }
                                                                                    ]
                                                                                } else {
                                                                                    if (items[idx].name == 'Single option answer name'.translate(this.props.lang)) {
                                                                                        items[idx].name = 'Multiple options answer name'.translate(this.props.lang);
                                                                                    }
                                                                                }

                                                                                items[idx].type = 2;

                                                                                this.setState({
                                                                                    items
                                                                                }, () => {
                                                                                    this.detectChange()
                                                                                    this.checkBlocksRange()
                                                                                })
                                                                            }}>{'Multiple options answer'.translate(this.props.lang)}</DropdownItem>
                                                                            <DropdownItem onClick={() => {
                                                                                let items = this.state.items;
                                                                                items[idx].type = 3;
                                                                                items[idx].name = 'Text answer name'.translate(this.props.lang);
                                                                                this.setState({
                                                                                    items
                                                                                }, () => {
                                                                                    this.detectChange()
                                                                                    this.checkBlocksRange()
                                                                                })
                                                                            }}>{'Text answer'.translate(this.props.lang)}</DropdownItem>
                                                                            {
                                                                                this.state.intelligentReferral ?
                                                                                    <DropdownItem onClick={() => {
                                                                                        let items = this.state.items;
                                                                                        items[idx].type = 5;
                                                                                        items[idx].name = 'Diagnos'.translate(this.props.lang);
                                                                                        items[idx].score = 0;
                                                                                        this.setState({
                                                                                            items
                                                                                        }, () => {
                                                                                            this.detectChange()
                                                                                            this.checkBlocksRange()
                                                                                        })
                                                                                    }}>{'Diagnos'.translate(this.props.lang)}</DropdownItem>

                                                                                    :
                                                                                    null
                                                                            }

                                                                            <DropdownItem onClick={() => {
                                                                                let items = this.state.items;
                                                                                items[idx].type = 6;
                                                                                items[idx].name = "Enter the patient's weight and height".translate(this.props.lang);
                                                                                items[idx].score = 0;
                                                                                this.setState({
                                                                                    items
                                                                                }, () => {
                                                                                    this.detectChange()
                                                                                    this.checkBlocksRange()
                                                                                })
                                                                            }}>{"Enter the patient's weight and height".translate(this.props.lang)}</DropdownItem>

                                                                            <DropdownItem onClick={() => {
                                                                                let items = this.state.items;
                                                                                items[idx].type = 9;
                                                                                items[idx].name = 'Image or file'.translate(this.props.lang);
                                                                                this.setState({
                                                                                    items
                                                                                }, () => {
                                                                                    this.detectChange()
                                                                                    this.checkBlocksRange()
                                                                                })
                                                                            }}>{'Image or file'.translate(this.props.lang)}</DropdownItem>
                                                                            <DropdownItem onClick={() => {
                                                                                let items = this.state.items;
                                                                                items[idx].type = 4;
                                                                                items[idx].name = 'Treatment instructions'.translate(this.props.lang);
                                                                                this.setState({
                                                                                    items
                                                                                }, () => {
                                                                                    this.detectChange()
                                                                                    this.checkBlocksRange()
                                                                                })
                                                                            }}>{'Treatment instructions'.translate(this.props.lang)}</DropdownItem>
                                                                            <DropdownItem
                                                                                onClick={() => {
                                                                                    let items = this.state.items;
                                                                                    items[idx].type = 10;
                                                                                    items[idx].name = 'New empty row'.translate(this.props.lang);
                                                                                    this.setState({
                                                                                        items
                                                                                    }, () => {
                                                                                        this.detectChange()
                                                                                        this.checkBlocksRange()
                                                                                    })
                                                                                }}>{'New empty row'.translate(this.props.lang)}</DropdownItem>
                                                                            <DropdownItem
                                                                                onClick={() => {
                                                                                    let items = this.state.items;
                                                                                    items[idx].type = 15;
                                                                                    items[idx].name = 'Info text'.translate(this.props.lang);
                                                                                    this.setState({
                                                                                        items
                                                                                    }, () => {
                                                                                        this.detectChange()
                                                                                        this.checkBlocksRange()
                                                                                    })
                                                                                }}>{'Info text'.translate(this.props.lang)}</DropdownItem>
                                                                            <DropdownItem onClick={() => {
                                                                                let items = this.state.items;
                                                                                items[idx].type = 12;
                                                                                items[idx].name = 'Blood pressure measured to'.translate(this.props.lang);
                                                                                items[idx].score = 0;
                                                                                this.setState({
                                                                                    items
                                                                                }, () => {
                                                                                    this.detectChange()
                                                                                    this.checkBlocksRange()
                                                                                })
                                                                            }}>{'Blood pressure'.translate(this.props.lang)}</DropdownItem>

                                                                            <DropdownItem onClick={() => {
                                                                                let items = this.state.items;
                                                                                items[idx].type = 13;
                                                                                items[idx].name = 'The patient states VAS:'.translate(this.props.lang);
                                                                                items[idx].score = 0;
                                                                                this.setState({
                                                                                    items
                                                                                }, () => {
                                                                                    this.detectChange()
                                                                                    this.checkBlocksRange()
                                                                                })
                                                                            }}>{'VAS-scale'.translate(this.props.lang)}</DropdownItem>

                                                                            <DropdownItem onClick={() => {
                                                                                let items = this.state.items;
                                                                                items[idx].type = 14;
                                                                                items[idx].name = 'Current respiratory rate/minute:'.translate(this.props.lang);
                                                                                items[idx].score = 0;
                                                                                this.setState({
                                                                                    items
                                                                                }, () => {
                                                                                    this.detectChange()
                                                                                    this.checkBlocksRange()
                                                                                })
                                                                            }}>{'Respiratory rate'.translate(this.props.lang)}</DropdownItem>
                                                                            <DropdownItem onClick={() => {
                                                                                let items = this.state.items;
                                                                                items[idx].type = 16;
                                                                                items[idx].name = 'Subtotal:'.translate(this.props.lang);
                                                                                items[idx].blocks = [
                                                                                    // {
                                                                                    //     id: uuidv4(),
                                                                                    //     comparison: 'more',
                                                                                    //     value: 0,
                                                                                    //     response: ''
                                                                                    // }
                                                                                ]
                                                                                this.setState({
                                                                                    items
                                                                                }, () => {
                                                                                    this.detectChange()
                                                                                    this.checkBlocksRange()
                                                                                })
                                                                            }}>{'Subtotal'.translate(this.props.lang)}</DropdownItem>
                                                                            {
                                                                                this.state.items && this.state.items.length == (idx + 1) ?
                                                                                    <DropdownItem onClick={() => {
                                                                                        let items = this.state.items;
                                                                                        items[idx].type = 17;
                                                                                        items[idx].name = 'Total:'.translate(this.props.lang);
                                                                                        items[idx].blocks = [
                                                                                            // {
                                                                                            //     id: uuidv4(),
                                                                                            //     comparison: 'more',
                                                                                            //     value: 0,
                                                                                            //     response: ''
                                                                                            // }
                                                                                        ]
                                                                                        this.setState({
                                                                                            items
                                                                                        }, () => {
                                                                                            this.detectChange()
                                                                                            this.checkBlocksRange()
                                                                                        })
                                                                                    }}>{'Total'.translate(this.props.lang)}</DropdownItem>
                                                                                    :
                                                                                    null
                                                                            }
                                                                        </DropdownMenu>


                                                                    </UncontrolledDropdown>


                                                                    {
                                                                        this.state.intelligentReferral ?

                                                                            <>
                                                                                {item.type == 0 ?

                                                                                    <button className={item.displayOnReferral ? /*'print-enabled'*/ 'referral-builder-tooltip-wrap' : 'referral-builder-tooltip-wrap'} onClick={() => {
                                                                                        let items = this.state.items;
                                                                                        items[idx].displayOnReferral = !items[idx].displayOnReferral;

                                                                                        this.setState({
                                                                                            items
                                                                                        }, () => this.detectChange())
                                                                                    }}>
                                                                                        {
                                                                                            item.displayOnReferral ?
                                                                                                <div className='print-show-hide-icon-wrap'>
                                                                                                    <Isvg src={viewIcon} />
                                                                                                </div>
                                                                                                :
                                                                                                <div className='print-show-hide-icon-wrap hide-icon-wrap'>
                                                                                                    <Isvg src={hideIcon} />
                                                                                                </div>
                                                                                        }
                                                                                        <div className='referral-builder-tooltip2'>{'Display title in preview'.translate(this.props.lang)}</div>

                                                                                    </button>


                                                                                    :
                                                                                    null
                                                                                }


                                                                                {idx != 0 && this.state.items && this.state.items[idx] && this.state.items[idx].type != 17 ?
                                                                                    <button className={this.state.linkingElement == idx ? 'linking-enabled referral-builder-tooltip-wrap' : (item.conditions && item.conditions.length) ? 'item-linked referral-builder-tooltip-wrap' : 'referral-builder-tooltip-wrap'} onMouseEnter={() => {
                                                                                        if (item.conditions && item.conditions.length) {
                                                                                            this.setState({
                                                                                                showLink: item.conditions
                                                                                            })
                                                                                        }
                                                                                    }} onMouseLeave={() => {
                                                                                        if (item.conditions && item.conditions.length) {
                                                                                            this.setState({
                                                                                                showLink: null
                                                                                            })
                                                                                        }
                                                                                    }} onClick={() => {
                                                                                        this.setState({
                                                                                            linkingElement: this.state.linkingElement !== idx ? idx : null,
                                                                                            //showLink: this.state.linkingElement !== idx ? item.conditions  : null
                                                                                        })
                                                                                    }}><Isvg src={Link} />
                                                                                        <div className='referral-builder-tooltip'>{'Link question'.translate(this.props.lang)}</div>

                                                                                    </button>
                                                                                    :
                                                                                    null
                                                                                }

                                                                            </>
                                                                            :
                                                                            null
                                                                    }


                                                                    <button className='referral-builder-tooltip-wrap' onClick={() => {
                                                                        this.setState({
                                                                            deleteElementModal: idx,
                                                                        })
                                                                    }}><Isvg src={garabage} />
                                                                        <div className='referral-builder-tooltip'>{'Delete'.translate(this.props.lang)}</div>

                                                                    </button>

                                                                    <UncontrolledDropdown size="sm" className="insert-above-below">
                                                                        <DropdownToggle>
                                                                            <Isvg src={moreIcon} />
                                                                        </DropdownToggle>
                                                                        <DropdownMenu>
                                                                            {
                                                                                idx > 0 ?
                                                                                    <DropdownItem
                                                                                        onClick={() => {
                                                                                            let items = this.state.items;
                                                                                            items.splice(idx, 0, {
                                                                                                name: 'Single option answer name'.translate(this.props.lang),
                                                                                                answers: [
                                                                                                    {
                                                                                                        id: uuidv4(),
                                                                                                        name: '',
                                                                                                        score: 0
                                                                                                    },
                                                                                                    {
                                                                                                        id: uuidv4(),
                                                                                                        name: '',
                                                                                                        score: 0
                                                                                                    }
                                                                                                ],
                                                                                                id: uuidv4(),
                                                                                                type: 1,
                                                                                            });

                                                                                            this.setState({
                                                                                                items
                                                                                            }, () => this.detectChange())
                                                                                        }}>{"Insert above".translate(this.props.lang)}</DropdownItem>
                                                                                    :
                                                                                    null
                                                                            }

                                                                            {
                                                                                this.state.items && this.state.items[idx] && this.state.items[idx].type != 17 ?
                                                                                    <DropdownItem
                                                                                        onClick={() => {
                                                                                            let items = this.state.items;
                                                                                            items.splice(idx + 1, 0, {
                                                                                                name: 'Single option answer name'.translate(this.props.lang),
                                                                                                answers: [
                                                                                                    {
                                                                                                        id: uuidv4(),
                                                                                                        name: '',
                                                                                                        score: 0
                                                                                                    },
                                                                                                    {
                                                                                                        id: uuidv4(),
                                                                                                        name: '',
                                                                                                        score: 0
                                                                                                    }
                                                                                                ],
                                                                                                id: uuidv4(),
                                                                                                type: 1,
                                                                                            });

                                                                                            this.setState({
                                                                                                items
                                                                                            }, () => this.detectChange())
                                                                                        }}
                                                                                    >{"Insert below".translate(this.props.lang)}</DropdownItem>
                                                                                    :
                                                                                    null
                                                                            }
                                                                        </DropdownMenu>
                                                                    </UncontrolledDropdown>


                                                                </div>


                                                            </div>
                                                        )
                                                    }
                                                })}></SortableList>
                                        </>
                                        :
                                        null
                                }


                            </div>



                            <div className="panel">
                                <Button color="primary" size="sm" onClick={() => {
                                    let items = this.state.items;
                                    let obj = {
                                        name: 'Single option answer name'.translate(this.props.lang),
                                        answers: [
                                            {
                                                id: uuidv4(),
                                                name: '',
                                                score: 0
                                            },
                                            {
                                                id: uuidv4(),
                                                name: '',
                                                score: 0
                                            }
                                        ],
                                        id: uuidv4(),
                                        type: 1,
                                    };
                                    if (items && items.length && items[items.length - 1].type != 17) {
                                        items.push(obj);
                                        this.setState({
                                            items
                                        }, () => this.detectChange())
                                    } else {
                                        items.splice(items.length - 1, 0, obj);
                                        this.setState({
                                            items
                                        }, () => this.detectChange())
                                    }



                                }} style={{ padding: '10px 22px' }}>{'Add new element'.translate(this.props.lang)}</Button>
                                <Button size="sm" onClick={() => this.setState({ openPageWarningModal: 2 })} disabled={this.state.checkActiveSave ? false : true} style={{ marginLeft: 20, padding: '10px 22px' }} color="primary">
                                    {
                                        this.state.showPlayer ? (
                                            <Player
                                                onEvent={(event) => {
                                                    if (event === "load" && this.player && this.player.current) this.stopAnimation();
                                                }}
                                                onStateChange={this.toggleVisibility}
                                                ref={this.player} // set the ref to your class instance
                                                autoplay={true}
                                                loop={true}
                                                controls={true}
                                                src="https://assets8.lottiefiles.com/packages/lf20_ZSRKCF.json"
                                                style={{ height: "30px", width: "30px" }}
                                            ></Player>

                                        ) : this.state.showPlayer2 ? (
                                            <div style={{ display: 'flex', alignItems: 'center' }}><Player
                                                onStateChange={this.toggleVisibility}
                                                ref={this.player2} // set the ref to your class instance
                                                autoplay={true}
                                                loop={true}
                                                controls={true}
                                                src="https://assets10.lottiefiles.com/packages/lf20_CxyxLk.json"
                                                style={{ height: "30px", width: "30px" }}
                                            ></Player>
                                                {'Saved'.translate(this.props.lang)}
                                            </div>
                                        ) : (
                                            "Save".translate(this.props.lang)
                                        )
                                    }
                                </Button>

                            </div>

                        </Col>


                    </Row>
                    {
                        this.state.clickedBlockModal ?
                            <Modal isOpen={this.state.clickedBlockModal} centered>
                                <ModalHeader style={{ margin: 'auto' }} >
                                    {'Block'.translate(this.props.lang)}  {this.state.clickedBlockModal ? this.state.clickedBlockModal.start == this.state.clickedBlockModal.end ? this.state.clickedBlockModal.start : `${this.state.clickedBlockModal.start}-${this.state.clickedBlockModal.end}` : ''}
                                </ModalHeader>

                                <ModalBody className='interval-modal-body-wrap'>
                                    <div className="block-start-end-wrap">
                                        <div className='block-start-end'>
                                            <Label>{'Start'.translate(this.props.lang)}</Label>
                                            <div className="answer-score-wrap">
                                                <Button color="primary" disabled={this.state.clickedBlockModal.item.start > this.state.clickedBlockModal.min ? false : true} onClick={() => {
                                                    let clickedBlockModal = this.state.clickedBlockModal;
                                                    let val = clickedBlockModal.item.start - 1;
                                                    clickedBlockModal.item.start = Number(val)
                                                    this.setState({
                                                        clickedBlockModal
                                                    })
                                                }}>-</Button>
                                                <input type="text" value={this.state.clickedBlockModal.item.start} disabled
                                                // onChange={(e) => {
                                                //     let clickedBlockModal = this.state.clickedBlockModal;
                                                //     let val = String(e.target.value).match(/[-]{0,1}[\d]*[.]{0,1}[\d]+/g);
                                                //     clickedBlockModal.item.start = Number(val)
                                                //     this.setState({
                                                //         clickedBlockModal
                                                //     })

                                                // }} 
                                                />
                                                <Button color="primary" disabled={this.state.clickedBlockModal.item.start < this.state.clickedBlockModal.max && this.state.clickedBlockModal.item.start < this.state.clickedBlockModal.item.end ? false : true} onClick={() => {
                                                    let clickedBlockModal = this.state.clickedBlockModal;
                                                    let val = clickedBlockModal.item.start + 1;
                                                    clickedBlockModal.item.start = Number(val)
                                                    this.setState({
                                                        clickedBlockModal
                                                    })
                                                }}>+</Button>
                                            </div>
                                        </div>
                                        <div className='block-start-end'>
                                            <Label>{'End'.translate(this.props.lang)}</Label>
                                            <div className="answer-score-wrap">
                                                <Button color="primary" disabled={this.state.clickedBlockModal.item.end > this.state.clickedBlockModal.min && this.state.clickedBlockModal.item.start < this.state.clickedBlockModal.item.end ? false : true} onClick={() => {
                                                    let clickedBlockModal = this.state.clickedBlockModal;
                                                    let val = clickedBlockModal.item.end - 1;
                                                    clickedBlockModal.item.end = Number(val)
                                                    this.setState({
                                                        clickedBlockModal
                                                    })
                                                }}>-</Button>
                                                <input type="text" disabled value={this.state.clickedBlockModal.item.end}
                                                //  onChange={(e) => {
                                                //     let clickedBlockModal = this.state.clickedBlockModal;
                                                //     let val = String(e.target.value).match(/[-]{0,1}[\d]*[.]{0,1}[\d]+/g);
                                                //     clickedBlockModal.item.end = Number(val)
                                                //     this.setState({
                                                //         clickedBlockModal
                                                //     })

                                                // }} 
                                                />
                                                <Button color="primary" disabled={this.state.clickedBlockModal.item.end < this.state.clickedBlockModal.max ? false : true} onClick={() => {
                                                    let clickedBlockModal = this.state.clickedBlockModal;
                                                    let val = clickedBlockModal.item.end + 1;
                                                    clickedBlockModal.item.end = Number(val)
                                                    this.setState({
                                                        clickedBlockModal
                                                    })
                                                }}>+</Button>
                                            </div>
                                        </div>
                                    </div>
                                    <FormGroup>
                                        <Label>{'Response'.translate(this.props.lang)}</Label>
                                        <Input style={{ height: 100 }} type='textarea' value={this.state.clickedBlockModal.item.response} onChange={(e) => {
                                            let clickedBlockModal = this.state.clickedBlockModal;
                                            clickedBlockModal.item.response = e.target.value;
                                            this.setState({ clickedBlockModal })
                                        }} />
                                    </FormGroup>

                                </ModalBody>
                                <ModalFooter className='buttons-right-blue'>
                                    <Button color='primary' onClick={() => {
                                        if (this.state.clickedBlockModal) {
                                            let clickedBlockModal = this.state.clickedBlockModal;
                                            if (clickedBlockModal && clickedBlockModal.item) {
                                                let items = this.state.items;
                                                let blocks = [...items[clickedBlockModal.idx].blocks];

                                                // let min = clickedBlockModal.min;
                                                // let max = clickedBlockModal.max;

                                                // for (let i = 0; i < blocks.length; i++) {
                                                //     if (i != clickedBlockModal.bidx) {
                                                //         if (blocks[i].end < clickedBlockModal.start) {
                                                //             if (min < blocks[i].end + 1) {
                                                //                 min = blocks[i].end + 1;
                                                //             }
                                                //         } else if (blocks[i].start > clickedBlockModal.end) {
                                                //             if (max > blocks[i].start - 1) {
                                                //                 max = blocks[i].start - 1;
                                                //             }

                                                //         }
                                                //     }
                                                // }

                                                let start = clickedBlockModal.item.start;
                                                let end = clickedBlockModal.item.end;
                                                // if (min > start) {
                                                //     start = min;
                                                // }
                                                // if (max < end) {
                                                //     end = max;
                                                // }
                                                // if (end < start) {
                                                //     blocks.splice(clickedBlockModal.bidx, 1)
                                                // } else {
                                                //     blocks[clickedBlockModal.bidx].response = clickedBlockModal.item.response;
                                                //     blocks[clickedBlockModal.bidx].start = start;
                                                //     blocks[clickedBlockModal.bidx].end = end;
                                                // }

                                                blocks[clickedBlockModal.bidx].response = clickedBlockModal.item.response;
                                                blocks[clickedBlockModal.bidx].start = start;
                                                blocks[clickedBlockModal.bidx].end = end;
                                                items[clickedBlockModal.idx].blocks = blocks
                                                this.setState({ items, clickedBlockModal: null }, () => this.detectChange())
                                            }

                                        }
                                    }}>{'Save'.translate(this.props.lang)}</Button>
                                    <Button color='primary' onClick={() => {
                                        this.setState({ clickedBlockModal: null })
                                    }}>{'Cancel'.translate(this.props.lang)}</Button>
                                </ModalFooter>

                            </Modal>
                            :
                            null
                    }


                    {
                        this.state.messageModalError ?
                            <Modal isOpen={this.state.messageModalError} centered toggle={() => this.setState({ messageModalError: !this.state.runModal })}>
                                {/* <ModalHeader style={{ margin: 'auto' }} >
                                    // {'Error message'.translate(this.props.lang)}
                                    {'Warning'.translate(this.props.lang)}
                                </ModalHeader> */}

                                <ModalBody className='delete-modal'>
                                    <p>{'You cannot drop here this element!'.translate(this.props.lang)}</p>

                                </ModalBody>
                                <ModalFooter className='buttons-right-blue'>
                                    <Button style={{ minWidth: 100 }} color='primary' onClick={() => this.setState({ messageModalError: !this.state.messageModalError })}>{'Ok'.translate(this.props.lang)}</Button>

                                </ModalFooter>

                            </Modal>
                            :
                            null
                    }

                </Container>
                <DeleteModal
                    lang={this.props.lang}
                    isOpen={this.state.deleteBlockModal !== null}
                    toggle={() => this.setState({ deleteBlockModal: null })}
                    handler={() => {
                        let items = this.state.items;

                        let relations = this.checkAnswerRelations(this.state.items[this.state.deleteBlockModal.idx].id, this.state.items[this.state.deleteBlockModal.idx].blocks[this.state.deleteBlockModal.bidx].id);
                        if (relations && relations.length && (relations[0].length || relations[1].length)) {
                            this.setState({
                                answerRelationError: relations
                            })

                            return;
                        }


                        items[this.state.deleteBlockModal.idx].blocks.splice(this.state.deleteBlockModal.bidx, 1);
                        this.setState({
                            items,
                            deleteBlockModal: null,
                        }, () => {
                            this.checkBlocksRange()
                            this.detectChange()
                        })
                    }}
                >
                    {'Delete block'.translate(this.props.lang)}?
                </DeleteModal>


                {/* <DeleteModal
                    lang={this.props.lang}
                    isOpen={this.state.deleteElementModal !== null}
                    toggle={() => this.setState({ deleteElementModal: null })}
                    handler={() => {
                        let items = this.state.items;
                        items.splice(this.state.deleteElementModal, 1);
                        this.setState({
                            items,
                            deleteElementModal: null
                        })
                    }}
                >
                    {'Delete element'.translate(this.props.lang)} <strong>{this.state.items[this.state.deleteElementModal] && this.state.items[this.state.deleteElementModal].name}</strong>?
                </DeleteModal>
                <DeleteModal
                    lang={this.props.lang}
                    isOpen={this.state.deleteOptionModal !== null}
                    toggle={() => this.setState({ deleteOptionModal: null })}
                    handler={() => {
                        let items = this.state.items;
                        items[this.state.deleteOptionModal.idx].answers.splice(this.state.deleteOptionModal.aidx, 1);
                        this.setState({
                            items,
                            deleteOptionModal: null,
                        })
                    }}
                >
                    {'Delete option'.translate(this.props.lang)}?
                </DeleteModal> */}
                <DeleteModal
                    lang={this.props.lang}
                    isOpen={this.state.deleteElementModal !== null}
                    toggle={() => this.setState({ deleteElementModal: null })}
                    handler={() => {
                        let items = this.state.items;
                        let relations = this.checkRelations(this.state.items[this.state.deleteElementModal].id);
                        // console.log(relations)
                        if (relations && relations.length && (relations[0].length || relations[1].length)) {
                            this.setState({
                                relationError: relations
                            })

                            return;
                        }


                        items.splice(this.state.deleteElementModal, 1);
                        this.setState({
                            items,
                            deleteElementModal: null
                        }, () => {
                            this.checkBlocksRange()
                            this.detectChange()
                        })
                    }}
                >
                    {'Delete element'.translate(this.props.lang)} <strong>{this.state.items[this.state.deleteElementModal] && this.state.items[this.state.deleteElementModal].name}</strong>?
                </DeleteModal>
                <DeleteModal
                    lang={this.props.lang}
                    isOpen={this.state.deleteOptionModal !== null}
                    toggle={() => this.setState({ deleteOptionModal: null })}
                    handler={() => {
                        let items = this.state.items;

                        let relations = this.checkAnswerRelations(this.state.items[this.state.deleteOptionModal.idx].id, this.state.items[this.state.deleteOptionModal.idx].answers[this.state.deleteOptionModal.aidx].id);
                        if (relations && relations.length && (relations[0].length || relations[1].length)) {
                            this.setState({
                                answerRelationError: relations
                            })

                            return;
                        }


                        items[this.state.deleteOptionModal.idx].answers.splice(this.state.deleteOptionModal.aidx, 1);
                        this.setState({
                            items,
                            deleteOptionModal: null,
                        }, () => {
                            this.checkBlocksRange()
                            this.detectChange()
                        })
                    }}
                >
                    {'Delete option'.translate(this.props.lang)}?
                </DeleteModal>


                {
                    this.state.error ?

                        <ErrorModal lang={this.props.lang}
                            isOpen={this.state.error}
                            toggle={() => this.setState({ error: null })}

                        >
                            {this.state.error.translate(this.props.lang)}
                        </ErrorModal>

                        :
                        null
                }

                {
                    this.state.relationError ?

                        <ErrorModal lang={this.props.lang}
                            isOpen={this.state.relationError}
                            toggle={() => this.setState({ relationError: null })}
                            size="xl"
                            zIndex={99999999}
                        >
                            <h4>{'You need to unlink current relationships to continue'.translate(this.props.lang)}</h4>
                            <div className="relations-content">

                                <h6>{'Upper conditions'.translate(this.props.lang)}</h6>
                                <Table striped className="relations-table">
                                    <tr>
                                        <th>{'Condition element'.translate(this.props.lang)}</th>
                                        <th>{'Answer'.translate(this.props.lang)}</th>
                                        <th>{'Element'.translate(this.props.lang)}</th>
                                        <th>{'Action'.translate(this.props.lang)}</th>
                                    </tr>

                                    {
                                        this.state.relationError[0].map((item) => {
                                            return (
                                                <tr>
                                                    <td>{item.conditionElement.itemIndex}. {item.conditionElement.name}</td>
                                                    <td>{item.conditionElement.answer}</td>
                                                    <td>{item.element.itemIndex}. {item.element.name}</td>
                                                    <td><button onClick={() => {
                                                        let items = this.state.items;
                                                        items[item.element.itemIndex].conditions.splice(item.element.conditionIndex, 1);
                                                        this.setState({
                                                            items
                                                        }, () => {
                                                            let relations = this.checkRelations(this.state.items[this.state.deleteElementModal].id);
                                                            if (relations && relations.length && (relations[0].length || relations[1].length)) {
                                                                this.setState({
                                                                    relationError: relations
                                                                })

                                                            } else {
                                                                this.setState({
                                                                    relationError: null
                                                                })
                                                            }

                                                        })
                                                    }}><i className="fa fa-unlink danger-color" /></button></td>
                                                </tr>
                                            )
                                        })
                                    }


                                </Table>

                                <h6>{'Down conditions'.translate(this.props.lang)}</h6>
                                <Table striped className="relations-table">
                                    <tr>
                                        <th>{'Condition element'.translate(this.props.lang)}</th>
                                        <th>{'Answer'.translate(this.props.lang)}</th>
                                        <th>{'Element'.translate(this.props.lang)}</th>
                                        <th>{'Action'.translate(this.props.lang)}</th>
                                    </tr>
                                    {
                                        this.state.relationError[1].map((item) => {
                                            return (
                                                <tr>
                                                    <td>{item.conditionElement.itemIndex}. {item.conditionElement.name}</td>
                                                    <td>{item.conditionElement.answer}</td>
                                                    <td>{item.element.itemIndex}. {item.element.name}</td>

                                                    <td><button onClick={() => {
                                                        let items = this.state.items;
                                                        items[item.element.itemIndex].conditions.splice(item.element.conditionIndex, 1);
                                                        this.setState({
                                                            items
                                                        }, () => {
                                                            let relations = this.checkRelations(this.state.items[this.state.deleteElementModal].id);
                                                            if (relations && relations.length && (relations[0].length || relations[1].length)) {
                                                                this.setState({
                                                                    relationError: relations
                                                                })

                                                            } else {
                                                                this.setState({
                                                                    relationError: null
                                                                })
                                                            }

                                                        })
                                                    }}><i className="fa fa-unlink danger-color" /></button></td>
                                                </tr>
                                            )
                                        })
                                    }
                                </Table>

                            </div>
                        </ErrorModal>

                        :
                        null
                }

                {
                    this.state.answerRelationError ?

                        <ErrorModal lang={this.props.lang}
                            isOpen={this.state.answerRelationError}
                            toggle={() => this.setState({ answerRelationError: null })}
                            size="xl"
                            zIndex={999999999}
                        >
                            <h4>{'Relations'.translate(this.props.lang)}</h4>
                            <Table striped className="relations-table">
                                <tr>
                                    <th>{'Condition element'.translate(this.props.lang)}</th>
                                    <th>{'Answer'.translate(this.props.lang)}</th>
                                    <th>{'Element'.translate(this.props.lang)}</th>
                                    <th>{'Action'.translate(this.props.lang)}</th>
                                </tr>
                                {
                                    this.state.answerRelationError[1].map((item) => {
                                        return (
                                            <tr>
                                                <td>{item.conditionElement.name}</td>
                                                <td>{item.conditionElement.answer}</td>
                                                <td>{item.element.name}</td>

                                                <td><button onClick={() => {
                                                    let items = this.state.items;
                                                    items[item.element.itemIndex].conditions.splice(item.element.conditionIndex, 1);
                                                    this.setState({
                                                        items
                                                    }, () => {
                                                        let relations;
                                                        if (this.state.deleteOptionModal) {
                                                            this.checkAnswerRelations(this.state.items[this.state.deleteOptionModal.idx].id, this.state.items[this.state.deleteOptionModal.idx].answers[this.state.deleteOptionModal.aidx].id);
                                                        } else if (this.state.deleteBlockModal) {
                                                            this.checkAnswerRelations(this.state.items[this.state.deleteBlockModal.idx].id, this.state.items[this.state.deleteBlockModal.idx].blocks[this.state.deleteBlockModal.bidx].id);
                                                        }
                                                        if (relations && relations.length && (relations[0].length || relations[1].length)) {
                                                            this.setState({
                                                                answerRelationError: relations
                                                            })

                                                        } else {
                                                            this.setState({
                                                                answerRelationError: null
                                                            })
                                                        }

                                                    })
                                                }}><i className="fa fa-unlink danger-color" /></button></td>
                                            </tr>
                                        )
                                    })
                                }
                                {
                                    this.state.answerRelationError[0].map((item) => {
                                        return (
                                            <tr>
                                                <td>{item.conditionElement.name}</td>
                                                <td>{item.conditionElement.answer}</td>
                                                <td>{item.element.name}</td>
                                                <td><button onClick={() => {
                                                    let items = this.state.items;
                                                    items[item.element.itemIndex].conditions.splice(item.element.conditionIndex, 1);
                                                    this.setState({
                                                        items
                                                    }, () => {
                                                        let relations = this.checkAnswerRelations(this.state.items[this.state.deleteOptionModal.idx].id, this.state.items[this.state.deleteOptionModal.idx].answers[this.state.deleteOptionModal.aidx].id);
                                                        if (relations && relations.length && (relations[0].length || relations[1].length)) {
                                                            this.setState({
                                                                answerRelationError: relations
                                                            })

                                                        } else {
                                                            this.setState({
                                                                answerRelationError: null
                                                            })
                                                        }

                                                    })
                                                }}><i className="fa fa-unlink danger-color" /></button></td>
                                            </tr>
                                        )
                                    })
                                }


                            </Table>
                        </ErrorModal>

                        :
                        null
                }

                {
                    this.state.importModal ?
                        <Modal size="xl" isOpen={this.state.importModal} centered>
                            <ModalHeader >{'Import'.translate(this.props.lang)}</ModalHeader>

                            <ModalBody>

                                <ListBuilder
                                    lang={this.props.lang}
                                    loading={this.state.loading}
                                    total={this.state.total}
                                    showNumeration={false}
                                    fields={[
                                        { type: 'text', name: 'name', label: 'Name'.translate(this.props.lang), allowSort: true },

                                    ]}
                                    rawItems={this.state.importModalList}
                                    items={this.state.importModalList.map((item => {
                                        return {
                                            ...item,

                                        }
                                    }))}

                                    actions={
                                        [
                                            {
                                                component: <div><Button color='primary'>{'Import'.translate(this.props.lang)}</Button></div>,
                                                onClick: (item) => {
                                                    if (item && item.items) {
                                                        this.setState({ items: item.items, importModal: false, intelligentReferral: item.intelligentReferral }, () => this.checkBlocksRange())
                                                        this.detectChange()
                                                    }
                                                }
                                            },

                                        ]
                                    }
                                    params={params}
                                    sortField={params.sortField}
                                    sortType={params.sortType}
                                    updateSort={this.updateSort}
                                    updateParams={this.updateParams}
                                    updateMultipleParams={this.updateMultipleParams}

                                ></ListBuilder>

                            </ModalBody>
                            <ModalFooter className='buttons-right-blue'>
                                <Button color='primary' onClick={() => this.setState({ importModal: false })}>{"Close".translate(this.props.lang)}</Button>

                            </ModalFooter>
                        </Modal>
                        :
                        null
                }


                {
                    this.state.professionsModal ?
                        <Modal size="lg" isOpen={this.state.professionsModal} centered toggle={() => this.setState({ professionsModal: !this.state.professionsModal })} >
                            <ModalHeader toggle={() => this.setState({ professionsModal: !this.state.professionsModal })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ professionsModal: !this.state.professionsModal })}><Isvg src={xIcon} /></button>}>{'Select group(s)'.translate(this.props.lang)}</ModalHeader>

                            <ModalBody className="run-modal">
                                <div className='select-unselect-all-wrap'>
                                    <Button color='primary' onClick={() => {
                                        if (this.state.professions && this.state.professions.length) {
                                            let professionList = [];
                                            for (let i = 0; i < this.state.professions.length; i++) {
                                                professionList.push(this.state.professions[i]._id)
                                            }
                                            this.setState({ professionList }, () => {
                                                this.detectChange()
                                            })

                                        }
                                    }}>{'Select all'.translate(this.props.lang)}</Button>
                                    <Button color='primary' onClick={() => {
                                        this.setState({ professionList: [] }, () => {
                                            this.detectChange()
                                        })
                                    }} style={{ marginLeft: 15 }}>{'Unselect all'.translate(this.props.lang)}</Button>
                                </div>
                                <FormGroup className="form-group-checkbox">
                                    {
                                        this.state.professions && this.state.professions.map((item, idx) => {
                                            return (
                                                <Checkbox checked={this.state.professionList.filter(profession => profession == item._id).length} onChange={(e) => {

                                                    let professionList = this.state.professionList;
                                                    if (e.target.checked) {
                                                        if (professionList.filter(profession => profession == item._id).length == 0) {
                                                            professionList.push(item._id)
                                                        }
                                                    } else {
                                                        let index = professionList.indexOf(item._id);
                                                        if (index > -1) {
                                                            professionList.splice(index, 1);
                                                        }
                                                    }

                                                    this.setState({ professionList }, () => {
                                                        this.detectChange()
                                                    })


                                                }} label={this.props.lang == 'en' ? item.professionEn : item.professionSv} />
                                            )
                                        })
                                    }
                                </FormGroup>


                            </ModalBody>
                            <ModalFooter className='buttons-right-blue'>
                                {
                                    this.props[0].match.params.id != 'new' ?
                                        <Button color='primary' onClick={() => this.setState({ professionsModal: false }, () => {
                                            if (this.props[0].match.params.id != 'new') {
                                                this.setState({ openPageWarningModal: 2 })
                                            }
                                        })}>{'Save'.translate(this.props.lang)}</Button>
                                        :
                                        <Button color='primary' onClick={() => this.setState({ professionsModal: false })}>{"Close".translate(this.props.lang)}</Button>
                                }
                            </ModalFooter>
                        </Modal>
                        :
                        null
                }

                {/*
                    this.state.durationWarning ?
                        <Modal isOpen={this.state.durationWarning} centered toggle={() => this.setState({ durationWarning: !this.state.durationWarning })} >
                            <ModalHeader toggle={() => this.setState({ durationWarning: !this.state.durationWarning })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ durationWarning: !this.state.durationWarning })}><Isvg src={xIcon} /></button>}>{'Warning!'.translate(this.props.lang)}</ModalHeader>

                            <ModalBody className="run-modal">
                                <div style={{ marginBottom: '35px' }}>
                                    <p>{"Value of duration cannot be none.".translate(this.props.lang)}</p>
                                </div>
                                <Button color='primary' onClick={() => this.setState({ durationWarning: false })}>{"Ok".translate(this.props.lang)}</Button>
                            </ModalBody>
                        </Modal>
                        :
                        null
                */}
                {/* {
                    this.state.durationWarning ?

                        <ErrorModal lang={this.props.lang}
                            isOpen={this.state.durationWarning}
                            toggle={() => this.setState({ durationWarning: null })}

                        >
                            {this.state.durationWarning.translate(this.props.lang)}
                        </ErrorModal>

                        :
                        null
                } */}

                {
                    this.state.runModal ?
                        <Modal isOpen={this.state.runModal} centered toggle={() => this.setState({ runModal: !this.state.runModal })} size={'xl'}>
                            <ModalHeader toggle={() => this.setState({ runModal: !this.state.runModal })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ runModal: !this.state.runModal })}><Isvg src={xIcon} /></button>}>{'Referral preview'.translate(this.props.lang)}</ModalHeader>

                            <ModalBody className="run-modal">
                                <CreateReferralModal lang={this.props.lang} sections={this.generateSections()}></CreateReferralModal>
                            </ModalBody>
                        </Modal>
                        :
                        null
                }
                <Modal isOpen={this.state.selectReferralModal} centered size="xl">
                    <ModalHeader toggle={() => this.setState({ selectReferralModal: !this.state.selectReferralModal })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ selectReferralModal: !this.state.selectReferralModal })}><Isvg src={xIcon} /></button>}>{'Import referral'.translate(this.props.lang)}</ModalHeader>

                    <ModalBody>
                        <ImportReferral import={(data) => {
                            this.detectChange()
                            this.setState({
                                ...data,
                                selectReferralModal: null
                            })
                        }} {...this.props} groupValue={true}></ImportReferral>
                    </ModalBody>

                </Modal>

                {this.state.previewModal ?
                    <Modal isOpen={this.state.previewModal} centered toggle={() => this.setState({ previewModal: !this.state.previewModal })} size="xl">
                        <ModalHeader toggle={() => this.setState({ previewModal: !this.state.previewModal })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ previewModal: !this.state.previewModal })}><Isvg src={xIcon} /></button>}>{'Form preview'.translate(this.props.lang)}</ModalHeader>

                        <ModalBody>

                            {/* <CreateDaybookModal {...this.props} daybookFormView={true} referral={{ name: this.state.name, description: this.state.description }} sections={this.generateSections()}></CreateDaybookModal> */}
                            <PreviewAnswerForm
                                {...this.props}
                                referralContent={true}
                                referral={{
                                    name: this.state.name,
                                    description: this.state.description,
                                    intelligentReferral: this.state.intelligentReferral,
                                    items: this.state.items
                                }}
                            />
                        </ModalBody>
                    </Modal>
                    :
                    null
                }

                {this.state.notSavedModal ?
                    <Modal isOpen={this.state.notSavedModal} centered>
                        {/* <ModalHeader style={{ margin: 'auto' }}>{'Warning'.translate(this.props.lang)}</ModalHeader> */}
                        <ModalBody className="delete-modal">
                            {/* <img src={warrning} /> */}
                            <p className="message">{'Data is not saved, if you click Yes it will be deleted'.translate(this.props.lang)}</p>
                        </ModalBody>
                        <ModalFooter className='buttons-right-blue'>
                            <Button color="primary" style={{ margin: 10, minWidth: 100 }} onClick={() => {
                                this.updateQuestionary(true, false)


                            }}>{'Save and exit'.translate(this.props.lang)}</Button>


                            <Button color="primary" style={{ margin: 10, minWidth: 100 }} onClick={() => this.setState({ showForm: null, tabIndex: this.state.tabIndexCheck, tabIndexCheck: false, colorSchemeChanged: false, selectedColorScheme: this.state.currentScheme, pageBuilderChanged: false }, () => {
                                this.setState({
                                    showForm: true,
                                    detectedChage: false
                                }, () => {
                                    this.props.abortAction()
                                    this.props[0].history.push('/personal-referrals/list')
                                })
                            })}>{'Exit without saving'.translate(this.props.lang)}</Button>

                            <Button color="primary" style={{ margin: 10, minWidth: 100 }} onClick={() => {
                                this.setState({ notSavedModal: null })
                            }}>{'Go back'.translate(this.props.lang)}</Button>

                        </ModalFooter>
                    </Modal>
                    : null}
                {this.props.changeLinkModal ?
                    <Modal isOpen={this.props.changeLinkModal} centered>
                        {/* <ModalHeader style={{ margin: 'auto' }}>{'Warning'.translate(this.props.lang)}</ModalHeader> */}
                        <ModalBody className="delete-modal">
                            {/* <img src={warrning} /> */}
                            <p className="message">{'Data is not saved, if you click Yes it will be deleted'.translate(this.props.lang)}</p>
                        </ModalBody>
                        <ModalFooter className='buttons-right-blue'>
                            <Button color="primary" style={{ margin: 10, minWidth: 100 }} onClick={() => {
                                this.updateQuestionary(false, true)


                            }}>{'Save and exit'.translate(this.props.lang)}</Button>


                            <Button color="primary" style={{ margin: 10, minWidth: 100 }} onClick={() => this.setState({ showForm: null, tabIndex: this.state.tabIndexCheck, tabIndexCheck: false, colorSchemeChanged: false, selectedColorScheme: this.state.currentScheme, pageBuilderChanged: false }, () => {
                                this.setState({
                                    showForm: true,
                                    detectedChage: false
                                }, () => {
                                    if (this.props.changeLinkModal.indexOf('group|') != -1) {
                                        this.props.changeGroup(this.props.changeLinkModal.split('|')[1])
                                        this.props.abortAction()
                                    } else if (this.props.changeLinkModal.indexOf('clinic|') != -1) {
                                        this.props.changeClinic(this.props.changeLinkModal.split('|')[1]);
                                        this.props.abortAction()
                                    } else if (!this.state.pageBuilderChanged) {
                                        this.props.abortAction()
                                        this.props[0].history.push(this.props.changeLinkModal)
                                    }
                                })

                            })}>{'Exit without saving'.translate(this.props.lang)}</Button>

                            <Button color="primary" style={{ margin: 10, minWidth: 100 }} onClick={() => {
                                this.props.abortAction()
                            }}>{'Go back'.translate(this.props.lang)}</Button>

                        </ModalFooter>
                    </Modal>
                    : null}

            </div >
        );
    }
}

const selector = formValueSelector('modalForm');

export default connect(state => {
    return {
        group: selector(state, 'group'),
        type: selector(state, 'type'),
        displayOnReferral: selector(state, 'displayOnReferral'),
        conditionQuestion: selector(state, 'conditionQuestion')

    }

}, {
    changeSubgroup: value => change("modalForm", "subgroup", value),

})(Page(ReferralBuilder));