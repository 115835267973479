import React, { Component } from 'react';
import Isvg from 'react-inlinesvg';
import { Link } from 'react-router-dom'
import notification from '../assets/svg/notification.svg';
import moreIcon from '../assets/svg/more.svg';
import info from '../assets/svg/info.svg'
import profileUser from '../assets/images/user.png';
import { API_ENDPOINT } from '../constants';

const modelPath = '/model'

let faceapi = null;
if (typeof window !== 'undefined') {
    faceapi = window.faceapi;
}

import {
    Container,
    Row,
    Col, Input, Label, Modal, ModalBody, ModalHeader, FormGroup, Button, ModalFooter
} from 'reactstrap';

const str = (json) => {
    let text = '<font color="lightblue">';
    text += json ? JSON.stringify(json).replace(/{|}|"|\[|\]/g, '').replace(/,/g, ', ') : '';
    text += '</font>';
    return text;
}

function parseJSON(response) {
    return response.json().then(json => {
        return {
            result: json,
            status: response.status
        }
    })
}

const minScore = 0.2; // minimum score
const maxResults = 5; // maximum number of results to return
let optionsSSDMobileNet;

export class FaceApi extends Component {
    constructor(props) {
        super(props);
        this.scanning = Math.floor(new Date().getTime() / 1000);
        this.state = {
            scanedPhotos: []
        };
    }


    componentDidMount() {
        this.get();

    }


    componentDidUpdate(prevProps, prevState) {

    }
    scanFace = async (roll, pitch, yaw) => {

        roll = Math.round(roll * 100);
        pitch = Math.round(pitch * 100);
        yaw = Math.round(yaw * 100);

        let faceType = -1;
        if (roll >= -5 && roll <= 5 && yaw >= -5 && yaw <= 5) {
            faceType = 0;
        } else if (roll <= -20) {
            faceType = 1;

        } else if (roll >= 20) {
            faceType = 2;

        } else if (yaw <= -20) {
            faceType = 3;

        } else if (yaw >= 20) {
            faceType = 4;
        }
        if (faceType > -1) {
            let scanedPhoto;
            this.scanning = Math.floor(new Date().getTime() / 1000);
            if (this.canvasRef) {
                var canvas = this.canvasRef
                canvas.width = this.videoRef.videoWidth;
                canvas.height = this.videoRef.videoHeight;
                // document.body.appendChild(canvas);

                var context = canvas.getContext('2d');
                context.drawImage(this.videoRef, 0, 0, this.videoRef.videoWidth, this.videoRef.videoHeight);


                canvas.toBlob((blob) => {
                    let file = new File([blob], "screenshot.png", { type: "image/png" })
                    this.setState({
                        takePhotoModal: {
                            message: '',
                            previewImage: canvas.toDataURL('image/png'),
                            image: file
                        }
                    })
                    // canvas.remove();

                }, 'image/png');



                let photo = canvas.toDataURL();
                scanedPhoto = photo
                this.setState({ scanedPhoto }, () => {
                    this.props.checkPhoto(this.state.scanedPhoto)
                })
            }

        }
        // if (this.state.scanedPhotos.filter(el => el.faceType == faceType).length == 0 && faceType > -1) {
        //     this.scanning = Math.floor(new Date().getTime() / 1000);
        //     let scanedPhotos = this.state.scanedPhotos;

        //     this.setState({ scanedPhotos }, () => {
        //         this.props.getNumberOfPhotos(this.state.scanedPhotos.length)
        //         if (this.state.scanedPhotos.length == 5) {
        //             this.setState({ checkFaceType: false })
        //             for (let i = 0; i < this.state.scanedPhotos.length; i++) {
        //                 this.props.savePhoto(this.state.scanedPhotos[i].photo)
        //             }
        //         }
        //     })
        // }

    }

    componentWillUnmount() {
        if (this.stream && this.stream.getTracks()) {
            this.stream.getTracks().forEach(track => track.stop());
            this.stream = null;
        }

    }

    log = (...txt) => {
        // eslint-disable-next-line no-console
        // console.log(...txt);
        const div = document.getElementById('log');
        if (div) div.innerHTML += `<br>${txt}`;
    }
    drawFaces = (canvas, data, fps) => {
        const ctx = canvas.getContext('2d');
        if (!ctx) return;
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        // draw title
        ctx.font = 'small-caps 20px "Segoe UI"';
        ctx.fillStyle = 'white';
        // ctx.fillText(`FPS: ${fps}`, 10, 25);
        for (const person of data) {
            // draw box around each face
            ctx.lineWidth = 3;
            ctx.strokeStyle = 'deepskyblue';
            ctx.fillStyle = 'deepskyblue';
            ctx.globalAlpha = 0.6;
            // ctx.beginPath();
            // ctx.rect(person.detection.box.x, person.detection.box.y, person.detection.box.width, person.detection.box.height);
            // ctx.stroke();
            ctx.globalAlpha = 1;
            // const expression = person.expressions.sort((a, b) => Object.values(a)[0] - Object.values(b)[0]);
            const expression = Object.entries(person.expressions).sort((a, b) => b[1] - a[1]);
            ctx.fillStyle = 'black';
            // ctx.fillText(`gender: ${Math.round(100 * person.genderProbability)}% ${person.gender}`, person.detection.box.x, person.detection.box.y - 59);
            // ctx.fillText(`expression: ${Math.round(100 * expression[0][1])}% ${expression[0][0]}`, person.detection.box.x, person.detection.box.y - 41);
            // ctx.fillText(`age: ${Math.round(person.age)} years`, person.detection.box.x, person.detection.box.y - 23);
            // ctx.fillText(`roll:${person.angle.roll.toFixed(3)} pitch:${person.angle.pitch.toFixed(3)} yaw:${person.angle.yaw.toFixed(3)}`, person.detection.box.x, person.detection.box.y - 5);
            ctx.fillStyle = 'lightblue';
            // ctx.fillText(`gender: ${Math.round(100 * person.genderProbability)}% ${person.gender}`, person.detection.box.x, person.detection.box.y - 60);
            // ctx.fillText(`expression: ${Math.round(100 * expression[0][1])}% ${expression[0][0]}`, person.detection.box.x, person.detection.box.y - 42);
            // ctx.fillText(`age: ${Math.round(person.age)} years`, person.detection.box.x, person.detection.box.y - 24);
            // ctx.fillText(`roll:${person.angle.roll.toFixed(3)} pitch:${person.angle.pitch.toFixed(3)} yaw:${person.angle.yaw.toFixed(3)}`, person.detection.box.x, person.detection.box.y - 6);
            // draw face points for each face
            ctx.globalAlpha = 0.8;
            ctx.fillStyle = 'lightblue';
            const pointSize = 2;
            // for (let i = 0; i < person.landmarks.positions.length; i++) {
            //     ctx.beginPath();
            //     ctx.arc(person.landmarks.positions[i].x, person.landmarks.positions[i].y, pointSize, 0, 2 * Math.PI);
            //     // ctx.fillText(`${i}`, person.landmarks.positions[i].x + 4, person.landmarks.positions[i].y + 4);
            //     ctx.fill();
            // }
            let tsNow = Math.floor(new Date().getTime() / 1000);
            if ((tsNow - this.scanning) > 2) {
                this.scanFace(person.angle.roll.toFixed(3), person.angle.pitch.toFixed(3), person.angle.yaw.toFixed(3))
            }
        }
    }

    detectVideo = async (video, canvas) => {
        if (!video || video.paused) return false;
        const t0 = performance.now();
        faceapi
            .detectAllFaces(video, optionsSSDMobileNet)
            .withFaceLandmarks()
            .withFaceExpressions()
            // .withFaceDescriptors()
            .withAgeAndGender()
            .then((result) => {
                const fps = 1000 / (performance.now() - t0);
                this.drawFaces(canvas, result, fps.toLocaleString());
                requestAnimationFrame(() => this.detectVideo(video, canvas));
                return true;
            })
            .catch((err) => {
                this.log(`Detect Error: ${str(err)}`);
                return false;
            });
        return false;
    }
    setupCamera = async () => {
        const video = document.getElementById('video');
        const canvas = document.getElementById('canvas');
        if (!video || !canvas) return null;

        let msg = '';
        this.log('Setting up camera');
        // setup webcam. note that navigator.mediaDevices requires that page is accessed via https
        if (!navigator.mediaDevices) {
            this.log('Camera Error: access not supported');
            return null;
        }
        let stream;
        const constraints = {
            audio: false,
            video: { facingMode: 'user', resizeMode: 'crop-and-scale', width: { max: 640 }, height: { max: 480 } },
        };
        if (window.innerWidth > window.innerHeight) constraints.video.width = { ideal: 640 };
        else constraints.video.height = { ideal: window.innerHeight };
        try {
            stream = await navigator?.mediaDevices?.getUserMedia?.(constraints);
            this.stream = stream;
        } catch (err) {
            if (err.name === 'PermissionDeniedError' || err.name === 'NotAllowedError') msg = 'camera permission denied';
            else if (err.name === 'SourceUnavailableError') msg = 'camera not available';
            this.log(`Camera Error: ${msg}: ${err.message || err}`);
            return null;
        }
        // @ts-ignore
        if (stream) video.srcObject = stream;
        else {
            this.log('Camera Error: stream empty');
            return null;
        }
        const track = stream.getVideoTracks()[0];
        const settings = track.getSettings();
        if (settings.deviceId) delete settings.deviceId;
        if (settings.groupId) delete settings.groupId;
        if (settings.aspectRatio) settings.aspectRatio = Math.trunc(100 * settings.aspectRatio) / 100;
        this.log(`Camera active: ${track.label}`); // ${str(constraints)}
        this.log(`Camera settings: ${str(settings)}`);
        canvas.addEventListener('click', () => {
            // @ts-ignore
            if (video && video.readyState >= 2) {
                // @ts-ignore
                if (video.paused) {
                    // @ts-ignore
                    video.play();
                    this.detectVideo(video, canvas);
                } else {
                    // @ts-ignore
                    video.pause();
                }
            }
            // @ts-ignore
            this.log(`Camera state: ${video.paused ? 'paused' : 'playing'}`);
        });
        return new Promise((resolve) => {
            video.onloadeddata = async () => {
                // @ts-ignore
                canvas.width = video.videoWidth;
                // @ts-ignore
                canvas.height = video.videoHeight;
                // @ts-ignore
                video.play();
                this.detectVideo(video, canvas);
                resolve(true);
            };
        });
    }
    setupFaceAPI = async () => {
        // load face-api models
        // this.log('Models loading');
        // await faceapi.nets.tinyFaceDetector.load(modelPath); // using ssdMobilenetv1
        await faceapi.nets.ssdMobilenetv1.load(modelPath);
        await faceapi.nets.ageGenderNet.load(modelPath);
        await faceapi.nets.faceLandmark68Net.load(modelPath);
        await faceapi.nets.faceRecognitionNet.load(modelPath);
        await faceapi.nets.faceExpressionNet.load(modelPath);
        optionsSSDMobileNet = new faceapi.SsdMobilenetv1Options({ minConfidence: minScore, maxResults });

        // check tf engine state
        this.log(`Models loaded: ${str(faceapi.tf.engine().state.numTensors)} tensors`);
    }
    get = async () => {
        // initialize tfjs
        this.log('FaceAPI WebCam Test');

        // if you want to use wasm backend location for wasm binaries must be specified
        // await faceapi.tf.setWasmPaths('../node_modules/@tensorflow/tfjs-backend-wasm/dist/');
        // await faceapi.tf.setBackend('wasm');

        // default is webgl backend
        await faceapi.tf.setBackend('webgl');

        await faceapi.tf.enableProdMode();
        await faceapi.tf.ENV.set('DEBUG', false);
        await faceapi.tf.ready();

        // check version
        this.log(`Version: FaceAPI ${str(faceapi?.version || '(not loaded)')} TensorFlow/JS ${str(faceapi?.tf?.version_core || '(not loaded)')} Backend: ${str(faceapi?.tf?.getBackend() || '(not loaded)')}`);
        // log(`Flags: ${JSON.stringify(faceapi?.tf?.ENV.flags || { tf: 'not loaded' })}`);

        await this.setupFaceAPI();
        await this.setupCamera();
    }




    render() {
        return (
            <div className='face-api-login-component-wrap'>
                {/* <video id="video" ref={node => this.videoRef = node} playsinline className="video"></video> */}
                <div className='video-wrap'>
                    <video id="video" ref={node => this.videoRef = node} playsinline className="video"></video>
                </div>
                <canvas id="canvas" ref={node => this.canvasRef = node} className="canvas"></canvas>

            </div>
        )
    }
}

export default FaceApi;