import React, { Component } from "react";
import Page from "../../containers/page";
import xIcon from '../../assets/svg/x.svg';

import {
    Container,
    Row,
    Col,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Table,
    Input,
    FormGroup,
    Label,
    Button,
} from "reactstrap";

import Isvg from "react-inlinesvg";

import { Player, Controls } from '@lottiefiles/react-lottie-player';

import FormBuilder from "../../components/forms/modalFormBuilder";
import { required } from "../../components/forms/validation";
import ErrorModal from '../../components/errorModal';
import InfoModal from '../../components/infoModal';
import ListBuilder from "../../components/listBuilder";
import DeleteModal from "../../components/deleteModal";
import {
    getSearchParams,
    generateSearchLink,
    generateSearchLinkMultiple,
} from "../../helpers/linkHelper";
import { API_ENDPOINT } from "../../constants";
import { formValueSelector, change } from "redux-form"; // ES6
import { connect } from "react-redux";
import ClinicsFilter from "../../components/clinicsFilter";
import UsersFilter from "../../components/usersFilter";
import Image from "../../components/forms/fields/image";
import Zip from "../../components/forms/fields/zip";


import edit from "../../assets/svg/edit.svg";
import garabage from "../../assets/svg/garbage.svg";
import attachInvoice from '../../assets/svg/attach.svg'

import addIcon from "../../assets/svg/add-icon.svg";
import webdocImage from "../../assets/images/webdoc.png";
import Search from "../../components/search";
import profileUser from "../../assets/images/user.png";
import notification from "../../assets/svg/notification.svg";
import Usage from "../../assets/svg/usage.svg";
import importIcon from '../../assets/svg/import.svg'

import exportIcon from "../../assets/svg/export.svg";
import Checkbox from '../../components/forms/fields/checkbox';
import MainClicGroupFilter from '../../components/mainClinicGroupsFilter';
import striptags from "striptags";

import moment from 'moment';
import DatePicker from '../../components/forms/fields/datePicker';

import { Pie, Bar } from 'react-chartjs-2';
import downloadIcon from '../../assets/svg/download.svg';

import CanvasJSReact from '../../assets/canvasjs.react';
var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;
import axios from 'axios'
import SelectGroupForTransfer from '../../components/selectGroupForTransfer'
import ListCheckBox from '../../components/forms/fields/listCheckboxFlex';

import Toggle from '../../components/forms/fields/toggle';


function parseJSON(response) {
    return response.json().then(json => {
        return {
            result: json,
            status: response.status
        }
    })
}

function parseText(response) {
    return response.text().then(text => {
        return {
            result: text,
            status: response.status
        }
    })
}
/**
* Group settings page
* @author   Milan Stanojevic
*/
class ExportClinicsStatistic extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props.initialData,
            form: false,
            useState: false,
            params: {
                entries: 10,
                page: 0,
            },
            items: [],
            groups: [],
            emails: [],
            subgroups: [],
            backups: [],
            importDatabaseTab: 1,
            total: 0,
            loading: true,
            clinicModules: [],
            selectedBackup: null,
            errors: {},
            licence: 10,
            videoLicence: 0,
            videoLink: 0,
            webDocModal: null,
            webDocSelectedClinics: [],
            integrationActive: 0,
            startDateSms: moment.unix(Math.floor(new Date().getTime() / 1000)).format('YYYY-MM-DD'),
            endDateSms: moment.unix(Math.floor(new Date().getTime() / 1000)).format('YYYY-MM-DD'),
            values: [],
            usersForTransfer: {},
            newUsernames: [],
            showAllUsersModal: null,


        };


    }


    get = () => {
        this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));

        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0 })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

        for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
            this.props.loadDataWithQuery[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0 })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

    }









    componentDidMount() {
        if (typeof window !== "undefined") {
            window.scrollTo(0, 0);
        }

        this.get();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps[0].location.search != this.props[0].location.search) {
            this.setState(
                {
                    loading: true,
                },
                () => {
                    this.get();
                }
            );
        }


    }



    updateSort = (field, type) => {
        if (this.state.useState) {
            let state = this.state.params;
            state.sortField = field;
            state.sortType = type;
            this.setState({
                params: state,
            });
        } else {
            this.props[0].history.push(
                generateSearchLinkMultiple(
                    this.props[0].location.search,
                    { entries: 10, page: 0 },
                    [
                        { name: "sortField", value: field },
                        { name: "sortType", value: type },
                    ],
                    false
                )
            );
        }
    };

    updateParams = (name, value, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0,
                };
                obj[name] = value;
                this.setState({ params: obj });
            } else {
                let obj = this.state.params;
                obj[name] = value;
                this.setState({ params: obj });
            }
        } else {
            this.props[0].history.push(
                generateSearchLink(
                    this.props[0].location.search,
                    { entries: 10, page: 0 },
                    name,
                    value,
                    restart
                )
            );
        }
    };
    updateMultipleParams = (fields, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0,
                };
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj });
            } else {
                let obj = this.state.params;
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj });
            }
        } else {
            this.props[0].history.push(
                generateSearchLinkMultiple(
                    this.props[0].location.search,
                    { entries: 10, page: 0 },
                    fields,
                    restart
                )
            );
        }
    };






    exportClinicsStatistic = (listOfGroups, dateFrom, dateTo, exportAll, email) => {
        if (email) {
            fetch(API_ENDPOINT + "/data/clinic-groups/statistic/export", {
                method: "POST",
                headers: {
                    Authorization:
                        typeof localStorage !== "undefined"
                            ? `Bearer ${localStorage.getItem("authToken")}`
                            : null,
                    "content-type": "application/json",
                },
                body: JSON.stringify({ group: listOfGroups, dateFrom: dateFrom, dateTo: dateTo, exportAll: exportAll, email: email, dataStorage: true }),
            }).then(res => res.json()).then((result) => {
                if (result) {

                    this.setState({
                        exportClinicsModal: false,
                        questionaries: false,
                        listOfGroups: false,
                        emailSendStatistic: '',
                        startDateSms: this.props.getStringDateTs(Math.floor(new Date().getTime() / 1000), 'YYYY-MM-DD')/*moment.unix(Math.floor(new Date().getTime() / 1000)).format('YYYY-MM-DD')*/,
                        endDateSms: this.props.getStringDateTs(Math.floor(new Date().getTime() / 1000), 'YYYY-MM-DD')/*moment.unix(Math.floor(new Date().getTime() / 1000)).format('YYYY-MM-DD')*/,
                        successMessage: 'Statistics have been successfully exported'.translate(this.props.lang)
                    })
                }
                this.get();

            })
        } else {
            this.setState({
                error: 'You must enter an email'.translate(this.props.lang)
            })
        }

    }




    render() {
        let params = {};
        if (this.state.useState) {
            params = this.state.params;
        } else {
            params = getSearchParams(this.props[0].location.search, {
                entries: 10,
                page: 0,
            });
        }


        return (
            <div className={this.props.autoHideSidebar ? "dashboard dashboard-auto-hide" : "dashboard"}>
                <Container fluid>
                    <Row>
                        <Col lg="12">
                            <div className="panel">
                                <div className="panel-header clinic-panel-header">
                                    <h5 className="header-component">
                                        {this.props.linksMeta &&
                                            this.props.linksMeta[this.props[0].location.pathname]
                                            ? this.props.linksMeta[this.props[0].location.pathname][
                                            this.props.lang
                                            ]
                                            : ""}{" "}
                                    </h5>{" "}


                                </div>
                                <Row>
                                    <Col lg='6'>
                                        <DatePicker
                                            label={'Start date'.translate(this.props.lang)}
                                            value={this.state.startDateSms}
                                            onChange={(e) => {
                                                this.setState({
                                                    startDateSms: e
                                                }, () => {
                                                    this.get()
                                                    if (new Date(this.state.startDateSms).getTime() > new Date(this.state.endDateSms).getTime()) {
                                                        this.setState({
                                                            endDateSms: e
                                                        }, () => {
                                                            this.get()
                                                        })
                                                    }
                                                })

                                            }} />
                                    </Col>
                                    <Col lg='6'>
                                        <DatePicker
                                            label={'End date'.translate(this.props.lang)}
                                            value={this.state.endDateSms}
                                            onChange={(e) => {
                                                this.setState({
                                                    endDateSms: e
                                                }, () => {
                                                    this.get()
                                                    if (new Date(this.state.startDateSms).getTime() > new Date(this.state.endDateSms).getTime()) {
                                                        this.setState({
                                                            startDateSms: e
                                                        }, () => {
                                                            this.get()
                                                        })
                                                    }
                                                })

                                            }} />
                                    </Col>

                                </Row>
                                <div style={{ marginBottom: 35, marginTop: 15 }}>
                                    <Label>{'Send statistics to email'.translate(this.props.lang)}</Label>
                                    <Input size="sm" type="text" value={this.state.emailSendStatistic} onChange={(e) => {
                                        this.setState({
                                            emailSendStatistic: e.target.value
                                        })

                                    }}></Input>
                                </div>

                                <SelectGroupForTransfer {...this.props}
                                    value={this.state.questionaries}
                                    onChange={(val) => {
                                        if (val) {
                                            let arr = []
                                            for (var key in val) {
                                                arr.push(key)
                                            }
                                            let questionaries = this.state.questionaries;
                                            questionaries = val;
                                            this.setState({ questionaries, listOfGroups: arr }, () => {
                                                // console.log(this.state.listOfGroups)
                                            });
                                        }
                                    }}
                                ></SelectGroupForTransfer>

                                <div className="export-clinics-statistic-buttons-wrap">

                                    <Button
                                        color="primary"
                                        size="sm"
                                        style={{ marginRight: 15 }}
                                        disabled={this.state.listOfGroups && this.state.listOfGroups.length > 0 ? false : true}
                                        onClick={() => this.exportClinicsStatistic(this.state.listOfGroups, this.state.startDateSms, this.state.endDateSms, false, this.state.emailSendStatistic)}
                                    >
                                        {"Export selected".translate(this.props.lang)}
                                    </Button>
                                    <Button
                                        color="primary"
                                        size="sm"
                                        onClick={() => this.exportClinicsStatistic(this.state.listOfGroups, this.state.startDateSms, this.state.endDateSms, true, this.state.emailSendStatistic)}
                                    >
                                        {"Export all".translate(this.props.lang)}
                                    </Button>
                                </div>



                            </div>
                        </Col>

                    </Row>
                </Container>




                {this.state.successMessage ?
                    <InfoModal
                        isOpen={this.state.successMessage}

                        header={'Info'.translate(this.props.lang)}
                        info={this.state.successMessage}
                        buttons={[
                            <Button color='primary' onClick={() => {
                                this.setState({
                                    successMessage: null
                                })

                            }}>{'Ok'.translate(this.props.lang)}</Button>
                        ]}
                    />

                    :

                    null}




                {this.state.error ?

                    <ErrorModal lang={this.props.lang}
                        isOpen={this.state.error}
                        toggle={() => this.setState({ error: null })}

                    >
                        {this.state.error.translate(this.props.lang)}
                    </ErrorModal>

                    : null}














            </div>
        );
    }
}

const selector = formValueSelector("modalForm");

export default connect(
    (state) => {
        return { group: selector(state, "group") };
    },
    {
        changeSubgroup: (value) => change("modalForm", "subgroup", value),
    }
)(Page(ExportClinicsStatistic));
