import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import Routes from './routes'
import { Provider } from 'react-redux'
import { createStore, combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { withRouter } from 'react-router-dom';
import DocumentMeta from 'react-document-meta';
import ReactGA from 'react-ga';

import { API_ENDPOINT } from './constants';
import socketIO from 'socket.io-client';
import incomingCall from './assets/sound/incoming_call.mp3';
import emergency from './assets/sound/emergency.mp3';
import call from './assets/svg/call.svg';
import xIcon from './assets/svg/x.svg';

import profilePicture from './assets/images/user.png';
import Isvg from 'react-inlinesvg';
import { Rnd } from 'react-rnd';
import dragIcon from './assets/svg/move.svg';

import { Button, Modal, Row, ModalHeader, ModalBody, ModalFooter, Col } from 'reactstrap';
import ErrorModal from './components/errorModal';
import InfoModal from './components/infoModal';


import { matchPath } from 'react-router-dom';
import { routes } from './routesList';
import QA from './qa/qa';
import ChatComponent from './components/chatComponent';

import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

import { SENTRY_DSN } from './constants';

import moment from 'moment';

import { Player, Controls } from "@lottiefiles/react-lottie-player";
import VideoCallPopUp from './views/chat/videoCallPopUp';


var browserInfo = require('browser-info');

const modelPath = '/model'
const minScore = 0.2; // minimum score
const maxResults = 5; // maximum number of results to return
let optionsSSDMobileNet;

const APP_VERSION = '2.0.0.5';


function getSevenDaysBeforeTs() {
  try {
    const today = new Date();
    const sevenDaysBefore = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7, today.getHours(), today.getMinutes(), today.getSeconds())?.getTime();

    return sevenDaysBefore
  } catch (err) {
    console.log('--- getSevenDaysBeforeTs err: ', err)
    return null;
  }
}


let faceapi = null;
if (typeof window !== 'undefined') {
  faceapi = window.faceapi;
}



const rootReducer = combineReducers({
  form: formReducer
});

// const LOGOUT_TIMEOUT = 20 * 60 * 1000; //ms
const LOGOUT_TIMEOUTV2 = 50 * 60; //ms

const store = createStore(rootReducer)
Number.prototype.formatPrice = function (sep = 2) {
  let dec_point = '.';
  let thousands_sep = ',';

  var parts = parseFloat(this).toFixed(sep).split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousands_sep);

  return parts.join(dec_point);
}

String.prototype.formatPrice = function (sep = 2) {
  let dec_point = '.';
  let thousands_sep = ',';

  var parts = parseFloat(this).toFixed(sep).split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousands_sep);

  return parts.join(dec_point);
}



function generateAlias(str) {
  str = str.toLowerCase();
  str = str.replace(/ä/g, 'a');
  str = str.replace(/ö/g, 'o');
  str = str.replace(/ü/g, 'u');
  str = str.replace(/ß/g, 'b');

  str = str.replace(/[^a-zA-Z0-9]/gi, '-').toLowerCase()
  str = str.replace(/-+/g, '-');

  return str;
}
//web notifications
function urlBase64ToUint8Array(base64String) {
  const padding = '='.repeat((4 - base64String.length % 4) % 4);
  const base64 = (base64String + padding)
    .replace(/-/g, '+')
    .replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

const publicVapidKey = 'BAhhWYormQopBNXSFIrv73BSUFEv3enfourjBWuYYG1o_5ZPYmA_0HarJBobZQDuyy_qtaz2Z1sZ8wBRAe_PevU';
//ent web notifications 

if (String.prototype.generateAlias == null) {
  String.prototype.generateAlias = function () {
    return generateAlias(this);
  }
}


Object.translate = function (o, s, lang) {
  if (!o) {
    return '';
  }

  s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
  s = s.replace(/^\./, '');           // strip a leading dot
  var a = s.split('.');
  for (var i = 0, n = a.length; i < n; ++i) {
    var k = a[i];
    if (k in o) {
      o = o[k];
    } else {
      return;
    }
  }
  return o[lang] ? o[lang] : o['en'];
}


Object.get = function (o, s) {
  //console.log(o, s)
  if (!o) {
    return null;
  }

  s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
  s = s.replace(/^\./, '');           // strip a leading dot
  var a = s.split('.');
  for (var i = 0, n = a.length; i < n; ++i) {
    var k = a[i];
    if (k in o) {
      o = o[k];
    } else {
      return;
    }
  }
  return o;
}


if (String.prototype.translate == null) {
  String.prototype.translate = function (lang) {
    /* if (!localStorage?.translate){
       localStorage?.translate = JSON.stringify({
         'ba': {
  
         },
         'en': {
  
         }
       });
     }
  
     let obj = JSON.parse(localStorage?.translate);
     obj.en[this] = this;
     obj.ba[this] = this;
 
     localStorage?.translate = JSON.stringify(obj);
     
     return this;*/

    let langs = typeof window !== 'undefined' ? window.langs : null;

    if (!langs) {
      return this;
    }

    //console.log(Object.keys(langs), lang)
    if (langs[lang] && langs[lang][this]) {
      return langs[lang][this];
    }
    else return this;
  }
}



if (String.prototype.protectReferralFiles == null) {
  String.prototype.protectReferralFiles = function (mediaToken, rid) {
    let res = this;
    const regexp = /(?:(?:https?|ftp|file):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#/%=~_|$?!:,.]*\)|[A-Z0-9+&@#/%=~_|$])/igm;
    if (this) {
      var match;
      let urls = [];
      do {
        match = regexp.exec(res);
        if (match && match[0]) {
          urls.push(match[0]);
        }
      } while (match);

      for (let i = 0; i < urls.length; i++) {
        res = res.replace(urls[i], `${urls[i]}?mt=${mediaToken}&rid=${rid}`);
      }
    }

    return res;
  }
}


class App extends Component {
  constructor(props) {
    super(props);
    this.translate = this.translate.bind(this);
    this.setLang = this.setLang.bind(this);
    this.setLightMode = this.setLightMode.bind(this);


    if (typeof window !== 'undefined') {
      window.lang = 'se';
      window._lang = 'se';
    }

    if (typeof window !== 'undefined' && typeof navigator !== 'undefined' && navigator?.mediaDevices && navigator?.userAgent?.toLowerCase?.().indexOf?.('firefox') == -1) {
      let getUserMedia = navigator?.mediaDevices?.getUserMedia || null;

      navigator.mediaDevices.getUserMedia = (constraints) => {
        if (!window._webRTCStreams) {
          window._webRTCStreams = [];
        }

        for (let i = 0; i < window._webRTCStreams.length; i++) {
          if (window._webRTCStreams[i]) {
            try {
              window._webRTCStreams[i].getTracks().forEach(track => track.stop());
              window._webRTCStreams[i] = null;
            } catch (e) { console.log(e) }
          }
        }

        return new Promise((resolve, reject) => {
          getUserMedia(constraints).then(stream => {
            window._webRTCStreams.push(stream);

            resolve(stream);

          }).catch((err) => {
            if (constraints && constraints.video && constraints.video.facingMode && constraints.video.facingMode.exact && constraints.video.facingMode.exact == "environment") {
              console.log('Trying to find back camera...')
              for (let i = 0; i < window._webRTCStreams.length; i++) {
                if (window._webRTCStreams[i]) {
                  try {
                    window._webRTCStreams[i].getTracks().forEach(track => track.stop());
                    window._webRTCStreams[i] = null;
                  } catch (e) { console.log(e) }
                }
              }

              try {
                navigator.mediaDevices.enumerateDevices().then(async (devices) => {
                  let backCameras = [];
                  for (let i = 0; i < devices.length; i++) {
                    if (devices[i].kind == "videoinput" && /back|rear|environment/gi.test(devices[i].label)) {
                      console.log('Found back camera', devices[i]);
                      try {
                        constraints.video = { deviceId: devices[i].deviceId };
                        let stream = await getUserMedia(constraints);
                        window._webRTCStreams.push(stream);
                        resolve(stream);
                        return;
                      } catch (e) { console.log('not working...') }
                    }
                  }

                  reject('error back camera not found');

                })
              } catch (err) { reject(err) }

            } else {
              reject(err);
            }
          });
        })
      }
    }


    this.state = {
      country: typeof window !== 'undefined' ? localStorage?.getItem?.('country') : null,
      secondaryLanguageDisabled: typeof window !== 'undefined' && localStorage?.getItem?.('secondaryLanguageDisabled') === 'yes' ? true : false,
      eConnect: {
        createdCount: 0,
        receivedCount: 0,
      },
      hasSavedForms: null,
      _printError: false,
      hasTakeCare: false,
      hasSemble: false,
      hasRegistrationRequests: false,
      lang: typeof window !== 'undefined' && (window.origin?.indexOf('https://sandbox.curoflow.se') !== -1 || window.origin?.indexOf('curoflow.dk') !== -1 || localStorage?.getItem?.('secondaryLanguageDisabled') === 'yes') ? 'en' : 'se',
      lightMode: 0,
      services: [],
      latestNews: [],
      modulesTree: [],
      availablePaths: [],
      ...props.appInitialData,
      linksMeta: {},
      userVerificationInProgress: true,
      //patientAccountModal: true,
      dateFormat: typeof window !== 'undefined' && localStorage?.getItem?.('secondaryLanguageDisabled') === 'yes' ? 'MM-DD-YYYY' : 'YYYY-MM-DD',
      dateTimeFormat: typeof window !== 'undefined' && localStorage?.getItem?.('secondaryLanguageDisabled') === 'yes' ? 'MM-DD-YYYY HH:mm' : 'YYYY-MM-DD HH:mm',
      autoHideSidebar: false,
      sidebarWide: false,
      // sidebarWide: typeof window != 'undefined' && window.innerWidth < 1199 ? false : true,
      videoCallX: 0,
      videoCallY: 0,
      logoutMessage: false,
      referralStatusBefore: false,
      lastNotifications: [],
      newNotif: false,
      groupSettingsModal: false,
      changeLinkModal: false,
      actionLink: false,
      _toManyRequests: null,
      showSavedFormsMessage: false,
      chat: {
        openChatList: false,
        clickedChats: [],
        openedChats: [],
        chat: {}
      },
      privacyPolicyModal: false,
      culture: 'sv-GB',
      activeVideoCallInWaitingRoom: false,

    };

  }


  detectAutomatedTool = () => {
    var documentDetectionKeys = [
      "__webdriver_evaluate",
      "__selenium_evaluate",
      "__webdriver_script_function",
      "__webdriver_script_func",
      "__webdriver_script_fn",
      "__fxdriver_evaluate",
      "__driver_unwrapped",
      "__webdriver_unwrapped",
      "__driver_evaluate",
      "__selenium_unwrapped",
      "__fxdriver_unwrapped",
    ];

    var windowDetectionKeys = [
      "_phantom",
      "__nightmare",
      "_selenium",
      "callPhantom",
      "callSelenium",
      "_Selenium_IDE_Recorder",
    ];

    for (const windowDetectionKey in windowDetectionKeys) {
      const windowDetectionKeyValue = windowDetectionKeys[windowDetectionKey];
      if (window[windowDetectionKeyValue]) {
        return true;
      }
    };
    for (const documentDetectionKey in documentDetectionKeys) {
      const documentDetectionKeyValue = documentDetectionKeys[documentDetectionKey];
      if (window['document'][documentDetectionKeyValue]) {
        return true;
      }
    };

    for (const documentKey in window['document']) {
      if (documentKey.match(/\$[a-z]dc_/) && window['document'][documentKey]['cache_']) {
        return true;
      }
    }

    if (window['external'] && window['external'].toString() && (window['external'].toString()['indexOf']('Sequentum') != -1)) return true;

    if (window['document']['documentElement']['getAttribute']('selenium')) return true;
    if (window['document']['documentElement']['getAttribute']('webdriver')) return true;
    if (window['document']['documentElement']['getAttribute']('driver')) return true;
    if (!window['navigator']) return true;
    if (window['navigator']['webdriver']) return true;

    return false;
  };


  toggleSidebar = () => {
    const currentState = this.state.sidebarWide
    this.setState({ sidebarWide: !currentState })
  }
  toggleSidebarv2 = (value) => {
    if (this.props.autoHideSidebar) {
      this.setState({ sidebarWide: value })
    } else {
      this.setState({ sidebarWide: !this.state.sidebarWide })
    }

  }
  toggleAutoHide = () => {
    this.setState({ autoHideSidebar: !this.state.autoHideSidebar }, () => {
      if (typeof window != 'undefined') {
        localStorage?.setItem('autoHideSidebar', this.state.autoHideSidebar)
      }
    })
  }

  forceSidebarWideToFalse = () => this.setState({ sidebarWide: false });


  formatName = (title, name) => {
    return this.state.lang == 'se' ? (name ? name : '') + " " + (title ? title : '') : (title ? title : '') + " " + (name ? name : '')
  }

  setLang(lang) {
    if (this.state.secondaryLanguageDisabled) return;
    this.setState({
      lang: lang
    });
  }
  setLightMode(val) {
    this.setState({
      lightMode: val
    });
  }

  setCulture = () => {
    let culture = 'sv-GB';
    if (this.state.lang === 'en') {
      culture = 'en-GB';
    }
    if (this.state.countryTwoLetter === 'es' && this.state.country === 'uk' && this.state.lang !== 'en') {
      culture = 'es';
    } else if (this.state.countryTwoLetter === 'gr' && this.state.country === 'uk' && this.state.lang !== 'en') {
      culture = 'el'
    }

    if (culture !== this.state.culture) {
      this.setState({ culture })
    }
  }

  setActiveVideoCallInWaitingRoom = (val) => {
    this.setState({
      activeVideoCallInWaitingRoom: val
    })
  }

  translate(text) {
    return text;
  }


  updateMeta = (data) => {
    this.setState({
      metaTags: data
    })
  }

  generateModulesMeta = (tree) => {
    let linksMeta = {};
    for (let i = 0; i < tree.length; i++) {
      linksMeta[tree[i].link] = tree[i].name;
      if (tree[i].modules) {
        for (let j = 0; j < tree[i].modules.length; j++) {
          linksMeta[tree[i].modules[j].link] = tree[i].modules[j].name;
        }
      }
    }
    // console.log(linksMeta)
    this.setState({
      linksMeta
    })
  }

  statusBefore = (status) => {
    console.log(status)
    this.setState({
      referralStatusBefore: status
    })
  }

  handleUploadFileProgress = (data, progress) => {
    // if (data){
    //   IdleTimerManager.setIdleTimerDisabled(true);
    // }else{
    //   IdleTimerManager.setIdleTimerDisabled(false);
    // }
    this.setState({
      uploadingFile: data,
      uploadingFileProgress: progress
    })
  }

  ring112Audio = (item) => {
    // console.log('adsf')
    // if (!item && this.emergency) {
    //   try {
    //     this.emergency.pause();
    //     this.emergency.currentTime = 0;
    //   } catch (error) {
    //     console.log(error)
    //   }


    // }

    if (item) {
      if (this.ringTimeout) {
        clearTimeout(this.ringTimeout)
      }
      this.emergency.loop = true
      this.emergency.play();

      this.ringTimeout = setTimeout(() => {
        if (this.emergency) {
          try {
            this.emergency.pause();
            this.emergency.currentTime = 0;
          } catch (error) {
            console.log(error)
          }
        }
      }, 3000)
    } else {
      if (this.ringTimeout) {
        clearTimeout(this.ringTimeout)
      }
      this.emergency.pause();
      this.emergency.currentTime = 0;

    }

  }

  clearStatusBefore = () => {
    this.setState({
      referralStatusBefore: null
    })
  }

  groupSettingsChanged = () => {
    this.setState({
      groupSettingsModal: true
    })
  }

  openModal = (item) => {
    this.setState({
      changeLinkModal: item,
      groupSettingsModal: false,
    })
  }


  abortAction = () => {

    this.setState({
      changeLinkModal: false,
      groupSettingsModal: false,
    })
  }

  enableLogoutMessage = () => {
    this.setState({ logoutMessage: true })
  }
  disableLogoutMessage = () => {
    this.setState({ logoutMessage: false })
  }

  checkSavedFormsMessage = () => {
    if (typeof window == 'undefined') {
      return;
    }
    let groupAlias = localStorage?.groupAlias;
    let checkedGroupsSavedForms = localStorage?.checkedGroupsSavedForms ? JSON.parse(localStorage?.checkedGroupsSavedForms) : [];
    if (groupAlias && this.state.uData && this.state.uData.userHaveUnfinishedSavedForms && checkedGroupsSavedForms.filter(el => el.toLowerCase() === groupAlias.toLowerCase()).length === 0) {
      if (!this.state.showSavedFormsMessage) {
        this.setState({ showSavedFormsMessage: true })
      }
    } else {
      if (this.state.showSavedFormsMessage) {
        this.setState({ showSavedFormsMessage: false })
      }
    }

  }

  verifyUser = (callback = null, setGroupCallback = null, disablePatientCheck = false, redirectLink = null) => {

    if (typeof window != 'undefined' && window.stopVerifyFun) {
      console.log(window.stopVerifyFun);
      return
    }

    if (!localStorage?.getItem('authToken')) {
      this.setState({
        userVerificationInProgress: false
      })
      return;
    }

    fetch(API_ENDPOINT + '/notification/last', {
      method: 'POST',
      headers: {
        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage?.getItem('authToken')}` : null,
        'content-type': 'application/json'
      },

    }).then(res => res.json()).then((result) => {
      if (result && result.seen && typeof window != 'undefined') {
        localStorage?.setItem('notif', result?._id);
        this.setState({
          newNotif: false
        })
      } else {
        this.setState({
          newNotif: true
        })
      }



    })

    if (localStorage?.getItem?.('profile')) {
      localStorage?.removeItem('bankidlogin');

      this.setState({
        selectedProfile: localStorage?.getItem('profile')
      });
    }
    // if (localStorage?.getItem('profile') == 'doctor') {
    //   localStorage?.removeItem('loginLandingPage');
    // }

    if (typeof localStorage && localStorage?.getItem('groupAlias') != 'undefined' && localStorage?.getItem('groupAlias') != null) {
      let alias = localStorage?.getItem('groupAlias')
      let lastGroup = localStorage?.getItem('lastGroup');
      let profile = localStorage?.getItem('profile')
      let groupId = null;
      let clinicId = null;
      if (this.state.selectedGroup) {
        groupId = this.state.selectedGroup;
      }
      if (this.state.selectedClinic) {
        clinicId = this.state.selectedClinic;
      }

      const lastLogin2fa = localStorage?.getItem?.('2faLogin') && localStorage?.getItem?.('2faLogin') === 'true';

      fetch(API_ENDPOINT + (lastGroup ? `/users/verify?grupa=${alias}&profile=${profile}&groupId=${groupId}&lastGroup=${lastGroup}&clinicId=${clinicId}${lastLogin2fa ? '&lastLogin2fa=true' : ''}` : `/users/verify?grupa=${alias}&profile=${profile}&groupId=${groupId}&clinicId=${clinicId}${lastLogin2fa ? '&lastLogin2fa=true' : ''}`), {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage?.getItem('authToken')}`
        },
      }).then(res => {
        return res.json()
      }).then((result) => {
        if (result && result.verificationCodeNeeded) {
          let email = ''
          if (result.email) {
            // email = result.email.split('@')[0] + result.email.split('@')[1].replace(/[^0-9,.]/g, "*")
            email = result.email
          }
          this.props.history.push({
            pathname: '/verification-code',
            state: {
              email: email
            }
          })
          return;
        }
        this.setState({
          userVerificationInProgress: false
        })
        if (!result.error) {

          if (!localStorage?.getItem('authToken')) {
            this.setState({
              userVerificationInProgress: false
            })
            return;
          }


          if (localStorage?.bankidlogin) {
            localStorage?.setItem('profile', 'doctor')
            localStorage?.setItem('userLevel', 20);
            localStorage?.removeItem('bankidlogin')

            // if (result.havePersonalReferrals) {
            //   localStorage?.setItem('profile', 'patient');
            //   localStorage?.removeItem('bankidlogin')
            // } else {
            //   localStorage?.setItem('profile', 'doctor')
            //   localStorage?.removeItem('bankidlogin')
            // }
          }
          if (localStorage?.loginLandingPage) {
            // if (result.havePersonalReferrals) {
            //   localStorage?.setItem('profile', 'patient')
            //   localStorage?.removeItem('loginLandingPage')
            // } else {
            //   localStorage?.setItem('profile', 'doctor')
            //   localStorage?.removeItem('loginLandingPage')
            // }
            //  console.log(result.havePersonalReferrals)
            localStorage?.setItem('profile', 'patient')
            localStorage?.setItem('userLevel', 2);
            localStorage?.removeItem('loginLandingPage')

          }

          if (typeof window !== 'undefined')
            window.lang = result.langKey;
          window._lang = result.langKey;

          this.setState({
            uData: result,
            lang: this.state.secondaryLanguageDisabled ? 'en' : result.langKey,
            hasTakeCare: result.hasTakeCare,
            hasSemble: result.hasSemble,
            dateFormat: result.langKey == 'se' || this.state.secondaryLanguageDisabled ? 'YYYY-MM-DD' : 'MM-DD-YYYY',
            dateTimeFormat: result.langKey == 'se' || this.state.secondaryLanguageDisabled ? 'YYYY-MM-DD HH:mm' : 'MM-DD-YYYY HH:mm',

          }, () => {
            // console.log(this.state.uData)
            this.forceUpdate()
            this.checkPrivacyPolicyModal()
            this.checkConsultationIndicators()
            this.checkSavedFormsMessage()
            if (callback) {
              callback(this.state.uData);
            }
          })
          if (result.clinicGroups && result.clinicGroups.length && !this.state.selectedGroup) {
            if (localStorage?.getItem('group')) {
              let groupIdx = -1;
              for (let i = 0; i < result.clinicGroups.length; i++) {
                if (result.clinicGroups[i]?._id == localStorage?.getItem('group')) {
                  groupIdx = i;
                  break;
                }
              }
              if (groupIdx != -1) {
                let clinicIdx = -1;
                if (localStorage?.getItem('clinic')) {
                  for (let i = 0; i < result.clinicGroups?.[groupIdx]?.clinics?.length; i++) {
                    if (result.clinicGroups[groupIdx]?.clinics[i]?._id == localStorage?.getItem('clinic')) {
                      clinicIdx = i;
                      break;
                    }
                  }
                }
                this.setState({
                  selectedGroup: result.clinicGroups[groupIdx]?._id,
                  selectedClinic: clinicIdx != -1 ? (result.clinicGroups[groupIdx].clinics && result.clinicGroups[groupIdx].clinics[clinicIdx] ? result.clinicGroups[groupIdx].clinics[clinicIdx]?._id : null) : (result.clinicGroups[groupIdx].clinics && result.clinicGroups[groupIdx].clinics[0] ? result.clinicGroups[groupIdx].clinics[0]?._id : null)
                }, () => {
                  this.verifyUser()
                  if (setGroupCallback) {
                    setGroupCallback();
                  }
                })
              } else {
                let clinicIdx = -1;
                if (localStorage?.getItem('clinic')) {
                  for (let i = 0; i < result.clinicGroups[0].clinics.length; i++) {
                    if (result.clinicGroups[0].clinics[i]?._id == localStorage?.getItem('clinic')) {
                      clinicIdx = i;
                      break;
                    }
                  }
                }
                this.setState({
                  selectedGroup: result.clinicGroups[0]?._id,
                  selectedClinic: clinicIdx != -1 ? (result.clinicGroups[0].clinics && result.clinicGroups[0].clinics[clinicIdx] ? result.clinicGroups[0].clinics[clinicIdx]?._id : null) : (result.clinicGroups[0].clinics && result.clinicGroups[0].clinics[0] ? result.clinicGroups[0].clinics[0]?._id : null)
                }, () => {
                  this.verifyUser()
                  if (setGroupCallback) {
                    setGroupCallback();
                  }
                })
              }

            } else {
              let clinicIdx = -1;
              if (localStorage?.getItem('clinic')) {
                for (let i = 0; i < result.clinicGroups[0].clinics.length; i++) {
                  if (result.clinicGroups[0].clinics[i]?._id == localStorage?.getItem('clinic')) {
                    clinicIdx = i;
                    break;
                  }
                }
              }
              this.setState({
                selectedGroup: result.clinicGroups[0]?._id,
                selectedClinic: clinicIdx != -1 ? (result.clinicGroups[0].clinics && result.clinicGroups[0].clinics[clinicIdx] ? result.clinicGroups[0].clinics[clinicIdx]?._id : null) : (result.clinicGroups[0].clinics && result.clinicGroups[0].clinics[0] ? result.clinicGroups[0].clinics[0]?._id : null)
              }, () => {
                this.verifyUser()
                if (setGroupCallback) {
                  setGroupCallback();
                }
              })
            }


          }

          if (!disablePatientCheck) {
            let url = this.props.location.pathname.split('/');
            let checkCreateReferralUrl = false;
            if (url && url.length == 3 && url[0] == '' && (url[2] == 'personal-referral' || (url[2] == 'choose-time' && this.state.uData))) {
              checkCreateReferralUrl = true;
            }
            if (
              (result.userLevel == 1 || result.userLevel == 2) && (!result.userData.name || !result.email || !result.phone || !result.street || !result.city || !result.zip)
              || (result.userLevel == 20 && checkCreateReferralUrl) && (!result.userData.name || !result.email || !result.phone || !result.street || !result.city || !result.zip)
            ) {
              this.setState({
                patientAccountModal: true
              })
            } else {
              this.setState({
                patientAccountModal: null
              })
            }


            if (!this.socket) {
              this.socket = socketIO(API_ENDPOINT, { /*transports: ['websocket'],*/transports: ['websocket', 'polling'], query: { token: localStorage?.getItem('authToken') } });
              this.registerSocketEvents();

              this.socket.on('call', async (data) => {
                this.setActiveVideoCallInWaitingRoom(true)
                if (data.initiatorUid == this.state.uData?._id)
                  return;

                // this.audioIncomingCall = new Audio(incomingCall);
                // this.audioIncomingCall.addEventListener('ended', () => {
                //   if (this.props.location.pathname !== '/waiting-room') {
                //     this.audioIncomingCall.currentTime = 0;
                //     this.audioIncomingCall.play();
                //   }

                // }, false);

                // if (this.props.location.pathname !== '/waiting-room') {
                //   this.audioIncomingCall.play();
                // }

                this.setState({
                  offerData: data
                }, () => {
                  if (localStorage?.getItem('rejectedCalls') && localStorage?.getItem('rejectedCalls') == data.conversation) {
                    localStorage?.removeItem('rejectedCalls')
                  }
                })
              })

              this.socket.emit('activeVideoCalls', {})
            }
          }

          // if (typeof localStorage && localStorage?.getItem('time') != 'undefined' && localStorage?.getItem('time') != null)
          //   if (!this.logoutInterval) {

          //     this.logoutInterval = setTimeout(() => {
          //       if (Math.floor(Date.now() / 1000) - localStorage?.getItem('time') > 59 * 20) {
          //         this.signOut()
          //         this.enableLogoutMessage()

          //       }


          //     }, LOGOUT_TIMEOUT);

          //   }

          let doctorPermission;
          if (result && result.clinicModules && result.clinicModules.length) {
            for (let i = 0; i < result.clinicModules.length; i++) {
              if (result.clinicModules[i].name == "Vårdutövare") {
                doctorPermission = result.clinicModules[i]?._id;
              }
            }

          }

          let isDoctor = false;
          if (result.groupPermissionsUser && result.groupPermissionsUser[this.state.selectedGroup] && result.groupPermissionsUser[this.state.selectedGroup][this.state.selectedClinic] && result.groupPermissionsUser[this.state.selectedGroup][this.state.selectedClinic].indexOf(doctorPermission) != -1) {
            isDoctor = true;
          }
          let treeIdExists = false;
          if ((result.userLevel == 20 && isDoctor) || (typeof window != 'undefined' && localStorage?.getItem('profile') == 'patient')) {
            treeIdExists = true;
          }

          let treeId = 'none';
          if (treeIdExists) {
            treeId = localStorage?.getItem('profile');
          }

          fetch(API_ENDPOINT + '/users/modules/tree/' + treeId, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${localStorage?.getItem('authToken')}`
            },
          }).then(res => {
            return res.json()
          }).then((result) => {
            if (!localStorage?.getItem('authToken')) {
              this.setState({
                userVerificationInProgress: false
              })
              return;
            }

            if (redirectLink) {
              redirectLink()
            } else {
              if (result[0] && this.props.location.pathname == '/') {
                if (result[0].modules && result[0].modules[0] && result[0].modules[0].link) {
                  this.props.history.push(result[0].modules[0].link);
                } else {

                  // this.props.history.push(result[0].link);
                  if (this.state.uData && !this.state.uData.searchPatientsAllowed && result[0].link == '/patients/search' && result[1].link) {
                    if (result[1].link == '/patients/search-enterprise' && result[2].link) {
                      this.props.history.push(result[2].link);
                    } else {
                      this.props.history.push(result[1].link);
                    }


                  } else {
                    this.props.history.push(result[0].link);
                  }
                }
              };
            }


            this.generateModulesMeta(result);
            this.setState({
              modulesTree: result
            })
          });

          fetch(API_ENDPOINT + '/users/modules/available-paths', {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${localStorage?.getItem('authToken')}`
            },
          }).then(res => {
            return res.json()
          }).then((result) => {
            if (!localStorage?.getItem('authToken')) {
              this.setState({
                userVerificationInProgress: false
              })
              return;
            }

            this.setState({
              availablePaths: result
            })
          });


        } else {
          this.setState({
            uData: null
          }, () => {
            this.forceUpdate()
          })
        }

        fetch(API_ENDPOINT + '/notification/last', {
          method: 'POST',
          headers: {
            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage?.getItem('authToken')}` : null,
            'content-type': 'application/json'
          },

        }).then(res => res.json()).then((result) => {
          if (result.seen && typeof window != 'undefined') {
            localStorage?.setItem('notif', result?._id);
            this.setState({
              newNotif: false
            })
          } else {
            this.setState({
              newNotif: true
            })
          }

        })
      })
    } else {
      let profile = localStorage?.getItem('profile')
      let groupId = null;
      let clinicId = null;
      if (this.state.selectedGroup) {
        groupId = this.state.selectedGroup
      }
      if (this.state.selectedClinic) {
        clinicId = this.state.selectedClinic;
      }
      let lastGroup = localStorage?.getItem('lastGroup');

      const lastLogin2fa = localStorage?.getItem?.('2faLogin') && localStorage?.getItem?.('2faLogin') === 'true';

      fetch(API_ENDPOINT + (lastGroup ? `/users/verify?profile=${profile}&groupId=${groupId}&lastGroup=${lastGroup}&clinicId=${clinicId}${lastLogin2fa ? '&lastLogin2fa=true' : ''}` : `/users/verify?profile=${profile}&groupId=${groupId}&clinicId=${clinicId}${lastLogin2fa ? '&lastLogin2fa=true' : ''}`), {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage?.getItem('authToken')}`
        },
      }).then(res => {
        return res.json()
      }).then((result) => {
        if (result && result.verificationCodeNeeded) {
          let email = ''
          if (result.email) {
            // email = result.email.split('@')[0] + result.email.split('@')[1].replace(/[^0-9,.]/g, "*")
            email = result.email
          }
          this.props.history.push({
            pathname: '/verification-code',
            state: {
              email: email
            }
          })
          return;
        }
        this.setState({
          userVerificationInProgress: false
        })
        if (!result.error) {
          if (!localStorage?.getItem('authToken')) {
            this.setState({
              userVerificationInProgress: false
            })
            return;
          }

          if (localStorage?.bankidlogin) {
            localStorage?.setItem('profile', 'doctor')
            localStorage?.setItem('userLevel', 20);
            localStorage?.removeItem('bankidlogin')
            // if (result.havePersonalReferrals) {
            //   localStorage?.setItem('profile', 'patient')
            //   localStorage?.removeItem('bankidlogin')
            // } else {
            //   localStorage?.setItem('profile', 'doctor')
            //   localStorage?.removeItem('bankidlogin')
            // }
          }
          if (localStorage?.loginLandingPage) {
            // if (result.havePersonalReferrals) {
            //   localStorage?.setItem('profile', 'patient')
            //   localStorage?.removeItem('loginLandingPage')
            // } else {
            //   localStorage?.setItem('profile', 'doctor')
            //   localStorage?.removeItem('loginLandingPage')
            // }
            localStorage?.setItem('profile', 'patient')
            localStorage?.setItem('userLevel', 2);
            localStorage?.removeItem('loginLandingPage')

          }



          if (typeof window !== 'undefined')
            window.lang = result.langKey;
          window._lang = result.langKey;

          this.setState({
            uData: result,
            lang: this.state.secondaryLanguageDisabled ? 'en' : result.langKey,
            hasTakeCare: result.hasTakeCare,
            hasSemble: result.hasSemble,
            dateFormat: result.langKey == 'se' || this.state.secondaryLanguageDisabled ? 'YYYY-MM-DD' : 'MM-DD-YYYY',
            dateTimeFormat: result.langKey == 'se' || this.state.secondaryLanguageDisabled ? 'YYYY-MM-DD HH:mm' : 'MM-DD-YYYY HH:mm',

          }, () => {
            this.checkPrivacyPolicyModal()
            this.checkConsultationIndicators()
            this.checkSavedFormsMessage()
            this.forceUpdate()
            if (callback) {
              callback();
            }
          })
          if (result.clinicGroups && result.clinicGroups.length && !this.state.selectedGroup) {
            if (localStorage?.getItem('group')) {
              let groupIdx = -1;
              for (let i = 0; i < result.clinicGroups.length; i++) {
                if (result.clinicGroups[i]?._id == localStorage?.getItem('group')) {
                  groupIdx = i;
                  break;
                }
              }
              if (groupIdx != -1) {

                let clinicIdx = -1;
                if (localStorage?.getItem('clinic')) {
                  for (let i = 0; i < result.clinicGroups[groupIdx]?.clinics?.length; i++) {
                    if (result.clinicGroups[groupIdx].clinics[i]?._id == localStorage?.getItem('clinic')) {
                      clinicIdx = i;
                      break;
                    }
                  }
                }
                this.setState({
                  selectedGroup: result.clinicGroups[groupIdx]?._id,
                  selectedClinic: clinicIdx != -1 ? (result.clinicGroups[groupIdx].clinics && result.clinicGroups[groupIdx].clinics[clinicIdx] ? result.clinicGroups[groupIdx].clinics[clinicIdx]?._id : null) : (result.clinicGroups[groupIdx].clinics && result.clinicGroups[groupIdx].clinics[0] ? result.clinicGroups[groupIdx].clinics[0]?._id : null)
                }, () => {
                  this.verifyUser()
                  if (setGroupCallback) {
                    setGroupCallback();
                  }
                })
              } else {
                let clinicIdx = -1;
                if (localStorage?.getItem('clinic')) {
                  for (let i = 0; i < result.clinicGroups[0].clinics.length; i++) {
                    if (result.clinicGroups[0].clinics[i]?._id == localStorage?.getItem('clinic')) {
                      clinicIdx = i;
                      break;
                    }
                  }
                }

                this.setState({
                  selectedGroup: result.clinicGroups[0]?._id,
                  selectedClinic: clinicIdx != -1 ? (result.clinicGroups[0].clinics && result.clinicGroups[0].clinics[clinicIdx] ? result.clinicGroups[0].clinics[clinicIdx]?._id : null) : (result.clinicGroups[0].clinics && result.clinicGroups[0].clinics[0] ? result.clinicGroups[0].clinics[0]?._id : null)
                }, () => {
                  this.verifyUser()
                  if (setGroupCallback) {
                    setGroupCallback();
                  }
                })
              }

            } else {
              let clinicIdx = -1;
              if (localStorage?.getItem('clinic')) {
                for (let i = 0; i < result.clinicGroups[0].clinics.length; i++) {
                  if (result.clinicGroups[0].clinics[i]?._id == localStorage?.getItem('clinic')) {
                    clinicIdx = i;
                    break;
                  }
                }
              }

              this.setState({
                selectedGroup: result.clinicGroups[0]?._id,
                selectedClinic: clinicIdx != -1 ? (result.clinicGroups[0].clinics && result.clinicGroups[0].clinics[clinicIdx] ? result.clinicGroups[0].clinics[clinicIdx]?._id : null) : (result.clinicGroups[0].clinics && result.clinicGroups[0].clinics[0] ? result.clinicGroups[0].clinics[0]?._id : null)
              }, () => {
                this.verifyUser()
                if (setGroupCallback) {
                  setGroupCallback();
                }
              })
            }


          }

          if (!disablePatientCheck) {
            let url = this.props.location.pathname.split('/');
            let checkCreateReferralUrl = false;
            if (url && url.length == 3 && url[0] == '' && (url[2] == 'personal-referral' || (url[2] == 'choose-time' && this.state.uData))) {
              checkCreateReferralUrl = true;
            }
            if ((result.userLevel == 1 || result.userLevel == 2) && (!result.userData.name || !result.email || !result.phone || !result.street || !result.city || !result.zip)
              || (result.userLevel == 20 && checkCreateReferralUrl) && (!result.userData.name || !result.email || !result.phone || !result.street || !result.city || !result.zip)) {
              this.setState({
                patientAccountModal: true
              })
            } else {
              this.setState({
                patientAccountModal: null
              })
            }


            if (!this.socket) {
              this.socket = socketIO(API_ENDPOINT, { /*transports: ['websocket'],*/transports: ['websocket', 'polling'], query: { token: localStorage?.getItem('authToken') } });
              this.registerSocketEvents();

              this.socket.on('call', async (data) => {
                this.setActiveVideoCallInWaitingRoom(true)
                // console.log(data);

                if (data.initiatorUid == this.state.uData?._id)
                  return;

                // this.audioIncomingCall = new Audio(incomingCall);
                // this.audioIncomingCall.addEventListener('ended', () => {
                //   if (this.props.location.pathname !== '/waiting-room') {
                //     this.audioIncomingCall.currentTime = 0;
                //     this.audioIncomingCall.play();
                //   }

                // }, false);

                // if (this.props.location.pathname !== '/waiting-room') {
                //   this.audioIncomingCall.play();
                // }


                this.setState({
                  offerData: data
                }, () => {
                  if (localStorage?.getItem('rejectedCalls') && localStorage?.getItem('rejectedCalls') == data.conversation) {
                    localStorage?.removeItem('rejectedCalls')
                  }

                })
              })

              this.socket.emit('activeVideoCalls', {})
            }
          }

          // if (typeof localStorage && localStorage?.getItem('time') != 'undefined' && localStorage?.getItem('time') != null)
          //   if (!this.logoutInterval) {
          //     this.logoutInterval = setTimeout(() => {
          //       if (Math.floor(Date.now() / 1000) - localStorage?.getItem('time') > 59 * 20) {
          //         this.signOut()
          //         this.enableLogoutMessage()

          //       }
          //     }, LOGOUT_TIMEOUT);

          //   }

          let doctorPermission;
          if (result && result.clinicModules && result.clinicModules.length) {
            for (let i = 0; i < result.clinicModules.length; i++) {
              if (result.clinicModules[i].name == "Vårdutövare") {
                doctorPermission = result.clinicModules[i]?._id;
              }
            }

          }

          let isDoctor = false;
          if (result.groupPermissionsUser && result.groupPermissionsUser[this.state.selectedGroup] && result.groupPermissionsUser[this.state.selectedGroup][this.state.selectedClinic] && result.groupPermissionsUser[this.state.selectedGroup][this.state.selectedClinic].indexOf(doctorPermission) != -1) {
            isDoctor = true;
          }
          let treeIdExists = false;
          if ((result.userLevel == 20 && isDoctor) || (typeof window != 'undefined' && localStorage?.getItem('profile') == 'patient')) {
            treeIdExists = true;
          }
          let treeId = 'none';

          if (treeIdExists) {
            treeId = localStorage?.getItem('profile');
          }
          fetch(API_ENDPOINT + '/users/modules/tree/' + treeId, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${localStorage?.getItem('authToken')}`
            },
          }).then(res => {
            return res.json()
          }).then((result) => {
            if (!localStorage?.getItem('authToken')) {
              this.setState({
                userVerificationInProgress: false
              })
              return;
            }

            if (redirectLink) {
              redirectLink()
            } else {
              if (result[0] && this.props.location.pathname == '/') {
                if (result[0].modules && result[0].modules[0]) {
                  this.props.history.push(result[0].modules[0].link);
                } else {
                  if (this.state.uData && !this.state.uData.searchPatientsAllowed && result[0].link == '/patients/search' && result[1].link) {
                    // this.props.history.push(result[1].link);
                    if (result[1].link == '/patients/search-enterprise' && result[2].link) {
                      this.props.history.push(result[2].link);
                    } else {
                      this.props.history.push(result[1].link);
                    }
                  } else {
                    this.props.history.push(result[0].link);
                  }

                }
              };
            }


            this.generateModulesMeta(result);
            this.setState({
              modulesTree: result
            })
          });

          fetch(API_ENDPOINT + '/users/modules/available-paths', {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${localStorage?.getItem('authToken')}`
            },
          }).then(res => {
            return res.json()
          }).then((result) => {

            if (!localStorage?.getItem('authToken')) {
              this.setState({
                userVerificationInProgress: false
              })
              return;
            }


            this.setState({
              availablePaths: result
            })
          });


        } else {
          this.setState({
            uData: null
          }, () => {
            this.forceUpdate()
          })
        }


        fetch(API_ENDPOINT + '/notification/last', {
          method: 'POST',
          headers: {
            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage?.getItem('authToken')}` : null,
            'content-type': 'application/json'
          },

        }).then(res => res.json()).then((result) => {
          // console.log(result)
          if (result.seen && typeof window != 'undefined') {
            localStorage?.setItem('notif', result?._id);
            this.setState({
              newNotif: false
            })
          } else {
            this.setState({
              newNotif: true
            })
          }

        })
      })

    }
  }

  componentWillUnmount() {
    if (this.logoutIntervalV2) {
      clearInterval(this.logoutIntervalV2)
    }


    try {
      let { visibilityChange } = this.getVisibilityAPI();

      window.removeEventListener(visibilityChange, this.handleVisibilityChange);
    } catch (e) {

    }


  }

  registerLog = (message) => {
    fetch(API_ENDPOINT + '/data/logs/register', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage?.getItem('authToken')}`
      },
      body: JSON.stringify({
        pathname: this.props.location.pathname,
        search: this.props.location.search,
        message: message,
        timestamp: Math.floor(new Date().getTime() / 1000)
      })
    })
  }

  checkToken = () => {
    if (typeof window != 'undefined' && window.stopVerifyFun) {
      // console.log(window.stopVerifyFun);
      return
    }
    let profile = localStorage?.getItem('profile')
    let groupId = null;
    if (this.state.selectedGroup) {
      groupId = this.state.selectedGroup
    }
    let clinicId = null;
    if (this.state.selectedClinic) {
      clinicId = this.state.selectedGroup
    }
    fetch(API_ENDPOINT + `/users/verify?profile=${profile}&groupId=${groupId}&clinicId=${clinicId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage?.getItem('authToken')}`
      },
    }).then(res => {
      return res.json()
    }).then((result) => {
      if (result && result.verificationCodeNeeded) {
        let email = ''
        if (result.email) {
          // email = result.email.split('@')[0] + result.email.split('@')[1].replace(/[^0-9,.]/g, "*")
          email = result.email
        }
        this.props.history.push({
          pathname: '/verification-code',
          state: {
            email: email
          }
        })
        return;
      }
      if (this.state.userVerificationInProgress)
        this.setState({
          userVerificationInProgress: false
        })
      if (result.error) {
        if (this.state.uData) {
          this.signOut()
        } else {
          // this.setState({
          //   uData: null
          // }, () => {
          //   setTimeout(() => {

          //     this.forceUpdate();

          //   }, 200);
          // })
        }

      }
    })

  }

  triggerPushNotification = async () => {
    try {
      if ('serviceWorker' in navigator) {
        const register = await navigator.serviceWorker.register('/serviceWorker.js', {
          scope: '/'
        });

        const subscription = await register.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: urlBase64ToUint8Array(publicVapidKey),
        });
        //console.log(subscription);
        await fetch(API_ENDPOINT + '/subscribe', {
          method: 'POST',
          body: JSON.stringify(subscription),
          headers: {
            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage?.getItem('authToken')}` : null,
            'Content-Type': 'application/json',
          },
        });
      } else {
        console.error('Service workers are not supported in this browser');
      }
    } catch (error) {

    }

  }
  changeProfile = (profile) => {
    if (profile == null) {
      localStorage?.removeItem('profile')
    } else {
      localStorage?.setItem('profile', profile)
    }

    // this.setState({
    //   selectedProfile: profile
    // })

    this.setState({
      selectedProfile: profile
    }, () => {

      let doctorPermission;
      if (this.state.uData && this.state.uData.clinicModules && this.state.uData.clinicModules.length) {
        for (let i = 0; i < this.state.uData.clinicModules.length; i++) {
          if (this.state.uData.clinicModules[i].name == "Vårdutövare") {
            doctorPermission = this.state.uData.clinicModules[i]?._id;
          }
        }
      }

      let isDoctor = false;
      if (this.state.uData.groupPermissionsUser && this.state.uData.groupPermissionsUser[this.state.selectedGroup] && this.state.uData.groupPermissionsUser[this.state.selectedGroup][this.state.selectedClinic] && this.state.uData.groupPermissionsUser[this.state.selectedGroup][this.state.selectedClinic].indexOf(doctorPermission) != -1) {
        isDoctor = true;
      }
      let treeIdExists = false;
      if ((result.userLevel == 20 && isDoctor) || (typeof window != 'undefined' && localStorage?.getItem('profile') == 'patient')) {
        treeIdExists = true;
      }
      let treeId = 'none';

      if (treeIdExists) {
        treeId = this.state.selectedProfile;
      }

      fetch(API_ENDPOINT + '/users/modules/tree/' + treeId, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage?.getItem('authToken')}`
        },
      }).then(res => {
        return res.json()
      }).then((result) => {
        if (result[0] && this.props.location.pathname == '/') {
          if (result[0].modules && result[0].modules[0]) {
            this.props.history.push(result[0].modules[0].link);
          } else {
            if (this.state.uData && !this.state.uData.searchPatientsAllowed && result[0].link == '/patients/search' && result[1].link) {
              // this.props.history.push(result[1].link);
              if (result[1].link == '/patients/search-enterprise' && result[2].link) {
                this.props.history.push(result[2].link);
              } else {
                this.props.history.push(result[1].link);
              }
            } else {
              this.props.history.push(result[0].link);
            }
            // this.props.history.push(result[0].link);
          }
        };

        this.generateModulesMeta(result);
        this.setState({
          modulesTree: result
        })
      });

      fetch(API_ENDPOINT + '/users/modules/available-paths', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage?.getItem('authToken')}`
        },
      }).then(res => {
        return res.json()
      }).then((result) => {
        this.setState({
          availablePaths: result
        })
      });
    })
    // window.location.reload(false)

  }


  changeGroup = (group) => {
    if (group == null) {
      localStorage?.removeItem('group')
    } else {
      localStorage?.setItem('group', group)
    }

    this.setState({
      selectedGroup: group
    }, () => {
      this.verifyUser()
      let location = this.props.location
      if (location.pathname && this.state.selectedGroup) {
        let links = {};
        for (let i = 0; i < this.state.modulesTree.length; i++) {
          links[this.state.modulesTree[i].link] = this.state.modulesTree[i];
          if (this.state.modulesTree[i].modules) {
            for (let j = 0; j < this.state.modulesTree[i].modules.length; j++) {
              links[this.state.modulesTree[i].modules[j].link] = this.state.modulesTree[i].modules[j];
            }
          }
        }

        if (links[location.pathname]) {

          let selectedGroupIdx = 0;
          if (this.state.selectedGroup && this.state.uData.clinicGroups) {
            for (let i = 0; i < this.state.uData.clinicGroups.length; i++) {
              if (this.state.uData.clinicGroups[i]?._id == this.state.selectedGroup) {
                selectedGroupIdx = i;
                break;
              }
            }
          }


          let sub = links[location.pathname];

          if ((!sub.clinicEntryModule || (sub.clinicEntryModule && this.state.uData && this.state.uData.groupPermissions && this.state.uData.groupPermissions[this.state.selectedGroup] && this.state.uData.groupPermissions[this.state.selectedGroup][this.state.selectedClinic] && this.state.uData.groupPermissions[this.state.selectedGroup][this.state.selectedClinic].indexOf(sub?._id) !== -1) &&
            (!sub.clinicEntryModuleCondition ||
              (
                sub.clinicEntryModuleCondition &&
                this.state.uData &&
                this.state.uData.clinicGroups &&
                this.state.uData.clinicGroups[selectedGroupIdx] &&
                //this.props.uData.clinicGroups[selectedGroupIdx][sub.clinicEntryModuleCondition]
                this.clinicEntryModuleCondition(this.state.uData.clinicGroups[selectedGroupIdx], sub.clinicEntryModuleCondition)
              )
            )) && (!sub.uDataCondition || (sub.uDataCondition && this.state.uData && this.uDataCondition(sub.uDataCondition)))) {

          } else {
            if (this.state.modulesTree[0].modules && this.state.modulesTree[0].modules[0]) {
              this.props.history.push(this.state.modulesTree[0].modules[0].link);
            } else {
              this.props.history.push(this.state.modulesTree[0].link);
            }
          }
        }


      }
    })
    if (this.state.uData) {
      for (let i = 0; i < this.state.uData.clinicGroups.length; i++) {
        if (this.state.uData.clinicGroups[i]?._id == group) {

          let clinicIdx = -1;
          if (localStorage?.getItem('clinic')) {
            for (let j = 0; j < this.state.uData.clinicGroups[i].clinics.length; j++) {
              if (this.state.uData.clinicGroups[i].clinics[j]?._id == localStorage?.getItem('clinic')) {
                clinicIdx = j;
                break;
              }
            }
          }
          let clinicId = clinicIdx != -1 ? (this.state.uData.clinicGroups[i].clinics && this.state.uData.clinicGroups[i].clinics[clinicIdx] ? this.state.uData.clinicGroups[i].clinics[clinicIdx]?._id : null) : (this.state.uData.clinicGroups[i].clinics && this.state.uData.clinicGroups[i].clinics[0] ? this.state.uData.clinicGroups[i].clinics[0]?._id : null);

          this.setState({
            selectedClinic: clinicId ? clinicId : this.state.uData.clinicGroups[i].clinics ? this.state.uData.clinicGroups[i].clinics[0]?._id : null
          })
        }
      }
    }
  }
  changeClinic = (group) => {
    if (group == null) {
      localStorage?.removeItem('clinic')
    } else {
      localStorage?.setItem('clinic', group)
    }

    this.setState({
      selectedClinic: group
    })

  }

  getSelectedGroup = () => {
    if (this.state.selectedGroup && this.state.uData.clinicGroups) {
      for (let i = 0; i < this.state.uData.clinicGroups.length; i++) {
        if (this.state.uData.clinicGroups[i]?._id == this.state.selectedGroup) {
          return this.state.uData.clinicGroups[i];

        }
      }
    }

  }

  getSelectedClinic = () => {
    let group = this.getSelectedGroup();
    if (group && group.clinics) {
      for (let i = 0; i < group.clinics.length; i++) {
        if (group.clinics[i]?._id == this.state.selectedClinic) {
          return group.clinics[i];
        }
      }
    }

  }

  setGroupAlias = (alias) => {
    this.setState({
      groupAlias: alias
    })
  }

  hideSidebarAndNavbar = (hideSidebar, hideNavbar) => {
    this.setState({
      hideSidebar: hideSidebar,
      hideNavbar: hideNavbar
    })
  }
  removePixelTrackingIfExists = () => {
    let trackingDiv = document.getElementById('tracking');

    let children = trackingDiv?.children;

    for (let i = children.length - 1; i >= 0; i--) {
      if (children[i].tagName.toLowerCase() === 'iframe') {
        trackingDiv.removeChild(children[i]);
      }
    }

  }
  patientCreatedReferral = () => {
    const tsNow = Math.floor(new Date().getTime() / 1000);
    if (this.patientCreatedReferralTs && this.patientCreatedReferralTs + 15 > tsNow) {
      return;
    }

    this.patientCreatedReferralTs = tsNow;

    this.removePixelTrackingIfExists();

    if (typeof window !== 'undefined') {
      const groupAlias = localStorage?.groupAlias;

      if (groupAlias) {
        fetch(API_ENDPOINT + `/data/clinic-groups/tracking-pixel/${groupAlias}`, {
          method: 'GET',
          headers: {
            'content-type': 'application/json'
          }
        }).then(res => res.json()).then((result) => {
          if (result?.trackingPixel) {
            let trackingPixel = result.trackingPixel;
            trackingPixel = trackingPixel?.trim()?.replace(/\\/g, '')?.replace(/\n/g, '')

            if (trackingPixel) {
              let trackingDiv = document.getElementById('tracking');
              trackingDiv.innerHTML += trackingPixel;
            }

          }
        })
      }

    }

  }

  signOut = (disabledRedirect = false) => {


    try {
      // if (this.audioIncomingCall) {
      //   this.audioIncomingCall.pause();
      // }
      if (this.state.offerData) {
        this.setState({
          offerData: null
        })
      }

    } catch (e) {
      console.log(e);
    }

    fetch(API_ENDPOINT + '/users/logout', {
      method: 'POST',
      headers: {
        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage?.getItem('authToken')} ` : null,
        'content-type': 'application/json'
      },
      body: JSON.stringify({
        token: localStorage?.getItem('authToken')
      })
    }).then(res => res.json()).then((result) => {


    })
    this.registerClinicGroupLogs({ type: 'logout' })


    // this.setState({ sidebarWide: false })
    let alias = localStorage?.groupAlias;
    let logoutLanding = localStorage?.logoutLandingPage;
    let userLevel;
    if (this.state.uData && this.state.uData.userLevel) {
      userLevel = this.state.uData.userLevel;
    } else {
      userLevel = localStorage?.getItem('userLevel');
    }
    if (localStorage?.getItem('loginGroupAlias')) {
      alias = localStorage?.getItem('loginGroupAlias');
    }
    let profile = localStorage?.getItem?.('profile');
    localStorage?.removeItem('authToken');
    if (!disabledRedirect)
      localStorage?.removeItem('groupAlias');
    localStorage?.removeItem('group');
    localStorage?.removeItem('time')
    localStorage?.removeItem('doctorList')
    localStorage?.removeItem('profile');
    localStorage?.removeItem('bankidlogin');
    localStorage?.removeItem('loginLandingPage');
    localStorage?.removeItem('logoutLandingPage');
    localStorage?.removeItem('loginGroupAlias');
    localStorage?.removeItem('userLevel');
    localStorage?.removeItem('lastGroup')
    localStorage?.removeItem('disableInitialLanding')
    localStorage?.removeItem('checkedGroupsSavedForms')
    localStorage?.removeItem?.('userData');
    localStorage?.removeItem?.('checkedPatientSavedForms');
    localStorage?.removeItem?.('2faLogin');
    localStorage?.removeItem?.('hidePatientModal');


    if (this.socket && !this.isSocketForGuestVideoCall) {
      this.socket.disconnect();
      this.socket = null;
    }
    this.setState({ uData: null, selectedGroup: null, selectedClinic: null }, () => {
      // if (alias && !disabledRedirect) {
      //   this.props.history.push(`/ ${ alias } `);
      //   localStorage?.removeItem('logoutLandingPage');
      // }

      // let matchedRoute = null;


      // for (let i = 0; i < routes.length; i++) {
      //   let route = routes[i];

      //   const match = matchPath(this.props.location.pathname, route);
      //   if (match && match.isExact) {
      //     matchedRoute = route;
      //     break;
      //   }
      // }

      // if (matchedRoute && matchedRoute.path != '/:alias') {
      if (!disabledRedirect) {
        if (alias && alias !== 'noGroupAlias' && logoutLanding && userLevel && userLevel == 20 && profile == 'doctor' && this.props.location.pathname.indexOf('video-call') == -1) {
          this.props.history.push(`/ ${alias} /login`);
        } else if (alias && alias !== 'noGroupAlias' && logoutLanding && userLevel && (userLevel == 1 || userLevel == 2 || userLevel == 20) && profile == 'patient') {
          this.props.history.push(`/${alias}`);
        } else {
          this.props.history.push(`/login`);
        }
      }

      // }


    })
    // if (this.logoutInterval) {
    //   clearTimeout(this.logoutInterval)
    // }

  }

  resetLogoutInterval = () => {
    if (this.state.uData) {
      if (Math.floor(Date.now() / 1000) > localStorage?.getItem('time') && this.state.uData) {
        localStorage?.setItem('time', Math.floor(Date.now() / 1000))
      }

    }
  }

  removeSavedFormsModalMessage = () => {
    this.setState({ hasSavedForms: null }, () => {
      try {
        if (typeof window !== 'undefined' && this.state.uData?._id) {
          let arr = [];

          if (localStorage?.getItem?.('checkedPatientSavedForms')) {
            try {
              const _arr = JSON.parse(localStorage?.getItem?.('checkedPatientSavedForms'));
              if (_arr && Array.isArray(_arr)) arr = _arr;
            } catch (err) { }
          }

          if (!arr.includes(String(this.state.uData?._id))) {
            arr.push(String(this.state.uData?._id))
          }

          localStorage?.setItem?.('checkedPatientSavedForms', JSON.stringify(arr));
        }
      } catch (err) {
        console.log('--- removeSavedFormsModalMessage err', err);
      }
    })
  }

  checkIfPatientHasSavedForms = () => {
    this.setState({ hasSavedForms: null }, async () => {
      const response = await fetch(API_ENDPOINT + `/users/saved-forms/check/${this.state.uData?._id}`, {
        headers: { 'Content-Type': 'application/json' }
      });
      if (!response || !response.ok) return;

      const result = await response.json();
      if (!result || result.error) return;

      let alreadyChecked = false;

      if (typeof window !== 'undefined' && localStorage?.getItem?.('checkedPatientSavedForms')) {
        try {
          const arr = JSON.parse(localStorage?.getItem?.('checkedPatientSavedForms'));
          if (arr && Array.isArray(arr) && this.state.uData?._id && arr.includes(String(this.state.uData?._id))) alreadyChecked = true;
        } catch (err) {
          console.log('--- checkIfPatientHasSavedForms err: ', err);
        }
      }

      if (alreadyChecked) return;

      this.setState({ hasSavedForms: result });
    });
  }

  componentDidUpdate(prevProps, prevState) {

    if ((!prevState?.selectedGroup && this.state.selectedGroup) || (prevState?.selectedGroup !== this.state.selectedGroup) || (!prevState?.uData && this.state.uData) || (prevState?.uData !== this.state.uData)) this.checkRegistrationRequests();

    if ((!prevState?.selectedGroup && this.state.selectedGroup) || (prevState?.selectedGroup !== this.state.selectedGroup) || (!prevState?.uData && this.state.uData) || (prevState?.uData !== this.state.uData) || (!prevState?.selectedClinic && this.state.selectedClinic) || (prevState?.selectedClinic !== this.state.selectedClinic)) this.checkEConnectCount();

    if (prevState.lang != this.state.lang) {
      window._lang = this.state.lang;
    }

    if (((!prevState?.uData?._id && this.state.uData?._id) || (String(prevState?.uData?._id) !== String(this.state.uData?._id))) && this.state.uData && this.state.uData._id && this.state.uData.userLevel && ([1, 2].includes(this.state.uData.userLevel) || (this.state.uData.userLevel === 20 && typeof window !== 'undefined' && localStorage?.getItem?.('profile') === 'patient'))) {
      this.checkIfPatientHasSavedForms();
    }

    if (prevState.uData != this.state.uData && !prevState.uData) {
      this.resetLogoutInterval();
    }

    if ((!prevState.uData && this.state.uData && this.state.uData.favicon) || (prevState.uData && this.state.uData && prevState.uData.favicon != this.state.uData.favicon) || (prevState.uData && !this.state.uData)) {
      let favicon = document.getElementById("favicon");
      if (this.state.uData && this.state.uData.favicon)
        favicon.href = this.state.uData.favicon;
      else
        favicon.href = '/favicon.ico';
    }


    let prevUrl = prevProps.location.pathname.split('/');
    let url = this.props.location.pathname.split('/');
    let checkCreateReferralPrevUrl = false;
    let checkCreateReferralUrl = false;

    if (url && url.length == 3 && url[0] == '' && (url[2] == 'personal-referral' || (url[2] == 'choose-time' && this.state.uData))) {
      checkCreateReferralUrl = true;
    }
    if (prevUrl && prevUrl.length == 3 && prevUrl[0] == '' && (prevUrl[2] == 'personal-referral' || (prevUrl[2] == 'choose-time' && this.state.uData))) {
      checkCreateReferralPrevUrl = true;
    }
    if (checkCreateReferralPrevUrl && !checkCreateReferralUrl) {
      this.verifyUser()
    } else if (!checkCreateReferralPrevUrl && checkCreateReferralUrl) {
      this.verifyUser()
    }

    if (prevState.uData != this.state.uData) {
      if (typeof window != 'undefined' && localStorage?.getItem?.('profile') != 'patient' && this.state.uData && this.state.uData.userLevel == 20 && (!this.state.uData.groupPermissions || (this.state.uData.groupPermissions && Object.keys(this.state.uData.groupPermissions).length === 0))) {
        this.setState({ _withoutGroupPermissions: true })
      }
    }

    if (!prevState.uData && prevState.uData != this.state.uData && (this.state.uData.userLevel == 1 || this.state.uData.userLevel == 2 || (typeof window != 'undefined' && localStorage?.getItem?.('profile') == 'patient'))) {
      this.setupFaceAPI();
    }

    if (prevState.lang !== this.state.lang || prevState.countryTwoLetter !== this.state.countryTwoLetter) {
      this.setCulture()
    }


  }

  checkPrivacyPolicyModal = async () => {
    let privacyPolicyModal = false;
    if (this.state.uData && this.state.uData.userLevel === 1 || this.state.uData.userLevel === 2 || (this.state.uData.userLevel === 20 && typeof window != 'undefined' && localStorage?.getItem?.('profile') === 'patient')) {
      if (typeof window != 'undefined' && localStorage?.groupAlias && !this.state.patientAccountModal) {
        let info = await fetch(API_ENDPOINT + "/data/clinic-group/info", {
          method: "POST",
          headers: {
            Authorization:
              typeof localStorage !== "undefined"
                ? `Bearer ${localStorage?.getItem("authToken")}`
                : null,
            "content-type": "application/json",
          },
          body: JSON.stringify({ alias: localStorage?.groupAlias }),
        })
        if (info) {
          let result = await info.json()

          if (result && result._id) {
            if (!this.state.uData.privacyPolicyModal || (this.state.uData.privacyPolicyModal && !this.state.uData.privacyPolicyModal[result._id])) {
              privacyPolicyModal = true;
            }
          }

        }

      }
    }

    if (privacyPolicyModal != this.state.privacyPolicyModal) {
      this.setState({ privacyPolicyModal })
    }
  }

  setupFaceAPI = async () => {
    let start = new Date().getTime()
    await faceapi.nets.ssdMobilenetv1.load(modelPath);
    await faceapi.nets.ageGenderNet.load(modelPath);
    await faceapi.nets.faceLandmark68Net.load(modelPath);
    await faceapi.nets.faceRecognitionNet.load(modelPath);
    await faceapi.nets.faceExpressionNet.load(modelPath);
    optionsSSDMobileNet = new faceapi.SsdMobilenetv1Options({ minConfidence: minScore, maxResults });
    let val = new Date().getTime() - start
    console.log(val + ' ms')

  }

  answer = () => {
    if (this.registerLog) {
      this.registerLog(`Answer button clicked`)
    }
    const uid = this.state.uData?._id;
    let bundleId;
    const conversationId = this.state.offerData?.conversation;
    const initiator = this.state.offerData?.initiator;
    if (conversationId) {
      fetch(API_ENDPOINT + '/clinic-groups/check/bundleId', {
        method: 'POST',
        headers: {
          'content-type': 'application/json'
        },
        body: JSON.stringify({ cid: conversationId })
      }).then(res => res.json()).then((result) => {
        if (result.bundleId) {
          bundleId = result.bundleId;
          if (bundleId && conversationId && initiator && uid) {
            if (bundleId) {
              try {
                window.location.replace(`${bundleId}://?conversation=${conversationId}&initiator=${initiator}&uid=${uid}`);
              } catch (err) { console.log(err) }
            }
          }
        }
      });
    }




    // if (this.audioIncomingCall) {
    //   this.audioIncomingCall.pause();
    //   //this.audioIncomingCall = null;
    // }

    //this.setState({ videoCallUrl: `/video-call/${this.state.offerData.conversation}?initiator=${this.state.offerData.initiator}`, videoCallMin: false });
    var strWindowFeatures = `height=${window.innerHeight - 1},width=${window.innerWidth - 1},resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes`;
    this.videoCallWindow = window.open(`/video-call/${this.state.offerData.conversation}?initiator=${this.state.offerData.initiator}`, "_blank", strWindowFeatures);
    this.videoCallWindow.addEventListener('message',
      (e) => {
        if (e.data == 'end-video-call') {
          this.videoCallWindow.close();
        }

        /*if (e.data == 'toggle-video-call') {
          this.setState({
            videoCallMin: !this.state.videoCallMin,
            videoCallX: !this.state.videoCallMin ? typeof window != 'undefined' ? window.innerWidth - 320 : 0 : 0,
            videoCallY: !this.state.videoCallMin ? typeof window != 'undefined' ? 20 : 0 : 0,
  
          })
        }*/

      }

      , false)

    localStorage?.setItem('rejectedCalls', this.state.offerData.conversation)
    this.setState({
      offerData: null
    })
  }

  pauseAudioIncommingCall = () => {
    // if (this.audioIncomingCall) {
    //   this.audioIncomingCall.pause();
    // }
  }

  endCall = () => {
    // if (this.audioIncomingCall) {
    //   this.audioIncomingCall.pause();
    //   //this.audioIncomingCall = null;
    // }


    this.setState({
      offerData: null
    })
  }


  initSocketForVideoRoom = (roomId, callback) => {
    this.isSocketForGuestVideoCall = true;
    this.socket = socketIO(API_ENDPOINT, { /*transports: ['websocket'],*/transports: ['websocket', 'polling'], query: { roomId: roomId } });
    this.registerSocketEvents();

    callback();
  }


  registerSocketEvents = () => {
    this.socket.on('endCall', async (data) => {
      if (localStorage?.getItem('rejectedCalls') && localStorage?.getItem('rejectedCalls') == data.conversation) {
        localStorage?.removeItem('rejectedCalls')
      }
      this.setActiveVideoCallInWaitingRoom(false)
    });
    this.socket.on('call', async (data) => {
      this.socket.emit('activeVideoCalls', {})
      this.setActiveVideoCallInWaitingRoom(true)
    })
    this.socket.on('connect', () => {
      this.setState({
        socketId: this.socket.id
      })
    });

    this.socket.on('reconnect', () => {
      //alert('reconnect')
      this.socket.emit('clientReconnect', { socketId: this.state.socketId });
      this.socket.emit('activeVideoCalls', {})

      this.setState({
        socketId: this.socket.id
      })
    })
    this.socket.on('newNotification', () => {
      fetch(API_ENDPOINT + '/notification/last', {
        method: 'POST',
        headers: {
          'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage?.getItem('authToken')}` : null,
          'content-type': 'application/json'
        },

      }).then(res => res.json()).then((result) => {

        if (result) {

          if (localStorage?.getItem('notif') != result?._id) {
            this.verifyUser()
            this.setState({
              newNotif: true
            })

          } else {
            this.setState({
              newNotif: false
            })
          }
        }

      })

    });
    this.socket.on('checkConusltationIndicators', () => {
      this.setState({ updateConsultationPageItems: Math.floor(new Date().getTime() / 1000) })
      this.checkConsultationIndicators()
    });


    this.socket.on('deletionRequestRefresh', () => {
      this.deletionRequestRefresh()
      this.setState({ deletionRequestRefresh: Math.floor(new Date().getTime()) })
    });

    this.socket.on('hostEndedCall', (data) => {
      if (data.conversation && this.state.offerData && String(data.conversation) === this.state.offerData.conversation && data.members && this.state.uData && data.members.findIndex(member => String(member) === String(this.state.uData?._id)) !== -1) {
        this.endCall()
      }
      this.setActiveVideoCallInWaitingRoom(false)
    });

    this.socket.on('activeVideoCalls', (data) => {
      let foundVideoCall = false;
      let rejectedCalls = typeof window !== 'undefined' && localStorage?.rejectedCalls ? JSON.parse(JSON.stringify(localStorage?.rejectedCalls)) : ''
      for (var key in data.myVideoCallsDisconnected) {
        if (rejectedCalls.indexOf(key) === -1 && data.myVideoCallsDisconnected[key] && data.myVideoCallsDisconnected[key].conversation && this.state.uData && String(data.myVideoCallsDisconnected[key].initiatorUid) !== String(this.state.uData?._id)) {
          this.setState({
            offerData: { ...data.myVideoCallsDisconnected[key] }
          })
          this.setActiveVideoCallInWaitingRoom(true)

          foundVideoCall = true;
          break;
        } else if (data.myVideoCallsDisconnected[key] && data.myVideoCallsDisconnected[key].conversation && this.state.uData && String(data.myVideoCallsDisconnected[key].initiatorUid) !== String(this.state.uData?._id)) {
          // if there is call in rejectedCalls continue wiht blinking in sidebar

          this.setActiveVideoCallInWaitingRoom(true)

          foundVideoCall = true;
        }
      }

      if (!foundVideoCall) {
        this.setState({
          offerData: null
        })
        this.setActiveVideoCallInWaitingRoom(false)
      }



    });

    this.socket.on('checkImportantInformation', () => {
      fetch(API_ENDPOINT + '/important-information/get/unseen', {
        method: 'GET',
        headers: {
          'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage?.getItem('authToken')}` : null,
          'content-type': 'application/json'
        },

      }).then(res => res.json()).then((result) => {
        if (result && result.importantInformation) {
          let uData = this.state.uData;
          uData.importantInformation = result.importantInformation;
          this.setState({ uData })
        }
      })

    });


    // this.socket.emit('activeVideoCalls', {})

  }
  deletionRequestRefresh = () => {
    if (this.state.selectedGroup && this.state.selectedClinic) {
      fetch(API_ENDPOINT + '/data/deletion-requests/check', {
        method: 'POST',
        headers: {
          'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage?.getItem('authToken')}` : null,
          'content-type': 'application/json'
        },
        body: JSON.stringify({
          group: this.state.selectedGroup,
          clinic: this.state.selectedClinic
        })

      }).then(res => res.json()).then((result) => {
        if (result) {
          let deletionRequests = result.deletionRequests ? result.deletionRequests : 0;
          let uData = this.state.uData;
          uData.deletionRequests = deletionRequests;
          this.setState({ uData })
        }

      })
    }
  }

  checkVisitedGroups = () => {
    if (typeof window != 'undefined') {
      fetch(API_ENDPOINT + '/users/users/clinics/update/' + localStorage?.groupAlias, {
        method: 'GET',
        headers: {
          'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage?.getItem('authToken')}` : null,
          'content-type': 'application/json'
        },
      }).then(res => res.json()).then((result) => {
        if (result && result.alias) {
          let uData = this.state.uData;
          uData.lastVisitedGroups = result;
          this.setState({ uData })
        }
      });
    }

  }


  updateChat = (chat) => {
    this.setState({ chat })
  }
  registerClinicGroupLogs = (obj, callback) => {
    let profile = null;
    let groupId = null;
    let groupAlias = null;
    if (typeof window != 'undefined' && localStorage?.getItem?.('profile')) {
      profile = localStorage?.getItem?.('profile');
    }
    if (typeof window != 'undefined' && localStorage?.groupAlias) {
      groupAlias = localStorage?.groupAlias;
    }
    if (this.state.selectedGroup) {
      groupId = this.state.selectedGroup;
    }
    if (!obj) {
      obj = {}
    }
    if (groupAlias) {
      obj.groupAlias = groupAlias;
    }
    if (groupId) {
      obj.groupId = groupId;
    }
    obj.profile = profile;

    fetch(API_ENDPOINT + '/register/clinic-group/logs', {
      method: 'POST',
      headers: {
        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage?.getItem('authToken')}` : null,
        'content-type': 'application/json'
      },
      body: JSON.stringify(obj)

    }).then(res => res.json()).then((result) => {
      if (callback) {
        callback()
      }



    })
  }

  updateListBuilderFields = (listName, field, callback) => {
    if (this.state.uData && listName && field) {
      let obj = {
        listName: listName,
        field: field
      }
      fetch(API_ENDPOINT + '/users/account/list-fields/update', {
        method: 'POST',
        headers: {
          'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage?.getItem('authToken')}` : null,
          'content-type': 'application/json'
        },
        body: JSON.stringify(obj)

      }).then(res => res.json()).then((result) => {
        if (result && result.hiddenListBuilderFields) {
          let uData = this.state.uData;
          uData.hiddenListBuilderFields = result.hiddenListBuilderFields;
          this.setState({ uData }, () => {
            if (callback) {
              callback()
            }
          })
        }
      })




    }
  }

  checkLastVisitedClinics = (group) => {
    if (typeof window != 'undefined') {
      let lastVisitedClinics = []
      if (localStorage?.lastVisitedClinics) {
        lastVisitedClinics = JSON.parse(localStorage?.lastVisitedClinics);
        lastVisitedClinics = lastVisitedClinics.reverse()
      }
      if (group) {
        let newArr = [];
        newArr = lastVisitedClinics.filter(item => String(item) != String(group));
        newArr.push(String(group));
        newArr = newArr.reverse();
        if (newArr.length > 10) {
          lastVisitedClinics = newArr.slice(0, 10);
        } else {
          lastVisitedClinics = newArr;
        }

        localStorage?.setItem('lastVisitedClinics', JSON.stringify(lastVisitedClinics))
      }

    }
  }

  revalidateListing = () => {
    let profile = (typeof window !== 'undefined' && localStorage && localStorage?.getItem('profile')) ? localStorage?.getItem('profile') : null;
    let groupId = null;
    let clinicId = null;
    if (this.state.selectedGroup) groupId = this.state.selectedGroup
    if (this.state.selectedClinic) clinicId = this.state.selectedClinic;
    let lastGroup = (typeof window !== 'undefined' && localStorage && localStorage?.getItem('lastGroup')) ? localStorage?.getItem('lastGroup') : null;
    let alias = (typeof window !== 'undefined' && localStorage && ![null, 'null', undefined, 'undefined'].includes(localStorage?.getItem('groupAlias'))) ? localStorage?.getItem('groupAlias') : null;

    let url;

    if (alias) {
      if (lastGroup) url = API_ENDPOINT + `/users/verify?grupa=${alias}&profile=${profile}&groupId=${groupId}&lastGroup=${lastGroup}&clinicId=${clinicId}`;
      else url = API_ENDPOINT + `/users/verify?grupa=${alias}&profile=${profile}&groupId=${groupId}&clinicId=${clinicId}`;
    } else {
      if (lastGroup) url = `/users/verify?profile=${profile}&groupId=${groupId}&lastGroup=${lastGroup}&clinicId=${clinicId}`;
      else url = `/users/verify?profile=${profile}&groupId=${groupId}&clinicId=${clinicId}`;
    }

    if (!url) return;

    fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage?.getItem('authToken')}`
      }
    }).then(res => res.json()).then(data => {
      if (data && ![null, undefined].includes(data.listRequests)) {
        this.setState(prev => ({
          ...prev,
          uData: {
            ...prev.uData,
            listRequests: data.listRequests
          }
        }))
      }
    }).catch(err => console.error('FETCH ERR: ', err));
  }

  getStringDateTs = (ts, format = this.state.dateFormat) => {
    if (!ts) return '';
    // let dateOffset = new Date(ts * 1000).getTimezoneOffset();
    // let serverTimezoneOffset = this.state.timezoneOffset;
    // let offset = serverTimezoneOffset ? serverTimezoneOffset - dateOffset : 0;

    // let timestamp = ts - offset * 60;

    // let convertedDate = moment.unix(timestamp).format(format);
    let convertedDate = moment.unix(ts).format(format);

    return convertedDate;
  };

  isValidDate = (d) => {
    return d instanceof Date && !isNaN(d);
  }

  getDateOfBirthFromSSN = (ssn) => {
    let dateOfBirth;
    let year, month, day;
    let format = this.state.dateFormat;

    if (ssn) {
      if (this.state.country === 'denmark' && ssn?.length === 10) {
        day = `${ssn[0]}${ssn[1]}`;
        month = `${ssn[2]}${ssn[3]}`;
        const yearLastDigits = Number(`${ssn[4]}${ssn[5]}`);
        const nowYear = new Date().getFullYear();
        const nowYearLastDigits = `${String(nowYear)[2]}${String(nowYear)[3]}`;
        if (yearLastDigits === 0) year = 2000;
        else {
          if (yearLastDigits >= +nowYearLastDigits) year = Number(`19${yearLastDigits < 10 ? '0' + yearLastDigits : yearLastDigits}`);
          else year = Number(`20${yearLastDigits < 10 ? '0' + yearLastDigits : yearLastDigits}`);
        }
      }
      if (ssn.length === 12) {
        year = ssn[0] + ssn[1] + ssn[2] + ssn[3];
        month = ssn[4] + ssn[5];
        day = ssn[6] + ssn[7];
      }
    }

    if (year && month && day && format) {
      let daysInMoth = new Date(year, month, 0).getDate();

      if (Number(day) > daysInMoth) {
        return null;
      }

      let date = format.replace('MM', month).replace('DD', day).replace('YYYY', year)

      if (this.isValidDate(new Date(date))) {
        dateOfBirth = date;
      }

    }

    return dateOfBirth;
  }

  calculateAgeFromDate = async (date) => {
    let check = await fetch(API_ENDPOINT + '/date/check-years/' + date, {
      method: 'GET',
      headers: {
        'content-type': 'application/json'
      }
    });
    if (check) {
      let result = await check.json()
      if (result && result.years) {
        return result.years;
      } else {
        return null;
      }
    }

  }

  shouldApplyHotfix = () => {
    const today = new Date();
    today.setHours(12, 0, 0, 0);
    if (today.getFullYear() === 2024 && today.getMonth() === 2) {
      // March 2024
      if (today.getDate() < 31) return true;
    }
    return false;
  }

  getStringDate = (date, format = this.state.dateFormat) => {
    let dateOffset = new Date(date).getTimezoneOffset();
    let serverTimezoneOffset = this.state.timezoneOffset;
    let offset = serverTimezoneOffset || serverTimezoneOffset === 0 ? serverTimezoneOffset - dateOffset : 0;

    let timestamp = new Date(date).getTime() / 1000 - offset * 60;

    let convertedDate = moment.unix(timestamp).format(format);

    return convertedDate;
  };

  getStringDateTs = (ts, format = this.state.dateFormat, hotfix = false) => {
    if (!ts) return '';
    let dateOffset = (hotfix && this.shouldApplyHotfix()) ? -60 : new Date(ts * 1000).getTimezoneOffset();
    let serverTimezoneOffset = this.state.timezoneOffset;
    let offset = serverTimezoneOffset || serverTimezoneOffset === 0 ? serverTimezoneOffset - dateOffset : 0;

    let timestamp = ts - offset * 60;

    let convertedDate = moment.unix(timestamp).format(format);

    return convertedDate;
  };
  getStringDateInverseTsV1 = (ts, format = this.state.dateFormat) => {
    if (!ts) return '';
    let dateOffset = new Date(ts * 1000).getTimezoneOffset();
    let serverTimezoneOffset = this.state.timezoneOffset;
    let offset = serverTimezoneOffset || serverTimezoneOffset === 0 ? serverTimezoneOffset - dateOffset : 0;

    let timestamp = ts + offset * 60;

    let convertedDate = moment.unix(timestamp).format(format);

    return convertedDate;
  };
  getStringDateInverseTs = (ts, format = this.state.dateFormat) => {
    if (!ts) return '';
    let dateOffset = new Date(ts * 1000).getTimezoneOffset();
    let currentClientOffest = new Date().getTimezoneOffset();
    let daylight = dateOffset !== currentClientOffest ? true : false
    let serverTimezoneOffset = this.state.timezoneOffset;
    let offset = serverTimezoneOffset || serverTimezoneOffset === 0 ? serverTimezoneOffset - dateOffset : 0;
    if (!daylight) {
      offset = 0;
    } else {
      offset -= serverTimezoneOffset - currentClientOffest;
    }
    let timestamp = ts + offset * 60;

    let convertedDate = moment.unix(timestamp).format(format);

    return convertedDate;
  };
  getMomentTs = (ts) => {
    if (!ts) return '';
    let dateOffset = new Date(ts * 1000).getTimezoneOffset();
    let serverTimezoneOffset = this.state.timezoneOffset;
    let offset = serverTimezoneOffset || serverTimezoneOffset === 0 ? serverTimezoneOffset - dateOffset : 0;
    let timestamp = ts - offset * 60 * 1000;

    let convertedDate = moment(timestamp);

    return convertedDate;
  };
  getDateServer = (ts) => {
    let dateOffset = new Date(ts * 1000).getTimezoneOffset();
    let serverTimezoneOffset = this.state.timezoneOffset;
    let offset = serverTimezoneOffset ? serverTimezoneOffset + serverTimezoneOffset - dateOffset : 0;

    let timestamp = ts - offset * 60;
    let convertedDate = moment.unix(timestamp).format('MM/DD/YYYY HH:mm');

    return convertedDate;
  }

  checkRegistrationRequests = async () => {
    if (!this.state.selectedGroup || !this.state.uData || !this.state.uData.userLevel || this.state.uData.userLevel < 20 || (typeof window !== 'undefined' && localStorage?.getItem?.('profile') !== 'doctor')) return this.setState({ hasRegistrationRequests: false });
    const response = await fetch(API_ENDPOINT + '/users/two-factor-auth/registration-requests/check?clinicGroupId=' + this.state.selectedGroup);
    if (!response || !response.ok) return this.setState({ hasRegistrationRequests: false });
    this.setState({ hasRegistrationRequests: true })
  }
  checkIfConsultationExists = async (referralId, callback) => new Promise((resolve, reject) => {
    this.setState({ activeConsultations: null, isAddNewConsultationButtonDisabled: false, activeConsultationCallback: null }, async () => {
      if (referralId) {
        let response = await fetch(API_ENDPOINT + `/referral/consultation/exists/${referralId}`, {
          method: 'GET',
          headers: {
            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage?.getItem('authToken')}` : null,
            'content-type': 'application/json'
          }
        })
        let result = await response?.json();
        if (result?.existsConsultations?.length) {
          const consultations = result.existsConsultations;

          let isAddNewConsultationButtonDisabled = false;
          if (consultations && Array.isArray(consultations) && consultations.length > 0) {
            for (const consultation of consultations) {
              let tab;
              if (consultation?.sender?.groupId === this.state.selectedGroup && consultation?.sender?.clinicId === this.state.selectedClinic) {
                if (consultation?.sender?.status !== 'closed') {
                  isAddNewConsultationButtonDisabled = true;
                  break;
                }
              } else if (consultation?.receiver?.groupId === this.state.selectedGroup && consultation?.receiver?.clinicId === this.state.selectedClinic) {
                if (consultation?.receiver?.status !== 'closed') {
                  isAddNewConsultationButtonDisabled = true;
                  break;
                }
              };
            }
          }
          let setObj = { activeConsultations: consultations, isAddNewConsultationButtonDisabled }
          if (!isAddNewConsultationButtonDisabled && callback) {
            setObj.activeConsultationCallback = callback;
          } else {
            setObj.activeConsultationCallback = null;
          }

          this.setState(setObj);

          resolve(true);
          // const consultation = result.existsConsultations;
          // let tab;
          // if (consultation?.sender?.groupId === this.state.selectedGroup && consultation?.sender?.clinicId === this.state.selectedClinic) {
          //   tab = 'sender';
          // } else if (consultation?.receiver?.groupId === this.state.selectedGroup && consultation?.receiver?.clinicId === this.state.selectedClinic) {
          //   tab = 'receiver';
          // };

          // if (tab) {
          //   const status = consultation?.[tab]?.status;
          //   console.log({
          //     _chatRedirect: true,
          //     consultationId: consultation?._id,
          //     tab,
          //     status,
          //   })
          //   this.props?.history?.push?.('/e-connect/consultation', {
          //     _chatRedirect: true,
          //     consultationId: consultation?._id,
          //     tab,
          //     status,
          //   })
          // }
        } else {
          resolve(false);
        }

      }
    })


  })
  getCasePdf = (id, daybookFormId = null) => {
    if (id && this.state.uData) {
      let profile = 'patient';
      if (this.state.uData && this.state.uData.userLevel === 20 && typeof window !== 'undefined' && localStorage?.getItem?.('profile') !== 'patient') {
        profile = 'doctor'
      }
      this.setState({ loadingPdf: true }, () => {
        fetch(API_ENDPOINT + '/data/cases/generate/pdf', {
          method: 'POST',
          headers: {
            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage?.getItem('authToken')}` : null,
            'content-type': 'application/json'
          },
          body: JSON.stringify({
            id: id,
            daybookFormId: daybookFormId,
            lang: this.state.lang,
            selectedClinic: this.state.selectedClinic,
            profile
          })

        }).then(res => res.json()).then((result) => {
          if (result && result.pdf) {
            setTimeout(() => {
              this.setState({ loadingPdf: false })
              let pdfUrl = API_ENDPOINT + result.pdf;
              var link = document.createElement("a");
              link.download = result.pdfName ? result.pdfName : 'test';
              link.target = "_blank";

              // Construct the URI
              link.href = pdfUrl;
              document.body.appendChild(link);
              link.click();
            }, 1000);
          } else {
            this.setState({ loadingPdf: false, _printError: result.error ? true : false })
          }
        }).catch(err => {
          console.log('UPLOAD ERR: ', err);
          this.setState({ loadingPdf: false, _printError: true })
        })
      })
    }


  }

  checkEConnectCount = async () => {
    try {
      if (!this.state.uData || !this.state.uData._id || !this.state.uData.userLevel || this.state.uData.userLevel !== 20 || !this.state.selectedGroup || !this.state.selectedClinic) return this.setState({ eConnect: { createdCount: 0, receivedCount: 0, responses: [], monitoring: [], chat: {}, forwarding: [] } });

      this.setState({ eConnect: { createdCount: 0, receivedCount: 0, responses: [], monitoring: [], chat: {}, forwarding: [] } }, async () => {
        const url = `${API_ENDPOINT}/e-connect/cases/count?userId=${this.state.uData?._id}&groupId=${this.state.selectedGroup}&departmentId=${this.state.selectedClinic}`;
        const response = await fetch(url, { headers: { 'Content-Type': 'application/json' } });
        if (!response || !response.ok);
        const result = await response.json();
        if (!result) return;

        this.setState({
          eConnect: {
            createdCount: result.createdCount || 0,
            receivedCount: result.receivedCount || 0,
            responses: result.responses || [],
            monitoring: result.monitoring || [],
            forwarding: result.forwarding || [],
            chat: result.chat || {},
          }
        });
      });

    } catch (err) {
      console.log('--- checkEConnectCount err: ', err);
      return this.setState({ eConnect: { createdCount: 0, receivedCount: 0 } });
    }
  }
  checkConsultationIndicators = () => {
    if (this.state.uData?._id && this.state.uData?.userLevel && typeof window !== 'undefined' && this.state.selectedClinic && this.state.selectedGroup) {
      this.setState({
        consultationIndicators: {
          sidebar: false,
          chatConsultationIds: [],
          incoming: false,
          outgoing: false,
          archived: false,
          unhandled: [],
        }
      }, () => {
        fetch(API_ENDPOINT + `/consultation/indicators/${this.state.selectedGroup}/${this.state.selectedClinic}`, {
          method: 'GET',
          headers: {
            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage?.getItem('authToken')}` : null,
            'content-type': 'application/json'
          }
        }).then(res => res.json()).then((result) => {
          let consultationIndicators = {
            sidebar: result?.sidebar ? true : false,
            chatConsultationIds: result?.chatConsultationIds ?? [],
            incoming: result?.incoming ? true : false,
            outgoing: result?.outgoing ? true : false,
            archived: result?.archived ? true : false,
            unhandled: result?.unhandled ?? [],
          }
          this.setState({ consultationIndicators })
        })
      })


    }
  }

  render() {
    let meta;
    if (this.state.metaTags) {
      meta = {
        title: this.state.linksMeta && this.state.linksMeta[this.props.location.pathname] ? this.state.linksMeta[this.props.location.pathname][this.state.lang] : this.state.metaTags.title,
        description: this.state.metaTags.description ? this.state.metaTags.description : null,
        meta: {
          charset: 'utf-8',
          name: {
            'og:title': this.state.linksMeta && this.state.linksMeta[this.props.location.pathname] ? this.state.linksMeta[this.props.location.pathname][this.state.lang] : this.state.metaTags.title,
            'og:image': this.state.metaTags['og:image'] ? this.state.metaTags['og:image'] : null,
            'og:description': this.state.metaTags.description ? this.state.metaTags.description : null,

          }
        }
      };

      if (this.state.metaTags['apple-itunes-app']) {
        meta.meta.name['apple-itunes-app'] = this.state.metaTags['apple-itunes-app'];
      }
    }

    let routerProps = [];
    routerProps[0] = this.props;

    if ((this.state.browserName && this.state.browserName !== 'Chrome' && this.state.browserName !== 'Safari' && this.state.browserName !== 'Firefox' && this.state.browserName !== 'Edge') || (this.state.browserNameUA && this.state.browserNameUA !== 'Chrome' && this.state.browserNameUA !== 'Safari' && this.state.browserNameUA !== 'Firefox' && this.state.browserNameUA !== 'Edge')) {
      return (
        <div className='guest-end-call-message'>
          <p>{'This browser is not supported.'.translate(this.state.lang)}</p>
        </div>
      )
    }

    routes.some(route => {
      // use `matchPath` here

      const match = matchPath(routerProps[0].location.pathname, route);
      if (match && match.isExact) {
        routerProps[0].match.path = match.path;
        routerProps[0].match.params = match.params;
      }
      return match && match.isExact;
    });

    // console.log('--- App.js render() this.state: ', this.state.offerData, this.state.offerData && this.props.location.pathname.indexOf('video-call') === -1 && this.props.location.pathname.indexOf('video-call-gateway') === -1 && this.props.location.pathname !== '/waiting-room');

    return (
      <Provider store={store}>
        {
          meta ?

            <DocumentMeta {...meta}>
            </DocumentMeta>

            :
            null
        }
        <Routes
          {...this.state}
          getPlatformInfo={this.getPlatformInfo}
          checkEConnectCount={this.checkEConnectCount}
          checkRegistrationRequests={this.checkRegistrationRequests}
          revalidateListing={this.revalidateListing}
          translate={this.translate}
          setLang={this.setLang}
          setLightMode={this.setLightMode}
          serverFetch={this.props.serverFetch}
          initialData={this.props.initialData ? this.props.initialData : {}}
          updateMeta={this.updateMeta}
          verifyUser={this.verifyUser}
          formatName={this.formatName}
          ring112Audio={this.ring112Audio}
          handleUploadFileProgress={this.handleUploadFileProgress}
          toggleSidebar={this.toggleSidebarv2}
          forceSidebarWideToFalse={this.forceSidebarWideToFalse}
          toggleAutoHide={this.toggleAutoHide}
          registerLog={this.registerLog}
          signOut={this.signOut}
          patientCreatedReferral={this.patientCreatedReferral}
          changeGroup={this.changeGroup}
          changeProfile={this.changeProfile}
          setGroupAlias={this.setGroupAlias}
          changeClinic={this.changeClinic}
          getSelectedGroup={this.getSelectedGroup}
          getSelectedClinic={this.getSelectedClinic}
          resetLogoutInterval={this.resetLogoutInterval}
          initSocketForVideoRoom={this.initSocketForVideoRoom}
          disableLogoutMessage={this.disableLogoutMessage}
          statusBefore={this.statusBefore}
          clearStatusBefore={this.clearStatusBefore}
          updateChat={this.updateChat}
          registerClinicGroupLogs={this.registerClinicGroupLogs}
          groupSettingsChanged={this.groupSettingsChanged}
          checkConsultationIndicators={this.checkConsultationIndicators}
          openModal={this.openModal}
          abortAction={this.abortAction}
          checkVisitedGroups={this.checkVisitedGroups}
          checkLastVisitedClinics={this.checkLastVisitedClinics}
          endCall={this.endCall}
          updateSidebarScrollTop={(sidebarScrollTop) => this.setState({ sidebarScrollTop })}
          socket={this.socket}
          hideSidebarAndNavbar={this.hideSidebarAndNavbar}
          updateListBuilderFields={this.updateListBuilderFields}
          getCasePdf={this.getCasePdf}
          checkIfConsultationExists={this.checkIfConsultationExists}
          setActiveVideoCallInWaitingRoom={this.setActiveVideoCallInWaitingRoom}
          startVideoCall={(conversation) => {

            var strWindowFeatures = `height=${window.innerHeight - 1},width=${window.innerWidth - 1},resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes`;
            this.videoCallWindow = window.open(`/video-call/${conversation}`, "_blank", strWindowFeatures);
            this.videoCallWindow.addEventListener('message',
              (e) => {
                if (e.data == 'end-video-call') {
                  this.videoCallWindow.close();
                }

                /*if (e.data == 'toggle-video-call') {
                  this.setState({
                    videoCallMin: !this.state.videoCallMin,
                    videoCallX: !this.state.videoCallMin ? typeof window != 'undefined' ? window.innerWidth - 320 : 0 : 0,
                    videoCallY: !this.state.videoCallMin ? typeof window != 'undefined' ? 20 : 0 : 0,
        
                  })
                }*/

              }

              , false)
            /*this.setState({
              videoCallUrl: `/video-call/${conversation}`,
              videoCallMin: false
            })*/
          }}
          allowCookies={() => {
            localStorage?.setItem?.('allowCookies', true);
            this.setState({
              cookies: true
            });
          }}
          getStringDate={this.getStringDate}
          getStringDateTs={this.getStringDateTs}
          getDateOfBirthFromSSN={this.getDateOfBirthFromSSN}
          isValidDate={this.isValidDate}
          calculateAgeFromDate={this.calculateAgeFromDate}
          getStringDateInverseTs={this.getStringDateInverseTs}
          getStringDateInverseTsV1={this.getStringDateInverseTsV1}
          getMomentTs={this.getMomentTs}
          getDateServer={this.getDateServer}
        />

        {
          this.state.activeConsultations ?
            <Modal
              isOpen={this.state.activeConsultations}
              centered
              size='md'
            >
              <ModalHeader
                close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ activeConsultations: null })} ><Isvg src={xIcon} /></button>}
              >
                {'Consultations'.translate(this.state.lang)}
              </ModalHeader>
              <ModalBody>
                <Row>
                  <Col>
                    {this.state.activeConsultations && Array.isArray(this.state.activeConsultations) && this.state.activeConsultations.length ? this.state.activeConsultations.map((consultation, idx) => {
                      let tab;
                      if (consultation?.sender?.groupId === this.state.selectedGroup && consultation?.sender?.clinicId === this.state.selectedClinic) {
                        tab = 'sender';
                      } else if (consultation?.receiver?.groupId === this.state.selectedGroup && consultation?.receiver?.clinicId === this.state.selectedClinic) {
                        tab = 'receiver';
                      };

                      if (!tab) return null;

                      const caseNumber = consultation?.referralData?.caseNumber ?? 'N/A';
                      const patient = {
                        name: consultation?.referralData?.patientName ?? 'N/A',
                        ssn: consultation?.patientSSN ?? 'N/A',
                      }

                      const otherClinic = tab === 'sender' ? {
                        group: consultation?.receiver?.groupName,
                        clinic: consultation?.receiver?.clinicName,
                      } : {
                        group: consultation?.sender?.groupName,
                        clinic: consultation?.sender?.clinicName,
                      }

                      const status = tab === 'sender' ? (
                        consultation?.sender?.status === 'closed' ? (
                          'Closed'.translate(this.props.lang)
                        ) : (
                          'Active'.translate(this.props.lang)
                        )
                      ) : (
                        consultation?.receiver?.status === 'closed' ? (
                          'Closed'.translate(this.props.lang)
                        ) : (
                          'Active'.translate(this.props.lang)
                        )
                      );

                      return (
                        <Button
                          key={consultation?._id ?? idx}
                          className='consultations-active-cons-button-wrapper'
                          onClick={() => {
                            const status = consultation?.[tab]?.status;
                            this.props?.history?.push?.('/e-connect/consultation', {
                              _chatRedirect: true,
                              consultationId: consultation?._id,
                              tab,
                              status,
                            })
                            this.setState({
                              activeConsultations: null,
                            })
                          }}
                        >

                          <div>
                            <div>{otherClinic?.group}</div>
                            <div>{otherClinic?.clinic}</div>
                          </div>
                          <div>{status}</div>
                        </Button>
                      )
                    }) : null}
                  </Col>
                </Row>
              </ModalBody>
              <ModalFooter style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                <button hidden style={{ display: 'none', visibility: 'hidden' }} aria-hidden='true'></button>
                <Button disabled={this.state.isAddNewConsultationButtonDisabled} style={{ minWidth: 100 }} color='info' onClick={() => {
                  if (this.state.isAddNewConsultationButtonDisabled) return;
                  if (!this.state.activeConsultationCallback) return;
                  this.state.activeConsultationCallback?.()
                  this.setState({
                    activeConsultations: null,
                  })
                }}>{'Create New Consultation'.translate(this.props.lang)}</Button>
                <Button style={{ minWidth: 100 }} color="primary" onClick={() => this.setState({ activeConsultations: null })}>{'Close'.translate(this.state.lang)}</Button>
                <button hidden style={{ display: 'none', visibility: 'hidden' }} aria-hidden='true'></button>
              </ModalFooter>
            </Modal> : null
        }
        {
          this.state.loadingPdf ?
            <div className="loader-wrap-case-pdf-wrap">
              <div className="loader-wrap-case-pdf">
                <Player
                  autoplay={true}
                  loop={true}
                  src="/lf30_editor_l5cxzdyf.json"
                  style={{
                    height:
                      typeof window != "undefined" &&
                        window.innerWidth < 768
                        ? "128px"
                        : "256px",
                    width:
                      typeof window != "undefined" &&
                        window.innerWidth < 768
                        ? "128px"
                        : "256px",
                  }}
                ></Player>

              </div>
            </div>

            :
            null
        }

        {/* {this.state.offerData && this.props.location.pathname.indexOf('video-call') === -1 && this.props.location.pathname.indexOf('video-call-gateway') === -1 && this.props.location.pathname !== '/waiting-room' ?
          <div className="video-call-pop-up">
            <div class="caller">
              <img src={this.state.offerData.user && this.state.offerData.user.profilePicture ? API_ENDPOINT + this.state.offerData.user.profilePicture : profilePicture} />
              <p>{this.state.offerData && this.state.offerData.user ? this.state.offerData.user.name : ''}</p>
            </div>
            <div className="buttons">
              <button onClick={this.answer} className="answer-button"><Isvg src={call} /></button>
              <button onClick={this.endCall} className="decline-button"><Isvg src={call} /></button>
            </div>

          </div>
          :
          null

        } */}

        {/** CV-297 this code is commented */}
        {this.state.offerData && this.props.location.pathname.indexOf('video-call') === -1 && this.props.location.pathname.indexOf('video-call-gateway') === -1 ?
          <VideoCallPopUp
            lang={this.state.lang}
            profilePicture={this.state.offerData?.user?.profilePicture ? API_ENDPOINT + this.state.offerData.user.profilePicture : profilePicture}
            name={this.state.offerData?.user?.name ?? ''}
            answer={this.answer}
            uData={this.state.uData}
            socket={this.socket}
            pauseAudioIncommingCall={this.pauseAudioIncommingCall}
          // endCall={this.endCall}
          />
          :
          null

        }



        {
          this.state.refreshRequired ?
            <div className="refresh-required">
              <div>
                <p>{'Curoflow updated to new version, please reload page to continue!'.translate(this.state.lang)}</p>
                <Button color="primary" onClick={() => {
                  window.location.reload();

                }}>{'Reload'.translate(this.state.lang)}</Button>
              </div>
            </div>

            :

            null
        }
        {
          false && this.state.showSavedFormsMessage && this.state.uData?._id && (routerProps[0]?.match?.path == '/:alias' || routerProps[0]?.match?.path == '/:mainAlias/:alias') ?
            <InfoModal
              isOpen={this.state.showSavedFormsMessage}
              onClose={(item) => {
                this.setState({
                  showSavedFormsMessage: item
                })
              }}
              close={true}
              header={'Info'.translate(this.state.lang)}
              info={'You have incomplete forms saved in your account. You can continue answering by starting the appropriate type of visit. Note that the saved forms will be deleted from the system 7 days after their answering has been started.'.translate(this.state.lang)}
              buttons={[
                <Button color="primary" style={{ margin: 10 }} onClick={() => this.setState({ showSavedFormsMessage: false }, () => {
                  if (typeof window != 'undefined') {
                    let checkedGroupsSavedForms = localStorage?.checkedGroupsSavedForms ? JSON.parse(localStorage?.checkedGroupsSavedForms) : [];
                    if (localStorage?.groupAlias) {
                      checkedGroupsSavedForms.push(localStorage?.groupAlias)
                      localStorage?.setItem('checkedGroupsSavedForms', JSON.stringify(checkedGroupsSavedForms))
                    }

                  }

                })}>{'Ok'.translate(this.state.lang)}</Button>


              ]}
            />

            :
            null
        }

        {this.state.hasSavedForms && this.state.uData?._id ? <InfoModal
          isOpen={this.state.hasSavedForms && this.state.uData?._id}
          onClose={this.removeSavedFormsModalMessage}
          close={true}
          header={'Info'.translate(this.state.lang)}
          info={<div>
            <div>{'You have incomplete forms saved in your account. You can continue answering by starting the appropriate type of visit. Note that the saved forms will be deleted from the system 7 days after their answering has been started.'.translate(this.state.lang)}</div>
            <br />
            {this.state.hasSavedForms?.multiple && this.state.hasSavedForms?.clinicGroupNames?.length > 0 ? < div >
              {'Clinics where you have saved forms'.translate(this.state.lang)}:
              <div style={{ marginTop: 5 }}>
                {this.state.hasSavedForms?.clinicGroupNames?.map?.(item => <div style={{ textAlign: 'center' }} key={item}>{item}</div>)}
              </div>
            </div> : null}
          </div>}
          buttons={
            [
              <Button
                color="primary"
                style={{ margin: 10 }}
                onClick={this.removeSavedFormsModalMessage}
              >
                {'Ok'.translate(this.state.lang)}
              </Button>


            ]}
        /> : null
        }

        {
          this.state._withoutGroupPermissions ?

            <InfoModal
              isOpen={this.state._withoutGroupPermissions}
              onClose={(item) => {
                this.setState({
                  _withoutGroupPermissions: item
                })
              }}
              close={true}
              header={'Info'.translate(this.state.lang)}
              info={'Your session will be closed because you are not a member of any clinic group.'.translate(this.state.lang)}
              buttons={[
                <Button color="primary" style={{ margin: 10 }} onClick={() => this.setState({ _withoutGroupPermissions: false }, () => this.signOut())}>{'OK'.translate(this.state.lang)}</Button>


              ]}
            />

            :
            null
        }

        {
          this.state._printError ? <ErrorModal lang={this.state.lang} isOpen={this.state._printError} toggle={() => this.setState({ _printError: false })}>
            {'An error preventing print action has occured!'.translate(this.state.lang)}
            {' '}
            {this.state.uData?.userLevel && this.state.uData?.userLevel >= 20 && (typeof window !== 'undefined' ? (
              !localStorage?.getItem?.('profile') || localStorage?.getItem?.('profile') !== 'patient'
            ) : true) ? 'Please check with your clinic administrator that all the information about your clinic, including logo, has been filled out!'.translate(this.state.lang) : null}
          </ErrorModal> : null
        }

        {
          this.state._forbidden ?
            <ErrorModal lang={this.state.lang} isOpen={this.state._forbidden} toggle={() => this.setState({ _forbidden: null })}>
              {'Action not allowed!'.translate(this.state.lang)}
            </ErrorModal>
            :
            null
        }
        {
          this.state._toManyRequests ?
            <ErrorModal lang={this.state.lang} isOpen={this.state._toManyRequests} toggle={() => this.setState({ _toManyRequests: null }, () => {
              // this.props.history.push('/')
            }
            )}>
              {this.state._toManyRequests}
            </ErrorModal>
            :
            null
        }
        {
          this.state.botModal ?
            <ErrorModal lang={this.state.lang} isOpen={this.state.botModal} toggle={() => { }}>
              {'Action not allowed!'.translate(this.state.lang)}
            </ErrorModal>
            :
            null
        }


        {
          this.state.uData && (this.state.uData.userLevel == 20 && typeof window != 'undefined' && localStorage?.getItem?.('profile') != 'patient') && routerProps[0].match.path != '/chat' && routerProps[0].match.path != '/chat/:windowConversation' && routerProps[0].match.path != '/video-call/:conversation' && routerProps[0].match.path != '/webdoc/auth' ?
            <ChatComponent
              {...routerProps}
              {...this.state}
              translate={this.translate}
              setLang={this.setLang}
              setLightMode={this.setLightMode}
              serverFetch={this.props.serverFetch}
              initialData={this.props.initialData ? this.props.initialData : {}}
              updateMeta={this.updateMeta}
              verifyUser={this.verifyUser}
              formatName={this.formatName}
              ring112Audio={this.ring112Audio}
              handleUploadFileProgress={this.handleUploadFileProgress}
              toggleSidebar={this.toggleSidebarv2}
              forceSidebarWideToFalse={this.forceSidebarWideToFalse}
              toggleAutoHide={this.toggleAutoHide}
              registerLog={this.registerLog}
              signOut={this.signOut}
              patientCreatedReferral={this.patientCreatedReferral}
              changeGroup={this.changeGroup}
              changeProfile={this.changeProfile}
              setGroupAlias={this.setGroupAlias}
              changeClinic={this.changeClinic}
              getSelectedGroup={this.getSelectedGroup}
              getSelectedClinic={this.getSelectedClinic}
              resetLogoutInterval={this.resetLogoutInterval}
              initSocketForVideoRoom={this.initSocketForVideoRoom}
              disableLogoutMessage={this.disableLogoutMessage}
              statusBefore={this.statusBefore}
              clearStatusBefore={this.clearStatusBefore}
              updateChat={this.updateChat}
              registerClinicGroupLogs={this.registerClinicGroupLogs}
              groupSettingsChanged={this.groupSettingsChanged}
              checkConsultationIndicators={this.checkConsultationIndicators}
              openModal={this.openModal}
              abortAction={this.abortAction}
              checkVisitedGroups={this.checkVisitedGroups}
              checkLastVisitedClinics={this.checkLastVisitedClinics}
              updateSidebarScrollTop={(sidebarScrollTop) => this.setState({ sidebarScrollTop })}
              socket={this.socket}
              updateListBuilderFields={this.updateListBuilderFields}
              getCasePdf={this.getCasePdf}
              checkIfConsultationExists={this.checkIfConsultationExists}

              startVideoCall={(conversation) => {
                var strWindowFeatures = `height=${window.innerHeight - 1},width=${window.innerWidth - 1},resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes`;
                this.videoCallWindow = window.open(`/video-call/${conversation}`, "_blank", strWindowFeatures);
                this.videoCallWindow.addEventListener('message',
                  (e) => {
                    if (e.data == 'end-video-call') {
                      this.videoCallWindow.close();
                    }

                    /*if (e.data == 'toggle-video-call') {
                      this.setState({
                        videoCallMin: !this.state.videoCallMin,
                        videoCallX: !this.state.videoCallMin ? typeof window != 'undefined' ? window.innerWidth - 320 : 0 : 0,
                        videoCallY: !this.state.videoCallMin ? typeof window != 'undefined' ? 20 : 0 : 0,
            
                      })
                    }*/

                  }

                  , false)
                /*this.setState({
                  videoCallUrl: `/video-call/${conversation}`,
                  videoCallMin: false
                })*/
              }}
              allowCookies={() => {
                localStorage?.setItem?.('allowCookies', true);
                this.setState({
                  cookies: true
                });
              }}
              getStringDate={this.getStringDate}
              getStringDateTs={this.getStringDateTs}
              getDateOfBirthFromSSN={this.getDateOfBirthFromSSN}
              isValidDate={this.isValidDate}
              calculateAgeFromDate={this.calculateAgeFromDate}
              getStringDateInverseTs={this.getStringDateInverseTs}
              getStringDateInverseTsV1={this.getStringDateInverseTsV1}
              getMomentTs={this.getMomentTs}
              getDateServer={this.getDateServer}
            />
            :
            null
        }


        {/*
          this.state.videoCallUrl ?

            <Rnd
              className="video-call-iframe-wrap"
              //dragHandleClassName={"video-call-iframe-drag-handle"}
              position={{
                x: this.state.videoCallMin ? this.state.videoCallX : 0,
                y: this.state.videoCallMin ? this.state.videoCallY : 0,
              }}
              enableResizing={false}
              disableDragging={!this.state.videoCallMin}
              size={{
                width: this.state.videoCallMin ? 320 : typeof window != 'undefined' ? window.innerWidth : 320,
                height: this.state.videoCallMin ? 240 : typeof window != 'undefined' ? window.innerHeight : 240
              }}

              onDragStop={(e, d) => {
                if (this.state.videoCallMin)
                  this.setState({ videoCallX: d.x, videoCallY: d.y });
              }}
            >
              {this.state.videoCallMin ?
                <div className="video-call-iframe-drag-handle"><Isvg src={dragIcon} /></div>
                :
                null

              }
              <iframe class={this.state.videoCallMin ? "video-call-iframe video-call-iframe-min" : "video-call-iframe"} src={this.state.videoCallUrl}></iframe>

            </Rnd>
            :
            null*/
        }

        {
          typeof window !== 'undefined' && (window.origin == 'https://qa-console.curoflow.se') ?
            <QA />
            :
            null
        }
      </Provider >

    );

  }




  uDataCondition = (condition) => {
    let arr = condition.split('||');
    for (let i = 0; i < arr.length; i++) {
      if (this.state.uData[arr[i]]) {
        return true;
      }
    }

    return false;
  }

  clinicEntryModuleCondition = (group, condition) => {
    let arr = condition.split('||');
    for (let i = 0; i < arr.length; i++) {
      if (group[arr[i]]) {
        return true;
      }
    }

    return false;
  }

  checkTimePhone = () => {
    if (localStorage?.time && Math.floor(Date.now() / 1000) - localStorage?.getItem('time') > /*59 * 20*/ LOGOUT_TIMEOUTV2) {
      const isIframe = this.checkIfIframe();
      if (isIframe) return;

      let override = false;
      if (localStorage?.getItem?.('hidePatientModal')) {
        override = true;
      }
      this.signOut(override ? false : this.props.location.pathname?.indexOf('/choose-time') !== -1 ? true : false)
      if (this.props.location.pathname?.indexOf('/choose-time') === -1)
        this.enableLogoutMessage();
    } else {
      this.resetLogoutInterval

    }

  }


  fetchPolyfill = (url, options) => {
    return new Promise((resolve, reject) => {
      fetch(url, options).then((res) => {
        if (res.status == 403) {
          this.setState({
            _forbidden: true
          })
        }


        resolve(res);
      }).catch((error) => reject(error));
    })
  }

  getVisibilityAPI = () => {
    // Set the name of the hidden property and the change event for visibility
    let hidden, visibilityChange;
    if (typeof document.hidden !== "undefined") { // Opera 12.10 and Firefox 18 and later support 
      hidden = "hidden";
      visibilityChange = "visibilitychange";
    } else if (typeof document.msHidden !== "undefined") {
      hidden = "msHidden";
      visibilityChange = "msvisibilitychange";
    } else if (typeof document.webkitHidden !== "undefined") {
      hidden = "webkitHidden";
      visibilityChange = "webkitvisibilitychange";
    }

    return {
      hidden,
      visibilityChange
    }
  }

  handleVisibilityChange = () => {
    let { hidden } = this.getVisibilityAPI();

    // if (this.activeTab && document[hidden] && this.state.call && this.state.removeBg) {
    //     this.getStream(true);
    // }

    if (!this.activeTab && !document[hidden]) {
      if (this.socket) {
        this.socket.emit('activeVideoCalls', {})
      }
    }


    this.activeTab = !document[hidden];
    // console.log('activeTab', this.activeTab);
    // document.title = 'activeTab ' + (this.activeTab ? '1' : '0') ;

  }

  checkExpiredEConnect = () => {
    try {
      if (typeof window !== 'undefined') {
        const eConnectSavedProgressString = localStorage?.getItem?.('eConnectSavedProgress');
        if (eConnectSavedProgressString) {
          const eConnectSavedProgress = JSON.parse(eConnectSavedProgressString);
          if (eConnectSavedProgress && Array.isArray(eConnectSavedProgress) && eConnectSavedProgress.length > 0) {
            const ts = getSevenDaysBeforeTs();
            const newArr = eConnectSavedProgress?.filter?.(obj => obj.ts && obj.ts >= ts);
            if (newArr && newArr.length > 0) {
              localStorage?.setItem?.('eConnectSavedProgress', JSON.stringify(newArr));
            } else {
              localStorage?.removeItem?.('eConnectSavedProgress');
            }
          }
        }
      }
    } catch (err) {
      console.log('--- checkExpiredEConnect err: ', err);
    }
  }

  getPlatformInfo = async () => {
    const response = await fetch(API_ENDPOINT + '/system/platform');
    if (response && response.ok) {
      const result = await response.json();
      if (result) {
        if (result.country) {
          // ! REAL WORLD USAGE !!! - don't leave commented out!
          this.setState({ country: result.country }, () => {
            localStorage?.setItem?.('country', result.country)
          })

          // ? Uncomment for denmark testing
          // this.setState({ country: 'denmark' }, () => {
          //   localStorage?.setItem?.('country', 'denmark')
          // });
        };
        if (result.countryTwoLetter) {
          this.setState({ countryTwoLetter: result.countryTwoLetter })
        }

        if (result.currency) {
          this.setState({ currency: result.currency }, () => {
            localStorage?.setItem("currency", result.currency);
          });
        };

        if (result.secondaryLanguageDisabled) {
          this.setState({ secondaryLanguageDisabled: true, lang: 'en', dateFormat: 'MM-DD-YYYY', dateTimeFormat: 'MM-DD-YYYY HH:mm' }, () => {
            localStorage?.setItem?.('secondaryLanguageDisabled', 'yes');
          })
        } else {
          this.setState({ secondaryLanguageDisabled: false }, () => {
            localStorage?.setItem?.('secondaryLanguageDisabled', 'no');
          })
        }
      }

      // ? todo - more platform info here ...
    }
  }

  checkIfIframe = () => {
    if (typeof window !== 'undefined' && window.self !== window.top) return true;
    return false;
  }

  componentDidMount() {
    this.getPlatformInfo();
    this.checkExpiredEConnect();
    this.checkEConnectCount();
    // if (typeof window !== 'undefined' && !localStorage.bankIdStarted) {
    //   localStorage.removeItem('tempEvent')
    //   localStorage.removeItem('tempSelectedReferral')
    //   localStorage.removeItem('tempReferral')
    //   localStorage.removeItem('tempClinicGroup')
    //   localStorage.removeItem('tempDoctor')
    //   localStorage.removeItem('webBookingBankId')
    // }

    let browserName = browserInfo();

    let userAgent = navigator.userAgent;
    let browserNameUA = 'No browser detection'
    if (userAgent.match(/chrome|chromium|crios/i)) {
      browserNameUA = "Chrome";
    } else if (userAgent.match(/firefox|fxios/i)) {
      browserNameUA = "Firefox";
    } else if (userAgent.match(/safari/i)) {
      browserNameUA = "Safari";
    } else if (userAgent.match(/edg/i)) {
      browserNameUA = "Edge";
    }

    this.setState({
      browserName: browserName.name,
      browserNameUA: browserNameUA
    }, () => {
      if (typeof window !== 'undefined' && window.innerWidth && window.innerWidth < 768) {
        if ((userAgent.indexOf("FBAN") > -1) || (userAgent.indexOf("FBAV") > -1)) {
          browserNameUA = 'Facebook'
        }

        this.setState({
          browserNameUA: browserNameUA
        })
      }

    })

    let { visibilityChange, hidden } = this.getVisibilityAPI();

    // Warn if the browser doesn't support addEventListener or the Page Visibility API
    if (typeof document.addEventListener === "undefined" || hidden === undefined) {
      console.log("This demo requires a browser, such as Google Chrome or Firefox, that supports the Page Visibility API.");
    } else {
      // Handle page visibility change   
      document.addEventListener(visibilityChange, this.handleVisibilityChange, false);

      document.addEventListener('focus', this.handleVisibilityChange, false);
      document.addEventListener('pageshow', this.handleVisibilityChange, false);
    }
    if (typeof window != 'undefined' && localStorage?.getItem('autoHideSidebar')) {
      this.setState({ autoHideSidebar: localStorage?.getItem('autoHideSidebar') == 'true' ? true : false }, () => {
        if (window.innerWidth > 1199 && this.state.autoHideSidebar) {
          this.setState({ sidebarWide: true })
        }
      })
    }

    this.emergency = new Audio(emergency);
    this.logoutIntervalV2 = setInterval(() => {
      if (this.state.uData) {

        if (Math.floor(Date.now() / 1000) - localStorage?.getItem('time') > LOGOUT_TIMEOUTV2) {
          const isIframe = this.checkIfIframe();
          if (!isIframe) {
            let override = false;
            if (localStorage?.getItem?.('hidePatientModal')) {
              override = true;
            }
            this.signOut(override ? false : this.props.location.pathname?.indexOf('/choose-time') !== -1 ? true : false)
            if (this.props.location.pathname?.indexOf('/choose-time') === -1)
              this.enableLogoutMessage()
          }
        }

      }
    }, 2 * 60 * 1000)

    if (this.detectAutomatedTool() && !(typeof window !== 'undefined' && (window.origin == 'https://tryme.curoflow.se' || window.origin == 'https://qa-console.curoflow.se' || window.origin == 'https://dev.curoflow.se'))) {
      this.props.history.replace('/');
      this.setState({
        botModal: true
      })
      return;
    }

    const originalFetch = window.fetch;
    window.fetch = (url, options) => new Promise((resolve, reject) => {
      if (!options) {
        options = {}
      }
      if (!options.headers) {
        options.headers = {}
      }
      // if (this.state.selectedGroup) {
      //   options.headers.__groupId = this.state.selectedGroup;
      // } else if (typeof window != 'undefined' && localStorage?.groupAlias) {
      //   options.headers.__groupAlias = localStorage?.groupAlias;
      // }


      originalFetch(url, options).then((res) => {
        if (res.status == 403) {
          this.setState({
            _forbidden: true
          })
        } else if (res.status == 429) {
          this.setState({
            _toManyRequests: 'Too many accounts created from this IP, please try again later'.translate(this.state.lang)
          })
        }

        resolve(res);
      }).catch((error) => reject(error));
    })







    this.socketListener = socketIO(API_ENDPOINT, { /*transports: ['websocket'],*/transports: ['websocket', 'polling'], query: { listener: true } });
    this.socketListener.on('checkVersion', (data) => {
      if (data.version && data.version !== APP_VERSION) {
        this.setState({
          refreshRequired: true
        })
      }
    })


    if (localStorage?.time && Math.floor(Date.now() / 1000) - localStorage?.getItem('time') > /*59 * 20*/ LOGOUT_TIMEOUTV2) {
      const isIframe = this.checkIfIframe();
      if (!isIframe) {
        let override = false;
        if (localStorage?.getItem?.('hidePatientModal')) {
          override = true;
        }
        this.signOut(override ? false : this.props.location.pathname?.indexOf('/choose-time') !== -1 ? true : false)
        if (this.props.location.pathname?.indexOf('/choose-time') === -1) this.enableLogoutMessage();
      }
    }

    document.addEventListener('mousemove', this.resetLogoutInterval)
    document.addEventListener('touchmove', this.checkTimePhone)

    this.props.history.listen((location) => {
      let isMobile = typeof window != 'undefined' && window.innerWidth < 1200;
      if (isMobile && location.pathname != this.props.location.pathname) {
        // this.setState({
        //   sidebarWide: null
        // })
      }


      if (this.state.uData && localStorage?.getItem('authToken')) {

        routes.some(route => {
          // use `matchPath` here

          const match = matchPath(location.pathname, route);
          if (match && match.isExact) {
            // console.log(route.path, this.state.availablePaths);

            if (this.state.availablePaths && this.state.availablePaths.length && !route.disableAvailablePaths && this.state.availablePaths.indexOf(route.path) == -1) {
              if (this.state.modulesTree && this.state.modulesTree.length) {
                this.props.history.push(this.state.modulesTree[0].link)
              } else {
                this.props.history.push(this.state.availablePaths[0])
              }

            }

          }
          return match && match.isExact;
        });

      }






    })

    try {
      this.triggerPushNotification();
    } catch (error) {

    }

    if (localStorage?.allowCookies) {

      this.setState({
        cookies: true
      });
    }

    this.verifyUser();

    setInterval(this.checkToken, 60000);

    fetch(API_ENDPOINT + '/language-translate', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage?.getItem('authToken')}`
      },
    }).then(res => {
      return res.json()
    }).then((result) => {
      window.langs = result;
      this.forceUpdate();
    });



    window.addEventListener('message',
      (e) => {
        if (e.data == 'end-video-call') {
          this.setState({
            videoCallUrl: null
          })
        }

        if (e.data == 'toggle-video-call') {
          this.setState({
            videoCallMin: !this.state.videoCallMin,
            videoCallX: !this.state.videoCallMin ? typeof window != 'undefined' ? window.innerWidth - 320 : 0 : 0,
            videoCallY: !this.state.videoCallMin ? typeof window != 'undefined' ? 20 : 0 : 0,

          })
        }

      }, false)

    if (this.props.uData) {
      this.setState({
        loggedIn: true
      })
    }

    fetch(API_ENDPOINT + '/timezone/info', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
    }).then(res => { return res.json() }).then((result) => {
      if (result.timezoneOffset || result.timezoneOffset === 0) {
        this.setState({ timezoneOffset: result.timezoneOffset, serverTimeInfo: result })
      }
    });

    fetch(API_ENDPOINT + '/timezone/info', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
    }).then(res => { return res.json() }).then((result) => {
      if (result.timezoneOffset || result.timezoneOffset === 0) {
        this.setState({ timezoneOffset: result.timezoneOffset })
      }
    });
    if (API_ENDPOINT.indexOf('localhost') === -1 && (typeof window !== 'undefined' && window?.origin?.indexOf('localhost') === -1)) {
      Sentry.init({
        dsn: SENTRY_DSN,
        integrations: [new BrowserTracing()],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
      });
    }

    fetch(API_ENDPOINT + '/users/date-of-birth', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
    }).then(res => { return res.json() }).then((result) => {
      let showDateOfBirth = false
      if (result?.dateOfBirth) {
        showDateOfBirth = true;
      }
      this.setState({ showDateOfBirth })
    });




  }

}

export default withRouter(App);
