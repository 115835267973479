import React, { Component } from 'react';
import Page from '../../containers/page';

import {
    Container,
    Row,
    Col,
    Modal, ModalHeader, ModalBody, ModalFooter, Button, Label, FormGroup, Input,

} from 'reactstrap';

import Isvg from 'react-inlinesvg'
import { Player, Controls } from '@lottiefiles/react-lottie-player';

import { API_ENDPOINT } from '../../constants';

import TypeFields from './typeFields';

const questionTypesToShow = [1, 2, 3, 4, 5, 6, 7, 8, 9, 12, 13, 14, 15, 18, 19];
const questionTypesForSubtotalTotal = [1, 2, 5, 6, 12, 13, 14];


import { Waypoint } from 'react-waypoint';

import { generateSections } from './helper/helperFunctions';



class AnswerFormComponent extends Component {
    constructor(props) {
        super(props);
        this.player = React.createRef();
        this.state = {
            ...props.initialData,
            answers: {},
            backAnswers: {},
            sections: [],
            skippedQuestions: {},
            nextButtonClicked: {},
            sectionQuestions: {},
            regions: [],
            questionsQueue: [],
            questionsQueueIndexes: {},
            questions: {},
            questionsIndexes: {},
            questionPage: 0,
            questionPageEntries: 10,
            currentQuestion: null,
            submitClicked: false,
        };
        this.sectionConditions = [true];
        this.totalSubtotal = [];
    }

    stopAnimation = () => {
        this.player.current.play();
    };


    componentDidMount() {



        if (this.props.referral) {
            this.get()
        }

        if (this.props.getSaveFormData) {
            this.props.getSaveFormData(this.getSaveFormData);
        }
        if (this.props.setSaveFormData) {
            this.props.setSaveFormData(this.setSaveFormData);
        }

        if (this.props._savedRevisitForm) {
            this.setSaveFormData(this.props._savedRevisitForm);
        }

    }
    get = () => {

        this.setState({ referral: this.props.referral }, () => {
            let sections;
            if (this.state.referral?.items) {
                sections = generateSections(this.state.referral.items)
            }
            if (sections) {
                this.setInitialSections(sections);
            } else {
                this.setInitialSections(this.state.referral?.sections)
            }

        })
    }


    getAnswersAndSections = () => {
        if (this.props.getAnswersAndSections) {
            this.props.getAnswersAndSections(this.state.sections, this.state.answers, this.state.question18Finished)
        }
    }

    getSaveFormData = () => {
        let data = {
            skippedQuestions: this.state.skippedQuestions,
            answers: this.state.answers,
            nextButtonClicked: this.state.nextButtonClicked,
            questionsQueue: this.state.questionsQueue,
            currentQuestion: this.state.currentQuestion,
            backAnswers: this.state.backAnswers
        }

        return data;
    }
    setSaveFormData = (data) => {
        if (data && Object.keys(data).length) {
            this.setState(data, () => {
                this.setCurrentQuestion(false, () => {
                    this.checkIfNextQuestionIsTotal()
                })
            })
        }
    }


    componentWillUnmount() {

    }

    componentDidUpdate(prevProps, prevState) {
        if (!prevProps.referral && this.props.referral) {
            this.get()
        }
    }

    checkAnsweredQuestions = () => {

        let answers = this.state.answers;
        let nextButtonClicked = this.state.nextButtonClicked ? this.state.nextButtonClicked : {};
        let question18Finished = this.state.question18Finished ? this.state.question18Finished : {};
        let questionsQueue = this.state.questionsQueue ? this.state.questionsQueue : [];
        let currentQuestion;

        let answersWithIdx = [];

        for (var key in answers) {
            if (key) {
                answersWithIdx.push({
                    questionId: key,
                    answer: answers[key],
                    __idx: this.state.questionsIndexes[key]
                })
                this.checkTotalSubtotal(key)
            }
        }
        answersWithIdx = answersWithIdx.sort((a, b) => a.__idx - b.__idx)

        for (let i = 0; i < answersWithIdx.length; i++) {
            if (answersWithIdx[i].questionId) {
                let question = this.getQuestion(answersWithIdx[i].questionId);
                if (question) {
                    if (question.type === 18) {
                        question18Finished[question.id] = true;
                    }
                    nextButtonClicked[question.id] = true;
                    questionsQueue.push(question.id);

                    if (questionTypesToShow.indexOf(question.type) !== -1) {
                        currentQuestion = question;
                    }
                }
            }
        }
        if (currentQuestion && currentQuestion.id && nextButtonClicked[currentQuestion.id]) {
            delete nextButtonClicked[currentQuestion.id];
        }

        this.setState({ questionsQueue, nextButtonClicked, question18Finished, currentQuestion }, () => {
            this.checkShowButtons()
        })

    }


    setInitialSections = (sections) => {

        let idx = 0;
        let intelligentReferral = this.state.referral?.intelligentReferral ? true : false;

        let firstQuestion;

        let questions = {};
        let questionsIndexes = {};
        let totalSubtotal = [];
        let sectionConditions = [];
        let sectionQuestions = {};

        let block = {}

        if (sections && sections.length) {
            for (let i = 0; i < sections.length; i++) {
                if (i === 0 || !intelligentReferral) {
                    sectionConditions.push(true)
                } else {
                    if (!sections[i].conditions || !sections[i]?.conditions.length) {
                        sectionConditions.push(true)
                    } else {
                        sectionConditions.push(false)
                    }

                }
                if (!sectionQuestions[i]) {
                    sectionQuestions[i] = [];
                }
                for (let j = 0; j < sections[i].questions.length; j++) {
                    let questionType = sections[i].questions[j] && sections[i].questions[j].type;
                    if (!firstQuestion && questionType && questionTypesToShow.indexOf(questionType) !== -1) {
                        firstQuestion = sections[i].questions[j];
                    }
                    if (questionTypesForSubtotalTotal.indexOf(questionType) !== -1) {
                        block[sections[i].questions[j].id] = { points: 0 };
                    }
                    if (questionType === 16 || questionType === 17) {
                        let totalSubtotalObj = {
                            id: sections[i].questions[j].id,
                            type: questionType,
                            block: { ...block },
                            sum: 0
                        }
                        if (questionType === 17) {
                            totalSubtotalObj.totalSum = 0;
                        }

                        totalSubtotal.push(totalSubtotalObj);
                        block = {};
                    }


                    sections[i].questions[j].__idx = idx;
                    questions[idx] = sections[i].questions[j].id;
                    questionsIndexes[sections[i].questions[j].id] = idx;
                    sectionQuestions[i].push(sections[i].questions[j].id)
                    idx++;
                }

            }
        }
        this.totalSubtotal = totalSubtotal;

        this.sectionConditions = sectionConditions;
        let questionPage = 0;
        if (this.props.previewAnsweredChatForm || this.props.previewAnsweredForm) {
            questionPage = 100;
        }



        this.setState({
            sections,
            questions,
            questionsIndexes,
            sectionQuestions,
            currentQuestion: firstQuestion,
            questionPage
        }, () => {
            if (this.props.answers && Object.keys(this.props.answers).length) {
                this.setState({ answers: this.props.answers }, () => {
                    this.checkAnsweredQuestions()

                })
            }
            this.checkShowButtons()
        })

    }
    checkShowQuestion = (questionId) => {

        let showQuestion = false;
        let currentQuestion = this.state.currentQuestion;

        if (currentQuestion && currentQuestion.id === questionId) {
            showQuestion = true;
        } else if (this.state.skippedQuestions && this.state.skippedQuestions[questionId]) {
            showQuestion = true;
        } else if (this.state.answers && this.state.answers[questionId]) {
            showQuestion = true;
        } else if (this.state.nextButtonClicked && this.state.nextButtonClicked[questionId]) {
            showQuestion = true;
        }
        if (currentQuestion && this.state.answers[currentQuestion.id] && this.checkQuestionAnswered(this.state.answers[currentQuestion.id], currentQuestion.type, currentQuestion.id)) {
            let findNextQuestion = this.findNextQuestion(false, false);
            if (findNextQuestion && findNextQuestion.type === 4) {
                showQuestion = true;
            }
        }


        return showQuestion;
    }
    getQuestion = (id) => {
        for (let i = 0; i < this.state.sections.length; i++) {
            if (this.state.sections[i].questions) {
                for (let j = 0; j < this.state.sections[i].questions.length; j++) {
                    if (this.state.sections[i].questions[j].id == id) {
                        return this.state.sections[i].questions[j];
                    }
                }
            }
        }

        return {};
    }
    getAnswer = (id) => {
        for (let i = 0; i < this.state.sections.length; i++) {
            if (this.state.sections[i].questions) {
                for (let j = 0; j < this.state.sections[i].questions.length; j++) {
                    if (this.state.sections[i].questions[j].answers) {
                        for (let k = 0; k < this.state.sections[i].questions[j].answers.length; k++) {
                            if (this.state.sections[i].questions[j].answers[k].id == id) {
                                return this.state.sections[i].questions[j].answers[k];
                            }
                        }
                    }
                    if (this.state.sections[i].questions[j].blocks) {
                        for (let k = 0; k < this.state.sections[i].questions[j].blocks.length; k++) {
                            if (this.state.sections[i].questions[j].blocks[k].id == id) {
                                return this.state.sections[i].questions[j].blocks[k];
                            }
                        }
                    }
                }
            }
        }

        return {};
    }




    answerQuestion = (questionId, answer, callback) => {

        this.props.ring112Audio(false)
        let intelligentReferral = this.state.referral?.intelligentReferral ? true : false;

        let question = this.getQuestion(questionId);
        if (!question) { return; }

        let answers = this.state.answers;

        if (question.type == 1 || question.type == 16 || question.type == 17) {
            if (!answers[question.id]) {
                answers[question.id] = null;
            }
            answers[question.id] = answer;
        } else if (question.type == 2) {
            if (!answers[question.id]) {
                answers[question.id] = [];
            }
            if (answers[question.id].indexOf(answer) !== -1) {
                answers[question.id].splice(answers[question.id].indexOf(answer), 1);
            } else {
                answers[question.id].push(answer);
            }
        } else if (question.type == 3 || question.type == 5 || question.type == 6 || question.type == 7 || question.type == 8 || question.type == 9 || question.type == 12 || question.type == 13 || question.type == 14 || question.type == 18 || question.type == 19) {
            answers[question.id] = answer;
        }

        let setObj = {
            answers: answers
        }
        let skippedQuestions = this.state.skippedQuestions;
        if (skippedQuestions[questionId]) {
            delete skippedQuestions[questionId];
            setObj.skippedQuestions = skippedQuestions;
        }
        let backAnswers = this.state.backAnswers;
        if (backAnswers[questionId]) {
            delete backAnswers[questionId];
            setObj.backAnswers = backAnswers;
        }

        this.setState({
            setObj
        }, () => {
            this.getAnswersAndSections()

            this.checkIfNextQuestionIsTotal()

            if (question.type === 17) {
                if (callback) {
                    callback()
                }
            } else {
                this.checkShowButtons()
                let nextId = question.id;
                let subtotalArr = [nextId];
                let findNextQuestionById = this.findNextQuestionById(false, true, nextId, subtotalArr);
                if (findNextQuestionById && question.type === 16) {
                    this.nextQuestion()
                } else {
                    if (callback) {
                        callback()
                    }
                }
                if (questionTypesForSubtotalTotal.indexOf(question.type) !== -1) {
                    this.checkTotalSubtotal(question.id)
                }
            }

        })
    }
    checkNextAnsweredQuestions = (questionId) => {

        let nextButtonClicked = this.state.nextButtonClicked;
        let questionsIndexes = this.state.questionsIndexes;

        let newAnswers = {};
        let newNextButtonClicked = {};
        let newQuestionsQueue = [];

        let answersWithIdx = [];

        let __idx = 0;
        for (var key in nextButtonClicked) {
            if (key) {
                answersWithIdx.push({
                    questionId: key,
                    answer: nextButtonClicked[key],
                    __idx: questionsIndexes[key]
                })
                if (key === questionId) {
                    __idx = questionsIndexes[key];
                }
            }
        }
        answersWithIdx = answersWithIdx.sort((a, b) => a.__idx - b.__idx)

        let check = true;
        for (let i = 0; i < answersWithIdx.length; i++) {
            if (answersWithIdx[i].questionId && check) {
                let ckeckNextQuestion;
                let checkquestion = this.getQuestion(answersWithIdx[i].questionId)
                if (checkquestion?.__idx > -1) {
                    let nextIdx = checkquestion.__idx + 1;
                    let checked = false;
                    while (!checked) {
                        if (this.state.questions[nextIdx]) {
                            let question = this.getQuestion(this.state.questions[nextIdx])

                            let questionTypesToShowArr = [...questionTypesToShow];
                            questionTypesToShowArr = [...questionTypesToShowArr, 16, 17]
                            if (question && question.type && questionTypesToShowArr.indexOf(question.type) !== -1 && this.checkQuestionConditions(question.id)) {
                                ckeckNextQuestion = question;
                                checked = true;
                            } else {
                                nextIdx = nextIdx + 1;
                            }

                        } else {
                            checked = true;
                        }
                    }
                }

                if (this.state.answers[answersWithIdx[i].questionId]) {
                    newAnswers[answersWithIdx[i].questionId] = this.state.answers[answersWithIdx[i].questionId];
                }
                if (this.state.nextButtonClicked[answersWithIdx[i].questionId]) {
                    newNextButtonClicked[answersWithIdx[i].questionId] = this.state.nextButtonClicked[answersWithIdx[i].questionId];
                }
                if (this.state.questionsQueue.indexOf(answersWithIdx[i].questionId) !== -1) {
                    newQuestionsQueue.push(answersWithIdx[i].questionId)
                }
                if (answersWithIdx[i].__idx >= __idx) {
                    check = false;
                }

            }
        }
        this.setState({
            answers: newAnswers,
            nextButtonClicked: newNextButtonClicked,
            questionsQueue: newQuestionsQueue,
            currentQuestion: this.getQuestion(questionId)
        }, () => {

            this.checkShowButtons()
        })


    }
    checkQuestionAnswered = (answer, questionType, questionId) => {

        let answerExist = false;
        if (!answer || !questionType) {
            return answerExist;
        }
        if (answer && (questionType === 1 || questionType === 3 || questionType === 14 || questionType === 16 || questionType === 17 || questionType === 19)) {
            answerExist = true;
        } else if (answer && answer.length && answer[0] && (questionType === 2 || questionType === 5 || questionType === 8 || questionType === 9 || questionType === 13)) {
            answerExist = true;
        } else if (answer && answer[0] && answer[1] && questionType === 6) {
            answerExist = true;
        } else if (answer && answer[0] && answer[1] && answer[2] && (questionType === 12)) {
            answerExist = true;
        } else if (questionType === 18) {
            if (this.state.question18Finished && this.state.question18Finished && this.state.question18Finished[questionId] && answer) {
                answerExist = true;
            }
        }

        return answerExist;
    }
    checkShowButtons = () => {


        let currentQuestion = this.state.currentQuestion;
        let intelligentReferral = this.state.referral?.intelligentReferral ? true : false;
        let showButtons = {
            skip: false,
            next: false,
            back: false,
            submit: false
        }

        if (!currentQuestion) {
            this.setState({ showButtons })
            return;
        }

        let questionType = currentQuestion.type;
        let answer = this.state.answers[currentQuestion.id];

        if (questionType === 4) {
            showButtons.back = true;
            this.setState({ showButtons }, () => {
                if (!this.props.preview)
                    this.scrollToEnd();
            })
            return;
        }


        if (!intelligentReferral) {
            if (!this.checkQuestionAnswered(answer, questionType, currentQuestion.id) && questionType !== 4 && questionType !== 15) {
                showButtons.skip = true;
            } else if (this.checkQuestionAnswered(answer, questionType, currentQuestion.id)) {
                showButtons.next = true;
                // showButtons.back = true;
            }

            let findNextQuestion = this.findNextQuestion();
            if (!findNextQuestion && (!currentQuestion.required || (currentQuestion.required && this.state.answers && this.state.answers[currentQuestion.id]))) {
                showButtons.submit = true;
            }
        } else {
            if (this.checkQuestionAnswered(answer, questionType, currentQuestion.id)) {
                showButtons.next = true;
                // showButtons.back = true;
            }
            let findNextQuestion = this.findNextQuestion(false, true);
            if (findNextQuestion && findNextQuestion.type === 16 && showButtons.next) {
                let nextId = findNextQuestion.id;
                let subtotalArr = [nextId];
                let findNextQuestionById = this.findNextQuestionById(false, true, nextId, subtotalArr);

                if (!findNextQuestionById || findNextQuestionById?.type === 17) {
                    showButtons.submit = true;
                }
            } else if ((!findNextQuestion || findNextQuestion?.type === 17) && (!currentQuestion || (currentQuestion && this.checkQuestionAnswered(answer, questionType, currentQuestion.id)))) {
                showButtons.submit = true;
            }
        }

        if (Object.keys(this.state.nextButtonClicked).length > 0 || Object.keys(this.state.skippedQuestions).length > 0) {
            showButtons.back = true;
        }


        if (questionType === 4 || questionType === 15) {
            if (questionType === 15) {
                let findNextQuestion = this.findNextQuestion(false, true);
                if (!findNextQuestion || findNextQuestion?.type === 17) {
                    showButtons.submit = true;
                } else {
                    showButtons.next = true;
                }
            }
            showButtons.back = true;
        }

        if (Object.keys(this.state.answers).length < 2 && Object.keys(this.state.skippedQuestions).length === 0) {
            let setBackFalse = true;
            if (Object.keys(this.state.answers).length === 1) {
                for (var key in this.state.answers) {
                    if (key != currentQuestion.id) {
                        setBackFalse = false;
                    }
                }
            }
            if (showButtons.back && setBackFalse) showButtons.back = false;
        }


        if (showButtons.next || currentQuestion.required) {
            if (showButtons.skip) showButtons.skip = false;
        }

        if (showButtons.submit) {
            if (showButtons.next) showButtons.next = false;
        }
        if (showButtons.skip && showButtons.submit) {
            if (this.state.skippedQuestions[currentQuestion.id]) {
                showButtons.skip = false;
            } else {
                showButtons.submit = false;
            }

        }
        if (intelligentReferral && showButtons.skip) {
            showButtons.skip = false;
        }

        if (intelligentReferral) {
            if (!showButtons.submit && showButtons.next) {
                if (this.state.nextIsTotal) {
                    showButtons.submit = true;
                    showButtons.next = false;
                }
            }
        } else {
            if (!showButtons.submit) {
                if (this.state.nextIsTotal) {
                    showButtons.submit = true;
                    showButtons.next = false;
                    showButtons.skip = false;
                }
            }
        }



        if (this.props.emitEnd) {
            this.props.emitEnd(this.state.answers, showButtons.submit)
        }

        if (this.props.preview) {
            showButtons = {
                skip: false,
                next: false,
                back: false,
                submit: false
            }
        }


        if (this.props.previewAnswerForm && this.state.submitClicked && !showButtons.back) {
            showButtons.back = true;
        }
        this.setState({ showButtons }, () => {

            if (currentQuestion?.type !== 2 && !this.props.preview)
                this.scrollToEnd()
        })

    }
    checkTotalSubtotal = (questionId) => {

        let totalSubtotal = this.totalSubtotal;

        let obj;
        if (totalSubtotal && totalSubtotal.length) {
            for (let i = 0; i < totalSubtotal.length; i++) {
                if (totalSubtotal[i] && totalSubtotal[i].block && totalSubtotal[i].block[questionId]) {
                    obj = totalSubtotal[i];
                }
            }
        }
        if (obj?.block && this.state.answers) {
            let sum = 0;
            for (var key in obj.block) {
                let score = 0;
                let question = this.getQuestion(key)
                if (this.state.answers[key] && question && this.checkQuestionAnswered(this.state.answers[key], question.type, key)) {
                    if (question.type === 1) {
                        let answer = this.getAnswer(this.state.answers[key]);
                        if (answer?.score) {
                            score = answer.score;
                        }
                    } else if (question.type === 2) {
                        if (this.state.answers[key] && this.state.answers[key].length) {
                            for (let i = 0; i < this.state.answers[key].length; i++) {
                                let answer = this.getAnswer(this.state.answers[key][i]);
                                if (answer?.score) {
                                    score += answer.score;
                                }
                            }
                        }
                    } else if (question?.score) {
                        score = question.score;
                    }
                }

                sum += score;
                obj.block[key].points = score;
            }
            obj.sum = sum;
            let index = totalSubtotal.findIndex(el => el.id === obj.id);
            if (index > -1) {
                totalSubtotal[index] = { ...obj };
            }
        }
        if (totalSubtotal?.filter(el => el.type === 17).length) {
            let totalSum = 0;
            for (let i = 0; i < totalSubtotal.length; i++) {
                if (totalSubtotal[i].sum) {
                    totalSum += totalSubtotal[i].sum;
                }
            }
            let index = totalSubtotal.findIndex(el => el.type === 17);
            if (index > -1) {
                totalSubtotal[index].totalSum = totalSum;
            }
        }
        this.totalSubtotal = totalSubtotal;

        this.checkIfNextQuestionIsTotal()

    }

    skipQuestion = () => {
        let currentQuestion = this.state.currentQuestion;
        if (!currentQuestion) return;



        let skippedQuestions = this.state.skippedQuestions;
        let questionsQueue = this.state.questionsQueue ? this.state.questionsQueue : [];
        let answers = this.state.answers;
        if (answers[currentQuestion.id])
            delete answers[currentQuestion.id];

        skippedQuestions[currentQuestion.id] = true;
        if (questionsQueue.indexOf(currentQuestion.id) === -1) {
            questionsQueue.push(currentQuestion.id);
        }

        let setObj = {
            skippedQuestions,
            questionsQueue,
            answers
        }
        if (this.state.questionPage > 0) {
            setObj.questionPage = 0;
        }
        this.setState(setObj, () => {
            this.setCurrentQuestion(false, () => {
                this.checkIfNextQuestionIsTotal()
            })

        })
    }
    nextQuestion = (callback) => {

        let currentQuestion = this.state.currentQuestion;
        if (!currentQuestion) return;

        let nextButtonClicked = this.state.nextButtonClicked ? this.state.nextButtonClicked : {};
        let questionsQueue = this.state.questionsQueue ? this.state.questionsQueue : [];
        if (questionsQueue.indexOf(currentQuestion.id) === -1 && (currentQuestion.type !== 16 && currentQuestion.type !== 17))
            questionsQueue.push(currentQuestion.id);
        nextButtonClicked[currentQuestion.id] = true;

        let setObj = {
            questionsQueue,
            nextButtonClicked
        }

        if (currentQuestion.type === 15 && !this.state.answers[currentQuestion.id]) {
            let answers = this.state.answers;
            answers[currentQuestion.id] = true;
            setObj.answers = answers;
        }


        if (this.state.questionPage > 0) {
            setObj.questionPage = 0;
        }

        this.setState(setObj, () => {

            this.setCurrentQuestion(false, callback, () => {
                this.checkIfNextQuestionIsTotal()
            })

        })

    }
    backQuestion = () => {


        let intelligentReferral = this.state.referral?.intelligentReferral ? true : false;
        let currentQuestion = this.state.currentQuestion;
        if (!currentQuestion) return;

        this.setState({ checkAudio: false }, () => {
            this.props.ring112Audio(null)
        })

        let questionsQueue = this.state.questionsQueue ? JSON.parse(JSON.stringify(this.state.questionsQueue)) : [];
        let nextButtonClicked = this.state.nextButtonClicked ? JSON.parse(JSON.stringify(this.state.nextButtonClicked)) : {};
        let answers = JSON.parse(JSON.stringify(this.state.answers));
        let backAnswers = JSON.parse(JSON.stringify(this.state.backAnswers));

        let setObj = {}
        if (questionsQueue && questionsQueue.length > 0) {
            if (currentQuestion.type === 4 || currentQuestion.type === 15) {
                if (questionsQueue[questionsQueue.length - 1] && currentQuestion.id === questionsQueue[questionsQueue.length - 1]) {
                    questionsQueue.splice(questionsQueue.length - 1, 1)
                }
                let prevQuestionId = questionsQueue[questionsQueue.length - 1];

                let prevQuestionIdCheckSubtotal = this.getPreviousAnsweredQuestion(currentQuestion.id);
                if (prevQuestionIdCheckSubtotal) {
                    let prevQuestion = this.getQuestion(prevQuestionIdCheckSubtotal)

                    if (prevQuestion.type === 16 && prevQuestion.id) {

                        while (prevQuestion?.type === 16) {
                            if (answers[prevQuestionIdCheckSubtotal]) {
                                backAnswers[prevQuestionIdCheckSubtotal] = answers[prevQuestionIdCheckSubtotal];
                                delete answers[prevQuestionIdCheckSubtotal];

                            }
                            if (nextButtonClicked[prevQuestionIdCheckSubtotal])
                                delete nextButtonClicked[prevQuestionIdCheckSubtotal];

                            let index = questionsQueue.indexOf(prevQuestionIdCheckSubtotal);
                            if (index !== -1) {
                                questionsQueue.splice(index, 1)
                            }

                            setObj.answers = answers;

                            prevQuestionIdCheckSubtotal = this.getPreviousAnsweredQuestion(prevQuestionIdCheckSubtotal);
                            if (prevQuestionIdCheckSubtotal) {
                                prevQuestion = this.getQuestion(prevQuestionIdCheckSubtotal)
                            } else {
                                prevQuestion = null;
                            }

                        }

                    }
                }


                if (prevQuestionId) {
                    if (this.state.skippedQuestions[prevQuestionId]) {

                        let skippedQuestions = this.state.skippedQuestions;
                        delete skippedQuestions[prevQuestionId];
                        let index = questionsQueue.indexOf(prevQuestionId);
                        if (index !== -1) {
                            questionsQueue.splice(index, 1)
                        }
                        delete nextButtonClicked[prevQuestionId];
                        delete nextButtonClicked[currentQuestion.id];

                        setObj.skippedQuestions = skippedQuestions;
                        setObj.nextButtonClicked = nextButtonClicked;
                        setObj.questionsQueue = questionsQueue;

                    } else if (this.state.answers[prevQuestionId]) {
                        let index = questionsQueue.indexOf(prevQuestionId);
                        if (index !== -1) {
                            questionsQueue.splice(index, 1)
                        }
                        delete nextButtonClicked[prevQuestionId];
                        delete nextButtonClicked[currentQuestion.id];
                        setObj.nextButtonClicked = nextButtonClicked;
                        setObj.questionsQueue = questionsQueue;


                    }

                }
            } else {
                let qustionId = currentQuestion.id;
                let prevQuestionId = this.getPreviousAnsweredQuestion(qustionId);
                let prevQuestion;
                if (prevQuestionId) {
                    prevQuestion = this.getQuestion(prevQuestionId)

                    if ((prevQuestion.type === 16 || prevQuestion.type === 17) && prevQuestion.id) {
                        while (prevQuestion?.type === 16 || prevQuestion?.type === 17) {
                            if (answers[prevQuestionId]) {
                                backAnswers[prevQuestionId] = answers[prevQuestionId];
                                delete answers[prevQuestionId];
                            }
                            if (nextButtonClicked[prevQuestionId])
                                delete nextButtonClicked[prevQuestionId];

                            let index = questionsQueue.indexOf(prevQuestionId);
                            if (index !== -1) {
                                questionsQueue.splice(index, 1)
                            }

                            prevQuestionId = this.getPreviousAnsweredQuestion(prevQuestionId);
                            if (prevQuestionId) {
                                prevQuestion = this.getQuestion(prevQuestionId)
                            }

                        }
                    }

                }

                if (qustionId) {
                    if (this.state.skippedQuestions[qustionId]) {
                        backAnswers[qustionId] = answers[qustionId];
                        delete answers[qustionId];
                        let skippedQuestions = this.state.skippedQuestions;
                        delete skippedQuestions[qustionId];
                        let index = questionsQueue.indexOf(qustionId);
                        if (index !== -1) {
                            questionsQueue.splice(index, 1)
                        }
                        delete nextButtonClicked[qustionId];
                        setObj = {
                            skippedQuestions,
                            answers,
                            questionsQueue,
                            nextButtonClicked,
                            backAnswers
                        };
                        if (currentQuestion.type === 18 && this.state.question18Finished && this.state.question18Finished[currentQuestion.id]) {
                            let question18Finished = this.state.question18Finished;
                            delete question18Finished[currentQuestion.id];
                            setObj.question18Finished = question18Finished;
                        }

                    } else if (this.state.answers[qustionId]) {
                        if (currentQuestion.type === 18) {
                            backAnswers[qustionId] = {
                                answer: answers[qustionId],
                                question18Finished: this.state.question18Finished && this.state.question18Finished[currentQuestion.id] ? true : false
                            };
                        } else {
                            backAnswers[qustionId] = answers[qustionId];
                        }
                        delete answers[qustionId];
                        delete nextButtonClicked[qustionId];
                        let index = questionsQueue.indexOf(qustionId);
                        if (index !== -1) {
                            questionsQueue.splice(index, 1)
                        }
                        setObj = {
                            answers: answers,
                            questionsQueue,
                            nextButtonClicked: nextButtonClicked,
                            backAnswers
                        };
                        if (currentQuestion.type === 18 && this.state.question18Finished && this.state.question18Finished[currentQuestion.id]) {
                            let question18Finished = this.state.question18Finished;
                            delete question18Finished[currentQuestion.id];
                            setObj.question18Finished = question18Finished;
                        }

                    } else {
                        if (nextButtonClicked && nextButtonClicked[qustionId])
                            delete nextButtonClicked[qustionId];
                        setObj = {
                            answers: answers,
                            questionsQueue,
                            nextButtonClicked: nextButtonClicked,
                            backAnswers
                        };
                        if (currentQuestion.type === 18 && this.state.question18Finished && this.state.question18Finished[currentQuestion.id]) {
                            let question18Finished = this.state.question18Finished;
                            delete question18Finished[currentQuestion.id];
                            setObj.question18Finished = question18Finished;
                        }
                    }

                }
            }


        }

        if (this.state.questionPage > 0) {
            setObj.questionPage = 0;
        }
        setObj.submitClicked = false;
        setObj.nextIsTotal = false;

        // check total
        if (this.totalSubtotal && this.totalSubtotal.filter(el => el.type === 17).length) {
            let totalQuestionId = this.totalSubtotal.filter(el => el.type === 17)[0].id;

            let answers = setObj?.answers ? JSON.parse(JSON.stringify(setObj.answers)) : this.state.answers;
            let nextButtonClicked = setObj?.nextButtonClicked ? JSON.parse(JSON.stringify(setObj.nextButtonClicked)) : this.state.nextButtonClicked;
            let questionsQueue = setObj?.questionsQueue ? JSON.parse(JSON.stringify(setObj.questionsQueue)) : this.state.questionsQueue;
            if (answers && answers[totalQuestionId]) {
                delete answers[totalQuestionId];
                setObj.answers = answers;
            }
            if (nextButtonClicked && nextButtonClicked[totalQuestionId]) {
                delete nextButtonClicked[totalQuestionId];
                setObj.nextButtonClicked = nextButtonClicked;
            }
            if (questionsQueue && questionsQueue.indexOf(totalQuestionId) !== -1) {
                let index = questionsQueue.indexOf(qustionId);
                if (index !== -1) {
                    questionsQueue.splice(index, 1)
                }
                setObj.questionsQueue = questionsQueue;
            }
        }
        ///


        this.setState(setObj, () => {
            this.getAnswersAndSections()

            this.setCurrentQuestion(true)

        })




    }
    setCurrentQuestion = (back = false, callback, secondCallback) => {

        let intelligentReferral = this.state.referral?.intelligentReferral ? true : false;
        let currentQuestion = this.state.currentQuestion;
        let findNextQuestion = this.findNextQuestion(back, !back);
        if (findNextQuestion) {
            currentQuestion = findNextQuestion;
        }

        if (currentQuestion?.type === 16 || currentQuestion?.type === 17) {
            let index = this.totalSubtotal?.findIndex(el => el.id === currentQuestion.id)
            if (index > -1) {
                let sum = currentQuestion.type === 16 ? this.totalSubtotal[index]?.sum : this.totalSubtotal[index]?.totalSum;
                if (currentQuestion?.blocks?.length) {
                    let answerId;
                    for (let i = 0; i < currentQuestion.blocks.length; i++) {
                        if (sum >= currentQuestion.blocks[i]?.start && sum <= currentQuestion.blocks[i]?.end) {
                            answerId = currentQuestion.blocks[i].id;
                            break;
                        }
                    }
                    if (answerId) {
                        this.answerQuestion(currentQuestion.id, answerId)
                    }
                }
            }
        }
        let setCurrentQuetion = true;

        if (setCurrentQuetion) {

            this.setState({ currentQuestion }, () => {

                if (this.state.currentQuestion && this.state.currentQuestion.id && this.state.backAnswers[this.state.currentQuestion.id]) {
                    if (this.state.currentQuestion.type === 2) {
                        let answer = this.state.backAnswers[this.state.currentQuestion.id];
                        if (answer && Object.keys(answer).length) {
                            for (var key in answer) {
                                this.answerQuestion(this.state.currentQuestion.id, answer[key])
                            }
                        }
                    } else {
                        if (this.state.currentQuestion.type === 18) {
                            if (this.state.backAnswers[this.state.currentQuestion.id].answer) {
                                if (this.state.backAnswers[this.state.currentQuestion.id].question18Finished) {
                                    let question18Finished = this.state.question18Finished;
                                    question18Finished[this.state.currentQuestion.id] = true;
                                    this.setState({ question18Finished })
                                }
                                this.answerQuestion(this.state.currentQuestion.id, this.state.backAnswers[this.state.currentQuestion.id].answer)
                            }
                        } else {
                            this.answerQuestion(this.state.currentQuestion.id, this.state.backAnswers[this.state.currentQuestion.id])
                        }
                    }
                }

                if (back) {
                    this.checkTotalSubtotal()
                }

                if (callback) {
                    callback()
                }
                if (secondCallback) {
                    secondCallback()
                }


                if (this.state.currentQuestion && this.state.currentQuestion.type === 4 && !this.state.checkAudio && this.state.currentQuestion.userInput) {
                    this.setState({
                        checkAudio: true
                    })
                    this.props.ring112Audio(true)
                }


                this.checkShowButtons();

                if (!this.props.preview)
                    this.scrollToEnd()
            })
        }

    }
    checkIfNextQuestionIsTotal = () => {
        let findNextQuestion = this.findNextQuestion(false, true);

        let nextIsTotal = false;
        if (findNextQuestion && findNextQuestion.type === 16) {
            let nextId = findNextQuestion.id;
            let subtotalArr = [nextId];

            let findNextQuestionById = this.findNextQuestionById(false, true, nextId, subtotalArr);
            if (findNextQuestionById?.type === 16) {
                while (findNextQuestionById?.type === 16) {
                    nextId = findNextQuestionById.id;
                    subtotalArr.push(nextId)
                    findNextQuestionById = this.findNextQuestionById(false, true, nextId, subtotalArr);
                    if (findNextQuestionById?.type === 17) {
                        nextIsTotal = true;
                    }
                }
            } else if (findNextQuestionById?.type === 17) {
                nextIsTotal = true;
            }
        }
        if ((findNextQuestion?.type === 17 || findNextQuestion?.type === 16) && this.props.hideSubmitButton) {
            let index = this.totalSubtotal?.findIndex(el => el.id === findNextQuestion.id)
            if (index > -1) {
                let sum = findNextQuestion?.type === 17 ? this.totalSubtotal[index]?.totalSum : this.totalSubtotal[index]?.sum;
                if (findNextQuestion?.blocks?.length) {
                    let answerId;
                    for (let i = 0; i < findNextQuestion.blocks.length; i++) {
                        if (sum >= findNextQuestion.blocks[i]?.start && sum <= findNextQuestion.blocks[i]?.end) {
                            answerId = findNextQuestion.blocks[i].id;
                            break;
                        }
                    }
                    if (answerId && this.state.answers[findNextQuestion.id] !== answerId) {
                        this.answerQuestion(findNextQuestion.id, answerId)
                    }
                }
            }
        }
        if (this.state.nextIsTotal !== nextIsTotal) {
            this.setState({ nextIsTotal }, () => {
                this.checkShowButtons()
            })
        }
    }

    findNextQuestion = (back = false, includeSubtotalTotal = false) => {

        let currentQuestion;
        let intelligentReferral = this.state.referral?.intelligentReferral ? true : false;

        if (!intelligentReferral) {
            if (this.state.currentQuestion && this.state.currentQuestion.__idx > -1) {
                let inc = back ? -1 : 1
                let nextIdx = this.state.currentQuestion.__idx + inc;
                let checked = false;
                while (!checked) {
                    if (this.state.questions[nextIdx]) {
                        let question = this.getQuestion(this.state.questions[nextIdx])

                        let questionTypesToShowArr = [...questionTypesToShow];
                        if (includeSubtotalTotal) {
                            questionTypesToShowArr = [...questionTypesToShowArr, 16, 17]
                        }
                        if (question && question.type && questionTypesToShowArr.indexOf(question.type) !== -1) {
                            currentQuestion = question;
                            checked = true;
                        } else {
                            nextIdx = nextIdx + inc;
                        }
                    } else {
                        checked = true;
                    }
                }
            }
        } else {
            if (this.state.currentQuestion?.__idx > -1) {
                let inc = back ? -1 : 1
                let nextIdx = this.state.currentQuestion.__idx + inc;
                let checked = false;
                while (!checked) {
                    if (this.state.questions[nextIdx]) {
                        let question = this.getQuestion(this.state.questions[nextIdx])
                        let questionTypesToShowArr = [...questionTypesToShow];
                        if (includeSubtotalTotal) {
                            questionTypesToShowArr = [...questionTypesToShowArr, 16, 17]
                        }

                        if (question && question.type && questionTypesToShowArr.indexOf(question.type) !== -1 && this.checkQuestionConditions(question.id)) {
                            currentQuestion = question;
                            checked = true;
                        } else {
                            nextIdx = nextIdx + inc;
                        }


                    } else {
                        checked = true;
                    }
                }
            }
        }

        return currentQuestion;
    }
    findNextQuestionById = (back = false, includeSubtotalTotal = false, id, subtotalArr = []) => {
        if (!id) return null;
        let questionById = this.getQuestion(id);
        if (!questionById) return null;
        let currentQuestion;
        let intelligentReferral = this.state.referral?.intelligentReferral ? true : false;

        if (!intelligentReferral) {
            if (questionById && questionById.__idx > -1) {
                let inc = back ? -1 : 1
                let nextIdx = questionById.__idx + inc;
                let checked = false;
                while (!checked) {
                    if (this.state.questions[nextIdx]) {
                        let question = this.getQuestion(this.state.questions[nextIdx])

                        let questionTypesToShowArr = [...questionTypesToShow];
                        if (includeSubtotalTotal) {
                            questionTypesToShowArr = [...questionTypesToShowArr, 16, 17]
                        }
                        if (question && question.type && questionTypesToShowArr.indexOf(question.type) !== -1) {
                            currentQuestion = question;
                            checked = true;
                        } else {
                            nextIdx = nextIdx + inc;
                        }
                    } else {
                        checked = true;
                    }
                }
            }
        } else {
            if (questionById?.__idx > -1) {
                let inc = back ? -1 : 1
                let nextIdx = questionById.__idx + inc;
                let checked = false;
                while (!checked) {
                    if (this.state.questions[nextIdx]) {
                        let question = this.getQuestion(this.state.questions[nextIdx])
                        let questionTypesToShowArr = [...questionTypesToShow];
                        if (includeSubtotalTotal) {
                            questionTypesToShowArr = [...questionTypesToShowArr, 16, 17]
                        }
                        if (question && question.type && questionTypesToShowArr.indexOf(question.type) !== -1 && this.checkQuestionConditions(question.id, subtotalArr)) {
                            currentQuestion = question;
                            checked = true;
                        } else {
                            nextIdx = nextIdx + inc;
                        }


                    } else {
                        checked = true;
                    }
                }
            }
        }

        return currentQuestion;
    }

    checkQuestionConditions = (questionId, subtotalArr = []) => {
        let question = this.getQuestion(questionId);
        if (!question) return false;

        let subtotalAnswers = {};
        if (subtotalArr && subtotalArr.length) {

            for (let i = 0; i < subtotalArr.length; i++) {
                let subtotalQuestion = this.getQuestion(subtotalArr[i]);
                if (subtotalQuestion?.type === 16) {
                    let index = this.totalSubtotal?.findIndex(el => el.id === subtotalQuestion.id)
                    if (index > -1) {
                        let sum = this.totalSubtotal[index]?.sum;
                        if (subtotalQuestion?.blocks?.length) {
                            let answerId;
                            for (let i = 0; i < subtotalQuestion.blocks.length; i++) {
                                if (sum >= subtotalQuestion.blocks[i]?.start && sum <= subtotalQuestion.blocks[i]?.end) {
                                    answerId = subtotalQuestion.blocks[i].id;
                                    break;
                                }
                            }
                            if (answerId) {
                                subtotalAnswers[subtotalArr[i]] = answerId;
                            }
                        }
                    }
                }
            }
        }


        let answers = this.state.answers;

        if (question && question.conditions && question.conditions.length && answers) {
            for (let i = 0; i < question.conditions.length; i++) {
                if (!question.conditions[i].conditionQuestion) {
                    continue;
                }
                let conditionQuestion = this.getQuestion(question.conditions[i].conditionQuestion);
                let answer = answers[conditionQuestion.id];

                if (!answer) {
                    if (subtotalAnswers[conditionQuestion.id]) {
                        answer = subtotalAnswers[conditionQuestion.id];
                    }
                }


                if (!conditionQuestion || (conditionQuestion && !answer) || (conditionQuestion && answer && !this.checkQuestionAnswered(answer, conditionQuestion.type, conditionQuestion.id) && !subtotalAnswers[conditionQuestion.id])) {
                    continue;
                }

                if (conditionQuestion.type == 1 || conditionQuestion.type == 16 || conditionQuestion.type == 17) {
                    if (answer === question.conditions[i].conditionAnswer) {
                        return true;
                    }
                } else if (conditionQuestion.type == 2) {
                    if (answer && (answer.indexOf(question.conditions[i].conditionAnswer) !== -1 || answer.filter(ans => ans.match(question.conditions[i].conditionAnswer)).length)) {
                        return true;
                    }

                } else if (conditionQuestion.type == 3 || conditionQuestion.type == 5 || conditionQuestion.type == 6 || conditionQuestion.type == 7 || conditionQuestion.type == 8 || conditionQuestion.type == 9 || conditionQuestion.type == 12 || conditionQuestion.type == 13 || conditionQuestion.type == 14 || conditionQuestion.type == 18 || conditionQuestion.type == 19) {
                    if (answer) {
                        return true;
                    }
                } else if (conditionQuestion.type == 10 || conditionQuestion.type == 15) {
                    return true;
                }
            }

        } else {
            return true;
        }




        return false;
    }



    questionToShow = () => {
        let arr = [];
        let page = this.state.questionPage;
        let entries = this.state.questionPageEntries;

        let numberOfQuestions = page * entries + entries;
        if (this.state.currentQuestion && this.state.currentQuestion.id && this.state.questionsQueue.indexOf(this.state.currentQuestion.id) === -1) {
            numberOfQuestions--;
            arr.push(this.state.currentQuestion.id)
        }
        let questionsQueue = this.state.questionsQueue ? this.state.questionsQueue : [];

        if (questionsQueue.length < numberOfQuestions) {
            arr = [...arr, ...questionsQueue]
        } else if (questionsQueue.length >= numberOfQuestions) {
            let indexes = questionsQueue.map((item) => {
                let obj = {
                    id: item,
                    idx: this.state.questionsIndexes[item]
                }
                return obj;
            })
            if (!this.props.preview) {
                indexes = indexes.sort((a, b) => b.idx - a.idx);
            } else {
                indexes = indexes.sort((a, b) => a.idx - b.idx);
            }
            for (let i = 0; i < numberOfQuestions; i++) {
                if (indexes[i] && indexes[i].id)
                    arr.push(indexes[i].id)
            }

        }
        if (this.state.currentQuestion && this.state.answers[this.state.currentQuestion.id] && this.checkQuestionAnswered(this.state.answers[this.state.currentQuestion.id], this.state.currentQuestion.type, this.state.currentQuestion.id)) {
            let findNextQuestion = this.findNextQuestion(false, false);
            if (findNextQuestion && findNextQuestion.type === 4) {
                arr.push(findNextQuestion.id)
            }
        }

        return arr;
    }
    getPreviousAnsweredQuestion = (id) => {
        let nextButtonClicked = this.state.nextButtonClicked ? this.state.nextButtonClicked : {};
        let questionsQueue = [];

        if (Object.keys(nextButtonClicked).length) {
            for (var key in nextButtonClicked) {
                questionsQueue.push(key)
            }
        } else if (Object.keys(this.state.skippedQuestions).length) {
            for (var key in this.state.skippedQuestions) {
                questionsQueue.push(key)
            }
        }
        let indexes = questionsQueue.map((item) => {
            let obj = {
                id: item,
                idx: this.state.questionsIndexes[item]
            }
            return obj;
        })
        indexes = indexes.sort((a, b) => b.idx - a.idx);
        let prevQuestion;
        if (indexes.filter(el => el?.id === id).length) {
            for (let i = 0; i < indexes.length; i++) {
                if (indexes[i].id === id && indexes[i + 1]?.id) {
                    prevQuestion = indexes[i + 1].id;
                    break;
                }
            }
        } else {
            prevQuestion = indexes[0].id;
        }
        return prevQuestion;

    }

    scrollToEnd = () => {
        if (this.chatWrapper) {
            let typeFieldRef = this.typeFieldRef;
            let offset = 0;
            let typeFieldRefArr = [];
            if (Object.keys(typeFieldRef).length) {
                for (var key in typeFieldRef) {
                    typeFieldRefArr.push({
                        _idx: this.state.questionsIndexes && this.state.questionsIndexes[key] ? this.state.questionsIndexes[key] : 0,
                        scrollHeight: typeFieldRef[key]?.scrollHeight,
                        id: key
                    })
                }
            }
            typeFieldRefArr = typeFieldRefArr.sort((a, b) => b._idx - a._idx)

            let currentQuestionId;
            if (this.state.currentQuestion?.type && questionTypesToShow.indexOf(this.state.currentQuestion.type) !== -1) {
                currentQuestionId = this.state.currentQuestion?.id;
            }

            if (currentQuestionId) {
                for (let i = 0; i < typeFieldRefArr.length; i++) {
                    if (typeFieldRefArr[i].scrollHeight) {
                        offset += typeFieldRefArr[i].scrollHeight;
                    }
                    if (typeFieldRefArr[i].id === currentQuestionId) {
                        break;
                    }
                }
            }

            this.chatWrapper.scrollTop = this.chatWrapper.scrollHeight - offset;
        }
    }


    checkSubmit = () => {
        let findNextQuestion = this.findNextQuestion(false, true);
        if (findNextQuestion && findNextQuestion.type === 16) {
            this.nextQuestion(() => {
                this.checkSubmit()
            })
        } else if (findNextQuestion && findNextQuestion.type === 17) {
            let index = this.totalSubtotal?.findIndex(el => el.id === findNextQuestion.id)
            if (index > -1) {
                let sum = this.totalSubtotal[index]?.totalSum;
                if (findNextQuestion?.blocks?.length) {
                    let answerId;
                    for (let i = 0; i < findNextQuestion.blocks.length; i++) {
                        if (sum >= findNextQuestion.blocks[i]?.start && sum <= findNextQuestion.blocks[i]?.end) {
                            answerId = findNextQuestion.blocks[i].id;
                            break;
                        }
                    }
                    if (answerId) {
                        this.answerQuestion(findNextQuestion.id, answerId, () => {
                            this.submitForm()
                        })
                    }
                }
            }
        } else {
            if (this.state.currentQuestion?.type === 15 && !this.state.answers[this.state.currentQuestion?.id]) {
                let answers = this.state.answers;
                answers[this.state.currentQuestion.id] = true;
                this.setState({ answers }, () => {
                    this.submitForm()
                })
            } else {
                this.submitForm()
            }

        }
    }
    generateReferral = () => {

    }

    submitForm = () => {
        this.setState({ submitClicked: true }, () => {
            if (this.props.previewAnswerForm) {
                this.checkShowButtons()
            }
        })

        if (this.props.submitAction) {
            this.setState({ __submitProcessing: true }, () => {

                let data = {
                    answers: this.state.answers,
                    sections: this.state.sections,
                }


                this.props.submitAction(data, () => {
                    this.setState({ __submitProcessing: null })
                })
            })
        }

    }

    lastQuestionAnswered = () => {
        let nextButtonClicked = this.state.nextButtonClicked ? this.state.nextButtonClicked : {};
        let questionsQueue = [];

        if (Object.keys(nextButtonClicked).length) {
            for (var key in nextButtonClicked) {
                questionsQueue.push(key)
            }
        }

        let indexes = questionsQueue.map((item) => {
            let question = this.getQuestion(item);
            let obj = {
                id: item,
                idx: this.state.questionsIndexes[item],
                type: question && question.type ? question.type : null
            }
            return obj;
        })
        indexes = indexes.sort((a, b) => b.idx - a.idx);

        let lastVisibleQuestion;
        for (let i = 0; i < indexes.length; i++) {
            if (questionTypesToShow.indexOf(indexes[i].type) !== -1) {
                lastVisibleQuestion = indexes[i].id;
                break;
            }
        }
        return lastVisibleQuestion;
    }



    render() {
        let currentQuestion = this.state.currentQuestion;

        let questionToShow = this.questionToShow();
        if (!questionToShow) {
            questionToShow = [];
        }
        let showButtons = this.state.showButtons;

        let divClassName = "panel referral-section referral-section-full-screen answer-forms-wrap";
        if (this.props.inModal) {
            divClassName = "panel referral-section referral-section-full-screen answer-forms-wrap answer-component-modal";
        }
        if (this.props.forPrint) {
            divClassName = "panel referral-section referral-section-full-screen answer-forms-wrap for-print-style-form";
            if (this.props.inModal) {
                divClassName = "panel referral-section referral-section-full-screen answer-forms-wrap answer-component-modal for-print-style-form";
            }
        }

        if (this.props.previewAnsweredChatForm) {
            divClassName += " for-print-style-form overflow-unset-important";
        }

        let isDoctor = this.props.uData && this.props.uData.userLevel == 20 && typeof window != 'undefined' && localStorage?.getItem?.('profile') != 'patient' ? true : false;

        let lastQuestionAnswered;
        if (this.state.submitClicked && questionTypesToShow.indexOf(currentQuestion?.type) === -1) {
            lastQuestionAnswered = this.lastQuestionAnswered();
        }

        return (
            <div className={divClassName} >
                {
                    this.state.referral?.name ?
                        <>
                            <h4>{this.state.referral.name}</h4>
                            {/* <p style={{ fontSize: 16, fontWeight: 500 }}>{this.state.referral?.description}</p> */}
                            <br /><br /><br />
                        </>
                        :
                        null
                }

                <div className="main-panel self-referral-panel" ref={(node) => this.chatWrapper = node}>
                    {
                        !this.props.preview ?
                            <Waypoint
                                onEnter={() => {
                                    let oldScrollHeight = 0;
                                    if (this.chatWrapper) {
                                        oldScrollHeight = this.chatWrapper.scrollHeight;
                                    }

                                    if (this.state.questionsQueue && this.state.questionsQueue.length > this.state.questionPageEntries && this.state.questionPage * this.state.questionPageEntries <= this.state.questionsQueue.length) {
                                        this.setState({ questionPage: this.state.questionPage + 1 }, () => {
                                            if (oldScrollHeight) {
                                                this.chatWrapper.scrollTop = this.chatWrapper.scrollTop + (this.chatWrapper.scrollHeight - oldScrollHeight);
                                            }

                                        })
                                    }


                                }}
                            ></Waypoint>
                            :
                            null
                    }
                    {
                        this.state.sections?.map((section, sidx) => {
                            let cssStyle = {};
                            if (section.fontSize) {
                                cssStyle.fontSize = section.fontSize;
                            }
                            if (section.fontWeight) {
                                cssStyle.fontWeight = section.fontWeight;
                            }
                            if (section.fontStyle) {
                                cssStyle.fontStyle = section.fontStyle;
                            }
                            return (
                                <div className="referral-section-item referal-content-big">
                                    <div className="referal-content-container">


                                        {
                                            section.questions?.filter(question => questionToShow.indexOf(question.id) !== -1).map((question, qidx) => {

                                                if ((question.type === 17 || question.type === 16)) {
                                                    if (!isDoctor || !(this.props.previewAnsweredForm || this.props.previewAnsweredChatForm))
                                                        return;
                                                }
                                                if (!this.checkShowQuestion(question.id)) {
                                                    return;
                                                }

                                                let name = question.name;
                                                if (question.wikiLinks && question.wikiLinks) {
                                                    for (let i = 0; i < question.wikiLinks.length; i++) {
                                                        name = name.replace(question.wikiLinks[i].term, `<a href="#" widx="${i}">${question.wikiLinks[i].term}</a>`)
                                                    }
                                                }


                                                let questionCssStyle = {};
                                                if (question.fontSize) {
                                                    questionCssStyle.fontSize = question.fontSize;
                                                }
                                                if (question.fontWeight) {
                                                    questionCssStyle.fontWeight = question.fontWeight;
                                                }
                                                if (question.fontStyle) {
                                                    questionCssStyle.fontStyle = question.fontStyle;
                                                }


                                                return (
                                                    <div className={this.props.forPrintHtml ? "referal-container3 pb20" : "referal-container3"} key={question.id} ref={(node) => {
                                                        if (!this.typeFieldRef) {
                                                            this.typeFieldRef = {};
                                                        }
                                                        if (question?.id) {
                                                            this.typeFieldRef[question.id] = node;
                                                        }

                                                    }}>


                                                        <TypeFields
                                                            question={question}
                                                            questionCssStyle={questionCssStyle}
                                                            uData={this.props.uData}
                                                            lang={this.props.lang}
                                                            name={name}
                                                            currentQuestion={currentQuestion}
                                                            clinicGroup={this.props.clinicGroup}
                                                            answerQuestion={this.answerQuestion}
                                                            questionsQueue={this.state.questionsQueue ? this.state.questionsQueue : []}
                                                            answers={this.state.answers ? this.state.answers : {}}
                                                            sections={this.state.sections ? this.state.sections : []}
                                                            checkIfNextQuestionIsTotal={this.checkIfNextQuestionIsTotal}
                                                            setStateFunction={(states, callback) => {
                                                                this.setState(states, () => {
                                                                    this.checkShowButtons()
                                                                    if (callback) {
                                                                        callback()
                                                                    }
                                                                })
                                                            }}
                                                            getAnswer={(id) => {
                                                                return this.getAnswer(id);
                                                            }}
                                                            totalSubtotal={this.totalSubtotal}
                                                            disabled={!this.props.preview && !this.state.submitClicked && !this.props.previewAnsweredChatForm && (this.state.currentQuestion?.id === question.id || this.state.submitClicked) ? false : true}
                                                            preview={this.props.preview}
                                                            previewAnsweredChatForm={this.props.previewAnsweredChatForm}
                                                            previewAnsweredForm={this.props.previewAnsweredForm}
                                                            forPrint={this.props.forPrint}
                                                            suggestionIndex={this.state.suggestionIndex}
                                                            regions={this.state.regions}
                                                            question18Finished={this.state.question18Finished ? this.state.question18Finished : {}}
                                                        />
                                                        {
                                                            (showButtons && question.id === this.state.currentQuestion?.id && !this.props.previewAnsweredChatForm) || (this.state.submitClicked && lastQuestionAnswered && lastQuestionAnswered === question.id) ?
                                                                <div className='answer-form-buttons-wrap'>


                                                                    {
                                                                        showButtons.back ?
                                                                            <Button disabled={this.state.submitClicked && !this.props.previewAnswerForm} className='back-button' onClick={() => {
                                                                                this.backQuestion()
                                                                            }}>
                                                                                {this.props.backButtonText ? this.props.backButtonText.translate(this.props.lang) : 'Back'.translate(this.props.lang)}
                                                                            </Button>
                                                                            :
                                                                            null
                                                                    }
                                                                    {
                                                                        showButtons.skip ?
                                                                            <Button disabled={this.state.submitClicked} className='skip-button' onClick={() => {
                                                                                this.skipQuestion()
                                                                            }}>
                                                                                {'Skip'.translate(this.props.lang)}
                                                                            </Button>
                                                                            :
                                                                            null
                                                                    }
                                                                    {
                                                                        showButtons.next ?
                                                                            <Button disabled={this.state.submitClicked} className='next-button' onClick={() => {
                                                                                this.nextQuestion()
                                                                            }}>
                                                                                {'Next'.translate(this.props.lang)}
                                                                            </Button>
                                                                            :
                                                                            null
                                                                    }
                                                                    {
                                                                        showButtons.submit && !this.props.hideSubmitButton ?
                                                                            <Button disabled={this.state.submitClicked} className='submit-button' onClick={() => {
                                                                                this.checkSubmit()
                                                                            }}>
                                                                                {
                                                                                    this.state.__submitProcessing ? /*'Processing...'.translate(this.props.lang)*/
                                                                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                                            <Player
                                                                                                onEvent={() => {
                                                                                                    if (!this.state.__submitProcessing) this.stopAnimation();
                                                                                                }}
                                                                                                onStateChange={this.toggleVisibility}
                                                                                                ref={this.player} // set the ref to your class instance
                                                                                                autoplay={true}
                                                                                                loop={true}
                                                                                                controls={true}
                                                                                                src="https://assets8.lottiefiles.com/packages/lf20_ZSRKCF.json"
                                                                                                style={{ height: "30px", width: "30px" }}
                                                                                            ></Player>
                                                                                            {'Processing...'.translate(this.props.lang)}
                                                                                        </div>

                                                                                        :
                                                                                        this.props.submitButtonText ?
                                                                                            this.props.submitButtonText
                                                                                            :
                                                                                            'Submit'.translate(this.props.lang)
                                                                                }
                                                                            </Button>
                                                                            :
                                                                            null
                                                                    }
                                                                </div>
                                                                :
                                                                null
                                                        }





                                                    </div>

                                                )
                                            }
                                            )

                                        }

                                    </div>


                                </div>
                            )
                        })
                    }

                    {
                        this.props.preview ?
                            <Waypoint
                                onEnter={() => {
                                    let oldScrollHeight = 0;
                                    if (this.chatWrapper) {
                                        oldScrollHeight = this.chatWrapper.scrollHeight;
                                    }

                                    if (this.state.questionsQueue && this.state.questionsQueue.length > this.state.questionPageEntries && this.state.questionPage * this.state.questionPageEntries <= this.state.questionsQueue.length) {
                                        this.setState({ questionPage: this.state.questionPage + 1 }, () => {
                                            if (oldScrollHeight) {
                                                this.chatWrapper.scrollTop = this.chatWrapper.scrollTop + (this.chatWrapper.scrollHeight - oldScrollHeight);
                                            }

                                        })
                                    }


                                }}
                            ></Waypoint>
                            :
                            null
                    }
                </div>

            </div>
        );
    }
}



export default AnswerFormComponent;