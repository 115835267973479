import { isSocialSecurityNumberValid } from "./fields/socialSecurityNumber"

/**
* Validation
* @author   Milan Stanojevic
*/
export const maxLength = max => value =>
  value && value.length > max ? `Must be ${max} characters or less` : undefined

export const number = value => value && isNaN(Number(value)) ? 'Must be a number' : undefined
export const minValue = min => value =>
  value && value < min ? `Must be at least ${min}` : undefined
export const email = value =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ?
    'Invalid email address' : undefined
export const emailAddress = (message) => value => value && !/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/i.test(value) ? message : undefined

export const duplicateEmail = (message, check) => value =>  check && value ? message : undefined

export const phoneNumber = (min, message) => value =>
  value && value.length < min ? message : undefined

export const required = (message = 'Required') => {
  return (value) => value ? undefined : message;
}

export const positiveNumber = () => (value) => {
  if(value){
    if(isNaN(value)){
      return "Must be a number";
    }

    if(value < 0){
      return "Must be a positive number";
    }

    if(value % 1 !== 0){
      return "Must be a whole number";
    }
  }
  return undefined
}

export const minLength = (min, message = 'Required') => value => value && value.length < min ? message : undefined

export const fileUploading = (message = 'Required') => {
  return (value) => value == 'loading' ? message : undefined;
}

export const fileUploadingRequired = (message = 'Required') => {
  return (value) => value == 'loading' ? message : value ? undefined : message;
}

export const dateValidate = (message) => value => new Date(value) instanceof Date && !isNaN(value) ? message : undefined




export const checkSocialSecurityNumber = (message = 'Social security number is not valid!', country) => (value) => {
  if (!country) {
    if (typeof window !== 'undefined' && localStorage && localStorage?.getItem?.('country')) country = localStorage?.getItem?.('country');
    else country = 'sweden';
  }
  const isValid = isSocialSecurityNumberValid(value, country);
  if (isValid) return undefined;
  return message;
}
