import React, { Component } from 'react';

import Isvg from 'react-inlinesvg';
import chart from '../assets/svg/chart.svg';


export class LandingLoginButton extends Component {
    constructor(props) {
        super(props);

        this.state = {

        };
    }

    componentDidMount() {
        setTimeout(() => {
            if (this.props.data) {
                this.setState({
                    data: this.props.data
                })
            }
        }, 100)

    }

    componentDidUpdate(prevProps){
        if (JSON.stringify(prevProps.data) != JSON.stringify(this.props.data)){
            this.setState({
                data: this.props.data
            })
        }
    }

    render() {
        return (
            this.state.data ?
                this.props.uData ?
                    <div>
                        <span className="username">{this.props.uData.userData.name}</span>
                        <button className={'login-button-landing-page'} style={this.state.data.loginOutline ? { backgroundColor: 'transparent', border: `1px solid ${this.state.data.loginColor}`, color: this.state.data.loginColor } : { background: this.state.data.loginColor, border: `1px solid transparent`, color: 'white' }}
                            onMouseEnter={() => {
                                let data = this.state.data;
                                data.loginOutline = !this.state.data.loginOutline
                                this.setState({
                                    data
                                })
                            }}
                            onMouseLeave={() => {
                                let data = this.state.data;
                                data.loginOutline = !this.state.data.loginOutline
                                this.setState({
                                    data
                                })
                            }}
                            onClick={() => this.props.signOut()}>{'Logout'.translate(this.props.lang)}</button>
                    </div>
                    :
                    <button className={'login-button-landing-page'} style={this.state.data.loginOutline ? { backgroundColor: 'transparent', border: `1px solid ${this.state.data.loginColor}`, color: this.state.data.loginColor } : { background: this.state.data.loginColor, border: `1px solid transparent`, color: 'white' }}
                        onMouseEnter={() => {
                            let data = this.state.data;
                            data.loginOutline = !this.state.data.loginOutline
                            this.setState({
                                data
                            })
                        }}
                        onMouseLeave={() => {
                            let data = this.state.data;
                            data.loginOutline = !this.state.data.loginOutline
                            this.setState({
                                data
                            })
                        }}
                        onClick={() => {

                            this.setState({
                                loginBankIdModal: true
                            }, () => {
                                this.props.fetchBankId()
                            })

                        }}>{'Login'.translate(this.props.lang)}</button>
                :
                null
        )
    }
}

export default LandingLoginButton;