import React from 'react'

const Chat = ({width, height, className}) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width={width || "24"} height={height || "18"} viewBox="0 0 26.373 25.579">
  <g id="Group_171" data-name="Group 171" transform="translate(-35.852 -491.324)">
    <g id="Layer_x0020_1" transform="translate(-145.393 301.634)">
      <g id="_1664356987584" transform="translate(182.84 190.59)">
        <g id="Group_170" data-name="Group 170" transform="translate(6.388 10.652)">
          <line id="Line_12" data-name="Line 12" x1="0.013" y1="2.471" fill="none" stroke="#134563" strokeMiterlimit="2.613" strokeWidth="1.8"/>
          <line id="Line_13" data-name="Line 13" x1="0.013" y1="2.471" transform="translate(5.544)" fill="none" stroke="#134563" strokeMiterlimit="2.613" strokeWidth="1.8"/>
          <line id="Line_14" data-name="Line 14" x1="0.013" y1="2.471" transform="translate(11.088)" fill="none" stroke="#134563" strokeMiterlimit="2.613" strokeWidth="1.8"/>
        </g>
        <path id="Path_217" data-name="Path 217" d="M194.838,190.59a11.879,11.879,0,1,1,0,23.758c-3.017,0-12,.018-12,.018s2.684-4.452,2.113-5.307a11.881,11.881,0,0,1,9.885-18.469Z" transform="translate(-182.84 -190.59)" fill="none" stroke="#134563" strokeMiterlimit="2.613" strokeWidth="1.8" fillRule="evenodd"/>
      </g>
    </g>
  </g>
</svg>

  )
}

export default Chat