import React, { Component } from 'react';
import Page from '../../containers/page';

import {
    Container,
    Row,
    Col,
    FormGroup, Label, Input, Button, UncontrolledAlert, Alert, Modal, ModalHeader, ModalBody, ModalFooter

} from 'reactstrap';

import { Rnd } from 'react-rnd';

import Isvg from "react-inlinesvg";
import { formValueSelector, change, isDirty, submit } from 'redux-form';
import { connect } from 'react-redux';

import FormBuilder from '../../components/forms/formBuilder';
import FormBuilderSecond from '../../components/forms/formBuilderSecond';

import { required, positiveNumber } from '../../components/forms/validation';
import { fileUploading } from '../../components/forms/validation';
import { fileUploadingRequired } from '../../components/forms/validation';


import ListBuilder from '../../components/listBuilder';
import DeleteModal from '../../components/deleteModal';
import { getSearchParams, generateSearchLink, generateSearchLinkMultiple } from '../../helpers/linkHelper';
import { API_ENDPOINT } from '../../constants';
import ErrorModal from '../../components/errorModal';
import { Link } from 'react-router-dom';

import { Player, Controls } from "@lottiefiles/react-lottie-player";
import Search from "../../components/search";
import profileUser from "../../assets/images/user.png";
import notification from "../../assets/svg/notification.svg";
import link from "../../assets/svg/link-new-tab.svg";

import Iframe from 'react-iframe';
import Checkbox from '../../components/forms/fields/checkbox';
import infoIcon from '../../assets/svg/info-icon.svg';

import defaultJson from '../landingBuilder/default.json'
import defaultInformationJson from '../landingBuilder/defaultInformation.json'
import defaultLoginJson from '../landingBuilder/defaultLogin.json'
import { v4 as uuidv4 } from 'uuid';
import LandingBuilder from './landingBuilder'
import warrning from '../../assets/svg/warrning.svg';
import hover1 from "../../assets/images/hover1.png";
import hover2 from "../../assets/images/hover2.png";
import hover3 from "../../assets/images/hover3.png";
import hover4 from "../../assets/images/hover4.png";
import hover5 from "../../assets/images/hover5.png";
import hover6 from "../../assets/images/hover6.png";
import ColorPicker from '../../components/forms/fields/colorpicker'

import moreIcon from '../../assets/svg/more.svg';
import testIcon from "../../assets/svg/garbage.svg";
import userIcon from "../../assets/images/profile.png";

import stetoscopeIcon from '../../assets/svg/a-stetoscope.svg';
import calendarIcon from "../../assets/svg/a-calendar.svg";
import documentIcon from "../../assets/svg/a-document.svg";
import eyeIcon from "../../assets/svg/a-eye.svg";
import InfoModal from '../../components/infoModal';
import { defaultError } from '../../components/TwoFactorAuthModal';
import SuccessIcon from '../../icons/SuccessIcon';
import ErrorIcon from '../../icons/ErrorIcon';
import WebBookingOffset from '../../components/forms/fields/webBookingOffset';

var Color = function Color(hexVal) { //define a Color class for the color objects
    this.hex = hexVal;
};

const constructColor = function (colorObj) {
    var hex = colorObj.hex.substring(1);
    /* Get the RGB values to calculate the Hue. */
    var r = parseInt(hex.substring(0, 2), 16) / 255;
    var g = parseInt(hex.substring(2, 4), 16) / 255;
    var b = parseInt(hex.substring(4, 6), 16) / 255;

    /* Getting the Max and Min values for Chroma. */
    var max = Math.max.apply(Math, [r, g, b]);
    var min = Math.min.apply(Math, [r, g, b]);


    /* Variables for HSV value of hex color. */
    var chr = max - min;
    var hue = 0;
    var val = max;
    var sat = 0;


    if (val > 0) {
        /* Calculate Saturation only if Value isn't 0. */
        sat = chr / val;
        if (sat > 0) {
            if (r == max) {
                hue = 60 * (((g - min) - (b - min)) / chr);
                if (hue < 0) {
                    hue += 360;
                }
            } else if (g == max) {
                hue = 120 + 60 * (((b - min) - (r - min)) / chr);
            } else if (b == max) {
                hue = 240 + 60 * (((r - min) - (g - min)) / chr);
            }
        }
    }
    colorObj.chroma = chr;
    colorObj.hue = hue;
    colorObj.sat = sat;
    colorObj.val = val;
    colorObj.luma = 0.3 * r + 0.59 * g + 0.11 * b;
    colorObj.red = parseInt(hex.substring(0, 2), 16);
    colorObj.green = parseInt(hex.substring(2, 4), 16);
    colorObj.blue = parseInt(hex.substring(4, 6), 16);
    return colorObj;
};

const sortColorsByLuma = function (colors) {
    return colors.sort(function (a, b) {
        return a.luma - b.luma;
    });
};

const mapHex = function (color) {
    return color.hex;
}

const outputColors = function (hexArray) {
    var colors = [];
    for (let i = 0; i < hexArray.length; i++) {
        var color = new Color(hexArray[i]);
        constructColor(color);
        colors.push(color);

    }

    sortColorsByLuma(colors);

    return colors.map(mapHex);
};



function parseJSON(response) {
    return response.json().then(json => {
        return {
            result: json,
            status: response.status
        }
    })
}


/**
* Group settings page
* @author   Milan Stanojevic
*/
class ClinicData extends Component {
    constructor(props) {
        super(props);
        this.player = React.createRef();
        this.player2 = React.createRef();
        this.state = {
            eConnectExternalText: '',
            num: 0,
            showPlayer: false,
            showPlayer2: false,
            showPlayerStripe: false,
            showPlayer2Stripe: false,
            ...props.initialData,
            form: false,
            useState: false,
            params: {
                entries: 10,
                page: 0,
                'query-group': null
            },
            items: [],
            total: 0,
            loading: true,
            groups: [],
            languages: [],
            regions: [],
            showForm: false,
            tabIndex: 1,
            template: -1,
            type: 'bankid',
            bankIdRedirectUrl: null,
            templates: [],
            // colorSchemeChanged: false,
            // selectedColorScheme: null,

            colorScheme: [
                {
                    _id: 1,
                    backgroundColor: '#F6F6F6',
                    primaryLight: '#F6F6F6',
                    primaryLightText: '#3A3F56',
                    primaryDark: '#129A7F',
                    primaryDarkText: '#ffffff',
                    secondaryLight: '#ffffff',
                    secondaryLightText: '#3A3F56',
                    secondaryDark: '#129A7F',
                    secondaryDarkText: '#ffffff',
                    image: hover1


                },
                {
                    _id: 2,
                    backgroundColor: '#F6F6F6',
                    primaryLight: '#E6F0FC',
                    primaryLightText: '#3A3F56',
                    primaryDark: '#3873AF',
                    primaryDarkText: '#ffffff',
                    secondaryLight: '#E6F0FC',
                    secondaryLightText: '#3A3F56',
                    secondaryDark: '#3873AF',
                    secondaryDarkText: '#ffffff',
                    image: hover2
                },
                {
                    _id: 3,
                    backgroundColor: '#F0F1F6',
                    primaryDark: '#B03679',
                    primaryDarkText: '#ffffff',
                    primaryLightText: '#505C82',
                    primaryLight: '#ffffff',
                    secondaryDark: '#283092',
                    secondaryDarkText: '#ffffff',
                    secondaryLightText: '#283092',
                    secondaryLight: '#ffffff',
                    image: hover3

                },
                {
                    _id: 4,
                    backgroundColor: '#ffffff',
                    primaryDark: '#24578D',
                    primaryDarkText: '#ffffff',
                    primaryLightText: '#ffffff',
                    primaryLight: '#74B5DB',
                    secondaryDark: '#74B5DB',
                    secondaryDarkText: '#ffffff',
                    secondaryLightText: '#3A3F56',
                    secondaryLight: '#EBF1F8',
                    image: hover4

                },
                {
                    _id: 5,
                    backgroundColor: '#F6F6F6',
                    primaryDark: '#4AC4D0',
                    primaryDarkText: '#ffffff',
                    primaryLightText: '#777A95',
                    primaryLight: '#ffffff',
                    secondaryDark: '#4AC4D0',
                    secondaryDarkText: '#ffffff',
                    secondaryLightText: '#2A2F35',
                    secondaryLight: '#FFFFFF',
                    image: hover5

                },
                {
                    _id: 6,
                    backgroundColor: '#ffffff',
                    primaryDark: '#EE786C',
                    primaryDarkText: '#ffffff',
                    primaryLightText: '#2A2F35',
                    primaryLight: '#FEEEEE',
                    secondaryDark: '#EE786C',
                    secondaryDarkText: '#ffffff',
                    secondaryLightText: '#2A2F35',
                    secondaryLight: '#FEEEEE',
                    image: hover6

                },
                {
                    _id: 7,
                    backgroundColor: '',
                    primaryDark: '',
                    primaryDarkText: '',
                    primaryLightText: '',
                    primaryLight: '',
                    secondaryDark: '',
                    secondaryDarkText: '',
                    secondaryLightText: '',
                    secondaryLight: '',
                    image: null

                }
            ],
            updateLanding: false,
            sortedColors: [],
            loginButtonPreview: false,




        };
    }

    showPlayer = () => {
        this.setState({
            showPlayer: true,
        });
    };

    hidePlayer = () => {
        this.setState({
            showPlayer: false,
        });
    };

    get = () => {
        this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));

        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0, 'query-group': null })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

        for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
            this.props.loadDataWithQuery[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0, 'query-group': null })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })

        }






    }
    getTemplates = () => {
        fetch(API_ENDPOINT + '/data-managment/landing-builder-styles/get/' + this.props.selectedGroup, {
            method: 'GET',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
        }).then(parseJSON).then(({ result, status }) => {
            if (result) {
                this.setState({
                    templates: result
                })
            }


        })
    }

    componentDidMount() {
        // this.props.abortAction()
        this.getClinicGroupSettings();

        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }

        this.get();

        if (this.state.colorScheme && this.state.colorScheme.length) {
            let sortedColors = [];
            for (let i = 0; i < this.state.colorScheme.length; i++) {
                let obj = [
                    this.state.colorScheme[i].backgroundColor,
                    this.state.colorScheme[i].primaryLight,
                    this.state.colorScheme[i].primaryLightText,
                    this.state.colorScheme[i].primaryDark,
                    this.state.colorScheme[i].primaryDarkText,
                    this.state.colorScheme[i].secondaryLight,
                    this.state.colorScheme[i].secondaryLightText,
                    this.state.colorScheme[i].secondaryDark,
                    this.state.colorScheme[i].secondaryDarkText,
                ];

                obj = outputColors(obj);

                sortedColors.push(obj);

            }
            this.setState({ sortedColors })
        }

        // fetch(API_ENDPOINT + '/data-managment/landing-builder-styles/get/all', {
        //     method: 'GET',
        //     headers: {
        //         'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
        //         'content-type': 'application/json'
        //     },
        // }).then(parseJSON).then(({ result, status }) => {
        //     if (result) {
        //         this.setState({
        //             templates: result
        //         })
        //     }


        // })
        this.getTemplates()


        let selectedGroupIdx = -1;
        if (this.props.uData) {
            if (this.props.selectedGroup && this.props.uData?.clinicGroups?.length) {
                for (let i = 0; i < this.props.uData.clinicGroups.length; i++) {
                    if (this.props.uData.clinicGroups[i]._id == this.props.selectedGroup) {
                        selectedGroupIdx = i;
                        this.setState({
                            loginButtonPreview: this.props.uData.clinicGroups[i] && this.props.uData.clinicGroups[i].landingConfig ? this.props.uData.clinicGroups[i].landingConfig.loginOutline : false
                        })
                        break;
                    }
                }
            }
        }
        this.getLandingStyle()
        // let landingStyle = {
        //     landingJSON: this.props.uData.clinicGroups[selectedGroupIdx] && this.props.uData.clinicGroups[selectedGroupIdx].landingJSON_v2 ? this.props.uData.clinicGroups[selectedGroupIdx].landingJSON_v2 : defaultJson,
        //     informationJSON: this.props.uData.clinicGroups[selectedGroupIdx] && this.props.uData.clinicGroups[selectedGroupIdx].informationJSON_v2 ? this.props.uData.clinicGroups[selectedGroupIdx].informationJSON_v2 : defaultInformationJson,
        //     loginJSON: this.props.uData.clinicGroups[selectedGroupIdx] && this.props.uData.clinicGroups[selectedGroupIdx].loginJSON_v2 ? this.props.uData.clinicGroups[selectedGroupIdx].loginJSON_v2 : defaultLoginJson
        // }
        if (this.props.uData.clinicGroups && this.props.uData.clinicGroups[selectedGroupIdx] && this.props.uData.clinicGroups[selectedGroupIdx].colorScheme) {
            if (this.state.colorScheme.filter(item => item._id == this.props.uData.clinicGroups[selectedGroupIdx].colorScheme._id).length) {
                //custom colors update
                if (this.props.uData.clinicGroups[selectedGroupIdx].colorScheme._id == 7) {
                    let obj = {
                        ...this.props.uData.clinicGroups[selectedGroupIdx].colorScheme,
                    }
                    let colorScheme = this.state.colorScheme
                    let idx = colorScheme.findIndex(item => item._id == 7)
                    colorScheme.splice(idx, 1, obj)
                    this.setState({
                        colorScheme,
                    }, () => {
                        let sortedColors = [];
                        for (let i = 0; i < this.state.colorScheme.length; i++) {
                            let obj = [
                                this.state.colorScheme[i].backgroundColor,
                                this.state.colorScheme[i].primaryLight,
                                this.state.colorScheme[i].primaryLightText,
                                this.state.colorScheme[i].primaryDark,
                                this.state.colorScheme[i].primaryDarkText,
                                this.state.colorScheme[i].secondaryLight,
                                this.state.colorScheme[i].secondaryLightText,
                                this.state.colorScheme[i].secondaryDark,
                                this.state.colorScheme[i].secondaryDarkText,
                            ];

                            if (this.state.colorScheme[i]._id != 7)
                                obj = outputColors(obj);

                            sortedColors.push(obj);

                        }
                        this.setState({ sortedColors })
                    })
                } else if (this.props.uData.clinicGroups[selectedGroupIdx].customColorScheme) {
                    let obj = {
                        ...this.props.uData.clinicGroups[selectedGroupIdx].customColorScheme
                    }
                    let colorScheme = this.state.colorScheme
                    let idx = colorScheme.findIndex(item => item._id == 7)
                    colorScheme.splice(idx, 1, obj)
                    this.setState({
                        colorScheme,
                    }, () => {
                        let sortedColors = [];
                        for (let i = 0; i < this.state.colorScheme.length; i++) {
                            let obj = [
                                this.state.colorScheme[i].backgroundColor,
                                this.state.colorScheme[i].primaryLight,
                                this.state.colorScheme[i].primaryLightText,
                                this.state.colorScheme[i].primaryDark,
                                this.state.colorScheme[i].primaryDarkText,
                                this.state.colorScheme[i].secondaryLight,
                                this.state.colorScheme[i].secondaryLightText,
                                this.state.colorScheme[i].secondaryDark,
                                this.state.colorScheme[i].secondaryDarkText,
                            ];

                            if (this.state.colorScheme[i]._id != 7)
                                obj = outputColors(obj);

                            sortedColors.push(obj);

                        }
                        this.setState({ sortedColors })
                    })
                }
                if (this.props.uData.clinicGroups[selectedGroupIdx].statusBarColor) {
                    this.setState({ statusBarColor: this.props.uData.clinicGroups[selectedGroupIdx].statusBarColor })
                }
                this.setState({ selectedColorScheme: this.state.colorScheme.filter(item => item._id == this.props.uData.clinicGroups[selectedGroupIdx].colorScheme._id)[0]._id })
            }
        }


        // this.setState({
        //     landingStyle
        // })
        if (this.props.uData.clinicGroups && this.props.uData.clinicGroups[selectedGroupIdx] && this.props.uData.clinicGroups[selectedGroupIdx].agentId) {
            this.setState({
                agentId: this.props.uData.clinicGroups[selectedGroupIdx].agentId
            })
        }

        if (this.props.uData.clinicGroups && this.props.uData.clinicGroups[selectedGroupIdx] && this.props.uData.clinicGroups[selectedGroupIdx].apiKey) {
            this.setState({
                apiKey: this.props.uData.clinicGroups[selectedGroupIdx].apiKey
            })
        }

        if (this.props.uData.clinicGroups && this.props.uData.clinicGroups[selectedGroupIdx] && this.props.uData.clinicGroups[selectedGroupIdx].bankAccount) {
            this.setState({
                bankAccount: this.props.uData.clinicGroups[selectedGroupIdx].bankAccount
            })
        }

        if (this.props.uData.clinicGroups && this.props.uData.clinicGroups[selectedGroupIdx] && this.props.uData.clinicGroups[selectedGroupIdx].bookingSettings) {
            this.setState({
                bookingSettings: this.props.uData.clinicGroups[selectedGroupIdx].bookingSettings
            })
        }
        if (this.props.uData.clinicGroups && this.props.uData.clinicGroups[selectedGroupIdx] && this.props.uData.clinicGroups[selectedGroupIdx].webdoc) {
            let data = {
                autoVisit: this.props.uData.clinicGroups[selectedGroupIdx].webdoc?.autoVisit
            }
            this.setState({
                webDocSettings: data
            })
        }




        this.setState({ showForm: true })

    }

    getLandingStyle = (reset = false) => {
        let selectedGroupIdx = -1;
        if (this.props.uData) {
            if (this.props.selectedGroup && this.props.uData?.clinicGroups?.length) {
                for (let i = 0; i < this.props.uData.clinicGroups.length; i++) {
                    if (this.props.uData.clinicGroups[i]._id == this.props.selectedGroup) {
                        selectedGroupIdx = i;
                        this.setState({
                            loginButtonPreview: this.props.uData.clinicGroups[i] && this.props.uData.clinicGroups[i].landingConfig ? this.props.uData.clinicGroups[i].landingConfig.loginOutline : false
                        })
                        break;
                    }
                }
            }
        }

        let landingStyle = {
            landingJSON: this.props.uData.clinicGroups[selectedGroupIdx] && this.props.uData.clinicGroups[selectedGroupIdx].landingJSON_v2 ? this.props.uData.clinicGroups[selectedGroupIdx].landingJSON_v2 : defaultJson,
            informationJSON: this.props.uData.clinicGroups[selectedGroupIdx] && this.props.uData.clinicGroups[selectedGroupIdx].informationJSON_v2 ? this.props.uData.clinicGroups[selectedGroupIdx].informationJSON_v2 : defaultInformationJson,
            loginJSON: this.props.uData.clinicGroups[selectedGroupIdx] && this.props.uData.clinicGroups[selectedGroupIdx].loginJSON_v2 ? this.props.uData.clinicGroups[selectedGroupIdx].loginJSON_v2 : defaultLoginJson
        }


        if (this.props.uData.clinicGroups[selectedGroupIdx] && this.props.uData.clinicGroups[selectedGroupIdx].footerSettings) {
            landingStyle.footerSettings = this.props.uData.clinicGroups[selectedGroupIdx].footerSettings;
        }


        this.setState({
            landingStyle: JSON.parse(JSON.stringify(landingStyle))
        }, () => {
            if (reset) {
                if (this.state.pageBuilderChanged) {
                    this.setState({ pageBuilderChanged: 'done' })
                }
            }
        })


    }

    componentWillUnmount() {
        clearTimeout(this.timer1);
    }

    componentDidUpdate(prevProps, prevState) {
        if ((!prevProps?.selectedGroup && this.props.selectedGroup) || (prevProps?.selectedGroup !== this.props.selectedGroup) || (prevState?.num !== this.state.num)) {
            this.getClinicGroupSettings();
        }

        if (prevProps[0].location.search != this.props[0].location.search) {
            this.setState({
                loading: true
            }, () => {
                this.get();

            })
        }

        if (prevProps.selectedGroup != this.props.selectedGroup) {
            this.getLandingStyle(true)
            this.getTemplates()

            this.setState({
                showForm: false
            }, () => {
                setTimeout(() => {
                    this.setState({ showForm: true })
                }, 100)
            })
            let selectedGroupIdx = -1;
            if (this.props.uData) {
                if (this.props.selectedGroup && this.props.uData?.clinicGroups?.length) {
                    for (let i = 0; i < this.props.uData.clinicGroups.length; i++) {
                        if (this.props.uData.clinicGroups[i]._id == this.props.selectedGroup) {
                            selectedGroupIdx = i;
                            break;
                        }
                    }
                }
            }
            let bookingSettings = this.props.uData.clinicGroups && this.props.uData.clinicGroups[selectedGroupIdx] && this.props.uData.clinicGroups[selectedGroupIdx].bookingSettings ? this.props.uData.clinicGroups[selectedGroupIdx].bookingSettings : null;
            this.setState({
                bookingSettings,
                bookingSettingsChanged: false
            })

        }
        if (prevProps.uData != this.props.uData) {
            let selectedGroupIdx = -1;
            if (this.props.uData) {
                if (this.props.selectedGroup && this.props.uData?.clinicGroups?.length) {
                    for (let i = 0; i < this.props.uData.clinicGroups.length; i++) {
                        if (this.props.uData.clinicGroups[i]._id == this.props.selectedGroup) {
                            selectedGroupIdx = i;
                            this.setState({
                                loginButtonPreview: this.props.uData.clinicGroups[i] && this.props.uData.clinicGroups[i].landingConfig ? this.props.uData.clinicGroups[i].landingConfig.loginOutline : false
                            })
                            break;
                        }
                    }
                }
            }


            if (this.props.uData.clinicGroups && this.props.uData.clinicGroups[selectedGroupIdx] && this.props.uData.clinicGroups[selectedGroupIdx].colorScheme) {
                if (this.state.colorScheme.filter(item => item._id == this.props.uData.clinicGroups[selectedGroupIdx].colorScheme._id).length) {
                    //custom colors update
                    if (this.props.uData.clinicGroups[selectedGroupIdx].colorScheme._id == 7) {
                        let obj = {
                            ...this.props.uData.clinicGroups[selectedGroupIdx].colorScheme,
                        }
                        let colorScheme = this.state.colorScheme
                        let idx = colorScheme.findIndex(item => item._id == 7)
                        colorScheme.splice(idx, 1, obj)
                        this.setState({
                            colorScheme,
                        }, () => {
                            let sortedColors = [];
                            for (let i = 0; i < this.state.colorScheme.length; i++) {
                                let obj = [
                                    this.state.colorScheme[i].backgroundColor,
                                    this.state.colorScheme[i].primaryLight,
                                    this.state.colorScheme[i].primaryLightText,
                                    this.state.colorScheme[i].primaryDark,
                                    this.state.colorScheme[i].primaryDarkText,
                                    this.state.colorScheme[i].secondaryLight,
                                    this.state.colorScheme[i].secondaryLightText,
                                    this.state.colorScheme[i].secondaryDark,
                                    this.state.colorScheme[i].secondaryDarkText,
                                ];

                                if (this.state.colorScheme[i]._id != 7)
                                    obj = outputColors(obj);

                                sortedColors.push(obj);

                            }
                            this.setState({ sortedColors })
                        })
                    } else if (this.props.uData.clinicGroups[selectedGroupIdx].customColorScheme) {
                        let obj = {
                            ...this.props.uData.clinicGroups[selectedGroupIdx].customColorScheme
                        }
                        let colorScheme = this.state.colorScheme
                        let idx = colorScheme.findIndex(item => item._id == 7)
                        colorScheme.splice(idx, 1, obj)
                        this.setState({
                            colorScheme,
                        }, () => {
                            let sortedColors = [];
                            for (let i = 0; i < this.state.colorScheme.length; i++) {
                                let obj = [
                                    this.state.colorScheme[i].backgroundColor,
                                    this.state.colorScheme[i].primaryLight,
                                    this.state.colorScheme[i].primaryLightText,
                                    this.state.colorScheme[i].primaryDark,
                                    this.state.colorScheme[i].primaryDarkText,
                                    this.state.colorScheme[i].secondaryLight,
                                    this.state.colorScheme[i].secondaryLightText,
                                    this.state.colorScheme[i].secondaryDark,
                                    this.state.colorScheme[i].secondaryDarkText,
                                ];

                                if (this.state.colorScheme[i]._id != 7)
                                    obj = outputColors(obj);

                                sortedColors.push(obj);

                            }
                            this.setState({ sortedColors })
                        })
                    }
                    if (this.props.uData.clinicGroups[selectedGroupIdx].statusBarColor) {
                        this.setState({ statusBarColor: this.props.uData.clinicGroups[selectedGroupIdx].statusBarColor })
                    }

                    this.setState({ selectedColorScheme: this.state.colorScheme.filter(item => item._id == this.props.uData.clinicGroups[selectedGroupIdx].colorScheme._id)[0]._id })
                }
            }

            if (this.props.uData.clinicGroups && this.props.uData.clinicGroups[selectedGroupIdx] && this.props.uData.clinicGroups[selectedGroupIdx].agentId) {
                this.setState({
                    agentId: this.props.uData.clinicGroups[selectedGroupIdx].agentId
                })
            }

            if (this.props.uData.clinicGroups && this.props.uData.clinicGroups[selectedGroupIdx] && this.props.uData.clinicGroups[selectedGroupIdx].apiKey) {
                this.setState({
                    apiKey: this.props.uData.clinicGroups[selectedGroupIdx].apiKey
                })
            }

            if (this.props.uData.clinicGroups && this.props.uData.clinicGroups[selectedGroupIdx] && this.props.uData.clinicGroups[selectedGroupIdx].bankAccount) {
                this.setState({
                    bankAccount: this.props.uData.clinicGroups[selectedGroupIdx].bankAccount
                })
            }
            if (this.props.uData.clinicGroups && this.props.uData.clinicGroups[selectedGroupIdx] && this.props.uData.clinicGroups[selectedGroupIdx].webdoc) {
                let data = {
                    autoVisit: this.props.uData.clinicGroups[selectedGroupIdx].webdoc?.autoVisit
                }
                this.setState({
                    webDocSettings: data
                })
            }



            if (!this.state.pageBuilderChanged) {
                this.getLandingStyle()
                this.setState({
                    pageBuilderChanged: 'done'
                })
            }

        }


        if (prevProps.uData != this.props.uData) {
            let selectedGroupIdx = -1;
            if (this.props.uData) {
                if (this.props.selectedGroup && this.props.uData?.clinicGroups?.length) {
                    for (let i = 0; i < this.props.uData.clinicGroups.length; i++) {
                        if (this.props.uData.clinicGroups[i]._id == this.props.selectedGroup) {
                            selectedGroupIdx = i;
                            break;
                        }
                    }
                }
            }
            if (JSON.stringify(prevProps.uData.clinicGroups) != JSON.stringify(this.props.uData.clinicGroups) && JSON.stringify(prevProps.uData.clinicGroups[selectedGroupIdx]) != JSON.stringify(this.props.uData.clinicGroups[selectedGroupIdx]) && JSON.stringify(prevProps.uData.clinicGroups[selectedGroupIdx].landingJSON_v2) != JSON.stringify(this.props.uData.clinicGroups[selectedGroupIdx].landingJSON_v2)) {

                this.getLandingStyle()
            }

        }
        if ((this.props.isDirty || this.props.isDirtySecond || this.state.pageBuilderChanged == 'open' || this.state.colorSchemeChanged || this.state.bookingSettingsChanged || this.state.webDocSettingsChanged) && !this.props.groupSettingsModal) {
            this.props.groupSettingsChanged()
        }

        if (this.props.loginOutline != prevProps.loginOutline) {
            this.setState({
                loginButtonPreview: this.props.loginOutline
            })
        }
    }


    updateSort = (field, type) => {
        if (this.state.useState) {
            let state = this.state.params;
            state.sortField = field;
            state.sortType = type;
            this.setState({
                params: state
            })
        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0 }, [{ name: 'sortField', value: field }, { name: 'sortType', value: type }], false));

        }
    }

    updateParams = (name, value, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };
                obj[name] = value;
                this.setState({ params: obj });
            } else {
                let obj = this.state.params;
                obj[name] = value;
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(generateSearchLink(this.props[0].location.search, { entries: 10, page: 0 }, name, value, restart));
        }
    }
    updateMultipleParams = (fields, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj });

            } else {
                let obj = this.state.params;
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0 }, fields, restart));
        }
    }

    stopAnimation = () => {
        this.player.current.play();
    };


    /*
       update = (data) => {
         data.groupId = this.props.selectedGroup;
 
         fetch(API_ENDPOINT + '/users/account/updateClinicData', {
             method: 'POST',
             headers: {
                 'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                 'content-type': 'application/json'
             },
             body: JSON.stringify(data)
         }).then(parseJSON).then(({ result, status }) => {
             if (result.error) {
                 this.setState({ error: result.error })
             } else {
                 this.setState({
                     message: result.message
                 })
 
                 this.props.verifyUser(() => {}, () => {
                     this.props.changeGroup(data.groupId)
                 });
             }
 
         })
 
 
     }
     */

    updateColorScheme = () => {
        let data = {
            colorScheme: this.state.colorScheme.filter(item => item._id == this.state.selectedColorScheme)[0],
            customColorScheme: this.state.colorScheme.filter(item => item._id == 7)[0],
            statusBarColor: this.state.statusBarColor
        }
        this.setState(
            {
                showPlayer: true,
            },
            () => {
                data.groupId = this.props.selectedGroup;
                data.clinic = this.props.selectedClinic;
                this.player.current.play();
                fetch(API_ENDPOINT + '/users/account/updateClinicData/colorScheme', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify(data)
                }).then(parseJSON).then(({ result, status }) => {
                    if (result.error) {
                        this.setState({
                            error: result.error,
                            showPlayer: false
                        })
                    } else {
                        this.setState({
                            message: result.message,
                            showPlayer: false,
                            showPlayer2: true,

                        }, () => {
                            setTimeout(() => {
                                this.setState({ showPlayer2: false });
                            }, 1000);
                        })

                        this.props.verifyUser(() => {
                            if (this.state.colorSchemeChanged && this.state.tabIndexCheck) {
                                this.setState({ showForm: null, tabIndex: this.state.tabIndexCheck, tabIndexCheck: false, colorSchemeChanged: false }, () => this.setState({ showForm: true }, () => this.props.abortAction()))
                            } else {
                                this.setState({ showForm: null, colorSchemeChanged: false }, () => this.setState({ showForm: true }, () => this.props.abortAction()))
                            }
                        }, () => {
                            this.props.changeGroup(data.groupId)

                        });
                        if (this.props.changeLinkModal && this.props.changeLinkModal.indexOf('group|') != -1) {
                            this.props.changeGroup(this.props.changeLinkModal.split('|')[1])
                            this.props.abortAction()
                        } else if (this.props.changeLinkModal && this.props.changeLinkModal.indexOf('clinic|') != -1) {
                            this.props.changeClinic(this.props.changeLinkModal.split('|')[1]);
                            this.props.abortAction()
                        } else if (this.props.changeLinkModal) {
                            this.props[0].history.push(this.props.changeLinkModal)
                            this.props.abortAction()
                        }


                    }

                })
            }
        )

    }

    getStripeAccountID = () => {

        let selectedGroupIdx = 0;
        if (this.props.selectedGroup && this.props.uData?.clinicGroups?.length) {
            for (let i = 0; i < this.props.uData.clinicGroups.length; i++) {
                if (this.props.uData.clinicGroups[i]._id == this.props.selectedGroup) {
                    selectedGroupIdx = i;
                    break;
                }
            }
        }

        let data = {
            groupId: this.props.selectedGroup,
            clinic: this.props.selectedClinic,
            stripeAccountID: this.props.uData.clinicGroups[selectedGroupIdx]?.stripeAccountID
        }
        this.setState(
            {
                showPlayerStripe: true,
            }, () => {

                this.player.current.play();
                fetch(API_ENDPOINT + '/stripe/account', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify(data)
                }).then(parseJSON).then(({ result, status }) => {
                    if (result.error) {
                        this.setState({
                            error: result.error,
                            showPlayerStripe: false
                        })
                    } else {

                        if (result && result.url) {
                            window.open(result.url)
                        }

                        this.setState({
                            showPlayerStripe: false,
                            showPlayer2Stripe: true,
                        }, () => {
                            setTimeout(() => {
                                this.setState({ showPlayer2Stripe: false });
                            }, 1000);
                        })


                    }

                })

            }
        )

    }

    updatePaymentSettings = (data) => {
        if (data && data.landingJSON_v2) { delete data.landingJSON_v2; }
        if (data && data.informationJSON_v2) { delete data.informationJSON_v2; }
        if (data && data.loginJSON_v2) { delete data.loginJSON_v2; }
        this.setState(
            {
                showPlayer: true,
            },
            () => {
                data.groupId = this.props.selectedGroup;
                data.clinic = this.props.selectedClinic;
                this.player.current.play();
                fetch(API_ENDPOINT + '/users/account/updateClinicData/paymentSettings', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify(data)
                }).then(parseJSON).then(({ result, status }) => {
                    if (result.error) {
                        this.setState({
                            error: result.error,
                            showPlayer: false
                        })
                    } else {
                        this.setState({
                            message: result.message,
                            showPlayer: false,
                            showPlayer2: true,
                        },
                            () => {
                                setTimeout(() => {
                                    this.setState({ showPlayer2: false });
                                }, 1000);
                            })
                        this.props.verifyUser(() => {
                            if (this.state.changeTabIndex) {
                                this.setState({ showForm: null, tabIndex: this.state.tabIndexCheck, tabIndexCheck: false, changeTabIndex: null }, () => this.setState({ showForm: true }, () => this.props.abortAction()))
                            } else {
                                this.setState({ showForm: null }, () => this.setState({ showForm: true }, () => this.props.abortAction()))
                            }
                        }, () => {
                            this.props.changeGroup(data.groupId)

                        });
                        if (this.props.changeLinkModal && this.props.changeLinkModal.indexOf('group|') != -1) {
                            this.props.changeGroup(this.props.changeLinkModal.split('|')[1])
                            this.props.abortAction()
                        } else if (this.props.changeLinkModal && this.props.changeLinkModal.indexOf('clinic|') != -1) {
                            this.props.changeClinic(this.props.changeLinkModal.split('|')[1]);
                            this.props.abortAction()
                        } else if (this.props.changeLinkModal) {
                            this.props[0].history.push(this.props.changeLinkModal)
                            this.props.abortAction()
                        }


                    }

                })
            }
        )

    }

    /**
   * Update clinic settings
   * @author   Milan Stanojevic
   * @Objectparam    {String} informationLink      link for information page
   * @Objectparam    {String} name                 group name
   * @Objectparam    {String} smsName              subject for sms
   * @Objectparam    {String} emailName            subject for email
   * @Objectparam    {String} privacyPolicy        privacyPolicy pdf
   * @Objectparam    {String} termsOfUse           termsOfUse pdf
   * @Objectparam    {String} accessibilityReport  accessibilityReport pdf
   * @Objectparam    {String} adress               clinic adress
   */
    update = (data) => {
        data.clinicProfileUpdate = true
        delete data.informationJSON;
        delete data.landingJSON;
        delete data.loginJSON;


        if (data && data.landingJSON_v2) { delete data.landingJSON_v2; }
        if (data && data.informationJSON_v2) { delete data.informationJSON_v2; }
        if (data && data.loginJSON_v2) { delete data.loginJSON_v2; }

        if (data.landingConfig.informationLink === 'https://') {
            delete data.landingConfig.informationLink
        }
        // if (data?.freeEventsStartOffset) {
        //     if (Number(data.freeEventsStartOffset) >= 15) {
        //         data.freeEventsStartOffset = Number(data.freeEventsStartOffset)
        //     } else {
        //         data.freeEventsStartOffset = 15;
        //     }
        // }
        this.setState(
            {
                showPlayer: true,
            },
            () => {
                data.groupId = this.props.selectedGroup;
                data.clinic = this.props.selectedClinic;
                this.player.current.play();
                fetch(API_ENDPOINT + '/users/account/updateClinicData', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify(data)
                }).then(parseJSON).then(({ result, status }) => {
                    if (result.error) {
                        this.setState({
                            error: result.error,
                            showPlayer: false
                        })
                    } else {
                        this.setState({
                            message: result.message,
                            showPlayer: false,
                            showPlayer2: true,
                        },
                            () => {
                                setTimeout(() => {
                                    this.setState({ showPlayer2: false });
                                }, 1000);
                            })
                        if (this.state.previewNeeded) {
                            this.setState({ previewNeeded: false });
                            window.open(window.location.origin + '/' + data.alias, '_blank')
                        }
                        this.props.verifyUser(() => {
                            if (this.state.changeTabIndex) {

                                this.setState({ showForm: null, tabIndex: this.state.tabIndexCheck, tabIndexCheck: false, changeTabIndex: null }, () => this.setState({ showForm: true }, () => this.props.abortAction()))
                            } else {
                                this.setState({ showForm: null }, () => this.setState({ showForm: true }, () => this.props.abortAction()))
                            }
                        }, () => {
                            this.props.changeGroup(data.groupId)

                        });
                        if (this.props.changeLinkModal && this.props.changeLinkModal.indexOf('group|') != -1) {
                            this.props.changeGroup(this.props.changeLinkModal.split('|')[1])
                            this.props.abortAction()
                        } else if (this.props.changeLinkModal && this.props.changeLinkModal.indexOf('clinic|') != -1) {
                            this.props.changeClinic(this.props.changeLinkModal.split('|')[1]);
                            this.props.abortAction()
                        } else if (this.props.changeLinkModal) {
                            this.props[0].history.push(this.props.changeLinkModal)
                            this.props.abortAction()
                            // this.setState({showForm: null}, () => this.setState({showForm: true}))
                        }


                    }

                })
            }
        )

    }

    copyFunction = () => {
        const copyText = this.input
        copyText.select();
        document.execCommand('copy');
    }
    copyFunction1 = () => {
        const copyText = this.input1
        copyText.select();
        document.execCommand('copy');
    }

    onDismiss = () => { this.setState({ message: null, error: null }) };

    refresh = () => {
        this.setState(prev => ({ ...prev, num: (prev?.num || 0) + 1 }));
    }

    getClinicGroupSettings = () => {
        this.setState({ clinicGroupSettings: null }, async () => {
            if (!this.props?.selectedGroup) return;
            const response = await fetch(API_ENDPOINT + '/data/clinic-group-settings/' + this.props.selectedGroup);
            if (!response || !response.ok) return;
            const result = await response.json();
            if (!result || result.error || !result.clinicGroupSettings) return;
            this.setState({ clinicGroupSettings: result.clinicGroupSettings }, () => {
                if (result.clinicGroupSettings?.eConnectExternalText) this.setState({ eConnectExternalText: result.clinicGroupSettings?.eConnectExternalText })
            });
        })
    }

    changeEConnectStatus = () => {
        if (!this.state.eConnectModal) return;
        this.setState({ loading: true }, async () => {
            const response = await fetch(API_ENDPOINT + '/e-connect/clinics/' + this.props.selectedGroup, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage?.getItem?.('authToken')}`
                },
                body: JSON.stringify({
                    action: this.state.eConnectModal
                })
            });
            if (!response) return this.setState({
                loading: false,
                error: defaultError,
                eConnectModal: null,
            }, () => {
                this.refresh();
            });

            const result = await response.json();
            if (!result || result.error) return this.setState({
                loading: false,
                error: result.error || defaultError,
                eConnectModal: null,
            }, () => {
                this.refresh();
            });

            this.setState({
                loading: false,
                error: null,
                // _updated: true,
                eConnectModal: null,
            }, () => {
                this.refresh();
            })
        })
    }

    changeEConnectExternalStatus = () => {
        if (!this.state.eConnectExternalModal) return;
        this.setState({ loading: true }, async () => {
            const response = await fetch(API_ENDPOINT + '/e-connect/clinics/' + this.props.selectedGroup, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage?.getItem?.('authToken')}`
                },
                body: JSON.stringify({
                    external: true,
                    action: this.state.eConnectExternalModal
                })
            });
            if (!response) return this.setState({
                loading: false,
                error: defaultError,
                eConnectExternalModal: null,
            }, () => {
                this.refresh();
            });

            const result = await response.json();
            if (!result || result.error) return this.setState({
                loading: false,
                error: result.error || defaultError,
                eConnectExternalModal: null,
            }, () => {
                this.refresh();
            });

            this.setState({
                loading: false,
                error: null,
                // _updated: true,
                eConnectExternalModal: null,
            }, () => {
                this.refresh();
            })
        })
    }

    changeEConnectExtText = () => {
        if (!this.state.clinicGroupSettings) return;
        const clinicGroupSettings = this.state.clinicGroupSettings;

        let obj;
        if (!clinicGroupSettings?.eConnect || clinicGroupSettings?.eConnectTempDisabled || clinicGroupSettings?.eConnectConfig?.externalDisabled) {
            if (!clinicGroupSettings?.eConnectConfig?.disabledTitle?.se?.trim?.() || !clinicGroupSettings?.eConnectConfig?.disabledTitle?.en?.trim?.() || !clinicGroupSettings?.eConnectConfig?.disabledText?.se?.trim?.() || !clinicGroupSettings?.eConnectConfig?.disabledText?.en?.trim?.()) return;

            obj = {
                "eConnectConfig.disabledTitle.se": clinicGroupSettings?.eConnectConfig?.disabledTitle?.se,
                "eConnectConfig.disabledTitle.en": clinicGroupSettings?.eConnectConfig?.disabledTitle?.en,
                "eConnectConfig.disabledText.se": clinicGroupSettings?.eConnectConfig?.disabledText?.se,
                "eConnectConfig.disabledText.en": clinicGroupSettings?.eConnectConfig?.disabledText?.en,
            }
        } else {
            if (!clinicGroupSettings?.eConnectConfig?.disabledTitle?.se?.trim?.() || !clinicGroupSettings?.eConnectConfig?.disabledTitle?.en?.trim?.() || !clinicGroupSettings?.eConnectConfig?.disabledText?.se?.trim?.() || !clinicGroupSettings?.eConnectConfig?.disabledText?.en?.trim?.() || !clinicGroupSettings?.eConnectConfig?.enabledTitle?.se?.trim?.() || !clinicGroupSettings?.eConnectConfig?.enabledTitle?.en?.trim?.() || !clinicGroupSettings?.eConnectConfig?.enabledText?.se?.trim?.() || !clinicGroupSettings?.eConnectConfig?.enabledText?.en?.trim?.()) return;

            obj = {
                "eConnectConfig.disabledTitle.se": clinicGroupSettings?.eConnectConfig?.disabledTitle?.se,
                "eConnectConfig.disabledTitle.en": clinicGroupSettings?.eConnectConfig?.disabledTitle?.en,
                "eConnectConfig.disabledText.se": clinicGroupSettings?.eConnectConfig?.disabledText?.se,
                "eConnectConfig.disabledText.en": clinicGroupSettings?.eConnectConfig?.disabledText?.en,
                "eConnectConfig.enabledTitle.se": clinicGroupSettings?.eConnectConfig?.enabledTitle?.se,
                "eConnectConfig.enabledTitle.en": clinicGroupSettings?.eConnectConfig?.enabledTitle?.en,
                "eConnectConfig.enabledText.se": clinicGroupSettings?.eConnectConfig?.enabledText?.se,
                "eConnectConfig.enabledText.en": clinicGroupSettings?.eConnectConfig?.enabledText?.en,
                "eConnectConfig.externalAuthBankid": clinicGroupSettings?.eConnectConfig?.externalAuthBankid,
                "eConnectConfig.externalAuthNetid": clinicGroupSettings?.eConnectConfig?.externalAuthNetid,
            }
        }
        if (!obj) return;

        this.setState({ loading: true, _saving: true }, async () => {
            const newState = {
                loading: false,
                _saving: false,
                error: null,
            };

            const response = await fetch(API_ENDPOINT + '/e-connect/clinics/' + this.props.selectedGroup, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage?.getItem?.('authToken')}`
                },
                body: JSON.stringify(obj)
            });
            if (!response) {
                newState.error = defaultError;
                return this.setState(newState, () => {
                    this.refresh();
                })
            }

            const result = await response.json();
            if (!result || result.error) {
                newState.error = result.error || defaultError;
                return this.setState(newState, () => {
                    this.refresh();
                })
            }

            newState.eConnectExternalText = '';
            newState._saved = true;
            newState.clinicGroupSettings = {};
            this.setState(newState, () => {
                this.refresh();
                this.timer1 = setTimeout(() => {
                    this.setState({ _saved: false })
                }, 1000)
            })
        });
    }
    updateWebDocSettings = () => {
        const autoVisit = this.state.webDocSettings?.autoVisit ?? false;



        let data = {
            webDocSettings: { autoVisit },
            groupId: this.props.selectedGroup,
            clinic: this.props.selectedClinic
        };

        this.setState({ showPlayer: true, _loading: true }, () => {
            this.player.current.play();

            fetch(API_ENDPOINT + '/users/account/updateClinicWebDocSettings', {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify(data)
            }).then(parseJSON).then(({ result, status }) => {
                if (result.error) {
                    this.setState({
                        error: result.error,
                        showPlayer: false,
                        _loading: false
                    })
                } else {
                    this.setState({
                        message: result.message,
                        showPlayer: false,
                        showPlayer2: true,
                        _loading: false,
                    },
                        () => {
                            setTimeout(() => {
                                this.setState({ showPlayer2: false });
                            }, 1000);
                        })

                    this.props.verifyUser(() => {
                        if (this.state.webDocSettingsChanged && this.state.tabIndexCheck) {
                            this.setState({ showForm: null, tabIndex: this.state.tabIndexCheck, tabIndexCheck: false, webDocSettingsChanged: false }, () => this.setState({ showForm: true }, () => this.props.abortAction()))
                        } else {
                            this.setState({ showForm: null, webDocSettingsChanged: false }, () => this.setState({ showForm: true }, () => this.props.abortAction()))
                        }

                    }, () => {
                        this.props.changeGroup(data.groupId)

                    });
                    if (this.props.changeLinkModal && this.props.changeLinkModal.indexOf('group|') != -1) {
                        this.props.changeGroup(this.props.changeLinkModal.split('|')[1])
                        this.props.abortAction()
                    } else if (this.props.changeLinkModal && this.props.changeLinkModal.indexOf('clinic|') != -1) {
                        this.props.changeClinic(this.props.changeLinkModal.split('|')[1]);
                        this.props.abortAction()
                    } else if (this.props.changeLinkModal) {
                        this.props[0].history.push(this.props.changeLinkModal)
                        this.props.abortAction()
                    }

                }

            })
        }
        )



    }

    updateBookingSettings = () => {
        let global = this.state.bookingSettings?.global ?? false;
        let defaultValue = this.state.bookingSettings?.default && !global ? true : false;

        let offset = 15;
        if (this.state.bookingSettings?.offset && (global || defaultValue)) {
            if (Number(this.state.bookingSettings.offset) >= 15) {
                offset = Number(this.state.bookingSettings.offset)
            }
        }

        let data = {
            bookingSettings: { offset, global, default: defaultValue },
            groupId: this.props.selectedGroup,
            clinic: this.props.selectedClinic
        };

        this.setState({ showPlayer: true, _loading: true }, () => {
            this.player.current.play();

            fetch(API_ENDPOINT + '/users/account/updateClinicBookingSettings', {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify(data)
            }).then(parseJSON).then(({ result, status }) => {
                if (result.error) {
                    this.setState({
                        error: result.error,
                        showPlayer: false,
                        _loading: false
                    })
                } else {
                    this.setState({
                        message: result.message,
                        showPlayer: false,
                        showPlayer2: true,
                        _loading: false,
                    },
                        () => {
                            setTimeout(() => {
                                this.setState({ showPlayer2: false });
                            }, 1000);
                        })

                    this.props.verifyUser(() => {
                        if (this.state.bookingSettingsChanged && this.state.tabIndexCheck) {
                            this.setState({ showForm: null, tabIndex: this.state.tabIndexCheck, tabIndexCheck: false, bookingSettingsChanged: false }, () => this.setState({ showForm: true }, () => this.props.abortAction()))
                        } else {
                            this.setState({ showForm: null, bookingSettingsChanged: false }, () => this.setState({ showForm: true }, () => this.props.abortAction()))
                        }

                    }, () => {
                        this.props.changeGroup(data.groupId)

                    });
                    if (this.props.changeLinkModal && this.props.changeLinkModal.indexOf('group|') != -1) {
                        this.props.changeGroup(this.props.changeLinkModal.split('|')[1])
                        this.props.abortAction()
                    } else if (this.props.changeLinkModal && this.props.changeLinkModal.indexOf('clinic|') != -1) {
                        this.props.changeClinic(this.props.changeLinkModal.split('|')[1]);
                        this.props.abortAction()
                    } else if (this.props.changeLinkModal) {
                        this.props[0].history.push(this.props.changeLinkModal)
                        this.props.abortAction()
                    }

                }

            })
        }
        )



    }

    render() {
        const clinicGroupSettings = this.state.clinicGroupSettings;
        let params = {};
        if (this.state.useState) {
            params = this.state.params;
        } else {
            params = getSearchParams(this.props[0].location.search, { entries: 10, page: 0 })
        }


        let selectedGroupIdx = 0;
        if (this.props.selectedGroup && this.props.uData?.clinicGroups?.length) {
            for (let i = 0; i < this.props.uData.clinicGroups.length; i++) {
                if (this.props.uData.clinicGroups[i]._id == this.props.selectedGroup) {
                    selectedGroupIdx = i;
                    break;
                }
            }
        }

        const defaultOptions = {
            loop: true,
            autoplay: true,
            rendererSettings: {
                preserveAspectRatio: "xMidYMid slice",
            },
        };

        let isDisabled = false;
        if (this.state.tabIndex === 5 && clinicGroupSettings) {
            if (!clinicGroupSettings?.eConnect || clinicGroupSettings?.eConnectTempDisabled || clinicGroupSettings?.eConnectConfig?.externalDisabled) {
                if (!clinicGroupSettings?.eConnectConfig?.disabledTitle?.se?.trim?.() || !clinicGroupSettings?.eConnectConfig?.disabledTitle?.en?.trim?.() || !clinicGroupSettings?.eConnectConfig?.disabledText?.se?.trim?.() || !clinicGroupSettings?.eConnectConfig?.disabledText?.en?.trim?.()) isDisabled = true;
            } else {
                if (!clinicGroupSettings?.eConnectConfig?.disabledTitle?.se?.trim?.() || !clinicGroupSettings?.eConnectConfig?.disabledTitle?.en?.trim?.() || !clinicGroupSettings?.eConnectConfig?.disabledText?.se?.trim?.() || !clinicGroupSettings?.eConnectConfig?.disabledText?.en?.trim?.() || !clinicGroupSettings?.eConnectConfig?.enabledTitle?.se?.trim?.() || !clinicGroupSettings?.eConnectConfig?.enabledTitle?.en?.trim?.() || !clinicGroupSettings?.eConnectConfig?.enabledText?.se?.trim?.() || !clinicGroupSettings?.eConnectConfig?.enabledText?.en?.trim?.() /*|| !clinicGroupSettings?.eConnectConfig?.externalAuthBankid || !clinicGroupSettings?.eConnectConfig?.externalAuthNetid*/) isDisabled = true;
            }
        }

        const groupData = this.props?.uData?.clinicGroups?.[selectedGroupIdx] ?? null;
        const webDocIsActive = groupData?.webdoc?.active && groupData?.webdoc?.clientId && groupData?.webdoc?.clientSecret ? true : false;
        const showWebDocSettings = webDocIsActive && groupData?.webdoc?.showWebDocSettings && groupData?.webdoc?.journalNoteOptionsExtended;


        return (
            <div className={this.props.autoHideSidebar ? "dashboard dashboard-auto-hide" : "dashboard"}>

                <Container fluid>
                    {/*
                    <Row>
                      <Col xl="9" lg="7">
                           <Search />
                      </Col>
                      <Col xl="3" lg="5">
                      <div className='component notifications'>
                            <Isvg src={notification} />
                            <div className='profile'>
                            <span className='name'>{this.props.uData && this.props.uData.username}</span>
                            <img src={this.props.uData && this.props.uData.profilePicture ? API_ENDPOINT + this.props.uData.profilePicture : profileUser} className="profile-image" />
                            <Isvg src={moreIcon} />
                            </div>
                        </div>
                      </Col>
                    </Row>
                    */}
                    <Row>
                        <Col lg="12">
                            {
                                this.state.error ?

                                    <ErrorModal lang={this.props.lang}
                                        isOpen={this.state.error}
                                        toggle={() => this.setState({ error: null }, () => {
                                            window.location.reload();
                                        })}

                                    >
                                        {this.state.error.translate(this.props.lang)}
                                    </ErrorModal>

                                    :
                                    null
                            }

                            {/* {this.state.error ?
                                <Alert toggle={this.onDismiss} isOpen={this.state.error} color="success">
                                    {this.state.error.translate(this.props.lang)}
                                </Alert>
                                :
                                null
                            } */}
                            {/* {this.state.message ?
                                <Alert toggle={this.onDismiss} isOpen={this.state.message} color="success">
                                    {this.state.message.translate(this.props.lang)}
                                </Alert>
                                :
                                null
                            } */}

                            <div className="panel">

                                <Row>
                                    <Col lg="12">
                                        <h5 className="component-header mb-35">{this.props.linksMeta && this.props.linksMeta[this.props[0].location.pathname] ? this.props.linksMeta[this.props[0].location.pathname][this.props.lang] : ''}</h5>
                                    </Col>
                                    <Col lg="12">
                                        <div className="tabs-buttons-wrap" style={{ zIndex: 950 }} /*style={{ position: 'relative', marginBottom: '50px', zIndex: "950", width: "100%", borderBottom: "1px solid #9cafbf" }}*/>
                                            <Button className={this.state.tabIndex == 1 ? "tab-button acitve-tab-button" : "tab-button"} onClick={() => this.props.isDirty || this.props.isDirtySecond || this.state.colorSchemeChanged || this.state.pageBuilderChanged == 'open' || this.state.bookingSettingsChanged || this.state.webDocSettingsChanged ? this.setState({ tabIndexCheck: 1 }) : this.setState({ tabIndex: 1 })}>{"Group settings".translate(this.props.lang)}</Button>
                                            {
                                                typeof window != 'undefined' && window.innerWidth > 1023 ?
                                                    <Button className={this.state.tabIndex == 2 ? "tab-button acitve-tab-button" : "tab-button"} onClick={() => this.props.isDirty || this.props.isDirtySecond || this.state.colorSchemeChanged || this.state.bookingSettingsChanged || this.state.webDocSettingsChanged ? this.setState({ tabIndexCheck: 2 }) : this.setState({ tabIndex: 2 })}>{"Landing Builder".translate(this.props.lang)}</Button>

                                                    :
                                                    null
                                            }
                                            <Button className={this.state.tabIndex == 3 ? "tab-button acitve-tab-button" : "tab-button"} onClick={() => this.props.isDirty || this.props.isDirtySecond || this.state.colorSchemeChanged || this.state.pageBuilderChanged == 'open' || this.state.bookingSettingsChanged || this.state.webDocSettingsChanged ? this.setState({ tabIndexCheck: 3 }) : this.setState({ tabIndex: 3 })}>{"Color scheme".translate(this.props.lang)}</Button>

                                            <Button className={this.state.tabIndex == 4 ? "tab-button acitve-tab-button" : "tab-button"} onClick={() => this.props.isDirty || this.state.colorSchemeChanged || this.state.pageBuilderChanged == 'open' || this.state.bookingSettingsChanged || this.state.webDocSettingsChanged ? this.setState({ tabIndexCheck: 4 }) : this.setState({ tabIndex: 4 })}>{"Payment Settings".translate(this.props.lang)}</Button>

                                            <Button className={this.state.tabIndex === 5 ? "tab-button acitve-tab-button" : "tab-button"} onClick={() => {
                                                console.log('this.state.tabIndex', this.state.tabIndex)
                                                if (this.state.tabIndex === 5) return;
                                                this.props.isDirty || this.props.isDirtySecond || this.state.colorSchemeChanged || this.state.pageBuilderChanged == 'open' || this.state.bookingSettingsChanged || this.state.webDocSettingsChanged ? this.setState({ tabIndexCheck: 5 }, () => {
                                                    // TODO later remove ?
                                                    // this.setState({ tabIndexCheck: false })
                                                }) : this.setState({ tabIndex: 5, tabIndexCheck: false }, () => {
                                                    // TODO later remove ?
                                                    this.setState({ tabIndexCheck: false })
                                                })
                                            }}>
                                                {'eConnect'.translate(this.props.lang)}
                                            </Button>

                                            <Button className={this.state.tabIndex == 6 ? "tab-button acitve-tab-button" : "tab-button"} onClick={() => this.props.isDirty || this.state.colorSchemeChanged || this.state.pageBuilderChanged == 'open' || this.state.webDocSettingsChanged ? this.setState({ tabIndexCheck: 6 }) : this.setState({ tabIndex: 6 })}>{"Booking settings".translate(this.props.lang)}</Button>
                                            {
                                                showWebDocSettings ?
                                                    <Button className={this.state.tabIndex == 7 ? "tab-button acitve-tab-button" : "tab-button"} onClick={() => this.props.isDirty || this.state.colorSchemeChanged || this.state.pageBuilderChanged == 'open' || this.state.bookingSettingsChanged ? this.setState({ tabIndexCheck: 7 }) : this.setState({ tabIndex: 7 })}>{"Integration settings".translate(this.props.lang)}</Button>
                                                    :
                                                    null
                                            }
                                        </div>
                                    </Col>

                                    {/* {
                                        this.state.tabIndex == 1 ? */}
                                    <div className={this.state.tabIndex == 1 ? 'visibility mt-20 flex-it' : 'none-visibility flex-it'}>
                                        <Col lg="7">

                                            {this.state.showForm ?
                                                <>
                                                    <FormBuilder selectedGroup={this.props.selectedGroup} selectedClinic={this.props.selectedClinic} countryTwoLetter={this.props.countryTwoLetter} country={this.props.country} dateFormat={this.props.dateFormat}
                                                        getStringDateTs={this.props.getStringDateTs}
                                                        lang={this.props.lang}
                                                        onSubmit={(data) => this.update(data)}
                                                        initialValues={this.props.uData.clinicGroups[selectedGroupIdx]}
                                                        buttonText={
                                                            this.state.showPlayer ? (
                                                                <Player
                                                                    onEvent={(event) => {
                                                                        if (event === "load") this.stopAnimation();
                                                                    }}
                                                                    onStateChange={this.toggleVisibility}
                                                                    ref={this.player} // set the ref to your class instance
                                                                    autoplay={true}
                                                                    loop={true}
                                                                    controls={true}
                                                                    src="https://assets8.lottiefiles.com/packages/lf20_ZSRKCF.json"
                                                                    style={{ height: "30px", width: "30px" }}
                                                                ></Player>

                                                            ) : this.state.showPlayer2 ? (
                                                                <div style={{ display: 'flex', alignItems: 'center' }}><Player
                                                                    onStateChange={this.toggleVisibility}
                                                                    ref={this.player2} // set the ref to your class instance
                                                                    autoplay={true}
                                                                    loop={true}
                                                                    controls={true}
                                                                    src="https://assets10.lottiefiles.com/packages/lf20_CxyxLk.json"
                                                                    style={{ height: "30px", width: "30px" }}
                                                                ></Player>
                                                                    {'Saved'.translate(this.props.lang)}
                                                                </div>
                                                            ) : (
                                                                "Save".translate(this.props.lang)
                                                            )
                                                        }

                                                        additionalButton={
                                                            // <a href={window.location.origin + '/' + this.props.uData.clinicGroups[selectedGroupIdx].alias} target="_blank">
                                                            <Button style={{ marginLeft: 10 }} type="submit" onClick={() => this.setState({ previewNeeded: true })} color="success">{'Preview'.translate(this.props.lang)}</Button>
                                                            //    </a>
                                                        }
                                                        fields={[
                                                            {
                                                                type: 'row',
                                                                children: [
                                                                    {
                                                                        type: 'col',
                                                                        width: {
                                                                            lg: 12,
                                                                            sm: 12,
                                                                            xs: 12
                                                                        },
                                                                        children: [

                                                                            {
                                                                                type: 'h4',
                                                                                text: 'Group settings'.translate(this.props.lang),
                                                                            },
                                                                        ]
                                                                    },
                                                                    {
                                                                        type: 'col',
                                                                        width: {
                                                                            lg: 8,
                                                                            sm: 12,
                                                                            xs: 12
                                                                        },
                                                                        children: [
                                                                            {
                                                                                type: 'text',
                                                                                name: 'name',
                                                                                disabled: true,
                                                                                label: 'Group name'.translate(this.props.lang),
                                                                            },

                                                                            {
                                                                                type: 'smsName',
                                                                                name: 'smsName',
                                                                                label: 'SMS sender name'.translate(this.props.lang),
                                                                            },
                                                                            {
                                                                                type: 'text',
                                                                                name: 'emailName',
                                                                                label: 'E-mail sender name'.translate(this.props.lang),
                                                                            },
                                                                            {
                                                                                type: 'h4',
                                                                                text: <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                                                    {'Bank ID settings'.translate(this.props.lang)}
                                                                                    <a href="https://zignsec.com/" target="_blank" className='group-settings-field-tooltip-wrap'><Isvg src={link} /><div className='group-settings-field-tooltip'>{'For your own IDP, enter information here.'.translate(this.props.lang)}</div></a>
                                                                                </div>,
                                                                            },
                                                                            {
                                                                                type: 'text',
                                                                                name: 'bankIdApiKey',
                                                                                label: 'Bank ID Api key'.translate(this.props.lang),
                                                                            },
                                                                            {
                                                                                type: 'h4',
                                                                                text: <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                                                    {'Net ID settings'.translate(this.props.lang)}
                                                                                    <a href="https://e-identitet.se/" target="_blank" className='group-settings-field-tooltip-wrap'><Isvg src={link} /><div className='group-settings-field-tooltip'>{'For your own Net ID connection, enter information here.'.translate(this.props.lang)}</div></a>
                                                                                </div>


                                                                            },
                                                                            {
                                                                                type: 'text',
                                                                                name: 'netIdApiKey',
                                                                                label: 'Net ID Api key'.translate(this.props.lang),
                                                                            },
                                                                            {
                                                                                type: 'text',
                                                                                name: 'authenticateServiceKey',
                                                                                label: 'Authenticate service key'.translate(this.props.lang),
                                                                            },

                                                                        ]
                                                                    },
                                                                    {
                                                                        type: 'col',
                                                                        width: {
                                                                            lg: 4,
                                                                            sm: 12,
                                                                            xs: 12
                                                                        },
                                                                        children: [
                                                                            {
                                                                                type: 'image',
                                                                                name: 'logo',
                                                                                label: 'Logo'.translate(this.props.lang),
                                                                                lang: this.props.lang,
                                                                                group: this.props.selectedGroup,
                                                                                validate: [fileUploading("Upload file".translate(this.props.lang))]
                                                                            },
                                                                            {
                                                                                type: 'image',
                                                                                name: 'logoChat',
                                                                                label: 'Chat logo'.translate(this.props.lang),
                                                                                description: 'Leave blank if you wish to use the default Curoflow logo.'.translate(this.props.lang),
                                                                                lang: this.props.lang,
                                                                                group: this.props.selectedGroup,
                                                                                validate: [fileUploading("Upload file".translate(this.props.lang))]
                                                                            },
                                                                        ]
                                                                    },



                                                                ]
                                                            },
                                                            {
                                                                type: 'row',
                                                                children: [
                                                                    {
                                                                        type: 'col',
                                                                        width: {
                                                                            lg: 12,
                                                                            sm: 12,
                                                                            xs: 12
                                                                        },
                                                                        children: [
                                                                            {
                                                                                type: 'h4',
                                                                                text: 'Landing page config'.translate(this.props.lang),
                                                                            },
                                                                        ]
                                                                    },
                                                                    {
                                                                        type: 'col',
                                                                        width: {
                                                                            lg: 4,
                                                                            sm: 12,
                                                                            xs: 12
                                                                        },
                                                                        children: [
                                                                            {
                                                                                type: 'checkbox',
                                                                                name: 'landingConfig.loginOutline',
                                                                                label: 'LogIn button outline'.translate(this.props.lang),
                                                                            },
                                                                        ]
                                                                    },
                                                                    {
                                                                        type: 'col',
                                                                        width: {
                                                                            lg: 4,
                                                                            sm: 12,
                                                                            xs: 12
                                                                        },
                                                                        children: [
                                                                            {
                                                                                type: 'color-picker',
                                                                                name: 'landingConfig.loginColor',
                                                                                label: 'LogIn button color'.translate(this.props.lang),
                                                                            },
                                                                        ]
                                                                    },
                                                                    {
                                                                        type: 'col',
                                                                        width: {
                                                                            lg: 4,
                                                                            sm: 12,
                                                                            xs: 12
                                                                        },
                                                                        children: [
                                                                            {
                                                                                type: 'button',
                                                                                name: 'landingConfig.previewLoginButton',
                                                                                label: 'LogIn button preview'.translate(this.props.lang),
                                                                                loginOutline: this.state.loginButtonPreview,
                                                                                loginColor: this.props.loginColor,
                                                                                onMouseEnter: () => {
                                                                                    this.setState({
                                                                                        loginButtonPreview: !this.state.loginButtonPreview
                                                                                    })
                                                                                },
                                                                                onMouseLeave: () => {
                                                                                    this.setState({
                                                                                        loginButtonPreview: !this.state.loginButtonPreview
                                                                                    })
                                                                                }
                                                                            },
                                                                        ]
                                                                    },
                                                                    {
                                                                        type: 'col',
                                                                        width: {
                                                                            lg: 12,
                                                                            sm: 12,
                                                                            xs: 12
                                                                        },
                                                                        children: [
                                                                            {
                                                                                type: 'url',
                                                                                name: 'landingConfig.informationLink',
                                                                                label: 'Information page link'.translate(this.props.lang),
                                                                            },
                                                                        ]
                                                                    },


                                                                    {
                                                                        type: 'col',
                                                                        width: {
                                                                            lg: 4,
                                                                            sm: 12,
                                                                            xs: 12
                                                                        },
                                                                        children: [
                                                                            {
                                                                                type: 'file',
                                                                                className: 'label-60',
                                                                                name: 'landingConfig.privacyPolicy',
                                                                                label: 'Privacy policy pdf'.translate(this.props.lang) + '*',
                                                                                acceptTypes: ".pdf",
                                                                                lang: this.props.lang,
                                                                                group: this.props.selectedGroup,
                                                                                validate: [fileUploadingRequired("Privacy policy is required!".translate(this.props.lang))],

                                                                            },
                                                                        ]
                                                                    },
                                                                    {
                                                                        type: 'col',
                                                                        width: {
                                                                            lg: 4,
                                                                            sm: 12,
                                                                            xs: 12
                                                                        },
                                                                        children: [
                                                                            {
                                                                                type: 'file',
                                                                                className: 'label-60',
                                                                                name: 'landingConfig.termsOfUse',
                                                                                label: 'Terms of use pdf'.translate(this.props.lang) + '*',
                                                                                acceptTypes: ".pdf",
                                                                                lang: this.props.lang,
                                                                                group: this.props.selectedGroup,
                                                                                validate: [fileUploadingRequired("Terms of use is required!".translate(this.props.lang))]
                                                                            },
                                                                        ]
                                                                    },
                                                                    {
                                                                        type: 'col',
                                                                        width: {
                                                                            lg: 4,
                                                                            sm: 12,
                                                                            xs: 12
                                                                        },
                                                                        children: [
                                                                            {
                                                                                type: 'file',
                                                                                className: 'label-60',
                                                                                name: 'landingConfig.accessibilityReport',
                                                                                label: 'Accessibility report pdf'.translate(this.props.lang), acceptTypes: ".pdf",
                                                                                lang: this.props.lang,
                                                                                group: this.props.selectedGroup,
                                                                                validate: [fileUploading("Privacy policy is required!".translate(this.props.lang))]
                                                                            },
                                                                        ]
                                                                    },
                                                                    // {
                                                                    //     type: 'col',
                                                                    //     width: {
                                                                    //         lg: 8,
                                                                    //         sm: 12,
                                                                    //         xs: 12
                                                                    //     },
                                                                    //     children: [
                                                                    //         {
                                                                    //             type: 'webBookingOffset',
                                                                    //             name: 'freeEventsStartOffset'
                                                                    //         }

                                                                    //     ]
                                                                    // },

                                                                    {

                                                                        type: 'col',
                                                                        width: {
                                                                            lg: 8,
                                                                            sm: 12,
                                                                            xs: 12
                                                                        },
                                                                        children: [
                                                                            {
                                                                                type: 'text',
                                                                                name: 'landingConfig.address[0]',
                                                                                label: 'Address line 1'.translate(this.props.lang),
                                                                                validate: [required("Address is required!".translate(this.props.lang))],
                                                                            },
                                                                            {
                                                                                type: 'text',
                                                                                name: 'landingConfig.address[1]',
                                                                                label: 'Address line 2'.translate(this.props.lang),
                                                                            },
                                                                            {
                                                                                type: 'text',
                                                                                name: 'landingConfig.phone',
                                                                                label: 'Phone'.translate(this.props.lang),
                                                                            },

                                                                        ]
                                                                    },
                                                                    {
                                                                        type: 'col',
                                                                        width: {
                                                                            lg: 8,
                                                                            sm: 12,
                                                                            xs: 12
                                                                        },
                                                                        children: [
                                                                            {
                                                                                type: 'text',
                                                                                name: 'landingConfig.coords[0]',
                                                                                label: 'Latitude'.translate(this.props.lang),
                                                                            },
                                                                            {
                                                                                type: 'text',
                                                                                name: 'landingConfig.coords[1]',
                                                                                label: 'Longitude'.translate(this.props.lang),
                                                                            },
                                                                            {
                                                                                type: 'map',
                                                                                height: 300,
                                                                                name: 'landingConfig.coords',
                                                                                label: 'Coords'.translate(this.props.lang),
                                                                            },


                                                                        ]
                                                                    },

                                                                    // {
                                                                    //     type: 'col',
                                                                    //     width: {
                                                                    //         lg: 8,
                                                                    //         sm: 12,
                                                                    //         xs: 12
                                                                    //     },

                                                                    //     children: [

                                                                    //         {
                                                                    //             type: 'color-picker',
                                                                    //             name: 'landingConfig.footerTextColor',
                                                                    //             label: 'Footer text color'.translate(this.props.lang),
                                                                    //             className: 'clinic-data-color-picker'
                                                                    //         },
                                                                    //         {
                                                                    //             type: 'color-picker',
                                                                    //             name: 'landingConfig.footerBackground',
                                                                    //             label: 'Footer background color'.translate(this.props.lang),
                                                                    //             className: 'clinic-data-color-picker'
                                                                    //         },


                                                                    //     ]
                                                                    // },



                                                                ]
                                                            }
                                                        ]}
                                                    ></FormBuilder>
                                                </>
                                                :
                                                null
                                            }

                                        </Col>

                                        <Col lg="5" className="group-settings-landing">
                                            {this.props.uData.clinicGroups[selectedGroupIdx].alias ?
                                                <>
                                                    <FormGroup>
                                                        <Label> {'Group name:'.translate(this.props.lang)} <strong>{this.props.uData.clinicGroups[selectedGroupIdx].name}</strong> </Label>

                                                    </FormGroup>
                                                    <hr />


                                                    <FormGroup >
                                                        <Checkbox disabled checked={this.props.uData.clinicGroups[selectedGroupIdx].personalReferrals} label={'E-egenremiss'.translate(this.props.lang)} />

                                                    </FormGroup>

                                                    {this.props.uData.clinicGroups[selectedGroupIdx].personalReferrals ?
                                                        <>
                                                            <FormGroup style={{ marginTop: 25 }}>
                                                                <Label> {'Landing page URL:'.translate(this.props.lang)} </Label>
                                                                <div className="landing-url">

                                                                    <Input size="sm" type='text' value={window.location.origin + '/' + this.props.uData.clinicGroups[selectedGroupIdx].alias} id='link' disabled={true} />
                                                                    <Button size="sm" onClick={() => this.copyFunction()} color='primary' style={{ marginLeft: '20px' }}> {'Copy'.translate(this.props.lang)}</Button>
                                                                </div>
                                                                <input type='text' ref={node => { this.input = node }} value={window.location.origin + '/' + this.props.uData.clinicGroups[selectedGroupIdx].alias} id='link' style={{ opacity: '0', position: 'absolute', left: '-1000px' }} />
                                                            </FormGroup>

                                                            <FormGroup>
                                                                <Label> {'Clinic admin login URL:'.translate(this.props.lang)} </Label>
                                                                <div className="landing-url">
                                                                    <Input size="sm" type='text' value={window.location.origin + '/' + this.props.uData.clinicGroups[selectedGroupIdx].alias + '/login'} id='link' disabled={true} />
                                                                    <Button size="sm" onClick={() => this.copyFunction1()} color='primary' style={{ marginLeft: '20px' }}> {'Copy'.translate(this.props.lang)}</Button>
                                                                </div>
                                                                <input type='text' ref={node => { this.input1 = node }} value={window.location.origin + '/' + this.props.uData.clinicGroups[selectedGroupIdx].alias + '/login'} id='link' style={{ opacity: '0', position: 'absolute', left: '-1000px' }} />
                                                            </FormGroup>

                                                        </>
                                                        :
                                                        null
                                                    }
                                                </>
                                                : null
                                            }
                                        </Col>

                                    </div>
                                    {/* :

                                            null
                                    } */}

                                    <Col lg="12" className={this.state.tabIndex == 2 ? 'visibility' : 'none-visibility'}>

                                        {/* Landing Builder V1  */}
                                        {/* <Iframe url={!this.props.check ? `/my-data/clinic-data/landing-configurator/false/1/${this.props.uData.clinicGroups[selectedGroupIdx].alias}/1` : `/my-data/clinic-data/landing-configurator/true/1/${this.props.uData.clinicGroups[selectedGroupIdx].alias}/1`}
                                            id="myId"
                                            className="iframe"
                                            allow="fullscreen"
                                        /> */}

                                        {/* Landing Builder V2  */}
                                        <LandingBuilder {...this.props} footerSettings={true} landingStyle={this.state.landingStyle} templates={this.state.templates}
                                            onChange={(item) => {
                                                this.setState({
                                                    pageBuilderChanged: item
                                                })

                                            }} updateLanding={this.state.updateLanding} changeUpdateLanding={(item) => {
                                                this.setState({ updateLanding: item }, () => {
                                                    this.props.verifyUser(() => { })
                                                    if (this.state.waitResponseFromLandingBulder) {
                                                        this.setState({ waitResponseFromLandingBulder: false }, () => {
                                                            if (this.props.changeLinkModal.indexOf('group|') != -1) {
                                                                this.props.changeGroup(this.props.changeLinkModal.split('|')[1])
                                                                this.props.abortAction()
                                                            } else if (this.props.changeLinkModal.indexOf('clinic|') != -1) {
                                                                this.props.changeClinic(this.props.changeLinkModal.split('|')[1]);
                                                                this.props.abortAction()
                                                            } else
                                                                this.props.verifyUser(() => {
                                                                    this.props[0].history.push(this.props.changeLinkModal)
                                                                    this.props.abortAction()
                                                                })
                                                        })
                                                    }
                                                })
                                            }} />

                                    </Col>

                                    <Col lg="12" className={this.state.tabIndex == 3 ? 'visibility' : 'none-visibility'}>
                                        <div className="colors-scheme-wrap">
                                            <div className='color-scheme-wrap'>
                                                <h6 className='color-sheme-subtitle'>
                                                    {'Predefined'.translate(this.props.lang)}
                                                </h6>
                                                {
                                                    this.state.colorScheme && this.state.colorScheme.length && this.state.colorScheme.map((item, idx) => {
                                                        if (item.image)
                                                            return (
                                                                <div className='color-scheme'>

                                                                    <div className={'radio-select'} onClick={() => this.setState({ selectedColorScheme: item._id }, () => {
                                                                        if (this.props.uData.clinicGroups && this.props.uData.clinicGroups[selectedGroupIdx] && this.props.uData.clinicGroups[selectedGroupIdx].colorScheme) {
                                                                            if (this.state.selectedColorScheme != this.props.uData.clinicGroups[selectedGroupIdx].colorScheme._id) {
                                                                                this.setState({ colorSchemeChanged: true, currentScheme: this.props.uData.clinicGroups[selectedGroupIdx].colorScheme._id })
                                                                                // console.log(this.state.selectedColorScheme, this.props.uData.clinicGroups[selectedGroupIdx].colorScheme._id)
                                                                            }
                                                                        } else {
                                                                            this.setState({
                                                                                colorSchemeChanged: true
                                                                            })
                                                                        }
                                                                    })}><div className={this.state.selectedColorScheme == item._id ? 'checked' : ''}></div></div>
                                                                    <div className='colors-wrap'>

                                                                        {
                                                                            this.state.sortedColors && this.state.sortedColors[idx] && this.state.sortedColors[idx].length && this.state.sortedColors[idx].map((color, c) => {

                                                                                return (
                                                                                    <div className='color' style={{ backgroundColor: color, border: '0.5px solid #E6EDF5' }} onClick={() => {
                                                                                        this.setState({
                                                                                            colorPickerOpen: `${idx};${c}`
                                                                                        })
                                                                                    }}>
                                                                                        {/* {item._id == '7' ? <Button onClick={() => {
                                                                                        this.setState({
                                                                                            colorPickerOpen: `${idx};${c}`
                                                                                        })
                                                                                    }} style={item._id == '7' && color != '' ? { backgroundColor: color } : { backgroundColor: '#568AC8' }} >{c}</Button> : null} */}
                                                                                    </div>
                                                                                )
                                                                            })
                                                                        }



                                                                    </div>


                                                                </div>
                                                            )
                                                    })
                                                }
                                                <h6 className='color-sheme-subtitle'>
                                                    {'Custom'.translate(this.props.lang)}
                                                </h6>
                                                {
                                                    this.state.colorScheme && this.state.colorScheme.length && this.state.colorScheme.map((item, idx) => {
                                                        if (!item.image)
                                                            return (
                                                                <div className='color-scheme'>

                                                                    <div className={'radio-select'} onClick={() => this.setState({ selectedColorScheme: item._id }, () => {
                                                                        if (this.props.uData.clinicGroups && this.props.uData.clinicGroups[selectedGroupIdx] && this.props.uData.clinicGroups[selectedGroupIdx].colorScheme) {
                                                                            if (this.state.selectedColorScheme != this.props.uData.clinicGroups[selectedGroupIdx].colorScheme._id) {
                                                                                this.setState({ colorSchemeChanged: true, currentScheme: this.props.uData.clinicGroups[selectedGroupIdx].colorScheme._id })
                                                                                // console.log(this.state.selectedColorScheme, this.props.uData.clinicGroups[selectedGroupIdx].colorScheme._id)
                                                                            }
                                                                        } else {
                                                                            this.setState({
                                                                                colorSchemeChanged: true
                                                                            })
                                                                        }
                                                                    })}><div className={this.state.selectedColorScheme == item._id ? 'checked' : ''}></div></div>
                                                                    <div className='colors-wrap'>

                                                                        {
                                                                            this.state.sortedColors && this.state.sortedColors[idx] && this.state.sortedColors[idx].length && this.state.sortedColors[idx].map((color, c) => {

                                                                                return (
                                                                                    <div className='custom-color-container'>
                                                                                        <div className='color' style={{ backgroundColor: color, border: '0.5px solid #E6EDF5' }}
                                                                                        // onClick={() => {
                                                                                        //     this.setState({
                                                                                        //         colorPickerOpen: `${idx};${c}`
                                                                                        //     })
                                                                                        // }}
                                                                                        >
                                                                                            <ColorPicker value={this.state.sortedColors && this.state.sortedColors[idx] ? this.state.sortedColors[idx][c] : '#568AC8'} classNameColor='custom-colorpicker' onChange={(color) => {
                                                                                                let sortedColors = this.state.sortedColors
                                                                                                sortedColors[idx].splice(c, 1, color)
                                                                                                let colorType = c
                                                                                                // console.log(this.state.colorPickerOpen)
                                                                                                let obj = {

                                                                                                    _id: 7,
                                                                                                    backgroundColor: colorType == '0' ? color : this.state.sortedColors[idx][0], // tekst 1
                                                                                                    primaryLight: colorType == '1' ? color : this.state.sortedColors[idx][1], // tekst 2
                                                                                                    primaryLightText: colorType == '2' ? color : this.state.sortedColors[idx][2], // ikonice
                                                                                                    primaryDark: colorType == '3' ? color : this.state.sortedColors[idx][3], // blok  
                                                                                                    primaryDarkText: colorType == '4' ? color : this.state.sortedColors[idx][4], // header
                                                                                                    secondaryLight: colorType == '5' ? color : this.state.sortedColors[idx][5], // bg svega
                                                                                                    secondaryLightText: colorType == '6' ? color : this.state.sortedColors[idx][6],
                                                                                                    secondaryDark: colorType == '7' ? color : this.state.sortedColors[idx][7],
                                                                                                    secondaryDarkText: colorType == '8' ? color : this.state.sortedColors[idx][8],
                                                                                                }
                                                                                                let colorScheme = this.state.colorScheme
                                                                                                colorScheme.splice(idx, 1, obj)
                                                                                                this.setState({
                                                                                                    sortedColors,
                                                                                                    colorScheme,
                                                                                                    // colorPickerOpen: null
                                                                                                })
                                                                                            }}></ColorPicker>
                                                                                            {/* {item._id == '7' ? <Button onClick={() => {
                                                                                            this.setState({
                                                                                                colorPickerOpen: `${idx};${c}`
                                                                                            })
                                                                                        }} style={item._id == '7' && color != '' ? { backgroundColor: color } : { backgroundColor: '#568AC8' }} >{c}</Button> : null} */}
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            })
                                                                        }



                                                                    </div>


                                                                </div>
                                                            )
                                                    })
                                                }
                                                {/* {
                                                    this.state.colorPickerOpen ?
                                                        <div>
                                                            <Label>{'Select color'.translate(this.props.lang)}</Label>
                                                            <ColorPicker value={this.state.sortedColors && this.state.sortedColors[this.state.colorPickerOpen.split(';')[0]] ? this.state.sortedColors[this.state.colorPickerOpen.split(';')[0]][this.state.colorPickerOpen.split(';')[1]] : '#568AC8'} onChange={(color) => {
                                                                let sortedColors = this.state.sortedColors
                                                                sortedColors[this.state.colorPickerOpen.split(';')[0]].splice(this.state.colorPickerOpen.split(';')[1], 1, color)
                                                                let colorType = this.state.colorPickerOpen.split(';')[1]
                                                                console.log(this.state.colorPickerOpen)
                                                                let obj = {
                                                                    _id: 7,
                                                                    backgroundColor: colorType == '0' ? color : this.state.sortedColors[this.state.colorPickerOpen.split(';')[0]][0], // tekst 1
                                                                    primaryLight: colorType == '1' ? color : this.state.sortedColors[this.state.colorPickerOpen.split(';')[0]][1], // tekst 2
                                                                    primaryLightText: colorType == '2' ? color : this.state.sortedColors[this.state.colorPickerOpen.split(';')[0]][2], // ikonice
                                                                    primaryDark: colorType == '3' ? color : this.state.sortedColors[this.state.colorPickerOpen.split(';')[0]][3], // blok  
                                                                    primaryDarkText: colorType == '4' ? color : this.state.sortedColors[this.state.colorPickerOpen.split(';')[0]][4], // header
                                                                    secondaryLight: colorType == '5' ? color : this.state.sortedColors[this.state.colorPickerOpen.split(';')[0]][5], // bg svega
                                                                    secondaryLightText: colorType == '7' ? color : this.state.sortedColors[this.state.colorPickerOpen.split(';')[0]][6],
                                                                    secondaryDark: colorType == '8' ? color : this.state.sortedColors[this.state.colorPickerOpen.split(';')[0]][7],
                                                                    secondaryDarkText: colorType == '9' ? color : this.state.sortedColors[this.state.colorPickerOpen.split(';')[0]][8],
                                                                }
                                                                let colorScheme = this.state.colorScheme
                                                                colorScheme.splice(this.state.colorPickerOpen.split(';')[0], 1, obj)
                                                                this.setState({
                                                                    sortedColors,
                                                                    colorScheme,
                                                                    colorPickerOpen: null
                                                                })
                                                            }}></ColorPicker>
                                                        </div>
                                                        :
                                                        null
                                                } */}
                                                <h6 className='color-sheme-subtitle'>
                                                    {'Status bar color'.translate(this.props.lang)}
                                                </h6>
                                                <div className='color-scheme'>


                                                    <div className='colors-wrap' style={{ marginLeft: 0 }}>

                                                        <div className='custom-color-container2'>
                                                            <div className='color' style={{ backgroundColor: this.state.statusBarColor, border: '0.5px solid #E6EDF5' }}

                                                            >
                                                                <ColorPicker width={60} value={this.state.statusBarColor ? this.state.statusBarColor : '#FFFFFF'}
                                                                    classNameColor='custom-colorpicker'
                                                                    onChange={(color) => {
                                                                        this.setState({ statusBarColor: color })
                                                                    }}
                                                                ></ColorPicker>

                                                            </div>
                                                        </div>


                                                    </div>


                                                </div>
                                            </div>

                                            <div className="color-preview-wrap">
                                                {
                                                    this.state.selectedColorScheme ?

                                                        this.state.colorScheme && this.state.colorScheme.length && this.state.colorScheme.map((item, idx) => {
                                                            // console.log(item)
                                                            if (item && item._id == this.state.selectedColorScheme)
                                                                return (
                                                                    // <div className="color-preview">
                                                                    /*
                                                                            item.image ?
                                                                            <div className="color-preview">
                                                                                    <img src={item.image} />
                                                                            </div>
                                                                            

                                                                            :
                                                                    */
                                                                    <div className="color-preview">
                                                                        <div className='screen-preview-container'>
                                                                            <header style={{ background: item.primaryLight }}>
                                                                                {
                                                                                    this.props.uData && this.props.uData.clinicGroups && this.props.uData.clinicGroups[selectedGroupIdx] && this.props.uData.clinicGroups[selectedGroupIdx].logo ?
                                                                                        <img src={API_ENDPOINT + this.props.uData.clinicGroups[selectedGroupIdx].logo} />
                                                                                        :
                                                                                        <h6>Curo Kliniken</h6>
                                                                                }


                                                                                <div>
                                                                                    <img src={userIcon} />
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="4" height="20" viewBox="0 0 4 20">
                                                                                        <g id="Group_23" data-name="Group 23" transform="translate(-1854 -46)">
                                                                                            <circle id="Ellipse_18" data-name="Ellipse 18" cx="2" cy="2" r="2" transform="translate(1854 46)" fill={item.primaryLightText ? item.primaryLightText : '#3A3F56'} />
                                                                                            <circle id="Ellipse_19" data-name="Ellipse 19" cx="2" cy="2" r="2" transform="translate(1854 54)" fill={item.primaryLightText ? item.primaryLightText : '#3A3F56'} />
                                                                                            <circle id="Ellipse_20" data-name="Ellipse 20" cx="2" cy="2" r="2" transform="translate(1854 62)" fill={item.primaryLightText ? item.primaryLightText : '#3A3F56'} />
                                                                                        </g>
                                                                                    </svg>
                                                                                </div>
                                                                            </header>
                                                                            <div className='screen-preview' style={{ background: item.backgroundColor }}>
                                                                                <div className='tabs'>
                                                                                    <h6>{'Mina ärenden'.translate(this.props.lang)}</h6>
                                                                                    <div>
                                                                                        <span>{'Alla'.translate(this.props.lang)}</span>
                                                                                        <span>{'Bokat'.translate(this.props.lang)}</span>
                                                                                        <span>{'Väntar'.translate(this.props.lang)}</span>
                                                                                        <span>{'Drop-in'.translate(this.props.lang)}</span>
                                                                                    </div>
                                                                                </div>
                                                                                <section className='screen-section'>
                                                                                    <div className='screen-section-container' style={{ background: item.secondaryLight }}>
                                                                                        <h3 style={{ color: item.secondaryLightText }}>{'Ögonoperation'.translate(this.props.lang)}</h3>
                                                                                        <h5 style={{ color: item.secondaryLightText }}>{'Ögonsjukvård'.translate(this.props.lang)}</h5>
                                                                                        <p style={{ color: item.secondaryLightText }}>Agnes Johansson</p>
                                                                                        <div>
                                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="25.203" height="25.19" viewBox="0 0 25.203 25.19">
                                                                                                <g id="Group_239" data-name="Group 239" transform="translate(-1584.127 -1365.657)">
                                                                                                    <g id="Layer_x0020_1" transform="translate(1362.7 1138.097)">
                                                                                                        <g id="_1664416167024" transform="translate(222.3 228.46)">
                                                                                                            <line id="Line_74" data-name="Line 74" y1="0.054" x2="23.43" transform="translate(0 9.194)" fill="none" stroke={item.secondaryDark ? item.secondaryDark : '#3A3F56'} stroke-miterlimit="2.613" stroke-width="1.8" />
                                                                                                            <g id="Group_238" data-name="Group 238" transform="translate(5.641 0)">
                                                                                                                <line id="Line_75" data-name="Line 75" y2="6.156" fill="none" stroke={item.secondaryDark ? item.secondaryDark : '#3A3F56'} stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="2.613" stroke-width="1.8" />
                                                                                                                <line id="Line_76" data-name="Line 76" y2="6.156" transform="translate(12.149)" fill="none" stroke={item.secondaryDark ? item.secondaryDark : '#3A3F56'} stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="2.613" stroke-width="1.8" />
                                                                                                            </g>
                                                                                                            <path id="Path_308" data-name="Path 308" d="M226.215,404.9h18.154a2.63,2.63,0,0,1,2.625,2.625v14.466a2.63,2.63,0,0,1-2.625,2.625H226.215a2.63,2.63,0,0,1-2.625-2.625V407.525A2.63,2.63,0,0,1,226.215,404.9Z" transform="translate(-223.563 -401.225)" fill="none" stroke={item.secondaryDark ? item.secondaryDark : '#3A3F56'} stroke-miterlimit="2.613" stroke-width="1.8" fill-rule="evenodd" />
                                                                                                            <line id="Line_77" data-name="Line 77" x1="0.014" y1="1.939" transform="translate(5.315 18.373)" fill="none" stroke={item.secondaryDark ? item.secondaryDark : '#3A3F56'} stroke-miterlimit="2.613" stroke-width="1.8" />
                                                                                                            <line id="Line_78" data-name="Line 78" x1="0.014" y1="1.939" transform="translate(10.23 13.661)" fill="none" stroke={item.secondaryDark ? item.secondaryDark : '#3A3F56'} stroke-miterlimit="2.613" stroke-width="1.8" />
                                                                                                            <line id="Line_79" data-name="Line 79" x1="0.014" y1="1.939" transform="translate(14.079 18.38)" fill="none" stroke={item.secondaryDark ? item.secondaryDark : '#3A3F56'} stroke-miterlimit="2.613" stroke-width="1.8" />
                                                                                                            <line id="Line_80" data-name="Line 80" x1="0.014" y1="1.939" transform="translate(18.203 13.77)" fill="none" stroke={item.secondaryDark ? item.secondaryDark : '#3A3F56'} stroke-miterlimit="2.613" stroke-width="1.8" />
                                                                                                        </g>
                                                                                                    </g>
                                                                                                </g>
                                                                                            </svg>
                                                                                            <span style={{ color: item.secondaryLightText }}>2021-05-21 08:25</span>
                                                                                        </div>
                                                                                        <div>
                                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="26.314" height="28.032" viewBox="0 0 26.314 28.032">
                                                                                                <g id="Group_589" data-name="Group 589" transform="translate(-20.463 -1277.627)">
                                                                                                    <g id="Layer_x0020_1" transform="translate(-239.387 1051.587)">
                                                                                                        <g id="_1700789009136" transform="translate(260.75 227.84)">
                                                                                                            <path id="Path_253" data-name="Path 253" d="M263.505,283.18l-2.755.059v9.505s.7,6.513,7.51,6.806c0,0,6.352.059,7.452-6.454l.117-9.916-1.755.059" transform="translate(-260.75 -281.933)" fill="none" stroke={item.secondaryDark ? item.secondaryDark : '#3A3F56'} stroke-miterlimit="2.613" stroke-width="1.8" fill-rule="evenodd" />
                                                                                                            <g id="Ellipse_76" data-name="Ellipse 76" transform="translate(9.973 0.102)" fill="none" stroke={item.secondaryDark ? item.secondaryDark : '#3A3F56'} stroke-miterlimit="2.613" stroke-width="1.8">
                                                                                                                <circle cx="1.247" cy="1.247" r="1.247" stroke="none" />
                                                                                                                <circle cx="1.247" cy="1.247" r="2.147" fill="none" />
                                                                                                            </g>
                                                                                                            <g id="Ellipse_77" data-name="Ellipse 77" transform="translate(21.121 12.013)" fill="none" stroke={item.secondaryDark ? item.secondaryDark : '#3A3F56'} stroke-miterlimit="2.613" stroke-width="1.8">
                                                                                                                <circle cx="1.247" cy="1.247" r="1.247" stroke="none" />
                                                                                                                <circle cx="1.247" cy="1.247" r="2.147" fill="none" />
                                                                                                            </g>
                                                                                                            <g id="Ellipse_78" data-name="Ellipse 78" transform="translate(3.218 0)" fill="none" stroke={item.secondaryDark ? item.secondaryDark : '#3A3F56'} stroke-miterlimit="2.613" stroke-width="1.8">
                                                                                                                <circle cx="1.247" cy="1.247" r="1.247" stroke="none" />
                                                                                                                <circle cx="1.247" cy="1.247" r="2.147" fill="none" />
                                                                                                            </g>
                                                                                                            <path id="Path_254" data-name="Path 254" d="M593.7,888.9c.823,9.461,13.639,8.962,14.844.352l.009-3.917" transform="translate(-586.198 -870.516)" fill="none" stroke={item.secondaryDark ? item.secondaryDark : '#3A3F56'} stroke-miterlimit="2.613" stroke-width="1.8" fill-rule="evenodd" />
                                                                                                        </g>
                                                                                                    </g>
                                                                                                </g>
                                                                                            </svg>
                                                                                            <span style={{ color: item.secondaryLightText }}>{'Gråstarr'.translate(this.props.lang)}</span>
                                                                                        </div>
                                                                                        <div>
                                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="26.122" height="23.8" viewBox="0 0 26.122 23.8">
                                                                                                <g id="Group_212" data-name="Group 212" transform="translate(-64.757 -657.1)">
                                                                                                    <g id="Group_211" data-name="Group 211">
                                                                                                        <path id="Path_66" data-name="Path 66" d="M11,18.273V13.182A2.182,2.182,0,0,1,13.182,11H28.455a2.182,2.182,0,0,1,2.182,2.182v6.574" transform="translate(57 647)" fill="none" stroke={item.secondaryDark ? item.secondaryDark : '#3A3F56'} stroke-linecap="round" stroke-linejoin="round" stroke-width="1.8" />
                                                                                                        <line id="Line_6" data-name="Line 6" x2="12" transform="translate(71.692 661.48)" fill="none" stroke={item.secondaryDark ? item.secondaryDark : '#3A3F56'} stroke-linecap="round" stroke-linejoin="round" stroke-width="1.8" />
                                                                                                        <path id="Path_278" data-name="Path 278" d="M0,0H7" transform="translate(76.692 664.4)" fill="none" stroke={item.secondaryDark ? item.secondaryDark : '#3A3F56'} stroke-linecap="round" stroke-width="1.8" />
                                                                                                        <path id="Path_279" data-name="Path 279" d="M0,0H3.9" transform="translate(75.868 673.45)" fill="none" stroke={item.secondaryDark ? item.secondaryDark : '#3A3F56'} stroke-linecap="round" stroke-width="1.8" />
                                                                                                        <path id="Path_280" data-name="Path 280" d="M0,0H3.9" transform="translate(77.818 671.5) rotate(90)" fill="none" stroke={item.secondaryDark ? item.secondaryDark : '#3A3F56'} stroke-linecap="round" stroke-width="1.8" />
                                                                                                        <path id="Path_277" data-name="Path 277" d="M2.7,0h3.53l3.69,1.886h11.7a2.768,2.768,0,0,1,2.7,2.829v6.6a2.768,2.768,0,0,1-2.7,2.829H2.7A2.768,2.768,0,0,1,0,11.314V2.829A2.768,2.768,0,0,1,2.7,0Z" transform="translate(65.657 665.857)" fill="none" stroke={item.secondaryDark ? item.secondaryDark : '#3A3F56'} stroke-linecap="round" stroke-width="1.8" />
                                                                                                    </g>
                                                                                                </g>
                                                                                            </svg>
                                                                                            <span style={{ color: item.secondaryLightText }}>1000006</span>
                                                                                        </div>
                                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="19.615" viewBox="0 0 30 19.615" className="upper-right-icon">
                                                                                            <g id="iconfinder_eye1_6351969" transform="translate(-1 -5.5)">
                                                                                                <path id="Path_80" data-name="Path 80" d="M19.118,16.414a5.065,5.065,0,0,0,.438-1.663,4.9,4.9,0,0,0-4.791-5,4.9,4.9,0,0,0-4.791,5,4.752,4.752,0,0,0,8.563,2.656A5.047,5.047,0,0,0,19.118,16.414Zm-4.354.41a2.332,2.332,0,0,0,1.567-.609,2.274,2.274,0,0,0,.731-1.464,2.371,2.371,0,0,0-2.3-2.5,2.457,2.457,0,0,0-2.407,2.5A2.406,2.406,0,0,0,14.765,16.824Z" transform="translate(1.288 0.566)" fill={item.secondaryDark ? item.secondaryDark : '#3A3F56'} fill-rule="evenodd" />
                                                                                                <path id="Path_81" data-name="Path 81" d="M1.106,15.622c-.018-.023.018.024,0,0a.517.517,0,0,1,0-.629l.031-.045C5.363,8.781,10.6,5.5,16.016,5.5S26.66,8.772,30.88,14.927a.644.644,0,0,1,.12.38.682.682,0,0,1-.131.4c-4.219,6.143-9.448,9.408-14.852,9.408C10.587,25.115,5.335,21.819,1.106,15.622Zm3.285-1.061a1.2,1.2,0,0,0,0,1.493c3.617,4.5,7.691,6.61,11.625,6.61s8.008-2.112,11.625-6.61a1.2,1.2,0,0,0,0-1.493c-3.617-4.5-7.691-6.609-11.625-6.609S8.009,10.063,4.391,14.561Z" transform="translate(0 0)" fill={item.secondaryDark ? item.secondaryDark : '#3A3F56'} fill-rule="evenodd" />
                                                                                            </g>
                                                                                        </svg>
                                                                                        <div className='lower-right-text'>
                                                                                            <h6>{'Bokat'.translate(this.props.lang)}</h6>
                                                                                            <p style={{ color: item.secondaryLightText }}>(2021-05-26 11:56)</p>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div className='screen-section-container' style={{ background: item.secondaryLight }}>
                                                                                        <h3 style={{ color: item.secondaryLightText }}>{'Ögonoperation'.translate(this.props.lang)}</h3>
                                                                                        <h5 style={{ color: item.secondaryLightText }}>{'Ögonsjukvård'.translate(this.props.lang)}</h5>
                                                                                        <p style={{ color: item.secondaryLightText }}>Agnes Johansson</p>
                                                                                        <div>
                                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="25.203" height="25.19" viewBox="0 0 25.203 25.19">
                                                                                                <g id="Group_239" data-name="Group 239" transform="translate(-1584.127 -1365.657)">
                                                                                                    <g id="Layer_x0020_1" transform="translate(1362.7 1138.097)">
                                                                                                        <g id="_1664416167024" transform="translate(222.3 228.46)">
                                                                                                            <line id="Line_74" data-name="Line 74" y1="0.054" x2="23.43" transform="translate(0 9.194)" fill="none" stroke={item.secondaryDark ? item.secondaryDark : '#3A3F56'} stroke-miterlimit="2.613" stroke-width="1.8" />
                                                                                                            <g id="Group_238" data-name="Group 238" transform="translate(5.641 0)">
                                                                                                                <line id="Line_75" data-name="Line 75" y2="6.156" fill="none" stroke={item.secondaryDark ? item.secondaryDark : '#3A3F56'} stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="2.613" stroke-width="1.8" />
                                                                                                                <line id="Line_76" data-name="Line 76" y2="6.156" transform="translate(12.149)" fill="none" stroke={item.secondaryDark ? item.secondaryDark : '#3A3F56'} stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="2.613" stroke-width="1.8" />
                                                                                                            </g>
                                                                                                            <path id="Path_308" data-name="Path 308" d="M226.215,404.9h18.154a2.63,2.63,0,0,1,2.625,2.625v14.466a2.63,2.63,0,0,1-2.625,2.625H226.215a2.63,2.63,0,0,1-2.625-2.625V407.525A2.63,2.63,0,0,1,226.215,404.9Z" transform="translate(-223.563 -401.225)" fill="none" stroke={item.secondaryDark ? item.secondaryDark : '#3A3F56'} stroke-miterlimit="2.613" stroke-width="1.8" fill-rule="evenodd" />
                                                                                                            <line id="Line_77" data-name="Line 77" x1="0.014" y1="1.939" transform="translate(5.315 18.373)" fill="none" stroke={item.secondaryDark ? item.secondaryDark : '#3A3F56'} stroke-miterlimit="2.613" stroke-width="1.8" />
                                                                                                            <line id="Line_78" data-name="Line 78" x1="0.014" y1="1.939" transform="translate(10.23 13.661)" fill="none" stroke={item.secondaryDark ? item.secondaryDark : '#3A3F56'} stroke-miterlimit="2.613" stroke-width="1.8" />
                                                                                                            <line id="Line_79" data-name="Line 79" x1="0.014" y1="1.939" transform="translate(14.079 18.38)" fill="none" stroke={item.secondaryDark ? item.secondaryDark : '#3A3F56'} stroke-miterlimit="2.613" stroke-width="1.8" />
                                                                                                            <line id="Line_80" data-name="Line 80" x1="0.014" y1="1.939" transform="translate(18.203 13.77)" fill="none" stroke={item.secondaryDark ? item.secondaryDark : '#3A3F56'} stroke-miterlimit="2.613" stroke-width="1.8" />
                                                                                                        </g>
                                                                                                    </g>
                                                                                                </g>
                                                                                            </svg>
                                                                                            <span style={{ color: item.secondaryLightText }}>2021-05-21 08:25</span>
                                                                                        </div>
                                                                                        <div>
                                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="26.314" height="28.032" viewBox="0 0 26.314 28.032">
                                                                                                <g id="Group_589" data-name="Group 589" transform="translate(-20.463 -1277.627)">
                                                                                                    <g id="Layer_x0020_1" transform="translate(-239.387 1051.587)">
                                                                                                        <g id="_1700789009136" transform="translate(260.75 227.84)">
                                                                                                            <path id="Path_253" data-name="Path 253" d="M263.505,283.18l-2.755.059v9.505s.7,6.513,7.51,6.806c0,0,6.352.059,7.452-6.454l.117-9.916-1.755.059" transform="translate(-260.75 -281.933)" fill="none" stroke={item.secondaryDark ? item.secondaryDark : '#3A3F56'} stroke-miterlimit="2.613" stroke-width="1.8" fill-rule="evenodd" />
                                                                                                            <g id="Ellipse_76" data-name="Ellipse 76" transform="translate(9.973 0.102)" fill="none" stroke={item.secondaryDark ? item.secondaryDark : '#3A3F56'} stroke-miterlimit="2.613" stroke-width="1.8">
                                                                                                                <circle cx="1.247" cy="1.247" r="1.247" stroke="none" />
                                                                                                                <circle cx="1.247" cy="1.247" r="2.147" fill="none" />
                                                                                                            </g>
                                                                                                            <g id="Ellipse_77" data-name="Ellipse 77" transform="translate(21.121 12.013)" fill="none" stroke={item.secondaryDark ? item.secondaryDark : '#3A3F56'} stroke-miterlimit="2.613" stroke-width="1.8">
                                                                                                                <circle cx="1.247" cy="1.247" r="1.247" stroke="none" />
                                                                                                                <circle cx="1.247" cy="1.247" r="2.147" fill="none" />
                                                                                                            </g>
                                                                                                            <g id="Ellipse_78" data-name="Ellipse 78" transform="translate(3.218 0)" fill="none" stroke={item.secondaryDark ? item.secondaryDark : '#3A3F56'} stroke-miterlimit="2.613" stroke-width="1.8">
                                                                                                                <circle cx="1.247" cy="1.247" r="1.247" stroke="none" />
                                                                                                                <circle cx="1.247" cy="1.247" r="2.147" fill="none" />
                                                                                                            </g>
                                                                                                            <path id="Path_254" data-name="Path 254" d="M593.7,888.9c.823,9.461,13.639,8.962,14.844.352l.009-3.917" transform="translate(-586.198 -870.516)" fill="none" stroke={item.secondaryDark ? item.secondaryDark : '#3A3F56'} stroke-miterlimit="2.613" stroke-width="1.8" fill-rule="evenodd" />
                                                                                                        </g>
                                                                                                    </g>
                                                                                                </g>
                                                                                            </svg>
                                                                                            <span style={{ color: item.secondaryLightText }}>{'Gråstarr'.translate(this.props.lang)}</span>
                                                                                        </div>
                                                                                        <div>
                                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="26.122" height="23.8" viewBox="0 0 26.122 23.8">
                                                                                                <g id="Group_212" data-name="Group 212" transform="translate(-64.757 -657.1)">
                                                                                                    <g id="Group_211" data-name="Group 211">
                                                                                                        <path id="Path_66" data-name="Path 66" d="M11,18.273V13.182A2.182,2.182,0,0,1,13.182,11H28.455a2.182,2.182,0,0,1,2.182,2.182v6.574" transform="translate(57 647)" fill="none" stroke={item.secondaryDark ? item.secondaryDark : '#3A3F56'} stroke-linecap="round" stroke-linejoin="round" stroke-width="1.8" />
                                                                                                        <line id="Line_6" data-name="Line 6" x2="12" transform="translate(71.692 661.48)" fill="none" stroke={item.secondaryDark ? item.secondaryDark : '#3A3F56'} stroke-linecap="round" stroke-linejoin="round" stroke-width="1.8" />
                                                                                                        <path id="Path_278" data-name="Path 278" d="M0,0H7" transform="translate(76.692 664.4)" fill="none" stroke={item.secondaryDark ? item.secondaryDark : '#3A3F56'} stroke-linecap="round" stroke-width="1.8" />
                                                                                                        <path id="Path_279" data-name="Path 279" d="M0,0H3.9" transform="translate(75.868 673.45)" fill="none" stroke={item.secondaryDark ? item.secondaryDark : '#3A3F56'} stroke-linecap="round" stroke-width="1.8" />
                                                                                                        <path id="Path_280" data-name="Path 280" d="M0,0H3.9" transform="translate(77.818 671.5) rotate(90)" fill="none" stroke={item.secondaryDark ? item.secondaryDark : '#3A3F56'} stroke-linecap="round" stroke-width="1.8" />
                                                                                                        <path id="Path_277" data-name="Path 277" d="M2.7,0h3.53l3.69,1.886h11.7a2.768,2.768,0,0,1,2.7,2.829v6.6a2.768,2.768,0,0,1-2.7,2.829H2.7A2.768,2.768,0,0,1,0,11.314V2.829A2.768,2.768,0,0,1,2.7,0Z" transform="translate(65.657 665.857)" fill="none" stroke={item.secondaryDark ? item.secondaryDark : '#3A3F56'} stroke-linecap="round" stroke-width="1.8" />
                                                                                                    </g>
                                                                                                </g>
                                                                                            </svg>
                                                                                            <span style={{ color: item.primarsecondaryLightTextyDark }}>1000006</span>
                                                                                        </div>
                                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="19.615" viewBox="0 0 30 19.615" className="upper-right-icon">
                                                                                            <g id="iconfinder_eye1_6351969" transform="translate(-1 -5.5)">
                                                                                                <path id="Path_80" data-name="Path 80" d="M19.118,16.414a5.065,5.065,0,0,0,.438-1.663,4.9,4.9,0,0,0-4.791-5,4.9,4.9,0,0,0-4.791,5,4.752,4.752,0,0,0,8.563,2.656A5.047,5.047,0,0,0,19.118,16.414Zm-4.354.41a2.332,2.332,0,0,0,1.567-.609,2.274,2.274,0,0,0,.731-1.464,2.371,2.371,0,0,0-2.3-2.5,2.457,2.457,0,0,0-2.407,2.5A2.406,2.406,0,0,0,14.765,16.824Z" transform="translate(1.288 0.566)" fill={item.secondaryDark ? item.secondaryDark : '#3A3F56'} fill-rule="evenodd" />
                                                                                                <path id="Path_81" data-name="Path 81" d="M1.106,15.622c-.018-.023.018.024,0,0a.517.517,0,0,1,0-.629l.031-.045C5.363,8.781,10.6,5.5,16.016,5.5S26.66,8.772,30.88,14.927a.644.644,0,0,1,.12.38.682.682,0,0,1-.131.4c-4.219,6.143-9.448,9.408-14.852,9.408C10.587,25.115,5.335,21.819,1.106,15.622Zm3.285-1.061a1.2,1.2,0,0,0,0,1.493c3.617,4.5,7.691,6.61,11.625,6.61s8.008-2.112,11.625-6.61a1.2,1.2,0,0,0,0-1.493c-3.617-4.5-7.691-6.609-11.625-6.609S8.009,10.063,4.391,14.561Z" transform="translate(0 0)" fill={item.secondaryDark ? item.secondaryDark : '#3A3F56'} fill-rule="evenodd" />
                                                                                            </g>
                                                                                        </svg>
                                                                                        <div className='lower-right-text'>
                                                                                            <h6 style={{ color: '#EE786C' }}>{'Avbokad tid'.translate(this.props.lang)}</h6>
                                                                                            <p style={{ color: item.secondaryLightText }}>(2021-05-26 11:56)</p>
                                                                                        </div>
                                                                                    </div>

                                                                                </section>
                                                                            </div>
                                                                            <footer className='footer' style={{ background: item.primaryLight }}>
                                                                                <div>
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24.273" height="25.8" viewBox="0 0 24.273 25.8">
                                                                                        <g id="Outline" transform="translate(-13.1 -12.443)">
                                                                                            <path id="Path_26" data-name="Path 26" d="M36.1,21.375,26.117,13.71a1.248,1.248,0,0,0-1.767,0l-9.988,7.666a1.248,1.248,0,0,0-.362.88V37.343H36.473V22.255A1.248,1.248,0,0,0,36.1,21.375Z" transform="translate(0 0)" fill="none" stroke={item.secondaryLightText ? item.secondaryLightText : '#3A3F56'} stroke-linecap="round" stroke-linejoin="round" stroke-width="1.8" />
                                                                                            <path id="Path_27" data-name="Path 27" d="M26.708,31.79h5.481a1.218,1.218,0,0,1,1.218,1.218V43.97H25.49V33.008A1.218,1.218,0,0,1,26.708,31.79Z" transform="translate(-4.218 -6.626)" fill="none" stroke={item.secondaryLightText ? item.secondaryLightText : '#3A3F56'} stroke-linecap="round" stroke-linejoin="round" stroke-width="1.8" />
                                                                                        </g>
                                                                                    </svg>
                                                                                </div>

                                                                                <div className='actove' style={{ background: item.primaryDark }}>
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="25.616" height="24.339" viewBox="0 0 25.616 24.339">
                                                                                        <g id="Group_577" data-name="Group 577" transform="translate(-1652.1 -1456.399)">
                                                                                            <g id="Group_252" data-name="Group 252" transform="translate(-89)">
                                                                                                <g id="Group_248" data-name="Group 248">
                                                                                                    <g id="Group_243" data-name="Group 243">
                                                                                                        <g id="Layer_x0020_1" transform="translate(1584.12 1260.639)">
                                                                                                            <g id="_1664407097696" transform="translate(157.88 196.67)">
                                                                                                                <path id="Path_315" data-name="Path 315" d="M168.67,754.35l-10.79.05a5.437,5.437,0,0,1,5.379-5.524,3.325,3.325,0,0,1,.04-6.648c3.83-.02,4.942,5.948-.008,6.648,0,0,5.08-.225,5.379,5.475Z" transform="translate(-157.88 -731.87)" fill="none" stroke={item.primaryDarkText ? item.primaryDarkText : '#3A3F56'} stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="2.613" stroke-width="1.8" fill-rule="evenodd" />
                                                                                                                <path id="Path_316" data-name="Path 316" d="M524.079,218.431h5.7a5.141,5.141,0,0,0,5.131-5.131v-9.642l-6.438-6.987h-7.437a3.1,3.1,0,0,0-3.094,3.094v5.091" transform="translate(-511.104 -196.67)" fill="none" stroke={item.primaryDarkText ? item.primaryDarkText : '#3A3F56'} stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="2.613" stroke-width="1.8" fill-rule="evenodd" />
                                                                                                                <path id="Path_317" data-name="Path 317" d="M1072.61,196.67l.037,3.59a3.471,3.471,0,0,0,3.515,3.434l2.885-.037" transform="translate(-1055.243 -196.67)" fill="none" stroke={item.primaryDarkText ? item.primaryDarkText : '#3A3F56'} stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="2.613" stroke-width="1.8" fill-rule="evenodd" />
                                                                                                            </g>
                                                                                                        </g>
                                                                                                    </g>
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                    </svg>
                                                                                </div>



                                                                                <div>
                                                                                    <svg
                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                        width="25.616"
                                                                                        height="24.339"
                                                                                        viewBox="0 0 25.616 24.339"
                                                                                        className="header-icon"
                                                                                    >
                                                                                        <g data-name="Group 279" fill="none" stroke={item.secondaryLightText ? item.secondaryLightText : '#3A3F56'} strokeWidth={1.8}>
                                                                                            <path
                                                                                                data-name="Path 379"
                                                                                                d="M776.616 1297.783l2.955-1 3.021 2 3.462-2 3.088 2 2.618-2 2.455 1-.055 21.624-14.93-.1a2.742 2.742 0 01-2.614-2.834v-18.69z"
                                                                                                strokeMiterlimit={2.613}
                                                                                                fillRule="evenodd"
                                                                                                transform="translate(-775.716 -1295.728)"
                                                                                            />
                                                                                            <path
                                                                                                data-name="Path 380"
                                                                                                d="M794.959 1319.409h3.307a1.812 1.812 0 001.643-1.873l-.064-8.91h-4.951"
                                                                                                strokeMiterlimit={2.613}
                                                                                                fillRule="evenodd"
                                                                                                transform="translate(-775.716 -1295.728)"
                                                                                            />
                                                                                            <path
                                                                                                data-name="Path 375"
                                                                                                d="M788.264 1305.325a3.249 3.249 0 00-2.858-1.764c-2.08-.04-3.8.993-3.32 3.229s6.881 1.447 6.74 4.431c-.1 2.2-1.943 2.65-3.619 2.592a3.372 3.372 0 01-2.98-1.841"
                                                                                                strokeLinecap="round"
                                                                                                transform="translate(-775.716 -1295.728)"
                                                                                            />
                                                                                            <path
                                                                                                data-name="Path 376"
                                                                                                d="M785.416 1301.821v13.548"
                                                                                                strokeLinecap="round"
                                                                                                transform="translate(-775.716 -1295.728)"
                                                                                            />
                                                                                        </g>
                                                                                    </svg>
                                                                                </div>

                                                                                <div>
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="26.373" height="25.579" viewBox="0 0 26.373 25.579">
                                                                                        <g id="Group_576" data-name="Group 576" transform="translate(-35.852 -491.324)">
                                                                                            <g id="Layer_x0020_1" transform="translate(-145.393 301.634)">
                                                                                                <g id="_1664356987584" transform="translate(182.84 190.59)">
                                                                                                    <g id="Group_170" data-name="Group 170" transform="translate(6.388 10.652)">
                                                                                                        <line id="Line_12" data-name="Line 12" x1="0.013" y1="2.471" fill="none" stroke={item.secondaryLightText ? item.secondaryLightText : '#3A3F56'} stroke-miterlimit="2.613" stroke-width="1.8" />
                                                                                                        <line id="Line_13" data-name="Line 13" x1="0.013" y1="2.471" transform="translate(5.544)" fill="none" stroke={item.secondaryLightText ? item.secondaryLightText : '#3A3F56'} stroke-miterlimit="2.613" stroke-width="1.8" />
                                                                                                        <line id="Line_14" data-name="Line 14" x1="0.013" y1="2.471" transform="translate(11.088)" fill="none" stroke={item.secondaryLightText ? item.secondaryLightText : '#3A3F56'} stroke-miterlimit="2.613" stroke-width="1.8" />
                                                                                                    </g>
                                                                                                    <path id="Path_217" data-name="Path 217" d="M194.838,190.59a11.879,11.879,0,1,1,0,23.758c-3.017,0-12,.018-12,.018s2.684-4.452,2.113-5.307a11.881,11.881,0,0,1,9.885-18.469Z" transform="translate(-182.84 -190.59)" fill="none" stroke={item.secondaryLightText ? item.secondaryLightText : '#3A3F56'} stroke-miterlimit="2.613" stroke-width="1.8" fill-rule="evenodd" />
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                    </svg>
                                                                                </div>

                                                                                <div>
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="27.875" height="32" viewBox="0 0 27.875 32">
                                                                                        <path id="iconfinder_Notification_4737445" d="M29.52,20.064l-.606-.488a1.394,1.394,0,0,1-.523-1.087V12.859A10.453,10.453,0,0,0,19.965,2.608a2.091,2.091,0,0,0-4.049,0A10.453,10.453,0,0,0,7.484,12.859V18.49a1.394,1.394,0,0,1-.523,1.087l-.606.488A6.272,6.272,0,0,0,4,24.943V26.1a2.788,2.788,0,0,0,2.788,2.788h5.756a5.575,5.575,0,0,0,10.781,0h5.763A2.788,2.788,0,0,0,31.875,26.1V24.957a6.272,6.272,0,0,0-2.355-4.892ZM17.938,30.281a2.788,2.788,0,0,1-2.4-1.394h4.8A2.788,2.788,0,0,1,17.938,30.281ZM29.088,26.1H6.788V24.957A3.484,3.484,0,0,1,8.1,22.239l.606-.488a4.181,4.181,0,0,0,1.568-3.261V12.859a7.666,7.666,0,0,1,15.331,0V18.49a4.181,4.181,0,0,0,1.568,3.268l.606.488a3.484,3.484,0,0,1,1.31,2.711Z" transform="translate(-4 -1.039)" fill={item.secondaryLightText ? item.secondaryLightText : '#3A3F56'} />
                                                                                    </svg>
                                                                                </div>
                                                                            </footer>
                                                                        </div>
                                                                    </div>


                                                                )
                                                        })
                                                        :
                                                        null
                                                }

                                            </div>
                                        </div>
                                        <div style={{ marginTop: 30 }}>
                                            <Button color='primary' onClick={() => this.updateColorScheme()}>
                                                {
                                                    this.state.showPlayer ? (
                                                        <Player
                                                            onEvent={(event) => {
                                                                if (event === "load") this.stopAnimation();
                                                            }}
                                                            onStateChange={this.toggleVisibility}
                                                            ref={this.player} // set the ref to your class instance
                                                            autoplay={true}
                                                            loop={true}
                                                            controls={true}
                                                            src="https://assets8.lottiefiles.com/packages/lf20_ZSRKCF.json"
                                                            style={{ height: "30px", width: "30px" }}
                                                        ></Player>

                                                    ) : this.state.showPlayer2 ? (
                                                        <div style={{ display: 'flex', alignItems: 'center' }}><Player
                                                            onStateChange={this.toggleVisibility}
                                                            ref={this.player2} // set the ref to your class instance
                                                            autoplay={true}
                                                            loop={true}
                                                            controls={true}
                                                            src="https://assets10.lottiefiles.com/packages/lf20_CxyxLk.json"
                                                            style={{ height: "30px", width: "30px" }}
                                                        ></Player>
                                                            {'Saved'.translate(this.props.lang)}
                                                        </div>
                                                    ) : (
                                                        "Save".translate(this.props.lang)
                                                    )
                                                }
                                            </Button>
                                        </div>
                                    </Col>

                                    <Col lg="12" className={this.state.tabIndex == 4 ? 'visibility' : 'none-visibility'}>
                                        <div style={{ marginTop: 50 }}>
                                            {this.state.showForm ?
                                                <>
                                                    <FormBuilderSecond countryTwoLetter={this.props.countryTwoLetter} country={this.props.country} dateFormat={this.props.dateFormat}
                                                        getStringDateTs={this.props.getStringDateTs}
                                                        lang={this.props.lang}
                                                        onSubmit={(data) => { this.updatePaymentSettings(data) }}
                                                        initialValues={this.props.uData.clinicGroups[selectedGroupIdx]}


                                                        buttonText={
                                                            this.state.showPlayer ? (
                                                                <Player
                                                                    onEvent={(event) => {
                                                                        if (event === "load") this.stopAnimation();
                                                                    }}
                                                                    onStateChange={this.toggleVisibility}
                                                                    ref={this.player} // set the ref to your class instance
                                                                    autoplay={true}
                                                                    loop={true}
                                                                    controls={true}
                                                                    src="https://assets8.lottiefiles.com/packages/lf20_ZSRKCF.json"
                                                                    style={{ height: "30px", width: "30px" }}
                                                                ></Player>

                                                            ) : this.state.showPlayer2 ? (
                                                                <div style={{ display: 'flex', alignItems: 'center' }}><Player
                                                                    onStateChange={this.toggleVisibility}
                                                                    ref={this.player2} // set the ref to your class instance
                                                                    autoplay={true}
                                                                    loop={true}
                                                                    controls={true}
                                                                    src="https://assets10.lottiefiles.com/packages/lf20_CxyxLk.json"
                                                                    style={{ height: "30px", width: "30px" }}
                                                                ></Player>
                                                                    {'Saved'.translate(this.props.lang)}
                                                                </div>
                                                            ) : (
                                                                "Save".translate(this.props.lang)
                                                            )
                                                        }


                                                        fields={[
                                                            {
                                                                type: 'row',
                                                                children: [


                                                                    {
                                                                        type: 'col',
                                                                        width: {
                                                                            lg: 8,
                                                                            sm: 12,
                                                                            xs: 12
                                                                        },
                                                                        children: [
                                                                            {
                                                                                type: 'h4',
                                                                                text: <div style={{ marginBottom: 10 }}>
                                                                                    {'Late cancelation period'.translate(this.props.lang)}
                                                                                </div>
                                                                            },

                                                                        ]
                                                                    },
                                                                ]
                                                            },

                                                            {
                                                                type: 'row',
                                                                children: [


                                                                    {
                                                                        type: 'col',
                                                                        width: {
                                                                            lg: 1,
                                                                            sm: 12,
                                                                            xs: 12
                                                                        },
                                                                        children: [
                                                                            {
                                                                                type: 'number',
                                                                                name: 'lateCancelationPeriod',
                                                                                validate: [positiveNumber()],
                                                                                placeholder: '24'
                                                                            },

                                                                        ]
                                                                    },

                                                                    {
                                                                        type: 'col',
                                                                        width: {
                                                                            lg: 7,
                                                                            sm: 12,
                                                                            xs: 12
                                                                        },
                                                                        children: [

                                                                            {
                                                                                type: 'h4',
                                                                                text: <div style={{ top: 5, left: -28, position: 'relative', display: 'flex', fontWeight: 500, fontSize: 16 }}>
                                                                                    {'h before scheduled appointment'.translate(this.props.lang)}
                                                                                    <div className='late-cancelation-field-tooltip-wrap'>
                                                                                        <Isvg src={link} /><div className='group-settings-field-tooltip'>{'If the patient cancels a visit within the period you enter, the payment request will remain.'.translate(this.props.lang)}</div>

                                                                                    </div>
                                                                                </div>
                                                                            },

                                                                        ]
                                                                    },
                                                                ]
                                                            },
                                                            {
                                                                type: 'row',
                                                                children: [

                                                                    {
                                                                        type: 'col',
                                                                        width: {
                                                                            lg: 8,
                                                                            sm: 12,
                                                                            xs: 12
                                                                        },
                                                                        children: [
                                                                            this.props.uData.clinicGroups[selectedGroupIdx]?.canUsePaysonForPayment &&
                                                                            {
                                                                                type: 'h4',
                                                                                text: <div>
                                                                                    {'Payson settings'.translate(this.props.lang)}
                                                                                </div>
                                                                            },



                                                                        ]
                                                                    },

                                                                ]
                                                            },
                                                            {
                                                                type: 'row',
                                                                children: [

                                                                    {
                                                                        type: 'col',
                                                                        width: {
                                                                            lg: 8,
                                                                            sm: 12,
                                                                            xs: 12
                                                                        },
                                                                        children: [

                                                                            this.props.uData.clinicGroups[selectedGroupIdx]?.canUsePaysonForPayment &&
                                                                            {
                                                                                type: 'text',
                                                                                name: 'agentId',
                                                                                label: 'Agent ID'.translate(this.props.lang),
                                                                            },
                                                                            this.props.uData.clinicGroups[selectedGroupIdx]?.canUsePaysonForPayment &&
                                                                            {
                                                                                type: 'text',
                                                                                name: 'apiKey',
                                                                                label: 'API-key'.translate(this.props.lang),
                                                                            },
                                                                            this.props.uData.clinicGroups[selectedGroupIdx]?.canUseSwishForPayment &&
                                                                            {
                                                                                type: 'h4',
                                                                                text: <div>
                                                                                    {'Swish settings'.translate(this.props.lang)}
                                                                                </div>
                                                                            },
                                                                            this.props.uData.clinicGroups[selectedGroupIdx]?.canUseSwishForPayment &&
                                                                            {
                                                                                type: 'text',
                                                                                name: 'bankAccount',
                                                                                label: 'Bank account'.translate(this.props.lang),
                                                                            },
                                                                            this.props.uData.clinicGroups[selectedGroupIdx]?.canUseStripeForPayment &&
                                                                            {
                                                                                type: 'h4',
                                                                                text: <div>
                                                                                    {'Stripe settings'.translate(this.props.lang)}
                                                                                </div>
                                                                            },
                                                                            // this.props.uData.clinicGroups[selectedGroupIdx]?.canUseStripeForPayment &&
                                                                            // {
                                                                            //     type: 'text',
                                                                            //     name: 'stripeAccountID',
                                                                            //     label: 'Stripe Account ID'.translate(this.props.lang),
                                                                            // },


                                                                        ]
                                                                    },

                                                                ]
                                                            },
                                                            {
                                                                type: 'row',
                                                                style: { display: 'flex', alignItems: 'center' },
                                                                children: [
                                                                    {
                                                                        type: 'col',
                                                                        width: {
                                                                            lg: 2,
                                                                            sm: 12,
                                                                            xs: 12
                                                                        },
                                                                        children: [

                                                                            this.props.uData.clinicGroups[selectedGroupIdx]?.canUseStripeForPayment &&
                                                                            {
                                                                                type: 'h4',
                                                                                style: { cursor: 'pointer', borderRadius: 10, backgroundColor: /*'#568AC8'*/'#3a3f56', color: 'white', display: 'flex', justifyContent: 'center' },
                                                                                text: <div onClick={() => {
                                                                                    this.getStripeAccountID()
                                                                                }}>
                                                                                    {
                                                                                        this.state.showPlayerStripe ? (
                                                                                            <Player
                                                                                                onEvent={(event) => {
                                                                                                    if (event === "load") this.stopAnimation();
                                                                                                }}
                                                                                                onStateChange={this.toggleVisibility}
                                                                                                ref={this.player} // set the ref to your class instance
                                                                                                autoplay={true}
                                                                                                loop={true}
                                                                                                controls={true}
                                                                                                src="https://assets8.lottiefiles.com/packages/lf20_ZSRKCF.json"
                                                                                                style={{ height: "30px", width: "30px" }}
                                                                                            ></Player>

                                                                                        ) : this.state.showPlayer2Stripe ? (
                                                                                            <div style={{ display: 'flex', alignItems: 'center' }}><Player
                                                                                                onStateChange={this.toggleVisibility}
                                                                                                ref={this.player2} // set the ref to your class instance
                                                                                                autoplay={true}
                                                                                                loop={true}
                                                                                                controls={true}
                                                                                                src="https://assets10.lottiefiles.com/packages/lf20_CxyxLk.json"
                                                                                                style={{ height: "30px", width: "30px" }}
                                                                                            ></Player>
                                                                                                {'Stripe ID'.translate(this.props.lang)}
                                                                                            </div>
                                                                                        ) : (
                                                                                            'Get Stripe ID'.translate(this.props.lang)
                                                                                        )
                                                                                    }

                                                                                </div>
                                                                            },


                                                                        ]
                                                                    },

                                                                    {
                                                                        type: 'col',
                                                                        width: {
                                                                            lg: 6,
                                                                            sm: 12,
                                                                            xs: 12
                                                                        },
                                                                        children: [


                                                                            this.props.uData.clinicGroups[selectedGroupIdx]?.canUseStripeForPayment &&
                                                                            {
                                                                                type: 'text',
                                                                                name: 'stripeAccountID',
                                                                                disabled: true,
                                                                                label: 'Stripe Account ID'.translate(this.props.lang),
                                                                            },


                                                                        ]
                                                                    },

                                                                ]
                                                            }
                                                        ]}
                                                    ></FormBuilderSecond>

                                                </>
                                                :
                                                null
                                            }
                                        </div>
                                    </Col>

                                    <Col lg='12' className={this.state.tabIndex === 5 ? 'visibility' : 'none-visibility'}>
                                        <div className='groupSettings_eConnect_container' style={{ marginTop: 50 }}>
                                            <h5>
                                                {'Curoflow eConnect'.translate(this.props.lang)}
                                            </h5>
                                            <h6>{'e-Connect'.translate(this.props.lang)}:</h6>
                                            <Row>
                                                <Col lg='6'>
                                                    <div className='groupSetting_eConnect_enabledDisabledStatus'>
                                                        {this.state.clinicGroupSettings?.eConnect && !this.state.clinicGroupSettings?.eConnectTempDisabled ? <SuccessIcon /> : <ErrorIcon />}
                                                        <p>{this.state.clinicGroupSettings?.eConnect ? this.state.clinicGroupSettings?.eConnectTempDisabled ? 'Group has paused the inflow of new eConnect cases' : 'Group can create and receive e-connect cases'.translate(this.props.lang) : 'eConnect is not enabled for this clinic group'}</p>
                                                    </div>
                                                </Col>
                                                <Col lg='6'>
                                                    {this.state.clinicGroupSettings?.eConnect ?
                                                        <Button style={{ padding: 10, borderRadius: 6, paddingRight: 20, paddingLeft: 20 }} size='lg' color={this.state.clinicGroupSettings?.eConnectTempDisabled ? 'info' : 'danger'} onClick={() => {
                                                            if (!this.state.clinicGroupSettings?.eConnect) return;
                                                            let action = this.state.clinicGroupSettings?.eConnectTempDisabled ? 'enable' : 'disable';
                                                            this.setState({ eConnectModal: action })
                                                        }}>
                                                            {this.state.clinicGroupSettings?.eConnectTempDisabled ? 'Enable'.translate(this.props.lang) : 'Disable'.translate(this.props.lang)}
                                                        </Button> : null}
                                                </Col>
                                            </Row>

                                            <hr />

                                            <h6>{'e-Connect External'.translate(this.props.lang)}</h6>
                                            {this.state.clinicGroupSettings?.eConnect && !this.state.clinicGroupSettings?.eConnectTempDisabled && this.state.clinicGroupSettings?.eConnectConfig ? <Row>
                                                <Col lg='6'>
                                                    <div className='groupSetting_eConnect_enabledDisabledStatus'>
                                                        {!this.state.clinicGroupSettings?.eConnectConfig?.externalDisabled ? <SuccessIcon /> : <ErrorIcon />}
                                                        <p>{!this.state.clinicGroupSettings?.eConnectConfig?.externalDisabled ? 'Group can receive external e-connect cases'.translate(this.props.lang) : 'Group will not receive external e-connect cases'}</p>
                                                    </div>
                                                </Col>
                                                <Col lg='6'>
                                                    <Button style={{ padding: 10, borderRadius: 6, paddingRight: 20, paddingLeft: 20 }} size='lg' color={this.state.clinicGroupSettings?.eConnectConfig?.externalDisabled ? 'info' : 'danger'} onClick={() => {
                                                        if (!this.state.clinicGroupSettings?.eConnect) return;
                                                        let action = this.state.clinicGroupSettings?.eConnectConfig?.externalDisabled ? 'enable' : 'disable';
                                                        this.setState({ eConnectExternalModal: action })
                                                    }}>
                                                        {this.state.clinicGroupSettings?.eConnectConfig?.externalDisabled ? 'Enable'.translate(this.props.lang) : 'Disable'.translate(this.props.lang)}
                                                    </Button>
                                                </Col>
                                            </Row> : null}

                                            {this.state.clinicGroupSettings?.eConnect && !this.state.clinicGroupSettings?.eConnectTempDisabled && this.state.clinicGroupSettings?.eConnectConfig && !this.state.clinicGroupSettings?.eConnectConfig?.externalDisabled ? <Row>
                                                <Col lg='6' style={{ marginTop: 10, marginBottom: 10 }}>
                                                    <Label>{'External eConnect signing method'.translate(this.props.lang)}</Label>
                                                    <Checkbox
                                                        checked={this.state.clinicGroupSettings?.eConnectConfig?.externalAuthBankid}
                                                        onChange={(e) => {
                                                            let clinicGroupSettings = this.state.clinicGroupSettings;
                                                            clinicGroupSettings.eConnectConfig.externalAuthBankid = e.target.checked;
                                                            this.setState({ clinicGroupSettings })

                                                        }}
                                                        label={'BankID'.translate(this.props.lang)}
                                                    />
                                                    {
                                                        // netid option disabled for now waiting for test card for more testing
                                                    }
                                                    {/* <Checkbox
                                                        checked={this.state.clinicGroupSettings?.eConnectConfig?.externalAuthNetid}
                                                        onChange={(e) => {
                                                            let clinicGroupSettings = this.state.clinicGroupSettings;
                                                            clinicGroupSettings.eConnectConfig.externalAuthNetid = e.target.checked;
                                                            this.setState({ clinicGroupSettings })

                                                        }}
                                                        label={'SITHS'.translate(this.props.lang)}
                                                    /> */}

                                                    {/* <Input
                                                        type='select'
                                                        required
                                                        value={this.state.clinicGroupSettings?.eConnectConfig?.externalAuth}
                                                        onChange={e => {
                                                            const externalAuth = e?.target?.value;
                                                            const clinicGroupSettings = this.state.clinicGroupSettings;
                                                            clinicGroupSettings.eConnectConfig.externalAuth = externalAuth
                                                            this.setState({ clinicGroupSettings })
                                                        }}
                                                    >
                                                        {this.props.country === 'sweden' ? <option value='bankId'>{'BankID'.translate(this.props.lang)}</option> : null}
                                                        {this.props.country === 'sweden' ? <option value='netId'>{'SITHS'.translate(this.props.lang)}</option> : null}
                                                        <option value='none'>{'No signing method'.translate(this.props.lang)}</option>
                                                    </Input> */}
                                                </Col>
                                                <Col lg='6' style={{ marginTop: 10, marginBottom: 10 }}>
                                                    <FormGroup>
                                                        <Label>{'External page link'.translate(this.props.lang)}</Label>
                                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: 10 }}>
                                                            <Input
                                                                type='text'
                                                                disabled
                                                                value={typeof window !== 'undefined' ? `${window.location.origin}/${this.state.clinicGroupSettings?.alias}/e-connect` : ''}
                                                            />
                                                            <Button color='primary' onClick={() => {
                                                                if (typeof navigator !== 'undefined' && typeof window !== 'undefined') {
                                                                    const link = `${window.location.origin}/${this.state.clinicGroupSettings?.alias}/e-connect`;
                                                                    navigator.clipboard.writeText(link);
                                                                }
                                                            }}>
                                                                {'Copy'.translate(this.props.lang)}
                                                            </Button>
                                                        </div>
                                                    </FormGroup>
                                                </Col>
                                            </Row> : null}

                                            {this.state.clinicGroupSettings?.eConnectConfig ? <Row>
                                                {!this.props.secondaryLanguageDisabled ? <Col lg='6'>
                                                    {this.state.clinicGroupSettings?.eConnect && !this.state.clinicGroupSettings?.eConnectTempDisabled && this.state.clinicGroupSettings?.eConnectConfig && !this.state.clinicGroupSettings?.eConnectConfig?.externalDisabled ? <FormGroup>
                                                        <Label>{'Page Title (enabled)'.translate(this.props.lang)}</Label>
                                                        <Input
                                                            type='text'
                                                            required
                                                            value={this.state.clinicGroupSettings?.eConnectConfig?.enabledTitle?.se}
                                                            onChange={(e) => {
                                                                const val = e?.target?.value;
                                                                const clinicGroupSettings = this.state.clinicGroupSettings;
                                                                clinicGroupSettings.eConnectConfig.enabledTitle.se = val;
                                                                this.setState({ clinicGroupSettings })
                                                            }}
                                                        />
                                                    </FormGroup> : null}
                                                    {this.state.clinicGroupSettings?.eConnect && !this.state.clinicGroupSettings?.eConnectTempDisabled && this.state.clinicGroupSettings?.eConnectConfig && !this.state.clinicGroupSettings?.eConnectConfig?.externalDisabled ? <FormGroup>
                                                        <Label>{'Page Text (enabled)'.translate(this.props.lang)}</Label>
                                                        <Input
                                                            type='textarea'
                                                            rows='5'
                                                            required
                                                            value={this.state.clinicGroupSettings?.eConnectConfig?.enabledText?.se}
                                                            onChange={(e) => {
                                                                const val = e?.target?.value;
                                                                const clinicGroupSettings = this.state.clinicGroupSettings;
                                                                clinicGroupSettings.eConnectConfig.enabledText.se = val;
                                                                this.setState({ clinicGroupSettings })
                                                            }}
                                                        />
                                                    </FormGroup> : null}

                                                    <FormGroup>
                                                        <Label>{'Page Title (disabled)'.translate(this.props.lang)}</Label>
                                                        <Input
                                                            type='text'
                                                            required
                                                            value={this.state.clinicGroupSettings?.eConnectConfig?.disabledTitle?.se}
                                                            onChange={(e) => {
                                                                const val = e?.target?.value;
                                                                const clinicGroupSettings = this.state.clinicGroupSettings;
                                                                clinicGroupSettings.eConnectConfig.disabledTitle.se = val;
                                                                this.setState({ clinicGroupSettings })
                                                            }}
                                                        />
                                                    </FormGroup>

                                                    <FormGroup>
                                                        <Label>{'Page Text (disabled)'.translate(this.props.lang)}</Label>
                                                        <Input
                                                            type='textarea'
                                                            rows='5'
                                                            required
                                                            value={this.state.clinicGroupSettings?.eConnectConfig?.disabledText?.se}
                                                            onChange={(e) => {
                                                                const val = e?.target?.value;
                                                                const clinicGroupSettings = this.state.clinicGroupSettings;
                                                                clinicGroupSettings.eConnectConfig.disabledText.se = val;
                                                                this.setState({ clinicGroupSettings })
                                                            }}
                                                        />
                                                    </FormGroup>
                                                </Col> : null}

                                                <Col lg={this.props.secondaryLanguageDisabled ? '12' : '6'}>
                                                    {this.state.clinicGroupSettings?.eConnect && !this.state.clinicGroupSettings?.eConnectTempDisabled && this.state.clinicGroupSettings?.eConnectConfig && !this.state.clinicGroupSettings?.eConnectConfig?.externalDisabled ? <FormGroup>
                                                        <Label><strong>[EN]</strong> {'Page Title (enabled)'.translate(this.props.lang)}</Label>
                                                        <Input
                                                            type='text'
                                                            required
                                                            value={this.state.clinicGroupSettings?.eConnectConfig?.enabledTitle?.en}
                                                            onChange={(e) => {
                                                                const val = e?.target?.value;
                                                                const clinicGroupSettings = this.state.clinicGroupSettings;
                                                                clinicGroupSettings.eConnectConfig.enabledTitle.en = val;
                                                                this.setState({ clinicGroupSettings })
                                                            }}
                                                        />
                                                    </FormGroup> : null}
                                                    {this.state.clinicGroupSettings?.eConnect && !this.state.clinicGroupSettings?.eConnectTempDisabled && this.state.clinicGroupSettings?.eConnectConfig && !this.state.clinicGroupSettings?.eConnectConfig?.externalDisabled ? <FormGroup>
                                                        <Label><strong>[EN]</strong> {'Page Text (enabled)'.translate(this.props.lang)}</Label>
                                                        <Input
                                                            type='textarea'
                                                            rows='5'
                                                            required
                                                            value={this.state.clinicGroupSettings?.eConnectConfig?.enabledText?.en}
                                                            onChange={(e) => {
                                                                const val = e?.target?.value;
                                                                const clinicGroupSettings = this.state.clinicGroupSettings;
                                                                clinicGroupSettings.eConnectConfig.enabledText.en = val;
                                                                this.setState({ clinicGroupSettings })
                                                            }}
                                                        />
                                                    </FormGroup> : null}
                                                    <FormGroup>
                                                        <Label><strong>[EN]</strong> {'Page Title (disabled)'.translate(this.props.lang)}</Label>
                                                        <Input
                                                            type='text'
                                                            required
                                                            value={this.state.clinicGroupSettings?.eConnectConfig?.disabledTitle?.en}
                                                            onChange={(e) => {
                                                                const val = e?.target?.value;
                                                                const clinicGroupSettings = this.state.clinicGroupSettings;
                                                                clinicGroupSettings.eConnectConfig.disabledTitle.en = val;
                                                                this.setState({ clinicGroupSettings })
                                                            }}
                                                        />
                                                    </FormGroup>

                                                    <FormGroup>
                                                        <Label><strong>[EN]</strong> {'Page Text (disabled)'.translate(this.props.lang)}</Label>
                                                        <Input
                                                            type='textarea'
                                                            rows='5'
                                                            required
                                                            value={this.state.clinicGroupSettings?.eConnectConfig?.disabledText?.en}
                                                            onChange={(e) => {
                                                                const val = e?.target?.value;
                                                                const clinicGroupSettings = this.state.clinicGroupSettings;
                                                                clinicGroupSettings.eConnectConfig.disabledText.en = val;
                                                                this.setState({ clinicGroupSettings })
                                                            }}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row> : null}

                                            <Button style={{ marginTop: 5, marginBottom: 5 }} color='primary' disabled={this.state._saving || this.state._saved || !this.state.clinicGroupSettings?.eConnectConfig || isDisabled} onClick={this.changeEConnectExtText}>
                                                {this.state._saving ? 'Saving'.translate(this.props.lang) : this.state._saved ? 'Saved!'.translate(this.props.lang) : 'Save'.translate(this.props.lang)}
                                            </Button>
                                        </div>
                                    </Col>

                                    <Col lg="12" className={this.state.tabIndex == 6 ? 'visibility' : 'none-visibility'}>
                                        <div style={{ marginTop: 50 }}>
                                            <FormGroup>
                                                <WebBookingOffset
                                                    lang={this.props.lang}
                                                    value={this.state.bookingSettings?.offset && (this.state.bookingSettings?.global || this.state.bookingSettings?.default) ? this.state.bookingSettings?.offset : 15}
                                                    disabledBorderBottom={true}
                                                    disabledBorderTop={true}
                                                    disabled={!this.state.bookingSettings?.global && !this.state.bookingSettings?.default ? true : false}
                                                    labelInfo={'Time-delay description'.translate(this.props.lang)}
                                                    onChange={(value) => {
                                                        let bookingSettings = this.state.bookingSettings ?? {};
                                                        bookingSettings.offset = value;
                                                        this.setState({ bookingSettings }, () => {
                                                            if (!this.state.bookingSettingsChanged) this.setState({ bookingSettingsChanged: true })
                                                        })
                                                    }}
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <Checkbox
                                                    checked={this.state.bookingSettings?.global}
                                                    onChange={(e) => {
                                                        let bookingSettings = this.state.bookingSettings ?? {};
                                                        bookingSettings.global = e.target.checked;
                                                        if (bookingSettings.global) {
                                                            bookingSettings.default = false;
                                                        }
                                                        this.setState({ bookingSettings }, () => {
                                                            if (!this.state.bookingSettingsChanged) this.setState({ bookingSettingsChanged: true })
                                                        })

                                                    }}
                                                    label={'Apply to all web booking forms'.translate(this.props.lang)}
                                                />
                                            </FormGroup>
                                            {
                                                !this.state.bookingSettings?.global ?
                                                    <FormGroup>
                                                        <Checkbox
                                                            checked={this.state.bookingSettings?.default}
                                                            onChange={(e) => {
                                                                let bookingSettings = this.state.bookingSettings ?? {};
                                                                bookingSettings.default = e.target.checked;
                                                                if (bookingSettings.default) {
                                                                    bookingSettings.global = false;
                                                                }
                                                                this.setState({ bookingSettings }, () => {
                                                                    if (!this.state.bookingSettingsChanged) this.setState({ bookingSettingsChanged: true })
                                                                })

                                                            }}
                                                            label={'Use as a default value for new web booking forms'.translate(this.props.lang)}
                                                        />
                                                    </FormGroup>
                                                    :
                                                    null
                                            }


                                            <Button type="submit" style={{ marginTop: 30 }} color="primary" disabled={this.state._loading} onClick={() => {
                                                if (!this.state._loading)
                                                    this.updateBookingSettings()
                                            }}>
                                                {
                                                    this.state.showPlayer ? (
                                                        <Player
                                                            onEvent={(event) => {
                                                                if (event === "load") this.stopAnimation();
                                                            }}
                                                            onStateChange={this.toggleVisibility}
                                                            ref={this.player} // set the ref to your class instance
                                                            autoplay={true}
                                                            loop={true}
                                                            controls={true}
                                                            src="https://assets8.lottiefiles.com/packages/lf20_ZSRKCF.json"
                                                            style={{ height: "30px", width: "30px" }}
                                                        ></Player>

                                                    ) : this.state.showPlayer2 ? (
                                                        <div style={{ display: 'flex', alignItems: 'center' }}><Player
                                                            onStateChange={this.toggleVisibility}
                                                            ref={this.player2} // set the ref to your class instance
                                                            autoplay={true}
                                                            loop={true}
                                                            controls={true}
                                                            src="https://assets10.lottiefiles.com/packages/lf20_CxyxLk.json"
                                                            style={{ height: "30px", width: "30px" }}
                                                        ></Player>
                                                            {'Saved'.translate(this.props.lang)}
                                                        </div>
                                                    ) : (
                                                        "Save".translate(this.props.lang)
                                                    )
                                                }

                                            </Button>
                                        </div>
                                    </Col>


                                    <Col lg="12" className={this.state.tabIndex == 7 ? 'visibility' : 'none-visibility'}>
                                        <div style={{ marginTop: 50 }}>
                                            <h5>
                                                {'WebDoc settings'.translate(this.props.lang)}
                                            </h5>

                                            <FormGroup>
                                                <Checkbox
                                                    checked={this.state.webDocSettings?.autoVisit}
                                                    onChange={(e) => {
                                                        let webDocSettings = this.state.webDocSettings ?? {};
                                                        webDocSettings.autoVisit = e.target.checked;
                                                        this.setState({ webDocSettings }, () => {
                                                            if (!this.state.webDocSettingsChanged) this.setState({ webDocSettingsChanged: true })
                                                        })

                                                    }}
                                                    label={'Auto create visit after export action'.translate(this.props.lang)}
                                                />
                                            </FormGroup>


                                            <Button type="submit" style={{ marginTop: 30 }} color="primary" disabled={this.state._loading} onClick={() => {
                                                if (!this.state._loading)
                                                    this.updateWebDocSettings()
                                            }}>
                                                {
                                                    this.state.showPlayer ? (
                                                        <Player
                                                            onEvent={(event) => {
                                                                if (event === "load") this.stopAnimation();
                                                            }}
                                                            onStateChange={this.toggleVisibility}
                                                            ref={this.player} // set the ref to your class instance
                                                            autoplay={true}
                                                            loop={true}
                                                            controls={true}
                                                            src="https://assets8.lottiefiles.com/packages/lf20_ZSRKCF.json"
                                                            style={{ height: "30px", width: "30px" }}
                                                        ></Player>

                                                    ) : this.state.showPlayer2 ? (
                                                        <div style={{ display: 'flex', alignItems: 'center' }}><Player
                                                            onStateChange={this.toggleVisibility}
                                                            ref={this.player2} // set the ref to your class instance
                                                            autoplay={true}
                                                            loop={true}
                                                            controls={true}
                                                            src="https://assets10.lottiefiles.com/packages/lf20_CxyxLk.json"
                                                            style={{ height: "30px", width: "30px" }}
                                                        ></Player>
                                                            {'Saved'.translate(this.props.lang)}
                                                        </div>
                                                    ) : (
                                                        "Save".translate(this.props.lang)
                                                    )
                                                }

                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                            </div>

                        </Col>
                    </Row>

                </Container>
                {this.state.tabIndexCheck ?
                    <Modal isOpen={this.state.tabIndexCheck} centered>
                        {/* <ModalHeader style={{ margin: 'auto' }}>{'Warning'.translate(this.props.lang)}</ModalHeader> */}
                        <ModalBody className="delete-modal">
                            {/* <img src={warrning} /> */}
                            <p className="message">{'Data is not saved, if you click Yes it will be deleted'.translate(this.props.lang)}</p>
                            <p>{this.props.isDirtySecond}</p>
                        </ModalBody>
                        <ModalFooter className='buttons-right-blue'>
                            <Button color="primary" style={{ margin: 10, minWidth: 100 }} onClick={() => {

                                if (this.state.colorSchemeChanged) {
                                    this.updateColorScheme()
                                } else if (this.state.bookingSettingsChanged) {
                                    this.updateBookingSettings()
                                } else if (this.state.webDocSettingsChanged) {
                                    this.updateWebDocSettings();
                                } else if (this.state.pageBuilderChanged == 'open') {
                                    // this.props.verifyUser(() => {

                                    // })
                                    this.setState({
                                        updateLanding: true,
                                        tabIndex: this.state.tabIndexCheck,
                                        tabIndexCheck: false,
                                        showForm: null,
                                        pageBuilderChanged: 'done'
                                    }, () => {
                                        this.setState({
                                            showForm: true
                                        })
                                        this.props.verifyUser(() => { })
                                    })

                                } else {
                                    this.setState({
                                        changeTabIndex: true
                                    }, () => {
                                        if (this.props.isDirtySecond) {
                                            this.props.dispatch(submit('formSecond'))
                                        } else {
                                            this.props.dispatch(submit('form'))

                                        }
                                    })
                                }


                            }}>{'Save and exit'.translate(this.props.lang)}</Button>


                            <Button color="primary" style={{ margin: 10, minWidth: 100 }} onClick={() => this.setState({ showForm: null, tabIndex: this.state.tabIndexCheck, tabIndexCheck: false, colorSchemeChanged: false, selectedColorScheme: this.state.currentScheme, pageBuilderChanged: false, bookingSettingsChanged: false, webDocSettingsChanged: false }, () => {
                                this.setState({ showForm: true })
                                if (!this.state.pageBuilderChanged) {
                                    this.props.abortAction()
                                    this.props.verifyUser(() => { })
                                }
                            })}>{'Exit without saving'.translate(this.props.lang)}</Button>

                        </ModalFooter>
                    </Modal>
                    : null}
                {this.props.changeLinkModal ?
                    <Modal isOpen={this.props.changeLinkModal} centered>
                        {/* <ModalHeader style={{ margin: 'auto' }}>{'Warning'.translate(this.props.lang)}</ModalHeader> */}
                        <ModalBody className="delete-modal">
                            {/* <img src={warrning} /> */}
                            <p className="message">{'Data is not saved, if you click Yes it will be deleted'.translate(this.props.lang)}</p>
                        </ModalBody>
                        <ModalFooter className='buttons-right-blue'>
                            <Button color="primary" style={{ margin: 10, minWidth: 100 }} onClick={() => {
                                if (this.state.colorSchemeChanged) {
                                    this.updateColorScheme()
                                } else if (this.state.bookingSettingsChanged) {
                                    this.updateBookingSettings()
                                } else if (this.state.webDocSettingsChanged) {
                                    this.updateWebDocSettings();
                                } else if (this.state.pageBuilderChanged == 'open') {
                                    // this.props.verifyUser(() => {

                                    // })
                                    this.setState({
                                        updateLanding: true,

                                        pageBuilderChanged: 'done',
                                        waitResponseFromLandingBulder: true
                                    })

                                } else {
                                    if (this.props.isDirtySecond) {
                                        this.props.dispatch(submit('formSecond'))
                                    } else {
                                        this.props.dispatch(submit('form'))

                                    }
                                }


                            }}>{'Save and exit'.translate(this.props.lang)}</Button>


                            <Button color="primary" style={{ margin: 10, minWidth: 100 }} onClick={() => this.setState({ showForm: null, /*tabIndex: this.state.tabIndexCheck,*/ tabIndexCheck: false, colorSchemeChanged: false, selectedColorScheme: this.state.currentScheme, pageBuilderChanged: false, bookingSettingsChanged: false, webDocSettingsChanged: false }, () => {
                                this.setState({
                                    showForm: true
                                }, () => {
                                    if (this.props.changeLinkModal.indexOf('group|') != -1) {
                                        this.props.changeGroup(this.props.changeLinkModal.split('|')[1])
                                        this.props.abortAction()
                                    } else if (this.props.changeLinkModal.indexOf('clinic|') != -1) {
                                        this.props.changeClinic(this.props.changeLinkModal.split('|')[1]);
                                        this.props.abortAction()
                                    } else if (!this.state.pageBuilderChanged) {
                                        this.props.verifyUser(() => {
                                            this.props[0].history.push(this.props.changeLinkModal)
                                            this.props.abortAction()
                                        })
                                    }
                                })

                                // this.props[0].history.push(this.props.changeLinkModal)
                                // this.props.abortAction()
                            })}>{'Exit without saving'.translate(this.props.lang)}</Button>

                        </ModalFooter>
                    </Modal>
                    : null}

                {this.state.eConnectModal ? <InfoModal
                    isOpen={this.state.eConnectModal}
                    onClose={() => {
                        this.setState({
                            eConnectModal: null
                        })
                    }}
                    info={this.state.eConnectModal === 'enable' ? 'Enable eConnect again? Other clinics will be able to see your clinic in their list, when choosing receiving clinic, and Your clinic will also accept external eConnect cases, unless this option is manually turned off.'.translate(this.props.lang) : 'Temporarily disable eConnect? Other clinics will NOT be able to see your clinic in their list, when choosing receiving clinic, and Your clinic will also NOT accept external eConnect cases.'.translate(this.props.lang)}
                    buttons={[
                        <button style={{ visibility: 'hidden', display: 'none' }} aria-hidden='true'></button>,
                        <Button
                            color={this.state.eConnectModal === 'enable' ? 'info' : 'danger'}
                            style={{ margin: 10, minWidth: 100 }}
                            onClick={this.changeEConnectStatus}
                        >
                            {this.state.eConnectModal === 'enable' ? 'Enable'.translate(this.props.lang) : 'Disable'.translate(this.props.lang)}
                        </Button>,
                        <Button
                            color='primary'
                            style={{ margin: 10, minWidth: 100 }}
                            onClick={() => {
                                this.setState({
                                    eConnectModal: null
                                });
                            }}
                        >
                            {'Cancel'.translate(this.props.lang)}
                        </Button>,
                        <button style={{ visibility: 'hidden', display: 'none' }} aria-hidden='true'></button>
                    ]}
                /> : null}

                {this.state.eConnectExternalModal ? <InfoModal
                    isOpen={this.state.eConnectExternalModal}
                    onClose={() => {
                        this.setState({
                            eConnectExternalModal: null
                        })
                    }}
                    info={this.state.eConnectExternalModal === 'enable' ? 'Enable external eConnect again? Persons that navigate to Your external e-connect cases page will be able to submit external e-connect cases.'.translate(this.props.lang) : 'Temporarily disable external eConnect? Persons that navigate to Your external e-connect cases page will NOT be able to submit external e-connect cases.'.translate(this.props.lang)}
                    buttons={[
                        <button style={{ visibility: 'hidden', display: 'none' }} aria-hidden='true'></button>,
                        <Button
                            color={this.state.eConnectExternalModal === 'enable' ? 'info' : 'danger'}
                            style={{ margin: 10, minWidth: 100 }}
                            onClick={this.changeEConnectExternalStatus}
                        >
                            {this.state.eConnectExternalModal === 'enable' ? 'Enable'.translate(this.props.lang) : 'Disable'.translate(this.props.lang)}
                        </Button>,
                        <Button
                            color='primary'
                            style={{ margin: 10, minWidth: 100 }}
                            onClick={() => {
                                this.setState({
                                    eConnectExternalModal: null
                                });
                            }}
                        >
                            {'Cancel'.translate(this.props.lang)}
                        </Button>,
                        <button style={{ visibility: 'hidden', display: 'none' }} aria-hidden='true'></button>
                    ]}
                /> : null}

                {this.state._updated ? <InfoModal
                    isOpen={this.state._updated}
                    onClose={() => {
                        this.setState({ _updated: false })
                    }}
                    info={'You have successfully updated your eConnect status'.translate(this.props.lang)}
                    buttons={[
                        <Button color='primary' onClick={() => this.setState({ _updated: false })}>{'OK'.translate(this.props.lang)}</Button>
                    ]}
                /> : null}

            </div>
        );
    }
}
const selector = formValueSelector('form');

export default connect(state => {
    return {
        check: selector(state, 'landingConfig.informationLink'),
        loginOutline: selector(state, 'landingConfig.loginOutline'),
        loginColor: selector(state, 'landingConfig.loginColor'),

        isDirty: isDirty('form')(state),
        isDirtySecond: isDirty('formSecond')(state),


    }

})(Page(ClinicData));


