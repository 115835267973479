import React, { Component } from 'react';

import {
    Container,
    Row,
    Col,
    FormGroup, Label, Input,
    Button
} from 'reactstrap';



import ListBuilder from './listBuilder';
import { getSearchParams, generateSearchLink, generateSearchLinkMultiple } from '../helpers/linkHelper';
import { API_ENDPOINT, GOOGLE_API_KEY } from '../constants';
import moment from 'moment';


function parseJSON(response) {
    return response.json().then(json => {
        return {
            result: json,
            status: response.status
        }
    })
}


function getDistance(a, b, c, d, ) {
    return getDst([a, b], [c, d]);
}

function rad(x) {
    return x * Math.PI / 180;
}

function getDst(p1, p2) {
    var R = 6378137; // Earth’s mean radius in meter
    var dLat = rad(p2[0] - p1[0]);
    var dLong = rad(p2[1] - p1[1]);
    var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(rad(p1[0])) * Math.cos(rad(p2[0])) *
        Math.sin(dLong / 2) * Math.sin(dLong / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;
    return (d / 1000).toFixed(1); // returns the distance in km
}

const loadDataWithQuery = [
    (fetchFunction, lang, match, query) => {

        let queryFields = {};
        for (var key in query) {
            if (key && key.indexOf('query-') === 0) {
                queryFields[key.replace('query-', '')] = query[key];
            }
        }
        if (query['_query-region']) {
            queryFields['region'] = query['_query-region'];
        }

        if (query['_query-group']) {
            queryFields['group'] = query['_query-group'];
        }

        return fetchFunction(API_ENDPOINT + '/data/clinics', {
            method: 'POST',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
            body: JSON.stringify({
                page: query.page,
                entries: query.entries,
                filter: query.filter,
                sortType: query.sortType,
                sortField: query.sortField,
                query: queryFields,
                'client-location': query['client-location'],
            })
        }).then(parseJSON).then(({ result, status }) => {
            if (status >= 200 && status < 300) {
                for (let i = 0; i < result.items.length; i++) {
                    if (result.items[i].vantetiderName) {
                        result.items[i].vantetiderName = result.items[i].vantetiderName.replace(/\&amp;/g, '&');
                    }
                }
                return {
                    page: query.page ? query.page : 0,
                    items: result.items,
                    total: result.total,
                    debugQuery: result.debugQuery,
                    _clinicSearchTs: query._ts
                }
            }

        })

    },
    (fetchFunction, lang, match, query) => {


        return fetchFunction(API_ENDPOINT + '/data/regions', {
            method: 'GET',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
        }).then(parseJSON).then(({ result, status }) => {
            if (status >= 200 && status < 300)
                return {
                    regions: result,
                }
        })

    },
    (fetchFunction, lang, match, query) => {


        return fetchFunction(API_ENDPOINT + '/data/groups', {
            method: 'GET',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
        }).then(parseJSON).then(({ result, status }) => {
            if (status >= 200 && status < 300)
                return {
                    groups: result,
                }
        })

    },
    (fetchFunction, lang, match, query) => {
        let queryFields = {};

        if (query['_query-region']) {
            queryFields = { region: query['_query-region'] };
        }


        return fetchFunction(API_ENDPOINT + '/data/cities', {
            method: 'POST',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
            body: JSON.stringify({
                query: queryFields,
                sortField: 'name',
                sortType: 1
            })
        }).then(parseJSON).then(({ result, status }) => {
            if (status >= 200 && status < 300)
                return {
                    cities: result,
                }
        })

    },
    (fetchFunction, lang, match, query) => {
        let queryFields = {};

        if (query['_query-group']) {
            queryFields = { group: query['_query-group'] };
        }


        return fetchFunction(API_ENDPOINT + '/data/subgroups/nonempty', {
            method: 'POST',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
            body: JSON.stringify({
                query: queryFields,
                sortField: 'name',
                sortType: 1

            })
        }).then(parseJSON).then(({ result, status }) => {
            if (status >= 200 && status < 300)
                return {
                    subgroups: result,
                }
        })

    },
]

/**
* List of referrals for selected clinic
* @author   Milan Stanojevic
*/
class ReferralClinics extends Component {
    constructor(props) {
        super(props);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.searchResultsWrap = React.createRef();

        this.state = {
            regions: [],
            cities: [],
            groups: [],
            subgroups: [],
            ...props.initialData,
            useState: true,
            params: {
                entries: 10,
                page: 0,
                'query-group': null
            },
            items: [],
            total: 0,
            loading: true,
            suggestions: [],

        };
    }


    get = () => {
        let params = this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0, 'query-group': null });
        this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
        params._ts = new Date().getTime();

        this.setState({
            _ts: params._ts
        }, () => {
            for (let i = 0; i < loadDataWithQuery.length; i++) {
                loadDataWithQuery[i](window.fetch, this.props.lang, this.props[0].match, params).then((data) => {
                    if (data && data._clinicSearchTs && this.state._ts == data._clinicSearchTs || (data && !data._clinicSearchTs)) {

                        this.setState({
                            ...data,
                            loading: null
                        })
                    }
                })
            }

        })


    }

    componentDidMount() {
        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }

        this.get();
        document.addEventListener('mousedown', this.handleClickOutside);

        let params = getSearchParams(this.props[0].location.search, { entries: 10, page: 0, 'query-group': null });
        if (params['query-search']) {
            this.setState({
                search: params['query-search']
            })
        }

        if (this.props.searchFor) {
            this.updateParams('query-search', this.props.searchFor);
        }
        if (this.props.includeTags) {
            this.updateParams('query-includeTags', this.props.includeTags);
        }

        if ("geolocation" in window.navigator) {
            window.navigator.geolocation.getCurrentPosition((position) => {

                this.setState({
                    currentLocation: position.coords
                })

                this.updateParams('client-location', `${position.coords.latitude},${position.coords.longitude}`);
            });

        }


    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }



    componentDidUpdate(prevProps, prevState) {
        if (prevProps[0].location.search != this.props[0].location.search) {
            this.setState({
                loading: true
            }, () => {
                this.get();

            })

        }

        if (this.state.useState && prevState._useStateTs != this.state._useStateTs) {
            this.setState({
                loading: true
            }, () => {
                this.get();

            })

        }


    }


    updateSort = (field, type) => {
        if (this.state.useState) {
            let state = this.state.params;
            state.sortField = field == 'waitingTime' ? 'waitingMin' : field;
            state.sortType = type;
            this.setState({
                params: state
            })

            this.setState({
                _useStateTs: new Date().getTime()
            })

        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0 }, [{ name: 'sortField', value: field == 'waitingTime' ? 'waitingMin' : field }, { name: 'sortType', value: type }], false));

        }
    }

    updateParams = (name, value, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };
                
                obj[name] = value;

                this.setState({ params: obj });
            } else {
                let obj = this.state.params;
                    obj[name] = value;
              

                this.setState({ params: obj })
            }

            this.setState({
                _useStateTs: new Date().getTime()
            })
        } else {
            this.props[0].history.push(generateSearchLink(this.props[0].location.search, { entries: 10, page: 0 }, name, value, restart));
        }
    }

    updateMultipleParams = (fields, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj });

            } else {
                let obj = this.state.params;
                for (let i = 0; i < fields.length; i++) {
                    
                    obj[fields[i].name] = fields[i].value;
                    
                }
                this.setState({ params: obj })
            }

            this.setState({
                _useStateTs: new Date().getTime()
            })

        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0 }, fields, restart));
        }
    }



    // insertOrUpdate = (data) => {
    //     this.setState({
    //         loading: true
    //     }, () => {
    //         delete data.selectedWaitingTimeType;

    //         if (!this.state.form._id) {

    //             fetch(API_ENDPOINT + '/data/clinics/new', {
    //                 method: 'PUT',
    //                 headers: {
    //                     'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
    //                     'content-type': 'application/json'
    //                 },
    //                 body: JSON.stringify(data)
    //             }).then(res => res.json()).then((result) => {
    //                 this.get();
    //                 this.setState({
    //                     form: null
    //                 })
    //             })
    //         } else {
    //             fetch(API_ENDPOINT + '/data/clinics/' + this.state.form._id, {
    //                 method: 'POST',
    //                 headers: {
    //                     'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
    //                     'content-type': 'application/json'
    //                 },
    //                 body: JSON.stringify(data)
    //             }).then(res => res.json()).then((result) => {
    //                 this.get();
    //                 this.setState({
    //                     form: null
    //                 })
    //             })
    //         }
    //     })
    // }

    // delete = (id) => {
    //     this.setState({
    //         loading: true
    //     }, () => {

    //         fetch(API_ENDPOINT + '/data/clinics/' + id, {
    //             method: 'DELETE',
    //             headers: {
    //                 'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
    //                 'content-type': 'application/json'
    //             },
    //         }).then(res => res.json()).then((result) => {
    //             this.get();
    //         })
    //     })

    // }

    /**
    * Search database for input value
    * @author   Milan Stanojevic
    */
    suggest = () => {

        fetch(API_ENDPOINT + '/data/clinics/suggest', {
            method: 'POST',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
            body: JSON.stringify({
                search: this.state.search
            })
        }).then(res => res.json()).then((result) => {
            this.setState({
                suggestions: result
            })
        })


    }

    handleClickOutside = (event) => {
        if (this.searchResultsWrap && this.searchResultsWrap.current && !this.searchResultsWrap.current.contains(event.target)) {
            this.setState({
                showSuggestions: false
            })
        }
    }


    waitingTimePrint(min, max) {
        if (min === '' && max === '') {
            return 'No data'.translate(this.props.lang);
        } else if (min == '0' && max == '0') {
            return '0' + ' ' + 'weeks'.translate(this.props.lang);
        } else if (min > 0 && max == '0') {
            return min + ' ' + 'weeks minimum'.translate(this.props.lang);
        } else if (min == '0' && max > 0) {
            return max + ' ' + 'weeks maximum'.translate(this.props.lang);
        } else if (min == max) {
            return max + ' ' + 'weeks'.translate(this.props.lang);
        } else return min + ' ' + 'to'.translate(this.props.lang) + ' ' + max + ' ' + 'weeks'.translate(this.props.lang);
    }




    render() {
        let params = {};
        if (this.state.useState) {
            params = this.state.params;
        } else {
            params = getSearchParams(this.props[0].location.search, { entries: 10, page: 0 })
        }

        return (
            <div>


                <Row>
                    <Col lg="12">
                        <Row>
                            <Col lg="12" >
                                <FormGroup>
                                    <Label size="sm">{'Distance measurement'.translate(this.props.lang)}</Label>
                                    <Input type="select" size="sm" value={this.state.distanceType} onChange={(e) => {
                                        this.setState({ distanceType: e.target.value }, async () => {
                                            if (this.props.patientData && (this.props.patientData.street || this.props.patientData.city) && this.state.distanceType == 2) {
                                                let geoRes = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(`${this.props.patientData.street ? this.props.patientData.street : ''} ${this.props.patientData.city ? this.props.patientData.city : ''} ${this.props.patientData.zip ? this.props.patientData.zip : ''} Sweden`)}&key=${GOOGLE_API_KEY}`);
                                                let geoData = await geoRes.json();

                                                if (geoData && geoData.results && geoData.results[0] && geoData.results[0].geometry && geoData.results[0].geometry.location) {
                                                    let position = {
                                                        coords: {
                                                            latitude: geoData.results[0].geometry.location.lat,
                                                            longitude: geoData.results[0].geometry.location.lng
                                                        }
                                                    }
                                                    this.setState({
                                                        currentLocation: position.coords
                                                    })

                                                    this.updateParams('client-location', `${position.coords.latitude},${position.coords.longitude}`);

                                                }

                                            } else {
                                                if ("geolocation" in window.navigator) {
                                                    window.navigator.geolocation.getCurrentPosition((position) => {

                                                        this.setState({
                                                            currentLocation: position.coords
                                                        })

                                                        this.updateParams('client-location', `${position.coords.latitude},${position.coords.longitude}`);
                                                    });

                                                }

                                            }
                                        })

                                    }}>
                                        <option value={1}>{'Use your current location'.translate(this.props.lang)}</option>
                                        <option value={2}>{'Use patient address'.translate(this.props.lang)}</option>
                                    </Input>
                                </FormGroup>
                            </Col>
                            <Col lg="3">
                                <FormGroup>
                                    <select value={params['_query-region'] ? params['_query-region'] : -1} onChange={(e) => this.updateMultipleParams([{ name: '_query-region', value: e.target.value == -1 ? null : e.target.value }, { name: 'query-city', value: null }, { name: 'page', value: 0 }], true)} className="form-control">
                                        {
                                            [{ shortName: 'Select region'.translate(this.props.lang), _id: -1 }, ...this.state.regions].map((item, idx) => {
                                                return (
                                                    <option key={idx} value={item._id}>{item.shortName}</option>
                                                )
                                            })
                                        }
                                    </select>
                                    {
                                        params['_query-region'] ?
                                            <div className="clear-data" onClick={() => {
                                                this.updateMultipleParams(
                                                    [
                                                        { name: '_query-region', value: null },
                                                        { name: 'query-city', value: null }
                                                    ], true
                                                );

                                                this.forceUpdate();

                                            }}>
                                                <i className="fa fa-times-circle" />
                                            </div>
                                            :
                                            null
                                    }

                                </FormGroup>

                            </Col>
                            <Col lg="3">
                                <FormGroup>
                                    <select value={params['query-city'] ? params['query-city'] : -1} onChange={(e) => this.updateMultipleParams([{ name: 'query-city', value: e.target.value == 'Select city' ? null : e.target.value }, { name: 'page', value: 0 }], true)} className="form-control">
                                        {
                                            [{ name: 'Select city'.translate(this.props.lang), _id: -1 }, ...this.state.cities].map((item, idx) => {
                                                return (
                                                    <option key={idx} value={item.name}>{item.name}</option>
                                                )
                                            })
                                        }
                                    </select>

                                    {
                                        params['query-city'] ?
                                            <div className="clear-data" onClick={() => {
                                                this.updateMultipleParams(
                                                    [
                                                        { name: 'query-city', value: null }
                                                    ], true
                                                );

                                                this.forceUpdate();

                                            }}>
                                                <i className="fa fa-times-circle" />
                                            </div>
                                            :
                                            null
                                    }
                                </FormGroup>
                            </Col>
                            <Col lg="3">
                                <FormGroup>
                                    <select value={params['_query-group'] ? params['_query-group'] : -1} onChange={(e) => this.updateMultipleParams([{ name: '_query-group', value: e.target.value == -1 ? null : e.target.value }, { name: 'query-subgroup', value: null }, { name: 'page', value: 0 }], true)} className="form-control">
                                        {
                                            [{ name: 'Select group'.translate(this.props.lang), _id: -1 }, ...this.state.groups].map((item, idx) => {
                                                return (
                                                    <option key={idx} value={item._id}>{item.name}</option>
                                                )
                                            })
                                        }
                                    </select>
                                    {
                                        params['_query-group'] ?
                                            <div className="clear-data" onClick={() => {
                                                this.updateMultipleParams(
                                                    [
                                                        { name: '_query-group', value: null },
                                                        { name: 'query-subgroup', value: null }
                                                    ], true
                                                );

                                                this.forceUpdate();

                                            }}>
                                                <i className="fa fa-times-circle" />
                                            </div>
                                            :
                                            null
                                    }
                                </FormGroup>
                            </Col>
                            <Col lg="3">
                                <FormGroup>
                                    <select value={params['query-subgroup'] ? params['query-subgroup'] : -1} onChange={(e) => this.updateMultipleParams([{ name: 'query-subgroup', value: e.target.value == -1 ? null : e.target.value }, { name: 'page', value: 0 }], true)} className="form-control">
                                        {
                                            [{ name: 'Select subgroup'.translate(this.props.lang), _id: -1 }, ...this.state.subgroups].map((item, idx) => {
                                                return (
                                                    <option key={idx} value={item._id}>{item.name}</option>
                                                )
                                            })
                                        }
                                    </select>
                                    {
                                        params['query-subgroup'] ?
                                            <div className="clear-data" onClick={() => {
                                                this.updateMultipleParams(
                                                    [
                                                        { name: 'query-subgroup', value: null }
                                                    ], true
                                                );

                                                this.forceUpdate();

                                            }}>
                                                <i className="fa fa-times-circle" />
                                            </div>
                                            :
                                            null
                                    }
                                </FormGroup>
                            </Col>

                            <Col lg="12" className="referral-clinics">
                                <FormGroup>
                                    {this.state.showSearch ?
                                        <Input onKeyUp={(e) => {
                                            if (e.keyCode == 13) {
                                                this.setState({
                                                    suggestionIndex: null
                                                })

                                                if (this.state.suggestionIndex != null) {
                                                    let suggestion = JSON.parse(JSON.stringify(this.state.suggestions[this.state.suggestionIndex]));
                                                    this.setState({ search: suggestion.name, suggestions: [] }, () => this.updateMultipleParams(
                                                        [{ name: 'query-subgroup', value: null },
                                                        { name: 'query-article', value: null },
                                                        { name: 'query-search', value: null },
                                                        { name: 'query-city', value: null },
                                                        { name: '_query-region', value: null },
                                                        { name: '_query-group', value: null },
                                                        { name: 'query-' + suggestion.type, value: suggestion.searchValue },
                                                        { name: 'page', value: 0 },
                                                        { name: 'sortType', value: null },
                                                        { name: 'sortField', value: null }
                                                        ], true))

                                                    return;
                                                }

                                                this.setState({ search: this.state.search, suggestions: [] }, () => this.updateMultipleParams([
                                                    { name: 'query-search', value: this.state.search },
                                                    { name: 'query-subgroup', value: null },
                                                    { name: 'query-article', value: null },
                                                    { name: 'query-city', value: null },
                                                    { name: '_query-region', value: null },
                                                    { name: '_query-group', value: null },
                                                    { name: 'page', value: 0 },
                                                    { name: 'sortType', value: null },
                                                    { name: 'sortField', value: null }
                                                ], true))

                                            }
                                            if (e.keyCode == 38) {
                                                this.setState({
                                                    suggestionIndex: this.state.suggestionIndex > 0 ? this.state.suggestionIndex - 1 : 0
                                                })
                                            }
                                            if (e.keyCode == 40) {
                                                this.setState({
                                                    suggestionIndex: this.state.suggestionIndex + 1 >= this.state.suggestions.length ? 0 : this.state.suggestionIndex == null ? 0 : this.state.suggestionIndex + 1
                                                })

                                            }
                                        }} onFocus={() => this.setState({ showSuggestions: true, suggestionIndex: null })} type="text" placeholder={'Search database...'.translate(this.props.lang)} value={this.state.search} onChange={(e) => this.setState({ search: e.target.value, showSuggestions: true }, this.suggest)}></Input>

                                        :

                                        null

                                    }
                                    {this.state.suggestions && this.state.suggestions.length && this.state.showSuggestions ?
                                        <div className="search-results" ref={this.searchResultsWrap}>
                                            <ul >
                                                {
                                                    this.state.suggestions.map((item, idx) => {
                                                        return (
                                                            <li className={this.state.suggestionIndex === idx ? 'active' : null} onClick={() => this.setState({ search: item.name, suggestions: [] }, () => this.updateMultipleParams(
                                                                [{ name: 'query-subgroup', value: null },
                                                                { name: 'query-article', value: null },
                                                                { name: 'query-search', value: null },
                                                                { name: 'query-city', value: null },
                                                                { name: '_query-region', value: null },
                                                                { name: '_query-group', value: null },
                                                                { name: 'query-' + item.type, value: item.searchValue },
                                                                { name: 'page', value: 0 },
                                                                { name: 'sortType', value: null },
                                                                { name: 'sortField', value: null }


                                                                ], true))}>{item.name}</li>
                                                        )
                                                    })
                                                }
                                            </ul>
                                        </div>
                                        :
                                        null
                                    }
                                    {
                                        params['query-search'] && this.state.search ?
                                            <div className="clear-data search-clear-data" onClick={() => {
                                                this.updateMultipleParams(
                                                    [
                                                        { name: 'query-search', value: null },
                                                        { name: 'query-subgroup', value: null },
                                                        { name: 'query-article', value: null },
                                                        { name: 'query-city', value: null },
                                                        { name: '_query-region', value: null },
                                                        { name: '_query-group', value: null },
                                                        { name: 'page', value: 0 },
                                                        { name: 'sortType', value: null },
                                                        { name: 'sortField', value: null }
                                                    ], true
                                                );

                                                this.setState({
                                                    search: ''
                                                })

                                            }}>
                                                <i className="fa fa-times-circle" />
                                            </div>
                                            :
                                            null
                                    }
                                    <Button onClick={() => this.setState({ showSearch: !this.state.showSearch })} color="primary" suze="sm"><i className="fa fa-search" /></Button>

                                </FormGroup>
                            </Col>


                        </Row>
                    </Col>
                </Row>
                <ListBuilder
                    lang={this.props.lang}
                    loading={this.state.loading}
                    total={this.state.total}
                    showNumeration={false}
                    actionLabel={'Import'.translate(this.props.lang)}
                    fields={
                        [
                            { type: 'text', name: 'name', label: 'Clinic name'.translate(this.props.lang), allowSort: true },
                            { type: 'text', name: 'regionName', label: 'Region'.translate(this.props.lang), allowSort: true },
                            { type: 'text', name: 'postAddress', label: 'Address'.translate(this.props.lang), allowSort: true },
                            { type: 'text', name: 'distance', label: 'Distance'.translate(this.props.lang), allowSort: true },
                            { type: 'text', name: 'waitingMin', label: 'Waiting time'.translate(this.props.lang), allowSort: true },
                            { type: 'text', name: 'waitingTimeUpdatedTimestamp', label: 'Updated'.translate(this.props.lang), allowSort: true },


                        ]
                    }
                    items={this.state.items.map((item, idx) => {
                        return {
                            ...item,
                            name: <>{item.vantetiderName ? item.vantetiderName : item.name}<br /><span className="clinic-subgroup-name">{item.subgroupName} {this.props[0].location.pathname !== '/' && item._sortScoreSubgroup ? `(${parseFloat(item._sortScore).toFixed(2)})` : null}</span> </>,
                            distance: this.state.currentLocation && item.coordinates && item.coordinates.indexOf(',') !== -1 ? getDistance(item.coordinates.split(',')[0], item.coordinates.split(',')[1], this.state.currentLocation.latitude, this.state.currentLocation.longitude) + ' km' : '/',
                            waitingMin: this.waitingTimePrint(item.waitingMin, item.waitingMax),
                            waitingTimeUpdatedTimestamp: item.waitingTimeUpdatedTimestamp ? this.props.getStringDateTs(item.waitingTimeUpdatedTimestamp, this.props.dateFormat)/*moment.unix(item.waitingTimeUpdatedTimestamp).format(this.props.dateFormat)*/ : null,
                            postAddress: !item.postAddress ? item.street + ', ' + item.zip + ' ' + item.city : item.postAddress
                        }
                    })}
                    rawItems={this.state.items}
                    actions={
                        [
                            {
                                component: <i className="fa fa-file base-color" />,
                                onClick: (item) => this.props.importClinic(item)
                            },
                        ]
                    }
                    onClick={(item) => this.setState({ clinicView: item })}
                    params={params}
                    sortField={params.sortField}
                    sortType={params.sortType}
                    updateSort={this.updateSort}
                    updateParams={this.updateParams}
                    updateMultipleParams={this.updateMultipleParams}
                ></ListBuilder>




            </div >
        );


    }
}
export default ReferralClinics;