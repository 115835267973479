import React, { Component } from 'react'
import Page from '../../containers/page'

import {
  Container,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
} from 'reactstrap'

import Isvg from 'react-inlinesvg'
import FormBuilder from '../../components/forms/modalFormBuilder'
import { required, emailAddress } from '../../components/forms/validation'

import ListBuilder from '../../components/listBuilder'
import DeleteModal from '../../components/deleteModal'

import Search from '../../components/search'
import Filter from '../../components/filter'
import Counter from '../../components/counter'
import PopularPage from '../../components/popularPages'


import edit from '../../assets/svg/edit.svg'
import garabage from '../../assets/svg/garbage.svg'
import view from '../../assets/svg/eye.svg'

import profile from '../../assets/images/profile-pic.png'

import map from '../../assets/svg/map.svg'
import chart from '../../assets/svg/chart.svg'
import addIcon from '../../assets/svg/add-icon.svg';
import locationIcon from '../../assets/svg/location-icon.svg';
import linkIcon from '../../assets/svg/link-icon.svg';
import phoneIcon from '../../assets/svg/phone-icon.svg';

import profileUser from '../../assets/images/user.png';
import notification from '../../assets/svg/notification.svg'
import moreIcon from '../../assets/svg/more.svg'

import {
  getSearchParams,
  generateSearchLink,
  generateSearchLinkMultiple,
} from '../../helpers/linkHelper'
import { API_ENDPOINT } from '../../constants'
import { formValueSelector, change } from 'redux-form' // ES6
import { connect } from 'react-redux'
import Map from '../../components/map'
import moment from 'moment'

import { Player, Controls } from '@lottiefiles/react-lottie-player';
import Checkbox from '../../components/forms/fields/checkbox';

function getDistance(a, b, c, d) {
  return getDst([a, b], [c, d])
}

function rad(x) {
  return (x * Math.PI) / 180
}

function getDst(p1, p2) {
  var R = 6378137 // Earth’s mean radius in meter
  var dLat = rad(p2[0] - p1[0])
  var dLong = rad(p2[1] - p1[1])
  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(rad(p1[0])) *
    Math.cos(rad(p2[0])) *
    Math.sin(dLong / 2) *
    Math.sin(dLong / 2)
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  var d = R * c
  return (d / 1000).toFixed(1) // returns the distance in km
}

/**
* Clinic list
* @author   Milan Stanojevic
*/
class Clinics extends Component {
  constructor(props) {
    super(props)
    this.handleClickOutside = this.handleClickOutside.bind(this)
    this.searchResultsWrap = React.createRef()
    this.state = {
      regions: [],
      cities: [],
      groups: [],
      subgroups: [],
      formTags: [],
      formRegions: [],
      formSubgroups: [],
      ...props.initialData,
      form: false,
      useState: false,
      params: {
        entries: 10,
        page: 0,
        'query-group': null,
      },
      items: [],
      total: 0,
      loading: true,
      suggestions: [],
      formAdmins: [],
    }
  }

  get = () => {
    let params = this.state.useState
      ? this.state.params
      : getSearchParams(this.props[0].location.search, {
        entries: 10,
        page: 0,
        'query-group': null,
      })
    this.props.updateMeta(
      this.props.generateSeoTags(this.state, this.props.lang)
    )
    params._ts = new Date().getTime()

    this.setState(
      {
        _ts: params._ts,
      },
      () => {
        for (let i = 0; i < this.props.loadData.length; i++) {
          this.props.loadData[i](
            window.fetch,
            this.props.lang,
            this.props[0].match,
            params
          ).then((data) => {
            if (
              (data &&
                data._clinicSearchTs &&
                this.state._ts == data._clinicSearchTs) ||
              (data && !data._clinicSearchTs)
            ) {
              this.setState(
                {
                  ...data,
                  loading: null,
                },
                () => {
                  this.props.updateMeta(
                    this.props.generateSeoTags(this.state, this.props.lang)
                  )
                }
              )
            }
          })
        }

        for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
          this.props.loadDataWithQuery[i](
            window.fetch,
            this.props.lang,
            this.props[0].match,
            params
          ).then((data) => {
            if (
              (data &&
                data._clinicSearchTs &&
                this.state._ts == data._clinicSearchTs) ||
              (data && !data._clinicSearchTs)
            ) {
              this.setState(
                {
                  ...data,
                  loading: null,
                },
                () => {
                  this.props.updateMeta(
                    this.props.generateSeoTags(this.state, this.props.lang)
                  )
                }
              )
            }
          })
        }
      }
    )
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0)
    }

    this.get()
    document.addEventListener('mousedown', this.handleClickOutside)

    let params = getSearchParams(this.props[0].location.search, {
      entries: 10,
      page: 0,
      'query-group': null,
    })
    if (params['query-search']) {
      this.setState({
        search: params['query-search'],
      })
    }

    if ('geolocation' in window.navigator) {
      window.navigator.geolocation.getCurrentPosition((position) => {
        this.setState({
          currentLocation: position.coords,
        })

        this.updateParams(
          'client-location',
          `${position.coords.latitude},${position.coords.longitude}`
        )
      })
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps[0].location.search != this.props[0].location.search) {
      let prevParams = getSearchParams(prevProps[0].location.search)
      let currentParams = getSearchParams(this.props[0].location.search)

      let region, city, group, subgroup, search, entries, page, filter
      if (currentParams?.['_query-region']) {
        for (let i = 0; i < this.state.regions?.length; i++) {
          if (this.state.regions?.[i]?._id == currentParams['_query-region']) {
            region = this.state.regions?.[i]?.shortName
            break
          }
        }
      }

      if (currentParams['query-city']) {
        city = currentParams['query-city']
      }

      if (currentParams?.['_query-group']) {
        for (let i = 0; i < this.state.groups?.length; i++) {
          if (this.state.groups?.[i]?._id == currentParams['_query-group']) {
            group = this.state.groups[i].name
            break
          }
        }
      }
      if (currentParams['query-subgroup']) {
        for (let i = 0; i < this.state.subgroups?.length; i++) {
          if (this.state.subgroups?.[i]?._id == currentParams['query-subgroup']) {
            subgroup = this.state.subgroups[i].name
            break
          }
        }
      }

      if (currentParams['query-search']) {
        search = currentParams['query-search']
      }
      if (currentParams['entries']) {
        entries = currentParams['entries']
      }
      if (currentParams['page']) {
        page = currentParams['page']
      }
      if (currentParams['filter']) {
        filter = currentParams['filter']
      }

      if (region || city || group || subgroup || search)
        this.props.registerLog(
          `Search: ${search ? search : ''} (region: ${region ? region : '-'
          }, city: ${city ? city : '-'}, group: ${group ? group : '-'
          }, subgroup: ${subgroup ? subgroup : '-'}, page: ${page ? page : 0
          }, entries: ${entries ? entries : 10}, filter: ${filter ? filter : '-'
          })`
        )

      this.setState(
        {
          loading: true,
        },
        () => {
          this.get()
        }
      )
    }

    if (
      typeof prevProps.selectedWaitingTimeType != 'undefined' &&
      prevProps.selectedWaitingTimeType != this.props.selectedWaitingTimeType
    ) {
      if (this.props.selectedWaitingTimeType == 0) {
        this.props.changeMinValue('')
        this.props.changeMaxValue('')
      } else if (this.props.selectedWaitingTimeType == 1) {
        this.props.changeMinValue(0)
        this.props.changeMaxValue(0)
      } else if (this.props.selectedWaitingTimeType == 2) {
        this.props.changeMinValue('')
        this.props.changeMaxValue(0)
      } else if (this.props.selectedWaitingTimeType == 3) {
        this.props.changeMinValue(0)
        this.props.changeMaxValue('')
      } else if (this.props.selectedWaitingTimeType == 4) {
        this.props.changeMinValue('')
        this.props.changeMaxValue('')
      } else if (this.props.selectedWaitingTimeType == 5) {
        this.props.changeMinValue('')
        this.props.changeMaxValue('')
      }
    }
  }

  updateSort = (field, type) => {
    if (this.state.useState) {
      let state = this.state.params
      state.sortField = field == 'waitingTime' ? 'waitingMin' : field
      state.sortType = type
      this.setState({
        params: state,
      })
    } else {
      this.props[0].history.push(
        generateSearchLinkMultiple(
          this.props[0].location.search,
          { entries: 10, page: 0 },
          [
            {
              name: 'sortField',
              value: field == 'waitingTime' ? 'waitingMin' : field,
            },
            { name: 'sortType', value: type },
          ],
          false
        )
      )
    }
  }

  updateParams = (name, value, restart = false) => {
    if (this.state.useState) {
      if (restart) {
        let obj = {
          entries: 10,
          page: 0,
        }
        obj[name] = value
        this.setState({ params: obj })
      } else {
        let obj = this.state.params
        obj[name] = value
        this.setState({ params: obj })
      }
    } else {
      this.props[0].history.push(
        generateSearchLink(
          this.props[0].location.search,
          { entries: 10, page: 0 },
          name,
          value,
          restart
        )
      )
    }
  }

  updateMultipleParams = (fields, restart = false) => {
    if (this.state.useState) {
      if (restart) {
        let obj = {
          entries: 10,
          page: 0,
        }
        for (let i = 0; i < fields.length; i++) {
          obj[fields[i].name] = fields[i].value
        }
        this.setState({ params: obj })
      } else {
        let obj = this.state.params
        for (let i = 0; i < fields.length; i++) {
          obj[fields[i].name] = fields[i].value
        }
        this.setState({ params: obj })
      }
    } else {
      this.props[0].history.push(
        generateSearchLinkMultiple(
          this.props[0].location.search,
          { entries: 10, page: 0 },
          fields,
          restart
        )
      )
    }
  }

  /**
  * Insert new or update clinic data
  * @author   Milan Stanojevic
  * @Objectparam    {Object} data     form data
  * @Objectparam    {String} id       clinic id
  */
  insertOrUpdate = (data) => {
    this.setState(
      {
        loading: true,
        _saving: true
      },
      () => {
        delete data.selectedWaitingTimeType;

        if (!this.state.form?._id) {
          fetch(API_ENDPOINT + '/data/clinics/new', {
            method: 'PUT',
            headers: {
              Authorization:
                typeof localStorage !== 'undefined'
                  ? `Bearer ${localStorage.getItem('authToken')}`
                  : null,
              'content-type': 'application/json',
            },
            body: JSON.stringify(data),
          })
            .then((res) => res.json())
            .then((result) => {
              this.get()
              this.setState({
                _saving: false
              }, () => {
                setTimeout(() => {
                  this.setState({ _saving: false, form: null })
                }, 1000)
              })
            })
        } else {
          fetch(API_ENDPOINT + '/data/clinics/' + this.state.form?._id, {
            method: 'POST',
            headers: {
              Authorization:
                typeof localStorage !== 'undefined'
                  ? `Bearer ${localStorage.getItem('authToken')}`
                  : null,
              'content-type': 'application/json',
            },
            body: JSON.stringify(data),
          }).then(res => res.json()).then((result) => {
            this.get();
            this.setState({
              _saving: false
            }, () => {
              setTimeout(() => {
                this.setState({ _saving: false, form: null })
              }, 1000)
            })
          })
        }
      }
    )
  }

  /**
  * Delete clinic data
  * @author   Milan Stanojevic
  * @Objectparam    {String} id       clinic id
  */
  delete = (id) => {
    this.setState(
      {
        loading: true,
      },
      () => {
        fetch(API_ENDPOINT + '/data/clinics/' + id, {
          method: 'DELETE',
          headers: {
            Authorization:
              typeof localStorage !== 'undefined'
                ? `Bearer ${localStorage.getItem('authToken')}`
                : null,
            'content-type': 'application/json',
          },
        })
          .then((res) => res.json())
          .then((result) => {
            this.get()
          })
      }
    )
  }

  /**
  * Function return clinics
  * @author   Milan Stanojevic
  * @Objectparam    {String} search       search param
  */
  suggest = () => {
    fetch(API_ENDPOINT + '/data/clinics/suggest', {
      method: 'POST',
      headers: {
        Authorization:
          typeof localStorage !== 'undefined'
            ? `Bearer ${localStorage.getItem('authToken')}`
            : null,
        'content-type': 'application/json',
      },
      body: JSON.stringify({
        search: this.state.search,
      }),
    })
      .then((res) => res.json())
      .then((result) => {
        this.setState({
          suggestions: result,
        })
      })
  }

  handleClickOutside = (event) => {
    if (
      this.searchResultsWrap &&
      this.searchResultsWrap.current &&
      !this.searchResultsWrap.current.contains(event.target)
    ) {
      this.setState({
        showSuggestions: false,
      })
    }
  }

  waitingTimePrint(min, max) {
    if (min === '' && max === '') {
      return 'No data'.translate(this.props.lang)
    } else if (min == '0' && max == '0') {
      return '0' + ' ' + 'weeks'.translate(this.props.lang)
    } else if (min > 0 && max == '0') {
      return min + ' ' + 'weeks minimum'.translate(this.props.lang)
    } else if (min == '0' && max > 0) {
      return max + ' ' + 'weeks maximum'.translate(this.props.lang)
    } else if (min == max) {
      return max + ' ' + 'weeks'.translate(this.props.lang)
    } else
      return (
        min +
        ' ' +
        'to'.translate(this.props.lang) +
        ' ' +
        max +
        ' ' +
        'weeks'.translate(this.props.lang)
      )
  }

  stopAnimation = () => {
    this.player.current.play();
  }

  render() {
    let params = {}
    if (this.state.useState) {
      params = this.state.params
    } else {
      params = getSearchParams(this.props[0].location.search, {
        entries: 10,
        page: 0,
      })
    }

    const defaultOptions = {
      loop: true,
      autoplay: true,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    };

    const waitingTimeDisabled = [
      [true, true],
      [true, true],
      [false, true],
      [true, false],
      [false, false],
      [false, false],
    ]

    return (
      <div className={this.props.autoHideSidebar ? "dashboard dashboard-auto-hide" : "dashboard"}>

        <Container fluid>
          <Row>
            <Col lg="12">
              {/*
                            <div className="panel">
                                {this.props[0].location.pathname !== '/' ?

                                    <h1>{this.props.linksMeta && this.props.linksMeta[this.props[0].location.pathname] ? this.props.linksMeta[this.props[0].location.pathname][this.props.lang] : ''} <button onClick={() => this.setState({
                                        form: {}
                                    })}><i className="fa fa-plus-circle fa-lg" /></button></h1>

                                    :
                                    <h1>{this.props.linksMeta && this.props.linksMeta[this.props[0].location.pathname] ? this.props.linksMeta[this.props[0].location.pathname][this.props.lang] : ''}</h1>

                                }
                            </div>
                            */}
              <div className="panel">
                <div className="panel-header">
                  <h5 className="component-header">{this.props.linksMeta && this.props.linksMeta[this.props[0].location.pathname] ? this.props.linksMeta[this.props[0].location.pathname][this.props.lang] : ''}</h5>
                  {
                    this.props[0].location.pathname != '/' ?
                      <button className="btn add-button" onClick={() => this.setState({
                        form: {}
                      })}><Isvg src={addIcon} /> {'Add'.translate(this.props.lang)}</button>
                      :
                      null
                  }
                </div>
                <Row>
                  <Col lg="12">
                    <Row>
                      <Col lg="12" >
                        <FormGroup>
                          <Input onKeyUp={(e) => {
                            if (e.keyCode == 13) {
                              this.setState({
                                suggestionIndex: null
                              })

                              if (this.state.suggestionIndex != null) {
                                let suggestion = JSON.parse(JSON.stringify(this.state.suggestions[this.state.suggestionIndex]));
                                this.setState({ search: suggestion.name, suggestions: [] }, () => this.updateMultipleParams(
                                  [{ name: 'query-subgroup', value: null },
                                  { name: 'query-article', value: null },
                                  { name: 'query-search', value: null },
                                  { name: 'query-city', value: null },
                                  { name: '_query-region', value: null },
                                  { name: '_query-group', value: null },
                                  { name: 'query-' + suggestion.type, value: suggestion.searchValue },
                                  { name: 'page', value: 0 },
                                  { name: 'sortType', value: null },
                                  { name: 'sortField', value: null }
                                  ], true))

                                return;
                              }

                              this.setState({ search: this.state.search, suggestions: [] }, () => this.updateMultipleParams([
                                { name: 'query-search', value: this.state.search },
                                { name: 'query-subgroup', value: null },
                                { name: 'query-article', value: null },
                                { name: 'query-city', value: null },
                                { name: '_query-region', value: null },
                                { name: '_query-group', value: null },
                                { name: 'page', value: 0 },
                                { name: 'sortType', value: null },
                                { name: 'sortField', value: null }
                              ], true))

                            }
                            if (e.keyCode == 38) {
                              this.setState({
                                suggestionIndex: this.state.suggestionIndex > 0 ? this.state.suggestionIndex - 1 : 0
                              })
                            }
                            if (e.keyCode == 40) {
                              this.setState({
                                suggestionIndex: this.state.suggestionIndex + 1 >= this.state.suggestions.length ? 0 : this.state.suggestionIndex == null ? 0 : this.state.suggestionIndex + 1
                              })

                            }
                          }} onFocus={() => this.setState({ showSuggestions: true, suggestionIndex: null })} type="text" placeholder={'Search database...'.translate(this.props.lang)} value={this.state.search} onChange={(e) => this.setState({ search: e.target.value, showSuggestions: true }, this.suggest)}></Input>
                          {this.state.suggestions && this.state.suggestions.length && this.state.showSuggestions ?
                            <div className="search-results" ref={this.searchResultsWrap}>
                              <ul >
                                {
                                  this.state.suggestions.map((item, idx) => {
                                    return (
                                      <li className={this.state.suggestionIndex === idx ? 'active' : null} onClick={() => this.setState({ search: item.name, suggestions: [] }, () => this.updateMultipleParams(
                                        [{ name: 'query-subgroup', value: null },
                                        { name: 'query-article', value: null },
                                        { name: 'query-search', value: null },
                                        { name: 'query-city', value: null },
                                        { name: '_query-region', value: null },
                                        { name: '_query-group', value: null },
                                        { name: 'query-' + item.type, value: item.searchValue },
                                        { name: 'page', value: 0 },
                                        { name: 'sortType', value: null },
                                        { name: 'sortField', value: null }


                                        ], true))}>{item.name}</li>
                                    )
                                  })
                                }
                              </ul>
                            </div>
                            :
                            null
                          }
                          {
                            params['query-search'] && this.state.search ?
                              <div className="clear-data" onClick={() => {
                                this.updateMultipleParams(
                                  [
                                    { name: 'query-search', value: null },
                                    { name: 'query-subgroup', value: null },
                                    { name: 'query-article', value: null },
                                    { name: 'query-city', value: null },
                                    { name: '_query-region', value: null },
                                    { name: '_query-group', value: null },
                                    { name: 'page', value: 0 },
                                    { name: 'sortType', value: null },
                                    { name: 'sortField', value: null }
                                  ], true
                                );

                                this.setState({
                                  search: ''
                                })

                              }}>
                                <i className="fa fa-times-circle" />
                              </div>
                              :
                              null
                          }
                        </FormGroup>
                      </Col>

                      <Col lg="3">
                        <FormGroup>
                          <select value={params['_query-region'] ? params['_query-region'] : -1} onChange={(e) => this.updateMultipleParams([{ name: '_query-region', value: e.target.value == -1 ? null : e.target.value }, { name: 'query-city', value: null }, { name: 'page', value: 0 }], true)} className="form-control">
                            {
                              [{ shortName: 'Select region'.translate(this.props.lang), _id: -1 }, ...this.state.regions].map((item, idx) => {
                                return (
                                  <option key={idx} value={item?._id}>{item.shortName}</option>
                                )
                              })
                            }
                          </select>
                          {
                            params['_query-region'] ?
                              <div className="clear-data" onClick={() => {
                                this.updateMultipleParams(
                                  [
                                    { name: '_query-region', value: null },
                                    { name: 'query-city', value: null }
                                  ], true
                                );

                                this.forceUpdate();

                              }}>
                                <i className="fa fa-times-circle" />
                              </div>
                              :
                              null
                          }

                        </FormGroup>

                      </Col>
                      <Col lg="3">
                        <FormGroup>
                          <select value={params['query-city'] ? params['query-city'] : -1} onChange={(e) => this.updateMultipleParams([{ name: 'query-city', value: e.target.value == 'Select city' ? null : e.target.value }, { name: 'page', value: 0 }], true)} className="form-control">
                            {
                              [{ name: 'Select city'.translate(this.props.lang), _id: -1 }, ...this.state.cities].map((item, idx) => {
                                return (
                                  <option key={idx} value={item.name}>{item.name}</option>
                                )
                              })
                            }
                          </select>

                          {
                            params['query-city'] ?
                              <div className="clear-data" onClick={() => {
                                this.updateMultipleParams(
                                  [
                                    { name: 'query-city', value: null }
                                  ], true
                                );

                                this.forceUpdate();

                              }}>
                                <i className="fa fa-times-circle" />
                              </div>
                              :
                              null
                          }
                        </FormGroup>
                      </Col>
                      <Col lg="3">
                        <FormGroup>
                          <select value={params['_query-group'] ? params['_query-group'] : -1} onChange={(e) => this.updateMultipleParams([{ name: '_query-group', value: e.target.value == -1 ? null : e.target.value }, { name: 'query-subgroup', value: null }, { name: 'page', value: 0 }], true)} className="form-control">
                            {
                              [{ name: 'Select group'.translate(this.props.lang), _id: -1 }, ...this.state.groups].map((item, idx) => {
                                return (
                                  <option key={idx} value={item?._id}>{item.name}</option>
                                )
                              })
                            }
                          </select>
                          {
                            params['_query-group'] ?
                              <div className="clear-data" onClick={() => {
                                this.updateMultipleParams(
                                  [
                                    { name: '_query-group', value: null },
                                    { name: 'query-subgroup', value: null }
                                  ], true
                                );

                                this.forceUpdate();

                              }}>
                                <i className="fa fa-times-circle" />
                              </div>
                              :
                              null
                          }
                        </FormGroup>
                      </Col>
                      <Col lg="3">
                        <FormGroup>
                          <select value={params['query-subgroup'] ? params['query-subgroup'] : -1} onChange={(e) => this.updateMultipleParams([{ name: 'query-subgroup', value: e.target.value == -1 ? null : e.target.value }, { name: 'page', value: 0 }], true)} className="form-control">
                            {
                              [{ name: 'Select subgroup'.translate(this.props.lang), _id: -1 }, ...this.state.subgroups].map((item, idx) => {
                                return (
                                  <option key={idx} value={item?._id}>{item.name}</option>
                                )
                              })
                            }
                          </select>
                          {
                            params['query-subgroup'] ?
                              <div className="clear-data" onClick={() => {
                                this.updateMultipleParams(
                                  [
                                    { name: 'query-subgroup', value: null }
                                  ], true
                                );

                                this.forceUpdate();

                              }}>
                                <i className="fa fa-times-circle" />
                              </div>
                              :
                              null
                          }
                        </FormGroup>
                      </Col>
                      {
                        this.props[0].location.pathname.indexOf('data-managment') !== -1 ?
                          <Col lg="6">
                            <FormGroup >
                              <Checkbox checked={params['query-_autoInsert'] ? true : false} onChange={() => {
                                this.updateMultipleParams([{ name: 'query-_autoInsert', value: !params['query-_autoInsert'] ? 'vantetider' : null }, { name: 'page', value: 0 }], true)
                              }} label={'Display only auto inserted clinics'.translate(this.props.lang)} />
                            </FormGroup>
                          </Col>
                          :

                          null
                      }
                      {
                        this.props[0].location.pathname.indexOf('data-managment') !== -1 ?
                          <Col lg="6">
                            <FormGroup >
                              <Checkbox checked={params['query-vantetiderTestQuery'] ? true : false} onChange={() => {
                                this.updateMultipleParams([{ name: 'query-vantetiderTestQuery', value: !params['query-vantetiderTestQuery'] ? 'true' : null }, { name: 'page', value: 0 }], true)
                              }} label={'Display clinics with different name and vantetiderName'.translate(this.props.lang)} />
                            </FormGroup>
                          </Col>
                          :

                          null
                      }

                    </Row>
                  </Col>
                </Row>
              </div>

              {this.state.debugQuery && Object.keys(this.state.debugQuery).length ?

                <div className="panel">
                  <h4>{'Debug Query'.translate(this.props.lang)}</h4>

                  <ul className="debug-tree">
                    {
                      this.state.debugQuery.region ?
                        <li>
                          <div className="category-name">
                            <strong>{'Region'.translate(this.props.lang)}:</strong> {this.state.debugQuery.region}
                          </div>
                        </li>
                        :
                        null
                    }
                    {
                      this.state.debugQuery.city ?
                        <li>
                          <div className="category-name">
                            <strong>{'City'.translate(this.props.lang)}:</strong> {this.state.debugQuery.city}
                          </div>
                        </li>
                        :
                        null
                    }
                    {
                      this.state.debugQuery.subgroups ?
                        this.state.debugQuery.subgroups.map((subgroup) => {
                          return (
                            <li>
                              <div className="category-name">
                                <strong>{'Subgroup'.translate(this.props.lang)}:</strong> {subgroup.name}
                              </div>
                            </li>

                          )
                        })
                        :
                        null
                    }

                    {
                      this.state.debugQuery.categories ?
                        this.state.debugQuery.categories.map((category) => {
                          return (
                            <li className="active">
                              <div className="category-name">
                                {'Category'.translate(this.props.lang)}: {category.name}
                                <div className="subgroups">
                                  <strong>{'Subgroups'.translate(this.props.lang)}:</strong> {category.subgroups && category.subgroups.map(item => item.name).join(', ')}
                                </div>
                              </div>
                            </li>

                          )
                        })

                        :
                        null
                    }

                    {
                      this.state.debugQuery.subcategories ?
                        this.state.debugQuery.subcategories.map((item, idx) => {
                          return (
                            <li className="active">
                              <div className="category-name">
                                {'Subcategory'.translate(this.props.lang)}: {item.name}
                                <div className="subgroups">
                                  <strong>{'Subgroups'.translate(this.props.lang)}:</strong> {item.subgroups.map(item => item.name).join(', ')}
                                </div>
                              </div>
                              {item.category ?
                                <ul>
                                  <li className="active">
                                    <div className="subcategory-name">
                                      {'Category'.translate(this.props.lang)}: {item.category.name}
                                      <div className="subgroups">
                                        <strong>{'Subgroups'.translate(this.props.lang)}:</strong> {item.category && item.category.subgroups.map(item => item.name).join(', ')}
                                      </div>
                                    </div>
                                  </li>
                                </ul>
                                :
                                null
                              }
                            </li>

                          )
                        })

                        :
                        null
                    }

                    {
                      this.state.debugQuery.tags ?
                        this.state.debugQuery.tags.map((tag) => {
                          return (
                            <li className="active">
                              <div className="category-name">
                                <strong>{'Tag'.translate(this.props.lang)}: </strong> {tag.name}
                              </div>

                              <ul>
                                {
                                  tag.subcategories && tag.subcategories.map((subCategory) => {
                                    return (
                                      <li className="active">
                                        <div className="subcategory-name">
                                          {'Subcategory'.translate(this.props.lang)}: {subCategory.name}
                                          <div className="subgroups">
                                            <strong>{'Subgroups'.translate(this.props.lang)}:</strong> {subCategory.subgroups.map(item => item.name).join(', ')}
                                          </div>
                                        </div>
                                        {subCategory.category ?
                                          <ul>
                                            <li className="active">
                                              <div className="subcategory-name">
                                                {'Category'.translate(this.props.lang)}: {subCategory.category.name}
                                                <div className="subgroups">
                                                  <strong>{'Subgroups'.translate(this.props.lang)}:</strong> {subCategory.category.subgroups && subCategory.category.subgroups.map(item => item.name).join(', ')}
                                                </div>
                                              </div>
                                            </li>
                                          </ul>
                                          :
                                          null

                                        }
                                      </li>
                                    )
                                  })
                                }
                              </ul>

                            </li>

                          )
                        })


                        :
                        null
                    }


                  </ul>

                </div>

                :
                null
              }



              {params['_query-group'] || params['query-subgroup'] || params['_query-region'] || params['query-city'] || params['query-search'] || params['query-_autoInsert'] || params['query-vantetiderTestQuery'] ?
                <div className="panel">
                  <ListBuilder
                    lang={this.props.lang}
                    loading={this.state.loading}
                    total={this.state.total}
                    showNumeration={false}
                    fields={
                      this.props[0].location.pathname !== '/' ?
                        [
                          { type: 'text', name: 'name', label: 'Clinic name'.translate(this.props.lang), allowSort: true },
                          { type: 'text', name: 'regionName', label: 'Region'.translate(this.props.lang), allowSort: true },
                          { type: 'text', name: 'street', label: 'Address'.translate(this.props.lang), allowSort: true },
                          { type: 'text', name: 'distance', label: 'Distance'.translate(this.props.lang), allowSort: true },
                          { type: 'text', name: 'waitingMin', label: 'Waiting time'.translate(this.props.lang), allowSort: true },


                        ]

                        :
                        [
                          { type: 'text', name: 'name', label: 'Clinic name'.translate(this.props.lang), allowSort: true },
                          { type: 'text', name: 'regionName', label: 'Region'.translate(this.props.lang), allowSort: true },
                          { type: 'text', name: 'street', label: 'Address'.translate(this.props.lang), allowSort: true },
                          { type: 'text', name: 'distance', label: 'Distance'.translate(this.props.lang), allowSort: true },
                          { type: 'text', name: 'waitingMin', label: 'Waiting time'.translate(this.props.lang), allowSort: true },
                          { type: 'text', name: 'waitingTimeUpdatedTimestamp', label: 'Updated'.translate(this.props.lang), allowSort: true },


                        ]



                    }
                    items={this.state.items.map((item, idx) => {
                      return {
                        ...item,
                        name: <>{item.name}<br /><span className="clinic-subgroup-name">{item.subgroupName} {this.props[0].location.pathname !== '/' && item._sortScoreSubgroup ? `(${parseFloat(item._sortScore).toFixed(2)})` : null}</span> </>,
                        distance: this.state.currentLocation && item.coordinates && item.coordinates.indexOf(',') !== -1 ? getDistance(item.coordinates.split(',')[0], item.coordinates.split(',')[1], this.state.currentLocation.latitude, this.state.currentLocation.longitude) + ' km' : '/',
                        waitingMin: this.waitingTimePrint(item.waitingMin, item.waitingMax),
                        waitingTimeUpdatedTimestamp: item.waitingTimeUpdatedTimestamp ? this.props.getStringDateTs(item.waitingTimeUpdatedTimestamp, this.props.dateFormat)/*moment.unix(item.waitingTimeUpdatedTimestamp).format(this.props.dateFormat)*/ : null
                      }
                    })}
                    rawItems={this.state.items}
                    actions={
                      this.props[0].location.pathname == '/data-managment/clinics' ?
                        [
                          {
                            component: <Isvg src={view} className="view-icon" />,
                            onClick: (item) => {
                              this.props.registerLog(`Open clinic "${item.name}"`);
                              this.setState({ clinicView: item })
                            }
                          },
                          {
                            component: <Isvg src={edit} className="edit-icon" />,
                            onClick: (item) => {
                              let form = item;

                              if (form.waitingMin == '' && form.waitingMax == '') {
                                form.selectedWaitingTimeType = 0;
                              }
                              if (form.waitingMin === 0 && form.waitingMax === 0) {
                                form.selectedWaitingTimeType = 1;
                              }
                              if (form.waitingMin != '' && form.waitingMax == '') {
                                form.selectedWaitingTimeType = 2;
                              }
                              if (form.waitingMin == '' && form.waitingMax != '') {
                                form.selectedWaitingTimeType = 3;
                              }

                              if (form.waitingMin != '' && form.waitingMax != '') {
                                form.selectedWaitingTimeType = 5;
                              }

                              if (form.waitingMin != '' && form.waitingMax != '' && form.waitingMax == form.waitingMin) {
                                form.selectedWaitingTimeType = 4;
                              }


                              this.setState({ form: form })
                            }
                          },
                          {
                            component: <Isvg src={garabage} className="garabage-icon" />,
                            onClick: (item) => this.setState({ deleteModal: item })
                          },
                        ]
                        :
                        null
                    }
                    onClick={(item) => this.setState({ clinicView: item })}
                    params={params}
                    sortField={params.sortField}
                    sortType={params.sortType}
                    updateSort={this.updateSort}
                    updateParams={this.updateParams}
                    updateMultipleParams={this.updateMultipleParams}
                  ></ListBuilder>
                  <DeleteModal
                    lang={this.props.lang}
                    isOpen={this.state.deleteModal}
                    toggle={() => this.setState({ deleteModal: null })}
                    handler={() => { this.delete(this.state.deleteModal?._id); this.setState({ deleteModal: null }) }}
                  >
                    {'Delete clinic'.translate(this.props.lang)} <strong>{this.state.deleteModal && this.state.deleteModal.name}</strong>?
                  </DeleteModal>

                </div>
                :
                null

              }
            </Col>
          </Row>

        </Container>
        {
          this.state.form ?
            <FormBuilder selectedGroup={this.props.selectedGroup} selectedClinic={this.props.selectedClinic} countryTwoLetter={this.props.countryTwoLetter} country={this.props.country} dateFormat={this.props.dateFormat}
              getStringDateTs={this.props.getStringDateTs}
              lang={this.props.lang}
              onSubmit={(data) => { this.insertOrUpdate(data) }}
              initialValues={{ ...this.state.form }}
              isOpen={this.state.form}
              toggle={() => this.setState({ form: null })}
              title={this.state.form?._id ? 'Edit entry'.translate(this.props.lang) : 'Add new clinic'.translate(this.props.lang)}
              _saving={this.state._saving}
              buttonConfirmText={'Saved'.translate(this.props.lang)}
              buttonText={'Save'.translate(this.props.lang)}

              fields={[
                {
                  type: 'row',
                  children: [
                    /*---- Col 7 ----*/
                    {
                      type: 'col',
                      width: {
                        lg: 7,
                        sm: 12,
                        xs: 12
                      },
                      children: [
                        {
                          type: 'row',
                          children: [
                            {
                              type: 'col',
                              width: {
                                lg: 12,
                                sm: 12,
                                xs: 12
                              },
                              children: [
                                {
                                  type: 'text',
                                  name: this.state.form.vantetiderName ? 'vantetiderName' : 'name',
                                  label: 'Clinic name'.translate(this.props.lang),
                                  validate: [required('Clinic name is required!'.translate(this.props.lang))],
                                },
                              ]
                            },
                            {
                              type: 'col',
                              width: {
                                lg: 12,
                                sm: 12,
                                xs: 12
                              },
                              children: [
                                {
                                  type: 'text',
                                  name: 'oldClinicName',
                                  label: 'Old clinic name'.translate(this.props.lang),
                                },
                              ]
                            },
                            {
                              type: 'col',
                              width: {
                                lg: 12,
                                sm: 12,
                                xs: 12
                              },
                              children: [
                                {
                                  type: 'textarea',
                                  name: 'description',
                                  label: 'Description'.translate(this.props.lang),
                                },
                              ]
                            },
                            {
                              type: 'col',
                              width: {
                                lg: 12,
                                sm: 12,
                                xs: 12
                              },
                              children: [
                                {
                                  type: 'select',
                                  multiple: true,
                                  name: 'tags',
                                  label: 'Select tags (hold CTRL to select multiple tags)'.translate(this.props.lang),
                                  values: this.state.formTags.map((item, idx) => { return { value: item?._id, name: item?.name } })

                                },
                              ]
                            },
                            {
                              type: 'col',
                              width: {
                                lg: 12,
                                sm: 12,
                                xs: 12
                              },
                              children: [
                                {
                                  type: 'text',
                                  name: 'street',
                                  label: 'Street name and number'.translate(this.props.lang),
                                },
                              ]
                            },
                            {
                              type: 'col',
                              width: {
                                lg: 6,
                                sm: 12,
                                xs: 12
                              },
                              children: [
                                {
                                  type: 'text',
                                  name: 'zip',
                                  label: 'Zip code'.translate(this.props.lang),
                                },
                              ]
                            },
                            {
                              type: 'col',
                              width: {
                                lg: 6,
                                sm: 12,
                                xs: 12
                              },
                              children: [
                                {
                                  type: 'text',
                                  name: 'city',
                                  label: 'City'.translate(this.props.lang),
                                },
                              ]
                            },
                            {
                              type: 'col',
                              width: {
                                lg: 12,
                                sm: 12,
                                xs: 12
                              },
                              children: [
                                {
                                  type: 'text',
                                  name: 'postAddress',
                                  label: 'Mailing address'.translate(this.props.lang),
                                },
                              ]
                            },
                            {
                              type: 'col',
                              width: {
                                lg: 6,
                                sm: 12,
                                xs: 12
                              },
                              children: [
                                {
                                  type: 'text',
                                  name: 'phone',
                                  label: 'Phone'.translate(this.props.lang),
                                },
                              ]
                            },
                            {
                              type: 'col',
                              width: {
                                lg: 6,
                                sm: 12,
                                xs: 12
                              },
                              children: [
                                {
                                  type: 'text',
                                  name: 'email',
                                  label: 'Email'.translate(this.props.lang),
                                },
                              ]
                            },
                            {
                              type: 'col',
                              width: {
                                lg: 12,
                                sm: 12,
                                xs: 12
                              },
                              children: [
                                {
                                  type: 'text',
                                  name: 'webSite',
                                  label: 'Web address'.translate(this.props.lang),
                                },
                              ]
                            },
                            {
                              type: 'col',
                              width: {
                                lg: 12,
                                sm: 12,
                                xs: 12
                              },
                              children: [
                                {
                                  type: 'text',
                                  name: 'coordinates',
                                  label: 'Google map coordinates'.translate(this.props.lang),
                                },
                              ]
                            },
                          ]
                        },

                        /*---- Col 7 ----*/
                      ]
                    },
                    {
                      type: 'col',
                      width: {
                        lg: 5,
                        sm: 12,
                        xs: 12
                      },
                      children: [
                        {
                          type: 'col',
                          width: {
                            lg: 12,
                            sm: 12,
                            xs: 12
                          },
                          children: [
                            {
                              type: 'select',
                              name: 'admin',
                              label: 'Select clinic administrator'.translate(this.props.lang),
                              values: [{ name: 'None', value: null }, ...this.state.formAdmins.map((item) => { return { name: item?.name, value: item?._id } })]
                            },
                          ]
                        },
                        {
                          type: 'col',
                          width: {
                            lg: 12,
                            sm: 12,
                            xs: 12
                          },
                          children: [
                            {
                              type: 'select',
                              name: 'region',
                              label: 'Select region'.translate(this.props.lang),
                              validate: [required('Region is required to select!'.translate(this.props.lang))],
                              values: [{ value: null, shortName: 'None' }, ...this.state.regions].map((item, idx) => { return { value: item?._id, name: item?.shortName } })
                            },
                          ]
                        },
                        {
                          type: 'col',
                          width: {
                            lg: 12,
                            sm: 12,
                            xs: 12
                          },
                          children: [
                            {
                              type: 'select',
                              name: 'subgroup',
                              label: 'Select subgroup'.translate(this.props.lang),
                              validate: [required('Subgroup is required to select!'.translate(this.props.lang))],
                              values: [{ value: null, name: 'None' }, ...this.state.formSubgroups].map((item, idx) => { return { value: item?._id, name: item?.name } })
                            },
                          ]
                        },
                        {
                          type: 'col',
                          width: {
                            lg: 12,
                            sm: 12,
                            xs: 12
                          },
                          children: [
                            {
                              type: 'options',
                              name: 'crmContract',
                              values: [{ name: 'With contract'.translate(this.props.lang), value: true, width: { lg: 6, sm: 6, xs: 6 } }, { name: 'Without contract'.translate(this.props.lang), value: false, width: { lg: 6, sm: 6, xs: 6 } }]

                            },
                          ]
                        },
                        {
                          type: 'col',
                          width: {
                            lg: 12,
                            sm: 12,
                            xs: 12
                          },
                          children: [
                            {
                              type: 'select',
                              name: 'selectedWaitingTimeType',
                              label: 'Waiting time type'.translate(this.props.lang),
                              values: [
                                { name: 'No data'.translate(this.props.lang), value: 0 },
                                { name: '0 weeks (No waiting)'.translate(this.props.lang), value: 1 },
                                { name: '(x) weeks minimum'.translate(this.props.lang), value: 2 },
                                { name: '(x) weeks maximum'.translate(this.props.lang), value: 3 },
                                { name: '(x) weeks'.translate(this.props.lang), value: 4 },
                                { name: '(x) to (y) weeks'.translate(this.props.lang), value: 5 },
                              ]
                            },
                          ]
                        },
                        {
                          type: 'col',
                          width: {
                            lg: 12,
                            sm: 12,
                            xs: 12
                          },
                          children: [
                            {
                              type: 'text',
                              name: 'waitingMin',
                              label: 'Minimum'.translate(this.props.lang),
                              disabled: waitingTimeDisabled[this.props.selectedWaitingTimeType ? this.props.selectedWaitingTimeType : 0][0]
                            },
                          ]
                        },
                        {
                          type: 'col',
                          width: {
                            lg: 12,
                            sm: 12,
                            xs: 12
                          },
                          children: [
                            {
                              type: 'text',
                              name: 'waitingMax',
                              label: 'Maximum'.translate(this.props.lang),
                              disabled: waitingTimeDisabled[this.props.selectedWaitingTimeType ? this.props.selectedWaitingTimeType : 0][1]

                            },
                          ]
                        }
                      ]
                    }
                  ]
                }


                /*
                  {
                      type: 'row',
                      children: [
                          {
                              type: 'col',
                              width: {
                                  lg: 6,
                                  sm: 12,
                                  xs: 12
                              },
                              children: [
                                  {
                                      type: 'select',
                                      name: 'admin',
                                      label: 'Select clinic administrator'.translate(this.props.lang),
                                      values: [{ name: 'None', value: null }, ...this.state.formAdmins.map((item) => { return { name: item.name, value: item._id } })]
                                  },
                              ]
                          },
                          {
                              type: 'col',
                              width: {
                                  lg: 6,
                                  sm: 12,
                                  xs: 12
                              },
                              children: [
                                  {
                                      type: 'select',
                                      name: 'region',
                                      label: 'Select region'.translate(this.props.lang),
                                      validate: [required('Region is required to select!'.translate(this.props.lang))],
                                      values: [{ value: null, shortName: 'None' }, ...this.state.regions].map((item, idx) => { return { value: item._id, name: item.shortName } })
                                  },
                              ]
                          },
                          {
                              type: 'col',
                              width: {
                                  lg: 12,
                                  sm: 12,
                                  xs: 12
                              },
                              children: [
                                  {
                                      type: 'text',
                                      name:  this.state.form.vantetiderName ? 'vantetiderName' : 'name',
                                      label: 'Clinic name'.translate(this.props.lang),
                                      validate: [required('Clinic name is required!'.translate(this.props.lang))],
                                  },
                              ]
                          },
                          {
                              type: 'col',
                              width: {
                                  lg: 12,
                                  sm: 12,
                                  xs: 12
                              },
                              children: [
                                  {
                                      type: 'text',
                                      name: 'oldClinicName',
                                      label: 'Old clinic name'.translate(this.props.lang),
                                  },
                              ]
                          },

                          {
                              type: 'col',
                              width: {
                                  lg: 12,
                                  sm: 12,
                                  xs: 12
                              },
                              children: [
                                  {
                                      type: 'select',
                                      name: 'subgroup',
                                      label: 'Select subgroup'.translate(this.props.lang),
                                      validate: [required('Subgroup is required to select!'.translate(this.props.lang))],
                                      values: [{ value: null, name: 'None' }, ...this.state.formSubgroups].map((item, idx) => { return { value: item._id, name: item.name } })
                                  },
                              ]
                          },
                          {
                              type: 'col',
                              width: {
                                  lg: 12,
                                  sm: 12,
                                  xs: 12
                              },
                              children: [
                                  {
                                      type: 'textarea',
                                      name: 'description',
                                      label: 'Description'.translate(this.props.lang),
                                  },
                              ]
                          },
                          {
                              type: 'col',
                              width: {
                                  lg: 12,
                                  sm: 12,
                                  xs: 12
                              },
                              children: [
                                  {
                                      type: 'select',
                                      multiple: true,
                                      name: 'tags',
                                      label: 'Select tags (hold CTRL to select multiple tags)'.translate(this.props.lang),
                                      values: this.state.formTags.map((item, idx) => { return { value: item._id, name: item.name } })

                                  },
                              ]
                          },
                          {
                              type: 'col',
                              width: {
                                  lg: 12,
                                  sm: 12,
                                  xs: 12
                              },
                              children: [
                                  {
                                      type: 'text',
                                      name: 'street',
                                      label: 'Street name and number'.translate(this.props.lang),
                                  },
                              ]
                          },
                          {
                              type: 'col',
                              width: {
                                  lg: 3,
                                  sm: 12,
                                  xs: 12
                              },
                              children: [
                                  {
                                      type: 'text',
                                      name: 'zip',
                                      label: 'Zip code'.translate(this.props.lang),
                                  },
                              ]
                          },
                          {
                              type: 'col',
                              width: {
                                  lg: 9,
                                  sm: 12,
                                  xs: 12
                              },
                              children: [
                                  {
                                      type: 'text',
                                      name: 'city',
                                      label: 'City'.translate(this.props.lang),
                                  },
                              ]
                          },
                          {
                              type: 'col',
                              width: {
                                  lg: 12,
                                  sm: 12,
                                  xs: 12
                              },
                              children: [
                                  {
                                      type: 'text',
                                      name: 'postAddress',
                                      label: 'Mailing address'.translate(this.props.lang),
                                  },
                              ]
                          },
                          {
                              type: 'col',
                              width: {
                                  lg: 6,
                                  sm: 12,
                                  xs: 12
                              },
                              children: [
                                  {
                                      type: 'text',
                                      name: 'phone',
                                      label: 'Phone'.translate(this.props.lang),
                                  },
                              ]
                          },
                          {
                              type: 'col',
                              width: {
                                  lg: 6,
                                  sm: 12,
                                  xs: 12
                              },
                              children: [
                                  {
                                      type: 'text',
                                      name: 'email',
                                      label: 'Email'.translate(this.props.lang),
                                  },
                              ]
                          },
                          {
                              type: 'col',
                              width: {
                                  lg: 6,
                                  sm: 12,
                                  xs: 12
                              },
                              children: [
                                  {
                                      type: 'text',
                                      name: 'webSite',
                                      label: 'Web address'.translate(this.props.lang),
                                  },
                              ]
                          },
                          {
                              type: 'col',
                              width: {
                                  lg: 6,
                                  sm: 12,
                                  xs: 12
                              },
                              children: [
                                  {
                                      type: 'text',
                                      name: 'coordinates',
                                      label: 'Google map coordinates'.translate(this.props.lang),
                                  },
                              ]
                          },
                          {
                              type: 'col',
                              width: {
                                  lg: 12,
                                  sm: 12,
                                  xs: 12
                              },
                              children: [
                                  {
                                      type: 'options',
                                      name: 'crmContract',
                                      values: [{ name: 'With contract'.translate(this.props.lang), value: true, width: { lg: 6, sm: 6, xs: 6 } }, { name: 'Without contract'.translate(this.props.lang), value: false, width: { lg: 6, sm: 6, xs: 6 } }]

                                  },
                              ]
                          },
                          {
                              type: 'col',
                              width: {
                                  lg: 12,
                                  sm: 12,
                                  xs: 12
                              },
                              children: [
                                  {
                                      type: 'select',
                                      name: 'selectedWaitingTimeType',
                                      label: 'Waiting time type'.translate(this.props.lang),
                                      values: [
                                          { name: 'No data'.translate(this.props.lang), value: 0 },
                                          { name: '0 weeks (No waiting)'.translate(this.props.lang), value: 1 },
                                          { name: '(x) weeks minimum'.translate(this.props.lang), value: 2 },
                                          { name: '(x) weeks maximum'.translate(this.props.lang), value: 3 },
                                          { name: '(x) weeks'.translate(this.props.lang), value: 4 },
                                          { name: '(x) to (y) weeks'.translate(this.props.lang), value: 5 },
                                      ]
                                  },
                              ]
                          },
                          {
                              type: 'col',
                              width: {
                                  lg: 6,
                                  sm: 12,
                                  xs: 12
                              },
                              children: [
                                  {
                                      type: 'text',
                                      name: 'waitingMin',
                                      label: 'Minimum'.translate(this.props.lang),
                                      disabled: waitingTimeDisabled[this.props.selectedWaitingTimeType ? this.props.selectedWaitingTimeType : 0][0]
                                  },
                              ]
                          },
                          {
                              type: 'col',
                              width: {
                                  lg: 6,
                                  sm: 12,
                                  xs: 12
                              },
                              children: [
                                  {
                                      type: 'text',
                                      name: 'waitingMax',
                                      label: 'Maximum'.translate(this.props.lang),
                                      disabled: waitingTimeDisabled[this.props.selectedWaitingTimeType ? this.props.selectedWaitingTimeType : 0][1]

                                  },
                              ]
                          },

                      ]
                  } 
                  */
              ]}
            ></FormBuilder>
            :
            null
        }

        {
          this.state.clinicView ?
            <Modal isOpen={this.state.clinicView} centered toggle={() => this.setState({ clinicView: !this.state.clinicView })} size={'lg'} className="clinic-modal">
              <ModalHeader toggle={() => this.setState({ clinicView: !this.state.clinicView })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ clinicView: !this.state.clinicView })}>&times;</button>}>{this.state.clinicView.vantetiderName ? this.state.clinicView.vantetiderName : this.state.clinicView.name}</ModalHeader>

              <ModalBody>
                <Row>
                  {this.state.clinicView.coordinates && this.state.clinicView.coordinates.indexOf(',') !== -1 ?
                    <Col lg="12">
                      <Map value={this.state.clinicView.coordinates} />
                    </Col>
                    :
                    null
                  }
                  <Col lg="6">
                    <div className="contact">

                      <p><strong>{'Contact'.translate(this.props.lang)}</strong></p>

                      <div className="contact-row">
                        <span> <Isvg src={locationIcon} /> </span>
                        <p>{this.state.clinicView.street}
                          {this.state.clinicView.zip}   {this.state.clinicView.city}
                        </p>
                      </div>

                      <div className="contact-row">
                        <span> <Isvg src={phoneIcon} /> </span>  <p>{this.state.clinicView.phone}</p>
                      </div>

                      <div className="contact-row">
                        {this.state.clinicView.webSite ? <p> <span> <Isvg src={linkIcon} /> </span> <a href={this.state.clinicView.webSite} target="_blank">{this.state.clinicView.webSite}</a></p> : null}
                      </div>

                    </div>
                  </Col>
                  <Col lg="6">
                    <div className="contact">
                      <p><strong>{'Region'.translate(this.props.lang)}:</strong> {this.state.clinicView.regionName}</p>
                      <p><strong>{'Subgroup'.translate(this.props.lang)}:</strong> {this.state.clinicView.subgroupName}</p>
                      <p><strong>{'Waiting time'.translate(this.props.lang)}:</strong> <span>{this.waitingTimePrint(this.state.clinicView.waitingMin, this.state.clinicView.waitingMax)}</span> </p>
                    </div>

                  </Col>
                  <Col lg="12">
                    <div className="desc">
                      <p><strong>{'Description'.translate(this.props.lang)}:</strong></p>
                      <p>{this.state.clinicView.description}</p> <br />
                    </div>
                  </Col>

                </Row>
              </ModalBody>
            </Modal>
            :
            null
        }

      </div>
    )
  }
}
const selector = formValueSelector('modalForm')

export default connect(
  (state) => {
    return {
      selectedWaitingTimeType: selector(state, 'selectedWaitingTimeType'),
    }
  },
  {
    changeMinValue: (value) => change('modalForm', 'waitingMin', value),
    changeMaxValue: (value) => change('modalForm', 'waitingMax', value),
    changeSelectedWaitingTimeType: (value) =>
      change('modalForm', 'selectedWaitingTimeType', value),
  }
)(Page(Clinics))
