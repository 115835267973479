import React, { Component } from 'react';
import Page from '../../containers/page';

import {
    Container,
    Row,
    Col,
    Button,
    FormGroup,
    Label,
    Input,
    UncontrolledAlert,
    UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Table, Form, Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';

import Isvg from 'react-inlinesvg'

import HTML from '../../components/forms/fields/html';

import ErrorModal from '../../components/errorModal';
import DeleteModal from '../../components/deleteModal';
import InfoModal from '../../components/infoModal';
import ListCheckBox from '../../components/forms/fields/listCheckbox';

import { getSearchParams, generateSearchLink, generateSearchLinkMultiple } from '../../helpers/linkHelper';
import { API_ENDPOINT } from '../../constants';
import { formValueSelector, change } from 'redux-form';  // ES6
import { connect } from 'react-redux';
import warrning from '../../assets/svg/warrning.svg';
import { SortableContainer, SortableElement, sortableHandle, } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import { v4 as uuidv4 } from 'uuid';
import garabage from '../../assets/svg/garbage.svg'
import arrows from '../../assets/svg/arrows.svg'

import CreateReferralModal from '../../components/createReferralModal';
import ImportReferral from '../../components/importReferral';
import CreatePersonalReferralModal from '../../components/createPersonalReferralModal';

import Search from '../../components/search';
import profileUser from '../../assets/images/user.png';
import notification from '../../assets/svg/notification.svg';
import moreIcon from '../../assets/svg/more.svg';
import xIcon from '../../assets/svg/x.svg';
import Print from '../../assets/svg/print.svg'
import Link from '../../assets/svg/link.svg'
import Checkbox from '../../components/forms/fields/checkbox';
import addIcon from '../../assets/svg/add-icon.svg';
import Html from '../../components/forms/fields/html';


import viewIcon from '../../assets/svg/eye.svg';
import hideIcon from '../../assets/svg/hide.svg';


function getRandomColor() {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
}


const colors = [
    "aqua",
    "black",
    "lime",
    "blue",
    "fuchsia",
    "gray",
    "green",
    "navy",
    "olive",
    "purple",
    "red",
    "silver",
    "teal",
    "yellow",
    "maroon",
];


function parseJSON(response) {
    return response.json().then(json => {
        return {
            result: json,
            status: response.status
        }
    })
}


const SortableItem = SortableElement(({ value, update }) => <li className={value.className}>{value.component}</li>);

const SortableList = SortableContainer(({ items, updateSubMenu }) => {
    return (
        <ul className="prepare-questionary-sort-list">
            {items.map((value, index) => (
                <SortableItem key={`item-${index}`} index={index} value={value} />
            ))}
        </ul>
    );
});

const SortableAnswer = SortableElement(({ value }) => <div className={value.className}>{value.component}</div>);

const SortableAnswers = SortableContainer(({ items }) => {
    return (
        <div>
            {items.map((value, index) => (
                <SortableAnswer className="bam" key={`item-${index}`} index={index} value={value} />
            ))}
        </div>
    );
});




const DragHandle = sortableHandle(({ children }) => children);


/**
* Form builder for referral
* @author   Milan Stanojevic
*/
class ReferralBuilder extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props.initialData,
            sectionFormIdx: null,
            answerForm: null,
            deleteElementModal: null,
            deleteOptionModal: null,
            linkingElement: null,
            articles: [],
            sections: [],
            tags: [],
            formTags: [],
            items: [],
            version: 1,
            haveQuestionary: true,
            answers: {},
            duration: -1,
            doctorsModal: false,
            doctorsList: [],
            wayOfScheduling: 'clinic',
            messageShow: false,
            messageModalError: false,
            cursorNoDrop: false,
            checkActiveSave: true,
            price: 0,
            freeOfCharge: true,
            openPageWarningModal: false,
            under18: true,
            older85: true,
            under18child: true,

        };
    }


    get = () => {
        this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));

        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0, clinic: this.props.selectedClinic, groupId: this.props.selectedGroup })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

        for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
            this.props.loadDataWithQuery[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0, clinic: this.props.selectedClinic, groupId: this.props.selectedGroup })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

    }

    componentDidMount() {

        fetch(API_ENDPOINT + '/data/clinic-groups/all', {
            method: 'POST',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
        }).then(parseJSON).then(({ result, status }) => {
            this.setState({
                clinicGroupsData: result
            })
        })

        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }

        this.get();

        // this.setState({ openPageWarningModal: true })



        let params = getSearchParams(this.props[0].location.search, {});

        this.getQuestionary();

        if (this.props[0].match.params.id == 'new') {
            this.checkActiveSave()
        }

    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps[0].location.search != this.props[0].location.search && this.state.doctors) {
            this.setState({
                loading: true
            }, () => {
                this.get();

            })
        }
        if (prevProps.selectedClinic != this.props.selectedClinic) {
            this.get();
        }

        if (prevState.doctors != this.state.doctors && this.state.doctors && this.state.doctors.length && !this.state.loading) {
            let arr = [];
            for (let i = 0; i < this.state.doctors.length; i++) {
                let obj = {
                    _id: this.state.doctors[i]._id,
                    checked: false,
                    name: this.state.doctors[i].name
                }
                arr.push(obj);
            }
            if (this.state.doctorsListQuestionary && this.state.doctorsListQuestionary.length) {
                for (let i = 0; i < arr.length; i++) {
                    for (let j = 0; j < this.state.doctorsListQuestionary.length; j++) {
                        if (this.state.doctorsListQuestionary[j]._id == arr[i]._id) {
                            arr[i].checked = this.state.doctorsListQuestionary[j].checked;
                        }
                    }
                }
            }

            this.setState({ doctorsList: arr, messageShow: true })
        }
        if (prevState.doctorsListQuestionary != this.state.doctorsListQuestionary && this.state.doctorsListQuestionary && this.state.doctorsListQuestionary.length && this.state.doctors && this.state.doctors.length && !this.state.loading) {
            let arr = [];
            for (let i = 0; i < this.state.doctors.length; i++) {
                let obj = {
                    _id: this.state.doctors[i]._id,
                    checked: false,
                    name: this.state.doctors[i].name
                }
                arr.push(obj);
            }
            for (let i = 0; i < arr.length; i++) {
                for (let j = 0; j < this.state.doctorsListQuestionary.length; j++) {
                    if (this.state.doctorsListQuestionary[j]._id == arr[i]._id) {
                        arr[i].checked = this.state.doctorsListQuestionary[j].checked;
                    }
                }
            }


            this.setState({ doctorsList: arr, messageShow: true })
        }
    }

    updateParams = (name, value,) => {
        this.props[0].history.push(generateSearchLink(this.props[0].location.search, {}, name, value, false));
    }


    /**
    * Function return questionary selected by id
    * @author   Milan Stanojevic
    * @Objectparam    {String} id    questionary id
    */
    getQuestionary = () => {
        fetch(API_ENDPOINT + '/data/general-health-care/' + this.props[0].match.params.id, {
            method: 'GET',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
        }).then(parseJSON).then(({ result, status }) => {
            if (status >= 200 && status < 300) {
                for (let i = 0; i < result.items.length; i++) {
                    if (!result.items[i].id) {
                        result.items[i].id = uuidv4();
                    }
                    /*if (result.items[i].conditionQuestion){
                        result.items[i].conditions = [{conditionQuestion: result.items[i].conditionQuestion, conditionAnswer: result.items[i].conditionAnswer }]
                        delete result.items[i].conditionQuestion;
                        delete result.items[i].conditionAnswer;
                    }*/
                }
                this.setState(result);
            }
        })
    }

    /**
    * Insert or update questionary
    * @author   Milan Stanojevic
    * @Objectparam    {String} id    questionary id
    */
    updateQuestionary = (redirect) => {

        if (this.props[0].location.pathname.indexOf('/create-personal-referral') !== -1) {
            let obj;
            obj = {
                items: this.state.items,
                name: this.state.name,
                description: this.state.description,
                intelligentReferral: this.state.intelligentReferral,
                groupsValue: this.state.groupsValue

            }

            fetch(API_ENDPOINT + '/data/general-health-care/new', {
                method: 'PUT',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify(obj)
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300) {
                    this.getQuestionary();
                    this.setState({
                        message: 'Questionary saved successfully',
                    })
                    this.props[0].history.push('/data-managment/general-health-care')
                }
            })
        } else {
            let obj;
            obj = {
                items: this.state.items,
                name: this.state.name,
                description: this.state.description,
                intelligentReferral: this.state.intelligentReferral,
                groupsValue: this.state.groupsValue

            }

            fetch(API_ENDPOINT + '/data/general-health-care/' + this.props[0].match.params.id, {
                method: this.props[0].match.params.id == 'new' ? 'PUT' : 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify(obj)
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300) {
                    this.getQuestionary();
                    this.setState({
                        message: 'Questionary saved successfully',
                    })
                    this.props[0].history.push('/data-managment/general-health-care')
                }
            })
        }


    }

    checkActiveSave = () => {
        let returnValue = true;

        // if (this.state.onlinePayment && this.state.invoice && !this.state.paymentOnSite) {
        //     returnValue = false;
        // } else if (this.state.onlinePayment && !this.state.invoice && this.state.paymentOnSite) {
        //     returnValue = false;
        // } else if (!this.state.onlinePayment && this.state.invoice && this.state.paymentOnSite) {
        //     returnValue = false;
        // } else if (this.state.freeOfCharge) {
        //     returnValue = true;
        // }
        // if (!returnValue) {
        //     this.setState({ errorSave: 'You cannot select 2 payment types.'.translate(this.props.lang) })
        // } else {
        //     this.setState({ errorSave: null })
        // }
        if (!this.state.name || this.state.name == '') {
            returnValue = false
        }

        this.setState({ checkActiveSave: returnValue })
    }


    checkAllPrintAnswers = () => {
        for (let i = 0; i < this.state.items.length; i++) {
            if (this.state.items[i].type == 1 || this.state.items[i].type == 2) {
                let answers = 0;
                for (let j = 0; j < this.state.items[i].answers.length; j++) {
                    if (!this.state.items[i].answers[j].text) {
                        answers++;
                    }
                }
                if (answers == this.state.items[i].answers.length || answers == 0) {
                    continue;
                } else {
                    return false;
                }
            }
        }

        return true;
    }


    conditionIndexOf = (conditions, conditionQuestion, conditionAnswer) => {
        if (!conditions) {
            return -1;
        }

        for (let i = 0; i < conditions.length; i++) {
            if (!conditionAnswer) {
                if (conditions[i].conditionQuestion == conditionQuestion) {
                    return i;
                }
            } else {
                if (conditions[i].conditionQuestion == conditionQuestion && conditions[i].conditionAnswer == conditionAnswer) {
                    return i;
                }
            }
        }

        return -1;
    }

    getAnswer = (id) => {
        for (let i = 0; i < this.state.items.length; i++) {
            if (this.state.items[i].answers) {
                for (let j = 0; j < this.state.items[i].answers.length; j++) {
                    if (this.state.items[i].answers[j].id == id) {
                        return this.state.items[i].answers[j];
                    }
                }
            }
        }
    }


    getItem = (id) => {
        for (let i = 0; i < this.state.items.length; i++) {
            if (this.state.items[i].id == id) {
                return this.state.items[i];
            }
        }
    }




    checkRelations = (itemId) => {
        let items = this.state.items;

        let itemsDict = {};
        for (let i = 0; i < items.length; i++) {
            itemsDict[items[i].id] = items[i];
        }


        let item = this.getItem(itemId)
        if (!item) {
            return null;
        }


        let upLinks = [];
        let downLinks = [];

        for (let i = 0; i < items.length; i++) {
            if (items[i].conditions) {

                let idx = this.conditionIndexOf(items[i].conditions, itemId, null);
                if (idx != -1) {
                    for (let j = 0; j < items[i].conditions.length; j++) {
                        if (items[i].conditions[j].conditionQuestion == itemId) {

                            downLinks.push({
                                conditionElement: {
                                    id: items[i].conditions[j].conditionQuestion,
                                    answerId: items[i].conditions[j].conditionAnswer,
                                    name: itemsDict[items[i].conditions[j].conditionQuestion].name,
                                    answer: this.getAnswer(items[i].conditions[j].conditionAnswer) && this.getAnswer(items[i].conditions[j].conditionAnswer).name,
                                },
                                element: {
                                    id: items[i].id,
                                    name: items[i].name,
                                    itemIndex: i,
                                    conditionIndex: j
                                }
                            });

                        }
                    }
                }


                if (items[i].id == itemId) {
                    for (let j = 0; j < items[i].conditions.length; j++) {
                        upLinks.push({
                            conditionElement: {
                                id: items[i].conditions[j].conditionQuestion,
                                answerId: items[i].conditions[j].conditionAnswer,
                                name: this.getItem(items[i].conditions[j].conditionQuestion) && this.getItem(items[i].conditions[j].conditionQuestion).name,
                                answer: this.getAnswer(items[i].conditions[j].conditionAnswer) && this.getAnswer(items[i].conditions[j].conditionAnswer).name,
                            },
                            element: {
                                id: item.id,
                                name: item.name,
                                itemIndex: i,
                                conditionIndex: j
                            }
                        })
                    }
                }
            }


        }


        if (!upLinks.length && !downLinks.length) {
            return null;
        } else {
            return [upLinks, downLinks];
        }


    }

    checkAnswerRelations = (itemId, answerId) => {
        let items = this.state.items;

        let itemsDict = {};
        for (let i = 0; i < items.length; i++) {
            itemsDict[items[i].id] = items[i];
        }

        let item = this.getItem(itemId)
        if (!item) {
            return null;
        }


        let upLinks = [];
        let downLinks = [];

        for (let i = 0; i < items.length; i++) {
            if (items[i].conditions) {

                let idx = this.conditionIndexOf(items[i].conditions, itemId, answerId);
                if (idx != -1) {
                    for (let j = 0; j < items[i].conditions.length; j++) {
                        if (items[i].conditions[j].conditionQuestion == itemId && items[i].conditions[j].conditionAnswer == answerId) {

                            downLinks.push({
                                conditionElement: {
                                    id: items[i].conditions[j].conditionQuestion,
                                    answerId: items[i].conditions[j].conditionAnswer,
                                    name: itemsDict[items[i].conditions[j].conditionQuestion].name,
                                    answer: this.getAnswer(items[i].conditions[j].conditionAnswer).name,
                                },
                                element: {
                                    id: items[i].id,
                                    name: items[i].name,
                                    itemIndex: i,
                                    conditionIndex: j
                                }
                            });

                        }
                    }
                }

            }


        }


        if (!upLinks.length && !downLinks.length) {
            return null;
        } else {
            return [upLinks, downLinks];
        }


    }



    generateSections = () => {
        let sections = {};
        let items = [{ type: 0 }, ...this.state.items];
        let lastSectionId = -1;
        for (let i = 0; i < items.length; i++) {
            if (items[i].type == 0) {
                lastSectionId++;
                sections[lastSectionId] = {
                    name: items[i].name,
                    displayOnReferral: false,
                    questions: [],
                    conditions: items[i].conditions ? items[i].conditions : [],
                    displayOnReferral: items[i].displayOnReferral,
                    fontStyle: items[i].fontStyle,
                    fontSize: items[i].fontSize,
                    fontWeight: items[i].fontWeight,

                }
            }
            if (lastSectionId != -1 && items[i].type != 0) {


                let wikiLinks = [];

                items[i].wikiLinks = wikiLinks;


                if (items[i].type == 1) {
                    let displayOnReferral = false;
                    if (items[i].answers) {
                        for (let j = 0; j < items[i].answers.length; j++) {
                            if (items[i].answers[j].text) {
                                displayOnReferral = true;
                            }
                        }
                    }

                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        answers: items[i].answers,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        displayOnReferral: displayOnReferral,
                        wikiLinks: items[i].wikiLinks,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                    });

                } else if (items[i].type == 2) {
                    let displayOnReferral = false;
                    if (items[i].answers) {
                        for (let j = 0; j < items[i].answers.length; j++) {

                            if (items[i].answers[j].text) {
                                displayOnReferral = true;
                            }
                        }
                    }
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        answers: items[i].answers,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        answerPrefix: items[i].answerPrefix,
                        userInput: items[i].userInput,
                        displayOnReferral: displayOnReferral || items[i].answerPrefix ? true : false,
                        wikiLinks: items[i].wikiLinks,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,

                    })
                } else if (items[i].type == 3) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        answerPrefix: items[i].answerPrefix,
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                    })

                } else if (items[i].type == 4) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        text: items[i].text,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                    })

                } else if (items[i].type == 5) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                    })

                } else if (items[i].type == 6) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                    })

                } else if (items[i].type == 7) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                    })

                } else if (items[i].type == 8) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                    })

                } else if (items[i].type == 9) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                    })

                } else if (items[i].type == 10) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        userInput: items[i].userInput,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                    })
                } else if (items[i].type == 12) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                    })

                } else if (items[i].type == 13) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                    })

                }
            }
        }

        return Object.values(sections);
    }



    render() {
        let params = {};
        if (this.state.useState) {
            params = this.state.params;
        } else {
            params = getSearchParams(this.props[0].location.search, { entries: 10, page: 0 })
        }

        let selectedGroupIdx = 0;
        if (this.props.selectedGroup && this.props.uData?.clinicGroups?.length) {
            for (let i = 0; i < this.props.uData.clinicGroups.length; i++) {
                if (this.props.uData.clinicGroups[i]._id == this.props.selectedGroup) {
                    selectedGroupIdx = i;
                    break;
                }
            }
        }

        let articlesDict = {};
        for (let i = 0; i < this.state.articles.length; i++) {
            articlesDict[this.state.articles[i]._id] = this.state.articles[i];
        }

        const bmiIndex = ['#9ABBDA', '#93CCA9', '#FFE02B', '#EAA353', '#D8414A'];

        return (
            <div className={this.props.autoHideSidebar ? "dashboard dashboard-auto-hide" : "dashboard"}>

                <Container fluid>
                    <Row>
                        {/* <Col lg="12">
                            {this.state.message ?
                                <UncontrolledAlert color="success">
                                    {this.state.message.translate(this.props.lang)}
                                </UncontrolledAlert>
                                :
                                null
                            }
                        </Col> */}
                        <Col lg="12">
                            <div className="panel">
                                <h4>{this.props[0].match.params.id == 'new' ? 'Add questionary'.translate(this.props.lang) : 'Edit questionary'.translate(this.props.lang)}
                                </h4>

                                <Row>

                                    <Col lg="6">
                                        <FormGroup>
                                            <Label>{'Name'.translate(this.props.lang)}</Label>
                                            <Input size="sm" value={this.state.name} onChange={(e) => this.setState({ name: e.target.value }, () => this.checkActiveSave())} />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label>{'Description'.translate(this.props.lang)}</Label>
                                            <Input size="sm" type="textarea" className="questionary-description" value={this.state.description} onChange={(e) => this.setState({ description: e.target.value })} />
                                        </FormGroup>
                                        <FormGroup>
                                            <div >
                                                <Checkbox checked={this.state.intelligentReferral} onChange={(e) => {
                                                    this.setState({
                                                        intelligentReferral: e.target.checked
                                                    })
                                                }} label={'Intelligent form builder'.translate(this.props.lang)} />
                                            </div>
                                        </FormGroup>


                                    </Col>
                                    <Col lg='6'>
                                        <FormGroup>
                                            <ListCheckBox
                                                label={'Assigned to:'.translate(this.props.lang)}
                                                value={this.state.groupsValue}
                                                values={this.state.clinicGroupsData && this.state.clinicGroupsData.map(item => {
                                                    return ({
                                                        name: item.name,
                                                        value: item._id
                                                    })
                                                })}
                                                onChange={(item) => {
                                                    this.setState({
                                                        groupsValue: item
                                                    })
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>

                                    <Col lg='12'>
                                        <FormGroup className="questionary-buttons">
                                            <Button onClick={() => this.setState({ openPageWarningModal: 1 })} size="sm" color="primary" disabled={this.state.checkActiveSave ? false : true}>{'Save'.translate(this.props.lang)}</Button>

                                            <Button size="sm" style={{ marginLeft: 10 }} onClick={() => this.setState({
                                                previewModal: true
                                            })} color="primary">
                                                {'Preview'.translate(this.props.lang)}
                                            </Button>

                                        </FormGroup>
                                    </Col>
                                </Row>

                            </div>
                        </Col>


                        <Col lg="12">
                            <div className="panel build-questionary-section">

                                <SortableList helperClass="test123"
                                    // onSortEnd={({ oldIndex, newIndex }) => {
                                    //     let items = this.state.items;

                                    //     items = arrayMove(items, oldIndex, newIndex);
                                    //     this.setState({
                                    //         items: items
                                    //     })
                                    // }}
                                    onSortEnd={({ oldIndex, newIndex }) => {
                                        let items = this.state.items;

                                        let checkOldIndex = this.checkRelations(items[oldIndex].id);


                                        if (oldIndex > newIndex) {
                                            let arr = [];
                                            if (checkOldIndex && checkOldIndex[0].length) {
                                                for (let i = 0; i < checkOldIndex[0].length; i++) {
                                                    arr.push(checkOldIndex[0][i].conditionElement.id)
                                                }


                                                let max = 0;
                                                for (let i = 0; i < arr.length; i++) {
                                                    for (let j = 0; j < items.length; j++) {
                                                        if (arr[i] == items[j].id && j > max) {
                                                            max = j;
                                                        }
                                                    }
                                                }

                                                if (newIndex > max) {
                                                    items = arrayMove(items, oldIndex, newIndex);
                                                    this.setState({
                                                        items: items
                                                    })
                                                } else {
                                                    this.setState({ messageModalError: true })
                                                }
                                            } else {
                                                items = arrayMove(items, oldIndex, newIndex);
                                                this.setState({
                                                    items: items
                                                })
                                            }

                                        }
                                        else {
                                            let arr = [];
                                            if (checkOldIndex && checkOldIndex[1].length) {
                                                for (let i = 0; i < checkOldIndex[1].length; i++) {
                                                    arr.push(checkOldIndex[1][i].element.id)
                                                }
                                                let min = items.length - 1;
                                                for (let i = 0; i < arr.length; i++) {
                                                    for (let j = 0; j < items.length; j++) {
                                                        if (arr[i] == items[j].id && j < min) {
                                                            min = j;
                                                        }
                                                    }
                                                }
                                                if (newIndex < min) {
                                                    items = arrayMove(items, oldIndex, newIndex);
                                                    this.setState({
                                                        items: items
                                                    })
                                                } else {
                                                    this.setState({ messageModalError: true })
                                                }
                                            } else {
                                                items = arrayMove(items, oldIndex, newIndex);
                                                this.setState({
                                                    items: items
                                                })
                                            }
                                        }
                                        document.body.style.cursor = 'default';


                                    }}
                                    onSortOver={({ index, oldIndex, newIndex }) => {


                                        let items = this.state.items;



                                        let checkOldIndex = this.checkRelations(items[index].id);

                                        if (index > newIndex) {
                                            let arr = [];
                                            if (checkOldIndex && checkOldIndex[0].length) {
                                                for (let i = 0; i < checkOldIndex[0].length; i++) {
                                                    arr.push(checkOldIndex[0][i].conditionElement.id)
                                                }
                                                let max = 0;
                                                for (let i = 0; i < arr.length; i++) {
                                                    for (let j = 0; j < items.length; j++) {
                                                        if (arr[i] == items[j].id && j > max) {
                                                            max = j;
                                                        }
                                                    }
                                                }
                                                if (newIndex > max) {
                                                    this.setState({ cursorNoDrop: false }, () => { document.body.style.cursor = 'default'; })
                                                } else {
                                                    this.setState({ cursorNoDrop: true }, () => { document.body.style.cursor = 'no-drop'; })
                                                }
                                            } else {
                                                this.setState({ cursorNoDrop: false }, () => { document.body.style.cursor = 'default'; })
                                            }

                                        }
                                        else {
                                            let arr = [];
                                            if (checkOldIndex && checkOldIndex[1].length) {
                                                for (let i = 0; i < checkOldIndex[1].length; i++) {
                                                    arr.push(checkOldIndex[1][i].element.id)
                                                }
                                                let min = items.length - 1;
                                                for (let i = 0; i < arr.length; i++) {
                                                    for (let j = 0; j < items.length; j++) {
                                                        if (arr[i] == items[j].id && j < min) {
                                                            min = j;
                                                        }
                                                    }
                                                }
                                                if (newIndex < min) {
                                                    this.setState({ cursorNoDrop: false }, () => { document.body.style.cursor = 'default'; })
                                                } else {
                                                    this.setState({ cursorNoDrop: true }, () => { document.body.style.cursor = 'no-drop'; })
                                                }
                                            } else {
                                                this.setState({ cursorNoDrop: false }, () => { document.body.style.cursor = 'default'; })
                                            }
                                        }


                                    }}
                                    useDragHandle items={this.state.items.map((item, idx) => {
                                        let cssStyle = {};
                                        if (item.fontSize) {
                                            cssStyle.fontSize = item.fontSize;
                                        }
                                        if (item.fontWeight) {
                                            cssStyle.fontWeight = item.fontWeight;
                                        }
                                        if (item.fontStyle) {
                                            cssStyle.fontStyle = item.fontStyle;
                                        }


                                        return {
                                            component: (
                                                <div>
                                                    <div style={{ display: 'flex', marginTop: 15 }}>
                                                        <div className="drag-handle">
                                                            <DragHandle><i className="fa fa-arrows" /> </DragHandle>
                                                        </div>
                                                        <span >{idx + 1}.</span>
                                                    </div>

                                                    {
                                                        item.type == 0 ?
                                                            <h4><input style={cssStyle} type="text" value={item.name} onChange={(e) => {
                                                                let items = this.state.items;
                                                                items[idx].name = e.target.value;
                                                                this.setState({
                                                                    items
                                                                })
                                                            }} className="edit-field-name m-l-45" />
                                                                <div className="text-options">
                                                                    <button className={item.fontStyle == 'italic' ? 'active' : ''} onClick={() => { let items = this.state.items; items[idx].fontStyle = items[idx].fontStyle == 'italic' ? null : 'italic'; this.setState({ items }) }}><i className="fa fa-italic" /></button>
                                                                    <button className={item.fontWeight == 'bold' ? 'active' : ''} onClick={() => { let items = this.state.items; items[idx].fontWeight = items[idx].fontWeight == 'bold' ? null : 'bold'; this.setState({ items }) }}><i className="fa fa-bold" /></button>

                                                                    <UncontrolledDropdown size="sm">
                                                                        <DropdownToggle caret>
                                                                            <i className="fa fa-font" />
                                                                        </DropdownToggle>
                                                                        <DropdownMenu>
                                                                            <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 10; this.setState({ items }) }}>10px</DropdownItem>
                                                                            <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 12; this.setState({ items }) }}>12px</DropdownItem>
                                                                            <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 14; this.setState({ items }) }}>14px</DropdownItem>
                                                                            <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 16; this.setState({ items }) }}>16px</DropdownItem>
                                                                            <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 18; this.setState({ items }) }}>18px</DropdownItem>
                                                                            <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 20; this.setState({ items }) }}>20px</DropdownItem>
                                                                        </DropdownMenu>
                                                                    </UncontrolledDropdown>
                                                                </div>



                                                            </h4>
                                                            :
                                                            null
                                                    }
                                                    {
                                                        item.type == 1 || item.type == 2 ?
                                                            <FormGroup tag="fieldset">
                                                                <Label size="sm"><input style={cssStyle} type="text" value={item.name} onChange={(e) => {
                                                                    let items = this.state.items;
                                                                    items[idx].name = e.target.value;
                                                                    this.setState({
                                                                        items
                                                                    })
                                                                }} className="edit-field-name" />
                                                                    {
                                                                        item.type == 2 && this.state.intelligentReferral ?
                                                                            <>

                                                                                /

                                                                                <input style={cssStyle} type="text" value={item.answerPrefix} placeholder={'Prefix for answers'.translate(this.props.lang)} onChange={(e) => {
                                                                                    let items = this.state.items;
                                                                                    items[idx].answerPrefix = e.target.value;
                                                                                    this.setState({
                                                                                        items
                                                                                    })
                                                                                }} className="edit-field-name" />

                                                                            </>
                                                                            :
                                                                            null
                                                                    }
                                                                    <div className="text-options">
                                                                        <button className={item.fontStyle == 'italic' ? 'active' : ''} onClick={() => { let items = this.state.items; items[idx].fontStyle = items[idx].fontStyle == 'italic' ? null : 'italic'; this.setState({ items }) }}><i className="fa fa-italic" /></button>
                                                                        <button className={item.fontWeight == 'bold' ? 'active' : ''} onClick={() => { let items = this.state.items; items[idx].fontWeight = items[idx].fontWeight == 'bold' ? null : 'bold'; this.setState({ items }) }}><i className="fa fa-bold" /></button>

                                                                        <UncontrolledDropdown size="sm">
                                                                            <DropdownToggle caret>
                                                                                <i className="fa fa-font" />
                                                                            </DropdownToggle>
                                                                            <DropdownMenu>
                                                                                <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 10; this.setState({ items }) }}>10px</DropdownItem>
                                                                                <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 12; this.setState({ items }) }}>12px</DropdownItem>
                                                                                <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 14; this.setState({ items }) }}>14px</DropdownItem>
                                                                                <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 16; this.setState({ items }) }}>16px</DropdownItem>
                                                                                <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 18; this.setState({ items }) }}>18px</DropdownItem>
                                                                                <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 20; this.setState({ items }) }}>20px</DropdownItem>
                                                                            </DropdownMenu>
                                                                        </UncontrolledDropdown>
                                                                    </div>


                                                                </Label>
                                                                <SortableAnswers onSortEnd={({ oldIndex, newIndex }) => {
                                                                    let items = this.state.items;
                                                                    let answers = JSON.parse(JSON.stringify(items[idx].answers));
                                                                    //answers = arrayMove(answers, oldIndex, newIndex);
                                                                    let tmp = answers[newIndex];
                                                                    answers[newIndex] = answers[oldIndex];
                                                                    answers[oldIndex] = tmp;
                                                                    items[idx].answers = answers
                                                                    this.setState({
                                                                        items: items
                                                                    }, () => {
                                                                        setTimeout(() => {
                                                                            this.forceUpdate()
                                                                        }, 50)
                                                                    })
                                                                }} useDragHandle items={
                                                                    item.answers.map((answer, aidx) => {
                                                                        return {
                                                                            component: (
                                                                                <FormGroup check key={answer.id}>

                                                                                    <Label size="sm" check>

                                                                                        <Input disabled size="sm" name={item.id} type={item.type == 1 ? 'radio' : 'checkbox'} />
                                                                                        {' '}

                                                                                        {(this.state.linkingElement !== null && this.state.linkingElement !== idx && this.state.linkingElement > idx) ?
                                                                                            <button className={(this.state.showLink && this.conditionIndexOf(this.state.showLink, item.id, answer.id) != -1) || (this.state.linkingElement !== null && this.state.items[this.state.linkingElement]?.conditions && this.state.items[this.state.linkingElement].conditions.length && this.conditionIndexOf(this.state.items[this.state.linkingElement].conditions, item.id, answer.id) != -1) ? 'linking-element active' : 'linking-element'} onClick={() => {
                                                                                                let items = this.state.items;
                                                                                                if (!items[this.state.linkingElement].conditions) {
                                                                                                    items[this.state.linkingElement].conditions = [];
                                                                                                }

                                                                                                let check = -1;
                                                                                                for (let i = 0; i < items[this.state.linkingElement].conditions.length; i++) {
                                                                                                    if (items[this.state.linkingElement].conditions[i].conditionQuestion == item.id && items[this.state.linkingElement].conditions[i].conditionAnswer == answer.id) {
                                                                                                        check = i;
                                                                                                    }
                                                                                                }

                                                                                                if (check != -1) {
                                                                                                    items[this.state.linkingElement].conditions.splice(check, 1);
                                                                                                } else {
                                                                                                    items[this.state.linkingElement].conditions.push({
                                                                                                        conditionAnswer: answer.id,
                                                                                                        conditionQuestion: item.id
                                                                                                    })
                                                                                                }

                                                                                                /*items[this.state.linkingElement].conditionQuestion = item.id;
                                                                                                items[this.state.linkingElement].conditionAnswer = answer.id;
                                                                                                */
                                                                                                this.setState({
                                                                                                    items,
                                                                                                    // linkingElement: null
                                                                                                });

                                                                                            }}>{answer.name}</button>
                                                                                            :
                                                                                            <>
                                                                                                <input type="text" value={answer.name} placeholder={'Enter option name...'.translate(this.props.lang)} className={(this.state.showLink && this.conditionIndexOf(this.state.showLink, item.id, answer.id) != -1) ? 'edit-field-input edit-field-input-active' : "edit-field-input"} onChange={(e) => {
                                                                                                    let items = this.state.items;
                                                                                                    items[idx].answers[aidx].name = e.target.value;
                                                                                                    this.setState({
                                                                                                        items
                                                                                                    })
                                                                                                }} />

                                                                                                {this.state.intelligentReferral ? <>
                                                                                                    <span className="slash">/</span>  <input type="text" value={answer.text} placeholder={'Enter text for printing on referral...'.translate(this.props.lang)} onChange={(e) => {
                                                                                                        let items = this.state.items;
                                                                                                        items[idx].answers[aidx].text = e.target.value;
                                                                                                        this.setState({
                                                                                                            items
                                                                                                        })
                                                                                                    }} className={(this.state.showLink && this.conditionIndexOf(this.state.showLink, item.id, answer.id) != -1) ? 'edit-field-input edit-field-input-active' : "edit-field-input"} />
                                                                                                </>
                                                                                                    :
                                                                                                    null
                                                                                                }
                                                                                            </>
                                                                                        }
                                                                                        {/*
                                                                                        <input type="text" value={answer.name} placeholder={'Enter option name...'.translate(this.props.lang)} className="edit-field-input" onChange={(e) => {
                                                                                            let items = this.state.items;
                                                                                            items[idx].answers[aidx].name = e.target.value;
                                                                                            this.setState({
                                                                                                items
                                                                                            })
                                                                                        }} />

                                                                                        {this.state.intelligentReferral ? <> / <input type="text" value={answer.text} placeholder={'Enter text for printing on referral...'.translate(this.props.lang)} onChange={(e) => {
                                                                                            let items = this.state.items;
                                                                                            items[idx].answers[aidx].text = e.target.value;
                                                                                            this.setState({
                                                                                                items
                                                                                            })
                                                                                        }} className="edit-field-input" /></>
                                                                                            :
                                                                                            null
                                                                                        }*/}


                                                                                        <button onClick={() => {
                                                                                            this.setState({
                                                                                                deleteOptionModal: { idx, aidx },
                                                                                            })
                                                                                        }}><Isvg style={{ minWidth: 22 }} src={garabage} /></button>
                                                                                        <DragHandle>
                                                                                            <div><Isvg src={arrows} className="arrows" /></div> </DragHandle>

                                                                                    </Label>
                                                                                </FormGroup>
                                                                            )
                                                                        }

                                                                    })
                                                                } />

                                                                <div className="add-new-option-wrap">
                                                                    <button className="add-new-option" onClick={() => {
                                                                        let items = this.state.items;
                                                                        items[idx].answers.push({
                                                                            id: uuidv4(),
                                                                            name: '',
                                                                        });
                                                                        this.setState({
                                                                            items
                                                                        })
                                                                    }}> {'Add new option'.translate(this.props.lang)} </button>
                                                                    {item.type == 2 && item.answerPrefix ?
                                                                        <FormGroup>
                                                                            <Checkbox onChange={() => {
                                                                                let items = this.state.items;
                                                                                items[idx].userInput = !items[idx].userInput;
                                                                                this.setState({ items })
                                                                            }} checked={item.userInput ? true : false} label={'User can enter text answer'.translate(this.props.lang)} />
                                                                        </FormGroup>
                                                                        :
                                                                        null
                                                                    }
                                                                </div>

                                                            </FormGroup>
                                                            :
                                                            null
                                                    }
                                                    {
                                                        item.type == 3 || item.type == 5 ?
                                                            <FormGroup tag="fieldset">
                                                                <Label size="sm">
                                                                    {/*
                                                                    <input style={cssStyle} type="text" value={item.name} onChange={(e) => {
                                                                        let items = this.state.items;
                                                                        items[idx].name = e.target.value;
                                                                        this.setState({
                                                                            items
                                                                        })
                                                                    }} className="edit-field-name" />
                                                                    <div className="text-options">
                                                                        <button className={item.fontStyle == 'italic' ? 'active' : ''} onClick={() => { let items = this.state.items; items[idx].fontStyle = items[idx].fontStyle == 'italic' ? null : 'italic'; this.setState({ items }) }}><i className="fa fa-italic" /></button>
                                                                        <button className={item.fontWeight == 'bold' ? 'active' : ''} onClick={() => { let items = this.state.items; items[idx].fontWeight = items[idx].fontWeight == 'bold' ? null : 'bold'; this.setState({ items }) }}><i className="fa fa-bold" /></button>

                                                                        <UncontrolledDropdown size="sm">
                                                                            <DropdownToggle caret>
                                                                                <i className="fa fa-font" />
                                                                            </DropdownToggle>
                                                                            <DropdownMenu>
                                                                                <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 10; this.setState({ items }) }}>10px</DropdownItem>
                                                                                <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 12; this.setState({ items }) }}>12px</DropdownItem>
                                                                                <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 14; this.setState({ items }) }}>14px</DropdownItem>
                                                                                <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 16; this.setState({ items }) }}>16px</DropdownItem>
                                                                                <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 18; this.setState({ items }) }}>18px</DropdownItem>
                                                                                <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 20; this.setState({ items }) }}>20px</DropdownItem>
                                                                            </DropdownMenu>
                                                                        </UncontrolledDropdown>
                                                                    </div>
                                                                */}
                                                                    {(this.state.linkingElement !== null && this.state.linkingElement !== idx && this.state.linkingElement > idx) || (this.state.showLink && this.conditionIndexOf(this.state.showLink, item.id, null) != -1) ?
                                                                        <button className={(this.state.showLink && this.conditionIndexOf(this.state.showLink, item.id, null) != -1) || (this.state.linkingElement !== null && this.state.items[this.state.linkingElement]?.conditions && this.state.items[this.state.linkingElement].conditions.length && this.conditionIndexOf(this.state.items[this.state.linkingElement].conditions, item.id) != -1) ? 'linking-element active' : 'linking-element'} onClick={() => {
                                                                            let items = this.state.items;
                                                                            //items[this.state.linkingElement].conditionQuestion = item.id;

                                                                            if (!items[this.state.linkingElement].conditions) {
                                                                                items[this.state.linkingElement].conditions = [];
                                                                            }

                                                                            let check = -1;
                                                                            for (let i = 0; i < items[this.state.linkingElement].conditions.length; i++) {
                                                                                if (items[this.state.linkingElement].conditions[i].conditionQuestion == item.id) {
                                                                                    check = i;
                                                                                }
                                                                            }

                                                                            if (check != -1) {
                                                                                items[this.state.linkingElement].conditions.splice(check, 1);
                                                                            } else {
                                                                                items[this.state.linkingElement].conditions.push({
                                                                                    conditionQuestion: item.id
                                                                                })
                                                                            }


                                                                            this.setState({
                                                                                items,
                                                                                //linkingElement: null
                                                                            });

                                                                        }}>{item.name}</button>

                                                                        :
                                                                        <>
                                                                            <input style={cssStyle} type="text" value={item.name} onChange={(e) => {
                                                                                let items = this.state.items;
                                                                                items[idx].name = e.target.value;
                                                                                this.setState({
                                                                                    items
                                                                                })
                                                                            }} className="edit-field-name" />
                                                                            <div className="text-options">
                                                                                <button className={item.fontStyle == 'italic' ? 'active' : ''} onClick={() => { let items = this.state.items; items[idx].fontStyle = items[idx].fontStyle == 'italic' ? null : 'italic'; this.setState({ items }) }}><i className="fa fa-italic" /></button>
                                                                                <button className={item.fontWeight == 'bold' ? 'active' : ''} onClick={() => { let items = this.state.items; items[idx].fontWeight = items[idx].fontWeight == 'bold' ? null : 'bold'; this.setState({ items }) }}><i className="fa fa-bold" /></button>

                                                                                <UncontrolledDropdown size="sm">
                                                                                    <DropdownToggle caret>
                                                                                        <i className="fa fa-font" />
                                                                                    </DropdownToggle>
                                                                                    <DropdownMenu>
                                                                                        <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 10; this.setState({ items }) }}>10px</DropdownItem>
                                                                                        <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 12; this.setState({ items }) }}>12px</DropdownItem>
                                                                                        <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 14; this.setState({ items }) }}>14px</DropdownItem>
                                                                                        <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 16; this.setState({ items }) }}>16px</DropdownItem>
                                                                                        <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 18; this.setState({ items }) }}>18px</DropdownItem>
                                                                                        <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 20; this.setState({ items }) }}>20px</DropdownItem>
                                                                                    </DropdownMenu>
                                                                                </UncontrolledDropdown>
                                                                            </div>

                                                                        </>

                                                                    }

                                                                </Label>

                                                                <Input disabled size="sm" type="text"></Input>


                                                            </FormGroup>

                                                            :
                                                            null
                                                    }
                                                    {
                                                        item.type == 8 ?
                                                            <FormGroup tag="fieldset">
                                                                <Label size="sm">

                                                                    {/* <input style={cssStyle} type="text" value={item.name} onChange={(e) => {
                                                                        let items = this.state.items;
                                                                        items[idx].name = e.target.value;
                                                                        this.setState({
                                                                            items
                                                                        })
                                                                    }} />*/}
                                                                    {(this.state.linkingElement !== null && this.state.linkingElement !== idx && this.state.linkingElement > idx) || (this.state.showLink && this.conditionIndexOf(this.state.showLink, item.id, null) != -1) ?
                                                                        <button className={(this.state.showLink && this.conditionIndexOf(this.state.showLink, item.id, null) != -1) || (this.state.linkingElement !== null && this.state.items[this.state.linkingElement]?.conditions && this.state.items[this.state.linkingElement].conditions.length && this.conditionIndexOf(this.state.items[this.state.linkingElement].conditions, item.id) != -1) ? 'linking-element active' : 'linking-element'} onClick={() => {
                                                                            let items = this.state.items;
                                                                            //items[this.state.linkingElement].conditionQuestion = item.id;

                                                                            if (!items[this.state.linkingElement].conditions) {
                                                                                items[this.state.linkingElement].conditions = [];
                                                                            }

                                                                            let check = -1;
                                                                            for (let i = 0; i < items[this.state.linkingElement].conditions.length; i++) {
                                                                                if (items[this.state.linkingElement].conditions[i].conditionQuestion == item.id) {
                                                                                    check = i;
                                                                                }
                                                                            }

                                                                            if (check != -1) {
                                                                                items[this.state.linkingElement].conditions.splice(check, 1);
                                                                            } else {
                                                                                items[this.state.linkingElement].conditions.push({
                                                                                    conditionQuestion: item.id
                                                                                })
                                                                            }


                                                                            this.setState({
                                                                                items,
                                                                                //linkingElement: null
                                                                            });

                                                                        }}>{item.name}</button>

                                                                        :
                                                                        <>
                                                                            <input style={cssStyle} type="text" value={item.name} onChange={(e) => {
                                                                                let items = this.state.items;
                                                                                items[idx].name = e.target.value;
                                                                                this.setState({
                                                                                    items
                                                                                })
                                                                            }} className="edit-field-name" />
                                                                            <div className="text-options">
                                                                                <button className={item.fontStyle == 'italic' ? 'active' : ''} onClick={() => { let items = this.state.items; items[idx].fontStyle = items[idx].fontStyle == 'italic' ? null : 'italic'; this.setState({ items }) }}><i className="fa fa-italic" /></button>
                                                                                <button className={item.fontWeight == 'bold' ? 'active' : ''} onClick={() => { let items = this.state.items; items[idx].fontWeight = items[idx].fontWeight == 'bold' ? null : 'bold'; this.setState({ items }) }}><i className="fa fa-bold" /></button>

                                                                                <UncontrolledDropdown size="sm">
                                                                                    <DropdownToggle caret>
                                                                                        <i className="fa fa-font" />
                                                                                    </DropdownToggle>
                                                                                    <DropdownMenu>
                                                                                        <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 10; this.setState({ items }) }}>10px</DropdownItem>
                                                                                        <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 12; this.setState({ items }) }}>12px</DropdownItem>
                                                                                        <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 14; this.setState({ items }) }}>14px</DropdownItem>
                                                                                        <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 16; this.setState({ items }) }}>16px</DropdownItem>
                                                                                        <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 18; this.setState({ items }) }}>18px</DropdownItem>
                                                                                        <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 20; this.setState({ items }) }}>20px</DropdownItem>
                                                                                    </DropdownMenu>
                                                                                </UncontrolledDropdown>
                                                                            </div>

                                                                        </>

                                                                    }

                                                                </Label>

                                                                <Input size="sm" type="file" title={"No file chosen".translate(this.props.lang)} accept=".doc, .docx, .pdf" >Select file</Input>


                                                            </FormGroup>

                                                            :
                                                            null
                                                    }
                                                    {
                                                        item.type == 9 ?
                                                            <FormGroup tag="fieldset">
                                                                <Label size="sm">
                                                                    {/*
                                                                    <input style={cssStyle} type="text" value={item.name} onChange={(e) => {
                                                                        let items = this.state.items;
                                                                        items[idx].name = e.target.value;
                                                                        this.setState({
                                                                            items
                                                                        })
                                                                    }} />*/}
                                                                    {(this.state.linkingElement !== null && this.state.linkingElement !== idx && this.state.linkingElement > idx) || (this.state.showLink && this.conditionIndexOf(this.state.showLink, item.id, null) != -1) ?
                                                                        <button className={(this.state.showLink && this.conditionIndexOf(this.state.showLink, item.id, null) != -1) || (this.state.linkingElement !== null && this.state.items[this.state.linkingElement]?.conditions && this.state.items[this.state.linkingElement].conditions.length && this.conditionIndexOf(this.state.items[this.state.linkingElement].conditions, item.id) != -1) ? 'linking-element active' : 'linking-element'} onClick={() => {
                                                                            let items = this.state.items;
                                                                            //items[this.state.linkingElement].conditionQuestion = item.id;

                                                                            if (!items[this.state.linkingElement].conditions) {
                                                                                items[this.state.linkingElement].conditions = [];
                                                                            }

                                                                            let check = -1;
                                                                            for (let i = 0; i < items[this.state.linkingElement].conditions.length; i++) {
                                                                                if (items[this.state.linkingElement].conditions[i].conditionQuestion == item.id) {
                                                                                    check = i;
                                                                                }
                                                                            }

                                                                            if (check != -1) {
                                                                                items[this.state.linkingElement].conditions.splice(check, 1);
                                                                            } else {
                                                                                items[this.state.linkingElement].conditions.push({
                                                                                    conditionQuestion: item.id
                                                                                })
                                                                            }


                                                                            this.setState({
                                                                                items,
                                                                                //linkingElement: null
                                                                            });

                                                                        }}>{item.name}</button>

                                                                        :
                                                                        <>
                                                                            <input style={cssStyle} type="text" value={item.name} onChange={(e) => {
                                                                                let items = this.state.items;
                                                                                items[idx].name = e.target.value;
                                                                                this.setState({
                                                                                    items
                                                                                })
                                                                            }} className="edit-field-name" />
                                                                            <div className="text-options">
                                                                                <button className={item.fontStyle == 'italic' ? 'active' : ''} onClick={() => { let items = this.state.items; items[idx].fontStyle = items[idx].fontStyle == 'italic' ? null : 'italic'; this.setState({ items }) }}><i className="fa fa-italic" /></button>
                                                                                <button className={item.fontWeight == 'bold' ? 'active' : ''} onClick={() => { let items = this.state.items; items[idx].fontWeight = items[idx].fontWeight == 'bold' ? null : 'bold'; this.setState({ items }) }}><i className="fa fa-bold" /></button>

                                                                                <UncontrolledDropdown size="sm">
                                                                                    <DropdownToggle caret>
                                                                                        <i className="fa fa-font" />
                                                                                    </DropdownToggle>
                                                                                    <DropdownMenu>
                                                                                        <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 10; this.setState({ items }) }}>10px</DropdownItem>
                                                                                        <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 12; this.setState({ items }) }}>12px</DropdownItem>
                                                                                        <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 14; this.setState({ items }) }}>14px</DropdownItem>
                                                                                        <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 16; this.setState({ items }) }}>16px</DropdownItem>
                                                                                        <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 18; this.setState({ items }) }}>18px</DropdownItem>
                                                                                        <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 20; this.setState({ items }) }}>20px</DropdownItem>
                                                                                    </DropdownMenu>
                                                                                </UncontrolledDropdown>
                                                                            </div>

                                                                        </>

                                                                    }

                                                                </Label>

                                                                <Input size="sm" type="file" title={"No file chosen".translate(this.props.lang)} accept="image/png, image/jpeg, image/jpg, image/gif, image/svg" >Select Image</Input>


                                                            </FormGroup>

                                                            :
                                                            null
                                                    }
                                                    {
                                                        item.type == 6 ?
                                                            <FormGroup tag="fieldset">
                                                                <Label size="sm">

                                                                    {(this.state.linkingElement !== null && this.state.linkingElement !== idx && this.state.linkingElement > idx) || (this.state.showLink && this.conditionIndexOf(this.state.showLink, item.id, null) != -1) ?
                                                                        <button className={(this.state.showLink && this.conditionIndexOf(this.state.showLink, item.id, null) != -1) || (this.state.linkingElement !== null && this.state.items[this.state.linkingElement]?.conditions && this.state.items[this.state.linkingElement].conditions.length && this.conditionIndexOf(this.state.items[this.state.linkingElement].conditions, item.id) != -1) ? 'linking-element active' : 'linking-element'} onClick={() => {
                                                                            let items = this.state.items;
                                                                            //items[this.state.linkingElement].conditionQuestion = item.id;

                                                                            if (!items[this.state.linkingElement].conditions) {
                                                                                items[this.state.linkingElement].conditions = [];
                                                                            }

                                                                            let check = -1;
                                                                            for (let i = 0; i < items[this.state.linkingElement].conditions.length; i++) {
                                                                                if (items[this.state.linkingElement].conditions[i].conditionQuestion == item.id) {
                                                                                    check = i;
                                                                                }
                                                                            }

                                                                            if (check != -1) {
                                                                                items[this.state.linkingElement].conditions.splice(check, 1);
                                                                            } else {
                                                                                items[this.state.linkingElement].conditions.push({
                                                                                    conditionQuestion: item.id
                                                                                })
                                                                            }


                                                                            this.setState({
                                                                                items,
                                                                                //linkingElement: null
                                                                            });

                                                                        }}>{item.name}</button>

                                                                        :
                                                                        <>
                                                                            <input style={cssStyle} type="text" value={item.name} onChange={(e) => {
                                                                                let items = this.state.items;
                                                                                items[idx].name = e.target.value;
                                                                                this.setState({
                                                                                    items
                                                                                })
                                                                            }} className="edit-field-name" />
                                                                            <div className="text-options">
                                                                                <button className={item.fontStyle == 'italic' ? 'active' : ''} onClick={() => { let items = this.state.items; items[idx].fontStyle = items[idx].fontStyle == 'italic' ? null : 'italic'; this.setState({ items }) }}><i className="fa fa-italic" /></button>
                                                                                <button className={item.fontWeight == 'bold' ? 'active' : ''} onClick={() => { let items = this.state.items; items[idx].fontWeight = items[idx].fontWeight == 'bold' ? null : 'bold'; this.setState({ items }) }}><i className="fa fa-bold" /></button>

                                                                                <UncontrolledDropdown size="sm">
                                                                                    <DropdownToggle caret>
                                                                                        <i className="fa fa-font" />
                                                                                    </DropdownToggle>
                                                                                    <DropdownMenu>
                                                                                        <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 10; this.setState({ items }) }}>10px</DropdownItem>
                                                                                        <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 12; this.setState({ items }) }}>12px</DropdownItem>
                                                                                        <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 14; this.setState({ items }) }}>14px</DropdownItem>
                                                                                        <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 16; this.setState({ items }) }}>16px</DropdownItem>
                                                                                        <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 18; this.setState({ items }) }}>18px</DropdownItem>
                                                                                        <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 20; this.setState({ items }) }}>20px</DropdownItem>
                                                                                    </DropdownMenu>
                                                                                </UncontrolledDropdown>
                                                                            </div>

                                                                        </>

                                                                    }


                                                                </Label>
                                                                <Row>
                                                                    <Col lg="2">
                                                                        <FormGroup>
                                                                            <Label>{'Weight:'.translate(this.props.lang)}</Label>
                                                                            <Input disabled size="sm" type="text"></Input>

                                                                        </FormGroup>
                                                                    </Col>
                                                                    <Col lg="2">
                                                                        <FormGroup>
                                                                            <Label>{'Height:'.translate(this.props.lang)}</Label>
                                                                            <Input disabled size="sm" type="text"></Input>

                                                                        </FormGroup>

                                                                    </Col>
                                                                </Row>

                                                            </FormGroup>

                                                            :
                                                            null
                                                    }
                                                    {/* {
                                                        item.type == 7 ?
                                                            <FormGroup tag="fieldset">
                                                                <Label size="sm">

                                                                //     <input style={cssStyle} type="text" value={item.name} onChange={(e) => {
                                                                //         let items = this.state.items;
                                                                //         items[idx].name = e.target.value;
                                                                //         this.setState({
                                                                //             items
                                                                //         })
                                                                //     }} className="edit-field-name" />

                                                                //     <div className="text-options">
                                                                //         <button className={item.fontStyle == 'italic' ? 'active' : ''} onClick={() => { let items = this.state.items; items[idx].fontStyle = items[idx].fontStyle == 'italic' ? null : 'italic'; this.setState({ items }) }}><i className="fa fa-italic" /></button>
                                                                //         <button className={item.fontWeight == 'bold' ? 'active' : ''} onClick={() => { let items = this.state.items; items[idx].fontWeight = items[idx].fontWeight == 'bold' ? null : 'bold'; this.setState({ items }) }}><i className="fa fa-bold" /></button>

                                                                //         <UncontrolledDropdown size="sm">
                                                                //             <DropdownToggle caret>
                                                                //                 <i className="fa fa-font" />
                                                                //             </DropdownToggle>
                                                                //             <DropdownMenu>
                                                                //                 <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 10; this.setState({ items }) }}>10px</DropdownItem>
                                                                //                 <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 12; this.setState({ items }) }}>12px</DropdownItem>
                                                                //                 <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 14; this.setState({ items }) }}>14px</DropdownItem>
                                                                //                 <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 16; this.setState({ items }) }}>16px</DropdownItem>
                                                                //                 <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 18; this.setState({ items }) }}>18px</DropdownItem>
                                                                //                 <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 20; this.setState({ items }) }}>20px</DropdownItem>
                                                                //             </DropdownMenu>
                                                                //         </UncontrolledDropdown>
                                                                // </div>
                                                                    {(this.state.linkingElement !== null && this.state.linkingElement !== idx && this.state.linkingElement > idx) || (this.state.showLink && this.conditionIndexOf(this.state.showLink, item.id, null) != -1) ?
                                                                        <button className={(this.state.showLink && this.conditionIndexOf(this.state.showLink, item.id, null) != -1) || (this.state.linkingElement !== null && this.state.items[this.state.linkingElement]?.conditions && this.state.items[this.state.linkingElement].conditions.length && this.conditionIndexOf(this.state.items[this.state.linkingElement].conditions, item.id) != -1) ? 'linking-element active' : 'linking-element'} onClick={() => {
                                                                            let items = this.state.items;
                                                                            //items[this.state.linkingElement].conditionQuestion = item.id;

                                                                            if (!items[this.state.linkingElement].conditions) {
                                                                                items[this.state.linkingElement].conditions = [];
                                                                            }

                                                                            let check = -1;
                                                                            for (let i = 0; i < items[this.state.linkingElement].conditions.length; i++) {
                                                                                if (items[this.state.linkingElement].conditions[i].conditionQuestion == item.id) {
                                                                                    check = i;
                                                                                }
                                                                            }

                                                                            if (check != -1) {
                                                                                items[this.state.linkingElement].conditions.splice(check, 1);
                                                                            } else {
                                                                                items[this.state.linkingElement].conditions.push({
                                                                                    conditionQuestion: item.id
                                                                                })
                                                                            }


                                                                            this.setState({
                                                                                items,
                                                                                //linkingElement: null
                                                                            });

                                                                        }}>{item.name}</button>

                                                                        :
                                                                        <>
                                                                            <input style={cssStyle} type="text" value={item.name} onChange={(e) => {
                                                                                let items = this.state.items;
                                                                                items[idx].name = e.target.value;
                                                                                this.setState({
                                                                                    items
                                                                                })
                                                                            }} className="edit-field-name" />
                                                                            <div className="text-options">
                                                                                <button className={item.fontStyle == 'italic' ? 'active' : ''} onClick={() => { let items = this.state.items; items[idx].fontStyle = items[idx].fontStyle == 'italic' ? null : 'italic'; this.setState({ items }) }}><i className="fa fa-italic" /></button>
                                                                                <button className={item.fontWeight == 'bold' ? 'active' : ''} onClick={() => { let items = this.state.items; items[idx].fontWeight = items[idx].fontWeight == 'bold' ? null : 'bold'; this.setState({ items }) }}><i className="fa fa-bold" /></button>

                                                                                <UncontrolledDropdown size="sm">
                                                                                    <DropdownToggle caret>
                                                                                        <i className="fa fa-font" />
                                                                                    </DropdownToggle>
                                                                                    <DropdownMenu>
                                                                                        <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 10; this.setState({ items }) }}>10px</DropdownItem>
                                                                                        <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 12; this.setState({ items }) }}>12px</DropdownItem>
                                                                                        <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 14; this.setState({ items }) }}>14px</DropdownItem>
                                                                                        <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 16; this.setState({ items }) }}>16px</DropdownItem>
                                                                                        <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 18; this.setState({ items }) }}>18px</DropdownItem>
                                                                                        <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 20; this.setState({ items }) }}>20px</DropdownItem>
                                                                                    </DropdownMenu>
                                                                                </UncontrolledDropdown>
                                                                            </div>

                                                                        </>

                                                                    }


                                                                </Label>
                                                                <Row>
                                                                    <Col lg="2">
                                                                        <FormGroup>
                                                                            <Label>{'Free kort number:'.translate(this.props.lang)}</Label>
                                                                            <Input disabled size="sm" type="text"></Input>

                                                                        </FormGroup>
                                                                    </Col>
                                                                    <Col lg="2">
                                                                        <FormGroup>
                                                                            <Label>{'Valid from:'.translate(this.props.lang)}</Label>
                                                                            <Input disabled size="sm" type="text"></Input>

                                                                        </FormGroup>

                                                                    </Col>
                                                                    <Col lg="2">
                                                                        <FormGroup>
                                                                            <Label>{'Valid to:'.translate(this.props.lang)}</Label>
                                                                            <Input disabled size="sm" type="text"></Input>

                                                                        </FormGroup>

                                                                    </Col>
                                                                    <Col lg="2">
                                                                        <FormGroup>
                                                                            <Label>{'Issued at:'.translate(this.props.lang)}</Label>
                                                                            <Input disabled size="sm" type="text"></Input>

                                                                        </FormGroup>

                                                                    </Col>

                                                                </Row>

                                                            </FormGroup>

                                                            :
                                                            null
                                                    } 
                                                */}
                                                    {
                                                        item.type == 12 ?
                                                            <FormGroup tag="fieldset">
                                                                <Label size="sm">


                                                                    {(this.state.linkingElement !== null && this.state.linkingElement !== idx && this.state.linkingElement > idx) || (this.state.showLink && this.conditionIndexOf(this.state.showLink, item.id, null) != -1) ?
                                                                        <button className={(this.state.showLink && this.conditionIndexOf(this.state.showLink, item.id, null) != -1) || (this.state.linkingElement !== null && this.state.items[this.state.linkingElement]?.conditions && this.state.items[this.state.linkingElement].conditions.length && this.conditionIndexOf(this.state.items[this.state.linkingElement].conditions, item.id) != -1) ? 'linking-element active' : 'linking-element'} onClick={() => {
                                                                            let items = this.state.items;
                                                                            //items[this.state.linkingElement].conditionQuestion = item.id;

                                                                            if (!items[this.state.linkingElement].conditions) {
                                                                                items[this.state.linkingElement].conditions = [];
                                                                            }

                                                                            let check = -1;
                                                                            for (let i = 0; i < items[this.state.linkingElement].conditions.length; i++) {
                                                                                if (items[this.state.linkingElement].conditions[i].conditionQuestion == item.id) {
                                                                                    check = i;
                                                                                }
                                                                            }

                                                                            if (check != -1) {
                                                                                items[this.state.linkingElement].conditions.splice(check, 1);
                                                                            } else {
                                                                                items[this.state.linkingElement].conditions.push({
                                                                                    conditionQuestion: item.id
                                                                                })
                                                                            }


                                                                            this.setState({
                                                                                items,
                                                                                //linkingElement: null
                                                                            });

                                                                        }}>{item.name}</button>

                                                                        :
                                                                        <>
                                                                            <input style={cssStyle} type="text" value={item.name} onChange={(e) => {
                                                                                let items = this.state.items;
                                                                                items[idx].name = e.target.value;
                                                                                this.setState({
                                                                                    items
                                                                                })
                                                                            }} className="edit-field-name" />
                                                                            <div className="text-options">
                                                                                <button className={item.fontStyle == 'italic' ? 'active' : ''} onClick={() => { let items = this.state.items; items[idx].fontStyle = items[idx].fontStyle == 'italic' ? null : 'italic'; this.setState({ items }) }}><i className="fa fa-italic" /></button>
                                                                                <button className={item.fontWeight == 'bold' ? 'active' : ''} onClick={() => { let items = this.state.items; items[idx].fontWeight = items[idx].fontWeight == 'bold' ? null : 'bold'; this.setState({ items }) }}><i className="fa fa-bold" /></button>

                                                                                <UncontrolledDropdown size="sm">
                                                                                    <DropdownToggle caret>
                                                                                        <i className="fa fa-font" />
                                                                                    </DropdownToggle>
                                                                                    <DropdownMenu>
                                                                                        <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 10; this.setState({ items }) }}>10px</DropdownItem>
                                                                                        <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 12; this.setState({ items }) }}>12px</DropdownItem>
                                                                                        <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 14; this.setState({ items }) }}>14px</DropdownItem>
                                                                                        <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 16; this.setState({ items }) }}>16px</DropdownItem>
                                                                                        <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 18; this.setState({ items }) }}>18px</DropdownItem>
                                                                                        <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 20; this.setState({ items }) }}>20px</DropdownItem>
                                                                                    </DropdownMenu>
                                                                                </UncontrolledDropdown>
                                                                            </div>

                                                                        </>

                                                                    }



                                                                </Label>
                                                                <Row>
                                                                    <Col lg="2">
                                                                        <FormGroup>
                                                                            <Label>{'Systolic:'.translate(this.props.lang)}</Label>
                                                                            <Input disabled size="sm" type="text"></Input>

                                                                        </FormGroup>
                                                                    </Col>
                                                                    <Col lg="2">
                                                                        <FormGroup>
                                                                            <Label>{'Diastolic:'.translate(this.props.lang)}</Label>
                                                                            <Input disabled size="sm" type="text"></Input>

                                                                        </FormGroup>

                                                                    </Col>
                                                                    <Col lg="2">
                                                                        <FormGroup>
                                                                            <Label>{'Pulse:'.translate(this.props.lang)}</Label>
                                                                            <Input disabled size="sm" type="text"></Input>

                                                                        </FormGroup>

                                                                    </Col>
                                                                </Row>

                                                            </FormGroup>

                                                            :
                                                            null
                                                    }

                                                    {
                                                        item.type == 13 ?
                                                            <FormGroup tag="fieldset">
                                                                <Label size="sm">


                                                                    {(this.state.linkingElement !== null && this.state.linkingElement !== idx && this.state.linkingElement > idx) || (this.state.showLink && this.conditionIndexOf(this.state.showLink, item.id, null) != -1) ?
                                                                        <button className={(this.state.showLink && this.conditionIndexOf(this.state.showLink, item.id, null) != -1) || (this.state.linkingElement !== null && this.state.items[this.state.linkingElement]?.conditions && this.state.items[this.state.linkingElement].conditions.length && this.conditionIndexOf(this.state.items[this.state.linkingElement].conditions, item.id) != -1) ? 'linking-element active' : 'linking-element'} onClick={() => {
                                                                            let items = this.state.items;
                                                                            //items[this.state.linkingElement].conditionQuestion = item.id;

                                                                            if (!items[this.state.linkingElement].conditions) {
                                                                                items[this.state.linkingElement].conditions = [];
                                                                            }

                                                                            let check = -1;
                                                                            for (let i = 0; i < items[this.state.linkingElement].conditions.length; i++) {
                                                                                if (items[this.state.linkingElement].conditions[i].conditionQuestion == item.id) {
                                                                                    check = i;
                                                                                }
                                                                            }

                                                                            if (check != -1) {
                                                                                items[this.state.linkingElement].conditions.splice(check, 1);
                                                                            } else {
                                                                                items[this.state.linkingElement].conditions.push({
                                                                                    conditionQuestion: item.id
                                                                                })
                                                                            }


                                                                            this.setState({
                                                                                items,
                                                                                //linkingElement: null
                                                                            });

                                                                        }}>{item.name}</button>

                                                                        :
                                                                        <>
                                                                            <input style={cssStyle} type="text" value={item.name} onChange={(e) => {
                                                                                let items = this.state.items;
                                                                                items[idx].name = e.target.value;
                                                                                this.setState({
                                                                                    items
                                                                                })
                                                                            }} className="edit-field-name" />
                                                                            <div className="text-options">
                                                                                <button className={item.fontStyle == 'italic' ? 'active' : ''} onClick={() => { let items = this.state.items; items[idx].fontStyle = items[idx].fontStyle == 'italic' ? null : 'italic'; this.setState({ items }) }}><i className="fa fa-italic" /></button>
                                                                                <button className={item.fontWeight == 'bold' ? 'active' : ''} onClick={() => { let items = this.state.items; items[idx].fontWeight = items[idx].fontWeight == 'bold' ? null : 'bold'; this.setState({ items }) }}><i className="fa fa-bold" /></button>

                                                                                <UncontrolledDropdown size="sm">
                                                                                    <DropdownToggle caret>
                                                                                        <i className="fa fa-font" />
                                                                                    </DropdownToggle>
                                                                                    <DropdownMenu>
                                                                                        <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 10; this.setState({ items }) }}>10px</DropdownItem>
                                                                                        <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 12; this.setState({ items }) }}>12px</DropdownItem>
                                                                                        <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 14; this.setState({ items }) }}>14px</DropdownItem>
                                                                                        <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 16; this.setState({ items }) }}>16px</DropdownItem>
                                                                                        <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 18; this.setState({ items }) }}>18px</DropdownItem>
                                                                                        <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 20; this.setState({ items }) }}>20px</DropdownItem>
                                                                                    </DropdownMenu>
                                                                                </UncontrolledDropdown>
                                                                            </div>

                                                                        </>

                                                                    }



                                                                </Label>
                                                                <Row>
                                                                    <Col lg="2">
                                                                        <FormGroup>
                                                                            {/* <Label>{'Systolic:'.translate(this.props.lang)}</Label> */}
                                                                            <Input disabled size="sm" type="text"></Input>

                                                                        </FormGroup>
                                                                    </Col>

                                                                </Row>

                                                            </FormGroup>

                                                            :
                                                            null
                                                    }

                                                    {
                                                        item.type == 10 ?
                                                            <div className='new-paragraph-checkbox'>
                                                                <h4>
                                                                    <input style={cssStyle} type="text" value={item.name} className="new-line-field" disabled />
                                                                </h4>

                                                                <Checkbox onChange={() => {
                                                                    let items = this.state.items;
                                                                    items[idx].userInput = !items[idx].userInput;
                                                                    this.setState({ items }, () => this.detectChange())
                                                                }} checked={item.userInput ? true : false} label={"Add an empty line".translate(this.props.lang)} />
                                                            </div>
                                                            // <h4><input style={cssStyle} type="text" value={item.name} className="new-line-field m-l-45" disabled />


                                                            // </h4>
                                                            :
                                                            null
                                                    }


                                                    {
                                                        item.type == 4 ?

                                                            <FormGroup tag="fieldset">
                                                                <Label size="sm">
                                                                    {/*
                                                                    <input style={cssStyle} type="text" value={item.name} onChange={(e) => {
                                                                    let items = this.state.items;
                                                                    items[idx].name = e.target.value;
                                                                    this.setState({
                                                                        items
                                                                    })
                                                                }} className="edit-field-name" />

                                                                    <div className="text-options">
                                                                        <button className={item.fontStyle == 'italic' ? 'active' : ''} onClick={() => { let items = this.state.items; items[idx].fontStyle = items[idx].fontStyle == 'italic' ? null : 'italic'; this.setState({ items }) }}><i className="fa fa-italic" /></button>
                                                                        <button className={item.fontWeight == 'bold' ? 'active' : ''} onClick={() => { let items = this.state.items; items[idx].fontWeight = items[idx].fontWeight == 'bold' ? null : 'bold'; this.setState({ items }) }}><i className="fa fa-bold" /></button>

                                                                        <UncontrolledDropdown size="sm">
                                                                            <DropdownToggle caret>
                                                                                <i className="fa fa-font" />
                                                                            </DropdownToggle>
                                                                            <DropdownMenu>
                                                                                <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 10; this.setState({ items }) }}>10px</DropdownItem>
                                                                                <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 12; this.setState({ items }) }}>12px</DropdownItem>
                                                                                <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 14; this.setState({ items }) }}>14px</DropdownItem>
                                                                                <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 16; this.setState({ items }) }}>16px</DropdownItem>
                                                                                <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 18; this.setState({ items }) }}>18px</DropdownItem>
                                                                                <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 20; this.setState({ items }) }}>20px</DropdownItem>
                                                                            </DropdownMenu>
                                                                        </UncontrolledDropdown>
                                                            </div>*/}
                                                                    {(this.state.linkingElement !== null && this.state.linkingElement !== idx && this.state.linkingElement > idx) || (this.state.showLink && this.conditionIndexOf(this.state.showLink, item.id, null) != -1) ?
                                                                        <button className={(this.state.showLink && this.conditionIndexOf(this.state.showLink, item.id, null) != -1) || (this.state.linkingElement !== null && this.state.items[this.state.linkingElement]?.conditions && this.state.items[this.state.linkingElement].conditions.length && this.conditionIndexOf(this.state.items[this.state.linkingElement].conditions, item.id) != -1) ? 'linking-element active' : 'linking-element'} onClick={() => {
                                                                            let items = this.state.items;
                                                                            //items[this.state.linkingElement].conditionQuestion = item.id;

                                                                            if (!items[this.state.linkingElement].conditions) {
                                                                                items[this.state.linkingElement].conditions = [];
                                                                            }

                                                                            let check = -1;
                                                                            for (let i = 0; i < items[this.state.linkingElement].conditions.length; i++) {
                                                                                if (items[this.state.linkingElement].conditions[i].conditionQuestion == item.id) {
                                                                                    check = i;
                                                                                }
                                                                            }

                                                                            if (check != -1) {
                                                                                items[this.state.linkingElement].conditions.splice(check, 1);
                                                                            } else {
                                                                                items[this.state.linkingElement].conditions.push({
                                                                                    conditionQuestion: item.id
                                                                                })
                                                                            }


                                                                            this.setState({
                                                                                items,
                                                                                //linkingElement: null
                                                                            });

                                                                        }}>{item.name}</button>

                                                                        :
                                                                        <>
                                                                            <input style={cssStyle} type="text" value={item.name} onChange={(e) => {
                                                                                let items = this.state.items;
                                                                                items[idx].name = e.target.value;
                                                                                this.setState({
                                                                                    items
                                                                                })
                                                                            }} className="edit-field-name" />
                                                                            <div className="text-options">
                                                                                <button className={item.fontStyle == 'italic' ? 'active' : ''} onClick={() => { let items = this.state.items; items[idx].fontStyle = items[idx].fontStyle == 'italic' ? null : 'italic'; this.setState({ items }) }}><i className="fa fa-italic" /></button>
                                                                                <button className={item.fontWeight == 'bold' ? 'active' : ''} onClick={() => { let items = this.state.items; items[idx].fontWeight = items[idx].fontWeight == 'bold' ? null : 'bold'; this.setState({ items }) }}><i className="fa fa-bold" /></button>

                                                                                <UncontrolledDropdown size="sm">
                                                                                    <DropdownToggle caret>
                                                                                        <i className="fa fa-font" />
                                                                                    </DropdownToggle>
                                                                                    <DropdownMenu>
                                                                                        <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 10; this.setState({ items }) }}>10px</DropdownItem>
                                                                                        <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 12; this.setState({ items }) }}>12px</DropdownItem>
                                                                                        <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 14; this.setState({ items }) }}>14px</DropdownItem>
                                                                                        <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 16; this.setState({ items }) }}>16px</DropdownItem>
                                                                                        <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 18; this.setState({ items }) }}>18px</DropdownItem>
                                                                                        <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 20; this.setState({ items }) }}>20px</DropdownItem>
                                                                                    </DropdownMenu>
                                                                                </UncontrolledDropdown>
                                                                            </div>

                                                                        </>

                                                                    }



                                                                </Label>


                                                                <HTML disableImage={true} height={350} value={item.text} onChange={(text) => {
                                                                    let items = this.state.items;
                                                                    items[idx].text = text;
                                                                    this.setState({
                                                                        items
                                                                    })
                                                                }} />


                                                            </FormGroup>
                                                            :
                                                            null
                                                    }


                                                    <div>

                                                        {
                                                            item.type != 10 ?
                                                                <div className="referal-checkbox-container">
                                                                    <Checkbox checked={item.required} onChange={(e) => {
                                                                        let items = this.state.items;
                                                                        items[idx].required = e.target.checked;
                                                                        this.setState({
                                                                            items
                                                                        })
                                                                    }} label={'Required'.translate(this.props.lang)} />

                                                                </div>
                                                                :
                                                                null
                                                        }

                                                        {/* Vrati ako propadne
                                                        <Label check style={{marginTop: 12, marginRight: 10, fontSize: 12}}>
                                                            <Input type="checkbox" checked={item.required} onChange={(e) => {
                                                            let items = this.state.items;
                                                            items[idx].required = e.target.checked;
                                                            this.setState({
                                                                items
                                                            })
                                                        }} /> 
                                                           {'Required'.translate(this.props.lang)}
                                                        </Label>
                                                        */}
                                                        <UncontrolledDropdown size="sm" direction="left">
                                                            <DropdownToggle caret>
                                                                {'Type'.translate(this.props.lang)}
                                                            </DropdownToggle>
                                                            <DropdownMenu>
                                                                {/* {
                                                                    this.state.intelligentReferral ?
                                                                        <DropdownItem
                                                                            onClick={() => {
                                                                                let items = this.state.items;
                                                                                items[idx].type = 0;
                                                                                items[idx].name = 'Title'.translate(this.props.lang);
                                                                                this.setState({
                                                                                    items
                                                                                })
                                                                            }}>{'Title'.translate(this.props.lang)}</DropdownItem>

                                                                        :
                                                                        null
                                                                } */}
                                                                <DropdownItem onClick={() => {
                                                                    let items = this.state.items;
                                                                    if (item.type != 1 && item.type != 2) {
                                                                        items[idx].name = 'Single option answer name'.translate(this.props.lang);
                                                                        items[idx].answers = [
                                                                            {
                                                                                id: uuidv4(),
                                                                                name: ''
                                                                            },
                                                                            {
                                                                                id: uuidv4(),
                                                                                name: ''
                                                                            }
                                                                        ]
                                                                    }
                                                                    items[idx].type = 1;
                                                                    this.setState({
                                                                        items
                                                                    })
                                                                }}>{'Single option answer'.translate(this.props.lang)}</DropdownItem>
                                                                <DropdownItem onClick={() => {
                                                                    let items = this.state.items;

                                                                    if (item.type != 1 && item.type != 2) {
                                                                        items[idx].name = 'Multiple options answer name'.translate(this.props.lang);
                                                                        items[idx].answers = [
                                                                            {
                                                                                id: uuidv4(),
                                                                                name: ''
                                                                            },
                                                                            {
                                                                                id: uuidv4(),
                                                                                name: ''
                                                                            }
                                                                        ]
                                                                    } else {
                                                                        if (items[idx].name == 'Single option answer name'.translate(this.props.lang)) {
                                                                            items[idx].name = 'Multiple options answer name'.translate(this.props.lang);
                                                                        }
                                                                    }

                                                                    items[idx].type = 2;

                                                                    this.setState({
                                                                        items
                                                                    })
                                                                }}>{'Multiple options answer'.translate(this.props.lang)}</DropdownItem>
                                                                <DropdownItem onClick={() => {
                                                                    let items = this.state.items;
                                                                    items[idx].type = 3;
                                                                    items[idx].name = 'Text answer name'.translate(this.props.lang);
                                                                    this.setState({
                                                                        items
                                                                    })
                                                                }}>{'Text answer'.translate(this.props.lang)}</DropdownItem>
                                                                {/* {
                                                                    this.state.intelligentReferral ?
                                                                        <DropdownItem onClick={() => {
                                                                            let items = this.state.items;
                                                                            items[idx].type = 5;
                                                                            items[idx].name = 'Diagnos'.translate(this.props.lang);
                                                                            this.setState({
                                                                                items
                                                                            })
                                                                        }}>{'Diagnos'.translate(this.props.lang)}</DropdownItem>

                                                                        :
                                                                        null
                                                                } */}

                                                                {/* <DropdownItem onClick={() => {
                                                                    let items = this.state.items;
                                                                    items[idx].type = 6;
                                                                    items[idx].name = "Enter the patient's weight and height".translate(this.props.lang);
                                                                    this.setState({
                                                                        items
                                                                    })
                                                                }}>{"Enter the patient's weight and height".translate(this.props.lang)}</DropdownItem>
                                                                
                                                                <DropdownItem onClick={() => {
                                                                    let items = this.state.items;
                                                                    items[idx].type = 9;
                                                                    items[idx].name = 'Image'.translate(this.props.lang);
                                                                    this.setState({
                                                                        items
                                                                    })
                                                                }}>{'Image'.translate(this.props.lang)}</DropdownItem>
                                                                <DropdownItem onClick={() => {
                                                                    let items = this.state.items;
                                                                    items[idx].type = 4;
                                                                    items[idx].name = 'Treatment instructions'.translate(this.props.lang);
                                                                    this.setState({
                                                                        items
                                                                    })
                                                                }}>{'Treatment instructions'.translate(this.props.lang)}</DropdownItem>
                                                                <DropdownItem
                                                                    onClick={() => {
                                                                        let items = this.state.items;
                                                                        items[idx].type = 10;
                                                                        items[idx].name = 'New empty row'.translate(this.props.lang);
                                                                        this.setState({
                                                                            items
                                                                        })
                                                                    }}>{'New empty row'.translate(this.props.lang)}</DropdownItem>
                                                                <DropdownItem onClick={() => {
                                                                    let items = this.state.items;
                                                                    items[idx].type = 12;
                                                                    items[idx].name = 'Blood pressure measured to'.translate(this.props.lang);
                                                                    this.setState({
                                                                        items
                                                                    })
                                                                }}>{'Blood pressure'.translate(this.props.lang)}</DropdownItem>

                                                                <DropdownItem onClick={() => {
                                                                    let items = this.state.items;
                                                                    items[idx].type = 13;
                                                                    items[idx].name = 'The patient states VAS:'.translate(this.props.lang);
                                                                    this.setState({
                                                                        items
                                                                    })
                                                                }}>{'VAS-scale'.translate(this.props.lang)}</DropdownItem> */}

                                                            </DropdownMenu>

                                                        </UncontrolledDropdown>


                                                        {
                                                            this.state.intelligentReferral ?

                                                                <>
                                                                    {item.type == 0 ?

                                                                        <button className={item.displayOnReferral ? /*'print-enabled'*/ 'referral-builder-tooltip-wrap' : 'referral-builder-tooltip-wrap'} onClick={() => {
                                                                            let items = this.state.items;
                                                                            items[idx].displayOnReferral = !items[idx].displayOnReferral;

                                                                            this.setState({
                                                                                items
                                                                            }, () => this.detectChange())
                                                                        }}>
                                                                            {
                                                                                item.displayOnReferral ?
                                                                                    <div className='print-show-hide-icon-wrap'>
                                                                                        <Isvg src={viewIcon} />
                                                                                    </div>
                                                                                    :
                                                                                    <div className='print-show-hide-icon-wrap hide-icon-wrap'>
                                                                                        <Isvg src={hideIcon} />
                                                                                    </div>
                                                                            }
                                                                            <div className='referral-builder-tooltip2'>{'Display title in preview'.translate(this.props.lang)}</div>

                                                                        </button>


                                                                        :
                                                                        null
                                                                    }
                                                                    {idx != 0 ?
                                                                        <button className={this.state.linkingElement == idx ? 'linking-enabled' : (item.conditions && item.conditions.length) ? 'item-linked' : ''} onMouseEnter={() => {
                                                                            if (item.conditions && item.conditions.length) {
                                                                                this.setState({
                                                                                    showLink: item.conditions
                                                                                })
                                                                            }
                                                                        }} onMouseLeave={() => {
                                                                            if (item.conditions && item.conditions.length) {
                                                                                this.setState({
                                                                                    showLink: null
                                                                                })
                                                                            }
                                                                        }} onClick={() => {
                                                                            this.setState({
                                                                                linkingElement: this.state.linkingElement !== idx ? idx : null,
                                                                                //showLink: this.state.linkingElement !== idx ? item.conditions  : null
                                                                            })
                                                                        }}><Isvg src={Link} /></button>
                                                                        :
                                                                        null
                                                                    }

                                                                </>
                                                                :
                                                                null
                                                        }

                                                        <button onClick={() => {
                                                            this.setState({
                                                                deleteElementModal: idx,
                                                            })
                                                        }}><Isvg src={garabage} /></button>

                                                        <UncontrolledDropdown size="sm" className="insert-above-below">
                                                            <DropdownToggle>
                                                                <Isvg src={moreIcon} />
                                                            </DropdownToggle>
                                                            <DropdownMenu>
                                                                {
                                                                    idx > 0 ?
                                                                        <DropdownItem
                                                                            onClick={() => {
                                                                                let items = this.state.items;
                                                                                items.splice(idx, 0, {
                                                                                    name: 'Single option answer name'.translate(this.props.lang),
                                                                                    answers: [
                                                                                        {
                                                                                            id: uuidv4(),
                                                                                            name: ''
                                                                                        },
                                                                                        {
                                                                                            id: uuidv4(),
                                                                                            name: ''
                                                                                        }
                                                                                    ],
                                                                                    id: uuidv4(),
                                                                                    type: 1,
                                                                                });

                                                                                this.setState({
                                                                                    items
                                                                                })
                                                                            }}>{"Insert above".translate(this.props.lang)}</DropdownItem>
                                                                        :
                                                                        null
                                                                }

                                                                <DropdownItem
                                                                    onClick={() => {
                                                                        let items = this.state.items;
                                                                        items.splice(idx + 1, 0, {
                                                                            name: 'Single option answer name'.translate(this.props.lang),
                                                                            answers: [
                                                                                {
                                                                                    id: uuidv4(),
                                                                                    name: ''
                                                                                },
                                                                                {
                                                                                    id: uuidv4(),
                                                                                    name: ''
                                                                                }
                                                                            ],
                                                                            id: uuidv4(),
                                                                            type: 1,
                                                                        });

                                                                        this.setState({
                                                                            items
                                                                        })
                                                                    }}
                                                                >{"Insert below".translate(this.props.lang)}</DropdownItem>
                                                            </DropdownMenu>
                                                        </UncontrolledDropdown>
                                                        {/* <Button color="primary" style={{ padding: 0 }} className="add-new-el-btn" size="sm" onClick={() => {
                                                            let items = this.state.items;
                                                            items.splice(idx + 1, 0, {
                                                                name: 'Single option answer name'.translate(this.props.lang),
                                                                answers: [
                                                                    {
                                                                        id: uuidv4(),
                                                                        name: ''
                                                                    },
                                                                    {
                                                                        id: uuidv4(),
                                                                        name: ''
                                                                    }
                                                                ],
                                                                id: uuidv4(),
                                                                type: 1,
                                                            });
                                                   
                                                            this.setState({
                                                                items
                                                            })
                                                        }} ><Isvg src={addIcon} />
                                                        <div className="chat-icons-tooltip">{'Add new element'.translate(this.props.lang)}</div>
                                                        </Button> */}


                                                    </div>


                                                </div>
                                            )
                                        }
                                    })}></SortableList>
                            </div>



                            <div className="panel">
                                <Button color="primary" size="sm" onClick={() => {
                                    let items = this.state.items;
                                    items.push({
                                        name: 'Single option answer name'.translate(this.props.lang),
                                        answers: [
                                            {
                                                id: uuidv4(),
                                                name: ''
                                            },
                                            {
                                                id: uuidv4(),
                                                name: ''
                                            }
                                        ],
                                        id: uuidv4(),
                                        type: 1,
                                    });
                                    this.setState({
                                        items
                                    })
                                }} style={{ padding: '10px 22px' }}>{'Add new element'.translate(this.props.lang)}</Button>
                                <Button size="sm" onClick={() => this.setState({ openPageWarningModal: 1 })} disabled={this.state.checkActiveSave ? false : true} style={{ marginLeft: 20, padding: '10px 22px' }} color="primary">{'Save'.translate(this.props.lang).translate(this.props.lang)}</Button>

                            </div>

                        </Col>


                    </Row>

                    {
                        this.state.messageModalError ?
                            <Modal isOpen={this.state.messageModalError} centered toggle={() => this.setState({ messageModalError: !this.state.runModal })}>
                                {/* <ModalHeader style={{ margin: 'auto' }} >
                                    // {'Error message'.translate(this.props.lang)}
                                    {'Warning'.translate(this.props.lang)}
                                </ModalHeader> */}

                                <ModalBody className='delete-modal'>
                                    <p>{'You cannot drop here this element!'.translate(this.props.lang)}</p>

                                </ModalBody>
                                <ModalFooter className='buttons-right-blue'>
                                    <Button style={{ minWidth: 100 }} color='primary' onClick={() => this.setState({ messageModalError: !this.state.messageModalError })}>{'Ok'.translate(this.props.lang)}</Button>

                                </ModalFooter>

                            </Modal>
                            :
                            null
                    }

                </Container>

                {/* <DeleteModal
                    lang={this.props.lang}
                    isOpen={this.state.deleteElementModal !== null}
                    toggle={() => this.setState({ deleteElementModal: null })}
                    handler={() => {
                        let items = this.state.items;
                        items.splice(this.state.deleteElementModal, 1);
                        this.setState({
                            items,
                            deleteElementModal: null
                        })
                    }}
                >
                    {'Delete element'.translate(this.props.lang)} <strong>{this.state.items[this.state.deleteElementModal] && this.state.items[this.state.deleteElementModal].name}</strong>?
                </DeleteModal>
                <DeleteModal
                    lang={this.props.lang}
                    isOpen={this.state.deleteOptionModal !== null}
                    toggle={() => this.setState({ deleteOptionModal: null })}
                    handler={() => {
                        let items = this.state.items;
                        items[this.state.deleteOptionModal.idx].answers.splice(this.state.deleteOptionModal.aidx, 1);
                        this.setState({
                            items,
                            deleteOptionModal: null,
                        })
                    }}
                >
                    {'Delete option'.translate(this.props.lang)}?
                </DeleteModal> */}
                <DeleteModal
                    lang={this.props.lang}
                    isOpen={this.state.deleteElementModal !== null}
                    toggle={() => this.setState({ deleteElementModal: null })}
                    handler={() => {
                        let items = this.state.items;
                        let relations = this.checkRelations(this.state.items[this.state.deleteElementModal].id);
                        if (relations && relations.length && (relations[0].length || relations[1].length)) {
                            this.setState({
                                relationError: relations
                            })

                            return;
                        }


                        items.splice(this.state.deleteElementModal, 1);
                        this.setState({
                            items,
                            deleteElementModal: null
                        })
                    }}
                >
                    {'Delete element'.translate(this.props.lang)} <strong>{this.state.items[this.state.deleteElementModal] && this.state.items[this.state.deleteElementModal].name}</strong>?
                </DeleteModal>
                <DeleteModal
                    lang={this.props.lang}
                    isOpen={this.state.deleteOptionModal !== null}
                    toggle={() => this.setState({ deleteOptionModal: null })}
                    handler={() => {
                        let items = this.state.items;

                        let relations = this.checkAnswerRelations(this.state.items[this.state.deleteOptionModal.idx].id, this.state.items[this.state.deleteOptionModal.idx].answers[this.state.deleteOptionModal.aidx].id);
                        if (relations && relations.length && (relations[0].length || relations[1].length)) {
                            this.setState({
                                answerRelationError: relations
                            })

                            return;
                        }


                        items[this.state.deleteOptionModal.idx].answers.splice(this.state.deleteOptionModal.aidx, 1);
                        this.setState({
                            items,
                            deleteOptionModal: null,
                        })
                    }}
                >
                    {'Delete option'.translate(this.props.lang)}?
                </DeleteModal>


                {
                    this.state.error ?

                        <ErrorModal lang={this.props.lang}
                            isOpen={this.state.error}
                            toggle={() => this.setState({ error: null })}

                        >
                            {this.state.error.translate(this.props.lang)}
                        </ErrorModal>

                        :
                        null
                }

                {
                    this.state.relationError ?

                        <ErrorModal lang={this.props.lang}
                            isOpen={this.state.relationError}
                            toggle={() => this.setState({ relationError: null })}
                            size="xl"

                        >
                            <h4>{'You need to unlink current relationships to continue'.translate(this.props.lang)}</h4>
                            <div className="relations-content">

                                <h6>{'Upper conditions'.translate(this.props.lang)}</h6>
                                <Table striped className="relations-table">
                                    <tr>
                                        <th>{'Condition element'.translate(this.props.lang)}</th>
                                        <th>{'Answer'.translate(this.props.lang)}</th>
                                        <th>{'Element'.translate(this.props.lang)}</th>
                                        <th>{'Action'.translate(this.props.lang)}</th>
                                    </tr>

                                    {
                                        this.state.relationError[0].map((item) => {
                                            return (
                                                <tr>
                                                    <td>{item.conditionElement.itemIndex}. {item.conditionElement.name}</td>
                                                    <td>{item.conditionElement.answer}</td>
                                                    <td>{item.element.itemIndex}. {item.element.name}</td>
                                                    <td><button onClick={() => {
                                                        let items = this.state.items;
                                                        items[item.element.itemIndex].conditions.splice(item.element.conditionIndex, 1);
                                                        this.setState({
                                                            items
                                                        }, () => {
                                                            let relations = this.checkRelations(this.state.items[this.state.deleteElementModal].id);
                                                            if (relations && relations.length && (relations[0].length || relations[1].length)) {
                                                                this.setState({
                                                                    relationError: relations
                                                                })

                                                            } else {
                                                                this.setState({
                                                                    relationError: null
                                                                })
                                                            }

                                                        })
                                                    }}><i className="fa fa-unlink danger-color" /></button></td>
                                                </tr>
                                            )
                                        })
                                    }


                                </Table>

                                <h6>{'Down conditions'.translate(this.props.lang)}</h6>
                                <Table striped className="relations-table">
                                    <tr>
                                        <th>{'Condition element'.translate(this.props.lang)}</th>
                                        <th>{'Answer'.translate(this.props.lang)}</th>
                                        <th>{'Element'.translate(this.props.lang)}</th>
                                        <th>{'Action'.translate(this.props.lang)}</th>
                                    </tr>
                                    {
                                        this.state.relationError[1].map((item) => {
                                            return (
                                                <tr>
                                                    <td>{item.conditionElement.itemIndex}. {item.conditionElement.name}</td>
                                                    <td>{item.conditionElement.answer}</td>
                                                    <td>{item.element.itemIndex}. {item.element.name}</td>

                                                    <td><button onClick={() => {
                                                        let items = this.state.items;
                                                        items[item.element.itemIndex].conditions.splice(item.element.conditionIndex, 1);
                                                        this.setState({
                                                            items
                                                        }, () => {
                                                            let relations = this.checkRelations(this.state.items[this.state.deleteElementModal].id);
                                                            if (relations && relations.length && (relations[0].length || relations[1].length)) {
                                                                this.setState({
                                                                    relationError: relations
                                                                })

                                                            } else {
                                                                this.setState({
                                                                    relationError: null
                                                                })
                                                            }

                                                        })
                                                    }}><i className="fa fa-unlink danger-color" /></button></td>
                                                </tr>
                                            )
                                        })
                                    }
                                </Table>

                            </div>
                        </ErrorModal>

                        :
                        null
                }

                {
                    this.state.answerRelationError ?

                        <ErrorModal lang={this.props.lang}
                            isOpen={this.state.answerRelationError}
                            toggle={() => this.setState({ answerRelationError: null })}
                            size="xl"

                        >
                            <h4>{'Relations'.translate(this.props.lang)}</h4>
                            <Table striped className="relations-table">
                                <tr>
                                    <th>{'Condition element'.translate(this.props.lang)}</th>
                                    <th>{'Answer'.translate(this.props.lang)}</th>
                                    <th>{'Element'.translate(this.props.lang)}</th>
                                    <th>{'Action'.translate(this.props.lang)}</th>
                                </tr>
                                {
                                    this.state.answerRelationError[1].map((item) => {
                                        return (
                                            <tr>
                                                <td>{item.conditionElement.name}</td>
                                                <td>{item.conditionElement.answer}</td>
                                                <td>{item.element.name}</td>

                                                <td><button onClick={() => {
                                                    let items = this.state.items;
                                                    items[item.element.itemIndex].conditions.splice(item.element.conditionIndex, 1);
                                                    this.setState({
                                                        items
                                                    }, () => {
                                                        let relations = this.checkAnswerRelations(this.state.items[this.state.deleteOptionModal.idx].id, this.state.items[this.state.deleteOptionModal.idx].answers[this.state.deleteOptionModal.aidx].id);
                                                        if (relations && relations.length && (relations[0].length || relations[1].length)) {
                                                            this.setState({
                                                                answerRelationError: relations
                                                            })

                                                        } else {
                                                            this.setState({
                                                                answerRelationError: null
                                                            })
                                                        }

                                                    })
                                                }}><i className="fa fa-unlink danger-color" /></button></td>
                                            </tr>
                                        )
                                    })
                                }
                                {
                                    this.state.answerRelationError[0].map((item) => {
                                        return (
                                            <tr>
                                                <td>{item.conditionElement.name}</td>
                                                <td>{item.conditionElement.answer}</td>
                                                <td>{item.element.name}</td>
                                                <td><button onClick={() => {
                                                    let items = this.state.items;
                                                    items[item.element.itemIndex].conditions.splice(item.element.conditionIndex, 1);
                                                    this.setState({
                                                        items
                                                    }, () => {
                                                        let relations = this.checkAnswerRelations(this.state.items[this.state.deleteOptionModal.idx].id, this.state.items[this.state.deleteOptionModal.idx].answers[this.state.deleteOptionModal.aidx].id);
                                                        if (relations && relations.length && (relations[0].length || relations[1].length)) {
                                                            this.setState({
                                                                answerRelationError: relations
                                                            })

                                                        } else {
                                                            this.setState({
                                                                answerRelationError: null
                                                            })
                                                        }

                                                    })
                                                }}><i className="fa fa-unlink danger-color" /></button></td>
                                            </tr>
                                        )
                                    })
                                }


                            </Table>
                        </ErrorModal>

                        :
                        null
                }




                {
                    this.state.doctorsModal ?
                        <Modal size="lg" isOpen={this.state.doctorsModal} centered toggle={() => this.setState({ doctorsModal: !this.state.doctorsModal })} >
                            <ModalHeader toggle={() => this.setState({ doctorsModal: !this.state.doctorsModal })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ doctorsModal: !this.state.doctorsModal })}><Isvg src={xIcon} /></button>}>{'Choose doctors'.translate(this.props.lang)}</ModalHeader>

                            <ModalBody className="run-modal">
                                <FormGroup className="form-group-checkbox">
                                    {
                                        this.state.doctorsList && this.state.doctorsList.length ?
                                            this.state.doctorsList && this.state.doctorsList.map((item, idx) => {
                                                return (
                                                    <Checkbox checked={item.checked} onChange={(e) => {
                                                        let doc = this.state.doctorsList;
                                                        for (let i = 0; i < doc.length; i++) {
                                                            if (doc[i]._id == item._id) {
                                                                doc[i].checked = !item.checked;
                                                            }
                                                        }
                                                        this.setState({ doctorsList: doc })


                                                    }} label={item.name} />
                                                )
                                            })
                                            :
                                            "The doctor's list is empty".translate(this.props.lang)
                                    }
                                </FormGroup>


                            </ModalBody>
                            <ModalFooter className='buttons-right-blue'>
                                {
                                    this.state.doctorsList && this.state.doctorsList.length ?
                                        <Button color='primary' onClick={() => this.setState({ doctorsModal: false }, () => {
                                            if (this.props[0].match.params.id != 'new') {
                                                this.setState({ openPageWarningModal: 2 })
                                            }
                                        })}>{'Save'.translate(this.props.lang)}</Button>
                                        :
                                        <Button color='primary' onClick={() => this.setState({ doctorsModal: false })}>{"Close".translate(this.props.lang)}</Button>
                                }
                            </ModalFooter>
                        </Modal>
                        :
                        null
                }

                {/*
                    this.state.durationWarning ?
                        <Modal isOpen={this.state.durationWarning} centered toggle={() => this.setState({ durationWarning: !this.state.durationWarning })} >
                            <ModalHeader toggle={() => this.setState({ durationWarning: !this.state.durationWarning })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ durationWarning: !this.state.durationWarning })}><Isvg src={xIcon} /></button>}>{'Warning!'.translate(this.props.lang)}</ModalHeader>

                            <ModalBody className="run-modal">
                                <div style={{ marginBottom: '35px' }}>
                                    <p>{"Value of duration cannot be none.".translate(this.props.lang)}</p>
                                </div>
                                <Button color='primary' onClick={() => this.setState({ durationWarning: false })}>{"Ok".translate(this.props.lang)}</Button>
                            </ModalBody>
                        </Modal>
                        :
                        null
                */}
                {/* {
                    this.state.durationWarning ?

                        <ErrorModal lang={this.props.lang}
                            isOpen={this.state.durationWarning}
                            toggle={() => this.setState({ durationWarning: null })}

                        >
                            {this.state.durationWarning.translate(this.props.lang)}
                        </ErrorModal>

                        :
                        null
                } */}

                {
                    this.state.runModal ?
                        <Modal isOpen={this.state.runModal} centered toggle={() => this.setState({ runModal: !this.state.runModal })} size={'xl'}>
                            <ModalHeader toggle={() => this.setState({ runModal: !this.state.runModal })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ runModal: !this.state.runModal })}><Isvg src={xIcon} /></button>}>{'Referral preview'.translate(this.props.lang)}</ModalHeader>

                            <ModalBody className="run-modal">
                                <CreateReferralModal lang={this.props.lang} sections={this.generateSections()}></CreateReferralModal>
                            </ModalBody>
                        </Modal>
                        :
                        null
                }
                <Modal isOpen={this.state.selectReferralModal} centered size="xl">
                    <ModalHeader toggle={() => this.setState({ selectReferralModal: !this.state.selectReferralModal })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ selectReferralModal: !this.state.selectReferralModal })}><Isvg src={xIcon} /></button>}>{'Import referral'.translate(this.props.lang)}</ModalHeader>

                    <ModalBody>
                        <ImportReferral import={(data) => {
                            this.setState({
                                ...data,
                                selectReferralModal: null
                            })
                        }} {...this.props}></ImportReferral>
                    </ModalBody>

                </Modal>

                {this.state.previewModal ?
                    <Modal isOpen={this.state.previewModal} centered toggle={() => this.setState({ previewModal: !this.state.previewModal })} size="xl">
                        <ModalHeader toggle={() => this.setState({ previewModal: !this.state.previewModal })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ previewModal: !this.state.previewModal })}><Isvg src={xIcon} /></button>}>{'Form preview'.translate(this.props.lang)}</ModalHeader>

                        <ModalBody>
                            {!this.state.intelligentReferral ?
                                <CreatePersonalReferralModal {...this.props} referral={{ name: this.state.name, description: this.state.description }} sections={this.generateSections()}></CreatePersonalReferralModal>

                                :

                                <CreateReferralModal {...this.props} referral={{ name: this.state.name, description: this.state.description }} sections={this.generateSections()}></CreateReferralModal>
                            }
                        </ModalBody>
                    </Modal>
                    :
                    null
                }
                {this.state.openPageWarningModal ?
                    <InfoModal
                        isOpen={this.state.openPageWarningModal}
                        onClose={(item) => {
                            this.setState({
                                openPageWarningModal: item
                            })
                        }}
                        toggle={() => this.setState({ openPageWarningModal: !this.state.openPageWarningModal })}
                        header={'Warning'.translate(this.props.lang)}
                        info={'Make sure the form works as desired!'.translate(this.props.lang)}
                        buttons={[

                            <Button color='primary' onClick={() => {
                                if (this.state.openPageWarningModal == 1) {
                                    this.updateQuestionary(true)
                                } else if (this.state.openPageWarningModal == 2) {
                                    this.updateQuestionary(false)
                                }
                            }}>{'Ok'.translate(this.props.lang)}</Button>


                        ]}
                    />

                    :

                    null}

            </div >
        );
    }
}

const selector = formValueSelector('modalForm');

export default connect(state => {
    return {
        group: selector(state, 'group'),
        type: selector(state, 'type'),
        displayOnReferral: selector(state, 'displayOnReferral'),
        conditionQuestion: selector(state, 'conditionQuestion')

    }

}, {
    changeSubgroup: value => change("modalForm", "subgroup", value),

})(Page(ReferralBuilder));