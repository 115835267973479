import React, { Component } from 'react';
import Page from '../../containers/page';

import {
    Container,
    Row,
    Col,
    Modal, ModalHeader, ModalBody, ModalFooter, Button, FormGroup, Input, Label
} from 'reactstrap';

import FormBuilder from '../../components/forms/modalFormBuilder';
import { required } from '../../components/forms/validation';

import Isvg from 'react-inlinesvg'
import { Link } from 'react-router-dom'

import Search from '../../components/search';

import profile from '../../assets/images/profile-pic.png'
import map from '../../assets/svg/map.svg'
import chart from '../../assets/svg/chart.svg'
import edit from '../../assets/svg/edit.svg'
import garabage from '../../assets/svg/garbage.svg'
import arrowDown from '../../assets/svg/arrow-down.svg'
import arrowUp from '../../assets/svg/arrow-up.svg'
import addIcon from '../../assets/svg/add-icon.svg';

import ListBuilder from '../../components/listBuilder';
import DeleteModal from '../../components/deleteModal';
import { getSearchParams, generateSearchLink, generateSearchLinkMultiple } from '../../helpers/linkHelper';
import { API_ENDPOINT, DOMAINS } from '../../constants';
import ErrorModal from '../../components/errorModal';

import duplicateIcon from '../../assets/svg/duplicate-icon.svg';
import importIcon from '../../assets/svg/import.svg'


import InfoModal from '../../components/infoModal';

import profileUser from '../../assets/images/user.png';
import notification from '../../assets/svg/notification.svg';
import moreIcon from '../../assets/svg/more.svg';

import { Player, Controls } from '@lottiefiles/react-lottie-player';
import SelectTemplateForTransfer from '../../components/selectTemplateForTransfer'


function parseJSON(response) {
    return response.json().then(json => {
        return {
            result: json,
            status: response.status
        }
    })
}

/**
* List of landing page styles
* @author   Aleksandar Dabic
*/
class LandingStyles extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...props.initialData,
            form: false,
            useState: false,
            params: {
                entries: 10,
                page: 0
            },
            items: [],
            total: 0,
            loading: true
        };
    }


    get = () => {
        this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));

        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0 })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

        for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
            this.props.loadDataWithQuery[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0 })).then((data) => {
                let dataToSet = { ...data }
                if (dataToSet.page || dataToSet.items || dataToSet.total) {
                    dataToSet.loading = null;
                    // console.log(dataToSet.page || dataToSet.items || dataToSet.total)
                    let checkFilter = '';
                    if (dataToSet.resultFilter) {
                        checkFilter = String(dataToSet.resultFilter)
                        delete dataToSet.resultFilter;
                    }
                    let search = this.props[0].location.search;
                    let serachParams = getSearchParams(search);
                    let searchParamsFilter = '';
                    if (serachParams.filter) {
                        searchParamsFilter = serachParams.filter;
                    }
                    if (searchParamsFilter != checkFilter) {
                        delete dataToSet.page;
                        delete dataToSet.items;
                        delete dataToSet.total;
                        delete dataToSet.loading;
                    }
                }
                this.setState({
                    ...dataToSet,
                    // loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }
        this.setState({ loadLibrary: true }, () => {
            fetch(API_ENDPOINT + '/data-managment/landing-builder-styles/library/icons', {
                method: 'GET',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300) {
                    if (result && result.gallery) {
                        this.setState({
                            libraryIcons: result.gallery,
                            loadLibrary: false
                        })
                    } else {
                        this.setState({
                            libraryIcons: [],
                            loadLibrary: false
                        })
                    }


                }

            })
        })


    }

    componentDidMount() {
        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }

        this.get();
    }

    componentDidUpdate(prevProps) {
        if (prevProps[0].location.search != this.props[0].location.search) {
            this.setState({
                loading: true
            }, () => {
                this.get();

            })
        }
    }


    updateSort = (field, type) => {
        if (this.state.useState) {
            let state = this.state.params;
            state.sortField = field;
            state.sortType = type;
            this.setState({
                params: state
            })
        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0 }, [{ name: 'sortField', value: field }, { name: 'sortType', value: type }], false));

        }
    }

    updateParams = (name, value, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };
                obj[name] = value;
                this.setState({ params: obj });
            } else {
                let obj = this.state.params;
                obj[name] = value;
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(generateSearchLink(this.props[0].location.search, { entries: 10, page: 0 }, name, value, restart));
        }
    }
    updateMultipleParams = (fields, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj });

            } else {
                let obj = this.state.params;
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0 }, fields, restart));
        }
    }




    stopAnimation = () => {
        this.player.current.play();
    }

    /**
    * Delete landing style with specific id
    * @author   Aleksandar Dabic
    */
    delete = (id) => {
        this.setState({
            loading: true
        }, () => {

            fetch(API_ENDPOINT + '/data-managment/landing-builder-styles/' + id, {
                method: 'DELETE',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
            }).then(parseJSON).then(({ result, status }) => {
                if (result.error) {
                    this.setState({ error: result.error })
                }
                this.get();
            })
        })

    }
    setEdit = (item) => {
        this.props[0].history.push(`/data-managment/landing-builder-styles/` + item._id)
    }

    duplicateModal = () => {
        let obj = this.state.duplicateModal;
        obj.name = obj.name + ' (copy)';

        this.setState({ loadingDuplicate: true }, () => {
            fetch(API_ENDPOINT + '/data-managment/landing-builder-styles/new', {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify(obj)
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300) {
                    this.setState({
                        // message: 'Questionary saved successfully',
                        duplicateModal: null,
                        loadingDuplicate: false
                    }, () => this.get())

                }

            })
        })
    }
    updateLibrary = () => {
        this.setState({ loadLibrary: true }, () => {
            fetch(API_ENDPOINT + '/data-managment/landing-builder-styles/library/icons', {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify({ image: this.state.image })
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300) {
                    this.setState({
                        image: null,
                        loadLibrary: false
                    }, () => this.get())

                }

            })
        })

    }
    deleteIcon = (idx) => {
        this.setState({ loadLibrary: true }, () => {
            let gallery = this.state.libraryIcons;
            let newGallery = [];
            if (gallery && gallery.length) {
                for (let i = 0; i < gallery.length; i++) {
                    if (i != idx) {
                        newGallery.push(gallery[i]);
                    }
                }
            }

            fetch(API_ENDPOINT + '/data-managment/landing-builder-styles/library/icons', {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify({ newGallery: newGallery, deleteItem: true })
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300) {
                    this.setState({ loadLibrary: false })
                    this.get()

                }

            })
        })

    }
    uploadImage = (e) => {
        let input = e.target;
        if (input.files && input.files[0]) {

            let fsize = input.files[0].size;
            let file = Math.round((fsize / 1024))
            this.setState({
                uploadTimestamp: Math.floor(new Date().getTime() / 1000)
            })
            if (file > 10240) {
                this.setState({ error: "File size limit has been reached! (max 10 MB)".translate(this.props.lang) })
            } else {

                this.setState({
                    _imageUploading: this.state.conversation
                })

                let formData = new FormData();
                formData.append('file', input.files[0]);

                fetch(API_ENDPOINT + '/data/upload-image', {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,

                    },
                    body: formData
                }).then((res) => res.text()).then((img) => {
                    this.setState({
                        image: img
                    }, () => this.updateLibrary())


                });
            }
        }
    }
    transferTemplates = (data) => {
        if (this.state.domen && this.state.domen != -1) {
            data.domen = this.state.domen
            console.log(data)

            this.setState({
                loading: true
            }, () => {

                fetch(API_ENDPOINT + '/domen-transfer/landing-builder-styles', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify(data)
                }).then(res => res.json()).then((result) => {
                    if (result) {
                        this.setState({
                            transferModal: false,
                            templates: null,
                            domen: null
                        })
                    }
                    this.get();
                })
            })
        } else {
            this.setState({
                error: 'You have to select domen'.translate(this.props.lang)
            })
        }

    }

    render() {
        let params = {};
        if (this.state.useState) {
            params = this.state.params;
        } else {
            params = getSearchParams(this.props[0].location.search, { entries: 10, page: 0 })
        }
        const defaultOptions = {
            loop: true,
            autoplay: true,
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice'
            }
        };

        return (
            <div className={this.props.autoHideSidebar ? "dashboard dashboard-auto-hide" : "dashboard"}>

                <Container fluid>
                    {this.state.error ?

                        <ErrorModal lang={this.props.lang}
                            isOpen={this.state.error}
                            toggle={() => this.setState({ error: null })}

                        >
                            {this.state.error.translate(this.props.lang)}
                        </ErrorModal>

                        : null}

                    <Row>
                        <Col xl="12">
                            <div className="panel">
                                <div className="panel-header landing-builder-styles-header-container">
                                    <h5 className="component-header">{this.props.linksMeta && this.props.linksMeta[this.props[0].location.pathname] ? this.props.linksMeta[this.props[0].location.pathname][this.props.lang] : ''}</h5>
                                    <div className="landing-builder-styles-header">
                                        <button className="btn add-button" style={{ marginRight: 15 }} onClick={() => {
                                            this.setState({
                                                transferModal: true,
                                            })
                                        }}><Isvg src={importIcon} /> {'Transfer template'.translate(this.props.lang)}</button>
                                        <div style={{ marginRight: 10 }}>
                                            <button className="btn add-button" onClick={() => {
                                                this.setState({ iconLibrary: true })
                                            }}> {'Icon library'.translate(this.props.lang)}</button>
                                        </div>
                                        <Link to="/data-managment/landing-builder-styles/new">
                                            <button className="btn add-button" ><Isvg src={addIcon} /> {'Add'.translate(this.props.lang)}</button>
                                        </Link>
                                    </div>

                                </div>
                                <ListBuilder
                                    lang={this.props.lang}
                                    loading={this.state.loading}
                                    total={this.state.total}
                                    showNumeration={false}
                                    fields={[{ type: 'text', name: 'name', label: 'Name'.translate(this.props.lang), allowSort: true }]}
                                    items={this.state.items}
                                    actions={
                                        [

                                            {
                                                component: <Isvg src={duplicateIcon} className="duplicate-icon" style={{ marginRight: 25 }} />,
                                                onClick: (item) => this.setState({ duplicateModal: item })
                                            },
                                            {
                                                component: <Isvg src={edit} className="edit-icon" />,
                                                onClick: (item) => this.setEdit(item)
                                            },
                                            {
                                                component: <Isvg src={garabage} className="delete-icon" />,
                                                onClick: (item) => this.setState({ deleteModal: item })
                                            },
                                        ]
                                    }
                                    params={params}
                                    sortField={params.sortField}
                                    sortType={params.sortType}
                                    updateSort={this.updateSort}
                                    updateParams={this.updateParams}
                                    updateMultipleParams={this.updateMultipleParams}
                                ></ListBuilder>
                                <DeleteModal
                                    lang={this.props.lang}
                                    isOpen={this.state.deleteModal}
                                    toggle={() => this.setState({ deleteModal: null })}
                                    handler={() => { this.delete(this.state.deleteModal._id); this.setState({ deleteModal: null }) }}
                                >
                                    {'Delete template'.translate(this.props.lang)} <strong>{this.state.deleteModal && this.state.deleteModal.name}</strong>?
                                </DeleteModal>
                                {this.state.duplicateModal ?
                                    <InfoModal
                                        isOpen={this.state.duplicateModal}
                                        toggle={() => this.setState({ duplicateModal: !this.state.duplicateModal })}
                                        header={'Duplicate form'.translate(this.props.lang)}
                                        info={`Do you want to duplicate the landing style`.translate(this.props.lang) + ' (' + this.state.duplicateModal.name + ')?'}
                                        buttons={[
                                            <Button color="primary" style={{ margin: 10 }} disabled={this.state.loadingDuplicate} onClick={() => this.duplicateModal()}>{'Yes'.translate(this.props.lang)}</Button>,
                                            <Button color="primary" style={{ margin: 10 }} onClick={() => this.setState({ duplicateModal: false })}>{'No'.translate(this.props.lang)}</Button>

                                        ]}

                                    />
                                    :
                                    null
                                }


                            </div>
                        </Col>
                    </Row>
                    <input ref={(node) => this.imageInput = node} type="file" accept="image/png, image/jpeg, image/jpg, image/gif, image/svg" key={this.state.uploadTimestamp} onChange={this.uploadImage} style={{ pointerEvents: 'none', opacity: 0 }} />

                    {
                        this.state.iconLibrary ?
                            <Modal isOpen={this.state.iconLibrary} size='xl' centered className="icon-library-wrap">
                                <ModalHeader>
                                    {'Icon library'.translate(this.props.lang)}
                                    <div style={{ cursor: 'pointer' }} onClick={() => {
                                        this.setState({ iconLibrary: false })
                                    }}> &times;</div>
                                </ModalHeader>

                                <ModalBody>
                                    {
                                        this.state.libraryIcons && this.state.libraryIcons.length ?
                                            <div className="icon-items">
                                                {
                                                    this.state.libraryIcons && this.state.libraryIcons.map((icon, idx) => {
                                                        return (
                                                            <div className="icon-item">
                                                                <img src={API_ENDPOINT + icon} />
                                                                <div className="remove-image" onClick={() => this.deleteIcon(idx)}>
                                                                    <Isvg src={garabage} />
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }

                                            </div>
                                            :
                                            <div className="empty-array">
                                                <h6>{'No icons uploaded'.translate(this.props.lang)}</h6>
                                            </div>

                                    }
                                </ModalBody>

                                <ModalFooter >
                                    <Button onClick={() => {
                                        if (this.imageInput) this.imageInput.click()
                                    }}><Isvg src={addIcon} /> {'Add'.translate(this.props.lang)}</Button>
                                </ModalFooter>

                            </Modal>
                            :
                            null
                    }
                    {
                        this.state.transferModal ?
                            <Modal isOpen={this.state.transferModal} centered size="xl">
                                <ModalHeader toggle={() => this.setState({ transferModal: !this.state.transferModal, questionaries: null, domen: null })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ transferModal: !this.state.transferModal, questionaries: null, domen: null })}>&times;</button>}>{'Select templates for transfer'.translate(this.props.lang)}</ModalHeader>

                                <ModalBody>
                                    <FormGroup>
                                        <Label>{'Select domen'.translate(this.props.lang)}</Label>
                                        <Input type='select'
                                            onChange={e => {

                                                this.setState({ domen: e.target.value == -1 ? null : e.target.value, }, () => { })
                                            }}
                                            value={this.state.domen}
                                        >
                                            <option value={-1}>
                                                {'None'.translate(this.props.lang)}
                                            </option>

                                            {
                                                DOMAINS?.filter(item => item.country === this.props.country)?.map((item, idx) => {
                                                    if (window.location.origin !== item.domen)
                                                        return (
                                                            <option value={item.api}>
                                                                {item.name}
                                                            </option>
                                                        )
                                                })
                                            }
                                            {/* {
                                                this.props.country === 'denmark' ?
                                                    <>
                                                        {window.location.origin != 'https://test.curoflow.dk' ?
                                                            <option value={'https://testapi.curoflow.dk'}>
                                                                {'TEST'.translate(this.props.lang)}
                                                            </option>
                                                            : null}
                                                        {window.location.origin != 'https://tryme.curoflow.dk' ?
                                                            <option value={'https://tryme-api.curoflow.dk'}>
                                                                {'TRY ME'.translate(this.props.lang)}
                                                            </option>
                                                            : null}
                                                        {window.location.origin != 'https://dashboard.curoflow.dk' ?
                                                            <option value={'https://dashboardproxy.curoflow.dk'}>
                                                                {'DASHBOARD'.translate(this.props.lang)}
                                                            </option>
                                                            : null}
                                                    </>
                                                    :
                                                    <>
                                                        {window.location.origin != 'https://dev.curoflow.se' ?
                                                            <option value={'https://devapi.curoflow.se'}>
                                                                {'DEV'.translate(this.props.lang)}
                                                            </option>
                                                            : null}
                                                        {window.location.origin != 'https://tryme.curoflow.se' ?
                                                            <option value={'https://tryme-api.curoflow.se'}>
                                                                {'TRY ME'.translate(this.props.lang)}
                                                            </option>
                                                            : null}
                                                        {window.location.origin != 'https://test.curoflow.se' ?
                                                            <option value={'https://testapi.curoflow.se'}>
                                                                {'TEST'.translate(this.props.lang)}
                                                            </option>
                                                            : null}
                                                        {window.location.origin != 'https://dashboard.curoflow.se' ?
                                                            <option value={'https://dashboardapi.curoflow.se'}>
                                                                {'DASHBOARD'.translate(this.props.lang)}
                                                            </option>
                                                            : null}
                                                        {window.location.origin != 'https://sandbox.curoflow.se' ?
                                                            <option value={'https://sandboxapi.curoflow.se'}>
                                                                {'SANDBOX'.translate(this.props.lang)}
                                                            </option>
                                                            : null}
                                                    </>
                                            } */}
                                        </Input>
                                    </FormGroup>

                                    <SelectTemplateForTransfer {...this.props}
                                        value={this.state.templates}
                                        onChange={(val) => {
                                            if (val) {
                                                let templates = this.state.templates;
                                                templates = val;
                                                this.setState({ templates });
                                            }
                                        }}
                                    ></SelectTemplateForTransfer>

                                </ModalBody>
                                <ModalFooter>
                                    <Button onClick={() => {
                                        this.transferTemplates(this.state.templates)
                                    }}>{'Transfer template'.translate(this.props.lang)}</Button>
                                </ModalFooter>

                            </Modal>
                            :
                            null
                    }

                </Container>


            </div>
        );
    }
}

export default Page(LandingStyles);