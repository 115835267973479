
import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import {
    FormGroup, Label, Form, FormFeedback
} from 'reactstrap';

import Isvg from 'react-inlinesvg';
import image from '../../../assets/svg/picture.svg';
import Attach from '../../../assets/svg/attach.svg';
import File from '../../../assets/svg/fajl.svg';
import { API_ENDPOINT } from '../../../constants';
import { Player, Controls } from "@lottiefiles/react-lottie-player";
import axios from 'axios';


function parseText(response) {
    return response.text().then(text => {
        return {
            result: text,
            status: response.status
        }
    })
}


class UploadJSON extends Component {
    constructor(props) {
        super(props);
        this.selectFile = this.selectFile.bind(this);

        this.state = {

        };
    }
    handleUploadFileProgress = (data, progress) => {

        this.setState({
            uploadingFile: data,
            uploadingFileProgress: progress
        })
    }

    selectFile(e) {
        this.setState({
            error: null
        })
        let input = e.target;
        console.log('input', input)
        if (input.files && input.files[0]) {

            let fsize = input.files[0].size;
            let file = Math.round((fsize / 1024))


            if (file > 5120) {
                this.setState({ error: "File size limit has been reached! (max 5 MB)".translate(this.props.lang) })
            } else {
                this.setState({
                    _loading: true
                })

                let formData = new FormData();
                formData.append('file', input.files[0]);



                this.setState({ origValue: this.props.value })
                this.props.onChange('loading')

                this.handleUploadFileProgress('Processing file...'.translate(this.props.lang));

                console.log('formData', formData)

                axios.post(API_ENDPOINT + '/data/modules/upload-document', formData, {
                    headers: {
                        Accept: 'application/json',
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    },
                    onUploadProgress: (progressEvent) => {
                        this.handleUploadFileProgress('Uploading file...'.translate(this.props.lang), Math.round((progressEvent.loaded * 100) / progressEvent.total));

                    }
                }).then((response) => {
                    if (response.status != 200) {
                        this.setState({ error: response.data.translate(this.props.lang) })
                    } else {
                        let obj = { fileName: input.files[0].name, file: response.data };
                        this.props.onChange(obj);
                        this.setState({
                            _loading: null
                        })
                    }
                })
            }
        }
    }



    render() {
        return (

            <FormGroup className="image-picker-wrap">
                <Label size="sm">{this.props.label}</Label>

                <div className="image-picker" style={{ height: 240 }}>
                    {!this.props.value ? <input type="file" accept=".json" title={"No file chosen".translate(this.props.lang)} onChange={this.selectFile} key={this.props.value && typeof this.props.value == 'object' ? this.props.value.file : this.props.value} /> : null}
                    {this.props.value ?
                        <img src={File} />
                        :
                        null
                    }
                    {this.state._loading ?
                        <div className="file-progress-loader">
                            <div>
                                <Player
                                    onEvent={(event) => {
                                        if (event === "load" && this.player && this.player.current) this.player.current.play();
                                    }}
                                    ref={this.player} // set the ref to your class instance
                                    autoplay={true}
                                    loop={true}
                                    controls={true}
                                    src="/lf30_editor_l5cxzdyf.json"
                                    renderer={'svg'}
                                    style={{ height: "128px", width: "128px", }}
                                ></Player>
                                <h6>{this.state.uploadingFileProgress ? ('Uploading file...'.translate(this.props.lang) + this.state.uploadingFileProgress + '%') : 'Processing file...'.translate(this.props.lang)}</h6>
                            </div>
                        </div>
                        : null
                    }

                    {
                        this.props.value ?
                            <button className="delete" onClick={() => this.props.onChange(null)}>&times;</button>
                            :
                            null
                    }


                    <div className="bottom-content">
                        <Isvg src={Attach} />
                        <p> <span>{'Upload a json file'.translate(this.props.lang)}</span> {'or drag and drop'.translate(this.props.lang)}</p>
                    </div>

                </div>

                <div>{this.state.error}</div>
                <div style={{ color: 'red' }}>{this.props.error}</div>
                <div> {this.props.value && typeof this.props.value == 'object' ? this.props.value.fileName : ''} </div>

            </FormGroup >



        );
    }
}

export default UploadJSON;