import React, { Component } from 'react';
import Link from '../components/link';

import Isvg from 'react-inlinesvg';
import Page from '../containers/page';

import { API_ENDPOINT } from '../constants';

/**
* BankId login component
* @author   Milan Stanojevic
*/
class BankId extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    componentDidMount() {
        let id;
        if (this.props[0].location.search) {
            let search = this.props[0].location.search;
            if (search) {
                if (search.indexOf('?relaystate=') != -1) {
                    let splitSearch = search.split('?relaystate=')
                    
                    if (splitSearch && splitSearch.length && splitSearch[1]) {
                        if (splitSearch[1].indexOf('&service=eIDBasic') != -1) {
                            let relaystateSplit = splitSearch[1].split('&service=eIDBasic')
                            if (relaystateSplit && relaystateSplit.length) {
                                id = relaystateSplit[0]
                            }
                        }
                    }
                }
            }

        }
        if (window.top) {
            window.top.postMessage(`signed=${id}`, '*')
        }
    }

    render() {
        return (
            <div>            </div>
        );
    }
}

export default BankId;