import React from 'react';

import Isvg from 'react-inlinesvg';
import moveIcon from '../../assets/svg/move.svg';
import garabage from '../../assets/svg/garbage.svg'


/**
* AppBuilder
* @author   Milan Stanojevic
*/
class Header extends React.Component {

    constructor(props) {
        super(props);
        this.state = {

        }
    }



    render() {
        return (
            <div className={`app-builder-component-header`}>
                <button className={this.props.handleClassName}><Isvg src={moveIcon}/></button>
                <button onClick={() => this.props.removeElement()}><Isvg src={garabage}/></button>

                
            </div>
        )
    }
}

export default Header;