import React, { Component } from 'react';
import Page from '../containers/page';

import { Link } from 'react-router-dom';

import {
    Container,
    Row,
    Col,
    Button,
    FormGroup,
    Label,
    Input,
    Modal, ModalHeader, ModalBody, ModalFooter, Form
} from 'reactstrap';
import Checkbox from './forms/fields/checkbox';


import { API_ENDPOINT } from '../constants';
import HTML from './forms/fields/html';

import FormBuilder from './forms/modalFormBuilder';
import { formValueSelector, change } from 'redux-form';  // ES6
import { connect } from 'react-redux';

import { required, checkSocialSecurityNumber, dateValidate } from './forms/validation';
import SocialSecurityNumber, { isSocialSecurityNumberValid } from './forms/fields/socialSecurityNumber';

import ErrorModal from './errorModal';



function parseJSON(response) {
    return response.json().then(json => {
        return {
            result: json,
            status: response.status
        }
    })
}



class AddSecondParentModal extends Component {
    constructor(props) {
        super(props);
        this.searchResultsWraps = {};

        this.state = {
            ...props.initialData,

        };
    }

    componentDidMount() {
        console.log(this.props.data)

    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.SSN != this.props.SSN && this.props.SSN && ((this.props.country === 'denmark' && this.props.SSN.length == 10) || this.props.SSN.length == 12)) {
            this.checkParentUser();
        }
        if (prevProps.SSN !== this.props.SSN) {

            if ((this.props.country === 'denmark' && this.props.SSN?.length === 10) || this.props.SSN?.length === 12) {
                let dateOfBirth = this.props.getDateOfBirthFromSSN(this.props.SSN);
                if (dateOfBirth && this.props.changeDateOfBirth) {
                    this.props.changeDateOfBirth(dateOfBirth)
                    if (!this.state.disabledDateOfBirth) { this.setState({ disabledDateOfBirth: true }) }
                } else {
                    if (this.state.disabledDateOfBirth) { this.setState({ disabledDateOfBirth: false }) }
                }
            } else {
                if (this.state.disabledDateOfBirth) { this.setState({ disabledDateOfBirth: false }) }
            }
        }
    }

    checkParentUser = () => {
        fetch(API_ENDPOINT + '/users/subaccounts/get', {
            method: 'POST',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
            body: JSON.stringify({ socialSecurityNumber: this.props.SSN })
        }).then(parseJSON).then(({ result, status }) => {
            if (status == 200 && result && result.userData) {
                this.props.changeName(result.name);
                this.props.changeEmail(result.email);
                if (result.userData.sex)
                    this.props.changeSex(result.userData.sex);
                this.props.changeLanguage(result.language);
                this.props.changePhone(result.phone);

            } else {
                this.props.changeName('');
                this.props.changeEmail('');
                this.props.changeSex('');
                this.props.changeLanguage('');
                this.props.changePhone('');
            }
        })

    }
    saveParent = (data) => {
        if (this.props.data?._id) {
            data.patientId = this.props.data._id;
            data.group = this.props.selectedGroup;

            fetch(API_ENDPOINT + '/users/patient/parent/insert', {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify(data)
            }).then(parseJSON).then(({ result, status }) => {
                if (result?.error) {
                    this.setState({ error: result.error })
                } else {
                    this.props.close?.(true)
                }
            })
        }

    }

    render() {
        return (
            <>
                {
                    this.state.error ?

                        <ErrorModal lang={this.props.lang}
                            isOpen={this.state.error}
                            toggle={() => this.setState({ error: null })}

                        >
                            {this.state.error.translate(this.props.lang)}
                        </ErrorModal>

                        :
                        null
                }
                <FormBuilder
                    selectedGroup={this.props.selectedGroup}
                    selectedClinic={this.props.selectedClinic}
                    country={this.props.country}
                    countryTwoLetter={this.props.countryTwoLetter}
                    dateFormat={this.props.dateFormat}
                    getStringDateTs={this.props.getStringDateTs}
                    onSubmit={(data) => {
                        console.log('data', data)
                        this.saveParent(data)
                        // if (!this.state.ssnExistInThisGroup) {
                        //     this.checkPatientValidations(data)
                        // } else {
                        //     this.setState({ addSecondGuardian: null, ssnExistInThisGroup: null, ssnExists: null })
                        // }

                    }}
                    initialValues={{}}
                    size="lg"
                    isOpen={true}
                    toggle={() => this.props.close?.()}
                    title={'Register second parent or legal guardian'.translate(this.props.lang)}
                    _saving={this.state._saving}
                    buttonConfirmText={'Saved'.translate(this.props.lang)}
                    buttonText={'Save'.translate(this.props.lang)}

                    lang={this.props.lang}
                    fields={[
                        {
                            type: 'row',
                            children: [


                                {
                                    type: 'col',
                                    width: {
                                        lg: 8,
                                        sm: 12,
                                        xs: 12
                                    },
                                    children: [
                                        {
                                            type: 'socialSecurityNumber',
                                            name: 'socialSecurityNumber',
                                            hideFindChildren: true,
                                            disableNewPatientBtn: true,
                                            searchByNameAndEmailFormBuilder: this.state.ssnExists && !this.state.ssnExistInThisGroup ? false : true,
                                            label: this.props.country === 'uk' ? "Date of birth".translate(this.props.lang) : 'Social security number'.translate(this.props.lang),
                                            disabled: this.state.ssnExists && !this.state.ssnExistInThisGroup ? true : false,
                                            validate: [required('Social security number is required!'.translate(this.props.lang)), checkSocialSecurityNumber('Social security number is not valid!'.translate(this.props.lang), this.props.country)]
                                        },
                                    ]
                                },
                                // {
                                //     type: 'col',
                                //     width: {
                                //         lg: 4,
                                //         sm: 12,
                                //         xs: 12
                                //     },
                                //     children: [
                                //         {
                                //             type: 'date-of-birth',
                                //             dateFormat: this.props.dateFormat,
                                //             name: 'dateOfBirth.date',
                                //             disabled: this.state.disabledDateOfBirth,
                                //             label: 'Date of birth'.translate(this.props.lang),
                                //             validate: [required('Date of birth is required!'.translate(this.props.lang)), dateValidate('Invalid date!'.translate(this.props.lang))]

                                //         },
                                //     ]
                                // },
                                {
                                    type: 'col',
                                    width: {
                                        lg: 4,
                                        sm: 12,
                                        xs: 12
                                    },
                                    children: [
                                        {
                                            type: 'select',
                                            name: 'sex',
                                            label: 'Sex'.translate(this.props.lang),
                                            disabled: this.state.ssnExists && !this.state.ssnExistInThisGroup ? true : false,
                                            values: [{ value: 0, name: 'Select'.translate(this.props.lang) }, { value: 'male', name: 'Male'.translate(this.props.lang) }, { value: 'female', name: 'Female'.translate(this.props.lang) }],
                                        },
                                    ]
                                },
                                {
                                    type: 'col',
                                    width: {
                                        lg: this.props.showDateOfBirth ? this.props.secondaryLanguageDisabled ? 6 : 4 : this.props.secondaryLanguageDisabled ? 12 : 8,
                                        sm: 12,
                                        xs: 12
                                    },
                                    children: [
                                        {
                                            type: 'text',
                                            name: 'name',
                                            label: 'First and last name'.translate(this.props.lang),
                                            validate: [required('Name is required!'.translate(this.props.lang))],
                                            disabled: this.state.ssnExists && !this.state.ssnExistInThisGroup ? true : false,

                                        },
                                    ]
                                },
                                {
                                    type: 'col',
                                    width: {
                                        lg: 4,
                                        sm: 12,
                                        xs: 12
                                    },
                                    children: [
                                        {
                                            type: 'select',
                                            name: 'language',
                                            label: 'Language'.translate(this.props.lang),
                                            disabled: this.state.ssnExists && !this.state.ssnExistInThisGroup ? true : false,
                                            values: this.props?.languages?.map(item => { return { name: item.name?.translate(this.props.lang), value: item._id } })
                                        },
                                    ]
                                },

                                {
                                    type: 'col',
                                    width: {
                                        lg: 8,
                                        sm: 12,
                                        xs: 12
                                    },
                                    children: [
                                        {
                                            type: 'text',
                                            name: 'email',
                                            label: 'Email address'.translate(this.props.lang),
                                            disabled: this.state.ssnExists && !this.state.ssnExistInThisGroup ? true : false,
                                            validate: []
                                        },
                                    ]
                                },
                                {
                                    type: 'col',
                                    width: {
                                        lg: 6,
                                        sm: 12,
                                        xs: 12
                                    },
                                    children: [
                                        {
                                            type: 'phone',

                                            name: 'phone',
                                            label: 'Phone'.translate(this.props.lang),
                                            disabled: this.state.ssnExists && !this.state.ssnExistInThisGroup ? true : false,
                                            validate: []
                                        },
                                    ]
                                },

                                !this.state.ssnExistInThisGroup ?
                                    {
                                        type: 'col',
                                        width: {
                                            lg: 12,
                                            sm: 12,
                                            xs: 12
                                        },
                                        children: [
                                            {
                                                type: 'checkbox',
                                                validate: [required('Consent is required!'.translate(this.props.lang))],
                                                name: 'consentAccepted',
                                                label: 'Consent has been obtained'.translate(this.props.lang),

                                            },
                                        ]
                                    } : { type: 'empty' },




                            ]
                        }
                    ]}
                ></FormBuilder>
            </>

        );
    }
}



const selector = formValueSelector('modalForm');

export default connect(state => {
    return {
        SSN: selector(state, 'socialSecurityNumber'),
        dateOfBirth: selector(state, 'dateOfBirth.date'),
        sex: selector(state, 'sex'),
        name: selector(state, 'name'),
        language: selector(state, 'language'),
        email: selector(state, 'email'),
        phone: selector(state, 'phone')

    }


}, {
    changeSSN: value => change('modalForm', 'socialSecurityNumber', value),
    changeDateOfBirth: value => change('modalForm', 'dateOfBirth.date', value),
    changeSex: value => change('modalForm', 'sex', value),
    changeName: value => change('modalForm', 'name', value),
    changeLanguage: value => change('modalForm', 'language', value),
    changeEmail: value => change('modalForm', 'email', value),
    changePhone: value => change('modalForm', 'phone', value),



})(AddSecondParentModal);