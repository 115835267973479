import React, { Component, Fragment } from 'react';
import PageWithLayout from '../../containers/page';
import { Alert, Button, Col, Container, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import Switch from '../../components/forms/fields/switch'


import ErrorModal from '../../components/errorModal';
import { API_ENDPOINT } from '../../constants';
import InfoModal from '../../components/infoModal';
import UserIsNotVerifiedTooltip from '../../components/UserIsNotVerifiedTooltip';
import { defaultError, isStringAValidNumber } from '../../components/TwoFactorAuthModal';
import Checkbox from '../../components/forms/fields/checkbox';
import { Player } from '@lottiefiles/react-lottie-player';
import InfoWithTooltip from '../../components/InfoWithTooltip';
import InlineSVG from 'react-inlinesvg';

import trashIcon from '../../assets/svg/garbage.svg';
import axios from 'axios';
import ConsultationList from '../../components/e-connect/ConsultationList';
import ReferralContent from '../../components/referralContent';
import FormPreview from '../forms/previewAnsweredForm';
import Isvg from 'react-inlinesvg'

import xIcon from '../../assets/svg/x.svg';
import DatePicker from '../../components/forms/fields/datePicker';
import TimePickerV2 from '../../components/forms/fields/timepicker_v2';


const SCHEDULED_STATUSES = ['scheduled', 'visit-rescheduled', 'revisit-scheduled']

function parseJSON(response) {
    return response.json().then(json => {
        return {
            result: json,
            status: response.status
        }
    })
}


const allowedFileTypes = ['image/jpeg', 'image/png', 'image/webp', 'text/plain', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/xml', 'application/rtf', 'application/vnd.ms-powerpoint', 'application/vnd.openxmlformats-officedocument.presentationml.presentation', 'video/mp4', 'application/json', 'text/csv', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/pdf'];

export class EconnectConsultationPage extends Component {
    constructor(props) {
        super(props);

        const adminPermissionId = props?.uData?.permissions?.admin;

        let view = 'incoming';
        const highlightedCases = [];
        // console.log('props0', props?.[0]);
        if (props?.[0]?.location?.state?._chatRedirect) {
            const { consultationId, tab, status } = props?.[0]?.location?.state;
            if (status === 'closed') view = 'archived';
            else {
                if (tab === 'sender') view = 'outgoing';
                else if (tab === 'receiver') view = 'incoming';
            }

            if (consultationId) {
                highlightedCases.push(consultationId);
            }
        } else if (props?.[0]?.location?.state?.messageModal) {
            if (props?.[0]?.location?.state?.messageModal?.includes?.('___')) {
                const [id, tab] = props?.[0]?.location?.state?.messageModal?.split('___');
                if (id) {
                    highlightedCases.push(id);
                };
                if (tab === 'sender') view = 'outgoing';
                else if (tab === 'receiver') view = 'incoming';
            }
        }
        this.state = {
            num: 0,
            view,
            allConsultations: adminPermissionId && props?.uData?.groupPermissionsUser?.[props?.selectedGroup]?.[props?.selectedClinic]?.includes?.(adminPermissionId) ? true : false,
            clinicGroupSettings: null,
            error: null,
            conversationId: null,
            clinicDoctors: [],
            highlightedCases,
        }
    }

    componentDidMount() {
        this.getClinicGroupSettings();
        this.getClinicDoctors()

        if (this.props.checkConsultationIndicators) {
            this.props.checkConsultationIndicators()
        }

    }

    componentDidUpdate(prevProps, prevState) {
        if ((!prevProps?.selectedGroup && this.props.selectedGroup) || (prevProps?.selectedGroup !== this.props.selectedGroup)) {
            this.getClinicGroupSettings();
            this.getClinicDoctors()
        }
        if (prevState.preview !== this.state.preview) {
            this.checkConsultationChat()
        }
    }
    getClinicDoctors = () => {
        this.setState({ clinicDoctors: [] }, async () => {
            if (this.props.selectedClinic) {
                const response = await fetch(API_ENDPOINT + '/consultation/colleagues/' + this.props.selectedClinic, {
                    method: 'GET',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    }
                });
                if (!response || !response.ok) return;
                const result = await response.json();
                if (result.doctors) {
                    this.setState({ clinicDoctors: result.doctors })
                }
            }

        })
    }
    checkConsultationChat = () => {
        this.setState({ conversationId: null }, () => {
            if (this.state.preview?.conversationData?._id && this.state.preview?.conversationData?.members?.indexOf(this.props.uData._id) !== -1) {
                this.setState({ conversationId: this.state.preview.conversationData._id })
            }
        })
    }


    componentWillUnmount() {
    }


    refresh = () => {
        this.setState(prev => ({ ...prev, num: +prev?.num + 1 }));
    }

    getClinicGroupSettings = () => {
        this.setState({ clinicGroupSettings: null }, async () => {
            if (!this.props?.selectedGroup) return;
            const response = await fetch(API_ENDPOINT + '/data/clinic-group-settings/' + this.props.selectedGroup);
            if (!response || !response.ok) return;
            const result = await response.json();
            if (!result || result.error || !result.clinicGroupSettings) return;
            this.setState({ clinicGroupSettings: result.clinicGroupSettings });
        })
    }
    generateSections = (itemsArr) => {
        let sections = {};
        let items = [{ type: 0 }, ...itemsArr];
        let lastSectionId = -1;
        for (let i = 0; i < items.length; i++) {
            if (items[i].type == 0) {
                lastSectionId++;
                sections[lastSectionId] = {
                    name: items[i].name,
                    displayOnReferral: false,
                    questions: [],
                    conditions: items[i].conditions ? items[i].conditions : [],
                    displayOnReferral: items[i].displayOnReferral,
                    fontStyle: items[i].fontStyle,
                    fontSize: items[i].fontSize,
                    fontWeight: items[i].fontWeight,

                }
            }
            if (lastSectionId != -1 && items[i].type != 0) {


                let wikiLinks = [];

                items[i].wikiLinks = wikiLinks;


                if (items[i].type == 1) {
                    let displayOnReferral = false;
                    if (items[i].answers) {
                        for (let j = 0; j < items[i].answers.length; j++) {
                            if (items[i].answers[j].text) {
                                displayOnReferral = true;
                            }
                        }
                    }

                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        answers: items[i].answers,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        displayOnReferral: displayOnReferral,
                        wikiLinks: items[i].wikiLinks,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                    });

                } else if (items[i].type == 2) {
                    let displayOnReferral = false;
                    if (items[i].answers) {
                        for (let j = 0; j < items[i].answers.length; j++) {

                            if (items[i].answers[j].text) {
                                displayOnReferral = true;
                            }
                        }
                    }
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        answers: items[i].answers,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        answerPrefix: items[i].answerPrefix,
                        userInput: items[i].userInput,
                        displayOnReferral: displayOnReferral || items[i].answerPrefix ? true : false,
                        wikiLinks: items[i].wikiLinks,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,

                    })
                } else if (items[i].type == 3) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        answerPrefix: items[i].answerPrefix,
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                    })

                } else if (items[i].type == 4) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        text: items[i].text,
                        userInput: items[i].userInput,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                    })
                } else if (items[i].type == 15) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        text: items[i].text,
                        userInput: items[i].userInput,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                    })

                } else if (items[i].type == 5) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                        score: items[i].score
                    })

                } else if (items[i].type == 6) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                        score: items[i].score
                    })

                } else if (items[i].type == 7) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                    })

                } else if (items[i].type == 8) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                    })

                } else if (items[i].type == 9) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                    })

                } else if (items[i].type == 10) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        userInput: items[i].userInput,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                    })
                } else if (items[i].type == 12) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                        score: items[i].score
                    })
                } else if (items[i].type == 14) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                        score: items[i].score
                    })

                } else if (items[i].type == 13) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                        score: items[i].score
                    })

                } else if (items[i].type == 16) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                        blocks: items[i].blocks,
                    })

                } else if (items[i].type == 17) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                        blocks: items[i].blocks,

                    })

                }
            }
        }

        return Object.values(sections);
    }


    print = (id) => {
        console.log('print')
    }

    openCasePatient = async (patientId) => {

        const response = await fetch(API_ENDPOINT + '/consultation/patient/' + patientId, {
            method: 'GET',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            }
        });
        if (!response || !response.ok) return;
        const result = await response.json();
        if (result?._id) {
            this.setState({
                casePatientModal: { ...result }
            })
        }

    }

    searchPatientWithRedirect = (SSN, enterprise) => {
        let obj = {
            socialSecurityNumber: SSN,
            group: this.props.selectedGroup,
            clinic: this.props.selectedClinic,
        }
        enterprise && (obj.openOnWebDoc = true);

        fetch(API_ENDPOINT + '/users/find/patient', {
            method: 'POST',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
            body: JSON.stringify(obj)
        }).then(res => res.json()).then((result) => {

            if (result && result._id) {
                const path = enterprise ? '/patients/search-enterprise' : '/patients/search';
                this.props.registerClinicGroupLogs?.({
                    type: 'patientProfile',
                    patient: SSN,
                });

                let state = {
                    TYPE: 'redirect',
                    redirectPatient: result,
                    redirectTabIndex: 1
                }
                enterprise && (state.redirectTabActive = 1);

                this.props?.[0]?.history?.push?.(path, state);

            } else {
                this.setState({ error: 'User not found in this clinic group' })
            }
        })
    }
    openCaseDetails = async (caseId, _2fa) => {
        const response = await fetch(API_ENDPOINT + '/consultation/case/' + caseId, {
            method: 'GET',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            }
        });
        if (!response || !response.ok) return;
        const result = await response.json();
        if (result?._id) {
            this.setState({
                casePreviewModal: { ...result, _2fa: _2fa ? true : false }
            })
        }
    }
    pickUpConsultation = async () => {
        let consultation = this.state.preview;
        if (consultation?._id) {
            const response = await fetch(API_ENDPOINT + '/consultation/pickup/' + consultation?._id, {
                method: 'GET',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                }
            });
            if (!response || !response.ok) return;
            const result = await response.json();
            if (result.addedReceiverDoctor) {
                this.refresh()
            }
        }

        this.setState({ pickUpConsultationMessage: null })
    }
    assignToColleague = async () => {

        let consultation = this.state.preview;
        if (consultation?._id && this.state.selectedColleague) {
            const response = await fetch(API_ENDPOINT + `/consultation/assign/${consultation?._id}/${this.state.selectedColleague}`, {
                method: 'GET',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                }
            });
            if (!response || !response.ok) return;
            const result = await response.json();
            if (result.addedReceiverDoctor) {
                this.refresh()
            }
        }

        this.setState({ assignToColleagueConsultationMessage: null, assignToColleagueModal: null })
    }
    unassignDoctor = async () => {
        let consultation = this.state.preview;
        if (consultation?._id && consultation?.receiver?.doctorId) {
            const response = await fetch(API_ENDPOINT + `/consultation/unassign/${consultation?._id}`, {
                method: 'GET',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                }
            });
            if (!response || !response.ok) return;
            const result = await response.json();
            if (result.unassignedDoctor) {
                this.refresh()
            }
        }

        this.setState({ unassignModal: null })
    }
    transferCase = async (type, changedTime) => {
        let consultation = this.state.preview;
        let data = {
            type: type,
            ...changedTime
        }
        if (consultation?._id) {
            const response = await fetch(API_ENDPOINT + `/consultation/transfer-case/${consultation?._id}`, {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify(data)
            });
            if (!response || !response.ok) return;
            const result = await response.json();
            if (result.transferDone) {
                this.refresh()
            }
            if (result.error) {
                this.setState({ error: result.error || defaultError });
            } else {
                if (this.state.changeBookedTimeModal) {
                    this.setState({ changeBookedTimeModal: null })
                }
            };
        }

        this.setState({ acceptTransferMessage: false, acceptTransferModal: false })
    }
    requestTransfer = async () => {
        let consultation = this.state.preview;
        if (consultation?._id) {
            const response = await fetch(API_ENDPOINT + `/consultation/request-transfer/${consultation?._id}/${this.state.view}`, {
                method: 'GET',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                }
            });
            if (!response || !response.ok) return;
            const result = await response.json();
            if (result.requestSent) {
                this.refresh()
            }
            if (result.error) {
                this.setState({ error: result.error || defaultError });
            };
        }

        this.setState({ requestTransferModal: null })
    }
    closeConsultation = async () => {
        let consultation = this.state.preview;
        if (consultation?._id) {
            const response = await fetch(API_ENDPOINT + `/consultation/close/${consultation?._id}`, {
                method: 'GET',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                }
            });
            if (!response || !response.ok) return;
            const result = await response.json();
            if (result.consultationClosed) {
                this.setState({ preview: null, view: 'archived' })
                this.refresh()
            }
            if (result.error) {
                this.setState({ error: result.error || defaultError });
            };
        }


        this.setState({ closeConsultationModal: null })
    }
    getStatusText = (status, username = null) => {
        let statusText = status;
        if (status === 'unhandled') statusText = 'Unhandled'.translate(this.props.lang);
        else if (status === 'sent') statusText = 'Sent'.translate(this.props.lang);
        else if (status === 'ongoing') statusText = 'Ongoing'.translate(this.props.lang);
        else if (status === 'case-received') statusText = 'Case received'.translate(this.props.lang);
        else if (status === 'case-transferred') statusText = 'Case transferred'.translate(this.props.lang);
        else if (status === 'closed' && !username) statusText = 'Closed'.translate(this.props.lang);
        else if (status === 'closed' && username) statusText = `${'Closed'.translate(this.props.lang)} ${'by'.translate(this.props.lang)} ${username}`;
        return statusText;
    }

    seenConsultation = async (consultationId) => {

        if (consultationId) {
            const response = await fetch(API_ENDPOINT + `/consultation/seen/${consultationId}`, {
                method: 'GET',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                }
            });
            if (!response || !response.ok) return;
            const result = await response.json();
            if (result.seenUpdate) {
                if (this.props.checkConsultationIndicators) {
                    this.props.checkConsultationIndicators()
                }
            }

        }
    }
    openChangeTimeModal = () => {
        let caseData = this.state.preview?.caseData;
        let data = {
        }
        let date = new Date();

        data.min = new Date();
        data.min.setHours(8, 30, 0)
        data.max = new Date();
        data.max.setHours(20, 30, 0)

        data.min = new Date(this.props.getStringDateTs(Math.floor(new Date(data.min).getTime() / 1000), 'MM/DD/YYYY HH:mm'))
        data.max = new Date(this.props.getStringDateTs(Math.floor(new Date(data.max).getTime() / 1000), 'MM/DD/YYYY HH:mm'))
        let workingTimeConfig = this.props.uData?.workingTimeConfig?.[this.props.selectedGroup]?.[this.props.selectedClinic];
        if (workingTimeConfig) {
            data.min = new Date(workingTimeConfig.min)
            data.max = new Date(workingTimeConfig.max)
        }

        let startDate = this.props.getStringDateTs(Math.floor(date.getTime() / 1000), 'YYYY-MM-DD');
        let endDate = this.props.getStringDateTs(Math.floor(date.getTime() / 1000), 'YYYY-MM-DD');
        let start = this.props.getStringDateTs(Math.floor(date.getTime() / 1000 + 600), 'HH:mm');
        let end = this.props.getStringDateTs(Math.floor(date.getTime() / 1000 + 900), 'HH:mm');

        if (caseData?.event?.startTime) {
            startDate = this.props.getStringDateTs(caseData.event.startTime, 'YYYY-MM-DD')
            endDate = this.props.getStringDateTs(caseData.event.startTime, 'YYYY-MM-DD')
            start = this.props.getStringDateTs(caseData.event.startTime, 'HH:mm');
            if (caseData?.event?.endTime) {
                end = this.props.getStringDateTs(caseData.event.endTime, 'HH:mm');
            }
        }



        data.date = startDate;
        data.endDate = endDate;
        data.startTime = start;
        data.endTime = end;

        this.setState({ changeBookedTimeModal: data })

    }
    acceptTrasnferWithChangedTime = () => {
        let date = this.state.changeBookedTimeModal?.date;
        let startTime = this.state.changeBookedTimeModal?.startTime;
        let endTime = this.state.changeBookedTimeModal?.endTime;
        let data = {
            startEventReservedDateOriginal: date + ' ' + startTime,
            endEventReservedDateOriginal: date + ' ' + endTime,
            startEventReservedDate: this.props.getStringDateInverseTs(Math.floor(new Date(date + ' ' + startTime).getTime() / 1000), `${this.props.dateFormat} HH:mm`),
            endEventReservedDate: this.props.getStringDateInverseTs(Math.floor(new Date(date + ' ' + endTime).getTime() / 1000), `${this.props.dateFormat} HH:mm`)
        }
        this.transferCase('change-booked-time', data)
    }

    render() {

        const humanReadable = {
            'waiting-for-approval': 'Waiting for approval'.translate(this.props.lang),
            'waiting-for-payment': 'Waiting for payment'.translate(this.props.lang),
            'disallowed': 'Disallowed'.translate(this.props.lang),
            'allowed': 'Allowed'.translate(this.props.lang),
            'return-for-update': 'Return for update'.translate(this.props.lang),
            'patient-requested-another-time': 'Patient requested anoother time'.translate(this.props.lang),
            'patient-rejected': 'Rejected by patient'.translate(this.props.lang),
            'patient-accepted': 'Patient accepted'.translate(this.props.lang),
            'clinic-accepted-requested-time': 'Clinic accepted requested time'.translate(this.props.lang),
            'clinic-rejected-requested-time': 'Clinic rejected requested time'.translate(this.props.lang),
            'approved-by-region-manager': 'Approved by region manager'.translate(this.props.lang),
            'rejected-by-region-manager': 'Rejected by region manager'.translate(this.props.lang),
            'scheduled': 'Scheduled'.translate(this.props.lang),
            'visit-rescheduled': 'Visit rescheduled'.translate(this.props.lang),
            'revisit-scheduled': 'Revisit scheduled'.translate(this.props.lang),
            'not-booked': 'Not booked'.translate(this.props.lang),
            'closed': 'Closed'.translate(this.props.lang),
            'waiting': 'Waiting'.translate(this.props.lang),
            'in-progress': 'In progress'.translate(this.props.lang),
            'canceled-by-clinic': 'Canceled by clinic'.translate(this.props.lang),
            'requested-new-appointment': 'Requested new appointment'.translate(this.props.lang),
            'external': 'External transfer'.translate(this.props.lang),

        }
        // if (this.state.preview) console.log('preview', this.state.preview);
        // console.log('consultation indicators: ', this.props.consultationIndicators)
        let selectedGroupIdx = -1;
        if (this.props.uData && this.props.selectedGroup && this.props.uData?.clinicGroups?.length) {
            for (let i = 0; i < this.props.uData.clinicGroups.length; i++) {
                if (this.props.uData.clinicGroups[i]._id == this.props.selectedGroup) {
                    selectedGroupIdx = i;
                    break;
                }
            }
        }
        let enterprise = this.props.uData && this.props.uData.clinicGroups && this.props.uData.clinicGroups[selectedGroupIdx] && this.props.uData.clinicGroups[selectedGroupIdx].enterprise;
        let canSearchPatients = this.props.uData && this.props.uData.clinicGroups && this.props.uData.clinicGroups[selectedGroupIdx] && this.props.uData.clinicGroups[selectedGroupIdx].sarchPatients;

        let canOpenProfile = false;
        if (this.state.clinicGroupSettings?.enterprise || this.state.clinicGroupSettings?.canSearchPatients) canOpenProfile = true;

        const preview = this.state.preview ? this.state.preview : null;
        const isSelectedDoctorExists = preview?.receiver?.doctorId ? true : false;
        const isSelectedDoctorLogged = preview?.receiver?.doctorId === this.props.uData._id ? true : false;
        const clinicDoctors = this.state.clinicDoctors ?? [];

        const type = preview?.receiver?.groupId === this.props.selectedGroup && preview?.receiver?.clinic === this.props.selectedClinic ? 'receiver' : preview?.sender?.groupId === this.props.selectedGroup && preview?.sender?.clinic === this.props.selectedClinic ? 'sender' : 'sender';
        const statusLogArr = this.state.view === 'outgoing' ? preview?.sender?.statusLog ?? [] : this.state.view === 'incoming' ? preview?.receiver?.statusLog ?? [] : this.state.view === 'archived' ? preview?.[type]?.statusLog ?? [] : [];
        const statusLog = statusLogArr.map((item, idx) => {
            return {
                status: this.getStatusText(item.status, item?.username),
                time: this.props.getStringDateTs(item.ts, this.props.dateFormat + ' HH:mm'),
                username: item?.username
            }
        })

        const isLoggedUserGroupAdmin = this.props.uData?.isGroupAdmin?.[this.props.selectedGroup]?.[this.props.selectedClinic];

        const groupPermissionsUser = this.props.uData?.groupPermissionsUser;
        const adminPermissionId = this.props.uData?.permissions?.admin;
        const isAdmin = groupPermissionsUser?.[this.props.selectedGroup]?.[this.props.selectedClinic]?.indexOf(adminPermissionId) !== -1 ? true : false;
        const isDoctor = groupPermissionsUser?.[this.props.selectedGroup]?.[this.props.selectedClinic]?.indexOf(this.props.uData?.permissions?.doctor) !== -1 ? true : false;

        return (
            <div className={this.props.autoHideSidebar ? "dashboard dashboard-auto-hide" : "dashboard"}>
                <Container fluid>
                    <Row style={{ marginBottom: 10 }}>
                        <Col lg='12' style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: 10 }}>
                            {/* <h2 style={{ fontWeight: '800' }}>{'Consultation'.translate(this.props.lang)}</h2> */}
                            {isAdmin ? <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: 10, position: 'relative', right: '6%' }}>
                                <span>{'Show all consultations'.translate(this.props.lang)}</span>
                                <Switch value={this.state.allConsultations} onChange={(e) => {
                                    this.setState({ allConsultations: e?.target?.checked })
                                }} />
                            </div> : null}
                        </Col>
                    </Row>

                    <div className='e-connect-tabs'>
                        <Button className={this.state.view === 'incoming' ? 'active-tab-button' : ''} color='secondary' onClick={() => {
                            if (this.state.view === 'incoming') return;
                            this.setState({ view: 'incoming' })
                        }}>
                            {this.props.consultationIndicators?.incoming ? <div className='consultation-new-message-notif'></div> : null}
                            {'Incoming'.translate(this.props.lang)}
                        </Button>
                        <Button className={this.state.view === 'outgoing' ? 'active-tab-button' : ''} color='secondary' onClick={() => {
                            if (this.state.view === 'outgoing') return;
                            this.setState({ view: 'outgoing' })
                        }}>
                            {this.props.consultationIndicators?.outgoing ? <div className='consultation-new-message-notif'></div> : null}
                            {'Outgoing'.translate(this.props.lang)}
                        </Button>
                        <Button className={this.state.view === 'archived' ? 'active-tab-button' : ''} color='secondary' onClick={() => {
                            if (this.state.view === 'archived') return;
                            this.setState({ view: 'archived' })
                        }}>
                            {this.props.consultationIndicators?.archived ? <div className='consultation-new-message-notif'></div> : null}
                            {'Archived'.translate(this.props.lang)}
                        </Button>
                    </div>
                    <Fragment key='active'>
                        <Row>
                            <Col lg='12'>
                                <div className='panel aeb-panel'>
                                    <div className='panel-header'>
                                        <h5 className='component-header'>
                                            {'Consultation Requests'.translate(this.props?.lang || 'en')}
                                        </h5>
                                    </div>
                                    <ConsultationList
                                        {...this.props}
                                        num={this.state.num}
                                        refresh={this.refresh}
                                        allConsultations={this.state.allConsultations}
                                        type={this.state.view}
                                        canOpenProfile={canOpenProfile}
                                        enterprise={this.state.clinicGroupSettings?.enterprise}
                                        previewOpened={preview?._id}
                                        onPreview={(item) => this.setState({ preview: item }, () => {
                                            // console.log(this.state.preview);
                                            if (this.state.preview?._id) {
                                                this.seenConsultation(this.state.preview._id);

                                                const highlightedCases = this.state.highlightedCases?.filter?.(i => i !== this.state.preview._id);
                                                this.setState({ highlightedCases });
                                            }
                                        })}
                                        onHistory={(item) => this.setState({ history: item })}
                                        onArchive={item => { if (item && item._id) this.setState({ archiveModal: item._id }) }}
                                        uData={this.props.uData}
                                        onPrint={(item) => { if (item && item._id) this.print(item._id) }}
                                        highlightedCases={this.state.highlightedCases}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Fragment>


                </Container>
                {
                    preview ?
                        <Modal
                            key='form-modal'
                            isOpen={preview}
                            size='xl'
                            centered
                            className='consultation-request-modal-wrap'
                        >
                            <ModalHeader
                                toggle={() => this.setState({ preview: null })}
                                close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }}
                                    onClick={() => {
                                        this.setState({ preview: null });
                                    }}
                                >&times;</button>}
                            >
                                {this.state.view === 'incoming' ? 'Consultation request'.translate(this.props.lang) : this.state.view === 'outgoing' ? 'Consultation request'.translate(this.props.lang) : this.state.view === 'archived' ? 'Consultation request'.translate(this.props.lang) : null}

                            </ModalHeader>
                            <ModalBody style={{ position: 'relative' }} className='consultation-request-modal-body-wrap'>

                                {this.state.loading ? <div className='econnect-loader'><Player
                                    autoplay={true}
                                    loop={true}
                                    src="/lf30_editor_l5cxzdyf.json"
                                    style={{ height: typeof window != 'undefined' && window.innerWidth < 768 ? "128px" : "256px", width: typeof window != 'undefined' && window.innerWidth < 768 ? "128px" : "256px" }}
                                /></div> : null}

                                <Row>
                                    <Col lg='6'>
                                        {
                                            this.state.view === 'incoming' ?
                                                <div className='header-row'>
                                                    <div className='box-wrap'>
                                                        <h5>{'Sender clinic'.translate(this.props.lang)}:</h5>
                                                        <p>{preview?.sender?.groupName} - {preview?.sender?.clinicName}</p>
                                                    </div>
                                                    <div className='box-wrap'>
                                                        <h5>{'Sender doctor'.translate(this.props.lang)}:</h5>
                                                        <p>{preview?.sender?.doctorName}</p>
                                                    </div>
                                                </div>
                                                : this.state.view === 'outgoing' ?
                                                    <div className='header-row'>
                                                        <div className='box-wrap'>
                                                            <h5>{'Receiver clinic'.translate(this.props.lang)}:</h5>
                                                            <p>{preview?.receiver?.groupName} - {preview?.receiver?.clinicName}</p>
                                                        </div>
                                                        <div className='box-wrap'>
                                                            <h5>{'Receiver doctor'.translate(this.props.lang)}:</h5>
                                                            <p>{preview?.receiver?.doctorName}</p>
                                                        </div>
                                                    </div>
                                                    : this.state.view === 'archived' ?
                                                        <>
                                                            <div className='header-row'>
                                                                <div className='box-wrap'>
                                                                    <h5>{'Sender clinic'.translate(this.props.lang)}:</h5>
                                                                    <p>{preview?.sender?.groupName} - {preview?.sender?.clinicName}</p>
                                                                </div>
                                                                <div className='box-wrap'>
                                                                    <h5>{'Sender doctor'.translate(this.props.lang)}:</h5>
                                                                    <p>{preview?.sender?.doctorName}</p>
                                                                </div>
                                                            </div>
                                                            <div className='header-row' style={{ marginTop: 15 }}>
                                                                <div className='box-wrap'>
                                                                    <h5>{'Receiver clinic'.translate(this.props.lang)}:</h5>
                                                                    <p>{preview?.receiver?.groupName} - {preview?.receiver?.clinicName}</p>
                                                                </div>
                                                                <div className='box-wrap'>
                                                                    <h5>{'Receiver doctor'.translate(this.props.lang)}:</h5>
                                                                    <p>{preview?.receiver?.doctorName}</p>
                                                                </div>
                                                            </div>
                                                        </>
                                                        :
                                                        null
                                        }

                                        <div key='div-patient-asdf' className='patient-info-row'>
                                            <div>
                                                <div>
                                                    <h6>{'Social security number'.translate(this.props.lang)}:</h6>
                                                    <p>{preview?.patientSSN} {preview?._2fa ? <UserIsNotVerifiedTooltip warning lang={this.props.lang} /> : null}</p>
                                                </div>
                                                <div>
                                                    <h6>{'Name'.translate(this.props.lang)}:</h6>
                                                    <p>{preview?.patientName}</p>
                                                </div>
                                            </div>
                                            {preview?._id ? <button onClick={() => {
                                                this.openCasePatient(preview?.patientId)

                                            }}>{'See patient info'.translate(this.props.lang)}</button> : null}
                                        </div>
                                        <div key='div-patient-asdf' className='case-info-row'>
                                            <div>
                                                <div>
                                                    <h6>{'Case'.translate(this.props.lang)}:</h6>
                                                    <p>{preview?.caseData?.questionaryName}</p>
                                                </div>
                                            </div>
                                            {preview?.caseData?.caseId ? <button className='see-details-btn' onClick={() => {
                                                this.openCaseDetails(preview?.caseData?.caseId, preview?._2fa)

                                            }}>{'See details'.translate(this.props.lang)}</button> : null}
                                        </div>
                                        <div key='div-patient-asdf' className='case-info-row'>
                                            <div>
                                                <div>
                                                    <h6>{'Comment'.translate(this.props.lang)}:</h6>
                                                    <p>{preview?.comment}</p>
                                                </div>
                                            </div>

                                        </div>
                                        <div key='div-patient-asdf' className='case-info-row'>
                                            <div>
                                                <div className='attachments-div-wrap'>
                                                    <h6>{'Attachments'.translate(this.props.lang)}:</h6>
                                                    {preview?.attachments?.length ? preview?.attachments?.map((item, idx) => {
                                                        return (
                                                            <a href={`${API_ENDPOINT}${item.url}`} target='_blank'>{item.name}</a>
                                                        )
                                                    })
                                                        :
                                                        <p className='italic-text'>{'No attachments'.translate(this.props.lang)}</p>
                                                    }
                                                </div>
                                            </div>

                                        </div>
                                        {
                                            preview?.transferCase?.senderRequest && this.state.view === 'incoming' ?
                                                <div key='div-patient-asdf' className='case-info-row'>
                                                    <div>
                                                        <div className='attachments-div-wrap'>
                                                            <h6 style={{ textDecoration: 'underline' }}>{'Has requested to transfer case'.translate(this.props.lang)}</h6>
                                                            {
                                                                !preview?.transferCase?.caseTransfered ?
                                                                    <div className='consultation-transfer-info'>
                                                                        <div>
                                                                            <div className='consultation-transfer-info-case-data'>
                                                                                <h6>{'Visit type:'.translate(this.props.lang)}</h6>
                                                                                <p>{preview?.caseData?.visitType.translate(this.props.lang)}</p>
                                                                            </div>
                                                                            <div className='consultation-transfer-info-case-data'>
                                                                                <h6>{'Case status:'.translate(this.props.lang)}</h6>
                                                                                <p>{humanReadable[preview?.caseData?.status]}</p>
                                                                            </div>
                                                                            {
                                                                                preview?.caseData?.patientReservedTimestamp ?
                                                                                    <div className='consultation-transfer-info-case-data'>
                                                                                        <h6>{'Case scheduled:'.translate(this.props.lang)}</h6>
                                                                                        <p>{this.props.getStringDateTs(preview?.caseData?.patientReservedTimestamp, `${this.props.dateFormat} HH:mm`)}</p>
                                                                                    </div>
                                                                                    :
                                                                                    null
                                                                            }
                                                                        </div>
                                                                        <div className='accept-transfer-btn-wrap'>

                                                                            <button className={isSelectedDoctorExists && isSelectedDoctorLogged ? 'accept-transfer-btn' : 'accept-transfer-btn disabled-btn'} disabled={!isSelectedDoctorExists || !isSelectedDoctorLogged} onClick={() => {
                                                                                if (isSelectedDoctorExists && isSelectedDoctorLogged) {
                                                                                    if (preview?.caseData?.status && SCHEDULED_STATUSES.indexOf(preview.caseData.status) !== -1) {
                                                                                        this.setState({ acceptTransferModal: true })
                                                                                    } else {
                                                                                        this.setState({ acceptTransferMessage: true })
                                                                                    }
                                                                                }

                                                                            }}>{'Accept transfer'.translate(this.props.lang)}</button>
                                                                            <InfoWithTooltip
                                                                                style={{ bottom: 4 }}
                                                                                text={'Case will be transferred to you and can be seen in “My cases” and “Chat”'}
                                                                                lang={this.props.lang}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    <p className='italic-text'>{'Case transfer has been completed!'.translate(this.props.lang)}</p>

                                                            }

                                                        </div>
                                                    </div>

                                                </div>

                                                :
                                                null
                                        }
                                        <div key='div-patient-asdf' className='case-info-row'>
                                            <div className='attachments-div-wrap status-history-wrap'>
                                                <h6>{'Status history'.translate(this.props.lang)}:</h6>
                                                {
                                                    statusLog?.length ?
                                                        statusLog.map((item, idx) => {
                                                            return (
                                                                <p className='status-history'>{item.status} - <span>{item.time}</span></p>
                                                            )
                                                        })
                                                        :
                                                        <p className='italic-text'>{'No logs'.translate(this.props.lang)}</p>
                                                }
                                            </div>

                                        </div>

                                    </Col>
                                    <Col lg='6'>
                                        {
                                            this.state.view === 'outgoing' ?
                                                <div className='button-wrap'>

                                                    <button className={!preview?.transferCase?.senderRequest ? 'button-btn' : 'button-btn disabled-btn'} disabled={preview?.transferCase?.senderRequest} onClick={() => {
                                                        if (!preview?.transferCase?.senderRequest) {
                                                            this.setState({ requestTransferModal: true })
                                                        }
                                                    }}>{'Request transfer'.translate(this.props.lang)}</button>

                                                    <button className={preview?.sender?.status !== 'closed' && !preview?.transferCase?.caseTransfered ? 'button-btn' : 'button-btn disabled-btn'} disabled={preview?.sender?.status === 'closed' || preview?.transferCase?.caseTransfered} onClick={() => {
                                                        if (preview?.sender?.status !== 'closed' || preview?.transferCase?.caseTransfered) {
                                                            this.setState({ closeConsultationModal: true })
                                                        }

                                                    }}>{'Close consultation'.translate(this.props.lang)}</button>
                                                </div>
                                                :
                                                null
                                        }
                                        {
                                            isSelectedDoctorExists ?
                                                this.state.conversationId ?
                                                    <div>
                                                        {/* {
                                                            this.state.view === 'outgoing' ?
                                                                <div className='button-wrap'>

                                                                    <button className={!preview?.transferCase?.senderRequest ? 'button-btn' : 'button-btn disabled-btn'} disabled={preview?.transferCase?.senderRequest} onClick={() => {
                                                                        if (!preview?.transferCase?.senderRequest) {
                                                                            this.setState({ requestTransferModal: true })
                                                                        }
                                                                    }}>{'Request transfer'.translate(this.props.lang)}</button>

                                                                    <button className={preview?.sender?.status !== 'closed' && !preview?.transferCase?.caseTransfered ? 'button-btn' : 'button-btn disabled-btn'} disabled={preview?.sender?.status === 'closed' || preview?.transferCase?.caseTransfered} onClick={() => {
                                                                        if (preview?.sender?.status !== 'closed' || preview?.transferCase?.caseTransfered) {
                                                                            this.setState({ closeConsultationModal: true })
                                                                        }

                                                                    }}>{'Close consultation'.translate(this.props.lang)}</button>
                                                                </div>
                                                                :
                                                                null
                                                        } */}
                                                        {
                                                            this.state.view === 'incoming' && isAdmin && isSelectedDoctorExists && !preview?.transferCase?.caseTransfered ?
                                                                <div className='button-wrap' style={{ marginTop: 20 }}>

                                                                    <button className='button-btn' onClick={() => {
                                                                        this.setState({ assignToColleagueModal: preview?.receiver?.doctorId, selectedColleague: null })

                                                                    }}>{'Assign to colleague'.translate(this.props.lang)}</button>


                                                                    <button className='button-btn' onClick={() => {
                                                                        if (preview?.receiver?.doctorId) {
                                                                            this.setState({ unassignModal: true })
                                                                        }

                                                                    }}>{'Unassign'.translate(this.props.lang)}</button>

                                                                </div>
                                                                :
                                                                null
                                                        }
                                                        {
                                                            this.state.view === 'incoming' && preview?.transferCase?.caseTransfered ?
                                                                <div className='button-wrap'>
                                                                    <button className={preview?.sender?.status !== 'closed' ? 'button-btn' : 'button-btn disabled-btn'} disabled={preview?.sender?.status === 'closed'} onClick={() => {
                                                                        if (preview?.sender?.status !== 'closed') {
                                                                            this.setState({ closeConsultationModal: true })
                                                                        }

                                                                    }}>{'Close consultation'.translate(this.props.lang)}</button>
                                                                </div>
                                                                :
                                                                null
                                                        }
                                                        <div className="iframe-chat-wrap">
                                                            <iframe
                                                                src={`/chat/${this.state.conversationId}?patientSearchModule=true&doNotOpenPatientProfile=true`}
                                                                onLoad={() => {
                                                                    if (this.props.checkConsultationIndicators) {
                                                                        setTimeout(() => {
                                                                            this.props.checkConsultationIndicators()
                                                                        }, 3000);
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    :
                                                    this.state.view === 'outgoing' ?
                                                        <div className='header-row'>
                                                            <div className='box-wrap'>
                                                                <h5>{'Sender clinic'.translate(this.props.lang)}:</h5>
                                                                <p>{preview?.sender?.groupName} - {preview?.sender?.clinicName}</p>
                                                            </div>
                                                            <div className='box-wrap'>
                                                                <h5>{'Sender doctor'.translate(this.props.lang)}:</h5>
                                                                <p>{preview?.sender?.doctorName}</p>
                                                            </div>
                                                        </div>
                                                        : this.state.view === 'incoming' ? <div>
                                                            <div className='header-row'>
                                                                <div className='box-wrap'>
                                                                    <h5>{'Receiver clinic'.translate(this.props.lang)}:</h5>
                                                                    <p>{preview?.receiver?.groupName} - {preview?.receiver?.clinicName}</p>
                                                                </div>
                                                                <div className='box-wrap'>
                                                                    <h5>{'Receiver doctor'.translate(this.props.lang)}:</h5>
                                                                    <p>{preview?.receiver?.doctorName}</p>
                                                                </div>
                                                            </div>
                                                            {
                                                                isAdmin && isSelectedDoctorExists && !preview?.transferCase?.caseTransfered ?
                                                                    <div className='button-wrap' style={{ marginTop: 20 }}>

                                                                        <button className='button-btn' onClick={() => {
                                                                            this.setState({ assignToColleagueModal: preview?.receiver?.doctorId })

                                                                        }}>{'Assign to colleague'.translate(this.props.lang)}</button>


                                                                        <button className='button-btn' onClick={() => {
                                                                            if (preview?.receiver?.doctorId) {
                                                                                this.setState({ unassignModal: true })
                                                                            }

                                                                        }}>{'Unassign'.translate(this.props.lang)}</button>

                                                                    </div>
                                                                    :
                                                                    null
                                                            }

                                                        </div>
                                                            :
                                                            null
                                                :
                                                this.state.view === 'incoming' ?
                                                    <div className='button-wrap'>

                                                        <button className={!isSelectedDoctorExists && isAdmin ? 'button-btn' : 'button-btn disabled-btn'} disabled={isSelectedDoctorExists || !isAdmin} onClick={() => {
                                                            if (!isSelectedDoctorExists && isAdmin) {
                                                                this.setState({ assignToColleagueModal: this.props.uData._id, selectedColleague: null })
                                                            }

                                                        }}>{'Assign to colleague'.translate(this.props.lang)}</button>


                                                        <button className={!isSelectedDoctorExists && isDoctor ? 'button-btn' : 'button-btn disabled-btn'} disabled={isSelectedDoctorExists || !isDoctor} onClick={() => {
                                                            if (!isSelectedDoctorExists && isDoctor) {
                                                                this.setState({ pickUpConsultationMessage: true })
                                                            }

                                                        }}>{'Pick up'.translate(this.props.lang)}</button>

                                                    </div>
                                                    :
                                                    null
                                        }

                                    </Col>
                                </Row>
                            </ModalBody>
                            <ModalFooter style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>

                            </ModalFooter>
                        </Modal>
                        :
                        null

                }
                {
                    this.state.assignToColleagueModal ?
                        <Modal key='clinic-patient-info' isOpen={this.state.assignToColleagueModal} size='md' centered>
                            <ModalHeader style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gap: 10 }} toggle={() => this.setState({ assignToColleagueModal: null })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ assignToColleagueModal: null })}>&times;</button>}>
                                {'Assign to colleague'.translate(this.props.lang)}
                            </ModalHeader>
                            <ModalBody>
                                <Input type='select' value={this.state.selectedColleague} onChange={(e) => {
                                    this.setState({ selectedColleague: e.target.value })
                                }}>
                                    <option value={-1}>{'None'.translate(this.props.lang)}</option>
                                    {
                                        clinicDoctors.filter(item => item._id !== this.state.assignToColleagueModal).map((item, idx) => {
                                            return (
                                                <option value={item._id}>{item.name}</option>
                                            )
                                        })
                                    }
                                </Input>
                            </ModalBody>
                            <ModalFooter className='buttons-right-blue' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Button
                                    color='primary'
                                    disabled={!this.state.selectedColleague || !clinicDoctors?.filter(item => item._id === this.state.selectedColleague)?.length}
                                    onClick={() => {
                                        this.setState({ assignToColleagueConsultationMessage: clinicDoctors.filter(item => item._id === this.state.selectedColleague)?.[0]?.name })
                                    }}
                                >
                                    {'Assign'.translate(this.props.lang)}
                                </Button>
                                <Button color='primary' onClick={() => this.setState({ assignToColleagueModal: null })}>
                                    {'Close'.translate(this.props.lang)}
                                </Button>
                            </ModalFooter>
                        </Modal>
                        :
                        null
                }
                {
                    this.state.closeConsultationModal ?
                        <InfoModal
                            isOpen={this.state.closeConsultationModal}
                            header={'Info'.translate(this.props.lang)}
                            info={'Do you want to close consultation?'.translate(this.props.lang)}
                            buttons={[
                                <Button color='primary' onClick={() => {
                                    this.closeConsultation()
                                }}>{'Yes'.translate(this.props.lang)}</Button>,
                                <Button color='primary' onClick={() => {
                                    this.setState({ closeConsultationModal: null })
                                }}>{'No'.translate(this.props.lang)}</Button>
                            ]}
                        />
                        :
                        null
                }
                {
                    this.state.requestTransferModal ?
                        <InfoModal
                            isOpen={this.state.requestTransferModal}
                            header={'Info'.translate(this.props.lang)}
                            info={'Do you want to request trasfer case?'.translate(this.props.lang)}
                            buttons={[
                                <Button color='primary' onClick={() => {
                                    this.requestTransfer()
                                }}>{'Yes'.translate(this.props.lang)}</Button>,
                                <Button color='primary' onClick={() => {
                                    this.setState({ requestTransferModal: null })
                                }}>{'No'.translate(this.props.lang)}</Button>
                            ]}
                        />
                        :
                        null
                }
                {
                    this.state.acceptTransferModal ?
                        <Modal isOpen={this.state.acceptTransferModal} centered>
                            <ModalHeader
                                // toggle={() => this.setState({ acceptTransferModal: !this.state.acceptTransferModal, doctorsList: [] })}
                                close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ acceptTransferModal: !this.state.acceptTransferModal, doctorsList: [] })}>&times;</button>}>
                                {'Accept transfer'.translate(this.props.lang)}
                            </ModalHeader>

                            <ModalBody>
                                <div className='schedule-modal-body-wrap'>
                                    <Button color='primary'
                                        onClick={() => {
                                            this.openChangeTimeModal()

                                        }}
                                    >
                                        {'Change booked time'.translate(this.props.lang)}
                                    </Button>
                                    {
                                        this.state.preview?.caseData?.patientReservedTimestamp && this.state.preview?.caseData?.patientReservedTimestamp < Math.floor(new Date().getTime() / 1000) ?
                                            null
                                            :
                                            <Button color='primary'
                                                onClick={() => {
                                                    this.transferCase('accept-booked-time')
                                                }}
                                            >
                                                {'Accept booked time'.translate(this.props.lang)}
                                            </Button>
                                    }

                                    <Button color='primary'
                                        onClick={() => {
                                            this.transferCase('receive-without-scheduling-time')
                                        }}
                                    >
                                        {'Receive without scheduling time'.translate(this.props.lang)}
                                    </Button>


                                </div>

                            </ModalBody>
                        </Modal>
                        :
                        null
                }
                {
                    this.state.changeBookedTimeModal ?
                        <Modal isOpen={this.state.changeBookedTimeModal} centered>
                            <ModalHeader
                                toggle={() => this.setState({ scheduleReferral: !this.state.changeBookedTimeModal })}
                                close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ changeBookedTimeModal: !this.state.changeBookedTimeModal })}>&times;</button>}>
                                {'Change booked time'.translate(this.props.lang)}
                            </ModalHeader>
                            <ModalBody>
                                <FormGroup>
                                    <Label>{'Doctor'.translate(this.props.lang)}</Label>
                                    <Input type='text' disabled={true} value={this.props?.uData?.name} />
                                </FormGroup>

                                <FormGroup>
                                    <DatePicker
                                        label={'Start date'.translate(this.props.lang)}
                                        value={this.state.changeBookedTimeModal.date}
                                        onChange={(e) => {
                                            let changeBookedTimeModal = this.state.changeBookedTimeModal;
                                            changeBookedTimeModal.date = e;
                                            this.setState({
                                                changeBookedTimeModal
                                            })
                                            if (Math.floor(new Date(this.state.changeBookedTimeModal.date + ' ' + this.state.changeBookedTimeModal.startTime) / 1000) > Math.floor(new Date(this.state.changeBookedTimeModal.endDate + ' ' + this.state.changeBookedTimeModal.endTime) / 1000)) {
                                                changeBookedTimeModal.endDate = this.state.changeBookedTimeModal.date
                                                this.setState({
                                                    changeBookedTimeModal
                                                })
                                            }
                                        }}
                                    />
                                </FormGroup>
                                <FormGroup className="timepicker-container timepicker-wide">
                                    <Label>{'Start time'.translate(this.props.lang)}</Label>
                                    &nbsp;

                                    <TimePickerV2
                                        getStringDateTs={this.props.getStringDateTs}
                                        format={'HH:mm'}
                                        value={this.state.changeBookedTimeModal.startTime}
                                        minValue={this.props.getStringDateTs(Math.floor(this.state.changeBookedTimeModal?.min?.getTime() / 1000), 'HH:mm')}
                                        maxValue={this.props.getStringDateTs(Math.floor(this.state.changeBookedTimeModal?.max?.getTime() / 1000 - 300), 'HH:mm')}
                                        flag={0}
                                        onChange={(time1, timeString) => {
                                            let changeBookedTimeModal = this.state.changeBookedTimeModal;
                                            changeBookedTimeModal.startTime = timeString
                                            let startTimeSplit = changeBookedTimeModal.startTime.split(":");
                                            let endTimeSplit = changeBookedTimeModal.endTime.split(":");
                                            let startTimeHourMinute = Number(startTimeSplit[0]) * 60 + Number(startTimeSplit[1]);
                                            let endTimeHourMinute = Number(endTimeSplit[0]) * 60 + Number(endTimeSplit[1]);
                                            if (startTimeHourMinute < endTimeHourMinute) {
                                                this.setState({ changeBookedTimeModal })
                                            } else {
                                                changeBookedTimeModal.endTime = Math.trunc((Number(this.state.changeBookedTimeModal.startTime.split(':')[0]) * 60 + Number(this.state.changeBookedTimeModal.startTime.split(':')[1]) + 5) / 60) + ":" + (Number(this.state.changeBookedTimeModal.startTime.split(':')[0]) * 60 + Number(this.state.changeBookedTimeModal.startTime.split(':')[1]) + 5) % 60
                                                const padded = changeBookedTimeModal?.endTime?.split?.(':');
                                                if (padded && Array.isArray(padded) && padded.length && isStringAValidNumber(padded[0]) && isStringAValidNumber(padded[1])) {
                                                    const h = padded[0]?.padStart?.(2, '0');
                                                    const m = padded[1]?.padStart?.(2, '0');
                                                    if (h && m) changeBookedTimeModal.endTime = `${h}:${m}`;
                                                }
                                                this.setState({
                                                    changeBookedTimeModal
                                                })
                                            }
                                        }}
                                    />
                                </FormGroup>
                                <FormGroup className="timepicker-container timepicker-wide">
                                    <Label>{'End time'.translate(this.props.lang)}</Label>
                                    &nbsp;
                                    <TimePickerV2
                                        getStringDateTs={this.props.getStringDateTs}
                                        format={'HH:mm'}
                                        value={this.state.changeBookedTimeModal.endTime}
                                        minValue={Math.trunc((Number(this.state.changeBookedTimeModal.startTime.split(':')[0]) * 60 + Number(this.state.changeBookedTimeModal.startTime.split(':')[1]) + 5) / 60) + ":" + (Number(this.state.changeBookedTimeModal.startTime.split(':')[0]) * 60 + Number(this.state.changeBookedTimeModal.startTime.split(':')[1]) + 5) % 60}
                                        maxValue={this.props.getStringDateTs(Math.floor(this.state.changeBookedTimeModal?.max?.getTime() / 1000), 'HH:mm')}
                                        flag={0}
                                        onChange={(time1, timeString) => {
                                            let changeBookedTimeModal = this.state.changeBookedTimeModal;
                                            changeBookedTimeModal.endTime = timeString

                                            let startTimeSplit = changeBookedTimeModal.startTime.split(":");
                                            let endTimeSplit = changeBookedTimeModal.endTime.split(":");

                                            let startTimeHourMinute = Number(startTimeSplit[0]) * 60 + Number(startTimeSplit[1]);
                                            let endTimeHourMinute = Number(endTimeSplit[0]) * 60 + Number(endTimeSplit[1]);

                                            if (startTimeHourMinute < endTimeHourMinute) {
                                                this.setState({ changeBookedTimeModal })
                                            }
                                        }}
                                    />

                                </FormGroup>

                                <Button color='primary' onClick={() => {
                                    this.acceptTrasnferWithChangedTime()
                                }}>
                                    {'Accept transfer'.translate(this.props.lang)}
                                </Button>

                            </ModalBody>
                        </Modal>
                        :
                        null
                }

                {
                    this.state.acceptTransferMessage ?
                        <InfoModal
                            isOpen={this.state.acceptTransferMessage}
                            header={'Info'.translate(this.props.lang)}
                            info={'Do you want to accept trasfer case?'.translate(this.props.lang)}
                            buttons={[
                                <Button color='primary' onClick={() => {
                                    this.transferCase('accept-booked-time')
                                }}>{'Yes'.translate(this.props.lang)}</Button>,
                                <Button color='primary' onClick={() => {
                                    this.setState({ acceptTransferMessage: null })
                                }}>{'No'.translate(this.props.lang)}</Button>
                            ]}
                        />
                        :
                        null
                }
                {
                    this.state.pickUpConsultationMessage ?
                        <InfoModal
                            isOpen={this.state.pickUpConsultationMessage}
                            header={'Info'.translate(this.props.lang)}
                            info={'Do you want to pick up consultation?'.translate(this.props.lang)}
                            buttons={[
                                <Button color='primary' onClick={() => {
                                    this.pickUpConsultation()
                                }}>{'Yes'.translate(this.props.lang)}</Button>,
                                <Button color='primary' onClick={() => {
                                    this.setState({ pickUpConsultationMessage: null })
                                }}>{'No'.translate(this.props.lang)}</Button>
                            ]}
                        />
                        :
                        null
                }
                {
                    this.state.unassignModal ?
                        <InfoModal
                            isOpen={this.state.unassignModal}
                            header={'Info'.translate(this.props.lang)}
                            info={`${'Do you want to unassign'.translate(this.props.lang)} ${preview?.receiver?.doctorId && clinicDoctors?.filter(item => item._id === preview.receiver.doctorId)?.[0]?.name ? clinicDoctors.filter(item => item._id === preview.receiver.doctorId)[0].name : 'doctor'.translate(this.props.lang)} ${'from consultation?'.translate(this.props.lang)}`}
                            buttons={[
                                <Button color='primary' onClick={() => {
                                    this.unassignDoctor()
                                }}>{'Yes'.translate(this.props.lang)}</Button>,
                                <Button color='primary' onClick={() => {
                                    this.setState({ unassignModal: null })
                                }}>{'No'.translate(this.props.lang)}</Button>
                            ]}
                        />
                        :
                        null
                }
                {
                    this.state.assignToColleagueConsultationMessage ?
                        <InfoModal
                            isOpen={this.state.assignToColleagueConsultationMessage}
                            header={'Info'.translate(this.props.lang)}
                            info={`${'Do you want to assign'.translate(this.props.lang)} ${this.state.assignToColleagueConsultationMessage} ${'to consultation?'.translate(this.props.lang)}`}
                            buttons={[
                                <Button color='primary' onClick={() => {
                                    this.assignToColleague()
                                }}>{'Yes'.translate(this.props.lang)}</Button>,
                                <Button color='primary' onClick={() => {
                                    this.setState({ assignToColleagueConsultationMessage: null })
                                }}>{'No'.translate(this.props.lang)}</Button>
                            ]}
                        />
                        :
                        null
                }


                {this.state.casePatientModal ? <Modal key='clinic-patient-info' isOpen={this.state.casePatientModal} size='md' centered>
                    <ModalHeader style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gap: 10 }} toggle={() => this.setState({ casePatientModal: null })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ casePatientModal: null })}>&times;</button>}>
                        {'Patient'.translate(this.props.lang)}
                        <InfoWithTooltip style={{ bottom: 4 }} lang={this.props.lang} text='Information about patient correct as of the moment of case creation' />
                    </ModalHeader>
                    <ModalBody>
                        <div className='eb-modal-div'>
                            <h6>{'Name'.translate(this.props.lang || 'en')}</h6>
                            <div>{this.state.casePatientModal?.name || 'N/A'}</div>
                        </div>
                        <div className='eb-modal-div'>
                            <h6>{'Social security number'.translate(this.props.lang || 'en')}</h6>
                            <div>{this.state.casePatientModal?.ssn || 'N/A'}</div>
                        </div>
                        <div className='eb-modal-div'>
                            <h6>{'Sex'.translate(this.props.lang || 'en')}</h6>
                            <div>{this.state.casePatientModal?.gender?.translate?.(this.props.lang) || 'N/A'}</div>
                        </div>
                        <div className='eb-modal-div'>
                            <h6>{'Phone Number'.translate(this.props.lang || 'en')}</h6>
                            <div>{this.state.casePatientModal?.phone || 'N/A'}</div>
                        </div>
                        <div className='eb-modal-div'>
                            <h6>{'Email Address'.translate(this.props.lang || 'en')}</h6>
                            <div>{this.state.casePatientModal?.email || 'N/A'}</div>
                        </div>
                    </ModalBody>
                    <ModalFooter style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                        <button style={{ visibility: 'hidden', display: 'none' }} aria-hidden='true'></button>
                        {canOpenProfile ? <Button color='primary' onClick={() => {
                            if (!canOpenProfile) return;
                            this.searchPatientWithRedirect(this.state.casePatientModal?.ssn, enterprise);
                        }}>
                            {'Open Patient Profile'.translate(this.props.lang)}
                        </Button> : null}
                        <Button color='primary' onClick={() => this.setState({ casePatientModal: null })}>
                            {'Close'.translate(this.props.lang)}
                        </Button>
                        <button style={{ visibility: 'hidden', display: 'none' }} aria-hidden='true'></button>
                    </ModalFooter>
                </Modal> : null}

                {
                    this.state.casePreviewModal ?
                        <ReferralContent
                            {...this.props}
                            data={this.state.casePreviewModal}
                            screen={'consultation'}
                            doctorPage={true}
                            enterprise={enterprise}
                            // adminInThisGroup={this.state.adminInThisGroup}
                            previewAnswersRevisit={(form) => {
                                this.setState({ previewAnswersModal: form })
                            }}
                            closeReferralContent={(data) => {
                                if (this.state.casePreviewModal) {
                                    this.setState({
                                        casePreviewModal: null
                                    })
                                }
                            }}
                            _consultation={true}
                        />
                        :
                        null
                }
                {
                    this.state.previewAnswersModal ?
                        <Modal isOpen={this.state.previewAnswersModal} centered toggle={() => this.setState({ previewAnswersModal: !this.state.previewAnswersModal })} size="lg">
                            <ModalHeader toggle={() => this.setState({ previewAnswersModal: !this.state.previewAnswersModal })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ previewAnswersModal: !this.state.previewAnswersModal })}><Isvg src={xIcon} /></button>}>{'Form preview'.translate(this.props.lang)}</ModalHeader>

                            <ModalBody>

                                <FormPreview {...this.props} referral={{ name: this.state.previewAnswersModal.name, description: this.state.previewAnswersModal.description, intelligentReferral: this.state.previewAnswersModal.intelligentReferral, items: this.state.previewAnswersModal.items, group: this.props.uData?.clinicGroups[selectedGroupIdx]?._id }} sections={this.generateSections(this.state.previewAnswersModal.items)} answers={this.state.previewAnswersModal.answers} disabledForm={true} hidePreview={true}></FormPreview>

                            </ModalBody>
                        </Modal>
                        :
                        null
                }

                {
                    this.state.error ? <ErrorModal lang={this.props.lang}
                        isOpen={this.state.error}
                        toggle={() => this.setState({ error: null })}

                    >
                        {this.state.error.translate(this.props.lang)}
                    </ErrorModal> : null
                }
            </div>
        )
    }
}


export default PageWithLayout(EconnectConsultationPage);