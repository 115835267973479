import React from 'react'
import reactCSS from 'reactcss'
import {
    Container,
    Row,
    Col,
    FormGroup, Label
} from 'reactstrap';
import Checkbox from './checkbox';
import infoIcon from "../../../assets/svg/link-new-tab.svg";
import Isvg from 'react-inlinesvg';
import { API_ENDPOINT } from '../../../constants';

/**
* Custom checkbox
* @author   Milan Stanojevic
*/
class CustomCheckbox extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
        };
    }



    render() {

        // "By creating an account, I approve [Clinic name]s User terms and Privacy policy."

        let label = null;

        let label1 = null;
        let label2 = null;

        if (this.props.groupData && this.props.groupData.alias) {

            let alias = this.props.groupData.alias;
            let name = this.props.groupData.name ? this.props.groupData.name : null;
            let privacyPolicy = this.props.groupData.privacyPolicy && this.props.groupData.privacyPolicy.file ? `${API_ENDPOINT}${this.props.groupData.privacyPolicy.file}` : null;
            let termsOfUse = this.props.groupData.termsOfUse && this.props.groupData.termsOfUse.file ? `${API_ENDPOINT}${this.props.groupData.termsOfUse.file}` : null;

            let startText;
            if (this.props.firstLogin) {
                startText = 'By creating an account, I approve'
            } else {
                startText = 'By continuing to use my account, I agree to'
            }

            if (privacyPolicy && termsOfUse) {
                label =
                    <div className='privacy-policy-field-label-wrap'>
                        <p>{startText.translate(this.props.lang)} {name ? (name + "'s".translate(this.props.lang)) : "clinic's".translate(this.props.lang)} <a href={termsOfUse} className={this.props.error ? 'error-a-tag' : ''} onClick={(e) => e.stopPropagation()} target="_blank">{"User terms".translate(this.props.lang)}</a> {"and".translate(this.props.lang)} <a href={privacyPolicy} className={this.props.error ? 'error-a-tag' : ''} onClick={(e) => e.stopPropagation()} target="_blank">{"Privacy policy".translate(this.props.lang)}</a>.</p>
                    </div>
            } else if (privacyPolicy && !termsOfUse) {
                label =
                    <div className='privacy-policy-field-label-wrap'>
                        <p>{startText.translate(this.props.lang)} {name ? (name + "'s".translate(this.props.lang)) : "clinic's".translate(this.props.lang)} <a href={privacyPolicy} className={this.props.error ? 'error-a-tag' : ''} onClick={(e) => e.stopPropagation()} target="_blank">{"Privacy policy".translate(this.props.lang)}</a>.</p>
                    </div>
            } else if (!privacyPolicy && termsOfUse) {
                label =
                    <div className='privacy-policy-field-label-wrap'>
                        <p>{startText.translate(this.props.lang)} {name ? (name + "'s".translate(this.props.lang)) : "clinic's".translate(this.props.lang)} <a href={termsOfUse} className={this.props.error ? 'error-a-tag' : ''} onClick={(e) => e.stopPropagation()} target="_blank">{"User terms".translate(this.props.lang)}</a>.</p>
                    </div>
            }

            label1 =
                <div className='privacy-policy-field-label-wrap'>
                    <p> {"To use this service, you must agree to".translate(this.props.lang)} {name}{"'s".translate(this.props.lang)} {termsOfUse ? <a href={termsOfUse} target="_blank">{"Terms of Service".translate(this.props.lang)}</a> : "Terms of Service".translate(this.props.lang)}.</p>
                </div>






        }

        if (this.props.getOnlyLabel) return label;

        // if (!label) {
        //     return null;
        // }

        return (
            <FormGroup className='form-group-label-wrap'>
                {label1}

                {/* <Checkbox
                    onChange={(e) => {
                        if (!this.props.disabled)
                            this.props.onChange(e.target.checked);
                    }}
                    checked={this.props.value}
                    label={label}
                    // errorLabelDisabled={true}
                    disabled={this.props.disabled}
                    error={this.props.error}
                /> */}


                {/* {
                    this.props.labelInfo ?
                        <div className='info'>
                            <Isvg src={infoIcon} />
                            <div className="info-text">{this.props.labelInfo}</div>
                        </div>
                        :
                        null
                } */}
            </FormGroup>

        )
    }
}

export default CustomCheckbox