import React, { Component } from 'react';
import moment from 'moment';
import Page from '../../containers/page';
import ListBuilder from '../../components/listBuilder';
import { Link } from 'react-router-dom';
import {
    Container,
    Row,
    Col,
    Modal, ModalHeader, ModalBody, ModalFooter, Button, FormGroup, Input, Label, Table
} from 'reactstrap';
import { getSearchParams, generateSearchLink, generateSearchLinkMultiple } from '../../helpers/linkHelper';
import { API_ENDPOINT } from '../../constants';
import Isvg from 'react-inlinesvg';
import garbageIcon from '../../assets/svg/garbage.svg';
import DeleteModal from '../../components/deleteModal';
import logoMobile from '../../assets/images/logo_mobile.png';

var striptags = require('striptags');

/**
* Notification page
* @author   Stefan Pantic
*/
class HomePage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            promotedProjects: [],
            ...props.initialData,
            form: false,

            useState: false,
            params: {
                entries: 10,
                page: 0
            },
            pageNumber: 0,
            items: []
        };
    }
    /**
    * Function call api for last notification to check if notification is needed and then updated list of notificatons with verifyUser() function
    * @author   Stefan Pantic
    */
    get = () => {
        fetch(API_ENDPOINT + '/notification/last', {
            method: 'POST',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },

        }).then(res => res.json()).then((result) => {
            // console.log(result)
            localStorage.setItem('notif', result._id);

        })
        // console.log('deleted get')
        this.props.verifyUser()
        this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));

        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0, sortField: 'timestamp', sortType: -1, })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

        for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
            this.props.loadDataWithQuery[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0, sortField: 'timestamp', sortType: -1, pageNumber: this.state.pageNumber, profile: localStorage.getItem('profile') })).then((data) => {

                if (data && data.notificationsAll && this.state.notificationsAll && this.state.notificationsAll.length && this.state.pageNumber != 0) {
                    for (let i = 0; i < data.notificationsAll.length; i++) {
                        for (let j = 0; j < this.state.notificationsAll.length; j++) {
                            if (this.state.notificationsAll[j]._id == data.notificationsAll[i]._id) {
                                this.state.notificationsAll.splice(j, 1)
                            }
                        }
                    }
                    data.notificationsAll = [...this.state.notificationsAll, ...data.notificationsAll]
                }

                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

    }

    componentDidMount() {
        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }

        this.get();


        this.interval = setInterval(() => {
            this.get();
        }, 60 * 1000);

        // this.updateNotif()

    }
    componentWillUnmount() {
        if (this.interval) {
            clearInterval(this.interval)
        }
        this.props.verifyUser()
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.uData && prevProps.uData && this.props.uData.lastNotifications && prevProps.lastNotifications && this.props.uData.lastNotifications.length != prevProps.uData.lastNotifications.length) {

            this.get()
        }
    }



    /**
    * Change group if needed 
    * @author   Stefan Pantic
    * @Objectparam    {Object} data    notification data
    */
    setGroup = (data) => {

        if (data.type.indexOf('|') != -1 && this.props.uData.userLevel != 1 && this.props.uData.userLevel != 2 && localStorage.getItem('profile') != 'patient') {
            if (data.clinic)
                this.props.changeClinic(data.clinic)
            let groupId = data.type.split('|');
            if (groupId[1] && this.props.selectedGroup.indexOf(groupId[1]) == -1) {
                for (let i = 0; i < this.props?.uData?.clinicGroups?.length; i++) {
                    if (this.props.uData.clinicGroups[i]._id.indexOf(groupId[1]) != -1) {
                        this.props.changeGroup(groupId[1])
                    }
                }
            }
        } else if (data.type.indexOf('|') != -1 && this.props.uData.patientGroups && this.props.uData.patientGroups.length) {
            if (data.type.indexOf('|') != -1 && this.props.uData.patientGroups && this.props.uData.patientGroups.length) {

                let groupId = data.type.split('|');
                for (let i = 0; i < this.props.uData.patientGroups.length; i++) {
                    if (this.props.uData.patientGroups[i]._id.indexOf(groupId[1]) != -1) {
                        // alert(this.props.uData.patientGroups[i].alias)
                        localStorage.setItem('groupAlias', this.props.uData.patientGroups[i].alias)
                    }
                }

            }
        }


    }

    updateNotif = () => {
        let id = 'allNotif'
        this.setState({
            loading: true
        }, () => {

            fetch(API_ENDPOINT + '/notification/seen/update/' + id, {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify()
            }).then(res => res.json()).then((result) => {
                this.get();
                // console.log('test')
            })

        })
    }

    deleteNotification = (id) => {
        this.setState({
            loading: true,
            notificationsAll: this.state.notificationsAll.filter(items => items._id.toString() != id.toString())
        }, () => {

            fetch(API_ENDPOINT + '/notification/delete/' + id, {
                method: 'DELETE',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
            }).then(res => res.json()).then((result) => {
                this.get();
            })

        })
    }

    deleteAllNotification = () => {
        this.setState({
            loading: true,
            notificationsAll: []

        }, () => {

            fetch(API_ENDPOINT + '/notification/delete/allnotification', {
                method: 'DELETE',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
            }).then(res => res.json()).then((result) => {
                this.get();
            })

        })
    }


    render() {
        // if (this.state.notificationsAll?.length) console.log('notifs', this.state.notificationsAll)
        let params = getSearchParams(this.props[0].location.search, { entries: 10, page: 0 })
        return (
            <div className={this.props.autoHideSidebar ? "dashboard dashboard-auto-hide" : "dashboard"}>
                <Container fluid>
                    <div className="panel select-clinics-wrapper">
                        <div className="notifications-header">
                            <h5 className="component-header mb-35">{this.props.linksMeta && this.props.linksMeta[this.props[0].location.pathname] ? this.props.linksMeta[this.props[0].location.pathname][this.props.lang] : ''}</h5>
                            <div className="notifications-header-buttons">
                                <Button color="primary" onClick={() => this.updateNotif()} disabled={this.state.notificationsAll && this.state.notificationsAll.length > 0 ? false : true}>{'Mark all as seen'.translate(this.props.lang)}</Button>

                                <Button color="primary" onClick={() => this.setState({ deleteModalAllNotification: true })} disabled={this.state.notificationsAll && this.state.notificationsAll.length > 0 ? false : true}>{'Delete all notifications'.translate(this.props.lang)}</Button>
                            </div>
                        </div>


                        <Row>
                            <Col lg="12">
                                <div className="choose-clinic-notification">
                                    {this.state.notificationsAll && this.state.notificationsAll.length ? this.state.notificationsAll.map((item, idx) => {
                                        let additionalState = {};
                                        if (item.description.indexOf('Message:|') != -1) {
                                            let description = item.description.split('|')
                                            item.description = description[0].translate(this.props.lang) + description[1]
                                        }

                                        if (item.type?.includes?.('PatientDocument:|')) {
                                            additionalState = {
                                                tabIndex: 6,
                                            }
                                        }

                                        if (item.type?.includes?.('TestResults:|')) {
                                            additionalState = {
                                                tabIndex: 3
                                            }
                                        }

                                        if (item.type?.includes?.('Diary:|')) {
                                            additionalState = {
                                                tabIndex: 2
                                            }
                                        }

                                        return (
                                            <div style={!item.seen ? { border: '2px solid #568AC8', position: 'relative' } : { position: 'relative' }}>
                                                {item.type.indexOf('message') != -1 || item.type.indexOf('closeConversation') != -1 ?
                                                    <div onClick={() => {
                                                        this.setGroup(item)

                                                        fetch(API_ENDPOINT + '/notification/seen/update/' + item._id, {
                                                            method: 'POST',
                                                            headers: {
                                                                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                                                                'content-type': 'application/json'
                                                            },
                                                            body: JSON.stringify()
                                                        }).then(res => res.json()).then((result) => {
                                                            this.get();

                                                        })
                                                    }}>
                                                        <a style={item.seen ? { color: '#3A3F56' } : { color: 'black' }} href={item.type.indexOf('closeConversation') != -1 ? item.link + '?closeConversation=true' : item.link}>
                                                            <div>
                                                                {/* <span>{idx + 1}</span> */}
                                                                {item.logo ?
                                                                    <img src={API_ENDPOINT + item.logo} />
                                                                    : <img src={logoMobile} />}
                                                                <div>
                                                                    <h6>{item.message}</h6>
                                                                    <p>{item.description}</p>
                                                                    <p>{this.props.getStringDateTs(item.timestemp, `${this.props.dateFormat} HH:mm`)/*moment.unix(item.timestemp).format(`${this.props.dateFormat} HH:mm`)*/}</p>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>

                                                    :
                                                    <Link style={item.seen ? { color: '#3A3F56' } : { color: 'black' }} onClick={() => {
                                                        this.setGroup(item)
                                                        fetch(API_ENDPOINT + '/notification/seen/update/' + item._id, {
                                                            method: 'POST',
                                                            headers: {
                                                                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                                                                'content-type': 'application/json'
                                                            },
                                                            body: JSON.stringify()
                                                        }).then(res => res.json()).then((result) => {
                                                            this.get();
                                                            if (item.link == "/my-data/personal-referrals/referrals") {
                                                                if (!(this.props.uData?.userLevel === 20 && typeof window != 'undefined' && localStorage?.getItem?.('profile') == 'doctor')) {
                                                                    localStorage.setItem('profile', 'patient')
                                                                    localStorage.setItem('userLevel', 2);
                                                                }

                                                            } else if (item.link == '/my-data/personal-referrals/received') {
                                                                localStorage.setItem('profile', 'doctor');
                                                                localStorage.setItem('userLevel', 20);
                                                            }
                                                        })
                                                    }} to={{
                                                        pathname: item.link == "/my-data/personal-referrals/referrals" && this.props.uData?.userLevel === 20 && typeof window != 'undefined' && localStorage?.getItem?.('profile') == 'doctor' ? '/my-data/personal-referrals/my-cases' : item.link,
                                                        state: {
                                                            messageModal: item.check,
                                                            date: item.date ? item.date : '',
                                                            ...additionalState
                                                        }
                                                    }}>
                                                        <div>
                                                            {/* <span>{idx + 1}</span> */}
                                                            {item.logo ?
                                                                <img src={API_ENDPOINT + item.logo} />
                                                                : <img src={logoMobile} />}
                                                            <div>

                                                                <h6>{item.message}</h6>
                                                                <p>{item.description}</p>
                                                                <p>{this.props.getStringDateTs(item.timestemp, `${this.props.dateFormat} HH:mm`)/*moment.unix(item.timestemp).format(`${this.props.dateFormat} HH:mm`)*/}</p>
                                                            </div>
                                                        </div>

                                                    </Link>

                                                }

                                                <div className={item.seen ? '' : 'ticket-notification'}></div>

                                                <div className="clear-data clear-data-centered" onClick={() => {
                                                    this.setState({ deleteModal: item._id })

                                                }}><div className="tabel-action-tooltip"><Isvg src={garbageIcon} />
                                                        <div className="chat-icons-tooltip">{'Delete'.translate(this.props.lang)}</div></div>
                                                </div>

                                            </div>


                                        )

                                    })
                                        :
                                        <p className='no-conversations-p'>
                                            {'No notifications'.translate(this.props.lang)}
                                        </p>
                                    }

                                </div>
                                <Button color='primary' onClick={() => this.setState({ pageNumber: this.state.pageNumber + 1 }, () => this.get())} >{'Load more'.translate(this.props.lang)}</Button>
                            </Col>
                        </Row>
                    </div>
                </Container>
                {this.state.deleteModal ?
                    <DeleteModal
                        lang={this.props.lang}
                        isOpen={this.state.deleteModal}
                        toggle={() => this.setState({ deleteModal: null })}
                        handler={() => { this.deleteNotification(this.state.deleteModal); this.setState({ deleteModal: null }) }}
                    >
                        {'Delete notification'.translate(this.props.lang)} <strong>{this.state.deleteModal && this.state.deleteModal.title}</strong>?
                    </DeleteModal>
                    : null}
                {this.state.deleteModalAllNotification ?
                    <DeleteModal
                        lang={this.props.lang}
                        isOpen={this.state.deleteModalAllNotification}
                        toggle={() => this.setState({ deleteModalAllNotification: null })}
                        handler={() => { this.deleteAllNotification(); this.setState({ deleteModalAllNotification: null }) }}
                    >
                        {'Delete all notifications'.translate(this.props.lang)} ?
                    </DeleteModal>
                    : null}
            </div>

        );


    }
}

export default Page(HomePage);