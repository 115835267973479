import React, { Component } from 'react';
import moment from 'moment';
import Page from '../../containers/page';
import ListBuilder from '../../components/listBuilder';
import { Link } from 'react-router-dom';
import {
    Container,
    Row,
    Col,
    Modal, ModalHeader, ModalBody, ModalFooter, Button, FormGroup, Input, Label, Table
} from 'reactstrap';
import { getSearchParams, generateSearchLink, generateSearchLinkMultiple } from '../../helpers/linkHelper';
import { API_ENDPOINT } from '../../constants';
import { v4 as uuidv4 } from 'uuid';
import DragAndDropList from '../../components/dragAndDropList';
var striptags = require('striptags');


class AppBuilderTest extends Component {
    constructor(props) {
        super(props);

        this.state = {
            cols: [
                {
                    id: uuidv4(),
                    'name': 'Doctor 1',
                    events: [
                        {
                            id: uuidv4(),
                            type: 'event-component',
                            name: 'event1',
                            start: Math.floor(new Date(2021, 5, 25, 0, 0, 0, 0).getTime() / 1000),
                            end: Math.floor(new Date(2021, 5, 25, 3, 0, 0, 0).getTime() / 1000)

                        },
                        {
                            id: uuidv4(),
                            type: 'event-component',
                            name: 'event2',
                            start: Math.floor(new Date(2021, 5, 25, 4, 0, 0, 0).getTime() / 1000),
                            end: Math.floor(new Date(2021, 5, 25, 6, 0, 0, 0).getTime() / 1000)

                        },
                    ]
                },
                {
                    id: uuidv4(),
                    'name': 'Doctor 2',
                    events: [

                    ]
                },
                {
                    id: uuidv4(),
                    'name': 'Doctor 3',
                    events: [
                        {
                            id: uuidv4(),
                            type: 'event-component',
                            name: 'event1',
                            start: Math.floor(new Date(2021, 5, 25, 2, 0, 0, 0).getTime() / 1000),
                            end: Math.floor(new Date(2021, 5, 25, 2, 35, 0, 0).getTime() / 1000)

                        },
                        {
                            id: uuidv4(),
                            type: 'event-component',
                            name: 'event2',
                            start: Math.floor(new Date(2021, 5, 25, 4, 0, 0, 0).getTime() / 1000),
                            end: Math.floor(new Date(2021, 5, 25, 4, 45, 0, 0).getTime() / 1000)

                        },
                        {
                            id: uuidv4(),
                            type: 'event-component',
                            name: 'event3',
                            start: Math.floor(new Date(2021, 5, 25, 6, 10, 0, 0).getTime() / 1000),
                            end: Math.floor(new Date(2021, 5, 25, 7, 0, 0, 0).getTime() / 1000)

                        },

                    ]
                },

                {
                    id: uuidv4(),
                    'name': 'Doctor 4',
                    events: [
                    ]
                },

                {
                    id: uuidv4(),
                    'name': 'Doctor 5',
                    events: [
                    ]
                },
                {
                    id: uuidv4(),
                    'name': 'Doctor 6',
                    events: [
                    ]
                },
                {
                    id: uuidv4(),
                    'name': 'Doctor 7',
                    events: [
                    ]
                },
                {
                    id: uuidv4(),
                    'name': 'Doctor 8',
                    events: [
                    ]
                },
                {
                    id: uuidv4(),
                    'name': 'Doctor 9',
                    events: [
                    ]
                },
            ]
        };
    }

    componentDidMount() {

    }
    componentWillUnmount() {

    }

    onChange = (item) => {


        let cols = {};
        for(let i=0;i<this.state.cols.length;i++){
            cols[this.state.cols[i].id] = {...this.state.cols[i], events: []};
        }
        for(let i=0;i<this.state.cols.length;i++){
            for(let j=0;j<this.state.cols[i].events.length;j++){
                if (this.state.cols[i].events[j].id == item.id){
                    let event = {
                        ...this.state.cols[i].events[j],
                        start: item.start,
                        end: item.end
                    };
                    cols[item.column].events.push(event);
                }else{
                    cols[this.state.cols[i].id].events.push(this.state.cols[i].events[j]);
                }
            }
        
        }

        this.setState({
            cols: Object.values(cols)
        })

    }



    render() {
        //console.log(params);
        return (
            <div className={this.props.autoHideSidebar ? "dashboard dashboard-auto-hide" : "dashboard"}>
                <Container>
                    <Row>
                        <Col lg="12">
                            <DragAndDropList
                                date={new Date()}
                                dateFormat={this.props.dateFormat}
                                cols={this.state.cols}
                                onChange={this.onChange}
                                components={{
                                    'event-component': (props) => {
                                        return (
                                            <div className="test-event-component">
                                                <p>{moment.unix(props.start).format('HH:mm')} - {moment.unix(props.end).format('HH:mm')}</p>
                                                <p>{props.name}</p>
                                            </div>
                                        )
                                    }
                                }}
                            ></DragAndDropList>
                        </Col>
                    </Row>
                </Container>
            </div>

        );


    }
}

export default Page(AppBuilderTest);