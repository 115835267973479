import React, { Component } from 'react';
import { API_ENDPOINT } from '../constants';
import { Player, Controls } from "@lottiefiles/react-lottie-player";

/**
* Payson transaction check component 
* @author   Stefan Pantic
*/
class Transaction extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // checkingTransaction: 1
        };
    }

    componentDidMount() {
        let key = this.props[0].match.params.id;
        // this.setState({
        //     loading: true
        // })
        if (key.indexOf('stripe-success') === -1 && key.indexOf('stripe-cancel') === -1) {
            setTimeout(() => {
                fetch(API_ENDPOINT + '/confirmation/transaction', {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({ key })
                }).then(res => res.json()).then((result) => {
                    // console.log(result);
                    if (result.error) {
                        this.setState({
                            error: result.error
                        })
                    } else {
                        if (result.status && result.status != 'readyToPay' && result.status != 'canceled' && result.status != 'denied' && result.status != 'readyToShip' && result.status != 'shipped' /*&& this.state.checkingTransaction < 6*/) {
                            this.checkAgain(key)
                        } else {
                            this.setState({
                                status: result.status,
                                reddirectToPaid: result && result.refData && result.refData.reddirectToPaid,
                                successMessageNeeded: result && result.refData && result.refData.successMessageNeeded,
                                fromMyPayments: result && result.refData && result.refData.fromMyPayments,
                                fromMyCases: result && result.refData && result.refData.fromMyCases,
                                referralPreviewObj: result && result.refData && result.refData.referralPreviewObj,
                                questionaryData: result.questionaryData,
                                dropIn: result.dropIn,
                                compliteReferral: result.referral
                            }, () => {
                                if ((result.status == 'readyToShip' || result.status == 'shipped') && result.refData && result.refData.doctor == 'none') {
                                    if (this.state.questionaryData?.paymentBeforeSubmission) {
                                        if (this.props.patientCreatedReferral) this.props.patientCreatedReferral();
                                    }
                                    this.generateInvoice(result.refData, result.transaction, 'payson', () => { })

                                    if (window.location.href == `${window.location.origin}/my-data/personal-referrals/referrals`) {
                                        location.reload()
                                    } else {
                                        if (this.state.successMessageNeeded && this.state.fromMyPayments) {
                                            this.props[0].history.push({
                                                pathname: '/my-data/personal-referrals/my-payments',
                                                state: {
                                                    // successModal: "Payment successful.".translate(this.props.lang),
                                                    tab: 2
                                                }

                                            })
                                        } else if (this.state.successMessageNeeded && this.state.questionaryData.paymentBeforeBookedTime) {

                                            this.props[0].history.push({
                                                pathname: '/my-data/personal-referrals/referrals',
                                                state: { successModal: "Your case has been submitted and payment has been made successfully.".translate(this.props.lang) }
                                            })
                                        } else if (this.state.successMessageNeeded && this.state.questionaryData.paymentAfterBookedTime) {
                                            this.props[0].history.push({
                                                pathname: '/my-data/personal-referrals/referrals',
                                                state: { successModal: "Your case has been submitted and payment has been made successfully.".translate(this.props.lang) }
                                            })
                                        } else if (this.state.successMessageNeeded && this.state.dropIn) {
                                            this.props[0].history.push({
                                                pathname: '/my-data/personal-referrals/referrals',
                                                state: { successModal: "Your case has been submitted and payment has been made successfully. Please remain online and track the status of your case.".translate(this.props.lang) }
                                            })
                                        } else if (result && result.refData && result.refData.successMessageNeeded) {
                                            this.props[0].history.push({
                                                pathname: '/my-data/personal-referrals/referrals',
                                                state: { successModal: "Payment successful.".translate(this.props.lang) }
                                            })
                                        } else {
                                            this.props[0].history.push('/my-data/personal-referrals/referrals')
                                        }
                                    }
                                } else if ((result.status == 'readyToShip' || result.status == 'shipped') && result.refData && result.refData.doctor != 'none') {
                                    if (this.state.questionaryData?.paymentBeforeSubmission) {
                                        if (this.props.patientCreatedReferral) this.props.patientCreatedReferral();
                                    }
                                    this.scheduleReferral(result.refData, result.transaction)
                                } else {
                                    this.chageStatus(result.refData, result.status)
                                }
                            })
                        }

                    }
                });
            }, 1000)
        } else {
            fetch(API_ENDPOINT + '/stripe/update/payment', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json'
                },
                body: JSON.stringify({ key })
            }).then(res => res.json()).then((result) => {

            });

            if (key.indexOf('stripe-cancel') === -1) {

                this.props[0].history.push({
                    pathname: '/my-data/personal-referrals/my-payments',
                    state: { successModal: "Payment failed, please make sure to pay before your scheduled visit.".translate(this.props.lang) }
                })

            } else if (key.indexOf('stripe-success') === -1) {

                this.props[0].history.push({
                    pathname: '/my-data/personal-referrals/referrals',
                    state: { successModal: "Payment successful.".translate(this.props.lang) }
                })

            }
        }



    }

    checkAgain = (key) => {
        this.setState({
            // checkingTransaction: this.state.checkingTransaction + 1,
            loading: true
        })

        //setInterval(() => {
        fetch(API_ENDPOINT + '/confirmation/transaction', {
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify({ key })
        }).then(res => res.json()).then((result) => {

            if (result.error) {
                this.setState({
                    error: result.error
                })
            } else {
                if (result.status && result.status != 'readyToPay' && result.status != 'canceled' && result.status != 'denied' && result.status != 'readyToShip' && result.status != 'shipped' /*&& this.state.checkingTransaction < 6*/) {
                    setTimeout(() => {
                        this.checkAgain(key)
                    }, 2000)
                } else {
                    this.setState({
                        status: result.status,
                        reddirectToPaid: result && result.refData && result.refData.reddirectToPaid,
                        successMessageNeeded: result && result.refData && result.refData.successMessageNeeded,
                        fromMyPayments: result && result.refData && result.refData.fromMyPayments,
                        fromMyCases: result && result.refData && result.refData.fromMyCases,
                        referralPreviewObj: result && result.refData && result.refData.referralPreviewObj,
                        questionaryData: result.questionaryData,
                        dropIn: result.dropIn,
                        compliteReferral: result.referral
                    }, () => {
                        if ((result.status == 'readyToShip' || result.status == 'shipped') && result.refData && result.refData.doctor == 'none') {
                            if (this.state.questionaryData?.paymentBeforeSubmission) {
                                if (this.props.patientCreatedReferral) this.props.patientCreatedReferral();
                            }
                            this.generateInvoice(result.refData, result.transaction, 'payson', () => { })

                            if (window.location.href == `${window.location.origin}/my-data/personal-referrals/referrals`) {
                                location.reload()
                            } else {
                                if (this.state.successMessageNeeded && this.state.fromMyPayments) {
                                    this.props[0].history.push({
                                        pathname: '/my-data/personal-referrals/my-payments',
                                        state: {
                                            // successModal: "Payment successful.".translate(this.props.lang),
                                            tab: 2
                                        }

                                    })
                                } else if (this.state.successMessageNeeded && this.state.questionaryData.paymentBeforeBookedTime) {
                                    this.props[0].history.push({
                                        pathname: '/my-data/personal-referrals/referrals',
                                        state: { successModal: "Your case has been submitted and payment has been made successfully.".translate(this.props.lang) }
                                    })
                                } else if (this.state.successMessageNeeded && this.state.questionaryData.paymentAfterBookedTime) {
                                    this.props[0].history.push({
                                        pathname: '/my-data/personal-referrals/referrals',
                                        state: { successModal: "Your case has been submitted and payment has been made successfully.".translate(this.props.lang) }
                                    })
                                } else if (this.state.successMessageNeeded && this.state.dropIn) {
                                    this.props[0].history.push({
                                        pathname: '/my-data/personal-referrals/referrals',
                                        state: { successModal: "Your case has been submitted and payment has been made successfully. Please remain online and track the status of your case.".translate(this.props.lang) }
                                    })
                                } else if (result && result.refData && result.refData.successMessageNeeded) {
                                    this.props[0].history.push({
                                        pathname: '/my-data/personal-referrals/referrals',
                                        state: { successModal: "Payment successful.".translate(this.props.lang) }
                                    })
                                } else {
                                    this.props[0].history.push('/my-data/personal-referrals/referrals')
                                }
                            }
                        } else if ((result.status == 'readyToShip' || result.status == 'shipped') && result.refData && result.refData.doctor != 'none') {
                            if (this.state.questionaryData?.paymentBeforeSubmission) {
                                if (this.props.patientCreatedReferral) this.props.patientCreatedReferral();
                            }
                            this.scheduleReferral(result.refData, result.transaction)
                        } else {
                            this.chageStatus(result.refData, result.status)
                        }
                    })
                }

            }
        });
        //}, 2000)

    }

    /**
    * Schedule referral event on calendar
    * @author   Stefan Pantic
    * @Objectparam    {String} date                 date when event start
    * @Objectparam    {Number} startTime            start time for event
    * @Objectparam    {Number} endTime              start time for event
    * @Objectparam    {String} doctor               doctor id
    * @Objectparam    {String} patientNote          note for events with referral
    * @Objectparam    {String} patient              patient id
    * @Objectparam    {String} referral             referral id
    */
    scheduleReferral = (refData, transaction) => {


        fetch(API_ENDPOINT + '/referrals/patient-schedule/' + refData.referral.toString(), {
            method: 'POST',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
            body: JSON.stringify({ startEventReservedDateOriginal: refData.data.date + ' ' + refData.data.startTime, endEventReservedDateOriginal: refData.data.date + ' ' + refData.data.endTime, startPatientReservedDate: this.props.getStringDateInverseTs(Math.floor(new Date(refData.data.date + ' ' + refData.data.startTime).getTime() / 1000), `${this.props.dateFormat} HH:mm`), endPatientReservedDate: this.props.getStringDateInverseTs(Math.floor(new Date(refData.data.date + ' ' + refData.data.endTime).getTime() / 1000), `${this.props.dateFormat} HH:mm`), doctor: refData.doctor, patientNote: refData.data.patientNote, patient: refData.patient, referral: refData.referral })
        }).then(res => res.json()).then((result) => {
            if (this.state.questionaryData?.paymentBeforeSubmission) {
                if (this.props.patientCreatedReferral) this.props.patientCreatedReferral();
            }
            this.generateInvoice(refData, transaction, 'payson', () => {
                if (this.state.successMessageNeeded && this.state.fromMyPayments) {
                    this.props[0].history.push({
                        pathname: '/my-data/personal-referrals/my-payments',
                        state: {
                            // successModal: "Payment successful.".translate(this.props.lang),
                            tab: 2
                        }

                    })
                } else if (this.state.successMessageNeeded && this.state.fromMyCases) {
                    this.props[0].history.push({
                        pathname: '/my-data/personal-referrals/referrals',
                        state: { successModal: "Payment successful.".translate(this.props.lang), referralPreviewObj: this.state.referralPreviewObj ? this.state.referralPreviewObj : null }
                    })
                } else if (result && result.refData && result.refData.successMessageNeeded) {
                    this.props[0].history.push({
                        pathname: '/my-data/personal-referrals/referrals',
                        state: { successModal: "Payment successful.".translate(this.props.lang) }
                    })
                } else {
                    this.props[0].history.push('/my-data/personal-referrals/referrals')
                }
            });
        })

    }
    /**
    * Generate invoice for referral
    * @author   Stefan Pantic
    * @Objectparam    {String} refData                referral data
    * @Objectparam    {String} transaction            transaction identifier
    */
    generateInvoice = (refData, transaction, online, callback) => {

        //racun se pravi samo na bekendu

        // fetch(API_ENDPOINT + '/referrals/generate-invoice/' + refData.referral.toString(), {
        //     method: 'POST',
        //     headers: {
        //         'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
        //         'content-type': 'application/json'
        //     },
        //     body: JSON.stringify({ transaction: transaction, online: online })
        // }).then(res => res.json()).then((result) => {
        //     this.props.clearStatusBefore();
        //     this.props.verifyUser()
        //     if (callback) {
        //         callback();
        //     }
        // })
        this.props.clearStatusBefore();
        this.props.verifyUser()
        if (callback) {
            callback();
        }
    }

    /**
    * Delete referral
    * @author   Stefan Pantic
    * @Objectparam    {String} referral             referral id
    */
    delete = (refData) => {

        fetch(API_ENDPOINT + '/referrals/patient-schedule/delete/' + refData.referral.toString(), {
            method: 'DELETE',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
        }).then(res => res.json()).then((result) => {

            this.props[0].history.push('/my-data/personal-referrals/referrals')
        })
    }

    /**
    * Return object if payson payment fail
    * @author   Stefan Pantic
    * @Objectparam    {String} referral             referral id
    * @Objectparam    {Object} refDate              referral data
    */
    chageStatus = (refData, status) => {

        fetch(API_ENDPOINT + '/referrals/patient-schedule/status/' + refData.referral.toString(), {
            method: 'POST',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
            body: JSON.stringify({ refData: refData, status: status })
        }).then(res => res.json()).then((result) => {

            if (this.state.successMessageNeeded && this.state.questionaryData && !this.state.questionaryData.paymentBeforeBookedTime && !this.state.dropIn) {
                if (this.state.questionaryData && this.state.questionaryData.wayOfScheduling == 'patient') {
                    this.props[0].history.push({
                        pathname: '/my-data/personal-referrals/my-payments',
                        state: { successModal: "You have successfully scheduled a visit. Remember to make the payment before the scheduled time.".translate(this.props.lang) }
                    })
                } else if (this.state.questionaryData && this.state.questionaryData.paymentAfterBookedTime && this.state.fromMyPayments) {
                    this.props[0].history.push({
                        pathname: '/my-data/personal-referrals/my-payments',
                        state: { successModal: "Payment failed, please make sure to pay before your scheduled visit.".translate(this.props.lang) }
                    })
                } else if (this.state.questionaryData && this.state.questionaryData.paymentAfterBookedTime) {
                    this.props[0].history.push({
                        pathname: '/my-data/personal-referrals/my-payments',
                        state: { successModal: "Your case has been submitted successfully. Remember to make the payment before the scheduled time.".translate(this.props.lang) }
                    })
                } else {
                    this.props[0].history.push({
                        pathname: '/my-data/personal-referrals/my-payments',
                        state: { successModal: "You have successfully scheduled a visit. Remember to make the payment before the scheduled time.".translate(this.props.lang) }
                    })
                }

            } else if (this.state.dropIn) {
                this.props[0].history.push({
                    pathname: '/my-data/personal-referrals/my-payments',
                    state: {
                        dropInErrorModal: "Please try again or choose another payment method. If you cancel, your case will be deleted.".translate(this.props.lang),
                        questionaryData: this.state.questionaryData,
                        refData: refData.referral,
                        compliteReferral: this.state.compliteReferral
                    }
                })
            } else {
                this.props.statusBefore(result.statusBefor)

                if (window.location.href == `${window.location.origin}/my-data/personal-referrals/my-payments`) {
                    location.reload()
                } else {
                    if (this.state.fromMyCases) {
                        this.props[0].history.push({
                            pathname: '/my-data/personal-referrals/referrals',
                            state: { successModal: "Payment failed, please make sure to pay before your scheduled visit.".translate(this.props.lang), referralPreviewObj: this.state.referralPreviewObj }
                        })
                    } else {
                        this.props[0].history.push('/my-data/personal-referrals/my-payments')
                    }

                }
                this.checkUpdate()
            }

        })

    }

    checkUpdate = () => {

        fetch(API_ENDPOINT + '/payment/check/update', {
            method: 'GET',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
        })
    }


    render() {
        return (
            <div className='fullscreen-loader'>
                {
                    this.state.loading ?
                        <div className="loader-wrap" >
                            <Player
                                autoplay={true}
                                loop={true}
                                src="/lf30_editor_l5cxzdyf.json"
                                style={{ height: typeof window != 'undefined' && window.innerWidth < 768 ? "128px" : "256px", width: typeof window != 'undefined' && window.innerWidth < 768 ? "128px" : "256px" }}
                            ></Player>

                        </div>
                        :
                        null
                }

                {
                    this.props.lang && this.state.loading ?
                        <p>{"Checking transaction...".translate(this.props.lang)}</p>
                        :
                        null
                }

            </div>




        );
    }
}

export default Transaction;