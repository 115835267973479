import React, { Component } from 'react';

import {
    Container,
    Row,
    Col, Input, Label, Modal, ModalBody, ModalHeader, FormGroup, Button, Table, FormFeedback, ModalFooter, Collapse, CardBody, Card
} from 'reactstrap';

import { getSearchParams, generateSearchLink, generateSearchLinkMultiple } from '../../../helpers/linkHelper';
import { API_ENDPOINT } from '../../../constants';
import Isvg from 'react-inlinesvg'
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Tooltip } from '@material-ui/core';
import { withStyles } from "@material-ui/core/styles";
import { v4 as uuidv4 } from 'uuid';
import InfoModal from '../../../components/infoModal';
import DatePicker from '../../../components/forms/fields/datePicker';
import moment from 'moment';

const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: "rgba(58, 63, 85, 0.9)",
        color: "#fff",
        boxShadow: theme.shadows[1],
        fontSize: 14
    }
}))(Tooltip);


function parseJSON(response) {
    return response.json().then(json => {
        return {
            result: json,
            status: response.status
        }
    })
}



function pad(n, width, z) {
    z = z || '0';
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
}
function generateAlias(str) {
    str.trim()
    str = str.toLowerCase();
    str = str.replace(/ä/g, 'a');
    str = str.replace(/ö/g, 'o');
    str = str.replace(/ü/g, 'u');
    str = str.replace(/ß/g, 'b');

    str = str.replace(/[^a-zA-Z0-9]/gi, '-').toLowerCase()
    str = str.replace(/-+/g, '-');

    return str;
}
/**
* Calendar for doctors
* @author   Stefan Pantic, Aleksandar Dabic, Milan Stanojevic
*/
class ListOfMedicines extends Component {
    constructor(props) {
        super(props);
        this.player = React.createRef();
        this.player2 = React.createRef();
        this.state = {
            ...props.initialData,
            form: false,
            useState: false,
            params: {
                entries: 10,
                page: 0
            },
            items: [],
            listOfMedicinesNote: false,
            total: 0,
            loading: true,
            listOfMedicines: [],
            section: {}

        };



    }
    showPlayer = () => {
        this.setState({
            showPlayer: true,
        });
    };

    hidePlayer = () => {
        this.setState({
            showPlayer: false,
        });
    };
    stopAnimation = () => {
        this.player.current.play();
    };

    get = () => {

        let obj = {
            patient: this.state.patient ? this.state.patient._id : this.props.uData._id,
            alias: localStorage.getItem('groupAlias')

        }
        fetch(API_ENDPOINT + '/users/all/medicines/list', {
            method: 'POST',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
            body: JSON.stringify(obj)
        }).then(parseJSON).then(({ result, status }) => {
            if (status >= 200 && status < 300) {
                this.setState({
                    section: result[0] ? result[0] : {},
                    listOfMedicinesSave: null,
                    editTableListOfMedicines: null
                })
            }
        })
    }

    componentDidMount() {
        this.get()
    }

    componentDidUpdate(prevProps, prevState) {


    }
    componentWillUnmount = () => {

    }


    updateSort = (field, type) => {
        if (this.state.useState) {
            let state = this.state.params;
            state.sortField = field;
            state.sortType = type;
            this.setState({
                params: state
            })
        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0 }, [{ name: 'sortField', value: field }, { name: 'sortType', value: type }], false));

        }
    }

    updateParams = (name, value, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };
                obj[name] = value;
                this.setState({ params: obj });
            } else {
                let obj = this.state.params;
                obj[name] = value;
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(generateSearchLink(this.props[0].location.search, { entries: 10, page: 0 }, name, value, restart));
        }
    }
    updateMultipleParams = (fields, restart = false) => {

        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj });

            } else {
                let obj = this.state.params;
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0 }, fields, restart));
        }
    }


    render() {
        if (this.state.section) {
            console.log(this.state.section)
        }

        return (
            <div >
                <Container fluid style={{ padding: 0 }}>
                    <Row>

                        <Col lg='12' style={{ padding: 0 }}>
                            <div >
                                <h6 className="component-header daybook-header">
                                    {
                                        this.props.children.length ?
                                            <div>
                                                <Input style={{ paddingRight: 50 }} type="select" onChange={(e) => {
                                                    let patient = this.props.children.filter(item => item._id == e.target.value)[0]
                                                    this.setState({
                                                        patient: patient
                                                    }, () => this.get())

                                                }}>
                                                    <option value={this.props.uData._id}>
                                                        {this.props.uData && this.props.uData.userData && this.props.uData.userData.name}
                                                        &nbsp;
                                                        {this.props.uData && this.props.uData.socialSecurityNumber}
                                                    </option>
                                                    {this.props.children.map(item => {
                                                        return (
                                                            <option value={item._id}>
                                                                {item.name}
                                                                &nbsp;
                                                                {item.socialSecurityNumber}
                                                            </option>
                                                        )
                                                    })}
                                                </Input>
                                            </div>
                                            :
                                            <div>

                                                {this.props.uData && this.props.uData.userData && this.props.uData.userData.name}<br />
                                                {this.props.uData && this.props.uData.socialSecurityNumber}
                                            </div>
                                    }
                                    <label>{'List of medicines updated'.translate(this.props.lang)}: {this.state.section && this.state.section.tsModified ? /*moment.unix(this.state.section.tsModified).format(this.props.dateFormat)*/this.props.getStringDateTs(this.state.section.tsModified, this.props.dateFormat) : this.state.section && this.state.section.tsCreated ? /*moment.unix(this.state.section.tsCreated).format(this.props.dateFormat)*/this.props.getStringDateTs(this.state.section.tsCreated, this.props.dateFormat) : 'Not modified'.translate(this.props.lang)}</label>
                                    {/* {this.props.uData.medicinesListModifiedTs && !this.state.patient ? moment.unix(this.props.uData.medicinesListModifiedTs).format(this.props.dateFormat) : this.state.patient && this.state.patient.medicinesListModifiedTs ? moment.unix(this.state.patient.medicinesListModifiedTs).format(this.props.dateFormat) : 'Not modified'.translate(this.props.lang)}</label> */}

                                </h6>

                                <Row>
                                    <Col lg='12'>
                                        {
                                            this.state.section && this.state.section.hourArray && this.state.section.hourArray.length ?
                                                null
                                                :
                                                <p className='no-conversations-p'>
                                                    {'Medication list is empty'.translate(this.props.lang)}
                                                </p>
                                        }
                                        <div>
                                            <Collapse isOpen={true} className="no-border">
                                                <Card >
                                                    <div style={{ overflowX: 'scroll' }}>
                                                        <table style={!this.state.editTableListOfMedicines ? { width: '100%' } : null} className='listofmedicines-table'>

                                                            <tr>
                                                                <th>{'Start of treatment'.translate(this.props.lang)}</th>
                                                                <th>{'Drug name, drug form, strength'.translate(this.props.lang)}</th>
                                                                <th>{'Time for ingestion, dosage'.translate(this.props.lang)}</th>
                                                                <th>{'Use and purpose'.translate(this.props.lang)}</th>
                                                                <th>{'End of treatment'.translate(this.props.lang)}</th>
                                                                <th>{'Other information'.translate(this.props.lang)}</th>
                                                            </tr>
                                                            <tr>
                                                                <td></td>
                                                                <td></td>
                                                                <td className='medicament-hours'>
                                                                    {this.state.section && this.state.section.hourArray && this.state.section.hourArray.length ? this.state.section.hourArray.map((item, idx) => {

                                                                        return (
                                                                            <td className='medicament-hours-take'>
                                                                                <p className='medicament-hours-take-data'>{item + 'h'}</p>
                                                                                {/* <p>{ item }</p>  */}
                                                                            </td>
                                                                        )
                                                                    }) : null}
                                                                </td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                            </tr>

                                                            {
                                                                this.state.section && this.state.section.items && this.state.section.items.map((element, eidx) => {
                                                                    return (
                                                                        <tr ref={node => {
                                                                            if (!this.tableRef) {
                                                                                this.tableRef = {};
                                                                            }
                                                                            if (element.id) {
                                                                                this.tableRef[element.id] = node;
                                                                            }

                                                                        }}>
                                                                            <td>
                                                                                {
                                                                                    <span id={`${element.id}-startTreatment`}>{element.startTreatment}</span>
                                                                                }
                                                                            </td>
                                                                            <td>
                                                                                {
                                                                                    <span id={`${element.id}-drugName`}>{element.drugName}</span>
                                                                                }

                                                                            </td>
                                                                            <td className='medicament-hours-input'>
                                                                                <td className='medicament-hours-input-row'>
                                                                                    {this.state.section.hourArray && this.state.section.hourArray.length ?

                                                                                        this.state.section && this.state.section.hourArray && this.state.section.hourArray.length ? this.state.section.hourArray.map((item, idx) => {
                                                                                            // console.log(this.state.section.items[0].timeForIngestion[`${item}`], idx)
                                                                                            return (
                                                                                                <td className='medicament-hours-take' onMouseEnter={() => {
                                                                                                    this.setState({
                                                                                                        tooltip: `${eidx}-${idx}`
                                                                                                    })
                                                                                                }} onMouseLeave={() => {
                                                                                                    this.setState({
                                                                                                        tooltip: null
                                                                                                    })
                                                                                                }}>
                                                                                                    <Input className='medicament-hours-take-data' disabled={this.state.editTableListOfMedicines ? false : true} type='text' value={this.state.section && this.state.section.items && this.state.section.items[eidx] && this.state.section.items[eidx].timeForIngestion && this.state.section.items[eidx].timeForIngestion[`${item}`] ? this.state.section.items[eidx].timeForIngestion[`${item}`] : ''} onChange={(e) => {
                                                                                                        let section = this.state.section;
                                                                                                        if (section && section.items && section.items[eidx]) {
                                                                                                            if (!section.items[eidx].timeForIngestion)
                                                                                                                section.items[eidx].timeForIngestion = {}
                                                                                                            section.items[eidx].username = this.props.uData.username;
                                                                                                            section.items[eidx].itemModifiedTs = Math.floor(new Date().getTime() / 1000)

                                                                                                            section.items[eidx].timeForIngestion[`${item}`] = e.target.value;
                                                                                                            this.setState({ section })
                                                                                                        }
                                                                                                    }} />

                                                                                                    {
                                                                                                        this.state.tooltip == `${eidx}-${idx}` && !this.state.editTableListOfMedicines && this.state.section && this.state.section.items && this.state.section.items[eidx] && this.state.section.items[eidx].timeForIngestion && this.state.section.items[eidx].timeForIngestion[`${item}`] ?
                                                                                                            <div className='tooltip-dosage'>{this.state.section && this.state.section.items && this.state.section.items[eidx] && this.state.section.items[eidx].timeForIngestion && this.state.section.items[eidx].timeForIngestion[`${item}`] ? this.state.section.items[eidx].timeForIngestion[`${item}`] : ''}</div>
                                                                                                            :
                                                                                                            null
                                                                                                    }
                                                                                                </td>
                                                                                            )
                                                                                        }) : null
                                                                                        :
                                                                                        <td >

                                                                                        </td>
                                                                                    }
                                                                                </td>
                                                                            </td>
                                                                            <td>
                                                                                {
                                                                                    <span id={`${element.id}-purpose`}>{element.purpose}</span>
                                                                                }
                                                                            </td>
                                                                            <td>
                                                                                {
                                                                                    <span id={`${element.id}-endTreatment`}>{element.endTreatment}</span>
                                                                                }
                                                                            </td>
                                                                            <td>
                                                                                {
                                                                                    <div id={`${element.id}-other`}>
                                                                                        <span>{element.other}</span>

                                                                                    </div>

                                                                                }
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                })
                                                            }
                                                        </table>
                                                    </div>
                                                </Card>
                                            </Collapse>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default ListOfMedicines;