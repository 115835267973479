import React from 'react';

import {
    Button
} from 'reactstrap';

import Isvg from 'react-inlinesvg';
import bankidIcon from '../assets/svg/bankid.svg';
import { Player, Controls } from "@lottiefiles/react-lottie-player";
import QRCode from "react-qr-code";
import { API_ENDPOINT } from '../constants'
function parseJSON(response) {
    return response.json().then(json => {
        return {
            result: json,
            status: response.status
        }
    })
}
/**
* BankdIDIdentity 
* @author   Milan Stanojevic
*/
class BankdIDIdentity extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            changeQrCode: true
        }
    }


    componentDidMount() {



        this.interval = setInterval(() => {
            this.checkBankIdIdentity()
            const imageElement = document.getElementById('qr-image');
            if (imageElement && this.state.changeQrCode) {
                this.setState({
                    changeQrCode: false
                }, () => {
                    this.changeQrCod()
                })
            }
        }, 2000);
    }

    checkBankIdIdentity = () => {
        if (this.props.bankIDkey)
            fetch(API_ENDPOINT + '/users/bankid/v5/check/identity', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json'
                },
                body: JSON.stringify({ key: this.props.bankIDkey, conversation: this.props.conversation })
            }).then(res => res.json()).then((result) => {
                if (result && result.status && result.status === 'Finished') {
                    this.props.handleBankIdIdentity(this.props.bankIDkey)
                    clearInterval(this.interval)
                }
            })
    }

    changeQrCod = () => {
        const qrCodeLink = this.props.qrCodeLink;
        const imageElement = document.getElementById('qr-image');
        let t = 0;

        this.qrCodeInterval = setInterval(() => {
            // QR code doesn't work after repeating 14 times so that is the limit for bankid v5 for now
            if (t === 14) {
                clearInterval(this.qrCodeInterval);
                this.props.generateBankIdRedirectUrl();
                // t = 0;
                // this.setState({
                //     changeQrCode: true
                // })
            }

            imageElement.setAttribute('src', qrCodeLink + '&t=' + t);
            t++;
        }, 2000);
    }
    componentDidUpdate(prevProps) {
        if (this.props.qrCodeLink !== prevProps.qrCodeLink) {
            this.setState({
                changeQrCode: true
            })
        }
    }
    componentWillUnmount() {
        this.componentUnmounted = true;
        if (this.interval) {
            clearInterval(this.interval)
        }
        if (this.qrCodeInterval) {
            clearInterval(this.qrCodeInterval)
        }
    }

    render() {
        return (
            <div className="bank-id-identity">
                {!this.props.loading && !this.props.redirectUrl ?

                    <Button disabled={this.props.disabled} color="primary" onClick={() => {
                        this.props.handleLoading(true);
                        this.props.generateBankIdRedirectUrl();
                    }}><Isvg src={bankidIcon} />{'Log in with BankID'.translate(this.props.lang)}</Button>
                    :
                    null
                }

                {this.props.loading ?

                    <Player
                        autoplay={true}
                        loop={true}
                        renderer={'svg'}
                        src="/lf30_editor_l5cxzdyf.json"
                        style={{ height: typeof window != 'undefined' && window.innerWidth < 768 ? "64px" : "128px", width: typeof window != 'undefined' && window.innerWidth < 768 ? "64px" : "128px" }}
                    ></Player>
                    :
                    null
                }


                {/*this.props.redirectUrl*/ this.props.qrCodeLink ?
                    <div className='bank-id-checkout'>
                        <div  >
                            {/* <QRCode
                                size={256}
                                style={{ height: "auto", maxWidth: 200, width: 200 }}
                                value={this.props.redirectUrl.replace(/.*text=/, '')}
                                viewBox={`0 0 256 256`}
                            /> */}
                            <img id='qr-image' src={this.props.qrCodeLink} style={{ height: 'auto', maxWidth: 200, width: 200 }} />
                        </div>
                        <p>{"Scan QR code if bankid app doesn't shows up".translate(this.props.lang)}</p>
                        <a href={this.props.redirectUrl} className='btn btn-primary'>{"Open BankID on this device".translate(this.props.lang)}</a>
                    </div>
                    // <iframe ref={(node) => this.bankIdIframe = node} onLoad={() => {
                    //     this.props.handleLoading(null);
                    //     try {
                    //         if (this.bankIdIframe && this.bankIdIframe.contentWindow && this.bankIdIframe.contentWindow.location && this.bankIdIframe.contentWindow.location.href && this.bankIdIframe.contentWindow.location.href.indexOf('bankid-identity/v2/') !== -1) {
                    //             if (!this.loggingInProgress) {
                    //                 this.loggingInProgress = true;
                    //                 let key = this.bankIdIframe.contentWindow.location.href.split('bankid-identity/v5/').pop().split('?')[0];
                    //                 this.props.handleBankIdIdentity(key);
                    //             }
                    //         }
                    //     } catch (e) { }
                    // }} src={this.props.redirectUrl} style={this.props.loading ? { opacity: 0 } : { width: '100%', height: 350, border: 0 }} />
                    :
                    null
                }

            </div>
        )
    }

}

export default BankdIDIdentity;