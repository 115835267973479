import React, { Component } from 'react';
import PageWithLayout from '../../containers/page';
import { Button, Col, Container, Row } from 'reactstrap';
import { API_ENDPOINT } from '../../constants';
import EconnectExternalCasesModal from '../../components/e-connect/EconnectExternalCasesModal';

export class EconnectExternalPage extends Component {
    constructor(props) {
        super(props)

        this.state = {
            num: 0,
            clinicGroupSettings: null,
            createNewCaseModal: false,
            languages: [
                {
                    label: 'EN',
                    value: 'en'
                },
                {
                    label: 'SE',
                    value: 'se'
                }
            ],
        }
    }

    componentDidMount() {
        this.getClinicGroupSettings();
    }

    componentDidUpdate(prevProps, prevState) {
        if ((!prevProps?.[0]?.match?.params?.alias && this.props?.[0]?.match?.params?.alias) || (prevProps?.[0]?.match?.params?.alias !== this.props?.[0]?.match?.params?.alias)) {
            this.getClinicGroupSettings();
        }
    }

    getClinicGroupSettings = () => {
        this.setState({ clinicGroupSettings: null }, async () => {
            if (!this.props?.[0]?.match?.params?.alias) return;
            const response = await fetch(API_ENDPOINT + '/data/clinic-group-settings/' + this.props?.[0]?.match?.params?.alias + '?alias=true');
            if (!response || !response.ok) return;
            const result = await response.json();
            if (!result || result.error || !result.clinicGroupSettings) return;
            this.setState({ clinicGroupSettings: result.clinicGroupSettings });
        })
    }

    render() {
        let privacyPolicyLink;
        if (this.state.clinicGroupSettings?.privacyPolicy?.file && this.props?.[0]?.match?.params?.alias) {
            privacyPolicyLink = `/${this.props?.[0]?.match?.params?.alias}/privacy-policy`
        };
        let termsOfUseLink;
        if (this.state.clinicGroupSettings?.termsOfUse?.file && this.props?.[0]?.match?.params?.alias) {
            termsOfUseLink = `/${this.props?.[0]?.match?.params?.alias}/terms-of-use`
        }

        return (
            <div className='shame' style={{ backgroundColor: 'white' }}>
                <Container fluid style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100vh' }}>
                    <Row className='ext-ec-logo-row'>
                        <Col className='ext-ec-logo-col'>
                            {this.state.clinicGroupSettings?.logo ? <img onClick={() => {
                                if (this.props?.[0]?.match?.params?.alias) {
                                    const alias = this.props?.[0]?.match?.params?.alias;
                                    this.props?.[0]?.history?.push?.(`/${alias}`);
                                }
                            }} style={this.props?.[0]?.match?.params?.alias ? { cursor: 'pointer' } : {}} src={API_ENDPOINT + this.state.clinicGroupSettings?.logo} alt='logo' /> : null}
                            {!this.props.secondaryLanguageDisabled && this.state.languages?.length && this.state.clinicGroupSettings ? <div className="ec-ext-lang-select-container">
                                <select
                                    id='languages'
                                    value={this.props.lang}
                                    onChange={(e) => {
                                        const val = e.target.value;
                                        this.props.setLang?.(val);
                                    }}
                                >
                                    {this.state.languages.map(obj => <option value={obj.value} key={obj.value}>{obj.label?.translate(this.props.lang)}</option>)}
                                </select>
                            </div> : null}
                        </Col>
                    </Row>

                    <div style={{ flex: 1 }}>
                        <Row className='ext-ec-title-row'>
                            <Col className='ext-ec-title-col'>
                                <h1>{(this.state.clinicGroupSettings?.eConnect && !this.state.clinicGroupSettings?.eConnectTempDisabled && !this.state.clinicGroupSettings?.eConnectConfig?.externalDisabled && this.state.clinicGroupSettings?.eConnectConfig?.enabledTitle) ? this.state.clinicGroupSettings?.eConnectConfig?.enabledTitle?.[this.props.lang] : this.state.clinicGroupSettings?.eConnectConfig?.disabledTitle?.[this.props.lang] ?? 'Curoflow e-Connect'.translate(this.props.lang)}</h1>
                            </Col>
                        </Row>

                        {this.state.clinicGroupSettings ? <Row className='ext-ec-text-row'>
                            <Col className='ext-ec-text-col'>
                                <p style={{ whiteSpace: 'pre-line' }}>
                                    {(this.state.clinicGroupSettings?.eConnect && !this.state.clinicGroupSettings?.eConnectTempDisabled && !this.state.clinicGroupSettings?.eConnectConfig?.externalDisabled && this.state.clinicGroupSettings?.eConnectConfig?.enabledText) ? this.state.clinicGroupSettings?.eConnectConfig?.enabledText?.[this.props.lang] : this.state.clinicGroupSettings?.eConnectConfig?.disabledText?.[this.props.lang] ?? (
                                        !this.state.clinicGroupSettings?.eConnect || this.state.clinicGroupSettings?.eConnectTempDisabled ? (
                                            this.props.lang === 'en' ? (
                                                'e-Connect is not activated and we can not receive any referrals here.'
                                            ) : (
                                                'e-Connect är inte aktiverad och vi kan inte ta emot remisser här.'
                                            )
                                        ) : (
                                            'This clinic is part of the Curoflow e-Connect platform and as such, it can receive external referral cases. If your clinic is not connected to Curoflow or Curoflow e-Connect, you can still submit your referral case, by clicking on the Create referral button bellow. Please note that by submitting your case, you are responsible for the correctness of the information in the submitted case, and that by submitting the case this way you will not become a part of Curoflow e-Connect, nor will you, in any form or way, be notified by Curoflow about other actions that will be taken on this case. Receiving clinic can choose to contact you about the case progress via the information that you provide, but is not obligated to do so.'.translate(this.props.lang)
                                        )
                                    )}
                                </p>
                                {/* <p>{!this.state.clinicGroupSettings?.eConnect || this.state.clinicGroupSettings?.eConnectTempDisabled ? (
                                'This clinic is not connected to Curoflow e-Connect, which is why You cannot submit your referral to this clinic at this time.'.translate(this.props.lang)
                            ) : this.state.clinicGroupSettings?.eConnectExternalText ? (
                                this.state.clinicGroupSettings?.eConnectExternalText
                            ) : (
                                'This clinic is part of the Curoflow e-Connect platform and as such, it can receive external referral cases. If your clinic is not connected to Curoflow or Curoflow e-Connect, you can still submit your referral case, by clicking on the Create New Case button bellow. Please note that by submitting your case, you are responsible for the correctness of the information in the submitted case, and that by submitting the case this way you will not become a part of Curoflow e-Connect, nor will you, in any form or way, be notified by Curoflow about other actions that will be taken on this case. Receiving clinic can choose to contact you about the case progress via the information that you provide, but is not obligated to do so.'.translate(this.props.lang)
                            )}</p> */}
                            </Col>
                        </Row> : null}

                        {this.state.clinicGroupSettings?.eConnect && !this.state.clinicGroupSettings?.eConnectTempDisabled && !this.state.clinicGroupSettings?.eConnectConfig?.externalDisabled ? <Row className='ext-ec-button-row'>
                            <Col className='ext-ec-button-col'>
                                <Button
                                    color='primary'
                                    size='lg'
                                    onClick={() => {
                                        this.setState({ createNewCaseModal: true })
                                    }}
                                >
                                    {'Create Referral'.translate(this.props.lang)}
                                </Button>
                            </Col>
                        </Row> : null}
                    </div>



                    {privacyPolicyLink || termsOfUseLink ? <Row className='ext-ec-footer'>
                        {privacyPolicyLink ? <a href={privacyPolicyLink} target='_blank'>
                            {'Privacy policy'.translate(this.props.lang)}
                        </a> : null}
                        {termsOfUseLink ? <a href={termsOfUseLink} target='_blank'>
                            {'Terms of use'.translate(this.props.lang)}
                        </a> : null}
                    </Row> : null}
                </Container>

                {
                    this.state.createNewCaseModal ?
                        <EconnectExternalCasesModal
                            clinicGroupId={this.state.clinicGroupSettings?.id}
                            isOpen={this.state.createNewCaseModal}
                            lang={this.props.lang}
                            alias={this.props?.[0]?.match?.params?.alias}
                            closeModal={() => { this.setState({ createNewCaseModal: false }) }}
                            uData={this.props.uData}
                            hasBankId={this.state.clinicGroupSettings?.bankIdAuthentication}
                            hasNetId={this.state.clinicGroupSettings?.netIdAuthentication}
                            country={this.props.country}
                            skipBankId={!this.state.clinicGroupSettings?.eConnectConfig?.externalAuthBankid}
                            // netid option disabled for now waiting for test card for more testing
                            skipNetId={true}
                            // skipNetId={!this.state.clinicGroupSettings?.eConnectConfig?.externalAuthNetid}
                            dateFormat={this.props.dateFormat}
                        />
                        :
                        null
                }
            </div>
        )
    }
}

export default PageWithLayout(EconnectExternalPage);