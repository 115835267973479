import React, { Component } from 'react';
import Link from '../components/link';

import Isvg from 'react-inlinesvg';
import Page from '../containers/page';
import {
    Container,
    Row,
    Col,
    Modal, ModalHeader, ModalBody, ModalFooter, Button
} from 'reactstrap';

import ErrorModal from '../components/errorModal';
import HTML from '../components/forms/fields/html';
import moment from 'moment';

import { API_ENDPOINT } from '../constants';
/**
* Manager page 
* @author   Stefan Pantic
*/
class ManagerID extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    /**
    * Instant login for manager
    * @author   Stefan Pantic
    * @Objectparam    {String} key    verification key for login
    */
    componentDidMount() {
        let key = this.props[0].match.params.key;

        fetch(API_ENDPOINT + '/confirmation/managerId/login', {
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify({ key })
        }).then(res => res.json()).then((result) => {
            if (result.error) {
                this.setState({
                    error: result.error
                })
            } else {
                // console.log(result)
                this.setState({
                    data: result
                })

            }
        });
    }

    /**
    * Manager conform payment
    * @author   Stefan Pantic
    * @Objectparam    {String} key    verification key for payment
    */
    confirmPayment = () => {
        let key = this.props[0].match.params.key;

        fetch(API_ENDPOINT + '/conformation/managerId/confirmpayment', {
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify({ key })
        }).then(res => res.json()).then((result) => {
            this.setState({
                conformation: false,
                disabled: true
            })
        });
    }

    /**
    * Manager refuse payment
    * @author   Stefan Pantic
    * @Objectparam    {String} key    verification key for payment
    */
    cancelPayment = () => {
        let key = this.props[0].match.params.key;

        fetch(API_ENDPOINT + '/conformation/managerId/cancelpayment', {
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify({ key })
        }).then(res => res.json()).then((result) => {
            this.setState({
                modal: false,
                disabled: true
            })
        });
    }


    render() {
        //console.log(this.state.data)
        return (
            <div>

                {this.state.data ?
                    <Container>
                        <div style={{padding: 50, background: '#fff'}}>
                            <div dangerouslySetInnerHTML={{ __html: this.state.data.referralContent }} onChange={() => { }}></div>
                            <Button color="success" style={{ marginRight: 20 }} onClick={() => this.setState({ conformation: this.state.data })} disabled={this.state.disabled}>Accept</Button>
                            <Button color="danger" onClick={() => this.setState({ modal: true })} disabled={this.state.disabled}>Cancel</Button>
                        </div>
                    </Container>

                    : null}

                {this.state.error ?

                    <ErrorModal lang={this.props.lang}
                        isOpen={this.state.error}
                        toggle={() => this.setState({ error: null })}

                    >
                        {this.state.error.translate(this.props.lang)}
                    </ErrorModal>

                    :
                    null
                }
                {this.state.modal ?
                    <Modal isOpen={this.state.modal} centered toggle={() => this.setState({ modal: !this.state.modal })} >
                        <ModalHeader>
                            <h4>{'Info'.translate(this.props.lang)}</h4>
                        </ModalHeader>
                        <ModalBody>

                            <div>
                                <p>I confirm that the patient <br />will receive care within national <br />the 90-day care guarantee in <br />sin hemregion. This is rejected <br />the payment obligation.</p>
                                <Button color="success" onClick={() => this.cancelPayment()}>Confirm</Button>
                            </div>

                        </ModalBody>

                    </Modal>

                    :
                    null
                }
                {this.state.conformation ?
                    <Modal isOpen={this.state.conformation} toggle={() => this.setState({ conformation: !this.state.conformation })} >
                        <ModalHeader>
                            <h4>{'Conformation'.translate(this.props.lang)}</h4>
                        </ModalHeader>
                        <ModalBody>
                            <p>Please confirm the payment.</p>
                            <Button color="success" onClick={() => this.confirmPayment()}>Confirm</Button>


                        </ModalBody>

                    </Modal>

                    :
                    null
                }
            </div>
        );
    }
}

export default ManagerID;