import React, { Component } from 'react';

import Select, { components } from 'react-select';

import { Link, Redirect } from 'react-router-dom';

import Isvg from 'react-inlinesvg';

import { API_ENDPOINT } from '../../../constants';

import GoToLocation from '../../../assets/svg/go-to-location.svg'

import direction from '../../../assets/svg/direction.svg'


import chroma from 'chroma-js';
const defaultValue = [
    {
        value: -1,
        label: 'None'
    },

]
const groupedOptions = [
    {
        value: 3,
        label: 'Option3'
    },
    {
        value: 4,
        label: 'Option4'
    }
]
const groupStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: '#E6EDF5'
};
const groupBadgeStyles = {
    backgroundColor: '#EBECF0',
    borderRadius: '1em',
    color: '#172B4D',
    display: 'inline-block',
    fontSize: 12,
    fontWeight: 'normal',
    lineHeight: '1',
    minWidth: 1,
    padding: '0.16666666666667em 0.5em',
    textAlign: 'center',
};
const NoOptionsMessage = props => {
    return (
        <div style={{ backgroundColor: 'white', padding: '0 15px', fontSize: 14, lineHeight: '30px', textAlign: 'center', color: '#afafaf' }}>{'NO OPTIONS'.translate(typeof window != 'undefined' ? window.lang : 'se')}</div>
    );
};



const colourStyles = {
    control: styles => ({ ...styles, backgroundColor: 'white', border: 'none', borderRadius: 10, height: 48, paddingLeft: 5, paddingRight: 0, fontSize: 15, fontWeight: 500, color: '#3A3F56' }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        const color = chroma("#E6EDF5");
        return {
            ...styles,
            border: 'none',
            outline: 'none',
            fontSize: 14,
            backgroundColor: isDisabled
                ? null
                : isSelected
                    ? "#568AC8"
                    : isFocused
                        ? color.alpha(0.1).css()
                        : null,
            color: isDisabled
                ? '#ccc'
                : isSelected
                    ? 'white'
                        ? "#E6EDF5"
                        : 'black'
                    : data.color,
            cursor: isDisabled ? 'not-allowed' : 'pointer',

            ':active': {
                ...styles[':active'],
                backgroundColor:
                    !isDisabled && (isSelected ? "#568AC8" : "#E6EDF5"),
            },
            ':hover': {
                ...styles[':hover'],
                backgroundColor:
                    !isDisabled && (isSelected ? "#568AC8" : "#E6EDF5"),
            },

        };
    },
    // menu: (provided, state) => {
    //     console.log(provided)
    //     console.log(state)
    //     let obj = {}
    //     if(state && state.selectProps && state.selectProps.mapHeight){
    //         obj.maxHeight = state.selectProps.mapHeight - 73;
    //         obj.height = state.selectProps.mapHeight - 73;

    //     }
    //     return{
    //         ...provided,
    //         ...obj
    //       }
    // },


    input: styles => ({ ...styles }),
    placeholder: styles => ({ ...styles }),
    singleValue: (styles, { data }) => ({ ...styles }),
};
const CustomOption = ({ children, ...props }) => {
    return (
        <components.Option {...props} className={props.isSelected ? "option-row option-row-selected" : "option-row"}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div className="landing-map-search-wrap">
                    <div className="landing-map-search-title">
                        <div className="landing-map-search-logo">

                            {
                                props && props.data && props.data.image ?
                                    <img src={API_ENDPOINT + props.data.image} />
                                    :
                                    <div className="landing-map-search-logo-box"></div>

                            }
                        </div>

                    </div>
                    <div>
                        <div className="landing-map-search-label">

                            {
                                props && props.data && props.data.label ?
                                    <p>{props.data.label}</p>
                                    :
                                    null
                            }
                        </div>

                        <div className="landing-map-search-address">

                            {
                                props && props.data && props.data.address ?
                                    <p>
                                        {props.data.address && props.data.address[0] ? <>{props.data.address[0]}<br /></> : null}
                                        {props.data.address && props.data.address[1] ? <>{props.data.address[1]}<br /></> : null}
                                        {props.data.address && props.data.address[2] ? <>{props.data.address[2]}<br /></> : null}
                                    </p>
                                    :
                                    null
                            }
                        </div>
                    </div>


                </div>
                <div className="go-to-icon" onClick={() => props.data.history.push(props.data.link)}>
                    <Isvg src={direction} />
                </div>
            </div>

        </components.Option>
    );
};

class SelectWithSearch extends Component {
    constructor(props) {
        super(props);

        this.state = {
            list: [],
            
        };
    }

    componentDidMount() {
      
    }
   



    render() {
        const resultLimit = 600;
        const maxLength = 10;
        let i = 0
   
        return (
            <div className="select-with-search-wrap" style={{ width: this.props.width ? this.props.width : '100%' }}>
                {
                    this.props.label ?
                        <label>{this.props.label}</label>
                        :
                        null
                }
                {
                    typeof window != 'undefined' && window.innerWidth >= 768 ?
                        <Select
                            defaultValue={defaultValue[0]}
                            options={this.props.list && this.props.list.map((item, idx) => {
                                let obj = {
                                    label: item.name,
                                    value: item.value,
                                    image: item.logo,
                                    address: item.landingConfig && item.landingConfig.address,
                                    link: item.link,
                                    history: this.props.history
                                }

                                return (
                                    obj
                                )
                            })}

                            menuIsOpen={true}
                            mapHeight={this.props.mapHeight}
                            components={{ NoOptionsMessage, Option: CustomOption, DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                            // isClearable={true}
                            styles={colourStyles}
                            className="select-with-search"
                            onChange={(e) => this.props.onChange(e)}
                            maxOptions={2}
                            value={
                                this.props.value ? {
                                    label: this.props.value.name,
                                    value: this.props.value.value
                                }
                                    :
                                    defaultValue[0]
                            }
                        />
                        :
                        <Select
                            defaultValue={defaultValue[0]}
                            options={this.props.list && this.props.list.map((item, idx) => {
                                let obj = {
                                    label: item.name,
                                    value: item.value,
                                    image: item.logo,
                                    address: item.landingConfig && item.landingConfig.address,
                                    link: item.link,
                                    history: this.props.history
                                }

                                return (
                                    obj
                                )
                            })}

                            components={{ NoOptionsMessage, Option: CustomOption, DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                            // isClearable={true}
                            styles={colourStyles}
                            className="select-with-search"
                            onChange={(e) => this.props.onChange(e)}
                            maxOptions={2}
                            value={
                                this.props.value ? {
                                    label: this.props.value.name,
                                    value: this.props.value.value
                                }
                                    :
                                    defaultValue[0]
                            }
                        />
                }

            </div>
        )

    }
}

export default SelectWithSearch;