import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';

import Isvg from 'react-inlinesvg';

import { FormGroup, Label, Input, FormFeedback, FormText } from 'reactstrap';

import hide from '../../../assets/svg/hide.svg';
import show from '../../../assets/svg/show.svg';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

/**
* Phone field
* @author   Milan Stanojevic
*/
class Text extends Component {
    constructor(props) {
        super(props);

        this.state = {
            focus: false,

        };
    }





    render() {
        return (
            <FormGroup >
                {this.props.label ? <Label size="sm" className={this.props.error ? "required" : ""}>{this.props.label}{this.props.haveValidation ? <span className="required-label-span">*</span> : null}</Label> : null}
                {/* <div className={this.state.focus && this.props.error ? 'required-error-phone-hover required-error-phone' : this.props.error && !this.state.focus ? 'required-error-phone' :  ''}> */}
                <Input size="sm" disabled={this.props.disabled} name={this.props.name} invalid={this.props.error ? true : false} onChange={(e) => {
                    let value = e.target.value;
                    if (value.indexOf('https://') == -1) {
                        value = 'https://';
                    }else{
                        value = e.target.value
                    }

                    this.props.onChange(value);

                }} value={this.props.value} placeholder={this.props.placeholder}></Input>
                {this.props.error ? <FormFeedback >{this.props.error}</FormFeedback> : null}
            </FormGroup>
        );
    }
}

export default Text;