import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';

import Isvg from 'react-inlinesvg';

import { FormGroup, Label, Input, FormFeedback, FormText } from 'reactstrap';

import hide from '../../../assets/svg/hide.svg';
import show from '../../../assets/svg/show.svg';
import striptags from 'striptags';
import infoIcon from "../../../assets/svg/link-new-tab.svg";


/**
* Generate custom fields for forms
* @author   Milan Stanojevic
*/
class WebBookingOffset extends Component {
    constructor(props) {
        super(props);

        this.state = {
            minutes: [0],
            hours: [0],
            value: {
                minutes: 15,
                hours: 0,
                value: 15
            }
        };
    }

    formatValue = (val) => {
        if (val && Number(val) > 15) {
            let hours = Math.floor(Number(val) / 60);
            let minutes = Number(val) - hours * 60;
            return {
                minutes,
                hours,
                value: Number(val)
            }
        } else {
            return {
                minutes: 15,
                hours: 0,
                value: 15
            }
        }
    }
    setValue = (val) => {
        let value = {
            hours: val?.hours ?? 0,
            minutes: val?.minutes ?? 0,
        }
        value.value = value.hours * 60 + value.minutes;

        if (value.value < 15) {
            value = {
                minutes: 15,
                hours: 0,
                value: 15
            }
        }

        this.setState({ value }, () => {
            this.props?.onChange(value.value)
        })
    }

    componentDidMount() {
        let minutes = [], hours = [];
        for (let i = 0; i <= 47; i++) {
            hours.push(i);
        }
        for (let i = 0; i <= 55; i += 5) {
            minutes.push(i);
        }
        this.setState({ minutes, hours })

        if (this.props.value) {
            let value = this.formatValue(this.props.value);
            if (JSON.stringify(value) !== JSON.stringify(this.state.value))
                this.setState({ value })
        }
    }
    checkDifference = (val1, val2) => {
        let difference = false;
        if (val1?.minutes !== val2?.minutes) difference = true;
        if (val1?.hours !== val2?.hours) difference = true;
        if (val1?.value !== val2?.value) difference = true;

        return difference;
    }
    componentDidUpdate(prevProps) {
        if (JSON.stringify(prevProps.value) !== JSON.stringify(this.props.value)) {
            let value = this.formatValue(this.props.value);
            if (this.checkDifference(value, this.state.value)) {
                this.setState({ value })
            }
        }
    }



    render() {
        const hours = this.state.hours ?? [];
        const minutes = this.state.minutes ?? [];

        let style = {};
        if (this.props.disabledBorderBottom) style.borderBottomWidth = 0;
        if (this.props.disabledBorderTop) style.borderTopWidth = 0;

        return (
            <FormGroup className='webbooking-offset-wrap' style={style}>
                <div>
                    <Label >
                        {'The first offered appointment time for Web booking'.translate(this.props.lang)}
                    </Label>
                    <div className='input-wrap'>
                        {'Current time'.translate(this.props.lang)} +
                        <div className='input-field-wrap'>
                            <Input disabled={this.props.disabled} type='select' value={this.state.value?.hours} onChange={(e) => {
                                let val = e.target.value;
                                let value = this.state.value;
                                value.hours = Number(val);
                                this.setValue(value);
                            }}>
                                {
                                    hours.map((item, idx) => {
                                        return (
                                            <option value={item}>{item}</option>
                                        )
                                    })
                                }
                            </Input>
                            {'hours'.translate(this.props.lang)}
                        </div>
                        <div className='input-field-wrap'>
                            <Input disabled={this.props.disabled} type='select' value={this.state.value?.minutes} onChange={(e) => {
                                let val = e.target.value;
                                let value = this.state.value;
                                value.minutes = Number(val);
                                this.setValue(value);
                            }}>
                                {
                                    minutes.filter(item => Number(this.state.value?.hours) > 0 || (Number(this.state.value?.hours) === 0 && Number(item) >= 15)).map((item, idx) => {
                                        return (
                                            <option value={item}>{item}</option>
                                        )
                                    })
                                }
                            </Input>
                            {'minutes'.translate(this.props.lang)}
                        </div>
                    </div>
                </div>
                {
                    this.props.labelInfo ?
                        <div className='info'>
                            <Isvg src={infoIcon} />
                            <div className="info-text">{this.props.labelInfo}</div>
                        </div>
                        :
                        null
                }


            </FormGroup >
        );
    }
}

export default WebBookingOffset;