import React, { Component } from 'react';
import Page from '../../containers/page';

import {
    Container,
    Row,
    Col,
    Modal, ModalHeader, ModalBody, ModalFooter, Button, FormGroup, Input, Label, Table
} from 'reactstrap';

import { Link } from 'react-router-dom';
import Isvg from 'react-inlinesvg'

import FormBuilder from '../../components/forms/modalFormBuilder';
import { required } from '../../components/forms/validation';
import ListBuilder from '../../components/listBuilder';
import DeleteModal from '../../components/deleteModal';
import ErrorModal from '../../components/errorModal';
import { getSearchParams, generateSearchLink, generateSearchLinkMultiple } from '../../helpers/linkHelper';
import { API_ENDPOINT } from '../../constants';
import { formValueSelector, change } from 'redux-form';  // ES6
import { connect } from 'react-redux';
import ReferralClinics from '../../components/referralClinics';
import moment from 'moment';
import PrintHelper from '../../components/printHelper';
import viewIcon from '../../assets/svg/eye.svg';
import billInfoIcon from '../../assets/svg/bill_info.svg';
import QuestionMark from '../../assets/svg/question.svg'
import xIcon from '../../assets/svg/x.svg';
import billGreenIcon from '../../assets/svg/bill_green.svg';
import billRedIcon from '../../assets/svg/bill_red.svg';
import transferIcon from '../../assets/svg/transfer-icon.svg';
import openChat from '../../assets/svg/open_chat.svg';
import garbageIcon from '../../assets/svg/garbage.svg';

import ChatIcon from '../../assets/svg/chat-icon.svg'
import Checkbox from '../../components/forms/fields/checkbox';
import ListCheckBox from '../../components/forms/fields/listCheckboxFlex';
import DatePicker from '../../components/forms/fields/datePicker';
import TimePicker from '../../components/forms/fields/timepicker';
import warrning from '../../assets/svg/warrning.svg';
import infoIcon from "../../assets/svg/link-new-tab.svg";
import InfoModal from '../../components/infoModal';

// import FormPreview from '../../components/formPreview';
import FormPreview from '../forms/previewAnsweredForm';


import ReferralContent from '../../components/referralContent';

import striptags from 'striptags';

import TimePickerV2 from '../../components/forms/fields/timepicker_v2';
import { isStringAValidNumber } from '../../components/TwoFactorAuthModal';
import ConsultationModal from '../../components/e-connect/ConsultationModal';


function pad(n, width, z) {
    z = z || '0';
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
}

/**
* List of recieved E-self-referrals
* @author   Milan Stanojevic
*/
class ReceivedReferrals extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props.initialData,
            form: false,
            useState: false,
            params: {
                entries: 10,
                page: 0
            },
            items: [],
            groups: [],
            subgroups: [],
            total: 0,
            loading: true,
            values: [],
            doctorsList: [],
            changeButton: false,
            activeRevisitForm: 1,
            fields: [],
            selectedDoctor: -1

        };
        this.state.min = new Date();
        this.state.min.setHours(8, 30, 0)
        this.state.max = new Date();
        this.state.max.setHours(20, 30, 0)
    }


    get = () => {
        this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));

        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0, sortField: 'timestamp', sortType: -1, clinic: this.props.selectedClinic })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

        for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
            this.props.loadDataWithQuery[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, {
                entries: 10, page: 0, sortField: 'timestamp', sortType: -1, clinic: this.props.selectedClinic, group: this.props.selectedGroup, recipientUser: this.props.uData._id, professions: this.state.values,
                controlBoking: this.state.controlBoking ? this.state.controlBoking : false,
                webBoking: this.state.webBoking ? this.state.webBoking : false,
                dropInBoking: this.state.dropInBoking ? this.state.dropInBoking : false,
                clinicBoking: this.state.clinicBoking ? this.state.clinicBoking : false,

                controlScheduled: this.state.controlScheduled ? this.state.controlScheduled : false,
                cancledByClinic: this.state.cancledByClinic ? this.state.cancledByClinic : false,
                rejectedByPatient: this.state.rejectedByPatient ? this.state.rejectedByPatient : false,
                closed: this.state.closed ? this.state.closed : false,
                scheduled: this.state.scheduled ? this.state.scheduled : false,
                notBooked: this.state.notBooked ? this.state.notBooked : false,
                disallowed: this.state.disallowed ? this.state.disallowed : false,
                waiting: this.state.waiting ? this.state.waiting : false,
                inProgress: this.state.inProgress ? this.state.inProgress : false,
                waitingForApproval: this.state.waitingForApproval ? this.state.waitingForApproval : false,
                waitingForPayment: this.state.waitingForPayment ? this.state.waitingForPayment : false,
                allowed: this.state.allowed ? this.state.allowed : false,
                columnVisibility: 'all-cases'
            })).then((data) => {
                let dataToSet = { ...data }
                if (dataToSet.page || dataToSet.items || dataToSet.total) {
                    dataToSet.loading = null;
                    // console.log(dataToSet.page || dataToSet.items || dataToSet.total)
                    let checkFilter = '';
                    if (dataToSet.resultFilter) {
                        checkFilter = String(dataToSet.resultFilter)
                        delete dataToSet.resultFilter;
                    }
                    let search = this.props[0].location.search;
                    let serachParams = getSearchParams(search);
                    let searchParamsFilter = '';
                    if (serachParams.filter) {
                        searchParamsFilter = serachParams.filter;
                    }
                    if (searchParamsFilter != checkFilter) {
                        delete dataToSet.page;
                        delete dataToSet.items;
                        delete dataToSet.total;
                        delete dataToSet.loading;
                    }
                }
                this.setState({
                    ...dataToSet,
                    // loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

    }

    componentDidMount() {
        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }

        if (this.props[0].location && this.props[0].location.state && this.props[0].location.state.messageModal) {
            this.setState({
                caseFilter: this.props[0].location.state.messageModal
            })
        }

        this.get();


        this.interval = setInterval(() => {
            this.get();
        }, 60 * 1000);

        if (this.props.uData && this.props.socket && !this.state._registerEvents) {
            this.setState({
                _registerEvents: true
            }, () => {
                this.registerEvents();


            })
        } else {
            this.socketInterval = setInterval(() => {
                if (this.props.uData && this.props.socket && !this.state._registerEvents) {
                    if (this.socketInterval) {
                        clearInterval(this.socketInterval)
                    }

                    this.setState({
                        _registerEvents: true
                    }, () => {
                        this.registerEvents();


                    })
                }

            }, 150);
        }



    }
    registerEvents = async () => {

        this.props.socket.on('newEventCreated', (data) => {
            if (data?.error && this.scheduleControlApiCalled) {
                this.setState({ error: data.error.translate(this.props.lang) })
            }
            setTimeout(() => {
                this.get()
            }, 1000);
        })


    }
    componentWillUnmount() {
        if (this.interval) {
            clearInterval(this.interval)
        }
        if (this.props.socket) {
            this.props.socket.removeListener('newEventCreated');
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps[0].location.search != this.props[0].location.search) {
            this.setState({
                loading: true
            }, () => {
                this.get();

            })
        }

        if (prevProps.selectedClinic != this.props.selectedClinic) {
            this.updateMultipleParams([{ name: 'page', value: '0' }, { name: 'entries', value: '10' }])
            this.get();
        }
        if (prevState.referral != this.state.referral && !this.state.referral && this.state.activeRevisitForm !== 1) {
            this.setState({ activeRevisitForm: 1 })
        }
    }

    updateSort = (field, type) => {
        if (this.state.useState) {
            let state = this.state.params;
            state.sortField = field;
            state.sortType = type;
            this.setState({
                params: state
            })
        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0, sortField: 'timestamp', sortType: -1 }, [{ name: 'sortField', value: field }, { name: 'sortType', value: type }], false));

        }
    }

    updateParams = (name, value, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };
                obj[name] = value;
                this.setState({ params: obj });
            } else {
                let obj = this.state.params;
                obj[name] = value;
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(generateSearchLink(this.props[0].location.search, { entries: 10, page: 0, sortField: 'timestamp', sortType: -1 }, name, value, restart));
        }
    }
    updateMultipleParams = (fields, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj });

            } else {
                let obj = this.state.params;
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0, sortField: 'timestamp', sortType: -1 }, fields, restart));
        }
    }
    generateSections = (itemsArr) => {
        let sections = {};
        let items = [{ type: 0 }, ...itemsArr];
        let lastSectionId = -1;
        for (let i = 0; i < items.length; i++) {
            if (items[i].type == 0) {
                lastSectionId++;
                sections[lastSectionId] = {
                    name: items[i].name,
                    displayOnReferral: false,
                    questions: [],
                    conditions: items[i].conditions ? items[i].conditions : [],
                    displayOnReferral: items[i].displayOnReferral,
                    fontStyle: items[i].fontStyle,
                    fontSize: items[i].fontSize,
                    fontWeight: items[i].fontWeight,

                }
            }
            if (lastSectionId != -1 && items[i].type != 0) {


                let wikiLinks = [];

                items[i].wikiLinks = wikiLinks;


                if (items[i].type == 1) {
                    let displayOnReferral = false;
                    if (items[i].answers) {
                        for (let j = 0; j < items[i].answers.length; j++) {
                            if (items[i].answers[j].text) {
                                displayOnReferral = true;
                            }
                        }
                    }

                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        answers: items[i].answers,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        displayOnReferral: displayOnReferral,
                        wikiLinks: items[i].wikiLinks,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                    });

                } else if (items[i].type == 2) {
                    let displayOnReferral = false;
                    if (items[i].answers) {
                        for (let j = 0; j < items[i].answers.length; j++) {

                            if (items[i].answers[j].text) {
                                displayOnReferral = true;
                            }
                        }
                    }
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        answers: items[i].answers,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        answerPrefix: items[i].answerPrefix,
                        userInput: items[i].userInput,
                        displayOnReferral: displayOnReferral || items[i].answerPrefix ? true : false,
                        wikiLinks: items[i].wikiLinks,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,

                    })
                } else if (items[i].type == 3) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        answerPrefix: items[i].answerPrefix,
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                    })

                } else if (items[i].type == 4) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        text: items[i].text,
                        userInput: items[i].userInput,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                    })
                } else if (items[i].type == 15) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        text: items[i].text,
                        userInput: items[i].userInput,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                    })

                } else if (items[i].type == 5) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                        score: items[i].score
                    })

                } else if (items[i].type == 6) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                        score: items[i].score
                    })

                } else if (items[i].type == 7) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                    })

                } else if (items[i].type == 8) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                    })

                } else if (items[i].type == 9) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                    })

                } else if (items[i].type == 10) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        userInput: items[i].userInput,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                    })
                } else if (items[i].type == 12) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                        score: items[i].score
                    })
                } else if (items[i].type == 14) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                        score: items[i].score
                    })

                } else if (items[i].type == 13) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                        score: items[i].score
                    })

                } else if (items[i].type == 16) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                        blocks: items[i].blocks,
                    })

                } else if (items[i].type == 17) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                        blocks: items[i].blocks,

                    })

                }
            }
        }

        return Object.values(sections);
    }

    /**
    * Allow referral if status is waiting-for-approval
    * @author   Milan Stanojevic
    */
    allowReferral = () => {
        this.setState({
            loading: true
        }, () => {
            fetch(API_ENDPOINT + '/referrals/allow/' + this.state.referral._id, {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify({})
            }).then(res => res.json()).then((result) => {
                this.get();

                if (result.error) {
                    this.setState({
                        error: result.error?.translate(this.props.lang),
                        signinModal: false,
                        allowForm: false,
                        disallowForm: false,
                        returnForm: false,
                        scheduleForm: false,
                        referral: result.referral ? result.referral : null
                    })
                } else {
                    this.setState({
                        signinModal: false,
                        allowForm: false,
                        referral: null,
                        disallowForm: false,
                        returnForm: false,
                        scheduleForm: false,
                        scheduleReferral: true
                    })
                }
            })
        })
    }
    /**
    * Disallow referral if status is waiting-for-approval
    * @author   Milan Stanojevic
    */
    disallowReferral = (data) => {
        this.setState({
            loading: true
        }, () => {
            fetch(API_ENDPOINT + '/referrals/disallow/' + this.state.referral._id, {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify(data)
            }).then(res => res.json()).then((result) => {
                this.get();
                if (result.error) {
                    this.setState({
                        error: result.error?.translate(this.props.lang),
                        referral: null,
                        disallowForm: false,
                        returnForm: false,
                        scheduleForm: false,
                    })
                } else {
                    this.setState({
                        referral: null,
                        disallowForm: false,
                        returnForm: false,
                        scheduleForm: false
                    })
                }

            })
        })
    }

    returnForUpdate = (data) => {
        this.setState({
            loading: true
        }, () => {
            fetch(API_ENDPOINT + '/referrals/return/' + this.state.referral._id, {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify(data)
            }).then(res => res.json()).then((result) => {
                this.get();
                this.setState({
                    referral: null,
                    disallowForm: false,
                    returnForm: false,
                    scheduleForm: false
                })
            })
        })
    }

    closeReferral = (data) => {
        if (!data) data = {};
        data.selectedGroup = this.props.selectedGroup;
        data.closeConversation = true;

        this.setState({
            loading: true
        }, () => {
            fetch(API_ENDPOINT + '/referrals/close/' + this.state.referral._id, {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify(data)
            }).then(res => res.json()).then((result) => {
                this.get();
                if (result?.error) {
                    this.setState({
                        error: result.error?.translate(this.props.lang),
                        loading: false
                    })
                } else {
                    this.setState({
                        referral: null,
                        disallowForm: false,
                        returnForm: false,
                        scheduleForm: false
                    })
                }

            })
        })
    }
    deleteReferral = () => {
        if (this.state.referral && this.state.referral._id) {
            console.log(this.state.referral)
        }
        this.setState({
            loading: true
        }, () => {
            fetch(API_ENDPOINT + '/referrals/delete/' + this.state.referral._id, {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify({})
            }).then(res => res.json()).then((result) => {
                this.get();
                if (result?.error) {
                    this.setState({
                        error: result.error?.translate(this.props.lang),
                        loading: false
                    })
                } else {
                    this.setState({
                        referral: null,

                    })
                }

            })
        })
    }

    forward = (clinic) => {

        this.setState({
            loading: true
        }, () => {
            fetch(API_ENDPOINT + '/referrals/forward/' + this.state.referral._id, {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify({
                    forwardRecipientClinic: clinic._id
                })
            }).then(res => res.json()).then((result) => {
                this.get();
                this.setState({
                    referral: null,
                    disallowForm: false,
                    returnForm: false,
                    selectClinicModal: false,
                    scheduleForm: false,
                    checkForward: false
                })
            })
        })

    }


    acceptReferral = () => {
        this.setState({
            loading: true
        }, () => {
            fetch(API_ENDPOINT + '/referrals/accept/' + this.state.referral._id, {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify({})
            }).then(res => res.json()).then((result) => {
                this.get();
                this.setState({
                    referral: null,
                    disallowForm: false,
                    returnForm: false,
                    scheduleForm: false
                })
            })
        })
    }
    rejectReferral = (data) => {
        this.setState({
            loading: true
        }, () => {
            fetch(API_ENDPOINT + '/referrals/reject/' + this.state.referral._id, {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify(data)
            }).then(res => res.json()).then((result) => {
                this.get();
                this.setState({
                    referral: null,
                    disallowForm: false,
                    returnForm: false,
                    scheduleForm: false
                })
            })
        })
    }

    /**
    * Accept requested time from patient if status is patient-requested-another-time
    * @author   Milan Stanojevic
    */
    acceptRequestedTime = () => {
        this.setState({
            loading: true
        }, () => {
            fetch(API_ENDPOINT + '/referrals/patient/accept-requested-time/' + this.state.referral._id, {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify({})
            }).then(res => res.json()).then((result) => {
                this.get();
                this.setState({
                    referral: null,
                    disallowForm: false,
                    returnForm: false,
                    scheduleForm: false
                })
            })
        })
    }
    /**
    * Reject requested time from patient if status is patient-requested-another-time
    * @author   Milan Stanojevic
    */
    rejectRequestedTime = (data) => {
        this.setState({
            loading: true
        }, () => {
            fetch(API_ENDPOINT + '/referrals/patient/reject-requested-time/' + this.state.referral._id, {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify({ date: data.date + ' ' + data.time })
            }).then(res => res.json()).then((result) => {
                this.get();
                this.setState({
                    referral: null,
                    disallowForm: false,
                    returnForm: false,
                    scheduleForm: false
                })
            })
        })
    }

    /**
    * Reject requested time from patient if status is patient-requested-another-time
    * @author   Milan Stanojevic
    * @Objectparam    {String} id                   referral id
    * @Objectparam    {String} name                 referral number
    * @Objectparam    {Boolean} patient             true
    */
    conversation = (id, data) => {
        this.setState({
            loading: true
        }, () => {
            fetch(API_ENDPOINT + '/referrals/conversation/recpient/' + id, {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify(data)
            }).then(res => res.json()).then((result) => {
                this.props[0].history.push(`/chat?conversation=${result.conversation}`)
            })

        })
    }

    transferDropIn = (item) => {
        // console.log(item);


        this.setState({
            loading: true
        }, () => {
            fetch(API_ENDPOINT + '/referrals/transfer/drop-in/' + item._id, {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify({ doctor: this.state.selectedDoctor, prevDoctor: item.recipientUser, group: this.props.selectedGroup, clinic: this.props.selectedClinic })
            }).then(res => res.json()).then((result) => {
                if (result.error) {
                    this.setState({
                        error: result.error,
                        loading: false
                    })
                } else {
                    this.get();

                    this.setState({
                        transferModal: false,
                        selectedDoctor: -1,
                        loading: false

                    })
                }
            })
        })

    }

    generateTimeReferral = (data) => {
        if (data.event)
            data.controlSchedule = data
        let date = new Date();
        var startDate = this.props.getStringDateTs(Math.floor(date.getTime() / 1000), 'YYYY-MM-DD')/*moment.unix(Math.floor(date.getTime() / 1000)).format('YYYY-MM-DD')*/;
        let start = this.props.getStringDateTs(Math.floor(date.getTime() / 1000 + 600), 'HH:mm')/*moment.unix(Math.floor(date.getTime() / 1000 + 600)).format('HH:mm')*/
        var end = this.props.getStringDateTs(Math.floor(date.getTime() / 1000 + 900), 'HH:mm')/*moment.unix(Math.floor(date.getTime() / 1000 + 900)).format("HH:mm")*/;


        data.date = startDate;
        data.startTime = start;
        data.endTime = end;

        // console.log(data)
        this.setState({
            scheduleListReferral: data
        })

    }

    getWorkingTime = () => {
        // {
        //     this.state.listDoctor ?
        //         this.state.subAccounts.filter(item => item._id.toString() == this.state.listDoctor).map(item => {
        //             if (item.workingTimeConfig) {
        //                 if (item.workingTimeConfig[this.props.selectedGroup] && item.workingTimeConfig[this.props.selectedGroup][this.props.selectedClinic]) {
        //                     this.setState({
        //                         min: new Date(item.workingTimeConfig[this.props.selectedGroup][this.props.selectedClinic].min),
        //                         max: new Date(item.workingTimeConfig[this.props.selectedGroup][this.props.selectedClinic].max),

        //                     })
        //                 }
        //             }
        //         })
        //         : this.setState({
        //             min: this.state.min,
        //             max: this.state.max,
        //         })
        // }
        let obj = {

        };
        obj.min = new Date();
        obj.min.setHours(8, 30, 0)
        obj.max = new Date();
        obj.max.setHours(20, 30, 0)

        obj.min = new Date(this.props.getStringDateTs(Math.floor(new Date(obj.min).getTime() / 1000), 'MM/DD/YYYY HH:mm'))
        obj.max = new Date(this.props.getStringDateTs(Math.floor(new Date(obj.max).getTime() / 1000), 'MM/DD/YYYY HH:mm'))

        if (this.state.listDoctor) {
            if (this.state.subAccounts && this.state.subAccounts.length && this.state.subAccounts.filter(item => item._id.toString() == this.state.listDoctor).length) {
                let doctors = this.state.subAccounts.filter(item => item._id.toString() == this.state.listDoctor);
                for (let i = 0; i < doctors.length; i++) {
                    if (doctors[i].workingTimeConfig) {
                        if (doctors[i].workingTimeConfig[this.props.selectedGroup] && doctors[i].workingTimeConfig[this.props.selectedGroup][this.props.selectedClinic]) {
                            obj.min = new Date(doctors[i].workingTimeConfig[this.props.selectedGroup][this.props.selectedClinic].min)
                            obj.max = new Date(doctors[i].workingTimeConfig[this.props.selectedGroup][this.props.selectedClinic].max)
                        }
                    }
                }
            }
        }
        let scheduleListReferral = this.state.scheduleListReferral;
        let min = this.props.getStringDateTs(Math.floor(obj.min.getTime() / 1000), 'HH:mm');
        let max = this.props.getStringDateTs(Math.floor(obj.max.getTime() / 1000), 'HH:mm');
        if (scheduleListReferral?.startTime && scheduleListReferral?.endTime && min) {
            let minSeconds = Number(min?.split(':')?.[0]) * 60 + Number(min?.split(':')?.[1])
            let maxSeconds = Number(max?.split(':')?.[0]) * 60 + Number(max?.split(':')?.[1])
            let startTimeSeconds = Number(scheduleListReferral.startTime?.split(':')?.[0]) * 60 + Number(scheduleListReferral.startTime?.split(':')?.[1])
            let endTimeSeconds = Number(scheduleListReferral.endTime?.split(':')?.[0]) * 60 + Number(scheduleListReferral.endTime?.split(':')?.[1])
            if (minSeconds > startTimeSeconds) {
                scheduleListReferral.startTime = min;
                obj.scheduleListReferral = scheduleListReferral;
            }
            if (minSeconds > endTimeSeconds) {
                scheduleListReferral.endTime = this.props.getStringDateTs(Math.floor((obj.min.getTime() / 1000) + 60 * 5), 'HH:mm');
                obj.scheduleListReferral = scheduleListReferral;
            }
            if (maxSeconds < startTimeSeconds) {
                scheduleListReferral.startTime = this.props.getStringDateTs(Math.floor((obj.max.getTime() / 1000) - 60 * 5), 'HH:mm');;
                obj.scheduleListReferral = scheduleListReferral;
            }
            if (maxSeconds < endTimeSeconds) {
                scheduleListReferral.endTime = max;
                obj.scheduleListReferral = scheduleListReferral;
            }
        }


        this.setState(obj)

    }


    scheduleControl = (data) => {
        let checkDate = new Date(data.date.replace(/-/g, "/") + ' ' + data.startTime);
        let today = new Date();

        // console.log(data)
        if (data.controlSchedule) {
            data.patientControlNote = data.patientNote;
            data.title = data.controlSchedule.event && data.controlSchedule.event.title;
            data.socialSecurityNumber = data.controlSchedule.patient ? data.controlSchedule.patient?.socialSecurityNumber : '';
            data.visitReasonOnline = data.controlSchedule.event && data.controlSchedule.event.visitReasonOnline;
            data.videoCall = data.controlSchedule.event && data.controlSchedule.event.videoCall;
            data.titleDoctor = '';
            data.revisitForms = data.controlSchedule.event && data.controlSchedule.event.revisitForms;
            data.visitReasonName = data.controlSchedule.event && data.controlSchedule.event.visitReasonName;
            data.visitReasonPrice = data.controlSchedule.event && data.controlSchedule.event.questionaryPrice ? data.controlSchedule.event.questionaryPrice : 0;
            data.visiReason = data.controlSchedule.event && data.controlSchedule.event.visiReason;
            data.hideTimeFromThePatient = data.controlSchedule.event && data.controlSchedule.event.hideTimeFromThePatient;
            data.closeCaseManually = data.controlSchedule.event && data.controlSchedule.event.closeCaseManually;
            data.referralExists = data.controlSchedule._id
            data.appointmentPaid = data.controlSchedule.event.appointmentPaid ? data.controlSchedule.event.appointmentPaid : false
            data.revisitFormsArray = data.controlSchedule.event.revisitFormsArray ? data.controlSchedule.event.revisitFormsArray : [];
            data.visitReasonOnline = data.controlSchedule.event.onlineVisit ? data.controlSchedule.event.onlineVisit : false;
            data.journalNoteFormSelected = data.controlSchedule.event.journalNoteFormSelected ? data.controlSchedule.event.journalNoteFormSelected : null;
            data.rescheduleRevisit = data.rescheduleRevisit ? true : false;
        }

        if (checkDate >= today) {
            this.setState({
                loading: true
            }, () => {
                this.scheduleControlApiCalled = true;
                fetch(API_ENDPOINT + '/working-hours/control', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        startEventReservedDateOriginal: data.date + ' ' + data.startTime,
                        endEventReservedDateOriginal: data.date + ' ' + data.endTime,
                        startEventReservedDate: this.props.getStringDateInverseTs(Math.floor(new Date(data.date + ' ' + data.startTime).getTime() / 1000), `${this.props.dateFormat} HH:mm`),
                        endEventReservedDate: this.props.getStringDateInverseTs(Math.floor(new Date(data.date + ' ' + data.endTime).getTime() / 1000), `${this.props.dateFormat} HH:mm`),
                        title: data.title,
                        socialSecurityNumber: data.socialSecurityNumber,
                        doctor: this.state.listDoctor,
                        group: this.props.selectedGroup,
                        clinic: this.props.selectedClinic,
                        patientControlNote: data.patientNote,
                        onlineVisit: data.visitReasonOnline,
                        videoCall: data.videoCall,
                        additionalDoctors: data.additionalDoctors,
                        titleDoctor: data.titleDoctor,
                        revisitForms: this.state.revisitFormsSelected,
                        visitReasonName: data.visitReasonName,
                        visitReasonPrice: data.visitReasonPrice,
                        visiReason: data.visiReason,
                        freePayment: data.freePayment,
                        hideTimeFromThePatient: data.hideTimeFromThePatient,
                        closeCaseManually: data.closeCaseManually,
                        referralExists: data.referralExists ? data.referralExists : null,
                        appointmentPaid: data.appointmentPaid ? data.appointmentPaid : false,
                        revisitFormsArray: data.revisitFormsArray ? data.revisitFormsArray : [],
                        rescheduleRevisit: data.rescheduleRevisit
                    })
                }).then(res => res.json()).then((result) => {
                    if (result.error) {
                        this.setState({
                            error: result.error.translate(this.props.lang),
                            loading: false
                        })
                    } else {
                        if (this.state.listDoctor) {
                            this.get();
                            this.setState({
                                scheduleListReferral: null,
                                doctor: this.state.listDoctor,
                                doctorsList: [],
                                referral: null,
                                listDoctor: null
                            }, () => {
                                // this.setState({
                                //     day: new Date(data.date.replace(/-/g, "/") + ' ' + data.startTime),
                                //     view: 'day',
                                //     scroll: true,
                                // }, () => {
                                //     this.get();
                                //     this.getWorkingTime()
                                // })
                            })
                        }
                        // else if (data.controlSchedule) {
                        //     this.get();
                        //     this.setState({
                        //         scheduleReferral: null,
                        //         referralId: null,
                        //         day: new Date(data.date.replace(/-/g, "/") + ' ' + data.startTime),
                        //         scroll: true,
                        //         doctorsList: [],
                        //         referralId: null
                        //     }, () => this.get())
                        // } else {
                        //     this.get()
                        //     this.setState({
                        //         scheduleModal: null,
                        //         referralId: null,
                        //         scheduleControl: null,
                        //         revisitFormsSelected: [],
                        //         revisitFormsSelect: [],
                        //         // view: 'week',
                        //         day: new Date(data.date.replace(/-/g, "/") + ' ' + data.startTime),
                        //         scroll: true,
                        //         doctorsList: []

                        //     })
                        // }

                    }

                })
            })
        } else {
            this.setState({
                wrongDate: true
            })
        }

    }

    listScheduleReferral = (data) => {
        if (data && data.additionalDoctors && data.additionalDoctors.length) {

            this.checkDoctorsAvailable(data.additionalDoctors, 5, () => {
                if (!this.state.errorAdditionalDoctors) {
                    let checkDate = new Date(data.date.replace(/-/g, "/") + ' ' + data.startTime);
                    let today = new Date();

                    if (checkDate >= today) {
                        // alert('test')
                        this.setState({
                            loading: true
                        }, () => {
                            fetch(API_ENDPOINT + '/referrals/schedule/' + data._id, {
                                method: 'POST',
                                headers: {
                                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                                    'content-type': 'application/json'
                                },
                                body: JSON.stringify({
                                    startEventReservedDateOriginal: data.date + ' ' + data.startTime,
                                    endEventReservedDateOriginal: data.date + ' ' + data.endTime,
                                    startPatientReservedDate: this.props.getStringDateInverseTs(Math.floor(new Date(data.date + ' ' + data.startTime).getTime() / 1000), `${this.props.dateFormat} HH:mm`),
                                    endPatientReservedDate: this.props.getStringDateInverseTs(Math.floor(new Date(data.date + ' ' + data.endTime).getTime() / 1000), `${this.props.dateFormat} HH:mm`),
                                    doctor: this.state.listDoctor,
                                    patientNote: data.patientNote,
                                    additionalDoctors: data.additionalDoctors,
                                    rescheduleReferral: data.rescheduleReferral ? true : false
                                })
                            }).then(res => res.json()).then((result) => {
                                // alert('test')
                                if (result.error) {
                                    if (result.error == 'Event can not start or end while others are in progress!') {
                                        this.setState({
                                            errorOverlap: {
                                                message: 'Unable to schedule this case. Selected time period is not available.'.translate(this.props.lang),
                                                doctor: this.state.listDoctor,
                                                date: Math.floor(new Date(data.date + ' ' + data.startTime).getTime() / 1000)

                                            },
                                            loading: false
                                        })
                                    } else {
                                        this.setState({
                                            error: result.error.translate(this.props.lang),
                                            loading: false
                                        })
                                        this.get();
                                    }
                                } else {
                                    this.get();
                                    this.setState({
                                        scheduleListReferral: null,
                                        doctor: this.state.listDoctor,
                                        doctorsList: [],
                                        referral: null,
                                        listDoctor: null
                                    }, () => {
                                        // this.setState({
                                        //     day: new Date(data.date.replace(/-/g, "/") + ' ' + data.startTime),
                                        //     view: 'day',
                                        //     scroll: true,
                                        // }, () => {
                                        //     this.get();
                                        //     this.getWorkingTime()
                                        // })
                                    })
                                }

                            })
                        })
                    } else {
                        this.setState({
                            wrongDate: true
                        })


                    }
                }

            })
        } else {
            let checkDate = new Date(data.date.replace(/-/g, "/") + ' ' + data.startTime);
            let today = new Date();

            if (checkDate >= today) {
                // alert('test')
                this.setState({
                    loading: true
                }, () => {
                    fetch(API_ENDPOINT + '/referrals/schedule/' + data._id, {
                        method: 'POST',
                        headers: {
                            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                            'content-type': 'application/json'
                        },
                        body: JSON.stringify({
                            startEventReservedDateOriginal: data.date + ' ' + data.startTime,
                            endEventReservedDateOriginal: data.date + ' ' + data.endTime,
                            startPatientReservedDate: this.props.getStringDateInverseTs(Math.floor(new Date(data.date + ' ' + data.startTime).getTime() / 1000), `${this.props.dateFormat} HH:mm`),
                            endPatientReservedDate: this.props.getStringDateInverseTs(Math.floor(new Date(data.date + ' ' + data.endTime).getTime() / 1000), `${this.props.dateFormat} HH:mm`),
                            doctor: this.state.listDoctor,
                            patientNote: data.patientNote,
                            additionalDoctors: data.additionalDoctors,
                            rescheduleReferral: data.rescheduleReferral ? true : false
                        })
                    }).then(res => res.json()).then((result) => {
                        // alert('test')
                        if (result.error) {
                            if (result.error == 'Event can not start or end while others are in progress!') {
                                this.setState({
                                    errorOverlap: {
                                        message: 'Unable to schedule this case. Selected time period is not available.'.translate(this.props.lang),
                                        doctor: this.state.listDoctor,
                                        date: Math.floor(new Date(data.date + ' ' + data.startTime).getTime() / 1000)

                                    },
                                    loading: false
                                })
                            } else {
                                this.setState({
                                    error: result.error.translate(this.props.lang),
                                    loading: false
                                })
                                this.get();
                            }
                        } else {
                            this.get();
                            this.setState({
                                scheduleListReferral: null,
                                doctor: this.state.listDoctor,
                                doctorsList: [],
                                referral: null,
                                listDoctor: null
                            }, () => {
                                // this.setState({
                                //     day: new Date(data.date.replace(/-/g, "/") + ' ' + data.startTime),
                                //     view: 'day',
                                //     scroll: true,
                                // }, () => {
                                //     this.get();
                                //     this.getWorkingTime()
                                // })
                            })
                        }

                    })
                })
            } else {
                this.setState({
                    wrongDate: true
                })


            }
        }


    }
    checkDoctorsAvailable = (doctorsList, modal, callback) => {
        let event;
        console.log(doctorsList)
        console.log(modal)
        console.log(callback)

        if (modal == 1) {
            event = this.state.updateEvent;
        } else if (modal == 2) {
            event = this.state.scheduleReferral;
        } else if (modal == 3) {
            event = this.state.scheduleControl;
        } else if (modal == 4) {
            event = doctorsList;
            doctorsList = doctorsList.additionalDoctors;
        } else if (modal == 5) {
            event = this.state.scheduleListReferral;
        }
        // console.log(event)

        if (doctorsList) {
            let data = {
                doctorsList: doctorsList,
                startEventReservedDateOriginal: event.date + ' ' + event.startTime,
                endEventReservedDateOriginal: event.date + ' ' + event.endTime,
                startEventReservedDate: this.props.getStringDateInverseTs(Math.floor(new Date(event.date + ' ' + event.startTime).getTime() / 1000), `${this.props.dateFormat} HH:mm`),
                endEventReservedDate: this.props.getStringDateInverseTs(Math.floor(new Date(event.date + ' ' + event.endTime).getTime() / 1000), `${this.props.dateFormat} HH:mm`),
                eventId: event.id
            }
            fetch(API_ENDPOINT + '/working-hours/check/doctors', {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify(data)
            }).then(res => res.json()).then((result) => {
                if (result.error) {
                    this.setState({
                        errorAdditionalDoctors: result.doctorsList
                    })
                } else {
                    this.setState({ doctorsModal: false, successfullyAdditionalDoctors: modal != 4 ? true : false }, () => {
                        if (modal == 1) {
                            let updateEvent = this.state.updateEvent;
                            updateEvent.additionalDoctors = this.state.doctorsList;
                            this.setState({ updateEvent })
                        } else if (modal == 2) {
                            let scheduleReferral = this.state.scheduleReferral;
                            scheduleReferral.additionalDoctors = this.state.doctorsList;
                            this.setState({ scheduleReferral })
                        } else if (modal == 3) {
                            let scheduleControl = this.state.scheduleControl;
                            scheduleControl.additionalDoctors = this.state.doctorsList;
                            this.setState({ scheduleControl })
                        } else if (modal == 5) {
                            let scheduleListReferral = this.state.scheduleListReferral;
                            scheduleListReferral.additionalDoctors = this.state.doctorsList;
                            this.setState({ scheduleListReferral })
                        }


                    })
                }
                if (callback) {
                    callback();
                }


            })
        }


    }

    transferReferral = (referral) => {
        let data = {};
        if (referral && referral.event && (referral.status == 'scheduled' || referral.status == 'revisit-scheduled' || referral.status === 'visit-rescheduled')) {
            if (referral.event.date) {
                data.date = referral.event.date;
            }
            if (referral.event.startTimeString) {
                data.startTime = referral.event.startTimeString;
            }
            if (referral.event.endTimeString) {
                data.endTime = referral.event.endTimeString;
            }
            if (this.state.selectedDoctor) {
                data.doctor = this.state.selectedDoctor;
            }
            if (referral.event._id) {
                data.id = referral.event._id;
            }
            this.setState({
                loading: true
            }, () => {
                fetch(API_ENDPOINT + '/referrals/transfer/' + data.id, {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({ startEventReservedDateOriginal: data.date + ' ' + data.startTime, endEventReservedDateOriginal: data.date + ' ' + data.endTime, startEventReservedDate: this.props.getStringDateInverseTs(Math.floor(new Date(data.date + ' ' + data.startTime).getTime() / 1000), `${this.props.dateFormat} HH:mm`), endEventReservedDate: this.props.getStringDateInverseTs(Math.floor(new Date(data.date + ' ' + data.endTime).getTime() / 1000), `${this.props.dateFormat} HH:mm`), doctor: data.doctor, group: this.props.selectedGroup, clinic: this.props.selectedClinic })
                }).then(res => res.json()).then((result) => {
                    if (result.error) {
                        if (result.error == 'Event can not start or end while others are in progress!') {
                            this.setState({
                                errorOverlap: {
                                    message: 'Unable to transfer this case to a new caregiver. The term is not available.'.translate(this.props.lang),
                                    doctor: referral.recipientUser,
                                    date: referral.event.startTime

                                },
                                loading: false
                            })
                        } else {
                            this.setState({
                                error: result.error.translate(this.props.lang),
                                loading: false
                            })
                        }
                    } else {
                        this.get()
                        this.setState({
                            transferModal: null,
                            selectedDoctor: -1,
                            loading: false
                        })
                    }

                })
            })


        } else if (referral) {
            this.setState({
                loading: true
            }, () => {
                fetch(API_ENDPOINT + '/referrals/transfer/drop-in/' + referral._id, {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({ doctor: this.state.selectedDoctor, prevDoctor: referral.recipientUser, group: this.props.selectedGroup, clinic: this.props.selectedClinic, transferType: 'bookingByClinic' })
                }).then(res => res.json()).then((result) => {
                    if (result.error) {
                        this.setState({
                            error: result.error,
                            loading: false
                        })
                    } else {
                        this.get();

                        this.setState({
                            transferModal: false,
                            selectedDoctor: -1,
                            loading: false

                        })
                    }
                })
            })

        }

    }
    assignReferral = (item) => {

        this.setState({
            loadingAssign: true
        }, () => {
            fetch(API_ENDPOINT + '/referrals/assign/drop-in/' + item._id, {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify({})
            }).then(res => res.json()).then((result) => {
                this.get();

                this.setState({
                    conversationModal: null,
                    loadingAssign: false

                }, () => this.conversation(item._id, { ...this.state.conversationForm, patient: true }))
            })
        })


    }
    generateHtmlForPrint = () => {
        let referral = this.state.referral;
        let html = '<div>';
        if (referral) {
            html += `${this.props.lang == 'se' ? this.state.referral.referralContent : this.state.referral.referralContentEn ? this.state.referral.referralContentEn : this.state.referral.referralContent}`
            if (referral.revisitFormsArray && referral.revisitFormsArray.length) {
                let revisitFormsArray = referral.revisitFormsArray;
                for (let i = 0; i < revisitFormsArray.length; i++) {
                    html += `<h6 style="font-size: 18px;">${'Patient answers'.translate(this.props.lang)} <span style="margin-left:20px; font-weight:600">${revisitFormsArray[i].name}</span> </h6>`
                    if (revisitFormsArray[i].referralData) {
                        html += `${revisitFormsArray[i].referralData ? revisitFormsArray[i].referralData : ''}`
                        html += `${revisitFormsArray[i].referralTotalData ? revisitFormsArray[i].referralTotalData : ''}`
                    } else {
                        html += `<p style="font-style: italic; font-size: 14px;">${'The patient did not fill out the form'.translate(this.props.lang)}</p>`
                    }
                    if (i != (revisitFormsArray.length - 1)) {
                        html += '<hr/>'
                    }
                }
            }

        }
        html += '</div>'

        return html;
    }


    updateReferralNote = (data) => {
        let obj = {
            note: data.note,
            username: this.props.uData.username
        }
        this.setState({
            loading: true
        }, () => {
            fetch(API_ENDPOINT + '/referrals/note/' + data._id, {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify(obj)
            }).then(res => res.json()).then((result) => {
                this.setState({
                    referralNote: null,

                })
                this.get()
            })
        })
    }

    getPatientProfile = (item) => {


        fetch(API_ENDPOINT + '/users/referral/patient-profile', {
            method: 'POST',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
            body: JSON.stringify({ referral: item._id })
        }).then(res => res.json()).then((result) => {
            if (result && result.patient) {
                let obj = { ...item, ...result }
                this.setState({ patientProfile: obj })
            } else {
                this.setState({ patientProfile: item })
            }
        })
    }

    getReferralInfo = (item, referralContentPreview = false) => {
        let referral = { ...item }

        fetch(API_ENDPOINT + '/referrals-cases/referral/projection/data', {
            method: 'POST',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
            body: JSON.stringify({ _id: item._id })
        }).then(res => res.json()).then((result) => {
            if (result) {
                referral = { ...referral, ...result }
            }
            this.setState({ referral }, () => {
                this.setState({ refresh: new Date().getTime() })
            })
            // if (referralContentPreview) {
            // this.setState({ referralContentPreview: referral }, () => {
            //     this.setState({ refresh: new Date().getTime() })

            // })
            // } else {
            //     this.setState({ referral })
            // }


        })
    }
    priorityForDropIn = (item) => {

        fetch(API_ENDPOINT + '/referrals/priority/drop-in/' + item._id, {
            method: 'POST',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
            body: JSON.stringify({ priority: item.priority })
        }).then(res => res.json()).then((result) => {
            this.get();
            let referral = this.state.referral
            referral.priority = item.priority
            this.setState({
                referral
            })
        })

    }

    searchPatientWithRedirect = (SSN, enterprise) => {
        let obj = {
            socialSecurityNumber: SSN,
            group: this.props.selectedGroup,
            clinic: this.props.selectedClinic,
        }
        enterprise && (obj.openOnWebDoc = true);

        fetch(API_ENDPOINT + '/users/find/patient', {
            method: 'POST',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
            body: JSON.stringify(obj)
        }).then(res => res.json()).then((result) => {

            if (result && result._id) {
                const path = enterprise ? '/patients/search-enterprise' : '/patients/search';
                this.props.registerClinicGroupLogs({
                    type: 'patientProfile',
                    patient: SSN,
                });

                let state = {
                    TYPE: 'redirect',
                    redirectPatient: result,
                    redirectTabIndex: 1
                }
                enterprise && (state.redirectTabActive = 1);

                this.props[0].history.push(path, state);

            } else {
                this.setState({ error: 'User not found in this clinic group' })
            }
        })
    }

    prepareNote = (item) => {
        let counter = 0
        let noteArr = item.note.split('')
        let noteFinished = ''
        for (let i = 0; i < noteArr.length; i++) {
            counter++
            noteFinished = noteFinished + noteArr[i]
            if (noteArr[i] === '\n') {
                counter = 0
            }

            if (counter === 80) {
                counter = 0
                noteFinished = noteFinished + '\n'
            }

        }

        item.note = noteFinished

        return item.note.split('\n').join('<br/>')
    }
    removeReferralFromList = (referralId) => {
        let data = {
            referralId: referralId,
            clinic: this.props.selectedClinic
        }
        if (data.referralId && data.clinic) {
            fetch(API_ENDPOINT + '/referral/remove/transfered/info', {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify(data)
            }).then(res => res.json()).then((result) => {
                if (result?.error) {
                    this.setState({ error: result.error })
                } else {
                    this.setState({ removeReferralModal: null, referral: null })
                    this.get()

                }

            })
        }


    }
    render() {

        let params = {};
        if (this.state.useState) {
            params = this.state.params;
        } else {
            params = getSearchParams(this.props[0].location.search, { entries: 10, page: 0, sortField: 'timestamp', sortType: -1 })
        }


        let subgroups = [{ name: 'None', value: null }];

        if (this.props.group) {
            for (let i = 0; i < this.state.subgroups.length; i++) {
                if (this.state.subgroups[i].groups && this.state.subgroups[i].groups.indexOf(this.props.group) !== -1) {
                    subgroups.push(this.state.subgroups[i]);
                }
            }
        }

        const statusColors = {
            'waiting-for-approval': 'orange',
            'waiting-for-payment': 'orange',
            'disallowed': 'red',
            'allowed': 'green',
            'return-for-update': 'blue',
            'patient-requested-another-time': 'purple',
            'patient-rejected': 'red',
            'patient-accepted': 'green',
            'clinic-accepted-requested-time': 'green',
            'clinic-rejected-requested-time': 'orange',
            'approved-by-region-manager': 'green',
            'rejected-by-region-manager': 'red',
            'scheduled': 'green',
            'visit-rescheduled': 'green',
            'not-booked': 'red',
            'revisit-scheduled': 'green',
            'closed': 'gray',
            'in-progress': 'green',
            'waiting': 'orange',
            'canceled-by-clinic': 'red',
            'requested-new-appointment': 'orange',
            'external': 'gray',


        }

        const humanReadable = {
            'waiting-for-approval': 'Waiting for approval'.translate(this.props.lang),
            'waiting-for-payment': 'Waiting for payment'.translate(this.props.lang),
            'disallowed': 'Disallowed'.translate(this.props.lang),
            'allowed': 'Allowed'.translate(this.props.lang),
            'return-for-update': 'Return for update'.translate(this.props.lang),
            'patient-requested-another-time': 'Patient requested anoother time'.translate(this.props.lang),
            'patient-rejected': 'Rejected by patient'.translate(this.props.lang),
            'patient-accepted': 'Patient accepted'.translate(this.props.lang),
            'clinic-accepted-requested-time': 'Clinic accepted requested time'.translate(this.props.lang),
            'clinic-rejected-requested-time': 'Clinic rejected requested time'.translate(this.props.lang),
            'approved-by-region-manager': 'Approved by region manager'.translate(this.props.lang),
            'rejected-by-region-manager': 'Rejected by region manager'.translate(this.props.lang),
            'scheduled': 'Scheduled'.translate(this.props.lang),
            'visit-rescheduled': 'Visit rescheduled'.translate(this.props.lang),
            'revisit-scheduled': 'Revisit scheduled'.translate(this.props.lang),
            'not-booked': 'Not booked'.translate(this.props.lang),
            'closed': 'Closed'.translate(this.props.lang),
            'waiting': 'Waiting'.translate(this.props.lang),
            'in-progress': 'In progress'.translate(this.props.lang),
            'canceled-by-clinic': 'Canceled by clinic'.translate(this.props.lang),
            'requested-new-appointment': 'Requested new appointment'.translate(this.props.lang),
            'external': 'External transfer'.translate(this.props.lang),

        }
        let selectedGroupIdx = -1;
        if (this.props.uData && this.props.selectedGroup && this.props.uData?.clinicGroups?.length) {
            for (let i = 0; i < this.props.uData.clinicGroups.length; i++) {
                if (this.props.uData.clinicGroups[i]._id == this.props.selectedGroup) {
                    selectedGroupIdx = i;
                    break;
                }
            }
        }
        let enterprise = this.props.uData && this.props.uData.clinicGroups && this.props.uData.clinicGroups[selectedGroupIdx] && this.props.uData.clinicGroups[selectedGroupIdx].enterprise;
        let canSearchPatients = this.props.uData && this.props.uData.clinicGroups && this.props.uData.clinicGroups[selectedGroupIdx] && this.props.uData.clinicGroups[selectedGroupIdx].sarchPatients;

        const groupPermissionsUser = this.props.uData?.groupPermissionsUser;
        const adminPermissionId = this.props.uData?.permissions?.admin;
        const doctorPermissionId = this.props.uData?.permissions?.doctor;
        const isAdmin = groupPermissionsUser?.[this.props.selectedGroup]?.[this.props.selectedClinic]?.indexOf(adminPermissionId) !== -1 ? true : false;
        const isDoctor = groupPermissionsUser?.[this.props.selectedGroup]?.[this.props.selectedClinic]?.indexOf(doctorPermissionId) !== -1 ? true : false;

        const eConnectEnabled = this.props.uData && this.props.uData.clinicGroups && this.props.uData.clinicGroups[selectedGroupIdx] && this.props.uData.clinicGroups[selectedGroupIdx].eConnect && !this.props.uData.clinicGroups[selectedGroupIdx].eConnectTempDisabled;

        return (
            <div className={this.props.autoHideSidebar ? "dashboard dashboard-auto-hide" : "dashboard"}>
                <Container fluid>
                    <Row>
                        <Col lg="12">
                            <div className="panel">
                                <h5 className="component-header">{this.props.linksMeta && this.props.linksMeta[this.props[0].location.pathname] ? this.props.linksMeta[this.props[0].location.pathname][this.props.lang] : ''}</h5>
                                <ListCheckBox
                                    label={'Groups:'.translate(this.props.lang)}
                                    value={this.state.values}
                                    buttonText1={'Mark all'.translate(this.props.lang)}
                                    buttonText2={'Unmark all'.translate(this.props.lang)}
                                    values={this.state.professions && this.state.professions.length ? this.state.professions.map(item => {
                                        return { name: this.props.lang == 'en' ? item.professionEn : item.professionSv, value: item._id }
                                    }) : null}

                                    onChange={(e) => {
                                        if (e && e.length) {
                                            let arr = [];
                                            arr = [...e]
                                            this.setState({
                                                values: arr
                                            }, () => this.get(), this.updateMultipleParams([{ name: 'page', value: '0' }, { name: 'entries', value: '10' }]))

                                        } else {
                                            this.setState({
                                                values: [],
                                                controlBoking: false,
                                                webBoking: false,
                                                dropInBoking: false,
                                                clinicBoking: false,
                                                allowed: false,
                                                scheduled: false,
                                                waitingForApproval: false,
                                                waitingForPayment: false,
                                                notBooked: false,
                                                disallowed: false,
                                                inProgress: false,
                                                waiting: false,
                                                controlScheduled: false,
                                                cancledByClinic: false,
                                                rejectedByPatient: false,
                                                closed: false
                                            }, () => this.get(), this.updateMultipleParams([{ name: 'page', value: '0' }, { name: 'entries', value: '10' }]))
                                        }
                                    }}
                                />

                                <div >
                                    {this.state.values && this.state.values.length ?
                                        <div>
                                            <Label>{'Way of schedulig:'.translate(this.props.lang)}</Label>
                                        </div>
                                        :
                                        null}
                                    <div className="profession-buttons">
                                        {this.state.values && this.state.values.length ?
                                            <div>
                                                <div >
                                                    <button className={this.state.controlBoking ? ' clicked' : ''} onClick={() => {
                                                        this.setState({
                                                            controlBoking: !this.state.controlBoking,
                                                            webBoking: false,
                                                            dropInBoking: false,
                                                            clinicBoking: false,
                                                            allowed: false,
                                                            scheduled: false,
                                                            waitingForApproval: false,
                                                            waitingForPayment: false,
                                                            notBooked: false,
                                                            disallowed: false,
                                                            inProgress: false,
                                                            waiting: false,
                                                            controlScheduled: false,
                                                            cancledByClinic: false,
                                                            rejectedByPatient: false,
                                                            closed: false
                                                        }, () => this.get(), this.updateMultipleParams([{ name: 'page', value: '0' }, { name: 'entries', value: '10' }]))
                                                    }} >{'revisit'.translate(this.props.lang)}</button>
                                                </div>
                                            </div>
                                            :
                                            null}
                                        {this.state.values && this.state.values.length ?
                                            <div>
                                                <div style={{ marginLeft: 10 }}>
                                                    <button className={this.state.webBoking ? ' clicked' : ''} onClick={() => {
                                                        this.setState({
                                                            webBoking: !this.state.webBoking,
                                                            controlBoking: false,
                                                            dropInBoking: false,
                                                            clinicBoking: false,
                                                            allowed: false,
                                                            scheduled: false,
                                                            waitingForApproval: false,
                                                            waitingForPayment: false,
                                                            notBooked: false,
                                                            disallowed: false,
                                                            inProgress: false,
                                                            waiting: false,
                                                            controlScheduled: false,
                                                            cancledByClinic: false,
                                                            rejectedByPatient: false,
                                                            closed: false
                                                        }, () => this.get(), this.updateMultipleParams([{ name: 'page', value: '0' }, { name: 'entries', value: '10' }]))
                                                    }} >{'Web boking'.translate(this.props.lang)}</button>
                                                </div>
                                            </div>
                                            :
                                            null}
                                        {this.state.values && this.state.values.length ?
                                            <div>
                                                <div style={{ marginLeft: 10 }}>
                                                    <button className={this.state.dropInBoking ? ' clicked' : ''} onClick={() => {
                                                        this.setState({
                                                            dropInBoking: !this.state.dropInBoking,
                                                            controlBoking: false,
                                                            webBoking: false,
                                                            clinicBoking: false,
                                                            allowed: false,
                                                            scheduled: false,
                                                            waitingForApproval: false,
                                                            waitingForPayment: false,
                                                            notBooked: false,
                                                            disallowed: false,
                                                            inProgress: false,
                                                            waiting: false,
                                                            controlScheduled: false,
                                                            cancledByClinic: false,
                                                            rejectedByPatient: false,
                                                            closed: false
                                                        }, () => this.get(), this.updateMultipleParams([{ name: 'page', value: '0' }, { name: 'entries', value: '10' }]))
                                                    }} >{'Drop in'.translate(this.props.lang)}</button>
                                                </div>
                                            </div>
                                            :
                                            null}
                                        {this.state.values && this.state.values.length ?
                                            <div>
                                                <div style={{ marginLeft: 10 }}>
                                                    <button className={this.state.clinicBoking ? ' clicked' : ''} onClick={() => {
                                                        this.setState({
                                                            clinicBoking: !this.state.clinicBoking,
                                                            controlBoking: false,
                                                            dropInBoking: false,
                                                            webBoking: false,
                                                            allowed: false,
                                                            scheduled: false,
                                                            waitingForApproval: false,
                                                            waitingForPayment: false,
                                                            notBooked: false,
                                                            disallowed: false,
                                                            inProgress: false,
                                                            waiting: false,
                                                            controlScheduled: false,
                                                            cancledByClinic: false,
                                                            rejectedByPatient: false,
                                                            closed: false
                                                        }, () => this.get(), this.updateMultipleParams([{ name: 'page', value: '0' }, { name: 'entries', value: '10' }]))
                                                    }} >{'Booking by clinic'.translate(this.props.lang)}</button>
                                                </div>
                                            </div>
                                            :
                                            null}
                                    </div>

                                </div>

                                <div >
                                    {this.state.controlBoking ?
                                        <div style={{ marginTop: 10 }}>
                                            <Label>{'Case status:'.translate(this.props.lang)}</Label>
                                        </div>
                                        :
                                        null}

                                    <div className="profession-buttons mr-0">
                                        {this.state.controlBoking ?
                                            <div>
                                                <div>
                                                    <button className={this.state.controlScheduled ? ' clicked' : ''} onClick={() => {
                                                        this.setState({
                                                            controlScheduled: !this.state.controlScheduled,
                                                            cancledByClinic: false,
                                                            rejectedByPatient: false,
                                                            closed: false
                                                        }, () => this.get(), this.updateMultipleParams([{ name: 'page', value: '0' }, { name: 'entries', value: '10' }]))
                                                    }} >{'Revisit scheduled'.translate(this.props.lang)}</button>
                                                </div>
                                            </div>
                                            :
                                            null}
                                        {this.state.controlBoking ?
                                            <div>
                                                <div >
                                                    <button className={this.state.cancledByClinic ? ' clicked' : ''} onClick={() => {
                                                        this.setState({
                                                            cancledByClinic: !this.state.cancledByClinic,
                                                            controlScheduled: false,
                                                            rejectedByPatient: false,
                                                            closed: false
                                                        }, () => this.get(), this.updateMultipleParams([{ name: 'page', value: '0' }, { name: 'entries', value: '10' }]))
                                                    }} >{'Cancled by clinic'.translate(this.props.lang)}</button>
                                                </div>
                                            </div>
                                            :
                                            null}
                                        {this.state.controlBoking ?
                                            <div>
                                                <div >
                                                    <button className={this.state.rejectedByPatient ? ' clicked' : ''} onClick={() => {
                                                        this.setState({
                                                            rejectedByPatient: !this.state.rejectedByPatient,
                                                            controlScheduled: false,
                                                            cancledByClinic: false,
                                                            closed: false
                                                        }, () => this.get(), this.updateMultipleParams([{ name: 'page', value: '0' }, { name: 'entries', value: '10' }]))
                                                    }} >{'Rejected by patient'.translate(this.props.lang)}</button>
                                                </div>
                                            </div>
                                            :
                                            null}
                                        {this.state.controlBoking ?
                                            <div>
                                                <div >
                                                    <button className={this.state.closed ? ' clicked' : ''} onClick={() => {
                                                        this.setState({
                                                            closed: !this.state.closed,
                                                            controlScheduled: false,
                                                            rejectedByPatient: false,
                                                            cancledByClinic: false
                                                        }, () => this.get(), this.updateMultipleParams([{ name: 'page', value: '0' }, { name: 'entries', value: '10' }]))
                                                    }} >{'Closed'.translate(this.props.lang)}</button>
                                                </div>
                                            </div>
                                            :
                                            null}
                                    </div>
                                </div>

                                <div >
                                    {this.state.webBoking ?
                                        <div style={{ marginTop: 10 }}>
                                            <Label>{'Case status:'.translate(this.props.lang)}</Label>
                                        </div>
                                        :
                                        null}

                                    <div className="profession-buttons mr-0">
                                        {this.state.webBoking ?
                                            <div>
                                                <div >
                                                    <button className={this.state.scheduled ? ' clicked' : ''} onClick={() => {
                                                        this.setState({
                                                            scheduled: !this.state.scheduled,
                                                            notBooked: false,
                                                            rejectedByPatient: false,
                                                            closed: false
                                                        }, () => this.get(), this.updateMultipleParams([{ name: 'page', value: '0' }, { name: 'entries', value: '10' }]))
                                                    }} >{'Scheduled'.translate(this.props.lang)}</button>
                                                </div>
                                            </div>
                                            :
                                            null}
                                        {this.state.webBoking ?
                                            <div>
                                                <div >
                                                    <button className={this.state.notBooked ? ' clicked' : ''} onClick={() => {
                                                        this.setState({
                                                            notBooked: !this.state.notBooked,
                                                            scheduled: false,
                                                            rejectedByPatient: false,
                                                            closed: false
                                                        }, () => this.get(), this.updateMultipleParams([{ name: 'page', value: '0' }, { name: 'entries', value: '10' }]))
                                                    }} >{'Not booked'.translate(this.props.lang)}</button>
                                                </div>
                                            </div>
                                            :
                                            null}
                                        {this.state.webBoking ?
                                            <div>
                                                <div >
                                                    <button className={this.state.rejectedByPatient ? ' clicked' : ''} onClick={() => {
                                                        this.setState({
                                                            rejectedByPatient: !this.state.rejectedByPatient,
                                                            scheduled: false,
                                                            notBooked: false,
                                                            closed: false
                                                        }, () => this.get(), this.updateMultipleParams([{ name: 'page', value: '0' }, { name: 'entries', value: '10' }]))
                                                    }}>{'Rejected by patient'.translate(this.props.lang)}</button>
                                                </div>
                                            </div>
                                            :
                                            null}
                                        {this.state.webBoking ?
                                            <div>
                                                <div >
                                                    <button className={this.state.closed ? ' clicked' : ''} onClick={() => {
                                                        this.setState({
                                                            closed: !this.state.closed,
                                                            scheduled: false,
                                                            rejectedByPatient: false,
                                                            notBooked: false
                                                        }, () => this.get(), this.updateMultipleParams([{ name: 'page', value: '0' }, { name: 'entries', value: '10' }]))
                                                    }} >{'Closed'.translate(this.props.lang)}</button>
                                                </div>
                                            </div>
                                            :
                                            null}
                                    </div>
                                </div>

                                <div >
                                    {this.state.dropInBoking ?
                                        <div style={{ marginTop: 10 }}>
                                            <Label>{'Case status:'.translate(this.props.lang)}</Label>
                                        </div>
                                        :
                                        null}

                                    <div className="profession-buttons mr-0">
                                        {this.state.dropInBoking ?
                                            <div>
                                                <div >
                                                    <button className={this.state.waiting ? ' clicked' : ''} onClick={() => {
                                                        this.setState({
                                                            waiting: !this.state.waiting,
                                                            inProgress: false,
                                                            closed: false
                                                        }, () => this.get(), this.updateMultipleParams([{ name: 'page', value: '0' }, { name: 'entries', value: '10' }]))
                                                    }} >{'Waiting'.translate(this.props.lang)}</button>
                                                </div>
                                            </div>
                                            :
                                            null}
                                        {this.state.dropInBoking ?
                                            <div>
                                                <div >
                                                    <button className={this.state.inProgress ? ' clicked' : ''} onClick={() => {
                                                        this.setState({
                                                            inProgress: !this.state.inProgress,
                                                            waiting: false,
                                                            closed: false
                                                        }, () => this.get(), this.updateMultipleParams([{ name: 'page', value: '0' }, { name: 'entries', value: '10' }]))
                                                    }} >{'In progress'.translate(this.props.lang)}</button>
                                                </div>
                                            </div>
                                            :
                                            null}

                                        {this.state.dropInBoking ?
                                            <div>
                                                <div >
                                                    <button className={this.state.closed ? ' clicked' : ''} onClick={() => {
                                                        this.setState({
                                                            closed: !this.state.closed,
                                                            waiting: false,
                                                            inProgress: false
                                                        }, () => this.get(), this.updateMultipleParams([{ name: 'page', value: '0' }, { name: 'entries', value: '10' }]))
                                                    }} >{'Closed'.translate(this.props.lang)}</button>
                                                </div>
                                            </div>
                                            :
                                            null}
                                    </div>
                                </div>

                                <div >
                                    {this.state.clinicBoking ?
                                        <div style={{ marginTop: 10 }}>
                                            <Label>{'Case status:'.translate(this.props.lang)}</Label>
                                        </div>
                                        :
                                        null}

                                    <div className="profession-buttons mr-0">
                                        {this.state.clinicBoking ?
                                            <div>
                                                <div >
                                                    <button className={this.state.waitingForApproval ? ' clicked' : ''} onClick={() => {
                                                        this.setState({
                                                            waitingForApproval: !this.state.waitingForApproval,
                                                            waitingForPayment: false,
                                                            allowed: false,
                                                            scheduled: false,
                                                            notBooked: false,
                                                            rejectedByPatient: false,
                                                            closed: false,
                                                            disallowed: false
                                                        }, () => this.get(), this.updateMultipleParams([{ name: 'page', value: '0' }, { name: 'entries', value: '10' }]))
                                                    }} >{'Waitign for approval'.translate(this.props.lang)}</button>
                                                </div>
                                            </div>
                                            :
                                            null}
                                        {this.state.clinicBoking ?
                                            <div>
                                                <div >
                                                    <button className={this.state.waitingForPayment ? ' clicked' : ''} onClick={() => {
                                                        this.setState({
                                                            waitingForPayment: !this.state.waitingForPayment,
                                                            waitingForApproval: false,
                                                            allowed: false,
                                                            scheduled: false,
                                                            notBooked: false,
                                                            rejectedByPatient: false,
                                                            closed: false,
                                                            disallowed: false
                                                        }, () => this.get(), this.updateMultipleParams([{ name: 'page', value: '0' }, { name: 'entries', value: '10' }]))
                                                    }} >{'Waiting for payment'.translate(this.props.lang)}</button>
                                                </div>
                                            </div>
                                            :
                                            null}

                                        {this.state.clinicBoking ?
                                            <div>
                                                <div >
                                                    <button className={this.state.allowed ? ' clicked' : ''} onClick={() => {
                                                        this.setState({
                                                            allowed: !this.state.allowed,
                                                            waitingForApproval: false,
                                                            waitingForPayment: false,
                                                            scheduled: false,
                                                            notBooked: false,
                                                            rejectedByPatient: false,
                                                            closed: false,
                                                            disallowed: false
                                                        }, () => this.get(), this.updateMultipleParams([{ name: 'page', value: '0' }, { name: 'entries', value: '10' }]))
                                                    }} >{'Allowed'.translate(this.props.lang)}</button>
                                                </div>
                                            </div>
                                            :
                                            null}
                                        {this.state.clinicBoking ?
                                            <div>
                                                <div >
                                                    <button className={this.state.scheduled ? ' clicked' : ''} onClick={() => {
                                                        this.setState({
                                                            scheduled: !this.state.scheduled,
                                                            waitingForApproval: false,
                                                            waitingForPayment: false,
                                                            allowed: false,
                                                            notBooked: false,
                                                            rejectedByPatient: false,
                                                            closed: false,
                                                            disallowed: false
                                                        }, () => this.get(), this.updateMultipleParams([{ name: 'page', value: '0' }, { name: 'entries', value: '10' }]))
                                                    }} >{'Scheduled'.translate(this.props.lang)}</button>
                                                </div>
                                            </div>
                                            :
                                            null}
                                        {this.state.clinicBoking ?
                                            <div>
                                                <div >
                                                    <button className={this.state.notBooked ? ' clicked' : ''} onClick={() => {
                                                        this.setState({
                                                            notBooked: !this.state.notBooked,
                                                            waitingForApproval: false,
                                                            waitingForPayment: false,
                                                            allowed: false,
                                                            scheduled: false,
                                                            rejectedByPatient: false,
                                                            closed: false,
                                                            disallowed: false
                                                        }, () => this.get(), this.updateMultipleParams([{ name: 'page', value: '0' }, { name: 'entries', value: '10' }]))
                                                    }} >{'Not booked'.translate(this.props.lang)}</button>
                                                </div>
                                            </div>
                                            :
                                            null}
                                        {this.state.clinicBoking ?
                                            <div>
                                                <div >
                                                    <button className={this.state.disallowed ? ' clicked' : ''} onClick={() => {
                                                        this.setState({
                                                            disallowed: !this.state.disallowed,
                                                            notBooked: false,
                                                            waitingForApproval: false,
                                                            waitingForPayment: false,
                                                            allowed: false,
                                                            scheduled: false,
                                                            rejectedByPatient: false,
                                                            closed: false
                                                        }, () => this.get(), this.updateMultipleParams([{ name: 'page', value: '0' }, { name: 'entries', value: '10' }]))
                                                    }} >{'Disallowed'.translate(this.props.lang)}</button>
                                                </div>
                                            </div>
                                            :
                                            null}

                                        {this.state.clinicBoking ?
                                            <div>
                                                <div >
                                                    <button className={this.state.rejectedByPatient ? ' clicked' : ''} onClick={() => {
                                                        this.setState({
                                                            rejectedByPatient: !this.state.rejectedByPatient,
                                                            waitingForApproval: false,
                                                            waitingForPayment: false,
                                                            allowed: false,
                                                            scheduled: false,
                                                            notBooked: false,
                                                            closed: false,
                                                            disallowed: false
                                                        }, () => this.get(), this.updateMultipleParams([{ name: 'page', value: '0' }, { name: 'entries', value: '10' }]))
                                                    }} >{'Rejected by patient'.translate(this.props.lang)}</button>
                                                </div>
                                            </div>
                                            :
                                            null}
                                        {this.state.clinicBoking ?
                                            <div>
                                                <div >
                                                    <button className={this.state.closed ? ' clicked' : ''} onClick={() => {
                                                        this.setState({
                                                            closed: !this.state.closed,
                                                            waitingForApproval: false,
                                                            waitingForPayment: false,
                                                            allowed: false,
                                                            scheduled: false,
                                                            notBooked: false,
                                                            rejectedByPatient: false,
                                                            disallowed: false
                                                        }, () => this.get(), this.updateMultipleParams([{ name: 'page', value: '0' }, { name: 'entries', value: '10' }]))
                                                    }} >{'Closed'.translate(this.props.lang)}</button>
                                                </div>
                                            </div>
                                            :
                                            null}
                                    </div>
                                </div>
                                <div style={{ marginBottom: 20 }}>

                                </div>

                                <div className='all-cases-listbuilder'>
                                    <ListBuilder
                                        lang={this.props.lang}
                                        loading={this.state.loading}
                                        total={this.state.total}
                                        showNumeration={false}
                                        filterDelay={600}
                                        columnVisibility={'all-cases'} //columnVisibility
                                        uData={this.props.uData} //columnVisibility
                                        updateListBuilderFields={this.props.updateListBuilderFields} //columnVisibility
                                        get={this.get}//columnVisibility
                                        fields={[
                                            { type: 'text', name: 'recpientClinicName', label: 'Recipient'.translate(this.props.lang), allowSort: true },
                                            { type: 'text', name: 'remissType', label: 'Referral Type'.translate(this.props.lang), allowSort: true },
                                            { type: 'text', name: 'profession', label: 'Group'.translate(this.props.lang), allowSort: true },
                                            { type: 'text', name: 'patient', label: 'Patient'.translate(this.props.lang), allowSort: false },
                                            { type: 'text', name: 'version', label: 'Referral ID'.translate(this.props.lang), allowSort: true },
                                            { type: 'text', name: 'questionaryName', label: 'Visit reason'.translate(this.props.lang), allowSort: true },
                                            { type: 'text', name: 'patientReservedTimestamp', label: 'Visit date'.translate(this.props.lang), allowSort: true },
                                            { type: 'text', name: 'status', label: 'Status'.translate(this.props.lang), allowSort: true },
                                            { type: 'text', name: 'timestamp', label: 'Created'.translate(this.props.lang), allowSort: true },
                                            { type: 'text', name: 'lastActivity', label: 'Last activity'.translate(this.props.lang), allowSort: true },
                                            // { type: 'checkbox', name: 'seenScheduled', label: 'Seen'.translate(this.props.lang), allowSort: true },
                                        ]}
                                        rawItems={this.state.items}
                                        actionColClassName='all-cases-action-td'
                                        items={this.state.items.map((item => {
                                            // console.log(item.referralIsPaid, item.appointmentPaid, item.paymentAfterBookedTime, item.questionaryPrice)
                                            return {

                                                ...item,
                                                ___className: item?._id && this.state.caseFilter?.includes?.(item._id) ? 'ec-highlighted-row' : undefined,
                                                remissType: item.remissTypeEn ? item.remissTypeEn.translate(this.props.lang) : '',
                                                questionaryName: this.props.lang == 'en' ? item.questionaryName?.replace?.(' (onlinebesök)', ' (online visit)') : item.questionaryName?.replace?.(' (online visit)', ' (onlinebesök)'),
                                                profession: this.props.lang == 'en' ? item.professionsStringEn : item.professionsStringSv,
                                                version: <><span>{item.patient?.referralNumber}</span></>,
                                                recpientClinicName: <>{item.recpientClinicName}<br /><span className="clinic-subgroup-name">{item.recpientClinicSubgroupName} </span><br /> <span className="clinic-subgroup-name">{item.doctorName}</span>  </>,
                                                patient: <Button color='#fff' className="patient-modal-button" onClick={() => {
                                                    // this.setState({ patientProfile: item }, () => {
                                                    //     this.props.registerClinicGroupLogs({
                                                    //         type: 'patientProfile',
                                                    //         patient: item.patient?.socialSecurityNumber
                                                    //     })
                                                    // })
                                                    if (enterprise || canSearchPatients) {
                                                        this.searchPatientWithRedirect(item.patient?.socialSecurityNumber, enterprise);
                                                    } else {
                                                        this.getPatientProfile(item)
                                                        this.props.registerClinicGroupLogs({
                                                            type: 'patientProfile',
                                                            patient: item.patient?.socialSecurityNumber
                                                        })
                                                    }

                                                }}>{item.patient?.name}<br /><span className="clinic-subgroup-name">{item.patient?.socialSecurityNumber} </span> {item?._2fa ? <p style={{ padding: 0, margin: 0, color: 'red', fontSize: 12 }}>{'2FA'.translate(this.props.lang)}</p> : null} </Button>,
                                                timestamp: <>{this.props.getStringDateTs(item.timestamp, `${this.props.dateFormat}`)/*moment.unix(item.timestamp).format(`${this.props.dateFormat}`)*/} <br /> {this.props.getStringDateTs(item.timestamp, `HH:mm`)/*moment.unix(item.timestamp).format('HH:mm')*/}</>,
                                                lastActivity: item.tsModified ? <>{this.props.getStringDateTs(item.tsModified, `${this.props.dateFormat}`)/*moment.unix(item.tsModified).format(`${this.props.dateFormat}`)*/} <br /> {this.props.getStringDateTs(item.tsModified, `HH:mm`)/*moment.unix(item.tsModified).format('HH:mm')*/} </> : <>{this.props.getStringDateTs(item.tsCreated, this.props.dateFormat)/*moment.unix(item.tsCreated).format(`${this.props.dateFormat}`)*/} <br /> {this.props.getStringDateTs(item.tsCreated, 'HH:mm')/*moment.unix(item.tsCreated).format('HH:mm')*/}</>,
                                                converateTimestamp: <>{this.props.getStringDateTs(item.converateTimestamp, this.props.dateFormat)/*moment.unix(item.converateTimestamp).format(`${this.props.dateFormat}`)*/} <br /> {this.props.getStringDateTs(item.converateTimestamp, 'HH:mm')/*moment.unix(item.converateTimestamp).format('HH:mm')*/}</>,
                                                patientReservedTimestamp: item.patientReservedTimestamp ? this.props.getStringDateTs(item.patientReservedTimestamp, `${this.props.dateFormat} HH:mm`)/*moment.unix(item.patientReservedTimestamp).format(`${this.props.dateFormat} HH:mm`)*/ : 'N/A',
                                                statusValue: item.status,
                                                status: item.recipientClinic !== this.props.selectedClinic ? <>

                                                    <div className='bill-icon'>
                                                        <div className="tabel-action-tooltip2">
                                                            <span>
                                                                {item.referralIsPaid || (item.event && item.event.appointmentPaid) || (item.event && item.event.visitReasonPrice == 0) || item.questionaryPrice == 0 ?
                                                                    <Isvg src={billGreenIcon} />
                                                                    :
                                                                    <Isvg src={billRedIcon} />}
                                                            </span>
                                                            <div className="chat-icons-tooltip tabel-action-up">
                                                                {
                                                                    item.referralIsPaid || (item.event && item.event.appointmentPaid) || (item.event && item.event.visitReasonPrice == 0) || item.questionaryPrice == 0 ?
                                                                        <>
                                                                            {
                                                                                item.wayOfPayment && item.wayOfPayment.indexOf('Free of charge') != -1 ?
                                                                                    item.wayOfPayment.translate(this.props.lang)
                                                                                    :
                                                                                    <>
                                                                                        {'Paid'.translate(this.props.lang)}
                                                                                        {item.wayOfPayment ? (': ' + item.wayOfPayment.translate(this.props.lang)) : ''}
                                                                                    </>
                                                                            }
                                                                        </>
                                                                        :
                                                                        'Unpaid case'.translate(this.props.lang)}
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <span style={{ color: statusColors['external'] }}>{humanReadable['external']} </span><br />

                                                </> :
                                                    <>

                                                        <div className='bill-icon'>
                                                            <div className="tabel-action-tooltip2">
                                                                <span>
                                                                    {item.referralIsPaid || (item.event && item.event.appointmentPaid) || (item.event && item.event.visitReasonPrice == 0) || item.questionaryPrice == 0 ?
                                                                        <Isvg src={billGreenIcon} />
                                                                        :
                                                                        <Isvg src={billRedIcon} />}
                                                                </span>
                                                                <div className="chat-icons-tooltip tabel-action-up">
                                                                    {
                                                                        item.referralIsPaid || (item.event && item.event.appointmentPaid) || (item.event && item.event.visitReasonPrice == 0) || item.questionaryPrice == 0 ?
                                                                            <>
                                                                                {
                                                                                    item.wayOfPayment && item.wayOfPayment.indexOf('Free of charge') != -1 ?
                                                                                        item.wayOfPayment.translate(this.props.lang)
                                                                                        :
                                                                                        <>
                                                                                            {'Paid'.translate(this.props.lang)}
                                                                                            {item.wayOfPayment ? (': ' + item.wayOfPayment.translate(this.props.lang)) : ''}
                                                                                        </>
                                                                                }
                                                                            </>
                                                                            :
                                                                            'Unpaid case'.translate(this.props.lang)}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <span style={{ color: statusColors[item.status] }}>{humanReadable[item.status]} </span><br />

                                                    </>,

                                            }
                                        }))}
                                        actions={
                                            [
                                                {
                                                    component: <div className="tabel-action-tooltip"> <Isvg src={viewIcon} /> <div className="chat-icons-tooltip">{'Preview'.translate(this.props.lang)}</div> </div>,
                                                    // onClick: (item) => this.setState({ referral: item }),
                                                    onClick: (item) => this.getReferralInfo(item),
                                                    // condition: (item) => {
                                                    //     if (item.remissType !== 'Drop-in')
                                                    //         return true;
                                                    //     else
                                                    //         return false;
                                                    // }
                                                },
                                                // {
                                                //     component: <div className="tabel-action-tooltip">  test <div className="chat-icons-tooltip">{'Preview'.translate(this.props.lang)}</div> </div>,
                                                //     onClick: (item) => this.getReferralInfo(item, true)
                                                //     // this.setState({ referralContentPreview: item })
                                                // },
                                                {
                                                    renderComponent: (item) => <div className="tabel-action-tooltip"> <Isvg src={transferIcon} className={eConnectEnabled && item?._consultation ? "transfer-icon consultation-active-icon" : "transfer-icon"} /> <div className="chat-icons-tooltip">{eConnectEnabled ? 'Consultation'.translate(this.props.lang) : 'Transfer'.translate(this.props.lang)}</div> </div>,
                                                    onClick: async (item) => {
                                                        if (eConnectEnabled && this.props.checkIfConsultationExists && item?._id) {
                                                            let consultationExists = await this.props.checkIfConsultationExists(item._id, () => {
                                                                this.setState({ transferModal: item })
                                                            });
                                                            if (!consultationExists)
                                                                this.setState({ transferModal: item })
                                                        } else {
                                                            this.setState({ transferModal: item })
                                                        }

                                                    },
                                                    condition: (item) => {
                                                        if (item.recipientClinic !== this.props.selectedClinic) {
                                                            return false;
                                                        } else if (item.remissType == 'Drop-in' || item.statusValue == 'allowed' || item.statusValue == 'scheduled' || item.statusValue == 'not-booked' || item.statusValue == 'revisit-scheduled' || item.statusValue === 'visit-rescheduled')
                                                            return true;
                                                        else
                                                            return false;
                                                    }
                                                },
                                                enterprise ?
                                                    {
                                                        renderComponent: (item) => { return (<div className="tabel-action-tooltip"> <Isvg src={infoIcon} className={item && item.note ? "note-icon" : ''} style={{ width: 36, height: 23 }} /> <div className="chat-icons-tooltip info-tooltip-custom"><span>{item && item.note ? <div dangerouslySetInnerHTML={{ __html: this.prepareNote(item) }}></div> : 'Note'.translate(this.props.lang)}{item && item.note && item.noteUsername ? ' ' + item.noteUsername : ''}<br />{item && item.note && item.noteUsername && item.noteTimestamp ? ' ' + this.props.getStringDateTs(item.noteTimestamp, `${this.props.dateFormat} HH:mm`)/*moment.unix(item.noteTimestamp).format(`${this.props.dateFormat} HH:mm`)*/ : ''}</span></div> </div>) },
                                                        onClick: (item) => this.setState({ referralNote: { ...item } }),
                                                        condition: (item) => {
                                                            if (item.recipientClinic === this.props.selectedClinic)
                                                                return true;
                                                            else
                                                                return false;
                                                        }
                                                    } : {
                                                        renderComponent: () => { return null },
                                                        onClick: () => { }
                                                    },
                                                {
                                                    component: <div className="tabel-action-tooltip"> <Isvg src={garbageIcon} className="transfer-icon" /> <div className="chat-icons-tooltip">{'Remove'.translate(this.props.lang)}</div> </div>,
                                                    onClick: (item) => this.setState({ removeReferralModal: { ...item } }),
                                                    condition: (item) => {
                                                        if (item.recipientClinic !== this.props.selectedClinic && isAdmin)
                                                            return true;
                                                        else
                                                            return false;
                                                    }
                                                }


                                            ]
                                        }
                                        params={params}
                                        sortField={params.sortField}
                                        sortType={params.sortType}
                                        updateSort={this.updateSort}
                                        updateParams={this.updateParams}
                                        updateMultipleParams={this.updateMultipleParams}

                                    ></ListBuilder>
                                </div>

                                <DeleteModal
                                    lang={this.props.lang}
                                    isOpen={this.state.deleteModal}
                                    toggle={() => this.setState({ deleteModal: null })}
                                    handler={() => { this.delete(this.state.deleteModal._id); this.setState({ deleteModal: null }) }}
                                >
                                    {'Delete referral'.translate(this.props.lang)} <strong>{this.state.deleteModal && this.state.deleteModal.title}</strong>?
                                </DeleteModal>

                            </div>
                        </Col>
                    </Row>

                </Container>
                {this.state.patientProfile ?
                    <Modal isOpen={this.state.patientProfile} centered>
                        <ModalHeader toggle={() => this.setState({ patientProfile: !this.state.patientProfile })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ patientProfile: !this.state.patientProfile })}><Isvg src={xIcon} /></button>}>
                            <h4>{'Patient profile'.translate(this.props.lang)}</h4>
                        </ModalHeader>
                        <ModalBody>
                            <FormGroup>
                                <Label>{'Social security number'.translate(this.props.lang)}</Label>
                                <Input type='text' disabled
                                    value={this.state.patientProfile.patient?.socialSecurityNumber} />
                            </FormGroup>
                            <FormGroup>
                                <Label>{'Name'.translate(this.props.lang)}</Label>
                                <Input type='text' disabled
                                    value={this.state.patientProfile.patient?.name} />
                            </FormGroup>
                            <FormGroup>
                                <Label>{'Email'.translate(this.props.lang)}</Label>
                                <Input type='text' disabled
                                    value={this.state.patientProfile.patient?.parents && this.state.patientProfile.patient?.parents[0] && this.state.patientProfile.patient?.parents[0].email ? this.state.patientProfile.patient?.parents[0].email : this.state.patientProfile.patient?.email} />
                            </FormGroup>
                            <FormGroup>
                                <Label>{'Phone'.translate(this.props.lang)}</Label>
                                <Input type='text' disabled
                                    value={this.state.patientProfile.patient?.parents && this.state.patientProfile.patient?.parents[0] && this.state.patientProfile.patient?.parents[0].phone ? this.state.patientProfile.patient?.parents[0].phone : this.state.patientProfile.patient?.phone} />
                            </FormGroup>
                            <FormGroup>
                                <Label>{'Address'.translate(this.props.lang)}</Label>
                                <Input type='text' disabled
                                    value={this.state.patientProfile.patient?.parents && this.state.patientProfile.patient?.parents[0] && this.state.patientProfile.patient?.parents[0].address ? this.state.patientProfile.patient?.parents[0].address : this.state.patientProfile.patient?.street + ',' + ' ' + this.state.patientProfile.patient?.zip + ' ' + this.state.patientProfile.patient?.city} />
                            </FormGroup>
                        </ModalBody>
                        <ModalFooter className='buttons-right-blue'>
                            <Button color="primary" onClick={() => this.setState({ patientProfile: !this.state.patientProfile })}>{'Close'.translate(this.props.lang)}</Button>

                        </ModalFooter>
                    </Modal>

                    : null}

                {
                    this.state.removeReferralModal ?
                        <DeleteModal
                            zIndex={99999}
                            lang={this.props.lang}
                            isOpen={this.state.removeReferralModal}
                            toggle={() => this.setState({ removeReferralModal: null })}
                            handler={() => { this.removeReferralFromList(this.state.removeReferralModal._id); }}
                        >
                            {'Do you want to delete the referral from the list of this clinic?'.translate(this.props.lang)}
                        </DeleteModal>
                        :
                        null
                }

                {
                    this.state.transferModal ?
                        eConnectEnabled ?
                            <ConsultationModal
                                {...this.props}
                                isOpen={this.state.transferModal}
                                referral={this.state.transferModal}
                                closeModal={() => this.setState({ transferModal: null }, () => {
                                    this.get()
                                })}
                            />
                            :

                            <Modal isOpen={this.state.transferModal} centered>
                                <ModalHeader toggle={() => this.setState({ transferModal: !this.state.transferModal, selectedDoctor: -1 })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ transferModal: !this.state.transferModal, selectedDoctor: -1 })}><Isvg src={xIcon} /></button>}>
                                    <h4>{'Transfer modal'.translate(this.props.lang)}</h4>
                                </ModalHeader>
                                <ModalBody>
                                    <FormGroup>
                                        <Label>{'Transfer from'.translate(this.props.lang)}</Label>
                                        <Input type='text' disabled
                                            value={this.state.transferModal.doctorName ? this.state.transferModal.doctorName : 'Unassigned'.translate(this.props.lang)} />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>{'Transfer to'.translate(this.props.lang)}</Label>
                                        <Input type='select'
                                            value={this.state.selectedDoctor}
                                            onChange={(e) => {
                                                this.setState({ selectedDoctor: e.target.value })
                                            }}
                                        >
                                            <option value={-1}>{'None'.translate(this.props.lang)}</option>
                                            {

                                                this.state.subAccounts && this.state.subAccounts.filter(el => el._id != this.state.transferModal.patientId).sort((a, b) => {
                                                    const nameA = a.name.toLowerCase();
                                                    const nameB = b.name.toLowerCase();
                                                    if (nameA < nameB) {
                                                        return -1
                                                    }
                                                    if (nameA > nameB) {
                                                        return 1
                                                    }
                                                }).map((item, idx) => {
                                                    if (item._id != this.state.transferModal.recipientUser)
                                                        return (
                                                            <option value={item._id}>{item.name}</option>
                                                        )
                                                })
                                            }
                                        </Input>
                                    </FormGroup>

                                </ModalBody>
                                <ModalFooter className='buttons-right-blue'>
                                    {
                                        this.state.transferModal.remissType != 'Drop-in' ?
                                            <Button color="primary" disabled={this.state.selectedDoctor == -1 ? true : false} onClick={() => this.transferReferral(this.state.transferModal)}>{'Transfer'.translate(this.props.lang)}</Button>
                                            :
                                            <Button color="primary" disabled={this.state.selectedDoctor == -1 ? true : false} onClick={() => this.transferDropIn(this.state.transferModal)}>{'Transfer'.translate(this.props.lang)}</Button>
                                    }
                                    <Button color="primary" onClick={() => this.setState({ transferModal: !this.state.transferModal, selectedDoctor: -1 })}>{'Close'.translate(this.props.lang)}</Button>

                                </ModalFooter>
                            </Modal>
                        :
                        null
                }

                {this.state.errorOverlap ?
                    <Modal isOpen={this.state.errorOverlap} centered>
                        {/* <ModalHeader style={{ margin: 'auto' }}>{'Error'.translate(this.props.lang)}</ModalHeader> */}
                        <ModalBody className="delete-modal">
                            {/* <img src={warrning} /> */}
                            <div className="message">
                                {this.state.errorOverlap && this.state.errorOverlap.message}
                            </div>
                        </ModalBody>
                        <ModalFooter style={{ display: 'flex', justifyContent: 'center' }} className="buttons-right-blue">
                            <Button style={{ minWidth: 100 }} color="primary" onClick={() => {
                                console.log(this.state.errorOverlap)
                                this.props[0].history.push({
                                    pathname: '/my-data/referrals/schedule',
                                    search: '?fromAllCases=true',
                                    state: {
                                        doctor: this.state.errorOverlap && this.state.errorOverlap.doctor,
                                        day: new Date((this.state.errorOverlap && this.state.errorOverlap.date) * 1000),
                                        step: 5,

                                    }
                                })
                            }} >{'Manage in calender'.translate(this.props.lang)}</Button>
                            <Button style={{ minWidth: 100 }} color="primary" onClick={() => this.setState({ errorOverlap: null })} >{'Close'.translate(this.props.lang)}</Button>{' '}
                        </ModalFooter>
                    </Modal>

                    :
                    null
                }
                {
                    this.state.previewAnswersReferralModal ?
                        <Modal isOpen={this.state.previewAnswersReferralModal} centered toggle={() => this.setState({ previewAnswersReferralModal: !this.state.previewAnswersReferralModal })} size="lg">
                            <ModalHeader toggle={() => this.setState({ previewAnswersReferralModal: !this.state.previewAnswersReferralModal })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ previewAnswersReferralModal: !this.state.previewAnswersReferralModal })}><Isvg src={xIcon} /></button>}>{'Form preview'.translate(this.props.lang)}</ModalHeader>

                            <ModalBody>

                                <FormPreview {...this.props} referral={{ name: this.state.previewAnswersReferralModal.name, description: this.state.previewAnswersReferralModal.description, intelligentReferral: this.state.previewAnswersReferralModal.intelligentReferral, items: this.state.previewAnswersReferralModal.questionaryData?.items, group: this.props.uData?.clinicGroups[selectedGroupIdx]?._id }} sections={this.generateSections(this.state.previewAnswersReferralModal.questionaryData.items)} answers={this.state.previewAnswersReferralModal.answers} disabledForm={true} hidePreview={true}></FormPreview>

                            </ModalBody>
                        </Modal>
                        :
                        null
                }
                {
                    this.state.previewAnswersModal ?
                        <Modal isOpen={this.state.previewAnswersModal} centered toggle={() => this.setState({ previewAnswersModal: !this.state.previewAnswersModal })} size="lg">
                            <ModalHeader toggle={() => this.setState({ previewAnswersModal: !this.state.previewAnswersModal })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ previewAnswersModal: !this.state.previewAnswersModal })}><Isvg src={xIcon} /></button>}>{'Form preview'.translate(this.props.lang)}</ModalHeader>

                            <ModalBody>

                                <FormPreview {...this.props} referral={{ name: this.state.previewAnswersModal.name, description: this.state.previewAnswersModal.description, intelligentReferral: this.state.previewAnswersModal.intelligentReferral, items: this.state.previewAnswersModal.items, group: this.props.uData?.clinicGroups[selectedGroupIdx]?._id }} sections={this.generateSections(this.state.previewAnswersModal.items)} answers={this.state.previewAnswersModal.answers} disabledForm={true} hidePreview={true}></FormPreview>

                            </ModalBody>
                        </Modal>
                        :
                        null
                }
                {
                    this.state.referral ?
                        <ReferralContent
                            {...this.props}
                            data={this.state.referral}
                            screen={'all-cases'}
                            doctorPage={true}
                            enterprise={enterprise}
                            adminInThisGroup={this.state.adminInThisGroup}
                            generateTimeReferral={(referral) => { this.generateTimeReferral(referral) }}
                            allowReferral={() => { this.allowReferral() }}
                            disallowReferral={(referral) => { this.setState({ disallowForm: referral }) }}
                            removeReferralModal={(item) => this.setState({ removeReferralModal: { ...item } })}
                            priorityModal={(referral) => {
                                this.setState({
                                    priorityModal: { ...referral }
                                })
                            }}
                            deleteCase={() => { this.setState({ deleteCase: true }) }}
                            pickUp={() => {
                                if (this.state.referral && this.state.referral.patient && this.state.referral.patient?.socialSecurityNumber == this.props.uData.socialSecurityNumber) {
                                    this.setState({ messageInfo: 'Unable to pick up this case. Both participants are using the same account.'.translate(this.props.lang) })
                                    return;
                                }
                                this.setState({ conversationModal: this.state.referral, conversationForm: { name: this.state.referral.patient?.referralNumber + '-' + pad(this.state.referral.conversations.length + 1, 2) } })
                            }}
                            closeCase={() => { this.setState({ closeCase: true }) }}
                            previewAnswersRevisit={(form) => {
                                this.setState({ previewAnswersModal: form })
                            }}
                            closeReferralContent={(data) => {
                                if (this.state.referral) {
                                    this.setState({
                                        referral: null
                                    })
                                }
                            }}
                        />
                        :
                        null
                }

                {this.state.referral && false ?
                    <Modal className='modal-header-unpaid' isOpen={this.state.referral} centered size="xl">
                        <ModalHeader toggle={() => this.setState({ referral: !this.state.referral })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ referral: !this.state.referral })}>&times;</button>}>
                            <div className='unpaid-header'>
                                <p>{'Referral preview'.translate(this.props.lang)}</p>



                            </div>
                        </ModalHeader>

                        <ModalBody>
                            <div className='unpaid-button-container'>
                                {
                                    this.state.referral.referralIsPaid || (this.state.referral.event && this.state.referral.event.appointmentPaid) || (this.state.referral.event && this.state.referral.event.visitReasonPrice == 0) || this.state.referral.questionaryPrice == 0 ?
                                        null
                                        :
                                        <button>
                                            <Isvg src={billInfoIcon} />
                                            {'UNPAID'.translate(this.props.lang)}
                                        </button>
                                }
                            </div>
                            [{
                                this.state.referral.priority ?
                                    <div className={
                                        this.state.referral.priority == 1 ? 'priority-circle p-red nohover' :
                                            this.state.referral.priority == 2 ? 'priority-circle p-orange nohover' :
                                                this.state.referral.priority == 3 ? 'priority-circle p-yellow nohover' :
                                                    this.state.referral.priority == 4 ? 'priority-circle p-black nohover' :
                                                        'priority-circle p-green nohover'
                                    }>
                                        {this.state.referral.priority}
                                    </div>
                                    :
                                    null
                            }]
                            <div dangerouslySetInnerHTML={{ __html: this.props.lang == 'se' ? this.state.referral.referralContent.protectReferralFiles(this.props.uData ? this.props.uData.mediaToken : '', this.state.referral._id) : this.state.referral.referralContentEn ? this.state.referral.referralContentEn.protectReferralFiles(this.props.uData ? this.props.uData.mediaToken : '', this.state.referral._id) : this.state.referral.referralContent.protectReferralFiles(this.props.uData ? this.props.uData.mediaToken : '', this.state.referral._id) }} onChange={() => { }}></div>
                            {
                                enterprise && this.state.referral.revisitFormsArray && this.state.referral.revisitFormsArray.length && this.state.referral.revisitFormsArray[this.state.activeRevisitForm - 1] ?
                                    this.state.referral.revisitFormsArray[this.state.activeRevisitForm - 1].referralData ?
                                        <div>
                                            <h6>{'Patient answers'.translate(this.props.lang)} <span style={{ marginLeft: 20, fontWeight: 600 }}>{this.state.referral.revisitFormsArray[this.state.activeRevisitForm - 1].name}</span> </h6>
                                            <div dangerouslySetInnerHTML={{ __html: this.props.lang == 'se' ? this.state.referral.revisitFormsArray[this.state.activeRevisitForm - 1].referralData.protectReferralFiles(this.props.uData ? this.props.uData.mediaToken : '', this.state.referral._id) : this.state.referral.revisitFormsArray[this.state.activeRevisitForm - 1].referralDataEn ? this.state.referral.revisitFormsArray[this.state.activeRevisitForm - 1].referralDataEn.protectReferralFiles(this.props.uData ? this.props.uData.mediaToken : '', this.state.referral._id) : this.state.referral.revisitFormsArray[this.state.activeRevisitForm - 1].referralData.protectReferralFiles(this.props.uData ? this.props.uData.mediaToken : '', this.state.referral._id) }}></div>

                                            {/* <div dangerouslySetInnerHTML={{ __html: this.state.referral.revisitFormsArray[this.state.activeRevisitForm - 1].referralData.protectReferralFiles(this.props.uData ? this.props.uData.mediaToken : '', this.state.referral._id)}}></div> */}
                                            {/* <div dangerouslySetInnerHTML={{ __html: this.state.referral.revisitFormsArray[this.state.activeRevisitForm - 1].referralData }}></div> */}
                                            {/* <div dangerouslySetInnerHTML={{ __html: this.state.referral.revisitFormsArray[this.state.activeRevisitForm - 1].referralTotalData }}></div> */}
                                            <Button color='primary' onClick={() => { this.setState({ previewAnswersModal: this.state.referral.revisitFormsArray[this.state.activeRevisitForm - 1] }) }}>{'Preview answers'.translate(this.props.lang)}</Button>
                                            <hr />
                                        </div>
                                        :
                                        <div>
                                            <h6>{'Patient answers'.translate(this.props.lang)} <span style={{ marginLeft: 20, fontWeight: 600 }}>{this.state.referral.revisitFormsArray[this.state.activeRevisitForm - 1].name}</span> </h6>
                                            <p style={{ fontStyle: 'italic', fontSize: 14 }}>{'The patient did not fill out the form'.translate(this.props.lang)}</p>
                                            <hr />
                                        </div>

                                    :
                                    null
                            }
                            {
                                this.state.referral && this.state.referral.answers && this.state.referral.questionaryData && this.state.referral.questionaryData.items ?
                                    <div style={{ marginBottom: 10 }}>
                                        <Button color='primary' onClick={() => { this.setState({ previewAnswersReferralModal: this.state.referral }) }}>{'Preview answers'.translate(this.props.lang)}</Button>
                                    </div>
                                    :
                                    null
                            }
                            <div className='patient-referral-preview-status-wrap'>
                                <div>
                                    <p> <strong>{'Status:'.translate(this.props.lang)}</strong> {humanReadable[this.state.referral.status]}</p>
                                    {this.state.referral.reason ?
                                        <p> <strong>{'Reason:'.translate(this.props.lang)}</strong> {this.state.referral.reason}</p>
                                        :
                                        null}
                                    {this.state.referral.patientReservedTimestamp ?
                                        <p> <strong>{'Scheduled time:'.translate(this.props.lang)}</strong> {this.props.getStringDateTs(this.state.referral.patientReservedTimestamp, `${this.props.dateFormat} HH:mm`)/*moment.unix(this.state.referral.patientReservedTimestamp).format(`${this.props.dateFormat} HH:mm`)*/}</p>
                                        :
                                        null}

                                    {
                                        this.state.referral.status == 'patient-requested-another-time' ?
                                            <p> <strong>{'Requested time:'.translate(this.props.lang)}</strong> {this.props.getStringDateTs(this.state.referral.patientRequestedTimestamp, `${this.props.dateFormat} HH:mm`)/*moment.unix(this.state.referral.patientRequestedTimestamp).format(`${this.props.dateFormat} HH:mm`)*/}</p>

                                            :
                                            null
                                    }

                                </div>
                                {
                                    enterprise && this.state.referral.revisitFormsArray && this.state.referral.revisitFormsArray.length ?
                                        <div className='doctor-preview-revisit-forms'>
                                            <div className='doctor-preview-revisit-forms-header'>
                                                {
                                                    this.state.activeRevisitForm ?
                                                        <span>{this.state.referral.revisitFormsArray[this.state.activeRevisitForm - 1].name} <span>({this.state.activeRevisitForm}/{this.state.referral.revisitFormsArray.length})</span></span>
                                                        :
                                                        <span>{this.state.referral.revisitFormsArray[0].name} <span>(1/{this.state.referral.revisitFormsArray.length})</span></span>
                                                }
                                            </div>
                                            <div className='buttons-next-prev-wrap'>
                                                <Button color='primary' disabled={this.state.activeRevisitForm && this.state.activeRevisitForm > 1 ? false : true} onClick={() => {
                                                    let activeRevisitForm = this.state.activeRevisitForm;
                                                    if (!activeRevisitForm) {
                                                        activeRevisitForm = 1;
                                                    }
                                                    if (activeRevisitForm > 1) {
                                                        activeRevisitForm--;
                                                    }
                                                    this.setState({ activeRevisitForm })
                                                }}>{'Previous'.translate(this.props.lang)}</Button>
                                                <Button color='primary' disabled={this.state.activeRevisitForm && this.state.activeRevisitForm < this.state.referral.revisitFormsArray.length ? false : true} onClick={() => {
                                                    let activeRevisitForm = this.state.activeRevisitForm;
                                                    if (!activeRevisitForm) {
                                                        activeRevisitForm = 1;
                                                    }
                                                    if (activeRevisitForm < this.state.referral.revisitFormsArray.length) {
                                                        activeRevisitForm++;
                                                    }
                                                    this.setState({ activeRevisitForm })
                                                }}>{'Next'.translate(this.props.lang)}</Button>
                                            </div>
                                        </div>
                                        :
                                        null

                                }
                            </div>


                        </ModalBody>
                        {this.state.referral.status == 'allowed' || this.state.referral.status == 'not-booked' || this.state.referral.status == 'canceled-by-clinic' ?
                            <ModalFooter style={{ margin: 'unset', justifyContent: 'flex-end', display: 'flex', width: '100%' }} className="buttons-right-blue">

                                <Button color="primary" style={{ margin: 10 }} onClick={() => this.generateTimeReferral(this.state.referral)}> {'ScheduleList'.translate(this.props.lang)} </Button>

                                <PrintHelper lang={this.props.lang} html={this.props.lang == 'se' ? this.state.referral.referralContent : this.state.referral.referralContentEn ? this.state.referral.referralContentEn : this.state.referral.referralContent} />
                                <Button color="primary" onClick={() => this.setState({ referral: null })}> {'Close'.translate(this.props.lang)} </Button>



                            </ModalFooter>
                            :
                            this.state.referral.status == 'waiting-for-approval' ?
                                <ModalFooter style={{ margin: 'unset', justifyContent: 'flex-end', display: 'flex', width: '100%' }} className="buttons-right-blue">

                                    {!this.state.referral.forwardRecipientClinic && !this.state.referral.forward && this.props.uData && this.props.uData.profession && this.props.uData.profession[this.props.selectedGroup] && this.state.referral.professionList && this.state.referral.professionList.filter(profession => {
                                        if (typeof this.props.uData.profession[this.props.selectedGroup] !== 'string') {
                                            return this.props.uData.profession[this.props.selectedGroup].indexOf(profession) !== -1
                                        } else {
                                            return profession == this.props.uData.profession[this.props.selectedGroup]
                                        }
                                    }).length ?
                                        <>
                                            <Button color="success" onClick={() => this.allowReferral()}><><i className="fa fa-check" />{'Allow'.translate(this.props.lang)}</></Button>
                                            <Button color="danger" onClick={() => this.setState({ disallowForm: this.state.referral })}><i className="fa fa-close" /> {'Disallow'.translate(this.props.lang)} </Button>


                                        </>
                                        :
                                        null


                                    }



                                    <PrintHelper lang={this.props.lang} html={this.props.lang == 'se' ? this.state.referral.referralContent : this.state.referral.referralContentEn ? this.state.referral.referralContentEn : this.state.referral.referralContent} />
                                    <Button color="primary" onClick={() => this.setState({ referral: null })}> {'Close'.translate(this.props.lang)} </Button>

                                </ModalFooter>
                                :
                                // this.state.referral.status == 'patient-requested-another-time' ?
                                //     <ModalFooter>
                                //         <Button color="success" onClick={() => this.acceptRequestedTime()}><i className="fa fa-check" /> {'Accept requested time'.translate(this.props.lang)}</Button>
                                //         <Button color="danger" onClick={() => this.setState({ scheduleForm: this.state.referral })}><i className="fa fa-close" /> {'Reject requested time'.translate(this.props.lang)} </Button>
                                //         <PrintHelper lang={this.props.lang} html={this.props.lang == 'se' ? this.state.referral.referralContent : this.state.referral.referralContentEn ? this.state.referral.referralContentEn : this.state.referral.referralContent} />

                                //         <Button color="primary" onClick={() => this.setState({ referral: null })}> {'Close'.translate(this.props.lang)} </Button>

                                //     </ModalFooter>
                                //     :
                                <ModalFooter style={{ margin: 'unset', justifyContent: 'flex-end', display: 'flex', width: '100%' }} className="buttons-right-blue">

                                    {
                                        this.state.referral.dropIn ?
                                            <Button onClick={() => {
                                                this.setState({
                                                    priorityModal: { ...this.state.referral }
                                                })
                                            }}>{'Set priority'.translate(this.props.lang)}</Button>
                                            :
                                            null
                                    }
                                    {
                                        this.state.adminInThisGroup && ((this.state.referral.questionaryPrice > 0 && !this.state.referral.referralIsPaid && !this.state.referral.control) || (this.state.referral.control && this.state.referral.event && this.state.referral.event.questionaryPrice > 0 && !this.state.referral.event.appointmentPaid)) ?
                                            <div className='red-button-wrap'>
                                                <Button color='danger' onClick={() => this.setState({ deleteCase: true })} >{'Delete case'.translate(this.props.lang)}</Button>
                                            </div>
                                            :
                                            null
                                    }
                                    {
                                        this.state.referral.status == 'waiting' && this.props.uData && this.props.uData.profession && this.props.uData.profession[this.props.selectedGroup] && this.state.referral.professionList && this.state.referral.professionList.filter(profession => {
                                            if (typeof this.props.uData.profession[this.props.selectedGroup] !== 'string') {
                                                return this.props.uData.profession[this.props.selectedGroup].indexOf(profession) !== -1
                                            } else {
                                                return profession == this.props.uData.profession[this.props.selectedGroup]
                                            }

                                        }).length ?
                                            <Button onClick={() => {
                                                if (this.state.referral && this.state.referral.patient && this.state.referral.patient?.socialSecurityNumber == this.props.uData.socialSecurityNumber) {
                                                    this.setState({ messageInfo: 'Unable to pick up this case. Both participants are using the same account.'.translate(this.props.lang) })
                                                    return;
                                                }
                                                this.setState({ conversationModal: this.state.referral, conversationForm: { name: this.state.referral.patient?.referralNumber + '-' + pad(this.state.referral.conversations.length + 1, 2) } })
                                            }} color="success">{'Pick up'.translate(this.props.lang)}</Button>
                                            :
                                            null
                                    }
                                    {
                                        (this.state.referral.status == 'revisit-scheduled' || this.state.referral.status === 'visit-rescheduled') && this.state.referral.closeCaseManually && this.state.referral.recipientUser == this.props.uData._id ?
                                            <div className='red-button-wrap'>
                                                <Button color='danger' onClick={() => this.setState({ closeCase: true })} >{'Close case'.translate(this.props.lang)}</Button>
                                            </div>
                                            :
                                            null
                                    }

                                    {
                                        this.state.referral.status == 'scheduled' || this.state.referral.status === 'visit-rescheduled' ?
                                            <>
                                                {
                                                    this.state.referral.recipientUser == this.props.uData._id ?
                                                        <div className='red-button-wrap'>
                                                            <Button onClick={() => this.setState({ closeCase: true })} color="danger">{'Close case'.translate(this.props.lang)}</Button>

                                                        </div>
                                                        :
                                                        null
                                                }
                                                <Button color="primary" style={{ margin: 10 }} onClick={() => {
                                                    this.props[0].history.push({
                                                        pathname: '/my-data/referrals/schedule',
                                                        search: '?fromAllCases=true',
                                                        state: {
                                                            doctor: this.state.referral.recipientUser,
                                                            day: new Date(this.state.referral.patientReservedTimestamp * 1000),
                                                            step: 5,

                                                        }
                                                    })
                                                }}> {'Manage in calendar'.translate(this.props.lang)} </Button>


                                            </>
                                            :
                                            null

                                    }
                                    {
                                        enterprise && this.state.referral.revisitFormsArray && this.state.referral.revisitFormsArray.length && this.state.referral.revisitFormsArray[this.state.activeRevisitForm - 1] ?
                                            <PrintHelper lang={this.props.lang} html={this.generateHtmlForPrint()} />

                                            :
                                            <PrintHelper lang={this.props.lang} html={this.props.lang == 'se' ? this.state.referral.referralContent : this.state.referral.referralContentEn ? this.state.referral.referralContentEn : this.state.referral.referralContent} />

                                    }
                                    {/* <PrintHelper lang={this.props.lang} html={this.props.lang == 'se' ? this.state.referral.referralContent : this.state.referral.referralContentEn ? this.state.referral.referralContentEn : this.state.referral.referralContent} /> */}

                                    <Button color="primary" onClick={() => this.setState({ referral: null })}> {'Close'.translate(this.props.lang)} </Button>

                                </ModalFooter>


                        }

                    </Modal>

                    :
                    null
                }
                {this.state.closeCase ?
                    <Modal isOpen={this.state.closeCase} centered>
                        {/* <ModalHeader style={{ margin: 'auto' }}>{'Warning'.translate(this.props.lang)}</ModalHeader> */}
                        <ModalBody className="delete-modal">
                            {/* <img src={warrning} /> */}
                            <p className="message">{`Are you sure you want to close the case?`.translate(this.props.lang)}</p>
                        </ModalBody>
                        <ModalFooter className='buttons-right-blue'>
                            <Button color="primary" style={{ margin: 10, minWidth: 100 }} onClick={() => this.setState({ closeCase: false }, () => this.closeReferral())}>{'Yes'.translate(this.props.lang)}</Button>
                            <Button color="primary" style={{ margin: 10, minWidth: 100 }} onClick={() => this.setState({ closeCase: false })}>{'No'.translate(this.props.lang)}</Button>

                        </ModalFooter>
                    </Modal>
                    : null}
                {this.state.deleteCase ?
                    <Modal isOpen={this.state.deleteCase} centered>
                        {/* <ModalHeader style={{ margin: 'auto' }}>{'Warning'.translate(this.props.lang)}</ModalHeader> */}
                        <ModalBody className="delete-modal">
                            {/* <img src={warrning} /> */}
                            <p className="message">{`Are you sure you want to delete the case?`.translate(this.props.lang)}</p>
                        </ModalBody>
                        <ModalFooter className='buttons-right-blue'>
                            <Button color="primary" style={{ margin: 10, minWidth: 100 }} onClick={() => this.setState({ deleteCase: false }, () => this.deleteReferral())}>{'Yes'.translate(this.props.lang)}</Button>
                            <Button color="primary" style={{ margin: 10, minWidth: 100 }} onClick={() => this.setState({ deleteCase: false })}>{'No'.translate(this.props.lang)}</Button>

                        </ModalFooter>
                    </Modal>
                    : null}

                {
                    this.state.conversationModal ?
                        <InfoModal
                            isOpen={this.state.conversationModal}
                            onClose={(item) => {
                                this.setState({
                                    conversationModal: item
                                })
                            }}
                            close={true}
                            toggle={() => this.setState({ conversationModal: !this.state.conversationModal })}
                            header={'Info'.translate(this.props.lang)}
                            info={'Do you want to pick up this case?'.translate(this.props.lang)}
                            buttons={[
                                <Button color="primary" disabled={this.state.loadingAssign} style={{ margin: 10 }} onClick={() => this.assignReferral(this.state.conversationModal)}>{'Yes'.translate(this.props.lang)}</Button>,
                                <Button color="primary" style={{ margin: 10 }} onClick={() => this.setState({ conversationModal: false })}>{'No'.translate(this.props.lang)}</Button>


                            ]}
                        />
                        :
                        null
                }
                {
                    this.state.messageInfo ?
                        <InfoModal
                            isOpen={this.state.messageInfo}

                            toggle={() => this.setState({ messageInfo: null })}
                            header={'Info'.translate(this.props.lang)}
                            info={this.state.messageInfo}
                            buttons={[
                                <Button color='primary' onClick={() => {
                                    this.setState({
                                        messageInfo: null,
                                    })

                                }}>{'Close'.translate(this.props.lang)}</Button>
                            ]}
                        />
                        :
                        null

                }
                {
                    this.state.scheduleListReferral ?
                        <Modal isOpen={this.state.scheduleListReferral} centered>
                            <ModalHeader toggle={() => this.setState({ scheduleReferral: !this.state.scheduleListReferral, doctorsList: [], listDoctor: null })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ scheduleListReferral: !this.state.scheduleListReferral, doctorsList: [], listDoctor: null })}>&times;</button>}>{'Schedule referral'.translate(this.props.lang)}</ModalHeader>

                            <ModalBody>

                                <FormGroup>

                                    <Label>{'Doctor'.translate(this.props.lang)}</Label>
                                    <Input type='select'
                                        onChange={e => this.setState({ listDoctor: e.target.value == -1 ? null : e.target.value, loadingDoctor: true, scroll: false }, () => {
                                            this.getWorkingTime(this.state.listDoctor)
                                        })}>
                                        <option value={-1}>{'None'.translate(this.props.lang)}</option>

                                        {this.state.subAccounts?.filter(userObj => userObj.socialSecurityNumber !== this.state.scheduleListReferral?.patient?.socialSecurityNumber)?.sort((a, b) => {
                                            const nameA = a.name.toLowerCase();
                                            const nameB = b.name.toLowerCase();
                                            if (nameA < nameB) {
                                                return -1
                                            }
                                            if (nameA > nameB) {
                                                return 1
                                            }
                                        }).map(item => {
                                            return <option value={item._id}>
                                                {item.name} - {item.username}
                                            </option>
                                        })}

                                    </Input>

                                </FormGroup>
                                <FormGroup>

                                    <Label>{'Patient'.translate(this.props.lang)}</Label>
                                    <Input type='text' value={`${this.state.scheduleListReferral.patient?.name}${" " + humanReadable[this.state.scheduleListReferral.status].translate(this.props.lang)}${this.state.scheduleListReferral.onlineVisit ? (' - ' + 'Online'.translate(this.props.lang)) : ''}`}>


                                    </Input>
                                </FormGroup>
                                <FormGroup>
                                    <DatePicker
                                        label={'Start date'.translate(this.props.lang)}
                                        value={this.state.scheduleListReferral.date}
                                        onChange={(e) => {
                                            let scheduleListReferral = this.state.scheduleListReferral;
                                            scheduleListReferral.date = e;
                                            this.setState({
                                                scheduleListReferral
                                            })
                                            if (Math.floor(new Date(this.state.scheduleListReferral.date + ' ' + this.state.scheduleListReferral.startTime) / 1000) > Math.floor(new Date(this.state.scheduleListReferral.endDate + ' ' + this.state.scheduleListReferral.endTime) / 1000)) {
                                                scheduleListReferral.endDate = this.state.scheduleListReferral.date
                                                this.setState({
                                                    scheduleListReferral
                                                })
                                            }
                                        }}
                                    />
                                </FormGroup>
                                <FormGroup className="timepicker-container timepicker-wide">
                                    <Label>{'Start time'.translate(this.props.lang)}</Label>
                                    &nbsp;

                                    <TimePickerV2
                                        getStringDateTs={this.props.getStringDateTs}
                                        format={'HH:mm'}
                                        value={this.state.scheduleListReferral.startTime}
                                        // value={moment(new Date(this.state.scheduleListReferral.date.replace(/-/g, "/") + " " + this.state.scheduleListReferral.startTime))}
                                        minValue={this.props.getStringDateTs(Math.floor(this.state.min.getTime() / 1000), 'HH:mm')} //moment.unix(this.state.min.getTime() / 1000).format("HH:mm")
                                        maxValue={this.props.getStringDateTs(Math.floor(this.state.max.getTime() / 1000 - 300), 'HH:mm')} //moment.unix(this.state.max.getTime() / 1000).format("HH:mm")
                                        flag={0}
                                        onChange={(time1, timeString) => {

                                            let scheduleListReferral = this.state.scheduleListReferral;
                                            scheduleListReferral.startTime = timeString

                                            let startTimeSplit = scheduleListReferral.startTime.split(":");
                                            let endTimeSplit = scheduleListReferral.endTime.split(":");

                                            let startTimeHourMinute = Number(startTimeSplit[0]) * 60 + Number(startTimeSplit[1]);
                                            let endTimeHourMinute = Number(endTimeSplit[0]) * 60 + Number(endTimeSplit[1]);
                                            // this.setState({ scheduleListReferral })
                                            if (startTimeHourMinute < endTimeHourMinute) {
                                                this.setState({ scheduleListReferral })
                                            } else {
                                                scheduleListReferral.endTime = Math.trunc((Number(this.state.scheduleListReferral.startTime.split(':')[0]) * 60 + Number(this.state.scheduleListReferral.startTime.split(':')[1]) + 5) / 60) + ":" + (Number(this.state.scheduleListReferral.startTime.split(':')[0]) * 60 + Number(this.state.scheduleListReferral.startTime.split(':')[1]) + 5) % 60
                                                const padded = scheduleListReferral?.endTime?.split?.(':');
                                                if (padded && Array.isArray(padded) && padded.length && isStringAValidNumber(padded[0]) && isStringAValidNumber(padded[1])) {
                                                    const h = padded[0]?.padStart?.(2, '0');
                                                    const m = padded[1]?.padStart?.(2, '0');
                                                    if (h && m) scheduleListReferral.endTime = `${h}:${m}`;
                                                }
                                                this.setState({
                                                    scheduleListReferral
                                                })
                                            }


                                        }}
                                    />
                                    {/* <TimePicker
                                        getStringDateTs={this.props.getStringDateTs}
                                        format={'HH:mm'}
                                        value={moment(new Date(this.state.scheduleListReferral.date.replace(/-/g, "/") + " " + this.state.scheduleListReferral.startTime))}
                                        minValue={this.props.getStringDateTs(Math.floor(this.state.min.getTime() / 1000), 'HH:mm')} //moment.unix(this.state.min.getTime() / 1000).format("HH:mm")
                                        maxValue={this.props.getStringDateTs(Math.floor(this.state.max.getTime() / 1000), 'HH:mm')} //moment.unix(this.state.max.getTime() / 1000).format("HH:mm")
                                        flag={0}
                                        onChange={(time1, timeString) => {

                                            let scheduleListReferral = this.state.scheduleListReferral;
                                            scheduleListReferral.startTime = timeString

                                            let startTimeSplit = scheduleListReferral.startTime.split(":");
                                            let endTimeSplit = scheduleListReferral.endTime.split(":");

                                            let startTimeHourMinute = Number(startTimeSplit[0]) * 60 + Number(startTimeSplit[1]);
                                            let endTimeHourMinute = Number(endTimeSplit[0]) * 60 + Number(endTimeSplit[1]);
                                            this.setState({ scheduleListReferral })

                                        }}
                                        allowClear={false}
                                    /> */}
                                </FormGroup>
                                <FormGroup className="timepicker-container timepicker-wide">
                                    <Label>{'End time'.translate(this.props.lang)}</Label>
                                    &nbsp;
                                    <TimePickerV2
                                        getStringDateTs={this.props.getStringDateTs}
                                        format={'HH:mm'}
                                        value={this.state.scheduleListReferral.endTime}
                                        // value={moment(new Date(this.state.scheduleListReferral.date.replace(/-/g, "/") + " " + this.state.scheduleListReferral.endTime))}
                                        minValue={Math.trunc((Number(this.state.scheduleListReferral.startTime.split(':')[0]) * 60 + Number(this.state.scheduleListReferral.startTime.split(':')[1]) + 5) / 60) + ":" + (Number(this.state.scheduleListReferral.startTime.split(':')[0]) * 60 + Number(this.state.scheduleListReferral.startTime.split(':')[1]) + 5) % 60}
                                        maxValue={this.props.getStringDateTs(Math.floor(this.state.max.getTime() / 1000), 'HH:mm')} // moment.unix(this.state.max.getTime() / 1000).format("HH:mm")
                                        flag={0}
                                        onChange={(time1, timeString) => {

                                            let scheduleListReferral = this.state.scheduleListReferral;
                                            scheduleListReferral.endTime = timeString

                                            let startTimeSplit = scheduleListReferral.startTime.split(":");
                                            let endTimeSplit = scheduleListReferral.endTime.split(":");

                                            let startTimeHourMinute = Number(startTimeSplit[0]) * 60 + Number(startTimeSplit[1]);
                                            let endTimeHourMinute = Number(endTimeSplit[0]) * 60 + Number(endTimeSplit[1]);

                                            if (startTimeHourMinute < endTimeHourMinute) {
                                                this.setState({ scheduleListReferral })
                                            }
                                        }}
                                    />
                                    {/* <TimePicker
                                        getStringDateTs={this.props.getStringDateTs}
                                        format={'HH:mm'}
                                        value={moment(new Date(this.state.scheduleListReferral.date.replace(/-/g, "/") + " " + this.state.scheduleListReferral.endTime))}
                                        minValue={Math.trunc((Number(this.state.scheduleListReferral.startTime.split(':')[0]) * 60 + Number(this.state.scheduleListReferral.startTime.split(':')[1]) + 5) / 60) + ":" + (Number(this.state.scheduleListReferral.startTime.split(':')[0]) * 60 + Number(this.state.scheduleListReferral.startTime.split(':')[1]) + 5) % 60}
                                        maxValue={this.props.getStringDateTs(Math.floor(this.state.max.getTime() / 1000), 'HH:mm')} // moment.unix(this.state.max.getTime() / 1000).format("HH:mm")
                                        flag={0}
                                        onChange={(time1, timeString) => {

                                            let scheduleListReferral = this.state.scheduleListReferral;
                                            scheduleListReferral.endTime = timeString

                                            let startTimeSplit = scheduleListReferral.startTime.split(":");
                                            let endTimeSplit = scheduleListReferral.endTime.split(":");

                                            let startTimeHourMinute = Number(startTimeSplit[0]) * 60 + Number(startTimeSplit[1]);
                                            let endTimeHourMinute = Number(endTimeSplit[0]) * 60 + Number(endTimeSplit[1]);

                                            if (startTimeHourMinute < endTimeHourMinute) {
                                                this.setState({ scheduleListReferral })
                                            }
                                        }}
                                        allowClear={false}
                                    /> */}
                                </FormGroup>
                                {
                                    this.state.listDoctor ?
                                        <FormGroup>
                                            <Label>{'Note'.translate(this.props.lang)}</Label>
                                            <Input type='textarea'
                                                name="note"
                                                style={{ height: '150px' }}
                                                onChange={(e) => {
                                                    let scheduleListReferral = this.state.scheduleListReferral;
                                                    scheduleListReferral.patientNote = striptags(e.target.value);
                                                    this.setState({
                                                        scheduleListReferral
                                                    })
                                                }}
                                                value={this.state.scheduleListReferral.patientNote}
                                            />
                                        </FormGroup>
                                        :
                                        null
                                }
                                {
                                    this.state.listDoctor ?
                                        <FormGroup>
                                            <Button onClick={() => this.setState({
                                                doctorsModal: 5
                                            })} color="primary">
                                                {'Add caregiver'.translate(this.props.lang)}
                                            </Button>
                                        </FormGroup>

                                        :

                                        null
                                }

                                {
                                    this.state.scheduleListReferral.controlSchedule ?
                                        <Button color='primary' disabled={this.state.listDoctor ? false : true} onClick={() => this.scheduleControl({ ...this.state.scheduleListReferral, rescheduleRevisit: true })}>{'Schedule referral'.translate(this.props.lang)}</Button>
                                        :
                                        <Button color='primary' disabled={this.state.listDoctor ? false : true} onClick={() => {
                                            if (this.state.scheduleListReferral.status === 'requested-new-appointment') {
                                                this.listScheduleReferral({ ...this.state.scheduleListReferral, rescheduleReferral: true })
                                            } else {
                                                this.listScheduleReferral({ ...this.state.scheduleListReferral, rescheduleReferral: false })
                                            }
                                        }}>{'Schedule referral'.translate(this.props.lang)}</Button>

                                }

                            </ModalBody>
                        </Modal>
                        :
                        null
                }
                {
                    this.state.doctorsModal ?
                        <Modal size="lg" isOpen={this.state.doctorsModal} centered toggle={() => this.setState({ doctorsModal: !this.state.doctorsModal })} >
                            <ModalHeader toggle={() => this.setState({ doctorsModal: !this.state.doctorsModal })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ doctorsModal: !this.state.doctorsModal })}><Isvg src={xIcon} /></button>}>{'Choose doctors'.translate(this.props.lang)}</ModalHeader>

                            <ModalBody className="run-modal">
                                <FormGroup className="form-group-checkbox">
                                    {
                                        this.state.doctors && this.state.doctors.length && this.state.doctors.filter((item) => {
                                            if (this.state.listDoctor && item._id != this.state.listDoctor) {
                                                return true;
                                            } else {
                                                return false;
                                            }
                                        }).length ?
                                            this.state.doctors.sort((a, b) => {
                                                const nameA = a.name;
                                                const nameB = b.name;
                                                if (nameA < nameB) {
                                                    return -1
                                                }
                                                if (nameA > nameB) {
                                                    return 1
                                                }
                                            }).map((item, idx) => {
                                                if (item._id != this.state.listDoctor)
                                                    return (
                                                        <Checkbox
                                                            checked={this.state.doctorsList && this.state.doctorsList.filter(doctor => doctor == item._id).length}
                                                            onChange={(e) => {

                                                                let doctorsList = this.state.doctorsList;
                                                                // console.log(doctorsList)
                                                                let exists = false;
                                                                for (let i = 0; i < doctorsList.length; i++) {
                                                                    if (doctorsList[i] == item._id) {
                                                                        exists = true;
                                                                    }
                                                                }
                                                                if (!exists) {
                                                                    doctorsList.push(item._id)
                                                                } else {
                                                                    let index = doctorsList.indexOf(item._id);
                                                                    if (index > -1) {
                                                                        doctorsList.splice(index, 1);
                                                                    }
                                                                }
                                                                // console.log(doctorsList)
                                                                this.setState({ doctorsList })


                                                            }}
                                                            label={item.name} />
                                                    )
                                            })
                                            :
                                            "The doctor's list is empty".translate(this.props.lang)
                                    }
                                </FormGroup>


                            </ModalBody>
                            <ModalFooter className='buttons-right-blue'>
                                {
                                    this.state.doctors && this.state.doctors.length && this.state.doctors.filter((item) => {
                                        if (this.props.uData._id && item._id != this.props.uData._id) {
                                            return true;
                                        } else {
                                            return false;
                                        }
                                    }).length ?
                                        <Button color='primary'
                                            // disabled={this.state.doctorsList && this.state.doctorsList.length > 0 ? false : true}
                                            onClick={() =>
                                                this.checkDoctorsAvailable(this.state.doctorsList, this.state.doctorsModal)
                                            }>{'Save'.translate(this.props.lang)}</Button>
                                        :
                                        null
                                }
                                <Button color='primary' onClick={() => {
                                    // console.log(this.state.doctorsList)
                                    // console.log(this.state.updateEvent.additionalDoctors)
                                    this.setState({ doctorsModal: false })
                                }
                                }>{"Close".translate(this.props.lang)}</Button>
                            </ModalFooter>
                        </Modal>
                        :
                        null
                }
                {
                    this.state.errorAdditionalDoctors ?

                        <ErrorModal lang={this.props.lang}
                            isOpen={this.state.errorAdditionalDoctors}
                            toggle={() => {
                                // let newDoctorList = this.state.doctorsList;
                                // if (newDoctorList && newDoctorList.length) {
                                //     for (let i = 0; i < newDoctorList.length; i++) {
                                //         if (this.state.errorAdditionalDoctors && this.state.errorAdditionalDoctors.length && this.state.errorAdditionalDoctors.filter(item => item.doctorId == newDoctorList[i] && !item.available).length) {
                                //             let index = newDoctorList.indexOf(newDoctorList[i]);
                                //             if (index > -1) {
                                //                 newDoctorList.splice(index, 1);
                                //             }
                                //         }
                                //     }
                                // }

                                // this.setState({ doctorsList: newDoctorList }, () => this.setState({ errorAdditionalDoctors: null }))

                                this.setState({ errorAdditionalDoctors: null })
                            }}

                        >
                            {
                                this.state.errorAdditionalDoctors && this.state.errorAdditionalDoctors.map((item, idx) => {
                                    return (
                                        <div>
                                            <span style={{ fontWeight: 600 }}>
                                                {item.doctorName}  {' - '}
                                            </span>

                                            {
                                                item.available ?
                                                    <span style={{ color: 'green' }}>{'Available'.translate(this.props.lang)}</span>
                                                    :
                                                    <span style={{ color: 'red' }}>{'Unavailable'.translate(this.props.lang)}</span>
                                            }

                                        </div>
                                    )
                                })

                            }
                        </ErrorModal>
                        :
                        null
                }
                {
                    this.state.wrongDate ?
                        <Modal isOpen={this.state.wrongDate} centered>
                            {/* <ModalHeader style={{ margin: 'auto' }}>
                                {'Warning'.translate(this.props.lang)}
                            </ModalHeader> */}
                            <ModalBody className="delete-modal">
                                {/* <img src={warrning} /> */}
                                <p>{'You can not pick date form the past'.translate(this.props.lang)}</p>
                            </ModalBody>
                            <ModalFooter className="buttons-right-blue">
                                <Button style={{ minWidth: 100 }} color='primary' onClick={() => this.setState({ wrongDate: !this.state.wrongDate })}>{'Pick again'.translate(this.props.lang)}</Button>
                            </ModalFooter>
                        </Modal>
                        : null
                }
                {
                    this.state.disallowForm ?
                        <FormBuilder selectedGroup={this.props.selectedGroup} selectedClinic={this.props.selectedClinic} countryTwoLetter={this.props.countryTwoLetter} country={this.props.country} dateFormat={this.props.dateFormat}
                            getStringDateTs={this.props.getStringDateTs}
                            lang={this.props.lang}
                            onSubmit={(data) => this.disallowReferral(data)}
                            isOpen={this.state.disallowForm}
                            toggle={() => this.setState({ disallowForm: null })}
                            title={'Disallow referral'.translate(this.props.lang)}
                            buttonText={'Submit'.translate(this.props.lang)}
                            fields={[
                                {
                                    type: 'row',
                                    children: [
                                        {
                                            type: 'col',
                                            width: {
                                                lg: 12,
                                                sm: 12,
                                                xs: 12
                                            },
                                            children: [
                                                {
                                                    type: 'textarea',
                                                    name: 'reason',
                                                    label: 'Give reason'.translate(this.props.lang),
                                                    validate: [required('Reason is required!'.translate(this.props.lang))]
                                                },
                                            ]
                                        },

                                    ]
                                }
                            ]}
                        ></FormBuilder>
                        :
                        null
                }
                {
                    this.state.returnForm ?
                        <FormBuilder selectedGroup={this.props.selectedGroup} selectedClinic={this.props.selectedClinic} countryTwoLetter={this.props.countryTwoLetter} country={this.props.country} dateFormat={this.props.dateFormat}
                            getStringDateTs={this.props.getStringDateTs}
                            lang={this.props.lang}
                            onSubmit={(data) => this.returnForUpdate(data)}
                            isOpen={this.state.returnForm}
                            toggle={() => this.setState({ returnForm: null })}
                            title={'Return for referral update'.translate(this.props.lang)}
                            buttonText={'Submit'.translate(this.props.lang)}
                            fields={[
                                {
                                    type: 'row',
                                    children: [
                                        {
                                            type: 'col',
                                            width: {
                                                lg: 12,
                                                sm: 12,
                                                xs: 12
                                            },
                                            children: [
                                                {
                                                    type: 'textarea',
                                                    name: 'reason',
                                                    label: 'Reason'.translate(this.props.lang),
                                                    validate: [required('Reason is required!'.translate(this.props.lang))]
                                                },
                                            ]
                                        },

                                    ]
                                }
                            ]}
                        ></FormBuilder>
                        :
                        null
                }
                {
                    this.state.allowForm ?
                        <FormBuilder selectedGroup={this.props.selectedGroup} selectedClinic={this.props.selectedClinic} countryTwoLetter={this.props.countryTwoLetter} country={this.props.country} dateFormat={this.props.dateFormat}
                            getStringDateTs={this.props.getStringDateTs}
                            lang={this.props.lang}
                            onSubmit={(data) => this.allowReferral(data)}
                            isOpen={this.state.allowForm}
                            toggle={() => this.setState({ allowForm: null })}
                            title={'Allow referral'.translate(this.props.lang)}
                            buttonText={'Submit'.translate(this.props.lang)}
                            fields={[
                                {
                                    type: 'row',
                                    children: [
                                        {
                                            type: 'col',
                                            width: {
                                                lg: 6,
                                                sm: 12,
                                                xs: 12
                                            },
                                            children: [
                                                {
                                                    type: 'date',
                                                    name: 'date',
                                                    label: 'Date'.translate(this.props.lang),
                                                    validate: [required('Date is required!'.translate(this.props.lang))]
                                                },
                                            ]
                                        },
                                        {
                                            type: 'col',
                                            width: {
                                                lg: 6,
                                                sm: 12,
                                                xs: 12
                                            },
                                            children: [
                                                {
                                                    type: 'time',
                                                    name: 'time',
                                                    label: 'Time'.translate(this.props.lang),
                                                    validate: [required('Time is required!'.translate(this.props.lang))]
                                                },
                                            ]
                                        },
                                    ]
                                }
                            ]}
                        ></FormBuilder>
                        :
                        null
                }
                {
                    this.state.scheduleForm ?
                        <FormBuilder selectedGroup={this.props.selectedGroup} selectedClinic={this.props.selectedClinic} countryTwoLetter={this.props.countryTwoLetter} country={this.props.country} dateFormat={this.props.dateFormat}
                            getStringDateTs={this.props.getStringDateTs}
                            lang={this.props.lang}
                            onSubmit={(data) => this.rejectRequestedTime(data)}
                            isOpen={this.state.scheduleForm}
                            toggle={() => this.setState({ scheduleForm: null })}
                            title={'Schedule new time'.translate(this.props.lang)}
                            buttonText={'Submit'.translate(this.props.lang)}
                            fields={[
                                {
                                    type: 'row',
                                    children: [
                                        {
                                            type: 'col',
                                            width: {
                                                lg: 6,
                                                sm: 12,
                                                xs: 12
                                            },
                                            children: [
                                                {
                                                    type: 'date',
                                                    name: 'date',
                                                    label: 'Date'.translate(this.props.lang),
                                                    validate: [required('Date is required!'.translate(this.props.lang))]
                                                },
                                            ]
                                        },
                                        {
                                            type: 'col',
                                            width: {
                                                lg: 6,
                                                sm: 12,
                                                xs: 12
                                            },
                                            children: [
                                                {
                                                    type: 'time',
                                                    name: 'time',
                                                    label: 'Time'.translate(this.props.lang),
                                                    validate: [required('Time is required!'.translate(this.props.lang))]
                                                },
                                            ]
                                        },
                                    ]
                                }
                            ]}
                        ></FormBuilder>
                        :
                        null
                }
                {
                    this.state.selectClinicModal ?
                        <Modal isOpen={this.state.selectClinicModal} centered toggle={() => this.setState({ selectClinicModal: !this.state.selectClinicModal })} size="xl">
                            <ModalHeader>
                                <h4>{'Search Curoflow database'.translate(this.props.lang)}</h4>
                            </ModalHeader>
                            <ModalBody>
                                <ReferralClinics {...this.props} patientData={this.state.patientData} searchFor={this.state.referral.articleName} includeTags={this.state.tags} importClinic={(clinic) => {
                                    this.setState({
                                        checkForward: clinic
                                    })

                                }}></ReferralClinics>
                            </ModalBody>
                        </Modal>
                        :
                        null
                }
                {
                    this.state.checkForward ?
                        <Modal isOpen={this.state.checkForward} centered toggle={() => this.setState({ checkForward: !this.state.checkForward })} size="xl">
                            {/* <ModalHeader>
                                <h4></h4>
                            </ModalHeader> */}
                            <ModalBody className='delete-modal'>
                                <p> {'Are you shure that you want to continue?'.translate(this.props.lang)} {'This referral will be sent to'.translate(this.props.lang)} {this.state.checkForward.name}.</p>
                            </ModalBody>
                            <ModalFooter className='button-right-container'>
                                <Button style={{ minWidth: 100 }} onClick={() => this.forward(this.state.checkForward)}>{'Continue'.translate(this.props.lang)}</Button>
                            </ModalFooter>
                        </Modal>
                        :
                        null
                }
                {
                    this.state.managerModal ?
                        <Modal isOpen={this.state.managerModal} centered toggle={() => this.setState({ managerModal: !this.state.managerModal })} >
                            <ModalHeader>
                                <h4>{'Request for payment'.translate(this.props.lang)}</h4>
                            </ModalHeader>
                            <ModalBody>
                                <div>
                                    <div class="form-check">
                                        <input type="checkbox" class="form-check-input" onChange={() => this.setState({ changeButton: !this.state.changeButton })} />
                                        <label class="form-check-label" for="paymentChecked" >Accept payment</label>
                                    </div>
                                    <Button onClick={this.state.changeButton ? () => { this.managerMail(this.state.managerModal), this.setState({ changeButton: !this.state.changeButton }) } : () => this.setState({ signinModal: this.state.managerModal, managerModal: false })}>{this.state.changeButton ? 'Sent to region manager' : 'Sign and allow'}</Button>


                                </div>
                            </ModalBody>
                        </Modal>
                        :
                        null
                }

                {this.state.error ?

                    <ErrorModal lang={this.props.lang}
                        isOpen={this.state.error}
                        toggle={() => this.setState({ error: null })}

                    >
                        {this.state.error.translate(this.props.lang)}
                    </ErrorModal>

                    :
                    null
                }

                {
                    this.state.priorityModal ?
                        <Modal isOpen={this.state.priorityModal} centered >

                            <ModalBody>
                                <div className='priority-circle-row'>
                                    <div className={this.state.priorityModal.priority == 1 ? 'priority-circle p-red red-active' : 'priority-circle p-red'} onClick={() => {
                                        let priorityModal = this.state.priorityModal
                                        priorityModal.priority = 1
                                        this.setState({
                                            priorityModal
                                        })
                                    }}>1</div>
                                    <div className={this.state.priorityModal.priority == 2 ? 'priority-circle p-orange orange-active' : 'priority-circle p-orange'} onClick={() => {
                                        let priorityModal = this.state.priorityModal
                                        priorityModal.priority = 2
                                        this.setState({
                                            priorityModal
                                        })
                                    }}>2</div>
                                    <div className={this.state.priorityModal.priority == 3 ? 'priority-circle p-yellow yellow-active' : 'priority-circle p-yellow'} onClick={() => {
                                        let priorityModal = this.state.priorityModal
                                        priorityModal.priority = 3
                                        this.setState({
                                            priorityModal
                                        })
                                    }}>3</div>
                                    <div className={this.state.priorityModal.priority == 4 ? 'priority-circle p-black black-active' : 'priority-circle p-black'} onClick={() => {
                                        let priorityModal = this.state.priorityModal
                                        priorityModal.priority = 4
                                        this.setState({
                                            priorityModal
                                        })
                                    }}>4</div>
                                    <div className={this.state.priorityModal.priority == 5 ? 'priority-circle p-green green-active' : 'priority-circle p-green'} onClick={() => {
                                        let priorityModal = this.state.priorityModal
                                        priorityModal.priority = 5
                                        this.setState({
                                            priorityModal
                                        })
                                    }}>5</div>
                                </div>
                            </ModalBody>
                            <ModalFooter className='buttons-right-blue'>
                                <Button onClick={() => {
                                    this.priorityForDropIn(this.state.priorityModal)
                                    this.setState({
                                        priorityModal: null
                                    })
                                }}>{'Confirm'.translate(this.props.lang)}</Button>
                                <Button color='primary'
                                    disabled={!this.state.priorityModal.priority}
                                    onClick={() => {
                                        let priorityModal = this.state.priorityModal
                                        priorityModal.priority = null;
                                        this.setState({
                                            priorityModal
                                        })
                                    }}>{'Remove'.translate(this.props.lang)}</Button>
                                <Button onClick={() => {
                                    this.setState({
                                        priorityModal: null
                                    })
                                }}>{'Cancel'.translate(this.props.lang)}</Button>
                            </ModalFooter>
                        </Modal>
                        : null
                }

                {this.state.signinModal ?
                    <Modal isOpen={this.state.signinModal} centered toggle={() => this.setState({ signinModal: !this.state.signinModal })} >
                        <ModalHeader>
                            <h4>{'Sign in with your NET ID'.translate(this.props.lang)}</h4>
                        </ModalHeader>
                        <ModalBody>
                            <Button onClick={() => this.allowReferral({})}>{'Continue'.translate(this.props.lang)}</Button>
                        </ModalBody>
                    </Modal>
                    : null}

                {this.state.scheduleReferral ?
                    <Modal isOpen={this.state.scheduleReferral} centered>
                        {/* <ModalHeader style={{ margin: 'auto' }}>{'Schedule case'.translate(this.props.lang)}</ModalHeader> */}
                        <ModalBody className='delete-modal'>
                            {/* <Isvg src={QuestionMark} /> */}
                            <p className='message'>{'Do you want to schedule the selected referral?'.translate(this.props.lang)}</p>
                        </ModalBody>
                        <ModalFooter className='buttons-right-blue'>
                            <Button color='primary' style={{ margin: 10, minWidth: 100 }} onClick={() => {
                                this.props[0].history.push(`/my-data/referrals/working-hours`)
                            }}>{'Yes'.translate(this.props.lang)}</Button>
                            <Button color='primary' style={{ margin: 10, minWidth: 100 }} onClick={() => this.setState({
                                scheduleReferral: false
                            })}>{'No'.translate(this.props.lang)}</Button>
                        </ModalFooter>
                    </Modal>
                    : null}

                {this.state.referralNote ?
                    <Modal isOpen={this.state.referralNote} centered>
                        <ModalHeader style={{ margin: 'auto' }}>{'Note'.translate(this.props.lang)}</ModalHeader>
                        <ModalBody className="delete-modal">
                            <Input type="textarea" style={{ height: 100 }} value={this.state.referralNote.note} onChange={(e) => {
                                let referralNote = this.state.referralNote;
                                referralNote.note = e.target.value;
                                this.setState({ referralNote })
                            }} />
                        </ModalBody>
                        <ModalFooter className='buttons-right-blue'>
                            <Button color="primary" style={{ margin: 10 }} onClick={() => {
                                this.updateReferralNote(this.state.referralNote)
                            }}>{'Save'.translate(this.props.lang)}</Button>

                            <Button color="primary" style={{ margin: 10 }} onClick={() => this.setState({ referralNote: null })

                            }>{'Close'.translate(this.props.lang)}</Button>

                        </ModalFooter>
                    </Modal>


                    : null}


            </div >
        );
    }
}

const selector = formValueSelector('modalForm');

export default connect(state => {
    return { group: selector(state, 'group') }

}, {
    changeSubgroup: value => change("modalForm", "subgroup", value),

})(Page(ReceivedReferrals));