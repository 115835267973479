import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { formValueSelector, change, isDirty, submit } from 'redux-form';

import { connect } from 'react-redux';

import Isvg from 'react-inlinesvg';

import { FormGroup, Label, Input, FormFeedback, FormText } from 'reactstrap';


// const ParsedNotif = require('../../../../parsed-replaced.json')
const ParsedNotif = require('../../../../parsed.json')
/**
* Generate name for sent sms messages
* @author   Milan Stanojevic
*/
class HighlightsText extends Component {
    constructor(props) {
        super(props);

        this.state = {
        };
    }

    componentDidMount() {

        if (this.props.value) {

            let params = '';
            params = this.props.value.match(/\<.*?\>.*?\<.*?\>|(https?:\/\/\{)[0-9]\}\/\{[0-9]\}|(https?:\/\/\{)[0-9]\}\/\{[0-9][0-9]\}|(https?:\/\/\{)[0-9][0-9]\}\/\{[0-9][0-9]\}/g)

            let message = this.props.value
            this.setState({
                message: message,
            })
        } else {
            let message = ''
            this.setState({
                message: message,
            })
        }

        let paramsArr = []
        for (let i = 0; i < ParsedNotif.length; i++) {
            if (ParsedNotif[i].type == this.props.typeNotif && ParsedNotif[i].key == this.props.keyNotif) {
                for (let j = 0; j < ParsedNotif[i].params.length; j++) {

                    paramsArr.push(ParsedNotif[i].params[j].split('|')[1])

                }

            }
        }

        this.setState({
            paramsArr: paramsArr
        })
    }

    componentDidUpdate(prevProps) {
        if (this.props.value && prevProps.value && this.props.value != prevProps.value) {
            let params = '';
            params = this.props.value.match(/\<.*?\>.*?\<.*?\>|(https?:\/\/\{)[0-9]\}\/\{[0-9]\}|(https?:\/\/\{)[0-9]\}\/\{[0-9][0-9]\}|(https?:\/\/\{)[0-9][0-9]\}\/\{[0-9][0-9]\}/g)

            let message = this.props.value
            this.setState({
                message: message,
            })
        }

        if (this.props.lang && prevProps.lang && this.props.lang !== prevProps.lang) {
            if (this.props.value) {

                let params = '';
                params = this.props.value.match(/\<.*?\>.*?\<.*?\>|(https?:\/\/\{)[0-9]\}\/\{[0-9]\}|(https?:\/\/\{)[0-9]\}\/\{[0-9][0-9]\}|(https?:\/\/\{)[0-9][0-9]\}\/\{[0-9][0-9]\}/g)

                let message = this.props.value
                this.setState({
                    message: message,
                })
            } else {
                let message = ''
                this.setState({
                    message: message,
                })
            }
        }

    }

    messageOut = () => {

        let re = /\{[0-9]\}|\{[0-9][0-9]\}/g

        let m

        if (this.state.message) {
            let preview = this.state.message
            //with i params must be from {0} {1} {2} ....
            // let i = 0
            do {
                m = re.exec(this.state.message);
                console.log(m);
                // console.log(m, i);
                if (m) {
                    // when m[0] is just a number params can be in random order {4} {3} {0} {1} ....
                    m[0] = m[0].replace('{', '').replace('}', '')
                    // if (m[0] == `{${i}}`) {
                        if(m[0]){

                        let params = this.state.message.match(/\<.*?\>|(https?:\/\/\{)[0-9]\}\/\{[0-9]\}|(https?:\/\/\{)[0-9]\}\/\{[0-9][0-9]\}|(https?:\/\/\{)[0-9][0-9]\}\/\{[0-9][0-9]\}/g)
                        let check = false

                        // console.log(params, this.props.typeNotif);
                        if (params && params.length) {

                            for (let j = 0; j < params.length; j++) {

                                if (!check && params[j].indexOf(`{${m[0]}}`) != -1) {
                                    // console.log(params[j], i, this.props.typeNotif);
                                    check = true
                                }

                            }

                            if (!check) {

                                let linkData = this.state.message.match(/\<.*?\>.*?\<.*?\>/g)
                                if (linkData && linkData.length) {
                                    let check2 = false
                                    for (let d = 0; d < linkData.length; d++) {
                                        let links = linkData[d].match(/\>.*?\</g)

                                        if (links && links.length) {
                                            if (links[0] == `>{${m[0]}}<`) {
                                                check2 = true
                                            }

                                        }

                                    }
                                    if (check2) {
                                        preview = preview.replace(`{${m[0]}}`, `${this.state.paramsArr[m[0]]}`)
                                    } else {
                                        preview = preview.replace(`{${m[0]}}`, `<span style="color: red;">${this.state.paramsArr[m[0]]}</span>`)

                                    }

                                } else {
                                    preview = preview.replace(`{${m[0]}}`, `<span style="color: red;">${this.state.paramsArr[m[0]]}</span>`)

                                }

                            } else {
                                if (this.props.typeNotif == 'sms')
                                    preview = preview.replace(`{${m[0]}}`, `<span style="color: blue;">${this.state.paramsArr[m[0]]}</span>`)

                            }
                        } else {
                            preview = preview.replace(`{${m[0]}}`, `<span style="color: red;">${this.state.paramsArr[m[0]]}</span>`)

                        }


                    }
                }

                // i++
            } while (m)
            preview = preview.replaceAll(/\s/g, ' ')
            if (this.props.typeNotif != 'sms') {
                preview = preview.replaceAll(/(https?:\/\/\{)[0-9]\}\/\{[0-9]\}|(https?:\/\/\{)[0-9]\}\/\{[0-9][0-9]\}|(https?:\/\/\{)[0-9][0-9]\}\/\{[0-9][0-9]\}/g, '#')

            } else {
                let links = preview.match(/(https?:\/\/).*?\/login/g)

                if (links && links.length) {
                    for (let i = 0; i < links.length; i++) {
                        preview = preview.replace(`${links[i]}`, `<span style="color: blue;">${links[i]}</span>`)

                    }
                } else {
                    let links = preview.match(/(https?:\/\/).*?<\/span>\//g)
                    // console.log(links);
                    if (links && links.length) {
                        for (let i = 0; i < links.length; i++) {
                            // console.log(links[i]);
                            preview = preview.replaceAll(/(https?:\/\/).*?<\/span>\//g, `<span style="color: blue;">${links[i]}</span>`)

                        }
                    }
                }
            }
            return preview
        }


    }

    render() {
        return (
            <FormGroup>
                {this.props.label ? <Label>{this.props.label}</Label> : null}
                <div className='container-text'>
                    <div className='container-text-message'>
                        <Label>{'Message:'.translate(this.props.lang)}</Label>
                        <textarea type='textarea' onScroll={() => {
                            if (this.textareaRef) {
                                if (typeof window != 'undefined' && window.innerWidth < 768 && this.divRefMob) {
                                    this.divRefMob.scrollTop = this.textareaRef.scrollTop
                                } else if (this.divRef) {
                                    this.divRef.scrollTop = this.textareaRef.scrollTop
                                }
                            }
                        }} ref={(node) => this.textareaRef = node} className='navi form-control' disabled={this.props.disabled} name={this.props.name} invalid={this.props.error ? true : false} onChange={(e) => {
                            if (this.textareaRef) {
                                if (typeof window != 'undefined' && window.innerWidth < 768 && this.divRefMob) {
                                    this.divRefMob.scrollTop = this.textareaRef.scrollTop
                                } else if (this.divRef) {
                                    this.divRef.scrollTop = this.textareaRef.scrollTop
                                }

                            }
                            {/** if you want to change notifications in notifications edit at super admin account comment this --> */ }
                            // if (e.target.value != this.state.message && this.state.message.match(/\{[0-9]\}|\{[0-9][0-9]\}|\<.*?\>|(https?:\/\/\{)[0-9]\}\/\{[0-9]\}|(https?:\/\/\{)[0-9]\}\/\{[0-9][0-9]\}|(https?:\/\/\{)[0-9][0-9]\}\/\{[0-9][0-9]\}/g)) {
                            //     let arr = e.target.value.match(/\{[0-9]\}|\{[0-9][0-9]\}|\<.*?\>|(https?:\/\/\{)[0-9]\}\/\{[0-9]\}|(https?:\/\/\{)[0-9]\}\/\{[0-9][0-9]\}|(https?:\/\/\{)[0-9][0-9]\}\/\{[0-9][0-9]\}/g);
                            //     let check = this.state.message.match(/\{[0-9]\}|\{[0-9][0-9]\}|\<.*?\>|(https?:\/\/\{)[0-9]\}\/\{[0-9]\}|(https?:\/\/\{)[0-9]\}\/\{[0-9][0-9]\}|(https?:\/\/\{)[0-9][0-9]\}\/\{[0-9][0-9]\}/g)

                            //     let items = true
                            //     if (arr && check && arr.length && check.length && arr.length == check.length) {
                            //         for (let i = 0; i < arr.length; i++) {
                            //             if (arr[i] != check[i]) {
                            //                 items = false
                            //             }

                            //         }
                            //     }

                            //     if (arr && check && arr.length && check.length && arr.length == check.length && items) {

                            //         let value = e.target.value;
                            //         this.setState({
                            //             message: value
                            //         }, () => {
                            //             let message = this.state.message
                            //             this.props.onChange(message)
                            //         })

                            //     } else {
                            //         let value = this.state.message
                            //         this.setState({
                            //             message: value
                            //         }, () => {
                            //             let message = this.state.message
                            //             this.props.onChange(message)
                            //         })
                            //     }
                            // } else {
                                {/** <-- */ }
                                {/** leave just this little code below to change value */}
                                let value = e.target.value;
                                this.setState({
                                    message: value
                                }, () => {
                                    let message = this.state.message
                                    this.props.onChange(message)
                                })
                                {/** -> */ }
                            // }
                            {/** <-- */ }

                        }} value={this.state.message} placeholder={this.props.placeholder}></textarea>


                        {typeof window != 'undefined' && window.innerWidth < 768 ?
                            <div className='infoi' contentEditable='true' style={{ maxHeight: 100, minHeight: 100, overflowY: 'auto' }} ref={(node) => this.divRefMob = node} dangerouslySetInnerHTML={{
                                __html: `<div style="
                        background-color: #E6EDF5;
                        padding-left: 15px;
                        padding-right: 15px;
                        padding-top: 5px;
                        font-size: 15px;
                        color: #3A3F56;
                        font-weight: 500;
                        border: none;
                        border-radius: 10px;">${this.state.message && this.state.message.replaceAll('<a', '&lt;a').replaceAll('</a', '&lt;/a').replaceAll(/\&.*?\a\>|(https?:\/\/\{)[0-9]\}\/\{[0-9]\}|(https?:\/\/\{)[0-9]\}\/\{[0-9][0-9]\}|(https?:\/\/\{)[0-9][0-9]\}\/\{[0-9][0-9]\}/g, (e) => {
                                    return `<span style=\"color: blue;\">${e}</span>`
                                }).replace(/\{[0-9]\}|\{[0-9][0-9]\}/g, (e) => {
                                    return `<span style=\"color: red;\">${e}</span>`
                                })}</div>`
                            }}></div>
                            :

                            <div className='infoi' contentEditable='true' style={{ maxHeight: 100, minHeight: 100, overflowY: 'auto' }} ref={(node) => this.divRef = node} dangerouslySetInnerHTML={{
                                __html: `<div style="
                        
                        background-color: #E6EDF5;
                        padding-left: 15px;
                        min-height: 100px;
                        padding-right: 15px;
                        padding-top: 5px;
                        font-size: 15px;
                        color: #3A3F56;
                        font-weight: 500;
                        border: none;
                        border-radius: 10px;">${this.state.message && this.state.message.replaceAll('<a', '&lt;a').replaceAll('</a', '&lt;/a').replaceAll(/\&.*?\a\>|(https?:\/\/\{)[0-9]\}\/\{[0-9]\}|(https?:\/\/\{)[0-9]\}\/\{[0-9][0-9]\}|(https?:\/\/\{)[0-9][0-9]\}\/\{[0-9][0-9]\}/g, (e) => {
                                    return `<span style=\"color: blue;\">${e}</span>`
                                }).replace(/\{[0-9]\}|\{[0-9][0-9]\}/g, (e) => {
                                    return `<span style=\"color: red;\">${e}</span>`
                                })}</div>`
                            }}></div>
                        }
                    </div>
                    {typeof window != 'undefined' && window.innerWidth < 768 ?
                        <div className='container-text-preview'>
                            {'Preview:'.translate(this.props.lang)}
                            <div className='infoi' contentEditable='false' dangerouslySetInnerHTML={{
                                __html: `<div style="
                        
                        background-color: transparent;
                        padding-left: 15px;
                        word-break: break-all;
                        padding-right: 15px;
                        padding-top: 5px;
                        font-size: 15px;
                        color: #3A3F56;
                        font-weight: 500;
                        border: 1px solid #BCC3CF;
                        overflow-y: auto;
                        border-radius: 10px;">${this.messageOut()}</div>`
                            }}></div>
                        </div>
                        :
                        <div className='container-text-preview'>
                            {'Preview:'.translate(this.props.lang)}
                            <div className='infoi' contentEditable='false' dangerouslySetInnerHTML={{
                                __html: `<div style="
                        
                        background-color: transparent;
                        padding-left: 15px;
                        word-break: break-all;
                        padding-right: 15px;
                        padding-top: 5px;
                        font-size: 15px;
                        color: #3A3F56;
                        font-weight: 500;
                        border: 1px solid #BCC3CF;
                        overflow-y: auto;
                        border-radius: 10px;">${this.messageOut()}</div>`
                            }}></div>
                        </div>
                    }

                </div>
            </FormGroup>
        );
    }
}
const selector = formValueSelector('modalForm');

export default connect(state => {
    return {
        // type: selector(state, 'type'),
        // keyNotif: selector(state, 'key'),

    }
}, {})(HighlightsText);