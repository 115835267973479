import { Player } from '@lottiefiles/react-lottie-player';
import React, { Component } from 'react';
import { Modal, ModalHeader, ModalFooter, ModalBody, Button, Input, Label, FormGroup } from 'reactstrap';
import { API_ENDPOINT, CHILD_AGE_LIMIT } from '../constants';

import Isvg from 'react-inlinesvg';
import infoIcon from '../assets/svg/link-new-tab.svg';
import singleUser from '../assets/svg/singleUser.svg';
import parentUser from '../assets/svg/parentUser.svg';

// FIELDS
import PhoneField from './forms/fields/phone';
import SSNField, { isSocialSecurityNumberValid } from './forms/fields/socialSecurityNumber';
import DateOfBirthField from './forms/fields/dateOfBirth';
// import { validateSocialSecurityNumber } from '../views/patients/searchEnterprise';
// import CustomCheckboxLabel from './forms/fields/checkBoxPrivacyPolicy';
// import CustomCheckbox from './forms/fields/customCheckbox';

import { v4 as uuid } from 'uuid';


export const defaultError = 'Something went wrong! Please refresh your browser and try again!';

export const isStringAValidNumber = (string) => {
    if (typeof string !== 'string') return false;
    return !isNaN(string) && !isNaN(parseFloat(string));
};

export const isValidEmailAddress = (string) => {
    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(String(string));
};

export const isValidPassword = (string) => {
    // const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    const regex = new RegExp("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{10,}$")
    return regex.test(String(string));
};

const calcAge = (socialSecurityNumber, country) => {
    if (!country) {
        if (typeof window !== 'undefined' && localStorage && localStorage?.getItem?.('country')) country = localStorage?.getItem?.('country');
        else country = 'sweden';
    }
    try {
        if (country === 'denmark') {
            const day = Number(`${socialSecurityNumber[0]}${socialSecurityNumber[1]}`);
            const month = Number(`${socialSecurityNumber[2]}${socialSecurityNumber[3]}`);
            const yearLastDigits = Number(`${socialSecurityNumber[4]}${socialSecurityNumber[5]}`);
            const nowYear = new Date().getFullYear();
            const nowYearLastDigits = `${String(nowYear)[2]}${String(nowYear)[3]}`;

            let year;
            if (yearLastDigits === 0) year = 2000;
            else {
                if (yearLastDigits >= +nowYearLastDigits) year = Number(`19${yearLastDigits < 10 ? '0' + yearLastDigits : yearLastDigits}`);
                else year = Number(`20${yearLastDigits < 10 ? '0' + yearLastDigits : yearLastDigits}`);
            }

            let date = new Date(year, month - 1, day);
            let years = (new Date().getTime() / 1000 - date.getTime() / 1000) / 31556926;

            return years;
        }

        let year = parseInt(`${socialSecurityNumber[0]}${socialSecurityNumber[1]}${socialSecurityNumber[2]}${socialSecurityNumber[3]}`);
        let month = parseInt(`${socialSecurityNumber[4]}${socialSecurityNumber[5]}`);
        let day = parseInt(`${socialSecurityNumber[6]}${socialSecurityNumber[7]}`);
        let date = new Date(year, month - 1, day);
        let years = (new Date().getTime() / 1000 - date.getTime() / 1000) / 31556926;

        return years;
    } catch (err) {
        console.log('calcAge err: ', err);
        return null;
    }
}

const calcDate = (socialSecurityNumber, country) => {
    if (!country) {
        if (typeof window !== 'undefined' && localStorage && localStorage?.getItem?.('country')) country = localStorage?.getItem?.('country');
        else country = 'sweden';
    }
    try {
        if (country === 'denmark') {
            const day = Number(`${socialSecurityNumber[0]}${socialSecurityNumber[1]}`);
            const month = Number(`${socialSecurityNumber[2]}${socialSecurityNumber[3]}`);
            const yearLastDigits = Number(`${socialSecurityNumber[4]}${socialSecurityNumber[5]}`);
            const nowYear = new Date().getFullYear();
            const nowYearLastDigits = `${String(nowYear)[2]}${String(nowYear)[3]}`;

            let year;
            if (yearLastDigits === 0) year = 2000;
            else {
                if (yearLastDigits >= +nowYearLastDigits) year = Number(`19${yearLastDigits < 10 ? '0' + yearLastDigits : yearLastDigits}`);
                else year = Number(`20${yearLastDigits < 10 ? '0' + yearLastDigits : yearLastDigits}`);
            }

            return `${year}-${month}-${day}`;
        }
        let year = parseInt(`${socialSecurityNumber[0]}${socialSecurityNumber[1]}${socialSecurityNumber[2]}${socialSecurityNumber[3]}`);
        let month = parseInt(`${socialSecurityNumber[4]}${socialSecurityNumber[5]}`);
        let day = parseInt(`${socialSecurityNumber[6]}${socialSecurityNumber[7]}`);
        return `${year}-${month}-${day}`;
    } catch (err) {
        console.log('calcDate err: ', err);
        return '';
    }
}

/**
 * @typedef {Object} Props
 * @property {String} [uid] - uid from url query - pass only if type is verify-account
 * @property {String} [alias] - alias from clinic group - send for create account
 * @property {Function} closeModal - function to close the modal
 * @property {Boolean} show2FAModal - state bool on wheather the modal is shown or not
 * @property {String} lang - language
 * @property {'verify-account'|'create-account'} type - type of modal to display
 */

/**
 * @class
 * @augments {Component<Props, State>}
 */
class TwoFactorAuthModal extends Component {
    /**
     * 
     * @param {Props} props 
     */
    constructor(props) {
        super(props)

        this.state = {
            loading: true,
            error: null,
            uid: null,

            warningModal: false,

            step: 1,

            hasPk: false,
            verified: false,
            formSubmittingFinished: false,

            phoneIsVerified: false,
            phoneLinkIsSent: false,
            phone: '',
            userPhone: '',
            phoneVerificationCode: '',

            emailIsVerified: false,
            emailLinkIsSent: false,
            email: '',
            userEmail: '',
            emailVerificationCode: '',

            password: '',
            repeatPassword: '',
            passwordIsSet: false,

            infoIsVerified: false,

            secretKey: null,

            handlerError: null,
            handlerSuccess: null,

            langs: null,
            groupData: null,

            isChild: false,

            ssnError: null,
            ssn2Error: null,

            createAccountError: null,
            accountCreated: false,

            mainAccount: {
                ssn: '',
                dateOfBirth: '',
                gender: '',
                name: '',
                language: '',
                email: '',
                phone: '',
            },

            childAccount: {
                ssn: '',
                dateOfBirth: '',
                gender: '',
                name: '',
                parent: {
                    ssn: '',
                    dateOfBirth: '',
                    gender: '',
                    name: '',
                    language: '',
                    email: '',
                    phone: ''
                }
            },
            parentExists: false,

            parentNeedsVerification: false,
            parentIsVerified: false,
            parentVerificationCode: '',
            parentVerificationCodeError: false,
            parentUserData: null,

            consent: true,
            _customHeader: null,
        }
    }

    getCustomHeader = () => {
        if (typeof window !== 'undefined') {
            if (localStorage?.getItem?.('x-cf-2fa-uuid')) {
                this.setState({ _customHeader: localStorage.getItem('x-cf-2fa-uuid') })
            } else {
                let _uuid = uuid();
                this.setState({ _customHeader: _uuid }, () => {
                    localStorage?.setItem?.('x-cf-2fa-uuid', _uuid);
                });
            }
        }
    }

    componentDidMount() {
        this.getCustomHeader();
        if (!this.props.uid && this.props.type === 'verify-account') this.props.closeModal?.();

        if (this.props.uid && this.props.type === 'verify-account') {
            if (!this.props.alias) return this.setState({ error: defaultError });
            this.setState({ loading: true, step: 3, uid: this.props.uid }, async () => {
                // const response = await fetch(API_ENDPOINT + '/users/two-factor-auth/check/' + this.props.uid);
                const response = await fetch(`${API_ENDPOINT}/users/two-factor-auth/check/${this.props.uid}?alias=${this.props.alias}`);

                if (!response) return this.setState({
                    loading: false,
                    error: defaultError,
                    uid: null
                });

                const result = await response.json();

                if (!result || !result.user || result.error || !result.secretKey) return this.setState({
                    loading: false,
                    uid: null,
                    error: result?.error || defaultError
                });

                this.setState({ secretKey: result.secretKey, groupId: result.groupId }, () => {
                    return this.updateState(result.user, true);
                })
            })
        }

        if (this.props.type === 'create-account') {
            if (!this.props.alias) return this.setState({ error: defaultError });
            this.setState({ loading: true }, async () => {
                const fetchLangs = fetch(API_ENDPOINT + '/languages', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        sortType: 1,
                        sortField: 'key',
                        query: {}
                    })
                });
                const fetchGroupData = fetch(API_ENDPOINT + '/data/clinic-group/info', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        alias: this.props.alias
                    })
                });

                const [response, response2] = await Promise.all([
                    fetchLangs,
                    fetchGroupData
                ]);

                if (!response || !response2) return this.setState({
                    loading: false,
                    error: defaultError,
                    uid: null
                });

                const result = await response.json();

                if (!result) return this.setState({
                    loading: false,
                    error: defaultError,
                    uid: null
                });

                const result2 = await response2.json();

                if (!result2 || result2.error || !result2._id) return this.setState({
                    loading: false,
                    error: result2?.error || defaultError,
                    uid: null
                });

                this.setState({ langs: result, error: null, loading: false, groupData: result2 }, () => {
                    if (this.state.groupData?.hideChildrenOption) this.setState({
                        step: 2,
                        isChild: false
                    });
                })
            });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.step !== this.state.step) this.setState({ handlerError: null, handlerSuccess: null });
    }

    // componentWillUnmount() {

    // }

    updateState = (user, handleLoading = false, accountCreated = false, skipToLastStep = false) => {
        if (!user || typeof user !== 'object') return this.setState({
            error: defaultError,
            loading: false,
        });

        let obj = {
            phoneIsVerified: user.phoneIsVerified,
            phoneLinkIsSent: user.phoneLinkIsSent,
            emailIsVerified: user.emailIsVerified,
            emailLinkIsSent: user.emailLinkIsSent,
            passwordIsSet: user.passwordIsSet,
            infoIsVerified: user.infoIsVerified,
            verified: user.verified,
            hasPk: user.hasPk
        };

        if (user.phone) {
            obj.phone = user.phone?.trim?.()?.replace?.('+', '')?.replace(/ /g, '') || '';
            obj.userPhone = user.phone?.trim?.()?.replace?.('+', '')?.replace(/ /g, '') || '';
        } else {
            obj.phone = '';
            obj.userPhone = '';
        }

        if (user.email) {
            obj.email = user.email?.trim?.()?.toLowerCase?.() || '';
            obj.userEmail = user.email?.trim?.()?.toLowerCase?.() || '';
        } else {
            obj.email = '';
            obj.userEmail = '';
        }

        this.setState(obj, () => {
            if (accountCreated) this.setState(prev => ({
                ...prev,
                accountCreated: true,
                step: skipToLastStep ? 6 : (prev?.step + 1 || 3)
            }));
            if (handleLoading) this.setState({ loading: false });
        })
    }

    handler = (action) => {
        if (action === 'no-action') return;

        if (action === 'create-account') {
            if (this.props.country === 'uk') {
                if (this.state.isChild) {
                    const childSSN = this.state.childAccount?.ssn;
                    const parentSSN = this.state.childAccount?.parent?.ssn;
                    if (!childSSN || !parentSSN || !isSocialSecurityNumberValid(childSSN, this.props.country) || !isSocialSecurityNumberValid(parentSSN, this.props.country)) return this.setState({
                        error: defaultError
                    });
                    const childAge = calcAge(childSSN);
                    if (!childAge || childAge > 18) return this.setState({
                        error: 'A child must be under 13 years old!'
                    });
                    const parentAge = calcAge(parentSSN);
                    if (!parentAge || parentAge < 18) return this.setState({
                        error: 'Only a parent/guardian can create an account for a child that is under 13 years old!'
                    });
                } else {
                    const ssn = this.state.mainAccount?.ssn;
                    if (!ssn || !isSocialSecurityNumberValid(ssn, this.props.country)) return this.setState({
                        error: defaultError
                    });
                    const age = calcAge(ssn);
                    if (!age || age < 18) return this.setState({
                        error: 'Only a parent/guardian can create an account for a child that is under 13 years old!'
                    });
                }
            }

            let obj;
            try {
                if (this.state.isChild) obj = {
                    isChild: true,
                    groupId: this.state.groupData?._id,
                    parentExists: this.state.parentExists,
                    ...this.state.childAccount
                };
                else obj = {
                    isChild: false,
                    groupId: this.state.groupData?._id,
                    ...this.state.mainAccount
                };
            } catch (err) {
                return this.setState({ error: defaultError });
            }

            if (!obj) return this.setState({ error: defaultError });

            this.setState({ loading: true }, async () => {
                const response = await fetch(API_ENDPOINT + '/users/two-factor-auth/create-account', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(obj)
                });

                if (!response) return this.setState({ loading: false, error: defaultError });

                const result = await response.json();

                if (!result || (response.status === 409 && result.error)) return this.setState({ loading: false, error: result?.error || defaultError });

                if (result.error || !result.user || !result.uid || !result.secretKey) return this.setState({ loading: false, createAccountError: result.error || defaultError });
                else return this.setState({ secretKey: result.secretKey, uid: result.uid }, () => {
                    let lastStep = false;
                    if (result.user && result.user.emailIsVerified && result.user.phoneIsVerified && result.user.passwordIsSet && result.user.verified) lastStep = true;
                    this.updateState(result.user, true, true, lastStep);
                });
            })
        }

        if (action === 'verify-parent-account') {
            const obj = {
                uid: this.state.parentUserData?._id,
                code: this.state.parentVerificationCode,
                groupId: this.state.groupData?._id ?? this.state.groupId
            };
            this.setState({ loading: true }, async () => {
                const response = await fetch(API_ENDPOINT + '/users/two-factor-auth/verify-parent-code', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(obj)
                });
                if (!response) return this.setState({
                    error: defaultError,
                    loading: false,
                });

                const result = await response.json();

                if (!result || !result._verifyParentAccount) return this.setState({
                    error: defaultError,
                    loading: false,
                });

                if (result.error) return this.setState({ parentVerificationCodeError: true, loading: false });
                return this.setState({ parentIsVerified: true, loading: false });
            });
        }

        if (action === 'next-step') return this.setState(prev => ({
            ...(prev || {}),
            step: +prev.step + 1
        }));

        if (!this.state.secretKey || !this.state.uid || !action) return;

        this.setState({ loading: true }, async () => {
            let obj = {
                secretKey: this.state.secretKey,
                uid: this.state.uid,
                action,
                lang: this.props.lang,
                groupId: this.state.groupData?._id ?? this.state.groupId
            }

            let url = API_ENDPOINT + '/users/two-factor-auth/verify';

            switch (action) {
                case 'send-phone-code-changed': {
                    obj.phone = this.state.phone;
                    break;
                };
                case 'verify-phone': {
                    obj.phoneVerificationCode = this.state.phoneVerificationCode;
                    break;
                };
                case 'send-email-code-changed': {
                    obj.email = this.state.email;
                    break;
                };
                case 'verify-email': {
                    obj.emailVerificationCode = this.state.emailVerificationCode;
                    break;
                };
                case 'set-password': {
                    obj.password = this.state.password;
                    obj.repeatPassword = this.state.repeatPassword;
                    break;
                }
            };

            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(obj)
            });

            if (!response) return this.setState({
                error: defaultError,
                loading: false,
            });

            const result = await response.json();

            if (action === 'delete-user') return this.props.closeModal?.();

            if (!result || !result.user) return this.setState({
                error: defaultError,
                loading: false,
            });

            let newState = {
                loading: false
            }

            if (result.user) this.updateState(result.user, false);

            this.setState(newState, () => {
                if (result.error) this.setState({ handlerError: result.error, handlerSuccess: null, });
                if (result.success) this.setState({ handlerSuccess: result.success, handlerError: null });

                if (result?.user?.verified || result?.stepUp) this.setState(prev => ({
                    ...(prev || {}),
                    step: +prev?.step + 1
                }));

                if (result?.user?.formSubmittingFinished) this.setState(prev => ({
                    ...(prev || {}),
                    formSubmittingFinished: true
                }));
            })
        });
    }

    closeModal = () => {
        if (this.props.type === 'create-account' && this.state.step === 2 && !this.state.error && !this.state.accountCreated && !this.state.verified && !this.state.parentNeedsVerification) return this.setState({
            warningModal: true
        })
        return this.props.closeModal?.();
        // if (this.state.error || this.state.step === 1) return this.props.closeModal?.();
        // if (this.props.type === 'verify-account') this.props.closeModal?.();
        // else {
        //     if (this.state.formSubmittingFinished) this.props.closeModal?.();
        //     else this.setState({ warningModal: true });
        // }
    }

    closeWarningModal = (action) => {
        if (action && action === 'close') {
            if (this.state.secretKey && this.state.uid) this.handler('delete-user');
            else this.props.closeModal?.();
        } else this.setState({ warningModal: false });
    }

    checkEmail = async (email) => {
        if (email && isValidEmailAddress(email)) {
            const response = await fetch(API_ENDPOINT + '/users/two-factor-auth/check-email', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email,
                    groupId: this.state.groupData?._id ?? this.state.groupId
                })
            });

            if (!response.ok) return this.setState({
                loading: false,
                error: 'An account with this email already exists! Please try logging in with your email, instead of trying to create a new account!'
            })
        }
    }

    checkSSN = (ssn, errorCallback, successCallback, parentCallback = null) => {
        if (this.props.country === 'uk') {
            if (!ssn) return;

            const check = isSocialSecurityNumberValid(ssn, this.props.country);
            if (!check || !isStringAValidNumber(ssn)) return errorCallback?.('Social security number is not valid');

            const age = calcAge(ssn, this.props.country);
            if (!age) return this.setState({
                loading: false,
                error: defaultError,
            });

            const date = calcDate(ssn, this.props.country);
            return successCallback?.(ssn, +age, date);
        };

        if (!ssn || !errorCallback || !successCallback) return;

        this.setState({ loading: true }, async () => {
            let url = API_ENDPOINT + '/users/two-factor-auth/check-ssn';
            if (parentCallback) url += `?parent=true&groupId=${this.state.groupData?._id ?? this.state.groupId}`;

            let customUuid;
            if (this.state._customHeader) customUuid = this.state._customHeader;
            else {
                if (typeof window !== 'undefined') {
                    if (localStorage?.getItem?.('x-cf-2fa-uuid')) {
                        customUuid = localStorage.getItem('x-cf-2fa-uuid');
                        this.setState({ _customHeader: localStorage.getItem('x-cf-2fa-uuid') });
                    } else {
                        let _uuid = uuid();
                        customUuid = _uuid;
                        localStorage?.setItem?.('x-cf-2fa-uuid', _uuid)
                    }
                }
            }

            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-cf-2fa-uuid': customUuid,
                },
                body: JSON.stringify({
                    ssn,
                    groupId: this.state.groupData?._id ?? this.state.groupId
                })
            });

            if (!parentCallback) {
                if (!response || !response.ok) return this.setState({
                    loading: false,
                    error: 'An account for this Social Security Number already exists! Please try logging in with you SSN, instead of trying to create a new account!'
                });

                const age = calcAge(ssn, this.props.country);
                if (!age) return this.setState({
                    loading: false,
                    error: defaultError,
                });

                const date = calcDate(ssn, this.props.country);

                this.setState({ loading: false }, () => {
                    return successCallback?.(ssn, +age, date);
                });
            } else {
                if (!response) return this.setState({
                    loading: false,
                    error: defaultError
                });

                const result = await response.json();

                if (!result || [null, undefined].includes(result.userExists) || result.error) return this.setState({
                    loading: false,
                    error: result.error || defaultError
                });

                if (result.userExists === false) {
                    const age = calcAge(ssn, this.props.country);
                    if (!age) return this.setState({
                        loading: false,
                        error: defaultError,
                        parentExists: false
                    });

                    const date = calcDate(ssn, this.props.country);

                    this.setState({ loading: false }, () => {
                        return successCallback?.(ssn, +age, date);
                    });
                } else if (result.userExists === true) {
                    this.props.navigate?.();
                    return this.setState({
                        loading: false,
                        error: 'An account for this Social Security Number already exists! Please try logging in with you SSN, and create an account for your child in the My Children tab on My Profile page!'
                    })
                    if (!result.userData) return this.setState({
                        loading: false,
                        error: defaultError
                    });
                    const age = calcAge(ssn, this.props.country);
                    if (!age) return this.setState({
                        loading: false,
                        error: defaultError,
                    });

                    const date = calcDate(ssn, this.props.country);
                    this.setState({ loading: false }, () => {
                        // console.log("user data: ", result.userData);
                        return parentCallback?.(result.userData, ssn, +age, date)
                    });
                } else return this.setState({
                    loading: false,
                    error: defaultError
                })
            }
        });
    }


    render() {
        if (!this.props.type) return null;


        const { state } = this;
        const { lang, country, secondaryLanguageDisabled } = this.props;

        let privacyPolicy = state.groupData && state.groupData.privacyPolicy && state.groupData.privacyPolicy.file ? `${API_ENDPOINT}${state.groupData.privacyPolicy.file}` : null;
        let termsOfUse = state.groupData && state.groupData.termsOfUse && state.groupData.termsOfUse.file ? `${API_ENDPOINT}${state.groupData.termsOfUse.file}` : null;

        function renderButtonTextAndAction() {
            let obj = {
                text: 'Next'.translate(lang),
                action: 'next-step',
                disabled: false,
            }

            switch (state.step) {
                // 1 - Chose type of account to create
                // 2 - Create account - generic informations about user
                // 3 - Phone - change number and/or send code and enter verification link
                // 4 - Email - change email and/or send code and enter verification link
                // 5 - Set password
                case 2: {
                    if (state.accountCreated && !state.createAccountError) break;

                    if (state.parentNeedsVerification && !state.parentIsVerified) {
                        obj.text = 'Verify Account';
                        if (state.parentVerificationCode?.trim() && isStringAValidNumber(state.parentVerificationCode)) {
                            obj.action = 'verify-parent-account';
                            obj.disabled = false;
                        } else {
                            obj.action = 'no-action';
                            obj.disabled = true;
                        }
                        break;
                    }

                    obj.text = 'Agree and Create Account'.translate(lang);

                    if (!state.consent || state.createAccountError) {
                        obj.action = 'no-action';
                        obj.disabled = true;
                        break;
                    }

                    if (state.isChild) {
                        if (!state.childAccount || !state.childAccount.parent || !state.childAccount.ssn?.trim?.() || !isStringAValidNumber(state.childAccount.ssn) || (country === 'denmark' ? state.childAccount.ssn.trim().length !== 10 : state.childAccount.ssn.trim().length !== 12) || !state.childAccount.dateOfBirth?.trim?.() || !state.childAccount.name?.trim?.() || !state.childAccount.parent.ssn?.trim?.() || !isStringAValidNumber(state.childAccount.parent.ssn) || (country === 'denmark' ? state.childAccount.parent.ssn.trim().length !== 10 : state.childAccount.parent.ssn.trim().length !== 12) || !state.childAccount.parent.dateOfBirth?.trim?.()) {
                            obj.action = 'no-action';
                            obj.disabled = true;
                            break;
                        }
                        if (!state.parentExists && (!state.childAccount.parent.name?.trim?.() || (!secondaryLanguageDisabled && !state.childAccount.parent.language?.trim?.()) || !state.childAccount.parent.email?.trim?.() || !isValidEmailAddress(state.childAccount.parent.email) || !state.childAccount.parent.phone?.trim?.())) {
                            obj.action = 'no-action';
                            obj.disabled = true;
                            break;
                        }

                        obj.action = 'create-account';
                        obj.disabled = false;
                        break;
                    } else {
                        if (!state.mainAccount || !state.mainAccount.ssn?.trim?.() || !isStringAValidNumber(state.mainAccount.ssn) || (country === 'denmark' ? state.mainAccount.ssn.trim().length !== 10 : state.mainAccount.ssn.trim().length !== 12) || !state.mainAccount.dateOfBirth?.trim?.() || !state.mainAccount.name?.trim?.() || (!secondaryLanguageDisabled && !state.mainAccount.language?.trim?.()) || !state.mainAccount.email?.trim?.() || !isValidEmailAddress(state.mainAccount.email) || !state.mainAccount.phone?.trim?.()) {
                            obj.action = 'no-action';
                            obj.disabled = true;
                            break;
                        }
                        obj.action = 'create-account';
                        obj.disabled = false;
                        break;
                    }
                };
                case 3: {
                    if (state.phoneIsVerified) break;

                    if (state.phoneLinkIsSent) {
                        if (state.phone?.trim?.() && state.phone !== state.userPhone) {
                            obj.text = 'Change number and send code'.translate(lang);
                            obj.action = 'send-phone-code-changed';
                            break;
                        }
                        if (state.phoneVerificationCode?.trim?.()) {
                            obj.text = 'Verify phone number'.translate(lang);

                            if (state.phoneVerificationCode?.trim?.()?.length && isStringAValidNumber(state.phoneVerificationCode)) {
                                obj.action = 'verify-phone';
                                obj.disabled = false;
                            } else {
                                obj.action = 'no-action';
                                obj.disabled = true;
                            }
                        } else {
                            obj.text = 'Resend code'.translate(lang);
                            obj.action = 'send-phone-code';
                        }
                        break;
                    }

                    if (state.phone?.trim?.()) {
                        obj.disabled = false;
                        if (state.phone === state.userPhone) {
                            obj.text = 'Send code'.translate(lang);
                            obj.action = 'send-phone-code';
                        }
                        else {
                            obj.text = 'Change number and send code'.translate(lang);
                            obj.action = 'send-phone-code-changed';
                        }
                    } else {
                        obj.disabled = true;
                        obj.text = 'Send code'.translate(lang);
                        obj.action = 'no-action'
                    }
                    break;
                };
                case 4: {
                    if (state.emailIsVerified) break;

                    if (state.emailLinkIsSent) {
                        if (state.email?.trim?.() && isValidEmailAddress(state.email) && state.email !== state.userEmail) {
                            obj.text = 'Change email and send code'.translate(lang);
                            obj.action = 'send-email-code-changed';
                            break;
                        }
                        if (state.emailVerificationCode?.trim?.()) {
                            obj.text = 'Verify email address'.translate(lang);

                            if (state.emailVerificationCode?.trim?.()?.length && isStringAValidNumber(state.emailVerificationCode)) {
                                obj.action = 'verify-email';
                                obj.disabled = false;
                            } else {
                                obj.action = 'no-action';
                                obj.disabled = true;
                            }
                        } else {
                            obj.text = 'Resend code'.translate(lang);
                            obj.action = 'send-email-code';
                        }
                        break;
                    }

                    if (state.email?.trim?.() && isValidEmailAddress(state.email)) {
                        obj.disabled = false;
                        if (state.email === state.userEmail) {
                            obj.text = 'Send code'.translate(lang);
                            obj.action = 'send-email-code';
                        }
                        else {
                            obj.text = 'Change email and send code'.translate(lang);
                            obj.action = 'send-email-code-changed';
                        }
                    } else {
                        obj.disabled = true;
                        obj.text = 'Send code'.translate(lang);
                        obj.action = 'no-action'
                    }
                    break;
                };
                case 5: {
                    if (state.passwordIsSet) break;
                    obj.text = 'Set Password'.translate(lang);
                    if (state.password?.trim?.() && isValidPassword(state.password) && state.repeatPassword && state.password === state.repeatPassword) {
                        obj.disabled = false;
                        obj.action = 'set-password';
                    } else {
                        obj.disabled = true;
                        obj.action = 'no-action';
                    }
                    break;
                }
                default: obj.disabled = true;
            }

            return obj;
        }

        return (
            <>
                <Modal
                    isOpen={
                        this.props.type === 'verify-account'
                            ? (this.props.show2FAModal && this.props.uid)
                            : this.props.show2FAModal
                    }
                    centered
                    size='md'
                >
                    <ModalHeader>
                        {this.props.type === 'verify-account' ? 'Verify Your Account'.translate(this.props.lang) : 'Create New Account'.translate(this.props.lang)}
                    </ModalHeader>

                    <ModalBody style={{ paddingBottom: state.step === 2 ? 0 : 5 }}>
                        {this.state.loading
                            ?
                            <Player
                                autoplay={true}
                                loop={true}
                                src="/lf30_editor_l5cxzdyf.json"
                                style={{ height: typeof window != 'undefined' && window.innerWidth < 768 ? "128px" : "256px", width: typeof window != 'undefined' && window.innerWidth < 768 ? "128px" : "256px" }}
                            />
                            :
                            this.state.error || (this.props.type === 'verify-account' && this.state.step === 2)
                                ?
                                <div style={{ textAlign: 'center', fontSize: 18 }}>
                                    {this.state.error?.translate(this.props.lang) || defaultError.translate(this.props.lang)}
                                </div>
                                :
                                this.state.verified && this.state.step >= 6
                                    ?
                                    <div style={{ textAlign: 'center', fontSize: 18 }}>
                                        {!this.state.infoIsVerified ? 'You have successfully created your account! After your account has been approved by clinic, you will be able to log in with your email and password!'.translate(this.props.lang) : 'You have successfully verified your account! Now you can try signing in with your email and password!'.translate(this.props.lang)}
                                    </div>
                                    :
                                    this.state.step === 1
                                        ? <div className='patient-modal-2fa'>
                                            <div className='login-btns login-btns-flex'>
                                                <Button onClick={() => this.setState({ step: 2, isChild: false })}>
                                                    <img className='invert-img' alt='single user' src={singleUser} />
                                                    <p>{`I'm over 13 years old and I'm creating an account for myself`.translate(this.props.lang)}</p>
                                                </Button>

                                                <Button onClick={() => this.setState({ step: 2, isChild: true })}>
                                                    <img className='invert-img' alt='parent user' src={parentUser} />
                                                    <p>{`I'm creating an account for my child (under 13 years old)`.translate(this.props.lang)}</p>
                                                </Button>
                                            </div>
                                        </div>
                                        :
                                        this.state.step === 2
                                            ?
                                            this.state.accountCreated
                                                ?
                                                <div style={{ textAlign: 'center', fontSize: 18 }}>{this.state.verified ? 'You have successfully created your account! After your account has been approved by clinic, you will be able to log in with your email and password!'.translate(this.props.lang) : 'You have successfully created your account! In order to start using it, you must verify your phone number and your email address, and set a password. To start this process, click on the "Next" button bellow. Alternativelly, you can do this later by clicking on a link that we sent to your phone number!'.translate(this.props.lang)}</div>
                                                :
                                                <div className='create-account-modal-fields-container'>
                                                    {this.state.isChild
                                                        ?
                                                        this.state.parentNeedsVerification &&
                                                            !this.state.parentIsVerified

                                                            ? <>
                                                                <FormGroup key='par-ver-cod'>
                                                                    <Label className='col-form-label-sm'>{'Verification Code'.translate(this.props.lang)}</Label>
                                                                    <Input type='text' value={this.state.parentVerificationCode} onChange={e => this.setState({ parentVerificationCode: e?.target?.value })} />
                                                                </FormGroup>
                                                                <div style={{ marginTop: 10 }}>
                                                                    <p style={{ fontSize: 13, fontWeight: 'lighter', color: this.state.parentVerificationCodeError ? 'red' : null }}>
                                                                        *{this.state.parentVerificationCodeError
                                                                            ? 'The code you entered is wrong! Please check the code you received and try again!'.translate(this.props.lang) : 'The SSN that you have entered belongs to a user that already exists on our platform. To continue you must confirm that account associated with this SSN is indeed yours. For that purpose, we have sent a 6-digit verification code to phone belonging to user in question. Please enter it in the form field above. If this is not your profile and you have entered the SSN by mistake, please close this dialog and start again.'.translate(this.props.lang)}
                                                                    </p>
                                                                </div>
                                                            </>
                                                            :
                                                            <>
                                                                <SSNField lang={this.props.lang} ukDisableCheck={true} country={this.props.country} value={this.state.childAccount.ssn} onChange={value => {
                                                                    if ((this.props.country === 'denmark' && value?.length === 10) || value?.length === 12) this.checkSSN(
                                                                        value,
                                                                        error => this.setState(prev => ({
                                                                            ...prev,
                                                                            ssnError1: error?.translate(this.props.lang) || 'Social security number is not valid!'.translate(this.props.lang),
                                                                            createAccountError: null,
                                                                            // childAccount: {
                                                                            //     ssn: '',
                                                                            //     dateOfBirth: '',
                                                                            //     gender: '',
                                                                            //     name: '',
                                                                            //     parent: {
                                                                            //         ssn: '',
                                                                            //         dateOfBirth: '',
                                                                            //         gender: '',
                                                                            //         name: '',
                                                                            //         language: '',
                                                                            //         email: '',
                                                                            //         phone: ''
                                                                            //     }
                                                                            // },
                                                                        })),
                                                                        (ssn, age, date) => {
                                                                            if (this.props.country === 'uk') return this.setState(prev => ({
                                                                                ...prev,
                                                                                ssnError1: null,
                                                                                createAccountError: null,
                                                                                childAccount: {
                                                                                    ...prev?.childAccount,
                                                                                    ssn,
                                                                                    dateOfBirth: date
                                                                                }
                                                                            }))
                                                                            if (age > (this.props.country === 'denmark' ? CHILD_AGE_LIMIT.denmark : this.props.country === 'sweden' ? CHILD_AGE_LIMIT.sweden : 18)) return this.setState({
                                                                                error: `A child must be under 13 years old!`
                                                                            });
                                                                            this.setState(prev => ({
                                                                                ...prev,
                                                                                ssnError1: null,
                                                                                createAccountError: null,
                                                                                childAccount: {
                                                                                    ...prev?.childAccount,
                                                                                    ssn,
                                                                                    dateOfBirth: date
                                                                                }
                                                                            }))
                                                                        });
                                                                    else this.setState(prev => ({
                                                                        ...prev,
                                                                        ssnError1: null,
                                                                        createAccountError: null,
                                                                        childAccount: {
                                                                            ...prev?.childAccount,
                                                                            ssn: value
                                                                        }
                                                                    }))
                                                                }} label={this.props.country === 'uk' ? "Date of birth".translate(this.props.lang) : "Social security number".translate(this.props.lang)} error={this.state.ssnError1} haveValidation={true} />

                                                                {this.state.platform && this.state.platform === 'germany' ? <DateOfBirthField value={this.state.childAccount.dateOfBirth} disabled={true} onChange={value => { return; this.setState(prev => ({ ...prev, childAccount: { ...prev?.childAccount, dateOfBirth: value } })) }} label={'Date of birth'.translate(this.props.lang)} /> : null}

                                                                <FormGroup>
                                                                    <Label className='col-form-label-sm'>{'First and last name'.translate(this.props.lang)}*</Label>
                                                                    <Input type='text' required value={this.state.childAccount.name || ''} onChange={e => { let value = e?.target?.value; this.setState(prev => ({ ...prev, createAccountError: null, childAccount: { ...prev?.childAccount, name: value } })) }} />
                                                                </FormGroup>

                                                                <FormGroup>
                                                                    <Label size='sm' className='form-group-label-wrap' style={{ position: 'relative' }}>
                                                                        {'Sex'.translate(this.props.lang)}
                                                                        <div className='info' >
                                                                            <Isvg src={infoIcon} />
                                                                            <div className="info-text">{"The patient's biological sex is needed for proper treatment".translate(this.props.lang)}</div>
                                                                        </div>
                                                                    </Label>
                                                                    <Input value={this.state.childAccount.gender} type='select' onChange={e => {
                                                                        let value = e?.target?.value; this.setState(prev => ({
                                                                            ...prev,
                                                                            createAccountError: null,
                                                                            childAccount: {
                                                                                ...prev.childAccount,
                                                                                gender: value
                                                                            }
                                                                        }))
                                                                    }} >
                                                                        <option key={'e'} value=''>{''}</option>
                                                                        <option key={'m'} value='male'>{'Male'.translate(this.props.lang)}</option>
                                                                        <option key={'f'} value='female'>{'Female'.translate(this.props.lang)}</option>
                                                                    </Input>
                                                                </FormGroup>

                                                                <div style={{ paddingTop: 20, paddingBottom: 20, color: 'red', textAlign: 'center', fontWeight: '500', fontSize: 18 }}>{'Parent/Guardian data'.translate(this.props.lang)}</div>

                                                                <SSNField lang={this.props.lang} ukDisableCheck={true} country={this.props.country} value={this.state.childAccount.parent.ssn} onChange={value => {
                                                                    if (this.state.parentExists) return;
                                                                    if ((this.props.country === 'denmark' && value?.length === 10) || value?.length === 12) this.checkSSN(
                                                                        value,
                                                                        error => this.setState(prev => ({
                                                                            ...prev,
                                                                            ssnError2: error?.translate(this.props.lang) || 'Social security number is not valid!'.translate(this.props.lang),
                                                                            createAccountError: null,
                                                                            parentExists: false,
                                                                            // childAccount: {
                                                                            //     ssn: '',
                                                                            //     dateOfBirth: '',
                                                                            //     gender: '',
                                                                            //     name: '',
                                                                            //     parent: {
                                                                            //         ssn: '',
                                                                            //         dateOfBirth: '',
                                                                            //         gender: '',
                                                                            //         name: '',
                                                                            //         language: '',
                                                                            //         email: '',
                                                                            //         phone: ''
                                                                            //     }
                                                                            // },
                                                                        })),
                                                                        (ssn, age, date) => {
                                                                            if (this.props.country === 'uk') return this.setState(prev => ({
                                                                                ...prev,
                                                                                ssnError2: null,
                                                                                createAccountError: null,
                                                                                parentExists: false,
                                                                                parentNeedsVerification: false,
                                                                                parentIsVerified: false,
                                                                                parentVerificationCode: '',
                                                                                parentVerificationCodeError: false,
                                                                                childAccount: {
                                                                                    ...prev?.childAccount,
                                                                                    parent: {
                                                                                        ...prev?.childAccount?.parent,
                                                                                        ssn,
                                                                                        dateOfBirth: date
                                                                                    }
                                                                                }
                                                                            }));
                                                                            if ((this.props.country === 'denmark' && age < CHILD_AGE_LIMIT.denmark) || (this.props.country === 'sweden' && age < CHILD_AGE_LIMIT.sweden) || (this.props.country !== 'sweden' && this.props.country !== 'denmark' && age < 18)) return this.setState({
                                                                                error: `Only a parent/guardian can create an account for a child that is under 13 years old!`
                                                                            });
                                                                            this.setState(prev => ({
                                                                                ...prev,
                                                                                ssnError2: null,
                                                                                createAccountError: null,
                                                                                parentExists: false,
                                                                                parentNeedsVerification: false,
                                                                                parentIsVerified: false,
                                                                                parentVerificationCode: '',
                                                                                parentVerificationCodeError: false,
                                                                                childAccount: {
                                                                                    ...prev?.childAccount,
                                                                                    parent: {
                                                                                        ...prev?.childAccount?.parent,
                                                                                        ssn,
                                                                                        dateOfBirth: date
                                                                                    }
                                                                                }
                                                                            }))
                                                                        },
                                                                        (userData, ssn, age, date) => {
                                                                            if ((this.props.country === 'denmark' && age < CHILD_AGE_LIMIT.denmark) || (this.props.country === 'sweden' && age < CHILD_AGE_LIMIT.sweden) || (this.props.country !== 'sweden' && this.props.country !== 'denmark' && age < 18)) return this.setState({
                                                                                error: `Only a parent/guardian can create an account for a child that is under 13 years old!`
                                                                            });
                                                                            this.setState(prev => ({
                                                                                ...prev,
                                                                                ssnError2: null,
                                                                                createAccountError: null,
                                                                                parentExists: true,
                                                                                parentNeedsVerification: true,
                                                                                parentIsVerified: false,
                                                                                parentVerificationCode: '',
                                                                                parentVerificationCodeError: false,
                                                                                parentUserData: userData,
                                                                                childAccount: {
                                                                                    ...prev?.childAccount,
                                                                                    parent: {
                                                                                        ...prev?.childAccount?.parent,
                                                                                        ssn,
                                                                                        dateOfBirth: date,
                                                                                        gender: userData?.gender || '',
                                                                                        name: userData?.name || '',
                                                                                        language: userData?.language || '',
                                                                                        email: userData?.email || '',
                                                                                        phone: userData?.phone || '',
                                                                                    }
                                                                                }
                                                                            }))
                                                                        });
                                                                    else this.setState(prev => ({
                                                                        ...prev,
                                                                        ssnError2: null,
                                                                        createAccountError: null,
                                                                        parentExists: false,
                                                                        childAccount: {
                                                                            ...prev?.childAccount,
                                                                            parent: {
                                                                                ...prev?.childAccount?.parent,
                                                                                ssn: value,
                                                                            }
                                                                        }
                                                                    }))
                                                                }} label={this.props.country === 'uk' ? "Date of birth".translate(this.props.lang) : "Social security number".translate(this.props.lang)} error={this.state.ssnError2} haveValidation={true} disabled={this.state.parentExists} />

                                                                {this.state.platform && this.state.platform === 'germany' ? <DateOfBirthField value={this.state.childAccount.parent.dateOfBirth} disabled={true} onChange={value => { return; this.setState(prev => ({ ...prev, childAccount: { ...prev?.childAccount, parent: { ...prev?.childAccount?.parent, dateOfBirth: value } } })) }} label={'Date of birth'.translate(this.props.lang)} /> : null}

                                                                <FormGroup>
                                                                    <Label className='col-form-label-sm'>{'First and last name'.translate(this.props.lang)}*</Label>
                                                                    <Input type='text' disabled={this.state.parentExists} required value={this.state.childAccount.parent.name || ''} onChange={e => { if (this.state.parentExists) return; let value = e?.target?.value; this.setState(prev => ({ ...prev, createAccountError: null, childAccount: { ...prev?.childAccount, parent: { ...prev?.childAccount?.parent, name: value } } })) }} />
                                                                </FormGroup>

                                                                <FormGroup>
                                                                    <Label size='sm' className='form-group-label-wrap' style={{ position: 'relative' }}>
                                                                        {'Sex'.translate(this.props.lang)}
                                                                        <div className='info' >
                                                                            <Isvg src={infoIcon} />
                                                                            <div className="info-text">{"Your biological sex is needed for proper treatment".translate(this.props.lang)}</div>
                                                                        </div>
                                                                    </Label>
                                                                    <Input value={this.state.childAccount.parent.gender} type='select' disabled={this.state.parentExists} onChange={e => {
                                                                        if (this.state.parentExists) return;
                                                                        let value = e?.target?.value; this.setState(prev => ({
                                                                            ...prev,
                                                                            createAccountError: null,
                                                                            childAccount: {
                                                                                ...prev.childAccount,
                                                                                parent: { ...prev?.childAccount?.parent, gender: value }
                                                                            }
                                                                        }))
                                                                    }} >
                                                                        <option key={'e'} value=''>{''}</option>
                                                                        <option key={'m'} value='male'>{'Male'.translate(this.props.lang)}</option>
                                                                        <option key={'f'} value='female'>{'Female'.translate(this.props.lang)}</option>
                                                                    </Input>
                                                                </FormGroup>

                                                                {!this.props.secondaryLanguageDisabled ? <FormGroup>
                                                                    <Label className='col-form-label-sm'>{'Language'.translate(this.props.lang)}*</Label>
                                                                    <Input value={this.state.childAccount.parent.language} type='select' disabled={this.state.parentExists} required onChange={e => {
                                                                        if (this.state.parentExists) return;
                                                                        let value = e?.target?.value; this.setState(prev => ({
                                                                            ...prev,
                                                                            createAccountError: null,
                                                                            childAccount: {
                                                                                ...prev?.childAccount,
                                                                                parent: { ...prev?.childAccount?.parent, language: value }

                                                                            }
                                                                        }))
                                                                    }} >
                                                                        <option key='e2' value=''>{''}</option>
                                                                        {this.state.langs?.map(lang => <option key={lang?._id || lang.lang} value={lang?._id}>
                                                                            {lang?.name?.translate(this.props.lang)}
                                                                        </option>)}
                                                                    </Input>
                                                                </FormGroup> : null}

                                                                <FormGroup>
                                                                    <Label className='col-form-label-sm'>{'Email address'.translate(this.props.lang)}*</Label>
                                                                    <Input type='email' disabled={this.state.parentExists} required value={this.state.childAccount.parent.email} onChange={e => { if (this.state.parentExists) return; let value = e?.target?.value; this.setState(prev => ({ ...prev, createAccountError: null, childAccount: { ...prev?.childAccount, parent: { ...prev?.childAccount?.parent, email: value } } })) }} onBlur={(e) => {
                                                                        this.checkEmail(e?.target?.value);
                                                                    }} />
                                                                </FormGroup>

                                                                <PhoneField
                                                                    countryTwoLetter={this.props.countryTwoLetter}
                                                                    country={this.props.country}
                                                                    label={'Phone number'.translate(this.props.lang)}
                                                                    value={this.state.childAccount.parent.phone}
                                                                    haveValidation={true}
                                                                    disabled={this.state.parentExists}
                                                                    onChange={e => {
                                                                        if (this.state.parentExists) return;
                                                                        let value = e?.replace(/ /g, '')?.replace('+', '');
                                                                        this.setState(prev => ({
                                                                            ...prev,
                                                                            createAccountError: null,
                                                                            childAccount: {
                                                                                ...prev?.childAccount,
                                                                                parent: { ...prev?.childAccount?.parent, phone: value }
                                                                            }
                                                                        }))
                                                                    }}
                                                                />

                                                                <FormGroup style={{ marginTop: 15 }}>
                                                                    <h6 style={{ margin: 0, padding: 0 }}>{"To use this service, you must agree to".translate(this.props.lang)} {state?.groupData?.name}{"'s".translate(this.props.lang)} {termsOfUse ? <a href={termsOfUse} target="_blank">{"Terms of Service".translate(this.props.lang)}</a> : "Terms of Service".translate(this.props.lang)}.</h6>

                                                                    {/* <CustomCheckbox value={this.state.consent} onChange={value => this.setState({ consent: value })} label={<CustomCheckboxLabel firstLogin={true} groupData={this.state.groupData} getOnlyLabel={true} />} /> */}
                                                                </FormGroup>

                                                                {this.state.createAccountError ? <div style={{ marginTop: 10, fontSize: 14, color: 'red', lineHeight: '18px', textAlign: 'center' }}>
                                                                    {this.state.createAccountError?.translate?.(this.props.lang)}
                                                                </div> : null}

                                                            </>
                                                        :
                                                        <>

                                                            <SSNField lang={this.props.lang} ukDisableCheck={true} country={this.props.country} value={this.state.mainAccount.ssn} onChange={value => {
                                                                if ((this.props.country === 'denmark' && value?.length === 10) || value?.length === 12) this.checkSSN(
                                                                    value,
                                                                    error => this.setState(prev => ({
                                                                        ...prev,
                                                                        ssnError: error?.translate(this.props.lang) || 'Social security number is not valid!'.translate(this.props.lang),
                                                                        createAccountError: null,
                                                                        // mainAccount: {
                                                                        //     ssn: '',
                                                                        //     dateOfBirth: '',
                                                                        //     gender: '',
                                                                        //     name: '',
                                                                        //     language: '',
                                                                        //     email: '',
                                                                        //     phone: '',
                                                                        // }
                                                                    })), (ssn, age, date) => {
                                                                        if (this.props.country === 'uk') return this.setState(prev => ({
                                                                            ...prev,
                                                                            ssnError: null,
                                                                            createAccountError: null,
                                                                            mainAccount: {
                                                                                ...prev?.mainAccount,
                                                                                ssn,
                                                                                dateOfBirth: date
                                                                            }
                                                                        }));
                                                                        if ((this.props.country === 'denmark' && age < CHILD_AGE_LIMIT.denmark) || (this.props.country === 'sweden' && age < CHILD_AGE_LIMIT.sweden) || (this.props.country !== 'sweden' && this.props.country !== 'denmark' && age < 18)) return this.setState({
                                                                            error: `Only a parent/guardian can create an account for a child that is under 13 years old!`
                                                                        });
                                                                        this.setState(prev => ({
                                                                            ...prev,
                                                                            ssnError: null,
                                                                            createAccountError: null,
                                                                            mainAccount: {
                                                                                ...prev?.mainAccount,
                                                                                ssn,
                                                                                dateOfBirth: date
                                                                            }
                                                                        }))
                                                                    });
                                                                else this.setState(prev => ({
                                                                    ...prev,
                                                                    ssnError: null,
                                                                    createAccountError: null,
                                                                    mainAccount: {
                                                                        ...prev?.mainAccount,
                                                                        ssn: value
                                                                    }
                                                                }))
                                                            }} label={this.props.country === 'uk' ? "Date of birth".translate(this.props.lang) : "Social security number".translate(this.props.lang)} error={this.state.ssnError} haveValidation={true} />

                                                            {this.state.platform && this.state.platform === 'germany' ? <DateOfBirthField value={this.state.mainAccount.dateOfBirth} disabled={true} onChange={value => { return; this.setState(prev => ({ ...prev, mainAccount: { ...prev?.mainAccount, dateOfBirth: value } })) }} label={'Date of birth'.translate(this.props.lang)} /> : null}

                                                            <FormGroup>
                                                                <Label className='col-form-label-sm'>{'First and last name'.translate(this.props.lang)}*</Label>
                                                                <Input type='text' required value={this.state.mainAccount.name || ''} onChange={e => { let value = e?.target?.value; this.setState(prev => ({ ...prev, createAccountError: null, mainAccount: { ...prev?.mainAccount, name: value } })) }} />
                                                            </FormGroup>

                                                            <FormGroup>
                                                                <Label size='sm' className='form-group-label-wrap' style={{ position: 'relative' }}>
                                                                    {'Sex'.translate(this.props.lang)}
                                                                    <div className='info' >
                                                                        <Isvg src={infoIcon} />
                                                                        <div className="info-text">{"Your biological sex is needed for proper treatment".translate(this.props.lang)}</div>
                                                                    </div>
                                                                </Label>
                                                                <Input value={this.state.mainAccount.gender} type='select' onChange={e => {
                                                                    let value = e?.target?.value; this.setState(prev => ({
                                                                        ...prev,
                                                                        createAccountError: null,
                                                                        mainAccount: {
                                                                            ...prev.mainAccount,
                                                                            gender: value
                                                                        }
                                                                    }))
                                                                }} >
                                                                    <option key={'e'} value=''>{''}</option>
                                                                    <option key={'m'} value='male'>{'Male'.translate(this.props.lang)}</option>
                                                                    <option key={'f'} value='female'>{'Female'.translate(this.props.lang)}</option>
                                                                </Input>
                                                            </FormGroup>

                                                            {!this.props.secondaryLanguageDisabled ? <FormGroup>
                                                                <Label className='col-form-label-sm'>{'Language'.translate(this.props.lang)}*</Label>
                                                                <Input value={this.state.mainAccount.language} type='select' required onChange={e => {
                                                                    let value = e?.target?.value; this.setState(prev => ({
                                                                        ...prev,
                                                                        createAccountError: null,
                                                                        mainAccount: {
                                                                            ...prev.mainAccount,
                                                                            language: value
                                                                        }
                                                                    }))
                                                                }} >
                                                                    <option key='e2' value=''>{''}</option>
                                                                    {this.state.langs?.map(lang => <option key={lang?._id || lang.lang} value={lang?._id}>
                                                                        {lang?.name.translate(this.props.lang)}
                                                                    </option>)}
                                                                </Input>
                                                            </FormGroup> : null}

                                                            <FormGroup>
                                                                <Label className='col-form-label-sm'>{'Email address'.translate(this.props.lang)}*</Label>
                                                                <Input type='email' required value={this.state.mainAccount.email} onChange={e => { let value = e?.target?.value; this.setState(prev => ({ ...prev, createAccountError: null, mainAccount: { ...prev?.mainAccount, email: value } })) }} onBlur={e => {
                                                                    this.checkEmail(e?.target?.value);
                                                                }} />
                                                            </FormGroup>

                                                            <PhoneField
                                                                countryTwoLetter={this.props.countryTwoLetter}
                                                                country={this.props.country}
                                                                label={'Phone number'.translate(this.props.lang)}
                                                                value={this.state.mainAccount.phone}
                                                                haveValidation={true}
                                                                onChange={e => {
                                                                    let value = e?.replace(/ /g, '')?.replace('+', '');
                                                                    this.setState(prev => ({
                                                                        ...prev,
                                                                        createAccountError: null,
                                                                        mainAccount: {
                                                                            ...prev?.mainAccount,
                                                                            phone: value
                                                                        }
                                                                    }))
                                                                }}
                                                            />

                                                            <FormGroup style={{ marginTop: 15 }}>
                                                                <h6 style={{ margin: 0, padding: 0 }}>{"To use this service, you must agree to".translate(this.props.lang)} {state?.groupData?.name}{"'s".translate(this.props.lang)} {termsOfUse ? <a href={termsOfUse} target="_blank">{"Terms of Service".translate(this.props.lang)}</a> : "Terms of Service".translate(this.props.lang)}.</h6>

                                                                {/* <CustomCheckbox value={this.state.consent} onChange={value => this.setState({ consent: value })} label={<CustomCheckboxLabel firstLogin={true} groupData={this.state.groupData} getOnlyLabel={true} />} /> */}
                                                            </FormGroup>

                                                            {this.state.createAccountError ? <div style={{ marginTop: 10, fontSize: 14, color: 'red', lineHeight: '18px', textAlign: 'center' }}>
                                                                {this.state.createAccountError?.translate?.(this.props.lang)}
                                                            </div> : null}
                                                        </>}
                                                </div>
                                            :
                                            this.state.step === 3
                                                ? <>
                                                    <PhoneField
                                                        countryTwoLetter={this.props.countryTwoLetter}
                                                        country={this.props.country}
                                                        label={'Phone number'.translate(this.props.lang)}
                                                        value={this.state.phone}
                                                        onChange={e => {
                                                            if (this.state.phoneIsVerified || this.props.type === 'create-account') return
                                                            this.setState({ phone: e?.replace(/ /g, '')?.replace('+', '') })
                                                        }}
                                                        disabled={this.state.phoneIsVerified || this.props.type === 'create-account'}
                                                    />
                                                    {this.state.phoneIsVerified ? <div style={{ fontSize: 14, lineHeight: '18px', color: 'green' }}>
                                                        {'Your phone number is verified! Click Next to continue the process and verify you email address'.translate(this.props.lang)}
                                                    </div> : null}
                                                    {this.state.phoneLinkIsSent && !this.state.phoneIsVerified
                                                        ? <FormGroup>
                                                            <Label className='col-form-label-sm'>{'Phone Verification Code'.translate(this.props.lang)}</Label>
                                                            <Input type='text' value={this.state.phoneVerificationCode} onChange={e => this.setState({ phoneVerificationCode: e?.target?.value })} />
                                                        </FormGroup>
                                                        : null}
                                                </>
                                                :
                                                this.state.step === 4
                                                    ?
                                                    <>
                                                        <FormGroup>
                                                            <Label className='col-form-label-sm'>{'Email address'.translate(this.props.lang)}</Label>
                                                            <Input
                                                                disabled={this.state.emailIsVerified || this.props.type === 'create-account'}
                                                                type='email'
                                                                value={this.state.email}
                                                                onChange={e => {
                                                                    if (this.state.emailIsVerified || this.props.type === 'create-account') return
                                                                    const value = e?.target?.value?.trim?.()?.toLowerCase?.();
                                                                    this.setState({ email: value })
                                                                }}
                                                            />
                                                        </FormGroup>
                                                        {this.state.emailIsVerified ? <div style={{ fontSize: 14, lineHeight: '18px', color: 'green' }}>
                                                            {'Your email address is verified! Click Next to continue the process and set your password'.translate(this.props.lang)}
                                                        </div> : null}
                                                        {this.state.emailLinkIsSent && !this.state.emailIsVerified
                                                            ? <FormGroup>
                                                                <Label className='col-form-label-sm'>{'Email Verification Code'.translate(this.props.lang)}</Label>
                                                                <Input type='text' value={this.state.emailVerificationCode} onChange={e => this.setState({ emailVerificationCode: e?.target?.value })} />
                                                            </FormGroup>
                                                            : null}
                                                    </>
                                                    :
                                                    this.state.step === 5
                                                        ? <>
                                                            <FormGroup>
                                                                <Label size='sm' className='form-group-label-wrap'>
                                                                    {'Password'.translate(this.props.lang)}
                                                                    <div className='info' >
                                                                        <Isvg src={infoIcon} />
                                                                        <div style={{ top: 40 }} className="info-text">{'Minimum 10 characters, at least one uppercase letter, one lowercase letter, one number and one special character:'.translate(this.props.lang)}</div>
                                                                    </div>
                                                                </Label>
                                                                <Input type='password' value={this.state.password} onChange={e => this.setState({ password: e?.target?.value })} disabled={this.state.passwordIsSet} />
                                                            </FormGroup>
                                                            <FormGroup>
                                                                <Label size='sm' className='form-group-label-wrap'>
                                                                    {'Repeat password'.translate(this.props.lang)}
                                                                </Label>
                                                                <Input type='password' value={this.state.repeatPassword} onChange={e => this.setState({ repeatPassword: e?.target?.value })} disabled={this.state.passwordIsSet} />
                                                            </FormGroup>
                                                            {this.state.hasPk ? <div>
                                                                <p style={{ fontSize: 12, lineHeight: '14px', textAlign: 'center' }}>*{"Your account already has password set! In the form above you can set a new password or repeat your old one. In either of the cases, the password you now set will be the password you need to use to log into your account, regardless of the type of account you're trying to log in to!".translate(this.props.lang)}</p>
                                                            </div> : this.state.passwordIsSet ? <div>
                                                                <p style={{ fontSize: 12, lineHeight: '14px', textAlign: 'center', color: 'green' }}>*{"You've successfully set your password! Click on Next to finish the process!".translate(this.props.lang)}</p>
                                                            </div> : null}
                                                        </>
                                                        :
                                                        <></>
                        }
                    </ModalBody>

                    <ModalFooter style={{ display: 'block', paddingTop: 5 }}>
                        {this.state.handlerError || this.state.handlerSuccess ? <div>
                            {this.state.handlerError ? <p style={{ textAlign: 'center', color: 'red', fontSize: 14, lineHeight: '18px' }}>{this.state.handlerError?.translate?.(this.props.lang)}</p> : null}
                            {this.state.handlerSuccess ? <p style={{ textAlign: 'center', color: 'green', fontSize: 14, lineHeight: '18px' }}>{this.state.handlerSuccess?.translate?.(this.props.lang)}</p> : null}
                        </div> : null}
                        <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: 10 }}>
                            <button style={{ display: 'none', visibility: 'hidden' }} aria-hidden></button>
                            {this.state.loading || this.state.error || this.state.step === 1 || (this.props.type === 'verify-account' && this.state.step === 2) || this.state.step >= 6 || (this.state.step === 2 && this.state.accountCreated && this.state.verified)
                                ? null
                                : <Button
                                    color='primary'
                                    onClick={() => this.handler(renderButtonTextAndAction().action)}
                                    disabled={renderButtonTextAndAction().disabled}
                                >
                                    {renderButtonTextAndAction().text}
                                </Button>
                            }
                            {this.state.loading
                                ?
                                null
                                :
                                <Button
                                    color={this.state.step === 1 ? 'primary' : ([2, 3, 4, 5].includes(this.state.step) && !(this.state.step === 2 && this.state.accountCreated && this.state.verified)) ? 'danger' : 'success'}
                                    onClick={this.closeModal}
                                >
                                    {'Close'.translate(this.props.lang)}
                                </Button>
                            }
                            <button style={{ display: 'none', visibility: 'hidden' }} aria-hidden></button>
                        </div>
                        {this.state.step === 2 && !this.state.accountCreated && !this.state.error && !this.state.loading && (this.state.parentNeedsVerification && !this.state.parentIsVerified ? false : true) ? <div>
                            <h6 style={{ marginTop: 15, marginBottom: 0, padding: 0 }}>{'You can read about how we handle your personal data:'.translate(this.props.lang)} {privacyPolicy ? <a href={privacyPolicy} target="_blank">{'Privacy Policy'.translate(this.props.lang)}</a> : 'Privacy Policy'.translate(this.props.lang)}.</h6>
                        </div> : null}
                    </ModalFooter>
                </Modal>
                {this.state.warningModal ? <Modal
                    isOpen={this.state.warningModal}
                    centered
                    size='md'
                >
                    <ModalBody className='delete-modal'>
                        <div className='message'>
                            {'Are you sure you want to close modal and stop creating your account? Any changes that you made will be forever lost and your account will be deleted!'.translate(this.props.lang)}
                        </div>
                    </ModalBody>
                    <ModalFooter style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <button style={{ display: 'none', visibility: 'hidden' }}></button>
                        <Button style={{ minWidth: 100 }} color='primary' onClick={() => this.closeWarningModal('cancel')}>
                            {'Cancel'.translate(this.props.lang)}
                        </Button>
                        <Button style={{ minWidth: 100 }} color='danger' onClick={() => this.closeWarningModal('close')}>
                            {'Close'.translate(this.props.lang)}
                        </Button>
                        <button style={{ display: 'none', visibility: 'hidden' }}></button>
                    </ModalFooter>
                </Modal> : null}
            </>
        )
    }
}

export default TwoFactorAuthModal