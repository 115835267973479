import React from 'react';

import Isvg from 'react-inlinesvg';
import moveIcon from '../../assets/svg/move.svg';
import { SketchPicker } from 'react-color'
import Image from './image';
import Icon from './icon';

import Checkbox from '../../components/forms/fields/checkbox';
import Toggle from './toggle';

import { API_ENDPOINT } from '../../constants'

function rgbaToHex(rgba) {
    const { r, g, b, a } = rgba;

    // Convert each component to hexadecimal
    const rHex = r.toString(16).padStart(2, '0');
    const gHex = g.toString(16).padStart(2, '0');
    const bHex = b.toString(16).padStart(2, '0');

    // Convert alpha channel to hexadecimal if available
    let aHex = '';
    if (typeof a === 'number') {
        const alpha = Math.round(a * 255);
        aHex = alpha.toString(16).padStart(2, '0');
    }

    // Combine the hexadecimal components
    const hex = `#${rHex}${gHex}${bHex}${aHex}`;
    // console.log('hex', hex)
    return hex;
}


const elements = {

    'text': {
        style: [

            {
                type: 'button-group',
                width: 100,
                label: 'Text align',
                selector: 'textAlign',
                condition: (style) => (!style || style.display != 'flex'),
                values: [
                    { icon: 'fa fa-align-left', value: 'left' },
                    { icon: 'fa fa-align-center', value: 'center' },
                    { icon: 'fa fa-align-right', value: 'right' },
                ]
            },
            {
                type: 'select',
                multipleParamsSelect: true,
                label: 'Horizontal aligment',
                values: [
                    {
                        name: 'None',
                        checked: (style) => {
                            return (style && ((!style.flexDirection || (style.flexDirection == 'row' && !style.justifyContent)) || (!style.flexDirection || (style.flexDirection == 'column' && !style.alignItems))));
                        },
                        onChange: (style) => {
                            if (style && style.flexDirection == 'column') {
                                return {
                                    alignItems: null,
                                }
                            } else {
                                return {
                                    justifyContent: null
                                }
                            }
                        }
                    },
                    {
                        name: 'Left',
                        checked: (style) => {
                            return (style && (((!style.flexDirection || style.flexDirection == 'row') && style.justifyContent == 'flex-start') || ((!style.flexDirection || style.flexDirection == 'column') && style.alignItems == 'flex-start')));
                        },
                        onChange: (style) => {
                            if (style && style.flexDirection == 'column') {
                                return {
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'flex-start',
                                }
                            } else {
                                return {
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'flex-start'
                                }
                            }
                        }
                    },
                    {
                        name: 'Center',
                        checked: (style) => {
                            return (style && (((!style.flexDirection || style.flexDirection == 'row') && style.justifyContent == 'center') || ((!style.flexDirection || style.flexDirection == 'column') && style.alignItems == 'center')));
                        },
                        onChange: (style) => {
                            if (style && style.flexDirection == 'column') {
                                return {
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                }
                            } else {
                                return {
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center'
                                }
                            }
                        }
                    },
                    {
                        name: 'Right',
                        checked: (style) => {
                            return (style && (((!style.flexDirection || style.flexDirection == 'row') && style.justifyContent == 'flex-end') || ((!style.flexDirection || style.flexDirection == 'column') && style.alignItems == 'flex-end')));
                        },
                        onChange: (style) => {
                            if (style && style.flexDirection == 'column') {
                                return {
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'flex-end',
                                }
                            } else {
                                return {
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'flex-end'
                                }
                            }
                        }
                    }
                ]
            },
            {
                type: 'select',
                multipleParamsSelect: true,
                label: 'Vertical aligment',
                values: [
                    {
                        name: 'None',
                        checked: (style) => {
                            return (style && ((!style.flexDirection || (style.flexDirection == 'row' && !style.alignItems)) || (!style.flexDirection || (style.flexDirection == 'column' && !style.justifyContent))));
                        },
                        onChange: (style) => {
                            if (style && style.flexDirection == 'column') {
                                return {
                                    justifyContent: null,
                                }
                            } else {
                                return {
                                    alignItems: null
                                }
                            }
                        }
                    },
                    {
                        name: 'Top',
                        checked: (style) => {
                            return (style && (((!style.flexDirection || style.flexDirection == 'row') && style.alignItems == 'flex-start') || ((!style.flexDirection || style.flexDirection == 'column') && style.justifyContent == 'flex-start')));
                        },
                        onChange: (style) => {
                            if (style && style.flexDirection == 'column') {
                                return {
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'flex-start',
                                }
                            } else {
                                return {
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'flex-start'
                                }
                            }
                        }
                    },
                    {
                        name: 'Center',
                        checked: (style) => {
                            return (style && (((!style.flexDirection || style.flexDirection == 'row') && style.alignItems == 'center') || ((!style.flexDirection || style.flexDirection == 'column') && style.justifyContent == 'center')));
                        },
                        onChange: (style) => {
                            if (style && style.flexDirection == 'column') {
                                return {
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                }
                            } else {
                                return {
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center'
                                }
                            }
                        }
                    },
                    {
                        name: 'Bottom',
                        checked: (style) => {
                            return (style && (((!style.flexDirection || style.flexDirection == 'row') && style.alignItems == 'flex-end') || ((!style.flexDirection || style.flexDirection == 'column') && style.justifyContent == 'flex-end')));
                        },
                        onChange: (style) => {
                            if (style && style.flexDirection == 'column') {
                                return {
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'flex-end',
                                }
                            } else {
                                return {
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'flex-end'
                                }
                            }
                        }
                    }
                ]
            },
            {
                type: 'color',
                width: 100,
                label: 'Color',
                selector: 'color',
            },
            {
                type: 'text',
                width: 100,
                label: 'Line height',
                selector: 'lineHeight',
            },

            {
                type: 'color',
                width: 100,
                label: 'Background color',
                selector: 'backgroundColor',
            },

            {
                type: 'image',
                width: 100,
                label: 'Background image',
                selector: 'backgroundImage',
                format: (value) => `${value}`

            },
            {
                type: 'select',
                width: 100,
                label: 'Background size',
                selector: 'backgroundSize',
                values: ['', 'contain', 'cover'],
                advanced: true,
                condition: (style) => { if (style && style.backgroundImage) { return true; } else return false }
            },
            {
                type: 'select',
                width: 100,
                label: 'Background repeat',
                selector: 'backgroundRepeat',
                values: ['', 'no-repeat', 'repeat-x', 'repeat-y'],
                advanced: true,
                condition: (style) => { if (style && style.backgroundImage) { return true; } else return false }

            },
            {
                type: 'select',
                width: 100,
                label: 'Background position',
                selector: 'backgroundPosition',
                values: ['', 'top', 'bottom', 'left', 'right', 'center'],
                advanced: true,
                condition: (style) => { if (style && style.backgroundImage) { return true; } else return false }
            },
            {
                type: 'select',
                width: 100,
                label: 'Display',
                selector: 'display',
                values: ['', 'block', 'inline-block', 'flex', 'none'],
                advanced: true,
                //condition: (style) => {if (style && style.backgroundImage) {return true;}else return false}
            },
            {
                type: 'group',
                label: 'Flexbox',
                condition: (style) => { if (style && style.display == 'flex') { return true; } else return false },
                advanced: true,
                children: [

                    {
                        type: 'select',
                        width: 50,
                        label: 'Justify',
                        selector: 'justifyContent',
                        values: ['flex-start', 'center', 'flex-end'],
                    },
                    {
                        type: 'select',
                        width: 50,
                        label: 'Align',
                        selector: 'alignItems',
                        values: ['flex-start', 'center', 'flex-end'],
                    }]
            },


            {
                type: 'group',
                label: 'Padding',
                advanced: true,
                children: [
                    {
                        type: 'text',
                        width: 50,
                        label: 'Top',
                        selector: 'paddingTop'
                    },
                    {
                        type: 'text',
                        width: 50,
                        label: 'Bottom',
                        selector: 'paddingBottom'
                    }, {
                        type: 'text',
                        width: 50,
                        label: 'Left',
                        selector: 'paddingLeft'
                    }, {
                        type: 'text',
                        width: 50,
                        label: 'Right',
                        selector: 'paddingRight'
                    }
                ]
            },
            {
                type: 'group',
                label: 'Margin',
                advanced: true,
                children: [
                    {
                        type: 'text',
                        width: 50,
                        label: 'Top',
                        selector: 'marginTop'
                    },
                    {
                        type: 'text',
                        width: 50,
                        label: 'Bottom',
                        selector: 'marginBottom'
                    }, {
                        type: 'text',
                        width: 50,
                        label: 'Left',
                        selector: 'marginLeft'
                    }, {
                        type: 'text',
                        width: 50,
                        label: 'Right',
                        selector: 'marginRight'
                    }
                ]
            },
            {
                type: 'group',
                label: 'Border radius',
                advanced: true,
                children: [
                    {
                        type: 'text',
                        width: 25,
                        label: '',
                        selector: 'borderTopLeftRadius'
                    },
                    {
                        type: 'text',
                        width: 25,
                        label: '',
                        selector: 'borderTopRightRadius'
                    }, {
                        type: 'text',
                        width: 25,
                        label: '',
                        selector: 'borderBottomLeftRadius'
                    }, {
                        type: 'text',
                        width: 25,
                        label: '',
                        selector: 'borderBottomRightRadius'
                    }
                ]
            },
            {
                type: 'group',
                label: 'Shadow',
                //condition: (style) => { if (style && style.display == 'flex') { return true; } else return false },
                advanced: true,
                children: [
                    {
                        type: 'color',
                        rgb: true,
                        width: 100,
                        label: 'Color',
                        selector: 'boxShadow',
                        getValue: (style) => {
                            let broken = style.boxShadow ? style.boxShadow.split(/ (?![^(]*\))/) : ['0', '0', '0', '0', '#000000'];
                            return broken[4]

                        },
                        format: (value, style) => {
                            let broken = style.boxShadow ? style.boxShadow.split(/ (?![^(]*\))/) : ['0', '0', '0', '0', '#000000'];
                            broken[4] = `rgba(${value.r}, ${value.g}, ${value.b}, ${value.a})`;
                            return broken.join(' ');
                        }

                    },
                    {
                        type: 'text',
                        width: 33.3,
                        label: 'X',
                        selector: 'boxShadow',
                        getValue: (style) => {
                            let broken = style.boxShadow ? style.boxShadow.split(/ (?![^(]*\))/) : ['0', '0', '0', '0', '#000000'];
                            return broken[0]

                        },
                        format: (value, style) => {
                            let broken = style.boxShadow ? style.boxShadow.split(/ (?![^(]*\))/) : ['0', '0', '0', '0', '#000000'];
                            broken[0] = value;
                            return broken.join(' ');
                        }


                    },
                    {
                        type: 'text',
                        width: 33.3,
                        label: 'Y',
                        selector: 'boxShadow',
                        getValue: (style) => {
                            let broken = style.boxShadow ? style.boxShadow.split(/ (?![^(]*\))/) : ['0', '0', '0', '0', '#000000'];
                            return broken[1]

                        },
                        format: (value, style) => {
                            let broken = style.boxShadow ? style.boxShadow.split(/ (?![^(]*\))/) : ['0', '0', '0', '0', '#000000'];
                            broken[1] = value;
                            return broken.join(' ');
                        }


                    }, {
                        type: 'text',
                        width: 33.3,
                        label: 'B',
                        selector: 'boxShadow',
                        getValue: (style) => {
                            let broken = style.boxShadow ? style.boxShadow.split(/ (?![^(]*\))/) : ['0', '0', '0', '0', '#000000'];
                            return broken[2]

                        },

                        format: (value, style) => {
                            let broken = style.boxShadow ? style.boxShadow.split(/ (?![^(]*\))/) : ['0', '0', '0', '0', '#000000'];
                            broken[2] = value;
                            return broken.join(' ');
                        }


                    },
                ]
            },
            {
                type: 'group',
                label: 'Border',
                //condition: (style) => { if (style && style.display == 'flex') { return true; } else return false },
                advanced: true,
                children: [
                    {
                        type: 'color',
                        width: 50,
                        rgb: true,
                        label: 'Color',
                        selector: 'border',
                        getValue: (style) => {
                            let broken = style.border ? style.border.split(/ (?![^(]*\))/) : ['2px', 'solid', '#000000'];
                            return broken[2]

                        },
                        format: (value, style) => {
                            let broken = style.border ? style.border.split(/ (?![^(]*\))/) : ['2px', 'solid', '#000000'];
                            broken[2] = `rgba(${value.r}, ${value.g}, ${value.b}, ${value.a})`;
                            return broken.join(' ');
                        }

                    },
                    {
                        type: 'text',
                        width: 50,
                        label: 'Width',
                        selector: 'border',
                        getValue: (style) => {
                            let broken = style.border ? style.border.split(/ (?![^(]*\))/) : ['2px', 'solid', '#000000'];
                            return broken[0]

                        },
                        format: (value, style) => {
                            let broken = style.border ? style.border.split(/ (?![^(]*\))/) : ['2px', 'solid', '#000000'];
                            broken[0] = value;
                            return broken.join(' ');
                        }

                    },

                ]
            },

        ]
    },
    'button': {
        style: [
            {
                type: 'select',
                multipleParamsSelect: true,
                label: 'Horizontal aligment',
                values: [
                    {
                        name: 'None',
                        checked: (style) => {
                            return (style && ((!style.flexDirection || (style.flexDirection == 'row' && !style.justifyContent)) || (!style.flexDirection || (style.flexDirection == 'column' && !style.alignItems))));
                        },
                        onChange: (style) => {
                            if (style && style.flexDirection == 'column') {
                                return {
                                    alignItems: null,
                                }
                            } else {
                                return {
                                    justifyContent: null
                                }
                            }
                        }
                    },
                    {
                        name: 'Left',
                        checked: (style) => {
                            return (style && (((!style.flexDirection || style.flexDirection == 'row') && style.justifyContent == 'flex-start') || ((!style.flexDirection || style.flexDirection == 'column') && style.alignItems == 'flex-start')));
                        },
                        onChange: (style) => {
                            if (style && style.flexDirection == 'column') {
                                return {
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'flex-start',
                                }
                            } else {
                                return {
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'flex-start'
                                }
                            }
                        }
                    },
                    {
                        name: 'Center',
                        checked: (style) => {
                            return (style && (((!style.flexDirection || style.flexDirection == 'row') && style.justifyContent == 'center') || ((!style.flexDirection || style.flexDirection == 'column') && style.alignItems == 'center')));
                        },
                        onChange: (style) => {
                            if (style && style.flexDirection == 'column') {
                                return {
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                }
                            } else {
                                return {
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center'
                                }
                            }
                        }
                    },
                    {
                        name: 'Right',
                        checked: (style) => {
                            return (style && (((!style.flexDirection || style.flexDirection == 'row') && style.justifyContent == 'flex-end') || ((!style.flexDirection || style.flexDirection == 'column') && style.alignItems == 'flex-end')));
                        },
                        onChange: (style) => {
                            if (style && style.flexDirection == 'column') {
                                return {
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'flex-end',
                                }
                            } else {
                                return {
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'flex-end'
                                }
                            }
                        }
                    }
                ]
            },
            {
                type: 'select',
                multipleParamsSelect: true,
                label: 'Vertical aligment',
                values: [
                    {
                        name: 'None',
                        checked: (style) => {
                            return (style && ((!style.flexDirection || (style.flexDirection == 'row' && !style.alignItems)) || (!style.flexDirection || (style.flexDirection == 'column' && !style.justifyContent))));
                        },
                        onChange: (style) => {
                            if (style && style.flexDirection == 'column') {
                                return {
                                    justifyContent: null,
                                }
                            } else {
                                return {
                                    alignItems: null
                                }
                            }
                        }
                    },
                    {
                        name: 'Top',
                        checked: (style) => {
                            return (style && (((!style.flexDirection || style.flexDirection == 'row') && style.alignItems == 'flex-start') || ((!style.flexDirection || style.flexDirection == 'column') && style.justifyContent == 'flex-start')));
                        },
                        onChange: (style) => {
                            if (style && style.flexDirection == 'column') {
                                return {
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'flex-start',
                                }
                            } else {
                                return {
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'flex-start'
                                }
                            }
                        }
                    },
                    {
                        name: 'Center',
                        checked: (style) => {
                            return (style && (((!style.flexDirection || style.flexDirection == 'row') && style.alignItems == 'center') || ((!style.flexDirection || style.flexDirection == 'column') && style.justifyContent == 'center')));
                        },
                        onChange: (style) => {
                            if (style && style.flexDirection == 'column') {
                                return {
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                }
                            } else {
                                return {
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center'
                                }
                            }
                        }
                    },
                    {
                        name: 'Bottom',
                        checked: (style) => {
                            return (style && (((!style.flexDirection || style.flexDirection == 'row') && style.alignItems == 'flex-end') || ((!style.flexDirection || style.flexDirection == 'column') && style.justifyContent == 'flex-end')));
                        },
                        onChange: (style) => {
                            if (style && style.flexDirection == 'column') {
                                return {
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'flex-end',
                                }
                            } else {
                                return {
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'flex-end'
                                }
                            }
                        }
                    }
                ]
            },
            {
                type: 'button-group',
                width: 100,
                label: 'Text align',
                selector: 'textAlign',
                values: [
                    { icon: 'fa fa-align-left', value: 'left' },
                    { icon: 'fa fa-align-center', value: 'center' },
                    { icon: 'fa fa-align-right', value: 'right' },
                ]
            },

            {
                type: 'color',
                width: 100,
                label: 'Color',
                selector: 'color',
            },

            {
                type: 'color',
                width: 100,
                label: 'Background color',
                selector: 'backgroundColor',
            },
            {
                type: 'select',
                width: 100,
                label: 'Display',
                selector: 'display',
                values: ['', 'block', 'inline-block', 'flex', 'none'],
                advanced: true,
                //condition: (style) => {if (style && style.backgroundImage) {return true;}else return false}
            },
            {
                type: 'group',
                label: 'Flexbox',
                condition: (style) => { if (style && style.display == 'flex') { return true; } else return false },
                advanced: true,
                children: [
                    {
                        type: 'select',
                        width: 50,
                        label: 'Direction',
                        selector: 'flexDirection',
                        values: ['', 'row', 'column'],
                    }, ,
                    {
                        type: 'select',
                        width: 50,
                        label: 'Justify',
                        selector: 'justifyContent',
                        values: ['', 'flex-start', 'center', 'flex-end'],
                    },
                    {
                        type: 'select',
                        width: 50,
                        label: 'Align',
                        selector: 'alignItems',
                        values: ['', 'flex-start', 'center', 'flex-end'],
                    }]
            },


            {
                type: 'group',
                label: 'Padding',
                advanced: true,
                children: [
                    {
                        type: 'text',
                        width: 50,
                        label: 'Top',
                        selector: 'paddingTop'
                    },
                    {
                        type: 'text',
                        width: 50,
                        label: 'Bottom',
                        selector: 'paddingBottom'
                    }, {
                        type: 'text',
                        width: 50,
                        label: 'Left',
                        selector: 'paddingLeft'
                    }, {
                        type: 'text',
                        width: 50,
                        label: 'Right',
                        selector: 'paddingRight'
                    }
                ]
            },
            {
                type: 'group',
                label: 'Margin',
                advanced: true,
                children: [
                    {
                        type: 'text',
                        width: 50,
                        label: 'Top',
                        selector: 'marginTop'
                    },
                    {
                        type: 'text',
                        width: 50,
                        label: 'Bottom',
                        selector: 'marginBottom'
                    }, {
                        type: 'text',
                        width: 50,
                        label: 'Left',
                        selector: 'marginLeft'
                    }, {
                        type: 'text',
                        width: 50,
                        label: 'Right',
                        selector: 'marginRight'
                    }
                ]
            },
            {
                type: 'group',
                label: 'Border radius',
                advanced: true,
                children: [
                    {
                        type: 'text',
                        width: 25,
                        label: '',
                        selector: 'borderTopLeftRadius'
                    },
                    {
                        type: 'text',
                        width: 25,
                        label: '',
                        selector: 'borderTopRightRadius'
                    }, {
                        type: 'text',
                        width: 25,
                        label: '',
                        selector: 'borderBottomLeftRadius'
                    }, {
                        type: 'text',
                        width: 25,
                        label: '',
                        selector: 'borderBottomRightRadius'
                    }
                ]
            },
            {
                type: 'group',
                label: 'Shadow',
                //condition: (style) => { if (style && style.display == 'flex') { return true; } else return false },
                advanced: true,
                children: [
                    {
                        type: 'color',
                        rgb: true,
                        width: 100,
                        label: 'Color',
                        selector: 'boxShadow',
                        getValue: (style) => {
                            let broken = style.boxShadow ? style.boxShadow.split(/ (?![^(]*\))/) : ['0', '0', '0', '0', '#000000'];
                            return broken[4]

                        },
                        format: (value, style) => {
                            let broken = style.boxShadow ? style.boxShadow.split(/ (?![^(]*\))/) : ['0', '0', '0', '0', '#000000'];
                            broken[4] = `rgba(${value.r}, ${value.g}, ${value.b}, ${value.a})`;
                            return broken.join(' ');
                        }

                    },
                    {
                        type: 'text',
                        width: 33.3,
                        label: 'X',
                        selector: 'boxShadow',
                        getValue: (style) => {
                            let broken = style.boxShadow ? style.boxShadow.split(/ (?![^(]*\))/) : ['0', '0', '0', '0', '#000000'];
                            return broken[0]

                        },
                        format: (value, style) => {
                            let broken = style.boxShadow ? style.boxShadow.split(/ (?![^(]*\))/) : ['0', '0', '0', '0', '#000000'];
                            broken[0] = value;
                            return broken.join(' ');
                        }


                    },
                    {
                        type: 'text',
                        width: 33.3,
                        label: 'Y',
                        selector: 'boxShadow',
                        getValue: (style) => {
                            let broken = style.boxShadow ? style.boxShadow.split(/ (?![^(]*\))/) : ['0', '0', '0', '0', '#000000'];
                            return broken[1]

                        },
                        format: (value, style) => {
                            let broken = style.boxShadow ? style.boxShadow.split(/ (?![^(]*\))/) : ['0', '0', '0', '0', '#000000'];
                            broken[1] = value;
                            return broken.join(' ');
                        }


                    }, {
                        type: 'text',
                        width: 33.3,
                        label: 'B',
                        selector: 'boxShadow',
                        getValue: (style) => {
                            let broken = style.boxShadow ? style.boxShadow.split(/ (?![^(]*\))/) : ['0', '0', '0', '0', '#000000'];
                            return broken[2]

                        },

                        format: (value, style) => {
                            let broken = style.boxShadow ? style.boxShadow.split(/ (?![^(]*\))/) : ['0', '0', '0', '0', '#000000'];
                            broken[2] = value;
                            return broken.join(' ');
                        }


                    },
                ]
            },
            {
                type: 'group',
                label: 'Border',
                //condition: (style) => { if (style && style.display == 'flex') { return true; } else return false },
                advanced: true,
                children: [
                    {
                        type: 'color',
                        width: 50,
                        rgb: true,
                        label: 'Color',
                        selector: 'border',
                        getValue: (style) => {
                            let broken = style.border ? style.border.split(/ (?![^(]*\))/) : ['2px', 'solid', '#000000'];
                            return broken[2]

                        },
                        format: (value, style) => {
                            let broken = style.border ? style.border.split(/ (?![^(]*\))/) : ['2px', 'solid', '#000000'];
                            broken[2] = `rgba(${value.r}, ${value.g}, ${value.b}, ${value.a})`;
                            return broken.join(' ');
                        }

                    },
                    {
                        type: 'text',
                        width: 50,
                        label: 'Width',
                        selector: 'border',
                        getValue: (style) => {
                            let broken = style.border ? style.border.split(/ (?![^(]*\))/) : ['2px', 'solid', '#000000'];
                            return broken[0]

                        },
                        format: (value, style) => {
                            let broken = style.border ? style.border.split(/ (?![^(]*\))/) : ['2px', 'solid', '#000000'];
                            broken[0] = value;
                            return broken.join(' ');
                        }

                    },

                ]
            },

        ],
        props: [
            {
                type: 'select',
                width: 100,
                label: 'Type',
                selector: 'type',
                values: [
                    { name: 'Choose type', value: '' },
                    { name: 'Seek care', value: 'seekCare' },
                    { name: 'Information', value: 'information' },
                    { name: 'My Referrals', value: 'myReferral' },
                    { name: 'Drop In', value: 'dropIn' },
                    { name: 'Choose time', value: 'chooseTime' },
                    { name: 'External link', value: 'externalLink' },
                    { name: 'Daybook', value: 'daybook' },
                    { name: 'Login', value: 'login' },
                    { name: 'Listing', value: 'listing' },
                    { name: 'Waiting room', value: 'waitingRoom' }
                ]

            },
            {
                type: 'text',
                width: 100,
                label: 'Link',
                selector: 'link',
                defaultValue: 'https://',
                condition: (props) => props.type == 'externalLink',
                format: (value) => `${value}`

            },

            {
                type: 'toggle-checkbox',
                width: 100,
                label: 'Open in external browser',
                selector: 'openInExternalBrowser',
                condition: (props) => props.type == 'externalLink',
            },

            {
                type: 'icon',
                width: 100,
                label: 'Icon',
                selector: 'icon',
                format: (value) => `${value}`

            },
            {
                type: 'group',
                label: 'Icon size',
                condition: (props) => props.icon,
                children: [
                    {
                        type: 'text',
                        width: 50,
                        label: 'Width',
                        selector: 'iconWidth'
                    },
                    {
                        type: 'text',
                        width: 50,
                        label: 'Height',
                        selector: 'iconHeight'
                    }
                ]
            },
            {
                type: 'group',
                label: 'Icon margin',
                condition: (props) => props.icon,
                children: [
                    {
                        type: 'text',
                        width: 50,
                        label: 'Top',
                        selector: 'iconMarginTop'
                    },
                    {
                        type: 'text',
                        width: 50,
                        label: 'Bottom',
                        selector: 'iconMarginBottom'
                    }, {
                        type: 'text',
                        width: 50,
                        label: 'Left',
                        selector: 'iconMarginLeft'
                    }, {
                        type: 'text',
                        width: 50,
                        label: 'Right',
                        selector: 'iconMarginRight'
                    }
                ]
            },



        ]
    },
    'button2': {
        style: [
            {
                type: 'select',
                multipleParamsSelect: true,
                label: 'Horizontal aligment',
                values: [
                    {
                        name: 'None',
                        checked: (style) => {
                            return (style && ((!style.flexDirection || (style.flexDirection == 'row' && !style.justifyContent)) || (!style.flexDirection || (style.flexDirection == 'column' && !style.alignItems))));
                        },
                        onChange: (style) => {
                            if (style && style.flexDirection == 'column') {
                                return {
                                    alignItems: null,
                                }
                            } else {
                                return {
                                    justifyContent: null
                                }
                            }
                        }
                    },
                    {
                        name: 'Left',
                        checked: (style) => {
                            return (style && (((!style.flexDirection || style.flexDirection == 'row') && style.justifyContent == 'flex-start') || ((!style.flexDirection || style.flexDirection == 'column') && style.alignItems == 'flex-start')));
                        },
                        onChange: (style) => {
                            if (style && style.flexDirection == 'column') {
                                return {
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'flex-start',
                                }
                            } else {
                                return {
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'flex-start'
                                }
                            }
                        }
                    },
                    {
                        name: 'Center',
                        checked: (style) => {
                            return (style && (((!style.flexDirection || style.flexDirection == 'row') && style.justifyContent == 'center') || ((!style.flexDirection || style.flexDirection == 'column') && style.alignItems == 'center')));
                        },
                        onChange: (style) => {
                            if (style && style.flexDirection == 'column') {
                                return {
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                }
                            } else {
                                return {
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center'
                                }
                            }
                        }
                    },
                    {
                        name: 'Right',
                        checked: (style) => {
                            return (style && (((!style.flexDirection || style.flexDirection == 'row') && style.justifyContent == 'flex-end') || ((!style.flexDirection || style.flexDirection == 'column') && style.alignItems == 'flex-end')));
                        },
                        onChange: (style) => {
                            if (style && style.flexDirection == 'column') {
                                return {
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'flex-end',
                                }
                            } else {
                                return {
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'flex-end'
                                }
                            }
                        }
                    }
                ]
            },
            {
                type: 'select',
                multipleParamsSelect: true,
                label: 'Vertical aligment',
                values: [
                    {
                        name: 'None',
                        checked: (style) => {
                            return (style && ((!style.flexDirection || (style.flexDirection == 'row' && !style.alignItems)) || (!style.flexDirection || (style.flexDirection == 'column' && !style.justifyContent))));
                        },
                        onChange: (style) => {
                            if (style && style.flexDirection == 'column') {
                                return {
                                    justifyContent: null,
                                }
                            } else {
                                return {
                                    alignItems: null
                                }
                            }
                        }
                    },
                    {
                        name: 'Top',
                        checked: (style) => {
                            return (style && (((!style.flexDirection || style.flexDirection == 'row') && style.alignItems == 'flex-start') || ((!style.flexDirection || style.flexDirection == 'column') && style.justifyContent == 'flex-start')));
                        },
                        onChange: (style) => {
                            if (style && style.flexDirection == 'column') {
                                return {
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'flex-start',
                                }
                            } else {
                                return {
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'flex-start'
                                }
                            }
                        }
                    },
                    {
                        name: 'Center',
                        checked: (style) => {
                            return (style && (((!style.flexDirection || style.flexDirection == 'row') && style.alignItems == 'center') || ((!style.flexDirection || style.flexDirection == 'column') && style.justifyContent == 'center')));
                        },
                        onChange: (style) => {
                            if (style && style.flexDirection == 'column') {
                                return {
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                }
                            } else {
                                return {
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center'
                                }
                            }
                        }
                    },
                    {
                        name: 'Bottom',
                        checked: (style) => {
                            return (style && (((!style.flexDirection || style.flexDirection == 'row') && style.alignItems == 'flex-end') || ((!style.flexDirection || style.flexDirection == 'column') && style.justifyContent == 'flex-end')));
                        },
                        onChange: (style) => {
                            if (style && style.flexDirection == 'column') {
                                return {
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'flex-end',
                                }
                            } else {
                                return {
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'flex-end'
                                }
                            }
                        }
                    }
                ]
            },
            {
                type: 'button-group',
                width: 100,
                label: 'Text align',
                selector: 'textAlign',
                values: [
                    { icon: 'fa fa-align-left', value: 'left' },
                    { icon: 'fa fa-align-center', value: 'center' },
                    { icon: 'fa fa-align-right', value: 'right' },
                ]
            },

            {
                type: 'color',
                width: 100,
                label: 'Color',
                selector: 'color',
            },

            {
                type: 'color',
                width: 100,
                label: 'Background color',
                selector: 'backgroundColor',
            },
            {
                type: 'select',
                width: 100,
                label: 'Display',
                selector: 'display',
                values: ['', 'block', 'inline-block', 'flex', 'none'],
                advanced: true,
                //condition: (style) => {if (style && style.backgroundImage) {return true;}else return false}
            },
            {
                type: 'group',
                label: 'Flexbox',
                condition: (style) => { if (style && style.display == 'flex') { return true; } else return false },
                advanced: true,
                children: [
                    {
                        type: 'select',
                        width: 50,
                        label: 'Direction',
                        selector: 'flexDirection',
                        values: ['', 'row', 'column'],
                    }, ,
                    {
                        type: 'select',
                        width: 50,
                        label: 'Justify',
                        selector: 'justifyContent',
                        values: ['', 'flex-start', 'center', 'flex-end'],
                    },
                    {
                        type: 'select',
                        width: 50,
                        label: 'Align',
                        selector: 'alignItems',
                        values: ['', 'flex-start', 'center', 'flex-end'],
                    }]
            },


            {
                type: 'group',
                label: 'Padding',
                advanced: true,
                children: [
                    {
                        type: 'text',
                        width: 50,
                        label: 'Top',
                        selector: 'paddingTop'
                    },
                    {
                        type: 'text',
                        width: 50,
                        label: 'Bottom',
                        selector: 'paddingBottom'
                    }, {
                        type: 'text',
                        width: 50,
                        label: 'Left',
                        selector: 'paddingLeft'
                    }, {
                        type: 'text',
                        width: 50,
                        label: 'Right',
                        selector: 'paddingRight'
                    }
                ]
            },
            {
                type: 'group',
                label: 'Margin',
                advanced: true,
                children: [
                    {
                        type: 'text',
                        width: 50,
                        label: 'Top',
                        selector: 'marginTop'
                    },
                    {
                        type: 'text',
                        width: 50,
                        label: 'Bottom',
                        selector: 'marginBottom'
                    }, {
                        type: 'text',
                        width: 50,
                        label: 'Left',
                        selector: 'marginLeft'
                    }, {
                        type: 'text',
                        width: 50,
                        label: 'Right',
                        selector: 'marginRight'
                    }
                ]
            },
            {
                type: 'group',
                label: 'Border radius',
                advanced: true,
                children: [
                    {
                        type: 'text',
                        width: 25,
                        label: '',
                        selector: 'borderTopLeftRadius'
                    },
                    {
                        type: 'text',
                        width: 25,
                        label: '',
                        selector: 'borderTopRightRadius'
                    }, {
                        type: 'text',
                        width: 25,
                        label: '',
                        selector: 'borderBottomLeftRadius'
                    }, {
                        type: 'text',
                        width: 25,
                        label: '',
                        selector: 'borderBottomRightRadius'
                    }
                ]
            },
            {
                type: 'group',
                label: 'Shadow',
                //condition: (style) => { if (style && style.display == 'flex') { return true; } else return false },
                advanced: true,
                children: [
                    {
                        type: 'color',
                        rgb: true,
                        width: 100,
                        label: 'Color',
                        selector: 'boxShadow',
                        getValue: (style) => {
                            let broken = style.boxShadow ? style.boxShadow.split(/ (?![^(]*\))/) : ['0', '0', '0', '0', '#000000'];
                            return broken[4]

                        },
                        format: (value, style) => {
                            let broken = style.boxShadow ? style.boxShadow.split(/ (?![^(]*\))/) : ['0', '0', '0', '0', '#000000'];
                            broken[4] = `rgba(${value.r}, ${value.g}, ${value.b}, ${value.a})`;
                            return broken.join(' ');
                        }

                    },
                    {
                        type: 'text',
                        width: 33.3,
                        label: 'X',
                        selector: 'boxShadow',
                        getValue: (style) => {
                            let broken = style.boxShadow ? style.boxShadow.split(/ (?![^(]*\))/) : ['0', '0', '0', '0', '#000000'];
                            return broken[0]

                        },
                        format: (value, style) => {
                            let broken = style.boxShadow ? style.boxShadow.split(/ (?![^(]*\))/) : ['0', '0', '0', '0', '#000000'];
                            broken[0] = value;
                            return broken.join(' ');
                        }


                    },
                    {
                        type: 'text',
                        width: 33.3,
                        label: 'Y',
                        selector: 'boxShadow',
                        getValue: (style) => {
                            let broken = style.boxShadow ? style.boxShadow.split(/ (?![^(]*\))/) : ['0', '0', '0', '0', '#000000'];
                            return broken[1]

                        },
                        format: (value, style) => {
                            let broken = style.boxShadow ? style.boxShadow.split(/ (?![^(]*\))/) : ['0', '0', '0', '0', '#000000'];
                            broken[1] = value;
                            return broken.join(' ');
                        }


                    }, {
                        type: 'text',
                        width: 33.3,
                        label: 'B',
                        selector: 'boxShadow',
                        getValue: (style) => {
                            let broken = style.boxShadow ? style.boxShadow.split(/ (?![^(]*\))/) : ['0', '0', '0', '0', '#000000'];
                            return broken[2]

                        },

                        format: (value, style) => {
                            let broken = style.boxShadow ? style.boxShadow.split(/ (?![^(]*\))/) : ['0', '0', '0', '0', '#000000'];
                            broken[2] = value;
                            return broken.join(' ');
                        }


                    },
                ]
            },


        ],
        props: [
            {
                type: 'select',
                width: 100,
                label: 'Type',
                selector: 'type',
                values: [
                    { name: 'Choose type', value: '' },
                    { name: 'External link', value: 'externalLink' },
                ]

            },
            {
                type: 'text',
                width: 100,
                label: 'Link',
                selector: 'link',
                defaultValue: 'https://',
                condition: (props) => props.type == 'externalLink',
                format: (value) => `${value}`

            },

            {
                type: 'toggle-checkbox',
                width: 100,
                label: 'Open in external browser',
                selector: 'openInExternalBrowser',
                condition: (props) => props.type == 'externalLink',
            },

            {
                type: 'icon',
                width: 100,
                label: 'Icon',
                selector: 'icon',
                format: (value) => `${value}`

            },
            {
                type: 'group',
                label: 'Icon size',
                condition: (props) => props.icon,
                children: [
                    {
                        type: 'text',
                        width: 50,
                        label: 'Width',
                        selector: 'iconWidth'
                    },
                    {
                        type: 'text',
                        width: 50,
                        label: 'Height',
                        selector: 'iconHeight'
                    }
                ]
            },
            {
                type: 'group',
                label: 'Icon margin',
                condition: (props) => props.icon,
                children: [
                    {
                        type: 'text',
                        width: 50,
                        label: 'Top',
                        selector: 'iconMarginTop'
                    },
                    {
                        type: 'text',
                        width: 50,
                        label: 'Bottom',
                        selector: 'iconMarginBottom'
                    }, {
                        type: 'text',
                        width: 50,
                        label: 'Left',
                        selector: 'iconMarginLeft'
                    }, {
                        type: 'text',
                        width: 50,
                        label: 'Right',
                        selector: 'iconMarginRight'
                    }
                ]
            },



        ]
    },
    'grid': {
        style: [
            {
                type: 'checkbox',
                width: 100,
                label: 'Container',
                id: 'container',
                checked: (style) => {
                    if (style && style.maxWidth == '1140' && style.marginLeft == 'auto' && style.marginRight == 'auto') {
                        return true;
                    } else {
                        return false;
                    }
                },
                onChange: (value) => {
                    if (value) {
                        return {
                            maxWidth: '1140',
                            marginLeft: 'auto',
                            marginRight: 'auto'
                        }
                    } else {
                        return {
                            maxWidth: null,
                            marginLeft: null,
                            marginRight: null
                        }
                    }
                }

            },

            {
                type: 'image',
                width: 100,
                label: 'Background image',
                selector: 'backgroundImage',
                format: (value) => `${value}`

            },
            {
                type: 'select',
                width: 100,
                label: 'Background size',
                selector: 'backgroundSize',
                values: ['', 'contain', 'cover'],
                advanced: true,
                condition: (style) => { if (style && style.backgroundImage) { return true; } else return false }
            },
            {
                type: 'select',
                width: 100,
                label: 'Background repeat',
                selector: 'backgroundRepeat',
                values: ['', 'no-repeat', 'repeat-x', 'repeat-y'],
                advanced: true,
                condition: (style) => { if (style && style.backgroundImage) { return true; } else return false }

            },
            {
                type: 'select',
                width: 100,
                label: 'Background position',
                selector: 'backgroundPosition',
                values: ['top', 'bottom', 'left', 'right', 'center'],
                advanced: true,
                condition: (style) => { if (style && style.backgroundImage) { return true; } else return false }
            },
            {
                type: 'color',
                width: 100,
                label: 'Background color',
                selector: 'backgroundColor',
            },
            {
                type: 'select',
                width: 100,
                label: 'Background blend mode',
                selector: 'backgroundBlendMode',
                values: ['normal', 'overlay'],
                advanced: true,
                condition: (style) => { if (style && style.backgroundImage && style.backgroundColor) { return true; } else return false }
            },


            {
                type: 'group',
                label: 'Padding',
                advanced: true,
                children: [
                    {
                        type: 'text',
                        width: 50,
                        label: 'Top',
                        selector: 'paddingTop'
                    },
                    {
                        type: 'text',
                        width: 50,
                        label: 'Bottom',
                        selector: 'paddingBottom'
                    }, {
                        type: 'text',
                        width: 50,
                        label: 'Left',
                        selector: 'paddingLeft'
                    }, {
                        type: 'text',
                        width: 50,
                        label: 'Right',
                        selector: 'paddingRight'
                    }
                ]
            },
            {
                type: 'group',
                label: 'Margin',
                advanced: true,
                children: [
                    {
                        type: 'text',
                        width: 50,
                        label: 'Top',
                        selector: 'marginTop'
                    },
                    {
                        type: 'text',
                        width: 50,
                        label: 'Bottom',
                        selector: 'marginBottom'
                    }, {
                        type: 'text',
                        width: 50,
                        label: 'Left',
                        selector: 'marginLeft'
                    }, {
                        type: 'text',
                        width: 50,
                        label: 'Right',
                        selector: 'marginRight'
                    }
                ]
            },

            {
                type: 'group',
                label: 'Border radius',
                advanced: true,
                children: [
                    {
                        type: 'text',
                        width: 25,
                        label: '',
                        selector: 'borderTopLeftRadius'
                    },
                    {
                        type: 'text',
                        width: 25,
                        label: '',
                        selector: 'borderTopRightRadius'
                    }, {
                        type: 'text',
                        width: 25,
                        label: '',
                        selector: 'borderBottomLeftRadius'
                    }, {
                        type: 'text',
                        width: 25,
                        label: '',
                        selector: 'borderBottomRightRadius'
                    }
                ]
            }
        ]
    },
    'image': {
        style: [
            {
                type: 'color',
                width: 100,
                label: 'Background color',
                selector: 'backgroundColor',
                advanced: true,
            },
            {
                type: 'select',
                width: 100,
                label: 'Object fit',
                selector: 'objectFit',
                values: ['', 'contain', 'cover'],
                advanced: true,
            },
            {
                type: 'select',
                width: 100,
                label: 'Object position',
                selector: 'objectPosition',
                values: ['', 'top', 'bottom', 'left', 'right', 'center'],
                advanced: true
            },
            {
                type: 'group',
                label: 'Padding',
                advanced: true,
                children: [
                    {
                        type: 'text',
                        width: 50,
                        label: 'Top',
                        selector: 'paddingTop'
                    },
                    {
                        type: 'text',
                        width: 50,
                        label: 'Bottom',
                        selector: 'paddingBottom'
                    }, {
                        type: 'text',
                        width: 50,
                        label: 'Left',
                        selector: 'paddingLeft'
                    }, {
                        type: 'text',
                        width: 50,
                        label: 'Right',
                        selector: 'paddingRight'
                    }
                ]
            },
            {
                type: 'group',
                label: 'Margin',
                advanced: true,
                children: [
                    {
                        type: 'text',
                        width: 50,
                        label: 'Top',
                        selector: 'marginTop'
                    },
                    {
                        type: 'text',
                        width: 50,
                        label: 'Bottom',
                        selector: 'marginBottom'
                    }, {
                        type: 'text',
                        width: 50,
                        label: 'Left',
                        selector: 'marginLeft'
                    }, {
                        type: 'text',
                        width: 50,
                        label: 'Right',
                        selector: 'marginRight'
                    }
                ]
            },
            {
                type: 'group',
                label: 'Border radius',
                advanced: true,
                children: [
                    {
                        type: 'text',
                        width: 25,
                        label: '',
                        selector: 'borderTopLeftRadius'
                    },
                    {
                        type: 'text',
                        width: 25,
                        label: '',
                        selector: 'borderTopRightRadius'
                    }, {
                        type: 'text',
                        width: 25,
                        label: '',
                        selector: 'borderBottomLeftRadius'
                    }, {
                        type: 'text',
                        width: 25,
                        label: '',
                        selector: 'borderBottomRightRadius'
                    }
                ]
            },
            {
                type: 'group',
                label: 'Shadow',
                //condition: (style) => { if (style && style.display == 'flex') { return true; } else return false },
                advanced: true,
                children: [
                    {
                        type: 'color',
                        rgb: true,
                        width: 100,
                        label: 'Color',
                        selector: 'boxShadow',
                        getValue: (style) => {
                            let broken = style.boxShadow ? style.boxShadow.split(/ (?![^(]*\))/) : ['0', '0', '0', '0', '#000000'];
                            return broken[4]

                        },
                        format: (value, style) => {
                            let broken = style.boxShadow ? style.boxShadow.split(/ (?![^(]*\))/) : ['0', '0', '0', '0', '#000000'];
                            broken[4] = `rgba(${value.r}, ${value.g}, ${value.b}, ${value.a})`;
                            return broken.join(' ');
                        }

                    },
                    {
                        type: 'text',
                        width: 33.3,
                        label: 'X',
                        selector: 'boxShadow',
                        getValue: (style) => {
                            let broken = style.boxShadow ? style.boxShadow.split(/ (?![^(]*\))/) : ['0', '0', '0', '0', '#000000'];
                            return broken[0]

                        },
                        format: (value, style) => {
                            let broken = style.boxShadow ? style.boxShadow.split(/ (?![^(]*\))/) : ['0', '0', '0', '0', '#000000'];
                            broken[0] = value;
                            return broken.join(' ');
                        }


                    },
                    {
                        type: 'text',
                        width: 33.3,
                        label: 'Y',
                        selector: 'boxShadow',
                        getValue: (style) => {
                            let broken = style.boxShadow ? style.boxShadow.split(/ (?![^(]*\))/) : ['0', '0', '0', '0', '#000000'];
                            return broken[1]

                        },
                        format: (value, style) => {
                            let broken = style.boxShadow ? style.boxShadow.split(/ (?![^(]*\))/) : ['0', '0', '0', '0', '#000000'];
                            broken[1] = value;
                            return broken.join(' ');
                        }


                    }, {
                        type: 'text',
                        width: 33.3,
                        label: 'B',
                        selector: 'boxShadow',
                        getValue: (style) => {
                            let broken = style.boxShadow ? style.boxShadow.split(/ (?![^(]*\))/) : ['0', '0', '0', '0', '#000000'];
                            return broken[2]

                        },

                        format: (value, style) => {
                            let broken = style.boxShadow ? style.boxShadow.split(/ (?![^(]*\))/) : ['0', '0', '0', '0', '#000000'];
                            broken[2] = value;
                            return broken.join(' ');
                        }


                    },
                ]
            },


        ]
    },
    'mainInfo': {
        style: [

            {
                type: 'group',
                label: 'Group name',
                //condition: (style) => { if (style && style.display == 'flex') { return true; } else return false },
                advanced: false,
                children: [
                    {
                        type: 'text',
                        width: 100,
                        label: 'Group name [en]',
                        selector: 'customGroupNameEn',
                        format: (value) => `${value}`

                    },
                    {
                        type: 'text',
                        width: 100,
                        label: 'Group name [sv]',
                        selector: 'customGroupNameSv',
                        format: (value) => `${value}`

                    },
                    {
                        type: 'select',
                        width: 50,
                        label: 'Visibility',
                        selector: 'visibilityGroupName',
                        values: ["visible", "invisible"],
                    },
                    {
                        type: 'color',
                        // rgb: true,
                        width: 100,
                        label: 'Color',
                        selector: 'groupNameColor',

                    },
                    {
                        type: 'select',
                        width: 50,
                        label: 'Font weight',
                        selector: 'groupNameFontWeight',
                        values: [300, 400, 500, 600, 700],
                    },
                    {
                        type: 'select',
                        width: 50,
                        label: 'Font size',
                        selector: 'groupNameFontSize',
                        values: ['8pt', '10pt', '12pt', '14pt', '16pt', '18pt', '20pt', '22pt', '24pt', '26pt', '28pt', '30pt', '32pt', '34pt', '36pt', '38pt', '40pt', '42pt', '44pt', '46pt', '48pt'],
                    }

                ]
            },
            {
                type: 'group',
                label: 'Search label',
                advanced: false,
                children: [
                    {
                        type: 'text',
                        width: 100,
                        label: 'Text [en]',
                        selector: 'searchLabelTextEn',
                        format: (value) => `${value}`

                    },
                    {
                        type: 'text',
                        width: 100,
                        label: 'Text [sv]',
                        selector: 'searchLabelTextSv',
                        format: (value) => `${value}`

                    },
                    {
                        type: 'color',
                        // rgb: true,
                        width: 100,
                        label: 'Color',
                        selector: 'searchLabelColor',

                    },
                    {
                        type: 'select',
                        width: 50,
                        label: 'Font weight',
                        selector: 'searchLabelFontWeight',
                        values: [300, 400, 500, 600, 700],
                    },
                    {
                        type: 'select',
                        width: 50,
                        label: 'Font size',
                        selector: 'searchLabelFontSize',
                        values: ['8pt', '10pt', '12pt', '14pt', '16pt', '18pt', '20pt', '22pt', '24pt', '26pt', '28pt', '30pt', '32pt', '34pt', '36pt', '38pt', '40pt', '42pt', '44pt', '46pt', '48pt'],
                    },
                    {
                        type: 'select',
                        width: 50,
                        label: 'Visibility',
                        selector: 'searchLabelVisibility',
                        values: ['visible', 'invisible'],
                    },

                ]
            },
            {
                type: 'group',
                label: 'Clinic buttons',
                advanced: false,
                children: [
                    {
                        type: 'color',
                        width: 100,
                        label: 'Background color',
                        selector: 'clinicButtonBackgroundColor',

                    },
                    {
                        type: 'color',
                        width: 100,
                        label: 'Color',
                        selector: 'clinicButtonColor',

                    },
                    {
                        type: 'select',
                        width: 50,
                        label: 'Font weight',
                        selector: 'clinicButtonFontWeight',
                        values: [300, 400, 500, 600, 700],
                    },
                    {
                        type: 'select',
                        width: 50,
                        label: 'Font size',
                        selector: 'clinicButtonFontSize',
                        values: ['8pt', '10pt', '12pt', '14pt', '16pt', '18pt', '20pt', '22pt', '24pt', '26pt', '28pt', '30pt', '32pt', '34pt', '36pt', '38pt', '40pt', '42pt', '44pt', '46pt', '48pt'],
                    }

                ]
            },
            {
                type: 'group',
                label: 'Show all - link',
                advanced: false,
                children: [
                    {
                        type: 'text',
                        width: 100,
                        label: 'Text [en]',
                        selector: 'showAllLinkTextEn',
                        format: (value) => `${value}`

                    },
                    {
                        type: 'text',
                        width: 100,
                        label: 'Text [sv]',
                        selector: 'showAllLinkTextSv',
                        format: (value) => `${value}`

                    },
                    {
                        type: 'color',
                        // rgb: true,
                        width: 100,
                        label: 'Color',
                        selector: 'showAllLinkColor',

                    },
                    {
                        type: 'select',
                        width: 50,
                        label: 'Font weight',
                        selector: 'showAllLinkFontWeight',
                        values: [300, 400, 500, 600, 700],
                    },
                    {
                        type: 'select',
                        width: 50,
                        label: 'Font size',
                        selector: 'showAllLinkFontSize',
                        values: ['8pt', '10pt', '12pt', '14pt', '16pt', '18pt', '20pt', '22pt', '24pt', '26pt', '28pt', '30pt', '32pt', '34pt', '36pt', '38pt', '40pt', '42pt', '44pt', '46pt', '48pt'],
                    }

                ]
            },
            {
                type: 'group',
                label: 'Login text',
                advanced: false,
                children: [
                    {
                        type: 'color',
                        // rgb: true,
                        width: 100,
                        label: 'Color',
                        selector: 'loginTextColor',

                    },
                    {
                        type: 'select',
                        width: 50,
                        label: 'Font weight',
                        selector: 'loginTextFontWeight',
                        values: [300, 400, 500, 600, 700],
                    },
                    {
                        type: 'select',
                        width: 50,
                        label: 'Font size',
                        selector: 'loginTextFontSize',
                        values: ['8pt', '10pt', '12pt', '14pt', '16pt', '18pt', '20pt', '22pt', '24pt', '26pt', '28pt', '30pt', '32pt', '34pt', '36pt', '38pt', '40pt', '42pt', '44pt', '46pt', '48pt'],
                    }

                ]
            },
            {
                type: 'group',
                label: 'Login button',
                advanced: false,
                children: [
                    {
                        type: 'text',
                        width: 100,
                        label: 'Text [en]',
                        selector: 'loginButtonTextEn',
                        format: (value) => `${value}`

                    },
                    {
                        type: 'text',
                        width: 100,
                        label: 'Text [sv]',
                        selector: 'loginButtonTextSv',
                        format: (value) => `${value}`

                    },
                    {
                        type: 'color',
                        // rgb: true,
                        width: 100,
                        label: 'Background color',
                        selector: 'loginButtonBackgroundColor',

                    },
                    {
                        type: 'color',
                        // rgb: true,
                        width: 100,
                        label: 'Color',
                        selector: 'loginButtonColor',

                    },
                ]
            },


        ]
    },


}


/**
* AppBuilder
* @author   Milan Stanojevic
*/
class Toolbox extends React.Component {

    constructor(props) {
        super(props);
        this.state = {

        }
    }

    renderPropsToolbox = (items) => {
        return items.map((item, idx) => {
            let props = {};
            if (this.props.nodes[this.props.activeElement].props) {
                props = { ...this.props.nodes[this.props.activeElement].props };
            }

            if (this.props.layout == 'sm' && this.props.nodes[this.props.activeElement].responsiveProps && this.props.nodes[this.props.activeElement].responsiveProps.sm) {
                props = { ...props, ...this.props.nodes[this.props.activeElement].responsiveProps.sm };

            }
            if (this.props.layout == 'xs' && this.props.nodes[this.props.activeElement].responsiveProps && this.props.nodes[this.props.activeElement].responsiveProps.xs) {
                props = { ...props, ...this.props.nodes[this.props.activeElement].responsiveProps.xs };
            }
            if (this.props.layout == 'app' && this.props.nodes[this.props.activeElement].responsiveProps && this.props.nodes[this.props.activeElement].responsiveProps.app) {
                props = { ...props, ...this.props.nodes[this.props.activeElement].responsiveProps.app };
            }
            if (this.props.isActive && this.props.nodes[this.props.activeElement].activeProps) {
                props = { ...props, ...this.props.nodes[this.props.activeElement].activeProps };
            }



            if ((!item.advanced || (this.state.advanced && item.advanced)) && (!item.condition || (item.condition && item.condition(props)))) {

                if (item.type == 'group') {
                    return (

                        <div className="toolbox-group">
                            <label>{item.label.translate(this.props.lang)}</label>
                            <div>{this.renderPropsToolbox(item.children)}</div>
                        </div>
                    )
                }
                else if (item.type == 'text') {
                    let protocol;
                    if (item.selector == 'link') {
                        protocol = props && props['protocol'] ? props['protocol'] : item.defaultValue;
                    }
                    return (

                        <div className="toolbox-text" style={{ flex: `0 0 ${item.width}%`, maxWidth: `${item.width}%` }}>
                            <label>{item.label.translate(this.props.lang)}</label>
                            <input type="text" onChange={(e) => {
                                let value = e.target.value;
                                let protocol;
                                if (item.selector == 'link') {
                                    if (value?.indexOf('http://') !== -1) {
                                        value = value.slice(7)
                                        protocol = 'http://';
                                    } else {
                                        value = value.slice(8)
                                        protocol = 'https://';
                                    }
                                }
                                this.props.changeProps(this.props.activeElement, item.selector, item.format ? item.format(value, props) : value, protocol);
                            }} value={item.selector == 'link' ? props[item.selector] ? protocol + props[item.selector] : protocol : props[item.selector] ? props[item.selector] : ''} />
                        </div>
                    )
                } else if (item.type == 'color') {
                    return (

                        <div className="toolbox-color" style={{ flex: `0 0 ${item.width}%`, maxWidth: `${item.width}%` }}>
                            <label>{item.label.translate(this.props.lang)}</label>
                            <div className="toolbox-color-wrap">
                                <input type="text" onChange={(e) => {
                                    this.props.changeProps(this.props.activeElement, item.selector, e.target.value);
                                }} value={props[item.selector] ? props[item.selector] : ''} />
                                <button style={{ backgroundColor: props[item.selector] ? props[item.selector] : '' }} onClick={() => {
                                    let obj = {};
                                    obj[this.props.activeElement + '-' + item.selector] = !this.state[this.props.activeElement + '-' + item.selector];
                                    this.setState(obj);
                                }}></button>
                            </div>
                            {
                                this.state[this.props.activeElement + '-' + item.selector] ? <div className="popover">
                                    <div className="cover" onClick={() => {
                                        let obj = {};
                                        obj[this.props.activeElement + '-' + item.selector] = false;
                                        this.setState(obj);

                                    }}></div>
                                    <SketchPicker
                                        color={props && props[item.selector] ? props[item.selector] : ''} onChange={(color) => {
                                            // this.props.changeProps(this.props.activeElement, item.selector, item.format ? item.format(color.hex, style) : color.hex);
                                            this.props.changeStyle(this.props.activeElement, item.selector, item.format ? item.format(color.rgb ? color.rgb : color.hex, style) : color.rgb ? rgbaToHex(color.rgb) : color.hex);

                                        }}
                                    />
                                </div>
                                    :
                                    null
                            }
                        </div>
                    )
                } else if (item.type == 'icon') {
                    return (

                        <div className="toolbox-image" style={{ flex: `0 0 ${item.width}%`, maxWidth: `${item.width}%` }}>
                            <label>{item.label.translate(this.props.lang)}</label>
                            <Icon libraryIcons={this.props.libraryIcons} lang={this.props.lang}
                                onChange={(value) => {
                                    this.props.changeProps(this.props.activeElement, item.selector, item.format ? item.format(value) : value);
                                }}
                                value={props && props[item.selector] ? props[item.selector] : ''}
                            ></Icon>

                        </div>
                    )
                } else if (item.type == 'image') {
                    return (

                        <div className="toolbox-image" style={{ flex: `0 0 ${item.width}%`, maxWidth: `${item.width}%` }}>
                            <label>{item.label.translate(this.props.lang)}</label>
                            <Image lang={this.props.lang}
                                onChange={(value) => {
                                    this.props.changeProps(this.props.activeElement, item.selector, item.format ? item.format(value) : value);
                                }}
                                value={props && props[item.selector] ? props[item.selector] : ''}
                            ></Image>

                        </div>
                    )
                } else if (item.type == 'select') {
                    return (



                        <div className="toolbox-select" style={{ flex: `0 0 ${item.width}%`, maxWidth: `${item.width}%` }}>
                            <label>{item.label.translate(this.props.lang)}</label>
                            <select onChange={(e) => {
                                this.props.changeProps(this.props.activeElement, item.selector, e.target.value);
                            }} value={props && props[item.selector] ? props[item.selector] : ''}>
                                {
                                    item.values.map((option) => {
                                        if ((option.value != 'daybook' && option.value != 'listing') || (option.value == 'daybook' && this.props.enterprise) || (option.value == 'listing' && this.props.listing))
                                            return (
                                                <option value={typeof option == 'object' ? option.value : option}>{typeof option == 'object' ? option.name.translate(this.props.lang) : option}</option>
                                            )
                                    })
                                }
                            </select>
                        </div>
                    )
                } else if (item.type == 'button-group') {
                    let value = props && props[item.selector] ? props[item.selector] : '';
                    return (
                        <div className="toolbox-button-group" style={{ flex: `0 0 ${item.width}%`, maxWidth: `${item.width}%` }}>
                            <label>{item.label.translate(this.props.lang)}</label>
                            <div>
                                {
                                    item.values.map((option) => {
                                        return (
                                            <button onClick={() => {
                                                this.props.changeProps(this.props.activeElement, item.selector, option.value);
                                            }} className={value == option.value ? 'active' : ''}><i className={option.icon} /></button>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    )
                } else if (item.type == 'toggle-checkbox') {
                    let value = props && props[item.selector] ? props[item.selector] : '';
                    return (
                        <div className="toolbox-button-group" style={{ marginTop: 20, marginBottom: 15 }}>
                            <label>{item.label.translate(this.props.lang)}</label>
                            <div className='toolbox-toggle-container'>
                                <Toggle checked={value} onChange={(e) => {
                                    this.props.changeProps(this.props.activeElement, item.selector, !value);
                                }} />
                            </div>
                        </div>
                    )
                }
            }


        })
    }

    renderToolbox = (items) => {
        return items.map((item, idx) => {
            let style = {};

            if (this.props.nodes[this.props.activeElement].style) {
                style = { ...this.props.nodes[this.props.activeElement].style };
            }

            if (this.props.layout == 'sm' && this.props.nodes[this.props.activeElement].responsiveStyle && this.props.nodes[this.props.activeElement].responsiveStyle.sm) {
                style = { ...style, ...this.props.nodes[this.props.activeElement].responsiveStyle.sm };
            }
            if (this.props.layout == 'xs' && this.props.nodes[this.props.activeElement].responsiveStyle && this.props.nodes[this.props.activeElement].responsiveStyle.xs) {
                style = { ...style, ...this.props.nodes[this.props.activeElement].responsiveStyle.xs };
            }
            if (this.props.layout == 'app' && this.props.nodes[this.props.activeElement].responsiveStyle && this.props.nodes[this.props.activeElement].responsiveStyle.app) {
                style = { ...style, ...this.props.nodes[this.props.activeElement].responsiveStyle.app };
            }
            if (this.props.isActive && this.props.nodes[this.props.activeElement].activeStyle) {
                style = { ...style, ...this.props.nodes[this.props.activeElement].activeStyle };
            }
            if (style && style.boxShadow) {
                style.boxShadow = style.boxShadow.replaceAll('px', '')
            }



            if ((!item.advanced || (this.state.advanced && item.advanced)) && (!item.condition || (item.condition && item.condition(style)))) {

                if (item.type == 'group') {
                    return (

                        <div className="toolbox-group">
                            <label>{item.label.translate(this.props.lang)}</label>
                            <div>{this.renderToolbox(item.children)}</div>
                        </div>
                    )
                }
                else if (item.type == 'text') {
                    return (

                        <div className="toolbox-text" style={{ flex: `0 0 ${item.width}%`, maxWidth: `${item.width}%` }}>
                            <label>{item.label.translate(this.props.lang)}</label>
                            <input type="text" onChange={(e) => {
                                this.props.changeStyle(this.props.activeElement, item.selector, item.format ? item.format(e.target.value, style) : e.target.value);
                            }} value={style[item.selector] ? item.getValue ? item.getValue(style) : style[item.selector] : ''} />
                        </div>
                    )
                } else if (item.type == 'color') {
                    return (

                        <div className="toolbox-color" style={{ flex: `0 0 ${item.width}%`, maxWidth: `${item.width}%` }}>
                            <label>{item.label.translate(this.props.lang)}</label>
                            <div className="toolbox-color-wrap">
                                <input type="text" onChange={(e) => {
                                    this.props.changeStyle(this.props.activeElement, item.selector, item.format ? item.format(e.target.value, style) : e.target.value);
                                }} value={style[item.selector] ? item.getValue ? item.getValue(style) : style[item.selector] : ''} />
                                <button style={{ backgroundColor: style[item.selector] ? style[item.selector] : '' }} onClick={() => {
                                    let obj = {};
                                    obj[this.props.activeElement + '-' + item.selector] = !this.state[this.props.activeElement + '-' + item.selector];
                                    this.setState(obj);
                                }}></button>
                            </div>
                            {
                                this.state[this.props.activeElement + '-' + item.selector] ? <div className="popover">
                                    <div className="cover" onClick={() => {
                                        let obj = {};
                                        obj[this.props.activeElement + '-' + item.selector] = false;
                                        this.setState(obj);

                                    }}></div>
                                    <SketchPicker color={style && style[item.selector] ? item.getValue ? item.getValue(style) : style[item.selector] : ''} onChange={(color) => {
                                        this.props.changeStyle(this.props.activeElement, item.selector, item.format ? item.format(color.rgb ? color.rgb : color.hex, style) : color.rgb ? rgbaToHex(color.rgb) : color.hex);

                                    }} />
                                </div>
                                    :
                                    null
                            }
                        </div>
                    )
                } else if (item.type == 'icon') {
                    return (

                        <div className="toolbox-image" style={{ flex: `0 0 ${item.width}%`, maxWidth: `${item.width}%` }}>
                            <label>{item.label.translate(this.props.lang)}</label>
                            <Icon libraryIcons={this.props.libraryIcons} lang={this.props.lang}
                                onChange={(value) => {
                                    this.props.changeStyle(this.props.activeElement, item.selector, item.format ? item.format(value) : value);
                                }}
                                value={style && style[item.selector] ? style[item.selector] : ''}
                            ></Icon>
                        </div>
                    )
                } else if (item.type == 'image') {
                    return (

                        <div className="toolbox-image" style={{ flex: `0 0 ${item.width}%`, maxWidth: `${item.width}%` }}>
                            <label>{item.label.translate(this.props.lang)}</label>
                            <Image lang={this.props.lang}
                                onChange={(value) => {
                                    this.props.changeStyle(this.props.activeElement, item.selector, item.format ? item.format(value) : value);
                                }}
                                value={style && style[item.selector] ? style[item.selector] : ''}
                            ></Image>
                        </div>
                    )
                } else if (item.type == 'select') {
                    return (

                        <div className="toolbox-select" style={{ flex: `0 0 ${item.width}%`, maxWidth: `${item.width}%` }}>
                            <label>{item.label.translate(this.props.lang)}</label>
                            {item.multipleParamsSelect ?

                                <select onChange={(e) => {
                                    //this.props.changeStyle(this.props.activeElement, item.selector, e.target.value);

                                    let idx = e.target.value;
                                    let res = item.values[idx].onChange(style);
                                    this.props.changeMultipleStyle(this.props.activeElement, res);

                                }} >
                                    {
                                        item.values.map((option, idx) => {
                                            return (
                                                <option selected={option.checked(style)} value={idx}>{option.name.translate(this.props.lang)}</option>
                                            )
                                        })
                                    }
                                </select>
                                :

                                <select onChange={(e) => {
                                    this.props.changeStyle(this.props.activeElement, item.selector, e.target.value);
                                }} value={style && style[item.selector] ? style[item.selector] : ''}>
                                    {
                                        item.values.map((option) => {
                                            return (
                                                <option value={typeof option == 'object' ? option.value : option}>{typeof option == 'object' ? option.name.translate(this.props.lang) : option}</option>
                                            )
                                        })
                                    }
                                </select>
                            }
                        </div>
                    )
                } else if (item.type == 'button-group') {
                    let value = style && style[item.selector] ? style[item.selector] : '';
                    return (
                        <div className="toolbox-button-group" style={{ flex: `0 0 ${item.width}%`, maxWidth: `${item.width}%` }}>
                            <label>{item.label.translate(this.props.lang)}</label>
                            <div>
                                {
                                    item.values.map((option) => {
                                        return (
                                            <button onClick={() => {
                                                this.props.changeStyle(this.props.activeElement, item.selector, option.value);
                                            }} className={value == option.value ? 'active' : ''}><i className={option.icon} /></button>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    )
                } else if (item.type == 'checkbox') {
                    return (

                        <div className="toolbox-checkbox" style={{ flex: `0 0 ${item.width}%`, maxWidth: `${item.width}%` }}>
                            <Checkbox label={item.label.translate(this.props.lang)} checked={item.checked(style)} onChange={(e) => {
                                let res = item.onChange(e.target.checked);
                                this.props.changeMultipleStyle(this.props.activeElement, res);


                            }} />
                        </div>
                    )
                }
            }

        })
    }

    render() {
        return (
            <div className={`app-builder-toolbox`}>
                <div className="app-builder-toolbox-header">
                    <h6>{'Toolbox'.translate(this.props.lang)}</h6>
                    <Toggle checked={this.state.advanced} onChange={(e) => this.setState({ advanced: e.target.checked })} label={'Advanced'.translate(this.props.lang)}></Toggle>
                </div>
                <div className="app-builder-toolbox-scroll">

                    {
                        this.props.activeElement && this.props.nodes[this.props.activeElement] && elements && elements[this.props.nodes[this.props.activeElement].type] && elements[this.props.nodes[this.props.activeElement].type].style ?
                            this.renderToolbox(elements[this.props.nodes[this.props.activeElement].type].style)
                            :
                            null
                    }

                    {
                        this.props.activeElement && this.props.nodes[this.props.activeElement] && elements && elements[this.props.nodes[this.props.activeElement].type] && elements[this.props.nodes[this.props.activeElement].type].props ?
                            this.renderPropsToolbox(elements[this.props.nodes[this.props.activeElement].type].props)
                            :
                            null
                    }


                    {this.state.advanced && this.props.nodes[this.props.activeElement] ?
                        <div className="toolbox-group visibility-group">
                            <label>{'Visibility'.translate(this.props.lang)}</label>
                            <div>
                                <Checkbox checked={this.props.nodes[this.props.activeElement].visibility.lg} onChange={(e) => this.props.changeVisibility(this.props.activeElement, 'lg', e.target.checked)} label={"Desktop".translate(this.props.lang)}></Checkbox>
                                <Checkbox checked={this.props.nodes[this.props.activeElement].visibility.sm} onChange={(e) => this.props.changeVisibility(this.props.activeElement, 'sm', e.target.checked)} label={"Tablet".translate(this.props.lang)}></Checkbox>
                                <Checkbox checked={this.props.nodes[this.props.activeElement].visibility.xs} onChange={(e) => this.props.changeVisibility(this.props.activeElement, 'xs', e.target.checked)} label={"Phone".translate(this.props.lang)}></Checkbox>
                                <Checkbox checked={this.props.nodes[this.props.activeElement].visibility.app} onChange={(e) => this.props.changeVisibility(this.props.activeElement, 'app', e.target.checked)} label={"App".translate(this.props.lang)}></Checkbox>

                            </div>
                        </div>
                        :
                        null
                    }
                    {this.state.advanced && this.props.nodes[this.props.activeElement] && this.props.nodes[this.props.activeElement].type == 'text' ?
                        <div className="toolbox-group visibility-group">
                            <label>{'Hide'.translate(this.props.lang)}</label>
                            <div>
                                <Checkbox checked={this.props.nodes[this.props.activeElement].hideLoggedIn} onChange={(e) => this.props.changeHideElement(this.props.activeElement, 'hideLoggedIn', e.target.checked)} label={"Logged in".translate(this.props.lang)}></Checkbox>
                                <Checkbox checked={this.props.nodes[this.props.activeElement].hideLoggedOut} onChange={(e) => this.props.changeHideElement(this.props.activeElement, 'hideLoggedOut', e.target.checked)} label={"Logged out".translate(this.props.lang)}></Checkbox>

                            </div>
                        </div>
                        :
                        null
                    }

                </div>

            </div>
        )
    }
}

export default Toolbox;