import React, { Component } from 'react';
import Link from '../components/link';

import Isvg from 'react-inlinesvg';
import Page from '../containers/page';

import { API_ENDPOINT } from '../constants';

import { Player, Controls } from "@lottiefiles/react-lottie-player";

import InfoModal from '../components/infoModal';
import ErrorModal from '../components/errorModal';

import {
    Container,
    Row,
    Col,
    Modal, ModalHeader, ModalBody, ModalFooter, Button, Input, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, FormGroup, Label
} from 'reactstrap';


import { getSearchParams, generateSearchLink, generateSearchLinkMultiple } from '../helpers/linkHelper';



class WebDocAuth extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true
        };
    }

    componentDidMount() {

        this.checkInterval = setInterval(() => {
            this.makeToken()
        }, 3000);

    }
    makeToken = () => {
        let params = {};
        if (this.state.useState) {
            params = this.state.params;
        } else {
            params = getSearchParams(this.props[0].location.search, { entries: 10, page: 0 })
        }
        if (params && params.code && this.props.selectedGroup && this.props.selectedClinic) {
            if (this.checkInterval) {
                clearInterval(this.checkInterval)
            }
            fetch(API_ENDPOINT + '/users/patient/search/webdoc/make-token', {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify({
                    group: this.props.selectedGroup,
                    clinic: this.props.selectedClinic,
                    code: params.code
                })
            }).then(res => res.json()).then((result) => {
                if (result && !result.error) {
                    this.setState({ loading: false, messageModal: 'The token was successfully created'.translate(this.props.lang) })
                } else if (result && result.error) {
                    this.setState({ loading: false, error: 'An error has occurred, please try again'.translate(this.props.lang) })
                }

            })
        }
    }

    render() {
        return (
            <div className='web-doc-auth-wrap'>
                {
                    this.state.loading ?
                        <div className="loader-wrap" >
                            <Player
                                autoplay={true}
                                loop={true}
                                src="/lf30_editor_l5cxzdyf.json"
                                style={{ height: typeof window != 'undefined' && window.innerWidth < 768 ? "128px" : "256px", width: typeof window != 'undefined' && window.innerWidth < 768 ? "128px" : "256px" }}
                            ></Player>

                        </div>
                        :
                        null
                }

                {
                    this.props.lang && this.state.loading ?
                        <p>{"Fetching token...".translate(this.props.lang)}</p>
                        :
                        null
                }
                {this.state.messageModal ?
                    <InfoModal
                        isOpen={this.state.messageModal}

                        toggle={() => this.setState({ messageModal: null })}
                        header={'Info'.translate(this.props.lang)}
                        info={this.state.messageModal}
                        buttons={[
                            <Button color='primary' onClick={() => {
                                this.setState({
                                    messageModal: null
                                }, () => {
                                    if (typeof window != 'undefined') {
                                        window.close();
                                    }
                                })

                            }}>{'Ok'.translate(this.props.lang)}</Button>
                        ]}
                    />

                    :

                    null}
                {this.state.error ?

                    <ErrorModal lang={this.props.lang}
                        isOpen={this.state.error}
                        toggle={() => this.setState({ error: null })}

                    >
                        {this.state.error}
                    </ErrorModal>

                    :
                    null
                }
            </div>
        );
    }
}

export default WebDocAuth;