import React, { Component } from 'react';

import {
    Container,
    Row,
    Col, Input, Label, Modal, ModalBody, ModalHeader, FormGroup, Button
} from 'reactstrap';


import ErrorModal from '../../../components/errorModal';
import { API_ENDPOINT } from '../../../constants';

import TimePicker from '../../../components/forms/fields/timepicker';

import Isvg from 'react-inlinesvg'
import PatientFilter from '../../../components/patientFilter';

import "react-big-calendar/lib/css/react-big-calendar.css";
import { Tooltip } from '@material-ui/core';
import { withStyles, makeStyles } from "@material-ui/core/styles";

import PaysonSvg from '../../../assets/svg/payson.svg';
import SwishSvg from '../../../assets/svg/swish.svg';
import Card from '../../../assets/svg/card.svg';

import checkIcon from '../../../assets/svg/check-icon.svg';
import calendar from '../../../assets/svg/calendar.svg';


import BankIdModal from '../../../components/bankIdModal';

import backIcon from '../../../assets/svg/back-icon.svg'
import clockIcon from '../../../assets/svg/clock-icon.svg'




import { Player, Controls } from "@lottiefiles/react-lottie-player";
import Payson from '../../../components/payson';
import Swish from '../../swish';


import moment from 'moment';

moment.locale('en')

import ModalFooter from 'reactstrap/lib/ModalFooter';

function parseJSON(response) {
    return response.json().then(json => {
        return {
            result: json,
            status: response.status
        }
    })
}
// function getMonday(d) {
//     d = new Date(d);
//     var day = d.getDay(),
//         diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
//     return new Date(d.setDate(diff));
// }

function getStartTS(date) {
    let start = null;
    let checkDate = new Date(date);
    checkDate.setHours(0, 0, 0, 0)

    let timestamp = Math.floor(new Date(checkDate).getTime() / 1000)
    if (timestamp) {
        start = timestamp;
    }

    return start;
}
function getEndTS(date) {
    let start = null;
    let checkDate = new Date(date);
    checkDate.setHours(23, 59, 59, 59)

    let timestamp = Math.floor(new Date(checkDate).getTime() / 1000)
    if (timestamp) {
        start = timestamp;
    }

    return start;
}

/**
* React Big Calendar for patient schedule
* @author   Aleksandar Dabic
*/
class WebBookingCalendar extends Component {
    constructor(props) {
        super(props);
        this.player = React.createRef();
        this.player2 = React.createRef();
        this.state = {
            ...props.initialData,
            form: false,
            useState: false,
            params: {
                entries: 10,
                page: 0
            },
            items: [],
            total: 0,
            loading: true,
            referrals: [],
            // view: 'week',
            view: typeof window != 'undefined' && window.innerWidth < 768 ? 'day' : 'week',
            doctors: [],
            doctor: null,
            clinics: [],
            wayOfPaying: false,
            age: 25,
            patientData: {},
            referralType: 1,
            referralSections: { header: '', footer: '', body: '' },
            version: 0,
            sections: [],
            calendarMonth: [],
            monday: null,
            weekDays: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
            // weekDaysMob: ['M', 'T', 'W', 'T', 'F', 'S', 'S'],
            weekDaysMob: ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'],
            weekViewDate: new Date()

        };
        this.state.min = new Date();
        this.state.min.setHours(8, 30, 0)
        this.state.max = new Date();
        this.state.max.setHours(20, 30, 0)
    }




    get = () => {


        let professions = [];

        let clinic;
        if (this.props.selectedReferral && this.props.selectedReferral.clinic && this.props.selectedReferral.clinic._id) {
            clinic = this.props.selectedReferral.clinic._id;
        }

        if (this.props.availableProfessions && this.props.personalQuestionary && this.props.personalQuestionary.professionList) {
            for (let i = 0; i < this.props.availableProfessions.length; i++) {
                if (this.props.personalQuestionary.professionList.filter(item => item == this.props.availableProfessions[i].value).length) {
                    professions.push(this.props.availableProfessions[i])
                }
            }
        }
        let alias;
        if (this.props[0].match.params.alias) {
            alias = this.props[0].match.params.alias;
        } else if (typeof window != 'undefined' && localStorage.groupAlias) {
            alias = localStorage.groupAlias;
        }
        fetch(API_ENDPOINT + '/data/clinic-groups/without-login/patient-data', {
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify({
                alias: alias
            })
        }).then(res => res.json()).then((result) => {
            if (result) {
                this.setState({
                    clinics: result.clinics,
                    groupId: result.groupId,
                    agentId: result.agentId,
                    apiKey: result.apiKey,
                    bankAccount: result.bankAccount
                })


            }
        }).then(() => fetch(API_ENDPOINT + '/users/subaccounts/doctors/personal-questionary', {
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify({
                page: null,
                entries: null,
                groupId: this.state.groupId ? this.state.groupId : null,
                clinic: this.state.clinics ? this.state.clinics[0]._id : null,
                recipientClinic: clinic ? clinic : null,
                query: {},
                personalQuestionary: this.props.personalQuestionary?._id,
                availableProfessions: professions,
                uid: this.props.uData && this.props.uData._id ? this.props.uData._id : null
            })
        }).then(res => res.json()).then((result) => {
            if (result) {
                this.setState({
                    doctors: result.doctors,
                    firstDoctor: result.firstDoctor
                })
                if (!(result && result.doctors && result.doctors.length && result.firstDoctor)) {
                    this.setState({
                        error: "No available time slots.",
                    })

                }
            }
        }).then(() => {
            this.fetchFreeEventsCalendar(() => { this.checkFirstFreeEvent() }, true)
            // fetch(API_ENDPOINT + '/working-hours/get-all/events', {
            //     method: 'POST',
            //     headers: {
            //         'content-type': 'application/json'
            //     },
            //     body: JSON.stringify({
            //         doctor: this.state.doctor,
            //         clinic: this.state.clinics ? this.state.clinics[0]._id : null,
            //         group: this.state.groupId ? this.state.groupId : null,
            //         recipientClinic: clinic ? clinic : null

            //     })
            // }).then(res => res.json()).then((result) => {
            //     if (result) {
            //         this.setState({
            //             items: result,
            //             loadingDoctor: false
            //         })
            //     }
            //     this.doctorWithoutFreeEvents()
            // })

        }))



    }
    checkFirstFreeEvent = () => {
        let firstFreeEvent = this.state.firstFreeEvent;

        if (firstFreeEvent) {
            this.setState({ monday: this.getMonday(new Date(firstFreeEvent * 1000)) })
        }

    }

    getMonday = (d) => {
        if (d) {
            d = new Date(d);
        } else {
            d = new Date();
        }

        // d = this.props.getStringDateTs(Math.floor(d.getTime() / 1000), this.props.dateFormat)

        d = new Date(d);

        var day = d.getDay(),
            diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday

        let monday = new Date(d.setDate(diff));
        monday = new Date(this.props.getStringDateTs(Math.floor(monday.getTime() / 1000), this.props.dateFormat))
        return monday;
    }


    componentDidMount() {
        this.get();
        this.setState({ monday: this.getMonday() })

        // if (typeof window !== 'undefined' && localStorage.tempEvent && localStorage.tempDoctor && localStorage.webBookingBankId) {
        //     this.setState({
        //         selectEvent: JSON.parse(localStorage.tempEvent),
        //         doctor: JSON.parse(localStorage.tempDoctor),
        //         bankIdModal: true
        //     }, () => {
        //         if (typeof window !== 'undefined' && !localStorage.bankIdStarted) {
        //             localStorage.removeItem('tempEvent')
        //             localStorage.removeItem('tempDoctor')
        //         }
        //     })
        // }

    }

    componentDidUpdate(prevProps, prevState) {
        // if (prevState.bankIdModal !== this.state.bankIdModal) {
        //     if (typeof window !== 'undefined') {
        //         if (this.state.bankIdModal) {
        //             localStorage.setItem('webBookingBankId', true)
        //         } else {
        //             localStorage.removeItem('webBookingBankId')
        //         }
        //     }
        // }

        if (this.state.referrals && this.state.referrals.length && !this.state.referralId && !this.state.doNotUpdate) {
            this.setState({
                referralId: this.state.referrals[0]._id,
                referralNumber: this.state.referrals[0].patient.referralNumber,
                doNotUpdate: true
            })
            this.props.refId(this.state.referrals[0]._id)

            if (this.state.referrals[0]._id) {
                for (let k = 0; k < this.state.referrals.length; k++) {
                    if (this.state.referrals[0]._id == this.state.referrals[k]._id) {
                        this.setState({
                            clinic: this.state.referrals[k].recipientClinic
                        }, () => this.get())
                        // console.log(this.state.referrals[k].recipientClinic)
                    }
                }
            } else {
                this.setState({
                    doctors: []
                })
            }
        }

        if (this.state.doctors && this.state.doctors.length && !this.state.doctor) {
            if (!this.state.firstDoctor) {
                this.setState({
                    noAvailableDoctors: 'None of the doctors has free time for schedule in this clinic.',
                    doctor: this.state.doctors[0]._id,
                }, () => {
                    this.getWorkingTime()
                    // if (typeof window !== 'undefined') {
                    //     localStorage.setItem('tempDoctor', JSON.stringify(this.state.doctor))
                    // }
                })
            } else {
                this.setState({
                    doctor: this.state.firstDoctor,
                }, () => {
                    this.getWorkingTime()
                    // if (typeof window !== 'undefined') {
                    //     localStorage.setItem('tempDoctor', JSON.stringify(this.state.doctor))
                    // }
                })
            }



        }



        if (this.state.monday !== prevState.monday || this.state.monthView !== prevState.monthView || this.state.monthView && this.state.monthViewDate !== prevState.monthViewDate) {
            let firstWeek = this.state.monthView !== prevState.monthView || this.state.monthView && this.state.monthViewDate !== prevState.monthViewDate ? true : false;
            // console.log(this.state.monthView, prevState.monthView, this.state.monthView && this.state.monthViewDate, prevState.monthViewDate)
            this.fetchFreeEventsCalendar(null, false)
        }
    }

    stopAnimation = () => {
        this.player.current.play();
    };

    showPlayer = () => {
        this.setState({
            showPlayer: true,
        });
    };

    hidePlayer = () => {
        this.setState({
            showPlayer: false,
        });
    };

    getWorkingTime = () => {
        // console.log('radni sati')
        {
            this.state.doctor ?
                this.state.doctors.filter(item => item._id.toString() == this.state.doctor).map(item => {
                    if (item.workingTimeConfig) {
                        if (item.workingTimeConfig[this.state.groupId] && item.workingTimeConfig[this.state.groupId][this.state.clinics[0]._id]) {
                            this.setState({
                                min: new Date(item.workingTimeConfig[this.state.groupId][this.state.clinics[0]._id].min),
                                max: new Date(item.workingTimeConfig[this.state.groupId][this.state.clinics[0]._id].max)
                            })
                        }
                    }
                })
                : this.setState({
                    min: this.state.min,
                    max: this.state.max
                })
        }

    }




    selectedEvent = (event) => {
        // console.log(event)
        // var startDate = moment.unix(event.start).format('YYYY-MM-DD');
        // let start = moment.unix(event.start).format('HH:mm')
        // var end = moment.unix(event.end).format("HH:mm");
        var startDate = this.props.getStringDateTs(event.start, 'YYYY-MM-DD');
        let start = this.props.getStringDateTs(event.start, 'HH:mm')
        var end = this.props.getStringDateTs(event.end, 'HH:mm');

        let startTs = event.start;
        let endTs = event.end;

        let obj = {
            title: event.title,
            id: event.id,
            date: startDate,
            startTime: start,
            endTime: end,
            type: event.type,
            duration: (endTs - startTs) / 60,
            allActiveForms: event.allActiveForms,
            eventForms: event.eventForms,
            oldTitle: event.oldTitle,
            startTimeHours: event?.startTimeHours,
            endTimeHours: event?.endTimeHours
        }
        // console.log(obj)
        if (event.type == 'free') {
            let startTimeHours = obj?.startTimeHours ?? obj.startTime;
            let endTimeHours = obj?.endTimeHours ?? obj.endTime;
            fetch(API_ENDPOINT + '/referrals/check-selected-time', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json'
                },
                body: JSON.stringify({
                    doctor: this.state.doctor,
                    startEventReservedDateOriginal: obj.date + ' ' + obj.startTime,
                    endEventReservedDateOriginal: obj.date + ' ' + obj.endTime,
                    // startEventReservedDate: this.props.getStringDateInverseTs(Math.floor(new Date(obj.date + ' ' + startTimeHours).getTime() / 1000), `${this.props.dateFormat} HH:mm`),
                    // endEventReservedDate: this.props.getStringDateInverseTs(Math.floor(new Date(obj.date + ' ' + endTimeHours).getTime() / 1000), `${this.props.dateFormat} HH:mm`),
                    startEventReservedDate: obj.date + ' ' + startTimeHours,
                    endEventReservedDate: obj.date + ' ' + endTimeHours,
                })
            }).then(res => res.json()).then((result) => {
                if (result.error) {
                    this.setState({
                        error: result.error.translate(this.props.lang)
                    })
                } else {
                    this.setState({
                        // scheduleReferral: obj,
                        selectEvent: obj
                    }, () => {
                        // if (typeof window !== 'undefined') {
                        //     localStorage.setItem('tempEvent', JSON.stringify(this.state.selectEvent))
                        // }
                    })
                }

            })

        }

    }




    /**
    * Change referral status
    * @author   Stefan Pantic
    * @Objectparam    {String} referral             referral id
    */
    chageStatus = (info) => {

        if (this.state.referralId) {
            for (let i = 0; i < this.state.referrals.length; i++) {
                if (this.state.referralId == this.state.referrals[i]._id) {
                    this.setState({
                        referral: this.state.referrals[i]
                    })
                }
            }
        }


        this.setState({
            loading: true
        }, () => {
            fetch(API_ENDPOINT + '/referrals/patient-failure/status/' + this.state.referral._id, {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
            }).then(res => res.json()).then((result) => {
                if (info) {
                    this.props.closeCalendarModal(false)
                }
            })
        })

    }





    reserveEvent = () => {


        if (this.props.changeSelectedTime) {
            let startTimeHours = this.state.selectEvent?.startTimeHours ?? this.state.selectEvent.startTime;
            let endTimeHours = this.state.selectEvent?.endTimeHours ?? this.state.selectEvent.endTime;
            this.setState(
                {
                    showPlayer: true,
                }, () => {
                    this.player.current.play();
                    fetch(API_ENDPOINT + '/referrals/patient-schedule/' + this.props.changeSelectedTime, {
                        method: 'POST',
                        headers: {
                            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                            'content-type': 'application/json'
                        },
                        body: JSON.stringify({
                            startEventReservedDateOriginal: this.state.selectEvent?.date + ' ' + this.state.selectEvent?.startTime,
                            endEventReservedDateOriginal: this.state.selectEvent?.date + ' ' + this.state.selectEvent?.endTime,
                            // startPatientReservedDate: this.props.getStringDateInverseTs(Math.floor(new Date(this.state.selectEvent?.date + ' ' + this.state.selectEvent?.startTime).getTime() / 1000), `${this.props.dateFormat} HH:mm`),
                            // endPatientReservedDate: this.props.getStringDateInverseTs(Math.floor(new Date(this.state.selectEvent?.date + ' ' + this.state.selectEvent?.endTime).getTime() / 1000), `${this.props.dateFormat} HH:mm`),
                            startPatientReservedDate: this.state.selectEvent.date + ' ' + startTimeHours,
                            endPatientReservedDate: this.state.selectEvent.date + ' ' + endTimeHours,
                            doctor: this.state.doctor,
                            patient: this.props.uData._id,
                            referral: this.props.changeSelectedTime,
                            scheduledBy: 'patient',
                            eventStatus: 'reserved',
                            reserved: true,
                            allActiveForms: this.state.selectEvent.allActiveForms,
                            eventForms: this.state.selectEvent.eventForms,
                            oldTitle: this.state.selectEvent.oldTitle
                        })
                    }).then(res => res.json()).then((result) => {
                        if (result.error) {
                            this.setState({ error: result.error.translate(this.props.lang) })
                        } else {
                            let selectedTime = {
                                date: this.state.selectEvent?.date,
                                startTime: this.state.selectEvent?.startTime,
                                endTime: this.state.selectEvent?.endTime
                            }
                            if (this.props.setSelectedTime) {
                                this.props.setSelectedTime(selectedTime)
                            }
                            this.setState({
                                scheduleReferral: null,
                                referralId: null,
                                cardModal: null,
                                calendarModal: null,
                                selectEvent: null,
                                _loader: false,
                                confirmButtonClicked: false


                            }, () => this.props.createdReferral(this.props.changeSelectedTime))
                            this.setState({
                                showPlayer: false,
                                showPlayer2: true,
                            },
                                () => {
                                    setTimeout(() => {
                                        this.setState({ showPlayer2: false });
                                    }, 1000);
                                })

                        }

                    })
                })
        } else {
            let startTimeHours = this.state.selectEvent?.startTimeHours ?? this.state.selectEvent.startTime;
            let endTimeHours = this.state.selectEvent?.endTimeHours ?? this.state.selectEvent.endTime;
            fetch(API_ENDPOINT + '/referrals/check-selected-time', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json'
                },
                body: JSON.stringify({
                    doctor: this.state.doctor,
                    startEventReservedDateOriginal: this.state.selectEvent.date + ' ' + this.state.selectEvent.startTime,
                    endEventReservedDateOriginal: this.state.selectEvent.date + ' ' + this.state.selectEvent.endTime,
                    // startEventReservedDate: this.props.getStringDateInverseTs(Math.floor(new Date(this.state.selectEvent.date + ' ' + this.state.selectEvent.startTime).getTime() / 1000), `${this.props.dateFormat} HH:mm`),
                    // endEventReservedDate: this.props.getStringDateInverseTs(Math.floor(new Date(this.state.selectEvent.date + ' ' + this.state.selectEvent.endTime).getTime() / 1000), `${this.props.dateFormat} HH:mm`)
                    startEventReservedDate: this.state.selectEvent.date + ' ' + startTimeHours,
                    endEventReservedDate: this.state.selectEvent.date + ' ' + endTimeHours,
                })
            }).then(res => res.json()).then((result) => {
                if (result.error) {
                    this.setState({
                        error: result.error.translate(this.props.lang)
                    })
                } else {
                    if (this.props.uData) {


                        if (this.props.uData._id == this.state.doctor) {
                            this.setState({ errorReserve: "You can't book an appointment with yourself.".translate(this.props.lang), doctor: null }, () => this.get())
                            return
                        }

                        this.setState(
                            {
                                showPlayer: true,
                                _loader: true
                            }, () => {
                                this.player.current.play();
                                let patientData = this.state.patientData;

                                patientData = {
                                    name: this.props.uData.userData.name,
                                    socialSecurityNumber: this.props.uData.socialSecurityNumber,
                                    phone: this.props.uData.phone,
                                    email: this.props.uData.email,
                                    city: this.props.uData.city,
                                    zip: this.props.uData.zip,
                                    street: this.props.uData.street,
                                }

                                this.setState({ patientData }, () => {
                                    let patient = this.state.patientData;
                                    if (patient.phone) {
                                        patient.phone = patient.phone.replace(/\+/g, '00').replace(/[^\d]/g, '');
                                        if (patient.phone.indexOf('00') == -1) {
                                            patient.phone = '00' + patient.phone;
                                        }
                                    }


                                    fetch(API_ENDPOINT + '/referrals/new', {
                                        method: 'POST',
                                        headers: {
                                            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                                            'content-type': 'application/json'
                                        },
                                        body: JSON.stringify({
                                            answers: {},
                                            questionarySections: this.state.sections,
                                            personalReferral: true,
                                            patient: patient,
                                            dropIn: false,
                                            questionaryName: this.props.personalQuestionary.name,
                                            onlineVisit: this.props.personalQuestionary.onlineVisit,
                                            questionaryDuration: this.props.personalQuestionary.duration,
                                            intelligentReferral: this.props.personalQuestionary.intelligentReferral,
                                            questionaryPrice: this.props.personalQuestionary.price,
                                            questionaryId: this.props.personalQuestionary?._id,
                                            doctorsList: this.props.personalQuestionary.doctorsListQuestionary,
                                            wayOfScheduling: this.props.personalQuestionary.wayOfScheduling,
                                            recipientClinic: this.props.personalQuestionary.clinic._id,
                                            referralType: this.state.referralType,
                                            referralSections: this.state.referralSections,
                                            allActiveForms: this.state.selectEvent.allActiveForms,
                                            eventForms: this.state.selectEvent.eventForms,
                                            oldTitle: this.state.selectEvent.oldTitle,
                                            version: this.state.version,
                                            referralContent: this.state.referralContent ? this.state.referralContent : null,
                                            paymentBeforeSubmission: this.props.personalQuestionary.paymentBeforeSubmission,
                                            paymentAfterSubmission: this.props.personalQuestionary.paymentAfterSubmission,

                                        })
                                    }).then(parseJSON).then(({ result, status }) => {
                                        if (status >= 200 && status < 300) {
                                            this.props.handleDeleteCheck(result.referralId);
                                            this.props.verifyUser(null, null, true);
                                            this.setState({ confirmButtonClicked: false })


                                            if (result) {
                                                this.tempReferralCheck(result.tempReferral)

                                                // if (typeof window !== 'undefined') {
                                                //     localStorage.removeItem('tempEvent')
                                                //     localStorage.removeItem('tempSelectedReferral')
                                                //     localStorage.removeItem('tempReferral')
                                                //     localStorage.removeItem('tempClinicGroup')
                                                //     localStorage.removeItem('tempDoctor')
                                                //     localStorage.removeItem('webBookingBankId')
                                                //     localStorage.removeItem('bankIdStarted')
                                                // }
                                            }



                                        }
                                    })
                                });


                            })

                    } else {

                        this.setState({
                            bankIdModal: true
                        }, () => {

                            let alias = '';
                            if (typeof window != 'undefined') {
                                alias = localStorage.groupAlias;
                            }
                            fetch(API_ENDPOINT + '/landing/page', {
                                method: 'POST',
                                headers: {
                                    'content-type': 'application/json'
                                },
                                body: JSON.stringify({ alias })
                            }).then(res => res.json()).then((result) => {
                                if (result.error) {
                                    this.props[0].history.push(`/`);

                                } else {
                                    this.setState({
                                        groupData: result,
                                        confirmButtonClicked: false
                                    }, () => {

                                        this.fetchBankId()
                                    })

                                }
                            });


                        })
                    }
                }

            })
        }





    }

    fetchBankId = () => {
        fetch(API_ENDPOINT + '/users/bankid/v2/start', {
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify({ group: this.state.groupData._id })
        }).then(res => res.json()).then((result) => {
            if (result.redirect_url) {
                if (this.state.bankIdModal)
                    this.setState({

                        bankIdModal: result.redirect_url

                    }, () => {
                        setTimeout(() => {
                            if (!this.props.uData) {
                                this.fetchBankId()
                            }
                        }, 1000 * 60 * 4);
                    })
            }
        })

    }

    tempReferralCheck = (data) => {
        if (!this.state.selectEvent) {
            return;
        }
        fetch(API_ENDPOINT + '/referrals/temp-referral-check/' + data, {
            method: 'GET',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
        }).then(res => res.json()).then((result) => {
            if (result && result.referralId) {

                let startTimeHours = this.state.selectEvent?.startTimeHours ?? this.state.selectEvent.startTime;
                let endTimeHours = this.state.selectEvent?.endTimeHours ?? this.state.selectEvent.endTime;

                this.setState({
                    _sendingReferral: null,
                    signinModal: false,
                    newReferralId: result.referralId,
                }, () => {
                    this.setState({
                        loading: true
                    }, () => {
                        fetch(API_ENDPOINT + '/referrals/patient-schedule/' + this.state.newReferralId, {
                            method: 'POST',
                            headers: {
                                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                                'content-type': 'application/json'
                            },
                            body: JSON.stringify({
                                startEventReservedDateOriginal: this.state.selectEvent?.date + ' ' + this.state.selectEvent?.startTime,
                                endEventReservedDateOriginal: this.state.selectEvent?.date + ' ' + this.state.selectEvent?.endTime,
                                // startPatientReservedDate: this.props.getStringDateInverseTs(Math.floor(new Date(this.state.selectEvent?.date + ' ' + this.state.selectEvent?.startTime).getTime() / 1000), `${this.props.dateFormat} HH:mm`),
                                // endPatientReservedDate: this.props.getStringDateInverseTs(Math.floor(new Date(this.state.selectEvent?.date + ' ' + this.state.selectEvent?.endTime).getTime() / 1000), `${this.props.dateFormat} HH:mm`),
                                startPatientReservedDate: this.state.selectEvent.date + ' ' + startTimeHours,
                                endPatientReservedDate: this.state.selectEvent.date + ' ' + endTimeHours,
                                doctor: this.state.doctor,
                                patient: this.props.uData._id,
                                referral: this.state.newReferralId,
                                scheduledBy: 'patient',
                                eventStatus: 'reserved',
                                reserved: true,
                                allActiveForms: this.state.selectEvent.allActiveForms,
                                eventForms: this.state.selectEvent.eventForms,
                                oldTitle: this.state.selectEvent.oldTitle
                            })
                        }).then(res => res.json()).then((result) => {
                            if (result.error) {
                                this.setState({ error: result.error.translate(this.props.lang) })
                            } else {
                                let selectedTime = {
                                    date: this.state.selectEvent?.date,
                                    startTime: this.state.selectEvent?.startTime,
                                    endTime: this.state.selectEvent?.endTime
                                }
                                if (this.props.setSelectedTime) {
                                    this.props.setSelectedTime(selectedTime)
                                }
                                this.setState({
                                    scheduleReferral: null,
                                    referralId: null,
                                    successModal: true,
                                    cardModal: null,
                                    calendarModal: null,
                                    selectEvent: null,
                                    _loader: false

                                }, () => this.props.createdReferral(this.state.newReferralId))

                                this.setState({
                                    showPlayer: false,
                                    showPlayer2: true,
                                },
                                    () => {
                                        setTimeout(() => {
                                            this.setState({ showPlayer2: false });
                                        }, 500);
                                    })
                            }

                        })
                    })
                })

                this.props.verifyUser(null, null, true);
            } else {
                setTimeout(() => {
                    this.tempReferralCheck(data)

                }, 500)
            }
        })

    }

    /**
    * Check if selected doctor have any free events
    * @author   Stefan Pantic
    */
    doctorWithoutFreeEvents = () => {

        let noFreeEvents = true
        // for (let i = 0; i < this.state.items.length; i++) {
        //     if (this.state.items[i].type == 'free') {
        //         noFreeEvents = false
        //     }
        // }


        let items = []
        let ref = this.state.referrals.filter((item) => item._id == this.state.referralId);

        let duration = 0;
        let clinicId;

        if (this.props.personalQuestionary) {
            duration = Number(this.props.personalQuestionary.duration)
        }

        if (this.props.personalQuestionary && this.props.personalQuestionary.clinic) {
            clinicId = this.props.personalQuestionary.clinic._id
        }

        for (let i = 0; i < this.state.items.length; i++) {
            let item = this.state.items[i];


            if (item.type == 'free' && duration && item.clinic.toString() == clinicId.toString()) {
                let segments = Math.floor(((Number(item.endTime) - Number(item.startTime)) / 60) / duration)

                for (let j = 0; j < segments; j++) {
                    let time = item.startTime + j * duration * 60;
                    let endTime = item.startTime + j * duration * 60 + duration * 60;
                    if (time > Math.floor(new Date().getTime() / 1000)) {
                        items.push({
                            id: item._id,
                            title: item.title.translate(this.props.lang),
                            tooltip: item.title.translate(this.props.lang),
                            start: new Date(time * 1000),
                            end: new Date(endTime * 1000),
                            type: item.type,
                            tooltipDisabled: false
                        })
                    }


                }
            }
        }
        let firstDayTs;
        for (let i = 0; i < items.length; i++) {
            if (!firstDayTs) {
                firstDayTs = Math.floor(new Date(items[i].start).getTime() / 1000);
            } else if (firstDayTs > Math.floor(new Date(items[i].start).getTime() / 1000)) {
                firstDayTs = Math.floor(new Date(items[i].start).getTime() / 1000);
            }

        }
        let monday = this.state.monday;
        // if (firstDayTs && moment.unix(Math.floor(new Date(monday).getTime() / 1000)).format('MM-DD-YYYY') != moment.unix(firstDayTs).format('MM-DD-YYYY')) {
        if (firstDayTs && this.props.getStringDateTs(Math.floor(new Date(monday).getTime() / 1000), 'MM-DD-YYYY') != this.props.getStringDateTs(firstDayTs, 'MM-DD-YYYY')) {
            let firstDay = new Date(firstDayTs * 1000)
            this.setState({ monday: this.getMonday(new Date(firstDay)) })
        }



        if (items.length >= 1) {
            noFreeEvents = false
            let min = items[0];
            for (let i = 1; i < items.length; i++) {
                if (Math.floor(new Date(items[i].start).getTime() / 1000) < Math.floor(new Date(min.start).getTime() / 1000)) {
                    min = items[i];
                }
            }
            this.setState({ day: new Date(Math.floor(new Date(min.start * 1000).getTime() / 1000)), noAvailableDoctors: false })
        } else {
            this.setState({ day: new Date() })
        }

        if (noFreeEvents/* && this.state.doctorChanged*/) {
            this.setState({
                error: "No available time slots.",
                // doctorChanged: false
            })
        }
    }
    goToLandingPage = () => {
        let url = '/';
        if (this.props[0].match.params.mainAlias) {
            url += this.props[0].match.params.mainAlias + '/'
        }
        if (this.props[0].match.params.alias) {
            url += this.props[0].match.params.alias
        }
        this.props[0].history.push(url)
    }





    fetchFreeEventsCalendar = (callback, firstWeek = false) => {
        let obj = {
            duration: this.props.personalQuestionary?.duration ? Number(this.props.personalQuestionary.duration) : 0,
            clinic: this.props.personalQuestionary?.clinic?._id ? this.props.personalQuestionary.clinic._id : null,
            doctor: this.state.doctor,
            date: this.state.monday,
            dateTs: this.state.firstFreeEvent ? this.state.firstFreeEvent : null,
            dateOffset: this.state.monday ? new Date(this.state.monday).getTimezoneOffset() : 0,
            month: this.state.monthView ? true : false,
            monthViewDate: this.state.monthViewDate && this.state.monthView ? this.state.monthViewDate : null,
            weekViewDate: this.state.weekViewDate ? this.state.weekViewDate : null,
            firstWeek: firstWeek,
            personalQuestionary: this.props.personalQuestionary?._id
        }


        if (obj.doctor && obj.clinic && obj.duration) {
            fetch(API_ENDPOINT + '/calendar/fetch/free-events', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json'
                },
                body: JSON.stringify(obj)
            }).then(res => res.json()).then((result) => {
                if (result && result.days) {

                    this.setState({
                        ...result,
                        firstFreeEvent: result.firstFreeEvent ? result.firstFreeEvent : null,
                        loadingDoctor: false
                    }, () => {
                        if (callback) {
                            callback()
                        }
                    })


                }
            })
        } else {
            if (this.state.firstFreeEvent) {
                this.setState({ firstFreeEvent: null })
            }
        }

    }


    render() {
        const days = this.state.days ? this.state.days : [];


        let selectedDoctor = null;
        for (let i = 0; i < this.state.doctors.length; i++) {
            if (this.state.doctors[i] && this.state.doctors[i]._id == this.state.doctor) {
                selectedDoctor = this.state.doctors[i];
                break;
            }
        }
        const currency = this.props.currency ? this.props.currency : typeof window !== 'undefined' && localStorage?.getItem("currency") ? localStorage.getItem("currency") : '';
        return (

            <div>
                <Container fluid>
                    <Row>
                        <Col lg='12'>
                            <div className='panel mob-div-padding-none'>

                                <div className="webbooking-calendar-wrap">
                                    <div className="mob-flex-col" style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                                        {
                                            this.state.doctors && this.state.doctors.length > 1 ?
                                                <div className="mob-wid-100 patient-calendar-select-wrap"> {'Doctor:'.translate(this.props.lang)}
                                                    <Input type='select' className="patient-calendar-select" style={{ width: '100%' }}
                                                        onChange={e => {

                                                            this.setState({ doctor: e.target.value == -1 ? null : e.target.value, loadingDoctor: true }, () => {
                                                                this.get(); this.getWorkingTime();

                                                                // if (typeof window !== 'undefined') {
                                                                //     localStorage.setItem('tempDoctor', JSON.stringify(this.state.doctor))
                                                                // }

                                                            })
                                                        }}
                                                        value={this.state.doctor}
                                                    >
                                                        {this.state.doctors.map(item => {
                                                            return <option value={item._id}>
                                                                {item.name}
                                                            </option>
                                                        })}

                                                    </Input>
                                                </div>
                                                :
                                                <div className="select-one-value patient-calendar-select-wrap">
                                                    {'Doctor:'.translate(this.props.lang)}
                                                    <Input type='select' className="patient-calendar-select" style={{ width: '100%' }}
                                                        value={this.state.doctor}
                                                        disabled
                                                    >

                                                        {this.state.doctors.map(item => {
                                                            return <option value={item._id}>
                                                                {item.name}
                                                            </option>
                                                        })}

                                                    </Input>
                                                </div>
                                        }
                                        {
                                            this.props.selectedReferral ?
                                                <div className='web-booking-calendar-info'>
                                                    <h6>{'Visit reason'.translate(this.props.lang)}:</h6>
                                                    <div>
                                                        <span> {this.props.lang == 'en' ? this.props.selectedReferral.name.replace(' (onlinebesök)', ' (online visit)') : this.props.selectedReferral.name.replace(' (online visit)', ' (onlinebesök)')}</span>
                                                    </div>
                                                    <div>
                                                        <h6>{'Duration'.translate(this.props.lang)}: <span>{this.props.selectedReferral.duration} min</span></h6>
                                                        <h6>{'Price'.translate(this.props.lang)}: <span>{this.props.selectedReferral.price} {currency}</span></h6>
                                                    </div>
                                                </div>
                                                :
                                                null
                                        }
                                    </div>
                                    <div className="web-booking-calendar-header-wrap">
                                        <div className='web-booking-calendar-back-wrap'>
                                            <Button color='primary' onClick={() => this.props.backFromCalendar()}>
                                                <Isvg src={backIcon} />
                                            </Button>
                                        </div>
                                        <div>
                                            {"Timezone: Stockholm".translate(this.props.lang)}
                                        </div>
                                        {
                                            !this.state.monthView ?
                                                <Button color='primary' className='button-green' onClick={() => {
                                                    this.setState({ monthView: true, monthViewDate: new Date() })
                                                }}>
                                                    {'Month view'.translate(this.props.lang)}
                                                </Button>
                                                :
                                                <Button color='primary' className='button-green' onClick={() => { this.setState({ monthView: false, monday: this.getMonday(new Date()) }) }}>
                                                    {'Week view'.translate(this.props.lang)}
                                                </Button>
                                        }
                                    </div>
                                    {
                                        this.state.loadingDoctor ?
                                            <div className="loader-wrap" >
                                                <Player
                                                    autoplay={true}
                                                    loop={true}
                                                    src="/lf30_editor_l5cxzdyf.json"
                                                    style={{ height: typeof window != 'undefined' && window.innerWidth < 768 ? "128px" : "256px", width: typeof window != 'undefined' && window.innerWidth < 768 ? "128px" : "256px", position: 'absolute', top: "50%", left: '50%', transform: 'translate(-50%, -50%)' }}
                                                ></Player>
                                            </div>
                                            :
                                            null
                                    }
                                    {
                                        !this.state.monthView ?
                                            <div
                                                ref={node => {
                                                    if (!this.state.calendarRef) {
                                                        this.setState({
                                                            calendarRef: node
                                                        })
                                                    }
                                                }}
                                                className='web-booking-calendar-custom-wrap'
                                            >
                                                <div className='navigation-wrap'>
                                                    <div onClick={() => {
                                                        let prevWeekMonday = new Date(this.state.monday);
                                                        prevWeekMonday.setDate(this.state.monday.getDate() - 7);
                                                        this.setState({ monday: prevWeekMonday })
                                                    }}
                                                        className='next-prev-btn'
                                                    >
                                                        <p >
                                                            {'Previous'.translate(this.props.lang)}
                                                        </p>
                                                    </div>
                                                    <p className='date-range'>
                                                        {this.props.getStringDateTs(days && days[0]?.timestamp, 'DD', true)} {(this.props.getStringDateTs(days && days[0]?.timestamp, 'MMM', true)).translate(this.props.lang)} - {this.props.getStringDateTs(days && days[6]?.timestamp, 'DD', true)} {(this.props.getStringDateTs(days && days[6]?.timestamp, 'MMM', true)).translate(this.props.lang)}

                                                    </p>
                                                    <div onClick={() => {
                                                        let prevWeekMonday = new Date(this.state.monday);
                                                        prevWeekMonday.setDate(this.state.monday.getDate() + 7);
                                                        this.setState({ monday: prevWeekMonday })
                                                    }} className='next-prev-btn'>
                                                        <p >
                                                            {'Next'.translate(this.props.lang)}
                                                        </p>
                                                    </div>

                                                </div>
                                                <div className='week-calendar-wrap'>

                                                    <div className='days-wrap'>
                                                        {
                                                            days?.map((date, didx) => {
                                                                return (
                                                                    <div key={didx} style={{ width: ((this.state.calendarRef && this.state.calendarRef.getBoundingClientRect() ? this.state.calendarRef.getBoundingClientRect().width : 360) - 30) / 7, alignItems: 'center' }}>
                                                                        <p style={{ textAlign: 'center', color: date?.items?.length ? '#000' : '#cacaca' }}>
                                                                            {
                                                                                typeof window != 'undefined' && window.innerWidth > 768 ?
                                                                                    this.state.weekDays && this.state.weekDays[didx] && this.state.weekDays[didx].translate(this.props.lang)
                                                                                    :
                                                                                    null
                                                                            }
                                                                            {
                                                                                typeof window != 'undefined' && window.innerWidth <= 768 ?
                                                                                    this.state.weekDaysMob && this.state.weekDaysMob[didx] && this.state.weekDaysMob[didx].translate(this.props.lang)
                                                                                    :
                                                                                    null
                                                                            }
                                                                        </p>
                                                                        <p style={{ textAlign: 'center', color: date?.items?.length ? '#000' : '#cacaca' }}>
                                                                            {this.props.getStringDateTs(date?.timestamp, 'DD', true)}
                                                                        </p>
                                                                        {
                                                                            // new Date().getDate() == new Date(date.date).getDate() && new Date().getMonth() == new Date(date.date).getMonth() ?
                                                                            date.today ?
                                                                                <div className='date-now-marker-wrap'>
                                                                                    <div className='date-now-marker'></div>
                                                                                </div>
                                                                                :

                                                                                null
                                                                        }
                                                                    </div>
                                                                )

                                                            })
                                                        }

                                                    </div>
                                                    <div>
                                                        <div className='items-wrap'>

                                                            {
                                                                days.map((date, didx) => {
                                                                    return (
                                                                        <div key={didx} className='one-day-wrap' style={{ width: ((this.state.calendarRef && this.state.calendarRef.getBoundingClientRect() ? this.state.calendarRef.getBoundingClientRect().width : 360) - 30) / 7 }}>
                                                                            {
                                                                                date.items.map((item, idx) => {
                                                                                    return (
                                                                                        <div onClick={() => {
                                                                                            this.selectedEvent(item)
                                                                                        }}>
                                                                                            <div style={{ marginBottom: 5 }}>
                                                                                                <div className='item-wrap'>
                                                                                                    <p>
                                                                                                        {this.props.getStringDateTs(item.start, 'HH:mm')}
                                                                                                    </p>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </div>

                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                            :
                                            <div className='web-booking-calendar-custom-wrap'>
                                                <div className='navigation-wrap'>
                                                    <div onClick={() => {
                                                        let monthViewDate = new Date(this.state.monthViewDate);
                                                        monthViewDate.setDate(1);
                                                        let prevMonth = monthViewDate.getMonth();
                                                        let prevYear = monthViewDate.getFullYear()
                                                        prevMonth--;
                                                        if (prevMonth == -1) {
                                                            prevMonth = 11;
                                                            prevYear--;
                                                        }
                                                        monthViewDate.setMonth(prevMonth)
                                                        monthViewDate.setFullYear(prevYear)

                                                        this.setState({ monthViewDate })
                                                    }}
                                                        className='next-prev-btn'
                                                    >
                                                        <p >
                                                            {'Previous'.translate(this.props.lang)}
                                                        </p>
                                                    </div>
                                                    <p className='date-range'>
                                                        {this.state.monthViewDate ? ((this.props.getStringDateTs(Math.floor(new Date(this.state.monthViewDate).getTime() / 1000), 'MMMM').translate(this.props.lang)) + ' ' + this.props.getStringDateTs(Math.floor(new Date(this.state.monthViewDate).getTime() / 1000), 'YYYY')) : ''}

                                                    </p>
                                                    <div onClick={() => {
                                                        let monthViewDate = new Date(this.state.monthViewDate);
                                                        monthViewDate.setDate(1);
                                                        let nextMonth = monthViewDate.getMonth();
                                                        let nextYear = monthViewDate.getFullYear()
                                                        nextMonth++;
                                                        if (nextMonth == 12) {
                                                            nextMonth = 0;
                                                            nextYear++;
                                                        }
                                                        monthViewDate.setMonth(nextMonth)
                                                        monthViewDate.setFullYear(nextYear)

                                                        this.setState({ monthViewDate })
                                                    }} className='next-prev-btn'>
                                                        <p >
                                                            {'Next'.translate(this.props.lang)}
                                                        </p>
                                                    </div>

                                                </div>
                                                <div className='web-booking-calendar-month-wrap'>
                                                    {
                                                        typeof window != 'undefined' && window.innerWidth > 768 ?
                                                            this.state.weekDays && this.state.weekDays.map((item, idx) => {
                                                                return (
                                                                    <div className='web-booking-calendar-month-week-days'>
                                                                        {item.translate(this.props.lang)}
                                                                    </div>
                                                                )
                                                            })
                                                            :
                                                            null
                                                    }
                                                    {
                                                        typeof window != 'undefined' && window.innerWidth <= 768 ?
                                                            this.state.weekDaysMob && this.state.weekDaysMob.map((item, idx) => {
                                                                return (
                                                                    <div className='web-booking-calendar-month-week-days'>
                                                                        {item.translate(this.props.lang)}
                                                                    </div>
                                                                )
                                                            })
                                                            :
                                                            null
                                                    }

                                                    {
                                                        this.state.calendarMonth && this.state.calendarMonth.map((item, idx) => {
                                                            return (
                                                                <div className={item.notThisMonth && !item.haveFreeTime ? 'web-booking-calendar-month-item free-time-box-not-this-month' : item.notThisMonth && item.haveFreeTime ? 'web-booking-calendar-month-item free-time-box-not-this-month-have-free' : item.haveFreeTime ? 'web-booking-calendar-month-item free-time-box' : 'web-booking-calendar-month-item'}
                                                                    onClick={() => {
                                                                        if (item.haveFreeTime) {
                                                                            this.setState({ monthView: false, monday: this.getMonday(new Date(item.date)) })
                                                                        }
                                                                    }}
                                                                >
                                                                    <h6 className={item.today ? 'today-date' : ''}>{item.day}</h6>

                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>




                                            </div>
                                    }




                                </div>

                            </div>

                        </Col>
                    </Row>
                </Container>
                {
                    this.state.selectEvent ?
                        <Modal isOpen={this.state.selectEvent} toggle={() => this.setState({ selectEvent: !this.state.selectEvent }, () => {
                            // if (typeof window !== 'undefined') {
                            //     localStorage.removeItem('tempEvent')
                            // }
                        })} centered >
                            <ModalHeader style={{ margin: 'auto' }}>
                                {'Healthcare meeting'.translate(this.props.lang)}
                            </ModalHeader>
                            <ModalBody>
                                <div className='schedule-web-booking-info' >

                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                        <div className="date-time">
                                            <Isvg src={calendar} />
                                            <p>
                                                {/* {this.state.selectEvent.date ? moment.unix(new Date(this.state.selectEvent.date).getTime() / 1000).format(this.props.dateFormat) : null}{this.props.lang == 'se' ? ' kl. ' : ' '}{this.state.selectEvent.startTime} */}
                                                {this.state.selectEvent.date ? this.props.getStringDateTs(Math.floor(new Date(this.state.selectEvent.date).getTime() / 1000), this.props.dateFormat) : null}{this.props.lang == 'se' ? ' kl. ' : ' '}{this.state.selectEvent.startTime}
                                            </p>
                                        </div>
                                        <div>
                                            {"Timezone: Stockholm".translate(this.props.lang)}
                                        </div>
                                        <div className='duration-wrap'>
                                            <Isvg src={clockIcon} />
                                            <p>{this.state.selectEvent.duration} min</p>
                                        </div>
                                    </div>
                                    <div className="doctor-name">
                                        {
                                            this.state.doctor && this.state.doctors && this.state.doctors.filter(doc => doc._id == this.state.doctor).length ?
                                                // this.state.doctors.filter(doc => doc._id == this.state.doctor)[0].name
                                                this.state.doctors.filter(doc => doc._id == this.state.doctor)[0].userData ?
                                                    <>
                                                        {this.state.doctors.filter(doc => doc._id == this.state.doctor)[0].userData.name}
                                                        <br />
                                                        {this.state.doctors.filter(doc => doc._id == this.state.doctor)[0].userData.title}
                                                    </>
                                                    :
                                                    null
                                                :
                                                null
                                        }
                                    </div>
                                    <div className="questionary-name">
                                        <Isvg src={checkIcon} />
                                        {this.props.personalQuestionary && this.props.personalQuestionary.name}
                                    </div>
                                    <div className="price-wrap">
                                        <span>{'Total'.translate(this.props.lang)}:</span>
                                        <span>{this.props.personalQuestionary && this.props.personalQuestionary.price} {currency}</span>
                                    </div>
                                    {
                                        this.props.displayPaymentInfoText ?
                                            <div className="info-message">
                                                {'The cost can vary depending on your age or if you have a free card. Confirm booking, answer questions and select payment method.'.translate(this.props.lang)}
                                            </div>
                                            :
                                            null
                                    }




                                </div>

                            </ModalBody>
                            <ModalFooter className="buttons-right-blue">
                                {/* <Button color="primary" style={{ margin: 10 }} disabled={this.state._loader} onClick={() => this.reserveEvent()}> */}
                                <Button
                                    disabled={this.state.confirmButtonClicked || this.state._loader}
                                    color='primary' onClick={() => {
                                        if (!this.state.confirmButtonClicked) {
                                            this.setState({ confirmButtonClicked: true }, () => {
                                                this.reserveEvent()
                                            })
                                        }

                                    }}>
                                    {
                                        this.state.showPlayer ? (
                                            <Player
                                                onEvent={(event) => {
                                                    if (event === "load") this.stopAnimation();
                                                }}
                                                onStateChange={this.toggleVisibility}
                                                ref={this.player} // set the ref to your class instance
                                                autoplay={true}
                                                loop={true}
                                                controls={true}
                                                src="https://assets8.lottiefiles.com/packages/lf20_ZSRKCF.json"
                                                style={{ height: "30px", width: "30px" }}
                                            ></Player>

                                        ) : this.state.showPlayer2 ? (
                                            <div style={{ display: 'flex', alignItems: 'center' }}><Player
                                                onStateChange={this.toggleVisibility}
                                                ref={this.player2} // set the ref to your class instance
                                                autoplay={true}
                                                loop={true}
                                                controls={true}
                                                src="https://assets10.lottiefiles.com/packages/lf20_CxyxLk.json"
                                                style={{ height: "30px", width: "30px" }}
                                            ></Player>
                                                {'Confirmed booking'.translate(this.props.lang)}
                                            </div>
                                        ) : (
                                            'Confirm booking'.translate(this.props.lang)
                                        )
                                    }
                                </Button>
                                {/* {'Confirm booking'.translate(this.props.lang)}</Button> */}
                                <Button color="primary" style={{ margin: 10 }} onClick={() => this.setState({ selectEvent: false }, () => {
                                    // if (typeof window !== 'undefined') {
                                    //     localStorage.removeItem('tempEvent')
                                    // }
                                })}>{'Cancel'.translate(this.props.lang)}</Button>
                            </ModalFooter>
                        </Modal>
                        :
                        null
                }




                {
                    this.state.bankIdModal ?
                        <BankIdModal
                            {...this.props}
                            checkLastLogin={true}
                            isOpen={this.state.bankIdModal}
                            // stateForOpen='bankIdModal'
                            toggle={() => {
                                if (this.props.uData) {
                                    localStorage.setItem('groupAlias', this.props[0].match.params.alias);
                                    if (this.props[0].location?.state?.formId)
                                        localStorage.setItem('hidePatientModal', true);
                                    if (!this.state.confirmButtonClicked) {
                                        this.reserveEvent()
                                    }
                                } else {
                                    if (this.state.confirmButtonClicked)
                                        this.setState({ confirmButtonClicked: false })
                                }
                                this.setState({ bankIdModal: null })

                            }}
                            redirect_url={this.state.bankIdModal}></BankIdModal>
                        :

                        null
                }



                {
                    this.state.cardModal && this.state.age < 85 && this.props.openPayWithCard ?
                        <Modal isOpen={this.state.cardModal} centered>
                            <ModalHeader style={{ margin: 'auto' }}>{'Pick method of payment:'.translate(this.props.lang)}</ModalHeader>
                            <ModalBody className="delete-modal">
                                <Isvg src={Card} />
                            </ModalBody>
                            <ModalFooter className='buttons-right-blue'>
                                <div className="payment-container">
                                    {this.state.agentId && this.state.apiKey ?
                                        <Button className="payment-button-payson" onClick={() => this.setState({ paysonModal: this.state.cardModal, cardModal: !this.state.cardModal })}><Isvg src={PaysonSvg} /> <span>{'Credit card'.translate(this.props.lang)}</span> </Button>
                                        : null}
                                    {this.state.bankAccount ?
                                        <Button className="payment-button-swish" onClick={() => this.setState({ swishModal: this.state.cardModal, cardModal: !this.state.cardModal })}><Isvg src={SwishSvg} /></Button>
                                        : null}
                                </div>
                            </ModalFooter>
                        </Modal>
                        : null
                }

                {
                    this.state.cancelCostModal ?
                        <Modal isOpen={this.state.cancelCostModal} centered>
                            {/* <ModalHeader style={{ margin: 'auto' }}>{'Warning'.translate(this.props.lang)}</ModalHeader> */}
                            <ModalBody className="delete-modal">
                                {/* <img src={warrning} /> */}
                                <p className="message">{`If you cancel, your case will be deleted. Are you sure you want to continue?`.translate(this.props.lang)}</p>
                            </ModalBody>
                            <ModalFooter className='buttons-right-blue'>
                                <Button color="primary" style={{ margin: 10, minWidth: 100 }} onClick={() => this.setState({ cancelCostModal: false }, () => this.chageStatus(true))}>{'Ok'.translate(this.props.lang)}</Button>
                                <Button color="primary" style={{ margin: 10, minWidth: 100 }} onClick={() => this.setState({ cancelCostModal: false }, () => this.chageStatus(false))}>{'Cancel'.translate(this.props.lang)}</Button>

                            </ModalFooter>
                        </Modal>
                        : null
                }
                {
                    this.state.paysonModal ?

                        <Payson
                            lang={this.props.lang}
                            openModal={(item) => this.setState({
                                paysonModal: item
                            }, () => {
                                // this.props[0].history.push('/my-data/personal-referrals/referrals')
                                this.setState({
                                    successModal: true,
                                })
                            }

                            )}
                            data={this.state.paysonModal}
                            doctor={this.state.doctor}
                            patient={this.props.uData._id}
                            referral={this.state.referralId}
                            price={this.props.price}
                        />

                        : null
                }
                {
                    this.state.swishModal ?

                        <Swish
                            getStringDateInverseTs={this.props.getStringDateInverseTs}
                            clearStatusBefore={() => this.props.clearStatusBefore()}
                            verifyUser={() => this.props.verifyUser()}
                            lang={this.props.lang}
                            history={this.props[0]}
                            doctor={this.state.doctor}
                            data={this.state.swishModal}
                            patient={this.props.uData._id}
                            open={this.state.swishModal}
                            phone={this.props.uData.phone}
                            price={this.props.price}
                            referralNumber={this.state.referralNumber}
                            referral={this.state.referralId}
                            openModal={(item) => this.setState({
                                swishModal: item
                            }, () => {
                                // this.props[0].history.push('/my-data/personal-referrals/referrals')
                                this.setState({
                                    successModal: true,
                                })
                            }
                            )}
                        />


                        : null
                }




                {
                    this.state.wrongDate ?
                        <Modal isOpen={this.state.wrongDate} toggle={() => this.setState({ wrongDate: !this.state.wrongDate })} centered>
                            {/* <ModalHeader>
                                <h4></h4>
                            </ModalHeader> */}
                            <ModalBody className='delete-modal'>
                                <p>{'You can not pick date form the past'.translate(this.props.lang)}</p>
                            </ModalBody>
                            <ModalFooter className='button-right-blue'>
                                <Button style={{ minWidth: 100 }} onClick={() => this.setState({ wrongDate: !this.state.wrongDate, scheduleModal: !this.state.scheduleModal })}>{'Pick again'.translate(this.props.lang)}</Button>
                            </ModalFooter>
                        </Modal>

                        : null
                }
                {
                    this.state.wrongInput ?
                        <Modal isOpen={this.state.wrongInput} toggle={() => this.setState({ wrongInput: !this.state.wrongInput })} centered>
                            {/* <ModalHeader>
                                <h4></h4>
                            </ModalHeader> */}
                            <ModalBody className='delete-modal'>
                                <p>{'Wrong input'.translate(this.props.lang)}</p>
                            </ModalBody>
                            <ModalFooter className='buttons-right-blue'>
                                <Button style={{ minWidth: 100 }} onClick={() => this.setState({ wrongInput: !this.state.wrongInput, scheduleModal: !this.state.scheduleModal })}>{'Pick again'.translate(this.props.lang)}</Button>
                            </ModalFooter>
                        </Modal>

                        : null
                }
                {

                    this.state.error && this.state.error != "No available time slots." || (this.state.error == "No available time slots." && !this.state.noAvailableDoctors) ?

                        <ErrorModal lang={this.props.lang}
                            isOpen={this.state.error}
                            toggle={() => this.setState({ error: null })}

                        >
                            {this.state.error.translate(this.props.lang)}
                        </ErrorModal>

                        :
                        null
                }
                {
                    this.state.errorReserve ?

                        <ErrorModal lang={this.props.lang}
                            isOpen={this.state.errorReserve}
                            toggle={() => this.setState({ errorReserve: null, selectEvent: null }, () => {
                                this.get()
                                // if (typeof window !== 'undefined') {
                                //     localStorage.removeItem('tempEvent')
                                // }
                            })}
                            closeButtonText={'Cancel'.translate(this.props.lang)}
                        >
                            {this.state.errorReserve}
                        </ErrorModal>

                        :
                        null
                }

                {
                    this.state.noAvailableDoctors ?


                        <ErrorModal lang={this.props.lang}
                            isOpen={this.state.noAvailableDoctors}
                            toggle={() => this.goToLandingPage()}
                            closeButtonText={'Go back'.translate(this.props.lang)}

                        >
                            {this.state.noAvailableDoctors.translate(this.props.lang)}
                        </ErrorModal>

                        :
                        null
                }
                {
                    this.state.successModal ?
                        <Modal isOpen={this.state.successModal} centered>
                            {/* <ModalHeader style={{ margin: 'auto' }}>{'Notification'.translate(this.props.lang)}</ModalHeader> */}
                            <ModalBody className='delete-modal'>
                                {/* <Isvg src={info} /> */}
                                <p className='message'>{'You have successfully reserved an examination. Please fill out the form.'.translate(this.props.lang)}</p>
                            </ModalBody>
                            <ModalFooter className='buttons-right-blue'>
                                <Button color="primary" style={{ float: 'right', minWidth: 100 }} onClick={() => {
                                    this.setState({ successModal: !this.state.successModal })
                                    this.props.registerClinicGroupLogs({
                                        type: 'startCreateReferral',
                                        referralType: 'Web booking',
                                        questionaryId: this.props.personalQuestionary?._id,
                                        questionaryName: this.props.personalQuestionary?.name
                                    })
                                    this.props.closeCalendarModal(false)
                                }}>{'Ok'.translate(this.props.lang)}</Button>
                            </ModalFooter>
                        </Modal>

                        : null
                }


            </div >
        );
    }
}

export default WebBookingCalendar;