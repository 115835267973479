import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form'

import BootstrapField from './field';
import OptionsField from './options';
import HTMLField from './html';
import Image from './image';
import Tags from './tags';
import EasySelect from './easySelect';
import Table from './table';
import Conditions from './conditions';
import Answers from './answers';
import Toggle from './toggle'

import ColorPicker from './colorpicker';

import Phone from './phone';
import File from '../../forms/fields/file'
import SocialSecurityNumber from '../../forms/fields/socialSecurityNumber';
import NetId from '../../forms/fields/netId'
import SmsName from '../../forms/fields/smsName'
import TextWithStyle from '../../forms/fields/textWithStyle'
import CustomCheckbox from '../../forms/fields/customCheckbox';
import CheckBoxPrivacyPolicy from '../../forms/fields/checkBoxPrivacyPolicy';

import ListCheckbox from '../../forms/fields/listCheckbox';
import ListCheckboxStringValues from '../../forms/fields/listCheckboxStringValues';
import SelectWithCheckbox from '../../forms/fields/selectWithCheckbox';
import Map from '../../forms/fields/map';
import HighlightsText from './highlightsText';
import Url from './../../forms/fields/url'
import WebBookingOffset from './webBookingOffset';

import DateOfBirth from './dateOfBirth';


// import DatePicker from '../fields/datePicker';

import {
    Container,
    Row,
    Col,
    Button,
    Label,
} from 'reactstrap';


/**
* Generate fields for forms
* @author   Milan Stanojevic
*/
const renderTagsField = ({
    input,
    placeholder,
    label,
    meta: { touched, error },
}) => (

    <Tags
        placeholder={placeholder}
        label={label}
        errorText={touched && error}
        error={touched && error}
        {...input}
    />
)
const renderColorPicker = ({
    input,
    placeholder,
    label,
    className,
    classNameColor,
    meta: { touched, error },
    disabled
}) => (

    <ColorPicker
        placeholder={placeholder}
        disabled={disabled}
        label={label}
        className={className}
        classNameColor={classNameColor}
        errorText={touched && error}
        error={touched && error}
        {...input}
    />
)
const renderDateOfBirth = ({
    input,
    label,
    dateFormat,
    meta: { touched, error },
    disabled
}) => (

    <DateOfBirth
        disabled={disabled}
        label={label}
        dateFormat={dateFormat}
        errorText={touched && error}
        error={touched && error}
        {...input}
    />
)


const renderToggleField = ({
    input,
    placeholder,
    label,
    meta: { touched, error },
}) => (

    <Toggle
        placeholder={placeholder}
        label={label}
        errorText={touched && error}
        error={touched && error}
        {...input}
    />
)
const renderMapField = ({
    input,
    label,
    meta: { touched, error },
    height
}) => (

    <Map
        label={label}
        height={height}
        errorText={touched && error}
        error={touched && error}
        {...input}
    />
)

const renderConditionsField = ({
    input,
    placeholder,
    label,
    meta: { touched, error },
    questions,
}) => (

    <Conditions
        placeholder={placeholder}
        label={label}
        errorText={touched && error}
        error={touched && error}
        questions={questions}
        {...input}
    />
)
const renderCustomCheckbox = ({
    input,
    placeholder,
    label,
    meta: { touched, error },
    questions,
    afterText,
    labelInfo,
    disabled
}) => (

    <CustomCheckbox
        placeholder={placeholder}
        label={label}
        labelInfo={labelInfo}
        errorText={touched && error}
        error={touched && error}
        questions={questions}
        afterText={afterText}
        disabled={disabled}
        {...input}
    />
)
const renderCustomCheckboxPrivacyPolicy = ({
    input,
    placeholder,
    label,
    meta: { touched, error },
    questions,
    afterText,
    labelInfo,
    groupData,
    lang,
    disabled,
    firstLogin
}) => (

    <CheckBoxPrivacyPolicy
        placeholder={placeholder}
        label={label}
        labelInfo={labelInfo}
        errorText={touched && error}
        error={touched && error}
        questions={questions}
        afterText={afterText}
        groupData={groupData}
        lang={lang}
        disabled={disabled}
        firstLogin={firstLogin}
        {...input}
    />
)

export const renderCustomListCheckbox = ({
    input,
    label,
    placeholder,
    meta: { touched, error },
    type,
    children,
    haveValidation,
    optionValue,
    afterText,
    beforeText,
    multiple,
    disabled,
    disableFilter,
    checkboxField,
    values,
    lang
}) => (

    <ListCheckbox
        placeholder={placeholder}
        errorText={touched && error}
        label={label}
        error={touched && error}
        type={type}
        haveValidation={haveValidation}
        optionValue={optionValue}
        afterText={afterText}
        beforeText={beforeText}
        multiple={multiple}
        disabled={disabled}
        values={values}
        checkboxField={checkboxField}
        disableFilter={disableFilter}
        lang={lang}
        {...input}

    >{children}</ListCheckbox>
)
export const renderCustomListCheckboxStringValues = ({
    input,
    label,
    placeholder,
    meta: { touched, error },
    type,
    children,
    haveValidation,
    optionValue,
    afterText,
    beforeText,
    multiple,
    disabled,
    disableFilter,
    checkboxField,
    values,
    lang
}) => (

    <ListCheckboxStringValues
        placeholder={placeholder}
        errorText={touched && error}
        label={label}
        error={touched && error}
        type={type}
        haveValidation={haveValidation}
        optionValue={optionValue}
        afterText={afterText}
        beforeText={beforeText}
        multiple={multiple}
        disabled={disabled}
        values={values}
        checkboxField={checkboxField}
        disableFilter={disableFilter}
        lang={lang}
        {...input}

    >{children}</ListCheckboxStringValues>
)
const renderAnswersField = ({
    input,
    placeholder,
    label,
    disablePrintableAnswer,
    meta: { touched, error },
}) => (

    <Answers
        disablePrintableAnswer={disablePrintableAnswer}
        placeholder={placeholder}
        label={label}
        errorText={touched && error}
        error={touched && error}
        {...input}
    />
)


export const renderEasySelect = ({
    input,
    label,
    placeholder,
    meta: { touched, error },
    type,
    children,
    haveValidation,
    optionValue,
    afterText,
    beforeText,
    multiple,
    disabled,
    disableFilter,
    values,
    lang,
    selectAllAndDisableValues
}) => (

    <EasySelect
        placeholder={placeholder}
        errorText={touched && error}
        label={label}
        error={touched && error}
        type={type}
        haveValidation={haveValidation}
        optionValue={optionValue}
        afterText={afterText}
        beforeText={beforeText}
        multiple={multiple}
        disabled={disabled}
        values={values}
        disableFilter={disableFilter}
        lang={lang}
        selectAllAndDisableValues={selectAllAndDisableValues}
        {...input}

    >{children}</EasySelect>
)

export const renderSelectWithCheckbox = ({
    input,
    label,
    placeholder,
    meta: { touched, error },
    type,
    children,
    haveValidation,
    optionValue,
    afterText,
    beforeText,
    multiple,
    disabled,
    disableFilter,
    values,
    placeHolder,
    lang,
    selectAllAndDisableValues
}) => (

    <SelectWithCheckbox
        placeholder={placeholder}
        errorText={touched && error}
        label={label}
        error={touched && error}
        type={type}
        haveValidation={haveValidation}
        optionValue={optionValue}
        afterText={afterText}
        beforeText={beforeText}
        multiple={multiple}
        disabled={disabled}
        values={values}
        placeHolder={placeHolder}
        disableFilter={disableFilter}
        lang={lang}
        selectAllAndDisableValues={selectAllAndDisableValues}
        {...input}

    >{children}</SelectWithCheckbox>
)

export const renderTable = ({
    input,
    label,
    meta: { touched, error },
    type,
    children,
    actions,
    fields,
    values
}) => (

    <Table
        label={label}
        values={values}
        actions={actions}
        fields={fields}

        {...input}

    ></Table>
)


export const webBookingOffsetField = ({
    input,
    meta: { touched, error },
    haveValidation,
    disabled,
    multilang,
    disabledBorderTop,
    disabledBorderBottom,
    labelInfo,
    lang,
}) => (

    <WebBookingOffset
        errorText={touched && error}
        error={touched && error}
        haveValidation={haveValidation}
        disabled={disabled}
        multilang={multilang}
        disabledBorderTop={disabledBorderTop}
        disabledBorderBottom={disabledBorderBottom}
        labelInfo={labelInfo}
        lang={lang}
        {...input}

    />
)

export const renderField = ({
    input,
    label,
    placeholder,
    meta: { touched, error },
    type,
    children,
    haveValidation,
    optionValue,
    afterText,
    beforeText,
    multiple,
    labelInfo,
    disabled,
    autoComplete,
    multilang,
    pattern,
    minValue,
    height,
    width,
    login,
    lang,
    tooltip
}) => (

    <BootstrapField
        placeholder={placeholder}
        errorText={touched && error}
        label={label}
        error={touched && error}
        type={type}
        labelInfo={labelInfo}
        haveValidation={haveValidation}
        optionValue={optionValue}
        afterText={afterText}
        beforeText={beforeText}
        multiple={multiple}
        height={height}
        width={width}
        disabled={disabled}
        minValue={minValue}
        multilang={multilang}
        lang={lang}
        login={login}
        autoComplete={autoComplete}
        pattern={pattern}
        tooltip={tooltip}
        {...input}

    >{children}</BootstrapField>
)

export const renderSocialSecurityNumber = ({
    input,
    label,
    placeholder,
    meta: { touched, error },
    type,
    haveValidation,
    disabled,
    labelInfo,
    lang,
    country,
    selectedGroup,
    hideDropDown,
    selectedClinic,
    searchByNameAndEmailFormBuilder,
    disableNewPatientBtn,
    hideFindChildren,
    includeChildren
}) => (

    <SocialSecurityNumber
        placeholder={placeholder}
        errorText={touched && error}
        label={label}
        error={touched && error}
        type={type}
        labelInfo={labelInfo}
        haveValidation={haveValidation}
        disabled={disabled}
        lang={lang}
        selectedGroup={selectedGroup}
        selectedClinic={selectedClinic}
        hideDropDown={hideDropDown}
        country={country}
        searchByNameAndEmailFormBuilder={searchByNameAndEmailFormBuilder}
        disableNewPatientBtn={disableNewPatientBtn}
        hideFindChildren={hideFindChildren}
        includeChildren={includeChildren}
        {...input}

    ></SocialSecurityNumber>
)
export const renderSmsName = ({
    input,
    label,
    placeholder,
    meta: { touched, error },
    type,
    haveValidation,
    disabled,
    lang
}) => (

    <SmsName
        placeholder={placeholder}
        errorText={touched && error}
        label={label}
        error={touched && error}
        type={type}
        haveValidation={haveValidation}
        disabled={disabled}
        lang={lang}
        {...input}

    ></SmsName>
)
export const renderHighlihtsText = ({
    input,
    label,
    placeholder,
    meta: { touched, error },
    typeNotif,
    keyNotif,
    haveValidation,
    disabled,
    lang
}) => (

    <HighlightsText
        placeholder={placeholder}
        errorText={touched && error}
        label={label}
        error={touched && error}
        typeNotif={typeNotif}
        keyNotif={keyNotif}
        haveValidation={haveValidation}
        disabled={disabled}
        lang={lang}
        {...input}

    ></HighlightsText>
)
export const renderTextWithStyle = ({
    input,
    label,
    placeholder,
    meta: { touched, error },
    type,
    haveValidation,
    disabled,
    lang
}) => (

    <TextWithStyle
        placeholder={placeholder}
        errorText={touched && error}
        label={label}
        error={touched && error}
        type={type}
        haveValidation={haveValidation}
        disabled={disabled}
        lang={lang}
        {...input}

    ></TextWithStyle>
)

export const renderNetId = ({
    input,
    label,
    placeholder,
    meta: { touched, error },
    type,
    haveValidation,
    tooltip,
    disabled,
    lang
}) => (

    <NetId
        placeholder={placeholder}
        errorText={touched && error}
        label={label}
        error={touched && error}
        type={type}
        haveValidation={haveValidation}
        disabled={disabled}
        tooltip={tooltip}
        lang={lang}
        {...input}

    ></NetId>
)



export const renderPhone = ({
    input,
    label,
    placeholder,
    meta: { touched, error },
    type,
    children,
    haveValidation,
    disabled,
    multilang,
    lang,
    country,
    countryTwoLetter
}) => (

    <Phone
        placeholder={placeholder}
        errorText={touched && error}
        label={label}
        error={touched && error}
        type={type}
        haveValidation={haveValidation}
        disabled={disabled}
        multilang={multilang}
        lang={lang}
        country={country}
        countryTwoLetter={countryTwoLetter}
        {...input}

    >{children}</Phone>
)

export const renderUrl = ({
    input,
    label,
    placeholder,
    meta: { touched, error },
    type,
    children,
    haveValidation,
    disabled,
    multilang,
    lang
}) => (

    <Url
        placeholder={placeholder}
        errorText={touched && error}
        label={label}
        error={touched && error}
        type={type}
        haveValidation={haveValidation}
        disabled={disabled}
        multilang={multilang}
        lang={lang}
        {...input}

    >{children}</Url>
)

export const renderOptionsField = ({
    input,
    label,
    placeholder,
    meta: { touched, error },
    type,
    haveValidation,
    disabled,
    values
}) => (

    <OptionsField
        placeholder={placeholder}
        errorText={touched && error}
        label={label}
        error={touched && error}
        type={type}
        haveValidation={haveValidation}
        disabled={disabled}
        values={values}
        {...input}

    ></OptionsField>
)

export const renderHTMLField = ({
    input,
    label,
    height,
    placeholder,
    meta: { touched, error },
    type,
    haveValidation,
    disabled,
    name
}) => (

    <HTMLField
        placeholder={placeholder}
        disabled={disabled}
        errorText={touched && error}
        label={label}
        height={height}
        error={touched && error}
        type={type}
        name={name}
        haveValidation={haveValidation}
        {...input}

    ></HTMLField>
)


export const renderImageField = ({
    input,
    label,
    description,
    height,
    group,
    meta: { touched, error },
    lang
}) => (

    <Image
        label={label}
        description={description}
        errorText={touched && error}
        error={touched && error}
        height={height}
        group={group}
        lang={lang}
        {...input}
    />
)

export const renderFileField = ({
    input,
    label,
    height,
    meta: { touched, error },
    lang,
    className,
    group,
    acceptTypes,
    haveValidation
}) => (

    <File
        label={label}
        errorText={touched && error}
        error={touched && error}
        height={height}
        acceptTypes={acceptTypes}
        group={group}
        className={className}
        lang={lang}
        haveValidation={haveValidation}
        {...input}
    />
)


export const renderFields = (fields, lang = 'en', country = 'sweden', countryTwoLetter, selectedGroup, selectedClinic) => {
    return fields && fields.map((item, idx) => {
        if (!item) {
            return null;
        }
        if (item.type == 'col') {
            return (
                <Col lg={item.width.lg} sm={item.width.sm} xs={item.width.xs} style={item.style ? item.style : {}}>
                    {item.children && item.children.length ? renderFields(item.children, lang, country, countryTwoLetter, selectedGroup, selectedClinic) : null}
                </Col>
            )
        } else if (item.type == 'row') {
            return (
                <Row style={item.style ? item.style : {}}>
                    {item.children && item.children.length ? renderFields(item.children, lang, country, countryTwoLetter, selectedGroup, selectedClinic) : null}
                </Row>
            )
        } else if (item.type == 'h6') {
            return (
                <h6 style={item.style ? item.style : {}}>{item.text}</h6>
            )
        } else if (item.type == 'h4') {
            return (
                <h4 style={item.style ? item.style : {}}>{item.text}</h4>
            )
        }
        else if (item.type == 'hr') {
            return (
                <hr style={item.style ? item.style : {}} />
            )
        } else if (item.type == 'empty') {
            return (
                <></>
            )
        } else if (item.type == 'button') {
            // console.log(item);
            return (
                <div>
                    <Label>{item.label}</Label>
                    <br />
                    <button className={'login-button-landing-page'} style={item.loginOutline ? { backgroundColor: 'transparent', border: `1px solid ${item.loginColor}`, color: item.loginColor } : { background: item.loginColor, border: `1px solid transparent`, color: 'white' }}
                        // disabled
                        onMouseEnter={() => item.onMouseEnter()}
                        onMouseLeave={() => item.onMouseLeave()}
                    >{'Login'}</button>

                </div>
            )
        } else if (item.type == 'custom-div') {
            return item.div
        } else if (item.type == 'color-picker') {
            return (
                <Field
                    name={item.name}
                    component={renderColorPicker}
                    label={item.label}
                    validate={item.validate}
                    haveValidation={item.validate && item.validate.length ? true : false}
                    disabled={item.disabled}
                    values={item.values}
                    className={item.className}
                    classNameColor={item.classNameColor}
                ></Field>

            )
        } else if (item.type == 'date-of-birth') {
            return (
                <Field
                    name={item.name}
                    component={renderDateOfBirth}
                    label={item.label}
                    validate={item.validate}
                    haveValidation={item.validate && item.validate.length ? true : false}
                    disabled={item.disabled}
                    dateFormat={item.dateFormat}
                ></Field>

            )
        } else if (item.type == 'options') {
            return (
                <Field
                    name={item.name}
                    component={renderOptionsField}
                    validate={item.validate}
                    haveValidation={item.validate && item.validate.length ? true : false}
                    disabled={item.disabled}
                    values={item.values}
                ></Field>

            )
        } else if (item.type == 'html') {
            return (
                <Field
                    name={item.name}
                    label={item.label}
                    height={item.height}
                    component={renderHTMLField}
                    validate={item.validate}
                    disabled={item.disabled}
                    haveValidation={item.validate && item.validate.length ? true : false}
                ></Field>

            )
        } else if (item.type == 'image') {
            return (
                <Field
                    name={item.name}
                    label={item.label}
                    group={item.group}
                    description={item.description}
                    height={item.height}
                    component={renderImageField}
                    validate={item.validate}
                    haveValidation={item.validate && item.validate.length ? true : false}
                    lang={item.lang ? item.lang : lang}
                ></Field>

            )
        } else if (item.type == 'file') {
            return (
                <Field
                    name={item.name}
                    label={item.label}
                    height={item.height}
                    group={item.group}
                    className={item.className}
                    component={renderFileField}
                    acceptTypes={item.acceptTypes}
                    validate={item.validate}
                    haveValidation={item.validate && item.validate.length ? true : false}
                    lang={item.lang ? item.lang : lang}
                ></Field>

            )
        } else if (item.type == 'checkbox') {
            return (
                <Field
                    name={item.name}
                    label={item.label}
                    height={item.height}
                    component={renderCustomCheckbox}
                    labelInfo={item.labelInfo}
                    validate={item.validate}
                    disabled={item.disabled}
                    afterText={item.afterText}
                    haveValidation={item.validate && item.validate.length ? true : false}
                    lang={lang}
                ></Field>

            )
        } else if (item.type == 'privacy-policy-checkbox') {
            return (
                <Field
                    name={item.name}
                    label={item.label}
                    height={item.height}
                    groupData={item.groupData}
                    component={renderCustomCheckboxPrivacyPolicy}
                    labelInfo={item.labelInfo}
                    validate={item.validate}
                    disabled={item.disabled}
                    afterText={item.afterText}
                    haveValidation={item.validate && item.validate.length ? true : false}
                    lang={item.lang}
                    firstLogin={item.firstLogin}
                ></Field>

            )
        } else if (item.type == 'map') {
            return (
                <Field
                    name={item.name}
                    label={item.label}
                    height={item.height}
                    component={renderMapField}
                    validate={item.validate}
                    disabled={item.disabled}
                    lang={lang}
                ></Field>

            )
        } else if (item.type == 'list-checkbox') {
            return (
                <Field
                    name={item.name}
                    component={renderCustomListCheckbox}
                    label={item.label}
                    placeholder={item.placeholder}
                    validate={item.validate}
                    type={'select'}
                    haveValidation={item.validate && item.validate.length ? true : false}
                    multiple={item.multiple}
                    disabled={item.disabled}
                    checkboxField={item.checkboxField}
                    disableFilter={item.disableFilter}
                    values={item.values}
                    lang={item.lang ? item.lang : lang}
                >

                </Field>
            )
        } else if (item.type == 'select-checkbox') {
            return (
                <Field
                    name={item.name}
                    component={renderSelectWithCheckbox}
                    label={item.label}
                    placeholder={item.placeholder}
                    validate={item.validate}
                    type={'select'}
                    haveValidation={item.validate && item.validate.length ? true : false}
                    multiple={item.multiple}
                    disabled={item.disabled}
                    checkboxField={item.checkboxField}
                    disableFilter={item.disableFilter}
                    placeHolder={item.placeHolder}
                    values={item.values}
                    lang={item.lang ? item.lang : lang}
                >

                </Field>
            )
        } else if (item.type == 'list-checkbox-string-values') {
            return (
                <Field
                    name={item.name}
                    component={renderCustomListCheckboxStringValues}
                    label={item.label}
                    placeholder={item.placeholder}
                    validate={item.validate}
                    type={'select'}
                    haveValidation={item.validate && item.validate.length ? true : false}
                    multiple={item.multiple}
                    disabled={item.disabled}
                    checkboxField={item.checkboxField}
                    disableFilter={item.disableFilter}
                    values={item.values}
                    lang={item.lang ? item.lang : lang}
                >

                </Field>
            )
        } else if (item.type == 'tags') {
            return (
                <Field
                    name={item.name}
                    label={item.label}
                    component={renderTagsField}
                    validate={item.validate}
                    haveValidation={item.validate && item.validate.length ? true : false}
                ></Field>

            )
        } else if (item.type == 'conditions') {
            return (
                <Field
                    name={item.name}
                    label={item.label}
                    component={renderConditionsField}
                    validate={item.validate}
                    haveValidation={item.validate && item.validate.length ? true : false}
                    questions={item.questions}
                ></Field>

            )
        } else if (item.type == 'answers') {
            return (
                <Field
                    name={item.name}
                    label={item.label}
                    component={renderAnswersField}
                    disablePrintableAnswer={item.disablePrintableAnswer}
                    validate={item.validate}
                    haveValidation={item.validate && item.validate.length ? true : false}
                ></Field>

            )
        } else if (item.type == 'easy-select') {
            return (
                <Field
                    name={item.name}
                    component={renderEasySelect}
                    label={item.label}
                    placeholder={item.placeholder}
                    validate={item.validate}
                    type={'select'}
                    haveValidation={item.validate && item.validate.length ? true : false}
                    multiple={item.multiple}
                    disabled={item.disabled}
                    disableFilter={item.disableFilter}
                    values={item.values}
                    lang={item.lang ? item.lang : lang}
                    selectAllAndDisableValues={item.selectAllAndDisableValues}
                >

                </Field>
            )
        } else if (item.type == 'table') {
            return (
                <Field
                    name={item.name}
                    component={renderTable}
                    label={item.label}
                    values={item.values}
                    fields={item.fields}
                    actions={item.actions}
                ></Field>
            )
        } else if (item.type == 'phone') {
            return (
                <Field
                    name={item.name}
                    component={renderPhone}
                    label={item.label}
                    validate={item.validate}
                    haveValidation={item.validate && item.validate.length ? true : false}
                    disabled={item.disabled}
                    country={country}
                    countryTwoLetter={countryTwoLetter}
                ></Field>
            )
        } else if (item.type == 'url') {
            return (
                <Field
                    name={item.name}
                    component={renderUrl}
                    label={item.label}
                    validate={item.validate}
                    haveValidation={item.validate && item.validate.length ? true : false}
                    disabled={item.disabled}
                ></Field>
            )
        } else if (item.type == 'socialSecurityNumber') {
            return (
                <Field
                    name={item.name}
                    component={renderSocialSecurityNumber}
                    placeholder={item.placeholder}
                    hideDropDown={item.hideDropDown}
                    validate={item.validate}
                    labelInfo={item.labelInfo}
                    haveValidation={item.validate && item.validate.length ? true : false}
                    label={item.label}
                    disabled={item.disabled}
                    lang={lang}
                    country={country}
                    selectedGroup={selectedGroup}
                    selectedClinic={selectedClinic}
                    searchByNameAndEmailFormBuilder={item.searchByNameAndEmailFormBuilder}
                    disableNewPatientBtn={item.disableNewPatientBtn}
                    hideFindChildren={item.hideFindChildren}
                    includeChildren={item.includeChildren}
                ></Field>
            )
        } else if (item.type == 'smsName') {
            return (
                <Field
                    name={item.name}
                    component={renderSmsName}
                    placeholder={item.placeholder}
                    validate={item.validate}
                    haveValidation={item.validate && item.validate.length ? true : false}
                    label={item.label}
                    disabled={item.disabled}
                ></Field>
            )
        }
        else if (item.type == 'highlightText') {
            return (
                <Field
                    name={item.name}
                    component={renderHighlihtsText}
                    placeholder={item.placeholder}
                    validate={item.validate}
                    typeNotif={item.typeNotif}
                    keyNotif={item.keyNotif}
                    haveValidation={item.validate && item.validate.length ? true : false}
                    label={item.label}
                    lang={item.lang}
                    disabled={item.disabled}
                ></Field>
            )
        }
        else if (item.type == 'switch') {
            return (
                <Field
                    name={item.name}
                    label={item.label}
                    component={renderToggleField}
                    validate={item.validate}
                    haveValidation={item.validate && item.validate.length ? true : false}
                ></Field>

            )
        } else if (item.type == 'textWithStyle') {
            return (
                <Field
                    name={item.name}
                    component={renderTextWithStyle}
                    placeholder={item.placeholder}
                    validate={item.validate}
                    haveValidation={item.validate && item.validate.length ? true : false}
                    label={item.label}
                    disabled={item.disabled}
                ></Field>
            )
        } else if (item.type == 'netId') {
            return (
                <Field
                    name={item.name}
                    component={renderNetId}
                    placeholder={item.placeholder}
                    validate={item.validate}
                    haveValidation={item.validate && item.validate.length ? true : false}
                    label={item.label}
                    tooltip={item.tooltip}
                    disabled={item.disabled}
                ></Field>
            )
        } else if (item.type == 'webBookingOffset') {
            return (
                <Field
                    name={item.name}
                    component={webBookingOffsetField}
                    validate={item.validate}
                    haveValidation={item.validate && item.validate.length ? true : false}
                    disabled={item.disabled}
                    multilang={item.multilang}
                    lang={lang}
                    disabledBorderTop={item.disabledBorderTop}
                    disabledBorderBottom={item.disabledBorderBottom}
                    labelInfo={item.labelInfo}
                />
            )
        } else {
            return (
                <Field
                    name={item.name}
                    component={renderField}
                    label={item.label}
                    placeholder={item.placeholder}
                    validate={item.validate}
                    type={item.type}
                    haveValidation={item.validate && item.validate.length ? true : false}
                    optionValue={item.optionValue}
                    afterText={item.afterText}
                    beforeText={item.beforeText}
                    labelInfo={item.labelInfo}
                    multiple={item.multiple}
                    disabled={item.disabled}
                    multilang={item.multilang}
                    height={item.height}
                    width={item.width}
                    lang={item.lang}
                    login={item.login}
                    autoComplete={item.autoComplete}
                    pattern={item.pattern}
                    minValue={item.minValue}
                    tooltip={item.tooltip}
                >
                    {item.values && item.values.length ? item.values.map((option, oidx) => {
                        return (
                            <option key={oidx} value={option.value}>{option.name}</option>
                        )
                    })
                        :
                        null
                    }
                </Field>
            )
        }

    })
}
