import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';

import Isvg from 'react-inlinesvg';
import searchIcon from '../../../assets/svg/search.svg'
import xIcon from '../../../assets/svg/x.svg';
import {
    Container,
    Row,
    Col,
    Button,
    FormGroup,
    Label,
    Input,
    UncontrolledAlert,
    Modal, ModalHeader, ModalBody, ModalFooter, Form
} from 'reactstrap';


class CustomSelectWithSearch extends Component {
    constructor(props) {
        super(props);
        this.wrapperRef = React.createRef();

        this.state = {
            // search: ''
        };
    }

    componentDidMount() {

    }





    render() {
        let items = [];
        if (this.props.items && this.props.items.filter(item => !this.state.search || (this.state.search && item.name && item.name.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1)).length) {
            items = this.props.items.filter(item => !this.state.search || (this.state.search && item.name && item.name.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1));
        }

        return (
            <div className='custom-select-with-search-wrap' style={this.props.minWidth ? { minWidth: this.props.minWidth } : {}}>
                {
                    !this.props.hideSearchField ?
                        <div className='custom-select-with-search-input-wrap'>
                            <Input placeholder={'Search'.translate(this.props.lang)} type='text' value={this.state.search} onChange={(e) => { this.setState({ search: e.target.value }) }} />
                            {
                                this.props.isInputClearable ?
                                    <div>
                                        {
                                            this.state.search && this.state.search !== '' ?
                                                <div className='x-icon' onClick={() => {
                                                    this.setState({
                                                        search: ''
                                                    })
                                                }}>
                                                    <Isvg src={xIcon} />
                                                </div>
                                                :

                                                <Isvg src={searchIcon} />
                                        }
                                    </div>
                                    :
                                    <Isvg src={searchIcon} />
                            }

                        </div>
                        :
                        null
                }

                <div className='custom-select-with-search-items-wrap' style={this.props.maxHeight ? { maxHeight: this.props.maxHeight } : {}}>
                    {
                        items.map((item, idx) => {
                            return (
                                <div key={idx} className={this.props.value && item && item.value && this.props.value === item?.value ? "custom-select-with-search-item acitve-item" : "custom-select-with-search-item"} onClick={() => this.props.onChange(item)}>
                                    {item?.name}
                                </div>
                            )
                        })
                    }
                </div>
            </div>

        );
    }
}

export default CustomSelectWithSearch;
