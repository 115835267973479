import React, { Component } from 'react';
import ListBuilder from '../listBuilder';
import { API_ENDPOINT } from '../../constants';
import Isvg from 'react-inlinesvg';

import eyeIcon from '../../assets/svg/eye.svg';
import historyIcon from '../../assets/svg/history-icon.svg';
import archiveIcon from '../../assets/svg/archive.svg';
import trashIcon from '../../assets/svg/garbage.svg';
import pdfIcon from '../../assets/svg/save-as-pdf.svg';
import { defaultError } from '../TwoFactorAuthModal';
import { Button } from 'reactstrap';


function parseJSON(response) {
    return response.json().then(json => {
        return {
            result: json,
            status: response.status
        }
    })
}

const loadDataWithQuery = [
    (fetchFunction, lang, match, query) => {
        let url = `${API_ENDPOINT}/consultation/items?page=${query?.page ?? 0}&entries=${query?.entries ?? 10}&sortField=${query?.sortField ?? '_id'}&sortType=${query?.sortType ?? -1}&filter=${query?.filter ?? ''}&clinicGroupId=${query?.clinicGroupId ?? ''}&departmentId=${query?.departmentId ?? ''}&_type=${query?.type ?? ''}&previewOpened=${query?.previewOpened ?? ''}`;
        if (query?.allConsultations === true) url += `&allConsultations=yes`;

        return fetchFunction?.(url, {
            method: 'GET',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
        }).then(parseJSON).then(({ result, status }) => {
            if (!result.error) {
                return {
                    page: query.page ? query.page : 0,
                    items: result.items,
                    total: result.total,
                    previewOpened: result.previewOpened,
                    resultFilter: result.filter
                }
            }
            else return { error: result.error }
        })
    }
]

export class ConsultationList extends Component {
    constructor(props) {
        super(props)

        this.state = {
            num: 0,
            form: false,
            useState: true,
            params: {
                entries: 10,
                page: 0,
                sortField: '_id',
                sortType: -1,
                filter: ''
            },
            items: [],
            total: 0,
            loading: true,
            error: null,
            user: null,
        }
    }

    get = () => {
        this.setState({ loading: true }, () => {
            this.props?.updateMeta?.(this.props?.generateSeoTags?.(this.state, this.props.lang));

            for (let i = 0; i < loadDataWithQuery?.length; i++) {
                loadDataWithQuery?.[i]?.(window?.fetch, this.props.lang, this.props[0].match, { ...this.state.params, clinicGroupId: this.props.selectedGroup, departmentId: this.props.selectedClinic, allConsultations: this.props.allConsultations, type: this.props.type, previewOpened: this.props.previewOpened }).then(data => {
                    if (data?.error) return this.setState({ loading: null, error: data.error?.translate(this.props.lang || 'en') });
                    console.log('get', { ...data })
                    if (this.props.previewOpened && data?.items?.filter(item => item._id === this.props.previewOpened)?.length) {
                        this.props.onPreview?.(data.items.filter(item => item._id === this.props.previewOpened)[0])
                    }else if(this.props.previewOpened && data?.previewOpened){
                        this.props.onPreview?.(data.previewOpened)
                    }
                    this.setState({ ...data, loading: null });
                })
            }
        })
    }

    componentDidMount() {
        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }

        this.get();

        if (this.props?.socket) this.registerSocket();
    }

    componentDidUpdate(prevProps, prevState) {
        if ((!prevProps?.selectedGroup && this.props.selectedGroup) || (prevProps?.selectedGroup !== this.props.selectedGroup) || (!prevProps?.selectedClinic && this.props.selectedClinic) || (prevProps?.selectedClinic !== this.props.selectedClinic)) this.get();

        if (prevProps?.num !== this.props.num) this.get();

        if (prevState?.num !== this.state.num) this.get();

        if (prevState?.params !== this.state.params) this.get();

        if (prevProps.allConsultations !== this.props.allConsultations) this.get();

        if (prevProps?.type !== this.props.type) this.get();
        if (prevProps?.updateConsultationPageItems !== this.props.updateConsultationPageItems) this.get();


        if ((!prevProps?.socket && this.props?.socket) && !this.state.socketInitialized) this.registerSocket();
    }

    componentWillUnmount() {
        this.props?.socket?.off?.('_consultationTransfer', this.socketListener);
        this.props.socket?.removeListener?.('_consultationTransfer', this.socketListener);
        this.props.socket?.removeListener?.('_consultationTransfer');
    }

    socketListener = ({ consultationId, _consultation }) => {
        console.log('socketListener', { consultationId, _consultation });
        this.refresh();
    }

    registerSocket = () => {
        this.setState({ socketInitialized: true }, () => {
            this.props.socket.on('_consultationTransfer', this.socketListener);
        });
    }

    updateSort = (field, type) => this.setState(prev => ({
        ...prev,
        params: {
            ...prev?.params,
            sortField: field,
            sortType: type
        }
    }));

    updateParams = (name, value, restart = false) => {
        let obj = {
            [name]: value
        }
        this.setState(prev => ({
            ...prev,
            params: {
                ...prev?.params,
                entries: restart ? 10 : prev.params.entries,
                page: restart ? 0 : prev.params.page,
                ...obj
            }
        }));
    }

    updateMultipleParams = (fields, restart = false) => {
        let obj = {};
        if (restart) {
            obj.entries = 10;
            obj.page = 0;
        }
        for (let i = 0; i < fields.length; i++) {
            obj[fields[i].name] = fields[i].value;
        }
        return this.setState(prev => ({
            ...prev,
            params: {
                ...prev?.params,
                ...obj
            }
        }));
    }

    searchPatientWithRedirect = (SSN, enterprise) => {
        let obj = {
            socialSecurityNumber: SSN,
            group: this.props.selectedGroup,
            clinic: this.props.selectedClinic,
        }
        enterprise && (obj.openOnWebDoc = true);

        fetch(API_ENDPOINT + '/users/find/patient', {
            method: 'POST',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
            body: JSON.stringify(obj)
        }).then(res => res.json()).then((result) => {

            if (result && result._id) {
                const path = enterprise ? '/patients/search-enterprise' : '/patients/search';
                this.props.registerClinicGroupLogs?.({
                    type: 'patientProfile',
                    patient: SSN,
                });

                let state = {
                    TYPE: 'redirect',
                    redirectPatient: result,
                    redirectTabIndex: 1
                }
                enterprise && (state.redirectTabActive = 1);

                this.props?.[0]?.history?.push?.(path, state);

            } else {
                this.setState({ error: 'User not found in this clinic group' })
            }
        })
    }

    updateSeen = async (id, actioner) => {
        // if (!id || !actioner || !['sender', 'receiver'].includes(actioner)) return;
        // try {
        //     const response = await fetch(API_ENDPOINT + `/e-connect/cases/count/${id}`, {
        //         method: 'PATCH',
        //         headers: {
        //             'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
        //             'content-type': 'application/json'
        //         },
        //         body: JSON.stringify({ actioner })
        //     });
        //     if (!response || !response.ok) return;

        //     this.props.checkEConnectCount?.();
        //     this.refresh();
        // } catch (err) {
        //     console.log('--- updateSeen err: ', err);
        // }
    }

    refresh = () => { this.setState(prev => ({ ...prev, num: (prev?.num || 0) + 1 })) }


    render() {
        let params = this.state.params;
        return (
            <>
                <ListBuilder
                    lang={this.props.lang}
                    loading={this.state.loading}
                    total={this.state.total}
                    showNumeration={false}
                    filterDelay={500}
                    fields={[
                        { type: 'text', name: '_caseNumber', label: 'Case ID'.translate(this.props.lang), allowSort: true },
                        { type: 'text', name: '_patient', label: 'Patient'.translate(this.props.lang), allowSort: true },
                        { type: 'text', name: '_sender', label: 'Sender'.translate(this.props.lang), allowSort: true },
                        { type: 'text', name: '_receiver', label: 'Receiver'.translate(this.props.lang), allowSort: true },
                        { type: 'text', name: '_lastChange', label: 'Last Change'.translate(this.props.lang), allowSort: true },
                        { type: 'text', name: '_status', label: 'Status'.translate(this.props.lang), allowSort: true },
                    ]}
                    rawItems={this.state.items}
                    items={this.state.items?.map(item => ({
                        ...item,
                        ___className: item?._id && this.props.highlightedCases?.includes?.(item._id) ? 'ec-highlighted-row' : undefined,
                        _caseNumber: item?.caseData?.caseNumber,
                        _patient: this.props.canOpenProfile ? <Button color='#fff' className="patient-modal-button" onClick={() => {
                            if (!this.props.canOpenProfile || !item?.patientSSN) return;
                            this.searchPatientWithRedirect(item?.patientSSN, this.props.enterprise);
                        }}>{item?.patientName}<br /><span className="clinic-subgroup-name">{item?.patientSSN} </span>  {item?._2fa ? <p style={{ padding: 0, margin: 0, color: 'red', fontSize: 12 }}>{'2FA'.translate(this.props.lang)}</p> : null} </Button> : <div style={{ padding: 10 }}>
                            <div style={{ fontWeight: '600', fontSize: 14 }}>{item?.patientName}</div>
                            <div style={{ fontWeight: '600', fontSize: 10 }}>{item?.patientSSN}</div>
                        </div>,
                        _sender: <div className='sender-receiver-wrap'>
                            <div className='sender-receiver-group'>{item?.sender?.groupName}</div>
                            <div className='sender-receiver-clinic'>{item?.sender?.clinicName} - {item?.sender?.clinicSubgroupName}</div>
                            <div className='sender-receiver-doctor'>{item?.sender?.doctorName}</div>
                        </div>,
                        _receiver: <div className='sender-receiver-wrap'>
                            <div className='sender-receiver-group'>{item?.receiver?.groupName}</div>
                            <div className='sender-receiver-clinic'>{item?.receiver?.clinicName} - {item?.receiver?.clinicSubgroupName}</div>
                            <div className='sender-receiver-doctor'>{item?.receiver?.doctorName}</div>
                        </div>,
                        _lastChange: <div>
                            <div style={{ fontSize: 13, margin: 0, padding: 0 }}>
                                {this.props.getStringDateTs(this.props.type === 'incoming' ? item?.receiver?.ts : item?.sender?.ts, this.props.dateFormat + ' HH:mm')}
                            </div>

                        </div>,

                        _status: <div className={`e-connect-list-status ${this.props.type === 'incoming' ?
                            (
                                item?.receiver?.status === 'ongoing' || item?.receiver?.status === 'case-received' ? (
                                    'e-connect-list-status-sent'
                                ) : item?.receiver?.status === 'unhandled' || item?.receiver?.status === 'new-event' ? (
                                    'e-connect-list-status-rejected'
                                ) : item?.receiver?.status === 'closed' ? (
                                    'e-connect-list-status-accepted'
                                ) : ''
                            )
                            :
                            this.props.type === 'outgoing' ? // [sent, answered, new-event, case-transferred, closed]
                                (
                                    item?.sender?.status === 'sent' || item?.sender?.status === 'ongoing' || item?.sender?.status === 'case-transferred' ? (
                                        'e-connect-list-status-sent'
                                    ) : item?.sender?.status === 'new-event' ? (
                                        'e-connect-list-status-rejected'
                                    ) : item?.sender?.status === 'closed' ? (
                                        'e-connect-list-status-accepted'
                                    ) : ''
                                )
                                :
                                (
                                    item?.receiver?.status === 'received' ? (
                                        'e-connect-list-status-sent'
                                    ) : item?.receiver?.status === 'rejected' ? (
                                        'e-connect-list-status-rejected'
                                    ) : item?.receiver?.status === 'in-progress' ? (
                                        'e-connect-list-status-accepted'
                                    ) : ''
                                )
                            }`}>
                            {
                                this.props.type === 'incoming' ? (
                                    item?.receiver?.status === 'unhandled' ? (
                                        'Unhandled'.translate(this.props.lang) // TODO
                                    ) : item?.receiver?.status === 'ongoing' ? (
                                        'Ongoing'.translate(this.props.lang)
                                    ) : item?.receiver?.status === 'new-event' ? (
                                        'New event'.translate(this.props.lang)
                                    ) : item?.receiver?.status === 'case-received' ? (
                                        'Case received'.translate(this.props.lang)
                                    ) : item?.receiver?.status === 'closed' ? (
                                        'Closed'.translate(this.props.lang)
                                    ) : 'N/A'
                                )
                                    :
                                    this.props.type === 'outgoing' ? (
                                        item?.sender?.status === 'sent' ? (
                                            'Sent'.translate(this.props.lang) // TODO
                                        ) : item?.sender?.status === 'ongoing' ? (
                                            'Ongoing'.translate(this.props.lang)
                                        ) : item?.sender?.status === 'new-event' ? (
                                            'New event'.translate(this.props.lang)
                                        ) : item?.sender?.status === 'case-transferred' ? (
                                            'Case transferred'.translate(this.props.lang)
                                        ) : item?.sender?.status === 'closed' ? (
                                            'Closed'.translate(this.props.lang)
                                        ) : 'N/A'
                                    )
                                        :
                                        this.props.type === 'archived' ? (
                                            item?.sender?.status === 'closed' ? (
                                                'Closed'.translate(this.props.lang) // TODO
                                            ) : 'N/A'
                                        )
                                            :
                                            null
                            }
                        </div>,

                    }))}
                    actions={[
                        {
                            renderComponent: item => <div className="tabel-action-tooltip">
                                <Isvg src={eyeIcon} className="view-icon" />
                                {this.props.consultationIndicators?.chatConsultationIds?.indexOf(item._id) !== -1 || this.props.type === 'incoming' && this.props.consultationIndicators?.unhandled?.includes?.(item._id) ? <div className='consultation-new-message-notif'></div> : null}
                                <div className="chat-icons-tooltip">{'Open'.translate(this.props.lang)}</div>
                            </div>,
                            onClick: item => {
                                this.props.onPreview?.(item);

                                // if (item?._id && this.props.type === 'created' && ['created', 'sent'].includes(this.props.type) && !item?.sender?.statusSeen && String(item?.sender?.user) === String(this.props.uData?._id) && String(item?.sender?.id) === String(this.props.selectedGroup) && String(item?.sender?.departmentId) === String(this.props.selectedClinic)) {
                                //     let actioner = 'sender';
                                //     let id = item?._id;

                                //     this.updateSeen(id, actioner);
                                // } else if (item?._id && this.props.type !== 'created' && ['received', 'answered'].includes(this.props.type) && !item?.receiver?.statusSeen && String(item?.receiver?.id) === String(this.props.selectedGroup) && (item?.receiver?.status === 'received' || (String(item?.receiver?.user) === String(this.props.uData?._id) && String(item?.receiver?.departmentId) === String(this.props.selectedClinic)))) {
                                //     let actioner = 'receiver';
                                //     let id = item?._id;

                                //     this.updateSeen(id, actioner);
                                // }
                            },
                        },
                        // {
                        //     component: <div className="tabel-action-tooltip" style={{ width: 24, height: 22 }}>
                        //         <Isvg src={historyIcon} className="view-icon" />
                        //         <div className="chat-icons-tooltip">{'View history'.translate(this.props.lang)}</div>
                        //     </div>,
                        //     condition: item => item?.history ? true : false,
                        //     onClick: item => this.props.onHistory?.(item),
                        // },
                        // {
                        //     component: <div className="tabel-action-tooltip" style={{ width: 24, height: 22 }}>
                        //         <Isvg src={pdfIcon} className="view-icon" />
                        //         <div className="chat-icons-tooltip">{'Save as PDF'.translate(this.props.lang)}</div>
                        //     </div>,
                        //     condition: item => item?._id ? true : false,
                        //     onClick: item => this.props.onPrint?.(item),
                        // },
                        // {
                        //     component: <div className="tabel-action-tooltip" style={{ width: 24, height: 22 }}>
                        //         <Isvg src={archiveIcon} className="view-icon" />
                        //         <div className="chat-icons-tooltip">{'Archive'.translate(this.props.lang)}</div>
                        //     </div>,
                        //     condition: item => {
                        //         if (this.props.type === 'created') {
                        //             if (item?.sender?.status && ['rejected', 'accepted'].includes(item?.sender?.status) && String(item?.sender?.user) === String(this.props.uData?._id)) return true;
                        //             return false;
                        //         } else {
                        //             if (item?.receiver?.status && ['rejected', 'in-progress'].includes(item?.receiver?.status) && String(item?.receiver?.user) === String(this.props.uData?._id)) return true;
                        //             return false;
                        //         }
                        //         return false;
                        //     },
                        //     onClick: item => this.props.onArchive?.(item),
                        // },
                        // {
                        //     component: <div className="tabel-action-tooltip" style={{ width: 24, height: 22 }}>
                        //         <Isvg src={trashIcon} className="view-icon" />
                        //         <div className="chat-icons-tooltip">{'Delete'.translate(this.props.lang)}</div>
                        //     </div>,
                        //     condition: item => this.props.type === 'created' && this.props.type === 'created' && item?.sender?.status === 'created' && this.props.uData?._id && (String(this.props.uData?._id) === String(item?.sender?.user)) ? true : false,
                        //     onClick: item => this.props.onDelete?.(item),
                        // },
                    ]}
                    params={params}
                    sortField={params.sortField}
                    sortType={params.sortType}
                    updateSort={this.updateSort}
                    updateParams={this.updateParams}
                    updateMultipleParams={this.updateMultipleParams}
                />
            </>
        )
    }
}

export default ConsultationList;