import React, { Component } from 'react';
import Page from '../../containers/page';

import {
    Container,
    Row,
    Col,
    Modal, ModalHeader, ModalBody, ModalFooter, Button, Table, Input, Label, FormGroup

} from 'reactstrap';

import Isvg from "react-inlinesvg";

import { formValueSelector, change } from 'redux-form';  // ES6
import { connect } from 'react-redux';
import addIcon from '../../assets/svg/add-icon.svg';
import edit from '../../assets/svg/edit.svg'
import garabage from '../../assets/svg/garbage.svg'

import FormBuilder from '../../components/forms/modalFormBuilder';
import { required } from '../../components/forms/validation';

import ListBuilder from '../../components/listBuilder';
import DeleteModal from '../../components/deleteModal';
import ErrorModal from '../../components/errorModal';
import Search from "../../components/search";
import profileUser from "../../assets/images/user.png";
import notification from "../../assets/svg/notification.svg";
import notificationSettings from "../../assets/svg/notifications_settings.svg";
import moreIcon from "../../assets/svg/more.svg";
import { getSearchParams, generateSearchLink, generateSearchLinkMultiple } from '../../helpers/linkHelper';
import { API_ENDPOINT } from '../../constants';
import Checkbox from '../../components/forms/fields/checkbox';
import { Player, Controls } from "@lottiefiles/react-lottie-player";


import warrning from '../../assets/svg/warrning.svg'

function parseJSON(response) {
    return response.json().then(json => {
        return {
            result: json,
            status: response.status
        }
    })
}

/**
* Clinic users page
* @author   Milan Stanojevic
*/
class SubAccounts extends Component {
    constructor(props) {
        super(props);
        this.player = React.createRef();
        this.player2 = React.createRef();
        this.state = {
            ...props.initialData,
            form: false,
            useState: false,
            params: {
                entries: 10,
                page: 0
            },
            items: [],
            revisitForms: [],


            total: 0,

        };
    }


    showPlayer = () => {
        this.setState({
            showPlayer: true,
        });
    };

    hidePlayer = () => {
        this.setState({
            showPlayer: false,
        });
    };

    get = () => {
        this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));

        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0, groupId: this.props.selectedGroup })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

        for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
            this.props.loadDataWithQuery[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0, groupId: this.props.selectedGroup, clinic: this.props.selectedClinic })).then((data) => {
                let dataToSet = { ...data }
                if (dataToSet.page || dataToSet.items || dataToSet.total) {
                    dataToSet.loading = null;
                    // console.log(dataToSet.page || dataToSet.items || dataToSet.total)
                    let checkFilter = '';
                    if (dataToSet.resultFilter) {
                        checkFilter = String(dataToSet.resultFilter)
                        delete dataToSet.resultFilter;
                    }
                    let search = this.props[0].location.search;
                    let serachParams = getSearchParams(search);
                    let searchParamsFilter = '';
                    if (serachParams.filter) {
                        searchParamsFilter = serachParams.filter;
                    }
                    if (searchParamsFilter != checkFilter) {
                        delete dataToSet.page;
                        delete dataToSet.items;
                        delete dataToSet.total;
                        delete dataToSet.loading;
                    }
                }
                this.setState({
                    ...dataToSet,
                    // loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }



    }

    componentDidMount() {
        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }

        let selectedGroupIdx = -1;
        if (this.props.uData) {
            if (this.props.selectedGroup && this.props.uData.clinicGroups) {
                for (let i = 0; i < this.props.uData.clinicGroups.length; i++) {
                    if (this.props.uData.clinicGroups[i]._id == this.props.selectedGroup) {
                        selectedGroupIdx = i;
                        break;
                    }
                }
            }
        }
        if (this.props.uData && this.props.uData.clinicGroups && this.props.uData.clinicGroups[selectedGroupIdx]) {
            let paysonExists = false;
            let swishExists = false;

            if (this.props.uData.clinicGroups[selectedGroupIdx].agentId && this.props.uData.clinicGroups[selectedGroupIdx].apiKey) {
                paysonExists = true;
            }
            if (this.props.uData.clinicGroups[selectedGroupIdx].bankAccount) {
                swishExists = true;
            }
            this.setState({ onlinePaymentVisible: paysonExists || swishExists ? true : false })
        }


        this.get();
    }

    componentDidUpdate(prevProps) {
        if (prevProps[0].location.search != this.props[0].location.search) {
            this.setState({
                loading: true
            }, () => {
                this.get();

            })
        }

        if (prevProps.selectedGroup != this.props.selectedGroup) {
            this.setState({
                loading: true
            }, () => {
                this.get();

            })

        }

        if (prevProps.price != this.props.price || prevProps.onlinePayment != this.props.onlinePayment || prevProps.invoice != this.props.invoice || prevProps.paymentOnSite != this.props.paymentOnSite) {
            if (this.props.price == 0) {
                this.props.changeOnlinePayment(false)
                this.props.changeInvoice(false)
                this.props.changePaymentOnSite(false)
                this.props.changeFreeCard(false)
                this.props.changeUnder18(false)
                this.props.changeUnder20(false)
                this.props.changeUnder23(false)
                this.props.changeUnder18child(false)
                this.props.changeOlder85(false)
                this.props.changeVatRate(-1)
                this.props.changeSelectVatRate(false)
            } else if (this.props.price > 0) {
                if (!this.props.onlinePayment && !this.props.invoice && !this.props.paymentOnSite && prevProps.onlinePayment) {
                    this.props.changeOnlinePayment(true)
                } else if (!this.props.onlinePayment && !this.props.invoice && !this.props.paymentOnSite && prevProps.invoice) {
                    this.props.changeInvoice(true)
                } else if (!this.props.onlinePayment && !this.props.invoice && !this.props.paymentOnSite && prevProps.paymentOnSite && !this.props.onlineVisit) {
                    this.props.changePaymentOnSite(true)
                } else if (!this.props.onlinePayment && !this.props.invoice && !this.props.paymentOnSite && prevProps.paymentOnSite && this.props.onlineVisit) {
                    if (this.state.onlinePaymentVisible)
                        this.props.changeOnlinePayment(true)
                    else
                        this.props.changeInvoice(true)
                } else if (!this.props.onlinePayment && !this.props.invoice && !this.props.paymentOnSite) {
                    if (this.state.onlinePaymentVisible)
                        this.props.changeOnlinePayment(true)
                    else
                        this.props.changeInvoice(true)
                }


            }

        }
        if (prevProps.selectVatRate !== this.props.selectVatRate && !this.props.selectVatRate) {
            this.props.changeVatRate(-1)
        }
        if (prevProps.onlineVisit != this.props.onlineVisit && this.props.onlineVisit) {
            this.props.changePaymentOnSite(false)
        }
        if (prevProps.onlineVisit != this.props.onlineVisit && !this.props.onlineVisit) {
            this.props.changeVideoCall(false)
        }
        if (prevProps.uData != this.props.uData && prevProps.uData.clinicGroups != this.props.uData.clinicGroups) {
            let selectedGroupIdx = -1;
            if (this.props.uData) {
                if (this.props.selectedGroup && this.props.uData?.clinicGroups?.length) {
                    for (let i = 0; i < this.props.uData.clinicGroups.length; i++) {
                        if (this.props.uData.clinicGroups[i]._id == this.props.selectedGroup) {
                            selectedGroupIdx = i;
                            break;
                        }
                    }
                }
            }
            if (prevProps.uData.clinicGroups[selectedGroupIdx] != this.props.uData.clinicGroups[selectedGroupIdx]) {
                if (this.props.uData && this.props.uData.clinicGroups && this.props.uData.clinicGroups[selectedGroupIdx]) {
                    let paysonExists = false;
                    let swishExists = false;

                    if (this.props.uData.clinicGroups[selectedGroupIdx].agentId && this.props.uData.clinicGroups[selectedGroupIdx].apiKey) {
                        paysonExists = true;
                    }
                    if (this.props.uData.clinicGroups[selectedGroupIdx].bankAccount) {
                        swishExists = true;
                    }
                    this.setState({ onlinePaymentVisible: paysonExists || swishExists ? true : false })
                }
            }

        }


    }



    updateSort = (field, type) => {
        if (this.state.useState) {
            let state = this.state.params;
            state.sortField = field;
            state.sortType = type;
            this.setState({
                params: state
            })
        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0 }, [{ name: 'sortField', value: field }, { name: 'sortType', value: type }], false));

        }
    }

    updateParams = (name, value, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };
                obj[name] = value;
                this.setState({ params: obj });
            } else {
                let obj = this.state.params;
                obj[name] = value;
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(generateSearchLink(this.props[0].location.search, { entries: 10, page: 0 }, name, value, restart));
        }
    }
    updateMultipleParams = (fields, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj });

            } else {
                let obj = this.state.params;
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0 }, fields, restart));
        }
    }


    /**
    * Insert or update user
    * @author   Milan Stanojevic
    * @Objectparam    {String} clinic                   clinic id
    * @Objectparam    {String} group                    group id
    * @Objectparam    {Object} groupPermissions         groupPermissions for user
    * @Objectparam    {Object} data                     form data
    */
    insertOrUpdate = (data) => {
        let vatObj = {}
        let selectedGroupIdx = 0;
        if (this.props.selectedGroup && this.props.uData?.clinicGroups?.length) {
            for (let i = 0; i < this.props.uData.clinicGroups.length; i++) {
                if (this.props.uData.clinicGroups[i]._id == this.props.selectedGroup) {
                    selectedGroupIdx = i;
                    break;
                }
            }
        }
        let canTransferPayments = this.props.uData && this.props.uData.clinicGroups && this.props.uData.clinicGroups[selectedGroupIdx] && this.props.uData.clinicGroups[selectedGroupIdx].canTransferPayments;
        if (canTransferPayments) {
            vatObj = {
                vatRate: data.vatRate,
                selectVatRate: data.selectVatRate
            }
        } else {
            vatObj = {
                vatRate: -1,
                selectVatRate: false
            }
        }
        data = { ...data, ...vatObj }

        this.setState({
            loading: true
        }, () => {
            if (!this.state.form._id) {

                fetch(API_ENDPOINT + '/users/price-list/new', {
                    method: 'PUT',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({ ...data, group: this.props.selectedGroup, clinic: this.props.selectedClinic })
                }).then(parseJSON).then(({ result, status }) => {
                    if (result.error) {
                        this.setState({ error: result.error })
                    } else {
                        this.get();
                        this.props.verifyUser();
                        this.setState({
                            form: null,
                        })
                    }

                })
            } else {
                fetch(API_ENDPOINT + '/users/price-list/' + this.state.form._id, {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({ ...data, group: this.props.selectedGroup, clinic: this.props.selectedClinic })
                }).then(parseJSON).then(({ result, status }) => {
                    if (result.error) {
                        this.setState({ error: result.error })
                    } else {
                        this.get();
                        this.props.verifyUser();
                        this.setState({
                            form: null,
                        })
                    }

                })
            }
        })
    }

    /**
    * Delete user
    * @author   Milan Stanojevic
    * @Objectparam    {String} id                   user id
    */
    delete = (data) => {
        this.setState({
            loading: true
        }, () => {

            fetch(API_ENDPOINT + '/users/price-list/' + data._id, {
                method: 'DELETE',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                }

            }).then(parseJSON).then(({ result, status }) => {
                if (result.error) {
                    this.setState({ error: result.error })
                }
                this.get();
            })
        })

    }

    render() {
        let params = {};
        if (this.state.useState) {
            params = this.state.params;
        } else {
            params = getSearchParams(this.props[0].location.search, { entries: 10, page: 0 })
        }

        let selectedGroupIdx = 0;
        if (this.props.selectedGroup && this.props.uData?.clinicGroups?.length) {
            for (let i = 0; i < this.props.uData.clinicGroups.length; i++) {
                if (this.props.uData.clinicGroups[i]._id == this.props.selectedGroup) {
                    selectedGroupIdx = i;
                    break;
                }
            }
        }

        let enterprise = this.props.uData && this.props.uData.clinicGroups && this.props.uData.clinicGroups[selectedGroupIdx] && this.props.uData.clinicGroups[selectedGroupIdx].enterprise;
        let canTransferPayments = this.props.uData && this.props.uData.clinicGroups && this.props.uData.clinicGroups[selectedGroupIdx] && this.props.uData.clinicGroups[selectedGroupIdx].canTransferPayments;

        let fields = [];
        if (enterprise) {
            fields = [
                { type: 'text', name: 'name', label: 'Name'.translate(this.props.lang), allowSort: true },
                { type: 'text', name: 'description', label: 'Description'.translate(this.props.lang), allowSort: true },
                { type: 'text', name: 'defaultForm', label: 'Default form'.translate(this.props.lang), allowSort: true },
            ];
        } else {
            fields = [
                { type: 'text', name: 'name', label: 'Name'.translate(this.props.lang), allowSort: true },
                { type: 'text', name: 'description', label: 'Description'.translate(this.props.lang), allowSort: true },
            ];
        }
        return (
            <div className={this.props.autoHideSidebar ? "dashboard dashboard-auto-hide" : "dashboard"}>

                <Container fluid>
                    <Row>
                        <Col lg="12">
                            <div className="panel">

                                <div className="panel-header">
                                    <h5 className="component-header">{this.props.linksMeta && this.props.linksMeta[this.props[0].location.pathname] ? this.props.linksMeta[this.props[0].location.pathname][this.props.lang] : ''}
                                    </h5>
                                    <button className="btn add-button" onClick={() => this.setState({
                                        form: {
                                            price: '0'
                                        },
                                    })}><Isvg src={addIcon} /> {'Add'.translate(this.props.lang)}</button>
                                </div>
                                <ListBuilder
                                    lang={this.props.lang}
                                    loading={this.state.loading}
                                    total={this.state.total}
                                    showNumeration={false}

                                    fields={fields}
                                    items={this.state.items.map(item => {
                                        return {
                                            ...item,
                                            defaultForm: item.defaultForm && this.state.revisitForms.filter(el => el._id === item.defaultForm).length ? this.state.revisitForms.filter(el => el._id === item.defaultForm)[0].name : null
                                            // name: this.props.formatName(item.userData.title, item.userData.name),

                                        }
                                    })}
                                    actions={
                                        [

                                            {
                                                component: <div className="tabel-action-tooltip"> <Isvg src={edit} /> <div className="chat-icons-tooltip">{'Edit'.translate(this.props.lang)}</div> </div>,
                                                onClick: (item) => {
                                                    let obj = item;
                                                    if (obj.price == 0) {
                                                        obj.price = '0'
                                                    }
                                                    this.setState({ form: obj })
                                                }

                                            },
                                            {
                                                component: <div className="tabel-action-tooltip"> <Isvg src={garabage} /> <div className="chat-icons-tooltip">{'Delete'.translate(this.props.lang)}</div> </div>,
                                                onClick: (item) => this.setState({ deleteModal: item })
                                            },
                                        ]
                                    }
                                    rawItems={this.state.items}
                                    params={params}
                                    sortField={params.sortField}
                                    sortType={params.sortType}
                                    updateSort={this.updateSort}
                                    updateParams={this.updateParams}
                                    updateMultipleParams={this.updateMultipleParams}
                                ></ListBuilder>
                                <DeleteModal
                                    lang={this.props.lang}
                                    isOpen={this.state.deleteModal}
                                    toggle={() => this.setState({ deleteModal: null })}
                                    handler={() => { this.delete(this.state.deleteModal); this.setState({ deleteModal: null }) }}
                                >
                                    {'Delete price list'.translate(this.props.lang)} <strong>{this.state.deleteModal && this.state.deleteModal.name}</strong>?
                                </DeleteModal>
                                {this.state.error ?

                                    <ErrorModal lang={this.props.lang}
                                        isOpen={this.state.error}
                                        toggle={() => this.setState({ error: null })}

                                    >
                                        {this.state.error.translate(this.props.lang)}
                                    </ErrorModal>

                                    :
                                    null
                                }
                                {this.state.errorAcitveReferrals ?
                                    <Modal isOpen={this.state.errorAcitveReferrals} centered>
                                        {/* <ModalHeader style={{ margin: 'auto' }}>{'Error'.translate(this.props.lang)}</ModalHeader> */}
                                        <ModalBody className="delete-modal">
                                            {/* <img src={warrning} /> */}
                                            <div className="message">
                                                {this.state.errorAcitveReferrals.translate(this.props.lang)}
                                                <FormGroup style={{ marginTop: 20, display: 'flex', justifyContent: 'center' }}>
                                                    <div>
                                                        <Label>{'Transfer to'.translate(this.props.lang)}</Label>
                                                        <Input type='select' value={this.state.transferToUser}
                                                            style={{ width: 300 }}
                                                            onChange={(e) => {

                                                                this.setState({ transferToUser: e.target.value })
                                                            }}>
                                                            <option value={null}>{'None'.translate(this.props.lang)}</option>

                                                            {
                                                                this.state.items && this.state.items.map((item, idx) => {
                                                                    if (item._id != this.state.userChecked._id)
                                                                        return (
                                                                            <option value={item._id}>{this.props.formatName(item.userData.title, item.userData.name)}</option>
                                                                        )
                                                                })
                                                            }
                                                        </Input>
                                                    </div>
                                                </FormGroup>
                                            </div>
                                        </ModalBody>
                                        <ModalFooter style={{ display: 'flex', justifyContent: 'center' }}>
                                            <Button style={{ minWidth: 100 }} disabled={!this.state.transferToUser || this.state.transferToUser == 'None' ? true : false} onClick={() => this.checkTransfer()} >{'Ok'.translate(this.props.lang)}</Button>{' '}

                                            <Button style={{ minWidth: 100 }} type="submit" color="primary" onClick={() => this.setState({ errorAcitveReferrals: null, transferToUser: null, userChecked: null })} >{'Cancel'.translate(this.props.lang)}</Button>{' '}
                                        </ModalFooter>
                                    </Modal>

                                    :
                                    null
                                }



                            </div>
                        </Col>
                    </Row>

                </Container>
                {this.state.form ?
                    <FormBuilder selectedGroup={this.props.selectedGroup} selectedClinic={this.props.selectedClinic} countryTwoLetter={this.props.countryTwoLetter} country={this.props.country} dateFormat={this.props.dateFormat}
                        getStringDateTs={this.props.getStringDateTs}
                        lang={this.props.lang}
                        onSubmit={(data) => {
                            this.insertOrUpdate(data)
                        }
                        }
                        initialValues={this.state.form}
                        size="lg"
                        isOpen={this.state.form}
                        toggle={() => this.setState({ form: null, doctorPermissions: false })}
                        title={this.state.form._id ? 'Modify item'.translate(this.props.lang) : 'Add new item'.translate(this.props.lang)}
                        buttonText={'Save'.translate(this.props.lang).translate(this.props.lang)}
                        disabledCreatedInfo={true}
                        // additionalButton={
                        //     <Button style={{ marginLeft: 10 }} onClick={() => this.setState({ form: null })} color="primary">{'Cancel'.translate(this.props.lang)}</Button>

                        // }
                        fields={[
                            {
                                type: 'row',
                                children: [
                                    {
                                        type: 'col',
                                        width: {
                                            lg: 6,
                                            sm: 12,
                                            xs: 12
                                        },
                                        children: [
                                            {
                                                type: 'col',
                                                width: {
                                                    lg: 12,
                                                    sm: 12,
                                                    xs: 12
                                                },
                                                children: [
                                                    {
                                                        type: 'text',
                                                        name: 'name',
                                                        label: 'Name'.translate(this.props.lang),
                                                        validate: [required('Name is required!'.translate(this.props.lang))]

                                                    },
                                                ]
                                            },
                                            {
                                                type: 'col',
                                                width: {
                                                    lg: 12,
                                                    sm: 12,
                                                    xs: 12
                                                },
                                                children: [
                                                    {
                                                        type: 'textarea',
                                                        name: 'description',
                                                        label: 'Description'.translate(this.props.lang),
                                                        // validate: [required('Description is required!'.translate(this.props.lang))]
                                                    },
                                                ]
                                            },
                                            {
                                                type: 'col',
                                                width: {
                                                    lg: 12,
                                                    sm: 12,
                                                    xs: 12
                                                },
                                                children: [
                                                    {
                                                        type: 'number',
                                                        name: 'price',
                                                        minValue: '0',
                                                        label: 'Price'.translate(this.props.lang),
                                                        validate: [required('Price is required!'.translate(this.props.lang))]

                                                    },
                                                ]
                                            },
                                            canTransferPayments ?
                                                {
                                                    type: 'col',
                                                    width: {
                                                        lg: 12,
                                                        sm: 12,
                                                        xs: 12
                                                    },
                                                    children: [
                                                        {
                                                            type: 'checkbox',
                                                            name: 'selectVatRate',
                                                            label: 'Select VAT rate'.translate(this.props.lang),
                                                            labelInfo: 'If you do not select a VAT rate, the fee is VAT-free'.translate(this.props.lang),
                                                            disabled: this.props.price == 0 ? true : false
                                                        },
                                                    ]
                                                } : null,
                                            canTransferPayments && this.props.selectVatRate ?
                                                {
                                                    type: 'col',
                                                    width: {
                                                        lg: 12,
                                                        sm: 12,
                                                        xs: 12
                                                    },
                                                    children: [
                                                        {
                                                            type: 'select',
                                                            name: 'vatRate',
                                                            // label: 'Default form'.translate(this.props.lang),
                                                            values: [
                                                                { name: 'VAT-free'.translate(this.props.lang), value: -1 },
                                                                { name: '6%'.translate(this.props.lang), value: 6 },
                                                                { name: '12%'.translate(this.props.lang), value: 12 },
                                                                { name: '25%'.translate(this.props.lang), value: 25 }
                                                            ],

                                                        },
                                                    ]
                                                }
                                                :
                                                null,
                                            {
                                                type: 'col',
                                                width: {
                                                    lg: 12,
                                                    sm: 12,
                                                    xs: 12
                                                },
                                                children: [
                                                    {
                                                        type: 'checkbox',
                                                        name: 'onlineVisit',
                                                        label: 'Online visit'.translate(this.props.lang),
                                                        // validate: [required('Online visit is required!'.translate(this.props.lang))]

                                                    },
                                                ]
                                            },
                                            this.props.onlineVisit ?
                                                {
                                                    type: 'col',
                                                    width: {
                                                        lg: 12,
                                                        sm: 12,
                                                        xs: 12
                                                    },
                                                    children: [
                                                        {
                                                            type: 'checkbox',
                                                            name: 'videoCall',
                                                            label: 'Video call'.translate(this.props.lang),
                                                            // validate: [required('Online visit is required!'.translate(this.props.lang))]

                                                        },
                                                    ]
                                                }
                                                :
                                                null,
                                            enterprise ?
                                                {
                                                    type: 'col',
                                                    width: {
                                                        lg: 12,
                                                        sm: 12,
                                                        xs: 12
                                                    },
                                                    children: [
                                                        {
                                                            type: 'select',
                                                            name: 'defaultForm',
                                                            label: 'Default form'.translate(this.props.lang),
                                                            values: [{ name: 'Select'.translate(this.props.lang), value: -1 }, ...this.state.revisitForms.map((item, idx) => { return { name: item.name, value: item._id } })],

                                                        },
                                                    ]
                                                } : null,
                                        ]
                                    },

                                    {
                                        type: 'col',
                                        width: {
                                            lg: 6,
                                            sm: 12,
                                            xs: 12
                                        },
                                        children: [
                                            {
                                                type: 'col',
                                                width: {
                                                    lg: 12,
                                                    sm: 12,
                                                    xs: 12
                                                },
                                                children: [
                                                    {
                                                        type: 'h4',
                                                        text: 'Payment types'.translate(this.props.lang),

                                                    },
                                                ]
                                            },
                                            this.state.onlinePaymentVisible ?
                                                {
                                                    type: 'col',
                                                    width: {
                                                        lg: 12,
                                                        sm: 12,
                                                        xs: 12
                                                    },
                                                    children: [
                                                        {
                                                            type: 'checkbox',
                                                            name: 'onlinePayment',
                                                            label: 'Online payment'.translate(this.props.lang),
                                                            disabled: this.props.price == 0 ? true : false
                                                        },
                                                    ]
                                                }
                                                :
                                                {
                                                    type: 'empty'
                                                },
                                            {
                                                type: 'col',
                                                width: {
                                                    lg: 12,
                                                    sm: 12,
                                                    xs: 12
                                                },
                                                children: [
                                                    {
                                                        type: 'checkbox',
                                                        name: 'invoice',
                                                        label: 'Invoice'.translate(this.props.lang),
                                                        disabled: this.props.price == 0 ? true : false
                                                    },
                                                ]
                                            },
                                            {
                                                type: 'col',
                                                width: {
                                                    lg: 12,
                                                    sm: 12,
                                                    xs: 12
                                                },
                                                children: [
                                                    {
                                                        type: 'checkbox',
                                                        name: 'paymentOnSite',
                                                        label: 'Payment on site'.translate(this.props.lang),
                                                        disabled: this.props.price == 0 || this.props.onlineVisit ? true : false

                                                    },
                                                ]
                                            },
                                            {
                                                type: 'col',
                                                width: {
                                                    lg: 12,
                                                    sm: 12,
                                                    xs: 12
                                                },
                                                children: [
                                                    {
                                                        type: 'h4',
                                                        text: 'Payment options'.translate(this.props.lang),

                                                    },
                                                ]
                                            },
                                            {
                                                type: 'col',
                                                width: {
                                                    lg: 12,
                                                    sm: 12,
                                                    xs: 12
                                                },
                                                children: [
                                                    {
                                                        type: 'checkbox',
                                                        name: 'freeCard',
                                                        label: 'Free card'.translate(this.props.lang),
                                                        disabled: this.props.price == 0 ? true : false
                                                    },
                                                ]
                                            },
                                            {
                                                type: 'col',
                                                width: {
                                                    lg: 12,
                                                    sm: 12,
                                                    xs: 12
                                                },
                                                children: [
                                                    {
                                                        type: 'checkbox',
                                                        name: 'under18',
                                                        label: 'Under 18 years old'.translate(this.props.lang),
                                                        disabled: this.props.price == 0 ? true : false
                                                    },
                                                ]
                                            },
                                            {
                                                type: 'col',
                                                width: {
                                                    lg: 12,
                                                    sm: 12,
                                                    xs: 12
                                                },
                                                children: [
                                                    {
                                                        type: 'checkbox',
                                                        name: 'under20',
                                                        label: 'Under 20 years old'.translate(this.props.lang),
                                                        disabled: this.props.price == 0 ? true : false
                                                    },
                                                ]
                                            },
                                            {
                                                type: 'col',
                                                width: {
                                                    lg: 12,
                                                    sm: 12,
                                                    xs: 12
                                                },
                                                children: [
                                                    {
                                                        type: 'checkbox',
                                                        name: 'under23',
                                                        label: 'Under 23 years old'.translate(this.props.lang),
                                                        disabled: this.props.price == 0 ? true : false
                                                    },
                                                ]
                                            },
                                            {
                                                type: 'col',
                                                width: {
                                                    lg: 12,
                                                    sm: 12,
                                                    xs: 12
                                                },
                                                children: [
                                                    {
                                                        type: 'checkbox',
                                                        name: 'under18child',
                                                        label: 'Child under 18 years old'.translate(this.props.lang),
                                                        disabled: this.props.price == 0 ? true : false
                                                    },
                                                ]
                                            },
                                            {
                                                type: 'col',
                                                width: {
                                                    lg: 12,
                                                    sm: 12,
                                                    xs: 12
                                                },
                                                children: [
                                                    {
                                                        type: 'checkbox',
                                                        name: 'older85',
                                                        label: '85 years or older'.translate(this.props.lang),
                                                        disabled: this.props.price == 0 ? true : false
                                                    },
                                                ]
                                            },


                                        ]
                                    },

                                ]
                            }
                        ]}

                    ></FormBuilder>
                    :
                    null
                }


            </div>
        );
    }
}


const selector = formValueSelector('modalForm');

export default connect(state => {
    return {
        price: selector(state, 'price'),
        onlineVisit: selector(state, 'onlineVisit'),
        onlinePayment: selector(state, 'onlinePayment'),
        invoice: selector(state, 'invoice'),
        paymentOnSite: selector(state, 'paymentOnSite'),
        freeCard: selector(state, 'freeCard'),
        under18: selector(state, 'under18'),
        under20: selector(state, 'under20'),
        under23: selector(state, 'under23'),
        under18child: selector(state, 'under18child'),
        older85: selector(state, 'older85'),
        selectVatRate: selector(state, 'selectVatRate'),
        vatRate: selector(state, 'vatRate')


    }


}, {
    changeOnlinePayment: value => change("modalForm", "onlinePayment", value),
    changeInvoice: value => change("modalForm", "invoice", value),
    changePaymentOnSite: value => change("modalForm", "paymentOnSite", value),
    changeFreeCard: value => change("modalForm", "freeCard", value),
    changeUnder18: value => change("modalForm", "under18", value),
    changeUnder20: value => change("modalForm", "under20", value),
    changeUnder23: value => change("modalForm", "under23", value),
    changeUnder18child: value => change("modalForm", "under18child", value),
    changeOlder85: value => change("modalForm", "older85", value),
    changeSelectVatRate: value => change("modalForm", "selectVatRate", value),
    changeVatRate: value => change("modalForm", "vatRate", value),
    changeVideoCall: value => change("modalForm", "videoCall", value)

})(Page(SubAccounts));