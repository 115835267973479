import React, { Component } from 'react';
import Page from '../../containers/page';

import {
    Container,
    Row,
    Col,
    Modal, ModalHeader, ModalBody, ModalFooter, Button
} from 'reactstrap';

import Isvg from 'react-inlinesvg'
import addIcon from '../../assets/svg/add-icon.svg';
import edit from '../../assets/svg/edit.svg'
import garabage from '../../assets/svg/garbage.svg'
import view from '../../assets/svg/eye.svg'
import ListBuilder from '../../components/listBuilder';
import DeleteModal from '../../components/deleteModal';
import { getSearchParams, generateSearchLink, generateSearchLinkMultiple } from '../../helpers/linkHelper';
import { API_ENDPOINT } from '../../constants';
import { formValueSelector, change } from 'redux-form';  // ES6
import { connect } from 'react-redux';
import Label from 'reactstrap/lib/Label';
import Input from 'reactstrap/lib/Input';
import FormGroup from 'reactstrap/lib/FormGroup';
import Checkbox from '../../components/forms/fields/checkbox';
import xIcon from '../../assets/svg/x.svg';

/**
* Support category page
* @author   Stefan Pantic
*/
class SupportCategory extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props.initialData,
            form: false,
            useState: false,
            params: {
                entries: 10,
                page: 0
            },
            items: [],
            groups: [],
            subgroups: [],
            total: 0,
            loading: true,
            category: {},
            conclusions: {},
            criticality: {},
            tabIndex: 1,
            conclusionsItems: [],
            criticalityItems: []

        };
    }


    get = () => {
        this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));

        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0, group: this.props.selectedGroup, clinic: this.props.selectedClinic })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

        for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
            this.props.loadDataWithQuery[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0, group: this.props.selectedGroup, clinic: this.props.selectedClinic })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

    }

    componentDidMount() {
        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }

        this.get();
    }

    componentDidUpdate(prevProps) {
        if (prevProps[0].location.search != this.props[0].location.search) {
            this.setState({
                loading: true
            }, () => {
                this.get();

            })
        }

        if (prevProps.selectedGroup != this.props.selectedGroup) {
            this.get();
        }

        if (prevProps.selectedClinic != this.props.selectedClinic) {
            this.get();
        }

    }


    updateSort = (field, type) => {
        if (this.state.useState) {
            let state = this.state.params;
            state.sortField = field;
            state.sortType = type;
            this.setState({
                params: state
            })
        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0, group: this.props.selectedGroup, clinic: this.props.selectedClinic }, [{ name: 'sortField', value: field }, { name: 'sortType', value: type }], false));

        }
    }

    updateParams = (name, value, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };
                obj[name] = value;
                this.setState({ params: obj });
            } else {
                let obj = this.state.params;
                obj[name] = value;
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(generateSearchLink(this.props[0].location.search, { entries: 10, page: 0, group: this.props.selectedGroup, clinic: this.props.selectedClinic }, name, value, restart));
        }
    }
    updateMultipleParams = (fields, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj });

            } else {
                let obj = this.state.params;
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0, group: this.props.selectedGroup, clinic: this.props.selectedClinic }, fields, restart));
        }
    }


    /**
* Create new support category
* @author   Stefan Pantic
* @Objectparam    {Object} data         support category data
*/
    save = (data) => {

        this.setState({
            loading: true
        }, () => {
            fetch(API_ENDPOINT + '/support-category/new', {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify(data)
            }).then(res => res.json()).then((result) => {
                this.get();
                this.setState({
                    categoryModal: null,
                    category: {}

                })
            })

        })
    }

    /**
    * Update support category
    * @author   Stefan Pantic
    * @Objectparam    {String} data.id      support category id
    * @Objectparam    {Object} data         support category data
    */
    update = (data) => {
        this.setState({
            loading: true
        }, () => {
            fetch(API_ENDPOINT + '/support-category/update/' + data._id, {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify(data)
            }).then(res => res.json()).then((result) => {
                this.get();
                this.setState({
                    categoryUpdate: null
                })
            })

        })
    }


    /**
    * Delete support category
    * @author   Stefan Pantic
    * @Objectparam    {String} id         support category id
    */
    delete = (id) => {
        this.setState({
            loading: true
        }, () => {

            fetch(API_ENDPOINT + '/support-category/delete/' + id, {
                method: 'DELETE',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
            }).then(res => res.json()).then((result) => {
                this.get();
            })
        })

    }


    /**
* Create new support conclusions
* @author   Pantelija Simeunovic
* @Objectparam    {Object} data         support conclusions data
*/
    saveConclusions = (data) => {
        console.log(data);
        this.setState({
            loading: true
        }, () => {
            fetch(API_ENDPOINT + '/support-conclusions/new', {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify(data)
            }).then(res => res.json()).then((result) => {
                this.get();
                this.setState({
                    conclusionsModal: null,
                    conclusions: {}

                })
            })

        })
    }

    saveCriticality = (data) => {
        console.log(data);
        this.setState({
            loading: true
        }, () => {
            fetch(API_ENDPOINT + '/support-criticality/new', {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify(data)
            }).then(res => res.json()).then((result) => {
                this.get();
                this.setState({
                    criticalityModal: null,
                    criticality: {}

                })
            })

        })
    }

    /**
    * Update support conclusions
    * @author   Pantelija Simeunovic
    * @Objectparam    {String} data.id      support conclusions id
    * @Objectparam    {Object} data         support conclusions data
    */
    updateConclusions = (data) => {
        this.setState({
            loading: true
        }, () => {
            fetch(API_ENDPOINT + '/support-conclusions/update/' + data._id, {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify(data)
            }).then(res => res.json()).then((result) => {
                this.get();
                this.setState({
                    conclusionsModal: null,
                    conclusionsUpdate: null
                })
            })

        })
    }

    updateCriticality = (data) => {
        this.setState({
            loading: true
        }, () => {
            fetch(API_ENDPOINT + '/support-criticality/update/' + data._id, {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify(data)
            }).then(res => res.json()).then((result) => {
                this.get();
                this.setState({
                    criticalityModal: null,
                    criticalityUpdate: null
                })
            })

        })
    }


    /**
    * Delete support conclusions
    * @author   Pantelija Simeunovic
    * @Objectparam    {String} id         support conclusions id
    */
    deleteConclusions = (id) => {
        this.setState({
            loading: true
        }, () => {

            fetch(API_ENDPOINT + '/support-conclusions/delete/' + id, {
                method: 'DELETE',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
            }).then(res => res.json()).then((result) => {
                this.get();
            })
        })

    }

    deleteCriticality = (id) => {
        this.setState({
            loading: true
        }, () => {

            fetch(API_ENDPOINT + '/support-criticality/delete/' + id, {
                method: 'DELETE',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
            }).then(res => res.json()).then((result) => {
                this.get();
            })
        })

    }

    render() {
        let params = {};
        if (this.state.useState) {
            params = this.state.params;
        } else {
            params = getSearchParams(this.props[0].location.search, { entries: 10, page: 0, group: this.props.selectedGroup, clinic: this.props.selectedClinic })
        }


        let subgroups = [{ name: 'None', value: null }];

        if (this.props.group) {
            for (let i = 0; i < this.state.subgroups.length; i++) {
                if (this.state.subgroups[i].groups && this.state.subgroups[i].groups.indexOf(this.props.group) !== -1) {
                    subgroups.push(this.state.subgroups[i]);
                }
            }
        }

        return (
            <div className={this.props.autoHideSidebar ? "dashboard dashboard-auto-hide" : "dashboard"}>

                <Container fluid>
                    <Row>
                        <div className='panel'>
                            <h5 className="component-header mb-35">{this.props.linksMeta && this.props.linksMeta[this.props[0].location.pathname] ? this.props.linksMeta[this.props[0].location.pathname][this.props.lang] : ''}</h5>
                            <Col lg="12" className='col-no-padding'>
                                <div className="tabs-buttons-wrap">
                                    <Button className={this.state.tabIndex == 1 ? "tab-button acitve-tab-button" : "tab-button"} onClick={() => this.setState({ tabIndex: 1, status: false }, () => this.get())}>{"Support categories".translate(this.props.lang)}</Button>

                                    <Button className={this.state.tabIndex == 2 ? "tab-button acitve-tab-button" : "tab-button"} onClick={() => this.setState({ tabIndex: 2, status: 'denied' }, () => this.get())}>{"Support conclusions".translate(this.props.lang)}</Button>

                                    <Button className={this.state.tabIndex == 3 ? "tab-button acitve-tab-button" : "tab-button"} onClick={() => this.setState({ tabIndex: 3, status: 'denied' }, () => this.get())}>{"Criticality".translate(this.props.lang)}</Button>

                                </div>

                            </Col>
                            {this.state.tabIndex == 1 ?

                                <Col lg="12">
                                    <div className="panel-header">
                                        <h5 className="component-header">{'Support categories'.translate(this.props.lang)}</h5>
                                        <button className="btn add-button" onClick={() => {
                                            this.setState({ categoryModal: true })
                                        }}><Isvg src={addIcon} /> {'Add'.translate(this.props.lang)}</button>
                                    </div>

                                    <ListBuilder
                                        lang={this.props.lang}
                                        loading={this.state.loading}
                                        total={this.state.total}
                                        showNumeration={false}
                                        fields={[
                                            { type: 'text', name: 'name', label: 'Name'.translate(this.props.lang), allowSort: true },
                                            { type: 'checkbox', name: 'doctorCategory', label: 'Doctor category'.translate(this.props.lang), allowSort: true },
                                        ]}
                                        items={this.state.items}
                                        actions={
                                            [
                                                {
                                                    component: <Isvg src={edit} />,
                                                    onClick: (item) => {
                                                        this.setState({ categoryUpdate: item })
                                                        console.log(item)
                                                    }
                                                },
                                                {
                                                    component: <Isvg src={garabage} />,
                                                    onClick: (item) => this.setState({ deleteModal: item })
                                                },
                                            ]
                                        }
                                        params={params}
                                        sortField={params.sortField}
                                        sortType={params.sortType}
                                        updateSort={this.updateSort}
                                        updateParams={this.updateParams}
                                        updateMultipleParams={this.updateMultipleParams}

                                    ></ListBuilder>
                                    <DeleteModal
                                        lang={this.props.lang}
                                        isOpen={this.state.deleteModal}
                                        toggle={() => this.setState({ deleteModal: null })}
                                        handler={() => { this.delete(this.state.deleteModal._id); this.setState({ deleteModal: null }) }}
                                    >
                                        {'Delete category'.translate(this.props.lang)} <strong>{this.state.deleteModal && this.state.deleteModal.name}</strong>?
                                    </DeleteModal>
                                </Col>
                                : null}

                            {
                                this.state.tabIndex == 2 ?
                                    <Col lg="12" className='col-no-padding'>
                                        <div className="panel-header">
                                            <h5 className="component-header">{'Support conclusions'.translate(this.props.lang)}</h5>
                                            <button className="btn add-button" onClick={() => {
                                                this.setState({ conclusionsModal: true })
                                            }}><Isvg src={addIcon} /> {'Add'.translate(this.props.lang)}</button>
                                        </div>

                                        <ListBuilder
                                            lang={this.props.lang}
                                            loading={this.state.loading}
                                            total={this.state.total}
                                            showNumeration={false}
                                            fields={[
                                                { type: 'text', name: 'conclusionsEn', label: 'Conclusions EN'.translate(this.props.lang), allowSort: true },
                                                { type: 'text', name: 'conclusionsSe', label: 'Conclusions SE'.translate(this.props.lang), allowSort: true },
                                            ]}
                                            items={this.state.conclusionsItems}
                                            actions={
                                                [
                                                    {
                                                        component: <Isvg src={edit} />,
                                                        onClick: (item) => {
                                                            this.setState({ conclusionsUpdate: item })
                                                            console.log(item)
                                                        }
                                                    },
                                                    {
                                                        component: <Isvg src={garabage} />,
                                                        onClick: (item) => this.setState({ deleteModal: item })
                                                    },
                                                ]
                                            }
                                            params={params}
                                            sortField={params.sortField}
                                            sortType={params.sortType}
                                            updateSort={this.updateSort}
                                            updateParams={this.updateParams}
                                            updateMultipleParams={this.updateMultipleParams}

                                        ></ListBuilder>
                                        <DeleteModal
                                            lang={this.props.lang}
                                            isOpen={this.state.deleteModal}
                                            toggle={() => this.setState({ deleteModal: null })}
                                            handler={() => { this.deleteConclusions(this.state.deleteModal._id); this.setState({ deleteModal: null }) }}
                                        >
                                            {'Delete conclusions'.translate(this.props.lang)} <strong>{this.state.deleteModal && this.state.deleteModal.name}</strong>?
                                        </DeleteModal>


                                    </Col>
                                    :
                                    null
                            }
                            {
                                this.state.tabIndex == 3 ?
                                    <Col lg="12" className='col-no-padding'>
                                        <div className="panel-header">
                                            <h5 className="component-header">{'Criticality'.translate(this.props.lang)}</h5>
                                            <button className="btn add-button" onClick={() => {
                                                this.setState({ criticalityModal: true })
                                            }}><Isvg src={addIcon} /> {'Add'.translate(this.props.lang)}</button>
                                        </div>

                                        <ListBuilder
                                            lang={this.props.lang}
                                            loading={this.state.loading}
                                            total={this.state.total}
                                            showNumeration={false}
                                            fields={[
                                                { type: 'text', name: 'criticalityEn', label: 'Criticality EN'.translate(this.props.lang), allowSort: true },
                                                { type: 'text', name: 'descriptionEn', label: 'Description EN'.translate(this.props.lang), allowSort: true },
                                                { type: 'text', name: 'criticalitySe', label: 'Criticality SE'.translate(this.props.lang), allowSort: true },
                                                { type: 'text', name: 'descriptionSe', label: 'Description SE'.translate(this.props.lang), allowSort: true },
                                            ]}
                                            items={this.state.criticalityItems}
                                            actions={
                                                [
                                                    {
                                                        component: <Isvg src={edit} />,
                                                        onClick: (item) => {
                                                            this.setState({ criticalityUpdate: item })
                                                            console.log(item)
                                                        }
                                                    },
                                                    {
                                                        component: <Isvg src={garabage} />,
                                                        onClick: (item) => this.setState({ deleteModal: item })
                                                    },
                                                ]
                                            }
                                            params={params}
                                            sortField={params.sortField}
                                            sortType={params.sortType}
                                            updateSort={this.updateSort}
                                            updateParams={this.updateParams}
                                            updateMultipleParams={this.updateMultipleParams}

                                        ></ListBuilder>
                                        <DeleteModal
                                            lang={this.props.lang}
                                            isOpen={this.state.deleteModal}
                                            toggle={() => this.setState({ deleteModal: null })}
                                            handler={() => { this.deleteCriticality(this.state.deleteModal._id); this.setState({ deleteModal: null }) }}
                                        >
                                            {'Delete criticality'.translate(this.props.lang)}?
                                        </DeleteModal>


                                    </Col>
                                    :
                                    null
                            }
                        </div>
                        {/* */}
                    </Row>
                </Container>

                {this.state.previewModal ?
                    <Modal isOpen={this.state.previewModal} centered toggle={() => this.setState({ previewModal: !this.state.previewModal })} size="xl">
                        <ModalHeader toggle={() => this.setState({ previewModal: !this.state.previewModal })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ previewModal: !this.state.previewModal })}><Isvg src={xIcon} /></button>}>{'Referral preview'.translate(this.props.lang)}</ModalHeader>

                        <ModalBody>
                            {/* <CreatePersonalReferralModal {...this.props} referral={{ name: this.state.previewModal.name, description: this.state.previewModal.description }} sections={this.generateSections(this.state.previewModal.items)}></CreatePersonalReferralModal> */}
                        </ModalBody>
                    </Modal>
                    :
                    null
                }

                {this.state.categoryModal ?
                    <Modal isOpen={this.state.categoryModal} centered size="lg">
                        <ModalHeader toggle={() => this.setState({ categoryModal: !this.state.categoryModal })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ categoryModal: !this.state.categoryModal })}><Isvg src={xIcon} /></button>}>{'Add category'.translate(this.props.lang)}</ModalHeader>

                        <ModalBody>
                            {/* <CreatePersonalReferralModal {...this.props} referral={{ name: this.state.previewModal.name, description: this.state.previewModal.description }} sections={this.generateSections(this.state.previewModal.items)}></CreatePersonalReferralModal> */}

                            <FormGroup>
                                <Label>{'Name'.translate(this.props.lang)}</Label>
                                <Input type='text' onChange={(e) => {
                                    let category = this.state.category;
                                    category.name = e.target.value;
                                    this.setState({
                                        category
                                    })
                                }} value={this.state.category.name} />
                            </FormGroup>

                            <FormGroup >
                                <Checkbox onChange={(e) => {
                                    let category = this.state.category;
                                    category.doctorCategory = e.target.checked;
                                    this.setState({
                                        category
                                    })
                                }} checked={this.state.category.doctorCategory} label={'Doctor category'.translate(this.props.lang)} />
                            </FormGroup>

                        </ModalBody>
                        <ModalFooter style={{ margin: 'unset', justifyContent: 'flex-end', display: 'flex', width: '100%' }}>
                            <Button color="primary" onClick={() => this.save(this.state.category)}>{'Save'.translate(this.props.lang)}</Button>

                        </ModalFooter>
                    </Modal>
                    :
                    null
                }


                {this.state.conclusionsModal ?
                    <Modal isOpen={this.state.conclusionsModal} centered size="xl">
                        <ModalHeader toggle={() => this.setState({ conclusionsModal: !this.state.conclusionsModal })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ conclusionsModal: !this.state.conclusionsModal })}><Isvg src={xIcon} /></button>}>{'Add conclusions'.translate(this.props.lang)}</ModalHeader>

                        <ModalBody>
                            <FormGroup>
                                <Label>{'Conclusions EN'.translate(this.props.lang)}</Label>
                                <Input type='textarea' onChange={(e) => {
                                    let conclusions = this.state.conclusions;
                                    conclusions.conclusionsEn = e.target.value;
                                    this.setState({
                                        conclusions
                                    })
                                }} value={this.state.conclusions.conclusionsEn} />
                            </FormGroup>

                            <FormGroup>
                                <Label>{'Conclusions SE'.translate(this.props.lang)}</Label>
                                <Input type='textarea' onChange={(e) => {
                                    let conclusions = this.state.conclusions;
                                    conclusions.conclusionsSe = e.target.value;
                                    this.setState({
                                        conclusions
                                    })
                                }} value={this.state.conclusions.conclusionsSe} />
                            </FormGroup>
                        </ModalBody>
                        <ModalFooter style={{ margin: 'unset', justifyContent: 'flex-end', display: 'flex', width: '100%' }}>
                            <Button color="primary" onClick={() => this.saveConclusions(this.state.conclusions)}>{'Save'.translate(this.props.lang)}</Button>

                        </ModalFooter>
                    </Modal>
                    :
                    null
                }
                {this.state.criticalityModal ?
                    <Modal isOpen={this.state.criticalityModal} centered size="xl">
                        <ModalHeader toggle={() => this.setState({ criticalityModal: !this.state.criticalityModal })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ criticalityModal: !this.state.criticalityModal })}><Isvg src={xIcon} /></button>}>{'Add criticality'.translate(this.props.lang)}</ModalHeader>

                        <ModalBody>
                            <FormGroup>
                                <Label>{'Criticality EN'.translate(this.props.lang)}</Label>
                                <Input type='textarea' onChange={(e) => {
                                    let criticality = this.state.criticality;
                                    criticality.criticalityEn = e.target.value;
                                    this.setState({
                                        criticality
                                    })
                                }} value={this.state.criticality.criticalityEn} />
                            </FormGroup>

                            <FormGroup>
                                <Label>{'Description EN'.translate(this.props.lang)}</Label>
                                <Input type='textarea' style={{height: 100}} onChange={(e) => {
                                    let criticality = this.state.criticality;
                                    criticality.descriptionEn = e.target.value;
                                    this.setState({
                                        criticality
                                    })
                                }} value={this.state.criticality.descriptionEn} />
                            </FormGroup>

                            <FormGroup>
                                <Label>{'Criticality SE'.translate(this.props.lang)}</Label>
                                <Input type='textarea' onChange={(e) => {
                                    let criticality = this.state.criticality;
                                    criticality.criticalitySe = e.target.value;
                                    this.setState({
                                        criticality
                                    })
                                }} value={this.state.criticality.criticalitySe} />
                            </FormGroup>

                            <FormGroup>
                                <Label>{'Description SE'.translate(this.props.lang)}</Label>
                                <Input type='textarea' style={{height: 100}} onChange={(e) => {
                                    let criticality = this.state.criticality;
                                    criticality.descriptionSe = e.target.value;
                                    this.setState({
                                        criticality
                                    })
                                }} value={this.state.criticality.descriptionSe} />
                            </FormGroup>
                        </ModalBody>
                        <ModalFooter style={{ margin: 'unset', justifyContent: 'flex-end', display: 'flex', width: '100%' }}>
                            <Button color="primary" onClick={() => this.saveCriticality(this.state.criticality)}>{'Save'.translate(this.props.lang)}</Button>

                        </ModalFooter>
                    </Modal>
                    :
                    null
                }

                {this.state.conclusionsUpdate ?
                    <Modal isOpen={this.state.conclusionsUpdate} centered size="xl">
                        <ModalHeader toggle={() => this.setState({ conclusionsUpdate: !this.state.conclusionsUpdate })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ conclusionsUpdate: !this.state.conclusionsUpdate })}><Isvg src={xIcon} /></button>}>{'Edit conclusions'.translate(this.props.lang)}</ModalHeader>

                        <ModalBody>
                            <FormGroup>
                                <Label>{'Conclusions EN'.translate(this.props.lang)}</Label>
                                <Input type='textarea' onChange={(e) => {
                                    let conclusionsUpdate = this.state.conclusionsUpdate;
                                    conclusionsUpdate.conclusionsEn = e.target.value;
                                    this.setState({
                                        conclusionsUpdate
                                    })
                                }} value={this.state.conclusionsUpdate.conclusionsEn} />
                            </FormGroup>

                            <FormGroup>
                                <Label>{'Conclusions SE'.translate(this.props.lang)}</Label>
                                <Input type='textarea' onChange={(e) => {
                                    let conclusionsUpdate = this.state.conclusionsUpdate;
                                    conclusionsUpdate.conclusionsSe = e.target.value;
                                    this.setState({
                                        conclusionsUpdate
                                    })
                                }} value={this.state.conclusionsUpdate.conclusionsSe} />
                            </FormGroup>

                        </ModalBody>
                        <ModalFooter style={{ margin: 'unset', justifyContent: 'flex-end', display: 'flex', width: '100%' }}>
                            <Button color="primary" onClick={() => this.updateConclusions(this.state.conclusionsUpdate)}>{'Save'.translate(this.props.lang)}</Button>
                        </ModalFooter>
                    </Modal>
                    :
                    null
                }

                {this.state.criticalityUpdate ?
                    <Modal isOpen={this.state.criticalityUpdate} centered size="xl">
                        <ModalHeader toggle={() => this.setState({ criticalityUpdate: !this.state.criticalityUpdate })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ criticalityUpdate: !this.state.criticalityUpdate })}><Isvg src={xIcon} /></button>}>{'Edit criticality'.translate(this.props.lang)}</ModalHeader>

                        <ModalBody>
                            <FormGroup>
                                <Label>{'Criticality EN'.translate(this.props.lang)}</Label>
                                <Input type='textarea' onChange={(e) => {
                                    let criticalityUpdate = this.state.criticalityUpdate;
                                    criticalityUpdate.criticalityEn = e.target.value;
                                    this.setState({
                                        criticalityUpdate
                                    })
                                }} value={this.state.criticalityUpdate.criticalityEn} />
                            </FormGroup>

                            <FormGroup>
                                <Label>{'Description EN'.translate(this.props.lang)}</Label>
                                <Input type='textarea' style={{height: 100}} onChange={(e) => {
                                    let criticalityUpdate = this.state.criticalityUpdate;
                                    criticalityUpdate.descriptionEn = e.target.value;
                                    this.setState({
                                        criticalityUpdate
                                    })
                                }} value={this.state.criticalityUpdate.descriptionEn} />
                            </FormGroup>

                            <FormGroup>
                                <Label>{'Criticality SE'.translate(this.props.lang)}</Label>
                                <Input type='textarea' onChange={(e) => {
                                    let criticalityUpdate = this.state.criticalityUpdate;
                                    criticalityUpdate.criticalitySe = e.target.value;
                                    this.setState({
                                        criticalityUpdate
                                    })
                                }} value={this.state.criticalityUpdate.criticalitySe} />
                            </FormGroup>

                            <FormGroup>
                                <Label>{'Description SE'.translate(this.props.lang)}</Label>
                                <Input type='textarea' style={{height: 100}} onChange={(e) => {
                                    let criticalityUpdate = this.state.criticalityUpdate;
                                    criticalityUpdate.descriptionSe = e.target.value;
                                    this.setState({
                                        criticalityUpdate
                                    })
                                }} value={this.state.criticalityUpdate.descriptionSe} />
                            </FormGroup>

                        </ModalBody>
                        <ModalFooter style={{ margin: 'unset', justifyContent: 'flex-end', display: 'flex', width: '100%' }}>
                            <Button color="primary" onClick={() => this.updateCriticality(this.state.criticalityUpdate)}>{'Save'.translate(this.props.lang)}</Button>
                        </ModalFooter>
                    </Modal>
                    :
                    null
                }


                {this.state.categoryUpdate ?
                    <Modal isOpen={this.state.categoryUpdate} centered size="xl">
                        <ModalHeader toggle={() => this.setState({ categoryUpdate: !this.state.categoryUpdate })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ categoryUpdate: !this.state.categoryUpdate })}><Isvg src={xIcon} /></button>}>{'Edit category'.translate(this.props.lang)}</ModalHeader>

                        <ModalBody>
                            {/* <CreatePersonalReferralModal {...this.props} referral={{ name: this.state.previewModal.name, description: this.state.previewModal.description }} sections={this.generateSections(this.state.previewModal.items)}></CreatePersonalReferralModal> */}

                            <FormGroup>
                                <Label>{'Name'.translate(this.props.lang)}</Label>
                                <Input type='text' onChange={(e) => {
                                    let categoryUpdate = this.state.categoryUpdate;
                                    categoryUpdate.name = e.target.value;
                                    this.setState({
                                        categoryUpdate
                                    })
                                }} value={this.state.categoryUpdate.name} />
                            </FormGroup>

                            <FormGroup>
                                <Checkbox onChange={(e) => {
                                    let categoryUpdate = this.state.categoryUpdate;
                                    categoryUpdate.doctorCategory = e.target.checked;
                                    this.setState({
                                        categoryUpdate
                                    })
                                }} checked={this.state.categoryUpdate.doctorCategory} label={'Doctor category'.translate(this.props.lang)} />
                            </FormGroup>

                        </ModalBody>
                        <ModalFooter style={{ margin: 'unset', justifyContent: 'flex-end', display: 'flex', width: '100%' }}>
                            <Button color="primary" onClick={() => this.update(this.state.categoryUpdate)}>{'Save'.translate(this.props.lang)}</Button>
                        </ModalFooter>
                    </Modal>
                    :
                    null
                }


            </div>
        );
    }
}

const selector = formValueSelector('modalForm');

export default connect(state => {
    return { group: selector(state, 'group') }

}, {
    changeSubgroup: value => change("modalForm", "subgroup", value),

})(Page(SupportCategory));