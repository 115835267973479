import React, { Component } from 'react';
import InlineSVG from 'react-inlinesvg';
import infoIcon from '../assets/svg/info.svg';

export class InfoWithTooltip extends Component {
    constructor(props) {
        super(props)

        this.state = {

        }
    }
    render() {
        return (
            <div className={`custom-tooltip ${this.props.className ? this.props.className : ''}`} style={this.props.style ?? {}}>
                <InlineSVG src={infoIcon} />
                <span className='tooltiptext'>{this.props.text?.translate(this.props.lang)}</span>
            </div>
        )
    }
}

export default InfoWithTooltip