import { faAlignRight } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import Toolbar from 'react-big-calendar/lib/Toolbar';


import {
    Container,
    Row,
    Col,
    Modal, ModalHeader, ModalBody, ModalFooter, Button, FormGroup, Input, Label, Table
} from 'reactstrap';
/**
* React Big Calendar custom toolbar
* @author   Stefan Pantic
*/
export default class CalendarToolbar extends Toolbar {

    componentDidMount() {
        const view = this.props.view;

    }

    render() {
        return (
            <div className="rbc-container">
                <div className="rbc-btn-group rbc-gray" style={{ display: 'flex' }}>
                    <Button type="button" onClick={() => this.navigate('PREV')}>{'Previous'.translate(this.props.lang)}</Button>
                    <Label className="rbc-toolbar-label toolbar-label-small-screen">{this.props.label}</Label>
                    <Button type="button" onClick={() => this.navigate('NEXT')}>{'Next'.translate(this.props.lang)}</Button>
                </div>

                {typeof window != 'undefined' && window.innerWidth < 768 ?
                    <div className="rbc-btn-group">
                    </div >
                    :
                    <div className="rbc-btn-group">
                        {
                            this.props.hideDay ?
                                null
                                :
                                <Button type="button" style={{ margin: 1 }} onClick={this.view.bind(null, 'day')}>{'Day'.translate(this.props.lang)}</Button>

                        }
                        {
                            this.props.hideWorkWeek ?
                                null
                                :
                                <Button type="button" style={{ margin: 1 }} onClick={this.view.bind(null, 'work_week')}>{'Work week'.translate(this.props.lang)}</Button>
                        }
                        {
                            this.props.hideWeek ?
                                null
                                :
                                <Button type="button" style={{ margin: 1 }} onClick={this.view.bind(null, 'week')}>{'Week'.translate(this.props.lang)}</Button>

                        }
                        {
                            this.props.hideDay && this.props.hideWeek && this.props.hideWorkWeek ?
                                null
                                :
                                <Button type="button" style={{ margin: 1 }} onClick={this.view.bind(null, 'month')}>{'Month'.translate(this.props.lang)}</Button>

                        }
                    </div >
                }

                {typeof window != 'undefined' && window.innerWidth < 768 ?
                    null
                    :
                    <div className="rbc-btn-group">
                        {
                            this.props.hideAgenda ?
                                null
                                :
                                <Button type="button" style={{ margin: 2 }} onClick={this.view.bind(null, 'agenda')}> {'Agenda'.translate(this.props.lang)}</Button>
                        }
                    </div>
                }

            </div>
        );
    }
}