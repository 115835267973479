import React, { Component } from 'react';
import Link from '../components/link';

import Isvg from 'react-inlinesvg';
import Page from '../containers/page';

import { API_ENDPOINT } from '../constants';
import { getSearchParams } from '../helpers/linkHelper';

/**
* MediaGateway component
* @author   Milan Stanojevic
*/
class MediaGateway extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    componentDidMount() {
        if (this.props.uData)
            this.form.submit();
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.uData && this.props.uData) {
            this.form.submit();

        }
    }

    render() {
        let params = getSearchParams(this.props[0].location.search, {})

        //uploads/5fff2ae94bbc1b5e66ad4ca6/clinicChat/72e415dd-cd24-43fb-bdf4-74587ff61671.mp4
        return (
            <form method="POST" ref={(node) => this.form = node} action={`${API_ENDPOINT}${params.path}`}>
                 <input type="hidden" name="mt" value={this.props.uData ? this.props.uData.mediaToken : ''} /> 
                {params.rid ?
                    <input type="hidden" name="rid" value={params.rid} />
                    :
                    null
                }
                {params.cid ?
                    <input type="hidden" name="cid" value={params.cid} />
                    :
                    null
                }
                {params.mid ?
                    <input type="hidden" name="mid" value={params.mid} />
                    :
                    null
                }
            </form>
        );
    }
}

export default MediaGateway;