import React, { Component } from 'react';
import Page from '../containers/page';
import BankIdModal from '../components/bankIdModal';
import { API_ENDPOINT } from '../constants';
import ErrorModal from './errorModal';
import { Player, Controls } from "@lottiefiles/react-lottie-player";

class VideoCallRedirect extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...props.initialData,
            _registerEvents: false
        };

    }


    componentDidMount() {

        if (this.props[0].match.params.initiator === 'initiator') {
            this.props.hideSidebarAndNavbar(true, true)
        }



        if (this.props[0].match.params.group) {

            fetch(API_ENDPOINT + '/clinic-groups/check/bundleId', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json'
                },
                body: JSON.stringify({ groupId: this.props[0].match.params.group, cid: this.props[0].match?.params?.conversation })
            }).then(res => res.json()).then((result) => {
                if (result.bundleId) {
                    if (typeof window !== 'undefined') {
                        if (result.bundleId) {
                            try {
                                window.location.replace(`${result.bundleId}://?conversation=${this.props[0].match.params.conversation}&initiator=${this.props[0].match.params.initiator}&uid=${this.props[0].match.params.userId}`);
                            } catch (err) { console.log(err) }
                        }
                    }
                }
            });
        }



        this.props.verifyUser(() => {
            if (this.props.uData && this.props.uData._id === this.props[0].match.params.userId) {
                if (this.props.uData && this.props.socket && !this.state._registerEvents) {

                    this.setState({
                        _registerEvents: true
                    }, () => {
                        this.registerEvents();
                        this.props.socket.emit('callStatus', { conversation: this.props[0].match.params.conversation, uid: this.props.uData._id })


                    })
                } else {
                    this.socketInterval = setInterval(() => {
                        if (this.props.uData && this.props.socket && !this.state._registerEvents) {

                            if (this.socketInterval) {
                                clearInterval(this.socketInterval)
                            }

                            this.setState({
                                _registerEvents: true
                            }, () => {
                                this.registerEvents();
                                this.props.socket.emit('callStatus', { conversation: this.props[0].match.params.conversation, uid: this.props.uData._id })

                            })
                        }

                    }, 500);
                }
            } else {
                this.setState({
                    error: 'Error, you are not the person who should answer'.translate(this.props.lang)
                })
            }
        });

        // if (!this.state.error) {
        //     setTimeout(() => {
        //         if (!this.props.uData) {
        //             this.fetchBankIdLogin()
        //         }
        //     }, 1000)
        // }







    }
    // fetchBankIdLogin = () => {
    //     fetch(API_ENDPOINT + '/users/bankid/v5/web/start', {
    //         method: 'POST',
    //         headers: {
    //             'content-type': 'application/json'
    //         },
    //         body: JSON.stringify({ group: this.props[0].match.params.group })
    //     }).then(res => res.json()).then((result) => {
    //         if (result.redirect_url) {
    //             if (result.qrCodeLink) {
    //                 this.setState({
    //                     qrCodeLink: result.qrCodeLink,
    //                     redirect_url: result.redirect_url
    //                 }, () => {
    //                     this.setState({
    //                         qrCodeLink: null
    //                     }, () => {
    //                         this.setState({
    //                             qrCodeLink: result.qrCodeLink
    //                         })
    //                     })
    //                 })
    //             }
    //         } else if (result.error) {
    //             this.setState({
    //                 errorBankID: result.error?.translate(this.props.lang)
    //             })
    //         }
    //     })

    // }

    checkInitiatorAgain = () => {
        if (this.props.uData) {
            // this.registerEvents();
            this.props.socket.emit('callStatus', { conversation: this.props[0].match.params.conversation, uid: this.props.uData._id })
        }

    }

    componentWillUnmount() {
        if (this.props.socket) {
            this.props.socket.removeListener('callStatus');
        }
    }

    registerEvents = async () => {
        this.props.socket.on('callStatus', (data) => {
            if (!data.initiator && this.props[0].match.params.initiator === 'initiator') {
                setTimeout(() => {
                    this.checkInitiatorAgain()

                }, 2000)
                return
            }
            if (data.activeConversation === 'none') {
                this.setState({
                    error: 'Video call ended'.translate(this.props.lang)
                })
            }

            if (data.activeConversation === this.props[0].match.params.conversation) {
                const link = `/video-call/${this.props[0].match.params.conversation}?initiator=${data.initiator}`
                this.props[0].history.push(link)
            }
        })

    }

    render() {


        return (
            <div>
                {
                    this.props[0].match.params.initiator === 'initiator' ?
                        <div className="waiting-room">
                            <div>
                                <div className="loader-wrap">
                                    <Player
                                        autoplay={true}
                                        loop={true}
                                        renderer={'svg'}
                                        src="/lf30_editor_l5cxzdyf.json"
                                        style={{ height: typeof window != 'undefined' && window.innerWidth < 768 ? "128px" : "256px", width: typeof window != 'undefined' && window.innerWidth < 768 ? "128px" : "256px" }}
                                    ></Player>

                                </div>

                                <p>{'Please Wait for the Host to Start this Meeting'.translate(this.props.lang)}</p>
                            </div>
                        </div>
                        :
                        null
                }
                {
                    // this.state.loginBankIdModal ?
                    !this.props.uData ?
                        <BankIdModal
                            {...this.props}
                            isOpen={true}
                            // isOpen={this.state.loginBankIdModal}
                            videoCallGroup={this.props[0]?.match?.params?.group}
                            videoCallUserId={this.props[0]?.match?.params?.userId}
                            videoCallGateway={true}
                            toggle={() => {

                                if (this.props.uData) {
                                    if (this.props.uData._id === this.props[0].match.params.userId) {
                                        if (this.props.uData && this.props.socket && !this.state._registerEvents) {

                                            this.setState({
                                                _registerEvents: true
                                            }, () => {
                                                this.registerEvents();
                                                this.props.socket.emit('callStatus', { conversation: this.props[0].match.params.conversation, uid: this.props.uData._id })


                                            })
                                        } else {
                                            this.socketInterval = setInterval(() => {
                                                if (this.props.uData && this.props.socket && !this.state._registerEvents) {

                                                    if (this.socketInterval) {
                                                        clearInterval(this.socketInterval)
                                                    }

                                                    this.setState({
                                                        _registerEvents: true
                                                    }, () => {
                                                        this.registerEvents();
                                                        this.props.socket.emit('callStatus', { conversation: this.props[0].match.params.conversation, uid: this.props.uData._id })


                                                    })
                                                }

                                            }, 500);
                                        }
                                    } else {
                                        this.setState({
                                            error: 'Error, you are not the person who should answer'.translate(this.props.lang)
                                        })
                                    }

                                }

                                this.setState({ loginBankIdModal: null })

                            }}
                        // redirect_url={this.state.loginBankIdModal}
                        >

                        </BankIdModal>
                        :

                        null
                }

                {this.state.error ?

                    <ErrorModal lang={this.props.lang}
                        isOpen={this.state.error}
                        toggle={() => this.setState({ error: null }, () => {
                            this.props[0].history.push('/')
                        })}

                    >
                        {this.state.error.translate(this.props.lang)}
                    </ErrorModal>

                    :
                    null
                }
            </div >
        );
    }
}



export default Page(VideoCallRedirect);
