
class QATestHelper {
    constructor(props) {
        this.props = props;

        if (typeof window !== 'undefined')
            this.initTracker();
    }


    createXPathFromElement = (elm, checkHref) => {
        var allNodes = document.getElementsByTagName('*');
        for (var segs = []; elm && elm.nodeType == 1; elm = elm.parentNode) {

            let nameCheck = true;

            let elementName = elm.getAttribute('name');
            if (elementName && elementName.indexOf && elementName.indexOf('.') !== -1){
                let splitted = elementName.split('.');
                for(let i=0;i<splitted.length;i++){
                    if (splitted[i].length == 24){
                        nameCheck = false;
                        break;
                    }
                }

            }

            if (elm.hasAttribute('id')) {
                var uniqueIdCount = 0;
                for (var n = 0; n < allNodes.length; n++) {
                    if (allNodes[n].hasAttribute('id') && allNodes[n].id == elm.id) uniqueIdCount++;
                    if (uniqueIdCount > 1) break;
                };
                if (uniqueIdCount == 1) {
                    segs.unshift('id("' + elm.getAttribute('id') + '")');
                    return segs.join('/');
                } else {
                    segs.unshift(elm.localName.toLowerCase() + '[@id="' + elm.getAttribute('id') + '"]');
                }
            } else if (elm.hasAttribute('name') && nameCheck) {
                var uniqueIdCount = 0;
                for (var n = 0; n < allNodes.length; n++) {
                    if (allNodes[n].hasAttribute('name') && allNodes[n].name == elm.name) uniqueIdCount++;
                    if (uniqueIdCount > 1) break;
                };
                if (uniqueIdCount == 1) {
                    segs.unshift('//' + elm.localName.toLowerCase() + '[@name="' + elm.getAttribute('name') + '"]');
                    return segs.join('/');
                } else {
                    segs.unshift(elm.localName.toLowerCase() + '[@name="' + elm.getAttribute('name') + '"]');
                }
            } else if (elm.hasAttribute('href') && elm.localName.toLowerCase() == 'a' && checkHref) {
                var uniqueIdCount = 0;
                for (var n = 0; n < allNodes.length; n++) {
                    if (allNodes[n].hasAttribute('href') && allNodes[n].href == elm.href) uniqueIdCount++;
                    if (uniqueIdCount > 1) break;
                };
                if (uniqueIdCount == 1) {
                    segs.unshift('//' + elm.localName.toLowerCase() + '[@href="' + elm.getAttribute('href') + '"]');
                    return segs.join('/');
                } else {
                    segs.unshift(elm.localName.toLowerCase() + '[@href="' + elm.getAttribute('href') + '"]');
                }
            } else if (elm.hasAttribute('class') && elm.localName && elm.localName.toLowerCase() !== 'button') {

                if (elm.previousSibling) {
                    for (var i = 1, sib = elm.previousSibling; sib; sib = sib.previousSibling) {
                        if (sib.getAttribute && sib.getAttribute('class') == elm.getAttribute('class')) i++;
                    };

                    if (i !== 1)
                        segs.unshift(elm.localName.toLowerCase() + '[@class="' + elm.getAttribute('class') + '"][' + i + ']');
                    else
                        segs.unshift(elm.localName.toLowerCase() + '[@class="' + elm.getAttribute('class') + '"]');


                } else {
                    segs.unshift(elm.localName.toLowerCase() + '[@class="' + elm.getAttribute('class') + '"]');

                }
            } else {
                for (var i = 1, sib = elm.previousSibling; sib; sib = sib.previousSibling) {
                    if (sib.localName == elm.localName) i++;
                };
                segs.unshift(elm.localName.toLowerCase() + '[' + i + ']');
            };
        };
        return segs.length ? '/' + segs.join('/') : null;
    };

    lookupElementByXPath = (path) => {
        var evaluator = new XPathEvaluator();
        var result = evaluator.evaluate(path, document.documentElement, null, XPathResult.FIRST_ORDERED_NODE_TYPE, null);
        return result.singleNodeValue;
    }


    getHtmlElement = (element) => {
        // console.log(element.localName)

        // check if element is wrapped with a tag
        let i = 0;
        let check = false;

        let node = element;

        while (node) {
            if (i >= 5) {
                break;
            }
            if (node.localName && node.localName.toLowerCase() == 'a') {
                check = true;
                break;
            }

            node = node.parentNode;
            i++;
        }

        if (check) {
            element = node;
        }


        while (['svg', 'g', 'path', 'line', 'circle', 'rect'].indexOf(element.localName.toLowerCase()) != -1) {
            // console.log(element.localName)
            element = element.parentNode;
        }

        return element;
    }

    trackClick = (e) => {
        let element = this.getHtmlElement(e.target);
        let xpath = this.createXPathFromElement(element);
        let fullXpath = this.createXPathFromElement(element);

        if (fullXpath.indexOf('div[@class="sidebar"]') !== -1) {
            if (element.localName.toLowerCase() == 'a') {
                if (!element.getAttribute('href')) {
                    xpath = `//div[@class="sidebar"]//a[contains(., "${element.innerText}")]`;
                } else {
                    xpath = `//div[@class="sidebar"]//a[@href="${element.getAttribute('href')}"]`;
                }
            }
        }


        // console.log(xpath);
        if (xpath.indexOf('qa-helper-ui') !== -1) {
            return;
        }

        if (this.shiftKey) {
            e.preventDefault();

                this.props.onElement({
                    localName: e.target.localName,
                    value: {
                        attribute: 'innerText',
                        operator: 'eq',
                        value: e.target.innerText
                    },
                    type: 'condition',
                    xpath: xpath
                })
    
            


        } else {
            this.props.onElement({
                localName: e.target.localName,
                type: 'click',
                xpath: xpath
            })
        }
    }

    trackInputChange = (e) => {
        const xpath = this.createXPathFromElement(e.target);
        if (xpath.indexOf('qa-helper-ui') !== -1) {
            return;
        }

        // console.log(xpath, e.target.value);
        if (e.target.localName && e.target.localName.toLowerCase().indexOf('select') !== -1 && e.target.value && e.target.value.length == 24) {
            let option = null;
            for(let i=0;i<e.target.children.length;i++){
                if (e.target.children[i].value == e.target.value){
                    option = e.target.children[i];
                    break;
                }
            }

            if (!option){
                this.props.onElement({
                    localName: e.target.localName,
                    type: e.target.getAttribute('type') == 'file' ? 'uploadFile' : 'change',
                    xpath: xpath,
                    value: e.target.value
                })
    
            }else{
                this.props.onElement({
                    localName: e.target.localName,
                    type: 'click',
                    xpath: xpath + `/option[.="${option.innerText}"]`,
                })
                console.log(xpath + `/option[.="${option.innerText}"]`)
            }

        } else {
            this.props.onElement({
                localName: e.target.localName,
                type: e.target.getAttribute('type') == 'file' ? 'uploadFile' : 'change',
                xpath: xpath,
                value: e.target.value
            })
        }
    }

    keydownHandler = (e) => {
        if (e.keyCode == 17) { // shift
            this.shiftKey = true;
        }
    }
    keyupHandler = (e) => {
        // console.log(this.keyCode)

        if (e.keyCode == 17) { // shift
            this.shiftKey = false;
        }

    }

    initTracker = () => {
        document.addEventListener('click', this.trackClick);
        document.addEventListener('change', this.trackInputChange);

        window.addEventListener('keydown', this.keydownHandler);
        window.addEventListener('keyup', this.keyupHandler);

    }
}

export default QATestHelper;
