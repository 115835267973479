import React, { Component } from 'react';

import { Link } from 'react-router-dom';

import {
    Container,
    Row,
    Col,
    Button,
    FormGroup,
    Label,
    Input,
    Modal, ModalHeader, ModalBody, ModalFooter, Form
} from 'reactstrap';

import { API_ENDPOINT } from '../../constants';
import HTML from './forms/fields/html';

import AnswerFormComponent from './answerFormComponent';
import InfoModal from '../../components/infoModal';


function parseJSON(response) {
    return response.json().then(json => {
        return {
            result: json,
            status: response.status
        }
    })
}



class RevisitForm extends Component {
    constructor(props) {
        super(props);
        this.searchResultsWraps = {};

        this.state = {
            ...props.initialData,
            answers: {},
            sections: [],
            showButton: true
        };
    }

    componentDidMount() {


        let setObj = {}
        let referral = {
            intelligentReferral: this.props.intelligentReferral ? true : false
        };



        if (this.props.sections) {
            referral.sections = this.props.sections;
        }
        if (this.props.answers) {
            setObj.answers = this.props.answers;
        }
        setObj.referral = referral;

        this.setState(setObj)


        if (this.props.alias) {
            this.getGroup(this.props.alias);
        }

        // this.getSavedForms()

    }
    getGroup = (alias) => {
        fetch(API_ENDPOINT + '/landing/page', {
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify({ alias })
        }).then(res => res.json()).then((result) => {
            if (result && result._id) {
                this.setState({ clinicGroup: result })
            }
        })
    }
    componentDidUpdate(prevProps, prevState) {


    }

    componentWillUnmount() {
        clearTimeout(this.timer1);
    }



    indexOfIndexOf(arr, str) {
        for (let i = 0; i < arr.length; i++) {
            let idx = arr[i].indexOf(str);
            if (idx !== -1) {
                return i;
            }

        }

        return -1;
    }


    submit = (data, callback) => {
        if (data.answers) {
            if (this.props.answer) {
                this.props.answer(data.answers, () => {

                    if (this.props.generateAnswers && this.props.saveAnswers) {
                        this.props.generateAnswers('<div></div>', () => {
                            this.props.saveAnswers()
                        })
                    }

                })
            }
        }

    }
    saveForm = () => {
        let obj = {
            revisit: true,
            groupAlias: this.props.form?.groupAlias,
            state: {
                referral: { ...this.state.referral, name: this.props.form?.name, revisitQuestionaryId: this.props.form?.revisitQuestionaryId, referralId: this.props.form?.referralId }
            }
        }

        if (this.getSaveFormData) {
            let data = this.getSaveFormData();
            obj.state = { ...obj.state, ...data }
        }

        fetch(API_ENDPOINT + '/users/personal-questionaries/save/data', {
            method: 'POST',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
            body: JSON.stringify(obj)
        }).then(res => res.json()).then((result) => {
            if (result && !result.error) {
                this.setState({ savedFormModal: result._id })

                if (typeof window != 'undefined') {
                    let checkedGroupsSavedForms = localStorage.checkedGroupsSavedForms ? JSON.parse(localStorage.checkedGroupsSavedForms) : [];
                    if (localStorage.groupAlias) {
                        checkedGroupsSavedForms.push(localStorage.groupAlias)
                        localStorage.setItem('checkedGroupsSavedForms', JSON.stringify(checkedGroupsSavedForms))
                    }
                }

            }
        })






        this.setState({ saveFormModal: false })
    }
    getSavedForms = () => {

        let obj = {
            groupAlias: this.props.form?.groupAlias,
            revisitQuestionaryId: this.props.form?.revisitQuestionaryId,
            referralId: this.props.form?.referralId,
        }

        fetch(API_ENDPOINT + '/users/revisit-questionaries/saved/data', {
            method: 'POST',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
            body: JSON.stringify(obj)
        }).then(res => res.json()).then((result) => {
            if (result && result.savedForms) {
                let checkSavedForms = false;
                if (result.savedForms && result.savedForms.length) {
                    checkSavedForms = true;
                }
                this.setState({ savedForms: result.savedForms, checkSavedForms })

            }
        })





    }


    render() {
        return (
            <div>
                <Container fluid>
                    <Row>
                        {this.state.referral?.sections?.length ?
                            <Col lg="12">
                                <AnswerFormComponent
                                    {...this.props}
                                    referral={this.state.referral}
                                    clinicGroup={this.state.clinicGroup}
                                    inModal={true}
                                    getSaveFormData={(getSaveFormData) => this.getSaveFormData = getSaveFormData}
                                    setSaveFormData={(setSaveFormData) => this.setSaveFormData = setSaveFormData}
                                    hideSubmitButton={this.props.disabledForm}
                                    submitButtonText={'Save'.translate(this.props.lang)}
                                    preview={this.props.disabledForm}
                                    submitAction={(data, callback) => {
                                        this.submit(data, callback)
                                    }}
                                />
                            </Col>
                            :
                            null
                        }
                        {
                            this.props.saveForm ?
                                <Col lg="12">
                                    <div className='save-form-button-wrap'>
                                        <Button
                                            className="save-formular-button save-formular-button-mobile"
                                            color="primary"
                                            onClick={() => {
                                                this.setState({ saveFormModal: true })
                                            }}
                                        >
                                            {'Continue later'.translate(this.props.lang)}
                                        </Button>
                                    </div>

                                </Col>
                                :
                                null
                        }

                    </Row>

                </Container>

                {this.state.saveFormModal ?
                    <InfoModal
                        isOpen={this.state.saveFormModal}
                        onClose={(item) => {
                            this.setState({
                                saveFormModal: item
                            })
                        }}
                        close={true}
                        header={'Info'.translate(this.props.lang)}
                        info={'Are you sure you want to save the case?'.translate(this.props.lang)}
                        buttons={[
                            <Button color="primary" style={{ margin: 10 }} onClick={() => this.saveForm()}>{'Yes'.translate(this.props.lang)}</Button>,
                            <Button color="primary" style={{ margin: 10 }} onClick={() => this.setState({ saveFormModal: false })}>{'No'.translate(this.props.lang)}</Button>


                        ]}
                    />
                    : null}

                {this.state.savedFormModal ?
                    <InfoModal
                        isOpen={this.state.savedFormModal}
                        onClose={(item) => {
                            this.setState({
                                savedFormModal: item
                            })
                        }}
                        close={true}
                        header={'Info'.translate(this.props.lang)}
                        info={'Your questionnaire has been saved.'.translate(this.props.lang)}
                        buttons={[
                            <Button color="primary" style={{ margin: 10 }} onClick={() => {
                                let resumeForm = this.state.savedFormModal;
                                this.props.setResumeForm?.(resumeForm, () => this.setState({ savedFormModal: false }))
                            }}>{'Continue answering'.translate(this.props.lang)}</Button>,
                            <Button color="primary" style={{ margin: 10 }} onClick={() => {
                                this.props.closeForm?.()
                            }}>{'Close the form'.translate(this.props.lang)}</Button>


                        ]}
                    />
                    : null}



                {this.state.wikiModal ?
                    <Modal isOpen={this.state.wikiModal} centered toggle={() => this.setState({ wikiModal: !this.state.wikiModal })} wrapClassName="wiki-hint-modal">
                        <ModalHeader close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ wikiModal: !this.state.wikiModal })}>&times;</button>}>{this.state.wikiModal.name}</ModalHeader>
                        <ModalBody>
                            <div dangerouslySetInnerHTML={{ __html: this.state.wikiModal.content }}></div>
                            <img src={API_ENDPOINT + this.state.wikiModal.image} />
                        </ModalBody>
                    </Modal>
                    :
                    null
                }


            </div>
        );
    }
}

export default RevisitForm;