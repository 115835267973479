import React, { Component } from 'react';
import Page from '../../containers/page';

import {
    Container,
    Row,
    Col,
    FormGroup,
    Label,
    Input
} from 'reactstrap';

import Isvg from 'react-inlinesvg'

import { formValueSelector, change } from 'redux-form';  // ES6
import { connect } from 'react-redux';

import FormBuilder from '../../components/forms/modalFormBuilder';
import { required } from '../../components/forms/validation';

import ListBuilder from '../../components/listBuilder';
import DeleteModal from '../../components/deleteModal';
import { getSearchParams, generateSearchLink, generateSearchLinkMultiple } from '../../helpers/linkHelper';
import { API_ENDPOINT } from '../../constants';
import { routes } from '../../routesList';
import moment from 'moment';

import Search from '../../components/search'
import addIcon from '../../assets/svg/add-icon.svg';
import edit from '../../assets/svg/edit.svg'
import garabage from '../../assets/svg/garbage.svg'
import DatePicker from '../../components/forms/fields/datePicker';

import profileUser from '../../assets/images/user.png';
import notification from '../../assets/svg/notification.svg'
import moreIcon from '../../assets/svg/more.svg'

import CanvasJSReact from '../../assets/canvasjs.react';
var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

/**
* CRM page
* @author   Milan Stanojevic
*/
class Crm extends Component {
    constructor(props) {
        super(props);

        let currDate = new Date();
        let date1 = new Date(currDate.getFullYear(), 0, 1);
        let date2 = new Date();

        this.state = {
            ...props.initialData,
            form: false,
            useState: true,
            params: {
                entries: 10,
                page: 0
            },
            items: [],
            endpoints: [],
            formModules: [],
            total: 0,
            loading: true,
            entities: [],
            kpiStats: {},
            dateFrom: moment.unix(Math.floor(date1.getTime() / 1000)).format('YYYY-MM-DD'),
            dateTo: moment.unix(Math.floor(date2.getTime() / 1000)).format('YYYY-MM-DD'),
            kpi: 0
        };
    }


    get = () => {
        this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));

        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0 })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

        for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
            this.props.loadDataWithQuery[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0 })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

    }

    componentDidMount() {
        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }

        this.stats()
        this.get();
    }

    componentDidUpdate(prevProps) {
        if (prevProps[0].location.search != this.props[0].location.search) {
            this.setState({
                loading: true
            }, () => {
                this.get();

            })
        }
    }


    updateSort = (field, type) => {
        if (this.state.useState) {
            let state = this.state.params;
            state.sortField = field;
            state.sortType = type;
            this.setState({
                params: state
            })
        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0 }, [{ name: 'sortField', value: field }, { name: 'sortType', value: type }], false));

        }
    }

    updateParams = (name, value, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };
                obj[name] = value;
                this.setState({ params: obj });
            } else {
                let obj = this.state.params;
                obj[name] = value;
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(generateSearchLink(this.props[0].location.search, { entries: 10, page: 0 }, name, value, restart));
        }
    }
    updateMultipleParams = (fields, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj });

            } else {
                let obj = this.state.params;
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0 }, fields, restart));
        }
    }


    /**
    * Insert new or update CRM
    * @author   Milan Stanojevic
    * @Objectparam    {String} id           crm id
    * @Objectparam    {Object} data         form data
    */
    insertOrUpdate = (data) => {
        /*if (data.date) {
            data.date = Math.floor(new Date(data.date).getTime() / 1000);
        }*/

        this.setState({
            loading: true
        }, () => {
            if (!this.state.form._id) {

                fetch(API_ENDPOINT + '/crm/new', {
                    method: 'PUT',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify(data)
                }).then(res => res.json()).then((result) => {
                    this.get();
                    this.stats();
                    this.setState({
                        form: null
                    })
                })
            } else {
                fetch(API_ENDPOINT + '/crm/' + this.state.form._id, {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify(data)
                }).then(res => res.json()).then((result) => {
                    this.get();
                    this.stats();
                    this.setState({
                        form: null
                    })
                })
            }
        })
    }

    /**
    * Delete CRM
    * @author   Milan Stanojevic
    * @Objectparam    {String} id           crm id
    */
    delete = (id) => {
        this.setState({
            loading: true
        }, () => {

            fetch(API_ENDPOINT + '/crm/' + id, {
                method: 'DELETE',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
            }).then(res => res.json()).then((result) => {
                this.stats();
                this.get();
            })
        })

    }

    /**
    * Get crm statistics
    * @author   Milan Stanojevic
    * @Objectparam    {String} dateFrom             starting date for statistics
    * @Objectparam    {String} dateTo               ending date for statistics
    * @Objectparam    {String} entity               entity
    * @Objectparam    {Number} kpi                  kpi
    */
    stats = () => {
        if (!this.state.kpi) {
            return;
        }
        fetch(API_ENDPOINT + '/crm/stats', {
            method: 'POST',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
            body: JSON.stringify({
                dateFrom: this.state.dateFrom,
                dateTo: this.state.dateTo,
                entity: this.state.entity == 'all' ? null : this.state.entity,
                kpi: this.state.kpi == 'all' ? null : parseInt(this.state.kpi)
            })
        }).then(res => res.json()).then((result) => {
            this.setState({
                kpiStats: result
            })
        })

    }


    render() {
        let params = {};
        if (this.state.useState) {
            params = this.state.params;
        } else {
            params = getSearchParams(this.props[0].location.search, { entries: 10, page: 0 })
        }

        let entries = [];
        let paginatedEntries = [];
        if (this.state.kpi && this.state.kpiStats && this.state.kpiStats[this.state.kpi]) {
            entries = JSON.parse(JSON.stringify(this.state.kpiStats[this.state.kpi].entries));

            if (params.filter) {
                entries = entries.filter((item) => {
                    let found = false;
                    for (var key in item) {
                        let checkString = item[key];
                        if (key == 'date') {
                            checkString = this.props.getStringDateTs(item[key], 'MM/DD/YYYY')/*moment.unix(item[key]).format('MM/DD/YYYY')*/;
                        } else {

                            checkString = String(checkString);
                        }

                        if (checkString.toLowerCase().indexOf(params.filter) !== -1) {
                            found = true;
                            break;
                        }

                    }

                    return found;

                })
            }

            if (params.sortField) {
                entries.sort((a, b) => {
                    if (params.sortField == 'entityName') {
                        if (params.sortType == 1) {

                            return (a[params.sortField] > b[params.sortField]) - (a[params.sortField] < b[params.sortField]);
                        } else {
                            return (b[params.sortField] > a[params.sortField]) - (b[params.sortField] < a[params.sortField]);

                        }
                    } else {
                        if (params.sortType == 1) {
                            return a[params.sortField] - b[params.sortField];
                        } else {
                            return b[params.sortField] - a[params.sortField];
                        }
                    }
                })
            }

            paginatedEntries = entries.slice(params.page * params.entries, params.page * params.entries + params.entries);

        }


        return (
            <div className={this.props.autoHideSidebar ? "dashboard dashboard-auto-hide" : "dashboard"}>

                <Container fluid>
                    <Row>
                        <Col lg="12">

                            <Row>
                                <Col lg="6">
                                    <div className="panel">
                                        <div className="panel-header">
                                            <h5 className="component-header">{this.props.linksMeta && this.props.linksMeta[this.props[0].location.pathname] ? this.props.linksMeta[this.props[0].location.pathname][this.props.lang] : ''}</h5>
                                            <button className="btn add-button" onClick={() => this.setState({
                                                form: {}
                                            })}><Isvg src={addIcon} /> {'Add'.translate(this.props.lang)}</button>
                                        </div>
                                        <h4>{'Select parameters'.translate(this.props.lang)}</h4>
                                        <Row>
                                            <Col lg="6">
                                                <FormGroup>
                                                    <Label size="sm">{'Select KPI'.translate(this.props.lang)}</Label>
                                                    <Input type="select" size="sm" value={this.state.kpi} onChange={(e) => {
                                                        this.setState({
                                                            kpi: e.target.value,
                                                            entity: 'all'
                                                        }, this.stats)
                                                    }}>
                                                        <option value={0}>{'None'.translate(this.props.lang)}</option>
                                                        <option value={1}>{'Samarbetspartner'.translate(this.props.lang)}</option>
                                                        <option value={2}>{'Antal patienter'.translate(this.props.lang)}</option>
                                                        <option value={3}>{'Såld vård'.translate(this.props.lang)}</option>
                                                        <option value={4}>{'MR & ultraljud'.translate(this.props.lang)}</option>
                                                        <option value={5}>{'Godkända remisser'.translate(this.props.lang)}</option>
                                                        <option value={6}>{'Total kostnad'.translate(this.props.lang)}</option>
                                                        <option value={'all'}>{'All'.translate(this.props.lang)}</option>

                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                            <Col lg="6">
                                                <FormGroup>
                                                    <Label size="sm">{'Select clinic / sector'.translate(this.props.lang)}</Label>
                                                    <Input value={this.state.entity} onChange={(e) => {
                                                        this.setState({
                                                            entity: e.target.value
                                                        }, this.stats)
                                                    }} disabled={!this.state.kpi || this.state.kpi == 2 || this.state.kpi == 5 || this.state.kpi == 'all'} type="select" size="sm">

                                                        {
                                                            this.state.entities && this.state.entities[this.state.kpi] ?

                                                                [{ name: 'All', _id: 'all' }, ...this.state.entities[this.state.kpi]].map((item, idx) => {
                                                                    return (
                                                                        <option value={item._id}>{item.name}</option>
                                                                    )
                                                                })
                                                                :
                                                                null
                                                        }

                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                            <Col lg="6">
                                                <FormGroup>
                                                    {/* <Label size="sm">{'Date from'.translate(this.props.lang)}</Label>
                                                    <Input type="date" size="sm" value={this.state.dateFrom} onChange={(e) => {
                                                        this.setState({
                                                            dateFrom: e.target.value
                                                        }, this.stats)
                                                    }}></Input> */}
                                                    <DatePicker
                                                        label={'Date from'.translate(this.props.lang)}
                                                        value={this.state.dateFrom}
                                                        onChange={(e) => {
                                                            this.setState({ dateFrom: e })
                                                        }}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col lg="6">
                                                <FormGroup>
                                                    {/* <Label size="sm">{'Date to'.translate(this.props.lang)}</Label>
                                                    <Input type="date" size="sm" value={this.state.dateTo} onChange={(e) => {
                                                        this.setState({
                                                            dateTo: e.target.value
                                                        }, this.stats)
                                                    }}></Input> */}
                                                    <DatePicker
                                                        label={'Date to'.translate(this.props.lang)}
                                                        value={this.state.dateTo}
                                                        onChange={(e) => {
                                                            this.setState({ dateTo: e })
                                                        }}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </div>

                                </Col>
                                <Col lg="6">
                                    <div className="panel stats-panel">
                                        <h4>{'Selection stats'.translate(this.props.lang)}</h4>
                                        {this.state.kpiStats && this.state.kpiStats[1] ? <div>{'Partner income (kr.):'.translate(this.props.lang)} <strong>{this.state.kpiStats[1].total.formatPrice(2)}</strong></div> : null}
                                        {this.state.kpiStats && this.state.kpiStats[3] ? <div>{'Sold care income (kr.):'.translate(this.props.lang)} <strong>{this.state.kpiStats[3].total.formatPrice(2)}</strong></div> : null}
                                        {this.state.kpiStats && this.state.kpiStats[2] ? <div>{'Number of total patiens:'.translate(this.props.lang)} <strong>{this.state.kpiStats[2].total}</strong></div> : null}
                                        {this.state.kpiStats && this.state.kpiStats[2] ? <div>{'Number of paid patients:'.translate(this.props.lang)} <strong>{this.state.kpiStats[2].paid}</strong></div> : null}
                                        {this.state.kpiStats && this.state.kpiStats[4] ? <div>{'MR & ultraljud income (kr.):'.translate(this.props.lang)} <strong>{this.state.kpiStats[4].total.formatPrice(2)}</strong></div> : null}
                                        {this.state.kpiStats && this.state.kpiStats[5] ? <div>{'Average percentage of approved referrals (%):'.translate(this.props.lang)} <strong>{parseFloat(this.state.kpiStats[5].approved).toFixed(2)}</strong></div> : null}
                                        {this.state.kpiStats && this.state.kpiStats[6] ? <div>{'Total cost (kr.):'.translate(this.props.lang)} <strong>{this.state.kpiStats[6].total.formatPrice(2)}</strong></div> : null}

                                    </div>

                                </Col>

                            </Row>

                            <Row>
                                {this.state.kpiStats && this.state.kpiStats[1] ?
                                    <Col lg={this.state.kpi == 1 ? '12' : '6'}>
                                        <div className="panel">
                                            <CanvasJSChart options={{
                                                animationEnabled: true, exportEnabled: true,
                                                title: {
                                                    text: 'Partner income'.translate(this.props.lang)
                                                },
                                                axisY: {
                                                    title: 'Income / month'.translate(this.props.lang),
                                                    lineColor: 'transparent',
                                                    titleFontColor: "#4F81BC",
                                                    lineColor: "#4F81BC",
                                                    labelFontColor: "#4F81BC",
                                                    tickColor: "#4F81BC"
                                                },

                                                toolTip: {
                                                    shared: true
                                                },
                                                legend: {
                                                    cursor: "pointer",
                                                    verticalAlign: 'top',
                                                    horizontalAlign: 'left',
                                                    itemclick: () => { }
                                                },
                                                data: this.state.kpiStats[1].entities.map((entity) => {
                                                    return {
                                                        type: "column",
                                                        name: entity.name,
                                                        legendText: entity.name,
                                                        showInLegend: true,
                                                        dataPoints: entity.monthStats.map((month) => {
                                                            return {
                                                                label: this.props.getStringDateTs(month.timestamp, 'MMM YY')/*moment.unix(month.timestamp).format('MMM YY')*/, y: month.total
                                                            }
                                                        })
                                                    }
                                                })
                                            }}
                                            /* onRef = {ref => this.chart = ref} */
                                            />

                                        </div>
                                    </Col>
                                    :
                                    null}

                                {this.state.kpiStats && this.state.kpiStats[2] ?
                                    <Col lg={this.state.kpi == 2 ? '12' : '6'}>

                                        <div className="panel">
                                            <CanvasJSChart options={{
                                                animationEnabled: true, exportEnabled: true,
                                                title: {
                                                    text: 'Number of patients'.translate(this.props.lang)
                                                },
                                                axisX: {
                                                    valueFormatString: "MMM YY",
                                                    minimum: new Date(this.state.kpiStats[2].monthStats[0].timestamp * 1000)
                                                },
                                                axisY: {
                                                    title: 'Number of patients'.translate(this.props.lang),
                                                    lineColor: 'transparent'
                                                    /*titleFontColor: "#4F81BC",
                                                    lineColor: "#4F81BC",
                                                    labelFontColor: "#4F81BC",
                                                    tickColor: "#4F81BC"*/
                                                },
                                                toolTip: {
                                                    shared: true
                                                },
                                                legend: {
                                                    cursor: "pointer",
                                                    verticalAlign: 'top',
                                                    horizontalAlign: 'left',
                                                    itemclick: () => { }
                                                },
                                                data: [{
                                                    name: "Number of patients",
                                                    showInLegend: true,
                                                    legendMarkerType: "triangle",
                                                    type: "area",
                                                    color: "rgba(200,50,50,0.9)",
                                                    markerSize: 0,
                                                    dataPoints: this.state.kpiStats[2].monthStats.map((month) => {
                                                        return {
                                                            x: new Date(month.timestamp * 1000), y: month.total
                                                        }
                                                    })
                                                },
                                                {
                                                    name: "Number of paid patients",
                                                    showInLegend: true,
                                                    legendMarkerType: "triangle",
                                                    type: "area",
                                                    color: "rgba(40,175,101,0.9)",
                                                    markerSize: 0,
                                                    dataPoints: this.state.kpiStats[2].monthStats.map((month) => {
                                                        return {
                                                            x: new Date(month.timestamp * 1000), y: month.paid
                                                        }
                                                    })
                                                },
                                                ]
                                            }}
                                            /* onRef = {ref => this.chart = ref} */
                                            />

                                        </div>
                                    </Col>
                                    :
                                    null}

                                {this.state.kpiStats && this.state.kpiStats[3] ?
                                    <Col lg={this.state.kpi == 3 ? '12' : '6'}>

                                        <div className="panel">
                                            <CanvasJSChart options={{
                                                animationEnabled: true, exportEnabled: true,
                                                title: {
                                                    text: 'Sold care income'.translate(this.props.lang)
                                                },
                                                axisY: {
                                                    title: 'Income / month'.translate(this.props.lang),
                                                    lineColor: 'transparent'
                                                    /*titleFontColor: "#4F81BC",
                                                    lineColor: "#4F81BC",
                                                    labelFontColor: "#4F81BC",
                                                    tickColor: "#4F81BC"*/
                                                },
                                                toolTip: {
                                                    shared: true
                                                },
                                                legend: {
                                                    cursor: "pointer",
                                                    verticalAlign: 'top',
                                                    horizontalAlign: 'left',
                                                    itemclick: () => { }
                                                },
                                                data: this.state.kpiStats[3].entities.map((entity) => {
                                                    return {
                                                        type: "column",
                                                        name: entity.name,
                                                        legendText: entity.name,
                                                        showInLegend: true,
                                                        dataPoints: entity.monthStats.map((month) => {
                                                            return {
                                                                label: this.props.getStringDateTs(month.timestamp, 'MMM YY')/*moment.unix(month.timestamp).format('MMM YY')*/, y: month.total
                                                            }
                                                        })
                                                    }
                                                })
                                            }}
                                            /* onRef = {ref => this.chart = ref} */
                                            />

                                        </div>
                                    </Col>
                                    :
                                    null}
                                {this.state.kpiStats && this.state.kpiStats[4] ?
                                    <Col lg={this.state.kpi == 4 ? '12' : '6'}>

                                        <div className="panel">
                                            <CanvasJSChart options={{
                                                animationEnabled: true, exportEnabled: true,
                                                title: {
                                                    text: 'MR & ultraljud income'.translate(this.props.lang)
                                                },
                                                axisY: {
                                                    title: 'Income / month'.translate(this.props.lang),
                                                    lineColor: 'transparent'
                                                    /*titleFontColor: "#4F81BC",
                                                    lineColor: "#4F81BC",
                                                    labelFontColor: "#4F81BC",
                                                    tickColor: "#4F81BC"*/
                                                },
                                                toolTip: {
                                                    shared: true
                                                },
                                                legend: {
                                                    cursor: "pointer",
                                                    verticalAlign: 'top',
                                                    horizontalAlign: 'left',
                                                    itemclick: () => { }
                                                },
                                                data: this.state.kpiStats[4].entities.map((entity) => {
                                                    return {
                                                        type: "column",
                                                        name: entity.name,
                                                        legendText: entity.name,
                                                        showInLegend: true,
                                                        dataPoints: entity.monthStats.map((month) => {
                                                            return {
                                                                label: this.props.getStringDateTs(month.timestamp, 'MMM YY')/*moment.unix(month.timestamp).format('MMM YY')*/, y: month.total
                                                            }
                                                        })
                                                    }
                                                })
                                            }}
                                            /* onRef = {ref => this.chart = ref} */
                                            />

                                        </div>
                                    </Col>
                                    :
                                    null}

                                {this.state.kpiStats && this.state.kpiStats[5] ?
                                    <Col lg={this.state.kpi == 5 ? '12' : '6'}>

                                        <div className="panel">
                                            <CanvasJSChart options={{
                                                animationEnabled: true, exportEnabled: true,
                                                title: {
                                                    text: 'Proportion of approved referrals'.translate(this.props.lang)
                                                },
                                                axisX: {
                                                    valueFormatString: "MMM YY",
                                                    minimum: new Date(this.state.kpiStats[5].monthStats[0].timestamp * 1000)
                                                },
                                                axisY: {
                                                    title: 'Percentage'.translate(this.props.lang),
                                                    lineColor: 'transparent'
                                                    /*titleFontColor: "#4F81BC",
                                                    lineColor: "#4F81BC",
                                                    labelFontColor: "#4F81BC",
                                                    tickColor: "#4F81BC"*/
                                                },
                                                toolTip: {
                                                    shared: true
                                                },
                                                legend: {
                                                    cursor: "pointer",
                                                    verticalAlign: 'top',
                                                    horizontalAlign: 'left',
                                                    itemclick: () => { }
                                                },
                                                data: [
                                                    {
                                                        name: "Approved referrals (%)",
                                                        showInLegend: true,
                                                        legendMarkerType: "triangle",
                                                        type: "stackedArea100",
                                                        color: "rgba(40,175,101,0.9)",
                                                        markerSize: 0,
                                                        yValueFormatString: "##.#0 '%'",
                                                        dataPoints: this.state.kpiStats[5].monthStats.map((month) => {
                                                            return {
                                                                x: new Date(month.timestamp * 1000), y: month.approved
                                                            }
                                                        })
                                                    },
                                                    {
                                                        name: "Unapproved referrals (%)",
                                                        showInLegend: true,
                                                        legendMarkerType: "triangle",
                                                        type: "stackedArea100",
                                                        color: "rgba(200,50,50,0.9)",
                                                        markerSize: 0,
                                                        yValueFormatString: "##.#0 '%'",
                                                        dataPoints: this.state.kpiStats[5].monthStats.map((month) => {
                                                            return {
                                                                x: new Date(month.timestamp * 1000), y: month.unapproved
                                                            }
                                                        })
                                                    },
                                                ]
                                            }}
                                            /* onRef = {ref => this.chart = ref} */
                                            />

                                        </div>
                                    </Col>
                                    :
                                    null}
                                {this.state.kpiStats && this.state.kpiStats[6] ?
                                    <Col lg={this.state.kpi == 6 ? '12' : '6'}>

                                        <div className="panel">
                                            <CanvasJSChart options={{
                                                animationEnabled: true, exportEnabled: true,
                                                title: {
                                                    text: 'Total cost'.translate(this.props.lang)
                                                },
                                                axisY: {
                                                    title: 'Income / month'.translate(this.props.lang),
                                                    lineColor: 'transparent'
                                                    /*titleFontColor: "#4F81BC",
                                                    lineColor: "#4F81BC",
                                                    labelFontColor: "#4F81BC",
                                                    tickColor: "#4F81BC"*/
                                                },
                                                toolTip: {
                                                    shared: true
                                                },
                                                legend: {
                                                    cursor: "pointer",
                                                    verticalAlign: 'bottom',
                                                    horizontalAlign: 'center',
                                                    itemclick: () => { }
                                                },
                                                data: [
                                                    {
                                                        type: "pie",
                                                        showInLegend: true,
                                                        dataPoints: this.state.kpiStats[6].entities.map((entity) => {
                                                            return {
                                                                name: entity.name, label: entity.name, y: entity.total
                                                            }
                                                        })
                                                    }
                                                ]

                                            }}
                                            /* onRef = {ref => this.chart = ref} */
                                            />

                                        </div>
                                    </Col>
                                    :
                                    null}

                                <Col lg="12">
                                    {
                                        this.state.kpiStats && this.state.kpi && this.state.kpi != 'all' && this.state.kpiStats[this.state.kpi] ?
                                            <div className="panel">
                                                <ListBuilder
                                                    lang={this.props.lang}
                                                    total={entries.length}
                                                    showNumeration={false}
                                                    fields={
                                                        this.state.kpi == 1 || this.state.kpi == 3 || this.state.kpi == 4 ?
                                                            [
                                                                { type: 'text', name: 'entityName', label: 'Clinic name'.translate(this.props.lang), allowSort: true },
                                                                { type: 'text', name: 'date', label: 'Entry date'.translate(this.props.lang), allowSort: true },
                                                                { type: 'text', name: 'amount', label: 'Amount'.translate(this.props.lang), allowSort: true },
                                                            ] :
                                                            this.state.kpi == 2 ?
                                                                [
                                                                    { type: 'text', name: 'total', label: 'Number of total patiens'.translate(this.props.lang), allowSort: true },
                                                                    { type: 'text', name: 'paid', label: 'Number of paid patiens'.translate(this.props.lang), allowSort: true },
                                                                    { type: 'text', name: 'date', label: 'Entry date'.translate(this.props.lang), allowSort: true },
                                                                ]
                                                                :
                                                                this.state.kpi == 5 ?
                                                                    [
                                                                        { type: 'text', name: 'approved', label: 'Approved referrals (%)'.translate(this.props.lang), allowSort: true },
                                                                        { type: 'text', name: 'unapproved', label: 'Unapproved referrals (%)'.translate(this.props.lang), allowSort: true },
                                                                        { type: 'text', name: 'date', label: 'Entry date'.translate(this.props.lang), allowSort: true },
                                                                    ]

                                                                    :

                                                                    [
                                                                        { type: 'text', name: 'entityName', label: 'Sector name'.translate(this.props.lang), allowSort: true },
                                                                        { type: 'text', name: 'date', label: 'Entry date'.translate(this.props.lang), allowSort: true },
                                                                        { type: 'text', name: 'amount', label: 'Amount'.translate(this.props.lang), allowSort: true },
                                                                    ]}
                                                    items={paginatedEntries.map((item) => {
                                                        return {
                                                            ...item,
                                                            date: this.props.getStringDateTs(item.date, 'MM/DD/YYYY')/*moment.unix(item.date).format('MM/DD/YYYY')*/
                                                        }
                                                    })}
                                                    rawItems={paginatedEntries}
                                                    actions={
                                                        [
                                                            {
                                                                component: <Isvg src={edit} className="edit-icon" />,
                                                                onClick: (item) => {
                                                                    item.date = this.props.getStringDateTs(item.date, 'YYYY-MM-DD')/*moment.unix(item.date).format('YYYY-MM-DD')*/
                                                                    this.setState({ form: item })
                                                                }

                                                            },
                                                            {
                                                                component: <Isvg src={garabage} className="delete-icon" />,
                                                                onClick: (item) => this.setState({ deleteModal: item })
                                                            },
                                                        ]
                                                    }
                                                    params={params}
                                                    sortField={params.sortField}
                                                    sortType={params.sortType}
                                                    updateSort={this.updateSort}
                                                    updateParams={this.updateParams}
                                                    updateMultipleParams={this.updateMultipleParams}
                                                ></ListBuilder>
                                                ,
                                                <DeleteModal
                                                    lang={this.props.lang}
                                                    isOpen={this.state.deleteModal}
                                                    toggle={() => this.setState({ deleteModal: null })}
                                                    handler={() => { this.delete(this.state.deleteModal._id); this.setState({ deleteModal: null }) }}
                                                >
                                                    {'Delete entry'.translate(this.props.lang)} ?
                                                </DeleteModal>

                                            </div>

                                            :

                                            null
                                    }
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                </Container>
                {this.state.form ?
                    <FormBuilder selectedGroup={this.props.selectedGroup} selectedClinic={this.props.selectedClinic} countryTwoLetter={this.props.countryTwoLetter} country={this.props.country} dateFormat={this.props.dateFormat}
                        getStringDateTs={this.props.getStringDateTs}
                        lang={this.props.lang}
                        onSubmit={(data) => this.insertOrUpdate(data)}
                        initialValues={this.state.form}
                        size={'lg'}
                        isOpen={this.state.form}
                        toggle={() => this.setState({ form: null })}
                        title={this.state.form._id ? 'Edit KPI entry'.translate(this.props.lang) : 'Add new KPI entry'.translate(this.props.lang)}
                        buttonText={'Save'.translate(this.props.lang).translate(this.props.lang)}
                        fields={[
                            {
                                type: 'row',
                                children: [
                                    {
                                        type: 'col',
                                        width: {
                                            lg: 12,
                                            sm: 12,
                                            xs: 12
                                        },
                                        children: [
                                            {
                                                type: 'select',
                                                name: 'kpi',
                                                label: 'Select KPI'.translate(this.props.lang),
                                                validate: [required('KPI is required!'.translate(this.props.lang))],
                                                values: [
                                                    { name: 'None'.translate(this.props.lang), value: null },
                                                    { name: 'Samarbetspartner'.translate(this.props.lang), value: 1 },
                                                    { name: 'Antal patienter'.translate(this.props.lang), value: 2 },
                                                    { name: 'Såld vård'.translate(this.props.lang), value: 3 },
                                                    { name: 'MR & ultraljud'.translate(this.props.lang), value: 4 },
                                                    { name: 'Godkända remisser'.translate(this.props.lang), value: 5 },
                                                    { name: 'Total kostnad'.translate(this.props.lang), value: 6 },
                                                ]
                                            },
                                        ]
                                    },
                                ]
                            },

                            this.props.kpi == 1 || this.props.kpi == 3 || this.props.kpi == 4 ? {
                                type: 'row',
                                children: [
                                    {
                                        type: 'col',
                                        width: {
                                            lg: 6,
                                            sm: 12,
                                            xs: 12
                                        },
                                        children: [
                                            {
                                                type: 'select',
                                                name: 'entity',
                                                label: 'Select clinic'.translate(this.props.lang),
                                                validate: [required('Clinic is required!'.translate(this.props.lang))],
                                                values: [
                                                    { name: 'None'.translate(this.props.lang), value: null },
                                                    { name: 'Add new'.translate(this.props.lang), value: '_addNew' },
                                                    ...(this.state.entities && this.state.entities[this.props.kpi] || []).filter(item => item.kpi == this.props.kpi).map((item) => {
                                                        return {
                                                            name: item.name,
                                                            value: item._id
                                                        }
                                                    })
                                                ]
                                            },
                                        ]
                                    },
                                    {
                                        type: 'col',
                                        width: {
                                            lg: 6,
                                            sm: 12,
                                            xs: 12
                                        },
                                        children: [
                                            {
                                                type: 'text',
                                                name: 'entityName',
                                                label: 'Clinic name'.translate(this.props.lang),
                                                disabled: this.props.entity == '_addNew' ? false : true,
                                            },
                                        ]
                                    },
                                    {
                                        type: 'col',
                                        width: {
                                            lg: 6,
                                            sm: 12,
                                            xs: 12
                                        },
                                        children: [
                                            {
                                                type: 'date',
                                                name: 'date',
                                                label: 'Date'.translate(this.props.lang),
                                            },
                                        ]
                                    },
                                    {
                                        type: 'col',
                                        width: {
                                            lg: 6,
                                            sm: 12,
                                            xs: 12
                                        },
                                        children: [
                                            {
                                                type: 'text',
                                                name: 'amount',
                                                label: 'Amount'.translate(this.props.lang),
                                            },
                                        ]
                                    },
                                ]
                            } : null,
                            this.props.kpi == 2 ? {
                                type: 'row',
                                children: [

                                    {
                                        type: 'col',
                                        width: {
                                            lg: 6,
                                            sm: 12,
                                            xs: 12
                                        },
                                        children: [
                                            {
                                                type: 'text',
                                                name: 'total',
                                                label: 'Number of total patiens'.translate(this.props.lang),
                                            },
                                        ]
                                    },
                                    {
                                        type: 'col',
                                        width: {
                                            lg: 6,
                                            sm: 12,
                                            xs: 12
                                        },
                                        children: [
                                            {
                                                type: 'text',
                                                name: 'paid',
                                                label: 'Number of paid patients'.translate(this.props.lang),
                                            },
                                        ]
                                    },

                                    {
                                        type: 'col',
                                        width: {
                                            lg: 6,
                                            sm: 12,
                                            xs: 12
                                        },
                                        children: [
                                            {
                                                type: 'date',
                                                name: 'date',
                                                label: 'Date'.translate(this.props.lang),
                                            },
                                        ]
                                    },
                                ]
                            } : null,
                            this.props.kpi == 5 ? {
                                type: 'row',
                                children: [

                                    {
                                        type: 'col',
                                        width: {
                                            lg: 6,
                                            sm: 12,
                                            xs: 12
                                        },
                                        children: [
                                            {
                                                type: 'text',
                                                name: 'approved',
                                                label: 'Approved referrals (%)'.translate(this.props.lang),
                                            },
                                        ]
                                    },
                                    {
                                        type: 'col',
                                        width: {
                                            lg: 6,
                                            sm: 12,
                                            xs: 12
                                        },
                                        children: [
                                            {
                                                type: 'text',
                                                name: 'unapproved',
                                                label: 'Unapproved referrals (%)'.translate(this.props.lang),
                                            },
                                        ]
                                    },

                                    {
                                        type: 'col',
                                        width: {
                                            lg: 6,
                                            sm: 12,
                                            xs: 12
                                        },
                                        children: [
                                            {
                                                type: 'date',
                                                name: 'date',
                                                label: 'Date'.translate(this.props.lang),
                                            },
                                        ]
                                    },
                                ]
                            } : null,
                            this.props.kpi == 6 ? {
                                type: 'row',
                                children: [
                                    {
                                        type: 'col',
                                        width: {
                                            lg: 6,
                                            sm: 12,
                                            xs: 12
                                        },
                                        children: [
                                            {
                                                type: 'select',
                                                name: 'entity',
                                                label: 'Select sector'.translate(this.props.lang),
                                                validate: [required('Sector is required!'.translate(this.props.lang))],
                                                values: [
                                                    { name: 'None'.translate(this.props.lang), value: null },
                                                    { name: 'Add new'.translate(this.props.lang), value: '_addNew' },
                                                    ...(this.state.entities && this.state.entities[this.props.kpi] || []).filter(item => item.kpi == this.props.kpi).map((item) => {
                                                        return {
                                                            name: item.name,
                                                            value: item._id
                                                        }
                                                    })

                                                ]
                                            },
                                        ]
                                    },
                                    {
                                        type: 'col',
                                        width: {
                                            lg: 6,
                                            sm: 12,
                                            xs: 12
                                        },
                                        children: [
                                            {
                                                type: 'text',
                                                name: 'entityName',
                                                label: 'Sector name'.translate(this.props.lang),
                                                disabled: this.props.entity == '_addNew' ? false : true,
                                            },
                                        ]
                                    },
                                    {
                                        type: 'col',
                                        width: {
                                            lg: 6,
                                            sm: 12,
                                            xs: 12
                                        },
                                        children: [
                                            {
                                                type: 'date',
                                                name: 'date',
                                                label: 'Date'.translate(this.props.lang),
                                            },
                                        ]
                                    },
                                    {
                                        type: 'col',
                                        width: {
                                            lg: 6,
                                            sm: 12,
                                            xs: 12
                                        },
                                        children: [
                                            {
                                                type: 'text',
                                                name: 'amount',
                                                label: 'Amount'.translate(this.props.lang),
                                            },
                                        ]
                                    },
                                ]
                            } : null,
                        ]}
                    ></FormBuilder>
                    :
                    null
                }



            </div>
        );
    }
}

const selector = formValueSelector('modalForm');

export default connect(state => {
    return { type: selector(state, 'type'), kpi: selector(state, 'kpi'), entity: selector(state, 'entity') }

}, {
    /*    changeMinValue: value => change("modalForm", "waitingMin", value),
        changeMaxValue: value => change("modalForm", "waitingMax", value),
        changeSelectedWaitingTimeType: value => change("modalForm", "selectedWaitingTimeType", value),
    */
})(Page(Crm));