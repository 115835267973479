import React, { Component } from 'react';
import Page from '../../containers/page';

import {
    Container,
    Row,
    Col,
    FormGroup, Label, Input, Button, UncontrolledAlert,
    Modal, ModalBody, ModalFooter
} from 'reactstrap';
import { Document, Page as Pageee, pdfjs } from 'react-pdf';


import { Link } from 'react-router-dom';

import ModalFormBuilder from '../../components/forms/modalFormBuilder';

import FormBuilder from '../../components/forms/formBuilder';
import { required } from '../../components/forms/validation';

import ListBuilder from '../../components/listBuilder';
import { getSearchParams, generateSearchLink, generateSearchLinkMultiple } from '../../helpers/linkHelper';
import { API_ENDPOINT } from '../../constants';
import ErrorModal from '../../components/errorModal';
import { formValueSelector, change } from 'redux-form';  // ES6
import { connect } from 'react-redux';
import lIcon from '../../assets/svg/upitnik.svg'
import Comment from '../../assets/svg/koment.svg'
import Hart from '../../assets/svg/srce.svg';
import i1 from '../../assets/svg/i1.png';
import i2 from '../../assets/svg/i2.png';
import i3 from '../../assets/svg/i3.png';
import locationIcon from '../../assets/svg/location.png'
import Isvg from 'react-inlinesvg'
import error from '../../assets/images/error.png';
// import BankIdModal from '../../components/bankIdModal';

function parseJSON(response) {
    return response.json().then(json => {
        return {
            result: json,
            status: response.status
        }
    })
}
/**
* Accessibility report page
* @author   Stefan Pantic
*/
class Account extends Component {
    constructor(props) {
        super(props);
        if (typeof window != 'undefined')
            pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

        this.state = {
            ...props.initialData,
            form: false,
            useState: false,
            params: {
                entries: 10,
                page: 0,
                'query-group': null
            },
            items: [],
            total: 0,
            loading: true,
            groups: [],
            languages: [],
            clinicIdx: 0,
            data: {},
            numPages: 0,
            pageNumber: 1
        };
    }


    get = () => {
        this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));

        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0, 'query-group': null })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

        for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
            this.props.loadDataWithQuery[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0, 'query-group': null })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

    }

    componentDidMount() {
        //if (typeof window !== 'undefined') { window.scrollTo(0, 0); }

        //this.get();
        // this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
        // setTimeout(() => {
        //     this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
        // }, 1000);

        let alias = this.props[0].match.params.alias;

        fetch(API_ENDPOINT + '/landing/page', {
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify({ alias })
        }).then(res => res.json()).then((result) => {
            if (result.error) {
                this.setState({
                    error: result.error
                })
            } else {
                // console.log(result)
                this.setState({
                    data: result
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            }
        });

        if (this.props.uData && this.state.data) {
            this.checkUserAuth();
        }

    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps[0].location.search != this.props[0].location.search) {
            this.setState({
                loading: true
            }, () => {
                this.get();

            })
        }

        if (typeof prevProps.selectedWaitingTimeType != 'undefined' && prevProps.selectedWaitingTimeType != this.props.selectedWaitingTimeType) {
            if (this.props.selectedWaitingTimeType == 0) {
                this.props.changeMinValue('');
                this.props.changeMaxValue('');
            } else if (this.props.selectedWaitingTimeType == 1) {
                this.props.changeMinValue(0);
                this.props.changeMaxValue(0);
            } else if (this.props.selectedWaitingTimeType == 2) {
                this.props.changeMinValue('');
                this.props.changeMaxValue(0);
            } else if (this.props.selectedWaitingTimeType == 3) {
                this.props.changeMinValue(0);
                this.props.changeMaxValue('');
            } else if (this.props.selectedWaitingTimeType == 4) {
                this.props.changeMinValue('');
                this.props.changeMaxValue('');
            } else if (this.props.selectedWaitingTimeType == 5) {
                this.props.changeMinValue('');
                this.props.changeMaxValue('');
            }
        }

        if (prevProps?.uData?._id !== this.props.uData?._id || prevState?.data?._id !== this.state.data?._id || prevProps?.[0]?.match?.params?.alias !== this.props?.[0]?.match?.params?.alias) {
            this.checkUserAuth();
        }
    }


    updateSort = (field, type) => {
        if (this.state.useState) {
            let state = this.state.params;
            state.sortField = field;
            state.sortType = type;
            this.setState({
                params: state
            })
        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0 }, [{ name: 'sortField', value: field }, { name: 'sortType', value: type }], false));

        }
    }

    updateParams = (name, value, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };
                obj[name] = value;
                this.setState({ params: obj });
            } else {
                let obj = this.state.params;
                obj[name] = value;
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(generateSearchLink(this.props[0].location.search, { entries: 10, page: 0 }, name, value, restart));
        }
    }
    updateMultipleParams = (fields, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj });

            } else {
                let obj = this.state.params;
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0 }, fields, restart));
        }
    }

    onDocumentLoadSuccess = ({ numPages }) => {
        //console.log(numPages)
        if (this.state.numPages != numPages)
            this.setState({
                numPages: numPages
            })
    }


    nextPage = () => {
        if (this.state.pageNumber < this.state.numPages)
            this.setState({
                pageNumber: this.state.pageNumber + 1
            })
    }
    backPage = () => {
        if (this.state.pageNumber != 1)
            this.setState({
                pageNumber: this.state.pageNumber - 1
            })
    }

    checkUserAuth = () => {
        const user = this.props.uData;
        const groupId = this.state.data?._id;
        const loggedInWith2fa = localStorage?.getItem?.('2faLogin');

        if (loggedInWith2fa && loggedInWith2fa === 'true' && user && groupId) {
            this.setState({ loading: true }, async () => {
                const newState = {
                    loading: false,
                };

                const response = await fetch(API_ENDPOINT + '/users/two-factor-auth/check-clinic-group', {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                    },
                    body: JSON.stringify({ groupId })
                });

                const result = await response?.json?.();

                if (!result) return this.setState(newState);

                if (result.error) {
                    newState.twoFactorAuthForbidden = result.error;
                    this.setState(newState);
                } else {
                    newState.twoFactorAuthForbidden = false;
                    this.setState(newState);
                }

                if (result.verify && result.verify === true) this.props.verifyUser?.();
            });
        }
    }

    navigateBackToProfile = () => {
        this.setState({ twoFactorAuthForbidden: false }, () => {
            this.props?.[0]?.history?.replace?.('/my-data/account');
        });
    }

    render() {
        return (
            <div className="shame">
                {this.state.error ? null :
                    //<Container>
                    <Row>
                        <Col lg="12">
                            <div className="landing-page-container" /*style={{ height: '100vh'}}*/>

                                <div className='landing-header' style={{ background: 'wight', paddingBottom: 0 }}>
                                    <Container>
                                        <div className="login-header">
                                            <div class="logo">
                                                <img src={API_ENDPOINT + this.state.data.logo} className="landing-page-logo" />
                                            </div>
                                            <div className="navigation">

                                            </div>
                                        </div>

                                    </Container>
                                </div>
                                <div className="landing-button" style={{ background: 'wight'/*, paddingTop: 0 */ }}>
                                    <Container>
                                        {typeof window != 'undefined' && this.state.data && this.state.data.accessibilityReport ?
                                            <div style={{ width: '100%' }} ref={(node) => this.pdfDocument = node}>
                                                <Document
                                                    file={{ url: API_ENDPOINT + (this.state.data.accessibilityReport && typeof this.state.data.accessibilityReport == "object" ? this.state.data.accessibilityReport.file : this.state.data.accessibilityReport) }}
                                                    onLoadSuccess={this.onDocumentLoadSuccess}
                                                    error={"Failed to load PDF file.".translate(this.props.lang)}
                                                    loading={"Loading PDF...".translate(this.props.lang)}
                                                    noData={"No page specified.".translate(this.props.lang)}
                                                >
                                                    <Pageee width={this.pdfDocument ? this.pdfDocument.clientWidth : null} pageNumber={this.state.pageNumber} />
                                                </Document>
                                                <div /*className="page-pagination app-page-pagination-mobile"*/ className="page-pagination">
                                                    <Button onClick={this.backPage}>{'Previous'.translate(this.props.lang)}</Button>
                                                    <p>{'Page'.translate(this.props.lang)} {this.state.pageNumber} {'of'.translate(this.props.lang)} {this.state.numPages}</p>
                                                    <Button onClick={this.nextPage}>{'Next'.translate(this.props.lang)}</Button>
                                                </div>

                                            </div>
                                            :
                                            null}
                                    </Container>
                                </div>
                                <div className="landing-footer footer-space">
                                    <Container>
                                        <Row>
                                            <Col lg="12">
                                                <ul className="landing-footer">
                                                    {this.state.data.privacyPolicy ? <li><Link to={`/${this.props[0].match.params.alias}/privacy-policy`} target="_blank"> {'Privacy policy'.translate(this.props.lang)} </Link></li> : null}
                                                    {this.state.data.termsOfUse ? <li><a href={`/${this.props[0].match.params.alias}/terms-of-use`} target="_blank"> {'Terms of use'.translate(this.props.lang)} </a></li> : null}
                                                    {this.state.data.accessibilityReport ? <li><a href={`/${this.props[0].match.params.alias}/accessibility-report`} target="_blank"> {'Accessibility Report'.translate(this.props.lang)} </a></li> : null}
                                                </ul>
                                            </Col>
                                            <Col lg="12">
                                                <ul className="landing-footer landing-footer-list">
                                                    <li>{this.state.data.address ? this.state.data.address[0] ? this.state.data.address[1] || this.state.data.address[2] ? this.state.data.address[0] + ',' : this.state.data.address[0] : '' : ''}</li>
                                                    <li>{this.state.data.address ? this.state.data.address[1] ? this.state.data.address[2] ? this.state.data.address[1] + ',' : this.state.data.address[1] : '' : ''}</li>
                                                    <li>{this.state.data.address ? this.state.data.address[2] : ''}</li>
                                                </ul>

                                            </Col>
                                        </Row>
                                    </Container>
                                </div>
                            </div>




                        </Col>

                    </Row>
                    //</Container>
                }

                {this.state.error ?

                    <Modal isOpen={this.state.error} centered>

                        <ModalBody className="delete-modal">
                            {/* <img src={error} /> */}
                            <div className="message">
                                {
                                    this.state.error
                                }
                            </div>
                        </ModalBody>
                        <ModalFooter className='buttons-right-blue'>
                            <Button style={{ minWidth: 100 }} type='button' color='primary' onClick={() => this.setState({ error: null })}>
                                {'Close'.translate(this.props.lang)}
                            </Button>
                        </ModalFooter>
                    </Modal>

                    :
                    null
                }

                {/* {
                    this.state.bankIdModal ?
                        <BankIdModal
                            {...this.props}
                            isOpen={this.state.bankIdModal}
                            toggle={() => {

                                if (this.props.uData) {

                                    localStorage.setItem('groupAlias', this.props[0].match.params.alias);

                                    this.props[0].history.push(`/${this.props[0].match.params.alias}/personal-referral`)
                                }
                                this.setState({ bankIdModal: null })

                            }}
                            redirect_url={this.state.bankIdModal}></BankIdModal>
                        :

                        null
                } */}
                {/* {
                    this.state.loginBankIdModal ?
                        <BankIdModal
                            {...this.props}
                            isOpen={this.state.loginBankIdModal}
                            toggle={() => {

                                if (this.props.uData) {

                                    localStorage.setItem('groupAlias', this.props[0].match.params.alias);
                                }
                                this.setState({ loginBankIdModal: null })

                            }}
                            redirect_url={this.state.loginBankIdModal}></BankIdModal>
                        :

                        null
                } */}
                {/* {
                    this.state.bankIdModal1 ?
                        <BankIdModal
                            {...this.props}
                            isOpen={this.state.bankIdModal1}
                            toggle={() => {

                                if (this.props.uData) {

                                    localStorage.setItem('groupAlias', this.props[0].match.params.alias);
                                    if (!this.props.uData.haveReferrals && !this.props.uData.havePersonalReferrals) {
                                        this.setState({
                                            error: 'You have no active issues with us...'.translate(this.props.lang)
                                        })
                                        return;
                                    }

                                    this.props[0].history.push(`/my-data/personal-referrals/referrals`)
                                }
                                this.setState({ bankIdModal1: null })

                            }}
                            redirect_url={this.state.bankIdModal1}></BankIdModal>
                        :

                        null
                } */}


                {this.state.error ?
                    <ErrorModal lang={this.props.lang}
                        isOpen={this.state.error}
                        toggle={() => this.setState({ error: null })}

                    >
                        {this.state.error.translate(this.props.lang)}
                    </ErrorModal>
                    :
                    null

                }

                {
                    this.state.twoFactorAuthForbidden ? <Modal
                        zIndex={99999}
                        size='md'
                        centered
                        isOpen={this.state.twoFactorAuthForbidden}
                    >
                        <ModalBody style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 20 }}>
                            <p style={{ textAlign: 'center', margin: 0, padding: 0, }}>{typeof this.state.twoFactorAuthForbidden === 'string' ? this.state.twoFactorAuthForbidden.translate(this.props.lang) : 'This clinic does not accept cases from patients who use two factor authentication, if their account is not verified with them.'.translate(this.props.lang)}</p>
                        </ModalBody>
                        <ModalFooter style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Button onClick={this.navigateBackToProfile} style={{ minWidth: 100 }}>
                                {'Ok'.translate(this.props.lang)}
                            </Button>
                        </ModalFooter>
                    </Modal> : null
                }


            </div>
        );
    }
}

const selector = formValueSelector('modalForm');

export default connect(state => {
    return { selectedWaitingTimeType: selector(state, 'selectedWaitingTimeType') }

}, {
    changeMinValue: value => change("modalForm", "waitingMin", value),
    changeMaxValue: value => change("modalForm", "waitingMax", value),
    changeSelectedWaitingTimeType: value => change("modalForm", "selectedWaitingTimeType", value),

})(Page(Account));


/*
<div dangerouslySetInnerHTML={{ __html: this.state.data.information }} ></div>
*/