import React, { Component } from 'react';
import ListBuilder from '../listBuilder';
import { API_ENDPOINT } from '../../constants';
import Isvg from 'react-inlinesvg';

import eyeIcon from '../../assets/svg/eye.svg';
import historyIcon from '../../assets/svg/history-icon.svg';
import archiveIcon from '../../assets/svg/archive.svg';
import trashIcon from '../../assets/svg/garbage.svg';
import pdfIcon from '../../assets/svg/save-as-pdf.svg';
import referralResponseIcon from '../../assets/svg/referral-response.svg';
import monitoringIcon from '../../assets/svg/monitoring.svg';
import chatIcon from '../../assets/svg/chat-icon.svg';
// import { defaultError } from '../TwoFactorAuthModal';
import { Button } from 'reactstrap';




function parseJSON(response) {
    return response.json().then(json => {
        return {
            result: json,
            status: response.status
        }
    })
}

const loadDataWithQuery = [
    (fetchFunction, lang, match, query) => {
        let url = `${API_ENDPOINT}/e-connect/cases?page=${query?.page ?? 0}&entries=${query?.entries ?? 10}&sortField=${query?.sortField ?? '_id'}&sortType=${query?.sortType ?? -1}&filter=${query?.filter ?? ''}&clinicGroupId=${query?.clinicGroupId ?? ''}&departmentId=${query?.departmentId ?? ''}&_type=${query?.type ?? ''}`;
        if (query?.allCases === true) url += `&allCases=yes`;

        return fetchFunction?.(url, {
            method: 'GET',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
        }).then(parseJSON).then(({ result, status }) => {
            if (!result.error) {
                return {
                    page: query.page ? query.page : 0,
                    items: result.items,
                    total: result.total,
                    resultFilter: result.filter
                }
            }
            else return { error: result.error }
        })
    }
]

export class EconnectCasesList extends Component {
    constructor(props) {
        super(props)

        this.state = {
            num: 0,
            form: false,
            useState: true,
            params: {
                entries: 10,
                page: 0,
                sortField: '_id',
                sortType: -1,
                filter: ''
            },
            items: [],
            total: 0,
            loading: true,
            error: null,
            user: null,
        }
    }

    get = () => {
        this.setState({ loading: true }, () => {
            this.props?.updateMeta?.(this.props?.generateSeoTags?.(this.state, this.props.lang));

            for (let i = 0; i < loadDataWithQuery?.length; i++) {
                loadDataWithQuery?.[i]?.(window?.fetch, this.props.lang, this.props[0].match, { ...this.state.params, clinicGroupId: this.props.selectedGroup, departmentId: this.props.selectedClinic, allCases: this.props.allCases, type: this.props.type }).then(data => {
                    if (data?.error) return this.setState({ loading: null, error: data.error?.translate(this.props.lang || 'en') });

                    this.setState({ ...data, loading: null });
                })
            }
        })
    }

    componentDidMount() {
        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }

        this.get();
    }

    componentDidUpdate(prevProps, prevState) {
        if ((!prevProps?.selectedGroup && this.props.selectedGroup) || (prevProps?.selectedGroup !== this.props.selectedGroup) || (!prevProps?.selectedClinic && this.props.selectedClinic) || (prevProps?.selectedClinic !== this.props.selectedClinic)) this.get();

        if (prevProps?.num !== this.props.num) this.get();

        if (prevState?.num !== this.state.num) this.get();

        if (prevState?.params !== this.state.params) this.get();

        if (prevProps.allCases !== this.props.allCases) this.get();
    }

    updateSort = (field, type) => this.setState(prev => ({
        ...prev,
        params: {
            ...prev?.params,
            sortField: field,
            sortType: type
        }
    }));

    updateParams = (name, value, restart = false) => {
        let obj = {
            [name]: value
        }
        this.setState(prev => ({
            ...prev,
            params: {
                ...prev?.params,
                entries: restart ? 10 : prev.params.entries,
                page: restart ? 0 : prev.params.page,
                ...obj
            }
        }));
    }

    updateMultipleParams = (fields, restart = false) => {
        let obj = {};
        if (restart) {
            obj.entries = 10;
            obj.page = 0;
        }
        for (let i = 0; i < fields.length; i++) {
            obj[fields[i].name] = fields[i].value;
        }
        return this.setState(prev => ({
            ...prev,
            params: {
                ...prev?.params,
                ...obj
            }
        }));
    }

    searchPatientWithRedirect = (SSN, enterprise) => {
        let obj = {
            socialSecurityNumber: SSN,
            group: this.props.selectedGroup,
            clinic: this.props.selectedClinic,
        }
        enterprise && (obj.openOnWebDoc = true);

        fetch(API_ENDPOINT + '/users/find/patient', {
            method: 'POST',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
            body: JSON.stringify(obj)
        }).then(res => res.json()).then((result) => {

            if (result && result._id) {
                const path = enterprise ? '/patients/search-enterprise' : '/patients/search';
                this.props.registerClinicGroupLogs?.({
                    type: 'patientProfile',
                    patient: SSN,
                });

                let state = {
                    TYPE: 'redirect',
                    redirectPatient: result,
                    redirectTabIndex: 1
                }
                enterprise && (state.redirectTabActive = 1);

                this.props?.[0]?.history?.push?.(path, state);

            } else {
                this.setState({ error: 'User not found in this clinic group' })
            }
        })
    }

    updateSeen = async (id, actioner, updateAnswerAndResponses = false) => {
        if (!id || !actioner || !['sender', 'receiver', 'forwarder'].includes(actioner)) return;
        try {
            const response = await fetch(API_ENDPOINT + `/e-connect/cases/count/${id}`, {
                method: 'PATCH',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify({ actioner, updateAnswerAndResponses })
            });
            if (!response || !response.ok) return;

            this.props.checkEConnectCount?.();
            this.refresh();
        } catch (err) {
            console.log('--- updateSeen err: ', err);
        }
    }

    refresh = () => { this.setState(prev => ({ ...prev, num: (prev?.num || 0) + 1 })) }

    getConversationId = (caseId) => new Promise((resolve) => {
        if (!caseId) return resolve(null);
        this.setState({ loading: true }, async () => {
            const newState = { loading: false };

            const response = await fetch(API_ENDPOINT + '/e-connect/chat/' + caseId, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage?.getItem?.('authToken')}`
                }
            });

            if (!response || !response.ok) {
                if (response?.status === 403) newState.error = 'You do not have permissions to edit this case!';
                else newState.error = 'Something went wrong! Please refresh your browser and try again!';
                this.setState(newState, () => { this.get() });
                return resolve(null);
            }

            const result = await response?.json?.();
            if (!result || !result.conversationId) {
                newState.error = 'Something went wrong! Please refresh your browser and try again!';
                this.setState(newState, () => { this.get() });
                return resolve(null);
            }

            this.setState(newState, () => { this.get() });
            return resolve(result.conversationId);
        });
    })

    checkPermissions = () => {
        if (this.props.uData && this.props.uData.permissions && this.props.selectedClinic && this.props.selectedGroup && this.props.uData.groupPermissionsUser) {

            if (this.props.uData.groupPermissionsUser[this.props.selectedGroup] && this.props.uData.groupPermissionsUser[this.props.selectedGroup][this.props.selectedClinic]) {
                let permissions = this.props.uData.groupPermissionsUser[this.props.selectedGroup][this.props.selectedClinic];
                let doctorPermission = false;
                let adminPermission = false;
                let clinicAdminPermission = false;
                for (let i = 0; i < permissions.length; i++) {
                    if (this.props.uData.permissions.clinicAdmin == permissions[i]) {
                        clinicAdminPermission = true;
                    } else if (this.props.uData.permissions.admin == permissions[i]) {
                        adminPermission = true;
                    } else if (this.props.uData.permissions.doctor == permissions[i]) {
                        doctorPermission = true;
                    }
                }

                if (adminPermission) return true;
            }
        }

        return false
    }


    render() {
        let params = this.state.params;
        const fields = this.props.type === 'forwarded' || this.props.type === 'forwarded-archive' ? [
            { type: 'text', name: '_no', label: 'Case ID'.translate(this.props.lang), allowSort: true },
            { type: 'text', name: '_patient', label: 'Patient'.translate(this.props.lang), allowSort: true },
            { type: 'text', name: '_sendingClinic', label: 'Sending clinic'.translate(this.props.lang), allowSort: true },
            { type: 'text', name: '_receivingClinic', label: 'Receiving clinic'.translate(this.props.lang), allowSort: true },
            { type: 'text', name: '_date', label: 'Last Change'.translate(this.props.lang), allowSort: true },
            { type: 'text', name: '_subject', label: 'Subject'.translate(this.props.lang), allowSort: true },
            { type: 'text', name: '_status', label: 'Status'.translate(this.props.lang), allowSort: true },
        ] : [
            { type: 'text', name: '_no', label: 'Case ID'.translate(this.props.lang), allowSort: true },
            { type: 'text', name: '_patient', label: 'Patient'.translate(this.props.lang), allowSort: true },
            { type: 'text', name: '_otherClinic', label: this.props._page === 'created' ? 'Receiving clinic'.translate(this.props.lang) : 'Sending clinic'.translate(this.props.lang), allowSort: true },
            { type: 'text', name: '_date', label: 'Last Change'.translate(this.props.lang), allowSort: true },
            { type: 'text', name: '_subject', label: 'Subject'.translate(this.props.lang), allowSort: true },
            { type: 'text', name: '_status', label: 'Status'.translate(this.props.lang), allowSort: true },
            { type: 'text', name: '_type', label: 'Type'.translate(this.props.lang), allowSort: true },
        ];
        return (
            <>
                <ListBuilder
                    lang={this.props.lang}
                    loading={this.state.loading}
                    total={this.state.total}
                    showNumeration={false}
                    filterDelay={500}
                    fields={fields}
                    rawItems={this.state.items}
                    items={this.state.items?.map(item => ({
                        ...item,
                        ___className: item?._id && this.props.highlightedCases?.includes?.(item._id) ? 'ec-highlighted-row' : undefined,
                        _no: this.props._page === 'created' && ['created', 'sent'].includes(this.props.type) ? (
                            !item?.sender?.statusSeen && String(item?.sender?.user) === String(this.props.uData?._id) && String(item?.sender?.id) === String(this.props.selectedGroup) && String(item?.sender?.departmentId) === String(this.props.selectedClinic) && false ? <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gap: 8 }}>
                                <div style={{ width: 10, height: 10, borderRadius: 5, backgroundColor: 'red', opacity: '0.7' }}></div>
                                <div>{item?.no}</div>
                            </div> : item?.no
                        ) : this.props._page !== 'created' && ['received', 'answered'].includes(this.props.type) && false ? (
                            !item?.receiver?.statusSeen && String(item?.receiver?.id) === String(this.props.selectedGroup) && ((
                                item?.receiver?.status === 'received'
                            ) || (
                                    String(item?.receiver?.user) === String(this.props.uData?._id) && String(item?.receiver?.departmentId) === String(this.props.selectedClinic)
                                )) ? <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gap: 8 }}>
                                <div style={{ width: 10, height: 10, borderRadius: 5, backgroundColor: 'red', opacity: '0.7' }}></div>
                                <div>{item?.no}</div>
                            </div> : item?.no
                        ) : item?.no,
                        _patient: this.props.canOpenProfile && item?.patient?.type && item.patient.type === 'internal' ? <Button color='#fff' className="patient-modal-button" onClick={() => {
                            if (!this.props.canOpenProfile || !item?.patient?.ssn) return;
                            this.searchPatientWithRedirect(item?.patient?.ssn, this.props.enterprise);
                        }}>{item?.patient?.name}<br /><span className="clinic-subgroup-name">{item?.patient?.ssn} </span>  {item?._2fa ? <p style={{ padding: 0, margin: 0, color: 'red', fontSize: 12 }}>{'2FA'.translate(this.props.lang)}</p> : null} </Button> : <div style={{ padding: 10 }}>
                            <div style={{ fontWeight: '600', fontSize: 14 }}>{item?.patient?.name}</div>
                            <div style={{ fontWeight: '600', fontSize: 10 }}>{item?.patient?.ssn}</div>
                        </div>,
                        _otherClinic: <div>
                            <div>{this.props._page === 'created' ? item?.receiver?.name : item?.sender?.name}</div>
                            {(this.props._page === 'created' && item?.receiver?.address) ? <div>
                                {item?.receiver?.address?.split?.(';')?.map?.(address => <p style={{ fontSize: 12, margin: 0, padding: 0 }}>{address}</p>)}
                            </div> : (this.props._page !== 'created' && item?.sender?.address) ? <div>
                                {item?.sender?.address?.split?.(';')?.map?.(address => <p style={{ fontSize: 12, margin: 0, padding: 0 }}>{address}</p>)}
                            </div> : null}
                        </div>,
                        _sendingClinic: <div>
                            <div>{item?.sender?.name}</div>
                            {item?.sender?.address ? <div>
                                {item?.sender?.address?.split?.(';')?.map?.(address => <p style={{ fontSize: 12, margin: 0, padding: 0 }}>{address}</p>)}
                            </div> : null}
                        </div>,
                        _receivingClinic: <div>
                            <div>{item?.receiver?.name}</div>
                            {item?.receiver?.address ? <div>
                                {item?.receiver?.address?.split?.(';')?.map?.(address => <p style={{ fontSize: 12, margin: 0, padding: 0 }}>{address}</p>)}
                            </div> : null}
                        </div>,

                        _date: <div>
                            <div style={{ fontSize: 13, margin: 0, padding: 0 }}>
                                {new Intl.DateTimeFormat(
                                    this.props.lang === 'se' ? 'sv-SE' : 'en-EN',
                                    {
                                        year: 'numeric',
                                        month: 'short',
                                        day: 'numeric',
                                    }
                                ).format(this.props._page === 'created' ? item?.sender?.statusTs : item?.receiver?.statusTs)}
                            </div>
                            <div style={{ fontSize: 13, margin: 0, padding: 0 }}>
                                {new Intl.DateTimeFormat(
                                    this.props.lang === 'se' ? 'sv-SE' : 'en-EN',
                                    {
                                        hour: 'numeric',
                                        minute: 'numeric'
                                    }
                                ).format(this.props._page === 'created' ? item?.sender?.statusTs : item?.receiver?.statusTs)}
                            </div>
                        </div>,
                        _subject: <div className='e-connect-list-subject' title={item?.subject}>
                            {item?.subject}
                        </div>,
                        _status: <div className={`e-connect-list-status ${['forwarded', 'forwarded-archive'].includes(this.props.type) ? (
                            this.props.type === 'forwarded' ? (
                                'e-connect-list-status-accepted'
                            ) : (
                                'e-connect-list-status-sent'
                            )
                        ) : this.props._page === 'created' ? (
                            item?.sender?.status === 'sent' ? (
                                'e-connect-list-status-sent'
                            ) : item?.sender?.status === 'rejected' ? (
                                'e-connect-list-status-rejected'
                            ) : item?.sender?.status === 'accepted' ? (
                                'e-connect-list-status-accepted'
                            ) : ''
                        ) : (
                            item?.receiver?.status === 'received' ? (
                                'e-connect-list-status-sent'
                            ) : item?.receiver?.status === 'rejected' ? (
                                'e-connect-list-status-rejected'
                            ) : item?.receiver?.status === 'in-progress' ? (
                                'e-connect-list-status-accepted'
                            ) : ''
                        )}`}>
                            {['forwarded', 'forwarded-archive'].includes(this.props.type) ? (
                                this.props.type === 'forwarded' ? (
                                    'Active'.translate(this.props.lang)
                                ) : (
                                    'Archived'.translate(this.props.lang)
                                )
                            ) : this.props._page === 'created' ? (
                                item?.sender?.status === 'created' ? (
                                    'Created'.translate(this.props.lang) // TODO
                                ) : item?.sender?.status === 'sent' ? (
                                    'Sent'.translate(this.props.lang)
                                ) : item?.sender?.status === 'rejected' ? (
                                    'Rejected'.translate(this.props.lang)
                                ) : item?.sender?.status === 'archived' ? (
                                    'Archived'.translate(this.props.lang)
                                ) : item?.sender?.status === 'accepted' ? (
                                    'Accepted'.translate(this.props.lang)
                                ) : 'N/A'
                            ) : (
                                item?.receiver?.status === 'received' ? (
                                    'Received'.translate(this.props.lang)
                                ) : item?.receiver?.status === 'rejected' ? (
                                    'Rejected'.translate(this.props.lang)
                                ) : item?.receiver?.status === 'in-progress' ? (
                                    'In Progress'.translate(this.props.lang)
                                ) : item?.receiver?.status === 'archived' ? (
                                    'Archived'.translate(this.props.lang)
                                ) : 'N/A'
                            )}
                        </div>,
                        _type: <div className={`e-connect-list-type ${item?.receiver?.type === 'internal' && item?.sender?.type === 'internal' ? 'e-connect-list-type-internal' : 'e-connect-list-type-external'}`}>
                            {item?.receiver?.type === 'internal' && item?.sender?.type === 'internal' ? (
                                'e-Connect'
                            ) : (
                                'External'.translate(this.props.lang)
                            )}
                        </div>
                    }))}
                    actions={[
                        {
                            renderComponent: (item) => {
                                let showDot = false;
                                if (this.props._page !== 'created' && ['received', 'answered'].includes(this.props.type) && !item?.receiver?.statusSeen && String(item?.receiver?.id) === String(this.props.selectedGroup) && ((
                                    item?.receiver?.status === 'received'
                                ) || (
                                        String(item?.receiver?.user) === String(this.props.uData?._id) && String(item?.receiver?.departmentId) === String(this.props.selectedClinic)
                                    ))) {
                                    showDot = true;
                                }

                                return <div className="tabel-action-tooltip">
                                    <Isvg src={eyeIcon} className="view-icon" />
                                    <div className="chat-icons-tooltip">{'Open'.translate(this.props.lang)}</div>
                                    {showDot ? <div className='ec-list-dot-indicator' /> : null}
                                </div>
                            },
                            onClick: item => {
                                this.props.onPreview?.(item);

                                if (item?._id && this.props._page === 'created' && ['created', 'sent'].includes(this.props.type) && !item?.sender?.statusSeen && String(item?.sender?.user) === String(this.props.uData?._id) && String(item?.sender?.id) === String(this.props.selectedGroup) && String(item?.sender?.departmentId) === String(this.props.selectedClinic)) {
                                    let actioner = 'sender';
                                    let id = item?._id;

                                    this.updateSeen(id, actioner);
                                } else if (item?._id && this.props._page !== 'created' && ['received', 'answered'].includes(this.props.type) && !item?.receiver?.statusSeen && String(item?.receiver?.id) === String(this.props.selectedGroup) && (item?.receiver?.status === 'received' || (String(item?.receiver?.user) === String(this.props.uData?._id) && String(item?.receiver?.departmentId) === String(this.props.selectedClinic)))) {
                                    let actioner = 'receiver';
                                    let id = item?._id;

                                    this.updateSeen(id, actioner);
                                }
                            },
                        },
                        {
                            renderComponent: (item) => {
                                let showDot = false;

                                // TODO 
                                if (this.props._page === 'created' && item?._id && this.props.eConnect?.responses?.includes?.(String(item._id)) && String(item?.sender?.user) === String(this.props.uData?._id) && String(item?.sender?.id) === String(this.props.selectedGroup) && String(item?.sender?.departmentId) === String(this.props.selectedClinic)) {
                                    showDot = true;
                                }
                                if (this.props._page === 'received') {
                                    if ((this.props.type === 'forwarded' || this.props.type === 'forwarded-archive') && this.props.eConnect?.forwarding?.includes?.(String(item._id)) && String(item?.forwarder?.user) === String(this.props.uData?._id) && String(item?.forwarder?.id) === String(this.props.selectedGroup) && String(item?.forwarder?.departmentId) === String(this.props.selectedClinic)) {
                                        showDot = true;
                                    } else {
                                        // if (item?.forward && !item.forward.receiverSeen && this.props.eConnect?.responses?.includes?.(String(item._id)) && String(item?.receiver?.user) === String(this.props.uData?._id) && String(item?.receiver?.id) === String(this.props.selectedGroup) && String(item?.receiver?.departmentId) === String(this.props.selectedClinic)) showDot = true;
                                    }
                                }

                                return <div className="tabel-action-tooltip" style={{ width: 24, height: 22 }}>
                                    <Isvg src={referralResponseIcon} className="view-icon" />
                                    <div className="chat-icons-tooltip">{'Comment'.translate(this.props.lang)}</div>
                                    {showDot ? <div className='ec-list-dot-indicator' /> : null}
                                </div>
                            },
                            condition: item => (item && (item.answer || item.forward)) ? true : false,
                            onClick: item => {
                                this.props.onReferralResponse?.(item);

                                if (this.props._page === 'created' && item?._id && this.props.eConnect?.responses?.includes?.(String(item._id)) && String(item?.sender?.user) === String(this.props.uData?._id) && String(item?.sender?.id) === String(this.props.selectedGroup) && String(item?.sender?.departmentId) === String(this.props.selectedClinic)) {
                                    this.updateSeen(item._id, 'sender', true);
                                }
                                if (this.props._page === 'received') {
                                    if ((this.props.type === 'forwarded' || this.props.type === 'forwarded-archive') && this.props.eConnect?.forwarding?.includes?.(String(item._id)) && String(item?.forwarder?.user) === String(this.props.uData?._id) && String(item?.forwarder?.id) === String(this.props.selectedGroup) && String(item?.forwarder?.departmentId) === String(this.props.selectedClinic)) {
                                        this.updateSeen(item._id, 'forwarder', true);
                                    } else {
                                        // if (item?.forward && !item.forward.receiverSeen && this.props.eConnect?.responses?.includes?.(String(item._id)) && String(item?.receiver?.user) === String(this.props.uData?._id) && String(item?.receiver?.id) === String(this.props.selectedGroup) && String(item?.receiver?.departmentId) === String(this.props.selectedClinic)) this.updateSeen(item._id, 'receiver', true);
                                    }
                                }
                            },
                        },
                        {
                            renderComponent: item => {
                                let showDot = false;
                                if (item?._id && (this.props.eConnect?.chat?.received?.includes?.(String(item._id)) || this.props.eConnect?.chat?.sent?.includes?.(String(item._id)))) {
                                    showDot = true;
                                }
                                return <div className="tabel-action-tooltip" style={{ width: 24, height: 22 }}>
                                    <Isvg src={chatIcon} className="view-icon" />
                                    <div className="chat-icons-tooltip">{'Chat'.translate(this.props.lang)}</div>
                                    {showDot ? <div className='ec-list-dot-indicator' /> : null}
                                </div>
                            },
                            condition: item => {
                                if (['forwarded', 'forwarded-archive'].includes(this.props.type)) return false;
                                if ((this.props.uData?._id && item?.answer && item.sender?.type === 'internal' && item.receiver?.type === 'internal' && ['accepted', 'archived'].includes(item.sender?.status) && ['in-progress', 'archived'].includes(item?.receiver?.status) && (this.props.uData?._id === item.sender?.user || this.props.uData?._id === item.receiver?.user))) {
                                    if (this.props._page === 'created') {
                                        if (this.props.type === 'archive') {
                                            if (item.conversationId) return true;
                                            return false;
                                        } else {
                                            return true;
                                        }
                                    } else {
                                        if (this.props.type === 'received-archive') {
                                            if (item.conversationId) return true;
                                            return false;
                                        } else {
                                            return true;
                                        }
                                    }
                                }

                                return false;
                            },
                            onClick: async item => {
                                if (!item.conversationId) {
                                    const conversationId = await this.getConversationId(item?._id);
                                    if (!conversationId) return;
                                    item.conversationId = conversationId;
                                }

                                this.props.onChat?.(item);
                            }
                        },
                        {
                            renderComponent: item => {
                                let showDot = false;
                                if (this.props._page === 'created' && item?._id && this.props.eConnect?.monitoring?.includes?.(String(item._id))) {
                                    showDot = true;
                                }
                                return <div className="tabel-action-tooltip" style={{ width: 24, height: 22 }}>
                                    <Isvg src={monitoringIcon} className="view-icon" />
                                    <div className="chat-icons-tooltip">{'Referral Monitoring'.translate(this.props.lang)}</div>
                                    {showDot ? <div className='ec-list-dot-indicator' /> : null}
                                </div>
                            },
                            condition: item => this.props._page === 'created' && ['sent', 'accepted'].includes(item?.sender?.status) && item?.sender?.user && String(item?.sender?.user) === String(this.props.uData?._id) ? true : false,
                            onClick: item => this.props.onMonitoring?.(item)
                        },
                        {
                            component: <div className="tabel-action-tooltip" style={{ width: 24, height: 22 }}>
                                <Isvg src={historyIcon} className="view-icon" />
                                <div className="chat-icons-tooltip">{'View history'.translate(this.props.lang)}</div>
                            </div>,
                            condition: item => item?.history ? true : false,
                            onClick: item => this.props.onHistory?.(item),
                        },
                        {
                            component: <div className="tabel-action-tooltip" style={{ width: 24, height: 22 }}>
                                <Isvg src={pdfIcon} className="view-icon" />
                                <div className="chat-icons-tooltip">{'Save as PDF'.translate(this.props.lang)}</div>
                            </div>,
                            condition: item => item?._id ? true : false,
                            onClick: item => this.props.onPrint?.(item),
                        },
                        {
                            component: <div className="tabel-action-tooltip" style={{ width: 24, height: 22 }}>
                                <Isvg src={archiveIcon} className="view-icon" />
                                <div className="chat-icons-tooltip">{'Archive case'.translate(this.props.lang)}</div>
                            </div>,
                            condition: item => {
                                if (this.props.type === 'forwarded-archive') return false;

                                if (this.props.type === 'forwarded') {
                                    if (item?.forwarder?.user && String(item?.forwarder?.user) === String(this.props.uData?._id)) return true;
                                }

                                if (this.props._page === 'created') {
                                    if (item?.sender?.status && ['rejected', 'accepted'].includes(item?.sender?.status) && (String(item?.sender?.user) === String(this.props.uData?._id) || this.checkPermissions())) return true;
                                    return false;
                                } else {
                                    if (item?.receiver?.status && ['rejected', 'in-progress'].includes(item?.receiver?.status) && (String(item?.receiver?.user) === String(this.props.uData?._id) || this.checkPermissions())) return true;
                                    return false;
                                }
                                return false;
                            },
                            onClick: item => this.props.onArchive?.(item),
                        },
                        {
                            component: <div className="tabel-action-tooltip" style={{ width: 24, height: 22 }}>
                                <Isvg src={trashIcon} className="view-icon" />
                                <div className="chat-icons-tooltip">{'Delete'.translate(this.props.lang)}</div>
                            </div>,
                            condition: item => this.props.type === 'created' && this.props._page === 'created' && item?.sender?.status === 'created' && this.props.uData?._id && (String(this.props.uData?._id) === String(item?.sender?.user)) ? true : false,
                            onClick: item => this.props.onDelete?.(item),
                        },
                    ]}
                    params={params}
                    sortField={params.sortField}
                    sortType={params.sortType}
                    updateSort={this.updateSort}
                    updateParams={this.updateParams}
                    updateMultipleParams={this.updateMultipleParams}
                />
            </>
        )
    }
}

export default EconnectCasesList