export const UPLOAD_EXTENSIONS = {
    "image": ['png', 'jpg', 'jpeg', 'gif', 'svg'],
    "document": ['txt', 'doc', 'docx', 'pdf', 'rtf'],
    "video": ['mp4', 'avi', 'webm', 'mov']
}





