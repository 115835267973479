import React from 'react';

import {
    Modal, ModalHeader, ModalBody, ModalFooter, Button, Label
} from 'reactstrap';

import FormBuilder from './forms/formBuilder';
import { required, emailAddress, dateValidate, duplicateEmail } from './forms/validation'

import { formValueSelector, change } from "redux-form"; // ES6
import { connect } from "react-redux";


import { API_ENDPOINT } from '../constants'
function parseJSON(response) {
    return response.json().then(json => {
        return {
            result: json,
            status: response.status
        }
    })
}



/**
* A model for entering patient data is displayed if not all required data is available
* @author Milan Stanojevic
*/
class PatientMandatoryDataModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            groupObj: null
        }
    }

    componentDidMount() {


        this.getGroupId()

    }

    componentDidUpdate(prevProps) {

        if (this.props.email && prevProps.email && this.props.email !== prevProps.email) {
            this.setState({
                emailError: false
            })
        }

    }

    /**
    * Update patient's profile
    * @author Milan Stanojevic
    */
    update = (data) => {
        let obj = {
            _id: data._id,
            privacyPolicy: data.privacyPolicy,
            email: data.email,
            phone: data.phone
        }


        fetch(API_ENDPOINT + '/users/account/update', {
            method: 'POST',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
            body: JSON.stringify(obj)
        }).then(parseJSON).then(({ result, status }) => {
            if (result.error) {
                this.setState({ error: result.error.translate(this.props.lang) })
            } else if (result.emailError) {
                this.setState({
                    emailError: result.emailError.translate(this.props.lang)
                })
            } else {
                this.setState({
                    message: result.message
                })
                this.props.verifyUser(() => {
                    if (this.props.callback) {
                        this.props.callback()
                    }
                });


            }

        })


    }


    getGroupId = () => {
        let groupAlias;
        if (typeof window != 'undefined' && localStorage.groupAlias) {
            groupAlias = localStorage.groupAlias;
        } else if (this.props[0].match.params.alias) {
            groupAlias = this.props[0].match.params.alias;
        }

        if (groupAlias) {


            fetch(API_ENDPOINT + "/data/clinic-group/info", {
                method: "POST",
                headers: {
                    Authorization:
                        typeof localStorage !== "undefined"
                            ? `Bearer ${localStorage.getItem("authToken")}`
                            : null,
                    "content-type": "application/json",
                },
                body: JSON.stringify({ alias: groupAlias }),
            }).then(parseJSON).then(({ result, status }) => {
                if (result && result._id) {
                    this.setState({ groupObj: result })
                }
            });
        }

    }

    render() {
        const { groupObj } = this.state;
        let showPrivacyPolicyCheckbox = false;
        if (this.state.groupObj && this.state.groupObj._id && this.props.uData && (this.props.uData.userLevel === 1 || this.props.uData.userLevel === 2 || (this.props.uData.userLevel === 20 && typeof window != 'undefined' && localStorage?.getItem?.('profile') === 'patient')) && (!this.props.uData.privacyPolicy || !this.props.uData.privacyPolicy[String(this.state.groupObj?._id)])) {
            showPrivacyPolicyCheckbox = true
        }
        let label2;

        if (showPrivacyPolicyCheckbox) {
            let privacyPolicy = groupObj.privacyPolicy && groupObj.privacyPolicy.file ? `${API_ENDPOINT}${groupObj.privacyPolicy.file}` : null;

            label2 =
                <div className='privacy-policy-field-label-wrap' style={{ marginTop: 20 }}>
                    <p style={{ fontSize: 14 }}> {'You can read about how we handle your personal data:'.translate(this.props.lang)} {privacyPolicy ? <a href={privacyPolicy} target="_blank">{'Privacy Policy'.translate(this.props.lang)}</a> : 'Privacy Policy'.translate(this.props.lang)}.</p>
                </div>
        }
        // if (typeof window !== 'undefined' && localStorage.hidePatientModal) {
        //     return null;
        // }
        let onlyPrivacyPolicy = false;
        if (this.props?.uData?.phone && this.props?.uData?.email && groupObj?._id && !this.props?.uData?.privacyPolicy?.[groupObj?._id]) {
            onlyPrivacyPolicy = true;
        }

        if (!this.props.uData) return null;

        return (
            <Modal isOpen={this.props.isOpen} centered zIndex={1060}>

                <ModalHeader style={{ display: 'block', width: '100%' }}>
                    <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                        <p style={{ marginBottom: 0 }}>{'My profile'.translate(this.props.lang)}</p>

                    </div>


                </ModalHeader>
                <ModalBody className="bankid-modal">
                    {(
                        (this.props.uData?.email && this.props.uData?.phone) ||
                        (this.props.uData?.patientInClinic && Array.isArray(this.props.uData?.patientInClinic) && this.props.uData?.patientInClinic.length > 0) ||
                        (this.props.uData?.privacyPolicy && typeof this.props.uData?.privacyPolicy === 'object' && Object.keys(this.props.uData?.privacyPolicy).length > 0)
                    ) ? null : <p>
                        {'Enter your email and phone number'.translate(this.props.lang)}
                    </p>}
                    {
                        this.props.uData ?
                            <FormBuilder
                                selectedGroup={this.props.selectedGroup}
                                selectedClinic={this.props.selectedClinic}
                                countryTwoLetter={this.props.countryTwoLetter}
                                country={this.props.country}
                                dateFormat={this.props.dateFormat}
                                getStringDateTs={this.props.getStringDateTs}
                                lang={this.props.lang}
                                onSubmit={(data) => {
                                    if (showPrivacyPolicyCheckbox) {
                                        if (!data.privacyPolicy) {
                                            data.privacyPolicy = {}
                                        }
                                        if (groupObj && groupObj._id)
                                            data.privacyPolicy[groupObj._id] = true;
                                    }
                                    this.update(data)
                                }}
                                initialValues={this.props.uData}
                                toggle={() => this.setState({ form: null })}
                                invalidMessage={"Please fill in required fields".translate(this.props.lang)}
                                buttonText={showPrivacyPolicyCheckbox ? 'Agree to Terms of Service and continue'.translate(this.props.lang) : 'Save'.translate(this.props.lang)}
                                buttonsWrapClassName={'empty'}
                                afterTextComponent={label2}
                                additionalAdditionalBeforeButton={
                                    <div style={{ width: '100%' }}>
                                        {
                                            this.state.emailError || this.state.error ? (
                                                <p style={{ color: 'red' }}>{this.state.emailError || this.state.error}</p>
                                            )
                                                :
                                                null
                                        }
                                    </div>
                                }
                                additionalButton={
                                    this.props.uData && this.props.uData.userLevel == 20 ?
                                        <Button color="danger" onClick={() => {
                                            let urlSplit = this.props[0].location.pathname.split('/');
                                            let url = '/' + urlSplit[1];
                                            this.props[0].history.push(url)
                                        }}>{'Cancel'.translate(this.props.lang)}</Button>
                                        :
                                        <Button color="danger" onClick={() => this.props.signOut()}>
                                            {/* {'Logout'.translate(this.props.lang)} */}
                                            {'Cancel and sign out'.translate(this.props.lang)}
                                        </Button>
                                }
                                fields={[
                                    {
                                        type: 'row',
                                        children: [
                                            {
                                                type: 'col',
                                                width: {
                                                    lg: 12,
                                                    sm: 12,
                                                    xs: 12
                                                },
                                                children: [
                                                    {
                                                        type: 'socialSecurityNumber',
                                                        disabled: true,
                                                        name: 'socialSecurityNumber',
                                                        label: this.props.country === 'uk' ? "Date of birth".translate(this.props.lang) : 'Social security number'.translate(this.props.lang),
                                                        validate: [required('Social security number is required!'.translate(this.props.lang))]

                                                    },
                                                ]
                                            },


                                            {
                                                type: 'col',
                                                width: {
                                                    lg: 12,
                                                    sm: 12,
                                                    xs: 12
                                                },
                                                children: [
                                                    {
                                                        type: 'text',
                                                        name: 'userData.name',
                                                        label: 'First and last name'.translate(this.props.lang),
                                                        disabled: true,
                                                        validate: [required('First and last name is required!'.translate(this.props.lang))]

                                                    },
                                                ]
                                            },
                                            !onlyPrivacyPolicy ?
                                                {
                                                    type: 'col',
                                                    width: {
                                                        lg: 12,
                                                        sm: 12,
                                                        xs: 12
                                                    },
                                                    children: [
                                                        {
                                                            type: 'email',
                                                            name: 'email',
                                                            label: 'Email address'.translate(this.props.lang),
                                                            disabled: this.props.uData?.email ? true : false,
                                                            validate: [required('Email address is required!'.translate(this.props.lang)), emailAddress('Invalid email address!'.translate(this.props.lang)), duplicateEmail(this.state.emailError, this.state.emailError)]
                                                        },
                                                    ]
                                                } : null,
                                            !onlyPrivacyPolicy ?
                                                {
                                                    type: 'col',
                                                    width: {
                                                        lg: 12,
                                                        sm: 12,
                                                        xs: 12
                                                    },
                                                    children: [
                                                        {
                                                            type: 'phone',
                                                            name: 'phone',
                                                            label: 'Phone number'.translate(this.props.lang),
                                                            disabled: this.props.uData?.phone ? true : false,
                                                            validate: [required('Phone number is required!'.translate(this.props.lang))]
                                                        },
                                                    ]
                                                } : null,

                                            showPrivacyPolicyCheckbox ?
                                                {
                                                    type: "col",
                                                    width: {
                                                        lg: 12,
                                                        sm: 12,
                                                        xs: 12,
                                                    },
                                                    children: [
                                                        {
                                                            type: "privacy-policy-checkbox",
                                                            name: `privacyPolicy.${groupObj?._id}`,
                                                            groupData: groupObj,
                                                            lang: this.props.lang,
                                                            firstLogin: true,
                                                        },
                                                    ],
                                                }
                                                :
                                                {
                                                    type: 'empty'
                                                },





                                        ]
                                    }
                                ]}
                            ></FormBuilder>
                            :
                            null

                    }
                    {/* <p className="error-message">{this.state.error}</p> */}
                </ModalBody>
            </Modal>
        )

    }
}

const selector2 = formValueSelector("form");


export default connect(
    (state) => {
        return {
            email: selector2(state, 'email')
        };
    },
    {

    }
)(PatientMandatoryDataModal);

