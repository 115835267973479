import React, { Component } from 'react';
import Page from '../../containers/page';

import {
    Container,
    Row,
    Col, Modal, ModalBody, ModalFooter, ModalHeader, Button, FormGroup, Label, Input
} from 'reactstrap';

import Isvg from 'react-inlinesvg'
import ListBuilder from '../../components/listBuilder';
import { getSearchParams, generateSearchLink, generateSearchLinkMultiple } from '../../helpers/linkHelper';
import moment from 'moment';
import view from '../../assets/svg/eye.svg'
import exportIcon from '../../assets/svg/export.svg';


function parseJSON(response) {
    return response.json().then(json => {
        return {
            result: json,
            status: response.status
        }
    })
}

/**
* Video call ratings list
* @author   Milan Stanojevic
*/
class ChatRatings extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props.initialData,
            form: false,
            useState: false,
            params: {
                entries: 10,
                page: 0,
                sortField: 'timestamp',
                sortType: -1
            },
            items: [],
            total: 0,
            loading: true,
            clinicGroups: [],
            selectedItems: {}
        };
    }


    get = () => {
        this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));

        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, {
                entries: 10, page: 0, sortField: 'timestamp',
                sortType: -1
            })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

        for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
            this.props.loadDataWithQuery[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, {
                entries: 10, page: 0, sortField: 'timestamp',
                sortType: -1,
                selectedGroup: this.state.selectedClinicGroup
            })).then((data) => {
                let dataToSet = { ...data }
                if (dataToSet.page || dataToSet.items || dataToSet.total) {
                    dataToSet.loading = null;
                    // console.log(dataToSet.page || dataToSet.items || dataToSet.total)
                    let checkFilter = '';
                    if (dataToSet.resultFilter) {
                        checkFilter = String(dataToSet.resultFilter)
                        delete dataToSet.resultFilter;
                    }
                    let search = this.props[0].location.search;
                    let serachParams = getSearchParams(search);
                    let searchParamsFilter = '';
                    if (serachParams.filter) {
                        searchParamsFilter = serachParams.filter;
                    }
                    if (searchParamsFilter != checkFilter) {
                        delete dataToSet.page;
                        delete dataToSet.items;
                        delete dataToSet.total;
                        delete dataToSet.loading;
                    }
                }
                this.setState({
                    ...dataToSet,
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

    }

    componentDidMount() {
        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }

        this.get();
    }

    componentDidUpdate(prevProps) {
        if (prevProps[0].location.search != this.props[0].location.search) {
            this.setState({
                loading: true
            }, () => {
                this.get();

            })
        }
        if (this.props.selectedGroup && prevProps.selectedGroup && this.props.selectedGroup != prevProps.selectedGroup && this.props.uData.userLevel == 20) {
            this.setState({
                selectedClinicGroup: this.props.selectedGroup
            })
        }
    }


    updateSort = (field, type) => {
        if (this.state.useState) {
            let state = this.state.params;
            state.sortField = field;
            state.sortType = type;
            this.setState({
                params: state
            })
        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0 }, [{ name: 'sortField', value: field }, { name: 'sortType', value: type }], false));

        }
    }

    updateParams = (name, value, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0,
                    sortField: 'timestamp',
                    sortType: -1
                };
                obj[name] = value;
                this.setState({ params: obj });
            } else {
                let obj = this.state.params;
                obj[name] = value;
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(generateSearchLink(this.props[0].location.search, { entries: 10, page: 0 }, name, value, restart));
        }
    }
    updateMultipleParams = (fields, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0,
                    sortField: 'timestamp',
                    sortType: -1
                };
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj });

            } else {
                let obj = this.state.params;
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0 }, fields, restart));
        }
    }


    getParent = (items, logId) => {
        for (let i = 0; i < items.length; i++) {
            if (items[i].id == logId) {
                return items[i];
            }
        }

        return null;
    }



    render() {
        let params = {};
        if (this.state.useState) {
            params = this.state.params;
        } else {
            params = getSearchParams(this.props[0].location.search, { entries: 10, page: 0 })
        }

        console.log(this.props.uData)

        return (
            <div className={this.props.autoHideSidebar ? "dashboard dashboard-auto-hide" : "dashboard"}>
                <a ref={(node) => this.downloadTag = node} style={{ opacity: 0 }}>download</a>
                <Container fluid>
                    <Row>
                        <Col lg="12">
                            <div className="panel">
                                <div className="panel-header">
                                    <h5 className="component-header">{this.props.linksMeta && this.props.linksMeta[this.props[0].location.pathname] ? this.props.linksMeta[this.props[0].location.pathname][this.props.lang] : ''}</h5>

                                </div>
                                {this.props.uData.userLevel == 100 ?
                                    <FormGroup style={{ marginBottom: 20 }}>
                                        <Label>{'Clinic group'.translate(this.props.lang)}</Label>
                                        <Input type="select" style={{ width: '30%' }} value={this.state.selectedClinicGroup} onChange={(e) => {
                                            console.log(e.target.value)
                                            if (e.target.value != -1) {
                                                this.setState({ selectedClinicGroup: e.target.value }, this.get)
                                            } else {
                                                this.setState({ selectedClinicGroup: null }, this.get)
                                            }
                                        }}>
                                            <option value={-1}>{'Select clinic group'.translate(this.props.lang)}</option>
                                            {
                                                this.state.clinicGroups && this.state.clinicGroups.map((item, idx) => {
                                                    return (
                                                        <option value={item._id}>{item.name}</option>
                                                    )
                                                })
                                            }
                                        </Input>
                                    </FormGroup>
                                    : null}

                                <ListBuilder
                                    lang={this.props.lang}
                                    loading={this.state.loading}
                                    total={this.state.total}
                                    showNumeration={false}
                                    fields={[
                                        { type: 'text', name: 'rating1', label: 'How did you experience your visit?'.translate(this.props.lang), allowSort: true },
                                        { type: 'text', name: 'rating2', label: `How did you experience the staff's competence?`.translate(this.props.lang), allowSort: true },
                                        { type: 'text', name: 'rating3', label: 'How did you experience the treatment?'.translate(this.props.lang), allowSort: true },
                                        { type: 'text', name: 'ratingAverage', label: 'Average'.translate(this.props.lang), allowSort: true },
                                        { type: 'text', name: 'comment', label: 'Comment'.translate(this.props.lang), allowSort: true },
                                        { type: 'text', name: 'groupName', label: 'Clinic'.translate(this.props.lang), allowSort: true },


                                    ]}
                                    rawItems={this.state.items}
                                    items={this.state.items.map(item => {
                                        return {
                                            ...item,
                                        }
                                    })}
                                    actions={
                                        [

                                            {
                                                component: <Isvg src={view} className="view-icon" />,
                                                onClick: (item) => this.setState({ ratingView: item })
                                            },
                                        ]
                                    }
                                    params={params}
                                    sortField={params.sortField}
                                    sortType={params.sortType}
                                    updateSort={this.updateSort}
                                    updateParams={this.updateParams}
                                    updateMultipleParams={this.updateMultipleParams}
                                ></ListBuilder>
                            </div>
                        </Col>
                    </Row>

                </Container>

                {
                    this.state.ratingView ?
                        <Modal isOpen={this.state.ratingView} centered>
                            <ModalHeader style={{ margin: 'auto' }}>{'Ratings'.translate(this.props.lang)}</ModalHeader>
                            <ModalBody className="delete-modal">
                                <div className="message">
                                    <p style={{ fontWeight: 600 }}>{'How did you experience your visit?'.translate(this.props.lang)}</p>
                                    <p>{this.state.ratingView && this.state.ratingView.rating1 ? this.state.ratingView.rating1 : 0}</p>
                                    <p style={{ fontWeight: 600 }}>{`How did you experience the staff's competence?`.translate(this.props.lang)}</p>
                                    <p>{this.state.ratingView && this.state.ratingView.rating2 ? this.state.ratingView.rating2 : 0}</p>
                                    <p style={{ fontWeight: 600 }}>{'How did you experience the treatment?'.translate(this.props.lang)}</p>
                                    <p>{this.state.ratingView && this.state.ratingView.rating3 ? this.state.ratingView.rating3 : 0}</p>
                                    <p style={{ fontWeight: 600 }}>{'Rating average'.translate(this.props.lang)}</p>
                                    <p>{this.state.ratingView && this.state.ratingView.ratingAverage ? this.state.ratingView.ratingAverage : 0}</p>
                                    <p style={{ fontWeight: 600 }}>{'Comment'.translate(this.props.lang)}</p>
                                    <p>{this.state.ratingView && this.state.ratingView.comment ? this.state.ratingView.comment : ''}</p>

                                </div>
                            </ModalBody>
                            <ModalFooter style={{ display: 'flex', justifyContent: 'center' }}>

                                <Button color="primary" onClick={() => this.setState({
                                    ratingView: null,
                                })} >{'Close'.translate(this.props.lang)}</Button>

                            </ModalFooter>
                        </Modal>
                        :
                        null
                }

            </div>
        );
    }
}

export default Page(ChatRatings);