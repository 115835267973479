import React from 'react';
import info from '../../../assets/svg/info.svg'
import Isvg from 'react-inlinesvg';
import { API_ENDPOINT, GOOGLE_API_KEY } from '../../../constants';
import ListBuilder from '../../../components/listBuilder';
import { getSearchParams, generateSearchLink, generateSearchLinkMultiple } from '../../../helpers/linkHelper';
import garbageIcon from '../../../assets/svg/garbage.svg';
import viewIcon from '../../../assets/svg/eye.svg';
import xIcon from '../../../assets/svg/x.svg';
import userIcon from '../../../assets/svg/people.svg';
import edit from '../../../assets/svg/edit.svg'
import Phone from '../../../components/forms/fields/phone';
import {
    Container,
    Row,
    Col,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Input,
    FormGroup,
    Label,
    Button,
} from "reactstrap";

function parseJSON(response) {
    return response.json().then(json => {
        return {
            result: json,
            status: response.status
        }
    })
}

const loadDataWithQuery = [
    (fetchFunction, lang, match, query) => {
        return fetchFunction(API_ENDPOINT + '/users/users/patient/search/' + query.patientId, {
            method: 'POST',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
            body: JSON.stringify({
                page: query.page,
                entries: query.entries,
                filter: query.filter,
                sortType: query.sortType,
                sortField: query.sortField,
            })
        }).then(parseJSON).then(({ result, status }) => {
            if (status >= 200 && status < 300)

                return {
                    page: query.page ? query.page : 0,
                    items: result.items,
                    total: result.total,
                }
        })

    },
]



/**
* Info modal 
* @author   Milan Stanojevic
*/
class InfoModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            useState: true,
            ...props.initialData,
            params: {
                entries: 10,
                page: 0,
                // 'query': null
            },
            items: [],
            total: 0,


        }

    }


    get = () => {
        let params = this.state.useState ? { ...this.state.params, patientId: this.props.patientID } : getSearchParams(this.props[0].location.search, { entries: 10, page: 0, 'query': null, patientId: this.props.patientID });

        this.setState({
            _ts: params._ts
        }, () => {
            for (let i = 0; i < loadDataWithQuery.length; i++) {
                loadDataWithQuery[i](window.fetch, this.props.lang, this.props[0].match, params).then((data) => {
                    // if (data && data._clinicSearchTs && this.state._ts == data._clinicSearchTs || (data && !data._clinicSearchTs)) {

                    this.setState({
                        ...data,
                        loading: null
                    })
                    // }
                })
            }

        })
    }

    componentDidUpdate(prevProps, prevState) {

        if (JSON.stringify(prevState.params) != JSON.stringify(this.state.params)) {
            this.get();
        }
        // if(prevProps[0].location.pathname != this.props[0].location.pathname){
        //     this.get()
        // }
        if (this.props.userConnected) {
            this.get()
            this.props.listUpdate()
        }

    }


    componentDidMount() {

        this.get();

        if (this.props.isOpen) {
            this.setState({
                isOpen: this.props.isOpen
            })
        }
    }

    updateSort = (field, type) => {
        if (this.state.useState) {
            let state = this.state.params;
            state.sortField = field;
            state.sortType = type;
            this.setState({
                params: state
            }, () => {
                this.get()
            })

            this.setState({
                _useStateTs: new Date().getTime()
            })

        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0 }, [], false));

        }
    }

    updateParams = (name, value, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };

                obj[name] = value;

                this.setState({ params: obj }, () => this.get());
            } else {
                let obj = this.state.params;
                obj[name] = value;


                this.setState({ params: obj }, () => this.get())
            }

            this.setState({
                _useStateTs: new Date().getTime()
            })
        } else {
            this.props[0].history.push(generateSearchLink(this.props[0].location.search, { entries: 10, page: 0 }, name, value, restart));
        }
    }

    updateMultipleParams = (fields, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj });

            } else {
                let obj = this.state.params;
                for (let i = 0; i < fields.length; i++) {

                    obj[fields[i].name] = fields[i].value;

                }
                this.setState({ params: obj })
            }

            this.setState({
                _useStateTs: new Date().getTime()
            })

        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0 }, fields, restart));
        }
    }

    deleteLinkUser(data) {
        fetch(API_ENDPOINT + '/users/users/patient/search/delete', {
            method: 'POST',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
            body: JSON.stringify({
                parentPatient: this.props.patientID,
                id: data._id
            })
        }).then(parseJSON).then(({ result, status }) => {
            if (result) {
                this.get()
            }
        })

    }

    updatePatientProfile = (obj) => {
        let data = {
            email: obj.email,
            phone: obj.phone,
            city: obj.city,
            street: obj.street,
            zip: obj.zip,
            userData: obj.userData

        }
        this.setState({
            loading: true
        }, () => {
            fetch(API_ENDPOINT + '/users/users/' + obj._id, {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify(data)
            }).then(res => res.json()).then((result) => {
                if (!result.error) {
                    // this.searchPatient(obj.socialSecurityNumber, 1)
                    this.setState({
                        patientInfo: null,
                        succesMessage: 'You have successfully updated patient information.'.translate(this.props.lang),
                        loading: false,
                    })
                } else {
                    this.setState({
                        error: result.error,
                        loading: false
                    })
                }

            })
        })
    }

    render() {
        let params = {};
        if (this.state.useState) {
            params = this.state.params;
        } else {
            params = getSearchParams(this.props[0].location.search, { entries: 10, page: 0 })
        }

        return (
            <div>
                <Modal isOpen={this.state.isOpen} toggle={this.props.toggle} centered size={this.props.size ? this.props.size : null}>

                    <ModalHeader style={{ margin: 'auto' }} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.props.onClose()}><Isvg src={xIcon} /></button>}>
                        {"Linked accounts".translate(this.props.lang)}
                    </ModalHeader>

                    <ModalBody className="delete-modal">
                        <div>
                            <ListBuilder
                                lang={this.props.lang}
                                loading={this.state.loading}
                                total={this.state.total}
                                showNumeration={false}
                                filterMoveRight={true}
                                disableShowEntries
                                disableFilterResults
                                actionLabel={'Action'.translate(this.props.lang)}
                                fields={
                                    [
                                        { type: 'text', name: 'name', label: 'Personal information'.translate(this.props.lang), allowSort: true },
                                        { type: 'text', name: 'relationn', label: 'Relation'.translate(this.props.lang), allowSort: false },
                                        { type: 'text', name: 'gender', label: 'Gender'.translate(this.props.lang), allowSort: false },
                                        { type: 'text', name: 'comment', label: 'Comment'.translate(this.props.lang), allowSort: false },

                                    ]
                                }

                                rawItems={this.state.items}
                                items={this.state.items.map((item, idx) => {

                                    return {
                                        ...item,
                                        name: <div>{item.name}<br /><span>{item.socialSecurityNumber} </span></div>,
                                        gender: item.userData && item.userData.sex ? item.userData.sex == 'male' ? 'Male'.translate(this.props.lang) : item.userData.sex == 'female' ? 'Female'.translate(this.props.lang) : item.userData.sex.translate(this.props.lang) : '',
                                        relationn: this.props.patient && ((this.props.patient.parentUser && this.props.patient.parentUser == item._id) || (this.props.patient.parentUser2 && this.props.patient.parentUser2 == item._id)) ? 'Parent'.translate(this.props.lang) :
                                            item.relation && item.relation.length ? item.relation.map(relation => {
                                                console.log(item._id);
                                                if (relation.userID == this.props.patientID) {
                                                    return (
                                                        <div>{relation.relationship.translate(this.props.lang)}</div>
                                                    )
                                                }
                                            }) : 'Barn'.translate(this.props.lang),
                                        comment: item.relation && item.relation.length ? item.relation.map(relation => {
                                            if (relation.userID == this.props.patientID) {
                                                return (
                                                    <div>{relation.coment}</div>
                                                )
                                            }
                                        }) : '',

                                    }
                                })}


                                actions={
                                    [{
                                        component: <div className="tabel-action-tooltip"><Isvg src={garbageIcon} style={{ width: 24, minWidth: 24 }} /><div style={{ width: 180 }} className="chat-icons-tooltip">{'Delete'.translate(this.props.lang)}</div> </div>,
                                        onClick: (item) => this.deleteLinkUser(item),
                                        condition: (item) => {
                                            if (item.relation && item.relation.length && item.relation.filter(rel => rel.userID == this.props.patientID).length) {
                                                return true
                                            } else {
                                                return false
                                            }
                                        },
                                    },

                                    {
                                        component: <div className="tabel-action-tooltip"><Isvg src={viewIcon} style={{ width: 24, minWidth: 24 }} /><div style={{ width: 180 }} className="chat-icons-tooltip">{'Open patient'.translate(this.props.lang)}</div> </div>,
                                        onClick: (item) => {
                                            this.props.openPatient(item.socialSecurityNumber)
                                        },
                                        condition: (item) => {
                                            // if ((item.relation && item.relation.length && item.relation.filter(rel => rel.userID == this.props.patientID).length) || (item.parentUser && item.parentUser == this.props.patientID)) {
                                            return true
                                            // } else {
                                            //     return false
                                            // }
                                        },
                                    },

                                    ]
                                }
                                params={params}
                                sortField={params.sortField}
                                sortType={params.sortType}
                                updateSort={this.updateSort}
                                updateParams={this.updateParams}
                                updateMultipleParams={this.updateMultipleParams}
                            ></ListBuilder>
                        </div >

                    </ModalBody>
                    <ModalFooter className="buttons-right-blue">
                        <Button color="primary" style={{ marginRight: 0 }} onClick={() => this.props.newAccount(true)}>{'Link new account'.translate(this.props.lang)}</Button>
                    </ModalFooter>
                </Modal>

                {/* {this.state.patientInfo ?
                    <Modal isOpen={this.state.patientInfo} centered size='lg'>
                        <ModalHeader toggle={() => this.setState({ patientInfo: !this.state.patientInfo })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ patientInfo: !this.state.patientInfo })}><Isvg src={xIcon} /></button>}>
                            <h4>{'Patient profile'.translate(this.props.lang)}</h4>
                        </ModalHeader>
                        <ModalBody>

                            <div className="patient-info-form">
                                <div>
                                    <p><strong>{'Name'.translate(this.props.lang)}:</strong> <span>{this.state.patientInfo && this.state.patientInfo.userData && this.state.patientInfo.userData.name}</span></p>
                                    <p><strong>{'Social security number'.translate(this.props.lang)}:</strong> <span>{this.state.patientInfo && this.state.patientInfo.socialSecurityNumber}</span></p>

                                    <p><strong>{'Sex'.translate(this.props.lang)}:</strong>
                                        <span style={{ width: '100%' }}>
                                            <FormGroup className="form-group-patient-info">
                                                <Input type="select" value={this.state.patientInfo.userData.sex} onChange={(e) => {
                                                    if (e.target.value == 'male' || e.target.value == 'female') {
                                                        let patientInfo = this.state.patientInfo;
                                                        patientInfo.userData.sex = e.target.value;
                                                        this.setState({ patientInfo })
                                                    } else {
                                                        let patientInfo = this.state.patientInfo;
                                                        patientInfo.userData.sex = null;
                                                        this.setState({ patientInfo })
                                                    }

                                                }}>
                                                    <option value={0}>{'Select'.translate(this.props.lang)}</option>
                                                    <option value={'male'}>{'Male'.translate(this.props.lang)}</option>
                                                    <option value={'female'}>{'Female'.translate(this.props.lang)}</option>
                                                </Input>
                                            </FormGroup>
                                        </span>
                                    </p>
                                </div>

                                <h6>{'Contact information'.translate(this.props.lang)}:</h6>
                                <div style={{ wordBreak: 'break-all' }}>
                                    <p><strong>{'Address'.translate(this.props.lang)}:</strong>
                                        <span style={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
                                            <span>{`${this.state.patientInfo && this.state.patientInfo.street ? this.state.patientInfo.street : ''} ${this.state.patientInfo && this.state.patientInfo.zip ? this.state.patientInfo.zip : ''} ${this.state.patientInfo && this.state.patientInfo.city ? this.state.patientInfo.city : ''}`}</span>
                                            <div className="edit-addres-wrap" onClick={() => this.setState({ editAddress: {
                                                street: this.state.patientInfo.street,
                                                city: this.state.patientInfo.city,
                                                zip: this.state.patientInfo.zip
                                            } })}><Isvg src={edit} /></div>
                                        </span>
                                    </p>
                                    <p><strong>{'Phone'.translate(this.props.lang)}:</strong>
                                        <span style={{ width: '100%' }}>
                                            <Phone value={this.state.patientInfo.phone} onChange={(e) => {
                                                let patientInfo = this.state.patientInfo;
                                                patientInfo.phone = e;
                                                this.setState({ patientInfo })
                                            }} />
                                        </span>
                                    </p>
                                    <p><strong>{'E-mail'.translate(this.props.lang)}:</strong>
                                        <span style={{ width: '100%' }}>
                                            <FormGroup className="form-group-patient-info2">
                                                <Input type='text' value={this.state.patientInfo.email} onChange={(e) => {
                                                    let patientInfo = this.state.patientInfo;
                                                    patientInfo.email = e.target.value;
                                                    this.setState({ patientInfo })
                                                }} />
                                            </FormGroup>
                                        </span>
                                    </p>

                                </div>
                                <h6>{'Related parties'.translate(this.props.lang)}:</h6>
                                <div>
                                    <p><strong>{'Name'.translate(this.props.lang)}:</strong> <span style={{ width: '100%' }}><Input type="text" value={this.state.patientInfo.userData.relatedParties.name} onChange={(e) => {
                                        let patientInfo = this.state.patientInfo;
                                        patientInfo.userData.relatedParties.name = e.target.value;
                                        this.setState({ patientInfo })
                                    }} /></span></p>
                                    <p><strong>{'Relation'.translate(this.props.lang)}:</strong> <span style={{ width: '100%' }}><Input type="text" value={this.state.patientInfo.userData.relatedParties.relations} onChange={(e) => {
                                        let patientInfo = this.state.patientInfo;
                                        patientInfo.userData.relatedParties.relations = e.target.value;
                                        this.setState({ patientInfo })
                                    }} /></span></p>
                                    <p><strong>{'Phone 1'.translate(this.props.lang)}:</strong>
                                        <span style={{ width: '100%' }}>
                                            <Phone value={this.state.patientInfo.userData.relatedParties.phone} onChange={(e) => {
                                                let patientInfo = this.state.patientInfo;
                                                patientInfo.userData.relatedParties.phone = e;
                                                this.setState({ patientInfo })
                                            }} />

                                        </span>
                                    </p>
                                    <p><strong>{'Phone 2'.translate(this.props.lang)}:</strong>
                                        <span style={{ width: '100%' }}>
                                            <Phone value={this.state.patientInfo.userData.relatedParties.phone2} onChange={(e) => {
                                                let patientInfo = this.state.patientInfo;
                                                patientInfo.userData.relatedParties.phone2 = e;
                                                this.setState({ patientInfo })
                                            }} />

                                        </span>
                                    </p>

                                </div>

                                <Label>{'Other information'.translate(this.props.lang)}:</Label>
                                <Input type="textarea" style={{ height: 100 }} value={this.state.patientInfo.userData.otherInformations} onChange={(e) => {
                                    let patientInfo = this.state.patientInfo;
                                    patientInfo.userData.otherInformations = e.target.value;
                                    this.setState({ patientInfo })
                                }} />

                            </div>


                        </ModalBody>
                        <ModalFooter className='buttons-right-blue'>
                            <Button color="primary" onClick={() => this.setState({ patientInfo: !this.state.patientInfo })}>{'Close'.translate(this.props.lang)}</Button>
                            <Button color="primary" onClick={() => {
                                this.updatePatientProfile(this.state.patientInfo)

                            }}>{'Save'.translate(this.props.lang)}</Button>
                        </ModalFooter>
                    </Modal>

                    : null} */}

                {/* {
                    this.state.editAddress ?
                        <Modal isOpen={this.state.editAddress} centered>
                            <ModalHeader toggle={() => this.setState({ editAddress: !this.state.editAddress })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ editAddress: !this.state.editAddress })}><Isvg src={xIcon} /></button>}>
                                <h4>{'Edit address'.translate(this.props.lang)}</h4>
                            </ModalHeader>
                            <ModalBody>
                                <FormGroup>
                                    <Label>{'Street'.translate(this.props.lang)}</Label>
                                    <Input type='text' value={this.state.editAddress.street} onChange={(e) => {
                                        let editAddress = this.state.editAddress;
                                        editAddress.street = e.target.value;
                                        this.setState({ editAddress })
                                    }} />
                                </FormGroup>
                                <FormGroup>
                                    <Label>{'City'.translate(this.props.lang)}</Label>
                                    <Input type='text' value={this.state.editAddress.city} onChange={(e) => {
                                        let editAddress = this.state.editAddress;
                                        editAddress.city = e.target.value;
                                        this.setState({ editAddress })
                                    }} />
                                </FormGroup>
                                <FormGroup>
                                    <Label>{'Zip'.translate(this.props.lang)}</Label>
                                    <Input type='text' value={this.state.editAddress.zip} onChange={(e) => {
                                        let editAddress = this.state.editAddress;
                                        editAddress.zip = e.target.value;
                                        this.setState({ editAddress })
                                    }} />
                                </FormGroup>

                            </ModalBody>
                            <ModalFooter className='buttons-right-blue'>
                                <Button color="primary" onClick={() => {
                                    let patientInfo = this.state.patientInfo;
                                    patientInfo.city = this.state.editAddress.city;
                                    patientInfo.zip = this.state.editAddress.zip;
                                    patientInfo.street = this.state.editAddress.street;
                                    // patientInfo.address = `${this.state.editAddress && this.state.editAddress.street ? this.state.editAddress.street : ''} ${this.state.editAddress && this.state.editAddress.zip ? this.state.editAddress.zip : ''} ${this.state.editAddress && this.state.editAddress.city ? this.state.editAddress.city : ''}`;

                                    this.setState({ patientInfo, editAddress: null }, () => {
                                        // this.updatePatientAddress(this.state.patientInfo)
                                    })
                                }}>{'Save'.translate(this.props.lang)}</Button>

                                <Button color="primary" onClick={() => this.setState({ editAddress: !this.state.editAddress })}>{'Close'.translate(this.props.lang)}</Button>

                            </ModalFooter>
                        </Modal>
                        :
                        null
                } */}
            </div>


        )
    }
}

export default InfoModal;