import React, { Component } from "react";
import Page from "../../containers/page";
import xIcon from '../../assets/svg/x.svg';

import {
    Container,
    Row,
    Col,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Table,
    Input,
    FormGroup,
    Label,
    Button,
} from "reactstrap";

import Isvg from "react-inlinesvg";

import { Player, Controls } from '@lottiefiles/react-lottie-player';

import FormBuilder from "../../components/forms/modalFormBuilder";
import { required } from "../../components/forms/validation";

import ListBuilder from "../../components/listBuilder";
import DeleteModal from "../../components/deleteModal";
import {
    getSearchParams,
    generateSearchLink,
    generateSearchLinkMultiple,
} from "../../helpers/linkHelper";
import { API_ENDPOINT } from "../../constants";
import { formValueSelector, change } from "redux-form"; // ES6
import { connect } from "react-redux";
import ClinicsFilter from "../../components/clinicsFilter";
import UsersFilter from "../../components/usersFilter";
import Image from "../../components/forms/fields/image";

import edit from "../../assets/svg/edit.svg";
import garabage from "../../assets/svg/garbage.svg";
import attachInvoice from '../../assets/svg/attach.svg'

import addIcon from "../../assets/svg/add-icon.svg";
import webdocImage from "../../assets/images/webdoc.png";
import Search from "../../components/search";
import profileUser from "../../assets/images/user.png";
import notification from "../../assets/svg/notification.svg";
import Usage from "../../assets/svg/usage.svg";
import exportIcon from "../../assets/svg/export.svg";
import Checkbox from '../../components/forms/fields/checkbox';
import MainClicGroupFilter from '../../components/mainClinicGroupsFilter';
import striptags from "striptags";

import moment from 'moment';
import DatePicker from '../../components/forms/fields/datePicker';

import { Pie, Bar, Line } from 'react-chartjs-2';
import downloadIcon from '../../assets/svg/download.svg';

import CanvasJSReact from '../../assets/canvasjs.react';
var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

function parseJSON(response) {
    return response.json().then(json => {
        return {
            result: json,
            status: response.status
        }
    })
}

function parseText(response) {
    return response.text().then(text => {
        return {
            result: text,
            status: response.status
        }
    })
}

class SystemMonitoring extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props.initialData,
            form: false,
            useState: false,
            params: {
                entries: 10,
                page: 0,
            },
            _loggedInUsers: 0,
            _logActiveUsers: [],
            __userLevel50: 0,
            _userLevel20: 0,
            _userLevel2: 0,
            _userName: null,
            userAgentList: false,
            hoursArrayMemoryUsageLast24: null,
            cpuCurrentPercent: 0

        };


    }

    get = () => {
        this.props.updateMeta(
            this.props.generateSeoTags(this.state, this.props.lang)
        );

        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](
                window.fetch,
                this.props.lang,
                this.props[0].match,
                this.state.useState
                    ? this.state.params
                    : getSearchParams(this.props[0].location.search, {
                        entries: 10,
                        page: 0,
                    })
            ).then((data) => {
                this.setState(
                    {
                        ...data,
                        loading: null,
                    },
                    () => {
                        this.props.updateMeta(
                            this.props.generateSeoTags(this.state, this.props.lang)
                        );
                    }
                );
            });
        }

        for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
            this.props.loadDataWithQuery[i](
                window.fetch,
                this.props.lang,
                this.props[0].match,
                this.state.useState
                    ? this.state.params
                    : getSearchParams(this.props[0].location.search, {
                        entries: 10,
                        page: 0,
                        startDateSms: this.state.startDateSms,
                        endDateSms: this.state.endDateSms,
                        group: this.state.group
                    })
            ).then((data) => {
                this.setState(
                    {
                        ...data,
                        loading: null,
                    },
                    () => {
                        this.props.updateMeta(
                            this.props.generateSeoTags(this.state, this.props.lang)
                        );
                    }
                );
            });
        }




    };
    componentWillUnmount() {

        if (this.intervalCheck) {
            clearInterval(this.intervalCheck)
        }
    }


    componentDidMount() {
        if (typeof window !== "undefined") {
            window.scrollTo(0, 0);
        }

        this.get();
        if (this.props.uData && this.props.socket && !this.state._registerEvents) {
            this.setState({
                _registerEvents: true
            }, () => {
                this.registerEvents();


            })
        } else {
            this.socketInterval = setInterval(() => {
                if (this.props.uData && this.props.socket && !this.state._registerEvents) {
                    if (this.socketInterval) {
                        clearInterval(this.socketInterval)
                    }

                    this.setState({
                        _registerEvents: true
                    }, () => {
                        this.registerEvents();


                    })
                }

            }, 150);
        }
        this.checkCpuAndMemoryUsage()
        this.intervalCheck = setInterval(() => {
            this.checkCpuAndMemoryUsage()
        }, 2000);


    }
    checkCpuAndMemoryUsage = () => {
        fetch(API_ENDPOINT + '/data-managment/usage/cpu-and-memory', {
            method: 'GET',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            }
        }).then(res => res.json()).then((result) => {
            // ------ new
            let currentData;
            if (result.currentData) {
                currentData = result.currentData;
                for (var key in currentData) {
                    currentData[key].totalMemory = currentData[key].totalMemory / 1024 / 1024;
                    currentData[key].freeMemory = currentData[key].freeMemory / 1024 / 1024;
                    currentData[key].usedMemory = currentData[key].usedMemory / 1024 / 1024;

                    if (currentData[key].totalMemory / 1024 > 1) {
                        currentData[key].totalMemory = (currentData[key].totalMemory / 1024).toFixed(2);
                        currentData[key].totalMemory = currentData[key].totalMemory.toString() + ' GB'
                    } else {
                        currentData[key].totalMemory = (currentData[key].totalMemory).toFixed(2);
                        currentData[key].totalMemory = currentData[key].totalMemory.toString() + ' MB'
                    }
                    if (currentData[key].freeMemory / 1024 > 1) {
                        currentData[key].freeMemory = (currentData[key].freeMemory / 1024).toFixed(2);
                        currentData[key].freeMemory = currentData[key].freeMemory.toString() + ' GB'
                    } else {
                        currentData[key].freeMemory = (currentData[key].freeMemory).toFixed(2);
                        currentData[key].freeMemory = currentData[key].freeMemory.toString() + ' MB'
                    }
                    if (currentData[key].usedMemory / 1024 > 1) {
                        currentData[key].usedMemory = (currentData[key].usedMemory / 1024).toFixed(2);
                        currentData[key].usedMemory = currentData[key].usedMemory.toString() + ' GB'
                    } else {
                        currentData[key].usedMemory = (currentData[key].usedMemory).toFixed(2);
                        currentData[key].usedMemory = currentData[key].usedMemory.toString() + ' MB'
                    }
                }
            }
            let hoursArrayMemoryUsageLast24Obj;
            if (result.hoursArrayMemoryUsageLast24Obj) {
                hoursArrayMemoryUsageLast24Obj = result.hoursArrayMemoryUsageLast24Obj;
                for (var key in hoursArrayMemoryUsageLast24Obj) {
                    hoursArrayMemoryUsageLast24Obj[key] = hoursArrayMemoryUsageLast24Obj[key].reverse()
                }
            }
            // ---------


            let totalMemory = result.totalMemory / 1024 / 1024;
            let freeMemory = result.freeMemory / 1024 / 1024;
            let usedMemory = result.usedMemory / 1024 / 1024;

            if (totalMemory / 1024 > 1) {
                totalMemory = (totalMemory / 1024).toFixed(2);
                totalMemory = totalMemory.toString() + ' GB'
            } else {
                totalMemory = (totalMemory).toFixed(2);
                totalMemory = totalMemory.toString() + ' MB'
            }
            if (freeMemory / 1024 > 1) {
                freeMemory = (freeMemory / 1024).toFixed(2);
                freeMemory = freeMemory.toString() + ' GB'
            } else {
                freeMemory = (freeMemory).toFixed(2);
                freeMemory = freeMemory.toString() + ' MB'
            }
            if (usedMemory / 1024 > 1) {
                usedMemory = (usedMemory / 1024).toFixed(2);
                usedMemory = usedMemory.toString() + ' GB'
            } else {
                usedMemory = (usedMemory).toFixed(2);
                usedMemory = usedMemory.toString() + ' MB'
            }
            // console.log(result.cpuPercent);
            let setObj = {
                totalMemory: totalMemory,
                freeMemory,
                usedMemory,
                currentData,
                servers: result.servers ? result.servers : null,
                IPCServerHostName: result.IPCServerHostName ? result.IPCServerHostName : null,
                hoursArrayMemoryUsageLast24Obj,
                hoursArrayMemoryUsageLast24: result.hoursArrayMemoryUsageLast24 && result.hoursArrayMemoryUsageLast24.reverse(),
                cpuCurrentPercent: result.cpuPercent ? result.cpuPercent : 0,
                apiRequest: result.apiRequest
            }


            if (!this.state.activeServer) {
                setObj.activeServer = result.servers && result.servers.length ? result.servers[0] : null;
            }


            this.setState(setObj)
        })
    }


    registerEvents = async () => {
        this.props.socket.on('loggedInUsers', (data) => {
            // console.log(data)
            this.setState({
                _loggedInUsers: Number(data.loggedInUsers),
                _userLevel50: data.userLevel50,
                _userLevel20: data.userLevel20,
                _userLevel2: data.userLevel2,
                _videoSession: data.videoSession,
                _userName: data.userName
            })
        })
        // console.log( this.props.socket)
        if (this.props.socket)
            this.props.socket.on('logActiveUsers', (data) => {
                var arr = [...this.state._logActiveUsers];
                let check = false;
                for (let i = 0; i < arr.length; i++) {
                    if (arr[i].id == data.id) {
                        arr.splice(i, 1, data)
                        check = true
                    }

                }
                if (arr.length >= 10) {
                    arr.splice(0, 1)
                }
                if (!check) {
                    arr.push(data)
                }

                this.setState({
                    _logActiveUsers: arr
                },
                    () => {
                        console.log(this.state._logActiveUsers)
                    })
            })

        this.props.socket.emit('requestLoginUsers', {})
    }

    componentDidUpdate(prevProps) {
        if (prevProps[0].location.search != this.props[0].location.search) {
            this.setState(
                {
                    loading: true,
                },
                () => {
                    this.get();
                }
            );
        }
    }




    render() {
        return (
            <div className={this.props.autoHideSidebar ? "dashboard dashboard-auto-hide" : "dashboard"}>
                <Container fluid>
                    <Row>
                        <Col lg="12">
                            <div className="panel">
                                <div className="panel-header">
                                    <h5 className="header-component">
                                        {this.props.linksMeta &&
                                            this.props.linksMeta[this.props[0].location.pathname]
                                            ? this.props.linksMeta[this.props[0].location.pathname][
                                            this.props.lang
                                            ]
                                            : ""}{" "}
                                    </h5>{" "}

                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }} className="monitoring-wrap">
                                    <h6>{'Logged in users'.translate(this.props.lang)}: <span>{this.state._loggedInUsers}</span></h6>
                                    <h6>{'Current number of API Requests'.translate(this.props.lang)}: <span>{this.state.apiRequest}</span></h6>
                                    <hr />
                                    {/* <h6>{'Active user '.translate(this.props.lang)}: <span>{this.state._logActiveUsers.id}</span> {'Endpoint '.translate(this.props.lang)}: <span>{this.state._logActiveUsers.endpoint}</span></h6> */}
                                    <h5>{'Active connection'.translate(this.props.lang)}</h5>
                                    <h6>{'User level admin '.translate(this.props.lang)}: <span>{this.state._userLevel50}</span></h6>
                                    <h6>{'User level doctor '.translate(this.props.lang)}: <span>{this.state._userLevel20}</span></h6>
                                    <h6>{'User level patient '.translate(this.props.lang)}: <span>{this.state._userLevel2}</span></h6>
                                    <h6>{'Video session number '.translate(this.props.lang)}: <span>{this.state._videoSession}</span></h6>

                                    {
                                        this.state.servers && this.state.servers.length && this.state.servers.length > 1 ?
                                            <>
                                                <div className="tabs-buttons-wrap" style={{ marginTop: 20, marginBottom: 20 }}>
                                                    {
                                                        this.state.servers.map((item, idx) => {
                                                            let tabName = item;
                                                            if (this.state.IPCServerHostName && this.state.IPCServerHostName[item]) {
                                                                tabName = this.state.IPCServerHostName[item];
                                                            }
                                                            return (
                                                                <Button className={this.state.activeServer == item ? "tab-button acitve-tab-button" : "tab-button"} onClick={() => this.setState({ activeServer: item })}>{tabName}</Button>
                                                            )
                                                        })
                                                    }

                                                </div>
                                            </>
                                            :
                                            <hr />
                                    }

                                    <h6>{'Total memory (Current)'.translate(this.props.lang)}: <span>{this.state.servers && this.state.activeServer && this.state.currentData ? this.state.currentData[this.state.activeServer].totalMemory ? this.state.currentData[this.state.activeServer].totalMemory : 0 : this.state.totalMemory}</span></h6>
                                    <h6>{'Free memory (Current)'.translate(this.props.lang)}: <span>{this.state.servers && this.state.activeServer && this.state.currentData ? this.state.currentData[this.state.activeServer].freeMemory ? this.state.currentData[this.state.activeServer].freeMemory : 0 : this.state.freeMemory}</span></h6>
                                    <h6>{'Used memory (Current)'.translate(this.props.lang)}: <span>{this.state.servers && this.state.activeServer && this.state.currentData ? this.state.currentData[this.state.activeServer].usedMemory ? this.state.currentData[this.state.activeServer].usedMemory : 0 : this.state.usedMemory}</span></h6>
                                    <hr />
                                    <h6>{'CPU usage (Current)'.translate(this.props.lang)}: <span>{this.state.servers && this.state.activeServer && this.state.currentData ? this.state.currentData[this.state.activeServer].cpuPercent ? this.state.currentData[this.state.activeServer].cpuPercent : 0 : this.state.cpuCurrentPercent}%</span></h6>
                                    <hr />

                                </div>
                                <div>
                                    {
                                        this.state.servers && this.state.activeServer && this.state.hoursArrayMemoryUsageLast24Obj && this.state.hoursArrayMemoryUsageLast24Obj[this.state.activeServer] ?
                                            <div className="chartjs-wrapper">
                                                <button onClick={() => {
                                                    const url = this.memoryUsageChart.toBase64Image();
                                                    // console.log(url)
                                                    var link = document.createElement("a");
                                                    link.download = `download.png`;
                                                    link.target = "_blank";

                                                    // Construct the URI
                                                    link.href = url;
                                                    document.body.appendChild(link);
                                                    link.click();

                                                }} className="chartjs-download-button"><Isvg src={downloadIcon} /></button>
                                                <Line
                                                    ref={(node) => this.memoryUsageChart = node}
                                                    options={{
                                                        animation: {
                                                            duration: 0
                                                        },
                                                        resposive: true,
                                                        maintainAspectRatio: false,
                                                        plugins: {
                                                            tooltip: {
                                                                callbacks: {
                                                                    label: function (tooltipItem, data) {
                                                                        return tooltipItem.parsed.y + ' GB';
                                                                    }
                                                                }
                                                            },

                                                            title: {
                                                                display: true,
                                                                padding: 20,
                                                                font: {
                                                                    size: 24,
                                                                },
                                                                text: 'Memory usage last 24h'.translate(this.props.lang)
                                                            },

                                                            legend: {
                                                                position: 'bottom',
                                                                labels: {
                                                                    usePointStyle: true,
                                                                },
                                                            }
                                                        }
                                                    }}
                                                    data={{
                                                        labels: this.state.hoursArrayMemoryUsageLast24Obj[this.state.activeServer].map((item) => item.hour),
                                                        datasets: [
                                                            {
                                                                label: 'Total memory'.translate(this.props.lang),
                                                                data: this.state.hoursArrayMemoryUsageLast24Obj[this.state.activeServer].map((item) => Number(item.stats.totalMemory)),
                                                                backgroundColor: '#4f81bc',
                                                                borderColor: '#4f81bc',
                                                                borderWidth: 1,
                                                                lineTension: 0.4
                                                            },
                                                            {
                                                                label: 'Used memory'.translate(this.props.lang),
                                                                data: this.state.hoursArrayMemoryUsageLast24Obj[this.state.activeServer].map((item) => Number(item.stats.usedMemory)),
                                                                backgroundColor: '#c0504e',
                                                                borderColor: '#c0504e',
                                                                borderWidth: 1,
                                                                lineTension: 0.4

                                                            },
                                                            {
                                                                label: 'Free memory'.translate(this.props.lang),
                                                                data: this.state.hoursArrayMemoryUsageLast24Obj[this.state.activeServer].map((item) => Number(item.stats.freeMemory)),
                                                                backgroundColor: '#9bbb58',
                                                                borderColor: '#9bbb58',
                                                                borderWidth: 1,
                                                                lineTension: 0.4

                                                            }
                                                        ]

                                                    }}
                                                ></Line>
                                            </div>
                                            :
                                            this.state.hoursArrayMemoryUsageLast24 ?
                                                <div className="chartjs-wrapper">
                                                    <button onClick={() => {
                                                        const url = this.memoryUsageChart.toBase64Image();
                                                        // console.log(url)
                                                        var link = document.createElement("a");
                                                        link.download = `download.png`;
                                                        link.target = "_blank";

                                                        // Construct the URI
                                                        link.href = url;
                                                        document.body.appendChild(link);
                                                        link.click();

                                                    }} className="chartjs-download-button"><Isvg src={downloadIcon} /></button>
                                                    <Line
                                                        ref={(node) => this.memoryUsageChart = node}
                                                        options={{
                                                            animation: {
                                                                duration: 0
                                                            },
                                                            resposive: true,
                                                            maintainAspectRatio: false,
                                                            plugins: {
                                                                tooltip: {
                                                                    callbacks: {
                                                                        label: function (tooltipItem, data) {
                                                                            return tooltipItem.parsed.y + ' GB';
                                                                        }
                                                                    }
                                                                },

                                                                title: {
                                                                    display: true,
                                                                    padding: 20,
                                                                    font: {
                                                                        size: 24,
                                                                    },
                                                                    text: 'Memory usage last 24h'.translate(this.props.lang)
                                                                },

                                                                legend: {
                                                                    position: 'bottom',
                                                                    labels: {
                                                                        usePointStyle: true,
                                                                    },
                                                                }
                                                            }
                                                        }}
                                                        data={{
                                                            labels: this.state.hoursArrayMemoryUsageLast24.map((item) => item.hour),
                                                            datasets: [
                                                                {
                                                                    label: 'Total memory'.translate(this.props.lang),
                                                                    data: this.state.hoursArrayMemoryUsageLast24.map((item) => Number(item.stats.totalMemory)),
                                                                    backgroundColor: '#4f81bc',
                                                                    borderColor: '#4f81bc',
                                                                    borderWidth: 1,
                                                                    lineTension: 0.4
                                                                },
                                                                {
                                                                    label: 'Used memory'.translate(this.props.lang),
                                                                    data: this.state.hoursArrayMemoryUsageLast24.map((item) => Number(item.stats.usedMemory)),
                                                                    backgroundColor: '#c0504e',
                                                                    borderColor: '#c0504e',
                                                                    borderWidth: 1,
                                                                    lineTension: 0.4

                                                                },
                                                                {
                                                                    label: 'Free memory'.translate(this.props.lang),
                                                                    data: this.state.hoursArrayMemoryUsageLast24.map((item) => Number(item.stats.freeMemory)),
                                                                    backgroundColor: '#9bbb58',
                                                                    borderColor: '#9bbb58',
                                                                    borderWidth: 1,
                                                                    lineTension: 0.4

                                                                }
                                                            ]

                                                        }}
                                                    ></Line>
                                                </div>

                                                :
                                                null
                                    }

                                </div>
                                <div style={{ marginTop: 30 }}>
                                    {
                                        this.state.servers && this.state.activeServer && this.state.hoursArrayMemoryUsageLast24Obj && this.state.hoursArrayMemoryUsageLast24Obj[this.state.activeServer] ?
                                            <div className="chartjs-wrapper">
                                                <button onClick={() => {
                                                    const url = this.memoryUsageChart.toBase64Image();
                                                    // console.log(url)
                                                    var link = document.createElement("a");
                                                    link.download = `download.png`;
                                                    link.target = "_blank";

                                                    // Construct the URI
                                                    link.href = url;
                                                    document.body.appendChild(link);
                                                    link.click();

                                                }} className="chartjs-download-button"><Isvg src={downloadIcon} /></button>
                                                <Line
                                                    ref={(node) => this.memoryUsageChart = node}
                                                    options={{
                                                        animation: {
                                                            duration: 0
                                                        },
                                                        resposive: true,
                                                        maintainAspectRatio: false,
                                                        plugins: {
                                                            tooltip: {
                                                                callbacks: {
                                                                    label: function (tooltipItem, data) {
                                                                        return tooltipItem.parsed.y + ' %';
                                                                    }
                                                                }
                                                            },

                                                            title: {
                                                                display: true,
                                                                padding: 20,
                                                                font: {
                                                                    size: 24,
                                                                },
                                                                text: 'CPU usage last 24h'.translate(this.props.lang)
                                                            },

                                                            legend: {
                                                                position: 'bottom',
                                                                labels: {
                                                                    usePointStyle: true,
                                                                },
                                                            }
                                                        }
                                                    }}
                                                    data={{
                                                        labels: this.state.hoursArrayMemoryUsageLast24Obj[this.state.activeServer].map((item) => item.hour),
                                                        datasets: [
                                                            {
                                                                label: 'CPU usage'.translate(this.props.lang),
                                                                data: this.state.hoursArrayMemoryUsageLast24Obj[this.state.activeServer].map((item) => Number(item.stats.cpuPercent)),
                                                                backgroundColor: '#4f81bc',
                                                                borderColor: '#4f81bc',
                                                                borderWidth: 1,
                                                                lineTension: 0.4
                                                            },
                                                        ]

                                                    }}
                                                ></Line>
                                            </div>
                                            :
                                            this.state.hoursArrayMemoryUsageLast24 ?
                                                <div className="chartjs-wrapper">
                                                    <button onClick={() => {
                                                        const url = this.memoryUsageChart.toBase64Image();
                                                        // console.log(url)
                                                        var link = document.createElement("a");
                                                        link.download = `download.png`;
                                                        link.target = "_blank";

                                                        // Construct the URI
                                                        link.href = url;
                                                        document.body.appendChild(link);
                                                        link.click();

                                                    }} className="chartjs-download-button"><Isvg src={downloadIcon} /></button>
                                                    <Line
                                                        ref={(node) => this.memoryUsageChart = node}
                                                        options={{
                                                            animation: {
                                                                duration: 0
                                                            },
                                                            resposive: true,
                                                            maintainAspectRatio: false,
                                                            plugins: {
                                                                tooltip: {
                                                                    callbacks: {
                                                                        label: function (tooltipItem, data) {
                                                                            return tooltipItem.parsed.y + ' %';
                                                                        }
                                                                    }
                                                                },

                                                                title: {
                                                                    display: true,
                                                                    padding: 20,
                                                                    font: {
                                                                        size: 24,
                                                                    },
                                                                    text: 'CPU usage last 24h'.translate(this.props.lang)
                                                                },

                                                                legend: {
                                                                    position: 'bottom',
                                                                    labels: {
                                                                        usePointStyle: true,
                                                                    },
                                                                }
                                                            }
                                                        }}
                                                        data={{
                                                            labels: this.state.hoursArrayMemoryUsageLast24.map((item) => item.hour),
                                                            datasets: [
                                                                {
                                                                    label: 'CPU usage'.translate(this.props.lang),
                                                                    data: this.state.hoursArrayMemoryUsageLast24.map((item) => Number(item.stats.cpuPercent)),
                                                                    backgroundColor: '#4f81bc',
                                                                    borderColor: '#4f81bc',
                                                                    borderWidth: 1,
                                                                    lineTension: 0.4
                                                                },
                                                            ]

                                                        }}
                                                    ></Line>
                                                </div>

                                                :
                                                null
                                    }
                                </div>
                                {this.state._logActiveUsers ?

                                    <Table striped className="relations-table">
                                        <tr>
                                            <th>{'User'.translate(this.props.lang)}</th>
                                            <th>{'Endpoint'.translate(this.props.lang)}</th>
                                            <th>{'Executing time'.translate(this.props.lang)}</th>
                                            <th>{'Method'.translate(this.props.lang)}</th>
                                            <th>{'User agent'.translate(this.props.lang)}</th>
                                        </tr>
                                        {
                                            this.state._logActiveUsers.map((item, idx) => {
                                                return (
                                                    <tr>
                                                        <td>{item.id} - {item.username}</td>
                                                        <td>{item.endpoint}</td>
                                                        <td>{item.executionTime + " ms"}</td>
                                                        <td>{item.method}</td>
                                                        <td>{this.state.userAgentList && this.state.index == idx ? '' : (item.userAgent).substring(0, 10)}<button onClick={() => this.setState({
                                                            userAgentList: !this.state.userAgentList, index: idx
                                                        })}>{this.state.userAgentList && this.state.index == idx ? item.userAgent : '...'}</button></td>

                                                    </tr>
                                                )
                                            })
                                        }
                                    </Table>

                                    : null}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div >
        );
    }
}

const selector = formValueSelector("modalForm");

export default connect(
    (state) => {
        return { group: selector(state, "group") };
    },
    {
        changeSubgroup: (value) => change("modalForm", "subgroup", value),
    }
)(Page(SystemMonitoring));
