import React, { Component } from 'react';
import Page from '../../containers/page';

import {
    Container,
    Row,
    Col, Input, Label, Modal, ModalBody, ModalHeader, FormGroup, Button, ButtonToggle, ModalFooter
} from 'reactstrap';


import FormBuilder from '../../components/forms/modalFormBuilder';
import { required } from '../../components/forms/validation';

import Isvg from 'react-inlinesvg'
import Search from '../../components/search';

import profile from '../../assets/images/profile-pic.png'
import map from '../../assets/svg/map.svg'
import chart from '../../assets/svg/chart.svg'
import edit from '../../assets/svg/edit.svg'
import garabage from '../../assets/svg/garbage.svg'
import arrowDown from '../../assets/svg/arrow-down.svg'
import arrowUp from '../../assets/svg/arrow-up.svg'
import addIcon from '../../assets/svg/add-icon.svg';

import ListBuilder from '../../components/listBuilder';
import DeleteModal from '../../components/deleteModal';
import { getSearchParams, generateSearchLink, generateSearchLinkMultiple } from '../../helpers/linkHelper';
import { API_ENDPOINT } from '../../constants';
import ErrorModal from '../../components/errorModal';

import profileUser from '../../assets/images/user.png';
import notification from '../../assets/svg/notification.svg';
import moreIcon from '../../assets/svg/more.svg';
import Iframe from 'react-iframe';

import LandingBuilder from './landingBuilder'
import defaultJson from './default.json'
import defaultInformationJson from './defaultInformation.json'
import defaultLoginJson from './defaultLogin.json'

import { Player, Controls } from '@lottiefiles/react-lottie-player';

function parseJSON(response) {
    return response.json().then(json => {
        return {
            result: json,
            status: response.status
        }
    })
}

/**
* Page for insert or update landing style
* @author   Aleksandar Dabic
*/
class AddLandingStyle extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...props.initialData,
            form: false,
            useState: false,
            params: {
                entries: 10,
                page: 0
            },
            items: [],
            total: 0,
            loading: true,
            tabIndex: 1,
            landingStyle: {},
            clinicGroupsList: []
        };
    }


    get = () => {
        this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));

        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0 })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

        for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
            this.props.loadDataWithQuery[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0 })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }



    }

    componentDidMount() {
        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }

        this.get();

        if (this.props[0].match.params.id != 'new') {
            fetch(API_ENDPOINT + '/data-managment/landing-builder-styles/' + this.props[0].match.params.id, {
                method: 'GET',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                }
            }).then(parseJSON).then(({ result, status }) => {
                if (result.error) {
                    this.setState({ error: result.error })
                } else {
                    this.setState({ landingStyle: result })

                }


            })
        } else {
            this.setState({
                landingStyle: {
                    landingJSON: defaultJson,
                    informationJSON: defaultInformationJson,
                    loginJSON: defaultLoginJson
                }
            })
        }



        window.onmessage = (e) => {
            if (e && e.data && e.data.indexOf && e.data.indexOf('changePath') !== -1) {
                this.props[0].history.push(e.data.split('||')[1])
            }
        }

        if (this.props.uData) {
            this.setState({
                loggedIn: true
            })
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps[0].location.search != this.props[0].location.search) {
            this.setState({
                loading: true
            }, () => {
                this.get();

            })
        }
    }


    updateSort = (field, type) => {
        if (this.state.useState) {
            let state = this.state.params;
            state.sortField = field;
            state.sortType = type;
            this.setState({
                params: state
            })
        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0 }, [{ name: 'sortField', value: field }, { name: 'sortType', value: type }], false));

        }
    }

    updateParams = (name, value, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };
                obj[name] = value;
                this.setState({ params: obj });
            } else {
                let obj = this.state.params;
                obj[name] = value;
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(generateSearchLink(this.props[0].location.search, { entries: 10, page: 0 }, name, value, restart));
        }
    }
    updateMultipleParams = (fields, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj });

            } else {
                let obj = this.state.params;
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0 }, fields, restart));
        }
    }






    stopAnimation = () => {
        this.player.current.play();
    }








    render() {
        let params = {};
        if (this.state.useState) {
            params = this.state.params;
        } else {
            params = getSearchParams(this.props[0].location.search, { entries: 10, page: 0 })
        }
        const defaultOptions = {
            loop: true,
            autoplay: true,
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice'
            }
        };

        return (
            <div className={this.props.autoHideSidebar ? "dashboard dashboard-auto-hide" : "dashboard"}>

                <Container fluid>

                    {/* <Row>
                        <Col lg='12' st> */}
                    <div className="panel panel-short">
                        <div className="panel-header">
                            <h5 className="component-header">{"Landing style".translate(this.props.lang)}</h5>
                        </div>


                        <div>
                            <LandingBuilder {...this.props} landingStyle={this.state.landingStyle} clinicGroupsList={this.state.clinicGroupsList} />

                        </div>
                        {/* <div className="landing-no-iframe">
                                    <h6>{"You can make changes only on desktop.".translate(this.props.lang)}</h6>
                                </div> */}


                    </div>
                    {/* </Col>
                    </Row> */}

                </Container>



            </div>
        );
    }
}

export default Page(AddLandingStyle);