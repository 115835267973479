import React from 'react';

import {
    Container,
    Row,
    Col, Input, Label, Modal, ModalBody, ModalHeader, FormGroup, Button, ButtonToggle, ModalFooter
} from 'reactstrap';

import { Rnd } from 'react-rnd';

import Isvg from 'react-inlinesvg'

import addIcon from '../../assets/svg/add-icon.svg';

import PreviewComponent from '../landingBuilder/previewComponent';


import defaultJson from '../landingBuilder/defaultMainClinicGroup.json'
import defaultInformationJson from '../landingBuilder/defaultInformation.json'
import defaultLoginJson from '../landingBuilder/defaultLogin.json'



import { Player, Controls } from "@lottiefiles/react-lottie-player";

import warrning from '../../assets/images/warrning.png';


import Toggle from '../../components/forms/fields/toggle';
import { API_ENDPOINT } from '../../constants';
import { v4 as uuidv4 } from 'uuid';


import AppBuilder from '../../appBuilder/appBuilder'

import { copyMobileToApp } from './landingHelper';

function parseJSON(response) {
    return response.json().then(json => {
        return {
            result: json,
            status: response.status,

        }
    })
}


/**
* Landing page configurator - template
* @author   Aleksandar Dabic
*/
class DragAndDropComponent extends React.Component {

    constructor(props) {
        super(props);
        this.player = React.createRef();
        this.player2 = React.createRef();
        this.state = {
            activeTab: 1,
            showPlayer: false,
            showPlayer2: false,
            infoPage: true,
            reset1: false,
            reset2: false,
            reset3: false,
            name: '',
            typeConf: 1,
            tabIndex: 1,
            layout: 'lg',
            libraryIcons: [],
            ifChange: false

        }
    }
    /**
    * Update landing page styles
    * @author   Aleksandar Dabic
    */
    update = () => {
        this.setState(
            {
                showPlayer: true,
            },
            () => {

                let data = {};


                data.landingJSON_v2 = this.state.landingJSON;
                let groupId = null;
                if (this.props.uData.mainClinicGroup && this.props.uData.mainClinicGroup._id)
                    groupId = this.props.uData.mainClinicGroup._id;
                if (groupId) {
                    this.player.current.play();
                    fetch(API_ENDPOINT + '/data/main-clinic-groups/' + groupId, {
                        method: 'POST',
                        headers: {
                            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                            'content-type': 'application/json'
                        },
                        body: JSON.stringify(data)
                    }).then(parseJSON).then(({ result, status }) => {
                        if (result.error) {
                            this.setState({ error: result.error })
                        } else {
                            this.setState({
                                showPlayer: false,
                                showPlayer2: true,
                            },
                                () => {
                                    setTimeout(() => {
                                        this.setState({ showPlayer2: false });
                                    }, 1000);
                                })
                            if (this.props.changeLinkModal) {
                                this.props[0].history.push(this.props.changeLinkModal)
                                this.props.abortAction()
                            } else {
                                this.props.onChange('done')
                                this.props.abortAction()
                            }



                            this.props.verifyUser(() => { }, () => {
                                this.props.changeGroup(data.groupId)
                            });
                        }

                    })
                }


            }
        )

        //     }
        // )
    }

    stopAnimation = () => {
        this.player.current.play();
    };

    showPlayer = () => {
        this.setState({
            showPlayer: true,
        });
    };

    hidePlayer = () => {
        this.setState({
            showPlayer: false,
        });
    };

    get = () => {
        if (this.props.landingStyle) {

            this.setState({
                landingJSON: this.props.landingStyle.landingJSON ? this.props.landingStyle.landingJSON : defaultJson,

            }, () => this.setState({ updateNeeded: uuidv4() }))
        }

        setTimeout(() => {
            this.setState({
                ifChange: true
            })

        }, 2000)

        fetch(API_ENDPOINT + '/data-managment/landing-builder-styles/library/icons', {
            method: 'GET',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
        }).then(parseJSON).then(({ result, status }) => {
            if (status >= 200 && status < 300) {
                if (result && result.gallery) {
                    this.setState({
                        libraryIcons: result.gallery,
                        loadLibrary: false
                    })
                } else {
                    this.setState({
                        libraryIcons: [],
                        loadLibrary: false
                    })
                }


            }

        })
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.landingStyle != this.props.landingStyle) {
            this.get()
        }
        if (this.props.updateLanding) {
            this.update()
            this.props.changeUpdateLanding(false)
        }
    }

    componentDidMount() {
        this.get()
    }

    setReset = () => {
        this.setState({
            landingJSON: defaultJson,
            reset: null
        }, () => this.setState({ updateNeeded: uuidv4() }))
    }

    /**
    * Import landing page template and update landing page style
    * @author   Aleksandar Dabic
    */
    importTemp = () => {



        let item = this.props.templates.filter(item => item._id == this.state.selectedTemp)[0];

        if (this.state.tabIndex == 1) {
            this.setState({ landingJSON: item.landingJSON, importModal: false, selectedTemp: null }, () => this.setState({ updateNeeded: uuidv4() }))

        } else if (this.state.tabIndex == 2) {
            this.setState({ informationJSON: item.informationJSON, importModal: false, selectedTemp: null }, () => this.setState({ updateNeeded: uuidv4() }))

        } else if (this.state.tabIndex == 3) {
            this.setState({ loginJSON: item.loginJSON, importModal: false, selectedTemp: null }, () => this.setState({ updateNeeded: uuidv4() }))
        }


        // this.update();







    }




    render() {

        return (
            <div style={{ marginTop: 50 }}>


                <div className="tabs-buttons-wrap2">
                    <div className='left-buttons'>
                        <div style={{ height: 33, width: 200, marginTop: -18 }}>
                            <Toggle value={this.state.preview} label={"Preview".translate(this.props.lang)} onChange={(e) => {
                                this.setState({ preview: !this.state.preview, importModal: false }, () => {
                                    // if(this.state.preview){
                                    //     this.setState({ updateNeeded: uuidv4() })
                                    // }
                                })
                            }} />
                        </div>
                        {/* <Button className={this.state.tabIndex == 1 ? "tab-button acitve-tab-button" : "tab-button"} onClick={() => this.setState({ tabIndex: 1 })}>{"Page bilder".translate(this.props.lang)}</Button>
                        <Button className={this.state.tabIndex == 2 ? "tab-button acitve-tab-button" : "tab-button"} onClick={() => this.setState({ tabIndex: 2 })}>{"Footer settings".translate(this.props.lang)}</Button> */}
                        {/* <Button className={this.state.tabIndex == 3 ? "tab-button acitve-tab-button" : "tab-button"} onClick={() => this.setState({ tabIndex: 3 })}>{"Login page".translate(this.props.lang)}</Button> */}
                    </div>

                    <div className='right-buttons'>

                        <Button className='tab-button acitve-tab-button' onClick={() => this.update()}>
                            {
                                this.state.showPlayer ? (
                                    <Player
                                        onEvent={(event) => {
                                            if (event === "load") this.stopAnimation();
                                        }}
                                        onStateChange={this.toggleVisibility}
                                        ref={this.player} // set the ref to your class instance
                                        autoplay={true}
                                        loop={true}
                                        controls={true}
                                        src="https://assets8.lottiefiles.com/packages/lf20_ZSRKCF.json"
                                        style={{ height: "20px", width: "20px" }}
                                    ></Player>

                                ) : this.state.showPlayer2 ? (
                                    <div style={{ display: 'flex', alignItems: 'center' }}><Player
                                        onStateChange={this.toggleVisibility}
                                        ref={this.player2} // set the ref to your class instance
                                        autoplay={true}
                                        loop={true}
                                        controls={true}
                                        src="https://assets10.lottiefiles.com/packages/lf20_CxyxLk.json"
                                        style={{ height: "20px", width: "20px" }}
                                    ></Player>
                                        {'Saved'.translate(this.props.lang)}
                                    </div>
                                ) : (
                                    "Save".translate(this.props.lang)
                                )
                            }
                        </Button>
                        <Button className='tab-button acitve-tab-button' onClick={() => this.setState({ reset: 1 })}>{"Reset".translate(this.props.lang)}</Button>

                        {/* {
                            this.props[0].match.params.id != 'new' ?
                                <>
                                </>
                                :
                                null
                        }
                        <Button className='tab-button acitve-tab-button' onClick={() => this.setState({ importModal: true, preview: false })}>{"Import".translate(this.props.lang)}</Button> */}


                    </div>
                </div>
                <div style={{ visibility: this.state.preview || this.state.importModal ? 'hidden' : 'visible', height: this.state.preview || this.state.importModal ? '0' : 'auto' }}>
                    <AppBuilder
                        json={this.state.landingJSON ? this.state.landingJSON : defaultJson}
                        handleChange={(json) => {
                            if (this.state.ifChange) {
                                this.props.onChange('open')
                                this.setState({
                                    ifChange: false
                                })
                            } else {
                                // this.props.onChange('done')
                            }
                            this.setState({
                                landingJSON: json,
                            })
                        }}
                        libraryIcons={this.state.libraryIcons}
                        changeLayout={(layout) => {
                            this.setState({
                                layout: layout
                            })
                        }}
                        importMobileToApp={(nodes) => {
                            let newNodes = copyMobileToApp(nodes)
                            if (this.state.ifChange) {
                                this.props.onChange('open')
                                this.setState({
                                    ifChange: false
                                })
                            }
                            this.setState({
                                landingJSON: JSON.parse(JSON.stringify(newNodes)),
                                updateNeeded: uuidv4()
                            })
                        }}
                        location={this.props[0].location.pathname}
                        layout={this.state.layout}
                        key={1}
                        updateNeeded={this.state.updateNeeded}
                        lang={this.props.lang}
                        preview={this.state.preview}
                        mainData={this.props.mainData}

                    />
                </div>
                {
                    this.state.preview ?
                        <div className="preview-landing-wrap">
                            <div className={this.state.layout == 'xs' || this.state.layout == 'app' ? 'preview-xs-size' : this.state.layout == 'sm' ? 'preview-sm-size' : 'preview-lg-size'}>
                                <PreviewComponent
                                    mainData={this.props.mainData}
                                    layout={this.state.layout}
                                    nodes={this.state.tabIndex == 1 ? JSON.parse(JSON.stringify(this.state.landingJSON)) : this.state.tabIndex == 2 ? JSON.parse(JSON.stringify(this.state.informationJSON)) : this.state.tabIndex == 3 ? JSON.parse(JSON.stringify(this.state.loginJSON)) : {}} />
                            </div>
                        </div>
                        :
                        null
                }

                {this.state.reset == 1 ?
                    <Modal isOpen={this.state.reset} style={{ marginTop: '50px' }} centered>
                        {/* <ModalHeader style={{ margin: 'auto' }}>{'Reset landing page'.translate(this.props.lang)}</ModalHeader> */}

                        <ModalBody className="delete-modal">
                            {/* <img src={warrning} /> */}
                            <div>{'Are you sure you want reset landing page?'.translate(this.props.lang)}</div>
                        </ModalBody>
                        <ModalFooter className='buttons-right-blue'>
                            <Button color='primary' style={{ margin: 5, backgroundColor: "#3A3F56", minWidth: 100 }} onClick={() => this.setReset()}>{'Yes'.translate(this.props.lang)}</Button>
                            <Button color='primary' style={{ margin: 5, minWidth: 100 }} onClick={() => this.setState({ reset: !this.state.reset })}>{'No'.translate(this.props.lang)}</Button>

                        </ModalFooter>
                    </Modal>
                    : null}
                {this.state.reset == 2 ?
                    <Modal isOpen={this.state.reset} style={{ marginTop: '50px' }} centered>
                        {/* <ModalHeader style={{ margin: 'auto' }}>{'Reset information page'.translate(this.props.lang)}</ModalHeader> */}

                        <ModalBody className="delete-modal">
                            {/* <img src={warrning} /> */}
                            <div>{'Are you sure you want reset information page?'.translate(this.props.lang)}</div>
                        </ModalBody>
                        <ModalFooter className='buttons-right-blue'>
                            <Button color='primary' style={{ margin: 5, backgroundColor: "#3A3F56", minWidth: 100 }} onClick={() => this.setReset()}>{'Yes'.translate(this.props.lang)}</Button>
                            <Button color='primary' style={{ margin: 5, minWidth: 100 }} onClick={() => this.setState({ reset: !this.state.reset })}>{'No'.translate(this.props.lang)}</Button>

                        </ModalFooter>
                    </Modal>
                    : null}

                {this.state.reset == 3 ?
                    <Modal isOpen={this.state.reset} style={{ marginTop: '50px' }} centered>
                        {/* <ModalHeader style={{ margin: 'auto' }}>{'Reset login page'.translate(this.props.lang)}</ModalHeader> */}
                        <ModalBody className="delete-modal">
                            {/* <img src={warrning} /> */}
                            <div>{'Are you sure you want reset login page?'.translate(this.props.lang)}</div>
                        </ModalBody>
                        <ModalFooter className='buttons-right-blue'>
                            <Button color='primary' style={{ margin: 5, backgroundColor: "#3A3F56", minWidth: 100 }} onClick={() => this.setReset()}>{'Yes'.translate(this.props.lang)}</Button>
                            <Button color='primary' style={{ margin: 5, minWidth: 100 }} onClick={() => this.setState({ reset: !this.state.reset })}>{'No'.translate(this.props.lang)}</Button>
                        </ModalFooter>
                    </Modal>
                    : null}



                {
                    this.state.importModal ?

                        <>
                            <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                                <div style={{ fontSize: 24, cursor: 'pointer' }} onClick={() => this.setState({ importModal: !this.state.importModal, selectedTemp: null })}>&times;</div>
                            </div>
                            {

                                this.props.templates && this.props.templates.map((item, idx) => {
                                    return (
                                        <FormGroup style={{ position: 'relative' }}>
                                            <hr />
                                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <Label>{item.name}</Label>

                                                <Button color='primary' style={{ margin: 5, }} onClick={() => { this.setState({ selectedTemp: item._id }, () => this.importTemp()) }}>{'Import'.translate(this.props.lang)}</Button>

                                            </div>
                                            <hr />
                                            <div className="import-modal-items">
                                                {
                                                    item.landingJSON && this.state.tabIndex == 1 ?
                                                        <div className="preview-landing-wrap">
                                                            <div className={this.state.layout == 'xs' || this.state.layout == 'app' ? 'preview-xs-size' : this.state.layout == 'sm' ? 'preview-sm-size' : 'preview-lg-size'}>
                                                                <PreviewComponent
                                                                    layout={this.state.layout}
                                                                    nodes={JSON.parse(JSON.stringify(item.landingJSON))}
                                                                />
                                                            </div>
                                                        </div>

                                                        :
                                                        null
                                                }
                                                {
                                                    item.informationJSON && this.state.tabIndex == 2 ?
                                                        <div className="preview-landing-wrap">
                                                            <div className={this.state.layout == 'xs' || this.state.layout == 'app' ? 'preview-xs-size' : this.state.layout == 'sm' ? 'preview-sm-size' : 'preview-lg-size'}>
                                                                <PreviewComponent
                                                                    layout={this.state.layout}
                                                                    nodes={JSON.parse(JSON.stringify(item.informationJSON))}
                                                                />
                                                            </div>
                                                        </div>

                                                        :
                                                        null
                                                }
                                                {
                                                    item.loginJSON && this.state.tabIndex == 3 ?
                                                        <div className="preview-landing-wrap">
                                                            <div className={this.state.layout == 'xs' || this.state.layout == 'app' ? 'preview-xs-size' : this.state.layout == 'sm' ? 'preview-sm-size' : 'preview-lg-size'}>
                                                                <PreviewComponent
                                                                    layout={this.state.layout}
                                                                    nodes={JSON.parse(JSON.stringify(item.loginJSON))}
                                                                />
                                                            </div>
                                                        </div>

                                                        :
                                                        null
                                                }

                                            </div>


                                        </FormGroup>
                                    )
                                })


                            }

                        </>
                        : null
                }





            </div>

        )
    }
}

export default DragAndDropComponent;