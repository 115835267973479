import React from 'react';

import {
    Modal, ModalHeader, ModalBody, ModalFooter, Button, FormGroup, Input, Label, Row, Col, Form
} from 'reactstrap';

import Isvg from 'react-inlinesvg';

import { v4 as uuidv4 } from 'uuid';
import Helper from './helper';

import File from './components/file';
import editIcon from './assets/edit.svg';
import deleteIcon from './assets/trash.svg';

import { API_ENDPOINT } from '../constants';


/**
* AppBuilder
* @author   Milan Stanojevic
*/
class QA extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            items: [],
            tests: [],
            name: '',
            url: '',
            conditionIdx: null,
            authToken: null
        }

        this.helper = new Helper({
            onElement: this.onElement
        });
    }


    onElement = (item) => {
        if (!this.state.active) {
            return;
        }

        if (item.type == 'uploadFile') {
            this.setState({
                uploadItem: item,
                active: false
            })

            return;
        }


        let items = this.state.items;
        items.push(item);
        this.setState({
            items
        })
    }

    save = () => {
        let name;
        if(this.state.tests && this.state.tests.filter(item => item._id == this.state.selectedTest).length && this.state.tests.filter(item => item._id == this.state.selectedTest)[0].name){
            name = this.state.tests.filter(item => item._id == this.state.selectedTest)[0].name;
        }
        fetch(API_ENDPOINT + '/qa/test/' + this.state.selectedTest, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.state.authToken}`,

            },
            body: JSON.stringify({
                name: name,
                items: this.state.items,
                url: this.state.url,
            })
        })

        this.setState({
            name: '',
            items: [],
            url: '',
            active: false,

        })
    }


    login = () => {
        fetch(API_ENDPOINT + "/users/login", {
            method: "POST",
            headers: {
                "content-type": "application/json",
            },
            body: JSON.stringify({
                username: this.state.username,
                password: this.state.password
            }),
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.error) {
                    if (result.error.text) {
                        this.setState({
                            error: result.error.text
                        })
                    } else {
                        this.setState({
                            error: result.error
                        })
                    }
                } else {
                    this.setState({ authToken: result.token }, () => this.getTests())
                }
            })

    }
    getTests = () => {
        fetch(API_ENDPOINT + "/qa/test", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.state.authToken}`,
            },
            body: JSON.stringify({
                page: null,
                entries: null

            }),
        })
            .then((res) => res.json())
            .then((result) => {
                this.setState({ tests: result })
            })
    }

    render() {
        if (!this.state.authToken) {
            return (
                <div className={this.state.visible ? 'qa-helper-ui visible' : 'qa-helper-ui'}>
                    <h6>QA Test toolchain</h6>

                    <FormGroup>
                        <Label size="sm">Username</Label>
                        <Input size="sm" type="text" value={this.state.username} onChange={(e) => this.setState({ username: e.target.value })} />
                    </FormGroup>
                    <FormGroup>
                        <Label size="sm">Password</Label>
                        <Input size="sm" type="password" value={this.state.password} onChange={(e) => this.setState({ password: e.target.value })} />
                    </FormGroup>
                    <Button color="primary" onClick={() => this.login()}>Log In</Button>
                    {
                        this.state.error ?
                            <p style={{ fontSize: 12, color: 'red', marginTop: 20 }}>{this.state.error}</p>
                            :
                            null
                    }
                </div>
            )
        }

        return (
            <div className={this.state.visible ? 'qa-helper-ui visible' : 'qa-helper-ui'}>
                <h6>QA Test toolchain</h6>

                <FormGroup>
                    <Label size="sm">Test</Label>
                    <Input size="sm" type="select" value={this.state.selectedTest} onChange={(e) => this.setState({ selectedTest: e.target.value != -1 ? e.target.value : null })}>
                        <option value={-1}>{'Select'.translate(this.props.lang)}</option>
                        {
                            this.state.tests && this.state.tests.map((item,idx) => {
                                return(
                                    <option value={item._id}>{item.name}</option>
                                )
                            })
                        }
                    </Input>
                    {/* <Input size="sm" type="text" value={this.state.name} onChange={(e) => this.setState({ name: e.target.value })} /> */}
                </FormGroup>
                {!this.state.uploadItem && this.state.conditionIdx === null ?
                    <div className="items qa-helper-ui-items">
                        <table>
                            <thead>
                                <tr>
                                    <th>Type</th>
                                    <th>Element</th>
                                    <th>Value</th>
                                    <th></th>

                                </tr>
                                {

                                }
                            </thead>
                            <tbody>
                                {
                                    this.state.items.map((item, idx) => {
                                        return (
                                            <tr key={idx}>
                                                <td>{item.type}</td>
                                                <td>{item.xpath.length > 20 ? item.xpath.substring(0, 20) + '...' : item.xpath}</td>
                                                <td>
                                                    {item.value ? typeof item.value == 'object' ? item.value.value : item.value : '/'}
                                                </td>
                                                <td>
                                                    {item.type == 'condition' ?
                                                        <button className="qa-helper-ui-button" onClick={() => this.setState({ conditionIdx: idx })}><Isvg src={editIcon} />   </button>
                                                        :
                                                        null}
                                                    <button className="qa-helper-ui-button" onClick={() => {
                                                        let items = this.state.items;
                                                        items.splice(idx, 1);
                                                        this.setState({ items })
                                                    }}><Isvg src={deleteIcon} />   </button>

                                                </td>

                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>

                    :
                    null
                }

                {this.state.conditionIdx !== null ?
                    <div className="qa-helper-ui-condition">
                        <h6>Condition editor</h6>
                        <p>{this.state.items[this.state.conditionIdx].xpath}</p>

                        <Row>
                            <Col lg="4">
                                <FormGroup size="sm">
                                    <Label size="sm">Attribute</Label>
                                    <Input size="sm" type="select" value={this.state.items[this.state.conditionIdx].value.attribute} onChange={(e) => {
                                        let items = this.state.items;
                                        items[this.state.conditionIdx].value.attribute = e.target.value;
                                        this.setState({
                                            items,
                                        })
                                    }}>
                                        <option value="innerText">innerText</option>
                                        <option value="src">src</option>
                                        <option value="src">class</option>
                                        <option value="name">name</option>
                                        <option value="type">type</option>
                                    </Input>
                                </FormGroup>
                            </Col>
                            <Col lg="4">
                                <FormGroup size="sm">
                                    <Label size="sm">Operator</Label>
                                    <Input size="sm" type="select" value={this.state.items[this.state.conditionIdx].value.operator} onChange={(e) => {
                                        let items = this.state.items;
                                        items[this.state.conditionIdx].value.operator = e.target.value;
                                        this.setState({
                                            items,
                                        })
                                    }}>
                                        <option value="eq">eq</option>
                                        <option value="contains">contains</option>
                                    </Input>
                                </FormGroup>
                            </Col>
                            <Col lg="4">
                                <FormGroup size="sm">
                                    <Label size="sm">Value</Label>
                                    <Input size="sm" value={this.state.items[this.state.conditionIdx].value.value} onChange={(e) => {
                                        let items = this.state.items;
                                        items[this.state.conditionIdx].value.value = e.target.value;
                                        this.setState({
                                            items,
                                        })
                                    }}></Input>
                                </FormGroup>
                            </Col>


                        </Row>

                        <Button color="primary" onClick={() => {
                            this.setState({ conditionIdx: null })
                        }}>Close</Button>

                    </div>

                    :
                    null

                }

                {this.state.uploadItem ?
                    <File authToken={this.state.authToken} label={'Upload same file'} value={this.state.file} onChange={(file) => {
                        this.setState({ file: file }, () => {
                            let items = this.state.items;
                            items.push({
                                ...this.state.uploadItem,
                                value: file
                            });
                            this.setState({
                                items,
                                active: true,
                                uploadItem: null
                            })

                        })
                    }} />

                    :
                    null
                }
                {!this.state.uploadItem && this.state.conditionIdx === null ?
                    <div className="qa-helper-ui-footer">
                        <Button color="primary" disabled={!this.state.selectedTest ? true : false} onClick={() => this.setState({ active: !this.state.active }, () => {
                            if (this.state.active && !this.state.url) {
                                this.setState({
                                    url: window.location.href
                                })
                            }
                        })}>{this.state.active ? 'Pause' : 'Start'}</Button>

                        <Button disabled={!this.state.items.length} color="primary" onClick={this.save}>Save</Button>

                    </div>
                    :
                    null
                }

            </div>
        )
    }
}

export default QA;