import React, { Component } from 'react';
import { Modal, ModalBody, ModalHeader, ModalFooter, Button } from 'reactstrap'
import PrintHelper from './printHelper';
import Isvg from 'react-inlinesvg';
import xIcon from '../assets/svg/x.svg';
import billInfoIcon from '../assets/svg/bill_info.svg';
import formIcon from '../assets/svg/attach-form.svg';

import webdocLogo from '../assets/images/webdoc-logo.png'



import { API_ENDPOINT } from '../constants';
import moment from 'moment';
import FormPreview from './formPreview';
import PreviewAnsweredForm from '../views/forms/previewAnsweredForm';

import { getReferralData, getReferralDataRevisit } from '../helpers/getReferralData';
import { generateHtml } from '../views/forms/helper/helperFunctions';
import { generateSections } from '../helpers/generateSections';


import videoChatIcon from '../assets/svg/video.svg';
import userChatIcon from '../assets/svg/user.svg';
import UserIsNotVerifiedTooltip from './UserIsNotVerifiedTooltip';

function imageUrlToBase64(url) {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.crossOrigin = "anonymous";
        img.onload = () => {
            const canvas = document.createElement("canvas");
            canvas.width = img.width;
            canvas.height = img.height;
            const ctx = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0);
            const base64 = canvas.toDataURL("image/png");
            resolve(base64);
        };
        img.onerror = reject;
        img.src = url;
    });
}

JSON.safeStringify = (obj, indent = 2) => {
    let cache = [];
    const retVal = JSON.stringify(
        obj,
        (key, value) =>
            typeof value === "object" && value !== null
                ? cache.includes(value)
                    ? undefined // Duplicate reference found, discard key
                    : cache.push(value) && value // Store value in our collection
                : value,
        indent
    );
    cache = null;

    // console.log(retVal);

    return retVal;
};

class referralContent extends Component {
    constructor(props) {
        super(props);
        this.modalBody = React.createRef();
        this.state = {
            clinic: false,
            activeRevisitForm: 1,
            printReferralContent: false
        };
    }
    setClinicLogo = () => {
        if (this.state.clinic?.logo) {
            let img = API_ENDPOINT + this.state.clinic?.logo;
            imageUrlToBase64(img)
                .then(base64 => {
                    this.setState({ clinicLogo: base64 })
                    // Do something with the converted JPEG image
                })
                .catch(error => {
                    console.error(error);
                    // Handle the error
                });

        }
    }
    checkInvoice = () => {
        if (this.props.data?._id) {
            fetch(API_ENDPOINT + '/referral/check/invoice/' + this.props.data._id, {
                method: 'GET',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
            }).then(res => res.json()).then((result) => {
                if (result) {
                    this.setState({ invoiceExists: result.invoiceExists, invoiceExistsChecked: this.props.data._id })
                }
            })
        }

    }

    componentDidMount() {

        let printInterval = setInterval(() => {
            if (!this.state.printReferralContent) {
                this.initPrint()
            } else {
                clearInterval(printInterval)
            }
        }, 200);
        if (this.props.data && !this.state.clinic) {
            fetch(API_ENDPOINT + '/data/clinics/' + this.props.data.recipientClinic, {
                method: 'GET',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
            }).then(res => res.json()).then((result) => {
                this.setState({
                    clinic: result
                }, () => {
                    this.setClinicLogo()
                })
            })
        }
        if (this.props.data) {
            this.checkInvoice()
        }


    }
    componentDidUpdate(prevProps) {
        // if ( JSON.stringify(prevProps.data) !== JSON.stringify(this.props.data)) {
        //     this.checkInvoice()
        // }

        if (JSON.safeStringify(prevProps.data) !== JSON.safeStringify(this.props.data)) {
            this.checkInvoice()
        }
    }



    initPrint = () => {
        if (this.modalBody && this.modalBody.current && !this.state.printReferralContent) {
            this.setState({
                printReferralContent: this.modalBody.current.outerHTML
            })
        }
    }

    generateHtmlForPrint = () => {
        let referral = this.props.data;
        let html = '<div>';
        if (this.state.printReferralContent) {
            html += this.state.printReferralContent;
        }
        if (this.props.enterprise && referral && referral.revisitFormsArray && referral.revisitFormsArray.length) {
            let revisitFormsArray = referral.revisitFormsArray;
            for (let i = 0; i < revisitFormsArray.length; i++) {

                html += `<h6 style="font-size: 18px;">${'Patient answers'.translate(this.props.lang)} <span style="margin-left:20px; font-weight:600">${revisitFormsArray[i].name}</span> </h6>`

                if (revisitFormsArray[i].answers) {
                    html += generateHtml({
                        sections: revisitFormsArray[i]?.questionarySections,
                        answers: revisitFormsArray[i]?.answers,
                        question18Finished: [],
                        intelligentReferral: revisitFormsArray[i]?.intelligentReferral
                    }, this.props.lang, null, true, true).protectReferralFiles(this.props.uData ? this.props.uData.mediaToken : '', this.props.data._id)
                    //  getReferralDataRevisit(revisitFormsArray[i], this.props.lang, this.props.doctorPage);

                } else {
                    html += `<p style="font-style: italic; font-size: 14px;">${'The patient did not fill out the form'.translate(this.props.lang)}</p>`
                }


                if (i != (revisitFormsArray.length - 1)) {
                    html += '<hr/>'
                }
            }
        }

        html += '</div>'

        return html;
    }

    checkConsultation = () => {
        if (this.props._consultation) {
            if (this.props._consultation?.caseTransfered === true) return `${'Consultation transfer'.translate(this.props.lang)} ${this.props._consultation?.caseNumber ?? ''}`;
            else if (this.props._consultation?.caseTransfered === false) return `${'Consultation active'.translate(this.props.lang)}`;
        } else if (this.props.data?._consultation) {
            if (this.props.data?._consultation?.caseTransfered === true) return `${'Consultation transfer'.translate(this.props.lang)} ${this.props.data?._consultation?.caseNumber ?? ''}`;
            else if (this.props.data?._consultation?.caseTransfered === false) return `${'Consultation active'.translate(this.props.lang)}`;
        }

        return null;
    }

    render() {
        let isMobile = typeof window != 'undefined' && window.innerWidth < 768 ? true : false;

        const _consultation = this.checkConsultation();

        const transferedCaseByConsultation = this.props.selectedClinic !== this.props.data?.recipientClinic ? true : false;

        const humanReadable = {
            'waiting-for-approval': 'Waiting for approval'.translate(this.props.lang),
            'waiting': 'Waiting'.translate(this.props.lang),
            'waiting-for-payment': 'Waiting for payment'.translate(this.props.lang),
            'disallowed': 'Disallowed'.translate(this.props.lang),
            'in-progress': 'In progress'.translate(this.props.lang),
            'allowed': 'Allowed'.translate(this.props.lang),
            'return-for-update': 'Return for update'.translate(this.props.lang),
            'patient-requested-another-time': 'Patient requested anoother time'.translate(this.props.lang),
            'patient-rejected': 'Rejected by patient'.translate(this.props.lang),
            'patient-accepted': 'Patient accepted'.translate(this.props.lang),
            'clinic-accepted-requested-time': 'Clinic accepted requested time'.translate(this.props.lang),
            'clinic-rejected-requested-time': 'Clinic rejected requested time'.translate(this.props.lang),
            'approved-by-region-manager': 'Approved by region manager'.translate(this.props.lang),
            'rejected-by-region-manager': 'Rejected by region manager'.translate(this.props.lang),
            'scheduled': 'Scheduled'.translate(this.props.lang),
            'visit-rescheduled': 'Visit rescheduled'.translate(this.props.lang),
            'revisit-scheduled': 'Revisit scheduled'.translate(this.props.lang),
            'not-booked': 'Not booked'.translate(this.props.lang),
            'closed': 'Closed'.translate(this.props.lang),
            'canceled-by-clinic': 'Canceled by clinic'.translate(this.props.lang),
            'requested-new-appointment': 'Requested new appointment'.translate(this.props.lang),
            'external': 'External transfer'.translate(this.props.lang),
        }
        const groupPermissionsUser = this.props.uData?.groupPermissionsUser;
        const adminPermissionId = this.props.uData?.permissions?.admin;
        const doctorPermissionId = this.props.uData?.permissions?.doctor;
        const isAdmin = groupPermissionsUser?.[this.props.selectedGroup]?.[this.props.selectedClinic]?.indexOf(adminPermissionId) !== -1 ? true : false;
        const isDoctor = groupPermissionsUser?.[this.props.selectedGroup]?.[this.props.selectedClinic]?.indexOf(doctorPermissionId) !== -1 ? true : false;

        let closeBtnDisabled = false;
        const caseStatus = this.props.data?.recipientClinic !== this.props.selectedClinic && this.props.screen !== 'patient-cases' ? 'external' : this.props.data.status;
        const caseObj = this.props.data;
        if (caseStatus === 'closed' || caseStatus === 'waiting-for-approval' || caseStatus === 'waiting') closeBtnDisabled = true;

        let deleteBtnDisabled = true;
        const isCaseFree = (caseObj?.control && caseObj?.event?.visitReasonPrice === 0) || (!caseObj?.control && caseObj?.questionaryPrice === 0) ? true : false;
        const isCasePaid = (caseObj?.control && caseObj?.event?.questionaryPrice > 0 && caseObj?.event?.appointmentPaid) || (!caseObj?.control && caseObj?.questionaryPrice > 0 && caseObj?.referralIsPaid) ? true : false;
        if (caseStatus === 'closed' && (!isCasePaid || isCaseFree)) deleteBtnDisabled = false;

        const closeCaseBtn = {
            disabled: closeBtnDisabled,
            show: isDoctor || isAdmin ? true : false
        };
        if (deleteBtnDisabled && this.state.invoiceExistsChecked && !this.state.invoiceExists && caseStatus === 'closed') {
            deleteBtnDisabled = false;
        }
        const deleteCaseBtn = {
            disabled: deleteBtnDisabled,
            show: isAdmin ? true : false
        };


        let doctorName;
        if (this.props.data?.doctorName) {
            doctorName = this.props.data?.doctorName;
        }
        if (this.props.data?.doctorsNames) {
            doctorName += this.props.data.doctorsNames.replace(`, ${doctorName}`, '');
        }
        let isLoggedUserFirstInMembers = false;
        if (this.props.members?.length && this.props.members[0] === this.props.uData?._id) {
            isLoggedUserFirstInMembers = true;
        }

        const conditionForRemoveExternalTransfer = isAdmin && this.props.data?.recipientClinic !== this.props.selectedClinic;

        const removeExternalTransferBtn = <div className='red-button-wrap'>
            <Button
                onClick={() => { if (this.props.removeReferralModal) { this.props.removeReferralModal(this.props.data) } }}
                color='danger'
            >
                {'Delete case'.translate(this.props.lang)}
            </Button>
        </div>;

        const createOnWebDoc = this.props?.data?.event?.createOnWebDoc;
        const showWebDocInfo = createOnWebDoc ? true : false;
        if (this.props.screen === 'chat') {
            return (
                <>


                    <div className="chat-referral-preview-title">
                        {
                            isMobile ?
                                <div className='close-referral-content-chat-wrap'>
                                    <div className='close-referral-content-chat' onClick={() => this.props.closeReferralContent(true)}> &lt; {'Back'.translate(this.props.lang)} </div>
                                </div>
                                :
                                null
                        }
                        <h4>{'Referral preview'.translate(this.props.lang)}</h4>

                    </div>
                    <div className="chat-referral-preview-body modal-body">
                        <div className='unpaid-button-container'>
                            {
                                showWebDocInfo ?
                                    <button className='webdoc-button-container'>
                                        <img src={webdocLogo} />
                                        {'CREATED ON WEBDOC'.translate(this.props.lang)}
                                    </button>
                                    :
                                    null
                            }
                            {
                                !this.props.doctorPage || this.props.data.referralIsPaid || (this.props.data.event && this.props.data.event.appointmentPaid) || (this.props.data.event && this.props.data.event.visitReasonPrice == 0) || this.props.data.questionaryPrice == 0 || this.props.uData.userLevel == 1 || this.props.uData.userLevel == 2 || localStorage.getItem('profile') == 'patient' ?
                                    null
                                    :
                                    <button className='unpaid-button-container-unpaid-button'>
                                        <Isvg src={billInfoIcon} />
                                        {'UNPAID'.translate(this.props.lang)}
                                    </button>
                            }
                            {
                                this.props.data?._2fa ? <button className='unpaid-button-container-2fa-button' disabled>
                                    <UserIsNotVerifiedTooltip lang={this.props.lang} warning />
                                    {'2FA'.translate(this.props.lang)}
                                </button> : null
                            }
                            {
                                _consultation ? <button className='unpaid-button-container-2fa-button' disabled>
                                    {_consultation}
                                </button> : null
                            }
                        </div>
                        {
                            this.props.data.priority && this.props.uData && this.props.uData.userLevel && this.props.uData.userLevel === 20 && typeof window !== 'undefined' && localStorage?.getItem?.('profile') && localStorage?.getItem('profile') !== 'patient' ?
                                <div className={
                                    this.props.data.priority == 1 ? 'priority-circle p-red nohover' :
                                        this.props.data.priority == 2 ? 'priority-circle p-orange nohover' :
                                            this.props.data.priority == 3 ? 'priority-circle p-yellow nohover' :
                                                this.props.data.priority == 4 ? 'priority-circle p-black nohover' :
                                                    'priority-circle p-green nohover'
                                }>
                                    {this.props.data.priority}
                                </div>
                                :
                                null
                        }
                        <div ref={this.modalBody}>
                            <div style={typeof window !== 'undefined' && window.innerWidth < 768 ? { display: 'flex', flexDirection: 'column' } : { display: 'flex', justifyContent: 'space-between' }}>
                                {this.state.clinic.logo && this.state.clinicLogo ? <p><img style={{ maxWidth: 220 }} src={this.state.clinicLogo} /></p> : ''}
                                <div style={{ marginTop: 20 }}>
                                    <p ><strong>{'Sökdatum'.translate(this.props.lang) + ': '}</strong> <span >{this.props.getStringDateTs(this.props.data.tsCreated, this.props.dateFormat)/*moment.unix(this.props.data.tsCreated).format(this.props.dateFormat)*/}</span></p>
                                </div>
                            </div>
                            <div >
                                <div>{this.state.clinic.name ? this.state.clinic.name : ''}</div>
                                <div>{this.state.clinic.street ? this.state.clinic.street : ''}, {this.state.clinic.zip ? this.state.clinic.zip : ''} {this.state.clinic.city ? this.state.clinic.city : ''}</div>
                                <div>{this.state.clinic.phone ? this.state.clinic.phone : ''} {this.state.clinic.email ? this.state.clinic.email : ''}</div>
                                <div>
                                    {doctorName ? doctorName : ''}


                                    {/* {this.props.data.doctorName && this.props.data.doctorsNames ? this.props.data.doctorName + this.props.data.doctorsNames : this.props.data.doctorName ? this.props.data.doctorName : ''} */}
                                </div>
                                <div>
                                    {
                                        this.props.data?.transferedFromOtherClinicUser ?
                                            `Transfered from:`.translate(this.props.lang) + ' ' + this.props.data?.transferedFromOtherClinicUser?.prevDoctorName + ' ' + 'Transfer time:' + ' ' + this.props.getStringDateTs(this.props.data?.transferedFromOtherClinicUser?.timestamp, this.props.dateFormat)
                                            :
                                            ''
                                    }

                                </div>
                            </div>
                            <hr />
                            <div>
                                <p><strong>{'Ärende-ID'.translate(this.props.lang) + ': '}</strong>{this.props.data.patient.referralNumber}</p>
                            </div>
                            <div>
                                <p>
                                    <strong>{'Personuppgifter'.translate(this.props.lang) + ': '}</strong>
                                </p>
                            </div>
                            <div>
                                {/* <p>{this.props.data.patient.socialSecurityNumber ? this.props.data.patient.socialSecurityNumber : ''}<br />{this.props.data.patient.name ? this.props.data.patient.name + (this.props.data.patient.parents && this.props.data.patient.parents[0] ?
                                    <>
                                        <strong>{'Vårdnadshavare'.translate(this.props.lang)}</strong>
                                        {this.props.data.patient.parents[0].socialSecurityNumber}
                                        {this.props.data.patient.parents[0].name}
                                        {this.props.data.patient.parents[0].address}
                                        {this.props.data.patient.parents[0].phone}
                                    </> : '') + (this.props.data.patient.parents && this.props.data.patient.parents[1] && this.props.data.patient.parents[1].socialSecurityNumber ?
                                        <>
                                            <br />
                                            {this.props.data.patient.parents[1].socialSecurityNumber}
                                            {this.props.data.patient.parents[1].name}
                                            {this.props.data.patient.parents[1].address}
                                            {this.props.data.patient.parents[1].phone}

                                        </>
                                        : '') : ''}<br />
                                    {this.props.data.patient.street ? this.props.data.patient.street : ''}<br />
                                    {this.props.data.patient.zip ? this.props.data.patient.zip : ''}&nbsp;
                                    {this.props.data.patient.city ? this.props.data.patient.city : ''}<br />
                                    {this.props.data.patient.phone ? this.props.data.patient.phone : ''}<br />
                                    {this.props.data.patient.email ? this.props.data.patient.email : ''}
                                </p> */}
                                <p>
                                    {this.props.data.patient.socialSecurityNumber ? this.props.data.patient.socialSecurityNumber : ''}<br />
                                    {
                                        this.props.data.patient.name ?
                                            this.props.data.patient.name : null
                                    }
                                    {
                                        this.props.data.patient.parents && this.props.data.patient.parents[0] ?
                                            <>
                                                <br />
                                                <strong>{'Vårdnadshavare'.translate(this.props.lang)}</strong><br />
                                                {this.props.data.patient.parents[0].socialSecurityNumber}<br />
                                                {this.props.data.patient.parents[0].name}<br />
                                                {this.props.data.patient.parents[0].address}<br />
                                                {this.props.data.patient.parents[0].phone}
                                            </>
                                            :
                                            null
                                    }
                                    {
                                        this.props.data.patient.parents && this.props.data.patient.parents[1] && this.props.data.patient.parents[1].socialSecurityNumber ?
                                            <>
                                                <br /><br />
                                                {this.props.data.patient.parents[1].socialSecurityNumber}<br />
                                                {this.props.data.patient.parents[1].name}<br />
                                                {this.props.data.patient.parents[1].address}<br />
                                                {this.props.data.patient.parents[1].phone}

                                            </>
                                            :
                                            null
                                    }

                                    <br />
                                    {this.props.data.patient.street && !(this.props.data.patient.parents && this.props.data.patient.parents[0]) ? <>{this.props.data.patient.street}<br /></> : ''}
                                    {this.props.data.patient.zip && !(this.props.data.patient.parents && this.props.data.patient.parents[0]) ? <>{this.props.data.patient.zip}&nbsp;</> : ''}
                                    {this.props.data.patient.city && !(this.props.data.patient.parents && this.props.data.patient.parents[0]) ? <>{this.props.data.patient.city}<br /></> : ''}
                                    {this.props.data.patient.phone && !(this.props.data.patient.parents && this.props.data.patient.parents[0]) ? this.props.data.patient.phone : ''}<br />
                                    {this.props.data.patient.email && !(this.props.data.patient.parents && this.props.data.patient.parents[0]) ? this.props.data.patient.email : ''}
                                </p>
                            </div>
                            <hr />
                            {
                                this.props.data.questionaryName && this.props.data.control ?
                                    <div>
                                        <h5>
                                            <b> {'Reason for visit:'.translate(this.props.lang)}</b> {this.props.data.questionaryName}
                                        </h5>
                                        <hr />
                                    </div>
                                    :
                                    null
                            }
                            {

                            }
                            {
                                !(this.props.data.revisitFormsArray && this.props.data.revisitFormsArray.length) && this.props.data.remissType !== "Revisit" && this.props.doctorPage ?
                                    <>

                                        {/* <div dangerouslySetInnerHTML={{ __html: getReferralData(this.props.data, this.props.lang, this.props.doctorPage).protectReferralFiles(this.props.uData ? this.props.uData.mediaToken : '', this.props.data._id) }}></div> */}
                                        <div dangerouslySetInnerHTML={{
                                            __html: generateHtml({
                                                sections: this.props.data?.questionarySections,
                                                answers: this.props.data?.answers,
                                                question18Finished: [],
                                                intelligentReferral: this.props.data?.intelligentReferral
                                            }, this.props.lang, null, true, true).protectReferralFiles(this.props.uData ? this.props.uData.mediaToken : '', this.props.data._id)
                                        }}></div>

                                        <hr />
                                    </>
                                    :
                                    null
                            }

                        </div>

                        {
                            (this.props.enterprise || !this.props.doctorPage) && this.props.data.revisitFormsArray && this.props.data.revisitFormsArray.length && this.props.data.revisitFormsArray[this.state.activeRevisitForm - 1] ?
                                this.props.data.revisitFormsArray[this.state.activeRevisitForm - 1].referralData ?
                                    <div>
                                        {
                                            this.props.doctorPage ?
                                                <>
                                                    <h6>{/*{'Patient answers'.translate(this.props.lang)}*/}<span style={{ marginLeft: 0, fontWeight: 600 }}>{this.props.data.revisitFormsArray[this.state.activeRevisitForm - 1].name}</span> </h6>
                                                    {/* <div dangerouslySetInnerHTML={{ __html: getReferralDataRevisit(this.props.data.revisitFormsArray[this.state.activeRevisitForm - 1], this.props.lang, this.props.doctorPage).protectReferralFiles(this.props.uData ? this.props.uData.mediaToken : '', this.props.data._id) }}></div> */}
                                                    <div dangerouslySetInnerHTML={{
                                                        __html: generateHtml({
                                                            sections: this.props.data.revisitFormsArray[this.state.activeRevisitForm - 1]?.questionarySections,
                                                            answers: this.props.data.revisitFormsArray[this.state.activeRevisitForm - 1]?.answers,
                                                            question18Finished: [],
                                                            intelligentReferral: this.props.data.revisitFormsArray[this.state.activeRevisitForm - 1]?.intelligentReferral
                                                        }, this.props.lang, null, true, true).protectReferralFiles(this.props.uData ? this.props.uData.mediaToken : '', this.props.data._id)
                                                    }}></div>
                                                </>
                                                :
                                                <>
                                                    <h6><span style={{ fontWeight: 600 }}>{this.props.data.revisitFormsArray[this.state.activeRevisitForm - 1].name}</span> </h6>
                                                </>
                                        }

                                        <Button color='primary' onClick={() => {
                                            if (this.props.previewAnswersRevisit) { this.props.previewAnswersRevisit(this.props.data.revisitFormsArray[this.state.activeRevisitForm - 1]) }
                                        }}>{'Preview answers'.translate(this.props.lang)}</Button>

                                        <hr />
                                    </div>
                                    :
                                    <div>
                                        {
                                            this.props.doctorPage ?
                                                <>
                                                    <h6>{/*{'Patient answers'.translate(this.props.lang)}*/}<span style={{ marginLeft: 0, fontWeight: 600 }}>{this.props.data.revisitFormsArray[this.state.activeRevisitForm - 1].name}</span> </h6>
                                                    <p style={{ fontStyle: 'italic', fontSize: 14 }}>{'The patient did not fill out the form'.translate(this.props.lang)}</p>
                                                </>
                                                :
                                                <>
                                                    <h6><span style={{ fontWeight: 600 }}>{this.props.data.revisitFormsArray[this.state.activeRevisitForm - 1].name}</span> </h6>
                                                    <p style={{ fontStyle: 'italic', fontSize: 14 }}>{'The patient did not fill out the form'.translate(this.props.lang)}</p>
                                                </>
                                        }

                                        <hr />

                                    </div>

                                :
                                null
                        }
                        {
                            this.props.data && this.props.data.answers && this.props.data.questionaryData && this.props.data.questionaryData.items ?
                                <div style={{ marginBottom: 10 }}>
                                    <Button color='primary' onClick={() => {
                                        if (this.props.previewAnswersReferralModal) { this.props.previewAnswersReferralModal(this.props.data) }
                                    }}>{'Preview answers'.translate(this.props.lang)}</Button>
                                </div>
                                :
                                null
                        }
                        <div className='patient-referral-preview-status-wrap'>
                            <div>
                                <p> <strong>{'Status:'.translate(this.props.lang)}</strong> {humanReadable[caseStatus]}</p>
                                {
                                    this.props.data?.transferedFromOtherClinicUser?.prevDoctorName && this.props.data?.transferedFromOtherClinicUser?.timestamp ?
                                        <p>
                                            <strong>{'Transferred from:'.translate(this.props.lang)}</strong> {this.props.data?.transferedFromOtherClinicUser?.prevDoctorName} <strong>{'Transferred time:'.translate(this.props.lang)}</strong> {this.props.getStringDateTs(this.props.data?.transferedFromOtherClinicUser?.timestamp, this.props.dateFormat)}
                                        </p>
                                        :
                                        null
                                }
                                {
                                    this.props.data?.caseTransferInfoData ?
                                        <>
                                            {
                                                this.props.data.caseTransferInfoData.transferredFromClinic ?
                                                    <p><strong>{'Transferred from'.translate(this.props.lang)}:</strong> {this.props.data.caseTransferInfoData.transferredFromClinic}{this.props.data.caseTransferInfoData.transferredFromDoctor ? ` ${'and'.translate(this.props.lang)} ${this.props.data.caseTransferInfoData.transferredFromDoctor}` : ''}</p>
                                                    :
                                                    null
                                            }
                                            {
                                                this.props.data.caseTransferInfoData.transferredToClinic ?
                                                    <p><strong>{'Transferred to'.translate(this.props.lang)}:</strong> {this.props.data.caseTransferInfoData.transferredToClinic}{this.props.data.caseTransferInfoData.transferredToDoctor ? ` ${'and'.translate(this.props.lang)} ${this.props.data.caseTransferInfoData.transferredToDoctor}` : ''}</p>
                                                    :
                                                    null
                                            }
                                            {
                                                this.props.data.caseTransferInfoData.transferredTs ?
                                                    <p><strong>{'Date of transfer'.translate(this.props.lang)}:</strong> {this.props.getStringDateTs(this.props.data.caseTransferInfoData.transferredTs, `${this.props.dateFormat} HH:mm`)}</p>
                                                    :
                                                    null
                                            }

                                        </>
                                        :
                                        null
                                }

                                {this.props.data.reason && (this.props.data.status === 'patient-rejected' || this.props.data.status === 'visit-rescheduled' || this.props.data.status === 'requested-new-appointment' || this.props.data.status === 'disallowed') ?
                                    <p> <strong>{'Message:'.translate(this.props.lang)}</strong> {this.props.data.reason}</p>
                                    :
                                    null}

                                {this.props.data.patientReservedTimestamp && !this.props.data.oldPatinetReservedTimestamp ?
                                    <p> <strong>{this.props.data?.dropIn ? `${'Picked up'.translate(this.props.lang)}:` : 'Scheduled time:'.translate(this.props.lang)}</strong> {this.props.getStringDateTs(this.props.data.patientReservedTimestamp, `${this.props.dateFormat}${!this.props.data.hideTimeFromThePatient ? ' HH:mm' : ''}`)/*moment.unix(this.props.data.patientReservedTimestamp).format(`${this.props.dateFormat}${!this.props.data.hideTimeFromThePatient ? ' HH:mm' : ''}`)*/}</p>
                                    :
                                    this.props.data.oldPatinetReservedTimestamp ?
                                        <p> <strong>{'Original scheduled time:'.translate(this.props.lang)}</strong> {this.props.getStringDateTs(this.props.data.oldPatinetReservedTimestamp, `${this.props.dateFormat}${!this.props.data.hideTimeFromThePatient ? ' HH:mm' : ''}`)/*moment.unix(this.props.data.patientReservedTimestamp).format(`${this.props.dateFormat}${!this.props.data.hideTimeFromThePatient ? ' HH:mm' : ''}`)*/}</p>
                                        :
                                        null
                                }
                                {/** oldPatientReservedTimestamp tell if case is rescheduled */}
                                {this.props.data.oldPatinetReservedTimestamp ?
                                    <p> <strong>{'New schedule time:'.translate(this.props.lang)}</strong> {this.props.getStringDateTs(this.props.data.patientReservedTimestamp, `${this.props.dateFormat}${!this.props.data.hideTimeFromThePatient ? ' HH:mm' : ''}`)/*moment.unix(this.props.data.patientReservedTimestamp).format(`${this.props.dateFormat}${!this.props.data.hideTimeFromThePatient ? ' HH:mm' : ''}`)*/}</p>
                                    :
                                    null
                                }
                                {this.props.data.oldPatinetReservedTimestamp ?
                                    <p> <strong>{'Appointement rescheduled:'.translate(this.props.lang)}</strong> {this.props.getStringDateTs(this.props.data.scheduleTimestamp, `${this.props.dateFormat}${!this.props.data.hideTimeFromThePatient ? ' HH:mm' : ''}`)/*moment.unix(this.props.data.patientReservedTimestamp).format(`${this.props.dateFormat}${!this.props.data.hideTimeFromThePatient ? ' HH:mm' : ''}`)*/}</p>
                                    :
                                    null
                                }
                                {
                                    this.props.data.status === 'patient-rejected' && this.props.data.patientRejectedTimestamp ?
                                        <p> <strong>{'Appointment cancelled:'.translate(this.props.lang)}</strong> {this.props.getStringDateTs(this.props.data.patientRejectedTimestamp, `${this.props.dateFormat} HH:mm`)/*moment.unix(this.props.data.patientRejectedTimestamp).format(`${this.props.dateFormat} HH:mm`)*/}</p>
                                        :
                                        null
                                }
                                {
                                    this.props.data.status === 'requested-new-appointment' && this.props.data.patientRequestedNewAppointment ?
                                        <p> <strong>{'Appointment cancelled:'.translate(this.props.lang)}</strong> {this.props.getStringDateTs(this.props.data.patientRequestedNewAppointment, `${this.props.dateFormat} HH:mm`)/*moment.unix(this.props.data.patientRequestedNewAppointment).format(`${this.props.dateFormat} HH:mm`)*/}</p>
                                        :
                                        null
                                }
                                {
                                    this.props.data.status == 'patient-requested-another-time' ?
                                        <p> <strong>{'Requested time:'.translate(this.props.lang)}</strong> {this.props.getStringDateTs(this.props.data.patientRequestedTimestamp, `${this.props.dateFormat} HH:mm`)/*moment.unix(this.props.data.patientRequestedTimestamp).format(`${this.props.dateFormat} HH:mm`)*/}</p>

                                        :
                                        null
                                }

                            </div>
                            {
                                (this.props.enterprise || !this.props.doctorPage) && this.props.data.revisitFormsArray && this.props.data.revisitFormsArray.length ?
                                    <div className='doctor-preview-revisit-forms'>
                                        <div className='doctor-preview-revisit-forms-header blablbabla'>
                                            {
                                                this.state.activeRevisitForm ?
                                                    <span>{this.props.data.revisitFormsArray[this.state.activeRevisitForm - 1].name} <span>({this.state.activeRevisitForm}/{this.props.data.revisitFormsArray.length})</span></span>
                                                    :
                                                    <span>{this.props.data.revisitFormsArray[0].name} <span>(1/{this.props.data.revisitFormsArray.length})</span></span>
                                            }
                                        </div>
                                        <div className='buttons-next-prev-wrap'>
                                            <Button color='primary' disabled={this.state.activeRevisitForm && this.state.activeRevisitForm > 1 ? false : true} onClick={() => {
                                                let activeRevisitForm = this.state.activeRevisitForm;
                                                if (!activeRevisitForm) {
                                                    activeRevisitForm = 1;
                                                }
                                                if (activeRevisitForm > 1) {
                                                    activeRevisitForm--;
                                                }
                                                this.setState({ activeRevisitForm })
                                            }}>{'Previous'.translate(this.props.lang)}</Button>
                                            <Button color='primary' disabled={this.state.activeRevisitForm && this.state.activeRevisitForm < this.props.data.revisitFormsArray.length ? false : true} onClick={() => {
                                                let activeRevisitForm = this.state.activeRevisitForm;
                                                if (!activeRevisitForm) {
                                                    activeRevisitForm = 1;
                                                }
                                                if (activeRevisitForm < this.props.data.revisitFormsArray.length) {
                                                    activeRevisitForm++;
                                                }
                                                this.setState({ activeRevisitForm })
                                            }}>{'Next'.translate(this.props.lang)}</Button>
                                        </div>
                                    </div>
                                    :
                                    null

                            }
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%', paddingTop: 10 }}>
                            {/* {
                                (this.props.data.status == 'revisit-scheduled' || this.props.data.status === 'visit-rescheduled') && this.props.data.closeCaseManually && this.props.enterprise && this.props.data.recipientUser == this.props.uData._id ?
                                    <div className='red-button-wrap' style={{ marginRight: 10 }}>
                                        <Button color='danger' onClick={() => {
                                            if (this.props.closeCase) { this.props.closeCase() }
                                        }} >{'Close case'.translate(this.props.lang)}</Button>
                                    </div>
                                    :
                                    null
                            } */}
                            {
                                closeCaseBtn.show ?
                                    <div className='red-button-wrap' style={{ marginRight: 10 }}>
                                        <Button
                                            disabled={closeCaseBtn.disabled || !isLoggedUserFirstInMembers}
                                            onClick={() => { if (this.props.closeCase && !closeCaseBtn.disabled) { this.props.closeCase() } }}
                                            color="danger"
                                        >
                                            {'Close case'.translate(this.props.lang)}
                                        </Button>
                                    </div>
                                    :
                                    null
                            }
                            {
                                !isMobile ?
                                    <Button color="primary" onClick={() => this.props.closeReferralContent(true)}> {'Close'.translate(this.props.lang)} </Button>
                                    :
                                    null
                            }
                        </div>

                    </div>
                </>
            )
        }

        return (
            <div >

                <Modal isOpen={this.props.data && this.state.clinic} centered size={this.props.screen == 'all-cases' ? 'xl' : "lg"}>
                    <ModalHeader toggle={() => this.props.closeReferralContent(true)} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.props.closeReferralContent(true)}><Isvg src={xIcon} /></button>}>{'Referral preview'.translate(this.props.lang)}</ModalHeader>

                    <ModalBody>
                        <div className='unpaid-button-container'>
                            {
                                showWebDocInfo ?
                                    <button className='webdoc-button-container'>
                                        <img src={webdocLogo} />
                                        {'CREATED ON WEBDOC'.translate(this.props.lang)}
                                    </button>
                                    :
                                    null
                            }
                            {
                                !this.props.doctorPage || this.props.data.referralIsPaid || (this.props.data.event && this.props.data.event.appointmentPaid) || (this.props.data.event && this.props.data.event.visitReasonPrice == 0) || this.props.data.questionaryPrice == 0 ?
                                    null
                                    :
                                    <button className='unpaid-button-container-unpaid-button'>
                                        <Isvg src={billInfoIcon} />
                                        {'UNPAID'.translate(this.props.lang)}
                                    </button>
                            }
                            {
                                this.props.data?._2fa ? <button className='unpaid-button-container-2fa-button' disabled>
                                    <UserIsNotVerifiedTooltip lang={this.props.lang} warning />
                                    {'2FA'.translate(this.props.lang)}
                                </button> : null
                            }
                            {
                                _consultation ? <button className='unpaid-button-container-2fa-button' disabled>
                                    {_consultation}
                                </button> : null
                            }
                        </div>
                        {
                            this.props.data.priority && this.props.uData && this.props.uData.userLevel && this.props.uData.userLevel === 20 && typeof window !== 'undefined' && localStorage?.getItem?.('profile') && localStorage?.getItem('profile') !== 'patient' ?
                                <div className={
                                    this.props.data.priority == 1 ? 'priority-circle p-red nohover' :
                                        this.props.data.priority == 2 ? 'priority-circle p-orange nohover' :
                                            this.props.data.priority == 3 ? 'priority-circle p-yellow nohover' :
                                                this.props.data.priority == 4 ? 'priority-circle p-black nohover' :
                                                    'priority-circle p-green nohover'
                                }>
                                    {this.props.data.priority}
                                </div>
                                :
                                null
                        }
                        <div ref={this.modalBody}>
                            <div style={typeof window !== 'undefined' && window.innerWidth < 768 ? { display: 'flex', flexDirection: 'column' } : { display: 'flex', justifyContent: 'space-between' }}>
                                {this.state.clinic.logo && this.state.clinicLogo ? <p><img style={{ maxWidth: 220 }} src={this.state.clinicLogo} /></p> : ''}
                                <div style={{ marginTop: 20 }}>
                                    <p ><strong>{'Sökdatum'.translate(this.props.lang) + ': '}</strong> <span >{this.props.getStringDateTs(this.props.data.tsCreated, this.props.dateFormat)/*moment.unix(this.props.data.tsCreated).format(this.props.dateFormat)*/}</span></p>
                                </div>
                            </div>
                            <div >
                                <div>{this.state.clinic.name ? this.state.clinic.name : ''}</div>
                                <div>{this.state.clinic.street ? this.state.clinic.street : ''}, {this.state.clinic.zip ? this.state.clinic.zip : ''} {this.state.clinic.city ? this.state.clinic.city : ''}</div>
                                <div>{this.state.clinic.phone ? this.state.clinic.phone : ''} {this.state.clinic.email ? this.state.clinic.email : ''}</div>
                                <div>
                                    {doctorName ? doctorName : ''}
                                    {/* {this.props.data.doctorName && this.props.data.doctorsNames ? this.props.data.doctorName + this.props.data.doctorsNames : this.props.data.doctorName ? this.props.data.doctorName : ''} */}
                                </div>

                            </div>
                            <hr />
                            <div>
                                <p><strong>{'Ärende-ID'.translate(this.props.lang) + ': '}</strong>{this.props.data.patient.referralNumber}</p>
                            </div>
                            <div>
                                <p>
                                    <strong>{'Personuppgifter'.translate(this.props.lang) + ': '}</strong>
                                </p>
                            </div>
                            <div>
                                <p>
                                    {this.props.data.patient.socialSecurityNumber ? this.props.data.patient.socialSecurityNumber : ''}<br />
                                    {
                                        this.props.data.patient.name ?
                                            this.props.data.patient.name : null
                                    }
                                    {
                                        this.props.data.patient.parents && this.props.data.patient.parents[0] ?
                                            <>
                                                <br />
                                                <strong>{'Vårdnadshavare'.translate(this.props.lang)}</strong><br />
                                                {this.props.data.patient.parents[0].socialSecurityNumber}<br />
                                                {this.props.data.patient.parents[0].name}<br />
                                                {this.props.data.patient.parents[0].address}<br />
                                                {this.props.data.patient.parents[0].phone}
                                            </>
                                            :
                                            null
                                    }
                                    {
                                        this.props.data.patient.parents && this.props.data.patient.parents[1] && this.props.data.patient.parents[1].socialSecurityNumber ?
                                            <>
                                                <br /><br />
                                                {this.props.data.patient.parents[1].socialSecurityNumber}<br />
                                                {this.props.data.patient.parents[1].name}<br />
                                                {this.props.data.patient.parents[1].address}<br />
                                                {this.props.data.patient.parents[1].phone}

                                            </>
                                            :
                                            null
                                    }

                                    <br />
                                    {this.props.data.patient.street && !(this.props.data.patient.parents && this.props.data.patient.parents[0]) ? <>{this.props.data.patient.street}<br /></> : ''}
                                    {this.props.data.patient.zip && !(this.props.data.patient.parents && this.props.data.patient.parents[0]) ? <>{this.props.data.patient.zip}&nbsp;</> : ''}
                                    {this.props.data.patient.city && !(this.props.data.patient.parents && this.props.data.patient.parents[0]) ? <>{this.props.data.patient.city}<br /></> : ''}
                                    {this.props.data.patient.phone && !(this.props.data.patient.parents && this.props.data.patient.parents[0]) ? this.props.data.patient.phone : ''}<br />
                                    {this.props.data.patient.email && !(this.props.data.patient.parents && this.props.data.patient.parents[0]) ? this.props.data.patient.email : ''}
                                </p>
                            </div>
                            <hr />
                            {
                                this.props.data.questionaryName && this.props.data.control ?
                                    <div>

                                        <h5>
                                            <b> {'Reason for visit:'.translate(this.props.lang)}</b> {this.props.data.questionaryName}
                                        </h5>

                                        <hr />
                                    </div>
                                    :
                                    null
                            }
                            {

                            }
                            {
                                !(this.props.data.revisitFormsArray && this.props.data.revisitFormsArray.length) && this.props.data.remissType !== "Revisit" && this.props.screen !== 'patient-cases' ?
                                    <>

                                        {/* <div dangerouslySetInnerHTML={{ __html: getReferralData(this.props.data, this.props.lang, this.props.doctorPage).protectReferralFiles(this.props.uData ? this.props.uData.mediaToken : '', this.props.data._id) }}></div> */}
                                        <div dangerouslySetInnerHTML={{
                                            __html: generateHtml({
                                                sections: this.props.data?.questionarySections,
                                                answers: this.props.data?.answers,
                                                question18Finished: [],
                                                intelligentReferral: this.props.data?.intelligentReferral
                                            }, this.props.lang, null, true, true).protectReferralFiles(this.props.uData ? this.props.uData.mediaToken : '', this.props.data._id)
                                        }}></div>
                                        <hr />
                                    </>
                                    :
                                    null
                            }



                        </div>

                        {
                            this.props.enterprise && this.props.data.revisitFormsArray && this.props.data.revisitFormsArray.length && this.props.data.revisitFormsArray[this.state.activeRevisitForm - 1] ?
                                this.props.data.revisitFormsArray[this.state.activeRevisitForm - 1].referralData ?
                                    <div>
                                        {
                                            this.props.screen !== 'patient-cases' ?
                                                <>
                                                    <h6>{/*{'Patient answers'.translate(this.props.lang)}*/}<span style={{ marginLeft: 0, fontWeight: 600 }}>{this.props.data.revisitFormsArray[this.state.activeRevisitForm - 1].name}</span> </h6>
                                                    {/* <div dangerouslySetInnerHTML={{ __html: getReferralDataRevisit(this.props.data.revisitFormsArray[this.state.activeRevisitForm - 1], this.props.lang, this.props.doctorPage).protectReferralFiles(this.props.uData ? this.props.uData.mediaToken : '', this.props.data._id) }}></div> */}
                                                    <div dangerouslySetInnerHTML={{
                                                        __html: generateHtml({
                                                            sections: this.props.data.revisitFormsArray[this.state.activeRevisitForm - 1]?.questionarySections,
                                                            answers: this.props.data.revisitFormsArray[this.state.activeRevisitForm - 1]?.answers,
                                                            question18Finished: [],
                                                            intelligentReferral: this.props.data.revisitFormsArray[this.state.activeRevisitForm - 1]?.intelligentReferral
                                                        }, this.props.lang, null, true, true).protectReferralFiles(this.props.uData ? this.props.uData.mediaToken : '', this.props.data._id)
                                                    }}></div>
                                                </>
                                                :
                                                <>
                                                    <h6><span style={{ fontWeight: 600 }}>{this.props.data.revisitFormsArray[this.state.activeRevisitForm - 1].name}</span> </h6>
                                                </>
                                        }

                                        <Button color='primary' onClick={() => {
                                            if (this.props.previewAnswersRevisit) {
                                                this.props.previewAnswersRevisit(this.props.data.revisitFormsArray[this.state.activeRevisitForm - 1])

                                            }
                                        }}>{'Preview answers'.translate(this.props.lang)}</Button>


                                        <hr />
                                    </div>
                                    :
                                    <div>
                                        {
                                            this.props.screen !== 'patient-cases' ?
                                                <>
                                                    <h6>{/*{'Patient answers'.translate(this.props.lang)}*/}<span style={{ marginLeft: 0, fontWeight: 600 }}>{this.props.data.revisitFormsArray[this.state.activeRevisitForm - 1].name}</span> </h6>
                                                    <p style={{ fontStyle: 'italic', fontSize: 14 }}>{'The patient did not fill out the form'.translate(this.props.lang)}</p>
                                                </>
                                                :
                                                <>
                                                    <h6><span style={{ fontWeight: 600 }}>{this.props.data.revisitFormsArray[this.state.activeRevisitForm - 1].name}</span> </h6>
                                                    <p style={{ fontStyle: 'italic', fontSize: 14 }}>{'The patient did not fill out the form'.translate(this.props.lang)}</p>

                                                </>
                                        }

                                        <hr />
                                    </div>

                                :
                                null
                        }
                        {
                            this.props.data && this.props.data.answers && this.props.data.questionaryData && this.props.data.questionaryData.items ?
                                <div style={{ marginBottom: 10 }}>
                                    {/* <Button color='primary' onClick={() => { this.setState({ previewAnswersReferralModal: this.props.data }) }}>{'Preview answers'.translate(this.props.lang)}</Button> */}
                                    <Button color='primary' onClick={() => { this.setState({ previewAnsweredFormModal: this.props.data }) }}>{'Preview answers'.translate(this.props.lang)}</Button>

                                </div>
                                :
                                null
                        }
                        <div className='patient-referral-preview-status-wrap'>
                            <div>
                                <p> <strong>{'Status:'.translate(this.props.lang)}</strong> {humanReadable[caseStatus]}</p>
                                {
                                    this.props.data?.transferedFromOtherClinicUser?.prevDoctorName && this.props.data?.transferedFromOtherClinicUser?.timestamp ?
                                        <p>
                                            <strong>{'Transferred from:'.translate(this.props.lang)}</strong> {this.props.data?.transferedFromOtherClinicUser?.prevDoctorName} <strong>{'Transferred time:'.translate(this.props.lang)}</strong> {this.props.getStringDateTs(this.props.data?.transferedFromOtherClinicUser?.timestamp, this.props.dateFormat)}
                                        </p>
                                        :
                                        null
                                }
                                {
                                    this.props.data?.caseTransferInfoData ?
                                        <>
                                            {
                                                this.props.data.caseTransferInfoData.transferredFromClinic ?
                                                    <p><strong>{'Transferred from'.translate(this.props.lang)}:</strong> {this.props.data.caseTransferInfoData.transferredFromClinic}{this.props.data.caseTransferInfoData.transferredFromDoctor ? ` ${'and'.translate(this.props.lang)} ${this.props.data.caseTransferInfoData.transferredFromDoctor}` : ''}</p>
                                                    :
                                                    null
                                            }
                                            {
                                                this.props.data.caseTransferInfoData.transferredToClinic ?
                                                    <p><strong>{'Transferred to'.translate(this.props.lang)}:</strong> {this.props.data.caseTransferInfoData.transferredToClinic}{this.props.data.caseTransferInfoData.transferredToDoctor ? ` ${'and'.translate(this.props.lang)} ${this.props.data.caseTransferInfoData.transferredToDoctor}` : ''}</p>
                                                    :
                                                    null
                                            }
                                            {
                                                this.props.data.caseTransferInfoData.transferredTs ?
                                                    <p><strong>{'Date of transfer'.translate(this.props.lang)}:</strong> {this.props.getStringDateTs(this.props.data.caseTransferInfoData.transferredTs, `${this.props.dateFormat} HH:mm`)}</p>
                                                    :
                                                    null
                                            }

                                        </>
                                        :
                                        null
                                }

                                {this.props.data.reason && (this.props.data.status === 'patient-rejected' || this.props.data.status === 'visit-rescheduled' || this.props.data.status === 'requested-new-appointment' || this.props.data.status === 'disallowed') ?
                                    <p> <strong>{'Message:'.translate(this.props.lang)}</strong> {this.props.data.reason}</p>
                                    :
                                    null}

                                {this.props.data.patientReservedTimestamp && !this.props.data.oldPatinetReservedTimestamp ?
                                    <p> <strong>{this.props.data?.dropIn ? `${'Picked up'.translate(this.props.lang)}:` : 'Scheduled time:'.translate(this.props.lang)}</strong> {this.props.getStringDateTs(this.props.data.patientReservedTimestamp, `${this.props.dateFormat}${!this.props.data.hideTimeFromThePatient ? ' HH:mm' : ''}`)/*moment.unix(this.props.data.patientReservedTimestamp).format(`${this.props.dateFormat}${!this.props.data.hideTimeFromThePatient ? ' HH:mm' : ''}`)*/}</p>
                                    :
                                    this.props.data.oldPatinetReservedTimestamp ?
                                        <p> <strong>{'Original scheduled time:'.translate(this.props.lang)}</strong> {this.props.getStringDateTs(this.props.data.oldPatinetReservedTimestamp, `${this.props.dateFormat}${!this.props.data.hideTimeFromThePatient ? ' HH:mm' : ''}`)/*moment.unix(this.props.data.patientReservedTimestamp).format(`${this.props.dateFormat}${!this.props.data.hideTimeFromThePatient ? ' HH:mm' : ''}`)*/}</p>
                                        :
                                        null
                                }
                                {/** oldPatientReservedTimestamp tell if case is rescheduled */}
                                {this.props.data.oldPatinetReservedTimestamp ?
                                    <p> <strong>{'New schedule time:'.translate(this.props.lang)}</strong> {this.props.getStringDateTs(this.props.data.patientReservedTimestamp, `${this.props.dateFormat}${!this.props.data.hideTimeFromThePatient ? ' HH:mm' : ''}`)/*moment.unix(this.props.data.patientReservedTimestamp).format(`${this.props.dateFormat}${!this.props.data.hideTimeFromThePatient ? ' HH:mm' : ''}`)*/}</p>
                                    :
                                    null
                                }
                                {this.props.data.oldPatinetReservedTimestamp ?
                                    <p> <strong>{'Appointement rescheduled:'.translate(this.props.lang)}</strong> {this.props.getStringDateTs(this.props.data.scheduleTimestamp, `${this.props.dateFormat}${!this.props.data.hideTimeFromThePatient ? ' HH:mm' : ''}`)/*moment.unix(this.props.data.patientReservedTimestamp).format(`${this.props.dateFormat}${!this.props.data.hideTimeFromThePatient ? ' HH:mm' : ''}`)*/}</p>
                                    :
                                    null
                                }
                                {
                                    this.props.data.status === 'patient-rejected' && this.props.data.patientRejectedTimestamp ?
                                        <p> <strong>{'Appointment cancelled:'.translate(this.props.lang)}</strong> {this.props.getStringDateTs(this.props.data.patientRejectedTimestamp, `${this.props.dateFormat} HH:mm`)/*moment.unix(this.props.data.patientRejectedTimestamp).format(`${this.props.dateFormat} HH:mm`)*/}</p>
                                        :
                                        null
                                }
                                {
                                    this.props.data.status === 'requested-new-appointment' && this.props.data.patientRequestedNewAppointment ?
                                        <p> <strong>{'Appointment cancelled:'.translate(this.props.lang)}</strong> {this.props.getStringDateTs(this.props.data.patientRequestedNewAppointment, `${this.props.dateFormat} HH:mm`)/*moment.unix(this.props.data.patientRequestedNewAppointment).format(`${this.props.dateFormat} HH:mm`)*/}</p>
                                        :
                                        null
                                }
                                {
                                    this.props.data.status == 'patient-requested-another-time' ?
                                        <p> <strong>{'Requested time:'.translate(this.props.lang)}</strong> {this.props.getStringDateTs(this.props.data.patientRequestedTimestamp, `${this.props.dateFormat} HH:mm`)/*moment.unix(this.props.data.patientRequestedTimestamp).format(`${this.props.dateFormat} HH:mm`)*/}</p>

                                        :
                                        null
                                }

                            </div>

                            {
                                (this.props.data.status == 'revisit-scheduled' || (this.props.data.status === 'visit-rescheduled' && this.props.data.remissType === 'Revisit')) && !this.props.doctorPage && this.props.screen === 'patient-cases' ?
                                    <div className='additional-buttons'>
                                        <Button color='primary'
                                            onClick={() => {
                                                if (this.props.payForTheVisit) {
                                                    this.props.payForTheVisit()
                                                }
                                            }}
                                            disabled={(!this.props.paidControls || (this.props.paidControls && !this.props.paidControls.filter(el => el == this.props.data._id).length)) && (this.props.data.bill && this.props.data.bill.caseObj && this.props.data.bill.caseObj.paymentMethod != 1 && this.props.data.bill.caseObj.paymentMethod != 2 && this.props.data.bill.caseObj.questionaryPrice) ? false : true}>
                                            {'Pay for the visit'.translate(this.props.lang)}
                                        </Button>
                                        {
                                            this.props.data.revisitFormsArray && this.props.data.revisitFormsArray.length ?
                                                <Button color='primary' onClick={() => {
                                                    if (this.props.formsToAnswer) {
                                                        if (this.props.sendEntireReferralOnCheckForms) this.props.formsToAnswer(this.props.data)
                                                        else this.props.formsToAnswer(this.props.data.revisitFormsArray)
                                                    }
                                                }}>
                                                    {'Answer forms'.translate(this.props.lang)}
                                                </Button>

                                                :
                                                null
                                        }

                                    </div>
                                    :
                                    null
                            }

                            {
                                this.props.enterprise && this.props.data.revisitFormsArray && this.props.data.revisitFormsArray.length ?
                                    <div className='doctor-preview-revisit-forms'>
                                        <div className='doctor-preview-revisit-forms-header blablbabla'>
                                            {
                                                this.state.activeRevisitForm ?
                                                    <span>{this.props.data.revisitFormsArray[this.state.activeRevisitForm - 1].name} <span>({this.state.activeRevisitForm}/{this.props.data.revisitFormsArray.length})</span></span>
                                                    :
                                                    <span>{this.props.data.revisitFormsArray[0].name} <span>(1/{this.props.data.revisitFormsArray.length})</span></span>
                                            }
                                        </div>
                                        <div className='buttons-next-prev-wrap'>
                                            <Button color='primary' disabled={this.state.activeRevisitForm && this.state.activeRevisitForm > 1 ? false : true} onClick={() => {
                                                let activeRevisitForm = this.state.activeRevisitForm;
                                                if (!activeRevisitForm) {
                                                    activeRevisitForm = 1;
                                                }
                                                if (activeRevisitForm > 1) {
                                                    activeRevisitForm--;
                                                }
                                                this.setState({ activeRevisitForm })
                                            }}>{'Previous'.translate(this.props.lang)}</Button>
                                            <Button color='primary' disabled={this.state.activeRevisitForm && this.state.activeRevisitForm < this.props.data.revisitFormsArray.length ? false : true} onClick={() => {
                                                let activeRevisitForm = this.state.activeRevisitForm;
                                                if (!activeRevisitForm) {
                                                    activeRevisitForm = 1;
                                                }
                                                if (activeRevisitForm < this.props.data.revisitFormsArray.length) {
                                                    activeRevisitForm++;
                                                }
                                                this.setState({ activeRevisitForm })
                                            }}>{'Next'.translate(this.props.lang)}</Button>
                                        </div>
                                    </div>
                                    :
                                    null

                            }

                        </div>

                        {
                            this.props.screen === 'search-enterprise' ?
                                <>
                                    {
                                        !transferedCaseByConsultation && !(this.props.uData && ((this.props.uData._id == this.props.data.recipientUser) || (this.props.data && this.props.data.additionalDoctors && this.props.data.additionalDoctors.filter(doc => doc == this.props.uData._id).length))) && this.props.data.conversations && this.props.data.conversations.length ?
                                            <div className='read-only-conversation-wrap'>
                                                <div style={{ marginBottom: 10 }}>
                                                    {
                                                        !this.state.showChat ?
                                                            <Button color='primary' onClick={() => this.setState({ showChat: true })}>{'See conversation'.translate(this.props.lang)}</Button>
                                                            :
                                                            <Button color='danger' onClick={() => this.setState({ showChat: false })}>{'Hide conversation'.translate(this.props.lang)}</Button>

                                                    }
                                                </div>
                                                {
                                                    this.state.showChat && this.props.data.conversations && this.props.data.conversations.map((conversation, i) => {
                                                        if (conversation && conversation.messages && conversation.messages.length) {
                                                            return (
                                                                <div className="read-only-messages-wrap">

                                                                    {
                                                                        conversation.messages.map((message, idx) => {
                                                                            if (message.type == 'special') {
                                                                                return (
                                                                                    <>
                                                                                        {message.videoStarted ? <h6 className='video-chat-notify'>
                                                                                            <Isvg src={videoChatIcon} />
                                                                                            <span className='time-started'>{this.props.getStringDateTs(message.tsCreated, this.props.dateFormat + ' HH:mm')/*moment.unix(message.tsCreated).format(this.props.dateFormat + ' HH:mm')*/}</span>
                                                                                            <span className='user-name'>{message.senderName}</span>
                                                                                            <span className='notify'> {'Started video meeting'.translate(this.props.lang)}</span>
                                                                                        </h6> : null}

                                                                                        {message.videoJoined ? <h6 className='video-chat-notify'>
                                                                                            <Isvg src={userChatIcon} />
                                                                                            <span className='time-started'>{this.props.getStringDateTs(message.tsCreated, this.props.dateFormat + ' HH:mm')/*moment.unix(message.tsCreated).format(this.props.dateFormat + ' HH:mm')*/}</span>
                                                                                            <span className='user-name'>{message.senderName}</span>
                                                                                            <span className='notify'> {'Joined video meeting'.translate(this.props.lang)}</span>
                                                                                        </h6> : null}

                                                                                        {message.videoLeft ? <h6 className='video-chat-notify'>
                                                                                            <Isvg src={userChatIcon} />
                                                                                            <span className='time-started'>{this.props.getStringDateTs(message.tsCreated, this.props.dateFormat + ' HH:mm')/*moment.unix(message.tsCreated).format(this.props.dateFormat + ' HH:mm')*/}</span>
                                                                                            <span className='user-name'>{message.senderName}</span>
                                                                                            <span className='notify'> {'Left video meeting'.translate(this.props.lang)}</span>
                                                                                        </h6> : null}

                                                                                        {message.videoEnded ? <h6 className='video-chat-notify'>
                                                                                            <Isvg src={userChatIcon} />
                                                                                            <span className='time-started'>{this.props.getStringDateTs(message.tsCreated, this.props.dateFormat + ' HH:mm')/*moment.unix(message.tsCreated).format(this.props.dateFormat + ' HH:mm')*/}</span>
                                                                                            <span className='user-name'>{message.senderName}</span>
                                                                                            <span className='notify'> {'Ended video meeting'.translate(this.props.lang)}</span>
                                                                                        </h6> : null}
                                                                                    </>
                                                                                )

                                                                            } else {
                                                                                return (
                                                                                    <div className="read-only-message-wrap">
                                                                                        <div className="read-only-message">
                                                                                            <span>{message.senderName}:</span>
                                                                                            <div className="read-only-message-content">
                                                                                                {/* {
                                                                                                message.additionalText && message.additionalText.split('\n').length ?
                                                                                                    message.additionalText.split('\n').map((text, t) => {
                                                                                                        if (t != message.additionalText.split('\n').length - 1)
                                                                                                            return (
                                                                                                                <>{text}<br /></>
                                                                                                            )
                                                                                                        else
                                                                                                            return (
                                                                                                                text
                                                                                                            )
                                                                                                    })
                                                                                                    :
                                                                                                    message.additionalText
                                                                                            } */}
                                                                                                {
                                                                                                    message.additionalText && message.additionalText.split('\n').length ?
                                                                                                        message.additionalText.split('\n').map((text, t) => {
                                                                                                            if (t != message.additionalText.split('\n').length - 1)
                                                                                                                return (
                                                                                                                    <>{text}&nbsp;</>
                                                                                                                )
                                                                                                            else
                                                                                                                return (
                                                                                                                    text
                                                                                                                )
                                                                                                        })
                                                                                                        :
                                                                                                        message.additionalText
                                                                                                }

                                                                                                {
                                                                                                    message.healthCareReferral && !message.hideIcon ?
                                                                                                        <div className='form-icon'>
                                                                                                            <Isvg src={formIcon} />
                                                                                                        </div>
                                                                                                        :
                                                                                                        null
                                                                                                }

                                                                                                {
                                                                                                    message?.message?.indexOf('The patient filled out the form successfully') > -1 ?
                                                                                                        <>
                                                                                                            {'The patient filled out the form successfully'.translate(this.props.lang) + ' - '} &nbsp;
                                                                                                            <div className='form-icon'>
                                                                                                                <Isvg src={formIcon} />
                                                                                                            </div>
                                                                                                            {message.message.split('The patient filled out the form successfully - ')[1]}

                                                                                                        </>

                                                                                                        :
                                                                                                        <>
                                                                                                            {message.message}

                                                                                                        </>


                                                                                                }

                                                                                                {
                                                                                                    message.image ?
                                                                                                        <a style={{ marginLeft: message.message ? 4 : 0 }} href={API_ENDPOINT + message.image} target='_blank'>{'Image'.translate(this.props.lang)}</a>
                                                                                                        :
                                                                                                        null
                                                                                                }
                                                                                                {
                                                                                                    message.video ?
                                                                                                        <a style={{ marginLeft: message.message ? 4 : 0 }} href={API_ENDPOINT + message.video} target='_blank'>{'Video'.translate(this.props.lang)}</a>
                                                                                                        :
                                                                                                        null
                                                                                                }
                                                                                                {
                                                                                                    message.document && message.document.file ?
                                                                                                        <a style={{ marginLeft: message.message ? 4 : 0 }} href={API_ENDPOINT + message.document.file} target='_blank'>{message.document && message.document.name ? message.document.name : 'Document'.translate(this.props.lang)}</a>
                                                                                                        :
                                                                                                        null
                                                                                                }
                                                                                            </div>


                                                                                        </div>
                                                                                        {
                                                                                            message.timestamp ?
                                                                                                <div className='message-time'>{this.props.getStringDateTs(message.timestamp, `${this.props.dateFormat} HH:mm`)/*moment.unix(message.timestamp).format(this.props.dateFormat + " HH:mm")*/}</div>
                                                                                                :
                                                                                                null
                                                                                        }
                                                                                    </div>
                                                                                )
                                                                            }

                                                                        })
                                                                    }

                                                                </div>
                                                            )
                                                        } else
                                                            return (
                                                                <div className="read-only-messages-wrap">
                                                                    <h5>{'Conversation is empty'.translate(this.props.lang)}</h5>
                                                                </div>

                                                            )
                                                    })
                                                }
                                            </div>
                                            :
                                            null
                                    }
                                </>
                                :
                                null
                        }


                    </ModalBody>
                    {
                        this.props.screen === 'all-cases' ?
                            <>
                                {this.props.data.status == 'allowed' || this.props.data.status == 'not-booked' || this.props.data.status == 'canceled-by-clinic' || this.props.data.status == 'requested-new-appointment' ?
                                    <ModalFooter style={{ margin: 'unset', justifyContent: 'flex-end', display: 'flex', width: '100%' }} className="buttons-right-blue">

                                        {
                                            !transferedCaseByConsultation ?
                                                <Button color="primary" style={{ margin: 10 }} onClick={() => {
                                                    if (this.props.generateTimeReferral) { this.props.generateTimeReferral(this.props.data) }
                                                }}>
                                                    {this.props.data.status == 'requested-new-appointment' ? 'Reschedule'.translate(this.props.lang) : 'ScheduleList'.translate(this.props.lang)}
                                                </Button>
                                                :
                                                null
                                        }
                                        {
                                            conditionForRemoveExternalTransfer ?
                                                removeExternalTransferBtn
                                                :
                                                null
                                        }

                                        {
                                            deleteCaseBtn.show && !transferedCaseByConsultation ?
                                                <div className='red-button-wrap'>
                                                    <Button
                                                        disabled={deleteCaseBtn.disabled}
                                                        onClick={() => { if (this.props.deleteCase && !deleteCaseBtn.disabled) { this.props.deleteCase() } }}
                                                        color='danger'
                                                    >
                                                        {'Delete case'.translate(this.props.lang)}
                                                    </Button>
                                                </div>
                                                :
                                                null
                                        }
                                        {
                                            closeCaseBtn.show && !transferedCaseByConsultation ?
                                                <div className='red-button-wrap'>
                                                    <Button
                                                        disabled={closeCaseBtn.disabled}
                                                        onClick={() => { if (this.props.closeCase && !closeCaseBtn.disabled) { this.props.closeCase() } }}
                                                        color="danger"
                                                    >
                                                        {'Close case'.translate(this.props.lang)}
                                                    </Button>
                                                </div>
                                                :
                                                null
                                        }


                                        {this.props.data?._id ?
                                            <Button disabled={!this.props.data._id || this.props.loadingPdf} color="primary" onClick={() => this.props.getCasePdf(this.props.data._id)}> {'Save as pdf'.translate(this.props.lang)} </Button>
                                            :
                                            null
                                        }

                                        <Button color="primary" onClick={() => this.props.closeReferralContent(true)}> {'Close'.translate(this.props.lang)} </Button>
                                    </ModalFooter>
                                    :
                                    this.props.data.status == 'waiting-for-approval' ?
                                        <ModalFooter style={{ margin: 'unset', justifyContent: 'flex-end', display: 'flex', width: '100%' }} className="buttons-right-blue">
                                            {!this.props.data.forwardRecipientClinic && !this.props.data.forward && this.props.uData && this.props.uData.profession && this.props.uData.profession[this.props.selectedGroup] && this.props.data.professionList && this.props.data.professionList.filter(profession => {
                                                if (typeof this.props.uData.profession[this.props.selectedGroup] !== 'string') {
                                                    return this.props.uData.profession[this.props.selectedGroup].indexOf(profession) !== -1

                                                } else {
                                                    return profession == this.props.uData.profession[this.props.selectedGroup]
                                                }
                                            }).length && !transferedCaseByConsultation ?
                                                <>
                                                    <Button color="success" onClick={() => {
                                                        if (this.props.allowReferral) { this.props.allowReferral() }
                                                    }}><><i className="fa fa-check" />{'Allow'.translate(this.props.lang)}</></Button>
                                                    <Button color="danger" onClick={() => {
                                                        if (this.props.disallowReferral) { this.props.disallowReferral(this.props.data) }
                                                    }}><i className="fa fa-close" /> {'Disallow'.translate(this.props.lang)} </Button>
                                                </>
                                                :
                                                null
                                            }
                                            {
                                                conditionForRemoveExternalTransfer ?
                                                    removeExternalTransferBtn
                                                    :
                                                    null
                                            }
                                            {
                                                deleteCaseBtn.show && !transferedCaseByConsultation ?
                                                    <div className='red-button-wrap'>
                                                        <Button
                                                            disabled={deleteCaseBtn.disabled}
                                                            onClick={() => { if (this.props.deleteCase && !deleteCaseBtn.disabled) { this.props.deleteCase() } }}
                                                            color='danger'
                                                        >
                                                            {'Delete case'.translate(this.props.lang)}
                                                        </Button>
                                                    </div>
                                                    :
                                                    null
                                            }
                                            {
                                                closeCaseBtn.show && !transferedCaseByConsultation ?
                                                    <div className='red-button-wrap'>
                                                        <Button
                                                            disabled={closeCaseBtn.disabled}
                                                            onClick={() => { if (this.props.closeCase && !closeCaseBtn.disabled) { this.props.closeCase() } }}
                                                            color="danger"
                                                        >
                                                            {'Close case'.translate(this.props.lang)}
                                                        </Button>
                                                    </div>
                                                    :
                                                    null
                                            }
                                            {this.props.data?._id ?
                                                <Button disabled={!this.props.data._id || this.props.loadingPdf} color="primary" onClick={() => this.props.getCasePdf(this.props.data._id)}> {'Save as pdf'.translate(this.props.lang)} </Button>
                                                :
                                                null
                                            }

                                            <Button color="primary" onClick={() => this.props.closeReferralContent(true)}> {'Close'.translate(this.props.lang)} </Button>


                                        </ModalFooter>
                                        :
                                        <ModalFooter style={{ margin: 'unset', justifyContent: 'flex-end', display: 'flex', width: '100%' }} className="buttons-right-blue">
                                            {
                                                this.props.data.dropIn && !transferedCaseByConsultation ?
                                                    <Button onClick={() => {
                                                        if (this.props.priorityModal) { this.props.priorityModal(this.props.data) }
                                                    }}>{'Set priority'.translate(this.props.lang)}</Button>
                                                    :
                                                    null
                                            }
                                            {/* {
                                                this.props.adminInThisGroup && ((this.props.data.questionaryPrice > 0 && !this.props.data.referralIsPaid && !this.props.data.control) || (this.props.data.control && this.props.data.event && this.props.data.event.questionaryPrice > 0 && !this.props.data.event.appointmentPaid)) ?
                                                    <div className='red-button-wrap'>
                                                        <Button color='danger' onClick={() => {
                                                            if (this.props.deleteCase) { this.props.deleteCase() }
                                                        }} >{'Delete case'.translate(this.props.lang)}</Button>
                                                    </div>
                                                    :
                                                    null
                                            } */}
                                            {
                                                this.props.data.status == 'waiting' && this.props.uData && this.props.uData.profession && this.props.uData.profession[this.props.selectedGroup] && this.props.data.professionList && this.props.data.professionList.filter(profession => {
                                                    if (typeof this.props.uData.profession[this.props.selectedGroup] !== 'string') {
                                                        return this.props.uData.profession[this.props.selectedGroup].indexOf(profession) !== -1
                                                    } else {
                                                        return profession == this.props.uData.profession[this.props.selectedGroup]
                                                    }

                                                }).length && !transferedCaseByConsultation ?
                                                    <Button onClick={() => {
                                                        if (this.props.pickUp) { this.props.pickUp(); }
                                                    }} color="success">{'Pick up'.translate(this.props.lang)}</Button>
                                                    :
                                                    null
                                            }
                                            {
                                                conditionForRemoveExternalTransfer ?
                                                    removeExternalTransferBtn
                                                    :
                                                    null
                                            }
                                            {
                                                deleteCaseBtn.show && !transferedCaseByConsultation ?
                                                    <div className='red-button-wrap'>
                                                        <Button
                                                            disabled={deleteCaseBtn.disabled}
                                                            onClick={() => { if (this.props.deleteCase && !deleteCaseBtn.disabled) { this.props.deleteCase() } }}
                                                            color='danger'
                                                        >
                                                            {'Delete case'.translate(this.props.lang)}
                                                        </Button>
                                                    </div>
                                                    :
                                                    null
                                            }
                                            {
                                                closeCaseBtn.show && !transferedCaseByConsultation ?
                                                    <div className='red-button-wrap'>
                                                        <Button
                                                            disabled={closeCaseBtn.disabled}
                                                            onClick={() => { if (this.props.closeCase && !closeCaseBtn.disabled) { this.props.closeCase() } }}
                                                            color="danger"
                                                        >
                                                            {'Close case'.translate(this.props.lang)}
                                                        </Button>
                                                    </div>
                                                    :
                                                    null
                                            }
                                            {/* {
                                                (this.props.data.status == 'revisit-scheduled' || this.props.data.status === 'visit-rescheduled') && this.props.data.closeCaseManually && this.props.data.recipientUser == this.props.uData._id ?
                                                    <div className='red-button-wrap'>
                                                        <Button color='danger' onClick={() => {
                                                            if (this.props.closeCase) { this.props.closeCase() }
                                                        }} >{'Close case'.translate(this.props.lang)}</Button>
                                                    </div>
                                                    :
                                                    null
                                            } */}
                                            {
                                                (this.props.data.status == 'scheduled' || (this.props.data.status === 'visit-rescheduled' && this.props.data.remissType !== 'Revisit')) && !transferedCaseByConsultation ?
                                                    <>
                                                        {/* {
                                                            this.props.data.recipientUser == this.props.uData._id ?
                                                                <div className='red-button-wrap'>
                                                                    <Button onClick={() => {
                                                                        if (this.props.closeCase) { this.props.closeCase() }
                                                                    }} color="danger">{'Close case'.translate(this.props.lang)}</Button>
                                                                </div>
                                                                :
                                                                null
                                                        } */}
                                                        <Button color="primary" style={{ margin: 10 }} onClick={() => {
                                                            this.props[0].history.push({
                                                                pathname: '/my-data/referrals/schedule',
                                                                search: '?fromAllCases=true',
                                                                state: {
                                                                    doctor: this.props.data.recipientUser,
                                                                    day: new Date(this.props.data.patientReservedTimestamp * 1000),
                                                                    step: 5,

                                                                }
                                                            })
                                                        }}> {'Manage in calendar'.translate(this.props.lang)} </Button>


                                                    </>
                                                    :
                                                    null
                                            }

                                            {this.props.data?._id ?
                                                <Button disabled={!this.props.data._id || this.props.loadingPdf} color="primary" onClick={() => this.props.getCasePdf(this.props.data._id)}> {'Save as pdf'.translate(this.props.lang)} </Button>
                                                :
                                                null
                                            }

                                            <Button color="primary" onClick={() => this.props.closeReferralContent(true)}> {'Close'.translate(this.props.lang)} </Button>

                                        </ModalFooter>


                                }
                            </>
                            :
                            this.props.screen === 'drop-in' ?
                                <>
                                    <ModalFooter style={{ margin: 'unset', justifyContent: 'flex-end', display: 'flex', width: '100%' }} className="buttons-right-blue">
                                        {
                                            !transferedCaseByConsultation ?
                                                <>
                                                    <Button onClick={() => {
                                                        if (this.props.priorityModal) { this.props.priorityModal(this.props.data) }
                                                    }}>{'Set priority'.translate(this.props.lang)}</Button>
                                                    {
                                                        this.props.data.status == 'waiting' && this.props.uData && this.props.uData.profession && this.props.uData.profession[this.props.selectedGroup] && this.props.data.professionList && this.props.data.professionList.filter(profession => {
                                                            if (typeof this.props.uData.profession[this.props.selectedGroup] !== 'string') {
                                                                return this.props.uData.profession[this.props.selectedGroup].indexOf(profession) !== -1

                                                            } else {
                                                                return profession == this.props.uData.profession[this.props.selectedGroup]

                                                            }
                                                        }).length ?
                                                            <Button onClick={() => {
                                                                if (this.props.pickUp) { this.props.pickUp(); }
                                                            }} color="success">{'Pick up'.translate(this.props.lang)}</Button>
                                                            :
                                                            null
                                                    }

                                                    {
                                                        deleteCaseBtn.show ?
                                                            <div className='red-button-wrap'>
                                                                <Button
                                                                    disabled={deleteCaseBtn.disabled}
                                                                    onClick={() => { if (this.props.deleteCase && !deleteCaseBtn.disabled) { this.props.deleteCase() } }}
                                                                    color='danger'
                                                                >
                                                                    {'Delete case'.translate(this.props.lang)}
                                                                </Button>
                                                            </div>
                                                            :
                                                            null
                                                    }
                                                    {
                                                        closeCaseBtn.show ?
                                                            <div className='red-button-wrap'>
                                                                <Button
                                                                    disabled={closeCaseBtn.disabled}
                                                                    onClick={() => { if (this.props.closeCase && !closeCaseBtn.disabled) { this.props.closeCase() } }}
                                                                    color="danger"
                                                                >
                                                                    {'Close case'.translate(this.props.lang)}
                                                                </Button>
                                                            </div>
                                                            :
                                                            null
                                                    }
                                                </>
                                                :
                                                null
                                        }

                                        {
                                            conditionForRemoveExternalTransfer ?
                                                removeExternalTransferBtn
                                                :
                                                null
                                        }
                                        {this.props.data?._id ?
                                            <Button disabled={!this.props.data._id || this.props.loadingPdf} color="primary" onClick={() => this.props.getCasePdf(this.props.data._id)}> {'Save as pdf'.translate(this.props.lang)} </Button>
                                            :
                                            null
                                        }


                                        <Button color="primary" onClick={() => this.props.closeReferralContent(true)}> {'Close'.translate(this.props.lang)} </Button>

                                    </ModalFooter>
                                </>
                                :
                                this.props.screen === 'web-booking' ?
                                    <>
                                        {
                                            this.props.data.status == 'not-booked' ?
                                                <ModalFooter style={{ margin: 'unset', justifyContent: 'flex-end', display: 'flex', width: '100%' }} className="buttons-right-blue">
                                                    {
                                                        conditionForRemoveExternalTransfer ?
                                                            removeExternalTransferBtn
                                                            :
                                                            null
                                                    }
                                                    {
                                                        !transferedCaseByConsultation ?
                                                            <>
                                                                <Button color="primary" style={{ margin: 10 }} onClick={() => {
                                                                    if (this.props.generateTimeReferral) { this.props.generateTimeReferral(this.props.data) }
                                                                }}> {'ScheduleList'.translate(this.props.lang)} </Button>

                                                                {
                                                                    deleteCaseBtn.show ?
                                                                        <div className='red-button-wrap'>
                                                                            <Button
                                                                                disabled={deleteCaseBtn.disabled}
                                                                                onClick={() => { if (this.props.deleteCase && !deleteCaseBtn.disabled) { this.props.deleteCase() } }}
                                                                                color='danger'
                                                                            >
                                                                                {'Delete case'.translate(this.props.lang)}
                                                                            </Button>
                                                                        </div>
                                                                        :
                                                                        null
                                                                }
                                                                {
                                                                    closeCaseBtn.show ?
                                                                        <div className='red-button-wrap'>
                                                                            <Button
                                                                                disabled={closeCaseBtn.disabled}
                                                                                onClick={() => { if (this.props.closeCase && !closeCaseBtn.disabled) { this.props.closeCase() } }}
                                                                                color="danger"
                                                                            >
                                                                                {'Close case'.translate(this.props.lang)}
                                                                            </Button>
                                                                        </div>
                                                                        :
                                                                        null
                                                                }
                                                            </>
                                                            :
                                                            null
                                                    }


                                                    {this.props.data?._id ?
                                                        <Button disabled={!this.props.data._id || this.props.loadingPdf} color="primary" onClick={() => this.props.getCasePdf(this.props.data._id)}> {'Save as pdf'.translate(this.props.lang)} </Button>
                                                        :
                                                        null
                                                    }

                                                    <Button color="primary" onClick={() => this.props.closeReferralContent(true)}> {'Close'.translate(this.props.lang)} </Button>

                                                </ModalFooter>
                                                :
                                                <ModalFooter style={{ margin: 'unset', justifyContent: 'flex-end', display: 'flex', width: '100%' }} className="buttons-right-blue">

                                                    {/* {
                                                        this.props.data.status == 'scheduled' || this.props.data.status === 'visit-rescheduled' ?
                                                            <div className='red-button-wrap'>
                                                                <Button onClick={() => {
                                                                    if (this.props.closeCase) { this.props.closeCase() }
                                                                }} color="danger">{'Close case'.translate(this.props.lang)}</Button>
                                                            </div>

                                                            :
                                                            null
                                                    } */}
                                                    {
                                                        !transferedCaseByConsultation ?
                                                            <>
                                                                {
                                                                    (this.props.data.status == 'scheduled' || this.props.data.status === 'visit-rescheduled') && this.props.adminPermission ?
                                                                        <Button color="primary" style={{ margin: 10 }} onClick={() => {
                                                                            this.props[0].history.push({
                                                                                pathname: '/my-data/referrals/schedule',
                                                                                search: '?fromAllCases=true',
                                                                                state: {
                                                                                    doctor: this.props.data.recipientUser,
                                                                                    day: new Date(this.props.data.patientReservedTimestamp * 1000),
                                                                                    step: 5,

                                                                                }
                                                                            })
                                                                        }}> {'Manage in calendar'.translate(this.props.lang)} </Button> :
                                                                        null
                                                                }

                                                                {
                                                                    deleteCaseBtn.show ?
                                                                        <div className='red-button-wrap'>
                                                                            <Button
                                                                                disabled={deleteCaseBtn.disabled}
                                                                                onClick={() => { if (this.props.deleteCase && !deleteCaseBtn.disabled) { this.props.deleteCase() } }}
                                                                                color='danger'
                                                                            >
                                                                                {'Delete case'.translate(this.props.lang)}
                                                                            </Button>
                                                                        </div>
                                                                        :
                                                                        null
                                                                }
                                                                {
                                                                    closeCaseBtn.show ?
                                                                        <div className='red-button-wrap'>
                                                                            <Button
                                                                                disabled={closeCaseBtn.disabled}
                                                                                onClick={() => { if (this.props.closeCase && !closeCaseBtn.disabled) { this.props.closeCase() } }}
                                                                                color="danger"
                                                                            >
                                                                                {'Close case'.translate(this.props.lang)}
                                                                            </Button>
                                                                        </div>
                                                                        :
                                                                        null
                                                                }
                                                            </>
                                                            :
                                                            null
                                                    }

                                                    {
                                                        conditionForRemoveExternalTransfer ?
                                                            removeExternalTransferBtn
                                                            :
                                                            null
                                                    }

                                                    {this.props.data?._id ?
                                                        <Button disabled={!this.props.data._id || this.props.loadingPdf} color="primary" onClick={() => this.props.getCasePdf(this.props.data._id)}> {'Save as pdf'.translate(this.props.lang)} </Button>
                                                        :
                                                        null
                                                    }
                                                    {/* {
                                                        this.state.printReferralContent ?
                                                            <PrintHelper lang={this.props.lang} html={this.state.printReferralContent} />
                                                            :
                                                            null
                                                    } */}
                                                    <Button color="primary" onClick={() => this.props.closeReferralContent(true)}> {'Close'.translate(this.props.lang)} </Button>

                                                </ModalFooter>
                                        }
                                    </>
                                    :
                                    this.props.screen === 'my-cases' ?
                                        <>
                                            {this.props.data.status == 'waiting-for-approval' || this.props.data.status == 'approved-by-region-manager' || this.props.data.status == 'rejected-by-region-manager' ?
                                                <ModalFooter style={{ margin: 'unset', justifyContent: 'flex-end', display: 'flex', width: '100%' }}>
                                                    {
                                                        !transferedCaseByConsultation ?
                                                            <>
                                                                {!this.props.data.forwardRecipientClinic && !this.props.data.forward ?
                                                                    <>
                                                                        <Button color="success" onClick={() => {
                                                                            if (this.props.allowReferral) { this.props.allowReferral() }
                                                                        }}><><i className="fa fa-check" />{'Allow'.translate(this.props.lang)}</></Button>
                                                                        <Button color="danger" onClick={() => {
                                                                            if (this.props.disallowReferral) { this.props.disallowReferral(this.props.data) }
                                                                        }}><i className="fa fa-close" /> {'Disallow'.translate(this.props.lang)} </Button>
                                                                    </>
                                                                    :
                                                                    null
                                                                }

                                                                {
                                                                    this.props.data.forwardRecipientClinic && this.props.data.forward ?
                                                                        <>
                                                                            <Button color="success" onClick={() => {
                                                                                if (this.props.acceptReferral) { this.props.acceptReferral() }
                                                                            }}><i className="fa fa-check" /> {'Accept'.translate(this.props.lang)}</Button>
                                                                            <Button color="danger" onClick={() => {
                                                                                if (this.props.rejectReferral) { this.props.rejectReferral() }
                                                                            }}><i className="fa fa-close" /> {'Reject'.translate(this.props.lang)} </Button>
                                                                        </>
                                                                        :
                                                                        null
                                                                }
                                                                {
                                                                    deleteCaseBtn.show ?
                                                                        <div className='red-button-wrap'>
                                                                            <Button
                                                                                disabled={deleteCaseBtn.disabled}
                                                                                onClick={() => { if (this.props.deleteCase && !deleteCaseBtn.disabled) { this.props.deleteCase() } }}
                                                                                color='danger'
                                                                            >
                                                                                {'Delete case'.translate(this.props.lang)}
                                                                            </Button>
                                                                        </div>
                                                                        :
                                                                        null
                                                                }
                                                                {
                                                                    closeCaseBtn.show ?
                                                                        <div className='red-button-wrap'>
                                                                            <Button
                                                                                disabled={closeCaseBtn.disabled}
                                                                                onClick={() => { if (this.props.closeCase && !closeCaseBtn.disabled) { this.props.closeCase() } }}
                                                                                color="danger"
                                                                            >
                                                                                {'Close case'.translate(this.props.lang)}
                                                                            </Button>
                                                                        </div>
                                                                        :
                                                                        null
                                                                }
                                                            </>
                                                            :
                                                            null
                                                    }

                                                    {
                                                        conditionForRemoveExternalTransfer ?
                                                            removeExternalTransferBtn
                                                            :
                                                            null
                                                    }
                                                    {this.props.data?._id ?
                                                        <Button disabled={!this.props.data._id || this.props.loadingPdf} color="primary" onClick={() => this.props.getCasePdf(this.props.data._id)}> {'Save as pdf'.translate(this.props.lang)} </Button>
                                                        :
                                                        null
                                                    }


                                                    <Button color="primary" onClick={() => this.props.closeReferralContent(true)}> {'Close'.translate(this.props.lang)} </Button>

                                                </ModalFooter>
                                                :
                                                this.props.data.status == 'patient-requested-another-time' ?
                                                    <ModalFooter style={{ margin: 'unset', justifyContent: 'flex-end', display: 'flex', width: '100%' }}>
                                                        {
                                                            !transferedCaseByConsultation ?
                                                                <>
                                                                    <Button color="success" onClick={() => {
                                                                        if (this.props.acceptRequestedTime) { this.props.acceptRequestedTime() }
                                                                    }}><i className="fa fa-check" /> {'Accept requested time'.translate(this.props.lang)}</Button>
                                                                    <Button color="danger" onClick={() => {
                                                                        if (this.props.rejectRequestedTime) { this.props.rejectRequestedTime(this.props.data) }
                                                                    }}><i className="fa fa-close" /> {'Reject requested time'.translate(this.props.lang)} </Button>
                                                                    {this.props.data?._id ?
                                                                        <Button disabled={!this.props.data._id || this.props.loadingPdf} color="primary" onClick={() => this.props.getCasePdf(this.props.data._id)}> {'Save as pdf'.translate(this.props.lang)} </Button>
                                                                        :
                                                                        null
                                                                    }
                                                                    {
                                                                        deleteCaseBtn.show ?
                                                                            <div className='red-button-wrap'>
                                                                                <Button
                                                                                    disabled={deleteCaseBtn.disabled}
                                                                                    onClick={() => { if (this.props.deleteCase && !deleteCaseBtn.disabled) { this.props.deleteCase() } }}
                                                                                    color='danger'
                                                                                >
                                                                                    {'Delete case'.translate(this.props.lang)}
                                                                                </Button>
                                                                            </div>
                                                                            :
                                                                            null
                                                                    }
                                                                    {
                                                                        closeCaseBtn.show ?
                                                                            <div className='red-button-wrap'>
                                                                                <Button
                                                                                    disabled={closeCaseBtn.disabled}
                                                                                    onClick={() => { if (this.props.closeCase && !closeCaseBtn.disabled) { this.props.closeCase() } }}
                                                                                    color="danger"
                                                                                >
                                                                                    {'Close case'.translate(this.props.lang)}
                                                                                </Button>
                                                                            </div>
                                                                            :
                                                                            null
                                                                    }
                                                                </>
                                                                :
                                                                null
                                                        }
                                                        {
                                                            conditionForRemoveExternalTransfer ?
                                                                removeExternalTransferBtn
                                                                :
                                                                null
                                                        }

                                                        <Button color="primary" onClick={() => this.props.closeReferralContent(true)}> {'Close'.translate(this.props.lang)} </Button>

                                                    </ModalFooter>
                                                    :
                                                    this.props.data.status == 'allowed' || this.props.data.status == 'not-booked' || this.props.data.status == 'canceled-by-clinic' || this.props.data.status == 'requested-new-appointment' ?
                                                        <ModalFooter style={{ margin: 'unset', justifyContent: 'flex-end', display: 'flex', width: '100%' }} className="buttons-right-blue">
                                                            {
                                                                !transferedCaseByConsultation ?
                                                                    <>
                                                                        <Button color="primary" style={{ margin: 10 }} onClick={() => {
                                                                            if (this.props.generateTimeReferral) { this.props.generateTimeReferral(this.props.data) }
                                                                        }}> {this.props.data.status == 'requested-new-appointment' ? 'Reschedule'.translate(this.props.lang) : 'ScheduleList'.translate(this.props.lang)} </Button>
                                                                    </>
                                                                    :
                                                                    null
                                                            }



                                                            {this.props.data?._id ?
                                                                <Button disabled={!this.props.data._id || this.props.loadingPdf} color="primary" onClick={() => this.props.getCasePdf(this.props.data._id)}> {'Save as pdf'.translate(this.props.lang)} </Button>
                                                                :
                                                                null
                                                            }
                                                            {
                                                                !transferedCaseByConsultation ?
                                                                    <>
                                                                        {
                                                                            deleteCaseBtn.show ?
                                                                                <div className='red-button-wrap'>
                                                                                    <Button
                                                                                        disabled={deleteCaseBtn.disabled}
                                                                                        onClick={() => { if (this.props.deleteCase && !deleteCaseBtn.disabled) { this.props.deleteCase() } }}
                                                                                        color='danger'
                                                                                    >
                                                                                        {'Delete case'.translate(this.props.lang)}
                                                                                    </Button>
                                                                                </div>
                                                                                :
                                                                                null
                                                                        }
                                                                        {
                                                                            closeCaseBtn.show ?
                                                                                <div className='red-button-wrap'>
                                                                                    <Button
                                                                                        disabled={closeCaseBtn.disabled}
                                                                                        onClick={() => { if (this.props.closeCase && !closeCaseBtn.disabled) { this.props.closeCase() } }}
                                                                                        color="danger"
                                                                                    >
                                                                                        {'Close case'.translate(this.props.lang)}
                                                                                    </Button>
                                                                                </div>
                                                                                :
                                                                                null
                                                                        }
                                                                    </>
                                                                    :
                                                                    null
                                                            }
                                                            {
                                                                conditionForRemoveExternalTransfer ?
                                                                    removeExternalTransferBtn
                                                                    :
                                                                    null
                                                            }
                                                            <Button color="primary" onClick={() => this.props.closeReferralContent(true)}> {'Close'.translate(this.props.lang)} </Button>
                                                        </ModalFooter>
                                                        :
                                                        <ModalFooter style={{ margin: 'unset', justifyContent: 'flex-end', display: 'flex', width: '100%' }} className="buttons-right-blue">
                                                            {
                                                                !transferedCaseByConsultation ?
                                                                    <>
                                                                        {
                                                                            this.props.data.dropIn ?
                                                                                <Button onClick={() => {
                                                                                    if (this.props.priorityModal) { this.props.priorityModal(this.props.data) }
                                                                                }}>{'Set priority'.translate(this.props.lang)}</Button>
                                                                                :
                                                                                null
                                                                        }
                                                                        {/* {
                                                                this.props.data.status == 'scheduled' || (this.props.data.status === 'visit-rescheduled' && this.props.data.remissType !== 'Revisit') ?
                                                                    <div className='red-button-wrap'>
                                                                        <Button onClick={() => {
                                                                            if (this.props.closeCase) { this.props.closeCase() }
                                                                        }} >{'Close case'.translate(this.props.lang)}</Button>
                                                                    </div>

                                                                    :
                                                                    null
                                                            }
                                                            {
                                                                (this.props.data.status == 'revisit-scheduled' || this.props.data.status === 'visit-rescheduled') && this.props.data.closeCaseManually ?
                                                                    <div className='red-button-wrap'>
                                                                        <Button color='danger' onClick={() => {
                                                                            if (this.props.closeCase) { this.props.closeCase() }
                                                                        }} >{'Close case'.translate(this.props.lang)}</Button>
                                                                    </div>
                                                                    :
                                                                    null
                                                            } */}

                                                                        {
                                                                            (this.props.data.status == 'scheduled' || (this.props.data.status === 'visit-rescheduled' && this.props.data.remissType !== 'Revisit')) && this.props.adminPermission ?
                                                                                <Button color="primary" style={{ margin: 10 }} onClick={() => {
                                                                                    this.props[0].history.push({
                                                                                        pathname: '/my-data/referrals/schedule',
                                                                                        search: '?fromAllCases=true',
                                                                                        state: {
                                                                                            doctor: this.props.data.recipientUser,
                                                                                            day: new Date(this.props.data.patientReservedTimestamp * 1000),
                                                                                            step: 5,
                                                                                        }
                                                                                    })
                                                                                }}> {'Manage in calendar'.translate(this.props.lang)} </Button>
                                                                                :
                                                                                null
                                                                        }
                                                                        {
                                                                            deleteCaseBtn.show ?
                                                                                <div className='red-button-wrap'>
                                                                                    <Button
                                                                                        disabled={deleteCaseBtn.disabled}
                                                                                        onClick={() => { if (this.props.deleteCase && !deleteCaseBtn.disabled) { this.props.deleteCase() } }}
                                                                                        color='danger'
                                                                                    >
                                                                                        {'Delete case'.translate(this.props.lang)}
                                                                                    </Button>
                                                                                </div>
                                                                                :
                                                                                null
                                                                        }
                                                                        {
                                                                            closeCaseBtn.show ?
                                                                                <div className='red-button-wrap'>
                                                                                    <Button
                                                                                        disabled={closeCaseBtn.disabled}
                                                                                        onClick={() => { if (this.props.closeCase && !closeCaseBtn.disabled) { this.props.closeCase() } }}
                                                                                        color="danger"
                                                                                    >
                                                                                        {'Close case'.translate(this.props.lang)}
                                                                                    </Button>
                                                                                </div>
                                                                                :
                                                                                null
                                                                        }
                                                                    </>
                                                                    :
                                                                    null
                                                            }
                                                            {
                                                                conditionForRemoveExternalTransfer ?
                                                                    removeExternalTransferBtn
                                                                    :
                                                                    null
                                                            }
                                                            {this.props.data?._id ?
                                                                <Button disabled={!this.props.data._id || this.props.loadingPdf} color="primary" onClick={() => this.props.getCasePdf(this.props.data._id)}> {'Save as pdf'.translate(this.props.lang)} </Button>
                                                                :
                                                                null
                                                            }


                                                            <Button color="primary" onClick={() => this.props.closeReferralContent(true)}> {'Close'.translate(this.props.lang)} </Button>

                                                        </ModalFooter>

                                            }
                                        </>
                                        :
                                        this.props.screen === 'schedule' ?
                                            <>
                                                {this.props.data.status == 'waiting-for-approval' || this.props.data.status == 'approved-by-region-manager' || this.props.data.status == 'rejected-by-region-manager' ?
                                                    <ModalFooter style={{ margin: 'unset', justifyContent: 'flex-end', display: 'flex', width: '100%' }}>
                                                        {
                                                            !transferedCaseByConsultation ?
                                                                <>
                                                                    {!this.props.data.forwardRecipientClinic && !this.props.data.forward ?
                                                                        <>
                                                                            <Button color="success" onClick={() => {
                                                                                if (this.props.allowReferral) { this.props.allowReferral() }
                                                                            }}><><i className="fa fa-check" />{'Allow'.translate(this.props.lang)}</></Button>
                                                                            <Button color="danger" onClick={() => {
                                                                                if (this.props.disallowReferral) { this.props.disallowReferral(this.props.data) }
                                                                            }}><i className="fa fa-close" /> {'Disallow'.translate(this.props.lang)} </Button>
                                                                        </>
                                                                        :
                                                                        null
                                                                    }

                                                                    {
                                                                        this.props.data.forwardRecipientClinic && this.props.data.forward ?
                                                                            <>
                                                                                <Button color="success" onClick={() => {
                                                                                    if (this.props.acceptReferral) { this.props.acceptReferral() }
                                                                                }}><i className="fa fa-check" /> {'Accept'.translate(this.props.lang)}</Button>
                                                                                <Button color="danger" onClick={() => {
                                                                                    if (this.props.rejectReferral) { this.props.rejectReferral() }
                                                                                }}><i className="fa fa-close" /> {'Reject'.translate(this.props.lang)} </Button>
                                                                            </>
                                                                            :
                                                                            null
                                                                    }

                                                                    {
                                                                        deleteCaseBtn.show ?
                                                                            <div className='red-button-wrap'>
                                                                                <Button
                                                                                    disabled={deleteCaseBtn.disabled}
                                                                                    onClick={() => { if (this.props.deleteCase && !deleteCaseBtn.disabled) { this.props.deleteCase() } }}
                                                                                    color='danger'
                                                                                >
                                                                                    {'Delete case'.translate(this.props.lang)}
                                                                                </Button>
                                                                            </div>
                                                                            :
                                                                            null
                                                                    }
                                                                    {
                                                                        closeCaseBtn.show ?
                                                                            <div className='red-button-wrap'>
                                                                                <Button
                                                                                    disabled={closeCaseBtn.disabled}
                                                                                    onClick={() => { if (this.props.closeCase && !closeCaseBtn.disabled) { this.props.closeCase() } }}
                                                                                    color="danger"
                                                                                >
                                                                                    {'Close case'.translate(this.props.lang)}
                                                                                </Button>
                                                                            </div>
                                                                            :
                                                                            null
                                                                    }
                                                                </>
                                                                :
                                                                null
                                                        }


                                                        {
                                                            conditionForRemoveExternalTransfer ?
                                                                removeExternalTransferBtn
                                                                :
                                                                null
                                                        }
                                                        {this.props.data?._id ?
                                                            <Button disabled={!this.props.data._id || this.props.loadingPdf} color="primary" onClick={() => this.props.getCasePdf(this.props.data._id)}> {'Save as pdf'.translate(this.props.lang)} </Button>
                                                            :
                                                            null
                                                        }

                                                        <Button color="primary" onClick={() => this.props.closeReferralContent(true)}> {'Close'.translate(this.props.lang)} </Button>

                                                    </ModalFooter>
                                                    :
                                                    this.props.data.status == 'patient-requested-another-time' ?
                                                        <ModalFooter style={{ margin: 'unset', justifyContent: 'flex-end', display: 'flex', width: '100%' }}>
                                                            {
                                                                !transferedCaseByConsultation ?
                                                                    <>
                                                                        <Button color="success" onClick={() => {
                                                                            if (this.props.acceptRequestedTime) { this.props.acceptRequestedTime() }
                                                                        }}><i className="fa fa-check" /> {'Accept requested time'.translate(this.props.lang)}</Button>
                                                                        <Button color="danger" onClick={() => {
                                                                            if (this.props.rejectRequestedTime) { this.props.rejectRequestedTime(this.props.data) }
                                                                        }}><i className="fa fa-close" /> {'Reject requested time'.translate(this.props.lang)} </Button>

                                                                        {
                                                                            deleteCaseBtn.show ?
                                                                                <div className='red-button-wrap'>
                                                                                    <Button
                                                                                        disabled={deleteCaseBtn.disabled}
                                                                                        onClick={() => { if (this.props.deleteCase && !deleteCaseBtn.disabled) { this.props.deleteCase() } }}
                                                                                        color='danger'
                                                                                    >
                                                                                        {'Delete case'.translate(this.props.lang)}
                                                                                    </Button>
                                                                                </div>
                                                                                :
                                                                                null
                                                                        }
                                                                        {
                                                                            closeCaseBtn.show ?
                                                                                <div className='red-button-wrap'>
                                                                                    <Button
                                                                                        disabled={closeCaseBtn.disabled}
                                                                                        onClick={() => { if (this.props.closeCase && !closeCaseBtn.disabled) { this.props.closeCase() } }}
                                                                                        color="danger"
                                                                                    >
                                                                                        {'Close case'.translate(this.props.lang)}
                                                                                    </Button>
                                                                                </div>
                                                                                :
                                                                                null
                                                                        }
                                                                    </>
                                                                    :
                                                                    null
                                                            }

                                                            {
                                                                conditionForRemoveExternalTransfer ?
                                                                    removeExternalTransferBtn
                                                                    :
                                                                    null
                                                            }
                                                            {this.props.data?._id ?
                                                                <Button disabled={!this.props.data._id || this.props.loadingPdf} color="primary" onClick={() => this.props.getCasePdf(this.props.data._id)}> {'Save as pdf'.translate(this.props.lang)} </Button>
                                                                :
                                                                null
                                                            }

                                                            <Button color="primary" onClick={() => this.props.closeReferralContent(true)}> {'Close'.translate(this.props.lang)} </Button>

                                                        </ModalFooter>
                                                        :
                                                        this.props.data.status == 'allowed' || this.props.data.status == 'not-booked' || this.props.data.status == 'canceled-by-clinic' || this.props.data.status == 'requested-new-appointment' ?
                                                            <ModalFooter style={{ margin: 'unset', justifyContent: 'flex-end', display: 'flex', width: '100%' }} className="buttons-right-blue">
                                                                {
                                                                    !transferedCaseByConsultation ?
                                                                        <>
                                                                            <Button color="primary" style={{ margin: 10 }} onClick={() => {
                                                                                if (this.props.generateTimeReferral) { this.props.generateTimeReferral(this.props.data) }
                                                                            }}> {this.props.data.status == 'requested-new-appointment' ? 'Reschedule'.translate(this.props.lang) : 'ScheduleList'.translate(this.props.lang)} </Button>
                                                                            {
                                                                                deleteCaseBtn.show ?
                                                                                    <div className='red-button-wrap'>
                                                                                        <Button
                                                                                            disabled={deleteCaseBtn.disabled}
                                                                                            onClick={() => { if (this.props.deleteCase && !deleteCaseBtn.disabled) { this.props.deleteCase() } }}
                                                                                            color='danger'
                                                                                        >
                                                                                            {'Delete case'.translate(this.props.lang)}
                                                                                        </Button>
                                                                                    </div>
                                                                                    :
                                                                                    null
                                                                            }
                                                                            {
                                                                                closeCaseBtn.show ?
                                                                                    <div className='red-button-wrap'>
                                                                                        <Button
                                                                                            disabled={closeCaseBtn.disabled}
                                                                                            onClick={() => { if (this.props.closeCase && !closeCaseBtn.disabled) { this.props.closeCase() } }}
                                                                                            color="danger"
                                                                                        >
                                                                                            {'Close case'.translate(this.props.lang)}
                                                                                        </Button>
                                                                                    </div>
                                                                                    :
                                                                                    null
                                                                            }
                                                                        </>
                                                                        :
                                                                        null
                                                                }
                                                                {
                                                                    conditionForRemoveExternalTransfer ?
                                                                        removeExternalTransferBtn
                                                                        :
                                                                        null
                                                                }
                                                                {this.props.data?._id ?
                                                                    <Button disabled={!this.props.data._id || this.props.loadingPdf} color="primary" onClick={() => this.props.getCasePdf(this.props.data._id)}> {'Save as pdf'.translate(this.props.lang)} </Button>
                                                                    :
                                                                    null
                                                                }

                                                                <Button color="primary" onClick={() => this.props.closeReferralContent(true)}> {'Close'.translate(this.props.lang)} </Button>
                                                            </ModalFooter>
                                                            :
                                                            <ModalFooter style={{ margin: 'unset', justifyContent: 'flex-end', display: 'flex', width: '100%' }} className="buttons-right-blue">
                                                                {
                                                                    !transferedCaseByConsultation ?
                                                                        <>
                                                                            {
                                                                                deleteCaseBtn.show ?
                                                                                    <div className='red-button-wrap'>
                                                                                        <Button
                                                                                            disabled={deleteCaseBtn.disabled}
                                                                                            onClick={() => { if (this.props.deleteCase && !deleteCaseBtn.disabled) { this.props.deleteCase() } }}
                                                                                            color='danger'
                                                                                        >
                                                                                            {'Delete case'.translate(this.props.lang)}
                                                                                        </Button>
                                                                                    </div>
                                                                                    :
                                                                                    null
                                                                            }
                                                                            {
                                                                                closeCaseBtn.show ?
                                                                                    <div className='red-button-wrap'>
                                                                                        <Button
                                                                                            disabled={closeCaseBtn.disabled}
                                                                                            onClick={() => { if (this.props.closeCase && !closeCaseBtn.disabled) { this.props.closeCase() } }}
                                                                                            color="danger"
                                                                                        >
                                                                                            {'Close case'.translate(this.props.lang)}
                                                                                        </Button>
                                                                                    </div>
                                                                                    :
                                                                                    null
                                                                            }
                                                                        </>
                                                                        :
                                                                        null
                                                                }
                                                                {
                                                                    conditionForRemoveExternalTransfer ?
                                                                        removeExternalTransferBtn
                                                                        :
                                                                        null
                                                                }

                                                                {this.props.data?._id ?
                                                                    <Button disabled={!this.props.data._id || this.props.loadingPdf} color="primary" onClick={() => this.props.getCasePdf(this.props.data._id)}> {'Save as pdf'.translate(this.props.lang)} </Button>
                                                                    :
                                                                    null
                                                                }


                                                                <Button color="primary" onClick={() => this.props.closeReferralContent(true)}> {'Close'.translate(this.props.lang)} </Button>

                                                            </ModalFooter>

                                                }
                                            </>
                                            :
                                            this.props.screen === 'received-referrals' ?
                                                <>
                                                    {this.props.data.status == 'waiting-for-approval' || this.props.data.status == 'approved-by-region-manager' || this.props.data.status == 'rejected-by-region-manager' ?
                                                        <ModalFooter style={{ margin: 'unset', justifyContent: 'flex-end', display: 'flex', width: '100%' }} className="buttons-right-blue">
                                                            {
                                                                !transferedCaseByConsultation ?
                                                                    <>
                                                                        {!this.props.data.forwardRecipientClinic && !this.props.data.forward && this.props.uData && this.props.uData.profession && this.props.uData.profession[this.props.selectedGroup] && this.props.data.professionList && this.props.data.professionList.filter(profession => {
                                                                            if (typeof this.props.uData.profession[this.props.selectedGroup] !== 'string') {
                                                                                return this.props.uData.profession[this.props.selectedGroup].indexOf(profession) !== -1
                                                                            } else {
                                                                                return profession == this.props.uData.profession[this.props.selectedGroup]
                                                                            }

                                                                        }).length ?
                                                                            <>
                                                                                <Button color="success" onClick={() => {
                                                                                    if (this.props.allowReferral) { this.props.allowReferral() }
                                                                                }}><><i className="fa fa-check" />{'Allow'.translate(this.props.lang)}</></Button>
                                                                                <Button color="danger" onClick={() => {
                                                                                    if (this.props.disallowReferral) { this.props.disallowReferral(this.props.data) }
                                                                                }}><i className="fa fa-close" /> {'Disallow'.translate(this.props.lang)} </Button>


                                                                            </>
                                                                            :
                                                                            null
                                                                        }

                                                                        {
                                                                            this.props.data.forwardRecipientClinic && this.props.data.forward ?

                                                                                <>
                                                                                    <Button color="success" onClick={() => {
                                                                                        if (this.props.acceptReferral) { this.props.acceptReferral() }
                                                                                    }}><i className="fa fa-check" /> {'Accept'.translate(this.props.lang)}</Button>
                                                                                    <Button color="danger" onClick={() => {
                                                                                        if (this.props.rejectReferral) { this.props.rejectReferral() }
                                                                                    }}><i className="fa fa-close" /> {'Reject'.translate(this.props.lang)} </Button>
                                                                                </>
                                                                                :
                                                                                null
                                                                        }
                                                                        {
                                                                            deleteCaseBtn.show ?
                                                                                <div className='red-button-wrap'>
                                                                                    <Button
                                                                                        disabled={deleteCaseBtn.disabled}
                                                                                        onClick={() => { if (this.props.deleteCase && !deleteCaseBtn.disabled) { this.props.deleteCase() } }}
                                                                                        color='danger'
                                                                                    >
                                                                                        {'Delete case'.translate(this.props.lang)}
                                                                                    </Button>
                                                                                </div>
                                                                                :
                                                                                null
                                                                        }
                                                                        {
                                                                            closeCaseBtn.show ?
                                                                                <div className='red-button-wrap'>
                                                                                    <Button
                                                                                        disabled={closeCaseBtn.disabled}
                                                                                        onClick={() => { if (this.props.closeCase && !closeCaseBtn.disabled) { this.props.closeCase() } }}
                                                                                        color="danger"
                                                                                    >
                                                                                        {'Close case'.translate(this.props.lang)}
                                                                                    </Button>
                                                                                </div>
                                                                                :
                                                                                null
                                                                        }
                                                                    </>
                                                                    :
                                                                    null
                                                            }
                                                            {
                                                                conditionForRemoveExternalTransfer ?
                                                                    removeExternalTransferBtn
                                                                    :
                                                                    null
                                                            }
                                                            {this.props.data?._id ?
                                                                <Button disabled={!this.props.data._id || this.props.loadingPdf} color="primary" onClick={() => this.props.getCasePdf(this.props.data._id)}> {'Save as pdf'.translate(this.props.lang)} </Button>
                                                                :
                                                                null
                                                            }

                                                            <Button color="primary" onClick={() => this.props.closeReferralContent(true)}> {'Close'.translate(this.props.lang)} </Button>

                                                        </ModalFooter>
                                                        :
                                                        this.props.data.status == 'allowed' || this.props.data.status == 'not-booked' || this.props.data.status == 'requested-new-appointment' ?
                                                            <ModalFooter style={{ margin: 'unset', justifyContent: 'flex-end', display: 'flex', width: '100%' }} className="buttons-right-blue">
                                                                {
                                                                    !transferedCaseByConsultation ?
                                                                        <>
                                                                            <Button color="primary" style={{ margin: 10 }} onClick={() => {
                                                                                if (this.props.generateTimeReferral) { this.props.generateTimeReferral(this.props.data) }
                                                                            }}> {this.props.data.status == 'requested-new-appointment' ? 'Reschedule'.translate(this.props.lang) : 'ScheduleList'.translate(this.props.lang)} </Button>

                                                                            {
                                                                                deleteCaseBtn.show ?
                                                                                    <div className='red-button-wrap'>
                                                                                        <Button
                                                                                            disabled={deleteCaseBtn.disabled}
                                                                                            onClick={() => { if (this.props.deleteCase && !deleteCaseBtn.disabled) { this.props.deleteCase() } }}
                                                                                            color='danger'
                                                                                        >
                                                                                            {'Delete case'.translate(this.props.lang)}
                                                                                        </Button>
                                                                                    </div>
                                                                                    :
                                                                                    null
                                                                            }
                                                                            {
                                                                                closeCaseBtn.show ?
                                                                                    <div className='red-button-wrap'>
                                                                                        <Button
                                                                                            disabled={closeCaseBtn.disabled}
                                                                                            onClick={() => { if (this.props.closeCase && !closeCaseBtn.disabled) { this.props.closeCase() } }}
                                                                                            color="danger"
                                                                                        >
                                                                                            {'Close case'.translate(this.props.lang)}
                                                                                        </Button>
                                                                                    </div>
                                                                                    :
                                                                                    null
                                                                            }
                                                                        </>
                                                                        :
                                                                        null
                                                                }
                                                                {
                                                                    conditionForRemoveExternalTransfer ?
                                                                        removeExternalTransferBtn
                                                                        :
                                                                        null
                                                                }
                                                                {this.props.data?._id ?
                                                                    <Button disabled={!this.props.data._id || this.props.loadingPdf} color="primary" onClick={() => this.props.getCasePdf(this.props.data._id)}> {'Save as pdf'.translate(this.props.lang)} </Button>
                                                                    :
                                                                    null
                                                                }

                                                                <Button color="primary" onClick={() => this.props.closeReferralContent(true)}> {'Close'.translate(this.props.lang)} </Button>

                                                            </ModalFooter>
                                                            :
                                                            this.props.data.status == 'patient-requested-another-time' ?
                                                                <ModalFooter>
                                                                    {
                                                                        !transferedCaseByConsultation ?
                                                                            <>
                                                                                <Button color="success" onClick={() => {
                                                                                    if (this.props.acceptRequestedTime) { this.props.acceptRequestedTime() }
                                                                                }}><i className="fa fa-check" /> {'Accept requested time'.translate(this.props.lang)}</Button>
                                                                                <Button color="danger" onClick={() => {
                                                                                    if (this.props.rejectRequestedTime) { this.props.rejectRequestedTime(this.props.data) }
                                                                                }}><i className="fa fa-close" /> {'Reject requested time'.translate(this.props.lang)} </Button>
                                                                                {
                                                                                    deleteCaseBtn.show ?
                                                                                        <div className='red-button-wrap'>
                                                                                            <Button
                                                                                                disabled={deleteCaseBtn.disabled}
                                                                                                onClick={() => { if (this.props.deleteCase && !deleteCaseBtn.disabled) { this.props.deleteCase() } }}
                                                                                                color='danger'
                                                                                            >
                                                                                                {'Delete case'.translate(this.props.lang)}
                                                                                            </Button>
                                                                                        </div>
                                                                                        :
                                                                                        null
                                                                                }
                                                                                {
                                                                                    closeCaseBtn.show ?
                                                                                        <div className='red-button-wrap'>
                                                                                            <Button
                                                                                                disabled={closeCaseBtn.disabled}
                                                                                                onClick={() => { if (this.props.closeCase && !closeCaseBtn.disabled) { this.props.closeCase() } }}
                                                                                                color="danger"
                                                                                            >
                                                                                                {'Close case'.translate(this.props.lang)}
                                                                                            </Button>
                                                                                        </div>
                                                                                        :
                                                                                        null
                                                                                }
                                                                            </>
                                                                            :
                                                                            null
                                                                    }
                                                                    {
                                                                        conditionForRemoveExternalTransfer ?
                                                                            removeExternalTransferBtn
                                                                            :
                                                                            null
                                                                    }
                                                                    {this.props.data?._id ?
                                                                        <Button disabled={!this.props.data._id || this.props.loadingPdf} color="primary" onClick={() => this.props.getCasePdf(this.props.data._id)}> {'Save as pdf'.translate(this.props.lang)} </Button>
                                                                        :
                                                                        null
                                                                    }

                                                                    <Button color="primary" onClick={() => this.props.closeReferralContent(true)}> {'Close'.translate(this.props.lang)} </Button>
                                                                </ModalFooter>
                                                                :
                                                                <ModalFooter style={{ margin: 'unset', justifyContent: 'flex-end', display: 'flex', width: '100%' }} className="buttons-right-blue">

                                                                    {/* {
                                                                        this.props.data.status == 'scheduled' || this.props.data.status === 'visit-rescheduled' || this.props.data.status == 'patient-rejected' || this.props.data.status == 'not-booked' ?
                                                                            <div className='red-button-wrap'>
                                                                                <Button color='danger' onClick={() => {
                                                                                    if (this.props.closeCase) { this.props.closeCase() }
                                                                                }} >{'Close case'.translate(this.props.lang)}</Button>
                                                                            </div>
                                                                            :
                                                                            null
                                                                    } */}
                                                                    {
                                                                        !transferedCaseByConsultation ?
                                                                            <>
                                                                                {
                                                                                    (this.props.data.status == 'scheduled' || this.props.data.status === 'visit-rescheduled') && this.props.adminPermission ?
                                                                                        <Button color="primary" style={{ margin: 10 }} onClick={() => {
                                                                                            this.props[0].history.push({
                                                                                                pathname: '/my-data/referrals/schedule',
                                                                                                search: '?fromAllCases=true',
                                                                                                state: {
                                                                                                    doctor: this.props.data.recipientUser,
                                                                                                    day: new Date(this.props.data.patientReservedTimestamp * 1000),
                                                                                                    step: 5
                                                                                                }
                                                                                            })
                                                                                        }}> {'Manage in calendar'.translate(this.props.lang)} </Button>
                                                                                        :
                                                                                        null
                                                                                }
                                                                                {
                                                                                    deleteCaseBtn.show ?
                                                                                        <div className='red-button-wrap'>
                                                                                            <Button
                                                                                                disabled={deleteCaseBtn.disabled}
                                                                                                onClick={() => { if (this.props.deleteCase && !deleteCaseBtn.disabled) { this.props.deleteCase() } }}
                                                                                                color='danger'
                                                                                            >
                                                                                                {'Delete case'.translate(this.props.lang)}
                                                                                            </Button>
                                                                                        </div>
                                                                                        :
                                                                                        null
                                                                                }
                                                                                {
                                                                                    closeCaseBtn.show ?
                                                                                        <div className='red-button-wrap'>
                                                                                            <Button
                                                                                                disabled={closeCaseBtn.disabled}
                                                                                                onClick={() => { if (this.props.closeCase && !closeCaseBtn.disabled) { this.props.closeCase() } }}
                                                                                                color="danger"
                                                                                            >
                                                                                                {'Close case'.translate(this.props.lang)}
                                                                                            </Button>
                                                                                        </div>
                                                                                        :
                                                                                        null
                                                                                }
                                                                            </>
                                                                            :
                                                                            null
                                                                    }
                                                                    {
                                                                        conditionForRemoveExternalTransfer ?
                                                                            removeExternalTransferBtn
                                                                            :
                                                                            null
                                                                    }
                                                                    {this.props.data?._id ?
                                                                        <Button disabled={!this.props.data._id || this.props.loadingPdf} color="primary" onClick={() => this.props.getCasePdf(this.props.data._id)}> {'Save as pdf'.translate(this.props.lang)} </Button>
                                                                        :
                                                                        null
                                                                    }

                                                                    <Button color="primary" onClick={() => this.props.closeReferralContent(true)}> {'Close'.translate(this.props.lang)} </Button>

                                                                </ModalFooter>
                                                    }
                                                </>
                                                :
                                                this.props.screen === 'search-enterprise' ?
                                                    <>
                                                        {this.props.data.status == 'waiting-for-approval' ?
                                                            <ModalFooter style={{ margin: 'unset', justifyContent: 'flex-end', display: 'flex', width: '100%' }} className="buttons-right-blue">
                                                                {
                                                                    !transferedCaseByConsultation ?
                                                                        <>
                                                                            {!this.props.data.forwardRecipientClinic && !this.props.data.forward && this.props.uData && this.props.uData.profession && this.props.uData.profession[this.props.selectedGroup] && this.props.data.professionList && this.props.data.professionList.filter(profession => {
                                                                                if (typeof this.props.uData.profession[this.props.selectedGroup] !== 'string') {
                                                                                    return this.props.uData.profession[this.props.selectedGroup].indexOf(profession) !== -1
                                                                                } else {
                                                                                    return profession == this.props.uData.profession[this.props.selectedGroup]
                                                                                }

                                                                            }).length ?
                                                                                <>
                                                                                    <Button color="success" onClick={() => {
                                                                                        if (this.props.allowReferral) { this.props.allowReferral() }
                                                                                    }}><><i className="fa fa-check" />{'Allow'.translate(this.props.lang)}</></Button>
                                                                                    <Button color="danger" onClick={() => {
                                                                                        if (this.props.disallowReferral) { this.props.disallowReferral(this.props.data) }
                                                                                    }}><i className="fa fa-close" /> {'Disallow'.translate(this.props.lang)} </Button>
                                                                                </>
                                                                                :
                                                                                null
                                                                            }
                                                                            {
                                                                                deleteCaseBtn.show ?
                                                                                    <div className='red-button-wrap'>
                                                                                        <Button
                                                                                            disabled={deleteCaseBtn.disabled}
                                                                                            onClick={() => { if (this.props.deleteCase && !deleteCaseBtn.disabled) { this.props.deleteCase() } }}
                                                                                            color='danger'
                                                                                        >
                                                                                            {'Delete case'.translate(this.props.lang)}
                                                                                        </Button>
                                                                                    </div>
                                                                                    :
                                                                                    null
                                                                            }
                                                                            {
                                                                                closeCaseBtn.show ?
                                                                                    <div className='red-button-wrap'>
                                                                                        <Button
                                                                                            disabled={closeCaseBtn.disabled}
                                                                                            onClick={() => { if (this.props.closeCase && !closeCaseBtn.disabled) { this.props.closeCase() } }}
                                                                                            color="danger"
                                                                                        >
                                                                                            {'Close case'.translate(this.props.lang)}
                                                                                        </Button>
                                                                                    </div>
                                                                                    :
                                                                                    null
                                                                            }
                                                                        </>
                                                                        :
                                                                        null
                                                                }
                                                                {
                                                                    conditionForRemoveExternalTransfer ?
                                                                        removeExternalTransferBtn
                                                                        :
                                                                        null
                                                                }
                                                                {this.props.data?._id ?
                                                                    <Button disabled={!this.props.data._id || this.props.loadingPdf} color="primary" onClick={() => this.props.getCasePdf(this.props.data._id)}> {'Save as pdf'.translate(this.props.lang)} </Button>
                                                                    :
                                                                    null
                                                                }

                                                                <Button color="primary" onClick={() => this.props.closeReferralContent(true)}> {'Close'.translate(this.props.lang)} </Button>

                                                            </ModalFooter>


                                                            :
                                                            this.props.data.status == 'allowed' || this.props.data.status == 'not-booked' || this.props.data.status == 'canceled-by-clinic' || this.props.data.status == 'requested-new-appointment' ?
                                                                <ModalFooter style={{ margin: 'unset', justifyContent: 'flex-end', display: 'flex', width: '100%' }} className="buttons-right-blue">
                                                                    {
                                                                        !transferedCaseByConsultation ?
                                                                            <>
                                                                                <Button color="primary" style={{ margin: 10 }} onClick={() => {
                                                                                    if (this.props.generateTimeReferral) { this.props.generateTimeReferral(this.props.data) }
                                                                                }}> {this.props.data.status == 'requested-new-appointment' ? 'Reschedule'.translate(this.props.lang) : 'ScheduleList'.translate(this.props.lang)} </Button>
                                                                                {
                                                                                    deleteCaseBtn.show ?
                                                                                        <div className='red-button-wrap'>
                                                                                            <Button
                                                                                                disabled={deleteCaseBtn.disabled}
                                                                                                onClick={() => { if (this.props.deleteCase && !deleteCaseBtn.disabled) { this.props.deleteCase() } }}
                                                                                                color='danger'
                                                                                            >
                                                                                                {'Delete case'.translate(this.props.lang)}
                                                                                            </Button>
                                                                                        </div>
                                                                                        :
                                                                                        null
                                                                                }
                                                                                {
                                                                                    closeCaseBtn.show ?
                                                                                        <div className='red-button-wrap'>
                                                                                            <Button
                                                                                                disabled={closeCaseBtn.disabled}
                                                                                                onClick={() => { if (this.props.closeCase && !closeCaseBtn.disabled) { this.props.closeCase() } }}
                                                                                                color="danger"
                                                                                            >
                                                                                                {'Close case'.translate(this.props.lang)}
                                                                                            </Button>
                                                                                        </div>
                                                                                        :
                                                                                        null
                                                                                }
                                                                            </>
                                                                            :
                                                                            null
                                                                    }
                                                                    {
                                                                        conditionForRemoveExternalTransfer ?
                                                                            removeExternalTransferBtn
                                                                            :
                                                                            null
                                                                    }
                                                                    {this.props.data?._id ?
                                                                        <Button disabled={!this.props.data._id || this.props.loadingPdf} color="primary" onClick={() => this.props.getCasePdf(this.props.data._id)}> {'Save as pdf'.translate(this.props.lang)} </Button>
                                                                        :
                                                                        null
                                                                    }

                                                                    <Button color="primary" onClick={() => this.props.closeReferralContent(true)}> {'Close'.translate(this.props.lang)} </Button>
                                                                </ModalFooter>
                                                                :
                                                                <ModalFooter style={{ margin: 'unset', justifyContent: 'flex-end', display: 'flex', width: '100%' }} className="buttons-right-blue">
                                                                    {
                                                                        !transferedCaseByConsultation ?
                                                                            <>
                                                                                {
                                                                                    this.props.data.dropIn ?
                                                                                        <Button onClick={() => {
                                                                                            if (this.props.priorityModal) { this.props.priorityModal(this.props.data) }
                                                                                        }}>{'Set priority'.translate(this.props.lang)}</Button>
                                                                                        :
                                                                                        null
                                                                                }
                                                                                {/* {
                                                                        (this.props.data.status == 'scheduled' || (this.props.data.status === 'visit-rescheduled' && this.props.data.remissType !== 'Revisit')) && this.props.data.recipientUser == this.props.uData._id ?
                                                                            <Button onClick={() => {
                                                                                if (this.props.closeCase) { this.props.closeCase() }
                                                                            }} >{'Close case'.translate(this.props.lang)}</Button>
                                                                            :
                                                                            null
                                                                    }
                                                                    {
                                                                        (this.props.data.status == 'revisit-scheduled' || this.props.data.status === 'visit-rescheduled') && this.props.data.closeCaseManually && this.props.data.recipientUser == this.props.uData._id ?
                                                                            <div className='red-button-wrap'>
                                                                                <Button color='danger' onClick={() => {
                                                                                    if (this.props.closeCase) { this.props.closeCase() }
                                                                                }} >{'Close case'.translate(this.props.lang)}</Button>
                                                                            </div>
                                                                            :
                                                                            null
                                                                    } */}

                                                                                {
                                                                                    (this.props.data.status == 'scheduled' || (this.props.data.status === 'visit-rescheduled' && this.props.data.remissType !== 'Revisit')) && this.props.adminPermission ?
                                                                                        <Button color="primary" style={{ margin: 10 }} onClick={() => {
                                                                                            this.props[0].history.push({
                                                                                                pathname: '/my-data/referrals/schedule',
                                                                                                search: '?fromAllCases=true',
                                                                                                state: {
                                                                                                    doctor: this.props.data.recipientUser,
                                                                                                    day: new Date(this.props.data.patientReservedTimestamp * 1000),
                                                                                                    step: 5,
                                                                                                }
                                                                                            })
                                                                                        }}> {'Manage in calendar'.translate(this.props.lang)} </Button>
                                                                                        :
                                                                                        null
                                                                                }

                                                                                {
                                                                                    this.props.data.status == 'waiting' && this.props.uData && this.props.uData.profession && this.props.uData.profession[this.props.selectedGroup] && this.props.data.professionList && this.props.data.professionList.filter(profession => {
                                                                                        if (typeof this.props.uData.profession[this.props.selectedGroup] !== 'string') {
                                                                                            return this.props.uData.profession[this.props.selectedGroup].indexOf(profession) !== -1
                                                                                        } else {
                                                                                            return profession == this.props.uData.profession[this.props.selectedGroup]
                                                                                        }
                                                                                    }).length ?
                                                                                        <Button onClick={() => {
                                                                                            if (this.props.pickUp) { this.props.pickUp(); }
                                                                                        }} color="success">{'Pick up'.translate(this.props.lang)}</Button>
                                                                                        :
                                                                                        null
                                                                                }
                                                                                {
                                                                                    deleteCaseBtn.show ?
                                                                                        <div className='red-button-wrap'>
                                                                                            <Button
                                                                                                disabled={deleteCaseBtn.disabled}
                                                                                                onClick={() => { if (this.props.deleteCase && !deleteCaseBtn.disabled) { this.props.deleteCase() } }}
                                                                                                color='danger'
                                                                                            >
                                                                                                {'Delete case'.translate(this.props.lang)}
                                                                                            </Button>
                                                                                        </div>
                                                                                        :
                                                                                        null
                                                                                }
                                                                                {
                                                                                    closeCaseBtn.show ?
                                                                                        <div className='red-button-wrap'>
                                                                                            <Button
                                                                                                disabled={closeCaseBtn.disabled}
                                                                                                onClick={() => { if (this.props.closeCase && !closeCaseBtn.disabled) { this.props.closeCase() } }}
                                                                                                color="danger"
                                                                                            >
                                                                                                {'Close case'.translate(this.props.lang)}
                                                                                            </Button>
                                                                                        </div>
                                                                                        :
                                                                                        null
                                                                                }
                                                                            </>
                                                                            :
                                                                            null
                                                                    }
                                                                    {
                                                                        conditionForRemoveExternalTransfer ?
                                                                            removeExternalTransferBtn
                                                                            :
                                                                            null
                                                                    }
                                                                    {this.props.data?._id ?
                                                                        <Button disabled={!this.props.data._id || this.props.loadingPdf} color="primary" onClick={() => this.props.getCasePdf(this.props.data._id)}> {'Save as pdf'.translate(this.props.lang)} </Button>
                                                                        :
                                                                        null
                                                                    }

                                                                    <Button color="primary" onClick={() => this.props.closeReferralContent(true)}> {'Close'.translate(this.props.lang)} </Button>

                                                                </ModalFooter>

                                                        }
                                                    </>
                                                    :
                                                    this.props.screen === 'patient-cases' ?
                                                        <ModalFooter style={{ margin: 'unset', justifyContent: 'flex-end', display: 'flex', width: '100%' }}>

                                                            {
                                                                !this.props.hideReject && this.props.data.patientReservedTimestamp && (this.props.data.status == 'scheduled' || this.props.data.status === 'visit-rescheduled' || this.props.data.status == 'clinic-rejected-requested-time' || this.props.data.status == 'revisit-scheduled') ?
                                                                    <>
                                                                        <Button color="danger" className="danger-button" onClick={() => {
                                                                            if (this.props.rejectForm) { this.props.rejectForm(this.props.data) }
                                                                        }}><i className="fa fa-close" /> {'Reject'.translate(this.props.lang)} </Button>
                                                                    </>
                                                                    :
                                                                    null
                                                            }
                                                            {this.props.data?._id ?
                                                                <Button disabled={!this.props.data._id || this.props.loadingPdf} color="primary" onClick={() => this.props.getCasePdf(this.props.data._id)}> {'Save as pdf'.translate(this.props.lang)} </Button>
                                                                :
                                                                null
                                                            }

                                                            <Button color="primary" onClick={() => this.props.closeReferralContent(true)}> {'Close'.translate(this.props.lang)} </Button>

                                                        </ModalFooter>
                                                        :
                                                        this.props.screen === 'consultation' ?
                                                            <ModalFooter style={{ margin: 'unset', justifyContent: 'flex-end', display: 'flex', width: '100%' }}>


                                                                <Button color="primary" onClick={() => this.props.closeReferralContent(true)}> {'Close'.translate(this.props.lang)} </Button>

                                                            </ModalFooter>
                                                            :
                                                            <ModalFooter style={{ margin: 'unset', justifyContent: 'flex-end', display: 'flex', width: '100%' }}>

                                                                {
                                                                    !transferedCaseByConsultation && !this.props.hideReject && this.props.data.patientReservedTimestamp && (this.props.data.status == 'scheduled' || this.props.data.status === 'visit-rescheduled' || this.props.data.status == 'clinic-rejected-requested-time' || this.props.data.status == 'revisit-scheduled') ?
                                                                        <>
                                                                            <Button color="danger" className="danger-button" onClick={() => this.setState({ rejectForm: this.props.data })}><i className="fa fa-close" /> {'Reject'.translate(this.props.lang)} </Button>
                                                                        </>
                                                                        :
                                                                        null
                                                                }
                                                                {this.props.data?._id ?
                                                                    <Button disabled={!this.props.data._id || this.props.loadingPdf} color="primary" onClick={() => this.props.getCasePdf(this.props.data._id)}> {'Save as pdf'.translate(this.props.lang)} </Button>
                                                                    :
                                                                    null
                                                                }
                                                                {/* {
                                                                this.state.printReferralContent ?
                                                                    <PrintHelper lang={this.props.lang} html={this.state.printReferralContent} />
                                                                    :
                                                                    null
                                                            } */}
                                                                <Button color="primary" onClick={() => this.props.closeReferralContent(true)}> {'Close'.translate(this.props.lang)} </Button>

                                                            </ModalFooter>
                    }


                </Modal>

                {
                    this.state.previewAnswersReferralModal ?
                        <Modal isOpen={this.state.previewAnswersReferralModal} centered toggle={() => this.setState({ previewAnswersReferralModal: !this.state.previewAnswersReferralModal })} size="lg">
                            <ModalHeader toggle={() => this.setState({ previewAnswersReferralModal: !this.state.previewAnswersReferralModal })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ previewAnswersReferralModal: !this.state.previewAnswersReferralModal })}><Isvg src={xIcon} /></button>}>{'Form preview'.translate(this.props.lang)}</ModalHeader>
                            <ModalBody>
                                <FormPreview {...this.props} referral={{ name: this.state.previewAnswersReferralModal.name, description: this.state.previewAnswersReferralModal.description, intelligentReferral: this.state.previewAnswersReferralModal.intelligentReferral }} sections={generateSections(this.state.previewAnswersReferralModal.questionaryData.items)} answers={this.state.previewAnswersReferralModal.answers} disabledForm={true} hidePreview={true}></FormPreview>
                            </ModalBody>
                        </Modal>
                        :
                        null
                }
                {
                    this.state.previewAnsweredFormModal ?
                        <Modal
                            isOpen={this.state.previewAnsweredFormModal}
                            centered
                            toggle={() => this.setState({ previewAnsweredFormModal: !this.state.previewAnsweredFormModal })}
                            size="lg"
                        >
                            <ModalHeader
                                toggle={() => this.setState({ previewAnsweredFormModal: !this.state.previewAnsweredFormModal })}
                                close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ previewAnsweredFormModal: !this.state.previewAnsweredFormModal })}
                                >
                                    <Isvg src={xIcon} /></button>}>{'Form preview'.translate(this.props.lang)}
                            </ModalHeader>
                            <ModalBody>
                                <PreviewAnsweredForm
                                    {...this.props}
                                    referral={{
                                        name: this.state.previewAnsweredFormModal.name,
                                        description: this.state.previewAnsweredFormModal.description,
                                        intelligentReferral: this.state.previewAnsweredFormModal.intelligentReferral,
                                        items: this.state.previewAnsweredFormModal.questionaryData?.items,
                                        group: this.state.previewAnsweredFormModal.questionaryData?.group
                                    }}
                                    sections={generateSections(this.state.previewAnsweredFormModal.questionaryData.items)}
                                    answers={this.state.previewAnsweredFormModal.answers}
                                    disabledForm={true}
                                    hidePreview={true}
                                />
                            </ModalBody>
                        </Modal>
                        :
                        null
                }

            </div >
        );


    }
}
export default referralContent;
