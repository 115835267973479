import React, { Component } from 'react';
import Page from '../containers/page';
import { getSearchParams, generateSearchLink, generateSearchLinkMultiple } from '../helpers/linkHelper';
import {
    Container,
    Row,
    Col, Modal, ModalBody, ModalFooter, ModalHeader, Button, FormGroup, Label, Input
} from 'reactstrap';
import Isvg from 'react-inlinesvg';

import { API_ENDPOINT } from '../constants';
import ListBuilder from '../components/listBuilder';

import exportIcon from '../assets/svg/export-json.svg';
import eyeIcon from '../assets/svg/eye.svg';
import trashIcon from '../assets/svg/garbage.svg';
// import exportIcon2 from '../assets/svg/export-json2.svg';
import checkIcon from '../assets/svg/handled.svg';
import ErrorModal from '../components/errorModal';

export class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...props.initialData,
            form: false,
            useState: false,
            params: {
                entries: 10,
                page: 0,
                sortField: '_id',
                sortType: -1
            },
            items: [],
            total: 0,
            loading: true,
            error: null
        }
    }

    get = () => {
        this.setState({ loading: true }, () => {
            this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));

            for (let i = 0; i < this.props?.loadDataWithQuery?.length; i++) {
                this.props.loadDataWithQuery?.[i]?.(window?.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props?.[0]?.location?.search, {
                    entries: 10,
                    page: 0,
                    sortField: '_id',
                    sortType: -1,
                })).then(data => {
                    if (data?.error) this.setState({ loading: null, error: data.error?.translate(this.props.lang || 'en') });
                    else {
                        let dataToSet = { ...data };
                        dataToSet.loading = null;
                        if (dataToSet.page || dataToSet.items || dataToSet.total) {
                            let checkFilter = '';
                            if (dataToSet.resultFilter) {
                                checkFilter = String(dataToSet.resultFilter)
                                delete dataToSet.resultFilter;
                            }
                            let search = this.props[0].location.search;
                            let serachParams = getSearchParams(search);
                            let searchParamsFilter = '';
                            if (serachParams.filter) {
                                searchParamsFilter = serachParams.filter;
                            }
                            if (searchParamsFilter != checkFilter) {
                                delete dataToSet.page;
                                delete dataToSet.items;
                                delete dataToSet.total;
                                delete dataToSet.loading;
                            }
                        }
                        this.setState({ ...dataToSet }, () => {
                            this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                        })
                    }
                })
            }
        })
    }

    componentDidMount() {
        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }

        this.get();
    }

    componentDidUpdate(prevProps) {
        if (prevProps[0].location.search != this.props[0].location.search) {
            this.setState({
                loading: true
            }, () => {
                this.get();

            })
        }
    }

    updateSort = (field, type) => {
        if (this.state.useState) {
            let state = this.state.params;
            state.sortField = field;
            state.sortType = type;
            this.setState({
                params: state
            })
        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0 }, [{ name: 'sortField', value: field }, { name: 'sortType', value: type }], false));

        }
    }

    updateParams = (name, value, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0,
                    sortField: '_id',
                    sortType: -1
                };
                obj[name] = value;
                this.setState({ params: obj });
            } else {
                let obj = this.state.params;
                obj[name] = value;
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(generateSearchLink(this.props[0].location.search, { entries: 10, page: 0 }, name, value, restart));
        }
    }
    updateMultipleParams = (fields, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0,
                    sortField: '_id',
                    sortType: -1
                };
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj });

            } else {
                let obj = this.state.params;
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0 }, fields, restart));
        }
    }

    generateFile = (file, name = 'dashboard-errors') => {
        try {
            let date = new Date();
            let a = document?.createElement?.('a');
            a.href = file;
            a.download = `${name}-${date?.getDate()}-${date?.getMonth() + 1}-${date?.getFullYear()}.json`;
            document?.body?.appendChild?.(a);
            a?.click?.();
            a?.remove?.();
        } catch (err) {
            console.log('generate file err: ', err);
        }

    }

    exportReport = async (id) => {
        if (!id) return;
        try {
            const response = await fetch(`${API_ENDPOINT}/error-boundary/${id}`, {
                method: 'GET',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
            });

            if (response && response.ok) {
                const blob = await response.blob();
                const file = window?.URL?.createObjectURL(blob);
                if (file) this.generateFile(file);
                else this.setState({ error: 'Something went wrong! Please try again later!' })
            } else {
                const data = await response.json();
                if (data && data.error) this.setState({ error: data?.error || 'Something went wrong! Please try again later.' });
            }
        } catch (err) {
            console.log('export report err:', err);
            this.setState({ error: err?.message || 'Something went wrong! Please try again later!' })
        }
    }

    markReportAsHandled = (id) => {
        if (!id) return;
        const submit = async (id) => {
            try {
                const response = await fetch(`${API_ENDPOINT}/error-boundary/${id}`, {
                    method: 'PUT',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    }
                });
                if (response && response.ok) {
                    const data = await response.json();
                    if (data.error) this.setState({ error: data.error, confirmModal: null });
                    else this.setState({ errorDetails: null, confirmModal: null }, () => this.get());
                } else this.setState({ error: 'Something went wrong! Please try again later!', confirmModal: null })
            } catch (err) {
                console.log('mark report as handler err: ', err);
                this.setState({ error: 'Something went wrong! Please try again later!', confirmModal: null })
            }
        }
        this.setState({
            confirmModal: {
                id,
                onYes: submit,
                yesText: 'Mark as handled'.translate(this.props.lang || 'en'),
                noText: 'Cancel'.translate(this.props.lang || 'en'),
                yesColor: 'success',
                message: 'Mark this error report as handled?'.translate(this.props.lang || 'en')
            }
        })
    }


    deleteReport = (id) => {
        if (!id) return;
        const submit = async (id) => {
            try {
                const response = await fetch(`${API_ENDPOINT}/error-boundary/${id}`, {
                    method: 'DELETE',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    }
                });
                if (response && response.ok) {
                    const data = await response.json();
                    if (data.error) this.setState({ error: data.error, confirmModal: null });
                    else this.setState({ errorDetails: null, confirmModal: null }, () => this.get());
                } else this.setState({ error: 'Something went wrong! Please try again later!', confirmModal: null })
            } catch (err) {
                console.log('mark report as handler err: ', err);
                this.setState({ error: 'Something went wrong! Please try again later!', confirmModal: null })
            }
        }
        this.setState({
            confirmModal: {
                id,
                onYes: submit,
                yesText: 'Delete report'.translate(this.props.lang || 'en'),
                noText: 'Cancel'.translate(this.props.lang || 'en'),
                yesColor: 'danger',
                message: 'Are you sure you want do delete this item? This action cannot be reversed!'.translate(this.props.lang || 'en')
            }
        })
    }


    render() {
        let params = {};
        if (this.state.useState) {
            params = this.state.params;
        } else {
            params = getSearchParams(this.props[0].location.search, { entries: 10, page: 0 })
        }

        return (
            <div className={this.props.autoHideSidebar ? "dashboard dashboard-auto-hide" : "dashboard"}>
                <Container fluid>
                    <Row>
                        <Col lg='12'>
                            <div className='panel aeb-panel'>
                                <div className='panel-header'>
                                    <h5 className='component-header'>{'Error Boundary Logs'.translate(this.props?.lang || 'en')}</h5>
                                    <Button onClick={() => this.exportReport('all')} color='primary' type='button' className='aeb-export-button'>
                                        <Isvg src={exportIcon} />
                                        {'Export all unhandled errors'.translate(this.props?.lang || 'en')}
                                    </Button>
                                </div>

                                <ListBuilder
                                    lang={this.props.lang}
                                    loading={this.state.loading}
                                    total={this.state.total}
                                    showNumeration={false}
                                    fields={[
                                        { type: 'text', name: 'name', label: 'Error name'.translate(this.props.lang), allowSort: true },
                                        { type: 'text', name: 'message', label: 'Error message'.translate(this.props.lang), allowSort: true },
                                        { type: 'text', name: 'page', label: 'Route'.translate(this.props.lang), allowSort: true },
                                        { type: 'text', name: 'platform', label: 'Platform'.translate(this.props.lang), allowSort: true },
                                        { type: 'text', name: 'ts', label: 'Date'.translate(this.props.lang), allowSort: true },
                                        { type: 'text', name: 'handled', label: 'Handled'.translate(this.props.lang), allowSort: true },
                                    ]}
                                    rawItems={this.state.items}
                                    items={this.state.items?.map?.(item => ({
                                        ...item,
                                        name: item?.name?.length > 15 ? item?.name?.slice?.(0, 15) + '...' : item?.name,
                                        message: item?.message?.length > 20 ? item?.message?.slice?.(0, 20) + '...' : item?.message,
                                        page: item?.page?.length > 15 ? item?.page?.slice?.(0, 15) + '...' : item?.page,
                                        handled: item?.handled ? <span style={{ fontWeight: 600, color: '#42ba96' }}>{'HANDLED'.translate(this.props.lang || 'en')}</span> : <span style={{ fontWeight: 600, color: '#df4759' }}>{'UNHANDLED'.translate(this.props.lang || 'en')}</span>,
                                        platform: item?.app ? 'app' : 'web',
                                        isHandled: item?.handled,
                                        ts: new Date(item?.ts).toLocaleString('en-EN')
                                    }))}
                                    actions={[
                                        {
                                            component: <div className="tabel-action-tooltip">
                                                <Isvg src={eyeIcon} className="view-icon" />
                                                <div className="chat-icons-tooltip">{'View details'.translate(this.props.lang)}</div>
                                            </div>,
                                            onClick: item => this.setState({ errorDetails: item })
                                        },
                                        {
                                            component: <div className="tabel-action-tooltip">
                                                <Isvg src={checkIcon} className="view-icon" />
                                                <div className="chat-icons-tooltip">{'Mark as handled'.translate(this.props.lang)}</div>
                                            </div>,
                                            onClick: item => this.markReportAsHandled(item?._id),
                                            condition: item => {  return item && item.isHandled ? false : true }
                                        },
                                        {
                                            component: <div className="tabel-action-tooltip aeb-icon">
                                                <Isvg src={exportIcon} className="view-icon" />
                                                <div className="chat-icons-tooltip">{'Export report'.translate(this.props.lang)}</div>
                                            </div>,
                                            onClick: item => this.exportReport(item?._id)
                                        },
                                        {
                                            component: <div className="tabel-action-tooltip">
                                                <Isvg src={trashIcon} className="view-icon" />
                                                <div className="chat-icons-tooltip">{'Delete report'.translate(this.props.lang)}</div>
                                            </div>,
                                            onClick: item => this.deleteReport(item?._id)
                                        },
                                    ]}
                                    params={params}
                                    sortField={params.sortField}
                                    sortType={params.sortType}
                                    updateSort={this.updateSort}
                                    updateParams={this.updateParams}
                                    updateMultipleParams={this.updateMultipleParams}
                                />
                            </div>
                        </Col>
                    </Row>
                </Container>
                {this.state.errorDetails ? <Modal isOpen={this.state.errorDetails} size='lg' centered toggle={() => this.setState({ errorDetails: null })} backdrop={true}>
                    <ModalHeader style={{ textAlign: 'center' }}>{this.state.errorDetails?.name}</ModalHeader>
                    <ModalBody>
                        <div className='eb-modal-div'>
                            <h6>{'Platform'.translate(this.props.lang || 'en')}</h6>
                            <div>{this.state.errorDetails?.app ? 'APP': 'WEB'}</div>
                        </div>
                        <div className='eb-modal-div'>
                            <h6>{'Route'.translate(this.props.lang || 'en')}</h6>
                            <div>{this.state.errorDetails?.page}</div>
                        </div>
                        <div className='eb-modal-div'>
                            <h6>{'Message'.translate(this.props.lang || 'en')}</h6>
                            <div>{this.state.errorDetails?.message}</div>
                        </div>
                        <div className='eb-modal-div'>
                            <h6>{'Error Stack'.translate(this.props.lang || 'en')}</h6>
                            <div>{this.state.errorDetails?.errorStack?.includes?.('\n')
                                ? this.state.errorDetails.errorStack.split?.('\n').map?.((row, i) => <p key={i}>{row}</p>) : this.state.errorDetails?.errorStack}</div>
                        </div>
                        <div className='eb-modal-div'>
                            <h6>{'Native Stack'.translate(this.props.lang || 'en')}</h6>
                            <div>{this.state.errorDetails?.nativeStack?.includes?.('\n')
                                ? this.state.errorDetails.nativeStack.split?.('\n').map?.((row, i) => <p key={i}>{row}</p>) : this.state.errorDetails?.nativeStack}</div>
                        </div>
                    </ModalBody>
                    <ModalFooter style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                        <button style={{ visibility: 'hidden', display: 'none' }} />
                        {this.state.errorDetails?.handled ? null : <Button color='success' onClick={() => this.markReportAsHandled(this.state.errorDetails?._id)}>{'Mark as handled'.translate(this.props.lang || 'en')}</Button>}
                        <Button color='info' onClick={() => this.exportReport(this.state.errorDetails?._id)}>{'Export report'.translate(this.props.lang || 'en')}</Button>
                        <Button color='danger' onClick={() => this.deleteReport(this.state.errorDetails?._id)}>{'Delete report'.translate(this.props.lang || 'en')}</Button>
                        <Button color='primary' onClick={() => this.setState({ errorDetails: null })}>{'Close'.translate(this.props.lang || 'en')}</Button>
                        <button style={{ visibility: 'hidden', display: 'none' }} />
                    </ModalFooter>
                </Modal> : null}

                {this.state.confirmModal ? <Modal isOpen={this.state.confirmModal} size='md' centered toggle={() => this.setState({ confirmModal: null })} backdrop={true}>
                    <ModalBody className='delete-modal' style={{ paddingTop: 30 }}>
                        <div className='message'>
                            {this.state.confirmModal?.message}
                        </div>
                    </ModalBody>
                    <ModalFooter style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <button style={{ visibility: 'hidden', display: 'none' }} />
                        <Button style={{ minWidth: 100 }} color={this.state.confirmModal?.yesColor || 'danger'} onClick={() => this.state.confirmModal?.onYes?.(this.state.confirmModal?.id)}>
                            {this.state.confirmModal?.yesText || 'Yes'.translate(this.props.lang || 'en')}
                        </Button>
                        <Button style={{ minWidth: 100 }} color='primary' onClick={() => this.setState({ confirmModal: null })}>
                            {this.state.confirmModal?.noText || 'No'.translate(this.props.lang || 'en')}
                        </Button>
                        <button style={{ visibility: 'hidden', display: 'none' }} />
                    </ModalFooter>
                </Modal> : null}

                {this.state.error ? <ErrorModal isOpen={this.state.error} toggle={() => this.setState({error: null})} size='md'>
                    {typeof this.state.error === 'string' ? this.state.error.translate(this.props.lang || 'en') : 'Something went wrong! Please try again later.'.translate(this.props.lang || 'en')}
                </ErrorModal> : null}
            </div>
        )
    }
}

export default Page(ErrorBoundary);