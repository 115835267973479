import { API_ENDPOINT } from "../constants";



function checkPoints(sections, answers) {
    let totalSubtotal = [];
    if (sections && sections.length) {
        for (let i = 0; i < sections.length; i++) {
            if (sections[i].questions && sections[i].questions?.length) {
                for (let j = 0; j < sections[i].questions?.length; j++) {
                    if (sections[i].questions[j].type == 16 || sections[i].questions[j].type == 17) {
                        totalSubtotal.push({
                            type: sections[i].questions[j].type,
                            id: sections[i].questions[j].id,
                            conditions: sections[i].questions[j].conditions,
                            sum: 0
                        })
                    }
                }
            }
        }
    }
    if (totalSubtotal && totalSubtotal.length && answers) {
        let sumSubtotal = 0;
        let sumTotal = 0;
        for (let i = 0; i < totalSubtotal.length; i++) {
            totalSubtotal[i].sum = 0;
        }

        let questionsArr = [];
        for (let i = 0; i < sections.length; i++) {
            for (let j = 0; j < sections[i].questions?.length; j++) {
                if (answers[sections[i].questions[j].id]) {
                    questionsArr.push(sections[i].questions[j].id)
                }
            }
        }

        for (let questionIdx = 0; questionIdx < questionsArr.length; questionIdx++) {
            let key = questionsArr[questionIdx];
            let question = getQuestion(key, sections)
            if (question) {
                if (question.type == 16) {
                    let index = totalSubtotal.findIndex(el => el.id == question.id)
                    if (index > -1) {
                        totalSubtotal[index].sum = sumSubtotal;
                        if (question.blocks && question.blocks.length) {
                            let blocks = question.blocks;
                            for (let i = 0; i < blocks.length; i++) {
                                let start = 0;
                                let end = 0;
                                if (blocks[i].start) {
                                    start = Number(blocks[i].start)
                                }
                                if (blocks[i].end) {
                                    end = Number(blocks[i].end)
                                }


                            }
                        }
                        sumSubtotal = 0;
                    }

                }
                if (question.type == 17) {
                    let index = totalSubtotal.findIndex(el => el.id == question.id)
                    if (index > -1) {

                        totalSubtotal[index].sum = sumTotal;
                        if (question.blocks && question.blocks.length) {
                            let blocks = question.blocks;
                            for (let i = 0; i < blocks.length; i++) {
                                let start = 0;
                                let end = 0;
                                if (blocks[i].start) {
                                    start = Number(blocks[i].start)
                                }
                                if (blocks[i].end) {
                                    end = Number(blocks[i].end)
                                }


                            }
                        }
                        sumTotal = 0;
                    }
                }

                if (question.type == 1 && question.answers && question.answers.filter(el => el.id == answers[key]).length && question.answers.filter(el => el.id == answers[key])[0].score) {
                    let score = question.answers.filter(el => el.id == answers[key])[0].score;
                    sumSubtotal += score;
                    sumTotal += score;
                }

                if (question.type == 2 && question.answers && answers[key] && answers[key].length) {
                    for (let i = 0; i < answers[key].length; i++) {
                        if (question.answers.filter(el => el.id == answers[key][i]).length && question.answers.filter(el => el.id == answers[key][i])[0].score) {
                            let score = question.answers.filter(el => el.id == answers[key][i])[0].score;
                            sumSubtotal += score;
                            sumTotal += score;
                        }
                    }
                }
                if ((question.type == 5 || question.type == 6 || question.type == 12 || question.type == 13 || question.type == 14) && question.score) {
                    let score = question.score;
                    sumSubtotal += score;
                    sumTotal += score;
                }
            }
        }



    }
    let response = {}
    if (totalSubtotal && totalSubtotal.length) {
        for (let i = 0; i < totalSubtotal.length; i++) {
            if (totalSubtotal[i].id) {
                response[totalSubtotal[i].id] = totalSubtotal[i].sum ? totalSubtotal[i].sum : 0;
            }
        }
    }
    return response;
}


function getQuestion(id, sections = []) {
    for (let i = 0; i < sections.length; i++) {
        if (sections[i].questions) {
            for (let j = 0; j < sections[i].questions?.length; j++) {
                if (sections[i].questions[j].id == id) {
                    return sections[i].questions[j];
                }
            }
        }
    }


    return {};
}
function checkQuestionAnswer(questionId, sections = [], answers) {
    let question = getQuestion(questionId, sections);
    if (!question) {
        return false;
    }

    if (question.type == 1) {
        if (answers[question.id]) {
            return true;
        }
    } else if (question.type == 2) {
        if (answers[question.id] && answers[question.id].length) {
            return true;
        }

    } else if (question.type == 3) {
        if (answers[question.id]) {
            return true;
        }

    } else if (question.type == 5) {
        if (answers[question.id]) {
            return true;
        }

    } else if (question.type == 6) {
        if (answers[question.id]) {
            return true;
        }

    } else if (question.type == 7) {
        if (answers[question.id] && answers[question.id][0] && answers[question.id][1] && answers[question.id][2] && answers[question.id][3]) {
            return true;
        }
    } else if (question.type == 8) {
        if (answers[question.id]) {
            return true;
        }
    } else if (question.type == 9) {
        if (answers[question.id]) {
            return true;
        }
    } else if (question.type == 10) {
        return true;
    } else if (question.type == 15) {
        return true;

    } else if (question.type == 12) {
        if (answers[question.id]) {
            return true;
        }

    } else if (question.type == 13) {
        if (answers[question.id]) {
            return true;
        }
    } else if (question.type == 14) {
        if (answers[question.id]) {
            return true;
        }

    } else if (question.type == 16 || question.type == 17) {
        if (answers[question.id]) {
            return true;
        }
    }
    return false;
}
function checkSectionAnswers(sectionIdx, sections = [], answers) {
    let lastQuestionType = -1;
    let i = sectionIdx;
    for (let j = 0; j < sections[i].questions?.length; j++) {
        if (checkQuestionCondition(sections[i].questions[j].id) && (sections[i].questions[j].type == 1 || sections[i].questions[j].type == 2 || sections[i].questions[j].type == 3 || sections[i].questions[j].type == 5 || sections[i].questions[j].type == 6 || sections[i].questions[j].type == 7 || sections[i].questions[j].type == 8 || sections[i].questions[j].type == 9 || sections[i].questions[j].type == 10 || sections[i].questions[j].type == 12 || sections[i].questions[j].type == 13 || sections[i].questions[j].type == 14 || sections[i].questions[j].type == 15, sections, answers)) {
            lastQuestionType = sections[i].questions[j].type;

            if (sections[i].questions[j].type == 10) {
                continue;
            }
            // if (sections[i].questions[j].type == 15) {
            //     continue;
            // }
            if (!answers[sections[i].questions[j].id] /*&& !this.state.skippedQuestions[sections[i].questions[j].id]*/ && sections[i].questions[j].type != 1) {
                return false;
            }
        }

        if (checkQuestionCondition(sections[i].questions[j].id) && (sections[i].questions[j].type == 4, sections, answers)) {
            lastQuestionType = sections[i].questions[j].type;
        }
    }
    return lastQuestionType != 4 ? true : false;
}
function checkQuestionCondition(questionId, sections = [], answers) {
    let question = getQuestion(questionId, sections);
    if (!question) {
        return;
    }

    if (!question.conditions) {
        return true;
    }

    if (!question.conditions.length) {
        return true;
    }



    for (let i = 0; i < question.conditions.length; i++) {
        if (!question.conditions[i].conditionQuestion) {
            continue;
        }

        let conditionQuestion = getQuestion(question.conditions[i].conditionQuestion, sections);
        if (!conditionQuestion) {
            continue;
        }

        if (conditionQuestion.type == 1) {
            if (answers[conditionQuestion.id] == question.conditions[i].conditionAnswer) {
                return true;
            }
        } else if (conditionQuestion.type == 2) {

            if (answers[conditionQuestion.id] && (answers[conditionQuestion.id].indexOf(question.conditions[i].conditionAnswer) !== -1 || answers[conditionQuestion.id].filter(ans => ans.match(question.conditions[i].conditionAnswer)).length)) {
                return true;
            }

        } else if (conditionQuestion.type == 6) {
            if (answers[conditionQuestion.id] && answers[conditionQuestion.id][0] && answers[conditionQuestion.id][1]) {
                return true;
            }


        } else if (conditionQuestion.type == 7) {
            if (answers[conditionQuestion.id] && answers[conditionQuestion.id][0] && answers[conditionQuestion.id][1] && answers[conditionQuestion.id][2] && answers[conditionQuestion.id][3]) {
                return true;
            }


        } else if (conditionQuestion.type == 3 || conditionQuestion.type == 5 || conditionQuestion.type == 8 || conditionQuestion.type == 9) {
            if (answers[conditionQuestion.id]) {
                return true;
            }

        } else if (conditionQuestion.type == 10) {
            return true;
        } else if (conditionQuestion.type == 15) {
            return true;
        } else if (conditionQuestion.type == 12) {
            if (answers[conditionQuestion.id] && answers[conditionQuestion.id][0] && answers[conditionQuestion.id][1] && answers[conditionQuestion.id][2]) {
                return true;
            }


        } else if (conditionQuestion.type == 13) {
            if (answers[conditionQuestion.id] && answers[conditionQuestion.id][0]) {
                return true;
            }
        } else if (conditionQuestion.type == 14) {
            if (answers[conditionQuestion.id]) {
                return true;
            }

        } else if (conditionQuestion.type == 16 || conditionQuestion.type == 17) {
            if (answers[conditionQuestion.id] == question.conditions[i].conditionAnswer) {
                return true;
            }
        }

    }
    return false;
}
function indexOfIndexOf(arr, str) {
    for (let i = 0; i < arr.length; i++) {
        let idx = arr[i].indexOf(str);
        if (idx !== -1) {
            return i;
        }

    }

    return -1;
}

function checkSectionCondition(sectionIdx, sections = [], answers) {
    let section = sections[sectionIdx];

    if (!section) {
        return;
    }
    if (sectionIdx > 0) {
        let prevSection = sections[sectionIdx - 1]
        let questions = prevSection.questions;
        if (questions && questions.length) {
            let notAllAnswered = false;
            for (let i = 0; i < questions.length; i++) {
                let check = false;
                if (questions[i] && questions[i].conditions.length == 0) {
                    check = true;
                } else if (questions[i] && questions[i].conditions.length > 0 && checkQuestionCondition(questions[i].id, sections, answers)) {
                    let check2 = true;
                    for (let j = 0; j < questions[i].conditions.length; j++) {
                        if (checkQuestionAnswer(questions[i].conditions[j].conditionQuestion, sections, answers) && checkQuestionCondition(questions[i].id, sections, answers)) {
                            check2 = false;
                        }
                    }
                    if (check2) {
                        check = true;
                    }
                }


                if (!checkQuestionAnswer(questions[i].id, sections, answers) && check) {
                    notAllAnswered = true;
                }

            }
            if (notAllAnswered) {
                return false;
            }
        }
    }








    if (!section.conditions) {
        if (sectionIdx == 0) {
            return true;
        } else {
            if (checkSectionAnswers(sectionIdx - 1, sections, answers)) {
                return true;
            } else {

                return false;

            }
        }

        return true;
    }
    if (!section.conditions.length) {
        if (sectionIdx == 0) {
            return true;
        } else {
            if (checkSectionAnswers(sectionIdx - 1, sections, answers)) {
                return true;
            } else {

                return false;
            }
        }
    }

    for (let i = 0; i < section.conditions.length; i++) {
        if (!section.conditions[i].conditionQuestion) {
            continue;
        }

        let question = getQuestion(section.conditions[i].conditionQuestion, sections);
        if (!question) {
            continue;
        }

        if (question.type == 1) {
            if (answers[question.id] == section.conditions[i].conditionAnswer) {
                return true;
            }
        } else if (question.type == 2) {
            if (answers[question.id] && answers[question.id].indexOf(section.conditions[i].conditionAnswer) !== -1) {

                return true;
            }

        } else if (question.type == 3) {
            if (answers[question.id]) {
                return true;
            }

        } else if (question.type == 5) {
            if (answers[question.id]) {
                return true;
            }

        } else if (question.type == 6) {
            if (answers[question.id]) {
                return true;
            }

        } else if (question.type == 7) {
            if (answers[question.id]) {
                return true;
            }
        } else if (question.type == 8) {
            if (answers[question.id]) {
                return true;
            }
        } else if (question.type == 9) {
            if (answers[question.id]) {
                return true;
            }

        } else if (question.type == 10) {
            return true;
        } else if (question.type == 15) {
            return true;
        } else if (question.type == 12) {
            if (answers[question.id]) {
                return true;
            }
        } else if (question.type == 14) {
            if (answers[question.id]) {
                return true;
            }
        } else if (question.type == 13) {
            if (answers[question.id]) {
                return true;
            }

        } else if (question.type == 16) {
            if (answers[question.id] == section.conditions[i].conditionAnswer) {
                return true;
            }
        } if (question.type == 17) {
            if (answers[question.id] == section.conditions[i].conditionAnswer) {
                return true;
            }
        }
    }
    return false;
}
function getAnswer(id, sections = [], answers) {

    for (let i = 0; i < sections.length; i++) {
        if (sections[i].questions) {
            for (let j = 0; j < sections[i].questions?.length; j++) {
                if (sections[i].questions[j].answers) {
                    for (let k = 0; k < sections[i].questions[j].answers.length; k++) {
                        if (sections[i].questions[j].answers[k].id == id) {
                            return sections[i].questions[j].answers[k];
                        }
                    }
                }
                if (sections[i].questions[j].blocks) {
                    for (let k = 0; k < sections[i].questions[j].blocks.length; k++) {
                        if (sections[i].questions[j].blocks[k].id == id) {
                            return sections[i].questions[j].blocks[k];
                        }
                    }
                }
            }
        }
    }


    return {};
}


export const getReferralDataRevisit = (referral, lang, doctor = true) => {
    let onlyTotals = false;
    let referralData = ``;
    if (referral) {
        // referralData += `<p><strong>${'Patient svar'.translate(lang)}</strong></p>\n\n`
        let sections = [];
        let answers = {};
        if (referral.questionarySections) {
            sections = referral.questionarySections;
        }
        if (referral.answers) {
            answers = referral.answers;
        }
        let totalSubtotal = checkPoints(sections, answers)

        if (!referral.intelligentReferral) {
            for (let i = 0; i < sections.length; i++) {
                if (checkSectionCondition(i, sections, answers)) {
                    if (sections[i].displayOnReferral) {
                        if (i > 0) {
                            referralData += '<br/><br/>'
                        }
                        referralData += `<p><strong>${sections[i].name}</strong></p>\n`;
                    }
                    referralData += '<p>\n';
                    for (let j = 0; j < sections[i].questions?.length; j++) {

                        if (checkQuestionCondition(sections[i].questions[j].id) && (sections[i].questions[j].type == 10 || answers[sections[i].questions[j].id], sections, answers)) {
                            if (sections[i].questions[j].displayOnReferral || true) {
                                if (sections[i].questions[j].type == 1) {
                                    if (getAnswer(answers[sections[i].questions[j].id]).name, sections, answers)
                                        referralData += sections[i].questions[j].name + ' - <strong>' + getAnswer(answers[sections[i].questions[j].id], sections, answers).name + '</strong> <br/>';
                                } else if (sections[i].questions[j].type == 2) {
                                    if (answers[sections[i].questions[j].id]) {

                                        let arr = [];

                                        for (let k = 0; k < sections[i].questions[j].answers.length; k++) {
                                            let check = -1;
                                            for (let l = 0; l < answers[sections[i].questions[j].id].length; l++) {
                                                if (sections[i].questions[j].answers[k] && answers[sections[i].questions[j].id][l] == sections[i].questions[j].answers[k].id) {
                                                    check = l;
                                                    break;
                                                }
                                            }
                                            if (check != -1) {

                                                if (getAnswer(answers[sections[i].questions[j].id][check], sections, answers).name)
                                                    arr.push(getAnswer(answers[sections[i].questions[j].id][check], sections, answers).name);

                                            }
                                        }

                                        if (sections[i].questions[j].userInput && indexOfIndexOf(answers[sections[i].questions[j].id], '__user-input:') !== -1) {
                                            arr.push(answers[sections[i].questions[j].id][indexOfIndexOf(answers[sections[i].questions[j].id], '__user-input:')].replace('__user-input:', ''))
                                        }


                                        //if (arr.length && sections[i].questions[j].answerPrefix) {
                                        if (arr.length > 1) {
                                            let last = arr.pop();
                                            referralData += sections[i].questions[j].name + ' - <strong>' + arr.join(', ') + ` ${'och'.translate(lang)} ` + last + '. </strong><br/>';
                                        } else {
                                            referralData += sections[i].questions[j].name + ' - <strong>' + arr.join(', ') + '.</strong><br/> ';
                                        }
                                        //}
                                    }
                                } else if (sections[i].questions[j].type == 3) {
                                    referralData += sections[i].questions[j].name + ' - <strong>' + (answers[sections[i].questions[j].id] ? answers[sections[i].questions[j].id] : '') + '.</strong><br/> ';
                                } else if (sections[i].questions[j].type == 5) {
                                    //referralData += answers[sections[i].questions[j].id] + '. ';
                                    let newArr = answers[sections[i].questions[j].id] ? JSON.parse(JSON.stringify(answers[sections[i].questions[j].id])) : [];

                                    let arr = [];
                                    for (let k = 0; k < newArr.length; k++) {
                                        if (newArr[k]) {
                                            arr.push(newArr[k]);
                                        }
                                    }
                                    if (arr.length > 1) {
                                        let last = arr.pop();
                                        referralData += arr.join(', ') + ` ${'och'.translate(lang)} ` + last + '. ';
                                    } else {
                                        referralData += arr.join(', ') + '. ';
                                    }

                                } else if (sections[i].questions[j].type == 6) {
                                    if (answers[sections[i].questions[j].id])
                                        referralData += `${'Patientens längd'.translate(lang)}: ` + answers[sections[i].questions[j].id][1] + ', ' + `${'vikt'.translate(lang)}: ` + answers[sections[i].questions[j].id][0] + ', ' + `${'BMI'.translate(lang)}: ` + parseFloat(answers[sections[i].questions[j].id] && answers[sections[i].questions[j].id].length ? (answers[sections[i].questions[j].id][0] / ((answers[sections[i].questions[j].id][1] / 100) * (answers[sections[i].questions[j].id][1] / 100))) : 0).toFixed(1) + '. ';
                                } else if (sections[i].questions[j].type == 7) {
                                    referralData += `<br/><br/>
                                ${'Frikortsnummer'.translate(lang)}: ${answers[sections[i].questions[j].id] ? answers[sections[i].questions[j].id][0] : ''}<br/>
                                ${'Giltig från'.translate(lang)}: ${answers[sections[i].questions[j].id] ? answers[sections[i].questions[j].id][1] : ''}<br/>
                                ${'Giltig till'.translate(lang)}: ${answers[sections[i].questions[j].id] ? answers[sections[i].questions[j].id][2] : ''}<br/>
                                ${'Utfärdad av'.translate(lang)}: ${answers[sections[i].questions[j].id] ? answers[sections[i].questions[j].id][3] : ''}<br/><br/>
                                `
                                } else if (sections[i].questions[j].type == 8) {
                                    referralData += `<a href='${API_ENDPOINT + answers[sections[i].questions[j].id]}' target="_blank"> ${sections[i].questions[j].name}</a><br/>` + ' ';
                                } else if (sections[i].questions[j].type == 9) {
                                    if (answers[sections[i].questions[j].id] && answers[sections[i].questions[j].id].length) {
                                        for (let f = 0; f < answers[sections[i].questions[j].id].length; f++) {
                                            if (answers[sections[i].questions[j].id][f] && answers[sections[i].questions[j].id][f].file)
                                                referralData += `<a href='${API_ENDPOINT + answers[sections[i].questions[j].id][f].file}' target="_blank">${'File/photo'.translate(lang)}</a><br/>` + ' ';
                                        }
                                    }
                                    // referralData += `<a href='${API_ENDPOINT + answers[sections[i].questions[j].id].file}' target="_blank">${'File/photo'.translate(lang)}</a><br/>` + ' ';
                                } else if (sections[i].questions[j].type == 10) {
                                    if (sections[i].questions[j].userInput) {
                                        referralData += "<br/><br/>"
                                    } else {
                                        referralData += "<br/>"
                                    }

                                } else if (sections[i].questions[j].type == 12) {
                                    referralData += `${'Patientens blodtryck uppmätt till'.translate(lang)}: <br/>`;
                                    referralData += answers[sections[i].questions[j].id] && answers[sections[i].questions[j].id][0] ? answers[sections[i].questions[j].id][0] + '/' : '';
                                    referralData += answers[sections[i].questions[j].id] && answers[sections[i].questions[j].id][1] ? answers[sections[i].questions[j].id][1] + '<br/>' : '';
                                    referralData += answers[sections[i].questions[j].id] && answers[sections[i].questions[j].id][2] ? 'Puls: ' + answers[sections[i].questions[j].id][2] + '<br/>' : '';
                                } else if (sections[i].questions[j].type == 13) {
                                    referralData += `${'Patientens VAS-skalvärde är'.translate(lang)}: `;
                                    referralData += answers[sections[i].questions[j].id] && answers[sections[i].questions[j].id][0] ? answers[sections[i].questions[j].id][0] + '<br/>' : '';
                                } else if (sections[i].questions[j].type == 14) {
                                    referralData += `${'Aktuell andningsfrekvens/minut'.translate(lang)}:`;
                                    referralData += answers[sections[i].questions[j].id] ? answers[sections[i].questions[j].id] + '<br/>' : '';
                                } else if (sections[i].questions[j].type == 16) {
                                    if (getAnswer(answers[sections[i].questions[j].id], sections, answers).response) {
                                        if (doctor) {
                                            referralData += '\n<span><strong>' + sections[i].questions[j].name + ' ' + getAnswer(answers[sections[i].questions[j].id], sections, answers).response + ` - ${(totalSubtotal[sections[i].questions[j].id] ? totalSubtotal[sections[i].questions[j].id] : 0)} ` + '</strong></span><br/>';
                                        } else {
                                            referralData += '<br/>';
                                        }
                                    }
                                } else if (sections[i].questions[j].type == 17) {
                                    if (getAnswer(answers[sections[i].questions[j].id], sections, answers).response) {
                                        if (doctor) {
                                            referralData += '\n<span><strong>' + sections[i].questions[j].name + ' ' + getAnswer(answers[sections[i].questions[j].id], sections, answers).response + ` - ${(totalSubtotal[sections[i].questions[j].id] ? totalSubtotal[sections[i].questions[j].id] : 0)} ` + '</strong></span><br/>';
                                        } else {
                                            referralData += '<br/>';
                                        }
                                    }
                                }
                            }
                        }
                    }

                    referralData += '</p>\n';
                }
            }
        } else {
            let empty = true;
            for (let i = 0; i < sections.length; i++) {

                if (checkSectionCondition(i, sections, answers)) {
                    if (sections[i].displayOnReferral) {
                        if (i > 0 && !empty) {
                            referralData += '<br/><br/>'
                        }
                        referralData += `<p><strong>${sections[i].name}</strong></p>\n`;
                        if (empty) empty = false;
                    }
                    // referralData += '<p>\n';
                    for (let j = 0; j < sections[i].questions?.length; j++) {

                        if (checkQuestionCondition(sections[i].questions[j].id) && (sections[i].questions[j].type == 10 || answers[sections[i].questions[j].id], sections, answers)) {

                            if (sections[i].questions[j].displayOnReferral || true) {

                                if (sections[i].questions[j].type == 1 && !onlyTotals) {
                                    if (getAnswer(answers[sections[i].questions[j].id], sections, answers).text) {
                                        referralData += getAnswer(answers[sections[i].questions[j].id], sections, answers).text + ' ';
                                        if (empty) empty = false;
                                    }
                                } else if (sections[i].questions[j].type == 2 && !onlyTotals) {
                                    if (answers[sections[i].questions[j].id]) {

                                        let arr = [];

                                        //for (let k = 0; k < answers[sections[i].questions[j].id].length; k++) {
                                        for (let k = 0; k < sections[i].questions[j].answers.length; k++) {
                                            let check = -1;
                                            for (let l = 0; l < answers[sections[i].questions[j].id].length; l++) {
                                                // console.log(answers[sections[i].questions[j].id][l], sections[i].questions[j].answers[k].id)
                                                if (sections[i].questions[j].answers[k] && answers[sections[i].questions[j].id][l] == sections[i].questions[j].answers[k].id) {
                                                    check = l;
                                                    break;
                                                }
                                            }
                                            // console.log(check)
                                            if (check != -1) {
                                                if (sections[i].questions[j].answerPrefix) {
                                                    /*if (answers[sections[i].questions[j].id][k].indexOf('__user-input:') == 0){
                                                        arr.push(answers[sections[i].questions[j].id][k].replace('__user-input:', ''));
                                                    }else{*/
                                                    if (getAnswer(answers[sections[i].questions[j].id][check], sections, answers).text)
                                                        arr.push(getAnswer(answers[sections[i].questions[j].id][check], sections, answers).text);
                                                    // }
                                                } else {
                                                    if (getAnswer(answers[sections[i].questions[j].id][check], sections, answers).text) {
                                                        referralData += getAnswer(answers[sections[i].questions[j].id][check], sections, answers).text + ' ';
                                                        if (empty) empty = false;
                                                    }
                                                }
                                            }
                                        }

                                        if (sections[i].questions[j].userInput && indexOfIndexOf(answers[sections[i].questions[j].id], '__user-input:') !== -1) {
                                            arr.push(answers[sections[i].questions[j].id][indexOfIndexOf(answers[sections[i].questions[j].id], '__user-input:')].replace('__user-input:', ''))
                                        }

                                        // console.log(arr);

                                        if (arr.length && sections[i].questions[j].answerPrefix) {
                                            if (arr.length > 1) {
                                                let last = arr.pop();
                                                referralData += sections[i].questions[j].answerPrefix + ' ' + arr.join(', ') + ` ${'och'.translate(lang)} ` + last + '. ';
                                            } else {
                                                referralData += sections[i].questions[j].answerPrefix + ' ' + arr.join(', ') + '. ';
                                            }
                                            if (empty) empty = false;
                                        }
                                    }
                                } else if (sections[i].questions[j].type == 3 && !onlyTotals && answers[sections[i].questions[j].id]) {
                                    // referralData += answers[sections[i].questions[j].id] + '. ';
                                    referralData += sections[i].questions[j].answerPrefix ? `${sections[i].questions[j].answerPrefix} ${answers[sections[i].questions[j].id]}. ` : `${answers[sections[i].questions[j].id]}. `;
                                    if (empty) empty = false;
                                } else if (sections[i].questions[j].type == 5 && !onlyTotals) {
                                    //referralData += answers[sections[i].questions[j].id] + '. ';
                                    let newArr = answers[sections[i].questions[j].id] ? JSON.parse(JSON.stringify(answers[sections[i].questions[j].id])) : [];
                                    let arr = [];
                                    for (let k = 0; k < newArr.length; k++) {
                                        if (newArr[k]) {
                                            arr.push(newArr[k]);
                                        }
                                    }

                                    if (arr.length > 1) {
                                        let last = arr.pop();
                                        referralData += arr.join(', ') + ` ${'och'.translate(lang)} ` + last + '. ';
                                    } else {
                                        referralData += arr.join(', ') + '. ';
                                    }
                                    if (empty) empty = false;

                                } else if (sections[i].questions[j].type == 6 && !onlyTotals) {
                                    referralData += `${'Patientens längd'.translate(lang)}: ` + answers[sections[i].questions[j].id][1] + ', ' + `${'vikt'.translate(lang)}: ` + answers[sections[i].questions[j].id][0] + ', ' + `${'BMI'.translate(lang)}: ` + parseFloat(answers[sections[i].questions[j].id] && answers[sections[i].questions[j].id].length ? (answers[sections[i].questions[j].id][0] / ((answers[sections[i].questions[j].id][1] / 100) * (answers[sections[i].questions[j].id][1] / 100))) : 0).toFixed(1) + '. ';
                                    if (empty) empty = false;
                                } else if (sections[i].questions[j].type == 9 && !onlyTotals) {
                                    if (answers[sections[i].questions[j].id] && answers[sections[i].questions[j].id].length) {
                                        for (let f = 0; f < answers[sections[i].questions[j].id].length; f++) {
                                            if (answers[sections[i].questions[j].id][f] && answers[sections[i].questions[j].id][f].file) {
                                                referralData += `<a href='${API_ENDPOINT + answers[sections[i].questions[j].id][f].file}' target="_blank">${'File/photo'.translate(lang)}</a><br/>` + ' ';
                                                if (empty) empty = false;
                                            }
                                        }
                                    }
                                    // referralData += `<a href='${answers[sections[i].questions[j].id] ? API_ENDPOINT + answers[sections[i].questions[j].id].file : ''}' target="_blank">${'File/photo'.translate(lang)}</a><br/>` + ' ';
                                } else if (sections[i].questions[j].type == 10 && !onlyTotals) {
                                    if (sections[i].questions[j].userInput) {
                                        referralData += "<br/><br/><br/><br/>"
                                    } else {
                                        referralData += "<br/><br/>"
                                    }
                                    if (empty) empty = false;

                                } else if (sections[i].questions[j].type == 12 && !onlyTotals) {
                                    referralData += `${'Patientens blodtryck uppmätt till'.translate(lang)}: <br/>`;
                                    referralData += answers[sections[i].questions[j].id] && answers[sections[i].questions[j].id][0] ? answers[sections[i].questions[j].id][0] + '/' : ''
                                    referralData += answers[sections[i].questions[j].id] && answers[sections[i].questions[j].id][1] ? answers[sections[i].questions[j].id][1] + '<br/>' : ''
                                    referralData += answers[sections[i].questions[j].id] && answers[sections[i].questions[j].id][2] ? 'Puls: ' + answers[sections[i].questions[j].id][2] + '<br/>' : ''
                                    if (empty) empty = false;
                                } else if (sections[i].questions[j].type == 13 && !onlyTotals) {
                                    referralData += `${'Patientens VAS-skalvärde är'.translate(lang)}: `;
                                    referralData += answers[sections[i].questions[j].id] && answers[sections[i].questions[j].id][0] ? answers[sections[i].questions[j].id][0] + '<br/>' : ''
                                    if (empty) empty = false;
                                } else if (sections[i].questions[j].type == 14 && !onlyTotals) {
                                    referralData += `${'Aktuell andningsfrekvens/minut'.translate(lang)}:`;
                                    referralData += answers[sections[i].questions[j].id] ? answers[sections[i].questions[j].id] + '<br/>' : ''
                                    if (empty) empty = false;
                                } else if (sections[i].questions[j].type == 16 && !onlyTotals) {
                                    if (getAnswer(answers[sections[i].questions[j].id], sections, answers).response) {
                                        // let value = this.state.totalSubtotal && this.state.totalSubtotal.filter(el => el.id == sections[i].questions[j].id).length && this.state.totalSubtotal.filter(el => el.id == sections[i].questions[j].id)[0].sum;
                                        if (doctor) {
                                            referralData += '<br/><span><strong>' + sections[i].questions[j].name + ' ' + getAnswer(answers[sections[i].questions[j].id], sections, answers).response + ` - ${(totalSubtotal[sections[i].questions[j].id] ? totalSubtotal[sections[i].questions[j].id] : 0)} ` + '</strong></span><br/>';
                                        } else {
                                            referralData += '<br/>';
                                        }
                                        if (empty) empty = false;
                                    }

                                } else if (sections[i].questions[j].type == 17 && !onlyTotals) {
                                    if (getAnswer(answers[sections[i].questions[j].id], sections, answers).response) {
                                        // let value = this.state.totalSubtotal && this.state.totalSubtotal.filter(el => el.id == sections[i].questions[j].id).length && this.state.totalSubtotal.filter(el => el.id == sections[i].questions[j].id)[0].sum;
                                        if (doctor) {
                                            referralData += '<br/><span><strong>' + sections[i].questions[j].name + ' ' + getAnswer(answers[sections[i].questions[j].id], sections, answers).response + ` - ${(totalSubtotal[sections[i].questions[j].id] ? totalSubtotal[sections[i].questions[j].id] : 0)} ` + '</strong></span><br/>';
                                        } else {
                                            referralData += '<br/>';
                                        }
                                        if (empty) empty = false;
                                    }

                                }
                            }
                        }


                    }

                    referralData += '</p>\n';
                }
            }
        }


    }
    return referralData;

}



export const getReferralData = (referral, lang, doctor = true) => {
    let referralData = ``;
    if (referral) {
        referralData += `<p><strong>${'Patient svar'.translate(lang)}</strong></p>\n\n`
        let sections = [];
        let answers = {};
        let question18Finished = {};
        if (referral.questionarySections) {
            sections = referral.questionarySections;
        }
        if (referral.answers) {
            answers = referral.answers;
        }
        if (referral.question18Finished) {
            question18Finished = referral.question18Finished;
        }

        let totalSubtotal = checkPoints(sections, answers)

        if (!referral.intelligentReferral) {
            for (let i = 0; i < sections.length; i++) {
                if (checkSectionCondition(i, sections, answers)) {
                    if (sections[i].displayOnReferral) {
                        if (i > 0) {
                            referralData += '<br/><br/>'
                        }
                        referralData += `<p><strong>${sections[i].name}</strong></p>\n`;
                    }
                    referralData += '<p>\n';
                    for (let j = 0; j < sections[i].questions?.length; j++) {


                        if (checkQuestionCondition(sections[i].questions[j].id) && (sections[i].questions[j].type == 10 || answers[sections[i].questions[j].id], sections, answers)) {
                            if (sections[i].questions[j].displayOnReferral || true) {
                                if (sections[i].questions[j].type == 1) {
                                    if (getAnswer(answers[sections[i].questions[j].id], sections, answers).name)
                                        referralData += sections[i].questions[j].name + ' - <strong>' + getAnswer(answers[sections[i].questions[j].id], sections, answers).name + '</strong> <br/>';
                                } else if (sections[i].questions[j].type == 2) {
                                    if (answers[sections[i].questions[j].id]) {

                                        let arr = [];

                                        for (let k = 0; k < sections[i].questions[j].answers.length; k++) {
                                            let check = -1;
                                            for (let l = 0; l < answers[sections[i].questions[j].id].length; l++) {
                                                if (sections[i].questions[j].answers[k] && answers[sections[i].questions[j].id][l] == sections[i].questions[j].answers[k].id) {
                                                    check = l;
                                                    break;
                                                }
                                            }
                                            if (check != -1) {

                                                if (getAnswer(answers[sections[i].questions[j].id][check], sections, answers).name)
                                                    arr.push(getAnswer(answers[sections[i].questions[j].id][check], sections, answers).name);

                                            }
                                        }

                                        if (sections[i].questions[j].userInput && indexOfIndexOf(answers[sections[i].questions[j].id], '__user-input:') !== -1) {
                                            arr.push(answers[sections[i].questions[j].id][indexOfIndexOf(answers[sections[i].questions[j].id], '__user-input:')].replace('__user-input:', ''))
                                        }


                                        if (arr.length > 1) {
                                            let last = arr.pop();
                                            referralData += sections[i].questions[j].name + ' - <strong>' + arr.join(', ') + ` ${'och'.translate(lang)} ` + last + '. </strong><br/>';
                                        } else {
                                            referralData += sections[i].questions[j].name + ' - <strong>' + arr.join(', ') + '.</strong><br/> ';
                                        }
                                    }
                                } else if (sections[i].questions[j].type == 3) {
                                    referralData += sections[i].questions[j].name + ' - <strong>' + (answers[sections[i].questions[j].id] ? answers[sections[i].questions[j].id] : '') + '.</strong><br/> ';
                                } else if (sections[i].questions[j].type == 5) {
                                    let newArr = answers[sections[i].questions[j].id] ? JSON.parse(JSON.stringify(answers[sections[i].questions[j].id])) : [];
                                    let arr = [];
                                    for (let k = 0; k < newArr.length; k++) {
                                        if (newArr[k]) {
                                            arr.push(newArr[k]);
                                        }
                                    }
                                    if (arr.length > 1) {
                                        let last = arr.pop();
                                        referralData += arr.join(', ') + ` ${'och'.translate(lang)} ` + last + '. ';
                                    } else {
                                        referralData += arr.join(', ') + '. ';
                                    }

                                } else if (sections[i].questions[j].type == 6) {
                                    if (answers[sections[i].questions[j].id])
                                        referralData += `${'Patientens längd'.translate(lang)}: ` + answers[sections[i].questions[j].id][1] + ', ' + `${'vikt'.translate(lang)}: ` + answers[sections[i].questions[j].id][0] + ', ' + `${'BMI'.translate(lang)}: ` + parseFloat(answers[sections[i].questions[j].id] && answers[sections[i].questions[j].id].length ? (answers[sections[i].questions[j].id][0] / ((answers[sections[i].questions[j].id][1] / 100) * (answers[sections[i].questions[j].id][1] / 100))) : 0).toFixed(1) + '. ';
                                } else if (sections[i].questions[j].type == 7) {
                                    referralData += `<br/><br/>
                            ${'Frikortsnummer'.translate(lang)}: ${answers[sections[i].questions[j].id] ? answers[sections[i].questions[j].id][0] : ''}<br/>
                            ${'Giltig från'.translate(lang)}: ${answers[sections[i].questions[j].id] ? answers[sections[i].questions[j].id][1] : ''}<br/>
                            ${'Giltig till'.translate(lang)}: ${answers[sections[i].questions[j].id] ? answers[sections[i].questions[j].id][2] : ''}<br/>
                            ${'Utfärdad av'.translate(lang)}: ${answers[sections[i].questions[j].id] ? answers[sections[i].questions[j].id][3] : ''}<br/><br/>
                            `
                                } else if (sections[i].questions[j].type == 8) {
                                    referralData += `<a href='${API_ENDPOINT + answers[sections[i].questions[j].id]}' target="_blank"> ${sections[i].questions[j].name}</a><br/>` + ' ';
                                } else if (sections[i].questions[j].type == 9) {
                                    if (answers[sections[i].questions[j].id] && answers[sections[i].questions[j].id].length) {
                                        for (let f = 0; f < answers[sections[i].questions[j].id].length; f++) {
                                            if (answers[sections[i].questions[j].id][f] && answers[sections[i].questions[j].id][f].file)
                                                referralData += `<a href='${API_ENDPOINT + answers[sections[i].questions[j].id][f].file}' target="_blank">${'File/photo'.translate(lang)}</a><br/>` + ' ';
                                        }
                                    }
                                } else if (sections[i].questions[j].type == 10) {
                                    if (sections[i].questions[j].userInput) {
                                        referralData += "<br/><br/>"
                                    } else {
                                        referralData += "<br/>"
                                    }

                                } else if (sections[i].questions[j].type == 12) {
                                    referralData += `${'Patientens blodtryck uppmätt till'.translate(lang)}: <br/>`;
                                    referralData += answers[sections[i].questions[j].id] && answers[sections[i].questions[j].id][0] ? answers[sections[i].questions[j].id][0] + '/' : '';
                                    referralData += answers[sections[i].questions[j].id] && answers[sections[i].questions[j].id][1] ? answers[sections[i].questions[j].id][1] + '<br/>' : '';
                                    referralData += answers[sections[i].questions[j].id] && answers[sections[i].questions[j].id][2] ? 'Puls: ' + answers[sections[i].questions[j].id][2] + '<br/>' : '';
                                } else if (sections[i].questions[j].type == 13) {
                                    referralData += `${'Patientens VAS-skalvärde är'.translate(lang)}: `;
                                    referralData += answers[sections[i].questions[j].id] && answers[sections[i].questions[j].id][0] ? answers[sections[i].questions[j].id][0] + '<br/>' : '';
                                } else if (sections[i].questions[j].type == 14) {
                                    referralData += `${'Aktuell andningsfrekvens/minut'.translate(lang)}:`;
                                    referralData += answers[sections[i].questions[j].id] ? answers[sections[i].questions[j].id] + '<br/>' : '';
                                } else if (sections[i].questions[j].type == 16) {
                                    if (getAnswer(answers[sections[i].questions[j].id], sections, answers).response) {
                                        if (doctor) {
                                            referralData += '\n<span><strong>' + sections[i].questions[j].name + ' ' + getAnswer(answers[sections[i].questions[j].id], sections, answers).response + ` - ${(totalSubtotal[sections[i].questions[j].id] ? totalSubtotal[sections[i].questions[j].id] : 0)} ` + '</strong></span><br/>';
                                        } else {
                                            referralData += '<br/>';
                                        }
                                    }
                                } else if (sections[i].questions[j].type == 17) {
                                    if (getAnswer(answers[sections[i].questions[j].id], sections, answers).response) {
                                        if (doctor) {
                                            referralData += '\n<span><strong>' + sections[i].questions[j].name + ' ' + getAnswer(answers[sections[i].questions[j].id], sections, answers).response + ` - ${(totalSubtotal[sections[i].questions[j].id] ? totalSubtotal[sections[i].questions[j].id] : 0)} ` + '</strong></span><br/>';
                                        } else {
                                            referralData += '<br/>';
                                        }
                                    }
                                }

                                // else if (sections[i].questions[j].type == 16) {
                                //     if (getAnswer(answers[sections[i].questions[j].id], sections, answers).ressponse)
                                //         referralData += '\n<span><strong>' + sections[i].questions[j].name + ' ' + getAnswer(answers[sections[i].questions[j].id], sections, answers).response + ' ' + '</strong></span><br/>';
                                // } else if (sections[i].questions[j].type == 17) {
                                //     if (getAnswer(answers[sections[i].questions[j].id], sections, answers).response)
                                //         referralData += '\n<span><strong>' + sections[i].questions[j].name + ' ' + getAnswer(answers[sections[i].questions[j].id], sections, answers).response + ' ' + '</strong></span><br/>';
                                // }
                            }
                        }
                    }

                    referralData += '</p>\n';
                }
            }
        } else {
            let empty = true;
            for (let i = 0; i < sections.length; i++) {
                if (checkSectionCondition(i, sections, answers)) {
                    if (sections[i].displayOnReferral) {
                        if (i > 0 && !empty) {
                            referralData += '<br/><br/>'
                        }
                        referralData += `<p><strong>${sections[i].name}</strong></p>\n`;
                        if (empty) empty = false;
                    }
                    referralData += '<p>\n';
                    for (let j = 0; j < sections[i].questions?.length; j++) {


                        if (checkQuestionCondition(sections[i].questions[j].id) && (sections[i].questions[j].type == 10 || answers[sections[i].questions[j].id], sections, answers)) {

                            if (sections[i].questions[j].displayOnReferral || ((sections[i].questions[j].type == 16 || sections[i].questions[j].type == 17) && doctor)) {
                                if (sections[i].questions[j].type == 1) {
                                    if (getAnswer(answers[sections[i].questions[j].id], sections, answers).text) {
                                        referralData += getAnswer(answers[sections[i].questions[j].id], sections, answers).text + ' ';
                                        if (empty) empty = false;
                                    }
                                } else if (sections[i].questions[j].type == 2) {
                                    if (answers[sections[i].questions[j].id]) {

                                        let arr = [];

                                        //for (let k = 0; k < answers[sections[i].questions[j].id].length; k++) {
                                        for (let k = 0; k < sections[i].questions[j].answers.length; k++) {
                                            let check = -1;
                                            for (let l = 0; l < answers[sections[i].questions[j].id].length; l++) {
                                                if (sections[i].questions[j].answers[k] && answers[sections[i].questions[j].id][l] == sections[i].questions[j].answers[k].id) {
                                                    check = l;
                                                    break;
                                                }
                                            }
                                            if (check != -1) {
                                                if (sections[i].questions[j].answerPrefix) {
                                                    /*if (answers[sections[i].questions[j].id][k].indexOf('__user-input:') == 0){
                                                        arr.push(answers[sections[i].questions[j].id][k].replace('__user-input:', ''));
                                                    }else{*/
                                                    if (getAnswer(answers[sections[i].questions[j].id][check], sections, answers).text)
                                                        arr.push(getAnswer(answers[sections[i].questions[j].id][check], sections, answers).text);
                                                    // }
                                                } else {
                                                    if (getAnswer(answers[sections[i].questions[j].id][check], sections, answers).text) {
                                                        referralData += getAnswer(answers[sections[i].questions[j].id][check], sections, answers).text + ' ';
                                                        if (empty) empty = false;
                                                    }
                                                }
                                            }
                                        }

                                        if (sections[i].questions[j].userInput && indexOfIndexOf(answers[sections[i].questions[j].id], '__user-input:') !== -1) {
                                            arr.push(answers[sections[i].questions[j].id][indexOfIndexOf(answers[sections[i].questions[j].id], '__user-input:')].replace('__user-input:', ''))
                                        }


                                        if (arr.length && sections[i].questions[j].answerPrefix) {
                                            if (arr.length > 1) {
                                                let last = arr.pop();
                                                referralData += sections[i].questions[j].answerPrefix + ' ' + arr.join(', ') + ` ${'och'.translate(lang)} ` + last + '. ';
                                            } else {
                                                referralData += sections[i].questions[j].answerPrefix + ' ' + arr.join(', ') + '. ';
                                            }
                                            if (empty) empty = false;
                                        }
                                    }
                                } else if (sections[i].questions[j].type == 3 && answers[sections[i].questions[j].id]) {
                                    // referralData += answers[sections[i].questions[j].id] + '. ';
                                    referralData += sections[i].questions[j].answerPrefix ? `${sections[i].questions[j].answerPrefix} ${answers[sections[i].questions[j].id]}. ` : `${answers[sections[i].questions[j].id]}. `;
                                    if (empty) empty = false;
                                } else if (sections[i].questions[j].type == 5) {
                                    let newArr = answers[sections[i].questions[j].id] ? JSON.parse(JSON.stringify(answers[sections[i].questions[j].id])) : [];
                                    let arr = [];
                                    for (let k = 0; k < newArr.length; k++) {
                                        if (newArr[k]) {
                                            arr.push(newArr[k]);
                                        }
                                    }
                                    if (arr.length > 1) {
                                        let last = arr.pop();
                                        referralData += arr.join(', ') + ` ${'och'.translate(lang)} ` + last + '. ';
                                    } else {
                                        referralData += arr.join(', ') + '. ';
                                    }
                                    if (empty) empty = false;

                                } else if (sections[i].questions[j].type == 6) {
                                    if (answers[sections[i].questions[j].id]) {
                                        referralData += `${'Patientens längd'.translate(lang)}: ` + answers[sections[i].questions[j].id][1] + ', ' + `${'vikt'.translate(lang)}: ` + answers[sections[i].questions[j].id][0] + ', ' + `${'BMI'.translate(lang)}: ` + parseFloat(answers[sections[i].questions[j].id] && answers[sections[i].questions[j].id].length ? (answers[sections[i].questions[j].id][0] / ((answers[sections[i].questions[j].id][1] / 100) * (answers[sections[i].questions[j].id][1] / 100))) : 0).toFixed(1) + '. ';
                                        if (empty) empty = false;
                                    }
                                } else if (sections[i].questions[j].type == 7) {
                                    referralData += `<br/><br/>
                                            ${'Frikortsnummer'.translate(lang)}: ${answers[sections[i].questions[j].id] ? answers[sections[i].questions[j].id][0] : ''}<br/>
                                        ${'Giltig från'.translate(lang)}: ${answers[sections[i].questions[j].id] ? answers[sections[i].questions[j].id][1] : ''}<br/>
                                        ${'Giltig till'.translate(lang)}: ${answers[sections[i].questions[j].id] ? answers[sections[i].questions[j].id][2] : ''}<br/>
                                        ${'Utfärdad av'.translate(lang)}: ${answers[sections[i].questions[j].id] ? answers[sections[i].questions[j].id][3] : ''}<br/><br/>
                                        `
                                    if (empty) empty = false;
                                } else if (sections[i].questions[j].type == 8) {
                                    referralData += `<a href='${API_ENDPOINT + answers[sections[i].questions[j].id]}' target="_blank"> ${sections[i].questions[j].name}</a><br/>` + ' ';
                                    if (empty) empty = false;
                                } else if (sections[i].questions[j].type == 9) {

                                    if (answers[sections[i].questions[j].id] && answers[sections[i].questions[j].id].length) {
                                        for (let f = 0; f < answers[sections[i].questions[j].id].length; f++) {
                                            if (answers[sections[i].questions[j].id][f] && answers[sections[i].questions[j].id][f].file) {
                                                referralData += `<a href='${API_ENDPOINT + answers[sections[i].questions[j].id][f].file}' target="_blank">${'File/photo'.translate(lang)}</a><br/>` + ' ';
                                                if (empty) empty = false;
                                            }
                                        }
                                    }
                                } else if (sections[i].questions[j].type == 10) {
                                    if (sections[i].questions[j].userInput) {
                                        referralData += "<br/><br/>"
                                    } else {
                                        referralData += "<br/>"
                                    }
                                    if (empty) empty = false;

                                } else if (sections[i].questions[j].type == 12) {
                                    referralData += `${'Patientens blodtryck uppmätt till'.translate(lang)}: <br/>`;
                                    referralData += answers[sections[i].questions[j].id] && answers[sections[i].questions[j].id][0] ? answers[sections[i].questions[j].id][0] + '/' : '';
                                    referralData += answers[sections[i].questions[j].id] && answers[sections[i].questions[j].id][1] ? answers[sections[i].questions[j].id][1] + '<br/>' : '';
                                    referralData += answers[sections[i].questions[j].id] && answers[sections[i].questions[j].id][2] ? `${'Puls'.translate(lang)}: ` + answers[sections[i].questions[j].id][2] + '<br/>' : '';
                                    if (empty) empty = false;
                                } else if (sections[i].questions[j].type == 13) {
                                    referralData += `${'Patientens VAS-skalvärde är'.translate(lang)}: `;
                                    referralData += answers[sections[i].questions[j].id] && answers[sections[i].questions[j].id][0] ? answers[sections[i].questions[j].id][0] + '<br/>' : '';
                                    if (empty) empty = false;
                                } else if (sections[i].questions[j].type == 14) {
                                    referralData += `${'Aktuell andningsfrekvens/minut'.translate(lang)}:`;
                                    referralData += answers[sections[i].questions[j].id] ? answers[sections[i].questions[j].id] + '<br/>' : '';
                                    if (empty) empty = false;
                                } else if (sections[i].questions[j].type == 16) {
                                    if (getAnswer(answers[sections[i].questions[j].id], sections, answers).response) {
                                        if (doctor) {
                                            if (referralData && referralData.length > 5) {
                                                let check = referralData.substr(referralData.length - 5, 5);
                                                if (check !== '<br/>') {
                                                    referralData += '<br/>'
                                                }
                                            }
                                            referralData += '\n<span><strong>' + sections[i].questions[j].name + ' ' + getAnswer(answers[sections[i].questions[j].id], sections, answers).response + ` - ${(totalSubtotal[sections[i].questions[j].id] ? totalSubtotal[sections[i].questions[j].id] : 0)} ` + '</strong></span><br/>';
                                        } else {
                                            referralData += '<br/>';
                                        }
                                        if (empty) empty = false;
                                    }
                                } else if (sections[i].questions[j].type == 17) {
                                    if (getAnswer(answers[sections[i].questions[j].id], sections, answers).response) {
                                        if (doctor) {
                                            if (referralData && referralData.length > 5) {
                                                let check = referralData.substr(referralData.length - 5, 5);
                                                if (check !== '<br/>') {
                                                    referralData += '<br/>'
                                                }
                                            }
                                            referralData += '\n<span><strong>' + sections[i].questions[j].name + ' ' + getAnswer(answers[sections[i].questions[j].id], sections, answers).response + ` - ${(totalSubtotal[sections[i].questions[j].id] ? totalSubtotal[sections[i].questions[j].id] : 0)} ` + '</strong></span><br/>';
                                        } else {
                                            referralData += '<br/>';
                                        }
                                        if (empty) empty = false;
                                    }
                                }
                                // else if (sections[i].questions[j].type == 16) {
                                //     if (getAnswer(answers[sections[i].questions[j].id], sections, answers).response)
                                //         referralData += '<br/><span><strong>' + sections[i].questions[j].name + ' ' + getAnswer(answers[sections[i].questions[j].id], sections, answers).response + ' ' + '</strong></span><br/>';
                                // } else if (sections[i].questions[j].type == 17) {
                                //     if (getAnswer(answers[sections[i].questions[j].id], sections, answers).response)
                                //         referralData += '<br/><span><strong>' + sections[i].questions[j].name + ' ' + getAnswer(answers[sections[i].questions[j].id], sections, answers).response + ' ' + '</strong></span><br/>';
                                // }
                            }
                        }
                    }

                    referralData += '</p>\n';
                }
            }

        }
    }
    return referralData;
}

export const generateReferralDaybook = (referral, lang) => {



    let referralData = '';
    if (referral) {
        let sections = [];
        let answers = {};
        if (referral.questionarySections) {
            sections = referral.questionarySections;
        }
        if (referral.answers) {
            answers = referral.answers;
        }

        referralData += `<p><strong>${'Patient svar'.translate(lang)}</strong></p>\n\n`;
        let empty = true;
        for (let i = 0; i < sections.length; i++) {
            if (checkSectionCondition(i, sections, answers)) {
                if (sections[i].displayOnReferral) {
                    if (i > 0 && !empty) {
                        referralData += '<br/><br/>'
                    }
                    referralData += `<p><strong>${sections[i].name}</strong></p>\n`;
                    if (empty) empty = false;
                }
                referralData += '<p>\n';
                for (let j = 0; j < sections[i].questions?.length; j++) {

                    if (checkQuestionCondition(sections[i].questions[j].id, sections, answers) && (sections[i].questions[j].type == 10 || answers[sections[i].questions[j].id])) {

                        if (sections[i].questions[j].displayOnReferral) {
                            if (sections[i].questions[j].type == 1) {
                                if (getAnswer(answers[sections[i].questions[j].id], sections, answers).text) {
                                    referralData += getAnswer(answers[sections[i].questions[j].id], sections, answers).text + ' ';
                                    if (empty) empty = false;
                                }
                            } else if (sections[i].questions[j].type == 2) {
                                if (answers[sections[i].questions[j].id]) {

                                    let arr = [];

                                    for (let k = 0; k < sections[i].questions[j].answers.length; k++) {
                                        let check = -1;
                                        for (let l = 0; l < answers[sections[i].questions[j].id].length; l++) {
                                            if (sections[i].questions[j].answers[k] && answers[sections[i].questions[j].id][l] == sections[i].questions[j].answers[k].id) {
                                                check = l;
                                                break;
                                            }
                                        }
                                        if (check != -1) {
                                            if (sections[i].questions[j].answerPrefix) {

                                                if (getAnswer(answers[sections[i].questions[j].id][check], sections, answers).text)
                                                    arr.push(getAnswer(answers[sections[i].questions[j].id][check], sections, answers).text);
                                            } else {
                                                if (getAnswer(answers[sections[i].questions[j].id][check], sections, answers).text) {
                                                    referralData += getAnswer(answers[sections[i].questions[j].id][check], sections, answers).text + ' ';
                                                    if (empty) empty = false;
                                                }
                                            }
                                        }
                                    }

                                    if (sections[i].questions[j].userInput && indexOfIndexOf(answers[sections[i].questions[j].id], '__user-input:') !== -1) {
                                        arr.push(answers[sections[i].questions[j].id][indexOfIndexOf(answers[sections[i].questions[j].id], '__user-input:')].replace('__user-input:', ''))
                                    }

                                    if (arr.length && sections[i].questions[j].answerPrefix) {
                                        if (arr.length > 1) {
                                            let last = arr.pop();
                                            referralData += sections[i].questions[j].answerPrefix + ' ' + arr.join(', ') + ' och ' + last + '. ';
                                        } else {
                                            referralData += sections[i].questions[j].answerPrefix + ' ' + arr.join(', ') + '. ';
                                        }
                                        if (empty) empty = false;
                                    }
                                }
                            } else if (sections[i].questions[j].type == 18) {

                            } else if (sections[i].questions[j].type == 19) {
                                // referralData += sections[i].questions[j].answerPrefix ? `${sections[i].questions[j].answerPrefix} ${answers[sections[i].questions[j].id]}. ` : `${answers[sections[i].questions[j].id]}. `;
                                referralData += sections[i].questions[j].name + ' - <strong>' + (answers[sections[i].questions[j].id] ? answers[sections[i].questions[j].id] : '') + '.</strong> ';
                                if (empty) empty = false;
                            } else if (sections[i].questions[j].type == 3) {
                                // referralData += answers[sections[i].questions[j].id] + '. ';
                                referralData += sections[i].questions[j].answerPrefix ? `${sections[i].questions[j].answerPrefix} ${answers[sections[i].questions[j].id]}. ` : `${answers[sections[i].questions[j].id]}. `;
                                if (empty) empty = false;
                            } else if (sections[i].questions[j].type == 5) {
                                let newArr = JSON.parse(JSON.stringify(answers[sections[i].questions[j].id]));
                                let arr = [];
                                for (let k = 0; k < newArr.length; k++) {
                                    if (newArr[k]) {
                                        arr.push(newArr[k]);
                                    }
                                }

                                if (arr.length > 1) {
                                    let last = arr.pop();
                                    referralData += arr.join(', ') + ' och ' + last + '. ';
                                } else {
                                    referralData += arr.join(', ') + '. ';
                                }
                                if (empty) empty = false;

                            } else if (sections[i].questions[j].type == 6) {
                                if (answers[sections[i].questions[j].id]) {
                                    referralData += `${'Patientens längd'.translate(lang)}: ` + answers[sections[i].questions[j].id][1] + ', ' + `${'vikt'.translate(lang)}: ` + answers[sections[i].questions[j].id][0] + ', ' + `${'BMI'.translate(lang)}: ` + parseFloat(answers[sections[i].questions[j].id] && answers[sections[i].questions[j].id].length ? (answers[sections[i].questions[j].id][0] / ((answers[sections[i].questions[j].id][1] / 100) * (answers[sections[i].questions[j].id][1] / 100))) : 0).toFixed(1) + '. ';
                                    if (empty) empty = false;
                                }
                            } else if (sections[i].questions[j].type == 9) {
                                if (answers[sections[i].questions[j].id] && answers[sections[i].questions[j].id].length) {
                                    for (let f = 0; f < answers[sections[i].questions[j].id].length; f++) {
                                        if (answers[sections[i].questions[j].id][f] && answers[sections[i].questions[j].id][f].file) {
                                            referralData += `<a href='${API_ENDPOINT + answers[sections[i].questions[j].id][f].file}' target="_blank">${'File/photo'.translate(lang)}</a><br/>` + ' ';
                                            if (empty) empty = false;
                                        }
                                    }
                                }
                            } else if (sections[i].questions[j].type == 10) {
                                if (sections[i].questions[j].userInput) {
                                    referralData += "<br/><br/><br/><br/>"
                                } else {
                                    referralData += "<br/><br/>"
                                }
                                if (empty) empty = false;

                            } else if (sections[i].questions[j].type == 12) {
                                referralData += `${'Patientens blodtryck uppmätt till'.translate(lang)}: <br/>`;
                                referralData += answers[sections[i].questions[j].id] && answers[sections[i].questions[j].id][0] ? answers[sections[i].questions[j].id][0] + '/' : ''
                                referralData += answers[sections[i].questions[j].id] && answers[sections[i].questions[j].id][1] ? answers[sections[i].questions[j].id][1] + '<br/>' : ''
                                referralData += answers[sections[i].questions[j].id] && answers[sections[i].questions[j].id][2] ? 'Puls: ' + answers[sections[i].questions[j].id][2] + '<br/>' : ''
                                if (empty) empty = false;
                            } else if (sections[i].questions[j].type == 13) {
                                referralData += `${'Patientens VAS-skalvärde är'.translate(lang)}: `;
                                referralData += answers[sections[i].questions[j].id] && answers[sections[i].questions[j].id][0] ? answers[sections[i].questions[j].id][0] + '<br/>' : ''
                                if (empty) empty = false;
                            } else if (sections[i].questions[j].type == 14) {
                                referralData += `${'Aktuell andningsfrekvens/minut'.translate(lang)}:`;
                                referralData += answers[sections[i].questions[j].id] ? answers[sections[i].questions[j].id] + '<br/>' : ''
                                if (empty) empty = false;
                            }

                        }
                    }


                }

                referralData += '</p>\n';
            }
        }
    }


    let html = `
        ${referralData}

    `
    return html;

}


export const generateScaleDaybook = (referral, lang) => {
    let referralData = '';
    if (referral) {
        let sections = [];
        let answers = {};
        if (referral.questionarySections) {
            sections = referral.questionarySections;
        }
        if (referral.answers) {
            answers = referral.answers;
        }


        for (let i = 0; i < sections.length; i++) {
            if (checkSectionCondition(i, sections, answers)) {

                referralData += '<div>\n';
                for (let j = 0; j < sections[i].questions.length; j++) {

                    if (checkQuestionCondition(sections[i].questions[j].id, sections, answers) && (sections[i].questions[j].type == 10 || answers[sections[i].questions[j].id])) {

                        if (sections[i].questions[j].displayOnReferral) {
                            if (sections[i].questions[j].type == 18) {
                                for (let k = 0; k < sections[i].questions[j].answers.length; k++) {

                                    if (sections[i].questions[j].answers[k].scale) {
                                        referralData += `<p>` + sections[i].questions[j].answers[k].scale.name + ": " + answers[sections[i].questions[j].id][sections[i].questions[j].answers[k].id] + '</p>'

                                    } else {
                                        referralData += `<p>` + ' ' + ": " + answers[sections[i].questions[j].id][sections[i].questions[j].answers[k].id] + '</p>'

                                    }
                                }


                            }
                        }
                    }


                }

                referralData += '</div>\n';
            }
        }
    }



    let html = `
        ${referralData}

    `

    return html;





}
