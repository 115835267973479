import React, { Component } from 'react';

import {
    Container,
    Row,
    Col,
    FormGroup, Label, Input,
    Button,
    Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';



import ListBuilder from './listBuilder';
import { getSearchParams, generateSearchLink, generateSearchLinkMultiple } from '../helpers/linkHelper';
import { API_ENDPOINT, GOOGLE_API_KEY } from '../constants';
import moment from 'moment';
import eRemissIcon from '../assets/svg/e.svg';
import PrintHelper from '../components/printHelper';
import Isvg from 'react-inlinesvg';
import Checkbox from './forms/fields/checkbox';
import view from '../assets/svg/eye.svg'
import garabage from '../assets/svg/garbage.svg'


function parseJSON(response) {
    return response.json().then(json => {
        return {
            result: json,
            status: response.status
        }
    })
}




const loadDataWithQuery = [
    (fetchFunction, lang, match, query) => {

        let queryFields = {};
        for (var key in query) {
            if (key && key.indexOf('query-') === 0) {
                queryFields[key.replace('query-', '')] = query[key];
            }
        }

        return fetchFunction(API_ENDPOINT + '/data/listing', {
            method: 'POST',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
            body: JSON.stringify({
                page: query.page,
                entries: query.entries,
                filter: query.filter,
                sortType: query.sortType,
                sortField: query.sortField,
                group: query.group,
                clinic: query.clinic,
                query: queryFields,
                lang: query.lang
            })
        }).then(parseJSON).then(({ result, status }) => {
            if (status >= 200 && status < 300)
                return {
                    page: query.page ? query.page : 0,
                    items: result.items,
                    total: result.total,
                    resultFilter: result.filter
                }
        })


    },

]

/**
* List of patients for selected clinic and selected department
* @author   Milan Stanojevic
*/
class HandledRequestsComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            regions: [],
            cities: [],
            groups: [],
            subgroups: [],
            ...props.initialData,
            useState: true,
            params: {
                entries: 10,
                page: 0,
            },
            items: [],
            total: 0,
            loading: true,
            suggestions: [],

        };
    }


    get = () => {
        let params = this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0, group: this.props.selectedGroup, clinic: this.props.selectedClinic });
        params.group = this.props.selectedGroup;
        params.clinic = this.props.selectedClinic;
        params.lang = this.props.lang;
        params._ts = new Date().getTime();

        this.setState({
            _ts: params._ts
        }, () => {
            for (let i = 0; i < loadDataWithQuery.length; i++) {
                loadDataWithQuery[i](window.fetch, this.props.lang, this.props[0].match, params).then((data) => {

                    this.setState({
                        ...data,
                        loading: null
                    })
                })
            }

        })


    }

    componentDidMount() {
        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }

        this.get();

        let params = getSearchParams(this.props[0].location.search, { entries: 10, page: 0 });


    }

    componentWillUnmount() {
    }



    componentDidUpdate(prevProps, prevState) {
        if (prevProps[0].location.search != this.props[0].location.search) {
            this.setState({
                loading: true
            }, () => {
                this.get();

            })

        }

        if (this.state.useState && prevState._useStateTs != this.state._useStateTs) {
            this.setState({
                loading: true
            }, () => {
                this.get();

            })

        }
        if (prevProps.selectedGroup != this.props.selectedGroup) {
            this.get();
        }
        if (prevProps.selectedClinic != this.props.selectedClinic) {
            this.get();
        }
        if (prevProps.getLogs != this.props.getLogs) {
            this.get();
        }


    }


    updateSort = (field, type) => {
        if (this.state.useState) {
            let state = this.state.params;
            state.sortField = field == 'waitingTime' ? 'waitingMin' : field;
            state.sortType = type;
            this.setState({
                params: state
            })

            this.setState({
                _useStateTs: new Date().getTime()
            })

        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0 }, [{ name: 'sortField', value: field == 'waitingTime' ? 'waitingMin' : field }, { name: 'sortType', value: type }], false));

        }
    }

    updateParams = (name, value, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };

                obj[name] = value;

                this.setState({ params: obj });
            } else {
                let obj = this.state.params;
                obj[name] = value;


                this.setState({ params: obj })
            }

            this.setState({
                _useStateTs: new Date().getTime()
            })
        } else {
            this.props[0].history.push(generateSearchLink(this.props[0].location.search, { entries: 10, page: 0 }, name, value, restart));
        }
    }

    updateMultipleParams = (fields, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj });

            } else {
                let obj = this.state.params;
                for (let i = 0; i < fields.length; i++) {

                    obj[fields[i].name] = fields[i].value;

                }
                this.setState({ params: obj })
            }

            this.setState({
                _useStateTs: new Date().getTime()
            })

        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0 }, fields, restart));
        }
    }









    render() {
        let params = {};
        if (this.state.useState) {
            params = this.state.params;
        } else {
            params = getSearchParams(this.props[0].location.search, { entries: 10, page: 0 })
        }
        return (
            <div className="users-filter">

                <ListBuilder
                    lang={this.props.lang}
                    loading={this.state.loading}
                    total={this.state.total}
                    showNumeration={false}
                    fields={[
                        { type: 'text', name: 'userRequest.userData.name', label: 'Patient'.translate(this.props.lang), allowSort: true },
                        { type: 'text', name: 'userRequest.socialSecurityNumber', label: 'Social security number'.translate(this.props.lang), allowSort: true },
                        { type: 'text', name: 'tsCreated', label: 'Request date'.translate(this.props.lang), allowSort: true },
                        { type: 'text', name: 'tsModified', label: 'Handling date'.translate(this.props.lang), allowSort: true },
                        { type: 'text', name: 'selectedDoctor', label: 'Selected doctor'.translate(this.props.lang), allowSort: true },
                        { type: 'text', name: 'status', label: 'Status'.translate(this.props.lang), allowSort: true },
                        // { type: 'text', name: 'deletionDate', label: 'Deletion date'.translate(this.props.lang), allowSort: true },
                        // { type: 'text', name: 'reason', label: 'Reason for deletion'.translate(this.props.lang), allowSort: true },

                    ]}
                    items={this.state.items.map(item => {
                        return {
                            ...item,
                            status: item.status.translate(this.props.lang),
                            'userRequest.userData.name': /*item.addOnlyChildren ? item.userRequest?.childrenList[0]?.name || 'test' :*/ item.userRequest && item.userRequest.userData && item.userRequest.userData.name ? item.userRequest.userData.name : 'test',
                            'userRequest.socialSecurityNumber': /*item.addOnlyChildren ? item.userRequest?.childrenList[0]?.socialSecurityNumber || 'test' :*/ item.userRequest && item.userRequest.socialSecurityNumber ? item.userRequest.socialSecurityNumber : 'test',
                            tsCreated: item.tsCreated ? this.props.getStringDateTs(item.tsCreated, this.props.dateFormat + ' HH:mm')/*moment.unix(item.tsCreated).format(this.props.dateFormat + ' HH:mm')*/ : null,
                            tsModified: item.tsModified ? this.props.getStringDateTs(item.tsModified, this.props.dateFormat + ' HH:mm')/*moment.unix(item.tsModified).format(this.props.dateFormat + ' HH:mm')*/ : null

                        }
                    })}
                    actions={
                        [
                            {
                                component: <div className="tabel-action-tooltip"> <Isvg src={view} className="cancel-icon" /> <div className="chat-icons-tooltip">{'Preview request'.translate(this.props.lang)}</div> </div>,
                                onClick: (item) => this.setState({ patientRequestModal: item })
                            },

                            // {
                            //     component: <div className="tabel-action-tooltip"> <Isvg src={garabage} className="delete-icon" /> <div className="chat-icons-tooltip">{'Delete'.translate(this.props.lang)}</div> </div>,
                            //     onClick: (item) => this.setState({ deleteModal: item })
                            // }

                        ]
                    }
                    rawItems={this.state.items}
                    params={params}
                    sortField={params.sortField}
                    sortType={params.sortType}
                    updateSort={this.updateSort}
                    updateParams={this.updateParams}
                    updateMultipleParams={this.updateMultipleParams}
                ></ListBuilder>




                {
                    this.state.patientRequestModal ?
                        <Modal isOpen={this.state.patientRequestModal} size='lg' centered >
                            <ModalHeader close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ patientRequestModal: false })}>&times;</button>}>{'Listing request'.translate(this.props.lang)}</ModalHeader>
                            <ModalBody>
                                <Row>
                                    <Col lg='6'>
                                        <div>
                                            <img style={{ maxWidth: 220, marginBottom: 15}} src={API_ENDPOINT + `${this.state.patientRequestModal.group && this.state.patientRequestModal.group.logo}`} />
                                            <div>{this.state.patientRequestModal.group && this.state.patientRequestModal.group.name}</div>
                                            <div>{this.state.patientRequestModal.group && this.state.patientRequestModal.group.address0 ? this.state.patientRequestModal.group.address0 : ''}</div>
                                            <div>{this.state.patientRequestModal.group && this.state.patientRequestModal.group.address1 ? this.state.patientRequestModal.group.address1 : ''}</div>
                                            <div>{this.state.patientRequestModal.group && this.state.patientRequestModal.group.phone}</div>
                                        </div>
                                    </Col>
                                    <Col lg='6'>
                                        <div><strong>{'Request date:'.translate(this.props.lang)}</strong> {' ' + this.props.getStringDateTs(this.state.patientRequestModal.tsCreated, this.props.dateFormat + ' HH:mm')/*moment.unix(this.state.patientRequestModal.tsCreated).format(this.props.dateFormat + ' HH:mm')*/}</div>
                                    </Col>
                                </Row>
                                <hr />
                                {this.state.patientRequestModal?.addOnlyChildren ? <Row><Col lg='12' sm='12'><div style={{marginTop: '10px', marginBottom: '10px', color: 'red', fontWeight: 'bold'}}>
                                    {'ONLY WANT CHILDREN TO BE LISTED'.translate(this.props.lang)}    
                                </div></Col></Row> : null}
                                <Row>
                                    <Col lg='6'>
                                        <div><b>{this.state.patientRequestModal?.addOnlyChildren ? 'Vårdnadshavare'.translate(this.props.lang) : 'Personal information'.translate(this.props.lang)}</b></div>
                                        <div>
                                            <div>{this.state.patientRequestModal.userRequest && this.state.patientRequestModal.userRequest.socialSecurityNumber}</div>
                                            <div>{this.state.patientRequestModal.userRequest && this.state.patientRequestModal.userRequest.userData && this.state.patientRequestModal.userRequest.userData.name}</div>
                                            <div>{this.state.patientRequestModal.userRequest && this.state.patientRequestModal.userRequest.city}</div>
                                            <div>{this.state.patientRequestModal.userRequest ? this.state.patientRequestModal.userRequest.zip + ' ' + this.state.patientRequestModal.userRequest.street : ''}</div>
                                            <div>{this.state.patientRequestModal.userRequest && this.state.patientRequestModal.userRequest.phone}</div>
                                            <div>{this.state.patientRequestModal.userRequest && this.state.patientRequestModal.userRequest.email}</div>

                                        </div>
                                    </Col>
                                    <Col lg="6">
                                        {
                                            this.state.patientRequestModal.userRequest.childrenList.length ?
                                                <div>
                                                    <div><b>{'Listed children'.translate(this.props.lang)}</b></div>
                                                    {
                                                        this.state.patientRequestModal.userRequest.childrenList.map(item => {
                                                            return (
                                                                <div>
                                                                    <div>{item.socialSecurityNumber}</div>
                                                                    <div>{item.name}</div>
                                                                    <br />
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                                :
                                                null
                                        }
                                    </Col>
                                </Row>
                                <br />
                                <Row>
                                    <Col>
                                        <div><b>{'Selected doctor:'.translate(this.props.lang) + ' '}</b>{this.state.patientRequestModal.selectedDoctor ? this.state.patientRequestModal.selectedDoctor : 'N/A'}</div>
                                    </Col>
                                </Row>
                                <br />
                                <Row>
                                    <Col>
                                        <div><b>{'Status:'.translate(this.props.lang) + ' '}</b>{this.state.patientRequestModal.status ? this.state.patientRequestModal.status.translate(this.props.lang) : 'Waiting for handling'.translate(this.props.lang)}</div>
                                    </Col>
                                </Row>
                            </ModalBody>
                            <ModalFooter style={{ margin: 'unset', justifyContent: 'flex-end', display: 'flex', width: '100%' }}>
                                {/* <Button color='primary' onClick={() => { this.updateList('Approved') }}><i className="fa fa-check" /> {'Approve'.translate(this.props.lang)}</Button> */}
                                {/* <Button color='danger' onClick={() => { this.updateList('Rejected') }}> <i className="fa fa-close" /> {'Reject'.translate(this.props.lang)}</Button> */}
                                <PrintHelper docName={this.props.lang === 'en' ? 'Clinic_document' : this.props.lang === 'se' ? 'Klinikdokument' : 'Clinic_document'} listRequest={true} lang={this.props.lang} html={this.props.lang === 'en' ? this.state.patientRequestModal.html : this.state.patientRequestModal.htmlSv ? this.state.patientRequestModal.htmlSv : this.state.patientRequestModal.html} />
                                <Button onClick={() => {
                                    this.setState({
                                        patientRequestModal: false
                                    })
                                }}>{'Close'.translate(this.props.lang)}</Button>
                            </ModalFooter>
                        </Modal>
                        :
                        null
                }


            </div >
        );


    }
}
export default HandledRequestsComponent;