import React, { Component } from 'react';
import Page from '../../containers/page';

import {
    Container,
    Row,
    Col,
    FormGroup, Label, Input, Button, UncontrolledAlert, Alert

} from 'reactstrap';

import { Rnd } from 'react-rnd';

import Isvg from "react-inlinesvg";
import { formValueSelector, change } from 'redux-form';
import { connect } from 'react-redux';

import FormBuilder from '../../components/forms/formBuilder';
import { required } from '../../components/forms/validation';

import ListBuilder from '../../components/listBuilder';
import DeleteModal from '../../components/deleteModal';
import { getSearchParams, generateSearchLink, generateSearchLinkMultiple } from '../../helpers/linkHelper';
import { API_ENDPOINT } from '../../constants';
import ErrorModal from '../../components/errorModal';
import { Link } from 'react-router-dom';
import TimePicker from '../../components/forms/fields/timepicker';
import TimePickerV2 from '../../components/forms/fields/timepicker_v2';


import { Player, Controls } from "@lottiefiles/react-lottie-player";

import Search from "../../components/search";
import profileUser from "../../assets/images/user.png";
import notification from "../../assets/svg/notification.svg";
import link from "../../assets/svg/link-new-tab.svg";

import Iframe from 'react-iframe';
import Checkbox from '../../components/forms/fields/checkbox';
import moment from 'moment';
import infoIcon from "../../assets/svg/link-new-tab.svg";


function parseJSON(response) {
    return response.json().then(json => {
        return {
            result: json,
            status: response.status
        }
    })
}


/**
* Group settings page
* @author   Milan Stanojevic
*/
class ClinicData extends Component {
    constructor(props) {
        super(props);
        this.player = React.createRef();
        this.player2 = React.createRef();
        this.state = {
            showPlayer: false,
            showPlayer2: false,
            ...props.initialData,
            form: false,
            useState: false,
            params: {
                entries: 10,
                page: 0,
                'query-group': null
            },
            items: [],
            total: 0,
            loading: true,
            groups: [],
            languages: [],
            regions: [],
            showForm: false,
            tabIndex: 1,
            template: -1,
            type: 'bankid',
            bankIdRedirectUrl: null,
            workingHours: null,
            defaultWaitingTime: 5


        };

    }

    showPlayer = () => {
        this.setState({
            showPlayer: true,
        });
    };

    hidePlayer = () => {
        this.setState({
            showPlayer: false,
        });
    };

    get = () => {
        let startDay = new Date();
        startDay.setHours(0, 0, 0, 0)
        let endDay = new Date();
        endDay.setHours(23, 59, 59, 0)
        this.setState({ min: startDay, max: endDay })

        this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));

        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0, 'query-group': null })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

        for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
            this.props.loadDataWithQuery[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0, 'query-group': null })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

        let selectedGroupIdx = 0;
        if (this.props.selectedGroup && this.props.uData?.clinicGroups?.length) {
            for (let i = 0; i < this.props.uData.clinicGroups.length; i++) {
                if (this.props.uData.clinicGroups[i]._id == this.props.selectedGroup) {
                    selectedGroupIdx = i;
                    break;
                }
            }
        }

        if (this.props.uData && this.props.uData.clinicGroups && this.props.uData.clinicGroups[selectedGroupIdx] && this.props.uData.clinicGroups[selectedGroupIdx].workingHours && this.props.uData.clinicGroups[selectedGroupIdx].clinics) {

            let obj = {};

            for (const key in this.props.uData.clinicGroups[selectedGroupIdx].workingHours) {

                if (this.props.uData.clinicGroups[selectedGroupIdx].workingHours.hasOwnProperty(key)) {
                    obj[key] = {};
                    for (const key2 in this.props.uData.clinicGroups[selectedGroupIdx].workingHours[key]) {

                        if (this.props.uData.clinicGroups[selectedGroupIdx].workingHours[key].hasOwnProperty(key2)) {
                            if (key2 == 'patientTime') {
                                obj[key][key2] = this.props.uData.clinicGroups[selectedGroupIdx].workingHours[key]['patientTime']
                                if (obj[this.props.selectedClinic] && obj[this.props.selectedClinic]['patientTime'] && obj[this.props.selectedClinic]['patientTime'] == 0) {
                                    this.setState({
                                        checkEstimateTime: true
                                    })
                                } else {
                                    this.setState({
                                        checkEstimateTime: false
                                    })
                                }
                            } else {
                                let day = {
                                    checked: this.props.uData.clinicGroups[selectedGroupIdx].workingHours[key][key2].checked,
                                    startTime: new Date(this.props.uData.clinicGroups[selectedGroupIdx].workingHours[key][key2].startTime),
                                    endTime: new Date(this.props.uData.clinicGroups[selectedGroupIdx].workingHours[key][key2].endTime),
                                };


                                obj[key][key2] = day;
                            }



                        }
                    }

                }
            }

            if (Object.keys(obj).length !== this.props.uData.clinicGroups[selectedGroupIdx].clinics.length) {
                for (let k = 0; k < this.props.uData.clinicGroups[selectedGroupIdx].clinics.length; k++) {
                    if (!obj[this.props.uData.clinicGroups[selectedGroupIdx].clinics[k]._id]) {
                        
                        let start = new Date();
                        start.setHours(0, 0, 0, 0)
                        let end = new Date();
                        end.setHours(23, 59, 59, 0)

                        obj[this.props.uData.clinicGroups[selectedGroupIdx].clinics[k]._id] = {
                            monday: {
                                checked: true,
                                startTime: start,
                                endTime: end
                            },
                            tuesday: {
                                checked: true,
                                startTime: start,
                                endTime: end
                            },
                            wednesday: {
                                checked: true,
                                startTime: start,
                                endTime: end
                            },
                            thursday: {
                                checked: true,
                                startTime: start,
                                endTime: end
                            },
                            friday: {
                                checked: true,
                                startTime: start,
                                endTime: end
                            },
                            saturday: {
                                checked: true,
                                startTime: start,
                                endTime: end
                            },
                            sunday: {
                                checked: true,
                                startTime: start,
                                endTime: end
                            },
                        }
                    }
                }
            }

            this.setState({ workingHours: obj })
        } else if (this.props.uData && this.props.uData.clinicGroups && this.props.uData.clinicGroups[selectedGroupIdx] && this.props.uData.clinicGroups[selectedGroupIdx].clinics) {
            let obj = {}
            for (let i = 0; i < this.props.uData.clinicGroups[selectedGroupIdx].clinics.length; i++) {
                let start = new Date();
                start.setHours(0, 0, 0, 0)
                let end = new Date();
                end.setHours(23, 59, 59, 0)
                obj[this.props.uData.clinicGroups[selectedGroupIdx].clinics[i]._id] = {
                    monday: {
                        checked: true,
                        startTime: start,
                        endTime: end
                    },
                    tuesday: {
                        checked: true,
                        startTime: start,
                        endTime: end
                    },
                    wednesday: {
                        checked: true,
                        startTime: start,
                        endTime: end
                    },
                    thursday: {
                        checked: true,
                        startTime: start,
                        endTime: end
                    },
                    friday: {
                        checked: true,
                        startTime: start,
                        endTime: end
                    },
                    saturday: {
                        checked: true,
                        startTime: start,
                        endTime: end
                    },
                    sunday: {
                        checked: true,
                        startTime: start,
                        endTime: end
                    },
                }
            }
            this.setState({ workingHours: obj })
        }





    }

    componentDidMount() {
        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }

        this.get();





        this.setState({ showForm: true })

    }

    componentDidUpdate(prevProps) {
        if (prevProps[0].location.search != this.props[0].location.search) {
            this.setState({
                loading: true,

            }, () => {
                this.get();

            })
        }

        if (prevProps.selectedGroup != this.props.selectedGroup || prevProps.selectedClinic != this.props.selectedClinic) {
            // this.setState({
            //     showForm: false
            // }, () => {
            //     setTimeout(() => {
            //         this.setState({ showForm: true })
            //     }, 100)
            // })
            this.setState({
                loading: true,
                workingHours: null
            }, () => {
                this.get()


            })

        }
    }


    updateSort = (field, type) => {
        if (this.state.useState) {
            let state = this.state.params;
            state.sortField = field;
            state.sortType = type;
            this.setState({
                params: state
            })
        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0 }, [{ name: 'sortField', value: field }, { name: 'sortType', value: type }], false));

        }
    }

    updateParams = (name, value, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };
                obj[name] = value;
                this.setState({ params: obj });
            } else {
                let obj = this.state.params;
                obj[name] = value;
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(generateSearchLink(this.props[0].location.search, { entries: 10, page: 0 }, name, value, restart));
        }
    }
    updateMultipleParams = (fields, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj });

            } else {
                let obj = this.state.params;
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0 }, fields, restart));
        }
    }

    stopAnimation = () => {
        this.player.current.play();
    };



    update = () => {
        let data = {};
        if (this.props.uData && this.props.uData._id && this.state.workingHours) {
            data.workingHours = this.state.workingHours;

            if (data.workingHours && !data.workingHours[this.props.selectedClinic].patientTime) {
                data.workingHours[this.props.selectedClinic].patientTime = 0
            }

            this.setState(
                {
                    showPlayer: true,
                },
                () => {
                    data.groupId = this.props.selectedGroup;
                    data.clinic = this.props.selectedClinic;
                    this.player.current.play();
                    fetch(API_ENDPOINT + '/users/account/updateClinicData/workingHours', {
                        method: 'POST',
                        headers: {
                            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                            'content-type': 'application/json'
                        },
                        body: JSON.stringify(data)
                    }).then(parseJSON).then(({ result, status }) => {
                        if (result.error) {
                            this.setState({ error: result.error })
                        } else {
                            this.setState({
                                message: result.message,
                                showPlayer: false,
                                showPlayer2: true,
                            },
                                () => {
                                    setTimeout(() => {
                                        this.setState({ showPlayer2: false });
                                    }, 1000);
                                })
                            // if (this.state.previewNeeded) {
                            //     this.setState({ previewNeeded: false });
                            //     window.open(window.location.origin + '/' + data.alias, '_blank')
                            // }
                            this.props.verifyUser(() => { }, () => {
                                this.props.changeGroup(data.groupId)
                            });
                        }

                    })
                }
            )
        }
    }



    render() {
        let params = {};
        if (this.state.useState) {
            params = this.state.params;
        } else {
            params = getSearchParams(this.props[0].location.search, { entries: 10, page: 0 })
        }


        let selectedGroupIdx = 0;
        if (this.props.selectedGroup && this.props.uData?.clinicGroups?.length) {
            for (let i = 0; i < this.props.uData.clinicGroups.length; i++) {
                if (this.props.uData.clinicGroups[i]._id == this.props.selectedGroup) {
                    selectedGroupIdx = i;
                    break;
                }
            }
        }
        // console.log('test ', this.state.workingHours)
        return (
            <div className={this.props.autoHideSidebar ? "dashboard dashboard-auto-hide" : "dashboard"}>

                <Container fluid>

                    <Row>
                        <Col lg="12">





                            <div className="panel">

                                <Row>
                                    <Col lg="7">
                                        <h5 className="component-header mb-35">{this.props.linksMeta && this.props.linksMeta[this.props[0].location.pathname] ? this.props.linksMeta[this.props[0].location.pathname][this.props.lang] : ''}</h5>
                                    </Col>
                                    <Col lg="5">
                                        <h5 className="d-center component-header mb-35" >{'Estimate time per patient case (drop-in)'.translate(this.props.lang)}</h5>
                                    </Col>

                                    <Col lg='7'>
                                        {
                                            this.state.workingHours ?
                                                this.props.uData && this.props.uData.clinicGroups[selectedGroupIdx].clinics.map((item, idx) => {
                                                    if (item._id == this.props.selectedClinic)

                                                        return (


                                                            this.state.workingHours[item._id] ?
                                                                <>
                                                                    {/* {this.state.workingHours && this.state.workingHours[item._id].patientTime == 0 ? this.setState({checkEstimateTime: true}) : this.setState({checkEstimateTime: false})} */}
                                                                    <div className="drop-in-working-hours-wrap">
                                                                        {/* <div>{item.name} <br /><span className="clinic-subgroup-name">{item.subgroupName}</span></div> */}
                                                                        <div className="drop-in-working-hours-days-wrap">
                                                                            <div className="drop-in-working-hours-day">
                                                                                <Checkbox checked={this.state.workingHours[item._id].monday.checked} onChange={(e) => {
                                                                                    let workingHours = this.state.workingHours;
                                                                                    workingHours[item._id].monday.checked = !workingHours[item._id].monday.checked;
                                                                                    this.setState({ workingHours })
                                                                                }} />
                                                                                <h6>{'Monday'.translate(this.props.lang)}</h6>

                                                                                <div className="drop-in-working-hours-timepicker">
                                                                                    <Label>{'Start time'.translate(this.props.lang)}</Label>
                                                                                    &nbsp;
                                                                                    <TimePickerV2
                                                                                        getStringDateTs={this.props.getStringDateTs}
                                                                                        format={'HH:mm'}
                                                                                        value={this.props.getStringDateTs((new Date(this.state.workingHours[item._id].monday.startTime).getTime() / 1000), "HH:mm")}
                                                                                        minValue={"00:00"}
                                                                                        maxValue={this.props.getStringDateTs(Math.floor(this.state.workingHours[item._id].monday.endTime.getTime() / 1000), 'HH:mm')}
                                                                                        flag={0}
                                                                                        onChange={(time1, timeString) => {

                                                                                            let workingHours = this.state.workingHours;

                                                                                            let startTimeSplit = timeString.split(":");

                                                                                            let newDate = new Date(this.props.getStringDateTs(Math.floor(new Date().getTime() / 1000), 'MM/DD/YYYY HH:mm'))
                                                                                            newDate.setHours(startTimeSplit[0], startTimeSplit[1], 0, 0)
                                                                                            workingHours[item._id].monday.startTime = new Date(this.props.getStringDateInverseTsV1(Math.floor(new Date(newDate).getTime() / 1000), 'MM/DD/YYYY HH:mm'))



                                                                                            this.setState({ workingHours })
                                                                                        }}
                                                                                        allowClear={false}
                                                                                        disabledClear={true}
                                                                                        disabled={!this.state.workingHours[item._id].monday.checked}
                                                                                    />
                                                                                    {/* <TimePicker
                                                                                        getStringDateTs={this.props.getStringDateTs}
                                                                                        format={'HH:mm'}
                                                                                        value={moment(this.state.workingHours[item._id].monday.startTime)}
                                                                                        minValue={"00:00"} // moment.unix(this.state.min.getTime() / 1000).format("HH:mm")
                                                                                        maxValue={this.props.getStringDateTs(Math.floor(this.state.workingHours[item._id].monday.endTime.getTime() / 1000), 'HH:mm')}//moment.unix(this.state.workingHours[item._id].monday.endTime.getTime() / 1000).format("HH:mm")
                                                                                        flag={0}
                                                                                        onChange={(time1, timeString) => {

                                                                                            let workingHours = this.state.workingHours;


                                                                                            let startTimeSplit = timeString.split(":");

                                                                                            let newDate = new Date()
                                                                                            newDate.setHours(startTimeSplit[0], startTimeSplit[1], 0, 0)
                                                                                            workingHours[item._id].monday.startTime = newDate

                                                                                            this.setState({ workingHours })
                                                                                        }}
                                                                                        allowClear={false}
                                                                                        disabledClear={true}
                                                                                        disabled={!this.state.workingHours[item._id].monday.checked}
                                                                                    /> */}
                                                                                </div>


                                                                                <div className="drop-in-working-hours-timepicker">
                                                                                    <Label>{'End time'.translate(this.props.lang)}</Label>
                                                                                    &nbsp;
                                                                                    <TimePickerV2
                                                                                        getStringDateTs={this.props.getStringDateTs}
                                                                                        format={'HH:mm'}
                                                                                        value={this.props.getStringDateTs((new Date(this.state.workingHours[item._id].monday.endTime).getTime() / 1000), "HH:mm")}
                                                                                        minValue={this.props.getStringDateTs(Math.floor(this.state.workingHours[item._id] && this.state.workingHours[item._id].monday.startTime.getTime() / 1000), 'HH:mm')}
                                                                                        maxValue={"23:59"}
                                                                                        flag={0}
                                                                                        onChange={(time1, timeString) => {

                                                                                            let workingHours = this.state.workingHours;


                                                                                            let startTimeSplit = timeString.split(":");

                                                                                            let newDate = new Date(this.props.getStringDateTs(Math.floor(new Date().getTime() / 1000), 'MM/DD/YYYY HH:mm'))
                                                                                            newDate.setHours(startTimeSplit[0], startTimeSplit[1], 0, 0)
                                                                                            workingHours[item._id].monday.endTime = new Date(this.props.getStringDateInverseTsV1(Math.floor(new Date(newDate).getTime() / 1000), 'MM/DD/YYYY HH:mm'))

                                                                                            this.setState({ workingHours })
                                                                                        }}
                                                                                        allowClear={false}
                                                                                        disabledClear={true}
                                                                                        disabled={!this.state.workingHours[item._id].monday.checked}
                                                                                    />
                                                                                    {/* <TimePicker
                                                                                        getStringDateTs={this.props.getStringDateTs}
                                                                                        format={'HH:mm'}
                                                                                        value={moment(this.state.workingHours[item._id] && this.state.workingHours[item._id].monday.endTime)}
                                                                                        minValue={this.props.getStringDateTs(Math.floor(this.state.workingHours[item._id] && this.state.workingHours[item._id].monday.startTime.getTime() / 1000), 'HH:mm')}//moment.unix(this.state.workingHours[item._id] && this.state.workingHours[item._id].monday.startTime.getTime() / 1000).format("HH:mm")
                                                                                        maxValue={"23:59"} // moment.unix(this.state.max.getTime() / 1000).format("HH:mm")
                                                                                        onChange={(time1, timeString) => {

                                                                                            let workingHours = this.state.workingHours;


                                                                                            let startTimeSplit = timeString.split(":");

                                                                                            let newDate = new Date()
                                                                                            newDate.setHours(startTimeSplit[0], startTimeSplit[1], 0, 0)
                                                                                            workingHours[item._id].monday.endTime = newDate

                                                                                            this.setState({ workingHours })
                                                                                        }}
                                                                                        flag={0}
                                                                                        disabledClear={true}
                                                                                        disabled={!this.state.workingHours[item._id].monday.checked}
                                                                                        allowClear={false}
                                                                                    /> */}
                                                                                </div>
                                                                            </div>

                                                                            <div className="drop-in-working-hours-day">
                                                                                <Checkbox checked={this.state.workingHours[item._id].tuesday.checked} onChange={(e) => {
                                                                                    let workingHours = this.state.workingHours;
                                                                                    workingHours[item._id].tuesday.checked = !workingHours[item._id].tuesday.checked;
                                                                                    this.setState({ workingHours })
                                                                                }} />
                                                                                <h6>{'Tuesday'.translate(this.props.lang)}</h6>

                                                                                <div className="drop-in-working-hours-timepicker">
                                                                                    <Label>{'Start time'.translate(this.props.lang)}</Label>
                                                                                    &nbsp;
                                                                                    <TimePickerV2
                                                                                        getStringDateTs={this.props.getStringDateTs}
                                                                                        format={'HH:mm'}
                                                                                        value={this.props.getStringDateTs((new Date(this.state.workingHours[item._id].tuesday.startTime).getTime() / 1000), "HH:mm")}
                                                                                        minValue={"00:00"}
                                                                                        maxValue={this.props.getStringDateTs(Math.floor(this.state.workingHours[item._id].tuesday.endTime.getTime() / 1000), 'HH:mm')}
                                                                                        flag={0}
                                                                                        onChange={(time1, timeString) => {

                                                                                            let workingHours = this.state.workingHours;


                                                                                            let startTimeSplit = timeString.split(":");

                                                                                            let newDate = new Date(this.props.getStringDateTs(Math.floor(new Date().getTime() / 1000), 'MM/DD/YYYY HH:mm'))
                                                                                            newDate.setHours(startTimeSplit[0], startTimeSplit[1], 0, 0)
                                                                                            workingHours[item._id].tuesday.startTime = new Date(this.props.getStringDateInverseTsV1(Math.floor(new Date(newDate).getTime() / 1000), 'MM/DD/YYYY HH:mm'))

                                                                                            this.setState({ workingHours })
                                                                                        }}
                                                                                        allowClear={false}
                                                                                        disabledClear={true}
                                                                                        disabled={!this.state.workingHours[item._id].tuesday.checked}
                                                                                    />
                                                                                    {/* <TimePicker
                                                                                        getStringDateTs={this.props.getStringDateTs}
                                                                                        format={'HH:mm'}
                                                                                        value={moment(this.state.workingHours[item._id].tuesday.startTime)}
                                                                                        minValue={"00:00"} //moment.unix(this.state.min.getTime() / 1000).format("HH:mm")
                                                                                        maxValue={this.props.getStringDateTs(Math.floor(this.state.workingHours[item._id].tuesday.endTime.getTime() / 1000), 'HH:mm')} //moment.unix(this.state.workingHours[item._id].tuesday.endTime.getTime() / 1000).format("HH:mm")
                                                                                        flag={0}
                                                                                        onChange={(time1, timeString) => {

                                                                                            let workingHours = this.state.workingHours;


                                                                                            let startTimeSplit = timeString.split(":");

                                                                                            let newDate = new Date()
                                                                                            newDate.setHours(startTimeSplit[0], startTimeSplit[1], 0, 0)
                                                                                            workingHours[item._id].tuesday.startTime = newDate

                                                                                            this.setState({ workingHours })
                                                                                        }}
                                                                                        allowClear={false}
                                                                                        disabledClear={true}
                                                                                        disabled={!this.state.workingHours[item._id].tuesday.checked}
                                                                                    /> */}
                                                                                </div>


                                                                                <div className="drop-in-working-hours-timepicker">
                                                                                    <Label>{'End time'.translate(this.props.lang)}</Label>
                                                                                    &nbsp;
                                                                                    <TimePickerV2
                                                                                        getStringDateTs={this.props.getStringDateTs}
                                                                                        format={'HH:mm'}
                                                                                        value={this.props.getStringDateTs((new Date(this.state.workingHours[item._id].tuesday.endTime).getTime() / 1000), "HH:mm")}
                                                                                        minValue={this.props.getStringDateTs(Math.floor(this.state.workingHours[item._id] && this.state.workingHours[item._id].tuesday.startTime.getTime() / 1000), 'HH:mm')}
                                                                                        maxValue={"23:59"}
                                                                                        flag={0}
                                                                                        onChange={(time1, timeString) => {

                                                                                            let workingHours = this.state.workingHours;


                                                                                            let startTimeSplit = timeString.split(":");

                                                                                            let newDate = new Date(this.props.getStringDateTs(Math.floor(new Date().getTime() / 1000), 'MM/DD/YYYY HH:mm'))
                                                                                            newDate.setHours(startTimeSplit[0], startTimeSplit[1], 0, 0)
                                                                                            workingHours[item._id].tuesday.endTime = new Date(this.props.getStringDateInverseTsV1(Math.floor(new Date(newDate).getTime() / 1000), 'MM/DD/YYYY HH:mm'))

                                                                                            this.setState({ workingHours })
                                                                                        }}
                                                                                        allowClear={false}
                                                                                        disabledClear={true}
                                                                                        disabled={!this.state.workingHours[item._id].tuesday.checked}
                                                                                    />
                                                                                    {/* <TimePicker
                                                                                        getStringDateTs={this.props.getStringDateTs}
                                                                                        format={'HH:mm'}
                                                                                        value={moment(this.state.workingHours[item._id] && this.state.workingHours[item._id].tuesday.endTime)}
                                                                                        minValue={this.props.getStringDateTs(Math.floor(this.state.workingHours[item._id] && this.state.workingHours[item._id].tuesday.startTime.getTime() / 1000), 'HH:mm')} //moment.unix(this.state.workingHours[item._id] && this.state.workingHours[item._id].tuesday.startTime.getTime() / 1000).format("HH:mm")
                                                                                        maxValue={"23:59"} //moment.unix(this.state.max.getTime() / 1000).format("HH:mm")
                                                                                        onChange={(time1, timeString) => {

                                                                                            let workingHours = this.state.workingHours;


                                                                                            let startTimeSplit = timeString.split(":");

                                                                                            let newDate = new Date()
                                                                                            newDate.setHours(startTimeSplit[0], startTimeSplit[1], 0, 0)
                                                                                            workingHours[item._id].tuesday.endTime = newDate

                                                                                            this.setState({ workingHours })
                                                                                        }}
                                                                                        flag={0}
                                                                                        disabledClear={true}
                                                                                        disabled={!this.state.workingHours[item._id].tuesday.checked}
                                                                                        allowClear={false}
                                                                                    /> */}
                                                                                </div>
                                                                            </div>
                                                                            <div className="drop-in-working-hours-day">
                                                                                <Checkbox checked={this.state.workingHours[item._id].wednesday.checked} onChange={(e) => {
                                                                                    let workingHours = this.state.workingHours;
                                                                                    workingHours[item._id].wednesday.checked = !workingHours[item._id].wednesday.checked;
                                                                                    this.setState({ workingHours })
                                                                                }} />
                                                                                <h6>{'Wednesday'.translate(this.props.lang)}</h6>

                                                                                <div className="drop-in-working-hours-timepicker">
                                                                                    <Label>{'Start time'.translate(this.props.lang)}</Label>
                                                                                    &nbsp;
                                                                                    <TimePickerV2
                                                                                        getStringDateTs={this.props.getStringDateTs}
                                                                                        format={'HH:mm'}
                                                                                        value={this.props.getStringDateTs((new Date(this.state.workingHours[item._id].wednesday.startTime).getTime() / 1000), "HH:mm")}
                                                                                        minValue={"00:00"} //moment.unix(this.state.min.getTime() / 1000).format("HH:mm")
                                                                                        maxValue={this.props.getStringDateTs(Math.floor(this.state.workingHours[item._id].wednesday.endTime.getTime() / 1000), 'HH:mm')} //moment.unix(this.state.workingHours[item._id].wednesday.endTime.getTime() / 1000).format("HH:mm")
                                                                                        flag={0}
                                                                                        onChange={(time1, timeString) => {

                                                                                            let workingHours = this.state.workingHours;


                                                                                            let startTimeSplit = timeString.split(":");

                                                                                            let newDate = new Date(this.props.getStringDateTs(Math.floor(new Date().getTime() / 1000), 'MM/DD/YYYY HH:mm'))
                                                                                            newDate.setHours(startTimeSplit[0], startTimeSplit[1], 0, 0)
                                                                                            workingHours[item._id].wednesday.startTime = new Date(this.props.getStringDateInverseTsV1(Math.floor(new Date(newDate).getTime() / 1000), 'MM/DD/YYYY HH:mm'))

                                                                                            this.setState({ workingHours })
                                                                                        }}
                                                                                        allowClear={false}
                                                                                        disabledClear={true}
                                                                                        disabled={!this.state.workingHours[item._id].wednesday.checked}
                                                                                    />
                                                                                    {/* <TimePicker
                                                                                        getStringDateTs={this.props.getStringDateTs}
                                                                                        format={'HH:mm'}
                                                                                        value={moment(this.state.workingHours[item._id].wednesday.startTime)}
                                                                                        minValue={"00:00"} //moment.unix(this.state.min.getTime() / 1000).format("HH:mm")
                                                                                        maxValue={this.props.getStringDateTs(Math.floor(this.state.workingHours[item._id].wednesday.endTime.getTime() / 1000), 'HH:mm')} //moment.unix(this.state.workingHours[item._id].wednesday.endTime.getTime() / 1000).format("HH:mm")
                                                                                        flag={0}
                                                                                        onChange={(time1, timeString) => {

                                                                                            let workingHours = this.state.workingHours;


                                                                                            let startTimeSplit = timeString.split(":");

                                                                                            let newDate = new Date()
                                                                                            newDate.setHours(startTimeSplit[0], startTimeSplit[1], 0, 0)
                                                                                            workingHours[item._id].wednesday.startTime = newDate

                                                                                            this.setState({ workingHours })
                                                                                        }}
                                                                                        allowClear={false}
                                                                                        disabledClear={true}
                                                                                        disabled={!this.state.workingHours[item._id].wednesday.checked}
                                                                                    /> */}
                                                                                </div>


                                                                                <div className="drop-in-working-hours-timepicker">
                                                                                    <Label>{'End time'.translate(this.props.lang)}</Label>
                                                                                    &nbsp;
                                                                                    <TimePickerV2
                                                                                        getStringDateTs={this.props.getStringDateTs}
                                                                                        format={'HH:mm'}
                                                                                        value={this.props.getStringDateTs((new Date(this.state.workingHours[item._id].wednesday.endTime).getTime() / 1000), "HH:mm")}
                                                                                        minValue={this.props.getStringDateTs(Math.floor(this.state.workingHours[item._id] && this.state.workingHours[item._id].wednesday.startTime.getTime() / 1000), 'HH:mm')} //moment.unix(this.state.workingHours[item._id] && this.state.workingHours[item._id].wednesday.startTime.getTime() / 1000).format("HH:mm")
                                                                                        maxValue={"23:59"} //moment.unix(this.state.max.getTime() / 1000).format("HH:mm")
                                                                                        flag={0}
                                                                                        onChange={(time1, timeString) => {

                                                                                            let workingHours = this.state.workingHours;


                                                                                            let startTimeSplit = timeString.split(":");

                                                                                            let newDate = new Date(this.props.getStringDateTs(Math.floor(new Date().getTime() / 1000), 'MM/DD/YYYY HH:mm'))
                                                                                            newDate.setHours(startTimeSplit[0], startTimeSplit[1], 0, 0)
                                                                                            workingHours[item._id].wednesday.endTime = new Date(this.props.getStringDateInverseTsV1(Math.floor(new Date(newDate).getTime() / 1000), 'MM/DD/YYYY HH:mm'))

                                                                                            this.setState({ workingHours })
                                                                                        }}
                                                                                        allowClear={false}
                                                                                        disabledClear={true}
                                                                                        disabled={!this.state.workingHours[item._id].wednesday.checked}
                                                                                    />
                                                                                    {/* <TimePicker
                                                                                        getStringDateTs={this.props.getStringDateTs}
                                                                                        format={'HH:mm'}
                                                                                        value={moment(this.state.workingHours[item._id] && this.state.workingHours[item._id].wednesday.endTime)}
                                                                                        minValue={this.props.getStringDateTs(Math.floor(this.state.workingHours[item._id] && this.state.workingHours[item._id].wednesday.startTime.getTime() / 1000), 'HH:mm')} //moment.unix(this.state.workingHours[item._id] && this.state.workingHours[item._id].wednesday.startTime.getTime() / 1000).format("HH:mm")
                                                                                        maxValue={"23:59"} //moment.unix(this.state.max.getTime() / 1000).format("HH:mm")
                                                                                        onChange={(time1, timeString) => {

                                                                                            let workingHours = this.state.workingHours;


                                                                                            let startTimeSplit = timeString.split(":");

                                                                                            let newDate = new Date()
                                                                                            newDate.setHours(startTimeSplit[0], startTimeSplit[1], 0, 0)
                                                                                            workingHours[item._id].wednesday.endTime = newDate

                                                                                            this.setState({ workingHours })
                                                                                        }}
                                                                                        flag={0}
                                                                                        disabledClear={true}
                                                                                        disabled={!this.state.workingHours[item._id].wednesday.checked}
                                                                                        allowClear={false}
                                                                                    /> */}
                                                                                </div>
                                                                            </div>

                                                                            <div className="drop-in-working-hours-day">
                                                                                <Checkbox checked={this.state.workingHours[item._id].thursday.checked} onChange={(e) => {
                                                                                    let workingHours = this.state.workingHours;
                                                                                    workingHours[item._id].thursday.checked = !workingHours[item._id].thursday.checked;
                                                                                    this.setState({ workingHours })
                                                                                }} />
                                                                                <h6>{'Thursday'.translate(this.props.lang)}</h6>

                                                                                <div className="drop-in-working-hours-timepicker">
                                                                                    <Label>{'Start time'.translate(this.props.lang)}</Label>
                                                                                    &nbsp;
                                                                                    <TimePickerV2
                                                                                        getStringDateTs={this.props.getStringDateTs}
                                                                                        format={'HH:mm'}
                                                                                        value={this.props.getStringDateTs((new Date(this.state.workingHours[item._id].thursday.startTime).getTime() / 1000), "HH:mm")}
                                                                                        minValue={"00:00"} //moment.unix(this.state.min.getTime() / 1000).format("HH:mm")
                                                                                        maxValue={this.props.getStringDateTs(Math.floor(this.state.workingHours[item._id].thursday.endTime.getTime() / 1000), 'HH:mm')} //moment.unix(this.state.workingHours[item._id].thursday.endTime.getTime() / 1000).format("HH:mm")
                                                                                        flag={0}
                                                                                        onChange={(time1, timeString) => {

                                                                                            let workingHours = this.state.workingHours;


                                                                                            let startTimeSplit = timeString.split(":");

                                                                                            let newDate = new Date(this.props.getStringDateTs(Math.floor(new Date().getTime() / 1000), 'MM/DD/YYYY HH:mm'))
                                                                                            newDate.setHours(startTimeSplit[0], startTimeSplit[1], 0, 0)
                                                                                            workingHours[item._id].thursday.startTime = new Date(this.props.getStringDateInverseTsV1(Math.floor(new Date(newDate).getTime() / 1000), 'MM/DD/YYYY HH:mm'))

                                                                                            this.setState({ workingHours })
                                                                                        }}
                                                                                        allowClear={false}
                                                                                        disabledClear={true}
                                                                                        disabled={!this.state.workingHours[item._id].thursday.checked}
                                                                                    />
                                                                                    {/* <TimePicker
                                                                                        getStringDateTs={this.props.getStringDateTs}
                                                                                        format={'HH:mm'}
                                                                                        value={moment(this.state.workingHours[item._id].thursday.startTime)}
                                                                                        minValue={"00:00"} //moment.unix(this.state.min.getTime() / 1000).format("HH:mm")
                                                                                        maxValue={this.props.getStringDateTs(Math.floor(this.state.workingHours[item._id].thursday.endTime.getTime() / 1000), 'HH:mm')} //moment.unix(this.state.workingHours[item._id].thursday.endTime.getTime() / 1000).format("HH:mm")
                                                                                        flag={0}
                                                                                        onChange={(time1, timeString) => {

                                                                                            let workingHours = this.state.workingHours;


                                                                                            let startTimeSplit = timeString.split(":");

                                                                                            let newDate = new Date()
                                                                                            newDate.setHours(startTimeSplit[0], startTimeSplit[1], 0, 0)
                                                                                            workingHours[item._id].thursday.startTime = newDate

                                                                                            this.setState({ workingHours })
                                                                                        }}
                                                                                        disabledClear={true}
                                                                                        disabled={!this.state.workingHours[item._id].thursday.checked}
                                                                                        allowClear={false}
                                                                                    /> */}
                                                                                </div>


                                                                                <div className="drop-in-working-hours-timepicker">
                                                                                    <Label>{'End time'.translate(this.props.lang)}</Label>
                                                                                    &nbsp;
                                                                                    <TimePickerV2
                                                                                        getStringDateTs={this.props.getStringDateTs}
                                                                                        format={'HH:mm'}
                                                                                        value={this.props.getStringDateTs((new Date(this.state.workingHours[item._id].thursday.endTime).getTime() / 1000), "HH:mm")}
                                                                                        minValue={this.props.getStringDateTs(Math.floor(this.state.workingHours[item._id] && this.state.workingHours[item._id].thursday.startTime.getTime() / 1000), 'HH:mm')} //moment.unix(this.state.workingHours[item._id] && this.state.workingHours[item._id].thursday.startTime.getTime() / 1000).format("HH:mm")
                                                                                        maxValue={"23:59"} //moment.unix(this.state.max.getTime() / 1000).format("HH:mm")
                                                                                        flag={0}
                                                                                        onChange={(time1, timeString) => {

                                                                                            let workingHours = this.state.workingHours;


                                                                                            let startTimeSplit = timeString.split(":");

                                                                                            let newDate = new Date(this.props.getStringDateTs(Math.floor(new Date().getTime() / 1000), 'MM/DD/YYYY HH:mm'))
                                                                                            newDate.setHours(startTimeSplit[0], startTimeSplit[1], 0, 0)
                                                                                            workingHours[item._id].thursday.endTime = new Date(this.props.getStringDateInverseTsV1(Math.floor(new Date(newDate).getTime() / 1000), 'MM/DD/YYYY HH:mm'))

                                                                                            this.setState({ workingHours })
                                                                                        }}
                                                                                        allowClear={false}
                                                                                        disabledClear={true}
                                                                                        disabled={!this.state.workingHours[item._id].thursday.checked}
                                                                                    />
                                                                                    {/* <TimePicker
                                                                                        getStringDateTs={this.props.getStringDateTs}
                                                                                        format={'HH:mm'}
                                                                                        value={moment(this.state.workingHours[item._id] && this.state.workingHours[item._id].thursday.endTime)}
                                                                                        minValue={this.props.getStringDateTs(Math.floor(this.state.workingHours[item._id] && this.state.workingHours[item._id].thursday.startTime.getTime() / 1000), 'HH:mm')} //moment.unix(this.state.workingHours[item._id] && this.state.workingHours[item._id].thursday.startTime.getTime() / 1000).format("HH:mm")
                                                                                        maxValue={"23:59"} //moment.unix(this.state.max.getTime() / 1000).format("HH:mm")
                                                                                        onChange={(time1, timeString) => {

                                                                                            let workingHours = this.state.workingHours;


                                                                                            let startTimeSplit = timeString.split(":");

                                                                                            let newDate = new Date()
                                                                                            newDate.setHours(startTimeSplit[0], startTimeSplit[1], 0, 0)
                                                                                            workingHours[item._id].thursday.endTime = newDate

                                                                                            this.setState({ workingHours })
                                                                                        }}
                                                                                        flag={0}
                                                                                        disabledClear={true}
                                                                                        disabled={!this.state.workingHours[item._id].thursday.checked}
                                                                                        allowClear={false}
                                                                                    /> */}
                                                                                </div>
                                                                            </div>


                                                                            <div className="drop-in-working-hours-day">
                                                                                <Checkbox checked={this.state.workingHours[item._id].friday.checked} onChange={(e) => {
                                                                                    let workingHours = this.state.workingHours;
                                                                                    workingHours[item._id].friday.checked = !workingHours[item._id].friday.checked;
                                                                                    this.setState({ workingHours })
                                                                                }} />
                                                                                <h6>{'Friday'.translate(this.props.lang)}</h6>

                                                                                <div className="drop-in-working-hours-timepicker">
                                                                                    <Label>{'Start time'.translate(this.props.lang)}</Label>
                                                                                    &nbsp;
                                                                                    <TimePickerV2
                                                                                        getStringDateTs={this.props.getStringDateTs}
                                                                                        format={'HH:mm'}
                                                                                        value={this.props.getStringDateTs((new Date(this.state.workingHours[item._id].friday.startTime).getTime() / 1000), "HH:mm")}
                                                                                        minValue={"00:00"} //moment.unix(this.state.min.getTime() / 1000).format("HH:mm")
                                                                                        maxValue={this.props.getStringDateTs(Math.floor(this.state.workingHours[item._id].friday.endTime.getTime() / 1000), 'HH:mm')} //moment.unix(this.state.workingHours[item._id].friday.endTime.getTime() / 1000).format("HH:mm")
                                                                                        flag={0}
                                                                                        onChange={(time1, timeString) => {

                                                                                            let workingHours = this.state.workingHours;


                                                                                            let startTimeSplit = timeString.split(":");

                                                                                            let newDate = new Date(this.props.getStringDateTs(Math.floor(new Date().getTime() / 1000), 'MM/DD/YYYY HH:mm'))
                                                                                            newDate.setHours(startTimeSplit[0], startTimeSplit[1], 0, 0)
                                                                                            workingHours[item._id].friday.startTime = new Date(this.props.getStringDateInverseTsV1(Math.floor(new Date(newDate).getTime() / 1000), 'MM/DD/YYYY HH:mm'))

                                                                                            this.setState({ workingHours })
                                                                                        }}
                                                                                        allowClear={false}
                                                                                        disabledClear={true}
                                                                                        disabled={!this.state.workingHours[item._id].friday.checked}
                                                                                    />
                                                                                    {/* <TimePicker
                                                                                        getStringDateTs={this.props.getStringDateTs}
                                                                                        format={'HH:mm'}
                                                                                        value={moment(this.state.workingHours[item._id].friday.startTime)}
                                                                                        minValue={"00:00"} //moment.unix(this.state.min.getTime() / 1000).format("HH:mm")
                                                                                        maxValue={this.props.getStringDateTs(Math.floor(this.state.workingHours[item._id].friday.endTime.getTime() / 1000), 'HH:mm')} //moment.unix(this.state.workingHours[item._id].friday.endTime.getTime() / 1000).format("HH:mm")
                                                                                        flag={0}
                                                                                        onChange={(time1, timeString) => {

                                                                                            let workingHours = this.state.workingHours;


                                                                                            let startTimeSplit = timeString.split(":");

                                                                                            let newDate = new Date()
                                                                                            newDate.setHours(startTimeSplit[0], startTimeSplit[1], 0, 0)
                                                                                            workingHours[item._id].friday.startTime = newDate

                                                                                            this.setState({ workingHours })
                                                                                        }}
                                                                                        disabledClear={true}
                                                                                        disabled={!this.state.workingHours[item._id].friday.checked}
                                                                                        allowClear={false}
                                                                                    /> */}
                                                                                </div>


                                                                                <div className="drop-in-working-hours-timepicker">
                                                                                    <Label>{'End time'.translate(this.props.lang)}</Label>
                                                                                    &nbsp;
                                                                                    <TimePickerV2
                                                                                        getStringDateTs={this.props.getStringDateTs}
                                                                                        format={'HH:mm'}
                                                                                        value={this.props.getStringDateTs((new Date(this.state.workingHours[item._id].friday.endTime).getTime() / 1000), "HH:mm")}
                                                                                        minValue={this.props.getStringDateTs(Math.floor(this.state.workingHours[item._id] && this.state.workingHours[item._id].friday.startTime.getTime() / 1000), 'HH:mm')} //moment.unix(this.state.workingHours[item._id] && this.state.workingHours[item._id].friday.startTime.getTime() / 1000).format("HH:mm")
                                                                                        maxValue={"23:59"} //moment.unix(this.state.max.getTime() / 1000).format("HH:mm")
                                                                                        flag={0}
                                                                                        onChange={(time1, timeString) => {

                                                                                            let workingHours = this.state.workingHours;


                                                                                            let startTimeSplit = timeString.split(":");

                                                                                            let newDate = new Date(this.props.getStringDateTs(Math.floor(new Date().getTime() / 1000), 'MM/DD/YYYY HH:mm'))
                                                                                            newDate.setHours(startTimeSplit[0], startTimeSplit[1], 0, 0)
                                                                                            workingHours[item._id].friday.endTime = new Date(this.props.getStringDateInverseTsV1(Math.floor(new Date(newDate).getTime() / 1000), 'MM/DD/YYYY HH:mm'))

                                                                                            this.setState({ workingHours })
                                                                                        }}
                                                                                        allowClear={false}
                                                                                        disabledClear={true}
                                                                                        disabled={!this.state.workingHours[item._id].friday.checked}
                                                                                    />
                                                                                    {/* <TimePicker
                                                                                        getStringDateTs={this.props.getStringDateTs}
                                                                                        format={'HH:mm'}
                                                                                        value={moment(this.state.workingHours[item._id] && this.state.workingHours[item._id].friday.endTime)}
                                                                                        minValue={this.props.getStringDateTs(Math.floor(this.state.workingHours[item._id] && this.state.workingHours[item._id].friday.startTime.getTime() / 1000), 'HH:mm')} //moment.unix(this.state.workingHours[item._id] && this.state.workingHours[item._id].friday.startTime.getTime() / 1000).format("HH:mm")
                                                                                        maxValue={"23:59"} //moment.unix(this.state.max.getTime() / 1000).format("HH:mm")
                                                                                        onChange={(time1, timeString) => {

                                                                                            let workingHours = this.state.workingHours;


                                                                                            let startTimeSplit = timeString.split(":");

                                                                                            let newDate = new Date()
                                                                                            newDate.setHours(startTimeSplit[0], startTimeSplit[1], 0, 0)
                                                                                            workingHours[item._id].friday.endTime = newDate

                                                                                            this.setState({ workingHours })
                                                                                        }}
                                                                                        flag={0}
                                                                                        disabledClear={true}
                                                                                        disabled={!this.state.workingHours[item._id].friday.checked}
                                                                                        allowClear={false}
                                                                                    /> */}
                                                                                </div>
                                                                            </div>
                                                                            <div className="drop-in-working-hours-day">
                                                                                <Checkbox checked={this.state.workingHours[item._id].saturday.checked} onChange={(e) => {
                                                                                    let workingHours = this.state.workingHours;
                                                                                    workingHours[item._id].saturday.checked = !workingHours[item._id].saturday.checked;
                                                                                    this.setState({ workingHours })
                                                                                }} />
                                                                                <h6>{'Saturday'.translate(this.props.lang)}</h6>

                                                                                <div className="drop-in-working-hours-timepicker">
                                                                                    <Label>{'Start time'.translate(this.props.lang)}</Label>
                                                                                    &nbsp;
                                                                                    <TimePickerV2
                                                                                        getStringDateTs={this.props.getStringDateTs}
                                                                                        format={'HH:mm'}
                                                                                        value={this.props.getStringDateTs((new Date(this.state.workingHours[item._id].saturday.startTime).getTime() / 1000), "HH:mm")}
                                                                                        minValue={"00:00"} //moment.unix(this.state.min.getTime() / 1000).format("HH:mm")
                                                                                        maxValue={this.props.getStringDateTs(Math.floor(this.state.workingHours[item._id].saturday.endTime.getTime() / 1000), 'HH:mm')} //moment.unix(this.state.workingHours[item._id].saturday.endTime.getTime() / 1000).format("HH:mm")
                                                                                        flag={0}
                                                                                        onChange={(time1, timeString) => {

                                                                                            let workingHours = this.state.workingHours;


                                                                                            let startTimeSplit = timeString.split(":");

                                                                                            let newDate = new Date(this.props.getStringDateTs(Math.floor(new Date().getTime() / 1000), 'MM/DD/YYYY HH:mm'))
                                                                                            newDate.setHours(startTimeSplit[0], startTimeSplit[1], 0, 0)
                                                                                            workingHours[item._id].saturday.startTime = new Date(this.props.getStringDateInverseTsV1(Math.floor(new Date(newDate).getTime() / 1000), 'MM/DD/YYYY HH:mm'))

                                                                                            this.setState({ workingHours })
                                                                                        }}
                                                                                        allowClear={false}
                                                                                        disabledClear={true}
                                                                                        disabled={!this.state.workingHours[item._id].saturday.checked}
                                                                                    />
                                                                                    {/* <TimePicker
                                                                                        getStringDateTs={this.props.getStringDateTs}
                                                                                        format={'HH:mm'}
                                                                                        value={moment(this.state.workingHours[item._id].saturday.startTime)}
                                                                                        minValue={"00:00"} //moment.unix(this.state.min.getTime() / 1000).format("HH:mm")
                                                                                        maxValue={this.props.getStringDateTs(Math.floor(this.state.workingHours[item._id].saturday.endTime.getTime() / 1000), 'HH:mm')} //moment.unix(this.state.workingHours[item._id].saturday.endTime.getTime() / 1000).format("HH:mm")
                                                                                        flag={0}
                                                                                        onChange={(time1, timeString) => {

                                                                                            let workingHours = this.state.workingHours;


                                                                                            let startTimeSplit = timeString.split(":");

                                                                                            let newDate = new Date()
                                                                                            newDate.setHours(startTimeSplit[0], startTimeSplit[1], 0, 0)
                                                                                            workingHours[item._id].saturday.startTime = newDate

                                                                                            this.setState({ workingHours })
                                                                                        }}
                                                                                        disabledClear={true}
                                                                                        disabled={!this.state.workingHours[item._id].saturday.checked}
                                                                                        allowClear={false}
                                                                                    /> */}
                                                                                </div>


                                                                                <div className="drop-in-working-hours-timepicker">
                                                                                    <Label>{'End time'.translate(this.props.lang)}</Label>
                                                                                    &nbsp;
                                                                                    <TimePickerV2
                                                                                        getStringDateTs={this.props.getStringDateTs}
                                                                                        format={'HH:mm'}
                                                                                        value={this.props.getStringDateTs((new Date(this.state.workingHours[item._id].saturday.endTime).getTime() / 1000), "HH:mm")}
                                                                                        minValue={this.props.getStringDateTs(Math.floor(this.state.workingHours[item._id] && this.state.workingHours[item._id].saturday.startTime.getTime() / 1000), 'HH:mm')} //moment.unix(this.state.workingHours[item._id] && this.state.workingHours[item._id].saturday.startTime.getTime() / 1000).format("HH:mm")
                                                                                        maxValue={"23:59"} //moment.unix(this.state.max.getTime() / 1000).format("HH:mm")
                                                                                        flag={0}
                                                                                        onChange={(time1, timeString) => {

                                                                                            let workingHours = this.state.workingHours;


                                                                                            let startTimeSplit = timeString.split(":");

                                                                                            let newDate = new Date(this.props.getStringDateTs(Math.floor(new Date().getTime() / 1000), 'MM/DD/YYYY HH:mm'))
                                                                                            newDate.setHours(startTimeSplit[0], startTimeSplit[1], 0, 0)
                                                                                            workingHours[item._id].saturday.endTime = new Date(this.props.getStringDateInverseTsV1(Math.floor(new Date(newDate).getTime() / 1000), 'MM/DD/YYYY HH:mm'))

                                                                                            this.setState({ workingHours })
                                                                                        }}
                                                                                        allowClear={false}
                                                                                        disabledClear={true}
                                                                                        disabled={!this.state.workingHours[item._id].saturday.checked}
                                                                                    />
                                                                                    {/* <TimePicker
                                                                                        getStringDateTs={this.props.getStringDateTs}
                                                                                        format={'HH:mm'}
                                                                                        value={moment(this.state.workingHours[item._id] && this.state.workingHours[item._id].saturday.endTime)}
                                                                                        minValue={this.props.getStringDateTs(Math.floor(this.state.workingHours[item._id] && this.state.workingHours[item._id].saturday.startTime.getTime() / 1000), 'HH:mm')} //moment.unix(this.state.workingHours[item._id] && this.state.workingHours[item._id].saturday.startTime.getTime() / 1000).format("HH:mm")
                                                                                        maxValue={"23:59"} //moment.unix(this.state.max.getTime() / 1000).format("HH:mm")
                                                                                        onChange={(time1, timeString) => {

                                                                                            let workingHours = this.state.workingHours;


                                                                                            let startTimeSplit = timeString.split(":");

                                                                                            let newDate = new Date()
                                                                                            newDate.setHours(startTimeSplit[0], startTimeSplit[1], 0, 0)
                                                                                            workingHours[item._id].saturday.endTime = newDate

                                                                                            this.setState({ workingHours })
                                                                                        }}
                                                                                        flag={0}
                                                                                        disabledClear={true}
                                                                                        disabled={!this.state.workingHours[item._id].saturday.checked}
                                                                                        allowClear={false}
                                                                                    /> */}
                                                                                </div>
                                                                            </div>
                                                                            <div className="drop-in-working-hours-day">
                                                                                <Checkbox checked={this.state.workingHours[item._id].sunday.checked} onChange={(e) => {
                                                                                    let workingHours = this.state.workingHours;
                                                                                    workingHours[item._id].sunday.checked = !workingHours[item._id].sunday.checked;
                                                                                    this.setState({ workingHours })
                                                                                }} />
                                                                                <h6>{'Sunday'.translate(this.props.lang)}</h6>

                                                                                <div className="drop-in-working-hours-timepicker">
                                                                                    <Label>{'Start time'.translate(this.props.lang)}</Label>
                                                                                    &nbsp;
                                                                                    <TimePickerV2
                                                                                        getStringDateTs={this.props.getStringDateTs}
                                                                                        format={'HH:mm'}
                                                                                        value={this.props.getStringDateTs((new Date(this.state.workingHours[item._id].sunday.startTime).getTime() / 1000), "HH:mm")}
                                                                                        minValue={"00:00"} //moment.unix(this.state.min.getTime() / 1000).format("HH:mm")
                                                                                        maxValue={this.props.getStringDateTs(Math.floor(this.state.workingHours[item._id].sunday.endTime.getTime() / 1000), 'HH:mm')} //moment.unix(this.state.workingHours[item._id].sunday.endTime.getTime() / 1000).format("HH:mm")
                                                                                        flag={0}
                                                                                        onChange={(time1, timeString) => {

                                                                                            let workingHours = this.state.workingHours;


                                                                                            let startTimeSplit = timeString.split(":");

                                                                                            let newDate = new Date(this.props.getStringDateTs(Math.floor(new Date().getTime() / 1000), 'MM/DD/YYYY HH:mm'))
                                                                                            newDate.setHours(startTimeSplit[0], startTimeSplit[1], 0, 0)
                                                                                            workingHours[item._id].sunday.startTime = new Date(this.props.getStringDateInverseTsV1(Math.floor(new Date(newDate).getTime() / 1000), 'MM/DD/YYYY HH:mm'))

                                                                                            this.setState({ workingHours })
                                                                                        }}
                                                                                        allowClear={false}
                                                                                        disabledClear={true}
                                                                                        disabled={!this.state.workingHours[item._id].sunday.checked}
                                                                                    />
                                                                                    {/* <TimePicker
                                                                                        getStringDateTs={this.props.getStringDateTs}
                                                                                        format={'HH:mm'}
                                                                                        value={moment(this.state.workingHours[item._id].sunday.startTime)}
                                                                                        minValue={"00:00"} //moment.unix(this.state.min.getTime() / 1000).format("HH:mm")
                                                                                        maxValue={this.props.getStringDateTs(Math.floor(this.state.workingHours[item._id].sunday.endTime.getTime() / 1000), 'HH:mm')} //moment.unix(this.state.workingHours[item._id].sunday.endTime.getTime() / 1000).format("HH:mm")
                                                                                        flag={0}
                                                                                        onChange={(time1, timeString) => {

                                                                                            let workingHours = this.state.workingHours;


                                                                                            let startTimeSplit = timeString.split(":");

                                                                                            let newDate = new Date()
                                                                                            newDate.setHours(startTimeSplit[0], startTimeSplit[1], 0, 0)
                                                                                            workingHours[item._id].sunday.startTime = newDate

                                                                                            this.setState({ workingHours })
                                                                                        }}
                                                                                        disabledClear={true}
                                                                                        disabled={!this.state.workingHours[item._id].sunday.checked}
                                                                                        allowClear={false}
                                                                                    /> */}
                                                                                </div>


                                                                                <div className="drop-in-working-hours-timepicker">
                                                                                    <Label>{'End time'.translate(this.props.lang)}</Label>
                                                                                    &nbsp;
                                                                                    <TimePickerV2
                                                                                        getStringDateTs={this.props.getStringDateTs}
                                                                                        format={'HH:mm'}
                                                                                        value={this.props.getStringDateTs((new Date(this.state.workingHours[item._id].sunday.endTime).getTime() / 1000), "HH:mm")}
                                                                                        minValue={this.props.getStringDateTs(Math.floor(this.state.workingHours[item._id] && this.state.workingHours[item._id].sunday.startTime.getTime() / 1000), 'HH:mm')} //moment.unix(this.state.workingHours[item._id] && this.state.workingHours[item._id].sunday.startTime.getTime() / 1000).format("HH:mm")
                                                                                        maxValue={"23:59"} //moment.unix(this.state.max.getTime() / 1000).format("HH:mm")
                                                                                        flag={0}
                                                                                        onChange={(time1, timeString) => {

                                                                                            let workingHours = this.state.workingHours;


                                                                                            let startTimeSplit = timeString.split(":");

                                                                                            let newDate = new Date(this.props.getStringDateTs(Math.floor(new Date().getTime() / 1000), 'MM/DD/YYYY HH:mm'))
                                                                                            newDate.setHours(startTimeSplit[0], startTimeSplit[1], 0, 0)
                                                                                            workingHours[item._id].sunday.endTime = new Date(this.props.getStringDateInverseTsV1(Math.floor(new Date(newDate).getTime() / 1000), 'MM/DD/YYYY HH:mm'))

                                                                                            this.setState({ workingHours })
                                                                                        }}
                                                                                        allowClear={false}
                                                                                        disabledClear={true}
                                                                                        disabled={!this.state.workingHours[item._id].sunday.checked}
                                                                                    />
                                                                                    {/* <TimePicker
                                                                                        getStringDateTs={this.props.getStringDateTs}
                                                                                        format={'HH:mm'}
                                                                                        value={moment(this.state.workingHours[item._id] && this.state.workingHours[item._id].sunday.endTime)}
                                                                                        minValue={this.props.getStringDateTs(Math.floor(this.state.workingHours[item._id] && this.state.workingHours[item._id].sunday.startTime.getTime() / 1000), 'HH:mm')} //moment.unix(this.state.workingHours[item._id] && this.state.workingHours[item._id].sunday.startTime.getTime() / 1000).format("HH:mm")
                                                                                        maxValue={"23:59"} //moment.unix(this.state.max.getTime() / 1000).format("HH:mm")
                                                                                        onChange={(time1, timeString) => {

                                                                                            let workingHours = this.state.workingHours;


                                                                                            let startTimeSplit = timeString.split(":");

                                                                                            let newDate = new Date()
                                                                                            newDate.setHours(startTimeSplit[0], startTimeSplit[1], 0, 0)
                                                                                            workingHours[item._id].sunday.endTime = newDate

                                                                                            this.setState({ workingHours })
                                                                                        }}
                                                                                        flag={0}
                                                                                        disabledClear={true}
                                                                                        disabled={!this.state.workingHours[item._id].sunday.checked}
                                                                                        allowClear={false}
                                                                                    /> */}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                                :
                                                                null


                                                        )
                                                })

                                                :
                                                null
                                        }
                                    </Col>
                                    <Col lg='5'>
                                        <div style={{ border: '1px solid #568AC8', padding: 5, borderRadius: 5, marginTop: 9 }}>
                                            <p style={{ marginBottom: 0, lineHeight: '1.2' }}>{'Please estimate the approximate time it takes to manage one drop-in case. The system will calculate the number of cases waiting to be managed and summarize the waiting time when the patient logs in.'.translate(this.props.lang)}</p>
                                        </div>

                                        <div style={{ display: 'flex', alignItems: 'center', marginTop: 40 }}>
                                            <Label style={{ fontWeight: 600, fontSize: 17, marginBottom: 0 }}>{'Submit time per patient case (minutes):'.translate(this.props.lang)}</Label>
                                            <div>
                                                {/* {this.state.checkEstimateTime ? */}
                                                <span className='info-wrap'>
                                                    <Isvg src={infoIcon} />
                                                    <div className='info-tooltip'>{'The system does not present the estimated waiting time for the patient when logging in.'.translate(this.props.lang)}</div>
                                                </span>
                                                {/* : null} */}
                                            </div>

                                            <Input style={/*this.state.checkEstimateTime ? */{ width: '27%', marginLeft: 10 }/* : { width: '33%', marginLeft: 10 }*/} type='text' value={this.state.workingHours && this.state.workingHours[this.props.selectedClinic] ? this.state.workingHours[this.props.selectedClinic].patientTime : this.state.defaultWaitingTime} onChange={(e) => {
                                                let workingHours = this.state.workingHours;
                                                workingHours[this.props.selectedClinic].patientTime = e.target.value
                                                this.setState({ workingHours }, () => {
                                                    if (this.state.workingHours && this.state.workingHours[this.props.selectedClinic].patientTime == 0) {
                                                        this.setState({
                                                            checkEstimateTime: true
                                                        })
                                                    } else {
                                                        this.setState({
                                                            checkEstimateTime: false
                                                        })
                                                    }

                                                })
                                            }} />

                                        </div>


                                    </Col>
                                    <Col lg='12'>
                                        {
                                            this.state.workingHours ?
                                                <Button color="primary" onClick={() => this.update()}>

                                                    {
                                                        this.state.showPlayer ? (
                                                            <Player
                                                                onEvent={(event) => {
                                                                    if (event === "load") this.stopAnimation();
                                                                }}
                                                                onStateChange={this.toggleVisibility}
                                                                ref={this.player} // set the ref to your class instance
                                                                autoplay={true}
                                                                loop={true}
                                                                controls={true}
                                                                src="https://assets8.lottiefiles.com/packages/lf20_ZSRKCF.json"
                                                                style={{ height: "30px", width: "30px" }}
                                                            ></Player>

                                                        ) : this.state.showPlayer2 ? (
                                                            <div style={{ display: 'flex', alignItems: 'center' }}><Player
                                                                onStateChange={this.toggleVisibility}
                                                                ref={this.player2} // set the ref to your class instance
                                                                autoplay={true}
                                                                loop={true}
                                                                controls={true}
                                                                src="https://assets10.lottiefiles.com/packages/lf20_CxyxLk.json"
                                                                style={{ height: "30px", width: "30px" }}
                                                            ></Player>
                                                                {'Saved'.translate(this.props.lang)}
                                                            </div>
                                                        ) : (
                                                            "Save".translate(this.props.lang)
                                                        )
                                                    }

                                                </Button>
                                                :
                                                null
                                        }

                                    </Col>





                                </Row>


                            </div>

                        </Col>
                    </Row>

                </Container>


            </div>
        );
    }
}
const selector = formValueSelector('form');

export default connect(state => {
    return {
        check: selector(state, 'landingConfig.informationLink')

    }

})(Page(ClinicData));


