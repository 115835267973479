import { API_ENDPOINT } from "../../../constants";

export const generateSections = (data) => {
    let sections = {};
    let items = [{ type: 0 }, ...data];
    let lastSectionId = -1;
    for (let i = 0; i < items.length; i++) {
        if (items[i].type == 0) {
            lastSectionId++;
            sections[lastSectionId] = {
                name: items[i].name,
                displayOnReferral: false,
                questions: [],
                conditions: items[i].conditions ? items[i].conditions : [],
                displayOnReferral: items[i].displayOnReferral,
                fontStyle: items[i].fontStyle,
                fontSize: items[i].fontSize,
                fontWeight: items[i].fontWeight,
                required: items[i].required,

            }
        }
        if (lastSectionId != -1 && items[i].type != 0) {


            let wikiLinks = [];

            items[i].wikiLinks = wikiLinks;


            if (items[i].type == 1) {
                let displayOnReferral = false;
                if (items[i].answers) {
                    for (let j = 0; j < items[i].answers.length; j++) {
                        if (items[i].answers[j].text) {
                            displayOnReferral = true;
                        }
                    }
                }

                sections[lastSectionId].questions.push({
                    id: items[i].id,
                    type: items[i].type,
                    name: items[i].name,
                    answers: items[i].answers,
                    conditions: items[i].conditions ? items[i].conditions : [],
                    displayOnReferral: displayOnReferral,
                    wikiLinks: items[i].wikiLinks,
                    fontStyle: items[i].fontStyle,
                    fontSize: items[i].fontSize,
                    fontWeight: items[i].fontWeight,
                    required: items[i].required,
                });

            } else if (items[i].type == 2) {
                let displayOnReferral = false;
                if (items[i].answers) {
                    for (let j = 0; j < items[i].answers.length; j++) {

                        if (items[i].answers[j].text) {
                            displayOnReferral = true;
                        }
                    }
                }
                sections[lastSectionId].questions.push({
                    id: items[i].id,
                    type: items[i].type,
                    name: items[i].name,
                    answers: items[i].answers,
                    conditions: items[i].conditions ? items[i].conditions : [],
                    answerPrefix: items[i].answerPrefix,
                    userInput: items[i].userInput,
                    displayOnReferral: displayOnReferral || items[i].answerPrefix ? true : false,
                    wikiLinks: items[i].wikiLinks,
                    fontStyle: items[i].fontStyle,
                    fontSize: items[i].fontSize,
                    fontWeight: items[i].fontWeight,
                    required: items[i].required,

                })
            } else if (items[i].type == 3) {
                sections[lastSectionId].questions.push({
                    id: items[i].id,
                    type: items[i].type,
                    name: items[i].name,
                    conditions: items[i].conditions ? items[i].conditions : [],
                    answerPrefix: items[i].answerPrefix,
                    wikiLinks: items[i].wikiLinks,
                    displayOnReferral: true,
                    fontStyle: items[i].fontStyle,
                    fontSize: items[i].fontSize,
                    fontWeight: items[i].fontWeight,
                    required: items[i].required,
                })

            } else if (items[i].type == 4) {
                sections[lastSectionId].questions.push({
                    id: items[i].id,
                    type: items[i].type,
                    name: items[i].name,
                    text: items[i].text,
                    userInput: items[i].userInput,
                    conditions: items[i].conditions ? items[i].conditions : [],
                    wikiLinks: items[i].wikiLinks,
                    fontStyle: items[i].fontStyle,
                    fontSize: items[i].fontSize,
                    fontWeight: items[i].fontWeight,
                    required: items[i].required,
                })
            } else if (items[i].type == 15) {
                sections[lastSectionId].questions.push({
                    id: items[i].id,
                    type: items[i].type,
                    name: items[i].name,
                    text: items[i].text,
                    userInput: items[i].userInput,
                    conditions: items[i].conditions ? items[i].conditions : [],
                    wikiLinks: items[i].wikiLinks,
                    fontStyle: items[i].fontStyle,
                    fontSize: items[i].fontSize,
                    fontWeight: items[i].fontWeight,
                    required: items[i].required,
                })

            } else if (items[i].type == 5) {
                sections[lastSectionId].questions.push({
                    id: items[i].id,
                    type: items[i].type,
                    name: items[i].name,
                    conditions: items[i].conditions ? items[i].conditions : [],
                    wikiLinks: items[i].wikiLinks,
                    displayOnReferral: true,
                    fontStyle: items[i].fontStyle,
                    fontSize: items[i].fontSize,
                    fontWeight: items[i].fontWeight,
                    required: items[i].required,
                    score: items[i].score
                })

            } else if (items[i].type == 6) {
                sections[lastSectionId].questions.push({
                    id: items[i].id,
                    type: items[i].type,
                    name: items[i].name,
                    conditions: items[i].conditions ? items[i].conditions : [],
                    wikiLinks: items[i].wikiLinks,
                    displayOnReferral: true,
                    fontStyle: items[i].fontStyle,
                    fontSize: items[i].fontSize,
                    fontWeight: items[i].fontWeight,
                    required: items[i].required,
                    score: items[i].score
                })

            } else if (items[i].type == 7) {
                sections[lastSectionId].questions.push({
                    id: items[i].id,
                    type: items[i].type,
                    name: items[i].name,
                    conditions: items[i].conditions ? items[i].conditions : [],
                    wikiLinks: items[i].wikiLinks,
                    displayOnReferral: true,
                    fontStyle: items[i].fontStyle,
                    fontSize: items[i].fontSize,
                    fontWeight: items[i].fontWeight,
                    required: items[i].required,
                })

            } else if (items[i].type == 8) {
                sections[lastSectionId].questions.push({
                    id: items[i].id,
                    type: items[i].type,
                    name: items[i].name,
                    conditions: items[i].conditions ? items[i].conditions : [],
                    wikiLinks: items[i].wikiLinks,
                    displayOnReferral: true,
                    fontStyle: items[i].fontStyle,
                    fontSize: items[i].fontSize,
                    fontWeight: items[i].fontWeight,
                    required: items[i].required,
                })

            } else if (items[i].type == 9) {
                sections[lastSectionId].questions.push({
                    id: items[i].id,
                    type: items[i].type,
                    name: items[i].name,
                    conditions: items[i].conditions ? items[i].conditions : [],
                    wikiLinks: items[i].wikiLinks,
                    displayOnReferral: true,
                    fontStyle: items[i].fontStyle,
                    fontSize: items[i].fontSize,
                    fontWeight: items[i].fontWeight,
                    required: items[i].required,
                })

            } else if (items[i].type == 10) {
                sections[lastSectionId].questions.push({
                    id: items[i].id,
                    type: items[i].type,
                    name: items[i].name,
                    userInput: items[i].userInput,
                    conditions: items[i].conditions ? items[i].conditions : [],
                    wikiLinks: items[i].wikiLinks,
                    displayOnReferral: true,
                    fontStyle: items[i].fontStyle,
                    fontSize: items[i].fontSize,
                    fontWeight: items[i].fontWeight,
                    required: items[i].required,
                })
            } else if (items[i].type == 12) {
                sections[lastSectionId].questions.push({
                    id: items[i].id,
                    type: items[i].type,
                    name: items[i].name,
                    conditions: items[i].conditions ? items[i].conditions : [],
                    wikiLinks: items[i].wikiLinks,
                    displayOnReferral: true,
                    fontStyle: items[i].fontStyle,
                    fontSize: items[i].fontSize,
                    fontWeight: items[i].fontWeight,
                    required: items[i].required,
                    score: items[i].score
                })
            } else if (items[i].type == 14) {
                sections[lastSectionId].questions.push({
                    id: items[i].id,
                    type: items[i].type,
                    name: items[i].name,
                    conditions: items[i].conditions ? items[i].conditions : [],
                    wikiLinks: items[i].wikiLinks,
                    displayOnReferral: true,
                    fontStyle: items[i].fontStyle,
                    fontSize: items[i].fontSize,
                    fontWeight: items[i].fontWeight,
                    required: items[i].required,
                    score: items[i].score
                })

            } else if (items[i].type == 13) {
                sections[lastSectionId].questions.push({
                    id: items[i].id,
                    type: items[i].type,
                    name: items[i].name,
                    conditions: items[i].conditions ? items[i].conditions : [],
                    wikiLinks: items[i].wikiLinks,
                    displayOnReferral: true,
                    fontStyle: items[i].fontStyle,
                    fontSize: items[i].fontSize,
                    fontWeight: items[i].fontWeight,
                    required: items[i].required,
                    score: items[i].score
                })

            } else if (items[i].type == 16) {
                sections[lastSectionId].questions.push({
                    id: items[i].id,
                    type: items[i].type,
                    name: items[i].name,
                    conditions: items[i].conditions ? items[i].conditions : [],
                    wikiLinks: items[i].wikiLinks,
                    displayOnReferral: true,
                    fontStyle: items[i].fontStyle,
                    fontSize: items[i].fontSize,
                    fontWeight: items[i].fontWeight,
                    required: items[i].required,
                    blocks: items[i].blocks,
                    required: items[i].required
                })

            } else if (items[i].type == 17) {
                sections[lastSectionId].questions.push({
                    id: items[i].id,
                    type: items[i].type,
                    name: items[i].name,
                    conditions: items[i].conditions ? items[i].conditions : [],
                    wikiLinks: items[i].wikiLinks,
                    displayOnReferral: true,
                    fontStyle: items[i].fontStyle,
                    fontSize: items[i].fontSize,
                    fontWeight: items[i].fontWeight,
                    required: items[i].required,
                    blocks: items[i].blocks,
                    required: items[i].required

                })

            } else if (items[i].type == 18) {
                sections[lastSectionId].questions.push({
                    id: items[i].id,
                    type: items[i].type,
                    name: items[i].name,
                    conditions: items[i].conditions ? items[i].conditions : [],
                    wikiLinks: items[i].wikiLinks,
                    displayOnReferral: true,
                    fontStyle: items[i].fontStyle,
                    fontSize: items[i].fontSize,
                    fontWeight: items[i].fontWeight,
                    required: items[i].required,
                    answers: items[i].answers,
                    required: items[i].required

                })

            } else if (items[i].type == 19) {
                sections[lastSectionId].questions.push({
                    id: items[i].id,
                    type: items[i].type,
                    name: items[i].name,
                    conditions: items[i].conditions ? items[i].conditions : [],
                    answerPrefix: items[i].answerPrefix,
                    wikiLinks: items[i].wikiLinks,
                    displayOnReferral: true,
                    fontStyle: items[i].fontStyle,
                    fontSize: items[i].fontSize,
                    fontWeight: items[i].fontWeight,
                    required: items[i].required,
                    graphType: items[i].graphType
                })

            }
        }
    }

    return Object.values(sections);
}

function checkQuestionAnswered(answer, questionType, questionId, question18Finished) {

    let answerExist = false;
    if (!answer || !questionType) {
        return answerExist;
    }
    if (answer && (questionType === 1 || questionType === 3 || questionType === 14 || questionType === 16 || questionType === 17 || questionType === 19)) {
        answerExist = true;
    } else if (answer && answer.length && answer[0] && (questionType === 2 || questionType === 5 || questionType === 8 || questionType === 9 || questionType === 13)) {
        answerExist = true;
    } else if (answer && answer[0] && answer[1] && questionType === 6) {
        answerExist = true;
    } else if (answer && answer[0] && answer[1] && answer[2] && (questionType === 12)) {
        answerExist = true;
    } else if (questionType === 18) {
        if (question18Finished && question18Finished && question18Finished[questionId] && answer) {
            answerExist = true;
        }
    }

    return answerExist;
}
function getAnswer(id, sections = [], answers) {

    for (let i = 0; i < sections.length; i++) {
        if (sections[i].questions) {
            for (let j = 0; j < sections[i].questions?.length; j++) {
                if (sections[i].questions[j].answers) {
                    for (let k = 0; k < sections[i].questions[j].answers.length; k++) {
                        if (sections[i].questions[j].answers[k].id == id) {
                            return sections[i].questions[j].answers[k];
                        }
                    }
                }
                if (sections[i].questions[j].blocks) {
                    for (let k = 0; k < sections[i].questions[j].blocks.length; k++) {
                        if (sections[i].questions[j].blocks[k].id == id) {
                            return sections[i].questions[j].blocks[k];
                        }
                    }
                }
            }
        }
    }


    return {};
}
function getQuestion(id, sections = []) {
    for (let i = 0; i < sections.length; i++) {
        if (sections[i].questions) {
            for (let j = 0; j < sections[i].questions?.length; j++) {
                if (sections[i].questions[j].id == id) {
                    return sections[i].questions[j];
                }
            }
        }
    }


    return {};
}
function indexOfIndexOf(arr, str) {
    for (let i = 0; i < arr.length; i++) {
        let idx = arr[i].indexOf(str);
        if (idx !== -1) {
            return i;
        }

    }

    return -1;
}
function checkPoints(sections, answers) {
    let totalSubtotal = [];
    if (sections && sections.length) {
        for (let i = 0; i < sections.length; i++) {
            if (sections[i].questions && sections[i].questions?.length) {
                for (let j = 0; j < sections[i].questions?.length; j++) {
                    if (sections[i].questions[j].type == 16 || sections[i].questions[j].type == 17) {
                        totalSubtotal.push({
                            type: sections[i].questions[j].type,
                            id: sections[i].questions[j].id,
                            conditions: sections[i].questions[j].conditions,
                            sum: 0
                        })
                    }
                }
            }
        }
    }
    if (totalSubtotal && totalSubtotal.length && answers) {
        let sumSubtotal = 0;
        let sumTotal = 0;
        for (let i = 0; i < totalSubtotal.length; i++) {
            totalSubtotal[i].sum = 0;
        }

        let questionsArr = [];
        for (let i = 0; i < sections.length; i++) {
            for (let j = 0; j < sections[i].questions?.length; j++) {
                if (answers[sections[i].questions[j].id]) {
                    questionsArr.push(sections[i].questions[j].id)
                }
            }
        }

        for (let questionIdx = 0; questionIdx < questionsArr.length; questionIdx++) {
            let key = questionsArr[questionIdx];
            let question = getQuestion(key, sections)
            if (question) {
                if (question.type == 16) {
                    let index = totalSubtotal.findIndex(el => el.id == question.id)
                    if (index > -1) {
                        totalSubtotal[index].sum = sumSubtotal;
                        if (question.blocks && question.blocks.length) {
                            let blocks = question.blocks;
                            for (let i = 0; i < blocks.length; i++) {
                                let start = 0;
                                let end = 0;
                                if (blocks[i].start) {
                                    start = Number(blocks[i].start)
                                }
                                if (blocks[i].end) {
                                    end = Number(blocks[i].end)
                                }


                            }
                        }
                        sumSubtotal = 0;
                    }

                }
                if (question.type == 17) {
                    let index = totalSubtotal.findIndex(el => el.id == question.id)
                    if (index > -1) {

                        totalSubtotal[index].sum = sumTotal;
                        if (question.blocks && question.blocks.length) {
                            let blocks = question.blocks;
                            for (let i = 0; i < blocks.length; i++) {
                                let start = 0;
                                let end = 0;
                                if (blocks[i].start) {
                                    start = Number(blocks[i].start)
                                }
                                if (blocks[i].end) {
                                    end = Number(blocks[i].end)
                                }


                            }
                        }
                        sumTotal = 0;
                    }
                }

                if (question.type == 1 && question.answers && question.answers.filter(el => el.id == answers[key]).length && question.answers.filter(el => el.id == answers[key])[0].score) {
                    let score = question.answers.filter(el => el.id == answers[key])[0].score;
                    sumSubtotal += score;
                    sumTotal += score;
                }

                if (question.type == 2 && question.answers && answers[key] && answers[key].length) {
                    for (let i = 0; i < answers[key].length; i++) {
                        if (question.answers.filter(el => el.id == answers[key][i]).length && question.answers.filter(el => el.id == answers[key][i])[0].score) {
                            let score = question.answers.filter(el => el.id == answers[key][i])[0].score;
                            sumSubtotal += score;
                            sumTotal += score;
                        }
                    }
                }
                if ((question.type == 5 || question.type == 6 || question.type == 12 || question.type == 13 || question.type == 14) && question.score) {
                    let score = question.score;
                    sumSubtotal += score;
                    sumTotal += score;
                }
            }
        }



    }
    let response = {}
    if (totalSubtotal && totalSubtotal.length) {
        for (let i = 0; i < totalSubtotal.length; i++) {
            if (totalSubtotal[i].id) {
                response[totalSubtotal[i].id] = totalSubtotal[i].sum ? totalSubtotal[i].sum : 0;
            }
        }
    }
    return response;
}

function checkSectionCondition(sIdx, sections, answers) {
    let returnValue = false;

    if (sections && sections.length && sections[sIdx]) {
        let questions = sections[sIdx].questions ? sections[sIdx].questions : [];
        for (let i = 0; i < questions.length; i++) {
            if (questions[i].id && answers[questions[i].id]) {
                returnValue = true;
                break;
            }
        }

    }


    return returnValue;
}
function checkQuestionCondition(question, answers, sections) {
    if (!question) {
        return;
    }

    if (!question.conditions) {
        return true;
    }

    if (!question.conditions.length) {
        return true;
    }


    for (let i = 0; i < question.conditions.length; i++) {
        if (!question.conditions[i].conditionQuestion) {
            continue;
        }


        let conditionQuestion = getQuestion(question.conditions[i].conditionQuestion, sections);
        if (!conditionQuestion) {
            continue;
        }

        if (conditionQuestion.type == 1) {
            if (answers[conditionQuestion.id] == question.conditions[i].conditionAnswer) {
                return true;
            }
        } else if (conditionQuestion.type == 2) {
            if (question.conditions[i].conditionAnswer == '__user-input:' && answers[conditionQuestion.id]) {
                let index = indexOfIndexOf(answers[conditionQuestion.id], '__user-input:');
                if (index != -1 && answers[conditionQuestion.id][index] && answers[conditionQuestion.id][index].replace('__user-input:', '')) {
                    return true;
                }
            } else {

                if (answers[conditionQuestion.id] && answers[conditionQuestion.id].indexOf(question.conditions[i].conditionAnswer) !== -1) {
                    return true;
                }
            }

        } else if (conditionQuestion.type == 18) {

            if (answers[conditionQuestion.id]) {
                return true;
            }

        } else if (conditionQuestion.type == 19 || conditionQuestion.type == 3 || conditionQuestion.type == 5 || conditionQuestion.type == 6 || conditionQuestion.type == 9 || conditionQuestion.type == 12 || conditionQuestion.type == 13 || conditionQuestion.type == 14) {
            if (answers[conditionQuestion.id]) {
                return true;
            }

        } else if (conditionQuestion.type == 10) {
            return true;

        } else if (conditionQuestion.type == 15) {
            return true;
        } else if (conditionQuestion.type == 16 || conditionQuestion.type == 17) {
            if (answers[conditionQuestion.id] == question.conditions[i].conditionAnswer) {
                return true;
            }
        }
    }
    return false;
}


export const generateHtml = (referral, lang, totalSubtotalId = null, addTitle = false, totalSubtotalInPreview = false) => {
    let html = '';
    let htmlArr = []
    if (referral) {

        let sections = [];
        let answers = {};
        let question18Finished = {};
        let intelligentReferral = referral.intelligentReferral ? true : false;
        if (referral.sections) {
            sections = referral.sections;
        }
        if (referral.answers) {
            answers = referral.answers;
        }
        if (referral.question18Finished) {
            question18Finished = referral.question18Finished;
        }
        let totalSubtotal = checkPoints(sections, answers)
        if (addTitle) {
            html += "<p><strong>Patient svar</strong></p>\n\n"
        }
        let totalSubtotalIdQuestion;
        if (totalSubtotalId) {
            totalSubtotalIdQuestion = getQuestion(totalSubtotalId, sections)
        }
        let empty = true;
        for (let i = 0; i < sections.length; i++) {
            if (totalSubtotalId) {
                if (totalSubtotalIdQuestion?.type !== 17)
                    html = '';
            }

            if (sections[i].displayOnReferral && checkSectionCondition(i, sections, answers)) {

                if (i > 0 && !empty) {
                    html += '<br/><br/>';
                }
                html += `<p><strong>${sections[i].name}</strong></p>\n`;

                if (empty) empty = false;
            }
            let prevQuestionType;
            for (let j = 0; j < sections[i].questions.length; j++) {


                let question = sections[i].questions[j];
                let answer = answers[question?.id];



                if (question.type === 10 /*&& prevQuestionType !== 10*/) {
                    if (checkQuestionCondition(question, answers, sections)) {
                        if (question.userInput) {
                            html += '<br/><br/>'
                            if (empty) empty = false;
                        } else {
                            html += '<br/>'
                            if (empty) empty = false;
                        }

                        prevQuestionType = question.type;
                    }

                }
                if (checkQuestionAnswered(answer, question.type, question.id, question18Finished)) {
                    if (intelligentReferral) {
                        if (question.displayOnReferral || true) {
                            if (question.type == 1) {
                                if (getAnswer(answer, sections, answers).text) {
                                    html += getAnswer(answer, sections, answers).text + ' ';
                                    if (empty) empty = false;
                                    prevQuestionType = question.type;
                                }
                            } else if (question.type == 2) {
                                if (answer) {

                                    let arr = [];

                                    for (let k = 0; k < question.answers.length; k++) {
                                        let check = -1;
                                        for (let l = 0; l < answer.length; l++) {
                                            if (question.answers[k] && answer[l] == question.answers[k].id) {
                                                check = l;
                                                break;
                                            }
                                        }
                                        if (check != -1) {
                                            if (question.answerPrefix) {

                                                if (getAnswer(answer[check], sections, answers).text)
                                                    arr.push(getAnswer(answer[check], sections, answers).text);
                                            } else {
                                                if (getAnswer(answer[check], sections, answers).text) {
                                                    html += getAnswer(answer[check], sections, answers).text + ' ';
                                                    if (empty) empty = false;
                                                    prevQuestionType = question.type;
                                                }
                                            }
                                        }
                                    }

                                    if (question.userInput && indexOfIndexOf(answer, '__user-input:') !== -1) {
                                        arr.push(answer[indexOfIndexOf(answer, '__user-input:')].replace('__user-input:', ''))
                                    }


                                    if (arr.length && question.answerPrefix) {
                                        if (arr.length > 1) {
                                            let last = arr.pop();
                                            html += question.answerPrefix + ' ' + arr.join(', ') + ' och ' + last + '. ';
                                            if (empty) empty = false;
                                            prevQuestionType = question.type;
                                        } else {
                                            html += question.answerPrefix + ' ' + arr.join(', ') + '. ';
                                            if (empty) empty = false;
                                            prevQuestionType = question.type;
                                        }
                                    }
                                }
                            } else if (question.type == 3) {
                                html += intelligentReferral && question.answerPrefix ? `${question.answerPrefix} ${answer}. ` : `${answer}. `;
                                if (empty) empty = false;
                                prevQuestionType = question.type;
                            } else if (question.type == 5) {
                                let newArr = JSON.parse(JSON.stringify(answer));
                                let arr = [];
                                for (let k = 0; k < newArr.length; k++) {
                                    if (newArr[k]) {
                                        arr.push(newArr[k]);
                                    }
                                }

                                if (arr.length > 1) {
                                    let last = arr.pop();
                                    html += arr.join(', ') + ' och ' + last + '. ';
                                    if (empty) empty = false;
                                    prevQuestionType = question.type;
                                } else {
                                    html += arr.join(', ') + '. ';
                                    if (empty) empty = false;
                                    prevQuestionType = question.type;
                                }

                            } else if (question.type == 6) {
                                if (answer) {
                                    html += 'Patientens längd: ' + answer[1] + ', ' + 'vikt: ' + answer[0] + ', ' + 'BMI: ' + parseFloat(answer && answer.length ? (answer[0] / ((answer[1] / 100) * (answer[1] / 100))) : 0).toFixed(1) + '. ';
                                    if (empty) empty = false;
                                    prevQuestionType = question.type;
                                }
                            } else if (question.type == 9) {
                                if (answer && answer.length) {
                                    for (let f = 0; f < answer.length; f++) {
                                        if (answer[f] && answer[f].file) {
                                            html += `<a href='${API_ENDPOINT + answer[f].file}' target="_blank">File/photo</a><br/>` + ' ';
                                            if (empty) empty = false;
                                            prevQuestionType = question.type;
                                        }
                                    }
                                }
                            } else if (question.type == 10) {
                                if (question.userInput) {
                                    html += "<br/><br/><br/><br/>"
                                    if (empty) empty = false;
                                } else {
                                    html += "<br/><br/>"
                                    if (empty) empty = false;
                                }

                                prevQuestionType = question.type;
                            } else if (question.type == 12) {
                                html += 'Patientens blodtryck uppmätt till: <br/>';
                                html += answer && answer[0] ? answer[0] + '/' : ''
                                html += answer && answer[1] ? answer[1] + '<br/>' : ''
                                html += answer && answer[2] ? 'Puls: ' + answer[2] + '<br/>' : ''
                                if (empty) empty = false;
                                prevQuestionType = question.type;
                            } else if (question.type == 13) {
                                html += 'Patientens VAS-skalvärde är:';
                                html += answer && answer[0] ? answer[0] + '<br/>' : ''
                                if (empty) empty = false;
                                prevQuestionType = question.type;
                            } else if (question.type == 14) {
                                html += 'Aktuell andningsfrekvens/minut:';
                                html += answer ? answer + '<br/>' : ''
                                if (empty) empty = false;
                                prevQuestionType = question.type;
                            } else if (question.type == 16) {
                                if (getAnswer(answer, sections, answers).response) {
                                    if (totalSubtotalInPreview) {
                                        if (html && html.length > 5) {
                                            let check = html.substr(html.length - 5, 5);
                                            if (check !== '<br/>') {
                                                html += '<br/>'
                                            }
                                        }
                                        html += '\n<span><strong>' + question.name + ' ' + getAnswer(answer, sections, answers).response + ` - ${(totalSubtotal[question.id] ? totalSubtotal[question.id] : 0)} ` + '</strong></span><br/>';
                                    } else {
                                        html += '<br/>';
                                    }
                                    if (empty) empty = false;
                                    prevQuestionType = question.type;
                                }
                            } else if (question.type == 17) {
                                if (getAnswer(answer, sections, answers).response) {
                                    if (totalSubtotalInPreview) {
                                        if (html && html.length > 5) {
                                            let check = html.substr(html.length - 5, 5);
                                            if (check !== '<br/>') {
                                                html += '<br/>'
                                            }
                                        }
                                        html += '\n<span><strong>' + question.name + ' ' + getAnswer(answer, sections, answers).response + ` - ${(totalSubtotal[question.id] ? totalSubtotal[question.id] : 0)} ` + '</strong></span><br/>';
                                    } else {
                                        html += '<br/>';
                                    }
                                    if (empty) empty = false;
                                    prevQuestionType = question.type;
                                }
                            } else if (question.type == 18) {
                                let questionAnswers = question.answers ? question.answers : [];
                                let questionAnswersNames = {};
                                for (let i = 0; i < questionAnswers.length; i++) {
                                    if (questionAnswers[i].id) {
                                        questionAnswersNames[questionAnswers[i].id] = questionAnswers[i].name ? questionAnswers[i].name : '';
                                    }
                                }
                                if (answer && Object.keys(answer).length) {
                                    for (var key in answer) {
                                        if (questionAnswersNames[key]) {

                                            html += `${questionAnswersNames[key]}: ${answer[key]} `;
                                            if (empty) empty = false;
                                            prevQuestionType = question.type;
                                        }
                                    }
                                }

                            } else if (question.type == 19) {
                                html += '<br/>' + question.name + ' - <strong>' + (answer ? answer : '') + '</strong><br/> ';
                                if (empty) empty = false;
                                prevQuestionType = question.type;
                            }

                        }
                    } else {

                        if (question.type == 1) {
                            if (getAnswer(answer, sections, answers).name) {
                                html += question.name + ' - <strong>' + getAnswer(answer, sections, answers).name + '</strong> <br/>';
                                if (empty) empty = false;
                                prevQuestionType = question.type;
                            }
                        } else if (question.type == 2) {
                            if (answer) {

                                let arr = [];

                                for (let k = 0; k < question.answers.length; k++) {
                                    let check = -1;
                                    for (let l = 0; l < answer.length; l++) {
                                        if (question.answers[k] && answer[l] == question.answers[k].id) {
                                            check = l;
                                            break;
                                        }
                                    }
                                    if (check != -1) {

                                        if (getAnswer(answer[check], sections, answers).name)
                                            arr.push(getAnswer(answer[check], sections, answers).name);

                                    }
                                }

                                if (question.userInput && indexOfIndexOf(answer, '__user-input:') !== -1) {
                                    arr.push(answer[indexOfIndexOf(answer, '__user-input:')].replace('__user-input:', ''))
                                }


                                if (arr.length > 1) {
                                    let last = arr.pop();
                                    html += question.name + ' - <strong>' + arr.join(', ') + ` ${'och'.translate(lang)} ` + last + '. </strong><br/>';
                                    if (empty) empty = false;
                                    prevQuestionType = question.type;
                                } else {
                                    html += question.name + ' - <strong>' + arr.join(', ') + '.</strong><br/> ';
                                    if (empty) empty = false;
                                    prevQuestionType = question.type;
                                }
                            }
                        } else if (question.type == 3) {
                            html += question.name + ' - <strong>' + (answer ? answer : '') + '.</strong><br/> ';
                            if (empty) empty = false;
                            prevQuestionType = question.type;
                        } else if (question.type == 5) {
                            let newArr = answer ? JSON.parse(JSON.stringify(answer)) : [];
                            let arr = [];
                            for (let k = 0; k < newArr.length; k++) {
                                if (newArr[k]) {
                                    arr.push(newArr[k]);
                                }
                            }
                            if (arr.length > 1) {
                                let last = arr.pop();
                                html += arr.join(', ') + ` ${'och'.translate(lang)} ` + last + '. ';
                                if (empty) empty = false;
                            } else {
                                html += arr.join(', ') + '. ';
                                if (empty) empty = false;
                            }
                            prevQuestionType = question.type;

                        } else if (question.type == 6) {
                            if (answer) {
                                html += `${'Patientens längd'.translate(lang)}: ` + answer[1] + ', ' + `${'vikt'.translate(lang)}: ` + answer[0] + ', ' + `${'BMI'.translate(lang)}: ` + parseFloat(answer && answer.length ? (answer[0] / ((answer[1] / 100) * (answer[1] / 100))) : 0).toFixed(1) + '. ';
                                if (empty) empty = false;
                                prevQuestionType = question.type;
                            }
                        } else if (question.type == 7) {
                            html += `<br/><br/>
                            ${'Frikortsnummer'.translate(lang)}: ${answer ? answer[0] : ''}<br/>
                            ${'Giltig från'.translate(lang)}: ${answer ? answer[1] : ''}<br/>
                            ${'Giltig till'.translate(lang)}: ${answer ? answer[2] : ''}<br/>
                            ${'Utfärdad av'.translate(lang)}: ${answer ? answer[3] : ''}<br/><br/>
                            `;
                            if (empty) empty = false;
                            prevQuestionType = question.type;
                        } else if (question.type == 8) {
                            html += `<a href='${API_ENDPOINT + answer}' target="_blank"> ${question.name}</a><br/>` + ' ';
                            if (empty) empty = false;
                            prevQuestionType = question.type;
                        } else if (question.type == 9) {

                            if (answer && answer.length) {
                                html += "<br/>"
                                for (let f = 0; f < answer.length; f++) {
                                    if (answer[f] && answer[f].file)
                                        html += `<a href='${API_ENDPOINT + answer[f].file}' target="_blank">${'File/photo'.translate(lang)}</a><br/>` + ' ';
                                }
                                if (empty) empty = false;
                                prevQuestionType = question.type;
                            }
                        } else if (question.type == 10) {
                            if (question.userInput) {
                                html += "<br/><br/>"
                            } else {
                                html += "<br/>"
                            }
                            if (empty) empty = false;

                            prevQuestionType = question.type;
                        } else if (question.type == 12) {
                            html += `${'Patientens blodtryck uppmätt till'.translate(lang)}: <br/>`;
                            html += answer && answer[0] ? answer[0] + '/' : '';
                            html += answer && answer[1] ? answer[1] + '<br/>' : '';
                            html += answer && answer[2] ? 'Puls: ' + answer[2] + '<br/>' : '';
                            if (empty) empty = false;
                            prevQuestionType = question.type;
                        } else if (question.type == 13) {
                            html += `${'Patientens VAS-skalvärde är'.translate(lang)}: `;
                            html += answer && answer[0] ? answer[0] + '<br/>' : '';
                            if (empty) empty = false;
                            prevQuestionType = question.type;
                        } else if (question.type == 14) {
                            html += `${'Aktuell andningsfrekvens/minut'.translate(lang)}:`;
                            html += answer ? answer + '<br/>' : '';
                            if (empty) empty = false;
                            prevQuestionType = question.type;
                        } else if (question.type == 16) {
                            if (getAnswer(answer, sections, answers).response) {
                                if (totalSubtotalInPreview) {
                                    html += '\n<span><strong>' + question.name + ' ' + getAnswer(answer, sections, answers).response + ` - ${(totalSubtotal[question.id] ? totalSubtotal[question.id] : 0)} ` + '</strong></span><br/>';
                                } else {
                                    html += '<br/>';
                                }
                                if (empty) empty = false;
                                prevQuestionType = question.type;
                            }
                        } else if (question.type == 17) {
                            if (getAnswer(answer, sections, answers).response) {
                                if (totalSubtotalInPreview) {
                                    html += '\n<span><strong>' + question.name + ' ' + getAnswer(answer, sections, answers).response + ` - ${(totalSubtotal[question.id] ? totalSubtotal[question.id] : 0)} ` + '</strong></span><br/>';
                                } else {
                                    html += '<br/>';
                                }
                                if (empty) empty = false;
                                prevQuestionType = question.type;
                            }
                        } else if (question.type == 18) {
                            let questionAnswers = question.answers ? question.answers : [];
                            let questionAnswersNames = {};
                            for (let i = 0; i < questionAnswers.length; i++) {
                                if (questionAnswers[i].id) {
                                    questionAnswersNames[questionAnswers[i].id] = questionAnswers[i].name ? questionAnswers[i].name : '';
                                }
                            }
                            if (answer && Object.keys(answer).length) {
                                html += '<br/>';

                                for (var key in answer) {
                                    if (questionAnswersNames[key]) {
                                        html += question.name + ' - <strong>' + `${questionAnswersNames[key]}: ${answer[key]}` + '</strong> <br/>';

                                    }
                                }
                                if (empty) empty = false;
                                prevQuestionType = question.type;
                            }

                        } else if (question.type == 3) {
                            html += question.name + ' - <strong>' + (answer ? answer : '') + '.</strong><br/> ';
                            if (empty) empty = false;
                            prevQuestionType = question.type;
                        }
                    }

                }
                if (question.type == 16 || question.type == 17) {
                    if (question.id == totalSubtotalId) {
                        let obj = {
                            id: totalSubtotalId,
                            response: `${translateReferralContent(html, lang)}`
                        }
                        htmlArr.push({ ...obj })
                    }
                }
            }
        }
    }

    html = translateReferralContent(html, lang)
    if (totalSubtotalId) {
        if (htmlArr && htmlArr.length) {
            for (let i = 0; i < htmlArr.length; i++) {
                if (htmlArr[i].id == totalSubtotalId)
                    return htmlArr[i].response
            }
        } else {
            return '';
        }
    } else {
        return html;

    }


}

function translateReferralContent(referralContent, lang) {
    let returnValue = referralContent;
    returnValue = referralContent
        .replace(/Sökdatum/g, 'Sökdatum'.translate(lang))
        .replace(/Ärende-ID/g, 'Ärende-ID'.translate(lang))
        .replace('Personuppgifter', 'Personuppgifter'.translate(lang))
        .replace('Patientens längd', 'Patientens längd'.translate(lang))
        .replace('vikt', 'vikt'.translate(lang))
        .replace('BMI', 'BMI'.translate(lang))
        .replace('Frikortsnummer', 'Frikortsnummer'.translate(lang))
        .replace('Giltig från', 'Giltig från'.translate(lang))
        .replace('Giltig till', 'Giltig till'.translate(lang))
        .replace('Utfärdad av', 'Utfärdad av'.translate(lang))
        .replace(/File\/photo/g, 'File/photo'.translate(lang))
        .replace('Patientens blodtryck uppmätt till', 'Patientens blodtryck uppmätt till'.translate(lang))
        .replace(/Patientens VAS-skalvärde är/g, 'Patientens VAS-skalvärde är'.translate(lang))
        .replace('Aktuell andningsfrekvens/minut', 'Aktuell andningsfrekvens/minut'.translate(lang))
        .replace('Vårdnadshavare', 'Vårdnadshavare'.translate(lang))
        .replace('Samtycke', 'Samtycke'.translate(lang))
        .replace('Free kort number', 'Free kort number'.translate(lang))
        .replace('Valid from', 'Valid from'.translate(lang))
        .replace('Valid to', 'Valid to'.translate(lang))
        .replace('Issued at', 'Issued at'.translate(lang))
        .replace('Paid on', 'Paid on'.translate(lang))
        .replace('Visit date', 'Visit date'.translate(lang))
        .replace('Kvitto nr', 'Kvitto nr'.translate(lang))
        .replace('Referens', 'Referens'.translate(lang))
        .replace('Betalningssätt', 'Betalningssätt'.translate(lang))
        .replace('Specifikation', 'Specifikation'.translate(lang))
        .replace('Anledning till besök', 'Anledning till besök'.translate(lang))
        .replace('Patient svar', 'Patient svar'.translate(lang))
        .replace('Price', 'Price'.translate(lang))
        .replace('VAT', 'VAT'.translate(lang))
        .replace('Total', 'Total'.translate(lang))
    return returnValue;
}