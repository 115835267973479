import React from 'react';

import {
    Modal, ModalHeader, ModalBody, ModalFooter, Button, Label
} from 'reactstrap';

import FormBuilder from './forms/formBuilder';
import { required, emailAddress } from './forms/validation'

import { formValueSelector, change } from "redux-form"; // ES6
import { connect } from "react-redux";


import { API_ENDPOINT } from '../constants'
function parseJSON(response) {
    return response.json().then(json => {
        return {
            result: json,
            status: response.status
        }
    })
}

/**
* A model for entering patient data is displayed if not all required data is available
* @author Milan Stanojevic
*/
class PrivacyPolicyModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            groupObj: null
        }
    }

    componentDidMount() {


        this.getGroupId()

    }

    componentDidUpdate(prevProps) {

    }

    /**
    * Update patient's profile
    * @author Milan Stanojevic
    */
    update = (data) => {
        // form is not updated

        fetch(API_ENDPOINT + '/users/account/update', {
            method: 'POST',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(parseJSON).then(({ result, status }) => {
            if (result.error) {
                this.setState({ error: result.error.translate(this.props.lang) })
            } else {
                this.setState({
                    message: result.message
                })
                this.props.verifyUser();
                // window.location.reload()
                //this.props.toggle();
            }

        })


    }


    getGroupId = () => {
        if (typeof window != 'undefined' && localStorage.groupAlias) {


            fetch(API_ENDPOINT + "/data/clinic-group/info", {
                method: "POST",
                headers: {
                    Authorization:
                        typeof localStorage !== "undefined"
                            ? `Bearer ${localStorage.getItem("authToken")}`
                            : null,
                    "content-type": "application/json",
                },
                body: JSON.stringify({ alias: localStorage.groupAlias }),
            }).then(parseJSON).then(({ result, status }) => {
                if (result && result._id) {
                    this.setState({ groupObj: result })
                }
            });
        }

    }

    render() {
        const { groupObj } = this.state;
        let showPrivacyPolicyCheckbox = false;
        if (groupObj && groupObj._id && this.props.uData && (this.props.uData.userLevel === 1 || this.props.uData.userLevel === 2 || (this.props.uData.userLevel === 20 && typeof window != 'undefined' && localStorage?.getItem?.('profile') === 'patient'))) {
            if (!this.props.uData.privacyPolicy || (this.props.uData.privacyPolicy && !this.props.uData.privacyPolicy[groupObj._id]))
                showPrivacyPolicyCheckbox = true
        }
        // if (showPrivacyPolicyCheckbox) {
        //     if (typeof window != 'undefined' && localStorage.openFirstPatientLoginModal) {
        //         showPrivacyPolicyCheckbox = false;
        //     }
        // }
        let privacyPolicy = groupObj && groupObj.privacyPolicy && groupObj.privacyPolicy.file ? `${API_ENDPOINT}${groupObj.privacyPolicy.file}` : null;
        let termsOfUse = groupObj && groupObj.termsOfUse && groupObj.termsOfUse.file ? `${API_ENDPOINT}${groupObj.termsOfUse.file}` : null;

        if (!termsOfUse && !privacyPolicy) {
            showPrivacyPolicyCheckbox = false;
        }

        if (this.props.patientAccountModal) {
            showPrivacyPolicyCheckbox = false;
        }
        if (typeof window !== 'undefined' && localStorage?.hidePatientModal) {
            showPrivacyPolicyCheckbox = false;
        }
        if (!showPrivacyPolicyCheckbox) {
            return null
        }

        if (!this.props.uData) {
            return null
        }


        return (
            <Modal isOpen={showPrivacyPolicyCheckbox} centered zIndex={1060}>

                <ModalHeader style={{ display: 'block', width: '100%' }}>
                    <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                        <p style={{ width: '100%', marginBottom: 0 }}>{'Terms and Conditions'.translate(this.props.lang)}</p>

                    </div>



                </ModalHeader>
                <ModalBody className="bankid-modal privacy-policy-modal-body">
                    <div>
                        <h6>{"To use this service, you must agree to".translate(this.props.lang)} {groupObj.name}{"'s".translate(this.props.lang)} {termsOfUse ? <a href={termsOfUse} target="_blank">{"Terms of Service".translate(this.props.lang)}</a> : "Terms of Service".translate(this.props.lang)}.</h6>
                    </div>
                    <div className='privacy-policy-modal-footer-buttons-wrap'>
                        <Button color="danger" className='logout-btn' onClick={() => this.props.signOut()}>{'Logout'.translate(this.props.lang)}</Button>
                        <Button color="primary" className='continue-btn' onClick={() => {
                            let privacyPolicy = this.props.uData.privacyPolicy ? this.props.uData.privacyPolicy : {}
                            if (groupObj && groupObj._id) {
                                privacyPolicy[groupObj._id] = true;
                            }
                            this.update({ privacyPolicy })
                        }}>{'Agree to Terms of Service'.translate(this.props.lang)}</Button>
                    </div>
                    {/* {
                        this.props.uData ?
                            <FormBuilder dateFormat={this.props.dateFormat}
                                getStringDateTs={this.props.getStringDateTs}
                                lang={this.props.lang}
                                onSubmit={(data) => this.update({ privacyPolicy: data.privacyPolicy })}
                                initialValues={this.props.uData}
                                toggle={() => this.setState({ form: null })}
                                buttonText={'Continue'.translate(this.props.lang).translate(this.props.lang)}
                                additionalButton={
                                    <Button style={{ marginLeft: 10 }} color="danger" onClick={() => this.props.signOut()}>{'Logout'.translate(this.props.lang)}</Button>

                                }
                                buttonsWrapClassName={'buttons-center-v2'}

                                fields={[
                                    {
                                        type: 'row',
                                        children: [

                                            {
                                                type: "col",
                                                width: {
                                                    lg: 12,
                                                    sm: 12,
                                                    xs: 12,
                                                },
                                                children: [
                                                    {
                                                        type: "privacy-policy-checkbox",
                                                        name: `privacyPolicy.${groupObj?._id}`,
                                                        groupData: groupObj,
                                                        lang: this.props.lang,
                                                        validate: [required('Privacy Policy is required!'.translate(this.props.lang))]
                                                    },
                                                ],
                                            }




                                        ]
                                    }
                                ]}
                            ></FormBuilder>
                            :
                            null

                    }
                    <p className="error-message">{this.state.error}</p> */}
                </ModalBody>
                <ModalFooter className='privacy-policy-modal-footer'>

                    <p>{'You can read about how we handle your personal data:'.translate(this.props.lang)} {privacyPolicy ? <a href={privacyPolicy} target="_blank">{'Privacy Policy'.translate(this.props.lang)}</a> : 'Privacy Policy'.translate(this.props.lang)}.</p>
                </ModalFooter>
            </Modal >
        )
    }
}


const selector2 = formValueSelector("form");


export default connect(
    (state) => {
        return {
            sex: selector2(state, 'userData.sex')
        };
    },
    {
        changeSex: (value) => change("form", "userData.sex", value),

    }
)(PrivacyPolicyModal);