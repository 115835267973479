import React, { Component } from 'react';
import Page from '../../../containers/page';

import {
    Container,
    Row,
    Col, Input, Label, Modal, ModalBody, ModalHeader, FormGroup, Button, Table, FormFeedback
} from 'reactstrap';
import { Link } from 'react-router-dom';

import { getSearchParams, generateSearchLink, generateSearchLinkMultiple } from '../../../helpers/linkHelper';
import { API_ENDPOINT } from '../../../constants';


import Isvg from 'react-inlinesvg'


import moment from 'moment';

import DiaryGraph from '../../../components/diaryGraph';
import { Player, Controls } from "@lottiefiles/react-lottie-player";



function parseJSON(response) {
    return response.json().then(json => {
        return {
            result: json,
            status: response.status
        }
    })
}



function pad(n, width, z) {
    z = z || '0';
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
}
class PatientDiaryGraph extends Component {
    constructor(props) {
        super(props);
        this.player = React.createRef();
        this.player2 = React.createRef();
        this.state = {
            ...props.initialData,
            daybookChartType: 'daily',
            _loading: true

        };

    }
    get = () => {
        let params = getSearchParams(this.props[0].location.search);

        if (params.dateFrom && params.dateTo && params.patientID && params.alias) {
            let token = typeof window !== 'undefined' && localStorage.token ? localStorage.token : null;
            let obj = {
                dateFrom: params.dateFrom,
                dateTo: params.dateTo,
                patientID: params.patientID,
                alias: params.alias
            }

            this.showStats(obj, token)

            console.log(params, obj)
        } else {
            this.setLoader(false)
        }



    }
    componentDidMount() {
        // http://localhost:5002/patient/diary-graph?dateFrom=11-01-2023&dateTo=11-30-2023&patientID=628b78310a0bbcf82f688bca&alias=mayoclinic
        this.get()
    }

    showStats = (data, token) => {
        if (token && data) {
            data.daybookChartType = this.state.daybookChartType;


            this.setState({ graphImage: null }, () => {
                fetch(API_ENDPOINT + '/users/daybook/stats', {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify(data)
                }).then(res => res.json()).then((result) => {
                    if (result) {

                        this.setState({ daybookStats: null }, () => {
                            this.setState({
                                daybookStats: result,
                                resetGraphImage: new Date().getTime(),
                                diaryGraphImage: null,
                            })
                            this.setLoader(false)
                        })
                    }
                })
            })

        } else {
            this.setLoader(false)
        }
    }
    setLoader = (value) => {
        this.setState({ _loading: value })
    }


    render() {
        return (
            <div className='diary-graph-page-wrap'>
                {
                    this.state._loading ?
                        <div className="loader-wrap" >
                            <Player
                                autoplay={true}
                                loop={true}
                                src="/lf30_editor_l5cxzdyf.json"
                                style={{ height: "128px", width: "128px", position: 'absolute', top: "50%", left: '50%', transform: 'translate(-50%, -50%)' }}
                            ></Player>

                        </div>
                        :
                        null
                }
                {
                    this.state.daybookStats && (this.state.daybookStats.dataset?.length || this.state.daybookStats.diaryGraphDataset?.length) ?
                        <DiaryGraph
                            getStringDateTs={this.props.getStringDateTs}
                            lang={this.props.lang}
                            daybookChartType={this.state.daybookChartType}
                            data={this.state.daybookStats}
                            resetGraphImage={this.state.resetGraphImage}
                            disableFullScreen={true}
                        />
                        :
                        null
                }
            </div>

        );
    }
}

export default PatientDiaryGraph;