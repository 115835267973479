import React, { Component } from 'react';
import moment from 'moment';
import Page from '../../containers/page';
import ListBuilder from '../../components/listBuilder';
import { Link } from 'react-router-dom';
import {
    Container,
    Row,
    Col,
    Modal, ModalHeader, ModalBody, ModalFooter, Button, FormGroup, Input, Label, Table
} from 'reactstrap';
import { getSearchParams, generateSearchLink, generateSearchLinkMultiple } from '../../helpers/linkHelper';
import { API_ENDPOINT } from '../../constants';
import ErrorModal from '../../components/errorModal';
import profileUser from "../../assets/images/user.png";
import warrning from '../../assets/svg/warrning.svg';


var striptags = require('striptags');

/**
* Support page
* @author   Stefan Pantic
*/
class HomePage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            promotedProjects: [],
            ...props.initialData,
            form: false,

            useState: false,
            params: {
                entries: 10,
                page: 0
            },
            items: [],
            tikets: [],
            category: {},
            messages: [],
            sentList: false,
            receivedList: true,
            assignedList: false,
            doctorTikets: [],
            tiket: -1,

        };
    }

    get = () => {

        // if (this.props.uData && this.props.uData.lastNotifications) {
        //     let notif = localStorage.getItem('notif') ? JSON.parse(localStorage.getItem('notif')) : [];

        //     for (let i = 0; i < this.props.uData.lastNotifications.length; i++) {
        //         if (notif.indexOf(this.props.uData.lastNotifications[i]._id) == -1) {
        //             notif.push(this.props.uData.lastNotifications[i]._id);
        //         }
        //     }
        //     localStorage.setItem('notif', JSON.stringify(notif));
        //     this.props.verifyUser();
        // }


        this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));

        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0, sortField: 'timestamp', sortType: -1, user: this.props.uData._id, alias: localStorage.getItem('groupAlias'), group: this.props.selectedGroup })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

        for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
            this.props.loadDataWithQuery[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0, sortField: 'timestamp', sortType: -1, user: this.props.uData._id, alias: localStorage.getItem('groupAlias'), group: this.props.selectedGroup })).then((data) => {
                let dataToSet = { ...data }
                if (dataToSet.page || dataToSet.items || dataToSet.total) {
                    dataToSet.loading = null;
                    // console.log(dataToSet.page || dataToSet.items || dataToSet.total)
                    let checkFilter = '';
                    if (dataToSet.resultFilter) {
                        checkFilter = String(dataToSet.resultFilter)
                        delete dataToSet.resultFilter;
                    }
                    let search = this.props[0].location.search;
                    let serachParams = getSearchParams(search);
                    let searchParamsFilter = '';
                    if (serachParams.filter) {
                        searchParamsFilter = serachParams.filter;
                    }
                    if (searchParamsFilter != checkFilter) {
                        delete dataToSet.page;
                        delete dataToSet.items;
                        delete dataToSet.total;
                        delete dataToSet.loading;
                    }
                }
                this.setState({
                    ...dataToSet,
                    // loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }


    }

    componentDidMount() {
        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }

        this.get();

        if (!localStorage?.getItem?.('groupAlias') && (this.props.uData.userLevel == 1 || this.props.uData.userLevel == 2)) {
            // this.props[0].history.push(`/home-patient-level`);
            this.setState({
                error: 'You have to select the group!'
            })
        }

        this.interval = setInterval(() => {
            this.get();

        }, 60 * 1000);

    }

    componentWillUnmount() {
        if (this.interval) {
            clearInterval(this.interval)
        }
    }
    componentDidUpdate(prevProps, prevState) {


        if (prevProps.selectedGroup != this.props.selectedGroup) {
            this.get();
        }
        if (this.props[0].location && this.props[0].location.state && this.props[0].location.state.messageModal && this.state.tikets && this.state.tikets.length && !prevState.tikets.length) {
            for (let i = 0; i < this.state.tikets.length; i++) {
                if (this.props[0].location.state.messageModal.toString() == this.state.tikets[i]._id.toString()) {
                    this.setState({
                        messageModal: this.state.tikets[i]
                    }, () => this.seen(this.state.tikets[i]._id))
                }
            }
        }

    }
    /**
    * Function for sending question to support 
    * @author   Stefan Pantic
    * @Objectparam    {String} message      sent message
    * @Objectparam    {String} subject      queston subject
    * @Objectparam    {String} tiket        support category id
    * @Objectparam    {String} user         user id who send question
    * @Objectparam    {String} alias        group alias
    * @Objectparam    {String} group        group id
    */
    sendQuestion = (data) => {

        this.setState({
            loading: true
        }, () => {
            fetch(API_ENDPOINT + '/support-category/tiket', {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify({ message: data.message, subject: data.subject, tiket: this.state.tiket, user: this.props.uData._id, alias: localStorage.getItem('groupAlias'), group: this.props.selectedGroup })
            }).then(res => res.json()).then((result) => {
                this.get();
                this.setState({
                    supportModal: null,
                    category: {}

                })
            })

        })
    }

    /**
    * Function change seen option on support tickets
    * @author   Stefan Pantic
    * @Objectparam    {String} id      ticket id
    */
    seen = (id) => {

        this.setState({
            loading: true
        }, () => {
            fetch(API_ENDPOINT + '/support-category/tiket/update/' + id, {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify()
            }).then(res => res.json()).then((result) => {
                this.get();

            })

        })
    }

    /**
    * Function for sending reply on received ticket
    * @author   Stefan Pantic
    * @Objectparam    {String} answer       message that user send
    * @Objectparam    {String} tiket        ticket id
    * @Objectparam    {String} user         user id who send reply
    */
    sendAnswer = (data) => {

        this.setState({
            loading: true
        }, () => {
            fetch(API_ENDPOINT + '/support-category/answer', {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify({ answer: data.answer, tiket: data._id, user: this.props.uData._id })
            }).then(res => res.json()).then((result) => {
                if (result.error) {
                    this.setState({
                        error: result.error.translate(this.props.lang)
                    })
                } else {
                    this.get();

                    let messageModal = this.state.messageModal;
                    messageModal.answer = '';
                    this.setState({
                        messageModal
                    })
                }

            })

        })
    }

    /**
    * Function that close ticket for further use
    * @author   Stefan Pantic
    * @Objectparam    {String} data       ticket id
    */
    closeTiket = (data) => {

        this.setState({
            loading: true
        }, () => {
            fetch(API_ENDPOINT + '/support-category/close/' + data, {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify()
            }).then(res => res.json()).then((result) => {
                this.get();

            })

        })
    }



    render() {
        let params = getSearchParams(this.props[0].location.search, { entries: 10, page: 0 })
        let level = this.props.uData.userLevel
        return (
            <div className={this.props.autoHideSidebar ? "dashboard dashboard-auto-hide" : "dashboard"}>
                <Container fluid>
                    <div className="panel select-clinics-wrapper">
                        <h5 className="component-header mb-35">{this.props.linksMeta && this.props.linksMeta[this.props[0].location.pathname] ? this.props.linksMeta[this.props[0].location.pathname][this.props.lang] : ''}</h5>
                        {level > 20 ?
                            <Col lg="4" className="ml-0">
                                <div className="ticket-buttons">
                                    <Button className="news-ticket-button" onClick={() => this.setState({ receivedList: true, assignedList: false, sentList: false })}>{'Received'.translate(this.props.lang)}</Button>
                                    <Button className="news-ticket-button" onClick={() => this.setState({ assignedList: true, sentList: false, receivedList: false })}>{'Assigned'.translate(this.props.lang)}</Button>
                                </div>
                            </Col>
                            :
                            <Col lg="4" className="ml-0">
                                <Button className="news-ticket-button" onClick={() => this.setState({ supportModal: true })}>{'Open new tiket'.translate(this.props.lang)}</Button>
                            </Col>

                        }
                        {level == 20 ?

                            <Col lg="4" className="ml-0">
                                <div className="ticket-buttons">
                                    <Button className="news-ticket-button" onClick={() => this.setState({ sentList: true, assignedList: false, receivedList: false })}>{'Sent'.translate(this.props.lang)}</Button>
                                    <Button className="news-ticket-button" onClick={() => this.setState({ receivedList: true, assignedList: false, sentList: false })}>{'Received'.translate(this.props.lang)}</Button>
                                    <Button className="news-ticket-button" onClick={() => this.setState({ assignedList: true, sentList: false, receivedList: false })}>{'Assigned'.translate(this.props.lang)}</Button>
                                </div>
                            </Col>

                            : null}
                        <Row>
                            {this.state.sentList ?
                                <Col lg="4">
                                    <div className="support-ticket">

                                        <>
                                            {this.state.doctorTikets.sort(function (a, b) { return b.timestemp - a.timestemp }).sort(function (a, b) { return a.close - b.close }).map(item => {

                                                return (

                                                    <Button className={item.close ? 'ticket-closed' : 'ticked-open'} onClick={() => this.setState({ messageModal: item }, () => this.seen(item._id))}>
                                                        <h6>{item.name}</h6>
                                                        <h6>{'Support-ID:'.translate(this.props.lang)} {item.tiketNumber} - {item.subject}</h6>
                                                        {level != 1 && level != 2 ? !item.seenSupport ? <p className="ticket-notification"></p> : '' : !item.seenUser ? <p className="ticket-notification"></p> : ''}
                                                        <div className="ticket-body">
                                                            {item.profilePicture ?
                                                                <img src={API_ENDPOINT + item.profilePicture} />

                                                                :
                                                                <img src={profileUser} />

                                                            }
                                                            <h6>{item.userName}</h6>
                                                            <p>{this.props.getStringDateTs(item.timestemp, `${this.props.dateFormat} HH:mm`)/*moment.unix(item.timestemp).format(`${this.props.dateFormat} HH:mm`)*/}</p>
                                                        </div>

                                                    </Button>
                                                )
                                            })}
                                        </>
                                    </div>
                                </Col>
                                :
                                null}
                            {this.state.receivedList ?
                                <Col lg="4">
                                    <div className="support-ticket">

                                        <>
                                            {this.state.tikets.sort(function (a, b) { return b.timestemp - a.timestemp }).sort(function (a, b) { return a.close - b.close }).map(item => {

                                                return (

                                                    <Button className={this.state.messageModal && this.state.messageModal._id == item._id ? item.close ? 'ticket-closed active-ticket-red' : item.supportUserWhoTakeTicket ? 'ticked-open active-ticket-green' : 'ticket-inprogress active-ticket-inprogress' : item.close ? 'ticket-closed' : item.supportUserWhoTakeTicket ? 'ticked-open' : 'ticket-inprogress'} onClick={() => this.setState({ messageModal: item }, () => this.seen(item._id))}>
                                                        <h6>{item.name}</h6>
                                                        <h6>{'Support-ID:'.translate(this.props.lang)} {item.tiketNumber} - {item.subject}</h6>
                                                        {level != 1 && level != 2 ? !item.seenSupport ? <p className="ticket-notification"></p> : '' : !item.seenUser ? <p className="ticket-notification"></p> : ''}
                                                        <div className="ticket-body">
                                                            {item.profilePicture ?
                                                                <img src={API_ENDPOINT + item.profilePicture} />

                                                                :
                                                                <img src={profileUser} />

                                                            }
                                                            <h6>{item.userName}</h6>
                                                            <p>{this.props.getStringDateTs(item.timestemp, `${this.props.dateFormat} HH:mm`)/*moment.unix(item.timestemp).format(`${this.props.dateFormat} HH:mm`)*/}</p>
                                                        </div>

                                                        {this.props.uData.userLevel != 1 && this.props.uData.userLevel != 2 ? <Button onClick={() => this.closeTiket(item._id)} disabled={item.close} >{item.close ? 'Closed'.translate(this.props.lang) : 'Close tiket'.translate(this.props.lang)}</Button> : null}
                                                    </Button>
                                                )
                                            })}
                                        </>
                                    </div>
                                </Col>
                                : null}
                            {this.state.assignedList ?
                                <Col lg="4">
                                    <div className="support-ticket">

                                        <>
                                            {this.state.assignedTickets.sort(function (a, b) { return b.timestemp - a.timestemp }).sort(function (a, b) { return a.close - b.close }).map(item => {

                                                return (

                                                    <Button className={this.state.messageModal && this.state.messageModal._id == item._id ? item.close ? 'ticket-closed active-ticket-red' : 'ticked-open active-ticket-green' : item.close ? 'ticket-closed' : 'ticked-open'} onClick={() => this.setState({ messageModal: item }, () => this.seen(item._id))}>
                                                        <h6>{item.name}</h6>
                                                        <h6>{'Support-ID:'.translate(this.props.lang)} {item.tiketNumber} - {item.subject}</h6>
                                                        {level != 1 && level != 2 ? !item.seenSupport ? <p className="ticket-notification"></p> : '' : !item.seenUser ? <p className="ticket-notification"></p> : ''}
                                                        <div className="ticket-body">
                                                            {item.profilePicture ?
                                                                <img src={API_ENDPOINT + item.profilePicture} />

                                                                :
                                                                <img src={profileUser} />

                                                            }
                                                            <h6>{item.userName}</h6>
                                                            <p>{this.props.getStringDateTs(item.timestemp, `${this.props.dateFormat} HH:mm`)/*moment.unix(item.timestemp).format(`${this.props.dateFormat} HH:mm`)*/}</p>
                                                        </div>

                                                        {this.props.uData.userLevel != 1 && this.props.uData.userLevel != 2 ? <Button onClick={() => this.closeTiket(item._id)} disabled={item.close} >{item.close ? 'Closed'.translate(this.props.lang) : 'Close tiket'.translate(this.props.lang)}</Button> : null}
                                                    </Button>
                                                )
                                            })}
                                        </>
                                    </div>
                                </Col>
                                : null}
                            <Col lg="8">
                                <div className="ticket-answer">
                                    {this.state.messageModal ?
                                        <div className="ticket-message-tab-container">
                                            {this.state.messageModal.close ?
                                                null
                                                : <FormGroup className="ticket-message-group ticket-message-tab">
                                                    <Label>{'Message'.translate(this.props.lang)}</Label>
                                                    <Input type='textarea' rows="6" className="textarea-six-row" onChange={(e) => {
                                                        let messageModal = this.state.messageModal;
                                                        messageModal.answer = e.target.value;
                                                        this.setState({
                                                            messageModal
                                                        })
                                                    }} value={this.state.messageModal.answer} />
                                                </FormGroup>}
                                            {this.state.messageModal.close ? null :
                                                <Button className="news-ticket-button" disabled={this.state.messageModal.answer ? this.state.messageModal.answer != '' ? false : true : true} onClick={() => this.sendAnswer(this.state.messageModal)}>{'Send'.translate(this.props.lang)}</Button>
                                            }
                                            {this.state.messages.filter(item => item.tiket == this.state.messageModal._id).sort(function (a, b) { return b.timestemp - a.timestemp }).map(item => {
                                                return (
                                                    <div className="mesage-box">
                                                        <div className="message-header">
                                                            <div className="message-header-container">
                                                                {item.profilePicture ?
                                                                    <img src={API_ENDPOINT + item.profilePicture} />

                                                                    :
                                                                    <img src={profileUser} />

                                                                }
                                                                <h6>{item.user}</h6>
                                                            </div>
                                                            <p>{this.props.getStringDateTs(item.timestemp, `${this.props.dateFormat} HH:mm`)/*moment.unix(item.timestemp).format(`${this.props.dateFormat} HH:mm`)*/}</p>
                                                        </div>
                                                        <p>{item.message}</p>
                                                    </div>

                                                )
                                            })}


                                        </div>
                                        : null}
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>

                {this.state.supportModal ?
                    <Modal isOpen={this.state.supportModal} centered>
                        <ModalHeader toggle={() => this.setState({ supportModal: !this.state.supportModal })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ supportModal: !this.state.supportModal })}>&times;</button>}>{'Your question'.translate(this.props.lang)}</ModalHeader>
                        <ModalBody>

                            <Input type='select'
                                className="mb-20"
                                onChange={e => this.setState({ tiket: e.target.value }, () => { this.get() })}>
                                <option value={-1}>{'None'.translate(this.props.lang)}</option>

                                {this.state.items.map(item => {
                                    return <option value={item._id}>
                                        {item.name}
                                    </option>
                                })}

                            </Input>
                            <FormGroup>
                                <Label>{'Subject'.translate(this.props.lang)}</Label>
                                <Input type='text' onChange={(e) => {
                                    let category = this.state.category;
                                    category.subject = e.target.value;
                                    this.setState({
                                        category
                                    })
                                }} value={this.state.category.subject} />
                            </FormGroup>
                            <FormGroup>
                                <Label>{'Message'.translate(this.props.lang)}</Label>
                                <Input type='textarea' rows="6" className="textarea-six-row" onChange={(e) => {
                                    let category = this.state.category;
                                    category.message = e.target.value;
                                    this.setState({
                                        category
                                    })
                                }} value={this.state.category.message} />
                            </FormGroup>
                            <Button color="primary" disabled={this.state.category.message && this.state.tiket != -1 ? false : true} onClick={() => this.sendQuestion(this.state.category)}>{'Send'.translate(this.props.lang)}</Button>
                        </ModalBody>
                    </Modal>
                    : null}

                {this.state.error ? (
                    <ErrorModal lang={this.props.lang}
                        isOpen={this.state.error}
                        toggle={() => this.setState({ error: null })}

                    >
                        {this.state.error.translate(this.props.lang)}
                    </ErrorModal>
                ) : null}

            </div>

        );


    }
}

export default Page(HomePage);