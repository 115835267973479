import React, { Component } from 'react';
import Page from '../../containers/page';

import {
    Container,
    Row,
    Col,
    FormGroup, Label, Input, Button, UncontrolledAlert,
    Modal, ModalBody, ModalFooter,
} from 'reactstrap';

import { Link } from 'react-router-dom';

import ModalFormBuilder from '../../components/forms/modalFormBuilder';

import FormBuilder from '../../components/forms/formBuilder';
import { required } from '../../components/forms/validation';

import ListBuilder from '../../components/listBuilder';
import { getSearchParams, generateSearchLink, generateSearchLinkMultiple } from '../../helpers/linkHelper';
import { API_ENDPOINT } from '../../constants';
import { formValueSelector, change } from 'redux-form';  // ES6
import { connect } from 'react-redux';
import lIcon from '../../assets/svg/upitnik.svg'
import Comment from '../../assets/svg/koment.svg'
import Hart from '../../assets/svg/srce.svg';

import Isvg from 'react-inlinesvg'
import locationIcon from '../../assets/svg/location.png'
import error from '../../assets/images/error.png';
import BankIdModal from '../../components/bankidModalDoctor';
// import BankIDModal_v2 from '../../components/bankIDModal_v2';
import ErrorModal from '../../components/errorModal'
import LoginComponent from '../../components/loginComponent';
import JsonToHtmlV2 from '../landingBuilder/jsonToHtml';
import defaultLoginJSON_v2 from '../landingBuilder/defaultLogin.json';
import FooterComponent from './footerComponent';


function prepareJSON(json) {
    let arr = [];
    for (var key in json) {
        arr.push({
            ...json[key],
            __id: key
        })
    }
    return arr;
}


function parseJSON(response) {
    return response.json().then(json => {
        return {
            result: json,
            status: response.status
        }
    })
}

/**
* Landing login page
* @author   Milan Stanojevic
*/
class Account extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props.initialData,
            form: false,
            useState: false,
            params: {
                entries: 10,
                page: 0,
                'query-group': null
            },
            items: [],
            total: 0,
            loading: true,
            groups: [],
            languages: [],
            clinicIdx: 0,
            data: {},
            type: 'bankid',
            bankIdRedirectUrl: null,
            windowWidth: typeof window != 'undefined' && window.innerWidth ? window.innerWidth : 0,
            showDefaultLogin: false
        };
    }


    get = () => {
        this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));

        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0, 'query-group': null })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

        for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
            this.props.loadDataWithQuery[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0, 'query-group': null })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

    }

    componentDidMount() {
        //if (typeof window !== 'undefined') { window.scrollTo(0, 0); }

        //this.get();
        window.addEventListener('resize', this.resizeWindow);

        let alias = this.props[0].match.params.alias;
        localStorage.setItem('groupAlias', alias);
        let mainAlias = this.props[0].match.params.mainAlias;
        if (mainAlias && alias) {
            fetch(API_ENDPOINT + '/landing/page', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json'
                },
                body: JSON.stringify({ alias, mainAlias })
            }).then(res => res.json()).then((result) => {
                if (result.error) {
                    this.props[0].history.push('/')
                } else {
                    if (result?.mainClinicGroup) {
                        this.props[0].history.push('/' + alias)
                    } else {
                        this.setState({
                            data: result
                        }, () => {
                            this.setState({
                                showDefaultLogin: true
                            })
                            if (result.favicon) {
                                let favicon = document.getElementById("favicon");
                                favicon.href = result.favicon;
                            } else if (result.logoChat) {
                                let favicon = document.getElementById("favicon");
                                favicon.href = result.logoChat;

                            } else {
                                favicon.href = '/favicon.ico';
                            }
                        })
                    }


                }
            });



        } else {
            fetch(API_ENDPOINT + '/landing/page', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json'
                },
                body: JSON.stringify({ alias })
            }).then(res => res.json()).then((result) => {
                if (result.error) {
                    this.props[0].history.push('/')
                } else {
                    // console.log(result)
                    this.setState({
                        data: result
                    }, () => {
                        if (result?.mainClinicGroup) {
                            this.props[0].history.push('/' + alias)
                        } else {
                            this.setState({
                                showDefaultLogin: true
                            })
                            if (result.favicon) {
                                let favicon = document.getElementById("favicon");
                                favicon.href = result.favicon;
                            } else if (result.logoChat) {
                                let favicon = document.getElementById("favicon");
                                favicon.href = result.logoChat;

                            } else {
                                favicon.href = '/favicon.ico';
                            }
                        }
                    })


                }
            });
        }

        this.fetchBankId()

        window.addEventListener('message',
            (e) => {

                if (e.data == 'loggedin') {

                    this.props.verifyUser(() => {
                        this.props.registerClinicGroupLogs({
                            type: 'login',
                            loginType: 'bankid'
                        })
                        this.props[0].history.push('/');


                    }, () => {


                        if (this.props.uData && this.props.uData.clinicGroups && this.props.uData.clinicGroups.length) {
                            let arr = [];
                            for (let i = 0; i < this.props.uData.clinicGroups.length; i++) {
                                arr.push(this.props.uData.clinicGroups[i]);
                            }
                            if (arr.filter((item) => item?._id == this.state.data?._id).length == 0) {
                                this.props.changeGroup(arr[0]?._id);
                                localStorage.setItem('groupAlias', arr[0].alias);
                            } else {
                                this.props.changeGroup(this.state.data?._id);
                            }
                        } else {
                            this.props.changeGroup(this.state.data?._id);
                        }


                    });

                } else if (e.data == 'loggedin_newuser') {


                    this.props.verifyUser(() => {
                        this.props.registerClinicGroupLogs({
                            type: 'login',
                            loginType: 'bankid'
                        })
                        this.props[0].history.push('/');
                        // console.log(this.props.uData)
                    }, () => {
                        if (this.props.uData && this.props.uData.clinicGroups && this.props.uData.clinicGroups.length) {
                            let arr = [];
                            for (let i = 0; i < this.props.uData.clinicGroups.length; i++) {
                                arr.push(this.props.uData.clinicGroups[i]);
                            }
                            if (arr.filter((item) => item?._id == this.state.data?._id).length == 0) {
                                this.props.changeGroup(arr[0]?._id)
                                localStorage.setItem('groupAlias', arr[0].alias);
                            } else {
                                this.props.changeGroup(this.state.data?._id)
                            }
                        } else {
                            this.props.changeGroup(this.state.data?._id)
                        }




                    });
                }
            }

            , false)

    }
    componentWillUnmount() {

        window.removeEventListener('resize', this.resizeWindow);
    }
    resizeWindow = () => {
        let windowWidth = this.state.windowWidth;
        let windowHeight = this.state.windowHeight;
        if (typeof window != 'undefined' && window.innerWidth && Math.abs(windowWidth - window.innerWidth) > 50) {
            this.setState({ windowWidth: window.innerWidth })
        } else if (typeof window != 'undefined' && window.innerHeight && Math.abs(windowHeight - window.innerHeight) > 50) {
            this.setState({ windowHeight: window.innerHeight })
        }

    }


    fetchBankId = () => {
        // fetch(API_ENDPOINT + '/users/bankid/v2/start', {
        //     method: 'POST',
        //     headers: {
        //         'content-type': 'application/json'
        //     },
        //     body: JSON.stringify({ group: this.state.data._id, alias: this.props[0].match.params.alias, doctorLogin: true })
        // }).then(res => res.json()).then((result) => {
        //     if (result.redirect_url) {
        //         this.setState({
        //             bankIdRedirectUrl: result.redirect_url
        //         }, () => {
        //             setTimeout(() => {
        //                 if (!this.props.uData) {
        //                     this.fetchBankId()
        //                 }
        //             }, 1000 * 60 * 4);
        //         })
        //     }
        // })
        this.setState({
            bankIdRedirectUrl: true
        })
    }
    fetchBankId2 = () => {
        // fetch(API_ENDPOINT + '/users/bankid/v2/start', {
        //     method: 'POST',
        //     headers: {
        //         'content-type': 'application/json'
        //     },
        //     body: JSON.stringify({ group: this.state.data._id, alias: this.props[0].match.params.alias, doctorLogin: true })
        // }).then(res => res.json()).then((result) => {
        //     if (result.redirect_url) {
        //         this.setState({

        //             loginBankIdModal: result.redirect_url
        //         }, () => {
        //             setTimeout(() => {
        //                 if (!this.props.uData) {
        //                     this.fetchBankId2()
        //                 }
        //             }, 1000 * 60 * 4);
        //         })
        //     }
        // })
        this.setState({
            loginBankIdModal: true
        })
    }

    componentDidUpdate(prevProps) {
        if (prevProps[0].location.search != this.props[0].location.search) {
            this.setState({
                loading: true
            }, () => {
                this.get();

            })
        }

        if (typeof prevProps.selectedWaitingTimeType != 'undefined' && prevProps.selectedWaitingTimeType != this.props.selectedWaitingTimeType) {
            if (this.props.selectedWaitingTimeType == 0) {
                this.props.changeMinValue('');
                this.props.changeMaxValue('');
            } else if (this.props.selectedWaitingTimeType == 1) {
                this.props.changeMinValue(0);
                this.props.changeMaxValue(0);
            } else if (this.props.selectedWaitingTimeType == 2) {
                this.props.changeMinValue('');
                this.props.changeMaxValue(0);
            } else if (this.props.selectedWaitingTimeType == 3) {
                this.props.changeMinValue(0);
                this.props.changeMaxValue('');
            } else if (this.props.selectedWaitingTimeType == 4) {
                this.props.changeMinValue('');
                this.props.changeMaxValue('');
            } else if (this.props.selectedWaitingTimeType == 5) {
                this.props.changeMinValue('');
                this.props.changeMaxValue('');
            }
        }

        if (!prevProps[0].uData && this.props.uData && prevProps[0].location.search != '?preview=true') {
            this.props[0].history.push(`/${this.props[0].match.params.alias}`);
        }

    }


    updateSort = (field, type) => {
        if (this.state.useState) {
            let state = this.state.params;
            state.sortField = field;
            state.sortType = type;
            this.setState({
                params: state
            })
        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0 }, [{ name: 'sortField', value: field }, { name: 'sortType', value: type }], false));

        }
    }

    updateParams = (name, value, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };
                obj[name] = value;
                this.setState({ params: obj });
            } else {
                let obj = this.state.params;
                obj[name] = value;
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(generateSearchLink(this.props[0].location.search, { entries: 10, page: 0 }, name, value, restart));
        }
    }
    updateMultipleParams = (fields, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj });

            } else {
                let obj = this.state.params;
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0 }, fields, restart));
        }
    }
    /**
    * Login function
    * @author   Milan Stanojevic
    * @Objectparam    {String} username              user's username
    * @Objectparam    {String} password              user's password
    */
    login = (data) => {
        data.verificationCodeNeeded = true;
        if (typeof window != 'undefined') {
            data.groupAlias = localStorage.groupAlias;
        }
        fetch(API_ENDPOINT + '/users/login', {
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(res => res.json()).then((result) => {
            if (result.error) {
                if (result.error.text) {
                    this.setState({
                        error: result.error.text
                    })
                } else {
                    this.setState({
                        error: result.error
                    })
                }

            } else {
                try {
                    if (result?.userData && typeof window !== 'undefined') {
                        localStorage.setItem('userData', JSON.stringify(result?.userData));
                    }
                } catch (err) {
                    console.error('login user data err: ', err);
                }
                localStorage.setItem('authToken', result.token);
                localStorage.setItem('time', Math.floor(Date.now() / 1000))
                localStorage.setItem('profile', 'doctor');
                localStorage.setItem('userLevel', 20);
                // localStorage.setItem('logoutLandingPage', true);
                this.props.registerLog('Logged in');
                this.props.verifyUser(() => {
                    this.props.registerClinicGroupLogs({
                        type: 'login',
                        loginType: 'username'
                    })
                    if (this.props.uData && this.props.uData.clinicGroups && this.props.uData.clinicGroups.length) {
                        let arr = [];
                        for (let i = 0; i < this.props.uData.clinicGroups.length; i++) {
                            arr.push(this.props.uData.clinicGroups[i]);
                        }
                        if (arr.filter((item) => item.alias == this.props[0].match.params.alias).length == 0) {
                            localStorage.setItem('groupAlias', arr[0].alias);
                        } else {
                            localStorage.setItem('groupAlias', this.props[0].match.params.alias);
                        }
                    } else {
                        localStorage.setItem('groupAlias', this.props[0].match.params.alias);
                    }
                    if (this.props.uData && this.props.uData.userLevel) {
                        localStorage.setItem('userLevel', this.props.uData.userLevel);
                    }
                    localStorage.setItem('loginGroupAlias', this.props[0].match.params.alias);
                    this.props[0].history.push(`/`);
                }, () => {
                    if (localStorage.getItem('loginGroupAlias') == localStorage.getItem('groupAlias')) {
                        this.props.changeGroup(this.state.data?._id);
                    } else {
                        let arr = [];
                        for (let i = 0; i < this.props.uData?.clinicGroups?.length; i++) {
                            arr.push(this.props.uData.clinicGroups[i]);
                        }
                        this.props.changeGroup(arr[0]?._id);
                    }

                });
            }
        })

    }



    render() {
        let params = {};
        if (this.state.useState) {
            params = this.state.params;
        } else {
            params = getSearchParams(this.props[0].location.search, { entries: 10, page: 0 })
        }

        // console.log(JSON.stringify(this.state.data && this.state.data.loginJSON_v2))
        const waitingTimeDisabled = [[true, true], [true, true], [false, true], [true, false], [false, false], [false, false]]

        //console.log(params)
        if (typeof window == 'undefined')
            return null;


        let maxHeight = typeof window !== 'undefined' && document.getElementById('main-container')?.offsetHeight;
        let shameStyle = {}
        if (maxHeight) {
            shameStyle.maxHeight = maxHeight;
            shameStyle.overflow = 'hidden';
        }
        return (
            <div className="shame" style={shameStyle}  /*style={this.state.selectdLandingConfig && this.state.selectdLandingConfig.footerBackground ? { backgroundColor: this.state.selectdLandingConfig.footerBackground } : this.state.data && this.state.data.footerBackground ? { backgroundColor: this.state.data.footerBackground } : {}}*/>


                {this.state.error ? null :
                    <Container fluid>
                        <Row>
                            <Col lg="12" className="p-0">
                                <div className="landing-page-container">
                                    {
                                        typeof window != 'undefined' && window.innerWidth >= 480 ?

                                            <div>

                                                <div className='landing-header'>
                                                    <Container>
                                                        <Row>
                                                            <Col lg="12">
                                                                <div className="login-header">


                                                                    <div className="logo">
                                                                        <img src={API_ENDPOINT + this.state.data.logo} className="landing-page-logo" />
                                                                    </div>


                                                                    <div className="navigation">
                                                                        {this.props.uData ?
                                                                            <div>
                                                                                <span className="username">{this.props.uData.userData.name}</span>
                                                                                <button className={'login-button-landing-page'} style={this.state.data.loginOutline ? { backgroundColor: 'transparent', border: `1px solid ${this.state.data.loginColor}`, color: this.state.data.loginColor } : { background: this.state.data.loginColor, border: `1px solid transparent`, color: 'white' }}
                                                                                    onMouseEnter={() => {
                                                                                        let data = this.state.data;
                                                                                        data.loginOutline = !this.state.data.loginOutline
                                                                                        this.setState({
                                                                                            data
                                                                                        })
                                                                                    }}
                                                                                    onMouseLeave={() => {
                                                                                        let data = this.state.data;
                                                                                        data.loginOutline = !this.state.data.loginOutline
                                                                                        this.setState({
                                                                                            data
                                                                                        })
                                                                                    }}
                                                                                    onClick={() => this.props.signOut()}>{'Logout'.translate(this.props.lang)}</button>
                                                                            </div>
                                                                            : null
                                                                            // :
                                                                            // <button className={'login-button-landing-page'} style={this.state.data.loginOutline ? { backgroundColor: 'transparent', border: `1px solid ${this.state.data.loginColor}`, color: this.state.data.loginColor } : { background: this.state.data.loginColor, border: `1px solid transparent`, color: 'white' }}
                                                                            //     onMouseEnter={() => {
                                                                            //         let data = this.state.data;
                                                                            //         data.loginOutline = !this.state.data.loginOutline
                                                                            //         this.setState({
                                                                            //             data
                                                                            //         })
                                                                            //     }}
                                                                            //     onMouseLeave={() => {
                                                                            //         let data = this.state.data;
                                                                            //         data.loginOutline = !this.state.data.loginOutline
                                                                            //         this.setState({
                                                                            //             data
                                                                            //         })
                                                                            //     }}
                                                                            //     onClick={() => {

                                                                            //         this.setState({
                                                                            //             loginBankIdModal: true
                                                                            //         }, () => {
                                                                            //             // fetch(API_ENDPOINT + '/users/bankid/v2/start', {
                                                                            //             //     method: 'POST',
                                                                            //             //     headers: {
                                                                            //             //         'content-type': 'application/json'
                                                                            //             //     },
                                                                            //             //     body: JSON.stringify({ group: this.state.data._id, alias: this.props[0].match.params.alias })
                                                                            //             // }).then(res => res.json()).then((result) => {
                                                                            //             //     if (result.redirect_url) {
                                                                            //             //         this.setState({

                                                                            //             //             loginBankIdModal: result.redirect_url
                                                                            //             //         })
                                                                            //             //     }
                                                                            //             // })
                                                                            //             this.fetchBankId2()

                                                                            //         })



                                                                            //     }}>{'Login'.translate(this.props.lang)}</button>
                                                                        }
                                                                    </div>
                                                                </div>

                                                            </Col>
                                                        </Row>


                                                    </Container>
                                                </div>

                                            </div>



                                            :
                                            null
                                    }
                                </div>



                                {/* Landing style v2 */}
                                <div>
                                    {
                                        typeof window != 'undefined' && window.innerWidth < 480 ?
                                            this.state.data && this.state.data.loginJSON_v2 ?
                                                <JsonToHtmlV2
                                                    layout={'xs'}
                                                    nodes={JSON.parse(JSON.stringify(this.state.data.loginJSON_v2))}
                                                    loginHandler={() => {
                                                        if (!this.props.uData) {
                                                            this.setState({
                                                                loginBankIdModal: true
                                                            }, () => {

                                                                this.fetchBankId2()
                                                            })
                                                        }
                                                    }}
                                                    loginComponent={
                                                        <div className='login-page' style={{ height: '100%', overflow: 'unset' }}>
                                                            <LoginComponent {...this.props} doctorLogin={true} netID={true} withoutPicture={true} mainAlias={this.props[0].match.params.mainAlias ? this.props[0].match.params.mainAlias : 'noGroupAlias'} alias={this.props[0].match.params.alias ? this.props[0].match.params.alias : 'noGroupAlias'} bankID={true} formLogin={true} />

                                                        </div>                                                        // <div className="mobile-app-login">
                                                        //     <h6 className="landing-login-text">{"Login".translate(this.props.lang)}</h6>
                                                        //     <FormGroup className="landing-login-header">
                                                        //         <Input type="select" value={this.state.type} onChange={(e) => this.setState({ type: e.target.value, bankIdRedirectUrl: null, _iframeLoaded: null, _hideIframe: null }, () => {
                                                        //             if (this.state.type == "bankid") {
                                                        //                 this.fetchBankId()


                                                        //             }
                                                        //         })}>
                                                        //             <option value="bankid">{'Log in with BankID'.translate(this.props.lang)}</option>
                                                        //             <option value="netid">{'Log in with SITHS'.translate(this.props.lang)}</option>
                                                        //             <option value="credentials">{'Log in with credentials'.translate(this.props.lang)}</option>
                                                        //         </Input>
                                                        //     </FormGroup>
                                                        //     {
                                                        //         this.state.type == 'netid' ?
                                                        //             <div className="bank-id-button landing-bank-id-button">
                                                        //                 <Button onClick={() => {
                                                        //                     fetch(API_ENDPOINT + `/users/netid/start/${this.props[0].match.params.alias}`, {
                                                        //                         method: 'GET',
                                                        //                         headers: {
                                                        //                             'content-type': 'application/json'
                                                        //                         },
                                                        //                     }).then(res => res.json()).then((result) => {
                                                        //                         if (result.redirectUrl) {
                                                        //                             window.location.replace(result.redirectUrl);
                                                        //                         }
                                                        //                     })


                                                        //                 }} color="primary">{'Log in with SITHS'.translate(this.props.lang)}</Button>
                                                        //             </div>

                                                        //             :
                                                        //             null
                                                        //     }

                                                        //     {
                                                        //         this.state.type == 'bankid' ?
                                                        //             this.state.bankIdRedirectUrl === null ?
                                                        //                 <div className="loader-wrap">
                                                        //                     <div className="loader" />
                                                        //                 </div>
                                                        //                 :
                                                        //                 <>
                                                        //                     {
                                                        //                         !this.state._iframeLoaded ?


                                                        //                             <div className="loader-wrap">
                                                        //                                 <div className="loader" />
                                                        //                             </div>
                                                        //                             :
                                                        //                             null
                                                        //                     }
                                                        //                     <BankIDModal_v2 {...this.props} />
                                                        //                 </>
                                                        //             :

                                                        //             null
                                                        //     }
                                                        //     <div className="landing-form">
                                                        //         {this.state.type == 'credentials' ?
                                                        //             <FormBuilder dateFormat={this.props.dateFormat}
                                                        //                 lang={this.props.lang}
                                                        //                 onSubmit={(data) => this.login(data)}
                                                        //                 buttonText={'Log in'.translate(this.props.lang)}
                                                        //                 fields={[
                                                        //                     {
                                                        //                         type: 'row',
                                                        //                         children: [
                                                        //                             {
                                                        //                                 type: 'col',
                                                        //                                 width: {
                                                        //                                     lg: 12,
                                                        //                                     sm: 12,
                                                        //                                     xs: 12
                                                        //                                 },
                                                        //                                 children: [
                                                        //                                     {
                                                        //                                         type: 'text',
                                                        //                                         name: 'username',
                                                        //                                         label: 'Username'.translate(this.props.lang),
                                                        //                                         validate: [required('Username is required!'.translate(this.props.lang))],
                                                        //                                         login: true
                                                        //                                     },
                                                        //                                 ]
                                                        //                             },
                                                        //                             {
                                                        //                                 type: 'col',
                                                        //                                 width: {
                                                        //                                     lg: 12,
                                                        //                                     sm: 12,
                                                        //                                     xs: 12
                                                        //                                 },
                                                        //                                 children: [
                                                        //                                     {
                                                        //                                         type: 'password',
                                                        //                                         name: 'password',
                                                        //                                         label: 'Password'.translate(this.props.lang),
                                                        //                                         validate: [required('Password is required!'.translate(this.props.lang))],
                                                        //                                         login: true
                                                        //                                     },
                                                        //                                 ]
                                                        //                             },

                                                        //                         ]
                                                        //                     }
                                                        //                 ]}
                                                        //             ></FormBuilder>
                                                        //             :
                                                        //             null
                                                        //         }
                                                        //     </div>

                                                        //     {this.state.type == 'credentials' ?
                                                        //         <div className="bank-id-button">
                                                        //             <Link to="/forgotpassword">
                                                        //                 {'Forgot password?'.translate(this.props.lang)}</Link>
                                                        //         </div>
                                                        //         :
                                                        //         null}

                                                        //     {this.state.error ?
                                                        //         <p className="error-message">{this.state.error.translate(this.props.lang)}</p>
                                                        //         :
                                                        //         null
                                                        //     }
                                                        // </div>

                                                    }
                                                />
                                                :
                                                defaultLoginJSON_v2 && this.state.showDefaultLogin ?
                                                    <JsonToHtmlV2
                                                        layout={'xs'}
                                                        nodes={JSON.parse(JSON.stringify(defaultLoginJSON_v2))}
                                                        loginHandler={() => {
                                                            if (!this.props.uData) {
                                                                this.setState({
                                                                    loginBankIdModal: true
                                                                }, () => {

                                                                    this.fetchBankId2()
                                                                })
                                                            }
                                                        }}
                                                        loginComponent={
                                                            <div className='login-page' style={{ height: '100%', overflow: 'unset' }}>
                                                                <LoginComponent {...this.props} doctorLogin={true} netID={true} withoutPicture={true} mainAlias={this.props[0].match.params.mainAlias ? this.props[0].match.params.mainAlias : 'noGroupAlias'} alias={this.props[0].match.params.alias ? this.props[0].match.params.alias : 'noGroupAlias'} bankID={true} formLogin={true} />

                                                            </div>
                                                            // <div className="mobile-app-login">
                                                            //     <h6 className="landing-login-text">{"Login".translate(this.props.lang)}</h6>
                                                            //     <FormGroup className="landing-login-header">
                                                            //         <Input type="select" value={this.state.type} onChange={(e) => this.setState({ type: e.target.value, bankIdRedirectUrl: null, _iframeLoaded: null, _hideIframe: null }, () => {
                                                            //             if (this.state.type == "bankid") {
                                                            //                 this.fetchBankId()

                                                            //             }
                                                            //         })}>
                                                            //             <option value="bankid">{'Log in with BankID'.translate(this.props.lang)}</option>
                                                            //             <option value="netid">{'Log in with SITHS'.translate(this.props.lang)}</option>
                                                            //             <option value="credentials">{'Log in with credentials'.translate(this.props.lang)}</option>
                                                            //         </Input>
                                                            //     </FormGroup>
                                                            //     {
                                                            //         this.state.type == 'netid' ?
                                                            //             <div className="bank-id-button landing-bank-id-button">
                                                            //                 <Button onClick={() => {
                                                            //                     fetch(API_ENDPOINT + `/users/netid/start/${this.props[0].match.params.alias}`, {
                                                            //                         method: 'GET',
                                                            //                         headers: {
                                                            //                             'content-type': 'application/json'
                                                            //                         },
                                                            //                     }).then(res => res.json()).then((result) => {
                                                            //                         if (result.redirectUrl) {
                                                            //                             window.location.replace(result.redirectUrl);
                                                            //                         }
                                                            //                     })


                                                            //                 }} color="primary">{'Log in with SITHS'.translate(this.props.lang)}</Button>
                                                            //             </div>

                                                            //             :
                                                            //             null
                                                            //     }

                                                            //     {
                                                            //         this.state.type == 'bankid' ?
                                                            //             this.state.bankIdRedirectUrl === null ?
                                                            //                 <div className="loader-wrap">
                                                            //                     <div className="loader" />
                                                            //                 </div>
                                                            //                 :
                                                            //                 <>
                                                            //                     {
                                                            //                         !this.state._iframeLoaded ?


                                                            //                             <div className="loader-wrap">
                                                            //                                 <div className="loader" />
                                                            //                             </div>
                                                            //                             :
                                                            //                             null
                                                            //                     }<BankIDModal_v2 {...this.props} />
                                                            //                 </>
                                                            //             :

                                                            //             null
                                                            //     }
                                                            //     <div className="landing-form">
                                                            //         {this.state.type == 'credentials' ?
                                                            //             <FormBuilder dateFormat={this.props.dateFormat}
                                                            //                 lang={this.props.lang}
                                                            //                 onSubmit={(data) => this.login(data)}
                                                            //                 buttonText={'Log in'.translate(this.props.lang)}
                                                            //                 fields={[
                                                            //                     {
                                                            //                         type: 'row',
                                                            //                         children: [
                                                            //                             {
                                                            //                                 type: 'col',
                                                            //                                 width: {
                                                            //                                     lg: 12,
                                                            //                                     sm: 12,
                                                            //                                     xs: 12
                                                            //                                 },
                                                            //                                 children: [
                                                            //                                     {
                                                            //                                         type: 'text',
                                                            //                                         name: 'username',
                                                            //                                         label: 'Username'.translate(this.props.lang),
                                                            //                                         validate: [required('Username is required!'.translate(this.props.lang))],
                                                            //                                         login: true
                                                            //                                     },
                                                            //                                 ]
                                                            //                             },
                                                            //                             {
                                                            //                                 type: 'col',
                                                            //                                 width: {
                                                            //                                     lg: 12,
                                                            //                                     sm: 12,
                                                            //                                     xs: 12
                                                            //                                 },
                                                            //                                 children: [
                                                            //                                     {
                                                            //                                         type: 'password',
                                                            //                                         name: 'password',
                                                            //                                         label: 'Password'.translate(this.props.lang),
                                                            //                                         validate: [required('Password is required!'.translate(this.props.lang))],
                                                            //                                         login: true
                                                            //                                     },
                                                            //                                 ]
                                                            //                             },

                                                            //                         ]
                                                            //                     }
                                                            //                 ]}
                                                            //             ></FormBuilder>
                                                            //             :
                                                            //             null
                                                            //         }
                                                            //     </div>

                                                            //     {this.state.type == 'credentials' ?
                                                            //         <div className="bank-id-button">
                                                            //             <Link to="/forgotpassword">
                                                            //                 {'Forgot password?'.translate(this.props.lang)}</Link>
                                                            //         </div>
                                                            //         :
                                                            //         null}

                                                            //     {this.state.error ?
                                                            //         <p className="error-message">{this.state.error.translate(this.props.lang)}</p>
                                                            //         :
                                                            //         null
                                                            //     }
                                                            // </div>

                                                        }
                                                    />
                                                    :
                                                    null
                                            :
                                            typeof window != 'undefined' && window.innerWidth >= 480 && window.innerWidth < 1200 ?
                                                this.state.data && this.state.data.loginJSON_v2 ?
                                                    <JsonToHtmlV2
                                                        layout={'sm'}
                                                        nodes={JSON.parse(JSON.stringify(this.state.data.loginJSON_v2))}
                                                        loginHandler={() => {
                                                            if (!this.props.uData) {
                                                                this.setState({
                                                                    loginBankIdModal: true
                                                                }, () => {

                                                                    this.fetchBankId2()
                                                                })
                                                            }
                                                        }}
                                                        loginComponent={
                                                            <div className='login-page' style={{ height: '100%', overflow: 'unset' }}>
                                                                <LoginComponent {...this.props} doctorLogin={true} netID={true} withoutPicture={true} mainAlias={this.props[0].match.params.mainAlias ? this.props[0].match.params.mainAlias : 'noGroupAlias'} alias={this.props[0].match.params.alias ? this.props[0].match.params.alias : 'noGroupAlias'} bankID={true} formLogin={true} />

                                                            </div>
                                                            // <div className="mobile-app-login">
                                                            //     <h6 className="landing-login-text">{"Login".translate(this.props.lang)}</h6>
                                                            //     <FormGroup className="landing-login-header">
                                                            //         <Input type="select" value={this.state.type} onChange={(e) => this.setState({ type: e.target.value, bankIdRedirectUrl: null, _iframeLoaded: null, _hideIframe: null }, () => {
                                                            //             if (this.state.type == "bankid") {
                                                            //                 this.fetchBankId()

                                                            //             }
                                                            //         })}>
                                                            //             <option value="bankid">{'Log in with BankID'.translate(this.props.lang)}</option>
                                                            //             <option value="netid">{'Log in with SITHS'.translate(this.props.lang)}</option>
                                                            //             <option value="credentials">{'Log in with credentials'.translate(this.props.lang)}</option>
                                                            //         </Input>
                                                            //     </FormGroup>
                                                            //     {
                                                            //         this.state.type == 'netid' ?
                                                            //             <div className="bank-id-button landing-bank-id-button">
                                                            //                 <Button onClick={() => {
                                                            //                     fetch(API_ENDPOINT + `/users/netid/start/${this.props[0].match.params.alias}`, {
                                                            //                         method: 'GET',
                                                            //                         headers: {
                                                            //                             'content-type': 'application/json'
                                                            //                         },
                                                            //                     }).then(res => res.json()).then((result) => {
                                                            //                         if (result.redirectUrl) {
                                                            //                             window.location.replace(result.redirectUrl);
                                                            //                         }
                                                            //                     })


                                                            //                 }} color="primary">{'Log in with SITHS'.translate(this.props.lang)}</Button>
                                                            //             </div>

                                                            //             :
                                                            //             null
                                                            //     }

                                                            //     {
                                                            //         this.state.type == 'bankid' ?
                                                            //             this.state.bankIdRedirectUrl === null ?
                                                            //                 <div className="loader-wrap">
                                                            //                     <div className="loader" />
                                                            //                 </div>
                                                            //                 :
                                                            //                 <>
                                                            //                     {
                                                            //                         !this.state._iframeLoaded ?


                                                            //                             <div className="loader-wrap">
                                                            //                                 <div className="loader" />
                                                            //                             </div>
                                                            //                             :
                                                            //                             null
                                                            //                     }
                                                            //                     <BankIDModal_v2 {...this.props} />
                                                            //                 </>
                                                            //             :

                                                            //             null
                                                            //     }
                                                            //     <div className="landing-form">
                                                            //         {this.state.type == 'credentials' ?
                                                            //             <FormBuilder dateFormat={this.props.dateFormat}
                                                            //                 lang={this.props.lang}
                                                            //                 onSubmit={(data) => this.login(data)}
                                                            //                 buttonText={'Log in'.translate(this.props.lang)}
                                                            //                 fields={[
                                                            //                     {
                                                            //                         type: 'row',
                                                            //                         children: [
                                                            //                             {
                                                            //                                 type: 'col',
                                                            //                                 width: {
                                                            //                                     lg: 12,
                                                            //                                     sm: 12,
                                                            //                                     xs: 12
                                                            //                                 },
                                                            //                                 children: [
                                                            //                                     {
                                                            //                                         type: 'text',
                                                            //                                         name: 'username',
                                                            //                                         label: 'Username'.translate(this.props.lang),
                                                            //                                         validate: [required('Username is required!'.translate(this.props.lang))],
                                                            //                                         login: true
                                                            //                                     },
                                                            //                                 ]
                                                            //                             },
                                                            //                             {
                                                            //                                 type: 'col',
                                                            //                                 width: {
                                                            //                                     lg: 12,
                                                            //                                     sm: 12,
                                                            //                                     xs: 12
                                                            //                                 },
                                                            //                                 children: [
                                                            //                                     {
                                                            //                                         type: 'password',
                                                            //                                         name: 'password',
                                                            //                                         label: 'Password'.translate(this.props.lang),
                                                            //                                         validate: [required('Password is required!'.translate(this.props.lang))],
                                                            //                                         login: true
                                                            //                                     },
                                                            //                                 ]
                                                            //                             },

                                                            //                         ]
                                                            //                     }
                                                            //                 ]}
                                                            //             ></FormBuilder>
                                                            //             :
                                                            //             null
                                                            //         }
                                                            //     </div>

                                                            //     {this.state.type == 'credentials' ?
                                                            //         <div className="bank-id-button">
                                                            //             <Link to="/forgotpassword">
                                                            //                 {'Forgot password?'.translate(this.props.lang)}</Link>
                                                            //         </div>
                                                            //         :
                                                            //         null}

                                                            //     {this.state.error ?
                                                            //         <p className="error-message">{this.state.error.translate(this.props.lang)}</p>
                                                            //         :
                                                            //         null
                                                            //     }
                                                            // </div>

                                                        }
                                                    />
                                                    :
                                                    defaultLoginJSON_v2 && this.state.showDefaultLogin ?
                                                        <JsonToHtmlV2
                                                            layout={'sm'}
                                                            nodes={JSON.parse(JSON.stringify(defaultLoginJSON_v2))}
                                                            loginHandler={() => {
                                                                if (!this.props.uData) {
                                                                    this.setState({
                                                                        loginBankIdModal: true
                                                                    }, () => {

                                                                        this.fetchBankId2()
                                                                    })
                                                                }
                                                            }}
                                                            loginComponent={
                                                                <div className='login-page' style={{ height: '100%', overflow: 'unset' }}>
                                                                    <LoginComponent {...this.props} doctorLogin={true} netID={true} withoutPicture={true} mainAlias={this.props[0].match.params.mainAlias ? this.props[0].match.params.mainAlias : 'noGroupAlias'} alias={this.props[0].match.params.alias ? this.props[0].match.params.alias : 'noGroupAlias'} bankID={true} formLogin={true} />

                                                                </div>
                                                                // <div className="mobile-app-login">
                                                                //     <h6 className="landing-login-text">{"Login".translate(this.props.lang)}</h6>
                                                                //     <FormGroup className="landing-login-header">
                                                                //         <Input type="select" value={this.state.type} onChange={(e) => this.setState({ type: e.target.value, bankIdRedirectUrl: null, _iframeLoaded: null, _hideIframe: null }, () => {
                                                                //             if (this.state.type == "bankid") {
                                                                //                 this.fetchBankId()

                                                                //             }
                                                                //         })}>
                                                                //             <option value="bankid">{'Log in with BankID'.translate(this.props.lang)}</option>
                                                                //             <option value="netid">{'Log in with SITHS'.translate(this.props.lang)}</option>
                                                                //             <option value="credentials">{'Log in with credentials'.translate(this.props.lang)}</option>
                                                                //         </Input>
                                                                //     </FormGroup>
                                                                //     {
                                                                //         this.state.type == 'netid' ?
                                                                //             <div className="bank-id-button landing-bank-id-button">
                                                                //                 <Button onClick={() => {
                                                                //                     fetch(API_ENDPOINT + `/users/netid/start/${this.props[0].match.params.alias}`, {
                                                                //                         method: 'GET',
                                                                //                         headers: {
                                                                //                             'content-type': 'application/json'
                                                                //                         },
                                                                //                     }).then(res => res.json()).then((result) => {
                                                                //                         if (result.redirectUrl) {
                                                                //                             window.location.replace(result.redirectUrl);
                                                                //                         }
                                                                //                     })


                                                                //                 }} color="primary">{'Log in with SITHS'.translate(this.props.lang)}</Button>
                                                                //             </div>

                                                                //             :
                                                                //             null
                                                                //     }

                                                                //     {
                                                                //         this.state.type == 'bankid' ?
                                                                //             this.state.bankIdRedirectUrl === null ?
                                                                //                 <div className="loader-wrap">
                                                                //                     <div className="loader" />
                                                                //                 </div>
                                                                //                 :
                                                                //                 <>
                                                                //                     {
                                                                //                         !this.state._iframeLoaded ?


                                                                //                             <div className="loader-wrap">
                                                                //                                 <div className="loader" />
                                                                //                             </div>
                                                                //                             :
                                                                //                             null
                                                                //                     }
                                                                //                     <BankIDModal_v2 {...this.props} />


                                                                //                 </>
                                                                //             :

                                                                //             null
                                                                //     }
                                                                //     <div className="landing-form">
                                                                //         {this.state.type == 'credentials' ?
                                                                //             <FormBuilder dateFormat={this.props.dateFormat}
                                                                //                 lang={this.props.lang}
                                                                //                 onSubmit={(data) => this.login(data)}
                                                                //                 buttonText={'Log in'.translate(this.props.lang)}
                                                                //                 fields={[
                                                                //                     {
                                                                //                         type: 'row',
                                                                //                         children: [
                                                                //                             {
                                                                //                                 type: 'col',
                                                                //                                 width: {
                                                                //                                     lg: 12,
                                                                //                                     sm: 12,
                                                                //                                     xs: 12
                                                                //                                 },
                                                                //                                 children: [
                                                                //                                     {
                                                                //                                         type: 'text',
                                                                //                                         name: 'username',
                                                                //                                         label: 'Username'.translate(this.props.lang),
                                                                //                                         validate: [required('Username is required!'.translate(this.props.lang))],
                                                                //                                         login: true
                                                                //                                     },
                                                                //                                 ]
                                                                //                             },
                                                                //                             {
                                                                //                                 type: 'col',
                                                                //                                 width: {
                                                                //                                     lg: 12,
                                                                //                                     sm: 12,
                                                                //                                     xs: 12
                                                                //                                 },
                                                                //                                 children: [
                                                                //                                     {
                                                                //                                         type: 'password',
                                                                //                                         name: 'password',
                                                                //                                         label: 'Password'.translate(this.props.lang),
                                                                //                                         validate: [required('Password is required!'.translate(this.props.lang))],
                                                                //                                         login: true
                                                                //                                     },
                                                                //                                 ]
                                                                //                             },

                                                                //                         ]
                                                                //                     }
                                                                //                 ]}
                                                                //             ></FormBuilder>
                                                                //             :
                                                                //             null
                                                                //         }
                                                                //     </div>

                                                                //     {this.state.type == 'credentials' ?
                                                                //         <div className="bank-id-button">
                                                                //             <Link to="/forgotpassword">
                                                                //                 {'Forgot password?'.translate(this.props.lang)}</Link>
                                                                //         </div>
                                                                //         :
                                                                //         null}

                                                                //     {this.state.error ?
                                                                //         <p className="error-message">{this.state.error.translate(this.props.lang)}</p>
                                                                //         :
                                                                //         null
                                                                //     }
                                                                // </div>

                                                            }
                                                        />
                                                        :
                                                        null
                                                :

                                                typeof window != 'undefined' && window.innerWidth >= 1200 && this.state.data && this.state.data.loginJSON_v2 ?
                                                    <JsonToHtmlV2
                                                        layout={'lg'}
                                                        nodes={JSON.parse(JSON.stringify(this.state.data.loginJSON_v2))}
                                                        loginHandler={() => {
                                                            if (!this.props.uData) {
                                                                this.setState({
                                                                    loginBankIdModal: true
                                                                }, () => {

                                                                    this.fetchBankId2()
                                                                })
                                                            }
                                                        }}
                                                        loginComponent={
                                                            <div className='login-page' style={{ height: '100%', overflow: 'unset' }}>
                                                                <LoginComponent {...this.props} doctorLogin={true} netID={true} withoutPicture={true} mainAlias={this.props[0].match.params.mainAlias ? this.props[0].match.params.mainAlias : 'noGroupAlias'} alias={this.props[0].match.params.alias ? this.props[0].match.params.alias : 'noGroupAlias'} bankID={true} formLogin={true} />

                                                            </div>
                                                            // <div>
                                                            //     <h6 className="landing-login-text">{"Login".translate(this.props.lang)}</h6>
                                                            //     <FormGroup className="landing-login-header">
                                                            //         <Input type="select" value={this.state.type} onChange={(e) => this.setState({ type: e.target.value, bankIdRedirectUrl: null, _iframeLoaded: null, _hideIframe: null }, () => {
                                                            //             if (this.state.type == "bankid") {
                                                            //                 this.fetchBankId()

                                                            //             }
                                                            //         })}>
                                                            //             <option value="bankid">{'Log in with BankID'.translate(this.props.lang)}</option>
                                                            //             <option value="netid">{'Log in with SITHS'.translate(this.props.lang)}</option>
                                                            //             <option value="credentials">{'Log in with credentials'.translate(this.props.lang)}</option>
                                                            //         </Input>
                                                            //     </FormGroup>
                                                            //     {
                                                            //         this.state.type == 'netid' ?
                                                            //             <div className="bank-id-button landing-bank-id-button">
                                                            //                 <Button onClick={() => {
                                                            //                     fetch(API_ENDPOINT + `/users/netid/start/${this.props[0].match.params.alias}`, {
                                                            //                         method: 'GET',
                                                            //                         headers: {
                                                            //                             'content-type': 'application/json'
                                                            //                         },
                                                            //                     }).then(res => res.json()).then((result) => {
                                                            //                         if (result.redirectUrl) {
                                                            //                             window.location.replace(result.redirectUrl);
                                                            //                         }
                                                            //                     })


                                                            //                 }} color="primary">{'Log in with SITHS'.translate(this.props.lang)}</Button>
                                                            //             </div>

                                                            //             :
                                                            //             null
                                                            //     }

                                                            //     {
                                                            //         this.state.type == 'bankid' ?
                                                            //             this.state.bankIdRedirectUrl === null ?
                                                            //                 <div className="loader-wrap">
                                                            //                     <div className="loader" />
                                                            //                 </div>
                                                            //                 :
                                                            //                 <>
                                                            //                     {
                                                            //                         !this.state._iframeLoaded ?


                                                            //                             <div className="loader-wrap">
                                                            //                                 <div className="loader" />
                                                            //                             </div>
                                                            //                             :
                                                            //                             null
                                                            //                     }
                                                            //                     <BankIDModal_v2 {...this.props} />


                                                            //                 </>
                                                            //             :

                                                            //             null
                                                            //     }
                                                            //     <div className="landing-form">
                                                            //         {this.state.type == 'credentials' ?
                                                            //             <FormBuilder dateFormat={this.props.dateFormat}
                                                            //                 lang={this.props.lang}
                                                            //                 onSubmit={(data) => this.login(data)}
                                                            //                 buttonText={'Log in'.translate(this.props.lang)}
                                                            //                 fields={[
                                                            //                     {
                                                            //                         type: 'row',
                                                            //                         children: [
                                                            //                             {
                                                            //                                 type: 'col',
                                                            //                                 width: {
                                                            //                                     lg: 12,
                                                            //                                     sm: 12,
                                                            //                                     xs: 12
                                                            //                                 },
                                                            //                                 children: [
                                                            //                                     {
                                                            //                                         type: 'text',
                                                            //                                         name: 'username',
                                                            //                                         label: 'Username'.translate(this.props.lang),
                                                            //                                         validate: [required('Username is required!'.translate(this.props.lang))],
                                                            //                                         login: true
                                                            //                                     },
                                                            //                                 ]
                                                            //                             },
                                                            //                             {
                                                            //                                 type: 'col',
                                                            //                                 width: {
                                                            //                                     lg: 12,
                                                            //                                     sm: 12,
                                                            //                                     xs: 12
                                                            //                                 },
                                                            //                                 children: [
                                                            //                                     {
                                                            //                                         type: 'password',
                                                            //                                         name: 'password',
                                                            //                                         label: 'Password'.translate(this.props.lang),
                                                            //                                         validate: [required('Password is required!'.translate(this.props.lang))],
                                                            //                                         login: true
                                                            //                                     },
                                                            //                                 ]
                                                            //                             },

                                                            //                         ]
                                                            //                     }
                                                            //                 ]}
                                                            //             ></FormBuilder>
                                                            //             :
                                                            //             null
                                                            //         }
                                                            //     </div>

                                                            //     {this.state.type == 'credentials' ?
                                                            //         <div className="bank-id-button">
                                                            //             <Link to="/forgotpassword">
                                                            //                 {'Forgot password?'.translate(this.props.lang)}</Link>
                                                            //         </div>
                                                            //         :
                                                            //         null}

                                                            //     {this.state.error ?
                                                            //         <p className="error-message">{this.state.error.translate(this.props.lang)}</p>
                                                            //         :
                                                            //         null
                                                            //     }
                                                            // </div>

                                                        }
                                                    />
                                                    :
                                                    defaultLoginJSON_v2 && this.state.showDefaultLogin ?
                                                        <JsonToHtmlV2
                                                            layout={'lg'}
                                                            nodes={JSON.parse(JSON.stringify(defaultLoginJSON_v2))}
                                                            loginHandler={() => {
                                                                if (!this.props.uData) {
                                                                    this.setState({
                                                                        loginBankIdModal: true
                                                                    }, () => {

                                                                        this.fetchBankId2()
                                                                    })
                                                                }
                                                            }}
                                                            loginComponent={
                                                                <div className='login-page' style={{ height: '100%', overflow: 'unset' }}>
                                                                    <LoginComponent {...this.props} doctorLogin={true} netID={true} withoutPicture={true} mainAlias={this.props[0].match.params.mainAlias ? this.props[0].match.params.mainAlias : 'noGroupAlias'} alias={this.props[0].match.params.alias ? this.props[0].match.params.alias : 'noGroupAlias'} bankID={true} formLogin={true} />

                                                                </div>

                                                                // <div>
                                                                //     <h6 className="landing-login-text">{"Login".translate(this.props.lang)}</h6>
                                                                //     <FormGroup className="landing-login-header">
                                                                //         <Input type="select" value={this.state.type} onChange={(e) => this.setState({ type: e.target.value, bankIdRedirectUrl: null, _iframeLoaded: null, _hideIframe: null }, () => {
                                                                //             if (this.state.type == "bankid") {
                                                                //                 this.fetchBankId()

                                                                //             }
                                                                //         })}>
                                                                //             <option value="bankid">{'Log in with BankID'.translate(this.props.lang)}</option>
                                                                //             <option value="netid">{'Log in with SITHS'.translate(this.props.lang)}</option>
                                                                //             <option value="credentials">{'Log in with credentials'.translate(this.props.lang)}</option>
                                                                //         </Input>
                                                                //     </FormGroup>
                                                                //     {
                                                                //         this.state.type == 'netid' ?
                                                                //             <div className="bank-id-button landing-bank-id-button">
                                                                //                 <Button onClick={() => {
                                                                //                     fetch(API_ENDPOINT + `/users/netid/start/${this.props[0].match.params.alias}`, {
                                                                //                         method: 'GET',
                                                                //                         headers: {
                                                                //                             'content-type': 'application/json'
                                                                //                         },
                                                                //                     }).then(res => res.json()).then((result) => {
                                                                //                         if (result.redirectUrl) {
                                                                //                             window.location.replace(result.redirectUrl);
                                                                //                         }
                                                                //                     })


                                                                //                 }} color="primary">{'Log in with SITHS'.translate(this.props.lang)}</Button>
                                                                //             </div>

                                                                //             :
                                                                //             null
                                                                //     }

                                                                //     {
                                                                //         this.state.type == 'bankid' ?
                                                                //             this.state.bankIdRedirectUrl === null ?
                                                                //                 <div className="loader-wrap">
                                                                //                     <div className="loader" />
                                                                //                 </div>
                                                                //                 :
                                                                //                 <>
                                                                //                     {
                                                                //                         !this.state._iframeLoaded ?


                                                                //                             <div className="loader-wrap">
                                                                //                                 <div className="loader" />
                                                                //                             </div>
                                                                //                             :
                                                                //                             null
                                                                //                     }
                                                                //                     <BankIDModal_v2 {...this.props} />


                                                                //                 </>
                                                                //             :

                                                                //             null
                                                                //     }
                                                                //     <div className="landing-form">
                                                                //         {this.state.type == 'credentials' ?
                                                                //             <FormBuilder dateFormat={this.props.dateFormat}
                                                                //                 lang={this.props.lang}
                                                                //                 onSubmit={(data) => this.login(data)}
                                                                //                 buttonText={'Log in'.translate(this.props.lang)}
                                                                //                 fields={[
                                                                //                     {
                                                                //                         type: 'row',
                                                                //                         children: [
                                                                //                             {
                                                                //                                 type: 'col',
                                                                //                                 width: {
                                                                //                                     lg: 12,
                                                                //                                     sm: 12,
                                                                //                                     xs: 12
                                                                //                                 },
                                                                //                                 children: [
                                                                //                                     {
                                                                //                                         type: 'text',
                                                                //                                         name: 'username',
                                                                //                                         label: 'Username'.translate(this.props.lang),
                                                                //                                         validate: [required('Username is required!'.translate(this.props.lang))],
                                                                //                                         login: true
                                                                //                                     },
                                                                //                                 ]
                                                                //                             },
                                                                //                             {
                                                                //                                 type: 'col',
                                                                //                                 width: {
                                                                //                                     lg: 12,
                                                                //                                     sm: 12,
                                                                //                                     xs: 12
                                                                //                                 },
                                                                //                                 children: [
                                                                //                                     {
                                                                //                                         type: 'password',
                                                                //                                         name: 'password',
                                                                //                                         label: 'Password'.translate(this.props.lang),
                                                                //                                         validate: [required('Password is required!'.translate(this.props.lang))],
                                                                //                                         login: true
                                                                //                                     },
                                                                //                                 ]
                                                                //                             },

                                                                //                         ]
                                                                //                     }
                                                                //                 ]}
                                                                //             ></FormBuilder>
                                                                //             :
                                                                //             null
                                                                //         }
                                                                //     </div>

                                                                //     {this.state.type == 'credentials' ?
                                                                //         <div className="bank-id-button">
                                                                //             <Link to="/forgotpassword">
                                                                //                 {'Forgot password?'.translate(this.props.lang)}</Link>
                                                                //         </div>
                                                                //         :
                                                                //         null}

                                                                //     {this.state.error ?
                                                                //         <p className="error-message">{this.state.error.translate(this.props.lang)}</p>
                                                                //         :
                                                                //         null
                                                                //     }
                                                                // </div>

                                                            }
                                                        />
                                                        :
                                                        null
                                    }
                                </div>

                                {/* ----- */}

                                {
                                    this.state.data.footerSettings ?
                                        <FooterComponent {...this.props} data={this.state.data} />

                                        :
                                        <div className="landing-footer footer-space">
                                            <Container>
                                                <Row>
                                                    <Col lg="12">
                                                        <ul className="landing-footer">
                                                            {this.state.data.privacyPolicy ? <li><Link to={`/${this.props[0].match.params.alias}/privacy-policy`} target="_blank"> {'Privacy policy'.translate(this.props.lang)} </Link></li> : null}
                                                            {this.state.data.termsOfUse ? <li><a href={`/${this.props[0].match.params.alias}/terms-of-use`} target="_blank"> {'Terms of use'.translate(this.props.lang)} </a></li> : null}
                                                            {this.state.data.accessibilityReport ? <li><a href={`/${this.props[0].match.params.alias}/accessibility-report`} target="_blank"> {'Accessibility Report'.translate(this.props.lang)} </a></li> : null}
                                                        </ul>
                                                    </Col>
                                                    <Col lg="12">
                                                        <ul className="landing-footer landing-footer-list">
                                                            <li>{this.state.data.address ? this.state.data.address[0] ? this.state.data.address[1] || this.state.data.address[2] ? this.state.data.address[0] + ',' : this.state.data.address[0] : '' : ''}</li>
                                                            <li>{this.state.data.address ? this.state.data.address[1] ? this.state.data.address[2] ? this.state.data.address[1] + ',' : this.state.data.address[1] : '' : ''}</li>
                                                            <li>{this.state.data.address ? this.state.data.address[2] : ''}</li>
                                                        </ul>

                                                    </Col>
                                                </Row>
                                            </Container>
                                        </div>
                                }


                            </Col>

                        </Row>
                    </Container>
                }

                {this.state.error ?

                    <Modal isOpen={this.state.error} centered>

                        <ModalBody className="delete-modal">
                            {/* <img src={error} /> */}
                            <div className="message">
                                {
                                    this.state.error
                                }
                            </div>
                        </ModalBody>
                        <ModalFooter className='buttons-right-blue'>
                            <Button style={{ minWidth: 100 }} type='button' color='primary' onClick={() => this.setState({ error: null })}>
                                {'Close'.translate(this.props.lang)}
                            </Button>
                        </ModalFooter>
                    </Modal>

                    :
                    null
                }

                {
                    this.state.bankIdModal ?
                        <BankIdModal
                            {...this.props}
                            isOpen={this.state.bankIdModal}
                            toggle={() => {

                                if (this.props.uData) {

                                    localStorage.setItem('groupAlias', this.props[0].match.params.alias);

                                    this.props[0].history.push(`/${this.props[0].match.params.alias}/personal-referral`)
                                }
                                this.setState({ bankIdModal: null })

                            }}
                            redirect_url={this.state.bankIdModal}></BankIdModal>
                        :

                        null
                }
                {
                    this.state.loginBankIdModal ?
                        <BankIdModal
                            {...this.props}
                            isOpen={this.state.loginBankIdModal}
                            toggle={() => {

                                if (this.props.uData) {
                                    // this.props.registerClinicGroupLogs({
                                    //     type: 'login',
                                    //     loginType: 'bankid'
                                    // })
                                    localStorage.setItem('groupAlias', this.props[0].match.params.alias);
                                }
                                this.setState({ loginBankIdModal: null })

                            }}
                            redirect_url={this.state.loginBankIdModal}></BankIdModal>
                        :

                        null
                }
                {/* {
                    this.state.bankIdModal1 ?
                        <BankIdModal
                            {...this.props}
                            isOpen={this.state.bankIdModal1}
                            toggle={() => {

                                if (this.props.uData) {

                                    localStorage.setItem('groupAlias', this.props[0].match.params.alias);
                                    if (!this.props.uData.haveReferrals && !this.props.uData.havePersonalReferrals) {
                                        this.setState({
                                            error: 'You have no active issues with us...'.translate(this.props.lang)
                                        })
                                        return;
                                    }

                                    this.props[0].history.push(`/my-data/personal-referrals/referrals`)
                                }
                                this.setState({ bankIdModal1: null })

                            }}
                            redirect_url={this.state.bankIdModal1}></BankIdModal>
                        :

                        null
                } */}


                {this.state.error ?
                    <ErrorModal lang={this.props.lang}
                        isOpen={this.state.error}
                        toggle={() => this.setState({ error: null })}

                    >
                        {this.state.error.translate(this.props.lang)}
                    </ErrorModal>
                    :
                    null

                }


            </div>
        );
    }
}

const selector = formValueSelector('modalForm');

export default connect(state => {
    return { selectedWaitingTimeType: selector(state, 'selectedWaitingTimeType') }

}, {
    changeMinValue: value => change("modalForm", "waitingMin", value),
    changeMaxValue: value => change("modalForm", "waitingMax", value),
    changeSelectedWaitingTimeType: value => change("modalForm", "selectedWaitingTimeType", value),

})(Page(Account));
