import React, { Component } from 'react';
import Page from '../containers/page';

import { Link } from 'react-router-dom';

import {
    Container,
    Row,
    Col,
    Button,
    FormGroup,
    Label,
    Input,
    Modal, ModalHeader, ModalBody, ModalFooter, Form
} from 'reactstrap';


import { API_ENDPOINT } from '../../constants';


function parseJSON(response) {
    return response.json().then(json => {
        return {
            result: json,
            status: response.status,
            disabledForm: false
        }
    })
}

import AnswerFormComponent from './answerFormComponent';


class PreviewAnsweredChatForm extends Component {
    constructor(props) {
        super(props);
        this.searchResultsWraps = {};

        this.state = {
            ...props.initialData,
            answers: {},
            disabledForm: false,
            sections: [],
        };
    }

    componentDidMount() {
        let setObj = {}
        let referral = {

        };

        if (this.props.referral) {
            referral = this.props.referral;
        }

        if (this.props.sections) {
            referral.sections = this.props.sections;
        }
        if (this.props.answers) {
            setObj.answers = this.props.answers;
        }
        setObj.disabledForm = true;

        setObj.referral = referral;

        this.setState(setObj)

        if (this.props.alias) {
            this.getGroup(this.props.alias);
        }


    }

    getGroup = (alias) => {
        fetch(API_ENDPOINT + '/landing/page', {
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify({ alias })
        }).then(res => res.json()).then((result) => {
            if (result && result._id) {
                this.setState({ clinicGroup: result })
            }
        })
    }

    componentDidUpdate(prevProps, prevState) {
        if (!prevState.questionsRef && this.state.questionsRef && this.props.referralContentRef && this.titleRef && this.props.forPrint) {
            if (this.props.referralContentRef) {
                let children = this.state.questionsRef.children;
                let pageHeight = this.props.referralContentRef.offsetHeight + this.titleRef.offsetHeight;
                const requiredPageHeight = 1550;
                for (let i = 0; i < children.length; i++) {
                    pageHeight += children[i].offsetHeight + 16;
                    if (pageHeight > requiredPageHeight) {
                        children[i].style.marginTop = ((children[i].offsetHeight + 16) - (pageHeight - requiredPageHeight)) + 'px';
                        pageHeight = children[i].offsetHeight;
                    }

                }
            }

        }

    }





    render() {
        return (
            <div>

                <Container fluid>
                    <Row>
                        {this.state.referral?.sections?.length ?
                            <Col lg="12">
                                <AnswerFormComponent
                                    {...this.props}
                                    referral={this.state.referral}
                                    clinicGroup={this.state.clinicGroup}
                                    inModal={true}
                                    hideSubmitButton={true}
                                    preview={true}
                                    forPrint={this.props.forPrint}
                                    forPrintHtml={this.props.forPrintHtml}
                                    previewAnsweredChatForm={true}
                                   
                                />
                            </Col>
                            :
                            null
                        }

                    </Row>

                </Container>





            </div >
        );
    }
}

export default PreviewAnsweredChatForm;