
import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import {
    FormGroup, Label, Form, FormFeedback
} from 'reactstrap';

import Isvg from 'react-inlinesvg';
import image from '../../../assets/svg/picture.svg';
import Attach from '../../../assets/svg/attach.svg';
import File from '../../../assets/svg/fajl.svg';
import { API_ENDPOINT } from '../../../constants';
import { Player, Controls } from "@lottiefiles/react-lottie-player";
import axios from 'axios';

import ErrorModal from '../../../components/errorModal';



function parseText(response) {
    return response.text().then(text => {
        return {
            result: text,
            status: response.status
        }
    })
}
function parseJSON(response) {
    return response.json().then(json => {
        return {
            result: json,
            status: response.status
        }
    })
}


/**
* Component for import files
* @author   Milan Stanojevic
*/
class Image extends Component {
    constructor(props) {
        super(props);
        this.selectFile = this.selectFile.bind(this);

        this.state = {

        };
    }

    handleUploadFileProgress = (data, progress) => {
        // if (data){
        //   IdleTimerManager.setIdleTimerDisabled(true);
        // }else{
        //   IdleTimerManager.setIdleTimerDisabled(false);
        // }
        this.setState({
            uploadingFile: data,
            uploadingFileProgress: progress
        })
    }

    selectFile(e) {
        this.setState({
            error: null
        })
        let input = e.target;
        if (input.files && input.files[0]) {

            let fsize = input.files[0].size;
            let file = Math.round((fsize / 1024))

            let isVideo = input.files[0].name.toLowerCase().indexOf('.mp4');

            if (isVideo == -1) {
                isVideo = input.files[0].name.toLowerCase().indexOf('.mov');
            }


            if (file > 5120 && isVideo == -1) {
                this.setState({ error: "File size limit has been reached! (max 5 MB)".translate(this.props.lang) })
            } else if (file > 524288 && isVideo > -1) {
                this.setState({ error: "File size limit has been reached! (max 512 MB)".translate(this.props.lang) })
            } else {
                this.setState({
                    _loading: true
                })

                let formData = new FormData();
                formData.append('file', input.files[0]);
                if (this.props.alias) {
                    formData.append('alias', this.props.alias)
                } else {
                    formData.append('group', this.props.group)
                    formData.append('fileType', 'clinicData')
                }
                if (this.props.acceptTypes) {
                    formData.append('acceptTypes', this.props.acceptTypes)
                }

                this.setState({ origValue: this.props.value })
                this.props.onChange('loading')

                this.handleUploadFileProgress('Processing file...'.translate(this.props.lang));


                axios.post(API_ENDPOINT + '/data/upload-document', formData, {
                    headers: {
                        Accept: 'application/json',
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    },
                    onUploadProgress: (progressEvent) => {
                        this.handleUploadFileProgress('Uploading file...'.translate(this.props.lang), Math.round((progressEvent.loaded * 100) / progressEvent.total));

                    }
                }).then((response) => {
                    if (response.status != 200) {
                        this.setState({ error: response.data.translate(this.props.lang), _loading: null })
                    } else {
                        let obj = { fileName: input.files[0].name, file: response.data };
                        this.props.onChange(obj);
                        this.setState({
                            _loading: null
                        })
                    }
                }).catch((error) => {
                    if (error.response) {
                        if (error.response.status != 200)
                            this.setState({ error: error.response.data.translate(this.props.lang), _loading: null })

                    }
                })



                // fetch(API_ENDPOINT + '/data/upload-document', {
                //     method: 'POST',
                //     headers: {
                //         Accept: 'application/json',
                //         'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,

                //     },
                //     body: formData
                // }).then(parseText).then(({ result, status }) => {
                //     if (status != 200) {
                //         this.setState({ error: result.translate(this.props.lang) })
                //     } else {
                //         let obj = { fileName: input.files[0].name, file: result };
                //         this.props.onChange(obj);
                //         this.setState({
                //             _loading: null
                //         })
                //     }
                // })
            }
        }
    }

    render() {
        // console.log(this.props)
        return (
            <FormGroup className="image-picker-wrap">
                <Label className={this.props.className ? this.props.className : null} size="sm">{this.props.label}{/*this.props.haveValidation ? <span className="required-label-span">*</span> : null*/}</Label>

                <div className="image-picker">
                    {!this.props.value ? <input type="file" accept={this.props.acceptTypes ? this.props.acceptTypes : "image/png, image/jpeg, image/jpg, image/gif, image/svg, .doc, .docx, .pdf, .mp4, .webm, .avi, .DOC, .DOCX, .PDF, .MP4, .WEBM, .AVI "} title={"No file chosen".translate(this.props.lang)} onChange={this.selectFile} key={this.props.value && typeof this.props.value == 'object' ? this.props.value.file : this.props.value} /> : null}
                    {this.props.value ?
                        <img src={File} />
                        :
                        null
                    }
                    {this.state._loading ?
                        <div className="file-progress-loader">
                            <div>
                                <Player
                                    onEvent={(event) => {
                                        if (event === "load" && this.player && this.player.current) this.player.current.play();
                                    }}
                                    ref={this.player} // set the ref to your class instance
                                    autoplay={true}
                                    loop={true}
                                    controls={true}
                                    src="/lf30_editor_l5cxzdyf.json"
                                    renderer={'svg'}
                                    style={{ height: "128px", width: "128px", }}
                                ></Player>
                                <h6>{this.state.uploadingFileProgress ? ('Uploading file...'.translate(this.props.lang) + this.state.uploadingFileProgress + '%') : 'Processing file...'.translate(this.props.lang)}</h6>
                            </div>
                        </div>
                        : null
                    }

                    {
                        this.props.value ?
                            <button className="delete" onClick={() => this.props.onChange(null)}>&times;</button>
                            :
                            null
                    }


                    <div className="bottom-content">
                        <Isvg src={Attach} />
                        <p> <span>{'Upload a file'.translate(this.props.lang)}</span> {'or drag and drop'.translate(this.props.lang)}</p>
                    </div>

                </div>
                {/* {this.props.error ? <FormFeedback >{this.props.error}</FormFeedback> : null} */}

                {/* <div>{this.state.error}</div> */}
                {
                    this.state.error ?

                        <ErrorModal lang={this.props.lang}
                            isOpen={this.state.error}
                            toggle={() => this.setState({ error: null })}
                        >
                            {this.state.error.translate(this.props.lang)}
                        </ErrorModal>

                        :
                        null
                }

                <div style={{ color: 'red' }}>{this.props.error}</div>
                <div> {this.props.value && typeof this.props.value == 'object' ? this.props.value.fileName.substring(0, 20) + "..." : ''} </div>

            </FormGroup >


        );
    }
}

export default Image;