import React, { Component } from 'react';
import Page from '../../containers/page';

import { Link } from 'react-router-dom';

import {
    Container,
    Row,
    Col,
    Button,
    FormGroup,
    Label,
    Input,
    UncontrolledAlert,
    Modal, ModalHeader, ModalBody, ModalFooter, Form
} from 'reactstrap';


import Isvg from 'react-inlinesvg';

import InfoModal from '../../components/infoModal';
import ErrorModal from '../../components/errorModal';
import DeleteModal from '../../components/deleteModal';
import { getSearchParams, generateSearchLink, generateSearchLinkMultiple } from '../../helpers/linkHelper';
import { API_ENDPOINT } from '../../constants';
import { formValueSelector, change } from 'redux-form';  // ES6
import { connect } from 'react-redux';

import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';

import BankIdModal from '../../components/bankIdModal';
import ReferralChatHelper from '../../components/referralChatHelper';

import info from '../../assets/svg/info.svg';

import warrning from '../../assets/svg/warrning.svg';
import PaysonSvg from '../../assets/svg/payson.svg';
import SwishSvg from '../../assets/svg/swish.svg';
import Card from '../../assets/svg/card.svg';

import xIcon from '../../assets/svg/x.svg';

import Payson from '../../components/payson';
import Swish from '../swish';



import { Player, Controls } from "@lottiefiles/react-lottie-player";

import PayNowModal from '../../components/payNowModal';

import { Waypoint } from 'react-waypoint';

import AnswerFormComponent from './answerFormComponent';


import SelectWithSearch from '../../components/forms/fields/customSelectWithSearch';
import CustomSelectWithSearch from '../../components/forms/fields/customSelectWithSearch';
import Stripe from '../../components/stripe';
import PatientMandatoryDataModal from '../../components/patientMandatoryDataModal';



function parseJSON(response) {
    return response.json().then(json => {
        return {
            result: json,
            status: response.status
        }
    })
}



class DropInReferral extends Component {
    constructor(props) {
        super(props);

        this.searchResultsWraps = {};

        this.player = React.createRef();
        this.player2 = React.createRef();
        this.state = {
            ...props.initialData,
            articles: [],
            sections: [],
            answers: {},
            patientData: {},
            recipientData: {},
            referralSections: { header: '', footer: '', body: '' },
            version: 0,
            referrals: [],
            referralType: 1,
            regions: [],
            onlinePaymentMethod: null,
            onlinePaymentFreeKort: null,
            choosenPayment: null,
            forChild: false,
            isDropIn: false,
            items: [],
            dropInClinic: null,
            selectedProfession: null,
            availableProfessions: [],
        };


        this.sectionConditions = [true];
        this.infoTexts = [];

    }

    showPlayer = () => {
        this.setState({
            showPlayer: true,
        });
    };

    hidePlayer = () => {
        this.setState({
            showPlayer: false,
        });
    };
    stopAnimation = () => {
        this.player.current.play();
    };


    get = () => {
        this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));

        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0, alias: localStorage.getItem('groupAlias') })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

        for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
            this.props.loadDataWithQuery[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0, alias: localStorage.getItem('groupAlias'), group: this.state.groupId, clinic: this.state.clinic })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));

                })
            })

        }

        fetch(API_ENDPOINT + '/landing/page', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ alias: this.props?.[0]?.match?.params?.alias })
        }).then((res) => {
            if (res) {
                res.json().then(response => {
                    if (response && !response.error) {
                        this.setState({ data: response })
                    }
                })
            }
        })

        fetch(API_ENDPOINT + '/data/personal-questionary/all/' + this.props[0].match.params.alias, {
            method: 'GET',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
        }).then(res => res.json()).then((result) => {



        })
        this.getProfessions();


    }
    getProfessions = () => {
        fetch(API_ENDPOINT + '/data/professions/all/' + this.props[0].match.params.alias, {
            method: 'POST',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
            body: JSON.stringify({
                isDropIn: this.state.isDropIn
            })
        }).then(res => res.json()).then((result) => {
            if (result) {
                let availableProfessions = [];
                for (let i = 0; i < result.length; i++) {
                    availableProfessions.push({
                        name: this.props.lang == 'en' ? result[i].professionEn : result[i].professionSv,
                        value: result[i]._id
                    })
                }
                this.setState({ availableProfessions }, () => this.getPersonalQuestionaries())
            }



        })

    }
    checkLinkToForm = () => {
        if (this.props[0].location?.state?.formId) {
            let formId = this.props[0].location.state.formId;
            this.setState({ isDropIn: true, selectedDropInForm: formId }, () => this.getProfessions())
            this.getSavedForms('dropIn', formId)

        }
    }


    componentDidMount() {
        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }
        this.props.abortAction()
        if (!this.props.groupSettingsModal) {
            this.props.groupSettingsChanged()
        }
        this.get();

        let params = getSearchParams(this.props[0].location.search);



        fetch(API_ENDPOINT + '/users/users/get-age/' + this.props.uData._id, {
            method: 'POST',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
            body: JSON.stringify({})
        }).then(res => res.json()).then((result) => {
            this.setState({
                age: result.age
            })
        })
        this.props.verifyUser(null, null, true)

        if (this.props[0].location.state && this.props[0].location.state.isDropIn) {
            this.setState({ isDropIn: this.props[0].location.state.isDropIn }, () => this.getProfessions())
            let selectedReferral;
            if (this.props[0].location.state && this.props[0].location.state.selectedDropInForm) {
                selectedReferral = this.props[0].location.state.selectedDropInForm;
            }
            this.getSavedForms('dropIn', selectedReferral)
        }



        if (this.props[0].location.state && this.props[0].location.state.selectedDropInForm) {
            this.setState({ selectedDropInForm: this.props[0].location.state.selectedDropInForm })
        }


        if (this.props.uData && this.state.data) {
            this.checkUserAuth();
        }

        this.checkLinkToForm()



    }



    componentDidUpdate(prevProps, prevState) {
        if (prevState.articles.length != this.state.articles.length && this.state.articles.length && !this.state.article) {
            let params = getSearchParams(this.props[0].location.search);
            if (params.article && params.article != 'None') {
                for (let i = 0; i < this.state.articles.length; i++) {
                    if (this.state.articles[i]._id == params.article) {
                        this.setState({
                            article: this.state.articles[i]._id,
                            articleName: this.state.articles[i].title,
                            haveQuestionary: this.state.articles[i].havePreparedQuestionary,

                        })
                    }
                }
            }
        }


        if (this.state.referrals && this.state.referrals.length && this.state.age && this.state.patientData && this.state.patientData.name && this.state.selectedDropInForm && !this.state.selectedReferral) {
            let referral = null;
            for (let i = 0; i < this.state.referrals.length; i++) {
                if (this.state.referrals[i]._id == this.state.selectedDropInForm) {

                    referral = this.state.referrals[i];
                    break;
                }
            }
            if (referral) {
                this.setState({
                    selectedReferral: this.state.selectedDropInForm,
                    referral
                })
            }

        }



        if (prevProps[0].location.search != this.props[0].location.search) {
            this.setState({
                loading: true
            }, () => {
                this.get();

            })
        }
        if (this.props[0].location.state && prevProps[0].location.state && prevProps[0].location.state.dropInClinic != this.props[0].location.state.dropInClinic) {
            this.setState({ dropInClinic: this.props[0].location.state.dropInClinic })

        }


        if (prevState.article != this.state.article) {
            this.getQuestionary();
        }
        if (prevProps?.uData?._id !== this.props.uData?._id || prevState?.data?._id !== this.state.data?._id || prevProps?.[0]?.match?.params?.alias !== this.props?.[0]?.match?.params?.alias) {
            this.checkUserAuth();
        }

    }




    /**
    * Create new referral after completing personal questionary
    * @author   Milan Stanojevic
    */
    sendReferral = (callback) => {

        let selectedClinic = this.state.referral ? this.state.referral.clinic : {};
        let selectedGroup = this.state.clinicGroup ? this.state.clinicGroup : {};
        let onlinePayment = {};
        if (this.state.onlinePaymentMethod == 1) {
            onlinePayment.haveFreeCard = true;
            if (this.state.onlinePaymentFreeKort && this.state.onlinePaymentFreeKort[0] == '' && this.state.onlinePaymentFreeKort[1] == '' && this.state.onlinePaymentFreeKort[2] == '' && this.state.onlinePaymentFreeKort[3] == '') {
                onlinePayment.haveElectronicFreeKort = true;
            } else if (this.state.onlinePaymentFreeKort) {
                onlinePayment.freeKort = this.state.onlinePaymentFreeKort;
            }
        } else if (this.state.onlinePaymentMethod == 7) {
            onlinePayment.payWithCard = true;
        } else if (this.state.onlinePaymentMethod == 2 || this.state.onlinePaymentMethod == 3 || this.state.onlinePaymentMethod == 4 || this.state.onlinePaymentMethod == 5 || this.state.onlinePaymentMethod == 6) {
            onlinePayment.free = true;
        }

        let clinicData = {
            _id: selectedClinic._id,
            subgroup: selectedClinic.subgroup,
            subgroupName: selectedClinic.subgroupName,
            name: selectedClinic.name,
            street: selectedClinic.street,
            zip: selectedClinic.zip,
            city: selectedClinic.city,
            postAddress: selectedClinic.postAddress,
            logo: selectedGroup.logo
        }

        this.setState({
            _sendingReferral: true,
            disableSubmit: true
        }, () => {
            let params = getSearchParams(this.props[0].location.search);
            let patientData = this.state.patientData;
            if (!patientData?.email && this.props.uData?.email) {
                patientData.email = this.props.uData.email;
            }
            if (!patientData?.phone && this.props.uData?.phone) {
                patientData.phone = this.props.uData.phone;
            }
            let patient = patientData;
            if (patient.phone) {
                patient.phone = patient.phone.replace(/\+/g, '00').replace(/[^\d]/g, '');
                if (patient.phone.indexOf('00') == -1) {
                    patient.phone = '00' + patient.phone;
                }
            }

            // fetch(API_ENDPOINT + (params.referral ? `/referrals/${params.referral}` : '/referrals/new'), {
            fetch(API_ENDPOINT + '/referrals/new', {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify({
                    answers: this.state.answers,
                    questionarySections: this.state.sections,
                    article: this.state.article,
                    personalReferral: true,
                    patient: patient,
                    onlinePayment: onlinePayment,
                    dropIn: this.state.isDropIn,
                    resumeForm: this.state.resumeForm,
                    questionaryName: this.state.referral.name,
                    questionaryData: this.state.referral,
                    onlineVisit: this.state.referral.onlineVisit,
                    questionaryDuration: this.state.referral.duration,
                    questionaryPrice: this.state.referral.price,
                    questionaryId: this.state.referral._id,
                    doctorsList: this.state.referral.doctorsListQuestionary,
                    intelligentReferral: this.state.referral.intelligentReferral,
                    wayOfScheduling: this.state.referral.wayOfScheduling,
                    paymentAfterBookedTime: this.state.referral.paymentAfterBookedTime,
                    paymentBeforeBookedTime: this.state.referral.paymentBeforeBookedTime,
                    paymentBeforeSubmission: this.state.referral.paymentBeforeSubmission,
                    paymentAfterSubmission: this.state.referral.paymentAfterSubmission,
                    professionList: this.state.referral.professionList,
                    profession: this.state.selectedProfession ? this.state.selectedProfession : '',
                    recipientClinic: this.state.referral.clinic._id,
                    freePayment: this.state.onlinePaymentMethod > 0 && this.state.onlinePaymentMethod < 7 ? this.state.onlinePaymentMethod : null,
                    paymentMethod: this.state.onlinePaymentMethod == 7 && this.state.choosenPayment ? this.state.choosenPayment : null,
                    referralType: this.state.referralType,
                    consent: this.state.consent,
                    referralSections: this.state.referralSections,
                    version: this.state.version,
                    referralContent: this.state.referralContent,
                    recipientClinicData: {
                        name: this.state.referral.clinic.name,
                        address1: this.state.recipientData.address1,
                        address2: this.state.recipientData.address2,
                        address3: this.state.recipientData.address3,
                    }
                })
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300) {
                    //alert('sent');

                    // this.props.verifyUser();
                    this.props.verifyUser(null, null, true);

                    if (result)
                        this.tempReferralCheck(result.tempReferral, callback)




                }
            })

        })

    }

    tempReferralCheck = (data, callback) => {

        fetch(API_ENDPOINT + '/referrals/temp-referral-check/' + data, {
            method: 'GET',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
        }).then(res => res.json()).then((result) => {
            if (result && result.referralId) {

                if (callback) {
                    callback()
                }

                this.setState({
                    _sendingReferral: null,
                    signinModal: false,
                    // successModal: true
                    newReferralId: result.referralId,
                })

                this.props.registerClinicGroupLogs({
                    type: 'endCreateReferral',
                    referralType: 'Drop in',
                    referralId: result.referralId
                })

                if (this.state.isDropIn && this.state.referral.price != 0) {
                    if (this.state.onlinePaymentMethod > 0 && this.state.onlinePaymentMethod < 7) {
                        if (this.props.patientCreatedReferral) this.props.patientCreatedReferral();

                        fetch(API_ENDPOINT + '/referrals/generate-invoice/' + result.referralId.toString(), {
                            method: 'POST',
                            headers: {
                                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                                'content-type': 'application/json'
                            },
                            body: JSON.stringify({ transaction: '', alias: localStorage.getItem('groupAlias') })
                        }).then(res => res.json()).then((result) => {
                            this.setState({ successModalFreeCard: "Based on your age, you are entitled to a free visit." })
                        })
                    } else {
                        if (this.state.referral?.paymentAfterSubmission) {
                            if (this.props.patientCreatedReferral) this.props.patientCreatedReferral();
                        }

                        this.setState({ payNowModal: this.state.referral })
                    }

                } else if (this.state.isDropIn && this.state.referral.price == 0) {
                    if (this.props.patientCreatedReferral) this.props.patientCreatedReferral();
                    fetch(API_ENDPOINT + '/referrals/generate-invoice/' + result.referralId.toString(), {
                        method: 'POST',
                        headers: {
                            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                            'content-type': 'application/json'
                        },
                        body: JSON.stringify({ transaction: '', alias: localStorage.getItem('groupAlias') })
                    }).then(res => res.json()).then((result) => {
                        if (this.state.onlinePaymentMethod > 0 && this.state.onlinePaymentMethod < 7)
                            this.setState({ successModalFreeCard: "Based on your age, you are entitled to a free visit." })
                        else {
                            this.setState({ successModalFreeCard: "Visit is free of charge." })
                        }

                    })

                } else {
                    if ((this.state.referral.price == 0 && (this.state.referral.wayOfScheduling == "bookingByClinic" || this.state.referral.wayOfScheduling == "dropIn")) || (this.state.onlinePaymentMethod > 0 && this.state.onlinePaymentMethod < 7 && (this.state.referral.wayOfScheduling == "bookingByClinic" || this.state.referral.wayOfScheduling == "dropIn"))) {
                        if (this.props.patientCreatedReferral) this.props.patientCreatedReferral();
                        fetch(API_ENDPOINT + '/referrals/generate-invoice/' + result.referralId.toString(), {
                            method: 'POST',
                            headers: {
                                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                                'content-type': 'application/json'
                            },
                            body: JSON.stringify({ transaction: '', alias: localStorage.getItem('groupAlias') })
                        }).then(res => res.json()).then((result) => {
                            if (this.state.onlinePaymentMethod > 0 && this.state.onlinePaymentMethod < 7 && this.state.onlinePaymentMethod != 1)
                                this.setState({ successModalFreeCard: "Based on your age, you are entitled to a free visit." })
                            else if (this.state.onlinePaymentMethod == 1) {
                                this.setState({ successModalFreeCard: 'Your case has been submitted successfully. Remember to take your free card with you.' })
                            } else {
                                this.setState({ successModalFreeCard: "Visit is free of charge." })

                            }

                        })
                    }
                    if (this.state.referral.wayOfScheduling == "webBooking") {
                        this.setState({ calendarModal: true })
                        if (result) {
                            fetch(API_ENDPOINT + '', {
                                method: 'POST',
                                headers: {
                                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                                    'content-type': 'application/json'
                                },
                                body: JSON.stringify({
                                    referralId: result.referralId,

                                })
                            }).then(parseJSON).then(({ result, status }) => {

                            })

                            this.interval = setInterval(() => {
                                fetch(API_ENDPOINT + '/referrals/check/que', {
                                    method: 'POST',
                                    headers: {
                                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                                        'content-type': 'application/json'
                                    },
                                    body: JSON.stringify({
                                        referralId: result.referralId,

                                    })
                                }).then(parseJSON).then(({ result, status }) => {

                                })
                            }, 1000 * 10);


                        }




                    }

                }

                this.props.verifyUser(null, null, true);
            } else {
                this.timer1 = setTimeout(() => {
                    this.tempReferralCheck(data, callback)

                }, 500)
            }
        })

    }

    /**
    * Get questionary
    * @author   Milan Stanojevic
    * @Objectparam   {String} article       article id
    * @Objectparam   {String} referral      referral id
    */
    getQuestionary = () => {
        fetch(API_ENDPOINT + '/data/questionary/' + this.state.article, {
            method: 'GET',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
        }).then(parseJSON).then(({ result, status }) => {
            if (status >= 200 && status < 300) {
                this.setState(result);
            }
        });


        let params = getSearchParams(this.props[0].location.search);
        if (params.referral) {
            fetch(API_ENDPOINT + '/referrals/' + params.referral, {
                method: 'GET',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300) {
                    result.recipientData = result.recipientClinicData;
                    result.patientData = result.patient;
                    result.version++;
                    this.setState(result);
                }
            });

        }


    }






    /**
    * Delete referral if patient close calendar before complete scheduling
    * @author   Aleksandar Dabic
    */
    checkDeleteReferral = () => {
        fetch(API_ENDPOINT + '/referrals/delete-last/' + this.state.checkRefId, {
            method: 'DELETE',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
        }).then(res => res.json()).then((result) => {
            fetch(API_ENDPOINT + '/referrals/check/que/delete', {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify({
                    referralId: this.state.checkRefId,

                })
            }).then(parseJSON).then(({ result, status }) => {
                this.closeCalendar()
            })


        })
    }

    /**
    * Delete referral
    * @author   Stefan Pantic
    * @Objectparam    {String} referral             referral id
    */
    deleteReferral = (checkChangeLink = false) => {
        this.setState({
            loading: true
        }, () => {
            fetch(API_ENDPOINT + '/referrals/patient-schedule/delete/' + this.state.newReferralId.toString(), {
                method: 'DELETE',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
            }).then(res => res.json()).then((result) => {

                if (checkChangeLink) {
                    if (this.props.changeLinkModal) {
                        this.props[0].history.push(this.props.changeLinkModal)
                    } else {
                        this.props[0].history.push(`/${localStorage.getItem('groupAlias')}`)
                    }
                } else {
                    this.props[0].history.push(`/${localStorage.getItem('groupAlias')}`)
                }
            }).catch(err => {
                if (checkChangeLink) {
                    if (this.props.changeLinkModal) {
                        this.props[0].history.push(this.props.changeLinkModal)
                    } else {
                        this.props[0].history.push(`/${localStorage.getItem('groupAlias')}`)
                    }
                } else {
                    this.props[0].history.push(`/${localStorage.getItem('groupAlias')}`)
                }
            })
        })
    }







    indexOfIndexOf(arr, str) {
        for (let i = 0; i < arr.length; i++) {
            let idx = arr[i].indexOf(str);
            if (idx !== -1) {
                return i;
            }

        }

        return -1;
    }

    updateParams = (name, value,) => {
        this.props[0].history.push(generateSearchLink(this.props[0].location.search, {}, name, value, false));
    }


    updatePaymentMethodFreeCard = (data) => {

        if (data && data.freeCardValidFrom && data.freeCardValidTo && (new Date(data.freeCardValidFrom) > this.state.referralStartAt || new Date(data.freeCardValidTo) < this.state.referralStartAt)) {
            this.setState({
                freeCardError: true
            })
        } else {
            if (data && data.freeCardRegion) {
                let region = this.state.regions.filter(item => item._id == data.freeCardRegion);
                if (region && region[0]) {
                    data.freeCardRegionName = region[0].shortName;
                }
            }
            data.freeCard = true;
            let onlinePaymentMethod = this.state.onlinePaymentMethod;
            onlinePaymentMethod = 1

            this.setState({
                onlinePaymentMethod,
                freeCardData: data
            }, () => {
                this.updateReferral()
            })
        }

    }

    updateReferral = () => {
        let data = {
            freePayment: this.state.onlinePaymentMethod > 0 && this.state.onlinePaymentMethod < 7 ? this.state.onlinePaymentMethod : null,
            paymentMethod: this.state.onlinePaymentMethod == 7 && this.state.choosenPayment ? this.state.choosenPayment : null,
        }
        if (this.state.freeCardData) {
            data.freeCardData = this.state.freeCardData
        }

        if (this.state.payson) {
            data.payson = true
        }
        if (this.state.swish) {
            data.swish = true
        }
        if (this.state.stripe) {
            data.stripe = true
        }
        if (data.paymentMethod == 2) {
            data.requsetedInvoice = true;
        }
        this.setState({
            loading: true
        }, () => {
            fetch(API_ENDPOINT + '/referrals/referrals/update/' + this.state.newReferralId.toString(), {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify(data)
            }).then(res => res.json()).then((result) => {
                if (result) {
                    if (result.paymentMethod == 2 || result.paymentMethod == 1) {
                        if (this.state.referral?.paymentBeforeSubmission) {
                            if (this.props.patientCreatedReferral) this.props.patientCreatedReferral();
                        }
                        if (this.state.payNowModal && this.state.payNowModal.paymentBeforeBookedTime) {
                            this.setState({
                                payNowModal: null,
                                successModal: result.paymentMethod == 2 ? 'Your case has been submitted successfully. Once the invoice is created, it will be delivered to your email address, and you will also be able to download it on the "My payments" page.' : null
                            })
                        } else if (this.state.payNowModal && this.state.payNowModal.paymentAfterBookedTime) {
                            this.setState({
                                payNowModal: null,
                                successModal: result.paymentMethod == 1 ? 'Your case has been submitted successfully. Payment must be made "On site" before the visit.' : 'Your case has been submitted successfully. Once the invoice is created, it will be delivered to your email address, and you will also be able to download it on the "My payments" page.'
                            })
                        } else {
                            this.setState({
                                payNowModal: null,
                                successModal: result.paymentMethod == 1 ? 'You have successfully scheduled a visit. Payment must be made "On site" before the visit.' : 'You have successfully scheduled a visit. Once the invoice is created, it will be delivered to your email address, and you will also be able to download it on the "My payments" page.'
                            })
                        }

                    } else if (result.payson) {
                        this.setState({
                            paysonModal: true,
                            payNowModal: null
                        })
                    } else if (result.swish) {
                        this.setState({
                            swishModal: true,
                            payNowModal: null
                        })
                    } else if (result.stripe) {
                        this.setState({
                            stripeModal: false
                        }, () => {
                            this.setState({
                                stripeModal: true,
                                // payNowModal: null
                            })
                        })

                    } else if (result.freePayment == 1) {
                        if (this.state.referral?.paymentBeforeSubmission) {
                            if (this.props.patientCreatedReferral) this.props.patientCreatedReferral();
                        }
                        fetch(API_ENDPOINT + '/referrals/generate-invoice/' + this.state.newReferralId.toString(), {
                            method: 'POST',
                            headers: {
                                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                                'content-type': 'application/json'
                            },
                            body: JSON.stringify({ transaction: '', alias: localStorage.getItem('groupAlias') })
                        }).then(res => res.json()).then((result) => {
                            this.setState({
                                payNowModal: null,
                                successModalFreeCard: this.state.payNowModal && this.state.payNowModal.paymentAfterBookedTime ? 'Your case has been submitted successfully. Remember to take your free card with you.' : this.state.isDropIn ? 'Your case has been submitted successfully. Please remain online and track the status of your case' : 'You have successfully scheduled a visit. Remember to take your free card with you.'
                            })
                        })

                    }

                }
                this.setState({
                    freeCardData: null,
                    payson: null,
                    swish: null
                })

            })
        })

    }
    checkWorkingHoursDropIn = (selectedReferral, referral, sections, questions) => {

        this.setState({ checkWorkingHoursLoader: true }, () => {
            fetch(API_ENDPOINT + '/referrals/check/working/hours', {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify({
                    group: referral && referral.group,
                    clinic: referral && referral.clinic && referral.clinic._id
                })
            }).then(res => res.json()).then((result) => {
                this.setState({
                    checkWorkingHoursLoader: null,

                })
                if (result.canCreateDropIn) {
                    this.setState({
                        selectedReferral,
                        referral,
                        sections,
                    });
                } else {
                    this.setState({
                        checkWorkingHoursError: 'We are currently unable to accept your drop in referral. Please try again later within the enclosed working hours'.translate(this.props.lang),
                        checkWorkingHoursAvailableTerms: result.availableTerms
                    })
                }
            })
        })


    }

    getPersonalQuestionaries = () => {
        if (!(!this.state.availableProfessions || (this.state.availableProfessions && this.state.availableProfessions.length == 0))) {
            fetch(API_ENDPOINT + '/data/personal-questionary/all/' + this.props[0].match.params.alias, {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify({
                    isDropIn: this.state.isDropIn,
                    availableProfessions: this.state.availableProfessions
                })
            }).then(res => res.json()).then((result) => {
                this.setState({
                    ...result
                })
            })
        }


    }





    saveForm = () => {

        let obj = {
            state: {
                patientData: this.state.patientData,
                _chatDone: this.state._chatDone,
                selectedReferral: this.state.selectedReferral,
                selectedDropInForm: this.state.selectedDropInForm,
                wayOfScheduling: 'dropIn',
                referral: this.state.referral
            }
        }

        if (this.getSaveFormData) {
            let data = this.getSaveFormData();
            obj.state = { ...obj.state, ...data }
        }



        fetch(API_ENDPOINT + '/users/personal-questionaries/save/data', {
            method: 'POST',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
            body: JSON.stringify(obj)
        }).then(res => res.json()).then((result) => {
            if (result && !result.error) {
                this.setState({ savedFormModal: result._id })

                if (typeof window != 'undefined') {
                    let checkedGroupsSavedForms = localStorage.checkedGroupsSavedForms ? JSON.parse(localStorage.checkedGroupsSavedForms) : [];
                    if (localStorage.groupAlias) {
                        checkedGroupsSavedForms.push(localStorage.groupAlias)
                        localStorage.setItem('checkedGroupsSavedForms', JSON.stringify(checkedGroupsSavedForms))
                    }
                }

            }
        })






        this.setState({ saveFormModal: false })

    }

    getSavedForms = (wayOfScheduling, selectedReferral) => {
        if (typeof window == 'undefined') {
            return
        }
        if (wayOfScheduling === 'dropIn' && !selectedReferral) {
            return;
        }
        let obj = {
            groupAlias: localStorage.groupAlias,
        }
        if (wayOfScheduling) {
            obj.wayOfScheduling = wayOfScheduling
        }
        if (selectedReferral) {
            obj.selectedReferral = selectedReferral;
        }



        fetch(API_ENDPOINT + '/users/personal-questionaries/saved/data', {
            method: 'POST',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
            body: JSON.stringify(obj)
        }).then(res => res.json()).then((result) => {
            if (result && result.savedForms) {
                if (result.savedForms.length) {
                    let savedForm = result.savedForms[0];
                    if (savedForm.questionaryNotExists || savedForm.questionaryNotAcitve) {
                        this.setState({ saveFormInfoModal: "You can't continue answering this form. The form has been deactivated or deleted." })
                    } else if (savedForm.questionaryModified) {
                        this.setState({ saveFormInfoModal: "You can't continue answering this form. The form content was changed in the meantime." })
                    } else {
                        this.setState({ checkSavedDropInForm: savedForm })
                    }
                }

            }
        })





    }


    submit = (data, callback) => {
        if (!this.props.uData?.phone || !this.props.uData?.email || (this.state.data?._id && !this.props.uData?.privacyPolicy?.[this.state.data._id])) {
            this.setState({ enterPatientDataModal: { data, callback } })
            return;
        } else {
            if (this.state.enterPatientDataModal)
                this.setState({ enterPatientDataModal: null })
        }
        let setObj = {};

        if (this.state.referral && this.state.age) {
            if (this.state.age < 18 && this.state.referral.under18) {
                let referal = this.state.referral;
                referal.price = 0;
                let onlinePaymentMethod = this.state.onlinePaymentMethod;
                onlinePaymentMethod = 4
                setObj.referal = referal;
                setObj.onlinePaymentMethod = onlinePaymentMethod;
                // this.setState({
                //     referal,
                //     onlinePaymentMethod
                // })
            } else if (this.state.age < 20 && this.state.referral.under20) {
                let referal = this.state.referral;
                referal.price = 0;
                let onlinePaymentMethod = this.state.onlinePaymentMethod;
                onlinePaymentMethod = 3;
                setObj.referal = referal;
                setObj.onlinePaymentMethod = onlinePaymentMethod;
                // this.setState({
                //     referal,
                //     onlinePaymentMethod
                // })
            } else if (this.state.age < 23 && this.state.referral.under23) {
                let referal = this.state.referral;
                referal.price = 0;
                let onlinePaymentMethod = this.state.onlinePaymentMethod;
                onlinePaymentMethod = 2
                // this.setState({
                //     referal,
                //     onlinePaymentMethod
                // })
                setObj.referal = referal;
                setObj.onlinePaymentMethod = onlinePaymentMethod;
            } else if (this.state.age > 85 && this.state.referral.older85) {
                let referal = this.state.referral;
                referal.price = 0;
                let onlinePaymentMethod = this.state.onlinePaymentMethod;
                onlinePaymentMethod = 5
                // this.setState({
                //     referal,
                //     onlinePaymentMethod
                // })
                setObj.referal = referal;
                setObj.onlinePaymentMethod = onlinePaymentMethod;
            } else if (this.state.forChild && this.state.referral.under18child) {
                let referal = this.state.referral;
                referal.price = 0;
                let onlinePaymentMethod = this.state.onlinePaymentMethod;
                onlinePaymentMethod = 6
                // this.setState({
                //     referal,
                //     onlinePaymentMethod
                // })
                setObj.referal = referal;
                setObj.onlinePaymentMethod = onlinePaymentMethod;
            }
        }



        if (this.state.referral.price != 0) {
            // this.setState({
            //     onlinePaymentMethod: 7
            // })
            setObj.onlinePaymentMethod = 7;
        }
        if (data?.answers) {
            setObj.answers = data.answers;
        }
        if (data?.sections) {
            setObj.sections = data.sections;
        }
        if (data?.referralContent) {
            setObj.referralContent = data.referralContent;
        }


        this.setState(setObj, () => {
            this.sendReferral(callback);

        })


    }

    continueSavedCase = () => {
        let checkSavedDropInForm = JSON.parse(JSON.stringify(this.state.checkSavedDropInForm))


        if (checkSavedDropInForm.state) {
            let states = JSON.parse(JSON.stringify(checkSavedDropInForm.state))
            let data = {}
            if (states.skippedQuestions) {
                data.skippedQuestions = states.skippedQuestions;
                delete states.skippedQuestions
            }
            if (states.answers) {
                data.answers = states.answers;
                delete states.answers
            }
            if (states.nextButtonClicked) {
                data.nextButtonClicked = states.nextButtonClicked;
                delete states.nextButtonClicked
            }
            if (states.questionsQueue) {
                data.questionsQueue = states.questionsQueue;
                delete states.questionsQueue
            }
            if (states.currentQuestion) {
                data.currentQuestion = states.currentQuestion;
                delete states.currentQuestion
            }
            if (states.backAnswers) {
                data.backAnswers = states.backAnswers;
                delete states.backAnswers
            }


            this.setState({ ...states, checkSavedDropInForm: null, resumeForm: checkSavedDropInForm._id }, () => {
                if (checkSavedDropInForm.selectedReferral && this.state.referrals && this.state.referrals.length && this.state.age && this.state.patientData && this.state.patientData.name) {
                    let referral = null;
                    for (let i = 0; i < this.state.referrals.length; i++) {
                        if (this.state.referrals[i]._id == checkSavedDropInForm.selectedReferral) {

                            referral = this.state.referrals[i];
                            break;
                        }
                    }
                    if (referral) {
                        this.setState({
                            selectedReferral: checkSavedDropInForm.selectedReferral,
                            referral,
                        }, () => {
                            if (this.setSaveFormData) {
                                this.setSaveFormData(data)
                            }
                        })
                    }

                }
            })
        }
    }


    componentWillUnmount() {
        if (this.timer1) clearTimeout(this.timer1);
    }

    checkUserAuth = () => {
        const user = this.props.uData;
        const groupId = this.state.data?._id;
        const loggedInWith2fa = localStorage?.getItem?.('2faLogin');

        if (loggedInWith2fa && loggedInWith2fa === 'true' && user && groupId) {
            this.setState({ loading: true }, async () => {
                const newState = {
                    loading: false,
                };

                const response = await fetch(API_ENDPOINT + '/users/two-factor-auth/check-clinic-group', {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                    },
                    body: JSON.stringify({ groupId })
                });

                const result = await response?.json?.();

                if (!result) return this.setState(newState);

                if (result.error) {
                    newState.twoFactorAuthForbidden = result.error;
                    this.setState(newState);
                } else {
                    newState.twoFactorAuthForbidden = false;
                    this.setState(newState);
                }

                if (result.verify && result.verify === true) this.props.verifyUser?.();
            });
        }
    }

    navigateBackToProfile = () => {
        this.setState({ twoFactorAuthForbidden: false }, () => {
            this.props?.[0]?.history?.replace?.('/my-data/account');
        });
    }

    render() {

        let params = {};
        if (this.state.useState) {
            params = this.state.params;
        } else {
            params = getSearchParams(this.props[0].location.search, { entries: 10, page: 0 })
        }


        let subgroups = [{ name: 'None', value: null }];

        if (this.props.group) {
            for (let i = 0; i < this.state.subgroups.length; i++) {
                if (this.state.subgroups[i].groups && this.state.subgroups[i].groups.indexOf(this.props.group) !== -1) {
                    subgroups.push(this.state.subgroups[i]);
                }
            }
        }


        const currency = this.props.currency ? this.props.currency : typeof window !== 'undefined' && localStorage?.getItem("currency") ? localStorage.getItem("currency") : '';


        return (
            <div className={this.props.autoHideSidebar ? "dashboard dashboard-auto-hide" : "dashboard"}>
                {
                    this.state.enterPatientDataModal ?
                        <PatientMandatoryDataModal
                            {...this.props}
                            isOpen={this.state.enterPatientDataModal ? true : false}
                            callback={() => {
                                this.submit(this.state.enterPatientDataModal?.data, this.state.enterPatientDataModal?.callback)
                            }}
                        />
                        :
                        null
                }
                <Container fluid>
                    <Row>


                        <Col lg="12">
                            {!this.state._chatDone ?
                                <div className="panel panel-full-screen">
                                    {this.state.clinicGroup && this.state.age ?
                                        <ReferralChatHelper countryTwoLetter={this.props.countryTwoLetter} country={this.props.country} showDateOfBirth={this.props.showDateOfBirth} dateFormat={this.props.dateFormat} getDateOfBirthFromSSN={this.props.getDateOfBirthFromSSN} calculateAgeFromDate={this.props.calculateAgeFromDate} dropIn={true} selectedDropInForm={this.state.selectedDropInForm} lang={this.props.lang} ring112Audio={(item) => this.props.ring112Audio(item)} clinicGroup={this.state.clinicGroup} age={this.state.age} uData={this.props.uData} privacyPolicy={`/${this.props[0].match.params.alias}/privacy-policy`} handleData={(data) => {
                                            if (data.socialSecurityNumber) {
                                                this.setState({
                                                    _chatDone: true,
                                                    patientData: {
                                                        name: data.childName,
                                                        socialSecurityNumber: data.socialSecurityNumber,
                                                        parents: data.parentsData,
                                                        dateOfBirth: data.dateOfBirth
                                                    },
                                                    forChild: true

                                                }, this.forceUpdate)
                                            } else {
                                                this.setState({
                                                    _chatDone: true,
                                                    patientData: {
                                                        name: this.props.uData.userData.name,
                                                        socialSecurityNumber: this.props.uData.socialSecurityNumber,
                                                        phone: this.props.uData.phone,
                                                        email: this.props.uData.email,
                                                        city: this.props.uData.city,
                                                        zip: this.props.uData.zip,
                                                        street: this.props.uData.street
                                                    }
                                                }, this.forceUpdate);
                                            }
                                        }}></ReferralChatHelper>
                                        :
                                        null
                                    }
                                </div>
                                :


                                <div className="panel referral-section referral-section-full-screen">

                                    <Row>

                                        {
                                            !this.state.referral ?
                                                <>

                                                    <Col lg="6">


                                                        {
                                                            !this.state.selectedReferral ?
                                                                <FormGroup>
                                                                    <CustomSelectWithSearch
                                                                        isInputClearable={true}
                                                                        lang={this.props.lang}
                                                                        items={[/*{ name: 'Search reason'.translate(this.props.lang), value: null },*/ ...this.state.referrals].map((item, idx) => {
                                                                            if ((item.active || item.name == 'Search reason'.translate(this.props.lang)) && (item.wayOfScheduling == 'bookingByClinic' || item.wayOfScheduling == 'webBooking' || this.state.isDropIn || item.name == 'Search reason'.translate(this.props.lang))) {
                                                                                if (this.state.isDropIn && item.name != 'Search reason'.translate(this.props.lang)) {
                                                                                    if (this.state.dropInClinic) {
                                                                                        if (item.clinic && this.state.dropInClinic == item.clinic._id) {
                                                                                            if (item.onlineVisit) {
                                                                                                let checkAge = false;
                                                                                                let age = this.state.age;
                                                                                                if (item.under18 && age <= 18) {
                                                                                                    checkAge = true;
                                                                                                }
                                                                                                if (item.under20 && age <= 20) {
                                                                                                    checkAge = true;
                                                                                                }
                                                                                                if (item.under23 && age <= 23) {
                                                                                                    checkAge = true;
                                                                                                }
                                                                                                if (item.older85 && age > 85) {
                                                                                                    checkAge = true;
                                                                                                }
                                                                                                if (item.under18child && this.state.forChild) {
                                                                                                    checkAge = true;
                                                                                                }


                                                                                                if ((this.state.clinicGroup && this.state.clinicGroup.agentId && this.state.clinicGroup.apiKey) || (this.state.clinicGroup && this.state.clinicGroup.bankAccount)) {

                                                                                                    if (!(item.wayOfScheduling == 'webBooking'))
                                                                                                        return {
                                                                                                            name: this.props.lang == 'en' ? item.name.replace(' (onlinebesök)', ' (online visit)') : item.name.replace(' (online visit)', ' (onlinebesök)'),
                                                                                                            value: item._id
                                                                                                        }
                                                                                                    // return (
                                                                                                    //     <option value={item._id}>{this.props.lang == 'en' ? item.name.replace(' (onlinebesök)', ' (online visit)') : item.name.replace(' (online visit)', ' (onlinebesök)')}</option>
                                                                                                    // )
                                                                                                } else {
                                                                                                    if (!(item.wayOfScheduling == 'webBooking')
                                                                                                        && ((item.freeOfCharge && item.price == 0) || checkAge))
                                                                                                        return {
                                                                                                            name: this.props.lang == 'en' ? item.name.replace(' (onlinebesök)', ' (online visit)') : item.name.replace(' (online visit)', ' (onlinebesök)'),
                                                                                                            value: item._id
                                                                                                        }
                                                                                                    // return (
                                                                                                    //     <option value={item._id}>{this.props.lang == 'en' ? item.name.replace(' (onlinebesök)', ' (online visit)') : item.name.replace(' (online visit)', ' (onlinebesök)')}</option>
                                                                                                    // )
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                    } else {
                                                                                        if (item.onlineVisit) {
                                                                                            let checkAge = false;
                                                                                            let age = this.state.age;
                                                                                            if (item.under18 && age <= 18) {
                                                                                                checkAge = true;
                                                                                            }
                                                                                            if (item.under20 && age <= 20) {
                                                                                                checkAge = true;
                                                                                            }
                                                                                            if (item.under23 && age <= 23) {
                                                                                                checkAge = true;
                                                                                            }
                                                                                            if (item.older85 && age > 85) {
                                                                                                checkAge = true;
                                                                                            }
                                                                                            if (item.under18child && this.state.forChild) {
                                                                                                checkAge = true;
                                                                                            }


                                                                                            if ((this.state.clinicGroup && this.state.clinicGroup.agentId && this.state.clinicGroup.apiKey) || (this.state.clinicGroup && this.state.clinicGroup.bankAccount)) {
                                                                                                if (!(item.wayOfScheduling == 'webBooking'))
                                                                                                    return {
                                                                                                        name: this.props.lang == 'en' ? item.name.replace(' (onlinebesök)', ' (online visit)') : item.name.replace(' (online visit)', ' (onlinebesök)'),
                                                                                                        value: item._id
                                                                                                    }
                                                                                                // return (
                                                                                                //     <option value={item._id}>{this.props.lang == 'en' ? item.name.replace(' (onlinebesök)', ' (online visit)') : item.name.replace(' (online visit)', ' (onlinebesök)')}</option>
                                                                                                // )
                                                                                            } else {
                                                                                                if (!(item.wayOfScheduling == 'webBooking')
                                                                                                    && ((item.freeOfCharge && item.price == 0) || checkAge))
                                                                                                    return {
                                                                                                        name: this.props.lang == 'en' ? item.name.replace(' (onlinebesök)', ' (online visit)') : item.name.replace(' (online visit)', ' (onlinebesök)'),
                                                                                                        value: item._id
                                                                                                    }
                                                                                                // return (
                                                                                                //     <option value={item._id}>{this.props.lang == 'en' ? item.name.replace(' (onlinebesök)', ' (online visit)') : item.name.replace(' (online visit)', ' (onlinebesök)')}</option>
                                                                                                // )
                                                                                            }
                                                                                        }
                                                                                    }


                                                                                } else {
                                                                                    return {
                                                                                        name: this.props.lang == 'en' ? item.name.substring(0, 86).replace(' (onlinebesök)', ' (online visit)') : item.name.substring(0, 86).replace(' (online visit)', ' (onlinebesök)'),
                                                                                        value: item._id
                                                                                    }
                                                                                    // return (

                                                                                    //     <option value={item._id}>{this.props.lang == 'en' ? item.name.substring(0, 86).replace(' (onlinebesök)', ' (online visit)') : item.name.substring(0, 86).replace(' (online visit)', ' (onlinebesök)')}</option>
                                                                                    // )
                                                                                }
                                                                            }




                                                                        })}
                                                                        hideSearchField={this.state.clinicGroup?.hideFormSearchFild}
                                                                        value={this.state.selectedReferral}
                                                                        maxHeight={'50vh'}
                                                                        onChange={(e) => {
                                                                            let value = e?.value ? e.value : null;
                                                                            let referral = null;
                                                                            for (let i = 0; i < this.state.referrals.length; i++) {
                                                                                if (this.state.referrals[i]._id == value) {

                                                                                    referral = this.state.referrals[i];
                                                                                    break;
                                                                                }
                                                                            }



                                                                            if (this.state.isDropIn) {

                                                                                this.checkWorkingHoursDropIn(value, referral, referral.sections, referral.sections[0].questions)
                                                                            } else {
                                                                                this.setState({
                                                                                    selectedReferral: value,
                                                                                    referral,

                                                                                });
                                                                            }

                                                                        }}
                                                                    />
                                                                    {/* <Label>{'Search reason'.translate(this.props.lang)}</Label>

                                                                    <Input type="select" className='search-reason-select' value={this.state.selectedReferral} onChange={(e) => {
                                                                        let referral = null;
                                                                        for (let i = 0; i < this.state.referrals.length; i++) {
                                                                            if (this.state.referrals[i]._id == e.target.value) {

                                                                                referral = this.state.referrals[i];
                                                                                break;
                                                                            }
                                                                        }



                                                                        if (this.state.isDropIn) {

                                                                            this.checkWorkingHoursDropIn(e.target.value, referral, referral.sections, referral.sections[0].questions)
                                                                        } else {
                                                                            this.setState({
                                                                                selectedReferral: e.target.value,
                                                                                referral,

                                                                            });
                                                                        }

                                                                    }}>

                                                                        {
                                                                            [{ name: 'Search reason'.translate(this.props.lang), value: null }, ...this.state.referrals].map((item, idx) => {
                                                                                if ((item.active || item.name == 'Search reason'.translate(this.props.lang)) && (item.wayOfScheduling == 'bookingByClinic' || item.wayOfScheduling == 'webBooking' || this.state.isDropIn || item.name == 'Search reason'.translate(this.props.lang))) {
                                                                                    if (this.state.isDropIn && item.name != 'Search reason'.translate(this.props.lang)) {
                                                                                        if (this.state.dropInClinic) {
                                                                                            if (item.clinic && this.state.dropInClinic == item.clinic._id) {
                                                                                                if (item.onlineVisit) {
                                                                                                    let checkAge = false;
                                                                                                    let age = this.state.age;
                                                                                                    if (item.under18 && age <= 18) {
                                                                                                        checkAge = true;
                                                                                                    }
                                                                                                    if (item.under20 && age <= 20) {
                                                                                                        checkAge = true;
                                                                                                    }
                                                                                                    if (item.under23 && age <= 23) {
                                                                                                        checkAge = true;
                                                                                                    }
                                                                                                    if (item.older85 && age > 85) {
                                                                                                        checkAge = true;
                                                                                                    }
                                                                                                    if (item.under18child && this.state.forChild) {
                                                                                                        checkAge = true;
                                                                                                    }


                                                                                                    if ((this.state.clinicGroup && this.state.clinicGroup.agentId && this.state.clinicGroup.apiKey) || (this.state.clinicGroup && this.state.clinicGroup.bankAccount)) {

                                                                                                        if (!(item.wayOfScheduling == 'webBooking'))
                                                                                                            return (
                                                                                                                <option value={item._id}>{this.props.lang == 'en' ? item.name.replace(' (onlinebesök)', ' (online visit)') : item.name.replace(' (online visit)', ' (onlinebesök)')}</option>
                                                                                                            )
                                                                                                    } else {
                                                                                                        if (!(item.wayOfScheduling == 'webBooking')
                                                                                                            && ((item.freeOfCharge && item.price == 0) || checkAge))
                                                                                                            return (
                                                                                                                <option value={item._id}>{this.props.lang == 'en' ? item.name.replace(' (onlinebesök)', ' (online visit)') : item.name.replace(' (online visit)', ' (onlinebesök)')}</option>
                                                                                                            )
                                                                                                    }
                                                                                                }
                                                                                            }
                                                                                        } else {
                                                                                            if (item.onlineVisit) {
                                                                                                let checkAge = false;
                                                                                                let age = this.state.age;
                                                                                                if (item.under18 && age <= 18) {
                                                                                                    checkAge = true;
                                                                                                }
                                                                                                if (item.under20 && age <= 20) {
                                                                                                    checkAge = true;
                                                                                                }
                                                                                                if (item.under23 && age <= 23) {
                                                                                                    checkAge = true;
                                                                                                }
                                                                                                if (item.older85 && age > 85) {
                                                                                                    checkAge = true;
                                                                                                }
                                                                                                if (item.under18child && this.state.forChild) {
                                                                                                    checkAge = true;
                                                                                                }


                                                                                                if ((this.state.clinicGroup && this.state.clinicGroup.agentId && this.state.clinicGroup.apiKey) || (this.state.clinicGroup && this.state.clinicGroup.bankAccount)) {
                                                                                                    if (!(item.wayOfScheduling == 'webBooking'))
                                                                                                        return (
                                                                                                            <option value={item._id}>{this.props.lang == 'en' ? item.name.replace(' (onlinebesök)', ' (online visit)') : item.name.replace(' (online visit)', ' (onlinebesök)')}</option>
                                                                                                        )
                                                                                                } else {
                                                                                                    if (!(item.wayOfScheduling == 'webBooking')
                                                                                                        && ((item.freeOfCharge && item.price == 0) || checkAge))
                                                                                                        return (
                                                                                                            <option value={item._id}>{this.props.lang == 'en' ? item.name.replace(' (onlinebesök)', ' (online visit)') : item.name.replace(' (online visit)', ' (onlinebesök)')}</option>
                                                                                                        )
                                                                                                }
                                                                                            }
                                                                                        }


                                                                                    } else {
                                                                                        return (
                                                                                            <option value={item._id}>{this.props.lang == 'en' ? item.name.substring(0, 86).replace(' (onlinebesök)', ' (online visit)') : item.name.substring(0, 86).replace(' (online visit)', ' (onlinebesök)')}</option>
                                                                                        )
                                                                                    }
                                                                                }




                                                                            })
                                                                        }
                                                                    </Input> */}

                                                                </FormGroup>
                                                                :
                                                                null
                                                        }


                                                    </Col>
                                                </>

                                                :

                                                <Col lg="12">
                                                    <AnswerFormComponent
                                                        {...this.props}
                                                        referral={this.state.referral}
                                                        clinicGroup={this.state.clinicGroup}
                                                        getSaveFormData={(getSaveFormData) => this.getSaveFormData = getSaveFormData}
                                                        setSaveFormData={(setSaveFormData) => this.setSaveFormData = setSaveFormData}
                                                        submitAction={(data, callback) => {
                                                            this.submit(data, callback)
                                                        }}
                                                    />
                                                </Col>
                                        }

                                    </Row>


                                </div>

                            }


                        </Col>
                        <Col lg="12">
                            <div className='fill-formular-footer-wrap'>
                                <Button className="close-button close-button-mobile" color="danger" outline onClick={() => this.setState({ cancelModal: true })}>{'Cancel'.translate(this.props.lang)}</Button>
                                {
                                    this.state.referral && this.state.referral.saveForm ?
                                        <Button className="save-formular-button save-formular-button-mobile" color="primary" onClick={() => this.setState({ saveFormModal: true })}>{'Continue later'.translate(this.props.lang)}</Button>
                                        :
                                        null
                                }
                            </div>
                        </Col>




                    </Row>

                </Container>


                {this.state.checkSavedDropInForm ?
                    <InfoModal
                        isOpen={this.state.checkSavedDropInForm}
                        onClose={(item) => {
                            this.setState({
                                checkSavedDropInForm: item
                            })
                        }}
                        close={true}
                        header={"Continue answering".translate(this.props.lang)}
                        info={"Do you wish to continue answering previously saved form?".translate(this.props.lang)}
                        buttons={[
                            <Button color="primary" style={{ margin: 10 }} onClick={() => {
                                this.continueSavedCase()


                            }}>{'Yes'.translate(this.props.lang)}</Button>,
                            <Button color="primary" style={{ margin: 10 }} onClick={() => this.setState({ checkSavedDropInForm: false })}>{'No'.translate(this.props.lang)}</Button>
                        ]}
                    />
                    : null}



                {
                    this.state.error ?

                        <ErrorModal lang={this.props.lang}
                            isOpen={this.state.error}
                            toggle={() => this.setState({ error: null })}

                        >
                            {this.state.error.translate(this.props.lang)}
                        </ErrorModal>

                        :
                        null
                }




                {
                    this.state.payNowModal ?

                        <PayNowModal lang={this.props.lang}
                            isOpen={this.state.payNowModal}

                            toggle={() => {
                                if (this.state.isDropIn && !this.state.payNowModal.paymentAfterSubmission) {
                                    this.setState({ cancelCostModal: true })
                                } else if (this.state.payNowModal && this.state.payNowModal.paymentAfterSubmission) {
                                    this.setState({ payNowModal: null, successModal: 'Payment failed, please make sure to pay before your appointment.' })
                                } else if (this.state.payNowModal && this.state.payNowModal.paymentAfterBookedTime) {
                                    this.setState({ payNowModal: null, successModal: 'Your case has been submitted successfully. Remember to make the payment before the scheduled time.' })
                                } else if (this.state.payNowModal && this.state.payNowModal.wayOfScheduling == 'webBooking') {
                                    this.setState({ payNowModal: null, successModal: 'You have successfully scheduled a visit. Remember to make the payment before the scheduled time.' })
                                }
                            }}
                            regions={this.state.regions}
                            dropIn={this.state.isDropIn ? true : false}
                            groupPaymentData={{
                                agentId: this.state.clinicGroup?.agentId,
                                apiKey: this.state.clinicGroup?.apiKey,
                                bankAccount: this.state.clinicGroup?.bankAccount,

                                stripeAccountID: this.state.clinicGroup?.stripeAccountID,

                                canUsePaysonForPayment: this.state.clinicGroup?.canUsePaysonForPayment,
                                canUseSwishForPayment: this.state.clinicGroup?.canUseSwishForPayment,
                                canUseStripeForPayment: this.state.clinicGroup?.canUseStripeForPayment
                            }}
                            updatePaymentMethod={(method) => {
                                let choosenPayment = this.state.choosenPayment;
                                choosenPayment = method.paymentMethod;
                                this.setState({
                                    choosenPayment,
                                    onlinePaymentMethod: 7,

                                }, () => {
                                    this.updateReferral()
                                })
                            }}
                            updatePaymentMethodFreeCard={(data) => this.updatePaymentMethodFreeCard(data)}
                            openPayson={(data) => {
                                let choosenPayment = this.state.choosenPayment;
                                choosenPayment = 3;
                                this.setState({
                                    choosenPayment,
                                    onlinePaymentMethod: 7,
                                    payson: data,
                                    swish: null,
                                    stripe: null
                                }, () => {
                                    this.updateReferral()
                                })

                            }}
                            openSwish={(data) => {
                                let choosenPayment = this.state.choosenPayment;
                                choosenPayment = 3;
                                this.setState({
                                    choosenPayment,
                                    onlinePaymentMethod: 7,
                                    swish: data,
                                    payson: null,
                                    stripe: null
                                }, () => {
                                    this.updateReferral()
                                })

                            }}
                            openStripe={(data) => {
                                let choosenPayment = this.state.choosenPayment;
                                choosenPayment = 3;
                                this.setState({
                                    choosenPayment,
                                    onlinePaymentMethod: 7,
                                    stripe: data,
                                    payson: null,
                                    swish: null
                                }, () => {
                                    this.updateReferral()
                                })

                            }}
                        >
                        </PayNowModal>

                        : null
                }
                {
                    this.state.checkWorkingHoursError ?
                        <InfoModal
                            isOpen={this.state.checkWorkingHoursError}
                            onClose={(item) => {
                                this.setState({
                                    checkWorkingHoursError: item
                                })
                            }}
                            close={true}
                            header={'Info'.translate(this.props.lang)}
                            info={
                                <>
                                    <span style={{ fontWeight: 600 }}>{this.state.checkWorkingHoursError}</span>
                                    <div className='table-error-dropin'>
                                        {
                                            this.state.checkWorkingHoursAvailableTerms && this.state.checkWorkingHoursAvailableTerms.map((item, idx) => {
                                                const startTime = this.props.getStringDate(item.startTimeUTC, 'HH:mm');
                                                // console.log(idx, startTime, item.startTimeUTC);
                                                const endTime = this.props.getStringDate(item.endTimeUTC, 'HH:mm');
                                                const timeUTC = `${startTime} - ${endTime}`
                                                return (


                                                    idx == 0 ?
                                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>{'Monday'.translate(this.props.lang).toUpperCase()}: <span>{item.available ? timeUTC : 'Closed'.translate(this.props.lang)}</span></div>
                                                        :
                                                        idx == 1 ?
                                                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>{'Tuesday'.translate(this.props.lang).toUpperCase()}: <span>{item.available ? timeUTC : 'Closed'.translate(this.props.lang)}</span></div>
                                                            :
                                                            idx == 2 ?
                                                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>{'Wednesday'.translate(this.props.lang).toUpperCase()}: <span>{item.available ? timeUTC : 'Closed'.translate(this.props.lang)}</span></div>
                                                                :
                                                                idx == 3 ?
                                                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>{'Thursday'.translate(this.props.lang).toUpperCase()}: <span>{item.available ? timeUTC : 'Closed'.translate(this.props.lang)}</span></div>
                                                                    :
                                                                    idx == 4 ?
                                                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>{'Friday'.translate(this.props.lang).toUpperCase()}: <span>{item.available ? timeUTC : 'Closed'.translate(this.props.lang)}</span></div>
                                                                        :
                                                                        idx == 5 ?
                                                                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>{'Saturday'.translate(this.props.lang).toUpperCase()}: <span>{item.available ? timeUTC : 'Closed'.translate(this.props.lang)}</span></div>
                                                                            :
                                                                            idx == 6 ?
                                                                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>{'Sunday'.translate(this.props.lang).toUpperCase()}: <span>{item.available ? timeUTC : 'Closed'.translate(this.props.lang)}</span></div>
                                                                                :
                                                                                null


                                                )
                                            })
                                        }
                                    </div>
                                </>

                            }
                            buttons={[
                                <Button color="primary" style={{ margin: 10 }} onClick={() => this.setState({ checkWorkingHoursError: false }, () => {
                                    this.props[0].history.push(`/${this.props[0].match.params.alias}`)
                                })}>{'Ok'.translate(this.props.lang)}</Button>,

                            ]}
                        />
                        :
                        null
                }

                {this.state.costModal ?
                    <InfoModal
                        isOpen={this.state.costModal}
                        onClose={(item) => {
                            this.setState({
                                costModal: item
                            })
                        }}
                        close={true}
                        // toggle={() => this.setState({ constModal: !this.state.costModal })}
                        header={this.state.referral.paymentOnSite && this.state.choosenPayment == 1 ? 'Payment on site'.translate(this.props.lang) : 'Invoice'.translate(this.props.lang)}
                        info={this.state.referral.paymentOnSite && this.state.choosenPayment == 1 ?
                            <p>{`This visit costs`.translate(this.props.lang) + ' ' + this.state.referral.price + ' ' + currency + " " + `and payment is made directly when visiting us.`.translate(this.props.lang)}</p>
                            : this.state.referral.invoice && this.state.choosenPayment == 2 ?
                                <div dangerouslySetInnerHTML={{ __html: this.state.referral.invoiceMessage }} /> : null
                        }
                        buttons={[
                            <Button color="primary" style={{ margin: 10 }} onClick={() => this.setState({ costModal: false }, () => this.sendReferral())}>{'Ok'.translate(this.props.lang)}</Button>,
                            <Button color="primary" style={{ margin: 10 }} onClick={() => this.setState({ costModal: false })}>{'Cancel'.translate(this.props.lang)}</Button>


                        ]}
                    />
                    : null}

                {this.state.freeCardError ?
                    <InfoModal
                        isOpen={this.state.freeCardError}
                        onClose={(item) => {
                            this.setState({
                                freeCardError: item
                            })
                        }}
                        close={true}
                        header={'Free card validation error'.translate(this.props.lang)}
                        info={'Your free card will not be valid on the day of the scheduled visit.'.translate(this.props.lang)}
                        buttons={[
                            <Button color="primary" style={{ margin: 10 }} onClick={() => this.setState({ freeCardError: false })}>{'Cancel'.translate(this.props.lang)}</Button>


                        ]}
                    />
                    : null}

                {
                    this.state.payNowOrLater ?
                        <InfoModal
                            isOpen={this.state.payNowOrLater}
                            onClose={(item) => {
                                this.setState({
                                    payNowOrLater: item
                                })
                            }}
                            close={true}
                            // toggle={() => this.setState({ payNowOrLater: !this.state.payNowOrLater })}
                            header={'Payment'.translate(this.props.lang)}
                            info={'Do you want to pay for your visit now?'.translate(this.props.lang)}
                            buttons={[
                                <Button color="primary" style={{ margin: 10 }} onClick={() => this.setState({ payNowModal: this.state.payNowOrLater, payNowOrLater: false })}>{'Pay now'.translate(this.props.lang)}</Button>,
                                <Button color="primary" style={{ margin: 10 }} onClick={() => this.setState({ successModalFreeCard: this.state.referral && !this.state.referral.paymentAfterBookedTime ? 'You have successfully scheduled a visit. Remember to make the payment before the scheduled time.' : 'Your case has been submitted successfully. Remember to make the payment before the scheduled time', payNowOrLater: false })}>{'Pay later'.translate(this.props.lang)}</Button>


                            ]}
                        />
                        :
                        null
                }
                {
                    this.state.freeOfChargeModal ?
                        <Modal isOpen={this.state.freeOfChargeModal} centered >
                            {/* <ModalHeader style={{ margin: 'auto' }}>{'Free of charge'.translate(this.props.lang)}</ModalHeader> */}
                            <ModalBody className='delete-modal'>
                                {/* <Isvg src={info} /> */}
                                <p className='message'>{`This visit is free of charge.`.translate(this.props.lang)}</p>

                            </ModalBody>
                            <ModalFooter className='buttons-right-blue'>
                                <Button color="primary" style={{ margin: 10, minWidth: 100 }} onClick={() => this.setState({ freeOfChargeModal: false }, () => this.sendReferral())}>{'Ok'.translate(this.props.lang)}</Button>

                            </ModalFooter>
                        </Modal>
                        :
                        null
                }

                {this.state.cancelCostModal ?
                    <InfoModal
                        isOpen={this.state.cancelCostModal}
                        // toggle={() => this.setState({ cancelCostModal: !this.state.cancelCostModal })}
                        header={'Warning'.translate(this.props.lang)}
                        info={`If you cancel, your case will be deleted. Are you sure you want to continue?`.translate(this.props.lang)}
                        buttons={[
                            <Button color="primary" style={{ margin: 10 }} onClick={() => this.setState({ cancelCostModal: false }, () => this.deleteReferral())}>{'Yes'.translate(this.props.lang)}</Button>,
                            <Button color="primary" style={{ margin: 10 }} onClick={() => this.setState({ cancelCostModal: false, payNowModal: this.state.referral })}>{'No'.translate(this.props.lang)}</Button>

                        ]}

                    />
                    : null}

                {this.state.signinModal ?

                    <Modal isOpen={this.state.signinModal} centered>
                        <ModalHeader toggle={() => this.setState({ signinModal: !this.state.signinModal })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ signinModal: !this.state.signinModal })}><Isvg src={xIcon} /></button>}>
                            <h4>{'Sign in with your NET ID'.translate(this.props.lang)}</h4>

                        </ModalHeader>
                        <ModalBody>
                            <Button onClick={() => this.sendReferral()}>{'Continue'.translate(this.props.lang)}</Button>
                        </ModalBody>
                    </Modal>
                    : null
                }

                {/* className="referral-success-modal" */}
                {this.state.successModal ?
                    <Modal isOpen={this.state.successModal} centered>
                        {/* <ModalHeader style={{ margin: 'auto' }}>{'Notification'.translate(this.props.lang)}</ModalHeader> */}
                        <ModalBody className="delete-modal">
                            {/* <div className="check-icon"> */}
                            {/* <Isvg src={info} /> */}
                            {<p className='message'>{this.state.successModal.translate(this.props.lang)}</p>}
                            {/* <p className='message'>{this.state.successModal == 'Free Card payment successful.' ? 'Free Card payment successful.'.translate(this.props.lang) : this.state.successModal == 'free' ? 'Based on your age, you are entitled to a free visit.'.translate(this.props.lang) : 'Your referral has been sent.'.translate(this.props.lang)}</p> */}
                            {/* </div> */}
                        </ModalBody>
                        <ModalFooter>
                            {this.state.referral.wayOfScheduling == "bookingByClinic" || this.state.referral.wayOfScheduling == "dropIn" ?

                                <Button color="primary" style={{ margin: 'auto', minWidth: 100 }} onClick={() => this.setState({ successModal: null }, () => this.props[0].history.push('/my-data/personal-referrals/my-payments'))}>{'Ok'.translate(this.props.lang)}</Button>
                                :
                                this.state.referral.wayOfScheduling == "webBooking" ?
                                    <Button color="primary" style={{ margin: 'auto', minWidth: 100 }} onClick={() => this.setState({ calendarModal: !this.state.isDropIn, successModal: null }, () => {
                                        // if (this.state.isDropIn) {
                                        this.props[0].history.push('/my-data/personal-referrals/my-payments')
                                        // }
                                    })}>{'Ok'.translate(this.props.lang)}</Button>
                                    : null}
                        </ModalFooter>
                    </Modal>
                    : null}

                {this.state.successModalFreeCard ?
                    <Modal isOpen={this.state.successModalFreeCard} centered>
                        {/* <ModalHeader style={{ margin: 'auto' }}>{'Notification'.translate(this.props.lang)}</ModalHeader> */}
                        <ModalBody className="delete-modal">
                            {/* <Isvg src={info} /> */}
                            {<p className='message'>{this.state.successModalFreeCard.translate(this.props.lang)}</p>}
                        </ModalBody>
                        <ModalFooter>
                            {this.state.referral.wayOfScheduling == "bookingByClinic" || this.state.referral.wayOfScheduling == "dropIn" ?

                                <Button color="primary" style={{ margin: 'auto', minWidth: 100 }} onClick={() => this.setState({ successModalFreeCard: null }, () => this.props[0].history.push('/my-data/personal-referrals/referrals'))}>{'Ok'.translate(this.props.lang)}</Button>
                                :
                                this.state.referral.wayOfScheduling == "webBooking" ?
                                    <Button color="primary" style={{ margin: 'auto', minWidth: 100 }} onClick={() => this.setState({ calendarModal: !this.state.isDropIn, successModalFreeCard: null }, () => {
                                        this.props[0].history.push('/my-data/personal-referrals/referrals')
                                    })}>{'Ok'.translate(this.props.lang)}</Button>
                                    : null}
                        </ModalFooter>
                    </Modal>
                    : null}

                {this.state.bankIdModal ?
                    <BankIdModal
                        {...this.props}
                        isOpen={this.state.bankIdModal}
                        toggle={() => this.setState({ bankIdModal: null })}
                        redirect_url={this.state.bankIdModal}></BankIdModal>
                    : null}
                {
                    this.state.cancelModal ?
                        <DeleteModal
                            lang={this.props.lang}
                            isOpen={this.state.cancelModal}
                            header={'Close case'.translate(this.props.lang)}
                            toggle={() => this.setState({ cancelModal: null })}
                            handler={() => {
                                this.props[0].history.push(`/${this.props[0].match.params.alias}`)
                                // this.props.ring112Audio(false)
                            }}
                        >
                            {'Are you sure you want to close the case? It has not been completed and will not be saved.'.translate(this.props.lang)}
                        </DeleteModal>
                        :
                        null
                }
                {this.state.saveFormInfoModal ?
                    <InfoModal
                        isOpen={this.state.saveFormInfoModal}
                        onClose={(item) => {
                            this.setState({
                                saveFormInfoModal: item
                            })
                        }}
                        close={true}
                        header={"Info".translate(this.props.lang)}
                        info={this.state.saveFormInfoModal.translate(this.props.lang)}
                        buttons={[
                            <Button color="primary" style={{ margin: 10 }} onClick={() => this.setState({ saveFormInfoModal: false })}>{'Ok'.translate(this.props.lang)}</Button>
                        ]}
                    />
                    : null}


                {this.state.saveFormModal ?
                    <InfoModal
                        isOpen={this.state.saveFormModal}
                        onClose={(item) => {
                            this.setState({
                                saveFormModal: item
                            })
                        }}
                        close={true}
                        header={'Info'.translate(this.props.lang)}
                        info={'Are you sure you want to save the case?'.translate(this.props.lang)}
                        buttons={[
                            <Button color="primary" style={{ margin: 10 }} onClick={() => this.saveForm()}>{'Yes'.translate(this.props.lang)}</Button>,
                            <Button color="primary" style={{ margin: 10 }} onClick={() => this.setState({ saveFormModal: false })}>{'No'.translate(this.props.lang)}</Button>


                        ]}
                    />
                    : null}
                {this.state.savedFormModal ?
                    <InfoModal
                        isOpen={this.state.savedFormModal}
                        onClose={(item) => {
                            this.setState({
                                savedFormModal: item
                            })
                        }}
                        close={true}
                        header={'Info'.translate(this.props.lang)}
                        info={'Your questionnaire has been saved.'.translate(this.props.lang)}
                        buttons={[
                            <Button color="primary" style={{ margin: 10 }} onClick={() => {
                                let resumeForm = this.state.savedFormModal;
                                this.setState({ resumeForm: resumeForm, savedFormModal: false })
                            }}>{'Continue answering'.translate(this.props.lang)}</Button>,
                            <Button color="primary" style={{ margin: 10 }} onClick={() => {
                                this.props[0].history.push(`/${this.props[0].match.params.alias}`)
                            }}>{'Close the form'.translate(this.props.lang)}</Button>


                        ]}
                    />
                    : null}




                {
                    this.state.closeWarn ?
                        <Modal isOpen={this.state.closeWarn} centered className="calendar-modal">

                            {/* <ModalHeader style={{ margin: 'auto' }} toggle={() => this.setState({ closeWarn: !this.state.closeWarn })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ closeWarn: false })}>&times;</button>}>{'Warning!'.translate(this.props.lang)}</ModalHeader> */}

                            <ModalBody className="delete-modal">
                                {/* <Isvg src={warrning} /> */}
                                <h6 className='message-info'>{'If you do not make an appointment, your remiss will be deleted from the system. Are you sure you want to continue?'.translate(this.props.lang)}</h6>
                            </ModalBody>
                            <ModalFooter className={'buttons-right-blue'}>
                                <Button style={{ minWidth: 100 }} color="primary" onClick={() => {
                                    // this.closeCalendar()
                                    this.checkDeleteReferral()
                                }
                                }>{'Yes'.translate(this.props.lang)}</Button>
                                <Button color="primary" style={{ marginLeft: '10px', minWidth: 100 }} onClick={() => this.setState({ closeWarn: false })}>{'No'.translate(this.props.lang)}</Button>
                            </ModalFooter>
                        </Modal>
                        :
                        null
                }
                {this.state.cardModal && this.state.onlinePaymentMethod == 7 ?
                    <Modal isOpen={this.state.cardModal} centered>
                        <ModalHeader style={{ margin: 'auto' }}>{'Pick method of payment:'.translate(this.props.lang)}</ModalHeader>

                        <ModalBody className="delete-modal">
                            <Isvg src={Card} />
                        </ModalBody>
                        <ModalFooter className='buttons-right-blue'>
                            <div className="payment-container">
                                {this.state.clinicGroup.agentId && this.state.clinicGroup.apiKey ?
                                    <Button className="payment-button-payson" onClick={() => this.setState({ paysonModal: this.state.cardModal, cardModal: !this.state.cardModal })}><Isvg src={PaysonSvg} /> <span>{'Credit card'.translate(this.props.lang)}</span> </Button>
                                    : null}
                                {this.state.clinicGroup.bankAccount ?
                                    <Button className="payment-button-swish" onClick={() => this.setState({ swishModal: this.state.cardModal, cardModal: !this.state.cardModal })}><Isvg src={SwishSvg} /></Button>
                                    : null}
                            </div>
                        </ModalFooter>
                    </Modal>
                    : null}

                {this.state.paysonModal ?

                    <Payson
                        lang={this.props.lang}
                        openModal={(item) => this.setState({
                            paysonModal: item
                        }, () => {

                            if (this.state.isDropIn && !this.state.referral.paymentAfterSubmission) {
                                this.setState({
                                    cancelCostModal: true
                                })
                            } else if (this.state.referral && this.state.referral.paymentAfterSubmission) {
                                this.setState({ payNowModal: null, successModal: 'Payment failed, please make sure to pay before your appointment.' })
                            } else if (this.state.referral && this.state.referral.paymentAfterBookedTime) {
                                this.setState({
                                    successModal: 'Your case has been submitted successfully. Remember to make the payment before the scheduled time.'
                                })
                            } else {
                                // this.props[0].history.push('/my-data/personal-referrals/my-payments')
                                this.setState({
                                    successModal: 'You have successfully scheduled a visit. Remember to make the payment before the scheduled time.'
                                })
                            }
                        })}
                        referral={this.state.newReferralId}
                        price={this.state.referral.price}
                        patient={this.props.uData._id}
                        successMessageNeeded={true}

                    />

                    : null}
                {this.state.stripeModal ?

                    <Stripe
                        {...this.props}
                        lang={this.props.lang}
                        openModal={(item) => this.setState({
                            stripeModal: item
                        }, () => {

                            if (this.state.isDropIn && !this.state.referral.paymentAfterSubmission) {
                                this.setState({
                                    cancelCostModal: true
                                })
                            } else if (this.state.referral && this.state.referral.paymentAfterSubmission) {
                                this.setState({ payNowModal: null, successModal: 'Payment failed, please make sure to pay before your appointment.' })
                            } else if (this.state.referral && this.state.referral.paymentAfterBookedTime) {
                                this.setState({
                                    successModal: 'Your case has been submitted successfully. Remember to make the payment before the scheduled time.'
                                })
                            } else {
                                // this.props[0].history.push('/my-data/personal-referrals/my-payments')
                                this.setState({
                                    successModal: 'You have successfully scheduled a visit. Remember to make the payment before the scheduled time.'
                                })
                            }
                        })}
                        referral={this.state.newReferralId}
                        price={this.state.referral.price}
                        patient={this.props.uData._id}
                        successMessageNeeded={true}

                    />

                    : null}
                {this.state.swishModal ?

                    <Swish
                        getStringDateInverseTs={this.props.getStringDateInverseTs}
                        clearStatusBefore={() => this.props.clearStatusBefore()}
                        verifyUser={() => this.props.verifyUser(null, null, true)}
                        lang={this.props.lang}
                        history={this.props[0]}
                        open={this.state.swishModal}
                        phone={this.props.uData.phone}
                        price={this.state.referral.price}
                        // referralNumber={this.state.referralNumber}
                        referral={this.state.newReferralId}
                        sendNotification={true}
                        callbackFunction={() => {
                            if (this.state.referral?.paymentBeforeSubmission) {
                                if (this.props.patientCreatedReferral) this.props.patientCreatedReferral();
                            }

                            if (this.state.isDropIn || (this.state.referral && this.state.referral.paymentBeforeBookedTime)) {
                                this.setState({
                                    successModalFreeCard: this.state.isDropIn ? "Your case has been submitted and payment has been made successfully. Please remain online and track the status of your case.".translate(this.props.lang) : "Your case has been submitted and payment has been made successfully.".translate(this.props.lang),
                                })
                            } else {
                                this.setState({
                                    successModalFreeCard: this.state.referral && this.state.referral.paymentAfterBookedTime ? 'Your case has been submitted and payment has been made successfully.'.translate(this.props.lang) : "Payment successful.".translate(this.props.lang),
                                })
                            }
                        }
                        }
                        openModal={(item) => this.setState({
                            swishModal: item
                        }, () => {
                            if (this.state.isDropIn && !this.state.referral.paymentAfterSubmission) {
                                this.setState({
                                    cancelCostModal: true
                                })
                            } else if (this.state.referral && this.state.referral.paymentAfterSubmission) {
                                this.setState({ payNowModal: null, successModal: 'Payment failed, please make sure to pay before your appointment.' })
                            } else {
                                // this.props[0].history.push('/my-data/personal-referrals/referrals')
                                this.setState({
                                    successModal: this.state.referral && this.state.referral.paymentAfterBookedTime ? 'Your case has been submitted successfully. Remember to make the payment before the scheduled time.' : 'You have successfully scheduled a visit. Remember to make the payment before the scheduled time.'
                                })
                            }
                        })
                        }
                    />

                    : null}

                {this.state.estimatedWaitingTime ?
                    <InfoModal
                        isOpen={this.state.estimatedWaitingTime}
                        onClose={(item) => {
                            this.setState({
                                estimatedWaitingTime: item
                            })
                        }}
                        close={true}
                        header={'Info'.translate(this.props.lang)}
                        info={'Estimated waiting time is'.translate(this.props.lang) + ' ' + (this.state.estimatedWaitingTime ? parseInt(this.state.estimatedWaitingTime / 60) == 0 ? this.state.estimatedWaitingTime + ' ' + 'minutes'.translate(this.props.lang) : + parseInt(this.state.estimatedWaitingTime / 60) + 'h'.translate(this.props.lang) + ' ' + 'and'.translate(this.props.lang) + ' ' + this.state.estimatedWaitingTime % 60 + ' ' + 'minutes'.translate(this.props.lang) : '/')}
                        buttons={[
                            <Button color="primary" style={{ margin: 10 }} onClick={() => this.setState({ estimatedWaitingTime: null }, () => { })}>{'Continue'.translate(this.props.lang)}</Button>,
                            <Button color="primary" style={{ margin: 10 }} onClick={() => this.props[0].history.push('/home-patient-level')}>{'Cancel'.translate(this.props.lang)}</Button>


                        ]}
                    />
                    : null}

                {this.props.changeLinkModal ?
                    <DeleteModal
                        lang={this.props.lang}
                        isOpen={this.props.changeLinkModal}
                        header={'Close case'.translate(this.props.lang)}
                        toggle={() => {
                            this.props.abortAction()
                            if (!this.props.groupSettingsModal) {
                                this.props.groupSettingsChanged()
                            }
                        }
                        }
                        handler={() => {
                            if (this.state.newReferralId) {
                                this.deleteReferral(true)
                            } else {
                                if (this.props.changeLinkModal) {
                                    this.props[0].history.push(this.props.changeLinkModal)
                                } else {
                                    this.props[0].history.push(`/${this.props[0].match.params.alias}`)
                                }
                                // this.props[0].history.push(`/${this.props[0].match.params.alias}`)

                            }
                            // this.props.ring112Audio(false)
                        }}
                    >
                        {'Are you sure you want to close the case? It has not been completed and will not be saved.'.translate(this.props.lang)}
                    </DeleteModal>
                    : null}

                {
                    this.state.twoFactorAuthForbidden ? <Modal
                        zIndex={99999}
                        size='md'
                        centered
                        isOpen={this.state.twoFactorAuthForbidden}
                    >
                        <ModalBody style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 20 }}>
                            <p style={{ textAlign: 'center', margin: 0, padding: 0, }}>{typeof this.state.twoFactorAuthForbidden === 'string' ? this.state.twoFactorAuthForbidden.translate(this.props.lang) : 'This clinic does not accept cases from patients who use two factor authentication, if their account is not verified with them.'.translate(this.props.lang)}</p>
                        </ModalBody>
                        <ModalFooter style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Button onClick={this.navigateBackToProfile} style={{ minWidth: 100 }}>
                                {'Ok'.translate(this.props.lang)}
                            </Button>
                        </ModalFooter>
                    </Modal> : null
                }


            </div >
        );
    }
}

const selector = formValueSelector('modalForm');

export default connect(state => {
    return {
        group: selector(state, 'group'),
        type: selector(state, 'type'),
        displayOnReferral: selector(state, 'displayOnReferral'),
        conditionQuestion: selector(state, 'conditionQuestion')

    }

}, {
    changeSubgroup: value => change("modalForm", "subgroup", value),

})(Page(DropInReferral));
