import React, { Component } from 'react';
import Page from '../containers/page';

import { Link } from 'react-router-dom';

import {
    Container,
    Row,
    Col,
    Button,
    FormGroup,
    Label,
    Input,
    Modal, ModalHeader, ModalBody, ModalFooter, Form
} from 'reactstrap';
import Checkbox from './forms/fields/checkbox';
import Isvg from "react-inlinesvg";
import xIcon from '../assets/svg/x.svg';
import infoIcon from "../assets/svg/link-new-tab.svg";


import { API_ENDPOINT } from '../constants';
import HTML from './forms/fields/html';

import FormBuilder from './forms/modalFormBuilder';
import { formValueSelector, change } from 'redux-form';  // ES6
import { connect } from 'react-redux';

import { required, checkSocialSecurityNumber, dateValidate } from './forms/validation';
import SocialSecurityNumber, { isSocialSecurityNumberValid } from './forms/fields/socialSecurityNumber';

import ErrorModal from './errorModal';
import InfoModal from './infoModal';



function parseJSON(response) {
    return response.json().then(json => {
        return {
            result: json,
            status: response.status
        }
    })
}



class WriteStatement extends Component {
    constructor(props) {
        super(props);
        this.searchResultsWraps = {};


        this.state = {
            ...props.initialData,
            statement: {
                title: '',
                notes: ''
            }

        };
    }

    componentDidMount() {
        if (this.props.conversation?.statement) {
            let statement = {
                title: this.props.conversation?.statement?.title,
                notes: this.props.conversation?.statement?.notes,
                email: this.props.conversation?.statement?.email
            }
            this.setState({ statement, lastSavedStatement: JSON.parse(JSON.stringify(statement)), newStatement: { ...statement } })
        } else {
            this.setState({
                newStatement: 'new'
            })
        }
        this.autoSaveInterval = setInterval(() => {
            this.saveStatementInConversation()
        }, 3000);

    }
    componentDidUpdate(prevProps, prevState) {

    }

    componentWillUnmount() {
        this.props.socket.removeListener('statementEvent');
    }

    saveStatementInConversation = (callback) => {
        let saveStatement = true;
        if (JSON.stringify(this.state.lastSavedStatement) === JSON.stringify(this.state.statement)) {
            saveStatement = false;
        }

        if (this.props.conversation?._id && saveStatement) {
            let statementForSave = this.state.statement ?? { title: '', notes: '' };
            let data = {
                statement: statementForSave,
                conversationId: this.props.conversation._id
            }

            fetch(API_ENDPOINT + '/chat/update/statement', {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage?.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify(data)
            }).then(res => res.json()).then((result) => {
                this.props.updateStatementInConversation?.(statementForSave)
                this.setState({ lastSavedStatement: JSON.parse(JSON.stringify(statementForSave)) })

                if (callback) {
                    callback()
                }

            })
        } else {
            if (callback) {
                callback()
            }
        }

    }
    componentWillUnmount() {
        this.saveStatementInConversation()

        if (this.autoSaveInterval) {
            clearInterval(this.autoSaveInterval)
        }
    }
    saveInPatientProfile = () => {
        let validation = this.checkValidation();
        if (validation) {
            this.setState({ message: validation.translate(this.props.lang) })
            return;
        }

        if (this.props.conversation?._id) {
            this.setState({ loadingSave: true }, () => {
                let data = {
                    conversationId: this.props.conversation._id,
                    clinic: this.props.selectedClinic
                }
                fetch(API_ENDPOINT + '/statement/save', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage?.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify(data)
                }).then(res => res.json()).then((result) => {
                    this.setState({ loadingSave: false, message: 'The statement was successfully saved'.translate(this.props.lang) })


                })
            })
        }


    }
    sendToEmail = () => {
        let validation = this.checkValidation();
        if (validation) {
            this.setState({ message: validation.translate(this.props.lang) })
            return;
        }


        let statement = {
            title: this.state.statement?.title,
            notes: this.state.statement?.notes
        }
        if (this.props.conversation?._id) {
            this.setState({ loadingPdf: true }, () => {
                let data = {
                    conversationId: this.props.conversation._id,
                    lang: this.props.lang
                }
                fetch(API_ENDPOINT + '/statement/create-pdf', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage?.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify(data)
                }).then(res => res.json()).then((result) => {
                    let pdfCreated = result.pdfCreated;
                    let emailSent = result.emailSent;

                    if (pdfCreated) {
                        this.props.updateStatementLastCreatedPdfInConversation?.(statement)
                    }

                    let message;
                    if (!pdfCreated) {
                        message = 'An error occurred while creating the PDF.'.translate(this.props.lang);
                    } else if (pdfCreated && !emailSent?.length) {
                        message = 'The PDF was created but no email was found to be sent to.'.translate(this.props.lang);
                    } else if (pdfCreated && emailSent?.length === 1) {
                        message = `${'The PDF has been created and sent to the following email address'.translate(this.props.lang)}: ${emailSent.join(', ')}.`
                    } else if (pdfCreated && emailSent?.length > 1) {
                        message = `${'The PDF has been created and sent to the following email addresses'.translate(this.props.lang)}: ${emailSent.join(', ')}.`
                    }

                    this.setState({ loadingPdf: false, message })

                })
            })
        }

    }
    checkPdfCreated = () => {
        let returnValue = false;

        if (this.props.conversation?.statementLastCreatedPdf && this.props.conversation?.statement) {
            let statementLastCreatedPdf = {
                title: this.props.conversation.statementLastCreatedPdf?.title,
                notes: this.props.conversation.statementLastCreatedPdf?.notes
            };
            let statement = {
                title: this.props.conversation.statement?.title,
                notes: this.props.conversation.statement?.notes
            };
            if (JSON.stringify(statementLastCreatedPdf) === JSON.stringify(statement)) {
                returnValue = true;
            }
        }
        return returnValue;
    }
    checkValidation = () => {

        let title = this.state.statement?.title;
        let notes = this.state.statement?.notes;
        title = title.trim();
        notes = notes.trim();

        if (!title && !notes) {
            return 'Title and Notes fields are required.';
        } else if (!title) {
            return 'Title is required.';
        } else if (!notes) {
            return 'Notes is required.';
        }

        return null;
    }
    render() {
        let checkPdf = this.checkPdfCreated()

        return (
            <div className='write-statement-wrap'>
                {
                    this.state.message ?
                        <InfoModal
                            isOpen={this.state.message}

                            toggle={() => this.setState({ message: null })}
                            header={'Info'.translate(this.props.lang)}
                            info={this.state.message}
                            buttons={[
                                <Button color='primary' onClick={() => {
                                    this.setState({
                                        message: null
                                    })

                                }}>{'Ok'.translate(this.props.lang)}</Button>
                            ]}
                        />
                        :
                        null
                }
                <FormGroup>
                    <Label>{'Title'.translate(this.props.lang)}</Label>
                    <Input type='text' onChange={(e) => {
                        let statement = this.state.statement;
                        statement.title = e.target.value;
                        this.setState({ statement })
                    }} value={this.state.statement?.title} />
                </FormGroup>
                <FormGroup>
                    <Label>{'Notes'.translate(this.props.lang)}</Label>
                    <HTML
                        plugins={''}
                        toolbar={[
                            'undo redo | bold | fontsizeselect',
                        ]}
                        disabledMenuBar={true}
                        height={350}
                        value={this.state.statement?.notes} onChange={(text) => {
                            let statement = this.state.statement;
                            statement.notes = text;
                            this.setState({ statement })
                        }} />
                </FormGroup>
                <FormGroup>
                    <Label>
                        {'Email'.translate(this.props.lang)}
                        <div className="tooltip-wrap">
                            <Isvg src={infoIcon} style={{ width: 20, minWidth: 20, height: 20 }} />
                            <div className="tooltip-on-element">{'The email field is optional, if an email is entered, a link to the PDF will be sent to it. Otherwise, the email address from the patient who is in the chat is used.'.translate(this.props.lang)}</div>
                        </div>
                    </Label>
                    <Input type='text' onChange={(e) => {
                        let statement = this.state.statement;
                        statement.email = e.target.value;
                        this.setState({ statement })
                    }} value={this.state.statement?.email} />
                </FormGroup>
                {/* {
                    checkPdf ?
                        <div className='info-message'>{'PDF created and sent to email!'.translate(this.props.lang)}</div>
                        :
                        null
                } */}
                <div className='write-statement-buttons-wrap'>
                    <div className='save-button-statement'>
                        <Button color='primary' disabled={this.state.loadingSave} onClick={() => {

                            if (this.state.newStatement !== 'new' && (this.state.statement.title !== this.state.newStatement.title || this.state.statement.notes !== this.state.newStatement.notes)) {
                                let type = 'update'
                                this.props.socket.emit('statementEvent', { conversation: this.props.conversation?._id, type: type })
                                this.setState({
                                    newStatement: { ...this.state.statement }
                                })
                            } else if (this.state.newStatement === 'new') {
                                let type = 'new'
                                this.props.socket.emit('statementEvent', { conversation: this.props.conversation?._id, type: type })
                                this.setState({
                                    newStatement: { ...this.state.statement }
                                })
                            }

                            this.saveStatementInConversation(() => this.saveInPatientProfile())
                        }}>{'Save'.translate(this.props.lang)}</Button>
                        <div className="tabel-action-tooltip-statement"> <Isvg src={infoIcon} /> <div className="chat-icons-tooltip-statement">{"This will save the statement in chat and the patinet's profile. The patinet can access the statement in the chat.".translate(this.props.lang)}</div> </div>

                    </div>

                    <Button color='primary' disabled={this.state.loadingPdf} onClick={() => {
                        this.saveStatementInConversation(() => this.sendToEmail())
                    }}>{'Send email'.translate(this.props.lang)}</Button>
                </div>
            </div>

        );
    }
}


export default WriteStatement;