import React, { Component } from 'react';

import {
    Container,
    Row,
    Col, Input, Label, Modal, ModalBody, ModalHeader, FormGroup, Button, Table, FormFeedback, ModalFooter, Collapse, CardBody, Card
} from 'reactstrap';

import { getSearchParams, generateSearchLink, generateSearchLinkMultiple } from '../../../helpers/linkHelper';
import { API_ENDPOINT } from '../../../constants';
import Isvg from 'react-inlinesvg'
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Tooltip } from '@material-ui/core';
import { withStyles } from "@material-ui/core/styles";
import { v4 as uuidv4 } from 'uuid';
import InfoModal from '../../../components/infoModal';
import DatePicker from '../../../components/forms/fields/datePicker';
import moment from 'moment';
import addIcon from '../../../assets/svg/add-icon.svg';
import garbageIcon from '../../../assets/svg/garbage.svg';
import DeleteModal from '../../../components/deleteModal';
import AlertIcon from '../../../assets/svg/uzvicnik.svg';

const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: "rgba(58, 63, 85, 0.9)",
        color: "#fff",
        boxShadow: theme.shadows[1],
        fontSize: 14
    }
}))(Tooltip);


function parseJSON(response) {
    return response.json().then(json => {
        return {
            result: json,
            status: response.status
        }
    })
}



function pad(n, width, z) {
    z = z || '0';
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
}
function generateAlias(str) {
    str.trim()
    str = str.toLowerCase();
    str = str.replace(/ä/g, 'a');
    str = str.replace(/ö/g, 'o');
    str = str.replace(/ü/g, 'u');
    str = str.replace(/ß/g, 'b');

    str = str.replace(/[^a-zA-Z0-9]/gi, '-').toLowerCase()
    str = str.replace(/-+/g, '-');

    return str;
}
/**
* Calendar for doctors
* @author   Stefan Pantic, Aleksandar Dabic, Milan Stanojevic
*/
class ListOfMedicines extends Component {
    constructor(props) {
        super(props);
        this.player = React.createRef();
        this.player2 = React.createRef();
        this.state = {
            ...props.initialData,
            form: false,
            useState: false,
            params: {
                entries: 10,
                page: 0
            },
            items: [],
            listOfMedicinesNote: false,
            total: 0,
            loading: true,
            view: typeof window != 'undefined' && window.innerWidth < 768 ? 'day' : 'month',
            day: new Date(),
            listOfMedicines: [],
            editTableListOfMedicines: false,
            section: {
                // hourArray: ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23']
            },
            openCalendar: true,
            changed: true


        };
        this.state.min = new Date();
        this.state.min.setHours(8, 30, 0)
        this.state.max = new Date();
        this.state.max.setHours(20, 30, 0)

        this.state.weekDate = new Date()
        this.state.weekDate.setDate(this.state.weekDate.getDate() + 7)

        this.state.monthDate = new Date()
        this.state.monthDate.setDate(this.state.monthDate.getDate() + 31)


    }
    showPlayer = () => {
        this.setState({
            showPlayer: true,
        });
    };

    hidePlayer = () => {
        this.setState({
            showPlayer: false,
        });
    };
    stopAnimation = () => {
        this.player.current.play();
    };

    get = () => {

        let obj = {
            doctor: this.props.uData._id,
            patient: this.props.patient._id,
            clinic: this.props.selectedClinic,
            group: this.props.selectedGroup

        }
        fetch(API_ENDPOINT + '/users/all/medicines/list', {
            method: 'POST',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
            body: JSON.stringify(obj)
        }).then(parseJSON).then(({ result, status }) => {
            if (status >= 200 && status < 300) {
                this.setState({
                    section: result[0] ? result[0] : {},
                    sectionsLastState: JSON.parse(JSON.stringify(result[0] ? result[0] : {})),
                    listOfMedicinesSave: null,
                    editTableListOfMedicines: null
                })
            }
        })
    }

    componentDidMount() {
        this.get()
    }

    componentDidUpdate(prevProps, prevState) {

        if (this.state.sectionsLastState && JSON.stringify(this.state.sectionsLastState.items) != JSON.stringify(this.state.section.items) && this.state.changed) {
            if (!this.props.notSavedTestResults) {
                this.props.notSaved(true)
            }
        } else {
            if (this.props.notSavedTestResults) {

                this.props.notSaved(false)
            }
        }

        if (prevProps.initialSave != this.props.initialSave && this.props.initialSave) {
            this.insertOrUpdate(this.state.section);
        }


    }
    componentWillUnmount = () => {
        if (this.fetchWebDocInterval) {
            clearInterval(this.fetchWebDocInterval)
        }
    }


    updateSort = (field, type) => {
        if (this.state.useState) {
            let state = this.state.params;
            state.sortField = field;
            state.sortType = type;
            this.setState({
                params: state
            })
        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0 }, [{ name: 'sortField', value: field }, { name: 'sortType', value: type }], false));

        }
    }

    updateParams = (name, value, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };
                obj[name] = value;
                this.setState({ params: obj });
            } else {
                let obj = this.state.params;
                obj[name] = value;
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(generateSearchLink(this.props[0].location.search, { entries: 10, page: 0 }, name, value, restart));
        }
    }
    updateMultipleParams = (fields, restart = false) => {

        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj });

            } else {
                let obj = this.state.params;
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0 }, fields, restart));
        }
    }


    insertOrUpdate = (data) => {
        let initialSave;
        if (this.props.initialSave && this.props.initialSave !== true) {
            initialSave = { ...this.props.initialSave, changedTab: null }
        }
        console.log(initialSave, 'test')
        // console.log(data.section.hourArray)
        if (this.state.openCalendar) {
            this.setState({
                openCalendar: !this.state.openCalendar
            })
        }
        let niz = []
        if (this.state.section && this.state.section.hourArray && this.state.section.hourArray.length) {
            // let arr = [...this.state.section.hourArray]
            let hours = [...this.state.section.hourArray]
            let items = this.state.section.items ? [...this.state.section.items] : []
            // console.log(items)
            for (let i = 0; i < hours.length; i++) {
                // let check = false
                if (items && items.length) {
                    for (let j = 0; j < items.length; j++) {
                        if (items[j].timeForIngestion) {
                            for (const key in items[j].timeForIngestion) {
                                // console.log(key, hours[i], check)
                                if (key.toString() == hours[i].toString() && items[j].timeForIngestion[key] != '') {
                                    // check = true
                                    let index = niz.findIndex(el => el == hours[i])
                                    if (!niz[index])
                                        niz.push(hours[i])
                                }
                            }
                        }
                    }
                }
            }

            // console.log(niz)
            let section = this.state.section;
            section.hourArray = niz
            this.setState({
                section
            }, () => {

                if (this.state.section) {
                    console.log(this.state.section.hourArray)
                }
            })

        }

        // data.section = this.state.section
        // this.props.saveMedicine(false, () => {
        //     this.props.abortAction()
        //     this.props.setStates({ changeMedicine: null })
        // })

        this.setState({
            loading: true
        }, () => {
            if (!this.state.section._id) {

                // console.log(data)
                let obj = {
                    // ...data,
                    ...this.state.section,
                    patient: this.props.patient._id,
                    selectedGroup: this.props.selectedGroup,
                    selectedClinic: this.props.selectedClinic,
                    doctor: this.props.uData._id
                }

                this.setState({ loading: true }, () => {
                    fetch(API_ENDPOINT + '/users/insert/medicines/list', {
                        method: 'POST',
                        headers: {
                            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                            'content-type': 'application/json'
                        },
                        body: JSON.stringify(obj)
                    }).then(parseJSON).then(({ result, status }) => {

                        this.get();
                        // console.log(this.props.updateEditTimeTs, "Ffffffff") 
                        this.props.updateEditTime()
                        this.setState({
                            listOfMedicinesSave: null,
                            // openCalendar: false
                            changed: false
                        }, () => {
                            this.props.saveMedicine(false, () => {
                                if (initialSave) {
                                    this.props.setStates(initialSave)
                                } else {
                                    this.props[0].history.push(this.props.changeLinkModal)
                                    this.props.abortAction()
                                }


                            })
                        })


                    })
                })

            } else {
                fetch(API_ENDPOINT + '/users/edit/medicines/list/' + this.state.section._id, {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify(data)
                }).then(res => res.json()).then((result) => {
                    this.get();
                    // console.log(this.props.updateEditTimeTs, "gggggggggggg") 
                    this.props.updateEditTime()
                    this.setState({
                        listOfMedicinesSave: null,
                        changed: false
                    }, () => {
                        this.props.saveMedicine(false, () => {
                            if (initialSave) {
                                this.props.setStates(initialSave)
                            } else {
                                if (this.props.changeLinkModal)
                                    this.props[0].history.push(this.props.changeLinkModal)
                                this.props.abortAction()
                            }


                        })
                    })


                })
            }
        })
    }


    render() {
        // console.log(this.state.section)
        return (
            <div >
                <Container fluid>
                    <Row>

                        <Col lg='12'>
                            <div >
                                <h6 className="component-header daybook-header">
                                    <div>
                                        <span>{this.props.patient && this.props.patient.userData && this.props.patient.userData.name}</span> <br />
                                        <p>{this.props.patient && this.props.patient.socialSecurityNumber}</p>
                                    </div>
                                    <label>{'List of medicines updated'.translate(this.props.lang)}: {this.state.section && this.state.section.tsModified ? this.props.getStringDateTs(this.state.section.tsModified, this.props.dateFormat)/*moment.unix(this.state.section.tsModified).format(this.props.dateFormat)*/ : this.state.section && this.state.section.tsCreated ? this.props.getStringDateTs(this.state.section.tsCreated, this.props.dateFormat)/*moment.unix(this.state.section.tsCreated).format(this.props.dateFormat)*/ : 'Not modified'.translate(this.props.lang)}</label>
                                    {/* {this.props.patient.medicinesListModifiedTs ? moment.unix(this.props.patient.medicinesListModifiedTs).format(this.props.dateFormat) : moment(new Date()).format(this.props.dateFormat)}</label> */}
                                    {this.state.editTableListOfMedicines ?
                                        <Button color='primary' style={{ marginRight: 15 }} onClick={() => {
                                            this.setState({
                                                listOfMedicinesSave: true
                                            })
                                        }}>{'Save'.translate(this.props.lang)}</Button>
                                        :
                                        <Button color='primary' style={{ marginRight: 15 }} onClick={() => {
                                            if (this.props.patient?.twoFactorAuthentication?.[this.props.selectedGroup]?.createAccount && !this.props.patient?.twoFactorAuthentication?.[this.props.selectedGroup]?.infoIsVerified) return;
                                            let section = this.state.section

                                            this.setState({

                                                listOfMedicinesNote: true,

                                            })
                                        }}
                                        disabled={(this.props.patient?.twoFactorAuthentication?.[this.props.selectedGroup]?.createAccount && !this.props.patient?.twoFactorAuthentication?.[this.props.selectedGroup]?.infoIsVerified)}
                                        >{'Edit'.translate(this.props.lang)}</Button>

                                    }

                                </h6>
                                <div className="medicinelist-alert">
                                    <Isvg src={AlertIcon} />
                                    <h6>{'Note that the list of medicines must be correct. The list is shown to the patient!'.translate(this.props.lang)}</h6>
                                </div>


                                <Row>
                                    <Col lg='12'>

                                        <div>
                                            <Collapse isOpen={true} className="no-border">
                                                <Card >
                                                    <div style={{ overflowX: 'scroll' }}>
                                                        <table className={this.state.editTableListOfMedicines ? 'listofmedicines-table' : 'listofmedicines-table listofmedicines-table-noeditable'}>

                                                            <tr>
                                                                <th>{'Start of treatment'.translate(this.props.lang)}</th>
                                                                <th>{'Drug name, drug form, strength'.translate(this.props.lang)}</th>
                                                                <th>{'Time for ingestion, dosage'.translate(this.props.lang)}</th>
                                                                <th>{'Use and purpose'.translate(this.props.lang)}</th>
                                                                <th>{'End of treatment'.translate(this.props.lang)}</th>
                                                                <th>{'Other information'.translate(this.props.lang)}</th>
                                                            </tr>
                                                            <tr>
                                                                <td></td>
                                                                <td></td>
                                                                <td className='medicament-hours'>
                                                                    {this.state.section && this.state.section.hourArray && this.state.section.hourArray.length ? this.state.section.hourArray.map((item, idx) => {

                                                                        return (
                                                                            <td className='medicament-hours-take'>
                                                                                <p className='medicament-hours-take-data'>{item + 'h'}</p>
                                                                                {/* <p>{ item }</p>  */}
                                                                            </td>
                                                                        )
                                                                    }) : null}
                                                                </td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                            </tr>


                                                            {
                                                                this.state.section && this.state.section.items && this.state.section.items.map((element, eidx) => {
                                                                    // console.log(element, eidx)
                                                                    return (
                                                                        <tr ref={node => {
                                                                            if (!this.tableRef) {
                                                                                this.tableRef = {};
                                                                            }
                                                                            if (element.id) {
                                                                                this.tableRef[element.id] = node;
                                                                            }

                                                                        }}>
                                                                            <td onClick={() => {
                                                                                let section = this.state.section;
                                                                                // section.items[eidx].startTreatment = moment(new Date()).format(this.props.dateFormat);
                                                                                this.setState({ focusedField: `${element.id}-startTreatment`, openCalendar: !this.state.openCalendar })
                                                                                // if (typeof window != 'undefined') {
                                                                                //     document.getElementById(`${element.id}-startTreatment`).focus()

                                                                                // }
                                                                            }}
                                                                            // className={!element.startTreatment ? 'required-td' : ''}
                                                                            >

                                                                                {
                                                                                    // this.state.focusedField == `${element.id}-startTreatment` && this.state.editTableListOfMedicines ?
                                                                                    //     <DatePicker disabled={this.state.editTableListOfMedicines ? false : true} id={`${element.id}-startTreatment`} type='text' value={element.startTreatment ? element.startTreatment : moment(new Date()).format(this.props.dateFormat)} onChange={(e) => {
                                                                                    //         let section = this.state.section;
                                                                                    //         if (section && section.items && section.items[eidx]) {
                                                                                    //             section.items[eidx].startTreatment = e;
                                                                                    //             section.items[eidx].username = this.props.uData.username;
                                                                                    //             section.items[eidx].itemModifiedTs = Math.floor(new Date().getTime() / 1000)
                                                                                    //             this.setState({ section })
                                                                                    //         }
                                                                                    //     }} />
                                                                                    //     :
                                                                                    <span id={`${element.id}-startTreatment`}>{element.startTreatment}</span>
                                                                                }
                                                                            </td>
                                                                            <td onClick={() => {

                                                                                this.setState({ focusedField: `${element.id}-drugName` }, () => {
                                                                                    if (typeof window != 'undefined') {
                                                                                        document.getElementById(`${element.id}-drugName`).focus()
                                                                                    }
                                                                                })
                                                                            }}
                                                                            // className={!element.drugName ? 'required-td' : ''}
                                                                            >
                                                                                {
                                                                                    this.state.focusedField == `${element.id}-drugName` && this.state.editTableListOfMedicines ?
                                                                                        <Input placeholder={'Drug name, drug form, strength'.translate(this.props.lang)} style={{ minWidth: '240px' }} disabled={this.state.editTableListOfMedicines ? false : true} id={`${element.id}-drugName`} type='text' value={element.drugName} onChange={(e) => {
                                                                                            let section = this.state.section;
                                                                                            if (section && section.items && section.items[eidx]) {
                                                                                                section.items[eidx].drugName = e.target.value;
                                                                                                section.items[eidx].username = this.props.uData.username;
                                                                                                section.items[eidx].itemModifiedTs = Math.floor(new Date().getTime() / 1000)
                                                                                                this.setState({ section })
                                                                                            }
                                                                                        }} />
                                                                                        :
                                                                                        <span id={`${element.id}-drugName`}>{element.drugName}</span>
                                                                                }
                                                                            </td>
                                                                            <td className='medicament-hours-input'>
                                                                                <td className='medicament-hours-input-row'>
                                                                                    {this.state.section.hourArray && this.state.section.hourArray.length ?

                                                                                        this.state.section && this.state.section.hourArray && this.state.section.hourArray.length ? this.state.section.hourArray.map((item, idx) => {
                                                                                            // console.log(this.state.section.items[0].timeForIngestion[`${item}`], idx)
                                                                                            return (
                                                                                                <td className='medicament-hours-take' onMouseEnter={() => {
                                                                                                    this.setState({
                                                                                                        tooltip: `${eidx}-${idx}`
                                                                                                    })
                                                                                                }} onMouseLeave={() => {
                                                                                                    this.setState({
                                                                                                        tooltip: null
                                                                                                    })
                                                                                                }}>
                                                                                                    <Input className='medicament-hours-take-data' disabled={this.state.editTableListOfMedicines ? false : true} type='text' value={this.state.section && this.state.section.items && this.state.section.items[eidx] && this.state.section.items[eidx].timeForIngestion && this.state.section.items[eidx].timeForIngestion[`${item}`] ? this.state.section.items[eidx].timeForIngestion[`${item}`] : ''} onChange={(e) => {
                                                                                                        let section = this.state.section;
                                                                                                        if (section && section.items && section.items[eidx]) {
                                                                                                            if (!section.items[eidx].timeForIngestion)
                                                                                                                section.items[eidx].timeForIngestion = {}
                                                                                                            section.items[eidx].username = this.props.uData.username;
                                                                                                            section.items[eidx].itemModifiedTs = Math.floor(new Date().getTime() / 1000)

                                                                                                            section.items[eidx].timeForIngestion[`${item}`] = e.target.value;
                                                                                                            this.setState({ section })
                                                                                                        }
                                                                                                    }} />

                                                                                                    {
                                                                                                        this.state.tooltip == `${eidx}-${idx}` && !this.state.editTableListOfMedicines && this.state.section && this.state.section.items && this.state.section.items[eidx] && this.state.section.items[eidx].timeForIngestion && this.state.section.items[eidx].timeForIngestion[`${item}`] ?
                                                                                                            <div className='tooltip-dosage'>{this.state.section && this.state.section.items && this.state.section.items[eidx] && this.state.section.items[eidx].timeForIngestion && this.state.section.items[eidx].timeForIngestion[`${item}`] ? this.state.section.items[eidx].timeForIngestion[`${item}`] : ''}</div>
                                                                                                            :
                                                                                                            null
                                                                                                    }
                                                                                                </td>
                                                                                            )
                                                                                        }) : null



                                                                                        :
                                                                                        <td >

                                                                                        </td>
                                                                                    }
                                                                                </td>
                                                                            </td>
                                                                            <td onClick={() => {
                                                                                this.setState({ focusedField: `${element.id}-purpose` }, () => {
                                                                                    if (typeof window != 'undefined') {
                                                                                        document.getElementById(`${element.id}-purpose`).focus()
                                                                                    }
                                                                                })
                                                                            }}>
                                                                                {
                                                                                    this.state.focusedField == `${element.id}-purpose` && this.state.editTableListOfMedicines ?
                                                                                        <Input placeholder={'Use and purpose'.translate(this.props.lang)} style={{ minWidth: '160px', marginLeft: 10 }} disabled={this.state.editTableListOfMedicines ? false : true} id={`${element.id}-purpose`} type='text' value={element.purpose} onChange={(e) => {
                                                                                            let section = this.state.section;
                                                                                            if (section && section.items && section.items[eidx]) {
                                                                                                section.items[eidx].purpose = e.target.value;
                                                                                                section.items[eidx].username = this.props.uData.username;
                                                                                                section.items[eidx].itemModifiedTs = Math.floor(new Date().getTime() / 1000)
                                                                                                this.setState({ section })
                                                                                            }
                                                                                        }} />
                                                                                        :
                                                                                        <span id={`${element.id}-purpose`}>{element.purpose}</span>
                                                                                }
                                                                            </td>
                                                                            <td onClick={(e) => {
                                                                                // console.log(e?.target?.tagName); 
                                                                                let ELEMENT;
                                                                                if (e?.target?.tagName === 'TD') {
                                                                                    ELEMENT = e?.target;
                                                                                } else if (e?.target?.tagName === 'SPAN') {
                                                                                    ELEMENT = e?.target?.parentNode;
                                                                                }

                                                                                // console.log(ELEMENT?.getBoundingClientRect())
                                                                                let section = this.state.section;
                                                                                // section.items[eidx].endTreatment = moment(new Date()).format(this.props.dateFormat);
                                                                                this.setState({ focusedField: `${element.id}-endTreatment`, openCalendar: !this.state.openCalendar, selectedItemPosition: ELEMENT?.getBoundingClientRect() }, () => {

                                                                                })
                                                                            }}>
                                                                                {
                                                                                    // this.state.focusedField == `${element.id}-endTreatment` && this.state.editTableListOfMedicines ?
                                                                                    //     <DatePicker endDate={this.state.section && this.state.section.items && this.state.section.items[eidx] && this.state.section.items[eidx].startTreatment ? new Date(this.state.section.items[eidx].startTreatment) : new Date()} disabled={this.state.editTableListOfMedicines ? false : true} id={`${element.id}-endTreatment`} type='text' value={element.endTreatment ? element.endTreatment : moment(new Date()).format(this.props.dateFormat)} onChange={(e) => {
                                                                                    //         let section = this.state.section;
                                                                                    //         if (section && section.items && section.items[eidx]) {
                                                                                    //             section.items[eidx].endTreatment = e;
                                                                                    //             section.items[eidx].username = this.props.uData.username;
                                                                                    //             section.items[eidx].itemModifiedTs = Math.floor(new Date().getTime() / 1000)
                                                                                    //             this.setState({ section })
                                                                                    //         }
                                                                                    //     }} />
                                                                                    //     :
                                                                                    <span id={`${element.id}-endTreatment`}>{element.endTreatment}</span>
                                                                                }
                                                                            </td>
                                                                            <td onClick={() => {
                                                                                this.setState({ focusedField: `${element.id}-other` }, () => {
                                                                                    if (typeof window != 'undefined') {
                                                                                        document.getElementById(`${element.id}-other`).focus()
                                                                                    }
                                                                                })
                                                                            }}>
                                                                                {
                                                                                    this.state.focusedField == `${element.id}-other` && this.state.editTableListOfMedicines ?
                                                                                        <Input placeholder={'Other information'.translate(this.props.lang)} style={{ minWidth: '200px' }} disabled={this.state.editTableListOfMedicines ? false : true} id={`${element.id}-other`} type='text' value={element.other} onChange={(e) => {
                                                                                            let section = this.state.section;
                                                                                            if (section && section.items && section.items[eidx]) {
                                                                                                section.items[eidx].other = e.target.value;
                                                                                                section.items[eidx].username = this.props.uData.username;
                                                                                                section.items[eidx].itemModifiedTs = Math.floor(new Date().getTime() / 1000)
                                                                                                this.setState({ section })
                                                                                            }
                                                                                        }} />
                                                                                        :
                                                                                        <div id={`${element.id}-other`}>
                                                                                            <span>{element.other}</span>

                                                                                        </div>

                                                                                }
                                                                                <span>{element.username ? element.username : ''}  {element.itemModifiedTs ? this.props.getStringDateTs(element.itemModifiedTs, this.props.dateFormat)/*moment.unix(element.itemModifiedTs).format(this.props.dateFormat)*/ : this.props.getStringDateTs(Math.floor(new Date().getTime() / 1000), this.props.dateFormat)/*moment(new Date()).format(this.props.dateFormat)*/}</span>
                                                                            </td>
                                                                            {this.state.editTableListOfMedicines ?
                                                                                <td className='delete-row'>

                                                                                    <div className='test-results-tooltip-wrap' onClick={() => {
                                                                                        this.setState({ deleteSectionItem: eidx + 1 })
                                                                                    }} style={{ marginLeft: 5, cursor: 'pointer', position: 'relative' }}><Isvg src={garbageIcon} />
                                                                                        <div className='test-results-tooltip'>{'Delete'.translate(this.props.lang)}</div>

                                                                                    </div>


                                                                                </td>
                                                                                : null}
                                                                        </tr>
                                                                    )

                                                                })

                                                            }
                                                        </table>
                                                    </div>
                                                    {
                                                        this.state.section && this.state.section.items && this.state.section.items.map((element, eidx) => {

                                                            if (this.state.focusedField == `${element.id}-startTreatment` && this.state.editTableListOfMedicines) {
                                                                return (
                                                                    <div className="datepicker-listofmedicines">
                                                                        <DatePicker dateFormat={this.props.dateFormat} listOfMedicinesHide={true} openCalendar={this.state.openCalendar} type='text' value={element.startTreatment ? element.startTreatment : moment(new Date()).format(this.props.dateFormat)} onChange={(e) => {
                                                                            let section = this.state.section;
                                                                            if (section && section.items && section.items[eidx]) {
                                                                                section.items[eidx].startTreatment = e;
                                                                                section.items[eidx].username = this.props.uData.username;
                                                                                section.items[eidx].itemModifiedTs = Math.floor(new Date().getTime() / 1000)
                                                                                this.setState({ section })
                                                                            }
                                                                        }} closeCalendar={() => {
                                                                            this.setState({
                                                                                openCalendar: !this.state.openCalendar
                                                                            })
                                                                        }} />
                                                                    </div>

                                                                )
                                                            }

                                                            if (this.state.focusedField == `${element.id}-endTreatment` && this.state.editTableListOfMedicines) {
                                                                console.log('sssss');
                                                                return (
                                                                    <div /*className="datepicker-listofmedicines datepicker-listofmedicines-enddate"*/ style={{ position: 'fixed', top: (typeof window !== 'undefined' && window.innerWidth >= 766 ? (this.state.selectedItemPosition?.top - (this.state.selectedItemPosition?.height || 40)) : 'unset'), bottom: (typeof window !== 'undefined' && window.innerWidth >= 766 ? 'unset' : this.state.selectedItemPosition?.top), left: (typeof window !== 'undefined' && ((window.innerWidth - this.state.selectedItemPosition?.left) > (window.innerWidth >= 766 ? 332 : 226)) ? this.state.selectedItemPosition?.left : (this.state.selectedItemPosition?.left - (window.innerWidth - this.state.selectedItemPosition?.right > 70 ? (window.innerWidth - this.state.selectedItemPosition?.right) : (window.innerWidth >= 766 ? 200 : 100)))) }}>
                                                                        <DatePicker dateFormat={this.props.dateFormat} endDate={this.state.section && this.state.section.items && this.state.section.items[eidx] && this.state.section.items[eidx].startTreatment ? new Date(this.state.section.items[eidx].startTreatment?.replace(/-/g, '/')) : new Date()} listOfMedicinesHide={true} openCalendar={this.state.openCalendar} type='text' value={element.endTreatment ? element.endTreatment : moment(new Date()).format(this.props.dateFormat)} onChange={(e) => {
                                                                            let section = this.state.section;
                                                                            if (section && section.items && section.items[eidx]) {
                                                                                section.items[eidx].endTreatment = e;
                                                                                section.items[eidx].username = this.props.uData.username;
                                                                                section.items[eidx].itemModifiedTs = Math.floor(new Date().getTime() / 1000)
                                                                                section.items[eidx].endTreatmentTs = Math.floor(new Date(e).getTime() / 1000)
                                                                                this.setState({ section })
                                                                            }
                                                                        }} closeCalendar={() => {
                                                                            this.setState({
                                                                                openCalendar: !this.state.openCalendar
                                                                            })
                                                                        }} />
                                                                    </div>
                                                                )
                                                            }
                                                        })

                                                    }
                                                    {this.state.editTableListOfMedicines ?
                                                        <Button style={{ width: '120px', marginTop: 15 }} color='primary' onClick={() => {
                                                            let section = this.state.section;
                                                            if (section && section.items) {
                                                                section.items.push({
                                                                    id: uuidv4(),
                                                                    startTreatment: moment(new Date()).format(this.props.dateFormat),
                                                                    drugName: '',
                                                                    timeForIngestion: {},
                                                                    purpose: '',
                                                                    endTreatment: moment(new Date()).format(this.props.dateFormat),
                                                                    other: ''
                                                                })
                                                                this.setState({ section })
                                                            } else {
                                                                let section = {
                                                                    ...this.state.section,
                                                                    items: []
                                                                }
                                                                section.items.push({
                                                                    id: uuidv4(),
                                                                    startTreatment: moment(new Date()).format(this.props.dateFormat),
                                                                    drugName: '',
                                                                    timeForIngestion: {},
                                                                    purpose: '',
                                                                    endTreatment: moment(new Date()).format(this.props.dateFormat),
                                                                    other: ''
                                                                })
                                                                this.setState({ section })
                                                            }
                                                        }}>{'Add new line'.translate(this.props.lang)}</Button>

                                                        : null}
                                                </Card>
                                            </Collapse>
                                        </div>
                                    </Col>
                                </Row>
                            </div>

                        </Col>

                    </Row>

                </Container>

                {this.state.listOfMedicinesNote ?
                    <InfoModal
                        isOpen={this.state.listOfMedicinesNote}
                        info={'Note that the list of medicines is displayed to the patient. Make sure that the correct information is entered.'.translate(this.props.lang)}
                        buttons={[

                            <Button color='primary'
                                onClick={() => {
                                    let section = this.state.section
                                    if (!(section && section.items && section.items.length)) {
                                        this.setState({
                                            listOfMedicinesNote: null,
                                            editTableListOfMedicines: true,

                                        })
                                        section.items = [
                                            {
                                                id: uuidv4(),
                                                startTreatment: moment(new Date()).format(this.props.dateFormat),
                                                drugName: '',
                                                timeForIngestion: {},
                                                purpose: '',
                                                endTreatment: moment(new Date()).format(this.props.dateFormat),
                                                other: ''
                                            }
                                        ]
                                        section.hourArray = ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23']


                                    } else {
                                        this.setState({
                                            listOfMedicinesNote: null,
                                            editTableListOfMedicines: true,
                                            section: {
                                                ...section,

                                                hourArray: ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23']
                                            },
                                        })
                                    }


                                }}>{'Continue'.translate(this.props.lang)}</Button>,

                            <Button color='primary'
                                onClick={() => {
                                    this.setState({
                                        listOfMedicinesNote: null,
                                    })

                                }}>{'Cancel'.translate(this.props.lang)}</Button>,


                        ]}
                    />
                    : null}

                {this.state.listOfMedicinesSave ?
                    <InfoModal
                        isOpen={this.state.listOfMedicinesSave}
                        info={'Make sure the list of medicines is correct.'.translate(this.props.lang)}
                        buttons={[

                            <Button color='primary'
                                onClick={() => {
                                    this.insertOrUpdate(this.state.section)

                                    // this.setState({
                                    //     listOfMedicinesNote: null,
                                    //     editTableListOfMedicines: true
                                    // })

                                }}>{'OK'.translate(this.props.lang)}</Button>,

                            <Button color='primary'
                                onClick={() => {
                                    this.setState({
                                        listOfMedicinesNote: null,
                                        listOfMedicinesSave: null
                                    })

                                }}>{'Cancel'.translate(this.props.lang)}</Button>,

                            <Button color='primary'
                                onClick={() => {
                                    this.get()
                                }}>{'Exit without saving'.translate(this.props.lang)}</Button>,


                        ]}
                    />
                    : null}

                <DeleteModal
                    lang={this.props.lang}
                    zIndex={99999999999}
                    isOpen={this.state.deleteSectionItem}
                    toggle={() => this.setState({ deleteSectionItem: null })}
                    handler={() => {
                        let index = this.state.deleteSectionItem - 1;
                        let section = this.state.section;

                        if (section.items[index]) {
                            section.items.splice(index, 1);
                            this.setState({ section, deleteSectionItem: null })
                        }

                    }}
                >
                    {'Are you sure you want to delete row?'.translate(this.props.lang)}
                </DeleteModal>
            </div>
        );
    }
}

export default ListOfMedicines;