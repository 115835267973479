import React from 'react';
import { Player, Controls } from "@lottiefiles/react-lottie-player";
import {
    Modal, ModalHeader, ModalBody, ModalFooter, Button, Input
} from 'reactstrap';
import { API_ENDPOINT } from '../constants';
import warrning from '../assets/svg/warrning.svg'
import info from '../assets/svg/info.svg'
import ErrorModal from './errorModal';
import QRCode from "react-qr-code";
import bankID from '../assets/images/icon-bankid2.png';
import deeplink from './browserDeeplink'
import { getSearchParams } from '../helpers/linkHelper';

class BankIDModal_v5 extends React.Component {

    constructor(props) {
        super(props);
        this.linkTag = React.createRef();
        this.imageQRcode = React.createRef();
        this.state = {
            personalNumber: '',
            error: false,
            qrCodeLink: null,
            personalNumber: '',
            redirect_url: false,
            data: false,
        }
        this.componentUnmounted = false;
    }

    componentDidMount() {

        this.logIn()

        let alias = this.props.alias;
        let mainAlias = this.props.mainAlias;
        if (mainAlias && alias && alias !== 'noGroupAlias') {
            fetch(API_ENDPOINT + '/landing/page', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json'
                },
                body: JSON.stringify({ alias, mainAlias })
            }).then(res => res.json()).then((result) => {
                if (result.error) {
                    this.props[0].history.push('/')
                } else {
                    this.setState({
                        data: result
                    }, () => {
                        if (result.favicon) {
                            let favicon = document.getElementById("favicon");
                            favicon.href = result.favicon;
                        } else if (result.logoChat) {
                            let favicon = document.getElementById("favicon");
                            favicon.href = result.logoChat;

                        } else {
                            favicon.href = '/favicon.ico';
                        }
                    })

                }
            });



        } else if (alias && alias !== 'noGroupAlias') {
            fetch(API_ENDPOINT + '/landing/page', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json'
                },
                body: JSON.stringify({ alias })
            }).then(res => res.json()).then((result) => {
                if (result.error) {
                    this.props[0].history.push('/')
                } else {
                    // console.log(result)
                    this.setState({
                        data: result
                    }, () => {

                        if (result.favicon) {
                            let favicon = document.getElementById("favicon");
                            favicon.href = result.favicon;
                        } else if (result.logoChat) {
                            let favicon = document.getElementById("favicon");
                            favicon.href = result.logoChat;

                        } else {
                            favicon.href = '/favicon.ico';
                        }
                    })

                }
            });
        }

        window.addEventListener('message', (e) => {
            if (this.props.doctorLogin) {
                if (e.data == 'loggedin') {

                    this.props.verifyUser(() => {
                        this.props.registerClinicGroupLogs({
                            type: 'login',
                            loginType: 'bankid'
                        })
                        this.props[0].history.push('/');


                    }, () => {


                        if (this.props.uData && this.props.uData.clinicGroups && this.props.uData.clinicGroups.length) {
                            let arr = [];
                            for (let i = 0; i < this.props.uData.clinicGroups.length; i++) {
                                arr.push(this.props.uData.clinicGroups[i]);
                            }
                            if (this.state.data && arr.filter((item) => item?._id == this.state.data?._id).length == 0) {
                                this.props.changeGroup(arr[0]?._id);
                                localStorage.setItem('groupAlias', arr[0]?.alias);
                            } else if (this.state.data) {
                                this.props.changeGroup(this.state.data?._id);
                            } else {
                                this.props.changeGroup(arr[0]?._id);
                            }
                        } else if (this.state.data) {
                            this.props.changeGroup(this.state.data?._id);
                        }


                    });

                } else if (e.data == 'loggedin_newuser') {


                    this.props.verifyUser(() => {
                        this.props.registerClinicGroupLogs({
                            type: 'login',
                            loginType: 'bankid'
                        })
                        this.props[0].history.push('/');
                        // console.log(this.props.uData)
                    }, () => {
                        if (this.props.uData && this.props.uData.clinicGroups && this.props.uData.clinicGroups.length) {
                            let arr = [];
                            for (let i = 0; i < this.props.uData.clinicGroups.length; i++) {
                                arr.push(this.props.uData.clinicGroups[i]);
                            }
                            if (this.state.data && arr.filter((item) => item?._id == this.state.data?._id).length == 0) {
                                this.props.changeGroup(arr[0]?._id)
                                localStorage.setItem('groupAlias', arr[0]?.alias);
                            } else if (this.state.data) {
                                this.props.changeGroup(this.state.data?._id)
                            } else {
                                this.props.changeGroup(arr[0]?._id)
                            }
                        } else if (this.state.data) {
                            this.props.changeGroup(this.state.data?._id)
                        }




                    });
                }
            } else {
                if (this.componentUnmounted) {
                    return;
                }
                if (e.data == 'loggedin') {

                    localStorage.setItem('loginLandingPage', true);
                    localStorage.setItem('logoutLandingPage', true);
                    if (localStorage.getItem('groupAlias'))
                        localStorage.setItem('loginGroupAlias', localStorage.getItem('groupAlias'));
                    let setVerifyRedirectLink;
                    if (this.props.setVerifyRedirectLink) {
                        setVerifyRedirectLink = this.props.setVerifyRedirectLink
                    }

                    this.props.verifyUser((uData) => {
                        if (this.props.navigateToMyChildren && (this.props?.uData?.userLevel == 2 || (this.props?.uData?.userLevel == 20 && typeof window !== 'undefined' && localStorage?.getItem?.('profile') == 'patient'))) return;
                        if (this.props.openLastLoginLanding && this.props.clinicGroupsItems && this.props.clinicGroupsItems.length) {
                            if (uData && uData.lastVisitedGroups && uData.lastVisitedGroups.length) {
                                let lastVisitedGroups = uData.lastVisitedGroups.reverse();
                                for (let i = 0; i < lastVisitedGroups.length; i++) {
                                    if (this.props.clinicGroupsItems.filter(el => el.alias == lastVisitedGroups[i].alias).length) {
                                        alias = lastVisitedGroups[i].alias;
                                        break;
                                    }
                                }
                                let alias;
                                if (alias) {
                                    this.props.toggle(alias);
                                } else {
                                    this.props.toggle();
                                }
                            } else {
                                this.props.toggle();
                            }
                        } else {
                            this.props.toggle();
                        }

                    }, null, false, () => {
                        if (this.props.navigateToMyChildren && (this.props?.uData?.userLevel == 2 || (this.props?.uData?.userLevel == 20 && typeof window !== 'undefined' && localStorage?.getItem?.('profile') == 'patient'))) {
                            this.props?.[0]?.history?.push?.(
                                '/my-data/account',
                                { navigateToMyChildren: true }
                            );
                        } else {
                            if (setVerifyRedirectLink) setVerifyRedirectLink()
                        }
                    });

                } else if (e.data == 'loggedin_newuser') {
                    localStorage.setItem('loginLandingPage', true);
                    localStorage.setItem('logoutLandingPage', true);
                    if (localStorage.getItem('groupAlias'))
                        localStorage.setItem('loginGroupAlias', localStorage.getItem('groupAlias'));

                    let setVerifyRedirectLink;
                    if (this.props.setVerifyRedirectLink) {
                        setVerifyRedirectLink = this.props.setVerifyRedirectLink
                    }

                    this.props.verifyUser(() => {
                        if (this.props.navigateToMyChildren && (this.props?.uData?.userLevel == 2 || (this.props?.uData?.userLevel == 20 && typeof window !== 'undefined' && localStorage?.getItem?.('profile') == 'patient'))) return;
                        if (this.props.toggle) this.props.toggle();
                    }, null, true, () => {
                        if (this.props.navigateToMyChildren && (this.props?.uData?.userLevel == 2 || (this.props?.uData?.userLevel == 20 && typeof window !== 'undefined' && localStorage?.getItem?.('profile') == 'patient'))) {
                            this.props?.[0]?.history?.push?.(
                                '/my-data/account',
                                { navigateToMyChildren: true }
                            );
                        } else {
                            if (setVerifyRedirectLink) setVerifyRedirectLink()
                        }
                    });
                    this.setState({
                        loggedIn: true
                    })

                }
            }

        }, false)

        let params = getSearchParams(this.props[0].location.search);

        if (params && params.key) {
            let key = params.key
            this.interval = setInterval(() => {
                if (!this.checkBankIdKey) {
                    this.checkBankId(key, (data) => {
                        if (data.token) {
                            this.setState({ loading: null }, () => {
                                clearInterval(this.interval);

                            })
                        }
                    });
                }

            }, 2000);
        }

        // if (params && params.qrCodeLink) {
        //     if (params.redirect_url) {
        //         this.setState({
        //         redirect_url: decodeURIComponent(params.redirect_url),

        //         })
        //     }
        //     this.setState({
        //         qrCodeLink: decodeURIComponent(params.qrCodeLink),
        //     }, () => {


        //         this.props[0].history.push(this.props[0].location.pathname)
        //     })
        // }


    }

    logIn = () => {
        if (this.interval) {
            clearInterval(this.interval)
        }

        this.setState({
            loading: true
        })
        this.generateBankIdAppURL((key, redirect_url) => {

            this.setState({
                loading: false
            }, () => {

                const qrCodeLink = this.state.qrCodeLink;
                const imageElement = document.getElementById('qr-image');
                let t = 0;

                this.interval = setInterval(() => {
                    // QR code doesn't work after repeating 14 times so that is the limit for bankid v5 for now
                    if (t === 14) {
                        clearInterval(this.interval);
                        this.logIn();
                        t = 0;
                    }

                    if (!this.checkBankIdKey) {
                        this.checkBankId(key, (data) => {
                            if (data.token) {
                                this.setState({ loading: null }, () => {
                                    clearInterval(this.interval);

                                })
                            }
                        });
                    }

                    imageElement.setAttribute('src', qrCodeLink + '&t=' + t);
                    t++;
                }, 2000);
            })

        })
        this.setState({
            error: false
        })

    }

    componentWillUnmount() {
        this.componentUnmounted = true;
        if (this.interval) {
            clearInterval(this.interval)
        }
    }

    generateBankIdAppURL = (callback) => {
        fetch(API_ENDPOINT + '/users/bankid/v5/web/start', {
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify({ group: this.props.group, alias: this.props.alias, bundleId: this.props.bundle })
        }).then(res => {
            return res.json()
        }).then((result) => {
            if (result.redirect_url) {
                if (result.qrCodeLink) {
                    this.setState({
                        qrCodeLink: result.qrCodeLink,
                        redirect_url: result.redirect_url
                    }, () => {
                        this.setState({
                            qrCodeLink: null
                        }, () => {
                            this.setState({
                                qrCodeLink: result.qrCodeLink
                            })
                        })
                    })
                }

                callback(result.key, result.redirect_url)
            } else if (result.error) {
                this.setState({
                    errorBankID: result.error?.translate(this.props.lang)
                })
            }

        })
    }

    checkBankId = async (key, callback) => {

        this.checkBankIdKey = true;

        fetch(API_ENDPOINT + '/users/bankid/v5/check', {
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify({ key: key, group: this.props.group, alias: this.props.alias })
        }).then(res => res.json()).then(async (result) => {
            if (this.checkBankIdKey) {
                this.checkBankIdKey = null;
            }
            if (result.sessionId && result.token) {
                if (result.error) {
                    this.setState({
                        error: result.error
                    })
                } else {
                    try {
                        if (result?.userData && typeof window !== 'undefined') {
                            localStorage?.setItem?.('userData', JSON.stringify(result?.userData));
                        }
                    } catch (err) {
                        console.error('login user data err: ', err);
                    }
                    if (this.props.doctorLogin) {
                        localStorage.setItem('authToken', result.token);
                        localStorage.setItem('time', Math.floor(Date.now() / 1000))
                        localStorage.setItem('profile', 'doctor');
                        localStorage.setItem('userLevel', 20);
                        localStorage.setItem('logoutLandingPage', true);
                        this.props.registerLog('Logged in');
                        this.props.verifyUser(() => {
                            this.props.registerClinicGroupLogs({
                                type: 'login',
                                loginType: 'username'
                            })
                            if (this.props.uData && this.props.uData.clinicGroups && this.props.uData.clinicGroups.length) {
                                let arr = [];
                                for (let i = 0; i < this.props.uData.clinicGroups.length; i++) {
                                    arr.push(this.props.uData.clinicGroups[i]);
                                }
                                localStorage.setItem('groupAlias', this.props.alias);
                            } else {
                                localStorage.setItem('groupAlias', this.props.alias);
                            }
                            if (this.props.uData && this.props.uData.userLevel) {
                                localStorage.setItem('userLevel', this.props.uData.userLevel);
                            }
                            localStorage.setItem('loginGroupAlias', this.props.alias);
                            this.props[0].history.push(`/`);
                        }, () => {
                            if (localStorage.getItem('loginGroupAlias') == localStorage.getItem('groupAlias') && this.state.data && localStorage.getItem('groupAlias') !== 'noGroupAlias') {
                                this.props.changeGroup(this.state.data?._id);
                            } else {
                                let arr = [];
                                if (this.props?.uData?.clinicGroups?.length) {
                                    for (let i = 0; i < this.props?.uData?.clinicGroups?.length; i++) {
                                        arr.push(this.props.uData.clinicGroups[i]);
                                    }
                                }
                                if (arr && arr.length > 0 && arr[0]) this.props.changeGroup(arr[0]?._id);
                            }

                        });
                    } else {
                        if (window.top) {
                            if (result.newUser) {
                                window.top.postMessage('loggedin_newuser', '*')

                            } else {
                                window.top.postMessage('loggedin', '*')
                            }
                            if (this.interval) {
                                clearInterval(this.interval)
                            }
                        }

                        if (result.sessionId) {
                            localStorage.setItem('sessionId', result.sessionId)
                        }

                        localStorage.setItem('authToken', result.token);
                        localStorage.setItem('time', Math.floor(Date.now() / 1000))
                        localStorage.setItem('profile', 'doctor');
                        if (localStorage.getItem('groupAlias')) {
                            localStorage.setItem('logoutLandingPage', true);
                            localStorage.setItem('loginGroupAlias', localStorage.getItem('groupAlias'));
                        } else if (this.props.alias) {
                            localStorage.setItem('groupAlias', this.props.alias)
                            localStorage.setItem('logoutLandingPage', true);
                            localStorage.setItem('loginGroupAlias', this.props.alias);
                        }
                        if (!this.props.toggle) {
                            if (result.message) {
                                this.props[0].history.push({
                                    pathname: '/my-data/account',
                                    state: { bankIdError: result.message }
                                })
                            } else {
                                window.location.href = "/"
                            }
                        }
                    }




                }
            }

            callback(result)
        }).catch((e) => {
            if (this.checkBankIdKey) {
                this.checkBankIdKey = null;
            }
        });
    }

    render() {
        let params = getSearchParams(this.props[0].location.search);
        return (

            <div className='bank-id-checkout'>
                <h3 >{'Legitimering med BankID'.translate(this.props.lang)}</h3>
                {
                    (this.state.loading) ?

                        <div className="loader-wrap">
                            <Player
                                autoplay={true}
                                loop={true}
                                src="/lf30_editor_l5cxzdyf.json"
                                style={{
                                    height:
                                        typeof window != "undefined" &&
                                            window.innerWidth < 768
                                            ? "128px"
                                            : "256px",
                                    width:
                                        typeof window != "undefined" &&
                                            window.innerWidth < 768
                                            ? "128px"
                                            : "256px",
                                }}
                            ></Player>
                        </div>
                        :
                        /*this.state.redirect_url */ this.state.qrCodeLink ?
                            <>
                                {/* <div  >
                                    <QRCode
                                        size={256}
                                        style={{ height: "auto", maxWidth: 200, width: 200 }}
                                        value={this.state.redirect_url.replace(/.*text=/, '')}
                                        viewBox={`0 0 256 256`}
                                    />

                                </div> */}
                                <img id='qr-image' src={this.state.qrCodeLink} style={{ height: 'auto', maxWidth: 200, width: 200 }} />

                                <p>{"Scan QR code if bankid app doesn't shows up".translate(this.props.lang)}</p>
                                <a href={this.state.redirect_url} className='btn btn-primary'>{"Open BankID on this device".translate(this.props.lang)}</a>
                            </>

                            :

                            // this.state.qrCodeLink ?
                            //     <>
                            //         <img src={this.state.qrCodeLink} />
                            //     </>

                            //     :
                            null
                }

                {this.state.errorBankID ?

                    <ErrorModal lang={this.props.lang}
                        isOpen={this.state.errorBankID}
                        toggle={() => this.setState({ errorBankID: null })}

                    >
                        {this.state.errorBankID.translate(this.props.lang)}
                    </ErrorModal>

                    :
                    null
                }
            </div>
        )
    }
}

export default BankIDModal_v5;
