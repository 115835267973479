import React, { Component } from 'react';
import Page from '../../containers/page';

import {
    Container,
    Row,
    Col,
} from 'reactstrap';

import Isvg from 'react-inlinesvg'

import FormBuilder from '../../components/forms/modalFormBuilder';
import { required } from '../../components/forms/validation';

import ListBuilder from '../../components/listBuilder';
import DeleteModal from '../../components/deleteModal';
import { getSearchParams, generateSearchLink, generateSearchLinkMultiple } from '../../helpers/linkHelper';
import { API_ENDPOINT } from '../../constants';

import Search from '../../components/search';

import profileUser from '../../assets/images/user.png';
import notification from '../../assets/svg/notification.svg'
import moreIcon from '../../assets/svg/more.svg'

/**
* Waiting time log page
* @author   Milan Stanojevic
*/
class WaitingTimeLog extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props.initialData,
            form: false,
            useState: false,
            params: {
                entries: 10,
                page: 0
            },
            items: [],
            total: 0,
            loading: true
        };
    }


    get = () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));

        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0 })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

        for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
            this.props.loadDataWithQuery[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0 })).then((data) => {
                let dataToSet = { ...data }
                if (dataToSet.page || dataToSet.items || dataToSet.total) {
                    dataToSet.loading = null;
                    // console.log(dataToSet.page || dataToSet.items || dataToSet.total)
                    let checkFilter = '';
                    if (dataToSet.resultFilter) {
                        checkFilter = String(dataToSet.resultFilter)
                        delete dataToSet.resultFilter;
                    }
                    let search = this.props[0].location.search;
                    let serachParams = getSearchParams(search);
                    let searchParamsFilter = '';
                    if (serachParams.filter) {
                        searchParamsFilter = serachParams.filter;
                    }
                    if (searchParamsFilter != checkFilter) {
                        delete dataToSet.page;
                        delete dataToSet.items;
                        delete dataToSet.total;
                        delete dataToSet.loading;
                    }
                }
                this.setState({
                    ...dataToSet,
                    // loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

    }

    componentDidMount() {
        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }

        this.get();
    }

    componentDidUpdate(prevProps) {
        if (prevProps[0].location.search != this.props[0].location.search) {
            this.setState({
                loading: true
            }, () => {
                this.get();

            })
        }
    }


    updateSort = (field, type) => {
        if (this.state.useState) {
            let state = this.state.params;
            state.sortField = field;
            state.sortType = type;
            this.setState({
                params: state
            })
        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0 }, [{ name: 'sortField', value: field }, { name: 'sortType', value: type }], false));

        }
    }

    updateParams = (name, value, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };
                obj[name] = value;
                this.setState({ params: obj });
            } else {
                let obj = this.state.params;
                obj[name] = value;
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(generateSearchLink(this.props[0].location.search, { entries: 10, page: 0 }, name, value, restart));
        }
    }
    updateMultipleParams = (fields, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj });

            } else {
                let obj = this.state.params;
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0 }, fields, restart));
        }
    }



    insertOrUpdate = (data) => {
        this.setState({
            loading: true
        }, () => {
            if (!this.state.form._id) {

                fetch(API_ENDPOINT + '/data/groups/new', {
                    method: 'PUT',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify(data)
                }).then(res => res.json()).then((result) => {
                    this.get();
                    this.setState({
                        form: null
                    })
                })
            } else {
                fetch(API_ENDPOINT + '/data/groups/' + this.state.form._id, {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify(data)
                }).then(res => res.json()).then((result) => {
                    this.get();
                    this.setState({
                        form: null
                    })
                })
            }
        })
    }

    delete = (id) => {
        this.setState({
            loading: true
        }, () => {

            fetch(API_ENDPOINT + '/data/groups/' + id, {
                method: 'DELETE',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
            }).then(res => res.json()).then((result) => {
                this.get();
            })
        })

    }

    waitingTimePrint(min, max) {
        if (min === '' && max === '') {
            return 'No data'.translate(this.props.lang);
        } else if (min == '0' && max == '0') {
            return '0' + ' ' + 'weeks'.translate(this.props.lang);
        } else if (min > 0 && max == '0') {
            return min + ' ' + 'weeks minimum'.translate(this.props.lang);
        } else if (min == '0' && max > 0) {
            return max + ' ' + 'weeks maximum'.translate(this.props.lang);
        } else if (min == max) {
            return max + ' ' + 'weeks'.translate(this.props.lang);
        } else return min + ' ' + 'to'.translate(this.props.lang) + ' ' + max + ' ' + 'weeks'.translate(this.props.lang);
    }



    render() {
        let params = {};
        if (this.state.useState) {
            params = this.state.params;
        } else {
            params = getSearchParams(this.props[0].location.search, { entries: 10, page: 0 })
        }
        return (
            <div className={this.props.autoHideSidebar ? "dashboard dashboard-auto-hide" : "dashboard"}>

                <Container fluid>
                    <Row>
                        <Col lg="12">

                            <div className="panel">
                                <div className="panel-header">
                                    <h4>{this.props.linksMeta && this.props.linksMeta[this.props[0].location.pathname] ?this.props.linksMeta[this.props[0].location.pathname][this.props.lang] : '' }</h4>
                                </div>
                                <ListBuilder
                                    lang={this.props.lang}
                                    loading={this.state.loading}
                                    total={this.state.total}
                                    showNumeration={false}
                                    fields={[
                                        { type: 'text', name: 'unitName', label: 'Clinic'.translate(this.props.lang), allowSort: true },
                                        { type: 'text', name: 'regionName', label: 'Region'.translate(this.props.lang), allowSort: true },
                                        { type: 'text', name: 'subgroupName', label: 'Subgroup'.translate(this.props.lang), allowSort: true },
                                        { type: 'text', name: 'waitingMin', label: 'Waiting time'.translate(this.props.lang), allowSort: true },
                                        { type: 'text', name: 'checked', label: 'Checked'.translate(this.props.lang), allowSort: true },

                                    ]}
                                    items={this.state.items.map((item, idx) => {
                                        return {
                                            ...item,
                                            checked: item.checked ?  <span style={{backgroundColor: '#03c500', padding: 5, color: '#fff', borderRadius: 10}}>True</span> : '',
                                            waitingMin: this.waitingTimePrint(item.waitingMin, item.waitingMax),

                                        }
                                    })}

                                    rawItems={this.state.items}
                                    onClick={(item) => {
                                        
                                        fetch(API_ENDPOINT + '/data/notFoundClinics/' + item._id, {
                                            method: 'POST',
                                            headers: {
                                                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                                                'content-type': 'application/json'
                                            },
                                            body: JSON.stringify({
                                                checked: !item.checked
                                            })
                                        }).then(res => res.json()).then((result) => {
                                            this.get();
                                            
                                        })
                        
                                    }}
                                    params={params}
                                    sortField={params.sortField}
                                    sortType={params.sortType}
                                    updateSort={this.updateSort}
                                    updateParams={this.updateParams}
                                    updateMultipleParams={this.updateMultipleParams}
                                ></ListBuilder>

                            </div>
                        </Col>
                    </Row>

                </Container>


            </div>
        );
    }
}

export default Page(WaitingTimeLog);