import React, { Component } from 'react';
import { GOOGLE_MAPS_API_KEY } from '../constants';

/**
* Showing map with specific coords
* @author   Milan Stanojevic
*/
export class Map extends Component {
    constructor(props) {
        super(props);
        this.initMap = this.initMap.bind(this);
        this.gmapCallback = this.gmapCallback.bind(this);
        if (typeof window !== 'undefined')
            window.googleMapsCallback = this.gmapCallback;

        this.state = {

        };
    }

    gmapCallback() {
        this.setState({
            _googleMapsLoaded: true
        })
    }



    initMap() {
        this.setState({
            _mapInit: true
        });


        var latLng = new window.google.maps.LatLng(this.props.value.split(',')[0], this.props.value.split(',')[1]);
        var map = new window.google.maps.Map(this.GoogleMap, {
            zoom: 16,
            center: latLng,
            mapTypeId: window.google.maps.MapTypeId.ROADMAP,
            disableDefaultUI: true,
            gestureHandling: "greedy",
        });

        var marker = new window.google.maps.Marker({
            position: new window.google.maps.LatLng(this.props.value.split(',')[0], this.props.value.split(',')[1]),
            map: map,
        });



        this.setState({ googleMap: map });
    }

    componentDidMount() {

        var ref = window.document.getElementsByTagName("script")[0];
        var script = window.document.createElement("script");
        script.src = `https://maps.googleapis.com/maps/api/js?sensor=false&key=${GOOGLE_MAPS_API_KEY}&callback=googleMapsCallback&language=se`;
        script.async = true;
        script.defer = true;

        ref.parentNode.insertBefore(script, ref);


        if (this.state._googleMapsLoaded && !this.state._mapInit) {
            this.initMap();
        }

    }

    componentDidUpdate(prevProps) {

        if (this.state._googleMapsLoaded && !this.state._mapInit) {
            this.initMap();
        }

        if (this.state._googleMapsLoaded && prevProps.value != this.props.value) {
            var latLng = new window.google.maps.LatLng(this.props.value.split(',')[0], this.props.value.split(',')[1]);
            this.state.googleMap.setCenter(latLng);
        }

    }


    render() {
        return (
            <div className="map-wrap">{
                this.state._googleMapsLoaded ?
                    <div className="map" ref={(input) => { this.GoogleMap = input; }}>

                    </div>
                    : null}
            </div>
        )
    }
}

export default Map;