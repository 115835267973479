import React, { Component } from 'react';
import Page from '../../containers/page';

import {
    Container,
    Row,
    Col,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    Input,
    FormGroup,
    Label
} from 'reactstrap';

import Isvg from 'react-inlinesvg'

import FormBuilder from '../../components/forms/modalFormBuilder';
import { required } from '../../components/forms/validation';
import addIcon from '../../assets/svg/add-icon.svg';
import Search from '../../components/search';
import ErrorModal from '../../components/errorModal';

import profileUser from '../../assets/images/user.png';
import notification from '../../assets/svg/notification.svg';
import moreIcon from '../../assets/svg/more.svg';
import edit from '../../assets/svg/edit.svg'
import garabage from '../../assets/svg/garbage.svg'
import file from '../../assets/svg/fajl.svg'
import importIcon from '../../assets/svg/import.svg'

import ListBuilder from '../../components/listBuilder';
import DeleteModal from '../../components/deleteModal';
import { getSearchParams, generateSearchLink, generateSearchLinkMultiple } from '../../helpers/linkHelper';
import { API_ENDPOINT, DOMAINS } from '../../constants';
import { formValueSelector, change } from 'redux-form';  // ES6
import { connect } from 'react-redux';
import SelectReferral from '../../components/selectReferral';
import SelectEReferral from '../../components/selectEReferral';
import SelectReferralForTransfer from '../../components/selectReferralForTransfer'

/**
* Personal referrals page
* @author   Milan Stanojevic
*/
class ReferralsList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props.initialData,
            form: false,
            useState: false,
            params: {
                entries: 10,
                page: 0
            },
            items: [],
            groups: [],
            subgroups: [],
            questionaries: {},
            total: 0,
            loading: true
        };
    }


    get = () => {
        this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));

        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0, group: this.props.selectedGroup, clinic: this.props.selectedClinic })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

        for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
            this.props.loadDataWithQuery[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0, group: this.props.selectedGroup, clinic: this.props.selectedClinic })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

    }

    componentDidMount() {
        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }

        this.get();
    }

    componentDidUpdate(prevProps) {
        if (prevProps[0].location.search != this.props[0].location.search) {
            this.setState({
                loading: true
            }, () => {
                this.get();

            })
        }

        if (prevProps.selectedGroup != this.props.selectedGroup) {
            this.get();
        }

        if (prevProps.selectedClinic != this.props.selectedClinic) {
            this.get();
        }

    }


    updateSort = (field, type) => {
        if (this.state.useState) {
            let state = this.state.params;
            state.sortField = field;
            state.sortType = type;
            this.setState({
                params: state
            })
        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0, group: this.props.selectedGroup, clinic: this.props.selectedClinic }, [{ name: 'sortField', value: field }, { name: 'sortType', value: type }], false));

        }
    }

    updateParams = (name, value, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };
                obj[name] = value;
                this.setState({ params: obj });
            } else {
                let obj = this.state.params;
                obj[name] = value;
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(generateSearchLink(this.props[0].location.search, { entries: 10, page: 0, group: this.props.selectedGroup, clinic: this.props.selectedClinic }, name, value, restart));
        }
    }
    updateMultipleParams = (fields, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj });

            } else {
                let obj = this.state.params;
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0, group: this.props.selectedGroup, clinic: this.props.selectedClinic }, fields, restart));
        }
    }



    /**
    * Update personal questionary
    * @author   Milan Stanojevic
    * @Objectparam    {Object} data     form data
    * @Objectparam    {String} id       personal questionary id
    */
    update = (data) => {

        delete data.subgroupName;
        delete data.groupName;
        delete data.additionalSubgroupsObjects;

        this.setState({
            loading: true
        }, () => {
            fetch(API_ENDPOINT + '/data/personal-questionary/' + data._id, {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify(data)
            }).then(res => res.json()).then((result) => {
                this.get();
                this.setState({
                    form: null
                })
            })

        })
    }

    /**
    * Delete personal questionary
    * @author   Milan Stanojevic
    * @Objectparam    {String} id       personal questionary id
    */
    delete = (id) => {
        this.setState({
            loading: true
        }, () => {

            fetch(API_ENDPOINT + '/data/personal-questionary/' + id, {
                method: 'DELETE',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
            }).then(res => res.json()).then((result) => {
                this.get();
            })
        })

    }

    transferQuestionaries = (data) => {

        if (this.state.domen && this.state.domen != -1) {
            data.domen = this.state.domen
            this.setState({
                loading: true
            }, () => {

                fetch(API_ENDPOINT + '/domen-transfer/questionary', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify(data)
                }).then(res => res.json()).then((result) => {
                    if (result) {
                        this.setState({
                            transferModal: false,
                            questionaries: null,
                            domen: null
                        })
                    }
                    this.get();
                })
            })
        } else {
            this.setState({
                error: 'You have to select domen'.translate(this.props.lang)
            })
        }

    }

    render() {
        let params = {};
        if (this.state.useState) {
            params = this.state.params;
        } else {
            params = getSearchParams(this.props[0].location.search, { entries: 10, page: 0, group: this.props.selectedGroup, clinic: this.props.selectedClinic })
        }


        let subgroups = [{ name: 'None', value: null }];

        if (this.props.group) {
            for (let i = 0; i < this.state.subgroups.length; i++) {
                if (this.state.subgroups[i].groups && this.state.subgroups[i].groups.indexOf(this.props.group) !== -1) {
                    subgroups.push(this.state.subgroups[i]);
                }
            }
        }

        return (
            <div className={this.props.autoHideSidebar ? "dashboard dashboard-auto-hide" : "dashboard"}>

                <Container fluid>
                    <Row>
                        <Col lg="12">
                            <div className="panel">
                                <div className="panel-header header-two-buttons">
                                    <h5 className="component-header">
                                        {this.props.linksMeta && this.props.linksMeta[this.props[0].location.pathname] ? this.props.linksMeta[this.props[0].location.pathname][this.props.lang] : ''}
                                    </h5>
                                    <div className="header-button-group">
                                        <button className="btn add-button" onClick={() => {
                                            this.props[0].history.push('/personal-referrals/new')
                                        }}><Isvg src={addIcon} /> {'Add'.translate(this.props.lang)}</button>

                                        <button className="btn add-button" style={{ marginRight: 15 }} onClick={() => {
                                            this.setState({
                                                transferModal: true,
                                            })
                                        }}><Isvg src={importIcon} /> {'Transfer questionary'.translate(this.props.lang)}</button>

                                        <button className="btn add-button" onClick={() => {
                                            this.setState({
                                                selectReferralModal: true
                                            })
                                        }}><Isvg src={importIcon} /> {'Import from clinic group'.translate(this.props.lang)}</button>
                                        <button className="btn add-button" onClick={() => {
                                            this.setState({
                                                selecteReferralModal: true
                                            })
                                        }}><Isvg src={importIcon} /> {'Import E-referral'.translate(this.props.lang)}</button>
                                    </div>
                                </div>
                                <ListBuilder
                                    lang={this.props.lang}
                                    loading={this.state.loading}
                                    total={this.state.total}
                                    showNumeration={false}
                                    fields={[
                                        { type: 'text', name: 'name', label: 'Name'.translate(this.props.lang), allowSort: true },
                                    ]}
                                    items={this.state.items}
                                    actions={
                                        [
                                            {
                                                component: <div className="tabel-action-tooltip"><Isvg src={edit} style={{ width: 24, minWidth: 24 }} /><div style={{ width: 180 }} className="chat-icons-tooltip">{'Edit'.translate(this.props.lang)}</div> </div>,
                                                onClick: (item) => this.props[0].history.push('/personal-referrals/' + item._id)
                                            },
                                            {
                                                component: <div className="tabel-action-tooltip"><Isvg src={garabage} style={{ width: 24, minWidth: 24 }} /><div style={{ width: 180 }} className="chat-icons-tooltip">{'Delete'.translate(this.props.lang)}</div> </div>,
                                                onClick: (item) => this.setState({ deleteModal: item })
                                            },
                                        ]
                                    }
                                    params={params}
                                    sortField={params.sortField}
                                    sortType={params.sortType}
                                    updateSort={this.updateSort}
                                    updateParams={this.updateParams}
                                    updateMultipleParams={this.updateMultipleParams}

                                ></ListBuilder>
                                <DeleteModal
                                    lang={this.props.lang}
                                    isOpen={this.state.deleteModal}
                                    toggle={() => this.setState({ deleteModal: null })}
                                    handler={() => { this.delete(this.state.deleteModal._id); this.setState({ deleteModal: null }) }}
                                >
                                    {'Delete referral'.translate(this.props.lang)} <strong>{this.state.deleteModal && this.state.deleteModal.name}</strong>?
                                </DeleteModal>

                            </div>
                        </Col>
                    </Row>
                </Container>

                <Modal isOpen={this.state.selectReferralModal} centered size="xl">
                    <ModalHeader toggle={() => this.setState({ selectReferralModal: !this.state.selectReferralModal })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ selectReferralModal: !this.state.selectReferralModal })}>&times;</button>}>{'Select referral'.translate(this.props.lang)}</ModalHeader>

                    <ModalBody>
                        <SelectReferral {...this.props}></SelectReferral>
                    </ModalBody>

                </Modal>

                <Modal isOpen={this.state.selecteReferralModal} centered size="xl">
                    <ModalHeader toggle={() => this.setState({ selecteReferralModal: !this.state.selecteReferralModal })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ selecteReferralModal: !this.state.selecteReferralModal })}>&times;</button>}>{'Select referral'.translate(this.props.lang)}</ModalHeader>

                    <ModalBody>
                        <SelectEReferral {...this.props}></SelectEReferral>
                    </ModalBody>

                </Modal>

                {this.state.error ?

                    <ErrorModal lang={this.props.lang}
                        isOpen={this.state.error}
                        toggle={() => this.setState({ error: null })}

                    >
                        {this.state.error.translate(this.props.lang)}
                    </ErrorModal>

                    : null}

                <Modal isOpen={this.state.transferModal} centered size="xl">
                    <ModalHeader toggle={() => this.setState({ transferModal: !this.state.transferModal, questionaries: null, domen: null })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ transferModal: !this.state.transferModal, questionaries: null, domen: null })}>&times;</button>}>{'Select referrals for transfer'.translate(this.props.lang)}</ModalHeader>

                    <ModalBody>
                        <FormGroup>
                            <Label>{'Select domen'.translate(this.props.lang)}</Label>
                            <Input type='select'
                                onChange={e => {

                                    this.setState({ domen: e.target.value == -1 ? null : e.target.value, }, () => { })
                                }}
                                value={this.state.domen}
                            >
                                <option value={-1}>
                                    {'None'.translate(this.props.lang)}
                                </option>
                                {
                                    DOMAINS?.filter(item => item.country === this.props.country)?.map((item, idx) => {
                                        if (window.location.origin !== item.domen)
                                            return (
                                                <option value={item.api}>
                                                    {item.name}
                                                </option>
                                            )
                                    })
                                }
                                {/* {
                                    this.props.country === 'denmark' ?
                                        <>
                                            {window.location.origin != 'https://test.curoflow.dk' ?
                                                <option value={'https://testapi.curoflow.dk'}>
                                                    {'TEST'.translate(this.props.lang)}
                                                </option>
                                                : null}
                                            {window.location.origin != 'https://tryme.curoflow.dk' ?
                                                <option value={'https://tryme-api.curoflow.dk'}>
                                                    {'TRY ME'.translate(this.props.lang)}
                                                </option>
                                                : null}
                                            {window.location.origin != 'https://dashboard.curoflow.dk' ?
                                                <option value={'https://dashboardproxy.curoflow.dk'}>
                                                    {'DASHBOARD'.translate(this.props.lang)}
                                                </option>
                                                : null}
                                        </>
                                        :
                                        <>
                                            {window.location.origin != 'https://dev.curoflow.se' ?
                                                <option value={'https://devapi.curoflow.se'}>
                                                    {'DEV'.translate(this.props.lang)}
                                                </option>
                                                : null}
                                            {window.location.origin != 'https://tryme.curoflow.se' ?
                                                <option value={'https://tryme-api.curoflow.se'}>
                                                    {'TRY ME'.translate(this.props.lang)}
                                                </option>
                                                : null}
                                            {window.location.origin != 'https://test.curoflow.se' ?
                                                <option value={'https://testapi.curoflow.se'}>
                                                    {'TEST'.translate(this.props.lang)}
                                                </option>
                                                : null}
                                            {window.location.origin != 'https://dashboard.curoflow.se' ?
                                                <option value={'https://dashboardapi.curoflow.se'}>
                                                    {'DASHBOARD'.translate(this.props.lang)}
                                                </option>
                                                : null}
                                            {window.location.origin != 'https://sandbox.curoflow.se' ?
                                                <option value={'https://sandboxapi.curoflow.se'}>
                                                    {'SANDBOX'.translate(this.props.lang)}
                                                </option>
                                                : null}
                                        </>
                                } */}
                            </Input>
                        </FormGroup>

                        <SelectReferralForTransfer {...this.props}
                            value={this.state.questionaries}
                            onChange={(val) => {
                                if (val) {
                                    let questionaries = this.state.questionaries;
                                    questionaries = val;
                                    this.setState({ questionaries });
                                }
                            }}
                        ></SelectReferralForTransfer>

                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={() => this.transferQuestionaries(this.state.questionaries)}>{'Transfer form'.translate(this.props.lang)}</Button>
                    </ModalFooter>

                </Modal>
            </div>
        );
    }
}

const selector = formValueSelector('modalForm');

export default connect(state => {
    return { group: selector(state, 'group') }

}, {
    changeSubgroup: value => change("modalForm", "subgroup", value),

})(Page(ReferralsList));