import React, { Component } from 'react';
import Page from '../../containers/page';

import {
    Container,
    Row,
    Col,
    Modal, ModalHeader, ModalBody, ModalFooter, Button
} from 'reactstrap';


import FormBuilder from '../../components/forms/modalFormBuilder';
import { required } from '../../components/forms/validation';

import ListBuilder from '../../components/listBuilder';
import DeleteModal from '../../components/deleteModal';
import { getSearchParams, generateSearchLink, generateSearchLinkMultiple } from '../../helpers/linkHelper';
import { API_ENDPOINT } from '../../constants';
import { formValueSelector, change } from 'redux-form';  // ES6
import { connect } from 'react-redux';
import ReferralClinics from '../../components/referralClinics';
import moment from 'moment';
import PrintHelper from '../../components/printHelper';
import ErrorModal from '../../components/errorModal';

class Referrals extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props.initialData,
            form: false,
            useState: false,
            params: {
                entries: 10,
                page: 0
            },
            items: [],
            groups: [],
            subgroups: [],
            total: 0,
            loading: true
        };
    }


    get = () => {
        this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));

        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0, sortField: 'timestamp', sortType: -1 })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

        for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
            this.props.loadDataWithQuery[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0, sortField: 'timestamp', sortType: -1 })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

    }

    componentDidMount() {
        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }

        this.get();


        this.interval = setInterval(() => {
            this.get();
        }, 60 * 1000);

    }
    componentWillUnmount() {
        if (this.interval) {
            clearInterval(this.interval)
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps[0].location.search != this.props[0].location.search) {
            this.setState({
                loading: true
            }, () => {
                this.get();

            })
        }
    }


    updateSort = (field, type) => {
        if (this.state.useState) {
            let state = this.state.params;
            state.sortField = field;
            state.sortType = type;
            this.setState({
                params: state
            })
        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0, sortField: 'timestamp', sortType: -1 }, [{ name: 'sortField', value: field }, { name: 'sortType', value: type }], false));

        }
    }

    updateParams = (name, value, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };
                obj[name] = value;
                this.setState({ params: obj });
            } else {
                let obj = this.state.params;
                obj[name] = value;
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(generateSearchLink(this.props[0].location.search, { entries: 10, page: 0, sortField: 'timestamp', sortType: -1 }, name, value, restart));
        }
    }
    updateMultipleParams = (fields, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj });

            } else {
                let obj = this.state.params;
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0, sortField: 'timestamp', sortType: -1 }, fields, restart));
        }
    }



    insertOrUpdate = (data) => {

        delete data.subgroupName;
        delete data.groupName;
        delete data.additionalSubgroupsObjects;

        this.setState({
            loading: true
        }, () => {
            if (!this.state.form._id) {

                fetch(API_ENDPOINT + '/data/articles/new', {
                    method: 'PUT',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify(data)
                }).then(res => res.json()).then((result) => {
                    this.get();
                    this.setState({
                        form: null
                    })
                })
            } else {
                fetch(API_ENDPOINT + '/data/articles/' + this.state.form._id, {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify(data)
                }).then(res => res.json()).then((result) => {
                    this.get();
                    this.setState({
                        form: null
                    })
                })
            }
        })
    }


    update = (id) => {

        this.setState({
            loading: true
        }, () => {
            fetch(API_ENDPOINT + `/referrals/seen/${id}`, {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },

            }).then(res => res.json()).then((result) => {
                this.setState({
                    loading: null
                })
            })

        })
    }



    delete = (id) => {
        this.setState({
            loading: true
        }, () => {

            fetch(API_ENDPOINT + `/referrals/recipient/${id}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
            }).then(res => res.json()).then((result) => {
                this.get();
            })
        })

    }


    acceptReferral = () => {
        this.setState({
            loading: true
        }, () => {
            fetch(API_ENDPOINT + '/referrals/patient/accept/' + this.state.referral._id, {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify({})
            }).then(res => res.json()).then((result) => {
                this.get();
                this.setState({
                    referral: null,
                    rejectForm: false,
                    requestAnotherDateForm: false
                })
            })
        })
    }
    rejectReferral = (data) => {
        this.setState({
            loading: true
        }, () => {
            fetch(API_ENDPOINT + '/referrals/patient/reject/' + this.state.referral._id, {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify(data)
            }).then(res => res.json()).then((result) => {
                this.get();
                if (result?.error) {
                    this.setState({
                        error: result.error?.translate(this.props.lang),
                        loading: false
                    })
                } else {
                    this.setState({
                        referral: null,
                        rejectForm: false,
                        requestAnotherDateForm: false
                    })
                }

            })
        })
    }
    requestAnotherDate = (data) => {
        this.setState({
            loading: true
        }, () => {
            fetch(API_ENDPOINT + '/referrals/patient/pick-another-time/' + this.state.referral._id, {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify({ date: data.date + ' ' + data.time })
            }).then(res => res.json()).then((result) => {
                this.get();
                this.setState({
                    referral: null,
                    rejectForm: false,
                    requestAnotherDateForm: false
                })
            })
        })
    }




    render() {
        let params = {};
        if (this.state.useState) {
            params = this.state.params;
        } else {
            params = getSearchParams(this.props[0].location.search, { entries: 10, page: 0, sortField: 'timestamp', sortType: -1 })
        }


        let subgroups = [{ name: 'None', value: null }];

        if (this.props.group) {
            for (let i = 0; i < this.state.subgroups.length; i++) {
                if (this.state.subgroups[i].groups && this.state.subgroups[i].groups.indexOf(this.props.group) !== -1) {
                    subgroups.push(this.state.subgroups[i]);
                }
            }
        }

        const statusColors = {
            'waiting-for-approval': 'orange',
            'disallowed': 'red',
            'allowed': 'green',
            'return-for-update': 'blue',
            'patient-requested-another-time': 'purple',
            'patient-rejected': 'red',
            'patient-accepted': 'green',
            'clinic-accepted-requested-time': 'green',
            'clinic-rejected-requested-time': 'orange',
            'approved-by-region-manager': 'green',
            'rejected-by-region-manager': 'red',
            'scheduled': 'green',
            'visit-rescheduled': 'green',
            'not-booked': 'red',
            'requested-new-appointment': 'orange',
            'external': 'gray',

        }

        const humanReadable = {
            'waiting-for-approval': 'Waiting for approval'.translate(this.props.lang),
            'disallowed': 'Disallowed'.translate(this.props.lang),
            'allowed': 'Allowed'.translate(this.props.lang),
            'return-for-update': 'Return for update'.translate(this.props.lang),
            'patient-requested-another-time': 'Patient requested anoother time'.translate(this.props.lang),
            'patient-rejected': 'Rejected by patient'.translate(this.props.lang),
            'patient-accepted': 'Patient accepted'.translate(this.props.lang),
            'clinic-accepted-requested-time': 'Clinic accepted requested time'.translate(this.props.lang),
            'clinic-rejected-requested-time': 'Clinic rejected requested time'.translate(this.props.lang),
            'approved-by-region-manager': 'Approved by region manager'.translate(this.props.lang),
            'rejected-by-region-manager': 'Rejected by region manager'.translate(this.props.lang),
            'scheduled': 'Scheduled'.translate(this.props.lang),
            'visit-rescheduled': 'Visit rescheduled'.translate(this.props.lang),
            'not-booked': 'Not booked'.translate(this.props.lang),
            'closed': 'Closed'.translate(this.props.lang),
            'requested-new-appointment': 'Requested new appointment'.translate(this.props.lang),
            'external': 'External transfer'.translate(this.props.lang),
        }
        return (
            <div>
                {this.state.error ?

                    <ErrorModal lang={this.props.lang}
                        isOpen={this.state.error}
                        toggle={() => this.setState({ error: null })}

                    >
                        {this.state.error.translate(this.props.lang)}
                    </ErrorModal>

                    :
                    null
                }
                <Container fluid>
                    <Row>
                        <Col lg="12">
                            <div className="panel">
                                <h5 className="component-header">{this.props.linksMeta && this.props.linksMeta[this.props[0].location.pathname] ? this.props.linksMeta[this.props[0].location.pathname][this.props.lang] : ''}</h5>
                                <ListBuilder
                                    lang={this.props.lang}
                                    loading={this.state.loading}
                                    total={this.state.total}
                                    showNumeration={false}
                                    fields={[
                                        { type: 'text', name: 'senderClinicData', label: 'Sender'.translate(this.props.lang), allowSort: true },
                                        { type: 'text', name: 'recpientClinicName', label: 'Receiver'.translate(this.props.lang), allowSort: true },

                                        //{ type: 'text', name: 'articleName', label: 'Disease'.translate(this.props.lang), allowSort: true },
                                        { type: 'text', name: 'version', label: 'Referral ID'.translate(this.props.lang), allowSort: true },
                                        { type: 'text', name: 'status', label: 'Status'.translate(this.props.lang), allowSort: true },
                                        { type: 'text', name: 'timestamp', label: 'Referral date'.translate(this.props.lang), allowSort: true },


                                    ]}
                                    rowBackground={(item) => { return item.status == 'scheduled' || item.status === 'visit-rescheduled' ? '#d4ffd9' : null }}
                                    rawItems={this.state.items}
                                    items={this.state.items.map((item => {
                                        return {
                                            ...item,
                                            version: item.patient.referralNumber + (item.version != 0 ? '.' + item.version : ''),

                                            senderClinicData: <>{item.senderClinicData ? item.senderClinicData.name : null}<br /><span className="clinic-subgroup-name">{item.senderClinicData ? item.senderClinicData.city : null} </span> </>,

                                            recpientClinicName: <>{item.recpientClinicName}<br /><span className="clinic-subgroup-name">{item.recpientClinicSubgroupName} </span> </>,
                                            status: <>
                                                <span style={{ color: statusColors[item.status] }}>{humanReadable[item.status]} </span><br />

                                                {item.status !== 'waiting-for-approval' ?
                                                    <span>(
                                                        {item.status == 'disallowed' ?
                                                            // moment.unix(item.disallowedTimestamp).format(`${this.props.dateFormat} HH:mm`)
                                                            this.props.getStringDateTs(item.disallowedTimestamp, `${this.props.dateFormat} HH:mm`)
                                                            :
                                                            item.status == 'return-for-update' ?
                                                                // moment.unix(item.returnTimestamp).format(`${this.props.dateFormat} HH:mm`)
                                                                this.props.getStringDateTs(item.returnTimestamp, `${this.props.dateFormat} HH:mm`)
                                                                :
                                                                item.status == 'allowed' ?
                                                                    // moment.unix(item.allowedTimestamp).format(`${this.props.dateFormat} HH:mm`)
                                                                    this.props.getStringDateTs(item.allowedTimestamp, `${this.props.dateFormat} HH:mm`)
                                                                    :
                                                                    item.status == 'patient-requested-another-time' ?
                                                                        // moment.unix(item.patientRequestTimeTimestamp).format(`${this.props.dateFormat} HH:mm`)
                                                                        this.props.getStringDateTs(item.patientRequestTimeTimestamp, `${this.props.dateFormat} HH:mm`)
                                                                        :
                                                                        item.status == 'patient-rejected' ?
                                                                            // moment.unix(item.patientRejectedTimestamp).format(`${this.props.dateFormat} HH:mm`)
                                                                            this.props.getStringDateTs(item.patientRejectedTimestamp, `${this.props.dateFormat} HH:mm`)
                                                                            :
                                                                            item.status == 'patient-accepted' ?
                                                                                // moment.unix(item.patientAcceptedTimestamp).format(`${this.props.dateFormat} HH:mm`)
                                                                                this.props.getStringDateTs(item.patientAcceptedTimestamp, `${this.props.dateFormat} HH:mm`)
                                                                                :
                                                                                item.status == 'patient-accepted' ?
                                                                                    // moment.unix(item.patientAcceptedTimestamp).format(`${this.props.dateFormat} HH:mm`)
                                                                                    this.props.getStringDateTs(item.patientAcceptedTimestamp, `${this.props.dateFormat} HH:mm`)
                                                                                    :
                                                                                    item.status == 'clinic-accepted-requested-time' ?
                                                                                        // moment.unix(item.clinicAcceptedTimestamp).format(`${this.props.dateFormat} HH:mm`)
                                                                                        this.props.getStringDateTs(item.clinicAcceptedTimestamp, `${this.props.dateFormat} HH:mm`)
                                                                                        :
                                                                                        item.status == 'clinic-rejected-requested-time' ?
                                                                                            // moment.unix(item.clinicRejectedTimestamp).format(`${this.props.dateFormat} HH:mm`)
                                                                                            this.props.getStringDateTs(item.clinicRejectedTimestamp, `${this.props.dateFormat} HH:mm`)
                                                                                            :
                                                                                            item.status == 'approved-by-region-manager' ?
                                                                                                // moment.unix(item.approvedByRegionManagerTimestemp).format(`${this.props.dateFormat} HH:mm`)
                                                                                                this.props.getStringDateTs(item.approvedByRegionManagerTimestemp, `${this.props.dateFormat} HH:mm`)
                                                                                                :
                                                                                                item.status == 'canceled-by-region-manager' ?
                                                                                                    // moment.unix(item.canceledByRegionManagerTimestemp).format(`${this.props.dateFormat} HH:mm`)
                                                                                                    this.props.getStringDateTs(item.canceledByRegionManagerTimestemp, `${this.props.dateFormat} HH:mm`)
                                                                                                    :
                                                                                                    item.status == 'scheduled' ?
                                                                                                        // moment.unix(item.patientReservedTimestamp).format(`${this.props.dateFormat} HH:mm`)
                                                                                                        this.props.getStringDateTs(item.patientReservedTimestamp, `${this.props.dateFormat} HH:mm`)
                                                                                                        :
                                                                                                        item.status == 'visit-rescheduled' ?
                                                                                                            // moment.unix(item.patientReservedTimestamp).format(`${this.props.dateFormat} HH:mm`)
                                                                                                            this.props.getStringDateTs(item.patientReservedTimestamp, `${this.props.dateFormat} HH:mm`)
                                                                                                            :
                                                                                                            item.status == 'not-booked' ?
                                                                                                                // moment.unix(item.notBookedTimestamp).format(`${this.props.dateFormat} HH:mm`)
                                                                                                                this.props.getStringDateTs(item.notBookedTimestamp, `${this.props.dateFormat} HH:mm`)
                                                                                                                :
                                                                                                                item.status == 'requested-new-appointment' ?
                                                                                                                    // moment.unix(item.patientRequestedNewAppointment).format(`${this.props.dateFormat} HH:mm`)
                                                                                                                    this.props.getStringDateTs(item.patientRequestedNewAppointment, `${this.props.dateFormat} HH:mm`)
                                                                                                                    :
                                                                                                                    null
                                                        }) </span> : null} </>, timestamp: this.props.getStringDateTs(item.timestamp, `${this.props.dateFormat} HH:mm`)/*moment.unix(item.timestamp).format(`${this.props.dateFormat} HH:mm`)*/,

                                        }
                                    }))}
                                    actions={
                                        [
                                            {
                                                component: <i className="fa fa-eye base-color" />,
                                                onClick: (item) => {
                                                    this.setState({ referral: item }),
                                                        this.update(item._id)
                                                }
                                            },

                                            /*{
                                                component: <i className="fa fa-undo base-color" />,
                                                onClick: (item) => this.setState({ form: item })
                                            },

                                            {
                                                component: <i className="fa fa-ban danger-color" />,
                                                onClick: (item) => this.setState({ form: item })
                                            },
                                            {
                                                component: <i className="fa fa-trash danger-color" />,
                                                onClick: (item) => this.setState({ deleteModal: item })
                                            },*/
                                        ]
                                    }
                                    params={params}
                                    sortField={params.sortField}
                                    sortType={params.sortType}
                                    updateSort={this.updateSort}
                                    updateParams={this.updateParams}
                                    updateMultipleParams={this.updateMultipleParams}

                                ></ListBuilder>
                                <DeleteModal
                                    lang={this.props.lang}
                                    isOpen={this.state.deleteModal}
                                    toggle={() => this.setState({ deleteModal: null })}
                                    handler={() => { this.delete(this.state.deleteModal._id); this.setState({ deleteModal: null }) }}
                                >
                                    {'Delete referral'.translate(this.props.lang)} <strong>{this.state.deleteModal && this.state.deleteModal.title}</strong>?
                                </DeleteModal>

                            </div>
                        </Col>
                    </Row>

                </Container>

                {this.state.referral ?
                    <Modal isOpen={this.state.referral} centered size="xl">
                        <ModalHeader toggle={() => this.setState({ referral: !this.state.referral })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ referral: !this.state.referral })}>&times;</button>}>{'Referral preview'.translate(this.props.lang)}</ModalHeader>

                        <ModalBody>
                            <div dangerouslySetInnerHTML={{ __html: this.props.lang == 'se' ? this.state.referral.referralContent.protectReferralFiles(this.props.uData ? this.props.uData.mediaToken : '', this.state.referral._id) : this.state.referral.referralContentEn ? this.state.referral.referralContentEn.protectReferralFiles(this.props.uData ? this.props.uData.mediaToken : '', this.state.referral._id) : this.state.referral.referralContent.protectReferralFiles(this.props.uData ? this.props.uData.mediaToken : '', this.state.referral._id) }} onChange={() => { }}></div>
                            <div>
                                <p> <strong>{'Status:'.translate(this.props.lang)}</strong> {humanReadable[this.state.referral.status]}</p>
                                {this.state.referral.patientReservedTimestamp ?
                                    <p> <strong>{'Scheduled time:'.translate(this.props.lang)}</strong>{this.props.getStringDateTs(this.state.referral.patientReservedTimestamp, `${this.props.dateFormat} HH:mm`)/*moment.unix(this.state.referral.patientReservedTimestamp).format(`${this.props.dateFormat} HH:mm`)*/}</p>
                                    :
                                    null}
                                {
                                    this.state.referral.status == 'patient-requested-another-time' ?
                                        <p> <strong>{'Requested time:'.translate(this.props.lang)}</strong> {this.props.getStringDateTs(this.state.referral.patientRequestedTimestamp, `${this.props.dateFormat} HH:mm`)/*moment.unix(this.state.referral.patientRequestedTimestamp).format(`${this.props.dateFormat} HH:mm`)*/}</p>

                                        :
                                        null
                                }


                            </div>
                        </ModalBody>
                        <ModalFooter>

                            {
                                this.state.referral.patientReservedTimestamp && (this.state.referral.status == 'scheduled' || this.state.referral.status === 'visit-rescheduled' || this.state.referral.status == 'clinic-rejected-requested-time') ?
                                    <>
                                        {/*<Button color="success" onClick={() => this.acceptReferral({})}><i className="fa fa-check" /> Accept</Button>*/}
                                        <Button color="danger" onClick={() => this.setState({ rejectForm: this.state.referral })}><i className="fa fa-close" /> {'Reject'.translate(this.props.lang)} </Button>
                                        {/*<Button color="primary" onClick={() => this.setState({ requestAnotherDateForm: this.state.referral })}><i className="fa fa-undo" /> Request another date </Button>*/}
                                    </>

                                    :
                                    null
                            }
                            <PrintHelper lang={this.props.lang} html={this.props.lang == 'se' ? this.state.referral.referralContent : this.state.referral.referralContentEn ? this.state.referral.referralContentEn : this.state.referral.referralContent} />

                            <Button color="primary" onClick={() => this.setState({ referral: null })}> {'Close'.translate(this.props.lang)} </Button>

                        </ModalFooter>

                    </Modal>

                    :
                    null
                }

                {
                    this.state.rejectForm ?
                        <FormBuilder selectedGroup={this.props.selectedGroup} selectedClinic={this.props.selectedClinic} countryTwoLetter={this.props.countryTwoLetter} country={this.props.country} dateFormat={this.props.dateFormat}
                            getStringDateTs={this.props.getStringDateTs}
                            lang={this.props.lang}
                            onSubmit={(data) => this.rejectReferral(data)}
                            isOpen={this.state.rejectForm}
                            toggle={() => this.setState({ rejectForm: null })}
                            title={'Reject shceduled time'.translate(this.props.lang)}
                            buttonText={'Submit'.translate(this.props.lang)}
                            fields={[
                                {
                                    type: 'row',
                                    children: [
                                        {
                                            type: 'col',
                                            width: {
                                                lg: 12,
                                                sm: 12,
                                                xs: 12
                                            },
                                            children: [
                                                {
                                                    type: 'textarea',
                                                    name: 'reason',
                                                    label: 'Reject reason'.translate(this.props.lang),
                                                    validate: [required('Reject reason is required!'.translate(this.props.lang))]
                                                },
                                            ]
                                        },

                                    ]
                                }
                            ]}
                        ></FormBuilder>
                        :
                        null
                }

                {
                    this.state.requestAnotherDateForm ?
                        <FormBuilder selectedGroup={this.props.selectedGroup} selectedClinic={this.props.selectedClinic} countryTwoLetter={this.props.countryTwoLetter} country={this.props.country} dateFormat={this.props.dateFormat}
                            getStringDateTs={this.props.getStringDateTs}
                            lang={this.props.lang}
                            onSubmit={(data) => this.requestAnotherDate(data)}
                            isOpen={this.state.requestAnotherDateForm}
                            toggle={() => this.setState({ requestAnotherDateForm: null })}
                            title={'Request another date'.translate(this.props.lang)}
                            buttonText={'Submit'.translate(this.props.lang)}
                            fields={[
                                {
                                    type: 'row',
                                    children: [
                                        {
                                            type: 'col',
                                            width: {
                                                lg: 6,
                                                sm: 12,
                                                xs: 12
                                            },
                                            children: [
                                                {
                                                    type: 'date',
                                                    name: 'date',
                                                    label: 'Date'.translate(this.props.lang),
                                                    validate: [required('Date is required!'.translate(this.props.lang))]
                                                },
                                            ]
                                        },
                                        {
                                            type: 'col',
                                            width: {
                                                lg: 6,
                                                sm: 12,
                                                xs: 12
                                            },
                                            children: [
                                                {
                                                    type: 'time',
                                                    name: 'time',
                                                    label: 'Time'.translate(this.props.lang),
                                                    validate: [required('Time is required!'.translate(this.props.lang))]
                                                },
                                            ]
                                        },
                                    ]
                                }
                            ]}
                        ></FormBuilder>
                        :
                        null
                }

                {
                    this.state.selectClinicModal ?
                        <Modal isOpen={this.state.selectClinicModal} centered size="xl">
                            <ModalHeader toggle={() => this.setState({ selectClinicModal: !this.state.selectClinicModal })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ selectClinicModal: !this.state.selectClinicModal })}>&times;</button>}>
                                <h4>{'Search Curoflow database'.translate(this.props.lang)}</h4>
                            </ModalHeader>
                            <ModalBody>
                                <ReferralClinics {...this.props} patientData={this.state.patientData} searchFor={this.state.referral.articleName} includeTags={this.state.tags} importClinic={(clinic) => {

                                    this.forward(clinic);
                                    /*let addressLines = [];
                                    if (clinic.postAddress) {
                                        addressLines = this.splitAddress(clinic.postAddress);
                                    } else {
                                        addressLines = [clinic.street, clinic.zip + ' ' + clinic.city, ''];
                                    }
                
                                    this.setState({
                                        recipientData: {
                                            ...clinic,
                                            name: clinic.vantetiderName ? clinic.vantetiderName : clinic.name,
                                            address1: addressLines[0],
                                            address2: addressLines[1],
                                            address3: addressLines[2]
                                        },
                                        selectClinicModal: null
                                    })*/
                                }}></ReferralClinics>
                            </ModalBody>
                        </Modal>
                        :
                        null
                }



            </div >
        );
    }
}

const selector = formValueSelector('modalForm');

export default connect(state => {
    return { group: selector(state, 'group') }

}, {
    changeSubgroup: value => change("modalForm", "subgroup", value),

})(Page(Referrals));