import React, { Component } from 'react';
import { Modal, ModalBody, ModalHeader, ModalFooter, Button } from 'reactstrap'
import PrintHelper from './printHelper';
import Isvg from 'react-inlinesvg';
import xIcon from '../assets/svg/x.svg';
import billInfoIcon from '../assets/svg/bill_info.svg';
import formIcon from '../assets/svg/attach-form.svg';


import { API_ENDPOINT } from '../constants';
import moment from 'moment';
import FormPreview from './formPreview';
import PreviewAnsweredForm from '../views/forms/previewAnsweredForm';

import { getReferralData, getReferralDataRevisit } from '../helpers/getReferralData';
import { generateHtml } from '../views/forms/helper/helperFunctions';
import { generateSections } from '../helpers/generateSections';


import videoChatIcon from '../assets/svg/video.svg';
import userChatIcon from '../assets/svg/user.svg';

function imageUrlToBase64(url) {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.crossOrigin = "anonymous";
        img.onload = () => {
            const canvas = document.createElement("canvas");
            canvas.width = img.width;
            canvas.height = img.height;
            const ctx = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0);
            const base64 = canvas.toDataURL("image/png");
            resolve(base64);
        };
        img.onerror = reject;
        img.src = url;
    });
}



class ChatFormFirstSection extends Component {
    constructor(props) {
        super(props);
        this.modalBody = React.createRef();
        this.state = {
            clinic: false,
            activeRevisitForm: 1,
            printReferralContent: false
        };
    }
    // setClinicLogo = () => {
    //     if (this.state.clinic?.logo) {
    //         let img = API_ENDPOINT + this.state.clinic?.logo;
    //         imageUrlToBase64(img)
    //             .then(base64 => {
    //                 this.setState({ clinicLogo: base64 })
    //                 // Do something with the converted JPEG image
    //             })
    //             .catch(error => {
    //                 console.error(error);
    //                 // Handle the error
    //             });

    //     }
    // }

    componentDidMount() {
        let setObj = {}
        if (this.props.clinicData) {
            setObj.clinic = this.props.clinicData;
            setObj.clinicLogo = this.props.clinicData?.clinicLogo;
        }
        this.setState(setObj)


        // if (!this.state.clinic) {
        //     fetch(API_ENDPOINT + '/data/clinics/' + this.props.clinic, {
        //         method: 'GET',
        //         headers: {
        //             'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
        //             'content-type': 'application/json'
        //         },
        //     }).then(res => res.json()).then((result) => {
        //         this.setState({
        //             clinic: result
        //         }, () => {
        //             this.setClinicLogo()
        //         })
        //     })
        // }
    }




    render() {



        return (

            <div style={{ paddingLeft: 30, paddingRight: 30 }} ref={this.modalBody}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    {this.state.clinic.logo && this.state.clinicLogo ? <p><img style={{ maxWidth: 220 }} src={this.state.clinicLogo} /></p> : ''}
                    <div style={{ marginTop: 20 }}>
                        <p ><strong>{'Sökdatum'.translate(this.props.lang) + ': '}</strong> <span >{this.props.getStringDateTs(this.props.tsCreated, this.props.dateFormat)/*moment.unix(this.props.tsCreated).format(this.props.dateFormat)*/}</span></p>
                    </div>
                </div>
                <div >
                    <div>{this.state.clinic.name ? this.state.clinic.name : ''}</div>
                    <div>{this.state.clinic.street ? this.state.clinic.street : ''}, {this.state.clinic.zip ? this.state.clinic.zip : ''} {this.state.clinic.city ? this.state.clinic.city : ''}</div>
                    <div>{this.state.clinic.phone ? this.state.clinic.phone : ''} {this.state.clinic.email ? this.state.clinic.email : ''}</div>

                </div>
                <hr />

                <div>
                    <p><strong>{'Personuppgifter'.translate(this.props.lang) + ': '}</strong></p>
                </div>
                <div>
                    <p>
                        {this.props.patient?.socialSecurityNumber ? this.props.patient.socialSecurityNumber : ''}<br />
                        {
                            this.props.patient?.name ?
                                this.props.patient.name : null
                        }


                        <br />
                        {this.props.patient?.street ? this.props.patient.street : ''}<br />
                        {this.props.patient?.zip ? this.props.patient.zip : ''}&nbsp;
                        {this.props.patient?.city ? this.props.patient.city : ''}<br />
                        {this.props.patient?.phone ? this.props.patient.phone : ''}<br />
                        {this.props.patient?.email ? this.props.patient.email : ''}
                    </p>
                </div>




            </div>


        );


    }
}
export default ChatFormFirstSection;