import React from 'react';

import {
    Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';

import RGL, { WidthProvider } from "react-grid-layout";
import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'

import Isvg from 'react-inlinesvg';
import moveIcon from '../assets/svg/move.svg';
import moreIcon from '../assets/svg/more.svg';

//const ReactGridLayout = WidthProvider(RGL);
import moment from 'moment';


function getColorOfEvent(type, onlineVisit) {
    let obj = {}

    if (type == 'referral') {
        if (onlineVisit) {
            obj = {
                backgroundColor: 'rgb(58, 63, 86)',
                color: '#ffff'
            }
        } else {
            obj = {
                backgroundColor: '#568ac8',
                color: '#ffff'
            }
        }

    } else if (type == 'revisit') {
        if (onlineVisit) {
            obj = {
                backgroundColor: 'rgb(58, 63, 86)',
                color: '#ffff'
            }
        } else {
            obj = {
                backgroundColor: '#60bebb',
                color: '#ffff'
            }
        }

    } else if (type == 'event') {
        obj = {
            backgroundColor: '#dbd233',
            color: '#ffff'
        }
    } else if (type == 'free') {
        obj = {
            backgroundColor: '#008000',
            color: '#ffff'
        }
    }




    return obj;
}


/**
* AppBuilder
* @author   Milan Stanojevic
*/
class DragAndDropList extends React.Component {

    constructor(props) {
        super(props);
        this.wrapperRef = React.createRef();

        this.state = {
            clipboard: []
        }
    }

    onLayoutChange = (layout) => {

        let oldLayout = [];
        let changedItems = [];

        for (let i = 0; i < this.props.cols.length; i++) {
            for (let j = 0; j < this.props.cols[i].events.length; j++) {
                // let startDate = new Date(this.props.cols[i].events[j].startTime * 1000);
                // let startDate = new Date(this.props.getStringDateTs(this.props.cols[i].events[j].startTime, this.props.dateFormat))
                let startDate = new Date(this.props.getStringDateTs(this.props.cols[i].events[j].startTime, 'MM/DD/YYYY HH:mm'))
                startDate.setHours(0);
                startDate.setMinutes(0);
                startDate.setSeconds(0);
                startDate.setMilliseconds(0);
                let startTimestamp = Math.floor(startDate.getTime() / 1000);

                for (let k = 0; k < layout.length; k++) {
                    if (layout[k].i == this.props.cols[i].events[j]._id) {
                        let generatedItem = {
                            i: this.props.cols[i].events[j]._id,
                            x: i,
                            w: 1,
                            maxW: 1,
                            y: Math.ceil(((Math.floor(new Date(this.props.getStringDateTs(this.props.cols[i].events[j].startTime, 'MM/DD/YYYY HH:mm')).getTime() / 1000) - startTimestamp)) / (5 * 60)),
                            h: Math.ceil((((this.props.cols[i].events[j].endTime - this.props.cols[i].events[j].startTime) / (5 * 60))))
                        }

                        if (layout[k].x != generatedItem.x || layout[k].y != generatedItem.y || layout[k].h != generatedItem.h) {
                            this.props.onChange({
                                _id: layout[k].i,
                                column: this.props.cols[layout[k].x]._id,
                                startTime: startTimestamp + layout[k].y * (5 * 60),
                                endTime: startTimestamp + layout[k].y * (5 * 60) + layout[k].h * (5 * 60),
                                columnChanged: layout[k].x != generatedItem.x,
                                oldColumn: generatedItem.x
                            })
                            return;
                        }

                    }
                }

            }
        }

        //this.props.onChange(changedItems);

    }

    onDrop = (layout, layoutItem, _event) => {
        // if (layoutItem._id)
        this.props.addFromClipboard(this.state.dragElementType, layoutItem, () => this.setState({ dragElementType: null }))




    };

    componentDidMount() {
        if (this.props.clipboard) {
            this.setState({ clipboard: this.props.clipboard })
        }
        document.addEventListener('mousedown', this.handleClickOutside);
    }
    componentDidUpdate(prevProps) {
        if (prevProps.clipboard != this.props.clipboard) {
            this.setState({ clipboard: this.props.clipboard })
        }

    }
    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }
    handleClickOutside = (event) => {

        if (this.wrapperRef && this.wrapperRef.contains && !this.wrapperRef.contains(event.target)) {
            this.setState({ contextMenu: null })
        }
    }


    render() {

        const STEP_HEIGHT = 10;
        const STEP = 5 * 60;
        const SECOND_HEIGHT = STEP_HEIGHT / STEP;

        let layout = [];
        let items = [];
        for (let i = 0; i < this.props.cols.length; i++) {
            for (let j = 0; j < this.props.cols[i].events.length; j++) {
                if (!this.props.cols[i].events[j].deleted && !this.props.cols[i].events[j].forDelete) {
                    items.push(this.props.cols[i].events[j]);


                    // let startDate = new Date(this.props.cols[i].events[j].startTime * 1000);
                    // let startDate = new Date(this.props.getStringDateTs(this.props.cols[i].events[j].startTime, this.props.dateFormat))
                    let startDate = new Date(this.props.getStringDateTs(this.props.cols[i].events[j].startTime, 'MM/DD/YYYY HH:mm'))
                    startDate.setHours(0);
                    startDate.setMinutes(0);
                    startDate.setSeconds(0);
                    startDate.setMilliseconds(0);
                    let startTimestamp = Math.floor(startDate.getTime() / 1000);

                    layout.push({
                        i: this.props.cols[i].events[j]._id,
                        x: i,
                        w: 1,
                        maxW: 1,
                        y: Math.ceil(((Math.floor(new Date(this.props.getStringDateTs(this.props.cols[i].events[j].startTime, 'MM/DD/YYYY HH:mm')).getTime() / 1000) - startTimestamp)) / (5 * 60)),
                        h: Math.ceil((((this.props.cols[i].events[j].endTime - this.props.cols[i].events[j].startTime) / (5 * 60)))),
                        static: (this.props.cols[i].events[j].startTime < Math.floor(new Date().getTime() / 1000)) || (this.props.cols[i].events[j].eventId || this.props.cols[i].events[j].eventFromAnotherClinic || this.props.cols?.[i]?.events?.[j]?.takeCare || this.props.cols?.[i]?.events?.[j]?.semble || this.props.cols[i].events[j].inClipboard || this.props.cols[i].events[j].reserved) ? true : false
                    })
                }

            }
        }
        items.sort((a, b) => b.startTime - a.startTime)

        let newDate = new Date(this.props.date.getFullYear(), this.props.date.getMonth(), this.props.date.getDate(), this.props.min ? this.props.min.hours : 0, this.props.min ? this.props.min.minutes : 0, 0, 0);
        let endDate = new Date(this.props.date.getFullYear(), this.props.date.getMonth(), this.props.date.getDate(), this.props.max ? this.props.max.hours : 23, this.props.max ? this.props.max.minutes : 59, 59, 999);
        // let newDate = new Date(this.props.getStringDateTs(Math.floor(new Date(this.props.date.getFullYear(), this.props.date.getMonth(), this.props.date.getDate(), this.props.min ? this.props.min.hours : 0, this.props.min ? this.props.min.minutes : 0, 0, 0).getTime() / 1000), this.props.dateFormat));
        // let endDate = new Date(this.props.getStringDateTs(Math.floor(new Date(this.props.date.getFullYear(), this.props.date.getMonth(), this.props.date.getDate(), this.props.max ? this.props.max.hours : 23, this.props.max ? this.props.max.minutes : 59, 59, 999).getTime() / 1000), this.props.dateFormat));
        let daySplitted = [];
        for (let i = 0; i < (endDate.getTime() - newDate.getTime()) / 1000 / 60 / 60; i++) {
            let newDate = new Date(this.props.date.getFullYear(), this.props.date.getMonth(), this.props.date.getDate(), this.props.min ? this.props.min.hours : 0, this.props.min ? this.props.min.minutes : 0, 0, 0);
            // let newDate = new Date(this.props.getStringDateTs(Math.floor(new Date(this.props.date.getFullYear(), this.props.date.getMonth(), this.props.date.getDate(), this.props.min ? this.props.min.hours : 0, this.props.min ? this.props.min.minutes : 0, 0, 0).getTime() / 1000), this.props.dateFormat));
            newDate.setMinutes(i * 60);
            daySplitted.push({ date: newDate, timestamp: Math.floor(newDate.getTime() / 1000) });
        }
        if (typeof window == 'undefined') {
            return null;
        }

        const VISIBLE_COLUMNS = typeof window != 'undefined' && window.innerWidth < 768 ? 2 : 5;

        const columnWidth = this.props.cols.length > VISIBLE_COLUMNS ? this.root && (this.root.offsetWidth / VISIBLE_COLUMNS) : this.root && (this.root.offsetWidth / this.props.cols.length);
        return (
            <div>
                {
                    this.state.clipboard && this.state.clipboard.length ?
                        <div className="drag-and-drop-calendar-clipboard">
                            <h4>{'Clipboard'.translate(this.props.lang)}</h4>
                            <div className='clipboard-elements-wrap'>
                                {
                                    this.state.clipboard.map((item, idx) => {
                                        if (!item.eventId)
                                            return (
                                                <div key={item?._id || idx}
                                                    className={`clipboard-element`}
                                                    style={getColorOfEvent(item.type, item.onlineVisit)}
                                                    draggable={true}
                                                    unselectable="on"
                                                    onDragStart={e => {

                                                        this.setState({
                                                            dragElementType: item
                                                        })

                                                        e.dataTransfer.setData("text/plain", "")

                                                    }}
                                                >
                                                    <h6>{this.props.getStringDateTs(item.startTime, 'HH:mm')/*moment.unix(item.startTime).format('HH:mm')*/} - {this.props.getStringDateTs(item.endTime, 'HH:mm')/*moment.unix(item.endTime).format('HH:mm')*/}</h6>
                                                    <h5>{item.type == 'free' ? item.title.translate(this.props.lang) : item.title}</h5>
                                                    <div
                                                        style={{ position: 'absolute', top: 5, right: 10, color: 'white', fontSize: 16, cursor: 'pointer' }}
                                                        onClick={() => this.props.removeFromClipboard(item)}
                                                    >&times;</div>
                                                </div>
                                            )
                                    })
                                }
                            </div>
                        </div>
                        :
                        null
                }

                <div className="drag-and-drop-calendar-wrap" ref={(node) => this.root = node}>

                    <div className="drag-and-drop-calendar-head" style={{ width: columnWidth * this.props.cols.length }}>
                        {
                            this.props.cols.map((item, idx) => {
                                return (
                                    <div key={idx} className="drag-and-drop-calendar-column" style={{ width: columnWidth }}>
                                        <div className="drag-and-drop-calendar-column-head">
                                            {item.name}
                                            {!item.clipboard ? <div className="delete-item" onClick={() => this.props.deleteItem(item)}>&times;</div> : null}
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>


                    <div className="drag-and-drop-calendar-scroll" style={{ width: columnWidth * this.props.cols.length }}>

                        <div className="drag-and-drop-calendar-layout">
                            {
                                this.props.cols.map((item, idx) => {
                                    return (
                                        <div key={idx} className="drag-and-drop-calendar-column" style={{ width: columnWidth }}>
                                            <div>
                                                {
                                                    daySplitted.map((block, bidx) => {
                                                        return (
                                                            <div
                                                                key={bidx}
                                                                className="drag-and-drop-calendar-column-block" style={{ height: SECOND_HEIGHT * 60 * 60 }}>
                                                                {!item.clipboard ?
                                                                    <div className="drag-and-drop-calendar-column-block-time">
                                                                        {moment.unix(block.timestamp).format('HH:mm')}
                                                                    </div>
                                                                    : null}
                                                                <div className="drag-and-drop-calendar-column-block-grid">
                                                                    {
                                                                        ([...Array(Math.floor(60 * 60 / STEP))]).map((item, idx) => {
                                                                            return <div key={idx} className="drag-and-drop-calendar-column-block-grid-line"></div>
                                                                        })
                                                                    }
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>

                        <div className="drag-and-drop-calendar-rgl" style={{ height: 24 * 60 * 60 * SECOND_HEIGHT, width: columnWidth * this.props.cols.length }}>
                            <RGL
                                layout={layout}
                                width={columnWidth * this.props.cols.length}

                                // when allowOverlap is true this doesn't work so logic is in onDragStop and onResizeStop
                                // onLayoutChange={(layout) => {
                                //     // if(!this.state.dragElementType){
                                //     //     this.onLayoutChange(layout)
                                //     // }
                                //     this.onLayoutChange(layout)
                                // }}

                                //draggableHandle={".app-builder-drag-handle"}
                                margin={[0, 0]}
                                droppingItem={this.state.dragElementType && { i: 'test', w: 1, h: Math.ceil((((this.state.dragElementType.endTime - this.state.dragElementType.startTime) / (5 * 60)))) }}
                                onDrop={this.onDrop}
                                isDroppable={true}
                                cols={this.props.cols.length}
                                rowHeight={STEP_HEIGHT}
                                verticalCompact={false}
                                preventCollision={true}
                                maxRows={24 * 60 * 60 / STEP}
                                allowOverlap={true}

                                onDragStop={(e) => {
                                    this.onLayoutChange(e)
                                }}
                                onResizeStop={(e) => {
                                    this.onLayoutChange(e)
                                }}
                            >
                                {
                                    items.map((item, idx) => {

                                        return (
                                            <div key={item._id} className="drag-and-drop-calendar-event">
                                                <div>
                                                    {this.props.components[item.type](item)}
                                                </div>
                                                <div className="more-icon-wrap" onClick={() => this.setState({ contextMenu: item })}>
                                                    <div>
                                                        <Isvg src={moreIcon} />
                                                        {
                                                            this.state.contextMenu && this.state.contextMenu._id == item._id ?
                                                                <div className='drag-and-drop-context-menu-overlay-context-menu-wrap' ref={node => this.wrapperRef = node}>

                                                                    <div className={!item.eventId && !item.eventFromAnotherClinic && !item?.takeCare && !item.inClipboard && !item.reserved && item.startTime > Math.floor(new Date().getTime() / 1000) ? "drag-and-drop-context-menu-overlay-context-menu" : "drag-and-drop-context-menu-overlay-context-menu disabled-context-menu"}>
                                                                        <ul>
                                                                            <li><button onClick={(e) => {
                                                                                e.stopPropagation()
                                                                                if (!item.eventId && !item.eventFromAnotherClinic && !item?.takeCare && !item.inClipboard && item.startTime > Math.floor(new Date().getTime() / 1000)) {
                                                                                    this.props.moveToClipoboard(this.state.contextMenu, () => {
                                                                                        this.setState({ contextMenu: null })
                                                                                    })
                                                                                }
                                                                            }}

                                                                            >{'Move to clipoboard'.translate(this.props.lang)}</button></li>
                                                                        </ul>
                                                                    </div>

                                                                </div>
                                                                :

                                                                null
                                                        }
                                                    </div>
                                                </div>
                                                {/* <div className="drag-and-drop-context-menu-overlay" onClick={() => this.setState({ contextMenu: null })}></div> */}

                                            </div>
                                        )
                                        // }

                                    })
                                }
                            </RGL>
                        </div>


                    </div>

                </div>
            </div >

        )
    }
}

export default DragAndDropList;