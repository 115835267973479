import React, { Component } from 'react';

import {
    Row,
    Col,
    Table,
    FormGroup, Label, Input, Button
} from 'reactstrap';

import Isvg from 'react-inlinesvg';


import ReactPaginate from 'react-paginate';
import moment from 'moment';
import Checkbox from './forms/fields/checkbox';
import { Player, Controls } from "@lottiefiles/react-lottie-player";

import { Waypoint } from 'react-waypoint';


function isNumber(char) {
    if (typeof char !== 'string') {
        return false;
    }
    if (char.trim() === '') {
        return false;
    }

    return !isNaN(char);
}

/**
* Generate data list with pagination
* @author   Milan Stanojevic
*/
class ListBuilderMob extends Component {
    constructor(props) {
        super(props);
        this.columnVisibilityRef = React.createRef();
        this.state = {
            filter: '',
            // lastChangeFilter: null
        };
    }

    componentDidMount() {
    }
    componentWillUnmount() {
    }



    componentDidUpdate(prevProps, prevState) {
        if (prevProps.updateFilterField != this.props.updateFilterField) {
            let params = this.props.params;
            if (params.filter != this.state.filter) {
                this.setState({ filter: params.filter ? params.filter : '' })
            }
        }
    }
    checkFilterDelay = () => {
        if (this.lastChangeFilter && this.props.filterDelay) {
            let delay = new Date().getTime() - this.lastChangeFilter;
            let delayTime = 100;
            if (this.props.filterDelay > 100) {
                delayTime = this.props.filterDelay - 100;
            }
            if (delay > delayTime) {
                this.props.updateMultipleParams([{ name: 'filter', value: this.state.filter }, { name: 'page', value: 0 }], true)
                this.lastChangeFilter = null

            }
        }
    }
    render() {
        let params = this.props.params;
        return (

            <Row className="list-builder-mobile-wrap">



                {
                    !this.props.disableFilter ?
                        <Col lg="12">
                            <FormGroup className='filter-wrap'>
                                <Label>
                                    {'Filter results'.translate(this.props.lang)}
                                </Label>
                                <Input
                                    type="text"
                                    style={{ marginRight: 0 }}
                                    value={this.state.filter}
                                    onChange={(e) => {
                                        let value = e.target.value;

                                        if (this.props.hideDash) {
                                            let dash = value.indexOf('-');
                                            if (dash !== -1) {
                                                if (value[dash - 1] && isNumber(value[dash - 1]) && value[dash + 1] && isNumber(value[dash + 1])) {
                                                    value = value.replace('-', '');
                                                }
                                            }
                                        }

                                        if (this.props.filterDelay && value) {
                                            this.setState({ filter: value }, () => {
                                                this.lastChangeFilter = new Date().getTime();
                                                setTimeout(() => {
                                                    this.checkFilterDelay()
                                                }, this.props.filterDelay);
                                            })
                                        } else {
                                            this.setState({ filter: value }, () => {
                                                this.props.updateMultipleParams([{ name: 'filter', value: this.state.filter }, { name: 'page', value: 0 }], true)
                                            })
                                        }


                                    }}
                                ></Input>
                            </FormGroup>
                        </Col>
                        :
                        null
                }

                {
                    this.props.filterFields && this.props.filterFields.length ?
                        <Col lg="12">
                            <div className='filter-fields-wrap'>
                                {
                                    this.props.filterFields.map((field, fidx) => {
                                        return (
                                            <div onClick={() => {
                                                if (field.onClick) {
                                                    field.onClick()
                                                }
                                            }} className={field.active ? 'filter-fields-item filter-fields-item-active' : 'filter-fields-item'}>
                                                {field.name}
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </Col>
                        :
                        null
                }


                <Col lg="12" >

                    <div className="list-items-wrap">
                        {
                            this.props.items && this.props.items.length ?
                                <div ref={(node) => this.itemsRef = node}>

                                    {
                                        this.props.items.map((item, idx) => {
                                            return (
                                                <div className="list-item">
                                                    <div className='items-header-wrap'>
                                                        <div className='items-header-left'>
                                                            {
                                                                this.props.fields.filter(f => (f.name && f.type?.indexOf('item') === -1)).map((field, fidx) => {
                                                                    if (field.type == 'title') {
                                                                        return (
                                                                            <div className='title-item'>
                                                                                <h5>{item[field.name]}</h5>
                                                                            </div>
                                                                        )
                                                                    } else if (field.type == 'subtitle') {
                                                                        return (
                                                                            <div className='subtitle-item'>
                                                                                <h6>{item[field.name]}</h6>
                                                                            </div>
                                                                        )
                                                                    }
                                                                })
                                                            }
                                                        </div>
                                                        <div className='items-header-right'>
                                                            {
                                                                this.props.fields.filter(f => (f.name && f.type === 'item-right-header')).map((field, fidx) => {
                                                                    if (field.condition && typeof field.condition === 'function' && !(field.condition(item))) return null;
                                                                    return (
                                                                        <div className='items-right'>
                                                                            {
                                                                                field.svg ?
                                                                                    <Isvg src={field.svg} style={field.svgStyle ? field.svgStyle : {}} />
                                                                                    :
                                                                                    null
                                                                            }
                                                                            {item[field.name]}
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </div>

                                                    <div className='items-wrap'>
                                                        <div className='items-left'>
                                                            {
                                                                this.props.fields.filter(f => (f.name && f.type === 'item')).map((field, fidx) => {
                                                                    if (field.condition && typeof field.condition === 'function' && !(field.condition(item))) return null;
                                                                    if (field.svg) {
                                                                        return (
                                                                            <div className='item-item'>
                                                                                <Isvg src={field.svg} style={field.svgStyle ? field.svgStyle : {}} />
                                                                                {item[field.name]}
                                                                            </div>
                                                                        )
                                                                    } else {
                                                                        return item[field.name]
                                                                    }
                                                                })
                                                            }

                                                        </div>
                                                        <div className='items-right'>
                                                            {
                                                                this.props.fields.filter(f => (f.name && f.type === 'item-right')).map((field, fidx) => {
                                                                    return (
                                                                        <div className='item-right-item'>
                                                                            {
                                                                                field.svg ?
                                                                                    <Isvg src={field.svg} style={field.svgStyle ? field.svgStyle : {}} />
                                                                                    :
                                                                                    null
                                                                            }
                                                                            {item[field.name]}
                                                                        </div>
                                                                    )
                                                                })
                                                            }

                                                        </div>
                                                    </div>


                                                    {this.props.actions && this.props.actions.length ?
                                                        <div className='actions-wrap'>
                                                            {
                                                                this.props.actions.map((action, aidx) => {
                                                                    let buttonIndicator = action && action.buttonIndicator && action.buttonIndicator(item) ? true : false;
                                                                    if (!action.condition || (action.condition && action.condition(item)))
                                                                        return (
                                                                            <button key={aidx} className={action.buttonClassName ? action.buttonClassName : ''} onClick={() => {
                                                                                if (this.props.rawItems) {
                                                                                    action.onClick(this.props.rawItems[idx]);
                                                                                }
                                                                                else {
                                                                                    action.onClick(item)
                                                                                }
                                                                            }}>
                                                                                {action.renderComponent ? action.renderComponent(item) : action.component}
                                                                                {buttonIndicator ? <div className='list-builder-mob-button-indicator' /> : null}
                                                                            </button>
                                                                        )
                                                                })
                                                            }
                                                        </div>
                                                        :
                                                        null
                                                    }


                                                </div>
                                            )
                                        })
                                    }
                                    {
                                        (this.props.total > this.props.items.length && !this.props.disableLoadMore) ?
                                            <div className='load-more-button-wrap'>
                                                <button onClick={() => {
                                                    if (this.props.loadMoreItems) {
                                                        this.props.loadMoreItems()
                                                    }
                                                }}>{'Load more'.translate(this.props.lang)}</button>
                                            </div>
                                            :
                                            null
                                    }

                                </div>
                                :
                                null

                        }


                    </div>

                    {this.props.loading ?
                        <div className="loader-wrap" >
                            <Player
                                autoplay={true}
                                loop={true}
                                src="/lf30_editor_l5cxzdyf.json"
                                style={{ height: "128px", width: "128px" }}
                            ></Player>

                        </div>
                        :
                        null
                    }

                </Col>




            </Row>

        );
    }
}

export default ListBuilderMob;