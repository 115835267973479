
import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';

import Isvg from 'react-inlinesvg';

import { FormGroup, Label, Input, FormFeedback, FormText, Button, Row, Col } from 'reactstrap';
import Checkbox from './checkbox';
/**
* Custom select list checkbox
* @author   Stefan Pantic
*/
class Text extends Component {
    constructor(props) {
        super(props);

        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.select = React.createRef();

        this.state = {
            filter: null,
            showGroups: false,
            name: ''
        };
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
        if (this.props.values && this.props.value) {
            let value = this.props.value;
            let name = ''
            if (typeof value === 'string') {
                value = [value]
            }

            for (let i = 0; i < this.props.values.length; i++) {
                for (let j = 0; j < value.length; j++) {
                    if (this.props.values[i].value === value[j]) {
                        name += this.props.values[i].name + ', '
                    }
                }

            }

            this.setState({
                name: name
            })
        }
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside = (event) => {
        if (this.select && this.select.current && !this.select.current.contains(event.target)) {
            this.setState({
                showGroups: false
            })
        }
    }

    render() {
        return (
            <div ref={this.select}>
                {this.props.label ? <Label size="sm" className={this.props.error ? "required" : ""}>{this.props.label}{this.props.haveValidation ? <span className="required-label-span">*</span> : null}</Label> : null}
                <div>
                    <div className='select-checbox-list' onClick={() => {
                        this.setState({
                            showGroups: !this.state.showGroups
                        })
                    }}>{this.state.name ? this.state.name : this.props.placeHolder}</div>
                    {
                        this.state.showGroups ?
                            <div className='select-checbox-list-items'>
                                {this.props.values && this.props.values.length ? this.props.values.map((option, oidx) => {
                                    if (!this.state.filter || (this.state.filter && option.name.indexOf(this.state.filter) == 0))
                                        return (
                                            <Col lg={"12"}>
                                                <FormGroup>

                                                    <Checkbox key={oidx} disabled={this.props.disabled || option.disabled} checked={this.props.checkAll ? true : this.props.value && this.props.value.indexOf(option.value) !== -1} onChange={() => {
                                                        if (!(this.props.disabled || option.disabled)) {
                                                            let value = this.props.value;
                                                            let name = this.state.name
                                                            if (!value) {
                                                                value = [];
                                                            }
                                                            if (typeof value === 'string') {
                                                                value = [value]
                                                            }

                                                            let idx = value.indexOf(option.value);
                                                            if (idx == -1) {
                                                                value.push(option.value);
                                                                name += option.name + ', '
                                                            } else {
                                                                value.splice(idx, 1);
                                                                name = name.replace(`${option.name}, `, '')

                                                            }
                                                            this.setState({
                                                                name: name
                                                            })
                                                            this.props.onChange(value);
                                                            this.forceUpdate();
                                                        }


                                                    }} type="checkbox" label={option.name} />
                                                </FormGroup>
                                            </Col>
                                        )
                                })
                                    :
                                    null
                                }
                            </div>
                            :
                            null 
                    }
                    <p style={{ color: '#dc3545', fontSize: '80%', marginTop:'3px' }}>{this.props.error}</p>
                </div>
                {/* <div className={'list-checkbox-scroll'}>
                    <FormGroup>

                        <Row className="multiselect-checkboxes">
                            {this.props.values && this.props.values.length ? this.props.values.map((option, oidx) => {
                                if (!this.state.filter || (this.state.filter && option.name.indexOf(this.state.filter) == 0))
                                    return (
                                        <Col lg={"12"}>
                                            <FormGroup>
                                                {
                                                    this.props.checkboxField ?
                                                        <p>{option.name}</p>
                                                        :
                                                        <Checkbox key={oidx} disabled={this.props.disabled || option.disabled} checked={this.props.value && this.props.value.indexOf(option.value) !== -1} onChange={() => {
                                                            let value = this.props.value ? this.props.value : '';
                                                            if (value && typeof value !== 'string') {
                                                                let values = ''
                                                                for (let i = 0; i < this.props.value.length; i++) {
                                                                    if (this.props.values.indexOf(this.props.value) !== -1)
                                                                        values += `${this.props.value[i]},`

                                                                }

                                                                value = values
                                                            }

                                                            let idx = value.indexOf(`${option.value},`);
                                                            if (idx === -1) {
                                                                value += `${option.value},`
                                                            } else {
                                                                value = value.replace(`${option.value},`, '')
                                                            }
                                                            this.props.onChange(value);

                                                        }} type="checkbox" label={option.name} />
                                                }
                                            </FormGroup>
                                        </Col>
                                    )
                            })
                                :
                                null
                            }
                        </Row>
                    </FormGroup>
                </div> */}
            </div>


        );
    }
}

export default Text;