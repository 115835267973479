import HomePage from './views/homePage';
import Groups from './views/dataManagment/groups';
import MedicalWorkersCategories from './views/dataManagment/medicalWorkersCategories';
import LandingStyles from './views/dataManagment/landingStyles';
import AddLandingStyle from './views/dataManagment/addLandingStyle';
import ImportantInformation from './views/dataManagment/importantInformation';
import LandingStylesV2 from './views/landingBuilder/landingStyles';
import AddLandingStyleV2 from './views/landingBuilder/addLandingStyles';
import UserNotifications from './views/dataManagment/userNotifications';
import SubGroups from './views/dataManagment/subgroups';
import Regions from './views/dataManagment/regions';
import Clinics from './views/dataManagment/clinics';
import Articles from './views/dataManagment/articles';
import Wiki from './views/dataManagment/wiki';
import Tags from './views/dataManagment/tags';
import WikiCategories from './views/dataManagment/wikiCategories';
import WaitingTimeLog from './views/dataManagment/waitingTimeLog';
import Icd10 from './views/dataManagment/icd10';
import GroupPermissions from './views/dataManagment/groupPermissions';
import PersonalReferrals from './views/dataManagment/personalReferrals';
import GeneralHealthCare from './views/dataManagment/generalHealthCare';
import FormImporter from './views/dataManagment/formImporter';
import FormBuilder from './views/dataManagment/formBuilder';
import UserManualEditor from './views/dataManagment/userManualEditor';
import UserManualPage from './views/dataManagment/userManualPage';
import UserManualPageEn from './views/dataManagment/userManualPageEn';
import HomePatientLevel from './views/homePatientLevel';
import PatientError from './views/patientError';
import Notifications from '././views/user/notifications';
import Support from './views/user/support';
import SupportSent from './views/user/sentSupport';
import ReceivedSupport from './views/user/reeivedSupport';
import SupportAssined from './views/user/assignedSupport';
import SupportCategory from './views/user/supportCategory';
import SupportArchive from './views/user/supportArchive';
import AllSupport from './views/user/allSupportTickets';
import TestCalendar from './views/referral/calendarViews/testCalendar';
import ManageModules from './views/modules/manageModules';
import SortModules from './views/modules/sortModules';
import UserLevels from './views/users/userLevels';
import Users from './views/users/users';
import Patients from './views/patients/patients';
import SearchPatient from './views/patients/search';
import SearchPatientEnterprise from './views/patients/searchEnterprise';
import Login from './views/loginPage';
import VerificationCodePage from './views/verificationCodePage';
import { API_ENDPOINT } from './constants';
import Account from './views/user/account';
import SubAccounts from './views/user/subAccounts';
import DeletionAccounts from './views/user/deletionAccounts';
import PatientList from './views/patients/patientList';
import PriceList from './views/user/priceList';
import ClinicData from './views/user/clinicData';
import DropInWorkingHours from './views/user/dropInWorkingHours';
import LandingBuilderMainClinic from './views/user/landingBuilderMainClinic';
import QuickPhrases from './views/user/quickPhrases';
import clinicNotifications from './views/user/clinicNotifications';
import DaybookEvents from './views/user/daybookEvents';
import Translation from './views/languages/translation';
import BankId from './views/bankId';
import SignBankId from './views/signBankId';
import BankIdIdentityResponse from './views/bankIdIdentityResponse';
import NetId from './views/netid';
import NetIdSign from './views/netidSign';
import Categories from './views/dataManagment/categories';
import SubCategories from './views/dataManagment/subCategories';
import CategoriesTree from './views/dataManagment/categoriesTree';
import PrepareQuestionary from './views/dataManagment/prepareQuestionary';
import Logs from './views/logs/logs';
import GroupLogs from './views/logs/groupLogs';
import Tracking from './views/logs/tracking';
import DeletedUsersLogs from './views/logs/deletedUsersLogs';
import Crm from './views/crm/crm';
import CreateRefferal from './views/referral/createReferral';
import SentReferrals from './views/referral/sentReferrals';
import ReceivedReferrals from './views/referral/receivedReferrals';
import ScheduleReferrals from './views/referral/scheduleReferrals';
import DragAndDropEvents from './views/referral/dragAndDropEvents';
import Referrals from './views/referral/referrals';
import Refferals from './views/egenRemiss/patient/referrals';
import CreateNewCase from './views/egenRemiss/patient/createNewCase';
import regionManagers from './views/dataManagment/regionManagers';
import ManagerID from './views/managerId';
import Chat from './views/chat/chat';
import ClinicGroups from './views/dataManagment/clinicGroups';
import ClinicGroupsExport from './views/dataManagment/clinicGroupsExport';
import MainClinicGroups from './views/dataManagment/mainClinicGroups';
import ExportClinicsStatistic from './views/dataManagment/exportClinicsStatistic';
import ListingPage from './views/user/listingPage';
import Payments from './views/dataManagment/payments';
import landingPage from './views/landingPage/landingPage';
import PrivacyPolicy from './views/landingPage/privacyPolicy';
import PrivacyPolicyV2 from './views/landingPage/privacyPolicyV2';
import AppPrivacyPolicy from './views/landingPage/appPrivacyPolicy';
import AccessibilityReport from './views/landingPage/accessibilityReport';
import AppAccessibilityReport from './views/landingPage/appAccessibilityReport';
import TermsOfUse from './views/landingPage/termsOfUse';
import AppTermsOfUse from './views/landingPage/appTermsOfUse';
import ReferralBuilder from './views/egenRemiss/referralBuilder';
import DaybookBuilder from './views/egenRemiss/daybookBuilder';
import RevisitBuilder from './views/egenRemiss/revisitBuilder';
import ChatFormsBuilder from './views/egenRemiss/chatFormsBuilder';
import HealthCareBuilder from './views/egenRemiss/healthCareBuilder';
import ReferralsList from './views/egenRemiss/referralsList';
import DaybookList from './views/egenRemiss/daybookList';
import RevisitFormsList from './views/egenRemiss/revisitFormsList';
import ChatFormsList from './views/egenRemiss/chatFormsList';
// import CreateReferral from './views/egenRemiss/createReferral';
import BookingByClinicReferral from './views/forms/bookingByClinicReferral';
import DropInReferral from './views/forms/dropInReferral';
import WebBookingReferral from './views/forms/webBookingReferral';
import ChooseTime from './views/egenRemiss/chooseTime';
import ReceivedRefferal from './views/egenRemiss/receivedReferrals';
import ReceivedWebBoking from './views/egenRemiss/receivedWebBoking';
import ReceivedDropInReferrals from './views/egenRemiss/receivedDropInReferrals';
import MyCases from './views/egenRemiss/myCases';
import MyPayments from './views/egenRemiss/myPayments';
import Description from './views/landingPage/description';
import AppDescription from './views/landingPage/appDescription';
import LandingLoginPage from './views/landingPage/loginPage';
import ForgotPassword from './views/forgotPassword';
import RecoveryPassword from './views/recoveryPassword';
import DoctorWorkingHours from './views/referral/calendarViews/doctorWorkingHours';
import GroupStatistics from './views/dataManagment/groupStatistics';
import Statistics from './views/dataManagment/statistics';
import ClinicStatistics from './views/user/groupStatistics';
import DataUsage from './views/user/dataUsegeClinicGroup';
import Swish from "./views/swish";
import VideoCallRatings from './views/logs/videoCallRatings';
import VideoCallLogs from './views/logs/videoCallLogs';
import Payson from './components/payson';
import Transaction from './views/transactionId';
import sentSupport from './views/user/sentSupport';
import AppBuilderTest from './views/user/appBuilderTest';
import allCases from './views/egenRemiss/allCases';
import VideoCallRedirect from './components/videoCallRedirect';
import SystemMonitoring from './views/dataManagment/systemMonitoring';
import MediaGateway from './views/mediaGateway';
import QAModule from './views/qa/qa';
import ChatRatings from './views/chat/chatRatings';
import allSupportTickets from './views/user/allSupportTickets';
import WebDocAuth from './views/webDocAuth';
import TempPage from './views/tempPage';
import TestPage from './views/forms/test';
import VideoCallStarted from './components/videoCallStarted';
import ErrorBoundary from './views/ErrorBoundary';
import TakeCareErrorsLog from './views/TakeCareErrorsLog';
import SembleErrorLogs from './views/SembleErrorLogs';
import TwoFactorAuthRegistrationRequests from './views/patients/TwoFactorAuthRegistrationRequests';
import MainClinicGroupsStatistic from './views/dataManagment/mainClinicGroupsStatistic';
import EconnectCreatedCasesPage from './views/e-connect/EconnectCreatedCasesPage';
import EconnectReceivedCasesPage from './views/e-connect/EconnectReceivedCasesPage';
import EconnectConsultationPage from './views/e-connect/EconnectConsultationPage';
import EconnectExternalPage from './views/e-connect/EconnectExternalPage';
import PatientDiaryGraph from './views/patients/components/patientDiaryGraph';

import StatmentPage from './views/user/statementPage';
import RedirectFormPage from './views/forms/redirectFormPage';
import VideoCallErrorLog from './views/logs/videoCallErrorLog';
import webDocCronLogs from './views/logs/webDocCronLogs';
import WaitingRoom from './views/chat/waitingRoom';

function iosDevice() {
    //return true;
    var userAgent = window.navigator.userAgent;
    if (userAgent.match(/iPad/i) || userAgent.match(/iPhone/i)) {
        return true;
    }
    else {
        return false;
    }
}


function parseJSON(response) {
    return response.json().then(json => {
        return {
            result: json,
            status: response.status
        }
    })
}


export const routes = [

    {
        path: "/",
        component: Clinics,

        loginNeeded: true,
        preAuthComponent: /*HomePage*/Login,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Curoflow'.translate(lang),
                description: ''

            }
        },
        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }
                if (query['_query-region']) {
                    queryFields['region'] = query['_query-region'];
                }

                if (query['_query-group']) {
                    queryFields['group'] = query['_query-group'];
                }

                return fetchFunction(API_ENDPOINT + '/data/clinics', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        entries: query.entries,
                        filter: query.filter,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        query: queryFields,
                        'client-location': query['client-location'],
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300) {
                        for (let i = 0; i < result.items.length; i++) {
                            if (result.items[i].vantetiderName) {
                                result.items[i].vantetiderName = result.items[i].vantetiderName.replace(/\&amp;/g, '&');
                            }
                        }
                        return {
                            page: query.page ? query.page : 0,
                            items: result.items,
                            total: result.total,
                            debugQuery: result.debugQuery,
                            _clinicSearchTs: query._ts
                        }
                    }

                })

            },
            (fetchFunction, lang, match, query) => {


                return fetchFunction(API_ENDPOINT + '/data/regions', {
                    method: 'GET',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            regions: result,
                        }
                })

            },
            (fetchFunction, lang, match, query) => {


                return fetchFunction(API_ENDPOINT + '/data/groups', {
                    method: 'GET',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            groups: result,
                        }
                })

            },
            (fetchFunction, lang, match, query) => {
                let queryFields = {};

                if (query['_query-region']) {
                    queryFields = { region: query['_query-region'] };
                }


                return fetchFunction(API_ENDPOINT + '/data/cities', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        query: queryFields,
                        sortField: 'name',
                        sortType: 1
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            cities: result,
                        }
                })

            },
            (fetchFunction, lang, match, query) => {
                let queryFields = {};

                if (query['_query-group']) {
                    queryFields = { group: query['_query-group'] };
                }


                return fetchFunction(API_ENDPOINT + '/data/subgroups/nonempty', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        query: queryFields,
                        sortField: 'name',
                        sortType: 1

                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            subgroups: result,
                        }
                })

            },
            (fetchFunction, lang, match, query) => {

                return fetchFunction(API_ENDPOINT + '/data/subgroups', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        sortField: 'name',
                        sortType: 1

                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            formSubgroups: result,
                        }
                })

            },
            (fetchFunction, lang, match, query) => {

                return fetchFunction(API_ENDPOINT + '/data/tags', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        sortField: 'name',
                        sortType: 1

                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            formTags: result,
                        }
                })

            },

        ]

    },

    {

        path: "/refresh/page",
        component: TempPage,
        // loginNeeded: true,
        // preAuthComponent: Login,
        disableAvailablePaths: true,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Refresh page'.translate(lang),
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: []

    },


    {
        path: "/login",
        component: Login,
        //redirectUser: '/',
        disableAvailablePaths: true,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Curoflow'.translate(lang),
                description: ''

            }
        },
        loadData: [

        ]

    },
    {
        path: "/verification-code",
        component: VerificationCodePage,
        //redirectUser: '/',
        disableAvailablePaths: true,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Curoflow'.translate(lang),
                description: ''

            }
        },
        loadData: [

        ]

    },

    {
        path: "/user/manual",
        component: UserManualPage,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang) => {
            return {
                title: 'User manual'.translate(lang) /*+ ' - Curoflow'.translate(lang)*/,
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [


        ]
    },
    {
        path: "/home-patient-level",
        component: HomePatientLevel,
        loginNeeded: true,
        preAuthComponent: Login,
        //redirectUser: '/',
        disableAvailablePaths: true,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Curoflow'.translate(lang),
                description: ''

            }
        },
        loadData: [

        ],
        loadDataWithQuery: [

        ]

    },
    {
        path: "/notifications",
        component: Notifications,
        loginNeeded: true,
        preAuthComponent: Login,
        //redirectUser: '/',
        //disableAvailablePaths: true,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Curoflow'.translate(lang),
                description: ''

            }
        },
        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {

                return fetchFunction(API_ENDPOINT + '/notification/notifications', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.pageNumber,
                        profile: query.profile
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            notificationsAll: result.items

                        }
                })

            },
        ]

    },
    {
        path: "/app-builder",
        component: AppBuilderTest,
        //loginNeeded: true,
        //preAuthComponent: Login,
        //redirectUser: '/',
        disableAvailablePaths: true,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Curoflow'.translate(lang),
                description: ''

            }
        },
        loadData: [

        ],
        loadDataWithQuery: [

        ]

    },

    {
        path: "/support",
        component: Support,
        loginNeeded: true,
        preAuthComponent: Login,
        //redirectUser: '/',
        //disableAvailablePaths: true,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Support'.translate(lang) /*+ ' - Curoflow'.translate(lang)*/,


            }
        },
        loadData: [

        ],
        loadDataWithQuery: [
            // (fetchFunction, lang, match, query) => {

            //     return fetchFunction(API_ENDPOINT + '/support-category/all', {
            //         method: 'POST',
            //         headers: {
            //             'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            //             'content-type': 'application/json'
            //         },
            //         body: JSON.stringify({

            //         })
            //     }).then(parseJSON).then(({ result, status }) => {
            //         if (status >= 200 && status < 300)
            //             return {
            //                 items: result

            //             }
            //     })

            // },

            // (fetchFunction, lang, match, query) => {

            //     return fetchFunction(API_ENDPOINT + '/support-category/all-messages', {
            //         method: 'POST',
            //         headers: {
            //             'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            //             'content-type': 'application/json'
            //         },
            //         body: JSON.stringify({

            //         })
            //     }).then(parseJSON).then(({ result, status }) => {
            //         if (status >= 200 && status < 300)
            //             return {
            //                 messages: result

            //             }
            //     })

            // },
            // (fetchFunction, lang, match, query) => {

            //     return fetchFunction(API_ENDPOINT + '/support-category/all-tikets/doctor', {
            //         method: 'POST',
            //         headers: {
            //             'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            //             'content-type': 'application/json'
            //         },
            //         body: JSON.stringify({

            //         })
            //     }).then(parseJSON).then(({ result, status }) => {
            //         if (status >= 200 && status < 300)
            //             return {
            //                 doctorTikets: result

            //             }
            //     })

            // },
            // (fetchFunction, lang, match, query) => {

            //     return fetchFunction(API_ENDPOINT + '/support-category/all-tikets/assigned', {
            //         method: 'POST',
            //         headers: {
            //             'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            //             'content-type': 'application/json'
            //         },
            //         body: JSON.stringify({

            //         })
            //     }).then(parseJSON).then(({ result, status }) => {
            //         if (status >= 200 && status < 300)
            //             return {
            //                 assignedTickets: result

            //             }
            //     })

            // },
        ]

    },
    {
        path: "/support-sent",
        component: sentSupport,
        loginNeeded: true,
        preAuthComponent: Login,
        //redirectUser: '/',
        //disableAvailablePaths: true,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Curoflow'.translate(lang),
                description: ''

            }
        },
        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {

                return fetchFunction(API_ENDPOINT + '/support-category/all', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({

                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            items: result

                        }
                })

            },
            // (fetchFunction, lang, match, query) => {

            //     return fetchFunction(API_ENDPOINT + '/support-category/all-messages', {
            //         method: 'POST',
            //         headers: {
            //             'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            //             'content-type': 'application/json'
            //         },
            //         body: JSON.stringify({

            //         })
            //     }).then(parseJSON).then(({ result, status }) => {
            //         if (status >= 200 && status < 300)
            //             return {
            //                 messages: result

            //             }
            //     })

            // },
            (fetchFunction, lang, match, query) => {

                return fetchFunction(API_ENDPOINT + '/support-category/all-tikets/doctor', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.pageNumber,
                        group: query.group,
                        clinic: query.clinic
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            doctorTikets: result.items

                        }
                })

            },
            // (fetchFunction, lang, match, query) => {

            //     return fetchFunction(API_ENDPOINT + '/support-category/all-tikets/assigned', {
            //         method: 'POST',
            //         headers: {
            //             'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            //             'content-type': 'application/json'
            //         },
            //         body: JSON.stringify({

            //         })
            //     }).then(parseJSON).then(({ result, status }) => {
            //         if (status >= 200 && status < 300)
            //             return {
            //                 assignedTickets: result

            //             }
            //     })

            // },
        ]

    },
    {
        path: "/support-received",
        component: ReceivedSupport,
        loginNeeded: true,
        preAuthComponent: Login,
        //redirectUser: '/',
        //disableAvailablePaths: true,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Curoflow'.translate(lang),
                description: ''

            }
        },
        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/support-category/all-tikets', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        group: query.group,
                        alias: query.alias,
                        page: query.pageNumber
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            tikets: result.items,
                            total: result.total
                        }
                })

            },
        ]

    },
    {
        path: "/support-assigned",
        component: SupportAssined,
        loginNeeded: true,
        preAuthComponent: Login,
        //redirectUser: '/',
        //disableAvailablePaths: true,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Curoflow'.translate(lang),
                description: ''

            }
        },
        loadData: [

        ],
        loadDataWithQuery: [
            // (fetchFunction, lang, match, query) => {

            //     return fetchFunction(API_ENDPOINT + '/support-category/all', {
            //         method: 'POST',
            //         headers: {
            //             'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            //             'content-type': 'application/json'
            //         },
            //         body: JSON.stringify({

            //         })
            //     }).then(parseJSON).then(({ result, status }) => {
            //         if (status >= 200 && status < 300)
            //             return {
            //                 items: result

            //             }
            //     })

            // },
            // (fetchFunction, lang, match, query) => {

            //     return fetchFunction(API_ENDPOINT + '/support-category/all-messages', {
            //         method: 'POST',
            //         headers: {
            //             'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            //             'content-type': 'application/json'
            //         },
            //         body: JSON.stringify({

            //         })
            //     }).then(parseJSON).then(({ result, status }) => {
            //         if (status >= 200 && status < 300)
            //             return {
            //                 messages: result

            //             }
            //     })

            // },
            // (fetchFunction, lang, match, query) => {

            //     return fetchFunction(API_ENDPOINT + '/support-category/all-tikets/doctor', {
            //         method: 'POST',
            //         headers: {
            //             'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            //             'content-type': 'application/json'
            //         },
            //         body: JSON.stringify({

            //         })
            //     }).then(parseJSON).then(({ result, status }) => {
            //         if (status >= 200 && status < 300)
            //             return {
            //                 doctorTikets: result

            //             }
            //     })

            // },
            (fetchFunction, lang, match, query) => {

                return fetchFunction(API_ENDPOINT + '/support-category/all-tikets/assigned', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.pageNumber
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            assignedTickets: result.items,
                            total: result.total
                        }
                })

            },
        ]

    },

    {
        path: "/support-category",
        component: SupportCategory,
        loginNeeded: true,
        preAuthComponent: Login,
        //redirectUser: '/',
        //disableAvailablePaths: true,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Support category'.translate(lang) /*+ ' - Curoflow'.translate(lang)*/,
                description: ''

            }
        },
        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/support-categories', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        entries: query.entries,
                        filter: query.filter,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        query: queryFields
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            page: query.page ? query.page : 0,
                            items: result.items,
                            total: result.total,
                            resultFilter: result.filter
                        }
                })

            },
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/support-conclusions', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        entries: query.entries,
                        filter: query.filter,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        query: queryFields
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            page: query.page ? query.page : 0,
                            conclusionsItems: result.items,
                            total: result.total,
                            resultFilter: result.filter
                        }
                })

            },
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/support-criticality', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        entries: query.entries,
                        filter: query.filter,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        query: queryFields
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            page: query.page ? query.page : 0,
                            criticalityItems: result.items,
                            total: result.total,
                            resultFilter: result.filter
                        }
                })

            }
        ]

    },
    {
        path: "/support-archive",
        component: SupportArchive,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Support archive'.translate(lang) /*+ ' - Curoflow'.translate(lang)*/,
                description: ''

            }
        },
        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/support-category/closed-tickets', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        // group: query.group
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            items: result

                        }
                })

            },
            // (fetchFunction, lang, match, query) => {

            //     return fetchFunction(API_ENDPOINT + '/support-category/all-messages', {
            //         method: 'POST',
            //         headers: {
            //             'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            //             'content-type': 'application/json'
            //         },
            //         body: JSON.stringify({

            //         })
            //     }).then(parseJSON).then(({ result, status }) => {
            //         if (status >= 200 && status < 300)
            //             return {
            //                 messages: result

            //             }
            //     })

            // },
        ]

    },
    {
        path: "/all-support-tickets",
        component: allSupportTickets,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang) => {
            return {
                title: 'All Support'.translate(lang) /*+ ' - Curoflow'.translate(lang)*/,
                description: ''

            }
        },
        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/support/all-support-ticket', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        entries: query.entries,
                        filter: query.filter,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        query: queryFields
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            page: query.page ? query.page : 0,
                            items: result.items,
                            total: result.total,
                            resultFilter: result.filter
                        }
                })

            },
        ]

    },



    {
        path: "/patient-error",
        component: PatientError,
        //loginNeeded: true,
        preAuthComponent: Login,
        //redirectUser: '/',
        disableAvailablePaths: true,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Curoflow Error'.translate(lang),
                description: ''

            }
        },
        loadData: [

        ],
        loadDataWithQuery: [

        ]

    },
    {
        path: "/forgotpassword",
        component: ForgotPassword,
        disableAvailablePaths: true,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Curoflow'.translate(lang),
                description: ''

            }
        },
        loadData: [

        ]

    },

    {
        path: "/webdoc/auth",
        component: WebDocAuth,
        disableAvailablePaths: true,
        generateSeoTags: (data, lang) => {
            return {
                title: 'WebDoc auth'.translate(lang) /*+ ' - Curoflow'.translate(lang)*/,
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [

        ]
    },
    {
        path: "/bankid/v2/modal/:loginStorageKey",
        component: BankId,
        disableAvailablePaths: true,
        generateSeoTags: (data, lang) => {
            return {
                title: 'BankId'.translate(lang) /*+ ' - Curoflow'.translate(lang)*/,
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [

        ]
    },



    {
        path: "/bankid-identity/v2/:loginStorageKey",
        component: BankIdIdentityResponse,
        disableAvailablePaths: true,
        generateSeoTags: (data, lang) => {
            return {
                title: 'BankId'.translate(lang) /*+ ' - Curoflow'.translate(lang)*/,
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [

        ]
    },

    {
        path: "/bankid/v2/:loginStorageKey",
        component: BankId,
        disableAvailablePaths: true,
        generateSeoTags: (data, lang) => {
            return {
                title: 'BankId'.translate(lang) /*+ ' - Curoflow'.translate(lang)*/,
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [

        ]
    },
    {
        path: "/bankid/sign/v2/:loginStorageKey",
        component: SignBankId,
        disableAvailablePaths: true,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Sign BankId'.translate(lang) /*+ ' - Curoflow'.translate(lang)*/,
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [

        ]
    },
    {
        path: "/check-transaction/:id",
        component: Transaction,
        disableAvailablePaths: true,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Transaction'.translate(lang) /*+ ' - Curoflow'.translate(lang)*/,
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [

        ]
    },
    {
        path: "/confirmation/managerId/:key",
        component: ManagerID,
        disableAvailablePaths: true,
        generateSeoTags: (data, lang) => {
            return {
                title: 'ManagerId'.translate(lang) /*+ ' - Curoflow'.translate(lang)*/,
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [

        ]
    },
    {
        path: "/forgotpassword/password-recovery/:group/:key",
        component: RecoveryPassword,
        disableAvailablePaths: true,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Password recovery'.translate(lang) /*+ ' - Curoflow'.translate(lang)*/,
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [

        ]
    },
    {
        path: "/netid/:loginStorageKey",
        component: NetId,
        disableAvailablePaths: true,
        generateSeoTags: (data, lang) => {
            return {
                title: 'NetId'.translate(lang) /*+ ' - Curoflow'.translate(lang)*/,
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [

        ]
    },
    {
        path: "/netid/sign/:loginStorageKey",
        component: NetIdSign,
        disableAvailablePaths: true,
        generateSeoTags: (data, lang) => {
            return {
                title: 'NetId'.translate(lang) /*+ ' - Curoflow'.translate(lang)*/,
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [

        ]
    },
    {
        path: "/data-managment/landing-builder-styles/:id",
        component: AddLandingStyleV2,
        loginNeeded: true,
        preAuthComponent: Login,
        disableAvailablePaths: true,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Landing style'.translate(lang) /*+ ' - Curoflow'.translate(lang)*/,
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/data/clinic-groups/all', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({})
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            clinicGroupsList: result
                        }
                })

            }
        ]
    },
    {
        path: "/data-managment/system-monitoring",
        component: SystemMonitoring,
        loginNeeded: true,
        preAuthComponent: Login,
        disableAvailablePaths: true,
        generateSeoTags: (data, lang) => {
            return {
                title: 'System monitoring'.translate(lang) /*+ ' - Curoflow'.translate(lang)*/,
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [

        ]
    },
    {
        path: "/data-managment/landing-styles/:id",
        component: AddLandingStyle,
        loginNeeded: true,
        preAuthComponent: Login,
        disableAvailablePaths: true,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Landing style'.translate(lang) /*+ ' - Curoflow'.translate(lang)*/,
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [
            // (fetchFunction, lang, match, query) => {

            //     let queryFields = {};
            //     for (var key in query) {
            //         if (key && key.indexOf('query-') === 0) {
            //             queryFields[key.replace('query-', '')] = query[key];
            //         }
            //     }

            //     return fetchFunction(API_ENDPOINT + '/data/groups', {
            //         method: 'POST',
            //         headers: {
            //             'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            //             'content-type': 'application/json'
            //         },
            //         body: JSON.stringify({
            //             page: query.page,
            //             entries: query.entries,
            //             filter: query.filter,
            //             sortType: query.sortType,
            //             sortField: query.sortField,
            //             query: queryFields
            //         })
            //     }).then(parseJSON).then(({ result, status }) => {
            //         if (status >= 200 && status < 300)
            //             return {
            //                 page: query.page ? query.page : 0,
            //                 items: result.items,
            //                 total: result.total,
            //             }
            //     })

            // }
        ]
    },


    {
        path: "/data-managment/landing-builder-styles",
        component: LandingStylesV2,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Landing templates'.translate(lang) /*+ ' - Curoflow'.translate(lang)*/,
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/data-managment/landing-builder-styles', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        entries: query.entries,
                        filter: query.filter,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        query: queryFields
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            page: query.page ? query.page : 0,
                            items: result.items,
                            total: result.total,
                            resultFilter: result.filter
                        }
                })

            },
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/data/clinic-groups/all', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            clinicGroups: result
                        }
                })

            }
        ]
    },


    {
        path: "/data-managment/groups",
        component: Groups,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Groups'.translate(lang) /*+ ' - Curoflow'.translate(lang)*/,
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/data/groups', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        entries: query.entries,
                        filter: query.filter,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        query: queryFields
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            page: query.page ? query.page : 0,
                            items: result.items,
                            total: result.total,
                            resultFilter: result.filter
                        }
                })

            }
        ]
    },
    {
        path: "/data-managment/medical-workers-categories",
        component: MedicalWorkersCategories,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Medical workers categories'.translate(lang) /*+ ' - Curoflow'.translate(lang)*/,
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/data/medical-workers-categories', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        entries: query.entries,
                        filter: query.filter,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        query: queryFields
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            page: query.page ? query.page : 0,
                            items: result.items,
                            total: result.total,
                            resultFilter: result.filter
                        }
                })

            }
        ]
    },

    {
        path: "/data-managment/user-notifications",
        component: UserNotifications,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Groups'.translate(lang) /*+ ' - Curoflow'.translate(lang)*/,
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/data/notificationsSettings', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        entries: query.entries,
                        filter: query.filter,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        query: queryFields,
                        receiverTab: query.receiverTab
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            page: query.page ? query.page : 0,
                            items: result.items,
                            total: result.total,
                            resultFilter: result.filter
                        }
                })

            },
            (fetchFunction, lang, match, query) => {


                return fetchFunction(API_ENDPOINT + '/languages', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        sortType: 1,
                        sortField: 'key',
                        query: {}
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            languages: result
                        }
                })

            },
        ]
    },
    {
        path: "/data-managment/icd10",
        component: Icd10,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Codes'.translate(lang) /*+ ' - Curoflow'.translate(lang)*/,
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/data/icd10', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        entries: query.entries,
                        filter: query.filter,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        query: queryFields
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            page: query.page ? query.page : 0,
                            items: result.items,
                            total: result.total,
                            resultFilter: result.filter
                        }
                })

            }
        ]
    },


    {
        path: "/data-managment/user-manual/en",
        component: UserManualPageEn,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang) => {
            return {
                title: 'User manual editor'.translate(lang) /*+ ' - Curoflow'.translate(lang)*/,
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [


        ]
    },

    {
        path: "/data-managment/user-manual",
        component: UserManualEditor,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang) => {
            return {
                title: 'User manual editor'.translate(lang) /*+ ' - Curoflow'.translate(lang)*/,
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [


        ]
    },

    {
        path: "/data-managment/form-importer/:id",
        component: FormBuilder,
        loginNeeded: true,
        preAuthComponent: Login,
        disableAvailablePaths: true,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Form builder'.translate(lang),
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/data/clinic-groups/all', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({})
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            clinicGroupsList: result
                        }
                })

            },


        ]
    },
    {
        path: "/data-managment/form-importer",
        component: FormImporter,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Form importer'.translate(lang) /*+ ' - Curoflow'.translate(lang)*/,
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/data/clinic-groups/all', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        checkForms: true
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            clinicGroups: result
                        }
                })

            },
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/data/form-importer', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        entries: query.entries,
                        filter: query.filter,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        query: queryFields,
                        formType: query.formType
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            page: query.page ? query.page : 0,
                            items: result.items,
                            total: result.total,
                            resultFilter: result.filter
                        }
                })

            },

        ]
    },
    {
        path: "/data-managment/general-health-care",
        component: GeneralHealthCare,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Codes'.translate(lang) /*+ ' - Curoflow'.translate(lang)*/,
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/data/general-health-care', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        entries: query.entries,
                        filter: query.filter,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        query: queryFields,
                        group: null,
                        clinic: null
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            page: query.page ? query.page : 0,
                            items: result.items,
                            total: result.total,
                        }
                })

            },

        ]
    },

    {
        path: "/data-managment/personal-referrals",
        component: PersonalReferrals,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Codes'.translate(lang) /*+ ' - Curoflow'.translate(lang)*/,
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/data/personal-questionary', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        entries: query.entries,
                        filter: query.filter,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        query: queryFields,
                        group: null,
                        clinic: null
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            page: query.page ? query.page : 0,
                            items: result.items,
                            total: result.total,
                        }
                })

            },

        ]
    },



    {
        path: "/data-managment/categories",
        component: Categories,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Categories'.translate(lang) /*+ ' - Curoflow'.translate(lang)*/,
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/data/categories', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        entries: query.entries,
                        filter: query.filter,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        query: queryFields
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            page: query.page ? query.page : 0,
                            items: result.items,
                            total: result.total,
                            resultFilter: result.filter
                        }
                })

            },
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/data/subgroups/nonempty', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: null,
                        entries: null,
                        filter: null,
                        sortType: 1,
                        sortField: 'name',
                        query: {}
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            subgroups: result,
                        }
                })

            },
        ]
    },


    {
        path: "/data-managment/subcategories",
        component: SubCategories,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Subcategories'.translate(lang) /*+ ' - Curoflow'.translate(lang)*/,
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/data/subcategories', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        entries: query.entries,
                        filter: query.filter,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        query: queryFields
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            page: query.page ? query.page : 0,
                            items: result.items,
                            total: result.total,
                            resultFilter: result.filter
                        }
                })

            },
            (fetchFunction, lang, match, query) => {
                return fetchFunction(API_ENDPOINT + '/data/categories', {
                    method: 'GET',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            categories: result
                        }
                })

            },
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/data/subgroups/nonempty', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: null,
                        entries: null,
                        filter: null,
                        sortType: 1,
                        sortField: 'name',
                        query: {}
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            subgroups: result,
                        }
                })

            },
        ]
    },
    {
        path: "/data-managment/subgroups",
        component: SubGroups,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Subgroups'.translate(lang) /*+ ' - Curoflow'.translate(lang)*/,
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/data/subgroups', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        entries: query.entries,
                        filter: query.filter,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        query: queryFields
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            page: query.page ? query.page : 0,
                            items: result.items,
                            total: result.total,
                            resultFilter: result.filter
                        }
                })

            },
            (fetchFunction, lang, match, query) => {
                return fetchFunction(API_ENDPOINT + '/data/groups', {
                    method: 'GET',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            groups: result
                        }
                })

            }
        ]
    },
    {
        path: "/data-managment/regions",
        component: Regions,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Regions'.translate(lang) /*+ ' - Curoflow'.translate(lang)*/,
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/data/regions', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        entries: query.entries,
                        filter: query.filter,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        query: queryFields
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            page: query.page ? query.page : 0,
                            items: result.items,
                            total: result.total,
                            resultFilter: result.filter
                        }
                })

            }
        ]
    },
    {
        path: "/data-managment/clinics",
        component: Clinics,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Clinics'.translate(lang) /*+ ' - Curoflow'.translate(lang)*/,
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }
                if (query['_query-region']) {
                    queryFields['region'] = query['_query-region'];
                }

                if (query['_query-group']) {
                    queryFields['group'] = query['_query-group'];
                }

                return fetchFunction(API_ENDPOINT + '/data/clinics', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        entries: query.entries,
                        filter: query.filter,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        query: queryFields,
                        'client-location': query['client-location'],


                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300) {
                        /*for (let i = 0; i < result.items.length; i++) {
                            if (result.items[i].vantetiderName) {
                                result.items[i].vantetiderName = result.items[i].vantetiderName.replace(/\&amp;/g, '&');
                            }
                        }*/
                        return {
                            page: query.page ? query.page : 0,
                            items: result.items,
                            total: result.total,
                            debugQuery: result.debugQuery,
                            _clinicSearchTs: query._ts
                        }
                    }
                })

            },
            (fetchFunction, lang, match, query) => {


                return fetchFunction(API_ENDPOINT + '/data/regions', {
                    method: 'GET',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            regions: result,
                        }
                })

            },
            (fetchFunction, lang, match, query) => {


                return fetchFunction(API_ENDPOINT + '/data/clinics/admins', {
                    method: 'GET',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            formAdmins: result,
                        }
                })

            },

            (fetchFunction, lang, match, query) => {


                return fetchFunction(API_ENDPOINT + '/data/groups', {
                    method: 'GET',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            groups: result,
                        }
                })

            },
            (fetchFunction, lang, match, query) => {
                let queryFields = {};

                if (query['_query-region']) {
                    queryFields = { region: query['_query-region'] };
                }


                return fetchFunction(API_ENDPOINT + '/data/cities', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        query: queryFields
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            cities: result,
                        }
                })

            },
            (fetchFunction, lang, match, query) => {
                let queryFields = {};

                if (query['_query-group']) {
                    queryFields = { group: query['_query-group'] };
                }


                return fetchFunction(API_ENDPOINT + '/data/subgroups', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        query: queryFields,
                        sortField: 'name',
                        sortType: 1

                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            subgroups: result,
                        }
                })

            },
            (fetchFunction, lang, match, query) => {

                return fetchFunction(API_ENDPOINT + '/data/subgroups', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        sortField: 'name',
                        sortType: 1

                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            formSubgroups: result,
                        }
                })

            },
            (fetchFunction, lang, match, query) => {

                return fetchFunction(API_ENDPOINT + '/data/tags', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({

                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            formTags: result,
                        }
                })

            },


        ]
    },
    {
        path: "/data-managment/articles",
        component: Articles,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Articles'.translate(lang) /*+ ' - Curoflow'.translate(lang)*/,
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/data/articles', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        entries: query.entries,
                        filter: query.filter,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        query: queryFields
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            page: query.page ? query.page : 0,
                            items: result.items,
                            total: result.total,
                            resultFilter: result.filter
                        }
                })

            },
            (fetchFunction, lang, match, query) => {

                return fetchFunction(API_ENDPOINT + '/data/groups', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        sortField: 'name',
                        sortType: 1,
                        query: {
                        }
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            groups: result
                        }
                })

            },
            (fetchFunction, lang, match, query) => {
                return fetchFunction(API_ENDPOINT + '/data/subgroups', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        sortField: 'name',
                        sortType: 1,

                        query: {

                        }
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            subgroups: result
                        }
                })

            },

        ]
    },

    {
        path: "/data-managment/important-information",
        component: ImportantInformation,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Important information'.translate(lang) /*+ ' - Curoflow'.translate(lang)*/,
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/data-managment/important-information', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        entries: query.entries,
                        filter: query.filter,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        query: queryFields,

                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            page: query.page ? query.page : 0,
                            items: result.items,
                            total: result.total,
                            resultFilter: result.filter
                        }
                })

            },


        ]
    },

    {
        path: "/data-managment/clinics-statistic/export",
        component: ExportClinicsStatistic,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Export clinics statistic'.translate(lang) /*+ ' - Curoflow'.translate(lang)*/,
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [



        ]
    },
    {
        path: "/data-managment/main-clinic-groups-statistic",
        component: MainClinicGroupsStatistic,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Export clinics statistic'.translate(lang) /*+ ' - Curoflow'.translate(lang)*/,
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/data/main-statistic/main-clinic-groups', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        entries: query.entries,
                        filter: query.filter,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        query: queryFields,

                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            page: query.page ? query.page : 0,
                            items: result.items,
                            total: result.total,
                            resultFilter: result.filter
                        }
                })

            },

        ]
    },
    {
        path: "/data-managment/clinic-groups",
        component: ClinicGroups,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Articles'.translate(lang) /*+ ' - Curoflow'.translate(lang)*/,
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/data/clinic-groups', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        entries: query.entries,
                        filter: query.filter,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        query: queryFields,
                        clinicIds: query.clinicIds
                        // startDateSms: query.startDateSms,
                        // endDateSms: query.endDateSms,
                        // startDateLogin: query.startDateLogin,
                        // endDateLogin: query.endDateLogin
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            page: query.page ? query.page : 0,
                            items: result.items,
                            total: result.total,
                            resultFilter: result.filter
                        }
                })

            },
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/data/clinic-groups/data-usage/time', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        entries: query.entries,
                        filter: query.filter,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        query: queryFields,
                        startDateSms: query.startDateSms,
                        endDateSms: query.endDateSms,
                        group: query.group
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            timeData: result.obj,
                            timeDataInterval: result.timeObj
                        }
                })

            },
            (fetchFunction, lang, match, query) => {
                return fetchFunction(API_ENDPOINT + '/modules/clinic', {
                    method: 'GET',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            clinicModules: result
                        }
                })

            },
            (fetchFunction, lang, match, query) => {
                return fetchFunction(API_ENDPOINT + '/data/statistic/main-clinic-groups', {
                    method: 'GET',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                }).then(parseJSON).then(({ result, status }) => {
                    console.log(result);
                    if (status >= 200 && status < 300)
                        return {
                            mainClinicGroups: result
                        }
                })

            },

        ]
    },
    {
        path: "/data-managment/clinic-groups/export",
        component: ClinicGroupsExport,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Export clinics statistcs'.translate(lang) /*+ ' - Curoflow'.translate(lang)*/,
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/data/main-clinic-groups/clinic-groups', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        entries: query.entries,
                        filter: query.filter,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        query: queryFields,
                        selectedMainClinicGroups: query.selectedMainClinicGroups
                        // startDateSms: query.startDateSms,
                        // endDateSms: query.endDateSms,
                        // startDateLogin: query.startDateLogin,
                        // endDateLogin: query.endDateLogin
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    console.log(result.filter);
                    if (status >= 200 && status < 300)
                        return {
                            page: query.page ? query.page : 0,
                            clinicIds: result.clinicIds,
                            items: result.items,
                            total: result.total,
                            resultFilter: result.filter
                        }
                })

            },
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/data/clinic-groups/data-usage/time', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        entries: query.entries,
                        filter: query.filter,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        query: queryFields,
                        startDateSms: query.startDateSms,
                        endDateSms: query.endDateSms,
                        group: query.group,
                        selectedMainClinicGroups: query.selectedMainClinicGroups
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            timeData: result.obj,
                            timeDataInterval: result.timeObj
                        }
                })

            },
            (fetchFunction, lang, match, query) => {
                return fetchFunction(API_ENDPOINT + '/data/statistic/main-clinic-groups', {
                    method: 'GET',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            mainClinicGroups: result
                        }
                })

            },


        ]
    },
    {
        path: "/data-managment/clinic-groups/data-used",
        component: DataUsage,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Data Used'.translate(lang) /*+ ' - Curoflow'.translate(lang)*/,
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/data/clinic-groups/data-usage', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        group: query.group
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            items: result
                        }
                })

            },
        ]
    },
    {
        path: "/data-managment/main-clinic-groups",
        component: MainClinicGroups,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Articles'.translate(lang) /*+ ' - Curoflow'.translate(lang)*/,
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/data/main-clinic-groups', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        entries: query.entries,
                        filter: query.filter,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        query: queryFields
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            page: query.page ? query.page : 0,
                            items: result.items,
                            total: result.total,
                            resultFilter: result.filter
                        }
                })

            },
            (fetchFunction, lang, match, query) => {
                return fetchFunction(API_ENDPOINT + '/modules/clinic', {
                    method: 'GET',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            clinicModules: result
                        }
                })

            },
            (fetchFunction, lang, match, query) => {
                return fetchFunction(API_ENDPOINT + '/data/main-clinic-groups/edit/login-text', {
                    method: 'GET',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            landingLoginInfo: result
                        }
                })

            },


        ]
    },
    {
        path: "/data-managment/group-permissions",
        component: GroupPermissions,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Articles'.translate(lang) /*+ ' - Curoflow'.translate(lang)*/,
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/data/group-permissions', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        entries: query.entries,
                        filter: query.filter,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        query: queryFields
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            page: query.page ? query.page : 0,
                            items: result.items,
                            total: result.total,
                            resultFilter: result.filter
                        }
                })

            },
            (fetchFunction, lang, match, query) => {
                return fetchFunction(API_ENDPOINT + '/modules/clinic', {
                    method: 'GET',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            formModules: result
                        }
                })

            },

        ]
    },
    {
        path: "/data-managment/group-statistics",
        component: GroupStatistics,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Articles'.translate(lang) + ' - RemissHjälpen'.translate(lang),
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/data/group-statistics', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        entries: query.entries,
                        filter: query.filter,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        query: queryFields,
                        group: query.group,
                        selectAllGroups: query.selectAllGroups,
                        dateFrom: query.dateFrom,
                        dateTo: query.dateTo
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            page: query.page ? query.page : 0,
                            items: result.items,
                            total: result.total,
                            resultFilter: result.filter
                        }
                })

            },

            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/data/clinic-groups/all', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            groups: result
                        }
                })

            }


        ]
    },
    {
        path: "/video-started-call/:id",
        component: VideoCallStarted,
        disableAvailablePaths: true,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Video Call Started'.translate(lang),
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [


        ]
    },
    {
        path: "/data-managment/statistics",
        component: Statistics,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Articles'.translate(lang) + ' - RemissHjälpen'.translate(lang),
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/data/all/statistics', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            groups: result
                        }
                })

            }


        ]
    },

    {
        path: "/daybook-events/list",
        component: DaybookList,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Articles'.translate(lang) /*+ ' - Curoflow'.translate(lang)*/,
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/data/daybook-events', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        entries: query.entries,
                        filter: query.filter,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        query: queryFields,
                        group: query.group,
                        clinic: query.clinic
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            page: query.page ? query.page : 0,
                            items: result.items,
                            total: result.total,
                            resultFilter: result.filter
                        }
                })

            },
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/data/clinic-groups/' + query.group, {
                    method: 'GET',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },

                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        console.log(result)
                    return {
                        clinicGroup: result
                    }
                })

            },
        ]
    },

    {
        path: "/chat-forms/list",
        component: ChatFormsList,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Articles'.translate(lang) /*+ ' - Curoflow'.translate(lang)*/,
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/data/chat-form-questionary', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        entries: query.entries,
                        filter: query.filter,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        query: queryFields,
                        group: query.group,
                        clinic: query.clinic
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            page: query.page ? query.page : 0,
                            items: result.items,
                            total: result.total,
                            resultFilter: result.filter
                        }
                })

            },
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/data/clinic-groups/' + query.group, {
                    method: 'GET',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },

                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        console.log(result)
                    return {
                        clinicGroup: result
                    }
                })

            },
        ]
    },
    {
        path: "/revisit-forms/list",
        component: RevisitFormsList,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Articles'.translate(lang) /*+ ' - Curoflow'.translate(lang)*/,
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/data/revisit-questionary', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        entries: query.entries,
                        filter: query.filter,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        query: queryFields,
                        group: query.group,
                        clinic: query.clinic
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            page: query.page ? query.page : 0,
                            items: result.items,
                            total: result.total,
                            resultFilter: result.filter,
                            keepingForms: result.keepingForms
                        }
                })

            },
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/data/clinic-groups/' + query.group, {
                    method: 'GET',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },

                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        console.log(result)
                    return {
                        clinicGroup: result
                    }
                })

            },
        ]
    },

    {
        path: "/personal-referrals/list",
        component: ReferralsList,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Articles'.translate(lang) /*+ ' - Curoflow'.translate(lang)*/,
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/data/personal-questionary', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        entries: query.entries,
                        filter: query.filter,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        query: queryFields,
                        group: query.group,
                        clinic: query.clinic,
                        tabIndex: query.tabIndex
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            page: query.page ? query.page : 0,
                            items: result.items,
                            total: result.total,
                            resultFilter: result.filter,
                            keepingForms: result.keepingForms
                        }
                })

            },
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/data/clinic-groups/' + query.group, {
                    method: 'GET',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },

                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            clinicGroup: result
                        }
                })

            },
        ]
    },


    {
        path: "/:alias/information",
        component: Description,
        //loginNeeded: true,
        preAuthComponent: Login,
        disableAvailablePaths: true,
        generateSeoTags: (data, lang) => {
            return {
                title: data?.seoData?.name ? 'Information'.translate(lang) + ' | ' + data?.seoData?.name : 'Information'.translate(lang),
                'og:image': data?.seoData?.logo ? API_ENDPOINT + data?.seoData?.logo : '',
                description: '',
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {


                return fetchFunction(API_ENDPOINT + '/landing/page', {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({ alias: match.params.alias })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            seoData: result,
                        }
                })

            },
        ]
    },
    {
        path: "/:alias/app-information",
        component: AppDescription,
        //loginNeeded: true,
        preAuthComponent: Login,
        disableAvailablePaths: true,
        generateSeoTags: (data, lang) => {
            return {
                title: data?.seoData?.name ? 'Information'.translate(lang) + ' | ' + data?.seoData?.name : 'Information'.translate(lang),
                'og:image': data?.seoData?.logo ? API_ENDPOINT + data?.seoData?.logo : '',
                description: '',
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {


                return fetchFunction(API_ENDPOINT + '/landing/page', {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({ alias: match.params.alias })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            seoData: result,
                        }
                })

            },
        ]
    },
    {
        path: "/:mainAlias/:alias/information",
        component: Description,
        //loginNeeded: true,
        preAuthComponent: Login,
        disableAvailablePaths: true,
        generateSeoTags: (data, lang) => {
            return {
                title: data?.seoData?.name ? 'Information'.translate(lang) + ' | ' + data?.seoData?.name : 'Information'.translate(lang),
                'og:image': data?.seoData?.logo ? API_ENDPOINT + data?.seoData?.logo : '',
                description: '',
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {


                return fetchFunction(API_ENDPOINT + '/landing/page', {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({ alias: match.params.alias })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            seoData: result,
                        }
                })

            },
        ]
    },
    {
        path: "/:mainAlias/:alias/app-information",
        component: AppDescription,
        //loginNeeded: true,
        preAuthComponent: Login,
        disableAvailablePaths: true,
        generateSeoTags: (data, lang) => {
            return {
                title: data?.seoData?.name ? 'Information'.translate(lang) + ' | ' + data?.seoData?.name : 'Information'.translate(lang),
                'og:image': data?.seoData?.logo ? API_ENDPOINT + data?.seoData?.logo : '',
                description: '',
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {


                return fetchFunction(API_ENDPOINT + '/landing/page', {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({ alias: match.params.alias })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            seoData: result,
                        }
                })

            },
        ]
    },
    {
        path: "/:alias/privacy-policy",
        component: PrivacyPolicy,
        //loginNeeded: true,
        preAuthComponent: Login,
        disableAvailablePaths: true,
        generateSeoTags: (data, lang) => {
            return {
                title: data?.seoData?.name ? 'Privacy policy'.translate(lang) + ' | ' + data?.seoData?.name : 'Privacy policy'.translate(lang),
                'og:image': data?.seoData?.logo ? API_ENDPOINT + data?.seoData?.logo : '',
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {


                return fetchFunction(API_ENDPOINT + '/landing/page', {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({ alias: match.params.alias })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            seoData: result,
                        }
                })

            },
        ]
    },
    {
        path: "/:alias/static/privacy-policy",
        component: PrivacyPolicyV2,
        //loginNeeded: true,
        preAuthComponent: Login,
        disableAvailablePaths: true,
        generateSeoTags: (data, lang) => {
            return {
                title: data?.seoData?.name ? 'Privacy policy'.translate(lang) + ' | ' + data?.seoData?.name : 'Privacy policy'.translate(lang),
                'og:image': data?.seoData?.logo ? API_ENDPOINT + data?.seoData?.logo : '',
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {


                return fetchFunction(API_ENDPOINT + '/landing/page', {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({ alias: match.params.alias })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            seoData: result,
                        }
                })

            },
        ]
    },
    {
        path: "/:alias/app-privacy-policy",
        component: AppPrivacyPolicy,
        //loginNeeded: true,
        preAuthComponent: Login,
        disableAvailablePaths: true,
        generateSeoTags: (data, lang) => {
            return {
                title: data?.seoData?.name ? 'Privacy policy'.translate(lang) + ' | ' + data?.seoData?.name : 'Privacy policy'.translate(lang),
                'og:image': data?.seoData?.logo ? API_ENDPOINT + data?.seoData?.logo : '',
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {


                return fetchFunction(API_ENDPOINT + '/landing/page', {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({ alias: match.params.alias })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            seoData: result,
                        }
                })

            },
        ]
    },
    {
        path: "/:alias/terms-of-use",
        component: TermsOfUse,
        //loginNeeded: true,
        preAuthComponent: Login,
        disableAvailablePaths: true,
        generateSeoTags: (data, lang) => {
            return {
                title: data?.seoData?.name ? 'Terms of use'.translate(lang) + ' | ' + data?.seoData?.name : 'Terms of use'.translate(lang),
                'og:image': data?.seoData?.logo ? API_ENDPOINT + data?.seoData?.logo : '',
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {


                return fetchFunction(API_ENDPOINT + '/landing/page', {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({ alias: match.params.alias })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            seoData: result,
                        }
                })

            },
        ]
    },
    {
        path: "/:alias/app-terms-of-use",
        component: AppTermsOfUse,
        //loginNeeded: true,
        preAuthComponent: Login,
        disableAvailablePaths: true,
        generateSeoTags: (data, lang) => {
            return {
                title: data?.seoData?.name ? 'Terms of use'.translate(lang) + ' | ' + data?.seoData?.name : 'Terms of use'.translate(lang),
                'og:image': data?.seoData?.logo ? API_ENDPOINT + data?.seoData?.logo : '',
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {


                return fetchFunction(API_ENDPOINT + '/landing/page', {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({ alias: match.params.alias })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            seoData: result,
                        }
                })

            },
        ]
    },
    {
        path: "/:alias/accessibility-report",
        component: AccessibilityReport,
        //loginNeeded: true,
        preAuthComponent: Login,
        disableAvailablePaths: true,
        generateSeoTags: (data, lang) => {
            return {
                title: data?.seoData?.name ? 'Accessibility Report'.translate(lang) + ' | ' + data?.seoData?.name : 'Accessibility Report'.translate(lang),
                'og:image': data?.seoData?.logo ? API_ENDPOINT + data?.seoData?.logo : '',
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {


                return fetchFunction(API_ENDPOINT + '/landing/page', {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({ alias: match.params.alias })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            seoData: result,
                        }
                })

            },
        ]
    },
    {
        path: "/:alias/app-accessibility-report",
        component: AppAccessibilityReport,
        //loginNeeded: true,
        preAuthComponent: Login,
        disableAvailablePaths: true,
        generateSeoTags: (data, lang) => {
            return {
                title: data?.seoData?.name ? 'Accessibility Report'.translate(lang) + ' | ' + data?.seoData?.name : 'Accessibility Report'.translate(lang),
                'og:image': data?.seoData?.logo ? API_ENDPOINT + data?.seoData?.logo : '',
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {


                return fetchFunction(API_ENDPOINT + '/landing/page', {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({ alias: match.params.alias })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            seoData: result,
                        }
                })

            },
        ]
    },
    {
        path: "/general-health-care/:id",
        component: HealthCareBuilder,
        loginNeeded: true,
        preAuthComponent: Login,
        disableAvailablePaths: true,
        generateSeoTags: (data, lang) => {
            return {
                title: 'General health-care form builder'.translate(lang),
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [

            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }



                return fetchFunction(API_ENDPOINT + '/users/subaccounts/doctors', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: null,
                        entries: null,
                        filter: query.filter,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        groupId: query.groupId,
                        clinic: query.clinic,
                        query: queryFields
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {

                            doctors: result,

                        }
                })

            },
        ]
    },

    {
        path: "/daybook-events/:id",
        component: DaybookBuilder,
        loginNeeded: true,
        preAuthComponent: Login,
        disableAvailablePaths: true,
        generateSeoTags: (data, lang) => {
            return {
                title: 'E-self-referrals form builder'.translate(lang),
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [

            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }



                return fetchFunction(API_ENDPOINT + '/users/subaccounts/doctors', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: null,
                        entries: null,
                        filter: query.filter,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        groupId: query.groupId,
                        clinic: query.clinic,
                        query: queryFields
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {

                            doctors: result,

                        }
                })


            },
            // //professions
            // (fetchFunction, lang, match, query) => {

            //     let queryFields = {};
            //     for (var key in query) {
            //         if (key && key.indexOf('query-') === 0) {
            //             queryFields[key.replace('query-', '')] = query[key];
            //         }
            //     }



            //     return fetchFunction(API_ENDPOINT + '/data/medical-workers-categories/all', {
            //         method: 'GET',
            //         headers: {
            //             'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            //             'content-type': 'application/json'
            //         }
            //     }).then(parseJSON).then(({ result, status }) => {
            //         if (status >= 200 && status < 300)
            //             return {

            //                 professions: result,

            //             }
            //     })


            // },

            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/data/daybook-events/number/new-forms', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        query: queryFields,
                        group: query.group,
                        clinic: query.clinic
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            personalQuestionaryNumberNew: result.newFormNumber
                        }
                })

            },
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/data/form-importer', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        entries: query.entries,
                        filter: query.filter,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        query: queryFields,
                        formType: query.formType,
                        group: query.groupId,
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            page: query.page ? query.page : 0,
                            importModalList: result.items,
                            total: result.total,
                        }
                })

            },
        ]
    },

    {
        path: "/chat-forms/:id",
        component: ChatFormsBuilder,
        loginNeeded: true,
        preAuthComponent: Login,
        disableAvailablePaths: true,
        generateSeoTags: (data, lang) => {
            return {
                title: 'E-self-referrals form builder'.translate(lang),
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [

            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }



                return fetchFunction(API_ENDPOINT + '/users/subaccounts/doctors', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: null,
                        entries: null,
                        filter: query.filter,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        groupId: query.groupId,
                        clinic: query.clinic,
                        query: queryFields
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {

                            doctors: result,

                        }
                })


            },

            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/data/chat-form-questionary/number/new-forms', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        query: queryFields,
                        group: query.group,
                        clinic: query.clinic
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            personalQuestionaryNumberNew: result.newFormNumber
                        }
                })

            },
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/data/form-importer', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        entries: query.entries,
                        filter: query.filter,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        query: queryFields,
                        formType: query.formType,
                        group: query.groupId,
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            page: query.page ? query.page : 0,
                            importModalList: result.items,
                            total: result.total,
                        }
                })

            },
        ]
    },
    {
        path: "/revisit-forms/:id",
        component: RevisitBuilder,
        loginNeeded: true,
        preAuthComponent: Login,
        disableAvailablePaths: true,
        generateSeoTags: (data, lang) => {
            return {
                title: 'E-self-referrals form builder'.translate(lang),
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [

            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }



                return fetchFunction(API_ENDPOINT + '/users/subaccounts/doctors', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: null,
                        entries: null,
                        filter: query.filter,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        groupId: query.groupId,
                        clinic: query.clinic,
                        query: queryFields
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {

                            doctors: result,

                        }
                })


            },
            // //professions
            // (fetchFunction, lang, match, query) => {

            //     let queryFields = {};
            //     for (var key in query) {
            //         if (key && key.indexOf('query-') === 0) {
            //             queryFields[key.replace('query-', '')] = query[key];
            //         }
            //     }



            //     return fetchFunction(API_ENDPOINT + '/data/medical-workers-categories/all', {
            //         method: 'GET',
            //         headers: {
            //             'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            //             'content-type': 'application/json'
            //         }
            //     }).then(parseJSON).then(({ result, status }) => {
            //         if (status >= 200 && status < 300)
            //             return {

            //                 professions: result,

            //             }
            //     })


            // },

            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/data/revisit-questionary/number/new-forms', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        query: queryFields,
                        group: query.group,
                        clinic: query.clinic
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            personalQuestionaryNumberNew: result.newFormNumber
                        }
                })

            },
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/data/form-importer', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        entries: query.entries,
                        filter: query.filter,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        query: queryFields,
                        formType: query.formType,
                        group: query.groupId,
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            page: query.page ? query.page : 0,
                            importModalList: result.items,
                            total: result.total,
                        }
                })

            },
        ]
    },
    {
        path: "/personal-referrals/:id",
        component: ReferralBuilder,
        loginNeeded: true,
        preAuthComponent: Login,
        disableAvailablePaths: true,
        generateSeoTags: (data, lang) => {
            return {
                title: 'E-self-referrals form builder'.translate(lang),
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [

            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }



                return fetchFunction(API_ENDPOINT + '/users/subaccounts/doctors', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: null,
                        entries: null,
                        filter: query.filter,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        groupId: query.groupId,
                        clinic: query.clinic,
                        query: queryFields
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {

                            doctors: result,

                        }
                })


            },
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }



                return fetchFunction(API_ENDPOINT + '/data/groups-professions/all', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({ group: query.groupId })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {

                            professions: result,

                        }
                })


            },

            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/data/personal-questionary/number/new-forms', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        query: queryFields,
                        group: query.group,
                        clinic: query.clinic
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            personalQuestionaryNumberNew: result.newFormNumber
                        }
                })

            },
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/data/form-importer', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        entries: query.entries,
                        filter: query.filter,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        query: queryFields,
                        formType: query.formType,
                        group: query.groupId,
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            page: query.page ? query.page : 0,
                            importModalList: result.items,
                            total: result.total,
                        }
                })

            },
        ]
    },
    {
        path: "/create-personal-referrals/:id",
        component: ReferralBuilder,
        loginNeeded: true,
        preAuthComponent: Login,
        disableAvailablePaths: true,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Articles'.translate(lang) /*+ ' - Curoflow'.translate(lang)*/,
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [
        ]
    },


    {
        path: "/data-managment/prepare-questionary",
        component: PrepareQuestionary,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Articles'.translate(lang) /*+ ' - Curoflow'.translate(lang)*/,
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {
                let queryFields = {};

                return fetchFunction(API_ENDPOINT + '/data/articles', {
                    method: 'GET',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            articles: result,
                        }
                })

            },
            (fetchFunction, lang, match, query) => {

                return fetchFunction(API_ENDPOINT + '/data/tags', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        sortField: 'name',
                        sortType: 1,
                        query: {}
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            formTags: result
                        }
                })

            },

        ]
    },
    {
        path: "/create-referral",
        component: CreateRefferal,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Articles'.translate(lang) /*+ ' - Curoflow'.translate(lang)*/,
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {
                let queryFields = {};

                return fetchFunction(API_ENDPOINT + '/data/articles', {
                    method: 'GET',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)

                        return {
                            articles: result,
                        }
                })

            },

        ]
    },

    {
        path: "/data-managment/wiki",
        component: Wiki,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Wiki'.translate(lang) /*+ ' - Curoflow'.translate(lang)*/,
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/data/wiki', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        entries: query.entries,
                        filter: query.filter,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        query: queryFields
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            page: query.page ? query.page : 0,
                            items: result.items,
                            total: result.total,
                            resultFilter: result.filter
                        }
                })

            }
        ]
    },
    {
        path: "/data-managment/region-managers",
        component: regionManagers,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Region Manager'.translate(lang) /*+ ' - Curoflow'.translate(lang)*/,
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/data/region-managers', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        entries: query.entries,
                        filter: query.filter,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        query: queryFields
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            page: query.page ? query.page : 0,
                            items: result.items,
                            total: result.total,
                            resultFilter: result.filter
                        }
                })

            },
            (fetchFunction, lang, match, query) => {
                let queryFields = {};

                return fetchFunction(API_ENDPOINT + '/data/regions', {
                    method: 'GET',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            regions: result,
                        }
                })

            },
            (fetchFunction, lang, match, query) => {
                let queryFields = {};

                return fetchFunction(API_ENDPOINT + '/data/subgroups', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            subgroups: result,
                        }
                })

            },
        ]
    },

    {
        path: "/logs/group-logs",
        component: GroupLogs,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Region Manager'.translate(lang) /*+ ' - Curoflow'.translate(lang)*/,
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [

            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/data/group/system-logs', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        entries: query.entries,
                        filter: query.filter,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        group: query.group,
                        startDate: query.startDate,
                        endDate: query.endDate,
                        query: queryFields
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            page: query.page ? query.page : 0,
                            items: result.items,
                            total: result.total,
                            resultFilter: result.filter
                        }
                })

            },
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/data/clinic-groups/all', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({})
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            clinicGroups: result
                        }
                })

            }
        ]
    },
    {
        path: "/logs/webdoc-cron",
        component: webDocCronLogs,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang) => {
            return {
                title: 'WebDoc cron logs'.translate(lang) /*+ ' - Curoflow'.translate(lang)*/,
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [

            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/data/webdoc-cron-logs', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        entries: query.entries,
                        filter: query.filter,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        onlyErrors: query.onlyErrors,
                        group: query.group,
                        query: queryFields
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            page: query.page ? query.page : 0,
                            items: result.items,
                            total: result.total,
                            resultFilter: result.filter
                        }
                })

            }

        ]
    },
    {
        path: "/logs/system-logs",
        component: Logs,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Region Manager'.translate(lang) /*+ ' - Curoflow'.translate(lang)*/,
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [

            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/data/system-logs', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        entries: query.entries,
                        filter: query.filter,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        group: query.group,
                        startDate: query.startDate,
                        endDate: query.endDate,
                        query: queryFields
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            page: query.page ? query.page : 0,
                            items: result.items,
                            total: result.total,
                            resultFilter: result.filter
                        }
                })

            },
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/data/clinic-groups/all', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({})
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            clinicGroups: result
                        }
                })

            }
        ]
    },
    {
        path: "/logs/video-call-ratings",
        component: VideoCallRatings,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Region Manager'.translate(lang) /*+ ' - Curoflow'.translate(lang)*/,
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/data/video-call-ratings', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        entries: query.entries,
                        filter: query.filter,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        query: queryFields
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            page: query.page ? query.page : 0,
                            items: result.items,
                            total: result.total,
                            resultFilter: result.filter
                        }
                })

            }
        ]
    },
    {
        path: "/logs/video-call-error-logs",
        component: VideoCallErrorLog,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang, langs = null) => {
            return {
                title: 'Video call error logs'.translate(lang),
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/video/call/error/log', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        entries: query.entries,
                        filter: query.filter,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        query: queryFields
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            page: query.page ? query.page : 0,
                            items: result.items,
                            total: result.total,
                        }
                })

            }
        ]
    },
    {
        path: '/error-boundary',
        component: ErrorBoundary,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Error Boundary Logs'.translate(lang),
                description: ''
            }
        },
        loadData: [],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {
                return fetchFunction?.(`${API_ENDPOINT}/error-boundary?page=${query?.page ?? 0}&entries=${query?.entries ?? 10}&sortField=${query?.sortField ?? '_id'}&sortType=${query?.sortType ?? -1}&filter=${query?.filter ?? ''}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                }).then(parseJSON).then(({ result, status }) => {
                    if (!result.error) return {
                        page: query.page ? query.page : 0,
                        items: result.items,
                        total: result.total,
                        resultFilter: result.filter
                    }
                    else return { error: result.error }
                })
            }
        ]
    },
    {
        path: '/take-care-errors',
        component: TakeCareErrorsLog,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Take Care Error Logs'.translate(lang),
                description: ''
            }
        },
        loadData: [],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {
                return fetchFunction?.(`${API_ENDPOINT}/take-care-errors?page=${query?.page ?? 0}&entries=${query?.entries ?? 10}&sortField=${query?.sortField ?? '_id'}&sortType=${query?.sortType ?? -1}&filter=${query?.filter ?? ''}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                }).then(parseJSON).then(({ result, status }) => {
                    if (!result.error) return {
                        page: query.page ? query.page : 0,
                        items: result.items,
                        total: result.total,
                        resultFilter: result.filter
                    }
                    else return { error: result.error }
                })
            }
        ]
    },
    {
        path: '/semble-errors',
        component: SembleErrorLogs,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Semble Error Logs'.translate(lang),
                description: ''
            }
        },
        loadData: [],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {
                return fetchFunction?.(`${API_ENDPOINT}/semble-errors?page=${query?.page ?? 0}&entries=${query?.entries ?? 10}&sortField=${query?.sortField ?? '_id'}&sortType=${query?.sortType ?? -1}&filter=${query?.filter ?? ''}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                }).then(parseJSON).then(({ result, status }) => {
                    if (!result.error) return {
                        page: query.page ? query.page : 0,
                        items: result.items,
                        total: result.total,
                        resultFilter: result.filter
                    }
                    else return { error: result.error }
                })
            }
        ]
    },
    {
        path: "/chat-ratings",
        component: ChatRatings,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Chat ratings'.translate(lang) /*+ ' - Curoflow'.translate(lang)*/,
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/data/conversationRatings', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        entries: query.entries,
                        filter: query.filter,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        query: queryFields,
                        selectedGroup: query.selectedGroup
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            page: query.page ? query.page : 0,
                            items: result.items,
                            total: result.total,
                            resultFilter: result.filter
                        }
                })

            },
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/data/clinic-groups/all', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({})
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            clinicGroups: result
                        }
                })

            }
        ]
    },
    {
        path: "/logs/video-call-logs",
        component: VideoCallLogs,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Region Manager'.translate(lang) /*+ ' - Curoflow'.translate(lang)*/,
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/data/video-call-logs', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        entries: query.entries,
                        filter: query.filter,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        query: queryFields
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            page: query.page ? query.page : 0,
                            items: result.items,
                            total: result.total,
                            resultFilter: result.filter
                        }
                })

            }
        ]
    },

    {
        path: "/logs/user-logs",
        component: Tracking,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Region Manager'.translate(lang) /*+ ' - Curoflow'.translate(lang)*/,
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/data/logs', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        entries: query.entries,
                        filter: query.filter,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        query: queryFields
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            page: query.page ? query.page : 0,
                            items: result.items,
                            total: result.total,
                            resultFilter: result.filter
                        }
                })

            }
        ]
    },
    {
        path: "/logs/deleted-user-logs",
        component: DeletedUsersLogs,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Region Manager'.translate(lang) /*+ ' - Curoflow'.translate(lang)*/,
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/data/logs/deleted-users', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        entries: query.entries,
                        filter: query.filter,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        query: queryFields
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            page: query.page ? query.page : 0,
                            items: result.items,
                            total: result.total,
                            resultFilter: result.filter
                        }
                })

            }
        ]
    },


    {
        path: "/data-managment/tags",
        component: Tags,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Tags'.translate(lang) /*+ ' - Curoflow'.translate(lang)*/,
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/data/tags', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        entries: query.entries,
                        filter: query.filter,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        query: queryFields
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            page: query.page ? query.page : 0,
                            items: result.items,
                            total: result.total,
                            resultFilter: result.filter
                        }
                })

            },
            (fetchFunction, lang, match, query) => {
                let queryFields = {};

                return fetchFunction(API_ENDPOINT + '/data/subgroups/nonempty', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        query: queryFields,
                        sortField: 'name',
                        sortType: 1

                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            subgroups: result,
                        }
                })

            },
            (fetchFunction, lang, match, query) => {
                let queryFields = {};

                return fetchFunction(API_ENDPOINT + '/data/articles', {
                    method: 'GET',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            articles: result,
                        }
                })

            },
            (fetchFunction, lang, match, query) => {
                return fetchFunction(API_ENDPOINT + '/data/categories', {
                    method: 'GET',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            categories: result
                        }
                })

            },


            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/data/subcategories', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: null,
                        entries: null,
                        filter: null,
                        sortType: 1,
                        sortField: 'name',
                        query: {}
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            subcategories: result
                        }
                })

            },

        ]
    },
    /*{
        path: "/data-managment/categories",
        component: WikiCategories,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Wiki categories'.translate(lang) + ' - Curoflow'.translate(lang),

            }
        },

        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                if (!query.sortField) {
                    query.sortField = 'name';
                    query.sortType = 1;
                }

                return fetchFunction(API_ENDPOINT + '/data/wikiCategories', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        entries: query.entries,
                        filter: query.filter,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        query: queryFields
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            page: query.page ? query.page : 0,
                            items: result.items,
                            total: result.total,
                        }
                })

            },
            (fetchFunction, lang, match, query) => {
                let queryFields = {};

                return fetchFunction(API_ENDPOINT + '/data/subgroups/nonempty', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        query: queryFields,
                        sortField: 'name',
                        sortType: 1

                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            subgroups: result,
                        }
                })

            },
            (fetchFunction, lang, match, query) => {
                let queryFields = {};

                return fetchFunction(API_ENDPOINT + '/data/articles', {
                    method: 'GET',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            articles: result,
                        }
                })

            },

        ]
    },*/
    {
        path: "/data-managment/waiting-time-log",
        component: WaitingTimeLog,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Waiting time log'.translate(lang) /*+ ' - Curoflow'.translate(lang)*/,
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                if (!query.sortField) {
                    query.sortField = 'name';
                    query.sortType = 1;
                }

                return fetchFunction(API_ENDPOINT + '/data/notFoundClinics', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        entries: query.entries,
                        filter: query.filter,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        query: queryFields
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            page: query.page ? query.page : 0,
                            items: result.items,
                            total: result.total,
                            resultFilter: result.filter
                        }
                })

            },


        ]
    },
    {
        path: "/modules/manage-modules",
        component: ManageModules,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Modules'.translate(lang) /*+ ' - Curoflow'.translate(lang)*/,
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/modules', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        entries: query.entries,
                        filter: query.filter,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        query: queryFields
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            page: query.page ? query.page : 0,
                            items: result.items,
                            total: result.total,
                            resultFilter: result.filter
                        }
                })

            },
            (fetchFunction, lang, match, query) => {

                return fetchFunction(API_ENDPOINT + '/modules', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: null,
                        entries: null,
                        filter: null,
                        sortType: 1,
                        sortField: 'name',
                        query: {}
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            formModules: result
                        }
                })

            },
            (fetchFunction, lang, match, query) => {
                return fetchFunction(API_ENDPOINT + '/modules/endpoints', {
                    method: 'GET',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            endpoints: result
                        }
                })

            },

        ]
    },
    {
        path: "/modules/sort-modules",
        component: SortModules,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Modules'.translate(lang) /*+ ' - Curoflow'.translate(lang)*/,
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/modules', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: null,
                        entries: null,
                        filter: null,
                        sortType: 1,
                        sortField: 'position',
                        query: {}
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300) {

                        let tree = [];
                        for (let i = 0; i < result.length; i++) {
                            if (result[i].type == 'menu') {
                                let obj = result[i];
                                obj.submenu = [];
                                for (let j = 0; j < result.length; j++) {
                                    if (result[j].type == 'submenu' && result[j].parent == result[i]._id) {
                                        obj.submenu.push(result[j]);
                                    }

                                }
                                tree.push(obj);
                            }
                        }
                        // console.log(tree)
                        return {
                            items: tree
                        }

                    }
                })

            },
        ]
    }, {
        path: "/qa",
        component: QAModule,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Modules'.translate(lang) /*+ ' - Curoflow'.translate(lang)*/,
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/qa/test', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: null,
                        entries: null,
                        filter: null,
                        sortType: 1,
                        sortField: 'position',
                        query: {},
                        key: typeof localStorage !== 'undefined' ? localStorage.getItem('qaKey') : null
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300) {
                        let res = [];
                        if (result && result.items) {
                            res = result.items;
                        }
                        if (result && result.stopped && typeof localStroage !== 'undefined') {
                            localStorage.removeItem('qaKey')
                        }
                        if (res && res.length) {
                            for (let i = 0; i < res.length; i++) {
                                if (res[i].output) {
                                    let attachments = [];
                                    let outputWithLinks = res[i].output;
                                    let outputWithLinksSplit = outputWithLinks.split('\n')
                                    for (let j = 0; j < outputWithLinksSplit.length; j++) {
                                        if (outputWithLinksSplit[j].startsWith(`${'https://qaapi-console.curoflow.se'}`)) {
                                            outputWithLinksSplit[j] = `<a href=${outputWithLinksSplit[j]} target="_blank">${outputWithLinksSplit[j]}</a>`
                                            attachments.push(outputWithLinksSplit[j])
                                        }
                                    }
                                    let output2 = '';
                                    for (let j = 0; j < outputWithLinksSplit.length; j++) {
                                        output2 += outputWithLinksSplit[j] + '\n'
                                    }
                                    res[i].output2 = output2;

                                    let outputWithLinksSplitImg = outputWithLinks.split('\n')
                                    for (let j = 0; j < outputWithLinksSplitImg.length; j++) {
                                        if (outputWithLinksSplitImg[j].startsWith(`${'https://qaapi-console.curoflow.se'}`)) {
                                            outputWithLinksSplitImg[j] = `<a href=${outputWithLinksSplitImg[j]} target="_blank"><img src=${outputWithLinksSplitImg[j]} style="width: 50%; height: auto; object-fit: cover;" /></a>`
                                        }
                                    }
                                    let output3 = '';
                                    for (let j = 0; j < outputWithLinksSplitImg.length; j++) {
                                        output3 += outputWithLinksSplitImg[j] + '\n'
                                    }
                                    res[i].output3 = output3;
                                    res[i].attachments = attachments;
                                }
                            }
                        }
                        return {
                            items: res
                        }

                    }
                })

            },
        ]
    },


    {
        path: "/data-managment/categories-tree",
        component: CategoriesTree,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Categories'.translate(lang) /*+ ' - Curoflow'.translate(lang)*/,
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/data/tags/tree', {
                    method: 'GET',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300) {

                        //console.log(tree)
                        return {
                            items: result
                        }

                    }
                })

            },

            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/data/subgroups/nonempty', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: null,
                        entries: null,
                        filter: null,
                        sortType: 1,
                        sortField: 'name',
                        query: {}
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            subgroups: result,
                        }
                })

            },
            (fetchFunction, lang, match, query) => {
                return fetchFunction(API_ENDPOINT + '/data/categories', {
                    method: 'GET',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            categories: result
                        }
                })

            },

        ]
    },
    {
        path: "/users/user-levels",
        component: UserLevels,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang) => {
            return {
                title: 'User levels'.translate(lang) /*+ ' - Curoflow'.translate(lang)*/,
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/users/user-levels', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        entries: query.entries,
                        filter: query.filter,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        query: queryFields
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            page: query.page ? query.page : 0,
                            items: result.items,
                            total: result.total,
                            resultFilter: result.filter
                        }
                })

            },
            (fetchFunction, lang, match, query) => {

                return fetchFunction(API_ENDPOINT + '/modules', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: null,
                        entries: null,
                        filter: null,
                        sortType: 1,
                        sortField: 'name',
                        query: {}
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            formModules: result
                        }
                })

            },

        ]
    },
    // 



    {

        path: "/patients/search",
        component: SearchPatient,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Search patient'.translate(lang) /*+ ' - Curoflow'.translate(lang)*/,
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {


                return fetchFunction(API_ENDPOINT + '/languages', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        sortType: 1,
                        sortField: 'key',
                        query: {}
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            languages: result
                        }
                })

            },
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }



                return fetchFunction(API_ENDPOINT + '/users/subaccounts', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({

                        groupId: query.group,
                        query: queryFields
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            subAccounts: result,
                        }
                })

            },
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                // for (var key in query) {
                //     if (key && key.indexOf('query-') === 0) {
                //         queryFields[key.replace('query-', '')] = query[key];
                //     }
                // }



                return fetchFunction(API_ENDPOINT + '/users/subaccounts/doctors', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: null,
                        entries: null,
                        filter: query.filter,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        groupId: query.group,
                        clinic: query.clinic,
                        query: queryFields
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {

                            doctors: result,

                        }
                })

            },
            (fetchFunction, lang, match, query) => {
                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/data/groups-professions/all', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({ group: query.group })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            professions: result
                        }
                })

            },

        ]

    },
    {

        path: "/patients/search-enterprise",
        component: SearchPatientEnterprise,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Search patient'.translate(lang) /*+ ' - Curoflow'.translate(lang)*/,
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {


                return fetchFunction(API_ENDPOINT + '/languages', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        sortType: 1,
                        sortField: 'key',
                        query: {}
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            languages: result
                        }
                })

            },
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }



                return fetchFunction(API_ENDPOINT + '/users/subaccounts', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({

                        groupId: query.group,
                        query: queryFields
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            subAccounts: result,
                        }
                })

            },
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                // for (var key in query) {
                //     if (key && key.indexOf('query-') === 0) {
                //         queryFields[key.replace('query-', '')] = query[key];
                //     }
                // }



                return fetchFunction(API_ENDPOINT + '/users/subaccounts/doctors', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: null,
                        entries: null,
                        filter: query.filter,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        groupId: query.group,
                        clinic: query.clinic,
                        query: queryFields
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {

                            doctors: result,

                        }
                })

            },
            (fetchFunction, lang, match, query) => {
                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/data/groups-professions/all', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({ group: query.group })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            professions: result
                        }
                })

            },

        ]

    },
    {
        path: "/patients/patients",
        component: Patients,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Patients'.translate(lang) /*+ ' - Curoflow'.translate(lang)*/,
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [


        ]
    },

    {
        path: "/users/users",
        component: Users,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Users'.translate(lang) /*+ ' - Curoflow'.translate(lang)*/,
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/users/users/user-accounts/v2', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        entries: query.entries,
                        filter: query.filter,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        query: queryFields
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300) {
                        return {
                            page: query.page ? query.page : 0,
                            items: result.items,
                            total: result.total,
                            resultFilter: result.filter
                        }


                    }

                })

            },
            // (fetchFunction, lang, match, query) => {

            //     let queryFields = {};
            //     for (var key in query) {
            //         if (key && key.indexOf('query-') === 0) {
            //             queryFields[key.replace('query-', '')] = query[key];
            //         }
            //     }

            //     return fetchFunction(API_ENDPOINT + '/users/users', {
            //         method: 'POST',
            //         headers: {
            //             'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            //             'content-type': 'application/json'
            //         },
            //         body: JSON.stringify({
            //             query: {}
            //         })
            //     }).then(parseJSON).then(({ result, status }) => {
            //         if (status >= 200 && status < 300)
            //             return {
            //                 formUsers: result
            //             }
            //     })

            // },
            (fetchFunction, lang, match, query) => {


                return fetchFunction(API_ENDPOINT + '/data/regions', {
                    method: 'GET',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            regions: result,
                        }
                })

            },

            (fetchFunction, lang, match, query) => {


                return fetchFunction(API_ENDPOINT + '/users/user-levels', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        sortType: -1,
                        sortField: 'level',
                        query: {}
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            userLevels: result
                        }
                })

            },

            (fetchFunction, lang, match, query) => {

                return fetchFunction(API_ENDPOINT + '/support-category/all', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        sortType: -1,
                        sortField: 'level',
                        query: {}
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            supportCategory: result

                        }
                })

            },
            (fetchFunction, lang, match, query) => {


                return fetchFunction(API_ENDPOINT + '/languages', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        sortType: 1,
                        sortField: 'key',
                        query: {}
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            languages: result
                        }
                })

            },
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/data/clinic-groups/all', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({})
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            clinicGroups: result
                        }
                })

            },

        ]
    },

    {
        path: "/my-data/account",
        component: Account,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Account'.translate(lang) /*+ ' - Curoflow'.translate(lang)*/,
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [

            (fetchFunction, lang, match, query) => {


                return fetchFunction(API_ENDPOINT + '/languages', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        sortType: 1,
                        sortField: 'key',
                        query: {}
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            languages: result
                        }
                })

            },
            (fetchFunction, lang, match, query) => {
                return fetchFunction(API_ENDPOINT + '/users/users/patient/search/' + query.patientId, {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        entries: query.entries,
                        filter: query.filter,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        patientProfile: true
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)

                        return {
                            page: query.page ? query.page : 0,
                            items: result.items,
                            total: result.total,
                        }
                })

            },
        ]
    },

    {
        path: "/my-data/main-clinic-data",
        component: LandingBuilderMainClinic,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang) => {
            return {
                title: 'My clinic data'.translate(lang) /*+ ' - Curoflow'.translate(lang)*/,
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {
                let queryFields = {};

                return fetchFunction(API_ENDPOINT + '/data/regions', {
                    method: 'GET',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            regions: result,
                        }
                })

            },


        ]
    },
    {
        path: "/my-data/clinic-data",
        component: ClinicData,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang) => {
            return {
                title: 'My clinic data'.translate(lang) /*+ ' - Curoflow'.translate(lang)*/,
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {
                let queryFields = {};

                return fetchFunction(API_ENDPOINT + '/data/regions', {
                    method: 'GET',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            regions: result,
                        }
                })

            },


        ]
    },
    {
        path: "/my-data/drop-in-working-hours",
        component: DropInWorkingHours,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang) => {
            return {
                title: 'My clinic data'.translate(lang) /*+ ' - Curoflow'.translate(lang)*/,
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [
            // (fetchFunction, lang, match, query) => {
            //     let queryFields = {};

            //     return fetchFunction(API_ENDPOINT + '/data/regions', {
            //         method: 'GET',
            //         headers: {
            //             'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            //             'content-type': 'application/json'
            //         },
            //     }).then(parseJSON).then(({ result, status }) => {
            //         if (status >= 200 && status < 300)
            //             return {
            //                 regions: result,
            //             }
            //     })

            // },


        ]
    },

    {
        path: "/my-data/quick-phrases",
        component: QuickPhrases,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Quick phrases'.translate(lang) /*+ ' - Curoflow'.translate(lang)*/,
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }



                return fetchFunction(API_ENDPOINT + '/users/account/clinic-phrase', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        entries: query.entries,
                        filter: query.filter,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        group: query.groupId,
                        clinic: query.clinic,
                        query: queryFields,
                        type: query.type,
                        checkType: true
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            page: query.page ? query.page : 0,
                            items: result.items,
                            total: result.total,
                            resultFilter: result.filter
                        }
                })

            },


        ]
    },
    {
        path: "/my-data/clinic-notifications",
        component: clinicNotifications,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Clinic notifications'.translate(lang) /*+ ' - Curoflow'.translate(lang)*/,
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {
                let queryFields = {};

                return fetchFunction(API_ENDPOINT + '/data/regions', {
                    method: 'GET',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            regions: result,
                        }
                })

            },


        ]
    },
    {
        path: "/my-data/daybook-events",
        component: DaybookEvents,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Daybook events'.translate(lang) /*+ ' - Curoflow'.translate(lang)*/,
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {
                let queryFields = {};

                return fetchFunction(API_ENDPOINT + '/data/regions', {
                    method: 'GET',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            regions: result,
                        }
                })

            },


        ]
    },




    {
        path: "/my-data/clinic-statistics",
        component: ClinicStatistics,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Articles'.translate(lang) + ' - RemissHjälpen'.translate(lang),
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/data/group-statistics', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        entries: query.entries,
                        filter: query.filter,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        query: queryFields,
                        group: query.group
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            page: query.page ? query.page : 0,
                            items: result.items,
                            total: result.total,
                        }
                })

            },



            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/data/group-statistics/files', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        group: query.group,
                        year: query.year,
                        january: query.january,
                        february: query.february,
                        march: query.march,
                        april: query.april,
                        may: query.may,
                        june: query.june,
                        july: query.july,
                        august: query.august,
                        september: query.september,
                        october: query.october,
                        november: query.november,
                        december: query.december

                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            files: result.result
                        }
                })

            },
            (fetchFunction, lang, match, query) => {


                return fetchFunction(API_ENDPOINT + '/data/groups-professions/all', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({ group: query.group })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            professions: result
                        }
                })

            },


        ]
    },

    {
        path: "/my-data/pricelist",
        component: PriceList,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Price list'.translate(lang) /*+ ' - Curoflow'.translate(lang)*/,
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }



                return fetchFunction(API_ENDPOINT + '/users/price-list', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        entries: query.entries,
                        filter: query.filter,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        groupId: query.groupId,
                        query: queryFields
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            page: query.page ? query.page : 0,
                            items: result.items,
                            total: result.total,
                            resultFilter: result.filter
                        }
                })

            },
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/data/revisit-questionary/all', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        group: query.groupId,
                        clinic: query.clinic,
                        query: queryFields
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            revisitForms: result && result.items ? result.items : [],
                        }
                })

            },




            (fetchFunction, lang, match, query) => {


                return fetchFunction(API_ENDPOINT + '/languages', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        sortType: 1,
                        sortField: 'key',
                        query: {}
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            languages: result
                        }
                })

            },


        ]
    },


    {
        path: "/my-data/deletion-requests",
        component: DeletionAccounts,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Deletion requests'.translate(lang) /*+ ' - Curoflow'.translate(lang)*/,
                description: ''
            }
        },

        loadData: [

        ],
        // loadDataWithQuery: [
        //     (fetchFunction, lang, match, query) => {

        //         let queryFields = {};
        //         for (var key in query) {
        //             if (key && key.indexOf('query-') === 0) {
        //                 queryFields[key.replace('query-', '')] = query[key];
        //             }
        //         }



        //         return fetchFunction(API_ENDPOINT + '/data/deletion-requests', {
        //             method: 'POST',
        //             headers: {
        //                 'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
        //                 'content-type': 'application/json'
        //             },
        //             body: JSON.stringify({
        //                 page: query.page,
        //                 entries: query.entries,
        //                 filter: query.filter,
        //                 sortType: query.sortType,
        //                 sortField: query.sortField,
        //                 group: query.group,
        //                 query: queryFields
        //             })
        //         }).then(parseJSON).then(({ result, status }) => {
        //             if (status >= 200 && status < 300)
        //                 return {
        //                     page: query.page ? query.page : 0,
        //                     items: result.items,
        //                     total: result.total,
        //                     resultFilter: result.filter
        //                 }
        //         })

        //     },



        // ]
    },
    {
        path: "/my-data/registration-requests",
        component: TwoFactorAuthRegistrationRequests,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Patient Registration Requests'.translate(lang) /*+ ' - Curoflow'.translate(lang)*/,
                description: ''
            }
        },
        loadData: [],
        loadDataWithQuery: []
    },
    {
        path: "/e-connect/created-cases",
        component: EconnectCreatedCasesPage,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang) => {
            return {
                title: 'E-connect Created Cases'.translate(lang) /*+ ' - Curoflow'.translate(lang)*/,
                description: ''
            }
        },
        loadData: [],
        loadDataWithQuery: []
    },
    {
        path: "/e-connect/received-cases",
        component: EconnectReceivedCasesPage,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang) => {
            return {
                title: 'E-connect Received Cases'.translate(lang) /*+ ' - Curoflow'.translate(lang)*/,
                description: ''
            }
        },
        loadData: [],
        loadDataWithQuery: []
    },
    {
        path: "/e-connect/consultation",
        component: EconnectConsultationPage,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang) => {
            return {
                title: 'E-connect Consultation'.translate(lang) /*+ ' - Curoflow'.translate(lang)*/,
                description: ''
            }
        },
        loadData: [],
        loadDataWithQuery: []
    },
    {
        path: "/my-data/patient-list",
        component: PatientList,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Patient List'.translate(lang) /*+ ' - Curoflow'.translate(lang)*/,
                description: ''
            }
        },

        loadData: [

        ],
        // loadDataWithQuery: [
        //     (fetchFunction, lang, match, query) => {

        //         let queryFields = {};
        //         for (var key in query) {
        //             if (key && key.indexOf('query-') === 0) {
        //                 queryFields[key.replace('query-', '')] = query[key];
        //             }
        //         }



        //         return fetchFunction(API_ENDPOINT + '/data/deletion-requests', {
        //             method: 'POST',
        //             headers: {
        //                 'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
        //                 'content-type': 'application/json'
        //             },
        //             body: JSON.stringify({
        //                 page: query.page,
        //                 entries: query.entries,
        //                 filter: query.filter,
        //                 sortType: query.sortType,
        //                 sortField: query.sortField,
        //                 group: query.group,
        //                 query: queryFields
        //             })
        //         }).then(parseJSON).then(({ result, status }) => {
        //             if (status >= 200 && status < 300)
        //                 return {
        //                     page: query.page ? query.page : 0,
        //                     items: result.items,
        //                     total: result.total,
        //                     resultFilter: result.filter
        //                 }
        //         })

        //     },



        // ]
    },

    {
        path: "/my-data/subaccounts",
        component: SubAccounts,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Sub accounts'.translate(lang) /*+ ' - Curoflow'.translate(lang)*/,
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }



                return fetchFunction(API_ENDPOINT + '/users/subaccounts', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        entries: query.entries,
                        filter: query.filter,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        groupId: query.groupId,
                        selectedProfession: query.selectedProfession,
                        query: queryFields
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            page: query.page ? query.page : 0,
                            items: result.items,
                            total: result.total,
                            // totalClinicAdmins: result.totalClinicAdmins,
                            clinicAdmins: result.clinicAdmins,
                            resultFilter: result.filter
                        }
                })

            },
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/data/personal-questionary/group', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        entries: null,
                        filter: query.filter,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        query: queryFields,
                        group: query.groupId,
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            // page: query.page ? query.page : 0,
                            questionaryList: result.items,
                            // total: result.total,
                        }
                })

            },
            (fetchFunction, lang, match, query) => {


                return fetchFunction(API_ENDPOINT + '/languages', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        sortType: 1,
                        sortField: 'key',
                        query: {}
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            languages: result
                        }
                })

            },
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/data/groups-professions/all', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({ group: query.groupId })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            professions: result,
                        }
                })

            },


        ]
    },

    {
        path: "/languages/translation",
        component: Translation,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Translation'.translate(lang) /*+ ' - Curoflow'.translate(lang)*/,
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/languages/translation', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        entries: query.entries,
                        filter: query.filter,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        query: queryFields
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            page: query.page ? query.page : 0,
                            items: result.items,
                            total: result.total,
                            resultFilter: result.filter
                        }
                })

            }
        ]
    },
    {
        path: "/crm",
        component: typeof window == 'undefined' ? null : Crm,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang) => {
            return {
                title: 'CRM'.translate(lang) /*+ ' - Curoflow'.translate(lang)*/,
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {

                return fetchFunction(API_ENDPOINT + '/crm/entities', {
                    method: 'GET',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            entities: result
                        }
                })

            }
        ]
    },

    {
        path: "/my-data/referrals/sent",
        component: SentReferrals,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Articles'.translate(lang) /*+ ' - Curoflow'.translate(lang)*/,
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/referrals/sent', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        entries: query.entries,
                        filter: query.filter,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        query: queryFields,
                        clinic: query.clinic
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            page: query.page ? query.page : 0,
                            items: result.items,
                            total: result.total,
                        }
                })

            }
        ]
    },

    {
        path: "/my-data/personal-referrals/my-cases",
        component: MyCases,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Articles'.translate(lang) /*+ ' - Curoflow'.translate(lang)*/,
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {
                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/referrals/received', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        entries: query.entries,
                        filter: query.filter,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        query: queryFields,
                        clinic: query.clinic,
                        personalReferral: true,
                        recipientUser: query.recipientUser,
                        myCases: true,
                        closed: false,
                        controlBoking: query.controlBoking,
                        dropInBoking: query.dropInBoking,
                        webBoking: query.webBoking,
                        clinicBoking: query.clinicBoking,

                        controlScheduled: query.controlScheduled,
                        cancledByClinic: query.cancledByClinic,
                        rejectedByPatient: query.rejectedByPatient,
                        closedCase: query.closed,
                        scheduled: query.scheduled,
                        notBooked: query.notBooked,
                        disallowed: query.disallowed,
                        inProgress: query.inProgress,
                        allowedCase: query.allowed,
                        lang: lang,
                        columnVisibility: query.columnVisibility ? query.columnVisibility : null


                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            page: query.page ? query.page : 0,
                            items: result.items,
                            total: result.total,
                            adminPermission: result.adminPermission,
                            resultFilter: result.filter,
                            responseTimestamp: result.responseTimestamp
                        }
                })

            },
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }



                return fetchFunction(API_ENDPOINT + '/users/subaccounts', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({

                        groupId: query.group,
                        query: queryFields
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            subAccounts: result,
                        }
                })

            },
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                // for (var key in query) {
                //     if (key && key.indexOf('query-') === 0) {
                //         queryFields[key.replace('query-', '')] = query[key];
                //     }
                // }



                return fetchFunction(API_ENDPOINT + '/users/subaccounts/doctors', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: null,
                        entries: null,
                        filter: query.filter,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        groupId: query.group,
                        clinic: query.clinic,
                        query: queryFields
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {

                            doctors: result,

                        }
                })

            },
        ]
    },

    {
        path: "/my-data/personal-referrals/drop-in/received",
        component: ReceivedDropInReferrals,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Articles'.translate(lang) /*+ ' - Curoflow'.translate(lang)*/,
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/referrals/received/drop-in', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        entries: query.entries,
                        filter: query.filter,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        query: queryFields,
                        clinic: query.clinic,
                        personalReferral: true,
                        closed: false,
                        recipientUser: query.recipientUser,
                        otherCases: true,
                        tabIndex: query.tabIndex,

                        closedCase: query.closed,
                        waiting: query.waiting,
                        inProgress: query.inProgress,
                        lang: lang,
                        columnVisibility: query.columnVisibility ? query.columnVisibility : null

                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            page: query.page ? query.page : 0,
                            items: result.items,
                            total: result.total,
                            clinicAdminPermission: result.clinicAdminPermission,
                            adminPermission: result.adminPermission,
                            doctorPermission: result.doctorPermission,
                            resultFilter: result.filter,
                            responseTimestamp: result.responseTimestamp
                        }
                })

            },

            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }



                return fetchFunction(API_ENDPOINT + '/users/subaccounts', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({

                        groupId: query.group,
                        query: queryFields
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            subAccounts: result,
                        }
                })

            },
        ]
    },
    {
        path: "/my-data/personal-referrals/received",
        component: ReceivedRefferal,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Articles'.translate(lang) /*+ ' - Curoflow'.translate(lang)*/,
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/referrals/received', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        entries: query.entries,
                        filter: query.filter,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        query: queryFields,
                        clinic: query.clinic,

                        personalReferral: true,
                        closed: false,
                        recipientUser: query.recipientUser,
                        otherCases: true,

                        controlScheduled: query.controlScheduled,
                        cancledByClinic: query.cancledByClinic,
                        rejectedByPatient: query.rejectedByPatient,
                        closedCase: query.closed,
                        scheduled: query.scheduled,
                        notBooked: query.notBooked,
                        disallowed: query.disallowed,
                        waitingForApproval: query.waitingForApproval,
                        waitingForPayment: query.waitingForPayment,
                        allowedCase: query.allowed,
                        lang: lang,
                        columnVisibility: query.columnVisibility ? query.columnVisibility : null
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        // console.log(result)
                        return {
                            page: query.page ? query.page : 0,
                            items: result.items,
                            // fields: result.fields,
                            total: result.total,
                            clinicAdminPermission: result.clinicAdminPermission,
                            adminPermission: result.adminPermission,
                            doctorPermission: result.doctorPermission,
                            resultFilter: result.filter,
                            responseTimestamp: result.responseTimestamp
                        }
                })

            },
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }



                return fetchFunction(API_ENDPOINT + '/users/subaccounts', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({

                        groupId: query.group,
                        query: queryFields
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            subAccounts: result,
                        }
                })

            },
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                // for (var key in query) {
                //     if (key && key.indexOf('query-') === 0) {
                //         queryFields[key.replace('query-', '')] = query[key];
                //     }
                // }



                return fetchFunction(API_ENDPOINT + '/users/subaccounts/doctors', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: null,
                        entries: null,
                        filter: query.filter,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        groupId: query.group,
                        clinic: query.clinic,
                        query: queryFields
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {

                            doctors: result,

                        }
                })

            },
        ]
    },
    {
        path: "/my-data/web-boking/received",
        component: ReceivedWebBoking,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Articles'.translate(lang) /*+ ' - Curoflow'.translate(lang)*/,
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/referrals/received', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        entries: query.entries,
                        filter: query.filter,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        query: queryFields,
                        clinic: query.clinic,

                        personalReferral: true,
                        closed: false,
                        recipientUser: query.recipientUser,
                        webCases: true,

                        rejectedByPatient: query.rejectedByPatient,
                        closedCase: query.closed,
                        scheduled: query.scheduled,
                        notBooked: query.notBooked,
                        lang: lang,
                        columnVisibility: query.columnVisibility ? query.columnVisibility : null
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        // console.log(result)
                        return {
                            page: query.page ? query.page : 0,
                            items: result.items,
                            // fields: result.fields,
                            total: result.total,
                            clinicAdminPermission: result.clinicAdminPermission,
                            adminPermission: result.adminPermission,
                            doctorPermission: result.doctorPermission,
                            resultFilter: result.filter,
                            responseTimestamp: result.responseTimestamp
                        }
                })

            },
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }



                return fetchFunction(API_ENDPOINT + '/users/subaccounts', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({

                        groupId: query.group,
                        query: queryFields
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            subAccounts: result,
                        }
                })

            },
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                // for (var key in query) {
                //     if (key && key.indexOf('query-') === 0) {
                //         queryFields[key.replace('query-', '')] = query[key];
                //     }
                // }



                return fetchFunction(API_ENDPOINT + '/users/subaccounts/doctors', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: null,
                        entries: null,
                        filter: query.filter,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        groupId: query.group,
                        clinic: query.clinic,
                        query: queryFields
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {

                            doctors: result,

                        }
                })

            },
        ]
    },
    {
        path: "/my-data/personal-referrals/all-cases",
        component: allCases,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Articles'.translate(lang) /*+ ' - Curoflow'.translate(lang)*/,
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/referrals-cases/all-cases', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        entries: query.entries,
                        filter: query.filter,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        query: queryFields,
                        clinic: query.clinic,
                        professions: query.professions,
                        controlBoking: query.controlBoking,
                        dropInBoking: query.dropInBoking,
                        webBoking: query.webBoking,
                        clinicBoking: query.clinicBoking,

                        controlScheduled: query.controlScheduled,
                        cancledByClinic: query.cancledByClinic,
                        rejectedByPatient: query.rejectedByPatient,
                        closed: query.closed,
                        scheduled: query.scheduled,
                        notBooked: query.notBooked,
                        disallowed: query.disallowed,
                        waiting: query.waiting,
                        inProgress: query.inProgress,
                        waitingForApproval: query.waitingForApproval,
                        waitingForPayment: query.waitingForPayment,
                        allowed: query.allowed,
                        lang: lang,
                        columnVisibility: query.columnVisibility ? query.columnVisibility : null
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            page: query.page ? query.page : 0,
                            items: result.items,
                            total: result.total,
                            adminInThisGroup: result.adminInThisGroup ? true : false,
                            resultFilter: result.filter
                        }
                })

            },
            (fetchFunction, lang, match, query) => {
                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/data/groups-professions/all', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({ group: query.group })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            professions: result
                        }
                })

            },
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }



                return fetchFunction(API_ENDPOINT + '/users/subaccounts', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({

                        groupId: query.group,
                        query: queryFields
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            subAccounts: result,
                        }
                })

            },
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                // for (var key in query) {
                //     if (key && key.indexOf('query-') === 0) {
                //         queryFields[key.replace('query-', '')] = query[key];
                //     }
                // }



                return fetchFunction(API_ENDPOINT + '/users/subaccounts/doctors', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: null,
                        entries: null,
                        filter: query.filter,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        groupId: query.group,
                        clinic: query.clinic,
                        query: queryFields
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {

                            doctors: result,

                        }
                })

            },
        ]
    },
    {
        path: "/my-data/personal-referrals/my-payments",
        component: MyPayments,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang) => {
            return {
                title: 'My payments'.translate(lang) /*+ ' - Curoflow'.translate(lang)*/,
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [
            // (fetchFunction, lang, match, query) => {

            //     return fetchFunction(API_ENDPOINT + '/referrals/patient/my-payments', {
            //         method: 'POST',
            //         headers: {
            //             'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            //             'content-type': 'application/json'
            //         },
            //         body: JSON.stringify({
            //             page: query.page,
            //             entries: query.entries,
            //             filter: query.filter,
            //             sortType: query.sortType,
            //             sortField: query.sortField,
            //         })
            //     }).then(parseJSON).then(({ result, status }) => {
            //         if (status >= 200 && status < 300)
            //             return {
            //                 page: query.page ? query.page : 0,
            //                 items: result.items,
            //                 total: result.total,
            //             }
            //     })

            // },
            (fetchFunction, lang, match, query) => {

                return fetchFunction(API_ENDPOINT + '/bills/payments', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        entries: query.entries,
                        filter: query.filter,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        patientsBills: true
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            page: query.page ? query.page : 0,
                            items: result.items,
                            total: result.total,
                            resultFilter: result.filter
                        }
                })

            },
            (fetchFunction, lang, match, query) => {

                return fetchFunction(API_ENDPOINT + '/referrals/patient/invoice', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        entries: query.entries,
                        filter: query.filter,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        groupAlias: query.alias,
                        patient: query.patient
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            pageInvoice: query.page ? query.page : 0,
                            itemsInvoice: result.items,
                            totalInvoice: result.total,
                            resultFilter: result.filter
                        }
                })

            },
            (fetchFunction, lang, match, query) => {


                return fetchFunction(API_ENDPOINT + '/data/regions', {
                    method: 'GET',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            regions: result,
                        }
                })

            },
        ]
    },
    {
        path: "/my-data/personal-referrals/payments",
        component: Payments,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Payments'.translate(lang) /*+ ' - Curoflow'.translate(lang)*/,
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [
            // (fetchFunction, lang, match, query) => {

            //     return fetchFunction(API_ENDPOINT + '/referrals/patient/my-payments', {
            //         method: 'POST',
            //         headers: {
            //             'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            //             'content-type': 'application/json'
            //         },
            //         body: JSON.stringify({
            //             page: query.page,
            //             entries: query.entries,
            //             filter: query.filter,
            //             sortType: query.sortType,
            //             sortField: query.sortField,
            //             group: query.group,
            //             clinic: query.clinic
            //         })
            //     }).then(parseJSON).then(({ result, status }) => {
            //         if (status >= 200 && status < 300)
            //             return {
            //                 page: query.page ? query.page : 0,
            //                 items: result.items,
            //                 total: result.total,
            //             }
            //     })

            // },
            (fetchFunction, lang, match, query) => {

                return fetchFunction(API_ENDPOINT + '/bills/payments', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        entries: query.entries,
                        filter: query.filter,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        group: query.group,
                        clinic: query.clinic,
                        columnVisibility: query.columnVisibility ? query.columnVisibility : null
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    // console.log(result)
                    if (status >= 200 && status < 300)

                        return {
                            page: query.page ? query.page : 0,
                            items: result.items,
                            total: result.total,
                            resultFilter: result.filter
                        }
                })

            },
            (fetchFunction, lang, match, query) => {

                return fetchFunction(API_ENDPOINT + `/data/clinic-groups/transfer-payments/check?groupId=${query.group}&clinicId=${query.clinic}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    }
                }).then(parseJSON).then(({ result, status }) => {
                    if (result.error) return { canTransferPayments: null }
                    return {
                        canTransferPayments: result.canTransferPayments
                    }
                })

            },

            (fetchFunction, lang, match, query) => {

                return fetchFunction(API_ENDPOINT + '/referrals/patient/invoice', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        entries: query.entries,
                        filter: query.filter,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        // alias: query.alias,
                        group: query.group,
                        clinic: query.clinic,
                        columnVisibility: query.columnVisibility ? query.columnVisibility : null
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            page: query.page ? query.page : 0,
                            itemsInvoice: result.items,
                            totalInvoice: result.total,
                            resultFilter: result.filter
                        }
                })

            }
        ]
    },
    {
        path: "/my-data/personal-referrals/closed",
        component: ReceivedRefferal,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Articles'.translate(lang) /*+ ' - Curoflow'.translate(lang)*/,
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/referrals/received', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        entries: query.entries,
                        filter: query.filter,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        query: queryFields,
                        clinic: query.clinic,
                        personalReferral: true,
                        closed: true,
                        lang: lang,
                        columnVisibility: query.columnVisibility ? query.columnVisibility : null

                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            page: query.page ? query.page : 0,
                            items: result.items,
                            // fields: result.fields,
                            total: result.total,
                            resultFilter: result.filter
                        }
                })

            }
        ]
    },

    {
        path: "/my-data/referrals/received",
        component: ReceivedReferrals,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Articles'.translate(lang) /*+ ' - Curoflow'.translate(lang)*/,
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/referrals/received', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        entries: query.entries,
                        filter: query.filter,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        query: queryFields,
                        clinic: query.clinic,
                        personalReferral: false,
                        lang: lang,

                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            page: query.page ? query.page : 0,
                            items: result.items,
                            total: result.total,
                        }
                })

            },

        ]
    },

    {
        path: "/my-data/referrals/transfer",
        component: DragAndDropEvents,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Articles'.translate(lang) /*+ ' - Curoflow'.translate(lang)*/,
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [
            // (fetchFunction, lang, match, query) => {

            //     let queryFields = {};
            //     for (var key in query) {
            //         if (key && key.indexOf('query-') === 0) {
            //             queryFields[key.replace('query-', '')] = query[key];
            //         }
            //     }

            //     return fetchFunction(API_ENDPOINT + '/referrals/received', {
            //         method: 'POST',
            //         headers: {
            //             'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            //             'content-type': 'application/json'
            //         },
            //         body: JSON.stringify({
            //             page: query.page,
            //             entries: query.entries,
            //             filter: query.filter,
            //             sortType: query.sortType,
            //             sortField: query.sortField,
            //             query: queryFields,
            //             clinic: query.clinic,
            //             allowed: true,
            //             lang: lang

            //         })
            //     }).then(parseJSON).then(({ result, status }) => {
            //         if (status >= 200 && status < 300)
            //             return {
            //                 page: query.page ? query.page : 0,
            //                 items: result.items,
            //                 total: result.total,
            //             }
            //     })

            // },

            // (fetchFunction, lang, match, query) => {

            //     let queryFields = {};
            //     for (var key in query) {
            //         if (key && key.indexOf('query-') === 0) {
            //             queryFields[key.replace('query-', '')] = query[key];
            //         }
            //     }



            //     return fetchFunction(API_ENDPOINT + '/users/subaccounts/doctors', {
            //         method: 'POST',
            //         headers: {
            //             'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            //             'content-type': 'application/json'
            //         },
            //         body: JSON.stringify({
            //             page: null,
            //             entries: null,
            //             filter: query.filter,
            //             sortType: query.sortType,
            //             sortField: query.sortField,
            //             groupId: query.groupId,
            //             clinic: query.clinic,
            //             query: queryFields
            //         })
            //     }).then(parseJSON).then(({ result, status }) => {
            //         if (status >= 200 && status < 300)
            //             return {

            //                 doctors: result,

            //             }
            //     })

            // },

            // (fetchFunction, lang, match, query) => {
            //     // alert('test')
            //     let queryFields = {};
            //     // console.log(query.filter)
            //     return fetchFunction(API_ENDPOINT + '/users/price-list/all', {
            //         method: 'POST',
            //         headers: {
            //             'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            //             'content-type': 'application/json'
            //         },
            //         body: JSON.stringify({

            //             clinic: query.clinic,
            //             group: query.groupId,
            //             query: queryFields,
            //             filter: query.filter
            //         })
            //     }).then(parseJSON).then(({ result, status }) => {
            //         if (status >= 200 && status < 300)
            //             return {
            //                 visitReasons: result,
            //             }
            //     })

            // },
        ]
    },
    {
        path: "/my-data/referrals/schedule",
        component: ScheduleReferrals,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Articles'.translate(lang) /*+ ' - Curoflow'.translate(lang)*/,
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/working-hours/web-booking-forms', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        group: query.groupId,
                        doctor: query.doctor,
                        clinic: query.clinic
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            webBookingForms: result
                        }
                })

            },
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/referrals/received', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        entries: query.entries,
                        filter: query.filter,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        query: queryFields,
                        clinic: query.clinic,
                        columnVisibility: query.columnVisibility ? query.columnVisibility : null,
                        allowed: true,
                        lang: lang

                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            page: query.page ? query.page : 0,
                            items: result.items,
                            total: result.total,
                            resultFilter: result.filter
                        }
                })

            },
            (fetchFunction, lang, match, query) => {

                return fetchFunction(API_ENDPOINT + '/working-hours/events', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        doctor: query.doctor,
                        clinic: query.clinic,
                        group: query.group,
                        day: query.day,
                        view: query.view

                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300) {
                        if (result) {
                            if (query.view == 'month' && result && result.length && query.groupId) {
                                let dateStart = new Date(query.day)
                                let dateEnd = new Date(query.day)
                                dateEnd = new Date(dateEnd.getFullYear(), dateEnd.getMonth() + 1, 0);
                                dateStart.setHours(0, 0, 0, 0)
                                dateEnd.setHours(23, 59, 59, 0)
                                let dateStartTs = Math.floor(new Date(dateStart).getTime() / 1000) - 24 * 60 * 60 * 7;
                                let dateEndTs = Math.floor(new Date(dateEnd).getTime() / 1000) + 24 * 60 * 60 * 7;

                                let obj = {
                                    title: 'Temporary event',
                                    doctor: query.doctor,
                                    type: "temporaryEvent",
                                    clinic: query.clinic,
                                    group: query.groupId,
                                }
                                for (let i = dateStartTs; i < dateEndTs; i += 24 * 60 * 60) {
                                    let start = i;
                                    let end = i + 24 * 60 * 60 - 1;
                                    if (result.filter(el => el.startTime >= start && el.startTime <= end && el.group == query.groupId && !el.reserved).length == 0) {
                                        let date = new Date(i * 1000)
                                        date.setHours(4, 0, 0, 0)
                                        obj.startTime = Math.floor(new Date(date).getTime() / 1000)
                                        obj.endTime = Math.floor(new Date(date).getTime() / 1000) + 60 * 15;
                                        result.push({ ...obj })
                                        result.push({ ...obj })
                                    }
                                }
                            }
                            return {
                                events: result
                            }
                        }

                    }

                })

            },
            (fetchFunction, lang, match, query) => {


                return fetchFunction(API_ENDPOINT + '/languages', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        sortType: 1,
                        sortField: 'key',
                        query: {}
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            languages: result
                        }
                })

            },
        ]
    },
    {
        path: "/my-data/referrals/working-hours",
        component: DoctorWorkingHours,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang) => {
            return {
                title: 'My calendar'.translate(lang) /*+ ' - Curoflow'.translate(lang)*/,
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/working-hours/web-booking-forms', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        group: query.group,
                        doctor: query.doctor,
                        clinic: query.clinic
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            webBookingForms: result
                        }
                })

            },
            (fetchFunction, lang, match, query) => {

                return fetchFunction(API_ENDPOINT + '/working-hours/events', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        doctor: query.doctor,
                        clinic: query.clinic,
                        group: query.group,
                        day: query.day,
                        view: query.view

                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300) {

                        if (query.view == 'month' && result && result.length) {
                            let dateStart = new Date(query.day)
                            let dateEnd = new Date(query.day)
                            dateEnd = new Date(dateEnd.getFullYear(), dateEnd.getMonth() + 1, 0);
                            dateStart.setHours(0, 0, 0, 0)
                            dateEnd.setHours(23, 59, 59, 0)
                            let dateStartTs = Math.floor(new Date(dateStart).getTime() / 1000) - 24 * 60 * 60 * 7;
                            let dateEndTs = Math.floor(new Date(dateEnd).getTime() / 1000) + 24 * 60 * 60 * 7;

                            let obj = {
                                title: 'Temporary event',
                                doctor: query.doctor,
                                type: "temporaryEvent",
                                clinic: query.clinic,
                                group: query.group,
                            }
                            for (let i = dateStartTs; i < dateEndTs; i += 24 * 60 * 60) {
                                let start = i;
                                let end = i + 24 * 60 * 60 - 1;
                                if (result.filter(el => el.startTime >= start && el.startTime <= end && el.group == query.group && !el.reserved).length == 0) {
                                    let date = new Date(i * 1000)
                                    date.setHours(4, 0, 0, 0)
                                    obj.startTime = Math.floor(new Date(date).getTime() / 1000)
                                    obj.endTime = Math.floor(new Date(date).getTime() / 1000) + 60 * 15;
                                    result.push({ ...obj })
                                    result.push({ ...obj })
                                }
                            }
                        }
                        return {
                            items: result
                        }
                    }


                })

            },

            (fetchFunction, lang, match, query) => {


                return fetchFunction(API_ENDPOINT + '/languages', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        sortType: 1,
                        sortField: 'key',
                        query: {}
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            languages: result
                        }
                })

            },

            // (fetchFunction, lang, match, query) => {
            //     return fetchFunction(API_ENDPOINT + '/take-care/get-bookings-for-doctor', {
            //         method: 'POST',
            //         headers: {
            //             'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            //             'content-type': 'application/json'
            //         },
            //         body: JSON.stringify({
            //             doctorId: query?.doctor,
            //             clinicId: query?.clinic,
            //             groupId: query?.group
            //         })
            //     }).then(parseJSON).then(({result, status}) => {
            //         return {
            //             takeCare: result
            //         }
            //     })
            // }
        ]
    },
    {
        path: "/payson",
        component: Payson,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Payson'.translate(lang) /*+ ' - Curoflow'.translate(lang)*/,
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [


        ]
    },


    {
        path: "/my-data/referrals/test-calendar",
        component: TestCalendar,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Calendar'.translate(lang) /*+ ' - Curoflow'.translate(lang)*/,
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                // console.log(query)

                return fetchFunction(API_ENDPOINT + '/users/subaccounts/doctors', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: null,
                        entries: null,
                        filter: query.filter,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        groupId: query.group,
                        clinic: query.clinic,
                        query: queryFields
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {

                            doctors: result,

                        }
                })

            },
            (fetchFunction, lang, match, query) => {

                return fetchFunction(API_ENDPOINT + '/working-hours/events', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        doctor: query.doctor,
                        clinic: query.clinic,
                        group: query.group,

                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            items: result

                        }
                })

            },

            (fetchFunction, lang, match, query) => {

                return fetchFunction(API_ENDPOINT + '/data/clinic-groups/patient-data', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        alias: query.alias
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            clinics: result.clinics,
                            groupId: result.groupId
                        }
                })

            },
        ]
    },
    {
        path: "/my-data/referrals",
        component: Referrals,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Articles'.translate(lang) /*+ ' - Curoflow'.translate(lang)*/,
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/referrals/patient', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        entries: query.entries,
                        filter: query.filter,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        query: queryFields,
                        personalReferral: false,
                        lang: lang
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            page: query.page ? query.page : 0,
                            items: result.items,
                            total: result.total,
                        }
                })

            }
        ]
    },
    {
        path: "/my-data/personal-referrals/referrals",
        component: Refferals,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Articles'.translate(lang) /*+ ' - Curoflow'.translate(lang)*/,
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/referrals/patient', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        entries: query.entries,
                        filter: query.filter,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        query: queryFields,
                        personalReferral: true,
                        lang: lang
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            page: query.page ? query.page : 0,
                            items: result.items,
                            total: result.total,
                            resultFilter: result.filter
                        }
                })

            },
            (fetchFunction, lang, match, query) => {


                return fetchFunction(API_ENDPOINT + '/data/regions', {
                    method: 'GET',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            regions: result,
                        }
                })

            },
        ]
    },
    {
        path: "/my-data/personal-referrals/create-new-case",
        component: CreateNewCase,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang) => {
            return {
                title: 'New case'.translate(lang) /*+ ' - Curoflow'.translate(lang)*/,
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [

        ]
    },
    {
        path: "/waiting-room",
        component: WaitingRoom,
        disableAvailablePaths: true,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Waiting room'.translate(lang) /*+ ' - Curoflow'.translate(lang)*/,
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: []
    },
    {
        path: "/chat",
        component: Chat,
        disableAvailablePaths: true,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Chat'.translate(lang) /*+ ' - Curoflow'.translate(lang)*/,
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {

                let queryFields = {};


                return fetchFunction(API_ENDPOINT + '/users/account/chat/clinic-phrase', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({

                        group: query.groupId,
                        clinic: query.clinic,
                        query: queryFields,
                        filter: query.filterPhrases,

                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            ...result
                        }
                })

            },


            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                // if (query.groupId)
                return fetchFunction(API_ENDPOINT + '/data/chat-form-questionary/all', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({

                        // group: query.groupId,    
                        // clinic: query.clinic,
                        query: queryFields,
                        filter: query.filter,
                        group: query.groupId
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300 && query.groupId)
                        return {
                            generalHealthCare: result,
                        }
                })

            },
        ]
    },
    {
        path: "/video-call/:conversation",
        disableAvailablePaths: true,
        component: Chat,
        //loginNeeded: true,
        //preAuthComponent: Login,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Video'.translate(lang) /*+ ' - Curoflow'.translate(lang)*/,
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {

                let queryFields = {};


                return fetchFunction(API_ENDPOINT + '/users/account/chat/clinic-phrase', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({

                        group: query.groupId,
                        clinic: query.clinic,
                        query: queryFields,
                        filter: query.filterPhrases,

                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            ...result
                        }
                })

            },
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                // if (query.groupId)
                return fetchFunction(API_ENDPOINT + '/data/general-health-care', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({

                        // group: query.groupId,    
                        // clinic: query.clinic,
                        query: queryFields,
                        filter: query.filter,
                        groupId: query.groupId
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300 && query.groupId)
                        return {
                            generalHealthCare: result,
                        }
                })

            },
        ]
    },
    {
        path: "/chat/:windowConversation",
        disableAvailablePaths: true,
        component: Chat,
        //loginNeeded: true,
        //preAuthComponent: Login,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Chat'.translate(lang) /*+ ' - Curoflow'.translate(lang)*/,
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {

                let queryFields = {};


                return fetchFunction(API_ENDPOINT + '/users/account/chat/clinic-phrase', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({

                        group: query.groupId,
                        clinic: query.clinic,
                        query: queryFields,
                        filter: query.filterPhrases,

                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            ...result
                        }
                })

            },
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                // if (query.groupId)
                return fetchFunction(API_ENDPOINT + '/data/chat-form-questionary/all', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({

                        // group: query.groupId,    
                        // clinic: query.clinic,
                        query: queryFields,
                        filter: query.filter,
                        group: query.groupId
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300 && query.groupId)
                        return {
                            generalHealthCare: result,
                        }
                })

            },
        ]
    },

    {
        path: "/:alias/choose-time",
        component: WebBookingReferral,
        loginNeeded: false,
        // preAuthComponent: Login,
        disableAvailablePaths: true,
        generateSeoTags: (data, lang) => {
            return {
                title: data?.clinicGroup?.name ? 'Tidsbokning'.translate(lang) + ' | ' + data.clinicGroup.name : 'Tidsbokning'.translate(lang),
                'og:image': `${data && data.clinicGroup && data.clinicGroup.logo ? API_ENDPOINT + data.clinicGroup.logo : ''}`,
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {

                return fetchFunction(API_ENDPOINT + '/data/without-token/personal-questionary/all/' + match.params.alias, {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({})
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            clinicGroup: result.clinicGroup
                        }
                })

            },
            (fetchFunction, lang, match, query) => {


                return fetchFunction(API_ENDPOINT + '/data/regions', {
                    method: 'GET',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            regions: result,
                        }
                })

            },




        ]
    },
    {
        path: "/:mainAlias/:alias/choose-time",
        component: WebBookingReferral,
        loginNeeded: false,
        // preAuthComponent: Login,
        disableAvailablePaths: true,
        generateSeoTags: (data, lang) => {
            return {
                title: data?.seoData?.name ? 'My E-self-referrals'.translate(lang) + ' | ' + data?.seoData?.name : 'My E-self-referrals'.translate(lang),
                'og:image': data?.seoData?.logo ? API_ENDPOINT + data?.seoData?.logo : '',
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {


                return fetchFunction(API_ENDPOINT + '/landing/page', {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({ alias: match.params.alias })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            seoData: result,
                        }
                })

            },
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/data/personal-questionary/get-all/' + match.params.alias, {
                    method: 'GET',
                    headers: {
                        // 'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            ...result
                        }
                })

            },
            (fetchFunction, lang, match, query) => {


                return fetchFunction(API_ENDPOINT + '/data/regions', {
                    method: 'GET',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            regions: result,
                        }
                })

            },


            (fetchFunction, lang, match, query) => {


                return fetchFunction(API_ENDPOINT + '/landing/page', {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({ alias: match.params.alias })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            seoData: result,
                        }
                })

            },

        ]
    },
    {
        path: "/:alias/personal-referral",
        component: BookingByClinicReferral,
        loginNeeded: true,
        preAuthComponent: Login,
        disableAvailablePaths: true,
        generateSeoTags: (data, lang) => {
            return {
                title: data?.seoData?.name ? data.isDropIn ? 'Drop in'.translate(lang) + ' | ' + data?.seoData?.name : 'Klinikbokning'.translate(lang) + ' | ' + data?.seoData?.name : data.isDropIn ? 'Drop in'.translate(lang) : 'Klinikbokning'.translate(lang),
                'og:image': data?.seoData?.logo ? API_ENDPOINT + data?.seoData?.logo : '',
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {


                return fetchFunction(API_ENDPOINT + '/landing/page', {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({ alias: match.params.alias })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            seoData: result,
                        }
                })

            },
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/data/personal-questionary/all/' + match.params.alias, {
                    method: 'GET',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            // ...result,
                            clinicGroup: result.clinicGroup
                        }
                })

            },
            (fetchFunction, lang, match, query) => {


                return fetchFunction(API_ENDPOINT + '/data/regions', {
                    method: 'GET',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            regions: result,
                        }
                })

            },


        ]
    },
    {
        path: "/:mainAlias/:alias/personal-referral",
        component: BookingByClinicReferral,
        loginNeeded: true,
        preAuthComponent: Login,
        disableAvailablePaths: true,
        generateSeoTags: (data, lang) => {
            return {
                title: data?.seoData?.name ? data.isDropIn ? 'Drop in'.translate(lang) + ' | ' + data?.seoData?.name : 'Klinikbokning'.translate(lang) + ' | ' + data?.seoData?.name : data.isDropIn ? 'Drop in'.translate(lang) : 'Klinikbokning'.translate(lang),
                'og:image': data?.seoData?.logo ? API_ENDPOINT + data?.seoData?.logo : '',
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {


                return fetchFunction(API_ENDPOINT + '/landing/page', {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({ alias: match.params.alias })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            seoData: result,
                        }
                })

            },
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/data/personal-questionary/all/' + match.params.alias, {
                    method: 'GET',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            clinicGroup: result.clinicGroup
                            // ...result
                        }
                })

            },
            (fetchFunction, lang, match, query) => {


                return fetchFunction(API_ENDPOINT + '/data/regions', {
                    method: 'GET',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            regions: result,
                        }
                })

            },


        ]
    },

    {
        path: "/:alias/drop-in",
        component: DropInReferral,
        loginNeeded: true,
        preAuthComponent: Login,
        disableAvailablePaths: true,
        generateSeoTags: (data, lang) => {
            return {
                title: (data?.seoData?.name ? 'Drop in'.translate(lang) + ' | ' + data?.seoData?.name : 'Drop in'.translate(lang)) /*+ ' - Curoflow'.translate(lang)*/,
                description: '',
                'og:image': data?.seoData?.logo ? API_ENDPOINT + data?.seoData?.logo : '',
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {


                return fetchFunction(API_ENDPOINT + '/landing/page', {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({ alias: match.params.alias })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            seoData: result,
                        }
                })

            },
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/data/personal-questionary/all/' + match.params.alias, {
                    method: 'GET',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                }).then(parseJSON).then(({ result, status }) => {
                    console.log(result);
                    if (status >= 200 && status < 300)
                        return {
                            // ...result,
                            clinicGroup: result.clinicGroup
                        }
                })

            },
            (fetchFunction, lang, match, query) => {


                return fetchFunction(API_ENDPOINT + '/data/regions', {
                    method: 'GET',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            regions: result,
                        }
                })

            },


        ]
    },
    {
        path: "/:mainAlias/:alias/drop-in",
        component: DropInReferral,
        loginNeeded: true,
        preAuthComponent: Login,
        disableAvailablePaths: true,
        generateSeoTags: (data, lang) => {
            return {
                title: (data?.seoData?.name ? 'Drop in'.translate(lang) + ' | ' + data?.seoData?.name : 'Drop in'.translate(lang)) /*+ ' - Curoflow'.translate(lang)*/,
                description: '',
                'og:image': data?.seoData?.logo ? API_ENDPOINT + data?.seoData?.logo : '',
            }
        },
        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {


                return fetchFunction(API_ENDPOINT + '/landing/page', {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({ alias: match.params.alias })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            seoData: result,
                        }
                })

            },
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/data/personal-questionary/all/' + match.params.alias, {
                    method: 'GET',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            ...result
                        }
                })

            },
            (fetchFunction, lang, match, query) => {


                return fetchFunction(API_ENDPOINT + '/data/regions', {
                    method: 'GET',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            regions: result,
                        }
                })

            },


        ]
    },
    {
        path: "/media-gateway",
        component: MediaGateway,
        disableAvailablePaths: true,
        generateSeoTags: (data, lang) => {
            return {
                title: "Media gateway",
                description: ''
            };
        },
        loadData: [],
    },


    {
        path: "/swish",
        component: Swish,
        disableAvailablePaths: true,
        generateSeoTags: (data, lang) => {
            return {
                title: "Swish test",
                description: ''
            };
        },
        loadData: [],
    },

    {
        path: "/:alias/login",
        component: LandingLoginPage,
        disableAvailablePaths: true,
        generateSeoTags: (data, lang) => {
            return {
                title: (data?.seoData?.name ? 'LogIn'.translate(lang) + ' | ' + data?.seoData?.name : 'LogIn'.translate(lang)) /*+ ' - Curoflow'.translate(lang)*/,
                description: '',
                'og:image': data?.seoData?.logo ? API_ENDPOINT + data?.seoData?.logo : '',
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {


                return fetchFunction(API_ENDPOINT + '/landing/page', {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({ alias: match.params.alias })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            seoData: result,
                        }
                })

            },
        ]
    },
    {
        path: "/:mainAlias/:alias/login",
        component: LandingLoginPage,
        disableAvailablePaths: true,
        generateSeoTags: (data, lang) => {
            return {
                title: (data?.seoData?.name ? 'LogIn'.translate(lang) + ' | ' + data?.seoData?.name : 'LogIn'.translate(lang)) /*+ ' - Curoflow'.translate(lang)*/,
                description: '',
                'og:image': data?.seoData?.logo ? API_ENDPOINT + data?.seoData?.logo : '',
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {


                return fetchFunction(API_ENDPOINT + '/landing/page', {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({ alias: match.params.alias })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            seoData: result,
                        }
                })

            },
        ]
    },

    {
        path: "/:alias/listing",
        component: ListingPage,
        disableAvailablePaths: true,
        generateSeoTags: (data, lang) => {
            let obj = {
                title: (data?.seoData?.name ? 'Listing'.translate(lang) + ' | ' + data?.seoData?.name : 'Listing'.translate(lang)) /*+ ' - Curoflow'.translate(lang)*/,
                description: '',
                'og:image': data?.seoData?.logo ? API_ENDPOINT + data?.seoData?.logo : '',
                // 'apple-itunes-app': `app-id=${data && data.seoData && data.seoData.appStoreId ? data.seoData.appStoreId : ''}`

            }
            return obj;
        },

        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {


                return fetchFunction(API_ENDPOINT + '/landing/page', {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({ alias: match.params.alias })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            seoData: result,
                        }
                })

            },


        ]
    },
    {
        path: "/:alias/e-connect",
        component: EconnectExternalPage,
        disableAvailablePaths: true,
        generateSeoTags: (data, lang) => ({
            title: data?.seoData?.name ? data?.seoData?.name + ' | E-Connect' : 'E-Connect',
            description: '',
            'og:image': data?.seoData?.logo ? API_ENDPOINT + data?.seoData?.logo : '',
            'apple-itunes-app': `app-id=${data && data.seoData && data.seoData.appStoreId ? data.seoData.appStoreId : ''}`
        }),
        loadData: [],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => fetchFunction(API_ENDPOINT + '/landing/page', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json'
                },
                body: JSON.stringify({ alias: match?.params?.alias })
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300) return { seoData: result }
            })
        ]
    },

    {
        path: "/patient/diary-graph",
        component: PatientDiaryGraph,
        disableAvailablePaths: true,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Curoflow'.translate(lang),
                description: ''

            }
        },

        loadData: [

        ],
        loadDataWithQuery: [


        ]
    },
    {
        path: "/statement/:alias/:statement",
        component: StatmentPage,
        disableAvailablePaths: true,
        generateSeoTags: (data, lang) => {
            let obj = {
                title: (data?.seoData?.name ? data?.seoData?.name : '') /*+ ' - Curoflow'.translate(lang)*/,
                description: '',
                'og:image': data?.seoData?.logo ? API_ENDPOINT + data?.seoData?.logo : '',
                'apple-itunes-app': `app-id=${data && data.seoData && data.seoData.appStoreId ? data.seoData.appStoreId : ''}`

            }
            return obj;
        },

        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {


                return fetchFunction(API_ENDPOINT + '/landing/page', {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({ alias: match.params.alias })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            seoData: result,
                        }
                })

            },


        ]
    },
    {
        path: "/:alias",
        component: landingPage,
        disableAvailablePaths: true,
        generateSeoTags: (data, lang) => {
            let obj = {
                title: (data?.seoData?.name ? data?.seoData?.name : '') /*+ ' - Curoflow'.translate(lang)*/,
                description: '',
                'og:image': data?.seoData?.logo ? API_ENDPOINT + data?.seoData?.logo : '',
                'apple-itunes-app': `app-id=${data && data.seoData && data.seoData.appStoreId ? data.seoData.appStoreId : ''}`

            }
            return obj;
        },

        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {


                return fetchFunction(API_ENDPOINT + '/landing/page', {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({ alias: match.params.alias })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            seoData: result,
                        }
                })

            },


        ]
    },
    {
        path: "/:mainAlias/:alias",
        component: landingPage,
        disableAvailablePaths: true,
        generateSeoTags: (data, lang) => {

            let obj = {
                title: (data?.seoData?.name ? data?.seoData?.name : '') /*+ ' - Curoflow'.translate(lang)*/,
                description: '',
                'og:image': data?.seoData?.logo ? API_ENDPOINT + data?.seoData?.logo : '',
                'apple-itunes-app': `app-id=${data && data.seoData && data.seoData.appStoreId ? data.seoData.appStoreId : ''}`


            }

            return obj;
        },

        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {


                return fetchFunction(API_ENDPOINT + '/landing/page/clinic-group', {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({ alias: match.params.alias, mainAlias: match.params.mainAlias })
                }).then(parseJSON).then(({ result, status }) => {

                    if (status >= 200 && status < 300)
                        return {
                            seoData: result,
                        }
                })

            },


        ]
    },
    {
        path: "/:alias/forms/:id",
        component: RedirectFormPage,
        disableAvailablePaths: true,
        generateSeoTags: (data, lang) => {
            let obj = {
                title: (data?.seoData?.name ? data?.seoData?.name : '') /*+ ' - Curoflow'.translate(lang)*/,
                description: '',
                'og:image': data?.seoData?.logo ? API_ENDPOINT + data?.seoData?.logo : '',
                'apple-itunes-app': `app-id=${data && data.seoData && data.seoData.appStoreId ? data.seoData.appStoreId : ''}`

            }
            return obj;
        },

        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {


                return fetchFunction(API_ENDPOINT + '/landing/page', {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({ alias: match.params.alias })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            seoData: result,
                        }
                })

            },


        ]
    },
    {
        path: "/:mainAlias/:alias/forms/:id",
        component: RedirectFormPage,
        disableAvailablePaths: true,
        generateSeoTags: (data, lang) => {

            let obj = {
                title: (data?.seoData?.name ? data?.seoData?.name : '') /*+ ' - Curoflow'.translate(lang)*/,
                description: '',
                'og:image': data?.seoData?.logo ? API_ENDPOINT + data?.seoData?.logo : '',
                'apple-itunes-app': `app-id=${data && data.seoData && data.seoData.appStoreId ? data.seoData.appStoreId : ''}`


            }

            return obj;
        },

        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {


                return fetchFunction(API_ENDPOINT + '/landing/page/clinic-group', {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({ alias: match.params.alias, mainAlias: match.params.mainAlias })
                }).then(parseJSON).then(({ result, status }) => {

                    if (status >= 200 && status < 300)
                        return {
                            seoData: result,
                        }
                })

            },


        ]
    },
    {
        path: "/video-call-gateway/:conversation/:roomid/:initiator/:group/:userId",
        component: VideoCallRedirect,
        disableAvailablePaths: true,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Video Call'.translate(lang) /*+ ' - Curoflow'.translate(lang)*/,
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [


        ]
    },





];

export function generateRoutes(r) {
    let newRoutes = [];
    for (let i = 0; i < r.length; i++) {
        newRoutes.push(r[i]);
    }
    return newRoutes;
}
