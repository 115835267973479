import React from 'react';

import {
    Modal, ModalHeader, ModalBody, ModalFooter, Button
} from 'reactstrap';

import FormBuilder from './forms/formBuilder';
import { required, emailAddress } from './forms/validation'
import { connect } from "react-redux";
import { formValueSelector, change } from "redux-form"; // ES6

import { API_ENDPOINT } from '../constants'

import FaceID from "../faceApi/loginComponent";

import backIcon from '../assets/svg/back-icon.svg'
// import BankIDModal_v2 from './bankIDModal_v2';
import BankIDModal_v5 from './bankIDModal_v5';
import Isvg from "react-inlinesvg";

import { Player, Controls } from "@lottiefiles/react-lottie-player";


function parseJSON(response) {
    return response.json().then(json => {
        return {
            result: json,
            status: response.status
        }
    })
}
/**
* BankID modal for login
* @author   Milan Stanojevic
*/
class BankId extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            lastLoggedUser: null,
            faceId: null,
            openFirstPatientLoginModal: true
        }
    }

    componentDidMount() {
        // console.log(this.props)
        window.addEventListener('message', (e) => {
            return
            if (e.data == 'loggedin') {

                localStorage.setItem('loginLandingPage', true);
                localStorage.setItem('logoutLandingPage', true);
                if (localStorage.getItem('groupAlias'))
                    localStorage.setItem('loginGroupAlias', localStorage.getItem('groupAlias'));
                let setVerifyRedirectLink;
                if (this.props.setVerifyRedirectLink) {
                    setVerifyRedirectLink = this.props.setVerifyRedirectLink
                }
                this.props.verifyUser((uData) => {
                    if (this.props.openLastLoginLanding && this.props.clinicGroupsItems && this.props.clinicGroupsItems.length) {
                        if (uData && uData.lastVisitedGroups && uData.lastVisitedGroups.length) {
                            let lastVisitedGroups = uData.lastVisitedGroups.reverse();
                            for (let i = 0; i < lastVisitedGroups.length; i++) {
                                if (this.props.clinicGroupsItems.filter(el => el.alias == lastVisitedGroups[i].alias).length) {
                                    alias = lastVisitedGroups[i].alias;
                                    break;
                                }
                            }
                            let alias;
                            if (alias) {
                                this.props.toggle(alias);
                            } else {
                                this.props.toggle();
                            }
                        } else {
                            this.props.toggle();
                        }
                    } else {
                        this.props.toggle();
                    }

                }, null, false, setVerifyRedirectLink);

            } else if (e.data == 'loggedin_newuser') {
                localStorage.setItem('loginLandingPage', true);
                localStorage.setItem('logoutLandingPage', true);
                if (localStorage.getItem('groupAlias'))
                    localStorage.setItem('loginGroupAlias', localStorage.getItem('groupAlias'));

                let setVerifyRedirectLink;
                if (this.props.setVerifyRedirectLink) {
                    setVerifyRedirectLink = this.props.setVerifyRedirectLink
                }
                this.props.verifyUser(() => {
                    if (this.props.toggle) {
                        this.props.toggle()
                    }
                }, null, true, setVerifyRedirectLink);
                this.setState({
                    loggedIn: true
                })

            }
        }, false)


        if (this.props.uData) {
            this.setState({
                loggedIn: true
            })
        }

        if (this.props.checkLastLogin && typeof window != 'undefined' && localStorage.sessionId && this.props.faceIdEnv) {
            fetch(API_ENDPOINT + '/users/session/check/last/logged', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json'
                },
                body: JSON.stringify({ sessionId: localStorage.sessionId })
            }).then(parseJSON).then(({ result, status }) => {
                if (result && result.users && result.users.length) {
                    this.setState({ lastLoggedUsers: result.users })
                }
                // if (result && result.user) {
                //   this.setState({ lastLoggedUser: result.user })
                // }

            })
        }
    }


    update = (data) => {

        fetch(API_ENDPOINT + '/users/account/update', {
            method: 'POST',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(parseJSON).then(({ result, status }) => {
            if (result.error) {
                this.setState({ error: result.error.translate(this.props.lang) })
            } else {
                this.setState({
                    message: result.message,
                    openFirstPatientLoginModal: false
                })
                this.props.verifyUser();
                this.props.toggle();
            }

        })


    }
    componentDidUpdate(prevProps) {
        if (prevProps.sex != this.props.sex) {
            if (this.props.sex == 0) {
                this.props.changeSex(null)
            }
        }

    }
    checkPhoto = (photo) => {
        this.setState({ loadingFaceId: true }, () => {
            let obj = { photo: photo }
            if (typeof window != 'undefined' && localStorage.sessionId) {
                obj.sessionId = localStorage.sessionId;
                obj.userId = this.state.faceId
            }
            fetch(API_ENDPOINT + "/users/login/check/face-id", {
                method: "POST",
                headers: {
                    "content-type": "application/json",
                },
                body: JSON.stringify(obj),
            }).then((res) => res.json()).then((result) => {
                if (result && result.user) {

                    this.setState({ loadingFaceId: false, succesScanning: true }, () => {
                        setTimeout(() => {
                            this.setState({ faceId: null })
                            if (result.user.token) {
                                if (typeof window != 'undefined') {
                                    localStorage.setItem('loginLandingPage', true);
                                    localStorage.setItem('logoutLandingPage', true);
                                    localStorage.setItem("authToken", result.user.token);
                                    localStorage.setItem("time", Math.floor(Date.now() / 1000));
                                    if (localStorage.getItem('groupAlias'))
                                        localStorage.setItem('loginGroupAlias', localStorage.getItem('groupAlias'));
                                    this.props.registerLog("Logged in");
                                    let setVerifyRedirectLink;
                                    if (this.props.setVerifyRedirectLink) {
                                        setVerifyRedirectLink = this.props.setVerifyRedirectLink
                                    }
                                    this.props.verifyUser(() => {
                                        this.props.toggle();
                                    }, null, false, setVerifyRedirectLink);


                                }
                            }
                        }, 2000);
                    })
                } else {
                    this.setState({ loadingFaceId: false, noMatchError: true }, () => {
                        setTimeout(() => {
                            this.setState({ noMatchError: false })
                        }, 2000);
                    })
                }
            });

        })

    }


    render() {
        return (
            <div className="bankid-modal">

                <>
                    {/* {
                        !this.state._iframeLoaded ?
                            <div className="loader-wrap">
                                <Player
                                    autoplay={true}
                                    loop={true}
                                    src="/lf30_editor_l5cxzdyf.json"
                                    style={{
                                        height:
                                            typeof window != "undefined" &&
                                                window.innerWidth < 768
                                                ? "128px"
                                                : "256px",
                                        width:
                                            typeof window != "undefined" &&
                                                window.innerWidth < 768
                                                ? "128px"
                                                : "256px",
                                    }}
                                ></Player>
                            </div>
                            :
                            null
                    } */}

                    {/* <iframe ref={(node) => this.bankIdIframe = node} onLoad={() => {
                        console.log('onload')
                        try {
                            if (this.bankIdIframe && this.bankIdIframe.contentWindow && this.bankIdIframe.contentWindow.location && this.bankIdIframe.contentWindow.location.href && this.bankIdIframe.contentWindow.location.href.indexOf('/bankid/v2') !== -1) {
                                this.setState({
                                    _hideIframe: true
                                })
                            }
                        } catch (e) { }
                        this.setState({ _iframeLoaded: true })
                    }} src={this.props.redirect_url} style={this.state._hideIframe ? { opacity: 0 } : { width: '100%', height: this.state._iframeLoaded ? 360 : 0, border: 0 }} /> */}
                    {/* <BankIDModal_v2 {...this.props} alias={this.props.alias}/> */}
                    <BankIDModal_v5 {...this.props} alias={this.props.alias} />
                </>

                <p className="error-message">{this.state.error}</p>


            </div>
        )
    }
}



export default BankId;