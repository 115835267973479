
import React, { Component } from 'react';

import { Editor } from '@tinymce/tinymce-react';
import { formatCSS } from '../cssHelper';
import InverseRadius from './inverseRadius';
/**
* Component for html field
* @author   Milan Stanojevic
*/
class Text extends Component {
    constructor(props) {
        super(props);

        this.state = {

        };
    }

    componentDidMount() {
        // console.log('mounting', this.props.id)
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.value == this.props.value && JSON.stringify(this.props.style) == JSON.stringify(nextProps.style)) {
            return false;
        }

        return true;
    }

    render() {
        // if (this.props.id == '08724d37-4a76-4351-91c0-6f00ddf9aa8f')
        //     console.log(this.props.id, this.props.value)

        let fontFormats = `Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Montserrat=montserrat, sans-serif; Symbol=symbol; Museo Sans Rounded 500=MuseoSansRounded500, sans-serif;Museo Sans Rounded 700=MuseoSansRounded700, sans-serif; Open Sans=OpenSansRegular, sans-serif; Open Sans SemiBold=OpenSansSemiBold, sans-serif; Poppins=poppins, sans-serif; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats;`;
        if (this.props.selectedGroup && (String(this.props.selectedGroup) === '6332d8bd39a2266ce2e07f15' || String(this.props.selectedGroup) === '62b2fb15339558d6374d19dc')) {
            // console.log(this.props);
            fontFormats = fontFormats + ` Flecha M Regular=FlechaMRegular, serif; Flecha M Regular Italic=FlechaMRegularItalic, serif; Sohne Leicht=SohneLeicht, sans-serif; Sohne Leicht Kursiv=SohneLeichtKursiv, sans-serif; Sohne Halbfett=SohneHalbfett, sans-serif; Sohne Halbfett Kursiv=SohneHalbfettKursiv, sans-serif; Sohne Kraftig=SohneKraftig, sans-serif; Sohne Kraftig Kursiv=SohneKraftigKursiv, sans-serif;`;
        }
        return (
            <div style={{ ...formatCSS(this.props.style), position: 'relative' }}>
                {
                    this.props.style.borderTopLeftRadius || this.props.style.borderTopRightRadius ||
                        this.props.style.borderBottomLeftRadius || this.props.style.borderBottomRightRadius ?
                        <InverseRadius style={this.props.style} />
                        :
                        null


                }
                <Editor
                    id={this.props.id}
                    inline={true}
                    menubar={false}
                    valid_styles={{
                        '*': 'font-size,font-family,color,text-decoration,text-align'
                    }}
                    toolbar={[
                        'undo redo | bold italic underline | fontselect fontsizeselect',
                        'forecolor backcolor | alignleft aligncenter alignright alignfull | numlist bullist outdent indent | link'
                    ]}

                    apiKey="ubxmhz75z3m6zd19co7o8zl3y4g4vsjnygpm76aq9cfj88d8"
                    init={{ plugins: 'link table code', remove_script_host: false, relative_urls: false, menubar: false, fontsize_formats: '8pt 10pt 12pt 14pt 16pt 18pt 20pt 22pt 24pt 26pt 28pt 30pt 32pt 34pt 36pt 38pt 40pt 42pt 44pt 46pt 48pt', font_formats: fontFormats }}
                    value={this.props.multilang ? (this.props.value && this.props.value[this.props.lang]) ? this.props.value[this.props.lang] : '' : this.props.value ? this.props.value : ''}
                    onEditorChange={(val) => {

                        if (this.props.multilang) {
                            let value = this.props.value;
                            if (!value) {
                                value = {};
                            }

                            if (value[this.props.lang] != val) {
                                value[this.props.lang] = val;


                                this.props.onChange(value);
                            }
                        } else {
                            if (val != this.props.value) {
                                this.props.onChange(val);
                            }
                        }

                        //this.forceUpdate();



                    }} />

            </div>




        );
    }
}

export default Text;