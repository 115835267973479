import React, { Component } from 'react';
import Page from '../../../containers/page';

import {
    Container,
    Row,
    Col, Input, Label, Modal, ModalBody, ModalHeader, FormGroup, Button, ModalFooter
} from 'reactstrap';

import EventComponent from './EventComponentt'

import FormBuilder from '../../../components/forms/modalFormBuilder';
import { required } from '../../../components/forms/validation';

import ListBuilder from '../../../components/listBuilder';
import DeleteModal from '../../../components/deleteModal';
import ErrorModal from '../../../components/errorModal';
import { getSearchParams, generateSearchLink, generateSearchLinkMultiple } from '../../../helpers/linkHelper';
import { API_ENDPOINT } from '../../../constants';

import TimePicker from '../../../components/forms/fields/timepicker';

import Isvg from 'react-inlinesvg'
import Search from '../../../components/search';
import PatientFilter from '../../../components/patientFilter';
import profileUser from '../../../assets/images/user.png'
import notification from '../../../assets/svg/notification.svg';
import moreIcon from '../../../assets/svg/more.svg';

import { Calendar, Day, momentLocalizer, Navigate } from 'react-big-calendar';
import MyWorkWeek from './MyWorkWeek';
import PatientCustomToolbar from './patientCustomToolbar';
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Tooltip } from '@material-ui/core';
import { withStyles, makeStyles } from "@material-ui/core/styles";
import warrning from '../../../assets/svg/warrning.svg';

const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: "rgba(58, 63, 85, 0.9)",
        color: "#fff",
        boxShadow: theme.shadows[1],
        fontSize: 14
    }
}))(Tooltip);



import moment from 'moment';
function parseJSON(response) {
    return response.json().then(json => {
        return {
            result: json,
            status: response.status
        }
    })
}


const localizer = momentLocalizer(moment)

class TestCalendar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props.initialData,
            form: false,
            useState: false,
            params: {
                entries: 10,
                page: 0
            },
            items: [],
            total: 0,
            loading: true,
            step: 5,
            referrals: [],
            view: 'week',
            doctors: [],
            clinics: []

        };
        this.state.min = new Date();
        this.state.min.setHours(8, 30, 0)
        this.state.max = new Date();
        this.state.max.setHours(20, 30, 0)
    }


    setWorkingTimeConfig = () => {
        let obj = {

        };
        obj.min = new Date();
        obj.min.setHours(8, 30, 0)
        obj.max = new Date();
        obj.max.setHours(20, 30, 0)

        if (this.props.uData && this.props.uData.workingTimeConfig && this.props.uData.workingTimeConfig[this.props.selectedGroup] && this.props.uData.workingTimeConfig[this.props.selectedGroup][this.props.selectedClinic]) {
            //alert(true);
            obj = this.props.uData.workingTimeConfig[this.props.selectedGroup][this.props.selectedClinic];
            obj.min = new Date(obj.min);
            obj.max = new Date(obj.max);
        }

        this.setState({
            ...obj
        })
    }

    get = () => {
        this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));



        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0, alias: localStorage.getItem('groupAlias') })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

        for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
            this.props.loadDataWithQuery[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0, alias: localStorage.getItem('groupAlias'), clinic: this.state.clinic, doctor: this.state.doctor, patient: this.props.uData._id, group: this.state.groupId })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }
        fetch(API_ENDPOINT + '/patient-referrals/all', {
            method: 'POST',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
            body: JSON.stringify({
                patient: this.props.uData._id,
                // clinic: this.state.clinic
            })
        }).then(res => res.json()).then((result) => {
            // console.log(result[0]._id)
            if (result) {
                this.setState({
                    referrals: result,
                    // referralId: result.length ? result[0]._id : null

                })
            }
            // this.props.setPageTotal(result.total);
        })

        this.setWorkingTimeConfig();

        fetch(API_ENDPOINT + '/data/clinic-groups/patient-data', {
            method: 'POST',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
            body: JSON.stringify({
                alias: localStorage.getItem('groupAlias')
            })
        }).then(res => res.json()).then((result) => {
            if (result) {
                this.setState({
                    clinics: result.clinics,
                    groupId: result.groupId
                })
            }
            // this.props.setPageTotal(result.total);
        })
        // console.log(this.state.referrals)



    }

    componentWillUnmount() {
        fetch(API_ENDPOINT + '/referrals/delete-last/' + this.props.uData._id, {
            method: 'DELETE',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
        }).then(res => res.json()).then((result) => {

        })
    }

    componentDidMount() {
        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }



        this.get();

    }

    componentDidUpdate(prevProps) {
        if (prevProps[0].location.search != this.props[0].location.search) {
            this.setState({
                loading: true
            }, () => {
                this.get();

            })
        }

        if (prevProps.selectedGroup != this.props.selectedGroup || prevProps.selectedClinic != this.props.selectedClinic) {
            this.setWorkingTimeConfig();
            this.get();

        }

        if (this.state.referrals && this.state.referrals.length && !this.state.referralId && !this.state.doNotUpdate) {
            this.setState({
                referralId: this.state.referrals[0]._id,
                doNotUpdate: true
            })

            if (this.state.referrals[0]._id) {
                for (let k = 0; k < this.state.referrals.length; k++) {
                    if (this.state.referrals[0]._id == this.state.referrals[k]._id) {
                        this.setState({
                            clinic: this.state.referrals[k].recipientClinic
                        }, () => this.get())
                        // console.log(this.state.referrals[k].recipientClinic)
                    }
                }
            } else {
                this.setState({
                    doctors: []
                })
            }
        }
    }

    scheduleEvent = (data) => {
        let checkDate = new Date(data.date.replace(/-/g, "/") + ' ' + data.startTime);
        let today = new Date();

        if (checkDate >= today) {
            this.setState({
                loading: true
            }, () => {

                fetch(API_ENDPOINT + '/working-hours/new', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({ startEventReservedDateOriginal: data.date + ' ' + data.startTime, endEventReservedDateOriginal: data.date + ' ' + data.endTime,startEventReservedDate: this.props.getStringDateInverseTs(Math.floor(new Date(data.date + ' ' + data.startTime).getTime() / 1000), `${this.props.dateFormat} HH:mm`), endEventReservedDate: this.props.getStringDateInverseTs(Math.floor(new Date(data.date + ' ' + data.endTime).getTime() / 1000), `${this.props.dateFormat} HH:mm`), doctor: this.props.uData._id, title: data.title, group: this.props.selectedGroup, clinic: this.props.selectedClinic })
                }).then(res => res.json()).then((result) => {
                    if (result.error) {
                        this.setState({
                            error: result.error.translate(this.props.lang)
                        })
                    } else {
                        this.get()
                        this.setState({
                            scheduleModal: null,

                        })
                    }

                })
            })
        } else {
            this.setState({
                wrongDate: true
            })
        }

    }

    scheduleControl = (data) => {
        let checkDate = new Date(data.date.replace(/-/g, "/") + ' ' + data.startTime);
        let today = new Date();
        //console.log(data)

        if (checkDate >= today) {
            this.setState({
                loading: true
            }, () => {

                fetch(API_ENDPOINT + '/working-hours/control', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({ startEventReservedDateOriginal: data.date + ' ' + data.startTime, endEventReservedDateOriginal: data.date + ' ' + data.endTime,startEventReservedDate: this.props.getStringDateInverseTs(Math.floor(new Date(data.date + ' ' + data.startTime).getTime() / 1000), `${this.props.dateFormat} HH:mm`), endEventReservedDate: this.props.getStringDateInverseTs(Math.floor(new Date(data.date + ' ' + data.endTime).getTime() / 1000), `${this.props.dateFormat} HH:mm`), title: data.title, socialSecurityNumber: data.socialSecurityNumber, doctor: this.props.uData._id, title: data.title, group: this.props.selectedGroup, clinic: this.props.selectedClinic, patientControlNote: data.patientControlNote })
                }).then(res => res.json()).then((result) => {
                    if (result.error) {
                        this.setState({
                            error: result.error.translate(this.props.lang)
                        })
                    } else {
                        this.get()
                        this.setState({
                            scheduleModal: null,
                            referralId: null,
                            scheduleControl: null

                        })
                    }

                })
            })
        } else {
            this.setState({
                wrongDate: true
            })
        }

    }


    handleSelect = (event) => {
        var startDate = moment.unix(Math.floor(event.start.getTime() / 1000)).format('YYYY-MM-DD');
        let start = moment.unix(Math.floor(event.start.getTime() / 1000)).format('HH:mm')
        let ref = this.state.referrals.filter((item) => item._id == this.state.referralId);
        let minutes = Number(start.split(":")[0]) * 60 + Number(start.split(":")[1]) + Number(ref[0].questionaryDuration);
        let t1 = Math.trunc(minutes / 60);
        let t2 = minutes % 60;

        let eventEnd = t1 + ":" + t2;
        // console.log(event.start)
        var end = eventEnd;

        let obj = {
            date: startDate,
            startTime: start,
            endTime: end,
            title: ''

        }

        let checkDate = new Date(startDate + ' ' + start);
        let today = new Date();
        //console.log(data)

        if (checkDate >= today) {

            this.setState({
                scheduleReferral: obj
            })
        }
    }


    update = () => {

        let selectedGroup = this.props.selectedGroup;
        let selectedClinic = this.props.selectedClinic;

        let workingTimeConfig = this.props.uData.workingTimeConfig ? this.props.uData.workingTimeConfig : {};

        if (!workingTimeConfig[this.props.selectedGroup]) {
            workingTimeConfig[this.props.selectedGroup] = {};
        }


        if (!workingTimeConfig[this.props.selectedGroup][this.props.selectedClinic]) {
            workingTimeConfig[this.props.selectedGroup][this.props.selectedClinic] = {};
        }

        workingTimeConfig[this.props.selectedGroup][this.props.selectedClinic].min = this.state.min;
        workingTimeConfig[this.props.selectedGroup][this.props.selectedClinic].max = this.state.max;

        fetch(API_ENDPOINT + '/users/account/update/working-time-config', {
            method: 'POST',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
            body: JSON.stringify({ workingTimeConfig: workingTimeConfig })
        }).then(parseJSON).then(({ result, status }) => {
            if (result.error) {
                this.setState({ error: result.error.translate(this.props.lang) })
            } else {
                this.setState({
                    message: result.message
                })
                this.props.verifyUser(() => {
                    this.setWorkingTimeConfig();

                }, () => {
                    this.props.changeGroup(selectedGroup);
                    this.props.changeClinic(selectedClinic);

                });
            }
        })
    }

    selectedEvent = (event) => {
        var startDate = moment.unix(Math.floor(event.start.getTime() / 1000)).format('YYYY-MM-DD');
        let start = moment.unix(Math.floor(event.start.getTime() / 1000)).format('HH:mm')
        var end = moment.unix(Math.floor(event.end.getTime() / 1000)).format("HH:mm");

        let obj = {
            title: event.title,
            id: event.id,
            date: startDate,
            startTime: start,
            endTime: end,
            patientNote: event.patientNote,
            patientControlNote: event.patientControlNote,
            type: event.type,
            patientProfile: event.patientProfile
        }
        this.setState({
            updateEvent: obj
        })
    }

    updateEvent = (data) => {
        let checkDate = new Date(data.date.replace(/-/g, "/") + ' ' + data.startTime);
        let today = new Date();

        if (checkDate >= today) {
            this.setState({
                loading: true
            }, () => {

                fetch(API_ENDPOINT + '/working-hours/update/' + data.id, {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({ startEventReservedDateOriginal: data.date + ' ' + data.startTime, endEventReservedDateOriginal: data.date + ' ' + data.endTime,startEventReservedDate: this.props.getStringDateInverseTs(Math.floor(new Date(data.date + ' ' + data.startTime).getTime() / 1000), `${this.props.dateFormat} HH:mm`), endEventReservedDate: this.props.getStringDateInverseTs(Math.floor(new Date(data.date + ' ' + data.endTime).getTime() / 1000), `${this.props.dateFormat} HH:mm`), title: data.title, doctor: this.props.uData._id, patientNote: data.patientNote, patientControlNote: data.patientControlNote })
                }).then(res => res.json()).then((result) => {
                    if (result.error) {
                        this.setState({
                            error: result.error.translate(this.props.lang)
                        })
                    } else {
                        this.get()
                        this.setState({
                            updateEvent: null
                        })
                    }

                })
            })
        } else {
            this.setState({
                wrongDate: true
            })


        }
    }

    deleteEvent = (data) => {
        this.setState({
            loading: true
        }, () => {

            fetch(API_ENDPOINT + '/working-hours/delete/' + data.id, {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify({ startEventReservedDateOriginal: data.date + ' ' + data.startTime, endEventReservedDateOriginal: data.date + ' ' + data.endTime,startEventReservedDate: this.props.getStringDateInverseTs(Math.floor(new Date(data.date + ' ' + data.startTime).getTime() / 1000), `${this.props.dateFormat} HH:mm`), endEventReservedDate: this.props.getStringDateInverseTs(Math.floor(new Date(data.date + ' ' + data.endTime).getTime() / 1000), `${this.props.dateFormat} HH:mm`), title: data.title, doctor: this.props.uData._id })
            }).then(res => res.json()).then((result) => {
                this.get()
                this.setState({
                    updateEvent: null,
                    warningModal: null
                })
            })
        })
    }

    scheduleReferral = (data) => {

        if (this.state.referralId) {
            for (let i = 0; i < this.state.referrals.length; i++) {
                if (this.state.referralId == this.state.referrals[i]._id) {
                    this.setState({
                        referral: this.state.referrals[i]
                    })
                }
            }
        }

        let checkDate = new Date(data.date.replace(/-/g, "/") + ' ' + data.startTime);
        let today = new Date();

        if (checkDate >= today) {
            this.setState({
                loading: true
            }, () => {
                fetch(API_ENDPOINT + '/referrals/schedule/' + this.state.referral._id, {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({ startEventReservedDateOriginal: data.date + ' ' + data.startTime, endEventReservedDateOriginal: data.date + ' ' + data.endTime,startPatientReservedDate: this.props.getStringDateInverseTs(Math.floor(new Date(data.date + ' ' + data.startTime).getTime() / 1000), `${this.props.dateFormat} HH:mm`), endPatientReservedDate: this.props.getStringDateInverseTs(Math.floor(new Date(data.date + ' ' + data.endTime).getTime() / 1000), `${this.props.dateFormat} HH:mm`), doctor: this.state.doctor, patientNote: data.patientNote, patient: this.props.uData._id })
                }).then(res => res.json()).then((result) => {
                    if (result.error) {
                        this.setState({ error: result.error.translate(this.props.lang) })
                    } else {

                        this.setState({
                            scheduleReferral: null,
                            referralId: null,
                            // doctor: null

                        })

                        this.get();
                    }

                })
            })
        } else {
            this.setState({
                wrongDate: true
            })


        }
    }
    submitNote = () => {
        // console.log(this.props.event.referral);
        // console.log(this.state.note);
        // if (this.state.patientNote) {
        //     fetch(API_ENDPOINT + '/referrals/update/patient-note/' + this.state.referralNote, {
        //         method: 'POST',
        //         headers: {
        //             'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
        //             'content-type': 'application/json'
        //         },
        //         body: JSON.stringify({
        //             note: this.state.patientNote
        //         })
        //     }).then(res => res.json()).then((result) => {
        //         this.setState({
        //             referralNote: null
        //         })
        //         this.get()
        //     })
        // }

    }
    submitControlNote = () => {

        // if (this.state.patientNote) {
        //     fetch(API_ENDPOINT + '/working-hours/events/update/patient-note/' + this.state.controlNote, {
        //         method: 'POST',
        //         headers: {
        //             'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
        //             'content-type': 'application/json'
        //         },
        //         body: JSON.stringify({
        //             note: this.state.patientNote
        //         })
        //     }).then(res => res.json()).then((result) => {
        //         this.setState({
        //             controlNote: null
        //         })
        //         this.get()
        //     })
        // }

    }



    render() {
        console.log(this.state.referralId)
        let params = {};
        if (this.state.useState) {
            params = this.state.params;
        } else {
            params = getSearchParams(this.props[0].location.search, { entries: 10, page: 0 })
        }

        const humanReadable = {
            'waiting-for-approval': 'Waiting for approval',
            'disallowed': 'Disallowed',
            'allowed': 'Allowed',
            'return-for-update': 'Return for update',
            'patient-requested-another-time': 'Patient requested anoother time',
            'patient-rejected': 'Rejected by patient',
            'patient-accepted': 'Patient accepted',
            'clinic-accepted-requested-time': 'Clinic accepted requested time',
            'clinic-rejected-requested-time': 'Clinic rejected requested time',
            'approved-by-region-manager': 'Approved by region manager',
            'rejected-by-region-manager': 'Rejected by region manager',
            'scheduled': 'Scheduled',
            'not-booked': 'Not booked',
            'closed': 'Closed',
            'requested-new-appointment': 'Requested new appointment'.translate(this.props.lang),
            'external': 'External transfer'.translate(this.props.lang),

        }

        return (

            <div className={this.props.autoHideSidebar ? "dashboard dashboard-auto-hide" : "dashboard"}>
                <Container fluid>

                    <Row>
                        <Col lg='12'>
                            <div className='panel'>
                                <h5 className="component-header">

                                    {this.props.linksMeta && this.props.linksMeta[this.props[0].location.pathname] ? this.props.linksMeta[this.props[0].location.pathname][this.props.lang] : ''}
                                </h5>
                                <div className="schedule">
                                    <div style={{ display: 'flex' }}>

                                        <div style={{ display: 'flex', alignItems: 'center' }}> {'Referral:'.translate(this.props.lang)}
                                            <Input type='select' style={{ width: 200, margin: 10 }}

                                                onChange={e => this.setState({ referralId: e.target.value == -1 ? null : e.target.value }, () => {
                                                    if (this.state.referralId) {
                                                        for (let k = 0; k < this.state.referrals.length; k++) {
                                                            if (this.state.referralId == this.state.referrals[k]._id) {
                                                                this.setState({
                                                                    clinic: this.state.referrals[k].recipientClinic
                                                                }, () => this.get())
                                                                // console.log(this.state.referrals[k].recipientClinic)
                                                            }
                                                        }
                                                    } else {
                                                        this.setState({
                                                            doctors: []
                                                        })
                                                    }
                                                })}
                                                value={this.state.referralId}
                                            >
                                                {/* <option value={-1}>{'None'.translate(this.props.lang)}</option> */}


                                                {this.state.referrals.filter(item => item.status == 'waiting-for-approval').map(item => {
                                                    return <option value={item._id}>
                                                        {item.patient.name}{" " + humanReadable[item.status].translate(this.props.lang)}
                                                    </option>
                                                })}

                                            </Input>
                                        </div>

                                        <div style={{ display: 'flex', alignItems: 'center' }}> {'Doctor:'.translate(this.props.lang)}
                                            <Input type='select' style={{ width: 200, margin: 10 }}
                                                onChange={e => this.setState({ doctor: e.target.value == -1 ? null : e.target.value }, () => { this.get() })}>
                                                <option value={-1}>{'None'.translate(this.props.lang)}</option>

                                                {this.state.doctors.map(item => {
                                                    return <option value={item._id}>
                                                        {item.name} - {item.username}
                                                    </option>
                                                })}

                                            </Input>
                                        </div>

                                    </div>

                                    <Calendar
                                        culture={this.props.culture}
                                        localizer={localizer}
                                        tooltipAccessor={null}
                                        views={{ week: MyWorkWeek }}
                                        defaultView={'week'}
                                        messages={{
                                            noEventsInRange: 'There are no events in this range'.translate(this.props.lang),
                                            date: 'Date'.translate(this.props.lang),
                                            time: 'Time'.translate(this.props.lang),
                                            event: 'Event'.translate(this.props.lang),
                                            agenda: 'Agenda'.translate(this.props.lang),
                                            allDay: 'All day'.translate(this.props.lang),
                                            month: 'Month'.translate(this.props.lang),
                                            day: 'Day'.translate(this.props.lang),
                                            today: 'Today'.translate(this.props.lang),
                                            previous: 'Previous'.translate(this.props.lang),
                                            next: 'Next'.translate(this.props.lang),
                                            tomorrow: 'Tomorrow'.translate(this.props.lang),
                                            week: 'Week'.translate(this.props.lang),
                                            work_week: 'Work week'.translate(this.props.lang),
                                            yesterday: 'Yesterday'.translate(this.props.lang),

                                        }}
                                        events={this.state.items.map(item => {
                                            if (item.patient == this.props.uData._id.toString())
                                                return {
                                                    id: item._id,
                                                    title: item.title,
                                                    start: new Date(item.startTime * 1000),
                                                    end: new Date(item.endTime * 1000),
                                                    referral: item.referral,
                                                    clinic: item.clinic,
                                                    type: item.type,
                                                    patientNote: item.patientNote,
                                                    patientProfile: item.patientProfile,
                                                    patientControlNote: item.patientControlNote,
                                                    // tooltip: item.title && item.patientNote ? item.title + ' | ' + 'NOTE: ' + item.patientNote :
                                                    //     item.title && item.patientControlNote ? item.title + ' | ' + 'NOTE: ' + item.patientControlNote :
                                                    //         item.title ? item.title : ""


                                                }
                                            else {
                                                return {
                                                    id: item._id,
                                                    // title: item.title,
                                                    start: new Date(item.startTime * 1000),
                                                    end: new Date(item.endTime * 1000),
                                                    referral: item.referral,
                                                    clinic: item.clinic,
                                                    type: item.type,
                                                    patientNote: item.patientNote,
                                                    patientProfile: item.patientProfile,
                                                    patientControlNote: item.patientControlNote,
                                                    // tooltip: item.title && item.patientNote ? item.title + ' | ' + 'NOTE: ' + item.patientNote :
                                                    //     item.title && item.patientControlNote ? item.title + ' | ' + 'NOTE: ' + item.patientControlNote :
                                                    //         item.title ? item.title : ""


                                                }
                                            }
                                        })}
                                        selectable={this.state.referralId ? true : false}
                                        onView={view => {
                                            this.setState({
                                                view
                                            })
                                        }}
                                        step={this.state.step}
                                        timeslots={4}
                                        onSelectSlot={(event) => this.handleSelect(event)}
                                        // onSelectEvent={(event) => this.selectedEvent(event)}
                                        min={this.state.min}
                                        max={this.state.max}
                                        components={{
                                            toolbar: props => (<PatientCustomToolbar {...props} lang={this.props.lang} />),
                                            event: props => (<EventComponent {...props}
                                                getStringDateTs={this.props.getStringDateTs}
                                                setPatientProfile={(patient) => this.setState({ patientProfile: patient })}
                                                setReferral={(ref, note, title) => this.setState({ referralNote: ref, patientNote: note, noteTitle: title })}
                                                setControl={(ref, note, title) => this.setState({ controlNote: ref, patientNote: note, noteTitle: title })}
                                                view={this.state.view} lang={this.props.lang} />)

                                        }}
                                        eventPropGetter={event => {
                                            // const backgroundColor = this.props.selectedClinic != event.clinic ? 'blue' : event.type == 'event' ? '#DBD233' : event.type == 'referral' ? '#568AC8' : '#60BEBB';
                                            const backgroundColor = event.title ? '#568AC8' : '#CB5353'
                                            return { style: { backgroundColor, fontSize: 14 } }
                                        }}

                                    />
                                </div>

                            </div>

                        </Col>
                    </Row>

                </Container>

                {
                    this.state.referralNote && this.state.view == 'agenda' ?
                        <Modal isOpen={this.state.referralNote} centered>
                            <ModalHeader toggle={() => this.setState({ referralNote: null })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ referralNote: null })}>&times;</button>}>{'Add note'.translate(this.props.lang)}</ModalHeader>

                            <ModalBody>
                                <FormGroup>
                                    <Label>{this.state.noteTitle}</Label>
                                </FormGroup>
                                <FormGroup>
                                    <Label>{'Note'.translate(this.props.lang)}</Label>
                                    <Input type='textarea'
                                        name="note"
                                        style={{ height: '150px' }}
                                        onChange={(e) => {

                                            this.setState({
                                                patientNote: e.target.value
                                            })
                                        }}
                                        value={this.state.patientNote}
                                    />
                                    <div style={{ display: 'flex', 'margin-top': '10px', 'margin-left': '-5px' }}>
                                        <Button color='primary' style={{ margin: 5 }} onClick={this.submitNote}>{'Save'.translate(this.props.lang)}</Button>
                                    </div>
                                </FormGroup>

                            </ModalBody>
                        </Modal>
                        :
                        null
                }
                {
                    this.state.controlNote && this.state.view == 'agenda' ?
                        <Modal isOpen={this.state.controlNote} centered>
                            <ModalHeader toggle={() => this.setState({ controlNote: null })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ controlNote: null })}>&times;</button>}>{'Add note'.translate(this.props.lang)}</ModalHeader>

                            <ModalBody>
                                <FormGroup>
                                    <Label>{this.state.noteTitle}</Label>
                                </FormGroup>
                                <FormGroup>
                                    <Label>{'Note'.translate(this.props.lang)}</Label>
                                    <Input type='textarea'
                                        name="note"
                                        style={{ height: '150px' }}
                                        onChange={(e) => {

                                            this.setState({
                                                patientNote: e.target.value
                                            })
                                        }}
                                        value={this.state.patientNote}
                                    />
                                    <div style={{ display: 'flex', 'margin-top': '10px', 'margin-left': '-5px' }}>
                                        <Button color='primary' style={{ margin: 5 }} onClick={this.submitControlNote}>{'Save'.translate(this.props.lang)}</Button>
                                    </div>
                                </FormGroup>

                            </ModalBody>
                        </Modal>
                        :
                        null
                }

                {this.state.patientProfile ?
                    <Modal isOpen={this.state.patientProfile} centered>
                        <ModalHeader toggle={() => this.setState({ patientProfile: null })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ patientProfile: null })}>&times;</button>}>
                            <h4>{'Patient profile'.translate(this.props.lang)}</h4>
                        </ModalHeader>
                        <ModalBody>
                            <FormGroup>
                                <Label>{'Social security number'.translate(this.props.lang)}</Label>
                                <Input type='text' disabled
                                    value={this.state.patientProfile.socialSecurityNumber} />
                            </FormGroup>
                            <FormGroup>
                                <Label>{'Name'.translate(this.props.lang)}</Label>
                                <Input type='text' disabled
                                    value={this.state.patientProfile.name} />
                            </FormGroup>
                            <FormGroup>
                                <Label>{'Email'.translate(this.props.lang)}</Label>
                                <Input type='text' disabled
                                    value={this.state.patientProfile.email} />
                            </FormGroup>
                            <FormGroup>
                                <Label>{'Phone'.translate(this.props.lang)}</Label>
                                <Input type='text' disabled
                                    value={this.state.patientProfile.phone} />
                            </FormGroup>
                            <FormGroup>
                                <Label>{'Address'.translate(this.props.lang)}</Label>
                                <Input type='text' disabled
                                    value={this.state.patientProfile.address} />
                            </FormGroup>

                            <Button color="primary" onClick={() => this.setState({ patientProfile: !this.state.patientProfile })}>{'Close'.translate(this.props.lang)}</Button>

                        </ModalBody>
                    </Modal>

                    : null}

                {this.state.scheduleModal ?
                    <Modal isOpen={this.state.scheduleModal} centered>
                        <ModalHeader toggle={() => this.setState({ scheduleModal: !this.state.scheduleModal })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ scheduleModal: !this.state.scheduleModal })}>&times;</button>}>{'Create event'.translate(this.props.lang)}</ModalHeader>

                        <ModalBody>
                            <FormGroup>
                                <Label>{'Title'.translate(this.props.lang)}</Label>
                                <Input type='text' onChange={(e) => {
                                    let scheduleModal = this.state.scheduleModal;
                                    scheduleModal.title = e.target.value;
                                    this.setState({
                                        scheduleModal
                                    })
                                }}
                                    value={this.state.scheduleModal.title} />
                            </FormGroup>
                            <FormGroup>
                                <Label>{'Start date'.translate(this.props.lang)}</Label>
                                <Input type='date' onChange={(e) => {
                                    let scheduleModal = this.state.scheduleModal;
                                    scheduleModal.date = e.target.value;
                                    this.setState({
                                        scheduleModal
                                    })
                                }}
                                    value={this.state.scheduleModal.date} />
                            </FormGroup>
                            <FormGroup className="timepicker-container timepicker-wide">
                                <Label>{'Start time'.translate(this.props.lang)}</Label>
                                &nbsp;
                                <TimePicker
                                    getStringDateTs={this.props.getStringDateTs}
                                    format={'HH:mm'}
                                    value={moment(new Date(this.state.scheduleModal.date.replace(/-/g, "/") + " " + this.state.scheduleModal.startTime))}
                                    maxValue={this.state.scheduleModal.endTime}
                                    flag={0}
                                    onChange={(time1, timeString) => {

                                        let scheduleModal = this.state.scheduleModal;
                                        scheduleModal.startTime = timeString

                                        let startTimeSplit = scheduleModal.startTime.split(":");
                                        let endTimeSplit = scheduleModal.endTime.split(":");

                                        let startTimeHourMinute = Number(startTimeSplit[0]) * 60 + Number(startTimeSplit[1]);
                                        let endTimeHourMinute = Number(endTimeSplit[0]) * 60 + Number(endTimeSplit[1]);


                                        if (startTimeHourMinute < endTimeHourMinute) {
                                            this.setState({ scheduleModal })
                                        } else {

                                        }

                                    }}
                                    allowClear={false}
                                />
                            </FormGroup>
                            <FormGroup className="timepicker-container timepicker-wide">
                                <Label>{'End time'.translate(this.props.lang)}</Label>
                                &nbsp;
                                <TimePicker
                                    getStringDateTs={this.props.getStringDateTs}
                                    format={'HH:mm'}
                                    value={moment(new Date(this.state.scheduleModal.date.replace(/-/g, "/") + " " + this.state.scheduleModal.endTime))}
                                    maxValue={this.state.scheduleModal.startTime}
                                    flag={1}
                                    onChange={(time1, timeString) => {

                                        let scheduleModal = this.state.scheduleModal;
                                        scheduleModal.endTime = timeString
                                        console.log(scheduleModal);

                                        let startTimeSplit = scheduleModal.startTime.split(":");
                                        let endTimeSplit = scheduleModal.endTime.split(":");

                                        let startTimeHourMinute = Number(startTimeSplit[0]) * 60 + Number(startTimeSplit[1]);
                                        let endTimeHourMinute = Number(endTimeSplit[0]) * 60 + Number(endTimeSplit[1]);
                                        //console.log(startTimeHourMinute)
                                        //console.log(endTimeHourMinute)

                                        if (startTimeHourMinute < endTimeHourMinute) {
                                            this.setState({ scheduleModal })

                                        }
                                    }}
                                    allowClear={false}
                                />
                            </FormGroup>
                            <div style={{ display: 'flex' }}>
                                <Button color='primary' disabled={this.state.scheduleModal.title ? false : true} style={{ margin: 5 }} onClick={() => this.scheduleEvent(this.state.scheduleModal)}>{'Schedule event'.translate(this.props.lang)}</Button>
                                <Button color='primary' style={{ margin: 5 }} onClick={() => this.setState({ scheduleReferral: this.state.scheduleModal, scheduleModal: !this.state.scheduleModal })}>{'Schedule referral'.translate(this.props.lang)}</Button>
                                <Button color='primary' style={{ margin: 5 }} onClick={() => this.setState({ scheduleControl: this.state.scheduleModal, scheduleModal: !this.state.scheduleModal })}>{'Schedule control'.translate(this.props.lang)}</Button>
                            </div>

                        </ModalBody>
                    </Modal>
                    : null}

                {this.state.scheduleReferral ?
                    <Modal isOpen={this.state.scheduleReferral} centered>
                        <ModalHeader toggle={() => this.setState({ scheduleReferral: !this.state.scheduleReferral })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ scheduleReferral: !this.state.scheduleReferral })}>&times;</button>}>{'Schedule referral'.translate(this.props.lang)}</ModalHeader>

                        <ModalBody>
                            {/* <FormGroup>
                                <Label>{'Referral'.translate(this.props.lang)}</Label>
                                <Input type='select' onChange={e => this.setState({ referralId: e.target.value != -1 ? e.target.value : null })}>
                                    <option value={-1}>{'None'.translate(this.props.lang)}</option>
                                    {this.state.referrals.filter(item => item.status == 'allowed' || item.status == 'not-booked').map(item => {

                                        return <option value={item._id}>

                                            {item.patient.name}{" " + humanReadable[item.status].translate(this.props.lang)}

                                        </option>
                                    })}

                                </Input>
                            </FormGroup> */}
                            <FormGroup>
                                <Label>{'Start date'.translate(this.props.lang)}</Label>
                                <Input type='date' onChange={(e) => {
                                    let scheduleReferral = this.state.scheduleReferral;
                                    scheduleReferral.date = e.target.value;
                                    this.setState({
                                        scheduleReferral
                                    })
                                }}
                                    value={this.state.scheduleReferral.date}
                                    disabled />
                            </FormGroup>
                            <FormGroup className="timepicker-container timepicker-wide">
                                <Label>{'Start time'.translate(this.props.lang)}</Label>
                                &nbsp;
                                <TimePicker
                                    getStringDateTs={this.props.getStringDateTs}
                                    format={'HH:mm'}
                                    value={moment(new Date(this.state.scheduleReferral.date.replace(/-/g, "/") + " " + this.state.scheduleReferral.startTime))}
                                    maxValue={this.state.scheduleReferral.endTime}
                                    flag={0}
                                    disabled={true}
                                    onChange={(time1, timeString) => {

                                        let scheduleReferral = this.state.scheduleReferral;
                                        scheduleReferral.startTime = timeString

                                        let startTimeSplit = scheduleReferral.startTime.split(":");
                                        let endTimeSplit = scheduleReferral.endTime.split(":");

                                        let startTimeHourMinute = Number(startTimeSplit[0]) * 60 + Number(startTimeSplit[1]);
                                        let endTimeHourMinute = Number(endTimeSplit[0]) * 60 + Number(endTimeSplit[1]);

                                        if (startTimeHourMinute < endTimeHourMinute) {
                                            this.setState({ scheduleReferral })
                                        }
                                    }}


                                    allowClear={false}
                                />
                            </FormGroup>
                            <FormGroup className="timepicker-container timepicker-wide">
                                <Label>{'End time'.translate(this.props.lang)}</Label>
                                &nbsp;
                                <TimePicker
                                    getStringDateTs={this.props.getStringDateTs}
                                    format={'HH:mm'}
                                    disabled={true}
                                    value={moment(new Date(this.state.scheduleReferral.date.replace(/-/g, "/") + " " + this.state.scheduleReferral.endTime))}
                                    maxValue={this.state.scheduleReferral.startTime}
                                    flag={1}
                                    onChange={(time1, timeString) => {

                                        let scheduleReferral = this.state.scheduleReferral;
                                        scheduleReferral.endTime = timeString


                                        let startTimeSplit = scheduleReferral.startTime.split(":");
                                        let endTimeSplit = scheduleReferral.endTime.split(":");

                                        let startTimeHourMinute = Number(startTimeSplit[0]) * 60 + Number(startTimeSplit[1]);
                                        let endTimeHourMinute = Number(endTimeSplit[0]) * 60 + Number(endTimeSplit[1]);

                                        if (startTimeHourMinute < endTimeHourMinute) {
                                            this.setState({ scheduleReferral })
                                        }
                                    }}
                                    allowClear={false}
                                />
                            </FormGroup>



                            <Button color='primary' disabled={this.state.referralId ? false : true} onClick={() => this.scheduleReferral(this.state.scheduleReferral)}>{'Schedule referral'.translate(this.props.lang)}</Button>

                        </ModalBody>
                    </Modal>
                    : null}

                {this.state.scheduleControl ?
                    <Modal isOpen={this.state.scheduleControl} centered >
                        <ModalHeader toggle={() => this.setState({ scheduleControl: !this.state.scheduleControl })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ scheduleControl: !this.state.scheduleControl })}>&times;</button>}>{'Schedule control'.translate(this.props.lang)}</ModalHeader>

                        <ModalBody>
                            <FormGroup>
                                <Button color='primary' onClick={() => this.setState({ patientModal: this.state.scheduleControl, scheduleControl: !this.state.scheduleControl })}>{'Find patient'.translate(this.props.lang)}</Button>
                            </FormGroup>

                            <FormGroup>
                                <Label>{'Title'.translate(this.props.lang)}</Label>
                                <Input type='text' onChange={(e) => {
                                    let scheduleControl = this.state.scheduleControl;
                                    scheduleControl.title = e.target.value;
                                    this.setState({
                                        scheduleControl
                                    })
                                }}
                                    value={this.state.scheduleControl.title} />
                            </FormGroup>
                            <FormGroup>
                                <Label>{'Start date'.translate(this.props.lang)}</Label>
                                <Input type='date' onChange={(e) => {
                                    let scheduleControl = this.state.scheduleControl;
                                    scheduleControl.date = e.target.value;
                                    this.setState({
                                        scheduleControl
                                    })
                                }}
                                    value={this.state.scheduleControl.date} />
                            </FormGroup>
                            <FormGroup className="timepicker-container timepicker-wide">
                                <Label>{'Start time'.translate(this.props.lang)}</Label>
                                &nbsp;
                                <TimePicker
                                    getStringDateTs={this.props.getStringDateTs}
                                    format={'HH:mm'}
                                    value={moment(new Date(this.state.scheduleControl.date.replace(/-/g, "/") + " " + this.state.scheduleControl.startTime))}
                                    maxValue={this.state.scheduleControl.endTime}
                                    flag={0}
                                    onChange={(time1, timeString) => {

                                        let scheduleControl = this.state.scheduleControl;
                                        scheduleControl.startTime = timeString


                                        let startTimeSplit = scheduleControl.startTime.split(":");
                                        let endTimeSplit = scheduleControl.endTime.split(":");

                                        let startTimeHourMinute = Number(startTimeSplit[0]) * 60 + Number(startTimeSplit[1]);
                                        let endTimeHourMinute = Number(endTimeSplit[0]) * 60 + Number(endTimeSplit[1]);
                                        //console.log(startTimeHourMinute)
                                        //console.log(endTimeHourMinute)

                                        if (startTimeHourMinute < endTimeHourMinute) {
                                            this.setState({ scheduleControl })
                                        } else {
                                            // this.setState({ wrongInput: true })
                                        }
                                    }}
                                    allowClear={false}
                                />
                            </FormGroup>
                            <FormGroup className="timepicker-container timepicker-wide">
                                <Label>{'End time'.translate(this.props.lang)}</Label>
                                &nbsp;
                                <TimePicker
                                    getStringDateTs={this.props.getStringDateTs}
                                    format={'HH:mm'}
                                    value={moment(new Date(this.state.scheduleControl.date.replace(/-/g, "/") + " " + this.state.scheduleControl.endTime))}
                                    maxValue={this.state.scheduleControl.startTime}
                                    flag={1}
                                    onChange={(time1, timeString) => {

                                        let scheduleControl = this.state.scheduleControl;
                                        scheduleControl.endTime = timeString

                                        let startTimeSplit = scheduleControl.startTime.split(":");
                                        let endTimeSplit = scheduleControl.endTime.split(":");

                                        let startTimeHourMinute = Number(startTimeSplit[0]) * 60 + Number(startTimeSplit[1]);
                                        let endTimeHourMinute = Number(endTimeSplit[0]) * 60 + Number(endTimeSplit[1]);
                                        //console.log(startTimeHourMinute)
                                        //console.log(endTimeHourMinute)

                                        if (startTimeHourMinute < endTimeHourMinute) {
                                            this.setState({ scheduleControl })
                                        } else {
                                            // this.setState({ wrongInput: true })
                                        }
                                    }}
                                    allowClear={false}
                                />
                            </FormGroup>
                            {
                                this.state.scheduleControl.title ?
                                    <FormGroup>
                                        <Label>{'Note'.translate(this.props.lang)}</Label>
                                        <Input type='textarea'
                                            name="note"
                                            style={{ height: '150px' }}
                                            onChange={(e) => {
                                                let scheduleControl = this.state.scheduleControl;
                                                scheduleControl.patientControlNote = e.target.value;
                                                this.setState({
                                                    scheduleControl
                                                })
                                            }}
                                            value={this.state.scheduleControl.patientControlNote}
                                        />
                                    </FormGroup>
                                    :
                                    null
                            }

                            <Button color='primary' disabled={this.state.scheduleControl.title ? false : true} onClick={() => this.scheduleControl(this.state.scheduleControl)}>{'Schedule control'.translate(this.props.lang)}</Button>

                        </ModalBody>
                    </Modal>
                    : null}

                {this.state.patientModal ?
                    <Modal isOpen={this.state.patientModal} size='xl' centered>
                        <ModalHeader toggle={() => this.setState({ patientModal: !this.state.patientModal })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ patientModal: !this.state.patientModal })}>&times;</button>}>{'Patients'.translate(this.props.lang)}</ModalHeader>

                        <ModalBody>

                            <PatientFilter
                                {...this.props}

                                value={this.state.patientModal.title}
                                onChange={(val) => {
                                    let patientModal = this.state.patientModal;
                                    patientModal.title = val.name;
                                    patientModal.socialSecurityNumber = val.socialSecurityNumber
                                    this.setState({ scheduleControl: this.state.patientModal, patientModal: !this.state.patientModal })
                                }}>
                            </PatientFilter>


                        </ModalBody>
                    </Modal>
                    : null}

                {this.state.updateEvent ?

                    <Modal isOpen={this.state.updateEvent} size={this.state.updateEvent.patientProfile ? 'lg' : ''} centered>
                        <ModalHeader toggle={() => this.setState({ updateEvent: !this.state.updateEvent })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ updateEvent: !this.state.updateEvent })}>&times;</button>}>{'Update event'.translate(this.props.lang)}</ModalHeader>

                        <ModalBody>
                            <Row>
                                <Col lg={this.state.updateEvent.patientProfile ? '6' : '12'}>
                                    <FormGroup>
                                        <Label>{'Title'.translate(this.props.lang)}</Label>
                                        <Input type='text' onChange={(e) => {
                                            let updateEvent = this.state.updateEvent;
                                            updateEvent.title = e.target.value;
                                            this.setState({
                                                updateEvent
                                            })
                                        }}
                                            value={this.state.updateEvent.title} />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>{'Start date'.translate(this.props.lang)}</Label>
                                        <Input type='date' onChange={(e) => {
                                            let updateEvent = this.state.updateEvent;
                                            updateEvent.date = e.target.value;
                                            this.setState({
                                                updateEvent
                                            })
                                        }}
                                            value={this.state.updateEvent.date} />
                                    </FormGroup>
                                    <FormGroup className="timepicker-container timepicker-wide">
                                        <Label>{'Start time'.translate(this.props.lang)}</Label>
                                        &nbsp;
                                        <TimePicker
                                            getStringDateTs={this.props.getStringDateTs}
                                            format={'HH:mm'}
                                            value={moment(new Date(this.state.updateEvent.date.replace(/-/g, "/") + " " + this.state.updateEvent.startTime))}
                                            maxValue={this.state.updateEvent.endTime}
                                            flag={0}
                                            onChange={(time1, timeString) => {

                                                let updateEvent = this.state.updateEvent;
                                                updateEvent.startTime = timeString

                                                let startTimeSplit = updateEvent.startTime.split(":");
                                                let endTimeSplit = updateEvent.endTime.split(":");

                                                let startTimeHourMinute = Number(startTimeSplit[0]) * 60 + Number(startTimeSplit[1]);
                                                let endTimeHourMinute = Number(endTimeSplit[0]) * 60 + Number(endTimeSplit[1]);
                                                //console.log(startTimeHourMinute)
                                                //console.log(endTimeHourMinute)

                                                if (startTimeHourMinute < endTimeHourMinute) {
                                                    this.setState({ updateEvent })
                                                } else {
                                                    // this.setState({ wrongInput: true })
                                                }
                                            }}
                                            allowClear={false}
                                        />
                                    </FormGroup>
                                    <FormGroup className="timepicker-container timepicker-wide">
                                        <Label>{'End time'.translate(this.props.lang)}</Label>
                                        &nbsp;
                                        <TimePicker
                                            getStringDateTs={this.props.getStringDateTs}
                                            format={'HH:mm'}
                                            value={moment(new Date(this.state.updateEvent.date.replace(/-/g, "/") + " " + this.state.updateEvent.endTime))}
                                            maxValue={this.state.updateEvent.startTime}
                                            flag={1}
                                            onChange={(time1, timeString) => {

                                                let updateEvent = this.state.updateEvent;
                                                updateEvent.endTime = timeString

                                                let startTimeSplit = updateEvent.startTime.split(":");
                                                let endTimeSplit = updateEvent.endTime.split(":");

                                                let startTimeHourMinute = Number(startTimeSplit[0]) * 60 + Number(startTimeSplit[1]);
                                                let endTimeHourMinute = Number(endTimeSplit[0]) * 60 + Number(endTimeSplit[1]);
                                                //console.log(startTimeHourMinute)
                                                //console.log(endTimeHourMinute)

                                                if (startTimeHourMinute < endTimeHourMinute) {
                                                    this.setState({ updateEvent })
                                                } else {
                                                    // this.setState({ wrongInput: true })
                                                }
                                            }}
                                            allowClear={false}
                                        />
                                    </FormGroup>
                                    {
                                        this.state.updateEvent.type == "referral" ?
                                            <FormGroup>
                                                <Label>{'Note'.translate(this.props.lang)}</Label>
                                                <Input type='textarea'
                                                    name="note"
                                                    style={{ height: '150px' }}
                                                    onChange={(e) => {
                                                        let updateEvent = this.state.updateEvent;
                                                        updateEvent.patientNote = e.target.value;
                                                        this.setState({
                                                            updateEvent
                                                        })
                                                    }}
                                                    value={this.state.updateEvent.patientNote}
                                                />
                                            </FormGroup>
                                            :
                                            null
                                    }
                                    {
                                        this.state.updateEvent.type == 'revisit' ?
                                            <FormGroup>
                                                <Label>{'Note'.translate(this.props.lang)}</Label>
                                                <Input type='textarea'
                                                    name="note"
                                                    style={{ height: '150px' }}
                                                    onChange={(e) => {
                                                        let updateEvent = this.state.updateEvent;
                                                        updateEvent.patientControlNote = e.target.value;
                                                        this.setState({
                                                            updateEvent
                                                        })
                                                    }}
                                                    value={this.state.updateEvent.patientControlNote}
                                                />
                                            </FormGroup>
                                            :
                                            null
                                    }




                                </Col>

                                {
                                    this.state.updateEvent.patientProfile ?
                                        <>
                                            <Col lg="6">
                                                <FormGroup>
                                                    <Label>{'Social security number'.translate(this.props.lang)}</Label>
                                                    <Input type='text' disabled
                                                        value={this.state.updateEvent.patientProfile.socialSecurityNumber} />
                                                </FormGroup>
                                                <FormGroup>
                                                    <Label>{'Name'.translate(this.props.lang)}</Label>
                                                    <Input type='text' disabled
                                                        value={this.state.updateEvent.patientProfile.name} />
                                                </FormGroup>
                                                <FormGroup>
                                                    <Label>{'Email'.translate(this.props.lang)}</Label>
                                                    <Input type='text' disabled
                                                        value={this.state.updateEvent.patientProfile.email} />
                                                </FormGroup>
                                                <FormGroup>
                                                    <Label>{'Phone'.translate(this.props.lang)}</Label>
                                                    <Input type='text' disabled
                                                        value={this.state.updateEvent.patientProfile.phone} />
                                                </FormGroup>
                                                <FormGroup>
                                                    <Label>{'Address'.translate(this.props.lang)}</Label>
                                                    <Input type='text' disabled
                                                        value={this.state.updateEvent.patientProfile.address} />
                                                </FormGroup>
                                            </Col>
                                        </>
                                        :
                                        null
                                }
                                <Col lg="12">
                                    <Button color='primary' style={{ margin: 10 }} onClick={() => this.updateEvent(this.state.updateEvent)}>{'Update'.translate(this.props.lang)}</Button>
                                    <Button color='primary' style={{ margin: 10 }} onClick={() => this.setState({ warningModal: this.state.updateEvent, updateEvent: !this.state.updateEvent })}>{'Delete'.translate(this.props.lang)}</Button>
                                    <Button color='primary' style={{ margin: 10 }} onClick={() => this.setState({ updateEvent: !this.state.updateEvent })}>{'Cancel'.translate(this.props.lang)}</Button>
                                </Col>

                            </Row>
                        </ModalBody>
                    </Modal>
                    : null}

                {/* {this.state.warningModal ?
                    <Modal isOpen={this.state.warningModal} centered >
                        <ModalHeader>
                            <h4>{'Warning'.translate(this.props.lang)}</h4>
                        </ModalHeader>
                        <ModalBody>
                            <p>{'Do you really want to delete the booked time? In that case, do not forget to book a new one!'.translate(this.props.lang)}</p>

                            <Button color='primary' style={{ margin: 10 }} onClick={() => this.deleteEvent(this.state.warningModal)}>{'Delete'.translate(this.props.lang)}</Button>
                            <Button color='primary' style={{ margin: 10 }} onClick={() => this.setState({ warningModal: !this.state.warningModal })}>{'Cancel'.translate(this.props.lang)}</Button>
                        </ModalBody>
                    </Modal>

                    : null} */}

                {this.state.warningModal ?
                    <Modal isOpen={this.state.warningModal} centered >
                        <ModalBody className="delete-modal">
                            <p>{'Do you really want to delete the booked time? In that case, do not forget to book a new one!'.translate(this.props.lang)}</p>
                        </ModalBody>
                        <ModalFooter className='buttons-right-blue'>
                            <Button color='primary' style={{ margin: 10, minWidth: 100 }} onClick={() => this.deleteEvent(this.state.warningModal)}>{'Delete'.translate(this.props.lang)}</Button>
                            <Button color='primary' style={{ margin: 10, minWidth: 100 }} onClick={() => this.setState({ warningModal: !this.state.warningModal })}>{'Cancel'.translate(this.props.lang)}</Button>
                        </ModalFooter>
                    </Modal>

                    : null}

                {/* {this.state.wrongDate ?
                    <Modal isOpen={this.state.wrongDate} toggle={() => this.setState({ wrongDate: !this.state.wrongDate })} centered>
                        <ModalHeader>
                            <h4>{'You can not pick date form the past'.translate(this.props.lang)}</h4>
                        </ModalHeader>
                        <ModalBody>
                            <div>
                                <Button onClick={() => this.setState({ wrongDate: !this.state.wrongDate, scheduleModal: !this.state.scheduleModal })}>{'Pick again'.translate(this.props.lang)}</Button>
                            </div>
                        </ModalBody>
                    </Modal>

                    : null} */}

                {this.state.wrongDate ?
                    <Modal isOpen={this.state.wrongDate} toggle={() => this.setState({ wrongDate: !this.state.wrongDate })} centered>
                        <ModalBody className="delete-modal">
                            <p>{'You can not pick date form the past'.translate(this.props.lang)}</p>
                        </ModalBody>
                        <ModalFooter className='buttons-right-blue'>
                            <Button style={{ minWidth: 100 }} onClick={() => this.setState({ wrongDate: !this.state.wrongDate, scheduleModal: !this.state.scheduleModal })}>{'Pick again'.translate(this.props.lang)}</Button>
                        </ModalFooter>
                    </Modal>

                    : null}

                {/* {this.state.wrongInput ?
                    <Modal isOpen={this.state.wrongInput} toggle={() => this.setState({ wrongInput: !this.state.wrongInput })} centered>
                        <ModalHeader>
                            <h4>{'Wrong input'.translate(this.props.lang)}</h4>
                        </ModalHeader>
                        <ModalBody>
                            <div>
                                <Button onClick={() => this.setState({ wrongInput: !this.state.wrongInput, scheduleModal: !this.state.scheduleModal })}>{'Pick again'.translate(this.props.lang)}</Button>
                            </div>
                        </ModalBody>
                    </Modal>

                    : null} */}

                {this.state.wrongInput ?
                    <Modal isOpen={this.state.wrongInput} toggle={() => this.setState({ wrongInput: !this.state.wrongInput })} centered>
                        <ModalBody className="delete-modal">
                            <p>{'Wrong input'.translate(this.props.lang)}</p>
                        </ModalBody>
                        <ModalFooter className='buttons-right-blue'>
                            <Button style={{ minWidth: 100 }} onClick={() => this.setState({ wrongInput: !this.state.wrongInput, scheduleModal: !this.state.scheduleModal })}>{'Pick again'.translate(this.props.lang)}</Button>
                        </ModalFooter>
                    </Modal>

                    : null}

                {this.state.error ?

                    <ErrorModal lang={this.props.lang}
                        isOpen={this.state.error}
                        toggle={() => this.setState({ error: null })}

                    >
                        {this.state.error.translate(this.props.lang)}
                    </ErrorModal>

                    :
                    null
                }
            </div>
        );
    }
}

export default Page(TestCalendar);