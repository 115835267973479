import React from 'react'

const WaitingRoom = ({ width, height, className, fill = '#fff' }) => {
    console.log(fill, 'fill');

    return (
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" height={height} width={width} viewBox="0 0 32 24" xmlSpace="preserve" className={className}>
            {/* <style type="text/css">
                .st0{fill:#fff;}
            </style> */}
            <path fill={fill} d="M22.4,8.5c0.6-0.3,1.1-0.6,1.6-0.9c1.6-0.9,3.2-1.8,4.8-2.7c1.1-0.6,2.4-0.1,2.7,1.1c0,0.2,0,0.4,0,0.5
        c0,3.7,0,7.4,0,11.1c0,1.5-1.4,2.3-2.7,1.6c-2-1.1-3.9-2.2-5.9-3.3c-0.1-0.1-0.3-0.1-0.5-0.3c0,1.1,0,2.1,0,3.1c0,1.5-1,2.8-2.4,3.2
        c-0.3,0.1-0.7,0.1-1.1,0.1c-5,0-10,0-15,0c-2,0-3.5-1.5-3.5-3.5c0-4.4,0-8.7,0-13.1C0.5,3.5,2,2,4,2c5,0,10,0,15,0
        c2,0,3.4,1.5,3.4,3.5C22.4,6.5,22.4,7.4,22.4,8.5z M11.5,3.2c-2.5,0-5,0-7.5,0c-1.4,0-2.3,0.9-2.3,2.3c0,4.4,0,8.7,0,13.1
        C1.7,20,2.7,21,4,21c5,0,9.9,0,14.9,0c1.4,0,2.3-0.9,2.3-2.3c0-4.4,0-8.7,0-13.1c0-1.4-0.9-2.4-2.4-2.4C16.4,3.2,14,3.2,11.5,3.2z
         M30.4,12.1c0-1.8,0-3.7,0-5.5c0-0.7-0.5-1-1-0.6c-2.2,1.2-4.4,2.5-6.6,3.7c-0.2,0.1-0.3,0.3-0.3,0.5c0,1.3,0,2.5,0,3.8
        c0,0.3,0.1,0.4,0.4,0.6c2.2,1.2,4.3,2.4,6.5,3.6c0.7,0.4,1.1,0.1,1.1-0.7C30.4,15.7,30.4,13.9,30.4,12.1z"></path>
            <path fill={fill} d="M7.4,15.8c-0.4-0.1-0.8-0.1-1.2-0.2c-0.5-0.1-1.1-0.3-1.6-0.5c-0.3-0.1-0.3-0.2-0.3-0.4c0.1-1,0.4-1.8,1-2.6
        c0.6-0.8,1.3-1.3,2.2-1.7c-0.6-0.4-1-0.9-1.1-1.6c-0.1-0.5,0-1,0.2-1.5C7.1,6.4,8.1,5.8,9.1,6c0.6,0.1,1.2,0.4,1.6,1
        c0.4,0.5,0.5,1.1,0.4,1.8c0.2,0,0.3,0,0.5,0c-0.1-0.8,0.1-1.5,0.6-2C12.6,6.3,13,6.1,13.5,6c1.1-0.2,2.2,0.4,2.6,1.4
        c0.4,0.9,0.3,2.2-0.9,3c0.1,0,0.1,0.1,0.2,0.1c1.7,0.8,2.7,2.1,3,4c0.1,0.6,0.1,0.6-0.5,0.8c-0.8,0.3-1.7,0.5-2.5,0.6
        c0.1,0.4,0.2,0.7,0.3,1.1c0.1,0.4,0.1,0.7,0.1,1.1c0,0.2,0,0.3-0.3,0.4c-0.6,0.2-1.2,0.3-1.8,0.4C12.5,19,11.2,19,10,18.9
        c-0.9-0.1-1.9-0.2-2.8-0.5C7,18.3,6.9,18.3,6.9,18c0-0.7,0.1-1.4,0.4-2C7.3,15.9,7.3,15.9,7.4,15.8z M11.4,18.4
        c1.1,0,2.2-0.1,3.3-0.4c0.5-0.1,0.5-0.1,0.4-0.6c0,0,0,0,0,0c-0.1-1.1-0.6-2-1.4-2.7c-1-0.9-2.2-1.2-3.5-0.7
        c-1.6,0.6-2.6,1.9-2.7,3.8c0,0.2,0.1,0.2,0.2,0.2C8.9,18.3,10.1,18.4,11.4,18.4z M13.8,10.8c0.1,1.1-0.2,1.9-1.1,2.5c0,0,0,0,0,0
        c0.9,0.4,1.7,1,2.3,1.8c0,0,0.1,0.1,0.2,0.1c0.2,0,0.5,0,0.7-0.1c0.6-0.1,1.3-0.3,1.9-0.5C17.9,12.8,15.6,10.6,13.8,10.8z M10,13.3
        c-0.9-0.6-1.3-1.5-1.1-2.6c-0.4,0.1-0.7,0.1-1,0.2c-1,0.3-1.7,0.9-2.3,1.8c-0.4,0.5-0.6,1.1-0.7,1.8c0,0.1,0,0.2,0.2,0.3
        c0.8,0.3,1.6,0.4,2.5,0.5c0.1,0,0.1,0,0.2-0.1C8.3,14.3,9.1,13.7,10,13.3z M13.2,11.2c0-1-0.8-1.9-1.9-1.9s-1.9,0.8-1.9,1.9
        c0,1,0.8,1.9,1.8,1.9C12.4,13.1,13.2,12.3,13.2,11.2z M15.8,8.4c0-0.1,0-0.3-0.1-0.4c-0.2-1-1.2-1.6-2.2-1.3c-1,0.2-1.6,1.2-1.3,2.2
        c0,0.1,0.1,0.2,0.2,0.2c0.5,0.2,0.8,0.6,1.1,1c0,0.1,0.1,0.1,0.2,0.1C14.8,10.3,15.8,9.5,15.8,8.4z M6.9,8.4c0,1.1,1,2,2.1,1.8
        c0.1,0,0.2-0.1,0.2-0.2c0.2-0.4,0.6-0.7,1-0.9c0.2-0.1,0.3-0.2,0.3-0.5c0-0.2,0-0.3,0-0.5c-0.1-0.9-1-1.6-1.9-1.5
        C7.6,6.7,6.9,7.5,6.9,8.4z"></path>
        </svg>

    )
}

export default WaitingRoom