import React, { Component } from 'react';
import Isvg from 'react-inlinesvg';
import { Link } from 'react-router-dom'
import notification from '../assets/svg/notification.svg';
import moreIcon from '../assets/svg/more.svg';
import info from '../assets/svg/info.svg'
import profileUser from '../assets/images/user.png';
import { API_ENDPOINT } from '../constants';

const modelPath = '/model'

let faceapi = null;
if (typeof window !== 'undefined') {
    faceapi = window.faceapi;
}

import {
    Container,
    Row,
    Col, Input, Label, Modal, ModalBody, ModalHeader, FormGroup, Button, ModalFooter
} from 'reactstrap';


function parseJSON(response) {
    return response.json().then(json => {
        return {
            result: json,
            status: response.status
        }
    })
}

const minScore = 0.2; // minimum score
const maxResults = 5; // maximum number of results to return
let optionsSSDMobileNet;

export class FaceApi extends Component {
    constructor(props) {
        super(props);
        this.scanning = Math.floor(new Date().getTime() / 1000);
        this.state = {
            scanedPhotos: [],
        };
    }


    componentDidMount() {


    }


    componentDidUpdate(prevProps, prevState) {
        if (prevProps.takePhoto != this.props.takePhoto && this.props.takePhoto) {
            this.get()
            setTimeout(() => {
                this.setState({ checkFaceType: true, scanedPhotos: [] })
            }, 1000);

        }
        if (prevProps.takePhoto != this.props.takePhoto && !this.props.takePhoto) {
            this.closeCamera()
        }
    }
    scanFace = async (roll, pitch, yaw) => {

        roll = Math.round(roll * 100);
        pitch = Math.round(pitch * 100);
        yaw = Math.round(yaw * 100);

        let faceType = -1;
        if (roll >= -10 && roll <= 10 && pitch >= -10 && pitch <= 10 && yaw >= -20 && yaw <= 20) {
            faceType = 0;
        } else if (pitch <= -20) {
            faceType = 1; //lijevo

        } else if (pitch >= 20) {
            faceType = 2; // desno

        } else if (yaw <= -20) {
            faceType = 3; //gore

        } else if (yaw > -20) {
            faceType = 4; //dole
        }
        if (this.state.scanedPhotos.filter(el => el.faceType == faceType).length == 0 && faceType > -1) {
            this.scanning = Math.floor(new Date().getTime() / 1000);
            let scanedPhotos = this.state.scanedPhotos;
            let obj = {
                faceType: faceType
            }
            if (this.canvasRef) {
                var canvas = document.createElement('canvas');
                canvas.width = this.videoRef.videoWidth;
                canvas.height = this.videoRef.videoHeight;
                document.body.appendChild(canvas);

                // console.log(canvas.width, canvas.height)

                var context = canvas.getContext('2d');
                context.drawImage(this.videoRef, 0, 0, this.videoRef.videoWidth, this.videoRef.videoHeight);


                canvas.toBlob((blob) => {
                    let file = new File([blob], "screenshot.png", { type: "image/png" })
                    this.setState({
                        takePhotoModal: {
                            message: '',
                            previewImage: canvas.toDataURL('image/png'),
                            image: file
                        }
                    })
                    canvas.remove();

                }, 'image/png');



                let photo = canvas.toDataURL();
                obj.photo = photo;

            }
            scanedPhotos.push(obj)


            this.setState({ scanedPhotos }, () => {
                this.props.getNumberOfPhotos(this.state.scanedPhotos.length)
                if (this.state.scanedPhotos.length == 5) {
                    this.setState({ checkFaceType: false })
                    for (let i = 0; i < this.state.scanedPhotos.length; i++) {
                        this.props.savePhoto(this.state.scanedPhotos[i].photo)
                    }
                } else {
                    if (!this.props.scanedTypes || (this.props.scanedTypes && this.props.scanedTypes.filter(el => el == (obj.faceType + 1)).length == 0))
                        this.props.getScanedTypes(obj.faceType)
                }
            })
        }

    }

    componentWillUnmount() {
        this.closeCamera()

    }

    closeCamera = () => {
        if (this.stream && this.stream.getTracks()) {
            this.stream.getTracks().forEach(track => track.stop());
            this.stream = null;
        }
    }



    drawFaces = (canvas, data, fps) => {
        const ctx = canvas.getContext('2d');
        if (!ctx) return;
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        // draw title
        ctx.font = 'small-caps 20px "Segoe UI"';
        ctx.fillStyle = 'white';
        for (const person of data) {

            let tsNow = Math.floor(new Date().getTime() / 1000);
            if (this.state.checkFaceType && (tsNow - this.scanning) > 1) {
                this.scanFace(person.angle.roll.toFixed(3), person.angle.pitch.toFixed(3), person.angle.yaw.toFixed(3))
            }
        }
    }

    detectVideo = async (video, canvas) => {
        if (!video || video.paused) return false;
        const t0 = performance.now();
        faceapi
            .detectAllFaces(video, optionsSSDMobileNet)
            .withFaceLandmarks()
            .withFaceExpressions()
            .withAgeAndGender()
            .then((result) => {
                const fps = 1000 / (performance.now() - t0);
                this.drawFaces(canvas, result, fps.toLocaleString());
                requestAnimationFrame(() => this.detectVideo(video, canvas));
                return true;
            })
            .catch((err) => {
                return false;
            });
        return false;
    }
    setupCamera = async () => {
        const video = document.getElementById('video');
        const canvas = document.getElementById('canvas');
        if (!video || !canvas) return null;

        let msg = '';

        if (!navigator.mediaDevices) {
            return null;
        }
        let stream;
        const constraints = {
            audio: false,
            video: { facingMode: 'user', resizeMode: 'crop-and-scale', width: { max: 640 }, height: { max: 480 } },
        };
        if (window.innerWidth > window.innerHeight) constraints.video.width = { ideal: 640 };
        else constraints.video.height = { ideal: window.innerHeight };
        try {
            stream = await navigator?.mediaDevices?.getUserMedia?.(constraints);
            this.stream = stream;
        } catch (err) {
            if (err.name === 'PermissionDeniedError' || err.name === 'NotAllowedError') msg = 'camera permission denied';
            else if (err.name === 'SourceUnavailableError') msg = 'camera not available';
            return null;
        }
        // @ts-ignore
        if (stream) video.srcObject = stream;
        else {
            return null;
        }
        const track = stream.getVideoTracks()[0];
        const settings = track.getSettings();
        if (settings.deviceId) delete settings.deviceId;
        if (settings.groupId) delete settings.groupId;
        if (settings.aspectRatio) settings.aspectRatio = Math.trunc(100 * settings.aspectRatio) / 100;

        canvas.addEventListener('click', () => {
            if (video && video.readyState >= 2) {
                if (video.paused) {
                    video.play();
                    this.detectVideo(video, canvas);
                } else {
                    video.pause();
                }
            }

        });
        return new Promise((resolve) => {
            video.onloadeddata = async () => {
                // @ts-ignore
                canvas.width = video.videoWidth;
                // @ts-ignore
                canvas.height = video.videoHeight;
                // @ts-ignore
                video.play();
                this.detectVideo(video, canvas);
                resolve(true);
            };
        });
    }
    // setupFaceAPI = async () => {
    //     let start = new Date().getTime()
    //     await faceapi.nets.ssdMobilenetv1.load(modelPath);
    //     await faceapi.nets.ageGenderNet.load(modelPath);
    //     await faceapi.nets.faceLandmark68Net.load(modelPath);
    //     await faceapi.nets.faceRecognitionNet.load(modelPath);
    //     await faceapi.nets.faceExpressionNet.load(modelPath);
    //     optionsSSDMobileNet = new faceapi.SsdMobilenetv1Options({ minConfidence: minScore, maxResults });
    //     let val = new Date().getTime() - start
    //     console.log(val + ' ms')

    // }
    get = async () => {

        await faceapi.tf.setBackend('webgl');

        await faceapi.tf.enableProdMode();
        await faceapi.tf.ENV.set('DEBUG', false);
        await faceapi.tf.ready();


        // await this.setupFaceAPI();
        await this.setupCamera();
    }




    render() {
        if(!this.props.takePhoto){
            return null
        }
        return (
            <div className='face-api-component-wrap'>
                <div className='video-wrap'>
                    <video id="video" ref={node => this.videoRef = node} playsinline className="video"></video>
                </div>
                <div className='canvas-wrap'>
                    <canvas id="canvas" ref={node => this.canvasRef = node} className="canvas"></canvas>
                </div>
                {/* <div id="log" ref={node => this.logRef = node} className="log"></div> */}

            </div>
        )
    }
}

export default FaceApi;