import React, { Component } from 'react';

import {
    Container,
    Row,
    Col,
    FormGroup, Label, Input,
    Button,
    Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';



import ListBuilder from './listBuilder';
import { getSearchParams, generateSearchLink, generateSearchLinkMultiple } from '../helpers/linkHelper';
import { API_ENDPOINT, GOOGLE_API_KEY } from '../constants';
import moment from 'moment';
import eRemissIcon from '../assets/svg/e.svg';

import Isvg from 'react-inlinesvg';
import Checkbox from './forms/fields/checkbox';

import garabage from '../assets/svg/garbage.svg'

import shapeBlue from "../assets/svg/shape-blue.svg";
import shapeGreen from "../assets/svg/shape-green.svg";
import shapeYellow from "../assets/images/shape-yellow.png";

import logoLogin from "../assets/svg/logo_login.svg";

import { Player, Controls } from "@lottiefiles/react-lottie-player";

import FormBuilder from "./forms/formBuilder";

import { required } from "./forms/validation";
import IlustrationMainClinic from '../assets/svg/ilustration_main_clinic.svg';


function parseJSON(response) {
    return response.json().then(json => {
        return {
            result: json,
            status: response.status
        }
    })
}





/**
* List of patients for selected clinic and selected department
* @author   Milan Stanojevic
*/
class MainClinicLandingPage extends Component {
    constructor(props) {
        super(props);

        this.state = {

        };
    }


    get = () => {


    }

    componentDidMount() {

        // this.getLandingLoginText()

    }

    componentWillUnmount() {
    }



    componentDidUpdate(prevProps, prevState) {


    }




    getLandingLoginText = () => {

        // fetch(API_ENDPOINT + '/data/main-clinic-groups/login/text', {
        //     method: 'GET',
        //     headers: {
        //         'content-type': 'application/json'
        //     }
        // }).then(res => res.json()).then((result) => {
        //     if (result) {
        //         this.setState({ landingLoginInfo: result })
        //     }
        // })
    }





    render() {
        return (
            <div className="main-clinic-landing-page-wrap-v2">
                <div className="login-main-wrap">
                    <div className="login-wrapper">
                        <Isvg src={shapeBlue} className="shape-blue" />
                        <Isvg src={shapeGreen} className="shape-green" />
                        <img src={shapeYellow} className="shape-yellow" />


                        <div className="log-in">
                            <Container fluid>
                                <Row>
                                    <Col lg={{ size: 12 }}>
                                        <div className="panel" style={{
                                            minWidth: typeof window != "undefined" &&
                                                window.innerWidth < 768
                                                ? null
                                                : "508px",
                                        }}>
                                            <div className="login-container">
                                                <h4>{"Login".translate(this.props.lang)} </h4>
                                                {
                                                    this.props.data && this.props.data.loginText ?
                                                        <div dangerouslySetInnerHTML={{ __html: this.props.data.loginText }} />
                                                        :
                                                        null
                                                }

                                                {/* <p>
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
                                                </p> */}

                                                <div className='ilustation-wrap'>
                                                    <Isvg src={IlustrationMainClinic} />
                                                </div>

                                                <div className='buttons-wrap'>
                                                    <Button color="primary" onClick={() => {
                                                        if (this.props.bankIdLogin) {
                                                            this.props.bankIdLogin()
                                                        }
                                                    }}>{'Log in'.translate(this.props.lang)} &nbsp;&nbsp;&nbsp;&gt;&gt;</Button>
                                                    <div className='not-now-link' onClick={() => {
                                                        if (this.props.changeInitialLanding) {
                                                            this.props.changeInitialLanding(false)
                                                        }
                                                    }}>
                                                        <h6>{'Not now'.translate(this.props.lang)}</h6>
                                                    </div>
                                                </div>


                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </div>
                </div>
            </div >

            // <div className="main-clinic-landing-page-wrap">

            //     <div>

            //         <div className='main-clinic-landing-header'>
            //             <h4>{'Welcome'.translate(this.props.lang)}</h4>
            //         </div>

            //         <div className='buttons-wrap'>
            //             <div className='login-button-wrap'>
            //                 <Button color='primary' onClick={() => {
            //                     if (this.props.bankIdLogin) {
            //                         this.props.bankIdLogin()
            //                     }
            //                 }}>{'Log in'.translate(this.props.lang)}</Button>
            //             </div>
            //             <div className='not-now-link' onClick={() => {
            //                 if (this.props.changeInitialLanding) {
            //                     this.props.changeInitialLanding(false)
            //                 }
            //             }}>
            //                 <h6>{'Not now'.translate(this.props.lang)}</h6>
            //             </div>
            //         </div>
            //     </div>
            // </div>
        );


    }
}
export default MainClinicLandingPage;