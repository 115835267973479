import React, { Component } from 'react';
import ListBuilder from './listBuilder';

import { getSearchParams, generateSearchLink, generateSearchLinkMultiple } from '../helpers/linkHelper'
import { API_ENDPOINT } from '../constants';

import {
    Container,
    Row,
    Col, Modal, ModalBody, ModalFooter, ModalHeader, Button, FormGroup, Label, Input
} from 'reactstrap';
import Isvg from 'react-inlinesvg';
import ErrorModal from './errorModal';

import eyeIcon from '../assets/svg/eye.svg';

import { defaultError } from './TwoFactorAuthModal';
import PhoneField from './forms/fields/phone';

function parseJSON(response) {
    return response.json().then(json => {
        return {
            result: json,
            status: response.status
        }
    })
}

const loadDataWithQuery = [
    (fetchFunction, lang, match, query) => {
        let url = `${API_ENDPOINT}/users/two-factor-auth/registration-requests?page=${query?.page ?? 0}&entries=${query?.entries ?? 10}&sortField=${query?.sortField ?? '_id'}&sortType=${query?.sortType ?? -1}&filter=${query?.filter ?? ''}&clinicGroupId=${query?.clinicGroupId ?? ''}`;
        if (query?.handled === true) url += `&handled=yes`;

        return fetchFunction?.(url, {
            method: 'GET',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
        }).then(parseJSON).then(({ result, status }) => {
            if (!result.error) {
                return {
                    page: query.page ? query.page : 0,
                    items: result.items,
                    total: result.total,
                    resultFilter: result.filter
                }
            }
            else return { error: result.error }
        })
    }
]

export class RegistrationRequestsList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            form: false,
            useState: true,
            params: {
                entries: 10,
                page: 0,
                sortField: '_id',
                sortType: -1,
                filter: ''
            },
            items: [],
            total: 0,
            loading: true,
            error: null,
            user: null,
        }
    }

    get = () => {
        this.setState({ loading: true }, () => {
            this.props?.updateMeta?.(this.props?.generateSeoTags?.(this.state, this.props.lang));

            for (let i = 0; i < loadDataWithQuery?.length; i++) {
                loadDataWithQuery?.[i]?.(window?.fetch, this.props.lang, this.props[0].match, { ...this.state.params, clinicGroupId: this.props.selectedGroup, handled: this.props.handled }).then(data => {
                    if (data?.error) return this.setState({ loading: null, error: data.error?.translate(this.props.lang || 'en') });

                    this.setState({ ...data, loading: null });
                })
            }
        })
    }

    componentDidMount() {
        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }

        this.get();
    }

    componentDidUpdate(prevProps, prevState) {
        if (!prevProps.selectedGroup && this.props.selectedGroup || prevProps.selectedGroup !== this.props.selectedGroup) this.get();

        if (prevProps?.num !== this.props.num) this.get();

        if (prevState?.params !== this.state.params) this.get();
    }

    updateSort = (field, type) => this.setState(prev => ({
        ...prev,
        params: {
            ...prev?.params,
            sortField: field,
            sortType: type
        }
    }));

    updateParams = (name, value, restart = false) => {
        let obj = {
            [name]: value
        }
        this.setState(prev => ({
            ...prev,
            params: {
                ...prev?.params,
                entries: restart ? 10 : prev.params.entries,
                page: restart ? 0 : prev.params.page,
                ...obj
            }
        }));
    }

    updateMultipleParams = (fields, restart = false) => {
        let obj = {};
        if (restart) {
            obj.entries = 10;
            obj.page = 0;
        }
        for (let i = 0; i < fields.length; i++) {
            obj[fields[i].name] = fields[i].value;
        }
        return this.setState(prev => ({
            ...prev,
            params: {
                ...prev?.params,
                ...obj
            }
        }));
    }

    changeStatus = (id, status) => {
        if (!id || !status || !['approved', 'denied'].includes(status)) return;

        this.setState({ loading: true }, async () => {
            const response = await fetch(`${API_ENDPOINT}/users/two-factor-auth/registration-requests/${id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                },
                body: JSON.stringify({ status, selectedGroup: this.props.selectedGroup })
            });
            if (!response) return this.setState({ error: defaultError, loading: null, });
            const result = await response.json();
            if (!result || result.error) return this.setState({ error: result?.error || defaultError, loading: null });

            this.setState({ user: null, loading: null }, () => this.props?.refresh?.());
        })

    }

    approveMultiple = (ids) => {
        if (!ids || !Array.isArray(ids) || !ids.length) return;
        this.setState({ loading: true }, async () => {
            const response = await fetch(`${API_ENDPOINT}/users/two-factor-auth/registration-requests/multiple`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                },
                body: JSON.stringify({
                    ids,
                    selectedGroup: this.props.selectedGroup
                })
            });
            if (!response) return this.setState({ error: defaultError, loading: null, });
            const result = await response.json();
            if (!result || result.error) return this.setState({ error: result?.error || defaultError, loading: null });

            this.setState({ user: null, loading: null }, () => this.props?.refresh?.());
        });
    }

    render() {
        let params = this.state.params;

        return (
            <>
                <ListBuilder
                    lang={this.props.lang}
                    loading={this.state.loading}
                    total={this.state.total}
                    showNumeration={false}
                    filterDelay={500}
                    fields={[
                        { type: 'text', name: 'socialSecurityNumber', label: 'Social security number'.translate(this.props.lang) },
                        { type: 'text', name: '_name', label: 'Name'.translate(this.props.lang) },
                        { type: 'text', name: 'email', label: 'Email address'.translate(this.props.lang) },
                        { type: 'text', name: '_phone', label: 'Phone number'.translate(this.props.lang) },
                        { type: 'text', name: '_children', label: 'Children'.translate(this.props.lang) },
                        { type: 'text', name: '_status', label: 'Status'.translate(this.props.lang) },
                    ]}
                    rawItems={this.state.items}
                    items={this.state.items?.map(item => ({
                        ...item,
                        _phone: item?.phone ? String(item.phone).startsWith('+') ? item.phone : `+${item.phone}` : '',
                        _name: item?.userData?.name || item?.name || '',
                        _children: item?.children?.length ? <>{'Yes'.translate(this.props.lang)} {' '} ({item?.children?.length})</> : 'No'.translate(this.props.lang),
                        _status: item?.twoFactorAuthentication?.[this.props.selectedGroup]?.status ? <span style={{ color: item?.twoFactorAuthentication?.[this.props.selectedGroup]?.status === 'denied' ? 'red' : 'blue' }}>{item?.twoFactorAuthentication?.[this.props.selectedGroup]?.status?.translate(this.props.lang)}</span> : <span style={{ color: 'orange' }}>{'unhandled'.translate(this.props.lang)}</span>
                    }))}
                    actions={[
                        {
                            component: <div className="tabel-action-tooltip">
                                <Isvg src={eyeIcon} className="view-icon" />
                                <div className="chat-icons-tooltip">{'View details'.translate(this.props.lang)}</div>
                            </div>,
                            onClick: item => this.setState({ user: item })
                        },
                    ]}
                    params={params}
                    sortField={params.sortField}
                    sortType={params.sortType}
                    updateSort={this.updateSort}
                    updateParams={this.updateParams}
                    updateMultipleParams={this.updateMultipleParams}
                    multiselect={this.props.multiselect ? [{ text: 'Approve all'.translate(this.props.lang), onClick: this.approveMultiple, color: 'primary' }] : undefined}
                />

                {this.state.user ? <Modal isOpen={this.state.user} size='lg' centered toggle={() => this.setState({ user: null })} backdrop={true}>
                    <ModalHeader style={{ textAlign: 'center' }}>{'Details'.translate(this.props.lang)}</ModalHeader>
                    <ModalBody>
                        {this.state.user?.socialSecurityNumber ? <div className='eb-modal-div'>
                            <h6>{'Social security number'.translate(this.props.lang || 'en')}</h6>
                            <div>{this.state.user?.socialSecurityNumber}</div>
                        </div> : null}
                        {this.state.user?.dateOfBirth?.timestamp ?
                            <div className='eb-modal-div'>
                                <h6>{'Date of birth'.translate(this.props.lang || 'en')}</h6>
                                <div>{new Date(this.state.user?.dateOfBirth?.timestamp * 1000)?.toLocaleDateString(this.props.lang === 'en' ? 'en-EN' : 'sv-SE')}</div>
                            </div>
                            : null}
                        <div className='eb-modal-div'>
                            <h6>{'Name'.translate(this.props.lang || 'en')}</h6>
                            <div>{this.state.user?.userData?.name || this.state.user?.name}</div>
                        </div>
                        <div className='eb-modal-div'>
                            <h6>{'Email address'.translate(this.props.lang || 'en')} {this.state.user?.twoFactorAuthentication?.[this.props.selectedGroup]?.emailIsVerified ? <span className='verified-2fa'>{'Verified'.translate(this.props.lang)}!</span> : null}</h6>
                            <div>{this.state.user?.email}</div>
                        </div>
                        <div className='eb-modal-div'>
                            <h6>{'Phone number'.translate(this.props.lang || 'en')} {this.state.user?.twoFactorAuthentication?.[this.props.selectedGroup]?.phoneIsVerified ? <span className='verified-2fa'>{'Verified'.translate(this.props.lang)}!</span> : null}</h6>
                            <div className='unset'>
                                <PhoneField disabled={true} value={this.state.user?.phone} onChange={() => { }} />
                            </div>
                        </div>
                        {this.state.user?.twoFactorAuthentication?.[this.props.selectedGroup]?.createAccount?.ts
                            ?
                            <div className='eb-modal-div'>
                                <h6>{'Account Created Date'.translate(this.props.lang || 'en')}</h6>
                                <div>{new Date(this.state.user?.twoFactorAuthentication?.[this.props.selectedGroup]?.createAccount?.ts)?.toLocaleString(this.props.lang === 'en' ? 'en-EN' : 'sv-SE')}</div>
                            </div>
                            :
                            null
                        }
                        {this.state.user?.children?.length > 0 ?
                            <div className='eb-modal-div'>
                                <h6>{'Children'.translate(this.props.lang || 'en')} {' '} ({`${this.state.user?.children?.length}`})</h6>
                                <div className='unset child-2fa'>{this.state.user?.children?.map?.((child, i) => <div key={child?._id || i}>
                                    {child?.socialSecurityNumber ? <div>
                                        <span>{'Social security number'.translate(this.props.lang)}:</span>
                                        <span>{child?.socialSecurityNumber}</span>
                                    </div> : null}
                                    {child?.dateOfBirth?.timestamp ? <div>
                                        <span>{'Date of birth'.translate(this.props.lang || 'en')}:</span>
                                        <span>{new Date(child?.dateOfBirth?.timestamp * 1000)?.toLocaleDateString(this.props.lang === 'en' ? 'en-EN' : 'sv-SE')}</span>
                                    </div> : null}
                                    {child?.userData?.name || child?.name ? <div>
                                        <span>{'Name'.translate(this.props.lang)}:</span>
                                        <span>{child?.userData?.name || child?.name}</span>
                                    </div> : null}
                                </div>)}</div>
                            </div>
                            : null}
                        {this.props.handled ? <div className='eb-modal-div'>
                            <h6>{'Status'.translate(this.props.lang || 'en')}</h6>
                            <div style={{ color: this.state.user?.twoFactorAuthentication?.[this.props.selectedGroup]?.status === 'denied' ? 'red' : 'blue' }}>{this.state.user?.twoFactorAuthentication?.[this.props.selectedGroup]?.status?.translate?.(this.props.lang || 'en')}</div>
                        </div> : null}
                    </ModalBody>
                    <ModalFooter style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                        <button style={{ visibility: 'hidden', display: 'none' }} aria-hidden='true'></button>

                        {!this.state.user?.twoFactorAuthentication?.[this.props.selectedGroup]?.status || this.state.user?.twoFactorAuthentication?.[this.props.selectedGroup]?.status === 'approved' ? <Button color='danger' onClick={() => this.changeStatus(this.state.user?._id, 'denied')}>
                            {'Deny'.translate(this.props.lang)}
                        </Button> : null}

                        {!this.state.user?.twoFactorAuthentication?.[this.props.selectedGroup]?.status || this.state.user?.twoFactorAuthentication?.[this.props.selectedGroup]?.status === 'denied' ? <Button color='info' onClick={() => this.changeStatus(this.state.user?._id, 'approved')}>
                            {'Approve'.translate(this.props.lang)}
                        </Button> : null}

                        <Button color='primary' onClick={() => this.setState({ user: null })}>
                            {'Close'.translate(this.props.lang)}
                        </Button>
                    </ModalFooter>
                </Modal> : null}

                {this.state.error ? <ErrorModal isOpen={this.state.error} toggle={() => this.setState({ error: null })} size='md'>
                    {typeof this.state.error === 'string' ? this.state.error.translate(this.props.lang || 'en') : 'Something went wrong! Please try again later.'.translate(this.props.lang || 'en')}
                </ErrorModal> : null}
            </>
        )
    }
}

export default RegistrationRequestsList