import React, { Component } from 'react';
import Page from '../../containers/page';

import {
    Container,
    Row,
    Col,
    Modal, ModalHeader, ModalBody, Input
} from 'reactstrap';

import Isvg from 'react-inlinesvg'

import FormBuilder from '../../components/forms/modalFormBuilder';
import { required } from '../../components/forms/validation';

import ListBuilder from '../../components/listBuilder';
import DeleteModal from '../../components/deleteModal';
import { getSearchParams, generateSearchLink, generateSearchLinkMultiple } from '../../helpers/linkHelper';
import { API_ENDPOINT } from '../../constants';
import ErrorModal from '../../components/errorModal';
import moment from 'moment';

import Search from '../../components/search';
import addIcon from '../../assets/svg/add-icon.svg';
import view from '../../assets/svg/eye.svg'

import profileUser from '../../assets/images/user.png';
import notification from '../../assets/svg/notification.svg'
import moreIcon from '../../assets/svg/more.svg'

import DatePicker from '../../components/forms/fields/datePicker';


function parseJSON(response) {
    return response.json().then(json => {
        return {
            result: json,
            status: response.status
        }
    })
}

/**
* System logs list
* @author   Milan Stanojevic
*/
class Logs extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props.initialData,
            form: false,
            useState: false,
            params: {
                entries: 10,
                page: 0,
                sortField: 'timestamp',
                sortType: -1
            },
            items: [],
            clinicGroups: [],
            startDateSearch: null,
            endDataSearch: null,
            total: 0,
            loading: true
        };
    }


    get = () => {
        this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));

        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, {
                entries: 10, page: 0, sortField: 'timestamp',
                sortType: -1
            })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

        for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
            this.props.loadDataWithQuery[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, {
                entries: 10, page: 0, sortField: 'timestamp', group: this.state.selectedClinicGroup, startDate: this.state.startDateSearch, endDate: this.state.endDataSearch,
                sortType: -1
            })).then((data) => {
                let dataToSet = { ...data }
                if (dataToSet.page || dataToSet.items || dataToSet.total) {
                    dataToSet.loading = null;
                    // console.log(dataToSet.page || dataToSet.items || dataToSet.total)
                    let checkFilter = '';
                    if (dataToSet.resultFilter) {
                        checkFilter = String(dataToSet.resultFilter)
                        delete dataToSet.resultFilter;
                    }
                    let search = this.props[0].location.search;
                    let serachParams = getSearchParams(search);
                    let searchParamsFilter = '';
                    if (serachParams.filter) {
                        searchParamsFilter = serachParams.filter;
                    }
                    if (searchParamsFilter != checkFilter) {
                        delete dataToSet.page;
                        delete dataToSet.items;
                        delete dataToSet.total;
                        delete dataToSet.loading;
                    }
                }
                this.setState({
                    ...dataToSet,
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

    }

    componentDidMount() {
        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }

        this.get();
    }

    componentDidUpdate(prevProps) {
        if (prevProps[0].location.search != this.props[0].location.search) {
            this.setState({
                loading: true
            }, () => {
                this.get();

            })
        }
    }


    updateSort = (field, type) => {
        if (this.state.useState) {
            let state = this.state.params;
            state.sortField = field;
            state.sortType = type;
            this.setState({
                params: state
            })
        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0 }, [{ name: 'sortField', value: field }, { name: 'sortType', value: type }], false));

        }
    }

    updateParams = (name, value, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0,
                    sortField: 'timestamp',
                    sortType: -1
                };
                obj[name] = value;
                this.setState({ params: obj });
            } else {
                let obj = this.state.params;
                obj[name] = value;
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(generateSearchLink(this.props[0].location.search, { entries: 10, page: 0 }, name, value, restart));
        }
    }
    updateMultipleParams = (fields, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0,
                    sortField: 'timestamp',
                    sortType: -1
                };
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj });

            } else {
                let obj = this.state.params;
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0 }, fields, restart));
        }
    }




    render() {
        let params = {};
        if (this.state.useState) {
            params = this.state.params;
        } else {
            params = getSearchParams(this.props[0].location.search, { entries: 10, page: 0 })
        }
        return (
            <div className={this.props.autoHideSidebar ? "dashboard dashboard-auto-hide" : "dashboard"}>

                <Container fluid>
                    <Row>
                        <Col lg="12">
                            <div className="panel">
                                <div className="panel-header">
                                    <h5 className="component-header">{this.props.linksMeta && this.props.linksMeta[this.props[0].location.pathname] ? this.props.linksMeta[this.props[0].location.pathname][this.props.lang] : ''}</h5>

                                </div>
                                {/* <div className="logs-clinic-group-filter-wrap">
                                    <Input type="select" className="clinic-group-select" value={this.state.selectedClinicGroup} onChange={(e) => {
                                        if (e.target.value != -1) {
                                            this.setState({ selectedClinicGroup: e.target.value }, this.get)
                                        } else {
                                            this.setState({ selectedClinicGroup: null }, this.get)
                                        }
                                    }}>
                                        <option value={-1}>{'Select clinic group'.translate(this.props.lang)}</option>
                                        {
                                            this.state.clinicGroups && this.state.clinicGroups.map((item, idx) => {
                                                return (
                                                    <option value={item._id}>{item.name}</option>
                                                )
                                            })
                                        }
                                    </Input>
                                    <DatePicker
                                        label={'Start date'.translate(this.props.lang)}
                                        value={this.state.startDateSearch}
                                        onChange={(e) => {
                                            this.setState({
                                                startDateSearch: e
                                            }, this.get)
                                        }}
                                    />
                                    <DatePicker
                                        label={'End date'.translate(this.props.lang)}
                                        value={this.state.endDataSearch}
                                        onChange={(e) => {
                                            this.setState({
                                                endDataSearch: e
                                            }, this.get)
                                        }}
                                    />

                                </div> */}
                                <ListBuilder
                                    lang={this.props.lang}
                                    loading={this.state.loading}
                                    total={this.state.total}
                                    showNumeration={false}
                                    fields={[
                                        { type: 'text', name: 'method', label: 'Method'.translate(this.props.lang), allowSort: true },
                                        { type: 'text', name: 'endpoint', label: 'Endpoint'.translate(this.props.lang), allowSort: true },
                                        { type: 'text', name: 'status', label: 'Status code'.translate(this.props.lang), allowSort: true },
                                        { type: 'text', name: 'ip', label: 'IP'.translate(this.props.lang), allowSort: true },
                                        { type: 'text', name: 'username', label: 'User'.translate(this.props.lang), allowSort: false },
                                        { type: 'text', name: 'timestamp', label: 'Time'.translate(this.props.lang), allowSort: true },
                                        { type: 'text', name: 'executionTime', label: 'Execution time [ms]'.translate(this.props.lang), allowSort: true },

                                    ]}
                                    rawItems={this.state.items}
                                    items={this.state.items.map(item => {
                                        return {
                                            ...item,
                                            timestamp: this.props.getStringDateTs(Math.floor(item.timestamp / 1000), `${this.props.dateFormat} HH:mm:ss`)/*moment.unix(Math.floor(item.timestamp / 1000)).format(`${this.props.dateFormat} HH:mm:ss`)*/
                                        }
                                    })}
                                    actions={
                                        [
                                            {
                                                component: <Isvg src={view} className="view-icon" />,
                                                onClick: (item) => this.setState({ logView: item })
                                            },
                                        ]
                                    }
                                    params={params}
                                    sortField={params.sortField}
                                    sortType={params.sortType}
                                    updateSort={this.updateSort}
                                    updateParams={this.updateParams}
                                    updateMultipleParams={this.updateMultipleParams}
                                ></ListBuilder>
                            </div>
                        </Col>
                    </Row>

                </Container>
                {
                    this.state.logView ?
                        <Modal isOpen={this.state.logView} centered toggle={() => this.setState({ logView: !this.state.logView })} size={'lg'} className="clinic-modal">
                            <ModalHeader toggle={() => this.setState({ logView: !this.state.logView })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ logView: !this.state.logView })}>&times;</button>}>{'Preview log'.translate(this.props.lang)}</ModalHeader>

                            <ModalBody>
                                <Row>
                                    <Col lg="6">

                                        <p><strong>{'Endpoint:'.translate(this.props.lang)}</strong> {this.state.logView.endpoint}</p>
                                        <p><strong>{'Method:'.translate(this.props.lang)}</strong> {this.state.logView.method}</p>
                                        <p><strong>{'Status code:'.translate(this.props.lang)}</strong> {this.state.logView.status}</p>
                                        <br />
                                        <p><strong>{'Time:'.translate(this.props.lang)}</strong> {this.props.getStringDateTs(Math.floor(this.state.logView.timestamp / 1000), `${this.props.dateFormat} HH:mm:ss`)/*moment.unix(Math.floor(this.state.logView.timestamp / 1000)).format(`${this.props.dateFormat} HH:mm:ss`)*/}</p>
                                        <p><strong>{'Execution time:'.translate(this.props.lang)}</strong> {this.state.logView.executionTime} ms</p>

                                        <br />


                                    </Col>
                                    <Col lg="6">
                                        {
                                            this.state.logView.body ?
                                                <pre>
                                                    {
                                                        this.state.logView.body
                                                    }
                                                </pre>
                                                :

                                                null
                                        }
                                        {
                                            this.state.logView.paymentResponse ?
                                                <pre>
                                                    {
                                                        JSON.stringify(this.state.logView.paymentResponse,null,4)
                                                    }
                                                </pre>
                                                : null
                                        }

                                    </Col>
                                    <Col lg="12">
                                        <p><strong>{'Ip:'.translate(this.props.lang)}</strong> {this.state.logView.ip}</p>
                                        <p><strong>{'Referer:'.translate(this.props.lang)}</strong> {this.state.logView.referer}</p>
                                        <p><strong>{'User agent:'.translate(this.props.lang)}</strong> {this.state.logView.userAgent}</p>

                                    </Col>

                                </Row>
                            </ModalBody>
                        </Modal>
                        :
                        null
                }


            </div>
        );
    }
}

export default Page(Logs);