import React, { Component } from 'react';

import { Link } from 'react-router-dom';

import {
    Container,
    Row,
    Col,
    Button,
    FormGroup,
    Label,
    Input,
    Modal, ModalHeader, ModalBody, ModalFooter, Form
} from 'reactstrap';

import { API_ENDPOINT } from '../../constants';

import AnswerFormComponent from './answerFormComponent';

function parseJSON(response) {
    return response.json().then(json => {
        return {
            result: json,
            status: response.status
        }
    })
}



class DiaryForm extends Component {
    constructor(props) {
        super(props);
        this.searchResultsWraps = {};

        this.state = {
            ...props.initialData,
            answers: {},
            sections: [],
            showButton: true,
        };
    }

    componentDidMount() {

        let setObj = {}
        let referral = {
            intelligentReferral: true
        };

        if (this.props.sections) {
            referral.sections = this.props.sections;
        }

        if (this.props.answers) {
            setObj.answers = this.props.answers;
        }
        setObj.referral = referral;
        this.setState(setObj)

        if (this.props.selectedEventAnswers && this.props.answerDaybookOpenModal) {
            if (this.props.selectedEventAnswers.length) {
                let arrFormIdx = this.props.selectedEventAnswers.findIndex(item => item.formID == this.props.answerDaybookOpenModal._id)
                if (this.props.selectedEventAnswers[arrFormIdx]) {
                    this.setState({
                        changeTime: true
                    })
                } else {
                    this.setState({
                        changeTime: false
                    })
                }
            } else {
                this.setState({
                    changeTime: false
                })
            }
        }
        if (this.props.date && Math.floor(new Date(this.props.date + ' 23:59:59').getTime() / 1000 + 60 * 60 * 24 * this.props.days) < Math.floor(new Date().getTime() / 1000)) {

            this.setState({
                showButton: false
            })
        }

        if (this.props.alias) {
            this.getGroup(this.props.alias);
        }

    }

    getGroup = (alias) => {
        fetch(API_ENDPOINT + '/landing/page', {
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify({ alias })
        }).then(res => res.json()).then((result) => {
            if (result && result._id) {
                this.setState({ clinicGroup: result })
            }
        })
    }

  

 

    
    submit = (data, callback) => {
        if (data.answers) {
            if (this.props.answer) {
                this.props.answer(data.answers, () => {
                    if (this.state.changeTime) {
                        let html = `<div></div>`
                        let totals = `<div></div>`
                        let scale = `<div></div>`
                        if (this.props.saveAnswers)
                            this.props.saveAnswers(html, totals, scale, callback)
                    } else {
                        let html = `<div></div>`
                        let totals = `<div></div>`
                        let scale = `<div></div>`
                        if (this.props.saveAnswers)
                            this.props.saveAnswers(html, totals, scale, callback)
                    }
                })
            }
        }

    }

    render() {
        return (
            <div>
                <Container fluid>
                    <Row>
                        {this.state.referral?.sections?.length ?
                            <Col lg="12">
                                <AnswerFormComponent
                                    {...this.props}
                                    referral={this.state.referral}
                                    inModal={true}
                                    hideSubmitButton={!this.state.showButton}
                                    clinicGroup={this.state.clinicGroup}
                                    preview={!this.state.showButton}
                                    submitButtonText={this.state.changeTime ? "Change my answers".translate(this.props.lang) : 'Save'.translate(this.props.lang)}
                                    submitAction={(data, callback) => {
                                        this.submit(data, callback)
                                    }}
                                    backButtonText='Previous question'
                                />
                            </Col>
                            :
                            null
                        }

                    </Row>

                </Container>



                {this.state.wikiModal ?
                    <Modal isOpen={this.state.wikiModal} centered toggle={() => this.setState({ wikiModal: !this.state.wikiModal })} wrapClassName="wiki-hint-modal">
                        <ModalHeader close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ wikiModal: !this.state.wikiModal })}>&times;</button>}>{this.state.wikiModal.name}</ModalHeader>
                        <ModalBody>
                            <div dangerouslySetInnerHTML={{ __html: this.state.wikiModal.content }}></div>
                            <img src={API_ENDPOINT + this.state.wikiModal.image} />
                        </ModalBody>
                    </Modal>
                    :
                    null
                }


            </div>
        );
    }
}

export default DiaryForm;