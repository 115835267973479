import React from 'react';

import {
    Modal, ModalHeader, ModalBody, ModalFooter, Button, FormGroup, Label, Input
} from 'reactstrap';
import SocialSecurityNumber from '../../../components/forms/fields/socialSecurityNumber';
import Checkbox from '../../../components/forms/fields/checkbox';
import infoIcon from "../../../assets/svg/link-new-tab.svg";
import xIcon from "../../../assets/svg/x.svg";
import Isvg from 'react-inlinesvg'
import { API_ENDPOINT } from '../../../constants';
import ErrorModal from '../../../components/errorModal';

/**
* Info modal 
* @author   Milan Stanojevic
*/
class InfoModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            value: ''
        }
    }

    componentDidMount() {
        if (this.props.isOpen) {
            this.setState({
                isOpen: this.props.isOpen
            })
        }
    }
    searchPatient = (search, direct = false) => {
        let obj = {
            socialSecurityNumber: search,
            group: this.props.selectedGroup,
            clinic: this.props.selectedClinic
        }

        fetch(API_ENDPOINT + '/users/search/patient', {
            method: 'POST',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
            body: JSON.stringify(obj)
        }).then(res => res.json()).then((result) => {
            if (result && result.error) {
                this.setState({
                    error: result.error,
                    name: null,
                    socialSecurityNumber: null,
                    patientID: null
                })
            } else {
                this.setState({
                    name: result.name,
                    socialSecurityNumber: result.socialSecurityNumber,
                    patientID: result._id
                })
            }
        })
    }
    connectPatient = () => {

        fetch(API_ENDPOINT + '/users/connect/patient', {
            method: 'POST',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
            body: JSON.stringify({
                relationship: this.state.relationship,
                coment: this.state.coment,
                id: this.state.patientID,
                parentPatient: this.props.parentID
            })
        }).then(res => res.json()).then((result) => {
            if (result && result.error) {
                this.setState({
                    error: result.error,

                })
            } else {
                this.props.onClose()
            }
        })
    }
    render() {
        return (
            <div>
                <Modal isOpen={this.state.isOpen} centered>

                    <ModalHeader style={{ margin: 'auto' }} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.props.onClose()}><Isvg src={xIcon} /></button>}>
                        {'Link new account'.translate(this.props.lang)}
                    </ModalHeader>
                    <ModalBody className="delete-modal">
                        <FormGroup>
                            <div >
                                <div className="enter-number-modal">
                                    <Label>{this.props.country === 'uk' ? "Date of birth".translate(this.props.lang) : "Enter social security number".translate(this.props.lang)} </Label>
                                    <Isvg src={infoIcon} /> <div className="chat-icons-tooltip">{"Children can only be added by their guardians".translate(this.props.lang)}</div>

                                </div>
                                <div className="social-number-input">
                                    <SocialSecurityNumber
                                        filterFields={['email']}
                                        includeChildren={false}
                                        hideFindChildren={true}
                                        disableDoB={true}
                                        disableNewPatientBtn={true}
                                        selectedGroup={this.props.selectedGroup}
                                        selectedClinic={this.props.selectedClinic}
                                        searchByNameAndEmail={this.state.searchByNameAndEmail || true}
                                        searchByNameAndEmailShow={(show) => {
                                            this.setState({
                                                searchByNameAndEmail: show
                                            })
                                        }}
                                        selectUserCallback={() => {
                                            this.searchPatient(this.state.value)
                                        }}
                                        lang={this.props.lang}
                                        country={this.props.country} placeholder={this.props.country === 'denmark' ? 'DDMMYYNNNNN' : this.props.lang === 'en' ? 'YYYYMMDDNNNN' : 'ÅÅÅÅMMDDNNNN'}
                                        haveValidation={this.props.haveValidation
                                        } value={this.state.value}
                                        errorMessage={"Social security number not correct.".translate(this.props.lang)}
                                        type="text"
                                        callbackAfterResetSerachField={() => {
                                            this.setState({
                                                name: null,
                                                socialSecurityNumber: null,
                                                patientID: null,
                                                relationship: null,
                                                coment: null,
                                                check: null
                                            })
                                        }}
                                        onChange={(e, callback) => {
                                            this.setState({
                                                value: e
                                            }, () => {
                                                if (callback) {
                                                    callback()
                                                }
                                                //api trazi usera po socialsecurityNumber
                                            })
                                        }} />

                                    <Button onClick={() => this.searchPatient(this.state.value)}>{'Search'.translate(this.props.lang)}</Button>
                                </div>
                            </div>

                        </FormGroup>
                        {

                            this.state.name ?
                                <div>
                                    <FormGroup>
                                        <div className="enter-number-inputs">
                                            <div style={{ width: '50%' }}>{this.state.name}<br /><span className="clinic-subgroup-name">{this.state.socialSecurityNumber} </span></div>
                                            <Input value={this.state.relationship} type='select' onChange={(e) => {
                                                this.setState({
                                                    relationship: e.target.value
                                                })
                                            }} >
                                                <option value={-1}>{'Choose relationship'.translate(this.props.lang)}</option>
                                                <option value={'partner'}>{'Partner'.translate(this.props.lang)}</option>
                                            </Input>
                                        </div>

                                    </FormGroup>

                                    <FormGroup>
                                        <div className="enter-number-confirm">
                                            <div>
                                                <Label>{'Comentar'.translate(this.props.lang)}</Label>
                                                <Input type='textarea' rows={6} value={this.state.coment} onChange={(e) => {
                                                    this.setState({
                                                        coment: e.target.value
                                                    })
                                                }} />

                                                <Checkbox checked={this.state.check} onChange={() => {
                                                    this.setState({ check: !this.state.check }, () => {

                                                    })
                                                }} label={"Confirm that the information is correct and that consent for linking has been obtained".translate(this.props.lang)}></Checkbox>
                                            </div>
                                            <Button onClick={() => {
                                                this.connectPatient()
                                            }} disabled={this.state.relationship && this.state.relationship != -1 && this.state.name && this.state.check ? false : true} >{'Connect'.translate(this.props.lang)}</Button>
                                        </div>

                                    </FormGroup>
                                </div>
                                :
                                null
                        }
                    </ModalBody>
                    <ModalFooter className="buttons-right-blue">
                        {/* <Button color="primary" style={{ margin: 10 }} onClick={() => this.props.onClose()}>{'OK'.translate(this.props.lang)}</Button> */}

                    </ModalFooter>
                </Modal>

                {this.state.error ?

                    <ErrorModal lang={this.props.lang}
                        isOpen={this.state.error}
                        toggle={() => {
                            this.setState({ error: null })
                        }}
                        zIndex={99999999999}
                    >
                        {this.state.error.translate(this.props.lang)}
                    </ErrorModal>

                    :
                    null
                }

            </div>

        )
    }
}

export default InfoModal;