import React from 'react';

import {
    Modal, ModalHeader, ModalBody, ModalFooter, Button
} from 'reactstrap';

import warrning from '../assets/svg/warrning.svg'
import info from '../assets/svg/info.svg'


/**
* Modal for showing error
* @author   Milan Stanojevic
*/
class ErrorModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        return (

            <Modal isOpen={this.props.isOpen} centered size={this.props.size ? this.props.size : null}>
                {/* <ModalHeader style={{ margin: 'auto' }}>{'Error'.translate(this.props.lang)}</ModalHeader> */}
                <ModalBody className="delete-modal">
                    {/* {
                        this.props.info ?

                            <img src={info} />

                            :

                            <img src={warrning} />
                    } */}
                    <div className="message" style={{paddingTop: 30, paddingLeft: 20, paddingRight: 20}}>
                        {
                            this.props.children
                        }
                    </div>
                </ModalBody>
                <ModalFooter style={{ display: 'flex', justifyContent: 'center' }} className='modal-footer-buttons-container'>
                    {
                        this.props.buttons ?
                            this.props.buttons && this.props.buttons.map((item, idx) => {
                                return (
                                    <Button color="primary" onClick={item.onClick} >{item.text}</Button>
                                )
                            })
                            :
                            null
                    }
                    {/* <Button type="submit" color="primary" onClick={() => this.props.toggle()} >{'Close'.translate(this.props.lang)}</Button>{' '} */}
                </ModalFooter>
            </Modal>
        )
    }
}

export default ErrorModal;