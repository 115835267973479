import React, { Component } from 'react';
import Isvg from 'react-inlinesvg';
import logoIcon from '../../assets/svg/cf-logo.svg';

export class DefaultErrorPage extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    navigateHome = () => {
        if (typeof window !== 'undefined') window?.location?.replace?.(window?.location?.origin);
    }

    render() {
        return (
            <div className='eb-container'>
                <div>
                    <div className='eb-logo'>
                        <Isvg src={logoIcon} />
                    </div>
                    <div className='eb-text'>
                        <h1>Ett tekniskt fel har uppstått!</h1>
                        <p className='eb-message'>På grund av ett oväntat fel har applikationen stoppats. För att hjälpa oss åtgärda felet skickas automatiskt en rapport med information om felet. Du kan gå tillbaka till din sida genom att klicka på knappen nedan eller ladda om sidan.</p>
                        <div className='eb-report'>
                            {this.props.reportSent === true
                                ? <p className='eb-p-green'>Rapport skickad!</p>
                                : this.props.reportSent === false
                                    ? <p className='eb-p-red'>Fel när rapporten skickades!</p>
                                    : <p className='eb-p-orange'>Skickar rapport ...</p>}
                        </div>
                        <div className='eb-buttons-container'>
                            <button onClick={this.navigateHome}>Gå tillbaka</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default DefaultErrorPage