import React, { Component } from 'react';
import Page from '../../containers/page';

import {
    Container,
    Row,
    Col,
    Modal, ModalHeader, ModalBody, ModalFooter, Button, FormGroup, Input, Label, Table
} from 'reactstrap';

import { Link } from 'react-router-dom';

import FormBuilder from '../../components/forms/modalFormBuilder';
import { required } from '../../components/forms/validation';

import ListBuilder from '../../components/listBuilder';
import DeleteModal from '../../components/deleteModal';
import ErrorModal from '../../components/errorModal';
import ErrorModal2 from '../../components/errorModal_v2';

import InfoModal from '../../components/infoModal';

import { getSearchParams, generateSearchLink, generateSearchLinkMultiple } from '../../helpers/linkHelper';
import { API_ENDPOINT } from '../../constants';
import { formValueSelector, change } from 'redux-form';  // ES6
import { connect } from 'react-redux';
import ReferralClinics from '../../components/referralClinics';
import { Calendar, Day, momentLocalizer } from 'react-big-calendar'
import CustomToolbar from './calendarViews/CustomToolbar';
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from 'moment';
import PrintHelper from '../../components/printHelper';



import View from '../../assets/svg/eye.svg';

import TimePicker from '../../components/forms/fields/timepicker';

import Isvg from 'react-inlinesvg'
import warrning from '../../assets/images/warrning.png';
import Search from '../../components/search';
import profileUser from '../../assets/images/user.png';
import notification from '../../assets/svg/notification.svg';
import moreIcon from '../../assets/svg/more.svg';
import CalendarToolbar from './calendarViews/CustomToolbar';
import EventComponent from './calendarViews/EventComponentt'
import DatePicker from '../../components/forms/fields/datePickerNextPrev';
import xIcon from '../../assets/svg/x.svg';
import openChat from '../../assets/svg/open_chat.svg';
import { Player, Controls } from "@lottiefiles/react-lottie-player";
import DragAndDropList from '../../components/dragAndDropList';

import { v4 as uuidv4 } from 'uuid';

import Checkbox from '../../components/forms/fields/checkbox';


const dateFormat = 'MM/DD/YYYY HH:mm';

const localizer = momentLocalizer(moment)

function pad(n, width, z) {
    z = z || '0';
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
}

class ScheduleReferrals extends Component {
    constructor(props) {
        super(props);
        this.player = React.createRef();
        this.player2 = React.createRef();
        this.wrapperRef = React.createRef();
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.state = {
            ...props.initialData,
            form: false,
            useState: false,
            params: {
                entries: 10,
                page: 0
            },
            items: [],
            total: 0,
            selectedDate: moment.unix(new Date().getTime() / 1000).format(this.props.dateFormat),
            searchDoctors: '',
            listOfDoctors: null,
            doctorList: [],
            cols: [],
            searchDropdownActive: false,
            clipboard: [],
            hidenEvents: [],
            // takeCareEvents: {},
        };


    }
    moveToClipoboard = (item, callback) => {
        let clipoard = this.state.clipboard;
        let eventObj = item;
        eventObj.inClipboard = true;
        if (clipoard.filter(event => event._id == item._id).length == 0) {
            clipoard.push(eventObj)


            if (eventObj.additionalDoctors && eventObj.additionalDoctors.length) {
                for (let i = 0; i < this.state.cols.length; i++) {
                    for (let j = 0; j < this.state.cols[i].events.length; j++) {
                        if (this.state.cols[i].events[j].eventId == item._id) {
                            let event = this.state.cols[i].events[j];
                            event.inClipboard = true;
                            clipoard.push(event)
                        }
                    }

                }
            }
            let cols = {};
            for (let i = 0; i < this.state.cols.length; i++) {
                cols[this.state.cols[i]._id] = { ...this.state.cols[i], events: [] };
            }


            for (let i = 0; i < this.state.cols.length; i++) {
                for (let j = 0; j < this.state.cols[i].events.length; j++) {
                    if (this.state.cols[i].events[j]._id == item._id) {
                        let event = eventObj;
                        cols[this.state.cols[i]._id].events.push(event);
                    } else if (this.state.cols[i].events[j].eventId == item._id) {
                        let event = this.state.cols[i].events[j];
                        event.inClipboard = true;

                        cols[this.state.cols[i]._id].events.push(event);
                    } else {
                        cols[this.state.cols[i]._id].events.push(this.state.cols[i].events[j]);
                    }
                }

            }



            this.setState({
                cols: Object.values(cols),
            })
        }





        this.setState({ clipoard })
        if (callback) {
            callback();
        }

    }
    removeFromClipboard = (item) => {
        let clipoard = this.state.clipboard;
        let eventObj = item;
        eventObj.inClipboard = false;


        for (let i = 0; i < clipoard.length; i++) {
            if (clipoard[i]._id == item._id || clipoard[i].eventId == item._id) {
                clipoard.splice(i, item.additionalDoctors && item.additionalDoctors.length ? item.additionalDoctors.length + 1 : 1);
            }
        }
        let cols = {};
        for (let i = 0; i < this.state.cols.length; i++) {
            cols[this.state.cols[i]._id] = { ...this.state.cols[i], events: [] };
        }


        for (let i = 0; i < this.state.cols.length; i++) {
            for (let j = 0; j < this.state.cols[i].events.length; j++) {
                if (this.state.cols[i].events[j]._id == item._id) {
                    let event = this.state.cols[i].events[j];
                    cols[this.state.cols[i]._id].events.push(event);
                } else if (this.state.cols[i].events[j].eventId == item._id) {
                    let event = this.state.cols[i].events[j];
                    event.inClipboard = false;

                    cols[this.state.cols[i]._id].events.push(event);
                } else {
                    cols[this.state.cols[i]._id].events.push(this.state.cols[i].events[j]);
                }
            }
        }
        this.setState({
            cols: Object.values(cols),
        })


        this.setState({ clipoard })



    }
    addFromClipboard = (item, layout, callback) => {
        if (!item) return;

        if (item && this.state.cols && layout && this.state.cols[layout.x] && this.state.cols[layout.x]._id && item.patientId == this.state.cols[layout.x]._id) {
            this.setState({ error: 'You cannot transfer an appointment to chosen doctor.'.translate(this.props.lang) })
            return;
        }
        let returnItem = {
            ...item
        };

        // when event come from clipboard time is not set with y param on grid so we need to change that
        let fixedTimeForEvent = {
            ...item
        }
        let duration = fixedTimeForEvent.endTime - fixedTimeForEvent.startTime;
        let startDate = new Date(fixedTimeForEvent.startTime * 1000);
        startDate.setHours(0);
        startDate.setMinutes(0);
        startDate.setSeconds(0);
        startDate.setMilliseconds(0);
        let startTimestamp = Math.floor(startDate.getTime() / 1000);
        fixedTimeForEvent.startTime = startTimestamp + layout.y * (5 * 60);
        fixedTimeForEvent.endTime = fixedTimeForEvent.startTime + duration;


        let selectedDate = new Date(this.state.selectedDate)
        let startHours = new Date(fixedTimeForEvent.startTime * 1000).getHours()
        let startMinutes = new Date(fixedTimeForEvent.startTime * 1000).getMinutes()
        let startSeconds = new Date(fixedTimeForEvent.startTime * 1000).getSeconds()

        let newStartTime = selectedDate.setHours(startHours, startMinutes, startSeconds, 0) / 1000;
        newStartTime = Math.floor(new Date(this.props.getStringDateInverseTsV1(newStartTime, dateFormat)).getTime() / 1000)


        let endHours = new Date(fixedTimeForEvent.endTime * 1000).getHours()
        let endMinutes = new Date(fixedTimeForEvent.endTime * 1000).getMinutes()
        let endSeconds = new Date(fixedTimeForEvent.endTime * 1000).getSeconds()

        let newEndTime = selectedDate.setHours(endHours, endMinutes, endSeconds, 0) / 1000;
        newEndTime = Math.floor(new Date(this.props.getStringDateInverseTsV1(newEndTime, dateFormat)).getTime() / 1000)


        fixedTimeForEvent.endTime = newEndTime;
        fixedTimeForEvent.startTime = newStartTime;

        let cols = {};

        let clipboardAwait = false;

        let hidenEvents = [...this.state.hidenEvents]
        let hidenEventsForNextCheck = []

        let overlap = false
        let colsArray = this.state.cols
        let eventForChange = {}
        let overlapEvents = []
        for (let i = 0; i < colsArray.length; i++) {
            for (let j = 0; j < colsArray[i].events.length; j++) {

                if (colsArray[i].events[j]._id === item._id) {
                    eventForChange = { ...colsArray[i].events[j] }
                }
                if (colsArray[i].events[j]._id !== item._id && i === layout.x && !colsArray[i].events[j].forDelete) {
                    if ((fixedTimeForEvent.startTime > colsArray[i].events[j].startTime && fixedTimeForEvent.startTime < colsArray[i].events[j].endTime) || (fixedTimeForEvent.endTime > colsArray[i].events[j].startTime && fixedTimeForEvent.endTime < colsArray[i].events[j].endTime)) {
                        overlap = true
                        // if (!colsArray[i].events[j].forDelete)
                        overlapEvents.push(colsArray[i].events[j])
                    } else if (fixedTimeForEvent.startTime < colsArray[i].events[j].startTime && fixedTimeForEvent.startTime < colsArray[i].events[j].endTime && fixedTimeForEvent.endTime > colsArray[i].events[j].startTime && fixedTimeForEvent.endTime > colsArray[i].events[j].endTime) {
                        overlap = true
                        // if (!colsArray[i].events[j].forDelete)
                        overlapEvents.push(colsArray[i].events[j])
                    } else if (fixedTimeForEvent.startTime == colsArray[i].events[j].startTime) {
                        overlap = true
                        // if (!colsArray[i].events[j].forDelete)
                        overlapEvents.push(colsArray[i].events[j])
                    } else if (fixedTimeForEvent.endTime == colsArray[i].events[j].endTime) {
                        overlap = true
                        // if (!colsArray[i].events[j].forDelete)
                        overlapEvents.push(colsArray[i].events[j])
                    }
                }

            }
        }
        if (overlap && ((overlapEvents && overlapEvents.length && overlapEvents.filter(item => item.type === 'referral' || item.type === 'revisit' || item.type === 'another-clinic' || item.type === 'event' || item.type === 'take-care' || item.type === 'semble')?.length) || (eventForChange?.type === 'free' || eventForChange?.type === 'event'))) {
            this.setState({
                cols: [],
            }, () => {
                this.setState({ error: 'You cannot transfer an appointment because of overlap.'.translate(this.props.lang) }, () => {

                    setTimeout(() => {
                        this.setState({ cols: [...colsArray] })
                    }, 0)
                })

            })
        } else if (overlap) {
            let counter = 0;
            for (let i = 0; i < this.state.cols.length; i++) {
                cols[this.state.cols[i]._id] = { ...this.state.cols[i], events: [] };
            }
            for (let i = 0; i < this.state.cols.length; i++) {
                for (let j = 0; j < this.state.cols[i].events.length; j++) {

                    if (hidenEvents && hidenEvents.length && this.state.cols[i].events[j]._id == item._id) {
                        for (let k = 0; k < hidenEvents.length; k++) {
                            if (hidenEvents[k]._id === item._id && !hidenEvents[k].used) {

                                if (hidenEvents[k].startTime >= fixedTimeForEvent.startTime && hidenEvents[k].endTime <= fixedTimeForEvent.endTime && i === layout.x) {
                                    continue
                                } else if (hidenEvents[k].startTime <= fixedTimeForEvent.startTime && hidenEvents[k].endTime < fixedTimeForEvent.endTime && hidenEvents[k].endTime > fixedTimeForEvent.startTime && i === layout.x) {
                                    let newEvent1 = {
                                        ...hidenEvents[k],
                                        _id: uuidv4(),
                                        startTime: hidenEvents[k].startTime,
                                        endTime: fixedTimeForEvent.startTime
                                    }

                                    // hiden events will show again if we move event from overlaped free event
                                    let hidenEvent = {
                                        ...hidenEvents[k],
                                        _id: fixedTimeForEvent._id,
                                        startTime: fixedTimeForEvent.startTime,
                                        endTime: hidenEvents[k].endTime
                                    }

                                    hidenEventsForNextCheck.push(hidenEvent)

                                    if (hidenEvents[k].startTime !== fixedTimeForEvent.startTime) {
                                        cols[this.state.cols[i]._id].events.push(newEvent1);
                                        hidenEvents[k].used = true
                                    }
                                } else if (hidenEvents[k].startTime > fixedTimeForEvent.startTime && hidenEvents[k].startTime < fixedTimeForEvent.endTime && hidenEvents[k].endTime > fixedTimeForEvent.endTime && i === layout.x) {
                                    let newEvent1 = {
                                        ...hidenEvents[k],
                                        _id: uuidv4(),
                                        startTime: fixedTimeForEvent.endTime,
                                        endTime: hidenEvents[k].endTime
                                    }

                                    // hiden events will show again if we move event from overlaped free event
                                    let hidenEvent = {
                                        ...hidenEvents[k],
                                        _id: fixedTimeForEvent._id,
                                        startTime: hidenEvents[k].startTime,
                                        endTime: fixedTimeForEvent.endTime
                                    }

                                    hidenEventsForNextCheck.push(hidenEvent)

                                    cols[this.state.cols[i]._id].events.push(newEvent1);
                                    hidenEvents[k].used = true

                                } else {
                                    cols[this.state.cols[i]._id].events.push({ ...hidenEvents[k], _id: uuidv4() });
                                    hidenEvents[k].used = true
                                }
                            }
                        }
                    }

                    if (this.state.cols[i].events[j]._id == item._id) {

                        let event = {
                            ...this.state.cols[i].events[j],
                            startTime: item.startTime,
                            endTime: item.endTime,
                            inClipboard: false
                        };
                        let duration = event.endTime - event.startTime;
                        let startDate = new Date(this.state.cols[i].events[j].startTime * 1000);
                        startDate.setHours(0);
                        startDate.setMinutes(0);
                        startDate.setSeconds(0);
                        startDate.setMilliseconds(0);
                        let startTimestamp = Math.floor(startDate.getTime() / 1000);
                        event.startTime = startTimestamp + layout.y * (5 * 60);
                        event.endTime = event.startTime + duration;


                        let selectedDate = new Date(this.state.selectedDate)
                        let startHours = new Date(event.startTime * 1000).getHours()
                        let startMinutes = new Date(event.startTime * 1000).getMinutes()
                        let startSeconds = new Date(event.startTime * 1000).getSeconds()

                        let newStartTime = selectedDate.setHours(startHours, startMinutes, startSeconds, 0) / 1000;
                        newStartTime = Math.floor(new Date(this.props.getStringDateInverseTsV1(newStartTime, dateFormat)).getTime() / 1000)
                        let endHours = new Date(event.endTime * 1000).getHours()
                        let endMinutes = new Date(event.endTime * 1000).getMinutes()
                        let endSeconds = new Date(event.endTime * 1000).getSeconds()

                        let newEndTime = selectedDate.setHours(endHours, endMinutes, endSeconds, 0) / 1000;
                        newEndTime = Math.floor(new Date(this.props.getStringDateInverseTsV1(newEndTime, dateFormat)).getTime() / 1000)
                        event.endTime = newEndTime;
                        event.startTime = newStartTime;

                        if (this.state.cols[i].events[j].additionalDoctors && this.state.cols[i].events[j].additionalDoctors.length) {

                        } else {
                            returnItem = {
                                ...event
                            };
                            cols[this.state.cols[layout.x]._id].events.push(event);
                            counter += 1;
                        }


                    } else {
                        let event = { ...this.state.cols[i].events[j] }
                        let isPushed = false
                        for (let k = 0; k < overlapEvents.length; k++) {

                            if (overlapEvents[k].startTime < fixedTimeForEvent.startTime && overlapEvents[k].endTime > fixedTimeForEvent.endTime && event._id === overlapEvents[k]._id) {
                                let newEvent1 = {
                                    ...event,
                                    _id: uuidv4(),
                                    startTime: overlapEvents[k].startTime,
                                    endTime: fixedTimeForEvent.startTime
                                }
                                let newEvent2 = {
                                    ...event,
                                    _id: uuidv4(),
                                    startTime: fixedTimeForEvent.endTime,
                                    endTime: overlapEvents[k].endTime,

                                }

                                // hiden events will show again if we move event from overlaped free event
                                let hidenEvent = {
                                    ...overlapEvents[k],
                                    _id: fixedTimeForEvent._id,
                                    startTime: fixedTimeForEvent.startTime,
                                    endTime: fixedTimeForEvent.endTime
                                }

                                hidenEventsForNextCheck.push(hidenEvent)

                                if (event._id && event._id.length <= 24) {
                                    let eventForDelete = {
                                        ...event,
                                        forDelete: true
                                    }
                                    cols[this.state.cols[i]._id].events.push(eventForDelete);
                                }


                                cols[this.state.cols[i]._id].events.push(newEvent1);
                                cols[this.state.cols[i]._id].events.push(newEvent2);

                                isPushed = true

                            } else if (overlapEvents[k].startTime >= fixedTimeForEvent.startTime && overlapEvents[k].endTime <= fixedTimeForEvent.endTime && event._id === overlapEvents[k]._id) {
                                if (event._id && event._id.length <= 24) {
                                    let eventForDelete = {
                                        ...event,
                                        forDelete: true
                                    }

                                    cols[this.state.cols[i]._id].events.push(eventForDelete);
                                }

                                // hiden events will show again if we move event from overlaped free event
                                let hidenEvent = {
                                    ...overlapEvents[k],
                                    _id: fixedTimeForEvent._id,
                                    startTime: overlapEvents[k].startTime,
                                    endTime: overlapEvents[k].endTime,
                                }

                                hidenEventsForNextCheck.push(hidenEvent)

                                isPushed = true
                            } else if (overlapEvents[k].startTime <= fixedTimeForEvent.startTime && overlapEvents[k].endTime <= fixedTimeForEvent.endTime && overlapEvents[k].endTime > fixedTimeForEvent.startTime && event._id === overlapEvents[k]._id) {
                                let newEvent1 = {
                                    ...event,
                                    _id: uuidv4(),
                                    startTime: overlapEvents[k].startTime,
                                    endTime: fixedTimeForEvent.startTime
                                }

                                // hiden events will show again if we move event from overlaped free event
                                let hidenEvent = {
                                    ...overlapEvents[k],
                                    _id: fixedTimeForEvent._id,
                                    startTime: fixedTimeForEvent.startTime,
                                    endTime: overlapEvents[k].endTime
                                }

                                hidenEventsForNextCheck.push(hidenEvent)

                                if (event._id && event._id.length <= 24) {
                                    let eventForDelete = {
                                        ...event,
                                        forDelete: true
                                    }
                                    cols[this.state.cols[i]._id].events.push(eventForDelete);
                                }
                                if (overlapEvents[k].startTime !== fixedTimeForEvent.startTime) {
                                    cols[this.state.cols[i]._id].events.push(newEvent1);

                                }
                                isPushed = true

                            } else if (overlapEvents[k].startTime >= fixedTimeForEvent.startTime && overlapEvents[k].startTime < fixedTimeForEvent.endTime && overlapEvents[k].endTime >= fixedTimeForEvent.endTime && event._id === overlapEvents[k]._id) {
                                let newEvent1 = {
                                    ...event,
                                    _id: uuidv4(),
                                    startTime: fixedTimeForEvent.endTime,
                                    endTime: overlapEvents[k].endTime
                                }

                                // hiden events will show again if we move event from overlaped free event
                                let hidenEvent = {
                                    ...overlapEvents[k],
                                    _id: fixedTimeForEvent._id,
                                    startTime: overlapEvents[k].startTime,
                                    endTime: fixedTimeForEvent.endTime
                                }

                                hidenEventsForNextCheck.push(hidenEvent)

                                if (event._id && event._id.length <= 24) {
                                    let eventForDelete = {
                                        ...event,
                                        forDelete: true
                                    }
                                    cols[this.state.cols[i]._id].events.push(eventForDelete);
                                }
                                if (overlapEvents[k].endTime !== fixedTimeForEvent.endTime) {
                                    cols[this.state.cols[i]._id].events.push(newEvent1);
                                }

                                isPushed = true

                            }
                        }
                        if (!isPushed)
                            cols[this.state.cols[i]._id].events.push(event);
                    }
                }
            }

            if (counter == 0) {

                let event = {
                    ...item,
                    startTime: item.startTime,
                    endTime: item.endTime,
                    inClipboard: false
                };
                let duration = event.endTime - event.startTime;
                let startDate = new Date(event.startTime * 1000);
                startDate.setHours(0);
                startDate.setMinutes(0);
                startDate.setSeconds(0);
                startDate.setMilliseconds(0);
                let startTimestamp = Math.floor(startDate.getTime() / 1000);
                event.startTime = startTimestamp + layout.y * (5 * 60);
                event.endTime = event.startTime + duration;


                let selectedDate = new Date(this.state.selectedDate)
                let startHours = new Date(event.startTime * 1000).getHours()
                let startMinutes = new Date(event.startTime * 1000).getMinutes()
                let startSeconds = new Date(event.startTime * 1000).getSeconds()

                let newStartTime = selectedDate.setHours(startHours, startMinutes, startSeconds, 0) / 1000;
                newStartTime = Math.floor(new Date(this.props.getStringDateInverseTsV1(newStartTime, dateFormat)).getTime() / 1000)

                let endHours = new Date(event.endTime * 1000).getHours()
                let endMinutes = new Date(event.endTime * 1000).getMinutes()
                let endSeconds = new Date(event.endTime * 1000).getSeconds()

                let newEndTime = selectedDate.setHours(endHours, endMinutes, endSeconds, 0) / 1000;
                newEndTime = Math.floor(new Date(this.props.getStringDateInverseTsV1(newEndTime, dateFormat)).getTime() / 1000)

                event.endTime = newEndTime;
                event.startTime = newStartTime;
                returnItem = {
                    ...event
                };
                if (event.additionalDoctors && event.additionalDoctors.length) {
                    // cols[this.state.cols[layout.x]._id].events.push(event);
                    this.checkAvailableAdditionalDoctor({
                        _id: event._id,
                        column: this.state.cols[layout.x]._id,
                        columnChanged: false,
                        startTime: event.startTime,
                        endTime: event.endTime,
                        oldColumn: 0
                    }, {
                        clipboard: [...this.state.clipboard],
                        cols: Object.values(cols),
                        returnItem: { ...returnItem },
                        callback: callback(),
                        event: {
                            column: layout.x,
                            event: event
                        }

                    })


                    clipboardAwait = true;
                } else {
                    cols[this.state.cols[layout.x]._id].events.push(event);
                }

                // cols[this.state.cols[layout.x]._id].events.push(event);

            }

            if (!clipboardAwait) {
                this.setState({
                    cols: Object.values(cols),
                }, () => {
                    this.removeFromClipboard(returnItem)
                    this.mergeFreeEvents()
                })

                if (callback) {
                    callback()
                }
            }
        }


        if (!overlap) {



            for (let i = 0; i < this.state.cols.length; i++) {
                cols[this.state.cols[i]._id] = { ...this.state.cols[i], events: [] };
            }
            let counter = 0;
            for (let i = 0; i < this.state.cols.length; i++) {
                for (let j = 0; j < this.state.cols[i].events.length; j++) {

                    if (hidenEvents && hidenEvents.length && this.state.cols[i].events[j]._id == item._id) {
                        for (let k = 0; k < hidenEvents.length; k++) {
                            if (hidenEvents[k]._id === item._id && !hidenEvents[k].used) {

                                if (hidenEvents[k].startTime >= fixedTimeForEvent.startTime && hidenEvents[k].endTime <= fixedTimeForEvent.endTime && i === layout.x) {
                                    continue
                                } else if (hidenEvents[k].startTime <= fixedTimeForEvent.startTime && hidenEvents[k].endTime < fixedTimeForEvent.endTime && hidenEvents[k].endTime > fixedTimeForEvent.startTime && i === layout.x) {
                                    let newEvent1 = {
                                        ...hidenEvents[k],
                                        _id: uuidv4(),
                                        startTime: hidenEvents[k].startTime,
                                        endTime: fixedTimeForEvent.startTime
                                    }

                                    // hiden events will show again if we move event from overlaped free event
                                    let hidenEvent = {
                                        ...hidenEvents[k],
                                        _id: fixedTimeForEvent._id,
                                        startTime: fixedTimeForEvent.startTime,
                                        endTime: hidenEvents[k].endTime
                                    }

                                    hidenEventsForNextCheck.push(hidenEvent)

                                    if (hidenEvents[k].startTime !== fixedTimeForEvent.startTime) {
                                        cols[this.state.cols[i]._id].events.push(newEvent1);
                                        hidenEvents[k].used = true
                                    }
                                } else if (hidenEvents[k].startTime > fixedTimeForEvent.startTime && hidenEvents[k].startTime < fixedTimeForEvent.endTime && hidenEvents[k].endTime > fixedTimeForEvent.endTime && i === layout.x) {
                                    let newEvent1 = {
                                        ...hidenEvents[k],
                                        _id: uuidv4(),
                                        startTime: fixedTimeForEvent.endTime,
                                        endTime: hidenEvents[k].endTime
                                    }

                                    // hiden events will show again if we move event from overlaped free event
                                    let hidenEvent = {
                                        ...hidenEvents[k],
                                        _id: fixedTimeForEvent._id,
                                        startTime: hidenEvents[k].startTime,
                                        endTime: fixedTimeForEvent.endTime
                                    }

                                    hidenEventsForNextCheck.push(hidenEvent)

                                    cols[this.state.cols[i]._id].events.push(newEvent1);
                                    hidenEvents[k].used = true

                                } else {
                                    cols[this.state.cols[i]._id].events.push({ ...hidenEvents[k], _id: uuidv4() });
                                    hidenEvents[k].used = true
                                }
                            }
                        }
                    }

                    if (this.state.cols[i].events[j]._id == item._id) {


                        let event = {
                            ...this.state.cols[i].events[j],
                            startTime: item.startTime,
                            endTime: item.endTime,
                            inClipboard: false
                        };
                        let duration = event.endTime - event.startTime;
                        let startDate = new Date(this.state.cols[i].events[j].startTime * 1000);
                        startDate.setHours(0);
                        startDate.setMinutes(0);
                        startDate.setSeconds(0);
                        startDate.setMilliseconds(0);
                        let startTimestamp = Math.floor(startDate.getTime() / 1000);
                        event.startTime = startTimestamp + layout.y * (5 * 60);
                        event.endTime = event.startTime + duration;


                        let selectedDate = new Date(this.state.selectedDate)
                        let startHours = new Date(event.startTime * 1000).getHours()
                        let startMinutes = new Date(event.startTime * 1000).getMinutes()
                        let startSeconds = new Date(event.startTime * 1000).getSeconds()

                        let newStartTime = selectedDate.setHours(startHours, startMinutes, startSeconds, 0) / 1000;
                        newStartTime = Math.floor(new Date(this.props.getStringDateInverseTsV1(newStartTime, dateFormat)).getTime() / 1000)

                        let endHours = new Date(event.endTime * 1000).getHours()
                        let endMinutes = new Date(event.endTime * 1000).getMinutes()
                        let endSeconds = new Date(event.endTime * 1000).getSeconds()

                        let newEndTime = selectedDate.setHours(endHours, endMinutes, endSeconds, 0) / 1000;
                        newEndTime = Math.floor(new Date(this.props.getStringDateInverseTsV1(newEndTime, dateFormat)).getTime() / 1000)

                        event.endTime = newEndTime;
                        event.startTime = newStartTime;

                        if (this.state.cols[i].events[j].additionalDoctors && this.state.cols[i].events[j].additionalDoctors.length) {
                            // this.checkAvailableAdditionalDoctor({
                            //     _id: item._id,
                            //     column: this.state.cols[layout.x]._id,
                            //     columnChanged: false,
                            //     startTime: event.startTime,
                            //     endTime: event.endTime,
                            //     oldColumn: 0,

                            // }, [...this.state.clipboard])
                            // clipboardAwait = true;
                            // cols[this.state.cols[i]._id].events.push(this.state.cols[i].events[j]);
                        } else {
                            returnItem = {
                                ...event
                            };
                            cols[this.state.cols[layout.x]._id].events.push(event);
                            counter += 1;
                        }




                    } else {
                        cols[this.state.cols[i]._id].events.push(this.state.cols[i].events[j]);
                    }
                }

            }
            if (counter == 0) {

                let event = {
                    ...item,
                    startTime: item.startTime,
                    endTime: item.endTime,
                    inClipboard: false
                };
                let duration = event.endTime - event.startTime;
                let startDate = new Date(event.startTime * 1000);
                startDate.setHours(0);
                startDate.setMinutes(0);
                startDate.setSeconds(0);
                startDate.setMilliseconds(0);
                let startTimestamp = Math.floor(startDate.getTime() / 1000);
                event.startTime = startTimestamp + layout.y * (5 * 60);
                event.endTime = event.startTime + duration;


                let selectedDate = new Date(this.state.selectedDate)
                let startHours = new Date(event.startTime * 1000).getHours()
                let startMinutes = new Date(event.startTime * 1000).getMinutes()
                let startSeconds = new Date(event.startTime * 1000).getSeconds()

                let newStartTime = selectedDate.setHours(startHours, startMinutes, startSeconds, 0) / 1000;
                newStartTime = Math.floor(new Date(this.props.getStringDateInverseTsV1(newStartTime, dateFormat)).getTime() / 1000)

                let endHours = new Date(event.endTime * 1000).getHours()
                let endMinutes = new Date(event.endTime * 1000).getMinutes()
                let endSeconds = new Date(event.endTime * 1000).getSeconds()

                let newEndTime = selectedDate.setHours(endHours, endMinutes, endSeconds, 0) / 1000;
                newEndTime = Math.floor(new Date(this.props.getStringDateInverseTsV1(newEndTime, dateFormat)).getTime() / 1000)

                event.endTime = newEndTime;
                event.startTime = newStartTime;
                returnItem = {
                    ...event
                };
                if (event.additionalDoctors && event.additionalDoctors.length) {
                    // cols[this.state.cols[layout.x]._id].events.push(event);
                    this.checkAvailableAdditionalDoctor({
                        _id: event._id,
                        column: this.state.cols[layout.x]._id,
                        columnChanged: false,
                        startTime: event.startTime,
                        endTime: event.endTime,
                        oldColumn: 0
                    }, {
                        clipboard: [...this.state.clipboard],
                        cols: Object.values(cols),
                        returnItem: { ...returnItem },
                        callback: callback(),
                        event: {
                            column: layout.x,
                            event: event
                        }

                    })


                    clipboardAwait = true;
                } else {
                    cols[this.state.cols[layout.x]._id].events.push(event);
                }

                // cols[this.state.cols[layout.x]._id].events.push(event);

            }


            if (!clipboardAwait) {
                this.setState({
                    cols: Object.values(cols),
                }, () => {
                    this.removeFromClipboard(returnItem)
                    this.mergeFreeEvents()
                })

                if (callback) {
                    callback()
                }
            }
        }

        this.setState({
            hidenEvents: [...hidenEvents?.filter(item => !item.used), ...hidenEventsForNextCheck]
        }, () => {
            if (this.state.hidenEvents) {
                let mergedHidenEvents = []

                for (let i = 0; i < this.state.hidenEvents.length; i++) {
                    if (mergedHidenEvents.findIndex(item => item._id === this.state.hidenEvents[i]._id) === -1) {
                        mergedHidenEvents.push(this.state.hidenEvents[i])
                    } else {
                        let index = mergedHidenEvents.findIndex(item => item._id === this.state.hidenEvents[i]._id)
                        if (index !== -1) {
                            if (this.state.hidenEvents[i].endTime === mergedHidenEvents[index].startTime) {
                                let obj = {
                                    ...mergedHidenEvents[index],
                                    startTime: this.state.hidenEvents[i].startTime,
                                }
                                mergedHidenEvents.splice(index, 1, obj)
                            } else if (this.state.hidenEvents[i].startTime === mergedHidenEvents[index].endTime) {
                                let obj = {
                                    ...mergedHidenEvents[index],
                                    endTime: this.state.hidenEvents[i].endTime,
                                }
                                mergedHidenEvents.splice(index, 1, obj)
                            }
                        }
                    }

                }

                this.setState({
                    hidenEvents: mergedHidenEvents
                })
            }
        })
    }

    checkAvailableAdditionalDoctor = (item, clipboard) => {
        fetch(API_ENDPOINT + '/referrals/check/doctors', {
            method: 'POST',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
            body: JSON.stringify(item)
        }).then(res => res.json()).then((result) => {
            if (result.error) {
                let check = true;
                let list = result.doctorsList;
                let arrDelete = [];
                if (list) {
                    for (let i = 0; i < list.length; i++) {
                        if (!list[i].available) {
                            arrDelete.push(list[i].doctorId)
                        }
                    }
                }

                for (let i = 0; i < this.state.cols.length; i++) {
                    for (let j = 0; j < this.state.cols[i].events.length; j++) {
                        if (this.state.cols[i].events[j]._id == item._id) {
                            let additionalDoctors = this.state.cols[i].events[j].additionalDoctors;
                            if (additionalDoctors && additionalDoctors.length) {
                                for (let doc = 0; doc < additionalDoctors.length; doc++) {
                                    if (arrDelete.filter(itemForDelete => itemForDelete == additionalDoctors[doc]).length) {
                                        check = false
                                    }
                                }
                            }
                        }
                    }

                }

                // if (!check) {
                if (true) {
                    if (!item.column) {
                        item.column = item.doctor;
                    }
                    this.setState({
                        errorAdditionalDoctors: result.doctorsList,
                        itemChecked: item,
                        oldEvent: result.oldEvent
                    }, () => {
                        if (clipboard) {
                            this.setState({ temporaryClipboard: clipboard })
                        }
                    })

                }

            } else {
                this.setState({
                    errorAdditionalDoctors: result.doctorsList,
                    itemChecked: item,
                    oldEvent: result.oldEvent
                }, () => this.removeUnavailable(true, clipboard))
            }




        })
    }

    selectNewTerm = () => {
        let cols = {};
        let cols1 = {};
        for (let i = 0; i < this.state.cols.length; i++) {
            cols[this.state.cols[i]._id] = { ...this.state.cols[i], events: [] };
        }
        if (this.state.oldEvent) {
            let item = this.state.oldEvent;

            for (let i = 0; i < this.state.cols.length; i++) {
                for (let j = 0; j < this.state.cols[i].events.length; j++) {
                    if (this.state.cols[i].events[j]._id == item._id) {

                        let event = {
                            ...this.state.cols[i].events[j],
                            startTime: item.startTime,
                            endTime: item.endTime,
                        };

                        if (item.oldColumn == 0) {
                            let selectedDate = new Date(this.state.selectedDate)

                            let startHours = new Date(item.startTime * 1000).getHours()
                            let startMinutes = new Date(item.startTime * 1000).getMinutes()
                            let startSeconds = new Date(item.startTime * 1000).getSeconds()

                            let newStartTime = selectedDate.setHours(startHours, startMinutes, startSeconds, 0) / 1000;
                            newStartTime = Math.floor(new Date(this.props.getStringDateInverseTsV1(newStartTime, dateFormat)).getTime() / 1000)

                            let endHours = new Date(item.endTime * 1000).getHours()
                            let endMinutes = new Date(item.endTime * 1000).getMinutes()
                            let endSeconds = new Date(item.endTime * 1000).getSeconds()

                            let newEndTime = selectedDate.setHours(endHours, endMinutes, endSeconds, 0) / 1000;
                            newEndTime = Math.floor(new Date(this.props.getStringDateInverseTsV1(newEndTime, dateFormat)).getTime() / 1000)

                            event.endTime = newEndTime;
                            event.startTime = newStartTime;

                        }
                        cols[item.column].events.push(event);

                    } else if (this.state.cols[i].events[j].eventId == item._id) {
                        let event = {
                            ...this.state.cols[i].events[j],
                            startTime: item.startTime,
                            endTime: item.endTime,
                        };
                        if (item.oldColumn == 0) {
                            let selectedDate = new Date(this.state.selectedDate)

                            let startHours = new Date(item.startTime * 1000).getHours()
                            let startMinutes = new Date(item.startTime * 1000).getMinutes()
                            let startSeconds = new Date(item.startTime * 1000).getSeconds()

                            let newStartTime = selectedDate.setHours(startHours, startMinutes, startSeconds, 0) / 1000;
                            newStartTime = Math.floor(new Date(this.props.getStringDateInverseTsV1(newStartTime, dateFormat)).getTime() / 1000)

                            let endHours = new Date(item.endTime * 1000).getHours()
                            let endMinutes = new Date(item.endTime * 1000).getMinutes()
                            let endSeconds = new Date(item.endTime * 1000).getSeconds()

                            let newEndTime = selectedDate.setHours(endHours, endMinutes, endSeconds, 0) / 1000;
                            newEndTime = Math.floor(new Date(this.props.getStringDateInverseTsV1(newEndTime, dateFormat)).getTime() / 1000)


                            event.endTime = newEndTime;
                            event.startTime = newStartTime;

                        }
                        cols[this.state.cols[i]._id].events.push(event);

                    }
                    else {
                        cols[this.state.cols[i]._id].events.push(this.state.cols[i].events[j]);
                    }
                }

            }



            this.setState({
                cols: Object.values(cols1),
                oldEvent: null,
            }, () => {
                this.setState({ cols: Object.values(cols) })
                this.forceUpdate()
            })
        }

        this.setState({
            errorAdditionalDoctors: null,
            itemChecked: null
        })

    }

    removeUnavailable = (notError, clipboard) => {
        let cols = {};
        for (let i = 0; i < this.state.cols.length; i++) {
            cols[this.state.cols[i]._id] = { ...this.state.cols[i], events: [] };
        }



        let item = this.state.itemChecked;
        let arrDelete = [];
        if (this.state.errorAdditionalDoctors) {
            for (let i = 0; i < this.state.errorAdditionalDoctors.length; i++) {
                if (!this.state.errorAdditionalDoctors[i].available) {
                    arrDelete.push(this.state.errorAdditionalDoctors[i].doctorId)
                }
            }
        }




        for (let i = 0; i < this.state.cols.length; i++) {
            for (let j = 0; j < this.state.cols[i].events.length; j++) {
                if (this.state.cols[i].events[j]._id == item._id) {

                    let additionalDoctors = this.state.cols[i].events[j].additionalDoctors;
                    let newAdditionalDoctors = [];
                    for (let doc = 0; doc < additionalDoctors.length; doc++) {
                        if (arrDelete.filter(doctor => doctor == additionalDoctors[doc]).length == 0) {
                            newAdditionalDoctors.push(additionalDoctors[doc])
                        }
                    }

                    let event = {
                        ...this.state.cols[i].events[j],
                        startTime: item.startTime,
                        endTime: item.endTime,
                        additionalDoctors: newAdditionalDoctors
                    };

                    if (item.oldColumn == 0) {
                        let selectedDate = new Date(this.state.selectedDate)

                        let startHours = new Date(item.startTime * 1000).getHours()
                        let startMinutes = new Date(item.startTime * 1000).getMinutes()
                        let startSeconds = new Date(item.startTime * 1000).getSeconds()

                        let newStartTime = selectedDate.setHours(startHours, startMinutes, startSeconds, 0) / 1000;
                        newStartTime = Math.floor(new Date(this.props.getStringDateInverseTsV1(newStartTime, dateFormat)).getTime() / 1000)


                        let endHours = new Date(item.endTime * 1000).getHours()
                        let endMinutes = new Date(item.endTime * 1000).getMinutes()
                        let endSeconds = new Date(item.endTime * 1000).getSeconds()


                        let newEndTime = selectedDate.setHours(endHours, endMinutes, endSeconds, 0) / 1000;
                        newEndTime = Math.floor(new Date(this.props.getStringDateInverseTsV1(newEndTime, dateFormat)).getTime() / 1000)


                        event.endTime = newEndTime;
                        event.startTime = newStartTime;

                    }
                    if (item.column)
                        cols[item.column].events.push(event);

                } else if (this.state.cols[i].events[j].eventId == item._id && this.state.cols[i]._id == item.column && notError) {
                    let event = {
                        ...this.state.cols[i].events[j],
                        startTime: item.startTime,
                        endTime: item.endTime,
                        deleted: true
                    };
                    if (item.oldColumn == 0) {
                        let selectedDate = new Date(this.state.selectedDate)

                        let startHours = new Date(item.startTime * 1000).getHours()
                        let startMinutes = new Date(item.startTime * 1000).getMinutes()
                        let startSeconds = new Date(item.startTime * 1000).getSeconds()

                        let newStartTime = selectedDate.setHours(startHours, startMinutes, startSeconds, 0) / 1000;
                        newStartTime = Math.floor(new Date(this.props.getStringDateInverseTsV1(newStartTime, dateFormat)).getTime() / 1000)


                        let endHours = new Date(item.endTime * 1000).getHours()
                        let endMinutes = new Date(item.endTime * 1000).getMinutes()
                        let endSeconds = new Date(item.endTime * 1000).getSeconds()

                        let newEndTime = selectedDate.setHours(endHours, endMinutes, endSeconds, 0) / 1000;
                        newEndTime = Math.floor(new Date(this.props.getStringDateInverseTsV1(newEndTime, dateFormat)).getTime() / 1000)


                        event.endTime = newEndTime;
                        event.startTime = newStartTime;

                    }
                    cols[this.state.cols[i]._id].events.push(event);

                } else if (this.state.cols[i].events[j].eventId == item._id && this.state.cols[i]._id != item.column && notError) {
                    let event = {
                        ...this.state.cols[i].events[j],
                        startTime: item.startTime,
                        endTime: item.endTime,
                        deleted: false
                    };
                    if (item.oldColumn == 0) {
                        let selectedDate = new Date(this.state.selectedDate)

                        let startHours = new Date(item.startTime * 1000).getHours()
                        let startMinutes = new Date(item.startTime * 1000).getMinutes()
                        let startSeconds = new Date(item.startTime * 1000).getSeconds()

                        let newStartTime = selectedDate.setHours(startHours, startMinutes, startSeconds, 0) / 1000;
                        newStartTime = Math.floor(new Date(this.props.getStringDateInverseTsV1(newStartTime, dateFormat)).getTime() / 1000)


                        let endHours = new Date(item.endTime * 1000).getHours()
                        let endMinutes = new Date(item.endTime * 1000).getMinutes()
                        let endSeconds = new Date(item.endTime * 1000).getSeconds()

                        let newEndTime = selectedDate.setHours(endHours, endMinutes, endSeconds, 0) / 1000;
                        newEndTime = Math.floor(new Date(this.props.getStringDateInverseTsV1(newEndTime, dateFormat)).getTime() / 1000)


                        event.endTime = newEndTime;
                        event.startTime = newStartTime;

                    }
                    cols[this.state.cols[i]._id].events.push(event);

                }
                else if (arrDelete.filter(doctor => doctor == this.state.cols[i]._id).length && this.state.cols[i].events[j].eventId == item._id) {
                    let event = {
                        ...this.state.cols[i].events[j],
                        deleted: true,
                        eventId: null
                    };
                    cols[this.state.cols[i]._id].events.push(event);

                } else if (arrDelete.filter(doctor => doctor == this.state.cols[i]._id).length == 0 && this.state.cols[i].events[j].eventId == item._id) {
                    let event = {
                        ...this.state.cols[i].events[j],
                        startTime: item.startTime,
                        endTime: item.endTime,
                    };
                    // if (item.oldColumn == 0) {
                    //     let selectedDate = new Date(this.state.selectedDate)
                    //     let startHours = new Date(item.startTime * 1000).getHours()
                    //     let startMinutes = new Date(item.startTime * 1000).getMinutes()
                    //     let startSeconds = new Date(item.startTime * 1000).getSeconds()

                    //     let newStartTime = selectedDate.setHours(startHours, startMinutes, startSeconds, 0) / 1000;

                    //     let endHours = new Date(item.endTime * 1000).getHours()
                    //     let endMinutes = new Date(item.endTime * 1000).getMinutes()
                    //     let endSeconds = new Date(item.endTime * 1000).getSeconds()

                    //     let newEndTime = selectedDate.setHours(endHours, endMinutes, endSeconds, 0) / 1000;

                    //     event.endTime = newEndTime;
                    //     event.startTime = newStartTime;

                    // }
                    cols[this.state.cols[i]._id].events.push(event);

                }
                else {
                    cols[this.state.cols[i]._id].events.push(this.state.cols[i].events[j]);
                }
            }

        }

        if (clipboard) {
            let newDoctor = this.state.cols[clipboard.event.column]._id;
            let arrAdditionalDoctors = []
            for (let i = 0; i < this.state.cols.length; i++) {

                for (let j = 0; j < this.state.errorAdditionalDoctors.length; j++) {
                    if (this.state.cols[i]._id == this.state.errorAdditionalDoctors[j].doctorId) {

                        for (let clip = 0; clip < clipboard.clipboard.length; clip++) {


                            if (clipboard.clipboard[clip].eventId == this.state.itemChecked._id && clipboard.clipboard[clip].doctor == this.state.cols[i]._id) {

                                let event = {
                                    ...clipboard.clipboard[clip],
                                    startTime: this.state.itemChecked.startTime,
                                    endTime: this.state.itemChecked.endTime
                                }
                                let checkExist = false;
                                if (event && event._id) {
                                    for (let col = 0; col < this.state.cols.length; col++) {
                                        for (let e = 0; e < this.state.cols[col].events.length; e++) {

                                            if (this.state.cols[col].events[e]._id == event._id) {
                                                checkExist = {
                                                    column: col,
                                                    event: e,
                                                };
                                            }
                                        }
                                    }
                                    if (!this.state.errorAdditionalDoctors[j].available) {
                                        event.deleted = true;
                                        event.eventId = null;

                                    } else {
                                        if (clipboard.event.event && clipboard.clipboard[clip].doctor != newDoctor) {
                                            arrAdditionalDoctors.push(this.state.errorAdditionalDoctors[j].doctorId)
                                        }

                                    }
                                    if (clipboard.event.event && clipboard.clipboard[clip].doctor == newDoctor) {
                                        event.deleted = true
                                    }

                                    if (checkExist) {

                                        cols[this.state.cols[checkExist.column]._id].events[checkExist.event] = event;
                                    } else {
                                        cols[this.state.cols[i]._id].events.push(event);
                                    }
                                }



                            }
                        }
                    }
                }
                if (clipboard && clipboard.event && this.state.cols[i]._id == this.state.cols[clipboard.event.column]._id) {
                    let checkExist = false;
                    if (clipboard.event.event) {
                        for (let col = 0; col < this.state.cols.length; col++) {
                            for (let e = 0; e < this.state.cols[col].events.length; e++) {

                                if (this.state.cols[col].events[e]._id == clipboard.event.event._id) {
                                    checkExist = {
                                        column: col,
                                        event: e,
                                    };
                                }
                            }
                        }
                        clipboard.event.event.additionalDoctors = arrAdditionalDoctors;
                        if (checkExist) {
                            cols[this.state.cols[checkExist.column]._id].events[checkExist.event] = clipboard.event.event;
                        } else {
                            cols[this.state.cols[i]._id].events.push(clipboard.event.event);
                        }
                    }
                }


            }
            this.setState({ temporaryClipboard: null })
        }


        this.setState({
            cols: Object.values(cols),
            errorAdditionalDoctors: null,
            itemChecked: null,
            oldEvent: null
        }, () => {
            // this.forceUpdate()
            if (clipboard) {
                this.setState({ cols: clipboard.cols })
                this.removeFromClipboard(clipboard.returnItem)
                if (clipboard.callback) {
                    clipboard.callback()
                }
            }

        })


    }

    mergeFreeEvents = () => {
        if (this.state.cols) {
            let cols = {}
            let checkedIds = []
            for (let i = 0; i < this.state.cols.length; i++) {
                cols[this.state.cols[i]._id] = { ...this.state.cols[i], events: [] };
            }
            for (let i = 0; i < this.state.cols.length; i++) {
                for (let j = 0; j < this.state.cols[i].events.length; j++) {
                    if (this.state.cols[i].events[j].forDelete) {
                        cols[this.state.cols[i]._id].events.push({ ...this.state.cols[i].events[j] })
                        checkedIds.push(this.state.cols[i].events[j]._id)
                        continue
                    }

                    if (this.state.cols[i].events[j].type !== 'free') {
                        cols[this.state.cols[i]._id].events.push(this.state.cols[i].events[j])
                    } else {

                        let freeEvent = { ...this.state.cols[i].events[j] }
                        if (checkedIds.indexOf(freeEvent._id) !== -1) {
                            continue
                        }
                        let arrayForCheck = this.state.cols[i].events.filter(item => item.type === 'free' && item._id !== freeEvent._id)

                        for (let k = 0; k < arrayForCheck.length; k++) {
                            if (checkedIds.indexOf(arrayForCheck[k]._id) !== -1) {
                                continue
                            }
                            let freeEventObj = {
                                allActiveForms: freeEvent.allActiveForms,
                                eventForms: freeEvent.eventForms
                            }
                            let currentEventObj = {
                                allActiveForms: arrayForCheck[k]?.allActiveForms,
                                eventForms: arrayForCheck[k]?.eventForms
                            }
                            if (JSON.stringify(Object.values(freeEventObj)) === JSON.stringify(Object.values(currentEventObj))) {

                                if (freeEvent.startTime === arrayForCheck[k].endTime) {
                                    freeEvent.startTime = arrayForCheck[k].startTime
                                    checkedIds.push(arrayForCheck[k]._id)
                                } else if (freeEvent.endTime === arrayForCheck[k].startTime) {
                                    freeEvent.endTime = arrayForCheck[k].endTime
                                    checkedIds.push(arrayForCheck[k]._id)
                                }
                            }
                        }
                        checkedIds.push(freeEvent._id)
                        cols[this.state.cols[i]._id].events.push({ ...freeEvent })

                    }
                }

            }
            this.setState({
                cols: [],
            }, () => {
                this.setState({ cols: Object.values(cols) }, () => {
                    if (this.state.cols) {
                        let cols = {}
                        let checkedIds = []
                        for (let i = 0; i < this.state.cols.length; i++) {
                            cols[this.state.cols[i]._id] = { ...this.state.cols[i], events: [] };
                        }
                        for (let i = 0; i < this.state.cols.length; i++) {
                            for (let j = 0; j < this.state.cols[i].events.length; j++) {
                                if (this.state.cols[i].events[j].forDelete) {
                                    cols[this.state.cols[i]._id].events.push({ ...this.state.cols[i].events[j] })
                                    checkedIds.push(this.state.cols[i].events[j]._id)
                                    continue
                                }

                                if (this.state.cols[i].events[j].type !== 'free') {
                                    cols[this.state.cols[i]._id].events.push(this.state.cols[i].events[j])
                                } else {

                                    let freeEvent = { ...this.state.cols[i].events[j] }
                                    if (checkedIds.indexOf(freeEvent._id) !== -1) {
                                        continue
                                    }
                                    let arrayForCheck = this.state.cols[i].events.filter(item => item.type === 'free' && item._id !== freeEvent._id)

                                    for (let k = 0; k < arrayForCheck.length; k++) {
                                        if (checkedIds.indexOf(arrayForCheck[k]._id) !== -1) {
                                            continue
                                        }
                                        let freeEventObj = {
                                            allActiveForms: freeEvent.allActiveForms,
                                            eventForms: freeEvent.eventForms
                                        }
                                        let currentEventObj = {
                                            allActiveForms: arrayForCheck[k]?.allActiveForms,
                                            eventForms: arrayForCheck[k]?.eventForms
                                        }
                                        if (JSON.stringify(Object.values(freeEventObj)) === JSON.stringify(Object.values(currentEventObj))) {

                                            if (freeEvent.startTime === arrayForCheck[k].endTime) {
                                                freeEvent.startTime = arrayForCheck[k].startTime
                                                checkedIds.push(arrayForCheck[k]._id)
                                            } else if (freeEvent.endTime === arrayForCheck[k].startTime) {
                                                freeEvent.endTime = arrayForCheck[k].endTime
                                                checkedIds.push(arrayForCheck[k]._id)
                                            }


                                        }
                                    }
                                    checkedIds.push(freeEvent._id)
                                    cols[this.state.cols[i]._id].events.push({ ...freeEvent })

                                }
                            }

                        }
                        this.setState({
                            cols: [],
                        }, () => {
                            this.setState({ cols: Object.values(cols) })
                        })
                    }
                })
            })
        }
    }

    onChange = (item) => {
        let hidenEvents = [...this.state.hidenEvents]

        let hidenEventsForNextCheck = []
        let overlap = false
        let colsArray = this.state.cols
        let eventForChange = {}
        let overlapEvents = []
        item.startTime = Math.floor(new Date(this.props.getStringDateInverseTsV1(item.startTime, dateFormat)).getTime() / 1000)
        item.endTime = Math.floor(new Date(this.props.getStringDateInverseTsV1(item.endTime, dateFormat)).getTime() / 1000)

        for (let i = 0; i < colsArray.length; i++) {

            for (let j = 0; j < colsArray[i].events.length; j++) {
                if (colsArray[i].events[j]._id === item._id) {
                    eventForChange = colsArray[i].events[j]
                }

                if (colsArray[i].events[j]._id !== item._id && colsArray[i]._id === item.column && !colsArray[i].events[j].forDelete) {
                    if ((item.startTime > colsArray[i].events[j].startTime && item.startTime < colsArray[i].events[j].endTime) || (item.endTime > colsArray[i].events[j].startTime && item.endTime < colsArray[i].events[j].endTime)) {
                        overlap = true
                        // if (!colsArray[i].events[j].forDelete)
                        overlapEvents.push(colsArray[i].events[j])
                    } else if (item.startTime < colsArray[i].events[j].startTime && item.startTime < colsArray[i].events[j].endTime && item.endTime > colsArray[i].events[j].startTime && item.endTime > colsArray[i].events[j].endTime) {
                        overlap = true
                        // if (!colsArray[i].events[j].forDelete)
                        overlapEvents.push(colsArray[i].events[j])
                    } else if (item.startTime == colsArray[i].events[j].startTime) {
                        overlap = true
                        // if (!colsArray[i].events[j].forDelete)
                        overlapEvents.push(colsArray[i].events[j])
                    } else if (item.endTime == colsArray[i].events[j].endTime) {
                        overlap = true
                        // if (!colsArray[i].events[j].forDelete)
                        overlapEvents.push(colsArray[i].events[j])
                    }
                }
            }
        }

        if (overlap && ((overlapEvents && overlapEvents.length && overlapEvents.filter(item => item.type === 'referral' || item.type === 'revisit' || item.type === 'another-clinic' || item.type === 'event' || item.type === 'take-care' || item.type === 'semble').length) || (eventForChange?.type === 'free' || eventForChange?.type === 'event'))) {
            this.setState({
                cols: [],
            }, () => {
                this.setState({ error: 'You cannot transfer an appointment because of overlap.'.translate(this.props.lang) }, () => {

                    setTimeout(() => {
                        this.setState({ cols: [...colsArray] })
                    }, 0)
                })

            })
        } else if (overlap) {

            let cols = {};
            for (let i = 0; i < this.state.cols.length; i++) {
                cols[this.state.cols[i]._id] = { ...this.state.cols[i], events: [] };
            }
            for (let i = 0; i < this.state.cols.length; i++) {
                for (let j = 0; j < this.state.cols[i].events.length; j++) {
                    if (hidenEvents && hidenEvents.length && this.state.cols[i].events[j]._id == item._id) {
                        for (let k = 0; k < hidenEvents.length; k++) {

                            if (hidenEvents[k]._id === item._id && !hidenEvents[k].used) {

                                if (hidenEvents[k].startTime >= item.startTime && hidenEvents[k].endTime <= item.endTime && this.state.cols[i]._id === item.column) {
                                    continue
                                } else if (hidenEvents[k].startTime <= item.startTime && hidenEvents[k].endTime < item.endTime && hidenEvents[k].endTime > item.startTime && this.state.cols[i]._id === item.column) {
                                    let newEvent1 = {
                                        ...hidenEvents[k],
                                        _id: uuidv4(),
                                        startTime: hidenEvents[k].startTime,
                                        endTime: item.startTime
                                    }

                                    // hiden events will show again if we move event from overlaped free event
                                    let hidenEvent = {
                                        ...hidenEvents[k],
                                        _id: item._id,
                                        startTime: item.startTime,
                                        endTime: hidenEvents[k].endTime
                                    }

                                    hidenEventsForNextCheck.push(hidenEvent)

                                    if (hidenEvents[k].startTime !== item.startTime) {
                                        cols[this.state.cols[i]._id].events.push(newEvent1);
                                        hidenEvents[k].used = true
                                    }
                                } else if (hidenEvents[k].startTime > item.startTime && hidenEvents[k].startTime < item.endTime && hidenEvents[k].endTime > item.endTime && this.state.cols[i]._id === item.column) {
                                    let newEvent1 = {
                                        ...hidenEvents[k],
                                        _id: uuidv4(),
                                        startTime: item.endTime,
                                        endTime: hidenEvents[k].endTime
                                    }

                                    // hiden events will show again if we move event from overlaped free event
                                    let hidenEvent = {
                                        ...hidenEvents[k],
                                        _id: item._id,
                                        startTime: hidenEvents[k].startTime,
                                        endTime: item.endTime
                                    }

                                    hidenEventsForNextCheck.push(hidenEvent)

                                    cols[this.state.cols[i]._id].events.push(newEvent1);
                                    hidenEvents[k].used = true
                                } else {
                                    cols[this.state.cols[i]._id].events.push({ ...hidenEvents[k], _id: uuidv4() });
                                    hidenEvents[k].used = true
                                }
                            }
                        }
                    }

                    if (this.state.cols[i].events[j]._id == item._id) {
                        if (this.state.cols[i].events[j].patientId && this.state.cols[i].events[j].patientId == item.column) {
                            this.setState({ error: 'You cannot transfer an appointment to chosen doctor.'.translate(this.props.lang) })

                            if (this.state.cols && this.state.cols[item.oldColumn] && this.state.cols[item.oldColumn]._id) {
                                let index = this.state.cols[item.oldColumn]._id;

                                if (index && cols[index] && cols[index].events) {
                                    cols[index].events.push(this.state.cols[i].events[j])
                                }
                            }

                        } else {
                            if (this.state.cols[i].events[j].additionalDoctors && !this.state.cols[i].events[j].eventId) {
                                this.checkAvailableAdditionalDoctor(item)
                                cols[this.state.cols[i]._id].events.push(this.state.cols[i].events[j]);
                            }
                            else {
                                let event = {
                                    ...this.state.cols[i].events[j],
                                    startTime: item.startTime,
                                    endTime: item.endTime
                                    // startTime: Math.floor(new Date(this.props.getStringDateInverseTsV1(item.startTime, dateFormat)).getTime() / 1000),
                                    // endTime: Math.floor(new Date(this.props.getStringDateInverseTsV1(item.endTime, dateFormat)).getTime() / 1000)
                                };
                                if (item.oldColumn == 0) {
                                    let selectedDate = new Date(this.state.selectedDate)
                                    let startHours = new Date(item.startTime * 1000).getHours()
                                    let startMinutes = new Date(item.startTime * 1000).getMinutes()
                                    let startSeconds = new Date(item.startTime * 1000).getSeconds()

                                    let newStartTime = selectedDate.setHours(startHours, startMinutes, startSeconds, 0);
                                    newStartTime = Math.floor(new Date(newStartTime).getTime() / 1000)
                                    // newStartTime = Math.floor(new Date(this.props.getStringDateInverseTsV1(newStartTime, dateFormat)).getTime() / 1000)

                                    let endHours = new Date(item.endTime * 1000).getHours()
                                    let endMinutes = new Date(item.endTime * 1000).getMinutes()
                                    let endSeconds = new Date(item.endTime * 1000).getSeconds()

                                    let newEndTime = selectedDate.setHours(endHours, endMinutes, endSeconds, 0);
                                    newEndTime = Math.floor(new Date(newEndTime).getTime() / 1000)
                                    // newEndTime = Math.floor(new Date(this.props.getStringDateInverseTsV1(newEndTime, dateFormat)).getTime() / 1000)

                                    event.endTime = newEndTime;
                                    event.startTime = newStartTime;

                                }
                                cols[item.column].events.push(event);

                            }
                        }


                    } else {
                        let event = {
                            ...this.state.cols[i].events[j],
                            // startTime: Math.floor(new Date(this.props.getStringDateInverseTsV1(this.state.cols[i].events[j].startTime, dateFormat)).getTime() / 1000),
                            // endTime: Math.floor(new Date(this.props.getStringDateInverseTsV1(this.state.cols[i].events[j].endTime, dateFormat)).getTime() / 1000)
                        }
                        let isPushed = false
                        for (let k = 0; k < overlapEvents.length; k++) {
                            if (overlapEvents[k].startTime < item.startTime && overlapEvents[k].endTime > item.endTime && event._id === overlapEvents[k]._id) {
                                let newEvent1 = {
                                    ...event,
                                    _id: uuidv4(),
                                    startTime: overlapEvents[k].startTime,
                                    endTime: item.startTime
                                }
                                let newEvent2 = {
                                    ...event,
                                    _id: uuidv4(),
                                    startTime: item.endTime,
                                    endTime: overlapEvents[k].endTime,

                                }

                                // hiden events will show again if we move event from overlaped free event
                                let hidenEvent = {
                                    ...overlapEvents[k],
                                    _id: item._id,
                                    startTime: item.startTime,
                                    endTime: item.endTime
                                }

                                hidenEventsForNextCheck.push(hidenEvent)

                                if (event._id && event._id.length <= 24) {
                                    let eventForDelete = {
                                        ...event,
                                        forDelete: true
                                    }
                                    cols[this.state.cols[i]._id].events.push(eventForDelete);
                                }


                                cols[this.state.cols[i]._id].events.push(newEvent1);
                                cols[this.state.cols[i]._id].events.push(newEvent2);

                                isPushed = true

                            } else if (overlapEvents[k].startTime >= item.startTime && overlapEvents[k].endTime <= item.endTime && event._id === overlapEvents[k]._id) {
                                if (event._id && event._id.length <= 24) {
                                    let eventForDelete = {
                                        ...event,
                                        forDelete: true
                                    }

                                    cols[this.state.cols[i]._id].events.push(eventForDelete);
                                }

                                // hiden events will show again if we move event from overlaped free event
                                let hidenEvent = {
                                    ...overlapEvents[k],
                                    _id: item._id,
                                    startTime: overlapEvents[k].startTime,
                                    endTime: overlapEvents[k].endTime,
                                }

                                hidenEventsForNextCheck.push(hidenEvent)

                                isPushed = true
                            } else if (overlapEvents[k].startTime <= item.startTime && overlapEvents[k].endTime <= item.endTime && overlapEvents[k].endTime > item.startTime && event._id === overlapEvents[k]._id) {
                                let newEvent1 = {
                                    ...event,
                                    _id: uuidv4(),
                                    startTime: overlapEvents[k].startTime,
                                    endTime: item.startTime
                                }

                                // hiden events will show again if we move event from overlaped free event
                                let hidenEvent = {
                                    ...overlapEvents[k],
                                    _id: item._id,
                                    startTime: item.startTime,
                                    endTime: overlapEvents[k].endTime
                                }

                                hidenEventsForNextCheck.push(hidenEvent)

                                if (event._id && event._id.length <= 24) {
                                    let eventForDelete = {
                                        ...event,
                                        forDelete: true
                                    }
                                    cols[this.state.cols[i]._id].events.push(eventForDelete);
                                }
                                if (overlapEvents[k].startTime !== item.startTime) {
                                    cols[this.state.cols[i]._id].events.push(newEvent1);

                                }
                                isPushed = true

                            } else if (overlapEvents[k].startTime >= item.startTime && overlapEvents[k].startTime < item.endTime && overlapEvents[k].endTime >= item.endTime && event._id === overlapEvents[k]._id) {
                                let newEvent1 = {
                                    ...event,
                                    _id: uuidv4(),
                                    startTime: item.endTime,
                                    endTime: overlapEvents[k].endTime
                                }

                                // hiden events will show again if we move event from overlaped free event
                                let hidenEvent = {
                                    ...overlapEvents[k],
                                    _id: item._id,
                                    startTime: overlapEvents[k].startTime,
                                    endTime: item.endTime
                                }

                                hidenEventsForNextCheck.push(hidenEvent)

                                if (event._id && event._id.length <= 24) {
                                    let eventForDelete = {
                                        ...event,
                                        forDelete: true
                                    }
                                    cols[this.state.cols[i]._id].events.push(eventForDelete);
                                }
                                if (overlapEvents[k].endTime !== item.endTime) {
                                    cols[this.state.cols[i]._id].events.push(newEvent1);
                                }

                                isPushed = true

                            }
                        }
                        if (!isPushed) {
                            cols[this.state.cols[i]._id].events.push(event);
                        }

                    }
                }
            }

            if (JSON.stringify(Object.values(cols)) != JSON.stringify(this.state.cols))

                this.setState({ cols: Object.values(cols) }, () => {
                    this.mergeFreeEvents()

                })
        }

        if (!overlap) {
            let cols = {};
            for (let i = 0; i < this.state.cols.length; i++) {
                cols[this.state.cols[i]._id] = { ...this.state.cols[i], events: [] };
            }
            for (let i = 0; i < this.state.cols.length; i++) {
                for (let j = 0; j < this.state.cols[i].events.length; j++) {

                    if (hidenEvents && hidenEvents.length && this.state.cols[i].events[j]._id == item._id) {
                        for (let k = 0; k < hidenEvents.length; k++) {

                            if (hidenEvents[k]._id === item._id && !hidenEvents[k].used) {

                                if (hidenEvents[k].startTime >= item.startTime && hidenEvents[k].endTime <= item.endTime && this.state.cols[i]._id === item.column) {
                                    continue
                                } else if (hidenEvents[k].startTime <= item.startTime && hidenEvents[k].endTime < item.endTime && hidenEvents[k].endTime > item.startTime && this.state.cols[i]._id === item.column) {
                                    let newEvent1 = {
                                        ...hidenEvents[k],
                                        _id: uuidv4(),
                                        startTime: hidenEvents[k].startTime,
                                        endTime: item.startTime
                                    }

                                    // hiden events will show again if we move event from overlaped free event
                                    let hidenEvent = {
                                        ...hidenEvents[k],
                                        _id: item._id,
                                        startTime: item.startTime,
                                        endTime: hidenEvents[k].endTime
                                    }

                                    hidenEventsForNextCheck.push(hidenEvent)

                                    if (hidenEvents[k].startTime !== item.startTime) {
                                        cols[this.state.cols[i]._id].events.push(newEvent1);
                                        hidenEvents[k].used = true
                                    }
                                } else if (hidenEvents[k].startTime > item.startTime && hidenEvents[k].startTime < item.endTime && hidenEvents[k].endTime > item.endTime && this.state.cols[i]._id === item.column) {
                                    let newEvent1 = {
                                        ...hidenEvents[k],
                                        _id: uuidv4(),
                                        startTime: item.endTime,
                                        endTime: hidenEvents[k].endTime
                                    }

                                    // hiden events will show again if we move event from overlaped free event
                                    let hidenEvent = {
                                        ...hidenEvents[k],
                                        _id: item._id,
                                        startTime: hidenEvents[k].startTime,
                                        endTime: item.endTime
                                    }

                                    hidenEventsForNextCheck.push(hidenEvent)

                                    cols[this.state.cols[i]._id].events.push(newEvent1);
                                    hidenEvents[k].used = true

                                } else {
                                    cols[this.state.cols[i]._id].events.push({ ...hidenEvents[k], _id: uuidv4() });
                                    hidenEvents[k].used = true
                                }
                            }
                        }
                    }

                    if (this.state.cols[i].events[j]._id == item._id) {
                        if (this.state.cols[i].events[j].patientId && this.state.cols[i].events[j].patientId == item.column) {
                            this.setState({ error: 'You cannot transfer an appointment to chosen doctor.'.translate(this.props.lang) })

                            if (this.state.cols && this.state.cols[item.oldColumn] && this.state.cols[item.oldColumn]._id) {
                                let index = this.state.cols[item.oldColumn]._id;

                                if (index && cols[index] && cols[index].events) {
                                    cols[index].events.push(this.state.cols[i].events[j])
                                }
                            }

                        } else {
                            if (this.state.cols[i].events[j].additionalDoctors && !this.state.cols[i].events[j].eventId) {
                                this.checkAvailableAdditionalDoctor(item)
                                cols[this.state.cols[i]._id].events.push(this.state.cols[i].events[j]);
                            }
                            else {
                                let event = {
                                    ...this.state.cols[i].events[j],
                                    startTime: item.startTime,
                                    endTime: item.endTime
                                    // startTime: Math.floor(new Date(this.props.getStringDateInverseTsV1(item.startTime, dateFormat)).getTime() / 1000),
                                    // endTime: Math.floor(new Date(this.props.getStringDateInverseTsV1(item.endTime, dateFormat)).getTime() / 1000)
                                };
                                if (item.oldColumn == 0) {
                                    let selectedDate = new Date(this.state.selectedDate)
                                    let startHours = new Date(item.startTime * 1000).getHours()
                                    let startMinutes = new Date(item.startTime * 1000).getMinutes()
                                    let startSeconds = new Date(item.startTime * 1000).getSeconds()

                                    let newStartTime = selectedDate.setHours(startHours, startMinutes, startSeconds, 0);
                                    newStartTime = Math.floor(new Date(newStartTime).getTime() / 1000)
                                    // newStartTime = Math.floor(new Date(this.props.getStringDateInverseTsV1(newStartTime, dateFormat)).getTime() / 1000)

                                    let endHours = new Date(item.endTime * 1000).getHours()
                                    let endMinutes = new Date(item.endTime * 1000).getMinutes()
                                    let endSeconds = new Date(item.endTime * 1000).getSeconds()

                                    let newEndTime = selectedDate.setHours(endHours, endMinutes, endSeconds, 0);
                                    newEndTime = Math.floor(new Date(newEndTime).getTime() / 1000)
                                    // newEndTime = Math.floor(new Date(this.props.getStringDateInverseTsV1(newEndTime, dateFormat)).getTime() / 1000)

                                    event.endTime = newEndTime;
                                    event.startTime = newStartTime;

                                }
                                cols[item.column].events.push(event);


                            }
                        }


                    } else {
                        cols[this.state.cols[i]._id].events.push(this.state.cols[i].events[j]);
                    }
                }

            }

            if (JSON.stringify(Object.values(cols)) != JSON.stringify(this.state.cols))

                this.setState({ cols: Object.values(cols) }, () => {
                    this.mergeFreeEvents()

                })

        }
        this.setState({
            hidenEvents: [...hidenEvents?.filter(item => !item.used), ...hidenEventsForNextCheck]
        }, () => {
            if (this.state.hidenEvents) {
                let mergedHidenEvents = []

                for (let i = 0; i < this.state.hidenEvents.length; i++) {
                    if (mergedHidenEvents.findIndex(item => item._id === this.state.hidenEvents[i]._id) === -1) {
                        mergedHidenEvents.push(this.state.hidenEvents[i])
                    } else {
                        let index = mergedHidenEvents.findIndex(item => item._id === this.state.hidenEvents[i]._id)
                        if (index !== -1) {
                            if (this.state.hidenEvents[i].endTime === mergedHidenEvents[index].startTime) {
                                let obj = {
                                    ...mergedHidenEvents[index],
                                    startTime: this.state.hidenEvents[i].startTime,
                                }
                                mergedHidenEvents.splice(index, 1, obj)
                            } else if (this.state.hidenEvents[i].startTime === mergedHidenEvents[index].endTime) {
                                let obj = {
                                    ...mergedHidenEvents[index],
                                    endTime: this.state.hidenEvents[i].endTime,
                                }
                                mergedHidenEvents.splice(index, 1, obj)
                            }
                        }
                    }

                }

                this.setState({
                    hidenEvents: mergedHidenEvents
                })
            }
        })
    }




    // get = () => {
    //     this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));

    //     for (let i = 0; i < this.props.loadData.length; i++) {
    //         this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0, sortField: 'timestamp', sortType: -1, clinic: this.props.selectedClinic, groupId: this.props.selectedGroup, doctor: this.state.doctor, filter: this.state.searchVisitReason })).then((data) => {
    //             this.setState({
    //                 ...data,
    //                 loading: null,
    //                 loadingDoctor: false
    //             }, () => {
    //                 this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
    //             })
    //         })
    //     }

    //     for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
    //         this.props.loadDataWithQuery[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0, sortField: 'timestamp', sortType: -1, clinic: this.props.selectedClinic, groupId: this.props.selectedGroup, doctor: this.state.doctor, filter: this.state.searchVisitReason })).then((data) => {
    //             this.setState({
    //                 ...data,
    //                 loading: null,
    //                 loadingDoctor: false
    //             }, () => {
    //                 this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
    //             })
    //         })
    //     }
    //     if (typeof window != 'undefined' && localStorage.doctorList && localStorage.doctorList.length) {
    //         this.setState({ doctorList: localStorage.doctorList.split(',') }, () => {
    //             this.refreshCols();
    //             // if (this.props.hasTakeCare) this.updateTakeCareEvents?.();
    //             // else this.timer = setTimeout(() => {
    //             //     if (this?.props?.hasTakeCare) this?.updateTakeCareEvents?.();
    //             // }, 2000);
    //         })
    //     }


    // }

    componentDidMount() {
        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }

        // this.get();
        this.findDoctors()

        // this.interval = setInterval(() => {
        //     // this.get();
        // }, 60 * 1000);
        document.addEventListener('mousedown', this.handleClickOutside);


        if (this.props.getStringDateTs) {
            this.setState({ selectedDate: this.props.getStringDateTs(Math.floor(new Date().getTime() / 1000), this.props.dateFormat) })
        }


    }
    componentWillUnmount() {
        // if (this.interval) {
        //     clearInterval(this.interval)
        // }
        document.removeEventListener('mousedown', this.handleClickOutside);

        // if (this.timer) clearTimeout(this.timer);

    }
    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.setState({ searchDropdownActive: false })
        }
    }

    componentDidUpdate(prevProps, prevState) {
        // if (prevProps[0].location.search != this.props[0].location.search) {
        //     this.setState({
        //         loading: true
        //     }, () => {
        //         this.get();

        //     })
        // }

        if (prevProps.selectedGroup != this.props.selectedGroup || prevProps.selectedClinic != this.props.selectedClinic) {
            // this.get();
            this.findDoctors()
        }

    }



    refreshCols = () => {

        if (this.state.doctorList && this.state.doctorList.length) {
            this.setState({ loadCols: true }, () => {
                fetch(API_ENDPOINT + '/transfer-events/fetch/doctors/events', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({ doctors: this.state.doctorList, group: this.props.selectedGroup, clinic: this.props.selectedClinic, date: this.state.selectedDate })
                }).then(res => res.json()).then((result) => {
                    if (result && result.length) {
                        let cols = {};
                        let arr = []

                        for (let i = 0; i < result.length; i++) {
                            cols[result[i]._id] = { ...result[i], events: [] };
                        }
                        for (let i = 0; i < result.length; i++) {
                            for (let j = 0; j < result[i].events.length; j++) {
                                if (this.state.clipboard.filter(item => item._id == result[i].events[j]._id).length) {
                                    let event = {
                                        ...result[i].events[j],

                                        inClipboard: true
                                    };
                                    cols[result[i]._id].events.push(event);
                                } else {
                                    cols[result[i]._id].events.push(result[i].events[j]);
                                }
                            }
                            arr.push(cols[result[i]._id])

                        }
                        this.setState({ cols: [...arr], loadCols: false })
                    }
                })
            })
            // for (let i = 0; i < this.state.doctorList.length; i++) {

            //     let obj = {
            //         id: this.state.doctorList[i]._id,
            //         name: this.state.doctorList[i].name,
            //         events: [

            //         ]
            //     }
            //     cols.push(obj);
            // }
        } else this.setState({ cols: [] });

        this.setState({
            hidenEvents: []
        })
    }


    updateSort = (field, type) => {
        if (this.state.useState) {
            let state = this.state.params;
            state.sortField = field;
            state.sortType = type;
            this.setState({
                params: state
            })
        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0, sortField: 'timestamp', sortType: -1 }, [{ name: 'sortField', value: field }, { name: 'sortType', value: type }], false));

        }
    }

    updateParams = (name, value, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };
                obj[name] = value;
                this.setState({ params: obj });
            } else {
                let obj = this.state.params;
                obj[name] = value;
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(generateSearchLink(this.props[0].location.search, { entries: 10, page: 0, sortField: 'timestamp', sortType: -1 }, name, value, restart));
        }
    }
    updateMultipleParams = (fields, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj });

            } else {
                let obj = this.state.params;
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0, sortField: 'timestamp', sortType: -1 }, fields, restart));
        }
    }





    showPlayer = () => {
        this.setState({
            showPlayer: true,
        });
    };

    hidePlayer = () => {
        this.setState({
            showPlayer: false,
        });
    };
    stopAnimation = () => {
        this.player.current.play();
    };

    findDoctors = (searchDropdownActive) => {

        this.setState({ loadDoctors: true }, () => {
            fetch(API_ENDPOINT + '/transfer-events/search/doctors', {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify({ search: this.state.searchDoctors, group: this.props.selectedGroup, clinic: this.props.selectedClinic })
            }).then(res => res.json()).then((result) => {
                if (result && result) {
                    this.setState({ listOfDoctors: result, loadDoctors: false })
                    if (searchDropdownActive) {
                        this.setState({ searchDropdownActive: false })
                    }
                }
            })
        })
    }
    update = () => {
        this.setState({ showPlayer: true }, () => {
            this.player.current.play();
            let cols = JSON.parse(JSON.stringify(this.state.cols))
            if (cols && cols.length) {
                fetch(API_ENDPOINT + '/transfer-events/update/events', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({ cols: cols })
                }).then(res => res.json()).then((result) => {

                    if (result.error) {
                        this.setState({
                            error: result.error,
                            showPlayer: false,
                        })
                    } else {
                        this.setState({
                            showPlayer: false,
                            showPlayer2: true,
                        },
                            () => {
                                setTimeout(() => {
                                    this.setState({ showPlayer2: false });
                                }, 1000);
                            })
                        this.setState({ cols: [] }, () => this.refreshCols())

                    }

                })
            }
        })


    }

    updateTakeCareEvents = async (remove = false) => {
        return;
        if (!this.props.hasTakeCare) return this.setState({ takeCareEvents: {} });

        if (this.state.doctorList && Array.isArray(this.state.doctorList) && this.state.doctorList.length > 0) {

            if (remove) {
                return this.setState(prev => {
                    let takeCareEvents = {};
                    for (let i = 0; i < this.state.doctorList.length; i++) {
                        let doctorId = this.state.doctorList[i];
                        if (doctorId && prev?.takeCareEvents?.[doctorId]) takeCareEvents[doctorId] = prev.takeCareEvents[doctorId];
                    }
                    return {
                        ...prev,
                        takeCareEvents,
                    }
                })
            }


            let filteredDoctorList;
            if (this.state.takeCareEvents && Object.keys(this.state.takeCareEvents)?.length === 0) filteredDoctorList = [...this.state.doctorList];
            else filteredDoctorList = this.state.doctorList?.filter?.(doctorId => !Object.keys(this.state.takeCareEvents).includes(String(doctorId)));

            if (filteredDoctorList && Array.isArray(filteredDoctorList) && filteredDoctorList.length > 0) {
                const response = await fetch(API_ENDPOINT + '/take-care/get-bookings-for-doctor-list', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    },
                    body: JSON.stringify({
                        groupId: this.props?.selectedGroup,
                        clinicId: this.props?.selectedClinic,
                        doctorList: filteredDoctorList
                    })
                });

                if (response && response.ok) {
                    const result = await response.json();
                    if (typeof result === 'object' && !result.error) {
                        this.setState(prev => {
                            let takeCareEvents = {
                                ...(prev.takeCareEvents || {}),
                                ...result
                            };
                            return {
                                ...prev,
                                takeCareEvents
                            }
                        });
                    }
                }
            }
        }

        else return this.setState({ takeCareEvents: {} });
    }

    renderColsWithTakeCare = (takeCareEvents, cols = [], DATE = null) => {
        return;
        try {
            if (cols.length === 0) return cols;

            let date;
            if (!DATE) date = new Date();
            else date = new Date(DATE);

            const day = date.getDate();
            const month = date.getMonth();
            const year = date.getFullYear();

            const startOfDay = Math.floor(new Date(year, month, day, 0, 0, 0)?.getTime() / 1000);
            const endOfDay = Math.floor(new Date(year, month, day, 23, 59, 59)?.getTime() / 1000);

            let newCols = [];

            for (let i = 0; i < cols.length; i++) {
                let doctorId = cols?.[i]?._id;
                if (doctorId && takeCareEvents?.[doctorId] && cols?.[i]?.events) {
                    let events = takeCareEvents[doctorId]?.events?.filter?.(event => event?.startTime >= startOfDay && event?.startTime <= endOfDay && event?.endTime >= startOfDay && event?.endTime <= endOfDay);
                    if (events && events.length) {
                        let eventIds = cols[i].events?.map?.(event => event?._id);
                        let filteredEvents = events?.filter?.(event => !eventIds.includes(event?._id));
                        let arr = [...(cols[i].events || []), ...(filteredEvents || [])]
                        cols[i].events = arr;
                        newCols.push(cols[i])
                    } else newCols.push(cols[i])
                } else newCols.push(cols[i]);
            }

            return newCols;
        } catch (err) {
            console.log('renderColsWithTakeCare err: ', err);
            return cols;
        }
    }

    render() {
        let params = {};
        if (this.state.useState) {
            params = this.state.params;
        } else {
            params = getSearchParams(this.props[0].location.search, { entries: 10, page: 0, sortField: 'timestamp', sortType: -1 })
        }
        return (
            <div className={this.props.autoHideSidebar ? "dashboard dashboard-auto-hide" : "dashboard"}>

                {this.state.error ?

                    <ErrorModal lang={this.props.lang}
                        isOpen={this.state.error}
                        toggle={() => this.setState({ error: null })}

                    >
                        {this.state.error.translate(this.props.lang)}
                    </ErrorModal>

                    :
                    null
                }

                {
                    this.state.errorAdditionalDoctors ?

                        <ErrorModal2 lang={this.props.lang}
                            isOpen={this.state.errorAdditionalDoctors}
                            toggle={() => {

                                this.setState({ errorAdditionalDoctors: null })
                            }}
                            buttons={[
                                {
                                    text: 'Remove unavailable'.translate(this.props.lang),
                                    onClick: () => this.removeUnavailable(false, this.state.temporaryClipboard)
                                },
                                {
                                    text: 'Select new term'.translate(this.props.lang),
                                    onClick: () => this.selectNewTerm()
                                }
                            ]}

                        >
                            {'Some caregivers are not available at the selected term.'.translate(this.props.lang)}
                            {
                                this.state.errorAdditionalDoctors && this.state.errorAdditionalDoctors.map((item, idx) => {
                                    if (!item.available)
                                        return (
                                            <div>
                                                <span style={{ fontWeight: 600 }}>
                                                    {item.doctorName}  {' - '}
                                                </span>

                                                {
                                                    item.available ?
                                                        <span style={{ color: 'green' }}>{'Available'.translate(this.props.lang)}</span>
                                                        :
                                                        <span style={{ color: 'red' }}>{'Unavailable'.translate(this.props.lang)}</span>
                                                }

                                            </div>
                                        )
                                })

                            }
                            {"Do you want to remove unavailable caregivers or select a new term?".translate(this.props.lang)}
                        </ErrorModal2>
                        :
                        null
                }
                <Container fluid>
                    <Row>
                        <Col lg="12">

                            <div className="panel">
                                <h5 className="component-header">{this.props.linksMeta && this.props.linksMeta[this.props[0].location.pathname] ? this.props.linksMeta[this.props[0].location.pathname][this.props.lang] : ''}</h5>
                                <div className="transfer-events-wrap">
                                    <div className="transfer-events-header">
                                        <div className="doctors-search-wrap" ref={node => this.wrapperRef = node}>
                                            <FormGroup>
                                                <Label>{'Doctors'.translate(this.props.lang)}</Label>
                                                <div style={{ position: 'relative' }} onClick={() => this.setState({ searchDropdownActive: true }, () => {
                                                    if (this.state.listOfDoctors && this.state.listOfDoctors.length == 0) {
                                                        this.findDoctors()
                                                    }
                                                })}>
                                                    <Input type='text' value={this.state.searchDoctors} onChange={(e) => {
                                                        if (e.target.value) {
                                                            this.setState({ searchDoctors: e.target.value }, () => this.findDoctors())
                                                        } else {
                                                            this.setState({ searchDoctors: '', listOfDoctors: null }, () => this.findDoctors())
                                                        }
                                                    }} />
                                                    {
                                                        this.state.searchDoctors ?
                                                            <div onClick={() => this.setState({ searchDoctors: '', listOfDoctors: null }, () => this.findDoctors(true))} style={{ position: 'absolute', fontSize: 20, top: 10, right: 10, cursor: 'pointer' }}>&times;</div>
                                                            :
                                                            null
                                                    }
                                                </div>

                                            </FormGroup>
                                            {
                                                this.state.listOfDoctors && this.state.listOfDoctors.length && this.state.searchDropdownActive ?
                                                    <div className="doctors-serach-results" >
                                                        {
                                                            this.state.listOfDoctors.sort((a, b) => {
                                                                const nameA = a.name;
                                                                const nameB = b.name;
                                                                if (nameA < nameB) {
                                                                    return -1
                                                                }
                                                                if (nameA > nameB) {
                                                                    return 1
                                                                }
                                                            }).map((item, idx) => {
                                                                return (
                                                                    <div className='doctor-item'>
                                                                        <p>{item.name}</p>
                                                                        {
                                                                            this.state.doctorList && this.state.doctorList.filter(doctor => doctor == item._id).length == 0 ?
                                                                                <Button color='primary' onClick={() => {
                                                                                    let doctorList = this.state.doctorList;
                                                                                    doctorList.push(item._id);
                                                                                    this.setState({ doctorList }, () => {
                                                                                        // if (typeof window != 'undefined') {
                                                                                        //     localStorage.setItem('doctorList', this.state.doctorList)
                                                                                        // }
                                                                                        this.refreshCols();
                                                                                        // this.updateTakeCareEvents?.();
                                                                                    })
                                                                                }}>{'Add'.translate(this.props.lang)}</Button>
                                                                                :
                                                                                <Button color='danger' onClick={() => {
                                                                                    let doctorList = [...(this.state.doctorList || [])]?.filter(doctorId => doctorId !== item?._id);

                                                                                    this.setState({ doctorList }, () => {
                                                                                        // if (typeof window != 'undefined') {
                                                                                        //     localStorage.setItem('doctorList', this.state.doctorList)
                                                                                        // }
                                                                                        this.refreshCols();
                                                                                        // this.updateTakeCareEvents?.(true);
                                                                                    })
                                                                                }}>{'Remove'.translate(this.props.lang)}</Button>
                                                                        }

                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                    :
                                                    null

                                            }

                                        </div>
                                        <div className='date-select'>
                                            <DatePicker
                                                label={''}
                                                value={this.state.selectedDate}
                                                lang={this.props.lang}
                                                dateFormat={this.props.dateFormat}
                                                onChange={(e) => {
                                                    this.setState({
                                                        selectedDate: e,
                                                    }, () => this.refreshCols())


                                                }}
                                            />

                                        </div>

                                    </div>
                                    {
                                        this.state.cols ?
                                            <div>
                                                <DragAndDropList
                                                    date={new Date(this.state.selectedDate && this.state.selectedDate?.replace(/-/g, '/'))}
                                                    dateFormat={this.props.dateFormat}
                                                    getStringDateTs={this.props.getStringDateTs}
                                                    lang={this.props.lang}
                                                    cols={/*(this.props.hasTakeCare && this.state.takeCareEvents && Object.keys(this.state.takeCareEvents)?.length > 0) ? this.renderColsWithTakeCare(this.state.takeCareEvents, this.state.cols, new Date(this.state.selectedDate)) :*/ this.state.cols}
                                                    clipboard={this.state.clipoard}
                                                    moveToClipoboard={(item, callback) => this.moveToClipoboard(item, callback)}
                                                    removeFromClipboard={(item) => this.removeFromClipboard(item)}
                                                    addFromClipboard={(item, layout, callback) => this.addFromClipboard(item, layout, callback)}
                                                    deleteItem={(item) => {
                                                        let doctorList = [...(this.state.doctorList || [])]?.filter(doctorId => doctorId !== item?._id);

                                                        this.setState({ doctorList }, () => {
                                                            // if (typeof window != 'undefined') {
                                                            //     localStorage.setItem('doctorList', this.state.doctorList)
                                                            // }
                                                            this.refreshCols();
                                                            // this.updateTakeCareEvents?.(true);
                                                        })
                                                    }}
                                                    onChange={this.onChange}


                                                    components={{
                                                        'referral': (props) => {
                                                            return (
                                                                <div style={props.startTime < Math.floor(new Date().getTime() / 1000) ? { cursor: 'default' } : {}} className={(props.inClipboard && !props.eventId) || props.reserved ? 'test-event-component another-clinic-event' : !props.eventId && props.scheduledBy == 'patient' ? "test-event-component referral-component-web-booking" : !props.eventId && !props.onlineVisit ? "test-event-component referral-component" : props.onlineVisit && !props.eventId ? "test-event-component referral-component-online" : "test-event-component additional-doctor-component"}>
                                                                    <p>{this.props.getStringDateTs(props.startTime, 'HH:mm')/*moment.unix(props.startTime).format('HH:mm')*/} - {this.props.getStringDateTs(props.endTime, 'HH:mm')/*moment.unix(props.endTime).format('HH:mm')*/}</p>
                                                                    <p>{props.reserved ? 'Reserved'.translate(this.props.lang) : props.title}</p>
                                                                </div>
                                                            )
                                                        },
                                                        'revisit': (props) => {
                                                            return (
                                                                <div style={props.startTime < Math.floor(new Date().getTime() / 1000) ? { cursor: 'default' } : {}} className={props.inClipboard && !props.eventId ? 'test-event-component another-clinic-event' : !props.eventId && !props.onlineVisit ? "test-event-component control-component" : props.onlineVisit && !props.eventId ? "test-event-component control-component-online" : "test-event-component additional-doctor-component"}>
                                                                    <p>{this.props.getStringDateTs(props.startTime, 'HH:mm')/*moment.unix(props.startTime).format('HH:mm')*/} - {this.props.getStringDateTs(props.endTime, 'HH:mm')/*moment.unix(props.endTime).format('HH:mm')*/}</p>
                                                                    <p>{props.title}</p>
                                                                </div>
                                                            )
                                                        },
                                                        'free': (props) => {
                                                            return (
                                                                <div style={props.startTime < Math.floor(new Date().getTime() / 1000) ? { cursor: 'default' } : {}} className={props.inClipboard ? 'test-event-component another-clinic-event' : "test-event-component free-component"}>
                                                                    <p>{this.props.getStringDateTs(props.startTime, 'HH:mm')/*moment.unix(props.startTime).format('HH:mm')*/} - {this.props.getStringDateTs(props.endTime, 'HH:mm')/*moment.unix(props.endTime).format('HH:mm')*/}</p>
                                                                    <p>{props.title.translate(this.props.lang)}</p>
                                                                </div>
                                                            )
                                                        },
                                                        'event': (props) => {
                                                            return (
                                                                <div style={props.startTime < Math.floor(new Date().getTime() / 1000) ? { cursor: 'default' } : {}} className={props.inClipboard ? 'test-event-component another-clinic-event' : "test-event-component event-component"}>
                                                                    <p>{this.props.getStringDateTs(props.startTime, 'HH:mm')/*moment.unix(props.startTime).format('HH:mm')*/} - {this.props.getStringDateTs(props.endTime, 'HH:mm')/*moment.unix(props.endTime).format('HH:mm')*/}</p>
                                                                    <p>{props.title}</p>
                                                                </div>
                                                            )
                                                        },
                                                        'another-clinic': (props) => {
                                                            return (
                                                                <div className='test-event-component another-clinic-event'>
                                                                    <p>{this.props.getStringDateTs(props.startTime, 'HH:mm')/*moment.unix(props.startTime).format('HH:mm')*/} - {this.props.getStringDateTs(props.endTime, 'HH:mm')/*moment.unix(props.endTime).format('HH:mm')*/}</p>
                                                                    <p>{props.title.translate(this.props.lang)}</p>
                                                                </div>
                                                            )
                                                        },
                                                        'take-care': (props) => <div className='test-event-component take-care-event-bg'>
                                                            <p>{this.props.getStringDateTs(props?.startTime, 'HH:mm') /*moment.unix(props?.startTime)?.format('HH:mm')*/} - {this.props.getStringDateTs(props?.endTime, 'HH:mm') /*moment.unix(props?.endTime)?.format('HH:mm')*/}</p>
                                                            <p>{props?.title?.translate(this.props.lang)}</p>
                                                        </div>,
                                                        'semble': (props) => <div className='test-event-component take-care-event-bg'>
                                                            <p>{this.props.getStringDateTs(props?.startTime, 'HH:mm') /*moment.unix(props?.startTime)?.format('HH:mm')*/} - {this.props.getStringDateTs(props?.endTime, 'HH:mm') /*moment.unix(props?.endTime)?.format('HH:mm')*/}</p>
                                                            <p>{props?.title?.translate(this.props.lang)}</p>
                                                        </div>
                                                    }}
                                                ></DragAndDropList>

                                            </div>
                                            :
                                            null
                                    }


                                    <div style={{ marginTop: 80 }}>

                                        <div className="calendar-legend-wrap calendar-legend-wrap-whole-clinic">
                                            <div className="legend-item">
                                                <div className="legend-color" style={{ backgroundColor: '#dbd233' }}></div>
                                                <h6>{'Event'.translate(this.props.lang)}</h6>
                                            </div>
                                            <div className="legend-item">
                                                <div className="legend-color" style={{ backgroundColor: '#568ac8' }}></div>
                                                <h6>{'Visit by referral'.translate(this.props.lang)}</h6>
                                            </div>
                                            <div className="legend-item">
                                                <div className="legend-color" style={{ backgroundColor: '#3a3f56' }}></div>
                                                <h6>{'All online visits'.translate(this.props.lang)}</h6>
                                            </div>
                                            <div className="legend-item">
                                                <div className="legend-color" style={{ backgroundColor: '#60bebb' }}></div>
                                                <h6>{'Visit without referral'.translate(this.props.lang)}</h6>
                                            </div>
                                            <div className="legend-item">
                                                <div className="legend-color" style={{ backgroundColor: 'green' }}></div>
                                                <h6>{'Available for online booking'.translate(this.props.lang)}</h6>
                                            </div>
                                            <div className="legend-item">
                                                <div className="legend-color" style={{ backgroundColor: '#fdab94' }}></div>
                                                <h6>{'Assisting to other caregiver'.translate(this.props.lang)}</h6>
                                            </div>
                                            <div className="legend-item">
                                                <div className="legend-color" style={{ backgroundColor: '#B256C8' }}></div>
                                                <h6>{'All Web booking events'.translate(this.props.lang)}</h6>
                                            </div>
                                            <div className="legend-item">
                                                <div className="legend-color" style={{ backgroundColor: 'rgba(86, 138, 200, 0.5)' }}></div>
                                                <h6>{'The term is not available'.translate(this.props.lang)}</h6>
                                            </div>

                                        </div>
                                        {/* <Button color='primary' onClick={() => this.update()}>{'Save'.translate(this.props.lang)}</Button> */}
                                        <Button color='primary' onClick={() => this.update()}>
                                            {
                                                this.state.showPlayer ? (
                                                    <Player
                                                        onEvent={(event) => {
                                                            if (event === "load") this.stopAnimation();
                                                        }}
                                                        onStateChange={this.toggleVisibility}
                                                        ref={this.player} // set the ref to your class instance
                                                        autoplay={true}
                                                        loop={true}
                                                        controls={true}
                                                        src="https://assets8.lottiefiles.com/packages/lf20_ZSRKCF.json"
                                                        style={{ height: "30px", width: "30px" }}
                                                    ></Player>

                                                ) : this.state.showPlayer2 ? (
                                                    <div style={{ display: 'flex', alignItems: 'center' }}><Player
                                                        onStateChange={this.toggleVisibility}
                                                        ref={this.player2} // set the ref to your class instance
                                                        autoplay={true}
                                                        loop={true}
                                                        controls={true}
                                                        src="https://assets10.lottiefiles.com/packages/lf20_CxyxLk.json"
                                                        style={{ height: "30px", width: "30px" }}
                                                    ></Player>
                                                        {'Saved'.translate(this.props.lang)}
                                                    </div>
                                                ) : (
                                                    "Save".translate(this.props.lang)
                                                )
                                            }
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </Col>

                    </Row>

                </Container>


            </div >
        );
    }
}

const selector = formValueSelector('modalForm');

export default connect(state => {
    return { group: selector(state, 'group') }

}, {
    changeSubgroup: value => change("modalForm", "subgroup", value),

})(Page(ScheduleReferrals));