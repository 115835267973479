import React, { Component } from "react";
import { Calendar } from "react-date-range";

import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import * as locales from "react-date-range/dist/locale";
import { DOMAINS } from "../../../constants";
import moment from "moment";
import { FormFeedback, FormGroup, Label } from "reactstrap";
function iosDevice() {
  //return true;
  var userAgent = window.navigator.userAgent;
  if (userAgent.match(/iPad/i) || userAgent.match(/iPhone/i)) {
    return true;
  } else {
    return false;
  }
}

class DateOfBirth extends Component {
  constructor(props) {
    super(props);
    this.wrapperRef = React.createRef();
    this.state = {};
  }

  componentDidMount() {
    let date = new Date();
    this.setState({
      date: new Date(),
    });

    this.setState({
      year: date.getFullYear(),
    });

    if (this.props.value) {
      this.setState({
        value: this.props.value,
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value != this.props.value && this.props.enableManualInput) {
      this.setState({
        value: this.props.value,
        error: null,
      });
    }
  }

  componentWillUnmount() {
    if (typeof window != "undefined") {
      window.stopVerifyFun = false;
    }
  }

  isDateValid = (dateStr) => {
    if (isNaN(new Date(dateStr).getTime())) {
      return false;
    }

    if (
      new Date(dateStr).getFullYear() < 1900 ||
      new Date(dateStr).getFullYear() > new Date().getFullYear()
    ) {
      return false;
    }

    if (new Date(dateStr).getMonth() < 0 || new Date(dateStr).getMonth() > 11) {
      return false;
    }

    if (new Date(dateStr).getDate() < 1 || new Date(dateStr).getDate() > 31) {
      return false;
    }

    if (
      new Date(dateStr).getFullYear() == new Date().getFullYear() &&
      new Date(dateStr).getMonth() == new Date().getMonth() &&
      new Date(dateStr).getDate() > new Date().getDate()
    ) {
      return false;
    }

    if (
      new Date(dateStr).getFullYear() == new Date().getFullYear() &&
      new Date(dateStr).getMonth() > new Date().getMonth()
    ) {
      return false;
    }

    return true;
  };

  render() {
    // console.log(this.state);
    let locale = null;

    if (typeof window !== "undefined" && iosDevice() && false) {
      locale = undefined;
    } else if (typeof window !== "undefined" && window._lang) {
      if (window._lang == "en") {
        locale = locales.enGB;
      } else {
        let domen = window.location.origin;
        let country = DOMAINS.find((item) => item.domen == domen)?.country;
        if (country == "sweden") {
          locale = locales.sv;
        } else if (country == "denmark") {
          locale = locales.da;
        } else if (country == "gr") {
          locale = locales.el;
        } else if (country == "es") {
          locale = locales.es;
        } else if (country == "ch") {
          locale = locales.de;
        } else {
          locale = locales.enGB;
        }
      }
    }
    return (
      <WithClickOutside
        onClickOutside={() => {
          this.setState({ showCalendar: null });
        }}
      >
        <FormGroup
          className={
            this.props.error || this.state.error
              ? "input-date-of-birth-wrap date-picker-field required"
              : "input-date-of-birth-wrap date-picker-field"
          }
          ref={this.wrapperRef}
        >
          {this.props.label ? (
            <Label>
              {this.props.label}
              {this.props.required ? <> *</> : null}
            </Label>
          ) : null}
          {this.props.enableManualInput && this.props.onChange ? (
            <input
              value={this.state.value || this.props.value}
              disabled={this.props.disabled}
              onClick={this.props.onClick}
              style={{
                height: this.props.heightInput ? this.props.heightInput : null,
                visibility: "visible",
              }}
              placeholder={
                this.props.dateFormat ? this.props.dateFormat : "YYYY-MM-DD"
              }
              onChange={(val) => {
                let prevValue = this.state.value;
                let value = val.target.value;
                if (value === "") {
                  this.props.onChange(null);
                }
                if (value.length != 10 && !this.state.showCalendar) {
                  this.setState({
                    showCalendar: true,
                  });
                }
                if (value.length > 10) {
                  return;
                }
                if (
                  !isNaN(value[0]) &&
                  !isNaN(value[1]) &&
                  !isNaN(value[2]) &&
                  !isNaN(value[3]) &&
                  value[4] != "-" &&
                  prevValue[4] != "-"
                ) {
                  value = value.slice(0, 4) + "-" + value.slice(4);
                }

                if (
                  !isNaN(value[0]) &&
                  !isNaN(value[1]) &&
                  !isNaN(value[2]) &&
                  !isNaN(value[3]) &&
                  !isNaN(value[5]) &&
                  Number(value[5]) > 1 &&
                  prevValue[5] != "0"
                ) {
                  value = value.slice(0, 5) + "0" + value.slice(5);
                }

                if (
                  !isNaN(value[5]) &&
                  !isNaN(value[6]) &&
                  value[7] != "-" &&
                  prevValue[7] != "-"
                ) {
                  value = value.slice(0, 7) + "-" + value.slice(7);
                }

                if (
                  !isNaN(value[5]) &&
                  !isNaN(value[6]) &&
                  !isNaN(value[8]) &&
                  Number(value[8]) > 3 &&
                  prevValue[8] != "0"
                ) {
                  value = value.slice(0, 8) + "0" + value.slice(8);
                }
                this.setState(
                  {
                    value: value,
                  },
                  () => {
                    if (this.state.value.length == 10) {
                      if (this.isDateValid(this.state.value)) {
                        let date = moment
                          .unix(
                            Math.floor(
                              new Date(this.state.value).getTime() / 1000
                            )
                          )
                          .format(
                            this.props.dateFormat
                              ? this.props.dateFormat
                              : "YYYY-MM-DD"
                          );

                        this.props.onChange(date);
                        this.setState({
                          showCalendar: null,
                          value: false,
                        });
                      } else {
                        this.setState({
                          error: "Invalid date",
                          showCalendar: null,
                        });
                      }
                    }
                  }
                );
              }}
              type="text"
              onFocus={() => {
                if (!this.state.showCalendar) {
                  this.setState(
                    {
                      showCalendar: true,
                    },
                    () => {
                      if (typeof window != "undefined") {
                        window.stopVerifyFun = true;
                      }
                    }
                  );
                }
              }}
            />
          ) : (
            <input
              value={this.props.value}
              disabled={this.props.disabled}
              onClick={this.props.onClick}
              style={{
                height: this.props.heightInput ? this.props.heightInput : null,
                visibility: "visible",
              }}
              type="text"
              onFocus={() => {
                if (!this.state.showCalendar) {
                  this.setState(
                    {
                      showCalendar: true,
                    },
                    () => {
                      if (typeof window != "undefined") {
                        window.stopVerifyFun = true;
                      }
                    }
                  );
                }
              }}
            />
          )}

          {this.state.showCalendar || this.props.openCalendar ? (
            <Calendar
              date={
                this.props.value
                  ? new Date(this.props.value.replace(/-/g, "/"))
                  : this.state.date
              }
              locale={locale}
              maxDate={new Date()}
              onChange={(date) => {
                this.setState({
                  showCalendar: null,
                  value: false,
                });
                let value = moment
                  .unix(Math.floor(new Date(date).getTime() / 1000))
                  .format(
                    this.props.dateFormat ? this.props.dateFormat : "YYYY-MM-DD"
                  );
                this.props.onChange(value);
                if (this.props.openCalendar) this.props.closeCalendar();
              }}
            />
          ) : null}

          {this.props.error ? (
            <FormFeedback>
              {this.props.error ? this.props.error : this.props.errorMessage}
            </FormFeedback>
          ) : null}
          {this.state.error ? (
            <FormFeedback>{this.state.error}</FormFeedback>
          ) : null}
        </FormGroup>
      </WithClickOutside>
    );
  }
}

export default DateOfBirth;

export const WithClickOutside = (props) => {
  const ref = React.useRef(null);

  React.useEffect(() => {
    function handleClickOutside(e) {
      if (!ref?.current?.contains?.(e?.target)) props.onClickOutside?.();
    }
    document?.addEventListener?.("mousedown", handleClickOutside);

    return () => {
      document?.removeEventListener?.("mousedown", handleClickOutside);
    };
  }, [ref]);

  return (
    <div
      ref={ref}
      onClick={(e) => {
        if (props?.stopPropagation) e?.stopPropagation?.();
      }}
      role="presentation"
    >
      {props.children}
    </div>
  );
};
