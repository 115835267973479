import React from 'react'
import reactCSS from 'reactcss'
import {
    Container,
    Row,
    Col,
    FormGroup, Label,
    Input,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from 'reactstrap';


import moment from 'moment';
import Isvg from 'react-inlinesvg';


/**
* Timepicker component
* @author   Aleksandar Dabic
*/
class Time extends React.Component {
    constructor(props) {
        super(props);
        this.wrapperRef = React.createRef();
        // this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);

        this.state = {
            isOpen: false,
            time: "00:00"
        };

    }


    handleClick = () => {
    };

    handleClose = () => {
    };

    handleChange = () => {
    };
    componentDidMount() {
        let arr1 = [];
        let arr2 = [];

        for (let i = 0; i < 24; i++) {
            let obj = {};
            if (i < 10) {
                obj = {
                    hour: ('0' + i).slice(-2)
                }
            } else {
                obj = {
                    hour: i
                }
            }

            arr1.push(obj);
        }
        for (let i = 0; i < 60; i++) {
            let obj = {};
            if (i < 10) {
                obj = {
                    minute: ('0' + i).slice(-2)
                }
            } else {
                obj = {
                    minute: i
                }
            }
            arr2.push(obj);
        }
        this.setState({ hours: arr1 });
        this.setState({ minutes: arr2 });

        document.addEventListener('mousedown', this.handleClickOutside);
        if (this.props.value) {
            // let time = this.props.value.format(this.props.format);

            // if (this.props.value && this.props.value._d && this.props.getStringDateTs) {
            //     time = this.props.getStringDateTs(Math.floor(new Date(this.props.value?._d).getTime() / 1000), this.props.format);
            // }
            let time = this.props.value


            this.setState({ time: time });
        }
    }


    componentDidUpdate(prevProps, prevState) {
        if (prevProps.value != this.props.value /*&& prevState.time == this.state.time*/) {
            if (this.props.value) {
                // let time = this.props.value.format(this.props.format);

                // if (this.props.value && this.props.value._d && this.props.getStringDateTs) {
                //     time = this.props.getStringDateTs(Math.floor(new Date(this.props.value?._d).getTime() / 1000), this.props.format);
                // }
                let time = this.props.value;
                this.setState({ time: time });
            }

        }

        if (prevProps.minValue != this.props.minValue || prevProps.maxValue != this.props.maxValue) {
            if (this.props.value) {
                // let time = this.props.value.format(this.props.format);

                // if (this.props.value && this.props.value._d && this.props.getStringDateTs) {
                //     time = this.props.getStringDateTs(Math.floor(new Date(this.props.value?._d).getTime() / 1000), this.props.format);
                // }
                let time = this.props.value;
                if (Number(time.split(":")[0]) < Number(this.props.minValue.split(":")[0])) {
                    let t = this.props.minValue;
                    let t1 = t.split(":")[0];
                    let t2 = t.split(":")[1];
                    if (Number(t1) < 10)
                        t1 = '0' + t1;
                    if (Number(t2) < 10)
                        t2 = '0' + t2;
                    t = t1 + ":" + t2;
                    this.setState({ time: t });
                    // this.props.value.set({ hour: t1, minute: t2 });
                    // //this.props.onChange(this.props.value, value.join(':'))

                    let value = this.state.time.split(':');
                    value[0] = t1;
                    value[1] = t2;
                    //this.props.value.set({ hour: t1, minute: t2 });
                    //this.props.onChange(this.props.value, value.join(':'))
                }
                else if (Number(time.split(":")[0]) == Number(this.props.minValue.split(":")[0]) && Number(time.split(":")[1]) < Number(this.props.minValue.split(":")[1])) {
                    let t = this.props.minValue;
                    let t1 = t.split(":")[0];
                    let t2 = t.split(":")[1];
                    if (Number(t1) < 10)
                        t1 = '0' + t1;
                    if (Number(t2) < 10)
                        t2 = '0' + t2;
                    t = t1 + ":" + t2;

                    this.setState({ time: t });
                    let value = this.state.time.split(':');
                    value[0] = t1;
                    value[1] = t2;
                    //this.props.value.set({ hour: t1, minute: t2 });
                    //this.props.onChange(this.props.value, value.join(':'))
                }
            }

        }

    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }
    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
            this.setState({ isOpen: false })
        }
    }


    render() {

        return (
            <div>
                <div className="time-picker" ref={this.wrapperRef} >
                    <Input type="text"
                        disabled={this.props.disabled ? true : false}
                        onFocus={() => {
                            if (this.props.disabled) {
                                this.setState({ isOpen: false })
                            } else {
                                this.setState({ isOpen: true })
                            }
                        }}
                        onChange={(e) => {

                            if (!this.props.disabled) {
                                let x = String(e.target.value);
                                x = x.substring(0, 5);
                                x = String(x);
                                let y = '';
                                for (let i = 0; i < x.length; i++) {
                                    if ((Number(x[i] >= 0 && Number(x[i]) <= 9)) || x[i] == ":")
                                        y += x[i]
                                }
                                x = y;


                                if (x[2] !== ':' && x.length >= 3) {
                                    x = x.replace(/\:/g, '').substring(0, 2) + ':' + x.replace(/\:/g, '').substring(2, 4);
                                }

                                if (Number(`${x[0]}${x[1]}`) > 23) {
                                    let replace_str = x.substring(0, 2);
                                    x = x.replace(replace_str, "23");
                                }

                                if (Number(`${x[3]}${x[4]}`) > 59) {
                                    let replace_str = x.substring(3, 5);
                                    x = x.replace(replace_str, "59");
                                }

                                if (this.props.flag == 0 && Number(this.props.maxValue && Number(`${x[0]}${x[1]}`) > Number(this.props.maxValue.split(":")[0]))) {
                                    let replace_str = x.substring(0, 2);
                                    x = x.replace(replace_str, this.props.maxValue.split(":")[0]);
                                }
                                if (this.props.flag == 0 && Number(this.props.minValue && Number(`${x[0]}${x[1]}`) < Number(this.props.minValue.split(":")[0]))) {
                                    let replace_str = x.substring(0, 2);
                                    x = x.replace(replace_str, this.props.minValue.split(":")[0]);
                                }
                                if (this.props.flag == 1 && Number(this.props.maxValue && Number(`${x[0]}${x[1]}`) < Number(this.props.maxValue.split(":")[0]))) {
                                    let replace_str = x.substring(0, 2);
                                    x = x.replace(replace_str, this.props.maxValue.split(":")[0]);
                                }
                                if (Number(`${x[3]}${x[4]}`) >= Number(this.props.maxValue && this.props.maxValue.split(":")[1]) && Number(this.state.time && this.state.time.split(":")[0]) == Number(this.props.maxValue && this.props.maxValue.split(":")[0]) && this.props.flag == 0) {
                                    let replace_str = x.substring(3, 5);
                                    x = x.replace(replace_str, this.props.maxValue.split(":")[1]);
                                }
                                if (Number(`${x[3]}${x[4]}`) <= Number(this.props.minValue && this.props.minValue.split(":")[1]) && Number(this.state.time && this.state.time.split(":")[0]) == Number(this.props.minValue && this.props.minValue.split(":")[0]) && this.props.flag == 0) {
                                    let replace_str = x.substring(3, 5);
                                    x = x.replace(replace_str, this.props.minValue.split(":")[1]);
                                }
                                if (Number(`${x[3]}${x[4]}`) <= Number(this.props.maxValue && this.props.maxValue.split(":")[1]) && Number(this.state.time && this.state.time.split(":")[0]) == Number(this.props.maxValue && this.props.maxValue.split(":")[0]) && this.props.flag == 1) {
                                    let replace_str = x.substring(3, 5);
                                    x = x.replace(replace_str, this.props.maxValue.split(":")[1]);
                                }


                                if (this.props.minValue?.length === 5 && x?.length === 5) {
                                    let xHours = Number(x.split(':')[0])
                                    let xMinutes = Number(x.split(':')[1])

                                    let xVal = xHours * 60 + xMinutes;

                                    let minHours = Number(this.props.minValue.split(':')[0])
                                    let minMinutes = Number(this.props.minValue.split(':')[1])

                                    let minVal = minHours * 60 + minMinutes;

                                    if (xVal < minVal) {
                                        x = this.props.minValue;
                                    }

                                }
                                if (this.props.maxValue?.length === 5 && x?.length === 5) {
                                    let xHours = Number(x.split(':')[0])
                                    let xMinutes = Number(x.split(':')[1])

                                    let xVal = xHours * 60 + xMinutes;

                                    let maxHours = Number(this.props.maxValue.split(':')[0])
                                    let maxMinutes = Number(this.props.maxValue.split(':')[1])

                                    let maxVal = maxHours * 60 + maxMinutes;

                                    if (xVal > maxVal) {
                                        x = this.props.maxValue;
                                    }

                                }



                                this.setState({ time: x })

                                let value = x.split(':');
                                // this.props.value.set({ hour: value[0], minute: value[1] });



                                this.props.onChange(this.props.value, value.join(':'))



                            }

                        }}
                        style={{ height: this.props.heightInput ? this.props.heightInput : 48, width: this.props.widthInput ? this.props.widthInput : 146 }}
                        value={this.props.disabledClear && this.props.disabled ? '00:00' : this.state.time != 'Invalid date' ? this.state.time : 'Invalid date'.translate(typeof window != 'undefined' ? window.lang : null)}
                    />
                    {
                        this.props.icon ?
                            <div className='icon-time-send' onClick={() => {
                                if (this.props.disabled) {
                                    this.setState({ isOpen: false })
                                } else {
                                    this.setState({ isOpen: true })
                                }
                            }}>
                                <Isvg src={this.props.icon} style={{ width: 32, minWidth: 32 }} />
                            </div>
                            :
                            null
                    }
                    <div className={this.state.isOpen ? "dropdown" : "dropdown-close"} >
                        <div className="dropdown-item" >
                            {
                                this.state.hours && this.state.hours.map((item, idx) => {
                                    if (Number(item.hour) >= Number(this.props.minValue && this.props.minValue.split(":")[0]) && Number(item.hour) <= Number(this.props.maxValue && this.props.maxValue.split(":")[0]) && this.props.flag == 0)
                                        return (
                                            <button className={this.state.time && this.state.time.substring(0, 2) == item.hour ? "active" : ""} onClick={() => {
                                                let value = this.state.time.split(':');
                                                value[0] = item.hour;

                                                this.setState({
                                                    time: value.join(':')
                                                })
                                                if (Number(item.hour) == Number(this.props.maxValue && this.props.maxValue.split(":")[0]) && Number(this.state.time && this.state.time.split(":")[1]) > Number(this.props.maxValue && this.props.maxValue.split(":")[1])) {
                                                    let min = Number(this.props.maxValue.split(":")[1]);

                                                    value[1] = min;
                                                    this.setState({
                                                        time: value.join(':')
                                                    })
                                                    //this.props.value.set({ minute: min })
                                                }
                                                if (Number(item.hour) == Number(this.props.minValue && this.props.minValue.split(":")[0]) && Number(this.state.time && this.state.time.split(":")[1]) < Number(this.props.minValue && this.props.minValue.split(":")[1])) {
                                                    let min = Number(this.props.minValue.split(":")[1]);

                                                    value[1] = min;
                                                    this.setState({
                                                        time: value.join(':')
                                                    })
                                                    //this.props.value.set({ minute: min })
                                                }

                                                //this.props.value.set({ hour: item.hour })

                                                this.props.onChange(this.props.value, value.join(':'));



                                            }}>{item.hour}</button>
                                        )
                                    else if (Number(item.hour) >= Number(this.props.maxValue && this.props.maxValue.split(":")[0]) && this.props.flag == 1)
                                        return (
                                            <button className={this.state.time && this.state.time.substring(0, 2) == item.hour ? "active" : ""} onClick={() => {
                                                let value = this.state.time.split(':');
                                                value[0] = item.hour;

                                                this.setState({
                                                    time: value.join(':')
                                                })


                                                //this.props.value.set({ hour: item.hour })

                                                this.props.onChange(this.props.value, value.join(':'))
                                            }}>{item.hour}</button>
                                        )
                                    else if (this.props.flag == 2)
                                        return (
                                            <button className={this.state.time && this.state.time.substring(0, 2) == item.hour ? "active" : ""} onClick={() => {
                                                let value = this.state.time.split(':');
                                                value[0] = item.hour;
                                                this.setState({
                                                    time: value.join(':')
                                                })

                                                //this.props.value.set({ hour: item.hour })

                                                this.props.onChange(this.props.value, value.join(':'))
                                            }}>{item.hour}</button>
                                        )

                                })
                            }
                        </div>
                        <div className="dropdown-item">
                            {
                                this.state.minutes && this.state.minutes.map((item, idx) => {

                                    // if(this.props.flag == 0){
                                    //     if(Number(this.state.time && this.state.time.split(":")[0]) == Number(this.props.maxValue && this.props.maxValue.split(":")[0]))
                                    // }
                                    if (Number(item.minute) <= Number(this.props.maxValue && this.props.maxValue.split(":")[1]) && Number(this.state.time && this.state.time.split(":")[0]) == Number(this.props.maxValue && this.props.maxValue.split(":")[0]) && Number(this.state.time && this.state.time.split(":")[0]) != Number(this.props.minValue && this.props.minValue.split(":")[0]) && this.props.flag == 0)

                                        return (

                                            <button className={this.state.time && this.state.time.substring(3, 5) == item.minute ? "active" : ""} onClick={() => {
                                                let value = this.state.time.split(':');
                                                value[1] = item.minute;
                                                this.setState({
                                                    time: value.join(':')
                                                })
                                                //this.props.value.set({ minute: item.minute })

                                                this.props.onChange(this.props.value, value.join(':'))
                                                this.setState({ isOpen: false })
                                            }}>{item.minute}</button>
                                        )
                                    else if (Number(item.minute) >= Number(this.props.minValue && this.props.minValue.split(":")[1]) && Number(this.state.time && this.state.time.split(":")[0]) == Number(this.props.minValue && this.props.minValue.split(":")[0]) && Number(this.state.time && this.state.time.split(":")[0]) != Number(this.props.maxValue && this.props.maxValue.split(":")[0]) && this.props.flag == 0)

                                        return (

                                            <button className={this.state.time && this.state.time.substring(3, 5) == item.minute ? "active" : ""} onClick={() => {
                                                let value = this.state.time.split(':');
                                                value[1] = item.minute;
                                                this.setState({
                                                    time: value.join(':')
                                                })
                                                //this.props.value.set({ minute: item.minute })

                                                this.props.onChange(this.props.value, value.join(':'))
                                                this.setState({ isOpen: false })
                                            }}>{item.minute}</button>
                                        )
                                    else if (Number(item.minute) <= Number(this.props.maxValue && this.props.maxValue.split(":")[1]) && Number(item.minute) >= Number(this.props.minValue && this.props.minValue.split(":")[1]) && Number(this.state.time && this.state.time.split(":")[0]) == Number(this.props.minValue && this.props.minValue.split(":")[0]) && Number(this.state.time && this.state.time.split(":")[0]) == Number(this.props.maxValue && this.props.maxValue.split(":")[0]) && this.props.flag == 0)

                                        return (

                                            <button className={this.state.time && this.state.time.substring(3, 5) == item.minute ? "active" : ""} onClick={() => {
                                                let value = this.state.time.split(':');
                                                value[1] = item.minute;
                                                this.setState({
                                                    time: value.join(':')
                                                })
                                                //this.props.value.set({ minute: item.minute })

                                                this.props.onChange(this.props.value, value.join(':'))
                                                this.setState({ isOpen: false })
                                            }}>{item.minute}</button>
                                        )

                                    else if (Number(this.state.time && this.state.time.split(":")[0]) != Number(this.props.maxValue && this.props.maxValue.split(":")[0]) && Number(this.state.time && this.state.time.split(":")[0]) != Number(this.props.minValue && this.props.minValue.split(":")[0]) && this.props.flag == 0)

                                        return (

                                            <button className={this.state.time && this.state.time.substring(3, 5) == item.minute ? "active" : ""} onClick={() => {
                                                let value = this.state.time.split(':');
                                                value[1] = item.minute;
                                                this.setState({
                                                    time: value.join(':')
                                                })
                                                //this.props.value.set({ minute: item.minute })

                                                this.props.onChange(this.props.value, value.join(':'))
                                                this.setState({ isOpen: false })
                                            }}>{item.minute}</button>
                                        )

                                    else if (Number(item.minute) >= Number(this.props.maxValue && this.props.maxValue.split(":")[1]) && Number(this.state.time && this.state.time.split(":")[0]) == Number(this.props.maxValue && this.props.maxValue.split(":")[0]) && this.props.flag == 1)
                                        return (

                                            <button className={this.state.time && this.state.time.substring(3, 5) == item.minute ? "active" : ""} onClick={() => {
                                                let value = this.state.time.split(':');
                                                value[1] = item.minute;
                                                this.setState({
                                                    time: value.join(':')
                                                })
                                                //this.props.value.set({ minute: item.minute })

                                                this.props.onChange(this.props.value, value.join(':'))
                                                this.setState({ isOpen: false })
                                            }}>{item.minute}</button>
                                        )
                                    else if (Number(this.state.time && this.state.time.split(":")[0]) != Number(this.props.maxValue && this.props.maxValue.split(":")[0]) && this.props.flag == 1)
                                        return (

                                            <button className={this.state.time && this.state.time.substring(3, 5) == item.minute ? "active" : ""} onClick={() => {
                                                let value = this.state.time.split(':');
                                                value[1] = item.minute;
                                                this.setState({
                                                    time: value.join(':')
                                                })
                                                //this.props.value.set({ minute: item.minute })

                                                this.props.onChange(this.props.value, value.join(':'))
                                                this.setState({ isOpen: false })
                                            }}>{item.minute}</button>
                                        )
                                    else if (this.props.flag == 2)
                                        return (

                                            <button className={this.state.time && this.state.time.substring(3, 5) == item.minute ? "active" : ""} onClick={() => {
                                                let value = this.state.time.split(':');
                                                value[1] = item.minute;
                                                this.setState({
                                                    time: value.join(':')
                                                })
                                                //this.props.value.set({ minute: item.minute })

                                                this.props.onChange(this.props.value, value.join(':'))
                                                this.setState({ isOpen: false })
                                            }}>{item.minute}</button>
                                        )
                                })
                            }
                        </div>

                    </div>

                </div>
            </div>


        )
    }
}

export default Time