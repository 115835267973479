import React, { Component } from 'react';
import Page from '../containers/page';

import {
    Container,
    Row,
    Col,
    Modal, ModalHeader, ModalBody, ModalFooter, Button, Label, FormGroup, Input,

} from 'reactstrap';

import Isvg from 'react-inlinesvg'
import { Player, Controls } from '@lottiefiles/react-lottie-player';
import { getSearchParams } from '../../helpers/linkHelper';
import { API_ENDPOINT } from '../../constants';

import { v4 as uuidv4 } from 'uuid';


class StatmentPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...props.initialData,
            loading: true
        };

    }

    get = () => {

        this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));

        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0, 'query-group': null })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

        for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
            this.props.loadDataWithQuery[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0, 'query-group': null })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }
        let alias = this.props[0].match.params.alias;

        if (alias) {
            fetch(API_ENDPOINT + '/landing/page', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json'
                },
                body: JSON.stringify({ alias })
            }).then(res => res.json()).then((result) => {
                if (result.error) {
                } else {
                    this.setState({ data: result })
                    if (result.favicon) {
                        let favicon = document.getElementById("favicon");
                        favicon.href = result.favicon;
                    } else if (result.logoChat) {
                        let favicon = document.getElementById("favicon");
                        favicon.href = result.logoChat;

                    } else {
                        favicon.href = '/favicon.ico';
                    }


                }
            });
        }


    }


    componentDidMount() {

        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }


        this.get()

    }


    componentWillUnmount() {

    }

    getCode = () => {
        let statement = this.props[0].match.params.statement;

        this.setState({ errorMessage: null, message: null, code: '', file: null, codeSent: null }, () => {
            fetch(API_ENDPOINT + '/statment/get-code', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json'
                },
                body: JSON.stringify({ statement })
            }).then(res => res.json()).then((result) => {
                this.setState({ message: 'The code was sent to the email.', codeSent: true })
            });
        })

    }

    confirmCode = () => {
        let statement = this.props[0].match.params.statement;
        let code = this.state.code;
        this.setState({ errorMessage: null, message: null, file: null }, () => {
            fetch(API_ENDPOINT + `/statment/check-code/${statement}/${code}`, {
                method: 'GET',
                headers: {
                    'content-type': 'application/json'
                }
            }).then(res => res.json()).then((result) => {
                if (result?.error) {
                    this.setState({ errorMessage: 'The code is invalid or has expired.' })
                } else if (result?.filePath) {
                    this.setState({
                        file: {
                            path: result.filePath,
                            name: result.fileName
                        }
                    })
                }
            });
        })



    }

    render() {
        let getCodeBtnDisabled = this.state.file?.path ?? false;
        let confirmBtnDisabled = this.state.file?.path || this.state.code?.length !== 6 ? true : false;

        console.log(this.state.data)
        return (
            <div className='statment-page-wrap'>
                <div className="logo">
                    <img src={API_ENDPOINT + this.state.data?.logo} className="landing-page-logo" />
                </div>

                <div className='verification-code'>
                    <h6>{'Download document'.translate(this.props.lang)}</h6>
                    <Input
                        type='text'
                        value={this.state.code}
                        placeholder={'Enter pass code here...'.translate(this.props.lang)}
                        disabled={this.state.file?.path}
                        onChange={(e) => {
                            this.setState({ code: e.target.value })
                        }} />
                    {
                        this.state.message && !this.state.file?.path ?
                            <p>{this.state.message.translate(this.props.lang)}</p>
                            :
                            this.state.errorMessage && !this.state.file?.path ?
                                <p style={{ color: 'red' }}>{this.state.errorMessage.translate(this.props.lang)}</p>
                                :
                                this.state.file?.path ?
                                    <Button color='primary' className='download-button' onClick={() => {
                                        let path = this.state.file.path;
                                        let name = this.state.file.name;
                                        let pdfUrl = API_ENDPOINT + path;
                                        var link = document.createElement("a");
                                        link.download = name ? name : 'test';
                                        link.target = "_blank";

                                        // Construct the URI
                                        link.href = pdfUrl;
                                        document.body.appendChild(link);
                                        link.click();
                                    }}>{'Download pdf'.translate(this.props.lang)}</Button>
                                    :
                                    null
                    }

                    <div className='buttons-wrap'>
                        <Button color='primary' className={this.state.codeSent ? 'outline-primary-btn' : ''} disabled={getCodeBtnDisabled} onClick={() => {
                            this.getCode()
                        }}>
                            {'Get code'.translate(this.props.lang)}
                        </Button>

                        <Button color='primary' disabled={confirmBtnDisabled} onClick={() => {
                            this.confirmCode()
                        }}>
                            {'Confirm'.translate(this.props.lang)}
                        </Button>
                    </div>
                </div>

            </div>
        );
    }
}



export default StatmentPage;