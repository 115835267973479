import React, { Component } from 'react';
import Page from '../../containers/page';

import { Link } from 'react-router-dom';

import {
    Container,
    Row,
    Col,
    Button,
    FormGroup,
    Label,
    Input,
    UncontrolledAlert,
    Modal, ModalHeader, ModalBody, ModalFooter, Form
} from 'reactstrap';


import { API_ENDPOINT } from '../../constants';

import File from '../../components/forms/fields/multipleFiles';


import logoSmall from '../../assets/images/chat-helper-logo.png';

import { generateHtml } from './helper/helperFunctions';

function parseJSON(response) {
    return response.json().then(json => {
        return {
            result: json,
            status: response.status
        }
    })
}
function cleanNumber(inputString) {
    // Remove all characters that are not digits, dots, or commas
    const cleanedString = inputString.replace(/[^\d.,]/g, '');

    // Replace commas with dots
    const stringWithDot = cleanedString.replace(/,/g, '.');

    // Ensure only one dot exists in the cleaned string
    const parts = stringWithDot.split('.');
    if (parts.length > 2) {
        return parts.slice(0, 1).join('') + '.' + parts.slice(1).join('');
    }

    return stringWithDot;
}


class TypeFields extends Component {
    constructor(props) {
        super(props);
        this.searchResultsWraps = {};
        this.state = {
            items: []
        };
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);

        let arr = []
        for (let i = 0; i <= 100; i++) {
            let obj = {}
            obj.value = i;
            obj.name = i;

            arr.push(obj)
        }

        this.setState({
            items: arr
        })
    }
    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }
    handleClickOutside = (event) => {
        for (var key in this.searchResultsWraps) {
            if (this.searchResultsWraps[key]) {
                if (this.searchResultsWraps[key] && this.searchResultsWraps[key] && !this.searchResultsWraps[key].contains(event.target)) {
                    let stateObj = {};
                    stateObj[`showIcd10Suggestions-${key}`] = false;
                    this.setState(stateObj)
                }

            }
        }

    }

    answerQuestion = (questionId, answer) => {
        if (this.props.answerQuestion) {
            this.props.answerQuestion(questionId, answer)
        }
    }
    indexOfIndexOf(arr, str) {
        for (let i = 0; i < arr.length; i++) {
            let idx = arr[i].indexOf(str);
            if (idx !== -1) {
                return i;
            }

        }

        return -1;
    }
    setStateFunction = (states, callback) => {
        if (this.props.setStateFunction) {
            this.props.setStateFunction(states, callback)
        }
    }
    getAnswer = (id) => {
        if (this.props.getAnswer) {
            return this.props.getAnswer(id);
        }
        return {};
    }

    render() {
        const { name, question, currentQuestion, questionCssStyle, clinicGroup, questionsQueue } = this.props;

        const bmiIndex = ['#9ABBDA', '#93CCA9', '#FFE02B', '#EAA353', '#D8414A'];

        if (!question) return null;

        let isDoctor = this.props.uData && this.props.uData.userLevel == 20 && typeof window != 'undefined' && localStorage?.getItem?.('profile') != 'patient' ? true : false;
        if (this.props.previewAnsweredForm && isDoctor && (question.type === 16 || question.type === 17)) {
            let totalScore = this.props.totalSubtotal.filter(el => el.id == question.id)[0].totalSum ? this.props.totalSubtotal.filter(el => el.id == question.id)[0].totalSum : this.props.totalSubtotal.filter(el => el.id == question.id)[0].sum ? this.props.totalSubtotal.filter(el => el.id == question.id)[0].sum : 0;
            return (
                <div>
                    <FormGroup tag="fieldset">
                        <Label style={{ ...questionCssStyle, backgroundColor: 'rgb(30, 140, 199, 0.19)', borderRadius: 10, maxWidth: '100%', padding: '10px 15px' }} size="sm" >
                            <h6>
                                {question.name}&nbsp;
                                <br />
                                {` ${this.getAnswer(this.props.answers[question.id])?.response}`}
                                <br />
                                {
                                    this.props.totalSubtotal && question && this.props.totalSubtotal.filter(el => el.id == question.id).length && this.props.totalSubtotal.filter(el => el.id == question.id)[0] ?
                                        question.type === 16 ?
                                            ("Subtotal score:".translate(this.props.lang) + " " + (this.props.totalSubtotal.filter(el => el.id == question.id)[0].sum ? this.props.totalSubtotal.filter(el => el.id == question.id)[0].sum : 0))
                                            :
                                            question.type === 17 ?
                                                ("Total score:".translate(this.props.lang) + " " + totalScore)
                                                :

                                                null
                                        :
                                        null
                                }

                                <br />
                            </h6>

                            <div dangerouslySetInnerHTML={{
                                __html: generateHtml({
                                    answers: this.props.answers,
                                    sections: this.props.sections
                                }, this.props.lang, question.id)
                            }}></div>

                        </Label>
                    </FormGroup>
                </div>
            )
        } else if (this.props.previewAnsweredChatForm && isDoctor && (question.type === 16 || question.type === 17)) {
            let totalScore = this.props.totalSubtotal.filter(el => el.id == question.id)[0].totalSum ? this.props.totalSubtotal.filter(el => el.id == question.id)[0].totalSum : this.props.totalSubtotal.filter(el => el.id == question.id)[0].sum ? this.props.totalSubtotal.filter(el => el.id == question.id)[0].sum : 0;
            return (
                <div>
                    <FormGroup tag="fieldset">
                        <Label style={{ ...questionCssStyle, backgroundColor: 'rgb(30, 140, 199, 0.19)', borderRadius: 10, maxWidth: '100%', padding: '10px 15px' }} size="sm" >
                            <h6>
                                {question.name}&nbsp;
                                <br />
                                {` ${this.getAnswer(this.props.answers[question.id])?.response}`}
                                <br />
                                {
                                    this.props.totalSubtotal && question && this.props.totalSubtotal.filter(el => el.id == question.id).length && this.props.totalSubtotal.filter(el => el.id == question.id)[0] ?
                                        question.type === 16 ?
                                            ("Subtotal score:".translate(this.props.lang) + " " + (this.props.totalSubtotal.filter(el => el.id == question.id)[0].sum ? this.props.totalSubtotal.filter(el => el.id == question.id)[0].sum : 0))
                                            :
                                            question.type === 17 ?
                                                ("Total score:".translate(this.props.lang) + " " + totalScore)
                                                :

                                                null
                                        :
                                        null
                                }

                                <br />
                            </h6>

                            {/* <div dangerouslySetInnerHTML={{
                                __html: generateHtml({
                                    answers: this.props.answers,
                                    sections: this.props.sections
                                }, this.props.lang, question.id)
                            }}></div> */}

                        </Label>
                    </FormGroup>
                </div>
            )
        }


        let bmi = 0;
        if (question.type === 6 && this.props.answers[question.id] && this.props.answers[question.id].length && this.props.answers[question.id][0] && this.props.answers[question.id][1] && isDoctor) {
            bmi = parseFloat(this.props.answers[question.id][0] / ((this.props.answers[question.id][1] / 100) * (this.props.answers[question.id][1] / 100)))
            if (bmi) bmi = bmi.toFixed(1)
        }

        return (
            <div className={"type-fields-wrap"}>
                <div className={question.type == 1 && question.answers && question.answers.length <= 3 ? currentQuestion && currentQuestion.id == question.id ? "message-container current-message-container" : "message-container" : "referal-container2"}>
                    <FormGroup tag="fieldset" className={question.type == 2 ? 'group-short' : ''}>
                        {question.wikiLinks && question.wikiLinks.length ?
                            <Label style={questionCssStyle} size="sm" className={question.type == 1 && question.answers && question.answers.length <= 3 ? 'message-question' : ''}>
                                {
                                    question.type == 1 && question.answers && question.answers.length <= 3 ?
                                        clinicGroup && clinicGroup.logoChat ?
                                            <div className="clinic-message">
                                                <div>
                                                    <img src={clinicGroup.logoChat} />
                                                </div>
                                            </div>
                                            :
                                            <div className="clinic-message">
                                                <div>
                                                    <img src={logoSmall} />
                                                </div>
                                            </div>


                                        :
                                        null
                                }

                                {<div className={question.type == 1 && question.answers && question.answers.length <= 3 ? "question" : null} onClick={(e) => {
                                    if (e.target && e.target.getAttribute('widx') !== null) {
                                        this.setState({
                                            wikiModal: question.wikiLinks[parseInt(e.target.getAttribute('widx'))].wiki
                                        })
                                    }
                                }} dangerouslySetInnerHTML={{ __html: name }}></div>}</Label>

                            :
                            <Label style={questionCssStyle} size="sm" className={/*question.type == 2 && question.answers && question.answers.length <= 3 || question.type == 1 && question.answers && question.answers.length <= 3*/  question.type !== 15 ? 'message-question' : ''}>

                                {
                                    // question.type == 2 && question.answers && question.answers.length <= 3 ||
                                    //     question.type == 1 && question.answers && question.answers.length <= 3 ?
                                    question.type !== 15 ?
                                        clinicGroup && clinicGroup.logoChat ?
                                            <div className="clinic-message">
                                                <div>
                                                    <img src={clinicGroup.logoChat} />
                                                </div>
                                            </div>
                                            :
                                            <div className="clinic-message">
                                                <div>
                                                    <img src={logoSmall} />
                                                </div>
                                            </div>
                                        :
                                        null
                                }
                                {
                                    question.type != 15 ?
                                        <div className={question.type == 2 && question.answers && question.answers.length <= 1 || question.type == 1 && question.answers && question.answers.length <= 1 ? "question" : "question-centered"}>
                                            {question.name}
                                        </div>
                                        :
                                        null
                                }



                            </Label>

                        }
                        {question.type == 1 ?
                            question.answers && question.answers.length > 0 ?
                                question.answers.map((answer) => {
                                    let answerName = answer.name;
                                    if (answer.wikiLinks) {
                                        for (let i = 0; i < answer.wikiLinks.length; i++) {
                                            answerName = answerName.replace(answer.wikiLinks[i].term, `<a href="#" widx="${i}">${answer.wikiLinks[i].term}</a>`)
                                        }
                                    }

                                    return (
                                        <FormGroup check >
                                            <div className={this.props.answers[question.id] == answer.id && this.props.previewAnsweredChatForm ? 'referal-checkbox h-48-mobile checked-answer' : this.props.previewAnsweredChatForm ? 'referal-checkbox h-48-mobile not-checked-answer' : 'referal-checkbox h-48-mobile'} onClick={() => {
                                                if (!this.props.disabled)
                                                    this.answerQuestion(question.id, answer.id)
                                            }}
                                            >
                                                <Label size="sm" check>
                                                    <Input
                                                        size="sm"
                                                        name={question.id}
                                                        type={'radio'}
                                                        checked={this.props.answers[question.id] == answer.id ? true : false}
                                                        onChange={() => this.answerQuestion(question.id, answer.id)}
                                                        disabled={this.props.disabled}
                                                    />
                                                    {' '}{<div onClick={(e) => {
                                                        if (e.target && e.target.getAttribute('widx') !== null) {
                                                            this.setState({
                                                                wikiModal: answer.wikiLinks[parseInt(e.target.getAttribute('widx'))].wiki
                                                            })
                                                        }
                                                    }} dangerouslySetInnerHTML={{ __html: answerName }}></div>}

                                                </Label>


                                            </div>
                                        </FormGroup>
                                    )
                                })
                                :


                                question.answers && question.answers.length <= 3 ?
                                    <div style={{ display: 'flex', justifyContent: this.props.answers[question.id] && questionsQueue && questionsQueue.filter(que => que == question.id).length ? 'flex-end' : 'center', alignItems: 'center' }}>
                                        {
                                            question.answers.map((answer) => {
                                                let answerName = answer.name;
                                                if (answer.wikiLinks) {
                                                    for (let i = 0; i < answer.wikiLinks.length; i++) {
                                                        answerName = answerName.replace(answer.wikiLinks[i].term, `<a href="#" widx="${i}">${answer.wikiLinks[i].term}</a>`)
                                                    }
                                                }
                                                if (this.props.answers[question.id] == answer.id) {
                                                    return (
                                                        <div className="message-answer h-custom-mobile" onClick={(e) => {
                                                            if (e.target && e.target.getAttribute('widx') !== null) {
                                                                this.setState({
                                                                    wikiModal: answer.wikiLinks[parseInt(e.target.getAttribute('widx'))].wiki
                                                                })
                                                            }
                                                        }} dangerouslySetInnerHTML={{ __html: answerName }}></div>

                                                    )
                                                }
                                                else if (this.props.answers[question.id] && questionsQueue && questionsQueue.filter(que => que == question.id).length == 0) {
                                                    return (
                                                        <div className="message-option h-custom-mobile" onClick={(e) => {
                                                            if (!this.props.disabled)
                                                                this.answerQuestion(question.id, answer.id);
                                                        }} dangerouslySetInnerHTML={{ __html: answerName }}></div>
                                                    )
                                                }
                                            })
                                        }

                                    </div>
                                    :
                                    null



                            :
                            null
                        }

                        {question.type == 2 ?
                            <>
                                {
                                    question.answers.map((answer) => {
                                        let answerName = answer.name;
                                        if (answer.wikiLinks) {
                                            for (let i = 0; i < answer.wikiLinks.length; i++) {
                                                answerName = answerName.replace(answer.wikiLinks[i].term, `<a href="#" widx="${i}">${answer.wikiLinks[i].term}</a>`)
                                            }
                                        }


                                        return (
                                            <FormGroup check >
                                                <div className={this.props.answers[question.id] && this.props.answers[question.id].indexOf(answer.id) !== -1 && this.props.previewAnsweredChatForm ? 'referal-checkbox pl15-desktop checked-answer' : this.props.previewAnsweredChatForm ? 'referal-checkbox pl15-desktop not-checked-answer' : 'referal-checkbox pl15-desktop'} onClick={() => {
                                                    if (!this.props.disabled)
                                                        this.answerQuestion(question.id, answer.id)
                                                }}>

                                                    <Label size="sm" className="checkbox-label pl0-mobile" check>
                                                        <Input
                                                            className="referal-default-checkbox"
                                                            size="sm" name={question.id}
                                                            type={'checkbox'}
                                                            checked={this.props.answers[question.id] && this.props.answers[question.id].indexOf(answer.id) !== -1 ? true : false}
                                                            onChange={() => this.answerQuestion(question.id, answer.id)}
                                                            disabled={this.props.disabled}
                                                        />
                                                        <span className="custom-checkbox"></span>
                                                        {' '}{<div onClick={(e) => {
                                                            if (e.target && e.target.getAttribute('widx') !== null) {
                                                                this.setState({
                                                                    wikiModal: answer.wikiLinks[parseInt(e.target.getAttribute('widx'))].wiki
                                                                })
                                                            }
                                                        }} dangerouslySetInnerHTML={{ __html: answerName }}></div>}
                                                    </Label>
                                                </div>

                                            </FormGroup>
                                        )
                                    })

                                }

                                {
                                    question.answerPrefix && question.userInput ?
                                        <FormGroup check>
                                            <div className="referal-checkbox pl15-desktop">
                                                <Label className="checkbox-label pl0-mobile" size="sm" check>
                                                    <Input disabled={this.props.disabled} className="referal-default-checkbox" size="sm" name={question.id} type={'checkbox'} checked={this.props.answers[question.id] && this.indexOfIndexOf(this.props.answers[question.id], '__user-input:') !== -1 ? true : false} onChange={() => {
                                                        let answers = this.props.answers;
                                                        if (!answers[question.id]) {
                                                            answers[question.id] = [];
                                                        }

                                                        let index = this.indexOfIndexOf(answers[question.id], '__user-input:');
                                                        if (index != -1) {
                                                            answers[question.id].splice(index, 1)
                                                        } else {
                                                            answers[question.id].push('__user-input:');
                                                        }
                                                        this.setStateFunction({ answers: answers })
                                                        // this.setState({
                                                        //     answers
                                                        // }, this.forceUpdate)
                                                    }} />
                                                    <span className="custom-checkbox"></span>
                                                    {' '}{'Further'.translate(this.props.lang)}
                                                </Label>
                                            </div>
                                        </FormGroup>

                                        :

                                        null
                                }

                                {
                                    this.props.answers[question.id] && this.indexOfIndexOf(this.props.answers[question.id], '__user-input:') != -1 ?
                                        <>
                                            <Label style={questionCssStyle} size="sm" className={!this.props.answers[question.id][this.indexOfIndexOf(this.props.answers[question.id], '__user-input:')].replace('__user-input:', '') ? 'required-question' : ''}>{question.answerPrefix}</Label>

                                            <Input disabled={this.props.disabled} size="sm" type="text" value={this.props.answers[question.id][this.indexOfIndexOf(this.props.answers[question.id], '__user-input:')].replace('__user-input:', '')} onChange={(e) => {
                                                let index = this.indexOfIndexOf(this.props.answers[question.id], '__user-input:');
                                                let answers = this.props.answers;
                                                answers[question.id][index] = '__user-input:' + e.target.value;
                                                // this.setState({
                                                //     answers
                                                // })
                                                this.setStateFunction({ answers: answers })
                                            }}></Input>

                                        </>
                                        :
                                        null
                                }

                            </>
                            :
                            null

                        }

                        {
                            question.type == 3 ?
                                <>
                                    <Input disabled={this.props.disabled} style={questionCssStyle} size="sm" type="textarea" value={this.props.answers[question.id]} onChange={(e) => {
                                        this.answerQuestion(question.id, e.target.value)
                                    }}></Input>

                                </>
                                :

                                null
                        }

                        {
                            question.type == 4 ?
                                <div>
                                    <div style={questionCssStyle} className="referral-treatment-instructions" dangerouslySetInnerHTML={{ __html: question.text }}></div>
                                </div>
                                :

                                null
                        }
                        {
                            question.type == 5 ?
                                <>
                                    {
                                        (this.props.answers[question.id] || [""]).map((diagnose, didx) => {
                                            return (
                                                <FormGroup>
                                                    <Input
                                                        disabled={this.props.disabled}
                                                        onKeyUp={(e) => {
                                                            if (e.keyCode == 13) {
                                                                this.setState({
                                                                    suggestionIndex: null,
                                                                })

                                                                if (this.state.suggestionIndex != null) {
                                                                    let suggestion = JSON.parse(JSON.stringify(this.state[`icd10Suggestions-${question.id}-${didx}`][this.state.suggestionIndex]));

                                                                    let arr = this.props.answers;
                                                                    if (!arr[question.id]) {
                                                                        arr[question.id] = [];
                                                                    }

                                                                    arr[question.id][didx] = suggestion.code + ' - ' + suggestion.name;


                                                                    this.answerQuestion(question.id, arr[question.id])

                                                                    let stateObj = {};
                                                                    stateObj[`icd10Suggestions-${question.id}-${didx}`] = [];
                                                                    stateObj[`showIcd10Suggestions-${question.id}-${didx}`] = false;
                                                                    this.setState(stateObj);
                                                                    return;
                                                                }

                                                            }
                                                            if (e.keyCode == 38) {
                                                                this.setState({
                                                                    suggestionIndex: this.state.suggestionIndex > 0 ? this.state.suggestionIndex - 1 : 0
                                                                })
                                                            }
                                                            if (e.keyCode == 40) {
                                                                this.setState({
                                                                    suggestionIndex: this.state.suggestionIndex + 1 >= this.state[`icd10Suggestions-${question.id}-${didx}`].length ? 0 : this.state.suggestionIndex == null ? 0 : this.state.suggestionIndex + 1
                                                                })

                                                            }
                                                        }}
                                                        onFocus={() => {
                                                            let stateObj = { suggestionIndex: null };
                                                            stateObj[`showIcd10Suggestions-${question.id}-${didx}`] = true;
                                                            this.setState(stateObj);
                                                        }}
                                                        style={questionCssStyle}
                                                        size="sm"
                                                        type="text"
                                                        value={this.props.answers[question.id] && this.props.answers[question.id][didx] ? this.props.answers[question.id][didx] : ''}
                                                        onChange={(e) => {
                                                            let arr = this.props.answers;
                                                            if (!arr[question.id]) {
                                                                arr[question.id] = [];
                                                            }

                                                            arr[question.id][didx] = e.target.value;
                                                            this.answerQuestion(question.id, arr[question.id])
                                                            fetch(API_ENDPOINT + '/data/icd10/suggest', {
                                                                method: 'POST',
                                                                headers: {
                                                                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                                                                    'content-type': 'application/json'
                                                                },
                                                                body: JSON.stringify({ search: e.target.value })
                                                            }).then(parseJSON).then(({ result, status }) => {
                                                                if (status >= 200 && status < 300) {
                                                                    let stateObj = {};
                                                                    stateObj[`icd10Suggestions-${question.id}-${didx}`] = result;
                                                                    stateObj[`showIcd10Suggestions-${question.id}-${didx}`] = true;
                                                                    this.setState(stateObj);
                                                                }
                                                            })
                                                        }}
                                                    ></Input>
                                                    {this.state[`showIcd10Suggestions-${question.id}-${didx}`] ?
                                                        <div className="search-results" ref={(node) => { this.searchResultsWraps[question.id + '-' + didx] = node; }}>
                                                            <ul >
                                                                {
                                                                    this.state[`icd10Suggestions-${question.id}-${didx}`] && this.state[`icd10Suggestions-${question.id}-${didx}`].map((icd10Item, idx) => {
                                                                        return (
                                                                            <li className={this.state.suggestionIndex === idx ? 'active' : null} onClick={() => {

                                                                                let arr = this.props.answers;
                                                                                if (!arr[question.id]) {
                                                                                    arr[question.id] = [];
                                                                                }

                                                                                arr[question.id][didx] = icd10Item.code + ' - ' + icd10Item.name;


                                                                                this.answerQuestion(question.id, arr[question.id])

                                                                                let stateObj = {};
                                                                                stateObj[`icd10Suggestions-${question.id}-${didx}`] = [];
                                                                                stateObj[`showIcd10Suggestions-${question.id}-${didx}`] = false;
                                                                                this.setState(stateObj);
                                                                            }}
                                                                            >{icd10Item.code} - {icd10Item.name}</li>
                                                                        )
                                                                    })
                                                                }
                                                            </ul>
                                                        </div>
                                                        :
                                                        null
                                                    }

                                                </FormGroup>
                                            )
                                        })
                                    }
                                    {
                                        !this.props.disabled ?
                                            <button className="add-diagnose-button" disabled={this.props.disabled} onClick={() => {
                                                let answers = this.props.answers;
                                                if (!answers[question.id]) {
                                                    answers[question.id] = [""];
                                                }

                                                answers[question.id].push("");
                                                this.setState({
                                                    answers
                                                })

                                            }}>{'Add diagnos'.translate(this.props.lang)}</button>
                                            :
                                            null
                                    }




                                </>
                                :

                                null
                        }

                        {
                            question.type == 6 ?
                                <Row>
                                    <Col lg="4">
                                        <FormGroup>
                                            <Label>{'Weight [kg]'.translate(this.props.lang)}</Label>
                                            <Input disabled={this.props.disabled} style={questionCssStyle} size="sm" type="number" value={this.props.answers[question.id] ? this.props.answers[question.id][0] : ''}
                                                onChange={(e) => {
                                                    let value = e.target.value;
                                                    value = value?.replace(',', '.')
                                                    let val = this.props.answers[question.id] ? this.props.answers[question.id] : ['', ''];
                                                    val[0] = value;
                                                    this.answerQuestion(question.id, val)
                                                }}></Input>

                                        </FormGroup>
                                    </Col>
                                    <Col lg="4" style={{ display: 'flex', alignItems: 'center' }}>
                                        <FormGroup>
                                            <Label>{'Height [cm]'.translate(this.props.lang)}</Label>
                                            <Input disabled={this.props.disabled} style={questionCssStyle} size="sm" type="number" value={this.props.answers[question.id] ? this.props.answers[question.id][1] : ''}
                                                onChange={(e) => {
                                                    let value = e.target.value;
                                                    value = value?.replace(',', '.');
                                                    let val = this.props.answers[question.id] ? this.props.answers[question.id] : ['', ''];
                                                    val[1] = value;
                                                    this.answerQuestion(question.id, val)
                                                }}></Input>

                                        </FormGroup>

                                    </Col>
                                    <Col lg="4" style={{ display: 'flex', alignItems: 'center' }}>
                                        <div style={{
                                            width: 24,
                                            height: 24,
                                            minWidth: 24,
                                            marginRight: 10,
                                            backgroundColor: parseFloat(this.props.answers[question.id] && this.props.answers[question.id].length ?
                                                (this.props.answers[question.id][0] / ((this.props.answers[question.id][1] / 100) * (this.props.answers[question.id][1] / 100))) : 0) < 18.5 ? bmiIndex[0]
                                                :
                                                parseFloat(this.props.answers[question.id] && this.props.answers[question.id].length ?
                                                    (this.props.answers[question.id][0] / ((this.props.answers[question.id][1] / 100) * (this.props.answers[question.id][1] / 100))) : 0) >= 18.5 && parseFloat(this.props.answers[question.id] && this.props.answers[question.id].length ?
                                                        (this.props.answers[question.id][0] / ((this.props.answers[question.id][1] / 100) * (this.props.answers[question.id][1] / 100))) : 0) < 25 ? bmiIndex[1]
                                                    :
                                                    parseFloat(this.props.answers[question.id] && this.props.answers[question.id].length ?
                                                        (this.props.answers[question.id][0] / ((this.props.answers[question.id][1] / 100) * (this.props.answers[question.id][1] / 100))) : 0) >= 25 && parseFloat(this.props.answers[question.id] && this.props.answers[question.id].length ?
                                                            (this.props.answers[question.id][0] / ((this.props.answers[question.id][1] / 100) * (this.props.answers[question.id][1] / 100))) : 0) < 30 ? bmiIndex[2]
                                                        :
                                                        parseFloat(this.props.answers[question.id] && this.props.answers[question.id].length ?
                                                            (this.props.answers[question.id][0] / ((this.props.answers[question.id][1] / 100) * (this.props.answers[question.id][1] / 100))) : 0) >= 30 && parseFloat(this.props.answers[question.id] && this.props.answers[question.id].length ?
                                                                (this.props.answers[question.id][0] / ((this.props.answers[question.id][1] / 100) * (this.props.answers[question.id][1] / 100))) : 0) < 35 ? bmiIndex[3]
                                                            :
                                                            parseFloat(this.props.answers[question.id] && this.props.answers[question.id].length ?
                                                                (this.props.answers[question.id][0] / ((this.props.answers[question.id][1] / 100) * (this.props.answers[question.id][1] / 100))) : 0) >= 35 && parseFloat(this.props.answers[question.id] && this.props.answers[question.id].length ?
                                                                    (this.props.answers[question.id][0] / ((this.props.answers[question.id][1] / 100) * (this.props.answers[question.id][1] / 100))) : 0) < 40 ? bmiIndex[4]
                                                                :
                                                                bmiIndex[5]

                                        }}></div>
                                        {
                                            bmi ?
                                                <div className='bmi-field-value-wrap'>
                                                    {'BMI'.translate(this.props.lang)}: {bmi}
                                                </div>
                                                :
                                                null
                                        }
                                    </Col>


                                </Row>
                                :

                                null
                        }
                        {
                            question.type == 7 ?
                                <Row>
                                    <Col lg="3">
                                        <FormGroup>
                                            <Label>{'Free kort number'.translate(this.props.lang)}</Label>
                                            <Input disabled={this.props.disabled} style={questionCssStyle} size="sm" type="text" value={this.props.answers[question.id] ? this.props.answers[question.id][0] : ''} onChange={(e) => {
                                                let val = this.props.answers[question.id] ? this.props.answers[question.id] : ['', '', '', ''];
                                                val[0] = e.target.value;
                                                this.answerQuestion(question.id, val)
                                            }}></Input>

                                        </FormGroup>
                                    </Col>
                                    <Col lg="3">
                                        <FormGroup>
                                            <Label>{'Valid from'.translate(this.props.lang)}</Label>
                                            <Input disabled={this.props.disabled} style={questionCssStyle} size="sm" type="date" value={this.props.answers[question.id] ? this.props.answers[question.id][1] : ''} onChange={(e) => {
                                                let val = this.props.answers[question.id] ? this.props.answers[question.id] : ['', '', '', ''];
                                                val[1] = e.target.value;
                                                this.answerQuestion(question.id, val)
                                            }}></Input>

                                        </FormGroup>
                                    </Col>
                                    <Col lg="3">
                                        <FormGroup>
                                            <Label>{'Valid to'.translate(this.props.lang)}</Label>
                                            <Input disabled={this.props.disabled} style={questionCssStyle} size="sm" type="date" value={this.props.answers[question.id] ? this.props.answers[question.id][2] : ''} onChange={(e) => {
                                                let val = this.props.answers[question.id] ? this.props.answers[question.id] : ['', '', '', ''];
                                                val[2] = e.target.value;
                                                this.answerQuestion(question.id, val)
                                            }}></Input>

                                        </FormGroup>
                                    </Col>
                                    <Col lg="3">
                                        <FormGroup>
                                            <Label>{'Issued at'.translate(this.props.lang)}</Label>
                                            <Input disabled={this.props.disabled} style={questionCssStyle} size="sm" type="select" value={this.props.answers[question.id] ? this.props.answers[question.id][3] : ''} onChange={(e) => {
                                                let val = this.props.answers[question.id] ? this.props.answers[question.id] : ['', '', '', ''];
                                                val[3] = e.target.value;
                                                this.answerQuestion(question.id, val)
                                            }}>
                                                {
                                                    this.props.regions ? [{ shortName: 'Select region'.translate(this.props.lang) }, ...this.props.regions].map((item, idx) => {
                                                        return (
                                                            <option value={idx == 0 ? null : item.shortName}>{item.shortName}</option>
                                                        )
                                                    })
                                                        :
                                                        null
                                                }
                                            </Input>

                                        </FormGroup>
                                    </Col>



                                </Row>
                                :

                                null
                        }

                        {
                            question.type == 8 ?
                                <File
                                    onChange={(img) => {
                                        this.answerQuestion(question.id, img)
                                    }}
                                    value={this.props.answers[question.id]}
                                    disabled={this.props.disabled}
                                />
                                :

                                null
                        }
                        {
                            question.type == 9 ?
                                this.props.forPrint || this.props.previewAnsweredForm || this.props.disabled ?
                                    <FormGroup tag="fieldset" style={{ paddingLeft: 20 }}>
                                        {
                                            this.props.answers[question.id] && this.props.answers[question.id].map((file, fidx) => {
                                                return (
                                                    <>
                                                        <a href={API_ENDPOINT + file.file + `?mt=${this.props.uData ? this.props.uData.mediaToken : ''}`} target="_blank">{'File/photo'.translate(this.props.lang)}</a>
                                                        <br />
                                                    </>
                                                )
                                            })
                                        }

                                    </FormGroup>
                                    :
                                    <>
                                        <File
                                            lang={this.props.lang}
                                            alias={localStorage.getItem('groupAlias')}
                                            onChange={(img) => {
                                                this.answerQuestion(question.id, img)
                                            }}
                                            value={this.props.answers[question.id]}
                                            disabled={this.props.disabled}
                                        />

                                    </>
                                :

                                null
                        }
                        {
                            question.type == 12 ?
                                <Row>
                                    <Col lg="4">
                                        <FormGroup>
                                            <Label>{'Systolic'.translate(this.props.lang)}</Label>
                                            <Input disabled={this.props.disabled} style={questionCssStyle} size="sm" type="number" min="0" max="300" value={this.props.answers[question.id] ? this.props.answers[question.id][0] : ''}
                                                onChange={(e) => {
                                                    let val = this.props.answers[question.id] ? this.props.answers[question.id] : ['', '', ''];
                                                    if (Number(e.target.value) > 300) {
                                                        val[0] = 300;
                                                    } else if (Number(e.target.value) >= 0 && Number(e.target.value) <= 300) {
                                                        val[0] = e.target.value;
                                                    } else if (Number(e.target.value) < 0) {
                                                        val[0] = 0;
                                                    }
                                                    this.answerQuestion(question.id, val)
                                                }}></Input>

                                        </FormGroup>
                                    </Col>
                                    <Col lg="4" style={{ display: 'flex', alignItems: 'center' }}>
                                        <FormGroup>
                                            <Label>{'Diastolic'.translate(this.props.lang)}</Label>
                                            <Input disabled={this.props.disabled} style={questionCssStyle} size="sm" type="number" min="0" max="300" value={this.props.answers[question.id] ? this.props.answers[question.id][1] : ''}
                                                onChange={(e) => {
                                                    let val = this.props.answers[question.id] ? this.props.answers[question.id] : ['', '', ''];
                                                    if (Number(e.target.value) > 300) {
                                                        val[1] = 300;
                                                    } else if (Number(e.target.value) >= 0 && Number(e.target.value) <= 300) {
                                                        val[1] = e.target.value;
                                                    } else if (Number(e.target.value) < 0) {
                                                        val[1] = 0;
                                                    }
                                                    this.answerQuestion(question.id, val)
                                                }}></Input>

                                        </FormGroup>

                                    </Col>
                                    <Col lg="4" style={{ display: 'flex', alignItems: 'center' }}>
                                        <FormGroup>
                                            <Label>{'Pulse'.translate(this.props.lang)}</Label>
                                            <Input disabled={this.props.disabled} style={questionCssStyle} size="sm" type="number" min="0" max="300" value={this.props.answers[question.id] ? this.props.answers[question.id][2] : ''}
                                                onChange={(e) => {
                                                    let val = this.props.answers[question.id] ? this.props.answers[question.id] : ['', '', ''];
                                                    if (Number(e.target.value) > 300) {
                                                        val[2] = 300;
                                                    } else if (Number(e.target.value) >= 0 && Number(e.target.value) <= 300) {
                                                        val[2] = e.target.value;
                                                    } else if (Number(e.target.value) < 0) {
                                                        val[2] = 0;
                                                    }
                                                    this.answerQuestion(question.id, val)
                                                }}></Input>

                                        </FormGroup>

                                    </Col>


                                </Row>
                                :

                                null
                        }
                        {
                            question.type == 13 ?
                                <Row>
                                    <Col lg={{ size: 4, offset: 4 }}>
                                        <FormGroup>
                                            <Input disabled={this.props.disabled} style={questionCssStyle} size="sm" type="number" min="0" max="10" value={this.props.answers[question.id] ? this.props.answers[question.id][0] : ''}
                                                onChange={(e) => {
                                                    let val = this.props.answers[question.id] ? this.props.answers[question.id] : [''];
                                                    if (Number(e.target.value) > 10) {
                                                        val[0] = 10;
                                                    } else if (Number(e.target.value) >= 0 && Number(e.target.value) <= 10) {
                                                        val[0] = e.target.value;
                                                    } else if (Number(e.target.value) < 0) {
                                                        val[0] = 0;
                                                    }
                                                    this.answerQuestion(question.id, val)
                                                }}></Input>

                                        </FormGroup>
                                    </Col>



                                </Row>
                                :

                                null
                        }
                        {
                            question.type == 14 ?
                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <Input disabled={this.props.disabled} type='select' value={this.props.answers[question.id] ? this.props.answers[question.id] : ''} min={0} max={100}
                                                onChange={(e) => {
                                                    let val = e.target.value
                                                    this.answerQuestion(question.id, val)
                                                }}>
                                                {
                                                    this.state.items.map(item => {
                                                        return (
                                                            <option value={item.value}>
                                                                {item.name}
                                                            </option>
                                                        )
                                                    })
                                                }
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                :
                                null
                        }
                        {
                            question.type == 15 ?
                                <div
                                    ref={(node) => {
                                        this.infoTextRef = node;
                                        this.infoTextId = question.id;
                                    }}
                                    style={questionCssStyle}
                                    className="referral-treatment-instructions"
                                    dangerouslySetInnerHTML={{ __html: question.text }}
                                />
                                :

                                null
                        }
                        {
                            question.type == 18 ?
                                <>
                                    {
                                        question.answers && question.answers.map((answer) => {
                                            let answers = this.props.answers;
                                            if (!answers[question.id]) {
                                                answers[question.id] = {};
                                            }
                                            if (!answers[question.id][answer.id]) {
                                                answers[question.id][answer.id] = 0
                                            }
                                            return (
                                                <FormGroup >
                                                    <Label className='scale-class' >
                                                        <p className={this.props.question18Finished && this.props.question18Finished[question.id] ? 'scale-class-question' : 'scale-class-question required-scale'} name={question.id} >{answer.name}</p>
                                                        <Button disabled={this.props.disabled} className='scale-class-question-button' onClick={() => {
                                                            let answers = this.props.answers;
                                                            if (!answers[question.id]) {
                                                                answers[question.id] = {};
                                                            }

                                                            if (answers[question.id] && answers[question.id][answer.id])
                                                                answers[question.id][answer.id] = Number(answers[question.id][answer.id]) - 1 >= 0 ? Number(answers[question.id][answer.id]) - 1 : 0;
                                                            else
                                                                answers[question.id][answer.id] = 0;

                                                            this.setStateFunction({ answers: answers }, () => {
                                                                if (this.props.checkIfNextQuestionIsTotal) {
                                                                    this.props.checkIfNextQuestionIsTotal()
                                                                }
                                                            })

                                                        }}>-</Button>
                                                        <input disabled={this.props.disabled} className='scale-class-question-input' type="text" value={this.props.answers && this.props.answers[question.id] && this.props.answers[question.id][answer.id] ? this.props.answers[question.id][answer.id] : 0}
                                                            onChange={(e) => {
                                                                let answers = this.props.answers;
                                                                if (!answers[question.id]) {
                                                                    answers[question.id] = {};
                                                                }
                                                                if (!answers[question.id][answer.id]) {
                                                                    answers[question.id][answer.id] = {}
                                                                }
                                                                let val = String(e.target.value).match(/[-]{0,1}[\d]*[.]{0,1}[\d]+/g);
                                                                val = val && val.length ? val[0] : 0;
                                                                val = Number(val);
                                                                answers[question.id][answer.id] = val < 0 ? 0 : val > 10 ? 10 : val;
                                                                // this.setState({
                                                                //     answers
                                                                // }, this.forceUpdate)
                                                                this.setStateFunction({ answers: answers }, () => {
                                                                    if (this.props.checkIfNextQuestionIsTotal) {
                                                                        this.props.checkIfNextQuestionIsTotal()
                                                                    }
                                                                })

                                                            }}
                                                        />
                                                        <Button disabled={this.props.disabled} className='scale-class-question-button' onClick={() => {

                                                            let answers = this.props.answers;
                                                            if (!answers[question.id]) {
                                                                answers[question.id] = {};
                                                            }

                                                            if (answers[question.id] && answers[question.id][answer.id]) {
                                                                answers[question.id][answer.id] = Number(answers[question.id][answer.id]) + 1 <= 10 ? Number(answers[question.id][answer.id]) + 1 : 10;

                                                            } else
                                                                answers[question.id][answer.id] = 1;

                                                            // this.setState({
                                                            //     answers
                                                            // }, this.forceUpdate)
                                                            this.setStateFunction({ answers: answers }, () => {
                                                                if (this.props.checkIfNextQuestionIsTotal) {
                                                                    this.props.checkIfNextQuestionIsTotal()
                                                                }
                                                            })
                                                        }}>+</Button>
                                                    </Label>

                                                </FormGroup>
                                            )
                                        })
                                    }
                                    {
                                        !this.props.question18Finished[question.id] ?
                                            <div className='scale-confirm-button-wrap'>
                                                <Button disabled={this.props.disabled} style={{ border: 'none' }} onClick={() => {
                                                    if (!this.props.question18Finished[question.id]) {
                                                        let question18Finished = this.props.question18Finished;
                                                        question18Finished[question.id] = true
                                                        this.setStateFunction({ question18Finished: question18Finished }, () => {
                                                            this.forceUpdate()
                                                            if (this.props.checkIfNextQuestionIsTotal) {
                                                                this.props.checkIfNextQuestionIsTotal()
                                                            }
                                                        })
                                                    }

                                                }}>{"Confirm".translate(this.props.lang)}</Button>
                                            </div>
                                            :
                                            null
                                    }

                                </>
                                :
                                null

                        }
                        {
                            question.type == 19 ?
                                <>
                                    <Input disabled={this.props.disabled} style={questionCssStyle} size="sm" type="text" value={this.props.answers[question.id]} onChange={(e) => {
                                        let val = cleanNumber(e.target.value);

                                        this.answerQuestion(question.id, val)
                                    }}></Input>

                                </>
                                :

                                null
                        }


                    </FormGroup>
                </div>
            </div>
        )
    }
}
export default TypeFields;