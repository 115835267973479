import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Notification from '../components/notifications';
import Sidebar from '../components/sidebar';
import Footer from '../components/footer';
import PatientAccountModal from '../components/patientAccountModal';
import PrivacyPolicyModal from '../components/privacyPolicyModal';

import ChatComponent from '../components/chatComponent';
import ImportantInformation from '../components/importantInformation';



function iosDevice() {
    var userAgent = window.navigator.userAgent;
    if (userAgent.match(/iPad/i) || userAgent.match(/iPhone/i)) {
        return true;
    }
    else {
        return false;
    }
}


export const DefaultLayout = (Wrapped) => (props) => {
    let homeContentSidebarShort = props.autoHideSidebar ? 'home-content-sidebar-short-auto-hide' : 'home-content-sidebar-short';
    return (
        <div style={typeof window != 'undefined' && iosDevice() ? { maxWidth: `${window.innerWidth}px` } : {}} className={props.sidebarWide && !props[0].match.path != '/chat/:windowConversation' ? `home-content ${props.uData ? 'home-content-sidebar-wide logged-in' : ''}` : `home-content ${homeContentSidebarShort} ${props.uData ? '' : 'not-logged-in'}`}>

            {props.uData ? <Sidebar className="sidebar" {...props} /> : null}
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                <Notification {...props} />
                <Wrapped {...props} />
            </div>
            <ImportantInformation {...props} />
            {props.uData && props[0].match.path != '/:alias/listing' ? <PatientAccountModal {...props} /> : null}
            {props.uData && props[0].match.path != '/:alias/listing' && props.privacyPolicyModal ? <PrivacyPolicyModal {...props} /> : null}
            {/* {
                props.uData && (props.uData.userLevel == 20 && typeof window != 'undefined' && localStorage?.getItem?.('profile') != 'patient') && props[0].match.path != '/chat' && props[0].match.path != '/chat/:windowConversation' && props[0].match.path != '/video-call/:conversation' ?
                    <ChatComponent {...props} />
                    :
                    null
            } */}
        </div>
    );
};

export default DefaultLayout;