import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';

import Isvg from 'react-inlinesvg';

import { FormGroup, Label, Input, FormFeedback, FormText } from 'reactstrap';

import hide from '../../../assets/svg/hide.svg';
import show from '../../../assets/svg/show.svg';
import striptags from 'striptags';
import infoIcon from "../../../assets/svg/link-new-tab.svg";


/**
* Generate custom fields for forms
* @author   Milan Stanojevic
*/
class Text extends Component {
    constructor(props) {
        super(props);

        this.state = {
            active: false
        };
    }


    componentDidMount() {
    }



    render() {

        return (
            <FormGroup check={this.props.type == 'checkbox'}>
                {this.props.label ?
                    <Label check={this.props.type == 'checkbox'} size="sm" className={this.props.error ? "required form-group-label-wrap" : "form-group-label-wrap"}>
                        {this.props.label}{this.props.haveValidation ? <span className="required-label-span">*</span> : null}
                        {
                            this.props.labelInfo ?
                                <div className='info'>
                                    <Isvg src={infoIcon} />
                                    <div className="info-text">{this.props.labelInfo}</div>
                                </div>
                                :
                                null
                        }
                    </Label>
                    :
                    null}
                {
                    this.props.beforeText ? <span className="field-before-text">{this.props.beforeText}</span> : null
                }
                <Input
                    style={{ height: this.props.height ? this.props.height : null, width: this.props.width ? this.props.width : null }}
                    onKeyPress={(e) => {
                        if (!this.props.pattern) {
                            return true;
                        } else {
                            var value = String.fromCharCode(e.which);

                            let check = this.props.pattern.test(value);
                            if (!check) {
                                e.preventDefault();
                                return false;
                            }
                            return true;

                        }
                    }} autoComplete={this.props.autoComplete} size="sm" check={this.props.type == 'checkbox'} disabled={this.props.disabled} name={this.props.name} defaultChecked={this.props.optionValue == this.props.value} checked={this.props.type == 'checkbox' ? this.props.value : null} invalid={this.props.error ? true : false} type={this.props.type ? this.props.type == 'password' && this.state.showPassword ? 'text' : this.props.type : 'text'} min={this.props.minValue} multiple={this.props.multiple} onChange={(val) => {
                        /*if (this.props.type == 'radio') {
                            this.props.onChange(this.props.optionValue);
                            return;
                        }*/

                        if (this.props.multilang) {
                            let value = this.props.value;
                            if (!value) {
                                value = {};
                            }
                            value[this.props.lang] = striptags(val.target.value);

                            this.props.onChange(value);
                            this.forceUpdate();
                        } else {
                            val.target.value = striptags(val.target.value)
                            this.props.onChange(val);
                            //this.forceUpdate();

                        }

                        //this.props.onChange(val)
                    }}

                    onClick={() => {

                        if (!this.state.active && this.props.type == 'text' && this.props.login) {
                            let value = this.props.value;
                            this.props.onChange(' ');
                            this.setState({ active: true })
                            setTimeout(() => {
                                this.props.onChange(value);
                            }, 20)
                        }



                    }}


                    value={this.props.multilang ? (this.props.value && this.props.value[this.props.lang]) ? this.props.value[this.props.lang] : '' : this.props.value} placeholder={this.props.placeholder}>{this.props.children}</Input>
                {
                    this.props.value && this.props.type == "password" ?
                        <button type="button" onClick={() => this.setState({ showPassword: !this.state.showPassword })} className="password-ctrl">
                            {this.state.showPassword ?
                                <Isvg src={hide} />
                                :
                                <Isvg src={show} />
                            }
                        </button>
                        :

                        null
                }

                {
                    this.props.afterText ? <span className="field-after-text">{this.props.afterText}</span> : null
                }
                {this.props.error ? <FormFeedback >{this.props.error}</FormFeedback> : null}
            </FormGroup >
        );
    }
}

export default Text;