import React, { Component } from 'react';
import { Link, matchPath } from 'react-router-dom';
import translateLinkToLang from '../translateLinkToLang';


class LangLink extends Component {
    constructor(props) {
        super(props);

    }

    componentDidMount() {

    }

    componentDidUpdate() {

    }
    render() {
        let to = this.props.to;
        let currentPathname = this.props.currentPathname;
        return (
            this.props.check ?
                <div onMouseEnter={this.props.onMouseEnter} onMouseLeave={this.props.onMouseLeave} onClick={() => this.props.openModal(this.props.to)}>
                    <a className={this.props.className}  >{this.props.children}</a>

                </div>
                :
                <Link
                    onMouseEnter={this.props.onMouseEnter}
                    onMouseLeave={this.props.onMouseLeave}
                    className={this.props.className}
                    to={{ pathname: to == currentPathname && !this.props.refershPageDisabled ? '/refresh/page' : to, state: { reditectTo: to == currentPathname && !this.props.refershPageDisabled? to : null } }}
                    onClick={
                        this.props.onClick ?
                            () => {
                                this.props.onClick();
                            }
                            :
                            () => { }
                    }
                >
                    {this.props.children}
                </Link>
        );
    }
}

export default LangLink;