import React, { Component } from 'react';

import { Link } from 'react-router-dom';

import {
    Container,
    Row,
    Col,
    Button,
    FormGroup,
    Label,
    Input,
    Modal, ModalHeader, ModalBody, ModalFooter, Form
} from 'reactstrap';

import { API_ENDPOINT } from '../../constants';
import PhoneInput from 'react-phone-input-2';
function parseJSON(response) {
    return response.json().then(json => {
        return {
            result: json,
            status: response.status,
            disabledForm: false
        }
    })
}



class FooterComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props.initialData,

        };
    }

    componentDidMount() {
    }


    render() {
        const { data } = this.props;

        let footerSettings = {
            backgroundColor: '#e6edf5',
            upperSegment: {
                color: '#7e8fae',
                fontSize: 16
            },
            lowerSegment: {
                color: '#7e8fae',
                fontSize: 16
            }
        };
        if (data.footerSettings) {
            footerSettings = data.footerSettings;
        }

        let upperSegmentStyle = footerSettings.upperSegment ? footerSettings.upperSegment : {}
        let lowerSegmentStyle = footerSettings.lowerSegment ? footerSettings.lowerSegment : {}
        return (
            <div className="landing-footer footer-space" style={{ backgroundColor: footerSettings.backgroundColor, marginTop: 'auto' }}>
                <Container>
                    <Row>
                        <Col lg="12">

                            <ul className="landing-footer" style={{ paddingLeft: 0 }}>
                                {data.privacyPolicy ? <li><Link to={`/${this.props[0].match.params.alias}/privacy-policy`} target="_blank" style={upperSegmentStyle}> {'Privacy policy'.translate(this.props.lang)} </Link></li> : null}
                                {data.termsOfUse ? <li><a href={`/${this.props[0].match.params.alias}/terms-of-use`} target="_blank" style={upperSegmentStyle}> {'Terms of use'.translate(this.props.lang)} </a></li> : null}
                                {data.accessibilityReport ? <li><a href={`/${this.props[0].match.params.alias}/accessibility-report`} target="_blank" style={upperSegmentStyle}> {'Accessibility Report'.translate(this.props.lang)} </a></li> : null}
                            </ul>
                        </Col>
                        <Col lg="12">
                            <ul className="landing-footer landing-footer-list" style={{ paddingLeft: 0 }}>
                                {data && data.address && data.address.map((address) => {
                                    if (address && address !== '' && address.trim() !== '')
                                        return (
                                            <li style={lowerSegmentStyle}>{address}</li>
                                        )
                                })}
                                {data && data.phone && <li style={lowerSegmentStyle}>
                                    {data.phone}
                                    {/* <PhoneInput disabled={true}
                                        buttonStyle={{ display: 'none' }}
                                        inputStyle={{ backgroundColor: 'transparent', cursor: 'default', ...lowerSegmentStyle }}
                                        value={data.phone} /> */}
                                </li>}
                            </ul>

                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default FooterComponent;