import React, { Component } from "react";
import Page from "../../containers/page";
import xIcon from '../../assets/svg/x.svg';

import {
  Container,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  Input,
  FormGroup,
  Label,
  Button,
} from "reactstrap";

import Isvg from "react-inlinesvg";

import { Player, Controls } from '@lottiefiles/react-lottie-player';

import FormBuilder from "../../components/forms/modalFormBuilder";
import { required } from "../../components/forms/validation";
import ErrorModal from '../../components/errorModal';
import InfoModal from '../../components/infoModal';
import ListBuilder from "../../components/listBuilder";
import DeleteModal from "../../components/deleteModal";
import {
  getSearchParams,
  generateSearchLink,
  generateSearchLinkMultiple,
} from "../../helpers/linkHelper";
import { API_ENDPOINT, DOMAINS } from "../../constants";
import { formValueSelector, change } from "redux-form"; // ES6
import { connect } from "react-redux";
import ClinicsFilter from "../../components/clinicsFilter";
import UsersFilter from "../../components/usersFilter";
import Image from "../../components/forms/fields/image";
import Zip from "../../components/forms/fields/zip";


import edit from "../../assets/svg/edit.svg";
import garabage from "../../assets/svg/garbage.svg";
import attachInvoice from '../../assets/svg/attach.svg'

import addIcon from "../../assets/svg/add-icon.svg";
import webdocImage from "../../assets/images/webdoc.png";
import teleqImage from "../../assets/images/teleqLogo.png";
import sembleImage from "../../assets/images/semble.webp";

import takeCareImage from '../../assets/images/take_care.webp';
import Search from "../../components/search";
import profileUser from "../../assets/images/user.png";
import notification from "../../assets/svg/notification.svg";
import Usage from "../../assets/svg/usage.svg";
import importIcon from '../../assets/svg/import.svg'

import exportIcon from "../../assets/svg/export.svg";
import Checkbox from '../../components/forms/fields/checkbox';
import MainClicGroupFilter from '../../components/mainClinicGroupsFilter';
import striptags from "striptags";

import moment from 'moment';
import DatePicker from '../../components/forms/fields/datePicker';

import { Pie, Bar } from 'react-chartjs-2';
import downloadIcon from '../../assets/svg/download.svg';

import CanvasJSReact from '../../assets/canvasjs.react';
var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;
import axios from 'axios'
import SelectGroupForTransfer from '../../components/selectGroupForTransfer'
import ListCheckBox from '../../components/forms/fields/listCheckboxFlex';

import Toggle from '../../components/forms/fields/toggle';
import HTML from '../../components/forms/fields/html';


function parseJSON(response) {
  return response.json().then(json => {
    return {
      result: json,
      status: response.status
    }
  })
}

function parseText(response) {
  return response.text().then(text => {
    return {
      result: text,
      status: response.status
    }
  })
}
/**
* Group settings page
* @author   Milan Stanojevic
*/
class ClinicGroups extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...props.initialData,
      form: false,
      useState: false,
      params: {
        entries: 10,
        page: 0,
      },
      items: [],
      groups: [],
      emails: [],
      subgroups: [],
      backups: [],
      importDatabaseTab: 1,
      total: 0,
      loading: true,
      clinicModules: [],
      selectedBackup: null,
      errors: {},
      licence: 10,
      videoLicence: 0,
      videoLink: 0,
      webDocModal: null,
      webDocSelectedClinics: [],
      integrationActive: 0,
      startDateSms: moment.unix(Math.floor(new Date().getTime() / 1000)).format('YYYY-MM-DD'),
      endDateSms: moment.unix(Math.floor(new Date().getTime() / 1000)).format('YYYY-MM-DD'),
      values: [],
      usersForTransfer: {},
      newUsernames: [],
      showAllUsersModal: null,
      mainClinicGroups: []

    };


  }

  get = () => {
    this.props.updateMeta(
      this.props.generateSeoTags(this.state, this.props.lang)
    );

    for (let i = 0; i < this.props.loadData.length; i++) {
      this.props.loadData[i](
        window.fetch,
        this.props.lang,
        this.props[0].match,
        this.state.useState
          ? this.state.params
          : getSearchParams(this.props[0].location.search, {
            entries: 10,
            page: 0,
          })
      ).then((data) => {
        this.setState(
          {
            ...data,
            loading: null,
          },
          () => {
            this.props.updateMeta(
              this.props.generateSeoTags(this.state, this.props.lang)
            );
          }
        );
      });
    }

    for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
      this.props.loadDataWithQuery[i](
        window.fetch,
        this.props.lang,
        this.props[0].match,
        this.state.useState
          ? this.state.params
          : getSearchParams(this.props[0].location.search, {
            entries: 10,
            page: 0,
            startDateSms: this.props.getStringDateTs(Math.floor(new Date(this.state.startDateSms).getTime() / 1000), 'YYYY-MM-DD 00:00:01')/*moment.unix(Math.floor(new Date(this.state.startDateSms).getTime() / 1000)).format('YYYY-MM-DD 00:00:01')*/,
            endDateSms: this.props.getStringDateTs(Math.floor(new Date(this.state.endDateSms).getTime() / 1000), 'YYYY-MM-DD 23:59:59')/*moment.unix(Math.floor(new Date(this.state.endDateSms).getTime() / 1000)).format('YYYY-MM-DD 23:59:59')*/,
            group: this.state.group,
            clinicIds: this.state.selectedMainClinicGroups && this.state.selectedMainClinicGroups !== '-1' && this.state.mainClinicGroups?.filter(item => item._id === this.state.selectedMainClinicGroups)[0]?.clinicGroups

          })
      ).then((data) => {
        let dataToSet = { ...data }
        if (dataToSet.page || dataToSet.items || dataToSet.total) {
          dataToSet.loading = null;
          // console.log(dataToSet.page || dataToSet.items || dataToSet.total)
          let checkFilter = '';
          if (dataToSet.resultFilter) {
            checkFilter = String(dataToSet.resultFilter)
            delete dataToSet.resultFilter;
          }
          let search = this.props[0].location.search;
          let serachParams = getSearchParams(search);
          let searchParamsFilter = '';
          if (serachParams.filter) {
            searchParamsFilter = serachParams.filter;
          }
          if (searchParamsFilter != checkFilter) {
            delete dataToSet.page;
            delete dataToSet.items;
            delete dataToSet.total;
            delete dataToSet.loading;
          }
        }
        if (dataToSet && dataToSet.items && this.state.form && this.state.form._id && dataToSet.items.filter(el => el._id == this.state.form._id).length) {
          dataToSet.items.filter(el => el._id == this.state.form._id)[0]
          this.setState({ form: dataToSet.items.filter(el => el._id == this.state.form._id)[0] }, () => {
            if (this.state.form && this.state.form.webdoc) {
              let data = {

                api: this.state.form.webdoc.api,
                clientId: this.state.form.webdoc.clientId,
                clientSecret: this.state.form.webdoc.clientSecret,
              };
              this.checkFetchClinics(data);
            } else {
              if (this.state.showRemoveConnection) {
                this.setState({ showRemoveConnection: false })
              }
            }
          })
        }
        this.setState(
          {
            ...dataToSet,
            // loading: null,
          },
          () => {
            this.props.updateMeta(
              this.props.generateSeoTags(this.state, this.props.lang)
            );
          }
        );
      });
    }


    fetch(API_ENDPOINT + '/data/db/temp/clinic-groups/backups', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
      }
    }).then((res) => res.json()).then((result) => {
      this.setState({ backups: result })

    })


  };

  uploadAuthKey = (e) => {

    let input = e.target;
    if (input.files && input.files[0]) {

      let fsize = input.files[0].size;
      let file = Math.round((fsize / 1024))
      this.setState({
        uploadTimestampDoc: Math.floor(new Date().getTime() / 1000)
      })
      if (file > 5120) {
        this.setState({ error: "File size limit has been reached! (max 5 MB)".translate(this.props.lang) })
      } else {

        this.setState({
          _documentUploading: true
        })

        let formData = new FormData();
        formData.append('file', input.files[0]);
        formData.append('group', this.state.form ? this.state.form._id : null)
        formData.append('fileType', 'clinicData')

        fetch(API_ENDPOINT + '/data/upload-document', {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,

          },
          body: formData
        }).then(parseText).then(({ result, status }) => {
          if (status != 200) {
            this.setState({ error: result.translate(this.props.lang) })
          } else {
            let form = this.state.form;
            if (!form.appSettings) {
              form.appSettings = {}
            }
            form.appSettings.authKeyP8 = result;
            this.setState({ form })

            this.setState({
              _documentUploading: null
            })
          }
        })
      }
    }
  }


  uploadJson = (e) => {
    let input = e.target;
    if (input.files && input.files[0]) {

      let fsize = input.files[0].size;
      let file = Math.round((fsize / 1024))
      this.setState({
        uploadTimestampDoc: Math.floor(new Date().getTime() / 1000)
      })
      if (file > 5120) {
        this.setState({ error: "File size limit has been reached! (max 5 MB)".translate(this.props.lang) })
      } else {

        this.setState({
          _documentUploading: true
        })

        let formData = new FormData();
        formData.append('file', input.files[0]);
        formData.append('group', this.state.form ? this.state.form._id : null)
        formData.append('fileType', 'clinicData')

        fetch(API_ENDPOINT + '/data/upload-document', {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,

          },
          body: formData
        }).then(parseText).then(({ result, status }) => {
          if (status != 200) {
            this.setState({ error: result.translate(this.props.lang) })
          } else {
            let form = this.state.form;
            if (!form.appSettings) {
              form.appSettings = {}
            }
            form.appSettings.googleServiceJson = result;
            this.setState({ form })

            this.setState({
              _documentUploading: null
            })
          }
        })
      }
    }
  }

  uploadDocument = (e) => {
    let input = e.target;
    if (input.files && input.files[0]) {

      let fsize = input.files[0].size;
      let file = Math.round((fsize / 1024))
      this.setState({
        uploadTimestampDoc: Math.floor(new Date().getTime() / 1000)
      })
      if (file > 5120) {
        this.setState({ error: "File size limit has been reached! (max 5 MB)".translate(this.props.lang) })
      } else {

        this.setState({
          _documentUploading: true
        })

        let formData = new FormData();
        formData.append('file', input.files[0]);
        formData.append('group', this.state.form ? this.state.form._id : null)
        formData.append('fileType', 'clinicData')

        fetch(API_ENDPOINT + '/data/upload-document', {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,

          },
          body: formData
        }).then(parseText).then(({ result, status }) => {
          if (status != 200) {
            this.setState({ error: result.translate(this.props.lang) })
          } else {
            let form = this.state.form;
            if (!form.appSettings) {
              form.appSettings = {}
            }
            form.appSettings.googleServiceInfo = result;
            this.setState({ form })

            this.setState({
              _documentUploading: null
            })
          }
        })
      }
    }
  }

  getTakeCareClinics = (data) => {
    if (data.api && data.clientId && data.clientSecret) {
      fetch(API_ENDPOINT + '/take-care/clinics', {
        method: 'POST',
        headers: {
          'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
          'content-type': 'application/json'
        },
        body: JSON.stringify(data)
      }).then(parseJSON).then(({ result }) => {

        if (result && result.error) this.setState({ error: result.error });
        if (result && Array.isArray(result)) {
          this.setState({ takeCareClinics: result })
        }
      }).catch(err => {
        if (err && err.error) this.setState({ error: err.error })
      })
    } else this.setState({
      error: 'Please make sure you have entered api, clientId and clientSecret fields!'.translate(this.props?.lang)
    })
  }

  getTeleqUnits = (data) => {
    if (data.api && data.clientId && data.clientSecret) {
      fetch(API_ENDPOINT + '/teleq/units', {
        method: 'POST',
        headers: {
          'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
          'content-type': 'application/json'

        },
        body: JSON.stringify(data)
      }).then(parseJSON).then(({ result, status }) => {
        if (result && Object.keys(result).length !== 0)
          this.setState({ teleqUnits: result })

      })
    }

  }

  getSembleLocations = (data) => {
    if (data?.api && data.token) {
      this.setState({ loading: true }, async () => {
        const newState = {
          loading: false,
          error: null,
        };

        const response = await fetch(`${API_ENDPOINT}/semble/clinics`, {
          method: 'POST',
          headers: {
            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            'content-type': 'application/json'
          },
          body: JSON.stringify(data)
        });

        const result = await response?.json?.();
        if (!result || result.error || !result.locations || !Array.isArray(result.locations) || !result.locations.length) {
          newState.error = result?.error || 'Something went wrong and request to Semble API has failed. Please try again later.';
          return this.setState(newState);
        }

        newState.sembleLocations = result.locations;
        this.setState(newState);
      });
    } else {
      this.setState({
        error: 'Please make sure you have entered api and token fields!'.translate(this.props.lang)
      });
    }
  }



  /**
  * Fuction for getting clinics from webdoc
  * @author   Aleksandar Dabic
  * @Objectparam    {String} api              api
  * @Objectparam    {String} clientId         clientId
  * @Objectparam    {String} clientSecret     clientSecret
  */
  fetchClinics = (data) => {

    if (data.api && data.clientId && data.clientSecret) {
      fetch(API_ENDPOINT + '/web-doc/clinics', {
        method: 'POST',
        headers: {
          'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
          'content-type': 'application/json'

        },
        body: JSON.stringify(data)
      }).then(parseJSON).then(({ result, status }) => {
        // console.log(result)
        if (result && Object.keys(result).length !== 0)
          this.setState({ webDocClinics: result })

      })
    }




  }
  checkFetchClinics = (data) => {

    if (data.api && data.clientId && data.clientSecret) {
      fetch(API_ENDPOINT + '/web-doc/clinics', {
        method: 'POST',
        headers: {
          'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
          'content-type': 'application/json'

        },
        body: JSON.stringify(data)
      }).then(parseJSON).then(({ result, status }) => {
        // console.log(result)
        if (result && Object.keys(result).length !== 0) {
          if (!this.state.showRemoveConnection)
            this.setState({ showRemoveConnection: true })
        } else {
          if (this.state.showRemoveConnection)
            this.setState({ showRemoveConnection: false })
        }


      })
    } else {
      if (this.state.showRemoveConnection) {
        this.setState({ showRemoveConnection: false })
      }
    }




  }
  componentDidMount() {
    if (typeof window !== "undefined") {
      window.scrollTo(0, 0);
    }

    this.get();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps?.country !== this.props.country) this.get();
    if (prevProps[0].location.search != this.props[0].location.search) {
      this.setState(
        {
          loading: true,
        },
        () => {
          this.get();
        }
      );
    }
    if (prevState.importModal != this.state.importModal && !this.state.importModal) {
      this.setState({ databaseFile: null, selectedBackup: null, importDatabaseTab: 1 })
    }
    // if (prevState.importGroupsModal != this.state.importGroupsModal && !this.state.importGroupsModal) {
    //   this.setState({
    //     importClinicData: {
    //       clinicCaseData: false,
    //       clinicData: false,
    //       clinicSupportData: false
    //     }
    //   })
    // }

  }



  updateSort = (field, type) => {
    if (this.state.useState) {
      let state = this.state.params;
      state.sortField = field;
      state.sortType = type;
      this.setState({
        params: state,
      });
    } else {
      this.props[0].history.push(
        generateSearchLinkMultiple(
          this.props[0].location.search,
          { entries: 10, page: 0 },
          [
            { name: "sortField", value: field },
            { name: "sortType", value: type },
          ],
          false
        )
      );
    }
  };

  updateParams = (name, value, restart = false) => {
    if (this.state.useState) {
      if (restart) {
        let obj = {
          entries: 10,
          page: 0,
        };
        obj[name] = value;
        this.setState({ params: obj });
      } else {
        let obj = this.state.params;
        obj[name] = value;
        this.setState({ params: obj });
      }
    } else {
      this.props[0].history.push(
        generateSearchLink(
          this.props[0].location.search,
          { entries: 10, page: 0 },
          name,
          value,
          restart
        )
      );
    }
  };
  updateMultipleParams = (fields, restart = false) => {
    if (this.state.useState) {
      if (restart) {
        let obj = {
          entries: 10,
          page: 0,
        };
        for (let i = 0; i < fields.length; i++) {
          obj[fields[i].name] = fields[i].value;
        }
        this.setState({ params: obj });
      } else {
        let obj = this.state.params;
        for (let i = 0; i < fields.length; i++) {
          obj[fields[i].name] = fields[i].value;
        }
        this.setState({ params: obj });
      }
    } else {
      this.props[0].history.push(
        generateSearchLinkMultiple(
          this.props[0].location.search,
          { entries: 10, page: 0 },
          fields,
          restart
        )
      );
    }
  };

  updateTakeCare = (data) => {
    console.log('take care data to send: ', data);
    if (this.state.form && this.state.form._id) {
      this.setState({ loading: true, _saving: true }, () => {
        fetch(API_ENDPOINT + '/data/clinic-groups/take-care/' + this.state.form._id, {
          method: "POST",
          headers: {
            Authorization:
              typeof localStorage !== "undefined"
                ? `Bearer ${localStorage.getItem("authToken")}`
                : null,
            "content-type": "application/json",
          },
          body: JSON.stringify(data)
        }).then(res => res.json()).then(result => {
          if (result.error) {
            this.setState({
              error: result.error.translate(this.props.lang),
              loading: null,

            });
          } else {
            this.get();
            this.setState({
              _saving: false,
              integrationModal: null,
              loaderWebDocSave: null
            }, () => {
              setTimeout(() => {
                this.setState({ _saving: false, webDocModal: null, error: null, takeCareClinics: null })
              }, 1000)
            })
          }
        })
      });
    }
  }

  updateSemble = (data) => {
    console.log("Semble data to send: ", data);
    if (this.state.form?._id) {
      this.setState({ loading: true, _saving: true }, async () => {
        const response = await fetch(`${API_ENDPOINT}/data/clinic-groups/semble/${this.state.form._id}`, {
          method: 'POST',
          headers: {
            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            'content-type': 'application/json',
          },
          body: JSON.stringify(data),
        });

        const result = await response?.json?.();

        if (!result || result.error) {
          return this.setState({
            error: result?.error?.translate(this.props.lang) || 'Something went wrong and request to Semble API has failed. Please try again later.',
            loading: null,
          });
        }

        this.get();
        this.setState({
          _saving: false,
          integrationModal: null,
          loaderWebDocSave: null,
          loading: false
        }, () => {
          setTimeout(() => {
            this.setState({ _saving: false, webDocModal: null, error: null, sembleLocations: null })
          }, 1000)

        })
      });
    }
  }


  updateWebDoc = (data) => {
    // console.log(data.webdoc.clinics)
    // console.log(JSON.stringify(data.webdoc.clinics))
    if (this.state.form && this.state.form._id) {
      this.setState(
        {
          loading: true,
          _saving: true,
          loaderWebDocSave: true
        },
        () => {
          fetch(API_ENDPOINT + "/data/clinic-groups/web-doc/" + this.state.form._id, {
            method: "POST",
            headers: {
              Authorization:
                typeof localStorage !== "undefined"
                  ? `Bearer ${localStorage.getItem("authToken")}`
                  : null,
              "content-type": "application/json",
            },
            body: JSON.stringify(data),
          })
            .then((res) => res.json())
            .then((result) => {
              if (result.error) {
                this.setState({
                  error: result.error.translate(this.props.lang),
                  loading: null,

                });
              } else {
                this.get();
                this.setState({
                  _saving: false,
                  integrationModal: null,
                  loaderWebDocSave: null
                }, () => {
                  setTimeout(() => {
                    this.setState({ _saving: false, webDocModal: null, error: null })
                  }, 1000)
                })
              }
            });
        }
      );
    }

  };
  updateTeleq = (data) => {
    if (this.state.form && this.state.form._id) {
      this.setState(
        {
          loading: true,
          _saving: true,
          loaderTeleqSave: true
        },
        () => {
          fetch(API_ENDPOINT + "/data/clinic-groups/teleq/" + this.state.form._id, {
            method: "POST",
            headers: {
              Authorization:
                typeof localStorage !== "undefined"
                  ? `Bearer ${localStorage.getItem("authToken")}`
                  : null,
              "content-type": "application/json",
            },
            body: JSON.stringify(data),
          })
            .then((res) => res.json())
            .then((result) => {
              if (result.error) {
                this.setState({
                  error: result.error.translate(this.props.lang),
                  loading: null,

                });
              } else {
                this.get();
                this.setState({
                  _saving: false,
                  integrationModal: null,
                  loaderTeleqSave: null
                }, () => {
                  setTimeout(() => {
                    this.setState({ _saving: false, teleqModal: null, error: null })
                  }, 1000)
                })
              }
            });
        }
      );
    }

  };

  /**
  * Insert new or update clinic group data
  * @author   Milan Stanojevic
  * @Objectparam    {Object} data     form data
  * @Objectparam    {String} id       clinic group id
  */
  insertOrUpdate = (data) => {
    let link = window.location.origin;
    data.link = link;
    // console.log(data)
    this.setState(
      {
        loading: true,
        _saving: true,
        showPlayer: true
      },
      () => {
        if (!this.state.form._id) {
          fetch(API_ENDPOINT + "/data/clinic-groups/new", {
            method: "PUT",
            headers: { Authorization: typeof localStorage !== "undefined" ? `Bearer ${localStorage.getItem("authToken")}` : null, "content-type": "application/json", },
            body: JSON.stringify(data),
          }).then((res) => res.json()).then((result) => {
            if (result.error) {
              this.setState({
                error: result.error.translate(this.props.lang),
                loading: null,
              });
            } else {
              this.get();
              this.setState({
                form: null,
                error: null,
                webDocClinics: null, teleqUnits: null
              });
              this.setState({
                showPlayer: false,
                showPlayer2: true,
              }, () => {
                setTimeout(() => {
                  this.setState({ showPlayer2: false });
                }, 1000);
              })
            }
          });
        } else {
          fetch(API_ENDPOINT + "/data/clinic-groups/" + this.state.form._id, {
            method: "POST",
            headers: { Authorization: typeof localStorage !== "undefined" ? `Bearer ${localStorage.getItem("authToken")}` : null, "content-type": "application/json", },
            body: JSON.stringify(data),
          }).then((res) => res.json()).then((result) => {
            if (result.error) {
              this.setState({
                error: result.error.translate(this.props.lang),
                loading: null,
              });
            } else {
              this.get();
              this.setState({
                showPlayer: false,
                showPlayer2: true,
              }, () => {
                setTimeout(() => {
                  this.setState({ showPlayer2: false });
                }, 1000);
              })
              this.setState({
                _saving: false
              }, () => {
                setTimeout(() => {
                  this.setState({ _saving: false, form: null, error: null, webDocClinics: null, teleqUnits: null })
                }, 1000)
              })
            }
          });
        }
      }
    );
  };
  /**
  * Update clinic group data
  * @author   Milan Stanojevic
  * @Objectparam    {Object} data     form data
  * @Objectparam    {String} id       clinic group id
  */
  update = (data) => {
    this.setState(
      {
        loading: true,
      },
      () => {
        fetch(API_ENDPOINT + "/data/clinic-groups/" + data._id, {
          method: "POST",
          headers: {
            Authorization:
              typeof localStorage !== "undefined"
                ? `Bearer ${localStorage.getItem("authToken")}`
                : null,
            "content-type": "application/json",
          },
          body: JSON.stringify(data),
        })
          .then((res) => res.json())
          .then((result) => {
            this.get();
            this.setState({
              form: null,
              webDocClinics: null
            });
          });
      }
    );
  };

  importDatabase = () => {
    let data = {};
    if (this.state.importDatabaseTab == 1) {
      data.databaseFile = this.state.databaseFile;
    } else if (this.state.importDatabaseTab == 2) {
      data.selectedBackup = this.state.selectedBackup;
    }

    this.setState({ loading: true }, () => {
      fetch(API_ENDPOINT + "/data/db/temp", {
        method: "POST",
        headers: {
          Authorization:
            typeof localStorage !== "undefined"
              ? `Bearer ${localStorage.getItem("authToken")}`
              : null,
          "content-type": "application/json",
        },
        body: JSON.stringify(data)
      })
        .then((res) => res.json())
        .then((result) => {
          this.setState({ importModal: false }, () => {
            this.setState({ loading: false })
            this.getTempData()
          })
        });
    })
    // this.setState({ importModal: false }, () => {
    //   this.getTempData()
    // })

  }
  getTempData = () => {

    fetch(API_ENDPOINT + "/data/db/temp/clinic-groups", {
      method: "GET",
      headers: {
        Authorization:
          typeof localStorage !== "undefined"
            ? `Bearer ${localStorage.getItem("authToken")}`
            : null,
        "content-type": "application/json",
      }
    })
      .then((res) => res.json())
      .then((result) => {
        this.setState({
          selectedClinicGroup: null,
          tempClinicGroups: result, importGroupsModal: true, importClinicData: {
            clinicCaseData: false,
            clinicData: false,
            clinicSupportData: false
          }
        })
      });
  }

  updateDatabase = () => {
    let selectedClinicGroup = this.state.selectedClinicGroup;
    let importClinicData = this.state.importClinicData;
    let groupName = '';
    for (let i = 0; i < this.state.tempClinicGroups.length; i++) {
      if (this.state.tempClinicGroups[i]._id == selectedClinicGroup) {
        groupName = this.state.tempClinicGroups[i].name;
      }
    }

    this.setState({ updateDatabaseLoader: true }, () => {

      this.props.handleUploadFileProgress('Processing file...'.translate(this.props.lang));


      axios.post(API_ENDPOINT + '/data/db/temp/clinic-groups/update/database', {
        group: selectedClinicGroup,
        importFiles: importClinicData

      }, {
        headers: {
          Accept: 'application/json',
          'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
        },
        onUploadProgress: (progressEvent) => {
          this.props.handleUploadFileProgress('Uploading file...'.translate(this.props.lang), Math.round((progressEvent.loaded * 100) / progressEvent.total));

        }
      }).then((response) => {
        if (response && response.status == 200) {
          let message = 'You have successfully updated the clinic group'.translate(this.props.lang) + ' (' + groupName + ')';
          this.setState({
            updateDatabaseLoader: false,
            importGroupsModal: false,
            selectedClinicGroup: null,
            succesMessage: message
          })
        }

      })
    })
    // fetch(API_ENDPOINT + "/data/db/temp/clinic-groups/update/database", {
    //   method: "POST",
    //   headers: {
    //     Authorization:
    //       typeof localStorage !== "undefined"
    //         ? `Bearer ${localStorage.getItem("authToken")}`
    //         : null,
    //     "content-type": "application/json",
    //   },
    //   body: JSON.stringify({
    //     group: selectedClinicGroup,
    //     importFiles: importClinicData

    //   })
    // })
    //   .then((res) => res.json())
    //   .then((result) => {

    //   });

  }

  /**
  * Delete clinic group data
  * @author   Milan Stanojevic
  * @Objectparam    {String} id       clinic group id
  */
  delete = (data) => {
    this.setState(
      {
        loading: true,
      },
      () => {
        fetch(API_ENDPOINT + "/data/clinic-groups/" + data._id, {
          method: "DELETE",
          headers: {
            Authorization:
              typeof localStorage !== "undefined"
                ? `Bearer ${localStorage.getItem("authToken")}`
                : null,
            "content-type": "application/json",
          },
          body: JSON.stringify({ name: data.name })
        })
          .then((res) => res.json())
          .then((result) => {
            if (result.success) {
              var link = document.createElement("a");
              link.download = `${result.file}`;
              link.target = "_blank";

              // Construct the URI
              link.href = API_ENDPOINT + `${result.file}`;
              document.body.appendChild(link);
              link.click();

              // window.open(API_ENDPOINT + `${result.file}`);

            }
            this.setState({
              success: result.success,
              file: result.file
            })
            this.get();
          });
      }
    );
  };

  /**
  * Export clinic data
  * @author   Aleksandar Dabic
  */
  export = (clinicGroup) => {
    this.setState({ loadingExport: true }, () => {
      fetch(API_ENDPOINT + "/data/clinic-groups/export", {
        method: "POST",
        headers: {
          Authorization:
            typeof localStorage !== "undefined"
              ? `Bearer ${localStorage.getItem("authToken")}`
              : null,
          "content-type": "application/json",
        },
        body: JSON.stringify(clinicGroup),
      })
        .then((res) => res.json())
        .then((result) => {
          if (result.success) {
            // window.open(API_ENDPOINT + `${result.file}`);

            var link = document.createElement("a");
            link.download = `${result.file}`;
            link.target = "_blank";

            // Construct the URI
            link.href = API_ENDPOINT + `${result.file}`;
            document.body.appendChild(link);
            link.click();




          }
          this.setState({
            success: result.success,
            file: result.file,
            loadingExport: false
          })
        });
    })


  }

  exportFromGraph = (data) => {
    // this.setState({ loadingExport: true }, () => {
    fetch(API_ENDPOINT + "/data/clinic-groups/data-usage/time/export", {
      method: "POST",
      headers: {
        Authorization: typeof localStorage !== "undefined" ? `Bearer ${localStorage.getItem("authToken")}` : null,
        "content-type": "application/json",
      },
      body: JSON.stringify({
        startDateSms: data.startDateSms,
        endDateSms: data.endDateSms,
        group: data.group,
      }),
    }).then((res) => res.json()).then((result) => {
      if (result.link) {
        window.open(API_ENDPOINT + result.link)
      }
    });
  }

  transferClinicGroup = (data) => {

    if (this.state.domen && this.state.domen != -1) {
      data.domen = this.state.domen
      if (this.state.emails && this.state.emails.length > 0) {
        data.emails = this.state.emails
      }
      if (this.state.usersForTransfer) {
        data.usersForTransfer = this.state.usersForTransfer
      }
      // console.log(data.usersForTransfer);
      this.setState({
        loading: true
      }, () => {

        fetch(API_ENDPOINT + '/domen-transfer/user-email-check', {
          method: 'POST',
          headers: {
            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            'content-type': 'application/json'
          },
          body: JSON.stringify(data)
        }).then(res => res.json()).then((result) => {
          if (result) {
            if (result.usersArr && result.usersArr.length > 0) {
              this.setState({
                arrUsers: result.usersArr,
                loading: false
              })
            } else if (result.message == 'ok') {
              // transfer
              // console.log('idemoooo')
              fetch(API_ENDPOINT + '/domen-transfer/clinic-group', {
                method: 'POST',
                headers: {
                  'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                  'content-type': 'application/json'
                },
                body: JSON.stringify(data)
              }).then(res => res.json()).then((result) => {
                if (result) {

                  this.setState({
                    transferModal: false,
                    questionaries: null,
                    domen: null,
                    arrUsers: [],
                    values: [],
                    usersForTransfer: {},
                    newUsernames: result.length ? result : []
                  })
                }
                this.get();
              })
            }

          }
        })
      })
    } else {
      this.setState({
        error: 'You have to select domen'.translate(this.props.lang)
      })
    }

  }





  exportClinicsStatistic = (listOfGroups, dateFrom, dateTo, exportAll/*, email*/) => {
    // console.log(email)
    // if (email != undefined) {
    fetch(API_ENDPOINT + "/data/clinic-groups/statistic/export", {
      method: "POST",
      headers: {
        Authorization:
          typeof localStorage !== "undefined"
            ? `Bearer ${localStorage.getItem("authToken")}`
            : null,
        "content-type": "application/json",
      },
      body: JSON.stringify({ group: listOfGroups, dateFrom: dateFrom, dateTo: dateTo, exportAll: exportAll/*, email: email*/, dataStorage: true }),
    }).then(res => res.json()).then((result) => {
      if (result) {

        this.setState({
          exportClinicsModal: false,
          questionaries: false,
          listOfGroups: false,
          emailSendStatistic: '',
          startDateSms: this.props.getStringDateTs(Math.floor(new Date().getTime() / 1000), 'YYYY-MM-DD')/*moment.unix(Math.floor(new Date().getTime() / 1000)).format('YYYY-MM-DD')*/,
          endDateSms: this.props.getStringDateTs(Math.floor(new Date().getTime() / 1000), 'YYYY-MM-DD')/*moment.unix(Math.floor(new Date().getTime() / 1000)).format('YYYY-MM-DD')*/
        })
      }
      this.get();

      if (result.link) {
        window.open(API_ENDPOINT + result.link)
      }

    })
    // } else {
    //   this.setState({
    //     error: 'You must enter an email'.translate(this.props.lang)
    //   })
    // }

  }
  pickUsers = (data) => {
    fetch(API_ENDPOINT + "/domen-transfer/pick-users", {
      method: "POST",
      headers: {
        Authorization:
          typeof localStorage !== "undefined"
            ? `Bearer ${localStorage.getItem("authToken")}`
            : null,
        "content-type": "application/json",
      },
      body: JSON.stringify({ groups: data }),
    }).then(res => res.json()).then((result) => {
      if (result) {
        let usersForTransfer = this.state.usersForTransfer
        for (let i = 0; i < Object.keys(result).length; i++) {
          usersForTransfer[Object.keys(result)[i]] = []

        }
        console.log(result);

        this.setState({
          clinicGroup: Object.keys(result)[0],
          pickUsersModal: result,
          usersForTransfer
        })
      }

    })
  }
  removeConnectionsWebDoc = () => {
    if (this.state.form && this.state.form.webdoc) {
      this.setState(
        {
          loading: true,
          _saving: true,
          loaderWebDocSave: true
        },
        () => {
          fetch(API_ENDPOINT + "/data/clinic-groups/remove/web-doc/" + this.state.form._id, {
            method: "POST",
            headers: {
              Authorization:
                typeof localStorage !== "undefined"
                  ? `Bearer ${localStorage.getItem("authToken")}`
                  : null,
              "content-type": "application/json",
            },
            body: JSON.stringify({}),
          })
            .then((res) => res.json())
            .then((result) => {
              if (result.error) {
                this.setState({
                  error: result.error.translate(this.props.lang),
                  loading: null,
                  loaderWebDocSave: null

                });
              } else {
                this.get();
                this.setState({
                  _saving: false,
                  integrationModal: null,
                  showRemoveConnection: false,
                  loaderWebDocSave: null
                }, () => {
                  setTimeout(() => {
                    this.setState({ _saving: false, webDocModal: null, error: null })
                  }, 1000)
                })
              }
            });
        }
      );
    }
  }
  removeConnectionsTeleq = () => {
    if (this.state.form && this.state.form.teleq) {
      this.setState(
        {
          loading: true,
          _saving: true,
          loaderTeleqSave: true
        },
        () => {
          fetch(API_ENDPOINT + "/data/clinic-groups/remove/teleq/" + this.state.form._id, {
            method: "POST",
            headers: {
              Authorization:
                typeof localStorage !== "undefined"
                  ? `Bearer ${localStorage.getItem("authToken")}`
                  : null,
              "content-type": "application/json",
            },
            body: JSON.stringify({}),
          })
            .then((res) => res.json())
            .then((result) => {
              if (result.error) {
                this.setState({
                  error: result.error.translate(this.props.lang),
                  loading: null,
                  loaderTeleqSave: null

                });
              } else {
                this.get();
                this.setState({
                  _saving: false,
                  integrationModal: null,
                  showRemoveConnection: false,
                  loaderTeleqSave: null
                }, () => {
                  setTimeout(() => {
                    this.setState({ _saving: false, teleqModal: null, error: null })
                  }, 1000)
                })
              }
            });
        }
      );
    }
  }


  removeConnectionsTakeCare = () => {
    if (this.state.form && this.state.form.takeCare) {
      this.setState({
        loading: true,
        _saving: true,
        loaderWebDocSave: true
      }, () => {
        fetch(API_ENDPOINT + "/data/clinic-groups/remove/take-care/" + this.state.form?._id, {
          method: "POST",
          headers: {
            Authorization:
              typeof localStorage !== "undefined"
                ? `Bearer ${localStorage.getItem("authToken")}`
                : null,
            "content-type": "application/json",
          },
          body: JSON.stringify({}),
        }).then(response => response.json()).then(result => {
          let error = null;
          if (result?.error) error = result.error?.translate(this.props?.lang);
          this.setState({
            error,
            loading: null,
            loaderWebDocSave: null,
            _saving: null
          }, () => {
            this.get();
            this.setState({
              _saving: false,
              integrationModal: null,
              showRemoveConnection: false,
              loaderWebDocSave: null
            }, () => {
              setTimeout(() => {
                this.setState({ _saving: false, webDocModal: null, error: null })
              }, 1000)
            });
          })
        }).catch(err => this.setState({
          error: err?.message?.translate(this.props?.lang) || 'Something went wrong! Could not finish request'.translate(this.props?.lang)
        }))
      })
    }
  }

  statisticGrafic = (item) => {
    fetch(API_ENDPOINT + "/data/statistic/clinic-groups", {
      method: "POST",
      headers: {
        Authorization:
          typeof localStorage !== "undefined"
            ? `Bearer ${localStorage.getItem("authToken")}`
            : null,
        "content-type": "application/json",
      },
      body: JSON.stringify({ group: item._id }),
    }).then(res => res.json()).then((result) => {
      // console.log(result);
      if (result) {
        this.setState({ dataUsedModal: result, group: result._id },
          () => this.get())
      }

    })

  }

  render() {
    let params = {};
    if (this.state.useState) {
      params = this.state.params;
    } else {
      params = getSearchParams(this.props[0].location.search, {
        entries: 10,
        page: 0,
      });
    }

    let subgroups = [{ name: "None", value: null }];

    if (this.props.group) {
      for (let i = 0; i < this.state.subgroups.length; i++) {
        if (
          this.state.subgroups[i].groups &&
          this.state.subgroups[i].groups.indexOf(this.props.group) !== -1
        ) {
          subgroups.push(this.state.subgroups[i]);
        }
      }
    }

    return (
      <div className={this.props.autoHideSidebar ? "dashboard dashboard-auto-hide" : "dashboard"}>

        <Container fluid>

          <Row>
            <Col lg="12">
              {
                this.state.loadingExport ?
                  <div className="export-loader-wrap">
                    <div className="loader-wrap">
                      <Player
                        autoplay={true}
                        loop={true}
                        renderer={'svg'}
                        src="/lf30_editor_l5cxzdyf.json"
                        style={{ height: typeof window != 'undefined' && window.innerWidth < 768 ? "128px" : "256px", width: typeof window != 'undefined' && window.innerWidth < 768 ? "128px" : "256px" }}
                      ></Player>

                    </div>
                  </div>
                  :
                  null
              }
              <div className="panel">
                <div className="panel-header clinic-panel-header">
                  <h5 className="header-component">
                    {this.props.linksMeta &&
                      this.props.linksMeta[this.props[0].location.pathname]
                      ? this.props.linksMeta[this.props[0].location.pathname][
                      this.props.lang
                      ]
                      : ""}{" "}
                  </h5>{" "}
                  <div className="clinic-group-header-buttons">
                    <div style={{ marginRight: 10 }}>
                      <button
                        className="btn add-button"
                        onClick={() => {
                          this.setState({
                            transferModal: true,
                          })
                        }
                        }
                      >
                        <Isvg src={importIcon} /> {"Transfer".translate(this.props.lang)}
                      </button>
                    </div>
                    <div style={{ marginRight: 10 }}>
                      <button
                        className="btn add-button"
                        onClick={() => {
                          this.setState({ importModal: true })
                        }
                        }
                      >
                        <Isvg src={importIcon} /> {"Import".translate(this.props.lang)}
                      </button>
                    </div>
                    <div style={{ marginRight: 10 }}>
                      <button
                        className="btn add-button"
                        onClick={() =>
                          this.setState({
                            form: {},
                          })
                        }
                      >
                        <Isvg src={addIcon} /> {"Add".translate(this.props.lang)}
                      </button>
                    </div>
                    <button
                      className="btn add-button"
                      onClick={() =>
                        this.setState({
                          exportClinicsModal: true

                        })
                      }
                    >
                      <Isvg src={addIcon} /> {"Export".translate(this.props.lang)}
                    </button>
                  </div>

                </div>
                <ListBuilder
                  lang={this.props.lang}
                  loading={this.state.loading}
                  total={this.state.total}
                  showNumeration={false}
                  className='list-builder-select'
                  selectLabela="Main clinic"
                  selectData={[{ name: 'Select'.translate(this.props.lang), value: "-1" }, ...this.state.mainClinicGroups && this.state.mainClinicGroups.map(item => {
                    return { name: item.name, value: item._id }
                  })]}
                  onSelectChange={(value) => {
                    this.setState({
                      selectedMainClinicGroups: value
                    }, () => {
                      this.get()
                    })
                  }}

                  selectedValue={this.state.selectedMainClinicGroups}
                  fields={[
                    { type: "text", name: "name", label: "Group name".translate(this.props.lang), allowSort: true, },
                    { type: "text", name: "createReferrals", label: "Create E-referrals".translate(this.props.lang), allowSort: true, },
                    { type: "text", name: "receiveReferrals", label: "Receive E-referrals".translate(this.props.lang), allowSort: true, },
                    { type: "text", name: "personalReferrals", label: "Self E-referrals".translate(this.props.lang), allowSort: true, },
                    { type: "text", name: "doctors", label: "Number of caregivers".translate(this.props.lang), },
                    { type: "text", name: "appUpdated", label: "App updated".translate(this.props.lang), allowSort: true, },
                    // { type: "text", name: "iosUpdated", label: "iOS updated".translate(this.props.lang), },
                    // { type: 'text', name: 'size', label: 'Data used'.translate(this.props.lang), allowSort: true },
                    // { type: 'text', name: 'smsThisMonth', label: 'SMS this month'.translate(this.props.lang), allowSort: true },
                    // { type: 'text', name: 'smsLast3Month', label: 'SMS last quarter'.translate(this.props.lang), allowSort: true },
                    // { type: 'text', name: 'allSms', label: 'Total sms'.translate(this.props.lang), allowSort: true },
                    // { type: 'text', name: 'loginsThisMonth', label: 'Logins this month'.translate(this.props.lang), allowSort: true },
                    // { type: 'text', name: 'loginsLast3Month', label: 'Logins last quarter'.translate(this.props.lang), allowSort: true },
                    // { type: 'text', name: 'allLogins', label: 'Total logins'.translate(this.props.lang), allowSort: true },
                    // { type: 'text', name: 'webdoc', label: 'WebDoc'.translate(this.props.lang), allowSort: true },
                  ]}
                  items={this.state.items.map((item) => {
                    let androidUpdated = this.props.lang === 'en' && item.appAndroidUpdatedStringEn ? item.appAndroidUpdatedStringEn : this.props.lang === 'se' && item.appAndroidUpdatedStringSv ? item.appAndroidUpdatedStringSv : item.appAndroidUpdated ? moment.unix(item.appAndroidUpdated).format(`${this.props.dateFormat} HH:mm`) : '/';
                    let iosUpdated = this.props.lang === 'en' && item.appIosUpdatedStringEn ? item.appIosUpdatedStringEn : this.props.lang === 'se' && item.appIosUpdatedStringSv ? item.appIosUpdatedStringSv : item.appIosUpdated ? moment.unix(item.appIosUpdated).format(`${this.props.dateFormat} HH:mm`) : '/';
                    return {
                      ...item,
                      createReferrals: (<div className=""><Checkbox checked={item.createReferrals} key={item._id} /></div>),
                      size: item.size != '0' ? <Button color='#fff' style={{ width: 150 }} className="patient-modal-button" onClick={() => this.setState({ dataUsedModal: item })}>{item.size} <Isvg src={Usage} className="pie-icon" /></Button> : item.size,
                      receiveReferrals: (
                        <div className="">
                          <Checkbox
                            checked={item.receiveReferrals}
                            key={item._id}
                          />
                        </div>
                      ),
                      personalReferrals: (
                        <div className="">
                          <Checkbox
                            checked={item.personalReferrals}
                            key={item._id}
                          />
                        </div>
                      ),
                      appUpdated: <div className="app-center-update-info-wrap">
                        <div><span>{"Android".translate(this.props.lang)}{item.appAndroidVersion ? ` (${item.appAndroidVersion})` : ''}:</span> {androidUpdated}</div>
                        <div><span>{"iOS".translate(this.props.lang)}{item.appIosVersion ? ` (${item.appIosVersion})` : ''}:</span> {iosUpdated}</div>
                      </div>
                      // webdoc: (
                      //   <div className="">
                      //     <Button color="primary" onClick={(item) => {
                      //       this.setState({ webDocModal: item })
                      //     }}>WebDoc</Button>
                      //   </div>
                      // )
                    };
                  })}
                  rawItems={this.state.items}
                  actions={[
                    // {
                    //   component: <Button color="primary" style={{ marginRight: 10 }}>{"WebDoc".translate(this.props.lang)}</Button>,
                    //   onClick: (item) => {
                    //     console.log(item)
                    //     this.setState({ webDocModal: item })
                    //   },
                    // },
                    {
                      component: <Isvg src={Usage} className="edit-icon" />,
                      onClick: (item) => this.statisticGrafic(item),
                    },
                    {
                      component: <Isvg src={edit} className="edit-icon" />,
                      onClick: (item) => this.setState({ form: item }, () => {
                        if (this.state.form && this.state.form.webdoc) {
                          let data = {

                            api: this.state.form.webdoc.api,
                            clientId: this.state.form.webdoc.clientId,
                            clientSecret: this.state.form.webdoc.clientSecret,
                          };
                          this.checkFetchClinics(data);
                        } else {
                          if (this.state.showRemoveConnection) {
                            this.setState({ showRemoveConnection: false })
                          }
                        }

                      }),
                    },
                    {
                      component: (
                        <Isvg src={garabage} className="delete-icon" />
                      ),
                      onClick: (item) => this.setState({ deleteModal: item }),
                    },
                    {
                      component: <Isvg src={exportIcon} className="export-icon" />,
                      onClick: (item) => this.export(item),
                    },
                  ]}
                  params={params}
                  sortField={params.sortField}
                  sortType={params.sortType}
                  updateSort={this.updateSort}
                  updateParams={this.updateParams}
                  updateMultipleParams={this.updateMultipleParams}
                ></ListBuilder>
                <DeleteModal
                  lang={this.props.lang}
                  isOpen={this.state.deleteModal}
                  toggle={() => this.setState({ deleteModal: null })}
                  handler={() => {
                    this.delete(this.state.deleteModal);
                    this.setState({ deleteModal: null });
                  }}
                >
                  {"Delete group".translate(this.props.lang)}{" "}
                  <strong>
                    {this.state.deleteModal && this.state.deleteModal.name}
                  </strong>
                  ?
                </DeleteModal>

              </div>
            </Col>
          </Row>
        </Container>
        {this.state.form ? (
          <Modal centered
            isOpen={this.state.form}
            toggle={() =>
              this.setState({ form: !this.state.form, error: null, webDocClinics: null, takeCareClinics: null, teleqUnits: null })
            }
            size="xl"
            className="clinic-groups-modal"
          >
            <ModalHeader
              close={
                <button
                  type="button"
                  className="close"
                  style={{ position: "absolute", top: "15px", right: "15px" }}
                  onClick={() =>
                    this.setState({ form: !this.state.form, error: null, webDocClinics: null, takeCareClinics: null, teleqUnits: null })
                  }
                >
                  <Isvg src={xIcon} />
                </button>
              }
            >
              {"Create group".translate(this.props.lang)}
            </ModalHeader>
            <ModalBody>
              <Row>
                <Col lg="6" className="clinic-groups-left">
                  <Row>
                    <Col lg="12">
                      <FormGroup>
                        <Label size="sm">
                          {"Group name".translate(this.props.lang)}
                        </Label>
                        <Input
                          invalid={this.state.errors.name}
                          size="sm"
                          type="text"
                          onChange={(e) => {
                            let form = this.state.form;
                            form.name = striptags(e.target.value);
                            this.setState({
                              form,
                            });
                          }}
                          value={this.state.form.name}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label size="sm">
                          {"SMS sender name".translate(this.props.lang)}
                        </Label>
                        <Input
                          invalid={this.state.errors.smsName}
                          maxLength="11"
                          size="sm"
                          type="text"
                          onChange={(e) => {
                            let form = this.state.form;
                            form.smsName = striptags(e.target.value)
                              .replace(/Å/g, "A")
                              .replace(/Ä/g, "A")
                              .replace(/Ö/g, "O")
                              .replace(/å/g, "a")
                              .replace(/ä/g, "a")
                              .replace(/ö/g, "o")
                              .replace(/[^a-zA-Z0-9]/gi, "")
                              .replace(/-+/g, "");
                            if (form.smsName.length > 11) {
                              return;
                            }
                            this.setState({
                              form,
                            });
                          }}
                          value={this.state.form.smsName}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label size="sm">
                          {"E-mail sender name".translate(this.props.lang)}
                        </Label>
                        <Input
                          invalid={this.state.errors.emailName}
                          size="sm"
                          type="text"
                          onChange={(e) => {
                            let form = this.state.form;
                            form.emailName = striptags(e.target.value);
                            this.setState({
                              form,
                            });
                          }}
                          value={this.state.form.emailName}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label size="sm">
                          {"Landing page link".translate(this.props.lang)}
                        </Label>
                        <div
                          style={{
                            display: "flex",
                            fontSize: 14,
                            alignItems: "center",
                          }}
                        >
                          {" "}
                          {window.location.origin + '/'}
                          <Input
                            invalid={this.state.errors.alias}
                            style={{ marginLeft: 5 }}
                            size="sm"
                            type="text"
                            onChange={(e) => {
                              let form = this.state.form;
                              form.alias = striptags(e.target.value);
                              this.setState({
                                form,
                              });
                            }}
                            value={this.state.form.alias}
                          />
                        </div>
                      </FormGroup>



                      <FormGroup>
                        <Label size="sm">
                          {"Login page link".translate(this.props.lang)}
                        </Label>
                        <Input
                          disabled
                          style={{ marginLeft: 5 }}
                          size="sm"
                          type="text"
                          value={
                            this.state.form.alias
                              ? `${window.location.origin}/${this.state.form.alias}/login`
                              : ""
                          }
                        />
                      </FormGroup>

                      {/* <FormGroup>
                        <Label size="sm">
                          {"Webdoc clinic id".translate(this.props.lang)}
                        </Label>
                        <Input

                          style={{ marginLeft: 5 }}
                          size="sm"
                          type="text"
                          value={this.state.form.webdoc && this.state.form.webdoc.cid}
                          onChange={(e) => {
                            let form = this.state.form;
                            if (form.webdoc) {
                              form.webdoc.cid = e.target.value;
                            }
                            else {
                              form.webdoc = {};
                              form.webdoc.cid = e.target.value;
                            }
                            this.setState({
                              form,
                            });
                          }}
                        />
                      </FormGroup> */}


                      <FormGroup>
                        <Label>
                          {"Licence type".translate(this.props.lang)}
                        </Label>
                        <Input
                          type="select"
                          onChange={(e) => {
                            let form = this.state.form;
                            form.licence = e.target.value;
                            this.setState({
                              form,
                            });
                          }}
                          value={this.state.form.licence ? this.state.form.licence : 10}
                        >
                          <option value={10}>
                            {"Basic (Max 10)".translate(this.props.lang)}
                          </option>
                          <option value={-1}>
                            {"Unlimited".translate(this.props.lang)}
                          </option>
                        </Input>
                      </FormGroup>


                      <FormGroup>
                        <Label>
                          {"Licence type video chat".translate(this.props.lang)}
                        </Label>
                        <Input
                          type="select"
                          onChange={(e) => {
                            let form = this.state.form;
                            form.videoLicence = e.target.value;
                            this.setState({
                              form,
                            });
                          }}
                          value={this.state.form.videoLicence ? this.state.form.videoLicence : 0}
                        >
                          <option value={0}>
                            {"None".translate(this.props.lang)}
                          </option>
                          <option value={2}>
                            {"Basic (Max 2)".translate(this.props.lang)}
                          </option>
                          <option value={5}>
                            {"Standard (Max 5)".translate(this.props.lang)}
                          </option>
                          <option value={10}>
                            {"Pro (Max 10)".translate(this.props.lang)}
                          </option>
                          <option value={15}>
                            {"Custom 1 (Max 15)".translate(this.props.lang)}
                          </option>
                          <option value={20}>
                            {"Custom 2 (Max 20)".translate(this.props.lang)}
                          </option>
                          <option value={25}>
                            {"Custom 3 (Max 25)".translate(this.props.lang)}
                          </option>
                          <option value={30}>
                            {"Custom 4 (Max 30)".translate(this.props.lang)}
                          </option>
                        </Input>
                      </FormGroup>
                      <FormGroup>
                        <Label>
                          {"Delete inactive patient accounts period".translate(this.props.lang)}
                        </Label>
                        <Input
                          type="number"
                          max={1095}
                          min={30}
                          onChange={(e) => {
                            let form = this.state.form;
                            form.counterOfDays = e.target.value;
                            this.setState({
                              form,
                            });
                          }}
                          onBlur={(e) => {
                            if (e.target.value == 0 || e.target.value == '') {
                              let form = this.state.form;
                              form.counterOfDays = 180;
                              this.setState({
                                form,
                              });
                            } else if (e.target.value > 1095) {
                              let form = this.state.form;
                              form.counterOfDays = 1095;
                              this.setState({
                                form,
                              });
                            } else if (e.target.value < 30) {
                              let form = this.state.form;
                              form.counterOfDays = 30;
                              this.setState({
                                form,
                              });
                            }

                          }}
                          value={this.state.form.counterOfDays}
                        >
                          {/* <option value={180}>
                            {"Standard".translate(this.props.lang)}
                          </option>
                          <option value={1}>
                            {"For test 1 day".translate(this.props.lang)}
                          </option>
                          <option value={30}>
                            {"30 days".translate(this.props.lang)}
                          </option>
                          <option value={180}>
                            {"180 days".translate(this.props.lang)}
                          </option>
                          <option value={1095}>
                            {"1095 days".translate(this.props.lang)}
                          </option> */}

                        </Input>
                      </FormGroup>
                      <FormGroup>
                        <Label>
                          {"Use video link with:".translate(this.props.lang)}
                        </Label>
                        <Input
                          type="select"
                          onChange={(e) => {
                            let form = this.state.form;
                            form.videoLink = e.target.value;
                            this.setState({
                              form,
                            });
                          }}
                          value={this.state.form.videoLink ? this.state.form.videoLink : 0}
                        >
                          <option value={0}>
                            {"Both".translate(this.props.lang)}
                          </option>
                          <option value={1}>
                            {"Login name".translate(this.props.lang)}
                          </option>
                          <option value={2}>
                            {"BankID login".translate(this.props.lang)}
                          </option>


                        </Input>
                      </FormGroup>
                      <FormGroup >
                        <Checkbox
                          onChange={(e) => {
                            let form = this.state.form;
                            form.videoCallTermsOfUse = e.target.checked;
                            this.setState({
                              form,
                            });
                          }}
                          checked={this.state.form.videoCallTermsOfUse}
                          label={"Terms of Service when entering a video call".translate(this.props.lang)}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label>
                          {"Type:".translate(this.props.lang)}
                        </Label>
                        <Input
                          type="select"
                          onChange={(e) => {
                            let form = this.state.form;
                            form.type = e.target.value;
                            if ([0, '0'].includes(e?.target?.value)) {
                              form.medicalStatments = false;
                              form.twoFactorAuthentication = false;
                              form.skip2faVerification = false;
                            }
                            this.setState({
                              form
                            });
                          }}
                          value={this.state.form?.type ? this.state.form.type : 0}
                        >
                          <option value={0}>
                            {"Standard".translate(this.props.lang)}
                          </option>
                          <option value={1}>
                            {"Standard & Patient search".translate(this.props.lang)}
                          </option>
                          <option value={2}>
                            {"Enterprise".translate(this.props.lang)}
                          </option>


                        </Input>
                      </FormGroup>



                      <h6>{'General Settings'.translate(this.props.lang)}</h6>

                      <FormGroup >
                        <Checkbox
                          onChange={(e) => {
                            let form = this.state.form;
                            form.personalReferrals = e.target.checked;
                            this.setState({
                              form,
                            });
                          }}
                          checked={this.state.form.personalReferrals}
                          label={"E-egenremiss".translate(this.props.lang)}
                        />
                      </FormGroup>
                      <FormGroup >
                        <Checkbox
                          onChange={(e) => {
                            let form = this.state.form;
                            form.haveTranslation = e.target.checked;
                            this.setState({
                              form,
                            });
                          }}
                          checked={this.state.form.haveTranslation}
                          label={"Google translate".translate(this.props.lang)}
                        />
                      </FormGroup>
                      <FormGroup >
                        <Checkbox
                          onChange={(e) => {
                            let form = this.state.form;
                            form.listing = e.target.checked;
                            this.setState({
                              form,
                            });
                          }}
                          checked={this.state.form.listing}
                          label={"Listing".translate(this.props.lang)}
                        />
                      </FormGroup>
                      <FormGroup >
                        <Checkbox
                          onChange={(e) => {
                            let form = this.state.form;
                            form.patientLevel = e.target.checked;
                            this.setState({
                              form,
                            });
                          }}
                          checked={this.state.form.patientLevel}
                          label={"Patient level".translate(this.props.lang)}
                        />
                      </FormGroup>

                      <FormGroup >
                        <Checkbox
                          onChange={(e) => {
                            let form = this.state.form;
                            form.keepingForms = e.target.checked;
                            this.setState({
                              form,
                            });
                          }}
                          checked={this.state.form.keepingForms}
                          label={"Save and resume forms".translate(this.props.lang)}
                        />
                      </FormGroup>

                      <FormGroup >
                        <Checkbox
                          onChange={(e) => {
                            let form = this.state.form;
                            form.hideWhoIsTheCaseRegarding = e.target.checked;
                            this.setState({
                              form,
                            });
                          }}
                          checked={this.state.form.hideWhoIsTheCaseRegarding}
                          label={"Hide 'Who is the case regarding?'".translate(this.props.lang)}
                        />
                      </FormGroup>

                      <FormGroup >
                        <Checkbox
                          onChange={(e) => {
                            let form = this.state.form;
                            form.hideEmergencyCall = e.target.checked;
                            this.setState({
                              form,
                            });
                          }}
                          checked={this.state.form.hideEmergencyCall}
                          label={"Hide Emergency Services Actions".translate(this.props.lang)}
                        />
                      </FormGroup>

                      <FormGroup >
                        <Checkbox
                          onChange={(e) => {
                            let form = this.state.form;
                            form.askForMedicalRecordPermission = e.target.checked;
                            this.setState({
                              form,
                            });
                          }}
                          checked={this.state.form.askForMedicalRecordPermission}
                          label={"Ask for medical record permission".translate(this.props.lang)}
                        />
                      </FormGroup>

                      {
                        [1, '1', 2, '2'].includes(this.state.form?.type) ?
                          <FormGroup >
                            <Checkbox
                              onChange={(e) => {
                                let form = this.state.form;
                                form.medicalStatments = e.target.checked;
                                this.setState({
                                  form,
                                });
                              }}
                              checked={this.state.form.medicalStatments}
                              label={"Medical statments".translate(this.props.lang)}
                            />
                          </FormGroup>
                          :
                          null

                      }
                      <FormGroup >
                        <Checkbox
                          onChange={(e) => {
                            let form = this.state.form;
                            form.linksToForms = e.target.checked;
                            this.setState({
                              form,
                            });
                          }}
                          checked={this.state.form.linksToForms}
                          label={"Links to forms".translate(this.props.lang)}
                        />
                      </FormGroup>
                      <FormGroup >
                        <Checkbox
                          onChange={(e) => {
                            let form = this.state.form;
                            form.hideFormSearchFild = e.target.checked;
                            this.setState({
                              form,
                            });
                          }}
                          checked={this.state.form.hideFormSearchFild}
                          label={"Hide form search field".translate(this.props.lang)}
                        />
                      </FormGroup>


                      <h6>{'User Authentication Methods'.translate(this.props.lang)}</h6>
                      <FormGroup>
                        <Checkbox
                          onChange={e => {
                            if ([0, '0'].includes(this.state.form?.type)) return;
                            const checked = e?.target?.checked;
                            this.setState(prev => ({
                              ...prev,
                              form: {
                                ...(prev.form || {}),
                                twoFactorAuthentication: checked,
                                skip2faVerification: !checked ? false : (prev?.form?.skip2faVerification ?? false)
                              }
                            }))
                          }}
                          disabled={[0, '0'].includes(this.state?.form?.type)}
                          checked={this.state.form.twoFactorAuthentication}
                          label={'Enable Two factor authentication'.translate(this.props.lang)}
                        />


                      </FormGroup>
                      {this.state.form?.twoFactorAuthentication ? <FormGroup className='skip-2fa-checkbox-container'>
                        <Checkbox
                          onChange={e => {
                            if ([0, '0'].includes(this.state.form?.type) || !this.state.form?.twoFactorAuthentication) return;
                            const checked = e?.target?.checked;
                            this.setState(prev => ({
                              ...prev,
                              form: {
                                ...(prev.form || {}),
                                skip2faVerification: checked,
                              }
                            }))
                          }}
                          disabled={[0, '0'].includes(this.state?.form?.type)}
                          checked={this.state.form.skip2faVerification}
                          label={'Skip Two factor authentication verification'.translate(this.props.lang)}

                        />
                      </FormGroup> : null}
                      <FormGroup>
                        <Checkbox
                          onChange={e => {
                            this.setState(prev => ({
                              ...prev,
                              form: {
                                ...(prev.form || {}),
                                bankIdAuthentication: e?.target?.checked,
                              }
                            }))
                          }}
                          checked={this.state.form.bankIdAuthentication}
                          label={'Enable BankID authentication'.translate(this.props.lang)}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Checkbox
                          onChange={e => {
                            this.setState(prev => ({
                              ...prev,
                              form: {
                                ...(prev.form || {}),
                                netIdAuthentication: e?.target?.checked,
                              }
                            }))
                          }}
                          checked={this.state.form.netIdAuthentication}
                          label={'Enable netID (SITHS) authentication'.translate(this.props.lang)}
                        />
                      </FormGroup>

                      {/* <FormGroup >
                        <Checkbox
                          onChange={(e) => {
                            let form = this.state.form;
                            form.sarchPatients = e.target.checked;
                            this.setState({
                              form,
                            });
                          }}
                          checked={this.state.form.sarchPatients}
                          label={"Patient search".translate(this.props.lang)}
                        />
                      </FormGroup>

                      <FormGroup >
                        <Checkbox
                          onChange={(e) => {
                            let form = this.state.form;
                            form.enterprise = e.target.checked;
                            this.setState({
                              form,
                            });
                          }}
                          checked={this.state.form.enterprise}
                          label={"Enterprise".translate(this.props.lang)}
                        />
                      </FormGroup> */}

                      {/* <h6>{"E-remiss".translate(this.props.lang)}</h6>
                      <FormGroup >
                        <Checkbox
                          onChange={(e) => {
                            let form = this.state.form;
                            form.receiveReferrals = e.target.checked;
                            this.setState({
                              form,
                            });
                          }}
                          checked={this.state.form.receiveReferrals}
                          label={"Group can receive referrals".translate(this.props.lang)}
                        />
                      </FormGroup>

                      <FormGroup >
                        <Checkbox
                          onChange={(e) => {
                            let form = this.state.form;
                            form.createReferrals = e.target.checked;
                            this.setState({
                              form,
                            });
                          }}
                          checked={this.state.form.createReferrals}
                          label={"Group can create referrals".translate(this.props.lang)}
                        />
                      </FormGroup> */}
                      <h6>{'E-connect'.translate(this.props.lang)}</h6>
                      <FormGroup>
                        <Checkbox
                          onChange={e => {
                            let form = this.state.form;
                            form.eConnect = e.target.checked;
                            this.setState({
                              form
                            })
                          }}
                          checked={this.state.form?.eConnect}
                          label={'Groups can send and receive e-connect cases'.translate(this.props.lang)}
                        />
                      </FormGroup>
                      <h6>{"Notifications".translate(this.props.lang)}</h6>
                      <FormGroup >
                        <Checkbox
                          onChange={(e) => {
                            let form = this.state.form;
                            form.mailNotifications = e.target.checked;
                            this.setState({
                              form,
                            });
                          }}
                          checked={this.state.form.mailNotifications}
                          label={"E-mail".translate(this.props.lang)}
                        />
                      </FormGroup>

                      <FormGroup >
                        <Checkbox
                          onChange={(e) => {
                            let form = this.state.form;
                            form.smsNotifications = e.target.checked;
                            this.setState({
                              form,
                            });
                          }}
                          checked={this.state.form.smsNotifications}
                          label={"SMS".translate(this.props.lang)}
                        />
                      </FormGroup>
                      <FormGroup >
                        <Checkbox
                          onChange={(e) => {
                            let form = this.state.form;
                            form.pushNotifications = e.target.checked;
                            this.setState({
                              form,
                            });
                          }}
                          checked={this.state.form.pushNotifications}
                          label={"Web push".translate(this.props.lang)}
                        />
                      </FormGroup>
                      <FormGroup >
                        <Checkbox
                          onChange={(e) => {
                            let form = this.state.form;
                            form.systemNotifications = e.target.checked;
                            this.setState({
                              form,
                            });
                          }}
                          checked={this.state.form.systemNotifications}
                          label={"System".translate(this.props.lang)}
                        />
                      </FormGroup>

                      {this.state.form.admin ? (
                        <FormGroup className="group-admin">
                          <p>
                            <strong>
                              {"Admin:".translate(this.props.lang)}
                            </strong>{" "}
                            {this.props.formatName(this.state.form.admin.userData.title, this.state.form.admin.userData.name)} -{" "}
                            {this.state.form.admin.username}
                          </p>
                        </FormGroup>
                      ) : null}
                      <FormGroup className="group-admin-buttons" style={{ marginTop: 20 }}>
                        <Button
                          style={{ marginRight: 8, marginTop: 5 }}
                          size="sm"
                          onClick={() =>
                            this.setState({ selectClinicsModal: true })
                          }
                        >
                          {"Select clinics".translate(this.props.lang)}
                        </Button>
                        <Button
                          style={{ marginRight: 8, marginTop: 5 }}
                          color="primary"
                          size="sm"
                          onClick={() =>
                            this.setState({ selectAdminsModal: true })
                          }
                        >
                          {"Select admin".translate(this.props.lang)}
                        </Button>

                        <Button
                          style={{ marginRight: 8, marginTop: 5 }}
                          color="primary"
                          size="sm"
                          onClick={() =>
                            this.setState({ integrationModal: true })
                          }
                        >
                          {"Integration".translate(this.props.lang)}
                        </Button>
                        {
                          this.state.form._id ?
                            <Button
                              style={{ marginRight: 8, marginTop: 5 }}
                              size="sm"
                              onClick={() =>
                                this.setState({ selectMainClinicsGroupsModal: true })
                              }
                            >
                              {"Main clinic".translate(this.props.lang)}
                            </Button>
                            :
                            null
                        }

                        <Button
                          style={{ marginRight: 8, marginTop: 5 }}
                          size="sm"
                          onClick={() =>
                            this.setState({ appSettingsModal: true })
                          }
                        >
                          {"App settings".translate(this.props.lang)}
                        </Button>
                      </FormGroup>

                      <p className="error" style={{ color: "red" }}>
                        {this.state.error}
                      </p>
                    </Col>
                  </Row>
                </Col>
                <Col lg="6" className="clinic-groups-users-table">
                  <Row>
                    <Col lg="12">
                      <Image
                        onLoadImage={(loading) => {
                          this.setState({
                            loadingImage: loading
                          })
                        }}
                        group={this.state.form._id}
                        lang={this.props.lang}
                        onChange={(logo) => {
                          let form = this.state.form;
                          form.logo = logo;
                          this.setState({
                            form,
                          });
                        }}
                        value={this.state.form.logo}
                        label={"Clinic logo".translate(this.props.lang)}
                      />
                    </Col>
                    <div className="clinic-groups-clinics-table">
                      <Table responsive hover className={"cursor-row "} >
                        <thead>
                          <tr>
                            <th></th>
                            <th>{"Clinic name".translate(this.props.lang)}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.form.clinics &&
                            Object.values(this.state.form.clinics).map(
                              (item, idx) => {
                                return (
                                  <tr>
                                    <td>
                                      <Checkbox
                                        checked={true}
                                        onChange={(e) => {

                                          this.setState({ deleteClicnic: item._id });

                                        }}
                                      />
                                    </td>
                                    <td>{item.name}</td>
                                  </tr>
                                );
                              }
                            )}
                        </tbody>
                      </Table>
                    </div>
                    <FormGroup>
                      <Label>{'Privacy Policy'.translate(this.props.lang)}</Label>
                      <HTML height={400} value={this.state.form.privacyPolicyHTML} onChange={(text) => {
                        let form = this.state.form;
                        form.privacyPolicyHTML = text;

                        this.setState({
                          form
                        })
                      }} />
                    </FormGroup>
                    <FormGroup style={{ width: '100%', marginRight: 30 }}>
                      <Label>{'Tracking pixel'.translate(this.props.lang)}</Label>
                      <Input
                        size="sm"
                        style={{ height: 100 }}
                        type="textarea"
                        onChange={(e) => {
                          let form = this.state.form;
                          form.trackingPixel = e.target.value;
                          this.setState({
                            form,
                          });
                        }}
                        value={this.state.form.trackingPixel}
                      />


                    </FormGroup>
                    <FormGroup>




                      <h6>{"Payments".translate(this.props.lang)}</h6>

                      <FormGroup >
                        <Checkbox
                          onChange={(e) => {
                            let form = this.state.form;
                            form.canTransferPayments = e.target.checked;
                            if (!e.target.checked) {
                              form.exportPaymentData = false;
                            }
                            this.setState({
                              form,
                            });
                          }}
                          checked={this.state.form.canTransferPayments}
                          label={"Transfer Payments".translate(this.props.lang)}
                        />
                      </FormGroup>
                      {
                        this.state.form?.canTransferPayments ?
                          <FormGroup style={{ marginLeft: 30 }}>
                            <Checkbox
                              onChange={(e) => {
                                let form = this.state.form;
                                form.exportPaymentData = e.target.checked;
                                this.setState({
                                  form,
                                });
                              }}
                              checked={this.state.form.exportPaymentData}
                              label={"Export payment data".translate(this.props.lang)}
                            />
                          </FormGroup>
                          :
                          null
                      }
                      <FormGroup >
                        <Checkbox
                          onChange={(e) => {
                            let form = this.state.form;
                            form.displayPaymentInfoText = e.target.checked;
                            this.setState({
                              form,
                            });
                          }}
                          checked={this.state.form.displayPaymentInfoText}
                          label={"Display payment info text".translate(this.props.lang)}
                        />
                      </FormGroup>
                      <h6>{'Payments methods'.translate(this.props.lang)}</h6>
                      <FormGroup >
                        <Checkbox
                          onChange={(e) => {
                            let form = this.state.form;
                            form.canUsePaysonForPayment = e.target.checked;
                            if (!e.target.checked) {
                              form.canUsePaysonForPayment = false;
                            }
                            this.setState({
                              form,
                            });
                          }}
                          checked={this.state.form.canUsePaysonForPayment}
                          label={"Payson".translate(this.props.lang)}
                        />
                      </FormGroup>
                      <FormGroup >
                        <Checkbox
                          onChange={(e) => {
                            let form = this.state.form;
                            form.canUseSwishForPayment = e.target.checked;
                            if (!e.target.checked) {
                              form.canUseSwishForPayment = false;
                            }
                            this.setState({
                              form,
                            });
                          }}
                          checked={this.state.form.canUseSwishForPayment}
                          label={"Swish".translate(this.props.lang)}
                        />
                      </FormGroup>
                      <FormGroup >
                        <Checkbox
                          onChange={(e) => {
                            let form = this.state.form;
                            form.canUseStripeForPayment = e.target.checked;
                            if (!e.target.checked) {
                              form.canUseStripeForPayment = false;
                            }
                            this.setState({
                              form,
                            });
                          }}
                          checked={this.state.form.canUseStripeForPayment}
                          label={"Stripe".translate(this.props.lang)}
                        />
                      </FormGroup>
                    </FormGroup>
                  </Row>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <Button
                color="primary"
                size="sm"
                onClick={() => {
                  let errors = {};
                  let foundError = false;
                  if (!this.state.form.smsName) {
                    foundError = true;
                    errors.smsName = true;
                  }
                  if (!this.state.form.emailName) {
                    foundError = true;
                    errors.emailName = true;
                  }
                  if (!this.state.form.name) {
                    foundError = true;
                    errors.name = true;
                  }
                  if (!this.state.form.alias) {
                    foundError = true;
                    errors.alias = true;
                  }

                  if (foundError) {
                    this.setState({
                      errors,
                    });
                    return;
                  } else {
                    this.setState({
                      errors,
                    });
                  }

                  let data = {
                    smsName: this.state.form.smsName,
                    emailName: this.state.form.emailName,
                    counterOfDays: this.state.form.counterOfDays ? Number(this.state.form.counterOfDays) : 180,
                    name: this.state.form.name,
                    alias: this.state.form.alias,
                    licence: this.state.form.licence ? this.state.form.licence : this.state.licence,
                    videoLicence: this.state.form.videoLicence ? this.state.form.videoLicence : 0,
                    videoLink: this.state.form.videoLink ? this.state.form.videoLink : 0,
                    personalReferrals: this.state.form.personalReferrals,
                    haveTranslation: this.state.form.haveTranslation,
                    listing: this.state.form.listing,
                    keepingForms: this.state.form.keepingForms,
                    hideWhoIsTheCaseRegarding: this.state.form.hideWhoIsTheCaseRegarding,
                    hideEmergencyCall: this.state.form.hideEmergencyCall,
                    askForMedicalRecordPermission: this.state.form.askForMedicalRecordPermission,
                    medicalStatments: this.state.form.medicalStatments,
                    linksToForms: this.state.form.linksToForms,
                    hideFormSearchFild: this.state.form.hideFormSearchFild,
                    videoCallTermsOfUse: this.state.form.videoCallTermsOfUse,
                    patientLevel: this.state.form.patientLevel,
                    type: this.state.form.type ? this.state.form.type : 0,
                    twoFactorAuthentication: (this.state.form?.twoFactorAuthentication && ![0, '0'].includes(this.state?.form?.type)) ? true : false,
                    skip2faVerification: (this.state.form?.twoFactorAuthentication && ![0, '0'].includes(this.state?.form?.type) && this.state.form?.skip2faVerification) ? true : false,
                    bankIdAuthentication: this.state.form?.bankIdAuthentication ? true : false,
                    netIdAuthentication: this.state.form?.netIdAuthentication ? true : false,
                    eConnect: this.state.form?.eConnect ? true : false,
                    sarchPatients: this.state.form.type == 1 ? true : null,
                    enterprise: this.state.form.type == 2 ? true : null,
                    createReferrals: this.state.form.createReferrals,
                    receiveReferrals: this.state.form.receiveReferrals,
                    // selectMainClinicsGroupsModal: this.state.form.selectMainClinicsGroupsModal,
                    appSettings: this.state.form.appSettings,
                    smsNotifications: this.state.form.smsNotifications,
                    mailNotifications: this.state.form.mailNotifications,
                    pushNotifications: this.state.form.pushNotifications,
                    systemNotifications: this.state.form.systemNotifications,

                    canUsePaysonForPayment: this.state.form.canUsePaysonForPayment,
                    canUseSwishForPayment: this.state.form.canUseSwishForPayment,
                    canUseStripeForPayment: this.state.form.canUseStripeForPayment,

                    canTransferPayments: this.state.form.canTransferPayments,
                    exportPaymentData: this.state.form.exportPaymentData,
                    displayPaymentInfoText: this.state.form.displayPaymentInfoText,
                    privacyPolicyHTML: this.state.form.privacyPolicyHTML,
                    trackingPixel: this.state.form.trackingPixel,
                    // webdoc: this.state.form.webdoc
                    //   ? {
                    //     // cid: this.state.form.webdoc.cid,
                    //     api: this.state.form.webdoc.api,
                    //     clientId: this.state.form.webdoc.clientId,
                    //     clientSecret: this.state.form.webdoc.clientSecret
                    //   } : {},
                    webdoc: this.state.form.webdoc
                      ? {
                        api: this.state.form.webdoc.api,
                        clientId: this.state.form.webdoc.clientId,
                        clientSecret: this.state.form.webdoc.clientSecret,
                        clinics: this.state.form.webdoc.clinics && this.state.form.webdoc.api && this.state.form.webdoc.clientId && this.state.form.webdoc.clientSecret ? this.state.form.webdoc.clinics : [],
                        syncOldEvents: this.state.form.webdoc.syncOldEvents ? true : false,
                        syncFromDate: this.state.form.webdoc.syncFromDate,
                        active: this.state.form.webdoc.active ? true : false,
                        journalNoteOptionsExtended: this.state.form.webdoc.journalNoteOptionsExtended ? true : false,
                        showWebDocSettings: this.state.form.webdoc.showWebDocSettings ? true : false,
                        autoVisit: this.state.form.webdoc.autoVisit && this.state.form.webdoc.showWebDocSettings && this.state.form.webdoc.journalNoteOptionsExtended ? true : false,
                      } : {},
                    takeCare: this.state.form.takeCare ? {
                      api: this.state.form.takeCare.api,
                      clientId: this.state.form.takeCare.clientId,
                      clientSecret: this.state.form.takeCare.clientSecret,
                      clinics: this.state.form.takeCare.clinics && this.state.form.takeCare.api && this.state.form.takeCare.clientId && this.state.form.takeCare.clientSecret ? this.state.form.takeCare.clinics : [],
                      syncOldEvents: this.state.form.takeCare.syncOldEvents ? true : false,
                      syncFromDate: this.state.form.takeCare.syncFromDate,
                      active: this.state.form.takeCare.active ? true : false,
                    } : {},
                    teleq: this.state.form.teleq ? {
                      api: this.state.form.teleq.api,
                      clientId: this.state.form.teleq.clientId,
                      clientSecret: this.state.form.teleq.clientSecret,
                      clinics: this.state.form.teleq.clinics && this.state.form.teleq.api && this.state.form.teleq.clientId && this.state.form.teleq.clientSecret ? this.state.form.teleq.clinics : [],
                      active: this.state.form.teleq.active ? true : false,
                    } : {},

                    clinics: this.state.form.clinics
                      ? Object.values(this.state.form.clinics).map((clinic) => {
                        return clinic._id;
                      })
                      : [],
                    admin: this.state.form.admin
                      ? this.state.form.admin._id
                      : null,
                    logo: this.state.form.logo,
                  };
                  if (!data.twoFactorAuthentication && !data.bankIdAuthentication) return this.setState({ error: 'If both Two Factor Authentication and BankID Authentication are turned off - users cannot log in! Please turn on one of those options and then try again!' })
                  this.insertOrUpdate(data);
                }}
              >
                {
                  this.state.showPlayer ? (
                    <Player
                      onEvent={(event) => {
                        if (event === "load" && this.player && this.player.current) this.stopAnimation();
                      }}
                      onStateChange={this.toggleVisibility}
                      ref={this.player} // set the ref to your class instance
                      autoplay={true}
                      loop={true}
                      controls={true}
                      src="https://assets8.lottiefiles.com/packages/lf20_ZSRKCF.json"
                      style={{ height: "auto", width: "30px" }}
                    ></Player>

                  ) : this.state.showPlayer2 ? (
                    <div style={{ display: 'flex', alignItems: 'center' }}><Player
                      onStateChange={this.toggleVisibility}
                      ref={this.player2} // set the ref to your class instance
                      autoplay={true}
                      loop={true}
                      controls={true}
                      src="https://assets10.lottiefiles.com/packages/lf20_CxyxLk.json"
                      style={{ height: "auto", width: "30px" }}
                    ></Player>
                      {'Saved'.translate(this.props.lang)}
                    </div>
                  ) : (
                    "Save".translate(this.props.lang)
                  )
                }
                {/* {"Save".translate(this.props.lang)} */}
              </Button>
            </ModalFooter>
          </Modal>
        ) : null}
        {
          this.state.integrationModal ?
            <Modal centered
              isOpen={this.state.integrationModal}
              toggle={() =>
                this.setState({
                  integrationModal: !this.state.integrationModal,
                  integrationActive: 0
                })
              }
              size="xl"
            >
              <ModalHeader
                close={
                  <button
                    type="button"
                    className="close"
                    style={{ position: "absolute", top: "15px", right: "15px" }}
                    onClick={() =>
                      this.setState({
                        integrationModal: !this.state.integrationModal,
                        integrationActive: 0

                      })
                    }
                  >
                    <Isvg src={xIcon} />
                  </button>
                }
              >
                {"Integrations".translate(this.props.lang)}
              </ModalHeader>
              <ModalBody>
                <div className="integrations-wrap">
                  <div className={this.state.integrationActive == 1 ? 'active' : ''}
                    onClick={() => {
                      if (this.state.integrationActive != 1)
                        this.setState({ integrationActive: 1 })
                      else
                        this.setState({ integrationActive: 0 })
                    }}>
                    <img src={webdocImage} />
                    <h6>{"WebDoc".translate(this.props.lang)}</h6>
                  </div>
                  <div className={this.state.integrationActive == 2 ? 'active' : ''}
                    onClick={() => {
                      if (this.state.integrationActive != 2)
                        this.setState({ integrationActive: 2 })
                      else
                        this.setState({ integrationActive: 0 })
                    }}>
                    <img src={takeCareImage} />
                    <h6>{"TakeCare".translate(this.props.lang)}</h6>
                  </div>
                  <div className={this.state.integrationActive == 3 ? 'active' : ''}
                    onClick={() => {
                      if (this.state.integrationActive != 3)
                        this.setState({ integrationActive: 3 })
                      else
                        this.setState({ integrationActive: 0 })
                    }}>
                    <img src={teleqImage} />
                    <h6>{"TeleQ".translate(this.props.lang)}</h6>
                  </div>
                  <div className={this.state.integrationActive == 4 ? 'active' : ''}
                    onClick={() => {
                      if (this.state.integrationActive != 4)
                        this.setState({ integrationActive: 4 })
                      else
                        this.setState({ integrationActive: 0 })
                    }}>
                    <img src={sembleImage} />
                    <h6>{"Semble".translate(this.props.lang)}</h6>
                  </div>



                </div>
                {
                  this.state.integrationActive == 1 ?
                    <>
                      <Row key={1}>
                        <Col lg="12" className="clinic-groups-left">
                          <Row>
                            <Col lg="12">
                              <FormGroup>
                                <Toggle label={'Active'.translate(this.props.lang)} value={this.state.form.webdoc && this.state.form.webdoc.active} onChange={(e) => {
                                  let form = this.state.form;
                                  if (form.webdoc) {
                                    form.webdoc.active = !form.webdoc.active;
                                  }
                                  else {
                                    form.webdoc = {};
                                    form.webdoc.active = true;
                                  }
                                  this.setState({
                                    form,
                                  });
                                }} />
                              </FormGroup>
                              <FormGroup>
                                <Label size="sm">
                                  {"Group name".translate(this.props.lang)}
                                </Label>
                                <Input
                                  invalid={this.state.errors.name}
                                  size="sm"
                                  type="text"
                                  disabled

                                  value={this.state.form.name}
                                />
                              </FormGroup>
                              <FormGroup>
                                <Label size="sm">
                                  {"Webdoc clinic api".translate(this.props.lang)}
                                </Label>
                                <Input
                                  style={{ marginLeft: 5 }}
                                  size="sm"
                                  type="text"

                                  value={this.state.form.webdoc && this.state.form.webdoc.api}
                                  onChange={(e) => {
                                    let form = this.state.form;
                                    if (form.webdoc) {
                                      form.webdoc.api = striptags(e.target.value);
                                    }
                                    else {
                                      form.webdoc = {};
                                      form.webdoc.api = striptags(e.target.value);
                                    }
                                    this.setState({
                                      form
                                    });
                                  }}
                                />
                              </FormGroup>
                              <FormGroup>
                                <Label size="sm">
                                  {"Client id".translate(this.props.lang)}
                                </Label>
                                <Input

                                  style={{ marginLeft: 5 }}
                                  size="sm"
                                  type="text"
                                  value={this.state.form.webdoc && this.state.form.webdoc.clientId}
                                  onChange={(e) => {
                                    let form = this.state.form;
                                    if (form.webdoc) {
                                      form.webdoc.clientId = striptags(e.target.value);
                                    }
                                    else {
                                      form.webdoc = {};
                                      form.webdoc.clientId = striptags(e.target.value);
                                    }
                                    this.setState({
                                      form,
                                    });
                                  }}
                                />
                              </FormGroup>
                              <FormGroup>
                                <Label size="sm">
                                  {"Client secret".translate(this.props.lang)}
                                </Label>
                                <Input

                                  style={{ marginLeft: 5 }}
                                  size="sm"
                                  type="text"
                                  value={this.state.form.webdoc && this.state.form.webdoc.clientSecret}
                                  onChange={(e) => {
                                    let form = this.state.form;
                                    if (form.webdoc) {
                                      form.webdoc.clientSecret = striptags(e.target.value);
                                    }
                                    else {
                                      form.webdoc = {};
                                      form.webdoc.clientSecret = striptags(e.target.value);
                                    }
                                    this.setState({
                                      form,
                                    });
                                  }}
                                />
                              </FormGroup>

                              {this.state.webDocClinics && this.state.form.webdoc && this.state.form.webdoc.api && this.state.form.webdoc.clientId && this.state.form.webdoc.clientSecret && Object.keys(this.state.form.clinics).map((keyName, i) => (
                                <Row>
                                  <Col lg="4">
                                    <FormGroup className="group-admin-buttons" style={{ marginTop: 20 }}>
                                      <Label size="sm">
                                        {"Clinic".translate(this.props.lang)}
                                      </Label>
                                      <Input

                                        style={{ marginLeft: 5 }}
                                        size="sm"
                                        type="text"
                                        value={this.state.form.clinics[keyName].name}
                                        disabled
                                      >
                                      </Input>
                                    </FormGroup>

                                  </Col>
                                  <Col lg="4">
                                    <FormGroup className="group-admin-buttons" style={{ marginTop: 20 }}>


                                      <FormGroup>
                                        <Label size="sm">
                                          {"WebDoc clinic".translate(this.props.lang)}
                                        </Label>
                                        <Input

                                          style={{ marginLeft: 5 }}
                                          size="sm"
                                          type="select"
                                          value={this.state.form.webdoc && this.state.form.webdoc.clinics && this.state.form.webdoc.clinics.filter((item) => item.clinic == keyName).length ? this.state.form.webdoc.clinics.filter((item) => item.clinic == keyName)[0].webDocId : 'None'}
                                          onChange={(e) => {
                                            let form = this.state.form;

                                            if (form.webdoc && !form.webdoc.clinics) {
                                              form.webdoc.clinics = [];
                                            }

                                            if (form.webdoc.clinics && form.webdoc.clinics.length) {
                                              for (let i = 0; i < form.webdoc.clinics.length; i++) {
                                                if (form.webdoc.clinics[i].clinic == keyName) {
                                                  form.webdoc.clinics.splice(i, 1);
                                                }
                                              }
                                            }

                                            let obj = {
                                              webDocId: e.target.value,
                                              clinic: keyName,
                                              syncType: '1'
                                            }
                                            form.webdoc.clinics.push(obj);

                                            this.setState({
                                              form
                                            })

                                          }}
                                        >
                                          <option value="None">
                                            {"None".translate(this.props.lang)}
                                          </option>

                                          {
                                            this.state.webDocClinics && this.state.webDocClinics.map((item, idx) => {
                                              return (
                                                <option key={idx} value={item.id}>
                                                  {item.name}
                                                </option>
                                              )
                                            })
                                          }


                                        </Input>
                                      </FormGroup>


                                    </FormGroup>

                                  </Col>
                                  <Col lg="4">
                                    {
                                      this.state.form.webdoc && this.state.form.webdoc.clinics && this.state.form.webdoc.clinics.filter((item) => item.clinic == keyName).length && this.state.form.webdoc.clinics.filter((item) => item.clinic == keyName)[0].webDocId && this.state.form.webdoc.clinics.filter((item) => item.clinic == keyName)[0].webDocId != 'None' ?
                                        <FormGroup className="group-admin-buttons" style={{ marginTop: 20 }}>
                                          <Label size="sm">
                                            {"Sync type".translate(this.props.lang)}
                                          </Label>
                                          <Input
                                            style={{ marginLeft: 5 }}
                                            size="sm"
                                            type="select"
                                            value={this.state.form.webdoc && this.state.form.webdoc.clinics && this.state.form.webdoc.clinics.filter((item) => item.clinic == keyName).length ? this.state.form.webdoc.clinics.filter((item) => item.clinic == keyName)[0].syncType : 1}
                                            onChange={(e) => {
                                              let form = this.state.form;

                                              if (form.webdoc && !form.webdoc.clinics) {
                                                form.webdoc.clinics = [];
                                              }

                                              let obj = {}

                                              if (form.webdoc.clinics && form.webdoc.clinics.length) {
                                                for (let i = 0; i < form.webdoc.clinics.length; i++) {
                                                  if (form.webdoc.clinics[i].clinic == keyName) {
                                                    obj = form.webdoc.clinics[i];
                                                    form.webdoc.clinics.splice(i, 1);
                                                  }
                                                }
                                              }
                                              obj.syncType = e.target.value;

                                              form.webdoc.clinics.push(obj);

                                              this.setState({
                                                form
                                              })

                                            }}
                                          >
                                            <option value={'1'}> {"Sync all".translate(this.props.lang)}</option>
                                            <option value={'2'}> {"Sync only from Curoflow to WebDoc".translate(this.props.lang)}</option>
                                          </Input>
                                        </FormGroup>

                                        :
                                        null
                                    }

                                  </Col>
                                </Row>
                              ))}




                              <p className="error" style={{ color: "red" }}>
                                {this.state.error}
                              </p>
                            </Col>
                            <Col lg="12">
                              {
                                this.state.form && this.state.form.webdoc && this.state.form.webdoc.api && this.state.form.webdoc.clientId && this.state.form.webdoc.clientSecret ?
                                  <Button
                                    color="primary"
                                    size="sm"
                                    onClick={() => {


                                      let data = {

                                        api: this.state.form.webdoc.api,
                                        clientId: this.state.form.webdoc.clientId,
                                        clientSecret: this.state.form.webdoc.clientSecret,
                                      };
                                      this.fetchClinics(data);
                                    }}
                                  >
                                    {"Fetch clinics".translate(this.props.lang)}
                                  </Button>
                                  :
                                  null
                              }
                            </Col>
                            <Col lg="12">
                              <FormGroup className="synchronization-of-existing-events-wrap">
                                <Checkbox
                                  onChange={(e) => {
                                    let form = this.state.form;
                                    let value = e.target.checked;
                                    if (form.webdoc) {
                                      form.webdoc.syncOldEvents = value;
                                      if (value) {
                                        form.webdoc.syncFromDate = this.props.getStringDateTs(Math.floor(new Date().getTime() / 1000), this.props.dateFormat)/*moment.unix(new Date().getTime() / 1000).format(this.props.dateFormat)*/;
                                      } else {
                                        form.webdoc.syncFromDate = null
                                      }
                                    } else {
                                      form.webdoc = {};
                                      form.webdoc.syncOldEvents = value;
                                      if (value) {
                                        form.webdoc.syncFromDate = this.props.getStringDateTs(Math.floor(new Date().getTime() / 1000), this.props.dateFormat)/*moment.unix(new Date().getTime() / 1000).format(this.props.dateFormat)*/;
                                      } else {
                                        form.webdoc.syncFromDate = null
                                      }
                                    }
                                    this.setState({
                                      form,
                                    });
                                  }}
                                  checked={this.state.form.webdoc && this.state.form.webdoc.syncOldEvents}
                                  label={"Synchronization of existing events".translate(this.props.lang)}
                                />
                                {
                                  this.state.form && this.state.form.webdoc && this.state.form.webdoc.syncOldEvents ?
                                    <DatePicker
                                      label={'Start from'.translate(this.props.lang)}
                                      value={this.state.form.webdoc.syncFromDate}
                                      minDate={new Date()}
                                      onChange={(e) => {
                                        let form = this.state.form;

                                        if (form.webdoc) {
                                          form.webdoc.syncFromDate = e;
                                        } else {
                                          form.webdoc = {};
                                          form.webdoc.syncFromDate = e;
                                        }
                                        this.setState({
                                          form,
                                        });

                                      }} />
                                    :
                                    null
                                }
                              </FormGroup>
                              <FormGroup>
                                <Checkbox
                                  onChange={(e) => {
                                    let form = this.state.form;
                                    let value = e.target.checked;
                                    if (!form.webdoc) {
                                      form.webdoc = {}
                                    }
                                    form.webdoc.journalNoteOptionsExtended = value;
                                    if (!value) {
                                      form.webdoc.showWebDocSettings = false;
                                    }
                                    this.setState({
                                      form,
                                    });
                                  }}
                                  checked={this.state?.form?.webdoc?.journalNoteOptionsExtended}
                                  label={"Jorunal note extended options".translate(this.props.lang)}
                                />
                              </FormGroup>
                              {
                                this.state?.form?.webdoc?.journalNoteOptionsExtended ?
                                  <FormGroup>
                                    <Checkbox
                                      onChange={(e) => {
                                        let form = this.state.form;
                                        let value = e.target.checked;
                                        if (!form.webdoc) {
                                          form.webdoc = {}
                                        }
                                        form.webdoc.showWebDocSettings = value;
                                        this.setState({
                                          form,
                                        });
                                      }}
                                      checked={this.state?.form?.webdoc?.showWebDocSettings}
                                      label={"Show WebDoc settings on the doctor's side".translate(this.props.lang)}
                                    />
                                  </FormGroup>
                                  :
                                  null
                              }




                            </Col>

                          </Row>
                        </Col>

                      </Row>
                    </>
                    :
                    this.state.integrationActive === 2 ?
                      <>
                        <Row key={2}>
                          <Col lg="12" className="clinic-groups-left">
                            <Row>
                              <Col lg="12">
                                <FormGroup>
                                  <Toggle label={'Active'.translate(this.props.lang)} value={this.state.form.takeCare && this.state.form.takeCare.active} onChange={(e) => {
                                    let form = this.state.form;
                                    if (form.takeCare) {
                                      form.takeCare.active = !form.takeCare.active;
                                    }
                                    else {
                                      form.takeCare = {};
                                      form.takeCare.active = true;
                                    }
                                    this.setState({
                                      form,
                                    });
                                  }} />
                                </FormGroup>
                                <FormGroup>
                                  <Label size="sm">
                                    {"Group name".translate(this.props.lang)}
                                  </Label>
                                  <Input
                                    invalid={this.state.errors.name}
                                    size="sm"
                                    type="text"
                                    disabled

                                    value={this.state.form.name}
                                  />
                                </FormGroup>
                                <FormGroup>
                                  <Label size="sm">
                                    {"Leyr API endpoint".translate(this.props.lang)}
                                  </Label>
                                  <Input
                                    style={{ marginLeft: 5 }}
                                    size="sm"
                                    type="text"
                                    disabled={true}
                                    value="https://api.leyr.io/api/emrs/takecare"
                                  />
                                </FormGroup>
                                <FormGroup>
                                  <Label size="sm">
                                    {"Client id".translate(this.props.lang)}
                                  </Label>
                                  <Input

                                    style={{ marginLeft: 5 }}
                                    size="sm"
                                    type="text"
                                    value={this.state.form.takeCare && this.state.form.takeCare.clientId}
                                    onChange={(e) => {
                                      let form = this.state.form;
                                      if (form.takeCare) {
                                        form.takeCare.clientId = striptags(e.target.value);
                                      }
                                      else {
                                        form.takeCare = {};
                                        form.takeCare.clientId = striptags(e.target.value);
                                      }
                                      this.setState({
                                        form,
                                      });
                                    }}
                                  />
                                </FormGroup>
                                <FormGroup>
                                  <Label size="sm">
                                    {"Client secret".translate(this.props.lang)}
                                  </Label>
                                  <Input

                                    style={{ marginLeft: 5 }}
                                    size="sm"
                                    type="text"
                                    value={this.state.form.takeCare && this.state.form.takeCare.clientSecret}
                                    onChange={(e) => {
                                      let form = this.state.form;
                                      if (form.takeCare) {
                                        form.takeCare.clientSecret = striptags(e.target.value);
                                      }
                                      else {
                                        form.takeCare = {};
                                        form.takeCare.clientSecret = striptags(e.target.value);
                                      }
                                      this.setState({
                                        form,
                                      });
                                    }}
                                  />
                                </FormGroup>

                                {this.state.takeCareClinics && this.state.form.takeCare && this.state.form.takeCare.clientId && this.state.form.takeCare.clientSecret && Object.keys(this.state.form.clinics).map((keyName, i) => (
                                  <Row key={i}>
                                    <Col lg="3" key='tc-cl'>
                                      <FormGroup className="group-admin-buttons" style={{ marginTop: 20 }}>
                                        <Label size="sm">
                                          {"Clinic".translate(this.props.lang)}
                                        </Label>
                                        <Input

                                          style={{ marginLeft: 5 }}
                                          size="sm"
                                          type="text"
                                          value={this.state.form.clinics[keyName].name}
                                          disabled
                                        >
                                        </Input>
                                      </FormGroup>

                                    </Col>
                                    <Col lg="3" key='tc-tcid'>
                                      <FormGroup className="group-admin-buttons" style={{ marginTop: 20 }}>


                                        <FormGroup>
                                          <Label size="sm">
                                            {"TakeCare clinic".translate(this.props.lang)}
                                          </Label>
                                          <Input

                                            style={{ marginLeft: 5 }}
                                            size="sm"
                                            type="select"
                                            value={this.state.form.takeCare && this.state.form.takeCare.clinics && this.state.form.takeCare.clinics.filter((item) => item.clinic == keyName).length ? this.state.form.takeCare.clinics.filter((item) => item.clinic == keyName)[0].takeCareId : 'None'}
                                            onChange={(e) => {
                                              let form = this.state.form;

                                              if (form.takeCare && !form.takeCare.clinics) {
                                                form.takeCare.clinics = [];
                                              }

                                              if (form.takeCare.clinics && form.takeCare.clinics.length) {
                                                for (let i = 0; i < form.takeCare.clinics.length; i++) {
                                                  if (form.takeCare.clinics[i].clinic == keyName) {
                                                    form.takeCare.clinics.splice(i, 1);
                                                  }
                                                }
                                              }

                                              let obj = {
                                                takeCareId: e.target.value,
                                                clinic: keyName,
                                                syncType: '1'
                                              }
                                              form.takeCare.clinics.push(obj);

                                              this.setState({
                                                form
                                              })

                                            }}
                                          >
                                            <option value="None">
                                              {"None".translate(this.props.lang)}
                                            </option>

                                            {
                                              this.state.takeCareClinics && this.state.takeCareClinics.map((item, idx) => {
                                                return (
                                                  <option key={idx} value={item.emr_id}>
                                                    {item.name}
                                                  </option>
                                                )
                                              })
                                            }


                                          </Input>
                                        </FormGroup>


                                      </FormGroup>

                                    </Col>
                                    <Col lg="3" key='tc-st'>
                                      {
                                        this.state.form.takeCare && this.state.form.takeCare.clinics && this.state.form.takeCare.clinics.filter((item) => item.clinic == keyName)?.length && this.state.form.takeCare.clinics.filter((item) => item.clinic == keyName)?.[0]?.takeCareId && this.state.form.takeCare.clinics.filter((item) => item.clinic == keyName)[0].takeCareId != 'None' ?
                                          <FormGroup className="group-admin-buttons" style={{ marginTop: 20 }}>
                                            <Label size="sm">
                                              {"Sync type".translate(this.props.lang)}
                                            </Label>
                                            <Input
                                              style={{ marginLeft: 5 }}
                                              size="sm"
                                              type="select"
                                              value={this.state.form.takeCare && this.state.form.takeCare.clinics && this.state.form.takeCare.clinics.filter((item) => item.clinic == keyName).length ? this.state.form.takeCare.clinics.filter((item) => item.clinic == keyName)[0].syncType : 1}
                                              onChange={(e) => {
                                                let form = this.state.form;

                                                if (form.takeCare && !form.takeCare.clinics) {
                                                  form.takeCare.clinics = [];
                                                }

                                                let obj = {}

                                                if (form.takeCare.clinics && form.takeCare.clinics.length) {
                                                  for (let i = 0; i < form.takeCare.clinics.length; i++) {
                                                    if (form.takeCare.clinics[i].clinic == keyName) {
                                                      obj = form.takeCare.clinics[i];
                                                      form.takeCare.clinics.splice(i, 1);
                                                    }
                                                  }
                                                }
                                                obj.syncType = e.target.value;

                                                form.takeCare.clinics.push(obj);

                                                this.setState({
                                                  form
                                                })

                                              }}
                                            >
                                              <option value={'1'}> {"Sync all".translate(this.props.lang)}</option>
                                              <option value={'2'}> {"Sync only from Curoflow to TakeCare".translate(this.props.lang)}</option>
                                            </Input>
                                          </FormGroup>

                                          :
                                          null
                                      }

                                    </Col>
                                    <Col lg="3" key='tc-sd'>
                                      {
                                        this.state.form.takeCare && this.state.form.takeCare.clinics && this.state.form.takeCare.clinics.filter((item) => item.clinic == keyName)?.length && this.state.form.takeCare.clinics.filter((item) => item.clinic == keyName)?.[0]?.takeCareId && this.state.form.takeCare.clinics.filter((item) => item.clinic == keyName)[0].takeCareId != 'None' ?
                                          <FormGroup className="group-admin-buttons" style={{ marginTop: 20 }}>
                                            <Label size="sm">
                                              {"Sync days".translate(this.props.lang)}
                                            </Label>
                                            <Input
                                              style={{ marginLeft: 5 }}
                                              size="sm"
                                              type="select"
                                              // value={this.state.form.takeCare && this.state.form.takeCare.clinics && this.state.form.takeCare.clinics.filter((item) => item.clinic == keyName).length ? this.state.form.takeCare.clinics.filter((item) => item.clinic == keyName)[0].syncType : 1}
                                              value={this.state.form?.takeCare?.clinics?.find?.(item => item?.clinic == keyName)?.syncDays || '30'}
                                              onChange={(e) => {
                                                let form = this.state.form;

                                                if (form.takeCare && !form.takeCare.clinics) {
                                                  form.takeCare.clinics = [];
                                                }

                                                let obj = {}

                                                if (form.takeCare.clinics && form.takeCare.clinics.length) {
                                                  for (let i = 0; i < form.takeCare.clinics.length; i++) {
                                                    if (form.takeCare.clinics[i].clinic == keyName) {
                                                      obj = form.takeCare.clinics[i];
                                                      form.takeCare.clinics.splice(i, 1);
                                                    }
                                                  }
                                                }
                                                obj.syncDays = e.target.value;

                                                form.takeCare.clinics.push(obj);

                                                this.setState({
                                                  form
                                                })

                                              }}
                                            >
                                              <option value={'30'}> {'30'}</option>
                                              <option value={'60'}> {'60'}</option>
                                              <option value={'90'}> {'90'}</option>
                                            </Input>
                                          </FormGroup>

                                          :
                                          null
                                      }

                                    </Col>
                                  </Row>
                                ))}




                                <p className="error" style={{ color: "red" }}>
                                  {this.state.error}
                                </p>
                              </Col>
                              <Col lg="12">
                                {
                                  this.state.form && this.state.form.takeCare && this.state.form.takeCare.clientId && this.state.form.takeCare.clientSecret ?
                                    <Button
                                      color="primary"
                                      size="sm"
                                      onClick={() => {
                                        let data = {
                                          api: this.state.form.takeCare.api || 'https://api.leyr.io/api/emrs/takecare',
                                          clientId: this.state.form.takeCare.clientId,
                                          clientSecret: this.state.form.takeCare.clientSecret,
                                        };
                                        this.getTakeCareClinics(data);
                                      }}
                                    >
                                      {"Fetch clinics".translate(this.props.lang)}
                                    </Button>
                                    :
                                    null
                                }
                              </Col>
                              {/* <Col lg="12">
                              <FormGroup className="synchronization-of-existing-events-wrap">
                                <Checkbox
                                  onChange={(e) => {
                                    let form = this.state.form;
                                    let value = e.target.checked;
                                    if (form.webdoc) {
                                      form.webdoc.syncOldEvents = value;
                                      if (value) {
                                        form.webdoc.syncFromDate = moment.unix(new Date().getTime() / 1000).format(this.props.dateFormat);
                                      } else {
                                        form.webdoc.syncFromDate = null
                                      }
                                    } else {
                                      form.webdoc = {};
                                      form.webdoc.syncOldEvents = value;
                                      if (value) {
                                        form.webdoc.syncFromDate = moment.unix(new Date().getTime() / 1000).format(this.props.dateFormat);
                                      } else {
                                        form.webdoc.syncFromDate = null
                                      }
                                    }
                                    this.setState({
                                      form,
                                    });
                                  }}
                                  checked={this.state.form.webdoc && this.state.form.webdoc.syncOldEvents}
                                  label={"Synchronization of existing events".translate(this.props.lang)}
                                />
                                {
                                  this.state.form && this.state.form.webdoc && this.state.form.webdoc.syncOldEvents ?
                                    <DatePicker
                                      label={'Start from'.translate(this.props.lang)}
                                      value={this.state.form.webdoc.syncFromDate}
                                      minDate={new Date()}
                                      onChange={(e) => {
                                        let form = this.state.form;

                                        if (form.webdoc) {
                                          form.webdoc.syncFromDate = e;
                                        } else {
                                          form.webdoc = {};
                                          form.webdoc.syncFromDate = e;
                                        }
                                        this.setState({
                                          form,
                                        });

                                      }} />
                                    :
                                    null
                                }
                              </FormGroup>

                            </Col> */}

                            </Row>
                          </Col>

                        </Row>
                      </>
                      :
                      this.state.integrationActive === 3 ?
                        <>
                          <Row key={3}>
                            <Col lg="12" className="clinic-groups-left">
                              <Row>
                                <Col lg="12">
                                  <FormGroup>
                                    <Toggle label={'Active'.translate(this.props.lang)} value={this.state.form.teleq && this.state.form.teleq.active} onChange={(e) => {
                                      let form = this.state.form;
                                      if (form.teleq) {
                                        form.teleq.active = !form.teleq.active;
                                      }
                                      else {
                                        form.teleq = {};
                                        form.teleq.active = true;
                                      }
                                      this.setState({
                                        form,
                                      });
                                    }} />
                                  </FormGroup>
                                  <FormGroup>
                                    <Label size="sm">
                                      {"Group name".translate(this.props.lang)}
                                    </Label>
                                    <Input
                                      invalid={this.state.errors.name}
                                      size="sm"
                                      type="text"
                                      disabled

                                      value={this.state.form.name}
                                    />
                                  </FormGroup>
                                  <FormGroup>
                                    <Label size="sm">
                                      {"API endpoint".translate(this.props.lang)}
                                    </Label>
                                    <Input
                                      style={{ marginLeft: 5 }}
                                      size="sm"
                                      type="text"
                                      value={this.state.form.teleq && this.state.form.teleq.api}
                                      onChange={(e) => {
                                        let form = this.state.form;
                                        if (form.teleq) {
                                          form.teleq.api = striptags(e.target.value);
                                        }
                                        else {
                                          form.teleq = {};
                                          form.teleq.api = striptags(e.target.value);
                                        }
                                        this.setState({
                                          form
                                        });
                                      }}
                                    />
                                  </FormGroup>
                                  <FormGroup>
                                    <Label size="sm">
                                      {"Client id".translate(this.props.lang)}
                                    </Label>
                                    <Input

                                      style={{ marginLeft: 5 }}
                                      size="sm"
                                      type="text"
                                      value={this.state.form.teleq && this.state.form.teleq.clientId}
                                      onChange={(e) => {
                                        let form = this.state.form;
                                        if (form.teleq) {
                                          form.teleq.clientId = striptags(e.target.value);
                                        }
                                        else {
                                          form.teleq = {};
                                          form.teleq.clientId = striptags(e.target.value);
                                        }
                                        this.setState({
                                          form,
                                        });
                                      }}
                                    />
                                  </FormGroup>
                                  <FormGroup>
                                    <Label size="sm">
                                      {"Client secret".translate(this.props.lang)}
                                    </Label>
                                    <Input

                                      style={{ marginLeft: 5 }}
                                      size="sm"
                                      type="text"
                                      value={this.state.form.teleq && this.state.form.teleq.clientSecret}
                                      onChange={(e) => {
                                        let form = this.state.form;
                                        if (form.teleq) {
                                          form.teleq.clientSecret = striptags(e.target.value);
                                        }
                                        else {
                                          form.teleq = {};
                                          form.teleq.clientSecret = striptags(e.target.value);
                                        }
                                        this.setState({
                                          form,
                                        });
                                      }}
                                    />
                                  </FormGroup>

                                  {this.state.teleqUnits && this.state.form.teleq && this.state.form.teleq.api && this.state.form.teleq.clientId && this.state.form.teleq.clientSecret && Object.keys(this.state.form.clinics).map((keyName, i) => (
                                    <Row>
                                      <Col lg="6">
                                        <FormGroup className="group-admin-buttons" style={{ marginTop: 20 }}>
                                          <Label size="sm">
                                            {"Clinic".translate(this.props.lang)}
                                          </Label>
                                          <Input

                                            style={{ marginLeft: 5 }}
                                            size="sm"
                                            type="text"
                                            value={this.state.form.clinics[keyName].name}
                                            disabled
                                          >
                                          </Input>
                                        </FormGroup>

                                      </Col>
                                      <Col lg="6">
                                        <FormGroup className="group-admin-buttons" style={{ marginTop: 20 }}>


                                          <FormGroup>
                                            <Label size="sm">
                                              {"TeleQ units".translate(this.props.lang)}
                                            </Label>
                                            <Input

                                              style={{ marginLeft: 5 }}
                                              size="sm"
                                              type="select"
                                              value={this.state.form.teleq && this.state.form.teleq.clinics && this.state.form.teleq.clinics.filter((item) => item.clinic == keyName).length ? this.state.form.teleq.clinics.filter((item) => item.clinic == keyName)[0].teleqId : 'None'}
                                              onChange={(e) => {
                                                let form = this.state.form;

                                                if (form.teleq && !form.teleq.clinics) {
                                                  form.teleq.clinics = [];
                                                }

                                                if (form.teleq.clinics && form.teleq.clinics.length) {
                                                  for (let i = 0; i < form.teleq.clinics.length; i++) {
                                                    if (form.teleq.clinics[i].clinic == keyName) {
                                                      form.teleq.clinics.splice(i, 1);
                                                    }
                                                  }
                                                }

                                                let obj = {
                                                  teleqId: e.target.value,
                                                  clinic: keyName,
                                                }
                                                form.teleq.clinics.push(obj);

                                                this.setState({
                                                  form
                                                }, () => console.log(this.state.form))

                                              }}
                                            >
                                              <option value="None">
                                                {"None".translate(this.props.lang)}
                                              </option>

                                              {
                                                this.state.teleqUnits && this.state.teleqUnits.map((item, idx) => {
                                                  return (
                                                    <option key={idx} value={item.id}>
                                                      {item.name}
                                                    </option>
                                                  )
                                                })
                                              }


                                            </Input>
                                          </FormGroup>


                                        </FormGroup>

                                      </Col>

                                    </Row>
                                  ))}






                                  <p className="error" style={{ color: "red" }}>
                                    {this.state.teleqError}
                                  </p>
                                </Col>
                                <Col lg="12">
                                  {
                                    this.state.form && this.state.form.teleq && this.state.form.teleq.api && this.state.form.teleq.clientId && this.state.form.teleq.clientSecret ?
                                      <Button
                                        color="primary"
                                        size="sm"
                                        onClick={() => {
                                          let data = {
                                            api: this.state.form.teleq.api,
                                            clientId: this.state.form.teleq.clientId,
                                            clientSecret: this.state.form.teleq.clientSecret,
                                          };
                                          this.getTeleqUnits(data);
                                        }}
                                      >
                                        {"Fetch units".translate(this.props.lang)}
                                      </Button>
                                      :
                                      null
                                  }
                                </Col>


                              </Row>
                            </Col>

                          </Row>
                        </>
                        :
                        this.state.integrationActive === 4 ?
                          <>
                            <Row key={4}>
                              <Col lg="12" className="clinic-groups-left">
                                <Row>
                                  <Col lg="12">
                                    <Col lg="12">
                                      <FormGroup>
                                        <Toggle label={'Active'.translate(this.props.lang)} value={this.state.form.semble && this.state.form.semble.active} onChange={(e) => {
                                          let form = this.state.form;
                                          if (form.semble) {
                                            form.semble.active = !form.semble.active;
                                          }
                                          else {
                                            form.semble = {};
                                            form.semble.active = true;
                                          }
                                          this.setState({
                                            form,
                                          });
                                        }} />
                                      </FormGroup>
                                      <FormGroup>
                                        <Label size="sm">
                                          {"Group name".translate(this.props.lang)}
                                        </Label>
                                        <Input
                                          invalid={this.state.errors.name}
                                          size="sm"
                                          type="text"
                                          disabled

                                          value={this.state.form.name}
                                        />
                                      </FormGroup>
                                      <FormGroup>
                                        <Label size="sm">
                                          {"Semble API endpoint".translate(this.props.lang)}
                                        </Label>
                                        <Input
                                          // style={{ marginLeft: 5 }}
                                          size="sm"
                                          type="text"
                                          value={this.state.form?.semble?.api || ''}
                                          onChange={(e) => {
                                            let form = this.state.form;
                                            if (form.semble) {
                                              form.semble.api = striptags(e.target.value);
                                            }
                                            else {
                                              form.semble = {};
                                              form.semble.api = striptags(e.target.value);
                                            }
                                            this.setState({
                                              form
                                            });
                                          }}
                                        />
                                      </FormGroup>
                                      <FormGroup>
                                        <Label size="sm">
                                          {"Token".translate(this.props.lang)}
                                        </Label>
                                        <Input
                                          // style={{ marginLeft: 5 }}
                                          size="sm"
                                          type="textarea"
                                          value={this.state.form?.semble?.token || ''}
                                          onChange={(e) => {
                                            let form = this.state.form;
                                            if (form.semble) {
                                              form.semble.token = e.target.value
                                            }
                                            else {
                                              form.semble = {};
                                              form.semble.token = e.target.value
                                            }
                                            this.setState({
                                              form,
                                            });
                                          }}

                                        />
                                      </FormGroup>

                                      {this.state.sembleLocations && this.state.form?.semble && this.state.form.semble.api && this.state.form.semble.token && Object.keys(this.state.form.clinics).map((keyName, i) => (
                                        <Row key={i}>
                                          <Col lg="3" key='tc-cl'>
                                            <FormGroup className="group-admin-buttons" style={{ marginTop: 20 }}>
                                              <Label size="sm">
                                                {"Clinic".translate(this.props.lang)}
                                              </Label>
                                              <Input

                                                style={{ marginLeft: 5 }}
                                                size="sm"
                                                type="text"
                                                value={this.state.form.clinics[keyName].name}
                                                disabled
                                              >
                                              </Input>
                                            </FormGroup>

                                          </Col>
                                          <Col lg="3" key='tc-tcid'>
                                            <FormGroup className="group-admin-buttons" style={{ marginTop: 20 }}>


                                              <FormGroup>
                                                <Label size="sm">
                                                  {"Semble location".translate(this.props.lang)}
                                                </Label>
                                                <Input

                                                  style={{ marginLeft: 5 }}
                                                  size="sm"
                                                  type="select"
                                                  // value={this.state.form.semble && this.state.form.semble.clinics && this.state.form.semble.clinics.filter((item) => item.clinic == keyName).length ? this.state.form.semble.clinics.filter((item) => item.clinic == keyName)[0]?.sembleId : 'None'}
                                                  value={this.state.form?.semble?.clinics?.find?.(item => item?.clinic == keyName)?.sembleId || 'None'}
                                                  onChange={(e) => {
                                                    let form = this.state.form;

                                                    if (form.semble && !form.semble.clinics) {
                                                      form.semble.clinics = [];
                                                    }

                                                    if (form.semble.clinics && form.semble.clinics.length) {
                                                      for (let i = 0; i < form.semble.clinics.length; i++) {
                                                        if (form.semble.clinics[i].clinic == keyName) {
                                                          form.semble.clinics.splice(i, 1);
                                                        }
                                                      }
                                                    }

                                                    let obj = {
                                                      sembleId: e.target.value,
                                                      clinic: keyName,
                                                      syncType: '1'
                                                    }
                                                    form.semble.clinics.push(obj);

                                                    this.setState({
                                                      form
                                                    })

                                                  }}
                                                >
                                                  <option value="None">
                                                    {"None".translate(this.props.lang)}
                                                  </option>

                                                  {
                                                    this.state.sembleLocations && this.state.sembleLocations.map((item, idx) => {
                                                      return (
                                                        <option key={idx} value={item.id}>
                                                          {item.name}
                                                        </option>
                                                      )
                                                    })
                                                  }


                                                </Input>
                                              </FormGroup>


                                            </FormGroup>

                                          </Col>
                                          <Col lg="3" key='tc-st'>
                                            {
                                              this.state.form?.semble?.clinics?.find?.(obj => obj?.clinic === keyName)?.sembleId && this.state.form?.semble?.clinics?.find?.(obj => obj?.clinic === keyName)?.sembleId !== 'None' ?
                                                // this.state.form.semble && this.state.form.semble.clinics && this.state.form.semble.clinics.filter((item) => item.clinic == keyName)?.length && this.state.form.semble.clinics.filter((item) => item.clinic == keyName)?.[0]?.sembleId && this.state.form.semble.clinics.filter((item) => item.clinic == keyName)[0].sembleId != 'None' ?
                                                <FormGroup className="group-admin-buttons" style={{ marginTop: 20 }}>
                                                  <Label size="sm">
                                                    {"Sync type".translate(this.props.lang)}
                                                  </Label>
                                                  <Input
                                                    style={{ marginLeft: 5 }}
                                                    size="sm"
                                                    type="select"
                                                    value={this.state.form.semble && this.state.form.semble.clinics && this.state.form.semble.clinics.filter((item) => item.clinic == keyName).length ? this.state.form.semble.clinics.filter((item) => item.clinic == keyName)[0].syncType : 1}
                                                    onChange={(e) => {
                                                      let form = this.state.form;

                                                      if (form.semble && !form.semble.clinics) {
                                                        form.semble.clinics = [];
                                                      }

                                                      let obj = {}

                                                      if (form.semble.clinics && form.semble.clinics.length) {
                                                        for (let i = 0; i < form.semble.clinics.length; i++) {
                                                          if (form.semble.clinics[i].clinic == keyName) {
                                                            obj = form.semble.clinics[i];
                                                            form.semble.clinics.splice(i, 1);
                                                          }
                                                        }
                                                      }
                                                      obj.syncType = e.target.value;

                                                      form.semble.clinics.push(obj);

                                                      this.setState({
                                                        form
                                                      })

                                                    }}
                                                  >
                                                    <option value={'1'}> {"Sync all".translate(this.props.lang)}</option>
                                                    <option value={'2'}> {"Sync only from Curoflow to Semble".translate(this.props.lang)}</option>
                                                  </Input>
                                                </FormGroup>

                                                :
                                                null
                                            }

                                          </Col>
                                          <Col lg="3" key='tc-sd'>
                                            {
                                              this.state.form.semble && this.state.form.semble.clinics && this.state.form.semble.clinics.filter((item) => item.clinic == keyName)?.length && this.state.form.semble.clinics.filter((item) => item.clinic == keyName)?.[0]?.sembleId && this.state.form.semble.clinics.filter((item) => item.clinic == keyName)[0].sembleId != 'None' ?
                                                <FormGroup className="group-admin-buttons" style={{ marginTop: 20 }}>
                                                  <Label size="sm">
                                                    {"Sync days".translate(this.props.lang)}
                                                  </Label>
                                                  <Input
                                                    style={{ marginLeft: 5 }}
                                                    size="sm"
                                                    type="select"
                                                    // value={this.state.form.semble && this.state.form.semble.clinics && this.state.form.semble.clinics.filter((item) => item.clinic == keyName).length ? this.state.form.semble.clinics.filter((item) => item.clinic == keyName)[0].syncType : 1}
                                                    value={this.state.form?.semble?.clinics?.find?.(item => item?.clinic == keyName)?.syncDays || '30'}
                                                    onChange={(e) => {
                                                      let form = this.state.form;

                                                      if (form.semble && !form.semble.clinics) {
                                                        form.semble.clinics = [];
                                                      }

                                                      let obj = {}

                                                      if (form.semble.clinics && form.semble.clinics.length) {
                                                        for (let i = 0; i < form.semble.clinics.length; i++) {
                                                          if (form.semble.clinics[i].clinic == keyName) {
                                                            obj = form.semble.clinics[i];
                                                            form.semble.clinics.splice(i, 1);
                                                          }
                                                        }
                                                      }
                                                      obj.syncDays = e.target.value;

                                                      form.semble.clinics.push(obj);

                                                      this.setState({
                                                        form
                                                      })

                                                    }}
                                                  >
                                                    <option value={'30'}> {'30'}</option>
                                                    <option value={'60'}> {'60'}</option>
                                                    <option value={'90'}> {'90'}</option>
                                                  </Input>
                                                </FormGroup>

                                                :
                                                null
                                            }

                                          </Col>
                                        </Row>
                                      ))}

                                      <p className="error" style={{ color: "red" }}>
                                        {this.state.error}
                                      </p>
                                    </Col>
                                    <Col lg="12">
                                      {
                                        this.state.form && this.state.form.semble && this.state.form.semble.api && this.state.form.semble.token ?
                                          <Button
                                            color="primary"
                                            size="sm"
                                            onClick={() => {
                                              let data = {
                                                api: this.state.form.semble.api,
                                                token: this.state.form.semble.token,
                                              };
                                              this.getSembleLocations(data);
                                            }}
                                          >
                                            {"Fetch clinics".translate(this.props.lang)}
                                          </Button>
                                          :
                                          null
                                      }
                                    </Col>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </>
                          :
                          null
                }



              </ModalBody>
              <ModalFooter style={{ display: 'flex', justifyContent: 'flex-end' }} className='buttons-right-blue'>
                {

                  this.state.integrationActive == 1 && this.state.showRemoveConnection ?
                    <div className='red-button-wrap'>
                      <Button color='danger' onClick={() => this.setState({ removeConnectionModal: true })} >{'Remove connection'.translate(this.props.lang)}</Button>
                    </div>
                    :
                    null
                }
                {
                  this.state.integrationActive === 2 && (this.state.form && this.state.form.takeCare && this.state.form.takeCare.active && this.state.form.takeCare.api && this.state.form.takeCare.clientId && this.state.form.takeCare.clientSecret && this.state.form.takeCare.clinics && Array.isArray(this.state.form.takeCare.clinics) && this.state.form.takeCare.clinics?.length > 0) ?
                    <div className='red-button-wrap'>
                      <Button color='danger' onClick={() => this.setState({ removeConnectionModalTC: true })} >{'Remove connection'.translate(this.props.lang)}</Button>
                    </div>
                    :
                    null
                }
                {
                  this.state.integrationActive === 3 && (this.state.form && this.state.form.teleq && this.state.form.teleq.active && this.state.form.teleq.api && this.state.form.teleq.clientId && this.state.form.teleq.clientSecret && this.state.form.teleq.clinics && Array.isArray(this.state.form.teleq.clinics) && this.state.form.teleq.clinics?.length > 0) ?
                    <div className='red-button-wrap'>
                      <Button color='danger' onClick={() => this.setState({ removeConnectionModalTeleq: true })} >{'Remove connection'.translate(this.props.lang)}</Button>
                    </div>
                    :
                    null
                }
                {

                  this.state.integrationActive == 1 ?
                    <Button
                      color="primary"
                      size="sm"
                      onClick={() => {


                        let data = {

                          webdoc: this.state.form.webdoc
                            ? {
                              // cid: this.state.form.webdoc.cid,
                              api: this.state.form.webdoc.api,
                              clientId: this.state.form.webdoc.clientId,
                              clientSecret: this.state.form.webdoc.clientSecret,
                              clinics: this.state.form.webdoc.clinics ? this.state.form.webdoc.clinics : [],
                              syncOldEvents: this.state.form.webdoc.syncOldEvents ? true : false,
                              syncFromDate: this.state.form.webdoc.syncFromDate,
                              active: this.state.form.webdoc.active ? true : false,
                              journalNoteOptionsExtended: this.state.form.webdoc.journalNoteOptionsExtended ? true : false,
                              showWebDocSettings: this.state.form.webdoc.showWebDocSettings ? true : false,
                            } : {},

                        };
                        this.updateWebDoc(data);
                      }}
                    >
                      {
                        this.state.form && this.state.form.webdoc && this.state.form.webdoc.syncOldEvents ?
                          "Save and sync".translate(this.props.lang)
                          :
                          "Save".translate(this.props.lang)

                      }
                    </Button>
                    :
                    null
                }
                {

                  this.state.integrationActive === 2 ?
                    <Button
                      color="primary"
                      size="sm"
                      onClick={() => {
                        let data = {
                          takeCare: this.state.form.takeCare
                            ? {
                              api: this.state.form.takeCare.api || 'https://api.leyr.io/api/emrs/takecare',
                              clientId: this.state.form.takeCare.clientId,
                              clientSecret: this.state.form.takeCare.clientSecret,
                              clinics: this.state.form.takeCare.clinics ? this.state.form.takeCare.clinics : [],
                              syncOldEvents: this.state.form.takeCare.syncOldEvents ? true : false,
                              syncFromDate: this.state.form.takeCare.syncFromDate,
                              active: this.state.form.takeCare.active ? true : false,
                            } : {},

                        };
                        this.updateTakeCare(data);
                      }}
                    >
                      {
                        this.state.form && this.state.form.takeCare && this.state.form.takeCare.syncOldEvents ?
                          "Save and sync".translate(this.props.lang)
                          :
                          "Save".translate(this.props.lang)

                      }
                    </Button>
                    :
                    null
                }
                {

                  this.state.integrationActive == 3 ?
                    <Button
                      color="primary"
                      size="sm"
                      onClick={() => {


                        let data = {

                          teleq: this.state.form.teleq
                            ? {
                              api: this.state.form.teleq.api,
                              clientId: this.state.form.teleq.clientId,
                              clientSecret: this.state.form.teleq.clientSecret,
                              clinics: this.state.form.teleq.clinics ? this.state.form.teleq.clinics : [],
                              active: this.state.form.teleq.active ? true : false,
                            } : {},

                        };
                        this.updateTeleq(data);
                      }}
                    >
                      {"Save".translate(this.props.lang)}
                    </Button>
                    :
                    null
                }

                {
                  this.state.integrationActive === 4 ?
                    <Button
                      color="primary"
                      size="sm"
                      onClick={() => {
                        let data = {
                          semble: this.state.form?.semble ? {
                            api: this.state.form.semble.api,
                            token: this.state.form.semble.token,
                            clinics: this.state.form.semble.active ? this.state.form.semble.clinics ? this.state.form.semble.clinics?.filter?.(obj => obj && obj.clinic && obj.sembleId && obj.sembleId !== 'None') : [] : [],
                            active: this.state.form.semble.active ? true : false,

                          } : {}
                        };
                        this.updateSemble(data);
                      }}
                    >
                      {
                        "Save".translate(this.props.lang)
                      }
                    </Button>
                    :
                    null
                }

                <Button
                  color="primary"
                  size="sm"
                  onClick={() => this.setState({ integrationModal: null, integrationActive: 0 })}
                >
                  {"Close".translate(this.props.lang)}
                </Button>
              </ModalFooter>
            </Modal>
            :
            null
        }
        {this.state.selectClinicsModal ? (
          <Modal centered
            isOpen={this.state.selectClinicsModal}
            toggle={() =>
              this.setState({
                selectClinicsModal: !this.state.selectClinicsModal,
              })
            }
            size="xl"
          >
            <ModalHeader
              close={
                <button
                  type="button"
                  className="close"
                  style={{ position: "absolute", top: "15px", right: "15px" }}
                  onClick={() =>
                    this.setState({
                      selectClinicsModal: !this.state.selectClinicsModal,
                    })
                  }
                >
                  <Isvg src={xIcon} />
                </button>
              }
            >
              {"Select clinics".translate(this.props.lang)}
            </ModalHeader>
            <ModalBody>
              <ClinicsFilter
                {...this.props}
                value={this.state.form.clinics}
                group={this.state.form._id}
                onChange={(val) => {
                  if (val) {
                    let form = this.state.form;
                    form.clinics = val;
                    this.setState({ form });
                  }
                }}
              ></ClinicsFilter>
            </ModalBody>
            <ModalFooter>
              <Button
                color="primary"
                size="sm"
                onClick={() => this.setState({ selectClinicsModal: null })}
              >
                {"Close".translate(this.props.lang)}
              </Button>
            </ModalFooter>
          </Modal>
        ) : null}

        {this.state.dataUsedModal ? (
          <Modal centered isOpen={this.state.dataUsedModal} size="xl">
            <ModalHeader close={
              <button type="button" className="close" style={{ position: "absolute", top: "15px", right: "15px" }} onClick={() => this.setState({ dataUsedModal: !this.state.dataUsedModal })}>
                <Isvg src={xIcon} />
              </button>}>
              {this.state.dataUsedModal.name}
            </ModalHeader>
            <ModalBody>
              <Row>
                <Col lg='12'>
                  <div className="panel">
                    <div className="chartjs-wrapper">
                      <button onClick={() => {
                        // console.log(this.dataUsageChart)
                        const url = this.dataUsageChart.toBase64Image();
                        console.log(url)
                        var link = document.createElement("a");
                        link.download = `download.png`;
                        link.target = "_blank";

                        // Construct the URI
                        link.href = url;
                        document.body.appendChild(link);
                        link.click();

                      }} className="chartjs-download-button"><Isvg src={downloadIcon} /></button>
                      <Pie
                        ref={(node) => this.dataUsageChart = node}
                        options={{

                          resposive: true, maintainAspectRatio: false,
                          plugins: {
                            tooltip: {
                              callbacks: {
                                label: function (tooltipItem, data) {
                                  return tooltipItem.label
                                }
                              }
                            },

                            title: {
                              display: true,
                              padding: 20,
                              font: {
                                size: 24,
                              },
                              text: 'Data used'.translate(this.props.lang) + ' ( ' + this.state.dataUsedModal.size + ' )'
                            },

                            legend: {
                              position: 'bottom',
                              labels: {
                                usePointStyle: true,
                              },
                            }
                          }
                        }}
                        data={{
                          labels: [
                            'Cases'.translate(this.props.lang) + ' ' + this.state.dataUsedModal.clinicCaseData,
                            'Chat'.translate(this.props.lang) + ' ' + this.state.dataUsedModal.clinicChat,
                            'Clinic data'.translate(this.props.lang) + ' ' + this.state.dataUsedModal.clinicData,
                            'Support'.translate(this.props.lang) + ' ' + this.state.dataUsedModal.clinicSupport
                          ],
                          datasets: [
                            {
                              data: [this.state.dataUsedModal.clinicCaseDataBytes, this.state.dataUsedModal.clinicChatBytes, this.state.dataUsedModal.clinicDataBytes, this.state.dataUsedModal.clinicSupportBytes],
                              backgroundColor: [
                                '#4f81bc',
                                '#c0504e',
                                '#9bbb58',
                                '#23bfaa',
                              ],
                              borderColor: [
                                '#4f81bc',
                                '#c0504e',
                                '#9bbb58',
                                '#23bfaa',
                              ],
                              borderWidth: 1,
                            }]

                        }}
                      ></Pie>
                    </div>
                  </div>
                </Col>
                <Col lg='6'>
                  <div className="panel">
                    <div className="chartjs-wrapper">
                      <button onClick={() => {
                        const url = this.smsChart.toBase64Image();
                        var link = document.createElement("a");
                        link.download = `download.png`;
                        link.target = "_blank";

                        // Construct the URI
                        link.href = url;
                        document.body.appendChild(link);
                        link.click();

                      }} className="chartjs-download-button"><Isvg src={downloadIcon} /></button>
                      <Bar
                        ref={(node) => this.smsChart = node}
                        options={{

                          resposive: true, maintainAspectRatio: false,
                          plugins: {

                            title: {
                              display: true,
                              padding: 20,
                              font: {
                                size: 24,
                              },
                              text: 'All sms sent'.translate(this.props.lang) + ' ( ' + this.state.dataUsedModal.allSms + ' )'
                            },

                            legend: {
                              display: false,
                            }
                          }
                        }}
                        data={{
                          labels: [
                            'This month'.translate(this.props.lang),
                            'This quater'.translate(this.props.lang),
                            'Previous quarter'.translate(this.props.lang)
                          ],
                          datasets: [
                            {
                              data: [this.state.dataUsedModal.smsThisMonth, this.state.dataUsedModal.smsLast3Month, this.state.dataUsedModal.smsLast6Month],
                              backgroundColor: [
                                '#4f81bc',
                                '#c0504e',
                                '#9bbb58',
                                '#23bfaa',
                              ],
                              borderColor: [
                                '#4f81bc',
                                '#c0504e',
                                '#9bbb58',
                                '#23bfaa',
                              ],
                              borderWidth: 1,
                            }]

                        }}
                      ></Bar>
                    </div>
                  </div>

                </Col>

                <Col lg='6'>
                  <div className="panel">
                    <div className="chartjs-wrapper">
                      <button onClick={() => {
                        const url = this.loginChart.toBase64Image();
                        // console.log(url)
                        var link = document.createElement("a");
                        link.download = `download.png`;
                        link.target = "_blank";

                        // Construct the URI
                        link.href = url;
                        document.body.appendChild(link);
                        link.click();

                      }} className="chartjs-download-button"><Isvg src={downloadIcon} /></button>
                      <Bar
                        ref={(node) => this.loginChart = node}
                        options={{

                          resposive: true, maintainAspectRatio: false,
                          plugins: {

                            title: {
                              display: true,
                              padding: 20,
                              font: {
                                size: 24,
                              },
                              text: 'All logins'.translate(this.props.lang) + ' ( ' + this.state.dataUsedModal.allLogins + ' )'
                            },

                            legend: {
                              display: false,
                            }
                          }
                        }}
                        data={{
                          labels: [
                            'This month'.translate(this.props.lang),
                            'This quater'.translate(this.props.lang),
                            'Previous quarter'.translate(this.props.lang)
                          ],
                          datasets: [
                            {
                              data: [this.state.dataUsedModal.loginsThisMonth, this.state.dataUsedModal.loginsLast3Month, this.state.dataUsedModal.loginsLast6Month],
                              backgroundColor: [
                                '#4f81bc',
                                '#c0504e',
                                '#9bbb58',
                                '#23bfaa',
                              ],
                              borderColor: [
                                '#4f81bc',
                                '#c0504e',
                                '#9bbb58',
                                '#23bfaa',
                              ],
                              borderWidth: 1,
                            }]

                        }}
                      ></Bar>
                    </div>
                  </div>
                </Col>
                <Col lg='6'>
                  <div className="panel">
                    <div className="chartjs-wrapper">
                      <button onClick={() => {
                        const url = this.signaturesChart.toBase64Image();
                        // console.log(url)
                        var link = document.createElement("a");
                        link.download = `download.png`;
                        link.target = "_blank";

                        // Construct the URI
                        link.href = url;
                        document.body.appendChild(link);
                        link.click();

                      }} className="chartjs-download-button"><Isvg src={downloadIcon} /></button>
                      <Bar
                        ref={(node) => this.signaturesChart = node}
                        options={{

                          resposive: true, maintainAspectRatio: false,
                          plugins: {

                            title: {
                              display: true,
                              padding: 20,
                              font: {
                                size: 24,
                              },
                              text: 'Total BankID signatures'.translate(this.props.lang) + ' ( ' + this.state.dataUsedModal.allSignatures + ' )'
                            },

                            legend: {
                              display: false,
                            }
                          }
                        }}
                        data={{
                          labels: [
                            'This month'.translate(this.props.lang),
                            'This quater'.translate(this.props.lang),
                            'Previous quarter'.translate(this.props.lang)
                          ],
                          datasets: [
                            {
                              data: [this.state.dataUsedModal.signaturesThisMonth, this.state.dataUsedModal.signaturesLast3Month, this.state.dataUsedModal.signaturesLast6Month],
                              backgroundColor: [
                                '#4f81bc',
                                '#c0504e',
                                '#9bbb58',
                                '#23bfaa',
                              ],
                              borderColor: [
                                '#4f81bc',
                                '#c0504e',
                                '#9bbb58',
                                '#23bfaa',
                              ],
                              borderWidth: 1,
                            }]

                        }}
                      ></Bar>
                    </div>



                  </div>
                </Col>
                <Col lg='12'>
                  <Row style={{ marginTop: 30 }}>
                    <Col lg='3'>
                      <DatePicker
                        label={'Start date'.translate(this.props.lang)}
                        value={this.state.startDateSms}
                        onChange={(e) => {
                          this.setState({
                            startDateSms: e
                          }, () => {
                            this.get()
                            if (new Date(this.state.startDateSms).getTime() > new Date(this.state.endDateSms).getTime()) {
                              this.setState({
                                endDateSms: e
                              }, () => {
                                this.get()
                              })
                            }
                          })

                        }} />
                    </Col>
                    <Col lg='3'>
                      <DatePicker
                        label={'End date'.translate(this.props.lang)}
                        value={this.state.endDateSms}
                        onChange={(e) => {
                          this.setState({
                            endDateSms: e
                          }, () => {
                            this.get()
                            if (new Date(this.state.startDateSms).getTime() > new Date(this.state.endDateSms).getTime()) {
                              this.setState({
                                startDateSms: e
                              }, () => {
                                this.get()
                              })
                            }
                          })

                        }} />
                    </Col>
                    <Col lg='3' style={{ display: 'flex', alignItems: 'end', marginBottom: 10 }}>
                      <Button onClick={() => this.exportFromGraph({
                        startDateSms: this.state.startDateSms,
                        endDateSms: this.state.endDateSms,
                        group: this.state.dataUsedModal._id
                      })} color="primary">{'Export'.translate(this.props.lang)}</Button>
                    </Col>
                    <Col lg='12'>
                      <div>
                        <table style={{ width: '100%' }}>
                          <tr>
                            <th >{'Date'.translate(this.props.lang)}</th>
                            <th >{'Total sms'.translate(this.props.lang)}</th>
                            <th >{'Total bankid logins'.translate(this.props.lang)}</th>
                            <th >{'Total BankID signatures'.translate(this.props.lang)}</th>
                            <th >{'Total users'.translate(this.props.lang)}</th>
                            {/* <th >{'Number of additional users'.translate(this.props.lang)}</th> */}
                          </tr>
                          {this.state.timeDataInterval && Object.keys(this.state.timeDataInterval).map(item => {
                            return (
                              <tr>
                                <td > {item} </td>
                                <td > {this.state.timeDataInterval[item].timeMessages} </td>
                                <td > {this.state.timeDataInterval[item].timeLogins} </td>
                                <td > {this.state.timeDataInterval[item].signatureTracking} </td>
                                <td > {this.state.timeDataInterval[item]?.usersList?.length} </td>
                                {/* <td > {this.state.timeDataInterval[item].users} </td> */}
                                <td><Button color='primary' onClick={() => this.setState({ showAllUsersModal: this.state.timeDataInterval[item].usersList })} >{'Show All Users'.translate(this.props.lang)}</Button></td>
                              </tr>

                            )
                          })}
                        </table>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>


            </ModalBody>
            <ModalFooter>
              <Button color="primary" size="sm" onClick={() => this.setState({ dataUsedModal: null })}>
                {"Close".translate(this.props.lang)}
              </Button>
            </ModalFooter>
          </Modal>
        ) : null}

        {this.state.showAllUsersModal ? <Modal centered isOpen={this.state.showAllUsersModal} toggle={() => this.setState({ showAllUsersModal: null })}>
          <ModalHeader close={<button
            type="button"
            className="close"
            style={{ position: "absolute", top: "15px", right: "15px" }}
            onClick={() =>
              this.setState({
                showAllUsersModal: null,
              })
            }
          >
            <Isvg src={xIcon} />
          </button>}>
            {'All Users'.translate(this.props.lang)}
          </ModalHeader>
          <ModalBody>
            <ul style={{ listStyle: 'none' }}>
              {this.state.showAllUsersModal.map((user, i) => <li>{i + 1}. {user}</li>)}
            </ul>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              size="sm"
              onClick={() => this.setState({ showAllUsersModal: null })}
            >
              {"Close".translate(this.props.lang)}
            </Button>
          </ModalFooter>
        </Modal> : null}

        {this.state.selectAdminsModal ? (
          <Modal centered
            isOpen={this.state.selectAdminsModal}
            toggle={() =>
              this.setState({
                selectAdminsModal: !this.state.selectAdminsModal,
              })
            }
            size="xl"
          >
            <ModalHeader
              close={
                <button
                  type="button"
                  className="close"
                  style={{ position: "absolute", top: "15px", right: "15px" }}
                  onClick={() =>
                    this.setState({
                      selectAdminsModal: !this.state.selectAdminsModal,
                    })
                  }
                >
                  <Isvg src={xIcon} />
                </button>
              }
            >
              {"Select users"}
            </ModalHeader>
            <ModalBody>
              <UsersFilter
                {...this.props}
                clinicModules={this.state.clinicModules}
                value={this.state.form.admin}
                onChange={(val) => {
                  let form = this.state.form;
                  form.admin = val;
                  this.setState({ form, selectAdminsModal: null });
                }}
              ></UsersFilter>
            </ModalBody>
            <ModalFooter>
              <Button
                color="primary"
                size="sm"
                onClick={() => this.setState({ selectAdminsModal: null })}
              >
                {"Close".translate(this.props.lang)}
              </Button>
            </ModalFooter>
          </Modal>
        ) : null}


        {this.state.webDocModal ? (
          <Modal centered
            isOpen={this.state.webDocModal}
            toggle={() =>
              this.setState({ webDocModal: null, webDocClinics: null, error: null })
            }
            size="xl"
            className="clinic-groups-modal"
          >
            <ModalHeader
              close={
                <button
                  type="button"
                  className="close"
                  style={{ position: "absolute", top: "15px", right: "15px" }}
                  onClick={() =>
                    this.setState({ webDocModal: null, webDocClinics: null, error: null })
                  }
                >
                  <Isvg src={xIcon} />
                </button>
              }
            >
              {"Edit WebDoc informations".translate(this.props.lang)}
            </ModalHeader>
            <ModalBody>
              <Row>
                <Col lg="12" className="clinic-groups-left">
                  <Row>
                    <Col lg="12">
                      <FormGroup>
                        <Label size="sm">
                          {"Group name".translate(this.props.lang)}
                        </Label>
                        <Input
                          invalid={this.state.errors.name}
                          size="sm"
                          type="text"
                          disabled

                          value={this.state.webDocModal.name}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label size="sm">
                          {"Webdoc clinic api".translate(this.props.lang)}
                        </Label>
                        <Input
                          style={{ marginLeft: 5 }}
                          size="sm"
                          type="text"

                          value={this.state.webDocModal.webdoc && this.state.webDocModal.webdoc.api}
                          onChange={(e) => {
                            let webDocModal = this.state.webDocModal;
                            if (webDocModal.webdoc) {
                              webDocModal.webdoc.api = striptags(e.target.value);
                            }
                            else {
                              webDocModal.webdoc = {};
                              webDocModal.webdoc.api = striptags(e.target.value);
                            }
                            this.setState({
                              webDocModal
                            });
                          }}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label size="sm">
                          {"Client id".translate(this.props.lang)}
                        </Label>
                        <Input

                          style={{ marginLeft: 5 }}
                          size="sm"
                          type="text"
                          value={this.state.webDocModal.webdoc && this.state.webDocModal.webdoc.clientId}
                          onChange={(e) => {
                            let webDocModal = this.state.webDocModal;
                            if (webDocModal.webdoc) {
                              webDocModal.webdoc.clientId = striptags(e.target.value);
                            }
                            else {
                              webDocModal.webdoc = {};
                              webDocModal.webdoc.clientId = striptags(e.target.value);
                            }
                            this.setState({
                              webDocModal,
                            });
                          }}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label size="sm">
                          {"Client secret".translate(this.props.lang)}
                        </Label>
                        <Input

                          style={{ marginLeft: 5 }}
                          size="sm"
                          type="text"
                          value={this.state.webDocModal.webdoc && this.state.webDocModal.webdoc.clientSecret}
                          onChange={(e) => {
                            let webDocModal = this.state.webDocModal;
                            if (webDocModal.webdoc) {
                              webDocModal.webdoc.clientSecret = striptags(e.target.value);
                            }
                            else {
                              webDocModal.webdoc = {};
                              webDocModal.webdoc.clientSecret = striptags(e.target.value);
                            }
                            this.setState({
                              webDocModal,
                            });
                          }}
                        />
                      </FormGroup>

                      {this.state.webDocClinics && Object.keys(this.state.webDocModal.clinics).map((keyName, i) => (
                        <Row>
                          <Col lg="6">
                            <FormGroup className="group-admin-buttons" style={{ marginTop: 20 }}>
                              <Label size="sm">
                                {"Clinic".translate(this.props.lang)}
                              </Label>
                              <Input

                                style={{ marginLeft: 5 }}
                                size="sm"
                                type="text"
                                value={this.state.webDocModal.clinics[keyName].name}
                                disabled
                              >
                              </Input>
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup className="group-admin-buttons" style={{ marginTop: 20 }}>


                              <FormGroup>
                                <Label size="sm">
                                  {"WebDoc clinic".translate(this.props.lang)}
                                </Label>
                                <Input

                                  style={{ marginLeft: 5 }}
                                  size="sm"
                                  type="select"
                                  value={this.state.webDocModal.webdoc && this.state.webDocModal.webdoc.clinics && this.state.webDocModal.webdoc.clinics.filter((item) => item.clinic == keyName).length ? this.state.webDocModal.webdoc.clinics.filter((item) => item.clinic == keyName)[0].webDocId : 'None'}
                                  onChange={(e) => {
                                    let webDocModal = this.state.webDocModal;

                                    if (webDocModal.webdoc && !webDocModal.webdoc.clinics) {
                                      webDocModal.webdoc.clinics = [];
                                    }

                                    if (webDocModal.webdoc.clinics && webDocModal.webdoc.clinics.length) {
                                      for (let i = 0; i < webDocModal.webdoc.clinics.length; i++) {
                                        if (webDocModal.webdoc.clinics[i].clinic == keyName) {
                                          webDocModal.webdoc.clinics.splice(i, 1);
                                        }
                                      }
                                    }

                                    let obj = {
                                      webDocId: e.target.value,
                                      clinic: keyName
                                    }
                                    webDocModal.webdoc.clinics.push(obj);

                                    this.setState({
                                      webDocModal
                                    })

                                  }}
                                >
                                  <option value="None">
                                    {"None".translate(this.props.lang)}
                                  </option>

                                  {
                                    this.state.webDocClinics && this.state.webDocClinics.map((item, idx) => {
                                      return (
                                        <option key={idx} value={item.id}>
                                          {item.name}
                                        </option>
                                      )
                                    })
                                  }


                                </Input>
                              </FormGroup>


                            </FormGroup>

                          </Col>
                        </Row>
                      ))}




                      <p className="error" style={{ color: "red" }}>
                        {this.state.error}
                      </p>
                    </Col>


                  </Row>
                </Col>

              </Row>
            </ModalBody>
            <ModalFooter>
              {
                this.state.webDocModal && this.state.webDocModal.webdoc && this.state.webDocModal.webdoc.api && this.state.webDocModal.webdoc.clientId && this.state.webDocModal.webdoc.clientSecret ?
                  <Button
                    color="primary"
                    size="sm"
                    onClick={() => {


                      let data = {

                        api: this.state.webDocModal.webdoc.api,
                        clientId: this.state.webDocModal.webdoc.clientId,
                        clientSecret: this.state.webDocModal.webdoc.clientSecret,
                      };
                      this.fetchClinics(data);
                    }}
                  >
                    {"Fetch clinics".translate(this.props.lang)}
                  </Button>
                  :
                  null
              }

              <Button
                color="primary"
                size="sm"
                onClick={() => {


                  let data = {

                    webdoc: this.state.webDocModal.webdoc
                      ? {
                        // cid: this.state.form.webdoc.cid,
                        api: this.state.webDocModal.webdoc.api,
                        clientId: this.state.webDocModal.webdoc.clientId,
                        clientSecret: this.state.webDocModal.webdoc.clientSecret,
                        clinics: this.state.webDocModal.webdoc.clinics ? this.state.webDocModal.webdoc.clinics : [],
                        syncOldEvents: this.state.webDocModal.webdoc.syncOldEvents ? true : false,
                        syncFromDate: this.state.webDocModal.webdoc.syncFromDate,
                        active: this.state.form.webDocModal.active ? true : false,
                        journalNoteOptionsExtended: this.state.webDocModal.webdoc.journalNoteOptionsExtended ? true : false,
                        showWebDocSettings: this.state.webDocModal.webdoc.showWebDocSettings ? true : false
                      } : {},

                  };
                  this.updateWebDoc(data);
                }}
              >
                {"Save".translate(this.props.lang)}
              </Button>


            </ModalFooter>
          </Modal>

        ) : null}
        {this.state.selectMainClinicsGroupsModal ? (
          <Modal centered
            isOpen={this.state.selectMainClinicsGroupsModal}
            toggle={() =>
              this.setState({
                selectMainClinicsGroupsModal: !this.state.selectMainClinicsGroupsModal,
              })
            }
            size="xl"
          >
            <ModalHeader
              close={
                <button
                  type="button"
                  className="close"
                  style={{ position: "absolute", top: "15px", right: "15px" }}
                  onClick={() =>
                    this.setState({
                      selectMainClinicsGroupsModal: !this.state.selectMainClinicsGroupsModal,
                    })
                  }
                >
                  <Isvg src={xIcon} />
                </button>
              }
            >
              {"Select clinic groups".translate(this.props.lang)}
            </ModalHeader>
            <ModalBody>
              <MainClicGroupFilter
                {...this.props}
                // value={this.state.form.selectMainClinicsGroupsModal}
                group={this.state.form._id}
              // onChange={(val) => {
              //     if (val) {
              //         let form = this.state.form;
              //         form.selectMainClinicsGroupsModal = val;
              //         this.setState({ form });
              //     }
              // }}
              ></MainClicGroupFilter>
            </ModalBody>
            <ModalFooter>
              <Button
                color="primary"
                size="sm"
                onClick={() => this.setState({ selectMainClinicsGroupsModal: null })}
              >
                {"Close".translate(this.props.lang)}
              </Button>
            </ModalFooter>
          </Modal>
        ) : null}
        <input ref={(node) => this.documentInput = node} type="file" accept=".plist" key={this.state.uploadTimestampDoc} onChange={this.uploadDocument} style={{ pointerEvents: 'none', opacity: 0 }} />
        <input ref={(node) => this.jsonInput = node} type="file" accept=".json" key={this.state.uploadTimestampDoc} onChange={this.uploadJson} style={{ pointerEvents: 'none', opacity: 0 }} />
        <input ref={(node) => this.authKeyInput = node} type="file" accept=".p8" key={this.state.uploadTimestampDoc} onChange={this.uploadAuthKey} style={{ pointerEvents: 'none', opacity: 0 }} />


        {
          this.state.appSettingsModal ?
            <Modal centered

              isOpen={this.state.appSettingsModal}
              toggle={() =>
                this.setState({
                  appSettingsModal: !this.state.appSettingsModal,
                })
              }
              size="lg"
            >
              <ModalHeader
                close={
                  <button
                    type="button"
                    className="close"
                    style={{ position: "absolute", top: "15px", right: "15px" }}
                    onClick={() =>
                      this.setState({
                        appSettingsModal: !this.state.appSettingsModal,
                      })
                    }
                  >
                    <Isvg src={xIcon} />
                  </button>
                }
              >
                {"App settings".translate(this.props.lang)}
              </ModalHeader>
              <ModalBody>
                <Row>
                  <Col lg='6'>
                    <FormGroup>
                      <Label>{'App name'.translate(this.props.lang)}</Label>
                      <Input type='text' value={this.state.form.appSettings && this.state.form.appSettings.name} onChange={(e) => {
                        let form = this.state.form;
                        if (!form.appSettings) {
                          form.appSettings = {}
                        }
                        let val = striptags(e.target.value);
                        form.appSettings.name = val;
                        this.setState({ form })
                      }} />
                    </FormGroup>
                    <FormGroup>
                      <Label>{'Bundle id'.translate(this.props.lang)}</Label>
                      <Input type='text' value={this.state.form.appSettings && this.state.form.appSettings.bundleId} onChange={(e) => {
                        let form = this.state.form;
                        if (!form.appSettings) {
                          form.appSettings = {}
                        }
                        let val = striptags(e.target.value);
                        val = val.replace(/[^A-Za-z.]/ig, '')
                        form.appSettings.bundleId = val;
                        this.setState({ form })
                      }} />
                    </FormGroup>
                    <FormGroup>
                      <Label>{'AppStore id'.translate(this.props.lang)}</Label>
                      <Input type='text' value={this.state.form.appSettings && this.state.form.appSettings.appStoreId} onChange={(e) => {
                        let form = this.state.form;
                        if (!form.appSettings) {
                          form.appSettings = {}
                        }

                        form.appSettings.appStoreId = e.target.value;
                        this.setState({ form })
                      }} />
                    </FormGroup>
                    {/* <FormGroup>
                  <Label>{'Version'.translate(this.props.lang)}</Label>
                  <Input type='text' value={this.state.form.appSettings && this.state.form.appSettings.version} onChange={(e) => {
                    let form = this.state.form;
                    if (!form.appSettings) {
                      form.appSettings = {}
                    }
                    form.appSettings.version = e.target.value;
                    this.setState({ form })
                  }} />
                </FormGroup> */}
                    <FormGroup>
                      <Image
                        onLoadImage={(loading) => {
                          this.setState({
                            loadingImage: loading
                          })
                        }}

                        group={this.state.form._id}
                        lang={this.props.lang}
                        onChange={(logo) => {
                          let form = this.state.form;
                          if (!form.appSettings) {
                            form.appSettings = {}
                          }
                          form.appSettings.appIcon = logo;
                          this.setState({
                            form,
                          });
                        }}
                        value={this.state.form.appSettings && this.state.form.appSettings.appIcon}
                        label={"App icon (Recommended 1024x1024)".translate(this.props.lang)}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Image
                        onLoadImage={(loading) => {
                          this.setState({
                            loadingImage: loading
                          })
                        }}

                        group={this.state.form._id}
                        lang={this.props.lang}
                        onChange={(logo) => {
                          let form = this.state.form;
                          if (!form.appSettings) {
                            form.appSettings = {}
                          }
                          form.appSettings.splashScreenLogo = logo;
                          this.setState({
                            form,
                          });
                        }}
                        value={this.state.form.appSettings && this.state.form.appSettings.splashScreenLogo}
                        label={"iOS Splash screen logo (Recommended 1024x1024)".translate(this.props.lang)}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Image
                        onLoadImage={(loading) => {
                          this.setState({
                            loadingImage: loading
                          })
                        }}

                        group={this.state.form._id}
                        lang={this.props.lang}
                        onChange={(logo) => {
                          let form = this.state.form;
                          if (!form.appSettings) {
                            form.appSettings = {}
                          }
                          form.appSettings.androidSplashScreen = logo;
                          this.setState({
                            form,
                          });
                        }}
                        value={this.state.form.appSettings && this.state.form.appSettings.androidSplashScreen}
                        label={"Android Splash screen (Recommended 1284x2778)".translate(this.props.lang)}
                      />
                    </FormGroup>

                    <FormGroup>
                      <Label>{'GoogleService-Info.plist'.translate(this.props.lang)}</Label>
                      <button className={this.state._documentUploading ? 'attach-invoice uploading' : this.state.form.appSettings && this.state.form.appSettings.googleServiceInfo ? 'attach-invoice uploaded' : "attach-invoice"} onClick={() => {
                        if (this.documentInput) this.documentInput.click()
                      }}>
                        <div className="button-menu-icon-wrap">
                          <Isvg src={attachInvoice} />
                        </div>
                        <div className='chat-icons-text'>
                          {
                            this.state.form.appSettings && this.state.form.appSettings.googleServiceInfo ?
                              'File uploaded'.translate(this.props.lang)
                              :
                              'Attach file'.translate(this.props.lang)
                          }
                        </div>
                      </button>
                    </FormGroup>
                    <FormGroup>
                      <Label>{'google-services.json'.translate(this.props.lang)}</Label>
                      <button className={this.state._documentUploading ? 'attach-invoice uploading' : this.state.form.appSettings && this.state.form.appSettings.googleServiceJson ? 'attach-invoice uploaded' : "attach-invoice"} onClick={() => {
                        if (this.jsonInput) this.jsonInput.click()
                      }}>
                        <div className="button-menu-icon-wrap">
                          <Isvg src={attachInvoice} />
                        </div>
                        <div className='chat-icons-text'>
                          {
                            this.state.form.appSettings && this.state.form.appSettings.googleServiceJson ?
                              'File uploaded'.translate(this.props.lang)
                              :
                              'Attach file'.translate(this.props.lang)
                          }
                        </div>
                      </button>
                    </FormGroup>
                    <FormGroup>

                      <Checkbox
                        onChange={(e) => {
                          let form = this.state.form;
                          if (!form.appSettings) {
                            form.appSettings = {}
                          }

                          form.appSettings.standalone = e.target.checked;
                          this.setState({ form })
                        }}
                        checked={this.state.form.appSettings && this.state.form.appSettings.standalone}
                        label={"Standalone".translate(this.props.lang)}
                      />
                    </FormGroup>

                    <FormGroup>

                      <Checkbox
                        onChange={(e) => {
                          let form = this.state.form;
                          if (!form.appSettings) {
                            form.appSettings = {}
                          }

                          form.appSettings.allowUsernameLogin = e.target.checked;
                          this.setState({ form })
                        }}
                        checked={this.state.form.appSettings && this.state.form.appSettings.allowUsernameLogin}
                        label={"Allow username login".translate(this.props.lang)}
                      />
                    </FormGroup>

                    <FormGroup>

                      <Checkbox
                        onChange={(e) => {
                          let form = this.state.form;
                          if (!form.appSettings) {
                            form.appSettings = {}
                          }

                          form.appSettings.secondaryServiceAccount = e.target.checked;
                          if (form.appSettings.secondaryServiceAccount) {
                            form.appSettings.thirdServiceAccount = false;
                          }
                          this.setState({ form })
                        }}
                        checked={this.state.form.appSettings && this.state.form.appSettings.secondaryServiceAccount}
                        label={"Secondary service account".translate(this.props.lang)}
                      />
                    </FormGroup>
                    <FormGroup>

                      <Checkbox
                        onChange={(e) => {
                          let form = this.state.form;
                          if (!form.appSettings) {
                            form.appSettings = {}
                          }

                          form.appSettings.thirdServiceAccount = e.target.checked;
                          if (form.appSettings.thirdServiceAccount) {
                            form.appSettings.secondaryServiceAccount = false;
                          }
                          this.setState({ form })
                        }}
                        checked={this.state.form.appSettings && this.state.form.appSettings.thirdServiceAccount}
                        label={"Third service account".translate(this.props.lang)}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg='6'>
                    <h6 style={{ fontSize: 22, marginBottom: 20 }}>{'Apple APN:'.translate(this.props.lang)}</h6>
                    <FormGroup>
                      <Label>{'AuthKey.p8'.translate(this.props.lang)}</Label>
                      <button className={this.state._documentUploading ? 'attach-invoice uploading' : this.state.form.appSettings && this.state.form.appSettings.authKeyP8 ? 'attach-invoice uploaded' : "attach-invoice"} onClick={() => {
                        if (this.authKeyInput) this.authKeyInput.click()
                      }}>
                        <div className="button-menu-icon-wrap">
                          <Isvg src={attachInvoice} />
                        </div>
                        <div className='chat-icons-text'>
                          {
                            this.state.form.appSettings && this.state.form.appSettings.authKeyP8 ?
                              'File uploaded'.translate(this.props.lang)
                              :
                              'Attach file'.translate(this.props.lang)
                          }
                        </div>
                      </button>
                    </FormGroup>
                    <FormGroup>
                      <Label>{'Key ID'.translate(this.props.lang)}</Label>
                      <Input type='text' value={this.state.form.appSettings && this.state.form.appSettings.keyId} onChange={(e) => {
                        let form = this.state.form;
                        if (!form.appSettings) {
                          form.appSettings = {}
                        }
                        let val = striptags(e.target.value);
                        form.appSettings.keyId = val;
                        this.setState({ form })
                      }} />
                    </FormGroup>
                    <FormGroup>
                      <Label>{'Team ID'.translate(this.props.lang)}</Label>
                      <Input type='text' value={this.state.form.appSettings && this.state.form.appSettings.teamId} onChange={(e) => {
                        let form = this.state.form;
                        if (!form.appSettings) {
                          form.appSettings = {}
                        }
                        let val = striptags(e.target.value);
                        form.appSettings.teamId = val;
                        this.setState({ form })
                      }} />
                    </FormGroup>
                  </Col>
                </Row>
                <hr />
                <FormGroup>
                  <Label>{'GitHub username'.translate(this.props.lang)}</Label>
                  <Input type='text' value={this.state.form.appSettings && this.state.form.appSettings.githubUsername} onChange={(e) => {
                    let form = this.state.form;
                    if (!form.appSettings) {
                      form.appSettings = {}
                    }
                    form.appSettings.githubUsername = e.target.value;
                    this.setState({ form })
                  }} />
                </FormGroup>
                <FormGroup>
                  <Label>{'GitHub token'.translate(this.props.lang)}</Label>
                  <Input type='text' value={this.state.form.appSettings && this.state.form.appSettings.githubToken} onChange={(e) => {
                    let form = this.state.form;
                    if (!form.appSettings) {
                      form.appSettings = {}
                    }
                    form.appSettings.githubToken = e.target.value;
                    this.setState({ form })
                  }} />
                </FormGroup>

              </ModalBody>
              <ModalFooter>
                <Button
                  color="primary"
                  size="sm"
                  onClick={() => this.setState({ appSettingsModal: null })}
                >
                  {"Close".translate(this.props.lang)}
                </Button>
              </ModalFooter>
            </Modal>
            :
            null
        }

        {
          this.state.importModal ?
            <Modal centered

              isOpen={this.state.importModal}
              // toggle={() =>
              //   this.setState({
              //     importModal: !this.state.importModal,
              //   })
              // }
              size="lg"
            >
              <ModalHeader

              >
                {"Import database".translate(this.props.lang)}
              </ModalHeader>
              <ModalBody>
                {
                  this.state.backups && this.state.backups.length ?
                    <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                      <div onClick={() => this.setState({ importDatabaseTab: 1 })} className={this.state.importDatabaseTab == 1 ? 'tab-database active-tab-database' : 'tab-database'}>{'Upload database'.translate(this.props.lang)}</div>
                      <div onClick={() => this.setState({ importDatabaseTab: 2 })} className={this.state.importDatabaseTab == 2 ? 'tab-database active-tab-database' : 'tab-database'}>{'Pick from backup'.translate(this.props.lang)}</div>
                    </div>
                    :
                    null
                }
                {
                  this.state.importDatabaseTab === 1 ?
                    <FormGroup>
                      <Zip label={'Upload database'.translate(this.props.lang)}
                        onChange={(zip) => {
                          this.setState({ databaseFile: zip })

                        }} value={this.state.databaseFile}
                      />
                    </FormGroup>
                    :
                    null

                }

                {
                  this.state.backups && this.state.backups.length && this.state.importDatabaseTab === 2 ?
                    <FormGroup>
                      <Label>{'Backup'.translate(this.props.lang)}</Label>
                      <Input type='select' value={this.state.selectedBackup} onChange={(e) => {
                        if (e.target.value != 'None') {
                          this.setState({ selectedBackup: e.target.value })
                        } else {
                          this.setState({ selectedBackup: null })

                        }
                      }}>

                        <option value={null}>{'None'.translate(this.props.lang)}</option>
                        {
                          this.state.backups && this.state.backups.map((item, idx) => {
                            return (
                              <option value={item}>{item}</option>
                            )
                          })
                        }
                      </Input>
                    </FormGroup>
                    :
                    null
                }

              </ModalBody>
              <ModalFooter>
                {
                  (this.state.databaseFile && this.state.importDatabaseTab === 1) || (this.state.selectedBackup && this.state.importDatabaseTab === 2) ?
                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>

                      <Button color="primary" onClick={() =>
                        this.importDatabase()
                      }>{'Import'.translate(this.props.lang)}</Button>
                      <Button color="primary" onClick={() =>
                        this.setState({
                          importModal: !this.state.importModal,
                        })
                      }>{'Close'.translate(this.props.lang)}</Button>


                    </div>
                    :
                    <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                      <Button color="primary" onClick={() =>
                        this.setState({
                          importModal: !this.state.importModal,
                        })
                      }>{'Close'.translate(this.props.lang)}</Button>
                    </div>
                }






              </ModalFooter>
            </Modal>
            :
            null
        }
        {
          this.state.importGroupsModal ?
            <Modal centered

              isOpen={this.state.importGroupsModal}

              size="lg"
            >
              <ModalHeader

              >
                {"Select clinic group".translate(this.props.lang)}
              </ModalHeader>
              <ModalBody>
                <FormGroup>
                  <Label>{'Clinic groups'.translate(this.props.lang)}</Label>
                  <Input type='select' value={this.state.selectedClinicGroup} onChange={(e) => {
                    if (e.target.value != 'None') {
                      this.setState({ selectedClinicGroup: e.target.value })
                    } else {
                      this.setState({ selectedClinicGroup: null })
                    }

                  }}>
                    <option value={null}>{'None'.translate(this.props.lang)}</option>
                    {
                      this.state.tempClinicGroups && this.state.tempClinicGroups.map((item, idx) => {
                        return (
                          <option value={item._id}>{item.name}</option>
                        )
                      })
                    }
                  </Input>
                </FormGroup>
                <FormGroup >
                  <Checkbox
                    onChange={(e) => {
                      let importClinicData = this.state.importClinicData;
                      if (!importClinicData) {
                        importClinicData = {}
                      }
                      importClinicData.clinicData = e.target.checked;
                      this.setState({
                        importClinicData,
                      });
                    }}
                    checked={this.state.importClinicData.clinicData}
                    label={"Clinic data".translate(this.props.lang)}
                  />
                </FormGroup>
                <FormGroup >
                  <Checkbox
                    onChange={(e) => {
                      let importClinicData = this.state.importClinicData;
                      if (!importClinicData) {
                        importClinicData = {}
                      }
                      importClinicData.clinicCaseData = e.target.checked;
                      this.setState({
                        importClinicData,
                      });
                    }}
                    checked={this.state.importClinicData.clinicCaseData}
                    label={"Clinic case data".translate(this.props.lang)}
                  />
                </FormGroup>
                <FormGroup >
                  <Checkbox
                    onChange={(e) => {
                      let importClinicData = this.state.importClinicData;
                      if (!importClinicData) {
                        importClinicData = {}
                      }
                      importClinicData.clinicSupportData = e.target.checked;
                      this.setState({
                        importClinicData,
                      });
                    }}
                    checked={this.state.importClinicData.clinicSupportData}
                    label={"Clinic support data".translate(this.props.lang)}
                  />
                </FormGroup>

              </ModalBody>
              <ModalFooter>


                {
                  this.state.selectedClinicGroup && this.state.importClinicData && (this.state.importClinicData.clinicData || this.state.importClinicData.clinicCaseData || this.state.importClinicData.clinicSupportData) ?
                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>

                      <Button color="primary" onClick={() => {
                        this.updateDatabase()
                      }
                      }>{'Import'.translate(this.props.lang)}</Button>
                      <Button color="primary" onClick={() =>
                        this.setState({
                          importGroupsModal: !this.state.importGroupsModal,
                        })
                      }>{'Close'.translate(this.props.lang)}</Button>


                    </div>
                    :
                    <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                      <Button color="primary" onClick={() =>
                        this.setState({
                          importGroupsModal: !this.state.importGroupsModal,
                        })
                      }>{'Close'.translate(this.props.lang)}</Button>
                    </div>
                }




              </ModalFooter>
            </Modal>
            :
            null
        }

        {this.state.error ?

          <ErrorModal lang={this.props.lang}
            isOpen={this.state.error}
            toggle={() => this.setState({ error: null })}
            zIndex={999999}
          >
            {this.state.error.translate(this.props.lang)}
          </ErrorModal>

          : null}

        <Modal isOpen={this.state.transferModal} centered size="xl">
          <ModalHeader toggle={() => this.setState({ transferModal: !this.state.transferModal, questionaries: null, domen: null })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ transferModal: !this.state.transferModal, questionaries: null, domen: null })}>&times;</button>}>{'Select clinic groups for transfer'.translate(this.props.lang)}</ModalHeader>

          <ModalBody>
            <FormGroup>
              <Label>{'Select domen'.translate(this.props.lang)}</Label>
              <Input type='select'
                onChange={e => {

                  this.setState({ domen: e.target.value == -1 ? null : e.target.value, }, () => { })
                }}
                value={this.state.domen}
              >
                <option value={-1}>
                  {'None'.translate(this.props.lang)}
                </option>
                {
                  DOMAINS?.filter(item => item.country === this.props.country)?.map((item, idx) => {
                    if (window.location.origin !== item.domen)
                      return (
                        <option value={item.api}>
                          {item.name}
                        </option>
                      )
                  })
                }
                {/* {
                  this.props.country === 'denmark' ?
                    <>
                      {window.location.origin != 'https://test.curoflow.dk' ?
                        <option value={'https://testapi.curoflow.dk'}>
                          {'TEST'.translate(this.props.lang)}
                        </option>
                        : null}
                      {window.location.origin != 'https://tryme.curoflow.dk' ?
                        <option value={'https://tryme-api.curoflow.dk'}>
                          {'TRY ME'.translate(this.props.lang)}
                        </option>
                        : null}
                      {window.location.origin != 'https://dashboard.curoflow.dk' ?
                        <option value={'https://dashboardproxy.curoflow.dk'}>
                          {'DASHBOARD'.translate(this.props.lang)}
                        </option>
                        : null}
                    </>
                    :
                    <>
                      {window.location.origin != 'https://dev.curoflow.se' ?
                        <option value={'https://devapi.curoflow.se'}>
                          {'DEV'.translate(this.props.lang)}
                        </option>
                        : null}
                      {window.location.origin != 'https://tryme.curoflow.se' ?
                        <option value={'https://tryme-api.curoflow.se'}>
                          {'TRY ME'.translate(this.props.lang)}
                        </option>
                        : null}
                      {window.location.origin != 'https://test.curoflow.se' ?
                        <option value={'https://testapi.curoflow.se'}>
                          {'TEST'.translate(this.props.lang)}
                        </option>
                        : null}
                      {window.location.origin != 'https://dashboard.curoflow.se' ?
                        <option value={'https://dashboardapi.curoflow.se'}>
                          {'DASHBOARD'.translate(this.props.lang)}
                        </option>
                        : null}
                      {window.location.origin != 'https://sandbox.curoflow.se' ?
                        <option value={'https://sandboxapi.curoflow.se'}>
                          {'SANDBOX'.translate(this.props.lang)}
                        </option>
                        : null}
                    </>
                } */}
              </Input>
            </FormGroup>

            <SelectGroupForTransfer {...this.props}
              value={this.state.questionaries}
              onChange={(val) => {
                if (val) {
                  let questionaries = this.state.questionaries;
                  questionaries = val;
                  this.setState({ questionaries });
                }
              }}
            ></SelectGroupForTransfer>

          </ModalBody>
          <ModalFooter>
            <Button onClick={() => /*this.transferClinicGroup(this.state.questionaries)*/ this.pickUsers(this.state.questionaries)}>{'Transfer group'.translate(this.props.lang)}</Button>
          </ModalFooter>

        </Modal>

        {this.state.arrUsers && this.state.arrUsers.length > 0 ?

          <Modal isOpen={this.state.transferModal} centered size="xl">
            <ModalHeader toggle={() => this.setState({ arrUsers: [] })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ arrUsers: [] })}>&times;</button>}>{'Update users'.translate(this.props.lang)}</ModalHeader>

            <ModalBody>
              {this.state.arrUsers.map(item => {
                return (
                  <div >
                    <Label>{'Username:'.translate(this.props.lang) + item.username}</Label>
                    <div style={{ display: 'flex' }}>
                      <Input type='text'
                        style={{ margin: 10 }}
                        disabled={true}
                        value={item.email}
                      />
                      <Input type='text'
                        style={{ margin: 10 }}
                        // value={this.state.emails.map(user => {if(user.username == item.username) return user.email})}
                        onChange={(e) => {
                          let obj = {}
                          let emails = this.state.emails
                          for (let i = 0; i < emails.length; i++) {
                            if (emails[i].username == item.username) {
                              emails.splice(i, 1)
                            }

                          }
                          obj.username = item.username
                          obj.email = e.target.value
                          emails.push(obj)
                          this.setState({
                            emails
                          })

                        }}
                      />
                    </div>

                  </div>

                )
              })}



            </ModalBody>
            <ModalFooter>
              <Button onClick={() => this.transferClinicGroup(this.state.questionaries)}>{'Update and transfer group'.translate(this.props.lang)}</Button>
            </ModalFooter>

          </Modal>
          : null
        }

        {this.state.succesMessage ?
          <InfoModal
            isOpen={this.state.succesMessage}

            // toggle={() => this.setState({ succesMessage: null })}
            header={'Info'.translate(this.props.lang)}
            info={this.state.succesMessage}
            buttons={[
              <Button color='primary' onClick={() => {
                this.setState({
                  succesMessage: null
                })

              }}>{'Ok'.translate(this.props.lang)}</Button>
            ]}
          />

          :

          null}
        {
          this.state.newUsernames.length ?
            <InfoModal
              isOpen={this.state.newUsernames}

              // toggle={() => this.setState({ newUsernames: null })}
              // header={'Info'.translate(this.props.lang)}
              info={
                <div className="usrname-changed-transfer-container">
                  <h5>{'Username changed'.translate(this.props.lang)}</h5>
                  {
                    this.state.newUsernames.map(item => {
                      return (
                        <div className="usrname-changed-transfer">
                          <span>
                            {item.oldUsername}
                          </span>
                          <div>

                          </div>
                          <span>
                            {item.newUsername}
                          </span>
                          <br />
                        </div>
                      )
                    })
                  }
                </div>
              }
              buttons={[
                <Button color='primary' style={{ paddingLeft: 25, paddingRight: 25 }} onClick={() => {
                  this.setState({
                    newUsernames: []
                  })

                }}>{'Ok'.translate(this.props.lang)}</Button>
              ]}
            />

            :

            null
        }
        {
          this.state.pickUsersModal ?
            <Modal isOpen={this.state.pickUsersModal} size="lg" centered>
              <ModalHeader>{"Pick users for transfer".translate(this.props.lang)}</ModalHeader>
              <ModalBody>
                <Label>{'Clinic group'.translate(this.props.lang)}</Label>
                <Input type='select'
                  onChange={e => {

                    this.setState({ clinicGroup: e.target.value, values: [] })
                  }}
                  value={this.state.clinicGroup}
                >
                  {
                    Object.keys(this.state.pickUsersModal).map(item => {
                      return (
                        <option value={`${item}`}>
                          {this.state.questionaries[item].name}
                        </option>
                      )
                    })
                  }


                </Input>
                <ListCheckBox
                  // label={'Users:'.translate(this.props.lang)}
                  tableShow={true}
                  thName={'Name'.translate(this.props.lang)}
                  thUsername={'Username'.translate(this.props.lang)}
                  mainButtons={true}
                  value={this.state.values}
                  buttonText1={'Mark all'.translate(this.props.lang)}
                  buttonText2={'Unmark all'.translate(this.props.lang)}
                  values={this.state.pickUsersModal[this.state.clinicGroup].map(item => {
                    return { name: item.name, username: item.username, value: item._id }
                  })}

                  onChange={(e) => {
                    if (e && e.length) {
                      let arr = [];
                      arr = [...e]
                      let usersForTransfer = this.state.usersForTransfer
                      usersForTransfer[this.state.clinicGroup] = [...arr]
                      // console.log(usersForTransfer);
                      this.setState({
                        values: arr,
                        usersForTransfer
                      })

                    } else {
                      let usersForTransfer = this.state.usersForTransfer
                      usersForTransfer[this.state.clinicGroup] = []
                      this.setState({
                        values: [],
                        usersForTransfer
                      })
                    }
                  }}
                />
              </ModalBody>
              <ModalFooter>
                <Button onClick={() => this.setState({ pickUsersModal: null }, () => this.transferClinicGroup(this.state.questionaries))} >{'Continue'.translate(this.props.lang)}</Button>
                <Button onClick={() => this.setState({ pickUsersModal: null, values: [], usersForTransfer: {} })} >{'Close'.translate(this.props.lang)}</Button>

              </ModalFooter>
            </Modal>

            :

            null
        }
        {this.state.updateDatabaseLoader ? (
          <div className="file-progress-loader">
            <div>
              <Player
                onEvent={(event) => {
                  if (event === "load" && this.player && this.player.current) this.player.current.play();
                }}
                ref={this.player} // set the ref to your class instance
                autoplay={true}
                loop={true}
                controls={true}
                src="/lf30_editor_l5cxzdyf.json"
                renderer={'svg'}
                style={{ height: "128px", width: "128px", }}
              ></Player>
              <h6>{this.props.uploadingFileProgress ? ('Uploading file...'.translate(this.props.lang) + this.props.uploadingFileProgress + '%') : 'Processing file...'.translate(this.props.lang)}</h6>
            </div>

          </div>
        ) : null}
        {this.state.loadingImage ?
          <div className="file-progress-loader">
            <div>
              <Player
                onEvent={(event) => {
                  if (event === "load" && this.player && this.player.current) this.player.current.play();
                }}
                ref={this.player} // set the ref to your class instance
                autoplay={true}
                loop={true}
                controls={true}
                src="/lf30_editor_l5cxzdyf.json"
                renderer={'svg'}
                style={{ height: "128px", width: "128px", }}
              ></Player>
              <h6>{'Processing file...'.translate(this.props.lang)}</h6>
            </div>

          </div>
          : null}
        {/* {
          this.state.loading ?
            <div className="file-progress-loader">
              <div>
                <Player
                  onEvent={(event) => {
                    if (event === "load" && this.player && this.player.current) this.player.current.play();
                  }}
                  ref={this.player} // set the ref to your class instance
                  autoplay={true}
                  loop={true}
                  controls={true}
                  src="/lf30_editor_l5cxzdyf.json"
                  renderer={'svg'}
                  style={{ height: "128px", width: "128px", }}
                ></Player>
                <h6>{'Processing file...'.translate(this.props.lang)}</h6>
              </div>

            </div>
            :
            null
        } */}
        {
          this.state.loaderWebDocSave || this.state.loaderTeleqSave ?
            <div className="file-progress-loader">
              <div style={{ backgroundColor: 'transparent' }}>
                <Player
                  onEvent={(event) => {
                    if (event === "load" && this.player && this.player.current) this.player.current.play();
                  }}
                  ref={this.player} // set the ref to your class instance
                  autoplay={true}
                  loop={true}
                  controls={true}
                  src="/lf30_editor_l5cxzdyf.json"
                  renderer={'svg'}
                  style={{ height: typeof window != 'undefined' && window.innerWidth < 768 ? "128px" : "256px", width: typeof window != 'undefined' && window.innerWidth < 768 ? "128px" : "256px" }}
                ></Player>
              </div>

            </div>
            :
            null
        }

        {this.state.exportClinicsModal ? (
          <Modal centered isOpen={this.state.exportClinicsModal} toggle={() => this.setState({ exportClinicsModal: !this.state.exportClinicsModal, listOfGroups: null, questionaries: null, emailSendStatistic: null })} size="xl">
            <ModalHeader
              close={
                <button
                  type="button"
                  className="close"
                  style={{ position: "absolute", top: "15px", right: "15px" }}
                  onClick={() =>
                    this.setState({
                      exportClinicsModal: !this.state.exportClinicsModal,
                      listOfGroups: null,
                      questionaries: null,
                      emailSendStatistic: null
                    })
                  }
                >
                  <Isvg src={xIcon} />
                </button>
              }
            >
              {"Export clinics statistic".translate(this.props.lang)}
            </ModalHeader>
            <ModalBody>
              <Row>
                <Col lg='6'>
                  <DatePicker
                    label={'Start date'.translate(this.props.lang)}
                    value={this.state.startDateSms}
                    onChange={(e) => {
                      this.setState({
                        startDateSms: e
                      }, () => {
                        this.get()
                        if (new Date(this.state.startDateSms).getTime() > new Date(this.state.endDateSms).getTime()) {
                          this.setState({
                            endDateSms: e
                          }, () => {
                            this.get()
                          })
                        }
                      })

                    }} />
                </Col>
                <Col lg='6'>
                  <DatePicker
                    label={'End date'.translate(this.props.lang)}
                    value={this.state.endDateSms}
                    onChange={(e) => {
                      this.setState({
                        endDateSms: e
                      }, () => {
                        this.get()
                        if (new Date(this.state.startDateSms).getTime() > new Date(this.state.endDateSms).getTime()) {
                          this.setState({
                            startDateSms: e
                          }, () => {
                            this.get()
                          })
                        }
                      })

                    }} />
                </Col>

              </Row>

              <div style={{ marginBottom: 35 }}>
                {/* <Label>{'Send statistics to email'.translate(this.props.lang)}</Label>
                <Input size="sm" type="text" value={this.state.emailSendStatistic} onChange={(e) => {
                  this.setState({
                    emailSendStatistic: e.target.value
                  })

                }}></Input> */}
              </div>
              <SelectGroupForTransfer {...this.props}
                value={this.state.questionaries}
                onChange={(val) => {
                  if (val) {
                    let arr = []
                    for (var key in val) {
                      arr.push(key)
                    }
                    let questionaries = this.state.questionaries;
                    questionaries = val;
                    this.setState({ questionaries, listOfGroups: arr }, () => {
                      // console.log(this.state.listOfGroups)
                    });
                  }
                }}
              ></SelectGroupForTransfer>
            </ModalBody>
            <ModalFooter>
              <Button
                color="primary"
                size="sm"
                style={{ borderColor: '#3A3F56' }}
                onClick={() => this.setState({
                  exportClinicsModal: null,
                  listOfGroups: null,
                  questionaries: null,
                  emailSendStatistic: null
                })}
              >
                {"Close".translate(this.props.lang)}
              </Button>
              <div className="buttons-right-blue">
                <Button
                  color="primary"
                  size="sm"
                  style={{ marginRight: 15 }}
                  disabled={this.state.listOfGroups && this.state.listOfGroups.length > 0 ? false : true}
                  onClick={() => this.exportClinicsStatistic(this.state.listOfGroups, this.state.startDateSms, this.state.endDateSms, false, this.state.emailSendStatistic)}
                >
                  {"Export selected".translate(this.props.lang)}
                </Button>
                <Button
                  color="primary"
                  size="sm"
                  onClick={() => this.exportClinicsStatistic(this.state.listOfGroups, this.state.startDateSms, this.state.endDateSms, true, this.state.emailSendStatistic)}
                >
                  {"Export all".translate(this.props.lang)}
                </Button>
              </div>
            </ModalFooter>
          </Modal>
        ) : null}

        {this.state.deleteClicnic ?
          <InfoModal
            isOpen={this.state.deleteClicnic}

            header={'Warning'.translate(this.props.lang)}
            info={'Notice: If you remove the clinic, all patient accounts, forms, and more will disappear. Are you sure you want to remove the clinic?'.translate(this.props.lang)}
            buttons={[

              <Button color='primary' onClick={() => {
                let form = this.state.form;
                delete form.clinics[this.state.deleteClicnic];
                this.setState({
                  form,
                  deleteClicnic: null
                })

              }}>{'Ok'.translate(this.props.lang)}</Button>,

              <Button color='primary' onClick={() => {
                this.setState({ deleteClicnic: null })
              }}>{'Cancel'.translate(this.props.lang)}</Button>

            ]}
          />


          : null}
        {
          this.state.removeConnectionModal ?
            <DeleteModal
              lang={this.props.lang}
              isOpen={this.state.removeConnectionModal}
              toggle={() => this.setState({ removeConnectionModal: null })}
              zIndex={9999}
              handler={() => {
                this.removeConnectionsWebDoc()
                this.setState({ removeConnectionModal: null });
              }}
            >
              {"Do you want to remove connection?".translate(this.props.lang)}

            </DeleteModal>
            :
            null
        }
        {
          this.state.removeConnectionModalTeleq ?
            <DeleteModal
              lang={this.props.lang}
              isOpen={this.state.removeConnectionModalTeleq}
              toggle={() => this.setState({ removeConnectionModalTeleq: null })}
              zIndex={9999}
              handler={() => {
                this.removeConnectionsTeleq()
                this.setState({ removeConnectionModalTeleq: null });
              }}
            >
              {"Do you want to remove connection?".translate(this.props.lang)}

            </DeleteModal>
            :
            null
        }

        {
          this.state.removeConnectionModalTC ?
            <DeleteModal
              lang={this.props.lang}
              isOpen={this.state.removeConnectionModalTC}
              toggle={() => this.setState({ removeConnectionModalTC: null })}
              zIndex={9999}
              handler={() => {
                this.removeConnectionsTakeCare()
                this.setState({ removeConnectionModalTC: null });
              }}
            >
              {"Do you want to remove connection?".translate(this.props.lang)}

            </DeleteModal>
            :
            null
        }

      </div>
    );
  }
}

const selector = formValueSelector("modalForm");

export default connect(
  (state) => {
    return { group: selector(state, "group") };
  },
  {
    changeSubgroup: (value) => change("modalForm", "subgroup", value),
  }
)(Page(ClinicGroups));
