import React, { Component } from 'react';
import Page from '../../containers/page';

import {
    Container,
    Row,
    Col,
    Button,
    FormGroup,
    Label,
    Input,
    UncontrolledAlert,
    UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Table, Form, Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';

import Isvg from 'react-inlinesvg'

import HTML from '../../components/forms/fields/html';

import ErrorModal from '../../components/errorModal';
import DeleteModal from '../../components/deleteModal';
import InfoModal from '../../components/infoModal';
import ListCheckBox from '../../components/forms/fields/listCheckbox';
import { getSearchParams, generateSearchLink, generateSearchLinkMultiple } from '../../helpers/linkHelper';
import { API_ENDPOINT } from '../../constants';
import { formValueSelector, change } from 'redux-form';  // ES6
import { connect } from 'react-redux';
import warrning from '../../assets/svg/warrning.svg';
import { SortableContainer, SortableElement, sortableHandle, } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import { v4 as uuidv4 } from 'uuid';
import garabage from '../../assets/svg/garbage.svg'
import arrows from '../../assets/svg/arrows.svg'

import CreateReferralModal from '../../components/createReferralModal';
import ImportReferral from '../../components/importReferral';
import CreatePersonalReferralModal from '../../components/createPersonalReferralModal';

import Search from '../../components/search';
import profileUser from '../../assets/images/user.png';
import notification from '../../assets/svg/notification.svg';
import moreIcon from '../../assets/svg/more.svg';
import xIcon from '../../assets/svg/x.svg';
import Print from '../../assets/svg/print.svg'
import Link from '../../assets/svg/link.svg'
import Checkbox from '../../components/forms/fields/checkbox';
import addIcon from '../../assets/svg/add-icon.svg';
import Html from '../../components/forms/fields/html';

import importIcon from '../../assets/svg/import.svg'

import FormPreview from '../../components/formPreview';
import CreateDaybookModal from '../../components/createDaybookModal';

import backIcon from '../../assets/svg/back-icon.svg'

import SelectableInterval from '../../components/selectableInterval';

import viewIcon from '../../assets/svg/eye.svg';
import hideIcon from '../../assets/svg/hide.svg';

import PreviewAnswerForm from '../forms/previewAnswerForm';



function getRandomColor() {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
}


const colors = [
    "aqua",
    "black",
    "lime",
    "blue",
    "fuchsia",
    "gray",
    "green",
    "navy",
    "olive",
    "purple",
    "red",
    "silver",
    "teal",
    "yellow",
    "maroon",
];


function parseJSON(response) {
    return response.json().then(json => {
        return {
            result: json,
            status: response.status
        }
    })
}


const SortableItem = SortableElement(({ value, update }) => <li className={value.className}>{value.component}</li>);

const SortableList = SortableContainer(({ items, updateSubMenu }) => {
    return (
        <ul className="prepare-questionary-sort-list">
            {items.map((value, index) => (
                <SortableItem key={`item-${index}`} index={index} value={value} />
            ))}
        </ul>
    );
});

const SortableAnswer = SortableElement(({ value }) => <div className={value.className}>{value.component}</div>);

const SortableAnswers = SortableContainer(({ items }) => {
    return (
        <div>
            {items.map((value, index) => (
                <SortableAnswer className="bam" key={`item-${index}`} index={index} value={value} />
            ))}
        </div>
    );
});




const DragHandle = sortableHandle(({ children }) => children);


/**
* Form builder for referral
* @author   Milan Stanojevic
*/
class RevisitBuilder extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props.initialData,
            sectionFormIdx: null,
            answerForm: null,
            deleteElementModal: null,
            deleteOptionModal: null,
            deleteBlockModal: null,
            linkingElement: null,
            articles: [],
            sections: [],
            tags: [],
            formTags: [],
            items: [],
            professions: [],
            version: 1,
            haveQuestionary: true,
            answers: {},
            duration: -1,
            doctorsModal: false,
            doctorsList: [],
            messageShow: false,
            messageModalError: false,
            cursorNoDrop: false,
            checkActiveSave: true,
            price: 0,
            freeOfCharge: true,
            openPageWarningModal: false,
            clinicGroups: [],
            clinicGroupsList: [],
            // under18: true,
            // older85: true,
            // under18child: true,
            wayofScheduling: 'bookingByClinic',
            nameBonus: '',
            numberOfElementsToRender: 1,
            detectedChage: false
        };
    }
    detectChange = () => {
        if (!this.state.detectedChage)
            this.setState({ detectedChage: true })
    }

    get = () => {
        this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));

        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0, clinic: this.props.selectedClinic, groupId: this.props.selectedGroup })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

        for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
            this.props.loadDataWithQuery[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0, clinic: this.props.selectedClinic, groupId: this.props.selectedGroup })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

    }

    componentDidMount() {
        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }

        this.get();
        if (this.props[0].match.params.id == 'new') {
            this.setState({ formType: 'visit-forms' })
        }

        // this.setState({ openPageWarningModal: true })



        let params = getSearchParams(this.props[0].location.search, {});
        if (this.props[0].match.params.id != 'new')
            this.getQuestionary();
        window.addEventListener('scroll', this.handleScroll);

        if (this.props.uData && this.props.uData.userLevel == 100) {


            fetch(API_ENDPOINT + '/data/clinic-groups/all', {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
            }).then(parseJSON).then(({ result, status }) => {
                this.setState({
                    clinicGroupsData: result
                })
            })
        }

        if (this.props[0].match.params.id == 'new') {
            this.checkActiveSave()
        }


    }
    handleScroll = () => {
        var scrollTop = (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop;

        // Grodriguez's fix for scrollHeight:
        // accounting for cases where html/body are set to height:100%
        var scrollHeight = (document.documentElement && document.documentElement.scrollHeight) || document.body.scrollHeight;

        // >= is needed because if the horizontal scrollbar is visible then window.innerHeight includes
        // it and in that case the left side of the equation is somewhat greater.
        var scrolledToBottom = (scrollTop + window.innerHeight) >= ((scrollHeight) - scrollHeight / 8);
        // console.log('scrolledToBottom', scrolledToBottom)
        if (scrolledToBottom) {
            this.setState({
                numberOfElementsToRender: this.state.numberOfElementsToRender + 1
            })
        }
    }

    componentWillUnmount() {
        try {
            window.removeEventListener('scroll', this.handleScroll);

            delete this.state;
            this.state = {};
        } catch (e) {

        }




    }


    componentDidUpdate(prevProps, prevState) {
        if (prevProps[0].location.search != this.props[0].location.search && this.state.doctors) {
            this.setState({
                loading: true
            }, () => {
                this.get();

            })
        }
        if (prevProps[0].location.pathname != this.props[0].location.pathname) {
            this.props.abortAction()
        }
        if (prevProps.selectedClinic != this.props.selectedClinic) {
            this.get();
        }


        if (this.state.name && prevState.name != this.state.name) {
            this.checkActiveSave()
        }

        if (this.state.detectedChage && !this.props.groupSettingsModal) {
            this.props.groupSettingsChanged()
        }



    }

    updateParams = (name, value,) => {
        this.props[0].history.push(generateSearchLink(this.props[0].location.search, {}, name, value, false));
    }
    checkDuplicateConditions = (idx, bidx) => {
        if (this.state.items && this.state.items[idx]) {
            let item = this.state.items[idx];
            if (item && item.blocks && item.blocks.length) {
                let blocks = item.blocks;
                let thisBlock = blocks[bidx];
                if (thisBlock) {
                    if (blocks.filter(block => block.id != thisBlock.id && block.comparison == thisBlock.comparison && block.value == thisBlock.value).length) {
                        this.setState({ duplicateBlock: 'There is already a block with the same parameters!'.translate(this.props.lang) })
                    }
                }

            }

        }
    }


    /**
    * Function return questionary selected by id
    * @author   Milan Stanojevic
    * @Objectparam    {String} id    questionary id
    */
    getQuestionary = () => {

        fetch(API_ENDPOINT + '/data/form-importer/' + this.props[0].match.params.id, {
            method: 'GET',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
        }).then(parseJSON).then(({ result, status }) => {


            if (status >= 200 && status < 300) {
                if (!result.items) {
                    result.items = []
                }
                for (let i = 0; i < result.items.length; i++) {
                    if (!result.items[i].id) {
                        result.items[i].id = uuidv4();
                    }

                }


                this.setState(result, () => {
                    if (this.state.name) {
                        this.setState({
                            name: this.props.lang == 'en' ? this.state.name.replace(' (onlinebesök)', ' (online visit)') : this.state.name.replace(' (online visit)', ' (onlinebesök)')
                        })
                    }
                    this.checkBlocksRange()
                });
            }

        })
    }

    /**
    * Insert or update questionary
    * @author   Milan Stanojevic
    * @Objectparam    {String} id    questionary id
    */
    updateQuestionary = (redirect, changeLink) => {
        /*if (!this.checkAllPrintAnswers()) {
            this.setState({
                error: 'Please check all print answers'
            })
            return;
        }*/
        let name = this.state.name;
        if (!name) {
            if (this.props.lang == 'en') {
                name = 'New form';
            } else {
                name = 'Ny formulär';
            }
            if (this.state.personalQuestionaryNumberNew && this.state.personalQuestionaryNumberNew > 0) {
                name += ` (${this.state.personalQuestionaryNumberNew})`
            }
        }


        this.setState({ openPageWarningModal: null })
        let obj = {
            items: this.state.items,
            name: name,
            clinicGroups: this.state.clinicGroups
        }
        if (this.state.formType && this.state.formType != 'daybook-forms') {
            obj.intelligentReferral = this.state.intelligentReferral;
        }
        if (this.props[0].match.params.id == 'new') {
            obj.formType = this.state.formType;
        }
        let activeTab;

        if (this.state.formType == 'visit-forms') {
            activeTab = 1;
        } else if (this.state.formType == 'revisit-forms') {
            activeTab = 2;
        } else if (this.state.formType == 'chat-forms') {
            activeTab = 3;
        } else if (this.state.formType == 'daybook-forms') {
            activeTab = 4;
        }


        fetch(API_ENDPOINT + '/data/form-importer/form/' + this.props[0].match.params.id, {
            method: 'POST',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
            body: JSON.stringify(obj)
        }).then(parseJSON).then(({ result, status }) => {
            if (status >= 200 && status < 300) {
                this.getQuestionary();
                this.setState({
                    message: 'Questionary saved successfully',
                    detectedChage: false
                })

                if (changeLink) {
                    this.props[0].history.push(this.props.changeLinkModal)

                } else if (!this.props.selectedGroup) {
                    if (redirect)
                        this.props[0].history.push({
                            pathname: '/data-managment/form-importer',
                            state: { activeTab: activeTab }
                        })
                } else {
                    if (redirect)
                        this.props[0].history.push({
                            pathname: '/data-managment/form-importer',
                            state: { activeTab: activeTab }
                        })
                }
                this.props.abortAction()


            }
        })



    }

    checkActiveSave = () => {
        let returnValue = true;

        if (!this.state.name || this.state.name == '') {
            returnValue = false
        }

        this.setState({ checkActiveSave: returnValue })
    }


    checkAllPrintAnswers = () => {
        for (let i = 0; i < this.state.items.length; i++) {
            if (this.state.items[i].type == 1 || this.state.items[i].type == 2) {
                let answers = 0;
                for (let j = 0; j < this.state.items[i].answers.length; j++) {
                    if (!this.state.items[i].answers[j].text) {
                        answers++;
                    }
                }
                if (answers == this.state.items[i].answers.length || answers == 0) {
                    continue;
                } else {
                    return false;
                }
            }
        }

        return true;
    }


    conditionIndexOf = (conditions, conditionQuestion, conditionAnswer) => {
        if (!conditions) {
            return -1;
        }

        for (let i = 0; i < conditions.length; i++) {
            if (!conditionAnswer) {
                if (conditions[i].conditionQuestion == conditionQuestion) {
                    return i;
                }
            } else {
                if (conditions[i].conditionQuestion == conditionQuestion && conditions[i].conditionAnswer == conditionAnswer) {
                    return i;
                }
            }
        }

        return -1;
    }

    getAnswer = (id) => {
        for (let i = 0; i < this.state.items.length; i++) {
            if (this.state.items[i].answers) {
                for (let j = 0; j < this.state.items[i].answers.length; j++) {
                    if (this.state.items[i].answers[j].id == id) {
                        return this.state.items[i].answers[j];
                    }
                }
            }
            if (this.state.items[i].blocks) {
                for (let j = 0; j < this.state.items[i].blocks.length; j++) {
                    if (this.state.items[i].blocks[j].id == id) {
                        return this.state.items[i].blocks[j];
                    }
                }
            }
        }
    }


    getItem = (id) => {
        for (let i = 0; i < this.state.items.length; i++) {
            if (this.state.items[i].id == id) {
                return this.state.items[i];
            }
        }
    }




    checkRelations = (itemId) => {
        let items = this.state.items;

        let itemsDict = {};
        for (let i = 0; i < items.length; i++) {
            itemsDict[items[i].id] = items[i];
        }


        let item = this.getItem(itemId)
        if (!item) {
            return null;
        }


        let upLinks = [];
        let downLinks = [];

        for (let i = 0; i < items.length; i++) {
            if (items[i].conditions) {

                let idx = this.conditionIndexOf(items[i].conditions, itemId, null);
                if (idx != -1) {
                    for (let j = 0; j < items[i].conditions.length; j++) {
                        if (items[i].conditions[j].conditionQuestion == itemId) {

                            downLinks.push({
                                conditionElement: {
                                    id: items[i].conditions[j].conditionQuestion,
                                    answerId: items[i].conditions[j].conditionAnswer,
                                    name: itemsDict[items[i].conditions[j].conditionQuestion].name,
                                    answer: this.getAnswer(items[i].conditions[j].conditionAnswer) && this.getAnswer(items[i].conditions[j].conditionAnswer).name,
                                },
                                element: {
                                    id: items[i].id,
                                    name: items[i].name,
                                    itemIndex: i,
                                    conditionIndex: j
                                }
                            });

                        }
                    }
                }


                if (items[i].id == itemId) {
                    for (let j = 0; j < items[i].conditions.length; j++) {
                        upLinks.push({
                            conditionElement: {
                                id: items[i].conditions[j].conditionQuestion,
                                answerId: items[i].conditions[j].conditionAnswer,
                                name: this.getItem(items[i].conditions[j].conditionQuestion) && this.getItem(items[i].conditions[j].conditionQuestion).name,
                                answer: this.getAnswer(items[i].conditions[j].conditionAnswer) && this.getAnswer(items[i].conditions[j].conditionAnswer).name,
                            },
                            element: {
                                id: item.id,
                                name: item.name,
                                itemIndex: i,
                                conditionIndex: j
                            }
                        })
                    }
                }
            }


        }


        if (!upLinks.length && !downLinks.length) {
            return null;
        } else {
            return [upLinks, downLinks];
        }


    }

    checkAnswerRelations = (itemId, answerId) => {
        let items = this.state.items;

        let itemsDict = {};
        for (let i = 0; i < items.length; i++) {
            itemsDict[items[i].id] = items[i];
        }

        let item = this.getItem(itemId)
        if (!item) {
            return null;
        }


        let upLinks = [];
        let downLinks = [];

        for (let i = 0; i < items.length; i++) {
            if (items[i].conditions) {

                let idx = this.conditionIndexOf(items[i].conditions, itemId, answerId);
                if (idx != -1) {
                    for (let j = 0; j < items[i].conditions.length; j++) {
                        if (items[i].conditions[j].conditionQuestion == itemId && items[i].conditions[j].conditionAnswer == answerId) {
                            downLinks.push({
                                conditionElement: {
                                    id: items[i].conditions[j].conditionQuestion,
                                    answerId: items[i].conditions[j].conditionAnswer,
                                    name: itemsDict[items[i].conditions[j].conditionQuestion].name,
                                    answer: this.getAnswer(items[i].conditions[j].conditionAnswer).name,
                                },
                                element: {
                                    id: items[i].id,
                                    name: items[i].name,
                                    itemIndex: i,
                                    conditionIndex: j
                                }
                            });

                        }
                    }
                }

            }


        }


        if (!upLinks.length && !downLinks.length) {
            return null;
        } else {
            return [upLinks, downLinks];
        }


    }



    generateSections = () => {
        let sections = {};
        let items = [{ type: 0 }, ...this.state.items];
        let lastSectionId = -1;
        for (let i = 0; i < items.length; i++) {
            if (items[i].type == 0) {
                lastSectionId++;
                sections[lastSectionId] = {
                    name: items[i].name,
                    displayOnReferral: false,
                    questions: [],
                    conditions: items[i].conditions ? items[i].conditions : [],
                    displayOnReferral: items[i].displayOnReferral,
                    fontStyle: items[i].fontStyle,
                    fontSize: items[i].fontSize,
                    fontWeight: items[i].fontWeight,
                    required: items[i].required,

                }
            }
            if (lastSectionId != -1 && items[i].type != 0) {


                let wikiLinks = [];

                items[i].wikiLinks = wikiLinks;


                if (items[i].type == 1) {
                    let displayOnReferral = false;
                    if (items[i].answers) {
                        for (let j = 0; j < items[i].answers.length; j++) {
                            if (items[i].answers[j].text) {
                                displayOnReferral = true;
                            }
                        }
                    }

                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        answers: items[i].answers,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        displayOnReferral: displayOnReferral,
                        wikiLinks: items[i].wikiLinks,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                        required: items[i].required,
                    });

                } else if (items[i].type == 2) {
                    let displayOnReferral = false;
                    if (items[i].answers) {
                        for (let j = 0; j < items[i].answers.length; j++) {

                            if (items[i].answers[j].text) {
                                displayOnReferral = true;
                            }
                        }
                    }
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        answers: items[i].answers,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        answerPrefix: items[i].answerPrefix,
                        userInput: items[i].userInput,
                        displayOnReferral: displayOnReferral || items[i].answerPrefix ? true : false,
                        wikiLinks: items[i].wikiLinks,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                        required: items[i].required,

                    })
                } else if (items[i].type == 3) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        answerPrefix: items[i].answerPrefix,
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                        required: items[i].required,
                    })

                } else if (items[i].type == 4) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        text: items[i].text,
                        userInput: items[i].userInput,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                        required: items[i].required,
                    })
                } else if (items[i].type == 15) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        text: items[i].text,
                        userInput: items[i].userInput,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                        required: items[i].required,
                    })

                } else if (items[i].type == 5) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                        required: items[i].required,
                        score: items[i].score
                    })

                } else if (items[i].type == 6) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                        required: items[i].required,
                        score: items[i].score
                    })

                } else if (items[i].type == 7) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                        required: items[i].required,
                    })

                } else if (items[i].type == 8) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                        required: items[i].required,
                    })

                } else if (items[i].type == 9) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                        required: items[i].required,
                    })

                } else if (items[i].type == 10) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        userInput: items[i].userInput,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                        required: items[i].required,
                    })
                } else if (items[i].type == 12) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                        required: items[i].required,
                        score: items[i].score
                    })
                } else if (items[i].type == 14) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                        required: items[i].required,
                        score: items[i].score
                    })

                } else if (items[i].type == 13) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                        required: items[i].required,
                        score: items[i].score
                    })

                } else if (items[i].type == 16) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                        required: items[i].required,
                        blocks: items[i].blocks,
                    })

                } else if (items[i].type == 17) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                        required: items[i].required,
                        blocks: items[i].blocks,

                    })

                }
            }
        }

        return Object.values(sections);
    }


    checkBlocksRange = () => {
        let items = this.state.items;
        if (items && items.length) {
            let blocks = [];
            let min = 0;
            let max = 0;
            let minTotal = 0;
            let maxTotal = 0;
            for (let i = 0; i < items.length; i++) {
                let item = items[i];
                if (item.type == 16 || item.type == 17) {
                    if (blocks.filter(el => el.id == item.id).length == 0) {
                        let obj = {
                            id: item.id,
                            min: item.type == 16 ? min : minTotal,
                            max: item.type == 16 ? max : maxTotal
                        }
                        blocks.push(obj)
                        min = 0;
                        max = 0;
                    }
                } else {
                    if ((item.type == 1 || item.type == 2) && item.answers && item.answers.length) {
                        let checkMin = 100;
                        let checkMax = 0;
                        for (let j = 0; j < item.answers.length; j++) {
                            let score = item.answers[j].score ? item.answers[j].score : 0;

                            if (item.type == 1) {
                                if (score > checkMax) {
                                    checkMax = score;
                                }
                            } else if (item.type == 2) {
                                checkMax += score;
                            }
                            if (score < checkMin) {
                                checkMin = score;
                            }
                        }

                        if (checkMin < min && checkMin >= 0) {
                            min = checkMin;
                        } else if (checkMin < 0) {
                            min += checkMin;
                        }

                        max += checkMax;
                        if (checkMin < minTotal && checkMin >= 0) {
                            minTotal = checkMin;
                        } else if (checkMin < 0) {
                            minTotal += checkMin;
                        }
                        maxTotal += checkMax;
                    }
                    if ((item.type == 5 || item.type == 6 || item.type == 12 || item.type == 13 || item.type == 14) && item.score) {
                        if (item.score < min && item.score >= 0) {
                            min = item.score;
                        } else if (item.score < 0) {
                            min += item.score;
                        }
                        // max += item.score;
                        if (item.score < minTotal && item.score >= 0) {
                            minTotal = item.score;
                        } else if (item.score < 0) {
                            minTotal += item.score;
                        }
                        // maxTotal += item.score;
                        if (item.score > 0) {
                            max += item.score;
                            maxTotal += item.score;
                        }
                    }

                }
            }
            for (let i = 0; i < blocks.length; i++) {
                let min = blocks[i].min;
                let max = blocks[i].max;
                for (let j = 0; j < items.length; j++) {
                    if (items[j].id == blocks[i].id) {
                        let checkBlocks = items[j].blocks;
                        if (checkBlocks && checkBlocks.length) {
                            let newBlocks = [];
                            for (let b = 0; b < checkBlocks.length; b++) {
                                let push = true;
                                if (checkBlocks[b].start < min && checkBlocks[b].end >= min) {
                                    checkBlocks[b].start = min;
                                } else if (checkBlocks[b].end < min) {
                                    push = false;
                                }

                                if (checkBlocks[b].end > max && checkBlocks[b].start <= max) {
                                    checkBlocks[b].end = max;
                                } else if (checkBlocks[b].start > max) {
                                    push = false;
                                }

                                if (push) {
                                    newBlocks.push(checkBlocks[b])
                                }
                            }
                            items[j].blocks = newBlocks;
                        }

                    }
                }
                this.setState({ items })
            }


            this.setState({ blocksRange: blocks })
        }

    }



    render() {
        let params = {};
        if (this.state.useState) {
            params = this.state.params;
        } else {
            params = getSearchParams(this.props[0].location.search, { entries: 10, page: 0 })
        }

        let selectedGroupIdx = 0;
        if (this.props.selectedGroup && this.props.uData?.clinicGroups?.length) {
            for (let i = 0; i < this.props.uData.clinicGroups.length; i++) {
                if (this.props.uData.clinicGroups[i]._id == this.props.selectedGroup) {
                    selectedGroupIdx = i;
                    break;
                }
            }
        }

        let articlesDict = {};
        for (let i = 0; i < this.state.articles.length; i++) {
            articlesDict[this.state.articles[i]._id] = this.state.articles[i];
        }

        const bmiIndex = ['#9ABBDA', '#93CCA9', '#FFE02B', '#EAA353', '#D8414A'];


        return (
            <div className={this.props.autoHideSidebar ? "dashboard dashboard-auto-hide" : "dashboard"}>

                <Container fluid>
                    <Row>
                        {/* <Col lg="12">
                            {this.state.message ?
                                <UncontrolledAlert color="success">
                                    {this.state.message.translate(this.props.lang)}
                                </UncontrolledAlert>
                                :
                                null
                            }
                        </Col> */}
                        <Col lg="12">
                            <div className="panel">

                                <div className='forms-builder-title'>
                                    <h4>{this.props[0].match.params.id == 'new' ? 'Add form'.translate(this.props.lang) : 'Edit form'.translate(this.props.lang)}
                                    </h4>
                                    <Button color='primary' onClick={() => {
                                        if (this.state.detectedChage) {
                                            this.setState({ notSavedModal: true })
                                        } else {

                                            let activeTab;

                                            if (this.state.formType == 'visit-forms') {
                                                activeTab = 1;
                                            } else if (this.state.formType == 'revisit-forms') {
                                                activeTab = 2;
                                            } else if (this.state.formType == 'chat-forms') {
                                                activeTab = 3;
                                            } else if (this.state.formType == 'daybook-forms') {
                                                activeTab = 4;
                                            }
                                            this.props[0].history.push({
                                                pathname: '/data-managment/form-importer',
                                                state: { activeTab: activeTab }
                                            })
                                        }

                                    }}><Isvg src={backIcon} /></Button>
                                </div>

                                <Row>
                                    {
                                        this.state.duplicateBlock ?
                                            <InfoModal
                                                isOpen={this.state.duplicateBlock}
                                                onClose={(item) => {
                                                    this.setState({
                                                        duplicateBlock: item
                                                    })
                                                }}
                                                toggle={() => this.setState({ duplicateBlock: !this.state.duplicateBlock })}
                                                header={'Warning'.translate(this.props.lang)}
                                                info={this.state.duplicateBlock}
                                                buttons={[

                                                    <Button color='primary' onClick={() => {
                                                        this.setState({ duplicateBlock: null })
                                                    }}>{'Ok'.translate(this.props.lang)}</Button>


                                                ]}
                                            />
                                            :
                                            null
                                    }

                                    <Col lg="6">
                                        <FormGroup>
                                            <Label>{'Name'.translate(this.props.lang)}*</Label>
                                            <Input size="sm" value={this.state.name} onChange={(e) => this.setState({ name: e.target.value }, () => {
                                                this.checkActiveSave()
                                                this.detectChange()
                                            })} />
                                        </FormGroup>
                                        {
                                            this.props[0].match.params.id == 'new' ?
                                                <FormGroup>
                                                    <Label>{'Form type'.translate(this.props.lang)}</Label>
                                                    <Input type='select' value={this.state.formType} onChange={(e) => {
                                                        let value = e.target.value;
                                                        this.setState({ formType: value })
                                                    }}>
                                                        <option value={'visit-forms'}>{'Visit forms'.translate(this.props.lang)}</option>
                                                        <option value={'revisit-forms'}>{'Revisit forms'.translate(this.props.lang)}</option>
                                                        <option value={'chat-forms'}>{'Chat forms'.translate(this.props.lang)}</option>
                                                        <option value={'daybook-forms'}>{'Diary forms'.translate(this.props.lang)}</option>
                                                    </Input>
                                                </FormGroup>
                                                :
                                                null
                                        }




                                    </Col>

                                    <Col lg="12">
                                        <div className='form-builder-clinic-groups-header-wrap'>
                                            <h6>{'List of clinic groups'.translate(this.props.lang)}</h6>
                                            <div className='form-builder-clinic-groups-mark-buttons'>
                                                <Button color='primary' onClick={() => {
                                                    if (this.state.clinicGroupsList && this.state.clinicGroupsList.length) {
                                                        let clinicGroups = [];
                                                        for (let i = 0; i < this.state.clinicGroupsList.length; i++) {
                                                            clinicGroups.push(this.state.clinicGroupsList[i]._id)
                                                        }
                                                        this.setState({ clinicGroups })
                                                    }
                                                }}>{'Mark all'.translate(this.props.lang)}</Button>
                                                <Button color='primary' onClick={() => {
                                                    this.setState({ clinicGroups: [] })
                                                }}>{'Unmark all'.translate(this.props.lang)}</Button>
                                            </div>
                                        </div>

                                        <hr />

                                        <div className='form-builder-clinic-groups-wrap'>
                                            {
                                                this.state.clinicGroupsList && this.state.clinicGroupsList.map((item, idx) => {
                                                    return (
                                                        <Checkbox checked={this.state.clinicGroups.filter(el => el == item._id).length ? true : false}
                                                            onChange={(e) => {
                                                                let value = e.target.checked;
                                                                let clinicGroups = this.state.clinicGroups;
                                                                if (value) {
                                                                    if (clinicGroups && clinicGroups.filter(el => el == item._id).length == 0) {
                                                                        clinicGroups.push(item._id);
                                                                    }
                                                                } else {
                                                                    if (clinicGroups && clinicGroups.filter(el => el == item._id).length) {
                                                                        let index = clinicGroups.findIndex(el => el == item._id)
                                                                        if (index > -1) {
                                                                            clinicGroups.splice(index, 1)
                                                                        }
                                                                    }
                                                                }
                                                                this.setState({
                                                                    clinicGroups
                                                                }, () => {
                                                                    this.detectChange()
                                                                })
                                                            }}
                                                            label={item.name} />
                                                    )
                                                })
                                            }
                                        </div>
                                        <hr />
                                    </Col>

                                    <Col lg="6">


                                        {
                                            this.state.formType && this.state.formType != 'daybook-forms' ?
                                                <FormGroup>
                                                    <div >
                                                        <Checkbox checked={this.state.intelligentReferral} onChange={(e) => {
                                                            this.setState({
                                                                intelligentReferral: e.target.checked
                                                            }, () => {
                                                                this.detectChange()
                                                            })
                                                        }} label={'Intelligent form builder'.translate(this.props.lang)} />
                                                    </div>
                                                </FormGroup>
                                                :
                                                null
                                        }

                                    </Col>

                                    {this.state.openPageWarningModal ?
                                        <InfoModal
                                            isOpen={this.state.openPageWarningModal}
                                            onClose={(item) => {
                                                this.setState({
                                                    openPageWarningModal: item
                                                })
                                            }}
                                            toggle={() => this.setState({ openPageWarningModal: !this.state.openPageWarningModal })}
                                            header={'Warning'.translate(this.props.lang)}
                                            info={'Make sure the form works as desired!'.translate(this.props.lang)}
                                            buttons={[

                                                <Button color='primary' onClick={() => {
                                                    if (this.state.openPageWarningModal == 1) {
                                                        this.updateQuestionary(true)
                                                    } else if (this.state.openPageWarningModal == 2) {
                                                        this.updateQuestionary(false)
                                                    }
                                                }}>{'Ok'.translate(this.props.lang)}</Button>


                                            ]}
                                        />

                                        :

                                        null}
                                    <Col lg='12'>
                                        <FormGroup className="questionary-buttons">
                                            <Button onClick={() => this.setState({ openPageWarningModal: 1 })} size="sm" color="primary" disabled={this.state.checkActiveSave ? false : true}>{'Save'.translate(this.props.lang)}</Button>

                                            <Button size="sm" style={{ marginLeft: 10 }} onClick={() => this.setState({
                                                previewModal: true
                                            })} color="primary">
                                                {'Preview'.translate(this.props.lang)}
                                            </Button>

                                        </FormGroup>
                                    </Col>
                                </Row>

                            </div>
                        </Col>


                        <Col lg="12">
                            <div className="panel build-questionary-section">

                                <ul className={this.state.showLink ? "linked-elements active" : "linked-elements"}>
                                    {
                                        this.state.items.map((item, idx) => {
                                            if (this.conditionIndexOf(this.state.showLink, item.id) !== -1)
                                                return (
                                                    <li>
                                                        <div>
                                                            <span>{idx + 1}.</span>
                                                            {
                                                                item.type == 1 || item.type == 2 ?
                                                                    <FormGroup tag="fieldset">
                                                                        <Label size="sm">{item.name}</Label>
                                                                        {
                                                                            item.answers.map((answer, aidx) => {
                                                                                return (
                                                                                    <FormGroup check>
                                                                                        <Label size="sm" check>
                                                                                            <Input disabled size="sm" name={item.id} type={item.type == 1 ? 'radio' : 'checkbox'} />
                                                                                            {' '}
                                                                                            {(this.state.showLink && this.conditionIndexOf(this.state.showLink, item.id, answer.id) != -1) ?
                                                                                                <button className={(this.state.showLink && this.conditionIndexOf(this.state.showLink, item.id, answer.id) != -1) || (this.state.linkingElement !== null && this.state.items[this.state.linkingElement].conditions
                                                                                                    && this.state.items[this.state.linkingElement].conditions.length && this.conditionIndexOf(this.state.items[this.state.linkingElement].conditions, item.id, answer.id) != -1) ? 'linking-element active' : 'linking-element'}
                                                                                                    onClick={() => {
                                                                                                        let items = this.state.items;
                                                                                                        if (!items[this.state.linkingElement].conditions) {
                                                                                                            items[this.state.linkingElement].conditions = [];
                                                                                                        }

                                                                                                        let check = -1;
                                                                                                        for (let i = 0; i < items[this.state.linkingElement].conditions.length; i++) {
                                                                                                            if (items[this.state.linkingElement].conditions[i].conditionQuestion == item.id && items[this.state.linkingElement].conditions[i].conditionAnswer == answer.id) {
                                                                                                                check = i;
                                                                                                            }
                                                                                                        }


                                                                                                        if (check != -1) {
                                                                                                            items[this.state.linkingElement].conditions.splice(check, 1);
                                                                                                        } else {
                                                                                                            items[this.state.linkingElement].conditions.push({
                                                                                                                conditionAnswer: answer.id,
                                                                                                                conditionQuestion: item.id
                                                                                                            })
                                                                                                        }

                                                                                                        /*items[this.state.linkingElement].conditionQuestion = item.id;
                                                                                                        items[this.state.linkingElement].conditionAnswer = answer.id;
                                                                                                        */
                                                                                                        this.setState({
                                                                                                            items,
                                                                                                            // linkingElement: null
                                                                                                        }, () => this.detectChange());

                                                                                                    }}>{answer.name}</button>
                                                                                                :
                                                                                                <>
                                                                                                    {answer.name}
                                                                                                </>
                                                                                            }
                                                                                        </Label>
                                                                                    </FormGroup>
                                                                                )
                                                                            })
                                                                        }

                                                                    </FormGroup>
                                                                    :
                                                                    null
                                                            }
                                                            {
                                                                item.type == 16 || item.type == 17 ?
                                                                    <FormGroup tag="fieldset">
                                                                        <Label size="sm">{item.name}</Label>
                                                                        {
                                                                            item.blocks.map((block, bidx) => {
                                                                                return (
                                                                                    <FormGroup check>
                                                                                        <Label size="sm">
                                                                                            {(this.state.showLink && this.conditionIndexOf(this.state.showLink, item.id, block.id) != -1) ?
                                                                                                <button className={(this.state.showLink && this.conditionIndexOf(this.state.showLink, item.id, block.id) != -1) || (this.state.linkingElement !== null && this.state.items[this.state.linkingElement]?.conditions && this.state.items[this.state.linkingElement].conditions.length && this.conditionIndexOf(this.state.items[this.state.linkingElement].conditions, item.id, block.id) != -1) ? 'linking-element active' : 'linking-element'} onClick={() => {
                                                                                                    let items = this.state.items;
                                                                                                    if (!items[this.state.linkingElement].conditions) {
                                                                                                        items[this.state.linkingElement].conditions = [];
                                                                                                    }

                                                                                                    let check = -1;
                                                                                                    for (let i = 0; i < items[this.state.linkingElement].conditions.length; i++) {
                                                                                                        if (items[this.state.linkingElement].conditions[i].conditionQuestion == item.id && items[this.state.linkingElement].conditions[i].conditionAnswer == block.id) {
                                                                                                            check = i;
                                                                                                        }
                                                                                                    }

                                                                                                    if (check != -1) {
                                                                                                        items[this.state.linkingElement].conditions.splice(check, 1);
                                                                                                    } else {
                                                                                                        items[this.state.linkingElement].conditions.push({
                                                                                                            conditionAnswer: block.id,
                                                                                                            conditionQuestion: item.id
                                                                                                        })
                                                                                                    }

                                                                                                    this.setState({
                                                                                                        items,
                                                                                                    }, () => this.detectChange());

                                                                                                }}>{'Block'.translate(this.props.lang)} {block.start == block.end ? block.start : `${block.start}-${block.end}`}</button>
                                                                                                :
                                                                                                <>
                                                                                                    {'Block'.translate(this.props.lang)} {block.start == block.end ? block.start : `${block.start}-${block.end}`}
                                                                                                </>
                                                                                            }
                                                                                        </Label>
                                                                                    </FormGroup>
                                                                                )
                                                                            })
                                                                        }

                                                                    </FormGroup>
                                                                    :
                                                                    null
                                                            }
                                                            {
                                                                item.type == 3 || item.type == 5 ?
                                                                    <FormGroup tag="fieldset">
                                                                        <Label size="sm">
                                                                            {(this.state.linkingElement !== null && this.state.linkingElement !== idx && this.state.linkingElement > idx) || (this.state.showLink && this.conditionIndexOf(this.state.showLink, item.id, null) != -1) ?
                                                                                <button className={(this.state.showLink && this.conditionIndexOf(this.state.showLink, item.id, null) != -1) || (this.state.linkingElement !== null && this.state.items[this.state.linkingElement]?.conditions && this.state.items[this.state.linkingElement].conditions.length && this.conditionIndexOf(this.state.items[this.state.linkingElement].conditions, item.id) != -1) ? 'linking-element active' : 'linking-element'} onClick={() => {
                                                                                    let items = this.state.items;

                                                                                    if (!items[this.state.linkingElement].conditions) {
                                                                                        items[this.state.linkingElement].conditions = [];
                                                                                    }

                                                                                    let check = -1;
                                                                                    for (let i = 0; i < items[this.state.linkingElement].conditions.length; i++) {
                                                                                        if (items[this.state.linkingElement].conditions[i].conditionQuestion == item.id) {
                                                                                            check = i;
                                                                                        }
                                                                                    }

                                                                                    if (check != -1) {
                                                                                        items[this.state.linkingElement].conditions.splice(check, 1);
                                                                                    } else {
                                                                                        items[this.state.linkingElement].conditions.push({
                                                                                            conditionQuestion: item.id
                                                                                        })
                                                                                    }


                                                                                    this.setState({
                                                                                        items,
                                                                                        //linkingElement: null
                                                                                    }, () => this.detectChange());

                                                                                }}>{item.name}</button>

                                                                                :
                                                                                <input type="text" value={item.name} onChange={(e) => {
                                                                                    let items = this.state.items;
                                                                                    items[idx].name = e.target.value;
                                                                                    this.setState({
                                                                                        items
                                                                                    }, () => this.detectChange())
                                                                                }} className="edit-field-name" />
                                                                            }
                                                                        </Label>

                                                                        <Input disabled size="sm" type="text"></Input>


                                                                    </FormGroup>

                                                                    :
                                                                    null
                                                            }
                                                            {
                                                                item.type == 6 ?
                                                                    <FormGroup tag="fieldset">
                                                                        <Label size="sm">
                                                                            {(this.state.linkingElement !== null && this.state.linkingElement !== idx && this.state.linkingElement > idx) || (this.state.showLink && this.conditionIndexOf(this.state.showLink, item.id, null) != -1) ?
                                                                                <button className={(this.state.showLink && this.conditionIndexOf(this.state.showLink, item.id, null) != -1) || (this.state.linkingElement !== null && this.state.items[this.state.linkingElement]?.conditions && this.state.items[this.state.linkingElement].conditions.length && this.conditionIndexOf(this.state.items[this.state.linkingElement].conditions, item.id) != -1) ? 'linking-element active' : 'linking-element'} onClick={() => {
                                                                                    let items = this.state.items;
                                                                                    //items[this.state.linkingElement].conditionQuestion = item.id;

                                                                                    if (!items[this.state.linkingElement].conditions) {
                                                                                        items[this.state.linkingElement].conditions = [];
                                                                                    }

                                                                                    let check = -1;
                                                                                    for (let i = 0; i < items[this.state.linkingElement].conditions.length; i++) {
                                                                                        if (items[this.state.linkingElement].conditions[i].conditionQuestion == item.id) {
                                                                                            check = i;
                                                                                        }
                                                                                    }

                                                                                    if (check != -1) {
                                                                                        items[this.state.linkingElement].conditions.splice(check, 1);
                                                                                    } else {
                                                                                        items[this.state.linkingElement].conditions.push({
                                                                                            conditionQuestion: item.id
                                                                                        })
                                                                                    }


                                                                                    this.setState({
                                                                                        items,
                                                                                        //linkingElement: null
                                                                                    }, () => this.detectChange());

                                                                                }}>{item.name}</button>

                                                                                :
                                                                                <input type="text" value={item.name} onChange={(e) => {
                                                                                    let items = this.state.items;
                                                                                    items[idx].name = e.target.value;
                                                                                    this.setState({
                                                                                        items
                                                                                    }, () => this.detectChange())
                                                                                }} className="edit-field-name" />
                                                                            }
                                                                        </Label>

                                                                        <Row>
                                                                            <Col lg="2">
                                                                                <FormGroup>
                                                                                    <Label>{'Weight:'.translate(this.props.lang)}</Label>
                                                                                    <Input disabled size="sm" type="text"></Input>

                                                                                </FormGroup>
                                                                            </Col>
                                                                            <Col lg="2">
                                                                                <FormGroup>
                                                                                    <Label>{'Height:'.translate(this.props.lang)}</Label>
                                                                                    <Input disabled size="sm" type="text"></Input>

                                                                                </FormGroup>

                                                                            </Col>
                                                                        </Row>


                                                                    </FormGroup>

                                                                    :
                                                                    null
                                                            }
                                                            {
                                                                item.type == 9 ?
                                                                    <FormGroup tag="fieldset">
                                                                        <Label size="sm">
                                                                            {/*
                                                                    <input style={cssStyle} type="text" value={item.name} onChange={(e) => {
                                                                        let items = this.state.items;
                                                                        items[idx].name = e.target.value;
                                                                        this.setState({
                                                                            items
                                                                        })
                                                                    }} />*/}
                                                                            {(this.state.linkingElement !== null && this.state.linkingElement !== idx && this.state.linkingElement > idx) || (this.state.showLink && this.conditionIndexOf(this.state.showLink, item.id, null) != -1) ?
                                                                                <button className={(this.state.showLink && this.conditionIndexOf(this.state.showLink, item.id, null) != -1) || (this.state.linkingElement !== null && this.state.items[this.state.linkingElement]?.conditions && this.state.items[this.state.linkingElement].conditions.length && this.conditionIndexOf(this.state.items[this.state.linkingElement].conditions, item.id) != -1) ? 'linking-element active' : 'linking-element'} onClick={() => {
                                                                                    let items = this.state.items;
                                                                                    //items[this.state.linkingElement].conditionQuestion = item.id;

                                                                                    if (!items[this.state.linkingElement].conditions) {
                                                                                        items[this.state.linkingElement].conditions = [];
                                                                                    }

                                                                                    let check = -1;
                                                                                    for (let i = 0; i < items[this.state.linkingElement].conditions.length; i++) {
                                                                                        if (items[this.state.linkingElement].conditions[i].conditionQuestion == item.id) {
                                                                                            check = i;
                                                                                        }
                                                                                    }

                                                                                    if (check != -1) {
                                                                                        items[this.state.linkingElement].conditions.splice(check, 1);
                                                                                    } else {
                                                                                        items[this.state.linkingElement].conditions.push({
                                                                                            conditionQuestion: item.id
                                                                                        })
                                                                                    }


                                                                                    this.setState({
                                                                                        items,
                                                                                        //linkingElement: null
                                                                                    }, () => this.detectChange());

                                                                                }}>{item.name}</button>

                                                                                :
                                                                                <>
                                                                                    <input style={cssStyle} type="text" value={item.name} onChange={(e) => {
                                                                                        let items = this.state.items;
                                                                                        items[idx].name = e.target.value;
                                                                                        this.setState({
                                                                                            items
                                                                                        }, () => this.detectChange())
                                                                                    }} className="edit-field-name" />
                                                                                    <div className="text-options">
                                                                                        <button className={item.fontStyle == 'italic' ? 'active' : ''} onClick={() => { let items = this.state.items; items[idx].fontStyle = items[idx].fontStyle == 'italic' ? null : 'italic'; this.setState({ items }, () => this.detectChange()) }}><i className="fa fa-italic" /></button>
                                                                                        <button className={item.fontWeight == 'bold' ? 'active' : ''} onClick={() => { let items = this.state.items; items[idx].fontWeight = items[idx].fontWeight == 'bold' ? null : 'bold'; this.setState({ items }, () => this.detectChange()) }}><i className="fa fa-bold" /></button>

                                                                                        <UncontrolledDropdown size="sm">
                                                                                            <DropdownToggle caret>
                                                                                                <i className="fa fa-font" />
                                                                                            </DropdownToggle>
                                                                                            <DropdownMenu>
                                                                                                <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 10; this.setState({ items }, () => this.detectChange()) }}>10px</DropdownItem>
                                                                                                <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 12; this.setState({ items }, () => this.detectChange()) }}>12px</DropdownItem>
                                                                                                <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 14; this.setState({ items }, () => this.detectChange()) }}>14px</DropdownItem>
                                                                                                <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 16; this.setState({ items }, () => this.detectChange()) }}>16px</DropdownItem>
                                                                                                <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 18; this.setState({ items }, () => this.detectChange()) }}>18px</DropdownItem>
                                                                                                <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 20; this.setState({ items }, () => this.detectChange()) }}>20px</DropdownItem>
                                                                                            </DropdownMenu>
                                                                                        </UncontrolledDropdown>
                                                                                    </div>

                                                                                </>

                                                                            }

                                                                        </Label>

                                                                        <Input size="sm" disabled type="file" title={"No file chosen".translate(this.props.lang)} accept="image/png, image/jpeg, image/jpg, image/gif, image/svg, .doc, .docx, .pdf" >{'Select Image'.translate(this.props.lang)}</Input>


                                                                    </FormGroup>

                                                                    :
                                                                    null
                                                            }
                                                            {
                                                                item.type == 12 ?
                                                                    <FormGroup tag="fieldset">
                                                                        <Label size="sm">
                                                                            {(this.state.linkingElement !== null && this.state.linkingElement !== idx && this.state.linkingElement > idx) || (this.state.showLink && this.conditionIndexOf(this.state.showLink, item.id, null) != -1) ?
                                                                                <button className={(this.state.showLink && this.conditionIndexOf(this.state.showLink, item.id, null) != -1) || (this.state.linkingElement !== null && this.state.items[this.state.linkingElement]?.conditions && this.state.items[this.state.linkingElement].conditions.length && this.conditionIndexOf(this.state.items[this.state.linkingElement].conditions, item.id) != -1) ? 'linking-element active' : 'linking-element'} onClick={() => {
                                                                                    let items = this.state.items;
                                                                                    //items[this.state.linkingElement].conditionQuestion = item.id;

                                                                                    if (!items[this.state.linkingElement].conditions) {
                                                                                        items[this.state.linkingElement].conditions = [];
                                                                                    }

                                                                                    let check = -1;
                                                                                    for (let i = 0; i < items[this.state.linkingElement].conditions.length; i++) {
                                                                                        if (items[this.state.linkingElement].conditions[i].conditionQuestion == item.id) {
                                                                                            check = i;
                                                                                        }
                                                                                    }

                                                                                    if (check != -1) {
                                                                                        items[this.state.linkingElement].conditions.splice(check, 1);
                                                                                    } else {
                                                                                        items[this.state.linkingElement].conditions.push({
                                                                                            conditionQuestion: item.id
                                                                                        })
                                                                                    }


                                                                                    this.setState({
                                                                                        items,
                                                                                        //linkingElement: null
                                                                                    }, () => this.detectChange());

                                                                                }}>{item.name}</button>

                                                                                :
                                                                                <input type="text" value={item.name} onChange={(e) => {
                                                                                    let items = this.state.items;
                                                                                    items[idx].name = e.target.value;
                                                                                    this.setState({
                                                                                        items
                                                                                    }, () => this.detectChange())
                                                                                }} className="edit-field-name" />
                                                                            }
                                                                        </Label>

                                                                        <Row>
                                                                            <Col lg="2">
                                                                                <FormGroup>
                                                                                    <Label>{'Systolic:'.translate(this.props.lang)}</Label>
                                                                                    <Input disabled size="sm" type="text"></Input>

                                                                                </FormGroup>
                                                                            </Col>
                                                                            <Col lg="2">
                                                                                <FormGroup>
                                                                                    <Label>{'Diastolic:'.translate(this.props.lang)}</Label>
                                                                                    <Input disabled size="sm" type="text"></Input>

                                                                                </FormGroup>

                                                                            </Col>
                                                                            <Col lg="2">
                                                                                <FormGroup>
                                                                                    <Label>{'Pulse:'.translate(this.props.lang)}</Label>
                                                                                    <Input disabled size="sm" type="text"></Input>

                                                                                </FormGroup>

                                                                            </Col>
                                                                        </Row>


                                                                    </FormGroup>

                                                                    :
                                                                    null
                                                            }
                                                            {
                                                                item.type == 13 ?
                                                                    <FormGroup tag="fieldset">
                                                                        <Label size="sm">
                                                                            {(this.state.linkingElement !== null && this.state.linkingElement !== idx && this.state.linkingElement > idx) || (this.state.showLink && this.conditionIndexOf(this.state.showLink, item.id, null) != -1) ?
                                                                                <button className={(this.state.showLink && this.conditionIndexOf(this.state.showLink, item.id, null) != -1) || (this.state.linkingElement !== null && this.state.items[this.state.linkingElement]?.conditions && this.state.items[this.state.linkingElement].conditions.length && this.conditionIndexOf(this.state.items[this.state.linkingElement].conditions, item.id) != -1) ? 'linking-element active' : 'linking-element'} onClick={() => {
                                                                                    let items = this.state.items;
                                                                                    //items[this.state.linkingElement].conditionQuestion = item.id;

                                                                                    if (!items[this.state.linkingElement].conditions) {
                                                                                        items[this.state.linkingElement].conditions = [];
                                                                                    }

                                                                                    let check = -1;
                                                                                    for (let i = 0; i < items[this.state.linkingElement].conditions.length; i++) {
                                                                                        if (items[this.state.linkingElement].conditions[i].conditionQuestion == item.id) {
                                                                                            check = i;
                                                                                        }
                                                                                    }

                                                                                    if (check != -1) {
                                                                                        items[this.state.linkingElement].conditions.splice(check, 1);
                                                                                    } else {
                                                                                        items[this.state.linkingElement].conditions.push({
                                                                                            conditionQuestion: item.id
                                                                                        })
                                                                                    }


                                                                                    this.setState({
                                                                                        items,
                                                                                        //linkingElement: null
                                                                                    }, () => this.detectChange());

                                                                                }}>{item.name}</button>

                                                                                :
                                                                                <input type="text" value={item.name} onChange={(e) => {
                                                                                    let items = this.state.items;
                                                                                    items[idx].name = e.target.value;
                                                                                    this.setState({
                                                                                        items
                                                                                    }, () => this.detectChange())
                                                                                }} className="edit-field-name" />
                                                                            }
                                                                        </Label>

                                                                        <Row>
                                                                            <Col lg="2">
                                                                                <FormGroup>
                                                                                    <Input disabled size="sm" type="text"></Input>

                                                                                </FormGroup>
                                                                            </Col>

                                                                        </Row>


                                                                    </FormGroup>

                                                                    :
                                                                    null
                                                            }

                                                            {
                                                                item.type == 4 ?

                                                                    <FormGroup tag="fieldset">
                                                                        <Label size="sm">{item.name}</Label>


                                                                        <div dangerouslySetInnerHTML={{ __html: item.text }}>

                                                                        </div>
                                                                        <FormGroup>
                                                                            <Checkbox checked={item.userInput ? true : false} label={'Alert the patient'.translate(this.props.lang)} />

                                                                        </FormGroup>


                                                                    </FormGroup>
                                                                    :
                                                                    null
                                                            }
                                                            {
                                                                item.type == 15 ?

                                                                    <FormGroup tag="fieldset">
                                                                        <Label size="sm">{item.name}</Label>


                                                                        <div dangerouslySetInnerHTML={{ __html: item.text }}>

                                                                        </div>


                                                                    </FormGroup>
                                                                    :
                                                                    null
                                                            }



                                                        </div>
                                                    </li>
                                                )
                                        })
                                    }
                                </ul>

                                <SortableList helperClass="test123"
                                    // onSortEnd={({ oldIndex, newIndex }) => {
                                    //     let items = this.state.items;

                                    //     items = arrayMove(items, oldIndex, newIndex);
                                    //     this.setState({
                                    //         items: items
                                    //     })
                                    // }}
                                    onSortEnd={({ oldIndex, newIndex }) => {
                                        this.setState({ cursorNoDrop: false }, () => { document.body.style.cursor = 'default'; })
                                        let items = this.state.items;

                                        let checkOldIndex = this.checkRelations(items[oldIndex].id);

                                        let haveTotal = null;
                                        if (items && items.length && items[items.length - 1].type == 17) {
                                            haveTotal = items.length - 1;
                                        }
                                        if (haveTotal && newIndex >= haveTotal) {
                                            return;
                                        }

                                        if (oldIndex > newIndex) {
                                            let arr = [];
                                            if (checkOldIndex && checkOldIndex[0].length) {
                                                for (let i = 0; i < checkOldIndex[0].length; i++) {
                                                    arr.push(checkOldIndex[0][i].conditionElement.id)
                                                }


                                                let max = 0;
                                                for (let i = 0; i < arr.length; i++) {
                                                    for (let j = 0; j < items.length; j++) {
                                                        if (arr[i] == items[j].id && j > max) {
                                                            max = j;
                                                        }
                                                    }
                                                }

                                                if (newIndex > max) {
                                                    items = arrayMove(items, oldIndex, newIndex);
                                                    this.setState({
                                                        items: items
                                                    }, () => this.detectChange())
                                                } else {
                                                    this.setState({ messageModalError: true })
                                                }
                                            } else {
                                                items = arrayMove(items, oldIndex, newIndex);
                                                this.setState({
                                                    items: items
                                                }, () => this.detectChange())
                                            }

                                        }
                                        else {
                                            let arr = [];
                                            if (checkOldIndex && checkOldIndex[1].length) {
                                                for (let i = 0; i < checkOldIndex[1].length; i++) {
                                                    arr.push(checkOldIndex[1][i].element.id)
                                                }
                                                let min = items.length - 1;
                                                for (let i = 0; i < arr.length; i++) {
                                                    for (let j = 0; j < items.length; j++) {
                                                        if (arr[i] == items[j].id && j < min) {
                                                            min = j;
                                                        }
                                                    }
                                                }
                                                if (newIndex < min) {
                                                    items = arrayMove(items, oldIndex, newIndex);
                                                    this.setState({
                                                        items: items
                                                    }, () => this.detectChange())
                                                } else {
                                                    this.setState({ messageModalError: true })
                                                }
                                            } else {
                                                items = arrayMove(items, oldIndex, newIndex);
                                                this.setState({
                                                    items: items
                                                }, () => this.detectChange())
                                            }
                                        }
                                        document.body.style.cursor = 'default';

                                        this.detectChange()

                                    }}
                                    onSortOver={({ index, oldIndex, newIndex }) => {


                                        let items = this.state.items;

                                        let haveTotal = null;
                                        if (items && items.length && items[items.length - 1].type == 17) {
                                            haveTotal = items.length - 1;
                                        }

                                        if (haveTotal && newIndex >= haveTotal) {
                                            this.setState({ cursorNoDrop: true }, () => { document.body.style.cursor = 'no-drop'; })
                                            return;
                                        }

                                        let checkOldIndex = this.checkRelations(items[index].id);
                                        if (index > newIndex) {
                                            let arr = [];
                                            if (checkOldIndex && checkOldIndex[0].length) {
                                                for (let i = 0; i < checkOldIndex[0].length; i++) {
                                                    arr.push(checkOldIndex[0][i].conditionElement.id)
                                                }
                                                let max = 0;
                                                for (let i = 0; i < arr.length; i++) {
                                                    for (let j = 0; j < items.length; j++) {
                                                        if (arr[i] == items[j].id && j > max) {
                                                            max = j;
                                                        }
                                                    }
                                                }
                                                if (newIndex > max) {
                                                    this.setState({ cursorNoDrop: false }, () => { document.body.style.cursor = 'default'; })
                                                } else {
                                                    this.setState({ cursorNoDrop: true }, () => { document.body.style.cursor = 'no-drop'; })
                                                }
                                            } else {
                                                this.setState({ cursorNoDrop: false }, () => { document.body.style.cursor = 'default'; })
                                            }

                                        } else {
                                            let arr = [];
                                            if (checkOldIndex && checkOldIndex[1].length) {
                                                for (let i = 0; i < checkOldIndex[1].length; i++) {
                                                    arr.push(checkOldIndex[1][i].element.id)
                                                }
                                                let min = items.length - 1;
                                                for (let i = 0; i < arr.length; i++) {
                                                    for (let j = 0; j < items.length; j++) {
                                                        if (arr[i] == items[j].id && j < min) {
                                                            min = j;
                                                        }
                                                    }
                                                }
                                                if (newIndex < min) {
                                                    this.setState({ cursorNoDrop: false }, () => { document.body.style.cursor = 'default'; })
                                                } else {
                                                    this.setState({ cursorNoDrop: true }, () => { document.body.style.cursor = 'no-drop'; })
                                                }
                                            } else {
                                                this.setState({ cursorNoDrop: false }, () => { document.body.style.cursor = 'default'; })
                                            }
                                        }

                                        this.detectChange()

                                    }}
                                    useDragHandle items={this.state.items.slice(0, this.state.numberOfElementsToRender * 10).map((item, idx) => {
                                        let cssStyle = {};
                                        if (item.fontSize) {
                                            cssStyle.fontSize = item.fontSize;
                                        }
                                        if (item.fontWeight) {
                                            cssStyle.fontWeight = item.fontWeight;
                                        }
                                        if (item.fontStyle) {
                                            cssStyle.fontStyle = item.fontStyle;
                                        }


                                        return {
                                            component: (
                                                <div>

                                                    <div style={{ display: 'flex', marginTop: 15 }}>
                                                        {
                                                            item.type != 17 ?
                                                                <div className="drag-handle">
                                                                    <DragHandle><i className="fa fa-arrows" /> </DragHandle>
                                                                </div>
                                                                :
                                                                null
                                                        }
                                                        <span >{idx + 1}.</span>
                                                    </div>


                                                    {
                                                        item.type == 0 ?
                                                            <h4><input style={cssStyle} type="text" value={item.name} onChange={(e) => {
                                                                let items = this.state.items;
                                                                items[idx].name = e.target.value;
                                                                this.setState({
                                                                    items
                                                                }, () => this.detectChange())
                                                            }} className="edit-field-name m-l-45" />
                                                                <div className="text-options">
                                                                    <button className={item.fontStyle == 'italic' ? 'active' : ''} onClick={() => { let items = this.state.items; items[idx].fontStyle = items[idx].fontStyle == 'italic' ? null : 'italic'; this.setState({ items }, () => this.detectChange()) }}><i className="fa fa-italic" /></button>
                                                                    <button className={item.fontWeight == 'bold' ? 'active' : ''} onClick={() => { let items = this.state.items; items[idx].fontWeight = items[idx].fontWeight == 'bold' ? null : 'bold'; this.setState({ items }, () => this.detectChange()) }}><i className="fa fa-bold" /></button>

                                                                    <UncontrolledDropdown size="sm">
                                                                        <DropdownToggle caret>
                                                                            <i className="fa fa-font" />
                                                                        </DropdownToggle>
                                                                        <DropdownMenu>
                                                                            <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 10; this.setState({ items }, () => this.detectChange()) }}>10px</DropdownItem>
                                                                            <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 12; this.setState({ items }, () => this.detectChange()) }}>12px</DropdownItem>
                                                                            <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 14; this.setState({ items }, () => this.detectChange()) }}>14px</DropdownItem>
                                                                            <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 16; this.setState({ items }, () => this.detectChange()) }}>16px</DropdownItem>
                                                                            <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 18; this.setState({ items }, () => this.detectChange()) }}>18px</DropdownItem>
                                                                            <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 20; this.setState({ items }, () => this.detectChange()) }}>20px</DropdownItem>
                                                                        </DropdownMenu>
                                                                    </UncontrolledDropdown>
                                                                </div>



                                                            </h4>
                                                            :
                                                            null
                                                    }
                                                    {
                                                        item.type == 1 || item.type == 2 ?
                                                            <FormGroup tag="fieldset">
                                                                <Label size="sm"><input style={cssStyle} type="text" value={item.name} onChange={(e) => {
                                                                    let items = this.state.items;
                                                                    items[idx].name = e.target.value;
                                                                    this.setState({
                                                                        items
                                                                    }, () => this.detectChange())
                                                                }} className="edit-field-name" />
                                                                    {
                                                                        item.type == 2 && this.state.intelligentReferral && this.state.formType !== 'chat-forms' ?
                                                                            <>

                                                                                /

                                                                                <input style={cssStyle} type="text" value={item.answerPrefix} placeholder={'Prefix for answers'.translate(this.props.lang)} onChange={(e) => {
                                                                                    let items = this.state.items;
                                                                                    items[idx].answerPrefix = e.target.value;
                                                                                    this.setState({
                                                                                        items
                                                                                    }, () => this.detectChange())
                                                                                }} className="edit-field-name" />

                                                                            </>
                                                                            :
                                                                            null
                                                                    }
                                                                    <div className="text-options">
                                                                        <button className={item.fontStyle == 'italic' ? 'active' : ''} onClick={() => { let items = this.state.items; items[idx].fontStyle = items[idx].fontStyle == 'italic' ? null : 'italic'; this.setState({ items }, () => this.detectChange()) }}><i className="fa fa-italic" /></button>
                                                                        <button className={item.fontWeight == 'bold' ? 'active' : ''} onClick={() => { let items = this.state.items; items[idx].fontWeight = items[idx].fontWeight == 'bold' ? null : 'bold'; this.setState({ items }, () => this.detectChange()) }}><i className="fa fa-bold" /></button>

                                                                        <UncontrolledDropdown size="sm">
                                                                            <DropdownToggle caret>
                                                                                <i className="fa fa-font" />
                                                                            </DropdownToggle>
                                                                            <DropdownMenu>
                                                                                <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 10; this.setState({ items }, () => this.detectChange()) }}>10px</DropdownItem>
                                                                                <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 12; this.setState({ items }, () => this.detectChange()) }}>12px</DropdownItem>
                                                                                <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 14; this.setState({ items }, () => this.detectChange()) }}>14px</DropdownItem>
                                                                                <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 16; this.setState({ items }, () => this.detectChange()) }}>16px</DropdownItem>
                                                                                <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 18; this.setState({ items }, () => this.detectChange()) }}>18px</DropdownItem>
                                                                                <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 20; this.setState({ items }, () => this.detectChange()) }}>20px</DropdownItem>
                                                                            </DropdownMenu>
                                                                        </UncontrolledDropdown>
                                                                    </div>


                                                                </Label>
                                                                <SortableAnswers onSortEnd={({ oldIndex, newIndex }) => {
                                                                    let items = this.state.items;
                                                                    let answers = JSON.parse(JSON.stringify(items[idx].answers));
                                                                    //answers = arrayMove(answers, oldIndex, newIndex);
                                                                    let tmp = answers[newIndex];
                                                                    answers[newIndex] = answers[oldIndex];
                                                                    answers[oldIndex] = tmp;
                                                                    items[idx].answers = answers
                                                                    this.setState({
                                                                        items: items
                                                                    }, () => {
                                                                        this.detectChange();
                                                                        setTimeout(() => {
                                                                            this.forceUpdate()
                                                                        }, 50)
                                                                    })
                                                                }} useDragHandle items={
                                                                    item.answers.map((answer, aidx) => {
                                                                        return {
                                                                            component: (
                                                                                <FormGroup check key={answer.id}>

                                                                                    <Label size="sm" check>

                                                                                        <Input disabled size="sm" name={item.id} type={item.type == 1 ? 'radio' : 'checkbox'} />
                                                                                        {' '}

                                                                                        {(this.state.linkingElement !== null && this.state.linkingElement !== idx && this.state.linkingElement > idx) ?
                                                                                            <button className={(this.state.showLink && this.conditionIndexOf(this.state.showLink, item.id, answer.id) != -1) || (this.state.linkingElement !== null && this.state.items[this.state.linkingElement]?.conditions && this.state.items[this.state.linkingElement].conditions.length && this.conditionIndexOf(this.state.items[this.state.linkingElement].conditions, item.id, answer.id) != -1) ? 'linking-element active' : 'linking-element'} onClick={() => {
                                                                                                let items = this.state.items;
                                                                                                if (!items[this.state.linkingElement].conditions) {
                                                                                                    items[this.state.linkingElement].conditions = [];
                                                                                                }

                                                                                                let check = -1;
                                                                                                for (let i = 0; i < items[this.state.linkingElement].conditions.length; i++) {
                                                                                                    if (items[this.state.linkingElement].conditions[i].conditionQuestion == item.id && items[this.state.linkingElement].conditions[i].conditionAnswer == answer.id) {
                                                                                                        check = i;
                                                                                                    }
                                                                                                }

                                                                                                if (check != -1) {
                                                                                                    items[this.state.linkingElement].conditions.splice(check, 1);
                                                                                                } else {
                                                                                                    items[this.state.linkingElement].conditions.push({
                                                                                                        conditionAnswer: answer.id,
                                                                                                        conditionQuestion: item.id
                                                                                                    })
                                                                                                }

                                                                                                /*items[this.state.linkingElement].conditionQuestion = item.id;
                                                                                                items[this.state.linkingElement].conditionAnswer = answer.id;
                                                                                                */
                                                                                                this.setState({
                                                                                                    items,
                                                                                                    // linkingElement: null
                                                                                                }, () => this.detectChange());

                                                                                            }}>{answer.name}</button>
                                                                                            :
                                                                                            <>
                                                                                                <input type="text" value={answer.name} placeholder={'Enter option name...'.translate(this.props.lang)} className={(this.state.showLink && this.conditionIndexOf(this.state.showLink, item.id, answer.id) != -1) ? 'edit-field-input edit-field-input-active' : "edit-field-input"} onChange={(e) => {
                                                                                                    let items = this.state.items;
                                                                                                    items[idx].answers[aidx].name = e.target.value;
                                                                                                    this.setState({
                                                                                                        items
                                                                                                    }, () => this.detectChange())
                                                                                                }} />

                                                                                                {this.state.intelligentReferral && this.state.formType !== 'chat-forms' ? <>
                                                                                                    <span className="slash">/</span>  <input type="text" value={answer.text} placeholder={'Enter text for printing on referral...'.translate(this.props.lang)} onChange={(e) => {
                                                                                                        let items = this.state.items;
                                                                                                        items[idx].answers[aidx].text = e.target.value;
                                                                                                        this.setState({
                                                                                                            items
                                                                                                        }, () => this.detectChange())
                                                                                                    }} className={(this.state.showLink && this.conditionIndexOf(this.state.showLink, item.id, answer.id) != -1) ? 'edit-field-input edit-field-input-active' : "edit-field-input"} />
                                                                                                </>
                                                                                                    :
                                                                                                    null
                                                                                                }
                                                                                            </>
                                                                                        }
                                                                                        {/*
                                                                                        <input type="text" value={answer.name} placeholder={'Enter option name...'.translate(this.props.lang)} className="edit-field-input" onChange={(e) => {
                                                                                            let items = this.state.items;
                                                                                            items[idx].answers[aidx].name = e.target.value;
                                                                                            this.setState({
                                                                                                items
                                                                                            })
                                                                                        }} />

                                                                                        {this.state.intelligentReferral ? <> / <input type="text" value={answer.text} placeholder={'Enter text for printing on referral...'.translate(this.props.lang)} onChange={(e) => {
                                                                                            let items = this.state.items;
                                                                                            items[idx].answers[aidx].text = e.target.value;
                                                                                            this.setState({
                                                                                                items
                                                                                            })
                                                                                        }} className="edit-field-input" /></>
                                                                                            :
                                                                                            null
                                                                                        }*/}
                                                                                        <div className="answer-score-wrap">
                                                                                            <Button color="primary" onClick={() => {
                                                                                                let items = this.state.items;
                                                                                                items[idx].answers[aidx].score = Number(items[idx].answers[aidx].score) - 1 <= -100 ? -100 : Number(items[idx].answers[aidx].score) - 1;
                                                                                                this.setState({
                                                                                                    items
                                                                                                }, () => {
                                                                                                    this.checkBlocksRange()
                                                                                                    this.detectChange()
                                                                                                })
                                                                                            }}>-</Button>
                                                                                            <input type="text" value={answer.score} onChange={(e) => {
                                                                                                let items = this.state.items;
                                                                                                let val = String(e.target.value).match(/[-]{0,1}[\d]*[.]{0,1}[\d]+/g);
                                                                                                val = val.length ? val[0] : 0;
                                                                                                val = Number(val);
                                                                                                items[idx].answers[aidx].score = val < -100 ? -100 : val > 100 ? 100 : val;
                                                                                                this.setState({
                                                                                                    items
                                                                                                }, () => {
                                                                                                    this.checkBlocksRange()
                                                                                                    this.detectChange()
                                                                                                })

                                                                                            }} />
                                                                                            <Button color="primary" onClick={() => {
                                                                                                let items = this.state.items;
                                                                                                items[idx].answers[aidx].score = Number(items[idx].answers[aidx].score) + 1 >= 100 ? 100 : Number(items[idx].answers[aidx].score) + 1;
                                                                                                this.setState({
                                                                                                    items
                                                                                                }, () => {
                                                                                                    this.checkBlocksRange()
                                                                                                    this.detectChange()
                                                                                                })
                                                                                            }}>+</Button>
                                                                                        </div>


                                                                                        <button className='referral-builder-tooltip-wrap' onClick={() => {
                                                                                            this.setState({
                                                                                                deleteOptionModal: { idx, aidx },
                                                                                            })
                                                                                        }}><Isvg style={{ minWidth: 22 }} src={garabage} />
                                                                                            <div className='referral-builder-tooltip'>{'Delete'.translate(this.props.lang)}</div>
                                                                                        </button>
                                                                                        <DragHandle>
                                                                                            <div><Isvg src={arrows} className="arrows" /></div> </DragHandle>

                                                                                    </Label>
                                                                                </FormGroup>
                                                                            )
                                                                        }

                                                                    })
                                                                } />

                                                                <div className="add-new-option-wrap">
                                                                    <button className="add-new-option" onClick={() => {
                                                                        let items = this.state.items;
                                                                        items[idx].answers.push({
                                                                            id: uuidv4(),
                                                                            name: '',
                                                                            score: 0
                                                                        });
                                                                        this.setState({
                                                                            items
                                                                        }, () => this.detectChange())
                                                                    }}> {'Add new option'.translate(this.props.lang)} </button>
                                                                    {
                                                                        item.type == 2 && item.answerPrefix && this.state.showLink && this.conditionIndexOf(this.state.showLink, item.id, '__user-input:') != -1 && !(this.state.linkingElement !== null && this.state.linkingElement !== idx && this.state.linkingElement > idx) ?
                                                                            <button className={'linking-element active'} >{'user-input'.translate(this.props.lang)}</button>
                                                                            :
                                                                            null
                                                                    }
                                                                    {item.type == 2 && item.answerPrefix && (this.state.linkingElement !== null && this.state.linkingElement !== idx && this.state.linkingElement > idx) ?
                                                                        <button className={(this.state.showLink && this.conditionIndexOf(this.state.showLink, item.id, '__user-input:') != -1) || (this.state.linkingElement !== null && this.state.items[this.state.linkingElement]?.conditions && this.state.items[this.state.linkingElement].conditions.length && this.conditionIndexOf(this.state.items[this.state.linkingElement].conditions, item.id, '__user-input:') != -1) ? 'linking-element active' : 'linking-element'} onClick={() => {
                                                                            let items = this.state.items;
                                                                            if (!items[this.state.linkingElement].conditions) {
                                                                                items[this.state.linkingElement].conditions = [];
                                                                            }

                                                                            let check = -1;
                                                                            for (let i = 0; i < items[this.state.linkingElement].conditions.length; i++) {
                                                                                if (items[this.state.linkingElement].conditions[i].conditionQuestion == item.id && items[this.state.linkingElement].conditions[i].conditionAnswer == '__user-input:') {
                                                                                    check = i;
                                                                                }
                                                                            }

                                                                            if (check != -1) {
                                                                                items[this.state.linkingElement].conditions.splice(check, 1);
                                                                            } else {
                                                                                items[this.state.linkingElement].conditions.push({
                                                                                    conditionAnswer: '__user-input:',
                                                                                    conditionQuestion: item.id
                                                                                })
                                                                            }

                                                                            this.setState({
                                                                                items,
                                                                                // linkingElement: null
                                                                            }, () => this.detectChange());

                                                                        }}>{'user-input'.translate(this.props.lang)}</button>
                                                                        :
                                                                        null
                                                                    }
                                                                    {item.type == 2 && item.answerPrefix ?
                                                                        <FormGroup>
                                                                            <Checkbox onChange={() => {
                                                                                let items = this.state.items;
                                                                                items[idx].userInput = !items[idx].userInput;
                                                                                this.setState({ items }, () => this.detectChange())
                                                                            }} checked={item.userInput ? true : false} label={'User can enter text answer'.translate(this.props.lang)} />
                                                                        </FormGroup>
                                                                        :
                                                                        null
                                                                    }
                                                                </div>

                                                            </FormGroup>
                                                            :
                                                            null
                                                    }
                                                    {
                                                        item.type == 3 || item.type == 5 ?
                                                            <FormGroup tag="fieldset">
                                                                <Label size="sm">

                                                                    {(this.state.linkingElement !== null && this.state.linkingElement !== idx && this.state.linkingElement > idx) || (this.state.showLink && this.conditionIndexOf(this.state.showLink, item.id, null) != -1) ?
                                                                        <button className={(this.state.showLink && this.conditionIndexOf(this.state.showLink, item.id, null) != -1) || (this.state.linkingElement !== null && this.state.items[this.state.linkingElement]?.conditions && this.state.items[this.state.linkingElement].conditions.length && this.conditionIndexOf(this.state.items[this.state.linkingElement].conditions, item.id) != -1) ? 'linking-element active' : 'linking-element'} onClick={() => {
                                                                            let items = this.state.items;
                                                                            //items[this.state.linkingElement].conditionQuestion = item.id;

                                                                            if (!items[this.state.linkingElement].conditions) {
                                                                                items[this.state.linkingElement].conditions = [];
                                                                            }

                                                                            let check = -1;
                                                                            for (let i = 0; i < items[this.state.linkingElement].conditions.length; i++) {
                                                                                if (items[this.state.linkingElement].conditions[i].conditionQuestion == item.id) {
                                                                                    check = i;
                                                                                }
                                                                            }

                                                                            if (check != -1) {
                                                                                items[this.state.linkingElement].conditions.splice(check, 1);
                                                                            } else {
                                                                                items[this.state.linkingElement].conditions.push({
                                                                                    conditionQuestion: item.id
                                                                                })
                                                                            }


                                                                            this.setState({
                                                                                items,
                                                                                //linkingElement: null
                                                                            }, () => this.detectChange());

                                                                        }}>{item.name}</button>

                                                                        :
                                                                        <>
                                                                            <input style={cssStyle} type="text" value={item.name} onChange={(e) => {
                                                                                let items = this.state.items;
                                                                                items[idx].name = e.target.value;
                                                                                this.setState({
                                                                                    items
                                                                                }, () => this.detectChange())
                                                                            }} className="edit-field-name" />
                                                                            {
                                                                                item.type == 3 && this.state.intelligentReferral ?
                                                                                    <>

                                                                                        /

                                                                                        <input style={cssStyle} type="text" value={item.answerPrefix} placeholder={'Prefix for answers'.translate(this.props.lang)} onChange={(e) => {
                                                                                            let items = this.state.items;
                                                                                            items[idx].answerPrefix = e.target.value;
                                                                                            this.setState({
                                                                                                items
                                                                                            }, () => this.detectChange())
                                                                                        }} className="edit-field-name" />

                                                                                    </>
                                                                                    :
                                                                                    null
                                                                            }
                                                                            <div className="text-options">
                                                                                <button className={item.fontStyle == 'italic' ? 'active' : ''} onClick={() => { let items = this.state.items; items[idx].fontStyle = items[idx].fontStyle == 'italic' ? null : 'italic'; this.setState({ items }, () => this.detectChange()) }}><i className="fa fa-italic" /></button>
                                                                                <button className={item.fontWeight == 'bold' ? 'active' : ''} onClick={() => { let items = this.state.items; items[idx].fontWeight = items[idx].fontWeight == 'bold' ? null : 'bold'; this.setState({ items }, () => this.detectChange()) }}><i className="fa fa-bold" /></button>

                                                                                <UncontrolledDropdown size="sm">
                                                                                    <DropdownToggle caret>
                                                                                        <i className="fa fa-font" />
                                                                                    </DropdownToggle>
                                                                                    <DropdownMenu>
                                                                                        <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 10; this.setState({ items }, () => this.detectChange()) }}>10px</DropdownItem>
                                                                                        <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 12; this.setState({ items }, () => this.detectChange()) }}>12px</DropdownItem>
                                                                                        <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 14; this.setState({ items }, () => this.detectChange()) }}>14px</DropdownItem>
                                                                                        <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 16; this.setState({ items }, () => this.detectChange()) }}>16px</DropdownItem>
                                                                                        <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 18; this.setState({ items }, () => this.detectChange()) }}>18px</DropdownItem>
                                                                                        <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 20; this.setState({ items }, () => this.detectChange()) }}>20px</DropdownItem>
                                                                                    </DropdownMenu>
                                                                                </UncontrolledDropdown>
                                                                            </div>
                                                                            {
                                                                                item.type == 5 ?
                                                                                    <div className="answer-score-wrap">
                                                                                        <Button color="primary" onClick={() => {
                                                                                            let items = this.state.items;
                                                                                            items[idx].score = Number(items[idx].score) - 1 <= -100 ? -100 : Number(items[idx].score) - 1;
                                                                                            this.setState({
                                                                                                items
                                                                                            }, () => {
                                                                                                this.checkBlocksRange()
                                                                                                this.detectChange()
                                                                                            })
                                                                                        }}>-</Button>
                                                                                        <input type="text" value={item.score} onChange={(e) => {
                                                                                            let items = this.state.items;
                                                                                            let val = String(e.target.value).match(/[-]{0,1}[\d]*[.]{0,1}[\d]+/g);
                                                                                            val = val.length ? val[0] : 0;
                                                                                            val = Number(val);
                                                                                            items[idx].score = val < -100 ? -100 : val > 100 ? 100 : val;
                                                                                            this.setState({
                                                                                                items
                                                                                            }, () => {
                                                                                                this.checkBlocksRange()
                                                                                                this.detectChange()
                                                                                            })

                                                                                        }} />
                                                                                        <Button color="primary" onClick={() => {
                                                                                            let items = this.state.items;
                                                                                            items[idx].score = Number(items[idx].score) + 1 >= 100 ? 100 : Number(items[idx].score) + 1;
                                                                                            this.setState({
                                                                                                items
                                                                                            }, () => {
                                                                                                this.checkBlocksRange()
                                                                                                this.detectChange()
                                                                                            })
                                                                                        }}>+</Button>
                                                                                    </div>
                                                                                    :
                                                                                    null
                                                                            }

                                                                        </>

                                                                    }

                                                                </Label>

                                                                <Input disabled size="sm" type="text"></Input>


                                                            </FormGroup>

                                                            :
                                                            null
                                                    }
                                                    {
                                                        item.type == 8 ?
                                                            <FormGroup tag="fieldset">
                                                                <Label size="sm">

                                                                    {(this.state.linkingElement !== null && this.state.linkingElement !== idx && this.state.linkingElement > idx) || (this.state.showLink && this.conditionIndexOf(this.state.showLink, item.id, null) != -1) ?
                                                                        <button className={(this.state.showLink && this.conditionIndexOf(this.state.showLink, item.id, null) != -1) || (this.state.linkingElement !== null && this.state.items[this.state.linkingElement]?.conditions && this.state.items[this.state.linkingElement].conditions.length && this.conditionIndexOf(this.state.items[this.state.linkingElement].conditions, item.id) != -1) ? 'linking-element active' : 'linking-element'} onClick={() => {
                                                                            let items = this.state.items;
                                                                            //items[this.state.linkingElement].conditionQuestion = item.id;

                                                                            if (!items[this.state.linkingElement].conditions) {
                                                                                items[this.state.linkingElement].conditions = [];
                                                                            }

                                                                            let check = -1;
                                                                            for (let i = 0; i < items[this.state.linkingElement].conditions.length; i++) {
                                                                                if (items[this.state.linkingElement].conditions[i].conditionQuestion == item.id) {
                                                                                    check = i;
                                                                                }
                                                                            }

                                                                            if (check != -1) {
                                                                                items[this.state.linkingElement].conditions.splice(check, 1);
                                                                            } else {
                                                                                items[this.state.linkingElement].conditions.push({
                                                                                    conditionQuestion: item.id
                                                                                })
                                                                            }


                                                                            this.setState({
                                                                                items,
                                                                                //linkingElement: null
                                                                            }, () => this.detectChange());

                                                                        }}>{item.name}</button>

                                                                        :
                                                                        <>
                                                                            <input style={cssStyle} type="text" value={item.name} onChange={(e) => {
                                                                                let items = this.state.items;
                                                                                items[idx].name = e.target.value;
                                                                                this.setState({
                                                                                    items
                                                                                }, () => this.detectChange())
                                                                            }} className="edit-field-name" />
                                                                            <div className="text-options">
                                                                                <button className={item.fontStyle == 'italic' ? 'active' : ''} onClick={() => { let items = this.state.items; items[idx].fontStyle = items[idx].fontStyle == 'italic' ? null : 'italic'; this.setState({ items }, () => this.detectChange()) }}><i className="fa fa-italic" /></button>
                                                                                <button className={item.fontWeight == 'bold' ? 'active' : ''} onClick={() => { let items = this.state.items; items[idx].fontWeight = items[idx].fontWeight == 'bold' ? null : 'bold'; this.setState({ items }, () => this.detectChange()) }}><i className="fa fa-bold" /></button>

                                                                                <UncontrolledDropdown size="sm">
                                                                                    <DropdownToggle caret>
                                                                                        <i className="fa fa-font" />
                                                                                    </DropdownToggle>
                                                                                    <DropdownMenu>
                                                                                        <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 10; this.setState({ items }, () => this.detectChange()) }}>10px</DropdownItem>
                                                                                        <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 12; this.setState({ items }, () => this.detectChange()) }}>12px</DropdownItem>
                                                                                        <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 14; this.setState({ items }, () => this.detectChange()) }}>14px</DropdownItem>
                                                                                        <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 16; this.setState({ items }, () => this.detectChange()) }}>16px</DropdownItem>
                                                                                        <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 18; this.setState({ items }, () => this.detectChange()) }}>18px</DropdownItem>
                                                                                        <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 20; this.setState({ items }, () => this.detectChange()) }}>20px</DropdownItem>
                                                                                    </DropdownMenu>
                                                                                </UncontrolledDropdown>
                                                                            </div>

                                                                        </>

                                                                    }

                                                                </Label>

                                                                <Input size="sm" type="file" title={"No file chosen".translate(this.props.lang)} accept=".doc, .docx, .pdf" >{'Select file'.translate(this.props.lang)}</Input>


                                                            </FormGroup>

                                                            :
                                                            null
                                                    }
                                                    {
                                                        item.type == 9 ?
                                                            <FormGroup tag="fieldset">
                                                                <Label size="sm">

                                                                    {(this.state.linkingElement !== null && this.state.linkingElement !== idx && this.state.linkingElement > idx) || (this.state.showLink && this.conditionIndexOf(this.state.showLink, item.id, null) != -1) ?
                                                                        <button className={(this.state.showLink && this.conditionIndexOf(this.state.showLink, item.id, null) != -1) || (this.state.linkingElement !== null && this.state.items[this.state.linkingElement]?.conditions && this.state.items[this.state.linkingElement].conditions.length && this.conditionIndexOf(this.state.items[this.state.linkingElement].conditions, item.id) != -1) ? 'linking-element active' : 'linking-element'} onClick={() => {
                                                                            let items = this.state.items;
                                                                            //items[this.state.linkingElement].conditionQuestion = item.id;

                                                                            if (!items[this.state.linkingElement].conditions) {
                                                                                items[this.state.linkingElement].conditions = [];
                                                                            }

                                                                            let check = -1;
                                                                            for (let i = 0; i < items[this.state.linkingElement].conditions.length; i++) {
                                                                                if (items[this.state.linkingElement].conditions[i].conditionQuestion == item.id) {
                                                                                    check = i;
                                                                                }
                                                                            }

                                                                            if (check != -1) {
                                                                                items[this.state.linkingElement].conditions.splice(check, 1);
                                                                            } else {
                                                                                items[this.state.linkingElement].conditions.push({
                                                                                    conditionQuestion: item.id
                                                                                })
                                                                            }


                                                                            this.setState({
                                                                                items,
                                                                                //linkingElement: null
                                                                            }, () => this.detectChange());

                                                                        }}>{item.name}</button>

                                                                        :
                                                                        <>
                                                                            <input style={cssStyle} type="text" value={item.name} onChange={(e) => {
                                                                                let items = this.state.items;
                                                                                items[idx].name = e.target.value;
                                                                                this.setState({
                                                                                    items
                                                                                }, () => this.detectChange())
                                                                            }} className="edit-field-name" />
                                                                            <div className="text-options">
                                                                                <button className={item.fontStyle == 'italic' ? 'active' : ''} onClick={() => { let items = this.state.items; items[idx].fontStyle = items[idx].fontStyle == 'italic' ? null : 'italic'; this.setState({ items }, () => this.detectChange()) }}><i className="fa fa-italic" /></button>
                                                                                <button className={item.fontWeight == 'bold' ? 'active' : ''} onClick={() => { let items = this.state.items; items[idx].fontWeight = items[idx].fontWeight == 'bold' ? null : 'bold'; this.setState({ items }, () => this.detectChange()) }}><i className="fa fa-bold" /></button>

                                                                                <UncontrolledDropdown size="sm">
                                                                                    <DropdownToggle caret>
                                                                                        <i className="fa fa-font" />
                                                                                    </DropdownToggle>
                                                                                    <DropdownMenu>
                                                                                        <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 10; this.setState({ items }, () => this.detectChange()) }}>10px</DropdownItem>
                                                                                        <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 12; this.setState({ items }, () => this.detectChange()) }}>12px</DropdownItem>
                                                                                        <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 14; this.setState({ items }, () => this.detectChange()) }}>14px</DropdownItem>
                                                                                        <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 16; this.setState({ items }, () => this.detectChange()) }}>16px</DropdownItem>
                                                                                        <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 18; this.setState({ items }, () => this.detectChange()) }}>18px</DropdownItem>
                                                                                        <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 20; this.setState({ items }, () => this.detectChange()) }}>20px</DropdownItem>
                                                                                    </DropdownMenu>
                                                                                </UncontrolledDropdown>
                                                                            </div>

                                                                        </>

                                                                    }

                                                                </Label>

                                                                <Input size="sm" type="file" title={"No file chosen".translate(this.props.lang)} accept="image/png, image/jpeg, image/jpg, image/gif, image/svg, .doc, .docx, .pdf" >{'Select Image'.translate(this.props.lang)}</Input>


                                                            </FormGroup>

                                                            :
                                                            null
                                                    }
                                                    {
                                                        item.type == 6 ?
                                                            <FormGroup tag="fieldset">
                                                                <Label size="sm">

                                                                    {(this.state.linkingElement !== null && this.state.linkingElement !== idx && this.state.linkingElement > idx) || (this.state.showLink && this.conditionIndexOf(this.state.showLink, item.id, null) != -1) ?
                                                                        <button className={(this.state.showLink && this.conditionIndexOf(this.state.showLink, item.id, null) != -1) || (this.state.linkingElement !== null && this.state.items[this.state.linkingElement]?.conditions && this.state.items[this.state.linkingElement].conditions.length && this.conditionIndexOf(this.state.items[this.state.linkingElement].conditions, item.id) != -1) ? 'linking-element active' : 'linking-element'} onClick={() => {
                                                                            let items = this.state.items;
                                                                            //items[this.state.linkingElement].conditionQuestion = item.id;

                                                                            if (!items[this.state.linkingElement].conditions) {
                                                                                items[this.state.linkingElement].conditions = [];
                                                                            }

                                                                            let check = -1;
                                                                            for (let i = 0; i < items[this.state.linkingElement].conditions.length; i++) {
                                                                                if (items[this.state.linkingElement].conditions[i].conditionQuestion == item.id) {
                                                                                    check = i;
                                                                                }
                                                                            }

                                                                            if (check != -1) {
                                                                                items[this.state.linkingElement].conditions.splice(check, 1);
                                                                            } else {
                                                                                items[this.state.linkingElement].conditions.push({
                                                                                    conditionQuestion: item.id
                                                                                })
                                                                            }


                                                                            this.setState({
                                                                                items,
                                                                                //linkingElement: null
                                                                            }, () => this.detectChange());

                                                                        }}>{item.name}</button>

                                                                        :
                                                                        <>
                                                                            <input style={cssStyle} type="text" value={item.name} onChange={(e) => {
                                                                                let items = this.state.items;
                                                                                items[idx].name = e.target.value;
                                                                                this.setState({
                                                                                    items
                                                                                }, () => this.detectChange())
                                                                            }} className="edit-field-name" />
                                                                            <div className="text-options">
                                                                                <button className={item.fontStyle == 'italic' ? 'active' : ''} onClick={() => { let items = this.state.items; items[idx].fontStyle = items[idx].fontStyle == 'italic' ? null : 'italic'; this.setState({ items }, () => this.detectChange()) }}><i className="fa fa-italic" /></button>
                                                                                <button className={item.fontWeight == 'bold' ? 'active' : ''} onClick={() => { let items = this.state.items; items[idx].fontWeight = items[idx].fontWeight == 'bold' ? null : 'bold'; this.setState({ items }, () => this.detectChange()) }}><i className="fa fa-bold" /></button>

                                                                                <UncontrolledDropdown size="sm">
                                                                                    <DropdownToggle caret>
                                                                                        <i className="fa fa-font" />
                                                                                    </DropdownToggle>
                                                                                    <DropdownMenu>
                                                                                        <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 10; this.setState({ items }, () => this.detectChange()) }}>10px</DropdownItem>
                                                                                        <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 12; this.setState({ items }, () => this.detectChange()) }}>12px</DropdownItem>
                                                                                        <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 14; this.setState({ items }, () => this.detectChange()) }}>14px</DropdownItem>
                                                                                        <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 16; this.setState({ items }, () => this.detectChange()) }}>16px</DropdownItem>
                                                                                        <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 18; this.setState({ items }, () => this.detectChange()) }}>18px</DropdownItem>
                                                                                        <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 20; this.setState({ items }, () => this.detectChange()) }}>20px</DropdownItem>
                                                                                    </DropdownMenu>
                                                                                </UncontrolledDropdown>
                                                                            </div>
                                                                            <div className="answer-score-wrap">
                                                                                <Button color="primary" onClick={() => {
                                                                                    let items = this.state.items;
                                                                                    items[idx].score = Number(items[idx].score) - 1 <= -100 ? -100 : Number(items[idx].score) - 1;
                                                                                    this.setState({
                                                                                        items
                                                                                    }, () => {
                                                                                        this.checkBlocksRange()
                                                                                        this.detectChange()
                                                                                    })
                                                                                }}>-</Button>
                                                                                <input type="text" value={item.score} onChange={(e) => {
                                                                                    let items = this.state.items;
                                                                                    let val = String(e.target.value).match(/[-]{0,1}[\d]*[.]{0,1}[\d]+/g);
                                                                                    val = val.length ? val[0] : 0;
                                                                                    val = Number(val);
                                                                                    items[idx].score = val < -100 ? -100 : val > 100 ? 100 : val;
                                                                                    this.setState({
                                                                                        items
                                                                                    }, () => {
                                                                                        this.checkBlocksRange()
                                                                                        this.detectChange()
                                                                                    })

                                                                                }} />
                                                                                <Button color="primary" onClick={() => {
                                                                                    let items = this.state.items;
                                                                                    items[idx].score = Number(items[idx].score) + 1 >= 100 ? 100 : Number(items[idx].score) + 1;
                                                                                    this.setState({
                                                                                        items
                                                                                    }, () => {
                                                                                        this.checkBlocksRange()
                                                                                        this.detectChange()
                                                                                    })
                                                                                }}>+</Button>
                                                                            </div>

                                                                        </>

                                                                    }


                                                                </Label>
                                                                <Row>
                                                                    <Col lg="2">
                                                                        <FormGroup>
                                                                            <Label>{'Weight:'.translate(this.props.lang)}</Label>
                                                                            <Input disabled size="sm" type="text"></Input>

                                                                        </FormGroup>
                                                                    </Col>
                                                                    <Col lg="2">
                                                                        <FormGroup>
                                                                            <Label>{'Height:'.translate(this.props.lang)}</Label>
                                                                            <Input disabled size="sm" type="text"></Input>

                                                                        </FormGroup>

                                                                    </Col>
                                                                </Row>

                                                            </FormGroup>

                                                            :
                                                            null
                                                    }

                                                    {
                                                        item.type == 12 ?
                                                            <FormGroup tag="fieldset">
                                                                <Label size="sm">


                                                                    {(this.state.linkingElement !== null && this.state.linkingElement !== idx && this.state.linkingElement > idx) || (this.state.showLink && this.conditionIndexOf(this.state.showLink, item.id, null) != -1) ?
                                                                        <button className={(this.state.showLink && this.conditionIndexOf(this.state.showLink, item.id, null) != -1) || (this.state.linkingElement !== null && this.state.items[this.state.linkingElement]?.conditions && this.state.items[this.state.linkingElement].conditions.length && this.conditionIndexOf(this.state.items[this.state.linkingElement].conditions, item.id) != -1) ? 'linking-element active' : 'linking-element'} onClick={() => {
                                                                            let items = this.state.items;
                                                                            //items[this.state.linkingElement].conditionQuestion = item.id;

                                                                            if (!items[this.state.linkingElement].conditions) {
                                                                                items[this.state.linkingElement].conditions = [];
                                                                            }

                                                                            let check = -1;
                                                                            for (let i = 0; i < items[this.state.linkingElement].conditions.length; i++) {
                                                                                if (items[this.state.linkingElement].conditions[i].conditionQuestion == item.id) {
                                                                                    check = i;
                                                                                }
                                                                            }

                                                                            if (check != -1) {
                                                                                items[this.state.linkingElement].conditions.splice(check, 1);
                                                                            } else {
                                                                                items[this.state.linkingElement].conditions.push({
                                                                                    conditionQuestion: item.id
                                                                                })
                                                                            }


                                                                            this.setState({
                                                                                items,
                                                                                //linkingElement: null
                                                                            }, () => this.detectChange());

                                                                        }}>{item.name}</button>

                                                                        :
                                                                        <>
                                                                            <input style={cssStyle} type="text" value={item.name} onChange={(e) => {
                                                                                let items = this.state.items;
                                                                                items[idx].name = e.target.value;
                                                                                this.setState({
                                                                                    items
                                                                                }, () => this.detectChange())
                                                                            }} className="edit-field-name" />
                                                                            <div className="text-options">
                                                                                <button className={item.fontStyle == 'italic' ? 'active' : ''} onClick={() => { let items = this.state.items; items[idx].fontStyle = items[idx].fontStyle == 'italic' ? null : 'italic'; this.setState({ items }, () => this.detectChange()) }}><i className="fa fa-italic" /></button>
                                                                                <button className={item.fontWeight == 'bold' ? 'active' : ''} onClick={() => { let items = this.state.items; items[idx].fontWeight = items[idx].fontWeight == 'bold' ? null : 'bold'; this.setState({ items }, () => this.detectChange()) }}><i className="fa fa-bold" /></button>

                                                                                <UncontrolledDropdown size="sm">
                                                                                    <DropdownToggle caret>
                                                                                        <i className="fa fa-font" />
                                                                                    </DropdownToggle>
                                                                                    <DropdownMenu>
                                                                                        <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 10; this.setState({ items }, () => this.detectChange()) }}>10px</DropdownItem>
                                                                                        <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 12; this.setState({ items }, () => this.detectChange()) }}>12px</DropdownItem>
                                                                                        <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 14; this.setState({ items }, () => this.detectChange()) }}>14px</DropdownItem>
                                                                                        <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 16; this.setState({ items }, () => this.detectChange()) }}>16px</DropdownItem>
                                                                                        <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 18; this.setState({ items }, () => this.detectChange()) }}>18px</DropdownItem>
                                                                                        <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 20; this.setState({ items }, () => this.detectChange()) }}>20px</DropdownItem>
                                                                                    </DropdownMenu>
                                                                                </UncontrolledDropdown>
                                                                            </div>
                                                                            <div className="answer-score-wrap">
                                                                                <Button color="primary" onClick={() => {
                                                                                    let items = this.state.items;
                                                                                    items[idx].score = Number(items[idx].score) - 1 <= -100 ? -100 : Number(items[idx].score) - 1;
                                                                                    this.setState({
                                                                                        items
                                                                                    }, () => {
                                                                                        this.checkBlocksRange()
                                                                                        this.detectChange()
                                                                                    })
                                                                                }}>-</Button>
                                                                                <input type="text" value={item.score} onChange={(e) => {
                                                                                    let items = this.state.items;
                                                                                    let val = String(e.target.value).match(/[-]{0,1}[\d]*[.]{0,1}[\d]+/g);
                                                                                    val = val.length ? val[0] : 0;
                                                                                    val = Number(val);
                                                                                    items[idx].score = val < -100 ? -100 : val > 100 ? 100 : val;
                                                                                    this.setState({
                                                                                        items
                                                                                    }, () => {
                                                                                        this.checkBlocksRange()
                                                                                        this.detectChange()
                                                                                    })

                                                                                }} />
                                                                                <Button color="primary" onClick={() => {
                                                                                    let items = this.state.items;
                                                                                    items[idx].score = Number(items[idx].score) + 1 >= 100 ? 100 : Number(items[idx].score) + 1;
                                                                                    this.setState({
                                                                                        items
                                                                                    }, () => {
                                                                                        this.checkBlocksRange()
                                                                                        this.detectChange()
                                                                                    })
                                                                                }}>+</Button>
                                                                            </div>

                                                                        </>

                                                                    }



                                                                </Label>
                                                                <Row>
                                                                    <Col lg="2">
                                                                        <FormGroup>
                                                                            <Label>{'Systolic:'.translate(this.props.lang)}</Label>
                                                                            <Input disabled size="sm" type="text"></Input>

                                                                        </FormGroup>
                                                                    </Col>
                                                                    <Col lg="2">
                                                                        <FormGroup>
                                                                            <Label>{'Diastolic:'.translate(this.props.lang)}</Label>
                                                                            <Input disabled size="sm" type="text"></Input>

                                                                        </FormGroup>

                                                                    </Col>
                                                                    <Col lg="2">
                                                                        <FormGroup>
                                                                            <Label>{'Pulse:'.translate(this.props.lang)}</Label>
                                                                            <Input disabled size="sm" type="text"></Input>

                                                                        </FormGroup>

                                                                    </Col>
                                                                </Row>

                                                            </FormGroup>

                                                            :
                                                            null
                                                    }
                                                    {
                                                        item.type == 14 ?
                                                            <FormGroup tag="fieldset">
                                                                <Label size="sm">
                                                                    {(this.state.linkingElement !== null && this.state.linkingElement !== idx && this.state.linkingElement > idx) || (this.state.showLink && this.conditionIndexOf(this.state.showLink, item.id, null) != -1) ?
                                                                        <button className={(this.state.showLink && this.conditionIndexOf(this.state.showLink, item.id, null) != -1) || (this.state.linkingElement !== null && this.state.items[this.state.linkingElement]?.conditions && this.state.items[this.state.linkingElement].conditions.length && this.conditionIndexOf(this.state.items[this.state.linkingElement].conditions, item.id) != -1) ? 'linking-element active' : 'linking-element'} onClick={() => {
                                                                            let items = this.state.items;
                                                                            //items[this.state.linkingElement].conditionQuestion = item.id;

                                                                            if (!items[this.state.linkingElement].conditions) {
                                                                                items[this.state.linkingElement].conditions = [];
                                                                            }

                                                                            let check = -1;
                                                                            for (let i = 0; i < items[this.state.linkingElement].conditions.length; i++) {
                                                                                if (items[this.state.linkingElement].conditions[i].conditionQuestion == item.id) {
                                                                                    check = i;
                                                                                }
                                                                            }

                                                                            if (check != -1) {
                                                                                items[this.state.linkingElement].conditions.splice(check, 1);
                                                                            } else {
                                                                                items[this.state.linkingElement].conditions.push({
                                                                                    conditionQuestion: item.id
                                                                                })
                                                                            }


                                                                            this.setState({
                                                                                items,
                                                                                //linkingElement: null
                                                                            }, () => this.detectChange());

                                                                        }}>{item.name}</button>

                                                                        :
                                                                        <>
                                                                            <input style={cssStyle} type="text" value={item.name} onChange={(e) => {
                                                                                let items = this.state.items;
                                                                                items[idx].name = e.target.value;
                                                                                this.setState({
                                                                                    items
                                                                                }, () => this.detectChange())
                                                                            }} className="edit-field-name" />
                                                                            <div className="text-options">
                                                                                <button className={item.fontStyle == 'italic' ? 'active' : ''} onClick={() => { let items = this.state.items; items[idx].fontStyle = items[idx].fontStyle == 'italic' ? null : 'italic'; this.setState({ items }, () => this.detectChange()) }}><i className="fa fa-italic" /></button>
                                                                                <button className={item.fontWeight == 'bold' ? 'active' : ''} onClick={() => { let items = this.state.items; items[idx].fontWeight = items[idx].fontWeight == 'bold' ? null : 'bold'; this.setState({ items }, () => this.detectChange()) }}><i className="fa fa-bold" /></button>

                                                                                <UncontrolledDropdown size="sm">
                                                                                    <DropdownToggle caret>
                                                                                        <i className="fa fa-font" />
                                                                                    </DropdownToggle>
                                                                                    <DropdownMenu>
                                                                                        <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 10; this.setState({ items }, () => this.detectChange()) }}>10px</DropdownItem>
                                                                                        <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 12; this.setState({ items }, () => this.detectChange()) }}>12px</DropdownItem>
                                                                                        <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 14; this.setState({ items }, () => this.detectChange()) }}>14px</DropdownItem>
                                                                                        <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 16; this.setState({ items }, () => this.detectChange()) }}>16px</DropdownItem>
                                                                                        <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 18; this.setState({ items }, () => this.detectChange()) }}>18px</DropdownItem>
                                                                                        <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 20; this.setState({ items }, () => this.detectChange()) }}>20px</DropdownItem>
                                                                                    </DropdownMenu>
                                                                                </UncontrolledDropdown>
                                                                            </div>
                                                                            <div className="answer-score-wrap">
                                                                                <Button color="primary" onClick={() => {
                                                                                    let items = this.state.items;
                                                                                    items[idx].score = Number(items[idx].score) - 1 <= -100 ? -100 : Number(items[idx].score) - 1;
                                                                                    this.setState({
                                                                                        items
                                                                                    }, () => {
                                                                                        this.checkBlocksRange()
                                                                                        this.detectChange()
                                                                                    })
                                                                                }}>-</Button>
                                                                                <input type="text" value={item.score} onChange={(e) => {
                                                                                    let items = this.state.items;
                                                                                    let val = String(e.target.value).match(/[-]{0,1}[\d]*[.]{0,1}[\d]+/g);
                                                                                    val = val.length ? val[0] : 0;
                                                                                    val = Number(val);
                                                                                    items[idx].score = val < -100 ? -100 : val > 100 ? 100 : val;
                                                                                    this.setState({
                                                                                        items
                                                                                    }, () => {
                                                                                        this.checkBlocksRange()
                                                                                        this.detectChange()
                                                                                    })

                                                                                }} />
                                                                                <Button color="primary" onClick={() => {
                                                                                    let items = this.state.items;
                                                                                    items[idx].score = Number(items[idx].score) + 1 >= 100 ? 100 : Number(items[idx].score) + 1;
                                                                                    this.setState({
                                                                                        items
                                                                                    }, () => {
                                                                                        this.checkBlocksRange()
                                                                                        this.detectChange()
                                                                                    })
                                                                                }}>+</Button>
                                                                            </div>

                                                                        </>

                                                                    }

                                                                </Label>

                                                                <Col>
                                                                    <Input type='select' disabled min={0} max={100} />
                                                                </Col>

                                                            </FormGroup>

                                                            :
                                                            null
                                                    }
                                                    {
                                                        item.type == 13 ?
                                                            <FormGroup tag="fieldset">
                                                                <Label size="sm">


                                                                    {(this.state.linkingElement !== null && this.state.linkingElement !== idx && this.state.linkingElement > idx) || (this.state.showLink && this.conditionIndexOf(this.state.showLink, item.id, null) != -1) ?
                                                                        <button className={(this.state.showLink && this.conditionIndexOf(this.state.showLink, item.id, null) != -1) || (this.state.linkingElement !== null && this.state.items[this.state.linkingElement]?.conditions && this.state.items[this.state.linkingElement].conditions.length && this.conditionIndexOf(this.state.items[this.state.linkingElement].conditions, item.id) != -1) ? 'linking-element active' : 'linking-element'} onClick={() => {
                                                                            let items = this.state.items;
                                                                            //items[this.state.linkingElement].conditionQuestion = item.id;

                                                                            if (!items[this.state.linkingElement].conditions) {
                                                                                items[this.state.linkingElement].conditions = [];
                                                                            }

                                                                            let check = -1;
                                                                            for (let i = 0; i < items[this.state.linkingElement].conditions.length; i++) {
                                                                                if (items[this.state.linkingElement].conditions[i].conditionQuestion == item.id) {
                                                                                    check = i;
                                                                                }
                                                                            }

                                                                            if (check != -1) {
                                                                                items[this.state.linkingElement].conditions.splice(check, 1);
                                                                            } else {
                                                                                items[this.state.linkingElement].conditions.push({
                                                                                    conditionQuestion: item.id
                                                                                })
                                                                            }


                                                                            this.setState({
                                                                                items,
                                                                                //linkingElement: null
                                                                            }, () => this.detectChange());

                                                                        }}>{item.name}</button>

                                                                        :
                                                                        <>
                                                                            <input style={cssStyle} type="text" value={item.name} onChange={(e) => {
                                                                                let items = this.state.items;
                                                                                items[idx].name = e.target.value;
                                                                                this.setState({
                                                                                    items
                                                                                }, () => this.detectChange())
                                                                            }} className="edit-field-name" />
                                                                            <div className="text-options">
                                                                                <button className={item.fontStyle == 'italic' ? 'active' : ''} onClick={() => { let items = this.state.items; items[idx].fontStyle = items[idx].fontStyle == 'italic' ? null : 'italic'; this.setState({ items }, () => this.detectChange()) }}><i className="fa fa-italic" /></button>
                                                                                <button className={item.fontWeight == 'bold' ? 'active' : ''} onClick={() => { let items = this.state.items; items[idx].fontWeight = items[idx].fontWeight == 'bold' ? null : 'bold'; this.setState({ items }, () => this.detectChange()) }}><i className="fa fa-bold" /></button>

                                                                                <UncontrolledDropdown size="sm">
                                                                                    <DropdownToggle caret>
                                                                                        <i className="fa fa-font" />
                                                                                    </DropdownToggle>
                                                                                    <DropdownMenu>
                                                                                        <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 10; this.setState({ items }, () => this.detectChange()) }}>10px</DropdownItem>
                                                                                        <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 12; this.setState({ items }, () => this.detectChange()) }}>12px</DropdownItem>
                                                                                        <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 14; this.setState({ items }, () => this.detectChange()) }}>14px</DropdownItem>
                                                                                        <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 16; this.setState({ items }, () => this.detectChange()) }}>16px</DropdownItem>
                                                                                        <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 18; this.setState({ items }, () => this.detectChange()) }}>18px</DropdownItem>
                                                                                        <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 20; this.setState({ items }, () => this.detectChange()) }}>20px</DropdownItem>
                                                                                    </DropdownMenu>
                                                                                </UncontrolledDropdown>
                                                                            </div>
                                                                            <div className="answer-score-wrap">
                                                                                <Button color="primary" onClick={() => {
                                                                                    let items = this.state.items;
                                                                                    items[idx].score = Number(items[idx].score) - 1 <= -100 ? -100 : Number(items[idx].score) - 1;
                                                                                    this.setState({
                                                                                        items
                                                                                    }, () => {
                                                                                        this.checkBlocksRange()
                                                                                        this.detectChange()
                                                                                    })
                                                                                }}>-</Button>
                                                                                <input type="text" value={item.score} onChange={(e) => {
                                                                                    let items = this.state.items;
                                                                                    let val = String(e.target.value).match(/[-]{0,1}[\d]*[.]{0,1}[\d]+/g);
                                                                                    val = val.length ? val[0] : 0;
                                                                                    val = Number(val);
                                                                                    items[idx].score = val < -100 ? -100 : val > 100 ? 100 : val;
                                                                                    this.setState({
                                                                                        items
                                                                                    }, () => {
                                                                                        this.checkBlocksRange()
                                                                                        this.detectChange()
                                                                                    })

                                                                                }} />
                                                                                <Button color="primary" onClick={() => {
                                                                                    let items = this.state.items;
                                                                                    items[idx].score = Number(items[idx].score) + 1 >= 100 ? 100 : Number(items[idx].score) + 1;
                                                                                    this.setState({
                                                                                        items
                                                                                    }, () => {
                                                                                        this.checkBlocksRange()
                                                                                        this.detectChange()
                                                                                    })
                                                                                }}>+</Button>
                                                                            </div>


                                                                        </>

                                                                    }



                                                                </Label>
                                                                <Row>
                                                                    <Col lg="2">
                                                                        <FormGroup>
                                                                            {/* <Label>{'Systolic:'.translate(this.props.lang)}</Label> */}
                                                                            <Input disabled size="sm" type="text"></Input>

                                                                        </FormGroup>
                                                                    </Col>

                                                                </Row>

                                                            </FormGroup>

                                                            :
                                                            null
                                                    }

                                                    {
                                                        item.type == 10 ?
                                                            <div className='new-paragraph-checkbox'>
                                                                <h4>
                                                                    <input style={cssStyle} type="text" value={item.name} className="new-line-field" disabled />
                                                                </h4>

                                                                <Checkbox onChange={() => {
                                                                    let items = this.state.items;
                                                                    items[idx].userInput = !items[idx].userInput;
                                                                    this.setState({ items }, () => this.detectChange())
                                                                }} checked={item.userInput ? true : false} label={"Add an empty line".translate(this.props.lang)} />
                                                            </div>
                                                            // <h4><input style={cssStyle} type="text" value={item.name} className="new-line-field m-l-45" disabled />


                                                            // </h4>
                                                            :
                                                            null
                                                    }


                                                    {
                                                        item.type == 4 ?

                                                            <FormGroup tag="fieldset">
                                                                <Label size="sm">

                                                                    {(this.state.linkingElement !== null && this.state.linkingElement !== idx && this.state.linkingElement > idx) || (this.state.showLink && this.conditionIndexOf(this.state.showLink, item.id, null) != -1) ?
                                                                        <button className={(this.state.showLink && this.conditionIndexOf(this.state.showLink, item.id, null) != -1) || (this.state.linkingElement !== null && this.state.items[this.state.linkingElement]?.conditions && this.state.items[this.state.linkingElement].conditions.length && this.conditionIndexOf(this.state.items[this.state.linkingElement].conditions, item.id) != -1) ? 'linking-element active' : 'linking-element'} onClick={() => {
                                                                            let items = this.state.items;
                                                                            //items[this.state.linkingElement].conditionQuestion = item.id;

                                                                            if (!items[this.state.linkingElement].conditions) {
                                                                                items[this.state.linkingElement].conditions = [];
                                                                            }

                                                                            let check = -1;
                                                                            for (let i = 0; i < items[this.state.linkingElement].conditions.length; i++) {
                                                                                if (items[this.state.linkingElement].conditions[i].conditionQuestion == item.id) {
                                                                                    check = i;
                                                                                }
                                                                            }

                                                                            if (check != -1) {
                                                                                items[this.state.linkingElement].conditions.splice(check, 1);
                                                                            } else {
                                                                                items[this.state.linkingElement].conditions.push({
                                                                                    conditionQuestion: item.id
                                                                                })
                                                                            }


                                                                            this.setState({
                                                                                items,
                                                                                //linkingElement: null
                                                                            }, () => this.detectChange());

                                                                        }}>{item.name}</button>

                                                                        :
                                                                        <>
                                                                            <input style={cssStyle} type="text" value={item.name} onChange={(e) => {
                                                                                let items = this.state.items;
                                                                                items[idx].name = e.target.value;
                                                                                this.setState({
                                                                                    items
                                                                                }, () => this.detectChange())
                                                                            }} className="edit-field-name" />
                                                                            <div className="text-options">
                                                                                <button className={item.fontStyle == 'italic' ? 'active' : ''} onClick={() => { let items = this.state.items; items[idx].fontStyle = items[idx].fontStyle == 'italic' ? null : 'italic'; this.setState({ items }, () => this.detectChange()) }}><i className="fa fa-italic" /></button>
                                                                                <button className={item.fontWeight == 'bold' ? 'active' : ''} onClick={() => { let items = this.state.items; items[idx].fontWeight = items[idx].fontWeight == 'bold' ? null : 'bold'; this.setState({ items }, () => this.detectChange()) }}><i className="fa fa-bold" /></button>

                                                                                <UncontrolledDropdown size="sm">
                                                                                    <DropdownToggle caret>
                                                                                        <i className="fa fa-font" />
                                                                                    </DropdownToggle>
                                                                                    <DropdownMenu>
                                                                                        <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 10; this.setState({ items }, () => this.detectChange()) }}>10px</DropdownItem>
                                                                                        <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 12; this.setState({ items }, () => this.detectChange()) }}>12px</DropdownItem>
                                                                                        <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 14; this.setState({ items }, () => this.detectChange()) }}>14px</DropdownItem>
                                                                                        <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 16; this.setState({ items }, () => this.detectChange()) }}>16px</DropdownItem>
                                                                                        <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 18; this.setState({ items }, () => this.detectChange()) }}>18px</DropdownItem>
                                                                                        <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 20; this.setState({ items }, () => this.detectChange()) }}>20px</DropdownItem>
                                                                                    </DropdownMenu>
                                                                                </UncontrolledDropdown>
                                                                            </div>

                                                                        </>

                                                                    }



                                                                </Label>


                                                                <HTML disableImage={true} height={350} value={item.text} onChange={(text) => {
                                                                    if (item.text !== text.replace(/ rel="noopener"/g, '')) {
                                                                        this.detectChange()
                                                                    }
                                                                    let items = this.state.items;
                                                                    items[idx].text = text;
                                                                    this.setState({
                                                                        items
                                                                    })
                                                                }} />

                                                                <Checkbox onChange={() => {
                                                                    let items = this.state.items;
                                                                    items[idx].userInput = !items[idx].userInput;
                                                                    this.setState({ items }, () => this.detectChange())
                                                                }} checked={item.userInput ? true : false} label={'Alert the patient'.translate(this.props.lang)} />


                                                            </FormGroup>
                                                            :
                                                            null
                                                    }
                                                    {
                                                        item.type == 15 ?

                                                            <FormGroup tag="fieldset">
                                                                <Label size="sm">{item.name}</Label>


                                                                <HTML disableImage={true} height={350} value={item.text} onChange={(text) => {
                                                                    if (item.text !== text.replace(/ rel="noopener"/g, '')) {
                                                                        this.detectChange()
                                                                    }
                                                                    let items = this.state.items;
                                                                    items[idx].text = text;
                                                                    this.setState({
                                                                        items
                                                                    })
                                                                }} />



                                                            </FormGroup>
                                                            :
                                                            null
                                                    }

                                                    {
                                                        item.type == 16 || item.type == 17 ?
                                                            <FormGroup tag="fieldset">
                                                                <Label size="sm"><input style={cssStyle} type="text" value={item.name} onChange={(e) => {
                                                                    let items = this.state.items;
                                                                    items[idx].name = e.target.value;
                                                                    this.setState({
                                                                        items
                                                                    }, () => this.detectChange())
                                                                }} className="edit-field-name" />
                                                                    {
                                                                        item.type == 2 && this.state.intelligentReferral ?
                                                                            <>

                                                                                /

                                                                                <input style={cssStyle} type="text" value={item.answerPrefix} placeholder={'Prefix for answers'.translate(this.props.lang)} onChange={(e) => {
                                                                                    let items = this.state.items;
                                                                                    items[idx].answerPrefix = e.target.value;
                                                                                    this.setState({
                                                                                        items
                                                                                    }, () => this.detectChange())
                                                                                }} className="edit-field-name" />

                                                                            </>
                                                                            :
                                                                            null
                                                                    }
                                                                    <div className="text-options">
                                                                        <button className={item.fontStyle == 'italic' ? 'active' : ''} onClick={() => { let items = this.state.items; items[idx].fontStyle = items[idx].fontStyle == 'italic' ? null : 'italic'; this.setState({ items }, () => this.detectChange()) }}><i className="fa fa-italic" /></button>
                                                                        <button className={item.fontWeight == 'bold' ? 'active' : ''} onClick={() => { let items = this.state.items; items[idx].fontWeight = items[idx].fontWeight == 'bold' ? null : 'bold'; this.setState({ items }, () => this.detectChange()) }}><i className="fa fa-bold" /></button>

                                                                        <UncontrolledDropdown className='font-size-dorpdown' size="sm">
                                                                            <DropdownToggle caret>
                                                                                <i className="fa fa-font" />
                                                                            </DropdownToggle>
                                                                            <DropdownMenu>
                                                                                <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 10; this.setState({ items }, () => this.detectChange()) }}>10px</DropdownItem>
                                                                                <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 12; this.setState({ items }, () => this.detectChange()) }}>12px</DropdownItem>
                                                                                <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 14; this.setState({ items }, () => this.detectChange()) }}>14px</DropdownItem>
                                                                                <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 16; this.setState({ items }, () => this.detectChange()) }}>16px</DropdownItem>
                                                                                <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 18; this.setState({ items }, () => this.detectChange()) }}>18px</DropdownItem>
                                                                                <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 20; this.setState({ items }, () => this.detectChange()) }}>20px</DropdownItem>
                                                                            </DropdownMenu>
                                                                        </UncontrolledDropdown>
                                                                    </div>


                                                                </Label>
                                                                {/* <SortableAnswers
                                                                    onSortEnd={({ oldIndex, newIndex }) => {
                                                                        let items = this.state.items;
                                                                        let blocks = JSON.parse(JSON.stringify(items[idx].blocks));
                                                                        let tmp = blocks[newIndex];
                                                                        blocks[newIndex] = blocks[oldIndex];
                                                                        blocks[oldIndex] = tmp;
                                                                        items[idx].blocks = blocks
                                                                        this.setState({
                                                                            items: items
                                                                        }, () => {
                                                                            this.detectChange();
                                                                            setTimeout(() => {
                                                                                this.forceUpdate()
                                                                            }, 50)
                                                                        })
                                                                    }}
                                                                    useDragHandle items={
                                                                        item.blocks && item.blocks.map((block, bidx) => {
                                                                            return {
                                                                                component: (
                                                                                    <>
                                                                                        {(this.state.linkingElement !== null && this.state.linkingElement !== idx && this.state.linkingElement > idx) ?
                                                                                            <>
                                                                                                <button className={(this.state.showLink && this.conditionIndexOf(this.state.showLink, item.id, block.id) != -1) || (this.state.linkingElement !== null && this.state.items[this.state.linkingElement]?.conditions && this.state.items[this.state.linkingElement].conditions.length && this.conditionIndexOf(this.state.items[this.state.linkingElement].conditions, item.id, block.id) != -1) ? 'linking-element active' : 'linking-element'} onClick={() => {
                                                                                                    let items = this.state.items;
                                                                                                    if (!items[this.state.linkingElement].conditions) {
                                                                                                        items[this.state.linkingElement].conditions = [];
                                                                                                    }

                                                                                                    let check = -1;
                                                                                                    for (let i = 0; i < items[this.state.linkingElement].conditions.length; i++) {
                                                                                                        if (items[this.state.linkingElement].conditions[i].conditionQuestion == item.id && items[this.state.linkingElement].conditions[i].conditionAnswer == block.id) {
                                                                                                            check = i;
                                                                                                        }
                                                                                                    }

                                                                                                    if (check != -1) {
                                                                                                        items[this.state.linkingElement].conditions.splice(check, 1);
                                                                                                    } else {
                                                                                                        items[this.state.linkingElement].conditions.push({
                                                                                                            conditionAnswer: block.id,
                                                                                                            conditionQuestion: item.id
                                                                                                        })
                                                                                                    }

                                                                                            
                                                                                                    this.setState({
                                                                                                        items,
                                                                                                    }, () => this.detectChange());

                                                                                                }}>{'Block'.translate(this.props.lang)} {(bidx + 1)}</button>
                                                                                                <FormGroup className="form-group-flex">
                                                                                                    {
                                                                                                        item.type == 16 ?
                                                                                                            <p>{'If subtotal is'.translate(this.props.lang)}</p>
                                                                                                            :
                                                                                                            <p>{'If total is'.translate(this.props.lang)}</p>
                                                                                                    }
                                                                                                    <Input type='select' disabled value={block.comparison}>
                                                                                                        <option value={'more'}>{'More >'.translate(this.props.lang)}</option>
                                                                                                        <option value={'less'}>{'Less'.translate(this.props.lang) + ' <'}</option>
                                                                                                        <option value={'equal'}>{'Equal ='.translate(this.props.lang)}</option>
                                                                                                    </Input>
                                                                                                    <p>{'then'.translate(this.props.lang)}</p>
                                                                                                    <Input disabled type='number' min='0' value={block.value} />
                                                                                                </FormGroup>

                                                                                                <FormGroup>
                                                                                                    <p>{'Response text'.translate(this.props.lang)}</p>
                                                                                                    <Input disabled size="sm" type="textarea"
                                                                                                        className="questionary-description"
                                                                                                        value={block.response}
                                                                                                    />
                                                                                                </FormGroup>
                                                                                            </>
                                                                                            :
                                                                                            <>
                                                                                                <div className={(this.state.showLink && this.conditionIndexOf(this.state.showLink, item.id, block.id) != -1) ? 'total-block-title total-block-title-show-link' : "total-block-title"}>
                                                                                                    <p>{'Block'.translate(this.props.lang)} {(bidx + 1)}:</p>
                                                                                                    <FormGroup>
                                                                                                        <Label>
                                                                                                            <button onClick={() => {
                                                                                                                this.setState({
                                                                                                                    deleteBlockModal: { idx, bidx },
                                                                                                                })
                                                                                                            }}><Isvg style={{ minWidth: 22 }} src={garabage} /></button>
                                                                                                            <DragHandle>
                                                                                                                <div><Isvg src={arrows} className="arrows" /></div> </DragHandle>
                                                                                                        </Label>
                                                                                                    </FormGroup>
                                                                                                </div>

                                                                                                <FormGroup className="form-group-flex">
                                                                                                    {
                                                                                                        item.type == 16 ?
                                                                                                            <p>{'If subtotal is'.translate(this.props.lang)}</p>
                                                                                                            :
                                                                                                            <p>{'If total is'.translate(this.props.lang)}</p>
                                                                                                    }
                                                                                                    <Input type='select' value={block.comparison} onChange={(e) => {
                                                                                                        let value = null;
                                                                                                        if (e.target.value != -1) {
                                                                                                            value = e.target.value;
                                                                                                        }
                                                                                                        let items = this.state.items;
                                                                                                        let blocks = items[idx].blocks;
                                                                                                        blocks[bidx].comparison = value;
                                                                                                        items[idx].blocks = blocks;
                                                                                                        this.setState({
                                                                                                            items
                                                                                                        }, () => {
                                                                                                            this.detectChange()
                                                                                                            this.checkDuplicateConditions(idx, bidx)
                                                                                                        })
                                                                                                    }}>
                                                                                                        <option value={'more'}>{'More >'.translate(this.props.lang)}</option>
                                                                                                        <option value={'less'}>{'Less'.translate(this.props.lang) + ' <'}</option>
                                                                                                        <option value={'equal'}>{'Equal ='.translate(this.props.lang)}</option>
                                                                                                    </Input>
                                                                                                    <p>{'then'.translate(this.props.lang)}</p>
                                                                                                    <Input type='number' min='0' value={block.value} onChange={(e) => {
                                                                                                        let items = this.state.items;
                                                                                                        let blocks = items[idx].blocks;
                                                                                                        blocks[bidx].value = e.target.value;
                                                                                                        items[idx].blocks = blocks;
                                                                                                        this.setState({
                                                                                                            items
                                                                                                        }, () => {
                                                                                                            this.detectChange()
                                                                                                            this.checkDuplicateConditions(idx, bidx)
                                                                                                        })
                                                                                                    }} />
                                                                                                </FormGroup>

                                                                                                <FormGroup>
                                                                                                    <p>{'Response text'.translate(this.props.lang)}</p>
                                                                                                    <Input size="sm" type="textarea"
                                                                                                        className="questionary-description"
                                                                                                        value={block.response}
                                                                                                        onChange={(e) => {
                                                                                                            let items = this.state.items;
                                                                                                            let blocks = items[idx].blocks;
                                                                                                            blocks[bidx].response = e.target.value;
                                                                                                            items[idx].blocks = blocks;
                                                                                                            this.setState({
                                                                                                                items
                                                                                                            }, () => this.detectChange())
                                                                                                        }} />
                                                                                                </FormGroup>
                                                                                            </>
                                                                                        }




                                                                                    </>
                                                                                )
                                                                            }

                                                                        })
                                                                    } />

                                                                <div className="add-new-option-wrap">
                                                                    <button className="add-new-option" onClick={() => {
                                                                        let items = this.state.items;
                                                                        items[idx].blocks.push({
                                                                            id: uuidv4(),
                                                                            comparison: 'more',
                                                                            value: 0,
                                                                            response: ''
                                                                        });
                                                                        this.setState({
                                                                            items
                                                                        }, () => this.detectChange())
                                                                    }}> {'Add new block'.translate(this.props.lang)} </button>

                                                                </div> */}

                                                                {(this.state.linkingElement !== null && this.state.linkingElement !== idx && this.state.linkingElement > idx) ?
                                                                    <div className="selectable-interval-component-wrap">
                                                                        <SelectableInterval
                                                                            min={this.state.blocksRange && this.state.blocksRange.filter(el => el.id == item.id).length && this.state.blocksRange.filter(el => el.id == item.id)[0] && this.state.blocksRange.filter(el => el.id == item.id)[0].min ? this.state.blocksRange.filter(el => el.id == item.id)[0].min : 0}
                                                                            max={this.state.blocksRange && this.state.blocksRange.filter(el => el.id == item.id).length && this.state.blocksRange.filter(el => el.id == item.id)[0] && this.state.blocksRange.filter(el => el.id == item.id)[0].max ? this.state.blocksRange.filter(el => el.id == item.id)[0].max : 0}
                                                                            blockHeight={30}
                                                                            blocks={item.blocks}
                                                                            onClick={(block, bidx) => {
                                                                                let items = this.state.items;
                                                                                if (!items[this.state.linkingElement].conditions) {
                                                                                    items[this.state.linkingElement].conditions = [];
                                                                                }

                                                                                let check = -1;
                                                                                for (let i = 0; i < items[this.state.linkingElement].conditions.length; i++) {
                                                                                    if (items[this.state.linkingElement].conditions[i].conditionQuestion == item.id && items[this.state.linkingElement].conditions[i].conditionAnswer == block.id) {
                                                                                        check = i;
                                                                                    }
                                                                                }

                                                                                if (check != -1) {
                                                                                    items[this.state.linkingElement].conditions.splice(check, 1);
                                                                                } else {
                                                                                    items[this.state.linkingElement].conditions.push({
                                                                                        conditionAnswer: block.id,
                                                                                        conditionQuestion: item.id
                                                                                    })
                                                                                }


                                                                                this.setState({
                                                                                    items,
                                                                                }, () => this.detectChange());
                                                                            }}
                                                                            tooltip={'response'}
                                                                            linkingElements={true}
                                                                            showLink={this.state.showLink}
                                                                            linkingElement={this.state.linkingElement}
                                                                            items={this.state.items}
                                                                            item={item}
                                                                        ></SelectableInterval>
                                                                    </div>
                                                                    :
                                                                    <div className="selectable-interval-component-wrap">
                                                                        <SelectableInterval
                                                                            min={this.state.blocksRange && this.state.blocksRange.filter(el => el.id == item.id).length && this.state.blocksRange.filter(el => el.id == item.id)[0] && this.state.blocksRange.filter(el => el.id == item.id)[0].min ? this.state.blocksRange.filter(el => el.id == item.id)[0].min : 0}
                                                                            max={this.state.blocksRange && this.state.blocksRange.filter(el => el.id == item.id).length && this.state.blocksRange.filter(el => el.id == item.id)[0] && this.state.blocksRange.filter(el => el.id == item.id)[0].max ? this.state.blocksRange.filter(el => el.id == item.id)[0].max : 0}
                                                                            blockHeight={30} blocks={item.blocks}
                                                                            onBlockSelection={(block) => {
                                                                                let obj = {
                                                                                    id: uuidv4(),
                                                                                    // comparison: 'more',
                                                                                    // value: block.start,
                                                                                    start: block.start,
                                                                                    end: block.end,
                                                                                    response: ''
                                                                                }

                                                                                let items = this.state.items;
                                                                                items[idx].blocks.push(obj);
                                                                                this.setState({
                                                                                    items
                                                                                }, () => this.detectChange())
                                                                            }}
                                                                            onClick={(block, bidx) => {
                                                                                let items = this.state.items;
                                                                                let blocks = [...items[idx].blocks];

                                                                                let min = this.state.blocksRange && this.state.blocksRange.filter(el => el.id == item.id).length && this.state.blocksRange.filter(el => el.id == item.id)[0] && this.state.blocksRange.filter(el => el.id == item.id)[0].min ? this.state.blocksRange.filter(el => el.id == item.id)[0].min : 0;
                                                                                let max = this.state.blocksRange && this.state.blocksRange.filter(el => el.id == item.id).length && this.state.blocksRange.filter(el => el.id == item.id)[0] && this.state.blocksRange.filter(el => el.id == item.id)[0].max ? this.state.blocksRange.filter(el => el.id == item.id)[0].max : 0;

                                                                                for (let i = 0; i < blocks.length; i++) {
                                                                                    if (i != bidx) {
                                                                                        if (blocks[i].end < block.start) {
                                                                                            if (min < blocks[i].end + 1) {
                                                                                                min = blocks[i].end + 1;
                                                                                            }
                                                                                        } else if (blocks[i].start > block.end) {
                                                                                            if (max > blocks[i].start - 1) {
                                                                                                max = blocks[i].start - 1;
                                                                                            }

                                                                                        }
                                                                                    }
                                                                                }
                                                                                let obj = {
                                                                                    idx: idx,
                                                                                    bidx: bidx,
                                                                                    item: { ...block },
                                                                                    start: block.start,
                                                                                    end: block.end,
                                                                                    min: min,
                                                                                    max: max
                                                                                }
                                                                                this.setState({ clickedBlockModal: obj })

                                                                            }}
                                                                            item={item}
                                                                            showLink={this.state.showLink}
                                                                            tooltip={'response'}
                                                                            deleteBlockHandler={(item, bidx) => {

                                                                                this.setState({
                                                                                    deleteBlockModal: { idx, bidx },
                                                                                })
                                                                            }}
                                                                        ></SelectableInterval>
                                                                    </div>

                                                                }



                                                            </FormGroup>
                                                            :
                                                            null
                                                    }


                                                    <div>

                                                        {
                                                            item.type != 10 ?
                                                                <div className="referal-checkbox-container">
                                                                    <Checkbox checked={this.state.intelligentReferral ? true : item.required} onChange={(e) => {
                                                                        let items = this.state.items;
                                                                        items[idx].required = e.target.checked;
                                                                        this.setState({
                                                                            items
                                                                        }, () => this.detectChange())
                                                                    }} label={'Required'.translate(this.props.lang)} />

                                                                </div>
                                                                :
                                                                null
                                                        }


                                                        <UncontrolledDropdown size="sm" direction="left">
                                                            <DropdownToggle caret>
                                                                {'Type'.translate(this.props.lang)}
                                                            </DropdownToggle>
                                                            <DropdownMenu>

                                                                {
                                                                    this.state.intelligentReferral && this.state.formType !== 'chat-forms' ?
                                                                        <DropdownItem
                                                                            onClick={() => {
                                                                                let items = this.state.items;
                                                                                items[idx].type = 0;
                                                                                items[idx].name = 'Title'.translate(this.props.lang);
                                                                                this.setState({
                                                                                    items
                                                                                }, () => {
                                                                                    this.detectChange()
                                                                                    this.checkBlocksRange()
                                                                                })
                                                                            }}>{'Title'.translate(this.props.lang)}</DropdownItem>

                                                                        :
                                                                        null
                                                                }
                                                                <DropdownItem onClick={() => {
                                                                    let items = this.state.items;
                                                                    if (item.type != 1 && item.type != 2) {
                                                                        items[idx].name = 'Single option answer name'.translate(this.props.lang);
                                                                        items[idx].answers = [
                                                                            {
                                                                                id: uuidv4(),
                                                                                name: '',
                                                                                score: 0
                                                                            },
                                                                            {
                                                                                id: uuidv4(),
                                                                                name: '',
                                                                                score: 0
                                                                            }
                                                                        ]
                                                                    }
                                                                    items[idx].type = 1;
                                                                    this.setState({
                                                                        items
                                                                    }, () => {
                                                                        this.detectChange()
                                                                        this.checkBlocksRange()
                                                                    })
                                                                }}>{'Single option answer'.translate(this.props.lang)}</DropdownItem>
                                                                <DropdownItem onClick={() => {
                                                                    let items = this.state.items;

                                                                    if (item.type != 1 && item.type != 2) {
                                                                        items[idx].name = 'Multiple options answer name'.translate(this.props.lang);
                                                                        items[idx].answers = [
                                                                            {
                                                                                id: uuidv4(),
                                                                                name: '',
                                                                                score: 0
                                                                            },
                                                                            {
                                                                                id: uuidv4(),
                                                                                name: '',
                                                                                score: 0
                                                                            }
                                                                        ]
                                                                    } else {
                                                                        if (items[idx].name == 'Single option answer name'.translate(this.props.lang)) {
                                                                            items[idx].name = 'Multiple options answer name'.translate(this.props.lang);
                                                                        }
                                                                    }

                                                                    items[idx].type = 2;

                                                                    this.setState({
                                                                        items
                                                                    }, () => {
                                                                        this.detectChange()
                                                                        this.checkBlocksRange()
                                                                    })
                                                                }}>{'Multiple options answer'.translate(this.props.lang)}</DropdownItem>
                                                                <DropdownItem onClick={() => {
                                                                    let items = this.state.items;
                                                                    items[idx].type = 3;
                                                                    items[idx].name = 'Text answer name'.translate(this.props.lang);
                                                                    this.setState({
                                                                        items
                                                                    }, () => {
                                                                        this.detectChange()
                                                                        this.checkBlocksRange()
                                                                    })
                                                                }}>{'Text answer'.translate(this.props.lang)}</DropdownItem>
                                                                {
                                                                    this.state.intelligentReferral ?
                                                                        <DropdownItem onClick={() => {
                                                                            let items = this.state.items;
                                                                            items[idx].type = 5;
                                                                            items[idx].name = 'Diagnos'.translate(this.props.lang);
                                                                            items[idx].score = 0;
                                                                            this.setState({
                                                                                items
                                                                            }, () => {
                                                                                this.detectChange()
                                                                                this.checkBlocksRange()
                                                                            })
                                                                        }}>{'Diagnos'.translate(this.props.lang)}</DropdownItem>

                                                                        :
                                                                        null
                                                                }

                                                                <DropdownItem onClick={() => {
                                                                    let items = this.state.items;
                                                                    items[idx].type = 6;
                                                                    items[idx].name = "Enter the patient's weight and height".translate(this.props.lang);
                                                                    items[idx].score = 0;
                                                                    this.setState({
                                                                        items
                                                                    }, () => {
                                                                        this.detectChange()
                                                                        this.checkBlocksRange()
                                                                    })
                                                                }}>{"Enter the patient's weight and height".translate(this.props.lang)}</DropdownItem>

                                                                <DropdownItem onClick={() => {
                                                                    let items = this.state.items;
                                                                    items[idx].type = 9;
                                                                    items[idx].name = 'Image or file'.translate(this.props.lang);
                                                                    this.setState({
                                                                        items
                                                                    }, () => {
                                                                        this.detectChange()
                                                                        this.checkBlocksRange()
                                                                    })
                                                                }}>{'Image or file'.translate(this.props.lang)}</DropdownItem>
                                                                <DropdownItem onClick={() => {
                                                                    let items = this.state.items;
                                                                    items[idx].type = 4;
                                                                    items[idx].name = 'Treatment instructions'.translate(this.props.lang);
                                                                    this.setState({
                                                                        items
                                                                    }, () => {
                                                                        this.detectChange()
                                                                        this.checkBlocksRange()
                                                                    })
                                                                }}>{'Treatment instructions'.translate(this.props.lang)}</DropdownItem>
                                                                {
                                                                    this.state.formType !== 'chat-forms' ?
                                                                        <DropdownItem
                                                                            onClick={() => {
                                                                                let items = this.state.items;
                                                                                items[idx].type = 10;
                                                                                items[idx].name = 'New empty row'.translate(this.props.lang);
                                                                                this.setState({
                                                                                    items
                                                                                }, () => {
                                                                                    this.detectChange()
                                                                                    this.checkBlocksRange()
                                                                                })
                                                                            }}>{'New empty row'.translate(this.props.lang)}</DropdownItem>
                                                                        :
                                                                        null
                                                                }
                                                                <DropdownItem
                                                                    onClick={() => {
                                                                        let items = this.state.items;
                                                                        items[idx].type = 15;
                                                                        items[idx].name = 'Info text'.translate(this.props.lang);
                                                                        this.setState({
                                                                            items
                                                                        }, () => {
                                                                            this.detectChange()
                                                                            this.checkBlocksRange()
                                                                        })
                                                                    }}>{'Info text'.translate(this.props.lang)}</DropdownItem>
                                                                <DropdownItem onClick={() => {
                                                                    let items = this.state.items;
                                                                    items[idx].type = 12;
                                                                    items[idx].name = 'Blood pressure measured to'.translate(this.props.lang);
                                                                    items[idx].score = 0;
                                                                    this.setState({
                                                                        items
                                                                    }, () => {
                                                                        this.detectChange()
                                                                        this.checkBlocksRange()
                                                                    })
                                                                }}>{'Blood pressure'.translate(this.props.lang)}</DropdownItem>

                                                                <DropdownItem onClick={() => {
                                                                    let items = this.state.items;
                                                                    items[idx].type = 13;
                                                                    items[idx].name = 'The patient states VAS:'.translate(this.props.lang);
                                                                    items[idx].score = 0;
                                                                    this.setState({
                                                                        items
                                                                    }, () => {
                                                                        this.detectChange()
                                                                        this.checkBlocksRange()
                                                                    })
                                                                }}>{'VAS-scale'.translate(this.props.lang)}</DropdownItem>

                                                                <DropdownItem onClick={() => {
                                                                    let items = this.state.items;
                                                                    items[idx].type = 14;
                                                                    items[idx].name = 'Current respiratory rate/minute:'.translate(this.props.lang);
                                                                    items[idx].score = 0;
                                                                    this.setState({
                                                                        items
                                                                    }, () => {
                                                                        this.detectChange()
                                                                        this.checkBlocksRange()
                                                                    })
                                                                }}>{'Respiratory rate'.translate(this.props.lang)}</DropdownItem>
                                                                {
                                                                    this.state.formType !== 'chat-forms' ?
                                                                        <>
                                                                            <DropdownItem onClick={() => {
                                                                                let items = this.state.items;
                                                                                items[idx].type = 16;
                                                                                items[idx].name = 'Subtotal:'.translate(this.props.lang);
                                                                                items[idx].blocks = [
                                                                                    // {
                                                                                    //     id: uuidv4(),
                                                                                    //     comparison: 'more',
                                                                                    //     value: 0,
                                                                                    //     response: ''
                                                                                    // }
                                                                                ]
                                                                                this.setState({
                                                                                    items
                                                                                }, () => {
                                                                                    this.detectChange()
                                                                                    this.checkBlocksRange()
                                                                                })
                                                                            }}>{'Subtotal'.translate(this.props.lang)}</DropdownItem>
                                                                            {
                                                                                this.state.items && this.state.items.length == (idx + 1) ?
                                                                                    <DropdownItem onClick={() => {
                                                                                        let items = this.state.items;
                                                                                        items[idx].type = 17;
                                                                                        items[idx].name = 'Total:'.translate(this.props.lang);
                                                                                        items[idx].blocks = [
                                                                                            // {
                                                                                            //     id: uuidv4(),
                                                                                            //     comparison: 'more',
                                                                                            //     value: 0,
                                                                                            //     response: ''
                                                                                            // }
                                                                                        ]
                                                                                        this.setState({
                                                                                            items
                                                                                        }, () => {
                                                                                            this.detectChange()
                                                                                            this.checkBlocksRange()
                                                                                        })
                                                                                    }}>{'Total'.translate(this.props.lang)}</DropdownItem>
                                                                                    :
                                                                                    null
                                                                            }
                                                                        </>
                                                                        :
                                                                        null
                                                                }

                                                            </DropdownMenu>

                                                        </UncontrolledDropdown>


                                                        {
                                                            this.state.intelligentReferral ?

                                                                <>
                                                                    {item.type == 0 ?

                                                                        <button className={item.displayOnReferral ? /*'print-enabled'*/ 'referral-builder-tooltip-wrap' : 'referral-builder-tooltip-wrap'} onClick={() => {
                                                                            let items = this.state.items;
                                                                            items[idx].displayOnReferral = !items[idx].displayOnReferral;

                                                                            this.setState({
                                                                                items
                                                                            }, () => this.detectChange())
                                                                        }}>
                                                                            {
                                                                                item.displayOnReferral ?
                                                                                    <div className='print-show-hide-icon-wrap'>
                                                                                        <Isvg src={viewIcon} />
                                                                                    </div>
                                                                                    :
                                                                                    <div className='print-show-hide-icon-wrap hide-icon-wrap'>
                                                                                        <Isvg src={hideIcon} />
                                                                                    </div>
                                                                            }
                                                                            <div className='referral-builder-tooltip2'>{'Display title in preview'.translate(this.props.lang)}</div>

                                                                        </button>


                                                                        :
                                                                        null
                                                                    }

                                                                    {/* {idx != 0 && this.state.items && this.state.items[idx] && this.state.items[idx].type != 17 ?
                                                                        <button className={this.state.linkingElement == idx ? 'linking-enabled' : (item.conditions && item.conditions.length) ? 'item-linked' : ''} onMouseEnter={() => {
                                                                            if (item.conditions && item.conditions.length) {
                                                                                this.setState({
                                                                                    showLink: item.conditions
                                                                                })
                                                                            }
                                                                        }} onMouseLeave={() => {
                                                                            if (item.conditions && item.conditions.length) {
                                                                                this.setState({
                                                                                    showLink: null
                                                                                })
                                                                            }
                                                                        }} onClick={() => {
                                                                            this.setState({
                                                                                linkingElement: this.state.linkingElement !== idx ? idx : null,
                                                                                //showLink: this.state.linkingElement !== idx ? item.conditions  : null
                                                                            })
                                                                        }}><Isvg src={Link} /></button>
                                                                        :
                                                                        null
                                                                    } */}
                                                                    {idx != 0 && this.state.items && this.state.items[idx] && this.state.items[idx].type != 17 ?
                                                                        <button className={this.state.linkingElement == idx ? 'linking-enabled referral-builder-tooltip-wrap' : (item.conditions && item.conditions.length) ? 'item-linked referral-builder-tooltip-wrap' : 'referral-builder-tooltip-wrap'} onMouseEnter={() => {
                                                                            if (item.conditions && item.conditions.length) {
                                                                                this.setState({
                                                                                    showLink: item.conditions
                                                                                })
                                                                            }
                                                                        }} onMouseLeave={() => {
                                                                            if (item.conditions && item.conditions.length) {
                                                                                this.setState({
                                                                                    showLink: null
                                                                                })
                                                                            }
                                                                        }} onClick={() => {
                                                                            this.setState({
                                                                                linkingElement: this.state.linkingElement !== idx ? idx : null,
                                                                                //showLink: this.state.linkingElement !== idx ? item.conditions  : null
                                                                            })
                                                                        }}><Isvg src={Link} />
                                                                            <div className='referral-builder-tooltip'>{'Link question'.translate(this.props.lang)}</div>

                                                                        </button>
                                                                        :
                                                                        null
                                                                    }
                                                                </>
                                                                :
                                                                null
                                                        }

                                                        <button className='referral-builder-tooltip-wrap' onClick={() => {
                                                            this.setState({
                                                                deleteElementModal: idx,
                                                            })
                                                        }}><Isvg src={garabage} />
                                                            <div className='referral-builder-tooltip'>{'Delete'.translate(this.props.lang)}</div>

                                                        </button>

                                                        <UncontrolledDropdown size="sm" className="insert-above-below">
                                                            <DropdownToggle>
                                                                <Isvg src={moreIcon} />
                                                            </DropdownToggle>
                                                            <DropdownMenu>
                                                                {
                                                                    idx > 0 ?
                                                                        <DropdownItem
                                                                            onClick={() => {
                                                                                let items = this.state.items;
                                                                                items.splice(idx, 0, {
                                                                                    name: 'Single option answer name'.translate(this.props.lang),
                                                                                    answers: [
                                                                                        {
                                                                                            id: uuidv4(),
                                                                                            name: '',
                                                                                            score: 0
                                                                                        },
                                                                                        {
                                                                                            id: uuidv4(),
                                                                                            name: '',
                                                                                            score: 0
                                                                                        }
                                                                                    ],
                                                                                    id: uuidv4(),
                                                                                    type: 1,
                                                                                });

                                                                                this.setState({
                                                                                    items
                                                                                }, () => this.detectChange())
                                                                            }}>{"Insert above".translate(this.props.lang)}</DropdownItem>
                                                                        :
                                                                        null
                                                                }
                                                                {
                                                                    this.state.items && this.state.items[idx] && this.state.items[idx].type != 17 ?
                                                                        <DropdownItem
                                                                            onClick={() => {
                                                                                let items = this.state.items;
                                                                                items.splice(idx + 1, 0, {
                                                                                    name: 'Single option answer name'.translate(this.props.lang),
                                                                                    answers: [
                                                                                        {
                                                                                            id: uuidv4(),
                                                                                            name: '',
                                                                                            score: 0
                                                                                        },
                                                                                        {
                                                                                            id: uuidv4(),
                                                                                            name: '',
                                                                                            score: 0
                                                                                        }
                                                                                    ],
                                                                                    id: uuidv4(),
                                                                                    type: 1,
                                                                                });

                                                                                this.setState({
                                                                                    items
                                                                                }, () => this.detectChange())
                                                                            }}
                                                                        >{"Insert below".translate(this.props.lang)}</DropdownItem>
                                                                        :
                                                                        null
                                                                }

                                                            </DropdownMenu>
                                                        </UncontrolledDropdown>



                                                    </div>


                                                </div>
                                            )
                                        }
                                    })}></SortableList>
                            </div>



                            <div className="panel">
                                <Button color="primary" size="sm" onClick={() => {
                                    let items = this.state.items;
                                    let obj = {
                                        name: 'Single option answer name'.translate(this.props.lang),
                                        answers: [
                                            {
                                                id: uuidv4(),
                                                name: '',
                                                score: 0
                                            },
                                            {
                                                id: uuidv4(),
                                                name: '',
                                                score: 0
                                            }
                                        ],
                                        id: uuidv4(),
                                        type: 1,
                                    };
                                    if (items && items.length && items[items.length - 1].type != 17) {
                                        items.push(obj);
                                        this.setState({
                                            items
                                        }, () => this.detectChange())
                                    } else {
                                        items.splice(items.length - 1, 0, obj);
                                        this.setState({
                                            items
                                        }, () => this.detectChange())
                                    }



                                }} style={{ padding: '10px 22px' }}>{'Add new element'.translate(this.props.lang)}</Button>
                                <Button size="sm" onClick={() => this.setState({ openPageWarningModal: 1 })} disabled={this.state.checkActiveSave ? false : true} style={{ marginLeft: 20, padding: '10px 22px' }} color="primary">{'Save'.translate(this.props.lang)}</Button>

                            </div>

                        </Col>


                    </Row>
                    {
                        this.state.clickedBlockModal ?
                            <Modal isOpen={this.state.clickedBlockModal} centered>
                                <ModalHeader style={{ margin: 'auto' }} >
                                    {'Block'.translate(this.props.lang)}  {this.state.clickedBlockModal ? this.state.clickedBlockModal.start == this.state.clickedBlockModal.end ? this.state.clickedBlockModal.start : `${this.state.clickedBlockModal.start}-${this.state.clickedBlockModal.end}` : ''}
                                </ModalHeader>

                                <ModalBody className='interval-modal-body-wrap'>
                                    <div className="block-start-end-wrap">
                                        <div className='block-start-end'>
                                            <Label>{'Start'.translate(this.props.lang)}</Label>
                                            <div className="answer-score-wrap">
                                                <Button color="primary" disabled={this.state.clickedBlockModal.item.start > this.state.clickedBlockModal.min ? false : true} onClick={() => {
                                                    let clickedBlockModal = this.state.clickedBlockModal;
                                                    let val = clickedBlockModal.item.start - 1;
                                                    clickedBlockModal.item.start = Number(val)
                                                    this.setState({
                                                        clickedBlockModal
                                                    })
                                                }}>-</Button>
                                                <input type="text" value={this.state.clickedBlockModal.item.start} disabled
                                                // onChange={(e) => {
                                                //     let clickedBlockModal = this.state.clickedBlockModal;
                                                //     let val = String(e.target.value).match(/[-]{0,1}[\d]*[.]{0,1}[\d]+/g);
                                                //     clickedBlockModal.item.start = Number(val)
                                                //     this.setState({
                                                //         clickedBlockModal
                                                //     })

                                                // }} 
                                                />
                                                <Button color="primary" disabled={this.state.clickedBlockModal.item.start < this.state.clickedBlockModal.max && this.state.clickedBlockModal.item.start < this.state.clickedBlockModal.item.end ? false : true} onClick={() => {
                                                    let clickedBlockModal = this.state.clickedBlockModal;
                                                    let val = clickedBlockModal.item.start + 1;
                                                    clickedBlockModal.item.start = Number(val)
                                                    this.setState({
                                                        clickedBlockModal
                                                    })
                                                }}>+</Button>
                                            </div>
                                        </div>
                                        <div className='block-start-end'>
                                            <Label>{'End'.translate(this.props.lang)}</Label>
                                            <div className="answer-score-wrap">
                                                <Button color="primary" disabled={this.state.clickedBlockModal.item.end > this.state.clickedBlockModal.min && this.state.clickedBlockModal.item.start < this.state.clickedBlockModal.item.end ? false : true} onClick={() => {
                                                    let clickedBlockModal = this.state.clickedBlockModal;
                                                    let val = clickedBlockModal.item.end - 1;
                                                    clickedBlockModal.item.end = Number(val)
                                                    this.setState({
                                                        clickedBlockModal
                                                    })
                                                }}>-</Button>
                                                <input type="text" disabled value={this.state.clickedBlockModal.item.end}
                                                //  onChange={(e) => {
                                                //     let clickedBlockModal = this.state.clickedBlockModal;
                                                //     let val = String(e.target.value).match(/[-]{0,1}[\d]*[.]{0,1}[\d]+/g);
                                                //     clickedBlockModal.item.end = Number(val)
                                                //     this.setState({
                                                //         clickedBlockModal
                                                //     })

                                                // }} 
                                                />
                                                <Button color="primary" disabled={this.state.clickedBlockModal.item.end < this.state.clickedBlockModal.max ? false : true} onClick={() => {
                                                    let clickedBlockModal = this.state.clickedBlockModal;
                                                    let val = clickedBlockModal.item.end + 1;
                                                    clickedBlockModal.item.end = Number(val)
                                                    this.setState({
                                                        clickedBlockModal
                                                    })
                                                }}>+</Button>
                                            </div>
                                        </div>
                                    </div>
                                    <FormGroup>
                                        <Label>{'Response'.translate(this.props.lang)}</Label>
                                        <Input style={{ height: 100 }} type='textarea' value={this.state.clickedBlockModal.item.response} onChange={(e) => {
                                            let clickedBlockModal = this.state.clickedBlockModal;
                                            clickedBlockModal.item.response = e.target.value;
                                            this.setState({ clickedBlockModal })
                                        }} />
                                    </FormGroup>

                                </ModalBody>
                                <ModalFooter className='buttons-right-blue'>
                                    <Button color='primary' onClick={() => {
                                        if (this.state.clickedBlockModal) {
                                            let clickedBlockModal = this.state.clickedBlockModal;
                                            if (clickedBlockModal && clickedBlockModal.item) {
                                                let items = this.state.items;
                                                let blocks = [...items[clickedBlockModal.idx].blocks];

                                                // let min = clickedBlockModal.min;
                                                // let max = clickedBlockModal.max;

                                                // for (let i = 0; i < blocks.length; i++) {
                                                //     if (i != clickedBlockModal.bidx) {
                                                //         if (blocks[i].end < clickedBlockModal.start) {
                                                //             if (min < blocks[i].end + 1) {
                                                //                 min = blocks[i].end + 1;
                                                //             }
                                                //         } else if (blocks[i].start > clickedBlockModal.end) {
                                                //             if (max > blocks[i].start - 1) {
                                                //                 max = blocks[i].start - 1;
                                                //             }

                                                //         }
                                                //     }
                                                // }

                                                let start = clickedBlockModal.item.start;
                                                let end = clickedBlockModal.item.end;
                                                // if (min > start) {
                                                //     start = min;
                                                // }
                                                // if (max < end) {
                                                //     end = max;
                                                // }
                                                // if (end < start) {
                                                //     blocks.splice(clickedBlockModal.bidx, 1)
                                                // } else {
                                                //     blocks[clickedBlockModal.bidx].response = clickedBlockModal.item.response;
                                                //     blocks[clickedBlockModal.bidx].start = start;
                                                //     blocks[clickedBlockModal.bidx].end = end;
                                                // }

                                                blocks[clickedBlockModal.bidx].response = clickedBlockModal.item.response;
                                                blocks[clickedBlockModal.bidx].start = start;
                                                blocks[clickedBlockModal.bidx].end = end;
                                                items[clickedBlockModal.idx].blocks = blocks
                                                this.setState({ items, clickedBlockModal: null }, () => this.detectChange())
                                            }

                                        }
                                    }}>{'Save'.translate(this.props.lang)}</Button>
                                    <Button color='primary' onClick={() => {
                                        this.setState({ clickedBlockModal: null })
                                    }}>{'Cancel'.translate(this.props.lang)}</Button>
                                </ModalFooter>

                            </Modal>
                            :
                            null
                    }

                    {
                        this.state.messageModalError ?
                            <Modal isOpen={this.state.messageModalError} centered toggle={() => this.setState({ messageModalError: !this.state.runModal })}>
                                {/* <ModalHeader style={{ margin: 'auto' }} >
                                    {'Error message'.translate(this.props.lang)}
                                    {'Warning'.translate(this.props.lang)}
                                </ModalHeader> */}

                                <ModalBody className='delete-modal'>
                                    <p>{'You cannot drop here this element!'.translate(this.props.lang)}</p>

                                </ModalBody>
                                <ModalFooter className='buttons-right-blue'>
                                    <Button color='primary' onClick={() => this.setState({ messageModalError: !this.state.messageModalError })}>{'Ok'.translate(this.props.lang)}</Button>

                                </ModalFooter>

                            </Modal>
                            :
                            null
                    }

                </Container>
                <DeleteModal
                    lang={this.props.lang}
                    isOpen={this.state.deleteBlockModal !== null}
                    toggle={() => this.setState({ deleteBlockModal: null })}
                    handler={() => {
                        let items = this.state.items;

                        let relations = this.checkAnswerRelations(this.state.items[this.state.deleteBlockModal.idx].id, this.state.items[this.state.deleteBlockModal.idx].blocks[this.state.deleteBlockModal.bidx].id);
                        if (relations && relations.length && (relations[0].length || relations[1].length)) {
                            this.setState({
                                answerRelationError: relations
                            })

                            return;
                        }


                        items[this.state.deleteBlockModal.idx].blocks.splice(this.state.deleteBlockModal.bidx, 1);
                        this.setState({
                            items,
                            deleteBlockModal: null,
                        }, () => {
                            this.checkBlocksRange()
                            this.detectChange()
                        })
                    }}
                >
                    {'Delete block'.translate(this.props.lang)}?
                </DeleteModal>

                <DeleteModal
                    lang={this.props.lang}
                    isOpen={this.state.deleteElementModal !== null}
                    toggle={() => this.setState({ deleteElementModal: null })}
                    handler={() => {
                        let items = this.state.items;
                        let relations = this.checkRelations(this.state.items[this.state.deleteElementModal].id);
                        // console.log(relations)
                        if (relations && relations.length && (relations[0].length || relations[1].length)) {
                            this.setState({
                                relationError: relations
                            })

                            return;
                        }


                        items.splice(this.state.deleteElementModal, 1);
                        this.setState({
                            items,
                            deleteElementModal: null
                        }, () => {
                            this.checkBlocksRange()
                            this.detectChange()
                        })
                    }}
                >
                    {'Delete element'.translate(this.props.lang)} <strong>{this.state.items[this.state.deleteElementModal] && this.state.items[this.state.deleteElementModal].name}</strong>?
                </DeleteModal>
                <DeleteModal
                    lang={this.props.lang}
                    isOpen={this.state.deleteOptionModal !== null}
                    toggle={() => this.setState({ deleteOptionModal: null })}
                    handler={() => {
                        let items = this.state.items;

                        let relations = this.checkAnswerRelations(this.state.items[this.state.deleteOptionModal.idx].id, this.state.items[this.state.deleteOptionModal.idx].answers[this.state.deleteOptionModal.aidx].id);
                        if (relations && relations.length && (relations[0].length || relations[1].length)) {
                            this.setState({
                                answerRelationError: relations
                            })

                            return;
                        }


                        items[this.state.deleteOptionModal.idx].answers.splice(this.state.deleteOptionModal.aidx, 1);
                        this.setState({
                            items,
                            deleteOptionModal: null,
                        }, () => {
                            this.checkBlocksRange()
                            this.detectChange()
                        })
                    }}
                >
                    {'Delete option'.translate(this.props.lang)}?
                </DeleteModal>


                {
                    this.state.error ?

                        <ErrorModal lang={this.props.lang}
                            isOpen={this.state.error}
                            toggle={() => this.setState({ error: null })}

                        >
                            {this.state.error.translate(this.props.lang)}
                        </ErrorModal>

                        :
                        null
                }

                {
                    this.state.relationError ?

                        <ErrorModal lang={this.props.lang}
                            isOpen={this.state.relationError}
                            toggle={() => this.setState({ relationError: null })}
                            size="xl"
                            zIndex={99999999}
                        >
                            <h4>{'You need to unlink current relationships to continue'.translate(this.props.lang)}</h4>
                            <div className="relations-content">

                                <h6>{'Upper conditions'.translate(this.props.lang)}</h6>
                                <Table striped className="relations-table">
                                    <tr>
                                        <th>{'Condition element'.translate(this.props.lang)}</th>
                                        <th>{'Answer'.translate(this.props.lang)}</th>
                                        <th>{'Element'.translate(this.props.lang)}</th>
                                        <th>{'Action'.translate(this.props.lang)}</th>
                                    </tr>

                                    {
                                        this.state.relationError[0].map((item) => {
                                            return (
                                                <tr>
                                                    <td>{item.conditionElement.itemIndex}. {item.conditionElement.name}</td>
                                                    <td>{item.conditionElement.answer}</td>
                                                    <td>{item.element.itemIndex}. {item.element.name}</td>
                                                    <td><button onClick={() => {
                                                        let items = this.state.items;
                                                        items[item.element.itemIndex].conditions.splice(item.element.conditionIndex, 1);
                                                        this.setState({
                                                            items
                                                        }, () => {
                                                            let relations = this.checkRelations(this.state.items[this.state.deleteElementModal].id);
                                                            if (relations && relations.length && (relations[0].length || relations[1].length)) {
                                                                this.setState({
                                                                    relationError: relations
                                                                })

                                                            } else {
                                                                this.setState({
                                                                    relationError: null
                                                                })
                                                            }

                                                        })
                                                    }}><i className="fa fa-unlink danger-color" /></button></td>
                                                </tr>
                                            )
                                        })
                                    }


                                </Table>

                                <h6>{'Down conditions'.translate(this.props.lang)}</h6>
                                <Table striped className="relations-table">
                                    <tr>
                                        <th>{'Condition element'.translate(this.props.lang)}</th>
                                        <th>{'Answer'.translate(this.props.lang)}</th>
                                        <th>{'Element'.translate(this.props.lang)}</th>
                                        <th>{'Action'.translate(this.props.lang)}</th>
                                    </tr>
                                    {
                                        this.state.relationError[1].map((item) => {
                                            return (
                                                <tr>
                                                    <td>{item.conditionElement.itemIndex}. {item.conditionElement.name}</td>
                                                    <td>{item.conditionElement.answer}</td>
                                                    <td>{item.element.itemIndex}. {item.element.name}</td>

                                                    <td><button onClick={() => {
                                                        let items = this.state.items;
                                                        items[item.element.itemIndex].conditions.splice(item.element.conditionIndex, 1);
                                                        this.setState({
                                                            items
                                                        }, () => {
                                                            let relations = this.checkRelations(this.state.items[this.state.deleteElementModal].id);
                                                            if (relations && relations.length && (relations[0].length || relations[1].length)) {
                                                                this.setState({
                                                                    relationError: relations
                                                                })

                                                            } else {
                                                                this.setState({
                                                                    relationError: null
                                                                })
                                                            }

                                                        })
                                                    }}><i className="fa fa-unlink danger-color" /></button></td>
                                                </tr>
                                            )
                                        })
                                    }
                                </Table>

                            </div>
                        </ErrorModal>

                        :
                        null
                }

                {
                    this.state.answerRelationError ?

                        <ErrorModal lang={this.props.lang}
                            isOpen={this.state.answerRelationError}
                            toggle={() => this.setState({ answerRelationError: null })}
                            size="xl"
                            zIndex={999999999}
                        >
                            <h4>{'Relations'.translate(this.props.lang)}</h4>
                            <Table striped className="relations-table">
                                <tr>
                                    <th>{'Condition element'.translate(this.props.lang)}</th>
                                    <th>{'Answer'.translate(this.props.lang)}</th>
                                    <th>{'Element'.translate(this.props.lang)}</th>
                                    <th>{'Action'.translate(this.props.lang)}</th>
                                </tr>
                                {
                                    this.state.answerRelationError[1].map((item) => {
                                        return (
                                            <tr>
                                                <td>{item.conditionElement.name}</td>
                                                <td>{item.conditionElement.answer}</td>
                                                <td>{item.element.name}</td>

                                                <td><button onClick={() => {
                                                    let items = this.state.items;
                                                    items[item.element.itemIndex].conditions.splice(item.element.conditionIndex, 1);
                                                    this.setState({
                                                        items
                                                    }, () => {
                                                        let relations;
                                                        if (this.state.deleteOptionModal) {
                                                            this.checkAnswerRelations(this.state.items[this.state.deleteOptionModal.idx].id, this.state.items[this.state.deleteOptionModal.idx].answers[this.state.deleteOptionModal.aidx].id);
                                                        } else if (this.state.deleteBlockModal) {
                                                            this.checkAnswerRelations(this.state.items[this.state.deleteBlockModal.idx].id, this.state.items[this.state.deleteBlockModal.idx].blocks[this.state.deleteBlockModal.bidx].id);
                                                        }
                                                        if (relations && relations.length && (relations[0].length || relations[1].length)) {
                                                            this.setState({
                                                                answerRelationError: relations
                                                            })

                                                        } else {
                                                            this.setState({
                                                                answerRelationError: null
                                                            })
                                                        }

                                                    })
                                                }}><i className="fa fa-unlink danger-color" /></button></td>
                                            </tr>
                                        )
                                    })
                                }
                                {
                                    this.state.answerRelationError[0].map((item) => {
                                        return (
                                            <tr>
                                                <td>{item.conditionElement.name}</td>
                                                <td>{item.conditionElement.answer}</td>
                                                <td>{item.element.name}</td>
                                                <td><button onClick={() => {
                                                    let items = this.state.items;
                                                    items[item.element.itemIndex].conditions.splice(item.element.conditionIndex, 1);
                                                    this.setState({
                                                        items
                                                    }, () => {
                                                        let relations = this.checkAnswerRelations(this.state.items[this.state.deleteOptionModal.idx].id, this.state.items[this.state.deleteOptionModal.idx].answers[this.state.deleteOptionModal.aidx].id);
                                                        if (relations && relations.length && (relations[0].length || relations[1].length)) {
                                                            this.setState({
                                                                answerRelationError: relations
                                                            })

                                                        } else {
                                                            this.setState({
                                                                answerRelationError: null
                                                            })
                                                        }

                                                    })
                                                }}><i className="fa fa-unlink danger-color" /></button></td>
                                            </tr>
                                        )
                                    })
                                }


                            </Table>
                        </ErrorModal>

                        :
                        null
                }



                {
                    this.state.runModal ?
                        <Modal isOpen={this.state.runModal} centered toggle={() => this.setState({ runModal: !this.state.runModal })} size={'xl'}>
                            <ModalHeader toggle={() => this.setState({ runModal: !this.state.runModal })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ runModal: !this.state.runModal })}><Isvg src={xIcon} /></button>}>{'Referral preview'.translate(this.props.lang)}</ModalHeader>

                            <ModalBody className="run-modal">
                                <FormPreview lang={this.props.lang} sections={this.generateSections()}></FormPreview>
                            </ModalBody>
                        </Modal>
                        :
                        null
                }
                <Modal isOpen={this.state.selectReferralModal} centered size="xl">
                    <ModalHeader toggle={() => this.setState({ selectReferralModal: !this.state.selectReferralModal })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ selectReferralModal: !this.state.selectReferralModal })}><Isvg src={xIcon} /></button>}>{'Import referral'.translate(this.props.lang)}</ModalHeader>

                    <ModalBody>
                        <ImportReferral import={(data) => {
                            this.detectChange()
                            this.setState({
                                ...data,
                                selectReferralModal: null
                            })
                        }} {...this.props} groupValue={true}></ImportReferral>
                    </ModalBody>

                </Modal>

                {this.state.previewModal ?
                    <Modal isOpen={this.state.previewModal} centered toggle={() => this.setState({ previewModal: !this.state.previewModal })} size={this.state.formType !== 'chat-forms' ? "xl" : "lg"}>
                        <ModalHeader toggle={() => this.setState({ previewModal: !this.state.previewModal })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ previewModal: !this.state.previewModal })}><Isvg src={xIcon} /></button>}>{'Form preview'.translate(this.props.lang)}</ModalHeader>

                        <ModalBody>
                            {/* <FormPreview {...this.props} referral={{ name: this.state.name, description: this.state.description }} sections={this.generateSections()}></FormPreview> */}
                            <PreviewAnswerForm
                                {...this.props}
                                referralContent={this.state.formType !== 'chat-forms' ? true : false}
                                referral={{
                                    name: this.state.name,
                                    description: this.state.description,
                                    intelligentReferral: this.state.intelligentReferral,
                                    items: this.state.items
                                }}
                            />
                        </ModalBody>
                    </Modal>
                    :
                    null
                }
                {this.props.changeLinkModal ?
                    <Modal isOpen={this.props.changeLinkModal} centered>
                        {/* <ModalHeader style={{ margin: 'auto' }}>{'Warning'.translate(this.props.lang)}</ModalHeader> */}
                        <ModalBody className="delete-modal">
                            {/* <img src={warrning} /> */}
                            <p className="message">{'Data is not saved, if you click Yes it will be deleted'.translate(this.props.lang)}</p>
                        </ModalBody>
                        <ModalFooter className='buttons-right-blue'>
                            <Button color="primary" style={{ margin: 10, minWidth: 100 }} onClick={() => {
                                this.updateQuestionary(false, true)


                            }}>{'Save and exit'.translate(this.props.lang)}</Button>


                            <Button color="primary" style={{ margin: 10, minWidth: 100 }} onClick={() => this.setState({ showForm: null, tabIndex: this.state.tabIndexCheck, tabIndexCheck: false, colorSchemeChanged: false, selectedColorScheme: this.state.currentScheme, pageBuilderChanged: false }, () => {
                                this.setState({
                                    showForm: true
                                }, () => {
                                    if (this.props.changeLinkModal.indexOf('group|') != -1) {
                                        this.props.changeGroup(this.props.changeLinkModal.split('|')[1])
                                        this.props.abortAction()
                                    } else if (this.props.changeLinkModal.indexOf('clinic|') != -1) {
                                        this.props.changeClinic(this.props.changeLinkModal.split('|')[1]);
                                        this.props.abortAction()
                                    } else if (!this.state.pageBuilderChanged) {
                                        this.props[0].history.push(this.props.changeLinkModal)
                                        this.props.abortAction()
                                    }
                                })


                            })}>{'Exit without saving'.translate(this.props.lang)}</Button>

                        </ModalFooter>
                    </Modal>
                    : null}

            </div >
        );
    }
}

const selector = formValueSelector('modalForm');

export default connect(state => {
    return {
        group: selector(state, 'group'),
        type: selector(state, 'type'),
        displayOnReferral: selector(state, 'displayOnReferral'),
        conditionQuestion: selector(state, 'conditionQuestion')

    }

}, {
    changeSubgroup: value => change("modalForm", "subgroup", value),

})(Page(RevisitBuilder));