import React, { Component } from 'react';

import {
    Container,
    Row,
    Col,
    FormGroup, Label, Input,
    Button,
    Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';



import ListBuilder from './listBuilder';
import { getSearchParams, generateSearchLink, generateSearchLinkMultiple } from '../helpers/linkHelper';
import { API_ENDPOINT, GOOGLE_API_KEY } from '../constants';
import moment from 'moment';
import eRemissIcon from '../assets/svg/e.svg';
import edit from '../assets/svg/edit.svg'
import viewIcon from '../assets/svg/eye.svg'

import garabage from '../assets/svg/garbage.svg'
import DeleteModal from '../components/deleteModal';
import ErrorModal from '../components/errorModal';


import Isvg from 'react-inlinesvg';
import Checkbox from '../components/forms/fields/checkbox';


function parseJSON(response) {
    return response.json().then(json => {
        return {
            result: json,
            status: response.status
        }
    })
}


function getDistance(a, b, c, d,) {
    return getDst([a, b], [c, d]);
}

function rad(x) {
    return x * Math.PI / 180;
}

function getDst(p1, p2) {
    var R = 6378137; // Earth’s mean radius in meter
    var dLat = rad(p2[0] - p1[0]);
    var dLong = rad(p2[1] - p1[1]);
    var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(rad(p1[0])) * Math.cos(rad(p2[0])) *
        Math.sin(dLong / 2) * Math.sin(dLong / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;
    return (d / 1000).toFixed(1); // returns the distance in km
}

const loadDataWithQuery = [
    (fetchFunction, lang, match, query) => {

        let queryFields = {};
        for (var key in query) {
            if (key && key.indexOf('query-') === 0) {
                queryFields[key.replace('query-', '')] = query[key];
            }
        }
        // console.log(query)
        let sortField;
        if (query.sortField && query.sortField === "registrationDate" && query.group) {
            sortField = `loginInfo.${query.group}.lastLoginTs`;
        }



        return fetchFunction(API_ENDPOINT + '/users/patients', {
            method: 'POST',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
            body: JSON.stringify({
                page: query.page,
                entries: query.entries,
                filter: query.filter,
                sortType: query.sortType,
                sortField: sortField,
                query: queryFields,
                group: query.group,
                clinic: query.clinic,
                onlyUserLevel1: query.onlyUserLevel1
            })
        }).then(parseJSON).then(({ result, status }) => {
            if (status >= 200 && status < 300)
                return {
                    page: query.page ? query.page : 0,
                    items: result.items,
                    total: result.total,
                    resultFilter: result.filter
                }
        })

    },

]


/**
* List of patients for selected clinic and selected department
* @author   Aleksandar Dabic
*/

class UsersFilter extends Component {
    constructor(props) {
        super(props);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.searchResultsWrap = React.createRef();

        this.state = {
            regions: [],
            cities: [],
            groups: [],
            subgroups: [],
            ...props.initialData,
            useState: true,
            params: {
                entries: 10,
                page: 0,
                'query-group': null
            },
            items: [],
            total: 0,
            loading: true,
            suggestions: [],

        };
    }

    exportList = () => {

        let onlyUserLevel1 = false;
        if (!this.props.patientDelete && this.props.userLevel1) {
            onlyUserLevel1 = true;
        }

        let params = this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0, 'query-group': null, group: this.props.selectedGroup, clinic: this.props.selectedClinic, onlyUserLevel1: onlyUserLevel1 });
        params.group = this.props.selectedGroup;
        params.clinic = this.props.selectedClinic;
        params._ts = new Date().getTime();


        params.onlyUserLevel1 = onlyUserLevel1;

        let queryFields = {};
        for (var key in params) {
            if (key && key.indexOf('query-') === 0) {
                queryFields[key.replace('query-', '')] = params[key];
            }
        }

        let sortField;
        if (params.sortField && params.sortField === "registrationDate" && params.group) {
            sortField = `loginInfo.${params.group}.lastLoginTs`;
        }


        fetch(API_ENDPOINT + '/users/patients', {
            method: 'POST',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
            body: JSON.stringify({
                page: params.page,
                entries: params.entries,
                filter: params.filter,
                sortType: params.sortType,
                sortField: sortField,
                query: queryFields,
                group: params.group,
                clinic: params.clinic,
                onlyUserLevel1: params.onlyUserLevel1,
                export: true,
                lang: this.props.lang
            })
        }).then(parseJSON).then(({ result, status }) => {
            if (result && result.success && result.file) {
                window.location.href = API_ENDPOINT + `${result.file}`;
            }
        })
    }


    get = () => {
        let onlyUserLevel1 = false;
        if (!this.props.patientDelete && this.props.userLevel1) {
            onlyUserLevel1 = true;
        }

        let params = this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0, 'query-group': null, group: this.props.selectedGroup, clinic: this.props.selectedClinic, onlyUserLevel1: onlyUserLevel1 });
        params.group = this.props.selectedGroup;
        params.clinic = this.props.selectedClinic;
        params._ts = new Date().getTime();


        params.onlyUserLevel1 = onlyUserLevel1;


        this.setState({
            _ts: params._ts
        }, () => {
            for (let i = 0; i < loadDataWithQuery.length; i++) {
                loadDataWithQuery[i](window.fetch, this.props.lang, this.props[0].match, params).then((data) => {
                    if (data && data._clinicSearchTs && this.state._ts == data._clinicSearchTs || (data && !data._clinicSearchTs)) {
                        let dataToSet = { ...data }
                        if (dataToSet.page || dataToSet.items || dataToSet.total) {
                            dataToSet.loading = null;

                            let checkFilter = '';
                            if (dataToSet.resultFilter) {
                                checkFilter = String(dataToSet.resultFilter)
                                delete dataToSet.resultFilter;
                            }
                            // let search = this.props[0].location.search;
                            // let serachParams = getSearchParams(search);
                            let searchParamsFilter = '';
                            if (params.filter) {
                                searchParamsFilter = params.filter;
                            }
                            if (searchParamsFilter != checkFilter) {
                                delete dataToSet.page;
                                delete dataToSet.items;
                                delete dataToSet.total;
                                delete dataToSet.loading;
                            }
                        }
                        this.setState({
                            ...dataToSet,
                            // loading: null
                        })
                    }
                })
            }

        })


    }

    componentDidMount() {
        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }

        this.get();
        document.addEventListener('mousedown', this.handleClickOutside);

        let params = getSearchParams(this.props[0].location.search, { entries: 10, page: 0, 'query-group': null });
        if (params['query-search']) {
            this.setState({
                search: params['query-search']
            })
        }

        if (this.props.searchFor) {
            this.updateParams('query-search', this.props.searchFor);
        }
        if (this.props.includeTags) {
            this.updateParams('query-includeTags', this.props.includeTags);
        }

        // if ("geolocation" in window.navigator) {
        //     window.navigator.geolocation.getCurrentPosition((position) => {

        //         this.setState({
        //             currentLocation: position.coords
        //         })

        //         this.updateParams('client-location', `${position.coords.latitude},${position.coords.longitude}`);
        //     });

        // }


    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }



    componentDidUpdate(prevProps, prevState) {
        if (prevProps[0].location.search != this.props[0].location.search) {
            this.setState({
                loading: true
            }, () => {
                this.get();

            })

        }

        if (this.state.useState && prevState._useStateTs != this.state._useStateTs) {
            this.setState({
                loading: true
            }, () => {
                this.get();

            })

        }

        if (prevProps.selectedGroup != this.props.selectedGroup) {
            this.get();
        }


        if (prevProps.getData != this.props.getData && this.props.getData) {
            let params = {};
            if (this.state.useState) {
                params = this.state.params;
            } else {
                params = getSearchParams(this.props[0].location.search, { entries: 10, page: 0 })
            }

            this.updateMultipleParams(params, true)
            // this.get()
            if (this.props.setData)
                this.props.setData()
        }

    }


    updateSort = (field, type) => {
        if (this.state.useState) {
            let state = this.state.params;
            state.sortField = field == 'waitingTime' ? 'waitingMin' : field;
            state.sortType = type;
            this.setState({
                params: state
            })

            this.setState({
                _useStateTs: new Date().getTime()
            })

        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0 }, [{ name: 'sortField', value: field == 'waitingTime' ? 'waitingMin' : field }, { name: 'sortType', value: type }], false));

        }
    }

    updateParams = (name, value, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };

                obj[name] = value;

                this.setState({ params: obj });
            } else {
                let obj = this.state.params;
                obj[name] = value;


                this.setState({ params: obj })
            }

            this.setState({
                _useStateTs: new Date().getTime()
            })
        } else {
            this.props[0].history.push(generateSearchLink(this.props[0].location.search, { entries: 10, page: 0 }, name, value, restart));
        }
    }

    updateMultipleParams = (fields, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj });

            } else {
                let obj = this.state.params;
                for (let i = 0; i < fields.length; i++) {

                    obj[fields[i].name] = fields[i].value;

                }
                this.setState({ params: obj })
            }

            this.setState({
                _useStateTs: new Date().getTime()
            })

        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0 }, fields, restart));
        }
    }






    handleClickOutside = (event) => {
        if (this.searchResultsWrap && this.searchResultsWrap.current && !this.searchResultsWrap.current.contains(event.target)) {
            this.setState({
                showSuggestions: false
            })
        }
    }


    waitingTimePrint(min, max) {
        if (min === '' && max === '') {
            return 'No data'.translate(this.props.lang);
        } else if (min == '0' && max == '0') {
            return '0' + ' ' + 'weeks'.translate(this.props.lang);
        } else if (min > 0 && max == '0') {
            return min + ' ' + 'weeks minimum'.translate(this.props.lang);
        } else if (min == '0' && max > 0) {
            return max + ' ' + 'weeks maximum'.translate(this.props.lang);
        } else if (min == max) {
            return max + ' ' + 'weeks'.translate(this.props.lang);
        } else return min + ' ' + 'to'.translate(this.props.lang) + ' ' + max + ' ' + 'weeks'.translate(this.props.lang);
    }

    /**
    * Delete patient from list of patients for selected clinic and selected department
    * @author   Aleksandar Dabic
    * @Objectparam    {String} id                  user's id
    */
    delete = (data) => { 
        this.setState({
            loading: true
        }, () => {

            fetch(API_ENDPOINT + '/users/patients/' + data._id + '/' + this.props.selectedGroup, {
                method: 'DELETE',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify({ name: data.userData.name })
            }).then(parseJSON).then(({ result, status }) => {
                if (result && result.error) {
                    this.setState({ error: result.error, connectedAccountsNames: result.connectedAccountsNames,  loading: false })
                    
                }else{
                    this.setState({
                        loading: false
                    })
                    this.get();
                }
            })
        })

    }



    render() {
        let params = {};
        if (this.state.useState) {
            params = this.state.params;
        } else {
            params = getSearchParams(this.props[0].location.search, { entries: 10, page: 0 })
        }


        let fields = [
            { type: 'text', name: 'socialSecurityNumber', label: 'Social security number'.translate(this.props.lang), allowSort: true },
            { type: 'text', name: 'name', label: 'Name'.translate(this.props.lang), allowSort: true },
            // { type: 'text', name: 'registrationDate', label: 'Registration date'.translate(this.props.lang), allowSort: false }

        ]
        if (!this.props.patientDelete && this.props.userLevel1) {
            fields = [
                { type: 'text', name: 'socialSecurityNumber', label: 'Social security number'.translate(this.props.lang), allowSort: true },
                { type: 'text', name: 'name', label: 'Name'.translate(this.props.lang), allowSort: true },
                { type: 'text', name: 'registrationDate', label: 'Registration date'.translate(this.props.lang), allowSort: true }

            ]
        }

        return (
            <div className="users-filter">
                {this.state.error ?

                    <ErrorModal lang={this.props.lang}
                        isOpen={this.state.error}
                        toggle={() => this.setState({ error: null })}

                    >
                        {this.state.error.translate(this.props.lang) + ' '}
                        <span style={{ fontWeight: 600 }}>
                            {
                                this.state.connectedAccountsNames && this.state.connectedAccountsNames.length && this.state.connectedAccountsNames.map((item, idx) => {
                                    if (idx != this.state.connectedAccountsNames.length - 1)
                                        return (
                                            <>
                                                {item},&nbsp;
                                            </>
                                        )
                                    else {
                                        return (
                                            <>
                                                {item}
                                            </>
                                        )
                                    }
                                })
                            }
                        </span>
                    </ErrorModal>

                    :
                    null
                }

                <ListBuilder
                    lang={this.props.lang}
                    loading={this.state.loading}
                    total={this.state.total}
                    showNumeration={false}
                    export={!this.props.patientDelete && this.props.userLevel1 && this.state.items && this.state.items.length ? true : false}
                    exportFunction={() => {
                        this.exportList()
                    }}
                    actionLabel={'Actions'.translate(this.props.lang)}
                    fields={fields}
                    items={this.state.items.map((item, idx) => {
                        return {
                            ...item,
                            name: item.userData.name,
                            registrationDate: item.loginInfo && item.loginInfo[this.props.selectedGroup] && item.loginInfo[this.props.selectedGroup].lastLoginTs ? this.props.getStringDateTs(item.loginInfo[this.props.selectedGroup].lastLoginTs, `${this.props.dateFormat} HH:mm`)/*moment.unix(item.loginInfo[this.props.selectedGroup].lastLoginTs).format(`${this.props.dateFormat} HH:mm`)*/ : '',
                            _checked: <Checkbox disabled checked={this.props.value && this.props.value._id == item._id} onChange={() => {
                            }} />,
                        }
                    })}

                    actions={
                        [
                            {
                                component: <div className="tabel-action-tooltip"> <Isvg src={garabage} className="delete-icon" /> <div className="chat-icons-tooltip">{'Delete'.translate(this.props.lang)}</div> </div>,
                                onClick: (item) => this.setState({ deleteModal: item }),
                                condition: (item) => {
                                    if (this.props.patientDelete) {
                                        return true
                                    } else {
                                        return false;
                                    }

                                }
                            },
                            {
                                component: <div className="tabel-action-tooltip"> <Isvg src={viewIcon} className="delete-icon" /> <div className="chat-icons-tooltip">{'Preview'.translate(this.props.lang)}</div> </div>,
                                onClick: (item) => {
                                    let address = '';
                                    let user = JSON.parse(JSON.stringify(item));
                                    if (user) {
                                        if (user.street) {
                                            address += user.street;
                                        }
                                        if (user.zip) {
                                            if (address.length) {
                                                address += ', ' + user.zip;
                                            } else {
                                                address += user.zip;
                                            }
                                        }

                                        if (user.city) {
                                            if (user.zip) {
                                                address += ' ' + user.city;
                                            } else if (address.length && !user.zip) {
                                                address += ', ' + user.city;
                                            } else {
                                                address += user.city;
                                            }

                                        }
                                    }
                                    if (user.userData && user.userData.sex) {
                                        user.gender = user.userData.sex === 'male' ? 'Male'.translate(this.props.lang) : user.userData.sex === 'female' ? 'Female'.translate(this.props.lang) : '';
                                    }
                                    user.address = address;
                                    this.setState({ previewModal: user })
                                },
                                condition: (item) => {
                                    if (!this.props.patientDelete && this.props.userLevel1) {
                                        return true
                                    } else {
                                        return false;
                                    }

                                }
                            },


                        ]
                    }
                    rawItems={this.state.items}
                    onClick={(item) => {
                        this.props.onChange(item);
                    }}
                    params={params}
                    sortField={params.sortField}
                    sortType={params.sortType}
                    updateSort={this.updateSort}
                    updateParams={this.updateParams}
                    updateMultipleParams={this.updateMultipleParams}
                ></ListBuilder>

                {
                    this.state.deleteModal ?
                        <DeleteModal
                            lang={this.props.lang}
                            isOpen={this.state.deleteModal}
                            toggle={() => this.setState({ deleteModal: null })}
                            handler={() => { this.delete(this.state.deleteModal); this.setState({ deleteModal: null }) }}
                        >
                            {'Delete user'.translate(this.props.lang)} <strong>{this.state.deleteModal && this.state.deleteModal.userData && this.state.deleteModal.userData.name}</strong>?
                            <p>{'Make sure all relevant data has been transferred to the Health Records System before deleting the account.'.translate(this.props.lang)}</p>
                        </DeleteModal>
                        :
                        null
                }

                {
                    this.state.previewModal ?
                        <Modal isOpen={this.state.previewModal} centered>
                            <ModalHeader toggle={() => this.setState({ previewModal: null })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ previewModal: null })}>&times;</button>}>
                                <h4>{'Patient profile'.translate(this.props.lang)}</h4>
                            </ModalHeader>
                            <ModalBody>
                                <FormGroup>
                                    <Label>{'Social security number'.translate(this.props.lang)}</Label>
                                    <Input type='text' disabled
                                        value={this.state.previewModal.socialSecurityNumber} />
                                </FormGroup>
                                <FormGroup>
                                    <Label>{'Name'.translate(this.props.lang)}</Label>
                                    <Input type='text' disabled
                                        value={this.state.previewModal.name} />
                                </FormGroup>
                                <FormGroup>
                                    <Label>{'Gender'.translate(this.props.lang)}</Label>
                                    <Input type='text' disabled
                                        value={this.state.previewModal.gender} />
                                </FormGroup>
                                <FormGroup>
                                    <Label>{'Email'.translate(this.props.lang)}</Label>
                                    <Input type='text' disabled
                                        value={this.state.previewModal.email} />
                                </FormGroup>
                                <FormGroup>
                                    <Label>{'Phone'.translate(this.props.lang)}</Label>
                                    <Input type='text' disabled
                                        value={this.state.previewModal.phone} />
                                </FormGroup>
                                <FormGroup>
                                    <Label>{'Address'.translate(this.props.lang)}</Label>
                                    <Input type='text' disabled
                                        value={this.state.previewModal.address ? this.state.previewModal.address : ''} />
                                </FormGroup>

                                <Button color="primary" onClick={() => this.setState({ previewModal: !this.state.previewModal })}>{'Close'.translate(this.props.lang)}</Button>

                            </ModalBody>
                        </Modal>
                        :
                        null
                }

            </div >
        );


    }
}
export default UsersFilter;