import React, { Component } from 'react';
import Page from '../../containers/page';

import {
    Container,
    Row,
    Col,
    Modal, ModalHeader, ModalBody, ModalFooter, Button, FormGroup, Input, Label, Table
} from 'reactstrap';

import Isvg from "react-inlinesvg";

import { formValueSelector, change } from 'redux-form';  // ES6
import { connect } from 'react-redux';
import addIcon from '../../assets/svg/add-icon.svg';
import edit from '../../assets/svg/edit.svg'
import garabage from '../../assets/svg/garbage.svg'

import FormBuilder from '../../components/forms/modalFormBuilder';
import { required } from '../../components/forms/validation';

import ListBuilder from '../../components/listBuilder';
import DeleteModal from '../../components/deleteModal';
import ErrorModal from '../../components/errorModal';
import Search from "../../components/search";
import profileUser from "../../assets/images/user.png";
import notification from "../../assets/svg/notification.svg";
import notificationSettings from "../../assets/svg/notifications_settings.svg";
import moreIcon from "../../assets/svg/more.svg";
import { getSearchParams, generateSearchLink, generateSearchLinkMultiple } from '../../helpers/linkHelper';
import { API_ENDPOINT } from '../../constants';
import Checkbox from '../../components/forms/fields/checkbox';
import { Player, Controls } from "@lottiefiles/react-lottie-player";
import xIcon from '../../assets/svg/x.svg';
import striptags from 'striptags';


function parseJSON(response) {
    return response.json().then(json => {
        return {
            result: json,
            status: response.status
        }
    })
}

/**
* Clinic users page
* @author   Milan Stanojevic
*/
class SubAccounts extends Component {
    constructor(props) {
        super(props);
        this.player = React.createRef();
        this.player2 = React.createRef();
        this.state = {
            ...props.initialData,
            form: false,
            useState: false,
            params: {
                entries: 10,
                page: 0
            },
            items: [],
            endpoints: [],
            formModules: [],
            userLevels: [],
            languages: [],
            formUsers: [],

            total: 0,
            loading: true,
            questionaryModal: false,
            questionaryList: [],
            tabIndex: 1,
        };
    }


    showPlayer = () => {
        this.setState({
            showPlayer: true,
        });
    };

    hidePlayer = () => {
        this.setState({
            showPlayer: false,
        });
    };

    get = () => {
        let type = 'doctor';
        if (this.state.tabIndex == 1) {
            let isAdmin = false;
            if (this.props.uData && this.props.uData.permissions && this.props.uData.permissions.admin && this.props.uData.permissions.clinicAdmin && this.props.selectedClinic && this.props.selectedGroup && this.props.uData.groupPermissionsUser && this.props.uData.groupPermissionsUser[this.props.selectedGroup] && this.props.uData.groupPermissionsUser[this.props.selectedGroup][this.props.selectedClinic] && this.props.uData.groupPermissionsUser[this.props.selectedGroup][this.props.selectedClinic].filter(el => el == this.props.uData.permissions.admin || el == this.props.uData.permissions.clinicAdmin).length) {
                isAdmin = true;
            }

            if (isAdmin) {
                type = 'clinic';
            }
        }


        this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));

        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0, groupId: this.props.selectedGroup, clinic: this.props.selectedClinic })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

        for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
            this.props.loadDataWithQuery[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0, groupId: this.props.selectedGroup, clinic: this.props.selectedClinic, type: type })).then((data) => {
                let dataToSet = { ...data }
                if (dataToSet.page || dataToSet.items || dataToSet.total) {
                    dataToSet.loading = null;
                    // console.log(dataToSet.page || dataToSet.items || dataToSet.total)
                    let checkFilter = '';
                    if (dataToSet.resultFilter) {
                        checkFilter = String(dataToSet.resultFilter)
                        delete dataToSet.resultFilter;
                    }
                    let search = this.props[0].location.search;
                    let serachParams = getSearchParams(search);
                    let searchParamsFilter = '';
                    if (serachParams.filter) {
                        searchParamsFilter = serachParams.filter;
                    }
                    if (searchParamsFilter != checkFilter) {
                        delete dataToSet.page;
                        delete dataToSet.items;
                        delete dataToSet.total;
                        delete dataToSet.loading;
                    }
                }
                this.setState({
                    ...dataToSet,
                    // loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }



    }

    componentDidMount() {
        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }

        if (this.props.uData && this.props.uData.clinicModules && this.props.uData.clinicModules.length) {
            for (let i = 0; i < this.props.uData.clinicModules.length; i++) {
                if (this.props.uData.clinicModules[i].name == "Vårdutövare") {
                    this.setState({ doctorIdPermissions: this.props.uData.clinicModules[i]._id })
                }
            }
        }

        this.get();
    }

    componentDidUpdate(prevProps) {
        if (prevProps[0].location.search != this.props[0].location.search) {
            this.setState({
                loading: true
            }, () => {
                this.get();

            })
        }
        if (prevProps[0].location.pathname != this.props[0].location.pathname) {
            this.setState({
                loading: true
            }, () => {
                this.get();

            })
        }

        if (prevProps.selectedGroup != this.props.selectedGroup) {
            this.setState({
                loading: true
            }, () => {
                this.get();

            })

        }
        
        if (prevProps.selectedClinic != this.props.selectedClinic) {
            this.setState({
                loading: true
            }, () => {
                this.get();

            })

        }

        if (prevProps.socialSecurityNumber != this.props.socialSecurityNumber && this.props.socialSecurityNumber && (this.props.socialSecurityNumber.length == 12 || (this.props.country === 'denmark' && this.props.socialSecurityNumber.length == 10))) {
            this.checkUser();
        }

        if (prevProps.name != this.props.name && this.state.form && this.props.name && this.props.name.indexOf(' ') !== -1) {
            this.getUsername();
        }


    }
    setDoctorPermissions = () => {
        this.setState({ doctorPermissions: true })
    }
    setOffDoctorPermissions = () => {
        this.setState({ doctorPermissions: false })
        this.props.changeQuestionaryList([])
    }



    updateSort = (field, type) => {
        if (this.state.useState) {
            let state = this.state.params;
            state.sortField = field;
            state.sortType = type;
            this.setState({
                params: state
            })
        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0 }, [{ name: 'sortField', value: field }, { name: 'sortType', value: type }], false));

        }
    }

    updateParams = (name, value, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };
                obj[name] = value;
                this.setState({ params: obj });
            } else {
                let obj = this.state.params;
                obj[name] = value;
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(generateSearchLink(this.props[0].location.search, { entries: 10, page: 0 }, name, value, restart));
        }
    }
    updateMultipleParams = (fields, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj });

            } else {
                let obj = this.state.params;
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0 }, fields, restart));
        }
    }


    /**
    * Insert or update user
    * @author   Milan Stanojevic
    * @Objectparam    {String} clinic                   clinic id
    * @Objectparam    {String} group                    group id
    * @Objectparam    {Object} groupPermissions         groupPermissions for user
    * @Objectparam    {Object} data                     form data
    */
    insertOrUpdate = (data) => {
        console.log(data)

        this.setState({ loading: true }, () => {
            fetch(API_ENDPOINT + '/users/account/update/clinic-phrase/' + (data._id ? data._id : 'new'), {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify({ ...data, groupPermissions: this.state.form.groupPermissions, group: this.props.selectedGroup, clinic: this.props.selectedClinic })
            }).then(parseJSON).then(({ result, status }) => {

                this.get();
                this.setState({
                    formItem: null,
                })
            })
        })




    }

    /**
    * Delete user
    * @author   Milan Stanojevic
    * @Objectparam    {String} id                   user id
    */
    delete = (id) => {
        this.setState({
            loading: true
        }, () => {

            fetch(API_ENDPOINT + '/users/account/update/clinic-phrase/' + id, {
                method: 'DELETE',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify({ groupId: this.props.selectedGroup })
            }).then(parseJSON).then(({ result, status }) => {
                if (result.error) {
                    this.setState({ error: result.error })
                }
                this.get();
            })
        })

    }




    render() {
        let params = {};
        if (this.state.useState) {
            params = this.state.params;
        } else {
            params = getSearchParams(this.props[0].location.search, { entries: 10, page: 0 })
        }

        //console.log(this.props)
        let selectedGroupIdx = 0;
        if (this.props.selectedGroup && this.props.uData?.clinicGroups?.length) {
            for (let i = 0; i < this.props.uData.clinicGroups.length; i++) {
                if (this.props.uData.clinicGroups[i]._id == this.props.selectedGroup) {
                    selectedGroupIdx = i;
                    break;
                }
            }
        }
        let isAdmin = false;
        if (this.props.uData && this.props.uData.permissions && this.props.uData.permissions.admin && this.props.uData.permissions.clinicAdmin && this.props.selectedClinic && this.props.selectedGroup && this.props.uData.groupPermissionsUser && this.props.uData.groupPermissionsUser[this.props.selectedGroup] && this.props.uData.groupPermissionsUser[this.props.selectedGroup][this.props.selectedClinic] && this.props.uData.groupPermissionsUser[this.props.selectedGroup][this.props.selectedClinic].filter(el => el == this.props.uData.permissions.admin || el == this.props.uData.permissions.clinicAdmin).length) {
            isAdmin = true;
        }
        console.log(isAdmin)

        return (
            <div className={this.props.autoHideSidebar ? "dashboard dashboard-auto-hide" : "dashboard"}>

                <Container fluid>
                    <Row>

                        <Col lg="12">
                            <div className="panel">

                                <div className="panel-header">
                                    <h5 className="component-header">{this.props.linksMeta && this.props.linksMeta[this.props[0].location.pathname] ? this.props.linksMeta[this.props[0].location.pathname][this.props.lang] : ''}
                                    </h5>
                                    <button className="btn add-button" onClick={() => this.setState({
                                        formItem: {
                                            type: !isAdmin ? 'doctor' : this.state.tabIndex == 1 ? 'clinic' : this.state.tabIndex == 2 ? 'doctor' : null
                                        }
                                    })}><Isvg src={addIcon} /> {'Add new phrase'.translate(this.props.lang)}</button>
                                </div>
                                {
                                    isAdmin ?
                                        <div className="flex-space-between tablet-none notifications-tabs-wrap" style={{ position: 'relative', marginBottom: '50px', width: "100%", borderBottom: "1px solid #9cafbf" }}>
                                            <div className="notifications-tabs tabs-buttons-wrap" style={{ marginBottom: 0, borderBottom: 0 }}>
                                                <Button className={this.state.tabIndex == 1 ? "tab-button acitve-tab-button" : "tab-button"}
                                                    onClick={() => this.setState({ tabIndex: 1 }, () => {
                                                        this.updateMultipleParams([{ name: 'page', value: '0' }, { name: 'entries', value: 10 }, { name: 'filter', value: '' }])
                                                        this.setState({ updateFilterField: new Date().getTime() })
                                                        this.get()
                                                    })}
                                                >{"Clinic phrases".translate(this.props.lang)}</Button>
                                                <Button className={this.state.tabIndex == 2 ? "tab-button acitve-tab-button" : "tab-button"}
                                                    onClick={() => this.setState({ tabIndex: 2 }, () => {
                                                        this.updateMultipleParams([{ name: 'page', value: '0' }, { name: 'entries', value: 10 }, { name: 'filter', value: '' }])
                                                        this.setState({ updateFilterField: new Date().getTime() })
                                                        this.get()
                                                    })}
                                                >{"My phrases".translate(this.props.lang)}</Button>
                                            </div>
                                        </div>
                                        :
                                        null
                                }


                                <ListBuilder
                                    lang={this.props.lang}
                                    loading={this.state.loading}
                                    total={this.state.total}
                                    showNumeration={false}
                                    updateFilterField={this.state.updateFilterField}
                                    fields={[
                                        { type: 'text', name: 'title', label: 'Title'.translate(this.props.lang), allowSort: true },
                                        { type: 'text', name: 'phraseText', label: 'Phrase'.translate(this.props.lang), allowSort: true },


                                    ]}
                                    items={this.state.items.map(item => {
                                        return {
                                            ...item,
                                            phraseText: item.phraseText ? item.phraseText : item.phrase

                                        }
                                    })}
                                    actions={
                                        [

                                            {
                                                component: <div className="tabel-action-tooltip"> <Isvg src={edit} /> <div className="chat-icons-tooltip">{'Edit'.translate(this.props.lang)}</div> </div>,
                                                onClick: (item) => this.setState({ formItem: JSON.parse(JSON.stringify(item)) })
                                            },
                                            {
                                                component: <div className="tabel-action-tooltip"> <Isvg src={garabage} /> <div className="chat-icons-tooltip">{'Delete'.translate(this.props.lang)}</div> </div>,
                                                onClick: (item) => this.setState({ deleteModal: item })
                                            },
                                        ]
                                    }
                                    rawItems={this.state.items}
                                    params={params}
                                    sortField={params.sortField}
                                    sortType={params.sortType}
                                    updateSort={this.updateSort}
                                    updateParams={this.updateParams}
                                    updateMultipleParams={this.updateMultipleParams}
                                ></ListBuilder>
                                <DeleteModal
                                    lang={this.props.lang}
                                    isOpen={this.state.deleteModal}
                                    toggle={() => this.setState({ deleteModal: null })}
                                    handler={() => { this.delete(this.state.deleteModal._id); this.setState({ deleteModal: null }) }}
                                >
                                    {'Delete phrase'.translate(this.props.lang)} <strong>{this.state.deleteModal && this.state.deleteModal.title}</strong>?
                                </DeleteModal>
                                {this.state.error ?

                                    <ErrorModal lang={this.props.lang}
                                        isOpen={this.state.error}
                                        toggle={() => this.setState({ error: null })}

                                    >
                                        {this.state.error.translate(this.props.lang)}
                                    </ErrorModal>

                                    :
                                    null
                                }



                            </div>
                        </Col>
                    </Row>

                </Container>
                {this.state.formItem ?
                    <Modal isOpen={this.state.formItem} centered>
                        <ModalHeader toggle={() => this.setState({ formItem: null })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ formItem: null })}><Isvg src={xIcon} /></button>}>
                            <h4>
                                {
                                    this.state.formItem._id ?
                                        'Edit phrase'.translate(this.props.lang)
                                        :
                                        'Add new phrase'.translate(this.props.lang)
                                }
                            </h4>
                        </ModalHeader>
                        <ModalBody>
                            <FormGroup>
                                <Label>{'Title'.translate(this.props.lang)}</Label>
                                <Input type='text' value={this.state.formItem.title} onChange={(e) => {
                                    let formItem = this.state.formItem;
                                    formItem.title = striptags(e.target.value);
                                    this.setState({ formItem })
                                }} />
                            </FormGroup>
                            <FormGroup>
                                <Label>{'Text'.translate(this.props.lang)}</Label>
                                <Input type='textarea' value={this.state.formItem.phrase} onChange={(e) => {
                                    let formItem = this.state.formItem;
                                    formItem.phrase = striptags(e.target.value);
                                    this.setState({ formItem })
                                }}
                                    style={{ height: 100 }}
                                />
                            </FormGroup>

                        </ModalBody>
                        <ModalFooter className='buttons-right-blue'>
                            <Button color="primary" disabled={false} onClick={() => this.insertOrUpdate(this.state.formItem)}>{'Save'.translate(this.props.lang)}</Button>

                            <Button color="primary" onClick={() => this.setState({ formItem: null })}>{'Close'.translate(this.props.lang)}</Button>

                        </ModalFooter>
                    </Modal>
                    :
                    null
                }


            </div>
        );
    }
}


const selector = formValueSelector('modalForm');

export default connect(state => {
    return {
        userLevel: selector(state, 'userLevel'),
        alias: selector(state, 'alias'),
        socialSecurityNumber: selector(state, 'socialSecurityNumber'),
        username: selector(state, 'username'),
        name: selector(state, 'userData.name'),
        questionaryList: selector(state, 'questionaryList'),


    }


}, {
    changeUserData: value => change("modalForm", "userData", value),
    changeEmail: value => change("modalForm", "email", value),
    changeUsername: value => change("modalForm", "username", value),
    changeLanguage: value => change("modalForm", "language", value),
    changePhone: value => change("modalForm", "phone", value),
    changeQuestionaryList: value => change("modalForm", "questionaryList", value)

})(Page(SubAccounts));