import React, { Component } from 'react';

import {
    Container,
    Row,
    Col,
    FormGroup, Label, Input,
    Button,
    Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';



import moment from 'moment';

import { API_ENDPOINT } from '../../../constants';
import caseIcon from '../../../assets/svg/case-mob.svg'
import questionaryIcon from '../../../assets/svg/questionary-mob.svg'
import calendarIcon from '../../../assets/svg/calendar-mob.svg'


import Isvg from 'react-inlinesvg';
import Search from '../../../components/search';

import DeleteModal from '../../../components/deleteModal';
import { getSearchParams, generateSearchLink, generateSearchLinkMultiple } from '../../../helpers/linkHelper';
import { required } from '../../../components/forms/validation';


import ListBuilderMob from '../../../components/listBuilderMob';
import ListBuilder from '../../../components/listBuilder';
import { Waypoint } from 'react-waypoint';

import ReferralContent from '../../../components/referralContent';
import FormBuilder from '../../../components/forms/modalFormBuilder';
import PayNowModal from '../../../components/payNowModal';
import Payson from '../../../components/payson';
import Swish from '../../swish';
import AnswerRevisitForm from '../../forms/revisitForm';
import FormPreview from '../../forms/previewAnsweredForm';

import ReferralClinics from '../../../components/referralClinics';
import PrintHelper from '../../../components/printHelper';
import info from '../../../assets/svg/info.svg';
import xIcon from '../../../assets/svg/x.svg';
import warrning from '../../../assets/svg/warrning.svg'
import InfoModal from '../../../components/infoModal';
import RescheduleWebBookingCalendar from './rescheduleWebBookingCalendar';
import Stripe from '../../../components/stripe';
import ErrorModal from '../../../components/errorModal';

import ChatIcon from '../../../assets/svg/chat-case.svg';
import VideoIcon from '../../../assets/svg/video-case.svg';
import HospitalIcon from '../../../assets/svg/hospital.svg';
import WaitingRoom from '../../../assets/svg/waiting-room.svg';

import WaitingRoom2 from '../../../icons/WaitingRoom';


function parseJSON(response) {
    return response.json().then(json => {
        return {
            result: json,
            status: response.status
        }
    })
}




const loadDataWithQuery = [
    (fetchFunction, lang, match, query) => {

        let queryFields = {};
        for (var key in query) {
            if (key && key.indexOf('query-') === 0) {
                queryFields[key.replace('query-', '')] = query[key];
            }
        }


        return fetchFunction(API_ENDPOINT + '/referrals/received-filter', {
            method: 'POST',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
            body: JSON.stringify({
                page: query.page,
                entries: 10,
                filter: query.filter,
                sortType: -1,
                sortField: 'timestamp',
                personalReferral: true,
                patient: query.socialSecurityNumber,
                status: query.status,
                web: true,
                dropInReferrals: query.dropInReferrals,

            })
        }).then(parseJSON).then(({ result, status }) => {
            if (status >= 200 && status < 300)
                return {
                    page: query.page ? query.page : 0,
                    items: result.items,
                    total: result.total,
                    resultFilter: result.filter
                }
        })


    },

]


class PatientCasesMob extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props.initialData,
            form: false,
            useState: false,
            params: {
                entries: 10,
                page: 0
            },
            items: [],
            groups: [],
            regions: [],
            total: 0,
            loading: true,
            activeRevisitForm: 1,
            availableProfessions: [],
        };
    }


    get = () => {
        if (!this.props.uData) {
            return;
        }
        let params = this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0, group: this.props.selectedGroup, clinic: this.props.selectedClinic });
        // params.group = this.props.selectedGroup;
        // params.clinic = this.props.selectedClinic;
        params._ts = new Date().getTime();
        params.socialSecurityNumber = this.props.uData.socialSecurityNumber;
        params.dropInReferrals = this.state.dropInReferrals;
        params.status = this.state.status;

        this.setState({
            _ts: params._ts
        }, () => {
            for (let i = 0; i < loadDataWithQuery.length; i++) {
                loadDataWithQuery[i](window.fetch, this.props.lang, this.props[0].match, params).then((data) => {


                    let params = getSearchParams(this.props[0].location.search, { entries: 10, page: 0 });

                    if (data.items && !(params.page === '0' || params.page === 0)) {
                        data.items = [...this.state.items, ...data.items];
                    }

                    this.setState({
                        ...data,
                        loading: null
                    }, () => {
                        if (this.state.referral && this.state.items && this.state.items.length && this.state.items.filter(el => el._id == this.state.referral._id).length) {
                            let index = this.state.items.findIndex(el => el._id == this.state.referral._id);
                            if (index > -1) {
                                this.setState({ referral: this.state.items[index] })
                            }
                        }
                    })
                })
            }

        })


    }

    componentDidMount() {
        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }



        let params = getSearchParams(this.props[0].location.search, { entries: 10, page: 0 });
        if ((params.page === '0' || params.page === 0) && !params.filter) {
            this.get()
        } else {
            // this.updateParams('page', 0)
            this.updateMultipleParams([{ name: 'filter', value: '' }, { name: 'page', value: 0 }], true)
        }

        if (this.props.regions) {
            this.setState({ regions: this.props.regions })
        }



    }

    componentWillUnmount() {
    }



    componentDidUpdate(prevProps, prevState) {
        if (prevProps[0].location.search != this.props[0].location.search) {
            this.setState({
                loading: true
            }, () => {
                this.get();

            })

        }


        if (prevProps.selectedGroup != this.props.selectedGroup) {
            this.get();
        }

        if (this.props.regions != prevProps.regions) {
            this.setState({ regions: this.props.regions })
        }

    }


    updateSort = (field, type) => {
        if (this.state.useState) {
            let state = this.state.params;
            state.sortField = field == 'waitingTime' ? 'waitingMin' : field;
            state.sortType = type;
            this.setState({
                params: state
            })

            this.setState({
                _useStateTs: new Date().getTime()
            })

        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0 }, [{ name: 'sortField', value: field == 'waitingTime' ? 'waitingMin' : field }, { name: 'sortType', value: type }], false));

        }
    }

    updateParams = (name, value, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };

                obj[name] = value;

                this.setState({ params: obj });
            } else {
                let obj = this.state.params;
                obj[name] = value;


                this.setState({ params: obj })
            }

            this.setState({
                _useStateTs: new Date().getTime()
            })
        } else {
            this.props[0].history.push(generateSearchLink(this.props[0].location.search, { entries: 10, page: 0 }, name, value, restart));
        }
    }

    updateMultipleParams = (fields, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj });

            } else {
                let obj = this.state.params;
                for (let i = 0; i < fields.length; i++) {

                    obj[fields[i].name] = fields[i].value;

                }
                this.setState({ params: obj })
            }

            this.setState({
                _useStateTs: new Date().getTime()
            })

        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0 }, fields, restart));
        }
    }




    setStatus = (status) => {
        let obj = {
            items: [],
            loading: true,
            status: status,
            dropInReferrals: !status || status === '_dropin' ? true : false
        }

        this.setState(obj, () => {
            this.updateMultipleParams([{ name: 'page', value: 0 }], true)
            this.get()
        })
    }

    rejectReferral = (data) => {
        this.setState({
            loading: true
        }, () => {
            fetch(API_ENDPOINT + '/referrals/patient/reject/' + this.state.referral._id, {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify(data)
            }).then(res => res.json()).then((result) => {
                this.get();
                if (result?.error) {
                    this.setState({
                        error: result.error?.translate(this.props.lang),
                        loading: false
                    })
                } else {
                    this.setState({
                        referral: null,
                        rejectForm: false,
                        requestAnotherDateForm: false
                    })
                }

            })
        })
    }
    rejectWebBookingReferral = (data) => {
        this.setState({
            loading: true
        }, () => {
            fetch(API_ENDPOINT + '/referrals/patient/reject/' + this.state.rejectWebBooking._id, {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify(data)
            }).then(res => res.json()).then((result) => {
                this.get();
                if (result?.error) {
                    this.setState({
                        error: result.error?.translate(this.props.lang),
                        loading: false
                    })
                } else {
                    this.setState({
                        rejectWebBooking: null,
                    })
                }

            })
        })
    }
    updatePaymentControl = (data) => {
        // console.log(data)
        this.setState({
            loading: true
        }, () => {
            fetch(API_ENDPOINT + '/working-hours/control-change-payment-method/' + data._id, {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify(data)
            }).then(res => res.json()).then((result) => {
                // console.log(result)
                if (result.paymentMethod == 1) {
                    this.setState({
                        paymnetOptionsControl: null,
                        payNowModal: null,
                        successModal: 'Payment must be made "On site" before the visit.'.translate(this.props.lang)
                    }, () => {
                        // console.log(this.state.paymnetOptions)

                    })
                    this.get();
                } else if (result.paymentMethod == 2) {
                    this.setState({
                        paymnetOptionsControl: null,
                        payNowModal: null,
                        successModal: 'Once the invoice is created, it will be delivered to your email address, and you will also be able to download it on the "My payments" page.'.translate(this.props.lang)
                    }, () => {
                        // console.log(this.state.paymnetOptions)

                    })
                    this.get();
                } else {
                    this.setState({
                        paymnetOptionsControl: null,
                        payNowModal: null
                    }, () => {
                        // console.log(this.state.paymnetOptions)

                    })
                    this.get();
                }
            })
        })
    }
    updatePaymentMethodFreeCard = (data) => {
        // console.log(this.state.payNowModal)
        // if (data && data.freeCardValidFrom && data.freeCardValidTo && this.state.payNowModal && this.state.payNowModal.startEventReservedDate && (new Date(data.freeCardValidFrom) > new Date(this.state.payNowModal.startEventReservedDate) || new Date(data.freeCardValidTo) < new Date(this.state.payNowModal.startEventReservedDate))) {
        if (data && data.freeCardValidFrom && data.freeCardValidTo && this.state.payNowModal && this.state.payNowModal.startEventReservedDate && (new Date(data.freeCardValidFrom) > new Date(this.props.getStringDateTs(this.state.payNowModal.startEventReservedDate, `${this.props.dateFormat} HH:mm`)/*moment.unix(this.state.newReferral.patientReservedTimestamp).format(`${`${this.props.dateFormat} HH:mm`} HH:mm`)*/) || new Date(data.freeCardValidTo) < new Date(this.props.getStringDateTs(this.state.payNowModal.startEventReservedDate, this.props.dateFormat)/*moment.unix(this.state.newReferral.patientReservedTimestamp).format(`${this.props.dateFormat} HH:mm`)*/))) {

            this.setState({
                freeCardError: true
            })
        } else {
            if (data && data.freeCardRegion) {
                let region = this.state.regions.filter(item => item._id == data.freeCardRegion);
                if (region && region[0]) {
                    data.freeCardRegionName = region[0].shortName;
                }
            }
            // alert(data)
            data.freeCard = true;
            this.setState({
                loading: true
            }, () => {
                fetch(API_ENDPOINT + '/working-hours/control-change-payment-method/' + data._id, {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify(data)
                }).then(res => res.json()).then((result) => {
                    this.setState({
                        paymnetOptionsControl: null,
                        payNowModal: null,
                        loading: false,
                        successModal: 'Remember to take your free card with you.'.translate(this.props.lang)
                    }, () => {
                        // console.log(this.state.paymnetOptions)
                        this.setState({ tabIndex: 2 })

                    })
                    this.get();


                })
            })
        }


    }
    openPayson = (data) => {
        this.setState({
            paysonModalControl: data,

        })
    }
    openStripe = (data) => {
        this.setState({
            stripeModalControl: false
        }, () => {
            this.setState({
                stripeModalControl: data,

            })
        })

    }
    openSwish = (data) => {
        this.setState({
            swishModalControl: data,

        }, () => {
            // console.log(this.state.swishModalControl)
        })
    }
    callbackAfterPaying = () => {
        let paidControls = [];
        if (this.state.paidControls && this.state.paidControls.length) {
            paidControls = this.state.paidControls;
        }
        if (this.state.referral && this.state.referral._id) {
            paidControls.push(this.state.referral._id)
            this.setState({ paidControls })
        }
        this.setState({ payNowModal: null, swishModalControl: null, swishModal: null, successModal: 'Payment successful.'.translate(this.props.lang) }, () => {

            this.get()
        })
    }

    rescheduleAppointment = (item) => {
        if (item.groupAlias) {
            fetch(API_ENDPOINT + '/data/without-token/professions/all/' + item.groupAlias, {
                method: 'POST',
                headers: {
                    'content-type': 'application/json'
                },
                body: JSON.stringify({
                    isDropIn: this.state.isDropIn,
                    uid: this.props.uData && this.props.uData._id ? this.props.uData._id : null
                })
            }).then(res => res.json()).then((result) => {
                if (result) {
                    let availableProfessions = [];
                    for (let i = 0; i < result.length; i++) {
                        availableProfessions.push({
                            name: this.props.lang == 'en' ? result[i].professionEn : result[i].professionSv,
                            value: result[i]._id
                        })
                    }

                    this.setState({ availableProfessions }, () => {
                        this.setState({ rescheduleAppointment: item, unRebookModal: false })
                    })
                }



            })
        }




    }

    backFromCalendar = () => {
        this.setState({
            rescheduleAppointment: null,
            selectedTime: null,
            availableProfessions: []
        });
    }
    checkPayment = (refId) => {
        if (refId) {
            fetch(API_ENDPOINT + '/referrals/' + refId, {
                method: 'GET',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                }
            }).then(res => res.json()).then((result) => {
                if (result) {
                    if (result.referralIsPaid) {
                        let selectedTime = `${result.patientReservedTimestamp ? this.props.getStringDateTs(result.patientReservedTimestamp, this.props.dateFormat)/*moment.unix(result.patientReservedTimestamp).format(this.props.dateFormat)*/ : null}${this.props.lang == 'se' ? ' kl. ' : ' '}${result.patientReservedTimestamp ? this.props.getStringDateTs(result.patientReservedTimestamp, 'HH:mm')/*moment.unix(result.patientReservedTimestamp).format('HH:mm')*/ : null}`;

                        let successText = `Thanks! We will remind you via notifications before your meeting that takes place`.translate(this.props.lang) + ` ${selectedTime}`;
                        if (!result?.onlineVisit) {
                            let arr = [];
                            if (result?.clinic?.name) { arr.push(result?.clinic?.name) }
                            if (result?.clinic?.street) { arr.push(result?.clinic?.street) }
                            if (result?.clinic?.zip) { arr.push(result?.clinic?.zip) }
                            if (result?.clinic?.city) { arr.push(result?.clinic?.city) }

                            if (!arr.length) { successText += '.'; }
                            else {
                                successText += `, ${arr.join(', ')}.`;
                            }
                            // successText += `, ${result?.clinic?.name}, ${result?.clinic?.street ? result.clinic.street : ''}, ${result?.clinic?.zip ? result.clinic.zip : ''} ${result?.clinic?.city ? result.clinic.city : ''}.`;
                        } else {
                            successText += '.'
                        }
                        this.setState({ successModal: successText })

                    } else {
                        this.setState({ payNowReferralModal: result })
                    }

                }
            })
        }

    }

    updatePaymentMethodReferralFreeCard = (data) => {

        if (data && data.freeCardValidFrom && data.freeCardValidTo && this.state.payNowReferralModal && this.state.payNowReferralModal.patientReservedTimestamp && (new Date(data.freeCardValidFrom) > new Date(this.props.getStringDateTs(this.state.payNowReferralModal.patientReservedTimestamp, `${this.props.dateFormat} HH:mm`)/*moment.unix(this.state.newReferral.patientReservedTimestamp).format(`${`${this.props.dateFormat} HH:mm`} HH:mm`)*/) || new Date(data.freeCardValidTo) < new Date(this.props.getStringDateTs(this.state.payNowReferralModal.patientReservedTimestamp, this.props.dateFormat)/*moment.unix(this.state.newReferral.patientReservedTimestamp).format(`${this.props.dateFormat} HH:mm`)*/))) {

            this.setState({
                freeCardError: true
            })
        } else {
            if (data && data.freeCardRegion) {
                let region = this.state.regions.filter(item => item._id == data.freeCardRegion);
                if (region && region[0]) {
                    data.freeCardRegionName = region[0].shortName;
                }
            }
            data.freeCard = true;
            let onlinePaymentMethod = this.state.onlinePaymentMethod;
            onlinePaymentMethod = 1
            // let referral = this.state.referral;
            // referral.price = 0

            this.setState({
                onlinePaymentMethod,
                // referral
                freeCardData: data
            }, () => {
                this.updateReferral('freeCard')
            })

            // this.updateReferral('freeCard')
        }


    }
    updateReferral = (type) => {
        let referral = this.state.payNowReferralModal;
        let alias = referral.groupAlias;
        let data = {
            freePayment: this.state.onlinePaymentMethod > 0 && this.state.onlinePaymentMethod < 7 ? this.state.onlinePaymentMethod : null,
            paymentMethod: this.state.onlinePaymentMethod == 7 && this.state.choosenPayment ? this.state.choosenPayment : null,
        }
        if (this.state.freeCardData) {
            data.freeCardData = this.state.freeCardData
        }

        if (this.state.payson) {
            data.payson = true
        }
        if (this.state.swish) {
            data.swish = true
        }
        if (this.state.stripe) {
            data.stripe = true
        }
        if (data.paymentMethod == 2) {
            data.requsetedInvoice = true;
        }

        this.setState({
            loadingPay: true
        }, () => {
            fetch(API_ENDPOINT + '/referrals/referrals/update/' + referral._id, {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify(data)
            }).then(res => res.json()).then((result) => {
                if (result) {
                    if (result.paymentMethod == 2 || result.paymentMethod == 1) {
                        this.setState({
                            payNowReferralModal: null
                        }, () => {
                            if (result.paymentMethod == 2) {
                                this.setState({
                                    successPayModal: "You have successfully scheduled a visit. Once the invoice is created, it will be delivered to your email address, and you will also be able to download it on the \"My payments\" page.".translate(this.props.lang),
                                    successLink: '/my-data/personal-referrals/my-payments'
                                })
                            } else if (result.paymentMethod == 1) {
                                this.setState({
                                    successPayModal: "You have successfully scheduled a visit. Payment must be made \"On site\" before the visit.".translate(this.props.lang),
                                    successLink: '/my-data/personal-referrals/my-payments'
                                })
                            } else {
                                this.props[0].history.push('/my-data/personal-referrals/referrals')
                            }


                        })
                    } else if (result.payson) {
                        this.setState({
                            paysonModal: referral,
                            payNowReferralModal: null
                        })
                    } else if (result.swish) {
                        this.setState({
                            swishModal: referral,
                            payNowReferralModal: null
                        })
                    } else if (result.stripe) {
                        this.setState({
                            stripeModal: false
                        }, () => {
                            this.setState({
                                stripeModal: referral,
                                // payNowReferralModal: null
                            })
                        })

                    } else if (result.freePayment == 1) {
                        fetch(API_ENDPOINT + '/referrals/generate-invoice/' + referral._id, {
                            method: 'POST',
                            headers: {
                                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                                'content-type': 'application/json'
                            },
                            body: JSON.stringify({ transaction: '', alias: alias })
                        }).then(res => res.json()).then((result) => {
                            this.setState({
                                payNowReferralModal: null
                            }, () => {
                                if (type && type == 'freeCard') {
                                    this.setState({
                                        successPayModal: "You have successfully scheduled a visit. Remember to take your free card with you.".translate(this.props.lang),
                                        successLink: '/my-data/personal-referrals/referrals'
                                    })
                                } else {
                                    this.props[0].history.push('/my-data/personal-referrals/referrals')
                                }

                            })
                        })

                    }

                }
                this.setState({
                    freeCardData: null
                })

            })
        })

    }
    unRebookBBCRCase = (item) => {
        if (item?._id) {
            let obj = {
                _id: item._id,
                requestNewAppointment: item.requestNewAppointment,
                noNewAppointment: item.noNewAppointment,
                reason: item.reason
            }

            fetch(API_ENDPOINT + '/referrals/un-rebook/case', {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify(obj)
            }).then(res => res.json()).then((result) => {
                if (result.error) {
                    this.setState({
                        error: result.error,
                    })
                } else {
                    this.setState({ unRebookBBCRModal: null })

                }

                this.get()
            })
        }



    }

    getSavedRevisitForms = (referralId, groupObj) => new Promise((resolve) => {
        if (!referralId || !groupObj || (!groupObj.id && !groupObj.alias)) return resolve(false);
        try {
            let url = `${API_ENDPOINT}/users/revisit-questionaries/saved/data?referralId=${referralId}`;
            if (groupObj.id) url += `&groupId=${groupObj.id}`;
            if (groupObj.alias) url += `&groupAlias=${groupObj.alias}`;

            this.setState({ loading: true, savedRevisitForms: [] }, async () => {
                const newState = {
                    loading: false
                };
                const response = await fetch(url, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage?.getItem?.('authToken')}`
                    }
                });
                if (!response || !response.ok) {
                    this.setState(newState);
                    return resolve(false);
                }

                const result = await response.json();
                if (!result || !result.savedForms) {
                    this.setState(newState);
                    return resolve(false);
                }

                newState.savedRevisitForms = result.savedForms;
                this.setState(newState);
                return resolve(true);
            });
        } catch (err) {
            console.log('--- getSavedRevisitForms err: ', err);
            return resolve(false);
        }
    });

    listForms = async (item) => {
        if (!item || !item.revisitFormsArray) return;
        let groupAlias;
        if (item.alias) {
            groupAlias = item.alias
        }
        if (
            !item.alias
            && item && item.recipientClinic
            && this.props.uData.patientGroups
            && this.props.uData.patientGroups.filter?.(group => group && group.clinics && group.clinics.filter?.(clinic => clinic == item?.recipientClinic).length)?.length
        ) {
            let group = this.props.uData.patientGroups.filter(group => group && group.clinics && group.clinics.filter(clinic => clinic == item.recipientClinic).length)[0];
            if (group && group.alias) {
                groupAlias = group.alias;
            }
        }

        await this.getSavedRevisitForms(item._id, { alias: groupAlias }); // TODO
        this.setState({
            formsToAnswer: item.revisitFormsArray
        });
    }

    // checkReferralState = () => {
    //     try {
    //         if (this.state.referral && this.state.referral._id && this.state.items && Array.isArray(this.state.items) && this.state.items.length > 0) {
    //             const currentReferral = this.state.items?.find?.(r => r?._id && String(r._id) === String(this.state.referral._id));
    //             if (currentReferral && currentReferral._id) {
    //                 if (JSON.stringify(this.state.referral) !== JSON.stringify(currentReferral)) {
    //                     console.log('UPDATING REFERRAL ...');
    //                     this.setState({ referral: currentReferral });
    //                 }
    //             }
    //         }
    //     } catch (err) {
    //         console.log("--- checkReferralState err: ", err);
    //     }

    // }


    render() {
        const statusColors = {
            'waiting-for-approval': 'orange',
            'waiting': 'orange',
            'waiting-for-payment': 'orange',
            'disallowed': 'red',
            'in-progress': 'green',
            'allowed': 'green',
            'return-for-update': 'blue',
            'patient-requested-another-time': 'purple',
            'patient-rejected': 'red',
            'patient-accepted': 'green',
            'clinic-accepted-requested-time': 'green',
            'clinic-rejected-requested-time': 'orange',
            'approved-by-region-manager': 'green',
            'rejected-by-region-manager': 'red',
            'canceled-by-clinic': 'red',
            'scheduled': 'green',
            'visit-rescheduled': 'green',
            'revisit-scheduled': 'green',
            'requested-new-appointment': 'orange',
            'external': 'gray',

        }

        const humanReadable = {
            'waiting-for-approval': 'Waiting for approval'.translate(this.props.lang),
            'waiting': 'Waiting'.translate(this.props.lang),
            'waiting-for-payment': 'Waiting for payment'.translate(this.props.lang),
            'disallowed': 'Disallowed'.translate(this.props.lang),
            'in-progress': 'In progress'.translate(this.props.lang),
            'allowed': 'Allowed'.translate(this.props.lang),
            'return-for-update': 'Return for update'.translate(this.props.lang),
            'patient-requested-another-time': 'Patient requested anoother time'.translate(this.props.lang),
            'patient-rejected': 'Rejected by patient'.translate(this.props.lang),
            'patient-accepted': 'Patient accepted'.translate(this.props.lang),
            'clinic-accepted-requested-time': 'Clinic accepted requested time'.translate(this.props.lang),
            'clinic-rejected-requested-time': 'Clinic rejected requested time'.translate(this.props.lang),
            'approved-by-region-manager': 'Approved by region manager'.translate(this.props.lang),
            'rejected-by-region-manager': 'Rejected by region manager'.translate(this.props.lang),
            'scheduled': 'Scheduled'.translate(this.props.lang),
            'visit-rescheduled': 'Visit rescheduled'.translate(this.props.lang),
            'revisit-scheduled': 'Revisit scheduled'.translate(this.props.lang),
            'not-booked': 'Not booked'.translate(this.props.lang),
            'closed': 'Closed'.translate(this.props.lang),
            'canceled-by-clinic': 'Canceled by clinic'.translate(this.props.lang),
            'requested-new-appointment': 'Requested new appointment'.translate(this.props.lang),
            'external': 'External transfer'.translate(this.props.lang),
        }

        let params = {};
        if (this.state.useState) {
            params = this.state.params;
        } else {
            params = getSearchParams(this.props[0].location.search, { entries: 10, page: 0 })
        }

        return (
            <div className={this.props.autoHideSidebar ? "dashboard dashboard-auto-hide patient-cases-wrap" : "dashboard patient-cases-wrap"}>


                <Container fluid>
                    <Row>
                        <Col lg="12">
                            <div className="panel">
                                <h5 className="component-header">{this.props.linksMeta && this.props.linksMeta[this.props[0].location.pathname] ? this.props.linksMeta[this.props[0].location.pathname][this.props.lang] : ''}</h5>

                                <ListBuilderMob
                                    lang={this.props.lang}
                                    loading={this.state.loading}
                                    total={this.state.total}
                                    rawItems={this.state.items}
                                    disableFilter={true}
                                    loadMoreItems={() => {
                                        this.updateParams('page', Number(params.page) + 1)
                                    }}
                                    params={params}
                                    fields={[
                                        {
                                            type: 'item-right-header', name: 'videoCallButton', condition: (item) => {
                                                if (item.videoCall && (item.originalStatus === 'scheduled' || item.originalStatus === 'visit-rescheduled' || item.originalStatus === 'revisit-scheduled' || item.originalStatus === 'in-progress' || item.originalStatus === 'waiting')) {
                                                    return true;
                                                } else {
                                                    return false;
                                                }
                                            }
                                        },

                                        { type: 'title', name: 'recpientClinicName' },
                                        { type: 'subtitle', name: 'recpientClinicSubgroupName' },
                                        { type: 'subtitle', name: 'doctorName' },
                                        { type: 'item', name: 'status', svg: calendarIcon },
                                        { type: 'item', name: 'questionaryName', svg: questionaryIcon },
                                        // { type: 'item', name: 'version', svg: caseIcon },
                                        {
                                            type: 'item', name: 'senderClinicData', svg: HospitalIcon, svgStyle: { width: 24, height: 24 }, condition: (item) => {
                                                if (!item.onlineVisit) {
                                                    return true;
                                                } else {
                                                    return false;
                                                }
                                            }
                                        },
                                        {
                                            type: 'item', name: 'onlineVisitData', svg: ChatIcon, svgStyle: { width: 24, height: 24 }, condition: (item) => {
                                                if (item.onlineVisit && !item.videoCall) {
                                                    return true;
                                                } else {
                                                    return false;
                                                }
                                            }
                                        },
                                        {
                                            type: 'item', name: 'videoCallCase', svg: VideoIcon, svgStyle: { width: 24, height: 24 }, condition: (item) => {
                                                if (item.videoCall) {
                                                    return true;
                                                } else {
                                                    return false;
                                                }
                                            }
                                        },
                                        { type: 'item-right', name: 'timestamp' },


                                    ]}
                                    filterFields={[
                                        { name: 'All'.translate(this.props.lang), onClick: () => this.setStatus(null), active: !this.state.status },
                                        { name: 'Scheduled'.translate(this.props.lang), onClick: () => this.setStatus('scheduled'), active: (this.state.status === 'scheduled' || this.state.status === 'visit-rescheduled') },
                                        { name: 'Waiting'.translate(this.props.lang), onClick: () => this.setStatus('waiting-for-approval'), active: this.state.status === 'waiting-for-approval' },
                                        { name: 'Drop in'.translate(this.props.lang), onClick: () => this.setStatus('_dropin'), active: this.state.status === '_dropin' },

                                    ]}
                                    items={this.state.items.map((item => {
                                        if (item.status === 'closed' && !item.dropIn && item.patientReservedTimestamp) {
                                            item.closedTimestamp = item.patientReservedTimestamp;
                                        }
                                        return {
                                            ...item,
                                            // videoCall: item.videoCall,
                                            videoCallButton: <>
                                                <div className='my-cases-waiting-room-button' onClick={() => {
                                                    this.props[0].history.push(`/waiting-room`)
                                                }}>
                                                    <div className='my-cases-waiting-room-button-content' >
                                                        <p className='my-cases-waiting-room-button-content-text' >{"Go to waiting room".translate(this.props.lang)}</p>

                                                        {/* <Isvg fill={'#568ac8'} className='my-cases-waiting-room-button-content-svg' src={WaitingRoom} /> */}
                                                        <WaitingRoom2 fill={'#568ac8'} className={'my-cases-waiting-room-button-content-svg'} width={30} height={30} title={'Waiting room'.translate(this.props.lang)} />

                                                    </div>

                                                </div>
                                            </>,
                                            // onlineVisit: item.onlineVisit,
                                            version: item.patient.referralNumber + (item.version != 0 ? '.' + item.version : ''),
                                            questionaryName: <div> {item.questionaryName ? this.props.lang == 'en' ? item.questionaryName?.replace?.(' (onlinebesök)', ' (online visit)') : item.questionaryName?.replace?.(' (online visit)', ' (onlinebesök)') : ''}<br />
                                                <span style={item && item.revisitForms && item.revisitFormsArray && item.revisitFormsArray.filter(forms => forms.finishedForm).length != item.revisitForms.length ? { color: 'red' } : { color: 'green' }}>{item && item.revisitForms && item.revisitFormsArray ? 'Forms:'.translate(this.props.lang) + ' ' + item.revisitFormsArray.filter(forms => forms.finishedForm).length + '/' + item.revisitForms.length : ''} </span></div>,

                                            senderClinicData: <>{"Physical visit -".translate(this.props.lang)} <span style={{ padding: 5 }}>{item.clinicAddress}</span></>,
                                            onlineVisitData: <>{"Digital visit - Chat".translate(this.props.lang)}</>,
                                            videoCallCase: <>{"Digital visit - Video meeting".translate(this.props.lang)}</>,
                                            // recpientClinicName: <>{item.recpientClinicName}<br /><span className="clinic-subgroup-name">{item.recpientClinicSubgroupName} </span><br /> <span className="clinic-subgroup-name">{item.doctorName}</span>  </>,
                                            originalStatus: item.status,
                                            status: <div className="status-text">
                                                <span style={{ color: statusColors[item.status] }}>{humanReadable[item.status]} </span><br />

                                                {item.status !== 'waiting-for-approval' && item.status !== 'waiting-for-payment' && item.status !== 'waiting' ?
                                                    <span>(
                                                        {item.status == 'disallowed' ?
                                                            // moment.unix(item.disallowedTimestamp).format(`${this.props.dateFormat} HH:mm`)
                                                            this.props.getStringDateTs(item.disallowedTimestamp, `${this.props.dateFormat} HH:mm`)
                                                            :
                                                            item.status == 'return-for-update' ?
                                                                // moment.unix(item.returnTimestamp).format(`${this.props.dateFormat} HH:mm`)
                                                                this.props.getStringDateTs(item.returnTimestamp, `${this.props.dateFormat} HH:mm`)
                                                                :
                                                                item.status == 'allowed' ?
                                                                    // moment.unix(item.allowedTimestamp).format(`${this.props.dateFormat} HH:mm`)
                                                                    this.props.getStringDateTs(item.allowedTimestamp, `${this.props.dateFormat} HH:mm`)
                                                                    :
                                                                    item.status == 'patient-requested-another-time' ?
                                                                        // moment.unix(item.patientRequestTimeTimestamp).format(`${this.props.dateFormat} HH:mm`)
                                                                        this.props.getStringDateTs(item.patientRequestTimeTimestamp, `${this.props.dateFormat} HH:mm`)
                                                                        :
                                                                        item.status == 'patient-rejected' ?
                                                                            // moment.unix(item.patientRejectedTimestamp).format(`${this.props.dateFormat} HH:mm`)
                                                                            this.props.getStringDateTs(item.patientRejectedTimestamp, `${this.props.dateFormat} HH:mm`)
                                                                            :
                                                                            item.status == 'patient-accepted' ?
                                                                                // moment.unix(item.patientAcceptedTimestamp).format(`${this.props.dateFormat} HH:mm`)
                                                                                this.props.getStringDateTs(item.patientAcceptedTimestamp, `${this.props.dateFormat} HH:mm`)
                                                                                :
                                                                                item.status == 'patient-accepted' ?
                                                                                    // moment.unix(item.patientAcceptedTimestamp).format(`${this.props.dateFormat} HH:mm`)
                                                                                    this.props.getStringDateTs(item.patientAcceptedTimestamp, `${this.props.dateFormat} HH:mm`)
                                                                                    :
                                                                                    item.status == 'clinic-accepted-requested-time' ?
                                                                                        // moment.unix(item.clinicAcceptedTimestamp).format(`${this.props.dateFormat} HH:mm`)
                                                                                        this.props.getStringDateTs(item.clinicAcceptedTimestamp, `${this.props.dateFormat} HH:mm`)
                                                                                        :
                                                                                        item.status == 'clinic-rejected-requested-time' ?
                                                                                            // moment.unix(item.clinicRejectedTimestamp).format(`${this.props.dateFormat} HH:mm`)
                                                                                            this.props.getStringDateTs(item.clinicRejectedTimestamp, `${this.props.dateFormat} HH:mm`)
                                                                                            :
                                                                                            item.status == 'approved-by-region-manager' ?
                                                                                                // moment.unix(item.approvedByRegionManagerTimestemp).format(`${this.props.dateFormat} HH:mm`)
                                                                                                this.props.getStringDateTs(item.approvedByRegionManagerTimestemp, `${this.props.dateFormat} HH:mm`)
                                                                                                :
                                                                                                item.status == 'canceled-by-region-manager' ?
                                                                                                    // moment.unix(item.canceledByRegionManagerTimestemp).format(`${this.props.dateFormat} HH:mm`)
                                                                                                    this.props.getStringDateTs(item.canceledByRegionManagerTimestemp, `${this.props.dateFormat} HH:mm`)
                                                                                                    :
                                                                                                    item.status == 'scheduled' ?
                                                                                                        // moment.unix(item.patientReservedTimestamp).format(`${this.props.dateFormat} HH:mm`)
                                                                                                        this.props.getStringDateTs(item.patientReservedTimestamp, `${this.props.dateFormat} HH:mm`)
                                                                                                        :
                                                                                                        item.status == 'visit-rescheduled' ?
                                                                                                            // moment.unix(item.patientReservedTimestamp).format(`${this.props.dateFormat} HH:mm`)
                                                                                                            this.props.getStringDateTs(item.patientReservedTimestamp, `${this.props.dateFormat} HH:mm`)
                                                                                                            :
                                                                                                            item.status == 'revisit-scheduled' ?
                                                                                                                // moment.unix(item.patientReservedTimestamp).format(`${this.props.dateFormat}${!item.hideTimeFromThePatient ? ' HH:mm' : ''}`)
                                                                                                                this.props.getStringDateTs(item.patientReservedTimestamp, `${this.props.dateFormat}${!item.hideTimeFromThePatient ? ' HH:mm' : ''}`)
                                                                                                                :
                                                                                                                item.status == 'not-booked' ?
                                                                                                                    // moment.unix(item.notBookedTimestamp).format(`${this.props.dateFormat} HH:mm`)
                                                                                                                    this.props.getStringDateTs(item.notBookedTimestamp, `${this.props.dateFormat} HH:mm`)
                                                                                                                    :
                                                                                                                    item.status == 'closed' ?
                                                                                                                        // moment.unix(item.closedTimestamp).format(`${this.props.dateFormat} HH:mm`)
                                                                                                                        this.props.getStringDateTs(item.closedTimestamp, `${this.props.dateFormat} HH:mm`)
                                                                                                                        :
                                                                                                                        item.status == 'in-progress' ?
                                                                                                                            // moment.unix(item.inProgressTimestamp).format(`${this.props.dateFormat} HH:mm`)
                                                                                                                            this.props.getStringDateTs(item.inProgressTimestamp, `${this.props.dateFormat} HH:mm`)
                                                                                                                            :
                                                                                                                            item.status == 'canceled-by-clinic' ?
                                                                                                                                // moment.unix(item.canceledByClinicTimestamp).format(`${this.props.dateFormat} HH:mm`)
                                                                                                                                this.props.getStringDateTs(item.canceledByClinicTimestamp, `${this.props.dateFormat} HH:mm`)
                                                                                                                                :
                                                                                                                                item.status == 'canceled-by-clinic' ?
                                                                                                                                    // moment.unix(item.canceledByClinicTimestamp).format(`${this.props.dateFormat} HH:mm`)
                                                                                                                                    this.props.getStringDateTs(item.canceledByClinicTimestamp, `${this.props.dateFormat} HH:mm`)
                                                                                                                                    :
                                                                                                                                    item.status == 'requested-new-appointment' ?
                                                                                                                                        // moment.unix(item.patientRequestedNewAppointment).format(`${this.props.dateFormat} HH:mm`) :
                                                                                                                                        this.props.getStringDateTs(item.patientRequestedNewAppointment, `${this.props.dateFormat} HH:mm`)
                                                                                                                                        :
                                                                                                                                        null
                                                        }) <br />
                                                        <span>{"Timezone: Stockholm".translate(this.props.lang)}</span>
                                                    </span> : null}

                                            </div>,

                                            timestamp: <div className='tscreated-div'><p>{'Referral date'.translate(this.props.lang)}</p><span>{this.props.getStringDateTs(item.timestamp, `${this.props.dateFormat} HH:mm`)/*moment.unix(item.timestamp).format(`${this.props.dateFormat} HH:mm`)*/}</span></div>,


                                        }
                                    }))}
                                    actions={
                                        [
                                            {

                                                component: <div className='list-builder-button-mob'>{'Read more'.translate(this.props.lang)}</div>,
                                                buttonClassName: 'blue-button-list-builder-mob',
                                                onClick: (item) => {

                                                    this.setState({ referral: item }, () => {
                                                        this.setState({ refresh: new Date().getTime() })
                                                    })

                                                }

                                            },
                                            {

                                                component: <div className='list-builder-button-mob'>{'Chat'.translate(this.props.lang)}</div>,
                                                buttonClassName: 'blue-button-list-builder-mob',
                                                buttonIndicator: (item) => {
                                                    if (item.conversation && item.conversation._id && !item.conversation.seen) {
                                                        return true;
                                                    } else {
                                                        return false;
                                                    }
                                                },
                                                onClick: (item) => {
                                                    if (item && item.conversation && item.conversation._id) {
                                                        this.props[0].history.push(`/chat?conversation=${item.conversation._id}`)
                                                    }

                                                },
                                                condition: (item) => {
                                                    if (item.conversation && item.conversation._id) {
                                                        return true;
                                                    } else {
                                                        return false;
                                                    }
                                                }

                                            },
                                            {

                                                component: <div className='list-builder-button-mob'>{'Un/rebook'.translate(this.props.lang)}</div>,
                                                buttonClassName: 'blue-button-list-builder-mob',
                                                onClick: (item) => {
                                                    if ((item.status === 'scheduled' || item.status === 'visit-rescheduled') && item.wayOfScheduling === 'webBooking') {
                                                        this.setState({ unRebookModal: item })
                                                    } else {
                                                        this.setState({ unRebookBBCRModal: { ...item, requestNewAppointment: false, noNewAppointment: false, reason: '' } })
                                                    }
                                                },
                                                condition: (item) => {
                                                    if ((((item.originalStatus === 'scheduled' || item.originalStatus === 'visit-rescheduled') && item.wayOfScheduling === 'webBooking') || ((item.originalStatus === 'scheduled' || item.originalStatus === 'visit-rescheduled') && item.wayOfScheduling === 'bookingByClinic') || (item.originalStatus === 'revisit-scheduled' || item.originalStatus === 'visit-rescheduled')) && item.patientReservedTimestamp - 15 * 60 > new Date().getTime() / 1000) {
                                                        return true;
                                                    } else {
                                                        return false;
                                                    }
                                                }

                                            },
                                            // {

                                            //     component: <div className='list-builder-button-mob'>{'Go to waiting room'.translate(this.props.lang)}</div>,
                                            //     buttonClassName: 'blue-button-list-builder-mob',
                                            //     onClick: () => {
                                            //         this.props[0].history.push(`/waiting-room`)
                                            //     },
                                            //     condition: (item) => {
                                            //         if (item.videoCall && (item.originalStatus === 'scheduled' || item.originalStatus === 'visit-rescheduled' || item.originalStatus === 'revisit-scheduled' || item.originalStatus === 'in-progress' || item.originalStatus === 'waiting')) {
                                            //             return true;
                                            //         } else {
                                            //             return false;
                                            //         }
                                            //     }

                                            // }

                                        ]
                                    }
                                    updateMultipleParams={this.updateMultipleParams}
                                />



                            </div>
                        </Col>
                    </Row>

                </Container>
                {
                    this.state.unRebookBBCRModal ?
                        <Modal isOpen={this.state.unRebookBBCRModal} centered >
                            <ModalHeader>
                                <h5>{'Un/rebook'.translate(this.props.lang)}</h5>
                            </ModalHeader>
                            <ModalBody className='un-rebook-case-modal-body-wrap'>
                                <div className='radio-button-field-wrap' onClick={() => {
                                    let unRebookBBCRModal = this.state.unRebookBBCRModal;
                                    unRebookBBCRModal.requestNewAppointment = true;
                                    unRebookBBCRModal.noNewAppointment = false;
                                    unRebookBBCRModal.reason = '';
                                    this.setState({ unRebookBBCRModal })
                                }}>
                                    <div className="radio-button-field-round" >{this.state.unRebookBBCRModal.requestNewAppointment ? <div className='radio-button-field-round-checked'></div> : null}</div>
                                    <h6>{"Request new appointment".translate(this.props.lang)}</h6>
                                </div>

                                <div className='radio-button-field-wrap' onClick={() => {
                                    let unRebookBBCRModal = this.state.unRebookBBCRModal;
                                    unRebookBBCRModal.requestNewAppointment = false;
                                    unRebookBBCRModal.noNewAppointment = true;
                                    unRebookBBCRModal.reason = '';
                                    this.setState({ unRebookBBCRModal })
                                }}>
                                    <div className="radio-button-field-round" >{this.state.unRebookBBCRModal.noNewAppointment ? <div className='radio-button-field-round-checked'></div> : null}</div>
                                    <h6>{"No new appointment".translate(this.props.lang)}</h6>
                                </div>
                                {
                                    this.state.unRebookBBCRModal.requestNewAppointment || this.state.unRebookBBCRModal.noNewAppointment ?
                                        <FormGroup className='reason-wrap'>
                                            <Label>{this.state.unRebookBBCRModal.requestNewAppointment ? 'What time would best suit you?'.translate(this.props.lang) : "State reason:".translate(this.props.lang)}</Label>
                                            <Input type='textarea' value={this.state.unRebookBBCRModal.reason} onChange={(e) => {
                                                let unRebookBBCRModal = this.state.unRebookBBCRModal;
                                                unRebookBBCRModal.reason = e.target.value;
                                                this.setState({ unRebookBBCRModal })
                                            }} />
                                        </FormGroup>
                                        :
                                        null
                                }


                            </ModalBody>

                            <ModalFooter className="buttons-right-blue column-buttons">
                                <Button
                                    color="primary"
                                    style={{ margin: 10 }}
                                    disabled={(this.state.unRebookBBCRModal.requestNewAppointment || this.state.unRebookBBCRModal.noNewAppointment) && this.state.unRebookBBCRModal.reason?.trim() ? false : true}
                                    onClick={() => {
                                        let item = { ...this.state.unRebookBBCRModal }
                                        this.unRebookBBCRCase(item)
                                    }}
                                >
                                    {'Submit'.translate(this.props.lang)}
                                </Button>

                                <Button color="primary" style={{ margin: 10 }} onClick={() => this.setState({ unRebookBBCRModal: null })}>{'Cancel'.translate(this.props.lang)}</Button>
                            </ModalFooter>
                        </Modal>
                        : null
                }

                {
                    this.state.unRebookModal ?
                        <Modal isOpen={this.state.unRebookModal} centered >

                            <ModalFooter className="buttons-right-blue column-buttons">
                                {
                                    this.state.unRebookModal.activeWebBooking ?
                                        <Button color="primary" style={{ margin: 10 }} onClick={() => {
                                            let item = { ...this.state.unRebookModal }
                                            this.rescheduleAppointment(item)

                                        }}>{'Reschedule appointment'.translate(this.props.lang)}</Button>
                                        :
                                        null
                                }

                                <Button color="primary" style={{ margin: 10 }} onClick={() => {
                                    let item = { ...this.state.unRebookModal }
                                    this.setState({ rejectWebBooking: item, unRebookModal: false })
                                }}>{'Cancel appointment'.translate(this.props.lang)}</Button>
                                <Button color="primary" style={{ margin: 10 }} onClick={() => this.setState({ unRebookModal: false })}>{'Cancel'.translate(this.props.lang)}</Button>
                            </ModalFooter>
                        </Modal>
                        : null
                }
                {
                    this.state.rejectWebBooking ?
                        <FormBuilder selectedGroup={this.props.selectedGroup} selectedClinic={this.props.selectedClinic} countryTwoLetter={this.props.countryTwoLetter} country={this.props.country} dateFormat={this.props.dateFormat}
                            getStringDateTs={this.props.getStringDateTs}
                            lang={this.props.lang}
                            onSubmit={(data) => this.rejectWebBookingReferral(data)}
                            isOpen={this.state.rejectWebBooking}
                            toggle={() => this.setState({ rejectWebBooking: null })}
                            title={'Cancel appointment'.translate(this.props.lang)}
                            buttonText={'Confirm cancellation'.translate(this.props.lang)}
                            additionalButton={<Button color="primary" onClick={() => this.setState({ rejectWebBooking: !this.state.rejectWebBooking })}>{'Close'.translate(this.props.lang)}</Button>}
                            modalFooterClassname={'buttons-right-blue'}
                            fields={[
                                {
                                    type: 'row',
                                    children: [
                                        {
                                            type: 'col',
                                            width: {
                                                lg: 12,
                                                sm: 12,
                                                xs: 12
                                            },
                                            children: [
                                                {
                                                    type: 'textarea',
                                                    name: 'reason',
                                                    height: 100,
                                                    label: 'Reason for cancellation'.translate(this.props.lang),
                                                    validate: [required('Reason for cancellation is required!'.translate(this.props.lang))]
                                                },
                                            ]
                                        },

                                    ]
                                }
                            ]}
                        ></FormBuilder>
                        :
                        null
                }

                {
                    this.state.rescheduleAppointment ?
                        <Modal isOpen={this.state.rescheduleAppointment} centered size="xxl" className="calendar-modal">
                            <ModalHeader>

                                <ModalHeader toggle={() => this.setState({ rescheduleAppointment: !this.state.rescheduleAppointment })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ rescheduleAppointment: !this.state.rescheduleAppointment })}><Isvg src={xIcon} /></button>}>{'Reschedule appointment'.translate(this.props.lang)}</ModalHeader>

                            </ModalHeader>
                            <ModalBody>
                                <RescheduleWebBookingCalendar
                                    {...this.props}
                                    referral={this.state.rescheduleAppointment}
                                    closeCalendarModal={(item) => this.setState({ rescheduleAppointment: item })}
                                    personalQuestionary={this.state.rescheduleAppointment.questionaryData}
                                    selectedReferral={this.state.rescheduleAppointment.questionaryData}
                                    backFromCalendar={() => this.backFromCalendar()}
                                    setSelectedTime={(selectedTime) => this.setState({ selectedTime })}
                                    price={this.state.rescheduleAppointment.questionaryData?.price}
                                    availableProfessions={this.state.availableProfessions}
                                    changeSelectedTime={this.state.rescheduleAppointment._id}
                                    displayPaymentInfoText={this.state.rescheduleAppointment.displayPaymentInfoText ? true : false}
                                    // createdReferral={(item) => this.setState({ createdReferral: item }, () => this.sendReferral())}
                                    createdReferral={(item) => {
                                        this.checkPayment(item)

                                        this.setState({ rescheduleAppointment: null }, () => {
                                            this.get()
                                        })
                                    }}
                                />
                                {/* <CalendarView
                                    {...this.props}
                                    uData={this.props.uData}
                                    handleDeleteCheck={(id) => {
                                        this.referralDeleteInterval(id);
                                    }}
                                    createdReferral={(item) => this.setState({ createdReferral: item }, () => this.getReferral())}
                                    closeCalendarModal={(item) => this.setState({ calendarModal: item })}
                                    lang={this.props.lang}
                                    refId={(id) => this.setState({ checkRefId: id })}
                                    openPayWithCard={this.state.onlinePaymentMethod == 7 && this.state.choosenPayment == 3 ? true : false}
                                    price={this.state.referral.price}
                                    personalQuestionary={this.state.referral}
                                    availableProfessions={this.state.availableProfessions}
                                    selectedReferral={this.state.referral}
                                    backFromCalendar={() => this.backFromCalendar()}
                                    setSelectedTime={(selectedTime) => this.setState({ selectedTime })}
                                /> */}

                            </ModalBody>

                        </Modal>

                        :
                        null
                }


                {
                    this.state.referral ?
                        <ReferralContent
                            {...this.props}
                            dateFormat={this.props.dateFormat}
                            lang={this.props.lang}
                            getStringDateTs={this.props.getStringDateTs}
                            data={this.state.referral}
                            uData={this.props.uData}
                            enterprise={true}
                            paidControls={this.state.paidControls}
                            screen={'patient-cases'}
                            doctorPage={false}
                            rejectForm={(referral) => {
                                this.setState({ rejectForm: referral })
                            }}
                            previewAnswersRevisit={(form) => {
                                this.setState({ previewAnswersModal: form }, () => {
                                    console.log(this.state.previewAnswersModal, this.state.referral)
                                })
                            }}
                            payForTheVisit={() => {
                                if (this.state.referral) {
                                    this.setState({ payNowModal: this.state.referral.bill && this.state.referral.bill.caseObj })

                                }
                            }}
                            formsToAnswer={this.listForms}
                            sendEntireReferralOnCheckForms={true}
                            closeReferralContent={(data) => {
                                if (data) {
                                    this.setState({
                                        referral: null
                                    })
                                }
                            }}
                            hideReject={this.state.referral?.wayOfScheduling === 'webBooking' || this.state.referral?.wayOfScheduling === 'bookingByClinic' || this.state.referral?.status === 'revisit-scheduled' || this.state.referral?.status === 'visit-rescheduled' ? true : false}
                        />
                        :
                        null
                }

                {this.state.freeCardError ?
                    <InfoModal
                        isOpen={this.state.freeCardError}
                        onClose={(item) => {
                            this.setState({
                                freeCardError: item
                            })
                        }}
                        close={true}
                        header={'Free card validation error'.translate(this.props.lang)}
                        info={'Your free card will not be valid on the day of the scheduled visit.'.translate(this.props.lang)}
                        buttons={[
                            <Button color="primary" style={{ margin: 10 }} onClick={() => this.setState({ freeCardError: false })}>{'Cancel'.translate(this.props.lang)}</Button>


                        ]}
                    />
                    : null}
                {this.state.error ?

                    <ErrorModal lang={this.props.lang}
                        isOpen={this.state.error}
                        toggle={() => this.setState({ error: null })}

                    >
                        {this.state.error.translate(this.props.lang)}
                    </ErrorModal>

                    :
                    null
                }

                {
                    this.state.payNowReferralModal ?
                        <PayNowModal lang={this.props.lang}
                            isOpen={this.state.payNowReferralModal.questionaryData}
                            toggle={() => {
                                this.setState({
                                    successPayModal: "Payment failed, please make sure to pay before your scheduled visit.".translate(this.props.lang),
                                    payNowReferralModal: false,
                                })
                            }}
                            regions={this.state.regions}
                            dropIn={false}
                            groupPaymentData={this.state.payNowReferralModal.groupPaymentData}
                            updatePaymentMethod={(method) => {
                                let choosenPayment = this.state.choosenPayment;
                                choosenPayment = method.paymentMethod;
                                this.setState({
                                    choosenPayment,
                                    onlinePaymentMethod: 7,

                                }, () => {
                                    this.updateReferral()
                                })
                            }}
                            updatePaymentMethodFreeCard={(data) => this.updatePaymentMethodReferralFreeCard(data)}
                            openPayson={(data) => {
                                let choosenPayment = this.state.choosenPayment;
                                choosenPayment = 3;
                                this.setState({
                                    choosenPayment,
                                    onlinePaymentMethod: 7,
                                    payson: data,
                                    swish: null,
                                    stripe: null
                                }, () => {
                                    this.updateReferral()
                                })

                            }}
                            openSwish={(data) => {
                                let choosenPayment = this.state.choosenPayment;
                                choosenPayment = 3;
                                this.setState({
                                    choosenPayment,
                                    onlinePaymentMethod: 7,
                                    swish: data,
                                    payson: null,
                                    stripe: null
                                }, () => {
                                    this.updateReferral()
                                })

                            }}
                            openStripe={(data) => {
                                let choosenPayment = this.state.choosenPayment;
                                choosenPayment = 3;
                                this.setState({
                                    choosenPayment,
                                    onlinePaymentMethod: 7,
                                    stripe: data,
                                    payson: null,
                                    swish: null
                                }, () => {
                                    this.updateReferral()
                                })

                            }}
                        >
                        </PayNowModal>
                        :
                        null
                }
                {this.state.paysonModal ?

                    <Payson
                        lang={this.props.lang}
                        openModal={(item) => this.setState({
                            paysonModal: item
                        }, () =>
                            // this.setState({
                            //     successModal: true
                            // })
                            this.setState({
                                successPayModal: "You have successfully scheduled a visit. Remember to make the payment before the scheduled time.".translate(this.props.lang),
                                successLink: '/my-data/personal-referrals/my-payments'
                            })
                        )}
                        referral={this.state.paysonModal._id}
                        price={this.state.paysonModal.questionaryPrice}
                        patient={this.props.uData._id}
                        successMessageNeeded={true}

                    />

                    : null}
                {this.state.stripeModal ?

                    <Stripe
                        {...this.props}
                        lang={this.props.lang}
                        openModal={(item) => this.setState({
                            stripeModal: item
                        }, () =>
                            // this.setState({
                            //     successModal: true
                            // })
                            this.setState({
                                successPayModal: "You have successfully scheduled a visit. Remember to make the payment before the scheduled time.".translate(this.props.lang),
                                successLink: '/my-data/personal-referrals/my-payments'
                            })
                        )}
                        referral={this.state.stripeModal._id}
                        price={this.state.stripeModal.questionaryPrice}
                        patient={this.props.uData._id}
                        successMessageNeeded={true}

                    />

                    : null}
                {this.state.swishModal ?

                    <Swish
                        getStringDateInverseTs={this.props.getStringDateInverseTs}
                        clearStatusBefore={() => this.props.clearStatusBefore()}
                        verifyUser={() => this.props.verifyUser(null, null, true)}
                        lang={this.props.lang}
                        history={this.props[0]}
                        open={this.state.swishModal}
                        phone={this.props.uData.phone}
                        price={this.state.swishModal.questionaryPrice}
                        referralNumber={this.state.swishModal.patient && this.state.swishModal.patient.referralNumber}
                        referral={this.state.swishModal._id}
                        openModal={(item) => this.setState({
                            swishModal: item
                        }, () =>
                            this.setState({
                                successPayModal: "You have successfully scheduled a visit. Remember to make the payment before the scheduled time.".translate(this.props.lang),
                                successLink: '/my-data/personal-referrals/my-payments'
                            }))}
                        callbackFunction={() =>
                            this.setState({
                                successPayModal: "Payment successful.".translate(this.props.lang),
                                successLink: '/my-data/personal-referrals/referrals'
                            })}
                    />

                    : null}

                {
                    this.state.payNowModal ?

                        <PayNowModal lang={this.props.lang}
                            isOpen={this.state.payNowModal}
                            toggle={() => this.setState({ payNowModal: null, successModal: "Payment failed, please make sure to pay before your scheduled visit.".translate(this.props.lang) })}
                            regions={this.state.regions}
                            updatePaymentMethod={(method) => this.updatePaymentControl(method)}
                            updatePaymentMethodFreeCard={(data) => this.updatePaymentMethodFreeCard(data)}
                            openPayson={(data) => this.openPayson(data)}
                            openSwish={(data) => this.openSwish(data)}
                            openStripe={(data) => this.openStripe(data)}
                        >
                        </PayNowModal>

                        : null
                }
                {this.state.formsToAnswer ?
                    <Modal isOpen={this.state.formsToAnswer} centered size='lg'>
                        <ModalHeader toggle={() => this.setState({ formsToAnswer: null, savedRevisitForms: [] })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }}
                            onClick={() => this.setState({
                                formsToAnswer: null,
                                savedRevisitForms: [],
                                // eventID: null,
                                selectedEventAnswers: null
                            })}>&times;</button>}>{'Forms to answer'.translate(this.props.lang)}</ModalHeader>

                        <ModalBody>

                            <FormGroup>
                                {this.state.formsToAnswer && this.state.formsToAnswer.length ?
                                    <ListBuilder
                                        lang={this.props.lang}
                                        loading={this.state.loading}
                                        total={this.state.total}
                                        showNumeration={false}
                                        hideFilters={true}
                                        hidePagination
                                        hideEntries
                                        actionLabel={'Action'.translate(this.props.lang)}
                                        fields={
                                            [
                                                { type: 'text', name: 'name', label: 'Name'.translate(this.props.lang), allowSort: false },
                                            ]
                                        }
                                        rawItems={this.state.formsToAnswer}
                                        items={this.state.formsToAnswer.map((item, idx) => {
                                            return {
                                                ...item,
                                            }
                                        })}

                                        actions={
                                            [
                                                {
                                                    component: <Button color="primary" className="tabel-action-tooltip">{'Continue'.translate(this.props.lang)}</Button>,
                                                    onClick: (item) => {
                                                        if (!item) return;

                                                        let _data = {};

                                                        const savedForm = this.state.savedRevisitForms?.find?.(obj => obj && obj.revisitQuestionaryId && String(obj.revisitQuestionaryId) === String(item.revisitQuestionaryId));

                                                        if (savedForm && savedForm.state) {
                                                            let states = JSON.parse(JSON.stringify(savedForm.state))
                                                            let data = {}
                                                            if (states.skippedQuestions) {
                                                                data.skippedQuestions = states.skippedQuestions;
                                                                delete states.skippedQuestions
                                                            }
                                                            if (states.answers) {
                                                                data.answers = states.answers;
                                                                delete states.answers
                                                            }
                                                            if (states.nextButtonClicked) {
                                                                data.nextButtonClicked = states.nextButtonClicked;
                                                                delete states.nextButtonClicked
                                                            }
                                                            if (states.questionsQueue) {
                                                                data.questionsQueue = states.questionsQueue;
                                                                delete states.questionsQueue
                                                            }
                                                            if (states.currentQuestion) {
                                                                data.currentQuestion = states.currentQuestion;
                                                                delete states.currentQuestion
                                                            }
                                                            if (states.backAnswers) {
                                                                data.backAnswers = states.backAnswers;
                                                                delete states.backAnswers
                                                            }
                                                            _data = data;
                                                            this.setState({ resumeForm: savedForm._id })
                                                        }

                                                        let groupAlias;
                                                        if (item.alias) {
                                                            groupAlias = item.alias
                                                        }
                                                        if (
                                                            !item.alias
                                                            && this.state.referral && this.state.referral.recipientClinic
                                                            && this.props.uData.patientGroups
                                                            && this.props.uData.patientGroups.filter(group => group && group.clinics && group.clinics.filter(clinic => clinic == this.state.referral.recipientClinic).length).length
                                                        ) {
                                                            let group = this.props.uData.patientGroups.filter(group => group && group.clinics && group.clinics.filter(clinic => clinic == this.state.referral.recipientClinic).length)[0];
                                                            if (group && group.alias) {
                                                                groupAlias = group.alias;
                                                            }
                                                        }

                                                        this.setState({
                                                            answerRevisitForm: { ...item, groupAlias: groupAlias, referralId: this.state.referral?._id, _data: _data && typeof _data === 'object' && Object.keys(_data)?.length > 0 ? _data : null }
                                                        });

                                                    },
                                                    condition: (form) => {
                                                        if (!form.answers && !form.finishedForm) {
                                                            if (form.revisitQuestionaryId && this.state.savedRevisitForms?.find?.(obj => obj && obj.revisitQuestionaryId && String(obj.revisitQuestionaryId) === String(form.revisitQuestionaryId))) {
                                                                const savedForm = this.state.savedRevisitForms?.find?.(obj => obj && obj.revisitQuestionaryId && String(obj.revisitQuestionaryId) === String(form.revisitQuestionaryId));
                                                                if (!savedForm || savedForm.questionaryNotAcitve || savedForm.questionaryNotExists || savedForm.questionaryModified) {
                                                                    return false
                                                                }
                                                                return true;
                                                            }
                                                            return false;
                                                        } else {
                                                            return false
                                                        }
                                                    }
                                                },

                                                {
                                                    component: <Button color="primary" className="tabel-action-tooltip">{'Answer'.translate(this.props.lang)}</Button>,
                                                    onClick: (item) => {
                                                        let groupAlias;
                                                        if (item.alias) {
                                                            groupAlias = item.alias
                                                        }
                                                        if (
                                                            !item.alias
                                                            && this.state.referral && this.state.referral.recipientClinic
                                                            && this.props.uData.patientGroups
                                                            && this.props.uData.patientGroups.filter(group => group && group.clinics && group.clinics.filter(clinic => clinic == this.state.referral.recipientClinic).length).length
                                                        ) {
                                                            let group = this.props.uData.patientGroups.filter(group => group && group.clinics && group.clinics.filter(clinic => clinic == this.state.referral.recipientClinic).length)[0];
                                                            if (group && group.alias) {
                                                                groupAlias = group.alias;
                                                            }
                                                        }
                                                        this.setState({
                                                            answerRevisitForm: { ...item, groupAlias: groupAlias, referralId: this.state.referral?._id }
                                                        })
                                                    },
                                                    condition: (form) => {
                                                        if (!form.answers && !form.finishedForm) {
                                                            return true
                                                        } else {
                                                            return false
                                                        }
                                                    }
                                                },
                                                {
                                                    component: <Button style={{ backgroundColor: 'green' }} className="tabel-action-tooltip">{"See my answers".translate(this.props.lang)}</Button>,
                                                    onClick: (item) => {
                                                        let groupAlias;
                                                        if (item.alias) {
                                                            groupAlias = item.alias
                                                        }
                                                        if (
                                                            !item.alias
                                                            && this.state.referral && this.state.referral.recipientClinic
                                                            && this.props.uData.patientGroups
                                                            && this.props.uData.patientGroups.filter(group => group && group.clinics && group.clinics.filter(clinic => clinic == this.state.referral.recipientClinic).length).length
                                                        ) {
                                                            let group = this.props.uData.patientGroups.filter(group => group && group.clinics && group.clinics.filter(clinic => clinic == this.state.referral.recipientClinic).length)[0];
                                                            if (group && group.alias) {
                                                                groupAlias = group.alias;
                                                            }
                                                        }
                                                        this.setState({
                                                            answerRevisitForm: { ...item, disabledForm: true, groupAlias: groupAlias }
                                                        }, () => {
                                                            if (item.answers) {
                                                                this.setState({
                                                                    questionaryAnswers: { ...item.answers }
                                                                })
                                                            }

                                                        })
                                                    },
                                                    condition: (form) => {
                                                        if (!form.answers && !form.finishedForm) {
                                                            return false
                                                        } else {
                                                            return true
                                                        }
                                                    }
                                                },
                                            ]
                                        }
                                        params={params}
                                        sortField={params.sortField}
                                        sortType={params.sortType}
                                        updateSort={this.updateSort}
                                        updateParams={this.updateParams}
                                        updateMultipleParams={this.updateMultipleParams}
                                    ></ListBuilder>
                                    :
                                    <strong>{"No forms to answer on selected day".translate(this.props.lang)}</strong>
                                }
                            </FormGroup>

                        </ModalBody>
                        <ModalFooter>
                            <Button color='primary' onClick={() => this.setState({
                                formsToAnswer: null,
                                savedRevisitForms: [],
                                // eventID: null,
                                selectedEventAnswers: null
                            })}>{'Close'.translate(this.props.lang)}</Button>
                        </ModalFooter>
                    </Modal>
                    : null}
                {
                    this.state.answerRevisitForm ?
                        <Modal isOpen={this.state.answerRevisitForm} centered size={'lg'}>
                            <ModalHeader close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }}
                                onClick={() => {
                                    if (this.state.answerRevisitForm && this.state.answerRevisitForm.answers) {
                                        if (JSON.stringify(this.state.answerRevisitForm.answers) != JSON.stringify(this.state.questionaryAnswers)) {
                                            this.setState({
                                                errorFormModal: 'Your data will not be saved.'
                                            })
                                        } else {
                                            this.setState({
                                                questionaryAnswers: {},
                                                answerRevisitForm: null,
                                                resumeForm: null,
                                                referralData: null,
                                                referralTotalData: null
                                            })
                                        }

                                    } else {
                                        this.setState({
                                            errorFormModal: 'Your data will not be saved.'
                                        })
                                    }


                                }}><Isvg src={xIcon} /></button>}>
                                <p>{this.state.answerRevisitForm.name}</p>
                                {/* <p style={{ fontSize: 16, fontWeight: 500 }}>{this.state.answerRevisitForm.description}</p> */}
                            </ModalHeader>

                            <ModalBody className="run-modal">
                                <AnswerRevisitForm
                                    {...this.props}
                                    _savedRevisitForm={this.state.answerRevisitForm?._data || null}
                                    sections={this.props.generateSections(this.state.answerRevisitForm.items)}
                                    intelligentReferral={this.state.answerRevisitForm.intelligentReferral}
                                    saveForm={this.state.answerRevisitForm.saveForm && !this.state.answerRevisitForm.finishedForm && !this.state.answerRevisitForm.disabledForm}
                                    form={this.state.answerRevisitForm}
                                    closeForm={() => {
                                        this.setState({
                                            questionaryAnswers: {},
                                            answerRevisitForm: null,
                                            resumeForm: null,
                                            referralData: null,
                                            referralTotalData: null,
                                            formsToAnswer: null,
                                            savedRevisitForms: [],
                                        })
                                    }}
                                    setResumeForm={(id, callback) => {
                                        this.setState({ resumeForm: id }, () => {
                                            if (callback) { callback() }
                                        })
                                    }}
                                    alias={this.state.answerRevisitForm.groupAlias ? this.state.answerRevisitForm.groupAlias : null}
                                    referralId={this.state.answerRevisitForm.referralId ? this.state.answerRevisitForm.referralId : null}
                                    answer={(item, callback) => {
                                        this.setState({ questionaryAnswers: item }, () => {
                                            if (callback) {
                                                callback()
                                            }
                                        })
                                    }}
                                    generateAnswers={(html, callback) => this.setState({ referralData: html }, () => {

                                        if (callback) {
                                            callback()
                                        }
                                    })}
                                    disabledForm={this.state.answerRevisitForm.disabledForm}
                                    answers={this.state.questionaryAnswers}
                                    saveAnswers={() => {

                                        if (this.state.answerRevisitForm && this.state.answerRevisitForm.revisitQuestionaryId && this.state.referral && this.state.referral._id && this.state.questionaryAnswers) {
                                            fetch(API_ENDPOINT + '/referrals/patient/answer/revisit/questionary', {
                                                method: 'POST',
                                                headers: {
                                                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                                                    'content-type': 'application/json'
                                                },
                                                body: JSON.stringify({
                                                    revisitQuestionaryId: this.state.answerRevisitForm.revisitQuestionaryId,
                                                    answers: this.state.questionaryAnswers,
                                                    referralId: this.state.referral._id,
                                                    referralData: this.state.referralData,
                                                    resumeForm: this.state.resumeForm
                                                    // referralTotalData: this.state.referralTotalData
                                                })
                                            }).then(parseJSON).then(({ result, status }) => {
                                                if (status >= 200 && status < 300) {
                                                    this.setState({
                                                        questionaryAnswers: {},
                                                        answerRevisitForm: null,
                                                        resumeForm: null,
                                                        referralData: null,
                                                        // referralTotalData: null,
                                                        formsToAnswer: null,
                                                        savedRevisitForms: []
                                                    }, () => { this.get(); })
                                                }

                                            })
                                        }

                                    }}
                                />

                            </ModalBody>
                        </Modal>
                        :
                        null
                }

                {
                    this.state.swishModalControl ?
                        <Swish
                            getStringDateInverseTs={this.props.getStringDateInverseTs}
                            clearStatusBefore={() => this.props.clearStatusBefore()}
                            verifyUser={() => this.props.verifyUser()}
                            lang={this.props.lang}
                            history={this.props[0]}
                            open={this.state.swishModalControl}
                            openModal={(item) => {
                                // console.log(this.props.referralStatusBefore)
                                console.log(item)
                                this.setState({
                                    swishModalControl: item,
                                    successModal: "Payment failed, please make sure to pay before your scheduled visit.".translate(this.props.lang),
                                    payNowModal: null

                                }, () => {
                                    if (this.props.referralStatusBefore && this.props.referralStatusBefore.refData && this.props.referralStatusBefore.refData.doctor) {
                                        // this.deleteReferral(this.props.referralStatusBefore.referral);
                                        this.props.clearStatusBefore();
                                    }
                                })

                            }}
                            price={this.state.swishModalControl.refData && this.state.swishModalControl.price ? this.state.swishModalControl.price : this.state.swishModalControl.refData && this.state.swishModalControl.refData.questionaryPrice ? this.state.swishModalControl.refData.questionaryPrice : this.state.swishModalControl.questionaryPrice}
                            phone={this.props.uData.phone}
                            referralNumber={this.state.swishModalControl.controlNumber ? this.state.swishModalControl.controlNumber : null}
                            referral={this.state.swishModalControl._id}
                            doctor={this.state.swishModalControl.doctor ? this.state.swishModalControl.doctor : null}
                            patient={this.props.uData._id}
                            callbackFunction={() => this.callbackAfterPaying()}
                        // data={this.state.swishModalControl.refData && this.state.swishModalControl.refData.data ? this.state.swishModalControl.refData.data : null}

                        />


                        : null
                }
                {
                    this.state.paysonModalControl ?

                        <Payson
                            lang={this.props.lang}
                            openModal={(item) => {
                                this.setState({
                                    paysonModalControl: item,
                                    successModal: "Payment failed, please make sure to pay before your scheduled visit.".translate(this.props.lang),
                                    payNowModal: null
                                }, () => {
                                    if (this.props.referralStatusBefore && this.props.referralStatusBefore.refData && this.props.referralStatusBefore.refData.doctor) {
                                        this.props.clearStatusBefore();
                                    }
                                })

                            }}
                            price={this.state.paysonModalControl.refData && this.state.paysonModalControl.price ? this.state.paysonModalControl.price : this.state.paysonModalControl.refData && this.state.paysonModalControl.refData.questionaryPrice ? this.state.paysonModalControl.refData.questionaryPrice : this.state.paysonModalControl.questionaryPrice}

                            referral={this.state.paysonModalControl._id}
                            doctor={this.state.paysonModalControl.doctor ? this.state.paysonModalControl.doctor : null}
                            patient={this.props.uData._id}
                            reddirectToPaid={true}
                            successMessageNeeded={true}
                            fromMyCases={true}
                            referralPreviewObj={{
                                referralId: this.state.referral ? this.state.referral._id : null,
                                page: params.page
                            }}
                        />


                        : null
                }
                {
                    this.state.stripeModalControl ?

                        <Stripe
                            {...this.props}
                            lang={this.props.lang}
                            openModal={(item) => {
                                this.setState({
                                    stripeModalControl: item,
                                    successModal: "Payment failed, please make sure to pay before your scheduled visit.".translate(this.props.lang),
                                    payNowModal: null
                                }, () => {
                                    if (this.props.referralStatusBefore && this.props.referralStatusBefore.refData && this.props.referralStatusBefore.refData.doctor) {
                                        this.props.clearStatusBefore();
                                    }
                                })

                            }}
                            price={this.state.stripeModalControl.refData && this.state.stripeModalControl.price ? this.state.stripeModalControl.price : this.state.stripeModalControl.refData && this.state.stripeModalControl.refData.questionaryPrice ? this.state.stripeModalControl.refData.questionaryPrice : this.state.stripeModalControl.questionaryPrice}

                            referral={this.state.stripeModalControl._id}
                            doctor={this.state.stripeModalControl.doctor ? this.state.stripeModalControl.doctor : null}
                            patient={this.props.uData._id}
                            reddirectToPaid={true}
                            successMessageNeeded={true}
                            fromMyCases={true}
                            referralPreviewObj={{
                                referralId: this.state.referral ? this.state.referral._id : null,
                                page: params.page
                            }}
                        />


                        : null
                }
                {
                    this.state.errorFormModal ?
                        <Modal isOpen={this.state.errorFormModal} centered>
                            <ModalHeader style={{ margin: 'auto' }}>{'Error'.translate(this.props.lang)}</ModalHeader>
                            <ModalBody className="delete-modal">


                                <img src={warrning} />
                                <div className="message">
                                    {
                                        this.state.errorFormModal.translate(this.props.lang)
                                    }
                                </div>
                            </ModalBody>
                            <ModalFooter style={{ display: 'flex', justifyContent: 'center' }}>

                                <Button color="primary" onClick={() => this.setState({
                                    questionaryAnswers: {},
                                    answerRevisitForm: null,
                                    resumeForm: null,
                                    referralData: null,
                                    errorFormModal: null,
                                    referralTotalData: null,
                                    formsToAnswer: null,
                                    savedRevisitForms: [],
                                    // selectedEventAnswers: null,
                                })} >{'Close'.translate(this.props.lang)}</Button>

                                <Button style={{ marginRight: 5 }} color="primary" onClick={() => this.setState({
                                    errorFormModal: null,
                                })} >{'Back'.translate(this.props.lang)}</Button>
                            </ModalFooter>
                        </Modal>
                        :
                        null
                }
                {this.state.successModal ?
                    <Modal isOpen={this.state.successModal} centered zIndex={99999}>
                        <ModalHeader style={{ margin: 'auto' }}>{'Notification'.translate(this.props.lang)}</ModalHeader>
                        <ModalBody className="delete-modal">
                            {/* <div className="check-icon"> */}
                            <Isvg src={info} />
                            <p className='message'>
                                {this.state.successModal}
                            </p>
                            {/* </div> */}
                        </ModalBody>
                        <ModalFooter>
                            <Button color="primary" style={{ margin: 'auto' }} onClick={() => this.setState({ successModal: null })}>{'Ok'.translate(this.props.lang)}</Button>
                        </ModalFooter>
                    </Modal>
                    : null}
                {this.state.successPayModal ?
                    <Modal isOpen={this.state.successPayModal} centered>
                        {/* <ModalHeader style={{ margin: 'auto' }}>{'Notification'.translate(this.props.lang)}</ModalHeader> */}
                        <ModalBody className="delete-modal">
                            {/* <div className="check-icon"> */}
                            {/* <Isvg src={info} /> */}
                            <p className='message'>
                                {
                                    this.state.successPayModal == 'Free Card payment successful.' ?
                                        'Free Card payment successful.'.translate(this.props.lang)
                                        :
                                        this.state.successPayModal == 'free' ?
                                            'Based on your age, you are entitled to a free visit.'.translate(this.props.lang)
                                            :
                                            this.state.successPayModal.translate(this.props.lang)
                                    // 'Your referral has been sent.'.translate(this.props.lang)
                                }
                            </p>
                            {/* </div> */}
                        </ModalBody>
                        <ModalFooter>
                            <Button color="primary" style={{ margin: 'auto', minWidth: 100 }} onClick={() => this.setState({ successPayModal: null }, () => {
                                if (this.state.successLink) {
                                    this.props[0].history.push(this.state.successLink)
                                } else {
                                    this.props[0].history.push('/my-data/personal-referrals/referrals')
                                }

                            })}>{'Ok'.translate(this.props.lang)}</Button>
                        </ModalFooter>
                    </Modal>
                    : null}

                {
                    this.state.rejectForm ?
                        <FormBuilder selectedGroup={this.props.selectedGroup} selectedClinic={this.props.selectedClinic} countryTwoLetter={this.props.countryTwoLetter} country={this.props.country} dateFormat={this.props.dateFormat}
                            getStringDateTs={this.props.getStringDateTs}
                            lang={this.props.lang}
                            onSubmit={(data) => this.rejectReferral(data)}
                            isOpen={this.state.rejectForm}
                            toggle={() => this.setState({ rejectForm: null })}
                            title={'Reject shceduled time'.translate(this.props.lang)}
                            buttonText={'Submit'.translate(this.props.lang)}
                            fields={[
                                {
                                    type: 'row',
                                    children: [
                                        {
                                            type: 'col',
                                            width: {
                                                lg: 12,
                                                sm: 12,
                                                xs: 12
                                            },
                                            children: [
                                                {
                                                    type: 'textarea',
                                                    name: 'reason',
                                                    label: 'Reject reason'.translate(this.props.lang),
                                                    validate: [required('Reject reason is required!'.translate(this.props.lang))]
                                                },
                                            ]
                                        },

                                    ]
                                }
                            ]}
                        ></FormBuilder>
                        :
                        null
                }

                {
                    this.state.requestAnotherDateForm ?
                        <FormBuilder selectedGroup={this.props.selectedGroup} selectedClinic={this.props.selectedClinic} countryTwoLetter={this.props.countryTwoLetter} country={this.props.country} dateFormat={this.props.dateFormat}
                            getStringDateTs={this.props.getStringDateTs}
                            lang={this.props.lang}
                            onSubmit={(data) => this.requestAnotherDate(data)}
                            isOpen={this.state.requestAnotherDateForm}
                            toggle={() => this.setState({ requestAnotherDateForm: null })}
                            title={'Request another date'.translate(this.props.lang)}
                            buttonText={'Submit'.translate(this.props.lang)}
                            fields={[
                                {
                                    type: 'row',
                                    children: [
                                        {
                                            type: 'col',
                                            width: {
                                                lg: 6,
                                                sm: 12,
                                                xs: 12
                                            },
                                            children: [
                                                {
                                                    type: 'date',
                                                    name: 'date',
                                                    label: 'Date'.translate(this.props.lang),
                                                    validate: [required('Date is required!'.translate(this.props.lang))]
                                                },
                                            ]
                                        },
                                        {
                                            type: 'col',
                                            width: {
                                                lg: 6,
                                                sm: 12,
                                                xs: 12
                                            },
                                            children: [
                                                {
                                                    type: 'time',
                                                    name: 'time',
                                                    label: 'Time'.translate(this.props.lang),
                                                    validate: [required('Time is required!'.translate(this.props.lang))]
                                                },
                                            ]
                                        },
                                    ]
                                }
                            ]}
                        ></FormBuilder>
                        :
                        null
                }

                {
                    this.state.selectClinicModal ?
                        <Modal isOpen={this.state.selectClinicModal} centered toggle={() => this.setState({ selectClinicModal: !this.state.selectClinicModal })} size="xl">
                            <ModalHeader>
                                <h4>{'Search Curoflow database'.translate(this.props.lang)}</h4>
                            </ModalHeader>
                            <ModalBody>
                                <ReferralClinics {...this.props} patientData={this.state.patientData} searchFor={this.state.referral.articleName} includeTags={this.state.tags} importClinic={(clinic) => {

                                    this.forward(clinic);

                                }}></ReferralClinics>
                            </ModalBody>
                        </Modal>
                        :
                        null
                }
                {
                    this.state.previewAnswersReferralModal ?
                        <Modal isOpen={this.state.previewAnswersReferralModal} centered toggle={() => this.setState({ previewAnswersReferralModal: !this.state.previewAnswersReferralModal })} size="lg">
                            <ModalHeader toggle={() => this.setState({ previewAnswersReferralModal: !this.state.previewAnswersReferralModal })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ previewAnswersReferralModal: !this.state.previewAnswersReferralModal })}><Isvg src={xIcon} /></button>}>{'Form preview'.translate(this.props.lang)}</ModalHeader>

                            <ModalBody>

                                <FormPreview {...this.props} referral={{ name: this.state.previewAnswersReferralModal.name, description: this.state.previewAnswersReferralModal.description, intelligentReferral: this.state.previewAnswersReferralModal.intelligentReferral, items: this.state.previewAnswersReferralModal.questionaryData?.items }} sections={this.props.generateSections(this.state.previewAnswersReferralModal.questionaryData.items)} answers={this.state.previewAnswersReferralModal.answers} disabledForm={true} hidePreview={true}></FormPreview>

                            </ModalBody>
                        </Modal>
                        :
                        null
                }
                {
                    this.state.previewAnswersModal ?
                        <Modal isOpen={this.state.previewAnswersModal} centered toggle={() => this.setState({ previewAnswersModal: !this.state.previewAnswersModal })} size="lg">
                            <ModalHeader toggle={() => this.setState({ previewAnswersModal: !this.state.previewAnswersModal })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ previewAnswersModal: !this.state.previewAnswersModal })}><Isvg src={xIcon} /></button>}>{'Form preview'.translate(this.props.lang)}</ModalHeader>

                            <ModalBody>

                                <FormPreview {...this.props} referral={{ name: this.state.previewAnswersModal.name, description: this.state.previewAnswersModal.description, intelligentReferral: this.state.previewAnswersModal.intelligentReferral, items: this.state.previewAnswersModal.items }} sections={this.props.generateSections(this.state.previewAnswersModal.items)} answers={this.state.previewAnswersModal.answers} disabledForm={true} hidePreview={true} hideTotalAndSubtotal={true}></FormPreview>

                            </ModalBody>
                        </Modal>
                        :
                        null
                }




            </div >
        );


    }
}
export default PatientCasesMob;