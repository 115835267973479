import React from 'react';
import { jsPDF } from "jspdf";

import {
    Button
} from 'reactstrap';

import Isvg from 'react-inlinesvg';
import printModal from '../assets/svg/print-modal.svg'
import Save from '../assets/svg/save.svg';

/**
* Open print page of browser, generate Save PDF button
* @author   Milan Stanojevic
*/
class PrintHelper extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    componentDidMount() {

    }

    render() {
        return (
            <div style={{ width: '100%' }}>


                <Button color='primary' style={{ width: '100%' }} onClick={() => {
                    const doc = new jsPDF('p', 'mm', [210, 297]);
                    doc.html(`<div style="width:1100px;padding: 100px 50px;">${this.props.html}</div>`, {
                        margin: [20, 20, 20, 20],

                        html2canvas: {
                            scale: 0.1909
                        },

                        callback: () => {
                            this.setState({
                                blob: doc.output('bloburl'),
                                docObj: doc
                            }, () => {
                                this.state.docObj.save(this.props.pdfName);

                            })
                        },

                    })

                }}>
                    {/* <Isvg src={Save} style={{ marginRight: 5 }} /> */}
                    {'Save as pdf'.translate(this.props.lang)}
                </Button>


                <iframe width="0" height="0" style={{ border: 0 }} src={this.state.blob} ref={(node) => this.iframe = node} onLoad={() => this.setState({ _loaded: true })}></iframe>

            </div>
        )
    }
}

export default PrintHelper;