import React, { Component } from 'react';
import Page from '../../containers/page';

import {
    Container,
    Row,
    Col,
    Modal, ModalHeader, ModalBody, Input, FormGroup, Label
} from 'reactstrap';

import Isvg from 'react-inlinesvg'

import FormBuilder from '../../components/forms/modalFormBuilder';
import { required } from '../../components/forms/validation';

import ListBuilder from '../../components/listBuilder';
import DeleteModal from '../../components/deleteModal';
import { getSearchParams, generateSearchLink, generateSearchLinkMultiple } from '../../helpers/linkHelper';
import { API_ENDPOINT } from '../../constants';
import ErrorModal from '../../components/errorModal';
import moment from 'moment';

import Search from '../../components/search';
import addIcon from '../../assets/svg/add-icon.svg';
import view from '../../assets/svg/eye.svg'

import profileUser from '../../assets/images/user.png';
import notification from '../../assets/svg/notification.svg'
import moreIcon from '../../assets/svg/more.svg'
import duplicateIcon from '../../assets/svg/duplicate-icon.svg';


import DatePicker from '../../components/forms/fields/datePicker';
import Checkbox from '../../components/forms/fields/checkbox';


function parseJSON(response) {
    return response.json().then(json => {
        return {
            result: json,
            status: response.status
        }
    })
}

class WebDocCronLogs extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props.initialData,
            form: false,
            useState: false,
            params: {
                entries: 10,
                page: 0,
                sortField: 'timestamp',
                sortType: -1
            },
            items: [],
            clinicGroups: [],
            startDateSearch: null,
            endDataSearch: null,
            total: 0,
            loading: true,
            selectedItems: {},
        };
    }
    componentWillUnmount() {
        if (this.checkFinishedInterval) {
            clearTimeout(this.checkFinishedInterval)
        }
    }

    get = () => {
        this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));

        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, {
                entries: 10, page: 0, sortField: 'timestamp', sortType: -1
            })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

        for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
            this.props.loadDataWithQuery[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, {
                entries: 10, page: 0, sortField: 'tsCreated', group: this.state.selectedClinicGroup, sortType: -1, onlyErrors: this.state.showOnlyErrors
            })).then((data) => {
                let dataToSet = { ...data }
                if (dataToSet.page || dataToSet.items || dataToSet.total) {
                    dataToSet.loading = null;
                    // console.log(dataToSet.page || dataToSet.items || dataToSet.total)
                    let checkFilter = '';
                    if (dataToSet.resultFilter) {
                        checkFilter = String(dataToSet.resultFilter)
                        delete dataToSet.resultFilter;
                    }
                    let search = this.props[0].location.search;
                    let serachParams = getSearchParams(search);
                    let searchParamsFilter = '';
                    if (serachParams.filter) {
                        searchParamsFilter = serachParams.filter;
                    }
                    if (searchParamsFilter != checkFilter) {
                        delete dataToSet.page;
                        delete dataToSet.items;
                        delete dataToSet.total;
                        delete dataToSet.loading;
                    }
                }
                this.setState({
                    ...dataToSet,
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));

                    if (this.state.items?.length && !this.state.items[0].finished) {
                        this.checkFinishedInterval = setTimeout(() => {
                            this.get()
                        }, 1000 * 10);
                    } else {
                        if (this.checkFinishedInterval) {
                            clearTimeout(this.checkFinishedInterval)
                        }
                    }
                })
            })
        }

    }
    getAllGroups = () => {
        fetch(API_ENDPOINT + '/data/clinic-groups/all', {
            method: 'POST',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
            body: JSON.stringify({})
        }).then(parseJSON).then(({ result, status }) => {
            if (status >= 200 && status < 300)
                this.setState({ clinicGroups: result })
        })

    }

    componentDidMount() {
        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }

        this.get();
        this.getAllGroups()
    }

    componentDidUpdate(prevProps) {
        if (prevProps[0].location.search != this.props[0].location.search) {
            this.setState({
                loading: true
            }, () => {
                this.get();

            })
        }
    }


    updateSort = (field, type) => {
        if (this.state.useState) {
            let state = this.state.params;
            state.sortField = field;
            state.sortType = type;
            this.setState({
                params: state
            })
        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0 }, [{ name: 'sortField', value: field }, { name: 'sortType', value: type }], false));

        }
    }

    updateParams = (name, value, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0,
                    sortField: 'timestamp',
                    sortType: -1
                };
                obj[name] = value;
                this.setState({ params: obj });
            } else {
                let obj = this.state.params;
                obj[name] = value;
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(generateSearchLink(this.props[0].location.search, { entries: 10, page: 0 }, name, value, restart));
        }
    }
    updateMultipleParams = (fields, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0,
                    sortField: 'timestamp',
                    sortType: -1
                };
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj });

            } else {
                let obj = this.state.params;
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0 }, fields, restart));
        }
    }

    getLog = (id) => {

        if (id) {
            fetch(API_ENDPOINT + `/data/webdoc-cron-logs/${id}`, {
                method: 'GET',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                }
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300 && result?.log)
                    this.setState({ previewLog: result.log, selectedItems: {} })
            })
        }


    }




    render() {
        let params = {};
        if (this.state.useState) {
            params = this.state.params;
        } else {
            params = getSearchParams(this.props[0].location.search, { entries: 10, page: 0 })
        }

        const previewLog = this.state.previewLog;
        return (
            <div className={this.props.autoHideSidebar ? "dashboard dashboard-auto-hide" : "dashboard"}>

                <Container fluid>
                    <Row>
                        <Col lg="12">
                            <div className="panel">
                                <div className="panel-header">
                                    <h5 className="component-header">{this.props.linksMeta && this.props.linksMeta[this.props[0].location.pathname] ? this.props.linksMeta[this.props[0].location.pathname][this.props.lang] : ''}</h5>
                                    <FormGroup>
                                        <Checkbox
                                            checked={this.state.showOnlyErrors}
                                            onChange={(e) => {
                                                this.setState({ showOnlyErrors: !this.state.showOnlyErrors }, () => {
                                                    this.get()
                                                })
                                            }}
                                            label={'Show only errors'.translate(this.props.lang)} />
                                    </FormGroup>
                                </div>


                                {/* <div className="logs-clinic-group-filter-wrap">
                                    <FormGroup style={{ marginBottom: 0 }}>
                                        <Label>{'Clinic group'.translate(this.props.lang)}</Label>
                                        <Input type="select" className="clinic-group-select" value={this.state.selectedClinicGroup} onChange={(e) => {
                                            if (e.target.value != -1) {
                                                this.setState({ selectedClinicGroup: e.target.value }, this.get)
                                            } else {
                                                this.setState({ selectedClinicGroup: null }, this.get)
                                            }
                                        }}>
                                            <option value={-1}>{'Select clinic group'.translate(this.props.lang)}</option>
                                            {
                                                this.state.clinicGroups && this.state.clinicGroups.map((item, idx) => {
                                                    return (
                                                        <option value={item._id}>{item.name}</option>
                                                    )
                                                })
                                            }
                                        </Input>
                                    </FormGroup>

                                </div> */}

                                <ListBuilder
                                    lang={this.props.lang}
                                    loading={this.state.loading}
                                    total={this.state.total}
                                    showNumeration={false}
                                    fields={[
                                        { type: 'text', name: 'tsCreated', label: 'Cron started'.translate(this.props.lang), allowSort: true },
                                        { type: 'text', name: 'duration', label: 'Duration'.translate(this.props.lang), allowSort: true },
                                        { type: 'text', name: 'finished', label: 'Finished'.translate(this.props.lang), allowSort: true },
                                        // { type: 'text', name: 'error', label: 'Error'.translate(this.props.lang), allowSort: true },


                                    ]}
                                    rawItems={this.state.items}
                                    items={this.state.items.map(item => {
                                        return {
                                            ...item,
                                            tsCreated: this.props.lang === 'en' ? item?.tsCreatedDateEn : item?.tsCreatedDateSv,
                                            duration: item?.durationString,
                                            finished: item?.finished ? <div className='list-item-finished'>{'Yes'.translate(this.props.lang)}</div> : <div className='list-item-not-finished'>{'No'.translate(this.props.lang)}</div>,
                                            ___className: item?.error?.name || item?.error?.message ? 'logs-highlighted-error-row' : undefined,
                                            // error: item?.error?.name || item?.error?.message ? <div className='list-item-not-finished'>{`${item?.error?.name ? `${item?.error?.name}: ` : ''}${item?.error?.message}`}</div> : <div className='list-item-finished'>{'No errors!'.translate(this.props.lang)}</div>
                                        }
                                    })}
                                    actions={
                                        [
                                            {
                                                component: <Isvg src={view} className="view-icon" />,
                                                onClick: (item) => {
                                                    if (item?._id) {
                                                        this.getLog(item._id)
                                                    }
                                                }
                                            },
                                        ]
                                    }
                                    params={params}
                                    sortField={params.sortField}
                                    sortType={params.sortType}
                                    updateSort={this.updateSort}
                                    updateParams={this.updateParams}
                                    updateMultipleParams={this.updateMultipleParams}
                                ></ListBuilder>
                            </div>
                        </Col>
                    </Row>

                </Container>
                {
                    previewLog ?
                        <Modal isOpen={previewLog} centered toggle={() => this.setState({ previewLog: !previewLog })} size={'lg'} className="clinic-modal">
                            <ModalHeader toggle={() => this.setState({ previewLog: !previewLog })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ previewLog: !previewLog })}>&times;</button>}>{'Preview log'.translate(this.props.lang)}</ModalHeader>

                            <ModalBody>
                                <Row>
                                    <Col lg="6">

                                        {
                                            previewLog.startTs ?
                                                <p><strong>{'Cron start time:'.translate(this.props.lang)}</strong> {this.props.getStringDateTs(Math.floor(previewLog.startTs / 1000), `${this.props.dateFormat} HH:mm:ss`)}</p>
                                                :
                                                null
                                        }
                                        {
                                            previewLog.endTs ?
                                                <p><strong>{'Cron end time:'.translate(this.props.lang)}</strong> {this.props.getStringDateTs(Math.floor(previewLog.endTs / 1000), `${this.props.dateFormat} HH:mm:ss`)}</p>
                                                :
                                                null
                                        }
                                        {
                                            previewLog.durationString ?
                                                <p><strong>{'Cron duration:'.translate(this.props.lang)}</strong> {previewLog.durationString}</p>
                                                :
                                                null
                                        }
                                        <p><strong>{'Cron finished:'.translate(this.props.lang)}</strong> {previewLog.finished ? 'Yes' : 'No'}</p>



                                    </Col>
                                    <Col lg="6">

                                        {
                                            previewLog?.error ?
                                                <div className='error-log-wrap'>
                                                    <h5>{'Error'.translate(this.props.lang)}</h5>
                                                    <h6>{previewLog?.error?.name}</h6>
                                                    <h6>{previewLog?.error?.message}</h6>
                                                </div>
                                                :
                                                null
                                        }

                                    </Col>
                                    <Col lg="12">
                                        <hr />
                                    </Col>

                                    <Col lg="12">
                                        {
                                            previewLog.logs?.length ?
                                                <ul className="webdoc-log-view" >
                                                    {previewLog.logs.map((item, idx) => {
                                                        return (
                                                            <li key={idx} className={this.state.selectedItems[idx] ? 'active-webdoc-log' : 'not-active-webdoc-log'}>
                                                                <div className={`head emit`} onClick={() => {
                                                                    let selectedItems = this.state.selectedItems;
                                                                    if (selectedItems?.[idx]) {
                                                                        selectedItems[idx] = false;
                                                                        for (var key in selectedItems) {
                                                                            if (selectedItems[key] && key.startsWith(`${idx}_`)) {
                                                                                selectedItems[key] = false;
                                                                            }
                                                                        }
                                                                    } else {
                                                                        selectedItems[idx] = true
                                                                    }
                                                                    this.setState({
                                                                        selectedItems
                                                                    })
                                                                }}>
                                                                    <span className="event-name">{item.name}</span>
                                                                </div>
                                                                {
                                                                    this.state.selectedItems[idx] ?
                                                                        <div className="content" >
                                                                            <ul className="webdoc-log-view" >
                                                                                {
                                                                                    item?.logs?.map((doctor, dIdx) => {
                                                                                        const error = doctor?.error ?? null;
                                                                                        return (
                                                                                            <li key={`${idx}_${dIdx}`} className={this.state.selectedItems[`${idx}_${dIdx}`] ? 'active-webdoc-log' : 'not-active-webdoc-log'}>
                                                                                                <div className={`head ${error ? 'error' : 'emit'}`} onClick={() => {
                                                                                                    let selectedItems = this.state.selectedItems;
                                                                                                    selectedItems[`${idx}_${dIdx}`] = !selectedItems[`${idx}_${dIdx}`];
                                                                                                    this.setState({
                                                                                                        selectedItems
                                                                                                    })
                                                                                                }}>
                                                                                                    <span className="event-name">{doctor.doctor}</span>
                                                                                                    {doctor?.duration ? <span className="peers"> {doctor.duration}</span> : null}
                                                                                                </div>
                                                                                                {
                                                                                                    this.state.selectedItems[`${idx}_${dIdx}`] ?
                                                                                                        <div className={`content ${error ? 'error-content' : 'not-error-content'}`} >
                                                                                                            {
                                                                                                                !error ?
                                                                                                                    <div className='copy-wrap'>
                                                                                                                        <div className="tabel-action-tooltip" onClick={() => {
                                                                                                                            let content = JSON.stringify(doctor.data, null, 4);
                                                                                                                            if (typeof window !== 'undefined' && content) {
                                                                                                                                navigator.clipboard.writeText(content)
                                                                                                                            }
                                                                                                                            //
                                                                                                                            if (content) {
                                                                                                                                this.setState({ copiedToClipboard: `${idx}_${dIdx}` }, () => {
                                                                                                                                    if (this.copyToClipboard) {
                                                                                                                                        clearTimeout(this.copyToClipboard)
                                                                                                                                    }
                                                                                                                                    this.copyToClipboard = setTimeout(() => {
                                                                                                                                        this.setState({ copiedToClipboard: false })
                                                                                                                                    }, 2000);
                                                                                                                                })
                                                                                                                            }
                                                                                                                        }}>
                                                                                                                            <Isvg src={duplicateIcon} className={this.state.copiedToClipboard === `${idx}_${dIdx}` ? "duplicate-icon-active" : "duplicate-icon"} />
                                                                                                                            {
                                                                                                                                this.state.copiedToClipboard === `${idx}_${dIdx}` ?
                                                                                                                                    <div style={{ visibility: 'visible' }} className="chat-icons-tooltip">{'Copied successfully!'.translate(this.props.lang)}</div>
                                                                                                                                    :
                                                                                                                                    <div className="chat-icons-tooltip">{'Copy'.translate(this.props.lang)}</div>
                                                                                                                            }

                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    :
                                                                                                                    null
                                                                                                            }
                                                                                                            <pre>
                                                                                                                {error ? error : JSON.stringify(doctor.data, null, 4)}
                                                                                                            </pre>
                                                                                                        </div>
                                                                                                        :
                                                                                                        null
                                                                                                }

                                                                                            </li>
                                                                                        )
                                                                                    })
                                                                                }

                                                                            </ul>
                                                                        </div>
                                                                        :
                                                                        null
                                                                }

                                                            </li>
                                                        )
                                                    })}

                                                </ul>
                                                :
                                                null
                                        }

                                    </Col>


                                    {/* <Col lg="6">
                                        {
                                            previewLog.body ?
                                                <pre>
                                                    {
                                                        previewLog.body
                                                    }
                                                </pre>
                                                :

                                                null
                                        }
                                        {
                                            previewLog.paymentResponse ?
                                                <pre>
                                                    {
                                                        JSON.stringify(previewLog.paymentResponse,null,4)
                                                    }
                                                </pre>
                                                : null
                                        }

                                    </Col>
                                    <Col lg="12">
                                        <p><strong>{'Ip:'.translate(this.props.lang)}</strong> {previewLog.ip}</p>
                                        <p><strong>{'Referer:'.translate(this.props.lang)}</strong> {previewLog.referer}</p>
                                        <p><strong>{'User agent:'.translate(this.props.lang)}</strong> {previewLog.userAgent}</p>

                                    </Col> */}

                                </Row>
                            </ModalBody>
                        </Modal>
                        :
                        null
                }


            </div>
        );
    }
}

export default Page(WebDocCronLogs);