import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";

import Isvg from "react-inlinesvg";
import { Calendar } from "react-date-range";

import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import * as locales from "react-date-range/dist/locale";
import { DOMAINS } from "../../../constants";
import moment from "moment";
function iosDevice() {
  //return true;
  var userAgent = window.navigator.userAgent;
  if (userAgent.match(/iPad/i) || userAgent.match(/iPhone/i)) {
    return true;
  } else {
    return false;
  }
}
/**
 * Custom datepicker
 * @author   Stefan Pantic
 */
class DatePicker extends Component {
  constructor(props) {
    super(props);
    this.wrapperRef = React.createRef();

    this.state = {};
  }

  componentDidMount() {
    let date = new Date();
    this.setState({
      date: new Date(),
    });

    this.setState({
      year: date.getFullYear(),
    });

    if (this.props.value) {
      this.setState({
        value: this.props.value,
      });
    }

    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value != this.state.value && !this.state.value) {
      if (this.props.value) {
        this.setState({
          value: this.props.value,
        });
      }
    }
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      this.setState({
        showCalendar: null,
      });
    }
  };

  render() {
    // console.log(this.props)
    let locale = null;

    if (typeof window !== "undefined" && iosDevice() && false) {
      locale = undefined;
    } else if (typeof window !== "undefined" && window._lang) {
      if (window._lang == "en") {
        locale = locales.enGB;
      } else {
        let domen = window.location.origin;
        let country = DOMAINS.find((item) => item.domen == domen)?.country;
        if (country == "sweden") {
          locale = locales.sv;
        } else if (country == "denmark") {
          locale = locales.da;
        } else if (country == "gr") {
          locale = locales.el;
        } else if (country == "es") {
          locale = locales.es;
        } else if (country == "ch") {
          locale = locales.de;
        } else {
          locale = locales.enGB;
        }
      }
    }
    return (
      <div
        className={
          this.props.error ? "date-picker-field required" : "date-picker-field"
        }
        ref={this.wrapperRef}
        style={{
          marginBottom: this.props.marginBottom ? this.props.marginBottom : 0,
        }}
      >
        <label>{this.props.label}</label>
        <input
          value={this.props.value}
          style={{
            height: this.props.heightInput ? this.props.heightInput : null,
          }}
          type="text"
          onFocus={() => {
            if (!this.state.showCalendar) {
              this.setState({
                showCalendar: true,
              });
            }
          }}
        />

        {this.state.showCalendar ? (
          <Calendar
            locale={locale}
            minDate={this.props.minDate ? this.props.minDate : new Date()}
            maxDate={this.props.maxDate}
            date={
              this.props.value ? new Date(this.props.value) : this.state.date
            }
            // disabledDay={(day) => {
            //     if (this.props.completeStaeInput && day) {
            //         if (day.getDay() == 0 && !this.props.completeStaeInput.checkedSunday) {
            //             return true
            //         } else if (day.getDay() == 1 && !this.props.completeStaeInput.checkedMonday) {
            //             return true
            //         } else if (day.getDay() == 2 && !this.props.completeStaeInput.checkedTuesday) {
            //             return true
            //         } else if (day.getDay() == 3 && !this.props.completeStaeInput.checkedWednesday) {
            //             return true
            //         } else if (day.getDay() == 4 && !this.props.completeStaeInput.checkedThursday) {
            //             return true
            //         } else if (day.getDay() == 5 && !this.props.completeStaeInput.checkedFriday) {
            //             return true
            //         } else if (day.getDay() == 6 && !this.props.completeStaeInput.checkedSaturday) {
            //             return true
            //         } else {
            //             return false
            //         }
            //     }

            // }}
            onChange={(date) => {
              // console.log(date)
              this.setState({
                showCalendar: null,
                // value:moment(new Date(date)).format('YYYY-MM-DD')
              });
              this.props.onChange(
                moment(new Date(date)).format(
                  this.props.dateFormat ? this.props.dateFormat : "YYYY-MM-DD"
                )
              );
            }}
          />
        ) : null}
      </div>
    );
  }
}

export default DatePicker;
