import React, { Component } from 'react';
import Page from '../../containers/page';

import {
    Container,
    Row,
    Col,
    Input,
    Label,
    Button,
    FormGroup,
    UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem,
    Collapse, CardBody, Card

} from 'reactstrap';


import FormBuilder from '../../components/forms/modalFormBuilder';
import { required } from '../../components/forms/validation';
import HTML from '../../components/forms/fields/html';

import Isvg from 'react-inlinesvg'
import Search from '../../components/search';

import profile from '../../assets/images/profile-pic.png'
import map from '../../assets/svg/map.svg'
import chart from '../../assets/svg/chart.svg'
import edit from '../../assets/svg/edit.svg'
import garabage from '../../assets/svg/garbage.svg'
import arrowDown from '../../assets/svg/arrow-down.svg'
import arrowUp from '../../assets/svg/arrow-up.svg'
import addIcon from '../../assets/svg/add-icon.svg';
import { Player, Controls } from "@lottiefiles/react-lottie-player";
import arrayMove from 'array-move';

import ListBuilder from '../../components/listBuilder';
import DeleteModal from '../../components/deleteModal';
import { getSearchParams, generateSearchLink, generateSearchLinkMultiple } from '../../helpers/linkHelper';
import { SortableContainer, SortableElement, sortableHandle, } from 'react-sortable-hoc';

import { API_ENDPOINT } from '../../constants';
import ErrorModal from '../../components/errorModal';

import profileUser from '../../assets/images/user.png';
import notification from '../../assets/svg/notification.svg';
import moreIcon from '../../assets/svg/more.svg';
import Iframe from 'react-iframe';



function parseJSON(response) {
    return response.json().then(json => {
        return {
            result: json,
            status: response.status
        }
    })
}

/**
* Page for edit user manual
* @author   Aleksandar Dabic
*/
class UserManual extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props.initialData,
            form: false,
            useState: false,
            showPlayer: false,
            showPlayer2: false,
            deleteModal: null,
            openedSection: -1,
            openedSections: [],
            params: {
                entries: 10,
                page: 0
            },
            sections: [],

            loading: true
        };
    }



    get = () => {
        this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));

        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0 })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

        for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
            this.props.loadDataWithQuery[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0 })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }
        if (this.props.lang == 'en') {
            fetch(API_ENDPOINT + '/data-managment/user/user-manual/en', {
                method: 'GET',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                }
            }).then(parseJSON).then(({ result, status }) => {
                if (result && result.sections) {
                    this.setState({ sections: result.sections })
                }

            })
        } else {
            fetch(API_ENDPOINT + '/data-managment/user/user-manual', {
                method: 'GET',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                }
            }).then(parseJSON).then(({ result, status }) => {
                if (result && result.sections) {
                    this.setState({ sections: result.sections })
                }

            })

        }





    }



    componentDidMount() {
        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }

        this.get();

        // window.onmessage = (e) => {
        //     if (e && e.data && e.data.indexOf && e.data.indexOf('changePath') !== -1) {
        //         this.props[0].history.push(e.data.split('||')[1])
        //     }
        // }

        // if (this.props.uData) {
        //     this.setState({
        //         loggedIn: true
        //     })
        // }
    }

    componentDidUpdate(prevProps) {
        if (prevProps[0].location.search != this.props[0].location.search) {
            this.setState({
                loading: true
            }, () => {
                this.get();

            })
        }
        if (prevProps.lang != this.props.lang) {
            this.get()
        }
    }


    updateSort = (field, type) => {
        if (this.state.useState) {
            let state = this.state.params;
            state.sortField = field;
            state.sortType = type;
            this.setState({
                params: state
            })
        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0 }, [{ name: 'sortField', value: field }, { name: 'sortType', value: type }], false));

        }
    }

    updateParams = (name, value, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };
                obj[name] = value;
                this.setState({ params: obj });
            } else {
                let obj = this.state.params;
                obj[name] = value;
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(generateSearchLink(this.props[0].location.search, { entries: 10, page: 0 }, name, value, restart));
        }
    }
    updateMultipleParams = (fields, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj });

            } else {
                let obj = this.state.params;
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0 }, fields, restart));
        }
    }



    insertOrUpdate = () => {
        this.setState(
            {
                showPlayer: true,
            },
            () => {

                this.player.current.play();
                fetch(API_ENDPOINT + '/data-managment/user/user-manual/update', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        sections: this.state.sections
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (result.error) {
                        this.setState({ error: result.error })
                    } else {
                        this.setState({
                            message: result.message,
                            showPlayer: false,
                            showPlayer2: true,
                        },
                            () => {
                                setTimeout(() => {
                                    this.setState({ showPlayer2: false });
                                }, 1000);
                            })

                    }

                })
            }
        )
    }


    stopAnimation = () => {
        this.player.current.play();
    }

    toggle = (idx) => {
        if (idx != this.state.openedSection) {
            this.setState({ openedSection: idx })
        } else {
            this.setState({ openedSection: -1 })
        }

        if (this.state.openedSections.filter(item => item == idx).length == 0) {
            let openedSections = this.state.openedSections;
            openedSections.push(idx);
            this.setState({ openedSections })
        } else {
            let openedSections = this.state.openedSections;
            let index = openedSections.indexOf(idx);
            if (index != -1) {
                openedSections.splice(index, 1)
            }
            this.setState({ openedSections })
        }

    }




    render() {
        let params = {};
        if (this.state.useState) {
            params = this.state.params;
        } else {
            params = getSearchParams(this.props[0].location.search, { entries: 10, page: 0 })
        }
        return (
            <div className={this.props.autoHideSidebar ? "dashboard dashboard-auto-hide" : "dashboard"}>
                <Container fluid>

                    <Row>
                        <Col xl="12">
                            <div className="panel panel-short">
                                <div className="panel-header">
                                    <h5 className="component-header">
                                        {this.props.linksMeta &&
                                            this.props.linksMeta[this.props[0].location.pathname]
                                            ? this.props.linksMeta[this.props[0].location.pathname][
                                            this.props.lang
                                            ]
                                            : ""}{" "}
                                    </h5>

                                </div>
                                {
                                    this.state.sections && this.state.sections.map((item, idx, arr) => {
                                        // let checkMarginBottom = false;
                                        // if(arr[idx].fontSize && idx + 1 < arr.length && arr[idx + 1].fontSize && arr[idx + 1].fontSize > arr[idx].fontSize){
                                        //     checkMarginBottom = true
                                        // }
                                        let checkMarginLeft = false
                                        let mainFontSize = arr[0].fontSize
                                        let nextMainMargin
                                        if (arr[1] && arr[1].fontSize < mainFontSize) {
                                            nextMainMargin = arr[1].fontSize
                                        }
                                        let checkNextMarginLeft = false
                                        if (mainFontSize && item.fontSize < mainFontSize) {
                                            checkMarginLeft = true
                                            if (nextMainMargin && item.fontSize < nextMainMargin) {
                                                checkNextMarginLeft = true
                                            }
                                        }
                                        return (
                                            <div>
                                                <div className="user-manual-section-item" onClick={() => this.toggle(idx)} style={{ marginBottom: '1rem' }}>
                                                    {/* {item.fontSize == 12 ? <h6>{item.title}</h6> :
                                                        item.fontSize == 14 ? <h5>{item.title}</h5> :
                                                            item.fontSize == 16 ? <h4>{item.title}</h4> :
                                                                item.fontSize == 19 ? <h3>{item.title}</h3> :
                                                                    item.fontSize == 24 ? <h2>{item.title}</h2> :
                                                                        item.fontSize == 32 ? <h1>{item.title}</h1> : <h4>{item.title}</h4>} */}
                                                    <div style={window.innerWidth < 768 ? checkMarginLeft ? checkNextMarginLeft ? { marginLeft: 30, fontSize: item.fontSize ? item.fontSize / 1.1 : 16 } : { marginLeft: 15, fontSize: item.fontSize ? item.fontSize / 1.2 : 16 } : { fontSize: item.fontSize ? item.fontSize / 1.3 : 16 } : checkMarginLeft ? checkNextMarginLeft ? { marginLeft: 30, fontSize: item.fontSize ? item.fontSize : 16 } : { marginLeft: 15, fontSize: item.fontSize ? item.fontSize : 16 } : { fontSize: item.fontSize ? item.fontSize : 16 }}>{item.title}</div>
                                                </div>
                                                {/* this.state.openedSections.filter(sectionIdx => sectionIdx == idx).length */}
                                                {/* this.state.openedSection == idx */}
                                                {!item.titleField ?
                                                    <div className="user-manual-card">
                                                        <Collapse isOpen={this.state.openedSections.filter(sectionIdx => sectionIdx == idx).length} className="no-border">
                                                            <Card style={checkMarginLeft ? checkNextMarginLeft ? { marginLeft: 30 } : { marginLeft: 15 } : {}}>
                                                                <CardBody>
                                                                    <div dangerouslySetInnerHTML={{ __html: item.content }}></div>
                                                                </CardBody>
                                                            </Card>
                                                        </Collapse>
                                                    </div>
                                                    : null}
                                            </div>
                                        )
                                    })
                                }



                            </div>
                        </Col>
                    </Row>
                </Container>



            </div>
        );
    }
}

export default Page(UserManual);