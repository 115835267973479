import React, { Component } from 'react';
import Page from '../../containers/page';

import {
    Container,
    Row,
    Col,
    Modal, ModalHeader, ModalBody, ModalFooter, Button, FormGroup, Input, Label, Table
} from 'reactstrap';

import { Link } from 'react-router-dom';
import Isvg from "react-inlinesvg";

import FormBuilder from '../../components/forms/modalFormBuilder';
import { required } from '../../components/forms/validation';

import ListBuilder from '../../components/listBuilder';
import DeleteModal from '../../components/deleteModal';
import ErrorModal from '../../components/errorModal';
import { getSearchParams, generateSearchLink, generateSearchLinkMultiple } from '../../helpers/linkHelper';
import { API_ENDPOINT } from '../../constants';
import { formValueSelector, change } from 'redux-form';  // ES6
import { connect } from 'react-redux';
import ReferralClinics from '../../components/referralClinics';
import moment from 'moment';
import PrintHelper from '../../components/printHelper';
import xIcon from '../../assets/svg/x.svg';
import Search from "../../components/search";
import profileUser from "../../assets/images/user.png";
import notification from "../../assets/svg/notification.svg";
import moreIcon from "../../assets/svg/more.svg";
import Checkbox from '../../components/forms/fields/checkbox';
import openChat from '../../assets/svg/open_chat.svg';


function pad(n, width, z) {
    z = z || '0';
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
}

/**
* Receive referrals page
* @author   Milan Stanojevic
*/
class ReceivedReferrals extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props.initialData,
            form: false,
            useState: false,
            params: {
                entries: 10,
                page: 0
            },
            items: [],
            groups: [],
            subgroups: [],
            total: 0,
            loading: true,
            changeButton: false,


        };
    }


    get = () => {
        this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));

        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0, sortField: 'timestamp', sortType: -1, clinic: this.props.selectedClinic })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

        for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
            this.props.loadDataWithQuery[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0, sortField: 'timestamp', sortType: -1, clinic: this.props.selectedClinic })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

    }

    componentDidMount() {
        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }

        this.get();


        this.interval = setInterval(() => {
            this.get();
        }, 60 * 1000);

    }
    componentWillUnmount() {
        if (this.interval) {
            clearInterval(this.interval)
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps[0].location.search != this.props[0].location.search) {
            this.setState({
                loading: true
            }, () => {
                this.get();

            })
        }

        if (prevProps.selectedClinic != this.props.selectedClinic) {
            this.get();
        }

    }


    updateSort = (field, type) => {
        if (this.state.useState) {
            let state = this.state.params;
            state.sortField = field;
            state.sortType = type;
            this.setState({
                params: state
            })
        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0, sortField: 'timestamp', sortType: -1 }, [{ name: 'sortField', value: field }, { name: 'sortType', value: type }], false));

        }
    }

    updateParams = (name, value, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };
                obj[name] = value;
                this.setState({ params: obj });
            } else {
                let obj = this.state.params;
                obj[name] = value;
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(generateSearchLink(this.props[0].location.search, { entries: 10, page: 0, sortField: 'timestamp', sortType: -1 }, name, value, restart));
        }
    }
    updateMultipleParams = (fields, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj });

            } else {
                let obj = this.state.params;
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0, sortField: 'timestamp', sortType: -1 }, fields, restart));
        }
    }



    insertOrUpdate = (data) => {

        delete data.subgroupName;
        delete data.groupName;
        delete data.additionalSubgroupsObjects;

        this.setState({
            loading: true
        }, () => {
            if (!this.state.form._id) {

                fetch(API_ENDPOINT + '/data/articles/new', {
                    method: 'PUT',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify(data)
                }).then(res => res.json()).then((result) => {
                    this.get();
                    this.setState({
                        form: null
                    })
                })
            } else {
                fetch(API_ENDPOINT + '/data/articles/' + this.state.form._id, {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify(data)
                }).then(res => res.json()).then((result) => {
                    this.get();
                    this.setState({
                        form: null
                    })
                })
            }
        })
    }


    update = (data) => {

        delete data.subgroupName;
        delete data.groupName;
        delete data.additionalSubgroupsObjects;

        this.setState({
            loading: true
        }, () => {
            fetch(API_ENDPOINT + '/data/articles/' + data._id, {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify(data)
            }).then(res => res.json()).then((result) => {
                this.get();
                this.setState({
                    form: null
                })
            })

        })
    }



    delete = (id) => {
        this.setState({
            loading: true
        }, () => {

            fetch(API_ENDPOINT + `/referrals/recipient/${id}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
            }).then(res => res.json()).then((result) => {
                this.get();
            })
        })

    }

    managerMail = (data) => {
        this.setState({
            loading: true
        }, () => {
            fetch(API_ENDPOINT + '/data/region-managers/email', {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify(data)
            }).then(res => res.json()).then((result) => {
                if (result.error) {
                    this.setState({ error: result.error })
                }
                this.get();
                this.setState({
                    referral: null,
                    disallowForm: false,
                    returnForm: false,
                    scheduleForm: false,
                    managerModal: false
                })
            })
        })
    }

    check = (data) => {




        if (data.manager) {
            this.setState({
                managerModal: data
            })
        } else {
            this.setState({ signinModal: data })
        }


    }

    /**
    * Function change referral status to allowed 
    * @author   Milan Stanojevic
    */
    allowReferral = (data) => {


        this.setState({
            loading: true
        }, () => {
            fetch(API_ENDPOINT + '/referrals/allow/' + this.state.referral._id, {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify({})
            }).then(res => res.json()).then((result) => {
                this.get();
                if (result.error) {
                    this.setState({
                        error: result.error?.translate(this.props.lang),
                        signinModal: false,
                        allowForm: false,
                        referral: null,
                        disallowForm: false,
                        returnForm: false,
                        scheduleForm: false,
                    })
                } else {
                    this.setState({
                        signinModal: false,
                        allowForm: false,
                        referral: null,
                        disallowForm: false,
                        returnForm: false,
                        scheduleForm: false,

                    })
                }

            })
        })

    }

    /**
    * Function change referral status to disallowed 
    * @author   Milan Stanojevic
    * @Objectparam  {String} reason         reason why this referral is disallowed
    */
    disallowReferral = (data) => {
        this.setState({
            loading: true
        }, () => {
            fetch(API_ENDPOINT + '/referrals/disallow/' + this.state.referral._id, {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify(data)
            }).then(res => res.json()).then((result) => {
                this.get();
                if (result.error) {
                    this.setState({
                        error: result.error?.translate(this.props.lang),
                        referral: null,
                        disallowForm: false,
                        returnForm: false,
                        scheduleForm: false,
                    })
                } else {
                    this.setState({
                        referral: null,
                        disallowForm: false,
                        returnForm: false,
                        scheduleForm: false
                    })
                }

            })
        })
    }

    returnForUpdate = (data) => {
        this.setState({
            loading: true
        }, () => {
            fetch(API_ENDPOINT + '/referrals/return/' + this.state.referral._id, {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify(data)
            }).then(res => res.json()).then((result) => {
                this.get();
                this.setState({
                    referral: null,
                    disallowForm: false,
                    returnForm: false,
                    scheduleForm: false
                })
            })
        })
    }

    forward = (clinic) => {

        this.setState({
            loading: true
        }, () => {
            fetch(API_ENDPOINT + '/referrals/forward/' + this.state.referral._id, {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify({
                    forwardRecipientClinic: clinic._id
                })
            }).then(res => res.json()).then((result) => {
                this.get();
                this.setState({
                    referral: null,
                    disallowForm: false,
                    returnForm: false,
                    selectClinicModal: false,
                    scheduleForm: false,
                    checkForward: false
                })
            })
        })

    }


    acceptReferral = () => {
        this.setState({
            loading: true
        }, () => {
            fetch(API_ENDPOINT + '/referrals/accept/' + this.state.referral._id, {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify({})
            }).then(res => res.json()).then((result) => {
                this.get();
                this.setState({
                    referral: null,
                    disallowForm: false,
                    returnForm: false,
                    scheduleForm: false
                })
            })
        })
    }
    rejectReferral = (data) => {
        this.setState({
            loading: true
        }, () => {
            fetch(API_ENDPOINT + '/referrals/reject/' + this.state.referral._id, {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify(data)
            }).then(res => res.json()).then((result) => {
                this.get();
                this.setState({
                    referral: null,
                    disallowForm: false,
                    returnForm: false,
                    scheduleForm: false
                })
            })
        })
    }

    acceptRequestedTime = () => {
        this.setState({
            loading: true
        }, () => {
            fetch(API_ENDPOINT + '/referrals/patient/accept-requested-time/' + this.state.referral._id, {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify({})
            }).then(res => res.json()).then((result) => {
                this.get();
                this.setState({
                    referral: null,
                    disallowForm: false,
                    returnForm: false,
                    scheduleForm: false
                })
            })
        })
    }
    rejectRequestedTime = (data) => {
        this.setState({
            loading: true
        }, () => {
            fetch(API_ENDPOINT + '/referrals/patient/reject-requested-time/' + this.state.referral._id, {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify({ date: data.date + ' ' + data.time })
            }).then(res => res.json()).then((result) => {
                this.get();
                this.setState({
                    referral: null,
                    disallowForm: false,
                    returnForm: false,
                    scheduleForm: false
                })
            })
        })
    }

    /**
    * Start chat conversation
    * @author   Milan Stanojevic
    * @Objectparam    {String} id       conversaton id
    * @Objectparam    {String} data     conversaton form data
    */
    conversation = (id, data) => {
        this.setState({
            loading: true
        }, () => {
            fetch(API_ENDPOINT + '/referrals/conversation/recpient/' + id, {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify(data)
            }).then(res => res.json()).then((result) => {
                this.props[0].history.push(`/chat?conversation=${result.conversation}`)
            })

        })
    }


    render() {
        let params = {};
        if (this.state.useState) {
            params = this.state.params;
        } else {
            params = getSearchParams(this.props[0].location.search, { entries: 10, page: 0, sortField: 'timestamp', sortType: -1 })
        }


        let subgroups = [{ name: 'None', value: null }];

        if (this.props.group) {
            for (let i = 0; i < this.state.subgroups.length; i++) {
                if (this.state.subgroups[i].groups && this.state.subgroups[i].groups.indexOf(this.props.group) !== -1) {
                    subgroups.push(this.state.subgroups[i]);
                }
            }
        }

        const statusColors = {
            'waiting-for-approval': 'orange',
            'disallowed': 'red',
            'allowed': 'green',
            'return-for-update': 'blue',
            'patient-requested-another-time': 'purple',
            'patient-rejected': 'red',
            'patient-accepted': 'green',
            'clinic-accepted-requested-time': 'green',
            'clinic-rejected-requested-time': 'orange',
            'approved-by-region-manager': 'green',
            'rejected-by-region-manager': 'red',
            'scheduled': 'green',
            'visit-rescheduled': 'green',
            'not-booked': 'red',
            'requested-new-appointment': 'orange',
            'external': 'gray',

        }

        const humanReadable = {
            'waiting-for-approval': 'Waiting for approval'.translate(this.props.lang),
            'disallowed': 'Disallowed'.translate(this.props.lang),
            'allowed': 'Allowed'.translate(this.props.lang),
            'return-for-update': 'Return for update'.translate(this.props.lang),
            'patient-requested-another-time': 'Patient requested anoother time'.translate(this.props.lang),
            'patient-rejected': 'Rejected by patient'.translate(this.props.lang),
            'patient-accepted': 'Patient accepted'.translate(this.props.lang),
            'clinic-accepted-requested-time': 'Clinic accepted requested time'.translate(this.props.lang),
            'clinic-rejected-requested-time': 'Clinic rejected requested time'.translate(this.props.lang),
            'approved-by-region-manager': 'Approved by region manager'.translate(this.props.lang),
            'rejected-by-region-manager': 'Rejected by region manager'.translate(this.props.lang),
            'scheduled': 'Scheduled'.translate(this.props.lang),
            'visit-rescheduled': 'Visit rescheduled'.translate(this.props.lang),
            'not-booked': 'Not booked'.translate(this.props.lang),
            'closed': 'Closed'.translate(this.props.lang),
            'requested-new-appointment': 'Requested new appointment'.translate(this.props.lang),
            'external': 'External transfer'.translate(this.props.lang),
        }





        return (
            <div className={this.props.autoHideSidebar ? "dashboard dashboard-auto-hide" : "dashboard"}>

                <Container fluid>
                    <Row>
                        <Col lg="12">

                            <div className="panel">
                                <div className="panel-header">
                                    <h5 className="component-header">{this.props.linksMeta && this.props.linksMeta[this.props[0].location.pathname] ? this.props.linksMeta[this.props[0].location.pathname][this.props.lang] : ''}</h5>
                                </div>

                                <ListBuilder
                                    lang={this.props.lang}
                                    loading={this.state.loading}
                                    total={this.state.total}
                                    showNumeration={false}
                                    fields={[
                                        { type: 'text', name: 'recpientClinicName', label: 'Receiver'.translate(this.props.lang), allowSort: true },
                                        { type: 'text', name: 'senderClinicDataName', label: 'Sender'.translate(this.props.lang), allowSort: true },
                                        { type: 'text', name: 'createdByUsername', label: 'HOSP'.translate(this.props.lang), allowSort: true },
                                        { type: 'text', name: 'remissType', label: 'Referral Type'.translate(this.props.lang), allowSort: true },
                                        //{ type: 'text', name: 'articleName', label: 'Disease'.translate(this.props.lang), allowSort: true },
                                        { type: 'text', name: 'patient', label: 'Patient'.translate(this.props.lang), allowSort: false },
                                        { type: 'text', name: 'version', label: 'Referral ID'.translate(this.props.lang), allowSort: true },
                                        { type: 'text', name: 'status', label: 'Status'.translate(this.props.lang), allowSort: true },
                                        { type: 'text', name: 'rsign', label: 'Rsign'.translate(this.props.lang), allowSort: true },
                                        { type: 'checkbox', name: '', label: 'Send'.translate(this.props.lang), allowSort: true },
                                        { type: 'checkbox', name: 'sendTimestamp', label: 'E-send'.translate(this.props.lang), allowSort: true },
                                        { type: 'checkbox', name: '', label: 'Confirmed'.translate(this.props.lang), allowSort: true },
                                        { type: 'text', name: 'timestamp', label: 'Referral date'.translate(this.props.lang), allowSort: true },
                                        { type: 'text', name: 'converateTimestamp', label: 'Coverage'.translate(this.props.lang), allowSort: true },
                                        { type: 'text', name: 'typ', label: 'Type E'.translate(this.props.lang), allowSort: true },
                                        { type: 'checkbox', name: 'seenScheduled', label: 'Seen'.translate(this.props.lang), allowSort: true },


                                    ]}
                                    rawItems={this.state.items}
                                    items={this.state.items.map((item => {
                                        return {
                                            ...item,
                                            version: item.patient?.referralNumber + (item.version != 0 ? '.' + item.version : ''),
                                            recpientClinicName: <>{item.recpientClinicName}<br /><span className="clinic-subgroup-name">{item.recpientClinicSubgroupName} </span></>,
                                            senderClinicDataName: <>{item.senderClinicData.name} <br /><span className="clinic-subgroup-name">{item.senderClinicData.subgroupName} </span></>,
                                            patient: <Button color='#fff' onClick={() => this.setState({ patientProfile: item })}>{item.patient?.name}<br /><span className="clinic-subgroup-name">{item.patient?.socialSecurityNumber} </span> </Button>,
                                            timestamp: this.props.getStringDateTs(item.timestamp, `${this.props.dateFormat} HH:mm`)/*moment.unix(item.timestamp).format(`${this.props.dateFormat} HH:mm`)*/,
                                            converateTimestamp: this.props.getStringDateTs(item.converateTimestamp, `${this.props.dateFormat} HH:mm`)/*moment.unix(item.converateTimestamp).format(`${this.props.dateFormat} HH:mm`)*/,
                                            status: <>
                                                <span style={{ color: statusColors[item.status] }}>{humanReadable[item.status]} </span><br />

                                                {item.status !== 'waiting-for-approval' ?
                                                    <span>(
                                                        {item.status == 'disallowed' ?
                                                            // moment.unix(item.disallowedTimestamp).format(`${this.props.dateFormat} HH:mm`) :
                                                            this.props.getStringDateTs(item.disallowedTimestamp, `${this.props.dateFormat} HH:mm`) :
                                                            item.status == 'return-for-update' ?
                                                                // moment.unix(item.returnTimestamp).format(`${this.props.dateFormat} HH:mm`) :
                                                                this.props.getStringDateTs(item.returnTimestamp, `${this.props.dateFormat} HH:mm`) :
                                                                item.status == 'allowed' ?
                                                                    // moment.unix(item.allowedTimestamp).format(`${this.props.dateFormat} HH:mm`) :
                                                                    this.props.getStringDateTs(item.allowedTimestamp, `${this.props.dateFormat} HH:mm`) :
                                                                    item.status == 'patient-requested-another-time' ?
                                                                        // moment.unix(item.patientRequestTimeTimestamp).format(`${this.props.dateFormat} HH:mm`) :
                                                                        this.props.getStringDateTs(item.patientRequestTimeTimestamp, `${this.props.dateFormat} HH:mm`) :
                                                                        item.status == 'patient-rejected' ?
                                                                            // moment.unix(item.patientRejectedTimestamp).format(`${this.props.dateFormat} HH:mm`) :
                                                                            this.props.getStringDateTs(item.patientRejectedTimestamp, `${this.props.dateFormat} HH:mm`) :
                                                                            item.status == 'patient-accepted' ?
                                                                                // moment.unix(item.patientAcceptedTimestamp).format(`${this.props.dateFormat} HH:mm`) :
                                                                                this.props.getStringDateTs(item.patientAcceptedTimestamp, `${this.props.dateFormat} HH:mm`) :
                                                                                item.status == 'patient-accepted' ?
                                                                                    // moment.unix(item.patientAcceptedTimestamp).format(`${this.props.dateFormat} HH:mm`) :
                                                                                    this.props.getStringDateTs(item.patientAcceptedTimestamp, `${this.props.dateFormat} HH:mm`) :
                                                                                    item.status == 'clinic-accepted-requested-time' ?
                                                                                        // moment.unix(item.clinicAcceptedTimestamp).format(`${this.props.dateFormat} HH:mm`) :
                                                                                        this.props.getStringDateTs(item.clinicAcceptedTimestamp, `${this.props.dateFormat} HH:mm`) :
                                                                                        item.status == 'clinic-rejected-requested-time' ?
                                                                                            // moment.unix(item.clinicRejectedTimestamp).format(`${this.props.dateFormat} HH:mm`) :
                                                                                            this.props.getStringDateTs(item.clinicRejectedTimestamp, `${this.props.dateFormat} HH:mm`) :
                                                                                            item.status == 'approved-by-region-manager' ?
                                                                                                // moment.unix(item.approvedByRegionManagerTimestemp).format(`${this.props.dateFormat} HH:mm`) :
                                                                                                this.props.getStringDateTs(item.approvedByRegionManagerTimestemp, `${this.props.dateFormat} HH:mm`) :
                                                                                                item.status == 'canceled-by-region-manager' ?
                                                                                                    // moment.unix(item.canceledByRegionManagerTimestemp).format(`${this.props.dateFormat} HH:mm`) :
                                                                                                    this.props.getStringDateTs(item.canceledByRegionManagerTimestemp, `${this.props.dateFormat} HH:mm`) :
                                                                                                    item.status == 'scheduled' ?
                                                                                                        // moment.unix(item.patientReservedTimestamp).format(`${this.props.dateFormat} HH:mm`) :
                                                                                                        this.props.getStringDateTs(item.patientReservedTimestamp, `${this.props.dateFormat} HH:mm`) :
                                                                                                        item.status == 'visit-rescheduled' ?
                                                                                                            // moment.unix(item.patientReservedTimestamp).format(`${this.props.dateFormat} HH:mm`)
                                                                                                            this.props.getStringDateTs(item.patientReservedTimestamp, `${this.props.dateFormat} HH:mm`)
                                                                                                            :
                                                                                                            item.status == 'not-booked' ?
                                                                                                                // moment.unix(item.notBookedTimestamp).format(`${this.props.dateFormat} HH:mm`) :
                                                                                                                this.props.getStringDateTs(item.notBookedTimestamp, `${this.props.dateFormat} HH:mm`) :
                                                                                                                item.status == 'closed' ?
                                                                                                                    // moment.unix(item.closedTimestamp).format(`${this.props.dateFormat} HH:mm`) :
                                                                                                                    this.props.getStringDateTs(item.closedTimestamp, `${this.props.dateFormat} HH:mm`) :
                                                                                                                    item.status == 'requested-new-appointment' ?
                                                                                                                        this.props.getStringDateTs(item.patientRequestedNewAppointment, `${this.props.dateFormat} HH:mm`) :
                                                                                                                        // moment.unix(item.patientRequestedNewAppointment).format(`${this.props.dateFormat} HH:mm`) :

                                                                                                                        null
                                                        }) </span> : null} </>,

                                        }
                                    }))}
                                    actions={
                                        [
                                            {
                                                component: <i className="fa fa-eye base-color" />,
                                                onClick: (item) => this.setState({ referral: item })
                                            },
                                            {
                                                component: <i className="fa fa-envelope" />,
                                                onClick: (item) => this.setState({ conversationModal: item, conversationForm: { name: item.patient?.referralNumber + '-' + pad(item.conversations.length + 1, 2) } })
                                            },


                                            /*{
                                                component: <i className="fa fa-undo base-color" />,
                                                onClick: (item) => this.setState({ form: item })
                                            },

                                            {
                                                component: <i className="fa fa-ban danger-color" />,
                                                onClick: (item) => this.setState({ form: item })
                                            },*/
                                            /*{
                                                component: <i className="fa fa-trash danger-color" />,
                                                onClick: (item) => this.setState({ deleteModal: item })
                                            },*/
                                        ]
                                    }
                                    params={params}
                                    sortField={params.sortField}
                                    sortType={params.sortType}
                                    updateSort={this.updateSort}
                                    updateParams={this.updateParams}
                                    updateMultipleParams={this.updateMultipleParams}

                                ></ListBuilder>
                                <DeleteModal
                                    lang={this.props.lang}
                                    isOpen={this.state.deleteModal}
                                    toggle={() => this.setState({ deleteModal: null })}
                                    handler={() => { this.delete(this.state.deleteModal._id); this.setState({ deleteModal: null }) }}
                                >
                                    {'Delete referral'.translate(this.props.lang)} <strong>{this.state.deleteModal && this.state.deleteModal.title}</strong>?
                                </DeleteModal>

                            </div>
                        </Col>
                    </Row>

                </Container>


                {this.state.referral ?
                    <Modal isOpen={this.state.referral} size="xl" centered>
                        <ModalHeader toggle={() => this.setState({ referral: !this.state.referral })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ referral: !this.state.referral })}><Isvg src={xIcon} /></button>}>{'Referral preview'.translate(this.props.lang)}</ModalHeader>

                        <ModalBody>
                            <div dangerouslySetInnerHTML={{ __html: this.props.lang == 'se' ? this.state.referral.referralContent.protectReferralFiles(this.props.uData ? this.props.uData.mediaToken : '', this.state.referral._id) : this.state.referral.referralContentEn ? this.state.referral.referralContentEn.protectReferralFiles(this.props.uData ? this.props.uData.mediaToken : '', this.state.referral._id) : this.state.referral.referralContent.protectReferralFiles(this.props.uData ? this.props.uData.mediaToken : '', this.state.referral._id) }} onChange={() => { }}></div>
                            <div>
                                <p> <strong>{'Status:'.translate(this.props.lang)}</strong> {humanReadable[this.state.referral.status]}</p>
                                {this.state.referral.reason ?
                                    <p> <strong>{'Reason:'.translate(this.props.lang)}</strong> {this.state.referral.reason ? this.state.referral.reason : ''}</p>
                                    :
                                    null}
                                {this.state.referral.patientReservedTimestamp ?
                                    <p> <strong>{'Scheduled time:'.translate(this.props.lang)}</strong> {this.props.getStringDateTs(this.state.referral.patientReservedTimestamp, `${this.props.dateFormat} HH:mm`)/*moment.unix(this.state.referral.patientReservedTimestamp).format(`${this.props.dateFormat} HH:mm`)*/}</p>
                                    :
                                    null}

                                {
                                    this.state.referral.status == 'patient-requested-another-time' ?
                                        <p> <strong>{'Requested time:'.translate(this.props.lang)}</strong> {this.props.getStringDateTs(this.state.referral.patientRequestedTimestamp, `${this.props.dateFormat} HH:mm`)/*moment.unix(this.state.referral.patientRequestedTimestamp).format(`${this.props.dateFormat} HH:mm`)*/}</p>

                                        :
                                        null
                                }

                            </div>

                        </ModalBody>
                        {this.state.referral.status == 'waiting-for-approval' || this.state.referral.status == 'approved-by-region-manager' || this.state.referral.status == 'rejected-by-region-manager' ?
                            <ModalFooter>
                                {!this.state.referral.forward && !this.state.referral.forwardRecipientClinic && !this.state.referral.isForwarded ?

                                    <Button color="primary" onClick={() => { this.setState({ selectClinicModal: true }) }}><i className="fa fa-reply" /> {'Forward'.translate(this.props.lang)}</Button>
                                    :
                                    null
                                }
                                {!this.state.referral.forwardRecipientClinic && !this.state.referral.forward ?
                                    <>
                                        <Button color="success" onClick={this.state.referral.status == 'approved-by-region-manager' ? () => this.setState({ signinModal: this.state.referral }) : () => this.check(this.state.referral)}><><i className="fa fa-check" />{'Sign and allow'.translate(this.props.lang)}</></Button>
                                        <Button color="danger" onClick={() => this.setState({ disallowForm: this.state.referral })}><i className="fa fa-close" /> {'Disallow'.translate(this.props.lang)} </Button>

                                        <Button color="warning" onClick={() => this.setState({ returnForm: this.state.referral })}><i className="fa fa-undo" /> {'Return for referral update'.translate(this.props.lang)} </Button>
                                    </>
                                    :
                                    null


                                }

                                {
                                    this.state.referral.forwardRecipientClinic && this.state.referral.forward ?
                                        <>
                                            <Button color="success" onClick={() => this.acceptReferral()}><i className="fa fa-check" /> {'Accept'.translate(this.props.lang)}</Button>
                                            <Button color="danger" onClick={() => this.rejectReferral({})}><i className="fa fa-close" /> {'Reject'.translate(this.props.lang)} </Button>

                                        </>

                                        :
                                        null
                                }

                                <PrintHelper lang={this.props.lang} html={this.props.lang == 'se' ? this.state.referral.referralContent : this.state.referral.referralContentEn ? this.state.referral.referralContentEn : this.state.referral.referralContent} />

                                <Button color="primary" onClick={() => this.setState({ referral: null })}> {'Close'.translate(this.props.lang)} </Button>


                            </ModalFooter>
                            :
                            this.state.referral.status == 'patient-requested-another-time' ?
                                <ModalFooter >

                                    <Button color="success" onClick={() => this.acceptRequestedTime()}><i className="fa fa-check" /> {'Accept requested time'.translate(this.props.lang)}</Button>
                                    <Button color="danger" onClick={() => this.setState({ scheduleForm: this.state.referral })}><i className="fa fa-close" /> {'Reject requested time'.translate(this.props.lang)} </Button>
                                    <PrintHelper lang={this.props.lang} html={this.props.lang == 'se' ? this.state.referral.referralContent : this.state.referral.referralContentEn ? this.state.referral.referralContentEn : this.state.referral.referralContent} />

                                    <Button color="primary" onClick={() => this.setState({ referral: null })}> {'Close'.translate(this.props.lang)} </Button>

                                </ModalFooter>
                                :
                                <ModalFooter>

                                    <PrintHelper lang={this.props.lang} html={this.props.lang == 'se' ? this.state.referral.referralContent : this.state.referral.referralContentEn ? this.state.referral.referralContentEn : this.state.referral.referralContent} />

                                    <Button color="primary" onClick={() => this.setState({ referral: null })}> {'Close'.translate(this.props.lang)} </Button>

                                </ModalFooter>

                        }

                    </Modal>

                    :
                    null
                }

                {
                    this.state.disallowForm ?
                        <FormBuilder selectedGroup={this.props.selectedGroup} selectedClinic={this.props.selectedClinic} countryTwoLetter={this.props.countryTwoLetter} country={this.props.country} dateFormat={this.props.dateFormat}
                            getStringDateTs={this.props.getStringDateTs}
                            lang={this.props.lang}
                            onSubmit={(data) => this.disallowReferral(data)}
                            isOpen={this.state.disallowForm}
                            toggle={() => this.setState({ disallowForm: null })}
                            title={'Disallow referral'.translate(this.props.lang)}
                            buttonText={'Submit'.translate(this.props.lang)}
                            fields={[
                                {
                                    type: 'row',
                                    children: [
                                        {
                                            type: 'col',
                                            width: {
                                                lg: 12,
                                                sm: 12,
                                                xs: 12
                                            },
                                            children: [
                                                {
                                                    type: 'textarea',
                                                    name: 'reason',
                                                    label: 'Give reason'.translate(this.props.lang),
                                                    validate: [required('Reason is required!'.translate(this.props.lang))]
                                                },
                                            ]
                                        },

                                    ]
                                }
                            ]}
                        ></FormBuilder>
                        :
                        null
                }
                {
                    this.state.returnForm ?
                        <FormBuilder selectedGroup={this.props.selectedGroup} selectedClinic={this.props.selectedClinic} countryTwoLetter={this.props.countryTwoLetter} country={this.props.country} dateFormat={this.props.dateFormat}
                            getStringDateTs={this.props.getStringDateTs}
                            lang={this.props.lang}
                            onSubmit={(data) => this.returnForUpdate(data)}
                            isOpen={this.state.returnForm}
                            toggle={() => this.setState({ returnForm: null })}
                            title={'Return for referral update'.translate(this.props.lang)}
                            buttonText={'Submit'.translate(this.props.lang)}
                            fields={[
                                {
                                    type: 'row',
                                    children: [
                                        {
                                            type: 'col',
                                            width: {
                                                lg: 12,
                                                sm: 12,
                                                xs: 12
                                            },
                                            children: [
                                                {
                                                    type: 'textarea',
                                                    name: 'reason',
                                                    label: 'Reason'.translate(this.props.lang),
                                                    validate: [required('Reason is required!'.translate(this.props.lang))]
                                                },
                                            ]
                                        },

                                    ]
                                }
                            ]}
                        ></FormBuilder>
                        :
                        null
                }

                {
                    this.state.allowForm ?
                        <FormBuilder selectedGroup={this.props.selectedGroup} selectedClinic={this.props.selectedClinic} countryTwoLetter={this.props.countryTwoLetter} country={this.props.country} dateFormat={this.props.dateFormat}
                            getStringDateTs={this.props.getStringDateTs}
                            lang={this.props.lang}
                            onSubmit={(data) => this.allowReferral(data)}
                            isOpen={this.state.allowForm}
                            toggle={() => this.setState({ allowForm: null })}
                            title={'Allow referral'.translate(this.props.lang)}
                            buttonText={'Submit'.translate(this.props.lang)}
                            fields={[
                                {
                                    type: 'row',
                                    children: [
                                        {
                                            type: 'col',
                                            width: {
                                                lg: 6,
                                                sm: 12,
                                                xs: 12
                                            },
                                            children: [
                                                {
                                                    type: 'date',
                                                    name: 'date',
                                                    label: 'Date'.translate(this.props.lang),
                                                    validate: [required('Date is required!'.translate(this.props.lang))]
                                                },
                                            ]
                                        },
                                        {
                                            type: 'col',
                                            width: {
                                                lg: 6,
                                                sm: 12,
                                                xs: 12
                                            },
                                            children: [
                                                {
                                                    type: 'time',
                                                    name: 'time',
                                                    label: 'Time'.translate(this.props.lang),
                                                    validate: [required('Time is required!'.translate(this.props.lang))]
                                                },
                                            ]
                                        },
                                    ]
                                }
                            ]}
                        ></FormBuilder>
                        :
                        null
                }


                {
                    this.state.scheduleForm ?
                        <FormBuilder selectedGroup={this.props.selectedGroup} selectedClinic={this.props.selectedClinic} countryTwoLetter={this.props.countryTwoLetter} country={this.props.country} dateFormat={this.props.dateFormat}
                            getStringDateTs={this.props.getStringDateTs}
                            lang={this.props.lang}
                            onSubmit={(data) => this.rejectRequestedTime(data)}
                            isOpen={this.state.scheduleForm}
                            toggle={() => this.setState({ scheduleForm: null })}
                            title={'Schedule new time'.translate(this.props.lang)}
                            buttonText={'Submit'.translate(this.props.lang)}
                            fields={[
                                {
                                    type: 'row',
                                    children: [
                                        {
                                            type: 'col',
                                            width: {
                                                lg: 6,
                                                sm: 12,
                                                xs: 12
                                            },
                                            children: [
                                                {
                                                    type: 'date',
                                                    name: 'date',
                                                    label: 'Date'.translate(this.props.lang),
                                                    validate: [required('Date is required!'.translate(this.props.lang))]
                                                },
                                            ]
                                        },
                                        {
                                            type: 'col',
                                            width: {
                                                lg: 6,
                                                sm: 12,
                                                xs: 12
                                            },
                                            children: [
                                                {
                                                    type: 'time',
                                                    name: 'time',
                                                    label: 'Time'.translate(this.props.lang),
                                                    validate: [required('Time is required!'.translate(this.props.lang))]
                                                },
                                            ]
                                        },
                                    ]
                                }
                            ]}
                        ></FormBuilder>
                        :
                        null
                }


                {
                    this.state.selectClinicModal ?
                        <Modal isOpen={this.state.selectClinicModal} size="xl" centered>
                            <ModalHeader toggle={() => this.setState({ selectClinicModal: !this.state.selectClinicModal })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ selectClinicModal: !this.state.selectClinicModal })}><Isvg src={xIcon} /></button>}>
                                <h4>{'Search Curoflow database'.translate(this.props.lang)}</h4>
                            </ModalHeader>
                            <ModalBody>
                                <ReferralClinics {...this.props} patientData={this.state.patientData} searchFor={this.state.referral.articleName} includeTags={this.state.tags} importClinic={(clinic) => {
                                    this.setState({
                                        checkForward: clinic
                                    })
                                    //this.forward(clinic);
                                    /*let addressLines = [];
                                    if (clinic.postAddress) {
                                        addressLines = this.splitAddress(clinic.postAddress);
                                    } else {
                                        addressLines = [clinic.street, clinic.zip + ' ' + clinic.city, ''];
                                    }
                
                                    this.setState({
                                        recipientData: {
                                            ...clinic,
                                            name: clinic.vantetiderName ? clinic.vantetiderName : clinic.name,
                                            address1: addressLines[0],
                                            address2: addressLines[1],
                                            address3: addressLines[2]
                                        },
                                        selectClinicModal: null
                                    })*/
                                }}></ReferralClinics>
                            </ModalBody>
                        </Modal>
                        :
                        null
                }

                {this.state.patientProfile ?
                    <Modal isOpen={this.state.patientProfile} centered>
                        <ModalHeader toggle={() => this.setState({ patientProfile: !this.state.patientProfile })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ patientProfile: !this.state.patientProfile })}><Isvg src={xIcon} /></button>}>
                            <h4>{'Patient profile'.translate(this.props.lang)}</h4>
                        </ModalHeader>
                        <ModalBody>
                            <FormGroup>
                                <Label>{'Name'.translate(this.props.lang)}</Label>
                                <Input type='text' disabled
                                    value={this.state.patientProfile.patient?.name} />
                            </FormGroup>
                            <FormGroup>
                                <Label>{'Email'.translate(this.props.lang)}</Label>
                                <Input type='text' disabled
                                    value={this.state.patientProfile.patient?.parents && this.state.patientProfile.patient?.parents[0] && this.state.patientProfile.patient?.parents[0].email ? this.state.patientProfile.patient?.parents[0].email : this.state.patientProfile.patient?.email} />
                            </FormGroup>
                            <FormGroup>
                                <Label>{'Phone'.translate(this.props.lang)}</Label>
                                <Input type='text' disabled
                                    value={this.state.patientProfile.patient?.parents && this.state.patientProfile.patient?.parents[0] && this.state.patientProfile.patient?.parents[0].phone ? this.state.patientProfile.patient?.parents[0].phone : this.state.patientProfile.patient?.phone} />
                            </FormGroup>
                            <FormGroup>
                                <Label>{'Address'.translate(this.props.lang)}</Label>
                                <Input type='text' disabled
                                    value={this.state.patientProfile.patient?.parents && this.state.patientProfile.patient?.parents[0] && this.state.patientProfile.patient?.parents[0].address ? this.state.patientProfile.patient?.parents[0].address : this.state.patientProfile.patient?.street + ',' + ' ' + this.state.patientProfile.patient?.zip + ' ' + this.state.patientProfile.patient?.city} />
                            </FormGroup>

                            <Button color="primary" onClick={() => this.setState({ patientProfile: !this.state.patientProfile })}>{'Close'.translate(this.props.lang)}</Button>

                        </ModalBody>
                    </Modal>

                    : null}

                {/* {this.state.wrongDate ?
                    <Modal isOpen={this.state.wrongDate} size="xl" centered>
                        <ModalHeader>
                            <h4>{'You can not pick date form the past'.translate(this.props.lang)}</h4>
                        </ModalHeader>
                        <ModalBody>
                            <div>
                                <Button onClick={() => this.setState({ wrongDate: !this.state.wrongDate })}>{'Pick again'.translate(this.props.lang)}</Button>
                            </div>
                        </ModalBody>
                    </Modal>

                    : null} */}

                {this.state.wrongDate ?
                    <Modal isOpen={this.state.wrongDate} size="xl" centered>
                        {/* <ModalHeader>
                            <h4>{'You can not pick date form the past'.translate(this.props.lang)}</h4>
                        </ModalHeader> */}
                        <ModalBody className="delete-modal">
                            <p>{'You can not pick date form the past'.translate(this.props.lang)}</p>
                        </ModalBody>
                        <ModalFooter className='buttons-right-blue'>
                            <Button style={{ minWidth: 100 }} onClick={() => this.setState({ wrongDate: !this.state.wrongDate })}>{'Pick again'.translate(this.props.lang)}</Button>
                        </ModalFooter>
                    </Modal>

                    : null}


                {/* {
                    this.state.checkForward ?
                        <Modal isOpen={this.state.checkForward} size="xl" centered>
                            <ModalHeader toggle={() => this.setState({ checkForward: !this.state.checkForward })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ checkForward: !this.state.checkForward })}><Isvg src={xIcon} /></button>}>
                                <h4>{'Are you shure that you want to continue?'.translate(this.props.lang)}</h4>
                            </ModalHeader>
                            <ModalBody>
                                <div>
                                    <p>{'This referral will be sent to'.translate(this.props.lang)} {this.state.checkForward.name}.</p>
                                    <Button onClick={() => this.forward(this.state.checkForward)}>{'Continue'.translate(this.props.lang)}</Button>
                                </div>
                            </ModalBody>
                        </Modal>
                        :
                        null
                } */}

                {
                    this.state.checkForward ?
                        <Modal isOpen={this.state.checkForward} size="xl" centered>
                            {/* <ModalHeader toggle={() => this.setState({ checkForward: !this.state.checkForward })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ checkForward: !this.state.checkForward })}><Isvg src={xIcon} /></button>}>
                                <h4>{'Are you shure that you want to continue?'.translate(this.props.lang)}</h4>
                            </ModalHeader> */}
                            <ModalBody className="delete-modal">
                                <p>{'This referral will be sent to'.translate(this.props.lang)} {this.state.checkForward.name}.</p>
                            </ModalBody>
                            <ModalFooter className='buttons-right-blue'>
                                <Button style={{ minWidth: 100 }}
                                    onClick={() => this.forward(this.state.checkForward)}
                                >{'Continue'.translate(this.props.lang)}</Button>
                                <Button style={{ minWidth: 100 }} onClick={() => this.setState({ checkForward: null })}>
                                    {'Cancel'.translate(this.props.lang)}
                                </Button>
                            </ModalFooter>
                        </Modal>
                        :
                        null
                }

                {
                    this.state.managerModal ?
                        <Modal isOpen={this.state.managerModal} centered>
                            <ModalHeader toggle={() => this.setState({ managerModal: !this.state.managerModal })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ managerModal: !this.state.managerModal })}><Isvg src={xIcon} /></button>}>
                                <h4>{'Request for payment'.translate(this.props.lang)}</h4>
                            </ModalHeader>
                            <ModalBody>
                                <div>
                                    <div class="form-check">
                                        <input type="checkbox" class="form-check-input" onChange={() => this.setState({ changeButton: !this.state.changeButton })} />
                                        <label class="form-check-label" for="paymentChecked" >{'Accept payment'.translate(this.props.lang)}</label>
                                    </div>
                                    <Button onClick={this.state.changeButton ? () => { this.managerMail(this.state.managerModal), this.setState({ changeButton: !this.state.changeButton }) } : () => this.setState({ signinModal: this.state.managerModal, managerModal: false })}>{this.state.changeButton ? 'Sent to region manager' : 'Sign and allow'}</Button>


                                </div>
                            </ModalBody>
                        </Modal>
                        :
                        null
                }

                {
                    this.state.conversationModal ?
                        <Modal isOpen={this.state.conversationModal} centered>
                            <ModalHeader toggle={() => this.setState({ conversationModal: !this.state.conversationModal })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ conversationModal: !this.state.conversationModal })}><Isvg src={xIcon} /></button>}>
                                <h4>{'Start conversation or enter existing conversation'.translate(this.props.lang)}</h4>
                            </ModalHeader>
                            <ModalBody>
                                {this.state.conversationModal.conversations.length ?
                                    <>
                                        <h6>{'Existing conversations'.translate(this.props.lang)}</h6>
                                        <Table responsive>
                                            <thead>
                                                <tr>
                                                    <th>{'Conversation name'.translate(this.props.lang)}</th>
                                                    <th>{'Clinic doctor'.translate(this.props.lang)}</th>
                                                    <th>{'Patient'.translate(this.props.lang)}</th>
                                                    <th>{'Action'.translate(this.props.lang)}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.conversationModal.conversations.map((item, idx) => {
                                                        return (
                                                            <tr>
                                                                <td>{item.name}</td>

                                                                <td><Checkbox checked={item._doctorIncludedInConversation} /></td>
                                                                <td><Checkbox checked={item._patientIncludedInConversation} /></td>
                                                                <td>
                                                                    <Link to={`/chat?conversation=${item._id}`}>< Isvg src={openChat} style={{ width: 21 }} /></Link>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }

                                            </tbody>
                                        </Table>
                                    </>
                                    :
                                    null
                                }

                                <h6>{'Start new conversation with:'.translate(this.props.lang)}</h6>
                                {/*<FormGroup>
                                    <Label >{'Conversation name'.translate(this.props.lang)}</Label>
                                    <Input value={this.state.conversationForm.name} onChange={(e) => {
                                        let conversationForm = this.state.conversationForm;
                                        conversationForm.name = e.target.value;
                                        if (conversationForm.name.indexOf('E-remiss ' + this.state.conversationModal.patient?.referralNumber + '-') !== 0) {
                                            conversationForm.name = 'E-remiss ' + this.state.conversationModal.patient?.referralNumber + '-';
                                        }
                                        this.setState({ conversationForm })
                                    }} size="sm" />
                                </FormGroup>
                                */}
                                <FormGroup>
                                    <Checkbox checked={this.state.conversationForm.clinicDoctor} onChange={(e) => {
                                        let conversationForm = this.state.conversationForm;
                                        conversationForm.clinicDoctor = e.target.checked;
                                        this.setState({ conversationForm })
                                    }} label={'Clinic doctor'.translate(this.props.lang)} />
                                </FormGroup>
                                <FormGroup check>
                                    <Checkbox disabled={!this.state.conversationModal._chatWithPatient} checked={this.state.conversationForm.patient} onChange={(e) => {
                                        let conversationForm = this.state.conversationForm;
                                        conversationForm.patient = e.target.checked;
                                        this.setState({ conversationForm })
                                    }} label={'Patient'.translate(this.props.lang)} />
                                </FormGroup>
                                <FormGroup>
                                    <Button size="sm" color="primary" onClick={() => {
                                        this.conversation(this.state.conversationModal._id, this.state.conversationForm);
                                    }}>{'Start new conversation'.translate(this.props.lang)}</Button>
                                </FormGroup>

                            </ModalBody>
                        </Modal>
                        :
                        null
                }

                {this.state.error ?

                    <ErrorModal lang={this.props.lang}
                        isOpen={this.state.error}
                        toggle={() => this.setState({ error: null })}

                    >
                        {this.state.error.translate(this.props.lang)}
                    </ErrorModal>

                    :
                    null
                }
                {this.state.signinModal ?
                    <Modal isOpen={this.state.signinModal} centered>
                        <ModalHeader toggle={() => this.setState({ signinModal: !this.state.signinModal })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ signinModal: !this.state.signinModal })}><Isvg src={xIcon} /></button>}>
                            <h4>{'Sign in with your NET ID'.translate(this.props.lang)}</h4>

                        </ModalHeader>
                        <ModalBody>
                            <Button onClick={() => this.allowReferral({})}>{'Continue'.translate(this.props.lang)}</Button>
                        </ModalBody>
                    </Modal>
                    : null}
            </div >
        );
    }
}

const selector = formValueSelector('modalForm');

export default connect(state => {
    return { group: selector(state, 'group') }

}, {
    changeSubgroup: value => change("modalForm", "subgroup", value),

})(Page(ReceivedReferrals));