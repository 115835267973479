import React from 'react';

import {
    Modal, ModalHeader, ModalBody, ModalFooter, Button
} from 'reactstrap';

import FormBuilder from './forms/formBuilder';
import { required, emailAddress } from './forms/validation'
import { connect } from "react-redux";
import { formValueSelector, change } from "redux-form"; // ES6

import { API_ENDPOINT } from '../constants'

import FaceID from "../faceApi/loginComponent";

import backIcon from '../assets/svg/back-icon.svg'
import QRCode from "react-qr-code";
import Isvg from "react-inlinesvg";

import { Player, Controls } from "@lottiefiles/react-lottie-player";


function parseJSON(response) {
    return response.json().then(json => {
        return {
            result: json,
            status: response.status
        }
    })
}
/**
* BankID modal for login
* @author   Milan Stanojevic
*/
class BankIdModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            changeQrCode: true
        }
    }

    componentDidMount() {
        window.addEventListener('message', (e) => {
            if (e && e.data && e.data.indexOf('signed') != -1) {
                let id;
                if (e.data.indexOf('=') != -1) {
                    let eDataSplit = e.data.split('=');
                    if (eDataSplit && eDataSplit[1]) {
                        id = eDataSplit[1];
                    }

                }
                this.props.toggle(id);

            }
        }, false)


        this.interval = setInterval(() => {
            this.checkBankIdSign()
            const imageElement = document.getElementById('qr-image');
            if (imageElement && this.state.changeQrCode) {
                this.setState({
                    changeQrCode: false
                }, () => {
                    this.changeQrCod()
                })
            }
        }, 2000);

        // if (this.props.qrCodeLink) {

        // }
    }

    changeQrCod = () => {
        const qrCodeLink = this.props.qrCodeLink;
        const imageElement = document.getElementById('qr-image');
        let t = 0;

        this.qrCodeInterval = setInterval(() => {
            // QR code doesn't work after repeating 14 times so that is the limit for bankid v5 for now
            if (t === 14) {
                clearInterval(this.qrCodeInterval);
                this.checkBankIdSign();
                t = 0;
            }

            imageElement.setAttribute('src', qrCodeLink + '&t=' + t);
            t++;
        }, 2000);
    }

    checkBankIdSign = () => {
        fetch(API_ENDPOINT + '/users/bankid/v5/check/sign', {
            method: 'POST',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
            body: JSON.stringify({ key: this.props.isOpen && this.props.isOpen.key, alias: this.props.alias ? this.props.alias : '' })
        }).then(res => res.json()).then((result) => {
            if (result && result.status && result.status === 'Finished') {
                this.props.toggle(this.props.isOpen && this.props.isOpen.key)
                clearInterval(this.interval)
                clearInterval(this.qrCodeInterval)
            }
        })
    }

    componentWillUnmount() {
        this.componentUnmounted = true;
        if (this.interval) {
            clearInterval(this.interval)
        }

        if (this.qrCodeInterval) {
            clearInterval(this.qrCodeInterval)
        }
    }



    render() {
        return (

            <Modal isOpen={this.props.isOpen} centered>
                <ModalHeader toggle={this.props.toggle} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={this.props.closeModal ? this.props.closeModal : this.props.toggle}>&times;</button>}>
                    {'BankID'.translate(this.props.lang)}
                </ModalHeader>
                <ModalBody className="bankid-modal">

                    {/* <iframe ref={(node) => this.bankIdIframe = node} onLoad={() => {
                        try {
                            if (this.bankIdIframe && this.bankIdIframe.contentWindow && this.bankIdIframe.contentWindow.location && this.bankIdIframe.contentWindow.location.href && this.bankIdIframe.contentWindow.location.href.indexOf('/bankid/sign/v5') !== -1) {
                                this.setState({
                                    _hideIframe: true
                                })
                            }
                        } catch (e) { }
                    }} src={this.props.redirect_url} style={this.state._hideIframe ? { opacity: 0 } : { width: '100%', height: 350, border: 0 }} /> */}
                    <div className='bank-id-checkout'>
                        <div  >
                            {/* <QRCode
                                size={256}
                                style={{ height: "auto", maxWidth: 200, width: 200 }}
                                value={this.props.redirect_url.replace(/.*text=/, '')}
                                viewBox={`0 0 256 256`}
                            /> */}
                            <img id='qr-image' src={this.props.qrCodeLink} style={{ height: 'auto', maxWidth: 200, width: 200 }} />

                        </div>
                        <p>{"Scan QR code if bankid app doesn't shows up".translate(this.props.lang)}</p>
                        <a href={this.props.redirect_url} className='btn btn-primary'>{"Open BankID on this device".translate(this.props.lang)}</a>
                    </div>
                    <p className="error-message">{this.state.error}</p>

                </ModalBody>
            </Modal>

        )
    }
}

const selector2 = formValueSelector("form");


export default connect(
    (state) => {
        return {
            sex: selector2(state, 'userData.sex')
        };
    },
    {
        changeSex: (value) => change("form", "userData.sex", value),

    }
)(BankIdModal);