import React, { Component } from 'react';
import Page from '../../containers/page';

import {
    Container,
    Row,
    Col,
} from 'reactstrap';

import RegistrationRequestsList from '../../components/RegistrationRequestsList';
import { API_ENDPOINT } from '../../constants';

export class TwoFactorAuthRegistrationRequests extends Component {
    constructor(props) {
        super(props);
        this.state = {
            num: 0,
            clinicGroupSettings: null
        }
    }

    refresh = () => {
        this.setState(prev => ({ ...prev, num: +prev?.num + 1 }));
        this.props?.checkRegistrationRequests?.();
    }

    componentDidMount() {
        this.getClinicGroupSettings();
    }

    componentDidUpdate(prevProps) {
        if ((!prevProps?.selectedGroup && this.props.selectedGroup) || (prevProps?.selectedGroup !== this.props.selectedGroup)) {
            this.getClinicGroupSettings();
        }
    }

    getClinicGroupSettings = () => {
        this.setState({ clinicGroupSettings: null }, async () => {
            if (!this.props?.selectedGroup) return;
            const response = await fetch(API_ENDPOINT + '/data/clinic-group-settings/' + this.props.selectedGroup);
            if (!response || !response.ok) return;
            const result = await response.json();
            if (!result || result.error || !result.clinicGroupSettings) return;
            this.setState({ clinicGroupSettings: result.clinicGroupSettings });
        })
    }

    render() {

        return (
            <div className={this.props.autoHideSidebar ? "dashboard dashboard-auto-hide" : "dashboard"}>
                <Container fluid>
                    {!this.state.clinicGroupSettings?.skip2faVerification ? <Row>
                        <Col lg='12'>
                            <div className='panel aeb-panel'>
                                <div className='panel-header'>
                                    <h5 className='component-header'>{'Patient Registration Requests'.translate(this.props?.lang || 'en')}</h5>
                                </div>

                                <RegistrationRequestsList {...this.props} handled={false} num={this.state.num} refresh={this.refresh} multiselect={true} />
                            </div>
                        </Col>
                    </Row> : null}
                    <Row>
                        <Col lg='12'>
                            <div className='panel aeb-panel'>
                                <div className='panel-header'>
                                    <h5 className='component-header'>{'Handled Patient Registration Requests'.translate(this.props?.lang || 'en')}</h5>
                                </div>

                                <RegistrationRequestsList {...this.props} handled={true} num={this.state.num} refresh={this.refresh} />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}

export default Page(TwoFactorAuthRegistrationRequests);